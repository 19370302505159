"use strict";

var MuniCatchBasinProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "muniCatchBasinProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.muniCatchBasinProgressLayer && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers.muniCatchBasinProgressLayer);
            Clustering.setState("muniCatchBasinProgress");
          } else {
            const mapId = Tree.get("mapId");
            const isFetching = Tree.get(["layers", mapId, "muniCatchBasinProgress", "isFetching"]);
            if (sameSpatialFilter && sameView && isFetching) {
              // Temp solution to prevent laoding twice from dataView and filters update
              return;
            }
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.muniCatchBasinProgressLayer)) {
            map.removeLayer(mapLayers.muniCatchBasinProgressLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");
    if (!filters.catchments.length && filters.searchString) {
      Tree.set(["layers", mapId, "muniCatchBasinProgress", "data"], []);
    } else {
      Tree.set(["layers", mapId, "muniCatchBasinProgress", "isFetching"], true);
      ReportApiCalls.getMuniCatchBasinProgress(filters, function (data) {
        Tree.set(["layers", mapId, "muniCatchBasinProgress", "isFetching"], false);
        Tree.set(["layers", mapId, "muniCatchBasinProgress", "data"], data);
        Tree.set(
          ["layers", mapId, "muniCatchBasinProgress", "spatialView"],
          Tree.get("spatialView", mapId),
        );
      });
    }
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "muniCatchBasinProgress", function (data) {
      if (Filters.spatialFilterIsSet()) {
        data = spatiallyFilterMuniCatchBasinData(data);
      }
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.muniCatchBasinProgressLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
      Clustering.setState("muniCatchBasinProgress");
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.muniCatchBasinProgressLayer) {
      map.removeLayer(mapLayers.muniCatchBasinProgressLayer);
    }
    mapLayers.muniCatchBasinProgressLayer = createLayer(data);
    mapLayers.muniCatchBasinProgressLayer = FastMarker.createFastMarker(
      map,
      mapLayers.muniCatchBasinProgressLayer,
      "muniCatchBasinProgress",
      MuniCatchBasinIcon.getCircleMarkerColor,
    );
    MuniCatchBasinPopup.loadDomListeners();
    mapLayers.muniCatchBasinProgressLayer.addTo(map);
    return mapLayers.muniCatchBasinProgressLayer;
  };

  var createLayer = function (data) {
    const layer = L.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;

        const marker = new FastMarker.DefaultHiddenMarker(latlng, {
          icon: MuniCatchBasinIcon.getIconMarker(
            properties.score,
            properties.routineMaintenanceDue,
            properties.isCatchBasinHighPriority,
          ),
        });
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => MuniCatchBasinPopup.getMuniCatchBasinPopupHtml(feature.properties),
          MuniCatchBasinPopup.getPopupOptions(),
        );
      },
    });

    MuniCatchBasinPopup.loadPopupEventListeners(layer);

    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Only watch for specific filters since other filters updates are already handled in treeUpdates
    // @TODO: Investigate why listening to filters update causes an infinite loop while searching
    Tree.watch({
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        Tree.get("dataView") === "muniCatchBasinView" &&
        MapFunctions.mapHasLayer(map, mapLayers.muniCatchBasinProgressLayer) &&
        Tree.get("layers", mapId, "muniCatchBasinProgress", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var spatiallyFilterMuniCatchBasinData = function (projectData) {
    const filters = Tree.get("filters");
    return projectData.filter((datum) => {
      return (
        filters.receivingWaters.includes(datum.catchment["drains_to_rw"]) ||
        filters.receivingWaters.includes(datum.catchment["drains_to_c"]) ||
        filters.catchments.includes(datum.catchment["catchid"])
      );
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    createLayer,
  };
};

module.exports = MuniCatchBasinProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Table = require("../mapFunctions/table");
const Filters = require("../mapFunctions/filters");
const Clustering = require("../mapFunctions/clustering");
const MuniCatchBasinIcon = require("./muniCatchBasinIcon");
const FastMarker = require("../mapFunctions/fastMarker");
const MuniCatchBasinPopup = require("./muniCatchBasinPopup");
