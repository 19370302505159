"use strict";

var DataViewFunctions = function () {
  var getDataViewConfig = function (dataView) {
    const config = DataViewConfig.get()[dataView];

    if (config) {
      return config;
    }
    throw new Error(`No configuration for dataView: ${dataView}.`);
  };

  var getCurrentDataViewProperty = function (property) {
    const dataView = Tree.get("dataView");
    const config = getDataViewConfig(dataView);

    if (config[property] !== undefined) {
      return config[property];
    }
  };

  var getCurrentDataModule = function () {
    const dataView = Tree.get("dataView");
    const config = getDataViewConfig(dataView);
    return config["dataModule"];
  };

  var getCurrentLayerName = function (mapId) {
    const config = getDataViewConfig(Tree.get("dataView"));

    if (!mapId) {
      mapId = Tree.get("mapId");
      if (!Tabs.activeTabHasMap()) {
        return null;
      }
    }

    if (config.layerSelectionControll && config.layerSelectionControll[mapId] === "layerDropdown") {
      return LayerDropdown.getSelectedDropdown();
    }

    var layerName = config["layerNameByMapId"][mapId];
    if (layerName) {
      return layerName;
    }
    throw new Error(`No layerName for mapId ${mapId}.`);
  };

  var getDataViewsByDataModule = function (dataModule) {
    var dataViews = [];
    for (const dataViewKey in DataViewConfig.get()) {
      if (DataViewConfig.get()[dataViewKey].dataModule === dataModule) {
        dataViews.push(dataViewKey);
      }
    }
    return dataViews;
  };

  var getTableDataColumns = function () {
    const config = getDataViewConfig(Tree.get("dataView"));
    const mapId = Tree.get("mapId");

    var tableDataColumns = config["tableDataColumns"]?.[mapId];
    if (tableDataColumns) {
      return tableDataColumns;
    }
    console.warn(`No tableDataColumns for mapId ${mapId}.`);
    return [];
  };

  var handleDataViewDisabled = function () {
    const selectedReports = AnnualReports.getYearReports();
    const allDataViewConfigs = getAllDataViewConfigs();
    const allDataViews = Object.keys(allDataViewConfigs);

    const $dataViewDropdownLis = $(".data-view-dropdown-list li");
    DataViewDropdown.disableDataViewDropdownItem($dataViewDropdownLis);

    for (const dataView of allDataViews) {
      const dataName = AnnualReports.mapDataViewToDataName(dataView);
      const dataNameReport = selectedReports.find((report) => {
        return report.dataName === dataName && !report.lockedReportDisabled;
      });

      const config = allDataViewConfigs[dataView];
      if (dataNameReport && !config.disabled) {
        DataViewDropdown.enableDataViewDropdownItem(dataView);
      }
    }
  };

  var customizeDataViewDropdownByGroup = function () {
    // @TODO: Render dataView dropdown from config
    $(".data-view-dropdown-list")
      .find("li[data-view], li[data-program]")
      .each(function () {
        const $li = $(this);
        if (!["regionAreaView", "regionPointView"].includes($li.data("view"))) {
          customizeDataViewDropdownListItem($li);
        }
      });
  };

  var customizeDataViewDropdownListItem = function ($li) {
    handleDataViewDropdownListItemDisplay($li);
    handleDataViewDropdownListItemText($li);
  };

  var handleDataViewDropdownListItemDisplay = function ($li) {
    const dataView = $li.data("view");
    const dataProgram = $li.data("program");
    let permitSettingsForActiveGroup;

    if (dataView) {
      permitSettingsForActiveGroup = PermitSettings.getPermitSettingsByDataView(dataView);
    } else if (dataProgram) {
      permitSettingsForActiveGroup = PermitSettings.getPermitSettingsByDataProgram(dataProgram);
    }

    if (permitSettingsForActiveGroup?.hide || dataView === "watershedView") {
      $li.addClass("default-hidden");
    }
    if (dataView === "watershedView" && !!permitSettingsForActiveGroup?.show) {
      $li.removeClass("default-hidden");
    }
  };

  var handleDataViewDropdownListItemText = function ($li) {
    const dataView = $li.data("view");
    const dataProgram = $li.data("program");
    const textForRootGroup = dataView
      ? PermitSettings.getDisplayPermitSectionByDataView(dataView)
      : PermitSettings.getDisplayPermitSectionByDataProgram(dataProgram);

    if (!textForRootGroup) {
      throw new Error(
        `No permit section configured for data view "${dataView}" and data program "${dataProgram}"`,
      );
    }

    $li.find("a").text(textForRootGroup);
  };

  var handleDataViewDefaultBasemap = function () {
    var mapId = Tree.get("mapId");
    var defaultBasemap = getCurrentDataViewProperty("defaultBasemap")[mapId];

    if (defaultBasemap) {
      BasemapFunctions.setBasemap(defaultBasemap, mapId);
    }
  };

  var setMapLayerFromDataView = function () {
    var layerName = getCurrentLayerName();
    var mapId = Tree.get("mapId");
    var showDefaultLayers = getCurrentDataViewProperty("showDefaultLayers");

    if (!layerName || Tree.get("firstLoad", mapId)) {
      return;
    }
    setSentryExtra("selectedLayer", layerName);

    if (Progeny.activeHasProgeny()) {
      if (!Progeny.activeGroupIsRegulator()) {
        MapFunctions.setMapLayers(layerName, showDefaultLayers);
      }
      if (!["chart", "summary"].includes(Tree.get("activeTab"))) {
        MapFunctions.enableMapLayer("regional");
      }

      hideRegulatorViewDropdownKpi();
      Filters.setDataViewDefaultFilters();
      KPI.loadKpi(); // @TODO: refactor to get rid of extra kpi calls
    } else {
      MapFunctions.setMapLayers(layerName, showDefaultLayers);
      Filters.load();
      setDataSortOnDataViewChange();
    }
    Filters.disableSearchIfInConfig();
    Kpi.loadListeners();

    Table.render();
    RegionDropdown.handleDisablingRegionButtons();
    handleLegendByDataView();
  };

  var setDataSortOnDataViewChange = function () {
    let currentDataSortId = Tree.get("filters", "dataSort");
    let currentDataSortName = "";
    if (currentDataSortId === "rw") {
      currentDataSortName = "Receiving Water";
    } else if (currentDataSortId === "ud") {
      currentDataSortName = "Urban Drainage";
    } else if (currentDataSortId === "uc") {
      currentDataSortName = "Urban Catchment";
    } else {
      // Default dataSort to receiving water if not one of 3 above
      currentDataSortId = "rw";
      currentDataSortName = "Receiving Water";
    }
    Filters.setDataSortDropdownSelection(currentDataSortId, currentDataSortName, true);
  };

  var hideRegulatorViewDropdownKpi = function () {
    const kpiTable = $(".kpi-table-div");
    kpiTable.hide();
  };

  var _getValidLayerData = function (selectedLayerData) {
    const selectedDataName = AnnualReports.mapDataModuleToDataName(selectedLayerData.dataModule);
    const supportedDataNames = _getSupportedDataNames();

    if (Progeny.activeHasProgeny()) {
      return selectedLayerData;
    }

    var validData = selectedLayerData;

    if (!supportedDataNames.includes(selectedDataName)) {
      validData = _getLayerWithDataName(supportedDataNames);
    }

    return validData;
  };

  var _getSupportedDataNames = function () {
    const allTabDataNames = getAllDataViews().map(AnnualReports.mapDataViewToDataName);
    var supportedDataNames = new Set();

    for (const report of AnnualReports.getYearReports()) {
      const dataName = report.dataName;
      if (allTabDataNames.includes(dataName)) {
        supportedDataNames.add(dataName);
      }
    }

    return Array.from(supportedDataNames);
  };

  var _getLayerWithDataName = function (dataNames) {
    const primaryLayers = LayerFunctions.getAllLayersWith("isPrimary", true);

    for (const layer in primaryLayers) {
      const layerData = primaryLayers[layer];
      const layerDataName = AnnualReports.mapDataModuleToDataName(layerData.dataModule);

      if (dataNames.includes(layerDataName)) {
        return layerData;
      }
    }

    throw new Error("Unable to find supported layer.");
  };

  var setDataView = function (dataView) {
    Tree.set("dataView", dataView);
    setSentryExtra("dataView", dataView);
    PageFunctions.getCurrentPage().data("view", dataView).attr("data-view", dataView);

    const enabledDataViews = PageFunctions.getCurrentPageProperty("enabledDataViews");
    if (!enabledDataViews.includes(dataView)) {
      PageFunctions.goToPage(getDefaultTab(dataView));
      return;
    }
    // @TODO: Handle this with dataViewConfig
    if (dataView === "watershedView" && Tree.get("activeGroup", "groupId") !== 15) {
      PageFunctions.goToPage("report", "runoffView");
      return;
    }

    var layerName = getCurrentLayerName();
    if (!layerName) {
      return;
    }

    PageFunctions.getCurrentPage().find(".floating-legend").attr("data-view", dataView);

    DataExport.handleDisabledLink();
    Tabs.handleDisabledTabs();
    YearDropdown.setSelectedOrValidYear();
    DataViewDropdown.updateDataViewDropdown(dataView);
    YearDropdown.handleYearDropdownDisabled();
    handleBlueDot(dataView);
    handleLegendByDataView();
    _handleSpatialView();
    handleSpatialViewButtonsDisplay(dataView);
    LayerDropdown.handleLayerDropdownDisplay();
    LayerDropdown.populateLayerDropdown();
    handleReportSimMapDisplay(dataView);
  };

  var handleReportSimMapDisplay = function (dataView = Tree.get("dataView")) {
    if (Tree.get("activeTab") === "report") {
      const toggle = dataView === "watershedView";
      $("#sim-map-container").toggle(toggle);
    }
  };

  function handleBlueDot(dataView) {
    if (getCurrentDataViewProperty("blueDotEnabled")) {
      BlueDot.watchUserLocation();
    } else {
      BlueDot.disable();
    }
  }

  var _handleSpatialView = function () {
    var mapId = Tree.get("mapId");
    var spatialView = Tree.get("spatialView", mapId);

    if (spatialView === "catchmentView" || spatialView === "drainageView") {
      Tree.set(["spatialView", mapId], "MS4View");
    }
  };

  var handleSpatialViewButtonsDisplay = function (dataView) {
    if (!dataView) dataView = Tree.get("dataView");
    const config = getDataViewConfig(dataView);
    var showSpatialViewButtons = !!config.catchmentViewEnabled;
    $(".spatial-view-buttons-container").toggle(showSpatialViewButtons);
  };

  var setSentryExtra = function (extra, value) {
    Sentry.getCurrentScope().setExtra(extra, value);
  };

  var handleLegendByDataView = function (dataView) {
    if (!dataView) {
      dataView = Tree.get("dataView");
    }
    var layerName = getCurrentLayerName();
    renderLegendLayers(dataView, layerName);
    renderLegendLegends(dataView, layerName);
    handleDataViewDefaultBasemap();
  };

  var renderLegendLayers = function (dataView, layerName) {
    var mapId = Tree.get("mapId");
    var layerData = LayerFunctions.getLayerConfigByTreeName(layerName);
    var template = getDataViewLegendLayersTemplate(dataView);
    if (template) {
      const html = nunjucks.render(template, {
        mapId,
        layers: [layerData],
      });
      PageFunctions.getCurrentPage().find(".layers-body").html(html);
    }
  };

  var renderLegendLegends = function (dataView, layerName) {
    var template = getDataViewLegendLegendsTemplate(dataView);
    var primaryLayerData = LayerFunctions.getLayerConfigByTreeName(layerName);
    if (template) {
      var html = nunjucks.render(template, { primaryLayerData });
      PageFunctions.getCurrentPage().find(".legends-body").html(html);
    }
    var mapId = Tree.get("mapId");
    if (mapId === "report" || mapId === "plan") {
      DataViewDropdown.updateLegendForTelrLayer();
    }
  };

  var getDataViewLegendLayersTemplate = function (dataView, mapId) {
    if (!dataView) {
      dataView = Tree.get("dataView");
    }
    if (!mapId) {
      mapId = Tree.get("mapId");
    }
    const templateMap = getDataViewConfig(dataView)["legendLayersTemplate"];
    if (templateMap === null) return;
    const template = templateMap[mapId];
    if (template) {
      return template;
    }
    console.warn(`legendLayersTemplate not defined for mapId ${mapId} and dataView ${dataView}.`);
  };

  var getDataViewLegendLegendsTemplate = function (dataView, mapId) {
    if (!dataView) {
      dataView = Tree.get("dataView");
    }
    if (!mapId) {
      mapId = Tree.get("mapId");
    }
    const templateMap = getDataViewConfig(dataView)["legendLegendsTemplate"];
    if (templateMap === null) return;
    const template = templateMap[mapId];
    if (template) {
      return template;
    }
    console.warn(`legendLegendsTemplate not defined for mapId ${mapId} and dataView ${dataView}.`);
  };

  var getAllDataViews = function () {
    return Object.keys(DataViewConfig.get());
  };

  var getAllDataViewConfigs = function () {
    return DataViewConfig.get();
  };

  var isTelrDataView = function (dataView = Tree.get("dataView")) {
    return ["runoffView", "particulatesView", "runoffRatioView"].includes(dataView);
  };

  var isTelrDataViewInNonInputsMap = function (dataView = Tree.get("dataView")) {
    var mapId = Tree.get("mapId");
    return isTelrDataView(dataView) && mapId !== "inputs";
  };

  var getDefaultTab = function (dataView) {
    const defaultTab = getDataViewConfig(dataView)["defaultTab"];
    if (defaultTab) {
      return defaultTab;
    } else {
      return "report";
    }
  };

  var getKpiApiCall = function (dataView) {
    const config = getDataViewConfig(dataView);
    return config["kpiApiCall"] || false;
  };

  var getSummaryTemplate = function (dataView) {
    const config = getDataViewConfig(dataView);
    const result = config["summaryTemplate"];
    if (!result) {
      throw new Error(`No summaryTemplate configuration for dataView: ${dataView}.`);
    }
    return result;
  };

  var getUpdateDataFunction = function (dataView) {
    const config = getDataViewConfig(dataView);
    return config["updateDataFunction"];
  };

  var getSummaryDisableYearsExcept = function (dataView) {
    const config = getDataViewConfig(dataView);
    return config["summaryDisableYearsExcept"] || false;
  };

  return {
    getDataViewConfig,
    getCurrentDataViewProperty,
    getCurrentDataModule,
    getCurrentLayerName,
    getDataViewsByDataModule,
    handleDataViewDisabled,
    setMapLayerFromDataView,
    setDataView,
    _getValidLayerData,
    _getSupportedDataNames,
    _getLayerWithDataName,
    getDataViewLegendLayersTemplate,
    getDataViewLegendLegendsTemplate,
    handleSpatialViewButtonsDisplay,
    getAllDataViews,
    getTableDataColumns,
    isTelrDataView,
    isTelrDataViewInNonInputsMap,
    customizeDataViewDropdownByGroup,
    handleDataViewDropdownListItemDisplay,
    handleDataViewDropdownListItemText,
    getDefaultTab,
    getKpiApiCall,
    getSummaryTemplate,
    getUpdateDataFunction,
    getSummaryDisableYearsExcept,
  };
};

module.exports = DataViewFunctions();

const Tree = require("../tree");
const DataViewConfig = require("./config/dataViewConfig");
const AnnualReports = require("./annualReports");
const PageFunctions = require("./pageFunctions");
const RegionDropdown = require("./regionDropdown");
const Progeny = require("../login/progeny");
const BlueDot = require("./blueDot");
const MapFunctions = require("./mapFunctions/mapFunctions");
const Table = require("./mapFunctions/table");
const Kpi = require("./report/kpi");
const Filters = require("./mapFunctions/filters");
const DataViewDropdown = require("./mapFunctions/dataViewDropdown");
const BasemapFunctions = require("./mapFunctions/basemapFunctions");
const YearDropdown = require("./mapFunctions/yearDropdown");
const Tabs = require("./tabs");
const LayerDropdown = require("./mapFunctions/layerDropdown");
const Sentry = require("@sentry/browser");
const LayerFunctions = require("./mapFunctions/layerFunctions");
const KPI = require("./report/kpi");
const DataExport = require("./dataExport");
const PermitSettings = require("./settings/permitSettings");
