"use strict";

const AssetsOutfallGeoServerDynamicMapLayer = function () {
  const layerName = "assetsOutfallEsriDynamicMap";
  const layerKey = "assetsOutfallEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsOutfallEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsOutfallEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsOutfallEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = AssetsOutfallGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
