"use strict";

const Mobile = function () {
  const $collapseLegendButton = $(".layers-button");
  const $collapseTable = $("#floatingInputsCollapse");
  const $collapseLegend = $(".floating-legend");

  var collapseAll = function (options) {
    collapseTable(options);
    collapseLegend(options);
  };

  var collapseTable = function (options) {
    $collapseTable.collapse(options);
  };

  var collapseLegend = function (options) {
    $collapseLegend.collapse(options);
  };

  var showLegendButton = function (show) {
    if (show) {
      $collapseLegendButton.show();
    } else {
      $collapseLegendButton.hide();
    }
  };

  var setVisibleOnMobile = function ($element, enabled, defaultVisible = false) {
    var classPrefix = defaultVisible ? "visible" : "hidden";

    $element.toggleClass(classPrefix + "-xs", enabled);
    $element.toggleClass(classPrefix + "-sm", enabled);
  };

  var toggleMobileButtons = function () {
    const listActive = Tree.get("navToggleSelection") === "list";
    const todosActive = Tree.get("activeTab") === "todo";
    const listAndTodosActive = listActive && todosActive;

    $(".floating-inputs").toggle(!listAndTodosActive);
  };

  var loadMapListeners = function (map) {
    map.off("popupopen", _collapseAllMobileNav);

    map.on("popupopen", _collapseAllMobileNav);
  };

  var _collapseAllMobileNav = function () {
    const $navToggle = $("#main-header-collapse-container");
    $navToggle.collapse("hide");
  };

  var loadListeners = function () {
    $(window).on("resize", setAppHeight);
    setAppHeight();
  };

  const setAppHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };

  return {
    collapseAll,
    showLegendButton,
    setVisibleOnMobile,
    toggleMobileButtons,
    loadMapListeners,
    loadListeners,
  };
};

module.exports = Mobile();

const Tree = require("./tree");
