"use strict";

const ConstructionProjectG2Filters = function () {
  const searchFields = ["projectIdentifier", "streetAddress", "projectName"];
  const searchDbFields = ["project_identifier", "street_address", "project_name"];
  let mainSmartFilters;

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, highwayOptions } =
      Filters.getSpatialArrays();
    const phaseOptions = ToolSettings.getSetting("filterConstants", "phases");
    const responsiblePartyOptions = ProjectInventoryConstants.ownershipOptions;
    const priorityOptions = BmpFcsInventoryConstants.yesNoNull;
    const pausedOptions = [
      {
        name: "Yes",
        value: true,
      },
      {
        name: "No",
        value: false,
      },
    ];
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      highwayOptions,
      phaseOptions,
      responsiblePartyOptions,
      priorityOptions,
      pausedOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
    };
  };

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          ConstructionProjectGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return ResourceFilters.spatialFiltersAreSet(filters) || isOnlineDateFilterSet(filters);
    }
  };

  var isOnlineDateFilterSet = function (filters) {
    return isOnlineDateType(filters.dateType) && (filters.dateFrom || filters.dateTo);
  };

  var isOnlineDateType = function (dateType) {
    return ["routine", "repair", "clean"].includes(dateType);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var passOwnershipFilter = function (datum, filters) {
    let pass;
    if (filters.ownership?.length) {
      pass = filters.ownership.includes(datum.implementerType) || datum.implementerType === null;
    } else {
      pass = false;
    }

    return pass;
  };

  var passPhaseFilter = function (datum, filters) {
    var pass = true;

    if (filters.phase?.length) {
      pass = filters.phase.includes(datum.phase);
    } else {
      pass = false;
    }

    return pass;
  };

  var passInspectionsFilter = function (datum, filters) {
    if (!filters.inspectionDate?.from && !filters.inspectionDate?.to) {
      return true;
    }

    return datum?.inspections?.some((data) => {
      return (
        data?.inspectionDate &&
        ResourceFilters.passIsoDateFilter(
          data.inspectionDate,
          filters.inspectionDate?.from,
          filters.inspectionDate?.to,
        )
      );
    });
  };

  var passInstalledDateFilter = function (datum, filters) {
    var pass = true;
    if (filters.installDate?.from || filters.installDate?.to) {
      pass = ResourceFilters.passIsoDateFilter(
        datum.projectStartDate,
        filters.installDate.from,
        filters.installDate.to,
      );
    }
    return pass;
  };

  var passCompletedDateFilter = function (datum, filters) {
    var pass = true;
    if (filters.completedDate?.from || filters.completedDate?.to) {
      pass = ResourceFilters.passIsoDateFilter(
        datum.projectEndDate,
        filters.completedDate.from,
        filters.completedDate.to,
      );
    }
    return pass;
  };

  var passViewPermissions = function (datum) {
    return datum.canViewProject === true;
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "phase",
      "project_phase",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "ownership",
      "implementer_type",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "maintainerType",
      "maintainer_type",
    );

    if (filters.priority && !FilterSummary.areAllCheckboxesChecked("priority")) {
      queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "priority",
        queryStrings,
        "high_priority",
      );
    }

    if (
      filters.paused &&
      !FilterSummary.areAllCheckboxesChecked("paused") &&
      Array.isArray(filters.paused)
    ) {
      if (filters["paused"].includes("true")) {
        queryStrings.push(`(pause_status = 'paused')`);
      }
      if (filters["paused"].includes("false")) {
        queryStrings.push(`(pause_status = 'restarted' OR pause_status IS NULL)`);
      }
    }

    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "project_start_date",
        filters.installDate.from,
        filters.installDate.to,
      );
    }

    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings, [
      { key: "drainsToRw", field: "drains_to_rw" },
      { key: "drainsToC", field: "drains_to_c" },
      { key: "catchmentName", field: "catchment_name" },
      { key: "zones", field: "zone_names" },
      { key: "highwayName", field: "highway_name" },
    ]);

    if (filters.completedDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "project_end_date",
        filters.completedDate.from,
        filters.completedDate.to,
      );
    }
    addGeoServerViewPermissionsQuery(queryStrings, filters);
  };

  var addGeoServerViewPermissionsQuery = function (queryStrings, filters) {
    const canNotViewIds = ConstructionProjectGeoServerLayer.getCanNotViewProjectIds();

    if (canNotViewIds.length !== 0) {
      queryStrings.push(GeoServerFilterFunctions.getWhereNotInArrayQuery("id", canNotViewIds));
    }
  };

  var _handleNullOption = function (datumValue) {
    return datumValue === null ? "null" : datumValue;
  };

  var addGeoServerViewParams = function (params, filters) {
    ResourceFilters.addDateParam(
      params,
      "inspections",
      filters.inspectionDate?.from,
      filters.inspectionDate?.to,
    );
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      phase: "phase",
      ownership: "implementerType",
      maintainerType: "maintainerType",
      priority: "highPriority",
      paused: "isPaused",
      condition: "condition_status",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      highwayName: "highway_name",
      subGroups: "groupId",

      otherFilters: (datum, filters) => {
        return (
          passViewPermissions(datum) &&
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passInstalledDateFilter(datum, filters) &&
          passInspectionsFilter(datum, filters) &&
          passCompletedDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
    passInstalledDateFilter,
    passCompletedDateFilter,
    passPhaseFilter,
    passOwnershipFilter,
    passViewPermissions,
    getProps,
    _handleNullOption,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = ConstructionProjectG2Filters();

const ApiError = require("../errors/apiError");
const ResourceFilters = require("../filters/resourceFilters");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const ProjectInventoryConstants = require("../construction/projectInventoryConstants");
const FilterSummary = require("../filters/filterSummary");
const Filters = require("../mapFunctions/filters");
const ToolSettings = require("../settings/toolSettings");
const Progeny = require("../login/progeny");
