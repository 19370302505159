"use strict";

const LayerConfig = function () {
  const get = function () {
    return {
      basemap: {
        treeName: "basemap",
      },
      parcelTiles: {
        treeName: "parcelTiles",
      },
      rwLayer: {
        treeName: "streams",
      },
      catchmentLayer: {
        treeName: "catchments",
      },
      highwayLayer: {
        treeName: "highways",
      },
      bmpLayer: {
        treeName: "bmps",
        clustering: true,
        clearOnYearChange: true,
      },
      fcsLayer: {
        treeName: "fcs",
        clustering: true,
        clearOnYearChange: true,
      },
      runoffProgressLayer: {
        treeName: "runoffProgress",
        id: "check-runoff-progress",
        type: "checkbox",
        value: "runoffProgress",
        isPrimary: true,
        name: "report-runoff-layers",
        checked: "checked",
        labelClass: "",
        label: "Runoff Reduction (%)",
        dataModule: "swtelr",
        summaryTableTemplate: "report/summaryTable/runoffProgressSummaryTable.njk",
        getCatchmentDataFromLayer: true,
      },
      runoffOpportunityLayer: {
        treeName: "runoffOpportunity",
        value: "runoffOpportunity",
        isPrimary: true,
        dataModule: "swtelr",
        summaryTableTemplate: "report/summaryTable/runoffOpportunitySummaryTable.njk",
        getCatchmentDataFromLayer: true,
      },
      particulatesProgressLayer: {
        treeName: "particulatesProgress",
        id: "check-particulates-progress",
        type: "checkbox",
        value: "particulatesProgress",
        isPrimary: true,
        name: "report-particulates-layers",
        checked: "checked",
        labelClass: "",
        label: "Particulate Reduction (%)",
        dataModule: "swtelr",
        summaryTableTemplate: "report/summaryTable/particulatesProgressSummaryTable.njk",
        getCatchmentDataFromLayer: true,
      },
      particulatesOpportunityLayer: {
        treeName: "particulatesOpportunity",
        value: "particulatesOpportunity",
        isPrimary: true,
        dataModule: "swtelr",
        summaryTableTemplate: "report/summaryTable/particulatesOpportunitySummaryTable.njk",
        getCatchmentDataFromLayer: true,
      },
      runoffRatioProgressLayer: {
        treeName: "runoffRatioProgress",
        id: "check-runoff-ratio-progress",
        type: "checkbox",
        value: "runoffRatioProgress",
        isPrimary: true,
        name: "report-runoff-ratio-layers",
        checked: "checked",
        labelClass: "",
        label: "Runoff Ratio Reduction (%)",
        dataModule: "swtelr",
        summaryTableTemplate: "report/summaryTable/runoffRatioProgressSummaryTable.njk",
        getCatchmentDataFromLayer: true,
      },
      runoffRatioOpportunityLayer: {
        treeName: "runoffRatioOpportunity",
        value: "runoffRatioOpportunity",
        isPrimary: true,
        dataModule: "swtelr",
        summaryTableTemplate: "report/summaryTable/runoffRatioOpportunitySummaryTable.njk",
        getCatchmentDataFromLayer: true,
      },
      sbmpProgressLayer: {
        treeName: "sbmpProgress",
        id: "check-sbmp-progress",
        type: "radio",
        value: "sbmpProgress",
        isPrimary: true,
        name: "report-sbmp-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Structural BMPs (sBMP)",
        dataModule: "swtelr",
        clustering: true,
        summaryTableTemplate: "report/summaryTable/sbmpProgressSummaryTable.njk",
        filterEnabled: true,
      },
      fcsProgressLayer: {
        treeName: "fcsProgress",
        id: "check-fcs-progress",
        type: "radio",
        value: "fcsProgress",
        isPrimary: true,
        name: "report-fcs-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Trash Full Capture Systems (FCS)",
        dataModule: "trash",
        clustering: true,
        summaryTableTemplate: "report/summaryTable/fcsProgressSummaryTable.njk",
        filterEnabled: false,
      },
      constructionProjectProgressLayer: {
        treeName: "constructionProjectProgress",
        id: "check-construction-project-progress",
        type: "radio",
        value: "constructionProjectProgress",
        isPrimary: true,
        name: "report-construction-project-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Construction Projects",
        dataModule: "construction",
        clustering: true,
        summaryTableTemplate: Session.isCdot()
          ? "report/summaryTable/constructionProjectProgressSummaryTableCdot.njk"
          : "report/summaryTable/constructionProjectProgressSummaryTableMs4.njk",
        filterEnabled: Session.isCdot() ? false : true,
        searchDisabled: Session.isCdot() ? true : false,
      },
      lidProjectProgressLayer: {
        treeName: "lidProjectProgress",
        id: "check-lid-project-progress",
        type: "radio",
        value: "lidProjectProgress",
        isPrimary: true,
        name: "report-lid-project-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "PCR Projects",
        dataModule: "lid",
        clustering: true,
        summaryTableTemplate: "report/summaryTable/lidProjectProgressSummaryTable.njk",
        filterEnabled: false,
      },
      incidentProgressLayer: {
        treeName: "incidentProgress",
        id: "check-incident-progress",
        type: "radio",
        value: "incidentProgress",
        isPrimary: true,
        name: "report-incident-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Incidents",
        dataModule: "idde",
        clustering: true,
        summaryTableTemplate: "report/summaryTable/incidentsProgressSummaryTable.njk",
        filterEnabled: false,
      },
      outfallProgressLayer: {
        treeName: "outfallProgress",
        id: "check-outfall-progress",
        type: "radio",
        value: "outfallProgress",
        isPrimary: true,
        name: "report-outfall-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Outfalls",
        dataModule: "muni outfall",
        clustering: true,
        summaryTableTemplate: "report/summaryTable/outfallsProgressSummaryTable.njk",
        filterEnabled: false,
      },

      muniCatchBasinProgressLayer: {
        treeName: "muniCatchBasinProgress",
        id: "check-muni-catch-basin-progress",
        type: "radio",
        value: "muniCatchBasinProgress",
        isPrimary: true,
        name: "report-muni-catch-basin-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Catch Basins",
        dataModule: "muniCatchBasin",
        clustering: true,
        summaryTableTemplate: "report/summaryTable/muniCatchBasinProgressSummaryTable.njk",
        filterEnabled: false,
      },

      muniCatchBasinReport: {
        treeName: "muniCatchBasinReport",
        id: "check-muni-catch-basin-progress",
        type: "radio",
        value: "muniCatchBasinReport",
        isPrimary: true,
        name: "report-muni-catch-basin-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Catch Basins",
        dataModule: "muniCatchBasinReport",
        clustering: true,
        summaryTableTemplate: "report/summaryTable/muniCatchBasinProgressSummaryTable.njk",
        filterEnabled: false,
      },

      landuseLayer: {
        treeName: "landuse",
      },
      highPriorityAreaLayer: {
        treeName: "highPriorityArea",
      },
      trashConditionEsriDynamicMapLayer: {
        treeName: "trashConditionEsriDynamicMap",
        value: "trashConditionEsriDynamicMap",
        isPrimary: true,
        dataModule: "trash",
        opacity: 0.9,
      },
      trashConditionCertaintyEsriDynamicMapLayer: {
        treeName: "trashConditionCertaintyEsriDynamicMap",
        clearOnYearChange: true,
        opacity: 0.9,
      },
      fullDrainageLayer: {
        treeName: "fullDrainage",
      },
      trashProgressLayer: {
        treeName: "trashProgress",
        id: "radio-full-trash",
        type: "radio",
        value: "trashProgress",
        isPrimary: true,
        name: "report-trash-radio-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Full Trash Capture",
        dataModule: "trash",
        summaryTableTemplate: "report/summaryTable/trashProgressSummaryTable.njk",
        renderNavKpiTable: true,
      },
      trashProgressEsriDynamicMapLayer: {
        treeName: "trashProgressEsriDynamicMap",
        id: "radio-full-trash",
        type: "radio",
        value: "trashProgressEsriDynamicMap",
        isPrimary: true,
        name: "report-trash-radio-layers",
        checked: "checked",
        labelClass: "radio-label",
        label: "Full Trash Capture",
        dataModule: "trash",
        summaryTableTemplate: "report/summaryTable/trashProgressSummaryTable.njk",
        renderNavKpiTable: true,
        opacity: 0.9,
      },
      litterHotspotEsriDynamicMapLayer: {
        treeName: "litterHotspotEsriDynamicMap",
        value: "litterHotspotEsriDynamicMap",
        isPrimary: true,
        dataModule: "trash",
        filterEnabled: true,
        opacity: 0.9,
      },
      parcelsPluEsriDynamicMapLayer: {
        treeName: "parcelsPluEsriDynamicMap",
        value: "parcelsPluEsriDynamicMap",
        isPrimary: true,
        dataModule: "trash",
        filterEnabled: true,
        opacity: 0.9,
      },
      ms4BoundaryLayer: {
        treeName: "ms4Boundary",
      },
      baseStormdrainLayer: {
        treeName: "stormdrains",
      },
      middleStormdrainLayer: {
        treeName: "stormdrains",
      },
      stormdrainLayer: {
        treeName: "stormdrains",
      },
      regionalLayer: {
        treeName: "regional",
      },
      regionPointLayer: {
        treeName: "regionPoint",
      },
      surveyLayer: {
        treeName: "survey123",
      },
      trashLineLayer: {
        treeName: "trashLines",
      },
      trashPointLayer: {
        treeName: "trashPoints",
      },
      sbmpProgressDrainageLayer: {
        treeName: "sbmpProgressDrainage",
        clearOnYearChange: true,
      },
      fcsProgressDrainageLayer: {
        treeName: "fcsProgressDrainage",
        clearOnYearChange: true,
      },
      muniCatchBasinProgressDrainageLayer: {
        treeName: "muniCatchBasinProgressDrainage",
        clearOnYearChange: true,
      },
      maintenanceZonesLayer: {
        treeName: "maintenanceZones",
        clearOnYearChange: true,
      },
      constructionProjectAreaProgressLayer: {
        treeName: "constructionProjectAreaProgress",
        clearOnYearChange: true,
      },
      lidProjectAreaProgressLayer: {
        treeName: "lidProjectAreaProgress",
        clearOnYearChange: true,
      },
      flowRoutingLayer: {
        treeName: "flowRouting",
      },
      outfallsSimpleLayer: {
        treeName: "outfallsSimple",
      },
      soilInputsLayer: {
        treeName: "soilInputs",
        id: "soil-inputs",
        type: "checkbox",
        value: "soilInputs",
        isPrimary: true,
        label: "Hydrologic Soil Group",
        checked: "checked",
        summaryTableTemplate: "report/summaryTable/soilInputsSummaryTable.njk",
      },
      imperviousInputsLayer: {
        treeName: "imperviousInputs",
        id: "impervious-inputs",
        type: "checkbox",
        value: "imperviousInputs",
        isPrimary: true,
        label: "Percent Impervious (%)",
        checked: "checked",
        summaryTableTemplate: "report/summaryTable/imperviousInputsSummaryTable.njk",
        tableDataColumns: ["percentImp"],
        dataSortLabelData: {
          dataSort1Id: "percentImp",
          dataSort1Label: "% Impervious",
        },
      },
      connectivityInputsLayer: {
        treeName: "connectivityInputs",
        id: "connectivity-inputs",
        type: "checkbox",
        value: "connectivityInputs",
        isPrimary: true,
        label: "Catchment Connectivity (%)",
        checked: "checked",
        summaryTableTemplate: "report/summaryTable/connectivityInputsSummaryTable.njk",
        filterEnabled: true,
        getCatchmentDataFromLayer: true,
      },
      landuseInputsLayer: {
        treeName: "landuseInputs",
        id: "landuse-inputs",
        type: "checkbox",
        value: "landuseInputs",
        isPrimary: true,
        label: "Land Use",
        checked: "checked",
        summaryTableTemplate: "report/summaryTable/landuseInputsSummaryTable.njk",
      },
      tempLayerNameLayer: {
        treeName: "tempLayerName",
        id: "check-temp-layer-name",
        type: "checkbox",
        value: "tempLayerName",
        isPrimary: true,
        name: "report-temp-layer-name",
        checked: "checked",
        labelClass: "",
        label: "Temp Layer Name",
        dataModule: "swtelr",
        clustering: false,
        // summaryTableTemplate: "report/summaryTable/lidProjectProgressSummaryTable.njk",
        filterEnabled: false,
      },
      groundwaterBasinLayer: {
        treeName: "groundwaterBasin",
      },
      landuseEsriDynamicMapLayer: {
        treeName: "landuseEsriDynamicMap",
      },
      streamsEsriDynamicMapLayer: {
        treeName: "streamsEsriDynamicMap",
        emptyLayerDef: true,
      },
    };
  };

  return { get };
};

module.exports = LayerConfig();

const Session = require("../../login/session");
