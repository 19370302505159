"use strict";

const PlanWizardController = function () {
  let submitted;

  let multiStepForm;
  let initialFilterState;
  let $page;
  let editObjectives;
  let smartFilters;

  const render = async function (planSlug = null, _editObjectives = true) {
    editObjectives = _editObjectives;
    planSlug = planSlug === null ? "new" : planSlug;
    const options = {
      breadcrumbs: {
        previousPage: {
          page: planSlug !== "new" ? "Stormwater Plans" : "Portfolio",
          route: planSlug !== "new" ? "/impact-accounting/stormwater-plans" : "/portfolio",
        },
        currentPage:
          planSlug !== "new" ? (editObjectives ? "Edit Plan" : "Edit Plan Info") : "Create Plan",
      },
      preparedDataFunction: _getPreparedData,
      afterRenderFunction: _afterRenderFunction,
      constants: [
        "regions",
        "states",
        "huc6",
        "huc8",
        "ownershipStatus",
        "tenants",
        "acquisitionPortfolios",
        "propertyTypes",
        "funds",
        "districts",
        "bmpRelationship",
        "retrofitOpportunities",
        "siteComplexity",
        "compensationStatus",
        "programs",
        "ejCommunity",
      ],
    };
    await PageController.render(
      "plan-wizard",
      null,
      () => EsgApiCalls.newOrEditPlan(planSlug === "new" ? null : planSlug, editObjectives),
      options,
    );
  };

  const _afterRenderFunction = function ($container, preparedData) {
    multiStepForm = new MultiStepForm("esg-plan", _getFormValidationErrors, _getDataReviewHtml, {
      saveCallback,
      updateCallback,
      createCallback,
      cancelCallback,
      isCompleted: PageController.getData("wizardCompleted"),
      initialStep: PageController.getData("wizardStep"),
    });

    multiStepForm.init($container, _getSteps(preparedData));
    $page = multiStepForm.getPage();

    Slider.init(_updateSumOfWeights, $page);
    MultipleSelect.init();

    submitted = false;
    initialFilterState = JSON.stringify(_getFilterData());

    smartFilters?.destroy();
    smartFilters = new SmartFilters(PropertiesFilters.getFilterConfigs());
    smartFilters.init($container);
    PropertiesFilters.setSmartFilters(smartFilters);

    SideBar.hideCollapsedSideBar();
    _loadListeners();
  };

  const _getSteps = function (preparedData) {
    const steps = [
      {
        title: "Plan Info",
        description: null,
        contentHtml: nunjucks.render("esg/planWizard/steps/planInfo.njk", preparedData),
      },
    ];

    if (editObjectives) {
      steps.push({
        title: "Environmental and Community Objectives",
        description:
          "The default analysis evenly weights the objectives below to rank properties suitable for your plan. Use the sliders below to adjust the weightings until they reflect the objectives of your plan.",
        contentHtml: nunjucks.render(
          "esg/planWizard/steps/environmentalAndCommunityObjectives.njk",
          preparedData,
        ),
      });
      steps.push({
        title: "Geographic Focus",
        description:
          "Use the filters below to refine the geographic focus of you plan to best meet your defined objectives.",
        contentHtml: nunjucks.render("esg/planWizard/steps/geographicFocus.njk", preparedData),
      });
      steps.push({
        title: "Properties Focus",
        description:
          "Use the filters below to focus on property characteristics that best meet the objectives of your plan.",
        contentHtml: nunjucks.render("esg/planWizard/steps/propertiesFocus.njk", preparedData),
      });
      steps.push({
        title: "Target Property Count",
        description:
          "The properties within your portfolio will be analyzed and ranked based on your defined objectives and the top ranking properties will be automatically selected for your consideration. Adjust the number of selected properties below. Don't worry you can always load more.",
        contentHtml: nunjucks.render("esg/planWizard/steps/targetPropertyCount.njk", preparedData),
      });
    }

    return steps;
  };

  const _getFilterData = function () {
    const data = {
      ...MultipleSelect.getData(),
      ...MinMax.getData(),
      ...ToggleCheckbox.getData(),
    };
    return {
      ...data,
      ejCommunity: data.ejOnly ? ["Yes"] : ["Yes", "No"],
    };
  };

  const _getPreparedData = function (data) {
    return {
      ..._convertNoneToAll(data),
      tenants: data.tenants.map((tenant) => String(tenant)),
      dropdownOptions: PageController.getDropdownOptions(false),
      isNewPlan: _isNewPlan(),
      watershedBasinsDisplay: getDisplayData(data, "huc6"),
      watershedSubbasinsDisplay: getDisplayData(data, "huc8"),
      programsDisplay: getDisplayData(data, "programs"),
      regionsDisplay: getDisplayData(data, "regions"),
      statesDisplay: getDisplayData(data, "states"),
      ownershipStatusDisplay: getDisplayData(data, "ownershipStatus"),
      tenantNames: getDisplayData(data, "tenants"),
      bmpRelationshipDisplay: getDisplayData(data, "bmpRelationship"),
      retrofitOpportunitiesDisplay: getDisplayData(data, "retrofitOpportunities"),
      siteComplexityDisplay: getDisplayData(data, "siteComplexity"),
      compensationStatusDisplay: getDisplayData(data, "compensationStatus"),
      propertyTypesDisplay: getDisplayData(data, "propertyTypes"),
      fundsDisplay: getDisplayData(data, "funds"),
      acquisitionPortfoliosDisplay: getDisplayData(data, "acquisitionPortfolios"),
      districtsDisplay: getDisplayData(data, "districts"),
      hiddenFields: EsgSettings.hiddenFields(),
      editObjectives: editObjectives,
      propertyCountConstrained: Math.min(data.propertyCount, data.targetPropertyCount),
    };
  };

  const multipleSelectColumns = [
    "regions",
    "states",
    "tenants",
    "huc6",
    "huc8",
    "acquisitionPortfolios",
    "propertyTypes",
    "funds",
    "districts",
    "ownershipStatus",
    "bmpRelationship",
    "retrofitOpportunities",
    "compensationStatus",
    "programs",
    "siteComplexity",
  ];

  const _convertNoneToAll = function (data) {
    multipleSelectColumns.forEach((column) => {
      if (!data[column]?.length) {
        data[column] = PropertiesFilters.getOptions(column);
      }
    });

    return data;
  };

  const _convertAllToNone = function (data) {
    multipleSelectColumns.forEach((column) => {
      if (data[column]?.length === PropertiesFilters.getOptions(column).length) {
        data[column] = [];
      }
    });

    return data;
  };

  const okToLeave = function () {
    return (Form.isFormEmpty("esg-plan") && !_filtersChanged()) || submitted;
  };

  const _filtersChanged = function () {
    return initialFilterState !== JSON.stringify(_getFilterData());
  };

  const _loadListeners = function () {
    $("#wizard-main-content")
      .off("blur", "input[name=target-property-count]", _defaultToTen)
      .on("blur", "input[name=target-property-count]", _defaultToTen);
  };

  const _defaultToTen = function () {
    const $field = $(this);
    if ((parseInt($field.val()) || 0) <= 0) {
      Form.setFieldValue("esg-plan", $field, 10);
    }
  };

  const _getDataReviewHtml = function () {
    let data = PageController.getData();
    if (!data) return;
    Tree.set("filters", _getFilterData());
    data = _getPreparedData({
      ...data,
      ...Form.getDataFromForm("esg-plan", false),
      propertyCount: PropertiesFilters.filterOffline(data.allProperties).length,
      wizardStep: multiStepForm.getLastVisitedStepNum(),
    });

    return nunjucks.render("esg/planWizard/planDataReview.njk", data);
  };

  const _getPlanName = function () {
    return (
      Form.getDataFromForm("esg-plan", false)?.planName ||
      PageController.getData("planName") ||
      "this plan"
    );
  };

  const _updateSumOfWeights = function () {
    const sumOfWeights = $page
      .find(".slider input[type='range']")
      .toArray()
      .reduce((sum, slider) => {
        return sum + Number($(slider).val());
      }, 0);
    const maxWidth = $("#weight").css("max-width");
    const width = sumOfWeights * parseInt(maxWidth);
    $page.find("#weight").css("width", width);
    const sumOfWeightsPercent = Math.round(sumOfWeights * 100);
    $page.find("#weight-label").text(sumOfWeightsPercent + "%");
    if (sumOfWeightsPercent < 25) {
      $page.find("#weight-label").addClass("outside").css("left", width);
    } else {
      $page.find("#weight-label").removeClass("outside").css("left", 0);
    }
    if (sumOfWeightsPercent > 100) {
      const exceededWidth = (sumOfWeights - 1) * parseInt(maxWidth);
      const exceededWidthPercent = sumOfWeightsPercent - 100;
      $page.find("#exceeded").css("width", exceededWidth);
      $page.find("#exceeded-label").text(exceededWidthPercent + "% exceeded");
      if (exceededWidthPercent < 65) {
        $page.find("#exceeded-label").addClass("outside").css("left", exceededWidth);
      } else {
        $page.find("#exceeded-label").removeClass("outside").css("left", 0);
      }
    } else {
      $page.find("#exceeded").css("width", 0);
      $page.find("#exceeded-label").text("");
    }
    if (sumOfWeightsPercent <= 100) {
      $page
        .find("#left-label")
        .show()
        .text(100 - sumOfWeightsPercent + "% left");
    } else {
      $page.find("#left-label").hide();
    }
  };

  const _getFormValidationErrors = function () {
    const errors = [];

    if ($("[name='plan-name']").val() === "") {
      errors.push({
        step: 0,
        message: "Please enter a plan name",
      });
    }
    if ($("#weight-label").text() !== "100%") {
      errors.push({
        step: 1,
        message: "The weights of your objectives must add up to 100%",
      });
    }

    return errors;
  };

  const afterSaveUrl = function (planData) {
    let url = `/impact-accounting/stormwater-plans/`;
    if (planData.wizardCompleted) {
      url += `${planData.id}/impact`;
    }
    return url;
  };

  const saveCallback = function () {
    const planName = _getPlanName();

    if (editObjectives) {
      MessageModal.showConfirmWarningModal(
        null,
        () => _submitPlanAndNavigate({ wizardCompleted: false }),
        "Go Back",
        "Save & Close",
        `<p>You have not completed all of the steps to create <b>${planName}</b>.</p><p>Click <b>Go Back</b> to complete the steps and create a draft plan.<br>Click <b>Save & Close</b> to save and complete later.</p>`,
      );
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        _savePlanInfo,
        "Go Back",
        "Save",
        `<p>Save plan information for <b>${planName}</b>?</p><p>Click <b>Go Back</b> to return to the data entry form.<br>Click <b>Save</b> to save the plan information.</p>`,
      );
    }
  };

  const updateCallback = function () {
    MessageModal.showConfirmWarningModal(
      null,
      () => _submitPlanAndNavigate(),
      "Go Back",
      "Recreate Plan",
      `Recreating the plan will unshelve properties and generate a new selection of properties based on specified weightings and filters.<br><br>Click <strong>Go Back</strong> to return to the data entry form.<br>Click <strong>Recreate Plan</strong> to generate a plan using the specified settings.`,
    );
  };

  const createCallback = function () {
    const planName = _getPlanName();

    MessageModal.showConfirmWarningModal(
      null,
      () => _submitPlanAndNavigate({ wizardCompleted: true }),
      "Go Back",
      "Create Plan",
      `<p>You have completed all steps to create <b>${planName}</b> and generate the draft impact based on your plan.</p><p>Click <b>Go Back</b> to return to the data entry form.<br>Click <b>Create Plan</b> to generate the draft plan and impacts.</p>`,
    );
  };

  const cancelCallback = function () {
    UrlRoutes.getPathPartByIndex(0) === "portfolio"
      ? UrlRoutes.go("portfolio")
      : UrlRoutes.go("stormwater-plans");
  };

  const _submitPlanAndNavigate = async function (options = {}) {
    const planData = await _submitPlan(options);
    UrlRoutes.navigate(afterSaveUrl(planData));
    return planData;
  };

  const _savePlanInfo = async function () {
    const planData = await _submitPlan();
    UrlRoutes.navigate(`/impact-accounting/stormwater-plans/${planData.id}/impact`);
  };

  const _submitPlan = async function (options = {}) {
    let planData;
    const inputData = {
      updates: {
        ...Form.getDataFromForm("esg-plan"),
        ...options,
        filters: _convertAllToNone(_getFilterData()),
        wizardStep: multiStepForm.getLastVisitedStepNum(),
      },
      editObjectives,
    };

    if (_isNewPlan()) {
      planData = await EsgApiCalls.createPlan(inputData);
    } else {
      const id = PageController.getData("id");
      planData = await EsgApiCalls.updatePlan({ id, ...inputData });
    }
    submitted = true;

    return planData;
  };

  const _isNewPlan = function () {
    return !PageController.getData("id");
  };

  const _getWatersheds = function (allWatersheds, hucLevel) {
    const constants = PageController.getConstants();
    if (constants && allWatersheds && allWatersheds.length) {
      return allWatersheds
        .filter((ws) => String(ws).length === hucLevel && constants["huc" + hucLevel][ws])
        .map((huc) => constants["huc" + hucLevel][huc]);
    }
    return [];
  };

  const getDisplayData = function (data, prop) {
    const constants = PageController.getConstants(prop);
    if (data[prop].length === Object.keys(constants).length) return [];
    if (typeof constants === "object" && !Array.isArray(constants)) {
      return Object.keys(constants)
        .filter((key) => data[prop]?.includes(Number(key)) || data[prop]?.includes(key || null))
        .map((value) => constants[value])
        .sort(PageController.sortWithNullAtEnd);
    } else {
      return constants
        .filter((value) => data[prop]?.includes(value))
        .map((value) => (value === null ? "No Data" : value))
        .sort(PageController.sortWithNullAtEnd);
    }
  };

  const _getMultiPartForm = function () {
    return multiStepForm;
  };

  return {
    render,
    okToLeave,
    getDisplayData,
    saveCallback,
    updateCallback,
    createCallback,
    cancelCallback,
    _defaultToTen,
    _getWatersheds,
    _getDataReviewHtml,
    _submitPlan,
    _getPlanName,
    _isNewPlan,
    _filtersChanged,
    _updateSumOfWeights,
    _getMultiPartForm,
  };
};

module.exports = PlanWizardController();

const EsgApiCalls = require("../esg/esgApiCalls");
const Form = require("../general/form");
const PageController = require("./pageController");
const UrlRoutes = require("../routes/urlRoutes");
const MessageModal = require("../modals/messageModal");
const Slider = require("../general/slider");
const MultipleSelect = require("../general/multipleSelect");
const MinMax = require("../general/minMax");
const ToggleCheckbox = require("../general/toggleCheckbox");
const SideBar = require("./sideBar");
const EsgSettings = require("./esgSettings");
const PropertiesFilters = require("./geoServer/propertiesFilters");
const Tree = require("../tree");
const MultiStepForm = require("../general/multiStepForm");
const SmartFilters = require("../mapFunctions/smartFilters");
