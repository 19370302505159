"use strict";

var RegionalSubmitReportPermitRequirementView = function () {
  var loadingPromise;

  var renderPermitRequirementTable = async function () {
    var data = await _getPermitRequirementData();
    _addPermitRequirementTableProps(data);
    var submitTable = null;
    submitTable = nunjucks.render("report/submit/regionalPermitRequirementTable.njk", data);
    $(".regional-report-table-container").html(submitTable);
    loadListeners();
  };

  var loadListeners = function () {
    $("#regional-submit-report").off("click", ".nav-link", _navLinkClick);
    $("#regional-submit-report").on("click", ".nav-link", _navLinkClick);
  };

  var _navLinkClick = function (e) {
    const PageFunctions = require("../pageFunctions");
    const dataView = $(e.currentTarget).closest("tr").data("view");
    const tab =
      Progeny.rootGroupIsRegulator() && Progeny.activeHasProgeny()
        ? "chart"
        : DataViewFunctions.getDefaultTab(dataView);
    PageFunctions.goToPage(tab, dataView);
  };

  var _addPermitRequirementTableProps = function (data) {
    if (Progeny.activeGroupIsRegulator) {
      data.showSubmittedPermitteeColumn = true;
    }

    var rootGroup = Tree.get("rootGroup");
    if (rootGroup.isRegion3Group) {
      data.showTwoPhases = true;
    }
  };

  var _getPermitRequirementData = async function () {
    const submitConfigData = SubmitReport.getSubmitConfigData();
    var category1 = submitConfigData.category1;
    var category2 = submitConfigData.category2;

    category1 = await _addProgressToSubmitTableCategory(category1);
    category2 = await _addProgressToSubmitTableCategory(category2);

    return { category1, category2 };
  };

  var _getOverviewKpiDataByYear = function (year) {
    const overviewKpi = Tree.get("regionalOverviewKpi", "data", year);
    return overviewKpi;
  };

  var _fetchOverviewKpiData = async function () {
    var currentSelectedYear = AnnualReports.getSelectedYear();
    const dataByYear = Tree.get("regionalOverviewKpi", "data", currentSelectedYear);
    if (!Tree.get("regionalOverviewKpi", "isFetching") && !dataByYear) {
      Tree.set(["regionalOverviewKpi", "isFetching"], true);
      loadingPromise = ReportApiCalls.getRegionalOverviewKpi(currentSelectedYear).then(
        function (data) {
          Tree.set(["regionalOverviewKpi", "data", currentSelectedYear], data);
          Tree.set(["regionalOverviewKpi", "isFetching"], false);
          return data;
        },
      );
    }
    return loadingPromise;
  };

  var _addProgressToSubmitTableCategory = async function (category) {
    await _fetchOverviewKpiData();

    return category.map(function (item) {
      const kpiData = _getOverviewKpiDataByYear(AnnualReports.getSelectedYear());

      if (!kpiData) {
        return item;
      }

      const kpiText = kpiData?.[item.dataProgram] || kpiData?.[Misc.camelToSnake(item.dataProgram)];
      if (kpiText) {
        item.progress = kpiText;
      }
      if (kpiData["submitted"] && kpiData["submitted"][item.dataName]) {
        item.phaseOne = kpiData["submitted"][item.dataName]["phaseOne"];
        item.phaseTwo = kpiData["submitted"][item.dataName]["phaseTwo"];
        item.linkSummaryCount = kpiData["submitted"][item.dataName]["linkSummaryCount"];

        if (item.linkSummaryEnabled && item.linkSummaryCount > 0) {
          item.progress = `${item.linkSummaryCount} Reports Linked`;
        }
      }
      return item;
    });
  };

  return {
    renderPermitRequirementTable,
  };
};

module.exports = RegionalSubmitReportPermitRequirementView();

const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const AnnualReports = require("../annualReports");
const DataViewFunctions = require("../dataViewFunctions");
const SubmitReport = require("../report/submitReport");
const Progeny = require("../../login/progeny");
const Misc = require("../misc");
