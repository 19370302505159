"use strict";

var SbmpProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var updateBmpIcon = function (properties, zoom) {
    return BmpFcsIcon.getIconMarker(
      properties.bmpScore,
      properties.bmpTypeObj.isCentralized,
      properties.bmpTypeObj.abbreviation,
      properties.isFcs,
      null,
      properties.planned,
      properties.fixedColor,
      zoom,
    );
  };

  var loadZoomListener = function (map, activeLayer) {
    const layer = activeLayer;
    map.on("zoomend", function () {
      RamMapFunctions.resizeLayerMarkers(layer, updateBmpIcon, map.getZoom());
    });
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "sbmpProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.sbmpProgressLayer && sameSpatialFilter && sameView && sameGroup) {
            RamMapFunctions.resizeLayerMarkers(
              mapLayers.sbmpProgressLayer,
              updateBmpIcon,
              map.getZoom(),
            );
            map.addLayer(mapLayers.sbmpProgressLayer);
            Clustering.setState("sbmpProgress");
          } else {
            const isFetching = Tree.get([
              "layers",
              Tree.get("mapId"),
              "sbmpProgress",
              "isFetching",
            ]);
            if (sameSpatialFilter && sameView && isFetching) {
              // Temp solution to prevent laoding twice from dataView and filters update
              return;
            }
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.sbmpProgressLayer)) {
            map.removeLayer(mapLayers.sbmpProgressLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    if (!filters.catchments.length && filters.searchString) {
      Tree.set(["layers", mapId, "sbmpProgress", "data"], []);
    } else {
      Tree.set(["layers", mapId, "sbmpProgress", "isFetching"], true);
      ReportApiCalls.getSbmpProgress(filters, function (data) {
        data = data.filter(function (bmp) {
          return bmp.isBmp;
        });

        Tree.set(["layers", mapId, "sbmpProgress", "isFetching"], false);
        Tree.set(["layers", mapId, "sbmpProgress", "data"], data);
        Tree.set(["layers", mapId, "sbmpProgress", "spatialView"], Tree.get("spatialView", mapId));
      });
    }
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "sbmpProgress", function (data) {
      if (Filters.spatialFilterIsSet()) {
        data = spatiallyFilterSbmpData(data);
      }
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.sbmpProgressLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
      Clustering.setState("sbmpProgress");
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.sbmpProgressLayer) {
      map.removeLayer(mapLayers.sbmpProgressLayer);
    }
    SbmpProgressPopup.loadDomListeners();
    mapLayers.sbmpProgressLayer = createLayer(data, map);
    mapLayers.sbmpProgressLayer.addTo(map);
    return mapLayers.sbmpProgressLayer;
  };

  var createLayer = function (data, map) {
    var layer = L.featureGroup();

    L.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;

        const marker = L.marker(latlng, {
          icon: BmpFcsIcon.getIconMarker(
            properties.bmpScore,
            properties.bmpTypeObj.isCentralized,
            properties.bmpTypeObj.abbreviation,
            properties.isFcs,
            null,
            properties.planned,
            properties.fixedColor,
            map.getZoom(),
          ),
        });
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => SbmpProgressPopup.getSbmpProgressPopupHtml(feature.properties),
          SbmpProgressPopup.getPopupOptions(),
        );
      },
    }).addTo(layer);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Only watch for specific filters since other filters updates are already handled in treeUpdates
    // @TODO: Investigate why listening to filters update causes an infinite loop while searching
    Tree.watch({
      status: ["filters", "status"],
      ownership: ["filters", "ownership"],
      structureType: ["filters", "structureType"],
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        Tree.get("dataView") === "sbmpView" &&
        MapFunctions.mapHasLayer(map, mapLayers.sbmpProgressLayer) &&
        Tree.get("layers", mapId, "sbmpProgress", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var spatiallyFilterSbmpData = function (data) {
    const filters = Tree.get("filters");
    return data.filter((bmp) => {
      return (
        filters.receivingWaters.includes(bmp.catchment["drains_to_rw"]) ||
        filters.receivingWaters.includes(bmp.catchment["drains_to_c"]) ||
        filters.catchments.includes(bmp.catchment["catchid"])
      );
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    spatiallyFilterSbmpData,
    createLayer,
    updateBmpIcon,
    loadZoomListener,
  };
};

module.exports = SbmpProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const BmpFcsIcon = require("./bmpFcsIcon");
const Table = require("../mapFunctions/table");
const Filters = require("../mapFunctions/filters");
const Clustering = require("../mapFunctions/clustering");
const SbmpProgressPopup = require("./sbmpProgressPopup");
const RamMapFunctions = require("../../mapFunctions/mapFunctions");
