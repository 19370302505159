"use strict";

const CsvUpload = function () {
  var $mount;
  var dropZone;
  var layer;
  var providedFile;
  var mapLayersToRefresh;
  var exportEnabled;

  var setTitle = function () {
    throw "Configurable function not set";
  };

  var setImportPromiseHandler = function () {
    throw "Configurable function not set";
  };

  var init = function (layerToUpload, mapLayers, setImportPromiseFn, setTitleFn, canExport) {
    setTitle = setTitleFn;
    mapLayersToRefresh = mapLayers;
    setImportPromiseHandler = setImportPromiseFn;
    layer = layerToUpload;
    exportEnabled = canExport;
  };

  var show = function ($renderMount, toggleImportEnabledFn) {
    $mount = $renderMount;

    const templateUrl = DataImportConfig.getTemplateUrl(layer);

    const props = {
      hasTemplate: !!templateUrl,
      templateUrl: templateUrl,
      exportEnabled: exportEnabled,
    };
    $mount.html(nunjucks.render("dataImport/csvUpload.njk", props));
    initializeDropZone();
    initializeDataDownloader();
    DataImportPreview.init(layer, $renderMount.find("#import-preview"), toggleImportEnabledFn);
    DataImportPreview.loadPreviewListeners();
  };

  var setProvidedFile = function (file) {
    if (file.name === undefined) throw "Provided file unexpectedly has no name.";
    providedFile = file;
  };

  var validateFile = async function (file) {
    hideErrorMessage();
    var ext = getFilenameExtension(file.name);
    switch (ext) {
      case "csv":
        var result = await parseCsv(file);
        var isValid = validateParsedResult(file, result);
        if (isValid) {
          return result;
        }
        break;
      default:
        showErrorMessages(["Unrecognized file format. Please upload a CSV file."]);
    }

    return null;
  };

  var parseCsv = function (file) {
    return new Promise(function (resolve) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: resolve,
      });
    });
  };

  var importValidatedFile = async function () {
    return setImportPromiseHandler(
      ApiCalls.importFromCsv(providedFile, DataImportPreview.getFieldMapping(), layer)
        .then((importResult) => {
          $mount.html(
            nunjucks.render("dataImport/done.njk", {
              created: importResult.numCreated,
              updated: importResult.numUpdated,
            }),
          );
          mapLayersToRefresh.forEach((layer) =>
            LayerFunctions.getLayerModule(layer).invalidateLayerData?.(),
          );
          return;
        })
        .catch((err) => {
          showErrorMessages([
            `There was a server error while processing your import, please try again later or contact us at <a href="mailto:customersuccess@2ndnaturewater.com">customersuccess@2ndnaturewater.com</a>.`,
          ]);
          throw err;
        }),
    );
  };

  var initializeDropZone = function () {
    dropZone = Dropzone($mount);
    dropZone.setFileZoneDropCallback(dropzoneCallback);
    dropZone.setFileSizeWarning(function () {
      showErrorMessages([`File is too large, imports are limited to 8MB.`]);
    });
    dropZone.setHiddenInputChangeCallback(dropzoneCallback);
  };

  var dropzoneCallback = function () {
    const files = dropZone.getFiles();
    const onlyFile = files.slice(-1)[0];
    _handleUploadedCsv(onlyFile);
  };

  var _handleUploadedCsv = async function (file) {
    const result = await validateFile(file);
    if (result === null) {
      return;
    }
    setProvidedFile(file);
    sendParsedResultToPreview(file, result);
  };

  var initializeDataDownloader = function () {
    $mount.find(".download-data").off("click", getDataForReimport);
    $mount.find(".download-data").on("click", getDataForReimport);
  };

  var getDataForReimport = function () {
    return ApiCalls.getBulkUpdateData(layer);
  };

  var hideErrorMessage = function () {
    $mount.find(".error-alert").hide();
  };

  var showParsingErrorMessages = function (parsingErrors) {
    showErrorMessages(parsingErrors.map((error) => `${error.message} (${error.row})`));
  };

  var showErrorMessages = function (messages) {
    $mount
      .find(".error-alert")
      .html("<strong>Error!</strong> <ul><li>" + messages.join("</li><li>") + "</li></ul>")
      .fadeIn("fast");
  };

  var getFilenameExtension = function (filename) {
    var dotIndex = filename.lastIndexOf(".");
    if (dotIndex > -1) return filename.slice(dotIndex + 1).toLowerCase();
    return "";
  };

  var validateParsedResult = function (file, result) {
    if (file.name === undefined) {
      throw "Provided file unexpectedly has no name.";
    } else if (result.errors && result.errors.length) {
      showParsingErrorMessages(result.errors);
      return false;
    } else if (result.data.length >= DataImportConfig.importRowLimit) {
      showErrorMessages([
        `File has too many rows, imports are limited to ${DataImportConfig.importRowLimit - 1}.`,
      ]);
      return false;
    }

    return true;
  };

  var sendParsedResultToPreview = function (file, result) {
    toggleDropzone(false);
    setTitle(file.name, result.data.length);
    return DataImportPreview.loadInitialImportPreview(result.data, result.meta.fields, file, layer);
  };

  var toggleDropzone = function (toggle) {
    $mount.find(".drop-zone").toggle(toggle);
  };

  var resetDropzone = function () {
    dropZone.reset();
    $mount.find(".drop-zone").html(`<p>Drag &amp; Drop files here</p>`);
  };

  var resetImportPreview = function () {
    toggleDropzone(true);
    resetDropzone();
    DataImportPreview.resetImportPreview();
  };

  return {
    init,
    show,
    importValidatedFile,
    hideErrorMessage,
    showErrorMessages,
    validateFile,
    setProvidedFile,
    resetImportPreview,
    _handleUploadedCsv,
  };
};

module.exports = CsvUpload();

const ApiCalls = require("../apiCalls");
const DataImportConfig = require("../config/dataImportConfig");
const DataImportPreview = require("./dataImportPreview");
const Dropzone = require("../dropzone");
const LayerFunctions = require("../layerFunctions");
const Papa = require("papaparse");
