"use strict";

const MuniCatchBasinPopup = function () {
  var getPopupHtml = function (properties) {
    properties.userPermissions = UserPermissions.getAllPermissions();
    properties.popupColor = MuniCatchBasinIcon.getIconColor(
      properties.score,
      properties.routineMaintenanceDue,
    );
    properties.readOnly = !properties?.permissions?.canBeEdited;
    addInspectionPermissions(properties, properties);
    return PopupContent.getLayerPopup(properties, "muniCatchBasin");
  };

  var addInspectionPermissions = function (data, props) {
    props.displayAddInspectionButton =
      SharedDataFunctions.isPhaseOrLater(data.phase, "completed") &&
      data.permissions?.canCreateRoutineInspection &&
      data.bmpTypeName !== "Unknown" &&
      !(Tree.get("dataView") === "bmp");
    props.canBeDeleted = data.permissions?.canBeDeleted;

    return props;
  };

  var openFactSheet = function () {
    FactSheet._toggleDataListIcon();
    const projectId = $(this).parents(".popup-bmp").data("id");
    Tree.set(["factSheet", "previousDataSort"], Tree.get(["table", "dataSort"]));
    Filters.setDataSort("projectIdentifier");
    Filters.toggleFilterButtons();

    CatchBasinFactSheetController.initFactSheet(projectId);
    Analytics.sendPopupEvent("view_details", "catchBasin");
  };

  var onPopupOpen = async function (properties) {
    var onPopupDelete = function () {
      deleteCatchBasin(id, data);
    };
    const id = properties.id || properties.idbmp;
    var data = await getPopupData(id);
    const html = getPopupHtml(data);
    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    handleDeleteButtons($popup, onPopupDelete);
    handleDownloadReport($popup);
    loadConditionBars(data);
    loadPopupPhotosAndFiles(id);
    handleFactSheetListeners();
  };

  var handleFactSheetListeners = function () {
    $(".popup-show-files").addClass("hidden");
    $("#mapContent").off("click", ".popup-fact-sheet-button");
    $("#mapContent").on("click", ".popup-fact-sheet-button", openFactSheet);
  };

  var handleDeleteButtons = function ($popup, onPopupDelete) {
    const deleteButton = $(".popup-delete-button");
    $popup.off("click", ".popup-delete-button", onPopupDelete);
    $popup.on("click", ".popup-delete-button", onPopupDelete);
    if (deleteButton.hasClass("popup-delete-bmp") || deleteButton.hasClass("popup-delete-fcs")) {
      deleteButton.removeClass("popup-delete-bmp");
      deleteButton.removeClass("popup-delete-fcs");
    }
  };

  var handleDownloadReport = function ($popup) {
    $popup.off("click", ".download-report", MuniBmpPopup.onDownloadReport);
    $popup.on("click", ".download-report", MuniBmpPopup.onDownloadReport);
  };

  var deleteCatchBasin = function (idBmp, popupData) {
    const popupCloseButn = $(".leaflet-popup-close-button");

    if (popupData?.isFcs) {
      MessageModal.showThreeButtonConfirmWarningModal(
        `This structure: ${popupData?.bmpName} is both an FCS and a catch basin. To delete it as an FCS, but keep it as a catch basin in 2NFORM, click Delete FCS. Note, this will also delete the selected treated area polygons. To permanently delete this structure from your 2NFORM data as both an FCS and catch basin, click Delete Asset. To return to the form without deleting, click Cancel.`,
        function () {
          BmpFunctions.deleteFcs(idBmp);
        },
        function () {
          ApiCalls.deleteBmp(idBmp).then(function () {
            _reloadAndClosePopup(popupCloseButn);
          });
        },
        "Cancel",
        "Delete Fcs",
        "Delete Asset",
      );
    } else {
      MessageModal.showConfirmWarningModal(
        `This will permanently delete catch basin ${popupData?.bmpName} from your 2NFORM data. Click Cancel to return to the form without deleting or Delete to permanently delete.`,
        function () {
          ApiCalls.deleteBmp(idBmp).then(function () {
            _reloadAndClosePopup(popupCloseButn);
          });
        },
        "Cancel",
        "Delete",
      );
    }
    Analytics.sendPopupEvent("delete_asset", "catchBasin");
  };

  var _reloadAndClosePopup = function (popupCloseButn) {
    MainMap.reloadBmpFcsLayer();
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var loadPopupPhotosAndFiles = function (id) {
    PopupPhotos.load("bmp", id);
    PopupFiles.loadFilenamesAndRender(id);
  };

  var getPopupData = async function (id) {
    const popupData = await ApiCalls.getMuniCatchBasinPopup(id);

    MuniBmpPopup.setLegacyAssessmentHistory(popupData.history);

    Tree.set("currentBmpFcs", popupData);
    return popupData;
  };

  var loadConditionBars = function (data) {
    const history = data?.history ?? [];
    PopupContent.setPopupConditionBar(
      $(".tr-muni-catch-basin-score"),
      history,
      (datum) => datum.score,
    );
  };

  return {
    getPopupHtml,
    onPopupOpen,
    addInspectionPermissions,
    getPopupData,
    loadConditionBars,
  };
};

module.exports = MuniCatchBasinPopup();

const ApiCalls = require("../apiCalls");
const MuniCatchBasinIcon = require("./muniCatchBasinIcon");
const PopupContent = require("../mapFunctions/popupContent");
const PopupFiles = require("../mapFunctions/popupFiles");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const SharedDataFunctions = require("../general/sharedDataFunctions");
const Tree = require("../tree");
const CatchBasinFactSheetController = require("./catchBasinFactSheetController");
const Filters = require("../mapFunctions/filters");
const FactSheet = require("../general/factSheet");
const MessageModal = require("../modals/messageModal");
const BmpFunctions = require("../modals/bmpFunctions");
const MainMap = require("../mapFunctions/mainMap");
const UserPermissions = require("../login/userPermissions");
const Analytics = require("../general/analytics");
const MuniBmpPopup = require("./bmp/muniBmpPopup");
