"use strict";

const Slider = function () {
  let $page;
  let onInputFunction;

  const init = function (onInput = () => {}, $selector) {
    $page = $selector;
    _loadListeners();
    _updatePaintedSliders();
    _roundEditableInputs();
    onInputFunction = onInput;
    onInputFunction();
  };

  const _loadListeners = function () {
    $page
      .find(".slider")
      .off("click, focus", ".editable-value", _handleEditableValueClick)
      .on("click, focus", ".editable-value", _handleEditableValueClick)
      .off("input", "input", _handleSliderInput)
      .on("input", "input", _handleSliderInput)
      .off("blur", ".editable-value input", _handleEditableValueBlur)
      .on("blur", ".editable-value input", _handleEditableValueBlur);
  };

  const _handleEditableValueClick = function () {
    $(this).find("input").select();
  };

  const _handleEditableValueBlur = function () {
    const $input = $(this);
    const $slider = $input.closest(".slider");
    const $rangeInput = $slider.find("input[type='range']");
    const percentage = Math.round($rangeInput.val() * 100);
    $input.val(percentage);
  };

  const _roundEditableInputs = function () {
    $page.find(".slider .editable-value input").each(function () {
      const $this = $(this);
      $this.val(Math.round($this.val()));
    });
  };

  const _handleSliderInput = function () {
    const $input = $(this);
    let value, percentage, $rangeInput, $editableValueInput;
    if ($input.attr("type") === "range") {
      value = $input.val();
      percentage = Math.round(value * 100);
      $rangeInput = $input;
      $editableValueInput = $input.closest(".slider").find(".editable-value input");
    } else {
      percentage = Math.min(parseInt($input.val()) || 0, 100);
      value = percentage / 100;
      $rangeInput = $input.closest(".slider").find("input[type='range']");
      $editableValueInput = $input;
    }
    $rangeInput.val(value);
    const formKey = $input.closest("form").data("form-key");
    if (formKey) {
      Form.setFieldValue(formKey, $rangeInput, $rangeInput.val());
    }
    if (percentage >= 10) percentage = Math.round($rangeInput.val() * 100);
    $editableValueInput.val(percentage);
    onInputFunction();
    _updatePaintedSliders($rangeInput);
  };

  const _updatePaintedSliders = function () {
    $page.find(".slider input[type='range']").each(function () {
      const $slider = $(this);
      const value = $slider.val();
      const percentage = Math.round(value * 100);
      const $paintedSlider = $slider.prev(".painted-slider");
      const $fill = $paintedSlider.find(".fill");
      const $thumb = $paintedSlider.find(".thumb");

      $fill.css("width", percentage + "%");
      $thumb.css({
        left: percentage + "%",
        transform: "translateX(-" + percentage + "%)",
      });
    });
  };

  return {
    init,
    _updatePaintedSliders,
    _handleEditableValueBlur,
    _handleSliderInput,
  };
};

module.exports = Slider();

const Form = require("./form");
