"use strict";

const FlowRoutingLayer = function () {
  const ApiCalls = require("../apiCalls");
  const MapLayer = require("../mapFunctions/mapLayer");

  const stringKey = "flowRouting";
  const layerKey = "flowRoutingLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    FlowRouting.loadToggledListenerForMap(map, mapLayers, mapId);
    FlowRouting.loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (mapId === "main") {
      FlowRouting.loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  var createFlowRoutingMarker = function (latlng, data) {
    return OutfallsIcon.getFlowRoutingMarker(
      latlng,
      data.rotationAzimuth,
      data.priority,
      data.condition,
      data.outfallInspectionIsDue,
    );
  };

  var getFlowRoutingPopupHtml = function (data) {
    return nunjucks.render("popups/flowRoutingPopup.njk", {
      featureProperties: data,
    });
  };

  const FlowRouting = new MapLayer(stringKey, layerKey, ApiCalls.getFlowRouting, {
    createMarkerFunction: createFlowRoutingMarker,
    getPopupHtmlFunction: getFlowRoutingPopupHtml,
    hideWhenZoomedOut: true,
  });

  return {
    ...FlowRouting,
    loadLayerListenersForMap,
  };
};

module.exports = FlowRoutingLayer();

const OutfallsIcon = require("./outfallsIcon");
