"use strict";

const RoutineMaintenanceTasks = function () {
  const stringKey = "maintenance-tasks";
  const tabHeader = "Maintenance";
  var $page;

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    RoutineMaintenanceFormTable.loadListeners($page);
  };

  var unloadListeners = function () {
    RoutineMaintenanceFormTable.unloadListeners($page);
  };

  var getProps = function () {
    return {
      trashRemovalVolumeUnitOptions: RoutineMaintenanceConstants.trashRemovalVolumeUnitOptions,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = RoutineMaintenanceTasks();

const RoutineMaintenanceConstants = require("../routineMaintenanceConstants");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
