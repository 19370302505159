"use strict";

const ReportGeoServerMapLayer = function (layerName, layerKey, createMarkerFunction = null) {
  const layerConfig = LayerFunctions.getLayerConfig(layerName);
  const wfsLayerKey = `${layerKey}Wfs`;
  const wmsLayerKey = `${layerKey}Wms`;
  var wfsMarkers;
  var superclusterIndex;
  var superclusterReady = false;
  var isWfsLayerFetching = false;

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(mapId, layerName, function (isEnabled, sameSpatialFilter) {
      var currentMapLayer = mapLayers[layerKey];
      if (isEnabled) {
        enableLayer(map, mapLayers, mapId, currentMapLayer, sameSpatialFilter);
      } else {
        disableLayer(map, mapLayers, mapId, currentMapLayer);
      }
    });

    if (!sessionStorage.getItem("geoServerLayerType")) {
      sessionStorage.setItem("geoServerLayerType", "WMS");
    }

    $("body").on("change", "input[name='super-cluster']", async function () {
      if (this.checked) {
        sessionStorage.setItem("geoServerLayerType", "WFS");
      } else {
        sessionStorage.setItem("geoServerLayerType", "WMS");
      }
      await rerenderGeoServerLayer(map, mapLayers, mapId);
    });
  };

  var enableLayer = function (map, mapLayers, mapId, currentMapLayer, sameSpatialFilter) {
    if (!layerConfig?.enableCatchmentLayer) {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").addClass("esri-dynamic-map-layer");
    }

    if (!Tree.get("enabledGeoServerLayers").includes(layerName)) {
      Tree.select("enabledGeoServerLayers").push(layerName);
    }

    loadGeoServerLayer(map, mapLayers, mapId, { refreshData: false });
  };

  var disableLayer = function (map, mapLayers, mapId, currentMapLayer) {
    // handled in reportMap.js
  };

  var invalidateLayerData = async function (map, mapLayers, mapId, { loadingScreen = true } = {}) {
    const isEnabled = Tree.get("layers", layerName, "isEnabled");

    Tree.set(["layers", layerName, "data"], null);

    if (isEnabled) {
      await loadGeoServerLayer(map, mapLayers, mapId, { loadingScreen });
    } else {
      MainMap.invalidateLayerByName(layerName);
    }
  };

  var loadGeoServerLayer = async function (
    map,
    mapLayers,
    mapId,
    { refreshData = true, loadingScreen = true } = {},
  ) {
    if (isWfsLayerFetching) return;
    const noWfsLayer = LayerFunctions.getLayerConfig(layerName)["noWfsLayer"];

    if (!noWfsLayer) {
      mapLayers[wfsLayerKey] = await createWfsLayer(map, mapLayers, refreshData, loadingScreen);
    }

    rerenderGeoServerLayer(map, mapLayers, mapId);
    if (refreshData) {
      GeoServerLayerFunctions.openGeoServerPopupByLayerName(layerName);
      if (mapLayers[wfsLayerKey]) {
        reopenPopupLayers(mapLayers[wfsLayerKey]);
      }
    }
  };

  var rerenderGeoServerLayer = function (map, mapLayers, mapId) {
    const isGisDataView = true;
    const hasAssociatedG2Layer = true;
    const clusteringEnabled = true;
    removeWmsLayer(map, mapLayers);

    if (isGisDataView || hasAssociatedG2Layer) {
      if (sessionStorage.getItem("geoServerLayerType") === "WFS" && clusteringEnabled) {
        mapLayers[layerKey] = mapLayers[wfsLayerKey];
      } else {
        removeWfsLayer(map, mapLayers);
        addWmsLayer(mapLayers);
        mapLayers[wmsLayerKey] = mapLayers[layerKey];
      }
    } else {
      addWmsLayer(mapLayers);
    }

    if (mapLayers[layerKey] && Tree.get("layers", mapId, layerName)) {
      mapLayers[layerKey].addTo(map);
    }
    // const tableExpanded = Tree.get("table", "expanded");
    // if (!tableExpanded.length) {
    //   Table.render();
    // }
  };

  var removeWfsLayer = function (map, mapLayers) {
    const layer = mapLayers?.[wfsLayerKey];

    if (MapFunctions.mapHasLayer(map, layer)) {
      map.removeLayer(layer);
    }
  };

  var removeWmsLayer = function (map, mapLayers) {
    const currentMapLayer = mapLayers?.[wmsLayerKey];

    if (MapFunctions.mapHasLayer(map, currentMapLayer)) {
      map.removeLayer(currentMapLayer);
    }
  };

  var addWmsLayer = function (mapLayers) {
    mapLayers[layerKey] = GeoServerLayerFunctions.getWmsLayer(layerName);
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers, mapId) {
    Tree.select("filters").on("update", async function () {
      if (Tree.get("layers", mapId, layerName)) {
        await loadGeoServerLayer(map, mapLayers, mapId, { refreshData: false });
      }
    });
  };

  var createWfsLayer = async function (map, mapLayers, refreshData, loadingScreen) {
    if (MapFunctions.mapHasLayer(map, wfsMarkers)) {
      map.removeLayer(wfsMarkers);
    }
    const popupHtmlFunction = GeoServerLayerFunctions.getPopupHtmlFunction(layerName);
    _setWfsMarkers(
      L.geoJSON(null, {
        pointToLayer: createClusterIcon,
        onEachFeature: function (feature, layer) {
          if (!feature.properties.cluster) {
            layer.bindPopup(
              () => popupHtmlFunction(structuredClone(feature.properties)),
              Layers.getPopupOptions(),
            );
          }
        },
      }),
    ).addTo(map);

    map.off("moveend", triggerSuperclusterLayerUpdate);
    map.on("moveend", triggerSuperclusterLayerUpdate);

    map.off("popupclose", onPopupClose);
    map.on("popupclose", onPopupClose);

    wfsMarkers.off("click");
    wfsMarkers.on("click", function (e) {
      var clusterId = e.layer.feature.properties.cluster_id;
      var center = e.latlng;
      var expansionZoom;
      if (clusterId) {
        expansionZoom = superclusterIndex.getClusterExpansionZoom(clusterId);
        map.flyTo(center, expansionZoom);
      }
    });

    _setSuperclusterReady(false);
    var allFeatures = await loadWfsLayerData(mapLayers, refreshData, loadingScreen);

    allFeatures = allFeatures.map(function (data) {
      return {
        type: "Feature",
        geometry: data.geometry,
        properties: data,
      };
    });

    superclusterIndex = new Supercluster({
      radius: 100,
      maxZoom: 16,
      minPoints: 5,
    }).load(allFeatures);
    _setSuperclusterReady(true);
    _updateSuperclusterLayer(map);
    loadPopupEventListeners(wfsMarkers);
    const searchString = Tree.get("filters", "searchString");
    if (
      Tree.get("layers", "report", "catchments", "isEnabled") &&
      !Tree.get("layers", "report", "catchments", "isFetching") &&
      !searchString
    ) {
      Table.render();
    }

    return wfsMarkers;
  };

  var triggerSuperclusterLayerUpdate = function (event) {
    _updateSuperclusterLayer(event.target);
  };

  var _updateSuperclusterLayer = function (map) {
    if (!superclusterReady) return;

    var bounds = map.getBounds();
    var bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
    var zoom = map.getZoom();
    var clusters = superclusterIndex.getClusters(bbox, zoom);

    clearLayersWithoutOpenPopup(wfsMarkers);
    wfsMarkers.addData(clusters);
    wfsMarkers.isSuperClusterLayer = true;
  };

  var clearLayersWithoutOpenPopup = function (wfsMarkers) {
    const popup = null;
    for (const layer of wfsMarkers.getLayers()) {
      if (!layer.isPopupOpen()) {
        wfsMarkers.removeLayer(layer);
      }
    }

    return popup;
  };

  var reopenPopupLayers = function (wfsMarkers) {
    for (const layer of wfsMarkers.getLayers()) {
      if (layer.isPopupOpen()) {
        layer.closePopup();
        layer.openPopup();
      }
    }
  };

  var createClusterIcon = function (feature, latlng) {
    if (!feature.properties.cluster) {
      return createMarkerFunction(feature, latlng);
    } else {
      const count = feature.properties.point_count;
      const size = count < 100 ? "small" : count < 1000 ? "medium" : "large";
      const icon = L.divIcon({
        html: "<div><span>" + feature.properties.point_count_abbreviated + "</span></div>",
        className: "marker-cluster marker-cluster-" + size,
        iconSize: L.point(40, 40),
      });
      return L.marker(latlng, {
        icon: icon,
      });
    }
  };

  var loadWfsLayerData = async function (mapLayers, refreshData, loadingScreen) {
    Tree.set(["layers", "report", layerName, "isFetching"], true);
    isWfsLayerFetching = true;

    const resource = ResourceController.get(layerName);
    var data = [];

    if (resource) {
      data = await resource.getAll({
        filters: null,
        refreshData: refreshData || Tree.get("layers", "report", layerName) === null,
        loadingScreen,
      });
      data = structuredClone(data);
    }

    setTreeLayerData(data);

    isWfsLayerFetching = false;

    return data;
  };

  var setTreeLayerData = function (data) {
    Tree.set(["layers", "report", layerName, "isFetching"], false);
    Tree.set(["layers", "report", layerName], data);
  };

  var onPopupClose = function () {
    GeoServerLayerFunctions.clearOpenPopupsByLayerName(layerName);
  };

  var _setWfsMarkers = function (newWfsMarkers) {
    wfsMarkers = newWfsMarkers;
    return wfsMarkers;
  };

  var _setSuperclusterReady = function (newSuperclusterReady) {
    superclusterReady = newSuperclusterReady;
    return superclusterReady;
  };

  var _setSuperclusterIndex = function (newSuperclusterIndex) {
    superclusterIndex = newSuperclusterIndex;
    return superclusterIndex;
  };

  var loadPopupEventListeners = function (layer) {
    const onPopupOpenFunction = GeoServerLayerFunctions.getOnPopupOpenFunction(layerName);

    if (!onPopupOpenFunction) {
      return;
    }

    var onPopupOpen = function (e) {
      onPopupOpenFunction(e.layer.feature.properties, layerName);
    };

    layer.off("popupopen", onPopupOpen);
    layer.on("popupopen", onPopupOpen);
    layer.off("popupclose", _onPopupClose);
    layer.on("popupclose", _onPopupClose);
  };

  var _onPopupClose = function (e) {
    mapFunctions.toggleLayerInFront(e.layer, false);
  };

  return {
    removeWmsLayer,
    removeWfsLayer,
    loadGeoServerLayer,
    loadToggledListenerForMap,
    loadTreeUpdateListenersForMap,
    invalidateLayerData,
    _setWfsMarkers,
    _setSuperclusterReady,
    _setSuperclusterIndex,
    _updateSuperclusterLayer,
  };
};

module.exports = ReportGeoServerMapLayer;

const MapFunctions = require("../../mapFunctions/mapFunctions");
const LayerFunctions = require("../../mapFunctions/layerFunctions");
const GeoServerLayerFunctions = require("../../../mapFunctions/geoServerLayerFunctions");
const Tree = require("../../../tree");
const ResourceController = require("../../../offline/resourceController");
const Supercluster = require("supercluster");
const Table = require("../../mapFunctions/table");
const Layers = require("../../../mapFunctions/layers");
const mapFunctions = require("../../../mapFunctions/mapFunctions");
const MainMap = require("../../../mapFunctions/mainMap");
