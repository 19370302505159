"use strict";

const ConstructionProjectAreaLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (mapId === "main") {
      loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");
    MapFunctions.whenLayerToggled(
      "constructionProjectArea",
      mapId,
      function (isEnabled, sameSpatialFilter) {
        if (isEnabled) {
          if (
            mapLayers.constructionProjectAreaLayer &&
            (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
          ) {
            map.addLayer(mapLayers.constructionProjectAreaLayer);
          } else {
            loadLayer(mapId);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.constructionProjectAreaLayer)) {
            map.removeLayer(mapLayers.constructionProjectAreaLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");
    MapFunctions.whenLayerDataUpdated("constructionProjectArea", mapId, function (data) {
      mapLayers.constructionProjectAreaLayer = MapFunctions.addConstructionProjectAreaLayer(
        map,
        mapLayers.constructionProjectAreaLayer,
        data,
      );
    });
  };

  // @TODO: Merge this with other layer filters update listeners
  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    const MapFunctions = require("../mapFunctions/mapFunctions");
    Tree.select("filters").on("update", function () {
      if (MapFunctions.mapHasLayer(map, mapLayers.constructionProjectAreaLayer)) {
        loadLayer();
      }
    });
  };

  var getStyle = function (feature) {
    const color = ConstructionProjectStyles.getColorHex(feature.properties);
    return {
      color: color,
      fillOpacity: 0.1,
      fillColor: color,
      weight: 3,
    };
  };

  var loadLayer = function (mapId) {
    var filters = Actions.getFiltersByMapId(mapId);
    var dataPath = Actions.getLayerDataPathByMapId(mapId);

    Tree.set(["layers", "constructionProjectArea", "isFetching"], true);

    ApiCalls.getConstructionProjectAreas(filters, function (data) {
      if (data) {
        data.forEach((project) => {
          project.geom = project.projectAreaGeom;
        });
      }
      data = CleanData.cleanGeoJson(data);
      Tree.set(["layers", "constructionProjectArea", dataPath], data);
      Tree.set(["layers", "constructionProjectArea", "isFetching"], false);
    });
  };

  var invalidateLayer = function () {
    const isEnabled = Tree.get("layers", "constructionProjectArea", "isEnabled");

    if (isEnabled) {
      loadLayer();
    } else {
      const mapLayers = MainMap.getMapLayers();
      mapLayers.constructionProjectAreaLayer = null;
    }
  };

  return {
    loadLayerListenersForMap,
    getStyle,
    loadLayer,
    invalidateLayer,
  };
};

module.exports = ConstructionProjectAreaLayer();

const ConstructionProjectStyles = require("./constructionProjectStyles");
const Actions = require("../actions");
const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const CleanData = require("../mapFunctions/cleanData");
const MainMap = require("../mapFunctions/mainMap");
