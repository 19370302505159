"use strict";

function BrowserWarning() {
  const closePromises = [];
  let allClosedPromise = null;
  let canClose = true;

  function init() {
    const $document = $(document);

    $document.unbind("ajaxSend", ajaxSend);
    $document.bind("ajaxSend", ajaxSend);

    window.addEventListener("beforeunload", function (e) {
      if (!_getCanClose()) {
        e.preventDefault();
        e.returnValue = "";
      }
    });
  }

  function ajaxSend(e, xhr, options) {
    if (options.type === "POST") {
      warnOnCloseWhile(Network.asPromise(xhr));
    }
  }

  function updateCloseStatus() {
    if (allClosedPromise) {
      return allClosedPromise;
    }

    allClosedPromise = Promise.allSettled(closePromises).finally(function () {
      allClosedPromise = null;
      canClose = true;
    });

    return allClosedPromise;
  }

  function warnOnCloseWhile(promise) {
    canClose = false;
    closePromises.push(promise);
    return promise.finally(updateCloseStatus);
  }

  function _getCanClose() {
    return canClose;
  }

  function confirmCanClose(promptAction) {
    if (_getCanClose()) {
      return true;
    }

    canClose = window.confirm(
      `Do you really want to ${promptAction}? Changes you made may not be saved.`,
    );
    return canClose;
  }

  function overrideCanClose() {
    canClose = true;
  }

  return { init, warnOnCloseWhile, _getCanClose, confirmCanClose, overrideCanClose };
}

module.exports = BrowserWarning();

const Network = require("../network");
