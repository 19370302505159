"use strict";

var BarChartController = function () {
  var graphTableClick = function (year, headerOrFooter) {
    highlightYear(year, headerOrFooter);
    YearDropdown.setYearDropdown(year);

    if (headerOrFooter === "header") {
      Tree.set("mitigationType", "Mitigated");
      $("#toplineRow" + year).css("color", "#000000");
      $(".subheaderTitleSpanMitigated").text("Current");
      // handleTableClick(year);
    } else {
      Tree.set("mitigationType", "Unmitigated");
      $("#baselineRow" + year).css("color", "#000000");
      $(".subheaderTitleSpanMitigated").text("Baseline");
      // handleTableClick(year);
    }
  };

  var highlightYear = function (year, headerOrFooter) {
    boldAndColorSelectedColumnText(year);

    $(".yellow-rectangle").hide();
    $(".yellow-rectangle-" + year).show();
  };

  var boldAndColorSelectedColumnText = function (year) {
    $(".x-axis-top-label").css("font-weight", "500");
    $(".x-axis-top-label-" + year).css("font-weight", "700");

    $(".bar-segment-label").css("font-weight", "300");
    $(".bar-segment-label-" + year).css("font-weight", "500");

    $(".row-0-value").css("font-weight", "300");
    $(".row-0-value-" + year).css("font-weight", "500");

    $(".row-1-value").css("font-weight", "300");
    $(".row-1-value-" + year).css("font-weight", "500");

    $(".row-2-value").css("font-weight", "300");
    $(".row-2-value-" + year).css("font-weight", "500");

    $(".row-3-value").css("font-weight", "300");
    $(".row-3-value-" + year).css("font-weight", "500");
  };

  return {
    graphTableClick,
    highlightYear,
  };
};

module.exports = BarChartController();

const Tree = require("../../../tree");
const YearDropdown = require("../../mapFunctions/yearDropdown");
