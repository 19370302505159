"use strict";

const ToggleCheckbox = function () {
  const getData = function () {
    const data = {};
    $(".toggle-checkbox").each(function () {
      const $toggle = $(this);
      const name = Misc.kebabToCamel($toggle.attr("name"));
      data[name] = $toggle.is(":checked");
    });
    return data;
  };

  return {
    getData,
  };
};

module.exports = ToggleCheckbox();

const Misc = require("../misc");
