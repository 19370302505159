"use strict";

const MenuConfig = function () {
  const get = function () {
    return {
      home: {
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            class: "disabled",
            permissionKey: "can_export_data",
          },
        ],
      },
      bmpram: {
        help: [
          {
            text: "Field Protocols",
            class: "navbar-field-protocols",
            href: "https://drive.google.com/file/d/1Zy-APHDDM7ejydFzywt5YhLdur4n0H4Q/view?usp=sharing",
            target: "_blank",
          },
        ],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      trashram: {
        help: [
          {
            text: "Urban Assessment Protocols",
            class: "navbar-urban-assessment-protocols",
            href: "https://drive.google.com/file/d/10orw9LrmrOkmFL0sH82xH5-32KkUOqDN/view?usp=sharing",
            target: "_blank",
          },
          {
            text: "FCS Assessment Protocols",
            class: "navbar-fcs-assessment-protocols",
            href: "https://www.2ndnaturewater.com/documents/TrashModule_FCSAssessmentProtocols.pdf",
            target: "_blank",
          },
        ],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
        settings: [
          {
            text: "Trash Report Settings",
            class: "trash-settings-link disabled",
          },
        ],
      },
      construction: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_projects",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      projdelivery: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_projects",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      lid: {
        help: [
          {
            text: "Field Guidance",
            class: "navbar-field-guidance",
            href: "https://kb.2ndnaturewater.com/hubfs/2NDNATURE%20User%20Guides/ParcelRAM_FieldGuide2019.pdf",
            target: "_blank",
          },
        ],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_projects",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      indcom: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      idde: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      muni: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      outmon: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      assets: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
      peo: {
        help: [],
        "import-export": [
          {
            text: "Import or Edit Data",
            class: "data-import-link",
            regulatorHidden: true,
            permissionKey: "can_create_bmps",
            fieldPersonnelHidden: true,
          },
          {
            text: "Export Data",
            disableWithoutDataViewProperty: "exportsEnabled",
            class: "data-export-link",
            permissionKey: "can_export_data",
          },
        ],
      },
    };
  };

  var getCurrentMenuItems = function (menuName) {
    const tool = Tree.get("tool");
    const menus = get()[tool];

    if (!menus || !menus[menuName]) {
      return [];
    }

    return menus[menuName];
  };

  var getGroupBasedMenuItems = function (menuName) {
    const menus = GroupBasedMenuItems.get();

    if (!menus || !menus[menuName]) {
      return [];
    }

    return menus[menuName];
  };

  var filterMenuItemsByPermission = function (listItems) {
    return listItems.filter((item) => {
      return !(item.permissionKey && !UserPermissions.getPermission(item.permissionKey));
    });
  };

  var disableMenuItemsWithoutDataViewProperties = function (listItems) {
    for (const item of listItems) {
      if (
        item.disableWithoutDataViewProperty &&
        !DataViewFunctions.getCurrentDataViewProperty(item.disableWithoutDataViewProperty)
      ) {
        item.class += " disabled";
      }
    }
  };

  return {
    get,
    getCurrentMenuItems,
    getGroupBasedMenuItems,
    filterMenuItemsByPermission,
    disableMenuItemsWithoutDataViewProperties,
  };
};

module.exports = MenuConfig();

const DataViewFunctions = require("../dataViewFunctions");
const UserPermissions = require("../login/userPermissions");
const GroupBasedMenuItems = require("../settings/groupBasedMenuItems");
const Tree = require("../tree");
