"use strict";

const AllPropertiesGeoServerPopup = function () {
  var getPopupHtml = function (programs) {
    return PopupContent.getLayerPopup(programs, "incentivePrograms");
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteProperty(data.id, data.name, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deleteProperty = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Property ${name}? Click "Delete" to permanently delete the Property. Click "Cancel" to return.`,
      async function () {
        invalidateLayerDataFunction();
        _closePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "cleanouts");
  };

  var _closePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var onPopupOpen = async function (programs, event = null) {
    var layerName = "incentivePrograms";
    const layerModule = LayerFunctions.getLayerModule(layerName);
    const id = programs.id;
    const property = LayerDataFunctions.getLayerDataAndIndexById(layerName, id);
    const color = PropertiesIcon.getClassAndColor(property.data.esg_property_symbol).color;
    var data = await getPopupData(id);
    data.color = color;
    const html = getPopupHtml(data);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var getPopupData = async function (id) {
    const popupData = await EsgApiCalls.getPropertyPopup(id);
    return popupData;
  };

  return {
    getPopupHtml,
    onPopupOpen,
  };
};

module.exports = AllPropertiesGeoServerPopup();

const LayerFunctions = require("../../layerFunctions");
const PopupContent = require("../../mapFunctions/popupContent");
const MessageModal = require("../../modals/messageModal");
const Analytics = require("../../general/analytics");
const EsgApiCalls = require("../esgApiCalls");
const PropertiesIcon = require("../icons/propertiesIcon");
const LayerDataFunctions = require("../../general/layerDataFunctions");
