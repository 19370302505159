"use strict";

const FilterConstants = function () {
  const sbmpTypes = [
    "1", //Dry Basin
    "2", //Wet Basin
    "3", //Infiltration Basin
    "4", //Treatment Vault
    "5", //Media Filter
    "6", //Bed Filter
    "7", //Settling Basin
    "8", //Biofiltration
    "9", //Infiltration Feature
    "10", //Pervious Pavement
    "11", //Sediment Trap
    "13", //Bioretention
    "14", //Detention Basin
    "15", //Bioswale
    "16", //Unknown
    "17", //Filtration Device
    "18", // Trash Trap
    "19", //Retention Basin
    "20", //Green Roof
    "21", //Rain Barrel
    "22", //Cistern
  ];
  const yesNoOptions = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
    {
      name: "No Data",
      value: "null",
    },
  ];
  const bmpTypeOptionsNoAbbreviationsScenarios = [
    {
      name: "Bed Filter",
      value: 6,
    },
    {
      name: "Biofiltration",
      value: 8,
    },
    {
      name: "Bioretention",
      value: 13,
    },
    {
      name: "Bioswale",
      value: 15,
    },
    {
      name: "Cistern",
      value: 22,
    },
    {
      name: "Detention Basin",
      value: 14,
    },

    {
      name: "Dry Basin",
      value: 1,
    },
    {
      name: "Filtration Device",
      value: 17,
    },
    {
      name: "Green Roof",
      value: 20,
    },
    {
      name: "Infiltration Basin",
      value: 3,
    },
    {
      name: "Infiltration Feature",
      value: 9,
    },
    {
      name: "Media Filter",
      value: 5,
    },
    {
      name: "Pervious Pavement",
      value: 10,
    },
    {
      name: "Rain Barrel",
      value: 21,
    },
    {
      name: "Retention Basin",
      value: 19,
    },
    {
      name: "Sediment Trap",
      value: 11,
    },
    {
      name: "Settling Basin",
      value: 7,
    },
    {
      name: "Treatment Vault",
      value: 4,
    },
    {
      name: "Wet Basin",
      value: 2,
    },
    {
      name: "Unknown",
      value: 16,
    },
    {
      name: "No Data",
      value: null,
    },
  ];

  const responsiblePartyDeliveryOptionsCB = [
    {
      name: "DOT",
      value: "dot",
    },
    {
      name: "Local Agency",
      value: "local-agency",
    },
    {
      name: "MS4",
      value: "ms4",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Our Agency",
      value: "our-agency",
    },
    {
      name: "Partner",
      value: "partner",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const conditionOptions = [
    {
      name: "Issues",
      value: "poor",
    },
    {
      name: "No Issues",
      value: "optimal",
    },
    {
      name: "None",
      value: "null",
    },
  ];

  const getEnforcementLevel = function () {
    const maintenanceLevelsWithName = ToolSettings.getSetting("constants", "maintenanceLevels");
    const result = [];

    for (const key in maintenanceLevelsWithName) {
      result.push({
        name: maintenanceLevelsWithName[key].displayName,
        value: key,
      });
    }

    result.push(
      {
        name: "Unknown",
        value: "unknown",
      },
      {
        name: "No Data",
        value: "null",
      },
    );

    return result;
  };
  const analysisOptions = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
    {
      name: "No Data",
      value: null,
    },
  ];

  const initialFilters = {
    search: "",
    statuses: [],
    ownership: [
      "our-agency",
      "private",
      "partner",
      "unknown",
      "other",
      "ms4",
      "dot",
      "local-agency",
    ],
    inaccessible: false,
    types: [],
    dateFrom: null,
    dateTo: null,
    scoreFrom: null,
    scoreTo: null,
    dateType: "assessment",
    dateTypeFcs: "assessment",
    dateTypeConstruction: "inspection",
    condition: [],
    descend: false,
    photos: ["true", "false"],
    priority: false,
    searchString: "",
    receivingWaters: [],
    catchments: [],
    facilityType: [],
  };

  const filtersNotToReset = ["filtersOn"];

  const spatialFilters = ["catchments", "receivingWaters", "maintenanceZones", "highways"];
  const filtersThatDisableDataSorts = [
    "catchmentName",
    "drainsToC",
    "drainsToRw",
    "highwayName",
    "drainsToSpatialGroupingId",
    "drainsToAssesmentArea",
    "subGroups",
    "zones",
  ];
  const dataSortsDisabledForSpatialSmartFilters = [
    "rw",
    "ud",
    "uc",
    "highways",
    "monitoringDrainages",
    "assessmentAreas",
    "watersheds",
    "maintenanceZones",
  ];
  const dataSortsEnabledForSpatialSmartFilters = ["assetManagementId"];

  const getDefaultFiltersByDataView = function (dataView = Tree.get("dataView")) {
    var filtersByDataView = getDefaultFilter(dataView);
    if (dataView === "roads") {
      filtersByDataView = getDefaultFilter("muni-catch-basin");
    }

    if (filtersByDataView === undefined) {
      console.warn(`No default filters provided for dataView ${dataView} in filterConstants`);
      filtersByDataView = {};
    }

    return filtersByDataView;
  };

  var getUniqueValueOnlyArray = function (data, key) {
    const seen = new Set();
    const result = data?.reduce((result, item) => {
      if (item[key] !== null && !seen.has(item[key])) {
        seen.add(item[key]);
        result.push(item[key]);
      }
      return result;
    }, []);

    if (!result?.includes("null")) {
      result?.push("null");
    }

    return result;
  };

  var getDefaultFilter = function (dataView) {
    const trashLinesDefault = { condition: ["Low", "Moderate", "High", "Very High"] };
    const photoDefault = ["true", "false"];
    const spatialData = ToolSettings.getSetting("topLevelDataSorts", "dataSort");
    const drainsToRw = getUniqueValueOnlyArray(spatialData?.catchments, "drains_to_rw");
    const drainsToC = getUniqueValueOnlyArray(spatialData?.catchments, "drains_to_c");
    const catchmentName = getUniqueValueOnlyArray(spatialData?.catchments, "catchid");
    const zones = spatialData?.maintenanceZones
      ? getUniqueValueOnlyArray(spatialData?.maintenanceZones, "maintenanceZoneName")
      : null;
    const getEnforcementLevelDefault = () => [
      ...Object.keys(ToolSettings.getSetting("constants", "maintenanceLevels")),
      "unknown",
      "null",
    ];

    const constructionConditionPhaseToggle =
      FeatureFlag.enabled("construction-condition-phase-toggle") && !Session.isCdot()
        ? "condition"
        : "phase";

    const getMuniBmp = () => ({
      responsiblePartyMaintenance: ["our-agency", "unknown"],
      implementationPhase: ["completed", "certified"],
      types: [
        "6",
        "8",
        "13",
        "15",
        "22",
        "14",
        "1",
        "17",
        "12",
        "20",
        "3",
        "9",
        "5",
        "10",
        "21",
        "19",
        "11",
        "7",
        "4",
        "2",
        "16",
        "18",
        "null",
      ],
      condition: ["poor", "optimal", "null"],
      fcs: ["true", "false", "null"],
      correctiveAction: _getAllMaintenanceIssueOptionKeys(),

      inProximityTo303dWaterbody: ["true", "false", "null"],
      consequenceOfFailure: ["very-high", "high", "moderate", "low", "very-low", "unknown", "null"],
      enforcementLevel: getEnforcementLevelDefault(),
      drainsToRw,
      drainsToC,
      catchmentName,
      zones,
    });

    if (dataView === "bmp") {
      return {
        phase: ["completed", "certified"],
        toDos: [],
        types: sbmpTypes,
        landOwnership: ["0", "1", "2", "3", "4", "5", "6", "7"],
        conditionPhaseToggle: "condition",
      };
    } else if (dataView === "fcs") {
      return {
        drainsToRw,
        drainsToC,
        catchmentName,
        phase: ["completed", "certified"],
        toDos: [],
        types: [
          "6",
          "8",
          "13",
          "15",
          "22",
          "14",
          "1",
          "17",
          "12",
          "20",
          "3",
          "9",
          "5",
          "10",
          "21",
          "19",
          "11",
          "7",
          "4",
          "2",
          "16",
          "18",
          "null",
        ],
        landOwnership: ["0", "1", "2"],
        ownership: [
          "our-agency",
          "private",
          "partner",
          "unknown",
          "other",
          "ms4",
          "dot",
          "local-agency",
          "null",
        ],
        responsiblePartyMaintenance: [
          "our-agency",
          "private",
          "partner",
          "unknown",
          "other",
          "ms4",
          "dot",
          "local-agency",
          "null",
        ],
        ...trashLinesDefault,
      };
    } else if (dataView === "va") {
      return {
        ...trashLinesDefault,
        ...photoDefault,
        assessmentDate: { from: DateTime.isoDateYearsAgo(2) },
      };
    } else if (dataView === "construction-project") {
      return {
        phase:
          constructionConditionPhaseToggle === "condition"
            ? ["construction", "post-active"]
            : ["planning", "planned", "construction", "post-active", "completed", "certified"],
        ownership: [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
          "null",
        ],
        priority: false,
        conditionPhaseToggle: constructionConditionPhaseToggle,
      };
    } else if (dataView === "construction-project-delivery") {
      return {
        phase: ToolSettings.getSetting("construction", "enable-post-active-phase")
          ? [
              "planning",
              "planned",
              "construction",
              "completed",
              "certified",
              "shelved",
              "rejected",
              "post-active",
            ]
          : ["planning", "planned", "construction", "completed", "certified", "shelved"],
        ownership: [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
          "null",
        ],
        priority: false,
        conditionPhaseToggle: "phase",
        types: sbmpTypes,
      };
    } else if (dataView === "lid-project") {
      return {
        status: ["completed", "certified"],
        dateTypeLidProject: "completed",
        performanceTier: ["?", "0", "1", "2", "3", "4"],
        lidOwnership: [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
          "null",
        ],
        conditionPhaseToggle: "condition",
        phase: ["completed", "certified"],
        drainsToRw,
        drainsToC,
        catchmentName,
      };
    } else if (dataView === "muni-manhole") {
      return {
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        enforcementLevel: getEnforcementLevelDefault(),
        responsiblePartyMaintenance: ["our-agency", "unknown"],
        landOwnership: [
          "dot",
          "local-agency",
          "ms4",
          "other",
          "our-agency",
          "partner",
          "private",
          "unknown",
          "null",
        ],
        implementationPhase: ["completed", "certified"],
        cof: ["very-high", "high", "moderate", "low", "very-low", "unknown", "null"],
        shape: [
          "arched",
          "circular",
          "egg",
          "oval",
          "horseshoe",
          "other",
          "rectangular",
          "square",
          "trapezoid",
          "unknown",
          "null",
        ],
        manholeType: [
          "control",
          "diversion",
          "drop",
          "drop-trap",
          "flush",
          "gate-valve",
          "junction",
          "junction-chamber",
          "offset",
          "other",
          "shallow",
          "siphon",
          "split",
          "sump",
          "Trap",
          "unknown",
          "null",
        ],
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
      };
    } else if (dataView === "lid-runoff") {
      return {
        status: ["completed", "certified"],
        dateTypeLidProject: "completed",
        performanceTier: ["?", "0", "1", "2", "3", "4"],
        lidOwnership: [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
          "null",
        ],
      };
    } else if (dataView === "muni-catch-basin") {
      return {
        phase: ["completed", "certified"],
        condition: ["poor", "optimal", "null"],
        types: ["11", "12"],
        landOwnership: ["0", "1", "2", "3", "4", "5", "6", "7"],
        ownership: [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
        ],
        responsiblePartyMaintenance: ["our-agency", "unknown"],
        consequenceOfFailure: [
          "very-high",
          "high",
          "moderate",
          "low",
          "very-low",
          "unknown",
          "null",
        ],
        sediment: ["yes", "no", "null"],
        fcs: ["true", "false", "null"],
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        enforcementLevel: getEnforcementLevelDefault(),
        catchBasinPriority: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
        dateType: "install",
        inProximityTo303dWaterbody: ["true", "false", "null"],
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
      };
    } else if (dataView === "muni-lift-stations") {
      return {
        phase: ["completed", "certified"],
        maintainedBy: ["our-agency", "unknown"],
        enforcementLevel: getEnforcementLevelDefault(),
        landOwnership: [
          "dot",
          "local-agency",
          "ms4",
          "other",
          "our-agency",
          "partner",
          "private",
          "unknown",
          "null",
        ],
        consequenceOfFailure: [
          "very-high",
          "high",
          "moderate",
          "low",
          "very-low",
          "unknown",
          "null",
        ],
        condition: ["poor", "optimal", "null"],
        type: [
          "centrifugal",
          "dry",
          "duplex",
          "jockey",
          "simplex",
          "split-case",
          "submersible",
          "triplex",
          "turbine",
          "other",
          "unknown",
          "null",
          "wet-dry",
        ],
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
      };
    } else if (dataView === "muni-cleanout") {
      return {
        phase: ["completed", "certified"],
        maintainedBy: ["our-agency", "unknown"],
        landOwnership: [
          "dot",
          "local-agency",
          "ms4",
          "other",
          "our-agency",
          "partner",
          "private",
          "unknown",
          "null",
        ],
        consequenceOfFailure: [
          "very-high",
          "high",
          "moderate",
          "low",
          "very-low",
          "unknown",
          "null",
        ],
        condition: ["poor", "optimal", "null"],
        shape: [
          "arched",
          "circular",
          "egg",
          "oval",
          "horseshoe",
          "other",
          "rectangular",
          "square",
          "trapezoid",
          "unknown",
          "null",
        ],
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        enforcementLevel: getEnforcementLevelDefault(),
        type: ["cleanout", "other", "null", "unknown"],
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
      };
    } else if (dataView === "muni-pipes") {
      return {
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
        phase: ["completed", "certified"],
        maintainedBy: ["our-agency", "unknown"],
        lined: ["true", "false", "null"],
        landOwnership: [
          "dot",
          "local-agency",
          "ms4",
          "other",
          "our-agency",
          "partner",
          "private",
          "unknown",
          "null",
        ],
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        enforcementLevel: getEnforcementLevelDefault(),
        accessible: ["true", "false", "null"],
        flowType: ["receiving-water", "303d", "other", "null"],
        consequenceOfFailure: [
          "very-high",
          "high",
          "moderate",
          "low",
          "very-low",
          "unknown",
          "null",
        ],
        condition: ["poor", "optimal", "null"],
        shape: [
          "circular",
          "horseshoe",
          "irregular",
          "oblong",
          "other",
          "rectangular",
          "trapezoidal",
          "triangular",
          "unknown",
          "null",
        ],
        type: [
          "cattle pass",
          "cross drain",
          "drain tile",
          "force main",
          "force",
          "gravity",
          "perforated",
          "underdrain",
          "other",
          "unknown",
          "null",
        ],
        serviceType: [
          "combined",
          "other",
          "recycled",
          "sewer",
          "storm",
          "supply",
          "unknown",
          "null",
        ],
      };
    } else if (dataView === "muni-outfalls") {
      return {
        responsiblePartyMaintenance: ["our-agency", "unknown"],
        landOwnership: [
          "dot",
          "local-agency",
          "ms4",
          "other",
          "our-agency",
          "partner",
          "private",
          "unknown",
          "null",
        ],
        outfallType: ["closed-pipe", "open-channel", "underground", "unknown", "other", "null"],
        outletType: [
          "apron",
          "ditch-outlet",
          "earthen",
          "endwall",
          "flap-gate",
          "flared",
          "pipe",
          "riser-structure",
          "rundown",
          "slotted",
          "tide-chamber",
          "weir",
          "other",
          "unknown",
          "null",
        ],
        implementationPhase: ["completed", "certified"],
        condition: ["poor", "optimal", "null"],
        cof: ["very-high", "high", "moderate", "low", "very-low", "unknown", "null"],
        shape: [
          "archway",
          "circular",
          "egg-shaped",
          "elliptical",
          "horseshoe",
          "rectangle",
          "square",
          "trapezoid",
          "u-shaped",
          "other",
          "unknown",
          "null",
        ],
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        enforcementLevel: getEnforcementLevelDefault(),
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
      };
    } else if (dataView === "muni-culvert") {
      return {
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
        responsiblePartyMaintenance: ["our-agency", "unknown"],
        lined: ["true", "false", "null"],
        landOwnership: [
          "dot",
          "local-agency",
          "ms4",
          "other",
          "our-agency",
          "partner",
          "private",
          "unknown",
          "null",
        ],
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        culvertType: ["arch", "box", "bridge", "pipe", "pipe-arch", "unknown", "other", "null"],
        serviceType: [
          "combined",
          "other",
          "recycled",
          "sewer",
          "storm",
          "supply",
          "unknown",
          "null",
        ],
        implementationPhase: ["completed", "certified"],
        accessible: ["true", "false", "null"],
        condition: ["poor", "optimal", "null"],
        cof: ["very-high", "high", "moderate", "low", "very-low", "unknown", "null"],
        shape: [
          "circular",
          "horseshoe",
          "irregular",
          "oblong",
          "other",
          "rectangular",
          "trapezoidal",
          "triangular",
          "unknown",
          "null",
        ],
      };
    } else if (dataView === "muni-open-channel") {
      return {
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
        responsiblePartyMaintenance: ["our-agency", "unknown"],
        lined: ["true", "false", "null"],
        landOwnership: [
          "dot",
          "local-agency",
          "ms4",
          "other",
          "our-agency",
          "partner",
          "private",
          "unknown",
          "null",
        ],
        openChannelType: [
          "aqueduct",
          "canal",
          "ditch",
          "natural",
          "other",
          "spillway",
          "unknown",
          "null",
        ],
        serviceType: [
          "combined",
          "other",
          "recycled",
          "sewer",
          "storm",
          "supply",
          "unknown",
          "null",
        ],
        correctiveAction: _getAllMaintenanceIssueOptionKeys(),
        flowType: ["receiving-water", "303d", "other", "null"],
        condition: ["poor", "optimal", "null"],
        implementationPhase: ["completed", "certified"],
        accessible: ["true", "false", "null"],
        cof: ["very-high", "high", "moderate", "low", "very-low", "unknown", "null"],
        shape: [
          "circular",
          "horseshoe",
          "irregular",
          "oblong",
          "other",
          "rectangular",
          "trapezoidal",
          "triangular",
          "unknown",
          "null",
        ],
      };
    } else if (dataView === "muni-bmp") {
      return getMuniBmp();
    } else if (dataView === "lid-bmp") {
      return { ...getMuniBmp(), responsiblePartyMaintenance: null };
    } else if (dataView === "incidents") {
      return {
        activityType: [
          "agricultural-irrigation",
          "concrete-wash",
          "fuel-diesel",
          "grey-water",
          "oil",
          "paint",
          "pressure-washing",
          "residential-irrigation",
          "water-leak",
          "hazardous-materials",
          "sewage",
          "other",
          "unknown",
          "no-activity",
          "no-investigations",
          "null",
        ],
        dateTypeIncidents: "requested",
        incidentType: IncidentInvestigationConstants.getFilterIncidentTypeOptions().map(
          (option) => option.value,
        ),
        drainsToRw,
        drainsToC,
        catchmentName,
      };
    } else if (dataView === "indcom-facilities") {
      return {
        conditionPhaseToggle: "condition",
        dateType: "inspection",
        facilityPhase: ["open", "closed"],
        openFindings: ["yes", "no"],
        openEnforcements: ["yes", "no"],
      };
    } else if (dataView === "scenarios") {
      return {
        conditionPhaseToggle: "phase",
        scenario: ["current", "potential", "null"],
        phase: ["planning", "planned", "construction", "post-active", "completed", "certified"],
        sbmpCategory: ["centralized", "decentralized", "unknown", "null"],
        types: [
          "6",
          "8",
          "13",
          "15",
          "22",
          "14",
          "1",
          "17",
          "20",
          "3",
          "9",
          "5",
          "10",
          "21",
          "19",
          "11",
          "7",
          "4",
          "2",
          "16",
        ],
        analysisReady: ["yes", "no", "null"],
        drainsToRw,
        drainsToC,
        catchmentName,
        zones,
      };
    } else if (dataView === "dry-weather") {
      return {
        responsiblePartyMaintenance: ["our-agency", "unknown"],
        phase: ["completed", "certified"],
      };
    } else if (dataView === "asset-management") {
      return {
        responsiblePartyMaintenance: ["our-agency"],
        phase: ["completed", "certified"],
      };
    }
  };

  var _getAllMaintenanceIssueOptionKeys = function () {
    return BmpFcsInventoryConstants.getAllMaintenanceTasks().reduce(
      function (result, task) {
        result.push(task.key);
        if (task.aliases) {
          result = result.concat(task.aliases);
        }
        return result;
      },
      ["no-access"],
    );
  };

  const pipeTypeOptions = [
    { name: "Cattle Pass", value: "cattle pass" },
    { name: "Cross Drain", value: "cross drain" },
    { name: "Drain Tile", value: "drain tile" },
    { name: "Force Main-Pressurized", value: "force main" },
    { name: "Force Pipe", value: "force" },
    { name: "Gravity Main", value: "gravity" },
    { name: "Perforated Pipe", value: "perforated" },
    { name: "Underdrain", value: "underdrain" },
    { name: "Other", value: "other" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const scenarioPhaseOptions = [
    {
      name: "Planning",
      value: "planning",
    },
    {
      name: "Design",
      value: "planned",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Certified",
      value: "certified",
    },
    {
      name: "Construction",
      value: "construction",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Shelved",
      value: "shelved",
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const getAllCorrectiveOptions = function () {
    const maintenanceTasksWithName = ToolSettings.getSetting("constants", "maintenanceTasks");
    const result = [];

    for (const key in maintenanceTasksWithName) {
      result.push({
        name: maintenanceTasksWithName[key].displayName,
        value: key,
      });
    }

    result.push({ name: "No Corrective Actions", value: "no-action" });

    return result;
  };

  const openChannelType = [
    { name: "Aqueduct", value: "aqueduct" },
    { name: "Canal", value: "canal" },
    { name: "Ditch", value: "ditch" },
    { name: "Natural", value: "natural" },
    { name: "Other", value: "other" },
    { name: "Spillway", value: "spillway" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const serviceType = [
    { name: "Combined Sewer-Storm", value: "combined" },
    { name: "Other", value: "other" },
    { name: "Recycled Water", value: "recycled" },
    { name: "Sewer", value: "sewer" },
    { name: "Storm", value: "storm" },
    { name: "Water Supply", value: "supply" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const flowType = [
    { name: "Receiving Water", value: "receiving-water" },
    { name: "303(d) Impaired Waterbody", value: "303d" },
    { name: "Other", value: "other" },
    { name: "No Data", value: "null" },
  ];

  const linnedAssetsShapes = [
    { name: "Circular", value: "circular" },
    { name: "Horseshoe", value: "horseshoe" },
    { name: "Irregular", value: "irregular" },
    { name: "Oblong", value: "oblong" },
    { name: "Other", value: "other" },
    { name: "Rectangular", value: "rectangular" },
    { name: "Trapezoidal", value: "trapezoidal" },
    { name: "Triangular", value: "triangular" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const muniResponsiblePartyOptions = [
    {
      name: "DOT",
      value: "dot",
    },
    {
      name: "Local Agency",
      value: "local-agency",
    },
    {
      name: "MS4",
      value: "ms4",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Our Agency",
      value: "our-agency",
    },
    {
      name: "Partner",
      value: "partner",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
    {
      name: "No Data",
      value: "null",
    },
  ];

  const liftStationTypes = [
    { name: "Centrifugal Pump", value: "centrifugal" },
    { name: "Dry", value: "dry" },
    { name: "Duplex Pump", value: "duplex" },
    { name: "Jockey Pump", value: "jockey" },
    { name: "Simplex Pump", value: "simplex" },
    { name: "Split-case Lift", value: "split-case" },
    { name: "Submersible", value: "submersible" },
    { name: "Triplex Pump", value: "triplex" },
    { name: "Turbine Pump", value: "turbine" },
    { name: "Wet-dry Configuration", value: "wet-dry" },
    { name: "Other", value: "other" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const cofOptionsWithNull = [
    { name: "Very High", value: "very-high" },
    { name: "High", value: "high" },
    { name: "Moderate", value: "moderate" },
    { name: "Low", value: "low" },
    { name: "Very Low", value: "very-low" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const shapeOptions = [
    { name: "Archway", value: "archway" },
    { name: "Circular", value: "circular" },
    { name: "Egg Shaped", value: "egg-shaped" },
    { name: "Elliptical", value: "elliptical" },
    { name: "Horseshoe", value: "horseshoe" },
    { name: "Other", value: "other" },
    { name: "Rectangular", value: "rectangle" },
    { name: "Square", value: "square" },
    { name: "Trapezoid", value: "trapezoid" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const OutfallShapeOptions = [
    { name: "Archway", value: "archway" },
    { name: "Circular", value: "circular" },
    { name: "Egg Shaped", value: "egg-shaped" },
    { name: "Elliptical", value: "elliptical" },
    { name: "Horseshoe", value: "horseshoe" },
    { name: "Rectangular", value: "rectangle" },
    { name: "Square", value: "square" },
    { name: "Trapezoid", value: "trapezoid" },
    { name: "U-Shaped", value: "u-shaped" },
    { name: "Other", value: "other" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const shapeOptionsCleanoutManholes = [
    { name: "Archway", value: "arched" },
    { name: "Circular", value: "circular" },
    { name: "Egg Shaped", value: "egg" },
    { name: "Elliptical", value: "oval" },
    { name: "Horseshoe", value: "horseshoe" },
    { name: "Other", value: "other" },
    { name: "Rectangular", value: "rectangular" },
    { name: "Square", value: "square" },
    { name: "Trapezoid", value: "trapezoid" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];
  const muniImplementationPhaseOptions = [
    { name: "Planning", value: "planning" },
    { name: "Design", value: "planned" },
    { name: "Construction", value: "construction" },
    { name: "Completed", value: "completed" },
    { name: "Certified", value: "certified" },
    { name: "Rejected", value: "rejected" },
    { name: "Shelved", value: "shelved" },
    { name: "Abandoned", value: "abandoned" },
    { name: "Removed", value: "removed" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];
  const cleanoutType = [
    { name: "Cleanout", value: "cleanout" },
    { name: "Other", value: "other" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  const culvertType = [
    { name: "Arch", value: "arch" },
    { name: "Box", value: "box" },
    { name: "Bridge", value: "bridge" },
    { name: "Pipe", value: "pipe" },
    { name: "Pipe Arch", value: "pipe-arch" },
    { name: "Other", value: "other" },
    { name: "Unknown", value: "unknown" },
    { name: "No Data", value: "null" },
  ];

  return {
    initialFilters,
    getDefaultFiltersByDataView,
    filtersNotToReset,
    spatialFilters,
    filtersThatDisableDataSorts,
    dataSortsDisabledForSpatialSmartFilters,
    dataSortsEnabledForSpatialSmartFilters,
    cleanoutType,
    analysisOptions,
    responsiblePartyDeliveryOptionsCB,
    liftStationTypes,
    getAllCorrectiveOptions,
    yesNoOptions,
    scenarioPhaseOptions,
    shapeOptionsCleanoutManholes,
    conditionOptions,
    getEnforcementLevel,
    muniResponsiblePartyOptions,
    bmpTypeOptionsNoAbbreviationsScenarios,
    cofOptionsWithNull,
    shapeOptions,
    OutfallShapeOptions,
    pipeTypeOptions,
    muniImplementationPhaseOptions,
    openChannelType,
    serviceType,
    flowType,
    linnedAssetsShapes,
    culvertType,
    _getAllMaintenanceIssueOptionKeys,
  };
};

module.exports = FilterConstants();
const Tree = require("./tree");
const ToolSettings = require("./settings/toolSettings");
const IncidentInvestigationConstants = require("./illicitDischarge/incidentInvestigation/incidentInvestigationConstants");
const DateTime = require("./dateTime");
const BmpFcsInventoryConstants = require("./bmpfcs/bmpFcsInventoryConstants");
const FeatureFlag = require("./settings/featureFlag");
const Session = require("./login/session");
