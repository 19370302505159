"use strict";

function UploadValidator() {
  function validate(data) {
    var bytes = 1000000; // Add one MB buffer for non-file data
    var fileCount = 0;

    for (const value of getIterator(data)) {
      if (value instanceof Blob || value instanceof File) {
        bytes += value.size;
        fileCount++;
      }
    }

    validateMaxFileCount(fileCount);
    validateMaxUploadSize(bytes);
  }

  function getIterator(data) {
    if (data && data.values) {
      return data.values();
    }

    return [];
  }

  function validateMaxFileCount(fileCount) {
    const maxFileCount = 100;

    if (fileCount > maxFileCount) {
      throw new UploadLimitError(
        `Uploading ${fileCount} files exceeds the ${maxFileCount} file limit. Please remove ${
          fileCount - maxFileCount
        } files and try again.`,
      );
    }
  }

  function validateMaxUploadSize(bytes) {
    const maxUploadSize = 256000000;

    if (bytes > maxUploadSize) {
      throw new UploadLimitError(
        `Upload size ${(bytes / 1000000).toFixed(1)}MB exceeds the ${
          maxUploadSize / 1000000
        }MB limit. Please remove some files and try again.`,
      );
    }
  }

  function reportError(error, htmlErrorMessage = null) {
    const message = NetworkErrorMessage.get(error, "upload", htmlErrorMessage);

    reportUnexpectedUploadErrors(error);
    MessageModal.showSimpleWarningModal(message, true);

    return false;
  }

  function reportUnexpectedUploadErrors(error) {
    if (error instanceof UploadLimitError) {
      return;
    }

    Sentry.captureException(error);
  }

  return {
    validate,
    reportError,
  };
}

module.exports = UploadValidator();

const MessageModal = require("../modals/messageModal");
const UploadLimitError = require("../errors/uploadLimitError");
const Sentry = require("@sentry/browser");
const NetworkErrorMessage = require("./networkErrorMessage");
