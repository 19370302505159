"use strict";

const TrashIconFunctions = function () {
  var createIcon = function (properties, zoom) {
    const options = MapFunctions.resizeIconsOnZoom(
      zoom,
      20,
      10,
      "icon-text-large",
      "icon-text-medium",
      null,
      [0, 15, 20, 30, 30, 30, 30, 30],
    );
    return MapStyles.getTrashPointIcon(properties.condition, options.size);
  };

  var createTrashFcsIcon = function (properties, zoom) {
    return BmpFcsIcon.getIconMarker(
      properties.score,
      BmpFcsFunctions.isCentralizedBMPType(properties.bmp_kind),
      properties.bmp_type_abbr,
      properties.fcs,
      properties.fcsAssessmentDue,
      properties.phase,
      null,
      Tree.get("activeTab") === "todo",
      zoom,
    );
  };

  return {
    createIcon,
    createTrashFcsIcon,
  };
};

module.exports = TrashIconFunctions();

const MapFunctions = require("../mapFunctions/mapFunctions");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions.js");
const MapStyles = require("../mapFunctions/mapStyles.js");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon.js");
const Tree = require("../tree.js");
