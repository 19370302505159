"use strict";

require("leaflet");
require("leaflet.vectorgrid");
L.esri = require("esri-leaflet");

const PopupContent = require("./popupContent");
const Tree = require("../tree");
const MapStyles = require("./mapStyles");
const AreaSelectionTable = require("./areaSelectionTable");
const Session = require("../login/session");
const Misc = require("../misc");
const ConstructionProjectAreaLayer = require("../construction/constructionProjectAreaLayer");
const LidProjectAreaLayer = require("../lid/lidProjectAreaLayer");
const LegacyBmpFcs = require("../bmpfcs/legacyBmpFcs");
const Device = require("../device");
const DataSortFunctions = require("../dataSortFunctions");
const MainMap = require("./mainMap");
const TelrResultLayerFunctions = require("../scenario/telrResultLayerFunctions");
const Table = require("./table");

var getPopupOptions = function () {
  const MapFunctions = require("./mapFunctions");
  return Object.assign(MapFunctions.getPopupOptions(), {
    minWidth: 200,
    maxWidth: 500,
    className: "popup-general",
  });
};

module.exports.getPopupOptions = getPopupOptions;

module.exports.ProjectLayer = L.GeoJSON.extend({
  options: {
    pane: "underCatchmentPane",
    style: ConstructionProjectAreaLayer.getStyle,
  },
});

var getWatershedLayerOptions = function () {
  return {
    pane: "stormdrainPane",
    style: MapStyles.styleThreeOThree,
    onEachFeature: function (feature, layer) {
      layer.bindPopup(PopupContent.getWatershedPopup(feature), getPopupOptions());
    },
  };
};

module.exports.WatershedLayer = L.GeoJSON.extend({
  options: getWatershedLayerOptions(),
});

const getBoundsLayer = function (data) {
  const boundaryLayerGray = _boundaryLayer(
    data,
    MapStyles.styleBoundaryGray,
    "ms4BoundaryGrayPane",
  );
  const boundaryLayerWhite = _boundaryLayer(data, MapStyles.styleBoundaryWhite, "ms4BoundaryPane");

  const boundsLayer = L.layerGroup([boundaryLayerGray, boundaryLayerWhite]);
  return boundsLayer;
};

const _boundaryLayer = function (data, style, pane) {
  return L.geoJSON(data, {
    pane: pane,
    style: style,
    interactive: false,
  });
};

const getOtherPropertiesLayer = function (data) {
  return L.geoJSON(data, {
    pane: "ms4BoundaryPane",
    style: MapStyles.styleOtherPropertyPolygon,
  });
};

module.exports.BaseStormdrainLayer = L.GeoJSON.extend({
  options: {
    pane: "stormdrainPane",
    style: MapStyles.styleBaseStormdrain,
  },
});

module.exports.MiddleStormdrainLayer = L.GeoJSON.extend({
  options: {
    pane: "stormdrainPane",
    style: MapStyles.styleMiddleStormdrain,
  },
});

module.exports.StormdrainLayer = L.GeoJSON.extend({
  options: {
    pane: "stormdrainPane",
    style: MapStyles.styleStormdrain,
  },
});

module.exports.LidProjectAreaLayer = L.GeoJSON.extend({
  options: {
    pane: "underCatchmentPane",
    style: LidProjectAreaLayer.getStyle,
    onEachFeature: function (feature, layer) {
      feature.properties["source"] = "lidProjectArea";
    },
  },
});

module.exports.ConstructionProjectAreaLayer = L.GeoJSON.extend({
  options: {
    pane: "underCatchmentPane",
    style: ConstructionProjectAreaLayer.getStyle,
    onEachFeature: function (feature, layer) {
      feature.properties["source"] = "constructionProjectArea";
    },
  },
});

module.exports.TrashPointLayer = L.GeoJSON.extend({
  options: {
    pointToLayer: function (feature, latlng) {
      var marker = L.marker(latlng, {
        pane: "trashPointPane",
        icon: MapStyles.getTrashPointIcon(feature.properties.condition),
      });
      return marker;
    },
    style: MapStyles.styleTrashPoints,
    onEachFeature: function (feature, layer) {
      feature.properties["source"] = "collector";
      feature.properties["color"] = MapStyles.convertColorToGradient(
        MapStyles.getTrashDataColor(feature.properties.condition),
      );
      if (!$("#modalMap").is(":visible")) {
        layer.bindPopup(
          nunjucks.render("popups/trashAssessmentPopup.njk", {
            featureProperties: feature.properties,
            readOnly: Session.isRegulator(),
          }),
        );
      }
    },
  },
});

module.exports.TrashLineLayer = L.GeoJSON.extend({
  options: {
    pane: "overBoundaryPane",
    style: MapStyles.styleCollectorData,
    onEachFeature: function (feature, layer) {
      feature.properties["source"] = "collector";
      feature.properties["color"] = MapStyles.convertColorToGradient(
        MapStyles.getTrashDataColor(feature.properties.condition),
      );

      if (!$("#modalMap").is(":visible")) {
        layer.bindPopup(
          nunjucks.render("popups/trashAssessmentPopup.njk", {
            featureProperties: feature.properties,
            readOnly: Session.isRegulator(),
          }),
        );
      }
    },
  },
});

module.exports.CollectorLineOutlineLayer = L.GeoJSON.extend({
  options: {
    pane: "overBoundaryPane",
    style: MapStyles.styleCollectorOutline,
  },
});

module.exports.ParcelGridLayer = L.VectorGrid.Slicer.extend({
  options: {
    pane: "underCatchmentPane",
    rendererFactory: L.canvas.tile,
    tolerance: 5,
    indexMaxZoom: 0,
    maxZoom: 21,
    vectorTileLayerStyles: {
      sliced: MapStyles.styleParcelCondition,
    },
  },
});

module.exports.ParcelPolygonViewLayer = L.GeoJSON.extend({
  options: {
    pane: "catchmentViewPane",
    style: MapStyles.styleParcelCondition,
    onEachFeature: function (feature, layer) {
      layer.on({
        mouseover: viewHighlight,
        mouseout: viewUnhighlight,
        click: (e) => Table.expandTableAndScroll(e.target.feature.properties.id),
      });
      layer.bindPopup(PopupContent.getRunoffConditionPopup(feature), getPopupOptions());
    },
  },
});

const customPolygonLayer = function (layerSettings) {
  return L.esri.featureLayer({
    url: layerSettings.serviceUrl,
    pane: "catchmentViewPane",
    style: MapStyles.styleParcelCondition,
    onEachFeature: function (feature, layer) {
      layer.bindPopup(PopupContent.getCustomLayerPopup(feature, layerSettings));
    },
    minZoom: layerSettings.minZoom || 4,
  });
};

const customLineLayer = function (layerSettings) {
  return L.esri.featureLayer({
    url: layerSettings.serviceUrl,
    pane: "catchmentViewPane",
    style: layerSettings.style ? layerSettings.style : MapStyles.styleCustomLineLayer,
    onEachFeature: function (feature, layer) {
      layer.bindPopup(PopupContent.getCustomLayerPopup(feature, layerSettings));
    },
    minZoom: layerSettings.minZoom || 4,
  });
};

module.exports.SurveyLayer = L.GeoJSON.extend({
  options: {
    pointToLayer: function (feature, latlng) {
      var marker = L.marker(latlng, {
        pane: "trashPointPane",
        icon: MapStyles.getTrashPointIcon(feature.properties.condition),
      });
      return marker;
    },
    onEachFeature: function (feature, layer) {
      feature.properties["source"] = "survey123";
      feature.properties["color"] = MapStyles.convertColorToGradient(
        MapStyles.getTrashDataColor(feature.properties.condition),
      );
      if (!$("#modalMap").is(":visible")) {
        layer.bindPopup(
          nunjucks.render("popups/trashAssessmentPopup.njk", {
            featureProperties: feature.properties,
            readOnly: Session.isRegulator(),
          }),
        );
      }
    },
  },
});

module.exports.OtherPropertiesLayer = L.GeoJSON.extend({
  options: {
    pane: "ms4BoundaryPane",
    style: MapStyles.styleOtherProperties,
  },
});

module.exports.FullDrainageBmpLayer = L.GeoJSON.extend({
  options: {
    pane: "underCatchmentPane",
    style: function (feature) {
      return LegacyBmpFcs.fullDrainagePolygon(
        feature,
        Tree.get("layers", "fullDrainageBmp", "isEnabled"),
        Tree.get("layers", "fullDrainageBmp", "isEnabledModal"),
      );
    },
  },
});

module.exports.FullDrainageFcsLayer = L.GeoJSON.extend({
  options: {
    pane: "underCatchmentPane",
    style: function (feature) {
      return LegacyBmpFcs.fullDrainagePolygon(
        feature,
        Tree.get("layers", "fullDrainageFcs", "isEnabled"),
        Tree.get("layers", "fullDrainageFcs", "isEnabledModal"),
      );
    },
  },
});

var selectPolygon = function (e, layer = null, drawn = false) {
  const AreaSelectionMap = require("../mapFunctions/areaSelectionMap");
  const ProjectInventoryModalController = require("../construction/projectInventoryModalController");

  if (ProjectInventoryModalController.isDrainageLayerDisabledInCurrentPage()) {
    return;
  }

  if (
    Tree.get("readOnlyModalMap") ||
    ProjectInventoryModalController.isDrainageLayerDisabledInCurrentPage()
  ) {
    return;
  }

  layer = e && e.target ? e.target : layer;
  var properties = layer.feature.properties;
  var polygonArray = [...AreaSelectionMap.getSelectedPolygons()];
  var catchmentArray = [...AreaSelectionMap.getSelectedCatchments()];
  var toolCatchName = getToolCatchmentName();

  if (properties["selected"] && drawn) {
    return;
  } else if (properties["selected"] && !drawn) {
    AreaSelectionTable.removeAreaSelectionTableRow(properties);
    delete properties.selected;
  } else {
    layer.feature.properties["selected"] = true;
    if (!layer.feature.properties["percent"]) {
      layer.feature.properties["percent"] = 100;
    }
    polygonArray.push(properties);
    enableImpInput();

    //keep track of additional catchments where polygons have been selected
    if (!catchmentArray.includes(properties.catch_name) && properties.catch_name != toolCatchName) {
      catchmentArray.push(properties.catch_name);
    }
    AreaSelectionMap.handleAddPolygon(properties, polygonArray, catchmentArray);
    AreaSelectionTable.addAreaSelectionTableRow(properties);
  }
  layer.setStyle(MapStyles.styleDrainageLayer(layer.feature));
};

function getToolCatchmentName() {
  const InventoryModal = require("../general/inventoryModal");
  const tool = Tree.get("tool");
  if (InventoryModal.isBmpFcsInventoryModalFormat()) {
    return Tree.get(["asset", "basicInfo", "catch_name"]);
  } else if (tool === "construction" || tool === "lid") {
    const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
    return ProjectInventoryModalController.getLatestConstructionData([
      "locationInfo",
      "catchmentName",
    ]);
  } else {
    return InventoryModal.getLatestData("some modal", ["locationInfo", "catchmentName"]);
  }
}

function enableImpInput() {
  Misc.toggleDisabled($(".impervious-treated"), false);
}

const CatchmentMixin = {
  highlight: function (propertyOnCatchment, valueOfProp) {
    // Exit if the layer has been removed from the map
    if (this._map === null) return;
    var zoom = this._map.getZoom();
    this.eachLayer(function (layer) {
      var isTarget = layer.feature.properties[propertyOnCatchment] == valueOfProp;
      if (isTarget) {
        if (zoom > 15) {
          layer.setStyle(MapStyles.zoomHighlights());
        } else {
          layer.setStyle(MapStyles.highlights());
        }
      }
    });
  },
  highlightMultiple: function (propertyOnCatchment, keys) {
    // Exit if the layer has been removed from the map
    if (this._map === null) return;
    var zoom = this._map.getZoom();
    this.eachLayer(function (layer) {
      var inTarget = layer.feature.properties[propertyOnCatchment] in keys;
      if (inTarget) {
        if (zoom > 15) {
          layer.setStyle(MapStyles.zoomHighlights());
        } else {
          layer.setStyle(MapStyles.highlights());
        }
      }
    });
  },
  unhighlightAll: function (bulkSelect = false) {
    var self = this;
    self.eachLayer(function (layer) {
      var isEnabled = !layer.feature.properties.disabled;

      if (bulkSelect) {
        layer.setStyle(MapStyles.styleCatchments(layer.feature));
      } else if (layer.feature.properties.selected) {
        layer.setStyle(MapStyles.selectedHighlight());
      } else if (isEnabled) {
        self.resetStyle(layer);
      } else {
        layer.setStyle(MapStyles.grayResults());
      }
    });
  },
  selectDrainsTo: function (data) {
    this.eachLayer(function (layer) {
      if (
        layer.feature.properties[data.prop] == data.id ||
        layer.feature.properties.selected == true
      ) {
        layer.feature.properties.disabled = false;
        layer.feature.properties.selected = true;
        layer.setStyle(MapStyles.highlights());
      } else {
        layer.feature.properties.disabled = true;
        layer.feature.properties.selected = false;
        layer.setStyle(MapStyles.grayResults());
      }
    });
  },
  filterDrainsTo: function (filter) {
    this.eachLayer(function (layer) {
      if (Tree.get("activeTab") === "todo") {
        layer.setStyle(MapStyles.grayResults());
      } else {
        if (
          filter.receivingWaters.includes(layer.feature.properties["drains_to_rw"]) ||
          filter.receivingWaters.includes(layer.feature.properties["drains_to_c"]) ||
          filter.catchments.includes(layer.feature.properties["catchid"])
        ) {
          return;
        } else {
          layer.feature.properties.disabled = true;
          layer.feature.properties.selected = false;
          layer.setStyle(MapStyles.grayResults());
        }
      }
    });
  },
  deselect: function (data) {
    var self = this;
    self.eachLayer(function (layer) {
      var isTarget = layer.feature.properties[data.prop] == data.id;
      if (data.allCollapsed || data.selectedRW == "") {
        delete layer.feature.properties.disabled;
        layer.feature.properties.selected = false;
        self.resetStyle(layer);
      } else if (isTarget) {
        layer.feature.properties.disabled = true;
        layer.feature.properties.selected = false;
      }
    });
    this._map.closePopup();
  },
};

const ModalCatchmentLayer = L.GeoJSON.extend({
  options: {
    pane: "catchmentPane",
    style: MapStyles.styleCatchments,
    onEachFeature: function (feature, layer) {
      if (!Session.isRegulator()) {
        layer.on({
          click: addCatchmentClick,
        });
      }
    },
  },
});

module.exports.ModalCatchmentLayer = ModalCatchmentLayer;

var addCatchmentClick = function (e) {
  const ProjectInventoryModalController = require("../construction/projectInventoryModalController");

  if (ProjectInventoryModalController.isDrainageLayerDisabledInCurrentPage()) {
    return;
  }
  var readOnlyModalMap = Tree.get("readOnlyModalMap");
  var layerEnabled = Tree.get("layers", "drainage", "isEnabledModal");
  if (layerEnabled && !readOnlyModalMap) {
    var catchid = e.target.feature.properties.catchid;
    const $addCatchmentModal = $("#add-catchment-modal");

    $addCatchmentModal
      .find(".modal-body")
      .html(
        "Select drainage area polygons in " +
          catchid +
          "?<br>" +
          "Click 'OK' to load selectable polygons or 'Cancel' to return to current catchment.",
      );
    $addCatchmentModal.modal("show");
  }
};

var PolygonMixin = {
  removeSelected: function (data) {
    var self = this;
    self.eachLayer(function (layer) {
      var isTarget = layer.feature.properties.polygon_id == parseInt(data.polygon_id);
      if (isTarget) {
        delete layer.feature.properties.selected;
        layer.setStyle(MapStyles.styleDrainageLayer(layer.feature));
      }
    });
  },
};

var highlight = function (e, tableSelector) {
  var zoom = this._map.getZoom();
  var gid = e.target.feature.properties.gid;
  handleTableHighlight(gid, tableSelector);
  e.target.setStyle(MapStyles.zoomHighlights(zoom));
};

var unhighlight = function (e, tableSelector) {
  var dataProps = e.target.feature.properties;
  var gid = dataProps.gid;
  var disabled = dataProps.disabled;
  handleTableHighlight(gid, tableSelector);
  if (disabled == null || disabled) {
    e.target.setStyle(MapStyles.styleCatchments(e.target.feature));
  } else {
    e.target.setStyle(MapStyles.selectedHighlight());
  }
};

var viewHighlight = function (e) {
  e.target.setStyle(MapStyles.highlights());
};

var viewUnhighlight = function (e) {
  var styler = e.target.defaultOptions.style;
  var disabled = e.target.feature.properties.disabled;

  if (!disabled) {
    e.target.setStyle(styler(e.target.feature));
  }
};

var highlightPolygon = function (e) {
  const ProjectInventoryModalController = require("../construction/projectInventoryModalController");

  if (ProjectInventoryModalController.isDrainageLayerDisabledInCurrentPage()) {
    return;
  }

  var zoom = this._map.getZoom();
  if (e.target.feature.properties["selected"]) {
    e.target.setStyle(MapStyles.selectedPolygonHighlight);
  } else {
    e.target.setStyle(MapStyles.zoomHighlights(zoom));
  }
};

var unhighlightPolygon = function (e) {
  if (e.target.feature.properties["selected"]) {
    e.target.setStyle(MapStyles.selectedPolygon(e.target.feature));
  } else {
    e.target.setStyle(MapStyles.styleDrainageLayer(e.target.feature));
  }
};

var handleTableHighlight = function (gid, tableSelector) {
  var $tableItem;

  if (tableSelector) {
    $tableItem = $(tableSelector).find(`[data-id="${gid}"]`);
  } else {
    const grouping = DataSortFunctions.getCurrentDataSortProperty("grouping");
    if (grouping === null) {
      $tableItem = $(".catchments-only h3[data-id=" + gid + "]");
    } else {
      $tableItem = $(".catchment-header li[data-id=" + gid + "]");
    }
  }

  if ($tableItem.hasClass("map-hover")) {
    $tableItem.removeClass("map-hover");
  } else {
    $tableItem.addClass("map-hover");
  }
};

var setDrainageHighlight = function (idBmp, bmpFcsLayer, bmpFcsDrainageLayer) {
  Tree.set(["layers", bmpFcsLayer, "selectedId"], idBmp);
  var layerCheck = Tree.get("layers", bmpFcsDrainageLayer, "isEnabled");

  if (!layerCheck) {
    MainMap.invalidateLayerByName(bmpFcsDrainageLayer);
  } else {
    highlightDrainage(bmpFcsLayer, bmpFcsDrainageLayer);
  }
};

var highlightDrainage = function (bmpFcsLayer, bmpFcsDrainageLayer) {
  var selectedId = Tree.get("layers", bmpFcsLayer, "selectedId");
  var fullDrainageFcs = Tree.get("layers", bmpFcsDrainageLayer, "data") ?? [];

  for (var index in fullDrainageFcs.features) {
    if (fullDrainageFcs.features[index].properties.idBmp == selectedId) {
      fullDrainageFcs.features[index].properties["highlight"] = true;
    } else {
      fullDrainageFcs.features[index].properties["highlight"] = false;
    }
  }

  Tree.set(["layers", bmpFcsDrainageLayer, "data"], fullDrainageFcs);
};

var clearHighlightDrainage = function (bmpFcsLayer, bmpFcsDrainageLayer) {
  Tree.set(["layers", bmpFcsLayer, "selectedId"], null);
  highlightDrainage(bmpFcsLayer, bmpFcsDrainageLayer);
};

const DrainageLayer = L.GeoJSON.extend({
  options: {
    pane: "overCatchmentPane",
    style: MapStyles.styleDrainageLayer,
    onEachFeature: function (feature, layer) {
      if (!Session.isRegulator()) {
        layer.on({
          mouseover: highlightPolygon,
          mouseout: unhighlightPolygon,
          click: selectPolygon,
        });
      }
    },
  },
  includes: PolygonMixin,
});
module.exports.DrainageLayer = DrainageLayer;

module.exports.DataLayer = L.GeoJSON.extend({
  options: {
    pane: "catchmentPane",
    style: MapStyles.styleCatchments,
    onEachFeature: function (feature, layer) {
      feature.properties["id"] = feature.properties["gid"];
      layer.bindPopup(PopupContent.getLayerPopup(feature, this?.layerType), getPopupOptions());
      layer.on({
        click: (e) => Table.expandTableAndScroll(e.target.feature.properties.id),
      });
    },
    interactive: !Device.isPrimaryInputTouch(),
  },
  includes: CatchmentMixin,
});

module.exports.TelrResultLayer = L.GeoJSON.extend({
  options: {
    pane: "overCatchmentPane",
    style: TelrResultLayerFunctions.getTelrResultLayerStyle,
    onEachFeature: function (feature, layer) {
      layer.bindPopup(function () {
        return nunjucks.render("popups/telrResultLayerPopup.njk", {
          ...feature.properties,
          color: TelrResultLayerFunctions.getColorForFeature(feature),
          dataScenario: Tree.get("dataScenario"),
        });
      }, getPopupOptions());
      layer.on({
        mouseover: telrHighlight,
        mouseout: telrUnhighlight,
        click: (e) => Table.expandTableAndScroll(e.target.feature.properties.gid),
      });
    },
  },
  // includes: TelrMixin
});

var telrHighlight = function (e) {
  var gid = e.target.feature.properties.gid;
  viewHighlight(e);
  handleTableHighlight(gid);
};

var telrUnhighlight = function (e) {
  var styler = e.target.defaultOptions.style;
  var disabled = e.target.feature.properties.disabled;
  var gid = e.target.feature.properties.gid;
  if (disabled) {
    e.target.setStyle(MapStyles.grayResults());
  } else {
    e.target.setStyle(styler(e.target.feature));
  }
  handleTableHighlight(gid);
};

module.exports.highlightDrainage = highlightDrainage;
module.exports.selectPolygon = selectPolygon;
module.exports.setDrainageHighlight = setDrainageHighlight;
module.exports.viewHighlight = viewHighlight;
module.exports.viewUnhighlight = viewUnhighlight;
module.exports.highlight = highlight;
module.exports.unhighlight = unhighlight;
module.exports.CatchmentMixin = CatchmentMixin;
module.exports.clearHighlightDrainage = clearHighlightDrainage;
module.exports.getWatershedLayerOptions = getWatershedLayerOptions;
module.exports.getBoundsLayer = getBoundsLayer;
module.exports.getOtherPropertiesLayer = getOtherPropertiesLayer;
module.exports.customPolygonLayer = customPolygonLayer;
module.exports.customLineLayer = customLineLayer;
