"use strict";

const IncidentHistory = function () {
  var renderIncidentHistory = function (data, displayRequestedDate) {
    if (!data.some((el) => el.displayType === "Request")) {
      data.push({
        displayType: "Request",
        displayDate: displayRequestedDate,
        noActions: true,
      });
    }
    var html = nunjucks.render("popups/history/incidentHistory.njk", {
      assessments: data,
    });
    $(".popup-history-pane")
      .html(html)
      .off("click", ".download-report", onDownloadReport)
      .on("click", ".download-report", onDownloadReport);
  };

  var onDownloadReport = async function () {
    const id = $(this).closest("tr").data("id");
    await ApiCalls.downloadIncidentInvestigationPdf(id);
  };

  var editHistory = async function (tableRowData, readOnly) {
    var investigationId = tableRowData.id;
    await IncidentInvestigationController.loadIncidentInvestigation({
      maybeId: investigationId,
      readOnly: readOnly,
    });
  };

  var confirmDeleteHistory = function ($todoTr, tableRowData) {
    var selectedId = tableRowData.id;

    MessageModal.showDeleteRecordModal(() => {
      deleteHistory(selectedId);
      $todoTr.remove();
    });
  };

  var deleteHistory = function (id) {
    ApiCalls.deleteIncidentInvestigation(id, IncidentLayer.invalidateLayerData);
  };

  return {
    editHistory,
    confirmDeleteHistory,
    renderIncidentHistory,
  };
};

module.exports = IncidentHistory();

const ApiCalls = require("../../apiCalls");
const MessageModal = require("../../modals/messageModal");
const IncidentLayer = require("./incidentLayer");
const IncidentInvestigationController = require("../incidentInvestigation/incidentInvestigationController");
