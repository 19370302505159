"use strict";

const MuniPipesGeoServerLayer = function () {
  const layerName = "muniPipes";
  const layerKey = "muniPipesLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const muniPipesGeoServerLayer = new GeoServerMapLayer(layerName, layerKey);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, muniPipesGeoServerLayer);

    muniPipesGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniPipesGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await ApiCalls.getMuniPipes();
    return _organizeLayerData(data);
  };

  var _organizeLayerData = function (layerData) {
    return layerData.map((data) => getOrganizedProperties(data));
  };

  var getOrganizedProperties = function (data) {
    return {
      id: data.id,
      groupId: data.group_id,
      name: data.name,
      type: data.pipe_type,
      cof: data.cof_final_text,
      material: data.material,
      phase: data.phase,
      landOwnership: data.property,
      implementerType: data.implementer_type,
      maintainedBy: data.responsible_party_maintenance,
      installDate: data.install_date,
      flowType: data.flow_direction,
      serviceType: data.line_service_type,
      lastInspectionId: data.last_maintenance_inspection_id,
      accessible: data.accessible,
      shape: data.shape,
      lined: data.lined,
      lastInspectionDue: data.last_maintenance_inspection_due,
      correctiveAction: data.corrective_actions ? JSON.parse(data.corrective_actions) : null,
      enforcementLevel: data.severity ? JSON.parse(data.severity) : null,
      condition: RoutineMaintenanceIcon.getLayerCondition(
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
      icon: RoutineMaintenanceIcon.getMaintenanceIcon(
        "muni-pipes",
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
      diameter: data.diameter,
      diameterUnits: data.diameter_units,
      length: data.length,
      lengthUnits: data.length_units,
      width: data.width,
      widthUnits: data.width_units,
      catchment_names: JSON.parse(data.catchment_names),
      drains_to_rws: JSON.parse(data.drains_to_rws),
      drains_to_cs: JSON.parse(data.drains_to_cs),
      zone_names: data.zone_names ? JSON.parse(data.zone_names) : null,
      routine: data.routine_inspection_dates ? JSON.parse(data.routine_inspection_dates) : null,
      repair: data.repair_dates ? JSON.parse(data.repair_dates) : null,
    };
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
    _organizeLayerData,
  };
};

module.exports = MuniPipesGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
const ApiCalls = require("../apiCalls");
