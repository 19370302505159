"use strict";

const BmpFcsInventoryBmpFcsInformation = function () {
  const stringKey = "bmp-fcs-information";
  let $page;
  let $inventoryModal;

  var loadSbmpInformationListeners = function () {
    $page.on("change", `[name="system-access"]`, toggleClosedSpaceFields);
    $page.on("change", `[name="fcs"]`, _fcsToggleUpdated);
    $page.on("change", `[name="design-depth-unit"]`, setSumpMinMax);
    $page.on("change", `[name="inlets"], [name="outlets"]`, inletsOutletsUpdated);
    loadCommonPageListeners();
  };

  var loadFcsInformationListeners = function () {
    $page.on(
      "change",
      "[name=multi-system]",
      BmpFcsInventoryModalController.toggleMultiSystemFields,
    );
    $page.on("change", `[name="inlets"], [name="outlets"]`, inletsOutletsUpdated);
    $page.on(
      "change",
      "[name=treats-storm]",
      BmpFcsInventoryModalController.toggleTreatsStormFields,
    );
    loadCommonPageListeners();
  };

  var loadMuniInformationListeners = function () {
    $page.on("change", `[name="fcs"]`, _fcsToggleUpdated);
    $page.on("change", `[name="design-depth"]`, setTypeBasedOnDepth);
    loadCommonPageListeners();
  };

  var removeSbmpInformationListeners = function () {
    $page.off("change", `[name="system-access"]`, toggleClosedSpaceFields);
    $page.off("change", `[name="fcs"]`, _fcsToggleUpdated);
    removeCommonPageListeners();
  };

  var removeMuniInformationListeners = function () {
    $page.off("change", `[name="fcs"]`, _fcsToggleUpdated);
    $page.off("change", `[name="design-depth"]`, setTypeBasedOnDepth);
    removeCommonPageListeners();
  };

  var removeFcsInformationListeners = function () {
    $page.off(
      "change",
      "[name=multi-system]",
      BmpFcsInventoryModalController.toggleMultiSystemFields,
    );
    $page.off(
      "change",
      "[name=treats-storm]",
      BmpFcsInventoryModalController.toggleTreatsStormFields,
    );
    removeCommonPageListeners();
  };

  var loadCommonPageListeners = function () {
    $page.on("change", `[name="bmp-type"]`, bmpTypeFieldChanged);
    $page.on("change", `[name="property"]`, propertyFieldUpdated);
    $page.on("change", `[name="visible-screen"]`, toggleScreenCountField);
    $page.on("change", `[name="treated-storm-type"]`, toggleTreatedStormFields);
    $page.on("change", `[name="sump"]`, toggleSumpDepthField);
    $page.on("change", `[name="shape"]`, toggleDiameterField);
    $page.on("change", `[name="wall-material"]`, matchSimilarFields);
    $page.on("change", `[name="footprint-material"]`, matchSimilarFields);

    Form.initializeAndLoadListeners($page, "bmp-fcs-inventory", { isMultiPart: true });
  };

  var removeCommonPageListeners = function () {
    $page.off("change", `[name="property"]`, propertyFieldUpdated);
    $page.off("change", `[name="bmp-type"]`, bmpTypeFieldChanged);
    $page.off("change", `[name="visible-screen"]`, toggleScreenCountField);
    $page.off("change", `[name="treated-storm-type"]`, toggleTreatedStormFields);
    $page.off("click", `input[name=treatment-train]`);
    $page.off("change", `[name="sump"]`, toggleSumpDepthField);
    $page.off("change", `[name="shape"]`, toggleDiameterField);
    $page.off("change", `[name="wall-material"]`, matchSimilarFields);
    $page.off("change", `[name="footprint-material"]`, matchSimilarFields);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    const options = BmpFcsInventoryModalController.getOptions();
    let mode;
    switch (options.mode) {
      case "bmpram":
      case "scenario":
        mode = "sBMP";
        break;
      case "trashram":
        mode = "FCS";
        break;
      case "muni":
        mode = "Catch Basin";
        break;
    }
    return `${mode} Info`;
  };

  var render = async function () {
    const options = BmpFcsInventoryModalController.getOptions();
    await renderPageHtml(options);
    disableTypesByMode(options.mode);
    BmpFcsInventoryModalController.initializeDropzones(options.readOnly);
    BmpFcsInventoryModalController.addHighlightsToFields($page);
    let showUploadHint = true;
    if (options.mode === "scenario") showUploadHint = false;
    InventoryModal.toggleUploadHint(showUploadHint);
    InventoryModal.restoreNavigationButtons();
  };

  var disableTypesByMode = function (mode) {
    let disabledTypes = [];
    if (mode === "bmpram") {
      disabledTypes = FormConstants.fcsOnlyTypeNumbers;
    } else if (mode === "trashram") {
      disabledTypes = FormConstants.bmpOnlyTypeNumbers;
    }

    disabledTypes.forEach((typeVal) => {
      $inventoryModal.find($(`[name="bmp-type"] [value=${typeVal}]`)).prop("disabled", true);
    });
  };

  var renderPageHtml = async function () {
    let renderData = InventoryModal.getAllData("bmp-fcs-inventory");
    renderData = getProps(renderData);
    renderData.responsiblePartyMaintenanceOptions =
      BmpFcsInventoryConstants.responsiblePartyMaintenanceOptions;

    const html = nunjucks.render(
      "modals/bmpFcsInventory/bmpFcsInventoryBmpFcsInformation.njk",
      renderData,
    );
    const fromUpdate = Tree.get("fromUpdate");
    BmpFcsInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
    $inventoryModal = $("#inventory-modal");
    loadAdditionalPageListeners();
    runFieldFunctions(renderData.bmpType);
    if (!fromUpdate) {
      $("select[name='bmp-type'] option:contains('(?) Unknown')").prop("selected", false);
    }
  };

  var loadAdditionalPageListeners = function () {
    const options = BmpFcsInventoryModalController.getOptions();
    switch (options.mode) {
      case "bmpram":
      case "scenario":
        loadSbmpInformationListeners();
        break;
      case "trashram":
        loadFcsInformationListeners();
        break;
      case "muni":
        loadMuniInformationListeners();
        break;
    }
  };

  var showFcsFieldByType = function (type) {
    type = parseInt(type);
    const options = BmpFcsInventoryModalController.getOptions();

    if (options.mode === "bmpram" || options.mode === "muni") {
      const isBmpOnlyType = BmpFcsFunctions.isBmpOnlyType(type) || type === 16;
      $page.find(".is-fcs").toggleClass("hidden", isBmpOnlyType);
      if (isBmpOnlyType) {
        $page.find("input[name='fcs'][value='false']").prop("checked", true).trigger("input");
        toggleAdditionalFcsPage();
      }
    } else if (options.mode === "trashram") {
      const isFcsOnlyType = BmpFcsFunctions.isFcsOnlyType(type) || type === 16;
      BmpFcsInventoryModalController.setFormDataField("bmp", !isFcsOnlyType);
      toggleAdditionalBmpPage();
    }
  };

  var showAdditionalFieldsByType = function (type, $modalPage = $page) {
    const typeData = FormConstants.inventoryTypes[type];
    toggleSbmpInformationSectionByType(type);
    toggleCatchBasinInformationSectionByType(type);
    if (typeData.required.length === 0 && typeData.optional.length === 0) {
      hideAllFields($modalPage, true);
    } else {
      hideAllFields($modalPage, false);
      typeData.required.forEach((field) => {
        toggleAdditionalFieldVisible($modalPage, field, true);
      });
      typeData.optional.forEach((field) => {
        toggleAdditionalFieldVisible($modalPage, field, false);
      });
    }
  };

  var toggleSbmpInformationSectionByType = function (type) {
    const options = BmpFcsInventoryModalController.getOptions();
    const toggleBmpInfoSections =
      BmpFcsFunctions.isPriorityCatchBasinType(type) && ["bmpram", "muni"].includes(options.mode);

    $(".sbmp-information").toggleClass("hidden", toggleBmpInfoSections);
  };

  var toggleCatchBasinInformationSectionByType = function (type) {
    const toggle = !BmpFcsFunctions.isPriorityCatchBasinType(type);
    $(".catch-basin-information").toggleClass("hidden", toggle);
  };

  var hideAllFields = function ($modalPage, toggle) {
    const extraFields = $modalPage.find(".bmp-type-specific-info");
    extraFields.toggleClass("hidden", toggle);
    extraFields.find("fieldset, .fieldset").toggleClass("hidden", true);
  };

  var toggleBasicFieldVisible = function (fieldName, visibility) {
    const $field = $page
      .find(`input[name=${fieldName}], select[name=${fieldName}]`)
      .parents("fieldset");
    $field.toggleClass("hidden", !visibility);
  };

  var toggleAdditionalFieldVisible = function ($modalPage, fieldName, required) {
    const $field = $modalPage.find(`[data-field=${fieldName}]`);
    $field.find("legend").toggleClass("required", required);
    $field.toggleClass("hidden", false);
  };

  var getProps = function (renderData) {
    const options = BmpFcsInventoryModalController.getOptions();
    return {
      ...renderData,
      renderMode: options.mode,
      ...getBmpFcsOptionProps(),
      ...BmpFcsInventoryAdditionalBmpInformation.getBmpOptionProps(),
      ...BmpFcsInventoryAdditionalFcsInformation.getFcsOptionProps(),
      ...BmpFcsInventoryAdditionalCatchBasinInformation.getCatchBasinOptionProps(),
    };
  };

  var getBmpFcsOptionProps = function () {
    return {
      bmpTypeOptions: BmpFcsInventoryConstants.bmpTypeOptions,
      catchBasinTypeOptions: BmpFcsInventoryConstants.catchBasinTypeOptions,
      propertyTypeOptions: BmpFcsInventoryConstants.propertyTypeOptions,
      systemAccessOptions: BmpFcsInventoryConstants.systemAccessOptions,
      catchBasinPriorityOptions: BmpFcsInventoryConstants.catchBasinPriorityOptions,
      cofOptions: BmpFcsInventoryConstants.cofOptions,
      unitOptions: BmpFcsInventoryConstants.unitOptions,
      datumOptions: BmpFcsInventoryConstants.datumOptions,
      treatedStormTypeOptions: BmpFcsInventoryConstants.treatedStormTypeOptions,
      treatedStormPercentileOptions: BmpFcsInventoryConstants.treatedStormPercentileOptions,
      responsiblePartyDeliveryOptions: BmpFcsInventoryConstants.responsiblePartyDeliveryOptions,
    };
  };

  var propertyFieldUpdated = function () {
    _updateResponsiblePartyMaintenance();
    toggleCatchBasinPriorityField();
  };

  var setDefaultResponsiblePartyMaintenance = function (setValue) {
    if (BmpFcsInventoryModalController.hasBeenSaved()) {
      return;
    }
    BmpFcsInventorySetLocation.assertValidResponsiblePartyMaintenance(setValue);

    BmpFcsInventoryModalController.setFormDataField("responsiblePartyMaintenance", setValue);
  };

  var _updateResponsiblePartyMaintenance = function () {
    const propertyStatus = parseInt(
      BmpFcsInventoryModalController.getLatestBmpInventoryData("property"),
    );
    const propertyTypeOptions = BmpFcsInventoryConstants.propertyTypeOptions;

    setDefaultResponsiblePartyMaintenance(
      propertyTypeOptions.find((option) => option.value === propertyStatus)
        .responsiblePartyMaintenance,
    );
  };

  var toggleCatchBasinPriorityField = function () {
    const bmpType = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");
    const propertyStatus = BmpFcsInventoryModalController.getLatestBmpInventoryData("property");
    const showField =
      BmpFcsFunctions.isPriorityCatchBasinType(bmpType) && parseInt(propertyStatus) === 0;
    const currentPriority =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("catchBasinPriority");

    $page.find(".catch-basin-priority").toggleClass("hidden", !showField);
    if (!showField) {
      if (BmpFcsInventoryModalController.getExistingData("catchBasinPriority")) {
        BmpFcsInventoryModalController.setFormDataField("catchBasinPriority", null);
      } else {
        BmpFcsInventoryModalController.unsetFormDataField("catchBasinPriority");
      }
    } else if (!currentPriority) {
      $page
        .find("input[name='catch-basin-priority'][value='low']")
        .prop("checked", true)
        .trigger("input");
    }
  };

  var toggleClosedSpaceFields = function () {
    const type = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");
    const toggle =
      BmpFcsFunctions.isPriorityCatchBasinType(type) ||
      BmpFcsInventoryModalController.getLatestBmpInventoryData("systemAccess");

    $page.find(".inaccessible-information").toggleClass("hidden", toggle);
  };

  var bmpTypeFieldChanged = function (e) {
    _toggleBmpTypeFields(parseInt($(e.currentTarget).val()));
  };

  var _toggleBmpTypeFields = function (currentType) {
    runFieldFunctions(currentType);
  };

  var runFieldFunctions = function (currentType) {
    toggleBasicFieldsByType(currentType);
    toggleCatchBasinPriorityField();
    toggleDbmpFields(currentType);
    setDefaultValuesByType(currentType);
    showFcsFieldByType(currentType);
    showAdditionalFieldsByType(currentType);
    BmpFcsInventoryModalController.toggleTelrRequiredFieldsByType(currentType, true);
    toggleSumpDepthField();
    handleTreatmentTrainField();
    setTreatmentTrainDataField();
    toggleTreatedStormFields();
    toggleClosedSpaceFields();
  };

  var toggleDbmpFields = function (currentType) {
    const options = BmpFcsInventoryModalController.getOptions();
    const isDbmp = BmpFcsFunctions.isDecentralizedBMPType(currentType);

    var fieldsToHide = [];
    if (options.mode === "scenario") {
      const fieldsToHideInScenario = ["diversion", "low-flow"];
      fieldsToHide = fieldsToHideInScenario.concat(FormConstants.fieldsToHideForDbmpTypes);
    } else {
      fieldsToHide = FormConstants.fieldsToHideForDbmpTypes;
    }

    fieldsToHide.forEach((field) => {
      toggleBasicFieldVisible(field, !isDbmp);
    });
  };

  var toggleBasicFieldsByType = function (currentType) {
    const catchBasinBasicFields = BmpFcsInventoryConstants.catchBasinBasicFields;
    const bmpBasicFields = BmpFcsInventoryConstants.bmpBasicFields;
    const isCatchBasin = BmpFcsFunctions.isPriorityCatchBasinType(currentType);
    catchBasinBasicFields.forEach(function (catchBasinField) {
      toggleBasicFieldVisible(catchBasinField, isCatchBasin);
    });

    bmpBasicFields.forEach(function (bmpField) {
      toggleBasicFieldVisible(bmpField, !isCatchBasin);
    });
  };

  var setDefaultValuesByType = function (type) {
    setSystemAccessDefault(type);
    setCatchBasinDefaults(type);
  };

  var setSystemAccessDefault = function (type) {
    const systemAccessDefault = FormConstants.systemAccessNumbers;
    if (systemAccessDefault.includes(type)) {
      $page
        .find("input[name='catch-basin-priority'][value='false']")
        .prop("checked", true)
        .trigger("input");
    }
  };

  var setCatchBasinDefaults = function (type) {
    if (BmpFcsFunctions.isPriorityCatchBasinType(type)) {
      const mode = BmpFcsInventoryModalController.getOptions()?.mode;
      const $sump = $page.find("input[name=sump]");

      if (mode === "bmpram") {
        if (type == 11) {
          $sump
            .prop("disabled", true)
            .filter("[value=true]")
            .prop("checked", true)
            .trigger("input");
        } else if (type == 12) {
          $sump.prop("disabled", false);
        }
        setSumpMinMax();
      } else if (mode === "trashram") {
        if (type == 11) {
          BmpFcsInventoryModalController.setFormDataField("sump", true);
        }
        updateSumpDepthValue();
      } else if ($sump.length) {
        $sump.prop("disabled", false);
        setSumpMinMax();
      }
    }
  };

  var getSumpMinMax = function () {
    const unit =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("designDepthUnit") ?? "feet";
    const bmpType = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");
    const options = BmpFcsInventoryModalController.getOptions();
    const max = NumberInput.getMaximumValueConstant();

    return {
      emptyInputBehavior: "min",
      minimumValue:
        options.mode === "muni" ? 0 : bmpType == 11 ? (unit === "feet" ? 1.1 : 13.2) : 0,
      maximumValue:
        options.mode === "muni" ? max : bmpType == 12 ? (unit === "feet" ? 1 : 12) : max,
    };
  };

  var setSumpMinMax = function () {
    const minMax = getSumpMinMax();
    const $sumpDepth = $page.find(".sump-depth input[name=design-depth]");

    const currentValue =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("designDepth") ??
      minMax.minimumValue;
    const clampedValue = Misc.clamp(minMax.minimumValue, currentValue, minMax.maximumValue);
    NumberInput.updateInputOptions($sumpDepth[0], {
      overrideMinMaxLimits: "ignore",
    });
    NumberInput.manuallySetInput($sumpDepth[0], clampedValue);
    NumberInput.updateInputOptions($sumpDepth[0], {
      overrideMinMaxLimits: null,
      ...minMax,
    });
  };

  var updateSumpDepthValue = function () {
    const minMax = getSumpMinMax();
    const currentValue =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("designDepth") ??
      minMax.minimumValue;
    const clampedValue = Misc.clamp(minMax.minimumValue, currentValue, minMax.maximumValue);
    BmpFcsInventoryModalController.setFormDataField("designDepth", clampedValue);
  };

  var setTypeBasedOnDepth = function () {
    const sumpDepthUnit =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("designDepthUnits") ?? "feet";
    const sumpDepth = BmpFcsInventoryModalController.getLatestBmpInventoryData("designDepth");

    const finalSumpDepth =
      sumpDepthUnit === "inches" ? UnitConversion.inchesToFeet(sumpDepth) : sumpDepth;
    const bmpType = finalSumpDepth > 1 ? 11 : 12;

    BmpFcsInventoryModalController.setFormDataField("bmpType", bmpType);
  };

  var toggleSumpDepthField = function () {
    const options = BmpFcsInventoryModalController.getOptions();
    const toggle = BmpFcsInventoryModalController.getLatestBmpInventoryData("sump");
    $page.find(".sump-depth").toggleClass("hidden", !toggle);
    if (!toggle && options.mode === "muni") {
      BmpFcsInventoryModalController.setFormDataField("bmpType", 12);
    } else if (options.mode === "muni") {
      setTypeBasedOnDepth();
    }
  };

  var toggleDiameterField = function () {
    const toggle = BmpFcsInventoryModalController.getLatestBmpInventoryData("shape") === "circular";
    $page.find(".length, .width").toggleClass("hidden", toggle);
    $page.find(".diameter").toggleClass("hidden", !toggle);
  };

  var _fcsToggleUpdated = function () {
    toggleAdditionalFcsPage();
  };

  var toggleAdditionalFcsPage = function () {
    const toggle = BmpFcsInventoryModalController.getLatestBmpInventoryData("fcs");
    BmpFcsInventoryModalController.toggleAdditionalPage("fcs", toggle);
  };

  var toggleAdditionalBmpPage = function () {
    const bmpType = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");
    if (BmpFcsFunctions.isPriorityCatchBasinType(bmpType)) {
      BmpFcsInventoryModalController.toggleAdditionalPage("catch-basin", true);
    } else {
      const toggle = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmp");
      BmpFcsInventoryModalController.toggleAdditionalPage("bmp", toggle);
    }
  };

  var toggleScreenCountField = function () {
    const toggle = BmpFcsInventoryModalController.getLatestBmpInventoryData("visibleScreen");
    $page.find(`[name="screen-count"]`).toggleClass("default-hidden", !toggle);
  };

  var cleanUp = function () {
    const options = BmpFcsInventoryModalController.getOptions();
    if (options.mode === "bmpram") {
      removeSbmpInformationListeners();
    } else if (options.mode === "trashram") {
      removeFcsInformationListeners();
    } else if (options.mode === "muni") {
      removeMuniInformationListeners();
    }
    $page.empty();
    InventoryModal.toggleUploadHint(false);
    return true;
  };

  var handleTreatmentTrainField = function () {
    const currentType = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");
    const isCentralized = BmpFcsFunctions.isCentralizedBMPType(currentType);
    const isInTreatmentTrain = BmpFcsInventoryModalController.getLatestBmpInventoryData("tt");
    const centralizedBmps =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("centralizedBmps") ?? [];
    const nonTreatmentTrainsCbmp = getNonTreatmentTrainCbmps(centralizedBmps);
    const isCurrentTypeExcludedTreatmentTrain =
      BmpFcsInventoryConstants.nonTreatmentTrainsTypes.includes(parseInt(currentType));
    if (
      isCentralized &&
      (nonTreatmentTrainsCbmp.length > 0 || isInTreatmentTrain) &&
      !isCurrentTypeExcludedTreatmentTrain
    ) {
      showTreatmentTrainField(true);
    } else {
      showTreatmentTrainField(false);
    }
  };

  var setTreatmentTrainDataField = function () {
    const isInTreatmentTrain = BmpFcsInventoryModalController.getLatestBmpInventoryData("tt");
    const centralizedBmps =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("centralizedBmps") ?? [];
    const nonTreatmentTrainsCbmp = getNonTreatmentTrainCbmps(centralizedBmps);

    if (nonTreatmentTrainsCbmp.length > 0 && !isInTreatmentTrain) {
      BmpFcsInventoryModalController.setFormDataField(
        "ttFlowsTo",
        nonTreatmentTrainsCbmp[0]?.idbmp,
      );
    }
  };

  var getNonTreatmentTrainCbmps = function (centralizedBmps) {
    const currentId = BmpFcsInventoryModalController.getExistingData("idbmp");

    return centralizedBmps.filter(function (cBmp) {
      return currentId !== cBmp.idbmp && !cBmp?.tt;
    });
  };

  var showTreatmentTrainField = function (showField) {
    $page.find(".treatment-train").toggleClass("hidden", !showField);
  };

  var matchDrainageValuesToCentralizedBmp = async function (cBmp) {
    const drawMethod = "drawing-tools";
    BmpFcsInventoryModalController.setFormDataField(
      "dissolvedDrainage",
      cBmp?.dissolvedDrainage?.geom,
    );
    await DrawController.setDrawnDrainageGeom(JSON.parse(cBmp?.dissolvedDrainage?.geom));
    BmpFcsInventoryModalController.setFormDataField("drawMethod", drawMethod);

    const acresPath = ["drawAcres", "acres"];
    const pluTreatedPath = ["drawAcres", "pluAcres"];
    BmpFcsInventoryModalController.setFormDataField(acresPath, cBmp?.drainageArea);
    BmpFcsInventoryModalController.setFormDataField(pluTreatedPath, cBmp?.pluTreated);
  };

  var toggleTreatedStormFields = function () {
    const treatedStormType =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("treatedStormType");
    const bmpType = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");

    if (treatedStormType && !BmpFcsFunctions.isPriorityCatchBasinType(bmpType)) {
      const togglePercentile = treatedStormType === "24-hr-percentile";
      $page.find(".treated-storm-percentile").toggleClass("hidden", !togglePercentile);
      $page.find(".treated-storm-depths-inches").toggleClass("hidden", togglePercentile);
    } else {
      $page.find(".treated-storm-percentile").toggleClass("hidden", true);
      $page.find(".treated-storm-depths-inches").toggleClass("hidden", true);
    }
  };

  var updateObservationDefaults = function () {
    if (BmpFcsInventoryModalController.hasBeenSaved()) {
      return;
    }

    const inlets = BmpFcsInventoryModalController.getLatestBmpInventoryData("inlets");
    BmpFcsInventoryModalController.setFormDataField(
      "maintenanceObservationStructuralConditionInlet",
      inlets > 0,
    );

    const outlets = BmpFcsInventoryModalController.getLatestBmpInventoryData("outlets");
    BmpFcsInventoryModalController.setFormDataField(
      "maintenanceObservationStructuralConditionOutlet",
      outlets > 0,
    );
  };

  var matchSimilarFields = function (e) {
    const $currentTarget = $(e.currentTarget);
    const currentField = $currentTarget.attr("name");
    $(`[name="${currentField}"]`).val($currentTarget.val());
  };

  var validate = function () {
    return true;
  };

  var inletsOutletsUpdated = function () {
    updateObservationDefaults();
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    matchDrainageValuesToCentralizedBmp,
    runFieldFunctions,
    setTypeBasedOnDepth,
    setDefaultResponsiblePartyMaintenance,
    getSumpMinMax,
    setSumpMinMax,
    toggleDiameterField,
    toggleSumpDepthField,
    _updateResponsiblePartyMaintenance,
    _fcsToggleUpdated,
    _toggleBmpTypeFields,
    showAdditionalFieldsByType,
    showFcsFieldByType,
  };
};

module.exports = BmpFcsInventoryBmpFcsInformation();

const BmpFcsFunctions = require("./bmpFcsFunctions");
const BmpFcsInventoryAdditionalBmpInformation = require("./bmpFcsInventoryAdditionalBmpInformation");
const BmpFcsInventoryAdditionalCatchBasinInformation = require("./bmpFcsInventoryAdditionalCatchBasinInformation");
const BmpFcsInventoryAdditionalFcsInformation = require("./bmpFcsInventoryAdditionalFcsInformation");
const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const DrawController = require("../mapFunctions/drawController");
const Form = require("../general/form");
const FormConstants = require("../mapFunctions/formConstants");
const InventoryModal = require("../general/inventoryModal");
const Misc = require("../misc");
const NumberInput = require("../general/numberInput");
const UnitConversion = require("../unitConversion");
const Tree = require("../tree");
const BmpFcsInventorySetLocation = require("./bmpFcsInventorySetLocation");
