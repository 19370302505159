"use strict";

const ParcelAssessmentConstants = function () {
  const parcelConditionOptions = [
    {
      name: "Runoff Neutral",
      value: "Yes",
      score: 5.0,
    },
    {
      name: "Reduced Runoff",
      value: "Partial",
      score: 3.0,
    },
    {
      name: "Status Quo",
      value: "No",
      score: 1.0,
    },
  ];

  const parcelConditionReasonOptions = [
    {
      name: "Roof",
      value: "Roof",
    },
    {
      name: "Impervious Surface",
      value: "Impervious_Surface",
    },
    {
      name: "Pervious Surface",
      value: "Pervious_Surface",
    },
    {
      name: "Perimeter",
      value: "Perimeter",
    },
    {
      name: "Other",
      value: "Other",
    },
  ];

  return {
    parcelConditionOptions,
    parcelConditionReasonOptions,
  };
};

module.exports = ParcelAssessmentConstants();
