"use strict";

const LocationPage = function () {
  const stringKey = "property-location";
  const headerInformation = "Location";
  let $page;
  let boundaryLayer;

  var loadSelectLocationListeners = function () {
    $page.on("input", "[name=property-id]", setPropertyIdentifierInput);
    $page.on("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.on("change", "[name=propertyType]", changePropertyType);
    Form.initializeAndLoadListeners($page, "property-inventory", { isMultiPart: true });
  };

  var removeListeners = function () {
    $page.off("input", "[name=property-id]", setPropertyIdentifierInput);
    $page.off("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.off("change", "[name=propertyType]", changePropertyType);
  };

  var changePropertyType = function () {
    BoundaryPage.calculateConsumptiveUse();
  };

  var setPropertyIdentifierInput = function () {
    setPropertyIdentifier($(this).val());
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    await renderPageHtml();
    ModalMap.removeMarkerLayer();
    showLocationSelectionMap(options);
    loadSelectLocationListeners();
    disableUnavailableElements();
    showBottomPanel(options?.existingLocation ?? false);
    ModalMap.invalidateModalMapSize();
  };

  var renderPageHtml = async function () {
    const renderData = InventoryModal.getAllData("property-inventory");
    const html = nunjucks.render("esg/modals/propertyInventory/locationPage.njk", renderData);

    $page = $(".modal-dynamic-content");
    InventoryModal.setModalDynamicContent(html);
    $(".setLocationMessage").addClass("project-map-text-styles");
  };

  var showLocationSelectionMap = function (options) {
    const boundary = BoundaryPage.getLatestBoundary();
    const configuration = {
      checkNewMarkerHandler: checkCreateNewPropertyMarker,
      newExistingMarkerHandler: PropertyInventoryModalController.loadExistingPropertyMarker,
      locationDataUpdateHandler: updateSetLocationFooter,
      badLocationUpdateHandler: resetLatLngToLastValidValue,
    };
    LocationSelectionMap.configureLocationModalMapHandlers(configuration);
    LocationSelectionMap.showLocationSelectionMap(options?.existingLocation ?? false, !boundary);
    LocationSelectionMap.attachGeocoding(options?.existingLocation);
    setLocationMapText();
    _addBoundaryToMap(boundary);
    _zoomToBoundary(boundary);
  };

  var _addBoundaryToMap = function (boundary) {
    if (!boundary) return;
    const map = ModalMap.getMap();
    boundaryLayer = L.geoJSON(boundary, { style: MapStyles.styleActivePropertyPolygon });
    boundaryLayer.addTo(map);
  };

  var _removeBoundaryLayer = function () {
    if (!boundaryLayer) return;
    const map = ModalMap.getMap();
    map.removeLayer(boundaryLayer);
  };

  var _zoomToBoundary = function (boundary) {
    if (!boundary) return;
    const map = ModalMap.getMap();
    const bounds = L.geoJSON(boundary).getBounds();
    map.fitBounds(bounds);
  };

  var checkCreateNewPropertyMarker = function () {
    var id = PropertyInventoryModalController.getLatestData("propertyId");
    var isNewMarker = !id;
    return isNewMarker;
  };

  var updateSetLocationFooter = async function (locationInfo, latlng) {
    const promise = updateLocationInfo(locationInfo);
    updateLatLngFields(latlng);
    showBottomPanel(true);
    const $propertyIdentifier = $page.find("input[name='property-id']");
    if ($propertyIdentifier.attr("disabled")) {
      Misc.toggleDisabled($propertyIdentifier, false);
      $propertyIdentifier.focus();
    }

    return promise;
  };

  var updateLocationInfo = function (locationInfo) {
    PropertyInventoryModalController.setData("zip", locationInfo.zip);
    PropertyInventoryModalController.setData("state", locationInfo.state);
    PropertyInventoryModalController.setData("city", locationInfo.city);
    PropertyInventoryModalController.setData("address", locationInfo.address);
    PropertyInventoryModalController.setData("county", locationInfo.county);

    setInputField("address", locationInfo.address, true);
    setInputField("city", locationInfo.city, true);
    setInputField("county", locationInfo.county, true);
    setInputField("zip", locationInfo.zip, true);
    setInputField("state", locationInfo.state, true);
  };

  var setInputField = function (field, value, updateForm) {
    if (field === "state") {
      value = Misc.convertStateForAutoPopulate(value);
    }
    $page.find(`[name=${field}]`).val(value);
    if (updateForm) {
      PropertyInventoryModalController.setData(field, value);
    }
  };

  var updateLatLngFields = function (latlng) {
    const latInput = $page.find(`input[name="latitude"]`)[0];
    const longInput = $page.find(`input[name="longitude"]`)[0];

    if (latInput && longInput) {
      NumberInput.manuallySetInput(latInput, latlng.lat);
      NumberInput.manuallySetInput(longInput, latlng.lng);
    }

    PropertyInventoryModalController.setData("latitude", latlng.lat);
    PropertyInventoryModalController.setData("longitude", latlng.lng);
    restoreButtonsIfHasAllRequiredInputs();
  };

  var setLocationMapText = function () {
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      ModalMap.showLocationMessage("Click map or drag marker to change location");
    } else {
      ModalMap.showLocationMessage("");
    }
  };

  var disableUnavailableElements = function () {
    const propertyIdentifier = PropertyInventoryModalController.getLatestData("propertyIdentifier");
    if (propertyIdentifier === undefined || propertyIdentifier === "") {
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
      Misc.toggleDisabled($page.find("[name=property-id]"), true);
    } else {
      InventoryModal.restoreNavigationButtons();
    }
  };

  var setPropertyIdentifier = function (newId) {
    if (newId === PropertyInventoryModalController.getLatestData("propertyIdentifier")) {
      restoreButtonsIfHasAllRequiredInputs();
      PropertyInventoryModalController.unsetData("propertyIdentifier");
      PropertyInventoryModalController.setModalTitle();
    } else if (newId === "") {
      InventoryModal.allowedSaveState(false);
      PropertyInventoryModalController.unsetData("propertyIdentifier");
      PropertyInventoryModalController.setModalTitle();
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
    } else {
      PropertyInventoryModalController.setData("propertyIdentifier", newId);
      PropertyInventoryModalController.setModalTitle();
      restoreButtonsIfHasAllRequiredInputs();
    }
  };

  var updateLatLngFromInput = function () {
    if ($page.find(`input[name="latitude"]`).val() && $page.find(`input[name="longitude"]`).val()) {
      const e = {
        latlng: {
          lat: $page.find(`input[name="latitude"]`).val(),
          lng: $page.find(`input[name="longitude"]`).val(),
        },
      };
      LocationSelectionMap.locationSelectionMapClick(e);
      showBottomPanel(true);

      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var hasAllRequiredInputs = function () {
    const latitude = $page.find(`input[name="latitude"]`).val();
    const longitude = $page.find(`input[name="longitude"]`).val();
    const propertyIdentifier = $page.find(`input[name="property-id"]`).val();

    return latitude && longitude && propertyIdentifier;
  };

  var restoreButtonsIfHasAllRequiredInputs = function () {
    if (hasAllRequiredInputs()) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }
  };

  var validatePropertyIdentifier = async function () {
    const propertyIdentifier = PropertyInventoryModalController.getLatestData("propertyIdentifier");
    const existingIdentifier = PropertyInventoryModalController.getLatestData("propertyIdentifier");
    if (propertyIdentifier === existingIdentifier) {
      return true;
    } else {
      const isUnique = ApiCalls.checkPropertyIdUnique(propertyIdentifier);
      if (!isUnique) {
        MessageModal.showSimpleWarningModal(
          "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
      return isUnique;
    }
  };

  var resetLatLngToLastValidValue = function (latlng) {
    NumberInput.manuallySetInput($page.find(`input[name="latitude"]`)[0], latlng.lat);
    NumberInput.manuallySetInput($page.find(`input[name="longitude"]`)[0], latlng.lng);
  };

  var showBottomPanel = function (show) {
    $(".modal-dynamic-content").toggleClass("hidden", !show);
  };

  var cleanUp = function () {
    removeListeners();
    LocationSelectionMap.removeGeocoding();
    LocationSelectionMap.hideLocationSelectionMap();
    _removeBoundaryLayer();
    $page.empty();
    return true;
  };

  var validate = function () {
    return validatePropertyIdentifier().catch((err) => {
      throw err;
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
  };
};

module.exports = LocationPage();

const Form = require("../../../general/form");
const InventoryModal = require("../../../general/inventoryModal");
const NumberInput = require("../../../general/numberInput");
const LocationSelectionMap = require("../../../mapFunctions/locationSelectionMap");
const ModalMap = require("../../../mapFunctions/modalMap");
const Misc = require("../../../misc");
const MessageModal = require("../../../report/general/messageModal");
const ApiCalls = require("../../esgApiCalls");
const PropertyInventoryModalController = require("../propertyInventoryModalController");
const BoundaryPage = require("./boundaryPage");
const MapStyles = require("../../../mapFunctions/mapStyles");
require("../../../mapFunctions/geocodingControls");
