"use strict";

const DomFunctions = function () {
  const scrollIntoView = function ($element, $container, topPadding = 0) {
    if (!$element.length) return;
    if (!elementWithinContainer($element, $container)) {
      $container.stop().animate(
        {
          scrollTop:
            $element.offset().top - $container.offset().top + $container.scrollTop() - topPadding,
        },
        300,
      );
    }
  };

  const elementWithinContainer = function ($element, $container) {
    if (!$element.length || !$container.length) return false;
    var elementTop = $element.offset().top;
    var elementBottom = elementTop + $element.outerHeight();
    var containerTop = $container.offset().top;
    var containerBottom = containerTop + $container.outerHeight();
    return elementTop >= containerTop && elementBottom <= containerBottom;
  };

  const withinElement = function (event, $element) {
    var x = event.pageX - $element.offset().left;
    var y = event.pageY - $element.offset().top;

    var width = $element.width();
    var height = $element.height();

    return x >= 0 && x <= width && y >= 0 && y <= height;
  };

  const elementsHaveSameTop = function ($elements) {
    return $elements.toArray().every((element, i, arr) => element.offsetTop === arr[0].offsetTop);
  };

  const countRows = function ($elements) {
    const rows = $elements.toArray().reduce((acc, element, i, arr) => {
      if (i === 0 || element.offsetTop !== arr[i - 1].offsetTop) {
        return acc + 1;
      }
      return acc;
    }, 0);

    return rows;
  };

  return {
    scrollIntoView,
    elementWithinContainer,
    elementsHaveSameTop,
    withinElement,
    countRows,
  };
};

module.exports = DomFunctions();
