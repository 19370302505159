"use strict";

const LidEnforcementController = require("./lidEnforcementController");
const BaseEnforcementHistory = require("../../enforcement/baseEnforcementHistory");

const EnforcementHistory = function () {
  var prepareReasonsFunction = function (historyItem) {
    let reasons;
    if (historyItem.enforcementReason === "open-issues") {
      reasons = historyItem.openIssues.map((issue) =>
        EnforcementAction.getOpenIssueDisplay(issue, true),
      );
    } else if (historyItem.enforcementReason === "failed-sbmp-assessment") {
      reasons = historyItem.failingBmpAssessments;
    }
    return reasons;
  };

  const base = BaseEnforcementHistory(LidEnforcementController, { prepareReasonsFunction });

  return {
    ...base,
  };
};

module.exports = EnforcementHistory();

const EnforcementAction = require("./enforcementAction");
