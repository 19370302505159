"use strict";

var RegionalSubmitReport = function () {
  var updateSubmitReportForRegional = async function () {
    $("#regional-submit-report").show();
    _renderRegionalSubmitReportHeader();
    _renderRegionalSubmitReportViewDropdown();
    RegionalView.setRegionalViewDom();

    var regionalSubmitReportView = Tree.get("regionalSubmitReportView");
    if (regionalSubmitReportView === "permittee") {
      await RegionalSubmitReportPermitteeView.renderPermitteeTable();
    } else {
      await RegionalSubmitReportPermitRequirementView.renderPermitRequirementTable();
    }
    loadRegionalSubmitReportListeners();
  };

  var loadRegionalSubmitReportListeners = function () {
    $("#regional-submit-report-view-dropdown").off();
    $("#regional-submit-report-view-dropdown").on(
      "click",
      ".dropdown-menu li",
      regionalSubmitReportViewDropdownLiClick,
    );

    Tree.select("regionalSubmitReportView").release();
    Tree.select("regionalSubmitReportView").on("update", updateSubmitReportForRegional);
  };

  var regionalSubmitReportViewDropdownLiClick = function () {
    var regionalSubmitReportView = $(this).data("regionalSubmitReportView");
    setRegionalSubmitReportViewDropdown(regionalSubmitReportView);
  };

  var setRegionalSubmitReportViewDropdown = function (regionalSubmitReportView) {
    Tree.set("regionalSubmitReportView", regionalSubmitReportView);
    Sentry.getCurrentScope().setExtra("regionalSubmitReportView", regionalSubmitReportView);
    _setDropdownSelection(regionalSubmitReportView);
  };

  var _setDropdownSelection = function (regionalSubmitReportView) {
    var $selectedLi = PageFunctions.getCurrentPage()
      .find(
        `#regional-submit-report-view-dropdown li[data-regional-submit-report-view="${regionalSubmitReportView}"]`,
      )
      .closest("li");
    var dropdownButtonText = $selectedLi.find("a").text();

    Dropdown.select($selectedLi);
    _setDropdownButtonText(dropdownButtonText);
  };

  var _setDropdownButtonText = function (dropdownButtonText) {
    PageFunctions.getCurrentPage()
      .find("#regional-submit-report-view-dropdown .dropdown-btn-text")
      .html(dropdownButtonText);
  };

  var _renderRegionalSubmitReportHeader = function () {
    var year = AnnualReports.getSelectedYear();
    var rootGroup = Tree.get("rootGroup");
    var regionalGroupName = rootGroup.name;
    if (rootGroup.isRegion3Group) {
      regionalGroupName = "California Central Coast Region";
    }
    var title = `${year} Reporting Year for the ${regionalGroupName}`;
    $("#regional-submit-report .submit-report-header-title").html(title).show();
  };

  var _renderRegionalSubmitReportViewDropdown = function () {
    $("#regional-submit-report-view-dropdown").toggle(Progeny.rootGroupIsRegulator());
  };

  return {
    updateSubmitReportForRegional,
  };
};

module.exports = RegionalSubmitReport();

const Tree = require("../../tree");
const AnnualReports = require("../annualReports");
const Sentry = require("@sentry/browser");
const PageFunctions = require("../pageFunctions");
const Dropdown = require("../general/dropdown");
const RegionalSubmitReportPermitRequirementView = require("./regionalSubmitReportPermitRequirementView");
const RegionalSubmitReportPermitteeView = require("./regionalSubmitReportPermitteeView");
const RegionalView = require("../regionalView");
const Progeny = require("../../login/progeny");
