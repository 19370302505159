"use strict";

var Clustering = function () {
  const maxFeaturesToUncluster = 1000;
  const clusteringLayersKey = {
    bmps: "bmpLayer",
    fcs: "fcsLayer",
    sbmpProgress: "sbmpProgressLayer",
    fcsProgress: "fcsProgressLayer",
    constructionProjectProgress: "constructionProjectProgressLayer",
    lidProjectProgress: "lidProjectProgressLayer",
    muniCatchBasinProgress: "muniCatchBasinProgressLayer",
    incidentProgress: "incidentProgressLayer",
    outfallProgress: "outfallProgressLayer",
  };

  var update = function (layerName) {
    const InputsMap = require("../inputs/inputsMap");
    var map = InputsMap.getActiveInputsMap();
    var layers = InputsMap.getActiveLayers();
    updateMap(map, layers, layerName);

    const ReportMap = require("../report/reportMap");
    map = ReportMap.getActiveReportMap();
    layers = ReportMap.getActiveLayers();
    updateMap(map, layers, layerName);

    const PlanMap = require("../plan/planMap");
    map = PlanMap.getActivePlanMap();
    layers = PlanMap.getActiveLayers();
    updateMap(map, layers, layerName);
  };

  var updateMap = function (map, layers, layerName) {
    const layerKey = clusteringLayersKey[layerName];
    const targetLayer = layers[layerKey];

    if (targetLayer) {
      layers[layerKey] = updateMapLayer(map, targetLayer);
    }
  };

  var updateMapLayer = function (map, layer) {
    if (FastMarker.isFastMarkerLayer(layer)) {
      FastMarker.setVisibleFastMarkerLayer(layer);
      return layer;
    }

    var markers = layer.getLayers();
    if (layer) {
      map.removeLayer(layer);
    }
    if (clusteringIsEnabled()) {
      layer = createClusterGroup(markers).addTo(map);
    } else {
      layer = L.featureGroup(markers).addTo(map);
    }
    return layer;
  };

  var createClusterGroup = function (layers) {
    return L.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: getClusterRadius,
      chunkedLoading: true,
    }).addLayers(layers);
  };

  var setState = function (layer, isEnabled = null) {
    if (isEnabled === null) {
      isEnabled = clusteringIsEnabled();
    }

    if (layer !== null) {
      const tooManyFeatures = isLayerBeyondUnclusteringLengthLimit(layer);
      if (tooManyFeatures) {
        isEnabled = true;
      }
      disableCheckboxForLayer(layer, tooManyFeatures);
    }
    setCheckboxes(isEnabled, layer);

    setClusteringEnabled(isEnabled);
    Tree.set("clustered", { layer });
    update(layer);
  };

  var setCheckboxes = function (isEnabled, layer = null) {
    if (isEnabled) {
      const layerCheckboxes = getCheckboxesByLayer(layer);

      layerCheckboxes.$active.prop("checked", true);
      layerCheckboxes.$inactive.prop("checked", false);
    } else {
      const $checkboxes = $(".cluster input");

      $checkboxes.prop("checked", false);
    }
  };

  var getCheckboxesByLayer = function (layer) {
    const $checkboxes = $(".cluster input");
    const $active = $checkboxes.filter(`[data-layer='${layer}']`);
    const $inactive = $checkboxes.not($active);

    return { $active, $inactive };
  };

  var setEnabledCheckbox = function (layer, anyEnabled = true) {
    const $checkboxes = $(".cluster input");
    const $toEnable = getCheckboxesByLayer(layer).$active;

    setCheckboxes(false);

    $checkboxes.parent().toggleClass("disabled", true);

    if (anyEnabled) {
      if (isLayerBeyondUnclusteringLengthLimit(layer)) {
        $toEnable.parent().toggleClass("disabled", true);
        $toEnable.prop("checked", true);
      } else {
        $toEnable.parent().toggleClass("disabled", false);
        $toEnable.prop("checked", clusteringIsEnabled());
      }
    }
  };

  var disableClustering = function () {
    const layer = Tree.get("clustered", "layer");
    setState(layer, false);
  };

  var clusteringIsEnabled = function () {
    if (!sessionClusteringIsSet()) {
      return true;
    }

    return sessionStorage.getItem("clusteredIsEnabled") === "true";
  };

  var sessionClusteringIsSet = function () {
    return sessionStorage.getItem("clusteredIsEnabled") !== null;
  };

  var setClusteringEnabled = function (isEnabled) {
    sessionStorage.setItem("clusteredIsEnabled", isEnabled);
  };

  var getClusterRadius = function (mapZoom) {
    const iconSize = 34;

    if (mapZoom >= 16) {
      // ~500 ft-
      return iconSize / 2;
    } else if (mapZoom >= 14) {
      // ~2000 ft-
      return iconSize;
    } else if (mapZoom >= 12) {
      // ~1 mi-
      return iconSize * 2;
    } else {
      // ~over 1 mi
      return iconSize * 4;
    }
  };

  var disableCheckboxForLayer = function (layer, toggle = true) {
    const $toDisable = getCheckboxesByLayer(layer).$active;
    $toDisable.parent().toggleClass("disabled", toggle);
  };

  var isLayerBeyondUnclusteringLengthLimit = function (layerName) {
    const mapId = Tree.get("mapId");
    const layerKey = clusteringLayersKey[layerName];
    const currentMapLayers = TreeUpdates.getMapLayers(mapId);
    const layer = currentMapLayers[layerKey];
    const layerEnabled = Tree.get("layers", mapId, layerName, "isEnabled");

    if (layer === null || !layerEnabled) {
      return false;
    }
    if (FastMarker.isFastMarkerLayer(layer)) {
      return FastMarker.isMaxVisible(layer);
    }
    if (layer.getLayers().length === 0) {
      return false;
    }
    let layerLength;
    if (layer.getLayers()[0].feature !== undefined) {
      layerLength = layer.getLayers().length > maxFeaturesToUncluster;
    } else {
      layerLength = layer.getLayers()[0].getLayers().length;
    }
    return layerLength > maxFeaturesToUncluster;
  };

  var getMaxFeaturesToUncluster = function () {
    return maxFeaturesToUncluster;
  };

  return {
    setState,
    setEnabledCheckbox,
    disableClustering,
    clusteringIsEnabled,
    isLayerBeyondUnclusteringLengthLimit,
    createClusterGroup,
    getMaxFeaturesToUncluster,
  };
};

module.exports = Clustering();

const Tree = require("../../tree");
const TreeUpdates = require("./treeUpdates");
const FastMarker = require("./fastMarker");

require("leaflet.markercluster");
