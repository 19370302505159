"use strict";

const ProjectBmpPopup = function () {
  var setProjectBmpLayerPopup = function (map, layer, feature) {
    layer.bindPopup(
      (layer) => renderProjectBmpPopup(feature.properties),
      MapFunctions.getPopupOptions(),
    );
  };

  var getProjectBmpPopupHtml = function (bmp, year = Tree.get("waterYear")) {
    var bmpMarkerData = LegacyBmpFcs.prepareBmpMarkerData(bmp, "bmp", year);
    var popupProps = LegacyBmpFcs.getPopupRenderingProps(bmpMarkerData, "bmp", year);
    var props = $.extend({}, bmp, bmpMarkerData, popupProps);
    props.permissions = UserPermissions.getAllPermissions();
    props.popupColor = BmpFcsIcon.getIconColor(props.lidScore, props.phase);
    props.showEditInventory =
      Tree.get("dataView") === "lid-project" && props.permissions.can_edit_bmps;
    return renderProjectBmpPopup(props);
  };

  var renderProjectBmpPopup = function (props) {
    return nunjucks.render("popups/projectBmpPopup.njk", props);
  };

  var loadPopupListeners = function (layer) {
    layer.on("popupopen", function (e) {
      if (e.layer && e.layer.feature) {
        const properties = e.layer.feature.properties;

        loadPopupData(properties.id);
      }
    });
  };

  var loadPopupData = async function (idbmp) {
    const data = await ApiCalls.getLidBmpPopup(idbmp);
    const html = getProjectBmpPopupHtml(data);
    $(".leaflet-popup-content").html(html);
    PopupContent.setPopupConditionBar($(".tr-lid-bmp-score"), data.history, (datum) => datum.score);

    data.history = MuniBmpPopup.setLegacyAssessmentHistory(data.history);
  };

  var createIcon = function (properties, zoom) {
    return BmpFcsIcon.getIconMarker(
      properties.lidScore,
      BmpFcsFunctions.isCentralizedBMPType(properties.bmp_kind),
      properties.bmp_type_abbr,
      properties.fcs,
      properties.bmpAssessmentDue,
      properties.phase,
      null,
      Tree.get("activeTab") === "todo",
      zoom,
    );
  };

  return {
    setProjectBmpLayerPopup,
    loadPopupListeners,
    renderProjectBmpPopup,
    loadPopupData,
    createIcon,
  };
};

module.exports = ProjectBmpPopup();

const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const ApiCalls = require("../apiCalls");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
const LegacyBmpFcs = require("../bmpfcs/legacyBmpFcs");
const UserPermissions = require("../login/userPermissions");
const MuniBmpPopup = require("../muni/bmp/muniBmpPopup");
const PopupContent = require("../mapFunctions/popupContent");
