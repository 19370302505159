"use strict";

var PageGroupFunctions = function () {
  var goToPageGroup = function (pageName) {
    const groupName = _getPageGroupConfigName(pageName);

    if (Tree.get("activePageGroup") === groupName) {
      return;
    }

    Tree.set(["activePageGroup"], groupName);

    const groupConfig = PageGroupConfig[groupName];
    if (!groupConfig.breadCrumbsPageLink) {
      Tabs.renderTabs([]);
    } else {
      Tabs.renderTabs(groupConfig.pages);
    }
    DataViewDropdown.toggleDropdown(groupConfig.dataViewDropdown);
    BreadCrumbsDropdown.setDropdown(groupConfig.breadCrumbsPageLink);
  };

  var _getPageGroupConfigName = function (pageName) {
    for (const groupName in PageGroupConfig) {
      const groupConfig = PageGroupConfig[groupName];

      if (groupConfig.pages.includes(pageName)) {
        return groupName;
      }
    }

    throw new Error(`${pageName} does not belong to any page group.`);
  };

  var getCurrentPageGroupProperty = function (property) {
    const groupName = Tree.get("activePageGroup");
    const groupConfig = PageGroupConfig[groupName];

    if (!groupConfig) {
      return null;
    }

    return groupConfig[property];
  };

  return { goToPageGroup, getCurrentPageGroupProperty };
};

module.exports = PageGroupFunctions();

const PageGroupConfig = require("./config/pageGroupConfig");
const Tree = require("../tree");
const Tabs = require("./tabs");
const DataViewDropdown = require("./mapFunctions/dataViewDropdown");
const BreadCrumbsDropdown = require("./breadCrumbsDropdown");
