"use strict";

const Tree = require("../../tree");

var PopupPhotos = function () {
  var surveyCursor = Tree.select("survey123_photos");
  var collectorCursor = Tree.select("collector_photos");
  var outfallCursor = Tree.select("outfall_photos");
  var bmpCursor = Tree.select("bmp_photos");
  var currentId = null;
  var layer = null;

  var loadListeners = function () {
    $(".map-content").on(
      "click",
      ".popup-general .obsPhoto, .popup-general .js-btnMorePhotos",
      showPopupPhotoModal,
    );
    $(".map-content").on("click", ".popup-general .js-browseLink", browseLinkClick);

    $(".delete-photo").on("click", deletePhoto);
    $("#bmpPhotoCarousel").on("click", ".js-captionContent", captionClick);
    $(".rotate-photo").on("click", rotatePhotoClick);
  };

  // @TODO: these listeners should be inside of a function that is explicitly called
  // so that they are not fired inappropriately in Ram just because this module is loaded
  // indirectly via main.js
  if (!window.twoNformReport) return;

  //Tree listeners
  bmpCursor.on("update", function () {
    var photos = bmpCursor.get(currentId);
    if (photos.isUploading) {
      renderThumbs(photos.items, true, photos.progress);
    } else {
      renderThumbs(photos.items, null, null);
    }
  });

  surveyCursor.on("update", function () {
    var photos = surveyCursor.get(currentId);
    if (photos.isUploading) {
      renderThumbs(photos.items, true, photos.progress);
    } else {
      renderThumbs(photos.items);
    }
  });

  collectorCursor.on("update", function () {
    var photos = collectorCursor.get(currentId);
    if (photos.isUploading) {
      renderThumbs(photos.items, true, photos.progress);
    } else {
      renderThumbs(photos.items);
    }
  });

  outfallCursor.on("update", function () {
    var photos = outfallCursor.get(currentId);
    if (photos.isUploading) {
      renderThumbs(photos.items, true, photos.progress);
    } else {
      renderThumbs(photos.items, null, null);
    }
  });

  var browseLinkClick = function () {
    $(this).next().trigger("click");
  };

  var captionClick = function () {
    var captionContent = $(this);
    var activeSlide = $("#bmpPhotoCarousel .carousel-inner .item.active");
    var photo = activeSlide.data("photo");
    var input = $('<input type="text" class="form-control" placeholder="add/edit caption here" />');
    var inputValue;
    var oldValue = captionContent.attr("title");
    captionContent.after(input);
    captionContent.hide();
    input.one("blur", function () {
      inputValue = input.val().trim();
      if (inputValue !== "" && inputValue !== oldValue) {
        captionContent.attr("title", inputValue);
        captionContent.text(inputValue);
        Actions.updatePhotoCaption(currentId, photo, inputValue, "bmp");
      }
      input.remove();
      captionContent.show();
    });
    input.on("keyup", function (e) {
      if (e.keyCode == 13) {
        input.blur();
      }
    });
    input.val(oldValue).focus();
  };

  var deletePhoto = function () {
    var carousel = $("#bmpPhotoCarousel");
    var activeSlide = $("#bmpPhotoCarousel .carousel-inner .item.active");
    var activeIndicator = $("#bmpPhotoCarousel .carousel-indicators .active");
    var photo = activeSlide.data("photo");
    if (confirm("Delete this image?")) {
      // jump to next slide, first slide or close the preview box
      var next = activeSlide.next();
      var first = activeSlide.siblings().first();
      if (next.length) {
        next.addClass("active");
        activeIndicator.next().addClass("active");
      } else if (first.length) {
        first.addClass("active");
        activeIndicator.siblings().first().addClass("active");
      } else {
        $("#photosModal").modal("hide");
      }

      // remove slide
      activeIndicator.remove();
      activeSlide.remove();

      // reset carousel
      carousel.carousel();

      Actions.deletePhoto(currentId, photo, layer);
    }
  };

  var showPopupPhotoModal = function () {
    var modal = $("#photosModal");
    var photos = Tree.get(layer + "_photos", currentId);
    if (layer == "outfall" || layer == "bmp") {
      $("#btnDeletePhoto").show();
    } else {
      $("#btnDeletePhoto").hide();
    }
    var indicators = nunjucks.render("report/popups/photos/carouselIndicators.html", {
      photos: photos.items,
      base: Config.apiUrl,
      active: $(this).data("index"),
    });
    var slides = nunjucks.render("report/popups/photos/carouselSlides.html", {
      photos: photos.items,
      base: Config.apiUrl,
      active: $(this).data("index"),
    });
    modal.find(".carousel-indicators").empty().html(indicators);
    modal.find(".carousel-inner").empty().html(slides);
    modal.modal("show");
  };

  var renderThumbs = function (items, isUploading, progress) {
    const photos = Tree.get(layer + "_photos", currentId);
    if (photos["items"]) {
      const cssList = Actions.initiatePhotoCss(photos["items"]);
      photos.cssList = cssList; //Clear the rotation records when modal opens
    }
    const thumbsHtml = nunjucks.render("report/popups/photos/popupThumbs.html", {
      base: Config.apiUrl,
      photos: items,
      progress: progress,
      isUploading: isUploading,
    });
    $(".popup-general .photo-list").empty().html(thumbsHtml);
  };

  var load = function (assessment) {
    layer = assessment.source.toLowerCase().replace(/\s/g, "");
    currentId = assessment.id;
    Tree.set("photo_layer", layer);

    var photos = Tree.get(layer + "_photos", currentId);
    if (photos === undefined) {
      Actions.loadPhotoList(currentId, layer);
    } else {
      renderThumbs(photos.items);
    }
  };

  var rotatePhotoClick = function () {
    const direction = $(this).data("rotate");
    const layer = Tree.get("photo_layer");
    const [degrees, index] = getDegrees(direction, layer);
    const activeImage = $(".item.active");
    var items = Tree.get(layer + "_photos", currentId, "items");
    const photoId = items[index].filename;

    activeImage.find("img").css({ transform: "rotate(" + degrees + "deg)" });
    $(".photo-list")
      .find("[data-index='" + index + "']")
      .css({ transform: "rotate(" + degrees + "deg)" });
    ReportApiCalls.rotateImage(layer, currentId, photoId, direction, function (newPhotoId) {
      items[index].filename = newPhotoId;
      const fullName = items[index].full;
      const fullPath = fullName.slice(0, fullName.lastIndexOf("/") + 1);
      items[index].full = fullPath + newPhotoId;
    });
  };

  var getDegrees = function (direction, layer) {
    const cssList = Tree.get(layer + "_photos", currentId, "cssList");
    const innerCarousel = $("#bmpPhotoCarousel .carousel-inner");
    const index = innerCarousel.find("div.item.active").index();
    if (direction == "right") {
      cssList[index] = cssList[index] + 90;
    } else {
      cssList[index] = cssList[index] - 90;
    }
    return [cssList[index], index];
  };

  return {
    load,
    loadListeners,
  };
};

module.exports = PopupPhotos();

const Config = require("../config");
const Actions = require("../actions");
const ReportApiCalls = require("../reportApiCalls");
