"use strict";

const AssetsManholeGeoServerDynamicMapLayer = function () {
  const layerName = "assetsManholeEsriDynamicMap";
  const layerKey = "assetsManholeEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsManholeEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsManholeEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsManholeEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = AssetsManholeGeoServerDynamicMapLayer();

// const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
