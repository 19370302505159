"use strict";

class UploadLimitError extends Error {
  constructor(message, uploadLimit, uploading) {
    super(message);
    this.name = "UploadLimitError";
    this.uploadLimit = uploadLimit;
    this.uploading = uploading;
  }
}

module.exports = UploadLimitError;
