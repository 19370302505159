"use strict";

const BmpFcsInventoryAdditionalCatchBasinInformation = function () {
  const stringKey = "additional-catch-basin-information";
  const headerInformation = "Catch Basin Info";
  let $page;

  var loadAdditionalCatchBasinInformationListeners = function () {
    Form.initializeAndLoadListeners($page, "bmp-fcs-inventory", { isMultiPart: true });
    $page.on("change", `[name="design-depth-unit"]`, setSumpMinMax);
    $page.on("change", `[name="shape"]`, toggleDiameterField);
    $page.on("change", `[name="sump"]`, toggleSumpDepthField);
  };

  var unloadAdditionalCatchBasinInformationListeners = function () {
    $page.off("change", `[name="design-depth-unit"]`, setSumpMinMax);
    $page.off("change", `[name="shape"]`, toggleDiameterField);
    $page.off("change", `[name="sump"]`, toggleSumpDepthField);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    const options = BmpFcsInventoryModalController.getOptions();
    await renderPageHtml(options);
    loadAdditionalCatchBasinInformationListeners();
    disableSumpIfSedimentTrap();
    setSumpMinMax();
    BmpFcsInventoryModalController.addHighlightsToFields($page);
  };

  var renderPageHtml = async function () {
    const renderData = getAdditionalCatchBasinProps();

    const html = nunjucks.render(
      "modals/bmpFcsInventory/bmpFcsInventoryAdditionalCatchBasinInformation.njk",
      renderData,
    );

    BmpFcsInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
  };

  var getAdditionalCatchBasinProps = function () {
    return {
      ...InventoryModal.getAllData("bmp-fcs-inventory"),
      ...getCatchBasinOptionProps(),
    };
  };

  var getCatchBasinOptionProps = function () {
    return {
      inletTypeOptions: BmpFcsInventoryConstants.inletTypeOptions,
      depthUnitOptions: BmpFcsInventoryConstants.depthUnitOptions,
      shapeOptions: BmpFcsInventoryConstants.shapeOptions,
      materialOptions: BmpFcsInventoryConstants.materialOptions,
    };
  };

  var disableSumpIfSedimentTrap = function () {
    const bmpType = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");
    if (bmpType == 11) {
      $page.find("input[name=sump]").prop("disabled", true);
    }
  };

  var cleanUp = function () {
    unloadAdditionalCatchBasinInformationListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var toggleDiameterField = function () {
    const toggle = BmpFcsInventoryModalController.getLatestBmpInventoryData("shape") === "circular";
    $page.find(".length, .width").toggleClass("hidden", toggle);
    $page.find(".diameter").toggleClass("hidden", !toggle);
  };

  var toggleSumpDepthField = function () {
    const toggle = BmpFcsInventoryModalController.getLatestBmpInventoryData("sump");
    $page.find(".sump-depth").toggleClass("hidden", !toggle);
  };

  var setSumpMinMax = function () {
    const minMax = BmpFcsInventoryBmpFcsInformation.getSumpMinMax();
    const $sumpDepth = $page.find(".sump-depth input[name=design-depth]");

    const currentValue =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("designDepth") ??
      minMax.minimumValue;
    const clampedValue = Misc.clamp(minMax.minimumValue, currentValue, minMax.maximumValue);
    NumberInput.updateInputOptions($sumpDepth[0], {
      overrideMinMaxLimits: "ignore",
    });
    NumberInput.manuallySetInput($sumpDepth[0], clampedValue);
    NumberInput.updateInputOptions($sumpDepth[0], {
      overrideMinMaxLimits: null,
      ...minMax,
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    getCatchBasinOptionProps,
  };
};

module.exports = BmpFcsInventoryAdditionalCatchBasinInformation();

const BmpFcsInventoryBmpFcsInformation = require("./bmpFcsInventoryBmpFcsInformation");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const InventoryModal = require("../general/inventoryModal");
const Form = require("../general/form");
const Misc = require("../misc");
const NumberInput = require("../general/numberInput");
