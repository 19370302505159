"use strict";

const RegionAreaLayer = function () {
  var loadListeners = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(mapId, "regional", function (regionalIsEnabled) {
      const currentMap = TreeUpdates.getCurrentMap();
      if (regionalIsEnabled) {
        if (mapLayers.regionalLayer) {
          mapLayers.regionalLayer.addTo(currentMap).bringToBack();
          currentMap.fitBounds(mapLayers.regionalLayer.getBounds());
        } else {
          loadLayer();
        }
      } else {
        if (MapFunctions.mapHasLayer(currentMap, mapLayers.regionalLayer)) {
          currentMap.removeLayer(mapLayers.regionalLayer);
        }
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "regional", function (data) {
      const currentMap = TreeUpdates.getCurrentMap();
      const currentMapLayers = TreeUpdates.getCurrentMapLayers();
      currentMapLayers.regionalLayer = addRegionalLayer(
        currentMap,
        currentMapLayers.regionalLayer,
        data,
      );
      map.fitBounds(currentMapLayers.regionalLayer.getBounds());
    });
  };

  var addRegionalLayer = function (map, regionalLayer, data) {
    if (regionalLayer) {
      map.removeLayer(regionalLayer);
    }

    regionalLayer = L.geoJson(data, {
      style: Progeny.activeGroupIsRegulator()
        ? MapStyles.styleRegionalRegulator
        : MapStyles.styleRegional,
      onEachFeature: function (feature, layer) {
        layer
          .on({
            mouseover: Ms4Table.highlightMs4Feature,
            mouseout: Ms4Table.resetHighlightMs4,
            click: Ms4Table.zoomToMs4,
          })
          .bindPopup((layer) => PopupContent.getRegionAreaPopup(feature));
      },
    })
      .on("popupclose", function (e) {
        $(".ms4-table .active").removeClass("active");
      })
      .addTo(map)
      .bringToBack();

    return regionalLayer;
  };

  var loadLayer = function () {
    const mapId = Tree.get("mapId");
    Tree.set(["layers", mapId, "regional", "isFetching"], true);
    return ReportApiCalls.getAllMS4s(function (data) {
      Tree.set("submittedMS4sLoaded", false);

      var geoJson = CleanData.cleanGeoJSON(data);
      Tree.set(["layers", mapId, "regional", "data"], geoJson);
      Tree.set(["layers", mapId, "regional", "isFetching"], false);
    });
  };

  return {
    loadListeners,
    loadLayer,
  };
};

module.exports = RegionAreaLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const CleanData = require("../mapFunctions/cleanData");
const ReportApiCalls = require("../reportApiCalls");
const TreeUpdates = require("../mapFunctions/treeUpdates");
const MapStyles = require("../mapFunctions/mapStyles");
const Ms4Table = require("../ms4Table");
const PopupContent = require("../mapFunctions/popupContent");
const Progeny = require("../../login/progeny");
