"use strict";

var FacilityTypeFilter = function () {
  var facilityTypes;

  var loadListeners = function () {
    Tree.select("layers", "indcomFacilities", "data").off("update", onDataUpdate);
    Tree.select("layers", "indcomFacilities", "data").on("update", onDataUpdate);
  };

  var onDataUpdate = function (e) {
    if (facilityTypes === undefined) {
      populateFacilityTypeOptions(e.data.currentData);
      render();
    }
  };

  var populateFacilityTypeOptions = function (facilities) {
    facilityTypes = getFilterFacilityTypeOptions(facilities);
  };

  var getFilterFacilityTypeOptions = function (
    facilities = Tree.get("layers", "indcomFacilities", "data"),
  ) {
    const groupFacilityTypes = IndcomInventoryConstants.getFacilityTypeOptions();
    const includedFacilityTypes = [...new Set(facilities.map((facility) => facility.facilityType))];

    return Object.values(groupFacilityTypes)
      .filter((facilityType) => includedFacilityTypes.includes(facilityType.value))
      .map((facility) => {
        return {
          ...facility,
          facilityType: facility.value,
          displayFacilityType: facility.name,
        };
      })
      .sort(function (a, b) {
        const textA = a.displayFacilityType.toUpperCase();
        const textB = b.displayFacilityType.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
  };

  var render = function () {
    const html = nunjucks.render("table/facilityTypeFilter.njk", getProps());
    $("#facility-type-filter").html(html);
  };

  function getProps() {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions } =
      Filters.getSpatialArrays();
    const checkedValues = Tree.get("filters", "facilityType") ?? [];

    const phaseOptions = [
      { name: "Open", value: "open" },
      { name: "Closed", value: "closed" },
    ];

    const yesNoOptions = [
      { name: "Yes", value: "yes" },
      { name: "No", value: "no" },
    ];

    const facilityTypeOptions = IndcomInventoryConstants.getFacilityTypeOptions().slice();
    const noDataOption = facilityTypeOptions.find((option) => option.name === "No Data");
    if (!noDataOption) {
      var newObj = {
        name: "No Data",
        value: "null",
      };
      facilityTypeOptions.push(newObj);
    }

    const responsiblePartyManagementOptions =
      IndcomInventoryConstants.responsiblePartyOptions.slice();
    const responsiblePartyNoDataOption = responsiblePartyManagementOptions.find(
      (option) => option.name === "No Data",
    );
    if (!responsiblePartyNoDataOption) {
      responsiblePartyManagementOptions.push({
        name: "No Data",
        value: "null",
      });
    }

    const riskProfileOptions = IndcomInventoryConstants.riskFactorOptions.slice();
    const riskProfileNoDataOption = riskProfileOptions.find((option) => option.name === "No Data");
    if (!riskProfileNoDataOption) {
      riskProfileOptions.push({
        name: "No Data",
        value: "null",
      });
    }

    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      responsiblePartyManagementOptions,
      riskProfileOptions,
      data: facilityTypes,
      checkedValues: checkedValues,
      facilityTypeOptions: facilityTypeOptions,
      phaseOptions: phaseOptions,
      yesNoOptions: yesNoOptions,
      fogParticipantOptions: FilterConstants.yesNoOptions,
      isIndustrialGeneralPermitOptions: FilterConstants.yesNoOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
    };
  }

  return {
    render,
    loadListeners,
    getFilterFacilityTypeOptions,
    getProps,
  };
};

module.exports = FacilityTypeFilter();

const FilterConstants = require("../filterConstants");
const Filters = require("../mapFunctions/filters");
const IndcomInventoryConstants = require("../indcom/indcomInventoryConstants");
const Tree = require("../tree");
const Progeny = require("../login/progeny");
