"use strict";

const NunjucksExtensions = function () {
  var initializeNunjucks = function (env = nunjucks.configure()) {
    setupExtensions();
    NunjucksFunctions.setupFunctions(env);
    NunjucksFilters.setupFilters(env);

    return env;
  };

  var setupExtensions = function () {
    nunjucks.asyncRender = async function (templatePath, props) {
      return new Promise((resolve, reject) => {
        nunjucks.render(templatePath, props, (errorMsg, html) => {
          if (errorMsg) reject(new Error(errorMsg));
          resolve(html);
        });
      });
    };

    nunjucks.asyncRenderString = async function (templateString, props) {
      return new Promise((resolve, reject) => {
        nunjucks.renderString(templateString, props, (errorMsg, html) => {
          if (errorMsg) reject(new Error(errorMsg));
          resolve(html);
        });
      });
    };
  };

  return {
    initializeNunjucks,
  };
};

module.exports = NunjucksExtensions();

const NunjucksFilters = require("./nunjucksFilters");
const NunjucksFunctions = require("./nunjucksFunctions");
