"use strict";

const IndcomInventorySetLocation = function () {
  const stringKey = "indcom-set-location";
  const headerInformation = "Location";
  let $page;

  var loadSelectLocationListeners = function () {
    $page.on("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.on("input", ":input.form-control", changeSetLocationInput);
    $page.on("change", `[name="use-manual-apn"]`, onUseManualApnChange);
    Form.initializeAndLoadListeners($page, "indcom-inventory", { isMultiPart: true });
  };

  var removeListeners = function () {
    $page.off("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.off("input", ":input.form-control", changeSetLocationInput);
    $page.off("change", `[name="use-manual-apn"]`, onUseManualApnChange);
  };

  var changeSetLocationInput = function () {
    const changedField = $(this).attr("name");
    if (changedField === "facility-identifier") {
      setIndcomId($(this).val());
    }
  };

  var setIndcomId = function (newName) {
    if (newName === IndcomInventoryModalController.getExistingData("facilityIdentifier")) {
      restoreButtonsIfHasAllRequiredInputs();
      IndcomInventoryModalController.unsetFormDataField("facilityIdentifier");
    } else if (newName === "") {
      InventoryModal.allowedSaveState(false);
      IndcomInventoryModalController.unsetFormDataField("facilityIdentifier");
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
    } else {
      IndcomInventoryModalController.setFormDataField("facilityIdentifier", newName);
      restoreButtonsIfHasAllRequiredInputs();
    }
  };

  var onUseManualApnChange = function () {
    const showManualApnInput =
      IndcomInventoryModalController.getLatestIndcomInventoryData("useManualApn") === true;
    $page.find(`input[name="parcel-apn"]`).toggleClass("hidden", showManualApnInput);
    $page.find(`input[name="manual-apn"]`).toggleClass("hidden", !showManualApnInput);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    await renderPageHtml();
    setValidLatLongInTree();
    ModalMap.removeMarkerLayer();
    showLocationSelectionMap(options);
    loadSelectLocationListeners();
    disableUnavailableElements();
  };

  var disableUnavailableElements = function () {
    const facilityIdentifier =
      IndcomInventoryModalController.getLatestIndcomInventoryData("facilityIdentifier");
    Misc.toggleDisabled(
      $("#inventory-modal input[name='facility-identifier']"),
      !facilityIdentifier,
    );

    if (facilityIdentifier) {
      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var checkCreateNewIndcomMarker = function () {
    var id = false;
    var isNewMarker = !id;
    return isNewMarker;
  };

  var showLocationSelectionMap = function (options) {
    const configuration = {
      checkNewMarkerHandler: checkCreateNewIndcomMarker,
      newExistingMarkerHandler: IndcomInventoryModalController.loadExistingIndcomlMarker,
      locationDataUpdateHandler: updateSetLocationFooter,
      badLocationUpdateHandler: resetLatLngToLastValidValue,
      createMarkerHandler: createIndcomMarker,
    };

    LocationSelectionMap.configureLocationModalMapHandlers(configuration);
    LocationSelectionMap.showLocationSelectionMap(options?.existingLocation);

    setLocationMapText();
  };

  var createIndcomMarker = function (latlng) {
    return ModalMap.getMarker(latlng);
  };

  var updateSetLocationFooter = async function (locationInfo, latlng) {
    const promise = updateLocationInfo(locationInfo);
    updateLatLngFields(latlng);

    const $facilityIdentifier = $page.find("input[name='facility-identifier']");
    if ($facilityIdentifier.attr("disabled")) {
      Misc.toggleDisabled($facilityIdentifier, false);
      $facilityIdentifier.focus();
    }

    return promise;
  };

  var updateLocationInfo = function (locationInfo) {
    locationInfo = parseLocationInfo(locationInfo);

    IndcomInventoryModalController.setFormDataField("locationInfo", locationInfo);
    setInputField("maintenance-zone", locationInfo.maintenanceZone, true);
    setInputField("maintenanceSection", locationInfo.maintenanceSection, true);
    setInputField("parcel-apn", locationInfo.parcelApn, true);
    setInputField("catchment-id", locationInfo.catchmentName, true);
    setInputField("address", locationInfo.address, true);
    setInputField("city", locationInfo.groupCityName, true);
    setInputField("zip", locationInfo.zip, true);
    setInputField("state", locationInfo.state, true);
    setInputField("highwayAssignment", locationInfo.highways, true);
    setInputField("region", locationInfo.regionName, true);
    setInputField("patrol", locationInfo.patrol, true);
    setInputField("state", locationInfo.state, true);
  };

  var updateLatLngFields = function (latlng) {
    const latInput = $page.find(`input[name="latitude"]`)[0];
    const longInput = $page.find(`input[name="longitude"]`)[0];

    if (latInput && longInput) {
      NumberInput.manuallySetInput(latInput, latlng.lat);
      NumberInput.manuallySetInput(longInput, latlng.lng);
    }

    IndcomInventoryModalController.setFormDataField("latitude", latlng.lat);
    IndcomInventoryModalController.setFormDataField("longitude", latlng.lng);
    restoreButtonsIfHasAllRequiredInputs();
  };

  var setInputField = function (field, value, updateForm) {
    if (field === "state") {
      value = Misc.convertStateForAutoPopulate(value);
    }
    if (field === "parcel-apn") {
      $(".indcom-parcel-apn").text(value);
    }
    if (field === "region") {
      $(".indcom-region").text(value);
    }
    if (field === "catchment-id") {
      $(".indcom-catch-id").text(value);
    }
    $page.find(`[name=${field}]`).val(value);
    if (updateForm && value !== undefined) {
      IndcomInventoryModalController.setFormDataField(field, value);
    }
  };

  var setLocationMapText = function () {
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      ModalMap.showLocationMessage("Click map or drag marker to change location");
    } else {
      ModalMap.showLocationMessage("Click map to set location");
    }
  };

  var parseLocationInfo = function (locationInfo) {
    return {
      regionName: locationInfo?.gname,
      highways: locationInfo.highway_name,
      catchmentGid: locationInfo.gid,
      catchmentName: locationInfo.catchid,
      receivingWaterName: locationInfo.drains_to_rw,
      landuseType: locationInfo.combo,
      groupName: locationInfo.gname,
      groupId: locationInfo.group_id,
      groupCityName: locationInfo.city,
      address: locationInfo.address,
      zip: locationInfo.zip,
      state: locationInfo.state,
      parcelApn: locationInfo.parcelid,
      maintenanceZone: locationInfo.maintenanceZone,
      maintenanceSection: locationInfo.maintenance_section,
      patrol: locationInfo.patrol,
    };
  };

  var getProps = function () {
    const renderProps = IndcomInventoryModalController.getInventoryForRender();
    const stateOptions = IndcomInventoryConstants.getStatesOptions();

    return {
      ...renderProps,
      locationInfo: parseLocationInfo(renderProps.locationInfo ?? {}),
      stateOptions,
    };
  };

  var setValidLatLongInTree = function () {
    const lat = IndcomInventoryModalController.getLatestIndcomInventoryData("latitude");
    const lng = IndcomInventoryModalController.getLatestIndcomInventoryData("longitude");
    if (lat && lng) {
      Tree.set("modalMapSingleMarkerLastValidLatLng", { lat, lng });
    }
  };

  var renderPageHtml = async function () {
    const props = getProps();
    const html = nunjucks.render("modals/indcomInventory/indcomInventorySetLocation.njk", props);
    IndcomInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
  };

  var updateLatLngFromInput = function () {
    if ($page.find(`input[name="latitude"]`).val() && $page.find(`input[name="longitude"]`).val()) {
      const e = {
        latlng: {
          lat: $page.find(`input[name="latitude"]`).val(),
          lng: $page.find(`input[name="longitude"]`).val(),
        },
      };
      LocationSelectionMap.locationSelectionMapClick(e);

      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var hasAllRequiredInputs = function () {
    const latitude = $page.find(`input[name="latitude"]`).val();
    const longitude = $page.find(`input[name="longitude"]`).val();
    const facilityIdentifier = $page.find(`input[name="facility-identifier"]`).val();

    return latitude && longitude && facilityIdentifier;
  };

  var restoreButtonsIfHasAllRequiredInputs = function () {
    if (hasAllRequiredInputs()) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }
  };

  var resetLatLngToLastValidValue = function (latlng) {
    NumberInput.manuallySetInput($page.find(`input[name="latitude"]`)[0], latlng.lat);
    NumberInput.manuallySetInput($page.find(`input[name="longitude"]`)[0], latlng.lng);
  };

  var cleanUp = function () {
    removeListeners();
    LocationSelectionMap.hideLocationSelectionMap();
    $page.empty();
    return true;
  };

  var validateFacilityId = async function () {
    const alreadyUsedId = Tree.get([
      "facilityInventoryState",
      "currentFacilityData",
      "facilityIdentifier",
    ]);
    const currentIdState = Tree.get("facilityInventoryState", "facilityIdentifier");

    if (alreadyUsedId === currentIdState) {
      return true;
    } else {
      const groupId = Tree.get("activeGroup", "groupId");
      const isUnique = await ApiCalls.checkUniqueFacilityIdentifier(groupId, currentIdState);
      if (!isUnique[0].unique) {
        MessageModal.showSimpleWarningModal(
          "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
      return isUnique[0].unique;
    }
  };

  var validate = async function () {
    return validateFacilityId().catch((err) => {
      throw err;
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    setInputField,
    cleanUp,
    validate,
    validateFacilityId,
    updateSetLocationFooter,
    loadSelectLocationListeners,
  };
};

module.exports = IndcomInventorySetLocation();

const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const LocationSelectionMap = require("../mapFunctions/locationSelectionMap");
const Misc = require("../misc");
const ModalMap = require("../mapFunctions/modalMap");
const NumberInput = require("../general/numberInput");
const Tree = require("../tree");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
const IndcomInventoryConstants = require("./indcomInventoryConstants");
const IndcomInventoryModalController = require("./indcomInventoryModalController");
