"use strict";

const UserRoles = function () {
  /**
   * This should contain only configurations for presentation that couldn't
   * easily go in the backend.
   */
  const userConfig = {
    // AKA Super Admin, Module Admin
    admin: {
      individualEnforcementsTodoTitle: "Open Individual Findings",
      showIndividualEnforcementsSection: true,
      showFormalCommunications: true,
      showResponseNeeded: true,
      showMissingInformation: true,
      managers: true,
      inventory: true,
    },
    // AKA Program Manager, Module Data Manager
    "data-manager": {
      individualEnforcementsTodoTitle: "Open Individual Findings",
      showIndividualEnforcementsSection: true,
      showFormalCommunications: true,
      showResponseNeeded: true,
      showMissingInformation: true,
      managers: true,
      inventory: true,
    },
    // AKA Module Reviewer (PE)
    reviewer: {
      showProjectEnforcementActionsTodos: true,
      showProjectInspectionTodos: false,
      showCompleteProjectTodos: false,
      showBmpInstallationTodos: false,
      showDeferralReviewButtons: true,
      showIndividualEnforcementsSection: true,
      showFormalCommunicationTodos: true,
    },
    viewer: {
      showDeferEnforcementButton: false,
      showProjectEnforcementActionsTodos: false,
      showProjectInspectionTodos: false,
      showCompleteProjectTodos: false,
      showBmpInstallationTodos: false,
      showIndividualEnforcementsSection: false,
    },
    "field-personnel": {
      showProjectInspectionTodos: ["can_inspect_projects"],
      showCompleteProjectTodos: ["can_certify_projects", "can_inspect_projects"],
      showBmpInstallationTodos: ["can_certify_projects"],
      showIndividualEnforcementsSection: true,
      showMissingInformation: true,
      inventory: true,
    },
    "editing-project-contact": {},
    responder: {
      individualEnforcementsTodoTitle: "Respond to Findings",
      showDeferEnforcementButton: true,
      showProjectEnforcementActionsTodos: false,
      showProjectInspectionTodos: false,
      showCompleteProjectTodos: false,
      showBmpInstallationTodos: false,
      showIndividualEnforcementsSection: true,
    },
    planner: {
      showProjectEnforcementActionsTodos: false,
      showIndividualEnforcementsSection: false,
    },
  };

  /*
   * You shouldn't necessarily always set a default for a config. Leave the
   * default out to have developer to decide on the config manually.
   */
  const configDefaults = {
    individualEnforcementsTodoTitle: "Open Findings",
    showDeferEnforcementButton: false,
    showDeferralReviewButtons: false,
    showProjectEnforcementActionsTodos: true,
    showProjectInspectionTodos: true,
    showCompleteProjectTodos: true,
    showBmpInstallationTodos: true,
    showIndividualEnforcementsSection: false,
    showFormalCommunications: false,
    showResponseNeeded: false,
    showMissingInformation: false,
    showFormalCommunicationTodos: false,
    managers: false,
    inventory: false,
  };

  var getConfig = function (key) {
    const role = getRole();
    if (userConfig[role] !== undefined && Array.isArray(userConfig[role][key])) {
      return userConfig[role][key].some((permission) => UserPermissions.getPermission(permission));
    } else if (userConfig[role] !== undefined && userConfig[role][key] !== undefined) {
      return userConfig[role][key];
    } else if (configDefaults[key] !== undefined) {
      return configDefaults[key];
    } else {
      throw new Error(
        `The config ${key} is not set for the ${role} role and it doesn't have a default.`,
      );
    }
  };

  var getRole = function () {
    return Tree.get("user", "user_role");
  };

  return {
    getRole,
    getConfig,
  };
};

module.exports = UserRoles();

const Tree = require("../tree");
const UserPermissions = require("./userPermissions");
