"use strict";

function isProductionDomain() {
  return window.location.href.indexOf("2nform.com/report/") > -1;
}

function isDevDomain() {
  return window.location.href.indexOf("dev.2nformdev.com/report/") > -1;
}

const currentTools = [
  {
    name: "report",
    title: "Annual Reporting",
    group: 1,
  },
  {
    title: "Strategic Planning",
    group: 1,
    disabled: true,
  },
  {
    name: "lid",
    title: "Post Construction Requirements",
    group: 2,
    isRam: true,
  },
  {
    name: "construction",
    title: "Construction",
    group: 2,
    isRam: true,
  },
  {
    name: "indcom",
    title: "Industrial / Commercial",
    group: 2,
    isRam: true,
  },
  {
    name: "idde",
    title: "Illicit Discharge",
    group: 2,
    isRam: true,
  },
  {
    name: "muni",
    title: "Municipal Maintenance",
    group: 2,
    isRam: true,
  },
  {
    name: "peo",
    title: "Public Ed & Outreach",
    group: 2,
    isRam: true,
  },
  {
    name: "bmpram",
    title: "Structural BMP Diagnostics",
    group: 2,
    isRam: true,
  },
  {
    name: "trashram",
    title: "Trash Compliance",
    group: 2,
    isRam: true,
  },
  {
    name: "outmon",
    title: "Water Quality Monitoring",
    group: 2,
    isRam: true,
  },
  {
    title: "Asset Lifecycle Optimization",
    group: 2,
    disabled: true,
  },
];

var getCurrentToolLinks = function (server) {
  var currentToolLinks = {};

  switch (server) {
    case "LOCALHOST":
      currentTools.forEach(function (tool) {
        if (tool.isRam) {
          currentToolLinks[tool.name] = "/ram/" + tool.name;
        } else {
          currentToolLinks[tool.name] = "/" + tool.name;
        }
      });
      break;

    case "DEV":
      currentTools.forEach(function (tool) {
        if (tool.isRam) {
          currentToolLinks[tool.name] = "//dev.2nformdev.com/ram/" + tool.name;
        } else {
          currentToolLinks[tool.name] = `//dev.2nformdev.com/${tool.name}/`;
        }
      });
      break;

    default:
      currentTools.forEach(function (tool) {
        if (tool.isRam) {
          currentToolLinks[tool.name] = "//2nform.com/ram/" + tool.name;
        } else {
          currentToolLinks[tool.name] = `//2nform.com/${tool.name}/`;
        }
      });
  }

  return currentToolLinks;
};

const common = {
  toolName: "report",
  site: "2nform",
  origin: window.origin,
  currentTools,
};

let config;

if (isDevDomain()) {
  config = {
    apiUrl: "https://apidev.2nformdev.com/public",
    currentToolLinks: getCurrentToolLinks("DEV"),
    gisServer: "Dev Dev",
  };
} else if (isProductionDomain()) {
  config = {
    apiUrl: "https://api.2nform.com/public",
    currentToolLinks: getCurrentToolLinks(""),
    gisServer: "Production",
    isProductionDomain: true,
  };
} else {
  config = {
    apiUrl: "http://api.localhost/public",
    currentToolLinks: getCurrentToolLinks("LOCALHOST"),
    gisServer: "Local",
  };
}

module.exports = { ...common, ...config };
