"use strict";

var LoadingScreen = function () {
  const waitingForResultClass = "waiting-for-result";
  const $container = $("#loading-screen");
  const animation = 200;
  var timeout = null;
  var shouldShow = true;
  var pending = 0;
  var ajaxPromiseResolve = null;

  var show = function () {
    shouldShow = true;
    disableInputKeyEvents();

    if (!timeout) {
      updateState();
    }

    updateAfterDelay(400);
  };

  var showWhilePromisePending = async function (promise) {
    const isPendingSymbol = Symbol();
    const showIfPending = function (result) {
      if (result === isPendingSymbol) {
        pending++;
        show();

        promise.finally(function () {
          pending--;
          hide();
        });
      }
    };

    await Promise.race([promise, Promise.race([Promise.resolve(isPendingSymbol)])]).then(
      showIfPending,
      showIfPending,
    );

    return promise;
  };

  var hide = function () {
    shouldShow = false;
    enableInputKeyEvents();

    if (!timeout) {
      updateAfterDelay(0);
    }
  };

  var updateAfterDelay = function (delay) {
    clearTimeout(timeout);
    timeout = setTimeout(updateState, delay);
  };

  var updateState = function () {
    timeout = null;

    if (shouldShow || pending > 0) {
      $container.stop().fadeIn(animation);
    } else {
      $container.fadeOut(animation);
    }
  };

  var loadAjaxEventListeners = function () {
    const $document = $(document);

    $document.unbind("ajaxStart", ajaxStart);
    $document.bind("ajaxStart", ajaxStart);

    $document.unbind("ajaxStop", ajaxStop);
    $document.bind("ajaxStop", ajaxStop);
  };

  var disableInputKeyEvents = function () {
    $("input").on("keydown keypress keyup", disableEvent);
  };

  var enableInputKeyEvents = function () {
    $("input").off("keydown keypress keyup", disableEvent);
  };

  var disableEvent = function () {
    return false;
  };

  var ajaxStart = function () {
    if (!Offline.onLine() || ajaxPromiseResolve) {
      return;
    }

    showWhilePromisePending(new Promise((resolve) => (ajaxPromiseResolve = resolve)));
  };

  var ajaxStop = function () {
    if (ajaxPromiseResolve) {
      ajaxPromiseResolve();
      ajaxPromiseResolve = null;
    }
  };

  var getLoadingHtml = function () {
    return `
          <div style="
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding-top: 25vh;
            background: rgba(0, 0, 0, 0.6);
            text-align: center;
          ">
              <img src="${$container.find("img")[0].src}" alt="Loading...">
          </div>`;
  };

  var waitForResult = async function (selectorOrElement, asyncFunction) {
    startWaitingForResult(selectorOrElement);
    const result = await asyncFunction;
    doneWaitingForResult(selectorOrElement);
    return result;
  };

  var startWaitingForResult = function (selectorOrElement) {
    $(selectorOrElement).addClass(waitingForResultClass);
  };

  var doneWaitingForResult = function (selectorOrElement = null) {
    $(selectorOrElement || `.${waitingForResultClass}`).removeClass(waitingForResultClass);
  };

  return {
    show,
    showWhilePromisePending,
    hide,
    loadAjaxEventListeners,
    getLoadingHtml,
    startWaitingForResult,
    doneWaitingForResult,
    waitForResult,
  };
};

module.exports = LoadingScreen();

const Offline = require("../offline/offline");
