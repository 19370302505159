"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetComparisonToTarget = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 2;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetComparisonToTarget.prototype = Object.create(InsightWidget.prototype);
InsightWidgetComparisonToTarget.prototype.constructor = InsightWidgetComparisonToTarget;

InsightWidgetComparisonToTarget.prototype.getHtml = function (insightData) {
  var legend = this.widgetConfig.getLegend(insightData);
  var data = this.widgetConfig.getChartData(insightData);
  const unit = this.widgetConfig.unit;

  var html = nunjucks.render("insight/widgets/comparisonToTarget.njk", {
    title: this.widgetConfig.title,
    legend,
    data,
    unit,
  });

  return html;
};

InsightWidgetComparisonToTarget.prototype.getHoverHtml = function (insightData) {};

module.exports = InsightWidgetComparisonToTarget;
