"use strict";

const MuniOutfallDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareMuniOutfall,
        template: "list/muniOutfallDataList.njk",
        layerName: "muniOutfalls",
      };
      DataList.init(config);
      initialRender();
      DataList.loadDomListeners([{ target: ".open-fact-sheet", callback: openGenericFactSheet }]);
      DataList.loadDomListeners([{ target: ".map-button-datalist", callback: openPopup }]);
    }
  };

  var openPopup = function () {
    const outfallId = $(this).data("id");
    MapFunctions.openPopupById(outfallId);
    Analytics.sendDataListEvent("address_to_map", "outfalls");
  };

  var initialRender = function () {
    DataList.setSorting("outfallName", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareMuniOutfall = function (muniOutfalls) {
    sortMuniOutfallsBy(muniOutfalls);

    for (const outfall of muniOutfalls) {
      const isHighPriority = ["high", "very-high"].includes(outfall.cof);
      outfall.iconClass = OutfallsIcon.getOutfallsIconClass(
        isHighPriority,
        outfall.condition,
        outfall.outfallInspectionIsDue,
      );
    }

    return {
      rows: muniOutfalls,
      options: {},
    };
  };

  var sortMuniOutfallsBy = function (muniOutfalls) {
    const alphaSorter = SortUtilities.alphaSorter;
    const booleanSorter = SortUtilities.booleanSorter;

    var sortersDict = {
      outfallName: alphaSorter,
      address: alphaSorter,
      displayOutfallType: alphaSorter,
      cof: SortUtilities.cofSorter,
      outletShape: alphaSorter,
      priority: booleanSorter,
      material: alphaSorter,
      displayOutletType: alphaSorter,
    };
    DataList.sortWithDict(muniOutfalls, sortersDict);
  };

  var openGenericFactSheet = function () {
    $("#details-button-container").addClass("desktop-only");
    const id = DataList.getIdFromRow($(this));
    OutfallFactSheetController.initFactSheet(id);
    Analytics.sendDataListEvent("open_factsheet", "outfalls");
  };

  return {
    init,
    _prepareMuniOutfall,
    render,
  };
};

module.exports = MuniOutfallDataListController();

const Analytics = require("../general/analytics");
const DataList = require("../mapFunctions/dataList");
const SortUtilities = require("../general/sortUtilities.js");
const MapFunctions = require("../mapFunctions/mapFunctions");
const OutfallFactSheetController = require("../muni/outfallFactSheetController");
const OutfallsIcon = require("../outfalls/outfallsIcon.js");
