"use strict";

const EnforcementValidator = function () {
  var getMissingRequiredFields = function (allData) {
    return FormValidator.getEmptyRequiredFieldsInData(
      allData,
      getRequiredEnforcementFields(allData),
    );
  };

  var getRequiredEnforcementFields = function (data) {
    const issueDateText =
      Misc.toTitleCase(data?.escalationAction?.replace("-", " ") ?? "Issue") + " Date";

    const fields = [
      {
        fieldName: "level",
        message: "Level",
      },
      {
        fieldName: "staff-name",
        message: "MS4 Staff",
      },
      {
        fieldName: "issue-date",
        message: issueDateText,
      },
    ];

    if (data.escalationAction !== "resolve") {
      fields.push({
        fieldName: "enforcement-reason",
        message: "Enforcement Reason",
      });
    }

    return fields;
  };

  return {
    getMissingRequiredFields,
  };
};

module.exports = EnforcementValidator();

const Misc = require("../misc");
const FormValidator = require("../general/formValidator");
