"use strict";

const FeatureFlag = function () {
  const enabled = function (flagKey) {
    return FeatureFlagModal.isFeatureFlagEnabled(flagKey);
  };

  return {
    enabled,
  };
};

module.exports = FeatureFlag();

const FeatureFlagModal = require("./featureFlagModal");
