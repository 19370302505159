"use strict";

const MainMapController = function () {
  var initTool = function () {
    const currentTool = Tree.get("tool");
    initDataView(currentTool);
    initSpatialView(currentTool);
    initActionButtons();
    displayTabByTool(currentTool);
    handleTabsVisible();
    handleTabsEnabled(currentTool);
    NavBar.initialize();
    initMapLayers(currentTool);
    Filters.initFilters();
    initTable(currentTool);
    handleMapListToggleDisplay();
    handleHideListActionButton();
    //@TODO close the legend if it is currently open
  };

  var initSpatialView = function () {
    const SpatialViewController = require("./spatialViewController");
    SpatialViewController.init();
  };

  var initDataView = function () {
    const DataViewController = require("./dataViewController");
    DataViewController.init();
  };

  var initTable = function () {
    $("#custom-tooltip").css("visibility", "hidden");
  };

  var displayTabByTool = function () {
    PageFunctions.goToPage(DataViewFunctions.getCurrentDataViewProperty("defaultTab"));
  };

  var loadLegend = function (mapId) {
    const dataView = Tree.get("dataView");
    const mapEnabledTabs = DataViewFunctions.getUserEnabledTabs(dataView, "map");
    if ((mapEnabledTabs || []).length === 0) return;
    const conditionPhaseToggle = Tree.get(["filters", "conditionPhaseToggle"]);
    var capTool = Misc.capitalizeFirstLetter(Tree.get("tool"));
    var legendSelector = getLegendSelector(mapId);
    var formattedDataView = Misc.capitalizeFirstLetter(Misc.kebabToCamel(dataView));

    var legendLayersFilePath = "maps/legendLayers" + formattedDataView + ".njk";
    var legendLegendFilePath = "maps/legendLegend" + formattedDataView + ".njk";
    var layersHtml = nunjucks.render(legendLayersFilePath, {
      mapId,
      capTool,
      dataView,
      conditionPhaseToggle,
      isNewDataDrivenRoadConditionGroup: Session.isNewDataDrivenRoadConditionGroup(),
      isBlankSlateRoadConditionGroup: Session.isBlankSlateRoadConditionGroup(),
      esriLayer: sessionStorage.getItem("esriLayer"),
      isGisDataView: DataViewFunctions.getCurrentDataViewProperty("isGisDataView"),
      useSimpleOutfallG2Layer:
        DataViewFunctions.getCurrentDataViewProperty("useSimpleOutfallG2Layer"),
      geoServerLayerType: sessionStorage.getItem("geoServerLayerType"),
      simplifiedStormdrainLayer: ToolSettings.getSetting("simplifiedStormdrainLayer"),
      customLayers: getCustomLayerData(),
      dryWeatherG2LayerClusterFeatureFlagged: FeatureFlag.enabled("dry-weather-g2-layer-cluster"),
    });
    var legendHtml = nunjucks.render(legendLegendFilePath, {
      dataView,
      mapId,
      capTool,
      conditionPhaseToggle,
      isNewDataDrivenRoadConditionGroup: Session.isNewDataDrivenRoadConditionGroup(),
      isBlankSlateRoadConditionGroup: Session.isBlankSlateRoadConditionGroup(),
      isCdot: Session.isCdot(),
    });

    $(legendSelector).find(".legend-layers").html(layersHtml);
    $(legendSelector).find(".map-legend").html(legendHtml);

    if (mapId === "main") {
      MapFunctions.setMapHeight();
    }

    handleLanduseLayer();
    handleCustomLayerHeight();
  };

  var handleCustomLayerHeight = function () {
    const customEsriLayerSettings = ToolSettings.getSetting("customEsriLayerSettings");
    const customLayerCount = customEsriLayerSettings?.layers?.length;
    if (customLayerCount > 1) {
      const divHeight = customLayerCount * 20 + 125;
      $(".layers-checkbox-div").css("height", `${divHeight}px`);
      $(".legend-checkbox-div").css("height", `${divHeight}px`);
    }
  };

  var getCustomLayerData = function () {
    const layers = CustomLayer.getCustomLayersToDisplayByModule(
      ToolSettings.getSetting("customEsriLayerSettings"),
    );
    if (!layers || layers.length === 0) return [];

    const mappedLayers = layers.map(function (layer) {
      return {
        displayName: layer.layerName,
        camelCaseName: Misc.toCamelCase(layer.layerName),
      };
    });
    return mappedLayers;
  };

  var handleLanduseLayer = async function () {
    if (Tree.get("tool") === "esg") {
      return;
    }

    var landuseLayerCount = Tree.get("landuseLayerCount");
    if (Tree.get("landuseLayerCountFetching")) {
      return;
    }

    if (landuseLayerCount === undefined) {
      Tree.set("landuseLayerCountFetching", true);
      landuseLayerCount = await getLanduseLayerCount();
      Tree.set("landuseLayerCount", landuseLayerCount);
      Tree.set("landuseLayerCountFetching", false);
    }

    const hasLanduseData = landuseLayerCount > 0;
    const $radioLanduse = $(".radio-landuse");

    Misc.toggleDisabled($radioLanduse, !hasLanduseData);
  };

  var getLanduseLayerCount = async function () {
    const esriKey = "soilImpervious";
    const esriLayer = "landuse";

    const token = await EsriLayerFunctions.getEsriLayerToken();
    const layerId = EsriLayerFunctions.getLayerId(esriKey, esriLayer);
    const esriLayerWhere = EsriLayerFunctions.getLayerDefsQuery(esriKey, esriLayer);
    const esriLayerUrl = EsriLayerFunctions.getEsriLayerUrl(esriKey);

    return await new Promise(function (resolve) {
      L.esri
        .query({
          url: `${esriLayerUrl}/${layerId}`,
        })
        .token(token)
        .returnGeometry(false)
        .where(esriLayerWhere)
        .count(function (error, count, response) {
          if (error) {
            console.log(error);
            return;
          }

          resolve(count);
        });
    });
  };

  var getLegendSelector = function (mapId) {
    return "#" + mapId + "-legend";
  };

  var initActionButtons = function () {
    $(".action-button-div").hide();

    if (!Session.isRegulator()) {
      var dualActionButtons = DataViewFunctions.getCurrentDataViewProperty("dualActionButtons");
      removeActionButtonListeners();
      if (dualActionButtons) {
        showDualActionButtons(dualActionButtons);
      } else {
        showSingleActionButton();
      }
      addActionButtonListeners();
    }
  };

  var handleHideListActionButton = function () {
    const listActionButtonHide =
      DataViewFunctions.getCurrentDataViewProperty("listActionButtonHide");
    if (listActionButtonHide) {
      $(".action-button-div").addClass("hidden");
    }
  };

  var showSingleActionButton = function () {
    const $actionButtonDiv = $(".action-button-div.single-action");
    const $actionButton = $actionButtonDiv.find(".action-button");

    $actionButtonDiv.show();
    const actionButtonConfig = DataViewFunctions.getCurrentDataViewProperty("actionButton");
    const permissionKey = actionButtonConfig?.permissionKey;
    if (permissionKey !== undefined) {
      actionButtonConfig.disabled = !UserPermissions.getPermission(permissionKey);
    }

    if (actionButtonConfig) {
      $actionButton.text(actionButtonConfig.buttonText);
      $actionButton.attr("data-action", actionButtonConfig.dataAction);
      $actionButton.toggleClass("disabled", actionButtonConfig.disabled === true);

      if (actionButtonConfig.defaultHidden) {
        $actionButtonDiv.hide();
      }
      if (!actionButtonConfig.offline) {
        $actionButton.addClass("online-only");
      }
    } else {
      throw new Error(`No actionButton configured for the ${Tree.get("dataView")} data view.`);
    }
  };

  var showDualActionButtons = function (dualActionButtons) {
    const $actionButtonDiv = $(".action-button-div.dual-action");

    $actionButtonDiv.show();
    $actionButtonDiv.each((divIndex, div) => {
      dualActionButtons.forEach((config, index) => {
        var $button = $(div).find(".btn").eq(index);
        $button.text(config.buttonText);
        $button.toggleClass(
          "disabled",
          $button.disabled === true ||
            !UserPermissions.getPermission(config.permissionKey) ||
            config.defaultDisabled === true,
        );
        $button.attr("data-action", config.dataAction);
        if (!config.offline) {
          $button.addClass("online-only");
        }
      });
    });
  };

  var actionButtonListenerMap = function () {
    return {
      "add-new": InventoryModal.newInventory,
      "bulk-clean": BulkSelectModalController.invokeCatchBasinBulkCleanModal,
      "road-om": RoadOmModalController.renderAndShow,
      "bulk-record-sweep": RecordSweepModal.renderAndShowBulkModal,
      "run-telr-scenario": ScenarioInsightDashboardController.onClickRunTelrButton,
    };
  };

  var removeActionButtonListeners = function () {
    $.each(actionButtonListenerMap(), function (dataAttr, action) {
      $(".action-button[data-action='" + dataAttr + "']").off("click", action);
    });
  };

  var addActionButtonListeners = function () {
    $.each(actionButtonListenerMap(), function (dataAttr, action) {
      $(".action-button[data-action='" + dataAttr + "']").on("click", action);
    });
  };

  var handleTabsVisible = function () {
    const enabledTabs = DataViewFunctions.getUserEnabledTabs();
    if (!enabledTabs) {
      throw new Error(`No enabledTabs configured for ${Tree.get("dataView")} in DataViewConfig.`);
    }
    $("#tool-nav-tabs li")
      .attr("hidden", true)
      .each(function () {
        const $tab = $(this);
        if (enabledTabs.includes($tab.find("a").data("maptarget"))) {
          $tab.attr("hidden", false);
        }
      });
  };

  var handleTabsEnabled = function () {
    goToDefaultPageIfCurrentPageDisabled();
    const enabledTabs = DataViewFunctions.getUserEnabledTabs();
    if (!enabledTabs) {
      throw new Error(`No visibleTabs configured for ${Tree.get("dataView")} in DataViewConfig.`);
    }
    $("#tool-nav-tabs li")
      .addClass("disabled")
      .each(function () {
        const $tab = $(this);
        if (enabledTabs.includes($tab.find("a").data("maptarget"))) {
          $tab.removeClass("disabled");
        }
      });
  };

  var handleMapListToggleDisplay = function () {
    var hideMapListToggle = DataViewFunctions.getCurrentDataViewProperty("hideMapListToggle");
    const dataTab = hideMapListToggle?.data;
    const toDoTab = hideMapListToggle?.todo;
    const insightTab = hideMapListToggle?.insight;
    const resultTab = hideMapListToggle?.result;

    const activeTab = Tree.get("activeTab");
    if (
      (activeTab === "todo" && toDoTab) ||
      (activeTab === "data" && dataTab) ||
      (activeTab === "insight" && insightTab) ||
      (activeTab === "result" && resultTab)
    ) {
      $(".nav-toggle-mount").addClass("hidden");
    } else {
      if ($(".nav-toggle-mount").hasClass("hidden")) {
        $(".nav-toggle-mount").removeClass("hidden");
        $(".nav-toggle-mount").css("display", "flex");
      }
    }
  };

  var goToDefaultPageIfCurrentPageDisabled = function () {
    const currentPage = Tree.get("activeTab");
    const currentPageIsEnabled = PageFunctions.pageIsEnabled(currentPage);

    if (!currentPageIsEnabled) {
      PageFunctions.goToDefaultPage();
    }
  };

  var initMapLayers = function (tool) {
    if (Session.isRegulator()) {
      return;
    }

    enableLayers(ToolFunctions.getToolDefaultEnabledLayers(tool), "main");
  };

  var enableLayers = function (layersArray, mapId) {
    layersArray.forEach((layer) => {
      DisplayOptions.handleLayerDisplay(layer, true, mapId);
    });
  };

  return {
    initTool,
    loadLegend,
    enableLayers,
    initActionButtons,
    showSingleActionButton,
    showDualActionButtons,
    handleTabsEnabled,
    handleTabsVisible,
    handleMapListToggleDisplay,
    handleHideListActionButton,
    handleCustomLayerHeight,
  };
};

module.exports = MainMapController();

const BulkSelectModalController = require("../bulkSelect/bulkSelectModalController");
const CustomLayer = require("./customLayer");
const DataViewFunctions = require("../dataViewFunctions");
const DisplayOptions = require("./displayOptions");
const Filters = require("./filters");
const InventoryModal = require("../general/inventoryModal");
const MapFunctions = require("./mapFunctions");
const Misc = require("../misc");
const PageFunctions = require("../pageFunctions");
const RecordSweepModal = require("../muni/recordSweepModal");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const ToolFunctions = require("../toolFunctions");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const NavBar = require("../navBar");
const ScenarioInsightDashboardController = require("../scenario/scenarioInsightDashboardController");
const RoadOmModalController = require("../muni/roads/roadOmModalController");
const EsriLayerFunctions = require("../mapFunctions/esriLayerFunctions");
const FeatureFlag = require("../settings/featureFlag");
