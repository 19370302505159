"use strict";

const FormPhotos = function () {
  var initializeFormPhotos = function ($parent, formKey) {
    loadListeners($parent, formKey);
    _initializeIdGenerator($parent);
  };

  var loadListeners = function ($parent, formKey) {
    $parent.on("click", ".browse-form-photo", triggerPhotoUpload);
    $parent.on("change", ".form-photo-input", (e) => _onPhotoInput(formKey, e));
    $parent.on("click", ".photo-thumb", (e) => openPhotoCarousel(formKey, e));
  };

  var unloadListeners = function ($parent) {
    $parent.off("click", ".browse-form-photo");
    $parent.off("change", ".form-photo-input");
    $parent.off("click", ".photo-thumb");
  };

  var openPhotoCarousel = function (formKey, e) {
    const $photo = $(e.currentTarget);
    loadPhotoCarousel(formKey, $photo.parent());
    PhotoCarousel.showPhotoCarouselModalFromPhotoId($photo.data("id"));
  };

  var _updateFormPhotoCaption = function (formKey, $activeImage, newValue) {
    const Form = require("./form");
    let { $photoThumb, photoId, path, index } = getPhotoVariables(formKey, $activeImage);
    if (index === -1) {
      index = createFormPhotoEntry(formKey, path, photoId);
    }
    Form.manuallySetFormDataField(formKey, [...path, index, "caption"], newValue);
    $photoThumb.find("img").attr("alt", newValue);
  };

  var _deleteFormPhoto = function (formKey, $activeImage) {
    const Form = require("./form");
    let { $photoThumb, photoId, path, index } = getPhotoVariables(formKey, $activeImage);

    if (index !== -1 && photoId.includes("new-photo")) {
      const photoData = Form.getFormDataAtPath(formKey, path);
      photoData.splice(index, 1);
      Form.manuallySetFormDataField(formKey, path, photoData);
    } else {
      if (index === -1) {
        index = createFormPhotoEntry(formKey, path, photoId);
      }
      Form.manuallySetFormDataField(formKey, [...path, index, "deleted"], "true");
    }
    $photoThumb.remove();
  };

  var _updateFormPhotoRotation = function (formKey, $activeImage, direction, currentRotation) {
    const Form = require("./form");
    let { $photoThumb, photoId, path, index } = getPhotoVariables(formKey, $activeImage);

    $photoThumb
      .find("img")
      .css("transform", `rotate(${currentRotation}deg)`)
      .data("rotation", currentRotation);
    if (index === -1) {
      index = createFormPhotoEntry(formKey, path, photoId);
    }
    Form.manuallySetFormDataField(formKey, [...path, index, "rotation"], currentRotation);
    PhotoCarousel.restoreRotation();
  };

  var getPhotoVariables = function (formKey, $activeImage) {
    const Form = require("./form");

    const photoId = $activeImage.data("id").toString();
    const $photoThumb = $(`[data-id=${photoId}].photo-thumb`);
    const path = Form.getPathPartsFromName(getPhotoPath($photoThumb));
    const index = getPhotoIndex(formKey, path, photoId);

    return { $photoThumb, photoId, path, index };
  };

  var triggerPhotoUpload = function () {
    $(this).siblings(".form-photo-input").trigger("click");
  };

  var _onPhotoInput = async function (formKey, e) {
    const Form = require("./form");
    const $input = $(e.currentTarget);
    const photosData = {
      formKey,
      files: [...e.currentTarget.files],
      formPath: getPhotoPath($input),
      compressImages: $input.data("compressImages"),
      directUpload: $input.data("directUpload"),
    };
    $input.val("");

    Form.addReadyToSaveLock(formKey, prepareAndUploadPhotos(photosData));
  };

  var prepareAndUploadPhotos = async function (photosData) {
    await _preparePhotoData(photosData);
    await _uploadPhotos(photosData);
  };

  var _preparePhotoData = async function (photosData) {
    photosData.files = photosData.files.map(_idPhotoObject);
    photosData.files = await _validatePhotoFileTypes(photosData.files);
    _createTemporaryThumbnails(photosData.files, photosData.formPath);

    if (photosData.compressImages) {
      await _compressPhotos(photosData.files);
    }

    photosData.files = _validatePhotoFileSizes(photosData.files);
    photosData.files = await Promise.all(photosData.files.map(_addFileUrl));
  };

  var _uploadPhotos = async function (photosData) {
    if (photosData.directUpload && DirectUpload.enabled()) {
      await _directFormPhotoUpload(photosData);
    } else {
      await _formPhotoUpload(photosData);
    }
  };

  var _validatePhotoFileTypes = async function (files) {
    const filteredPhotoFiles = [];

    for (const fileObj of files) {
      if (await validatePhotoFileAndWarn(fileObj.file)) {
        filteredPhotoFiles.push(fileObj);
      }
    }

    return filteredPhotoFiles;
  };

  var validatePhotoFileAndWarn = async function (file, maxFileSize = null) {
    if (!(await isValidImage(file))) {
      MessageModal.showSimpleWarningModal("Unsupported File Type");
      return false;
    } else if (maxFileSize && file.size > maxFileSize) {
      MessageModal.showFileSizeWarning(file.name);
      return false;
    }

    return true;
  };

  var isValidImage = async function (file) {
    const fileTypeResult = await FileType.fromBlob(file);
    return imageMimeTypes.includes(fileTypeResult?.mime);
  };

  var _compressPhotos = async function (files) {
    // `resize()`` starts failing if too many big images
    // are processed at once, so can't use Promise.all().
    for (const fileObj of files) {
      const resizer = new ImageResizer(fileObj.file);
      fileObj.file = await resizer.resize();
    }
  };

  var _validatePhotoFileSizes = function (files) {
    return files.filter(function (fileObj) {
      const file = fileObj.file;

      if (file.size > 8000000) {
        MessageModal.showFileSizeWarning(file.name);
        return false;
      }
      return true;
    });
  };

  var _formPhotoUpload = async function (photosData) {
    await prepareFilesAndSetForm(photosData, function (fileObject) {
      return { id: fileObject.id, file: fileObject.file };
    });
  };

  var _directFormPhotoUpload = async function (photosData) {
    const Form = require("./form");

    await prepareFilesAndSetForm(photosData, async function (fileObject) {
      const data = {
        id: fileObject.id,
      };
      const directUploadFromDataStore = await Form.addFile(photosData.formKey, fileObject.file);

      if (directUploadFromDataStore) {
        data.directUpload = directUploadFromDataStore;
        fileObject.directUpload = directUploadFromDataStore;
      } else {
        data.uploadedFileName = await DirectUpload.uploadFile(fileObject.file, {
          stringKey: photosData.formKey,
          progressCallback: function (progressEvent) {
            const $progress = $(
              `[name="${photosData.formPath}"] .form-photo-list [data-id=${fileObject.id}] progress`,
            );
            $progress.attr("value", progressEvent.loaded);
            $progress.attr("max", progressEvent.total);
          },
        });
      }

      return data;
    });
  };

  var prepareFilesAndSetForm = async function (photosData, pushFunction) {
    const path = Form.getPathPartsFromName(photosData.formPath);

    const existingFiles = getExistingFiles(photosData.formKey, path);

    for (const fileObject of photosData.files) {
      try {
        const data = await pushFunction(fileObject);
        Form.manuallySetFormDataField(photosData.formKey, [...path, existingFiles.length], data);
        existingFiles.push(data);
        _replaceTemporaryThumbnail(photosData.formPath, fileObject);
      } catch (e) {
        _removeTemporaryThumbnail(photosData.formPath, fileObject);
        UploadValidator.reportError(
          e,
          `
          <p>Unable to add photo:</p>
          <p><img src="${fileObject.url}"></p>
          `,
        );
      }
    }
  };

  var getExistingFiles = function (formKey, path) {
    const store = Form.getDataStore(formKey);
    let data;

    if (store) {
      data = Form.getDataAtPath(store.getWithUpdates(), path);
    } else {
      data = Form.getFormDataAtPath(formKey, path);
    }

    return data || [];
  };

  var _idPhotoObject = function (file) {
    return {
      id: getNextPhotoId(),
      file: file,
    };
  };

  var _addFileUrl = async function (fileObj) {
    fileObj.url = await readFileAsDataUrl(fileObj.file);

    return fileObj;
  };

  var _replaceTemporaryThumbnail = function (formPath, fileObj) {
    const html = nunjucks.render("modals/photos/formPhotoThumb.njk", {
      photo: fileObj,
    });
    const $tempThumbnail = _getTemporaryThumbnail(formPath, fileObj);
    return $tempThumbnail.replaceWith(html);
  };

  var _removeTemporaryThumbnail = function (formPath, fileObj) {
    const $tempThumbnail = _getTemporaryThumbnail(formPath, fileObj);
    return $tempThumbnail.remove();
  };

  var _getTemporaryThumbnail = function (formPath, fileObj) {
    return $(`[name="${formPath}"] .form-photo-list [data-id=${fileObj.id}]`);
  };

  var _createTemporaryThumbnails = function (files, formPath) {
    for (const fileObject of files) {
      const html = nunjucks.render("modals/photos/tempFormPhotoThumb.njk", {
        photo: fileObject,
      });
      const $photoList = $(`[name="${formPath}"] .form-photo-list`);
      $(html).appendTo($photoList);
    }
  };

  var readFileAsDataUrl = function (file) {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  var _initializeIdGenerator = function ($parent) {
    const $lastThumb = $parent.find(".photo-thumb:last-child").first();
    const id = $lastThumb?.data("id");
    var lastNumber;

    if (typeof id === "string" && id.startsWith("new-photo-")) {
      lastNumber = id.split("-");
      lastNumber = parseInt(lastNumber[lastNumber.length - 1]);
      lastNumber++;
    }

    const idGenerator = Misc.getSequenceGenerator("new-photo-", lastNumber);
    getNextPhotoId = function () {
      return idGenerator.next().value;
    };

    return getNextPhotoId;
  };

  var getNextPhotoId = function () {
    throw Error("Generator was not initialized");
  };

  var loadPhotoCarousel = function (formKey, $parent) {
    const configuration = {
      caption: ($activeImage, newValue) => _updateFormPhotoCaption(formKey, $activeImage, newValue),
      delete: ($activeImage) => _deleteFormPhoto(formKey, $activeImage),
      rotate: ($activeImage, direction, currentRotation) =>
        _updateFormPhotoRotation(formKey, $activeImage, direction, currentRotation),
    };

    const $photoElements = $parent.find(".photo-thumb");
    const photos = getPhotosFromPhotoThumbs($photoElements);
    PhotoCarousel.load(photos, configuration);
  };

  var getPhotosFromPhotoThumbs = function ($photos) {
    return $photos.get().map(getPhotoObject);
  };

  var getPhotoObject = function (photo) {
    const photoId = $(photo).data("id");
    const photoImg = $(photo).find("img");
    return {
      id: photoId,
      caption: photoImg.attr("alt"),
      tempUrl: photoImg.attr("src"),
      full: photoImg.data("full"),
      rotation: photoImg.data("rotation"),
    };
  };

  var getPhotoPath = function ($target) {
    return $target.parents(".form-photos").attr("name");
  };

  var getPhotoIndex = function (formKey, path, id) {
    const Form = require("./form");

    const formData = Form.getFormDataAtPath(formKey, path) || [];
    return formData.findIndex((photo) => {
      return photo?.id === id;
    });
  };

  var createFormPhotoEntry = function (formKey, path, photoId) {
    const Form = require("./form");
    const formData = Form.getFormDataAtPath(formKey, path) || [];
    const newIndex = formData.length;
    Form.manuallySetFormDataField(formKey, [...path, newIndex], { id: photoId });
    return newIndex;
  };

  return {
    _preparePhotoData,
    _uploadPhotos,
    _formPhotoUpload,
    _updateFormPhotoCaption,
    _deleteFormPhoto,
    _updateFormPhotoRotation,
    _createTemporaryThumbnails,
    _replaceTemporaryThumbnail,
    _initializeIdGenerator,
    initializeFormPhotos,
    unloadListeners,
    readFileAsDataUrl,
    validatePhotoFileAndWarn,
  };
};

module.exports = FormPhotos();

const DirectUpload = require("../files/directUpload");
const Form = require("./form");
const ImageResizer = require("../files/imageResizer");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
const PhotoCarousel = require("./photoCarousel");
const UploadValidator = require("./uploadValidator");
const FileType = require("file-type/browser");
const { imageMimeTypes } = require("../files/mimeTypeConstants");
