"use strict";

var FlowRoutingLayer = function () {
  const stringKey = "flowRouting";
  const layerKey = "flowRoutingLayer";
  var Outfall;

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    Outfall = new Outfalls(
      stringKey,
      layerKey,
      OutfallsIcon.getFlowRoutingMarker,
      ReportApiCalls.getFlowRouting,
    );
    Outfall.loadToggledListenerForMap(map, mapLayers, mapId);
    Outfall.loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    Outfall.loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadLayer = function () {
    Outfall.loadOutfalls();
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = FlowRoutingLayer();

const OutfallsIcon = require("./outfallsIcon");
const ReportApiCalls = require("../reportApiCalls");
const Outfalls = require("./outfalls");
