"use strict";

const EsgSettings = function () {
  var hiddenFields = function () {
    return (
      ToolSettings.getSetting("group", "settings")?.["displaySettings"]?.["hiddenFields"] || []
    );
  };

  var propertyTitleDisplay = function (data = {}) {
    const labels = {
      propertyName: "Property Name",
      propertyIdentifier: "Property ID",
    };
    const fieldName = ToolSettings.getSetting("group", "settings")?.["displaySettings"]?.[
      "property"
    ];

    return {
      label: labels?.[fieldName],
      value: data?.[fieldName],
      fieldName,
    };
  };

  return {
    hiddenFields,
    propertyTitleDisplay,
  };
};

module.exports = EsgSettings();

const ToolSettings = require("../settings/toolSettings");
