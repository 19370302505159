const IddeManholeGeoServerLayer = function () {
  const layerName = "iddeManhole";
  const layerKey = "iddeManholeLayer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: L.divIcon({
        iconSize: ManholesIcon._updateIconSizeOnZoom(),
        popupAnchor: [0, 5],
        className: feature.properties.icon.class,
      }),
    });
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const muniManholeGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, muniManholeGeoServerLayer);

    muniManholeGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniManholeGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeMuniLayerData(data);
  };

  var organizeMuniLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    return {
      id: data.id,
      geometry: geometry,
      manholeName: data.manhole_name,
      manholeType: data.manhole_type,
      address: data.address,
      cof: data.cof_final_text,
      coverShape: data.cover_shape,
      icon: RoutineMaintenanceIcon.getMaintenanceIcon(
        "muni-manhole",
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
    };
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
  };
};

module.exports = IddeManholeGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
const ManholesIcon = require("./manholes/manholesIcon");
