"use strict";

var StreamsEsriDynamicMapLayer = function () {
  const esriKey = "soilImpervious";
  const esriLayer = "streams";
  const layerName = "streamsEsriDynamicMap";
  const layerKey = "streamsEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var layer = new EsriDynamicMapLayer(layerName, layerKey, esriKey, esriLayer);
    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = StreamsEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
