"use strict";

const EsriFeatureLayer = function (
  layerName,
  layerKey,
  esriKey,
  esriLayer,
  getLayerOptionsHandler = null,
) {
  const layerConfig = LayerFunctions.getLayerConfig(layerName);

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      var currentMapLayer = mapLayers[`${layerName}Layer`];
      if (isEnabled) {
        enableLayer(map, mapLayers, mapId, currentMapLayer, sameSpatialFilter);
      } else {
        disableLayer(map, mapLayers, mapId, currentMapLayer);
      }
    });
  };

  var enableLayer = function (map, mapLayers, mapId, currentMapLayer, sameSpatialFilter) {
    if (currentMapLayer && (mapId === "modal" || (mapId === "main" && sameSpatialFilter))) {
      map.addLayer(currentMapLayer);
    } else {
      loadEsriLayer(map, mapLayers, mapId);
    }
  };

  var disableLayer = function (map, mapLayers, mapId, currentMapLayer) {
    if (MapFunctions.mapHasLayer(map, currentMapLayer)) {
      map.removeLayer(currentMapLayer);
    }
  };

  var loadEsriLayer = async function (map, mapLayers, mapId) {
    if (!mapLayers[layerKey]) {
      mapLayers[layerKey] = await createEsriLayer();
    } else if (!layerConfig?.emptyLayerDef) {
      const layerId = EsriLayerFunctions.getLayerId(esriKey, esriLayer);
      const currentLayerDefs = EsriLayerFunctions.getLayerDefs(
        esriKey,
        esriLayer,
        false,
        layerConfig?.emptyLayerDef,
      );
      const sameDynamicLayerDefs =
        currentLayerDefs?.[layerId] === mapLayers[layerKey].getLayerDefs()?.[layerId];
      if (!sameDynamicLayerDefs) {
        mapLayers[layerKey].setLayerDefs(currentLayerDefs);
      }
    }

    if (Tree.get("layers", layerName, Actions.getLayerIsEnabledPathByMapId(mapId))) {
      mapLayers[layerKey].addTo(map);
    }
    return mapLayers[layerKey];
  };

  var createEsriLayer = async function () {
    var mapLayerOptions = getMapLayerOptions();
    var layer = await EsriLayerFunctions.getEsriFeatureLayer(esriKey, esriLayer, mapLayerOptions);
    return layer;
  };

  var getMapLayerOptions = function () {
    var options = {};
    if (getLayerOptionsHandler) {
      options = getLayerOptionsHandler();
    }

    return Object.assign(options, {
      opacity: layerConfig?.opacity || 1,
      minZoom: layerConfig?.minZoom,
      pane: layerConfig?.pane || "overCatchmentPane",
      layerDefs: EsriLayerFunctions.getLayerDefs(
        esriKey,
        esriLayer,
        false,
        layerConfig?.emptyLayerDef,
      ),
    });
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers, mapId) {
    Tree.select("filters").on("update", function () {
      const currentMapLayer = mapLayers[layerKey];
      if (
        MapFunctions.mapHasLayer(map, currentMapLayer) &&
        Tree.get("layers", layerName, "isEnabled")
      ) {
        loadEsriLayer(map, mapLayers, mapId);
      }
    });
  };

  return {
    loadToggledListenerForMap,
    loadTreeUpdateListenersForMap,
  };
};

module.exports = EsriFeatureLayer;

const Actions = require("../actions");
const EsriLayerFunctions = require("./esriLayerFunctions");
const MapFunctions = require("./mapFunctions");
const Tree = require("../tree");
const LayerFunctions = require("../layerFunctions");
