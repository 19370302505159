"use strict";

const ToolConfig = function () {
  const get = function (forHome = false) {
    const tools = allTools();
    if (!forHome) {
      Object.keys(tools).forEach((key) => {
        if (tools[key].homePageOnly) {
          delete tools[key];
        }
      });
    }
    return tools;
  };

  const allTools = function () {
    const Home = require("../home");
    return {
      report: {
        name: "Annual Reporting",
        external: true,
        sortOrder: 1,
        homeCategory: 0,
        description: Home.getAnnualReportDescription(),
      },
      pie: {
        name: "Program Impact",
        homePageOnly: true,
        sortOrder: 2,
        homeCategory: 0,
        description: "Quantified tracking of stormwater quality improvements",
      },
      compcal: {
        name: "Compliance Calendar",
        homePageOnly: true,
        sortOrder: 3,
        homeCategory: 0,
        comingSoon: true,
        description: "Consolidated to do list of high priority program tasks",
      },
      suit: {
        name: "Suitability Analysis",
        homePageOnly: true,
        sortOrder: 15,
        homeCategory: 3,
        comingSoon: true,
      },
      scenario: {
        name: "Scenario Planning",
        sortOrder: 17,
        homeCategory: 3,
        dataViews: ["scenarios"],
        dataSorts: ["rw", "uc", "ud"],
        customTabName: {
          data: "Inputs",
        },
      },
      plan: {
        name: "Strategic Planning",
        homePageOnly: true,
        external: true,
        sortOrder: 4,
        disabled: true,
      },
      bmpram: {
        name: "sBMP/SCM Diagnostics",
        sortOrder: 11,
        homeCategory: 2,
        dataViews: ["bmp"],
        dataSorts: ["rw", "uc", "ud"],
      },
      trashram: {
        name: "Trash Compliance",
        sortOrder: 12,
        homeCategory: 2,
        dataViews: ["fcs", "va"],
      },
      construction: {
        name: "Construction",
        sortOrder: 5,
        homeCategory: 1,
        offline: true,
        dataViews: ["construction-project"],
      },
      projdelivery: {
        name: "Project Delivery",
        sortOrder: 16,
        homeCategory: 3,
        homePageOnly: false,
        comingSoon: false,
        offline: true,
        dataViews: ["construction-project-delivery"],
      },
      lid: {
        name: "Post-Construction",
        sortOrder: 6,
        homeCategory: 1,
        dataViews: ["lid-project", "lid-bmp", "lid-runoff"],
      },
      indcom: {
        name: "Industrial / Commercial",
        sortOrder: 7,
        homeCategory: 1,
        dataViews: ["indcom-facilities"],
        offline: true,
      },
      idde: {
        name: "Illicit Discharge",
        sortOrder: 8,
        homeCategory: 1,
        dataViews: ["incidents", "dry-weather"],
      },
      muni: {
        name: "Municipal Maintenance",
        sortOrder: 9,
        homeCategory: 1,
        offline: true,
        dataViews: [
          "muni-catch-basin",
          "muni-outfalls",
          "muni-bmp",
          "muni-manhole",
          "muni-cleanout",
          "muni-lift-stations",
          "muni-pipes",
          "muni-open-channel",
          "muni-culvert",
          "roads",
        ],
      },
      outmon: {
        name: "Water Quality Monitoring",
        sortOrder: 13,
        homeCategory: 2,
        enabledTabs: ["data"],
        dataViews: ["outfalls"],
      },
      assets: {
        name: "Asset Lifecycle Optimization",
        sortOrder: 18,
        homeCategory: 3,
        enabledTabs: ["data"],
        dataViews: ["asset-management"],
        homePageOnly: false,
        comingSoon: false,
      },
      peo: {
        name: "Public Ed & Outreach",
        sortOrder: 10,
        homeCategory: 1,
        enabledTabs: ["data"],
        dataViews: ["activities"],
      },
      imac: {
        name: "Impervious Accounting",
        homePageOnly: true,
        sortOrder: 14,
        homeCategory: 2,
        comingSoon: true,
      },
      cr: {
        name: "Cost Reporting",
        homePageOnly: true,
        sortOrder: 19,
        homeCategory: 3,
        comingSoon: true,
      },
    };
  };

  return {
    get,
  };
};

module.exports = ToolConfig();
