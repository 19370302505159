"use strict";

var ConnectivityInputsLayer = function () {
  const layerName = "connectivityInputs";
  const layerKey = "connectivityInputsLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadColumnArrayUpdatedListener(mapId);
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      layerName,
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        var runoffLayer = mapLayers[layerKey];
        var forceRefresh = !sameSpatialFilter || !sameView || !sameGroup;
        if (isEnabled) {
          TelrLayerFunctions.displayTelrLayer(
            runoffLayer,
            layerName,
            "percentConnectivity",
            map,
            mapLayers,
            forceRefresh,
          );
        } else {
          if (MapFunctions.mapHasLayer(map, runoffLayer)) {
            map.removeLayer(runoffLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, layerName, function (data) {
      mapLayers[layerKey] = addConnectivityInputsLayer(map, mapLayers[layerKey], data);
      TelrLayerFunctions.selectTelrCatchmentsByFilter(mapLayers[layerKey]);
      if (
        Tree.get("renderTableOnNextDataLayerLoad") &&
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var addConnectivityInputsLayer = function (map, layer, layerData) {
    if (layer) {
      map.removeLayer(layer);
    }
    layer = new (Layers.getLayers().ConnectivityLayer)(layerData).addTo(map);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Only watch for specific filters since other filters updates are already handled in treeUpdates
    // @TODO: Investigate why listening to filters update causes an infinite loop while searching
    Tree.watch({
      connectivity: ["filters", "connectivity"],
    }).on("update", function () {
      if (
        DataViewFunctions.getCurrentLayerName() === layerName &&
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", mapId, layerName, "isEnabled")
      ) {
        Tree.set(["renderTableOnNextDataLayerLoad"], true);
      }
    });
  };

  var loadColumnArrayUpdatedListener = function (mapId) {
    Tree.select(["layers", mapId, layerName, "columnArray"]).on("update", function (e) {
      var columns = e.data.currentData;
      DataViewDropdown.updateLegendColumns(columns, "runoff-progress", true);
    });
  };

  var getColumnValues = function () {
    return [0.1, 0.25, 0.5, 0.75, 1];
  };

  var getKpi = async function (callback) {
    const filters = Tree.get("filters");
    const data = await getLayerDataWhenAvalible();

    const features = data.features.filter(function (feature) {
      return Layers.featureInFilter(filters, feature);
    });
    var kpi = {};
    addKpiYearData(kpi);
    addKpiAcres(kpi, features);

    callback([kpi]);
  };

  var getLayerDataWhenAvalible = async function () {
    const mapId = Tree.get("mapId");
    var data = Tree.get("layers", mapId, layerName, "data");
    while (Tree.get("layers", mapId, layerName, "isFetching") || !data) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      data = Tree.get("layers", mapId, layerName, "data");
    }
    return data;
  };

  var addKpiAcres = function (kpi, features) {
    kpi.totalAcres = 0;
    for (const feature of features) {
      const key = `connectivityAcres${feature.properties.connect * 100}`;
      const acres = parseFloat(feature.properties.acres);

      if (!kpi[key]) {
        kpi[key] = 0;
      }

      kpi.totalAcres += acres;
      kpi[key] += acres;
    }
    return kpi;
  };

  var addKpiYearData = function (kpi) {
    const report = AnnualReports.getYearReports(
      AnnualReports.getSelectedYear(),
      "stormwater group",
    )[0];
    kpi.year = report.reportingYear;
    kpi.dataRangeFirst = report.dataRangeFirst;
    kpi.dataRangeLast = report.dataRangeLast;
    kpi.displayDataRangeFirst = report.displayDataRangeFirst;
    kpi.displayDataRangeLast = report.displayDataRangeLast;
  };

  return {
    loadLayerListenersForMap,
    getColumnValues,
    getKpi,
  };
};

module.exports = ConnectivityInputsLayer();

const MapFunctions = require("../../mapFunctions/mapFunctions");
const Tree = require("../../../tree");
const Table = require("../../mapFunctions/table");
const Layers = require("../../mapFunctions/layers");
const TelrLayerFunctions = require("../../telr/telrLayerFunctions");
const DataViewDropdown = require("../../mapFunctions/dataViewDropdown");
const AnnualReports = require("../../annualReports");
const DataViewFunctions = require("../../dataViewFunctions");
