"use strict";

const Table = function () {
  var $tableContainer = null;
  var $table = null;
  let mapId = null;

  var loadListeners = function (thisMapId) {
    Filters.loadFilterListeners(thisMapId);
    // list item events
    mapId = thisMapId;
    $tableContainer = PageFunctions.getCurrentPage().find(".floating-inputs-table");
    $table = $tableContainer.find(".bottomFloatingInputsTable");
    $tableContainer.off();
    $table.off();

    $tableContainer.on("mouseover", ".inputs-table li", listItemMouseover);
    $tableContainer.on("mouseout", ".inputs-table li", listItemMouseout);
    setupClickDblclickListeners(
      $tableContainer,
      ".inputs-table li",
      listItemClick,
      listItemDblClick,
    );

    setupClickDblclickListeners(
      $tableContainer,
      ".catchments-only h3",
      urbanCatchmentClick,
      urbanCatchmentDblClick,
    );

    $tableContainer.on("mouseover", ".catchments-only h3", listItemMouseover);
    $tableContainer.on("mouseout", ".catchments-only h3", listItemMouseout);
    $tableContainer.on("click", function () {
      const openPopups = $(".leaflet-popup-close-button");
      if (openPopups.length) {
        openPopups[0].click();
      }
    });

    // list header events
    $tableContainer.on("mouseover", ".catchment-table-header h3", headerMouseover);
    $tableContainer.on("mouseout", ".catchment-table-header h3", headerMouseout);
    $tableContainer.on("click", ".catchment-table-header h3", headerClick);

    // data column highlight events
    $tableContainer.on("mouseover", ".data-col", highlightDataCol);
    $tableContainer.on("mouseout", ".data-col", unhighlightDataCol);

    $tableContainer.on("click", ".collapse-button", collapseSideBar);

    $("#map-container").on("click", ".expand-side-bar-button", expandSideBar);

    Tree.select("filters", "dataSort").on("update", resetExpandedInTree);
  };

  var resetExpandedInTree = function () {
    Tree.set(["table", "expanded"], []);
  };

  var saveExpandedToTree = function () {
    var drainsToId = PageFunctions.getCurrentPage()
      .find(".drains-to-parent-div.active h3[data-type='drainsTo']")
      .get()
      .map(function (element) {
        return $(element).data("id");
      });
    Tree.set(["table", "expanded"], drainsToId);
  };

  var setupClickDblclickListeners = function (
    container,
    selector,
    clickListener,
    dblclickListener,
  ) {
    container
      .on("click", selector, function (e) {
        var that = this;
        setTimeout(function () {
          var dblclick = parseInt($(that).data("double"), 10);
          if (dblclick > 0) {
            $(that).data("double", dblclick - 1);
          } else {
            clickListener.call(that, e);
          }
        }, 300);
      })
      .on("dblclick", selector, function (e) {
        $(this).data("double", 2);
        dblclickListener.call(this, e);
      });
  };

  var loadTable = function () {
    setDataColTooltips();
    Filters.load();
  };

  var setDataColTooltips = function () {
    const mapId = Tree.get("mapId");
    // @TODO: Move this into dataView config
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.total-acres`,
      text: "Total Area (acres)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.plu-acres`,
      text: "Total PLU (acres)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.plu-acres-report`,
      text: "Total PLU (acres)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.treated-percent`,
      text: "% Treated",
      width: 110,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.untreated-plu-acres`,
      text: "PLU Area Untreated (acres)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.reduction-runoff`,
      text: "Runoff Reduction (afy)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.reduction-particulates`,
      text: "Particulate Reduction (ton/yr)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.reduction-perc`,
      text: "Reduction from Baseline",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='sbmpView'] .data-count-rollup`,
      text: "Structural BMP Count (#)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='fcsView'] .data-count-rollup`,
      text: "Trash Full Capture System Count (#)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='muniCatchBasinView'] .data-count-rollup`,
      text: "Catch Basin Count (#)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='constructionView'] .data-count-rollup`,
      text: "Construction Project Count (#)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='lidView'] .data-count-rollup`,
      text: "PCR Project Count (#)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.imp-area`,
      text: "Treated Impervious Area (acres)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.plu-area`,
      text: "Trash Priority Land Use (acres)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='runoffView'] .data-col.volume`,
      text: "Runoff Volume (afy)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='runoffView'] .data-col.rate`,
      text: "Runoff Rate (ft/yr)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='particulatesView'] .data-col.volume`,
      text: "Particulate Load (ton/yr)",
      width: 150,
    });
    Tooltip.setTooltip({
      selector: `#${mapId}[data-view='particulatesView'] .data-col.rate`,
      text: "Particulate Rate (ton/ac/yr)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.reduction-runoff-ratio`,
      text: "Runoff Ratio Reduction (%)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.volume-runoff-ratio`,
      text: "Runoff Ratio (%)",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.percent-imp`,
      text: "% Impervioius",
      width: 140,
    });
    Tooltip.setTooltip({
      selector: `#${mapId} .data-col.area`,
      text: "Area (acres)",
      width: 110,
    });
  };

  var render = function (catchments = null) {
    if (Progeny.activeHasProgeny()) {
      _resetTree();
      Ms4Table.loadMs4Table();
      return;
    }

    if (catchments === null) {
      catchments = Actions.getCatchmentData();
    }

    if (!catchments || !catchments.length) {
      Tree.set("renderTableOnNextDataLayerLoad", true);
      return;
    }

    catchments = Filters.filterCatchmentsBySearch(catchments);
    TableDataFunctions.addSelectedToCatchments(catchments);
    const organizedCatchments = TableDataFunctions.organizeCatchments(catchments);
    renderPreparedData(organizedCatchments, catchments);
    syncDomWithTree();
  };

  var renderPreparedData = function (organizedCatchments, catchments) {
    const DataViewFunctions = require("../dataViewFunctions");
    var mapId = Tree.get("mapId");
    var dataSort = Tree.get("filters", "dataSort");
    var allMs4Stats = Tree.get("spatialGroups", mapId, "allMs4");
    var currentLayerName = DataViewFunctions.getCurrentLayerName();

    TableDataFunctions.addTableDataByDataView(organizedCatchments, catchments, allMs4Stats);
    organizedCatchments = normalizeCatchmentData(organizedCatchments, dataSort);
    var data = getTemplateData(dataSort, mapId, organizedCatchments, allMs4Stats, currentLayerName);
    addAllMs4GroupName(data);

    data.selected = Tree.get("selected");
    data.table = Tree.get("table");

    var html = nunjucks.render("report/table/list.html", data);
    $table.html(html);
    Tree.set("renderTableOnNextDataLayerLoad", false);
  };

  var addAllMs4GroupName = function (data) {
    const activeGroupName = Tree.get("activeGroup", "name");
    if (!Progeny.activeHasProgeny() && activeGroupName !== undefined) {
      data.groupName = activeGroupName;
    } else {
      data.groupName = "All MS4";
    }
  };

  var getDrainsToBySort = function (dataSort) {
    const drainsToBySort = { rw: "drains_to_rw", ud: "drains_to_c" };
    return drainsToBySort[dataSort];
  };

  var normalizeCatchmentData = function (organizedCatchments, dataSort) {
    organizedCatchments?.forEach((group) => {
      group.drainsTo = group[getDrainsToBySort(dataSort)];
    });

    return organizedCatchments;
  };

  var getTemplateData = function (
    dataSort,
    mapId,
    organizedCatchments,
    allMs4Stats,
    selectedLayer,
  ) {
    const DataViewFunctions = require("../dataViewFunctions");
    var tableDataColumns = DataViewFunctions.getTableDataColumns();
    var tableDataColumnsByLayer = LayerFunctions.getCurrentLayerProperty("tableDataColumns");
    var highlights = getHighlights(selectedLayer);
    var htmlData = {
      [mapId]: true,
      allMs4Stats: allMs4Stats,
      catchmentGroups: organizedCatchments,
      highlights: highlights,
      dataView: Tree.get("dataView"),
      isFlatList: TableDataFunctions.isFlatData(dataSort),
      prop: getDrainsToBySort(dataSort),
      showDataReduction: tableDataColumns.includes("reduction"),
      showDataCount: tableDataColumns.includes("count"),
      showDataImpArea: tableDataColumns.includes("impArea"),
      showDataPluArea: tableDataColumns.includes("pluArea"),
      showDataRate: tableDataColumns.includes("rate"),
      showDataPercentImp: tableDataColumnsByLayer && tableDataColumnsByLayer.includes("percentImp"),
      showDataArea: tableDataColumns.includes("area"),
    };
    return htmlData;
  };

  var getHighlights = function (selectedLayer) {
    var highlightData = MapConstants.highlightData;
    if (selectedLayer in highlightData) {
      return highlightData[selectedLayer];
    } else {
      return {};
    }
  };

  // -----  DOM event handlers  -----

  var listItemMouseover = function (e) {
    var data = this.dataset;
    var layers = TreeUpdates.getCurrentMapLayers();
    if (data.type === "all-ms4") {
      layers.catchmentLayer.highlight(null, true);
    } else {
      layers.catchmentLayer.highlight(data);
    }
  };

  var listItemMouseout = function (e) {
    TreeUpdates.getCurrentMapLayers().catchmentLayer.unhighlight();
  };

  var listItemClick = function (e) {
    urbanCatchmentClick.call(this, e);

    if (e.currentTarget != this) return; // stops propagation, only open list if it was clicked on
    var item = $(e.currentTarget);
    var data = item.data();
    var isAlreadyOpen = false;
    if ($(e.currentTarget.firstChild).hasClass("open") || item.hasClass("open")) {
      isAlreadyOpen = true;
    }

    const targetId = data.id;
    const elementAllSubTabs = $tableContainer.find(`[data-id="${targetId}"]`);

    if (isAlreadyOpen) {
      elementAllSubTabs.children().removeClass("open");
      elementAllSubTabs.removeClass("open");
    } else {
      elementAllSubTabs.children().addClass("open");
      elementAllSubTabs.addClass("open");
    }
  };

  var listItemDblClick = function (e) {
    const data = $(this).data();
    setTimeout(() => {
      CatchmentView.setUrbanCatchmentView(data.catchid, data.viewType, data.rw, data.id);
    }, 400);
  };

  var urbanCatchmentClick = function (e) {
    var element = $(this);
    var catchment = [element.find(".catch-title").text()];
    var catchmentId = element.data("id");
    var receivingWater = element.data().rw;

    resetSelectedTree();
    Tree.set(["selected", "rwThatSelectedDrainsTo"], receivingWater);

    if (element.hasClass("selected")) {
      if (Tree.get("spatialView", mapId) !== "catchmentView") {
        resetTableHighlight();

        if (Tree.get("spatialView", mapId) !== "drainageView") {
          Tree.set(["spatialView", mapId], "MS4View");
          PageFunctions.getCurrentPage().find("li.catch-selected").removeClass("catch-selected");
        }
      }
    } else {
      if (
        Tree.get("spatialView", mapId) === "drainageView" &&
        element.closest(".drains-to-parent-div").hasClass("selected")
      ) {
        setDrainageViewCatchment(catchment, catchmentId);
      } else {
        resetTableHighlight();
        if (catchment[0] === "All MS4" || element.data("type") === "all-ms4") {
          resetSelectedTree();
        } else {
          Tree.set(["selected", "catchment"], catchment[0]);
        }

        Tree.set(["spatialView", mapId], "MS4View");
        PageFunctions.getCurrentPage().find("li.catch-selected").removeClass("catch-selected");
      }
    }

    syncDomWithTree();
  };

  var urbanCatchmentDblClick = function (e) {
    const data = $(this).data();
    if (data["type"] === "all-ms4") {
      urbanCatchmentClick.call(this, e);
      return;
    }
    setTimeout(() => {
      CatchmentView.setUrbanCatchmentView(data.catchid, data.viewType, data.rw, data.id);
    }, 400);
  };

  var setDrainageViewCatchment = function (catchName, catchmentId) {
    Tree.set(["selected", "catchment"], catchName);
    Tree.set(["selected", "urbanDrainage"], []);

    PageFunctions.getCurrentPage().find("li.catch-selected").removeClass("catch-selected");
    PageFunctions.getCurrentPage()
      .find("li[data-id='" + catchmentId + "']")
      .addClass("catch-selected");
  };

  var headerMouseover = function (e) {
    var data = this.dataset;
    var layers = TreeUpdates.getCurrentMapLayers();
    if ($(this).hasClass("master-toggle")) {
      layers.catchmentLayer.highlight(null, true);
    } else {
      layers.catchmentLayer.highlight(data);
    }
  };

  var headerMouseout = function (e) {
    TreeUpdates.getCurrentMapLayers().catchmentLayer.unhighlight();
  };

  var headerClick = function (e) {
    const headerAttributeData = $(e.currentTarget).data();
    headerAttributeData["selectedRW"] = Tree.get("selected", "receivingWater");
    resetSelectedTree();

    if ($(this).hasClass("master-toggle")) {
      headerClickMaster();
    } else {
      headerClickNonMaster(e, headerAttributeData);
    }

    saveExpandedToTree();
  };

  var headerClickNonMaster = function (e, headerAttributeData) {
    if (isGroupExpanded(headerAttributeData.id)) {
      if (headerAttributeData.selectedRW === headerAttributeData.id) {
        if (Tree.get("spatialView", mapId) === "MS4View") {
          closeDeselectHeader(e);
        } else {
          e.stopPropagation();
          const $target = $(e.currentTarget);
          Tree.set(["selected", "catchment"], null);
          Tree.set(["selected", "urbanDrainage"], [$target.data("id")]);
          Tree.set(["selected", "rwThatSelectedDrainsTo"], $target.data("rw"));
          $target
            .closest(".drains-to-parent-div")
            .find(".catch-selected")
            .removeClass("catch-selected");
        }
      } else {
        reselectExpandedHeader(e, headerAttributeData);
      }
    } else {
      expandCollapsedHeader(e, headerAttributeData);
    }

    Tree.set(["table", "masterToggled"], false);
    syncDomWithTree();
  };

  var closeDeselectHeader = function (e) {
    const targetId = e.currentTarget.dataset.id;
    toggleTableExpansion(targetId, false);
  };

  var reselectExpandedHeader = function (e, headerAttributeData) {
    e.stopPropagation(); //prevent data-toggle
    resetTableHighlight();
    Tree.set(["selected", "receivingWater"], headerAttributeData.id);
    if (headerAttributeData.rw)
      Tree.set(["selected", "rwThatSelectedDrainsTo"], headerAttributeData.rw);

    Tree.set(["spatialView", mapId], "MS4View");
  };

  var expandCollapsedHeader = function (e, headerAttributeData) {
    _resetTree();
    Tree.set(["selected", "receivingWater"], headerAttributeData.id);
    if (headerAttributeData.rw)
      Tree.set(["selected", "rwThatSelectedDrainsTo"], headerAttributeData.rw);

    const targetId = e.currentTarget.dataset.id;
    toggleTableExpansion(targetId, true);

    Tree.set(["spatialView", mapId], "MS4View");
  };

  var toggleTableExpansion = function (receivingWaterToExpand, expand) {
    var expanded = new Set(Tree.get("table", "expanded"));
    if (expand) {
      expanded.add(receivingWaterToExpand);
    } else {
      expanded.delete(receivingWaterToExpand);
    }
    Tree.set(["table", "expanded"], [...expanded]);
  };

  var syncDomWithTree = function (forceToggleExpand = null) {
    const expanded = Tree.get("table", "expanded");
    const masterToggled = Tree.get("table", "masterToggled");
    const selectedCatchment = Tree.get("selected", "catchment");
    const descend = Tree.get("filters", "descend");

    $tableContainer.find(".master-toggle .expand-arrow").toggleClass("open", masterToggled);

    const drainsToId = getAllDrainsToIds();
    for (const drainsTo of drainsToId) {
      const isSelected = isGroupSelected(drainsTo);

      if (forceToggleExpand === null) {
        toggleExpansion(drainsTo, masterToggled || expanded.includes(drainsTo), isSelected);
      } else {
        toggleExpansion(drainsTo, forceToggleExpand, isSelected);
      }
    }

    selectCatchment(selectedCatchment);
    setDataSort(descend);
    Filters.syncSearchDomWithTree();
    Filters.syncSpatialFiltersWithTable();
  };

  var toggleExpansion = function (drainsTo, expand, isSelected) {
    var targetsAllSubTabs = $tableContainer.find(`[data-id="${drainsTo}"]`);

    targetsAllSubTabs.find(".expand-arrow").toggleClass("open", expand);
    targetsAllSubTabs
      .closest(".drains-to-parent-div")
      .find(".catch-list")
      .collapse(expand ? "show" : "hide");
    targetsAllSubTabs.closest(".drains-to-parent-div").toggleClass("active", expand);
    targetsAllSubTabs.closest(".drains-to-parent-div").toggleClass("selected", isSelected);
  };

  var selectCatchment = function (catchId) {
    var $catchmentHeaders = $tableContainer
      .find(".catchment-header, .catchments-only")
      .removeClass("selected");
    $catchmentHeaders.find(`[data-catchid="${catchId}"]`).addClass("selected");
  };

  var setDataSort = function (descend) {
    var arrowDown = PageFunctions.getCurrentPage().find(".glyphicon-arrow-down");
    var arrowUp = PageFunctions.getCurrentPage().find(".glyphicon-arrow-up");

    if (descend) {
      arrowDown.hide();
      arrowUp.show();
    } else {
      arrowUp.hide();
      arrowDown.show();
    }
  };

  var getAllDrainsToIds = function () {
    return PageFunctions.getCurrentPage()
      .find(".drains-to-parent-div h3[data-type='drainsTo']")
      .get()
      .map(function (element) {
        return $(element).data("id");
      });
  };

  const highlightDataCol = function (e) {
    const $target = $(e.currentTarget);

    $(".data-col[data-highlight=" + $target.data("highlight") + "]").addClass("highlight");
  };

  const unhighlightDataCol = function (e) {
    $(".data-col.highlight").removeClass("highlight");
  };

  var resetTable = function () {
    _resetTree();
    syncDomWithTree();
  };

  var _resetTree = function () {
    resetSelectedTree();
    resetExpandedInTree();
    resetTableHighlight();
  };

  var resetTableHighlight = function () {
    $table.find(".drains-to-parent-div").removeClass("selected");
    $table.find(".catchments-only h3").removeClass("selected");
    $table.find(".catchment-header li").removeClass("selected");
  };

  var headerClickMaster = function () {
    if (Tree.get("table", "masterToggled")) {
      collapseAll();
    } else {
      expandAll();
    }

    Tree.set(["spatialView", mapId], "MS4View");
    _resetTree();
  };

  var expandAll = function () {
    toggleExpandAll(true);
  };

  var collapseAll = function () {
    toggleExpandAll(false);
  };

  var toggleExpandAll = function (expand) {
    Tree.set(["table", "masterToggled"], expand);
    syncDomWithTree(expand);
  };

  var resetSelectedTree = function () {
    Tree.set(["selected", "receivingWater"], null);
    Tree.set(["selected", "rwThatSelectedDrainsTo"], null);
    Tree.set(["selected", "urbanDrainage"], []);
    Tree.set(["selected", "catchment"], null);
  };

  var handleSearchFilterDisplay = function (spatialView) {
    if (spatialView !== "MS4View") {
      Filters.closeAll();
      $tableContainer.find(".search-button, .filter-button, .sort-button").attr("disabled", true);
    } else {
      $tableContainer
        .find(".search-button, .filter-button, .sort-button")
        .not(".permanentlyDisabled")
        .attr("disabled", false);
    }
  };

  var isGroupExpanded = function (drainsToName) {
    return Tree.get("table", "expanded").includes(drainsToName);
  };

  var isGroupSelected = function (drainsToName) {
    return Tree.get("selected", "receivingWater") === drainsToName;
  };

  var isCatchmentSelected = function (catchId) {
    var selected = Tree.get("selected", "catchment");
    if (selected === undefined) {
      console.error("Selected catchment for tab was unexpectedly undefined.");
    }
    if (typeof selected === "string") {
      return selected === catchId;
    }
  };

  const collapseSideBar = function () {
    $(".floating-inputs-table").addClass("hidden");
    $("#collapsed-side-table").removeClass("hidden");
    $(".summary-table-wrapper").css("left", "56px");
    $(".pie-chart-container").css("margin-right", "150px");
    $(".bar-chart-d3").css("left", "150px");
    $(".map-title").css("left", "65px");
  };

  const expandSideBar = function () {
    $(".floating-inputs-table").removeClass("hidden");
    $("#collapsed-side-table").addClass("hidden");
    $(".summary-table-wrapper").css("left", "300px");
    $(".pie-chart-container").css("margin-right", "0px");
    $(".bar-chart-d3").css("left", "300px");
    $(".map-title").css("left", "320px");
  };

  return {
    render,
    loadListeners,
    loadTable,
    resetTableHighlight,
    handleSearchFilterDisplay,
    resetSelectedTree,
    isGroupExpanded,
    isGroupSelected,
    isCatchmentSelected,
    getTemplateData,
    normalizeCatchmentData,
    expandAll,
    collapseAll,
    resetExpandedInTree,
    resetTable,
    expandSideBar,
  };
};

module.exports = Table();

const Tree = require("../../tree");
const Tooltip = require("../tooltip");
const TableDataFunctions = require("./tableDataFunctions");
const MapConstants = require("./mapConstants");
const Filters = require("./filters");
const CatchmentView = require("./catchmentView");
const Actions = require("../actions");
const PageFunctions = require("../pageFunctions");
const TreeUpdates = require("./treeUpdates");
const Progeny = require("../../login/progeny");
const Ms4Table = require("../ms4Table");
const LayerFunctions = require("./layerFunctions");
