"use strict";

var OutfallsIcon = function () {
  var getOutfallsMarker = function (latlng, rotation, outfallData) {
    const iconRotationOffset = -90;

    return L.marker(latlng, {
      pane: "outfallPane",
      icon: getOutfallsMarkerIcon(outfallData, rotation),
      rotationAngle: rotation + iconRotationOffset,
      rotationOrigin: "center",
    });
  };

  var getOutfallsMarkerIcon = function (data) {
    const zoom = ReportMap.getActiveReportMap()?.getZoom();
    const image = getOutfallsReportIcon(data.condition, data.priority);
    const iconSizesArray = [6, 16, 16, 36, 36, 36, 36, 36];
    const updatedIconSizeOptions = RamMapFunctions.resizeIconsOnZoom(
      zoom,
      false,
      false,
      false,
      false,
      iconSizesArray,
    );
    var icon = L.icon({
      iconSize: updatedIconSizeOptions.size,
      iconUrl: image.url,
      className: image.class,
    });

    return icon;
  };

  var getOutfallsIconClass = function (isPriority) {
    return getOutfallsIcon(isPriority).class;
  };

  var getOutfallsIcon = function (isPriority) {
    const outfallsPriotiryIcon = {
      url: "/ram/images/report/markers/outfalls-priority.svg",
      class: "outfalls-priority-icon",
    };
    const outfallsIcon = { url: "/ram/images/report/markers/outfalls.svg", class: "outfalls-icon" };
    return isPriority ? outfallsPriotiryIcon : outfallsIcon;
  };

  var getOutfallsReportIcon = function (condition, priority) {
    const priorityOutfall = {
      url: "/ram/images/report/markers/outfalls/outfall-priority.svg",
      class: "outfalls-report-priority-icon",
    };
    const priorityOptimalOutfall = {
      url: "/ram/images/report/markers/outfalls/outfall-optimal-priority.svg",
      class: "outfalls-optimal-priority-icon",
    };
    const priorityPoorOutfall = {
      url: "/ram/images/report/markers/outfalls/outfall-poor-priority.svg",
      class: "outfalls-poor-priority-icon",
    };

    const nonPriorityOutfall = {
      url: "/ram/images/report/markers/outfalls/outfall.svg",
      class: "outfalls-report-icon",
    };
    const nonPriorityOptimalOutfall = {
      url: "/ram/images/report/markers/outfalls/outfall-optimal.svg",
      class: "outfalls-optimal-icon",
    };
    const nonPriorityPoorOutfall = {
      url: "/ram/images/report/markers/outfalls/outfall-poor.svg",
      class: "outfalls-poor-icon",
    };

    if (condition === null && priority === false) {
      return nonPriorityOutfall;
    } else if (condition === "optimal" && priority === false) {
      return nonPriorityOptimalOutfall;
    } else if (condition === "poor" && priority === false) {
      return nonPriorityPoorOutfall;
    } else if (condition === null && priority === true) {
      return priorityOutfall;
    } else if (condition === "optimal" && priority === true) {
      return priorityOptimalOutfall;
    } else if (condition === "poor" && priority === true) {
      return priorityPoorOutfall;
    } else {
      return nonPriorityOutfall;
    }
  };

  var getFlowRoutingMarker = function (latlng, rotation, isTerminal) {
    const iconRotationOffset = -90;

    return L.marker(latlng, {
      pane: "flowRoutingPane",
      icon: getFlowRoutingMarkerIcon(isTerminal),
      rotationAngle: rotation + iconRotationOffset,
      rotationOrigin: "center",
    });
  };

  var getFlowRoutingMarkerIcon = function (isTerminal) {
    const image = getFlowRoutingIcon(isTerminal);
    var icon = L.icon({
      iconSize: [18, 12],
      popupAnchor: [0, -12],
      iconUrl: image.url,
      className: image.class,
    });

    return icon;
  };

  var getFlowRoutingIcon = function (isTerminal) {
    const terminalFlowIcon = {
      url: "/ram/images/report/markers/flow-routing-terminal.svg",
      class: "flow-routing-terminal-icon",
    };
    const flowIcon = {
      url: "/ram/images/report/markers/flow-routing.svg",
      class: "flow-routing-icon",
    };
    return isTerminal ? terminalFlowIcon : flowIcon;
  };

  var getIconColor = function (condition, hex = false) {
    if (condition === undefined) {
      condition = null;
    }

    return MapStyles.getOutfallsConditionColor(condition, hex);
  };

  return {
    getFlowRoutingMarker,
    getOutfallsMarker,
    getOutfallsIconClass,
    getOutfallsMarkerIcon,
    getIconColor,
    getOutfallsReportIcon,
  };
};

module.exports = OutfallsIcon();

const RamMapFunctions = require("../../mapFunctions/mapFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const ReportMap = require("../report/reportMap");
