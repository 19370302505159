"use strict";

// check if user is logged in
//  callback will receive either {context, token} or false
async function run(callback) {
  await _loginWithSsoToken();
  isLoggedIn({}, callback);
}

function isLoggedIn(data, callback = () => {}) {
  Auth.validateToken(data, callback);
}

function login(username, password, callback) {
  Auth.getToken(username, password, callback);
}

function logout(callback) {
  Auth.revokeToken(callback);
}

function updatePassword(email, oldPassword, newPassword, callback) {
  var params = {
    email: encodeURIComponent(email),
    password: encodeURIComponent(oldPassword),
    newPassword: encodeURIComponent(newPassword),
  };
  Auth.updatePassword(params, callback);
}

function selectAlias(params, callback) {
  Auth.getAlias(params, function (data) {
    window.location.reload();
  });
}

function warnAndRefresh(message) {
  setTimeout(function () {
    window.location.reload();
  }, 5000);
  MessageModal.showNoticeMessage(message, function () {
    window.location.reload();
  });
}

function ssoLogin() {
  const urlParams = new URLSearchParams(window.location.search);

  if (!jwtSso(urlParams) && !samlSso(urlParams)) {
    UrlRoutes.navigateToHomePage();
    return;
  }
}

var jwtSso = function (urlParams) {
  const redirectUrl = urlParams.get("redirect_url");

  if (!redirectUrl) {
    return false;
  }

  ApiCalls.getJwtSsoToken().then(function (tokenString) {
    LoadingScreen.show();
    window.location = redirectUrl + "?jwt=" + tokenString;
  });

  return true;
};

var samlSso = function (urlParams) {
  const samlRequest = urlParams.get("SAMLRequest");
  const relayState = urlParams.get("RelayState");

  if (!samlRequest || !relayState) {
    return false;
  }

  ApiCalls.getSaml2SsoResponse(samlRequest).then(function (data) {
    LoadingScreen.show();

    if (!data || $.isEmptyObject(data)) {
      UrlRoutes.navigateToHomePage();
      return;
    }

    const $form = $("#samlPost");
    $form.attr("action", data["destination"]);
    $form.find("[name=SAMLResponse]").val(data["samlResponse"]);
    $form.find("[name=RelayState]").val(relayState);
    $form.submit();
  });

  return true;
};

var samlLogin = async function (email) {
  let data;

  try {
    data = await ApiCalls.saml2SsoLogin(email);
  } catch (e) {
    ApiError.assertIs(e);

    // Can't directly return the xhrResponse without rejecting the promise
    return { xhrResponse: e.xhrResponse };
  }

  startSamlLogin(data);

  return null;
};

var startSamlLogin = function (data) {
  const url = new URL(data.destination);
  const query = url.searchParams;
  query.append("SAMLRequest", data.authnRequest);
  query.append("RelayState", Config.get().origin);
  location.href = url;
};

var _loginWithSsoToken = async function () {
  const ssoToken = popSsoLoginToken();

  if (!ssoToken) {
    return;
  }

  try {
    const loginToken = await ApiCalls.ssoTokenLogin(ssoToken);
    TokenManager.saveToken(loginToken);
  } catch (e) {
    // If the user has an SSO token, it should always work...
    Analytics.reportException(e);
  }
};

var popSsoLoginToken = function () {
  const url = new URL(window.location.href);
  const queryString = url.searchParams;
  const ssoLogin = queryString.get("ssoLogin");

  if (!ssoLogin) {
    return null;
  }

  queryString.delete("ssoLogin");
  url.search = queryString.toString();
  window.history.replaceState(window.history.state, "", url.toString());

  return ssoLogin;
};

module.exports = {
  run,
  login,
  logout,
  updatePassword,
  isLoggedIn,
  warnAndRefresh,
  ssoLogin,
  selectAlias,
  samlLogin,
  startSamlLogin,
  _loginWithSsoToken,
};

const ApiCalls = require("../apiCalls");
const LoadingScreen = require("../general/loadingScreen");
const MessageModal = require("../modals/messageModal");
const UrlRoutes = require("../routes/urlRoutes");
const Auth = require("./auth");
const Config = require("../config");
const ApiError = require("../errors/apiError");
const TokenManager = require("./tokenManager");
const Analytics = require("../general/analytics");
