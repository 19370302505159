"use strict";

const ImpactController = function () {
  var render = async function (id = null) {
    const options = {
      breadcrumbs: {
        previousPage: {
          page: "Stormwater Plans",
          route: "/impact-accounting/stormwater-plans",
        },
        get currentPage() {
          return PageController.getData("planName");
        },
      },
      navTabs: {
        asset: id,
        enabledTabs: ["impact", "suitable", "selected"],
        activeTab: "impact",
      },
      title: "Impact",
      preparedDataFunction: _getPreparedData,
      afterRenderFunction: _afterRenderFunction,
      constants: [
        "bmpRelationship",
        "compensationStatus",
        "retrofitOpportunities",
        "siteComplexity",
        "ownershipStatus",
        "propertyTypes",
        "funds",
        "acquisitionPortfolios",
        "programs",
        "districts",
      ],
    };
    await PageController.render(
      "impact",
      "esg/dashboards/impact.njk",
      () => EsgApiCalls.getPlanImpact(id),
      options,
    );
  };

  const _loadListeners = async function () {
    $(".button.change-phase").off().on("click", _showPlanPhaseModal);
    $(".button.recreate-plan").off().on("click", _showRecreateWarningModal);
  };

  const _showPlanPhaseModal = function (e) {
    const id = $(e.target).attr("data-id");
    ChangePhaseModal.render({ ...PageController.getData(), id });
  };

  const _showRecreateWarningModal = async function () {
    MessageModal.showConfirmWarningModal(
      null,
      () => {
        const planId = $(this).data("id");
        window.location.assign(`/impact-accounting/stormwater-plans/${planId}`);
      },
      "Go Back",
      "Continue",
      `<p>Recreating the plan allows you to change your objective weightings, change filter selections, and rerun the benefit analysis. This will unshelve properties and generate a new selection of properties based on specified weightings and filters.</p><p>Click <strong>Go Back</strong> to keep the existing property selection.<br>Click <strong>Continue</strong> to adjust your plan specifications.</p>`,
    );
  };

  const _afterRenderFunction = function () {
    ToolTips.init("impact");
    FilterSummary.disable();
    _renderWidgets();
    _loadListeners();
  };

  const _getPreparedData = function (data) {
    return {
      ...data,
      bmpRelationship: PlanWizardController.getDisplayData(data, "bmpRelationship"),
      compensationStatus: PlanWizardController.getDisplayData(data, "compensationStatus"),
      programs: PlanWizardController.getDisplayData(data, "programs"),
      retrofitOpportunities: PlanWizardController.getDisplayData(data, "retrofitOpportunities"),
      siteComplexity: PlanWizardController.getDisplayData(data, "siteComplexity"),
      ownershipStatus: PlanWizardController.getDisplayData(data, "ownershipStatus"),
      propertyTypes: PlanWizardController.getDisplayData(data, "propertyTypes"),
      acquisitionPortfolios: PlanWizardController.getDisplayData(data, "acquisitionPortfolios"),
      funds: PlanWizardController.getDisplayData(data, "funds"),
      districts: PlanWizardController.getDisplayData(data, "districts"),
      dropClasses: DataRichness.getDropClasses(data.dataRichness),
      hiddenFields: EsgSettings.hiddenFields(),
      logos: GroupLogos.get(),
      canCreatePlan: UserPermissions.getPermission("can_create_plan"),
    };
  };

  const _renderWidgets = function () {
    const $container = $(".ej-widgets");
    const data = PageController.getData();
    const oneMillion = 1000000;

    [
      {
        key: "stormwaterBetterManaged",
        title: "Stormwater Managed",
        unit: "M gal/yr",
        hoverUnit: "M gal/yr",
        color: "#2D85D2",
        isDollars: false,
        inMillions: true,
      },
      {
        key: "stormwaterPollution",
        title: "Stormwater Pollution Mitigated",
        unit: "ton/yr",
        hoverUnit: "ton/yr",
        color: "#D95526",
        isDollars: false,
        inMillions: false,
      },
      {
        key: "capitalCost",
        title: "Capital Cost",
        unit: "M $",
        hoverUnit: "M",
        isDollars: true,
        inMillions: true,
        color: "#5C9C1C",
      },
    ].map((widget) => {
      const totalVal = widget.inMillions ? data[widget.key] / oneMillion : data[widget.key];
      const ejVal = widget.inMillions
        ? data[`${widget.key}Ej`] / oneMillion
        : data[`${widget.key}Ej`];
      const donut = new InsightWidgetTwoDonuts({
        ...widget,
        onePie: true,
        hideExportButton: true,
        legendData: () => {
          return {
            [widget.key]: { label: "", color: widget.color },
          };
        },
        getChartData: () => {
          return [
            {
              key: widget.key,
              hoverKey: widget.key,
              label: "",
              value: ejVal,
              total: totalVal,
            },
          ];
        },
        getHoverData: () => {
          return {
            [widget.key]: {
              template: "insight/widgets/ejReductionHover.njk",
              title: `${widget.title} in EJ Communities`,
              value: ejVal,
              valueLabel: `EJ ${widget.title}`,
              total: totalVal,
              totalLabel: `Total ${widget.title}`,
              unit: widget.hoverUnit,
              valuePrefix: widget.isDollars ? "$" : "",
            },
          };
        },
      });
      WidgetHandler.render($container, donut, data);
    });
  };

  return {
    render,
  };
};

module.exports = ImpactController();

const EsgApiCalls = require("../esgApiCalls");
const PageController = require("../pageController");
const DataRichness = require("../dataRichness");
const ToolTips = require("../toolTips");
const FilterSummary = require("../../filters/filterSummary");
const GroupLogos = require("../groupLogos");
const WidgetHandler = require("../../general/widgets/widgetHandler");
const InsightWidgetTwoDonuts = require("../../general/widgets/insightWidgetTwoDonuts");
const UserPermissions = require("../../login/userPermissions");
const ChangePhaseModal = require("../modals/changePhaseModal");
const PlanWizardController = require("../planWizardController");
const EsgSettings = require("../esgSettings");
const MessageModal = require("../../modals/messageModal");
