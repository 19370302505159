"use strict";

const FacilityEnforcementController = require("./facilityEnforcementController");
const BaseEnforcementHistory = require("../../enforcement/baseEnforcementHistory");

const EnforcementHistory = function () {
  const prepareReasonsFunction = function (historyItem) {
    let reasons = [];
    if (historyItem.enforcementReason === "open-findings") {
      reasons = historyItem.openFindings.map((openFinding) =>
        EnforcementAction.getOpenFindingDisplay(openFinding),
      );
    }
    return reasons;
  };

  const base = BaseEnforcementHistory(FacilityEnforcementController, { prepareReasonsFunction });

  return {
    ...base,
  };
};

module.exports = EnforcementHistory();

const EnforcementAction = require("./enforcementAction");
