"use strict";

const LinkSummaryModal = function () {
  const $modal = $("#link-summary-modal");
  const formKey = "link-summary";
  var inited = false;
  var props;

  var init = function () {
    Form.initializeAndLoadListeners($modal, formKey, { isMultiPart: true });
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.off("click", ".new-file-button", createNewFile);
    $modal.on("click", ".new-file-button", createNewFile);
    $modal.off("click", ".save-file-button", saveFile);
    $modal.on("click", ".save-file-button", saveFile);
    $modal.off("click", ".save-initial-button", saveInitialFile);
    $modal.on("click", ".save-initial-button", saveInitialFile);
    $modal.off("click", ".edit-button", editFile);
    $modal.on("click", ".edit-button", editFile);
    $modal.off("click", ".delete-button", deleteExistingFile);
    $modal.on("click", ".delete-button", deleteExistingFile);
    $modal.off("click", ".delete-editing-button", deleteEditingFile);
    $modal.on("click", ".delete-editing-button", deleteEditingFile);
    $modal.off("click", ".save-link-summary-button", saveAndCloseModal);
    $modal.on("click", ".save-link-summary-button", saveAndCloseModal);
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
      removeInitialInputListener();
    } else {
      ModalFunctions.showConfirmWarningModal(
        `None of the changes you have made will be saved. ` +
          `Click "Go Back" to return to the data entry form. Click "Close Without Saving" to close and lose all changes.`,
        close,
        "Go Back",
        "Close Without Saving",
      );
    }
  };

  var renderAndShow = function (reportProps) {
    if (!inited) init();
    props = Object.assign({}, reportProps);
    props.linkSummaryFiles = addAbsoluteUrlsToFiles(props.linkSummaryFiles || []);

    const html = nunjucks.render("report/modals/annualReport/linkSummaryModal.njk", props);
    $modal.html(html);
    renderLinkSummaryForm();
    $modal.modal("show");
  };

  var renderLinkSummaryForm = function () {
    const html = nunjucks.render("report/modals/annualReport/linkSummaryForm.njk", props);
    $("#link-summary-form-container").html(html);

    if (!props.linkSummaryFiles || props.linkSummaryFiles.length === 0) {
      $modal.find(".new-file-button").hide();
      stageInitialFile();
    }
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
  };

  var stageInitialFile = function () {
    const { html, currentFilesLength, newFile } = createFileHtml(true);
    toggleEditSaveButtonsDisabled(true);
    $modal.find(".link-summary-files").prepend(html);
    $modal.on("input", `[name*="files[${currentFilesLength}]"]`, stageInitialId);
    initializeAdditionalFormFields(newFile);
  };

  var stageInitialId = function () {
    const initialFileIndex = getInitialFileIndex();
    setFormInitialState(initialFileIndex);
    $modal.off("input", `[name*="files[${initialFileIndex}]"]`, stageInitialId);
  };

  var setFormInitialState = function (filesLength = 0) {
    const newFileId = $modal.find(".link-summary-files .file").data("id");
    const $firstFile = $modal.find(".link-summary-files .file").first();
    const uploadDate = $firstFile.find(`[name="files[${filesLength}][upload-date]"]`).val();

    if (!getFileInfoById(newFileId)) {
      Form.manuallySetFormDataField(formKey, ["files", filesLength, "id"], newFileId);
      Form.manuallySetFormDataField(
        formKey,
        ["files", filesLength, "uploadDate"],
        DateTime.truncateMilliseconds(uploadDate),
      );
    }
  };

  var initializeAdditionalFormFields = function (file) {
    initializeDatePicker(file.uploadDate);
  };

  var initializeDatePicker = function (date) {
    Form.initializeDatePickers($modal, formKey);
    if (date) {
      DateTimePicker.setDateOnElement($modal.find("[name*=upload-date]"), date);
    }
  };

  var getFileInfoById = function (fileId) {
    const fileIndex = getFileIndexById(fileId);
    const linkSummaryFiles = getAllLinkSummaryFilesData();
    return fileIndex === undefined ? undefined : linkSummaryFiles[fileIndex];
  };

  var getFileIndexById = function (fileId) {
    const linkSummaryFiles = getAllLinkSummaryFilesData();
    if (linkSummaryFiles) {
      return linkSummaryFiles.findIndex((file) => {
        return fileId === (file || {}).id;
      });
    }
  };

  var createNewFile = function () {
    const { html, currentFilesLength, newFile } = createFileHtml(false);
    Form.manuallySetFormDataField(formKey, ["files", currentFilesLength], newFile);
    toggleEditSaveButtonsDisabled(true);
    $modal.find(".link-summary-files").prepend(html);
    initializeAdditionalFormFields(newFile);
  };

  var createFileHtml = function (initialFile = false) {
    const newId = Date.now();
    const newFile = { id: newId, uploadDate: DateTime.getNowIso(), fileLink: "" };
    const currentFilesLength = getFilesLength();
    return {
      html: nunjucks.render("report/modals/annualReport/linkSummaryNewFile.njk", {
        file: newFile,
        index: currentFilesLength,
        initialFile: initialFile,
      }),
      currentFilesLength: currentFilesLength,
      newFile: newFile,
    };
  };

  var saveInitialFile = function (e) {
    const filesLength = getFilesLength();
    setFormInitialState(filesLength);
    saveFile(e);
  };

  var saveFile = function (e) {
    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    let fileInfo = getFileInfoById(fileId);

    if (Misc.isValidUrl(fileInfo.fileLink)) {
      fileInfo = addAbsoluteUrlsToFiles([fileInfo])[0];
      const html = nunjucks.render("report/modals/annualReport/linkSummaryExistingFile.njk", {
        file: {
          ...fileInfo,
        },
      });
      $file.closest("section").html(html);
      toggleEditSaveButtonsDisabled(false);
      removeInitialInputListener();
    } else {
      showInvalidUrlModal();
    }
  };

  var editFile = function (e) {
    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    editFileById(fileId);
  };

  var editFileById = function (fileId) {
    const fileInfo = getFileInfoById(fileId);
    const html = nunjucks.render("report/modals/annualReport/linkSummaryModifyFile.njk", {
      file: fileInfo,
      index: getFileIndexById(fileInfo.id),
    });
    $(`.file[data-id=${fileId}]`).html(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(fileInfo);
  };

  var toggleEditSaveButtonsDisabled = function (disabled) {
    if (disabled === false) {
      $modal.find(".new-file-button").show();
    }
    $modal.find(".edit-button, .new-file-button").prop("disabled", disabled);
  };

  var removeInitialInputListener = function () {
    const initialFileIndex = getInitialFileIndex();
    $modal.off("input", `[name*="files[${initialFileIndex}]"]`, stageInitialId);
  };

  var getFilesLength = function () {
    const linkSummaryFiles = getAllLinkSummaryFilesData();
    return (linkSummaryFiles || []).length;
  };

  var getInitialFileIndex = function () {
    return Math.max(0, getFilesLength() - 1);
  };

  var getAllLinkSummaryFilesData = function () {
    const formData = Form.getDataFromForm(formKey, false) || {};
    const existingData = { files: props.linkSummaryFiles };
    return $.extend(true, {}, existingData, formData).files || [];
  };

  var saveAndCloseModal = function () {
    var annualReportId = props.annualReportId;
    var linkSummaryUpdates = Form.getDataFromForm(formKey, false);

    if (!validateLinkSummaryFilesUrl(linkSummaryUpdates.files || [])) {
      showInvalidUrlModal();
      return;
    }

    if (linkSummaryUpdates && linkSummaryUpdates.files) {
      cleanFileIds(linkSummaryUpdates);
      ReportApiCalls.updateReportLinkSummary(annualReportId, linkSummaryUpdates, () => {
        close();
        Actions.loadAnnualReporting(SubmitReport.updateSubmitReport);
      });
    } else {
      close();
    }
  };

  var cleanFileIds = function (linkSummaryUpdates) {
    const files = linkSummaryUpdates.files || [];
    const existingFileIds = (props.linkSummaryFiles || []).map((file) => {
      return file.id;
    });

    Misc.appendExistingAttribute(existingFileIds, files, "id");
    Misc.removeAttributeValues(existingFileIds, files, "id");
    linkSummaryUpdates.files = Object.values(files);
  };

  var deleteExistingFile = function (e) {
    const $target = $(e.currentTarget);
    const fileId = $target.closest(".file").data("id");
    const fileInfo = getFileInfoById(fileId);
    const deleteExistingFileCallback = () => {
      deleteFile(fileId);
    };

    showDeleteFileModal(fileInfo.fileTitle, deleteExistingFileCallback);
  };

  var deleteEditingFile = function (e) {
    const $target = $(e.currentTarget);
    const fileId = $target.closest(".file").data("id");
    const fileInfo = getFileInfoById(fileId);
    if (fileInfo) {
      const deleteEditingFileCallback = () => {
        deleteFile(fileId);
        toggleEditSaveButtonsDisabled(false);
      };
      showDeleteFileModal(fileInfo.fileTitle, deleteEditingFileCallback);
    } else {
      removeInitialInputListener();
      $(`[data-id="${fileId}"]`).remove();
      toggleEditSaveButtonsDisabled(false);
    }
  };

  var showDeleteFileModal = function (fileTitle, callback) {
    ModalFunctions.showConfirmWarningModal(
      `You have selected to delete file '${fileTitle}'. To permanently delete this file from your 2NFORM data, click Delete. To keep the record, click Cancel.`,
      callback,
      "Cancel",
      "Delete",
    );
  };

  var showInvalidUrlModal = function () {
    ModalFunctions.showSimpleWarningModal("Please enter a valid URL for file link");
  };

  var deleteFile = function (fileId) {
    const fileIndex = getFileIndexById(fileId);
    const existingFileIds = (props.linkSummaryFiles || []).map((file) => {
      return file.id;
    });

    if (existingFileIds.includes(fileId)) {
      Form.manuallySetFormDataField(formKey, ["files", fileIndex], {
        deleted: true,
        id: fileId,
      });
    } else {
      Form.manuallyUnsetField(formKey, ["files", fileIndex]);
    }
    $(`[data-id="${fileId}"]`).remove();
  };

  var addAbsoluteUrlsToFiles = function (files) {
    return files.map((file) => {
      const makeAbsolute = file.fileLink && !/^(https?:)?\/\/.*$/.test(file.fileLink);
      file.absoluteFileLink = makeAbsolute ? "//" + file.fileLink : file.fileLink;
      return file;
    });
  };

  var validateLinkSummaryFilesUrl = function (files) {
    let valid = true;
    files
      .filter((file) => !file.deleted && file.fileLink !== undefined)
      .forEach((file) => {
        if (!Misc.isValidUrl(file.fileLink)) {
          valid = false;
        }
      });
    return valid;
  };

  return {
    renderAndShow,
    close,
    _closeModalIfConfirmed,
    addAbsoluteUrlsToFiles,
    validateLinkSummaryFilesUrl,
  };
};

module.exports = LinkSummaryModal();

const ReportApiCalls = require("../reportApiCalls");
const Form = require("../../general/form");
const DateTime = require("../dateTime");
const DateTimePicker = require("../general/dateTimePicker");
const ModalFunctions = require("../modals/modalFunctions");
const Actions = require("../actions");
const SubmitReport = require("./submitReport");
const Misc = require("../misc");
