"use strict";

var ScenarioFilter = function () {
  const StructuralBmpFilters = require("../bmpFcsG2/structuralBmpFilters");
  let mainSmartFilters;

  var getProps = async function () {
    const phaseOptions = ToolSettings.getSetting("filterConstants", "phases");
    const monitoringDrainageData = ToolSettings.getSetting(
      "topLevelDataSorts",
      "spatialGrouping",
      "catchments",
      "monitoringDrainages",
    );
    const assessmentAreaData = ToolSettings.getSetting(
      "topLevelDataSorts",
      "spatialGrouping",
      "catchments",
      "assessmentAreas",
    );
    let assesmentAreadata;
    let assesmentAreaOptions;
    if (assessmentAreaData?.length) {
      assesmentAreaOptions = Table.getUnique(assesmentAreadata, "groupingName");
    }
    let monitoringOptions;
    if (monitoringDrainageData?.length) {
      monitoringOptions = Table.getUnique(monitoringDrainageData, "groupingName");
    }
    const subGroupProps = Progeny.getSubGroupFilterProps();

    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, highwayOptions } =
      Filters.getSpatialArrays();

    return {
      isUlar: Session.isUlar(),
      isVentura: Session.isVentura(),
      monitoringOptions,
      assesmentAreaOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      scenarioOptions: [
        { name: "Current", value: "current" },
        { name: "Potential", value: "potential" },
        { name: "No Scenario", value: "null" },
      ],
      scenarioSelected: ["current", "potential"],
      phaseOptions,
      sBMPCategoryOptions: StructuralBmpFilters.categoryOptions,
      sBMPTypes: ToolSettings.getSetting("filterConstants", "bmpTypes"),
      defaultBmpTypes: Tree.get("filters")?.types?.map((type) => parseInt(type)),
      analysisOptions: FilterConstants.analysisOptions,
      receivingWaterOptions,
      catchmentsOptions,
      urbanDrainageOptions,
      highwayOptions,
    };
  };

  var filterResourceDataHandler = function (unfilteredData, filters) {
    const filteredData = ResourceFilters.spatialFilters(unfilteredData, filters);
    return filterOffline(filteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      drainsToSpatialGroupingId: "monitoringName",
      drainsToAssesmentArea: ["assessmentMonitoringName", "array"],
      highwayName: "highwayName",
      drainsToRw: "drainsToRw",
      drainsToC: "drainsToC",
      catchmentName: "catchmentName",
      subGroups: "groupId",
      scenario: "scenario",
      phase: "phase",
      sbmpCategory: StructuralBmpFilters.getPassCategoryFilter("bmpSortOrder"),
      types: "bmpSortOrder",
      analysisReady: "analysisReady",
      otherFilters: (datum, filters) => {
        return ResourceFilters.passSearchStringFilter(datum, filters, ["assetId"]);
      },
    };
  };

  return {
    getProps,
    filterResourceDataHandler,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = ScenarioFilter();
const FilterConstants = require("../filterConstants");
const Progeny = require("../login/progeny");
const Filters = require("../mapFunctions/filters");
const Tree = require("../tree");
const ToolSettings = require("../settings/toolSettings");
const Table = require("../mapFunctions/table");
const Session = require("../login/session");
const ResourceFilters = require("./resourceFilters");
