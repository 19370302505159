"use strict";

const AssetsPopupFunctions = function () {
  var getMode = function () {
    return Tree.get("assetManagementRadioMode");
  };

  var getFormattedDate = function (dateString) {
    const date = new Date(dateString);
    return dateString ? date.toLocaleDateString("en-US") : "—";
  };

  var getAssetAge = function (dateString) {
    if (!dateString) {
      return "—";
    }
    const today = new Date();
    const installDate = new Date(dateString);
    let age = today.getFullYear() - installDate.getFullYear();
    var m = today.getMonth() - installDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < installDate.getDate())) {
      age--;
    }
    return age;
  };

  var getBmpTypeFromId = function (id) {
    const bmpTypeObject = FormConstants.bmpTypes;
    const bmpValues = Object.values(bmpTypeObject);
    return bmpValues.find((value) => bmpTypeObject[id] === value);
  };

  var getPopupHeaderProps = function (props) {
    const mode = getMode();

    switch (mode) {
      case "attribution":
        return getAttributionPopupHeaderProps(props);
      case "pof":
        return getPofPopupHeaderProps(props);
      case "cof":
        return getCofPopupHeaderProps(props);
      case "criticality":
        return getCriticalityPopupHeaderProps(props);
      default:
        return {};
    }
  };

  var getAttributionPopupHeaderProps = function (props) {
    let hexColor = "#DBDBDB";
    let textColorClass = "";

    if (props.attributed === true) {
      hexColor = "#808080";
    } else {
      hexColor = "#DBDBDB";
      textColorClass = "light-text";
    }

    return { hexColor, textColorClass };
  };

  var getPofPopupHeaderProps = function (props) {
    let hexColor = "#DBDBDB";
    let textColorClass = "";

    if (props.pof_final_text === "Unknown") {
      hexColor = "#dbdbdb";
      textColorClass = "light-text";
    } else if (props.pof_final_text === "very-low") {
      hexColor = "#389675";
    } else if (props.pof_final_text === "low") {
      hexColor = "#e0bc67";
    } else if (props.pof_final_text === "moderate") {
      hexColor = "#f98628";
    } else if (props.pof_final_text === "high") {
      hexColor = "#d95945";
    } else if (props.pof_final_text === "very-high") {
      hexColor = "#933ac8";
    }

    return { hexColor, textColorClass };
  };

  var getCofPopupHeaderProps = function (props) {
    let hexColor = "#D9D9D9";
    let textColorClass = "";

    if (props.cof_final_text === "unknown") {
      hexColor = "#D9D9D9";
      textColorClass = "light-text";
    } else if (props.cof_final_text === "very-high") {
      hexColor = "#67000D";
    } else if (props.cof_final_text === "high") {
      hexColor = "#CB181D";
    } else if (props.cof_final_text === "moderate") {
      hexColor = "#FB6A4A";
    } else if (props.cof_final_text === "low") {
      hexColor = "#FCBBA1";
      textColorClass = "light-text";
    } else if (props.cof_final_text === "very-low") {
      hexColor = "#FFE4D1";
      textColorClass = "light-text";
    }

    return { hexColor, textColorClass };
  };

  var getCriticalityPopupHeaderProps = function (props) {
    let hexColor = "#D9D9D9";
    let textColorClass = "";

    if (props.criticality_text === "unknown") {
      hexColor = "#D9D9D9";
      textColorClass = "light-text";
    } else if (props.criticality_text === "very-high") {
      hexColor = "#330031";
    } else if (props.criticality_text === "high") {
      hexColor = "#7A0177";
    } else if (props.criticality_text === "moderate") {
      hexColor = "#D63492";
    } else if (props.criticality_text === "low") {
      hexColor = "#E580BB";
      textColorClass = "light-text";
    } else if (props.criticality_text === "very-low") {
      hexColor = "#ECBAD7";
      textColorClass = "light-text";
    }

    return { hexColor, textColorClass };
  };

  var getDisplayPofText = function (props) {
    let displayPofText;

    if (props.pof_final_text === "Unknown") {
      displayPofText = "Unknown";
    } else if (props.pof_final_text === "very-low") {
      displayPofText = "Very Low";
    } else if (props.pof_final_text === "low") {
      displayPofText = "Low";
    } else if (props.pof_final_text === "moderate") {
      displayPofText = "Moderate";
    } else if (props.pof_final_text === "high") {
      displayPofText = "High";
    } else if (props.pof_final_text === "very-high") {
      displayPofText = "Very High";
    }

    return displayPofText;
  };

  return {
    getMode,
    getBmpTypeFromId,
    getAssetAge,
    getFormattedDate,
    getPopupHeaderProps,
    getDisplayPofText,
  };
};

module.exports = AssetsPopupFunctions();

const FormConstants = require("../mapFunctions/formConstants");
const Tree = require("../tree");
