"use strict";

var MuniCatchBasinProgressDrainageLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "muniCatchBasinProgressDrainage",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (
            mapLayers.muniCatchBasinProgressDrainageLayer &&
            sameSpatialFilter &&
            sameView &&
            sameGroup
          ) {
            map.addLayer(mapLayers.muniCatchBasinProgressDrainageLayer);
          } else {
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.muniCatchBasinProgressDrainageLayer)) {
            map.removeLayer(mapLayers.muniCatchBasinProgressDrainageLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "muniCatchBasinProgressDrainage", "isFetching"], true);
    ReportApiCalls.getMuniCatchBasinProgressDrainage(filters, function (data) {
      Tree.set(["layers", mapId, "muniCatchBasinProgressDrainage", "isFetching"], false);
      Tree.set(["layers", mapId, "muniCatchBasinProgressDrainage", "data"], data);
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(
      Tree,
      mapId,
      "muniCatchBasinProgressDrainage",
      function (data) {
        const geoJsonData = CleanData.cleanGeoJSON(data);
        mapLayers.muniCatchBasinProgressDrainageLayer = createLayerAndAddToMap(
          map,
          mapLayers,
          geoJsonData,
        );
      },
    );
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.muniCatchBasinProgressDrainageLayer) {
      map.removeLayer(mapLayers.muniCatchBasinProgressDrainageLayer);
    }
    mapLayers.muniCatchBasinProgressDrainageLayer = createLayer(data);
    mapLayers.muniCatchBasinProgressDrainageLayer.addTo(map);
    return mapLayers.muniCatchBasinProgressDrainageLayer;
  };

  var createLayer = function (data) {
    return new (Layers.getLayers().MuniCatchBasinProgressDrainageLayer)(data);
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      status: ["filters", "status"],
      ownership: ["filters", "ownership"],
      structureType: ["filters", "structureType"],
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.muniCatchBasinProgressDrainageLayer) &&
        Tree.get("layers", mapId, "muniCatchBasinProgressDrainage", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = MuniCatchBasinProgressDrainageLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Layers = require("../mapFunctions/layers");
