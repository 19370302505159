"use strict";

const Messages = function () {
  let echo;
  let namespace;
  const multiPartMessages = {};

  const init = function () {
    if (echo) return;
    window.Ably = Ably;
    _setNamespace();
    echo = new Echo({
      broadcaster: "ably",
      authEndpoint: Network.getApiUrl("/broadcasting/auth"),
      withoutInterceptors: true,
      auth: { headers: Network.getHeaders() },
    });

    echo.connector.ably.connection.on((stateChange) => {
      if (stateChange.current === "connected") {
        console.log("connected to ably server");
      }
    });
  };

  const subscribe = function ({ channel, event, callback }) {
    init();
    echo
      .private(`${namespace}${channel}`)
      .listen(`.${event}`, (data) => _handleMessage(data, callback));
  };

  const unsubscribe = function (channel) {
    echo?.leave(`${namespace}${channel}`);
  };

  const _setNamespace = function () {
    namespace = ToolSettings.getSetting("ablyChannelPrefix");
  };

  const _handleMessage = function (data, callback) {
    if (!data?.messageGroup) callback(data);

    const { messageGroup, totalParts, index, chunk } = data;

    if (!multiPartMessages[messageGroup]) {
      multiPartMessages[messageGroup] = new Array(totalParts).fill(undefined);
    }

    multiPartMessages[messageGroup][index] = chunk;

    if (multiPartMessages[messageGroup].every((part) => part !== undefined)) {
      const completeMessage = multiPartMessages[messageGroup].join("");
      delete multiPartMessages[messageGroup];
      const parsedMessage = JSON.parse(completeMessage);
      callback(parsedMessage);
    }
  };

  return {
    init,
    subscribe,
    unsubscribe,
    _handleMessage,
  };
};

module.exports = Messages();

const Echo = require("@ably/laravel-echo").default;
const Ably = require("ably");
const Network = require("./network");
const ToolSettings = require("./settings/toolSettings");
