"use strict";

const RegionDropdown = function () {
  var load = function (data) {
    if (data.length == 0) {
      console.error("Missing MS4 data for regional dropdown");
    }
    render(data);
    loadDomListeners();
    loadTreeListeners();
  };

  var render = function (data) {
    const html = nunjucks.render("navbar/regionDropdown.njk", {
      groups: data,
    });
    $("#regional-dropdown-list").html(html);
    setRegionDropdownTitle(Tree.get(["activeGroup", "name"]));
  };

  var loadDomListeners = function () {
    $("#nav-title-container").on("click", "#regional-dropdown-list li", regionalListItemClick);
  };

  var regionalListItemClick = function () {
    const $item = $(this);
    const groupName = $item.text();
    const groupId = $item.data("groupid");
    if (groupId == sessionStorage.activeGroupId) {
      return;
    }
    Tree.set(["activeGroup", "groupId"], groupId);
    Tree.set("mapTitle", groupName);
    sessionStorage.activeGroupId = groupId;
    MainMap.handleSpatialChange(groupId);
  };

  var loadTreeListeners = function () {
    Tree.select(["activeGroup", "groupId"]).on("update", (e) =>
      setRegionDropdownSelection(e.data.currentData),
    );
    Tree.select(["activeGroup", "name"]).on("update", (e) =>
      setRegionDropdownTitle(e.data.currentData),
    );
  };

  var setRegionDropdownSelection = function (newGroupId) {
    const $selection = $("#regional-dropdown-list").find(`[data-groupid=${newGroupId}]`);

    if ($selection.length > 0) {
      Dropdown.select($selection);
    }
  };

  var setRegionDropdownTitle = function (title) {
    $(".city-name").text(title);
  };

  return {
    load,
    setRegionDropdownTitle,
  };
};

module.exports = RegionDropdown();

const MainMap = require("./mainMap");
const Tree = require("../tree");
const Dropdown = require("../general/dropdown");
