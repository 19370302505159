"use strict";

const ModalMap = function () {
  var modalMap;
  var mapLayers;

  var blueDot;
  var currentBasemap;

  var modalMapHandlerConfiguration = {
    modalMapClickHandler: null,
    newExistingMarkerHandler: null,
    dragEndHandler: null,
  };

  var init = function () {
    LeafletExtensions.loadLeafletExtensions();
    $(window).on("resize", invalidateModalMapSize);
    mapLayers = LayerFunctions.getDefaultMapLayers();
    loadTreeListeners();
  };

  var resetModalMapHandlers = function () {
    Object.keys(modalMapHandlerConfiguration).forEach(function (key) {
      modalMapHandlerConfiguration[key] = null;
    });
  };

  var configureModalMapHandlers = function (newConfiguration) {
    Object.keys(modalMapHandlerConfiguration).forEach(function (key) {
      if (newConfiguration && newConfiguration[key] !== undefined) {
        modalMapHandlerConfiguration[key] = newConfiguration[key];
      }
    });
  };

  var initializeModalMap = function () {
    if (!modalMap) {
      loadModalMap();
      resetModalDisplayOptions();
      loadModalMapLayerListeners();
      setModalMapView();
    }
    afterOpenInventory();
    initModalMapLayers();
    startMapListener(modalMap);
  };

  var afterOpenInventory = function () {
    DataViewFunctions.getCurrentDataViewProperty("afterOpenInventory")?.();
    MainMapController.loadLegend("modal");
  };

  var showModalMap = function () {
    initializeModalMap();
    setModalMapView();
    updateModalMapDisplay();
  };

  var updateModalMapDisplay = function () {
    setModalMapBasemap();
    invalidateModalMapSize();
    showBlueDot();
  };

  var invalidateModalMapSize = function () {
    if (modalMap) {
      modalMap.invalidateSize();
      MapFunctions.updateAutoPanPadding();
      BulkSelectTable.setBulkSelectTableHeight();
    }
  };

  var loadModalMap = function () {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const maxZoom = rasterBasemap ? 19 : 21;

    if (mapLayers === undefined) mapLayers = LayerFunctions.getDefaultMapLayers();

    modalMap = L.map("modalMap", {
      editable: true,
      zoomControl: false,
      tileLayer: {
        maxNativeZoom: maxZoom,
      },
      minZoom: 3,
      maxZoom: maxZoom,
    });

    MapPaneFunctions.createCustomMapPanes(modalMap, "modal");
    loadModalMapLegend();
    loadModalMapListeners();
    handleModalMapLayers();
    loadBoundary(modalMap);

    //when location is updated, move blueDot marker
    Tree.select("blueDotLatLng").on("update", function (e) {
      showBlueDot();
    });

    DisplayOptions.resetDisplayOptions();
    MapFunctions.addMapZoomControl(modalMap);
  };

  // @TODO: remove startMapListener, disableNoZoomLayers, end nableNoZoomLayers when switching to vector basemaps
  // note: these functions are duplicated in 3 other places
  var startMapListener = function (reportMap) {
    reportMap.on("zoomend", function () {
      const rasterBasemap = FeatureFlag.enabled("raster-basemap");
      if (rasterBasemap) {
        const zoom = reportMap.getZoom();
        const currentBasemap = BasemapFunctions.getBasemap("modal");

        if (zoom > 16) {
          disableNoZoomLayers();
          if (
            currentBasemap !== "DarkGray" &&
            currentBasemap !== "Gray" &&
            currentBasemap !== "NationalGeographic"
          )
            return;
          Tree.set("basemapBeforeZoomMapModal", currentBasemap);
          BasemapFunctions.setBasemap("Imagery", "modal");
        } else {
          enableNoZoomLayers();
          const basemapBeforeZoom = Tree.get("basemapBeforeZoomMap");
          if (basemapBeforeZoom) {
            BasemapFunctions.setBasemap(basemapBeforeZoom, "modal");
          }
          Tree.set("basemapBeforeZoomMapModal", "");
        }
      }
    });
  };

  var disableNoZoomLayers = function () {
    $("#modalMap").find("[data-style='Gray']").addClass("disabled");
    $("#modalMap").find("[data-style='DarkGray']").addClass("disabled");
    $("#modalMap").find("[data-style='NationalGeographic']").addClass("disabled");
  };

  var enableNoZoomLayers = function () {
    $("#modalMap").find("[data-style='Gray']").removeClass("disabled");
    $("#modalMap").find("[data-style='DarkGray']").removeClass("disabled");
    $("#modalMap").find("[data-style='NationalGeographic']").removeClass("disabled");
  };

  var loadModalMapLegend = function () {
    const legend = new L.Control.ModalMapLegend();
    modalMap.addControl(legend);
    MainMapController.loadLegend("modal");
  };

  var setModalMapView = function () {
    if (modalMap) {
      const zoomLevel = Tree.get("zoomLevel");
      const mapCenter = Tree.get("mapCenter");
      modalMap.setView(mapCenter, zoomLevel);
    }
  };

  var setModalMapBasemap = function (mapStyle) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    if (!mapStyle) {
      mapStyle = BasemapFunctions.getBasemap("modal", true);
    }
    if (modalMap) {
      if (rasterBasemap) {
        currentBasemap = MapFunctions.addBasemap(modalMap, currentBasemap, mapStyle);
      } else {
        currentBasemap = MapFunctions.addVectorBasemap(modalMap, currentBasemap, mapStyle);
      }
    }
  };

  var handleModalMapLayers = function () {
    const catchments = Tree.get(["catchments", "items"]);
    mapLayers.catchmentsLayer = MapFunctions.addModalCatchLayer(
      modalMap,
      mapLayers.catchmentsLayer,
      catchments,
    );

    mapLayers.boundsLayer = createBoundaryLayer(modalMap);
    mapLayers.boundsLayer.addTo(modalMap);
  };

  var initModalMapLayers = function () {
    const defaultEnabledLayers = DataViewFunctions.getCurrentDataViewProperty("defaultModalLayers");

    if (defaultEnabledLayers) {
      MainMapController.enableLayers(defaultEnabledLayers, "modal");
    }
  };

  var loadModalMapListeners = function () {
    if (modalMap) {
      modalMap.off();
      modalMap.on("moveend zoomend", modalMapMoveZoomEnd);
      loadModalMapClickListeners();
    }
  };

  var resetModalMapClickListeners = function () {
    modalMap.off("click");
  };

  var loadModalMapClickListeners = function () {
    resetModalMapClickListeners();
    if (modalMapHandlerConfiguration.modalMapClickHandler !== null) {
      modalMap.on("click", modalMapHandlerConfiguration.modalMapClickHandler);
    }
  };

  var loadTreeListeners = function () {
    BasemapFunctions.addBasemapChangeListener(function (newBasemap) {
      setModalMapBasemap(newBasemap);
    }, "modal");
  };

  var loadModalMapLayerListeners = function () {
    LayerFunctions.loadAllLayerMapListeners(modalMap, mapLayers, "modal");

    MapFunctions.whenLayerToggled("fullDrainageBmp", "modal", function (fullDrainageBmpIsEnabled) {
      if (fullDrainageBmpIsEnabled) {
        if (mapLayers.fullDrainageBmpLayer) {
          MapFunctions.displayFullDrainageLayer(modalMap, mapLayers, "bmp");
        } else {
          Actions.loadFullDrainageBmp("modal");
        }
      } else {
        MapFunctions.removeFullDrainageBmp(modalMap, mapLayers);
      }
    });

    MapFunctions.whenLayerToggled("fullDrainageFcs", "modal", function (fullDrainageFcsIsEnabled) {
      if (fullDrainageFcsIsEnabled) {
        if (mapLayers.fullDrainageFcsLayer) {
          MapFunctions.displayFullDrainageLayer(modalMap, mapLayers, "fcs");
        } else {
          Actions.loadFullDrainageFcs("modal");
        }
      } else {
        MapFunctions.removeFullDrainageFcs(modalMap, mapLayers);
      }
    });

    MapFunctions.whenLayerToggled("stormdrains", "modal", function (stormdrainsIsEnabled) {
      if (stormdrainsIsEnabled) {
        if (mapLayers.stormdrainLayer) {
          StormdrainLayer.handleStormdrainDisplay(modalMap, mapLayers, "modal");
        } else {
          StormdrainLayer.loadStormdrains("modal");
        }
      } else {
        MapFunctions.hideStormdrains(modalMap, mapLayers);
      }
    });

    MapFunctions.whenLayerToggled("streams", "modal", function (isEnabled) {
      if (isEnabled) {
        if (mapLayers.rwLayer) {
          modalMap.addLayer(mapLayers.rwLayer);
        } else {
          Actions.loadWatersheds("modal");
        }
      } else {
        if (mapLayers.rwLayer) {
          modalMap.removeLayer(mapLayers.rwLayer);
        }
      }
    });

    MapFunctions.whenLayerToggled("lidProjectBmp", "modal", function (lidProjectBmpEnabled) {
      if (lidProjectBmpEnabled) {
        if (mapLayers.lidProjectBmpLayer) {
          modalMap.addLayer(mapLayers.lidProjectBmpLayer);
        } else {
          Actions.loadLidProjectBmps("modal");
        }
      } else {
        if (MapFunctions.mapHasLayer(modalMap, mapLayers.lidProjectBmpLayer)) {
          modalMap.removeLayer(mapLayers.lidProjectBmpLayer);
        }
      }
    });

    // whenLayerDataUpdated
    MapFunctions.whenLayerDataUpdated("drainage", "modal", (data) => {
      if (modalMap && data.features.length) {
        if (MapFunctions.mapHasLayer(modalMap, mapLayers.drainageLayer)) {
          modalMap.removeLayer(mapLayers.drainageLayer);
        }
        mapLayers.drainageLayer = MapFunctions.addDrainageLayer(
          modalMap,
          mapLayers.drainageLayer,
          data,
          Session.isRegulator(),
        );
        mapLayers.drainageLayer.addTo(modalMap);
        modalMap.fitBounds(mapLayers.drainageLayer.getBounds());
      }
    });

    MapFunctions.whenLayerDataUpdated("fullDrainageFcs", "modal", function (data) {
      mapLayers.fullDrainageFcsLayer = MapFunctions.addFullDrainageLayer(
        modalMap,
        mapLayers.fullDrainageFcsLayer,
        data,
        "fcs",
      );
      MapFunctions.displayFullDrainageLayer(modalMap, mapLayers, "fcs");
    });

    MapFunctions.whenLayerDataUpdated("fullDrainageBmp", "modal", function (data) {
      mapLayers.fullDrainageBmpLayer = MapFunctions.addFullDrainageLayer(
        modalMap,
        mapLayers.fullDrainageBmpLayer,
        data,
        "bmp",
      );
      MapFunctions.displayFullDrainageLayer(modalMap, mapLayers, "bmp");
    });

    MapFunctions.whenLayerDataUpdated("constructionProjectArea", "modal", function (data) {
      mapLayers.constructionProjectAreaLayer = MapFunctions.addConstructionProjectAreaLayer(
        modalMap,
        mapLayers.constructionProjectAreaLayer,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated("stormdrains", "modal", function (data) {
      StormdrainLayer.addAllStormdrainLayers(modalMap, mapLayers, data, "modal");
    });

    MapFunctions.whenLayerDataUpdated("streams", "modal", function (data) {
      mapLayers.rwLayer = MapFunctions.addStreamLayer(modalMap, mapLayers, data);
    });

    MapFunctions.whenLayerDataUpdated("lidProjectBmp", "modal", function (data) {
      mapLayers.lidProjectBmpLayer = MapFunctions.addBmpLayer(
        modalMap,
        mapLayers.lidProjectBmpLayer,
        data,
        "modal",
      );
    });
  };

  var bounceSingleMarker = function () {
    var marker = getMarker();
    if (marker) {
      //marker.bounce(4);
    }
  };

  var updateSingleMarkerIcon = function (iconObj) {
    const marker = getMarker();
    if (marker) {
      marker.setIcon(iconObj);
    }
  };

  var hideLocationMessage = function () {
    $(".setLocationMessageBox").hide();
    $(".setLocationMessage").hide();
    MapFunctions.updateAutoPanPadding();
  };

  var showLocationMessage = function (text) {
    $(".setLocationMessageBox").show();
    $(".setLocationMessage").show();
    $(".setLocationMessage").text(text);
    MapFunctions.updateAutoPanPadding();
  };

  var showModalMapLegendGroup = function (position = 0) {
    $("#modal-legend-group").show();
    $("#modal-legend-group").css("margin-top", `${position}px`);
  };

  var resetModalDisplayOptions = function () {
    $("#modal-legend-group").css("top", "14px");
    $("#modal-legend-group .layers-body").hide();
    $("#modal-legend-group .expand-arrow").removeClass("open");
    $("#modal-legend-group .legend-button").hide();
    Tree.set("modalLegendShowing", false);
  };

  var setSingleMarker = function (singleMarker) {
    mapLayers.singleMarkerLayer = singleMarker;
    singleMarker.addTo(modalMap);
  };

  function createBoundaryLayer() {
    const boundsLayer = L.geoJson(null, {
      pane: "ms4BoundaryPane",
      interactive: false,
      style: MapStyles.styleCatchmentOutline,
    });
    return boundsLayer;
  }

  function loadBoundary() {
    const groupId = Tree.get(["activeGroup", "groupId"]);
    ApiCalls.getBoundary(groupId, (data) => {
      const geoJson = CleanData.cleanGeoJson(data);
      mapLayers.boundsLayer.addData(geoJson);
      Tree.set(["layers", "bounds", "isFetching"], false);
      Tree.set(["layers", "bounds", "dataModal"], geoJson);
    });
  }

  var modalMapMoveZoomEnd = function () {
    Tree.set("bbox", modalMap.getBounds().toBBoxString());
    const zoomLevel = modalMap.getZoom();

    if (mapLayers.stormdrainLayer) {
      StormdrainLayer.handleStormdrainDisplay(modalMap, mapLayers, "modal");
    }
    MapFunctions.handleRWDisplay(modalMap, mapLayers, "modal");

    if (zoomLevel < 14) {
      const zoomedPastMuni = !MapFunctions.isMuniScaleZoom(zoomLevel);

      if (!zoomedPastMuni && Progeny.activeHasProgeny()) {
        return;
      }
      Misc.toggleDisabledInputs($(".checkbox.disabled-zoomed-out"), zoomedPastMuni);
    }
  };

  var getMarker = function (latLng = null) {
    return getSingleMarker(latLng);
  };

  var getSingleMarker = function (latLng) {
    if (MapFunctions.mapHasLayer(modalMap, mapLayers.singleMarkerLayer)) {
      return mapLayers.singleMarkerLayer;
    } else {
      if (latLng) {
        createNewSingleMarker(latLng);
      } else {
        if (modalMapHandlerConfiguration.newExistingMarkerHandler !== null) {
          modalMapHandlerConfiguration.newExistingMarkerHandler(18);
        } else {
          console.error("modalMapHandlerConfiguration.newExistingMarkerHandler is not set");
        }
      }
      return mapLayers.singleMarkerLayer;
    }
  };

  var setMarkerDragHandlers = function (marker, newMarker) {
    marker.dragging.enable();
    marker.off("dragstart dragend");
    if (modalMapHandlerConfiguration.dragEndHandler !== null) {
      marker.on("dragend", () => {
        modalMapHandlerConfiguration.dragEndHandler(marker.getLatLng(), newMarker);
      });
    }
  };
  var createSingleRotatingOutFallMarker = function (latlng, rotation, isPriority) {
    const iconRotationOffset = -180;

    mapLayers.singleMarkerLayer = L.marker(latlng, {
      pane: "outfallPane",
      icon: OutfallsIcon.getOutfallsMarkerIcon(isPriority),
      rotationAngle: rotation + iconRotationOffset,
      rotationOrigin: "center",
    });
    return mapLayers.singleMarkerLayer.addTo(modalMap);
  };

  var createNewSingleMarker = function (latlng) {
    const zoom = 15;
    mapLayers.singleMarkerLayer = new L.marker(latlng, {
      icon: MapStyles.getNewMapIcon(zoom),
      zIndexOffset: 2000,
    });
    return mapLayers.singleMarkerLayer.addTo(modalMap);
  };

  var showBlueDot = function () {
    const location = Tree.get("blueDotLatLng");
    if (location) {
      if (mapLayers.blueDotLayer !== null) {
        modalMap.removeLayer(mapLayers.blueDotLayer);
      }
      mapLayers.blueDotLayer = L.featureGroup();
      blueDot = L.circleMarker(location, {
        pane: "blueDotPane",
      });
      blueDot.addTo(mapLayers.blueDotLayer);
      mapLayers.blueDotLayer.addTo(modalMap);
    }
  };

  var disableMarkerRelocation = function () {
    //@TODO need to make marker read only
    modalMap.off("click");
    if (
      MapFunctions.mapHasLayer(modalMap, mapLayers.singleMarkerLayer) &&
      mapLayers.singleMarkerLayer.dragging
    ) {
      mapLayers.singleMarkerLayer.dragging.disable();
      mapLayers.singleMarkerLayer.off("dragend");
    }
  };

  var modalMapHasLayer = function (layer) {
    if (modalMap && MapFunctions.mapHasLayer(modalMap, mapLayers[layer])) {
      return true;
    } else {
      return false;
    }
  };

  var getMap = function () {
    return modalMap;
  };

  var getDrainageLayer = function () {
    return mapLayers.drainageLayer;
  };

  var getMapLayers = function () {
    return mapLayers;
  };

  var getMapLayer = function (layer) {
    return mapLayers[layer];
  };

  var addMapLayer = function (layer) {
    modalMap.addLayer(mapLayers[layer]);
  };

  var removeMapLayer = function (layer) {
    modalMap.removeLayer(mapLayers[layer]);
  };

  var resetModalMap = function () {
    resetModalMapHandlers();
    resetModalMapLayers();
    resetModalDisplayOptions();
    resetModalMapClickListeners();
    afterCloseInventory();

    if (modalMap?.ruler) {
      MapFunctions.closeRuler(modalMap.ruler);
    }
  };

  var afterCloseInventory = function () {
    DataViewFunctions.getCurrentDataViewProperty("afterCloseInventory")?.();
  };

  var resetModalMapLayers = function () {
    resetModalMapTreeLayers();
    resetModalMapMapLayers();

    // A bit of a hack. Used to detect when we close the modal map
    MapFunctions.updateAutoPanPadding();
  };

  var resetModalMapTreeLayers = function () {
    var layers = Tree.get("layers");
    for (const layer in layers) {
      if (layers[layer].dataModal) {
        Tree.set(["layers", layer, "dataModal"], []);
      }
      if (layers[layer].isEnabledModal) {
        DisplayOptions.handleLayerDisplay(layer, false, "modal");
      }
    }
  };

  var resetModalMapMapLayers = function () {
    var modalLayersConfig = LayerFunctions.getPropertyForAllLayers("doNotReset");
    for (const layerName in modalLayersConfig) {
      if (!modalLayersConfig[layerName]) {
        const layerKey = LayerFunctions.getLayerKeyByLayerName(layerName);

        if (MapFunctions.mapHasLayer(modalMap, mapLayers[layerKey]) || mapLayers[layerKey]) {
          modalMap.removeLayer(mapLayers[layerKey]);
          mapLayers[layerKey] = null;
        }
      }
    }
  };

  var closePopups = function () {
    if (modalMap) {
      modalMap.closePopup();
    }
  };

  var removeMarkerLayer = function () {
    if (modalMapHasLayer("singleMarkerLayer")) {
      removeMapLayer("singleMarkerLayer");
    }
  };

  var refreshMarkerLayer = function () {
    if (modalMapHasLayer("singleMarkerLayer")) {
      removeMapLayer("singleMarkerLayer");
      getMarker();
    }
  };

  var zoomToMarker = function () {
    const marker = getMarker();
    if (marker) {
      const latlng = [marker.getLatLng()];
      const map = getMap();
      map.fitBounds(L.latLngBounds(latlng));
    }
  };

  var isModalMapVisible = function () {
    const container = getMap()?.getContainer();

    if (!container) {
      return false;
    }

    return $(container).is(":visible");
  };

  return {
    init,
    resetModalMapHandlers,
    configureModalMapHandlers,
    loadModalMapClickListeners,
    showModalMap,
    updateModalMapDisplay,
    showModalMapLegendGroup,
    createNewSingleMarker,
    disableMarkerRelocation,
    hideLocationMessage,
    showLocationMessage,
    setMarkerDragHandlers,
    setSingleMarker,
    bounceSingleMarker,
    modalMapHasLayer,
    getMap,
    getMarker,
    getDrainageLayer,
    getMapLayers,
    getMapLayer,
    addMapLayer,
    removeMapLayer,
    invalidateModalMapSize,
    closePopups,
    removeMarkerLayer,
    refreshMarkerLayer,
    updateSingleMarkerIcon,
    zoomToMarker,
    initializeModalMap,
    isModalMapVisible,
    resetModalMap,
    createSingleRotatingOutFallMarker,
    loadModalMap,
    setModalMapView,
  };
};

module.exports = ModalMap();

const Actions = require("../actions");
const ApiCalls = require("../apiCalls");
const BasemapFunctions = require("./basemapFunctions");
const CleanData = require("./cleanData");
const DataViewFunctions = require("../dataViewFunctions");
const DisplayOptions = require("./displayOptions");
const FeatureFlag = require("../settings/featureFlag");
const LayerFunctions = require("../layerFunctions");
const LeafletExtensions = require("./leafletExtensions");
const MainMapController = require("./mainMapController");
const MapFunctions = require("./mapFunctions");
const MapPaneFunctions = require("./mapPaneFunctions");
const MapStyles = require("./mapStyles");
const Misc = require("../misc");
const OutfallsIcon = require("../outfalls/outfallsIcon");
const Progeny = require("../login/progeny");
const Session = require("../login/session");
const StormdrainLayer = require("../general/stormdrainLayer");
const Tree = require("../tree");
const BulkSelectTable = require("../bulkSelect/bulkSelectTable");
require("./modalMapLegend");
