"use strict";

const ProjectInventoryGeneralInfo = function () {
  const stringKey = "construction-general-info";
  const headerInformation = "General Info";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (dropDownPhase) {
    const project = InventoryModal.getAllData("project-inventory");
    if (typeof dropDownPhase === "string") {
      project.phase = dropDownPhase;
    }

    const props = await renderGeneralInfoHtml(project);
    loadListeners();
    ProjectStageData.updateAllStageDataDatePickers(props.stageData);
    handleRequiredFieldsNavigation(project);
  };

  var handleRequiredFieldsNavigation = function (project) {
    InventoryModal.restoreNavigationButtons();
  };

  var renderGeneralInfoHtml = async function (project) {
    const props = await getProps(project);
    const html = await nunjucks.asyncRender(
      "modals/projectInventory/projectInventoryGeneralInfo.njk",
      props,
    );
    ProjectInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    return props;
  };

  var getProps = async function (project) {
    return {
      dataView: Tree.get("dataView"),
      project: project,
      phaseOptions: ProjectInventoryConstants.phaseOptions,
      ownershipOptions: ProjectInventoryConstants.ownershipOptions,
      landOwnershipOptions: ProjectInventoryConstants.ownershipOptions,
      projectTypeOptions: ToolSettings.getDomainOptions("project_type"),
      developmentTypeOptions: ToolSettings.getDomainOptions("development_type"),
      rejectionReasonOptions: ProjectInventoryConstants.rejectionReasonOptions,
      stageData: ProjectStageData.getRenderStageData(project.stageData),
      enablePostActivePhase: ToolSettings.getSetting("construction", "enable-post-active-phase"),
    };
  };

  var loadListeners = function () {
    unloadListeners();
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
    ProjectStageData.loadStageDataListeners($page);
    $page.on("change", `[name='project-phase']`, handlePhaseChange);
    $page.on("2N:FormInput", `[name="project-start-date"]`, _onActualStartDateChange);
    $page.on("2N:FormInput", `[name="project-end-date"]`, onEndDate);
  };

  var onEndDate = function (e, date) {
    const phase = ProjectInventoryModalController.getLatestConstructionData("phase");
    render(phase);
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="project-start-date"]`, _onActualStartDateChange);

    $page.off("change", `[name='project-phase']`, handlePhaseChange);
  };
  var handlePhaseChange = function (event) {
    const phase = $(event.target).val();
    ProjectInventoryModalController.setFormDataField("phase", phase);
    render(phase);
  };
  var _onActualStartDateChange = function (e, date) {
    const phase = ProjectInventoryModalController.getLatestConstructionData("phase");
    Form.manuallySetDateTime(
      "project-inventory",
      ["projectStartDate"],
      date,
      $page,
      "project-start-date",
    );
    render(phase);
  };

  var cleanUp = function () {
    unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    _onActualStartDateChange,
  };
};

module.exports = ProjectInventoryGeneralInfo();

const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const ProjectStageData = require("./projectStageData");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
