"use strict";

const LidProjectInsightDashboardController = function () {
  var $insightContainer;
  var inited = false;
  var controller = null;

  var reports = [];
  var reportingYears;

  var performanceTierOptions;
  var responsiblePartyMaintenanceOptions;

  var focusYear;
  var comparisonYear;
  var performanceTier;
  var responsiblePartyMaintenance;

  var init = async function (reInitialize = false) {
    if (!inited || reInitialize) {
      await loadReportingYears();

      $insightContainer = $(".insight-container");

      performanceTier = "total";
      responsiblePartyMaintenance = "total";

      performanceTierOptions = [
        { name: "All", value: "total" },
        { name: "No Criteria", value: "?" },
        { name: "Exempt", value: "0" },
        { name: "Site Design", value: "1" },
        { name: "WQ Treatment", value: "2" },
        { name: "Runoff Retention", value: "3" },
        { name: "Peak Management", value: "4" },
      ];

      responsiblePartyMaintenanceOptions = ProjectInventoryConstants.ownershipOptions.slice();
      responsiblePartyMaintenanceOptions.unshift({ name: "All", value: "total" });

      loadDomListeners();

      var widgetLidProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of PCR Projects",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["lidProjectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of PCR Projects in ${focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in total number of completed or certified projects between the comparison and focus years";
        },
      });

      var widgetProjectBmpCount = new InsightWidgetSimpleNumber({
        isHidden: () => !FeatureFlag.enabled("lid-project-insight-bmp-count-widget"),
        size: 1,
        title: "Total # of  Structural BMPs in PCR Projects",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["projectBmpCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of  Structural BMPs in PCR Projects in ${focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in total number of Structural BMPs in PCR Projects between the comparison and focus years";
        },
      });

      var widgetSendLetterProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: FeatureFlag.enabled("pcr-letter")
          ? "Total # of Self-Verification Notices Sent"
          : "Total # of Formal Communications Sent",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["sendLetterProjectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return FeatureFlag.enabled("pcr-letter")
            ? `Total # of Self-Verification Notices Sent in ${focusYear}`
            : `Total # of Formal Communications Sent in ${focusYear}`;
        },
        getHoverDescription: () => {
          return FeatureFlag.enabled("pcr-letter")
            ? "The percent difference in total number of projects that sent self-verification notices between the comparison and focus years"
            : "The percent difference in total number of projects that sent formal communications between the comparison and focus years";
        },
      });

      var widgetReceiveLetterProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Responses Received",
        upGreenDownRed: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["receiveLetterProjectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of Responses Received in ${focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in total number of projects that received responses between the comparison and focus years";
        },
      });

      var widgetUnresponsiveProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Unresponsive Projects",
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["unresponsiveProjectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `Total # of Unresponsive Projects in ${focusYear}`;
        },
        getHoverDescription: () => {
          return "The percent difference in total number of unresponsive projects between the comparison and focus years";
        },
      });

      var widgetLidProjectCountByPerformanceTier = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "# of PCR Projects by Performance Tier",
        excludeZeros: true,
        neutralTrendColor: true,
        typeCountLimit: 4,
        twoLineLabel: true,
        getDataArray: (insightData, dataPath) => {
          const dataArray = insightData[dataPath]["lidProjectCountByPerformanceTier"];
          dataArray.forEach((datum) => {
            datum.key = datum.key.toString();
            const matchedItem = performanceTierOptions.find((option) => option.value === datum.key);
            datum.displayName = matchedItem?.name || datum.value;
          });

          return dataArray;
        },
        getYearByDataPath,
        getSelectedType: getSelectedPerformanceTier,
        getHoverTitle: (insightData, hoverType) => {
          const matchedItem = performanceTierOptions.find((option) => option.value == hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `${focusYear} & ${comparisonYear} <br/>${displayName} <br/>Performance Tier`;
        },
        getHoverDescription: (insightData, hoverType) => {
          const matchedItem = performanceTierOptions.find((item) => item.value === hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `The percent difference in total number of completed or certified projects between the comparison and focus reporting years designated as ${displayName}. Projects can belong to multiple performance tiers, so counts may not align with Total PCR Projects.`;
        },
      });

      var widgetMonthlyReceiveLetterProjectCount = new InsightWidgetMonthlyCountChart({
        size: 4,
        sizeSm: 2,
        title: "Total # of Responses Received by Month",
        upGreenDownRed: true,
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["monthlyReceiveLetterProjectCount"];
        },
        getYearByDataPath,
        getHoverTitle: (hoverIndex) => {
          const monthIndex = hoverIndex + 1;

          return `${focusYear} & ${comparisonYear} Month ${monthIndex} <br/> Total # of Responses Received`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of responses received between the comparison and focus reporting years";
        },
        getNotes: () => {
          const focusReport = reports.find(
            (report) => report.reportingYear === parseInt(focusYear),
          );

          return `*${focusReport.displayDataRangeFirst} is the start of the focus reporting year. ${focusReport.displayDataRangeLast} is the end of the focus reporting year.`;
        },
      });

      var widgetResponsePercentByYear = new InsightWidgetPercentByYearBarChart({
        size: 2,
        title: "Response Rates",
        upGreenDownRed: true,
        getDataArray: (insightData) => {
          return [
            {
              key: focusYear,
              value: insightData["focus"].receiveLetterProjectCount,
              total: insightData["focus"].sendLetterProjectCount,
              displayName: focusYear,
            },
            {
              key: comparisonYear,
              value: insightData["comparison"].receiveLetterProjectCount,
              total: insightData["comparison"].sendLetterProjectCount,
              displayName: comparisonYear,
            },
          ];
        },
        legendData: () => {
          return {
            focusTotal: {
              label: `Sent ${focusYear}`,
              color: "#A4B6C2",
            },
            comparisonTotal: {
              label: `Sent ${comparisonYear}`,
              color: "#CCCCCC",
            },
            focusValue: {
              label: `Received ${focusYear}`,
              color: "#1B4966",
            },
            comparisonValue: {
              label: `Received ${comparisonYear}`,
              color: "#808080",
            },
          };
        },
        getHoverTitle: (hoverIndex) => {
          const year = hoverIndex === 0 ? focusYear : comparisonYear;
          return `${year} Response Rate`;
        },
        getHoverDescription: (hoverIndex) => {
          const year = hoverIndex === 0 ? focusYear : comparisonYear;

          return FeatureFlag.enabled("pcr-letter")
            ? `The percent of self-verification notices that have received a response in the ${year} reporting year`
            : `The percent of formal communications that have received a response in the ${year} reporting year`;
        },
      });

      var widgetAlternativeComplianceProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Alternative Compliance Projects",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["alternativeComplianceProjectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getCustomHoverData: (insightData) => {
          return {
            template: "insight/widgets/simpleNumberListHover.njk",
            title: `Off Site Mitigation Subwatersheds in ${focusYear}`,
            simpleListTableHeader: "Circumstance",
            simpleListTableData: insightData["focus"].alternativeComplianceCircumstanceCount,
          };
        },
      });

      var widgetOffsiteMitigationProjectCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Off Site Mitigation Projects",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["offsiteMitigationProjectCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getCustomHoverData: (insightData) => {
          return {
            template: "insight/widgets/simpleNumberListHover.njk",
            title: `Off Site Mitigation Subwatersheds in ${focusYear}`,
            simpleListTableHeader: "Subwatershed",
            simpleListTableData: insightData["focus"].subwatershedCount,
          };
        },
      });

      var config = {
        getInsightDataFunction: ApiCalls.getLidProjectInsight,
        widgets: [
          widgetLidProjectCount,
          widgetSendLetterProjectCount,
          widgetReceiveLetterProjectCount,
          widgetUnresponsiveProjectCount,
          widgetLidProjectCountByPerformanceTier,
          widgetMonthlyReceiveLetterProjectCount,
          widgetResponsePercentByYear,
          widgetProjectBmpCount,
          widgetAlternativeComplianceProjectCount,
          widgetOffsiteMitigationProjectCount,
        ],
        hasFilter: true,
        getFilterMenuHtmlFunction,
        saveFilterFunction,
        getMobileFilterButtonTextFunction,
      };

      controller = new InsightDashboardController(config);
      controller.init();
      inited = true;
      render();
    }
  };

  var loadDomListeners = function () {
    $insightContainer.off("change", ".year-dropdown", onYearChange);
    $insightContainer.on("change", ".year-dropdown", onYearChange);

    $insightContainer.off("change", `[name="performanceTier"]`, OnPerformanceTierChange);
    $insightContainer.on("change", `[name="performanceTier"]`, OnPerformanceTierChange);

    $insightContainer.off(
      "change",
      `[name="responsiblePartyMaintenance"]`,
      onReponsiblePartyMaintenanceChange,
    );
    $insightContainer.on(
      "change",
      `[name="responsiblePartyMaintenance"]`,
      onReponsiblePartyMaintenanceChange,
    );

    $insightContainer.off("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
    $insightContainer.on("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
  };

  var onYearChange = function () {
    if (this.name === "focus-year") {
      focusYear = this.value;
    } else if (this.name === "comparison-year") {
      comparisonYear = this.value;
    }
    render();
  };

  var OnPerformanceTierChange = function () {
    performanceTier = this.value;
    render();
  };

  var onReponsiblePartyMaintenanceChange = function () {
    responsiblePartyMaintenance = this.value;
    render();
  };

  var onBarGroupClick = function () {
    var $barGroup = $(this);
    var $performanceTierSelect = $insightContainer.find(`select[name="performanceTier"]`);

    if ($barGroup.hasClass("selected")) {
      $performanceTierSelect.val("total").change();
    } else {
      const performanceTier = $barGroup.data("type");
      if (performanceTier) {
        $performanceTierSelect.val(performanceTier).change();
      }
    }
  };

  var render = function () {
    if (Tree.get("dataView") !== "lid-project") {
      return;
    }

    controller.render({
      focusYear,
      comparisonYear,
      performanceTier,
      responsiblePartyMaintenance,
    });
  };

  var loadReportingYears = async function () {
    reports = await ApiCalls.getAnnualReports("lid");
    reportingYears = reports
      .map((report) => report.reportingYear)
      .sort()
      .reverse();
    focusYear = reportingYears[0];
    comparisonYear = reportingYears[1] || reportingYears[0];
  };

  var getFilterMenuHtmlFunction = function () {
    var yearOptions = reportingYears.map((year) => {
      return { name: year, value: year };
    });

    return nunjucks.render("insight/lidProjectInsightDashboardFilters.njk", {
      performanceTierOptions,
      performanceTier,
      responsiblePartyMaintenanceOptions,
      responsiblePartyMaintenance,
      yearOptions,
      focusYear,
      comparisonYear,
    });
  };

  var getMobileFilterButtonTextFunction = function () {
    const displayName = performanceTierOptions.getObjectWith("value", performanceTier)?.name;
    return `${focusYear} & ${comparisonYear}, ${displayName}`;
  };

  var saveFilterFunction = function (formData) {
    if (formData["focusYear"]) {
      focusYear = parseInt(formData["focusYear"]);
      $insightContainer.find(`select[name="focus-year"]`).val(focusYear);
    }
    if (formData["comparisonYear"]) {
      comparisonYear = parseInt(formData["comparisonYear"]);
      $insightContainer.find(`select[name="comparison-year"]`).val(comparisonYear);
    }
    if (formData["performanceTier"]) {
      performanceTier = formData["performanceTier"];
      $insightContainer.find(`select[name="performanceTier"]`).val(performanceTier);
    }
    if (formData["responsiblePartyMaintenance"]) {
      responsiblePartyMaintenance = formData["responsiblePartyMaintenance"];
      $insightContainer
        .find(`select[name="responsiblePartyMaintenance"]`)
        .val(responsiblePartyMaintenance);
    }

    render();
  };

  var getYearByDataPath = function (dataPath) {
    return dataPath === "focus" ? focusYear : comparisonYear;
  };

  var getSelectedPerformanceTier = function () {
    return performanceTier;
  };

  return {
    init,
  };
};

module.exports = LidProjectInsightDashboardController();

const ApiCalls = require("../apiCalls");
const InsightDashboardController = require("../general/widgets/insightDashboardController");
const InsightWidgetSimpleNumber = require("../general/widgets/insightWidgetSimpleNumber");
const InsightWidgetMonthlyCountChart = require("../general/widgets/insightWidgetMonthlyCountChart");
const InsightWidgetCountByTypeChart = require("../general/widgets/insightWidgetCountByTypeChart");
const InsightWidgetPercentByYearBarChart = require("../general/widgets/insightWidgetPercentByYearBarChart");
const ProjectInventoryConstants = require("../construction/projectInventoryConstants");
const Tree = require("../tree");
const FeatureFlag = require("../settings/featureFlag");
