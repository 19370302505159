"use strict";

var TrashConditionEsriDynamicMapLayer = function () {
  const esriKey = "trash";
  const esriLayer = "condition";
  const layerName = "trashConditionEsriDynamicMap";
  const layerKey = "trashConditionEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var layer = new EsriDynamicMapLayer(layerName, layerKey, esriKey, esriLayer);
    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = TrashConditionEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
