"use strict";

const PageFunctions = function () {
  var goToPage = function (pageName) {
    const tabs = PageConfig["tabs"];
    const navPages = PageConfig["navToggle"];

    if (tabs[pageName]) {
      Panels.handleDisplayTab(pageName);
    }

    if (navPages[pageName]) {
      NavToggle.showPanel(pageName);
    }

    if (!tabs[pageName] && !navPages[pageName]) {
      throw new Error(`Unknown page ${pageName}.`);
    }
  };

  var goToDefaultPage = function () {
    const defaultPage = DataViewFunctions.getCurrentDataViewProperty("defaultTab");
    goToPage(defaultPage);
  };

  var pageIsEnabled = function (pageName) {
    const enabledPages = DataViewFunctions.getUserEnabledTabs();

    if (enabledPages) {
      return enabledPages.includes(pageName);
    }

    return false;
  };

  var getPageConfig = function (pageName) {
    for (const type in PageConfig) {
      const config = PageConfig[type];
      const pageConfig = config[pageName];
      if (pageConfig) {
        return pageConfig;
      }
    }

    throw new Error(`No configuration for page ${pageName}.`);
  };

  var getPageTypeConfigs = function (pageType) {
    const typeConfig = PageConfig[pageType];

    if (!typeConfig) {
      throw new Error(`No configuration for page type ${pageType}.`);
    }

    return typeConfig;
  };

  var getTabProperty = function (activeTab, property) {
    return PageConfig.tabs[activeTab]?.[property];
  };

  var getNavProperty = function (navToggleSelection, property) {
    return PageConfig.navToggle[navToggleSelection]?.[property];
  };

  return {
    goToPage,
    getPageTypeConfigs,
    getPageConfig,
    pageIsEnabled,
    goToDefaultPage,
    getTabProperty,
    getNavProperty,
  };
};

module.exports = PageFunctions();

const PageConfig = require("./config/pageConfig");
const NavToggle = require("./mapFunctions/navToggle");
const Panels = require("./panels");
const DataViewFunctions = require("./dataViewFunctions");
