"use strict";

const ProjectDeliveryFilters = function () {
  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          ProjectDeliveryBmpsGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData, filters) {
    return unfilteredData.filter((datum) => {
      return passPhaseFilter(datum, filters) && passTypesFilter(datum, filters);
    });
  };

  var onlineFilterIsSet = function (filters) {
    return ResourceFilters.spatialFiltersAreSet(filters);
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.phase.length > 0) {
      queryStrings.push(GeoServerFilterFunctions.getInArrayQuery("bmp_phase", filters.phase));
    }

    if (filters.types?.length !== undefined) {
      queryStrings.push(GeoServerFilterFunctions.getInArrayQuery("bmp_type_num", filters.types));
    }
  };

  var passPhaseFilter = function (datum, filters) {
    var pass = true;
    var phase = datum.bmp_phase;

    if (filters.phase?.length) {
      pass = filters.phase.includes(phase);
    }

    return pass;
  };

  var passTypesFilter = function (datum, filters) {
    if (filters.types?.length !== undefined) {
      return filters.types.some((type) => parseInt(type) === datum.bmp_type_num);
    }
    return true;
  };

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
  };
};

module.exports = ProjectDeliveryFilters();

const ApiError = require("../errors/apiError");

const ResourceFilters = require("../filters/resourceFilters");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const ProjectDeliveryBmpsGeoServerLayer = require("./projectDeliveryBmpsGeoServerLayer");
