"use strict";

const PostConstructionProjectAreaG2Layer = function () {
  const layerName = "postConstructionProjectAreaG2";
  const layerKey = "postConstructionProjectAreaG2Layer";

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );

    return data;
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const layer = new GeoServerMapLayer(layerName, layerKey);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, layer);

    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
  };
};

module.exports = PostConstructionProjectAreaG2Layer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
