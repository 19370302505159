"use strict";

var FcsProgressPopup = function () {
  var fcsAssessmentHistory = null;

  var loadDomListeners = function () {
    $(".map-content").off("click", ".popup-history-button");
    $(".map-content").on("click", ".popup-history-button", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
  };

  var getFcsProgressPopupHtml = function (fcs) {
    var popupProps = getFcsProgressPopupProps(fcs);
    var props = $.extend({}, fcs, popupProps);
    fcsAssessmentHistory = props.fcsAssessmentHistory;
    return nunjucks.render("report/popups/fcsProgressPopup.njk", props);
  };

  var getFcsProgressPopupProps = function (fcs) {
    return {
      popupColor: BmpFcsIcon.getIconColor(fcs.fcsScore, fcs.planned),
      date: fcs.lastCompleteFcsAssessment?.assessmentDate ?? null,
    };
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();

    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  var popupHistoryButtonClick = function () {
    var props = getInspectionHistoryProps();
    $(".popup-fcs-history").html(
      nunjucks.render("report/popups/bmpFcsAssessmentHistory.njk", props),
    );
    $(".popup-fcs-main").hide();
    $(".popup-history-button").hide();
    $(".popup-fcs-history").show();
    $(".popup-back-button").show();
  };

  var getInspectionHistoryProps = function () {
    const props = {};
    props.assessments = fcsAssessmentHistory;
    return props;
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-fcs-history").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-history-button").show();
    $parent.find(".popup-fcs-main").show();
  };

  return {
    getFcsProgressPopupHtml,
    getPopupOptions,
    loadDomListeners,
  };
};

module.exports = FcsProgressPopup();

const BmpFcsIcon = require("./bmpFcsIcon");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
