"use strict";

const ActivityInventoryModalController = function () {
  const activityInventoryFormKey = "activity-inventory";
  let isDuplicate;

  function loadFormListener() {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput", onFormInput);
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  }

  function getActivityInventoryData() {
    return InventoryModal.getAllData(activityInventoryFormKey);
  }

  function onFormInput(e, value, path) {
    unsetExistingFields(value, path);
    checkInventorySaveable();
    setModalTitle();
  }

  function checkInventorySaveable() {
    const activityName = getLatestActivityInventoryData("activityName");

    if (activityName === "" || !activityName) {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    } else {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }

    if (noUpdatesInForm()) {
      InventoryModal.readySaveState(false);
    } else {
      InventoryModal.readySaveState(true);
    }
  }

  function unsetExistingFields(value, path) {
    const existingValue = getExistingData(path);

    if (existingValue !== undefined && value === existingValue) {
      Form.manuallyUnsetField(activityInventoryFormKey, path);
    }
  }

  function getExistingData(path) {
    const pathToCheck = $.isArray(path) ? path : [path];
    return Tree.get(["asset", activityInventoryFormKey, ...pathToCheck]);
  }

  function getLatestActivityInventoryData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(activityInventoryFormKey, path);
  }

  function setFormDataField(path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(activityInventoryFormKey, path, value);
  }

  function unsetFormDataField(path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(activityInventoryFormKey, path);
    return Form.getDataFromForm(activityInventoryFormKey, false);
  }

  function noUpdatesInForm() {
    if (isDuplicate) {
      return false;
    }

    return Object.keys(Form.getDataFromForm(activityInventoryFormKey, false)).length === 0;
  }

  function getActivityInventoryPages() {
    const basePages = [
      ActivityInventoryInfo,
      ActivityPostActivitySummary,
      ActivityInventoryContacts,
      ActivityInventoryFiles,
    ];
    return basePages;
  }

  function resetAllPages() {
    const allPages = getActivityInventoryPages();
    allPages.forEach((page) => {
      if (page.resetState) {
        page.resetState();
      }
    });
  }

  async function getModalData(maybeId) {
    isDuplicate = false;

    InventoryModal.setOpenInventory(activityInventoryFormKey);
    let data;

    if (maybeId) {
      data = await ApiCalls.getActivity(maybeId);
    } else {
      data = getDefaultActivityValues();
    }

    return data;
  }

  async function invokeActivityInventoryModal(maybeId, options, startingPage) {
    const data = await getModalData(maybeId);

    return initializeActivityInventoryModalPages(data, startingPage, options);
  }

  async function copyActivityInventoryModal(id) {
    let data = await getModalData(id);
    data = _duplicateModalData(data);

    return initializeActivityInventoryModalPages(data);
  }

  function _duplicateModalData(data) {
    isDuplicate = true;

    delete data.id;
    delete data.startDate;
    delete data.endDate;
    delete data.activityFiles;
    delete data.photos;
    deleteEmptyData(data);

    data.activityName = `Copy of ${data.activityName}`;

    return data;
  }

  var deleteEmptyData = function (data) {
    for (const key in data) {
      const datum = data[key];
      if (datum === null || datum === "" || datum?.length === 0) {
        delete data[key];
      }
    }
  };

  var getDefaultActivityValues = function () {
    return {
      audienceType: "public-engagement",
      submitterName: Tree.get(["user", "username"]),
    };
  };

  async function initializeActivityInventoryModalPages(
    processedData,
    startingPage = null,
    options = {},
  ) {
    Form.initializeAndLoadListeners($("#inventory-modal"), activityInventoryFormKey, {
      isMultiPart: true,
    });
    saveData(processedData);
    const inputPages = getActivityInventoryPages();
    resetAllPages();
    await InventoryModal.showModal(inputPages, startingPage, options, {
      saveAndCloseHandler: saveAndCloseModal,
      cancelHandler: closeInventory,
      formHasUpdatesHandler: () => !noUpdatesInForm(),
    });

    InventoryModal.resetModalTitle();
    InventoryModal.setHideInventoryOnClose(!options?.modalInModal);
    checkInventorySaveable();
    setModalTitle();
    loadFormListener();
  }

  function saveData(processedData) {
    if (isDuplicate) {
      setFormDataField([], processedData);
      Tree.set(["asset", activityInventoryFormKey], {});
    } else {
      Tree.set(["asset", activityInventoryFormKey], processedData);
    }
  }

  async function saveAndCloseModal() {
    if (!(await _confirmDuplicateSave())) {
      return false;
    }

    try {
      await saveModal();
      closeModal();
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  }

  async function _confirmDuplicateSave() {
    if (!isDuplicate) {
      return true;
    }

    try {
      const newActivityName = getLatestActivityInventoryData("activityName");
      const html = nunjucks.render(`modals/peo/confirmSaveDuplicate.njk`, { newActivityName });
      await MessageModal.showConfirmWarningModalAsPromise(html, {
        returnMessage: "Go Back",
        okMessage: "Save",
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async function saveModal() {
    await Form.getReadyToSavePromise(activityInventoryFormKey);
    const activityToUpload = _processActivityFormData(
      Form.getDataFromForm(activityInventoryFormKey),
    );
    if (getExistingData("id") !== undefined) {
      const id = getExistingData("id");
      const dataToUpload = Form.toFormData({
        activityId: id,
        activityUpdates: activityToUpload,
      });
      await ApiCalls.updateActivity(dataToUpload);
    } else {
      const newData = $.extend(true, {}, getExistingData([]), activityToUpload);
      const dataToUpload = Form.toFormData({
        activityData: newData,
      });
      await ApiCalls.createActivity(dataToUpload);
    }
  }

  function _processActivityFormData(activityToUpload) {
    if (activityToUpload.contacts !== undefined) {
      const existingContacts = getExistingData("contacts") ?? [];
      activityToUpload.contacts = CommonModalFunctions.cleanIds(
        activityToUpload.contacts,
        existingContacts,
      );
    }
    if (activityToUpload.activityFiles !== undefined) {
      const existingFiles = getExistingData("activityFiles") ?? [];
      activityToUpload.activityFiles = CommonModalFunctions.cleanIds(
        activityToUpload.activityFiles,
        existingFiles,
      );
      CommonModalFunctions.removeUploadOrLocation(activityToUpload.activityFiles);
    }
    return activityToUpload;
  }

  function closeInventory() {
    resetInventory();
  }

  function resetInventory() {
    Form.clearForm(activityInventoryFormKey);
  }

  function renderPageContent(html) {
    InventoryModal.setModalDynamicContent(html);
  }

  function closeModal() {
    InventoryModal.hide();
    ActivitiesLayer.invalidateLayerData();
  }

  function setModalTitle() {
    setActivityName();
    setActivityType();
  }

  function setActivityName() {
    const activityName = getLatestActivityInventoryData("activityName");
    if (activityName === undefined || activityName === "") {
      InventoryModal.setModalTitle("Add New Activity");
    } else {
      InventoryModal.setModalTitle("Activity Name: ", activityName);
    }
  }

  function setActivityType() {
    const activity = getLatestActivityInventoryData("activity");
    const displayName = ActivityConstants.get().activities[activity]?.displayName ?? "—";

    InventoryModal.setModalTitle("Activity: ", displayName, "activityType");
  }

  function getActivityInventoryFormKey() {
    return activityInventoryFormKey;
  }

  return {
    _processActivityFormData,
    invokeActivityInventoryModal,
    copyActivityInventoryModal,
    setFormDataField,
    renderPageContent,
    unsetFormDataField,
    getExistingData,
    closeModal,
    getActivityInventoryData,
    getLatestActivityInventoryData,
    getActivityInventoryFormKey,
    setActivityName,
    setModalTitle,
    _duplicateModalData,
    _confirmDuplicateSave,
  };
};

module.exports = ActivityInventoryModalController();

const ApiCalls = require("../apiCalls");
const InventoryModal = require("../general/inventoryModal");
const ActivitiesLayer = require("./activitiesLayer");
const Tree = require("../tree");
const Form = require("../general/form");
const ActivityInventoryInfo = require("./activityInventoryInfo");
const ActivityPostActivitySummary = require("./activityPostActivitySummary");
const ActivityInventoryContacts = require("./activityInventoryContacts");
const ActivityInventoryFiles = require("./activityInventoryFiles");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const UploadValidator = require("../general/uploadValidator");
const ActivityConstants = require("./activityConstants");
const MessageModal = require("../modals/messageModal");
