"use strict";

const RoadOmModalController = function () {
  const htmlPath = "modals/muni/roads";
  const formKey = "road-om";
  let $modal;
  let initialDefaults;
  let spatialData;

  const renderAndShow = function () {
    const readOnly = false;

    setNormalizedSpatialData();
    initialDefaults = getinitialDefaults();
    $modal = setupModal(readOnly);
    Form.initializeAndLoadListeners($modal, formKey);
    loadListeners();
    disableSaveWhenEmptyRequiredFields();
  };

  const getinitialDefaults = function () {
    return {
      assessmentDate: DateTime.getNowIso(),
      spatialType: spatialData.name,
    };
  };

  const setupModal = function (readOnly) {
    const $modal = showModal(readOnly);

    TabbedModal.setSaveHandler(_saveAndCloseModal);
    TabbedModal.setCancelHandler(unloadListeners);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);

    TabbedModal.setHeaderText(nunjucks.render("modals/muni/roads/roadOmHeader.njk", {}));

    return $modal;
  };

  const showModal = function (readOnly) {
    const tabs = [require("./roadOmPage")];
    const renderData = mapTabProps(tabs);
    return TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, { readOnly });
  };

  const mapTabProps = function (tabs) {
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(initialDefaults));
    }, {});
    return {
      ...initialDefaults,
      ...tabProps,
    };
  };

  const formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  const _saveAndCloseModal = async function () {
    await Form.getReadyToSavePromise(formKey);
    const data = getUpdateData();

    ApiCalls.saveRoadCondition(data);
    unloadListeners();
    TabbedModal.hide();
  };

  const getUpdateData = function () {
    const updates = Form.getDataFromForm(formKey, false);
    return $.extend(true, initialDefaults, updates);
  };

  const manuallySetFormDataField = function (path, value) {
    Form.manuallySetFormDataField(formKey, path, value);
  };

  const loadListeners = function () {
    $modal.on("2N:FormInput", disableSaveWhenEmptyRequiredFields);
  };

  const unloadListeners = function () {
    $modal.on("2N:FormInput", disableSaveWhenEmptyRequiredFields);
  };

  const disableSaveWhenEmptyRequiredFields = function () {
    const data = getUpdateData();
    const requiredFields = getRequiredFields();
    const hasMissingFields =
      FormValidator.getEmptyRequiredFieldsInData(data, requiredFields).length > 0;
    const hasSpatialConditions = allSpatialConditionsAreComplete(data);
    TabbedModal.disableSaveButton(hasMissingFields || !hasSpatialConditions);
  };

  const getRequiredFields = function () {
    return [
      {
        fieldName: "assessmentDate",
      },
    ];
  };

  const allSpatialConditionsAreComplete = function (data) {
    const spatialCondition = getCleanSpatialConditions(data);

    if (spatialCondition.length === 0) {
      return false;
    }

    for (const condition of spatialCondition) {
      if (!hasRequiredSpatialConditions(condition)) {
        return false;
      }
    }

    return true;
  };

  const getCleanSpatialConditions = function (data) {
    return (data.spatialCondition ?? []).filter((d) => !!d);
  };

  const hasRequiredSpatialConditions = function (condition) {
    for (const requiredField of ["road_accessible", "sweeper_type", "sweeper_frequency", "pci"]) {
      if (!condition[requiredField]) {
        return false;
      }
    }

    return true;
  };

  const getNormalizedSpatialData = function () {
    return spatialData;
  };

  const setNormalizedSpatialData = function () {
    spatialData =
      normalizeDataSortData("catchments", "Catchment", "catchid", function (data) {
        return { value: data.id, name: data.catchid };
      }) ??
      normalizeDataSortData("sweepingNetworks", "Sweeping Route", "route_name", function (data) {
        const routeName = Session.isNewDataDrivenRoadConditionGroup()
          ? data.route_name
          : data.network_name;
        return { value: routeName, name: routeName };
      });

    if (!spatialData) {
      throw new Error(`No spatial data`);
    }
  };

  const normalizeDataSortData = function (name, displayName, sortKey, dataOptionsCallback) {
    const dataSort = DataSortFunctions.getCurrentDataSortData(name);

    if (dataSort.length === 0) {
      return null;
    }

    if (sortKey) {
      dataSort.sort((a, b) => ("" + a[sortKey]).localeCompare(b[sortKey]));
    }

    return {
      displayName: displayName,
      name: name,
      dataOptions: dataSort.map(dataOptionsCallback),
    };
  };

  return {
    renderAndShow,
    getUpdateData,
    manuallySetFormDataField,
    getNormalizedSpatialData,
    _saveAndCloseModal,
  };
};

module.exports = RoadOmModalController();

const TabbedModal = require("../../general/tabbedModal");
const Form = require("../../general/form");
const ApiCalls = require("../../apiCalls");
const DateTime = require("../../dateTime");
const FormValidator = require("../../general/formValidator");
const DataSortFunctions = require("../../dataSortFunctions");
const Session = require("../../login/session");
