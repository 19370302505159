"use strict";

var AnnualReports = function () {
  var setRegionalYears = function (data) {
    const allMs4s = _organizeByGroupId(data);
    if (Tree.get("allMs4s")) {
      Tree.merge("allMs4s", allMs4s);
    } else {
      Tree.set("allMs4s", allMs4s);
    }

    const allYears = _getAllRegionalYears(data);
    _updateYears(allYears, Progeny.rootGroupIsRegulator());
  };

  var _organizeByGroupId = function (data) {
    var dataByGroup = {};
    for (const group of data) {
      dataByGroup[group.groupId] = group;
    }
    return dataByGroup;
  };

  var setMs4Years = function (data) {
    const groupId = Tree.get("activeGroup", "groupId");
    Tree.set(["allMs4s", groupId, "reports"], data);

    const allYears = _getUniqueYearsInDescendingOrder(data);
    _updateYears(allYears);
  };

  var updateMs4Years = function (data) {
    var allMs4s = Tree.get("allMs4s");

    for (const newReport of data) {
      var oldReports = allMs4s[newReport.groupId].reports;
      var reportIndex = oldReports.findIndex(function (oldReport) {
        return oldReport.id === newReport.id;
      });

      oldReports[reportIndex] = newReport;
    }

    Tree.set("allMs4s", allMs4s);
  };

  var _updateYears = function (allYears, isRegional = false) {
    Tree.set("annualReportingLoaded", true);

    YearDropdown.populateYearDropdown(allYears);

    var defaultSelectedYear = SubmitReport.getDefaultSelectedYear(allYears, isRegional);
    _updateYearDropdownSelection(defaultSelectedYear);

    DataViewDropdown.setupDataViewDropdown();
  };

  var _updateYearDropdownSelection = function (defaultYear) {
    var selection = Tree.get("yearDropdownSelection");

    if (selection === null) {
      selection = defaultYear;
    }

    Tree.set(["yearDropdownSelection"], selection);
  };

  var _getAllRegionalYears = function (allMs4s) {
    var reports = [];

    for (const ms4 of allMs4s) {
      reports = reports.concat(ms4.reports);
    }

    return _getUniqueYearsInDescendingOrder(reports);
  };

  var getAllMs4s = function () {
    return Tree.get("allMs4s");
  };

  var getReports = function () {
    var groupId = Tree.get("activeGroup", "groupId");
    return Tree.get(["allMs4s", groupId, "reports"]);
  };

  var getAllMs4sReports = function (dataName = null) {
    var ms4Reports = [];
    var allMs4s = getAllMs4s();
    for (const groupId in allMs4s) {
      ms4Reports = ms4Reports.concat(allMs4s[groupId].reports);
    }

    if (!ms4Reports) {
      throw new Error(`No report data found for all Ms4s.`);
    }

    if (dataName !== null) {
      ms4Reports = ms4Reports.filter(function (report) {
        return report.dataName === dataName;
      });
    }

    return ms4Reports;
  };

  var getMs4Years = function (dataName = null, groupId = Tree.get("activeGroup", "groupId")) {
    const ms4Reports = _getMs4Reports(dataName, groupId);
    const activeReports = ms4Reports.filter((report) => !report.lockedReportDisabled);

    return _getUniqueYearsInDescendingOrder(activeReports);
  };

  var getYearReports = function (
    year = getSelectedYear(),
    dataName = null,
    groupId = Tree.get("activeGroup", "groupId"),
  ) {
    var ms4Reports;
    if (Progeny.activeHasProgeny()) {
      ms4Reports = getAllMs4sReports(dataName);
    } else {
      ms4Reports = _getMs4Reports(dataName, groupId);
    }

    return ms4Reports.filter(function (report) {
      return report.reportingYear === year;
    });
  };

  var getReportById = function (id, groupId = Tree.get("activeGroup", "groupId")) {
    var allMs4Reports = _getMs4Reports(null, groupId);

    return allMs4Reports.find(function (report) {
      return report.id === id;
    });
  };

  var _getMs4Reports = function (dataName = null, groupId) {
    var ms4Reports = Tree.get("allMs4s", groupId, "reports");

    if (Progeny.activeHasProgeny()) {
      return getAllMs4sReports(dataName);
    }

    if (!ms4Reports) {
      throw new Error(`No report data found for ${groupId}.`);
    }

    if (dataName) {
      ms4Reports = ms4Reports.filter(function (report) {
        return report.dataName === dataName;
      });
    }

    return ms4Reports;
  };

  var getLatestMs4Report = function (
    dataName = null,
    groupId = Tree.get("activeGroup", "groupId"),
  ) {
    const dataNameReports = _getMs4Reports(dataName, groupId);
    const latestReport = dataNameReports.reduce(
      function (max, current) {
        if (max.reportingYear < current.reportingYear) {
          return current;
        }

        return max;
      },
      { reportingYear: Number.NEGATIVE_INFINITY },
    );

    if (latestReport.reportingYear === Number.NEGATIVE_INFINITY) {
      return null;
    }

    return latestReport;
  };

  var _getUniqueYearsInDescendingOrder = function (reports) {
    var uniqueYears = new Set();

    for (const report of reports) {
      uniqueYears.add(report.reportingYear);
    }

    return [...uniqueYears].sort().reverse();
  };

  var setSubmittedMs4ReportByDataName = function (dataName, date, selectedYear) {
    getYearReports(selectedYear, dataName)[0].submittedOn = date;
  };

  var hasSubmitted = function (dataName = null, groupId = Tree.get("activeGroup", "groupId")) {
    var ms4Reports = _getMs4Reports(dataName, groupId);

    ms4Reports = ms4Reports.filter(function (report) {
      return report.submittedOn !== null;
    });

    return ms4Reports.length !== 0;
  };

  var getSelectedYear = function (skipThrowing = false) {
    var selected = Tree.get("yearDropdownSelection");
    var selectedYear = parseInt(selected);

    if (!skipThrowing && isNaN(selectedYear)) {
      throw new Error(`Trying to get invalid selected year ${selected}.`);
    }

    return selectedYear;
  };

  var isYearDataLoaded = function () {
    return Tree.get("allMs4s") !== null;
  };

  var mapDataModuleToDataName = function (layerName) {
    const mapDataNames = {
      trash: "trash group",
      swtelr: "stormwater group",
      construction: "construction",
      lid: "lid",
      idde: "idde",
      muniCatchBasin: "muni catch basin",
      "muni outfall": "muni outfall",
      "muni facility": "muni facility",
      "muni bmp": "muni bmp",
    };
    return mapDataNames[layerName];
  };

  var mapDataViewToDataName = function (dataView) {
    const map = {
      trashView: "trash group",
      runoffView: "stormwater group",
      particulatesView: "stormwater group",
      sbmpView: "stormwater group",
      runoffRatioView: "stormwater group",
      constructionView: "construction",
      lidView: "lid",
      iddeView: "idde",
      muniCatchBasinView: "muni catch basin",
      fcsView: "trash group",
      watershedView: "stormwater group",
      publicView: "public",
      assetManagementView: "asset management",
      indCommView: "indcom",
      muniFacilityView: "muni facility",
      muniBmpView: "muni bmp",
      outfallView: "muni outfall",
      streetSweepingView: "stormwater group",
    };
    return map[dataView];
  };

  var sortReportsByDataName = function (reports) {
    const sortOrder = {
      "stormwater group": 1,
      "trash group": 2,
      construction: 3,
      lid: 4,
      "muni catch basin": 5,
      "muni outfall": 6,
      "muni facility": 7,
      "water quality": 8,
      "asset management": 9,
      idde: 10,
      indcom: 11,
      public: 12,
    };

    return reports.sort(function (a, b) {
      return sortOrder[a.dataName] - sortOrder[b.dataName];
    });
  };

  return {
    setRegionalYears,
    getMs4Years,
    setMs4Years,
    getLatestMs4Report,
    setSubmittedMs4ReportByDataName,
    hasSubmitted,
    getSelectedYear,
    isYearDataLoaded,
    getAllMs4s,
    getYearReports,
    mapDataModuleToDataName,
    mapDataViewToDataName,
    sortReportsByDataName,
    getReportById,
    updateMs4Years,
    getReports,
  };
};

module.exports = AnnualReports();

const Tree = require("../tree");
const Progeny = require("../login/progeny");
const DataViewDropdown = require("./mapFunctions/dataViewDropdown");
const YearDropdown = require("./mapFunctions/yearDropdown");
const SubmitReport = require("./report/submitReport");
