"use strict";

const TemporaryBmpObservation = function () {
  var addObservation = function ($button, basePropertyName) {
    const $newObservation = $(
      getNewTemporaryBmpInspectionCardHtml($button.data("observationValue"), basePropertyName),
    );

    $button.before($newObservation);
    initializeTemporaryBmpInspection($newObservation);
  };

  var addAdministrativeFinding = function ($button, basePropertyName) {
    const $newFinding = $(
      getNewAdministrativeFindingCardHtml(basePropertyName, $button.data("observationValue")),
    );

    $button.before($newFinding);
    initializeTemporaryBmpInspection($newFinding);
  };

  var addObservationFinding = function ($button, basePropertyName) {
    const $newFinding = $(
      getNewObservationFindingCardHtml(basePropertyName, $button.data("observationValue")),
    );

    $button.before($newFinding);
    initializeTemporaryBmpInspection($newFinding);
  };

  var getNewTemporaryBmpInspectionCardHtml = function (observationValue, basePropertyName) {
    return nunjucks.render("modals/projectInspection/temporaryBmpInspectionCard.njk", {
      namePrefix: `${basePropertyName}[${observationValue}]`,
      inspection: createNewTemporaryBmpInspection(basePropertyName, observationValue),
      requiredObservationConditionOptions:
        ProjectInspectionConstants.getRequiredObservationConditionOptions(),
    });
  };

  var getNewAdministrativeFindingCardHtml = function (basePropertyName, practice) {
    return nunjucks.render("modals/projectInspection/administrativeFindingCard.njk", {
      namePrefix: `${basePropertyName}[${practice}]`,
      finding: createNewTemporaryBmpInspection(basePropertyName, practice),
      issueTypes:
        ProjectInspectionConstants.getAdministrativeFindingByPractice(practice)?.issueTypes,
      findingSeverityOptions: ProjectInspectionConstants.getFindingSeverityOptions(),
    });
  };

  var getNewObservationFindingCardHtml = function (basePropertyName, practice) {
    return nunjucks.render("modals/projectInspection/observationFindingCard.njk", {
      namePrefix: `${basePropertyName}[${practice}]`,
      finding: createNewTemporaryBmpInspection(basePropertyName, practice),
      practice,
      observationFindingOptions:
        ProjectInspectionConstants.getObservationFindingByPractice(practice),
      findingSeverityOptions: ProjectInspectionConstants.getFindingSeverityOptions(),
    });
  };

  var createNewTemporaryBmpInspection = function (basePropertyName, key) {
    const newInspection = getNextInspection(basePropertyName, key);

    ProjectInspectionController.setFormData(
      [basePropertyName, key, newInspection.index],
      newInspection,
    );

    return newInspection;
  };

  var getNextInspection = function (basePropertyName, key) {
    const existingObservations =
      ProjectInspectionController.getAllData()[basePropertyName][key] ?? [];
    existingObservations.push({});
    ProjectInspectionConstants.indexObservationArray(existingObservations);
    return existingObservations[existingObservations.length - 1];
  };

  var initializeTemporaryBmpInspection = function ($inspection) {
    const formKey = ProjectInspectionController.getFormKey();

    LocationButton.initializeButtons($inspection, formKey);
    FormPhotos.unloadListeners($inspection.find(".form-photos"));
    FormPhotos.initializeFormPhotos($inspection.find(".form-photos"), formKey);
    CollapsingCard.initializeCard($inspection);
  };

  var onDeleteObservation = function () {
    const $deleteButton = $(this);
    setObservationDeleted($deleteButton.data("id"));
    $deleteButton.closest(".card").remove();
  };

  var setObservationDeleted = function (observationPath) {
    const path = Form.getPathPartsFromName(observationPath);
    ProjectInspectionController.setFormData([...path, "deleted"], true);
  };

  return {
    createNewTemporaryBmpInspection,
    initializeTemporaryBmpInspection,
    addObservation,
    addAdministrativeFinding,
    addObservationFinding,
    onDeleteObservation,
  };
};

module.exports = TemporaryBmpObservation();

const CollapsingCard = require("../../general/collapsingCard");
const Form = require("../../general/form");
const FormPhotos = require("../../general/formPhotos");
const LocationButton = require("../../general/locationButton");
const ProjectInspectionConstants = require("./projectInspectionConstants");
const ProjectInspectionController = require("./projectInspectionController");
