"use strict";

const StreamsEsriFeatureLayer = function () {
  const layerName = "streamsEsriFeature";
  const layerKey = "streamsEsriFeatureLayer";
  const esriKey = "soilImpervious";
  const esriLayer = "streams";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var layer = new EsriFeatureLayer(
      layerName,
      layerKey,
      esriKey,
      esriLayer,
      Layers.getWatershedLayerOptions,
    );
    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = StreamsEsriFeatureLayer();

const EsriFeatureLayer = require("../mapFunctions/esriFeatureLayer");
const Layers = require("../mapFunctions/layers");
