"use strict";

const GroupConfiguration = function () {
  const stringKey = "group-configuration";
  const tabHeader = "Group";

  var loadListeners = function (data) {};

  var unloadListeners = function () {};

  var getProps = function (data) {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = GroupConfiguration();
