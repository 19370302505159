"use strict";

const ActivityInventoryInfo = function () {
  const stringKey = "activity-info";
  const headerInformation = "Activity info";
  let $page;

  var loadActivityInfoListeners = function () {
    $page.on("change", `[name="audienceType"]`, renderAudienceTypeUpdates);
    $page.on("change", `[name="activity"]`, renderActivityUpdates);
    $page.on("change", `[name="timeFrame"]`, renderTimeFrameField);

    Form.initializeAndLoadListeners(
      $page,
      ActivityInventoryModalController.getActivityInventoryFormKey(),
      { isMultiPart: true },
    );

    MultipleSelect.init($page);
  };

  var removeActivityInfoListeners = function () {
    $page.off("change", `[name="activity"]`, renderActivityUpdates);
    $page.off("change", `[name="timeFrame"]`, renderTimeFrameField);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    await renderPageHtml();
    setActivityOutreachType();
    toggleAudienceTypedFields();
    createOtherFields();
    loadActivityInfoListeners();
  };

  var renderPageHtml = async function () {
    const renderData = getActivityInfoProps();
    const html = await nunjucks.asyncRender("modals/peo/activityInventoryInfo.njk", {
      ...renderData,
    });
    ActivityInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  var getActivityInfoProps = function () {
    const constants = ActivityConstants.get();
    const { receivingWaterOptions } = Filters.getSpatialArrays();
    const inventoryData = ActivityInventoryModalController.getActivityInventoryData();

    return {
      ...inventoryData,
      constants: {
        activities: getAudienceFilteredActivities(),
        activitySubTypes: getActivitySubtypes(),
        staff: mapTargetFields(constants.staff),
        audience: mapTargetFields(constants.audience),
        behavior: mapTargetFields(constants.behavior),
        pollutant: mapTargetFields(constants.pollutant),
        audienceTypes: constants.audienceTypes,
        permits: constants.permits,
        timeFrames: constants.timeFrames,
        receivingWaterOptions,
        selectAllReceivingWater: _shouldSelectAll("receivingWater"),
        progenyOptions: Progeny.getActiveGroupProgenyFormOptions(),
        selectAllProgeny: _shouldSelectAll("progeny"),
      },
    };
  };

  var getAudienceFilteredActivities = function () {
    const constants = ActivityConstants.get();
    const activityConstants = constants?.activities;
    const currentAudience =
      ActivityInventoryModalController.getLatestActivityInventoryData("audienceType");

    return Object.keys(activityConstants ?? {})
      .filter((activity) => activityConstants[activity]["audienceType"] === currentAudience)
      .map(function (activity) {
        return {
          name: constants.activities[activity]["displayName"],
          value: activity,
        };
      });
  };

  var getActivitySubtypes = function () {
    const constants = ActivityConstants.get();
    const activityConstants = constants?.activities;
    const currentActivity =
      ActivityInventoryModalController.getLatestActivityInventoryData("activity");

    return currentActivity
      ? activityConstants[currentActivity].activitySubTypes.map(function (activitySubTypeKey) {
          return {
            name: constants.activitySubTypes[activitySubTypeKey],
            value: activitySubTypeKey,
          };
        })
      : [];
  };

  var renderAudienceTypeUpdates = function (e) {
    ActivityInventoryModalController.setFormDataField("activity", "");
    updateAvailableActivities();
    toggleAudienceTypedFields();
    renderActivityUpdates();
  };

  var updateAvailableActivities = function () {
    $page.find(".activity-dropdown").html(
      nunjucks.render("modals/peo/activityDropdown.njk", {
        constants: { activities: getAudienceFilteredActivities() },
      }),
    );

    $page.off("change", `[name="activity"]`, renderActivityUpdates);
    $page.on("change", `[name="activity"]`, renderActivityUpdates);
  };

  var toggleAudienceTypedFields = function () {
    const currentAudience =
      ActivityInventoryModalController.getLatestActivityInventoryData("audienceType");
    $page.find(".audience-typed").toggleClass("hidden", true);
    $page.find(`.audience-typed.${currentAudience}`).toggleClass("hidden", false);
  };

  var renderActivityUpdates = function (e) {
    setActivityOutreachType();
    updateActivitySubtypesDropdown();
    ActivityInventoryModalController.setModalTitle();
  };

  var setActivityOutreachType = function () {
    const activityConstants = ActivityConstants.get().activities;
    const currentActivity =
      ActivityInventoryModalController.getLatestActivityInventoryData("activity");
    const activity = activityConstants[currentActivity];
    $page.find(".activity-group").html(activity?.activityGroup ?? "");
    $page.find(".outreach-type").html(activity?.outreachType ?? "");
  };

  var updateActivitySubtypesDropdown = function () {
    const $activitySubType = $page.find(".activity-sub-type");
    const activitySubTypes = getActivitySubtypes();
    $page.find(".activity-sub-type-field").toggleClass("hidden", activitySubTypes.length === 0);

    const activityConstants = ActivityConstants.get().activities;
    const currentActivity =
      ActivityInventoryModalController.getLatestActivityInventoryData("activity");

    if (currentActivity) {
      const currentSubType =
        ActivityInventoryModalController.getLatestActivityInventoryData("activitySubType");

      const activitySubType = activityConstants[currentActivity].activitySubTypes.includes(
        currentSubType,
      )
        ? currentSubType
        : null;

      const html = nunjucks.render("modals/peo/activitySubType.njk", {
        activitySubTypes,
        activitySubType,
      });
      $activitySubType.html(html);
    }
  };

  var mapTargetFields = function (targetOptions) {
    return targetOptions?.map(function (target) {
      return {
        name: target.displayValue,
        value: target.id,
      };
    });
  };

  var renderTimeFrameField = function () {
    const timeFrame = ActivityInventoryModalController.getLatestActivityInventoryData("timeFrame");
    const startDate = ActivityInventoryModalController.getLatestActivityInventoryData("startDate");
    const endDate = ActivityInventoryModalController.getLatestActivityInventoryData("endDate");
    const $timeFrames = $page.find(".activity-dates");
    $timeFrames.html(
      nunjucks.render("modals/peo/activityTimeFrame.njk", {
        timeFrame,
        startDate,
        endDate,
      }),
    );
    Form.initializeDatePickers(
      $timeFrames,
      ActivityInventoryModalController.getActivityInventoryFormKey(),
    );
  };

  var cleanUp = function () {
    removeActivityInfoListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var createOtherFields = function () {
    const otherFields = ["staff", "pollutants", "behavior"];
    otherFields.forEach(function (field) {
      const $otherField = $page.find(`.${field}`).find(".checkbox-container:contains('Other')");
      const othersClass = `other-${field}`;
      $otherField.toggleClass(othersClass, true);
      const othersInput = Misc.kebabToCamel(othersClass);
      const othersValue =
        ActivityInventoryModalController.getLatestActivityInventoryData(othersInput);
      const defaultHidden = $otherField.find("[type='checkbox']").prop("checked") ? "" : "hidden";

      const html = nunjucks.render("modals/peo/othersInput.njk", {
        othersInput,
        othersValue,
        defaultHidden,
      });
      $otherField.append(html);
      $otherField.off("change", "[type='checkbox']", toggleOtherInput);
      $otherField.on("change", "[type='checkbox']", toggleOtherInput);
    });
  };

  var toggleOtherInput = function (e) {
    const checked = $(e.currentTarget).prop("checked");
    $(e.currentTarget).parent().siblings().toggleClass("hidden", !checked);
  };

  var _shouldSelectAll = function (fieldName) {
    const existingData = ActivityInventoryModalController.getExistingData([])[fieldName];
    const updateData = ActivityInventoryModalController.getLatestActivityInventoryData([])[
      fieldName
    ];

    return existingData === undefined && updateData === undefined;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    mapTargetFields,
    _shouldSelectAll,
  };
};

module.exports = ActivityInventoryInfo();

const Form = require("../general/form");
const Misc = require("../misc");
const ActivityInventoryModalController = require("./activityInventoryModalController");
const ActivityConstants = require("./activityConstants");
const Filters = require("../mapFunctions/filters");
const MultipleSelect = require("../general/multipleSelect");
const Progeny = require("../login/progeny");
