"use strict";

const MaintenanceZonesLegendLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      "maintenanceZonesLegend",
      mapId,
      function (zonesAreEnabled, sameSpatialFilter) {
        if (zonesAreEnabled) {
          if (
            mapLayers.maintenanceZonesLegendLayer &&
            (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
          ) {
            map.addLayer(mapLayers.maintenanceZonesLegendLayer);
          } else {
            loadLayer(mapId);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.maintenanceZonesLegendLayer)) {
            map.removeLayer(mapLayers.maintenanceZonesLegendLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated("maintenanceZonesLegend", mapId, function (data) {
      mapLayers.maintenanceZonesLegendLayer = addMaintenanceZonesLegendLayer(
        map,
        mapLayers.maintenanceZonesLegendLayer,
        data,
      );
    });
  };

  var addMaintenanceZonesLegendLayer = function (map, maintenanceZonesLegendLayer, data) {
    if (maintenanceZonesLegendLayer) {
      map.removeLayer(maintenanceZonesLegendLayer);
    }

    return L.geoJson(data, {
      pane: "overCatchmentPane",
      style: MapStyles.styleMaintenanceZonesLegend,
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => nunjucks.render("popups/maintenanceZonePopup.njk", feature),
          MapFunctions.getPopupOptions(),
        );
      },
    }).addTo(map);
  };

  var loadLayer = function (mapId = "main") {
    const filters = Actions.getFiltersByMapId(mapId);
    const dataPath = Actions.getLayerDataPathByMapId(mapId);
    Tree.set(["layers", "maintenanceZonesLegend", "isFetching"], true);
    ApiCalls.getMaintenanceZones(filters).then((data) => {
      Tree.set(["layers", "maintenanceZonesLegend", dataPath], data);
      Tree.set(["layers", "maintenanceZonesLegend", "isFetching"], false);
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = MaintenanceZonesLegendLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const Actions = require("../actions");
