"use strict";

const BlueDot = function () {
  const blueDotByMap = {
    inputs: null,
    report: null,
    plan: null,
  };

  function watchUserLocation() {
    const mapId = Tree.get("mapId");
    const blueDot = blueDotByMap[mapId] || L.circleMarker();
    blueDotByMap[mapId] = blueDot;
    if (!Tabs.activeTabHasMap()) {
      throw new Error("Can't enable blue dot if there's no map being shown.");
    }
    const map = TreeUpdates.getCurrentMap();
    map.stopLocate();
    map.locate({ watch: true, enableHighAccuracy: true });
    map.on("locationfound", (e) => onLocationFound(e.latlng, map, blueDot));
  }

  function disable() {
    const map = TreeUpdates.getCurrentMap();
    map.stopLocate();
    map.off("locationfound");
    const mapId = Tree.get("mapId");
    if (!Tabs.activeTabHasMap()) {
      return;
    }
    const blueDot = blueDotByMap[mapId];
    if (MapFunctions.mapHasLayer(map, blueDot)) {
      map.removeLayer(blueDot);
    }
    blueDotByMap[mapId] = null;
  }

  function onLocationFound(latlng, map, blueDot) {
    blueDot.setLatLng(latlng);

    if (!MapFunctions.mapHasLayer(map, blueDot)) {
      blueDot.addTo(map);
    }
  }

  return { watchUserLocation, disable };
};

module.exports = BlueDot();

const Tree = require("../tree");
const TreeUpdates = require("./mapFunctions/treeUpdates");
const MapFunctions = require("./mapFunctions/mapFunctions");
const Tabs = require("./tabs");
