"use strict";

const OfflineFilterFunctions = function () {
  // Same as API CommonHelperFunctions@withinRange()
  const passMinMaxFilter = function (value, minMax = { min: null, max: null }) {
    const { min, max } = minMax;
    var pass = true;

    const minSet = Misc.onlyContainsNumbers(min);
    const maxSet = Misc.onlyContainsNumbers(max);

    if (minSet || maxSet) {
      if (value === null) return false;

      if (minSet) {
        pass = Number(value) >= Number(min);
      }
      if (maxSet) {
        pass = pass && Number(value) <= Number(max);
      }
    }

    return pass;
  };

  var manyToManyOrNullFilterUndefined = function (datum, filters, filterKey, nullAlias) {
    if (!filters[filterKey]) {
      return true;
    }

    let nullAliasMatched = false;

    if (filters[filterKey].includes(nullAlias)) {
      nullAliasMatched =
        datum === undefined ||
        datum === null ||
        (Array.isArray(datum) && (datum.includes(null) || datum.includes(undefined)));
    }

    return filters[filterKey].some((item) => datum?.includes(item)) || nullAliasMatched;
  };

  var manyToManyOrNullFilter = function (datum, filters, filterKey, nullAlias) {
    let pass = false;
    filters[filterKey].forEach((item) => {
      if (
        datum?.includes(item) ||
        (filters[filterKey]?.includes(nullAlias) &&
          (datum == null || datum?.includes(null) || datum?.includes(undefined)))
      ) {
        pass = true;
      }
    });
    return pass;
  };

  var manyToManyOrNullFilterOutfall = function (datum, filters, filterKey, nullAlias) {
    let pass = false;

    if (filters[filterKey]?.includes(nullAlias)) {
      return (
        datum === undefined || datum?.includes(null) || datum?.includes(undefined) || datum === null
      );
    }

    filters[filterKey].forEach((item) => {
      if (datum?.includes(item)) {
        pass = true;
      }
    });

    return pass;
  };

  var passYesNoNullFilter = function (datum, filters, filterKey, dataKey) {
    return (
      !filters[filterKey] ||
      (filters[filterKey].includes("true") && datum[dataKey] === true) ||
      (filters[filterKey].includes("false") && datum[dataKey] === false) ||
      (filters[filterKey].includes("null") && datum[dataKey] === null)
    );
  };

  var passYesNoExistsFilter = function (datum, filters, filterKey, dataKey) {
    return (
      !filters[filterKey] ||
      (filters[filterKey].includes("true") && datum[dataKey] !== null) ||
      (filters[filterKey].includes("false") && datum[dataKey] === null)
    );
  };

  var includedOrNull = function (filters, datum, nullAlias = "null") {
    return filters.includes(String(datum)) || (datum == null && filters.includes("null"));
  };

  var passFilter = function (filterSelected, datum) {
    if (filterSelected?.length !== undefined) {
      return includedOrNull(filterSelected, datum);
    } else return true;
  };

  return {
    passMinMaxFilter,
    includedOrNull,
    manyToManyOrNullFilter,
    passYesNoNullFilter,
    passYesNoExistsFilter,
    passFilter,
    manyToManyOrNullFilterUndefined,
    manyToManyOrNullFilterOutfall,
  };
};

module.exports = OfflineFilterFunctions();

const Misc = require("../misc");
