"use strict";

const AssetManagementRadioModeToggleController = function () {
  var handleRadioClick = function (that) {
    const layerName = $(that).attr("value");

    $(".asset-radio-layer").removeAttr("disabled");
    $(that).attr("disabled", true);

    if (layerName === "probability-failure") {
      Tree.set("assetManagementRadioMode", "pof");
    } else if (layerName === "assets") {
      Tree.set("assetManagementRadioMode", "attribution");
    } else if (layerName === "cof") {
      Tree.set("assetManagementRadioMode", "cof");
    } else if (layerName === "criticality") {
      Tree.set("assetManagementRadioMode", "criticality");
    }
  };

  return {
    handleRadioClick,
  };
};

module.exports = AssetManagementRadioModeToggleController();

const Tree = require("../tree");
