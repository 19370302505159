"use strict";

const ProjectInventoryAssetDetails = function () {
  const stringKey = "project-asset-details";
  const headerInformation = "Asset Details";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };
  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });

    var bmps = InventoryModal.getAllData("project-inventory").bmps;
    var hasBmps = checkNoBmpWarning(bmps);
    var checkboxesDisabled = ProjectInventoryConstants.assetsDisabledPhases.includes(
      InventoryModal.getAllData("project-inventory").phase,
    );

    // Marshall Fields
    bmps.forEach(async (bmp) => {
      bmp.bmpName = bmp.bmpId ?? bmp.bmpName;
      bmp.bmpType = bmp.bmpType ?? bmp.bmpTypeObj.id;
      bmp.bmpTypeName = bmp.bmpTypeName ?? bmp.bmpTypeObj.name;
      bmp.bmpTypeAbbr = bmp.bmpTypeAbbr ?? bmp.bmpTypeObj.abbreviation;
      bmp.checkboxesDisabled = checkboxesDisabled;
    });
    await setPossibleInstallationInspectionCheck(bmps);

    const html = nunjucks.render("modals/projectInventory/projectInventoryAssetDetails.njk", {
      props: { bmps, hasBmps },
    });
    ProjectInventoryModalController.renderPageContent(html);
  };

  var setPossibleInstallationInspectionCheck = function (bmps) {
    var bmpPromises = [];
    bmps.forEach((bmp) => {
      bmpPromises.push(
        new Promise((resolve) => {
          getPossibleInstallationInspectionType(bmp).then((result) => {
            bmp.possibleInstallationInspectionTypes = result;

            // Check all for New Bmp or Update Bmp type
            if (bmp.bmp || bmp.bmpTypeObj.abbreviation !== bmp.bmpTypeAbbr) {
              const allOptions = result.map((type) => {
                return type.value;
              });
              bmp.installationInspectionTypes = allOptions;
            }

            bmp.possibleInstallationInspectionTypes.forEach((type) => {
              bmp.installationInspectionTypes = bmp.installationInspectionTypes ?? [];
              type["checked"] = bmp.installationInspectionTypes.includes(type.value);
            });

            resolve();
          });
        }),
      );
    });
    return Promise.all(bmpPromises);
  };

  var getPossibleInstallationInspectionType = function (bmp) {
    return new Promise((resolve) => {
      ProjectInventoryModalController.fetchPossibleInstallationInspectionTypes(
        bmp.bmpType ?? bmp.bmpTypeObj.id,
      ).then((result) => {
        resolve(result);
      });
    });
  };

  var checkNoBmpWarning = function (bmps) {
    var hasBmps = bmps.filter((bmp) => {
      return !bmp.deleted;
    });

    if (!hasBmps.length > 0) {
      var callbackNo = function () {
        InventoryModal.cleanUpPage();
        InventoryModal.setActivePageByKey("project-contacts");
      };

      var callbackYes = function () {
        InventoryModal.cleanUpPage();
        InventoryModal.setActivePageByKey("project-add-bmps");
      };

      MessageModal.showConfirmWarningModal(
        `You do not have any assets associated with this project. Would you like to add Structural BMPs? Click "Yes" to add structural BMPs to this project. Click "No" to continue without adding a sBMP.`,
        callbackNo,
        "Yes",
        "No",
        null,
        callbackYes,
        "Add Structural BMPs?",
      );
    }

    return hasBmps;
  };

  var cleanUp = function () {
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
  };
};

module.exports = ProjectInventoryAssetDetails();

const ProjectInventoryModalController = require("./projectInventoryModalController");
const InventoryModal = require("../general/inventoryModal");
const MessageModal = require("../modals/messageModal");
const Form = require("../general/form");
const ProjectInventoryConstants = require("./projectInventoryConstants");
