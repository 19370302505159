"use strict";

const LidIcon = function () {
  var getLidProjectMapIcon = function (lidProjectData) {
    return RamLidIcon.getLidProjectMapIcon(
      lidProjectData,
      true,
      ReportMap.getActiveReportMap()?.getZoom(),
      [24, 24],
      true,
      "",
      true,
    );
  };

  return {
    getLidProjectMapIcon,
  };
};

module.exports = LidIcon();

const ReportMap = require("../report/reportMap");
// Ram modules
const RamLidIcon = require("../../lid/lidIcon");
