"use strict";

const MuniBmpIcon = function () {
  var getIconMarker = function (isCentralized, condition, isDue, abbreviation, zoom) {
    const iconInfo = getIconInfo(isCentralized, condition, isDue, abbreviation, zoom);
    return createIcon(iconInfo, zoom);
  };

  var createIcon = function (iconInfo, mapZoom) {
    const bmpIconProps = _updateIconPropsOnZoom(mapZoom);
    const dataView = Tree.get("dataView");
    return new L.DivIcon({
      className: iconInfo.class,
      iconSize: dataView === "muni-bmp" || dataView === "lid-bmp" ? bmpIconProps.size : [26, 32],
      iconAnchor: BmpFcsIcon.updateIconAnchorOnZoom(iconInfo.isCentralized, mapZoom).iconAnchor,
      popupAnchor: [0, -10],
      html:
        dataView === "muni-bmp" || dataView === "lid-bmp"
          ? "<span class='" + bmpIconProps.textCSSClass + "'>" + iconInfo.abbreviation + "</span>"
          : "<span>" + iconInfo.abbreviation + "</span>",
    });
  };

  var _updateIconPropsOnZoom = function (mapZoom, currentDataView) {
    const zoomLevel = mapZoom ? mapZoom : Tree.get("zoomLevel");
    const ratio = 0.8125;
    const isLargeGroupScenarioDataview =
      ["scenarios", "construction-project-delivery"].includes(currentDataView) &&
      Session.isLargeGroup();
    const iconSizesArray = isLargeGroupScenarioDataview
      ? [20, 40, 40, 40, 40, 40, 40, 40]
      : [8, 20, 20, 42, 40, 40, 40, 40];

    const iconClassNamesArray = [
      "icon-font-none",
      "icon-font-small",
      "icon-font-medium",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
    ];
    const largeGroupIconClassNamesArray = [
      "icon-font-medium",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
      "icon-font-large",
    ];
    const updatedIconProps = MapFunctions.resizeIconsOnZoom(
      zoomLevel,
      false,
      false,
      false,
      false,
      iconSizesArray,
      Session.isLargeGroup() ? [17, 21, 25, 30, 30, 28, 28, 28] : null,
      true,
      ratio,
      isLargeGroupScenarioDataview ? largeGroupIconClassNamesArray : iconClassNamesArray,
    );

    return updatedIconProps;
  };

  var getIconInfo = function (isCentralized, condition, isDue, abbreviation, zoom = null) {
    const iconInfo = {
      class: ["muni", "bmp"],
      color: "grey",
      abbreviation,
    };

    if (isCentralized) {
      iconInfo.class.push("centralized");
    }

    iconInfo.class = iconInfo.class.join("-");
    iconInfo.class = RoutineMaintenanceIcon.getConditionClass(iconInfo.class, condition);
    iconInfo.color = RoutineMaintenanceIcon.getConditionColor(condition);
    iconInfo.class = `${iconInfo.class} muni-bmp-icon`;

    if (isDue) {
      iconInfo.class += " " + MapStyles.getDueIconClass();
    }

    if (zoom !== null) {
      iconInfo.class += " " + BmpFcsIcon.getBmpIconZoomClass(zoom);
    }

    return iconInfo;
  };

  return {
    getIconMarker,
    getIconInfo,
    _updateIconPropsOnZoom,
  };
};

module.exports = MuniBmpIcon();

const BmpFcsIcon = require("../../bmpfcs/bmpFcsIcon");
const RoutineMaintenanceIcon = require("../../general/routineMaintenanceIcon");
const Session = require("../../login/session");
const MapFunctions = require("../../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const MapStyles = require("../../mapFunctions/mapStyles");
