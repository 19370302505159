"use strict";

const CustomLayer = function () {
  var init = function (mapId, map, mapLayers, layerSettings) {
    const displayInThisModule = _displayInThisModule(layerSettings);
    if (!displayInThisModule) return;

    _loadToggledListenerForCustomLayer(mapId, map, mapLayers, layerSettings);
    _startCustomLayerZoomListener(map, layerSettings);
  };

  var _displayInThisModule = function (layerSettings) {
    const thisModule = Tree.get("tool");
    if (layerSettings.modules.includes(thisModule) || layerSettings.modules.includes("all")) {
      return true;
    } else {
      return false;
    }
  };

  var getCustomLayerKey = function (layerSettings) {
    const camelCaseName = Misc.toCamelCase(layerSettings.layerName);
    return `${camelCaseName}CustomLayer`;
  };

  var _loadToggledListenerForCustomLayer = function (mapId, map, mapLayers, layerSettings) {
    const layerKey = getCustomLayerKey(layerSettings);
    MapFunctions.whenLayerToggled(layerKey, mapId, function (isEnabled) {
      var currentMapLayer = mapLayers[`${layerKey}`];
      if (isEnabled) {
        enableLayer(map, currentMapLayer, layerSettings, mapLayers, layerKey);
      } else {
        disableLayer(map, currentMapLayer);
      }
    });
  };

  var enableLayer = function (map, currentMapLayer, layerSettings, mapLayers, layerKey) {
    if (currentMapLayer) {
      map.removeLayer(currentMapLayer);
    }
    const customLayer = CustomLayerConfig.getCustomLayerByType(layerSettings);
    mapLayers[layerKey] = customLayer;

    return customLayer.addTo(map);
  };

  var disableLayer = function (map, currentMapLayer) {
    if (currentMapLayer) {
      map.removeLayer(currentMapLayer);
    }
  };

  var _startCustomLayerZoomListener = function (map, layerSettings) {
    map.on("moveend zoomend", function () {
      var zoomLevel = map.getZoom();
      const enabled = zoomLevel >= layerSettings.minZoom;

      Misc.toggleDisabledInputs($(".checkbox.disabled-zoomed-out-custom"), !enabled);
    });
  };

  var getCustomLayersToDisplayByModule = function (layerSettings) {
    const thisModule = Tree.get("tool");
    const moduleLayers = layerSettings?.layers.filter((layer) => {
      return layer.modules.includes(thisModule) || layer.modules.includes("all");
    });
    return moduleLayers;
  };

  return {
    init,
    getCustomLayerKey,
    getCustomLayersToDisplayByModule,
  };
};

module.exports = CustomLayer();

const CustomLayerConfig = require("./customLayerConfig");
const MapFunctions = require("./mapFunctions");
const Misc = require("../misc");
const Tree = require("../tree");
