"use strict";

const Device = function () {
  const isPrimaryInputTouch = function () {
    return window.matchMedia("(pointer: coarse)").matches;
  };

  return { isPrimaryInputTouch };
};

module.exports = Device();
