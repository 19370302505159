"use strict";

const IndcomFacilityGeoServerLayer = function () {
  const layerName = "indcomFacilities";
  const layerKey = "indcomFacilitiesLayer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: IndcomIcon.getFacilityIconMarker(feature.properties),
    });
  };

  var createIconFunction = function (feature, latlng) {
    return IndcomIcon.getFacilityIconMarker(feature.properties);
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const indcomFacilityGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
      createIconFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, indcomFacilityGeoServerLayer);

    indcomFacilityGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    indcomFacilityGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
    loadGlobalListeners();

    if (Tree.get("tool") === "indcom") {
      loadConditionPhaseToggleUpdatedListener(map, mapLayers);
    }
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    const properties = {
      contacts: _parseContacts(data.contacts),
      geometry: geometry,
      id: data.id,
      groupId: data.group_id,
      facilityIdentifier: data.facility_identifier,
      facilityName: data.facility_name,
      facilityType: data.facility_type,
      facilitySize: data.facility_size,
      displayFacilityType: _getDisplayFacilityType(data.facility_type),
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      sicCode: data.sic_code,
      wasteDischargerIdentifier: data.waste_discharger_identifier,
      isIndustrialGeneralPermit: data.is_industrial_general_permit,
      industrialGeneralPermitNumber: data.industrial_general_permit_number,
      fogParticipant: data.fog_participant,
      riskProfile: data.risk_profile,
      greaseTrap: data.grease_trap,
      greaseTallow: data.grease_tallow,
      greaseInterceptor: data.grease_interceptor,
      facilityPhase: data.facility_phase,
      responsiblePartyManagement: data.responsible_party_management,
      hasOpenEnforcements: data.open_enforcements,
      hasOpenFindings: data.open_findings,
      hasInspections: data.has_inspections,
      canCreateFacility: ToolSettings.getSetting("permissions", "can_create_facility"),
      canInspectFacility: ToolSettings.getSetting("permissions", "can_inspect_facility"),
      canIssueEnforcement: ToolSettings.getSetting("permissions", "can_issue_facility_enforcement"),
      openFindings: _parseOpenFindings(data.current_open_findings),
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      ...GeoServerLayerFunctions.prepareToDoData(data, {
        indcom_facility_inspection: "indcomFacilityInspection",
        indcom_facility_finding: "indcomFacilityFinding",
        indcom_facility_enforcement: "indcomFacilityEnforcement",
        indcom_facility_inventory_incomplete: "indcomFacilityInventoryIncomplete",
      }),
    };

    properties.tableIcon = IndcomIcon.getFacilityIconClass(properties);

    return properties;
  };

  var _getDisplayFacilityType = function (facilityType) {
    return (
      IndcomInventoryConstants.getFacilityTypeOptions().find((type) => type.value === facilityType)
        ?.name ?? facilityType
    );
  };

  var _parseContacts = function (jsonString) {
    return (
      JSON.parse(jsonString)?.map(function (data) {
        return {
          id: data.id,
          displayRole: data.role,
          name: `${data.first_name} ${data.last_name}`,
          email: data.email,
          phoneNumber: data.phone_number,
        };
      }) ?? []
    );
  };

  var _parseOpenFindings = function (jsonString) {
    return (
      JSON.parse(jsonString)?.reduce(function (map, finding) {
        map[finding.observation_id] = {
          notes: finding.notes,
          compliant: "no",
        };
        return map;
      }, {}) ?? {}
    );
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeLayerData(data);
  };

  var loadGlobalListeners = function () {
    // Following the trend of Construction's Add Inspection listeners, globally loading these seems like the best way
    $("body").off("click", ".js-add-facility-inspection", newFacilityInspectionClick);
    $("body").on("click", ".js-add-facility-inspection", newFacilityInspectionClick);

    $("body").off("click", ".js-edit-facility-inspection", editFacilityInspectionClick);
    $("body").on("click", ".js-edit-facility-inspection", editFacilityInspectionClick);

    $("body").off("click", ".js-issue-facility-enforcement", issueFacilityEnforcementClick);
    $("body").on("click", ".js-issue-facility-enforcement", issueFacilityEnforcementClick);

    $("body").off("click", ".download-facility-inspection", downloadFacilityInspectionClick);
    $("body").on("click", ".download-facility-inspection", downloadFacilityInspectionClick);

    $("body").off(
      "click",
      ".download-facility-self-inspection",
      downloadFacilitySelfInspectionClick,
    );
    $("body").on(
      "click",
      ".download-facility-self-inspection",
      downloadFacilitySelfInspectionClick,
    );

    $("body").off("click", ".js-view-facility-self-inspection", showSelfInspectionClick);
    $("body").on("click", ".js-view-facility-self-inspection", showSelfInspectionClick);

    $("body").off("click", ".js-edit-enforcement", editEnforcementAction);
    $("body").on("click", ".js-edit-enforcement", editEnforcementAction);

    $("body").off("click", ".js-view-enforcement", viewEnforcementAction);
    $("body").on("click", ".js-view-enforcement", viewEnforcementAction);
  };

  var newFacilityInspectionClick = function () {
    const id = $(this).data("id");
    FacilityInspectionController.loadNewFacilityInspection(id);
  };

  var editFacilityInspectionClick = function () {
    const id = $(this).data("id");
    FacilityInspectionController.loadExistingFacilityInspection(id);
  };

  var issueFacilityEnforcementClick = function () {
    const id = $(this).data("id");
    FacilityEnforcementController.loadNewEnforcement(id);
  };

  var downloadFacilityInspectionClick = function () {
    var inspectionId = $(this).parents("tr").data("id");
    ApiCalls.downloadFacilityInspectionReport(inspectionId);
  };

  var downloadFacilitySelfInspectionClick = function () {
    var inspectionId = $(this).parents("tr").data("id");
    ApiCalls.downloadFacilitySelfInspectionReport(inspectionId);
  };

  var showSelfInspectionClick = function () {
    var inspectionId = $(this).parents("tr").data("id");
    IndcomFacilitySelfInspectionModal.showReadOnly(inspectionId);
  };

  var editEnforcementAction = function () {
    const id = $(this).data("id");
    FacilityEnforcementController.editEnforcementAction(id);
  };

  var viewEnforcementAction = function () {
    const id = $(this).data("id");
    FacilityEnforcementController.viewEnforcementAction(id);
  };

  var loadConditionPhaseToggleUpdatedListener = function (map, mapLayers) {
    Tree.select(["filters", "conditionPhaseToggle"]).on("update", function (e) {
      if (
        e.data.previousData !== e.data.currentData &&
        Tree.get("dataView") === "indcom-facilities"
      ) {
        const toggle = e.data.currentData;

        if (toggle === "condition") {
          Filters.updateConditionCheckboxes(["open"]);
          Tree.set(["filters", "facilityPhase"], ["open"]);
        } else {
          Filters.updatePhaseCheckboxes(["open", "closed"]);
          Tree.set(["filters", "facilityPhase"], ["open", "closed"]);
        }
        MultipleSelect.updateDropdown($('.dropdown input[name="facilityPhase"]'));
        FilterSummary.render();
        handleLegendDisplayByConditionPhaseToggle(toggle);
        DisplayOptions.validateConditionPhaseToggleRadioButtons(toggle);
      }
    });
  };

  var handleLegendDisplayByConditionPhaseToggle = function (toggle) {
    DisplayOptions.handleLegendDisplayByConditionPhaseToggle(layerName, toggle);
  };

  var getOfflineData = async function (id) {
    return await ResourceController.get("indcomFacilities").getById(id);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeLayerData,
    getLayerData,
    getOfflineData,
    _parseOpenFindings,
    _parseContacts,
  };
};

module.exports = IndcomFacilityGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const IndcomIcon = require("./indcomIcon");
const ApiCalls = require("../apiCalls");
const FacilityEnforcementController = require("./facilityEnforcement/facilityEnforcementController");
const FacilityInspectionController = require("./facilityInspection/facilityInspectionController");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const DisplayOptions = require("../mapFunctions/displayOptions");
const Filters = require("../mapFunctions/filters");
const ResourceController = require("../offline/resourceController");
const IndcomInventoryConstants = require("./indcomInventoryConstants");
const MultipleSelect = require("../general/multipleSelect");
const FilterSummary = require("../filters/filterSummary");
const IndcomFacilitySelfInspectionModal = require("../portal/indcomFacilitySelfInspection/indcomFacilitySelfInspectionModal");
