class PortalController {
  static #secret;
  static #secretData;

  static async authenticate() {
    PortalController.#secret = UrlRoutes.getPathPartByIndex(0);
    let result;

    try {
      TokenManager.removeToken();
      result = await PortalApiCalls.authenticate(PortalController.#secret);
    } catch (e) {
      ApiError.assertIs(e);
      PortalController.#showAuthenticationError(e);

      return false;
    }

    TokenManager.saveToken(result.token);
    PortalController.#secretData = TokenManager.getTokenData(result.token).context;

    await PortalController.#loadRequired();

    return true;
  }

  static goToForm() {
    const routeName = PortalController.#getRouteNameByModelCanAccess();
    PortalController.goWithSecret(routeName);
  }

  static goWithSecret(routeName) {
    UrlRoutes.go(routeName, { secret: PortalController.#secret });
  }

  static get secretData() {
    return PortalController.#secretData;
  }

  static #getRouteNameByModelCanAccess() {
    const token = TokenManager.loadToken();
    const modelCanAccess = TokenManager.getTokenData(token).context;

    return {
      "lid-send-notice": "portal-lid-self-inspection-response",
      "indcom-facility-inspection": "portal-indcom-facility-self-inspection-response",
    }[modelCanAccess.type];
  }

  static async #loadRequired() {
    const formSettings = await PortalApiCalls.getFormSettings();
    FormSettings.saveSettings(formSettings);
  }

  static #showAuthenticationError(e) {
    if (e.status === 401) {
      MessageModal.showCustomMessage(
        "Invalid URL",
        "The URL you've entered is not in our records. Please double check that you've entered the correct code.",
      );
    } else if (e.status === 500 || e.status === 0) {
      MessageModal.showCustomMessage(
        "Server Error",
        "Something went wrong on our end, please try again later or contact us at <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for assistance.",
      );
    } else if (e.status === 429) {
      MessageModal.showCustomMessage(
        "Too Many Attempts",
        "Please wait a minute and refresh the page.",
      );
    } else if (e.status === 403 && e.xhrResponse?.responseJSON) {
      PortalController.#showForbiddenAuthenticationError(e.xhrResponse.responseJSON);
    } else {
      throw e;
    }
  }

  static #showForbiddenAuthenticationError(response) {
    const groupName = response.groupName;

    if (response.reason === "expired") {
      MessageModal.showCustomMessage(
        "Invalid URL",
        `Oops! Looks like this URL is no longer valid. If you believe this is a mistake, please contact the ${groupName}.`,
      );
    } else if (response.reason === "completed") {
      MessageModal.showCustomMessage(
        "Already Submitted",
        `Oops! Looks like your response has already been recorded. If you believe this was a mistake, please contact the ${groupName}.`,
      );
    }
  }
}

module.exports = PortalController;

const PortalApiCalls = require("./portalApiCalls");
const UrlRoutes = require("../routes/urlRoutes");
const TokenManager = require("../login/tokenManager");
const ApiError = require("../errors/apiError");
const MessageModal = require("../modals/messageModal");
const FormSettings = require("../settings/formSettings");
