"use strict";

var BasemapFunctions = function () {
  var setBasemap = function (style, mapId) {
    Tree.set(["basemap", mapId], style);

    var name = basemapIdToName(style);
    setBasemapDropdownButtonText(mapId, name);
    if (!Progeny.activeHasProgeny()) setBasemapDropdownSelection(mapId, style);
    handleDisplayWhiteText(style);
  };

  var setBasemapDropdownButtonText = function (mapId, basemapName) {
    $(`#${mapId}`)
      .find(".basemap-button")
      .html(`${basemapName} <span class="glyphicon glyphicon-menu-down"></span>`);
  };

  var setBasemapDropdownSelection = function (mapId, style) {
    var $selectedLi = $(`#${mapId}`)
      .find(`.basemap-dropdown a[data-style="${style}"]`)
      .closest("li");
    if ($selectedLi.length > 0) Dropdown.select($selectedLi);
  };

  var basemapIdToName = function (id) {
    if (id.includes(" ") || !/([A-Z])/g.test(id)) {
      console.error(`Unexpected basemap id: ${id}`);
    }
    if (id.length < 2) return id;
    return id.replace(/([A-Z])/g, " $1").slice(1);
  };

  var handleDisplayWhiteText = function (style) {
    const whiteTextSet = ["Imagery", "DarkGray", "Hybrid"];
    const useWhiteText = whiteTextSet.includes(style);
    MapTitle.toggleUseWhiteText(useWhiteText);
  };

  return {
    setBasemap,
  };
};

module.exports = BasemapFunctions();

const Tree = require("../../tree");
const MapTitle = require("./mapTitle");
const Dropdown = require("../general/dropdown");
const Progeny = require("../../login/progeny");
