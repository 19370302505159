"use strict";

const LayerSelection = function () {
  let $container;

  var show = function ($containerArg, setLayerFn) {
    setLayerHandler = setLayerFn;
    $container = $containerArg;
    const selectedLayer = _getDefaultLayer();
    $container.html(
      nunjucks.render("dataImport/layerSelection.njk", {
        layerOptions: DataImportConfig.getLayerOptions(),
        selectedLayer: selectedLayer.value,
      }),
    );
    setLayerFn(selectedLayer);
    $container.on("input", "[name='layer-selection']", setLayerSelection);
  };

  var _getDefaultLayer = function () {
    let selectedLayerValue;
    if (Tree.get("tool") !== "home") {
      selectedLayerValue = DataViewFunctions.getCurrentDataViewProperty("defaultImportLayer");
    }
    if (selectedLayerValue) {
      const selectedLayer = getLayerByValue(selectedLayerValue);
      if (selectedLayer.enabled) {
        return selectedLayer;
      }
    }
    return DataImportConfig.getLayerOptions().filter((option) => option.enabled)[0];
  };

  var getLayerByValue = function (value) {
    return DataImportConfig.getLayerOptions().find((layer) => layer.value === value);
  };

  var setLayerSelection = function (e) {
    const selectedLayerValue = $(e.currentTarget).val();
    setLayerHandler(getLayerByValue(selectedLayerValue));
  };

  var setLayerHandler = function () {
    throw "Handler not set.";
  };

  var cleanUp = function () {
    $container.off("click", "[name='layer-selection']", setLayerSelection);
    $container.empty();
  };

  var getLayerImportType = function (layer) {
    const options = DataImportConfig.getLayerOptions();
    return options.find((option) => option.value === layer)["type"];
  };

  return {
    cleanUp,
    show,
    getLayerImportType,
    _getDefaultLayer,
  };
};

module.exports = LayerSelection();

const Tree = require("../tree");
const DataImportConfig = require("../config/dataImportConfig");
const DataViewFunctions = require("../dataViewFunctions");
