"use strict";

const projectDeliveryBmpsPopup = function () {
  const layerName = "projectDeliveryBmps";

  var getPopupHtml = function (props) {
    const dataView = Tree.get("dataView");
    const hideScore = dataView === "scenarios" && ["completed", "certified"].includes(props.phase);
    const showProjectName = dataView === "scenarios";
    const showEditInventory = dataView === "construction-project-delivery";
    const showPerformance = dataView === "scenarios";
    const showCurrentPerformance = ["completed", "certified"].includes(props.phase);
    const showModeledCondition = dataView === "scenarios";
    const showZinc = ToolSettings.getSetting("hasZincCoefficient");

    const properties = {
      layerName,
      props,
      hideScore,
      showProjectName,
      showEditInventory,
      showPerformance,
      showModeledCondition,
      showZinc,
      showCurrentPerformance,
    };

    return PopupContent.getLayerPopup(properties, layerName);
  };

  var onPopupOpen = async function (properties, layerName) {
    const id = properties.id ?? properties.idbmp;
    const popupData = await ApiCalls.getScenarioBmpPopup(id);

    const html = getPopupHtml(popupData);
    $(".leaflet-popup-content").html(html);
  };

  return {
    getPopupHtml,
    onPopupOpen,
  };
};

module.exports = projectDeliveryBmpsPopup();

const ApiCalls = require("../../apiCalls");
const PopupContent = require("../../mapFunctions/popupContent");
const Tree = require("../../tree");
const ToolSettings = require("../../settings/toolSettings");
