"use strict";

const ResultModal = function () {
  const defaultCancelButtonText = "Cancel";
  const defaultSaveButtonText = "Save";
  const savePdfButtonText = "Save & Create PDF";

  var $modal;
  var inited = false;
  var saveResultHandler;
  var closeResultHandler;

  var init = function () {
    $modal = $("#routine-result-modal");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-button", saveButtonClick);
    $modal.on("click", ".save-button", saveButtonClick);
    $modal.off("click", ".cancel-button", cancelButtonClick);
    $modal.on("click", ".cancel-button", cancelButtonClick);
  };

  var loadExtraButtonListeners = function (extraButtons) {
    for (const i in extraButtons) {
      const button = extraButtons[i];

      if (button.callback) {
        const $button = $modal.find(`.modal-footer button[data-index="${i}"]`);
        $button.off("click");
        $button.on("click", button.callback);
      }
    }
  };

  /*
    @resultsData: [{ greenMessage: "text", redMessage: "text", issuesMessages: ["text", ...] }, ...]
  */
  var renderAndShow = function (
    resultsData,
    headerHtml,
    saveHandler,
    closeHandler,
    {
      inspectionName = "inspection",
      createsPdf = true,
      extraButtons = [], // { text: "Button Text", type: "primary", callback: () => {} }
    } = {},
  ) {
    if (!inited) init();
    saveResultHandler = saveHandler;
    closeResultHandler = closeHandler;

    const html = nunjucks.render("modals/resultModal.njk", {
      resultsData,
      headerHtml,
      extraButtons,
      ...getAllConfirmText(inspectionName, { createsPdf }),
    });
    $modal.html(html);
    loadExtraButtonListeners(extraButtons);
    $modal.modal("show");
    Analytics.sendScreenView("modal", "routine-result-modal");
  };

  var cancelButtonClick = function () {
    if (closeResultHandler) {
      close();
      closeResultHandler();
    } else {
      throw new Error("Close handler was not configured");
    }
  };

  var saveButtonClick = function () {
    if (saveResultHandler) {
      saveResultHandler();
      close();
    } else {
      throw new Error("Save handler was not configured");
    }
  };

  var close = function () {
    // Clear content
    $modal.modal("hide");
    inited = false;
  };

  var getAllConfirmText = function (inspectionName, { createsPdf = true } = {}) {
    return {
      confirmSaveText: getConfirmSaveText(inspectionName, { createsPdf }),
      confirmCancelText: `Click “${defaultCancelButtonText}” to return to the form and make edits.`,
      saveButtonText: getSaveButtonText({ createsPdf }),
      cancelButtonText: defaultCancelButtonText,
    };
  };

  var getConfirmSaveText = function (inspectionName, { createsPdf = true } = {}) {
    let result = `Click “${getSaveButtonText({ createsPdf })}” to save this ${inspectionName}`;

    if (createsPdf) {
      if (Session.notificationEnabled("pdf-letters")) {
        result += ` and generate a PDF that will be emailed to you shortly`;
      } else {
        result += ` and generate a PDF that will be available from the asset history shortly`;
      }
    } else {
      result += ` and close the form`;
    }

    result += ".";
    return result;
  };

  var getSaveButtonText = function ({ createsPdf } = {}) {
    return createsPdf ? savePdfButtonText : defaultSaveButtonText;
  };

  return {
    renderAndShow,
    getAllConfirmText,
  };
};

module.exports = ResultModal();

const Analytics = require("./analytics");
const Session = require("../login/session");
