"use strict";

var SbmpProgressPopup = function () {
  var bmpAssessmentHistory = null;

  var loadDomListeners = function () {
    $(".map-content").off("click", ".popup-history-button");
    $(".map-content").on("click", ".popup-history-button", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
  };

  var getSbmpProgressPopupHtml = function (bmp) {
    var popupProps = getSbmpProgressPopupProps(bmp);
    var props = $.extend({}, bmp, popupProps);
    bmpAssessmentHistory = props.bmpAssessmentHistory;
    return nunjucks.render("report/popups/sbmpProgressPopup.njk", props);
  };

  var getSbmpProgressPopupProps = function (bmp) {
    return {
      popupColor: BmpFcsIcon.getIconColor(bmp.bmpScore, bmp.planned),
      date: bmp.lastCompleteBmpObservation?.observationDate ?? null,
      showDate: bmp.lastCompleteBmpObservation
        ? bmp.lastCompleteBmpObservation.assessmentType !== "decline"
        : bmp.declinedCondition === null,
    };
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();

    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  var popupHistoryButtonClick = function () {
    var props = getInspectionHistoryProps();
    $(".popup-bmp-history").html(
      nunjucks.render("report/popups/bmpFcsAssessmentHistory.njk", props),
    );
    $(".popup-bmp-main").hide();
    $(".popup-history-button").hide();
    $(".popup-bmp-history").show();
    $(".popup-back-button").show();
  };

  var getInspectionHistoryProps = function () {
    const props = {};
    props.assessments = bmpAssessmentHistory;
    return props;
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-bmp-history").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-history-button").show();
    $parent.find(".popup-bmp-main").show();
  };

  return {
    getSbmpProgressPopupHtml,
    getPopupOptions,
    loadDomListeners,
  };
};

module.exports = SbmpProgressPopup();

const BmpFcsIcon = require("./bmpFcsIcon");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
