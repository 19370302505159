"use strict";

const MultipleObservation = function () {
  var addObservation = function ($button) {
    const $newObservation = $(getNewMultipleObservationCardHtml($button.data("constantId")));

    $button.before($newObservation);
    initializeTemporaryBmpInspection($newObservation);
  };

  var getNewMultipleObservationCardHtml = function (constantId) {
    return nunjucks.render("modals/newProjectInspection/multipleObservationCard.njk", {
      constant: NewProjectInspectionConstants.getConstantById(constantId),
      observation: createNewObservation(constantId),
      requiredObservationConditionOptions:
        NewProjectInspectionConstants.getRequiredObservationConditionOptions(),
    });
  };

  var createNewObservation = function (constantId) {
    const newObservation = getNextObservation(constantId);

    NewProjectInspectionController.setFormData(
      ["inspectionObservations", constantId, newObservation.index],
      newObservation,
    );

    return newObservation;
  };

  var getNextObservation = function (constantId) {
    const existingInspectionObservations =
      NewProjectInspectionController.getAllData()["inspectionObservations"]?.[constantId] ?? [];
    const existingOpenFindings =
      NewProjectInspectionController.getAllData()["openFindings"]?.[constantId] ?? [];
    const allExistingObservations = existingOpenFindings.concat(existingInspectionObservations);

    allExistingObservations.push({});
    NewProjectInspectionConstants.indexObservationArray(allExistingObservations);

    return allExistingObservations[allExistingObservations.length - 1];
  };

  var initializeTemporaryBmpInspection = function ($inspection) {
    const formKey = NewProjectInspectionController.getFormKey();

    LocationButton.initializeButtons($inspection, formKey);
    FormPhotos.unloadListeners($inspection.find(".form-photos"));
    FormPhotos.initializeFormPhotos($inspection.find(".form-photos"), formKey);
    CollapsingCard.initializeCard($inspection);
  };

  var onDeleteObservation = function () {
    const $deleteButton = $(this);
    const findingsCode = $deleteButton.closest(".multi-obs-card").data("findingsCode");
    setObservationDeleted($deleteButton.data("id"), findingsCode);
    $deleteButton.closest(".card").remove();
  };

  var setObservationDeleted = function (observationPath, findingsCode) {
    const path = Form.getPathPartsFromName(observationPath);
    NewProjectInspectionController.setFormData([...path, "deleted"], true);
    NewProjectInspectionController.setFormData([...path, "findingsCode"], findingsCode);
  };

  return {
    initializeTemporaryBmpInspection,
    addObservation,
    onDeleteObservation,
  };
};

module.exports = MultipleObservation();

const CollapsingCard = require("../../general/collapsingCard");
const Form = require("../../general/form");
const FormPhotos = require("../../general/formPhotos");
const LocationButton = require("../../general/locationButton");
const NewProjectInspectionController = require("./newProjectInspectionController");
const NewProjectInspectionConstants = require("./newProjectInspectionConstants");
