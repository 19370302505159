"use strict";

const EsriConfig = {
  soilImpervious: {
    ids: { soil: 0, impervious: 1, trash: 2, landuse: 3, catchment: 4, streams: 5 },
    filters: {
      catchments: "CATCH_ID",
      drainsToRw: "DRAINS_TO_RW",
      drainsToC: "DRAINS_TO_C",
      reportYearFirstValid: "reporting_year_first_valid",
      reportYearNotValid: "reporting_year_not_valid",
    },
  },
  trash: {
    ids: {
      trashProgress: 0,
      trashCondition: 1,
      litterHotspot: 2,
      trashConditionCertainty: 3,
      progress: 4,
      condition: 5,
      hotspot: 6,
      conditionCertainty: 7,
    },
    filters: {
      catchments: "catch_name",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
    },
  },
  simLayer: {
    webmap: "9b990a663b14473294c300981125dc25",
  },
  parcelsPlu: {
    ids: {
      parcels_plu: [0, 1],
    },
    filters: {
      catchments: "catchid",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      reportYearFirstValid: "reporting_year_first_valid",
      reportYearNotValid: "reporting_year_not_valid",
    },
  },
};

module.exports = EsriConfig;
