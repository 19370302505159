const pageGroupConfig = {
  maps: {
    pages: ["report", "chart", "summary", "inputs", "plan"],
    yearDropdown: true,
    dataViewDropdown: true,
    breadCrumbsPageLink: "submit",
  },
  landing: {
    pages: ["submit"],
    yearDropdown: true,
    dataViewDropdown: false,
    breadCrumbsPageLink: null,
  },
  settings: {
    pages: ["groupSettings"],
    yearDropdown: true,
    dataViewDropdown: false,
    breadCrumbsPageLink: "submit",
  },
};

module.exports = pageGroupConfig;
