"use strict";

const Infiltration = function () {
  var sameLocIdAndTimeExists = function (record, existingData) {
    if (!existingData) {
      return false;
    }
    var locid = String(record.inf_locid);
    var time = String(record.inf_time);
    return existingData.some(
      (inf) => String(inf.inf_locid) === locid && String(inf.inf_time) === time,
    );
  };

  return {
    sameLocIdAndTimeExists,
  };
};

module.exports = Infiltration();
