"use strict";

var BmpFcsIcon = function () {
  var getTableIcon = function (score, isCentralized, isFcs, isDue, planned, fixedColor) {
    var color = fixedColor ? fixedColor : getIconColor(score, planned);
    var classNamesArray = [getTableClass(isFcs, isCentralized), color];

    return {
      iconClass: classNamesArray.join(" "),
    };
  };

  var getIconMarker = function (
    score,
    isCentralized,
    abbreviation,
    isFcs,
    isDue,
    phase,
    fixedColor = null,
    mapZoom,
  ) {
    var color = fixedColor ? fixedColor : getIconColor(score, phase);
    var classNamesArray = getMarkerColorClassArray(color, isFcs, isCentralized);

    return newIconMarker(classNamesArray, isCentralized, abbreviation, isFcs, mapZoom);
  };

  var getMarkerColorClassArray = function (color, isFcs, isCentralized) {
    var classNamesArray = ["bmp-marker-icon"];

    if (isFcs) {
      classNamesArray.push(isCentralized ? "bmp-l fcs-l-" + color : "fcs-s-" + color);
    } else {
      classNamesArray.push(isCentralized ? "bmp-l bmp-l-" + color : "bmp-s-" + color);
    }

    return classNamesArray;
  };

  var getTableClass = function (isFcs, isCentralized) {
    var className;

    if (isFcs) {
      className = "fcs-badge";
    } else {
      className = "bmp-badge";
    }

    if (isCentralized) {
      className += "-square";
    } else {
      className += "-circle";
    }

    return className;
  };

  var newIconMarker = function (classNamesArray, isCentralized, abbreviation, isFcs, zoom) {
    var BMPIcon = L.DivIcon.extend({
      createShadow: function () {
        return this._createIcon("shadow");
      },
    });

    const updatedIconProps = RamMuniBmpIcon._updateIconPropsOnZoom(zoom);
    const updatedIconAnchors = RamBmpFcsIcon.updateIconAnchorOnZoom(isCentralized, zoom);

    return new BMPIcon({
      className: classNamesArray.join(" "),
      iconSize: updatedIconProps.size,
      iconAnchor: updatedIconAnchors.iconAnchor,
      popupAnchor: updatedIconAnchors.popupAnchor,
      html: "<span class='" + updatedIconProps.textCSSClass + "'>" + abbreviation + "</span>",
    });
  };

  var getUnsavedIconMarker = function () {
    var classNamesArray = getMarkerColorClassArray("blue", false, false);

    return newIconMarker(classNamesArray, false, "", false, "");
  };

  var getIconColor = function (score, phase, hex = false) {
    if (phase === undefined) {
      throw new Error("No phase passed.");
    }

    if (["planning", "planned"].includes(phase)) {
      return hex ? "#FFFFFF" : "white";
    } else if ("shelved" === phase) {
      return hex ? "#FFFFFF" : "checkerboard";
    } else if ("rejected" === phase) {
      return hex ? "#FFFFFF" : "crossed";
    }

    return MapStyles.getConditionColor(score, hex);
  };

  var getBmpDrainageAreaStyle = function (score, planned, isEnabled) {
    const color = getIconColor(score, planned, true);
    const opacity = isEnabled ? 0.8 : 0;

    var styleProps = {
      color: color,
      fillOpacity: opacity,
      fillColor: color,
      weight: 0,
    };

    return styleProps;
  };

  return {
    getIconMarker,
    getUnsavedIconMarker,
    getTableIcon,
    getBmpDrainageAreaStyle,
    getIconColor,
  };
};

module.exports = BmpFcsIcon();

const RamBmpFcsIcon = require("../../bmpfcs/bmpFcsIcon");
const RamMuniBmpIcon = require("../../muni/bmp/muniBmpIcon");
const MapStyles = require("../mapFunctions/mapStyles");
