"use strict";

const MuniManholeDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareMuniManhole,
        template: "list/muniManholeDataList.njk",
        layerName: "muniManhole",
      };
      DataList.init(config);
      initialRender();
      loadListeners();
      DataList.loadDomListeners();
    }
  };

  var loadListeners = function () {
    $(".data-list-container").off("click", ".map-button");
    $(".data-list-container").on("click", ".map-button", showMarkerOnMap);
  };

  var showMarkerOnMap = function () {
    var $tr = $(this).parents("tr");
    var id = $tr.data("id");

    MapFunctions.openPopupById(id);
    const mapButtonContainer = $(this).parents("td");
    DataList.addAnalyticsToMapIconOnAddress(mapButtonContainer.hasClass("address-col"));
  };

  var initialRender = function () {
    DataList.setSorting("manholeName", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareMuniManhole = function (muniManholes) {
    sortMuniManholesBy(muniManholes);

    return {
      rows: muniManholes,
      options: {},
    };
  };

  var sortMuniManholesBy = function (muniManholes) {
    const alphaSorter = SortUtilities.alphaSorter;

    var sortersDict = {
      manholeName: alphaSorter,
      address: alphaSorter,
      manholeType: alphaSorter,
      coverShape: alphaSorter,
      cof: SortUtilities.cofSorter,
    };
    DataList.sortWithDict(muniManholes, sortersDict);
  };

  return {
    init,
  };
};

module.exports = MuniManholeDataListController();

const DataList = require("../mapFunctions/dataList");
const SortUtilities = require("../general/sortUtilities.js");
const MapFunctions = require("../mapFunctions/mapFunctions.js");
