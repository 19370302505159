"use strict";

const Auth = function () {
  const validateToken = function (params, callback) {
    var token = params.token ?? TokenManager.loadToken();
    var tokenData;

    // if the token exists, send to auth provider for verification
    if (token) {
      tokenData = TokenManager.getTokenData(token);
      ApiCalls.validateToken(token)
        .done(function (response) {
          console.info(
            `%cAPI build: %c${response}`,
            "font-weight: bold;",
            `
                             border: 1px solid black;
                             border-radius: 4px;
                             background-color: #0b3e5e;
                             color: white;
                             padding: 4px 6px;
                             `,
          );
          if (params.token) {
            TokenManager.saveToken(token);
          }

          callback(TokenManager.getTokenData(token));
        })
        .fail(function (xhr) {
          if ([400, 401, 500].includes(xhr.status)) {
            TokenManager.removeToken();
            callback(false);
          } else {
            callback(tokenData);
          }
        });
    } else {
      // if there is no token, ask user for credentials
      callback(false);
      LoadingScreen.hide();
    }
  };

  // send credentials to auth provider to get a new token
  const getToken = function (username, password, callback) {
    ApiCalls.authenticate(username, password)
      .done(function (response) {
        if (response.token) {
          TokenManager.saveToken(response.token);
          callback(TokenManager.getTokenData(response.token));
        } else if (response.authnRequest) {
          SingleSignOn.startSamlLogin(response);
        } else {
          callback(false);
        }
      })
      .fail(function (xhr) {
        callback(false, xhr.status);
      });
  };

  const getAlias = function (params, callback) {
    ApiCalls.user({ ...params, token: TokenManager.loadToken() })
      .done(function (response) {
        if (response.token) {
          TokenManager.saveToken(response.token);
          callback(TokenManager.getTokenData(response.token));
        } else {
          callback(false);
        }
      })
      .fail(function () {
        callback(false);
      });
  };

  const revokeToken = function (callback) {
    ApiCalls.revokeToken({ token: TokenManager.loadToken() })
      .done(function () {
        callback(true);
      })
      .fail(function () {
        callback(false);
      });
    TokenManager.removeToken();
  };

  const updatePassword = function (params, callback) {
    ApiCalls.password({ ...params, token: TokenManager.loadToken() })
      .done(function () {
        // try to get a new token
        getToken(
          {
            username: params.email,
            password: params.newPassword,
          },
          callback,
        );
      })
      .fail(function (xhr) {
        callback({ error: xhr.responseText });
      });
  };

  return {
    validateToken: validateToken,
    getToken: getToken,
    revokeToken: revokeToken,
    updatePassword: updatePassword,
    getAlias: getAlias,
  };
};

module.exports = Auth();

const LoadingScreen = require("../general/loadingScreen");
const ApiCalls = require("../apiCalls");
const TokenManager = require("./tokenManager");
const SingleSignOn = require("./sso");
