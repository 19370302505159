"use strict";

const CollapsingCard = function () {
  var initializeCards = function ($parent) {
    const cards = [];

    for (const card of $parent.find(".card")) {
      cards.push(initializeCard($(card)));
    }

    return cards;
  };

  var initializeCard = function ($card) {
    return new Card($card);
  };

  return {
    initializeCards,
    initializeCard,
  };
};

const Card = function ($card) {
  var init = function () {
    assertIsCard();
    loadListeners();
    setStatusText();
  };

  var assertIsCard = function () {
    if ($card?.length !== 1) {
      throw new Error(`${$card} must be a single jQuery element.`);
    }
    if (!$card.hasClass("card")) {
      throw new Error(`${$card?.html()} is not a card.`);
    }
  };

  var loadListeners = function () {
    $card.off("show.bs.collapse", onShowDropdown);
    $card.on("show.bs.collapse", onShowDropdown);

    $card.off("hide.bs.collapse", onHideDropdown);
    $card.on("hide.bs.collapse", onHideDropdown);
  };

  var setStatusText = function () {
    const $status = $card.find("> a .card-status");
    const statusText = getTrackedInputStatus() ?? countSubCards() ?? getCustomStatusText() ?? null;
    const showStatus = statusText !== null && getCardState() === "close";

    $status.text(showStatus ? statusText : "");
    $status.toggle(showStatus);
  };

  var countSubCards = function () {
    const subCardsName = $card.data("subCardsName");

    if (!subCardsName) {
      return null;
    }

    const subCardsCount = $card
      .find(`.card`)
      .filter((i, subCard) => $(subCard).data("cardState") !== "start").length;

    if (subCardsCount === 0) {
      return null;
    }

    return `${subCardsCount} ${NunjucksFilters.pluralIfMultiple(subCardsName, subCardsCount)}`;
  };

  var getTrackedInputStatus = function () {
    const trackedInputName = $card.data("trackedInputName");

    if (!trackedInputName) {
      return null;
    }

    const $trackedInput = $card.find(`[name="${trackedInputName}"]`);

    if ($trackedInput.length === 0) {
      console.warn(
        `Collapsing card can't find input named "${trackedInputName}" to display status for.`,
      );
      return;
    }

    return getStatusTextForInput($trackedInput);
  };

  var getCustomStatusText = function () {
    return $card.data("customStatusText") || null;
  };

  var getStatusTextForInput = function ($inputs) {
    const label = getLabelByInputType($inputs);
    const legend = $inputs.first().closest("fieldset").find("legend").text().trim();

    if (!legend || !label) {
      return null;
    }

    return `${legend} - ${label}`;
  };

  var getLabelByInputType = function ($inputs) {
    const input = $inputs[0];

    if (input.tagName === "INPUT" && input.type === "radio") {
      return getSelectedRadioLabel($inputs);
    } else if (input.tagName === "SELECT") {
      return getSelectedSelectLabel($inputs);
    }

    throw new Error(`Collapsing card can not track input "${input.tagName}".`);
  };

  var getSelectedRadioLabel = function ($radios) {
    return $radios.filter(":checked")?.[0]?.labels?.[0]?.textContent?.trim();
  };

  var getSelectedSelectLabel = function ($select) {
    if ($select.prop("value") === "no-selection") {
      return null;
    }

    return $select?.[0]?.selectedOptions?.[0]?.text?.trim();
  };

  var onShowDropdown = function (e) {
    e.stopPropagation();
    _setCardState("open");
  };

  var onHideDropdown = function (e) {
    e.stopPropagation();
    _setCardState("close");
  };

  var _setCardState = function (newState) {
    if (getCardState() === "start") {
      removeStartState();
    }

    getCollapseButton().toggleClass("open", newState === "open");
    $card.data("cardState", newState);
    setStatusText();
  };

  var removeStartState = function () {
    const newCollapseButtonHtml = nunjucks.render("macros/macroTemplates/cardCollapseButton.njk", {
      cardState: "open",
    });
    getCollapseButton($card).replaceWith(newCollapseButtonHtml);
  };

  var getCollapseButton = function () {
    return $card.find("> a:first-child span");
  };

  var getCardState = function () {
    return $card.data("cardState");
  };

  init();

  return {
    _setCardState,
  };
};

module.exports = CollapsingCard();

const NunjucksFilters = require("./nunjucksFilters");
