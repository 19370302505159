"use strict";

L.Control.DrawMethodSelector = L.Control.extend({
  initialize: function (props) {
    this._props = props;
    this._map = null;
    this._methodSelectorDiv = null;
    this._method = props.defaultValue;
  },
  onAdd: function (map) {
    this._map = map;
    map.methodSelection = this;
    this._methodSelectorDiv = L.DomUtil.create("div", "method-selection-control");

    // apply listeners
    L.DomEvent.disableClickPropagation(this._methodSelectorDiv);
    this.addToggleListeners(this._methodSelectorDiv);
    return this._methodSelectorDiv;
  },
  onRemove: function (map) {
    delete map.methodSelection;
  },
  selectMethodRadio: function (method) {
    $(this._methodSelectorDiv).find(`[value=${method}]`).prop("checked", true);
  },
  addToggleListeners: function (element) {
    const $header = $(element).find(".method-header");
    const $radio = $(element).find(`[name="tool-selection"]`);
    $header.off("click", this.handleToggleHeaderSlide);
    $radio.off("click", this.handleToggleToolLayer);

    $header.on("click", this.handleToggleHeaderSlide);
    $radio.on("click", this.handleToggleToolLayer);
  },
  handleToggleHeaderSlide: function (e) {
    const $header = $(e.currentTarget);
    $header.find(".expand-arrow").toggleClass("open");
    $header.siblings(".method-body").slideToggle(400);
  },
  handleToggleToolLayer: function (e) {
    const selectionVal = $(e.currentTarget).val();
    AreaSelectionMap.setActiveMethodLayer(selectionVal);
  },
  show: function () {
    $(this).show();
  },
  hide: function () {
    $(this).hide();
  },
  getSelectedMethod: function () {
    return "drawing-tools";
  },
  options: {
    position: "topright",
  },
});

L.Control.drawMethodSector = function (props) {
  return new L.Control.DrawMethodSelector(props);
};

const AreaSelectionMap = require("./areaSelectionMap");
