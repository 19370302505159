"use strict";

const MuniManholePopup = function () {
  var getPopupHtml = function (properties) {
    properties.permissions = UserPermissions.getAllPermissions();
    return PopupContent.getLayerPopup(properties, "muniManhole");
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteManhole(data.id, data.manholeName, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deleteManhole = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Manhole ${name}? Click "Delete" to permanently delete the Manhole. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteManhole(id);
        invalidateLayerDataFunction(popupCloseButn);
        _closePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "manholes");
  };

  var _closePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var onPopupOpen = async function (properties, event = null) {
    const id = properties.id;
    var data = await getPopupData(id);
    const html = getPopupHtml(data);
    const layerModule = LayerFunctions.getLayerModule("muniManhole");
    const $popup = $(".leaflet-popup-content");
    $popup.html(html);

    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var getPopupData = async function (id) {
    const popupData = await ApiCalls.getMuniManholePopup(id);
    popupData.icon = RoutineMaintenanceIcon.getMaintenanceIcon(
      "muni-manhole",
      popupData.lastMaintenanceInspection,
      popupData.lastMaintenanceInspection?.needsRepair,
    );

    return popupData;
  };

  return {
    getPopupHtml,
    onPopupOpen,
    onPopupOpenWithData,
  };
};

module.exports = MuniManholePopup();

const ApiCalls = require("../apiCalls");
const PopupContent = require("../mapFunctions/popupContent");
const MessageModal = require("../modals/messageModal");
const LayerFunctions = require("../layerFunctions");
const UserPermissions = require("../login/userPermissions");
const Analytics = require("../general/analytics");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
