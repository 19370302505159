"use strict";

const CaseInsensitiveMap = function (initialEntries = {}) {
  this.get = function (key) {
    const genericKey = this._getGenericKey(key);
    return this.entries[genericKey];
  };

  this.set = function (key, value) {
    const genericKey = this._getGenericKey(key);
    this.entries[genericKey] = value;
  };

  this._getGenericKey = function (key) {
    return key.toLowerCase().replace(/[_-]/g, "");
  };

  this.has = function (key) {
    const genericKey = this._getGenericKey(key);
    return genericKey in this.entries;
  };

  this.toObj = function () {
    return { ...this.entries };
  };

  this.entries = {};
  Object.keys(initialEntries).forEach((key) => {
    this.set(key, initialEntries[key]);
  });
};

module.exports = CaseInsensitiveMap;
