"use strict";

const UserPermissions = function () {
  var permissions;

  var saveUserPermissions = function (permissionsArray) {
    permissions = permissionsArray;
  };

  var getAllPermissions = function () {
    return structuredClone(permissions);
  };

  var getPermission = function (permissionKey) {
    const permission = permissions?.[permissionKey];
    if (permission === undefined) {
      console.error(`${permissionKey} on token was unexpectedly undefined.`);
    }
    return permission;
  };

  // Based on API UserRole@doesNotIncludePhase
  var doesNotIncludePhase = function (pahsePermission, phase) {
    const phasePermissions = getPermission("all_phase_permissions")?.[pahsePermission];

    if (!phasePermissions) {
      return true;
    }

    return !phasePermissions.includes(phase);
  };

  return {
    getAllPermissions,
    getPermission,
    saveUserPermissions,
    doesNotIncludePhase,
  };
};

module.exports = UserPermissions();
