"use strict";

const OutfallRoutineMaintenanceController = require("../../outfalls/routineMaintenance/outfallRoutineMaintenanceController");
const RouteFunctions = require("./routeFunctions");

const OutfallRoutes = function () {
  const routineInspectionRoute = {
    handler: function ({ data, params }) {
      return RouteFunctions.openNewOrExistingInspection({
        openInspectionFn: OutfallRoutineMaintenanceController.showOutfallRoutineMaintenanceModal,
        resourceKey: "outfallRoutineMaintenance",
        assetId: data.id,
        inspectionId: data.inspectionId,
        params: params,
      });
    },
    hooks: {
      leave: RouteFunctions.leaveTabbedModalRoute,
    },
    data: {
      dataView: "muni-outfalls",
    },
  };

  const routineInspectionDryWeatherRoute = {
    handler: routineInspectionRoute.handler,
    hooks: routineInspectionRoute.hooks,
    data: {
      dataView: "dry-weather",
    },
  };

  return {
    routineInspectionRoute,
    routineInspectionDryWeatherRoute,
  };
};

module.exports = OutfallRoutes();
