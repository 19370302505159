"use strict";

const PhotoCarousel = function () {
  let $modal;
  let inited = false;
  const handlerConfiguration = {
    caption: null,
    rotate: null,
    delete: null,
    setProfile: null,
    showProfile: null,
  };

  var init = function () {
    if (inited) {
      return;
    }
    $modal = $("#photos-modal");
    loadListeners();
    inited = true;
  };

  var loadListeners = function () {
    $(".delete-photo").off();
    $(".delete-photo").on("click", confirmDeletePhoto);

    $(".rotate-photo").off("click");
    $(".rotate-photo").on("click", rotatePhotoClick);

    $(".set-profile").off("click", setProfilePhoto);
    $(".set-profile").on("click", setProfilePhoto);

    $("#bmpPhotoCarousel").off("click", ".js-captionContent");
    $("#bmpPhotoCarousel").on("click", ".js-captionContent", captionClick);

    // bootstrap is supposed to handle this with data-keyboard="true" but it doesn't
    $modal.off("keydown");
    $modal.on("keydown", navigateWithKeyboardArrows);

    $modal.off("slide.bs.carousel", handleCarouselSlide);
    $modal.on("slide.bs.carousel", handleCarouselSlide);

    $modal.on("hidden.bs.modal", resetActiveItems);
  };

  var renderHtml = function (photos) {
    var indicators = nunjucks.render("popups/photos/carouselIndicators.njk", {
      photos: photos,
      active: $(this).data("index"),
    });
    var slides = nunjucks.render("popups/photos/carouselSlides.njk", {
      photos: photos,
      active: $(this).data("index"),
    });
    $modal.find(".carousel-indicators").empty().html(indicators);
    $modal.find(".carousel-inner").empty().html(slides);
  };

  var showPhotoCarouselModalFromPhotoId = function (showFromId) {
    const item = `[data-id=${showFromId}]`;
    loadFullImage(item);
    setCarouselInnerActive(item);
    setCarouselIndicatorsActive(item);
    showSetProfilePhoto(showFromId);
    $modal.modal("show");
  };

  var showPhotoCarouselModalFromIndex = function (index) {
    const $item = $($modal.find(`.carousel-inner .item`)[index]);
    $item.addClass("active");
    loadFullImage($item);
    $($modal.find(`.carousel-indicators li`)[index]).addClass("active");
    $modal.modal("show");
  };

  var handleCarouselSlide = function (target) {
    const id = $(target.relatedTarget).data("id");
    showSetProfilePhoto(id);
    loadFullImage(target.relatedTarget);
  };

  var loadFullImage = function (item) {
    const $img = $(item).find(`img`);
    if ($img.attr("src") === $img.data("full")) return;
    $img.attr("src", $img.data("full"));
  };

  var showSetProfilePhoto = function (itemId) {
    const handleProfilePicture = handlerConfiguration.showProfile !== null;
    $modal.find(".set-profile").toggleClass("default-hidden", !handleProfilePicture);
    if (handleProfilePicture) {
      handlerConfiguration.showProfile(itemId);
    }
  };

  var setCurrentProfileItem = function (setActive) {
    const $profileStar = $modal.find(".set-profile span");
    $profileStar.toggleClass("glyphicon-star", setActive);
    $profileStar.toggleClass("glyphicon-star-empty", !setActive);
  };

  var setCarouselInnerActive = function (item) {
    $modal.find(`.carousel-inner ${item}`).first().addClass("active");
  };

  var setCarouselIndicatorsActive = function (item) {
    $modal.find(`.carousel-indicators ${item}`).first().addClass("active");
  };

  var load = function (photos, configuration, readOnly = false) {
    init();
    configureHandlers(configuration);
    renderHtml(photos);
    Session.isRegulator(false) || readOnly ? disablePhotoModalButtons() : enablePhotoModalButtons();

    return $modal;
  };

  var configureHandlers = function (configuration) {
    Object.keys(handlerConfiguration).forEach(function (key) {
      if (configuration && configuration[key] !== undefined) {
        handlerConfiguration[key] = configuration[key];
      } else {
        handlerConfiguration[key] = null;
      }
    });
  };

  var disablePhotoModalButtons = function () {
    $("#photos-modal").find(".rotate-photo, .delete-photo").hide();
    $("#bmpPhotoCarousel").off("click", ".js-captionContent");
  };

  var enablePhotoModalButtons = function () {
    $modal.find(".rotate-photo, .delete-photo").show();
    $("#bmpPhotoCarousel").off("click", ".js-captionContent");
    $("#bmpPhotoCarousel").on("click", ".js-captionContent", captionClick);
  };

  var confirmDeletePhoto = function () {
    MessageModal.showConfirmWarningModal("Delete this image?", deletePhoto, "Cancel", "Delete");
  };

  var setProfilePhoto = function (e) {
    if (handlerConfiguration.setProfile !== null) {
      const activeId = getActiveSlide().data("id");
      handlerConfiguration.setProfile(activeId);
    }
  };

  var deletePhoto = function () {
    // jump to next slide, first slide or close the preview box
    var $activeSlide = getActiveSlide();
    var $activeIndicator = getActiveIndicator();
    var next = $activeSlide.next();
    var first = $activeSlide.siblings().first();
    if (next.length) {
      next.addClass("active");
      $activeIndicator.next().addClass("active");
    } else if (first.length) {
      first.addClass("active");
      $activeIndicator.siblings().first().addClass("active");
    } else {
      $modal.modal("hide");
    }
    if (handlerConfiguration.delete !== null) {
      handlerConfiguration.delete($activeSlide);
    }
    // remove slide
    $activeIndicator.remove();
    $activeSlide.remove();
  };

  var rotatePhotoClick = function (e) {
    const $rotateButton = $(".rotate-photo");
    if ($rotateButton.hasClass("rotating")) {
      return;
    }
    $rotateButton.addClass("rotating");

    const direction = $(this).data("rotate");
    const degrees = direction === "left" ? -90 : 90;
    const $activeImage = $(".item.active");
    let currentRotation = $activeImage.data("rotation") || 0;
    currentRotation += degrees;
    $activeImage.data("rotation", currentRotation);
    $activeImage.find("img").css({
      transform: "rotate(" + currentRotation + "deg)",
    });
    if (handlerConfiguration.rotate !== null) {
      handlerConfiguration.rotate($activeImage, direction, currentRotation);
    }
  };

  var restoreRotation = function () {
    const $rotateButton = $(".rotate-photo");
    $rotateButton.removeClass("rotating");
  };

  var resetActiveItems = function () {
    $modal.find(".carousel-inner .item, .carousel-indicators li").each(function () {
      $(this).removeClass("active");
    });
  };

  var navigateWithKeyboardArrows = function (e) {
    if (e.which === 39) {
      $modal.find("a.right").click();
    } else if (e.which === 37) {
      $modal.find("a.left").click();
    }
  };

  var captionClick = function () {
    var captionContent = $(this);
    var $activeSlide = getActiveSlide();
    const placeholder = Session.isRegulator ? '"add/edit caption here"' : "";
    var input = $(`<input type="text" class="form-control" placeholder=${placeholder} />`);
    var inputValue;
    var oldValue = captionContent.attr("title");

    captionContent.after(input);
    captionContent.hide();
    input.one("blur", function () {
      inputValue = input.val().trim();
      if (inputValue !== "" && inputValue !== oldValue) {
        captionContent.attr("title", inputValue);
        captionContent.text(inputValue);

        if (handlerConfiguration.caption !== null) {
          handlerConfiguration.caption($activeSlide, inputValue);
        }
      }
      input.remove();
      captionContent.show();
    });
    input.on("keyup", function (e) {
      if (e.keyCode == 13) {
        input.blur();
      }
    });
    input.val(oldValue).focus();
  };

  var getActiveSlide = function () {
    return $modal.find(".carousel-inner .item.active");
  };

  var getActiveIndicator = function () {
    return $modal.find(".carousel-indicators .active");
  };

  return {
    load,
    deletePhoto,
    rotatePhotoClick,
    restoreRotation,
    captionClick,
    showPhotoCarouselModalFromPhotoId,
    showPhotoCarouselModalFromIndex,
    getActiveSlide,
    getActiveIndicator,
    setProfilePhoto,
    setCurrentProfileItem,
  };
};

module.exports = PhotoCarousel();

const Session = require("../login/session");
const MessageModal = require("../modals/messageModal");
