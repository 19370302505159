"use strict";

function ActivityFilter() {
  const searchFields = ["activityName", "fullAddress"];
  let outreachTypeFormOptions;
  let activityGroupFormOptions;
  let mainSmartFilters;

  async function filterResourceDataHandler(unfilteredData, filters, loadingScreen) {
    return filterOffline(unfilteredData, filters);
  }

  function filterOffline(unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  }

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      activityAudience: ["targetOptions", "array"],
      activityStaff: ["targetOptions", "array"],
      activityPollutant: ["targetOptions", "array"],
      activityBehavior: ["targetOptions", "array"],
      receivingWater: (datum, filters) => _nullIsAllFilter("receivingWater", datum, filters),
      progeny: (datum, filters) => _nullIsAllFilter("progeny", datum, filters, parseInt),
      audienceType: "audienceType",
      outreachType: _outreachTypeFilter,
      activityGroup: _activityGroupFilter,
      activity: "activityKey",
      permits: "permit",
      date: _dateFilter,
      attendeeCount: passAttendeeCountFilter,
      impressionCount: passImpressionCountFilter,
      volunteerCount: passVolunteerCountFilter,
      otherFilters: (datum, filters) => {
        return ResourceFilters.passSearchStringFilter(datum, filters, searchFields);
      },
    };
  };

  function getProps() {
    const constants = ActivityConstants.get();
    const { receivingWaterOptions } = Filters.getSpatialArrays();

    return {
      activityAudienceOptions: ActivityInventoryInfo.mapTargetFields(constants.audience),
      activityStaffOptions: ActivityInventoryInfo.mapTargetFields(constants.staff),
      activityPollutantOptions: ActivityInventoryInfo.mapTargetFields(constants.pollutant),
      activityBehaviorOptions: ActivityInventoryInfo.mapTargetFields(constants.behavior),
      audienceTypeOptions: constants.audienceTypes,
      outreachTypeOptions: getOutreachOptions(constants.activities).getFormOptions(),
      activityGroupOptions: getActivityGroupOptions(constants.activities).getFormOptions(),
      activityOptions: getActivityOptions(constants.activities),
      permitOptions: constants.permits,
      receivingWaterOptions,
      progenyOptions: Progeny.getActiveGroupProgenyFormOptions(),
    };
  }

  function getOutreachOptions(activities) {
    if (outreachTypeFormOptions) {
      return outreachTypeFormOptions;
    }

    outreachTypeFormOptions = new GroupedFormOptions();

    for (const activity in activities) {
      const displayName = activities[activity].outreachType;
      outreachTypeFormOptions.add(displayName, activity);
    }

    return outreachTypeFormOptions;
  }

  function getActivityGroupOptions(activities) {
    if (activityGroupFormOptions) {
      return activityGroupFormOptions;
    }

    activityGroupFormOptions = new GroupedFormOptions();

    for (const activity in activities) {
      const displayName = activities[activity].activityGroup;
      activityGroupFormOptions.add(displayName, activity);
    }

    return activityGroupFormOptions;
  }

  function _outreachTypeFilter(datum, filters) {
    if (!filters.outreachType) {
      return true;
    }

    return filters.outreachType.some(function (outreachTypeIndex) {
      return outreachTypeFormOptions.has(outreachTypeIndex, datum.activityKey);
    });
  }

  function _activityGroupFilter(datum, filters) {
    if (!filters.activityGroup) {
      return true;
    }

    return filters.activityGroup.some(function (activityGroupIndex) {
      return activityGroupFormOptions.has(activityGroupIndex, datum.activityKey);
    });
  }

  function getActivityOptions(activities) {
    const options = [];

    for (const activity in activities) {
      options.push({
        name: activities[activity].displayName,
        value: activity,
      });
    }

    return options;
  }

  function _dateFilter(datum, filters) {
    return ResourceFilters.passDateRangeOverRangeFilter(
      datum.startDateIso,
      datum.endDateIso,
      filters.date?.from,
      filters.date?.to,
    );
  }

  function passAttendeeCountFilter(datum, filters) {
    return OfflineFilterFunctions.passMinMaxFilter(datum.attendeeCount, filters.attendeeCount);
  }

  function passImpressionCountFilter(datum, filters) {
    return OfflineFilterFunctions.passMinMaxFilter(datum.impressionCount, filters.impressionCount);
  }

  function passVolunteerCountFilter(datum, filters) {
    return OfflineFilterFunctions.passMinMaxFilter(datum.volunteerCount, filters.volunteerCount);
  }

  function _nullIsAllFilter(field, datum, filters, filterTransformCallback = null) {
    const value = datum[field];
    const filter = filters[field];

    if (!filter) {
      return true;
    } else if (value === undefined) {
      return false;
    }

    return filter.some(function (filterValue) {
      if (value === null) {
        return true;
      } else if (filterTransformCallback !== null) {
        filterValue = filterTransformCallback(filterValue);
      }

      return value.includes(filterValue);
    });
  }

  return {
    filterResourceDataHandler,
    getProps,
    getFilterConfigs,
    setSmartFilters,
    _nullIsAllFilter,
  };
}

module.exports = ActivityFilter();

const ResourceFilters = require("../filters/resourceFilters");
const ActivityInventoryInfo = require("./activityInventoryInfo");
const ActivityConstants = require("./activityConstants");
const GroupedFormOptions = require("../general/groupedFormOptions");
const OfflineFilterFunctions = require("../mapFunctions/offlineFilterFunctions");
const Filters = require("../mapFunctions/filters");
const Progeny = require("../login/progeny");
