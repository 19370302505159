"use strict";

const Tooltip = function () {
  const $tooltip = $("#custom-tooltip");
  const $tooltipContainer = $tooltip.find(".tooltip-container");
  let $outerContainer;
  let overflow;
  let timeout;

  const setTooltip = function (tooltipConfig) {
    const selector = tooltipConfig.selector;
    const text = tooltipConfig.text;
    const width = tooltipConfig.width;
    $outerContainer = $("html");

    $outerContainer.on("mouseover", selector, function () {
      showTooltip.call(this, text, width);
    });
    $outerContainer.on("mouseout", selector, hideTooltip);
  };

  const showTooltip = function (text, width) {
    const $item = $(this);
    const offset = $item.offset();

    setTooltipText(text);
    if (width) {
      setTooltipWidth(width);
    }
    setTooltipRelativePosition($item);

    timeout = setTimeout(function () {
      adjustPosition($item, offset);
      if ($item.is(":visible") && $item.is(":hover")) {
        $tooltip.css("visibility", "visible");
      }
    }, 400);

    overflow = $outerContainer.css("overflow");
    $outerContainer.css("overflow", "hidden");
  };

  const hideTooltip = function () {
    clearTimeout(timeout);
    $tooltip.css("visibility", "hidden");
    $tooltip.offset({ top: 0, left: -250 });
    $outerContainer.css("overflow", overflow);
  };

  const setTooltipText = function (text) {
    $("#custom-tooltip .tooltip-text").text(text);
  };

  const setTooltipWidth = function (width) {
    $tooltipContainer.css("width", width + "px");
  };

  const setTooltipRelativePosition = function ($item) {
    $tooltipContainer.css("left", $item.width() / 2 + "px");
    $tooltipContainer.css("top", -($tooltipContainer.height() + 5) + "px");
  };

  const adjustPosition = function ($item, offset) {
    $tooltip.offset({ top: offset.top, left: offset.left });
    if ($tooltipContainer.offset().left < 0) {
      $tooltipContainer.css("left", $tooltipContainer.width() / 2 + "px");
    }
  };

  return {
    setTooltip: setTooltip,
    hideTooltip: hideTooltip,
  };
};

module.exports = Tooltip();
