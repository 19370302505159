const DryWeatherOutfallGeoServerLayer = function () {
  const layerName = "dryWeatherOutfall";
  const layerKey = "dryWeatherOutfallLayer";

  var createMarkerFunction = function (feature, latlng) {
    const data = feature.properties;
    return DryWeatherIcon.getDryWeatherMarker(
      latlng,
      data,
      MapFunctions.resizeIconsOnZoom(
        Tree.get("zoomLevel"),
        40,
        20,
        "icon-text-large",
        "icon-text-medium",
      ),
    );
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const layer = new GeoServerMapLayer(layerName, layerKey, createMarkerFunction);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, layer);

    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeLayerData(data);
  };

  var organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    return {
      assetType: "outfall",
      id: data.id,
      geometry: geometry,
      outfallName: data.outfall_name,
      outfallType: data.outfall_type,
      cof: data.cof_final_text,
      phase: data.phase,
      responsiblePartyMaintenance: data.responsible_party_maintenance,
      dryWeatherCondition: data.dry_weather_condition,
      rotationAzimuth: data.rotation_azimuth,
      isDue: data.todo_is_monitoring_location,
      isHighPriority: ["high", "very-high"].includes(data.cof_final_text),
    };
  };

  return {
    getLayerData,
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
  };
};

module.exports = DryWeatherOutfallGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const DryWeatherIcon = require("./dryWeatherIcon");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
