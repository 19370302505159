"use strict";

const RegionDropdown = function () {
  var load = function (groups) {
    render(groups);
    loadDomListeners();
    loadTreeListeners();
  };

  var render = function (groups) {
    groups = groups.map((group) => {
      if (group.properties) return group.properties;
      return group;
    });
    const activeGroup = Tree.get("activeGroup");
    const activeGroupHasProgeny = Progeny.activeHasProgeny();
    const ancestorGroups = Progeny.getAncestors(activeGroup.groupId);
    const html = nunjucks.render("report/navbar/regionDropdown.html", {
      groups,
      ancestorGroups,
      activeGroup,
      activeGroupHasProgeny,
    });
    getRegionDropdown().html(html);
    handleDisablingRegionButtons();
  };

  var handleDisablingRegionButtons = function () {
    if (!Tree.get("activeTab") || !AnnualReports.isYearDataLoaded()) {
      return;
    }

    var groupIdsToDisable = [];
    // @TODO: Merge thse two groupIdsToDisable logic
    if (Progeny.rootGroupIsRegulator() && Progeny.rootGroupHasProgeny()) {
      groupIdsToDisable = getGroupIdsToDisableForRegulator();
    } else if (Progeny.activeHasProgeny()) {
      groupIdsToDisable = getGroupIdsToDisable();
    } else {
      return;
    }

    _disableGroupDomClick(groupIdsToDisable);
    _disableGroupMapPopups(groupIdsToDisable);
  };

  var getGroupIdsToDisableForRegulator = function () {
    return Progeny.getRootGroupProgeny()
      .filter((progeny) => {
        if (progeny.isBoundaryOnly) return true;
        return !hasSubmittedInYear(progeny.reports);
      })
      .map((progeny) => progeny.groupId);
  };

  var hasSubmittedInYear = function (reports, year) {
    if (!year) {
      year = AnnualReports.getSelectedYear();
    }

    var submittedReportInYear;
    if (Tree.get("activeTab") === "submit") {
      submittedReportInYear = reports.find((report) => {
        return report.reportingYear === year && report.submittedOn;
      });
    } else {
      const dataName = AnnualReports.mapDataModuleToDataName(
        DataViewFunctions.getCurrentDataModule(),
      );
      submittedReportInYear = reports.find((report) => {
        return (
          report.reportingYear === year &&
          report.submittedOn &&
          (!dataName || report.dataName === dataName)
        );
      });
    }

    if (submittedReportInYear) {
      return true;
    }
    return false;
  };

  var getGroupIdsToDisable = function () {
    const selectedYear = AnnualReports.getSelectedYear();
    const dataName = AnnualReports.mapDataModuleToDataName(
      DataViewFunctions.getCurrentDataModule(),
    );
    return Progeny.getActiveGroupProgeny()
      .filter((progeny) => {
        if (progeny.isBoundaryOnly) return true;
        const reports = progeny.reports;
        const selectedYearReport = reports.find((report) => {
          return (
            report.reportingYear === selectedYear && (!dataName || report.dataName === dataName)
          );
        });

        if (
          !selectedYearReport ||
          (selectedYearReport.hidden && Tree.get("dataView") !== "sbmpView")
        ) {
          return true;
        }
        return false;
      })
      .map((progeny) => progeny.groupId);
  };

  var _disableGroupDomClick = function (groupIdsToDisable) {
    const $tableMs4s = $(".ms4-table li");
    $tableMs4s.removeClass("disabled");
    const $regionDropdown = getRegionDropdown();
    $regionDropdown.find("a").removeClass("disabled");
    for (const groupId of groupIdsToDisable) {
      $tableMs4s.filter(`[data-group-id="${groupId}"]`).addClass("disabled");
      $regionDropdown.find(`[data-group-id="${groupId}"] a`).addClass("disabled");
    }
  };

  var _disableGroupMapPopups = function (groupIdsToDisable) {
    if (!Tabs.activeTabHasMap()) return;
    const layers = TreeUpdates.getCurrentMapLayers();

    if (layers) {
      if (layers.regionalLayer) {
        disableListenersAndPopupForLayer(layers.regionalLayer, groupIdsToDisable);
      }
      if (layers.regionPointLayer) {
        disableListenersAndPopupForLayer(layers.regionPointLayer, groupIdsToDisable);
      }
    }
  };

  var disableListenersAndPopupForLayer = function (layer, groupIdsToDisable) {
    layer.eachLayer(function (layer) {
      const properties = layer.feature.properties;
      const groupId = properties.group_id || properties.groupId;
      if (!groupId) {
        throw new Error("Couldn't get groupId of region.");
      }
      if (groupIdsToDisable.includes(groupId)) {
        layer.unbindPopup();
        layer.off("click");
      }
    });
  };

  var loadDomListeners = function () {
    $("#nav-title-container").off("click", "#region-dropdown-list li a", regionalListItemClick);
    $("#nav-title-container").on("click", "#region-dropdown-list li a", regionalListItemClick);
  };

  var loadTreeListeners = function () {
    Tree.select("activeGroup", "groupId").on("update", (e) => {
      const selectedGroupId = e.data.currentData;
      const name = Tree.get("activeGroup", "name");
      ToolNavTitle.set(name, true);
      if (Progeny.activeHasProgeny()) {
        render(Progeny.getActiveGroupProgeny());
      } else {
        const parentId = Progeny.getParent(selectedGroupId).groupId;
        render(Progeny.getProgenyByGroupId(parentId).progeny);
      }
    });
  };

  var getRegionDropdown = function () {
    return $("#region-dropdown-list");
  };

  var regionalListItemClick = function () {
    const $region = $(this).parent();
    const selectedGroupId = $region.data("groupId");

    if (selectedGroupId !== Tree.get("activeGroup", "groupId")) {
      RegionalView.changeActiveGroup(selectedGroupId);
    }
  };

  return {
    load,
    handleDisablingRegionButtons,
    render,
    hasSubmittedInYear,
    getGroupIdsToDisableForRegulator,
  };
};

module.exports = RegionDropdown();

const Tree = require("../tree");
const AnnualReports = require("./annualReports");
const TreeUpdates = require("./mapFunctions/treeUpdates");
const DataViewFunctions = require("./dataViewFunctions");
const Progeny = require("../login/progeny");
const ToolNavTitle = require("./toolNavTitle");
const RegionalView = require("./regionalView");
const Tabs = require("./tabs");
