"use strict";

var TelrLayerFunctions = function () {
  var displayTelrLayer = function (
    layer,
    layerName,
    runoffOrParticulates,
    currentMap,
    mapLayers,
    forceRefresh,
  ) {
    if (layer && !forceRefresh) {
      var filters = Tree.get("filters");
      if (!MapFunctions.mapHasLayer(currentMap, layer)) layer.addTo(currentMap);
      layer.filterDrainsToTelr(filters);
    } else {
      loadTelrLayer(layerName, runoffOrParticulates);
    }
  };

  var loadTelrLayer = function (layerName, runoffOrParticulates) {
    var mapId = Tree.get("mapId");

    if (Progeny.activeHasProgeny() || Tree.get("layers", mapId, layerName, "isFetching")) {
      return;
    }

    Tree.set(["layers", mapId, layerName, "isFetching"], true);
    var year = AnnualReports.getSelectedYear();
    var mitigationType = getMitigationType(layerName);

    ReportApiCalls.getTelrResults(year, runoffOrParticulates, mitigationType, function (data) {
      data = data.filter(filterTelrResults);
      var layerData = CleanData.cleanGeoData(data);
      layerData = CleanData.addLoadType(layerData, layerName);
      var columnArray = CleanData.getColumnArray(layerData.features, layerName);
      var coloredFeatures = MapStyles.colorByLoadColumns(layerData.features, layerName);
      layerData.features = coloredFeatures;

      Tree.set(["layers", mapId, layerName, "columnArray"], columnArray);
      Tree.set(["layers", mapId, layerName, "rawData"], data);
      Tree.set(["layers", mapId, layerName, "data"], layerData);
      Tree.set(["layers", mapId, layerName, "layerDropdown"], Tree.get("layerDropdown", mapId));
      Tree.set(["layers", mapId, layerName, "isFetching"], false);

      if (Tree.get("renderTableOnNextDataLayerLoad")) Table.render();
    });
  };

  var filterTelrResults = function (data) {
    const filters = Tree.get("filters");

    return isInFilterArrayIfExists(filters.connectivity, data.connect * 100);
  };

  var isInFilterArrayIfExists = function (filter, property) {
    if (filter !== undefined && filter !== null && property !== undefined && !isNaN(property)) {
      // Can't use filter.includes() because we are probably comparing strings
      // to numbers.
      return (
        filter.find(function (filterValue) {
          return filterValue == property;
        }) !== undefined
      );
    }

    return true;
  };

  var getMitigationType = function (layerName) {
    if (layerName.includes("Opportunity")) {
      return LayerDropdown.isBaseline()
        ? "Unmitigated"
        : LayerDropdown.isCurrent()
          ? "Mitigated"
          : LayerDropdown.isMatrixBaseline()
            ? "Matrix Unmitigated"
            : "Matrix Mitigated";
    }
    return "Mitigated";
  };

  var selectTelrCatchmentsByFilter = function (telrLayer) {
    var filters = Tree.get("filters");
    telrLayer.filterDrainsToTelr(filters);
  };

  var getRunoffType = function () {
    // @TODO: Merge runoffType with runoffOrParticulates
    var dataView = Tree.get("dataView");
    var runoffTypeMap = {
      runoffView: "runoffReduction",
      particulatesView: "particulateReduction",
      runoffRatioView: "percentReduction",
      sbmpView: "sbmpView",
      trashView: "trashView",
    };
    if (!runoffTypeMap[dataView]) {
      throw new Error(`No runoffType for dataView ${dataView}.`);
    }
    return runoffTypeMap[dataView];
  };

  return {
    displayTelrLayer,
    getMitigationType,
    selectTelrCatchmentsByFilter,
    getRunoffType,
  };
};

module.exports = TelrLayerFunctions();

const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const AnnualReports = require("../annualReports");
const CleanData = require("../mapFunctions/cleanData");
const Table = require("../mapFunctions/table");
const MapStyles = require("../mapFunctions/mapStyles");
const Progeny = require("../../login/progeny");
const LayerDropdown = require("../mapFunctions/layerDropdown");
const MapFunctions = require("../mapFunctions/mapFunctions");
