"use strict";

const ProjectInventoryAreaSelection = function () {
  const stringKey = "construction-area-selection";
  const headerInformation = "Area";
  let $page;
  const units = {};

  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
    loadUnitsListeners();

    $page.off("change", `[name='area-manually']`, enterAreaManually);
    $page.on("change", `[name='area-manually']`, enterAreaManually);
  };

  var enterAreaManually = function (e) {
    renderAreaSelectionHtml();
    loadListeners();
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = function () {
    renderAreaSelectionHtml();
    loadListeners();
    showAreaSelectionMap();
    ProjectInventoryModalController.setModalTitle();
    ModalMap.zoomToMarker();
    DrawController.zoomToDrainage();
  };

  var renderAreaSelectionHtml = function () {
    const renderData = InventoryModal.getAllData("project-inventory");
    const dataView = Tree.get("dataView");
    renderData.totalAcreage = calculateDataLayerAcreage();
    renderData.areaUnits = ProjectInventoryConstants.areaUnits;
    renderData.lengthUnits = ProjectInventoryConstants.lengthUnits;
    initializeUnits(renderData);
    renderData.dataView = dataView;
    const template = "modals/projectInventory/projectInventoryAreaSelection.njk";
    const html = nunjucks.render(template, renderData);
    ProjectInventoryModalController.renderPageContent(html);
    _addAndRemoveMarginClass();
    $page = $(".modal-dynamic-content");
  };

  var _addAndRemoveMarginClass = function () {
    $(".modal-dynamic-content").addClass("add-area-low-margin").removeClass("add-bmp-no-margin");
  };
  function loadUnitsListeners() {
    $page.on("input", `[name='priority-info[manual-area-units]']`, (e) => {
      const newUnit = $(e.currentTarget).val();
      updateUnits(newUnit, "priority-info[manual-area-units]", "manualArea");
    });
    $page.on("input", `[name='priority-info[area-of-disturbance-units]']`, (e) => {
      const newUnit = $(e.currentTarget).val();
      updateUnits(newUnit, "priority-info[area-of-disturbance-units]", "areaOfDisturbance");
    });
    $page.on("input", `[name='priority-info[net-impervious-area-units]']`, (e) => {
      const newUnit = $(e.currentTarget).val();
      updateUnits(newUnit, "priority-info[net-impervious-area-units]", "netImperviousArea");
    });

    $page.on("input", `[name='priority-info[pre-impervious-area-units]']`, (e) => {
      const newUnit = $(e.currentTarget).val();
      updateUnits(newUnit, "priority-info[pre-impervious-area-units]", "preImperviousArea");
    });

    $page.on("input", `[name='priority-info[post-impervious-area-units]']`, (e) => {
      const newUnit = $(e.currentTarget).val();
      updateUnits(newUnit, "priority-info[post-impervious-area-units]", "postImperviousArea");
    });

    $page.on("input", `[name='priority-info[proximity-to-receiving-water-units]']`, (e) => {
      const newUnit = $(e.currentTarget).val();
      updateUnits(
        newUnit,
        "priority-info[proximity-to-receiving-water-units]",
        "proximityToReceivingWater",
      );
    });

    $page.on("input", `[name='priority-info[proximity-to-303d-receiving-water-units]']`, (e) => {
      const newUnit = $(e.currentTarget).val();
      updateUnits(
        newUnit,
        "priority-info[proximity-to-303d-receiving-water-units]",
        "proximityTo303dReceivingWater",
      );
    });
  }

  function updateUnits(newUnit, unitsInputSelector, numberInputKey) {
    updateUnitAndConvertValue(
      unitsInputSelector,
      ProjectInventoryModalController.getLatestConstructionData(["priorityInfo", numberInputKey]),
      newUnit,
    );
  }

  function initializeUnits(renderData) {
    initializeUnitsWithDefault(
      renderData.priorityInfo,
      "manualAreaUnits",
      "priority-info[manual-area-units]",
      "acres",
    );
    initializeUnitsWithDefault(
      renderData.priorityInfo,
      "netImperviousAreaUnits",
      "priority-info[net-impervious-area-units]",
      "acres",
    );
    initializeUnitsWithDefault(
      renderData.priorityInfo,
      "areaOfDisturbanceUnits",
      "priority-info[area-of-disturbance-units]",
      "acres",
    );
    initializeUnitsWithDefault(
      renderData.priorityInfo,
      "preImperviousAreaUnits",
      "priority-info[pre-impervious-area-units]",
      "acres",
    );
    initializeUnitsWithDefault(
      renderData.priorityInfo,
      "postImperviousAreaUnits",
      "priority-info[post-impervious-area-units]",
      "acres",
    );
    initializeUnitsWithDefault(
      renderData.priorityInfo,
      "proximityToReceivingWaterUnits",
      "priority-info[proximity-to-receiving-water-units]",
      "feet",
    );
    initializeUnitsWithDefault(
      renderData.priorityInfo,
      "proximityTo303dReceivingWaterUnits",
      "priority-info[proximity-to-303d-receiving-water-units]",
      "feet",
    );
  }

  function initializeUnitsWithDefault(
    priorityInfo,
    unitsInputKey,
    unitsInputSelector,
    defaultUnits,
  ) {
    if (!priorityInfo) {
      priorityInfo = {};
    }

    if (!priorityInfo[unitsInputKey]) {
      priorityInfo[unitsInputKey] = defaultUnits;
      ProjectInventoryModalController.setFormDataField(
        ["priorityInfo", unitsInputKey],
        defaultUnits,
      );
    }

    units[unitsInputSelector] = priorityInfo[unitsInputKey];
  }

  function updateUnitAndConvertValue(fieldName, prevValue, newUnit) {
    const prevUnit = units[fieldName];
    if (prevUnit === newUnit) return;
    units[fieldName] = newUnit;
    if (prevUnit !== null) {
      const newValue = UnitConversion.convertVal(prevValue, prevUnit, newUnit);
      const valueFieldName = fieldName.replace("-units", "");
      try {
        NumberInput.manuallySetInput($page.find(`[name='${valueFieldName}']`)[0], newValue);
      } catch (err) {
        NumberInput.manuallySetInput($page.find(`[name='${valueFieldName}']`)[0], 0);
      }
    }
  }

  var showAreaSelectionMap = function () {
    const constructionAreaSelectionMapHandlers = {
      addPolygonHandler: constructionAreaPolygonUpdate,
      removePolygonHandler: constructionAreaPolygonUpdate,
      selectAllPolygonsHandler: constructionAreaSelectAllPolygonsHandler,
      loadPolygonTreeHandler: loadPolygonsToConstructionTree,
      percentageUpdateHandler: setTotalAreaAcres,
      newExistingMarkerHandler: ProjectInventoryModalController.loadExistingProjectMarker,
    };
    const activeMethod =
      ProjectInventoryModalController.getLatestConstructionData(["drawMethod"]) || "drawing-tools";
    ModalMap.removeMarkerLayer();
    AreaSelectionMap.resetAreaSelectionMapHandlers();
    AreaSelectionMap.configureAreaSelectionMapHandlers(constructionAreaSelectionMapHandlers);
    AreaSelectionMap.showAreaSelectionMap(true);
    AreaSelectionMap.setActiveMethodLayer(activeMethod);
    AreaSelectionTable.setAreaSelectionTablePercentageText("in Project");
  };

  var getTotalAreaAcres = function () {
    const drawMethod = ProjectInventoryModalController.getLatestConstructionData(["drawMethod"]);

    if (drawMethod === "drawing-tools") {
      return Form.getFormDataAtPath("project-inventory", ["drawnAcres"]) || 0;
    } else {
      return calculateDataLayerAcreage();
    }
  };

  var calculateDataLayerAcreage = function () {
    const polygons =
      ProjectInventoryModalController.getLatestConstructionData(["areaPolygons"]) || [];
    const totalAcreage = polygons.reduce((area, polygon) => {
      return area + parseFloat(polygon.acres) * (polygon.percent / 100);
    }, 0);
    return totalAcreage;
  };

  var loadPolygonsToConstructionTree = function () {};

  var constructionAreaPolygonUpdate = function (polyProps, polygonArray, catchmentArray) {
    polygonArray = polygonArray.map((polygon) => {
      return {
        id: polygon.id,
        apn: polygon.apn,
        percent: polygon.percent,
        catch_name: polygon.catch_name,
        polygon_id: polygon.polygon_id,
        acres: polygon.acres,
      };
    });
    ProjectInventoryModalController.setFormDataField("areaPolygons", polygonArray);
    ProjectInventoryModalController.setFormDataField("selectedCatchments", catchmentArray);
    setTotalAreaAcres();
  };

  var constructionAreaSelectAllPolygonsHandler = function (polygonArray) {
    ProjectInventoryModalController.setFormDataField("areaPolygons", polygonArray);
    setTotalAreaAcres();
  };

  var setTotalAreaAcres = function () {
    const areaManually = ProjectInventoryModalController.getLatestConstructionData([
      "areaManually",
    ]);
    if (!areaManually) {
      const acres = getTotalAreaAcres();
      const totalArea = acres.toFixed(2);
      $page.find(".total-area div").html(`${totalArea} acres`);
    }
  };

  var getTotalAreaSelected = function () {
    const drawnAcres =
      ProjectInventoryModalController.getLatestConstructionData(["drawnAcres"]) || 0;
    const dataLayerAcres = calculateDataLayerAcreage();
    const drawMethod = DrawController.getSelectedMethod();
    return drawMethod === "drawing-tools" ? drawnAcres : dataLayerAcres;
  };

  var convertToSquareFeet = function (acres) {
    return acres * 43560;
  };

  var validateManualValuesAndShowWarnings = function () {
    const allProjectData = ProjectInventoryModalController.getAllConstructionData();
    let manualArea = allProjectData.priorityInfo?.manualArea;
    let areaDisturbance = allProjectData.priorityInfo?.areaOfDisturbance;
    let imperviousArea = allProjectData.priorityInfo?.netImperviousArea;
    const manualAreaUnits = allProjectData.priorityInfo?.manualAreaUnits;
    const areaDisturbanceUnits = allProjectData.priorityInfo?.areaOfDisturbanceUnits;
    const netImperviousAreaUnits = allProjectData.priorityInfo?.netImperviousAreaUnits;
    if (manualAreaUnits === "acres") {
      manualArea = convertToSquareFeet(manualArea);
    }
    if (areaDisturbanceUnits === "acres" && areaDisturbance) {
      areaDisturbance = convertToSquareFeet(areaDisturbance);
    }
    if (netImperviousAreaUnits === "acres" && imperviousArea) {
      imperviousArea = convertToSquareFeet(imperviousArea);
    }
    if (areaDisturbance && areaDisturbance > manualArea) {
      MessageModal.showReturnWarningModal(
        "You have entered an Area of Disturbance that is greater than the selected total project area. Return to Area Selection to resolve this error.",
      );
      return false;
    }
    if (imperviousArea && imperviousArea > manualArea) {
      MessageModal.showReturnWarningModal(
        "You have entered a Net Impervious Area that is greater than the selected total project area. Return to Area Selection to resolve this error.",
      );
      return false;
    }
    return true;
  };

  var validateAreasAndShowWarningModal = function () {
    const totalAreaSelected = getTotalAreaSelected();
    const updatedPriorityInfo = ProjectInventoryModalController.getLatestConstructionData([
      "priorityInfo",
    ]);
    if (updatedPriorityInfo.areaOfDisturbance) {
      let areaOfDisturbanceAcres = updatedPriorityInfo.areaOfDisturbance;
      if (updatedPriorityInfo.areaOfDisturbanceUnits !== undefined) {
        areaOfDisturbanceAcres = UnitConversion.convertVal(
          updatedPriorityInfo.areaOfDisturbance,
          updatedPriorityInfo.areaOfDisturbanceUnits,
          "acres",
        );
      }

      if (
        areaOfDisturbanceAcres &&
        parseFloat(areaOfDisturbanceAcres) > totalAreaSelected.toFixed(2)
      ) {
        MessageModal.showReturnWarningModal(
          "You have entered an Area of Disturbance that is greater than the selected total project area. Return to Area Selection to resolve this error.",
        );
        return false;
      }
    }
    if (updatedPriorityInfo.netImperviousArea) {
      let netImperviousAreaAcres = updatedPriorityInfo.netImperviousArea;
      if (updatedPriorityInfo.netImperviousAreaUnits) {
        netImperviousAreaAcres = UnitConversion.convertVal(
          updatedPriorityInfo.netImperviousArea,
          updatedPriorityInfo.netImperviousAreaUnits,
          "acres",
        );
      }

      if (netImperviousAreaAcres && parseFloat(netImperviousAreaAcres) > totalAreaSelected) {
        MessageModal.showReturnWarningModal(
          "You have entered a Net Impervious Area that is greater than the selected total project area. Return to Area Selection to resolve this error.",
        );
        return false;
      }
    }

    return true;
  };

  var cleanUp = function () {
    AreaSelectionMap.hideAreaSelectionMap();
    $page.empty();
    return true;
  };

  var validate = function () {
    const areaManually = ProjectInventoryModalController.getLatestConstructionData([
      "areaManually",
    ]);
    if (!areaManually) {
      return validateAreasAndShowWarningModal();
    } else {
      return validateManualValuesAndShowWarnings();
    }
  };

  var resetState = function () {
    AreaSelectionMap.resetDrawController();
  };

  return {
    getStringKey,
    getHeaderInformation,
    getTotalAreaSelected,
    render,
    cleanUp,
    validate,
    constructionAreaPolygonUpdate,
    resetState,
    setTotalAreaAcres,
    getTotalAreaAcres,
    validateManualValuesAndShowWarnings,
    showAreaSelectionMap,
    renderAreaSelectionHtml,
    _addAndRemoveMarginClass,
  };
};

module.exports = ProjectInventoryAreaSelection();

const Tree = require("../tree");
const UnitConversion = require("../unitConversion");
const NumberInput = require("../general/numberInput");
const InventoryModal = require("../general/inventoryModal");
const MessageModal = require("../modals/messageModal");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const AreaSelectionMap = require("../mapFunctions/areaSelectionMap");
const AreaSelectionTable = require("../mapFunctions/areaSelectionTable");
const Form = require("../general/form");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const DrawController = require("../mapFunctions/drawController");
const ModalMap = require("../mapFunctions/modalMap");
