"use strict";

var outfallProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "outfallProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.outfallProgressLayer && sameSpatialFilter && sameView && sameGroup) {
            RamMapFunctions.resizeLayerMarkers(
              mapLayers.outfallProgressLayer,
              OutfallsIcon.getOutfallsMarkerIcon,
            );
            OutfallsIcon.getOutfallsMarkerIcon;
            map.addLayer(mapLayers.outfallProgressLayer);
            Clustering.setState("outfallProgress");
          } else {
            const isFetching = Tree.get([
              "layers",
              Tree.get("mapId"),
              "outfallProgress",
              "isFetching",
            ]);
            if (sameSpatialFilter && sameView && isFetching) {
              // Temp solution to prevent laoding twice from dataView and filters update
              return;
            }
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.outfallProgressLayer)) {
            map.removeLayer(mapLayers.outfallProgressLayer);
          }
        }
      },
    );
  };

  var loadLayer = async function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");
    if (!filters.catchments.length && filters.searchString) {
      Tree.set(["layers", mapId, "outfallProgress", "data"], []);
    } else {
      Tree.set(["layers", mapId, "outfallProgress", "isFetching"], true);
      const data = await ReportApiCalls.getOutfallProgress(filters);
      Tree.set(["layers", mapId, "outfallProgress", "isFetching"], false);
      Tree.set(["layers", mapId, "outfallProgress", "data"], data);
      Tree.set(["layers", mapId, "outfallProgress", "spatialView"], Tree.get("spatialView", mapId));
    }
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "outfallProgress", function (data) {
      if (Filters.spatialFilterIsSet()) {
        data = spatiallyFilterOutfallData(data);
      }
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.outfallProgressLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
      Clustering.setState("outfallProgress");
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.outfallProgressLayer) {
      map.removeLayer(mapLayers.outfallProgressLayer);
    }
    mapLayers.outfallProgressLayer = createLayer(data);
    OutfallsPopup.loadDomListeners();
    mapLayers.outfallProgressLayer.addTo(map);
    return mapLayers.outfallProgressLayer;
  };

  var createLayer = function (data) {
    var layer = L.featureGroup();
    const iconRotationOffset = -90;

    L.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        const outfallData = feature.properties;
        const marker = L.marker(latlng, {
          pane: "outfallPane",
          icon: OutfallsIcon.getOutfallsMarkerIcon(
            outfallData,
            ReportMap.getActiveReportMap().getZoom(),
          ),
          rotationAngle: outfallData.rotationAzimuth + iconRotationOffset,
          rotationOrigin: "center",
        });
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => OutfallsPopup.getOutfallPopupHtml(feature.properties),
          OutfallsPopup.getPopupOptions(),
        );
      },
    }).addTo(layer);
    OutfallsPopup.loadPopupEventListeners(layer);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Only watch for specific filters since other filters updates are already handled in treeUpdates
    // @TODO: Investigate why listening to filters update causes an infinite loop while searching
    Tree.watch({
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        Tree.get("dataView") === "outfallView" &&
        MapFunctions.mapHasLayer(map, mapLayers.incidentProgressLayer) &&
        Tree.get("layers", mapId, "outfallProgress", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var spatiallyFilterOutfallData = function (outfallData) {
    const filters = Tree.get("filters");
    return outfallData.filter((datum) => {
      return (
        filters.receivingWaters.includes(datum.catchment["drains_to_rw"]) ||
        filters.receivingWaters.includes(datum.catchment["drains_to_c"]) ||
        filters.catchments.includes(datum.catchment["catchid"])
      );
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    createLayer,
  };
};

module.exports = outfallProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Table = require("../mapFunctions/table");
const Filters = require("../mapFunctions/filters");
const Clustering = require("../mapFunctions/clustering");
const OutfallsIcon = require("../outfalls/outfallsIcon");
const OutfallsPopup = require("../outfalls/outfallsPopup");
const ReportMap = require("../report/reportMap");
const RamMapFunctions = require("../../mapFunctions/mapFunctions");
