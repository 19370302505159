"use strict";
let data;

const CatchBasinFactSheetController = function () {
  var initFactSheet = async function (id) {
    data = await getFactSheetData(id);
    const currentConfigs = FactSheetConfig.getFactSheetConfig("muni-catch-basins");
    FactSheet.init(data, currentConfigs);
  };

  var getFactSheetData = async function (id) {
    try {
      return await ApiCalls.getCatchBasinForFactSheet(id);
    } catch (e) {
      ApiError.assertIs(e);
    }

    return await MuniCatchBasinGeoServerLayer.getOfflineData(id);
  };

  return {
    initFactSheet,
  };
};

module.exports = CatchBasinFactSheetController();

const ApiCalls = require("../apiCalls");
const ApiError = require("../errors/apiError");
const FactSheet = require("../general/factSheet");
const FactSheetConfig = require("../config/factSheetConfig");
const MuniCatchBasinGeoServerLayer = require("./muniCatchBasinGeoServerLayer");
