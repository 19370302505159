"use strict";

function StatusBarOnlineStatus() {
  var onlineBannerTimeout;
  var $banner;

  function init() {
    $banner = $(".status-bar-container");
    statusBarOnlineStatusDomListeners();
    if (!Offline.onLine()) {
      _updateStatus();
    }
  }

  var statusBarOnlineStatusDomListeners = function () {
    $banner.on("click", _expandStatusBanner);
    $banner.on("click", ".status-banner-expanded-back-button", _contractStatusBanner);
    $banner.on("click", ".resync-all-inspections", resyncInspections);
    $(window).on("offline", _updateStatus);
    $(window).on("online", _updateStatus);
    SyncManager.addStatusListener(_updateStatus);
  };

  var _expandStatusBanner = function () {
    if (_isExpanded()) {
      return;
    }

    $banner.addClass("banner-expanded");
  };

  var _contractStatusBanner = function (e) {
    $(".status-bar-container").removeClass("banner-expanded");
    e.stopPropagation(); // Prevent calling _expandStatusBanner()
  };

  var removeOnlineBanner = function () {
    if (!$(".status-bar-container").hasClass("banner-expanded")) {
      toggleStatusBanner(false);
    }
  };

  var setBanner = function (bannerStatus) {
    $banner.removeClass("online-banner");
    $banner.removeClass("error-banner");
    $banner.removeClass("offline-banner");
    $banner.addClass(`${bannerStatus}-banner`);
  };

  var resyncInspections = function () {
    ResourceController.sync();
  };

  var _updateStatus = function () {
    const status = SyncManager.getStatus();
    var props = {};

    clearTimeout(onlineBannerTimeout);

    if (!ToolFunctions.getToolConfigProperty("offline", null, false) && !Offline.onLine()) {
      props = {
        overrideMessage: "Offline is not supported in this tool",
        bannerStatus: "error",
        syncStatus: null,
      };
    } else if (status.toSync === 0 && status.rejected > 0) {
      props.syncStatus = "error";
      props.bannerStatus = "error";

      if (!UserResource.getUserData("lastUnresolvedSyncError")) {
        _expandStatusBanner();
      }
    } else if (!Offline.onLine()) {
      props.bannerStatus = "offline";
    } else {
      props.bannerStatus = "online";

      if (status.toSync > 0) {
        props.syncStatus = "syncing";
      } else {
        props.syncStatus = "done syncing";
      }

      if (props.syncStatus === "done syncing") {
        onlineBannerTimeout = setTimeout(removeOnlineBanner, 5000);
      }
    }

    addErrorInformation(props);
    setBanner(props.bannerStatus);
    renderStatusBanner(props);
  };

  var renderStatusBanner = function (props) {
    const banner = nunjucks.render("popups/onlineStatusBanner.njk", props);
    $banner.html(banner);
    toggleStatusBanner(true);
  };

  var toggleStatusBanner = function (show) {
    $("body").toggleClass("status-banner-visible", show);
    MapFunctions.setMapHeight();
  };

  var addErrorInformation = function (props) {
    if (props.syncStatus === "error") {
      const lastUnresolvedSyncError = UserResource.getUserData("lastUnresolvedSyncError");
      if (lastUnresolvedSyncError) {
        props.syncError = lastUnresolvedSyncError;
      } else {
        props.syncError = getSyncError();
        UserResource.setUserData("lastUnresolvedSyncError", props.syncError);
      }
    } else if (props.syncStatus === "done syncing") {
      UserResource.setUserData("lastUnresolvedSyncError", null);
    }
  };

  var getSyncError = function () {
    const Sentry = require("@sentry/browser");
    const data = {
      t: DateTime.getNowIso(),
      i: Sentry.lastEventId(),
      b: global.twoNformBuildVersion.substring(0, 8),
    };
    return JSON.stringify(data).replace(/{|}|"/g, "");
  };

  var _isExpanded = function () {
    return $banner.hasClass("banner-expanded");
  };

  return {
    init,
    _isExpanded,
    _updateStatus,
    _expandStatusBanner,
    _contractStatusBanner,
  };
}

module.exports = StatusBarOnlineStatus();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Offline = require("./offline");
const ResourceController = require("./resourceController");
const SyncManager = require("./syncManager");
const ToolFunctions = require("../toolFunctions");
const UserResource = require("./userResource");
const DateTime = require("../dateTime");
