"use strict";

var MapFunctions = function () {
  var bmpMarkerMap = {};

  function getAllMapIds() {
    return ["report", "plan", "inputs"];
  }

  function setAllMapExtentsNotInitialized() {
    getAllMapIds().forEach((mapId) => {
      Tree.set(["extentInitialized", mapId], false);
    });
  }

  function resetAllMapSpatialViewsToMs4() {
    getAllMapIds().forEach((mapId) => {
      Tree.set(["spatialView", mapId], "MS4View");
    });
  }

  function clearLayersForAllMapIds() {
    getAllMapIds().forEach((mapId) => {
      clearViewLayers(mapId, false, true);
    });
  }

  const addBasemap = function (map, currentBasemap, newBasemap) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    currentBasemap = rasterBasemap
      ? addRasterBasemap(map, currentBasemap, newBasemap)
      : addVectorBasemap(map, currentBasemap, newBasemap);
    return currentBasemap;
  };

  var addVectorBasemap = function (map, basemap, style) {
    style = cleanVectorStyle(style);
    const apiKey = ToolSettings.getSetting("esri", "authkey");
    if (basemap) {
      map.removeLayer(basemap);
    }
    basemap = L.esri.Vector.vectorBasemapLayer(`ArcGIS:${style}`, {
      apiKey: apiKey,
    });
    return basemap.addTo(map);
  };

  var cleanVectorStyle = function (style) {
    if (style === "Gray") {
      style = "LightGray";
    } else if (style === "Imagery") {
      style = "Imagery:Standard";
    } else if (style === "Hybrid") {
      style = "Imagery";
    }
    return style;
  };

  var addRasterBasemap = function (map, basemap, style) {
    if (basemap) {
      map.removeLayer(basemap);
    }
    if (style === "Hybrid") {
      var imagery = L.esri.basemapLayer("Imagery", { detectRetina: true, maxNativeZoom: 19 });
      var imageryTransportation = L.esri.basemapLayer("ImageryTransportation");
      var imageryLabels = L.esri.basemapLayer("ImageryLabels");
      basemap = L.featureGroup([imagery, imageryTransportation, imageryLabels]);

      if (basemap._layers) {
        for (const key in basemap._layers) {
          const basemapLayer = basemap._layers[key];
          resetBasemapOptions(basemapLayer);
        }
      }
    } else {
      basemap = L.esri.basemapLayer(style, {
        detectRetina: true,
        maxNativeZoom: 19,
      });
    }

    resetBasemapOptions(basemap);

    return basemap.addTo(map);
  };

  var resetBasemapOptions = function (basemap) {
    basemap.options.maxNativeZoom = 19;
    basemap.options.maxZoom = 19;
    basemap.options.zoomOffset = 0;
    basemap.options.tileSize = 256;
  };

  var addCatchLayer = function (map, catchmentLayer, data) {
    const DataViewFunctions = require("../dataViewFunctions");
    var geoJson = CleanData.cleanGeoJSON(data);
    if (catchmentLayer) {
      map.removeLayer(catchmentLayer);
    }
    catchmentLayer = new (Layers.getLayers().CatchmentLayer)(geoJson, {
      enableHighlight: true,
    });
    catchmentLayer.on("popupopen", function (e) {
      DataViewFunctions.handleSpatialViewButtonsDisplay();
    });
    return catchmentLayer.addTo(map);
  };

  var addHighwayLayer = function (map, highwayLayer, data) {
    var geoJson = CleanData.cleanGeoJSON(data);
    if (highwayLayer) {
      map.removeLayer(highwayLayer);
    }
    highwayLayer = new (Layers.getLayers().HighwayLayer)(geoJson);
    return highwayLayer.addTo(map);
  };

  var zoomToCatchmentLayer = function (
    map = TreeUpdates.getCurrentMap(),
    catchmentLayer = TreeUpdates.getCurrentMapLayers().catchmentLayer,
  ) {
    var mapId = Tree.get("mapId");
    var isCatchmentLayerFetching = Tree.get(["layers", mapId, "catchments", "isFetching"]);

    if (catchmentLayer && Object.keys(catchmentLayer._layers).length) {
      if (
        Tree.get("reZoomCatchmentLayer") ||
        (!Tree.get("extentInitialized", mapId) && !isCatchmentLayerFetching)
      ) {
        const bounds = catchmentLayer.getBounds();
        map.fitBounds(bounds);
        Tree.set("bbox", bounds.toBBoxString());
        Tree.set(["extentInitialized", mapId], true);
      }

      if (!Tab.activeTabHasMapShowing()) {
        Tree.set("reZoomCatchmentLayer", true);
      }
    }
  };

  var addModalCatchLayer = function (map, catchmentLayer, data) {
    var geoJson = CleanData.cleanGeoJSON(data);
    if (catchmentLayer) {
      map.removeLayer(catchmentLayer);
    }
    catchmentLayer = new (Layers.getLayers().ModalCatchmentLayer)(geoJson);
    if (Object.keys(catchmentLayer._layers).length) {
      map.fitBounds(catchmentLayer.getBounds());
    }
    return catchmentLayer.addTo(map);
  };

  var addBmpLayer = function (map, bmpLayer, data) {
    if (Filters.spatialFilterIsSet()) {
      data = SbmpProgressLayer.spatiallyFilterSbmpData(data);
    }
    var geojsonData = CleanData.cleanGeoJSON(data);
    if (bmpLayer) {
      map.removeLayer(bmpLayer);
    }
    SbmpProgressPopup.loadDomListeners();
    bmpLayer = SbmpProgressLayer.createLayer(geojsonData, map);
    bmpLayer.addTo(map);

    return bmpLayer;
  };

  var addFcsLayer = function (map, fcsLayer, data) {
    if (Filters.spatialFilterIsSet()) {
      data = FcsProgressLayer.spatiallyFilterFcsData(data);
    }
    var geojsonData = CleanData.cleanGeoJSON(data);
    if (fcsLayer) {
      map.removeLayer(fcsLayer);
    }
    FcsProgressPopup.loadDomListeners();
    fcsLayer = FcsProgressLayer.createLayer(geojsonData, true, map);
    fcsLayer.addTo(map);

    return fcsLayer;
  };

  var addFullDrainageLayer = function (map, fullDrainageLayer, data) {
    if (fullDrainageLayer) {
      map.removeLayer(fullDrainageLayer);
    }
    fullDrainageLayer = new (Layers.getLayers().FullDrainageLayer)(data);
    return fullDrainageLayer;
  };

  var addParcelTileLayer = function (map, tileLayer, data) {
    if (tileLayer) {
      map.removeLayer(tileLayer);
    }
    tileLayer = new (Layers.getLayers().ParcelGridLayer)(data);
    return tileLayer.addTo(map);
  };

  var addLanduseLayer = function (map, landuseLayer, data) {
    if (landuseLayer) {
      map.removeLayer(landuseLayer);
    }
    landuseLayer = L.geoJson(data, {
      style: MapStyles.styleLanduse,
    })
      .addTo(map)
      .bringToBack();
    return landuseLayer;
  };

  var addMs4BoundaryLayer = function (map, ms4BoundaryLayer, data) {
    if (ms4BoundaryLayer) {
      map.removeLayer(ms4BoundaryLayer);
    }
    ms4BoundaryLayer = Layers.getLayers().Ms4BoundaryLayer(data).addTo(map);
    return ms4BoundaryLayer;
  };

  var addStreamLayer = function (map, rwLayer, data) {
    if (rwLayer) {
      map.removeLayer(rwLayer);
    }
    rwLayer = new (Layers.getLayers().WatershedLayer)();
    rwLayer.addData(data);
    startRWListener(map, rwLayer);
    return rwLayer;
  };

  var addStormdrainLayer = function (map, mapLayers, data) {
    if (mapLayers.stormdrainLayer) {
      map.removeLayer(mapLayers.stormdrainLayer);
    }
    mapLayers.stormdrainLayer = new (Layers.getLayers().StormdrainLayer)();
    mapLayers.stormdrainLayer.addData(data);
    handleStormdrainDisplay(map, mapLayers);
    startStormDrainsZoomListener(map, mapLayers);
    return mapLayers.stormdrainLayer.addTo(map);
  };

  var addMiddleStormdrainLayer = function (map, middleStormdrainLayer, data) {
    if (middleStormdrainLayer) {
      map.removeLayer(middleStormdrainLayer);
    }
    middleStormdrainLayer = new (Layers.getLayers().MiddleStormdrainLayer)();
    middleStormdrainLayer.addData(data);
    return middleStormdrainLayer.addTo(map);
  };

  var addBaseStormdrainLayer = function (map, baseStormdrainLayer, data) {
    if (baseStormdrainLayer) {
      map.removeLayer(baseStormdrainLayer);
    }
    baseStormdrainLayer = new (Layers.getLayers().BaseStormdrainLayer)();
    baseStormdrainLayer.addData(data);

    return baseStormdrainLayer.addTo(map);
  };

  var handleStormdrainDisplay = function (currentMap, mapLayers) {
    const mapId = Tree.get("mapId");
    const thisLegend = PageFunctions.getCurrentPage().find(".display-options");
    const mapZoom = currentMap.getZoom();
    const stormdrainsEnabled = Tree.get("layers", mapId, "stormdrains", "isEnabled");
    if (currentMap.getZoom() <= 12) {
      if (stormdrainsEnabled) {
        //hide all storm drains
        hideStormDrainsLayer(mapLayers);
        hideMiddleStormDrainsLayer(mapLayers);
        hideBaseStormDrainsLayer(mapLayers);
        hideStormDrainsLegend(thisLegend);
      }
    } else {
      if (stormdrainsEnabled) {
        if (mapZoom <= 14) {
          //display the thin layer of base storm drains only
          hideStormDrainsLayer(mapLayers);
          hideMiddleStormDrainsLayer(mapLayers);
          thinBaseStormDrainsLayer(currentMap, mapLayers);
          showStormDrainsThinLegend(thisLegend);
        } else {
          //display the three layers of storm drains
          thickenBaseStormDrainsLayer(currentMap, mapLayers);
          showMiddleStormDrainsLayer(currentMap, mapLayers);
          showStormDrainsLayer(currentMap, mapLayers);
          showStormDrainsLegend(thisLegend);
        }
      }
    }
  };

  var handleHideStormdrains = function (currentMap, mapLayers) {
    currentMap.removeLayer(mapLayers.stormdrainLayer);
    currentMap.removeLayer(mapLayers.middleStormdrainLayer);
    currentMap.removeLayer(mapLayers.baseStormdrainLayer);
  };

  var hideStormDrainsLayer = function (mapLayers) {
    mapLayers.stormdrainLayer.setStyle({
      opacity: 0,
    });
  };

  var showStormDrainsLayer = function (currentMap, mapLayers) {
    if (!mapHasLayer(currentMap, mapLayers.stormdrainLayer))
      currentMap.addLayer(mapLayers.stormdrainLayer);
    mapLayers.stormdrainLayer.setStyle({
      opacity: 1,
    });
  };

  var hideMiddleStormDrainsLayer = function (mapLayers) {
    mapLayers.middleStormdrainLayer.setStyle({
      opacity: 0,
    });
  };

  var showMiddleStormDrainsLayer = function (currentMap, mapLayers) {
    if (!mapHasLayer(currentMap, mapLayers.middleStormdrainLayer))
      currentMap.addLayer(mapLayers.middleStormdrainLayer);
    mapLayers.middleStormdrainLayer.setStyle({
      opacity: 1,
    });
  };

  var hideBaseStormDrainsLayer = function (mapLayers) {
    mapLayers.baseStormdrainLayer.setStyle({
      opacity: 0,
    });
  };

  var thinBaseStormDrainsLayer = function (currentMap, mapLayers) {
    if (!mapHasLayer(currentMap, mapLayers.baseStormdrainLayer))
      currentMap.addLayer(mapLayers.baseStormdrainLayer);
    mapLayers.baseStormdrainLayer.setStyle(MapStyles.styleBaseStormdrainThin);
    mapLayers.baseStormdrainLayer.setStyle({
      opacity: 1,
    });
  };

  var thickenBaseStormDrainsLayer = function (currentMap, mapLayers) {
    if (!mapHasLayer(currentMap, mapLayers.baseStormdrainLayer))
      currentMap.addLayer(mapLayers.baseStormdrainLayer);
    mapLayers.baseStormdrainLayer.setStyle(MapStyles.styleBaseStormdrain);
    mapLayers.baseStormdrainLayer.setStyle({
      opacity: 1,
    });
  };

  var showStormDrainsLegend = function (legendId) {
    legendId.find(".stormdrain-legend").show().removeClass("mid-zoom");
  };

  var showStormDrainsThinLegend = function (legendId) {
    legendId.find(".stormdrain-legend").show().addClass("mid-zoom");
  };

  var hideStormDrainsLegend = function (legendId) {
    legendId.find(".stormdrain-legend").hide();
  };

  var whenLayerDataUpdated = function (tree, mapId, key, callback) {
    tree.select("layers", mapId, key, "data").on("update", function () {
      var cursor = Tree.get("layers", mapId, key);
      if (
        Tree.get("dataView") === "muniCatchBasinView" &&
        FeatureFlag.enabled("annual-report-muni-catch-basin-tko")
      ) {
        MuniCatchBasinReportGeoServerLayer.loadLayer("report");
      }
      if (cursor && cursor.isEnabled && !cursor.isFetching) {
        callback(cursor.data);
      }
    });
  };

  var whenLayerToggled = function (mapId, key, callback) {
    Tree.select("layers", mapId, key, "isEnabled").on("update", function (e) {
      copyCurrentSelectionToToggledLayer(mapId, key, callback);
    });
  };

  var copyCurrentSelectionToToggledLayer = function (mapId, key, callback) {
    var spatialView = Tree.get("spatialView", mapId);
    var layerIsEnabled = Tree.get("layers", mapId, key, "isEnabled");
    var globalSelected = getGlobalSelected(spatialView);
    var globalSelectedType = getGlobalSelectedType();
    var globalSearchString = Tree.get("filters", "searchString");
    var layerSelected = Tree.get("layers", mapId, key, "selected");
    var layerSelectedType = Tree.get("layers", mapId, key, "selectedType");
    var layerSelectedGroup = Tree.get("layers", mapId, key, "groupId");
    var localSearchString = Tree.get("layers", mapId, key, "searchString");
    var groupId = Tree.get("activeGroup", "groupId");
    var sameSpatialView = Tree.get("layers", mapId, key, "spatialView") === spatialView;
    var sameSpatialFilter =
      globalSelected === layerSelected &&
      globalSelectedType === layerSelectedType &&
      globalSearchString === localSearchString;
    var sameGroup = groupId === layerSelectedGroup;
    setSelectedTree(
      mapId,
      key,
      globalSelected,
      globalSelectedType,
      globalSearchString,
      groupId,
      layerIsEnabled,
    );

    callback(layerIsEnabled, sameSpatialFilter, sameSpatialView, sameGroup);
  };

  var getGlobalSelected = function (spatialView) {
    let globalSelected;
    if (spatialView === "drainageView") {
      globalSelected = Tree.get("selected", "urbanDrainage").length
        ? Tree.get("selected", "urbanDrainage")[0]
        : Tree.get("selected", "catchment")
          ? Tree.get("selected", "catchment")[0]
          : "";
    } else if (spatialView === "catchmentView") {
      globalSelected = Tree.get("selected", "catchment");
    } else {
      globalSelected = Tree.get("selected", "receivingWater")
        ? Tree.get("selected", "receivingWater")
        : Tree.get("selected", "catchment")
          ? Tree.get("selected", "catchment")
          : "";
    }
    return globalSelected;
  };

  var getGlobalSelectedType = function () {
    return Tree.get("selected", "receivingWater")
      ? "header"
      : Tree.get("selected", "catchment")
        ? "catchment"
        : "";
  };

  var setSelectedTree = function (
    mapId,
    key,
    globalSelected,
    globalSelectedType,
    globalSearchString,
    groupId,
    layerIsEnabled,
  ) {
    Tree.set(["layers", mapId, key, "selected"], globalSelected);
    Tree.set(["layers", mapId, key, "selectedType"], globalSelectedType);
    Tree.set(["layers", mapId, key, "searchString"], globalSearchString);
    if (layerIsEnabled) Tree.set(["layers", mapId, key, "groupId"], groupId);
  };

  var enableBmpLayer = function (tree, key, callback) {
    Tree.select("layers", Tree.get("mapId"), key, "isEnabled").on("update", function (e) {
      if (e.data.currentData) {
        callback();
      }
    });
  };

  var safeRemove = function (map, layer) {
    if (layer) {
      map.removeLayer(layer);
    }
  };

  var selectBmpOnMap = function (idbmp) {
    var thisMarker = bmpMarkerMap[idbmp];
    L.Marker.stopAllBouncingMarkers();
    thisMarker.bounce(2);
  };

  var selectBmpOnModalMap = function (idbmp) {
    var thisMarker = bmpMarkerMap[idbmp];
    L.Marker.stopAllBouncingMarkers();
    thisMarker.bounce(3);
    thisMarker.on("click", function (e) {
      thisMarker.dragging.enable();
      $(".setLocationMessageBox").hide();
    });
    thisMarker.on("dragend", function (e) {
      getLocationData(thisMarker.getLatLng());
    });
  };

  var getLocationData = function (latlng) {
    var editData = Session.loadCurrentBmp();
    if (editData.hasOwnProperty("bmp_type")) {
      var bmp_type = editData.bmp_type;
    }
    var bmpId = editData.bmp_id;
    var idbmp = editData.idbmp;

    //clear inputs incase values are missing in new location;
    $("#basicInfo").find("input[class=auto], textarea").val("");
    var groupId = Session.loadContext()["groupId"];

    ReportApiCalls.getMarkerData(groupId, latlng.lat, latlng.lng, function (data) {
      //check for bmp outside of ms4 boundary
      if (data[0] && data.length > 0) {
        $('#basicInfo input[name="ms4Name"]').val(data[0].gname);
        $('#basicInfo input[name="catch_name"]').val(data[0].catchid);
        $('#basicInfo input[name="lu_name"]').val(data[0].combo);
        $('#basicInfo input[name="bmp_apn"]').val(data[0].parcelid);
        var updateData = {
          idbmp: idbmp,
          bmp_id: bmpId,
          bmp_luid: data[0].lu_id,
          lu_name: data[0].combo,
          bmp_groupid: groupId,
          bmp_longitude: latlng.lng,
          bmp_latitude: latlng.lat,
          bmp_apn: data[0].parcelid,
          catchid: data[0].gid,
        };
        if (typeof bmp_type !== "undefined") {
          updateData["bmp_type"] = bmp_type;
        }
        if (!updateData.bmp_apn) {
          delete updateData.bmp_apn;
          $('#basicInfo input[name="bmp_apn"]').val("N/A");
        }
        Session.replace("BMP", updateData);
      } else {
        $('#basicInfo input[name="ms4Name"]').val("N/A");
        $('#basicInfo input[name="catch_name"]').val("N/A");
        $('#basicInfo input[name="bmp_apn"]').val("N/A");
        $('#basicInfo input[name="lu_name"]').val("OTH");
        $('#basicInfo input[name="address"]').val("N/A");
        $('#basicInfo input[name="bmp_latitude"]').val("N/A");
        $('#basicInfo input[name="bmp_longitude"]').val("N/A");

        var outlier = {
          idbmp: idbmp,
          bmp_id: bmpId,
          bmp_luid: 7,
          lu_name: "OTH",
          bmp_groupid: groupId,
          bmp_longitude: latlng.lng,
          bmp_latitude: latlng.lat,
          bmp_apn: 0,
          catchid: 0,
        };
        if (typeof bmp_type !== "undefined") {
          outlier["bmp_type"] = bmp_type;
        }
        Session.replace("BMP", outlier);
      }
    });
  };

  var addTrashLinesLayer = function (map, trashLineLayer, data) {
    if (trashLineLayer) {
      map.removeLayer(trashLineLayer);
    }
    trashLineLayer = new (Layers.getLayers().TrashLineLayer)();
    trashLineLayer.addData(data);
    trashLineLayer.on("popupopen", function (e) {
      if (e.layer && e.layer.feature) {
        PopupPhotos.load(e.layer.feature.properties);
      }
    });
    return trashLineLayer.addTo(map);
  };

  var addTrashPointsLayer = function (map, trashPointLayer, data) {
    if (trashPointLayer) {
      map.removeLayer(trashPointLayer);
    }
    trashPointLayer = new (Layers.getLayers().TrashPointLayer)();
    trashPointLayer.addData(data);
    trashPointLayer.on("popupopen", function (e) {
      if (e.layer && e.layer.feature) {
        PopupPhotos.load(e.layer.feature.properties);
      }
    });
    return trashPointLayer.addTo(map);
  };

  var toggleLegendArrow = function (header) {
    if (header.find(".expand-arrow").hasClass("open")) {
      header.find(".expand-arrow").removeClass("open");
      header.closest(".legend-group").find(".legendContentContainer").slideUp();
    } else {
      header.find(".expand-arrow").addClass("open");
      header.closest(".legend-group").find(".legendContentContainer").slideDown();
    }
  };

  var startRWListener = function (currentMap, rwLayer) {
    currentMap.on("zoomend", function () {
      const streamIsEnabled = Tree.get("layers", Tree.get("mapId"), "streams", "isEnabled");
      if (currentMap.getZoom() <= 12) {
        if (streamIsEnabled) {
          currentMap.removeLayer(rwLayer);
        }
      } else {
        if (streamIsEnabled && !mapHasLayer(currentMap, rwLayer)) {
          currentMap.addLayer(rwLayer);
        }
      }
    });
  };

  var startStormDrainsZoomListener = function (currentMap, mapLayers) {
    currentMap.on("zoomend", function () {
      handleStormdrainDisplay(currentMap, mapLayers);
    });
  };

  var startLegendZoomListeners = function (currentMap) {
    currentMap.on("zoomend", function () {
      const thisLegend = $("#" + Tree.get("mapId")).find(".display-options");
      const thisStreamCheckbox = thisLegend.find("input[value=inputs-streams]");
      const thisStormDrainsCheckbox = thisLegend.find("input[value=inputs-stormdrains]");
      const thisDischargeCheckbox = thisLegend.find("input[value=inputs-outfallsSimple]");
      if (currentMap.getZoom() <= 12) {
        thisStreamCheckbox.parent().addClass("disabled");
        thisStormDrainsCheckbox.parent().addClass("disabled");
        thisDischargeCheckbox.parent().addClass("disabled");
      } else {
        thisStreamCheckbox.parent().removeClass("disabled");
        thisStormDrainsCheckbox.parent().removeClass("disabled");
        thisDischargeCheckbox.parent().removeClass("disabled");
      }
    });
  };

  var addCollectorLineOutlineLayer = function (currentMap, mapLayers, data) {
    if (mapLayers.collectorLineOutlineLayer) {
      currentMap.removeLayer(mapLayers.collectorLineOutlineLayer);
    }

    mapLayers.collectorLineOutlineLayer = new (Layers.getLayers().CollectorLineOutlineLayer)();
    mapLayers.collectorLineOutlineLayer.addData(data);
    mapLayers.collectorLineOutlineLayer.addTo(currentMap).bringToBack();
    setTimeout(function () {
      mapLayers.ms4BoundaryLayer.bringToBack();
    }, 1000);
    return mapLayers.collectorLineOutlineLayer;
  };

  var enableMapLayer = function (layerName) {
    var mapId = Tree.get("mapId");
    Tree.set(["layers", mapId, layerName, "isEnabled"], true);
    if (layerName === "muniCatchBasinReport") {
      Tree.set("enabledGeoServerLayers", layerName);
    }

    DisplayOptions.toggleLegend(layerName, true);
  };

  var removeDefaultLayers = function (layers) {
    var defaultLayers = MapConstants.defaultMapLayers;
    var filteredLayers = Object.keys(layers)
      .filter((key) => !defaultLayers.includes(key))
      .reduce((obj, key) => {
        obj[key] = layers[key];
        return obj;
      }, {});
    return filteredLayers;
  };

  var setMapLayers = function (layerName, showDefaultLayers = true) {
    const mapId = Tree.get("mapId");
    var map = TreeUpdates.getMap(mapId);
    var mapLayers = TreeUpdates.getMapLayers(mapId);

    clearG2Layers(map, mapLayers);
    addG2ClickListeners(map);
    clearViewLayers(Tree.get("mapId"), showDefaultLayers);
    enableMapLayer(layerName);
  };

  var addG2ClickListeners = function (map) {
    const dataView = Tree.get("dataView");

    if (dataView !== "muniCatchBasinView") {
      GeoServerLayerFunctions.removeMapClickListener(map);
    } else {
      GeoServerLayerFunctions.addMapClickListener(map);
    }
  };

  var clearG2Layers = function (map, mapLayers) {
    $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").removeClass("esri-dynamic-map-layer");
    if (mapHasLayer(map, mapLayers["muniCatchBasinReportLayer"])) {
      map.removeLayer(mapLayers["muniCatchBasinReportLayer"]);
    }
    if (mapHasLayer(map, mapLayers["muniCatchBasinReportLayerWfs"])) {
      map.removeLayer(mapLayers["muniCatchBasinReportLayerWfs"]);
    }
    if (mapHasLayer(map, mapLayers["muniCatchBasinReportLayerWms"])) {
      map.removeLayer(mapLayers["muniCatchBasinReportLayerWms"]);
    }
  };

  var clearViewLayers = function (
    mapId = Tree.get("mapId"),
    ignoreDefaultLayers = true,
    clearData = false,
  ) {
    var map = TreeUpdates.getMap(mapId);
    var mapNotInitialized = map === null;
    if (mapNotInitialized) return;
    var mapLayers = TreeUpdates.getMapLayers(mapId);
    var cleanedMapLayers = ignoreDefaultLayers ? removeDefaultLayers(mapLayers) : mapLayers;

    for (var layer in mapLayers) {
      if (mapHasLayer(map, cleanedMapLayers[layer]) && layer !== "basemap") {
        const layerTreeName = LayerFunctions.getLayerConfig(layer, "treeName", true);
        Tree.set(["layers", mapId, layerTreeName, "isEnabled"], false);
        if (clearData) {
          map.removeLayer(mapLayers[layer]);
          mapLayers[layer] = null;
          if (layerTreeName !== "catchments") {
            Tree.set(["layers", mapId, layerTreeName, "data"], CleanData.cleanGeoJSON([]));
          }
          _clearTelrTreeData(mapId);
        }
        DisplayOptions.toggleLegend(layerTreeName, false);
      }
    }
    clearLayerCheckboxes();
  };

  var _clearTelrTreeData = function (mapId) {
    Tree.set(["layers", mapId, "runoffProgress", "rawData"], []);
    Tree.set(["layers", mapId, "particulatesProgress", "rawData"], []);
    Tree.set(["layers", mapId, "runoffProgress", "isEnabled"], false);
    Tree.set(["layers", mapId, "particulatesProgress", "isEnabled"], false);
  };

  var clearLayerCheckboxes = function () {
    $("#report .layers-checkbox-div input:checkbox").each((index, element) => {
      if (!$(element).val().includes("ms4Boundary")) {
        $(element).prop("checked", false);
      }
    });
  };

  var setCurrentMapToLastMapExtent = function () {
    const lastMapId = Tree.get("lastMapId");
    const currentMapId = Tree.get("mapId");

    if (!currentMapId || !lastMapId || lastMapId === currentMapId) {
      return;
    }

    const lastMap = TreeUpdates.getMap(lastMapId);
    const currentMap = TreeUpdates.getMap(currentMapId);

    currentMap.setView(lastMap.getCenter(), lastMap.getZoom(), { zoomAnimation: false });
  };

  var mapHasLayer = function (map, mapLayer) {
    const hasLayer = mapLayer && map.hasLayer(mapLayer) ? true : false;
    return hasLayer;
  };

  return {
    addBasemap,
    addCatchLayer,
    addHighwayLayer,
    addModalCatchLayer,
    addBmpLayer,
    addParcelTileLayer,
    addLanduseLayer,
    addStreamLayer,
    whenLayerDataUpdated,
    safeRemove,
    selectBmpOnMap,
    selectBmpOnModalMap,
    addStormdrainLayer,
    addMiddleStormdrainLayer,
    addBaseStormdrainLayer,
    enableBmpLayer,
    addTrashLinesLayer,
    addTrashPointsLayer,
    toggleLegendArrow,
    addMs4BoundaryLayer,
    handleHideStormdrains,
    handleStormdrainDisplay,
    startLegendZoomListeners,
    addCollectorLineOutlineLayer,
    whenLayerToggled,
    addFcsLayer,
    addFullDrainageLayer,
    removeDefaultLayers,
    setMapLayers,
    zoomToCatchmentLayer,
    _clearTelrTreeData,
    setAllMapExtentsNotInitialized,
    getAllMapIds,
    resetAllMapSpatialViewsToMs4,
    clearLayersForAllMapIds,
    setCurrentMapToLastMapExtent,
    enableMapLayer,
    mapHasLayer,
  };
};

module.exports = MapFunctions();

const CleanData = require("./cleanData");
const MapStyles = require("./mapStyles");
const Layers = require("./layers");
const Tree = require("../../tree");
const Session = require("../../login/session");
const ReportApiCalls = require("../reportApiCalls");
const PopupPhotos = require("./popupPhotos");
const MapConstants = require("./mapConstants");
const Filters = require("./filters");
const PageFunctions = require("../pageFunctions");
const TreeUpdates = require("./treeUpdates");
const DisplayOptions = require("./displayOptions");
const LayerFunctions = require("../mapFunctions/layerFunctions");
const Tab = require("../tabs");
const SbmpProgressLayer = require("../bmpfcs/sbmpProgressLayer");
const SbmpProgressPopup = require("../bmpfcs/sbmpProgressPopup");
const FcsProgressLayer = require("../bmpfcs/fcsProgressLayer");
const FcsProgressPopup = require("../bmpfcs/fcsProgressPopup");
const GeoServerLayerFunctions = require("../../mapFunctions/geoServerLayerFunctions");
const MuniCatchBasinReportGeoServerLayer = require("../muni/g2/muniCatchBasinReportGeoServerLayer");
const FeatureFlag = require("../../settings/featureFlag");
const ToolSettings = require("../../settings/toolSettings");
