"use strict";

const UserConfiguration = function () {
  const stringKey = "user-configuration";
  const tabHeader = "2N User";

  var loadListeners = function (data) {};

  var unloadListeners = function () {};

  var getProps = function (data) {
    return {
      roleOptions: (ToolSettings.getDomainOptions("user_roles") || []).filter(
        (option) => option.value !== "server",
      ),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = UserConfiguration();

const ToolSettings = require("../toolSettings");
