"use strict";

const Tree = require("../tree");

const PopupPhotos = function () {
  const obsLayers = ["obs", "bench", "bmpobs", "parcelAssessment"];

  var obsCursor = Tree.select("obs_photos"); // fcs assessment
  var benchCursor = Tree.select("bench_photos");
  var bmpobsCursor = Tree.select("bmpobs_photos");
  var parcelAssessmentCursor = Tree.select("parcelAssessment_photos");

  var cursorByLayer = {};

  var currentId = null;
  var readOnlyMode = false;

  var loadListeners = function () {
    $("body").on("click", ".browse-photo", browsePhotoClick);

    $("body").on(
      "click",
      ".popup-general .obsPhoto, .popup-general .js-btnMorePhotos, .modal .obsPhoto, .modal .js-btnMorePhotos",
      showPopupPhotoModal,
    );

    $("body").on("change", ".modal-photo-input", _modalPhotoInputChange);

    $("#fcs-assessment-modal").on("shown.bs.modal", function () {
      if (Tree.get(["asset", "update"])) {
        load("obs", Tree.get(["obs_photos", "currentId"]));
      }
    });

    $("#benchmarkModal").on("shown.bs.modal", function () {
      if (Tree.get(["bmpram", "benchmarkUpdate"])) {
        load("bench", Tree.get(["bench_photos", "currentId"]));
      }
    });

    $("#observationModal").on("shown.bs.modal", function () {
      if (Tree.get(["bmpram", "bmpObsUpdate"])) {
        load("bmpobs", Tree.get(["bmpobs_photos", "currentId"]));
      }
    });

    $("#parcel-assessment-modal").on("shown.bs.modal", function () {
      if (Tree.get(["currentParcelData", "assessmentUpdate"])) {
        load("parcelAssessment", Tree.get(["parcelAssessment_photos", "currentId"]));
      }
    });

    //Tree listeners
    obsCursor.on("update", obsPhotoCursorUpdate);
    benchCursor.on("update", obsPhotoCursorUpdate);
    bmpobsCursor.on("update", obsPhotoCursorUpdate);
    parcelAssessmentCursor.on("update", obsPhotoCursorUpdate);
  };

  var obsPhotoCursorUpdate = function () {
    const layer = Tree.get("photo_layer");
    var photos = this.get(currentId);
    const tempPhotos = this.get("temps");

    if (tempPhotos && tempPhotos.length) {
      if (photos && photos.items && photos.items.length) {
        const combinedPhotoItems = photos.items.concat(tempPhotos);

        if (photos.isUploading) {
          renderThumbs(combinedPhotoItems, true, photos.progress);
        } else {
          renderThumbs(combinedPhotoItems, null, null);
        }
      } else {
        renderThumbs(tempPhotos, null, null);
      }
    } else {
      if (photos && photos.items && photos.items.length) {
        if (photos.isUploading) {
          renderThumbs(photos.items, true, photos.progress);
        } else {
          renderThumbs(photos.items, null, null);
        }
      } else {
        // renderThumbs([], null, null, true);
        const photoListClassSelector = getPhotoListClassSelectorByLayer(layer);
        clearThumbs(photoListClassSelector);
      }
    }
  };

  var browsePhotoClick = function () {
    $(this).next().trigger("click");
  };

  var popupCaptionClick = function ($slide, inputValue) {
    const layer = Tree.get("photo_layer");
    var photo = $slide.data("photo");

    if (layerHasTempPhotos(layer)) {
      const photosTreePath = getPhotosTreePathByLayer(layer);
      const temps = Tree.get([photosTreePath, "temps"]);

      for (let i = 0; i < temps.length; i++) {
        if (photo == temps[i].id) {
          temps[i].caption = inputValue;
          break;
        }
      }
    }

    if (currentId !== null) {
      Actions.updatePhotoCaption(currentId, photo, inputValue, layer);
    }
  };

  var popupDeletePhoto = function ($slide) {
    const layer = Tree.get("photo_layer");
    var photo = $slide.data("photo");

    if (layerHasTempPhotos(layer)) {
      const photosTreePath = getPhotosTreePathByLayer(layer);
      const temps = Tree.get([photosTreePath, "temps"]);
      for (let i = 0; i < temps.length; i++) {
        if (photo == temps[i].id) {
          temps.splice(i, 1);
          Tree.set([photosTreePath, "temps"], []);
          Tree.set([photosTreePath, "temps"], temps);
          break;
        }
      }
    }

    if (currentId !== null) {
      Actions.deletePhoto(currentId, photo, layer);
    }
  };

  var popupRotatePhotoClick = function ($slide, direction, currentRotation) {
    if (currentId === null) {
      PhotoCarousel.restoreRotation();
      return;
    }

    const layer = Tree.get("photo_layer");
    const index = $slide.index();
    var items = getPhotoData(layer, [currentId, "items"]);
    const photoId = items[index]?.filename;
    $(".photo-list")
      .find("[data-index='" + index + "']")
      .css({ transform: "rotate(" + currentRotation + "deg)" });
    if (photoId !== undefined) {
      ApiCalls.rotateImage(layer, currentId, photoId, direction, function (newPhotoUrl) {
        items[index].full = newPhotoUrl;
        items[index].filename = decodeURI(
          newPhotoUrl.substring(0, newPhotoUrl.indexOf("?")).split("/").pop(),
        );
        PhotoCarousel.restoreRotation();
      });
    } else {
      PhotoCarousel.restoreRotation();
    }
  };

  var showPopupPhotoModal = function () {
    const layer = Tree.get("photo_layer");
    let photoItems;
    if (layerHasTempPhotos(layer)) {
      const photosTreePath = getPhotosTreePathByLayer(layer);
      photoItems =
        currentId !== null && Tree.get(photosTreePath, currentId)
          ? Tree.get(photosTreePath, currentId).items
          : null;
      const temps = Tree.get([photosTreePath, "temps"]);

      if (photoItems && photoItems.length) {
        photoItems = photoItems.concat(temps);
      } else {
        photoItems = temps;
      }
    } else {
      photoItems = getPhotoData(layer, [currentId, "items"]);
    }

    const configuration = {
      delete: popupDeletePhoto,
      rotate: popupRotatePhotoClick,
      caption: popupCaptionClick,
    };
    const cantEditBmpPhotos =
      !UserPermissions.getPermission("can_create_bmps") && Tree.get("dataView") === "bmp";
    PhotoCarousel.load(photoItems, configuration, readOnlyMode || cantEditBmpPhotos);
    PhotoCarousel.showPhotoCarouselModalFromIndex($(this).data("index"));
  };

  var renderThumbs = function (items, isUploading, progress, obsOnly = false) {
    const layer = Tree.get("photo_layer");

    if (currentId) {
      const photos = getPhotoData(layer, [currentId]);
      if (photos && photos["items"]) {
        const cssList = Actions.initiatePhotoCss(photos["items"]);
        photos.cssList = cssList; //Clear the rotation records when modal opens
      }
    }

    if (obsLayers.includes(layer)) {
      const thumbsHtml = nunjucks.render("popups/photos/observationThumbs.njk", {
        photos: items,
        progress: progress,
        isUploading: isUploading,
      });
      var photoListClassSelector = getPhotoListClassSelectorByLayer(layer);
      if (layer === "parcelAssessment") {
        $(photoListClassSelector).empty().html(thumbsHtml);
      } else {
        $(".modal " + photoListClassSelector)
          .empty()
          .html(thumbsHtml);
      }
    } else {
      const thumbTemplate = "popupThumbs.njk";
      const thumbsHtml = nunjucks.render("popups/photos/" + thumbTemplate, {
        photos: items,
        progress: progress,
        isUploading: isUploading,
      });
      if (!obsOnly) {
        $(".popup-general .photo-list").empty().html(thumbsHtml);
      }
    }
  };

  var clearThumbs = function (selector) {
    $(selector).empty();
  };

  var load = function (layer, id = null, readOnly = false) {
    Tree.set("photo_layer", layer);
    currentId = id;
    readOnlyMode = readOnly;

    loadLayerListener(layer);

    if (id === null) {
      return;
    }

    var photos = getPhotoData(layer, [currentId]);
    if (photos === undefined) {
      Actions.loadPhotoList(currentId, layer);
    } else {
      renderThumbs(photos.items);
    }
  };

  var loadLayerListener = function (layer) {
    if (cursorByLayer[layer] || obsLayers.includes(layer)) {
      return;
    }

    const cursor = Tree.select(getTreeLayerPath(layer));

    cursor.on("update", function () {
      var photos = cursor.get(currentId);
      if (photos.isUploading) {
        renderThumbs(photos.items, true, photos.progress);
      } else {
        renderThumbs(photos.items, null, null);
      }
    });

    cursorByLayer[layer] = cursor;
  };

  var getPhotoData = function (layerName = Tree.get("photo_layer"), path = []) {
    return Tree.get([getTreeLayerPath(layerName), ...path]);
  };

  var getTreeLayerPath = function (layerName) {
    return layerName + "_photos";
  };

  var setCurrentId = function (newId) {
    currentId = newId;
  };

  var clearObsTempPhotos = function (layer) {
    setCurrentId(Tree.get("currentBmpFcs").idBmp);
    var photosTreePath = getPhotosTreePathByLayer(layer);
    var photoListClassSelector = getPhotoListClassSelectorByLayer(layer);

    Tree.set([photosTreePath, "temps"], []);
    Tree.set("photo_layer", "bmp");
    $(".modal " + photoListClassSelector).empty();
  };

  var clearAssessmentTempPhotos = function (layer) {
    var photosTreePath = getPhotosTreePathByLayer(layer);
    var photoListClassSelector = getPhotoListClassSelectorByLayer(layer);

    Tree.set([photosTreePath, "temps"], []);
    Tree.set("photo_layer", "bmp");
    $(photoListClassSelector).empty();
  };

  var fileInputChange = async function () {
    const layer = Tree.get("photo_layer");
    const input = $(this);

    const files = await getValidPhotosFromFileList(this.files);
    Actions.uploadPhotos(currentId, files, layer);
    input.val(""); // reset the input field
  };

  var storeTempPhotos = async function (layer, files) {
    assertIsArray(files);
    const photosTreePath = getPhotosTreePathByLayer(layer);

    for (const file of files) {
      const url = await FormPhotos.readFileAsDataUrl(file);
      Tree.select([photosTreePath, "temps"]).push({
        id: Date.now(),
        tempUrl: url,
        caption: "",
        filename: file.name,
        file: file,
      });
    }

    Tree.set("photo_layer", layer);
  };

  var assertIsArray = function (maybeArray) {
    if (!Array.isArray(maybeArray)) {
      throw new Error(`${JSON.stringify(maybeArray)} Must be an array.`);
    }
  };

  var _modalPhotoInputChange = async function () {
    var $input = $(this);
    var layerName = getObsPhotoLayerNameByModalId($input.closest(".modal").attr("id"));

    const files = await getValidPhotosFromFileList(this.files);
    await storeTempPhotos(layerName, files);
    $input.val("");
  };

  var getValidPhotosFromFileList = async function (fileList) {
    const maxFileSize = 8 * 1024 * 1024;
    const validFiles = [];

    for (const file of fileList) {
      if (await FormPhotos.validatePhotoFileAndWarn(file, maxFileSize)) {
        validFiles.push(file);
      }
    }

    return validFiles;
  };

  var getObsPhotoLayerNameByModalId = function (id) {
    switch (id) {
      case "fcs-assessment-modal":
        return "obs"; // fcs assessment photos are store as "obs"
      case "benchmarkModal":
        return "bench";
      case "observationModal":
        return "bmpobs";
      case "parcel-assessment-modal":
        return "parcelAssessment";
    }
  };

  var getPhotoListClassSelectorByLayer = function (layer) {
    if (obsLayers.includes(layer)) {
      return `.${Misc.camelToKebab(layer)}-photo-list`;
    }
  };

  var getPhotosTreePathByLayer = function (layer) {
    if (obsLayers.includes(layer)) {
      return `${layer}_photos`;
    }
  };

  var saveTempPhotos = function (layer, id) {
    var photosTreePath = getPhotosTreePathByLayer(layer);
    var temps = Tree.get([photosTreePath, "temps"]);

    if (temps.length) {
      for (let i = 0; i < temps.length; i++) {
        const temp = temps[i];
        Actions.uploadPhotos(id, [temp.file], layer, temp.caption);
      }
    }
  };

  var layerHasTempPhotos = function (layer) {
    var photosTreePath = getPhotosTreePathByLayer(layer);
    return obsLayers.includes(layer) && Tree.get([photosTreePath, "temps"]).length;
  };

  return {
    load,
    setCurrentId,
    loadListeners,
    clearObsTempPhotos,
    clearAssessmentTempPhotos,
    fileInputChange,
    saveTempPhotos,
    _modalPhotoInputChange,
  };
};

module.exports = PopupPhotos();

const Actions = require("../actions");
const ApiCalls = require("../apiCalls");
const PhotoCarousel = require("../general/photoCarousel");
const Misc = require("../misc");
const UserPermissions = require("../login/userPermissions");
const FormPhotos = require("../general/formPhotos");
