"use strict";

const MuniLiftStationsDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareMuniMLiftStations,
        template: "list/muniLiftStationsDataList.njk",
        layerName: "muniLiftStations",
      };
      DataList.init(config);
      initialRender();
      loadListeners();
      DataList.loadDomListeners();
    }
  };

  var loadListeners = function () {
    $(".data-list-container").off("click", ".map-button");
    $(".data-list-container").on("click", ".map-button", showMarkerOnMap);
  };

  var showMarkerOnMap = function () {
    var $tr = $(this).parents("tr");
    var id = $tr.data("id");

    MapFunctions.openPopupById(id);
    const mapButtonContainer = $(this).parents("td");
    DataList.addAnalyticsToMapIconOnAddress(mapButtonContainer.hasClass("address-col"));
  };

  var initialRender = function () {
    DataList.setSorting("name", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareMuniMLiftStations = function (muniLiftStations) {
    sortMuniLiftStationsBy(muniLiftStations);

    return {
      rows: muniLiftStations,
      options: {},
    };
  };

  var sortMuniLiftStationsBy = function (muniLiftStations) {
    const alphaSorter = SortUtilities.alphaSorter;
    const booleanSorter = SortUtilities.booleanSorter;
    var sortersDict = {
      name: alphaSorter,
      address: alphaSorter,
      type: alphaSorter,
      sump: booleanSorter,
      cof: SortUtilities.cofSorter,
    };
    DataList.sortWithDict(muniLiftStations, sortersDict);
  };

  return {
    init,
  };
};

module.exports = MuniLiftStationsDataListController();

const DataList = require("../../mapFunctions/dataList");
const SortUtilities = require("../../general/sortUtilities.js");
const MapFunctions = require("../../mapFunctions/mapFunctions.js");
