"use strict";

const MapPaneFunctions = function () {
  var createCustomMapPanes = function (map, mapId) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const offset = mapId === "modal" && rasterBasemap ? 60 : 0;

    // Custom overlay panes, default markerPane zindex = 400
    map.createPane("underCatchmentPane").style.zIndex = 410 - offset;
    map.createPane("catchmentPane").style.zIndex = 420 - offset;
    map.createPane("overCatchmentPane").style.zIndex = 430 - offset;
    map.createPane("catchmentViewPane").style.zIndex = 435 - offset;
    map.createPane("stormdrainPane").style.zIndex = 440 - offset;
    map.createPane("ms4BoundaryPane").style.zIndex = 450 - offset;
    map.createPane("ms4BoundaryGrayPane").style.zIndex = 451 - offset;
    map.createPane("overBoundaryPane").style.zIndex = 460 - offset;

    // Custom marker panes, default markerPane zindex = 600
    map.createPane("flowRoutingPane").style.zIndex = 580;
    map.createPane("outfallPane").style.zIndex = 590;
    map.createPane("trashPointPane").style.zIndex = 610;
    map.createPane("blueDotPane").style.zIndex = 640;
  };

  return {
    createCustomMapPanes,
  };
};

module.exports = MapPaneFunctions();

const FeatureFlag = require("../settings/featureFlag");
