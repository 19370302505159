"use strict";

const ConstructionProjectDataListController = function () {
  var inited = false;
  var config = null;

  var init = function () {
    if (inited) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareConstructionProjects,
        template: "list/constructionProjectDataList.njk",
        layerName: "constructionProjectG2",
      };
      DataList.init(config);
      initialRender();
      DataList.loadDomListeners([{ target: ".open-fact-sheet", callback: openFactSheet }]);
      DataList.loadDomListeners([
        { target: ".map-button-datalist-construction", callback: openPopup },
      ]);
    }
  };

  var openPopup = function () {
    const projectId = $(this).data("id");
    MapFunctions.openPopupById(projectId);
    Analytics.sendDataListEvent("address_to_map", "construction");
  };

  var initialRender = function () {
    DataList.setSorting("projectIdentifier", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareConstructionProjects = function (constructionProjects) {
    addSurvey123Urls(constructionProjects);
    addIconClasses(constructionProjects);
    _updateDisturbanceAreaForSortingPurpose(constructionProjects);
    sortConstructionProjectsBy(constructionProjects);
    return {
      rows: constructionProjects,
      options: {},
    };
  };

  var _updateDisturbanceAreaForSortingPurpose = function (constructionProjects) {
    for (const project of constructionProjects) {
      project.disturbanceArea = project.priorityInfo?.displayAreaOfDisturbance;
    }
  };

  var addSurvey123Urls = function (constructionProjects) {
    for (const project of constructionProjects) {
      ConstructionProjectPopup.addInspectionButtonProps(project, project);
    }
  };

  var addIconClasses = function (constructionProjects) {
    constructionProjects.forEach((project) => {
      project.iconClass = ConstructionIcon.getConstructionProjectIconClass(project, true);
    });
  };

  var sortConstructionProjectsBy = function (constructionProjects) {
    const dateSorter = DateTime.dbDateStringComparator;
    const alphaSorter = SortUtilities.alphaSorter;
    const booleanSorter = SortUtilities.booleanSorter;
    const phaseSorter = sortByConstructionPhase;

    var sortersDict = {
      projectIdentifier: alphaSorter,
      projectName: alphaSorter,
      streetAddress: alphaSorter,
      highPriority: booleanSorter,
      startDateIso: dateSorter,
      phase: phaseSorter,
      disturbanceArea: alphaSorter,
    };
    DataList.sortWithDict(constructionProjects, sortersDict);
  };

  var sortByConstructionPhase = function (phaseA, phaseB) {
    return getPhaseOrder(phaseB) - getPhaseOrder(phaseA);
  };

  var getPhaseOrder = function (phase) {
    const phaseMap = {
      planning: 1,
      planned: 2,
      construction: 3,
      "post-active": 4,
      completed: 5,
      certified: 6,
      shelved: 7,
      rejected: 8,
    };

    if (phaseMap[phase] === undefined) {
      throw new Error(`Unknown project phase ${phase}.`);
    }

    return phaseMap[phase];
  };

  var openFactSheet = function () {
    const projectId = DataList.getIdFromRow($(this));
    Tree.set(["factSheet", "previousDataSort"], Tree.get(["table", "dataSort"]));
    Filters.setDataSort("projectIdentifier");
    ProjectFactSheetController.initFactSheet(projectId);
    DataList.hide();
    $("#details-button-container").addClass("desktop-only");
    Analytics.sendDataListEvent("open_factsheet", "construction");
    FactSheet.handleFilterFlow();
  };

  return {
    init,
    sortByConstructionPhase,
    getPhaseOrder,
    addSurvey123Urls,
    _prepareConstructionProjects,
    _updateDisturbanceAreaForSortingPurpose,
  };
};

module.exports = ConstructionProjectDataListController();

const ConstructionIcon = require("../construction/constructionIcon");
const ConstructionProjectPopup = require("./constructionProjectPopup");
const DataList = require("../mapFunctions/dataList");
const DateTime = require("../dateTime");
const Filters = require("../mapFunctions/filters");
const SortUtilities = require("../general/sortUtilities");
const ProjectFactSheetController = require("../construction/projectFactSheetController");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Analytics = require("../general/analytics");
const FactSheet = require("../general/factSheet");
