"use strict";

const ImageResizer = function (file) {
  const iphonePhotoResolution = 4032;
  const maxIosCanvasSize = 4096; // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
  const oneMBSize = 1000000;

  var resize = async function (longEdgePixels = iphonePhotoResolution / 2, quality = 0.7) {
    try {
      const img = await loadImage();

      if (imagePixelsSmallerThan(img, longEdgePixels) || filesizeSmallerThan(oneMBSize)) {
        return file;
      }

      const canvas = resizeImage(img, longEdgePixels);

      return await toJpgFile(canvas, file, quality);
    } catch (e) {
      Analytics.reportException(e);
      return file;
    }
  };

  var loadImage = async function () {
    const img = document.createElement("img");
    const url = URL.createObjectURL(file);

    img.src = url;
    await img.decode();
    URL.revokeObjectURL(url);

    return img;
  };

  var imagePixelsSmallerThan = function (img, longEdgePixels) {
    return img.width <= longEdgePixels && img.height <= longEdgePixels;
  };

  var filesizeSmallerThan = function (filesize) {
    return file.size <= filesize;
  };

  var resizeImage = function (img, longEdgePixels) {
    let source = img;

    if (imagePixelsSmallerThan(img, maxIosCanvasSize)) {
      // Image quality sucks if we resize the image onto a canvas.
      // Resizing from one canvas to another is much better.
      source = drawToCanvas(img, img.width, img.height);
    }

    const scaledSize = getScaledImageSize(img, longEdgePixels);
    return drawToCanvas(source, scaledSize.width, scaledSize.height);
  };

  var drawToCanvas = function (img, width, height) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context.imageSmoothingQuality = "high";
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
    return canvas;
  };

  var getScaledImageSize = function (img, longEdgePixels) {
    if (img.width > img.height) {
      return {
        width: longEdgePixels,
        height: getShortEdgeWidth(longEdgePixels, img.width, img.height),
      };
    } else {
      return {
        width: getShortEdgeWidth(longEdgePixels, img.height, img.width),
        height: longEdgePixels,
      };
    }
  };

  var getShortEdgeWidth = function (longEdgePixels, imgLongEdge, imgShortEdge) {
    const scale = (longEdgePixels / imgLongEdge) * imgShortEdge;
    return Math.round(scale);
  };

  var toJpgFile = async function (canvas, file, quality) {
    const blob = await new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", quality));
    const filename = replaceExtension(file.name, ".jpg");
    return new File([blob], filename, { type: blob.type, lastModified: file.lastModified });
  };

  var replaceExtension = function (filename, newExtension) {
    const matchExtension = /(\.[^.]*)?$/;
    return filename.replace(matchExtension, newExtension);
  };

  return {
    resize,
  };
};

module.exports = ImageResizer;

const Analytics = require("../general/analytics");
