"use strict";

const InsightWidget = require("./insightWidget");

const StackedBar = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [
      {
        className: "hover-bar",
        getHoverHtml: StackedBar.prototype.getHoverHtml,
      },
    ];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 1;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

StackedBar.prototype = Object.create(InsightWidget.prototype);
StackedBar.prototype.constructor = StackedBar;

StackedBar.prototype.getHtml = function (insightData) {
  var title = this.widgetConfig.title;

  var html = nunjucks.render("insight/widgets/stackedBar.njk", {
    title,
    showExportButton: false,
  });

  return html;
};

StackedBar.prototype.renderChart = function (insightData, exportModal = false) {
  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }

  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  const containerWidth = $chartContainer.width();
  const containerHeight = $chartContainer.height();

  var margin = {
    ...defaults.margin,
    top: 55,
  };
  var width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom;

  var dataArray = this.widgetConfig.getDataArray(insightData),
    unit = this.widgetConfig.unit,
    legendData = this.widgetConfig.legendData();

  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right - 10)
    .attr("height", height + margin.top + margin.bottom + 10);

  svg
    .append("text")
    .attr("x", 25)
    .attr("y", 40)
    .attr("fill", "rgba(0,0,0,0.45)")
    .attr("font-size", "10px")
    .attr("font-weight", "400")
    .text(unit);

  var chartGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top + 10})`);

  var scaleX = d3
    .scaleBand()
    .domain(dataArray.bars.map((item) => item.label))
    .range([0, width - (dataArray.goal ? 30 : 0)])
    .padding(0.4);

  var axisX = d3
    .axisBottom(scaleX)
    .tickFormat((d) => d.label)
    .tickPadding(10)
    .tickSize(0);
  chartGroup
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(10," + height + ")")
    .call(axisX);

  function valuesSum(bar) {
    return Object.values(bar).reduce((sum, val) => sum + (typeof val === "number" ? val : 0), 0);
  }

  const maxY = Math.max(dataArray.goal || 0, ...dataArray.bars.map((bar) => valuesSum(bar)));

  var scaleY = d3.scaleLinear().domain([0, maxY]).range([height, 0]);
  var axisY = d3
    .axisLeft(scaleY)
    .tickFormat((n) => Misc.formatLargeNumber(n, 1, true))
    .ticks(5)
    .tickSize(0)
    .tickPadding(10)
    .tickSizeInner(-width);
  chartGroup.append("g").attr("class", "axis").attr("transform", "translate(10,0)").call(axisY);

  var bars = chartGroup
    .selectAll()
    .data(dataArray.bars)
    .enter()
    .append("g")
    .attr("class", function (d) {
      let classes = "bar-group";
      if (valuesSum(d) !== 0 && d.hoverKey) {
        classes += " hover-bar";
      }
      return classes;
    })
    .attr("data-hover-key", (d) => d.hoverKey);

  const barWidth = width / dataArray.bars.length - (width / dataArray.bars.length) * 0.3;

  bars
    .append("rect")
    .attr("class", "hover-background-rect")
    .attr("display", (d) => (valuesSum(d) !== 0 ? "block" : "none"))
    .attr("x", (d) => scaleX(d.label) - 5)
    .attr("y", 0)
    .attr("width", barWidth + 10)
    .attr("height", height)
    .attr("fill", "transparent");

  const legendKeys = Object.keys(legendData);
  const colors = Object.values(legendData).map((item) => item.color);
  const textColors = Object.values(legendData).map((item) => item.textColor);

  const color = d3.scaleOrdinal().domain(legendKeys).range(colors);

  const textColor = d3.scaleOrdinal().domain(legendKeys).range(textColors);

  const stackedData = d3.stack().keys(legendKeys)(dataArray.bars);

  chartGroup
    .append("g")
    .selectAll("g")
    .data(stackedData)
    .join("g")
    .attr("fill", (d) => color(d.key))
    .attr("pointer-events", "none")
    .selectAll("rect")
    .data((d) => d)
    .join("rect")
    .attr("x", (d) => scaleX(d.data.label))
    .attr("y", (d) => scaleY(d[1]))
    .attr("height", (d) => scaleY(d[0]) - scaleY(d[1]))
    .attr("width", barWidth)
    .each(function (d) {
      const key = this.parentNode.__data__.key;
      const lastKey = stackedData[stackedData.length - 1].key;
      const isTopBar = key === lastKey;
      const barHeight = scaleY(d[0]) - scaleY(d[1]);
      const fontSize = 10;
      const isBarTooSmall = barHeight < fontSize + 2;
      const value = (d[1] - d[0]).toFixed(1);

      d3.select(this.parentNode)
        .append("text")
        .attr("x", () => scaleX(d.data.label) + barWidth / 2)
        .attr("y", () => scaleY(d[1]))
        .attr("dy", () => {
          if (isBarTooSmall && isTopBar) {
            return -fontSize * 0.5;
          } else {
            return `${Math.min(fontSize * 1.5, barHeight / 2 + fontSize / 2 - 1)}px`;
          }
        })
        .attr("text-anchor", "middle")
        .text(() => NunjucksFilters.millions(value, 1))
        .attr("font-size", `${fontSize}px`)
        .attr("font-weight", () => (d.data.focus ? "bold" : "normal"))
        .attr("fill", () => textColor(key))
        .attr("display", () =>
          (isBarTooSmall && !isTopBar) || value === "0.0" ? "none" : "block",
        );
    });

  bars
    .append("text")
    .data(dataArray.bars)
    .attr("text-anchor", "middle")
    .attr("x", (d) => scaleX(d.label) + barWidth / 2)
    .attr("y", () => scaleY(0) + 20)
    .attr("font-size", "11px")
    .attr("font-weight", (d) => (d.focus ? "bold" : "normal"))
    .text((d) => d.label);

  if (dataArray.goal) {
    chartGroup
      .append("path")
      .style("stroke-dasharray", "2, 2")
      .attr("stroke", "#D96F53")
      .attr("d", () =>
        d3.line()([
          [10, scaleY(dataArray.goal)],
          [width, scaleY(dataArray.goal)],
        ]),
      );

    chartGroup
      .append("text")
      .attr("fill", "#D96F53")
      .attr("font-size", "16px")
      .attr("text-anchor", "end")
      .attr("alignment-baseline", "middle")
      .attr("transform", () => `translate(${width}, ${scaleY(dataArray.goal) - 10})`)
      .text(Misc.formatLargeNumber(dataArray.goal, 1));
    chartGroup
      .append("text")
      .attr("fill", "#D96F53")
      .attr("font-size", "16px")
      .attr("text-anchor", "end")
      .attr("alignment-baseline", "middle")
      .attr("transform", () => `translate(${width}, ${scaleY(dataArray.goal) + 12})`)
      .text("Goal");
  }

  this.appendStandardChartLegend(
    svg,
    legendData,
    this.widgetConfig.legendCountPerRow,
    "left",
    true,
  );
};

StackedBar.prototype.getHoverHtml = function (insightData, $hoverTarget) {
  var hoverData = this.widgetConfig.getHoverData(insightData),
    hoverKey = $hoverTarget.data("hover-key"),
    data = hoverData[hoverKey],
    template = data.template;

  var html = nunjucks.render(template, data);

  return html;
};

module.exports = StackedBar;

const d3 = require("d3-v6");
const Misc = require("../../misc");
const NunjucksFilters = require("../nunjucksFilters");
