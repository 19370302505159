"use strict";

var BreadCrumbsDropdown = function () {
  var setDropdown = async function (pageName = null) {
    if (pageName === null) {
      await _setToolDropdown();
    } else {
      _setToolLink(pageName);
    }
  };

  var _setToolLink = function (pageName) {
    const displayName = PageFunctions.getPageConfigByPageName(pageName).displayName;
    const isRegulator = Progeny.rootGroupIsRegulator();

    $("#bread-crumbs-dropdown").html(
      nunjucks.render("report/navbar/toolLink.njk", { pageName, displayName, isRegulator }),
    );
  };

  var _setToolDropdown = async function () {
    const toolDropdownItems = Panels.getToolDropdownItems();

    var enabledTools = Tree.get("user", "enabledTools");

    if (enabledTools === null) {
      enabledTools = await ReportApiCalls.getToolsEnabledForUser();
      Tree.set(["user", "enabledTools"], enabledTools);
    }

    $("#bread-crumbs-dropdown").html(nunjucks.render("report/navbar/toolDropdown.njk"));
    $("#tool-dropdown").html(Panels.getToolDropdownHtml(toolDropdownItems));
  };

  return { setDropdown };
};

module.exports = BreadCrumbsDropdown();

const Tree = require("../tree");
const ReportApiCalls = require("./reportApiCalls");
const Panels = require("../panels");
const PageFunctions = require("./pageFunctions");
const Progeny = require("../login/progeny");
