"use strict";

const InformationPage = function () {
  const stringKey = "information";
  const headerInformation = "Information";
  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    $page = $(".modal-dynamic-content");
    const renderData = InventoryModal.getAllData("property-inventory");
    const html = nunjucks.render("esg/modals/propertyInventory/informationPage.njk", {
      ...renderData,
      hiddenFields: EsgSettings.hiddenFields(),
    });
    InventoryModal.setModalDynamicContent(html);
    _loadListeners();
  };

  var _loadListeners = function () {
    Form.initializeAndLoadListeners($page, PropertyInventoryModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("change", "input[name=swUtility]", _toggleSwUtility);
    $page.on("change", "[name=useManualConsumptiveUse]", _changeUseManualConsumptiveUse);
    $page.on("change", "[name=ownershipStatus]", _changeOwnershipStatus);
    $page.on("input", "[name=consumptiveUseManual]", _inputConsumptiveUseManual);
  };

  var _unloadListeners = function () {
    $page.off("change", "input[name=swUtility]", _toggleSwUtility);
    $page.off("change", "[name=useManualConsumptiveUse]", _changeUseManualConsumptiveUse);
    $page.off("change", "[name=ownershipStatus]", _changeOwnershipStatus);
    $page.off("input", "[name=consumptiveUseManual]", _inputConsumptiveUseManual);
  };

  var _toggleSwUtility = function () {
    const swUtility = PropertyInventoryModalController.getLatestData("swUtility");
    $("fieldset.annual-stormwater-utility-fee, fieldset.fee-fiscal-year").toggleClass(
      "hidden",
      !swUtility,
    );
  };

  var _changeUseManualConsumptiveUse = function () {
    _toggleManualConsumptiveUse($(this));
  };

  var _changeOwnershipStatus = function () {
    const ownershipStatus = $(this).val();
    $("fieldset.sold-date").toggleClass("hidden", ownershipStatus !== "sold");
    $("fieldset.acquired-date").toggleClass("hidden", ownershipStatus === "prospective");
  };

  var _inputConsumptiveUseManual = function () {
    PropertyInventoryModalController.setData("useManualConsumptiveUse", true);
    PropertyInventoryModalController.setData("consumptiveUseManual", $(this).val() * 1e6);
  };

  var _toggleManualConsumptiveUse = function ($this) {
    const $consumptiveUse = $this.closest(".consumptive-use");
    const consumptiveUse = Number(
      PropertyInventoryModalController.getLatestData("consumptiveUse") || 0,
    );
    const consumptiveUseManual = Number(
      PropertyInventoryModalController.getLatestData("consumptiveUseManual") || null,
    );
    $consumptiveUse.toggleClass("manual", $this.is(":checked"));
    const $input = $consumptiveUse.find("[name=consumptiveUseManual]");
    const newVal = consumptiveUseManual || consumptiveUse;
    $input.val(NunjucksFilters.millions(newVal, 2));
    PropertyInventoryModalController.setData("consumptiveUseManual", newVal);
    $input.select();
  };

  var cleanUp = function () {
    _unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
  };
};

module.exports = InformationPage();

const Form = require("../../../general/form");
const InventoryModal = require("../../../general/inventoryModal");
const NunjucksFilters = require("../../../general/nunjucksFilters");
const EsgSettings = require("../../esgSettings");
const PropertyInventoryModalController = require("../propertyInventoryModalController");
