"use strict";

function IndcomInventoryModalController() {
  const indcomInventoryFormKey = "indcom-inventory";

  function loadFormListener() {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  }

  function refreshState() {
    Tree.set(["facilityInventoryState", "currentFacilityLatLng"], "");
    Tree.set(["facilityInventoryState", "currentFacilityData"], {});
    Tree.set(["facilityInventoryState", "existing"], false);
    Tree.set(["facilityInventoryState", "facilityIdentifier"], null);
  }

  function getLatestIndcomInventoryData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(indcomInventoryFormKey, path);
  }

  function onFormInput(e, value, path) {
    if (path[0] === "facilityIdentifier") {
      Tree.set(["facilityInventoryState", "facilityIdentifier"], value);
    }
    checkEnableSaveButton(value, path);
  }

  function loadExistingIndcomlMarker(zoom) {
    const markerIcon = getIndcomMarker(zoom);
    const latlng = getIndcomLatLng();

    const marker = new L.marker(latlng, {
      icon: markerIcon,
      zIndexOffset: 2000,
    });
    ModalMap.setSingleMarker(marker);
  }

  function getIndcomMarker(zoom) {
    const indcomData = InventoryModal.getAllData(indcomInventoryFormKey);

    indcomData.zoom = zoom;

    return IndcomIcon.getFacilityIconMarker(indcomData);
  }

  function getExistingData(path) {
    const pathToCheck = $.isArray(path) ? path : [path];
    return Tree.get(["asset", indcomInventoryFormKey, ...pathToCheck]);
  }

  function unsetFormDataField(path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(indcomInventoryFormKey, path);
    return Form.getDataFromForm(indcomInventoryFormKey, false);
  }

  async function invokeIndcomInventoryModal(maybeId, options, startingPage) {
    InventoryModal.setOpenInventory(indcomInventoryFormKey);
    if (maybeId) {
      const data = await ApiCalls.getFacility(maybeId);
      Tree.set(["facilityInventoryState", "currentFacilityData"], data);
      Tree.set(["facilityInventoryState", "existing"], true);
      return initializeIndcomInventoryModalPages(data, "indcom-set-location", {
        existingLocation: true,
      });
    } else {
      refreshState();
      return initializeIndcomInventoryModalPages(
        IndcomInventoryConstants.getIndcomInventoryDefaults(),
        startingPage,
        options,
      );
    }
  }

  async function initializeIndcomInventoryModalPages(processedData, startingPage, options = {}) {
    const pageOptions = {
      existingLocation: !!(processedData || {}).projectIdentifier,
      ...options,
    };

    $("#modalMap").hide();
    Tree.set(["asset", indcomInventoryFormKey], processedData);
    resetAllPages();
    const inputPages = getIndcomInventoryPages();
    await InventoryModal.showModal(inputPages, startingPage, pageOptions, {
      saveAndCloseHandler: checkSaveAndClose,
      cancelHandler: closeInventory,
      formHasUpdatesHandler: () => !noUpdatesInForm(),
      onPageChangeHandler: highlightIncompleteFields,
    });
    InventoryModal.disableSaveButton();
    InventoryModal.resetModalTitle();
    setModalTitle();

    InventoryModal.setHideInventoryOnClose(!options?.modalInModal);
    loadFormListener();
  }

  function getIndcomInventoryPages() {
    const basePages = [
      IndcomInventorySetLocation,
      IndcomInventoryGeneralInfo,
      IndcomInventoryContacts,
      IndcomInventoryFiles,
    ];

    return basePages;
  }

  function getIndcomLatLng() {
    return {
      lat: getLatestIndcomInventoryData("latitude"),
      lng: getLatestIndcomInventoryData("longitude"),
    };
  }

  function setModalTitle() {
    if (Tree.get("facilityInventoryState", "existing")) {
      const facilityIdentifier = Tree.get(
        "facilityInventoryState",
        "currentFacilityData",
        "facilityIdentifier",
      );
      const assetDisplayName = ToolSettings.getSetting(
        "constants",
        "assetDisplayNames",
        "indcom-facility",
      );
      InventoryModal.setModalTitle(`${assetDisplayName} ID: `, facilityIdentifier);

      const facilityType = Tree.get(
        "facilityInventoryState",
        "currentFacilityData",
        "displayFacilityType",
      );
      InventoryModal.setModalTitle("Type: ", facilityType, "type");
    } else {
      InventoryModal.setModalTitle("Add New Facility");
      InventoryModal.setModalTitle("", null, "type");
    }
  }

  function resetAllPages() {
    const allPages = getIndcomInventoryPages();
    allPages.forEach((page) => {
      if (page?.resetState) {
        page.resetState();
      }
    });
  }

  function checkSaveAndClose() {
    return new Promise((resolve) => {
      saveAndCloseModal();
      resolve(false);
    });
  }

  function closeInventory() {
    resetInventory();
  }
  function resetInventory() {
    Tree.unset("modalMapSingleMarkerLastValidLatLng");
    Form.clearForm(indcomInventoryFormKey);
    ModalMap.resetModalMap();
    ModalMap.setModalMapView();
  }

  function checkEnableSaveButton(value, path) {
    const existingValue = getExistingData(path);
    if (existingValue !== undefined && value === existingValue) {
      Form.manuallyUnsetField(indcomInventoryFormKey, path);
    }
    if (noUpdatesInForm()) {
      InventoryModal.readySaveState(false);
    } else {
      InventoryModal.readySaveState(true);
    }
  }
  function setFormDataField(path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(indcomInventoryFormKey, path, value);
  }
  function noUpdatesInForm() {
    const noUpdates = Object.keys(Form.getDataFromForm(indcomInventoryFormKey, false)).length === 0;
    return noUpdates;
  }
  function renderPageContent(html) {
    InventoryModal.setModalDynamicContent(html);
  }
  function closeModal() {
    InventoryModal.hide();
  }

  function reopenPopupForUpdatedData(facilityId = null) {
    facilityId = !facilityId ? Tree.get(["layers", "indcomFacilities", "selectedId"]) : facilityId;
    if (facilityId) {
      MapFunctions.openGeoServerPopup("indcomFacilities", facilityId);
    }
  }

  async function saveAndCloseModal() {
    try {
      const exists = Tree.get(["facilityInventoryState", "existing"]);
      const currentId = Tree.get(["facilityInventoryState", "currentFacilityData", "id"]);
      const facilityToLoad = Form.getDataFromForm(indcomInventoryFormKey);
      _processFacilityFormData(facilityToLoad);
      if (exists) {
        const data = Form.toFormData({
          facilityId: currentId,
          facilityUpdates: facilityToLoad,
        });
        await ApiCalls.updateFacility(data);
        handleCleanUpAfterSave();
        reopenPopupForUpdatedData(currentId);
      } else {
        const newFacilityData = $.extend(
          true,
          {},
          IndcomInventoryConstants.getIndcomInventoryDefaults(),
          facilityToLoad,
        );
        const data = Form.toFormData({
          facilityData: newFacilityData,
        });
        await ApiCalls.createNewFacility(data);
        handleCleanUpAfterSave();
      }
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  }

  function handleCleanUpAfterSave() {
    refreshState();
    closeModal();
    IndcomFacilityGeoServerLayer.invalidateLayerData();
    IndcomFacilityGeoServerLayer.loadLayer();
  }

  function getInventoryForRender() {
    return InventoryModal.getAllData(indcomInventoryFormKey);
  }

  function getIndcomInventoryFormKey() {
    return indcomInventoryFormKey;
  }

  function _processFacilityFormData(facilityToLoad) {
    if (facilityToLoad.contacts !== undefined) {
      const existingContacts = getExistingData("contacts") ?? [];
      facilityToLoad.contacts = CommonModalFunctions.cleanIds(
        facilityToLoad.contacts,
        existingContacts,
      );
    }

    if (facilityToLoad.facilityFiles !== undefined) {
      const existingFiles = getExistingData("facilityFiles") ?? [];
      facilityToLoad.facilityFiles = CommonModalFunctions.cleanIds(
        facilityToLoad.facilityFiles,
        existingFiles,
      );
      CommonModalFunctions.removeUploadOrLocation(facilityToLoad.facilityFiles);
    }
  }

  function highlightIncompleteFields() {
    const incompleteRequiredFields = getExistingData("incompleteRequiredFields") ?? [];
    CommonModalFunctions.highlightEmptyFormFields(
      $("#inventory-modal .modal-dynamic-content"),
      incompleteRequiredFields,
      Form.getDataFromForm(indcomInventoryFormKey, false),
    );
  }

  return {
    invokeIndcomInventoryModal,
    initializeIndcomInventoryModalPages,
    setFormDataField,
    renderPageContent,
    unsetFormDataField,
    getExistingData,
    onFormInput,
    saveAndCloseModal,
    closeModal,
    getLatestIndcomInventoryData,
    loadExistingIndcomlMarker,
    getInventoryForRender,
    getIndcomInventoryFormKey,
  };
}

module.exports = IndcomInventoryModalController();

const ApiCalls = require("../apiCalls");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const ModalMap = require("../mapFunctions/modalMap");
const Tree = require("../tree");
const UploadValidator = require("../general/uploadValidator");
const IndcomInventoryConstants = require("./indcomInventoryConstants");
const IndcomInventoryContacts = require("./indcomInventoryContacts");
const IndcomInventoryFiles = require("./indcomInventoryFiles");
const IndcomInventorySetLocation = require("./indcomInventorySetLocation");
const IndcomInventoryGeneralInfo = require("./indcomInventoryGeneralInfo");
const IndcomIcon = require("./indcomIcon");
const IndcomFacilityGeoServerLayer = require("./indcomFacilityGeoServerLayer");
const MapFunctions = require("../mapFunctions/mapFunctions");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const ToolSettings = require("../settings/toolSettings");
