"use strict";

const ProjectAssociatedBmpLayer = function () {
  var markerReferences = {};

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      "projectAssociatedBmp",
      mapId,
      function (isEnabled, sameSpatialFilter) {
        if (isEnabled) {
          if (
            mapLayers.projectAssociatedBmpLayer &&
            (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
          ) {
            map.addLayer(mapLayers.projectAssociatedBmpLayer);
          } else {
            loadProjectAssociatedBmps(mapId);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.projectAssociatedBmpLayer)) {
            map.removeLayer(mapLayers.projectAssociatedBmpLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated("projectAssociatedBmp", mapId, function (data) {
      data = data.filter((item) => !item.deleted);
      mapLayers.projectAssociatedBmpLayer = addProjectAssociatedBmpLayer(
        map,
        mapLayers.projectAssociatedBmpLayer,
        data,
        mapId,
      );
      AddBmpsTable.render(data);
      ProjectInventoryAddBmps.renderAddBmpsHtml(data);
    });
  };

  var loadProjectAssociatedBmps = async function (mapId) {
    var projectBmps = ProjectInventoryModalController.getExistingConstructionData("bmps") || [];
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    Tree.set(["layers", "projectAssociatedBmp", dataPath], projectBmps);
  };

  var addProjectAssociatedBmpLayer = function (map, projectAssociatedBmpLayer, data, mapId) {
    const CleanData = require("../mapFunctions/cleanData");

    var geoJson = CleanData.cleanGeoJson(data);

    if (projectAssociatedBmpLayer) {
      map.removeLayer(projectAssociatedBmpLayer);
    }
    projectAssociatedBmpLayer = L.featureGroup();
    L.geoJson(geoJson, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;
        const markerOpacity = getMarkerOpacity(mapId, properties.idBmp);
        const draggable = AddBmpsMap.bmpIsEditable(properties);
        const marker = L.marker(latlng, {
          draggable: draggable,
          icon: BmpFcsIcon.getIconMarker(
            properties.bmpScore,
            properties.bmpTypeObj.isCentralized,
            properties.bmpTypeObj.abbreviation,
            properties.isFcs,
            properties.bmpObservationDue,
            properties.phase,
          ),
          opacity: markerOpacity,
        });
        marker.on("dragend", markerDragend);
        storeMarkerReference(properties.idbmp, marker);
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => getProjectAssociatedBmpPopupHtml(feature.properties),
          MapFunctions.getPopupOptions(),
        );
        layer.on({
          click: highlightTable,
        });
      },
    }).addTo(projectAssociatedBmpLayer);

    projectAssociatedBmpLayer.on("popupclose", onPopupClose);

    return projectAssociatedBmpLayer.addTo(map);
  };

  var getProjectAssociatedBmpPopupHtml = function (bmp) {
    var popupProps = getProjectAssociatedBmpPopupProps(bmp);
    var props = $.extend({}, bmp, popupProps);
    return nunjucks.render("popups/projectAssociatedBmpPopup.njk", props);
  };

  var getProjectAssociatedBmpPopupProps = function (bmp) {
    var props = {};
    props.popupColor = BmpFcsIcon.getIconColor(bmp.bmpScore, bmp.phase);
    return props;
  };

  var getMarkerOpacity = function (mapId, idBmp) {
    return mapId === "modal"
      ? MapFunctions.getModalMapMarkerOpacity(idBmp, Tree.get(["asset", "basicInfo", "idBmp"]))
      : 1;
  };

  var markerDragend = function (event) {
    var marker = event.target;
    var idBmp = marker.feature.properties.idbmp;
    var latlng = marker.getLatLng();
    AddBmpsMap.markerDragendHandler(idBmp, latlng);
  };

  var addBmpData = function (newData, mapId = "modal") {
    ProjectTreatmentTypes.addByBmp(newData);
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var dataCursor = Tree.select(["layers", "projectAssociatedBmp", dataPath]);
    dataCursor.push(newData);
    saveLayerDataToForm();
  };

  var removeBmpDataByIdBmp = function (idBmp, mapId = "modal") {
    var bmpData = getDataAndIndexByIdBmp(idBmp, mapId).data;

    if (bmpData.isTemp) {
      var dataPath = Actions.getLayerDataPathByMapId(mapId);
      var dataCursor = Tree.select(["layers", "projectAssociatedBmp", dataPath]);
      dataCursor.apply(function (data) {
        return data.filter((item) => item.idbmp !== idBmp);
      });
      saveLayerDataToForm();
    } else {
      updateDataByIdBmp(idBmp, { deleted: true }, mapId);
    }

    ProjectTreatmentTypes.deleteByBmp(bmpData);
  };

  var updateDataByIdBmp = function (idBmp, newData, mapId = "modal") {
    const oldBmp = getDataAndIndexByIdBmp(idBmp, mapId).data;

    LayerDataFunctions.updateLayerDataById("projectAssociatedBmp", idBmp, newData, mapId);
    saveLayerDataToForm();

    const newBmp = getDataAndIndexByIdBmp(idBmp, mapId).data;
    ProjectTreatmentTypes.updateTypeByBmp(oldBmp, newBmp);
  };

  var getCurrentData = function (mapId = "modal") {
    return LayerDataFunctions.getCurrentLayerData("projectAssociatedBmp", mapId);
  };

  var getDataAndIndexByIdBmp = function (idBmp, mapId = "modal") {
    return LayerDataFunctions.getLayerDataAndIndexById("projectAssociatedBmp", idBmp, mapId);
  };

  var saveLayerDataToForm = function (mapId = "modal") {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var dataCursor = Tree.select(["layers", "projectAssociatedBmp", dataPath]);
    var bmps = dataCursor.get() || [];
    var processedData = processDataForFormUpdates(bmps);
    Form.manuallySetFormDataField("project-inventory", ["bmps"], processedData);
    Misc.toggleDisabled($(".add-new-save-button"), false);
  };

  var processDataForFormUpdates = function (bmps) {
    var processedData = [];

    bmps.forEach((bmp) => {
      let processedBmpData;
      if (bmp.isTemp) {
        processedBmpData = getProcessedNewBmpData(bmp);
      } else if (bmp.deleted) {
        processedBmpData = getProcessedDeletedBmpData(bmp);
      } else {
        processedBmpData = getProcessedUpdatedBmpData(bmp);
      }
      processedData.push(processedBmpData);
    });
    return processedData;
  };

  var getProcessedNewBmpData = function (bmp) {
    const FormFunctions = require("../mapFunctions/formFunctions");
    var nonNullInventoryDefaults = FormFunctions.removeNullFields(
      FormConstants.inventoryDefaultFields,
    );

    delete nonNullInventoryDefaults.tt;
    delete nonNullInventoryDefaults.ttFlowDirection;

    var newData = {
      bmpGroupid: Tree.get(["activeGroup", "groupId"]),
      phase: bmp.phase,
      bmp: bmp.isBmp,
      fcs: bmp.isFcs,
      treatmentRate: bmp.treatmentRate,
    };
    setBmpNameUpdates(newData, bmp);
    setBmpTypeUpdates(newData, bmp);
    setBmpLocationUpdates(newData, bmp);
    setBmpDetailsFormUpdates(newData, bmp);

    return Object.assign({}, nonNullInventoryDefaults, newData);
  };

  var getProcessedDeletedBmpData = function (bmp) {
    return {
      idbmp: bmp.idbmp,
      deleted: true,
    };
  };

  var getProcessedUpdatedBmpData = function (bmp) {
    const updatedDetailsArray = [
      bmp.bmp_wqcapUpdated,
      bmp.bmp_trateUpdated,
      bmp.diversionUpdated,
      bmp.bmp_flowUpdated,
      bmp.drainsoutUpdated,
      bmp.treatmentCapacityUnitUpdated,
      bmp.bmp_footprintUpdated,
      bmp.footprintUnitUpdated,
      bmp.intake_rateUpdated,
      bmp.bmp_wetUpdated,
      bmp.wetPoolCapacityUnitUpdated,
      bmp.bmp_irateUpdated,
      bmp.bmp_drawdownUpdated,
      bmp.flowLengthUpdated,
      bmp.bmpImpareaUpdated,
      bmp.installationDateUpdated,
    ];
    var updatedData = {
      idbmp: bmp.idbmp,
    };
    if (bmp.bmpNameUpdated) {
      setBmpNameUpdates(updatedData, bmp);
    }
    if (bmp.bmpTypeUpdated) {
      setBmpTypeUpdates(updatedData, bmp);
    }
    if (bmp.locationUpdated) {
      setBmpLocationUpdates(updatedData, bmp);
    }
    if (updatedDetailsArray.includes(true)) {
      setBmpDetailsFormUpdates(updatedData, bmp);
    }

    return updatedData;
  };

  var setBmpDetailsFormUpdates = function (newData, bmp) {
    newData.bmp_trate = bmp.bmp_trate;
    newData.bmp_flow = bmp.bmp_flow;
    newData.diversion = bmp.diversion;
    newData.drainsout = bmp.drainsout;
    newData.bmp_wqcap = bmp.bmp_wqcap;
    newData.treatmentCapacityUnit = bmp.treatmentCapacityUnit;
    newData.bmp_footprint = bmp.bmp_footprint;
    newData.footprintUnit = bmp.footprintUnit;
    newData.intake_rate = bmp.intake_rate;
    newData.bmp_wet = bmp.bmp_wet;
    newData.wetPoolCapacityUnit = bmp.wetPoolCapacityUnit;
    newData.bmp_irate = bmp.bmp_irate;
    newData.bmp_drawdown = bmp.bmp_drawdown;
    newData.flowLength = bmp.flowLength;
    newData.bmpImparea = bmp.bmpImparea;
    newData.installationDate = bmp.installationDate;
  };

  var setBmpNameUpdates = function (newData, bmp) {
    newData.bmpId = bmp.bmpName;
  };

  var setBmpTypeUpdates = function (newData, bmp) {
    newData.bmpType = bmp.bmpTypeObj.sortOrder;
    newData.bmpTypeName = bmp.bmpTypeObj.name;
    newData.bmpTypeAbbr = bmp.bmpTypeObj.abbreviation;
  };

  var setBmpLocationUpdates = function (newData, bmp) {
    newData.bmpLatitude = bmp.bmp_latitude;
    newData.bmpLongitude = bmp.bmp_longitude;
    newData.bmpApn = bmp.bmp_apn;
    newData.luName = bmp.lu_name;
    newData.bmpLuid = bmp.bmp_luid;
  };

  var refreshLayer = function (mapId = "modal") {
    LayerDataFunctions.refreshLayer("projectAssociatedBmp", mapId);
  };

  var show = function () {
    Actions.toggleLayer("projectAssociatedBmp", true, "modal");
  };

  var hide = function () {
    Actions.toggleLayer("projectAssociatedBmp", false, "modal");
  };

  var highlightTable = function (e) {
    var properties = e.target.feature.properties;
    var idBmp = properties.idbmp;

    AddBmpsTable.scrollToAndHighlightTableRow(idBmp);
  };

  var onPopupClose = function () {
    AddBmpsTable.removeTableHighlight();
  };

  var storeMarkerReference = function (idBmp, marker) {
    markerReferences[idBmp] = marker;
  };

  var getMarkerReference = function (idBmp) {
    return markerReferences[idBmp];
  };

  var canEditBmpOfType = function (bmpTypeAbbreviation) {
    return bmpTypeAbbreviation !== "?";
  };

  return {
    loadLayerListenersForMap,
    loadProjectAssociatedBmps,
    show,
    hide,
    addBmpData,
    removeBmpDataByIdBmp,
    updateDataByIdBmp,
    getCurrentData,
    refreshLayer,
    getMarkerReference,
    processDataForFormUpdates,
    canEditBmpOfType,
  };
};

module.exports = ProjectAssociatedBmpLayer();

const Actions = require("../actions");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Form = require("../general/form");
const Misc = require("../misc");
const FormConstants = require("../mapFunctions/formConstants");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const LayerDataFunctions = require("../general/layerDataFunctions");
const ProjectTreatmentTypes = require("./projectTreatmentTypes");
const AddBmpsTable = require("../mapFunctions/addBmpsTable");
const AddBmpsMap = require("../mapFunctions/addBmpsMap");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const ProjectInventoryAddBmps = require("../construction/projectInventoryAddBmps");
