"use strict";

const ProjectInventoryFiles = function () {
  const stringKey = "project-files";
  const headerInformation = "Files";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
    $page.on("click", ".save-button", saveFileData);
    $page.on("click", ".edit-button", editFile);
    $page.on("click", `[name*="location-type"]`, setLocationRadioClick);
    $page.on("click", ".new-file", insertNewFile);
    $page.on("click", ".delete-button", deleteFileButtonClick);
    $page.on("click", ".delete-editing-button", deleteEditingFileButtonClick);
    $page.on("click", ".save-initial-button", saveInitialFileData);
  };

  var unloadListeners = function () {
    $page.off("click", ".save-button");
    $page.off("click", ".edit-button");
    $page.off("click", `[name*="location-type"]`);
    $page.off("click", ".new-file");
    $page.off("click", ".delete-button");
    $page.off("click", ".delete-editing-button");
    $page.off("click", ".save-initial-button", saveInitialFileData);
  };

  var render = function (options = {}) {
    const project = InventoryModal.getAllData("project-inventory");
    const props = getProps(project);
    renderFilesHtml(props);
    initializeExistingFiles(props);
    loadListeners();
    setInitialState(props, options);
  };

  var getProps = function (project) {
    return {
      permitsAndPlans: prepareFiles(project),
      fileTypes: ProjectInventoryConstants.getFileTypeOptions(),
      uploadLocation: ProjectInventoryConstants.uploadLocationOptions,
    };
  };

  var prepareFiles = function (props) {
    return props.permitsAndPlans
      .filter((file) => !file.deleted)
      .map((file) => ({
        ...file,
        displayApprovalDate: getDisplayDateFromIsoDate(file),
        displayType: getDisplayFileType(file.type),
      }))
      .sort((a, b) => a.id - b.id);
  };

  var setInitialState = function (props, options) {
    const numFiles = ((props || {}).permitsAndPlans || []).length;
    if (options.editFile) {
      editFileById(options.editFile);
    } else if (numFiles === 0 || options.newFile) {
      $page.find(".new-file").hide();
      stageNewFile();
      const index = $page.find(".file-display").first().data("index");
      $page.on(
        "input drop",
        `[name*="permitsAndPlans[${index}]"], [data-name*="permitsAndPlans[${index}]"]`,
        stageInitialFileObj,
      );
    }
  };

  var insertNewFile = function () {
    const { html, newFile, currentFilesLength } = createFileHtml();
    Form.manuallySetFormDataField(
      "project-inventory",
      ["permitsAndPlans", currentFilesLength],
      newFile,
    );
    $page.find(".project-files").prepend(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(newFile);
  };

  var stageNewFile = function () {
    const { html, newFile, currentFilesLength } = createFileHtml(true);
    $page.on("input", `[name*="permitsAndPlans[${currentFilesLength}]"]`, createFormFileEntry);
    $page.find(".project-files").prepend(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(newFile);
  };

  var createFormFileEntry = function (e) {
    const filePosition = $(e.currentTarget).parents(".file-display").data("index");
    stageInitialFileObj(e);
    removeInitialInputListener(filePosition);
  };

  var createFileHtml = function (initialFile = false) {
    const fileTypes = ProjectInventoryConstants.getFileTypeOptions();
    var uploadLocation = ProjectInventoryConstants.uploadLocationOptions;
    uploadLocation = uploadLocation.find((location) => {
      return location.value === "external-url";
    });

    const newId = Date.now();
    const newFile = {
      id: newId,
      type: fileTypes[0].value,
      locationType: uploadLocation.value,
      displayLocationType: uploadLocation.name,
      approvalDateIso: DateTime.getTodayIso(),
    };
    const currentFilesLength = getCurrentFilesLength();

    return {
      html: nunjucks.render("modals/projectInventory/projectInventoryNewFile.njk", {
        fileTypes: fileTypes,
        file: newFile,
        uploadLocation: ProjectInventoryConstants.uploadLocationOptions,
        index: currentFilesLength,
        initialFile: initialFile,
      }),
      newFile: newFile,
      currentFilesLength: currentFilesLength,
    };
  };

  var getCurrentFilesLength = function () {
    const data = InventoryModal.getAllData("project-inventory");
    return (data.permitsAndPlans || []).length;
  };

  var stageInitialFileObj = function (e) {
    const $firstFile = $page.find(".project-files .file").first();
    const fileId = $firstFile.data("id");
    const filePosition = $(e.currentTarget).parents(".file").find(".file-display").data("index");
    if (!getFileById(fileId)) {
      const type = $firstFile.find(`[name="permitsAndPlans[${filePosition}][type]"]`).val();
      const locationType = $firstFile
        .find(`[name="permitsAndPlans[${filePosition}][location-type]"] option:selected`)
        .val();
      const approvalDateIso = $firstFile
        .find(`[name="permitsAndPlans[${filePosition}][approval-date-iso]"]`)
        .val();
      Form.manuallySetFormDataField(
        "project-inventory",
        ["permitsAndPlans", filePosition, "id"],
        fileId,
      );
      Form.manuallySetFormDataField(
        "project-inventory",
        ["permitsAndPlans", filePosition, "locationType"],
        locationType,
      );
      Form.manuallySetFormDataField(
        "project-inventory",
        ["permitsAndPlans", filePosition, "type"],
        type,
      );
      Form.manuallySetFormDataField(
        "project-inventory",
        ["permitsAndPlans", filePosition, "approvalDateIso"],
        DateTime.truncateMilliseconds(approvalDateIso),
      );
    }

    $page.off(
      "input drop",
      `[name*="permitsAndPlans[${filePosition}]"], [data-name*="permitsAndPlans[${filePosition}]"] `,
      stageInitialFileObj,
    );
  };

  var initializeAdditionalFormFields = function (file) {
    const displayDate = getIsoDateFromDisplayDate(file);
    initializeDatePicker(displayDate);
    initializeProjectDropzone(file, false);
    showInputByLocationType(file.locationType);
  };

  var initializeDatePicker = function (date) {
    Form.initializeDatePickers($page, "project-inventory");
    if (date) {
      DateTimePicker.setDateOnElement(
        $page.find("[name*=approval-date-iso]").assertLength(1),
        date,
      );
    }
  };

  var initializeExistingFiles = function (project) {
    project.permitsAndPlans.forEach((existingFile) => {
      initializeProjectDropzone(existingFile, true);
    });
  };

  var initializeProjectDropzone = function (permitPlan, readOnly) {
    const $parent = $page.find(`[data-id=${permitPlan.id}]`);
    const dropzoneName = $parent.find(".drop-zone").attr("name");
    let newFile = [];
    let existingFile = [];
    if ($.isArray(permitPlan.fileUploadLocation)) {
      newFile = permitPlan.fileUploadLocation;
    } else if (permitPlan.uploadedFilename) {
      existingFile = [permitPlan.uploadedFilename];
    }
    const dropzone = Form.initializeDropzone("project-inventory", $parent, {
      newFiles: newFile,
      existingFiles: existingFile,
      maxNumberFiles: 1,
      readOnly,
    });

    configureDropzoneHandlers(permitPlan.id, dropzoneName, dropzone);
  };

  var configureDropzoneHandlers = function (permitPlanId, dropzoneName, dropzone) {
    dropzone.setDownloadHandler(() => ApiCalls.downloadPermitPlanFile(permitPlanId));
    dropzone.setRemoveExistingCallback((file) => {
      removeFileFromForm(dropzoneName, file);
    });
  };

  var setLocationRadioClick = function (e) {
    const value = $(e.currentTarget).val();
    showInputByLocationType(value);
  };

  var editFile = function (e) {
    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    editFileById(fileId);
  };

  var editFileById = function (fileId) {
    const fileInfo = getFileById(fileId);
    const options = getFileTypeOptionsWithFile(fileInfo);

    const html = nunjucks.render("modals/projectInventory/projectInventoryModifyFile.njk", {
      fileTypes: options,
      uploadLocation: ProjectInventoryConstants.uploadLocationOptions,
      file: fileInfo,
      index: getFileIndexById(fileId),
    });
    $(`.file[data-id=${fileId}]`).html(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(fileInfo);
  };

  var getFileTypeOptionsWithFile = function (fileInfo) {
    const options = Array.from(ProjectInventoryConstants.getFileTypeOptions());

    if (
      !options.some(function (option) {
        return option.value === fileInfo.type;
      })
    ) {
      options.unshift(getFileType(fileInfo.type));
    }

    return options;
  };

  var saveInitialFileData = function (e) {
    stageInitialFileObj(e);
    saveFileData(e);
  };

  var saveFileData = function (e) {
    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    let file = getFileById(fileId);
    const type = ProjectInventoryConstants.uploadLocationOptions.find((option) => {
      return option.value === file.locationType;
    });
    if (type === undefined) {
      throw new Error(`File type ${file.locationType} does not exist.`);
    }
    file.displayLocationType = type.name;
    file = addAbsoluteUrlsToFiles([file])[0];
    const html = nunjucks.render("modals/projectInventory/projectInventoryExistingFile.njk", {
      file: {
        ...file,
        displayApprovalDate: getDisplayDateFromIsoDate(file),
        displayType: getDisplayFileType(file.type),
      },
    });
    removeInitialInputListener();
    toggleEditSaveButtonsDisabled(false);
    $file.closest("section").html(html);
    initializeProjectDropzone(file, true);
  };

  var deleteFileButtonClick = function (e) {
    showDeleteFileModal(e, false);
  };

  var deleteEditingFileButtonClick = function (e) {
    showDeleteFileModal(e, true);
  };

  var showDeleteFileModal = function (e, editingFile) {
    const $file = $(e.currentTarget).closest(".file");
    const fileId = $file.data("id");
    const displayFileType = getDisplayFileTypeById(fileId);
    if (displayFileType) {
      const deleteCallback = function () {
        deleteFile(fileId);
        if (editingFile === true) {
          toggleEditSaveButtonsDisabled(false);
        }
      };

      MessageModal.showConfirmWarningModal(
        `You have selected to delete this ${displayFileType}. To permanently delete this file from your 2NFORM data, click Delete. To keep the record, click Cancel.`,
        deleteCallback,
        "Cancel",
        "Delete",
      );
    } else {
      removeInitialInputListener();
      $(`[data-id="${fileId}"]`).remove();
      toggleEditSaveButtonsDisabled(false);
    }
  };

  var deleteFile = function (fileId) {
    const fileIndex = getFileIndexById(fileId);
    const existingFileIds = (
      ProjectInventoryModalController.getExistingConstructionData("permitsAndPlans") || []
    ).map((file) => {
      return file.id;
    });

    if (existingFileIds.includes(fileId)) {
      Form.manuallySetFormDataField("project-inventory", ["permitsAndPlans", fileIndex], {
        deleted: true,
        id: fileId,
      });
    } else {
      Form.manuallyUnsetField("project-inventory", ["permitsAndPlans", fileIndex]);
    }
    $(`[data-id="${fileId}"]`).remove();
  };

  var toggleEditSaveButtonsDisabled = function (disabled) {
    if (disabled === false) {
      $page.find(".new-file").show();
    }
    $(".edit-button, .new-file").prop("disabled", disabled);
  };

  var renderFilesHtml = function (project) {
    project.permitsAndPlans = addAbsoluteUrlsToFiles(project.permitsAndPlans || []);
    const html = nunjucks.render("modals/projectInventory/projectInventoryFiles.njk", project);
    ProjectInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  function addAbsoluteUrlsToFiles(files) {
    return files.map((file) => {
      const makeAbsolute = file.externalUrl && !/^(https?:)?\/\/.*$/.test(file.externalUrl);
      file.absoluteUrl = makeAbsolute ? "//" + file.externalUrl : file.externalUrl;
      return file;
    });
  }

  var getFileById = function (fileId) {
    const data = InventoryModal.getAllData("project-inventory");
    return data.permitsAndPlans.find((file) => {
      return file.id === fileId;
    });
  };

  var getFileIndexById = function (fileId) {
    const data = InventoryModal.getAllData("project-inventory");
    if (data.permitsAndPlans) {
      return data.permitsAndPlans.findIndex((file) => {
        return fileId === (file || {}).id;
      });
    }
  };

  var getDisplayFileTypeById = function (fileId) {
    const fileIndex = getFileIndexById(fileId);
    const fileType = InventoryModal.getLatestData("project-inventory", [
      "permitsAndPlans",
      fileIndex,
      "type",
    ]);
    return fileType === undefined ? undefined : getDisplayFileType(fileType);
  };

  var getDisplayDateFromIsoDate = function (file) {
    if (file.approvalDateIso !== undefined) {
      return DateTime.formatIsoString(file.approvalDateIso);
    } else {
      return file.displayApprovalDate;
    }
  };

  var getIsoDateFromDisplayDate = function (file) {
    if (file.approvalDateIso === undefined) {
      return DateTime.parseDateToIso(file.displayApprovalDate);
    } else return file.approvalDateIso;
  };

  var getDisplayFileType = function (fileValue) {
    return getFileType(fileValue).displayName;
  };

  var getFileType = function (fileValue) {
    return ProjectInventoryConstants.getAllFileTypeOptions().find((options) => {
      return options.value === fileValue;
    });
  };

  var cleanUp = function () {
    unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var showInputByLocationType = function (locationType) {
    $page.find(".file-text-description").toggle(locationType === "location");
    $page.find(".file-drop-zone").toggle(locationType === "upload");
    $page.find(".file-external-url").toggle(locationType === "external-url");
  };

  const removeFileFromForm = function (dropzoneName, file) {
    const pathParts = Form.getPathPartsFromName(dropzoneName);
    Form.manuallySetFormDataField("project-inventory", pathParts, "");
  };

  var removeInitialInputListener = function (length) {
    const currentFilesLength = length || getCurrentFilesLength();
    $page.off("input", `[name*="permitsAndPlans[${currentFilesLength}]"]`, createFormFileEntry);
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    deleteFile,
  };
};

module.exports = ProjectInventoryFiles();

const ProjectInventoryModalController = require("./projectInventoryModalController");
const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const Form = require("../general/form");
const DateTime = require("../dateTime");
const DateTimePicker = require("../general/dateTimePicker");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
