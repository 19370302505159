"use strict";

var ProgressBarChart = function () {
  var render = function (data, extendedView, shouldShowMilestones) {
    var dataView = Tree.get("dataView");

    if (dataView === "trashView") {
      data = BarChartData.prepareTrash(data, extendedView);
      renderBarChart(data, extendedView ? 9 : 5, !extendedView, shouldShowMilestones);
    } else if (dataView === "runoffView") {
      data = BarChartData.prepareRunoff(data, extendedView);
      renderBarChart(data, extendedView ? 9 : 5, !extendedView, false);
    } else if (dataView === "particulatesView") {
      data = BarChartData.prepareParticulate(data, extendedView);
      renderBarChart(data, extendedView ? 9 : 5, !extendedView, false);
    } else {
      console.error(`Trying to render bar charts with unknown dataView: ${dataView}`);
    }
  };

  // This tutorial is helpful for understanding some conventions used:
  // https://bost.ocks.org/mike/bar/
  var renderBarChart = function (
    yearsData,
    numYearsToRender,
    largeMarginRight,
    shouldShowMilestones,
  ) {
    yearsData = addFutureYearsData(yearsData, numYearsToRender);

    if (!yearsData?.length) {
      return;
    }

    const tableBrowserWidth = 300;
    const chartDiv = "bar-chart-d3";
    const margin = ChartStyles.getMargin(largeMarginRight);
    // This is the width of just the chart, not padding or y-axis tick space
    const w = $(window).width() - margin.left - margin.right - tableBrowserWidth;
    // let maxW = 600;
    // let minW = 400;
    // w = Math.max(minW, Math.min(maxW, w));

    // Not sure why, but it takes a 150 px translation to get below the nav bars
    const titleBarsOffestFromTop = 110;

    const height = ChartStyles.sizes.chartHeight; //$(window).height() - margin.top - margin.bottom - titleBarsHeight;

    $("." + chartDiv).html("");

    const svg = d3
      .select("." + chartDiv)
      .append("svg")
      .attr("width", w + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", getTranslateString(margin.left, titleBarsOffestFromTop));

    setupYearsData(yearsData);

    const yScale = d3.scale.linear().rangeRound([height, 0]);

    const paddingFraction = 0.3;
    const outerPaddingFraction = paddingFraction / 2;
    const xScale = d3.scale
      .ordinal()
      .rangeBands([0, w], paddingFraction, outerPaddingFraction)
      .domain(yearsData.map((datum) => datum.year));

    const xRectWidth = xScale(yearsData[1].year) - xScale(yearsData[0].year);
    const xRectPadding = (paddingFraction * xRectWidth) / 2;
    const xBarWidth = xRectWidth - 2 * xRectPadding;

    const yScaleBeyondTallestBar = 1.15;
    let yFullHeight = d3.max(yearsData, (yearDatum) => yearDatum.total);
    yFullHeight = Math.max(yFullHeight, 0.05);
    yScale.domain([0, yFullHeight * yScaleBeyondTallestBar]);

    addLabelPositionsToYearsData(yearsData, yScale);
    addSpecialRoundingToYearsData(yearsData);

    const yearColumnsBehind = svg.selectAll("g.year-columns-behind").data(yearsData);

    yearColumnsBehind
      .enter()
      .append("g")
      .attr("class", "year-columns-behind")
      .attr("transform", function (d) {
        return getTranslateString(xScale(d.year), 0);
      });

    renderYellowHighlightRectangles(
      yearColumnsBehind,
      xRectWidth,
      xRectPadding,
      height,
      shouldShowMilestones,
    );

    renderAxes(svg, xScale, yScale, w, yearsData);

    const yearColumnsFront = svg.selectAll("g.year-columns-front").data(yearsData);

    yearColumnsFront
      .enter()
      .append("g")
      .attr("class", function (d) {
        let classes = "year-columns-front";

        if (!d.submitted) {
          classes += " not-submitted";
        }

        return classes;
      })
      .attr("transform", function (d) {
        return getTranslateString(xScale(d.year), 0);
      });

    renderBars(yearColumnsFront, xBarWidth, height, xRectPadding, yScale, yFullHeight, xScale);

    if (shouldShowMilestones) {
      renderMilestoneLines(yearColumnsFront, xBarWidth, yScale, yFullHeight);
    }

    finishYearColumnTitles(yearsData);

    svg
      .append("text")
      .attr("class", "bar-chart-title")
      .text(
        Tree.get("ms4BarChartTitleOveride")
          ? Tree.get("ms4BarChartTitleOveride")
          : MapTitle.getSpatialTitle(),
      )
      .attr("x", -ChartStyles.sizes.chartHorizontalPadding - 12)
      .attr("y", -80);
    Tree.set("ms4BarChartTitleOveride", null);

    svg
      .append("line")
      .attr("class", "bar-chart-title-underline")
      .attr("x1", -ChartStyles.sizes.chartHorizontalPadding - 12)
      .attr("x2", w + margin.right - 12)
      .attr("y1", -75)
      .attr("y2", -75)
      .attr("stroke-width", 1)
      .attr("stroke", "#aaa");

    renderAdditionalFieldsRows(yearColumnsFront, xBarWidth, height + 39, shouldShowMilestones);

    renderBarSegmentLabels(yearColumnsFront, yearsData, xBarWidth, w, xScale, yScale);

    //Creating lines that go underneath the labels on the bottom row
    const line1H = height + 65;
    svg
      .append("line")
      .attr("x1", -ChartStyles.sizes.chartHorizontalPadding)
      .attr("x2", w)
      .attr("y1", line1H)
      .attr("y2", line1H)
      .attr("stroke-width", 1)
      .attr("stroke", "#e6e6e6");

    var line2H = height + 2 * 65;
    var line3H = height + 3 * 65;
    var line4H = height + 4 * 65;
    svg
      .append("line")
      .attr("x1", -ChartStyles.sizes.chartHorizontalPadding)
      .attr("x2", w)
      .attr("y1", line2H)
      .attr("y2", line2H)
      .attr("stroke-width", 1)
      .attr("stroke", "#e6e6e6");

    svg
      .append("line")
      .attr("x1", -ChartStyles.sizes.chartHorizontalPadding)
      .attr("x2", w)
      .attr("y1", line3H)
      .attr("y2", line3H)
      .attr("stroke-width", 1)
      .attr("stroke", "#e6e6e6");

    if (shouldShowMilestones) {
      svg
        .append("line")
        .attr("x1", -ChartStyles.sizes.chartHorizontalPadding)
        .attr("x2", w)
        .attr("y1", line4H)
        .attr("y2", line4H)
        .attr("stroke-width", 1)
        .attr("stroke", "#e6e6e6");
    }

    renderRowLabels(svg, shouldShowMilestones, yearsData);
    renderLegend(svg, shouldShowMilestones, yearsData);

    //Year box header thing -- line across
    svg
      .append("line")
      .attr("x1", 0 - ChartStyles.sizes.chartHorizontalPadding)
      .attr("x2", w)
      .attr("y1", -15)
      .attr("y2", -15)
      .attr("stroke-width", 1)
      .attr("stroke", "#808080");

    const AnnualReports = require("../../annualReports");
    const selectedYear = AnnualReports.getSelectedYear();
    BarChartController.highlightYear(selectedYear, "header");
  };

  var setupYearsData = function (yearsData) {
    yearsData
      .filter((yearDatum) => !yearDatum.isFuture)
      .forEach((yearDatum) => {
        const groups = yearDatum.barSegmentGroups;
        let y0 = 0;
        let finalNonzeroGroup;

        groups.forEach((segmentGroup) => {
          const value = segmentGroup.value == undefined ? 0 : segmentGroup.value;

          segmentGroup.y0 = y0;
          segmentGroup.y1 = y0 += value;
          segmentGroup.yDelta = value;
          segmentGroup.isFinalGroup = false;
          if (value > 0 && segmentGroup.format === "bar") {
            finalNonzeroGroup = segmentGroup;
          }
        });

        if (finalNonzeroGroup !== undefined) {
          finalNonzeroGroup.isFinalGroup = true;
        }

        yearDatum.total = groups[groups.length - 1].y1;
        yearDatum.allZero = yearDatum.total == 0;
      });

    const maxTotal = yearsData
      .filter((yearDatum) => !yearDatum.isFuture)
      .reduce((prev, curr) => (prev.year > curr.year ? prev : curr)).total;

    const additionalValuesForFuture = getAdditionalValuesForFuture(yearsData);

    yearsData
      .filter((yearDatum) => yearDatum.isFuture)
      .forEach((yearDatum) => {
        yearDatum.y0 = 0;
        yearDatum.y1 = maxTotal;
        yearDatum.yDelta = maxTotal;
        yearDatum.allZeros = maxTotal == 0;
        yearDatum.additionalValues = filterOutCopied(yearDatum.additionalValues);
        // Order isn't guaranteed here, it's by chance that total is first value
        yearDatum.additionalValues = additionalValuesForFuture.concat(yearDatum.additionalValues);
      });
  };

  var filterOutCopied = function (additionalValues) {
    return additionalValues.filter((additionalValue) => !additionalValue.copiedIntoFuture);
  };

  var addLabelPositionsToYearsData = function (yearsData, yScale) {
    yearsData
      .filter((d) => !d.isFuture)
      .forEach((yearDatum) => {
        const groups = yearDatum.barSegmentGroups;
        groups.forEach((group, i) => {
          if (i > 0 && i < groups.length - 1) {
            if (
              (yScale(0) - yScale(groups[i - 1].yDelta) < 20 &&
                yScale(0) - yScale(group.yDelta) < 30) ||
              (yScale(0) - yScale(group.yDelta) < 20 &&
                yScale(0) - yScale(groups[i + 1].yDelta) < 30)
            ) {
              group.overlappingLabelShortSegment = true;
            }
          }

          if (yScale(0) - yScale(group.yDelta) < 20) {
            group.shortSegment = true;
            if (i == 0) {
              group.shortBottomSegment = true;
            }
            if (i == groups.length - 1) {
              group.shortTopSegment = true;
            }
          }
        });
      });
  };

  var addSpecialRoundingToYearsData = function (yearsData) {
    yearsData
      .filter((d) => !d.isFuture)
      .forEach((yearDatum) => {
        yearDatum.barSegmentGroups.forEach((group) => {
          if (yearDatum.total < 1) {
            group.roundToHundredths = true;
          } else if (yearDatum.total < 10) {
            group.roundToTenths = true;
          }
        });
      });
  };

  var getAdditionalValuesForFuture = function (yearsData) {
    const additionalFieldNames = yearsData
      .filter((yearDatum) => !yearDatum.isFuture)[0]
      .additionalValues.filter((additionalField) => additionalField.copiedIntoFuture)
      .map((additionalField) => additionalField.name);

    return additionalFieldNames.map((name) => {
      return yearsData
        .filter((yearDatum) => !yearDatum.isFuture)
        .map((yearDatum) => {
          return yearDatum.additionalValues.filter((additionalField) => {
            additionalField.year = yearDatum.year;
            return additionalField.name === name;
          })[0];
        })
        .reduce(
          (aF1, aF2) => {
            const maxYearValue = [aF1, aF2].reduce((prev, curr) =>
              prev.year > curr.year ? prev : curr,
            ).value;

            return {
              name: aF1.name || aF2.name,
              value: aF2.copiedIntoFuture || aF1.copiedIntoFuture ? maxYearValue : undefined,
              clickable: false,
              format: aF1.format || aF2.format,
              unit: aF1.unit || aF2.unit,
              isFuture: true,
            };
          },
          { value: 0 },
        );
    });
  };

  var renderYellowHighlightRectangles = function (
    yearColumns,
    xRectWidth,
    xRectPadding,
    height,
    shouldShowMilestones,
  ) {
    yearColumns
      .selectAll("rect.yellow-rectangle")
      .data((d) => [d])
      .enter()
      .append("rect")
      .attr("width", xRectWidth)
      .attr("height", shouldShowMilestones ? height + 309 : height + 244)
      .attr("class", function (d) {
        return "yellow-rectangle yellow-rectangle-" + d.year;
      })
      .attr("id", function (d) {
        return "yellow-rectangle-" + d.year;
      })
      .attr("fill", "#f8f6d1")
      .attr("transform", function (d) {
        return getTranslateString(-xRectPadding, -50);
      })
      .style("text-align", "left");
  };

  var renderAxes = function (svg, xScale, yScale, w, yearsData) {
    const xAxisFunc = d3.svg.axis().scale(xScale).orient("top");

    const yAxisFunc = d3.svg
      .axis()
      .scale(yScale)
      .ticks(7)
      .orient("left")
      .innerTickSize(w + ChartStyles.sizes.chartHorizontalPadding)
      .tickPadding(10);

    // y-axis markers
    const yAxis = svg
      .append("g")
      .attr("class", "y-axis")
      .attr("transform", getTranslateString(w, 0))
      .call(yAxisFunc);

    const yAxisText = yAxis
      .selectAll("text")
      .style("text-anchor", "start")
      .attr("dy", "-.5em")
      .attr("dx", "1em")
      .attr("fill", "#808080");

    yAxisText
      .filter((d, i) => i === 0)
      .text(function (data) {
        return `0 ${yearsData[0].barSegmentLabel.verticalUnits}`;
      });

    const xAxisTop = svg
      .append("g")
      .attr("transform", getTranslateString(0, -30))
      .attr("class", "x-axis-top")
      .call(xAxisFunc);

    xAxisTop.selectAll("text").attr("y", "0.2em");
  };

  var renderRowLabels = function (svg, shouldShowMilestones, yearsData) {
    var labelDesc = yearsData[0].additionalValues.map(function (value, i) {
      value.yPos = ChartStyles.getRowYPos(i + 1);
      return value;
    });

    const textGroup = svg
      .selectAll("text.y")
      .data(labelDesc)
      .enter()
      .append("text")
      .attr("class", "y")
      .attr("dy", function (d) {
        if (d.format === "unit") {
          return `-${ChartStyles.sizes.verticalTextPadding}`;
        }
        return "0";
      })
      .attr("y", function (d) {
        return d.yPos;
      })
      .attr("class", function (d) {
        return "yLeft";
      })
      .attr("x", -ChartStyles.sizes.columnWidth)
      .text(function (d) {
        return d.name;
      })
      .style("text-align", "left");

    textGroup
      .filter(function (d) {
        return d.format === "unit";
      })
      .append("tspan")
      .attr("x", (d) => {
        return -ChartStyles.sizes.columnWidth;
      })
      .attr("dy", ChartStyles.sizes.verticalTextSpacing)
      .text(function (d) {
        return `(${d.unit})`;
      });
  };

  var renderAdditionalFieldsRows = function (
    yearColumns,
    columnWidth,
    firstRowY,
    shouldShowMilestones,
  ) {
    const textRenderer = (d) => {
      if (d.value === undefined || d.value === null) return;
      if (d.format === "percent") {
        return formatNumberString(d.value, 0) + "%";
      } else if (d.format === "unit") {
        return formatNumberString(d.value);
      } else if (d.format === "milestone" && shouldShowMilestones) {
        return formatNumberString(d.value, 0) + "%";
      }
    };

    yearColumns.each(function (yearDatum) {
      d3.select(this)
        .selectAll("text.additional-row-value")
        .data((d) => d.additionalValues)
        .enter()
        .append("text")
        .attr("transform", getTranslateString(columnWidth / 2, 0))
        .attr("width", columnWidth)
        .style("cursor", yearDatum.isFuture ? "auto" : undefined)
        .attr("class", function (d, i) {
          const rowNumClassName = "row-" + i + "-value";
          let theClass = "additional-row-value " + rowNumClassName;
          theClass += " " + rowNumClassName + "-" + yearDatum.year;
          if (typeof window.location.href.split("?")[1] !== "undefined") {
            theClass += " noHover";
          }
          if (d.clickable && !yearDatum.isFuture) {
            theClass += " bar-chart-clickable";
          }
          return theClass;
        })
        .attr("y", (d, i) => firstRowY + i * 65)
        .attr("text-anchor", "middle")
        .on("click", (d) => {
          if (d.clickable) {
            BarChartController.graphTableClick(yearDatum.year, "header");
          }
        })
        .text(textRenderer);
    });
  };

  var renderLegend = function (svg, shouldShowMilestones, values) {
    const data = values[0].barSegmentGroups;
    data.totalRows = values[0].additionalValues.length;

    const legendEntry = svg
      .selectAll("g.bar-chart-legend-entry")
      .data(values[0].barSegmentGroups)
      .enter()
      .append("g")
      .attr("transform", (d, i) => {
        const x = 145 * i - 67;
        return getTranslateString(
          x,
          ChartStyles.sizes.labelHeight + ChartStyles.getRowYPos(data.totalRows),
        );
      })
      .attr("class", "bar-chart-legend-entry");

    legendEntry
      .append("rect")
      .attr("class", (d) => {
        let theClass = "bar-chart-legend-color-square";
        theClass += ` ${CleanData.camelToKebabCase(d.fieldName)}-legend-icon`;
        return theClass;
      })
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("width", 22)
      .attr("height", 14)
      .attr("stroke", (d) =>
        ChartStyles.barSegmentIsDark(d.fieldName) ? "#ccc" : "rgba(0, 0, 0, 0)",
      );

    legendEntry
      .filter((d) => ChartStyles.getBarSegmentShape(d.format) === "line")
      .append("line")
      .attr("x1", 0)
      .attr("x2", 22)
      .attr("y1", 6)
      .attr("y2", 6)
      .attr("stroke", "#e0bc67")
      .attr("stroke-width", 3);

    legendEntry
      .append("text")
      .attr("class", "bar-chart-legend-label")
      .attr("x", 30)
      .attr("y", 11)
      .text((d) => d.name);
  };

  var addFutureYearsData = function (yearsData, numYearsToRender) {
    yearsData = removeYearsOutsideRange(yearsData, numYearsToRender);

    if (!yearsData?.length) {
      return [];
    }

    const yearsProvided = yearsData.map((yearDatum) => yearDatum.year);
    const firstYear = Math.min(...yearsData.map((yearData) => yearData.year));
    const firstFutureYear =
      Math.max(
        ...yearsData.filter((yearData) => !yearData.isFuture).map((yearData) => yearData.year),
      ) + 1;
    const futureYearsInRange = range(firstFutureYear, firstYear + numYearsToRender);
    const newFutureYears = futureYearsInRange
      .filter((year) => !yearsProvided.includes(year))
      .map((futureYear) => {
        return {
          year: futureYear,
          isFuture: true,
          submitted: false,
          additionalValues: [],
        };
      });
    return yearsData.concat(newFutureYears).sort((yearData1, yearData2) => {
      return yearData1.year - yearData2.year;
    });
  };

  var removeYearsOutsideRange = function (yearsData, numYearsToRender) {
    const firstYearWithData = Math.min(
      ...yearsData.filter((yearDatum) => !yearDatum.isFuture).map((yearDatum) => +yearDatum.year),
    );
    const yearsDataInRange = yearsData.filter((yearData) => {
      return (
        yearData.year >= firstYearWithData && yearData.year < firstYearWithData + numYearsToRender
      );
    });
    return yearsDataInRange;
  };

  var range = function (start, endExclusive) {
    if (start == endExclusive) {
      return [];
    }
    return [...Array(endExclusive - start).keys()].map((x) => x + start);
  };

  var filterBarSegmentGroups = function (d, filterFunction, includeFutureBarSegments) {
    let data = d.barSegmentGroups;
    if (d.isFuture) {
      data = [d];
      if (includeFutureBarSegments && d.barSegmentGroups !== undefined) {
        data = [d, ...d.barSegmentGroups];
      }
    }

    data = data.filter(function (group) {
      return filterFunction(group);
    });

    return data;
  };

  var renderBarSegmentLabels = function (yearColumns, yearsData, xBarWidth, w, xScale, yScale) {
    yearColumns
      .selectAll("text.bar-segment-label")
      .data(function (d) {
        return filterBarSegmentGroups(d, function (group) {
          return group.format === "bar";
        });
      })
      .enter()
      .append("text")
      .filter((d) => !d.isFuture && d.yDelta > 0)
      .attr("dx", (d) => xBarWidth / 2)
      .attr("width", xBarWidth)
      .attr("class", (d) => {
        const nameKebab = CleanData.camelToKebabCase(d.fieldName);
        let theClass = "bar-segment-label";
        theClass += " bar-segment-label-" + d.year;
        theClass += ` ${nameKebab}-label`;
        if (d.shortTopSegment) {
          theClass += " bar-segment-label-dark";
        }
        if (d.shortBottomSegment) {
          theClass += " bar-segment-label-light";
        }
        return theClass;
      })
      .attr("y", function (d) {
        let calculatedHeight;
        if (d.shortSegment) {
          calculatedHeight = yScale(d.y1) - 25;
        } else {
          calculatedHeight = yScale(d.y1);
        }
        return calculatedHeight + 20;
      })
      .attr("text-anchor", "middle")
      .attr("fill", (d) => (ChartStyles.barSegmentIsDark(d.fieldName) ? "#FFFFFF" : "#000000"))
      .transition()
      .delay((d, i) => 250 * (i + 1))
      .text((d, i) => {
        const formatted = d.roundToHundredths
          ? formatNumberString(d.yDelta, 2)
          : d.roundToTenths
            ? formatNumberString(d.yDelta, 1)
            : formatNumberString(d.yDelta);
        if (!d.overlappingLabelShortSegment) {
          if (+formatted != 0) {
            return formatted;
          }
        }
      });
  };

  var formatNumberString = function (num, fractionDigits) {
    if (num < 10 && fractionDigits === undefined) fractionDigits = 1;
    if (fractionDigits === undefined) fractionDigits = 0;

    if (num !== undefined && num !== "") {
      let formatted = (+num).toLocaleString(undefined, {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      });
      if (formatted === "0" && fractionDigits < 2) {
        formatted = (+num).toLocaleString(undefined, {
          maximumFractionDigits: fractionDigits + 1,
          minimumFractionDigits: fractionDigits + 1,
        });
      }
      return +formatted === 0 ? "0" : formatted;
    }
  };

  var renderMilestoneLines = function (yearColumns, xBarWidth, yScale, yFullHeight) {
    yearColumns
      .selectAll("line.milestone-line")
      .data((d) => {
        return filterBarSegmentGroups(
          d,
          function (group) {
            return (group.format === "milestone" || group.isFuture) && group.value != null;
          },
          true,
        );
      })
      .enter()
      .append("line")
      .attr("class", "milestone-line")
      .attr("y1", (d) => {
        return yScale(0);
      })
      .attr("y2", (d) => {
        return yScale(0);
      })
      .attr("x1", 0)
      .attr("x2", xBarWidth)
      .attr("stroke", "#e0b85a")
      .attr("stroke-width", 3)
      .transition()
      .duration(750)
      .attr("y1", (d) => {
        return yScale((yFullHeight * d.value) / 100);
      })
      .attr("y2", (d) => {
        return yScale((yFullHeight * d.value) / 100);
      });
  };

  var renderBars = function (
    yearColumns,
    xBarWidth,
    height,
    xRectPadding,
    yScale,
    yFullHeight,
    xScale,
  ) {
    yearColumns
      .selectAll("path")
      .data(function (d) {
        return filterBarSegmentGroups(d, function (group) {
          return group.format !== "milestone";
        });
      })
      .enter()
      .append("path")
      .filter((d) => !d.allZero)
      .attr("d", (d) => {
        // Transition origin
        const xCoord = ChartStyles.barSegmentIsDark(d.fieldName) ? 1 : 0;
        let yCoord = yScale(d.y1);

        let width = xBarWidth;
        if (ChartStyles.barSegmentIsDark(d.fieldName)) {
          width -= 2;
        }

        let height = yScale(d.y0) - yScale(d.y1);

        const radius = d.isFinalGroup || d.isFuture ? 7 : 0;

        if (radius > 0) {
          const yExtend = Math.max(0, radius - height);
          height += yExtend;
          yCoord -= yExtend;
        }

        const zeroHeight = 0;
        return getRoundedTopRectanglePath(xCoord, yCoord + height, width, zeroHeight, 0);
      })
      .attr({
        class: function (d, i) {
          let theClass = "";
          if (d.isFuture) {
            theClass += "rect-" + d.year + "-future";
          } else {
            theClass += "rect-" + d.year + "-actual ";
            theClass += camelToKebabCase(d.fieldName) + "-bar ";
          }
          return theClass;
        },
        fill: function (d) {
          if (d.isFuture) {
            return "url(#diagonal-stripe-pattern-gray)";
          }
        },
        id: function (d) {
          return "box" + d.year;
        },
      })
      .attr("stroke-width", function (d) {
        if (!ChartStyles.barSegmentIsDark(d.fieldName)) {
          return "0";
        }
        return "2";
      })
      .transition()
      .delay(function (d, i) {
        return i * 250;
      })
      .attr("d", (d) => {
        const xCoord = ChartStyles.barSegmentIsDark(d.fieldName) ? 1 : 0;
        let yCoord = yScale(d.y1);

        let width = xBarWidth;
        if (ChartStyles.barSegmentIsDark(d.fieldName)) {
          width -= 2;
        }

        let height = yScale(d.y0) - yScale(d.y1);

        const radius = d.isFinalGroup || d.isFuture ? 7 : 0;

        if (radius > 0) {
          const yExtend = Math.max(0, radius - height);
          height += yExtend;
          yCoord -= yExtend;
        }

        return getRoundedTopRectanglePath(xCoord, yCoord, width, height, radius);
      });
  };

  var camelToKebabCase = function (str) {
    return str.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
  };

  var finishYearColumnTitles = function (yearsData) {
    d3.select(".x-axis-top")
      .selectAll("text")
      .attr("cursor", function (d, i) {
        if (yearsData[i].isFuture) {
          return "auto";
        }
      })
      .attr("class", function (d, i) {
        return "x-axis-top-label x-axis-top-label-" + yearsData[i].year;
      })
      .attr("dy", ".1em")
      .attr("fill", (d, i) => (yearsData[i].isFuture ? "#808080" : "#262626"))
      .style("pointer-events", (d, i) => (yearsData[i].isFuture ? "none" : undefined))
      .on("click", function (d, i) {
        if (!yearsData[i].isFuture) {
          BarChartController.graphTableClick(yearsData[i].year, "header");
        }
      });
  };

  var getTranslateString = function (x, y) {
    return `translate(${x}, ${y})`;
  };

  var getRoundedTopRectanglePath = function (x, y, width, height, radius) {
    return roundedRectanglePath(x, y, width, height, radius, true, true, false, false);

    // https://stackoverflow.com/questions/12115691/
    //   svg-d3-js-rounded-corner-on-one-corner-of-a-rectangle
    // x: x-coordinate
    // y: y-coordinate
    // w: width
    // h: height
    // r: corner radius
    // tl: top_left rounded?
    // tr: top_right rounded?
    // bl: bottom_left rounded?
    // br: bottom_right rounded?
    function roundedRectanglePath(x, y, w, height, r, tl, tr, bl, br) {
      let retval;
      retval = "M" + (x + r) + "," + y;
      retval += "h" + (w - 2 * r);
      if (tr) {
        retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r;
      } else {
        retval += "h" + r;
        retval += "v" + r;
      }
      retval += "v" + (height - 2 * r);
      if (br) {
        retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
      } else {
        retval += "v" + r;
        retval += "h" + -r;
      }
      retval += "h" + (2 * r - w);
      if (bl) {
        retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
      } else {
        retval += "h" + -r;
        retval += "v" + -r;
      }
      retval += "v" + (2 * r - height);
      if (tl) {
        retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
      } else {
        retval += "v" + -r;
        retval += "h" + r;
      }
      retval += "z";
      return retval;
    }
  };

  return {
    render,
  };
};

module.exports = ProgressBarChart();
const BarChartController = require("./barChartController");
const CleanData = require("../../mapFunctions/cleanData");
const ChartStyles = require("./chartStyles");
const BarChartData = require("./barChartData");
const MapTitle = require("../../mapFunctions/mapTitle");
const Tree = require("../../../tree");
