"use strict";

const CleanData = function () {
  var cleanGeoJson = function (data) {
    var queryResult = data;
    var i = 0,
      length = queryResult.length,
      prop = null,
      geoJson = {
        type: "FeatureCollection",
        features: [],
      }; // Set up the initial GeoJSON object.
    for (i = 0; i < length; i++) {
      // For each result create a feature
      var feature = {
        type: "Feature",
        geometry: queryResult[i].geom ? JSON.parse(queryResult[i].geom) : null,
        properties: {},
      };
      // finally for each property/extra field, add it to the feature as properties as defined in the GeoJSON spec.
      for (prop in queryResult[i]) {
        if (prop !== "geom" && queryResult[i].hasOwnProperty(prop)) {
          feature.properties[prop] = queryResult[i][prop];
        }
      }
      geoJson.features.push(feature);
    }
    // return the FeatureCollection geoJson object.
    return geoJson;
  };

  function buildDictionary(array, key, prop) {
    var dict = {};
    if (Array.isArray(array)) {
      array.forEach(function (item) {
        dict[item[key]] = item[prop];
      });
    }
    return dict;
  }

  var removePoints = function (feature) {
    if (typeof feature.geometry.geometries !== "undefined") {
      var thisGeomArray = [];
      feature.geometry.geometries.forEach(function (geometry) {
        if (geometry.type == "Polygon") {
          thisGeomArray.push(geometry);
        }
      });
      feature.geometry.geometries = thisGeomArray;
      return feature;
    } else {
      return feature.geometry.type == "MultiPolygon" || feature.geometry.type == "Polygon";
    }
  };

  var prepareRawData = function (geoJson) {
    var cleanedGeoJson = [];

    for (var i = 0; i < geoJson.length; i++) {
      // For each result create a feature
      //             for (var j = 0; j < geoJson[i].length; j++) {
      var feature = {};
      $.each(geoJson[i], function (k, v) {
        feature[k] = v;
        //                feature += k + ":" + v + ",";
      });
      cleanedGeoJson.push(feature);
    }
    return cleanedGeoJson;
  };

  var cleanGeoData = function (data, type) {
    var queryResult = prepareRawData(data);
    // Initalise variables.
    var i = 0,
      length = queryResult.length,
      prop = null,
      geoJson = {
        type: "FeatureCollection",
        features: [],
      }; // Set up the initial GeoJSON object.

    for (i = 0; i < length; i++) {
      // For each result create a feature
      var feature = {
        type: "Feature",
        geometry: JSON.parse(queryResult[i].geom),
        properties: {},
      };
      // finally for each property/extra field, add it to the feature as properties as defined in the GeoJSON spec.
      for (prop in queryResult[i]) {
        if (prop !== "geoJson" && queryResult[i].hasOwnProperty(prop)) {
          feature.properties[prop] = queryResult[i][prop];
        }
      }
      // Push the feature into the features array in the geoJson object.
      geoJson.features.push(feature);
    }
    // Remove points that are remnants of the clip in postgis
    if (type !== "line") {
      var cleanFeatures = geoJson.features.filter(removePoints);
      geoJson.features = cleanFeatures;
    }
    return geoJson;
  };

  var applyParcelConditions = function (parcelFeatures, conditionData) {
    var conditionDict = buildDictionary(conditionData, "polygon_id", "condition");
    var dateDict = buildDictionary(conditionData, "polygon_id", "date");
    parcelFeatures.forEach(function (feat, i, array) {
      var id = feat.properties.id;
      feat.properties.condition = id in conditionDict ? conditionDict[id] : null;
      feat.properties.date = id in dateDict ? dateDict[id] : null;
    });
  };

  var getColumnArray = function (features, runoffOrParticulates) {
    var numberArray = [];
    var i;

    //put all normalised runoff values in an array
    if (runoffOrParticulates == "Impervious") {
      for (i = 0; i < features.length; i++) {
        numberArray.push(Number(features[i].properties.imp));
      }
    } else if (runoffOrParticulates == "Percent Runoff") {
      for (i = 0; i < features.length; i++) {
        numberArray.push(Number(features[i].properties.normalized * 100));
      }
    } else {
      for (i = 0; i < features.length; i++) {
        numberArray.push(Number(features[i].properties.normalized));
      }
    }

    //sort the values from min to max
    numberArray.sort(sortNumber);

    function sortNumber(a, b) {
      return a - b;
    }
    var columnValues;

    //find the headers for the different colors
    var numPerCol = Math.round(numberArray.length / 5);
    if (runoffOrParticulates == "Runoff") {
      columnValues = [
        numberArray[numPerCol].toFixed(2),
        numberArray[numPerCol * 2].toFixed(2),
        numberArray[numPerCol * 3].toFixed(2),
        numberArray[numPerCol * 4].toFixed(2),
      ];
    } else if (runoffOrParticulates == "Percent Runoff") {
      columnValues = [
        numberArray[numPerCol].toFixed(0),
        numberArray[numPerCol * 2].toFixed(0),
        numberArray[numPerCol * 3].toFixed(0),
        numberArray[numPerCol * 4].toFixed(0),
      ];
    } else if (runoffOrParticulates == "Particulates") {
      columnValues = [
        numberArray[numPerCol].toFixed(3),
        numberArray[numPerCol * 2].toFixed(3),
        numberArray[numPerCol * 3].toFixed(3),
        numberArray[numPerCol * 4].toFixed(3),
      ];
    } else if (runoffOrParticulates == "Impervious") {
      columnValues = [
        numberArray[numPerCol].toFixed(2),
        numberArray[numPerCol * 2].toFixed(2),
        numberArray[numPerCol * 3].toFixed(2),
        numberArray[numPerCol * 4].toFixed(2),
      ];
    }
    return columnValues;
  };

  var groupByProp = function (items, propName, arrayName) {
    //remove null headers
    items = items.filter((item) => item[propName] != null);
    // get unique values for propName
    var propValues = items
      .map(function (item) {
        return item[propName];
      })
      .filter(function (value, i, self) {
        return self.indexOf(value) === i;
      });
    // build groups
    var grouped = propValues.map(function (value) {
      return {
        [propName]: value,
        [arrayName]: [],
        ["drains_to_rw"]: "",
      };
    });
    items.forEach(function (item) {
      var idx = propValues.indexOf(item[propName]);
      grouped[idx][arrayName].push(item);

      if (propName !== "watershed") {
        grouped[idx]["drains_to_rw"] = item["drains_to_rw"];
      }
    });
    //sort rws alphabetically
    var alphaDescend = Tree.get(["filters", "descend"]);
    grouped.sort(dynamicSort(propName, alphaDescend));
    grouped = appendOther(grouped);
    return grouped;
  };

  function appendOther(objArray) {
    //remove out of bounds data and append to the end of table
    var otherIndex = objArray.findIndex(function (obj) {
      return obj.drains_to_c == "Other" || obj.drains_to_rw == "Other";
    });
    objArray.push(objArray.splice(otherIndex, 1)[0]);
    return objArray;
  }

  function dynamicSort(property, descending) {
    var sortOrder = 1;
    if (descending) {
      sortOrder = -1;
    }
    return function (a, b) {
      //treat underscores & hyphens as spaces
      var aa = a[property].replace(/_|\/|-/g, " ");
      var bb = b[property].replace(/_|\/|-/g, " ");
      return (
        aa.localeCompare(bb, undefined, {
          numeric: true,
          sensitivity: "base",
        }) * sortOrder
      );
    };
  }

  var getPolygonFeatureFromCoordinates = function (coordinates, options = {}) {
    const feature = { type: "Polygon", coordinates: coordinates };
    return L.geoJSON(feature, options).getLayers()[0];
  };

  return {
    cleanGeoJson,
    buildDictionary,
    cleanGeoData,
    applyParcelConditions,
    getColumnArray,
    groupByProp,
    getPolygonFeatureFromCoordinates,
  };
};

module.exports = CleanData();

const Tree = require("../tree");
