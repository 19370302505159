"use strict";

const FileFunctions = function () {
  var isFileDocument = function (file) {
    return MimeTypeConstants.documentMimeTypes.includes(file.type);
  };

  var isFileImage = function (file) {
    return MimeTypeConstants.imageMimeTypes.includes(file.type);
  };

  var isFilePresentation = function (file) {
    return MimeTypeConstants.presentationMimeTypes.includes(file.type);
  };

  var isFileSpreadsheet = function (file) {
    return MimeTypeConstants.spreadsheetMimeTypes.includes(file.type);
  };

  var isFileZip = function (file) {
    return MimeTypeConstants.zipMimeTypes.includes(file.type);
  };

  var isFileUnknownType = function (file) {
    return file.type === "";
  };

  return {
    isFileDocument,
    isFileImage,
    isFilePresentation,
    isFileSpreadsheet,
    isFileZip,
    isFileUnknownType,
  };
};

module.exports = FileFunctions();

const MimeTypeConstants = require("./mimeTypeConstants");
