"use strict";

const PlanPropertiesGeoServerLayer = function () {
  const layerName = "planProperties";
  const layerKey = "planPropertiesLayer";
  var planPropertiesGeoServerLayer;
  var baseQuery;
  let totalCount;

  var init = function (planId = null) {
    planPropertiesGeoServerLayer = new GeoServerMapLayer(layerName, layerKey, createMarkerFunction);
    baseQuery = planId ? `plan_id = ${planId}` : "1 = 1";
  };

  var loadWfsLayerData = async function (refreshData = false, loadingScreen = true) {
    return await planPropertiesGeoServerLayer.loadWfsLayerData({}, refreshData, loadingScreen);
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    if (mapId === "modal") return;
    if (!planPropertiesGeoServerLayer) init();
    planPropertiesGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
  };

  var getTotalPropertiesCount = function () {
    if (totalCount) return totalCount;
  };

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: PropertiesIcon.getIcon(feature.properties.esg_property_symbol),
    });
  };

  var getBaseQuery = function () {
    return baseQuery;
  };

  var loadLayer = function () {
    planPropertiesGeoServerLayer.loadGeoServerLayer(Map.getMap(), Map.getLayers(), "main", {
      refreshData: false,
    });
  };

  var loadGeoServerLayer = function () {
    planPropertiesGeoServerLayer.loadGeoServerLayer(Map.getMap(), Map.getLayers(), "main", {
      refreshData: true,
      loadingScreen: false,
    });
  };

  var invalidateLayerData = function (loadingScreen = true) {
    planPropertiesGeoServerLayer.invalidateLayerData(Map.getMap(), Map.getLayers(), "main", {
      loadingScreen,
    });
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    totalCount = data.length;
    return _organizeLayerData(data);
  };

  var _organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return _getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var _getOrganizedProperties = function (data, geometry) {
    return {
      totalCount,
      id: data.id,
      geometry: geometry,
      name: data.name,
      propertyIdentifier: data.property_identifier,
      planSlug: data.plan_slug,
      phase: data.phase,
      address: data.address,
      city: data.city,
      county: data.county,
      state: data.state,
      displayAddress: `${data.city}, ${NunjucksFilters.getStateAbbreviation(data.state)}`,
      dataRichness: data.data_richness,
      selected: data.selected,
      shelved: data.shelved,
      zip: data.zip,
      propertyType: data.property_type,
      propertyName: data.property_name,
      owner: data.owner,
      aquiredDate: data.acquired_date,
      developedYear: data.developed_year,
      acquisitionPortfolio: data.acquisition_portfolio,
      fund: data.fund,
      district: data.district,
      geom: data.geom,
      propertyAcres: data.property_acres,
      imperviousAcres: data.impervious_acres,
      perviousAcres: data.pervious_acres,
      perviousPercent: data.pervious_percent,
      buildingCount: data.building_count,
      buildingFootprint: data.building_footprint,
      consumptiveUse: data.consumptive_use,
      propertyContamination: data.property_contamination,
      existingSbmp: data.existing_sbmp,
      qb: data.qb,
      tssb: data.tssb,
      swMngd: data.sw_mngd,
      pollMngd: data.poll_mngd,
      imperviousTreatablePercent: data.impervious_treatable_percent,
      imperviousTreatableAcres: data.impervious_treatable_acres,
      climateScenario: data.climate_scenario,
      p85GcmHist: data.p85_gcm_hist,
      p85Gcm2050: data.p85_gcm_2050,
      designStormHistory: data.design_storm_hist,
      designStorm2050: data.design_storm_2050,
      targetCapacity: data.target_capacity,
      siteConfig: data.site_config,
      unitTreatmentCost: data.unit_treatment_cost,
      costCapital: data.cost_capital,
      unitTreatmentCostManual: data.unit_treatment_cost_manual,
      costCapitalManual: data.cost_capital_manual,
      costRecovery: data.cost_recovery,
      costTotal: data.cost_total,
      costRecoveryPercent: data.cost_recovery_percent,
      costPerGallon: data.cost_per_gallon,
      crProgramsCount: data.cr_programs_count,
      wqScore: data.wq_score,
      wsScore: data.ws_score,
      frScore: data.fr_score,
      uhScore: data.uh_score,
      ejCommunity: data.ej_community,
      region: data.region,
      epaRegion: data.epa_region,
      huc2Name: data.huc2_name,
      huc6Name: data.huc6_name,
      avgPpt: data.avg_ppt,
      soil: data.soil,
      femaZone: data.fema_zone,
      aquifer: data.aquifer,
      aquiferName: data.aquifer_name,
      slope: data.slope,
      aspect: data.aspect,
      qRatio: data.qratio,
      qRatioTreated: data.qratio_treated,
      proximityTo303D: data.proximity_to_303d,
      nvdi: data.ndvi,
      uhi: data.uhi,
      lithology: data.lithology,
      swUtility: data.sw_utility,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      deletedAt: data.deleted_at,
      groupId: data.group_id,
      huc2Code: data.huc2_code,
      huc6Code: data.huc6_code,
      huc8Code: data.huc8_code,
      huc8Name: data.huc8_name,
      imperviousWeight: data.impervious_weight,
      centroidGeom: data.centroid_geom,
      geomMultiPoly: data.geom_multipoly,
      planId: data.plan_id,
      planName: data.plan_name,
      planIdentifier: data.plan_identifier,
      rank: data.rank,
      esg_property_symbol: data.esg_property_symbol,
      tenants: JSON.parse(data.tenants || "[]"),
      ownershipStatus: data.ownership_status,
      retrofitOpportunities: JSON.parse(data.retrofit_opportunities || "[]"),
      bmpRelationship: JSON.parse(data.bmp_relationship || "[]"),
      siteComplexity: data.site_complexity,
      compensationStatus: JSON.parse(data.compensation_status || "[]"),
      jobStatus: data.job_status,
      useManualImperviousAcres: data.use_manual_impervious_acres,
    };
  };

  return {
    init,
    getBaseQuery,
    loadWfsLayerData,
    loadLayerListenersForMap,
    loadLayer,
    loadGeoServerLayer,
    getLayerData,
    invalidateLayerData,
    getTotalPropertiesCount,
  };
};

module.exports = PlanPropertiesGeoServerLayer();

const NunjucksFilters = require("../../general/nunjucksFilters");
const GeoServerLayerFunctions = require("../../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../../mapFunctions/geoServerMapLayer");
const Map = require("../../esg/map");
const PropertiesIcon = require("../icons/propertiesIcon");
