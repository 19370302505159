"use strict";

const AssetsLiftStationGeoServerDynamicMapLayer = function () {
  const layerName = "assetsLiftStationEsriDynamicMap";
  const layerKey = "assetsLiftStationEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsLiftStationEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsLiftStationEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsLiftStationEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = AssetsLiftStationGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
