"use strict";

const Progeny = function () {
  var rootGroupProgeny;
  var activeGroupProgeny;

  var setActiveGroupById = function (groupId) {
    if (groupId == Tree.get("rootGroup", "groupId")) {
      _setActiveGroup(Tree.get("rootGroup"));
    } else {
      _setActiveGroup(getProgenyByGroupId(groupId));
    }
  };

  var _setActiveGroup = function (group) {
    if (group.groupId == Tree.get("rootGroup", "groupId")) {
      activeGroupProgeny = [...rootGroupProgeny];
    } else {
      activeGroupProgeny = [...group.progeny];
    }
    Sentry.getCurrentScope().setExtra("activeGroup", group.name);
    Tree.set("activeGroup", group);
  };

  function canViewUnsubmittedDataForGroup(groupId) {
    if (Tree.get("activeGroup", "groupId") === groupId) return true;
    const progeny = getProgenyByGroupId(groupId);
    return progeny.canBeViewedUnsubmitted;
  }

  var getProgenyByGroupId = function (groupId) {
    if (groupId == Tree.get("rootGroup", "groupId")) {
      return Tree.get("rootGroup");
    }
    let groupsToSearch = [...rootGroupProgeny];
    while (groupsToSearch.length !== 0) {
      const currentGroup = groupsToSearch.shift();
      if (currentGroup.groupId == groupId) {
        return Object.assign({}, currentGroup);
      }
      groupsToSearch = groupsToSearch.concat(currentGroup.progeny);
    }
    throw new Error(`Couldn't find progeny with groupId ${groupId}.`);
  };

  var isDirectProgenyOf = function (potentialProgenyId, parentId) {
    if (parentId == Tree.get("rootGroup", "groupId")) {
      return rootGroupProgeny.some((progeny) => {
        return progeny.groupId == potentialProgenyId;
      });
    }
    return getProgenyByGroupId(parentId).progeny.some((progeny) => {
      return progeny.groupId == potentialProgenyId;
    });
  };

  var isPartner = function () {
    if (rootGroupProgeny === undefined) {
      throw Error("Progeny are loading.");
    }
    return rootGroupProgeny.length > 0 && rootGroupProgeny.every((progeny) => progeny.canEdit);
  };

  var isRegulator = function (shouldThrow = true) {
    if (shouldThrow && rootGroupProgeny === undefined) {
      throw Error("Progeny are loading.");
    }
    return (
      rootGroupProgeny?.length > 0 && rootGroupProgeny?.every((progeny) => !progeny.canBeEdited)
    );
  };

  var rootGroupIsRegulator = function () {
    const rootGroup = Tree.get("rootGroup");
    return rootGroup.isRegulator;
  };

  var activeGroupIsRegulator = function () {
    const activeGroup = Tree.get("activeGroup");
    return activeGroup.isRegulator;
  };

  var rootGroupHasProgeny = function () {
    if (rootGroupProgeny === undefined) {
      throw Error("Progeny are loading.");
    }
    return rootGroupProgeny.length > 0;
  };

  var activeHasProgeny = function () {
    if (activeGroupProgeny === undefined) {
      throw Error("Progeny are loading.");
    }
    return activeGroupProgeny && activeGroupProgeny.length > 0;
  };

  var activeGroupHasGrandprogeny = function () {
    return getActiveGroupProgeny().some((progeny) => {
      return progeny.progeny.length > 0;
    });
  };

  var saveProgeny = function (data) {
    rootGroupProgeny = [...data.progeny];
    Tree.merge(["rootGroup"], data);
    activeGroupProgeny = [...data.progeny];
  };

  var getActiveGroupProgeny = function () {
    if (activeGroupProgeny === undefined) {
      throw Error("Progeny are loading.");
    }
    return activeGroupProgeny;
  };

  var getActiveGroupProgenyFormOptions = function () {
    const progeny = getActiveGroupProgeny();
    return progeny.map(function (progeny) {
      return {
        name: progeny.name,
        value: progeny.groupId,
      };
    });
  };

  var getActiveGroupProgenyByGroupId = function (groupId) {
    const match = getActiveGroupProgeny().find((progeny) => progeny.groupId == groupId);
    if (match === undefined) {
      throw new Error(`Couldn't find progeny with groupId ${groupId}.`);
    }
    return match;
  };

  var isGroupInActiveGroupProgeny = function (groupId) {
    return getActiveGroupProgeny().some((progeny) => progeny.groupId == groupId);
  };

  var hasProgeny = function (parent, groupId) {
    return parent.progeny.some((child) => {
      return child.groupId == groupId || hasProgeny(child, groupId);
    });
  };

  var getParent = function (groupId) {
    const ancestors = getAncestors(groupId);
    if (ancestors.length === 0) {
      throw new Error("No parent for root group.");
    }
    return ancestors[ancestors.length - 1];
  };

  var getAncestors = function (groupId) {
    const rootGroup = Tree.get("rootGroup");
    const rootGroupId = rootGroup.groupId;
    if (groupId == rootGroupId) {
      return [];
    }
    return [rootGroup].concat(_getAncestorsFromDepth(rootGroupProgeny, groupId));
  };

  /**
   * Gets the ancestors of a group, from top to bottom, given a list
   * of progeny at the same depth containing one ancestor of groupId.
   */
  var _getAncestorsFromDepth = function (groups, groupId) {
    for (const child of groups) {
      if (child.groupId == groupId) return [];
      if (hasProgeny(child, groupId)) {
        return [child].concat(_getAncestorsFromDepth(child.progeny, groupId));
      }
    }
  };

  function isLoaded() {
    return rootGroupProgeny !== undefined;
  }

  var getRootGroupProgeny = function () {
    if (rootGroupProgeny === undefined) {
      throw Error("Progeny are loading.");
    }
    return rootGroupProgeny;
  };

  var getSubGroupFilterProps = function () {
    const progenyData = getActiveGroupProgeny();
    if (progenyData.length > 0) {
      const subGroupSelected = progenyData.map((item) => {
        return item.groupId;
      });
      const subGroupOptions = progenyData.map((item) => {
        return { name: item.name, value: item.groupId };
      });
      Tree.set("filters", {
        ...Tree.get("filters"),
        subGroups: subGroupSelected,
      });

      return {
        subGroupSelected,
        subGroupOptions,
      };
    }
  };

  return {
    setActiveGroupById,
    getActiveGroupProgenyByGroupId,
    isPartner,
    isRegulator,
    activeHasProgeny,
    getActiveGroupProgeny,
    getActiveGroupProgenyFormOptions,
    activeGroupHasGrandprogeny,
    saveProgeny,
    isGroupInActiveGroupProgeny,
    getProgenyByGroupId,
    isDirectProgenyOf,
    getAncestors,
    getParent,
    canViewUnsubmittedDataForGroup,
    isLoaded,
    rootGroupIsRegulator,
    activeGroupIsRegulator,
    rootGroupHasProgeny,
    getRootGroupProgeny,
    getSubGroupFilterProps,
  };
};

module.exports = Progeny();

const Tree = require("../tree");
const Sentry = require("@sentry/browser");
