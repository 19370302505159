"use strict";

const CatchmentView = function () {
  let mapId = null;
  const thisMap = null;

  const startListeners = function () {
    mapId = Tree.get("mapId");
  };

  const setUrbanCatchmentView = function (catchName, viewType, rw, gid) {
    checkNoCatchmentLayer();

    Table.resetSelectedTree();
    Tree.set(["spatialView", mapId], "catchmentView");
    Tree.set(["selected", "catchment"], catchName);
    Tree.set(["selected", "rwThatSelectedDrainsTo"], rw);

    Tree.set(["filters", "dataSort"], "uc");
    setSortDropdown(viewType, "uc");
    Filters.syncSpatialFiltersWithTable();

    setTimeout(function () {
      thisMap.find(".catchments-only h3.selected").removeClass("selected");
      thisMap.find("h3[data-catchid='" + catchName[0] + "']").addClass("selected");
      Layers.scrollTable(gid);
    }, 0);

    const $popupCloseButton = $(".leaflet-popup-close-button")[0];
    if ($popupCloseButton !== undefined) {
      $popupCloseButton.click();
    }

    MainMap.zoomToCatchmentView();
  };

  const setSortDropdown = function (viewType, dataSort) {
    thisMap
      .find(".dropdown-toggle.sort")
      .attr("data-id", viewType)
      .html(viewType + '<span class="glyphicon glyphicon-menu-down"></span>');
    thisMap.find(".dropdown-menu.sort li").removeClass("dropdown-selection");
    thisMap.find(".dropdown-menu.sort span").hide();
    thisMap
      .find(".dropdown-menu.sort li[data-id='" + dataSort + "']")
      .addClass("dropdown-selection");
    thisMap.find(".dropdown-menu.sort li[data-id='" + dataSort + "'] span").show();
  };

  const checkNoCatchmentLayer = function () {
    const $thisMap = PageFunctions.getCurrentPage();

    if (mapId == "inputs") {
      if (Tree.get("layers", "inputs", "fullDrainage", "isEnabled")) {
        $thisMap.find(".radio-priority").click();
      }
    } else if (mapId == "plan") {
      if (
        Tree.get("layers", "plan", "fullDrainage", "isEnabled") ||
        Tree.get("layers", "plan", "runoff", "isEnabled")
      ) {
        $thisMap.find(".radio-priority").click();
      }
    }
  };

  return {
    startListeners,
    setUrbanCatchmentView,
  };
};

module.exports = CatchmentView();

const Filters = require("./filters");
const Layers = require("./layers");
const MainMap = require("./mainMap");
const PageFunctions = require("../pageFunctions");
const Table = require("./table");
const Tree = require("../../tree");
