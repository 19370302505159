"use strict";

const FacilityHistory = function () {
  var renderFacilityHistory = function (data) {
    var html = nunjucks.render("popups/history/facilityHistory.njk", {
      history: data,
    });
    $(".popup-history-pane").html(html);
  };

  return {
    renderFacilityHistory,
  };
};

module.exports = FacilityHistory();
