"use strict";

const AssetsPipeGeoServerDynamicMapLayer = function () {
  const layerName = "assetsPipeEsriDynamicMap";
  const layerKey = "assetsPipeEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsPipeEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsPipeEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsPipeEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function () {};

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = AssetsPipeGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
