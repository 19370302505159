const pageConfig = {
  tabs: {
    criticalAssets: { targetSelector: "#map-panel" },
    thePlan: { targetSelector: "#map-panel" },
    todo: { targetSelector: "#map-panel" },
    data: { targetSelector: "#map-panel" },
    settings: { targetSelector: "#settings", noPanels: true },
    insight: { targetSelector: "#map-panel" },
    result: { targetSelector: "#map-panel" },
    fullPage: { targetSelector: "#full-page", noPanels: true },
  },
  navToggle: {
    map: {},
    list: {},
  },
};

module.exports = pageConfig;
