"use strict";

const GroupLogos = function () {
  const logosByGroup = {
    78085: [
      // Link Logistics
      {
        url: "https://linklogistics.com",
        img: "/shared/link-logo-black.svg",
      },
      {
        url: "https://www.nature.org/en-us/",
        img: "/shared/brightstorm-logo.png",
      },
    ],
    110457: [
      // 2N Testing ESG
      {
        img: "/shared/clean-water-logo.svg",
      },
    ],
    110458: [
      // Clean Water Corporation
      {
        img: "/shared/clean-water-logo.svg",
      },
    ],
    114125: [
      // Clean Water Corp.
      {
        img: "/shared/clean-water-logo.svg",
      },
    ],
    114123: [
      // Reveler Development formerly Bush Watson
      {
        url: "https://reveler.com/",
        img: "/shared/reveler-logo.png",
      },
    ],
    114124: [
      // Walmart
      {
        url: "https://corporate.walmart.com/",
        img: "/shared/logo-walmart.png",
      },
    ],
    114127: [
      // Los Angeles Unified School District
      {
        url: "https://www.lausd.net/",
        img: "/shared/lausd-logo.png",
      },
    ],
    114219: [
      // Kroenke Sports & Entertainment
      {
        url: "#",
        img: "/shared/KSE.png",
      },
    ],
    114220: [
      // Costco Wholesale Corporation
      {
        url: "https://www.costco.com/",
        img: "/shared/Costco_Logo.png",
      },
    ],
    114319: [
      // The Home Depot, Inc.
      {
        url: "https://www.homedepot.com/",
        img: "/shared/home-depot.png",
      },
    ],
    114320: [
      // Dollar General
      {
        url: "https://www.dollargeneral.com/",
        img: "/shared/dollar-general.png",
      },
    ],
  };

  const get = function () {
    var groupId = Tree.get("activeGroup", "groupId");
    return logosByGroup[groupId] || [];
  };

  return {
    get,
  };
};

module.exports = GroupLogos();

const Tree = require("../tree");
