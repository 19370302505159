"use strict";

const DryWeatherFunctions = function () {
  var openDryWeatherScreening = function (
    id,
    assetType,
    screeningId,
    readOnly = false,
    maintenanceType = null,
  ) {
    if (assetType === "outfall") {
      navigateToInspection("/ram/asset/outfall/", id, "/dryWeatherScreening", screeningId);
    } else if (assetType === "bmpFcs") {
      navigateToInspection("/ram/asset/catchBasin/", id, "/routineInspection", screeningId);
    } else {
      DryWeatherController.loadDryWeatherScreening({
        id,
        assetType,
        screeningId,
        readOnly,
        maintenanceType,
      });
    }
  };

  var navigateToInspection = function (pathStart, assetId, pathEnd, inspectionId) {
    let url = pathStart + assetId + pathEnd;

    if (inspectionId) {
      url += `/${inspectionId}`;
    }

    UrlRoutes.navigate(url);
  };

  var deleteDryWeatherScreening = function ($todoTr, screeningId, assetType) {
    if (assetType === "outfall") {
      OutfallInspectionHistory.confirmDeleteHistory($todoTr, { id: screeningId });
    } else if (assetType === "bmpFcs") {
      BmpFunctions.confirmDeleteHistory($todoTr, {
        id: screeningId,
        assetType: "routineMaintenance",
      });
    } else {
      DryWeatherController.deleteDryWeatherScreening({ $todoTr, screeningId, assetType });
    }
  };

  var eachDryWeatherLayer = function (callback) {
    const dryWeatherLayers = [
      DryWeatherOutfallGeoServerLayer,
      IddeManholeGeoServerLayer,
      DryWeatherCatchBasinGeoServerLayer,
      MonitoringLocationLayer,
    ];

    for (const layer of dryWeatherLayers) {
      callback(layer);
    }
  };

  var invalidateAllDryWeatherLayersData = function () {
    eachDryWeatherLayer((layer) => layer.invalidateLayerData());
  };

  var getDryWeatherLayerPopupHtml = function (data) {
    const displayIllicitActivity =
      IncidentInvestigationConstants.getIllicitActivityOptions().getObjectWith(
        "value",
        data.lastIncident?.illicitActivity,
      )?.name;

    return nunjucks.render("popups/dryWeatherPopup.njk", {
      ...data,
      displayIllicitActivity,
      popupColor: DryWeatherIcon.getIconColor(data.dryWeatherCondition, data.isDue),
      showDeleteOutfallButton: data.assetType === "outfall",
    });
  };

  var loadPopupListeners = function (data, $element) {
    $element.off("click", ".open-dry-weather-screening");
    $element.on("click", ".open-dry-weather-screening", () =>
      openDryWeatherScreening(data.id, data.assetType),
    );

    $element.off("click", ".open-dry-weather-screening-follow-up");
    $element.on("click", ".open-dry-weather-screening-follow-up", () =>
      openDryWeatherScreening(data.id, data.assetType, null, false, "follow-up"),
    );
  };

  return {
    openDryWeatherScreening,
    deleteDryWeatherScreening,
    invalidateAllDryWeatherLayersData,
    getDryWeatherLayerPopupHtml,
    loadPopupListeners,
  };
};

module.exports = DryWeatherFunctions();

const DryWeatherController = require("./dryWeatherScreening/dryWeatherController");
const DryWeatherIcon = require("./dryWeatherIcon");
const DryWeatherOutfallGeoServerLayer = require("./dryWeatherOutfallGeoServerLayer");
const DryWeatherCatchBasinGeoServerLayer = require("./dryWeatherCatchBasinGeoServerLayer");
const IddeManholeGeoServerLayer = require("../muni/iddeManholeGeoServerLayer");
const IncidentInvestigationConstants = require("./incidentInvestigation/incidentInvestigationConstants");
const MonitoringLocationLayer = require("./monitoringLocationLayer");
const UrlRoutes = require("../routes/urlRoutes");
const OutfallInspectionHistory = require("../outfalls/outfallInspectionHistory");
const BmpFunctions = require("../modals/bmpFunctions");
