"use strict";

const RoutineMaintenanceFormTable = function () {
  const getCorrectionOptions = function () {
    const correctionOptions = [
      {
        value: "cleaned",
      },
      {
        value: "corrected",
        includedByDefault: true,
      },
      {
        value: "further-review",
        includedByDefault: true,
      },
    ];

    return ToolSettings.addNameToOptions("constants", "maintenanceCorrections", correctionOptions);
  };

  const getLevelOptions = function () {
    const levelOptions = [
      {
        value: "clean",
      },
      {
        value: "minimal-issue",
        includedByDefault: true,
      },
      {
        value: "minor-issue",
        includedByDefault: true,
      },
      {
        value: "major-issue",
        includedByDefault: true,
      },
      {
        value: "replacement-required",
        includedByDefault: true,
      },
    ];

    return ToolSettings.addNameToOptions("constants", "maintenanceLevels", levelOptions);
  };

  const maintenanceIssuesUpdateFields = [
    "key",
    "issue",
    "data",
    "dataOption",
    "correction",
    "level",
    "dataNumber",
  ];

  var loadListeners = function ($page) {
    $page.on("change", `[name$="[issue]"]`, _toggleIssuePresentFields);
    $page.on("change", `[name$="[correction]"]`, _toggleLevelField);
    $page.on("2N:FormInput", `[name$="[issueCorrected]"]`, _onIssueCorrected);

    RoutineMaintenanceFormGeneral.toggleAssetAccessible($page);
  };

  var unloadListeners = function ($page) {
    $page.off("change", `[name$="[issue]"]`, _toggleIssuePresentFields);
    $page.off("change", `[name$="[correction]"]`, _toggleLevelField);
    $page.off("2N:FormInput", `[name$="[issueCorrected]"]`, _onIssueCorrected);
  };

  var _toggleIssuePresentFields = function () {
    const $input = $(this);
    const $row = $input.closest("tr");
    const toggle = $input.val() === "true";

    $row.find(".task-data-col, .task-correction-col").toggleClass("hidden", !toggle);
    RoutineMaintenanceFormGeneral.toggleAssetAccessible($row);

    if (toggle) {
      const $correctionSelect = $row.find(".task-correction-col select");
      handleLevelFieldDisplay($correctionSelect);
    } else {
      $row.find(".task-level-col").addClass("hidden");
    }
  };

  var _toggleLevelField = function () {
    var $select = $(this);
    handleLevelFieldDisplay($select);
  };

  var _onIssueCorrected = function (e, value, pathParts, state, formKey) {
    Form.manuallySetFormDataField(formKey, [...pathParts.slice(0, -1), "issue"], !value);

    const $input = $(this);
    if ($input.data("issueKey") === "asset-accessible") {
      RoutineMaintenanceFormGeneral.toggleAssetAccessible($input, value);
    }
  };

  var handleLevelFieldDisplay = function ($correctionSelect) {
    var toggle = $correctionSelect.val() === "further-review";

    $correctionSelect.closest("tr").find(".task-level-col").toggleClass("hidden", !toggle);
  };

  var getMaintenanceTasksWithOptions = function (allTasks, maintenanceTasks) {
    const result = $.extend(true, [], maintenanceTasks);

    for (const task of result) {
      const taskWithOptions = getTaskByKey(allTasks, task.key);

      setTaskOptions(task, taskWithOptions);
    }

    return sortTasksByName(result);
  };

  var getDefaultMaintenanceTasksWithOptions = function (
    allTasks,
    { maintenanceTasks = null, filterByConfiguredKey = null } = {},
  ) {
    const result = getDefaultMaintenanceTasks(allTasks, maintenanceTasks, filterByConfiguredKey);

    for (const task of result) {
      const taskWithOptions = getTaskByKey(allTasks, task.key);

      if (task.issue === undefined) {
        task.issue = false;
      }
      delete task.id;

      setTaskOptions(task, taskWithOptions);
    }

    return sortTasksByName(result);
  };

  var getDefaultMaintenanceTasks = function (allTasks, maintenanceTasks, filterByConfiguredKey) {
    let result = maintenanceTasks ?? allTasks;

    if (maintenanceTasks === null || filterByConfiguredKey !== null) {
      result = filterByConfigured(result, filterByConfiguredKey);
    }

    return $.extend(true, [], result);
  };

  var filterByConfigured = function (allTasks, configuredKey) {
    const configuredTaskKeys =
      RoutineMaintenanceConfig.getConfiguredMaintenanceTasks(configuredKey);
    return allTasks.filter((task) => configuredTaskKeys.includes(task.key));
  };

  var setTaskOptions = function (task, taskWithOptions) {
    task.numberPlaceholder = taskWithOptions.numberPlaceholder;
    task.autoNumericTypeClass = taskWithOptions.autoNumericTypeClass;
    task.dataDescription = taskWithOptions.dataDescription;
    task.selectOptions = taskWithOptions.selectOptions;
    task.selectNoOptionMessage = taskWithOptions.selectNoOptionMessage;
    task.dataOption = task.dataOption ?? taskWithOptions.numberUnit;
    task.name = taskWithOptions.name;
    task.correctionOptions = getDefaultOptions(
      getCorrectionOptions(),
      taskWithOptions.correctionOptions,
    );
    task.levelOptions = getDefaultOptions(getLevelOptions(), taskWithOptions.levelOptions);
    task.levelName = getLevelOptions().getObjectWith("value", task.level)?.name;
  };

  var getTaskByKey = function (allTasks, key) {
    var issue = allTasks.find((task) => taskHasKey(task, key));
    if (!issue) {
      throw new Error("No issue found with key: " + key);
    }
    return issue;
  };

  var sortTasksByName = function (unsortedArray) {
    return unsortedArray.sort((a, b) => SortUtilities.alphaSorter(a.name, b.name));
  };

  var taskHasKey = function (task, key) {
    return task.key === key || (task.aliases || []).includes(key);
  };

  var getDefaultOptions = function (allOptions, optionsOverride = null) {
    if (optionsOverride) {
      return allOptions.filter((option) => optionsOverride.includes(option.value));
    } else {
      return allOptions.filter((option) => option.includedByDefault);
    }
  };

  var combineRoutineMaintenanceIssues = function (
    data,
    existingCategoryArray,
    newCategory = "maintenanceIssues",
  ) {
    data[newCategory] = [];

    for (const category of existingCategoryArray) {
      if (data[category]) {
        data[newCategory] = data[newCategory].concat(data[category]);
        delete data[category];
      }
    }

    return data;
  };

  var cleanRoutineMaintenanceIssues = function (issueArray, editExisting) {
    let updateFields = maintenanceIssuesUpdateFields;

    if (editExisting) {
      updateFields = updateFields.concat(["id"]);
    }

    // Remove deleted array items
    issueArray = issueArray.filter((issue) => issue !== undefined);

    for (const issue of issueArray) {
      cleanIssue(issue, updateFields);
    }

    return issueArray;
  };

  var cleanIssue = function (issue, updateFields) {
    migrateIssueCorrected(issue);

    for (const key in issue) {
      if (!updateFields.includes(key)) {
        delete issue[key];
      }
    }
  };

  // @TODO: Safe to remove after about a week
  var migrateIssueCorrected = function (issue) {
    if (issue.issueCorrected !== undefined) {
      issue.issue = !issue.issueCorrected;
    }
  };

  var processRepairAdditionalInfo = function (additionalInfo) {
    if (!Array.isArray(additionalInfo)) {
      return;
    }

    for (const info of additionalInfo) {
      if (!("issue" in info)) {
        info.issue = true;
      }
    }
  };

  var filterIssuesBy = function (issuesToFilter, issuesToFilterOut) {
    const issueKeysToFilter = getSetOfIssueKeys(issuesToFilterOut);
    return issuesToFilter.filter((issue) => !issueInSetOfKeys(issue, issueKeysToFilter));
  };

  var getSetOfIssueKeys = function (issuesArray) {
    const set = new Set();

    for (const issue of issuesArray) {
      for (const key of getAllIssueKeys(issue)) {
        set.add(key);
      }
    }

    return set;
  };

  var getAllIssueKeys = function (issue) {
    const issueKeys = [issue.key];

    if (issue.aliases) {
      for (const alias of issue.aliases) {
        issueKeys.push(alias);
      }
    }

    return issueKeys;
  };

  var issueInSetOfKeys = function (issue, issueKeysSet) {
    for (const key of getAllIssueKeys(issue)) {
      if (issueKeysSet.has(key)) {
        return true;
      }
    }

    return false;
  };

  var getRepairTabName = function () {
    return "Open Issues";
  };

  var getConditionTabName = function () {
    return "Condition & Maintenance Tasks";
  };

  return {
    getLevelOptions,
    loadListeners,
    unloadListeners,
    getMaintenanceTasksWithOptions,
    getDefaultMaintenanceTasksWithOptions,
    sortTasksByName,
    getTaskByKey,
    combineRoutineMaintenanceIssues,
    cleanRoutineMaintenanceIssues,
    processRepairAdditionalInfo,
    filterIssuesBy,
    getRepairTabName,
    getConditionTabName,
    _toggleIssuePresentFields,
    _toggleLevelField,
    _onIssueCorrected,
  };
};

module.exports = RoutineMaintenanceFormTable();

const Form = require("./form");
const SortUtilities = require("../general/sortUtilities");
const RoutineMaintenanceConfig = require("../config/routineMaintenanceConfig");
const ToolSettings = require("../settings/toolSettings");
const RoutineMaintenanceFormGeneral = require("./routineMaintenanceFormGeneral");
