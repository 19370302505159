"use strict";

const LidProjectStyles = function () {
  var getColor = function (lidProjectData) {
    let phase = lidProjectData.phase;
    const condition = lidProjectData.lidCondition;

    if (phase === undefined) {
      phase = "planned";
    }
    if (SharedDataFunctions.isPhaseOrLater(phase, "completed")) {
      if (condition === "desired") {
        return "green";
      } else if (condition === "poor") {
        return "red";
      }
      return "grey";
    } else {
      return "white";
    }
  };

  var getColorHex = function (lidProjectData) {
    const colorHexMap = MapStyles.getHexColors();
    const color = getNewLidColor(lidProjectData);
    if (color in colorHexMap) {
      return colorHexMap[color];
    }
    throw `Hex not known for color ${color}.`;
  };

  var getTableIconColorClass = function (lidProjectData, isTodo = false) {
    const color = getColor(lidProjectData);
    return `lid-table-${color}`;
  };

  var getMapIconColorClass = function (lidProjectData, isTodo = false) {
    const color = getNewLidColor(lidProjectData, isTodo);
    return `lid-map-${color}`;
  };

  var getLidPerformanceFontColor = function (lidProjectData, isTodo = false) {
    const color = getNewLidColor(lidProjectData, isTodo);
    const fontColor = color === "white" ? "black-text" : "white-text";
    return fontColor;
  };

  var getNewLidProjectIconClass = function (projectData, isTodo = false) {
    const classParts = ["construction"];
    if (Tree.get(["filters", "conditionPhaseToggle"]) === "phase" && !isTodo) {
      classParts.push(`phase-${projectData.phase}`);
    } else {
      if (projectData.openEnforcements) {
        classParts.push("enforcement");
      }
      if (projectData.pcrCondition === "desired") {
        classParts.push("satisfactory");
      } else if (projectData.pcrCondition === "poor") {
        classParts.push("unsatisfactory");
      } else {
        classParts.push("no-inspection");
      }
    }

    return classParts.join("-");
  };

  var getNewLidColor = function (projectData, isTodo = false) {
    if (Tree.get(["filters", "conditionPhaseToggle"]) === "phase" && !isTodo) {
      return projectData.phase;
    } else {
      if (projectData.pcrCondition === "desired") {
        return "green";
      } else if (projectData.pcrCondition === "poor") {
        return "red";
      }

      return "grey";
    }
  };

  return {
    getColorHex,
    getTableIconColorClass,
    getMapIconColorClass,
    getLidPerformanceFontColor,
    getColor,
    getNewLidProjectIconClass,
    getNewLidColor,
  };
};

module.exports = LidProjectStyles();

const MapStyles = require("../mapFunctions/mapStyles");
const SharedDataFunctions = require("../general/sharedDataFunctions");
const Tree = require("../tree");
