"use strict";

const Dropzone = function ($wrapper, readonly = false, maxNumFiles = null) {
  var state = {
    selected: [], // keeps tracks of files (JS objects) added to the form
    existing: [], // a list of FILENAMES already existing on the server
    trash: [], // a list of filenames to be removed from the server
    readonly: readonly,
    maxNumFiles: maxNumFiles,
  };
  var $zone = $wrapper.find(".drop-zone");
  var $singleFileUploadContainer = $wrapper.find(".single-file-upload-container");
  var $hiddenFileInput = $wrapper.find("input[name=files]");
  $hiddenFileInput.off();
  $zone.off();

  var downloadHandler = function () {
    console.warn("missing downloadHandler");
  };

  var fileSizeWarning = function () {
    console.warn("missing fileSizeWarning");
  };

  var fileTypeWarning = function (filename) {
    MessageModal.showSimpleWarningModal(
      `${filename} doesn't appear to be a supported file type. Please try uploading a document, image, spreadsheet, presentation, or zip file.`,
    );
  };

  var hiddenInputChangeCallback = function () {};

  var fileZoneDropCallback = function () {};

  var removeExistingCallback = function () {};

  var removeNewCallback = function () {};

  $hiddenFileInput.on("change", function () {
    const files = Array.from(this.files);
    if (files) {
      const filteredFiles = removeInvalidFiles(files);
      _addFiles(filteredFiles);
      $zone.html(renderHtml());
      resetHiddenFileUploadInput($(this));
    }
    hiddenInputChangeCallback();
  });

  var resetHiddenFileUploadInput = function ($input) {
    $input.val("");
  };

  // Hack! - trigger hidden file input
  $wrapper.find(".dropzone-browse").on("click", function () {
    $wrapper.find("input[name=files]").trigger("click");
  });

  $zone.on("click", ".file-list a", function () {
    var fileData = $(this).data();
    switch (fileData.action) {
      case "download-new":
        return downloadNew(fileData.name, fileData.idx);
      case "remove-new":
        return removeNew(fileData.name, fileData.idx);
      case "download-existing":
        return downloadExisting(fileData.name, fileData.idx);
      case "remove-existing":
        return _removeExisting(fileData.name, fileData.idx);
      default:
        console.log("unknown action", fileData);
    }
  });

  $singleFileUploadContainer.on("click", "[data-action]", function (event) {
    var fileData = $(this).data();
    switch (fileData.action) {
      case "download-new":
        return downloadNew(fileData.name, fileData.idx);
      case "remove-new":
        event.stopPropagation();
        return removeNew(fileData.name, fileData.idx);
      default:
        console.log("unknown action", fileData);
    }
  });

  var setupDragAndDrop = function ($zone, readonly) {
    if (readonly) return;
    $zone
      .on("dragenter", function (e) {
        e.preventDefault();
        if ($zone.is(e.target)) {
          $zone.children().hide();
          $zone.addClass("focused");
        }
      })
      .on("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
      })
      .on("dragend dragleave", function (e) {
        e.preventDefault();
        if ($zone.is(e.target)) {
          $zone.children().show();
          $zone.removeClass("focused");
        }
      })
      .on("drop", function (e) {
        e.preventDefault();
        $zone.removeClass("focused");
        var domEvent = e.originalEvent;
        var newFiles = [];
        if (domEvent.dataTransfer.files) {
          newFiles = [...domEvent.dataTransfer.files];
          const filteredFiles = removeInvalidFiles(newFiles);
          if (filteredFiles.length > 0) {
            _addFiles(filteredFiles);
            $zone.html(renderHtml());
            fileZoneDropCallback();
          }
        }
      });
  };

  setupDragAndDrop($zone, readonly);

  var removeInvalidFiles = function (files) {
    return removeTooLargeAndShowWarning(removeFilesWithInvalidTypeAndShowWarning(files));
  };

  var isFileTypeValid = function (file) {
    return (
      FileFunctions.isFileDocument(file) ||
      FileFunctions.isFileImage(file) ||
      FileFunctions.isFileZip(file) ||
      FileFunctions.isFileSpreadsheet(file) ||
      FileFunctions.isFilePresentation(file) ||
      FileFunctions.isFileUnknownType(file)
    );
  };

  function removeFilesWithInvalidTypeAndShowWarning(files) {
    return files.filter((file) => {
      if (!isFileTypeValid(file)) {
        console.log(`Tried to upload file of unsupported MIME type: ${file.type}`);
        fileTypeWarning(file.name);
        return false;
      }
      return true;
    });
  }

  function removeTooLargeAndShowWarning(files) {
    const maxUploadSize = 8 * 1024 * 1024; // 8MB
    return files.filter((file) => {
      if (file.size > maxUploadSize) fileSizeWarning(file.name);
      return file.size <= maxUploadSize;
    });
  }

  function downloadNew(name, idx) {
    FileSaver.saveAs(state.selected[idx], name);
  }

  function removeNew(name, idx) {
    state.selected.splice(idx, 1);
    $zone.html(renderHtml());

    removeNewCallback(name);
  }

  // note: maybe this should be a standard HTML link
  function downloadExisting(name) {
    downloadHandler(name);
  }

  function _removeExisting(name, idx) {
    state.existing.splice(idx, 1);
    state.trash.push(name);
    $zone.html(renderHtml());

    removeExistingCallback(name);
  }

  function _addFiles(newFiles) {
    var i, idx;
    var names = state.selected.map(prop("name"));

    for (i = 0; i < newFiles.length; i++) {
      // if a file by the same name exist in either list, remove it
      idx = names.indexOf(newFiles[i].name);
      if (idx > -1) {
        state.selected.splice(idx, 1);
      }
      idx = state.existing.indexOf(newFiles[i].name);
      if (idx > -1) {
        state.existing.splice(idx, 1);
      }
    }

    // add file
    const fileCount = state.selected.length + state.existing.length + newFiles.length;
    if (maxNumFiles === null || fileCount <= maxNumFiles) {
      state.selected = state.selected.concat(newFiles);
    } else {
      MessageModal.showSimpleWarningModal(
        `You have selected to upload ${fileCount} files. The maximum files allowed on this upload is ${maxNumFiles}.`,
      );
    }
  }

  function renderHtml() {
    var html = nunjucks.render("dropzone/filelist.njk", {
      state: state,
    });
    handleSingleFileUploadDisplay();
    return html;
  }

  var handleSingleFileUploadDisplay = function () {
    if ($singleFileUploadContainer.length) {
      const $uploadButton = $singleFileUploadContainer.find(".upload-button");
      const $fileList = $singleFileUploadContainer.find(".file-list");

      if (state.selected?.length) {
        const filename = state.selected[0].name;
        $uploadButton.hide();
        $fileList.show();
        $singleFileUploadContainer.find(".file-name").text(filename);
        $singleFileUploadContainer
          .find("[data-name]")
          .data("name", filename)
          .prop("data-name", filename);
      } else {
        $uploadButton.show();
        $fileList.hide();
      }
    }
  };

  function prop(key) {
    return function (obj) {
      return obj[key];
    };
  }

  function getFiles() {
    return $.extend(true, [], state.selected);
  }

  function getRemoved() {
    return $.extend(true, [], state.trash);
  }

  function getAllFilenames() {
    return state.existing.concat(
      state.selected.map(function (f) {
        return f.name;
      }),
    );
  }

  function reset(selected, existing, trash) {
    state.selected = selected || [];
    state.existing = existing || [];
    state.trash = trash || [];
    $zone.html(renderHtml());
  }

  // function that receives a file name and returns the URL to that file
  function setDownloadHandler(func) {
    downloadHandler = func;
  }

  function setFileSizeWarning(func) {
    fileSizeWarning = func;
  }

  function setFileTypeWarning(func) {
    fileTypeWarning = func;
  }

  function setHiddenInputChangeCallback(func) {
    hiddenInputChangeCallback = func;
  }

  function setFileZoneDropCallback(func) {
    fileZoneDropCallback = func;
  }

  function setRemoveExistingCallback(func) {
    removeExistingCallback = func;
  }

  function setRemoveNewCallback(func) {
    removeNewCallback = func;
  }

  return {
    reset,
    getAllFilenames,
    getFiles,
    getRemoved,
    removeNew,
    renderHtml,
    setDownloadHandler,
    setFileSizeWarning,
    setFileTypeWarning,
    setHiddenInputChangeCallback,
    setFileZoneDropCallback,
    setRemoveExistingCallback,
    setRemoveNewCallback,
    _addFiles,
    _removeExisting,
  };
};

module.exports = Dropzone;

const MessageModal = require("./modals/messageModal");
const FileFunctions = require("./files/fileFunctions");
const FileSaver = require("file-saver");
