"use strict";

/*
  Please use CachedObjectStore instead to access a database
*/

function Database(newName, migrationsArray, options = {}) {
  const name = `2nform-${newName}`;
  const migrationStoreName = "migrations";
  const updateBlockedMessage =
    "2NFORM failed to update. Please close any open 2NFORM tabs and refresh this page.";
  const updateBlockingMessage =
    "2NFORM is updating in another tab. Please close this tab to continue.";
  let db;
  let pendingOpen;

  async function _getOpenDb() {
    if (!db && !pendingOpen) {
      pendingOpen = Retryer.tryPromise(async function () {
        close();
        return open();
      });
    }

    if (pendingOpen) {
      await pendingOpen;
      pendingOpen = null;
    }

    return db;
  }

  async function open() {
    db = await idb.openDB(name, migrationsArray.length, {
      upgrade(db, oldVersion, newVersion, transaction) {
        if (oldVersion === 0) {
          createMigrationsTable(db);
        }
        return runMigrations(db, transaction);
      },
      blocked() {
        MessageModal.showSimpleWarningModal(updateBlockedMessage);
      },
      blocking() {
        MessageModal.showSimpleWarningModal(updateBlockingMessage);
      },
      terminated() {
        Sentry.addBreadcrumb({
          level: "error",
          category: `offline.terminated`,
          message: "Database connection unexpectedly closed.",
        });
      },
      ...options,
    });
  }

  function createMigrationsTable(db) {
    db.createObjectStore(migrationStoreName, { keyPath: "key" });
  }

  async function runMigrations(db, transaction) {
    const migrationStore = transaction.objectStore(migrationStoreName);
    const ranMigrationsSet = await getMigrationKeySet(migrationStore);
    const migrationStartTime = new Date(Date.now());

    for (const migration of migrationsArray) {
      assertMigration(migration);

      if (!ranMigrationsSet.has(migration.key)) {
        await migration.migration(db, transaction);
        migrationStore.add({ key: migration.key, timestamp: migrationStartTime });
        ranMigrationsSet.add(migration.key);
      }
    }
  }

  async function getMigrationKeySet(migrationStore) {
    const ranMigrationsSet = new Set();
    const ranMigrationsArray = await migrationStore.getAll();

    for (const ranMigration of ranMigrationsArray) {
      ranMigrationsSet.add(ranMigration.key);
    }

    return ranMigrationsSet;
  }

  function assertMigration(migration) {
    if (typeof migration !== "object") {
      throw new Error(`All migrations must be objects`);
    }
    if (typeof migration.key !== "string") {
      throw new Error(`All migrations must have string keys`);
    }
    if (typeof migration.migration !== "function") {
      throw new Error(`All migrations must have a function migration`);
    }
  }

  async function deleteDatabase() {
    close();

    return await idb.deleteDB(name, {
      blocked: function () {
        MessageModal.showSimpleWarningModal(updateBlockedMessage);
      },
    });
  }

  async function getTransaction(...args) {
    let transaction;

    try {
      transaction = await _openAndGetTransaction(args);
    } catch (e) {
      try {
        // Reconnecting to the database to fix browser bug: https://github.com/jakearchibald/idb/issues/229
        close();
        transaction = await _openAndGetTransaction(args);
      } catch (e2) {
        // Unable to reconnect to IndexedDB, assuming Safari is broken: https://bugs.webkit.org/show_bug.cgi?id=197050
        SingleSignOn.warnAndRefresh(
          "Fatal error encountered, the page will now refresh. If the issue persists, please close your web browser and start it again.",
        );

        throw e;
      }
    }

    return transaction;
  }

  async function _openAndGetTransaction(args) {
    const db = await _getOpenDb();
    return db.transaction(...args);
  }

  function close() {
    try {
      db.close();
    } catch (e) {
      // Throws if already closed
    }

    db = null;
  }

  async function getObjectStoreTransaction(storeName, write = false) {
    const mode = write ? "readwrite" : "readonly";
    const transaction = await getTransaction(storeName, mode);
    const store = transaction.objectStore(storeName);
    store.done = transaction.done;
    return store;
  }

  async function backgroundBulkWriteOperation(storeName, allData, operation, signal = null) {
    const batchSize = 100;
    const runInBackground = allData.length > 1;
    const promises = [];
    let i = 0;

    while (signal?.aborted !== true && i < allData.length) {
      if (runInBackground) {
        await MainThreadScheduling.yieldOrContinue("idle", signal);
      }

      const transaction = await getObjectStoreTransaction(storeName, true);
      const data = allData.slice(i, i + batchSize);
      const promise = safeBulkOperation(transaction, data, operation);
      promises.push(promise);

      i += batchSize;
    }

    if (signal?.aborted === true) {
      throw new DOMException("The operation was aborted.", "AbortError");
    }

    await Promise.all(promises);
  }

  /*
    Performant bulk update that throws useful errors if an insert fails.

    Only awaiting `store.done` throws an `AbortError` with a misleading
    call stack, but awaiting each individual call tanks performance.
    This is the only way I could find to have no performance penalty and
    throw exceptions with useful call stacks.
  */
  async function safeBulkOperation(store, allData, operation) {
    let abortedError = null;

    for (const data of allData) {
      operation(store, data).catch(function (e) {
        if (!abortedError) {
          abortedError = e;
        }
        // All transactions are aborted if one fails, so suppress subsequent error messages
      });
    }

    await store.done;

    if (abortedError) {
      throw abortedError;
    }
  }

  function getName() {
    return name;
  }

  return {
    getTransaction,
    getObjectStoreTransaction,
    close,
    deleteDatabase,
    safeBulkOperation,
    backgroundBulkWriteOperation,
    getName,
    _getOpenDb,
  };
}

module.exports = Database;

const idb = require("idb");
const MessageModal = require("../modals/messageModal");
const Retryer = require("../general/retryer");
const Sentry = require("@sentry/browser");
const SingleSignOn = require("../login/sso");
const MainThreadScheduling = require("main-thread-scheduling");
