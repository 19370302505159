"use strict";

const CatchmentPopup = function () {
  var getPopupHtml = function (properties) {
    let options;
    options = getCatchmentPopupOptions(properties);
    return PopupContent.getLayerPopup(options, "catchments");
  };

  var getCatchmentPopupOptions = function (properties) {
    return Object.assign({}, properties, {
      showPluArea: !!properties?.plu_acreage,
      avg_condition: getRoadConditionAvg(properties),
    });
  };

  var getRoadConditionAvg = function (properties) {
    var conditions = [];
    for (var prop in properties) {
      if (prop.includes("_condition")) {
        properties[prop] =
          typeof properties[prop] === "string" ? parseInt(properties[prop], 10) : properties[prop];
        conditions.push(properties[prop]);
      }
    }
    var sumConditions = conditions.reduce((a, b) => a + b, 0);
    var avgCondition = sumConditions / conditions.length;
    return avgCondition;
  };

  var onPopupOpen = function (feature) {
    var $popupCatchment = $(".popup-catchment");
    handleCatchmentViewControlDisplay($popupCatchment);
    handleCatchmentRoadConditionDisplay($popupCatchment, feature);
  };

  var handleCatchmentViewControlDisplay = function ($popupCatchment) {
    var dataViewConfig = ToolFunctions.getCurrentDataViewConfig();
    if (dataViewConfig) {
      const hasCatchmentView = !!dataViewConfig.catchmentView;
      $popupCatchment.find(".tr-plu").toggle(!hasCatchmentView);
      $popupCatchment.find(".popup-catchment-view-control").toggle(hasCatchmentView);
    }
  };

  var handleCatchmentRoadConditionDisplay = async function ($popupCatchment, properties) {
    var catchWithRoadCondition =
      DataViewFunctions.getCurrentDataViewProperty("catchWithRoadCondition");
    $(".popup-catchment").find(".popup-catchment-road-condition").toggle(!!catchWithRoadCondition);

    var showRecordSweep =
      catchWithRoadCondition && UserPermissions.getPermission("can_create_record_sweeps");

    $(".popup-catchment")
      .find(".popup-catchment-record-sweep, .popup-footer")
      .toggle(!!showRecordSweep);

    if (showRecordSweep) {
      properties.group_id = Tree.get("activeGroup", "groupId");
      properties.catch_id = properties.catchid;
      properties.objectid = properties.gid;
      $popupCatchment.html(await StreetSweepingPopup.getStreetSweepingPopup({ properties }));
    }
  };

  return {
    getPopupHtml,
    onPopupOpen,
    getRoadConditionAvg,
  };
};

module.exports = CatchmentPopup();

const DataViewFunctions = require("../dataViewFunctions");
const UserPermissions = require("../login/userPermissions");
const PopupContent = require("../mapFunctions/popupContent");
const StreetSweepingPopup = require("../muni/streetSweepingPopup");
const ToolFunctions = require("../toolFunctions");
const Tree = require("../tree");
