"use strict";

const NumberInput = function () {
  const MAXIMUM = 9999999999999.99;

  const optionsPerType = {
    dollarAmount: {
      currencySymbol: "$",
      minimumValue: 0,
      maximumValue: MAXIMUM,
      emptyInputBehavior: "min",
      wheelOn: "hover",
      noEventListeners: false,
    },
    dollarAmountInteger: {
      currencySymbol: "$",
      minimumValue: 0,
      emptyInputBehavior: "min",
      wheelOn: "hover",
      decimalPlaces: 0,
      noEventListeners: false,
    },
    dollarAmountIntegerPlaceholder: {
      currencySymbol: "$",
      minimumValue: 0,
      emptyInputBehavior: "null",
      wheelOn: "hover",
      decimalPlaces: 0,
      noEventListeners: false,
    },
    dollarAmountPlaceholder: {
      currencySymbol: "$",
      minimumValue: 0,
      emptyInputBehavior: "null",
      wheelOn: "hover",
      noEventListeners: false,
    },
    percentage: {
      suffixText: "%",
      minimumValue: 0,
      maximumValue: 100,
      decimalPlaces: 0,
      noEventListeners: false,
    },
    percentInteger: {
      minimumValue: 0,
      maximumValue: 100,
      decimalPlaces: 0,
      noEventListeners: false,
      wheelOn: "hover",
      wheelStep: 1,
      emptyInputBehavior: 0,
    },
    positiveNegativeFloat: {
      minimumValue: -MAXIMUM,
      maximumValue: MAXIMUM,
    },
    positiveFloat: {
      minimumValue: 0,
      maximumValue: MAXIMUM,
    },
    positiveFloatTenth: {
      minimumValue: 0,
      maximumValue: MAXIMUM,
      decimalPlaces: 1,
    },
    positiveFloatHundredthAllowEmpty: {
      minimumValue: 0,
      maximumValue: MAXIMUM,
      decimalPlaces: 2,
    },
    positiveFloatHundredth: {
      minimumValue: 0,
      maximumValue: MAXIMUM,
      decimalPlaces: 2,
      emptyInputBehavior: 0,
    },
    latitude: {
      minimumValue: -90.0,
      maximumValue: 90.0,
      decimalPlaces: 8,
    },
    longitude: {
      minimumValue: -180.0,
      maximumValue: 180.0,
      decimalPlaces: 8,
    },
    signed: {
      minimumValue: -MAXIMUM,
      maximumValue: MAXIMUM,
      negativeBracketsTypeOnBlur: "(,)",
    },
    positiveInteger: {
      minimumValue: 0,
      decimalPlaces: 0,
      maximumValue: 999999999,
    },
    positiveIntegerEvents: {
      minimumValue: 0,
      decimalPlaces: 0,
      maximumValue: 999999999,
      wheelOn: "hover",
      overrideMinMaxLimits: null,
      noEventListeners: false,
    },
    integerIdInput: {
      minimumValue: 0,
      decimalPlaces: 0,
      maximumValue: 999999999999999,
      digitGroupSeparator: "",
    },
    positiveIntegerMinOne: {
      minimumValue: 1,
      decimalPlaces: 0,
      maximumValue: 999999999,
    },
  };

  const defaultOptions = {
    emptyInputBehavior: "null",
    maximumValue: MAXIMUM,
    minimumValue: 0,
    noEventListeners: false,
    onInvalidPaste: "clamp", // https://github.com/autoNumeric/autoNumeric/blob/66f871a658c8bd20441127286815b401af2ebbcc/src/AutoNumericOptions.js#L535
  };

  var initializeAutoNumericFieldByClass = function ($field) {
    if (!$field.hasClass("autonumeric")) {
      console.error("Field must have class autonumeric to initailize as AutoNumeric.");
    }

    const type = getType($field);
    const options = getOptions(type);
    new AutoNumeric($field[0], options);

    if (
      "minimumValue" in options &&
      options.minimumValue > 0 &&
      options.emptyInputBehavior === "null"
    ) {
      initializePositiveMinInput($field);
    }

    $field.eq(0).off("change", autoNumericChangeEvent);
    $field.eq(0).on("change", autoNumericChangeEvent);
  };

  var initializeYearInput = function ($field) {
    $field.eq(0).off("input", _onChangeYear).on("input", _onChangeYear);
    $field.eq(0).off("keydown", _onKeyDownYear).on("keydown", _onKeyDownYear);
  };

  var _onChangeYear = function () {
    const $this = $(this);
    const val = $this.val();
    if (val === "") {
      return;
    }
    const newVal = val.replace(/\D/g, "").slice(-4).padStart(4, "0");
    $this.val(newVal);
  };

  var _onKeyDownYear = function (e) {
    // if it is delete or backspace, empty the field
    if (e.keyCode === 8 || e.keyCode === 46) {
      $(this).val("");
    }
  };

  var initializePositiveMinInput = function ($field) {
    $field.eq(0).off("keydown", _onKeyDownPositiveMin).on("keydown", _onKeyDownPositiveMin);
  };

  var _onKeyDownPositiveMin = function (e) {
    // if it is delete or backspace, empty the field
    if (e.keyCode === 8 || e.keyCode === 46) {
      const $this = $(this);
      const currentValue = $this.val();
      const highlightedValue = window.getSelection()?.toString();

      if (highlightedValue === currentValue) {
        emptyInput(this);
      } else if (currentValue.length === 1) {
        if (!$this.data("lastDigitInput")) {
          $this.data("lastDigitInput", currentValue);
        } else {
          emptyInput(this);
          $this.data("lastDigitInput", "");
        }
      }
    }
  };

  var emptyInput = function (element) {
    const $element = $(element);
    $element.val("");
    $element.attr("value", "");
    manuallySetInput(element, "");
  };

  var getOptions = function (type) {
    const options = { ...defaultOptions };
    if (type !== null) {
      Object.assign(options, optionsPerType[type]);
    }
    return options;
  };

  var autoNumericChangeEvent = function () {
    const value = $(this).val();
    manuallySetInput(this, value);
  };

  var getType = function ($autonumericField) {
    const classes = Object.keys(optionsPerType).map(Misc.camelToKebab);
    const classFieldHas = classes.filter((className) => $autonumericField.hasClass(className));
    if (classFieldHas.length > 0) {
      if (classFieldHas.length > 1) {
        console.error(`Autonumeric field had multiple type classes: ${classFieldHas}`);
      }
      return Misc.kebabToCamel(classFieldHas[0]);
    }
    return null;
  };

  var loadAutoNumericListener = function ($parent, selector, callback) {
    $parent.off("autoNumeric:rawValueModified", selector);
    $parent.on("autoNumeric:rawValueModified", selector, function (e) {
      if (e.detail.oldRawValue === undefined) {
        return; // Don't send initialization to updates.
      }

      let newValue = e.detail.newRawValue;
      if (isIntOrStringInt(newValue)) {
        newValue = parseInt(newValue);
      }
      const event = {
        currentTarget: e.detail.aNElement.node(),
        newValue: newValue,
      };
      callback(event);
    });
  };

  var isIntOrStringInt = function (rawValue) {
    if (isNaN(rawValue)) {
      return false;
    }
    var floatVal = parseFloat(rawValue);
    return (floatVal | 0) === floatVal;
  };

  var removeAutoNumericListener = function ($parent, selector) {
    $parent.off("autoNumeric:rawValueModified", selector);
  };

  var manuallySetInput = function (element, value) {
    const autoNumericElement = getAutoNumericElement(element);
    try {
      autoNumericElement.set(value);
    } catch (e) {
      console.warn(e.message);
      clampToMinMax(element, value);
    }
  };

  var clampToMinMax = function (element, value) {
    const autoNumericElement = getAutoNumericElement(element);
    const settings = autoNumericElement.getSettings();
    const valueNumber = parseFloat(value);

    if (valueNumber > settings.maximumValue) {
      autoNumericElement.set(settings.maximumValue);
    } else if (valueNumber < settings.minimumValue) {
      autoNumericElement.set(settings.minimumValue);
    }
  };

  var getAutoNumericElement = function (element) {
    if (!AutoNumeric.isManagedByAutoNumeric(element)) {
      throw new Error("Attempted to access a non-autonumeric element");
    }

    return AutoNumeric.getAutoNumericElement(element);
  };

  var clear = function (element) {
    const autoNumericElement = getAutoNumericElement(element);
    autoNumericElement.clear();
  };

  var getValue = function (element) {
    const autoNumericElement = getAutoNumericElement(element);
    if (autoNumericElement.getNumericString() === "") {
      return null;
    }
  };

  var updateInputOptions = function (element, options) {
    const autoNumericElement = getAutoNumericElement(element);
    autoNumericElement.update(options);
  };

  var formatByType = function (number, type) {
    return AutoNumeric.format(number, getOptions(type));
  };

  var getMaximumValueConstant = function () {
    return MAXIMUM;
  };

  return {
    initializeAutoNumericFieldByClass,
    initializeYearInput,
    loadAutoNumericListener,
    removeAutoNumericListener,
    manuallySetInput,
    updateInputOptions,
    getValue,
    clear,
    formatByType,
    getMaximumValueConstant,
  };
};

module.exports = NumberInput();

const AutoNumeric = require("autonumeric");
const Misc = require("../misc");
