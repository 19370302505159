"use strict";

const ProjectInspectionGeneralInfo = function () {
  const stringKey = "general-info";
  const tabHeader = "General info";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on("2N:FormInput", "[name='precipitation-now']", _rerenderWeather);
    $page.on(
      "2N:FormInput",
      "[name='inspection-date'], [name='last-rainfall-date']",
      updateDaysSinceLastRainfall,
    );
    $page.on(
      "2N:FormInput",
      "[name='inspection-type']",
      ProjectInspectionController.updateVisibleTabs,
    );

    _rerenderWeather();
  };

  var unloadListeners = function () {
    $page.off(
      "2N:FormInput",
      "[name='inspection-date'], [name='last-rainfall-date']",
      updateDaysSinceLastRainfall,
    );
    $page.off(
      "2N:FormInput",
      "[name='inspection-type']",
      ProjectInspectionController.updateVisibleTabs,
    );
  };

  var getProps = function (data) {
    return {
      inspectionTypeOptions: ProjectInspectionConstants.getInspectionTypeOptions(),
      precipitationNowOptions: ProjectInspectionConstants.getPrecipitationNowOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var updateDaysSinceLastRainfall = function () {
    const data = ProjectInspectionController.getAllData();
    const daysSinceLastRainfall = getDaysSinceLastRainfall(
      data.inspectionDate,
      data.lastRainfallDate,
    );

    $page.find("[name=days-since-last-rainfall]").val(daysSinceLastRainfall);
  };

  var getDaysSinceLastRainfall = function (inspectionDate, lastRainfallDate) {
    if (!lastRainfallDate || !inspectionDate) {
      return null;
    }

    lastRainfallDate = DateTime.parseIsoDate(lastRainfallDate);
    inspectionDate = DateTime.parseIsoDate(inspectionDate);

    return DateTime.getDaysDifferenceNumber(inspectionDate, lastRainfallDate);
  };

  var _rerenderWeather = function () {
    const $currentWeather = $page.find(`[name='precipitation-now']`).parent();
    $currentWeather.siblings().remove();

    const data = ProjectInspectionController.getAllData();
    const weatherHtml = nunjucks.render("modals/projectInspection/templates/weather.njk", {
      props: data,
    });

    $currentWeather.after(weatherHtml);

    ProjectInspectionController.reloadListeners();
    updateDaysSinceLastRainfall();
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _rerenderWeather,
  };
};

module.exports = ProjectInspectionGeneralInfo();

const DateTime = require("../../dateTime");
const ProjectInspectionConstants = require("./projectInspectionConstants");
const ProjectInspectionController = require("./projectInspectionController");
