"use strict";

const ProjectDeliveryBmpsGeoServerLayer = function () {
  const layerName = "projectDeliveryBmps";
  const layerKey = "projectDeliveryBmpsLayer";

  var createMarkerFunction = function (feature, latlng) {
    const projectDeliveryBmpsGeoServerLayer = Tree.get([layerName, "main"]);
    const existingMarker = projectDeliveryBmpsGeoServerLayer?.getMarkerById(feature.properties.id);
    if (existingMarker) {
      updateMarker(existingMarker, feature);
      return existingMarker;
    } else {
      return L.marker(latlng, {
        icon: BmpFcsIcon.getIconMarker(
          feature.properties.bmp_score,
          feature.properties.cbmp,
          feature.properties.bmp_type_abbr,
          feature.properties.fcs,
          false,
          feature.properties.phase,
        ),
      });
    }
  };

  var updateMarker = function (marker, feature) {
    marker.setIcon(
      BmpFcsIcon.getIconMarker(
        feature.properties.bmp_score,
        feature.properties.cbmp,
        feature.properties.bmp_type_abbr,
        feature.properties.fcs,
        false,
        feature.properties.phase,
      ),
    );
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );

    return organizeLayerData(data);
  };

  var organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      const properties = data.properties;
      properties.geometry = data.geometry;
      return properties;
    });
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const projectDeliveryBmpsGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(
      layerName,
      mapId,
      projectDeliveryBmpsGeoServerLayer,
    );

    projectDeliveryBmpsGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    projectDeliveryBmpsGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    createMarkerFunction,
    getLayerData,
    invalidateLayerData,
  };
};

module.exports = ProjectDeliveryBmpsGeoServerLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const Tree = require("../tree");
