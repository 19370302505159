"use strict";

const PropertyCostsModalController = function () {
  let renderData;
  const formKey = "property-costs";

  const renderPageContent = function (html) {
    InventoryModal.setModalDynamicContent(html);
  };

  const getFormKey = function () {
    return formKey;
  };

  const getAllData = function () {
    return InventoryModal.getAllData(formKey);
  };

  const showPropertyCostsModal = async function (data, startingPage, options = {}) {
    renderData = data;
    const pages = [CapitalCostPage, PropertyCostsAddBmps, CostRecoveryPage];
    const handlers = {
      saveAndCloseHandler: saveAndCloseModal,
      cancelHandler: _onCancelled,
      formHasUpdatesHandler: _onFormHasUpdates,
    };

    Tree.set(["asset", formKey], {
      ...renderData,
      dropdownOptions: PageController.getDropdownOptions(),
    });
    await InventoryModal.showModal(pages, startingPage, options, handlers);
    setModalTitle();
    InventoryModal.restoreAllButtons();
    ButtonStyleRadios.init(formKey);
  };

  var saveAndCloseModal = async function () {
    const confirmSave = async function () {
      let updates = {};
      CostRecoveryPage.updateCostRecoveryPrograms();
      Form.getReadyToSavePromise(formKey)
        .then(async function () {
          const allData = getAllData();
          updates = Form.getDataFromForm(formKey);
          _addDefaults(updates, allData);
          processNumberStringsAddedBmps(updates.bmps || []);
          updates.analysisComplete = true;
          const updateData = Form.toFormData({
            id: renderData.id,
            updates: updates,
          });

          const newPropertyData = await EsgApiCalls.updatePropertyCost(updateData);
          PageController.refresh(newPropertyData);
          closeModal();
        })
        .catch((error) => {
          console.error(`Didn't save - Form.getReadyToSavePromise(${formKey}) failed`);
        });
    };
    return new Promise((resolve) => {
      MessageModal.showConfirmWarningModal(
        null,
        confirmSave,
        "Go Back",
        "Save & Close",
        _renderDesktopAnalysisReview(),
        null,
        "Review Desktop Analysis",
        "esg",
      );
      resolve(false);
    });
  };

  var _renderDesktopAnalysisReview = function () {
    const constants = PageController.getConstants();
    const allData = getAllData();
    _addDefaults(allData, allData);
    const data = {
      ...allData,
      siteComplexity: constants.siteComplexity[allData.siteComplexity],
      bmpRelationshipSummary: bmpSummary(allData, constants.bmpRelationship, "bmpRelationship"),
      retrofitOpportunitySummary: bmpSummary(
        allData,
        constants.retrofitOpportunities,
        "retrofitOpportunity",
      ),
      costRecoverySummary: costRecoverySummary(allData, constants),
    };
    return nunjucks.render("esg/modals/propertyCosts/propertyCostsConfirm.njk", data);
  };

  var _addDefaults = function (data, allData) {
    ["siteComplexity", "bmpRelationship", "retrofitOpportunity"].forEach((key) => {
      if (!allData[key]) {
        data[key] = "unknown";
      }
    });
  };

  const bmpSummary = (data, constants, key) => {
    const bmps = data.bmps || [];
    const choice = data[key];

    if (bmps.filter((bmp) => !bmp.deleted).length === 0) {
      return choice ? constants[choice] : "Unknown";
    }

    return Object.entries(constants)
      .map(([value, label]) => {
        const count = bmps.filter((bmp) => bmp[key] === value && !bmp.deleted).length;
        return count ? `${label} (${count})` : null;
      })
      .filter(Boolean)
      .join(", ");
  };

  var costRecoverySummary = function (data, constants) {
    return Object.keys(constants.compensationStatus)
      .map((status) => {
        const count = data.incentivePrograms.filter(
          (program) => program.compStatus === status,
        ).length;
        return count > 0 ? `${constants.compensationStatus[status]} (${count})` : null;
      })
      .filter(Boolean)
      .join(", ");
  };

  var closeModal = function () {
    Form.clearForm(formKey);
    InventoryModal.hide();
  };

  var _onCancelled = function () {
    Form.clearForm(formKey);
  };

  var _onFormHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getExistingPropertyData = function (path = []) {
    path = Array.isArray(path) ? path : [path];
    return Tree.get(["asset", formKey, ...path]);
  };

  function getLatestData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(formKey, path);
  }

  var setData = function (path, value) {
    path = Array.isArray(path) ? path : [path];
    if (typeof value === "undefined") {
      Form.manuallyUnsetField(formKey, path);
    } else {
      Form.manuallySetFormDataField(formKey, path, value);
    }
  };

  var setModalTitle = function () {
    InventoryModal.resetModalTitle();
    $('.modal-title[data-id="default"]').remove(); // @TODO figure out why this is not a problem in other inventory modals (like sBMP)
    const propertyIdentifier = getExistingPropertyData("propertyIdentifier");
    const propertyName = getLatestData("propertyName");

    const title = EsgSettings.propertyTitleDisplay({ propertyName, propertyIdentifier });
    InventoryModal.setModalTitle(`${title.label}: `, title.value, title.fieldName);
    InventoryModal.setModalTitle("Property Address:", renderData.address, "address");

    if (renderData.hasOwnProperty("planStatus")) {
      InventoryModal.setModalTitle("Property Status:", renderData.planStatus, "planStatus");
    }
  };

  var loadExistingPropertyMarker = function (zoom) {
    const latlng = getExistingPropertyData(["centroid", "coordinates"]);
    const marker = new L.marker(latlng, {
      icon: L.divIcon({ iconSize: [0, 0] }),
    });
    ModalMap.setSingleMarker(marker);
  };

  var processNumberStringsAddedBmps = function (bmps) {
    bmps.forEach((bmp) => {
      ["drainageArea", "imperviousArea", "treatmentCapacity", "footprint"].forEach((field) => {
        if (bmp[field]) {
          bmp[field] = removeCommasFromNumberString(bmp[field]);
        }
      });
    });
  };

  var removeCommasFromNumberString = function (numberString) {
    if (typeof numberString === "number") {
      return numberString;
    } else {
      return numberString.replace(/,/g, "");
    }
  };

  return {
    showPropertyCostsModal,
    getFormKey,
    getAllData,
    renderPageContent,
    getExistingPropertyData,
    getLatestData,
    setData,
    setModalTitle,
    loadExistingPropertyMarker,
    bmpSummary,
    _renderDesktopAnalysisReview,
    _addDefaults,
  };
};

module.exports = PropertyCostsModalController();
const InventoryModal = require("../../general/inventoryModal");
const CapitalCostPage = require("./propertyCosts/capitalCostPage");
const Tree = require("../../tree");
const ButtonStyleRadios = require("../../general/buttonStyleRadios");
const PropertyCostsAddBmps = require("./propertyCosts/propertyCostsAddBmps");
const ModalMap = require("../../mapFunctions/modalMap");
const Form = require("../../general/form");
const EsgApiCalls = require("../esgApiCalls");
const CostRecoveryPage = require("./propertyCosts/costRecoveryPage");
const PageController = require("../pageController");
const MessageModal = require("../../modals/messageModal");
const EsgSettings = require("../esgSettings");
