"use strict";

const IddeCatchBasinLayer = function () {
  const ApiCalls = require("../apiCalls");
  const DryWeatherLayer = require("./dryWeatherLayer");

  const layerName = "iddeCatchBasin";
  const layerKey = "iddeCatchBasinLayer";

  var getIddeCatchBasinPopup = function (id) {
    return ApiCalls.getIddeDryWeatherPopup(id, "bmpFcs");
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    iddeCatchBasinLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    iddeCatchBasinLayer.loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (mapId === "main") {
      iddeCatchBasinLayer.loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  const iddeCatchBasinLayer = new DryWeatherLayer(
    layerName,
    layerKey,
    ApiCalls.getDryWeatherCatchBasins,
    getIddeCatchBasinPopup,
    false,
    true,
  );

  return {
    ...iddeCatchBasinLayer,
    loadLayerListenersForMap,
  };
};

module.exports = IddeCatchBasinLayer();
