"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetTwoPies = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [
      {
        className: "hover-pie",
        getHoverHtml: InsightWidgetTwoPies.prototype.getHoverHtml,
      },
    ];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 1;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetTwoPies.prototype = Object.create(InsightWidget.prototype);
InsightWidgetTwoPies.prototype.constructor = InsightWidgetTwoPies;

InsightWidgetTwoPies.prototype.getHtml = function (insightData) {
  var title = this.widgetConfig.title;

  var html = nunjucks.render("insight/widgets/twoPies.njk", {
    title,
    onePie: this.widgetConfig.onePie,
    onePieLabel: this.widgetConfig.onePieLabel,
  });

  return html;
};

InsightWidgetTwoPies.prototype.renderChart = function (insightData, exportModal = false) {
  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }

  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight;

  var margin = defaults.margin,
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom,
    radius = this.widgetConfig.onePie ? 80 : 50;

  var chartData = this.widgetConfig.getChartData(insightData),
    legendData = this.widgetConfig.legendData();

  chartData.forEach(function (chart, i) {
    if (chart.slices.every((d) => d.value === 0)) {
      chartData[i].noData = true;
      chartData[i].slices = [
        { value: 1, color: "#D7D7D7", noData: true },
        { value: 1, color: "#E4E4E4", noData: true },
        { value: 1, color: "#DDDDDA", noData: true },
      ];
    }
    chart.slices = chart.slices.filter((slice) => slice.value !== 0);
  });

  Object.keys(legendData).forEach((key) => {
    if (chartData.every((chart) => chart.slices.every((d) => d.key !== key))) {
      delete legendData[key];
    }
  });

  const extraMargin = this.widgetConfig.onePie ? 20 : 10;
  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom + extraMargin);

  var pie = d3.pie().value(function (d) {
    return d.value;
  });

  if (this.widgetConfig.customSort) {
    pie.sort(null);
  }

  var arc = d3.arc().outerRadius(radius).innerRadius(0);

  var outsideArc = d3
    .arc()
    .innerRadius(radius + 10)
    .outerRadius(radius + 10);

  const chartCount = this.widgetConfig.onePie ? 1 : 2;
  var charts = svg
    .selectAll("g")
    .data(chartData)
    .enter()
    .append("g")
    .attr("transform", function (d, i) {
      const left = (containerWidth * i + containerWidth / 2) / chartCount;
      return `translate(${left},${containerHeight / 2 + extraMargin})`;
    })
    .attr("pointer-events", "bounding-box")
    .attr("class", function (d, i) {
      let classes = "pie pie-" + i;
      if (!d.noData) {
        classes += " hover-pie";
      }
      return classes;
    })
    .attr("data-hover-key", function (d) {
      return d.hoverKey;
    });

  var chartLabel = charts
    .append("text")
    .attr("class", "pie-title")
    .attr("x", 0)
    .attr("y", radius + 20)
    .attr("text-anchor", "middle");

  const wholeNumber = this.widgetConfig.wholeNumber;
  if (!this.widgetConfig.hideTotal) {
    chartLabel
      .append("tspan")
      .attr("font-size", "15px")
      .attr("x", 0)
      .text(function (d) {
        const total = d.slices.reduce((acc, curr) => acc + curr.value, 0);
        if (!d.noData) {
          if (wholeNumber) {
            return `${total} ${d.unit}`;
          } else {
            return `${InsightWidgetFunctions.formatNumber(total)} ${d.unit}`;
          }
        }
      });
  }

  chartLabel
    .append("tspan")
    .attr("x", 0)
    .attr("dy", function (d) {
      if (d.noData) {
        return "0";
      } else {
        return "18";
      }
    })
    .text(function (d) {
      return d.label;
    });

  var pies = charts
    .selectAll(".pie")
    .data(function (d) {
      return pie(d.slices);
    })
    .enter()
    .append("g")
    .attr("class", "arc");

  pies
    .append("path")
    .attr("d", arc)
    .attr("fill", function (d) {
      return d.data.color || legendData[d.data.key].color;
    });

  charts
    .append("circle")
    .attr("fill", "transparent")
    .attr("r", radius - 1)
    .style("stroke-dasharray", "4, 4")
    .attr("stroke-width", 2)
    .attr("stroke", function (d) {
      return d.noData ? "black" : "transparent";
    });

  charts
    .append("text")
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .attr("class", "no-data")
    .text(function (d) {
      return d.noData ? "No data" : "";
    });

  pies
    .append("text")
    .attr("transform", function (d) {
      if (smallArc(d)) {
        return "translate(" + outsideArc.centroid(d) + ")";
      } else {
        return "translate(" + arc.centroid(d) + ")";
      }
    })
    .attr("class", function (d) {
      let classes = "keep-color";
      if (smallArc(d)) {
        classes += " label-outside";
      }
      return classes;
    })
    .attr("dy", ".35em")
    .attr("font-size", "10px")
    .attr("text-anchor", "middle")
    .attr("fill", function (d) {
      return Misc.isDarkColor(d.data.color || legendData[d.data.key].color) && !smallArc(d)
        ? "#fff"
        : "#000";
    })
    .text(function (d) {
      const value = wholeNumber ? d.data.value : InsightWidgetFunctions.formatNumber(d.data.value);
      const theText = d.data.noData ? "" : value;
      d.width = theText.length * 5;
      return theText;
    });

  [0, 1].forEach(function (pieNum) {
    const outsideLabels = d3.selectAll(`${chartContainerSelector} .pie-${pieNum} .label-outside`),
      outsideLabelsData = outsideLabels.data();
    outsideLabelsData.forEach(function (d) {
      d.x = outsideArc.centroid(d)[0];
      d.y = outsideArc.centroid(d)[1];
    });

    const force = d3
      .forceSimulation(outsideLabelsData)
      .force(
        "collide",
        d3.forceCollide().radius((d) => d.width / 2 + 3),
      )
      .force("radial", d3.forceRadial(radius + 10))
      .stop();
    for (let i = 0; i < 300; i++) force.tick();
    outsideLabels.attr("transform", function (d) {
      return "translate(" + d.x + "," + d.y + ")";
    });
  });

  this.appendStandardChartLegend(svg, legendData, this.widgetConfig.legendCountPerRow);

  function smallArc(d) {
    return d.endAngle - d.startAngle < 0.5;
  }
};

InsightWidgetTwoPies.prototype.getHoverHtml = function (insightData, $hoverTarget) {
  var hoverData = this.widgetConfig.getHoverData(insightData),
    hoverKey = $hoverTarget.data("hover-key"),
    data = hoverData[hoverKey],
    template = data.template;

  var html = nunjucks.render(template, {
    ...data,
  });

  return html;
};

module.exports = InsightWidgetTwoPies;

const d3 = require("d3-v6");
const Misc = require("../../misc");
const InsightWidgetFunctions = require("./insightWidgetFunctions");
