"use strict";

const EsriLayerFunctions = function () {
  var publishingServerToken;
  var modelingServerToken;
  const esriLayerUrlByEsriKey = {
    soilImpervious:
      "https://2nformspatial.com/server/rest/services/Annual_Reporting_Input_Layers/MapServer",
    streetSweeping:
      "https://2nformspatial.com/server/rest/services/Street_Sweeping_Roads/MapServer",
    roadsOm: "https://2nformspatial.com/server/rest/services/Road_Conditions/MapServer",
    trash: "https://2nformspatial.com/server/rest/services/Trash/MapServer",
    parcelsPlu: "https://2nformspatial.com/server/rest/services/Priority_Landuse/MapServer",
  };

  var getEsriLayer = async function (esriKey, esriLayer, options = {}) {
    const esriLayerUrl = getEsriLayerUrl(esriKey);
    var token;

    if (esriLayerUrl.includes("2nformspatial")) {
      token = await getEsriLayerToken("publishing");
    } else {
      token = await getEsriLayerToken();
    }

    const layerId = getLayerId(esriKey, esriLayer);

    const layerOptions = {
      url: esriLayerUrl,
      layers: [layerId],
      token: token,
    };

    return L.esri.dynamicMapLayer(Object.assign(layerOptions, options));
  };

  var getEsriLayerUrl = function (esriKey) {
    if (!esriLayerUrlByEsriKey[esriKey]) {
      throw new Error("esriKey not defined in esriLayerUrlByEsriKey: " + esriKey);
    }
    return esriLayerUrlByEsriKey[esriKey];
  };

  var getEsriLayerToken = async function (server = "modeling") {
    if (server == "publishing") {
      if (!publishingServerToken) {
        publishingServerToken = await ReportApiCalls.getEsriLayerToken("publishing");
        return publishingServerToken;
      } else {
        return publishingServerToken;
      }
    } else {
      if (!modelingServerToken) {
        modelingServerToken = await ReportApiCalls.getEsriLayerToken();
        return modelingServerToken;
      } else {
        return modelingServerToken;
      }
    }
  };

  var getLayerDefs = function (esriKey, esriLayer, emptyLayerDef = false) {
    var filtersStringArray = [];
    const config = EsriConfig[esriKey];
    const twoNFilters = Tree.get("filters");
    const year = AnnualReports.getSelectedYear();
    const esriFilters = getEsriSpatialFilters(twoNFilters, config);
    const isDemoGroup = Session.isDemoGroup();
    let layerId;
    if (esriKey === "trash" && (year > 2021 || isDemoGroup) && Config.gisServer === "Production") {
      layerId = config["ids"][esriLayer] + 4;
    } else if (
      esriKey === "trash" &&
      (year > 2021 || isDemoGroup) &&
      Config.gisServer !== "Production"
    ) {
      layerId = config["ids"][esriLayer] + 8;
    } else {
      layerId = config["ids"][esriLayer];
    }
    if (emptyLayerDef) {
      filtersStringArray.push("1=1");
    } else {
      addGroupIdFilterString(filtersStringArray);
      filtersStringArray.push(getYearSQL(year, config));

      for (const key in esriFilters) {
        filtersStringArray.push(arrayToSqlIn(key, esriFilters[key]));
      }
    }

    return getLayerDefinitionByLayer(layerId, filtersStringArray);
  };

  var getLayerDefinitionByLayer = function (layerId, filtersStringArray) {
    const layerDefinitions = {};
    if (Array.isArray(layerId)) {
      for (const layer in layerId) {
        layerDefinitions[layer] = filtersStringArray.join(" AND ");
      }
    } else {
      layerDefinitions[layerId] = filtersStringArray.join(" AND ");
    }
    return layerDefinitions;
  };

  // @return { FILTER_KEY: ["FILTER_VALUE", ...], ... }
  // Logic from API SpatialFilterBuilder.addSpatialFiltersToQueryBuilder
  var getEsriSpatialFilters = function (twoNFilters, config) {
    const filtersMap = config["filters"];
    var esriFilters = {};

    if (twoNFilters["receivingWaters"] && twoNFilters["receivingWaters"].length > 0) {
      if (twoNFilters["dataSort"] === "ud" || twoNFilters["spatialView"] === "drainage") {
        esriFilters[filtersMap["drainsToC"]] = twoNFilters["receivingWaters"];
      } else {
        esriFilters[filtersMap["drainsToRw"]] = twoNFilters["receivingWaters"];
      }
    }

    if (twoNFilters["catchments"] && twoNFilters["catchments"].length > 0) {
      esriFilters[filtersMap["catchments"]] = twoNFilters["catchments"];
    }

    return esriFilters;
  };

  // @return "COLUMN IN ('VALUE', ...)"
  var arrayToSqlIn = function (key, filterArray) {
    const inString = filterArray
      .map(function (filter) {
        if (Number.isInteger(filter) || filter === null) {
          return `${filter}`;
        } else {
          return `'${filter}'`;
        }
      })
      .join(", ");
    return `${key} IN (${inString})`;
  };

  var getYearSQL = function (year, config) {
    const reportYearValid = config["filters"]["reportYearFirstValid"];
    const reportYearNotValid = config["filters"]["reportYearNotValid"];
    var selectedYear = parseInt(year);

    if (reportYearValid && reportYearNotValid) {
      return `(${reportYearValid} <= ${selectedYear} OR ${reportYearValid} IS null) AND (${reportYearNotValid} > ${selectedYear} OR ${reportYearNotValid} IS null)`;
    }

    return `year = ${selectedYear}`;
  };

  var getLayerId = function (esriKey, esriLayer) {
    if (esriKey in EsriConfig) {
      const config = EsriConfig[esriKey];
      const isDemoGroup = Session.isDemoGroup();
      if (esriLayer in config["ids"]) {
        const year = AnnualReports.getSelectedYear();
        if (
          esriKey === "trash" &&
          (year > 2021 || isDemoGroup) &&
          Config.gisServer === "Production"
        ) {
          return config["ids"][esriLayer] + 4;
        } else if (
          esriKey === "trash" &&
          (year > 2021 || isDemoGroup) &&
          Config.gisServer !== "Production"
        ) {
          return config["ids"][esriLayer] + 8;
        }
        return config["ids"][esriLayer];
      }
      throw new Error("esriLayer not defined in EsriConfig ids: " + esriLayer);
    }
    throw new Error("esriKey not defined in EsriConfig: " + esriKey);
  };

  var getLayerDefsQuery = function (esriKey, esriLayer) {
    const layerId = getLayerId(esriKey, esriLayer);
    const layerDefs = getLayerDefs(esriKey, esriLayer);
    return layerDefs[layerId];
  };

  var getLayerProperties = function (layer, esriKey, esriLayer, callback, layerId = null) {
    if (layerId === null) {
      layerId = getLayerId(esriKey, esriLayer);
    }
    const layerDefsQuery = getLayerDefsQuery(esriKey, esriLayer);

    layer
      .query()
      .layer(layerId)
      .returnGeometry(false)
      .where(layerDefsQuery)
      .run((error, featureCollection, response) => {
        callback(featureCollection);
      });
  };

  var addGroupIdFilterString = function (filtersStringArray) {
    let groupIdFilterStr;
    const progeny = Progeny.getActiveGroupProgeny();
    if (progeny) {
      const progenyGroupIds = progeny.map((group) => group.groupId);
      if (progenyGroupIds?.length) {
        groupIdFilterStr = `group_id IN (${progenyGroupIds.join(",")})`;
      } else {
        groupIdFilterStr = `group_id = ${Tree.get("activeGroup", "groupId")}`;
      }
    }

    if (groupIdFilterStr) {
      filtersStringArray.push(groupIdFilterStr);
    }
  };

  return {
    getEsriLayer,
    getEsriLayerToken,
    getLayerDefs,
    getLayerProperties,
    getLayerId,
    getLayerDefsQuery,
  };
};

module.exports = EsriLayerFunctions();

const ReportApiCalls = require("../reportApiCalls");
const EsriConfig = require("../config/esriConfig");
const AnnualReports = require("../annualReports");
const Config = require("../config");
const Progeny = require("../../login/progeny");
const Session = require("../../login/session");
const Tree = require("../../tree");
