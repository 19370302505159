"use strict";

function updatePassword(e) {
  var email = $("#emailPU").val();

  var $oldPassword = $("#passwordPU");
  var oldPassword = $oldPassword.val();

  var $newPassword = $("#newPasswordPU");
  var newPassword = $newPassword.val();

  var $verifynewPassword = $("#verifyNewPasswordPU");
  var verifynewPassword = $verifynewPassword.val();

  var $errorMessage = $(".errorMessage");

  if (verifynewPassword !== newPassword) {
    $errorMessage.text("New passwords do not match.");
    $verifynewPassword.val("");
    $newPassword.val("").select();
    return;
  }

  SingleSignOn.updatePassword(email, oldPassword, newPassword, function (newTokenData) {
    if (!newTokenData.error) {
      Session.initSession(newTokenData);
      $errorMessage.text("Your password has been updated.");
    } else {
      $errorMessage.text(newTokenData.error);
      $verifynewPassword.val("");
      $newPassword.val("").select();
    }
  });
}

function forgotPassword() {
  var email = $("#pUsername").val();
  ReportApiCalls.sendPassword(
    email,
    function () {
      $("#forgotComment").html("<p>A reset link has been emailed to " + email + "</p>");
    },
    function () {
      $("#forgotComment").html("<p>Could not send email, please try again.</p>");
    },
  );
}

function showforgotPassword() {
  $("#forgotDiv").css("display", "block");
  $("#loginDiv").css("display", "none");
}

function returnToLogin() {
  $(".sub-login-pages").css("display", "none");
  $("#loginDiv").css("display", "block");
}

function logout() {
  // clears session, revokes token and redirects
  Session.clear();
  SingleSignOn.logout(UrlRoutes.navigateToHomePage);
}

var Login = {
  updatePassword: updatePassword,
  showforgotPassword: showforgotPassword,
  returnToLogin: returnToLogin,
  forgotPassword: forgotPassword,
  logout: logout,
};

module.exports = Login;

const ReportApiCalls = require("../reportApiCalls");
const Session = require("../../login/session");
const SingleSignOn = require("../../login/sso");
const UrlRoutes = require("../../routes/urlRoutes");
