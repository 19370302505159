"use strict";

const ToDoFunctions = function () {
  var loadToDoFiltersListeners = function () {
    const dataView = Tree.get("dataView");

    const watcher = Tree.watch({
      toDoFilters: ["toDoFilters", dataView],
    });
    watcher.off("update", onToDoFilterUpdate);
    watcher.on("update", onToDoFilterUpdate);

    const selectedSubjectWatcher = Tree.watch({
      selectedSubject: ["todos", dataView, "selectedSubject"],
    });
    selectedSubjectWatcher.off("update", onSelectedSubjectUpdate);
    selectedSubjectWatcher.on("update", onSelectedSubjectUpdate);
  };

  var onToDoFilterUpdate = function (e) {
    if (e.target.get().toDoFilters) {
      ToDoListController.loadTodos(Tree.get("filters"), false);
    }

    ToDoMapTable.updateToDoMapLayers();
  };

  var onSelectedSubjectUpdate = function (e) {
    ToDoMapTable.updateToDoMapLayers();
  };

  var getTodoPropertyNameByAssetType = function (property) {
    var dataView = Tree.get("dataView");

    switch (property) {
      case "score":
        if (dataView === "bmp") {
          return "bmpMapScore";
        }
        return dataView + "_score";
      case "days_remaining":
        return dataView + "_days_remaining";
      case "obs_date":
        return dataView + "_obs_date";
      case "due_date":
        return dataView + "_due_date";
      default:
        console.error("Todo property not defined");
    }
  };

  var getTodoByIdBmp = function (idBmp) {
    var dataView = Tree.get("dataView");
    return Tree.get("todos", dataView, "unfilteredData").filter((todo) => todo.idBmp == idBmp)[0];
  };

  var setToDoSubject = function (subject) {
    Tree.set(["todos", Tree.get("dataView"), "selectedSubject"], subject);
  };

  var getIsIdInTodos = function (id, todoSubject) {
    var todoIdSets = Tree.get(["todos", Tree.get("dataView"), "todoIdSets"]);
    if (!todoIdSets) {
      return;
    }

    if (todoSubject) {
      if (!Array.isArray(todoSubject)) {
        todoSubject = [todoSubject];
      }

      for (const subject of todoSubject) {
        if (!todoIdSets[subject] || !todoIdSets[subject].has(id)) {
          return false;
        }
      }
      return true;
    } else {
      for (const key in todoIdSets) {
        if (todoIdSets[key].has(id)) {
          return true;
        }
      }
    }
    return false;
  };

  var canAccessToDoSubject = function (subject) {
    const canAccessCallback = ToDoConfig.getToDoSubjectConfig(
      [subject, "canAccessCallback"],
      false,
    );

    if (!canAccessCallback) {
      throw new Error(`canAccessCallback is not defined for to do subject ${subject}.`);
    }

    return canAccessCallback();
  };

  var getToDoSubjectByApiSubjectName = function (apiSubjectName) {
    const toDoSubjects = ToDoConfig.getToDoSubjectConfig([]);

    for (const key in toDoSubjects) {
      if (toDoSubjects[key].apiSubjectName === apiSubjectName) {
        return key;
      }
    }

    throw new Error(`No todoSubject found for apiSubjectName ${apiSubjectName}.`);
  };

  var getIsToDoFilterEnabled = function (sectionName, dataView = Tree.get("dataView")) {
    const defaultToDoFilter = ToDoConfig.getToDoFiltersDataViewConfig(
      [dataView, "filters", sectionName],
      false,
    );

    return !!defaultToDoFilter;
  };

  var prepareToDoSpatialFilterData = function (data) {
    if (DataViewFunctions.getUserEnabledTabs().includes("todo")) {
      if (Tree.get("toDoSpatialFilterData") === null && data?.length && data[0]) {
        if (data[0].drains_to_rw) {
          prepareReceivingWaterFilterData(data);
        } else if (data[0].maintenanceZoneName) {
          prepareMaintenanceZoneFilterData(data);
        } else {
          console.warn("Unidentified spatial table");
        }
      }
    }
  };

  var prepareReceivingWaterFilterData = function (data) {
    var preparedSpatialData = data.map((datum) => ({
      drainsToRw: datum.drains_to_rw,
      catchments: datum.catchments?.map((catchment) => ({
        id: catchment.gid,
        catchId: catchment.catchid,
        drainsToRw: catchment.drains_to_rw,
      })),
    }));
    Tree.set("toDoSpatialFilterData", {
      filterSection: "receivingWater",
      spatialData: preparedSpatialData,
    });
  };

  var prepareMaintenanceZoneFilterData = function (data) {
    var preparedSpatialData = data.map((datum) => ({
      id: datum.gid,
      maintenanceZoneName: datum.maintenanceZoneName,
    }));
    Tree.set("toDoSpatialFilterData", {
      filterSection: "maintenanceZone",
      spatialData: preparedSpatialData,
    });
  };

  var cleanSpatialToDoFilters = function (toDoFilters) {
    var cleanedToDoFilters = {};
    for (const key in toDoFilters) {
      const filters = toDoFilters[key];
      const newCatchmentArray = [];
      if (filters["catchment"]?.length) {
        filters["catchment"].forEach((catchment) => {
          const parent = $(`.spatial-child[value="${catchment}"]`).closest("ul").data("parent");
          if (!filters["receivingWater"] || !filters["receivingWater"].includes(parent)) {
            newCatchmentArray.push(catchment);
          }
        });
      }
      cleanedToDoFilters[key] = Object.assign({}, filters, { catchment: newCatchmentArray });
    }
    return cleanedToDoFilters;
  };

  var getFilterDataType = function (toDoSubject, dataView) {
    const filterDataTypes = ToDoConfig.getToDoFiltersDataViewConfig([dataView, "filterDataTypes"]);
    return filterDataTypes[toDoSubject];
  };

  var prepareTodoDataIntoSubjects = function (data, dataView) {
    var toDoSubjects = DataViewFunctions.getUserEnabledToDoSubjects(dataView);

    var todoMapTableData = {};
    toDoSubjects.forEach((subject) => {
      todoMapTableData[subject] = { data: [] };
    });

    data = TableDataFunctions.prepareData(data, dataView, true);

    data.forEach(function (item) {
      pushSubjects(item, todoMapTableData, dataView);
    });

    return todoMapTableData;
  };

  var addMissingToDoSubjects = function (toDos) {
    for (const toDo of toDos) {
      if (toDo.toDoSubjects === undefined) {
        toDo.toDoSubjects = [getToDoSubjectByApiSubjectName(toDo.subject)];
      }
    }

    return toDos;
  };

  var pushSubjects = function (item, todoMapTableData) {
    for (const key of item.toDoSubjects) {
      if (todoMapTableData[key]) {
        todoMapTableData[key].data.push(item);
      }
    }
  };

  var getToDoFilters = function (dataView = Tree.get("dataView")) {
    const toDoFilters = Tree.get("toDoFilters")?.[dataView];
    return cleanSpatialToDoFilters(toDoFilters);
  };

  var invalidateToDoData = function (layerName) {
    if (isToDoLayer(layerName)) {
      Tree.set(["todos", Tree.get("dataView"), "unfilteredData"], null);
    }
  };

  var isToDoLayer = function (layerName) {
    return (
      DataViewFunctions.getCurrentDataViewProperty("defaultLayers").includes(layerName) &&
      DataViewFunctions.getUserEnabledTabs(Tree.get("dataView"), "map").includes("todo")
    );
  };

  var loadTodoByLayer = function (layerName, mapId = "main") {
    if (mapId === "main" && isToDoLayer(layerName) && Tree.get("activeTab") === "todo") {
      return ToDoListController.loadTodos();
    }

    return null;
  };

  var addCommonDueDateFields = function (toDo, isoDateString) {
    if (!isoDateString) {
      toDo.dueDateIso = null;
      toDo.displayDueDate = "—";
      toDo.pastDue = false;
      return;
    }

    const parsedDate = DateTime.parseIsoDate(isoDateString);

    toDo.dueDateIso = isoDateString;
    toDo.displayDueDate = DateTime.dateToGroupDisplayDate(parsedDate);
    toDo.pastDue = DateTime.isPast(parsedDate);
  };

  var flattenAdditionalInfoAndCloneExtrasOntoEndOfArray = function (array, toDo, toDoKey) {
    const additionalInfo = toDo[toDoKey]?.additionalInfo;
    if (!Array.isArray(additionalInfo)) {
      return;
    }

    for (var i = 1; i < additionalInfo.length; i++) {
      const clone = $.extend(true, {}, toDo);
      clone[toDoKey].additionalInfo = additionalInfo[i];
      array.push(clone);
    }

    toDo[toDoKey].additionalInfo = toDo[toDoKey].additionalInfo[0];
  };

  var showConfirmClearToDoListConfirm = function (
    count,
    { selectedToDosName, confirmButton } = {},
  ) {
    const pluralizedSectionToDoName = NunjucksFilters.pluralIfMultiple(selectedToDosName, count);
    const thisThese = NunjucksFilters.pluralIfMultiple("this", count);

    const message = `This will remove <strong>${count}</strong> ${pluralizedSectionToDoName} from your to do list. Click “Go Back” to return to the to do list. Click “${confirmButton}” to remove ${thisThese} ${pluralizedSectionToDoName} from your to do list.`;

    return MessageModal.showConfirmWarningModalAsPromise(message, {
      okMessage: confirmButton,
      returnMessage: "Go Back",
    });
  };

  return {
    getTodoPropertyNameByAssetType,
    getTodoByIdBmp,
    getToDoSubjectByApiSubjectName,
    canAccessToDoSubject,
    getIsToDoFilterEnabled,
    getIsIdInTodos,
    loadToDoFiltersListeners,
    prepareToDoSpatialFilterData,
    setToDoSubject,
    getFilterDataType,
    prepareTodoDataIntoSubjects,
    getToDoFilters,
    invalidateToDoData,
    isToDoLayer,
    loadTodoByLayer,
    addMissingToDoSubjects,
    addCommonDueDateFields,
    flattenAdditionalInfoAndCloneExtrasOntoEndOfArray,
    showConfirmClearToDoListConfirm,
  };
};

module.exports = ToDoFunctions();

const DataViewFunctions = require("../dataViewFunctions");
const DateTime = require("../dateTime");
const TableDataFunctions = require("../general/tableDataFunctions");
const ToDoConfig = require("../config/toDoConfig");
const ToDoListController = require("./toDoListController");
const ToDoMapTable = require("./toDoMapTable");
const Tree = require("../tree");
const MessageModal = require("../modals/messageModal");
const NunjucksFilters = require("../general/nunjucksFilters");
