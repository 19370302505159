"use strict";

var LanduseInputsLayer = function () {
  const esriKey = "soilImpervious";
  const esriLayer = "landuse";
  const layerName = "landuseInputs";
  const layerKey = "landuseInputsLayer";
  var layer = null;

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      layerName,
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers[layerKey] && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers[layerKey]);
          } else {
            if (sameSpatialFilter && sameView) {
              // Temp solution to prevent loading twice from dataView and filters update
              return;
            }
            createLayerAndAddToMap(map, mapLayers);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers[layerKey])) {
            map.removeLayer(mapLayers[layerKey]);
          }
        }
      },
    );
  };

  var createLayerAndAddToMap = async function (map, mapLayers) {
    if (mapLayers[layerKey]) {
      map.removeLayer(mapLayers[layerKey]);
    }
    if (!Tree.get(["layers", layerName, "isFetching"])) {
      Tree.set(["layers", layerName, "isFetching"], true);
      mapLayers[layerKey] = await createLayer();
      Tree.set(["layers", layerName, "isFetching"], false);
      mapLayers[layerKey].addTo(map);
    }

    return mapLayers[layerKey];
  };

  var createLayer = async function () {
    const options = {
      pane: "underCatchmentPane",
      layerDefs: EsriLayerFunctions.getLayerDefs(esriKey, esriLayer),
      opacity: 0.7,
    };
    layer = await EsriLayerFunctions.getEsriLayer(esriKey, esriLayer, options);
    EsriLayerFunctions.getLayerProperties(layer, esriKey, esriLayer, renderSummaryTable);
    return layer;
  };

  var renderSummaryTable = function (featureCollection) {
    var data = getLanduseKpiData(featureCollection);
    var formattedKpiData = InputsLayerFunctions.getFormattedKpiData(data);
    Kpi.renderKpi(formattedKpiData, null, true);
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      catchments: ["filters", "catchments"],
      receivingWaters: ["filters", "receivingWaters"],
    }).on("update", function () {
      if (
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", mapId, layerName, "isEnabled")
      ) {
        mapLayers[layerKey].setLayerDefs(EsriLayerFunctions.getLayerDefs(esriKey, esriLayer));
      }
    });
  };

  var getLanduseKpiData = function (featureCollection) {
    var totalAcres = 0;
    var landuseAcresMap = {};

    if (featureCollection && featureCollection.features) {
      featureCollection.features.forEach((landuse) => {
        const acres = landuse.properties.acres;
        const lu = landuse.properties.lu;

        if (!(lu in landuseAcresMap)) {
          landuseAcresMap[lu] = { acres: 0 };
        }
        landuseAcresMap[lu].acres += acres;
        totalAcres += acres;
      });
      addLandusePercentAcres(totalAcres, landuseAcresMap);
    }
    return { totalAcres, landuseAcresMap };
  };

  var addLandusePercentAcres = function (totalAcres, landuseAcresMap) {
    for (const lu in landuseAcresMap) {
      landuseAcresMap[lu].percentAcres = landuseAcresMap[lu].acres / totalAcres;
    }
  };

  return {
    loadLayerListenersForMap,
    getLanduseKpiData,
  };
};

module.exports = LanduseInputsLayer();

const MapFunctions = require("../../mapFunctions/mapFunctions");
const Tree = require("../../../tree");
const EsriLayerFunctions = require("../../mapFunctions/esriLayerFunctions");
const Kpi = require("../../report/kpi");
const InputsLayerFunctions = require("../inputsLayerFunctions");
