"use strict";

require("leaflet");
require("leaflet.vectorgrid");

var Layers = function () {
  var popupOptions = {
    minWidth: 200,
    maxWidth: 500,
    className: "twon-popup",
    autoPanPaddingTopLeft: L.point(370, 70),
    autoPanPaddingBottomRight: L.point(270, 70),
  };

  var layerTypes = {};
  var inited = false;

  function getLayers() {
    if (!inited) {
      initializeLayers();
      inited = true;
      return layerTypes;
    }
    return layerTypes;
  }

  const Ms4BoundaryLayer = function (data) {
    const boundaryLayerGray = _boundaryLayer(
      data,
      MapStyles.styleBoundaryGray,
      "ms4BoundaryGrayPane",
    );
    const boundaryLayerWhite = _boundaryLayer(
      data,
      MapStyles.styleBoundaryWhite,
      "ms4BoundaryPane",
    );

    return L.layerGroup([boundaryLayerGray, boundaryLayerWhite]);
  };

  const _boundaryLayer = function (data, style, pane) {
    return L.geoJSON(data, {
      pane: pane,
      style: style,
      interactive: false,
    });
  };

  function initializeLayers() {
    var WatershedLayer = L.GeoJSON.extend({
      options: {
        pane: "stormdrainPane",
        style: MapStyles.styleThreeOThree,
        onEachFeature: function (feature, layer) {
          layer.bindPopup(PopupContent.getWatershedPopup(feature), popupOptions);
        },
      },
    });

    var BaseStormdrainLayer = L.GeoJSON.extend({
      options: {
        pane: "stormdrainPane",
        style: MapStyles.styleBaseStormdrain,
      },
    });

    var MiddleStormdrainLayer = L.GeoJSON.extend({
      options: {
        pane: "stormdrainPane",
        style: MapStyles.styleMiddleStormdrain,
      },
    });

    var StormdrainLayer = L.GeoJSON.extend({
      options: {
        pane: "stormdrainPane",
        style: MapStyles.styleStormdrain,
      },
    });

    var SurveyLayer = L.GeoJSON.extend({
      options: {
        pointToLayer: function (feature, latlng) {
          var marker = L.marker(latlng, {
            icon: MapStyles.getTrashPointIcon(feature.properties.condition),
          });
          return marker;
        },
        onEachFeature: function (feature, layer) {
          feature.properties["source"] = "survey123";
          feature.properties["color"] = MapStyles.getTrashDataColor(feature.properties.condition);
          layer.bindPopup(nunjucks.render("report/popups/rawData.html", feature.properties));
        },
      },
    });

    var TrashPointLayer = L.GeoJSON.extend({
      options: {
        pointToLayer: function (feature, latlng) {
          var marker = L.marker(latlng, {
            icon: MapStyles.getTrashPointIcon(feature.properties.condition),
          });
          return marker;
        },
        style: MapStyles.styleTrashPoints,
        onEachFeature: function (feature, layer) {
          feature.properties["source"] = "collector";
          feature.properties["color"] = MapStyles.getTrashDataColor(feature.properties.condition);
          layer.bindPopup(nunjucks.render("report/popups/rawData.html", feature.properties));
        },
      },
    });

    var CatchmentMixin = {
      highlight: function (data, highlightAll = false) {
        if (!highlightAll && (!data.prop || !data.id)) {
          console.error(
            `Expected data object to have a prop and id defined, was prop: ${data.prop}, id: ${data.id}.`,
          );
        }

        var zoom = this._map.getZoom();
        this.eachLayer(function (layer) {
          var isTarget;
          if (!highlightAll) {
            isTarget = layer.feature.properties[data.prop] == data.id;
          }
          if (isTarget || highlightAll) {
            if (zoom > 15) {
              layer.setStyle(MapStyles.zoomHighlights());
            } else {
              layer.setStyle(MapStyles.highlights());
            }
          }
        });
      },
      unhighlight: function () {
        var self = this;
        self.eachLayer(function (layer) {
          var isEnabled = !layer.feature.properties.disabled;
          if (layer.feature.properties.selected) {
            layer.setStyle(MapStyles.selectedHighlight());
          } else if (isEnabled) {
            self.resetStyle(layer);
          } else {
            layer.setStyle(MapStyles.grayResults());
          }
        });
      },
      filterDrainsTo: function (filter) {
        this.eachLayer(function (layer) {
          if (catchmentInSpatialFilter(filter, layer.feature)) {
            enablePolygonProperties(layer);
            layer.setStyle(MapStyles.selectedHighlight());
          } else {
            disablePolygonProperties(layer);
            layer.setStyle(MapStyles.grayResults());
          }
        });
      },
      select: function (data) {
        //        console.log('This is the select function which doesnt currently work');
      },
    };

    var TelrMixin = {
      filterDrainsToTelr: filterDrainsToTelr,
    };

    var TrashLineLayer = L.GeoJSON.extend({
      options: {
        style: MapStyles.styleCollectorData,
      },
    });

    var CollectorLineOutlineLayer = L.GeoJSON.extend({
      options: {
        style: MapStyles.styleCollectorOutline,
      },
    });

    var ParcelGridLayer = L.VectorGrid.Slicer.extend({
      options: {
        rendererFactory: L.canvas.tile,
        tolerance: 5,
        indexMaxZoom: 0,
        maxZoom: 20,
        vectorTileLayerStyles: {
          sliced: MapStyles.styleParcelCondition,
        },
      },
    });

    var FullDrainageLayer = L.GeoJSON.extend({
      options: {
        pane: "underCatchmentPane",
        style: function (feature) {
          return MapStyles.fcsProgressDrainagePolygon(feature);
        },
      },
    });

    var SbmpProgressDrainageLayer = L.GeoJSON.extend({
      options: {
        pane: "underCatchmentPane",
        style: function (feature) {
          return MapStyles.sbmpProgressDrainagePolygon(feature);
        },
      },
    });

    var FcsProgressDrainageLayer = L.GeoJSON.extend({
      options: {
        pane: "underCatchmentPane",
        style: function (feature) {
          return MapStyles.fcsProgressDrainagePolygon(feature);
        },
      },
    });

    var MuniCatchBasinProgressDrainageLayer = L.GeoJSON.extend({
      options: {
        pane: "underCatchmentPane",
        style: function (feature) {
          return MapStyles.muniCatchBasinProgressDrainagePolygon(feature);
        },
      },
    });

    var ConstructionProjectAreaLayer = L.GeoJSON.extend({
      options: {
        pane: "underCatchmentPane",
        style: ConstructionProjectAreaProgressLayer.getStyle,
        onEachFeature: function (feature, layer) {
          feature.properties["source"] = "constructionProjectArea";
        },
      },
    });

    var HighPriorityAreaLayer = L.GeoJSON.extend({
      options: {
        pane: "underCatchmentPane",
        style: HighPriorityAreaProgressLayer.getStyle,
        onEachFeature: function (feature, layer) {
          feature.properties["source"] = "highPriorityArea";
        },
      },
    });

    var LidProjectAreaLayer = L.GeoJSON.extend({
      options: {
        pane: "underCatchmentPane",
        style: LidProjectAreaProgressLayer.getStyle,
        onEachFeature: function (feature, layer) {
          feature.properties["source"] = "lidProjectArea";
        },
      },
    });

    var ModalCatchmentLayer = L.GeoJSON.extend({
      options: {
        pane: "catchmentPane",
        style: MapStyles.styleCatchments,
      },
    });

    var RunoffLayer = L.GeoJSON.extend({
      options: {
        pane: "overCatchmentPane",
        style: MapStyles.styleSwtelrCatchments,
        onEachFeature: function (feature, layer) {
          const mapId = Tree.get("mapId");
          layer.bindPopup(function () {
            return nunjucks.render("report/popups/runoffPopup.njk", {
              properties: feature.properties,
              mapId,
            });
          }, popupOptions);
          layer.on({
            mouseover: telrHighlight,
            mouseout: telrUnhighlight,
            click: expandTableAndScroll,
          });
        },
      },
      includes: TelrMixin,
    });

    var ConnectivityLayer = L.GeoJSON.extend({
      options: {
        pane: "overCatchmentPane",
        style: MapStyles.styleSwtelrCatchments,
        onEachFeature: function (feature, layer) {
          layer.bindPopup(function () {
            return nunjucks.render("report/popups/connectivityPopup.njk", {
              properties: feature.properties,
            });
          }, popupOptions);
          layer.on({
            mouseover: telrHighlight,
            mouseout: telrUnhighlight,
            click: expandTableAndScroll,
          });
        },
      },
      includes: TelrMixin,
    });

    var ParticulatesLayer = L.GeoJSON.extend({
      options: {
        pane: "overCatchmentPane",
        style: MapStyles.styleSwtelrCatchments,
        onEachFeature: function (feature, layer) {
          const mapId = Tree.get("mapId");
          layer.bindPopup(function () {
            return nunjucks.render("report/popups/particulatesPopup.njk", {
              properties: feature.properties,
              mapId,
            });
          }, popupOptions);
          layer.on({
            mouseover: telrHighlight,
            mouseout: telrUnhighlight,
            click: expandTableAndScroll,
          });
        },
      },
      includes: TelrMixin,
    });

    var RunoffRatioLayer = L.GeoJSON.extend({
      options: {
        pane: "overCatchmentPane",
        style: MapStyles.styleSwtelrCatchments,
        onEachFeature: function (feature, layer) {
          layer.bindPopup(function () {
            return nunjucks.render("report/popups/runoffRatioPopup.njk", {
              properties: feature.properties,
            });
          }, popupOptions);
          layer.on({
            mouseover: telrHighlight,
            mouseout: telrUnhighlight,
            click: expandTableAndScroll,
          });
        },
      },
      includes: TelrMixin,
    });

    var CatchmentLayer = L.GeoJSON.extend({
      //on each disabled so g2 popup layer listeners are not overwritten
      options: {
        pane: "catchmentPane",
        style: MapStyles.styleCatchments,
        onEachFeature: function (feature, layer) {
          layer.bindPopup(PopupContent.getCatchmentPopup(feature), popupOptions);
          layer.on({
            mouseover: highlight,
            mouseout: unhighlight,
            click: catchmentLayerClick,
          });
        },
      },
      includes: CatchmentMixin,
    });

    var HighwayLayer = L.GeoJSON.extend({
      options: {
        pane: "catchmentPane",
        style: MapStyles.styleCatchments,
      },
    });

    layerTypes = {
      WatershedLayer,
      BaseStormdrainLayer,
      MiddleStormdrainLayer,
      StormdrainLayer,
      SurveyLayer,
      TrashPointLayer,
      TrashLineLayer,
      CollectorLineOutlineLayer,
      ParcelGridLayer,
      ModalCatchmentLayer,
      CatchmentLayer,
      HighwayLayer,
      Ms4BoundaryLayer,
      FullDrainageLayer,
      SbmpProgressDrainageLayer,
      FcsProgressDrainageLayer,
      MuniCatchBasinProgressDrainageLayer,
      RunoffLayer,
      ParticulatesLayer,
      RunoffRatioLayer,
      ConnectivityLayer,
      ConstructionProjectAreaLayer,
      HighPriorityAreaLayer,
      LidProjectAreaLayer,
    };
  }

  var highlight = function (e) {
    var zoom = this._map.getZoom();
    var gid = e.target.feature.properties.gid;
    handleTableHighlight(gid);
    e.target.setStyle(MapStyles.zoomHighlights(zoom));
  };

  var viewHighlight = function (e) {
    e.target.setStyle(MapStyles.highlights());
  };

  var unhighlight = function (e) {
    var dataProps = e.target.feature.properties;
    var gid = dataProps.gid;
    var disabled = dataProps.disabled;
    handleTableHighlight(gid);
    if (disabled == null || disabled) {
      e.target.setStyle(MapStyles.styleCatchments());
    } else {
      e.target.setStyle(MapStyles.selectedHighlight());
    }
  };

  var telrHighlight = function (e) {
    var gid = e.target.feature.properties.gid;
    viewHighlight(e);
    handleTableHighlight(gid);
  };

  var telrUnhighlight = function (e) {
    var styler = e.target.defaultOptions.style;
    var disabled = e.target.feature.properties.disabled;
    var gid = e.target.feature.properties.gid;
    if (disabled) {
      e.target.setStyle(MapStyles.grayResults());
    } else {
      e.target.setStyle(styler(e.target.feature));
    }
    handleTableHighlight(gid);
  };

  var catchmentInSpatialFilter = function (filter, feature) {
    var properties = feature.properties || feature;

    return (
      filter.receivingWaters.includes(properties["drains_to_rw"]) ||
      filter.receivingWaters.includes(properties["drains_to_c"]) ||
      filter.catchments.includes(properties["catchid"])
    );
  };

  var spatialFiltersAreEmpty = function (filter) {
    return filter.receivingWaters.length === 0 && filter.catchments.length === 0;
  };

  var filterDrainsToTelr = function (filter) {
    this.eachLayer(function (layer) {
      if (featureInFilter(filter, layer.feature)) {
        enablePolygonProperties(layer);
        layer.setStyle(MapStyles.styleSwtelrCatchments(layer.feature));
      } else {
        disablePolygonProperties(layer);
        layer.setStyle(MapStyles.grayResults());
      }
    });
  };

  var handleTableHighlight = function (gid) {
    var tableItem;
    var sort = Tree.get("filters", "dataSort");

    if (sort == "uc" || sort == "total" || sort == "plu") {
      tableItem = $(".catchments-only h3[data-id=" + gid + "]");
    } else {
      tableItem = $(".catchment-header li[data-id=" + gid + "]");
    }

    if (tableItem.hasClass("map-hover")) {
      tableItem.removeClass("map-hover");
    } else {
      tableItem.addClass("map-hover");
    }
  };

  var scrollTable = function (gid) {
    const mapId = Tree.get("mapId");
    const tbl = $("#" + mapId + " .bottomFloatingInputsTable");
    const tableCatch = tbl.find('[data-id="' + gid + '"]');

    if (tableCatch) {
      //check if catch table entry out of view
      if (tableCatch.offset()) {
        //expand and scroll table so catch table entry is visible
        tbl.animate(
          {
            scrollTop:
              tableCatch.offset().top -
              tbl.offset().top +
              tbl.scrollTop() -
              tbl.innerHeight() / 2 +
              tableCatch.outerHeight() / 2,
          },
          250,
        );
      }
    }
  };

  function highlightDrainage() {
    const mapId = Tree.get("mapId");
    const selectedId = Tree.get("layers", mapId, "fcs", "selectedId");
    const fullDrainage = Tree.get("layers", mapId, "fullDrainage", "data");
    for (var index in fullDrainage.features) {
      if (fullDrainage.features[index].properties.idbmp == selectedId) {
        fullDrainage.features[index].properties["highlight"] = true;
      } else {
        fullDrainage.features[index].properties["highlight"] = false;
      }
    }
    Tree.set(["layers", mapId, "fullDrainage", "data"], "");
    Tree.set(["layers", mapId, "fullDrainage", "data"], fullDrainage);
  }

  var expandTableAndScroll = function (e) {
    //on click map catchment expand table and scroll to catchment clicked
    var gid = e.target.feature.properties.gid;
    Table.expandAll();
    scrollTable(gid);
  };

  var catchmentLayerClick = function (e) {
    const catchmentFeature = e.target.feature;
    if (LayerDropdown.getCurrentLayerDropdownProperty("catchmentPopupTemplate")) {
      EsriLayerPopup.renderCatchmentPopupByLayerDropdown(catchmentFeature);
    }
    handleCatchmentPopupDisplay();
    expandTableAndScroll.call(this, e);
  };

  var handleCatchmentPopupDisplay = function () {
    var showPriorityLanuse = !DataViewFunctions.getCurrentDataViewProperty(
      "hideCatchmentPopupPriorityLanduse",
    );
    $(".row-priority-landuse").toggle(showPriorityLanuse);
  };

  const setDrainageHighlight = (e) => {
    const idbmp = e.target.feature.properties.idbmp;
    Tree.set(["layers", Tree.get("mapId"), "fcs", "selectedId"], idbmp);
    const layerCheck = Tree.get("layers", Tree.get("mapId"), "fullDrainage", "isEnabled");
    if (!layerCheck) {
      Actions.loadFcsDrainage(Tree.get("mapId"));
    } else {
      highlightDrainage();
    }
  };

  var featureInFilter = function (filter, feature) {
    return spatialFiltersAreEmpty(filter) || catchmentInSpatialFilter(filter, feature);
  };

  var enablePolygonProperties = function (layer) {
    layer.feature.properties.disabled = false;
    layer.feature.properties.selected = true;
  };

  var disablePolygonProperties = function (layer) {
    layer.feature.properties.disabled = true;
    layer.feature.properties.selected = false;
  };

  return {
    scrollTable,
    highlightDrainage,
    setDrainageHighlight,
    featureInFilter,
    getLayers,
  };
};

module.exports = Layers();

const Actions = require("../actions");
const Table = require("./table");
const PopupContent = require("./popupContent");
const MapStyles = require("./mapStyles");
const Tree = require("../../tree");
const LayerDropdown = require("./layerDropdown");
const EsriLayerPopup = require("./esriLayerPopup");
const ConstructionProjectAreaProgressLayer = require("../construction/constructionProjectAreaProgressLayer");
const LidProjectAreaProgressLayer = require("../lid/lidProjectAreaProgressLayer");
const HighPriorityAreaProgressLayer = require("../idde/highPriorityAreaLayer");
const DataViewFunctions = require("../dataViewFunctions");
