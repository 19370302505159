"use strict";

var LidProjectAreaProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "lidProjectAreaProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.lidProjectAreaProgressLayer && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers.lidProjectAreaProgressLayer);
          } else {
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.lidProjectAreaProgressLayer)) {
            map.removeLayer(mapLayers.lidProjectAreaProgressLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "lidProjectAreaProgress", "isFetching"], true);
    ReportApiCalls.getLidProjectAreaProgress(filters, function (data) {
      if (data) {
        data.forEach((project) => {
          project.geom = project.projectAreaGeom;
        });
      }
      Tree.set(["layers", mapId, "lidProjectAreaProgress", "isFetching"], false);
      Tree.set(["layers", mapId, "lidProjectAreaProgress", "data"], data);
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "lidProjectAreaProgress", function (data) {
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.lidProjectAreaProgressLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.lidProjectAreaProgressLayer) {
      map.removeLayer(mapLayers.lidProjectAreaProgressLayer);
    }
    mapLayers.lidProjectAreaProgressLayer = createLayer(data);
    mapLayers.lidProjectAreaProgressLayer.addTo(map);
    return mapLayers.lidProjectAreaProgressLayer;
  };

  var createLayer = function (data) {
    return new (Layers.getLayers().LidProjectAreaLayer)(data);
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // @TODO: Update for lid filters
    Tree.watch({
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.lidProjectAreaProgressLayer) &&
        Tree.get("layers", mapId, "lidProjectAreaProgress", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var getStyle = function (feature) {
    const color = LidProjectStyles.getColorHex(feature.properties);
    return {
      color: color,
      fillOpacity: 0.8,
      fillColor: color,
      weight: 0,
    };
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getStyle,
  };
};

module.exports = LidProjectAreaProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Layers = require("../mapFunctions/layers");
const LidProjectStyles = require("./lidProjectStyles");
