"use strict";

const PageController = function () {
  let firstLoad = true;
  let config;
  let pageDataFunction;
  let pageTemplate;
  let beforeNextRenderFunction;

  let page;
  let $page;
  let pageData;
  let sideBarData;
  let sideBarDataPromise;
  let constants;
  let layerModule;

  const render = async function (_page, template, dataFunction, options = {}) {
    config = options;
    pageDataFunction = dataFunction;
    pageTemplate = template;
    page = _page;
    $page = $(`#esg-${page}`);

    _reset();

    if (config.dataView) {
      Tree.set("dataView", config.dataView);
      if (EsgRouteFunctions.isNewRoute()) _setDataSort();
    }

    await _loadPage();
    _loadListeners();
  };

  const _reset = function () {
    DataList.reset();
    Tree.select("filters").off("update", _onFiltersUpdate);
    Tree.set(["filters", "type"], null);
  };

  const _loadListeners = function () {
    Tree.select("filters").on("update", _onFiltersUpdate);
  };

  var _onFiltersUpdate = async function () {
    await layerModule?.loadGeoServerLayer({ refreshData: false, loadingScreen: false });
  };

  const beforeNextRender = function (callback) {
    beforeNextRenderFunction = callback;
  };

  const _loadPage = async function (data = null) {
    if (firstLoad) LoadingScreen.show();
    firstLoad = false;

    beforeNextRenderFunction?.();
    beforeNextRenderFunction = null;

    if (config.doNotRerender) {
      _initializeDataList();
    } else {
      await _renderPage(data);
    }

    _applySavedFilters();
    config.afterRenderFunction?.($page, _getPreparedData());
    LoadingScreen.hide();
  };

  const _renderPage = async function (data = null) {
    if (data) {
      pageData = data;
    } else {
      await LoadingScreen.waitForResult(".page.current, #mega-menu div", _getData());
    }
    Breadcrumbs.setBreadcrumbs(config.breadcrumbs);
    MegaMenu.init();
    Map.remove();
    _renderTemplate();
    Map.init({
      dataView: config.dataView,
      layerModule,
      ...config.layer?.map,
    });
    _initializeDataList();
    SideBar.init(config.sideBar, sideBarData, config.layer?.name);
    NavTabs.setupTabs(config.navTabs);
    NavToggle.setupToggles(config.navToggle);
    Table.handleSideBarToggle();
  };

  const refresh = async function (data = null) {
    if (layerModule) {
      await layerModule.loadGeoServerLayer();
    } else {
      await _loadPage(data);
    }
  };

  const rerender = async function () {
    await _loadPage(pageData);
  };

  const getConfig = function () {
    return config;
  };

  const _getG2Data = async function () {
    layerModule = LayerFunctions.getLayerModule(config.layer.name);
    layerModule.init(config.layer.initParam);
    await layerModule.loadWfsLayerData(true, false);
    Tree.set(["layers", config.layer.name, "isEnabled"], true);
    Tree.set("enabledGeoServerLayers", [config.layer.name]);
  };

  const getData = function (prop = null) {
    if (prop === null) return pageData;
    if (typeof pageData[prop] === "undefined") return null;

    return pageData[prop];
  };

  const getLayerData = function (prop = null) {
    const layerName = config.layer?.name;
    if (!layerName) return null;
    const data = Tree.get(["layers", layerName, "data"]) || [];
    if (prop === null) return data;
    if (typeof data[prop] === "undefined") return null;
  };

  const getConstants = function (key = null) {
    if (key === null) return constants || {};
    if (typeof constants === "undefined") return null;

    return constants[key];
  };

  const _renderTemplate = function () {
    const html = pageTemplate ? nunjucks.render(pageTemplate, _getPreparedData()) : "";

    $(".page").html("").removeClass("current");

    $page.html(html).addClass("current");
  };

  const _getPreparedData = function (data = null) {
    const theData = data || getData();
    return {
      dropdownOptions: getDropdownOptions(),
      ...(config.preparedDataFunction ? config.preparedDataFunction(theData) : theData),
    };
  };

  const _getPreparedLayerData = function (data = null) {
    const theData = data || getLayerData();
    return config.layer?.list?.preparedDataFunction
      ? config.layer.list.preparedDataFunction(theData)
      : theData;
  };

  const _getConstants = async function () {
    const planId = config.layer?.name === "planProperties" ? getData("id") : null;
    constants = config.constants ? await EsgApiCalls.getConstants(config.constants, planId) : {};
  };

  const _getData = async function () {
    if (config.layer?.g2) await _getPageData();
    await Promise.all([
      config.layer?.g2 ? _getG2Data() : _getPageData(),
      _getConstants(),
      _getSideBarData(),
    ]);
  };

  const _getSideBarData = async function () {
    if (!config?.sideBar?.dataFunction) return;

    if (EsgRouteFunctions.isNewRoute()) {
      sideBarData = null;
      sideBarDataPromise = null;
    }

    if (sideBarData) return sideBarData;
    if (sideBarDataPromise) return sideBarDataPromise;

    sideBarDataPromise = (async () => {
      const data = await config.sideBar.dataFunction();
      const filteredIds = Tree.get("filteredIds");

      sideBarData = filteredIds ? data?.filter((datum) => filteredIds.includes(datum.id)) : data;

      sideBarDataPromise = null;
      return sideBarData;
    })();

    return sideBarDataPromise;
  };

  const _getPageData = async function () {
    layerModule = null;
    pageData = await pageDataFunction();
  };

  const _initializeDataList = function () {
    if (!config?.layer?.list) return;

    DataList.init({
      prepareDataCallback: _getPreparedLayerData,
      template: config.layer.list.template,
      layerName: config.layer.name,
      afterRenderFunction: _afterListRenderFunction,
    });
    if (config.layer.list.defaultSortKey) {
      const reversed = config.layer.list.defaultSortReversed || null;
      DataList.setSorting(config.layer.list.defaultSortKey, reversed);
    }
    DataList.renderSorting();
    DataList.activateOverflowTooltips();
    DataList.loadLayerListeners();
    DataList.loadDomListeners(config.layer.list.listeners);
  };

  const _afterListRenderFunction = function (rowsAndOptions) {
    _saveIds(rowsAndOptions.rows);
    config.layer.list.afterRenderFunction(rowsAndOptions);
  };

  const _saveIds = function (rows) {
    const ids = rows.map((row) => row.id);
    Tree.set("filteredIds", ids);
  };

  const getDropdownOptions = function (removeNulls = true) {
    const allOptions = {};
    if (!constants) return allOptions;
    Object.keys(constants).forEach((constant) => {
      const options = constants[constant];
      let optionArray;
      if (typeof options === "object" && !Array.isArray(options)) {
        optionArray = Object.keys(options)
          .filter((key) => !removeNulls || key !== "")
          .map((key) => ({
            value: key,
            name: options[key],
          }));
      } else {
        optionArray = options
          .filter((option) => !removeNulls || option !== null)
          .map((option) =>
            option === null ? { value: null, name: "No Data" } : { value: option, name: option },
          );
      }
      allOptions[constant] = _shouldSortOptions(constant)
        ? optionArray.sort(sortWithNullAtEnd)
        : optionArray;
    });

    return allOptions;
  };

  const sortWithNullAtEnd = function (a, b) {
    if (typeof a === "object" && !Array.isArray(a)) {
      if (a.value === null) return 1;
      if (b.value === null) return -1;
      return a.name.localeCompare(b.name);
    }
    if (a === "No Data") return 1;
    if (b === "No Data") return -1;
    return a.localeCompare(b);
  };

  const getDefaultFilters = function () {
    const defaultFilters = {};
    if (!constants) return defaultFilters;
    Object.keys(constants).forEach((constant) => {
      const options = constants[constant];
      if (typeof options === "object" && !Array.isArray(options)) {
        defaultFilters[constant] = Object.keys(options);
      } else {
        defaultFilters[constant] = options;
      }
    });

    return defaultFilters;
  };

  const _shouldSortOptions = function (constant) {
    const constantsNotSorted = [
      "ejCommunity",
      "retrofitOpportunities",
      "siteComplexity",
      "bmpRelationship",
      "compensationStatus",
      "ownershipStatus",
      "propertyTypes",
      "programTypes",
      "propertyTypeUnitConsumptiveUse",
      "rankCategories",
      "compTypes",
    ];
    return !constantsNotSorted.includes(constant);
  };

  const _setDataSort = function () {
    const defaultDataSort = DataViewFunctions.getCurrentDataViewProperty("defaultDataSort");
    Filters.setDataSort(defaultDataSort);
  };

  const getPageName = function () {
    return page;
  };

  const saveFilters = function () {
    if (!config?.saveFilters) return;

    const filters = Tree.get("filters");
    const defaultFilters = getDefaultFilters();
    Object.keys(defaultFilters).forEach((constant) => {
      if (FilterSummary.areAllCheckboxesChecked(constant)) {
        filters[constant] = defaultFilters[constant];
      }
    });
    Tree.set(_getPageFiltersKey(), filters);
  };

  const _applySavedFilters = function () {
    const filters = Tree.get(_getPageFiltersKey());
    if (filters) {
      Tree.set("filters", filters);
      SideBar.renderFilters(filters);
      $("#inventoryFilters .search-input input").val(filters.searchString || "");
      if (config.sideBar?.filterBarButtons && filters.searchString) {
        $(".search-input").show();
      }
    }
  };

  const _getPageFiltersKey = function () {
    const id = pageData?.id || "";
    return `${page}${id ? `-${id}` : ""}-filters`;
  };

  return {
    render,
    beforeNextRender,
    refresh,
    rerender,
    getData,
    getLayerData,
    getConfig,
    getConstants,
    getDropdownOptions,
    getDefaultFilters,
    sortWithNullAtEnd,
    getPageName,
    saveFilters,
    _applySavedFilters,
  };
};

module.exports = PageController();

const NavToggle = require("./navToggle");
const LoadingScreen = require("../general/loadingScreen");
const NavTabs = require("./navTabs");
const SideBar = require("./sideBar");
const Map = require("./map");
const DataList = require("../mapFunctions/dataList");
const MegaMenu = require("./megaMenu");
const Breadcrumbs = require("./breadcrumbs");
const EsgApiCalls = require("./esgApiCalls");
const Tree = require("../tree");
const LayerFunctions = require("../layerFunctions");
const DataViewFunctions = require("../dataViewFunctions");
const Filters = require("../mapFunctions/filters");
const EsgRouteFunctions = require("../routes/esg/esgRouteFunctions");
const Table = require("../mapFunctions/table");
const FilterSummary = require("../filters/filterSummary");
