"use strict";

const AssetsCatchBasinGeoServerDynamicMapLayer = function () {
  const layerName = "assetsCatchBasinEsriDynamicMap";
  const layerKey = "assetsCatchBasinEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsCatchBasinEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsCatchBasinEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsCatchBasinEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = AssetsCatchBasinGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
