"use strict";

const PageConfig = function () {
  const get = function () {
    return {
      inputs: {
        pageSelector: "#inputs",
        enabledDataViews: Progeny.activeHasProgeny()
          ? []
          : ["trashView", "runoffView", "particulatesView", "runoffRatioView"],
        mapId: "inputs",
        displayName: "Inputs",
      },
      report: {
        pageSelector: "#report",
        enabledDataViews: [
          "trashView",
          "runoffView",
          "particulatesView",
          "sbmpView",
          "regionAreaView",
          "regionPointView",
          "runoffRatioView",
          "constructionView",
          "lidView",
          "muniCatchBasinView",
          "fcsView",
          "watershedView",
          "iddeView",
          "outfallView",
        ],
        mapId: "report",
        displayName: "Progress Map",
      },
      plan: {
        pageSelector: "#plan",
        enabledDataViews: Progeny.activeHasProgeny()
          ? []
          : ["trashView", "runoffView", "particulatesView", "runoffRatioView"],
        mapId: "plan",
        displayName: "Opportunity",
      },
      chart: {
        pageSelector: "#chart",
        enabledDataViews: ["trashView", "runoffView", "particulatesView", "regionAreaView"],
        disabledInRegionalView: false,
        mapId: "report",
        displayName: "Chart",
      },
      summary: {
        pageSelector: "#summary",
        enabledDataViews: getSummaryEnabledDataViews(),
        disabledInRegionalView: Progeny.activeGroupIsRegulator() && Progeny.activeHasProgeny(),
        mapId: "report",
        displayName: "Summary",
      },
      submit: {
        pageSelector: "#submit-report",
        enabledDataViews: [
          "trashView",
          "runoffView",
          "particulatesView",
          "sbmpView",
          "runoffRatioView",
          "constructionView",
          "lidView",
          "muniCatchBasinView",
          "fcsView",
          "watershedView",
          "regionAreaView",
          "iddeView",
          "publicView",
          "assetManagementView",
          "indCommView",
          "muniFacilityView",
          "muniBmpView",
          "outfallView",
          "streetSweepingView",
        ],
        disabledInRegionalView: false,
        mapId: null,
        displayName: "Annual Reporting",
      },
      groupSettings: {
        pageSelector: "#group-settings",
        enabledDataViews: [
          "trashView",
          "runoffView",
          "particulatesView",
          "sbmpView",
          "constructionView",
          "lidView",
          "muniCatchBasinView",
          "fcsView",
          "watershedView",
        ],
        disabledInRegionalView: true,
        disabledForRegulators: true,
        mapId: null,
        displayName: "Group Settings",
      },
    };
  };

  const getSummaryEnabledDataViews = function () {
    const defaultDataViews = [
      "trashView",
      "sbmpView",
      "particulatesView",
      "runoffView",
      "muniCatchBasinView",
      "publicView",
      "iddeView",
      "assetManagementView",
      "indCommView",
      "muniFacilityView",
      "muniBmpView",
      "constructionView",
      "outfallView",
      "lidView",
      "streetSweepingView",
    ];

    if (Session.isCdot()) {
      return defaultDataViews.filter((dataView) => dataView !== "iddeView");
    }

    return defaultDataViews;
  };

  return { get };
};

module.exports = PageConfig();

const Progeny = require("../../login/progeny");
const Session = require("../../login/session");
