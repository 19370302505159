"use strict";

const ProjectInspectionSubmission = function () {
  const stringKey = "submission";
  const tabHeader = "Submission";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    renderSummary(data);

    $page.on("2N:FormInput", "[name='followupInspection']", onFollowupInspectionChange);
    $page.on("2N:FormInput", "[name='isSiteRepPresent']", onIsSiteRepPresentChange);
    $page.on("click", "[name='preview']", previewButtonClick);
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", "[name='followupInspection']", onFollowupInspectionChange);
    $page.off("2N:FormInput", "[name='isSiteRepPresent']", onIsSiteRepPresentChange);
    $page.off("click", "[name='preview']", previewButtonClick);
  };

  var getProps = function (data) {
    return {
      additionalCorrectiveActionOptions:
        ProjectInspectionConstants.getAdditionalCorrectiveActionOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onFollowupInspectionChange = function (e, followupInspection) {
    const dueDateKey = "followupInspectionDueDate";
    const $dueDateFieldset = $(`[name="${dueDateKey}"]`).parent();
    $dueDateFieldset.toggle(followupInspection);
    // Field data is conditionally nulled in the API
  };

  var onIsSiteRepPresentChange = function (e, isSiteRepPresent) {
    const $fieldsContainer = $("#is-site-rep-present-field-container-js");
    if (isSiteRepPresent === "yes") {
      $fieldsContainer.show();
    } else {
      $fieldsContainer.hide();
    }
    // Field data is conditionally nulled in the API
  };

  var renderSummary = function (data) {
    $page
      .find("#js-project-inspection-submission-summary")
      .html(ProjectInspectionSummary.getSummaryHtml(data));
  };

  var previewButtonClick = function () {
    InspectionPreview.preview(
      ProjectInspectionController.getDataToUpload,
      ApiCalls.downloadConstructionProjectInspectionPreview,
    );
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    renderSummary,
  };
};

module.exports = ProjectInspectionSubmission();

const ProjectInspectionController = require("./projectInspectionController");
const ApiCalls = require("../../apiCalls");
const ProjectInspectionConstants = require("./projectInspectionConstants");
const InspectionPreview = require("../../general/inspectionPreview");
const ProjectInspectionSummary = require("./projectInspectionSummary");
