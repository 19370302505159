"use strict";

// https://github.com/Leaflet/Leaflet.markercluster

const Clustering = function () {
  const maxFeaturesToUncluster = 1000;

  var update = function (layerName) {
    if (layerName === null) {
      return;
    }

    const map = MainMap.getMap();
    const layer = MainMap.getMapLayerByLayerName(layerName);
    const layerExists = MainMap.layerExists(layer);
    const layerEnabled = Tree.get("layers", layerName, "isEnabled");
    const layerClusteringEnabled = enabledForLayer(layerName);
    if (layerClusteringEnabled && layerExists && layerEnabled) {
      MainMap.setMapLayerByLayerName(layerName, updateClusteringLayer(map, layer));
    }
  };

  var updateClusteringLayer = function (map, layer) {
    var markers = layer.getLayers();
    if (layer) {
      map.removeLayer(layer);
    }
    if (clusteringIsEnabled()) {
      layer = createClusterGroup(markers).addTo(map);
    } else {
      layer = L.featureGroup(markers).addTo(map);
    }
    return layer;
  };

  var createClusterGroup = function (layers) {
    return L.markerClusterGroup({
      showCoverageOnHover: false,
      maxClusterRadius: getClusterRadius,
      chunkedLoading: true,
    }).addLayers(layers);
  };

  var setState = function (layer, isEnabled = null) {
    if (isEnabled === null) {
      isEnabled = clusteringIsEnabled();
    }

    if (layer !== null) {
      const tooManyFeatures = isLayerBeyondUnclusteringFeaturesLimit(layer);
      if (tooManyFeatures) {
        isEnabled = true;
      }
      disableCheckboxForLayer(layer, tooManyFeatures);
    }
    setCheckboxes(isEnabled, layer);

    setClusteringEnabled(isEnabled);
    Tree.set("clustered", { layer });
    update(layer);
  };

  var isLayerBeyondUnclusteringFeaturesLimit = function (layerName) {
    const layer = MainMap.getMapLayerByLayerName(layerName);
    const layerExists = MainMap.layerExists(layer);
    const layerEnabled = Tree.get("layers", layerName, "isEnabled");

    if (layer === null || !layerExists || !layerEnabled) {
      return false;
    }
    if (layer.getLayers().length === 0) {
      return false;
    }

    var layers;
    if (layer.getLayers()[0].feature !== undefined) {
      layers = layer.getLayers();
    } else {
      layers = layer.getLayers()[0].getLayers();
    }

    return layers.length > maxFeaturesToUncluster;
  };

  var disableCheckboxForLayer = function (layer, toggle = true) {
    const $toDisable = getCheckboxesByLayer(layer).$active;
    Misc.toggleDisabled($toDisable, toggle);
  };

  var setCheckboxes = function (isEnabled, layer = null) {
    if (isEnabled) {
      const layerCheckboxes = getCheckboxesByLayer(layer);

      layerCheckboxes.$active.prop("checked", true);
      layerCheckboxes.$inactive.prop("checked", false);
    } else {
      const $checkboxes = $(".cluster input").not(`[name="super-cluster"]`);

      $checkboxes.prop("checked", false);
    }
  };

  var getCheckboxesByLayer = function (layer) {
    const $checkboxes = $(".cluster input");
    const $active = $checkboxes.filter(`[data-layer='${layer}']`);
    const $inactive = $checkboxes.not($active).not(`[name="super-cluster"]`);

    return { $active, $inactive };
  };

  var setEnabledCheckbox = function (layer, anyEnabled = true) {
    const $checkboxes = $(".cluster input");
    const $toEnable = getCheckboxesByLayer(layer).$active;
    setCheckboxes(false);

    Misc.toggleDisabled($checkboxes, true);

    if (anyEnabled) {
      if (isLayerBeyondUnclusteringFeaturesLimit(layer)) {
        Misc.toggleDisabled($toEnable, true);
        $toEnable.prop("checked", true);
      } else {
        Misc.toggleDisabled($toEnable, false);
        $toEnable.prop("checked", clusteringIsEnabled());
      }
    }
  };

  var setSuperClusterEnabledCheckbox = function (layer, isEnabled) {
    var $superClusterCheckbox = $(`input[name="super-cluster"][data-layer="${layer}"]`);

    if ($superClusterCheckbox.length === 0) {
      $superClusterCheckbox = $(`input[name="cluster"][data-layer="${layer}"]`);
    }

    Misc.toggleDisabled($superClusterCheckbox, !isEnabled);
  };

  var disableClustering = function () {
    const layer = Tree.get("clustered", "layer");
    setState(layer, false);
  };

  var clusteringIsEnabled = function () {
    if (!sessionClusteringIsSet()) {
      return true;
    }

    return sessionStorage.getItem("clusteredIsEnabled") === "true";
  };

  var sessionClusteringIsSet = function () {
    return sessionStorage.getItem("clusteredIsEnabled") !== null;
  };

  var setClusteringEnabled = function (isEnabled) {
    sessionStorage.setItem("clusteredIsEnabled", isEnabled);
  };

  var getClusterRadius = function (mapZoom) {
    const iconSize = 34;

    if (mapZoom >= 16) {
      // ~500 ft-
      return iconSize / 2;
    } else if (mapZoom >= 14) {
      // ~2000 ft-
      return iconSize;
    } else if (mapZoom >= 12) {
      // ~1 mi-
      return iconSize * 2;
    } else {
      // ~over 1 mi
      return iconSize * 4;
    }
  };

  var enabledForLayer = function (layerName) {
    const config = LayerFunctions.getLayerConfig(layerName);

    if (config) {
      return config.clusteringEnabled;
    }

    return null;
  };

  var getMaxFeaturesToUncluster = function () {
    return maxFeaturesToUncluster;
  };

  return {
    setState,
    setEnabledCheckbox,
    setSuperClusterEnabledCheckbox,
    disableClustering,
    clusteringIsEnabled,
    enabledForLayer,
    getMaxFeaturesToUncluster,
    createClusterGroup,
  };
};

module.exports = Clustering();

const MainMap = require("./mainMap");
const Tree = require("../tree");
const Misc = require("../misc");
const LayerFunctions = require("../layerFunctions");

require("leaflet.markercluster");
