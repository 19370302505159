"use strict";

const BulkSelectMap = function () {
  var handlers = {
    loadLayerHandler: null,
    selectAllHandler: null,
  };

  var loadSelectAllListener = function () {
    var $selectAllButton = $("#modalMap .select-all-button");
    var $clearAllButton = $("#modalMap .clear-all-button");

    if (Tree.get("readOnlyModalMap")) {
      $selectAllButton.hide();
      $clearAllButton.hide();
    } else {
      $selectAllButton.show();
      $clearAllButton.show();
    }

    $selectAllButton.off("click");
    $selectAllButton.on("click", handleSelectAll);
    $clearAllButton.off("click");
    $clearAllButton.on("click", handleClearAll);
  };

  var handleSelectAll = function () {
    BulkSelectTable.resetSavedSelectedId();
    handlers.selectAllHandler(true);
  };

  var handleClearAll = function () {
    BulkSelectTable.resetSavedSelectedId();
    handlers.selectAllHandler(false);
  };

  var resetBulkSelectMapHandlers = function () {
    handlers = {
      loadLayerHandler: null,
      selectAllHandler: null,
    };
    BulkSelectTable.configureBulkSelectTableHandlers(handlers);
  };

  var configureBulkSelectMapHandlers = function (newHandlers) {
    handlers = {
      loadLayerHandler: newHandlers.loadLayerHandler,
      selectAllHandler: newHandlers.selectAllHandler,
    };
    BulkSelectTable.configureBulkSelectTableHandlers(newHandlers);
  };

  var showBulkSelectMap = function () {
    const BulkSelectPage = require("./bulkSelectPage");
    const getBulkSelectLayer = BulkSelectPage.getPageConfig("getBulkSelectLayer");
    const layerGeomType = BulkSelectPage.getPageConfig("layerGeomType");
    const modalBasemap = BulkSelectPage.getPageConfig("modalBasemap");
    const polyline = BulkSelectPage.getPageConfig("polyline", true);

    handleBulkSelectModalMapDisplay(modalBasemap);

    if (handlers.loadLayerHandler) {
      handlers.loadLayerHandler();
    } else {
      console.error("Error: loadLayerHandler is not defined in bulk select pageConfig");
    }
    BulkSelectionDraw.init(ModalMap.getMap(), getBulkSelectLayer, layerGeomType, polyline);

    if (!Tree.get("readOnlyModalMap")) {
      $(".polygon-select").show();
      BulkSelectionDraw.show();
    }

    loadSelectAllListener();
    showBulkSelectTable();
  };

  var handleBulkSelectModalMapDisplay = function (modalBasemap) {
    if (!ModalMap.getMap()) {
      ModalMap.showModalMap();
    } else {
      ModalMap.updateModalMapDisplay();
    }
    $("#modalMap").show();
    ModalMap.showModalMapLegendGroup();
    ModalMap.invalidateModalMapSize();

    BasemapFunctions.setBasemap(modalBasemap, "modal");
  };

  var showBulkSelectTable = function () {
    BulkSelectTable.init();
    $(".modal-map-floating-table").hide();
    $("#floating-bulk-select-table").show();
  };

  var hideInstructionMessage = function () {
    ModalMap.hideLocationMessage();
  };

  return {
    resetBulkSelectMapHandlers,
    configureBulkSelectMapHandlers,
    showBulkSelectMap,
    hideInstructionMessage,
  };
};

module.exports = BulkSelectMap();

const BasemapFunctions = require("../mapFunctions/basemapFunctions");
const BulkSelectionDraw = require("../mapFunctions/bulkSelectionDraw");
const BulkSelectTable = require("./bulkSelectTable");
const ModalMap = require("../mapFunctions/modalMap");
const Tree = require("../tree");
