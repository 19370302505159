"use strict";

var TableDataFunctions = function () {
  var organizeCatchments = function (catchments) {
    var sortDescending, dataSort;

    sortDescending = Tree.get("filters", "descend");
    dataSort = Tree.get("filters", "dataSort");

    if (catchments.length === 0) {
      return catchments;
    }

    if (isFlatCatchmentsList(dataSort)) {
      sortByDataSort(catchments, dataSort, sortDescending);
      return catchments;
    }

    if (areCatchmentsGrouped(dataSort)) {
      var groupedCatchments = groupCatchmentsAndAddGroupStats(dataSort, catchments);
      sortGroupedCatchments(groupedCatchments, sortDescending);
      return groupedCatchments;
    }
  };

  var sortByDataSort = function (catchments, dataSort, descend) {
    const dataSortToProperty = MapConstants.dataSortToProperty;
    const propertyName = dataSortToProperty[dataSort];
    niceSort(catchments, propertyName, descend);
  };

  var isFlatCatchmentsList = function (dataSort) {
    var dataSortArray = Object.keys(MapConstants.dataSortToProperty);
    return dataSortArray.includes(dataSort);
  };

  var sortGroupedCatchments = function (groupedCatchments, sortDescending) {
    groupedCatchments.forEach(function (group) {
      niceSort(group.catchments, "catchid", sortDescending);
    });
  };

  var groupCatchmentsAndAddGroupStats = function (dataSort, catchments) {
    var groupingProperty = getGroupingProperty(dataSort);
    const statsBySpatialGroup = getSpatialGroupsStatsForGroupingProperty(groupingProperty);
    const groupedCatchments = CleanData.groupByProp(
      catchments,
      groupingProperty,
      "catchments",
      true,
    );
    groupedCatchments.forEach((group) => {
      const id = group[groupingProperty];
      group.expanded = Table.isGroupExpanded(id);
      group.selected = Table.isGroupSelected(id);
      if (statsBySpatialGroup.size > 0) {
        const spatialGroupStats = statsBySpatialGroup.get(id);
        if (spatialGroupStats) {
          group.acres = spatialGroupStats.acres;
          group.plu_acreage = spatialGroupStats.plu_acreage;
          group.plu_treated = spatialGroupStats.plu_treated;
          group.plu_untreated = spatialGroupStats.plu_untreated;
          group.plu_perc = spatialGroupStats.plu_perc;
        }
      }
    });
    return groupedCatchments;
  };

  var getSpatialGroupsStatsForGroupingProperty = function (groupingProperty) {
    const mapId = Tree.get("mapId");
    const spatialGroupsStatsMap = new Map([
      ["drains_to_rw", () => Tree.get("spatialGroups", mapId, "receivingWaters")],
      ["drains_to_c", () => Tree.get("spatialGroups", mapId, "urbanDrainages")],
    ]);
    if (spatialGroupsStatsMap.has(groupingProperty)) {
      const spatialGroupsData = spatialGroupsStatsMap.get(groupingProperty)();
      const mapEntries = spatialGroupsData.map((spatialGroupData) => [
        spatialGroupData.id,
        spatialGroupData,
      ]);
      return new Map(mapEntries);
    } else {
      console.error(`Could not find spatial groups for grouping property ${groupingProperty}`);
      return new Map();
    }
  };

  var areCatchmentsGrouped = function (dataSort) {
    return dataSort === "rw" || dataSort === "ud";
  };

  var niceSort = function (items, property, descending) {
    items.sort(function (a, b) {
      var aProp = a[property];
      var bProp = b[property];
      var noAProp = (!aProp && aProp !== 0) || aProp === "—";
      var noBProp = (!bProp && bProp !== 0) || bProp === "—";
      var descendingMultiplier = getSortMultiplier(descending);

      if (noBProp && noAProp) {
        return 0;
      } else if (noAProp) {
        return descendingMultiplier * -1;
      } else if (noBProp) {
        return descendingMultiplier * 1;
      }

      var sorter = getSorter(bProp, aProp);
      return sorter(aProp, bProp, descending);
    });
  };

  var getSorter = function (bProp, aProp) {
    var sorter = null;
    if (typeof bProp === "boolean" || typeof aProp === "boolean") {
      sorter = booleanSorter;
    } else if (
      typeof aProp === "number" ||
      typeof bProp === "number" ||
      isNumberString(aProp) ||
      isNumberString(bProp)
    ) {
      sorter = numberSorter;
    } else {
      sorter = stringSorter;
    }
    return sorter;
  };

  var isNumberString = function (value) {
    return typeof value === "string" && !isNaN(value);
  };

  var booleanSorter = function (bool1, bool2, descending) {
    var descendingMultiplier = getSortMultiplier(descending);
    var result = bool1 === bool2 ? 0 : bool1 ? 1 : -1;
    return descendingMultiplier * result;
  };

  var numberSorter = function (num1, num2, descending) {
    var descendingMultiplier = getSortMultiplier(descending);
    var result = num2 - num1;
    return descendingMultiplier * result;
  };

  var stringSorter = function (str1, str2, descending) {
    str1 = convertUnderscoresHyphensToWhitespace(str1);
    str2 = convertUnderscoresHyphensToWhitespace(str2);
    var descendingMultiplier = getSortMultiplier(descending);
    return (
      descendingMultiplier *
      str1.localeCompare(str2, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  };

  var convertUnderscoresHyphensToWhitespace = function (str) {
    return str.replace(/_|\/|-/g, " ");
  };

  var getSortMultiplier = function (descending) {
    if (descending) {
      return -1;
    } else {
      return 1;
    }
  };

  var getGroupingProperty = function (dataSort) {
    var sortProperty;
    if (dataSort === "rw") {
      sortProperty = "drains_to_rw";
    } else if (dataSort === "ud") {
      sortProperty = "drains_to_c";
    }
    return sortProperty;
  };

  var addSelectedToCatchments = function (catchments) {
    catchments.forEach((catchment) => {
      catchment.selected = Table.isCatchmentSelected(catchment.catchid);
    });
  };

  var isFlatData = function (dataSort) {
    const notFlatData = ["ud", "rw"];
    return !notFlatData.includes(dataSort);
  };

  var addTableDataByDataView = function (organizedCatchments, catchments, allMs4Stats) {
    var dataView = Tree.get("dataView");
    var dataSort = Tree.get("filters", "dataSort");
    var mapId = Tree.get("mapId");

    if (DataViewFunctions.isTelrDataView(dataView)) {
      const tableDataColumnsByLayer = LayerFunctions.getCurrentLayerProperty("tableDataColumns");
      if (tableDataColumnsByLayer && tableDataColumnsByLayer.includes("percentImp")) {
        addPercentImpTotals(dataSort, organizedCatchments);
        addAllMs4PercentImpTotals(catchments, allMs4Stats);
      } else if (dataView === "runoffView" || dataView === "particulatesView") {
        addSwtelrTotals(dataSort, organizedCatchments);
        addAllMs4SwtelrTotals(catchments, allMs4Stats);
      } else if (dataView === "runoffRatioView") {
        addRunoffRatioTotals(dataSort, organizedCatchments);
        addAllMs4RunoffRatioTotals(catchments, allMs4Stats);
      }
    } else if (dataView === "sbmpView") {
      const sbmpData = Tree.get(["layers", mapId, "sbmpProgress", "data"]);
      addDataCounts(dataSort, organizedCatchments, sbmpData, allMs4Stats);
      addBmpFcsAreaTotals(
        dataSort,
        organizedCatchments,
        sbmpData,
        allMs4Stats,
        "imperviousAreaAcres",
        "dataImpArea",
      );
    } else if (dataView === "trashView") {
      normalizeTrashTotals(dataSort, organizedCatchments, mapId);
      addAllMs4TrashReductions(allMs4Stats, mapId);
    } else if (dataView === "constructionView") {
      const projectData = Tree.get(["layers", mapId, "constructionProjectProgress", "data"]);
      addDataCounts(dataSort, organizedCatchments, projectData, allMs4Stats);
    } else if (dataView === "lidView") {
      const projectData = Tree.get(["layers", mapId, "lidProjectProgress", "data"]);
      addDataCounts(dataSort, organizedCatchments, projectData, allMs4Stats);
    } else if (dataView === "muniCatchBasinView") {
      let projectData;
      if (FeatureFlag.enabled("annual-report-muni-catch-basin-tko")) {
        projectData = Tree.get(["layers", mapId, "muniCatchBasinReport"]);
      } else {
        projectData = Tree.get(["layers", mapId, "muniCatchBasinProgress", "data"]);
      }

      addDataCounts(dataSort, organizedCatchments, projectData, allMs4Stats);
    } else if (dataView === "iddeView") {
      const projectData = Tree.get(["layers", mapId, "incidentProgress", "data"]);
      addDataCounts(dataSort, organizedCatchments, projectData, allMs4Stats);
    } else if (dataView === "outfallView") {
      const projectData = Tree.get(["layers", mapId, "outfallProgress", "data"]);
      addDataCounts(dataSort, organizedCatchments, projectData, allMs4Stats);
    } else if (dataView === "fcsView") {
      const fcsData = Tree.get(["layers", mapId, "fcsProgress", "data"]);
      addDataCounts(dataSort, organizedCatchments, fcsData, allMs4Stats);
      addBmpFcsAreaTotals(
        dataSort,
        organizedCatchments,
        fcsData,
        allMs4Stats,
        "pluTreatedAcres",
        "dataPluArea",
      );
    } else if (dataView === "watershedView") {
      // placeholder for watershedView
    } else if (dataView === "publicView") {
      // placeholder for publicView
    } else if (dataView === "assetManagementView") {
      // placeholder for assetManagementView
    } else if (dataView === "indCommView") {
      // placeholder for indCommView
    } else if (dataView === "muniFacilityView") {
      // placeholder for muniFacilityView
    } else if (dataView === "muniBmpView") {
      // placeholder for muniBmpView
    } else if (dataView === "streetSweepingView") {
      // placeholder for streetSweepingView
    } else {
      console.error(`Unknown dataView ${dataView}`);
    }
  };

  var eachCatchment = function (dataSort, organizedCatchment, callback) {
    if (isFlatData(dataSort)) {
      callback(organizedCatchment);
      return;
    }

    for (const catchment of organizedCatchment.catchments) {
      callback(catchment);
    }
  };

  var addSwtelrTotals = function (dataSort, organizedCatchments) {
    var isBaseline = LayerDropdown.isBaseline();
    var volumeProp = isBaseline ? "baseline" : "raw";
    var rateProp = isBaseline ? "baselineNorm" : "normalized";

    organizedCatchments.forEach((catchmentGroup) => {
      let totalReduction = 0;
      let baselineTotal = 0;
      let totalVolume = 0;
      let totalAcres = 0;
      let totalVolumeCurrent = 0;
      let totalVolumeBaseline = 0;

      eachCatchment(dataSort, catchmentGroup, function (catchmentGroup) {
        totalReduction += catchmentGroup.reduction;
        baselineTotal += parseFloat(catchmentGroup.baseline);
        // For opportunity map
        catchmentGroup.volume = catchmentGroup[volumeProp];
        catchmentGroup.rate = catchmentGroup[rateProp];
        totalVolume += parseFloat(catchmentGroup.volume);
        totalAcres += parseFloat(catchmentGroup.acres);
        totalVolumeCurrent += parseFloat(catchmentGroup.raw);
        totalVolumeBaseline += parseFloat(catchmentGroup.baseline);
      });

      catchmentGroup.totalReduction = totalReduction;
      catchmentGroup.baselineTotal = baselineTotal;
      const calculatedPerReduction = totalReduction / baselineTotal;
      catchmentGroup.totalPerReduction = calculatedPerReduction ? calculatedPerReduction : 0;

      catchmentGroup.totalVolume = totalVolume;
      catchmentGroup.totalAcres = totalAcres;
      catchmentGroup.totalRate = totalVolume / totalAcres;
      catchmentGroup.totalVolumeCurrent = totalVolumeCurrent;
      catchmentGroup.totalRateCurrent = totalVolumeCurrent / totalAcres;
      catchmentGroup.totalVolumeBaseline = totalVolumeBaseline;
      catchmentGroup.totalRateBaseline = totalVolumeBaseline / totalAcres;
    });
  };

  var addAllMs4SwtelrTotals = function (catchments, allMs4Stats) {
    var isBaseline = LayerDropdown.isBaseline();
    var volumeProp = isBaseline ? "baseline" : "raw";

    allMs4Stats.totalReduction = 0;
    allMs4Stats.totalBaseline = 0;
    allMs4Stats.totalVolume = 0;
    allMs4Stats.totalAcres = 0;

    catchments.forEach((catchment) => {
      allMs4Stats.totalReduction += parseFloat(catchment.reduction);
      allMs4Stats.totalBaseline += parseFloat(catchment.baseline);
      allMs4Stats.totalVolume += parseFloat(catchment[volumeProp]);
      allMs4Stats.totalAcres += parseFloat(catchment.acres);
    });

    var calculatedPerReduction = allMs4Stats.totalReduction / allMs4Stats.totalBaseline;
    allMs4Stats.totalPerReduction = calculatedPerReduction ? calculatedPerReduction : 0;
    allMs4Stats.totalRate = allMs4Stats.totalVolume / allMs4Stats.totalAcres;
  };

  var addRunoffRatioTotals = function (dataSort, organizedCatchments) {
    var isBaseline = LayerDropdown.isBaseline();
    var volumeProp = isBaseline ? "baseline" : "raw";
    var runoffRatioLoadProp = isBaseline ? "runoffRatioLoadBaseline" : "runoffRatioLoad";

    organizedCatchments.forEach((catchmentGroup) => {
      let totalConnectedAcres = 0;
      let totalRunoffRatioLoadReduction = 0;
      let totalRunoffRatioLoad = 0;
      let totalAcres = 0;

      eachCatchment(dataSort, catchmentGroup, function (catchmentGroup) {
        catchmentGroup.volume = catchmentGroup[volumeProp];
        totalAcres += parseFloat(catchmentGroup.acres);
        totalConnectedAcres +=
          parseFloat(catchmentGroup.acres) * parseFloat(catchmentGroup.connect);
        totalRunoffRatioLoadReduction += parseFloat(catchmentGroup.runoffRatioLoadReduction);
        totalRunoffRatioLoad += parseFloat(catchmentGroup[runoffRatioLoadProp]);
      });
      var totalAdjustedRainfall = getTotalAdjustedRainfall(
        catchmentGroup.catchments,
        totalConnectedAcres,
      );

      catchmentGroup.totalAcres = totalAcres;
      catchmentGroup.totalReduction =
        totalRunoffRatioLoadReduction / ((totalAdjustedRainfall / 12) * totalConnectedAcres);
      catchmentGroup.totalVolume =
        totalRunoffRatioLoad / ((totalAdjustedRainfall / 12) * totalConnectedAcres);
    });
  };

  var getTotalAdjustedRainfall = function (catchments, totalConnectedAcres) {
    if (catchments && catchments.length) {
      var totalAdjustedRainfall = 0;

      catchments.forEach((catchment) => {
        const adjustedRainfall =
          (parseFloat(catchment.acres) / totalConnectedAcres) * parseFloat(catchment.rain);
        totalAdjustedRainfall += adjustedRainfall;
      });
      return totalAdjustedRainfall;
    }
    return 0;
  };

  var addAllMs4RunoffRatioTotals = function (catchments, allMs4Stats) {
    var isBaseline = LayerDropdown.isBaseline();
    var runoffRatioLoadProp = isBaseline ? "runoffRatioLoadBaseline" : "runoffRatioLoad";

    var totalAcres = 0;
    var totalConnectedAcres = 0;
    var totalRunoffRatioLoadReduction = 0;
    var totalRunoffRatioLoad = 0;

    catchments.forEach((catchment) => {
      totalAcres += parseFloat(catchment.acres);
      totalConnectedAcres += parseFloat(catchment.acres) * parseFloat(catchment.connect);
      totalRunoffRatioLoadReduction += parseFloat(catchment.runoffRatioLoadReduction);
      totalRunoffRatioLoad += parseFloat(catchment[runoffRatioLoadProp]);
    });

    var totalAdjustedRainfall = getTotalAdjustedRainfall(catchments, totalConnectedAcres);

    allMs4Stats.totalAcres = totalAcres;
    allMs4Stats.totalReduction =
      totalRunoffRatioLoadReduction / ((totalAdjustedRainfall / 12) * totalConnectedAcres);
    allMs4Stats.totalVolume =
      totalRunoffRatioLoad / ((totalAdjustedRainfall / 12) * totalConnectedAcres);
  };

  var addPercentImpTotals = function (dataSort, organizedCatchments) {
    organizedCatchments.forEach((catchmentGroup) => {
      let totalImpAcres = 0;
      let totalAcres = 0;

      eachCatchment(dataSort, catchmentGroup, function (catchmentGroup) {
        totalImpAcres += parseFloat(catchmentGroup.imp) * parseFloat(catchmentGroup.acres);
        totalAcres += parseFloat(catchmentGroup.acres);
      });
      const calculatedTotalImp = totalImpAcres / totalAcres;
      catchmentGroup.totalPercentImp = calculatedTotalImp ? calculatedTotalImp : 0;
      catchmentGroup.totalAcres = totalAcres;
    });
  };

  var addAllMs4PercentImpTotals = function (catchments, allMs4Stats) {
    allMs4Stats.totalImpAcres = 0;
    allMs4Stats.totalAcres = 0;

    catchments.forEach((catchment) => {
      allMs4Stats.totalImpAcres += parseFloat(catchment.imp) * parseFloat(catchment.acres);
      allMs4Stats.totalAcres += parseFloat(catchment.acres);
    });

    var calculatedTotalImp = allMs4Stats.totalImpAcres / allMs4Stats.totalAcres;
    allMs4Stats.totalPercentImp = calculatedTotalImp ? calculatedTotalImp : 0;
  };

  var groupDataCountsByCatchName = function (data) {
    var map = {};
    for (const datum of data) {
      const catchName = datum.catchment.catchid;
      if (!(catchName in map)) {
        map[catchName] = 0;
      }
      map[catchName]++;
    }
    return map;
  };

  var addDataCounts = function (dataSort, organizedCatchments, data, allMs4Stats) {
    if (!data || data.length === undefined) {
      return;
    }

    var dataCountByCatchName = groupDataCountsByCatchName(data);

    organizedCatchments?.forEach((catchmentGroup) => {
      let totalCountInGroup = 0;

      eachCatchment(dataSort, catchmentGroup, function (catchment) {
        const countInCatch = dataCountByCatchName[catchment.catchid];
        if (!isNaN(countInCatch)) {
          catchment.dataCount = countInCatch;
          totalCountInGroup += countInCatch;
        }
      });
      catchmentGroup.totalDataCount = totalCountInGroup;
    });
    allMs4Stats.totalDataCount = data.length;
  };

  var addBmpFcsAreaTotals = function (
    dataSort,
    organizedCatchments,
    bmpFcsData,
    allMs4Stats,
    areaProperty,
    dataAreaKey,
  ) {
    if (!bmpFcsData || bmpFcsData.length === undefined) {
      return;
    }

    var totalDataAreaKey = "total" + dataAreaKey.charAt(0).toUpperCase() + dataAreaKey.slice(1);
    var areaByCatchName = groupAreaByCatchName(bmpFcsData, areaProperty);

    organizedCatchments.forEach((catchmentGroup) => {
      let totalAreaInGroup = 0;

      eachCatchment(dataSort, catchmentGroup, function (catchment) {
        const areaInCatch = areaByCatchName[catchment.catchid];
        if (!isNaN(areaInCatch)) {
          catchment[dataAreaKey] = areaInCatch;
          totalAreaInGroup += areaInCatch;
        } else {
          catchment[dataAreaKey] = 0;
        }
      });
      catchmentGroup[totalDataAreaKey] = totalAreaInGroup;
    });
    allMs4Stats[totalDataAreaKey] = getTotalAreaForAllMs4(bmpFcsData, areaProperty);
  };

  var groupAreaByCatchName = function (bmpFcsData, areaProperty) {
    var map = {};
    for (const bmp of bmpFcsData) {
      const catchName = bmp.catchment.catchid;
      if (!(catchName in map)) {
        map[catchName] = 0;
      }
      const area = Number(bmp[areaProperty]);
      if (!isNaN(area)) {
        map[catchName] += area;
      }
    }
    return map;
  };

  var getTotalAreaForAllMs4 = function (data, areaProperty) {
    var totalArea = 0;
    data.forEach((bmp) => {
      const area = Number(bmp[areaProperty]);
      if (!isNaN(area)) totalArea += area;
    });
    return totalArea;
  };

  var normalizeTrashTotals = function (dataSort, organizedCatchments, mapId) {
    const totalReductionByMap = getTrashReductionByMap(mapId);
    const totalPerReductionByMap = getTrashPerReductionByMap(mapId);

    organizedCatchments.forEach((catchmentGroup) => {
      eachCatchment(dataSort, catchmentGroup, function (catchmentGroup) {
        catchmentGroup.reduction = catchmentGroup[totalReductionByMap];
        catchmentGroup.perReduction = catchmentGroup[totalPerReductionByMap];
      });

      catchmentGroup.totalReduction = catchmentGroup[totalReductionByMap];
      catchmentGroup.totalPerReduction = catchmentGroup[totalPerReductionByMap];
    });
  };

  var addAllMs4TrashReductions = function (allMs4Stats, mapId) {
    const totalReductionByMap = getTrashReductionByMap(mapId);
    const totalPerReductionByMap = getTrashPerReductionByMap(mapId);

    allMs4Stats.totalReduction = allMs4Stats[totalReductionByMap];
    allMs4Stats.totalPerReduction = allMs4Stats[totalPerReductionByMap];
  };

  var getTrashReductionByMap = function (mapId) {
    const totalReductionByMap = { inputs: "acres", report: "plu_acreage", plan: "acres" };
    return totalReductionByMap[mapId];
  };

  var getTrashPerReductionByMap = function (mapId) {
    const totalPerReductionByMap = {
      inputs: "plu_acreage",
      report: "plu_perc",
      plan: "plu_untreated",
    };
    return totalPerReductionByMap[mapId];
  };

  return {
    niceSort,
    organizeCatchments,
    getGroupingProperty,
    addSelectedToCatchments,
    isFlatCatchmentsList,
    getSorter,
    booleanSorter,
    numberSorter,
    stringSorter,
    isFlatData,
    addTableDataByDataView,
    addSwtelrTotals,
    normalizeTrashTotals,
    addAllMs4TrashReductions,
    addPercentImpTotals,
    addAllMs4PercentImpTotals,
    groupDataCountsByCatchName,
    addBmpFcsAreaTotals,
    addDataCounts,
  };
};

module.exports = TableDataFunctions();

const Tree = require("../../tree");
const CleanData = require("./cleanData");
const MapConstants = require("./mapConstants");
const Table = require("./table");
const LayerDropdown = require("./layerDropdown");
const DataViewFunctions = require("../dataViewFunctions");
const LayerFunctions = require("./layerFunctions");
const FeatureFlag = require("../../settings/featureFlag");
