"use strict";

var PageFunctions = function () {
  var goToPage = function (pageName, dataView) {
    PageGroupFunctions.goToPageGroup(pageName);
    Tabs.loadTab(pageName, dataView);
  };

  var getPageConfigByPageName = function (pageName) {
    const config = PageConfig.get()[pageName];

    if (config) {
      return config;
    }
    throw new Error(`No configuration for page ${pageName}.`);
  };

  var getAllPageConfigs = function () {
    return PageConfig.get();
  };

  var getCurrentPageProperty = function (property) {
    const pageConfig = getPageConfigByPageName(Tree.get("activeTab"));

    return pageConfig[property];
  };

  var isCurrentPageSet = function () {
    return Tree.get("activeTab") !== null;
  };

  var pageHasSupportedReport = function (pageConfig) {
    const AnnualReports = require("./annualReports");

    if (Progeny.activeHasProgeny()) {
      return true;
    }

    for (const dataView of pageConfig.enabledDataViews) {
      const dataName = AnnualReports.mapDataViewToDataName(dataView);

      if (AnnualReports.getLatestMs4Report(dataName)) {
        return true;
      }
    }
    return false;
  };

  var getCurrentPage = function () {
    return $(getCurrentPageProperty("pageSelector"));
  };

  return {
    goToPage,
    getPageConfigByPageName,
    getAllPageConfigs,
    getCurrentPageProperty,
    isCurrentPageSet,
    pageHasSupportedReport,
    getCurrentPage,
  };
};

module.exports = PageFunctions();

const Progeny = require("../login/progeny");
const PageConfig = require("./config/pageConfig");
const Tree = require("../tree");
const PageGroupFunctions = require("./pageGroupFunctions");
const Tabs = require("./tabs");
