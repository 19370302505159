"use strict";

const ScenarioDropdown = function () {
  var $dropdownContainer;

  var isDropdownEnabled = function () {
    const scenarioDropdown = DataViewFunctions.getCurrentDataViewProperty("scenarioDropdown");
    return !!scenarioDropdown;
  };

  var init = function () {
    $dropdownContainer = $("#scenario-dropdown-container");
    handleScenarioDropdownDisplay();
    populateDropdown();
    loadScenarioDropdownListeners();
  };

  var loadScenarioDropdownListeners = function () {
    $dropdownContainer.off();
    $dropdownContainer.on("click", ".dropdown-menu li", dropdownItemClick);
  };

  var dropdownItemClick = function () {
    if ($(this).hasClass("disabled")) return;
    var dataScenario = $(this).data("scenario");
    setDataScenario(dataScenario);

    var openPopups = $(".leaflet-popup-close-button");
    if (openPopups.length) {
      openPopups[0].click();
    }
  };

  var setDataScenario = function (dataSecnario) {
    Tree.set("dataScenario", dataSecnario);

    _setDropdownSelection(dataSecnario);
  };

  var populateDropdown = function () {
    const dropdownEnabled = isDropdownEnabled();

    if (!dropdownEnabled) {
      return;
    }

    const template = "maps/scenarioDropdownMenu.njk";
    const dropdownItems = DataViewFunctions.getCurrentDataViewProperty("scenarioDropdown");
    const selected = Tree.get("dataScenario");

    $dropdownContainer.html(nunjucks.render(template, { dropdownItems }));
    _setDropdownSelection(selected);
  };

  var _setDropdownSelection = function (dataScenario) {
    var $selectedLi = $dropdownContainer.find(`li[data-scenario="${dataScenario}"]`).closest("li");
    var dropdownButtonText = $selectedLi.find("a").text();

    Dropdown.select($selectedLi);
    _setDropdownButtonText(dropdownButtonText);
  };

  var _setDropdownButtonText = function (dropdownButtonText) {
    $dropdownContainer
      .find(".dropdown-btn-text")
      .html(`${dropdownButtonText}`)
      .prop("title", dropdownButtonText);
  };

  var handleScenarioDropdownDisplay = function () {
    var show = isDropdownEnabled();
    $dropdownContainer.toggle(show);
  };

  return {
    init,
  };
};

module.exports = ScenarioDropdown();

const DataViewFunctions = require("../dataViewFunctions");
const Dropdown = require("../general/dropdown");
const Tree = require("../tree");
