"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetReductionBar = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [
      {
        className: "hover-bar",
        getHoverHtml: InsightWidgetReductionBar.prototype.getHoverHtml,
      },
      {
        className: "goal-group",
        getHoverHtml: InsightWidgetReductionBar.prototype.getGoalHoverHtml,
      },
    ];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 2;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetReductionBar.prototype = Object.create(InsightWidget.prototype);
InsightWidgetReductionBar.prototype.constructor = InsightWidgetReductionBar;

InsightWidgetReductionBar.prototype.getHtml = function (insightData) {
  var title = this.widgetConfig.title;

  var html = nunjucks.render("insight/widgets/reductionBar.njk", {
    title,
  });

  return html;
};

InsightWidgetReductionBar.prototype.renderChart = function (insightData, exportModal = false) {
  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }

  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight;

  var margin = defaults.margin,
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom;

  var dataArray = this.widgetConfig.getDataArray(insightData),
    unit = this.widgetConfig.unit;

  var defaultBarColor = "#666666";

  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right - 10)
    .attr("height", height + margin.top + margin.bottom + 10);

  svg
    .append("text")
    .attr("x", 0)
    .attr("y", 10)
    .attr("fill", "#000")
    .attr("font-size", "11px")
    .attr("font-weight", "100")
    .text(`Unit: ${unit}`);

  var chartGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top + 10})`);

  var scaleX = d3
    .scaleBand()
    .domain(dataArray.bars.map((item) => item.label))
    .range([0, width - 20])
    .padding(0.4);

  var axisX = d3
    .axisBottom(scaleX)
    .tickFormat(function (d) {
      return d.label;
    })
    .tickPadding(10)
    .tickSize(0);
  chartGroup
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(10," + height + ")")
    .call(axisX);

  const maxY = dataArray.baselineValue === 0 ? 10 : Math.ceil(dataArray.baselineValue * 1.1);

  var scaleY = d3.scaleLinear().domain([0, maxY]).range([height, 0]);
  var axisY = d3
    .axisLeft(scaleY)
    .tickFormat(function (n) {
      return Misc.formatLargeNumber(n, 1);
    })
    .ticks(5)
    .tickSize(0)
    .tickPadding(10)
    .tickSizeInner(-width);
  chartGroup.append("g").attr("class", "axis").attr("transform", "translate(10,0)").call(axisY);

  var bars = chartGroup
    .selectAll()
    .data(dataArray.bars)
    .enter()
    .append("g")
    .attr("class", function (d) {
      let classes = "bar-group";
      if (d.value !== 0 && d.hoverKey) {
        classes += " hover-bar";
      }
      return classes;
    })
    .attr("data-hover-key", function (d) {
      return d.hoverKey;
    });

  const barWidth = width / dataArray.bars.length - (width / dataArray.bars.length) * 0.6;

  bars
    .append("rect")
    .attr("class", "hover-background-rect")
    .attr("display", function (d) {
      return d.value === 0 ? "none" : "block";
    })
    .attr("x", function (d) {
      return scaleX(d.label) - 5;
    })
    .attr("y", function (d) {
      return 0;
    })
    .attr("width", function (d) {
      return barWidth + 10;
    })
    .attr("height", function (d) {
      return height;
    })
    .attr("fill", "transparent");

  bars
    .append("rect")
    .data(dataArray.bars)
    .attr("display", function (d) {
      return d.value === 0 && d.reduction === 0 ? "none" : "block";
    })
    .attr("x", function (d) {
      return scaleX(d.label);
    })
    .attr("y", function (d) {
      return scaleY(dataArray.baselineValue || 0);
    })
    .attr("width", barWidth)
    .attr("height", function (d) {
      return scaleY(d.value || 0) - scaleY(dataArray.baselineValue || 0);
    })
    .attr("fill", function (d) {
      return d.color;
    });

  bars
    .append("rect")
    .attr("display", function (d) {
      return d.value === 0 && d.reduction === 0 ? "none" : "block";
    })
    .data(dataArray.bars)
    .attr("x", function (d) {
      return scaleX(d.label);
    })
    .attr("y", function (d) {
      return scaleY(d.value || 0);
    })
    .attr("width", barWidth)
    .attr("height", function (d) {
      return height - scaleY(d.value || 0);
    })
    .attr("fill", function (d) {
      return defaultBarColor;
    });

  const tailWidth = 4;
  const lineDistance = 8;
  const arrowWidth = 4;
  const arrowHeight = 6;
  bars
    .append("path")
    .attr("display", function (d) {
      return d.value === 0 && d.reduction === 0 ? "none" : "block";
    })
    .attr("d", function (d) {
      return d3.line()([
        [
          scaleX(d.label) + barWidth + lineDistance - tailWidth,
          scaleY(dataArray.baselineValue || 0),
        ],
        [
          scaleX(d.label) + barWidth + lineDistance + tailWidth,
          scaleY(dataArray.baselineValue || 0),
        ],
      ]);
    })
    .attr("stroke", "black")
    .attr("stroke-width", function (d) {
      if (scaleY(d.value || 0) - scaleY(dataArray.baselineValue || 0) > arrowHeight) {
        return 1;
      } else {
        return 0;
      }
    });
  bars
    .append("path")
    .attr("display", function (d) {
      return d.value === 0 && d.reduction === 0 ? "none" : "block";
    })
    .attr("d", function (d) {
      return d3.line()([
        [scaleX(d.label) + barWidth + lineDistance, scaleY(dataArray.baselineValue || 0)],
        [scaleX(d.label) + barWidth + lineDistance, scaleY(d.value || 0)],
      ]);
    })
    .attr("stroke", "black");
  bars
    .append("path")
    .attr("display", function (d) {
      return d.value === 0 && d.reduction === 0 ? "none" : "block";
    })
    .attr("d", `M 0 0 L -${arrowWidth} -${arrowHeight} L ${arrowWidth} -${arrowHeight} Z`)
    .attr("fill", "black")
    .attr("transform", function (d) {
      return `translate(${scaleX(d.label) + barWidth + lineDistance}, ${scaleY(d.value || 0) + 1})`;
    });
  bars
    .append("text")
    .attr("display", function (d) {
      return d.value === 0 && d.reduction === 0 ? "none" : "block";
    })
    .attr("fill", "black")
    .attr("font-size", "11px")
    .attr("transform", function (d) {
      return `translate(${
        scaleX(d.label) + barWidth + lineDistance + arrowWidth + 3
      }, ${scaleY(d.value || 0) - arrowHeight})`;
    })
    .text(function (d) {
      return InsightWidgetFunctions.formatNumber(d.reduction);
    });

  bars
    .append("text")
    .data(dataArray.bars)
    .attr("class", "bar")
    .attr("text-anchor", "middle")
    .attr("x", function (d) {
      return scaleX(d.label) + barWidth / 2;
    })
    .attr("y", function (d) {
      const scale = scaleY(d.value || 0);
      return inTheBar(d) ? scale + 17 : scale - 7;
    })
    .text(function (d) {
      return !dataArray.baselineValue ? "No data" : Misc.formatLargeNumber(d.value, 1);
    })
    .attr("font-size", function (d) {
      return !dataArray.baselineValue ? "10px" : "12px";
    })
    .attr("fill", function (d) {
      if (inTheBar(d)) {
        const color = defaultBarColor;
        return Misc.isDarkColor(color) ? "white" : "black";
      } else {
        return "black";
      }
    })
    .attr("class", "keep-color");
  bars
    .append("text")
    .data(dataArray.bars)
    .attr("text-anchor", "middle")
    .attr("x", function (d) {
      return scaleX(d.label) + barWidth / 2;
    })
    .attr("y", function (d) {
      return scaleY(0) + 20;
    })
    .text(function (d) {
      return d.label;
    });

  if (dataArray.goal) {
    chartGroup
      .append("path")
      .style("stroke-dasharray", "2, 2")
      .attr("stroke", "#D96F53")
      .attr("d", function (d) {
        return d3.line()([
          [10, scaleY(dataArray.goal)],
          [width, scaleY(dataArray.goal)],
        ]);
      });

    const goalGroup = chartGroup
      .append("g")
      .attr("class", "goal-group")
      .attr("transform", `translate(${width},${scaleY(dataArray.goal) - 14})`);

    goalGroup
      .append("rect")
      .attr("class", "hover-background-rect")
      .attr("x", -25)
      .attr("width", 50)
      .attr("height", 28)
      .attr("fill", "transparent");

    const goalLabel = goalGroup
      .append("text")
      .attr("y", 12)
      .attr("fill", "#D96F53")
      .attr("font-size", "11px")
      .attr("text-anchor", "end")
      .attr("class", "goal-label");

    goalLabel.append("tspan").text("Goal");

    goalLabel
      .append("tspan")
      .attr("x", 0)
      .attr("dy", 12)
      .text(Misc.formatLargeNumber(dataArray.goal, 1));
  }

  function inTheBar(d) {
    const scale = scaleY(d.value || 0),
      scale0 = scaleY(0);
    return scale < scale0 - 25;
  }
};

InsightWidgetReductionBar.prototype.getHoverHtml = function (insightData, $hoverTarget) {
  var hoverData = this.widgetConfig.getHoverData(insightData),
    hoverKey = $hoverTarget.data("hover-key"),
    data = hoverData[hoverKey],
    template = data.template;

  var html = nunjucks.render(template, {
    ...data,
  });

  return html;
};

InsightWidgetReductionBar.prototype.getGoalHoverHtml = function (insightData, $hoverTarget) {
  const unit = this.widgetConfig.unit;
  const template = "insight/widgets/reductionGoalHover.njk";

  var hoverData = this.widgetConfig.getGoalHoverData(insightData);

  var html = nunjucks.render(template, {
    unit,
    ...hoverData,
  });

  return html;
};

module.exports = InsightWidgetReductionBar;

const d3 = require("d3-v6");
const Misc = require("../../misc");
const InsightWidgetFunctions = require("./insightWidgetFunctions");
