"use strict";

var SegmentData = function () {
  /*
    Segments an array using Jenks Natural Breaks.

    Code from:
    https://observablehq.com/@jdev42092/jenks-breaks-using-simple-statistics

    Returns:
    an array of ints that are segmentation devisions [lowerBound, ..., upperBound]
    divisions are above the specified number, so the value 3 is
    part of the range under a division at 3
  */
  var jenks = function (data, segments) {
    const CleanData = require("./mapFunctions/cleanData");
    // sort data in numerical order
    data = CleanData.getSortedArray(data);
    data = CleanData.removeDuplicateEndData(data);

    if (data.length <= segments + 1) {
      return onePerPoint(data, segments);
    }

    // get our basic matrices
    var matrices = jenksMatrices(data, segments),
      // we only need lower class limits here
      lower_class_limits = matrices.lower_class_limits,
      k = data.length - 1,
      kclass = [],
      countNum = segments;

    // the calculation of classes will never include the upper and
    // lower bounds, so we need to explicitly set them
    kclass[segments] = data[data.length - 1];
    kclass[0] = data[0];

    // the lower_class_limits matrix is used as indexes into itself
    // here: the `k` variable is reused in each iteration.
    while (countNum > 1) {
      kclass[countNum - 1] = data[lower_class_limits[k][countNum] - 2];
      k = lower_class_limits[k][countNum] - 1;
      countNum--;
    }

    return kclass;
  };

  var jenksMatrices = function (data, segments) {
    // in the original implementation, these matrices are referred to
    // as `LC` and `OP`
    //
    // * lower_class_limits (LC): optimal lower class limits
    // * variance_combinations (OP): optimal variance combinations for all classes
    var lower_class_limits = [],
      variance_combinations = [],
      // loop counters
      i,
      j,
      // the variance, as computed at each step in the calculation
      variance = 0;

    // Initialize and fill each matrix with zeroes
    for (i = 0; i < data.length + 1; i++) {
      var tmp1 = [],
        tmp2 = [];
      for (j = 0; j < segments + 1; j++) {
        tmp1.push(0);
        tmp2.push(0);
      }
      lower_class_limits.push(tmp1);
      variance_combinations.push(tmp2);
    }

    for (i = 1; i < segments + 1; i++) {
      lower_class_limits[1][i] = 1;
      variance_combinations[1][i] = 0;
      // in the original implementation, 9999999 is used but
      // since Javascript has `Infinity`, we use that.
      for (j = 2; j < data.length + 1; j++) {
        variance_combinations[j][i] = Infinity;
      }
    }

    for (var l = 2; l < data.length + 1; l++) {
      // `SZ` originally. this is the sum of the values seen thus
      // far when calculating variance.
      var sum = 0,
        // `ZSQ` originally. the sum of squares of values seen
        // thus far
        sum_squares = 0,
        // `WT` originally. This is the number of
        w = 0,
        // `IV` originally
        i4 = 0;

      // in several instances, you could say `Math.pow(x, 2)`
      // instead of `x * x`, but this is slower in some browsers
      // introduces an unnecessary concept.
      for (var m = 1; m < l + 1; m++) {
        // `III` originally
        var lower_class_limit = l - m + 1,
          val = data[lower_class_limit - 1];

        // here we're estimating variance for each potential classing
        // of the data, for each potential number of classes. `w`
        // is the number of data points considered so far.
        w++;

        // increase the current sum and sum-of-squares
        sum += val;
        sum_squares += val * val;

        // the variance at this point in the sequence is the difference
        // between the sum of squares and the total x 2, over the number
        // of samples.
        variance = sum_squares - (sum * sum) / w;

        i4 = lower_class_limit - 1;

        if (i4 !== 0) {
          for (j = 2; j < segments + 1; j++) {
            if (variance_combinations[l][j] >= variance + variance_combinations[i4][j - 1]) {
              lower_class_limits[l][j] = lower_class_limit;
              variance_combinations[l][j] = variance + variance_combinations[i4][j - 1];
            }
          }
        }
      }

      lower_class_limits[l][1] = 1;
      variance_combinations[l][1] = variance;
    }

    return {
      lower_class_limits,
      variance_combinations,
    };
  };

  var onePerPoint = function (data) {
    var columnValues = [];

    if (data.length === 1) {
      return [data[0], data[0]];
    }

    for (const value of data) {
      columnValues.push(value);
    }

    return columnValues;
  };

  var quantile = function (uniqueValArray) {
    uniqueValArray = getUniqueValueArray(uniqueValArray);
    var columnValues = [];

    var uniqueCount = uniqueValArray.length;
    if (uniqueCount > 5) {
      for (let i = 1; i < 5; i++) {
        var index = Math.round(i * 0.2 * (uniqueValArray.length + 1)) - 1;
        columnValues.push(uniqueValArray[index]);
      }
    } else {
      //in case there aren't enough values to rep in the legend
      columnValues = onePerPoint(uniqueValArray);
    }
    return columnValues;
  };

  var getUniqueValueArray = function (numberArray) {
    //sort the values from min to max
    numberArray = getSortedArray(numberArray);
    //check how many zero values there are
    var zeroCount = numberArray.filter(function (v) {
      return v === 0;
    }).length;
    //remove zeros from calculation for quantile values
    if (zeroCount > 1) {
      numberArray.splice(0, zeroCount);
    }
    //get array & count of unique values
    var uniqueValArray = numberArray.filter(function (v, i) {
      return i == numberArray.lastIndexOf(v);
    });
    return uniqueValArray;
  };

  var getSortedArray = function (numberArray) {
    var numberArrayCopy = [...numberArray];
    return numberArrayCopy.sort((a, b) => a - b);
  };

  return {
    jenks,
    quantile,
  };
};

module.exports = SegmentData();
