"use strict";

const MegaMenu = function () {
  const init = function () {
    close();
    _loadListeners();
    _render();
  };

  const _loadListeners = function () {
    $("body").off("click", _clickAwayFromMegaMenu).on("click", _clickAwayFromMegaMenu);
    $("#mega-menu-button").off("click", _toggleMegaMenu).on("click", _toggleMegaMenu);
  };

  const _render = function () {
    const html = nunjucks.render("esg/megaMenu.njk");
    $("#mega-menu").html(html);
    _addCurrent();
  };

  const _addCurrent = function () {
    const currentUrl = window.location?.pathname;
    let $selector = $(`#mega-menu a[href="${currentUrl}"]`);
    if ($selector.length) {
      $selector.addClass("current");
    } else {
      const PageController = require("./pageController");
      const config = PageController.getConfig();
      const prevUrl = config?.breadcrumbs?.previousPage?.route;
      if (prevUrl) $selector = $(`#mega-menu a[href="${prevUrl}"]`).addClass("current-parent");
    }
    $selector.closest("div").prev("a").addClass("current-parent");
  };

  const _toggleMegaMenu = function (e) {
    e.preventDefault();
    $("#mega-menu-button").toggleClass("active");
  };

  const _clickAwayFromMegaMenu = function (e) {
    if (!$(e.target).closest(".esg-breadcrumbs").length || $(e.target).is("#mega-menu-container")) {
      close();
    }
  };

  const close = function (e) {
    $("#mega-menu-button").removeClass("active");
  };

  return {
    init,
    close,
    _addCurrent,
  };
};

module.exports = MegaMenu();
