"use strict";

const FormalCommunicationModal = function () {
  const formKey = "formal-communication";
  var $modal;
  var inited = false;
  var props;

  var init = function () {
    $modal = $("#formal-communication-modal");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);

    $modal.off("click", ".preview-pdf-button", generateFormPreview);
    $modal.on("click", ".preview-pdf-button", generateFormPreview);

    $modal.off("click", ".send-formal-communication-button", _sendFormalCommunicationIfConfirmed);
    $modal.on("click", ".send-formal-communication-button", _sendFormalCommunicationIfConfirmed);

    $modal.off("click", ".download-inspection-report", _downloadInspectionReport);
    $modal.on("click", ".download-inspection-report", _downloadInspectionReport);

    $modal.off("2N:FormInput", checkEnableSendButton);
    $modal.on("2N:FormInput", checkEnableSendButton);
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var _downloadInspectionReport = function () {
    const inspectionId = $(this).data("inspectionId");
    ApiCalls.downloadConstructionInspectionReport(inspectionId, null, "inspection");
  };

  var _sendFormalCommunicationIfConfirmed = function () {
    MessageModal.showConfirmWarningModal(
      `Are you sure you want to save? This will save the document to the project's files and remove this task from your To Do List. Click 'Save' to continue or 'Cancel' to return.`,
      sendFormalCommunications,
      "Cancel",
      "Save",
    );
  };

  var checkEnableSendButton = function () {
    const canSendFormalCommunication = hasAllRequiredFields();
    $modal
      .find(".send-formal-communication-button")
      .toggleClass("disabled", !canSendFormalCommunication);
  };

  var renderAndShow = async function (id, relatedId, severity, formalCommunicationType) {
    if (!inited) init();
    const formalCommunication = await ApiCalls.getFormalCommunication(id);
    props = Object.assign(
      { id, relatedId, severity, formalCommunicationType },
      formalCommunication,
    );
    const renderPromise = render();
    initializeFields();
    Analytics.sendScreenView("modal", formKey);
    return renderPromise;
  };

  var render = async function () {
    const html = nunjucks.render("modals/projectInventory/formalCommunicationModal.njk", props);
    $modal.html(html);
    return new Promise((resolve) => {
      $modal.one("shown.bs.modal", resolve);
      $modal.modal("show");
    });
  };

  var initializeFields = function () {
    Form.initializeAndLoadListeners($modal, formKey);
    const today = DateTimePicker.setToNow($modal, "date");
    Form.manuallySetFormDataField("formal-communication", ["date"], today);
  };

  var generateFormPreview = async function () {
    const formValues = getInputFields(false);
    const preview = new PreviewDownloader();
    const pdf = await ApiCalls.formalCommunicationPdfPreview(formValues);
    preview.openBlob(new Blob([pdf], { type: "application/pdf" }));
  };

  var getInputFields = function (clearData) {
    const formData = Form.getDataFromForm("formal-communication", clearData);
    formData.id = props?.id;
    formData.severity = props?.severity;
    formData.relatedId = props?.relatedId;
    return formData;
  };

  var sendFormalCommunications = async function () {
    const formValues = getInputFields(true);
    await ApiCalls.sendFormalCommunications(formValues);
    close();
    ToDoListController.loadTodos();
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
  };

  var hasAllRequiredFields = function () {
    const requiredFields = ["signature", "title", "date"];
    const nonEmptyFields = Object.fromEntries(
      Object.entries(getInputFields(false)).filter(([index, value]) => !!value),
    );
    const keysExisting = Object.keys(nonEmptyFields);
    return requiredFields.every((requiredField) => keysExisting.includes(requiredField));
  };

  return {
    renderAndShow,
    close,
    render,
    _closeModalIfConfirmed,
    hasAllRequiredFields,
  };
};

module.exports = FormalCommunicationModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const DateTimePicker = require("../general/dateTimePicker");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const PreviewDownloader = require("../files/previewDownloader");
const ToDoListController = require("../mapFunctions/toDoListController");
