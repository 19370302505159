"use strict";

var SubmitReportFunctions = function () {
  var getTrashPercentTreated = function (data) {
    if (data) {
      return data.acresBaseline === 0
        ? 0
        : Math.round((data.acresTreated / data.acresBaseline) * 100);
    }
  };

  var getRunoffPercentReduced = function (data) {
    if (data) {
      return Math.round((data.afyReduced / data.afyBaseline) * 100);
    }
  };

  var getParticulatesPercentReduced = function (data) {
    if (data) {
      return Math.round((data.tonsPerYearReduced / data.tonsPerYearBasesline) * 100);
    }
  };

  return {
    getTrashPercentTreated,
    getRunoffPercentReduced,
    getParticulatesPercentReduced,
  };
};

module.exports = SubmitReportFunctions();
