"use strict";

const FactSheetNew = function () {
  let config;
  let $container;
  let data;
  let currentId;
  const searchStrings = new Map();

  var open = async function (id, _config = null, _data = null) {
    if (_config) _init(_config);
    config.onCloseFunction?.();
    currentId = id;
    _setListView();
    if (!(await _getData(id, _data))) {
      close();
      return;
    }
    _setDataSort();
    _show();
    _addMap();
    _loadListeners();
    _handleFilterFlow();
    _rerenderAllTables();
    FactSheetPhotos.init(config, data, $container);
    config.afterOpenFunction?.();
    SideBar.highlightRow(id);
  };

  var reload = function (_data) {
    open(currentId, null, _data);
  };

  var _init = function (_config) {
    config = _config;
    $container = $("#fact-sheet-container");
  };

  var _setListView = function () {
    DataViewController.toggleActiveButton("list");
    Tree.set("navToggleSelection", "list");
  };

  var _setDataSort = function () {
    Tree.set(["factSheet", "previousDataSort"], Tree.get(["table", "dataSort"]));
    if (!Tree.get("factSheet", "open")) Filters.setDataSort(config.dataSort);
  };

  var _addMap = function () {
    const polygon = data[config.polygonColumnName];
    const point = data[config.pointColumnName];
    const icon = config.iconFunction(data);

    if (polygon) {
      MapFunctions.createStaticMapWithPolygon("factsheet-map", { geom: polygon });
    } else if (point) {
      MapFunctions.createStaticMapWithPoint("factsheet-map", { geom: point, icon });
    }
  };

  var _handleFilterFlow = function () {
    Table.renderBackButtonIfFactSheet();
    FilterSummary.disable();
  };

  var _getData = async function (id, _data = null) {
    data = _data || (await config.getDataFunction(id));
    if (data?.status === 404) return false;
    data = {
      ...data,
      isFullWidth: Tree.get("activeTab") === "todo",
      searchStrings: Object.fromEntries(searchStrings.entries()),
    };
    config.prepareDataFunction?.(data);
    return true;
  };

  var _show = function () {
    const scrollPosition = $(".fact-sheet").scrollTop();
    $container.html(nunjucks.render(config.template, data));
    $(".fact-sheet").scrollTop(scrollPosition);

    Tree.set(["factSheet", "open"], true);
    Tree.set(["factSheet", "id"], currentId);
    $("#map-pages").children().removeClass("active");
    $container.addClass("active");
    $(".reporting-timeline").hide();
  };

  var _loadListeners = function () {
    $("#bottomFloatingInputsTable")
      .off("click", ".table-datum", _onClickTableDatum)
      .on("click", ".table-datum", _onClickTableDatum)
      .off("click", ".ms4-return-button")
      .on("click", ".ms4-return-button", close);
    $container
      .off("click", ".back-button", close)
      .on("click", ".back-button", close)
      .off("click", ".toggle-show-more", _toggleShowMore)
      .on("click", ".toggle-show-more", _toggleShowMore)
      .off("input", ".fact-sheet-search-table", _inputSearchTable)
      .on("input", ".fact-sheet-search-table", _inputSearchTable)
      .off("click", "th.sortable", _sortByClickedColumn)
      .on("click", "th.sortable", _sortByClickedColumn)
      .off("click", "#fact-sheet-next", _clickNext)
      .on("click", "#fact-sheet-next", _clickNext)
      .off("click", "#fact-sheet-previous", _clickPrevious)
      .on("click", "#fact-sheet-previous", _clickPrevious);
  };

  var _onClickTableDatum = function () {
    _go($(this).data("id"));
  };

  var _go = function (id) {
    if (!Tree.get(["factSheet", "open"]) || !id) return;
    UrlRoutes.go(config.routeName, { id });
  };

  var closeIfId = function (id) {
    if (id === currentId) close();
  };

  var close = function () {
    if (config) {
      UrlRoutes.navigate(config.closeUrl);
      config.onCloseFunction?.();
    }
    if (!Tree.get(["factSheet", "open"])) return;

    const dataView = Tree.get("dataView");
    if (
      [
        "construction-project-delivery",
        "muni-catch-basin",
        "muni-outfalls",
        "construction-project",
        "indcom-facilities",
        "lid-project",
      ].includes(dataView)
    ) {
      Tree.set("navToggleSelection", "list");
      $(".ms4-return-button").addClass("hidden");
      if (Tree.get("activeTab") !== "todo") {
        FilterSummary.enable();
      }
    }
    Filters.setDataSort(Tree.get(["factSheet", "previousDataSort"]), true, false);
    Filters.toggleFilterButtons(false);
    $("#details-button-container").removeClass("desktop-only");

    if (Tree.get("navToggleSelection") === "list") {
      ListPanel.show(Tree.get("activeTab"), Tree.get("dataView"));
      if (Tree.get("activeTab") === "data") {
        DataList.show();
      }
    }
    $("#details-button-container").removeClass("desktop-only");

    Tree.set(["factSheet", "open"], false);
    $("#fact-sheet-container").removeClass("active");
    if (Tree.get("activeTab") === "todo") {
      $(".reporting-timeline").show();
    }
  };

  var _toggleShowMore = function (e) {
    $(this).closest(".factsheet-table").toggleClass("expanded");
    e.preventDefault();
  };

  var _inputSearchTable = function () {
    _searchTable($(this).data("table-key"));
  };

  var _searchTable = function (tableKey) {
    const search = $(`input[data-table-key="${tableKey}"]`).val() || "";
    searchStrings.set(tableKey, search);
    const searchSelector = `${_tableSelector(tableKey)} tbody tr`;
    FilterDom.filterByText(search, $(searchSelector));
  };

  var _rerenderTable = function (key) {
    const $table = $(_tableSelector(key));
    const template = config.tableTemplates[key];
    const sortedData = { [key]: _getSortedData(key) };
    $table.find("tbody").html(nunjucks.render(template, sortedData));
    _searchTable(key);
  };

  var _tableSelector = function (key) {
    return `.factsheet-table[data-table-key="${key}"]`;
  };

  var _getSortedData = function (tableKey) {
    const sortedData = data[tableKey] || [];
    const sorting = config.dataSorting[tableKey];
    if (Object.keys(config?.sortersDict || {}).includes(sorting.key)) {
      SortUtilities.sortRows(sorting.key, sorting.reversed, sortedData, config.sortersDict);
    }

    return sortedData;
  };

  var _sortByClickedColumn = function () {
    const $this = $(this);
    const $table = $this.closest(".factsheet-table");
    const tableKey = $table.data("tableKey");
    config.dataSorting[tableKey] = {
      key: $this.data("sortKey"),
      reversed:
        config.dataSorting[tableKey]?.key === $this.data("sortKey")
          ? !config.dataSorting[tableKey]?.reversed
          : false,
    };
    _rerenderTable(tableKey);
    TableSort.updateHeaders(config.dataSorting[tableKey], $table);
  };

  var _clickNext = function () {
    _go(SideBar.getNextId());
  };

  var _clickPrevious = function () {
    _go(SideBar.getPreviousId());
  };

  var _rerenderAllTables = function () {
    Object.keys(config?.tableTemplates || {}).forEach((key) => _rerenderTable(key));
  };

  var getId = function () {
    return currentId;
  };

  var getData = function () {
    return data;
  };

  var $getRow = function ($this) {
    return $this.parents(".history-item-tr");
  };

  var addDisplayDates = function (data = []) {
    data.forEach(function (row) {
      if (!row.date) return;
      row.displayDate = DateTime.dateToGroupDisplayDate(DateTime.parseIsoDate(row.date));
    });
  };

  return {
    open,
    close,
    closeIfId,
    getId,
    reload,
    getData,
    $getRow,
    addDisplayDates,
  };
};

module.exports = FactSheetNew();

const DateTime = require("../dateTime");
const SideBar = require("../esg/sideBar");
const FilterSummary = require("../filters/filterSummary");
const DataList = require("../mapFunctions/dataList");
const DataViewController = require("../mapFunctions/dataViewController");
const Filters = require("../mapFunctions/filters");
const ListPanel = require("../mapFunctions/listPanel");
const SortUtilities = require("../general/sortUtilities");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Table = require("../mapFunctions/table");
const UrlRoutes = require("../routes/urlRoutes");
const Tree = require("../tree");
const FilterDom = require("./filterDom");
const TableSort = require("./tableSort");
const FactSheetPhotos = require("./factSheetPhotos");
