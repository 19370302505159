"use strict";

const RoutineMaintenanceFormGeneral = function () {
  var assetAccessibleOnlyTabs;

  var loadListeners = function ($page, data, newAssetAccessibleOnlyTabs, Controller = null) {
    assetAccessibleOnlyTabs = newAssetAccessibleOnlyTabs;

    $page.on("2N:FormInput", `[name="asset-accessible"]`, (e, isAccessible) =>
      toggleAssetAccessible($page, isAccessible),
    );

    if (Controller) {
      $page.on("2N:FormInput", `[name="flow-present"]`, () => flowRadioToggle(Controller));
      $page.on("2N:FormInput", `[name="standing-water-present"]`, () =>
        flowRadioToggle(Controller),
      );
      $page.on("2N:FormInput", "[name='monitoring-location-id']", (e, setMonitoringLocationId) =>
        setMonitoringLocationResult($page, setMonitoringLocationId, data),
      );

      setMonitoringLocationResult($page, data.monitoringLocationId);
    }

    toggleAssetAccessible($page, data.assetAccessible);
  };

  var unloadListeners = function ($page) {
    $page.off("2N:FormInput", `[name="asset-accessible"]`);
    $page.off("2N:FormInput", `[name="flow-present"]`);
    $page.off("2N:FormInput", `[name="standing-water-present"]`);
    $page.off("2N:FormInput", "[name='monitoring-location-id']");
  };

  var toggleAssetAccessible = function ($element, setIsAccessible = null) {
    const $modal = $element.closest(".modal-content");
    let isAccessible = setIsAccessible;

    if (isAccessible === null) {
      isAccessible = $modal.find(`[name="asset-accessible"]:checked`).val() === "true";
    } else {
      $modal.find(`[name="asset-accessible"][value="${isAccessible}"]`).prop("checked", true);
    }

    _toggleAssetAccessibleSections($modal, isAccessible);

    TabbedModal.toggleExclusivelyShowTabs(!isAccessible, "showHardAssets", assetAccessibleOnlyTabs);
  };

  var _toggleAssetAccessibleSections = function ($modal, isAccessible) {
    const hasCheckedNewIssues = $modal.find(`[name$="[issue]"][value="true"]:checked`).length > 0;
    const hasNoAccessIssue = $modal.find(`[data-issue-key="asset-accessible"]`).length > 0;

    $modal
      .find(`[name="asset-accessible"]`)
      .prop("disabled", hasCheckedNewIssues || hasNoAccessIssue);
    $modal.find(`[data-issue-key="asset-accessible"]`).prop("disabled", hasCheckedNewIssues);

    if (hasNoAccessIssue) {
      $modal.find(`[name="access-issue"]`).prop("disabled", true);
    }

    $modal.find(`.asset-accessible-hide-when-accessible`).toggle(!isAccessible);
    $modal.find(`.asset-accessible-show-when-accessible`).toggle(isAccessible);
  };

  var addProps = function (props, data) {
    props.accessIssueOptions = DryWeatherConstants.getAccessIssueOptions();
    props.submergedOptions = DryWeatherConstants.getSubmergedOptions();
    props.precipTypeOptions = OutfallInspectionConstants.getPrecipitationTypeOptions();
  };

  var getTabName = function () {
    return "General Info";
  };

  var flowRadioToggle = function (Controller) {
    const standingWater = Controller.getFormData(["standingWaterPresent"]);
    const flowPresent = Controller.getFormData(["flowPresent"]);
    const showMeasurementsSamples = standingWater === true || flowPresent === true;

    DryWeatherController.toggleFlowTabs(showMeasurementsSamples);
  };

  var setMonitoringLocationResult = function ($page, setMonitoringLocationId, data = {}) {
    const relatedMonitoringLocations = data.asset?.relatedMonitoringLocations;

    setMonitoringLocationId = parseInt(setMonitoringLocationId);

    if (isNaN(setMonitoringLocationId)) {
      return;
    }

    const locationResult =
      relatedMonitoringLocations?.find((location) => location.value === setMonitoringLocationId)
        ?.displayCondition ?? "—";

    $page.find(".monitoring-location-id-result").text(locationResult);
  };

  return {
    loadListeners,
    unloadListeners,
    addProps,
    getTabName,
    toggleAssetAccessible,
    flowRadioToggle,
  };
};

module.exports = RoutineMaintenanceFormGeneral();

const DryWeatherConstants = require("../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
const OutfallInspectionConstants = require("../outfalls/outfallInspectionConstants");
const TabbedModal = require("./tabbedModal");
const DryWeatherController = require("../illicitDischarge/dryWeatherScreening/dryWeatherController");
