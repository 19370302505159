"use strict";

const ProjectInspectionSummary = function () {
  var getSummaryHtml = function (data) {
    return nunjucks.render("modals/projectInspection/templates/summary.njk", getSummaryData(data));
  };

  var getSummaryData = function (data) {
    var summaryData;

    if (ToolSettings.getSetting("construction", "is-findings-workflow")) {
      summaryData = _getFindingsData(data);
    } else {
      summaryData = _getObservationsData(data);
    }

    return {
      ...summaryData,
      projectIdentifier: data.project?.projectIdentifier,
      inspectionDate: data.inspectionDate,
      inspectionType: data.inspectionType,
      isFindingsWorkflow: ToolSettings.getSetting("construction", "is-findings-workflow"),
    };
  };

  var _getObservationsData = function (data) {
    data = $.extend(true, {}, data);
    const allObservations = [
      ...flattenObservations(data.requiredObservations),
      ...flattenObservations(data.additionalObservations),
    ];
    const enforcementActions = [];
    const otherIssues = [];
    var numberOfObservations = 0;
    var observationsScoreTally = 0;

    for (const unpreparedObservation of allObservations) {
      if (unpreparedObservation && unpreparedObservation.deleted !== true) {
        const observation = prepareObservation(unpreparedObservation);
        if (!isNaN(observation.condition)) {
          observationsScoreTally += observation.condition;
          numberOfObservations++;

          updateObservationEnforcements(observation, enforcementActions, otherIssues);
        }
      }
    }

    return {
      numberOfObservations,
      enforcementActions,
      otherIssues,
      ...getObservationsScores(observationsScoreTally, numberOfObservations, data),
    };
  };

  var flattenObservations = function (observationsObject) {
    const result = [];

    ProjectInspectionConstants.addCorrectiveActionInfo(observationsObject);

    for (const key in observationsObject) {
      for (const observation of observationsObject[key]) {
        result.push(observation);
      }
    }

    return result;
  };

  var prepareObservation = function (observation) {
    observation.condition = parseFloat(observation.condition) / 10;
    return observation;
  };

  var updateObservationEnforcements = function (observation, enforcementActions, otherIssues) {
    if (observation.condition <= 0.5) {
      enforcementActions.push(observation.correctiveActionInfo.correctiveActionName);
    } else if (observation.condition === 0.8) {
      otherIssues.push(observation.correctiveActionInfo.correctiveActionName);
    }
  };

  var getObservationsScores = function (observationsScoreTally, numberOfObservations, data) {
    var scoreNumber = observationsScoreTally / numberOfObservations;
    scoreNumber = isNaN(scoreNumber) ? 1 : scoreNumber;
    const passFailScore = getPassFailScore(data);

    return getScores(passFailScore ?? scoreNumber);
  };

  var getFindingsScores = function (numberOfFindings, data) {
    var scoreNumber = numberOfFindings === 0 ? 1 : 0;
    const passFailScore = getPassFailScore(data);

    return getScores(passFailScore ?? scoreNumber);
  };

  var getScores = function (scoreNumber) {
    scoreNumber = Misc.floorDecimals(scoreNumber, 1);
    const scoreGrade = getScoreGrade(scoreNumber);
    const scoreClass = getScoreClass(scoreNumber);

    return { scoreNumber, scoreGrade, scoreClass };
  };

  var getScoreGrade = function (scoreNumber) {
    if (scoreNumber < 0.6) {
      return "F";
    } else if (scoreNumber < 0.7) {
      return "D";
    } else if (scoreNumber < 0.8) {
      return "C";
    } else if (scoreNumber < 0.9) {
      return "B";
    } else {
      return "A";
    }
  };

  var getScoreClass = function (scoreNumber) {
    if (scoreNumber < 0.7) {
      return "red";
    } else {
      return "green";
    }
  };

  var getPassFailScore = function (data) {
    if (data.inspectionType !== "close-out") {
      return null;
    }

    const passes = !includesAny(
      [
        data.disturbedAreasStabilized,
        data.structuralBmpsInstalledProperly,
        data.temporaryBmpsRemoved,
      ],
      [false, "no"],
    );

    return passes ? 1 : 0;
  };

  var includesAny = function (array, includeOptions) {
    for (const item of array) {
      if (includeOptions.includes(item)) {
        return true;
      }
    }

    return false;
  };

  var _getFindingsData = function (data) {
    data = $.extend(true, {}, data);
    const allObservations = [
      ...flattenObservations(data.administrativeFindings),
      ...flattenObservations(data.observationFindings),
    ];
    const findingsBySeverity = {};
    var numberOfFindings = 0;

    for (const observation of allObservations) {
      if (observation && observation.deleted !== true) {
        addSeverity(findingsBySeverity, observation);
        numberOfFindings++;
      }
    }

    return {
      findingSeverities: flatenAndSortFindingSeverities(findingsBySeverity),
      numberOfFindings,
      ...getFindingsScores(numberOfFindings, data),
    };
  };

  var addSeverity = function (findingsBySeverity, observation) {
    if (!observation.severity) {
      return;
    }

    if (!findingsBySeverity[observation.severity]) {
      const severityOptions = ProjectInspectionConstants.getFindingSeverityOptions();

      findingsBySeverity[observation.severity] = {
        displayName: severityOptions.find((severity) => severity.value === observation.severity)
          .name,
        count: 0,
      };
    }

    findingsBySeverity[observation.severity].count++;
  };

  var flatenAndSortFindingSeverities = function (findingsBySeverity) {
    const severityOptions = ProjectInspectionConstants.getFindingSeverityOptions();
    const result = [];

    for (const severityOption of severityOptions) {
      const findingsSeverity = findingsBySeverity[severityOption.value];

      if (findingsSeverity) {
        result.push(findingsSeverity);
      }
    }

    return result;
  };

  return { getSummaryHtml, getSummaryData, _getObservationsData, _getFindingsData };
};

module.exports = ProjectInspectionSummary();

const ToolSettings = require("../../settings/toolSettings");
const ProjectInspectionConstants = require("./projectInspectionConstants");
const Misc = require("../../misc");
