const ParcelsPluEsriDynamicMapLayer = function () {
  const layerName = "parcelsPluEsriDynamicMap";
  const layerKey = "parcelsPluEsriDynamicMapLayer";
  const esriKey = "parcelsPlu";
  const esriLayers = "parcels_plu";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var parcelsPluEsriDynamicMapLayer = new EsriDynamicMapLayer(
      layerName,
      layerKey,
      esriKey,
      esriLayers,
    );
    parcelsPluEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    parcelsPluEsriDynamicMapLayer.loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = ParcelsPluEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
