"use strict";

const DrainageSelectionDraw = function () {
  var init = function (map, enableMethodSelection = false) {
    DrawController.init(map, true, enableMethodSelection);
    loadDrawCreatedListeners(map);
  };

  var loadDrawCreatedListeners = function (map) {
    map.off("draw:created", handleDrawCreated);
    map.on("draw:created", handleDrawCreated);
  };

  var handleDrawCreated = async function (e) {
    if (DrawController.isDrawnItemsLayerShowing()) {
      DrawController.addPolygonToDrawLayer(e);
      await calculateDrawnDrainageArea();
    } else {
      var intersectingPolys = getIntersectingDrainagePolygons(e.layer, e.layerType);
      selectIntersectingPolygons(intersectingPolys);
    }
  };

  var calculateDrawnDrainageArea = async function (updateFooter = true) {
    try {
      const result = await ApiCalls.calculateDrainageArea(DrawController.getDrawnDrainageGeom());
      if (Tree.get("tool") === "esg") {
        PropertyInventoryModalController.updateAcres(result["acres"]);
      } else if (InventoryModal.isBmpFcsInventoryModalFormat()) {
        BmpFcsInventoryModalController.setFormDataField("drawAcres", result);
        if (updateFooter) {
          DrainageTable.updateDrainageAreaFooter(result["acres"], result["pluAcres"]);
        }
      } else {
        Form.manuallySetFormDataField("project-inventory", ["drawnAcres"], result["acres"]);
        ProjectInventoryAreaSelection.setTotalAreaAcres();
      }
    } catch {
      console.error("Unable to calculate acreage");
    }
  };

  var getIntersectingDrainagePolygons = function (drawnLayer, drawnLayerType) {
    var drainageLayer = ModalMap.getDrainageLayer();
    return DrawFunctions.getDrawIntersectingData(
      drawnLayer,
      drawnLayerType,
      drainageLayer,
      "Polygon",
    );
  };

  var selectIntersectingPolygons = function (intersectingPolys) {
    intersectingPolys.forEach((layer) => {
      Layers.selectPolygon(null, layer, true);
    });
  };

  var show = function () {
    $(".method-selection-control").show();
    DrawController.showDrawControl();
  };

  var hide = function () {
    $(".method-selection-control").hide();
    DrawController.hideDrawControl();
    DrawController.toggleDrainageDrawControls(false);
  };

  var resetDrainageSelectionDraw = function () {
    DrawController.hideDrawLayer();
    DrawController.resetDrawController();
  };

  return {
    init,
    show,
    hide,
    resetDrainageSelectionDraw,
    calculateDrawnDrainageArea,
  };
};

module.exports = DrainageSelectionDraw();

const ModalMap = require("./modalMap");
const Layers = require("./layers");
const DrawController = require("./drawController");
const DrawFunctions = require("./drawFunctions");
const ApiCalls = require("../apiCalls");
const DrainageTable = require("./drainageTable");
require("./drawMethodSelector");
const InventoryModal = require("../general/inventoryModal");
const Form = require("../general/form");
const ProjectInventoryAreaSelection = require("../construction/projectInventoryAreaSelection");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
const PropertyInventoryModalController = require("../esg/modals/propertyInventoryModalController");
const Tree = require("../tree");
