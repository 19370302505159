"use strict";

const LidProjectAreaLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (mapId === "main") {
      loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");
    MapFunctions.whenLayerToggled("lidProjectArea", mapId, function (isEnabled, sameSpatialFilter) {
      if (isEnabled) {
        if (
          mapLayers.lidProjectAreaLayer &&
          (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
        ) {
          map.addLayer(mapLayers.lidProjectAreaLayer);
        } else {
          loadLayer(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.lidProjectAreaLayer)) {
          map.removeLayer(mapLayers.lidProjectAreaLayer);
        }
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");
    MapFunctions.whenLayerDataUpdated("lidProjectArea", mapId, function (data) {
      mapLayers.lidProjectAreaLayer = MapFunctions.addLidProjectAreaLayer(
        map,
        mapLayers.lidProjectAreaLayer,
        data,
      );
    });
  };

  // @TODO: Merge this with other layer filters update listeners
  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    const MapFunctions = require("../mapFunctions/mapFunctions");
    Tree.select("filters").on("update", function (e) {
      if (MapFunctions.mapHasLayer(map, mapLayers.lidProjectAreaLayer)) {
        loadLayer();
      }
    });
  };

  var getStyle = function (feature) {
    const color = LidProjectStyles.getColorHex(feature.properties);
    return {
      color:
        feature.properties.highlight && feature.properties.highlight === true
          ? MapStyles.highlights().color
          : color,
      fillOpacity: 0.8,
      fillColor: color,
      weight: feature.properties.highlight && feature.properties.highlight === true ? 3 : 0,
    };
  };

  var loadLayer = function (mapId) {
    var filters = Actions.getFiltersByMapId(mapId);
    var dataPath = Actions.getLayerDataPathByMapId(mapId);

    Tree.set(["layers", "lidProjectArea", "isFetching"], true);

    ApiCalls.getLidProjectAreas(filters, function (data) {
      if (data) {
        data.forEach((project) => {
          project.geom = project.projectAreaGeom;
        });
      }
      data = CleanData.cleanGeoJson(data);

      Tree.set(["layers", "lidProjectArea", dataPath], data);
      Tree.set(["layers", "lidProjectArea", "isFetching"], false);
    });
  };

  return {
    loadLayer,
    loadLayerListenersForMap,
    getStyle,
  };
};

module.exports = LidProjectAreaLayer();

const LidProjectStyles = require("./lidProjectStyles");
const Tree = require("../tree");
const CleanData = require("../mapFunctions/cleanData");
const ApiCalls = require("../apiCalls");
const Actions = require("../actions");
const MapStyles = require("../mapFunctions/mapStyles");
