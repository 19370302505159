class LidSelfInspectionConfirmationModal {
  static formKey = "lid-self-inspection-confirmation";
  static $modal = $("#assessment-modal");
  static initialData;

  static async show() {
    LidSelfInspectionConfirmationModal.initialData =
      await PortalApiCalls.getLidSelfInspectionProject();

    LidSelfInspectionConfirmationModal.#loadDomListeners();
    LidSelfInspectionConfirmationModal.#render();
    Form.initializeAndLoadListeners(
      LidSelfInspectionConfirmationModal.$modal,
      LidSelfInspectionConfirmationModal.formKey,
    );

    Analytics.sendScreenView("portal", LidSelfInspectionConfirmationModal.formKey);
  }

  static #loadDomListeners() {
    LidSelfInspectionConfirmationModal.$modal.on(
      "click",
      ".save-button",
      LidSelfInspectionConfirmationModal._save,
    );
    LidSelfInspectionConfirmationModal.$modal.on(
      "2N:FormInput",
      LidSelfInspectionConfirmationModal.#checkEnableSaveButton,
    );
    LidSelfInspectionConfirmationModal.$modal.on(
      "click",
      ".js-download-pdf",
      LidSelfInspectionConfirmationModal._downloadPdf,
    );
  }

  static #unloadDomListeners() {
    LidSelfInspectionConfirmationModal.$modal.off(
      "click",
      ".save-button",
      LidSelfInspectionConfirmationModal._save,
    );
    LidSelfInspectionConfirmationModal.$modal.off(
      "2N:FormInput",
      LidSelfInspectionConfirmationModal.#checkEnableSaveButton,
    );
    LidSelfInspectionConfirmationModal.$modal.off(
      "click",
      ".js-download-pdf",
      LidSelfInspectionConfirmationModal._downloadPdf,
    );
  }

  static #checkEnableSaveButton() {
    const canSaveLetter = LidSelfInspectionConfirmationModal._hasAllRequiredFields();
    LidSelfInspectionConfirmationModal.$modal
      .find(".save-button")
      .toggleClass("disabled", !canSaveLetter);
  }

  static #render() {
    const html = nunjucks.render("modals/portal/selfInspectionConfirmation.njk", {
      initialData: LidSelfInspectionConfirmationModal.initialData,
      showEmailOptIn: true,
    });
    LidSelfInspectionConfirmationModal.$modal.html(html);
    LidSelfInspectionConfirmationModal.$modal.modal("show");
  }

  static #getUpdateData(updateData) {
    return {
      updates: updateData,
    };
  }

  static async _save() {
    await Form.getReadyToSavePromise(LidSelfInspectionConfirmationModal.formKey);
    const updateData = LidSelfInspectionConfirmationModal.#getUpdateData(
      Form.getDataFromForm(LidSelfInspectionConfirmationModal.formKey, false),
    );
    await PortalApiCalls.saveLidSelfInspectionConfirmation(updateData);
    MessageModal.showCustomMessage(
      "Success",
      `A confirmation email has been sent to you at ${updateData.updates.email}! You may now close this browser tab.`,
      { primaryButtonText: "Close" },
    );
  }

  static close() {
    Form.clearForm(LidSelfInspectionConfirmationModal.formKey);
    LidSelfInspectionConfirmationModal.#unloadDomListeners();
    LidSelfInspectionConfirmationModal.$modal.modal("hide");
  }

  static _hasAllRequiredFields() {
    let requiredFilled = 0;
    const requiredFields = ["email"];
    const data = Form.getDataFromForm(LidSelfInspectionConfirmationModal.formKey, false);

    for (const key in data) {
      if (requiredFields.includes(key) && !!data[key]) {
        requiredFilled++;
      }
    }

    const isValidEmail = LidSelfInspectionConfirmationModal.$modal
      .find(`[name="email"]`)[0]
      .checkValidity();

    return requiredFilled === requiredFields.length && isValidEmail;
  }

  static _downloadPdf() {
    return PortalApiCalls.downloadLidSelfInspectionPdf();
  }
}

module.exports = LidSelfInspectionConfirmationModal;

const Analytics = require("../../general/analytics");
const PortalApiCalls = require("../portalApiCalls");
const Form = require("../../general/form");
const MessageModal = require("../../modals/messageModal");
