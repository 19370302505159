"use strict";

function SharedDataFunctions() {
  const priorityOrders = ["very-high", "high", "moderate", "low", "very-low", "unknown", "no-data"];

  function isPhaseOrLater(currentPhase, targetPhase) {
    const phaseOrderMap = {
      rejected: 1,
      abandoned: 2,
      removed: 3,
      shelved: 4,
      planning: 5,
      planned: 6,
      design: 7,
      construction: 8,
      "post-active": 9,
      completed: 10,
      certified: 11,
    };

    const currentOrder = !currentPhase ? 0 : phaseOrderMap[currentPhase];
    const targetOrder = phaseOrderMap[targetPhase];

    if (currentOrder === undefined || targetOrder === undefined) {
      throw new Error(`Undefined phase ${currentPhase} or ${targetPhase}.`);
    }

    return targetOrder <= currentOrder;
  }

  function isBeforePhase(currentPhase, targetPhase) {
    return !isPhaseOrLater(currentPhase, targetPhase);
  }

  function getPrioritySortOrder() {
    return priorityOrders;
  }

  function prioritySorter(priority1, priority2) {
    return priorityOrders.indexOf(priority2) - priorityOrders.indexOf(priority1);
  }

  return { isPhaseOrLater, isBeforePhase, getPrioritySortOrder, prioritySorter };
}

module.exports = SharedDataFunctions();
