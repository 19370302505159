"use strict";

const GeoServerConfig = function () {
  return {
    get: function () {
      return {
        // config object key is layerName
        structuralBmps: {
          view: "bmpram_bmp",
          popupView: "bmpram_bmp_popup",
          style: "bmpram_bmp",
          stylePhase: " bmpram_bmp_phase",
          popupModule: StructuralBmpsPopupGeoServerLayer,
          filterModule: StructuralBmpFilters,
        },
        bmpDrainageG2: {
          view: "bmp_drainages",
          style: "bmp_drainages",
          stylePhase: " bmp_drainages_phase",
          filterModule: StructuralBmpFilters,
          popupModule: null,
        },
        constructionProjectG2: {
          view: "construction_construction",
          popupView: "construction_construction_popup",
          style: Session.isLargeGroup()
            ? "construction_condition_watershed"
            : "construction_condition",
          stylePhase: Session.isLargeGroup()
            ? "construction_phase_watershed"
            : "construction_phase",
          popupModule: ConstructionProjectPopupGeoServerLayer,
          filterModule: ConstructionProjectG2Filters,
        },
        constructionProjectAreaG2: {
          view: "construction_project_areas",
          style: "construction_project_areas",
          stylePhase: " construction_project_areas_phase",
          filterModule: ConstructionProjectG2Filters,
          popupModule: null,
        },
        postConstructionProjectG2: {
          view: "construction_post_construction",
          popupView: "construction_post_construction_popup",
          style: "construction_post_construction_condition",
          stylePhase: "construction_post_construction_phase",
          popupModule: PostConstructionProjectPopupGeoServerLayer,
          filterModule: PostConstructionProjectG2Filters,
        },
        postConstructionProjectAreaG2: {
          view: "post_construction_project_areas",
          style: "post_construction_project_areas",
          stylePhase: "post_construction_project_areas_phase",
          filterModule: PostConstructionProjectG2Filters,
          popupModule: null,
        },
        assetsCatchBasinEsriDynamicMap: {
          view: "assets_catch_basins",
          popupView: "assets_catch_basins_popup",
          popupModule: AssetsCatchBasinPopup,
          filterModule: AssetsCatchBasinFilters,
        },
        assetsManholeEsriDynamicMap: {
          view: "assets_manholes",
          popupView: "assets_manholes_popup",
          popupModule: AssetsManholePopup,
          filterModule: AssetsManholeFilters,
        },
        assetsBmpEsriDynamicMap: {
          view: "assets_bmps",
          popupView: "assets_bmps_popup",
          popupModule: AssetsBmpPopup,
          filterModule: AssetsBmpFilters,
        },
        assetsLiftStationEsriDynamicMap: {
          view: "assets_lift_stations",
          popupView: "assets_lift_stations_popup",
          popupModule: AssetsLiftStationPopup,
          filterModule: AssetsLiftStationFilters,
        },
        assetsCleanOutEsriDynamicMap: {
          view: "assets_cleanouts",
          popupView: "assets_cleanouts_popup",
          popupModule: AssetsCleanOutPopup,
          filterModule: AssetsCleanoutFilters,
        },
        assetsCulvertEsriDynamicMap: {
          view: "assets_culverts",
          popupView: "assets_culverts_popup",
          popupModule: AssetsCulvertPopup,
          filterModule: AssetsCulvertFilters,
        },
        assetsOutfallEsriDynamicMap: {
          view: "assets_outfalls",
          popupView: "assets_outfalls_popup",
          popupModule: AssetsOutfallPopup,
          filterModule: AssetsOutfallFilters,
        },
        simpleOutfallGeoServerDynamicMap: {
          view: "simple_outfalls",
          popupView: "simple_outfalls_popup",
          popupModule: SimpleOutfallPopup,
          style: Session.isBatonRouge() ? "simple_outfalls_no_rotation" : "simple_outfalls",
        },
        assetsOpenChannelEsriDynamicMap: {
          view: "assets_open_channels",
          popupView: "assets_open_channels_popup",
          popupModule: AssetsOpenChannelPopup,
          filterModule: AssetsOpenChannelFilters,
        },
        assetsPipeEsriDynamicMap: {
          view: "assets_pipes",
          popupView: "assets_pipes_popup",
          popupModule: AssetsPipePopup,
          filterModule: AssetsPipeFilters,
        },
        muniCatchBasin: {
          view: "muni_catch_basins",
          popupView: "muni_catch_basins_popup",
          style: "muni_catch_basins",
          popupModule: MuniCatchBasinPopup,
          filterModule: MuniCatchBasinFilters,
        },
        muniCatchBasinReport: {
          view: "snapshots_catch_basins",
          popupView: "snapshots_catch_basins_popup",
          style: "ar_catch_basins",
          popupModule: MuniCatchBasinReportPopup,
        },
        muniManhole: {
          view: "muni_manholes",
          popupView: "muni_manholes_popup",
          style: "muni_manholes",
          popupModule: MuniManholePopup,
          filterModule: MuniManholeFilters,
        },
        iddeManhole: {
          view: "dry_weather_manholes",
          popupView: "idde_manholes_popup",
          style: "dry_weather_manholes",
          popupModule: DryWeatherGeoServerLayerPopup,
          filterModule: DryWeatherGeoServerLayerFilters,
        },
        dryWeatherOutfall: {
          view: "dry_weather_outfalls",
          popupView: "dry_weather_outfalls_popup",
          style: Session.isBatonRouge()
            ? "dry_weather_outfalls_no_rotation"
            : "dry_weather_outfalls",
          popupModule: DryWeatherGeoServerLayerPopup,
          filterModule: DryWeatherGeoServerLayerFilters,
        },
        dryWeatherCatchBasin: {
          view: "dry_weather_catch_basins",
          popupView: "dry_weather_catch_basins_popup",
          style: "dry_weather_catch_basins",
          popupModule: DryWeatherGeoServerLayerPopup,
          filterModule: DryWeatherGeoServerLayerFilters,
        },
        muniCleanout: {
          view: "muni_cleanouts",
          popupView: "muni_cleanouts_popup",
          style: "muni_cleanouts",
          popupModule: MuniCleanoutPopup,
          filterModule: MuniCleanoutFilters,
        },
        muniBmp: {
          view: "muni_sbmps",
          popupView: "muni_sbmps_popup",
          style: "muni_sbmps",
          popupModule: MuniBmpPopup,
          filterModule: MuniBmpFilters,
        },
        lidBmp: {
          view: "lid_sbmps",
          popupView: "lid_sbmps_popup",
          style: "muni_sbmps",
          popupModule: MuniBmpPopup,
          filterModule: LidBmpFilters,
        },
        muniCulvert: {
          view: "muni_culverts",
          popupView: "muni_culverts_popup",
          style: "muni_culverts",
          popupModule: MuniCulvertPopup,
          filterModule: MuniCulvertFilters,
        },
        muniOpenChannel: {
          view: "muni_open_channels",
          popupView: "muni_open_channels_popup",
          style: "muni_open_channels",
          popupModule: MuniOpenChannelPopup,
          filterModule: MuniOpenChannelFilters,
        },
        muniOutfalls: {
          view: "muni_outfalls",
          popupView: "muni_outfalls_popup",
          style: Session.isBatonRouge() ? "muni_outfalls_no_rotation" : "muni_outfalls",
          popupModule: OutfallPopup,
          filterModule: MuniOutfallsFilters,
        },
        indcomFacilities: {
          view: "indcom_facilities",
          popupView: "indcom_facilities_popup",
          style: "indcom_facilities",
          stylePhase: "indcom_facilities_phase",
          popupModule: IndcomFacilityPopup,
          filterModule: IndcomFacilityFilters,
        },
        catchments:
          !Session.isNewDataDrivenRoadConditionGroup() || Tree.get("dataView") !== "roads"
            ? {
                view: "catchments",
                popupView: "catchments_popup",
                popupModule: CatchmentPopup,
              }
            : {},
        maintenanceZones: {
          view: "maintenance_zones",
          popupView: "maintenance_zones_popup",
          popupModule: MaintenanceZonePopup,
        },
        highways: {
          view: "highways",
        },
        muniLiftStations: {
          view: "muni_lift_stations",
          popupView: "muni_lift_stations_popup",
          style: "muni_lift_stations",
          popupModule: MuniLiftStationsPopup,
          filterModule: MuniLiftStationsFilters,
        },
        muniPipes: {
          view: "muni_pipes",
          popupView: "muni_pipes_popup",
          style: "muni_pipes",
          popupModule: MuniPipesPopup,
          filterModule: MuniPipesFilters,
        },
        projectDeliveryBmps: {
          view: "pdelivery_sbmps",
          popupView: "pdelivery_sbmps_popup",
          style: Session.isLargeGroup() ? "pdelivery_sbmps_watershed" : "pdelivery_sbmps",
          popupModule: projectDeliveryBmpsPopup,
          stylePhase: Session.isLargeGroup()
            ? "pdelivery_sbmps_phase_watershed"
            : "pdelivery_sbmps_phase",
          filterModule: ProjectDeliveryFilters,
        },
        telrResultBmp: {
          view: "telr_result_bmps",
          popupView: "telr_result_bmps_popup",
          style: Session.isLargeGroup() ? "pdelivery_sbmps_watershed" : "pdelivery_sbmps",
          stylePhase: Session.isLargeGroup()
            ? "telr_result_bmps_phase_watershed"
            : "pdelivery_sbmps_phase",
          popupModule: projectDeliveryBmpsPopup,
          filterModule: TelrResultBmpFilters,
        },
        stormdrainGeoServer: {
          view: "storm_drains",
          style: "storm_drains",
          popupModule: null,
        },
        planProperties: {
          view: "esg_plan_properties",
          popupView: "esg_plan_properties_popup",
          style: "esg_properties",
          popupModule: PlanPropertiesGeoServerPopup,
          filterModule: PropertiesFilters,
        },
        allProperties: {
          view: "esg_all_properties",
          popupView: "esg_all_properties_popup",
          style: "esg_properties",
          popupModule: AllPropertiesGeoServerPopup,
          filterModule: PropertiesFilters,
        },
        incentivePrograms: {
          view: "esg_incentive_programs",
          popupModule: IncentiveProgramsGeoServerPopup,
          filterModule: IncentiveProgramsFilters,
        },
        activities: {
          popupModule: { getPopupHtml: () => {} },
          filterModule: ActivityFilters,
        },
      };
    },
  };
};

module.exports = GeoServerConfig();

const AssetsBmpPopup = require("../assetManagement/popups/assetsBmpPopup");
const AssetsCatchBasinPopup = require("../assetManagement/popups/assetsCatchBasinPopup");
const AssetsCleanOutPopup = require("../assetManagement/popups/assetsCleanOutPopup");
const AssetsCulvertPopup = require("../assetManagement/popups/assetsCulvertPopup");
const AssetsLiftStationPopup = require("../assetManagement/popups/assetsLiftStationPopup");
const AssetsManholePopup = require("../assetManagement/popups/assetsManholePopup");
const AssetsOpenChannelPopup = require("../assetManagement/popups/assetsOpenChannelPopup");
const MuniOpenChannelFilters = require("../muni/openChannel/muniOpenChannelFilters");
const AssetsOutfallPopup = require("../assetManagement/popups/assetsOutfallPopup");
const AssetsPipePopup = require("../assetManagement/popups/assetsPipePopup");
const CatchmentPopup = require("../catchments/catchmentPopup");
const DryWeatherGeoServerLayerFilters = require("../illicitDischarge/dryWeatherGeoServerLayerFilters");
const DryWeatherGeoServerLayerPopup = require("../illicitDischarge/dryWeatherGeoServerLayerPopup");
const IndcomFacilityPopup = require("../indcom/indcomFacilityPopup");
const IndcomFacilityFilters = require("../indcom/indcomFacilityFilters");
const MaintenanceZonePopup = require("../maintenanceZones/maintenanceZonePopup");
const MuniCatchBasinPopup = require("../muni/muniCatchBasinPopup");
const MuniCatchBasinFilters = require("../muni/muniCatchBasinFilters");
const MuniBmpFilters = require("../muni/bmp/muniBmpFilters");
const MuniManholePopup = require("../muni/muniManholePopup");
const MuniManholeFilters = require("../muni/muniManholeFilters");
const MuniCleanoutPopup = require("../muni/muniCleanoutPopup");
const MuniCleanoutFilters = require("../muni/muniCleanoutFilters");
const MuniBmpPopup = require("../muni/bmp/muniBmpPopup");
const MuniCulvertPopup = require("../muni/culvert/muniCulvertPopup");
const MuniCulvertFilters = require("../muni/culvert/muniCulvertFilters");
const MuniOpenChannelPopup = require("../muni/openChannel/muniOpenChannelPopup");
const MuniOutfallsFilters = require("../muni/outfalls/muniOutfallsFilters");
const MuniLiftStationsPopup = require("../muni/muniLiftStationsPopup");
const MuniLiftStationsFilters = require("../muni/muniLiftStationsFilters");
const OutfallPopup = require("../outfalls/outfallPopup");
const SimpleOutfallPopup = require("../outfalls/simpleOutfallPopup");
const MuniPipesPopup = require("../muni/muniPipesPopup");
const MuniPipesFilters = require("../muni/muniPipesFilters");
const projectDeliveryBmpsPopup = require("../projectDelivery/popups/projectDeliveryBmpsPopup");
const ProjectDeliveryFilters = require("../projectDelivery/projectDeliveryFilters");
const StructuralBmpsPopupGeoServerLayer = require("../bmpFcsG2/structuralBmpsPopupGeoServerLayer");
const StructuralBmpFilters = require("../bmpFcsG2/structuralBmpFilters");
const TelrResultBmpFilters = require("../scenario/telrResultBmpFilters");
const ConstructionProjectPopupGeoServerLayer = require("../constructionG2/constructionProjectPopupGeoServerLayer");
const ConstructionProjectG2Filters = require("../constructionG2/constructionProjectG2Filters");
const PostConstructionProjectPopupGeoServerLayer = require("../constructionG2/postConstructionProjectPopupGeoServerLayer");
const PostConstructionProjectG2Filters = require("../constructionG2/postConstructionProjectG2Filters");
const Session = require("../login/session");
const MuniCatchBasinReportPopup = require("../muni/MuniCatchBasinReportPopup");
const PlanPropertiesGeoServerPopup = require("../esg/geoServer/planPropertiesGeoServerPopup");
const PropertiesFilters = require("../esg/geoServer/propertiesFilters");
const AllPropertiesGeoServerPopup = require("../esg/geoServer/allPropertiesGeoServerPopup");
const IncentiveProgramsFilters = require("../esg/geoServer/incentiveProgramsFilters");
const IncentiveProgramsGeoServerPopup = require("../esg/geoServer/incentiveProgramsGeoServerPopup");
const Tree = require("../tree");
const ActivityFilters = require("../peo/activityFilters");
const LidBmpFilters = require("../lid/lidBmpFilters");
const AssetsPipeFilters = require("../assetManagement/filters/assetsPipeFilters");
const AssetsCatchBasinFilters = require("../assetManagement/filters/assetsCatchBasinFilters");
const AssetsBmpFilters = require("../assetManagement/filters/assetsBmpFilters");
const AssetsOutfallFilters = require("../assetManagement/filters/assetsOutfallFilters");
const AssetsManholeFilters = require("../assetManagement/filters/assetsManholeFilters");
const AssetsCleanoutFilters = require("../assetManagement/filters/assetsCleanoutFilters");
const AssetsLiftStationFilters = require("../assetManagement/filters/assetsLiftStationFilters");
const AssetsCulvertFilters = require("../assetManagement/filters/assetsCulvertFilters");
const AssetsOpenChannelFilters = require("../assetManagement/filters/assetsOpenChannelFilters");
