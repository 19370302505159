"use strict";

const Benchmark = function () {
  //default values for new bench
  const THRESHMIN = 0.4;
  const THRESHMAX = 0.75;
  const BENCHVEGPER = 0.575;
  const HUNDRED = 100;
  const BENCHVEGPERDEFAULT = 85;
  const THRESHVEGDEFAULT = 50;

  const $benchmarkModal = $("#benchmarkModal");
  const $benchmarkForm = $("#benchInfo");

  var init = function () {
    loadListeners();
    $("#datetimepicker-bench").datetimepicker({
      format: "YYYY-MM-DD HH:mm",
      defaultDate: new Date(),
    });
  };

  var loadListeners = function () {
    $benchmarkModal.off();
    $benchmarkModal.on("click", ".modal-footer .cancel-btn", cancelBenchmarkForm);
    $benchmarkModal.on("click", ".modal-footer .save-btn", validateBenchmarkFields);
    $benchmarkModal.on(
      "click",
      ".bench-field-group[data-field-group='mat-accum'] .save-btn",
      saveDepth,
    );
    $benchmarkModal.on(
      "click",
      ".bench-field-group[data-field-group='infiltration'] .save-btn",
      saveInf,
    );
    CommonModalFunctions.hideModalDatePickerOnClickOutside(
      $benchmarkModal.find("input[name='bm_date']"),
      $benchmarkModal,
    );

    $("#benchInfo").off();
    $("#benchInfo").on("blur", ":input[type=text]", function () {
      var field = $(this).attr("name");
      var dataArray = Tree.get(["bmpram", "bmp"]);

      //for benchmark_depth table
      if (field == "dpth_desc") {
        const depthData = Tree.get(["bmpram", "depth"]);
        depthData[field] = $(this).val();
        Tree.set(["bmpram", "depth"], depthData);
        return;
      } else if (field == "bm_date") {
        if (!Tree.get(["bmpram", "benchmarkUpdate"]) && !isBenchmarkDateValid($(this).val())) {
          $benchmarkForm.find("input[name='bm_date']").data("DateTimePicker").date(new Date());
          return;
        }
      }

      dataArray[field] = $(this).val();
      saveTemp(dataArray);
    });

    $("#benchInfo").on("change", ":input[type=radio]", function () {
      var dataArray = Tree.get(["bmpram", "bmp"]);

      dataArray[$(this).attr("name")] = $(this).val();
      saveTemp(dataArray);
    });

    $("#benchInfo").on("change", ":input[type=number]", function () {
      //@TODO refactor
      var HUNDRED = 100;
      var value = $(this).val();
      var dataArray = Tree.get(["bmpram", "bmp"]);

      if (value == "") {
        value = null;
      }
      var field = $(this).attr("name");
      if (field == "bm_veg_pct" || field == "thr_veg_pct") {
        validateVegCover(dataArray.bmp_type, field);
        value = value / HUNDRED;
      } else if (field === "bm_dpth" || field == "dpth_desc") {
        const units = $("#benchInfo [name='dpth_unit']").val();
        const depthInFt = UnitConversion.convertVal(parseFloat(value), units, "feet");

        if (field === "bm_dpth" && dataArray.bmp_type == "Sediment Trap" && depthInFt < 1.0) {
          MessageModal.showSimpleWarningModal(
            "The benchmark depth must be at least 1.0 ft. By definition, a sediment trap has at least a 1ft sump to capture particles. Otherwise, it is a drop inlet. Please re-measure the depth or re-type the BMP.",
          );
          $("#benchInfo input[name=bm_dpth]").val("");
        } else if (
          field === "bm_dpth" &&
          (dataArray.bmp_type == "Bioretention" || dataArray.bmp_type == "Biofiltration") &&
          depthInFt < 0.17
        ) {
          MessageModal.showSimpleWarningModal("The benchmark depth must be at least 0.17 ft.");
          $("#benchInfo input[name=bm_dpth]").val("");
        } else if (field === "bm_dpth" && parseFloat(value) < 0.01) {
          value = 0.01;
          $('#benchInfo input[name="bm_dpth"]').val(0.01);
        }
        if (field === "bm_dpth" && Misc.countDecimals(value) > 2) {
          value = Math.ceil(value * 100) / 100;
          $('#benchInfo input[name="bm_dpth"]').val(value);
        }
        //for benchmark_depth table
        const depthData = Tree.get(["bmpram", "depth"]);
        depthData[field] = value;
        Tree.set(["bmpram", "depth"], depthData);
        return;
      } else if (field == "inf_locid" || field == "inf_time" || field == "inf_rdg") {
        //for benchmark inf table
        var infData = Tree.get(["bmpram", "inf"]);
        infData[field] = value;
        Tree.set(["bmpram", "inf"], infData);
        return;
      } else if (field == "bm_inf_rate") {
        dataArray["thr_inf_rate"] = (1 - parseFloat(dataArray.thr_inf_decline)) * value;
      } else if (field == "thr_veg_max" || field == "thr_veg_min") {
        var valid = validateThreshMinMax(field);
        if (valid) {
          var min = $("#benchInfo input[name='thr_veg_min']").val();
          var max = $("#benchInfo input[name='thr_veg_max']").val();
          dataArray["bm_veg_pct"] =
            ((parseFloat(max) - parseFloat(min)) / 2 + parseFloat(min)) / HUNDRED;
          value = value / HUNDRED;
        }
      }
      dataArray[$(this).attr("name")] = value;
      saveTemp(dataArray);
    });

    $("#benchInfo").on("change", "select", function () {
      var data_field = $(this).attr("name");
      if (data_field == "bm_dpth_type" || data_field == "dpth_unit") {
        const depthData = Tree.get(["bmpram", "depth"]);
        depthData[data_field] = $(this).val();
        Tree.set(["bmpram", "depth"], depthData);
      }
    });

    $("#benchInfo").on("click", ".depth-record .delete-btn", deleteDepth);
    $("#benchInfo").on("click", ".inf-record .delete-btn", deleteInf);

    Tree.select(["bmpram", "tempSaveDepths"]).on("update", function (e) {
      var depthArray = e.data.currentData;
      calculateAndSaveDepthData(depthArray);
      handleDepthTypeOption(depthArray);
    });

    Tree.select(["bmpram", "tempSaveInfs"]).on("update", function (e) {
      var tempSaveInfs = e.data.currentData;
      calculateAndSaveInfData(tempSaveInfs);
    });
  };

  var cancelBenchmarkForm = function () {
    function close() {
      closeBenchmarkModal();
      resetBmpramData();
      resetBenchmarkForm();
      PopupPhotos.clearObsTempPhotos("bench");
    }

    if (Tree.get("readOnlyModal")) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var saveDepth = function () {
    var check = checkFootprintWqcapInputs();
    var record = Tree.get(["bmpram", "depth"]);
    var tempSaveDepthsCursor = Tree.select(["bmpram", "tempSaveDepths"]);

    if (!record.dpth_desc || !record.bm_dpth || !record.bm_dpth_type || record.bm_dpth_type == 0) {
      MessageModal.showSimpleWarningModal("Enter a value for all fields to save.");
    } else {
      if (check) {
        record["bm_id"] = Tree.get("currentBmpFcs").currentBenchmarkId;
        record["dpth_id"] = "temp_" + Date.now();

        setAndConvertDepthUnit(record);
        tempSaveDepthsCursor.push(record);
      } else {
        blockDisplayedData();
      }
      resetDepthForm();
    }
  };

  var setAndConvertDepthUnit = function (record) {
    if (!record.dpth_unit) {
      record.dpth_unit = "feet";
    }
    if (record.dpth_unit !== "feet") {
      const convertedValue = UnitConversion.convertVal(record.bm_dpth, record.dpth_unit, "feet");
      record.bm_dpth = convertedValue;
    }
  };

  var resetDepthForm = function (num = 0) {
    Tree.set(["bmpram", "depth", "bm_dpth_type"], num);
    Tree.set(["bmpram", "depth", "dpth_desc"], null);
    Tree.set(["bmpram", "depth", "bm_dpth"], null);

    $("#benchInfo select[name='bm_dpth_type']").val(num.toString());
    $("#benchInfo :input[name='dpth_desc']").val("");
    $("#benchInfo :input[name='bm_dpth']").val("");

    const currentUnit = Tree.get(["bmpram", "depth", "dpth_unit"]);
    $("#benchInfo select[name='dpth_unit']").val(currentUnit || "feet");

    var $depthTypeSelect = $benchmarkForm.find("select[name='bm_dpth_type']");
    Misc.toggleDisabledInputs($depthTypeSelect, false);
  };

  var loadDepthData = function (benchmarkId, groupId) {
    if (!benchmarkId) {
      benchmarkId = Tree.get("currentBmpFcs").currentBenchmarkId;
    }
    ApiCalls.getDepthData(benchmarkId, groupId, function (dataArr) {
      Tree.set(["bmpram", "tempSaveDepths"], dataArr);
    });
  };

  var calculateAndSaveDepthData = function (dataArr) {
    var data = Tree.get(["bmpram", "benchmarkData"]);
    var bmpData = Tree.get(["bmpram", "bmp"]);
    if (data) {
      var last = data.length - 1;

      if (dataArr && dataArr.length) {
        bmpData["bm_depth_ft"] = data[last]["bm_depth_ft"] = getAverageDepth(dataArr);
        bmpData["bm_dpth_num"] = data[last]["bm_dpth_num"] = dataArr.length;
        bmpData["thr_dpth_ft"] = data[last]["thr_dpth_ft"] = getThreshDepth(data, bmpData, dataArr);
      } else {
        $benchmarkForm.find(".depth-container").empty();
        bmpData["bm_depth_ft"] = data[last]["bm_depth_ft"] = null;
        bmpData["bm_dpth_num"] = data[last]["bm_dpth_num"] = null;
        bmpData["thr_dpth_ft"] = data[last]["thr_dpth_ft"] = null;
      }
      data[last] = removeInvFields(data[last]);
      Tree.set(["bmpram", "benchmarkData"], data);
      Tree.set(["bmpram", "bmp"], bmpData);
      // populateBenchmark(data);
    }
  };

  var saveInf = function () {
    var record = Tree.get(["bmpram", "inf"]);
    var tempSaveInfsCursor = Tree.select(["bmpram", "tempSaveInfs"]);

    const errorMessage = BmpObservation.getInfiltrationErrorMessage(
      $benchmarkForm,
      record,
      Tree.get(["bmpram", "tempSaveInfs"]),
    );
    if (errorMessage) {
      MessageModal.showSimpleWarningModal(errorMessage);
    } else {
      record["bm_id"] = Tree.get("currentBmpFcs").currentBenchmarkId;
      record["bm_inf_id"] = "temp_" + Date.now();

      tempSaveInfsCursor.push(record);
      resetInfForm();
    }
  };

  var resetInfForm = function () {
    Tree.set(["bmpram", "inf", "inf_rdg"], null);
    Tree.set(["bmpram", "inf", "inf_time"], null);

    $("#benchInfo input[name='inf_time']").val("");
    $("#benchInfo input[name='inf_rdg']").val("");
  };

  //TODO redundant data, clean up
  var loadInfiltrationData = function (benchmarkId, groupId, callback) {
    if (!benchmarkId) {
      benchmarkId = Tree.get("currentBmpFcs").currentBenchmarkId;
    }

    ApiCalls.getInfData(benchmarkId, groupId, function (tempSaveInfs) {
      Tree.set(["bmpram", "tempSaveInfs"], tempSaveInfs);
      if (callback) {
        callback();
      }
    });
  };

  var calculateAndSaveInfData = function (tempSaveInfs) {
    var data = Tree.get(["bmpram", "benchmarkData"]);
    var bmpData = Tree.get(["bmpram", "bmp"]);

    if (data) {
      var last = data.length - 1;

      if (tempSaveInfs && tempSaveInfs.length) {
        var avgRate = calcInfRate(tempSaveInfs);
        bmpData["bm_inf_rate"] = data[last]["bm_inf_rate"] = avgRate;
        bmpData["thr_inf_rate"] = data[last]["thr_inf_rate"] =
          (1 - data[last]["thr_inf_decline"]) * avgRate;
        // bmpData["bm_inf_obs"] = data[last]["bm_inf_obs"] = tempSaveInfs.length;
      } else {
        $benchmarkForm.find(".inf-container").empty();
        bmpData["bm_inf_rate"] = data[last]["bm_inf_rate"] = null;
        bmpData["thr_inf_rate"] = data[last]["thr_inf_rate"] = null;
      }
      data[last] = removeInvFields(data[last]);
      Tree.set(["bmpram", "benchmarkData"], data);
      Tree.set(["bmpram", "bmp"], bmpData);
    }
  };

  var calcInfRate = function (tempSaveInfs) {
    //finds average rate for each location then averages those for final rate value
    var byLocation = groupByLocation(tempSaveInfs);
    var byLocationSorted = sortByTime(byLocation);
    var locationCount, totalRate, avgRate;
    locationCount = totalRate = avgRate = 0;
    $benchmarkForm.find(".inf-container").html("").show();
    byLocationSorted.forEach(function (item, index) {
      var locationRate = 0;
      for (let i = 0; i < item.length; i++) {
        const infHtml = nunjucks.render("modals/benchmark/infiltration.njk", {
          inf: item[i],
        });
        $benchmarkForm.find(".inf-container").append(infHtml);
      }
      if (item.length >= 2) {
        locationCount++;
        var tempTime, tempReading, tempRate;
        for (let i = 0; i < item.length - 1; i++) {
          tempTime = item[i + 1]["inf_time"] - item[i]["inf_time"];
          tempReading = item[i + 1]["inf_rdg"] - item[i]["inf_rdg"];
          tempRate = tempReading / tempTime;
          locationRate += Math.abs(tempRate);
        }
        totalRate += locationRate / (item.length - 1);
      }
    });
    avgRate = totalRate / locationCount;
    return avgRate;
  };

  var groupByLocation = function (tempSaveInfs) {
    var grouped = [];
    for (var i = 0; i < tempSaveInfs.length; i++) {
      var locid = tempSaveInfs[i]["inf_locid"];
      (grouped[locid] = grouped[locid] || []).push(tempSaveInfs[i]);
    }
    return grouped;
  };

  var sortByTime = function (dataArray) {
    var timename = "inf_time";
    //sorts tempSaveInfs by time value
    dataArray.forEach(function (item, index) {
      dataArray[index].sort(function (a, b) {
        return parseFloat(a[timename]) - parseFloat(b[timename]);
      });
    });
    return dataArray;
  };

  var resetBenchmarkForm = function () {
    $("#benchInfo").find("input").not("[type=radio]").val("");
    $("#benchInfo").find("textarea").val("");
    $("#benchInfo").find("select[name='bm_dpth_type']").val("0");
    $benchmarkForm.find(".depth-container").empty();
    $benchmarkForm.find(".inf-container").empty();
    resetDepthForm();
    getFootprintWqcapInputContainers($benchmarkForm).show();
    CommonModalFunctions.removeHighlights($benchmarkForm);
  };

  var resetBmpramData = function () {
    Tree.set("bmpram", {});
    Tree.set(["bmpram", "tempSaveDepths"], []);
    Tree.set(["bmpram", "tempDeleteDepthIds"], []);
    Tree.set(["bmpram", "tempSaveInfs"], []);
    Tree.set(["bmpram", "tempDeleteInfIds"], []);
  };

  //@TODO: condense api calls  & refactor
  var showNewBenchmarkModal = function (bmpName, bmpType, bmpTypeNumber) {
    const groupId = Tree.get("activeGroup", "groupId");
    Tree.set(["bmpram", "benchmarkUpdate"], false);
    setBenchmarkFormDisplayOptions(bmpName, bmpType, true);
    handleExtraBenchmarkFields(bmpTypeNumber);
    CommonModalFunctions.handleReadOnlyView($benchmarkModal, false);
    setDefaultDepthType(bmpTypeNumber);
    checkStadiaRodOnly(bmpTypeNumber);

    ApiCalls.getBmp(bmpName, groupId, function (bmpData) {
      const thresh_cap = getThreshCap(bmpData);
      populateBenchmark([
        {
          bmpName: bmpName,
          idBmp: bmpData[0].idBmp,
          bmp_type: bmpType,
          bmp_wqcap: bmpData[0].bmp_wqcap,
          bmp_footprint: bmpData[0].bmp_footprint,
        },
      ]);

      ApiCalls.getTypeData(groupId, bmpType, function (veg_data) {
        loadDefaultVeg(veg_data, bmpType);
        setNewBenchmarkData(bmpName, bmpData);
        setNewBenchmarkBmp(bmpName, bmpData, bmpType, thresh_cap, veg_data);
      });
    });
  };

  var setNewBenchmarkData = function (bmpName, bmpData) {
    Tree.set(
      ["bmpram", "benchmarkData"],
      [
        {
          bmpName: bmpName,
          idBmp: bmpData[0].idBmp,
          thr_inf_decline: bmpData[0].thr_inf_decline,
        },
      ],
    );
  };

  var setNewBenchmarkBmp = function (bmpName, bmpData, bmpType, thresh_cap, veg_data) {
    const timestamp = getFormattedDateTime();

    const newBenchData = {
      bmpName: bmpName,
      idBmp: bmpData[0].idBmp,
      bmp_type: bmpType,
      bmp_wqcap: bmpData[0].bmp_wqcap,
      bmp_footprint: bmpData[0].bmp_footprint,
      thr_dpth_cap: thresh_cap,
      thr_inf_decline: bmpData[0].thr_inf_decline,
      bm_veg_pct: veg_data[0].bm_veg_per,
      thr_veg_pct: veg_data[0].thr_veg_per,
      thr_veg_min: null,
      thr_veg_max: null,
      bm_inf_obs: "2",
      inf_input: "observation",
      bm_date: timestamp,
      bm_pers: Tree.get(["user", "username"]),
    };
    if (bmpType == "Wet Basin" || bmpType == "Retention Basin") {
      newBenchData.bm_veg_pct = BENCHVEGPER;
      newBenchData.thr_veg_min = THRESHMIN;
      newBenchData.thr_veg_max = THRESHMAX;
    }
    Tree.set(["bmpram", "bmp"], newBenchData);
    PopupPhotos.load("bench");
  };

  //TODO condense api calls  & refactor
  var showEditBenchmarkModal = function (
    bmpName,
    idBmp,
    bmpType,
    bmpTypeNumber,
    bm_id = 0,
    readOnly,
  ) {
    init();
    const groupId = Tree.get("activeGroup", "groupId");
    Tree.set(["bmpram", "benchmarkUpdate"], true);
    Tree.set(["bench_photos", "currentId"], bm_id);

    setBenchmarkFormDisplayOptions(bmpName, bmpType);
    handleExtraBenchmarkFields(bmpTypeNumber);
    CommonModalFunctions.handleReadOnlyView($benchmarkModal, readOnly);
    setDefaultDepthType(bmpTypeNumber);
    checkStadiaRodOnly(bmpTypeNumber);

    ApiCalls.getBenchmark(idBmp, groupId, bm_id, 0, function (benchmarkData) {
      if (benchmarkData.length) {
        //use most recent bench record;
        const last = benchmarkData.length - 1;
        benchmarkData[last]["bmp_type"] = bmpType;

        ApiCalls.getBmp(bmpName, groupId, function (bmpData) {
          const thresh_cap = getThreshCap(bmpData);

          setBenchmarkBmp(benchmarkData, bmpName, bmpData, bmpType, thresh_cap);

          if (
            (bmpType == "Dry Basin" || bmpType == "Wet Basin" || bmpType == "Infiltration Basin") &&
            bmpData[0].inaccessible != 1
          ) {
            loadInfiltrationData(bm_id, groupId, function () {
              loadDepthData(bm_id, groupId);
            });
          } else {
            loadDepthData(bm_id, groupId);
          }
        });

        Tree.set(["bmpram", "benchmarkData"], benchmarkData);
        populateBenchmark(benchmarkData);
        PopupPhotos.load("bench", Tree.get(["bench_photos", "currentId"]));
      }
    });
  };

  var setBenchmarkBmp = function (benchmarkData, bmpName, bmpData, bmpType, thresh_cap) {
    var benchData = {
      bmpName: bmpName,
      idBmp: bmpData[0].idBmp,
      bm_id: benchmarkData[benchmarkData.length - 1].bm_id,
      bmp_type: bmpType,
      bmp_wqcap: bmpData[0].bmp_wqcap,
      bmp_footprint: bmpData[0].bmp_footprint,
      thr_dpth_cap: thresh_cap,
      thr_inf_decline: bmpData[0].thr_inf_decline,
      bmp_inlets: bmpData[0].bmp_inlets,
      bmp_outlets: bmpData[0].bmp_outlets,
    };
    Tree.set(["bmpram", "bmp"], benchData);
  };

  var getThreshCap = function (bmpData) {
    var thresh_cap = bmpData[0].thr_dpth_cap;
    if (parseInt(bmpData[0].inaccessible) == 1) {
      $(".bench-field-group.type-specific").hide();
      if (parseInt(bmpData[0].inv_sedacc) == 1) {
        thresh_cap = 0.5;
        delete bmpData[0].inv_sedacc;
      }
    }
    return thresh_cap;
  };

  var populateBenchmark = function (data) {
    var last = data.length - 1;
    var currentRecord = data[last];
    for (var key in currentRecord) {
      if (currentRecord[key] || currentRecord[key] == 0) {
        if (
          key == "bm_veg_pct" ||
          key == "thr_veg_pct" ||
          key == "thr_veg_min" ||
          key == "thr_veg_max"
        ) {
          var value = currentRecord[key] * HUNDRED;
          $('#benchInfo :input[name="' + key + '"]').val(value);
        } else if (key == "bm_inf_obs") {
          $('#benchInfo :input[name="bm_inf_obs"][value="' + currentRecord[key] + '"]').prop(
            "checked",
            true,
          );
        } else {
          if (key === "bm_date") {
            $('#benchInfo :input[name="' + key + '"]')
              .data("DateTimePicker")
              .date(currentRecord[key]);
          } else {
            $('#benchInfo :input[name="' + key + '"]').val(currentRecord[key]);
          }
        }
      }
    }
    setLocationCounts(currentRecord["bmp_footprint"]);
  };

  var setLocationCounts = function (footprint) {
    footprint = parseFloat(footprint);

    const matAccumLocationCount = getMatAccumLocationCount(footprint);
    $("#benchInfo :input[name='mat_location_count']").val(matAccumLocationCount);

    const infLocationCount = BmpFunctions.getLocationCount(footprint);
    $("#benchInfo :input[name='inf_location_count']").val(infLocationCount);
  };

  var getMatAccumLocationCount = function (footprint) {
    if (footprint && footprint >= 10000) {
      return 2;
    } else {
      return 1;
    }
  };

  var saveTemp = function (array) {
    Tree.set(["bmpram", "bmp"], array);
  };

  var getFormattedDateTime = function () {
    //save autopopulated date
    var date = new Date();
    var dataArray = Tree.get(["bmpram", "benchmarkData"]);

    if (dataArray && dataArray[0]) {
      dataArray[0]["bm_date"] = date;
      Tree.set(["bmpram", "benchmarkData"], dataArray);

      var year = date.getFullYear();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var dateString = month + "/" + day + "/" + year + " " + hours + ":" + minutes;
      return dateString;
    }
  };

  var setBenchmarkFormDisplayOptions = function (bmpName, bmpType, newBenchmark = false) {
    Misc.toggleDisabledInputs($("#benchmarkModal"), false);

    Tree.set(["bmpram", "inf"], {});
    Tree.set(["bmpram", "benchmarkData"], [{}]);

    $("#benchmarkModal .bmp-id").text(bmpName);
    $("#benchmarkModal .bmp-type").text(bmpType);

    $benchmarkModal.modal("show");
    $(".addBenchButton").show();
    $("#benchmarkModal .cancel-btn").show();

    if (newBenchmark) {
      const timestamp = getFormattedDateTime();
      $("#benchmarkModal input[name='bm_date']").data("DateTimePicker").date(new Date(timestamp));
      $("#benchmarkModal input[name='thr_veg_min']").val(THRESHMIN * 100);
      $("#benchmarkModal input[name='thr_veg_max']").val(THRESHMAX * 100);

      const username = Tree.get(["user", "username"]);
      $benchmarkModal.find("input[name='bm_pers']").val(username);
    }

    Analytics.sendScreenView("modal", "benchmark", "bmp");
  };

  var checkStadiaRodOnly = function (bmpTypeNumber) {
    var $depthTypeSelect = $benchmarkForm.find("select[name='bm_dpth_type']");
    if (FormConstants.stadiaRodOnlyTypeNumbers.includes(bmpTypeNumber)) {
      setFixedDepthTypeOption(2);
      $depthTypeSelect.data("fixedOnly", 2);
    } else {
      $depthTypeSelect.data("fixedOnly", 0);
    }
  };

  var setDefaultDepthType = function (bmpTypeNumber) {
    var defaultDepthType = 2;
    if (FormConstants.defaultStaffPlateTypeNumbers.includes(bmpTypeNumber)) {
      defaultDepthType = 1;
    }
    $("#bench-thresh-dropdown").val(defaultDepthType.toString());
    Tree.set(["bmpram", "depth", "bm_dpth_type"], defaultDepthType);
  };

  var toggleFootprintWqcapByType = function (bmpTypeNumber, $form) {
    if (FormConstants.hideFootprintWqcapTypeNumbers.includes(bmpTypeNumber)) {
      getFootprintWqcapInputContainers($form).hide();
    }
  };

  var getFootprintWqcapInputContainers = function ($parent) {
    return $parent
      .find("input[name='bmp_wqcap'], input[name='bmp_footprint']")
      .closest(".info-input, fieldset");
  };

  var loadDefaultVeg = function (dataArr, bmp_type) {
    var bm_per = dataArr[0].bm_veg_per * HUNDRED;
    var thr_per = dataArr[0].thr_veg_per * HUNDRED;
    if (bmp_type === "green-roof") {
      thr_per = dataArr[0].thr_veg_per == null ? THRESHVEGDEFAULT : thr_per;
      bm_per = dataArr[0].bm_veg_per == null ? BENCHVEGPERDEFAULT : bm_per;
    }
    $("#benchInfo :input[name='bm_veg_pct']").val(bm_per);
    $("#benchInfo :input[name='thr_veg_pct']").val(thr_per);
  };

  var validateThreshMinMax = function (field) {
    var threshMin = $("#benchInfo input[name='thr_veg_min']").val();
    var threshMax = $("#benchInfo input[name='thr_veg_max']").val();
    //check if both fields have been entered before validating (zero is valid)
    if (threshMax >= 0 && threshMax.length && threshMin >= 0 && threshMin.length) {
      if (threshMax <= threshMin) {
        MessageModal.showSimpleWarningModal(
          "Threshold maximum cannot exceed the threshold minimum, please re-enter values.",
        );
        $("#benchInfo input[name='" + field + "']").val(null);
        return false;
      }
    }
    return true;
  };

  var validateVegCover = function (bmpType, field) {
    var type = bmpType.replace(/\s/g, "", bmpType).toLowerCase();
    var benchVeg = $("#benchInfo input[name='bm_veg_pct']").val();
    var threshVeg = $("#benchInfo input[name='thr_veg_pct']").val();
    const greenRoof = "greenroof";
    const bioswale = "bioswale";
    if (benchVeg && benchVeg.length && threshVeg && threshVeg.length) {
      if (type == bioswale || type === greenRoof) {
        if (parseFloat(benchVeg) <= parseFloat(threshVeg)) {
          const typeName = type === greenRoof ? "Green Roof" : "Bioswale";
          MessageModal.showSimpleWarningModal(
            `For ${typeName} BMPs the threshold % cannot exceed benchmark %. Please re-enter values.`,
          );
          $("#benchInfo input[name='" + field + "']").val(null);
          return false;
        }
      } else {
        if (parseFloat(benchVeg) >= parseFloat(threshVeg)) {
          MessageModal.showSimpleWarningModal(
            "For this type of BMP, the benchmark % cannot exceed or equal threshold %. Please re-enter values.",
          );
          $("#benchInfo input[name='" + field + "']").val(null);
          return false;
        }
      }
    }
    return true;
  };

  var checkFootprintWqcapInputs = function () {
    return !(
      $("select#bench-thresh-dropdown option:checked").val() === "1" &&
      (!$("#bench-thresh-footprint").val() || !$("#bench-thresh-treatment").val())
    );
  };

  var blockDisplayedData = function () {
    MessageModal.showSimpleWarningModal(
      "You cannot select Staff Plate for this BMP, because either Treatment Capacity or Footprint has no value. Please select Stadia Rod, or return to the inventory to enter the values.",
    );
  };

  //@TODO: clean req checks & check for depth & bench records
  var validateBenchmarkFields = function () {
    var invalidDataGroups = [];
    var invalidInputs = [];
    var current = Tree.get(["bmpram", "bmp"]);
    var groupId = Tree.get("activeGroup", "groupId");
    var tempSaveInfs = Tree.get(["bmpram", "tempSaveInfs"]);

    //check if there is depth
    if (
      $benchmarkForm.find(".depth-container").is(":visible") &&
      $benchmarkForm.find(".depth-container").children(":visible").length == 0
    ) {
      invalidDataGroups.push("mat-accum");
    }
    // Check if there is infiltration rate
    if ($(".bench-field-group.infiltration-group").is(":visible") && !current["bm_inf_rate"]) {
      invalidDataGroups.push("infiltration");
    }

    if (!current.bm_id) {
      current["bm_id"] = Tree.get(["bmpram", "benchmarkData"])[0].bm_id;
    }

    // Check for all inputs and selects except ".infiltration-group, .mat-accum-group"
    const $filteredGroups = $("#benchInfo").find(
      ".bench-field-group:not(.infiltration-group, .mat-accum-group)",
    );
    $filteredGroups.find("input").each(function (index, element) {
      if ($(element).is(":visible") && !element.checkValidity()) {
        invalidInputs.push(element.name);
      }
    });

    $filteredGroups.find("select").each(function (index, element) {
      if ($(element).is(":visible") && $(element).val() == "0") {
        invalidInputs.push(element.name);
      }
    });

    if (groupByLocation(tempSaveInfs).some((loc) => loc.length === 1)) {
      MessageModal.showSimpleWarningModal("There must be at least 2 records for each Location ID.");
    } else if (invalidDataGroups.length > 0 || invalidInputs.length > 0) {
      CommonModalFunctions.removeHighlights($benchmarkForm);
      for (const group of invalidDataGroups) {
        CommonModalFunctions.highlight($benchmarkForm.find("[data-field-group='" + group + "']"));
      }
      for (const name of invalidInputs) {
        CommonModalFunctions.highlightParent($benchmarkForm.find("[name='" + name + "']"));
      }

      invalidDataGroups = invalidDataGroups.map((group) => FormConstants.dataGroupLabels[group]);
      invalidInputs = invalidInputs.map((select) => FormConstants.bmpBenchmarkLabels[select]);

      SaveIncompleteModal.showConfirmSaveIncompleteModal(
        current.bmpName,
        "benchmark",
        invalidDataGroups.concat(invalidInputs),
        function () {
          saveBenchmark(groupId, current, false);
        },
      );
    } else {
      saveBenchmark(groupId, current, true);
    }
  };

  // TODO: Merge insert and update
  var saveBenchmark = function (groupId, current, isComplete) {
    resetBenchmarkForm();

    current = removeNullFields(current);
    current = removeInvFields(current);
    current["complete"] = isComplete;
    current["active"] = true;
    if (Tree.get(["bmpram", "benchmarkUpdate"])) {
      ApiCalls.updateBenchmark(current, function () {
        saveBenchmarkTempRecords(current["bm_id"]);
        MainMap.reloadBmpFcsLayer();
        PopupPhotos.saveTempPhotos("bench", current["bm_id"]);
      });
    } else {
      ApiCalls.saveNewBenchmark(current, function (bm_id) {
        saveBenchmarkTempRecords(bm_id);
        MainMap.reloadBmpFcsLayer();
        PopupPhotos.saveTempPhotos("bench", bm_id);
      });
    }

    Tree.set(["bmpram", "bmp"], {});
    closeBenchmarkModal();
  };

  var closeBenchmarkModal = function () {
    $benchmarkModal.modal("hide");
    Analytics.sendScreenView();
  };

  var saveBenchmarkTempRecords = function (benchmarkId) {
    var groupId = Tree.get("activeGroup", "groupId");
    var tempSaveDepths = Tree.get(["bmpram", "tempSaveDepths"]);
    var tempDeleteDepthIds = Tree.get(["bmpram", "tempDeleteDepthIds"]);
    var tempSaveInfs = Tree.get(["bmpram", "tempSaveInfs"]);
    var tempDeleteInfIds = Tree.get(["bmpram", "tempDeleteInfIds"]);

    if (tempSaveDepths && tempSaveDepths.length) {
      ApiCalls.saveBenchmarkDepths(tempSaveDepths, benchmarkId, groupId, function () {
        Tree.set(["bmpram", "tempSaveDepths"], []);
      });
    }
    if (tempDeleteDepthIds && tempDeleteDepthIds.length) {
      ApiCalls.deleteBenchmarkDepths(tempDeleteDepthIds, groupId, function () {
        Tree.set(["bmpram", "tempDeleteDepthIds"], []);
      });
    }

    if (tempSaveInfs && tempSaveInfs.length) {
      ApiCalls.saveBenchmarkInfs(tempSaveInfs, benchmarkId, groupId, function () {
        Tree.set(["bmpram", "tempSaveInfs"], []);
      });
    }
    if (tempDeleteInfIds && tempDeleteInfIds.length) {
      ApiCalls.deleteBenchmarkInfs(tempDeleteInfIds, groupId, function () {
        Tree.set(["bmpram", "tempDeleteInfIds"], []);
      });
    }
  };

  var handleExtraBenchmarkFields = function (typeNumber) {
    var currentBmpFcs = Tree.get("currentBmpFcs");
    var requiredFields = [];
    var optionalFields = [];
    $(".bench-field-group.type-specific").hide();

    if (currentBmpFcs.system_access === false && currentBmpFcs.bmpType !== 20) {
      requiredFields = getSpecialCaseBenchmarkFields(currentBmpFcs);
    } else {
      const extraBenchmarkFieldsByType = getExtraBenchmarkFieldsByType(typeNumber);
      requiredFields = extraBenchmarkFieldsByType.required;
      optionalFields = extraBenchmarkFieldsByType.optional;
      toggleFootprintWqcapByType(typeNumber, $benchmarkForm);
    }

    if (requiredFields.length) {
      FormFunctions.handleExtraRequiredFields(requiredFields, "#benchInfo", "data-field-group");
    }
    if (optionalFields.length) {
      FormFunctions.handleExtraOptionalFields(optionalFields, "#benchInfo", "data-field-group");
    }
  };

  var getExtraBenchmarkFieldsByType = function (typeNumber) {
    const extraBenchmarkFieldsByType = FormConstants.EXTRA_BENCHMARK_FIELDS_BY_TYPE;
    if (extraBenchmarkFieldsByType[typeNumber]) {
      return extraBenchmarkFieldsByType[typeNumber];
    }
    return { required: [], optional: [] };
  };

  var getSpecialCaseBenchmarkFields = function (currentBmpFcs) {
    var obsPortMatAccum = currentBmpFcs.inv_sedacc;
    var obsPortStdWater = currentBmpFcs.inv_stwat;

    if (obsPortMatAccum == 1) {
      return ["mat-accum"];
    } else if (obsPortMatAccum == 0 && obsPortStdWater == 0) {
      return [];
    }
    return [];
  };

  var removeNullFields = function (dataArray) {
    var validArray = {};
    for (var key in dataArray) {
      if (dataArray[key] !== null) {
        validArray[key] = dataArray[key];
      }
    }
    delete validArray["bmp_type"];
    return validArray;
  };

  // @TODO: Add a remove read-only fields method
  var removeInvFields = function (dataArray) {
    if (dataArray.hasOwnProperty("bmp_wqcap")) {
      delete dataArray["bmp_wqcap"];
    }
    if (dataArray.hasOwnProperty("bmp_inlets")) {
      delete dataArray["bmp_inlets"];
    }
    if (dataArray.hasOwnProperty("bmp_outlets")) {
      delete dataArray["bmp_outlets"];
    }
    delete dataArray["bmp_footprint"];
    return dataArray;
  };

  var removeReadOnlyFields = function (containerSelector, dataArray) {
    const $container = $(containerSelector);
    for (var key in dataArray) {
      if (
        $container
          .find(`input[name="${key}"]`)
          .closest(".info-input, fieldset")
          .hasClass("read-only")
      ) {
        delete dataArray[key];
      }
    }
    return dataArray;
  };

  var getThreshDepth = function (benchmarkData, bmpData, depthArray) {
    const BIO_THRESH = 0.16;
    const SED_THRESH = 0.999;
    var threshDepth = null;
    var bmpType = bmpData["bmp_type"];
    var depthType = depthArray[0].bm_dpth_type;
    if (FormConstants.bmpTypesUsingBioThresh.includes(bmpType)) {
      threshDepth = BIO_THRESH;
    } else if (bmpType == "Sediment Trap") {
      threshDepth = SED_THRESH;
    } else {
      if (depthType == 1) {
        // staff plate
        threshDepth =
          (parseFloat(bmpData["bmp_wqcap"]) / parseFloat(bmpData["bmp_footprint"])) *
            parseFloat(bmpData["thr_dpth_cap"]) +
          parseFloat(benchmarkData[0]["bm_depth_ft"]);
      } else if (depthType == 2) {
        // stadia rod
        threshDepth =
          parseFloat(bmpData["thr_dpth_cap"]) * parseFloat(benchmarkData[0]["bm_depth_ft"]);
      }
    }
    return threshDepth;
  };

  var getAverageDepth = function (depthArray) {
    var averageDepth = 0;
    var $observationContainer = $("#bmp-observation-form .depth-container");
    var $benchmarkContainer = $benchmarkForm.find(".depth-container");
    $benchmarkContainer.empty();
    $observationContainer.empty();

    for (var i = 0; i < depthArray.length; i++) {
      if ($benchmarkContainer.is(":visible")) {
        renderDepthRecord(
          depthArray[i].dpth_id,
          depthArray[i].dpth_desc,
          getDepthTypeName(depthArray[i].bm_dpth_type),
          depthArray[i].dpth_unit
            ? UnitConversion.convertVal(depthArray[i].bm_dpth, "feet", depthArray[i].dpth_unit)
            : "—",
          depthArray[i].dpth_unit
            ? UnitConversion.getUnitAbbreviation(depthArray[i].dpth_unit)
            : "—",
        );
        CommonModalFunctions.toggleDisabledInputs($benchmarkContainer);
        averageDepth += parseFloat(depthArray[i].bm_dpth);
      } else if ($observationContainer.is(":visible")) {
        const depthHtml = nunjucks.render("modals/bmpObservation/legacyObsDepth.njk", {
          depth: {
            depthId: depthArray[i].dpth_id,
            bm_depth_id: depthArray[i].bm_depth_id ?? depthArray[i].dpth_id,
            depthDesc: depthArray[i].dpth_desc,
            depthTypeName: getDepthTypeName(depthArray[i].bm_dpth_type),
            depthValue: depthArray[i].dpth_unit
              ? UnitConversion.convertVal(
                  parseFloat(depthArray[i].obs_dpth_msmt),
                  "feet",
                  depthArray[i].dpth_unit,
                )
              : "—",
            depthUnit: depthArray[i].dpth_unit ? depthArray[i].dpth_unit : "—",
            depthUnitAbbr: depthArray[i].dpth_unit
              ? UnitConversion.getUnitAbbreviation(depthArray[i].dpth_unit)
              : "—",
          },
        });
        $observationContainer.append(depthHtml);
        CommonModalFunctions.toggleDisabledInputs($observationContainer);

        if (depthArray[i].obs_dpth_msmt) {
          averageDepth += parseFloat(depthArray[i].obs_dpth_msmt);
        }
      }
    }

    if (averageDepth) {
      averageDepth = averageDepth / depthArray.length;
    }
    return averageDepth;
  };

  var renderDepthRecord = function (depthId, depthDesc, depthTypeName, depthValue, depthUnit) {
    const depthHtml = nunjucks.render("modals/benchmark/depth.njk", {
      depth: {
        depthId: depthId,
        depthDesc: depthDesc,
        depthTypeName: depthTypeName,
        depthValue: depthValue,
        depthUnit: depthUnit,
      },
    });
    $benchmarkForm.find(".depth-container").append(depthHtml);
  };

  var handleDepthTypeOption = function (depthArray) {
    // Temp fix for letting user select only one depth type for one benchmark
    if (depthArray) {
      if (depthArray.length) {
        const selectedDepthType = depthArray[0].bm_dpth_type;
        setFixedDepthTypeOption(selectedDepthType);
      } else {
        const $depthTypeSelect = $benchmarkForm.find("select[name='bm_dpth_type']");
        if ($depthTypeSelect.data("fixedOnly") == 0) {
          resetDepthForm();
        }
      }
    }
  };

  var setFixedDepthTypeOption = function (depthType) {
    var $depthTypeSelect = $benchmarkForm.find("select[name='bm_dpth_type']");
    var disabledOption = null;
    if (depthType == 1) {
      disabledOption = 2;
    } else if (depthType == 2) {
      disabledOption = 1;
    }
    Misc.toggleDisabled($depthTypeSelect.find("option[value='" + disabledOption + "']"), true);
    Tree.set(["bmpram", "depth", "bm_dpth_type"], depthType);
    $depthTypeSelect.val(depthType);
  };

  var getDepthTypeName = function (depthType) {
    return depthType == 1 ? "Staff Plate" : depthType == 2 ? "Stadia Rod" : "—";
  };

  var deleteDepth = function (e) {
    var depthId = $(this).closest(".depth-record").data("depthid");

    MessageModal.showDeleteRecordModal(() => {
      const tempDeleteDepthIdsCursor = Tree.select(["bmpram", "tempDeleteDepthIds"]);
      const tempSaveDepthsCursor = Tree.select(["bmpram", "tempSaveDepths"]);
      handleTempDeleteRecord("dpth_id", depthId, tempDeleteDepthIdsCursor, tempSaveDepthsCursor);
    });
  };

  var deleteInf = function (e) {
    var infId = $(this).closest(".inf-record").data("infid");

    MessageModal.showDeleteRecordModal(() => {
      const tempDeleteInfIdsCursor = Tree.select(["bmpram", "tempDeleteInfIds"]);
      const tempSaveInfsCursor = Tree.select(["bmpram", "tempSaveInfs"]);
      handleTempDeleteRecord("bm_inf_id", infId, tempDeleteInfIdsCursor, tempSaveInfsCursor);
    });
  };

  var handleTempDeleteRecord = function (
    recordIdName,
    recordIdToDelete,
    tempDeleteRecordIdsCursor,
    tempSaveRecordsCursor,
  ) {
    var tempSaveRecords = tempSaveRecordsCursor.get();

    for (let i = 0; i < tempSaveRecords.length; i++) {
      const record = tempSaveRecords[i];
      if (record[recordIdName] == recordIdToDelete) {
        tempSaveRecordsCursor.splice([i, 1]);

        if (!isNaN(record[recordIdName])) {
          tempDeleteRecordIdsCursor.push(recordIdToDelete);
        }
        break;
      }
    }
  };

  var isBenchmarkDateValid = function (date) {
    var currentBmpFcs = Tree.get("currentBmpFcs");
    var currentBenchmarkDate = currentBmpFcs.currentBenchmarkDate;
    var currentObsDate = currentBmpFcs.currentObsDate;
    var bmpScoreObsDate = currentBmpFcs.bmpScoreObsDate;
    var datesData = {};

    if (currentBmpFcs.skipObservation && currentBenchmarkDate) {
      datesData.earlierDate = currentBenchmarkDate;
      datesData.earlierDateName = "Last benchmark date";
    } else if (currentObsDate) {
      datesData.earlierDate = currentObsDate;
      datesData.earlierDateName = "Last observation date";
    } else if (bmpScoreObsDate) {
      datesData.earlierDate = bmpScoreObsDate;
      datesData.earlierDateName = "Last observation date";
    } else if (currentBenchmarkDate) {
      datesData.earlierDate = currentBenchmarkDate;
      datesData.earlierDateName = "Last benchmark date";
    } else {
      return true;
    }

    datesData.laterDate = date;
    datesData.laterDateName = "Current benchmark date";
    return validateDate(datesData);
  };

  var validateDate = function (datesData) {
    var laterDate = new Date(datesData.laterDate.split(" ")[0]);
    var earlierDate = new Date(datesData.earlierDate.split(" ")[0]);
    var laterDateName =
      datesData.laterDateName.charAt(0).toUpperCase() + datesData.laterDateName.slice(1);
    var earlierDateName =
      datesData.earlierDateName.charAt(0).toLowerCase() + datesData.earlierDateName.slice(1);

    if (laterDate < earlierDate) {
      MessageModal.showSimpleWarningModal(
        laterDateName +
          " cannot be earlier than " +
          earlierDateName +
          ": " +
          earlierDate.toLocaleDateString() +
          ".",
      );
      return false;
    }
    return true;
  };

  return {
    init,
    showNewBenchmarkModal,
    showEditBenchmarkModal,
    getFormattedDateTime,
    removeInvFields,
    removeNullFields,
    removeReadOnlyFields,
    getDepthTypeName,
    validateDate,
  };
};

module.exports = Benchmark();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const BmpFunctions = require("./bmpFunctions");
const CommonModalFunctions = require("./commonModalFunctions");
const FormConstants = require("../mapFunctions/formConstants");
const FormFunctions = require("../mapFunctions/formFunctions");
const MainMap = require("../mapFunctions/mainMap");
const MessageModal = require("./messageModal");
const SaveIncompleteModal = require("./saveIncompleteModal");
const Misc = require("../misc");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const Tree = require("../tree");
const UnitConversion = require("../unitConversion");
const BmpObservation = require("./bmpObservation");
