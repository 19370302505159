"use strict";

var BasemapFunctions = function () {
  var basemapListeners = [];

  var setBasemap = function (style, mapId) {
    const config = getBasemapConfigByMapId(mapId);
    sessionStorage.setItem(config.key, style);

    _setBasemapHtml(style, mapId);
    triggerBasemapChangeListeners(style, mapId);
  };

  var _setBasemapHtml = function (style, mapId) {
    setBasemapSelection(style, mapId);
    handleDisplayWhiteText(style, mapId);
  };

  var triggerBasemapChangeListeners = function (style, mapId) {
    for (const listenerObj of basemapListeners) {
      if (listenerObj.mapId === mapId) {
        listenerObj.fn(style);
      }
    }
  };

  var getBasemap = function (mapId, updateHtml = false) {
    const config = getBasemapConfigByMapId(mapId);
    let style = sessionStorage.getItem(config.key);

    if (style === null || PageController.getPageName() === "portfolio") {
      style = config.defaultValue;
    }
    if (updateHtml) {
      _setBasemapHtml(style, mapId);
    }

    return style;
  };

  var getBasemapConfigByMapId = function (mapId) {
    const overRide = DataViewFunctions.getCurrentDataViewProperty("defaultModalMap");
    const baseMap = Tree.get("baseMap");
    const defaultStyle = "Imagery";
    const mapIdToBasemapConfigMap = {
      main: { key: "basemapSelection", defaultValue: baseMap },
      modal: {
        key: "basemapModalSelection",
        defaultValue: overRide ? overRide : defaultStyle,
      },
    };

    const config = mapIdToBasemapConfigMap[mapId];

    if (!config) {
      throw new Error(`Unknown basemap map ID "${mapId}".`);
    }

    return config;
  };

  var setBasemapSelection = function (style, mapId) {
    var $legendDiv = mapId === "modal" ? $("#modal-legend") : $("#main-legend");
    var $selectedLi = $legendDiv
      .find(`.basemap-dropdown-menu a[data-style="${style}"]`)
      .closest("li");

    $legendDiv
      .find(".basemap-button")
      .html(`${$selectedLi.text().trim()} <span class="glyphicon glyphicon-menu-down"></span>`);
    Dropdown.select($selectedLi);
  };

  var handleDisplayWhiteText = function (style, mapId = null) {
    let whiteTextSet;
    if (mapId === "modal") {
      whiteTextSet = [];
    } else {
      whiteTextSet = ["Imagery", "DarkGray", "Hybrid"];
    }

    const useWhiteText = whiteTextSet.includes(style);
    MapTitle.toggleUseWhiteText(useWhiteText);
  };

  var addBasemapChangeListener = function (fn, mapId) {
    basemapListeners.push({ fn, mapId });
  };

  return {
    setBasemap,
    getBasemap,
    addBasemapChangeListener,
  };
};

module.exports = BasemapFunctions();

const MapTitle = require("./mapTitle");
const Dropdown = require("../general/dropdown");
const DataViewFunctions = require("../dataViewFunctions");
const PageController = require("../esg/pageController");
const Tree = require("../tree");
