"use strict";

const StructuralBmpsPopupGeoServerLayer = function () {
  var getPopupHtml = function (properties) {
    return PopupContent.getLayerPopup(properties, "structuralBmps");
  };

  var onPopupOpen = async function (properties) {
    const id = properties.id ?? properties.idbmp;
    var data = await getPopupData(id);
    const html = getPopupHtml(data);
    Tree.set(["layers", "structuralBmps", "selectedId"], id);
    $(".leaflet-popup-content").html(html);
    const $popup = $(".leaflet-popup-content");
    const layerModule = LayerFunctions.getLayerModule("structuralBmps");
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
    highlightOnLeftHandTableInMapViewOnly(id);
    unHighlightOnLeftHandTableInMapViewOnly();
  };

  var loadAndShowPopupPhotos = function () {
    const idBmp = $(".popup-show-photos-bmp").data("id-bmp");
    PopupPhotos.load("bmp", idBmp);

    $(".popup-main-pane").hide();
    $(".popup-back-button").show();
    $(".popup-photos-pane").show();

    Analytics.sendPopupEvent("view_photos", "bmp");
  };

  var unHighlightOnLeftHandTableInMapViewOnly = function () {
    $(".leaflet-popup-close-button").on("click", function () {
      Table.removeMapHoverHighlight();
    });
  };

  var highlightOnLeftHandTableInMapViewOnly = function (id) {
    var $tblMapView = $("#bottomFloatingInputsTable");
    var $tableItem = $tblMapView.find('[data-id="' + id + '"]');
    if ($tableItem) {
      Table.scrollTo($tblMapView, $tableItem);
      Table.highlightInTable($tableItem);
    }
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      if (data.isFcs) {
        deleteFcsAndOrBmp(data, data.id, invalidateLayerDataFunction);
      } else {
        deleteBmp(data.id, data.bmpName, invalidateLayerDataFunction);
      }
    };

    $element.off("click", ".sbmp-popup-delete-bmp", onPopupDelete);
    $element.on("click", ".sbmp-popup-delete-bmp", onPopupDelete);

    $element.off("click", ".sbmp-popup-delete-fcs", onPopupDelete);
    $element.on("click", ".sbmp-popup-delete-fcs", onPopupDelete);

    $element.off("click", ".download-report", onPopupDownload);
    $element.on("click", ".download-report", onPopupDownload);
  };

  var onPopupDownload = function () {
    var data = $(this).closest("tr").data();

    if (data.assetType === "bmp") {
      Analytics.sendPopupEvent("download_report", "bmp");
      ApiCalls.downloadBmpObservationPdf(data.obs_id);
    } else {
      throw new Error(`No download defined for ${data.assetType}`);
    }
  };

  var deleteBmp = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete BMP ${name}? Click "Delete" to permanently delete the BMP. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteBmp(id);
        invalidateLayerDataFunction();
        _reloadAndClosePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "bmp");
  };

  var deleteFcsAndOrBmp = function (popupData, idBmp, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showThreeButtonConfirmWarningModal(
      `This structure: ${popupData?.bmpName} is both an FCS and a structural BMP. To delete it as an FCS, but keep it as a BMP in 2NFORM, click Delete FCS. Note, this will also delete the selected treated area polygons. To permanently delete this structure from your 2NFORM data as both an FCS and BMP., click Delete Asset. To return to the form without deleting, click Cancel.`,
      function () {
        BmpFunctions.deleteFcs(idBmp);
        invalidateLayerDataFunction();
      },
      function () {
        ApiCalls.deleteBmp(idBmp).then(function () {
          _reloadAndClosePopup(popupCloseButn);
        });
      },
      "Cancel",
      "Delete Fcs",
      "Delete Asset",
    );
  };

  var _reloadAndClosePopup = function (popupCloseButn) {
    StructuralBmpsGeoServerLayer.loadLayer();
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var getPopupData = async function (id) {
    const data = await ApiCalls.getBmpPopup(id);
    data.layerName = "structuralBmps";
    data.userPermissions = UserPermissions.getAllPermissions();
    Tree.set("bmpFcsAssessmentHistory", data.history);

    Tree.set("currentBmpFcs", data);

    const year = Tree.get("waterYear");
    const markerData = LegacyBmpFcs.prepareBmpMarkerData(data, "structuralBmps", year);
    const popupProps = LegacyBmpFcs.getPopupRenderingProps(markerData, "structuralBmps", year);

    popupProps.popupColor = BmpFcsIcon.getIconColor(markerData.bmpMapScore, markerData.phase);

    MuniCatchBasinPopup.addInspectionPermissions(data, popupProps);
    const markerDataAndPopupProps = $.extend({}, markerData, popupProps);

    return markerDataAndPopupProps;
  };

  return {
    getPopupHtml,
    onPopupOpen,
    getPopupData,
    loadAndShowPopupPhotos,
    onPopupDownload,
  };
};

module.exports = StructuralBmpsPopupGeoServerLayer();

const ApiCalls = require("../apiCalls");
const PopupContent = require("../mapFunctions/popupContent");
const LayerFunctions = require("../layerFunctions");
const MessageModal = require("../modals/messageModal");
const UserPermissions = require("../login/userPermissions");
const Analytics = require("../general/analytics");
const Tree = require("../tree");
const LegacyBmpFcs = require("../bmpfcs/legacyBmpFcs");
const MuniCatchBasinPopup = require("../muni/muniCatchBasinPopup");
const BmpFunctions = require("../modals/bmpFunctions");
const Table = require("../mapFunctions/table");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const StructuralBmpsGeoServerLayer = require("./structuralBmpsGeoServerLayer");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
