"use strict";

const AssetsOpenChannelGeoServerDynamicMapLayer = function () {
  const layerName = "assetsOpenChannelEsriDynamicMap";
  const layerKey = "assetsOpenChannelEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsOpenChannelEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsOpenChannelEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsOpenChannelEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };
  var loadLayer = function () {};

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = AssetsOpenChannelGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
