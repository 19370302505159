"use strict";

const BaseEnforcementAction = function (EnforcementController, { getPropsFunction = null } = {}) {
  const stringKey = `enforcement-action`;
  const tabHeader = "Enforcement Action";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("change", "[name='enforcement-reason']", _toggleReasonFields);
    $page.on("change", "[name='escalation-action']", _toggleEscalationFields);
  };

  var unloadListeners = function () {
    $page.off("change", "[name='enforcement-reason']", _toggleReasonFields);
    $page.off("change", "[name='escalation-action']", _toggleEscalationFields);
  };

  var getProps = function (data) {
    data.staffName = data?.staffName || Session.loadContext().full_name;
    return {
      existingEnforcement: data?.id,
      enforcementLetterOptions: [],
      escalationActionOptions: [
        { name: "Keep Open", value: "keep-open" },
        { name: "Escalate", value: "escalate" },
        { name: "Resolve", value: "resolve" },
      ],
      ...(getPropsFunction?.(data) ?? {}),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var _toggleReasonFields = function () {
    const currentReason = $('select[name="enforcement-reason"]').val();
    $page.find("[data-reason]").addClass("hidden");
    $page.find(`[data-reason~=${currentReason}]`).removeClass("hidden");
  };

  var _toggleEscalationFields = function () {
    const currentAction = $('select[name="escalation-action"]').val();
    if (currentAction === "keep-open") {
      $page.find("[name=level]").attr("disabled", true);
      _hideResolvedFields(false);
      _resetEscalationLevelSelection();
      _setIssueDateText("Keep Open Date");
    } else if (currentAction === "escalate") {
      $page.find("[name=level]").attr("disabled", false);
      _hideResolvedFields(false);
      _setIssueDateText("Escalate Date");
    } else if (currentAction === "resolve") {
      _hideResolvedFields(true);
      _setIssueDateText("Resolved Date");
    }
  };

  var _hideResolvedFields = function (toggle) {
    $page.find("[name=level], [name=fine]").parents("fieldset").toggleClass("hidden", toggle);
    $page.find(".reason-section").toggleClass("hidden", toggle);
  };

  var _resetEscalationLevelSelection = function () {
    const existingEscalationLevel = EnforcementController.getExistingData()?.level ?? null;
    $page.find("[name=level]").val(existingEscalationLevel);
    EnforcementController.setFormField("level", existingEscalationLevel);
  };

  var _setIssueDateText = function (newText) {
    $page.find("[name=issue-date]").siblings("legend").text(newText);
  };

  return {
    unloadListeners,
    loadListeners,
    getProps,
    getStringKey,
    getTabHeader,
    _toggleReasonFields,
  };
};

module.exports = BaseEnforcementAction;

const Session = require("../login/session");
