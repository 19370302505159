"use strict";

const RoutineMaintenanceConfig = function () {
  let maintenanceTaksByKey = null;

  const init = function (newData) {
    maintenanceTaksByKey = newData;
  };

  const _getMaintenanceTaks = function (key) {
    if (maintenanceTaksByKey === null) {
      throw new Error(`Routine maintenance config is not initialized.`);
    }
    return maintenanceTaksByKey[key];
  };

  const getConfiguredMaintenanceTasks = function (key) {
    const enabledMaintenanceIssues = _getMaintenanceTaks(key);

    if (enabledMaintenanceIssues === undefined) {
      throw new Error(`"${key}" is not a known maintenance task.`);
    }

    return enabledMaintenanceIssues;
  };

  return {
    init,
    getConfiguredMaintenanceTasks,
  };
};

module.exports = RoutineMaintenanceConfig();
