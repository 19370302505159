"use strict";

const MuniBmpDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareMuniBmps,
        template: "list/muniBmpDataList.njk",
        layerName: Tree.get("dataView") === "lid-bmp" ? "lidBmp" : "muniBmp",
      };
      DataList.init(config);
      initialRender();
      loadListeners();
      DataList.loadDomListeners();
    }
  };

  var loadListeners = function () {
    $(".data-list-container").off("click", ".map-button");
    $(".data-list-container").on("click", ".map-button", showMarkerOnMap);
  };

  var showMarkerOnMap = function () {
    var $tr = $(this).parents("tr");
    var id = $tr.data("id");

    MapFunctions.openPopupById(id);
    const mapButtonContainer = $(this).parents("td");
    DataList.addAnalyticsToMapIconOnAddress(mapButtonContainer.hasClass("address-col"));
  };

  var initialRender = function () {
    DataList.setSorting("bmpName", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareMuniBmps = function (muniBmps) {
    sortMuniBmpsBy(muniBmps);

    for (const bmp of muniBmps) {
      bmp.inletTypeDisplayName = Misc.getOptionNameByValue(
        BmpFcsInventoryConstants.inletTypeOptions,
        bmp.inletType,
      );
      bmp.iconClass = MuniBmpIcon.getIconInfo(
        bmp?.bmpTypeObj?.isCentralized,
        bmp?.condition,
        bmp?.isDue,
        bmp?.bmpTypeObj?.abbreviation,
      );
      _addInspectionOrRepairActionDropdown(bmp);
    }
    return {
      rows: muniBmps,
      options: {},
    };
  };

  var _addInspectionOrRepairActionDropdown = function (bmp) {
    bmp.showRoutineMaintenanceButton =
      bmp.permissions.canCreateRoutineInspection && bmp.bmpDisplayType !== "Unknown" && !!bmp.phase;
  };

  var sortMuniBmpsBy = function (muniBmps) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;

    var sortersDict = {
      bmpName: alphaSorter,
      address: alphaSorter,
      bmpDisplayType: alphaSorter,
      cof: SortUtilities.cofSorter,
      treatmentCapacity: numberSorter,
    };
    DataList.sortWithDict(muniBmps, sortersDict);
  };

  return {
    init,
    _prepareMuniBmps,
    render,
    _addInspectionOrRepairActionDropdown,
  };
};

module.exports = MuniBmpDataListController();

const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const DataList = require("../mapFunctions/dataList");
const SortUtilities = require("../general/sortUtilities.js");
const Misc = require("../misc.js");
const MuniBmpIcon = require("../muni/bmp/muniBmpIcon");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
