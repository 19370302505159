"use strict";

const SimpleOutfallGeoServerDynamicMapLayer = function () {
  const layerName = "simpleOutfallGeoServerDynamicMap";
  const layerKey = "simpleOutfallGeoServerDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var simpleOutfallGeoServerDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    simpleOutfallGeoServerDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    simpleOutfallGeoServerDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return data;
  };

  return {
    loadLayerListenersForMap,
    getLayerData,
  };
};

module.exports = SimpleOutfallGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
