"use strict";

const OutfallInventoryGeneralInfo = function () {
  const stringKey = "outfall-general-info";
  const headerInformation = "General info";
  let $page;
  let $inventoryModal;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    await renderPageHtml();
  };

  var onOutletShapeChange = function (e, outletShape) {
    toggleOutfallDimensionsByShape(
      outletShape,
      $page,
      $page.find(`[name="outlet-diameter"]`),
      $page.find(`[name="outlet-length"]`),
      $page.find(`[name="outlet-width"]`),
    );
  };

  var onOutletMaterialChange = function (e, outletMaterial) {
    toggleOutfallMaterialChange($page, outletMaterial);
  };

  var toggleOutfallMaterialChange = function ($page, outletMaterial) {
    toggleOutfallOtherField($page, outletMaterial, "other-material");
  };

  var toggleOutfallDimensionsByShape = function (
    outletShape,
    $page,
    $diameterInput,
    $lengthInput,
    $widthInput,
  ) {
    const isCircular = OutfallInspectionConstants.outletShapeIsCircular(outletShape);

    $diameterInput.closest("fieldset").toggle(isCircular);
    $lengthInput.closest("fieldset").toggle(!isCircular);
    $widthInput.closest("fieldset").toggle(!isCircular);

    toggleOutfallOtherField($page, outletShape, "other-shape");
  };

  var toggleOutfallOtherField = function ($page, fieldValue, fieldToToggle) {
    const toggleFieldVisibility = fieldValue !== "other";
    $page.find(`.${fieldToToggle}`).toggleClass("hidden", toggleFieldVisibility);
  };

  var loadOutfallGeneralInfoListeners = function () {
    $page.on("2N:FormInput", `[name="outlet-shape"]`, onOutletShapeChange);
    $page.on("2N:FormInput", `[name="outlet-material"]`, onOutletMaterialChange);
    Form.initializeAndLoadListeners($page, "outfall-inventory", { isMultiPart: true });
  };

  var initializeDropzones = function (files) {
    const existingFiles = getExistingFiles();

    Form.initializeDropzone("outfall-inventory", $inventoryModal, {
      newFiles: getNewFiles(),
      existingFiles: existingFiles,
      maxNumberFiles: 9,
      downloadCallback: ApiCalls.downloadOutfallFile,
    });
  };

  var getExistingFiles = function () {
    const files = OutfallInventoryModalController.getExistingData(["additionalFiles"]) ?? [];
    const removedFiles =
      OutfallInventoryModalController.getLatestOutfallInventoryData([
        "trashedFiles",
        "additionalFiles",
      ]) ?? [];
    return files.filter((file) => !removedFiles.includes(file));
  };

  var getNewFiles = function () {
    return Form.getFormDataAtPath("outfall-inventory", ["additionalFiles"]);
  };

  var removeListeners = function () {
    $page.off("2N:FormInput", `[name="outlet-shape"]`, onOutletShapeChange);
  };

  var renderPageHtml = function () {
    const renderData = OutfallInventoryModalController.getInventoryForRender();
    const additionalProps = getAdditionalProps(renderData);
    const html = nunjucks.render("modals/outfallInventory/outfallInventoryGeneralInfo.njk", {
      ...renderData,
      ...additionalProps,
    });
    $page = $(".modal-dynamic-content");
    $inventoryModal = $("#inventory-modal");
    OutfallInventoryModalController.renderPageContent(html);
    loadOutfallGeneralInfoListeners();
    initializeDropzones(renderData?.additionalFiles);
  };

  function sortOptions(options) {
    return options.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  var getAdditionalProps = function (outfallData) {
    return {
      consequenceOfFailureOptions: OutfallInventoryConstants.consequenceOfFailureOptions,
      priorityOptions: OutfallInventoryConstants.priorityOptions,
      responsiblePartyMaintenanceOptions: OutfallInventoryConstants.eswiftEntityTypeOptions,
      ownershipOptions: OutfallInventoryConstants.eswiftEntityTypeOptions,
      landOwnershipOptions: OutfallInventoryConstants.eswiftEntityTypeOptions,
      outletMaterialOptions: sortOptions(OutfallInspectionConstants.getOutletMaterialOptions()),
      typeOptions: OutfallInspectionConstants.getOutletTypeOptions(),
      outfallTypeOptions: OutfallInspectionConstants.getOutfallTypeOptions(),
      outletShapeOptions: OutfallInspectionConstants.getOutletShapeOptions(),
      lengthUnitOptions: OutfallInspectionConstants.lengthUnitOptions,
      elevationUnitOptions: OutfallInspectionConstants.elevationUnitOptions,
      isCircular: OutfallInspectionConstants.outletShapeIsCircular(
        outfallData?.outfallInfo?.outletShape,
      ),
      datumOptions: OutfallInventoryConstants.datumOptions,
      outfallInfo: outfallData,
    };
  };

  var cleanUp = function () {
    removeListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    toggleOutfallDimensionsByShape,
    toggleOutfallMaterialChange,
    sortOptions,
  };
};

module.exports = OutfallInventoryGeneralInfo();

const OutfallInventoryConstants = require("./outfallInventoryConstants");
const OutfallInspectionConstants = require("./outfallInspectionConstants");
const OutfallInventoryModalController = require("./outfallInventoryModalController");
const Form = require("../general/form");
const ApiCalls = require("../apiCalls");
