"use strict";

const FacilityRequirements = function () {
  const stringKey = "facility-requirements";
  const tabHeader = "Facility Requirements";

  var loadListeners = function (data) {};

  var unloadListeners = function () {};

  var getProps = function (data) {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onChangeInspectionType = function ($container) {
    const searchString = $container.find("[name='inspection-type']").val() || "";
    const $selector = $container.find(".location-observation");
    const options = {
      selectorTextSelector: ".valid-inspection-types",
      parentSelector: ".card",
    };
    FilterDom.filterByText(searchString, $selector, options);
  };

  return {
    onChangeInspectionType,
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = FacilityRequirements();

const FilterDom = require("../../general/filterDom");
