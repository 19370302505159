"use strict";

const GroupSettings = function () {
  var startGroupSettingsTableListeners = function () {
    $(".group-settings-table").off("click", ".edit", editSettingClick);
    $(".group-settings-table").on("click", ".edit", editSettingClick);

    $(".group-settings-table").off("click", ".cancel", cancelSettingClick);
    $(".group-settings-table").on("click", ".cancel", cancelSettingClick);

    $(".group-settings-table").off("click", ".save", saveSettingClick);
    $(".group-settings-table").on("click", ".save", saveSettingClick);
  };

  var editSettingClick = function () {
    const $tableRow = $(this).closest("tr");

    toggleTableRowEditing($tableRow, true);
  };

  var cancelSettingClick = function () {
    const $tableRow = $(this).closest("tr");

    toggleTableRowEditing($tableRow, false);
  };

  var saveSettingClick = function () {
    const $tableRow = $(this).closest("tr");

    saveTableRowEdits($tableRow);
  };

  var toggleTableRowEditing = function ($tableRow, isEditing) {
    var report;

    if (_isGlobalRow($tableRow)) {
      report = _getGlobalGroupSettings();
    } else {
      const reportId = $tableRow.data("id");
      report = AnnualReports.getReportById(reportId);
    }
    addDisplayNames([report]);

    const editable = SubmitReport._isSubmissionEnabled();

    $tableRow.html(
      nunjucks.render("report/table/groupSettingsTableRow.njk", {
        report,
        isEditing,
        editable,
      }),
    );

    if (isEditing) {
      $tableRow.find(".date-picker").each(function (i, datePicker) {
        DateTimePicker.initialize($(datePicker));
      });
    }
  };

  var saveTableRowEdits = async function ($tableRow) {
    const reportId = $tableRow.data("id");

    var formData = {};
    formData.id = reportId;
    formData.deadline = DateTimePicker.getIsoDate($tableRow, "deadline", 23, 59, 59);
    formData.dataRangeFirst = DateTimePicker.getIsoDate($tableRow, "dataRangeFirst");
    formData.dataRangeLast = DateTimePicker.getIsoDate($tableRow, "dataRangeLast", 23, 59, 59);

    var data;
    try {
      if (_isGlobalRow($tableRow)) {
        data = await GlobalGroupSettings.editGlobalSettings(formData);
      } else {
        data = await ReportApiCalls.editAnnualReport(formData);
      }
    } catch (e) {
      handleBadDataWindow($tableRow);
      return;
    }

    if (_isGlobalRow($tableRow)) {
      updateSettings();
      return;
    } else {
      AnnualReports.updateMs4Years(data);
    }

    toggleTableRowEditing($tableRow, false);
  };

  var handleBadDataWindow = async function ($tableRow) {
    try {
      await MessageModal.showModal(
        `
        <p>The data window is invalid, please see <a href="https://kb.2ndnaturewater.com/knowledge/report-dates-and-windows" target="_blank">Data Window Rules & Validation</a> in the help center to correct the issue or press "Cancel" below to revert your changes.</p>
        <p>Or contact <a href="mailto:customersuccess@2ndnaturewater.com">CustomerSuccess@2ndnaturewater.com</a> for further assistance.</p>
        `,
        "Invalid Data Window",
        "Edit",
        "Cancel",
      );
    } catch (e) {
      toggleTableRowEditing($tableRow, false);
    }
  };

  var updateSettings = async function () {
    if (Tree.get("activeTab") !== "groupSettings") {
      return;
    }
    const AnnualReports = require("../annualReports");
    var reports = AnnualReports.getYearReports();
    reports = AnnualReports.sortReportsByDataName(reports);

    await GlobalGroupSettings.loadGlobalSettings();
    const globalGroupSettings = _getGlobalGroupSettings(reports[0].reportingYear);
    if (globalGroupSettings) {
      reports.unshift(globalGroupSettings);
    }
    addDisplayNames(reports);
    reports = filterEmptyDisplayNames(reports);

    const hasSubmittedYear = reports.some((report) => !!report.submittedOn);
    const editable = SubmitReport._isSubmissionEnabled();

    $(".group-settings-table").html(
      nunjucks.render("report/table/groupSettingsTable.njk", {
        reports,
        hasSubmittedYear,
        editable,
      }),
    );

    startGroupSettingsTableListeners();
  };

  var _getGlobalGroupSettings = function (reportingYear = AnnualReports.getSelectedYear()) {
    var groupSettings = GlobalGroupSettings.getGlobalSettings(reportingYear);

    if (!groupSettings) {
      return null;
    }

    _addFakeGlobalGroupSettingsFields(groupSettings);

    return groupSettings;
  };

  var _addFakeGlobalGroupSettingsFields = function (groupSettings) {
    groupSettings.isSettingsDefault = true;
    groupSettings.submittedOn = null;
  };

  var _isGlobalRow = function ($tableRow) {
    return $tableRow.hasClass("settings-default");
  };

  var addDisplayNames = function (reports) {
    for (const report of reports) {
      if (report.isSettingsDefault) {
        report.displayNames = ["Global Dates"];
      } else if (report.dataName) {
        report.displayNames = getDisplayNames(report.dataName);
      }
    }
  };

  var filterEmptyDisplayNames = function (reports) {
    return reports.filter((report) => report.displayNames && report.displayNames.length !== 0);
  };

  var getDisplayNames = function (dataName) {
    const settingsOnlyEntriesByDataName = {
      "trash group": ["Trash Visual Assessments"],
      "stormwater group": ["LID Runoff Condition"],
    };
    const settingsOnlyNames = settingsOnlyEntriesByDataName[dataName];
    let displayNames = PermitSettings.getDisplayPermitSectionsByDataName(dataName, {
      includePermit: true,
    });

    if (settingsOnlyNames) {
      displayNames = displayNames.concat(settingsOnlyNames);
    }

    return displayNames;
  };

  return { updateSettings };
};

module.exports = GroupSettings();

const AnnualReports = require("../annualReports");
const ReportApiCalls = require("../reportApiCalls");
const DateTimePicker = require("../general/dateTimePicker");
const GlobalGroupSettings = require("./globalGroupSettings");
const MessageModal = require("../general/messageModal");
const PermitSettings = require("./permitSettings");
const SubmitReport = require("../report/submitReport");
const Tree = require("../../tree");
