"use strict";

const MuniOutfallsFilters = function () {
  const searchFields = ["outfallName", "address"];
  const searchDbFields = ["outfall_name", "address"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          MuniOutfallsGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData, filters) {
    if (filters.toDoFilters) {
      return unfilteredData.filter((datum) => {
        return ResourceFilters.passToDoFilters(datum, filters, toDoFilters);
      });
    } else {
      return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
    }
  };

  var resetTodoFilter = function () {
    Filters.resetToDoFiltersByDataView("muni-outfalls");
  };

  var passDataTabMaintainerTypeFilter = function (datum, filters) {
    let pass = true;

    const maintainerType = !datum.maintainerType ? "null" : datum.maintainerType;
    if (filters.responsiblePartyMaintenance?.length !== undefined) {
      pass = filters.responsiblePartyMaintenance.includes(maintainerType);
    }
    return pass;
  };

  var passDataTabLandOwnershipFilter = function (datum, filters) {
    let pass = true;

    const landOwnership = !datum.landOwnership ? "null" : datum.landOwnership;
    if (filters.landOwnership?.length !== undefined) {
      pass = filters.landOwnership.includes(landOwnership);
    }
    return pass;
  };

  var passOutfallTypeFilter = function (datum, filters) {
    let pass = true;

    const outfallType = !datum.outfallType ? "null" : datum.outfallType;
    if (filters.outfallType?.length !== undefined) {
      pass = filters.outfallType.includes(outfallType);
    }
    return pass;
  };

  var passDataTabOutletTypeFilter = function (datum, filters) {
    let pass = true;

    const outletType = !datum.outletType ? "null" : datum.outletType;
    if (filters.outletType?.length) {
      pass = filters.outletType.includes(outletType);
    }
    return pass;
  };

  var passDataTabConsequenceOfFailureFilter = function (datum, filters) {
    let pass = true;

    const cof = !datum.cof ? "null" : datum.cof;
    if (filters.cof?.length) {
      pass = filters.cof.includes(cof);
    }
    return pass;
  };

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  var passOutletShapeFilter = function (datum, filters) {
    let pass = true;

    const outletShape = !datum.outletShape ? "null" : datum.outletShape;
    if (filters.shape?.length) {
      pass = filters.shape.includes(outletShape);
    }
    return pass;
  };

  var passConditionFilter = function (datum, filters) {
    let pass = true;

    const condition = !datum.condition ? "null" : datum.condition;
    if (filters.condition?.length) {
      pass = filters.condition.includes(condition);
    }
    return pass;
  };

  var passImplementationPhaseFilter = function (datum, filters) {
    let pass = true;

    const phase = !datum.phase ? "null" : datum.phase;
    if (filters.implementationPhase?.length) {
      pass = filters.implementationPhase.includes(phase);
    }
    return pass;
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }

    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings);

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementerType",
      "implementer_type",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "responsiblePartyMaintenance",
      "maintainer_type",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "landOwnership",
      "property",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "outfallType",
      "outfall_type",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "outletType",
      "outlet_type",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementationPhase",
      "phase",
    );
    addConditionFilterToGeoServerQuery(queryStrings, filters);
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "cof",
      "cof_final_text",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "shape",
      "outlet_shape",
    );
    const lengthWidthQueries = [];
    const diameterQuery = [];
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "outlet_diameter",
      filters.diameterRange,
      "outlet_diameter_units",
      diameterQuery,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "outlet_length",
      filters.lengthRange,
      "outlet_length_units",
      lengthWidthQueries,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "outlet_width",
      filters.widthRange,
      "outlet_width_units",
      lengthWidthQueries,
    );
    const shapeDbColumnName = "outlet_shape";
    const indexOfShapeQuery = MeasurementMinMax.findIndexOfShapeQuery(
      queryStrings,
      shapeDbColumnName,
    );
    GeoServerFilterFunctions.createMinMaxMeasurmentCombinedQuery(
      queryStrings,
      lengthWidthQueries,
      diameterQuery,
      indexOfShapeQuery,
      shapeDbColumnName,
    );
    addInstalledDateFilterToGeoServerQuery(queryStrings, filters);
  };

  var addInstalledDateFilterToGeoServerQuery = function (queryStrings, filters) {
    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "install_date",
        filters.installDate?.from,
        filters.installDate?.to,
      );
    }
  };

  var addConditionFilterToGeoServerQuery = function (queryStrings, filters) {
    if (FilterSummary.areAllCheckboxesChecked("condition")) {
      return;
    }
    const query = [];
    if (filters.condition.includes("poor")) {
      query.push(`(todo_is_outfall_repair = true) AND (todo_is_outfall_inspection = false)`);
    }
    if (filters.condition.includes("null")) {
      query.push(`last_maintenance_inspection_id IS NULL`);
    }
    if (filters.condition.includes("optimal")) {
      query.push("todo_is_outfall_repair = false AND last_maintenance_inspection_id IS NOT NULL");
    }

    if (query.length) {
      queryStrings.push(`(${query.join(" OR ")})`);
    }
  };

  var addFilterToGeoServerQuery = function (queryStrings, filters, filterName, columnName) {
    if (filters[filterName]?.length) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayQuery(
          columnName,
          filters[filterName],
          null,
          shouldSeparateUnknownAndNull,
        ),
      );
    }
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return (
        ResourceFilters.spatialFiltersAreSet(filters) ||
        ResourceFilters.isDateFilterSet(filters.routineDate) ||
        ResourceFilters.isDateFilterSet(filters.repairDate)
      );
    }
  };

  var addGeoServerViewParams = function (params, filters) {
    if (!FilterSummary.areAllCheckboxesChecked("correctiveAction")) {
      GeoServerFilterFunctions.addAdditionalInfoParams(
        filters,
        params,
        "routineRepair",
        true,
        false,
      );
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("correctiveAction") &&
      $('input[name="correctiveAction"][value="no-action"]').is(":checked")
    ) {
      params[`includeNoAction`] = true;
    }
    if (!FilterSummary.areAllCheckboxesChecked("enforcementLevel")) {
      GeoServerFilterFunctions.addAdditionalInfoParams(
        filters,
        params,
        "enforcementLevel",
        false,
        true,
      );
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("enforcementLevel") &&
      $('input[name="enforcementLevel"][value="null"]').is(":checked")
    ) {
      params[`includeNoActionLevel`] = true;
    }
    ResourceFilters.addDateParam(
      params,
      "routine",
      filters.routineDate?.from,
      filters.routineDate?.to,
    );
    ResourceFilters.addDateParam(
      params,
      "repair",
      filters.repairDate?.from,
      filters.repairDate?.to,
    );
  };

  var toDoFilters = function (datum, subjectFilters, subject) {
    return (
      passDueDateFilter(datum, subjectFilters, subject) &&
      passImplementerTypeFilter(datum, subjectFilters) &&
      passMaintainerTypeFilter(datum, subjectFilters) &&
      passOutletTypeFilter(datum, subjectFilters) &&
      passConsequenceOfFailureFilter(datum, subjectFilters)
    );
  };

  var passImplementerTypeFilter = function (datum, filters) {
    if (filters.implementerType?.length !== undefined) {
      return filters.implementerType.includes(datum.implementerType ?? "unknown");
    }

    return true;
  };

  var passMaintainerTypeFilter = function (datum, filters) {
    if (filters.maintainerType?.length !== undefined) {
      return filters.maintainerType.includes(datum.maintainerType ?? "unknown");
    }

    return true;
  };

  var passOutletTypeFilter = function (datum, filters) {
    const outletTypes = getAllOutletTypes();

    return ResourceFilters.passUnknownWhiteListFilter(
      datum.outletType,
      filters.outletType,
      outletTypes,
    );
  };

  var getAllOutletTypes = function () {
    return OutfallInspectionConstants.addInspectionToDoFilterOptions({}).typeOptions.map(
      (option) => option.value,
    );
  };

  var passConsequenceOfFailureFilter = function (datum, filters) {
    return ResourceFilters.passUnknownWhiteListFilter(datum.cof, filters.consequenceOfFailure, [
      "very-high",
      "high",
      "moderate",
      "low",
      "very-low",
    ]);
  };

  var passDueDateFilter = function (datum, filters, subject) {
    return ResourceFilters.passIsoDateFilter(
      datum[subject]?.dueDate,
      filters.dueDateFrom,
      filters.dueDateTo,
    );
  };

  var addToDoGeoServerFilterQuery = function (subjectStrings, filters) {
    addOutletTypeQuery(subjectStrings, filters);
    addMaintainerTypeQuery(subjectStrings, filters);
    addImplementerTypeQuery(subjectStrings, filters);
    addConsequenceOfFailureQuery(subjectStrings, filters);
  };

  var addOutletTypeQuery = function (queryStrings, filters) {
    if (!Array.isArray(filters.outletType)) {
      return;
    }

    const outletTypes = getAllOutletTypes();
    queryStrings.push(
      GeoServerFilterFunctions.getInArrayQuery("outlet_type", filters.outletType, outletTypes),
    );
  };

  var addMaintainerTypeQuery = function (queryStrings, filters) {
    if (!Array.isArray(filters.maintainerType)) {
      return;
    }

    queryStrings.push(
      GeoServerFilterFunctions.getInArrayQuery("maintainer_type", filters.maintainerType),
    );
  };

  var addImplementerTypeQuery = function (queryStrings, filters) {
    if (!Array.isArray(filters.implementerType)) {
      return;
    }

    queryStrings.push(
      GeoServerFilterFunctions.getInArrayQuery("implementer_type", filters.implementerType),
    );
  };

  var addConsequenceOfFailureQuery = function (queryStrings, filters) {
    if (!Array.isArray(filters.consequenceOfFailure)) {
      return;
    }
    if (filters.consequenceOfFailure?.length !== undefined) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayQuery("cof_final_text", filters.consequenceOfFailure, [
          "very-high",
          "high",
          "moderate",
          "low",
          "very-low",
        ]),
      );
    }
  };

  var shouldSeparateUnknownAndNull = function () {
    return Tree.get("activeTab") === "data";
  };

  var getProps = function () {
    const outfallTypeOptions = [
      { name: "Closed Pipe", value: "closed-pipe" },
      { name: "Open Channel", value: "open-channel" },
      { name: "Underground", value: "underground" },
      { name: "Unknown", value: "unknown" },
      { name: "Other", value: "other" },
      { name: "No Data", value: "null" },
    ];

    const outletTypeOptions = [
      { name: "Apron", value: "apron" },
      { name: "Ditch Outlet", value: "ditch-outlet" },
      { name: "Earthen", value: "earthen" },
      { name: "Endwall", value: "endwall" },
      { name: "Flap Gate", value: "flap-gate" },
      { name: "Flared Endsection", value: "flared" },
      { name: "Pipe", value: "pipe" },
      { name: "Riser Structure", value: "riser-structure" },
      { name: "Rundown", value: "rundown" },
      { name: "Slotted", value: "slotted" },
      { name: "Tide Chamber", value: "tide-chamber" },
      { name: "Weir", value: "weir" },
      { name: "Other", value: "other" },
      { name: "Unknown", value: "unknown" },
      { name: "No Data", value: "null" },
    ];

    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, zoneOptions } =
      Filters.getSpatialArrays();

    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      zoneOptions,
      receivingWaterOptions,
      urbanDrainageOptions,
      catchmentsOptions,
      responsiblePartyOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      landOwnershipOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      outfallTypeOptions: outfallTypeOptions,
      outletTypeOptions: outletTypeOptions,
      implementationPhaseOptions: FilterConstants.muniImplementationPhaseOptions,
      cofOptions: FilterConstants.cofOptionsWithNull,
      outletShapeOptions: FilterConstants.OutfallShapeOptions,
      conditionOptions: FilterConstants.conditionOptions,
      correctiveOptions: FilterConstants.getAllCorrectiveOptions(),
      enforcementLevel: FilterConstants.getEnforcementLevel(),
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      subGroups: "groupId",
      implementerType: "implementerType",
      responsiblePartyMaintenance: "maintainerType",
      landOwnership: "landOwnership",
      outfallType: "outfallType",
      outletType: "outletType",
      implementationPhase: "phase",
      condition: "condition",
      cof: "cof",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      zones: "zone_names",
      correctiveAction: ["correctiveAction", "array"],
      enforcementLevel: ["enforcementLevel", "array"],
      shape: "outletShape",
      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          MeasurementMinMax.passDiameterFilter(
            datum.outletShape,
            filters.shape,
            datum.outletDiameter,
            datum.outletDiameterUnits,
            { max: filters.diameterRange?.max, min: filters.diameterRange?.min },
          ) &&
          MeasurementMinMax.passLengthWidthFilter(
            datum.outletShape,
            filters.shape,
            datum.outletLength,
            datum.outletLengthUnits,
            datum.outletWidth,
            datum.outletWidthUnits,
            { max: filters.lengthRange?.max, min: filters.lengthRange?.min },
            { max: filters.widthRange?.max, min: filters.widthRange?.min },
          ) &&
          passInstalledDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    addToDoGeoServerFilterQuery,
    addGeoServerFilterQuery,
    getProps,
    passDataTabMaintainerTypeFilter,
    passDataTabLandOwnershipFilter,
    passOutfallTypeFilter,
    passDataTabOutletTypeFilter,
    passDataTabConsequenceOfFailureFilter,
    addInstalledDateFilterToGeoServerQuery,
    passInstalledDateFilter,
    passImplementationPhaseFilter,
    passOutletShapeFilter,
    passConditionFilter,
    addConditionFilterToGeoServerQuery,
    addFilterToGeoServerQuery,
    resetTodoFilter,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = MuniOutfallsFilters();

const ApiError = require("../../errors/apiError");
const GeoServerFilterFunctions = require("../../mapFunctions/geoServerFilterFunctions");
const MuniOutfallsGeoServerLayer = require("./muniOutfallsGeoServerLayer");
const OutfallInspectionConstants = require("../../outfalls/outfallInspectionConstants");
const ResourceFilters = require("../../filters/resourceFilters");
const Tree = require("../../tree");
const FilterConstants = require("../../filterConstants");
const Filters = require("../../mapFunctions/filters");
const MeasurementMinMax = require("../../general/measurementMinMax");
const FilterSummary = require("../../filters/filterSummary");
const Progeny = require("../../login/progeny");
