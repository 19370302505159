"use strict";

const AssetsPipeFilters = function () {
  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {};

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    AssetManagementFilters.addGeoServerFilterQuery(queryStrings, filters);
  };

  var addGeoServerViewParams = function (params, filters) {
    GeoServerFilterFunctions.addSpatialFilterViewParams(params, filters);
  };

  var getFilterConfigs = function () {
    return {};
  };

  var loadListeners = function () {};

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
    addGeoServerViewParams,
    getFilterConfigs,
    loadListeners,
  };
};

module.exports = AssetsPipeFilters();

const GeoServerFilterFunctions = require("../../mapFunctions/geoServerFilterFunctions");
const AssetManagementFilters = require("./assetManagementFilters");
