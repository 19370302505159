"use strict";
let data;

const OutfallFactSheetController = function () {
  var initFactSheet = async function (id) {
    data = await getFactSheetData(id);
    const currentConfigs = FactSheetConfig.getFactSheetConfig("muni-outfalls");
    FactSheet.init(data, currentConfigs);
  };

  var getFactSheetData = async function (id) {
    try {
      return await ApiCalls.getOutfallForFactSheet(id);
    } catch (e) {
      ApiError.assertIs(e);
    }

    return await MuniOutfallsGeoServerLayer.getOfflineData(id);
  };

  return {
    initFactSheet,
  };
};

module.exports = OutfallFactSheetController();

const ApiCalls = require("../apiCalls");
const ApiError = require("../errors/apiError");
const FactSheet = require("../general/factSheet");
const FactSheetConfig = require("../config/factSheetConfig.js");
const MuniOutfallsGeoServerLayer = require("./outfalls/muniOutfallsGeoServerLayer");
