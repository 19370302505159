"use strict";

const BmpFcsInventoryDrainageArea = function () {
  const stringKey = "bmp-fcs-drainage-area";
  const headerInformation = "Drainage Area";
  let $page;

  var loadDrainageAreaListeners = function () {
    $page.on("change", "[name=tt-flows-to]", changeTreatmentTrainPolygons);
    Form.initializeAndLoadListeners($page, "bmp-fcs-inventory", { isMultiPart: true });
  };

  var unloadDrainageAreaListeners = function () {
    $page.off("change", "[name=tt-flows-to]", changeTreatmentTrainPolygons);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    await renderPageHtml();
    loadDrainageAreaListeners();
    showDrainageAreaModalMap();
    BmpFcsInventoryModalController.addHighlightsToFields($page);
  };

  var renderPageHtml = async function () {
    const renderData = getDrainageAreaProps();
    const html = nunjucks.render(
      "modals/bmpFcsInventory/bmpFcsInventoryDrainageArea.njk",
      renderData,
    );
    BmpFcsInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
  };

  var getDrainageAreaProps = function () {
    const renderData = InventoryModal.getAllData("bmp-fcs-inventory");
    renderData.showCentralizedFields = BmpFcsFunctions.isCentralizedBMPType(renderData.bmpType);
    renderData.isCatchBasin = BmpFcsFunctions.isPriorityCatchBasinType(renderData.bmpType ?? 16);
    const options = BmpFcsInventoryModalController.getOptions();
    renderData.renderMode = options.mode;

    if (renderData.treatmentTrain) {
      renderData.ttFlowName = getFixedTreatmentTrain();
      renderData.ttFlowsToOptions = getTreatmentTrainOptions();
      renderData.ttOptions = BmpFcsInventoryConstants.treatmentTrainOptions;
    }

    return renderData;
  };

  var getFixedTreatmentTrain = function () {
    const otherCentralizedBmps = BmpFcsInventoryModalController.getOtherCentralizedBmps();
    const currentId = BmpFcsInventoryModalController.getLatestBmpInventoryData("idbmp");
    const treatmentTrainCount = otherCentralizedBmps.reduce((ttCount, item) => {
      if (item.tt !== null) {
        ttCount += 1;
      }
      return ttCount;
    }, 0);

    if (otherCentralizedBmps.length === 1) {
      return otherCentralizedBmps[0].bmp_id;
    } else if (treatmentTrainCount === 2) {
      const nonTTBmp = otherCentralizedBmps.find(function (cBmp) {
        return cBmp.tt === null;
      });
      return nonTTBmp?.bmp_id ?? null;
    } else if (treatmentTrainCount === 3) {
      const pairedTreatmentTrain = otherCentralizedBmps.find(function (cBmp) {
        return cBmp.ttFlowsTo === currentId;
      });
      if (pairedTreatmentTrain) {
        return pairedTreatmentTrain.bmp_id;
      } else {
        console.error("Unable to find paired treatment train");
        return null;
      }
    }
  };

  var getTreatmentTrainOptions = function () {
    const treatmentTrainOptions = BmpFcsInventoryModalController.getOtherCentralizedBmps();
    return treatmentTrainOptions.map((cbmp) => {
      return {
        name: cbmp.bmp_id,
        value: cbmp.idbmp,
      };
    });
  };

  var showDrainageAreaModalMap = function () {
    const activeMethod = BmpFcsInventoryModalController.getLatestBmpInventoryData("drawMethod");
    const bmpType = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpType");

    configureDrainageAreaModalMap(bmpType);
    AreaSelectionMap.showAreaSelectionMap(true);
    ModalMap.invalidateModalMapSize();
    AreaSelectionMap.setActiveMethodLayer(activeMethod);
    zoomToAssetOrDrainageArea();

    if (BmpFcsFunctions.isCentralizedBMPType(bmpType)) {
      CentralizedBmpLayer.show();
    } else {
      CentralizedBmpLayer.hide();
    }
  };

  var zoomToAssetOrDrainageArea = function () {
    ModalMap.zoomToMarker();
    DrawController.zoomToDrainage();
  };

  var configureDrainageAreaModalMap = function (bmpType) {
    const markerHandler = BmpFcsFunctions.isCentralizedBMPType(bmpType)
      ? BmpFcsInventoryModalController.loadCentralizedBmpMarker
      : BmpFcsInventoryModalController.loadExistingBmpFcsCatchBasinMarker;
    const drainageSelectionMapConfiguration = {
      newExistingMarkerHandler: markerHandler,
    };
    AreaSelectionMap.resetAreaSelectionMapHandlers();

    const drainageSelectionTableHandlers = DrainageTable.getDrainageTableConfigurations();
    ModalMap.disableMarkerRelocation();
    ModalMap.configureModalMapHandlers(drainageSelectionMapConfiguration);
    ModalMap.refreshMarkerLayer();
    AreaSelectionMap.configureAreaSelectionMapHandlers(drainageSelectionTableHandlers);
  };

  var getUniqueSelectedCatchments = function () {
    const areaPolygons =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("areaPolygons") || [];
    const polygonCatchments = new Set(areaPolygons.map((polygon) => polygon.catchid));
    const selectedCatchments = BmpFcsInventoryModalController.getLatestBmpInventoryData([
      "selectedCatchments",
    ]);
    if (selectedCatchments) {
      polygonCatchments.add(...selectedCatchments);
    }
    return [...polygonCatchments];
  };

  var cleanUp = function () {
    unloadDrainageAreaListeners();
    AreaSelectionMap.hideAreaSelectionMap();
    CentralizedBmpLayer.hide();
    $page.empty();
    return true;
  };

  var changeTreatmentTrainPolygons = async function (e) {
    const treatmentTrainId = parseInt($(e.currentTarget).val());
    await BmpFcsModalMap.changeTreatmentTrainPolygons(treatmentTrainId);
    selectCbmpDrawMethod(treatmentTrainId);
  };

  var selectCbmpDrawMethod = async function (cbmpId) {
    const newMethod = "drawing-tools";
    await AreaSelectionMap.setActiveMethodLayer(newMethod);
  };

  var validate = async function () {
    if (validateImperviousArea()) {
      MessageModal.showReturnWarningModal(
        "You have entered an Impervious Drainage Area that is greater than the selected total drainage area. Return to Drainage Area to resolve this error.",
      );
      return false;
    }
    return true;
  };

  var validateImperviousArea = function () {
    const mode = BmpFcsInventoryModalController.getOptions().mode;
    if (mode === "scenario") {
      return false;
    }
    const totalTreated = parseFloat($(".total-treated").text());
    const imperviousArea =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("imperviousArea");

    return imperviousArea > totalTreated;
  };

  var resetState = function () {
    AreaSelectionMap.resetDrawController();
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    resetState,
    getUniqueSelectedCatchments,
  };
};

module.exports = BmpFcsInventoryDrainageArea();

const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const BmpFcsModalMap = require("../mapFunctions/bmpFcsModalMap");
const BmpFcsFunctions = require("./bmpFcsFunctions");
const AreaSelectionMap = require("../mapFunctions/areaSelectionMap");
const InventoryModal = require("../general/inventoryModal");
const Form = require("../general/form");
const ModalMap = require("../mapFunctions/modalMap");
const CentralizedBmpLayer = require("./centralizedBmpLayer");
const DrainageTable = require("../mapFunctions/drainageTable");
const MessageModal = require("../modals/messageModal");
const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const DrawController = require("../mapFunctions/drawController");
