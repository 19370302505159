"use strict";

const ProjectInspectionObservationFindings = function () {
  const stringKey = "observation-findings";
  const tabHeader = "Control Measures";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on("input", "[name='observation-finding-purpose']", _onFindingPurposeChange);
    $page.on("input", "[name='observation-finding-practice']", _onFindingPracticeChange);
    $page.on("click", "[name='add-observation-finding-practice']", _onAddFindingButtonChange);
    $page.on("click", "[name=add-observation-finding]", _onAddFinding);
    $page.on("input", `[name$="[issueType]"]`, onIssueTypeChange);
    $page.on("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var unloadListeners = function () {
    $page.off("input", "[name='observation-finding-purpose']", _onFindingPurposeChange);
    $page.off("input", "[name='observation-finding-practice']", _onFindingPracticeChange);
    $page.off("click", "[name='add-observation-finding-practice']", _onAddFindingButtonChange);
    $page.off("click", "[name=add-observation-finding]", _onAddFinding);
    $page.off("input", `[name$="[issueType]"]`, onIssueTypeChange);
    $page.off("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var getProps = function (data) {
    return {
      observationFindingPurposeOptions:
        ProjectInspectionConstants.getObservationFindingPurposeOptions(),
      observationFindingsOptions: ProjectInspectionConstants.getObservationFindings(),
      findingSeverityOptions: ProjectInspectionConstants.getFindingSeverityOptions(),
      observationFindingPracticeEnabled: false,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var _onFindingPurposeChange = function () {
    const purpose = $(this).val();

    replaceObservationPractice(purpose);
  };

  var replaceObservationPractice = function (purpose) {
    const $practice = $page.find("[name=observation-finding-practice]").closest("fieldset");

    const props = {
      observationFindingPracticeOptions: getUnusedPracticeOptions(purpose),
      observationFindingPracticeEnabled: true,
    };

    const html = nunjucks.render(
      "modals/projectInspection/templates/observationFindingsPractice.njk",
      {
        props,
      },
    );

    return $practice.replaceWith(html);
  };

  var getUnusedPracticeOptions = function (purpose) {
    const allOptions =
      ProjectInspectionConstants.getObservationFindingPracticeOptionsByPurpose(purpose);
    const allExistingPractices = Object.keys(
      ProjectInspectionController.getAllData().observationFindings,
    );

    return allOptions.filter((option) => !allExistingPractices.includes(option.value));
  };

  var _onFindingPracticeChange = function () {
    const practice = $(this).val();
    const $button = $page.find("[name=add-observation-finding-practice]");

    updateAddObservationButton($button, practice);
  };

  var updateAddObservationButton = function ($button, practice) {
    $button.prop("disabled", !practice);
    $button.data("practice", practice);
  };

  var _onAddFindingButtonChange = function () {
    const $button = $(this);
    const practice = $button.data("practice");
    const purpose = ProjectInspectionConstants.getObservationFindingByPractice(practice).purpose;

    updateAddObservationButton($button, null);
    addAdditionalFinding(practice);
    replaceObservationPractice(purpose);
  };

  var addAdditionalFinding = function (practice) {
    const html = nunjucks.render("modals/projectInspection/templates/observationFinding.njk", {
      props: getProps(),
      practice,
      findings: [
        TemporaryBmpObservation.createNewTemporaryBmpInspection("observationFindings", practice),
      ],
      overideCardState: "open",
    });
    const $newObservation = $(html);

    $page.find("section").eq(1).prepend($newObservation);
    TemporaryBmpObservation.initializeTemporaryBmpInspection($newObservation);
    TemporaryBmpObservation.initializeTemporaryBmpInspection($newObservation.find(".card"));
  };

  var _onAddFinding = function () {
    const $button = $(this);
    TemporaryBmpObservation.addObservationFinding($button, "observationFindings");
  };

  var onIssueTypeChange = function () {
    const $button = $(this);
    const $citation = $button.closest(".card").find(".js-finding-citation");

    const issueType = $button.val();
    const practice = $button.closest("fieldset").data("practice");

    const citation = ProjectInspectionConstants.getFindingIssueCitation(practice, issueType);
    $citation.text(citation);

    saveCitation($button.attr("name"), citation);
  };

  var saveCitation = function (issueTypeName, citation) {
    var path = Form.getPathPartsFromName(issueTypeName);
    path = [...path.slice(0, path.length - 1), "citation"];
    ProjectInspectionController.setFormData(path, citation);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _onFindingPurposeChange,
    _onFindingPracticeChange,
    _onAddFindingButtonChange,
  };
};

module.exports = ProjectInspectionObservationFindings();

const Form = require("../../general/form");
const ProjectInspectionConstants = require("./projectInspectionConstants");
const ProjectInspectionController = require("./projectInspectionController");
const TemporaryBmpObservation = require("./temporaryBmpObservation");
