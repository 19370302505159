"use strict";

class SelectTable {
  listeners;

  constructor($table, { onSelect = null } = {}) {
    this.listeners = onSelect ? [onSelect] : [];
    this.$table = $table;
    this.loadListeners();
  }

  loadListeners() {
    const self = this;

    function _onCheck(e) {
      const $input = $(e.currentTarget);
      self._updateRow($input);
      self.triggerCallbacks($input);
    }
    this.$table.off("input", ".select-table-check", _onCheck);
    this.$table.on("input", ".select-table-check", _onCheck);

    function _onToggleAll(e) {
      self.toggleAllRows(this.checked);
      self.triggerCallbacks($(e.currentTarget));
    }
    this.$table.off("input", ".select-table-toggle-all", _onToggleAll);
    this.$table.on("input", ".select-table-toggle-all", _onToggleAll);
  }

  triggerCallbacks($input) {
    if (this.listeners.length === 0) {
      return;
    }

    for (const listener of this.listeners) {
      listener($input);
    }
    this.updateTable();
  }

  _updateRow($input) {
    this.updateTable();
  }

  updateTable() {
    this._toggleUsing((input) => input.checked);
  }

  toggleAllRows(toggle) {
    this._toggleUsing(() => toggle);
  }

  _toggleUsing(callback) {
    let someChecked = false;
    let someUnchecked = false;

    for (const input of this.getAllInputs()) {
      this._toggleSelectedRow($(input), callback(input));

      if (input.checked) {
        someChecked = true;
      } else {
        someUnchecked = true;
      }
    }

    this._setToggleAllInput(someChecked, someUnchecked);
    this._setDisabledClasses(someChecked);
  }

  getAllInputs() {
    return this.$table.find(".select-table-check");
  }

  getAllSelectedRows() {
    return this.getAllInputs()
      .filter((i, elm) => elm.checked)
      .closest("tr");
  }

  _setToggleAllInput(someChecked, someUnchecked) {
    const $toggleInput = this.$table.find(".select-table-toggle-all");
    $toggleInput.toggleClass("partially-checked", someChecked === true && someUnchecked === true);

    if (someChecked === true && someUnchecked === false) {
      $toggleInput.prop("checked", true);
    } else if (someChecked === false && someUnchecked === true) {
      $toggleInput.prop("checked", false);
    }
  }

  _setDisabledClasses(someChecked) {
    const $clearInput = this.$table.find(".select-table-disable-when-no-selection");
    $clearInput.prop("disabled", someChecked === false);
  }

  _toggleSelectedRow($input, toggle) {
    $input.prop("checked", toggle);
    $input.parents("tr").toggleClass("selected", toggle);
  }

  addListener(listener) {
    this.listeners.push(listener);
  }
}

module.exports = SelectTable;
