"use strict";

var PieChart = function () {
  var render = function (data) {
    const dataView = Tree.get("dataView");
    // @TO DO: take runoff and particulate data
    if (!pieChartIsEnabled()) {
      unrender();
      return;
    }

    var getChartType = function () {
      if (dataView === "particulatesView") {
        return "particulate-reduction";
      }
      if (dataView === "trashView") {
        return "plu-treatment";
      }
      if (dataView === "runoffView") {
        return "runoff-reduction";
      }
      if (dataView === "sbmpView") {
        return "runoff-reduction";
      }
    };

    const chartType = getChartType();
    const yearData = prepareData(chartType, data);

    var container = $(".pie-chart-d3");
    container.html("");

    var width = 370,
      height = 640,
      radius = Math.min(width, height) * 0.5 - 80;

    var arc = d3.svg.arc().outerRadius(radius).innerRadius(0);

    const pie = d3.layout
      .pie()
      .sort(null)
      .value((d) => d.value);
    const svg = d3
      .select(".pie-chart-d3")
      .append("svg")
      .attr("align", "center")
      .attr("width", "350")
      .attr("height", "100%")
      .attr("viewBox", "0 0 " + width + " " + height)
      .attr("preserveAspectRatio", "xMinYMin")
      .append("g")
      .attr("transform", getTranslateString(0, -41.5));

    const parent = svg
      .append("g")
      .attr("transform", "translate(" + [width / 2 - 20, height / 2 - 40] + ")");

    const tableYOffset = 415;
    renderTitle(svg, yearData.year, width, dataView);
    renderTable(svg, yearData.chartRows, width, tableYOffset, dataView);
    renderAdditionalRows(svg, yearData.additionalRows, width, tableYOffset, dataView);
    renderPieChart(parent, pie, yearData, arc, radius, chartType);
    renderCloseButton(svg, width);
  };

  var getPercent = function (numerator, denominator) {
    return denominator === 0 ? 0 : Math.floor((numerator / denominator) * 100);
  };

  var pieChartIsEnabled = function () {
    const dataModule = DataViewFunctions.getCurrentDataModule();

    return (
      (dataModule === "trash" && !Progeny.activeHasProgeny()) ||
      (dataModule === "swtelr" && !Progeny.activeHasProgeny())
    );
  };

  var prepareData = function (chartType, yearData) {
    if (chartType === "plu-treatment") {
      const year = yearData.year;
      const additionalRows = [
        {
          nameOnTable: "TREATED PLU",
          value: +yearData.treatedPlu,
          format: "unit",
          unit: "ac",
        },
        {
          nameOnTable: "% TREATED",
          value: +yearData.percentPluTreated,
          format: "percent",
        },
      ];

      const chartRows = [
        {
          name: "treatedPluLitter",
          nameOnTable: "Low Litter",
          nameOnPieChart: "Low Litter",
          percentage:
            yearData.treatedPlu == 0
              ? 0
              : (100 * +yearData.treatedPluLitter) / +yearData.treatedPlu,
          value: +yearData.treatedPluLitter,
        },
        {
          name: "treatedPluFcs",
          nameOnTable: "Full Capture Systems",
          nameOnPieChart: "FCS",
          percentage:
            yearData.treatedPlu == 0 ? 0 : (100 * +yearData.treatedPluFcs) / +yearData.treatedPlu,
          value: +yearData.treatedPluFcs,
        },
      ];

      if (allValuesEmpty(chartRows)) {
        chartRows.push({
          nameOnPieChart: "No reductions",
          isAllZerosPlaceholder: true,
          value: 1,
        });
      }

      return { year, additionalRows, chartRows };
    }
    if (chartType === "particulate-reduction") {
      const year = yearData.year;
      const totalReduction = +yearData.totalParticulateReductionConnected;
      const runoffDisconnection = +yearData.particulateParcelConnected;
      const sweeping = +yearData.particulateRoadConnected;
      const decentralizedBmp = +yearData.particulateDecentralizedConnected;
      const centralizedBmp = +yearData.particulateCentralizedConnected;
      const percentForRunoffDisconnection = getPercent(runoffDisconnection, totalReduction);
      const percentForSweeping = getPercent(sweeping, totalReduction);
      const percentForDecentralized = getPercent(decentralizedBmp, totalReduction);
      const percentForCentralized = getPercent(centralizedBmp, totalReduction);

      const additionalRows = [
        {
          nameOnTable: "Particulate load reduction",
          value: totalReduction,
          format: "unit",
          unit: "ton/yr",
        },
      ];
      const chartRows = [
        {
          name: "runoffDisconection",
          nameOnTable: "Runoff disconnection",
          nameOnPieChart:
            percentForRunoffDisconnection !== 0
              ? percentForRunoffDisconnection.toString() + "%"
              : "",
          percentage: runoffDisconnection === 0 ? 0 : percentForRunoffDisconnection,
          value: runoffDisconnection,
        },
        {
          name: "streetSweeping",
          nameOnTable: "Street sweeping",
          nameOnPieChart: percentForSweeping !== 0 ? percentForSweeping.toString() + "%" : "",

          percentage: sweeping === 0 ? 0 : percentForSweeping,
          value: sweeping,
        },
        {
          name: "decentralizedStructualBmps",
          nameOnTable: "Decentralized structural BMPs",
          nameOnPieChart:
            percentForDecentralized !== 0 ? percentForDecentralized.toString() + "%" : "",
          percentage: decentralizedBmp === 0 ? 0 : percentForDecentralized,
          value: decentralizedBmp,
        },
        {
          name: "centralizedStructualBMPs",
          nameOnTable: "Centralized structural BMPs",
          nameOnPieChart: percentForCentralized !== 0 ? percentForCentralized.toString() + "%" : "",
          percentage: centralizedBmp === 0 ? 0 : percentForCentralized,
          value: centralizedBmp,
        },
      ];
      if (allValuesEmpty(chartRows)) {
        chartRows.push({
          nameOnPieChart: "No reductions",
          isAllZerosPlaceholder: true,
          value: 1,
        });
      }

      return { year, additionalRows, chartRows };
    }
    if (chartType === "runoff-reduction") {
      const year = yearData.year;
      const totalRunoffVolumeReduction = +yearData.totalRunoffReductionConnected;
      const runoffDisconection = +yearData.runoffParcelConnected;
      const getPercentForRunoffDisconnection = getPercent(
        runoffDisconection,
        totalRunoffVolumeReduction,
      );
      const runoffDecentralized = +yearData.runoffDecentralizedConnected;
      const getPercentForRunoffDecentralized = getPercent(
        runoffDecentralized,
        totalRunoffVolumeReduction,
      );
      const runoffCentralized = +yearData.runoffCentralizedConnected;
      const getPercentForRunoffCentralized = getPercent(
        runoffCentralized,
        totalRunoffVolumeReduction,
      );

      const additionalRows = [
        {
          nameOnTable: "Runoff volume reduction",
          value: totalRunoffVolumeReduction,
          format: "unit",
          unit: "afy",
        },
      ];
      const chartRows = [
        {
          name: "roRunoffDisconection",
          nameOnTable: "Runoff disconnection",
          nameOnPieChart:
            getPercentForRunoffDisconnection !== 0
              ? getPercentForRunoffDisconnection.toString() + "%"
              : "",

          percentage: runoffDisconection === 0 ? 0 : getPercentForRunoffDisconnection,
          value: runoffDisconection,
        },

        {
          name: "roDecentralizedStructualBmps",
          nameOnTable: "Decentralized structural BMPs",
          nameOnPieChart:
            getPercentForRunoffDecentralized !== 0
              ? getPercentForRunoffDecentralized.toString() + "%"
              : "",
          percentage: runoffDecentralized === 0 ? 0 : getPercentForRunoffDecentralized,
          value: runoffDecentralized,
        },
        {
          name: "roCentralizedStructualBMPs",
          nameOnTable: "Centralized structural BMPs",
          nameOnPieChart:
            getPercentForRunoffCentralized !== 0
              ? getPercentForRunoffCentralized.toString() + "%"
              : "",
          percentage: runoffCentralized === 0 ? 0 : getPercentForRunoffCentralized,
          value: runoffCentralized,
        },
      ];
      if (allValuesEmpty(chartRows)) {
        chartRows.push({
          nameOnPieChart: "No reductions",
          isAllZerosPlaceholder: true,
          value: 1,
        });
      }

      return { year, additionalRows, chartRows };
    }
  };

  var allValuesEmpty = function (chartRows) {
    return chartRows.every((row) => row.value === 0);
  };

  var renderPieChart = function (parent, pie, yearData, arc, radius, chartType) {
    const g = parent
      .selectAll(".arc")
      .data(pie(yearData.chartRows))
      .enter()
      .append("g")
      .attr("class", "arc")
      .attr("class", "labels");

    g.append("path")
      .attr("class", (d) => {
        if (!d.data.isAllZerosPlaceholder) {
          return CleanData.camelToKebabCase(d.data.name) + "-background";
        }
      })
      .attr("fill", (d) => {
        if (d.data.isAllZerosPlaceholder) {
          return "white";
        }
      })
      .attr("stroke-width", (d) => {
        return d.startAngle === d.endAngle ? 0 : 0.7;
      })
      .transition()
      .delay((d, i) => i * 500)
      .duration(500)
      .attrTween("d", function (d) {
        const interp = d3.interpolate(d.startAngle, d.endAngle);
        return (t) => {
          d.endAngle = interp(t);
          return arc(d);
        };
      });

    g.append("path")
      .attr("class", (d, i) => {
        if (d.data.isAllZerosPlaceholder) {
          return "all-zeros-placeholder-pattern";
        }
        return CleanData.camelToKebabCase(d.data.name) + "-pattern";
      })
      .attr("stroke-width", (d) => {
        return d.startAngle === d.endAngle ? 0 : 0.7;
      })
      .transition()
      .delay((d, i) => i * 500)
      .duration(500)
      .attrTween("d", function (d) {
        const interp = d3.interpolate(d.startAngle, d.endAngle);
        return (t) => {
          d.endAngle = interp(t);
          return arc(d);
        };
      });

    var labelr = radius + 18; // radius for label anchor

    const labels = g
      .append("text")
      .attr("text-anchor", function (d) {
        return (d.endAngle + d.startAngle) / 2 > Math.PI ? "end" : "start";
      })
      .attr("transform", function (d) {
        var c = arc.centroid(d),
          x = c[0],
          y = c[1],
          // pythagorean theorem for hypotenuse
          h = Math.sqrt(x * x + y * y);
        return "translate(" + (x / h) * labelr + "," + (y / h) * labelr + ")";
      });

    labels
      .append("tspan")
      .attr("x", 0)
      .attr("y", 0)
      .attr("class", "pie-chart-arc-label")
      .text(function (d, i) {
        if (CleanData.roundToZero(d.value) != 0) {
          return d.data.nameOnPieChart;
        }
      });

    labels
      .append("tspan")
      .attr("dy", "1.4em")
      .attr("x", 0)
      .attr("y", 0)
      .attr("class", "pie-chart-arc-sublabel")
      .text(function (d, i) {
        if (!d.data.isAllZerosPlaceholder) {
          if (chartType === "particulate-reduction" || chartType === "runoff-reduction") {
            if (CleanData.roundToZero(d.value) != 0) {
              return "";
            }
          } else {
            if (CleanData.roundToZero(d.value) != 0) {
              return "(" + formatNumberString(d.value, 0) + " ac)";
            }
          }
        }
      });
  };

  var renderTitle = function (svg, year, width, dataView) {
    const title = svg
      .append("g")
      .attr("transform", getTranslateString(0, 132))
      .attr("class", "pie-chart-title");

    title.append("text").text(() => {
      const getTitle = () => {
        if (dataView === "particulatesView") {
          return " Particulates Reduction";
        }
        if (dataView === "trashView") {
          return " Acreage Reduction";
        }
        if (dataView === "runoffView") {
          return " Runoff Volume Reductions";
        }
      };
      let theText = "";
      theText += year;
      theText += getTitle();
      return theText;
    });

    title
      .append("line")
      .attr("class", "pie-chart-title-line")
      .attr("x2", width - 15)
      .attr("y1", 10)
      .attr("y2", 10)
      .attr("stroke", "#000");
  };

  var renderTable = function (svg, rows, width, yOffset, dataView) {
    const getYValueAndUnitType = () => {
      if (dataView === "particulatesView") {
        return {
          yValue: yOffset + 185,
          unitType: " ton/yr",
          type: "Treatment",
          amount: "Contribution",
        };
      }
      if (dataView === "trashView") {
        return {
          yValue: yOffset + 108,
          unitType: " ac",
          type: "Treatment",
          amount: "Acres Treated",
        };
      }
      if (dataView === "runoffView" || dataView === "sbmpView") {
        return {
          yValue: yOffset + 150,
          unitType: " afy",
          type: "Treatment",
          amount: "Contribution",
        };
      }
    };
    const tableGroup = svg.append("g");

    tableGroup
      .append("line")
      .attr("class", "pie-chart-table-line-light")
      .attr("x2", width - 15)
      .attr("y1", yOffset + 32)
      .attr("y2", yOffset + 32);

    tableGroup
      .append("line")
      .attr("class", "pie-chart-table-line-dark")
      .attr("x2", width - 15)
      .attr("y1", getYValueAndUnitType().yValue)
      .attr("y2", getYValueAndUnitType().yValue);

    const table = tableGroup
      .append("foreignObject")
      .attr("width", width)
      .attr("height", "100%")
      .attr("class", "pie-chart-table")
      .attr("transform", getTranslateString(0, yOffset))
      .append("xhtml:table")
      .style("width", `${width - 20}px`);

    const headerRow = table.append("tr").style("height", "36px");

    headerRow.append("th").attr("width", "70%").text(getYValueAndUnitType().type);
    headerRow.append("th").attr("width", "30%").text(getYValueAndUnitType().amount);

    const tableRows = table
      .selectAll("tr.pie-chart-table-rows")
      .data(rows.filter((row) => !row.isAllZerosPlaceholder))
      .enter()
      .append("tr")
      .style("height", "34px")
      .attr("class", "pie-chart-table-rows");

    const firstColumnSvg = tableRows
      .append("td")
      .append("svg")
      .attr("width", "100%")
      .attr("height", 20);

    firstColumnSvg
      .append("rect")
      .attr("width", "22px")
      .attr("height", "14px")
      .attr("y", "4px")
      .attr("ry", "3px")
      .attr("rx", "3px")
      .attr("class", (d) => CleanData.camelToKebabCase(d.name) + "-background");

    firstColumnSvg
      .append("rect")
      .attr("width", "22px")
      .attr("height", "14px")
      .attr("y", "4px")
      .attr("ry", "3px")
      .attr("rx", "3px")
      .attr("class", (d) => CleanData.camelToKebabCase(d.name) + "-pattern");

    firstColumnSvg
      .append("text")
      .attr("dy", "1.2em")
      .attr("dx", "28px")
      .attr("class", "pie-chart-table-svg-text")
      .text((d) => d.nameOnTable);

    tableRows
      .append("td")
      .text((d) => formatNumberString(d.value, 0) + getYValueAndUnitType().unitType);
  };

  var renderAdditionalRows = function (svg, additionalRows, width, tableYOffset, dataView) {
    const getTranslateValue = () => {
      if (dataView === "particulatesView") {
        return tableYOffset + 224;
      }
      if (dataView === "trashView") {
        return tableYOffset + 138;
      }
      if (dataView === "runoffView" || dataView === "sbmpView") {
        return tableYOffset + 175;
      }
    };
    const group = svg.append("g").attr("transform", getTranslateString(0, getTranslateValue()));

    const additionalRowsText = group
      .selectAll("text.additional-row")
      .data(additionalRows)
      .enter()
      .append("text")
      .attr("dy", (d, i) => `${i * 2.2}em`);

    additionalRowsText
      .append("tspan")
      .attr("class", "additional-rows-label")
      .style("text-anchor", "start")
      .text((d) => d.nameOnTable);

    additionalRowsText
      .append("tspan")
      .attr("x", width - 20)
      .attr("class", "additional-rows-value")
      .style("text-anchor", "end")
      .text((d) => {
        if (d.format === "percent") {
          return formatNumberString(d.value, 0) + "%";
        } else if (d.format === "unit") {
          return formatNumberString(d.value, 0) + " " + d.unit;
        }
      });
  };

  var renderCloseButton = function (svg, width) {
    const buttonSize = 8;
    const button = svg
      .append("g")
      .attr("transform", getTranslateString(width - 32, 125))
      .attr("class", "pie-chart-close-button")
      .attr("height", buttonSize)
      .attr("width", buttonSize);

    button
      .append("rect")
      .attr("stroke-width", 0)
      .attr("width", buttonSize)
      .attr("height", buttonSize);

    button
      .append("line")
      .attr("stroke-width", 1.5)
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", buttonSize)
      .attr("y2", buttonSize);

    button
      .append("line")
      .attr("stroke-width", 1.5)
      .attr("x1", 0)
      .attr("y1", buttonSize)
      .attr("x2", buttonSize)
      .attr("y2", 0);
  };

  var getTranslateString = function (x, y) {
    return `translate(${x}, ${y})`;
  };

  var formatNumberString = function (num, fractionDigits) {
    if (fractionDigits === undefined) {
      fractionDigits = 1;
    }
    if (num !== undefined && num !== "") {
      let formatted = (+num).toLocaleString(undefined, {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      });
      if (formatted === "0") {
        formatted = (+num).toLocaleString(undefined, {
          maximumFractionDigits: fractionDigits + 2,
          minimumFractionDigits: fractionDigits + 2,
        });
      }
      return +formatted == 0 ? "0" : formatted;
    }
  };

  var unrender = function () {
    $(".pie-chart-d3").html("");
  };

  return {
    render,
    unrender,
    pieChartIsEnabled,
  };
};

module.exports = PieChart();

const Tree = require("../../../tree");
const CleanData = require("../../mapFunctions/cleanData");
const DataViewFunctions = require("../../dataViewFunctions");
const Progeny = require("../../../login/progeny");
