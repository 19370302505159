"use strict";

const AssetsCulvertPopup = function () {
  const layerName = "assetsCulvertEsriDynamicMap";

  var getPopupHtml = function (props) {
    addDisplayNames(props);
    const properties = {
      layerName,
      props,
      formattedDate: AssetsPopupFunctions.getFormattedDate(props.install_date),
      assetAge: AssetsPopupFunctions.getAssetAge(props.install_date),
      popupHeaderProps: AssetsPopupFunctions.getPopupHeaderProps(props),
      displayPofText: AssetsPopupFunctions.getDisplayPofText(props),
      mode: AssetsPopupFunctions.getMode(),
    };
    return PopupContent.getLayerPopup(properties, layerName);
  };

  var addDisplayNames = function (props) {
    props.displayType = ToolSettings.getSetting("culvert", "type")[props.type]?.display;
  };

  return {
    getPopupHtml,
  };
};

module.exports = AssetsCulvertPopup();

const AssetsPopupFunctions = require("../assetsPopupFunctions");
const PopupContent = require("../../mapFunctions/popupContent");
const ToolSettings = require("../../settings/toolSettings");
