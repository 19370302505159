"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetTwoDonuts = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [
      {
        className: "hover-pie",
        getHoverHtml: InsightWidgetTwoDonuts.prototype.getHoverHtml,
      },
    ];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 1;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetTwoDonuts.prototype = Object.create(InsightWidget.prototype);
InsightWidgetTwoDonuts.prototype.constructor = InsightWidgetTwoDonuts;

InsightWidgetTwoDonuts.prototype.getHtml = function (insightData) {
  var title = this.widgetConfig.title;

  var html = nunjucks.render("insight/widgets/twoPies.njk", {
    title,
    onePie: this.widgetConfig.onePie,
    onePieLabel: this.widgetConfig.onePieLabel,
    hideExportButton: this.widgetConfig.hideExportButton ?? false,
  });
  return html;
};

InsightWidgetTwoDonuts.prototype.renderChart = function (insightData, exportModal = false) {
  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }

  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight;

  var margin = defaults.margin,
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom,
    aspectRatio = width / height,
    radius = this.widgetConfig.onePie ? Math.min(40 + 30 * aspectRatio, 75) : 50;
  const donutHoleRadius = radius * 0.6;

  var chartData = this.widgetConfig.getChartData(insightData);
  var legendData = this.widgetConfig.legendData();
  const unit = this.widgetConfig.unit;

  const extraMargin = this.widgetConfig.onePie ? 10 : 0;
  const unitsFontWeight = this.widgetConfig.onePie ? "normal" : "100";
  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom + extraMargin * aspectRatio);

  svg
    .append("text")
    .attr("x", 0)
    .attr("y", 10)
    .attr("fill", "#000")
    .attr("font-size", "11px")
    .attr("font-weight", unitsFontWeight)
    .text(`Unit: ${unit}`);

  var pie = d3.pie().value(function (d) {
    return d.value;
  });

  pie.sort(null);

  var arc = d3.arc().outerRadius(radius).innerRadius(donutHoleRadius);

  const chartCount = this.widgetConfig.onePie ? 1 : 2;

  var charts = svg
    .selectAll("g")
    .data(chartData)
    .enter()
    .append("g")
    .attr("transform", function (d, i) {
      const left = (containerWidth * i + containerWidth / 2) / chartCount;
      return `translate(${left},${containerHeight / 2 + extraMargin - 10})`;
    })
    .attr("pointer-events", "bounding-box")
    .attr("class", function (d, i) {
      let classes = "pie pie-" + i;
      if (d.total) {
        classes += " hover-pie";
      }
      return classes;
    })
    .attr("data-hover-key", function (d) {
      return d.hoverKey;
    });

  charts
    .append("rect")
    .attr("class", "hover-background-rect")
    .attr("display", function (d) {
      return d.total ? "block" : "none";
    })
    .attr("x", function (d, i) {
      return -1 * (radius + 3); // -53
    })
    .attr("y", function (d) {
      return -1 * (radius - 1); // -49
    })
    .attr("width", function (d) {
      return 2 * (radius + 3); // 106
    })
    .attr("height", function (d) {
      return 2 * (radius + 3) + 4; // 110
    })
    .attr("rx", 4)
    .attr("fill", "transparent");

  var chartLabel = charts
    .append("text")
    .attr("class", "pie-title")
    .attr("transform", "translate(0, 8)")
    .attr("x", 0)
    .attr("y", radius + 20 + extraMargin)
    .attr("text-anchor", "middle");

  const wholeNumber = this.widgetConfig.wholeNumber;
  chartLabel
    .append("tspan")
    .attr("font-size", "15px")
    .attr("x", 0)
    .text(function (d) {
      if (!d.total) {
        return "0 / 0";
      } else {
        if (wholeNumber) {
          return `${d.value || 0} / ${d.total}`;
        } else {
          const formattedValue = InsightWidgetFunctions.formatNumber(d.value);
          const formattedTotal = InsightWidgetFunctions.formatNumber(d.total);
          return `${formattedValue || 0} / ${formattedTotal}`;
        }
      }
    });

  chartLabel
    .append("tspan")
    .attr("x", 0)
    .attr("dy", function (d) {
      if (d.noData) {
        return "0";
      } else {
        return "18";
      }
    })
    .text(function (d) {
      return d.label;
    });

  var pies = charts
    .selectAll(".pie")
    .data(function (d) {
      if (!d.total) {
        return pie([{ value: 100, key: "" }]);
      } else {
        const dataPercent = (d.value / d.total) * 100;

        return pie([
          { value: dataPercent, key: d.key },
          { value: 100 - dataPercent, key: "" },
        ]);
      }
    })
    .enter()
    .append("g")
    .attr("class", "arc")
    .attr("transform", "translate(0, 8)");

  pies
    .append("path")
    .attr("d", arc)
    .attr("fill", function (d) {
      return d.data.color || legendData[d.data.key]?.color || "#EDEDED";
    });

  charts
    .append("circle")
    .attr("fill", "transparent")
    .attr("transform", "translate(0, 8)")
    .attr("r", radius - 1)
    .style("stroke-dasharray", "4, 4")
    .attr("stroke-width", 2)
    .attr("stroke", function (d) {
      return !d.total ? "black" : "transparent";
    });
  charts
    .append("circle")
    .attr("fill", "transparent")
    .attr("transform", "translate(0, 8)")
    .attr("r", donutHoleRadius - 1)
    .style("stroke-dasharray", "4, 4")
    .attr("stroke-width", 2)
    .attr("stroke", function (d) {
      return !d.total ? "black" : "transparent";
    });
  const textLevel = chartCount === 1 ? 12 : 8;
  charts
    .append("text")
    .attr("font-size", function (d) {
      if (!d.total) {
        return "12px";
      }
      return chartCount === 1 ? "26px" : "18px";
    })
    .attr("transform", `translate(0,${textLevel})`)
    .attr("text-anchor", "middle")
    .attr("alignment-baseline", "middle")
    .attr("dominant-baseline", "middle")
    .text(function (d) {
      const formattedPercent = InsightWidgetFunctions.formatPercentage(d.value, d.total, 0);
      return !d.total ? "No Data" : formattedPercent;
    });
};

InsightWidgetTwoDonuts.prototype.getHoverHtml = function (insightData, $hoverTarget) {
  const hoverData = this.widgetConfig.getHoverData(insightData);
  const legendData = this.widgetConfig.legendData();
  const unit = this.widgetConfig.unit;

  const hoverKey = $hoverTarget.data("hover-key");
  const data = hoverData[hoverKey];
  const template = data.template;

  const color = legendData[hoverKey]?.color || "#EDEDED";

  var html = nunjucks.render(template, {
    unit,
    color,
    ...data,
  });

  return html;
};

module.exports = InsightWidgetTwoDonuts;

const d3 = require("d3-v6");
const InsightWidgetFunctions = require("./insightWidgetFunctions");
