"use strict";

const MuniPipesPopup = function () {
  const popups = {};

  var getPopupHtml = function (properties) {
    properties.permissions = UserPermissions.getAllPermissions();
    return PopupContent.getLayerPopup(properties, "muniPipes");
  };

  var onPopupOpen = async function (properties, layerName) {
    const data = await getPopupData(properties.id);
    const html = getPopupHtml(data);
    const $popup = $(".leaflet-popup-content").html(html);
    const layerModule = LayerFunctions.getLayerModule(layerName);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };
  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deletePipe(data.id, data.name, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deletePipe = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Pipe ${name}? Click "Delete" to permanently delete the Pipe. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deletePipe(id);
        invalidateLayerDataFunction();
        _reloadAndClosePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "pipes");
  };
  var _reloadAndClosePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var getPopupData = async function (id) {
    if (popups[id]) {
      return popups[id];
    }
    const popupData = await ApiCalls.getMuniPipesPopup(id);
    popupData.icon = RoutineMaintenanceIcon.getMaintenanceIcon(
      "muni-pipes",
      popupData.lastMaintenanceInspection,
      popupData.lastMaintenanceInspection?.needsRepair,
    );
    // eslint-disable-next-line require-atomic-updates
    popups[id] = popupData;
    return popupData;
  };

  var getPopupLatLng = async function (id) {
    const data = await getPopupData(id);
    return data?.midpointLatLng;
  };

  return {
    getPopupHtml,
    onPopupOpen,
    getPopupLatLng,
  };
};

module.exports = MuniPipesPopup();

const ApiCalls = require("../apiCalls");
const PopupContent = require("../mapFunctions/popupContent");
const LayerFunctions = require("../layerFunctions");
const MessageModal = require("../modals/messageModal");
const UserPermissions = require("../login/userPermissions");
const Analytics = require("../general/analytics");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
