"use strict";

const BulkSelectTable = function () {
  var $tbl;
  var savedSelectedId = null;
  var bulkSelectTableHandlers = {
    deselectHandler: null,
    assignTableIconsHandler: null,
    getMarkerReferenceHandler: null,
    bulkSelectTableMouseoverHandler: null,
    bulkSelectTableMouseoutHandler: null,
  };
  var list = null;
  var renderData = null;

  var init = function () {
    var mapLayers = ModalMap.getMapLayers();
    loadListeners(mapLayers);
    setBulkSelectTableHeight();
  };

  var loadListeners = function () {
    $tbl = $("#floating-bulk-select-table");
    $tbl.off("click", ".delete-btn", handleTableDeselect);
    $tbl.on("click", ".delete-btn", handleTableDeselect);

    $tbl.off("click", "tr", tableRowClick);
    $tbl.on("click", "tr", tableRowClick);

    $tbl.off("mouseover", "tr", tableHoverMouseover);
    $tbl.off("mouseout", "tr", tableHoverMouseout);
    $tbl.on("mouseover", "tr", tableHoverMouseover);
    $tbl.on("mouseout", "tr", tableHoverMouseout);
  };

  var configureBulkSelectTableHandlers = function (newHandlers) {
    bulkSelectTableHandlers = {
      deselectHandler: newHandlers.deselectHandler,
      assignTableIconsHandler: newHandlers.assignTableIconsHandler,
      getMarkerReferenceHandler: newHandlers.getMarkerReferenceHandler,
      bulkSelectTableMouseoverHandler: newHandlers.bulkSelectTableMouseoverHandler,
      bulkSelectTableMouseoutHandler: newHandlers.bulkSelectTableMouseoutHandler,
    };
  };

  var render = function (data) {
    const BulkSelectPage = require("./bulkSelectPage");

    const tableTemplate = BulkSelectPage.getPageConfig("bulkSelectTableTemplate");
    const rowTemplate = BulkSelectPage.getPageConfig("bulkSelectRowTemplate");

    if (!tableTemplate) {
      console.error("Error: bulkSelectTableTemplate is not defined in bulk select pageConfig");
      return;
    } else if (!rowTemplate) {
      console.error("Error: bulkSelectRowTemplate is not defined in bulk select pageConfig");
      return;
    }

    assignBulkSelectTableIcons(data);

    const $container = $("#bulk-select-table-container");
    if (!list) {
      const html = nunjucks.render(tableTemplate, {
        data,
      });
      $container.html(html);
    }
    $(".bulk-select-count").text(data.length);
    renderData = data;
    renderRows($container, rowTemplate);

    if (savedSelectedId) {
      highlightTableAndOpenPopup(savedSelectedId);
    }
  };

  var renderRows = function ($container, rowTemplate) {
    function getRowCallback(index) {
      const row = renderData[index];
      return nunjucks.render(rowTemplate, {
        row,
        selected: row.id === savedSelectedId,
      });
    }

    if (list) {
      list.updateData(getRowCallback, renderData.length, { resetScroll: false });
    } else {
      list = new LazyList({
        rowContainer: $container.find("tbody")[0],
        scrollContainer: $container[0],
        getRowCallback,
        totalRows: renderData.length,
      });
    }
  };

  var clearBulkSelectTable = function () {
    list.destroy();
    list = null;
    $tbl.find("tbody").empty();
  };

  var setBulkSelectTableHeight = function () {
    const mapHeight = $("#modalMap").height();
    $("#bulk-select-table-container").css("height", mapHeight);
  };

  var handleTableDeselect = function (e) {
    var id = $(e.target).closest("tr").data("id");
    bulkSelectTableHandlers.deselectHandler(id);
  };

  var assignBulkSelectTableIcons = function (data) {
    if (bulkSelectTableHandlers.assignTableIconsHandler) {
      bulkSelectTableHandlers.assignTableIconsHandler(data);
    }
  };

  var tableRowClick = function () {
    var id = $(this).data("id");
    if (id) {
      savedSelectedId = id;
      highlightTableAndOpenPopup(id);
    }
  };

  var highlightTableAndOpenPopup = function (id) {
    var marker = bulkSelectTableHandlers.getMarkerReferenceHandler(id);
    if (marker) marker.openPopup();
    highlightTableRow(id);
  };

  var scrollToAndHighlightTableRow = function (id) {
    highlightTableRow(id, true);
  };

  var highlightTableRow = function (id, scrollTo = false) {
    savedSelectedId = id;

    if (scrollTo) {
      const index = renderData.findIndex((datum) => datum.id === id);
      list.scrollToIndex(index, true);
    } else {
      list.renderVisibleRows();
    }
  };

  var resetSavedSelectedId = function () {
    savedSelectedId = null;
  };

  var tableHoverMouseover = function () {
    var id = $(this).data("id");
    if (id) {
      if (bulkSelectTableHandlers.bulkSelectTableMouseoverHandler) {
        bulkSelectTableHandlers.bulkSelectTableMouseoverHandler(id);
      }
    }
  };

  var tableHoverMouseout = function () {
    if (bulkSelectTableHandlers.bulkSelectTableMouseoutHandler) {
      bulkSelectTableHandlers.bulkSelectTableMouseoutHandler();
    }
  };

  return {
    init,
    render,
    configureBulkSelectTableHandlers,
    clearBulkSelectTable,
    scrollToAndHighlightTableRow,
    resetSavedSelectedId,
    setBulkSelectTableHeight,
  };
};

module.exports = BulkSelectTable();

const ModalMap = require("../mapFunctions/modalMap");
const LazyList = require("../general/lazyList");
