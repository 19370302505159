"use strict";

const AssetManagementWidgetToggle = function () {
  let thisToggle;

  var loadDomListeners = function () {
    thisToggle.off();
    thisToggle.on("click", ".nav-toggle-map-button", _showMapPanelfromWidget);
    thisToggle.on("click", ".nav-toggle-widget-button", showWidgetScreen);
  };

  var render = function () {
    const selected = "map";
    Tree.set("navToggleSelection", selected);
    $(".nav-toggle-mount")
      .getOnlyOrThrow()
      .html(nunjucks.render("navbar/navToggleMapWidget.njk", { selected }));
    thisToggle = $(".nav-toggle");
  };
  var showWidgetScreen = function () {
    Tree.set("navToggleSelection", "widget");
    $(".nav-toggle-widget-button").addClass("active");
    $(".nav-toggle-map-button").removeClass("active");
    $("#bottomFloatingInputsTable").attr("hidden", true);
    $("#details-button-container").attr("hidden", true);
    $("#widget-page-container").removeAttr("hidden");
    $(".map-title").addClass("hidden");
    FilterSummary.hide();
  };
  var _showMapPanelfromWidget = function () {
    Tree.set("navToggleSelection", "map");
    $("#bottomFloatingInputsTable").removeAttr("hidden");
    $(".nav-toggle-widget-button").removeClass("active");
    $("#details-button-container").removeAttr("hidden");
    $("#widget-page-container").attr("hidden", true);
    $(".nav-toggle-map-button").addClass("active");
    $(".map-title").removeClass("hidden");
    FilterSummary.show();
  };
  return {
    render,
    loadDomListeners,
    showWidgetScreen,
    _showMapPanelfromWidget,
  };
};

module.exports = AssetManagementWidgetToggle();

const Tree = require("../tree");
const FilterSummary = require("../filters/filterSummary");
