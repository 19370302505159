class PortalRoutes {
  static get goToFormRoute() {
    return {
      as: "portal-go-to-form",
      handler: PortalController.goToForm,
    };
  }

  static get lidSelfInspectionResponseRoute() {
    return {
      as: "portal-lid-self-inspection-response",
      handler: LidSelfInspectionModal.show,
      hooks: {
        leave: function (done) {
          LidSelfInspectionModal.close();
          done(true);
        },
      },
    };
  }

  static get lidSelfInspectionConfirmationRoute() {
    return {
      as: "portal-lid-self-inspection-confirmation",
      handler: LidSelfInspectionConfirmationModal.show,
      hooks: {
        leave: function (done) {
          LidSelfInspectionConfirmationModal.close();
          done(true);
        },
      },
    };
  }

  static get indcomFacilitySelfInspectionResponseRoute() {
    return {
      as: "portal-indcom-facility-self-inspection-response",
      handler: IndcomFacilitySelfInspectionModal.show,
      hooks: {
        leave: function (done) {
          IndcomFacilitySelfInspectionModal.close();
          done(true);
        },
      },
    };
  }

  static get indcomFacilitySelfInspectionConfirmationRoute() {
    return {
      as: "portal-indcom-facility-self-inspection-confirmation",
      handler: IndcomFacilitySelfInspectionConfirmationModal.show,
      hooks: {
        leave: function (done) {
          IndcomFacilitySelfInspectionConfirmationModal.close();
          done(true);
        },
      },
    };
  }
}

module.exports = PortalRoutes;

const PortalController = require("../../portal/portalController");
const LidSelfInspectionModal = require("../../portal/lidSelfInspection/lidSelfInspectionModal");
const LidSelfInspectionConfirmationModal = require("../../portal/lidSelfInspection/lidSelfInspectionConfirmationModal");
const IndcomFacilitySelfInspectionModal = require("../../portal/indcomFacilitySelfInspection/indcomFacilitySelfInspectionModal");
const IndcomFacilitySelfInspectionConfirmationModal = require("../../portal/indcomFacilitySelfInspection/indcomFacilitySelfInspectionConfirmationModal");
