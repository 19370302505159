"use strict";

const Config = function () {
  const toolLinks = {
    lid: "/ram/lid",
    trashram: "/ram/trashram",
    bmpram: "/ram/bmpram",
    construction: "/ram/construction",
    idde: "/ram/idde",
    indcom: "/ram/indcom",
    muni: "/ram/muni",
    peo: "/ram/peo",
    outmon: "/ram/outmon",
    report: "/report/",
    pie: "/report/",
    assets: "/ram/assets",
    projdelivery: "/ram/projdelivery",
    compcal: "/ram/compcal",
    suit: "/ram/suit",
    scenario: "/ram/scenario",
  };

  const isDevDomain = function () {
    return (
      getLocationUrl().hostname === "dev.2nformdev.com" ||
      getLocationUrl().hostname === "esg.2nformdev.com"
    );
  };

  const isProductionDomain = function () {
    return (
      getLocationUrl().hostname === "2nform.com" || getLocationUrl().hostname === "rainsteward.com"
    );
  };

  const getLocationUrl = function () {
    let url;

    if (isWorkerContext()) {
      url = self.registration.scope;
    } else {
      url = window.location.href;
    }

    return new URL(url);
  };

  const isWorkerContext = function () {
    return typeof window === "undefined";
  };

  const getSite = function () {
    const hostname = getLocationUrl().hostname;
    if (hostname.includes("esg") || hostname === "rainsteward.com") {
      return "esg";
    } else if (getLocationUrl().pathname.startsWith("/portal/")) {
      return "portal";
    }

    return "2nform";
  };

  const get = function () {
    const common = {
      origin: getLocationUrl().origin,
      site: getSite(),
      toolLinks,
    };

    let config;

    if (isDevDomain()) {
      config = {
        environment: "Dev Dev",
        apiUrl: "https://apidev.2nformdev.com/public",
      };
    } else if (isProductionDomain()) {
      config = {
        environment: "Production",
        apiUrl: "https://api.2nform.com/public",
      };
    } else {
      config = {
        environment: "local",
        apiUrl: "http://api.localhost/public",
      };
    }

    return { ...common, ...config };
  };

  return {
    get,
    isWorkerContext,
    isProductionDomain,
  };
};

module.exports = Config();
