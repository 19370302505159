const PropertiesIcon = function () {
  var getIcon = function (symbol, customSize = null) {
    const currentMap = Map.getMap();
    const currentZoomLevel = currentMap?.getZoom();
    let iconSize;
    if (customSize) {
      iconSize = customSize;
    } else if (!currentZoomLevel) {
      iconSize = [12, 12];
    } else {
      currentZoomLevel < 7
        ? (iconSize = [8, 8])
        : currentZoomLevel < 11
          ? (iconSize = [12, 12])
          : currentZoomLevel < 13
            ? (iconSize = [18, 18])
            : currentZoomLevel < 16
              ? (iconSize = [26, 26])
              : (iconSize = [36, 36]);
    }

    return new L.DivIcon({
      iconSize: iconSize,
      popupAnchor: [0, 0],
      className: getClassAndColor(symbol).class,
    });
  };

  function getClassAndColor(symbol) {
    switch (symbol) {
      case 1:
        return { class: "esg-property-very-high", color: "#004370" };
      case 2:
        return { class: "esg-property-high", color: "#2D658B" };
      case 3:
        return { class: "esg-property-moderate", color: "#4F80A0" };
      case 4:
        return { class: "esg-property-low", color: "#8FAFC3" };
      case 5:
        return { class: "esg-property-very-low", color: "#C5D6E1" };
      default:
        throw new Error(`Invalid esg_property_symbol: ${symbol}`);
    }
  }

  return {
    getIcon,
    getClassAndColor,
  };
};

module.exports = PropertiesIcon();
const Map = require("../map");
