"use strict";

const PropertyRankingModal = function () {
  let $modal;

  const loadListeners = function () {
    $modal.off();

    $modal.find("[value='cancel']").on("click", () => $modal.modal("hide"));
    $modal.find("[value='ok']").on("click", updatePropertyRankings);
  };

  const render = async function () {
    const data = await EsgApiCalls.getPropertyRankingSettings();
    $modal = $("#property-ranking-modal");
    $modal.html(nunjucks.render("esg/modals/editPropertyRankingModal.njk", data));

    Slider.init(_updateSumOfWeights, $modal);
    $modal.modal("show");
    loadListeners();
  };

  const _updateSumOfWeights = function () {
    const sumOfWeights = $modal
      .find(".slider input[type='range']")
      .toArray()
      .reduce((sum, slider) => {
        return sum + Number($(slider).val());
      }, 0);
    const maxWidth = $modal.find("#weight").css("max-width");
    const width = sumOfWeights * parseInt(maxWidth);
    $modal.find("#weight").css("width", width);
    const sumOfWeightsPercent = Math.round(sumOfWeights * 100);
    $modal.find("#weight-label").text(sumOfWeightsPercent + "%");
    if (sumOfWeightsPercent < 25) {
      $modal.find("#weight-label").addClass("outside").css("left", width);
    } else {
      $modal.find("#weight-label").removeClass("outside").css("left", 0);
    }
    if (sumOfWeightsPercent > 100) {
      const exceededWidth = (sumOfWeights - 1) * parseInt(maxWidth);
      const exceededWidthPercent = sumOfWeightsPercent - 100;
      $modal.find("#exceeded").css("width", exceededWidth);
      $modal.find("#exceeded-label").text(exceededWidthPercent + "% exceeded");
      if (exceededWidthPercent < 65) {
        $modal.find("#exceeded-label").addClass("outside").css("left", exceededWidth);
      } else {
        $modal.find("#exceeded-label").removeClass("outside").css("left", 0);
      }
    } else {
      $modal.find("#exceeded").css("width", 0);
      $modal.find("#exceeded-label").text("");
    }
    if (sumOfWeightsPercent <= 100) {
      $modal
        .find("#left-label")
        .show()
        .text(100 - sumOfWeightsPercent + "% left");
    } else {
      $modal.find("#left-label").hide();
    }

    $modal.find("button[value=ok]").toggleClass("disabled", sumOfWeightsPercent !== 100);
  };

  const updatePropertyRankings = async function () {
    const data = _getValuesFromSliders();
    await EsgApiCalls.updatePropertyRankingSettings(data);
    await PageController.refresh();
    $modal.modal("hide");
  };

  const _getValuesFromSliders = function () {
    return {
      wsWeight: $modal.find("[name=ws-weight]").val(),
      wqWeight: $modal.find("[name=wq-weight]").val(),
      frWeight: $modal.find("[name=fr-weight]").val(),
      uhWeight: $modal.find("[name=uh-weight]").val(),
      imperviousWeight: $modal.find("[name=impervious-weight]").prop("checked"),
    };
  };

  return {
    render,
  };
};

module.exports = PropertyRankingModal();

const Slider = require("../../general/slider");
const EsgApiCalls = require("../esgApiCalls");
const PageController = require("../pageController");
