"use strict";

var LitterHotspotEsriDynamicMapLayer = function () {
  const esriKey = "trash";
  const esriLayer = "litterHotspot";
  const layerName = "litterHotspotEsriDynamicMap";
  const layerKey = "litterHotspotEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var layer = new EsriDynamicMapLayer(layerName, layerKey, esriKey, esriLayer);
    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = LitterHotspotEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
