"use strict";

const NavTabs = function () {
  let $container;
  let inited = false;

  function init() {
    $container = $("#tool-header .tool-tabs-container");
    $container.off("click", "li", _onTabClick);
    $container.on("click", "li", _onTabClick);
    inited = true;
  }

  function setupTabs(params) {
    if (!inited) init();
    setEnabledTabs(params?.enabledTabs ?? []);
    setActiveTab(params?.activeTab ?? "");
  }

  function _onTabClick(e) {
    const $target = $(e.currentTarget).find("a");
    const mapTarget = $target.data("maptarget"); // route to page
    const asset = $target.data("asset");
    let data;

    if (asset) {
      const currentUrl = window.location.pathname.split("/");
      data = currentUrl[currentUrl.lastIndexOf(asset) + 1];
    }

    setActiveTab();
    const hash = window.location.hash;
    UrlRoutes.go(mapTarget, { planSlug: data });
    if (mapTarget !== "impact") window.location.hash = hash;
  }

  function setEnabledTabs(tabs) {
    $container.find("li").toggleClass("hidden", true);
    tabs.forEach((tab) => {
      $container.find(`[data-maptarget=${tab}]`).parents("li").toggleClass("hidden", false);
    });
  }

  function getActiveTab() {
    if ($container.find(".active").length) {
      return $container.find(".active a").attr("data-maptarget");
    }
    return "selected";
  }

  function setActiveTab(tab) {
    $container.find("li").toggleClass("active", false);
    if (tab) {
      $container.find(`[data-maptarget=${tab}]`).parents("li").toggleClass("active", true);
    }
  }

  return {
    init,
    setupTabs,
    setEnabledTabs,
    setActiveTab,
    getActiveTab,
  };
};
module.exports = NavTabs();

const UrlRoutes = require("../routes/urlRoutes");
