class TableSort {
  sortingKey = null;
  sortingReversed = null;
  defaultReverse = false;
  sortersDict;
  $container;

  constructor($container, sortersDict) {
    this.$container = $container;
    this.sortersDict = sortersDict;
  }

  static updateHeaders(sorting, $container) {
    $container.find(`th[data-sort-key]`).removeClass("sorting");

    if (sorting.key) {
      const $header = $container.find(`th[data-sort-key="${sorting.key}"]`);
      $header.addClass("sorting");
      $header.find(".sort-arrow").toggleClass("flipped", !!sorting.reversed);

      const $mobileSortHeader = $container.find("th.icon-col");
      $mobileSortHeader.addClass("sorting");
      $mobileSortHeader.find(".sort-arrow").toggleClass("flipped", !!sorting.reversed);
    }
  }

  sort(data) {
    SortUtilities.sortRows(this.sortingKey, this.sortingReversed, data, this.sortersDict);
  }

  sortWithDict(data, sortersDict) {
    SortUtilities.sortRows(this.sortingKey, this.sortingReversed, data, sortersDict);
  }

  updateHeaders = function () {
    TableSort.updateHeaders(this.getSorting(), this.$container);
  };

  getSorting = function () {
    return {
      key: this.sortingKey,
      reversed: this.sortingReversed,
    };
  };

  setSorting = function (key = null, reversed = undefined) {
    if (reversed === undefined) {
      if (this.sortingKey === key) {
        key = null;
        this.sortingReversed = !this.sortingReversed;
      } else {
        this.sortingReversed = this.defaultReverse;
      }
    } else {
      this.sortingReversed = reversed;
    }

    if (key) {
      this.sortingKey = key;
    }
  };
}

module.exports = TableSort;

const SortUtilities = require("../general/sortUtilities");
