"use strict";

const BmpFcsModalMap = function () {
  var changeTreatmentTrainPolygons = async function (centralizedBmpId) {
    const centralizedBmps = BmpFcsInventoryModalController.getOtherCentralizedBmps();
    const cBmp = centralizedBmps.find((bmp) => {
      return bmp.idbmp === centralizedBmpId;
    });
    await DrawController.setDrawnDrainageGeom(JSON.parse(cBmp.dissolvedDrainage.geom));
  };

  return {
    changeTreatmentTrainPolygons,
  };
};

module.exports = BmpFcsModalMap();

const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
const DrawController = require("./drawController");
