"use strict";

const CustomWmsLayer = function () {
  L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
    loadTile: async function (coords, done, img) {
      const fullUrl = this.getTileUrl(coords);
      const [url, urlparams] = fullUrl.split("?");
      const controller = new AbortController();
      const observer = stopLoadingOnLeafletAbort(img, controller);
      let objectUrl = null;

      try {
        const blobData = await fetchDataAsBlob(url, urlparams, controller.signal);
        observer.disconnect();

        objectUrl = window.URL.createObjectURL(blobData);
        img.src = objectUrl;
        img
          .decode()
          .catch(() => {})
          .finally(() => window.URL.revokeObjectURL(objectUrl));
      } catch (e) {
        observer.disconnect();
        window.URL.revokeObjectURL(objectUrl);
        if (controller.signal.aborted) {
          return;
        }
        NetworkError.assertIs(e);
        console.error(`Failed to fetch ${fullUrl}`);
      }

      done(undefined, img);
    },

    createTile: function (coords, done) {
      const img = document.createElement("img");
      this.loadTile(coords, done, img);

      return img;
    },
  });

  const betterWms = (L.tileLayer.betterWms = function (url, options) {
    return new L.TileLayer.BetterWMS(url, options);
  });

  /*
    Leaflet internally calls `_abortLoading()` and removes any `img` elements that haven't been loaded yet. But because we're using `fetch`, the `img.complete` property is always `true`. So instead we can wait for when Leaflet later calls `_removeTile()` and sets the img `src` to an empty string. So we watch for `src` change and cancel the fetch.
    See https://github.com/Leaflet/Leaflet/blob/v1.3.4/src/layer/tile/TileLayer.js#L240
  */
  const stopLoadingOnLeafletAbort = function (img, controller) {
    const observer = new MutationObserver(() => controller.abort());
    observer.observe(img, {
      attributes: true,
      attributeFilter: ["src"],
    });

    return observer;
  };

  const fetchDataAsBlob = async function (url, urlparams, signal) {
    const username = ToolSettings.getSetting("geoserver", "username");
    const password = ToolSettings.getSetting("geoserver", "password");
    const response = await Network.fetchFrom(url, {
      method: "POST",
      body: urlparams,
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + btoa(username + ":" + password),
      },
      signal,
    });
    return await response.blob();
  };

  return { betterWms };
};

module.exports = CustomWmsLayer();

const Network = require("../network");
const NetworkError = require("../errors/networkError");
const ToolSettings = require("../settings/toolSettings");
