"use strict";

const SideBar = function () {
  let config;
  let data;
  let filterModule;
  let smartFilter;
  let hasSideBar;

  const init = async function (_config = null, _data = null, layerName = null) {
    config = _config;

    if (_data && layerName) Tree.set(["layers", layerName, "data"], _data);
    if (layerName) {
      data = Tree.get("layers", layerName, "data");
      filterModule = GeoServerLayerFunctions.getGeoServerLayerProperty(layerName, "filterModule");
    }

    if (config) _render();
    hasSideBar = !!config;
    _toggle();
  };

  const _render = function () {
    Tree.set("isDefaultFilter", true);
    Tree.set("activeTab", "data");
    Table.render();
    Table.loadListeners();
    Table.setDataColTooltips();
    Filters.loadFilterBarListeners();
    Filters.renderFiltersMenu();
    _toggleList();
    _loadSmartFilters();
    renderFilters();
    _setFilterBarHeading("Filters/Search");
    _loadListeners();
    _toggleFilterBarButtons();
  };

  const _loadSmartFilters = function () {
    smartFilter?.destroy();

    if (!filterModule) {
      return;
    }

    smartFilter = new SmartFilters(filterModule.getFilterConfigs(), {
      onSetDefault: renderFilters,
    });
    filterModule.setSmartFilters(smartFilter);
  };

  const _toggle = async function () {
    $("#bottomFloatingInputsTable").toggleClass("hide-catchment-expand", !hasSideBar);
    $(".floating-inputs").css("display", hasSideBar ? "block" : "none");
    $(".page.current").toggleClass("with-side-bar", hasSideBar);
  };

  function _getNextPrevRowId(isNext) {
    const selector = ".table-datum:visible";
    const selectedRow = $(`.table-datum.map-hover`);
    let targetRow = isNext
      ? selectedRow.nextAll(selector).first()
      : selectedRow.prevAll(selector).first();
    targetRow = targetRow.length ? targetRow : isNext ? $(selector).first() : $(selector).last();
    return targetRow.data("id");
  }

  function getNextId() {
    const isNext = true;
    return _getNextPrevRowId(isNext);
  }

  function getPreviousId() {
    const isNext = false;
    return _getNextPrevRowId(isNext);
  }

  function goToNext() {
    config.onRowClick(getNextId());
  }

  function goToPrevious() {
    config.onRowClick(getPreviousId());
  }

  const _loadListeners = function () {
    const $filtersContainer = $("#bottomFloatingInputsTable");
    $(window).on("resize", MapFunctions.setMapHeight); // to update table height
    $(".floating-inputs").off().on("click", ".table-datum", _onRowClick);
    $(".search-input input").off().on("input", _searchSideBar);
    $(".sort-button").on("click", _searchSideBar);
    $(".search-button").on("click", _searchButtonClick);

    Filters.loadAnalyticsListeners($filtersContainer);
  };

  const _onRowClick = function () {
    config.onRowClick($(this).data("id"));
  };

  const _searchSideBar = function () {
    const search = $(".search-input input").val();
    Tree.set(["filters", "searchString"], search);
    FilterDom.filterByText(search, $(".table-datum"));
    highlightRow();
  };

  const _searchButtonClick = function () {
    $(".search-input input").val("");
    _searchSideBar();
  };

  const highlightRow = function (
    dataId = Tree.get("tool") === "esg"
      ? PageController.getData()?.id
      : Tree.get("factSheet", "id"),
  ) {
    $(".map-hover").removeClass("map-hover");
    $('.table-datum[data-id="' + dataId + '"]').addClass("map-hover");
    const $selected = $(".map-hover");
    const $container = $("#bottomFloatingInputsTable");
    const topPadding = $(".ms4-return-button.hidden").length ? 0 : $(".ms4-return-button").height();
    DomFunctions.scrollIntoView($selected, $container, topPadding);
  };

  const isFlatList = function () {
    return config?.isFlatList || false;
  };

  const _toggleList = function () {
    $(".data-sort-dropdown").toggleClass("hidden", !isFlatList());
    $(".sort-button").closest(".icon-button").toggleClass("hidden", !isFlatList());
  };

  var renderFilters = function (filters = null) {
    if (!config?.filtersTemplate) return;
    $("#bottomFloatingInputsTable").html(
      nunjucks.render(config.filtersTemplate, {
        dropdownOptions: PageController.getDropdownOptions(false),
        filters: filters || PageController.getDefaultFilters(),
        hiddenFields: EsgSettings.hiddenFields(),
        data,
      }),
    );
    smartFilter.init($(".display-filters"));
    MultipleSelect.init("#bottomFloatingInputsTable");
  };

  var _setFilterBarHeading = function (text) {
    $("#filter-bar-heading").text(text).toggleClass("hidden", !config.filtersTemplate);
  };

  var _toggleFilterBarButtons = function () {
    if (!$(".main-content").length) return;

    const show = !!config.filterBarButtons;
    $(".item-count-button, .search-button").toggle(show);
    if (!show) $(".search-input").hide();
    MapFunctions.setMapHeight();
  };

  const hideCollapsedSideBar = function () {
    $("#collapsed-side-table").addClass("hidden");
  };

  return {
    init,
    getNextId,
    getPreviousId,
    goToNext,
    goToPrevious,
    isFlatList,
    hasSideBar: () => hasSideBar,
    renderFilters,
    _toggle,
    _toggleList,
    _setFilterBarHeading,
    highlightRow,
    hideCollapsedSideBar,
  };
};

module.exports = SideBar();

const DomFunctions = require("../general/domFunctions");
const FilterDom = require("../general/filterDom");
const MultipleSelect = require("../general/multipleSelect");
const Filters = require("../mapFunctions/filters");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const MapFunctions = require("../mapFunctions/mapFunctions");
const SmartFilters = require("../mapFunctions/smartFilters");
const Table = require("../mapFunctions/table");
const Tree = require("../tree");
const EsgSettings = require("./esgSettings");
const PageController = require("./pageController");
