"use strict";

var FilterActions = function () {
  var filterTableByUntreatedPluPercentile = function (filter) {
    Actions.loadCatchments(Tree.get("mapId"), filter);
    setSortDropdownForFilterSpatialType(filter["spatialTypeUntreatedPlu"][0]);
  };

  var setSortDropdownForFilterSpatialType = function (spatialType) {
    if (spatialType !== null) {
      if (spatialType == "catchment") {
        Filters.setDataSortDropdownSelection("plu_untreated", "Untreated PLU");
      } else if (spatialType == "drains_to_c") {
        Filters.setDataSortDropdownSelection("ud", "Urban Drainages");
      } else {
        console.error(`Unexpected spatial type: ${spatialType}`);
      }
    }
  };

  return {
    filterTableByUntreatedPluPercentile,
  };
};

module.exports = FilterActions();

const Tree = require("../../tree");
const Actions = require("../actions");
const Filters = require("./filters");
