"use strict";

const BrowserSupportsFeature = function () {
  let allFeaturesSupported = true;

  const checkRequired = function () {
    offline();
    promise();
    return all();
  };

  // Fails on older versions of Chrome
  const promise = function () {
    const supported = "Promise" in window && "allSettled" in window.Promise;
    return featureSupported(supported, "Promises unsupported");
  };

  // Fails on older browsers
  // Fails on FireFox in private browsing mode
  const offline = function () {
    const supported = "indexedDB" in window && "serviceWorker" in navigator;
    featureSupported(supported, "Offline unsupported");
    return supported;
  };

  // Fails on iOS/Safari in private browsing mode
  // There's lots of other IndexedDB weirdness that happens,
  // but this is an easy to test feature we use
  const offlineImages = async function () {
    const storeName = "test";

    try {
      const db = new Database("feature-test", [
        {
          key: "initial",
          migration: function (migrationDb) {
            migrationDb.createObjectStore(storeName, { keyPath: "id" });
          },
        },
      ]);

      const transaction = await db.getTransaction(storeName, "readwrite");
      await transaction.objectStore(storeName).put({
        id: 1,
        data: new Blob(),
      }).done;
    } catch (e) {
      return featureSupported(false, `Offline images unsupported: ${e.message}`);
    }

    return true;
  };

  const featureSupported = function (supported, message) {
    if (!supported) {
      console.error(`Offline images unsupported: ${message}`);
      allFeaturesSupported = false;
    }

    return supported;
  };

  const all = function () {
    return allFeaturesSupported;
  };

  return { checkRequired, offlineImages, offline, promise, all };
};

module.exports = BrowserSupportsFeature();

const Database = require("../offline/database");
