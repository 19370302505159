"use strict";

const SignaturePadExtensions = function () {
  const SignaturePad = require("signature_pad");
  const originalCreatePoint = SignaturePad.prototype._createPoint;

  var run = function () {
    SignaturePad.prototype._createPoint = convertToCanvasRelativePoint;
  };

  var convertToCanvasRelativePoint = function (x, y) {
    var point = originalCreatePoint.apply(this, [x, y]);
    const offset = getContentBoxOffset($(this.canvas));
    const ratio = getScaleRatio(this.canvas, offset);

    point.x = (point.x - offset.left) * ratio.width;
    point.y = (point.y - offset.top) * ratio.height;

    return point;
  };

  var getScaleRatio = function (canvas, offset) {
    const rect = canvas.getBoundingClientRect();
    return {
      width: canvas.width / (rect.width - offset.left - offset.right),
      height: canvas.height / (rect.height - offset.top - offset.bottom),
    };
  };

  var getContentBoxOffset = function ($element) {
    return {
      top: getCssNumber($element, "border-top-width") + getCssNumber($element, "padding-top"),
      right: getCssNumber($element, "border-right-width") + getCssNumber($element, "padding-right"),
      bottom:
        getCssNumber($element, "border-bottom-width") + getCssNumber($element, "padding-bottom"),
      left: getCssNumber($element, "border-left-width") + getCssNumber($element, "padding-left"),
    };
  };

  var getCssNumber = function ($element, cssProperty) {
    return parseFloat($element.css(cssProperty));
  };

  return {
    run,
  };
};

module.exports = SignaturePadExtensions();
