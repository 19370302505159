"use strict";

const BulkSelectFunctions = function () {
  // @TODO: Merge with bulkSelectPage pageConfig
  var getApiCallHandler = function (actionKey) {
    switch (actionKey) {
      case "catchBasinBulkClean":
        return saveCatchBasinBulkClean;
      default:
        console.error("ApiCallHanlder not defined");
    }
  };

  // @TODO: Merge with bulkSelectPage pageConfig
  var getRefreshLayerHandler = function (actionKey) {
    switch (actionKey) {
      case "catchBasinBulkClean":
        return handleCatchBasinBulkCleanRefreshLayer;
      default:
        console.error("RefreshLayerHandler not defined");
    }
  };

  var saveCatchBasinBulkClean = async function () {
    var selectedIds = Tree.get(["bulkSelect", "selectedIds"]);
    var date = Tree.get(["bulkSelect", "date"]);
    await ApiCalls.saveCatchBasinBulkClean(selectedIds, date);
  };

  var handleCatchBasinBulkCleanRefreshLayer = function () {
    const MuniCatchBasinGeoServerLayer = require("../muni/muniCatchBasinGeoServerLayer");
    MuniCatchBasinGeoServerLayer.invalidateLayerData();
  };

  return {
    getApiCallHandler,
    getRefreshLayerHandler,
  };
};

module.exports = BulkSelectFunctions();

const ApiCalls = require("../apiCalls");
const Tree = require("../tree");
