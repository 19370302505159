"use strict";

const LidSelfInspectionController = function () {
  var init = function () {
    loadListeners();
  };

  var loadListeners = function () {
    const $lidDataListContainer = $("#lid .data-list-container");

    $lidDataListContainer.off("click", ".mail-button", mailButtonClick);
    $lidDataListContainer.on("click", ".mail-button", mailButtonClick);
  };

  var mailButtonClick = function () {
    var $tr = $(this).parents("tr");
    var projectId = $tr.data("id");
    var modalAction = $(this).data("modalAction") || $(this).data("action");

    if (modalAction === "update-response") {
      LetterCommunicationModal.renderAndShow([projectId]);
    } else if (modalAction === "generate-letter") {
      LetterCommunicationModal.renderAndShow([projectId], { action: "send" });
    } else {
      throw Error(`modalAction not defined: ${modalAction}`);
    }
  };

  return {
    init,
  };
};

module.exports = LidSelfInspectionController();

const LetterCommunicationModal = require("./letterCommunicationModal");
