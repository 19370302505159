"use strict";

const ProjectInspectionRequiredObservations = function () {
  const stringKey = "required-observations";
  const tabHeader = "Required Observations";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on("click", "[name=add-observation]", _onAddObservation);
    $page.on("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var unloadListeners = function () {
    $page.off("click", "[name=add-observation]", _onAddObservation);
    $page.off("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var getProps = function (data) {
    return {
      requiredObservationOptions: ProjectInspectionConstants.getRequiredObservationOptions(),
      requiredObservationConditionOptions:
        ProjectInspectionConstants.getRequiredObservationConditionOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var _onAddObservation = function () {
    const $button = $(this);
    TemporaryBmpObservation.addObservation($button, "requiredObservations");
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _onAddObservation,
  };
};

module.exports = ProjectInspectionRequiredObservations();

const ProjectInspectionConstants = require("./projectInspectionConstants");
const TemporaryBmpObservation = require("./temporaryBmpObservation");
