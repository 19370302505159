"use strict";

var Survey123UrlBuilder = function () {
  const bmpInstallationInspectionSurveyItemId = "3380bd24168a484e808331738f17e718";

  var initialFilter = [
    "Group_Id",
    "Proj_ID",
    "Proj_Id_2N",
    "Proj_Name",
    "Insp_Name",
    "LRP_Name",
    "LRP_Phone",
    "LRP_Email",
    "Cont_Name",
    "Cont_Phone",
    "Cont_Email",
  ];

  var getBmpInstallationInspectionUrl = function (fields) {
    var url = "arcgis-survey123://";
    url += "?itemID=" + bmpInstallationInspectionSurveyItemId + "&";
    var installationFilter = initialFilter.concat(["BMP_ID_2N", "BMP_ID", "sbmp_type", "actions"]);
    var prefillString = getFieldPrefillString(fields, installationFilter);
    return url + prefillString;
  };

  var getFieldPrefillString = function (fields, filter) {
    var fieldsToPrefill = [
      { survey123Key: "Proj_ID", value: fields.projectIdentifier },
      { survey123Key: "Proj_Id_2N", value: fields.projectId || fields.id },
      { survey123Key: "Group_Id", value: fields.groupId },
      { survey123Key: "Proj_Name", value: fields.projectName },
      { survey123Key: "Insp_Name", value: Session.getUser().username },
      { survey123Key: "Risk_Level", value: fields.permitInfo?.cgpRiskLevel },
      { survey123Key: "BMP_ID", value: fields.bmpId },
      { survey123Key: "BMP_ID_2N", value: fields.id },
      { survey123Key: "sbmp_type", value: fields.bmpType },
      {
        survey123Key: "actions",
        value: fields.activityTypes ? fields.activityTypes.join(",") : " ",
      },
      {
        survey123Key: "Prioritiz",
        value:
          fields.highPriority === true ? "High" : fields.highPriority === false ? "None" : null,
      },
      {
        survey123Key: "Insp_Freq",
        value: fields.nextInspection?.routineInspectionDisplayFrequency,
      },
      {
        survey123Key: "LRP_Name",
        value: fields.owner?.name ?? " ",
      },
      {
        survey123Key: "LRP_Phone",
        value: fields.owner?.phoneNumber ?? " ",
      },
      {
        survey123Key: "LRP_Email",
        value: fields.owner?.email ?? " ",
      },
      {
        survey123Key: "Cont_Name",
        value: fields.contractor?.name ?? " ",
      },
      {
        survey123Key: "Cont_Phone",
        value: fields.contractor?.phoneNumber ?? " ",
      },
      {
        survey123Key: "Cont_Email",
        value: fields.contractor?.email ?? " ",
      },
      { survey123Key: "Rain_start", value: fields.rainySeasonStartDate },
      { survey123Key: "Rain_end", value: fields.rainySeasonEndDate },
      { survey123Key: "Score_Prev", value: fields.score },
      {
        survey123Key: "sync_enforcements_within_project",
        value: fields.syncEnforcementsWithinProject,
      },
    ];

    fieldsToPrefill = filterFields(fieldsToPrefill, filter);

    var fieldsToPrefillJoined = fieldsToPrefill
      .map((field) => {
        var value = field.value;
        if (value === null || value === undefined || value === "") {
          value = " ";
        }
        return `field:${field.survey123Key}=${encodeURIComponent(value)}`;
      })
      .join("&");
    return fieldsToPrefillJoined;
  };

  var filterFields = function (fields, filter) {
    return fields.filter((field) => {
      return filter.includes(field.survey123Key);
    });
  };

  return {
    getBmpInstallationInspectionUrl,
  };
};

module.exports = Survey123UrlBuilder();

const Session = require("../login/session");
