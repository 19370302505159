"use strict";

var Actions = function () {
  var loadCatchments = function (mapId, filter) {
    if (Tree.get(["layers", mapId, "catchments", "isFetching"])) {
      return;
    }

    Tree.set(["layers", mapId, "catchments", "isFetching"], true);
    const year = AnnualReports.getSelectedYear();

    ReportApiCalls.getCatchments(
      function (data) {
        if (!data.catchments) {
          data = {
            catchments: data,
            receivingWaters: [],
            urbanDrainages: [],
            allMs4: {},
          };
        }
        Tree.set(["layers", mapId, "catchments", "isFetching"], false);
        Tree.set(["layers", mapId, "catchments", "data"], data.catchments);
        Tree.set(["spatialGroups", mapId, "receivingWaters"], data.receivingWaters);
        Tree.set(["spatialGroups", mapId, "urbanDrainages"], data.urbanDrainages);
        Tree.set(["spatialGroups", mapId, "allMs4"], data.allMs4);
      },
      filter,
      year,
    );
  };

  var loadHighways = async function (mapId) {
    if (!mapId || Tree.get(["layers", mapId, "highways", "isFetching"])) {
      return;
    }
    Tree.set(["layers", mapId, "highways", "isFetching"], true);
    await ReportApiCalls.getHighways().then(function (data) {
      Tree.set(["layers", mapId, "highways", "isFetching"], false);
      Tree.set(["layers", mapId, "highways", "data"], data);
    });
  };

  var loadBmps = function () {
    var sbmpLayerFilters = FilterConstants.sbmpLayerFilters;
    var filters = Object.assign({}, Tree.get("filters"), sbmpLayerFilters);
    var role = Session.loadContext().group_role;

    Tree.set(["layers", Tree.get("mapId"), "bmps", "isFetching"], true);
    if (role !== "partner") {
      ReportApiCalls.getSbmpProgress(filters, function (data) {
        data = data.filter(function (bmp) {
          return bmp.isBmp;
        });
        Tree.merge(["layers", Tree.get("mapId"), "bmps"], {
          isFetching: false,
          data: data,
        });
      });
    }
  };

  var selectCatchmentsByFilter = function (mapLayers) {
    var filters = Tree.get("filters");
    if (mapLayers.catchmentLayer) mapLayers.catchmentLayer.filterDrainsTo(filters);
  };

  var getCatchmentData = function () {
    var mapId = Tree.get("mapId");
    var layerName = DataViewFunctions.getCurrentLayerName();
    if (LayerFunctions.getCurrentLayerProperty("getCatchmentDataFromLayer")) {
      return Tree.select(["layers", mapId, layerName, "rawData"]).deepClone();
    }
    return Tree.select(["layers", mapId, "catchments", "data"]).deepClone();
  };

  var toggleLayerEnabled = function (layerName, isEnabled, mapLayers, mapId) {
    //The swtich statement handles the weird layers that depend on each other
    switch (layerName) {
      case "trashLines":
        Tree.set(["layers", mapId, "trashPoints", "isEnabled"], isEnabled);
        break;
    }
    Tree.set(["layers", mapId, layerName, "isEnabled"], isEnabled);
  };

  var loadParcelCanvasLayer = function () {
    Tree.set(["layers", "parcelTiles", "isFetching"], true);
    ReportApiCalls.getAllParcels(function (parcelData, conditionData) {
      var parcelTiles = CleanData.cleanGeoJSON(parcelData);
      CleanData.applyParcelConditions(parcelTiles.features, conditionData);
      Tree.merge(["layers", "parcelTiles"], {
        data: parcelTiles,
        isFetching: false,
      });
    });
  };

  var loadMs4Boundary = function (mapId) {
    if (Tree.get(["layers", mapId, "ms4Boundary", "isFetching"])) {
      return;
    }

    Tree.set(["layers", mapId, "ms4Boundary", "isFetching"], true);
    const year = AnnualReports.getSelectedYear();

    ReportApiCalls.getBoundary(year, function (data) {
      const geoJson = CleanData.cleanGeoJSON(data);
      Tree.merge(["layers", mapId, "ms4Boundary"], {
        data: geoJson,
        isFetching: false,
      });
    });
  };

  var loadLanduse = function () {
    Tree.set(["layers", "landuse", "isFetching"], true);
    ReportApiCalls.loadLandUse(function (data) {
      var geoJson = CleanData.cleanGeoData(data);
      Tree.merge(["layers", "landuse"], {
        data: geoJson,
        isFetching: false,
      });
    });
  };

  var loadSubmittedMS4s = function () {
    const year = AnnualReports.getSelectedYear();
    const dataModule = DataViewFunctions.getCurrentDataModule();

    return ReportApiCalls.getSubmittedMS4s(year, dataModule, function (data) {
      Tree.set("submittedMS4sLoaded", true);
      const html = nunjucks.render("report/table/ms4Table.html", {
        data: data,
        onlySubmitted: true,
      });
      $(".bottom-floating-inputs-table").html(html);
    });
  };

  var loadStormdrains = function (mapId) {
    Tree.set(["layers", mapId, "stormdrains", "isFetching"], true);
    ReportApiCalls.getStormdrains(function (data) {
      var geoJson = CleanData.cleanGeoJSON(data, "line");
      Tree.set(["layers", mapId, "stormdrains", "data"], geoJson);
      Tree.set(["layers", mapId, "stormdrains", "isFetching"], false);
    });
  };

  var loadReceivingWaters = function (mapId) {
    var bbox = Tree.get("bbox");
    Tree.set(["layers", mapId, "streams", "isFetching"], true);
    ReportApiCalls.getReceivingWaters(bbox, function (data) {
      var geoJson = CleanData.cleanGeoJSON(data, "line");
      Tree.merge(["layers", mapId, "streams"], {
        data: geoJson,
        isFetching: false,
      });
    });
  };

  var loadCollectorData = function () {
    var groupId = Tree.get("activeGroup", "groupId");
    var filters = Tree.get("filters");

    ReportApiCalls.getTrashPoints(groupId, filters, function (data) {
      var geoJsonLines = CleanData.cleanGeoJSON(data[0]);
      Tree.merge(["layers", "trashLines"], {
        data: geoJsonLines,
        isFetching: false,
      });

      var geoJsonPoints = CleanData.cleanGeoJSON(data[1]);
      Tree.merge(["layers", "trashPoints"], {
        data: geoJsonPoints,
        isFetching: false,
      });
      var combinedArray = data[0].concat(data[1]);
      //update catch list for urban catchment table
      updateAppCatchArray(combinedArray, "trashLines");
    });
  };

  var updateAppCatchArray = function (objArray, layerName) {
    var filteredCatchArray = [];
    objArray.forEach(function (record) {
      if (filteredCatchArray.indexOf(record.catchid) == -1) {
        filteredCatchArray.push(record.catchid);
      }
    });
    Tree.set(["layers", layerName, "catchList"], filteredCatchArray);
    updateFilteredCatchData();
  };

  var updateFilteredCatchData = function () {
    // compiles array of catchments that contain filtered data by building
    // app-specific lists and then combining those lists for a table filter list

    var layers = Tree.get("layers");
    var collectorArray = layers.trashLines.catchList;
    var surveyArray = layers.survey123.catchList;

    if (layers.trashLines.isEnabled || layers.survey123.isEnabled) {
      if (layers.trashLines.isEnabled && layers.survey123.isEnabled) {
        var tempArray = [];
        surveyArray.forEach(function (record) {
          if (collectorArray.indexOf(record) == -1) {
            tempArray.push(record);
          }
        });
        tempArray = collectorArray.concat(tempArray);
        setFilteredCatchData(tempArray);
      } else if (!layers.trashLines.isEnabled && layers.survey123.isEnabled) {
        setFilteredCatchData(surveyArray);
      } else {
        setFilteredCatchData(collectorArray);
      }
    } else {
      Tree.set(["catchmentData", Tree.get("mapId")], []);
    }
  };

  var setFilteredCatchData = function (catchNameArray) {
    //actually sets the current filtered list for the urban catchment table
    var filteredCatchArray = [];
    var catchments = Tree.get("layers", Tree.get("mapId"), "catchments", "data");
    catchNameArray.forEach(function (catchment) {
      for (var i = 0; i < catchments.length; i++) {
        if (catchment == catchments[i].catchid) {
          filteredCatchArray.push(catchments[i]);
        }
      }
    });
    Tree.set(["catchmentData", Tree.get("mapId")], filteredCatchArray);
  };

  var toggleLegendDisplay = function (display, mapId) {
    Tree.set(["legendShowing", mapId], display);
  };

  var loadFCSs = function (mapId) {
    var filters = Tree.get("filters");
    var role = Session.loadContext().group_role;

    Tree.set(["layers", mapId, "fcs", "isFetching"], true);
    if (role !== "partner") {
      ReportApiCalls.getFcsProgress(filters, function (data) {
        const fcsData = data.filter(function (bmp) {
          return bmp.isFcs;
        });
        Tree.set(["layers", mapId, "fcs", "isFetching"], false);
        Tree.set(["layers", mapId, "fcs", "data"], fcsData);

        const $thisMap = PageFunctions.getCurrentPage();
        if (!fcsData.length && Tree.get("firstLoad", mapId)) {
          Tree.set(["layers", mapId, "fcs", "isEnabled"], false);
          $thisMap.find(".radio-fcs").prop("checked", false);
          $thisMap.find(".radio-priority").click();
          Tree.set(["firstLoad", mapId], false);
        } else if (fcsData.length && Tree.get("firstLoad", mapId)) {
          Tree.set(["firstLoad", mapId], false);
        }
      });
    }
  };

  var loadFcsDrainage = function (mapId) {
    if (!mapId) {
      mapId = Tree.get("mapId");
    }
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "fullDrainage", "isFetching"], true);
    ReportApiCalls.getFcsProgressDrainage(filters, function (data) {
      var geoJsonPolygons = CleanData.cleanGeoJSON(data);
      Tree.set(["layers", mapId, "fullDrainage", "isFetching"], false);
      Tree.set(["layers", mapId, "fullDrainage", "data"], geoJsonPolygons);

      if (data.length) {
        if (Tree.get("layers", Tree.get("mapId"), "fcs", "selectedId")) {
          highlightDrainage();
        }
      }
    });
  };

  const highlightDrainage = () => {
    const mapId = Tree.get("mapId");
    const selectedId = Tree.get("layers", mapId, "fcs", "selectedId");
    const fullDrainage = Tree.get("layers", mapId, "fullDrainage", "data");
    for (var index in fullDrainage.features) {
      if (fullDrainage.features[index].properties.idbmp == selectedId) {
        fullDrainage.features[index].properties["highlight"] = true;
      } else {
        fullDrainage.features[index].properties["highlight"] = false;
      }
    }
    //fix this, calling update twice but won't trigger update on leaf property changes.
    Tree.set(["layers", mapId, "fullDrainage", "data"], "");
    Tree.set(["layers", mapId, "fullDrainage", "data"], fullDrainage);
  };

  /* ****************** Photos ****************** */

  var loadPhotoList = function (id, layer) {
    var groupId = Tree.get("activeGroup", "groupId");
    Tree.set([layer + "_photos", id, "isFetching"], true);
    ReportApiCalls.getPhotoList(groupId, id, layer, function (photoList) {
      const cssList = initiatePhotoCss(photoList);
      Tree.merge([layer + "_photos", id], {
        items: photoList,
        isFetching: false,
        lastUpdated: Date.now(),
        cssList,
      });
    });
  };

  var deletePhoto = function (id, filename, layer) {
    var items = Tree.get(layer + "_photos", id, "items");
    var groupId = Tree.get("activeGroup", "groupId");
    items = items.filter(function (photo) {
      return photo.filename !== filename;
    });

    Tree.set([layer + "_photos", id, "items"], items);
    ReportApiCalls.deletePhoto(layer, id, filename, groupId, function () {
      // do nothing
    });
  };

  var updatePhotoCaption = function (id, filename, caption, layer) {
    var groupId = Tree.get("activeGroup", "groupId");

    // use baobab's descriptor object to find the first matching photo
    var cursor = Tree.select(layer + "_photos", id, "items", {
      filename: filename,
    });
    ReportApiCalls.savePhotoCaption(groupId, id, filename, caption, layer, function () {
      cursor.set(["caption"], caption);
      cursor.release();
    });
  };

  var loadAnnualReporting = async function (callback) {
    const groupId = Tree.get("activeGroup", "groupId");

    return ReportApiCalls.getAnnualReportingYears(groupId).then((data) => {
      AnnualReports.setMs4Years(data);
      if (callback) {
        callback(data);
      }
    });
  };

  var initiatePhotoCss = function (photoList) {
    const cssListArray = Array.apply(null, Array(photoList.length)).map(
      Number.prototype.valueOf,
      0,
    );
    return cssListArray;
  };

  return {
    toggleLayerEnabled,
    loadCatchments,
    loadHighways,
    loadBmps,
    loadReceivingWaters,
    loadPhotoList,
    deletePhoto,
    updatePhotoCaption,
    selectCatchmentsByFilter,
    loadStormdrains,
    loadCollectorData,
    loadLanduse,
    loadParcelCanvasLayer,
    loadMs4Boundary,
    toggleLegendDisplay,
    updateFilteredCatchData,
    loadFCSs,
    loadFcsDrainage,
    loadAnnualReporting,
    initiatePhotoCss,
    loadSubmittedMS4s,
    getCatchmentData,
  };
};

module.exports = Actions();

const ReportApiCalls = require("./reportApiCalls");
const CleanData = require("./mapFunctions/cleanData");
const Session = require("../login/session");
const Tree = require("../tree");
const AnnualReports = require("./annualReports");
const DataViewFunctions = require("./dataViewFunctions");
const PageFunctions = require("./pageFunctions");
const LayerFunctions = require("./mapFunctions/layerFunctions");
const FilterConstants = require("./mapFunctions/filterConstants");
