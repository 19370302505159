"use strict";

const BaseEnforcementController = require("../../enforcement/baseEnforcementController");

const LidEnforcementController = function () {
  const formKey = "lid-enforcement";
  const htmlPath = "modals/lidEnforcement";
  const headerTemplate = "modals/lidEnforcement/lidEnforcementHeader.njk";
  var base;

  const getTabModules = () => {
    return {
      EnforcementAction: require("./enforcementAction"),
      EnforcementLetterManagement: require("./enforcementLetterManagement"),
      EnforcementHistory: require("./enforcementHistory"),
    };
  };

  const newEnforcementApiFunction = (id) => ApiCalls.getNewLidEnforcement(id);
  const getEnforcementApiFunction = (id) => ApiCalls.getLidEnforcement(id);
  const createEnforcementApiFunction = (formData) => ApiCalls.issueLidEnforcement(formData);
  const updateEnforcementApiFunction = (formData) => ApiCalls.updateLidEnforcement(formData);

  const getAssetNameFunction = (data) => data.project.projectName;
  const getAssetIdFunction = (data) => {
    return { projectId: data.project.id };
  };

  const afterHideFunction = () => {
    PostConstructionProjectGeoServerLayer.invalidateLayerData();
    ToDoListController.loadTodos();
  };

  const beforeSaveFunction = (updates) => {
    delete updates.existingFailingBmpAssessments;
    delete updates.existingOpenIssues;
  };

  base = BaseEnforcementController(
    formKey,
    htmlPath,
    headerTemplate,
    getTabModules,
    newEnforcementApiFunction,
    getEnforcementApiFunction,
    createEnforcementApiFunction,
    updateEnforcementApiFunction,
    getAssetNameFunction,
    getAssetIdFunction,
    { afterHideFunction, beforeSaveFunction },
  );

  return {
    ...base,
  };
};

module.exports = LidEnforcementController();

const ApiCalls = require("../../apiCalls");
const PostConstructionProjectGeoServerLayer = require("../../constructionG2/postConstructionProjectGeoServerLayer");
const ToDoListController = require("../../mapFunctions/toDoListController");
