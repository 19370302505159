"use strict";

const BulkSelectionDraw = function () {
  var getBulkSelectLayer = null;
  var bulkSelectLayerGeomType = null;

  var init = function (map, getBulkSelectLayerCallback, layerGeomType, polyline) {
    getBulkSelectLayer = getBulkSelectLayerCallback;
    bulkSelectLayerGeomType = layerGeomType;
    DrawController.init(map, polyline, false);
    loadDrawCreatedListeners(map);
  };

  var loadDrawCreatedListeners = function (map) {
    map.off("draw:created");
    map.on("draw:created", handleDrawCreated);
  };

  var handleDrawCreated = function (e) {
    var intersectingData = getIntersectingData(e.layer, e.layerType);
    selectIntersectingData(intersectingData);
  };

  var getIntersectingData = function (drawnLayer, drawnLayerType) {
    return DrawFunctions.getDrawIntersectingData(
      drawnLayer,
      drawnLayerType,
      getBulkSelectLayer(),
      bulkSelectLayerGeomType,
    );
  };

  var selectIntersectingData = function (intersectingData) {
    const BulkSelectPage = require("../bulkSelect/bulkSelectPage");
    var selectedIds = intersectingData.map((datum) => {
      return datum.feature ? datum.feature.properties.id : datum.properties.id;
    });
    BulkSelectPage.handleMultiSelect(selectedIds);
  };

  var show = function () {
    DrawController.showDrawControl();
  };

  var hide = function () {
    DrawController.hideDrawControl();
  };

  return {
    init,
    show,
    hide,
  };
};

module.exports = BulkSelectionDraw();

const DrawController = require("./drawController");
const DrawFunctions = require("./drawFunctions");
