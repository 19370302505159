"use strict";

const MapTitle = function () {
  var init = function () {
    loadListeners();
    const mapTitle = Tree.get("activeGroup", "name");
    Tree.set("mapTitle", mapTitle);
    $(".map-title").show();
  };

  var loadListeners = function () {
    loadDataListeners();
  };

  var loadDataListeners = function () {
    Tree.select("mapTitle").on("update", (e) => {
      setMapTitle(e.data.currentData);
    });

    Tree.watch({
      activeTab: ["activeTab"],
      navToggleSelection: ["navToggleSelection"],
    }).on("update", function (e) {
      const target = e.target.get();
      const activeTab = target.activeTab;
      const navToggleSelection = target.navToggleSelection;
      const $mapTitle = $(".map-title");
      if (
        PageFunctions.getTabProperty(activeTab, "noPanels") ||
        (navToggleSelection === "list" && ["todo", "insight"].includes(activeTab))
      ) {
        $mapTitle.hide();
      } else {
        $mapTitle.show();
      }
    });

    Tree.select(["factSheet", "open"]).on("update", (e) => {
      const $mapTitle = $(".map-title");
      if (e.data.currentData === true) {
        $mapTitle.hide();
        $("#list-panel .action-button-div.single-action").hide();
      } else {
        $mapTitle.show();
        $("#list-panel .action-button-div.single-action").show();
      }
    });
  };

  var setMapTitle = function (title) {
    if (title.includes("Regional Board")) {
      title = title.replace("al Board", "");
    }
    $(".map-title-header").text(title);
    $(".todo-spatial-title").text(title);
  };

  var useWhiteTextBaseMap = function () {
    const whiteTextSet = new Set(["Hybrid", "Imagery", "DarkGray"]);
    toggleUseWhiteText(whiteTextSet.has(BasemapFunctions.getBasemap("main")));
  };

  var toggleUseWhiteText = function (toggle) {
    const $mapTitle = $(".map-title");
    toggle ? $mapTitle.addClass("white-text") : $mapTitle.removeClass("white-text");
  };

  return {
    init,
    useWhiteTextBaseMap,
    toggleUseWhiteText,
  };
};

module.exports = MapTitle();

const BasemapFunctions = require("./basemapFunctions");
const Tree = require("../tree");
const PageFunctions = require("../pageFunctions");
