"use strict";

const EnforcementHistory = function (
  EnforcementController,
  { prepareReasonsFunction = null } = {},
) {
  const stringKey = `enforcement-history`;
  const tabHeader = "Enforcement History";
  let $page;

  var _prepareReasons = function (historyItem) {
    return prepareReasonsFunction?.(historyItem) ?? [];
  };

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("click", ".letter-file-download", _downloadExistingLetterFile);

    _populateEscalationFindings();
  };

  var unloadListeners = function () {
    $page.off("click", ".letter-file-download", _downloadExistingLetterFile);
  };

  var getProps = function (data) {
    return {
      readOnly: data?.readOnly ?? false,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var _populateEscalationFindings = function () {
    const history = EnforcementController.getLatestData().enforcementHistory;

    history?.forEach(function (historyItem) {
      const reasons = _prepareReasons(historyItem);
      const renderedReasons = nunjucks.render("modals/enforcement/historyReasons.njk", { reasons });
      $page
        .find(`[data-id='${historyItem.id}']`)
        .parent()
        .find(`.history-open-findings`)
        .html(renderedReasons);
    });
  };

  var _downloadExistingLetterFile = function (e) {
    const letterFileUuid = $(e.currentTarget).data("uuid");
    ApiCalls.downloadEnforcementLetterFile(letterFileUuid);
  };

  return {
    unloadListeners,
    loadListeners,
    getProps,
    getStringKey,
    getTabHeader,
  };
};

module.exports = EnforcementHistory;

const ApiCalls = require("../apiCalls");
