const ManageUsers = function () {
  let inspectionPermissions = [];
  let inspectionPermissionsGrouped = [];
  let $manageUsersModal;

  var loadListeners = function () {
    $manageUsersModal = $("#manageUsersModal");
    $manageUsersModal.off();
    $manageUsersModal.on("click", ".resend-invite", resendInvite);
    $manageUsersModal.on("change", ".user-permissions", updateUserTypePermissions);
    $manageUsersModal.on("click", ".affiliated", toggleAffiliated);
    $manageUsersModal.on("change", ".affiliations", renderNewAffiliationField);
    $manageUsersModal.on("click", ".user-back-div", showManageUsers);
    $manageUsersModal.on("click", ".user-back-div.manage-users-back", showManageUsers);
    $manageUsersModal.on("click", ".user-setting-button", showAddUser);
    $manageUsersModal.on("click", ".create-new-user", createNewUser);
    $manageUsersModal.on("click", ".show-edit-user", editUserClick);
    $manageUsersModal.on("click", ".user-settings-remove-cancel", showManageUsers);
    $manageUsersModal.on("click", ".user-settings-remove-user", deleteUser);
    $manageUsersModal.on("click", ".delete-button", showRemoveUser);
    $manageUsersModal.on("click", ".update-button", updateUser);
  };

  var loadManageUsers = function () {
    loadListeners();
    showManageUsersModal();
  };

  var showManageUsersModal = function () {
    showManageUsers();
    $("#manageUsersModal").modal("show");
  };

  var showManageUsers = function () {
    ApiCalls.getGroupUsers(function (userData) {
      var data = {
        Users: userData,
      };
      $("#manageUsersModal")
        .find(".modal-body")
        .html(nunjucks.render("userSettings/manageUsers.njk", data));
    });
  };

  var showRemoveUser = async function () {
    const userData = $("[data-user-id]").data();
    const userWarning = await ApiCalls.checkDeleteUser(userData.userId);

    const content = {
      message: userWarning,
      ...userData,
    };
    $("#manageUsersModal")
      .find(".modal-body")
      .html(nunjucks.render("userSettings/removeUser.njk", content));
  };

  var showAddUser = async function () {
    const groupSetting = ToolSettings.getSetting("group");
    if (!groupSetting?.canCreateUsers) {
      const maxUserCount = groupSetting?.maxUsers ?? 0;
      MessageModal.showWarningModal(
        `You are currently only allowed ${maxUserCount} users. Please remove a user or contact 2NDNATURE to proceed.`,
      );
    } else {
      await Promise.all([
        ApiCalls.getPossibleContactCompanies(),
        ApiCalls.getPossibleUserRoles(),
        ApiCalls.getAdditionalInspectionPermissions(),
      ]).then((values) => {
        Tree.set("groupAffiliations", values[0]);
        const userRoles = values[1];
        inspectionPermissions = values[2];

        const mode = "new-user";
        const defaultOptions = {
          tools: [],
          inspectionPermissions: [],
        };

        $("#manageUsersModal")
          .find(".modal-body")
          .html(_prepareUserModal(userRoles, mode, defaultOptions));
        loadInviteUsersListeners();
        return;
      });
    }
  };
  var loadInviteUsersListeners = function () {
    var $inviteNewUsers = $("#addNewUserContent");
    $inviteNewUsers.off();
    $inviteNewUsers.on("change", ".user-permissions", updateUserTypePermissions);
    $inviteNewUsers.on("change", ".affiliations", renderNewAffiliationField);
    $inviteNewUsers.on("change", ".tool-access input", showInspectionFields);
  };

  var deleteUser = function (e) {
    const data = $(this).data();
    ApiCalls.deleteUser(data.userId, function () {
      $("#removeUserContent").html(
        "<p> You have successfully removed " + data.username + " from your group. </p>",
      );
    });
  };

  var createNewUserCallback = function (response) {
    var username = $("#usernameNew").val();
    var htmlData = { username: username };
    var theHtml = null;
    if (response === "New User Requires Approval") {
      theHtml = nunjucks.render("modals/approvalNeededMessage.njk", htmlData);
    } else {
      theHtml = nunjucks.render("modals/newUserSuccess.njk", htmlData);
    }
    $("#addNewUserContent").html(theHtml);
    $("#newUserComment").empty();
    $(".create-new-user").hide();
  };

  var createNewUserError = function (xhr) {
    const errors = xhr.responseJSON ? xhr.responseJSON.errors : { response: [xhr.responseText] };
    const $commentBox = $("#newUserComment");
    $commentBox.empty();

    for (const field in errors) {
      const error = errors[field][0];
      $commentBox.append(`<p>${error}</p>`);
    }

    $commentBox.show();
  };

  var createNewUser = function () {
    var email = $("#emailNew").val();
    var username = $("#usernameNew").val();
    var role = $("#user-role").val();
    var tools = getSelectedToolAccess();
    var affiliated = $(".affiliated input").is(":checked");
    var affiliation = $(".select-affiliation").val();
    var newAffiliation = affiliation === "new" ? $(".new-affiliation input").val() : null;
    var selectedPermissions = getSelectedPermissions();

    if (
      affiliated &&
      affiliation === "new" &&
      (newAffiliation === null || newAffiliation?.trim().length === 0)
    ) {
      return MessageModal.showWarningModal("Limited access users must have an affiliation.");
    }

    ApiCalls.createNewUser(
      email,
      username,
      role,
      tools,
      affiliation,
      newAffiliation,
      selectedPermissions,
      createNewUserCallback,
      createNewUserError,
    );
  };

  var getSelectedRole = function () {
    return $("#user-role").val();
  };

  var getSelectedPermissions = function () {
    return $("#manageUsersModal .inspection-permissions .permission")
      .not(".hidden")
      .find("input:checkbox:checked")
      .map(function () {
        return $(this).val();
      })
      .get();
  };

  var editUserClick = function (e) {
    const userToEdit = $(e.currentTarget).data("user-id");
    showEditUser(userToEdit);
  };

  var showEditUser = async function (userToEdit) {
    await Promise.all([
      ApiCalls.getExistingUserData(userToEdit),
      ApiCalls.getPossibleContactCompanies(),
      ApiCalls.getPossibleUserRoles(),
      ApiCalls.getAdditionalInspectionPermissions(),
    ]).then((values) => {
      const existingUser = values[0];
      Tree.set("groupAffiliations", values[1]);
      const userRoles = values[2];
      inspectionPermissions = values[3];
      const mode = "edit";

      $("#manageUsersModal")
        .find(".modal-body")
        .html(_prepareUserModal(userRoles, mode, existingUser));
      renderAffiliationsDropdown(!!existingUser.affiliation, existingUser.affiliation);
      renderNewAffiliationField();
      showInspectionFields();
      return;
    });
  };

  var _prepareUserModal = function (userRoles, mode, existingUser) {
    const groupAffiliations = Tree.get("groupAffiliations");
    const existingTools = ToolFunctions.getToolsInOrder();
    const enabledTools = ToolFunctions.getToolsEnabledForUser();
    const inspections = filterInspectionFields(existingTools.map((tool) => tool.internalName));
    inspectionPermissionsGrouped = inspections;
    return nunjucks.render("userSettings/inviteNewUser.njk", {
      enabledTools,
      existingTools,
      userRoles,
      groupAffiliations,
      mode,
      existingUser,
      inspections,
    });
  };

  var filterInspectionFields = function (selectedTools) {
    const inpsectionsToFilter = selectedTools
      .map(function (tool) {
        return inspectionPermissions[tool];
      })
      .flatMap(function (tools) {
        return tools;
      });

    return inpsectionsToFilter.filter(
      (fieldToFilter, index, self) =>
        index ===
        self.findIndex(
          (fieldToSearch) => fieldToSearch?.value === fieldToFilter?.value && fieldToSearch?.value,
        ),
    );
  };

  var showInspectionFields = function () {
    const filteredFields = filterInspectionFields(getSelectedToolAccess());
    const toggleInspectionField =
      filteredFields.length === 0 || getSelectedRole() !== "field-personnel";
    $("#manageUsersModal")
      .find(".inspection-permissions")
      .toggleClass("hidden", toggleInspectionField);

    inspectionPermissionsGrouped.forEach(function (permission) {
      const toggleView = filteredFields.flatMap((filtered) => filtered.value);
      $("#manageUsersModal")
        .find(`#${permission.value}`)
        .toggleClass("hidden", !toggleView.includes(permission.value) || toggleInspectionField);
    });
  };

  var updateUser = function () {
    var email = $("#email-existing").text().trim();
    var userId = $("[data-user-id]").data("userId");
    var role = getSelectedRole();
    var tools = getSelectedToolAccess();
    var affiliated = $(".affiliated input").is(":checked");
    var affiliation = $(".select-affiliation").val();
    var newAffiliation = affiliation === "new" ? $(".new-affiliation input").val() : null;
    var selectedPermissions = getSelectedPermissions();

    if (
      affiliated &&
      affiliation === "new" &&
      (newAffiliation === null || newAffiliation?.trim().length === 0)
    ) {
      return MessageModal.showWarningModal("Limited access users must have an affiliation.");
    }

    ApiCalls.updateExistingUser(
      userId,
      email,
      role,
      tools,
      affiliation,
      newAffiliation,
      selectedPermissions,
      updateUserCallback,
      createNewUserError,
    );
  };

  var updateUserCallback = function () {
    var email = $("#email-existing").text().trim();
    var htmlData = { email: email };
    var html = nunjucks.render("modals/updateUserSuccess.njk", htmlData);

    $("#addNewUserContent").html(html);
    $("#newUserComment").empty();
    $(".invite-button").hide();
  };

  var getSelectedToolAccess = function () {
    return $("#manageUsersModal")
      .find(".tool-access input:checkbox:checked")
      .map(function () {
        return $(this).val();
      })
      .get();
  };

  var updateUserTypePermissions = function (e) {
    const selectedUserRole = $(e.currentTarget).val();

    showAffiliationsField(selectedUserRole);
    showInspectionFields();
  };

  var toggleAffiliated = function () {
    const affiliationValue = $manageUsersModal.find(".affiliated input").is(":checked");
    renderAffiliationsDropdown(affiliationValue);
    renderNewAffiliationField();
  };

  var showAffiliationsField = function (selectedUserRole) {
    // Admins cannot be affiliated, Responders must be affiliated
    const nonAffiliated = ["admin", "reviewer"].includes(selectedUserRole);
    const mustBeAffiliated = selectedUserRole === "responder";
    const $affiliated = $manageUsersModal.find(".affiliated");
    const $affiliatedInput = $affiliated.find("input");
    if (nonAffiliated || mustBeAffiliated) {
      $affiliatedInput.prop("disabled", true);
      $affiliatedInput.prop("checked", !nonAffiliated);
    } else {
      $affiliatedInput.prop("disabled", false);
    }

    toggleAffiliated();
    renderNewAffiliationField();
  };

  var renderAffiliationsDropdown = function (showAffiliations, selectedAffiliation = null) {
    if (showAffiliations) {
      const groupAffiliations = Tree.get("groupAffiliations");
      const affiliations = nunjucks.render("userSettings/affiliations.njk", {
        affiliations: groupAffiliations,
        selectedAffiliation: selectedAffiliation,
      });
      $("#manageUsersModal").find(".affiliations").html(affiliations);
    } else {
      $("#manageUsersModal").find(".affiliations").empty();
    }
  };

  var renderNewAffiliationField = function () {
    const selectedAffiliation = $(".affiliations").find(".select-affiliation").val();
    if (selectedAffiliation === "new") {
      const newAffiliaction = nunjucks.render("userSettings/newAffiliation.njk");
      $(".new-affiliation").html(newAffiliaction);
    } else {
      $(".new-affiliation").empty();
    }
  };

  var resendInvite = function (e) {
    const user = $(e.currentTarget).val();
    ApiCalls.resendInvite(
      user,
      function (responseMessage) {
        MessageModal.showWarningModal(responseMessage, "Notice");
      },
      function (error) {
        MessageModal.showWarningModal(error.responseText, "Warning");
      },
    );
  };

  return {
    showManageUsersModal,
    showManageUsers,
    showRemoveUser,
    showAddUser,
    showEditUser,
    deleteUser,
    _prepareUserModal,
    loadListeners,
    loadManageUsers,
  };
};

module.exports = ManageUsers();

const ApiCalls = require("../apiCalls");
const MessageModal = require("./messageModal");
const ToolFunctions = require("../toolFunctions");
const Tree = require("../tree");
const ToolSettings = require("../settings/toolSettings");
