"use strict";

const Dropdown = function () {
  var select = function ($selectedLi) {
    if ($selectedLi.parent(".dropdown-menu").length !== 1) {
      throw new Error("$selectedLi is not a dropdown menu list item.");
    }

    $selectedLi.siblings(".dropdown-selection").removeClass("dropdown-selection");
    $selectedLi.addClass("dropdown-selection");
  };

  return { select };
};

module.exports = Dropdown();
