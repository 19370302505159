"use strict";

const IncidentsIcon = function () {
  var getIncidentsNewMarkerIcon = function () {
    return getIncidentsIcon(getIncidentsIconClass("new"));
  };

  var getIncidentsIconClass = function (status) {
    return `idde-incident-${status}`;
  };

  var getIconColor = function (incidentStatus) {
    if (incidentStatus === "closed") {
      return "black";
    } else if (incidentStatus === "open") {
      return "red";
    }
    return;
  };

  var getIncidentsMarkerIcon = function (properties, options = null) {
    const iconClass = getIncidentsIconClass(properties.incidentStatus);

    return options
      ? getIncidentsIcon(iconClass, options.size, options.showText)
      : getIncidentsIcon(iconClass);
  };

  var getIncidentsIcon = function (iconClass, size = [24, 24], showText = true) {
    return L.divIcon({
      iconSize: size,
      popupAnchor: getPopupAnchor(size),
      className: iconClass,
    });
  };

  var getPopupAnchor = function (size) {
    const anchor = [0, -4];
    if (size[0] < 7) {
      anchor[1] = 4;
    } else if (size[0] < 21) {
      anchor[1] = 0;
    }
    return anchor;
  };

  return {
    getIncidentsNewMarkerIcon,
    getIncidentsMarkerIcon,
    getIconColor,
    getIncidentsIconClass,
  };
};

module.exports = IncidentsIcon();
