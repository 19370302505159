"use strict";

function Offline() {
  async function init() {
    Sentry.setTag("online", onLine());

    if (!onLine()) {
      await warnIfOfflineNotReady();

      Analytics.sendAction(["went_offline", "page_load"]);
    }

    updateOnlineClass();
    logStorageQuota();

    $(window).on("online", goOnline);
    $(window).on("offline", goOffline);
  }

  function goOnline() {
    const ResourceController = require("./resourceController");

    Sentry.setTag("online", onLine());
    updateOnlineClass();
    ResourceController.sync();
    Analytics.sendAction(["went_online", "from_offline"]);
  }

  function goOffline() {
    const Filters = require("../mapFunctions/filters");
    const NavToggle = require("../mapFunctions/navToggle");

    Sentry.setTag("online", onLine());
    updateOnlineClass();
    warnIfOfflineNotReady();

    if (DataViewFunctions.getCurrentDataViewProperty("offline")) {
      NavToggle.showPanel("list");
      Filters.setFlatDataSort();
    }

    Analytics.sendAction(["went_offline", "from_online"]);
  }

  function onLine() {
    return navigator.onLine;
  }

  async function warnIfOfflineNotReady() {
    if (!(await InstallManager.serviceWorkerReady())) {
      await MessageModal.showSimpleWarningModal(
        "The application was not able to fully install before going offline. Please go online and wait a few moments before trying again.",
      );
    }
  }

  function removeOfflineOnlyFields(data) {
    Misc.eachNestedPropertyWithKey(data, "offlineOnly", function (offlineOnly, parent) {
      delete parent.offlineOnly;
    });

    return data;
  }

  function updateOnlineClass() {
    $("body").toggleClass("offline", !onLine());
  }

  // Based on https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
  async function logStorageQuota() {
    if (navigator?.storage?.estimate) {
      const estimate = await navigator.storage.estimate();
      const percent = ((estimate.usage / estimate.quota) * 100).toFixed(2);
      const usedGb = (estimate.usage / 1000000000).toFixed(2);
      const quotaGb = (estimate.quota / 1000000000).toFixed(2);
      console.debug(`Storage quota: ${usedGb}GB used of ${quotaGb}GB quota (${percent}%)`);
    }
  }

  return { init, onLine, removeOfflineOnlyFields, logStorageQuota };
}

module.exports = Offline();

const Analytics = require("../general/analytics");
const DataViewFunctions = require("../dataViewFunctions");
const InstallManager = require("./installManager");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
const Sentry = require("@sentry/browser");
