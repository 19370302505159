"use strict";

const ConstructionProjectStyles = function () {
  var getColor = function (constructionProjectData) {
    if (Tree.get(["filters", "conditionPhaseToggle"]) === "phase") {
      return getColorByPhase(constructionProjectData);
    } else {
      return getColorByCondition(constructionProjectData);
    }
  };

  var getColorByPhase = function (constructionProjectData) {
    return constructionProjectData.phase;
  };

  var getColorByCondition = function (constructionProjectData) {
    if (!constructionProjectData.noInspection && constructionProjectData.isScorePassing !== null) {
      return constructionProjectData.isScorePassing ? "green" : "red";
    }
    return "no-inspection";
  };

  var getColorHex = function (constructionProjectData) {
    // Can't import at the bottom until we update to Node 14.
    // NunjucksFilters imports this file and runs it in Node,
    // and MapStyles makes Nunjucks import a bunch of modules
    // including some that use nullish coalescing, a feature
    // that doesn't exist in our current Node 12.
    const MapStyles = require("../mapFunctions/mapStyles");
    return MapStyles.getHexColor(getColor(constructionProjectData));
  };

  var getHeaderColorClass = function (constructionProjectData) {
    return `background-${getColor(constructionProjectData)}`;
  };

  var getDisplayPhase = function (phase) {
    const displayPhases = {
      planning: "Planning",
      planned: "Design",
      construction: "Construction",
      completed: "Completed",
      certified: "Certified",
      shelved: "Shelved",
      rejected: "Rejected",
      "post-active": "Post Active",
    };

    return displayPhases[phase];
  };

  return {
    getHeaderColorClass,
    getColorHex,
    getDisplayPhase,
    getColorByPhase,
  };
};

module.exports = ConstructionProjectStyles();

const Tree = require("../tree");
