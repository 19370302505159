"use strict";

const WidgetHandler = function () {
  let $hoverContainer;

  const render = function ($container, widget, data) {
    if (!widget || (widget.widgetConfig.isHidden && widget.widgetConfig.isHidden(data))) {
      return;
    }

    $hoverContainer = $(".hover-container");
    const widgetHtml = widget.getHtml(data);
    const $widgetElement = $(`<div data-widget-id="${widget.widgetId}">${widgetHtml}</div>`)
      .addClass("insight-widget-container")
      .addClass(`span-${widget.getSize()}`)
      .addClass(`span-md-${widget.getSizeMd()}`)
      .addClass(`span-sm-${widget.getSizeSm()}`);

    if (widget.widgetConfig.isGreyedOut && widget.widgetConfig.isGreyedOut()) {
      $widgetElement.addClass("greyed-out");
    }

    $container.append($widgetElement);

    if (widget.renderChart) {
      widget.renderChart(data);
    }

    if (widget.loadListeners) {
      widget.loadListeners();
    }

    if (widget.widgetConfig.hovers?.length) {
      $widgetElement.off("mouseenter");
      $widgetElement.off("mouseleave");

      $widgetElement.on("mouseleave", `.hover-target`, function (e) {
        $container.find(".hovered").removeClass("hovered");
        $hoverContainer.hide();
      });

      widget.widgetConfig.hovers.forEach((hover) => {
        $widgetElement.find(`.${hover.className}`).addClass("hover-target");

        $widgetElement.on("mouseenter", `.${hover.className}`, function (e) {
          const $hoverTarget = $(this);
          const hoverHtml = hover.getHoverHtml.call(widget, data, $hoverTarget);

          if (hoverHtml) {
            $hoverContainer.html(hoverHtml);

            var pos = getHoverContainerPosition($hoverTarget, hover.relativeContainerClassName);
            $hoverContainer.css({
              top: pos.top,
              left: pos.left,
            });

            $hoverContainer.show();
          }
        });
      });
    }
    _loadListeners($container, widget, data);
  };

  var getHoverContainerPosition = function ($hoverTarget, relativeContainerClassName = null) {
    if (relativeContainerClassName) {
      $hoverTarget = $hoverTarget.closest(`.${relativeContainerClassName}`);
    }

    const hoverTargetOffset = $hoverTarget.offset();
    const windowWidth = $(window).width();
    const hoverContainerWidth = $hoverContainer.outerWidth();

    var hoverTargetWidth = 0;
    if ($hoverTarget.outerWidth()) {
      hoverTargetWidth = $hoverTarget.outerWidth();
    } else if ($hoverTarget[0]?.getBoundingClientRect) {
      hoverTargetWidth = $hoverTarget[0].getBoundingClientRect().width;
    }
    var hoverContainerLeft = hoverTargetOffset.left - (hoverContainerWidth - hoverTargetWidth) / 2;

    if (hoverContainerLeft < 0) {
      hoverContainerLeft = 0;
    }

    var hoverContainerRight = hoverContainerLeft + hoverContainerWidth;

    if (hoverContainerRight > windowWidth) {
      hoverContainerLeft = windowWidth - hoverContainerWidth;
    }

    var hoverContainerTop = hoverTargetOffset.top - $hoverContainer.outerHeight() + 1;

    if (hoverContainerTop < 84) {
      let hoverTargetHeight = 0;
      if ($hoverTarget.outerHeight()) {
        hoverTargetHeight = $hoverTarget.outerHeight();
      } else if ($hoverTarget[0]?.getBoundingClientRect) {
        hoverTargetHeight = $hoverTarget[0].getBoundingClientRect().height;
      }

      hoverContainerTop = hoverTargetOffset.top + hoverTargetHeight - 1;
    }

    return {
      left: hoverContainerLeft,
      top: hoverContainerTop,
    };
  };

  var _loadListeners = function ($container, widget, data) {
    $(window)
      .off(`resize.${widget.widgetId}`)
      .on(`resize.${widget.widgetId}`, () => onWindowResize($container, widget, data));
  };

  var onWindowResize = function ($container, widget, data) {
    $container.css("min-height", "initial");
    if (widget.renderChart) {
      widget.renderChart(data);
      widget.renderChart(data, true);
    }
    if (widget.widgetConfig.hovers?.length) {
      widget.widgetConfig.hovers.forEach((hover) => {
        widget.getWidgetElement().find(`.${hover.className}`).addClass("hover-target");
      });
    }
  };

  return {
    render,
  };
};

module.exports = WidgetHandler();
