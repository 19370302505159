"use strict";

const RecordSweepConstants = function () {
  const curbAccessibilitySelectOptions = [
    {
      name: "< 25%",
      value: "less_than_25",
    },

    {
      name: "25-50%",
      value: "between_25_50",
    },

    {
      name: "50-75%",
      value: "between_50_75",
    },
    {
      name: "> 75%",
      value: "greater_than_75",
    },
  ];

  const debrisCollectedUnitOptions = [
    {
      name: "cu-yds",
      value: "cubic-yards",
    },
    {
      name: "cu-ft",
      value: "cubic-feet",
    },
    {
      name: "gallons",
      value: "gallons",
    },
    {
      name: "tons",
      value: "tons",
    },
  ];

  return {
    curbAccessibilitySelectOptions,
    debrisCollectedUnitOptions,
  };
};

module.exports = RecordSweepConstants();
