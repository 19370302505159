"use strict";

const DryWeatherGeoServerLayerPopup = function () {
  var getPopupHtml = function (properties) {
    properties.isDue = properties.isDue || false;
    properties.dryWeatherCondition = properties.dryWeatherCondition || null;
    return DryWeatherFunctions.getDryWeatherLayerPopupHtml(properties);
  };

  var onPopupOpenWithData = function (data, $element) {
    var onDownloadReport = async function () {
      const id = $(this).closest("tr").data("id");
      await ApiCalls.downloadOutfallInspectionPdf(id);
    };

    $element.off("click", ".open-dry-weather-screening");
    $element.on("click", ".open-dry-weather-screening", () =>
      DryWeatherFunctions.openDryWeatherScreening(data.id, data.assetType),
    );
    $element.off("click", ".open-dry-weather-screening-follow-up");
    $element.on("click", ".open-dry-weather-screening-follow-up", () =>
      DryWeatherFunctions.openDryWeatherScreening(
        data.id,
        data.assetType,
        null,
        false,
        "follow-up",
      ),
    );
    $element.off("click", ".download-report", onDownloadReport);
    $element.on("click", ".download-report", onDownloadReport);
  };

  var onPopupOpen = async function (properties, event = null) {
    const id = properties.id;
    const assetType = properties.asset_type || properties.assetType;
    var data = await getPopupData(id, assetType);
    const html = getPopupHtml(data);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);

    onPopupOpenWithData(data, $popup);
    DryWeatherHistory.renderDryWeatherHistory(data.history, data.assetType, data.id);
  };

  var getPopupData = async function (id, assetType) {
    const popupData = await ApiCalls.getIddeDryWeatherPopup(id, assetType);

    return popupData;
  };

  return {
    getPopupHtml,
    onPopupOpen,
    onPopupOpenWithData,
  };
};

module.exports = DryWeatherGeoServerLayerPopup();

const ApiCalls = require("../apiCalls");
const DryWeatherFunctions = require("./dryWeatherFunctions");
const DryWeatherHistory = require("./dryWeatherHistory");
