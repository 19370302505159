"use strict";

const UrlImport = function () {
  var $mount;
  var layer;
  var _url;

  var validateHandler = function () {
    throw "validateHandler is not set";
  };

  var loadListeners = function () {
    $mount.off("input", `[name="url-input"]`, handleValidateButtonToggle);
    $mount.on("input", `[name="url-input"]`, handleValidateButtonToggle);

    $mount.off("keypress", handleFormSubmit);
    $mount.on("keypress", handleFormSubmit);
  };

  var handleValidateButtonToggle = function (e) {
    const disableState = $(e.currentTarget).val().length === 0;
    Misc.toggleDisabled($("#import-modal .import-next-button"), disableState);
  };

  var handleFormSubmit = function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      validateUrl();
      return false;
    }
  };

  var validateUrl = function () {
    const urlRegex = DataImportConfig.validationRegex[layer];
    const urlTester = new RegExp(urlRegex);
    const url = $mount.find(`[name="url-input"]`).val();

    if (urlTester.test(url)) {
      validateHandler(url);
    } else {
      showWarningModal("wrongUrl");
    }
  };

  var parseJson = function (data) {
    try {
      data = JSON.parse(data);
    } catch (e) {
      return data;
    }
    return data;
  };

  var _setUrl = function (newUrl) {
    _url = newUrl;
  };

  var queryArcGisUrl = async function (url) {
    const queryUrl = new URL(url);
    queryUrl.pathname += "/query";
    try {
      const response = await ApiCalls.getArcGisUrlData(queryUrl);
      const responseJson = parseJson(response);
      if (responseJson.error) {
        showWarningModal("missingToken");
      } else {
        _setUrl(queryUrl);
        if (responseJson.geometry) {
          responseJson.properties.geometry = responseJson.geometry;
        }
        showUrlImportModal(responseJson);
      }
    } catch (e) {
      showWarningModal("badUrl");
    }
  };

  var showUrlImportModal = function (responseJson) {
    DataImport.setCurrentPage("url-import");
    toggleUrlModal();
    const data = parseResponseData(responseJson);
    const fields = getFieldsFromData(data);
    DataImportPreview.loadUrlImportPreview(data, fields, layer);
  };

  var toggleUrlModal = function () {
    const $importModal = $("#import-modal");
    $importModal.find(".import-next-button").hide();
    $importModal.find(".url-validator").hide();
    $importModal.find(".import-button").show();
    $importModal.find(".url-upload").show();
    DataImport.toggleWideImportModal(true);
  };

  var getFieldsFromData = function (data) {
    return Object.keys(data[0] || {});
  };

  var parseResponseData = function (responseJson) {
    return responseJson.features.map(function (feature) {
      return { ...feature.properties, geometry: JSON.stringify(feature.geometry) };
    });
  };

  var init = function (layerToImport, mapLayer, newHandler) {
    layer = layerToImport;
    validateHandler = newHandler;
  };

  var show = function ($renderMount, toggleImportEnabledFn) {
    $mount = $renderMount;
    $mount.html(
      nunjucks.render("dataImport/urlImport.njk", { inputText: DataImportConfig.modalBody[layer] }),
    );
    loadListeners();

    DataImportPreview.init(layer, $renderMount.find("#import-preview"), toggleImportEnabledFn);
    DataImportPreview.loadPreviewListeners();
  };

  var showWarningModal = function (warningType) {
    const warning = DataImportConfig.errorMessages[layer][warningType];
    MessageModal.showWarningModal(warning.message, warning.title);
    $mount.find(`[name="url-input"]`).val("");
  };

  var importValidatedUrl = async function () {
    try {
      const fieldMapping = DataImportPreview.getFieldMapping();
      const urlString = _url.toString();
      const importCount = await ApiCalls.importValidatedUrl(urlString, fieldMapping, layer);
      $mount.html(
        nunjucks.render("dataImport/done.njk", {
          created: importCount,
        }),
      );
      // @TODO fix up refresh layer for url imports
      //LayerDataFunctions.refreshLayer(mapLayerToRefresh, "main");
    } catch (e) {
      $mount.html(nunjucks.render("dataImport/fail.njk"));
      console.error(e);
    } finally {
      DataImport.setCurrentPage("import-finished");

      $("#import-modal .import-button").hide();
    }
  };

  return {
    init,
    showUrlImportModal,
    show,
    _setUrl,
    validateUrl,
    queryArcGisUrl,
    showWarningModal,
    importValidatedUrl,
  };
};

module.exports = UrlImport();

const ApiCalls = require("../apiCalls");
const DataImport = require("./dataImport");
const DataImportConfig = require("../config/dataImportConfig");
const DataImportPreview = require("./dataImportPreview");
const Misc = require("../misc");
const MessageModal = require("../modals/messageModal");
