"use strict";

function BrowserHistory() {
  let navigated = false;

  function init() {
    window.addEventListener("popstate", _onNavigate);
  }

  function nextTickPromise() {
    return new Promise((resolve) => setTimeout(resolve, 0));
  }

  async function _onNavigate() {
    navigated = true;
    await nextTickPromise();
    navigated = false;
  }

  async function isNavigating() {
    if (navigated) {
      return true;
    }

    await nextTickPromise();
    return navigated;
  }

  return { init, isNavigating, _onNavigate };
}

module.exports = BrowserHistory();
