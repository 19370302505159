"use strict";

var UserSettingsFunctions = function () {
  const userTypes = {
    "municipal-employee": {
      displayName: "Municipal Employee",
      roles: [
        { roleName: "Module Admin", value: "admin" },
        { roleName: "Module Reviewer (PE)", value: "reviewer" },
        { roleName: "Module Data Manager", value: "data-manager" },
        { roleName: "Field Personnel", value: "field-personnel" },
      ],
    },
    subcontractor: {
      displayName: "Subcontractor",
      roles: [{ roleName: "Responder", value: "responder" }],
    },
  };

  var loadInviteUsersListeners = function () {
    const inviteUsersModal = $("#userSettingsModal");
    inviteUsersModal.off();
    inviteUsersModal.on("change", ".invite-new-user .user-type", updateUserTypePermissions);
    inviteUsersModal.on("change", ".invite-new-user .affiliations", renderNewAffiliationField);
    inviteUsersModal.on("click", ".create-new-user", createNewUser);
    inviteUsersModal.on("click", ".delete-button", showRemoveUserClick);
    inviteUsersModal.on("click", ".update-button", updateUser);
  };

  var loadManageUsersListeners = function () {
    const inviteUsersModal = $("#userSettingsModal");
    inviteUsersModal.off();
    inviteUsersModal.on("click", ".resend-invite", resendInvite);
  };

  var loadUserSettingsListeners = function () {
    const $userSettingsModal = $("#userSettingsModal");
    $userSettingsModal.off("click", "#notification-settings-li", showManageNotifications);
    $userSettingsModal.off("click", "#update-notifications", updateNotificationSettings);
    $userSettingsModal.on("click", "#notification-settings-li", showManageNotifications);
    $userSettingsModal.on("click", "#update-notifications", updateNotificationSettings);
  };

  var resendInvite = function (e) {
    const user = $(e.currentTarget).val();
    ReportApiCalls.resendInvite(
      user,
      function (responseMessage) {
        ModalFunctions.showWarningModal(responseMessage, "Notice");
      },
      function (error) {
        ModalFunctions.showWarningModal(error.responseText, "Warning");
      },
    );
  };

  var showUserSettingsModal = function () {
    render();
    $("#userSettingsModal").modal("show");
    loadUserSettingsListeners();
  };

  var render = function () {
    $("#userSettingsModal")
      .find(".modal-body")
      .html(
        nunjucks.render("report/userSettings/userSettings.html", {
          canManageUsers: UserPermissions.getPermission("can_manage_users"),
        }),
      );
  };

  var showRemoveUserClick = function () {
    var email = $("#email-existing").text().trim();
    showRemoveUser(email);
  };

  var showRemoveUser = function (username) {
    const userId = $("[data-user-id]").data("userId");
    const data = {
      username: username,
      userId: userId,
    };

    $("#userSettingsModal")
      .find(".modal-body")
      .html(nunjucks.render("report/userSettings/removeUser.html", data));
  };

  var updateUser = function () {
    var email = $("#email-existing").text().trim();
    var userId = $("[data-user-id]").data("userId");
    var role = $("#user-role").val();
    var tools = getSelectedToolAccess();
    var affiliation = $(".select-affiliation").val();
    var newAffiliation = affiliation === "new" ? $(".new-affiliation input").val() : null;

    ReportApiCalls.updateExistingUser(
      userId,
      email,
      role,
      tools,
      affiliation,
      newAffiliation,
      updateUserCallback,
      createNewUserError,
    );
  };

  var updateUserCallback = function () {
    var email = $("#email-existing").text().trim();
    var htmlData = { email: email };
    var html = nunjucks.render("report/modals/updateUserSuccess.html", htmlData);

    $("#addNewUserContent").html(html);
    $("#newUserComment").empty();
    $(".invite-button").hide();
  };

  var backToManageUsers = function () {
    showManageUsers();
  };

  var showManageUsers = function () {
    ReportApiCalls.getGroupUsers(function (userData) {
      var data = {
        Users: userData,
      };
      $("#userSettingsModal")
        .find(".modal-body")
        .html(nunjucks.render("userSettings/manageUsers.njk", data));
      loadManageUsersListeners();
    });
  };

  var userSettingSelected = function (selection) {
    switch (selection) {
      case "changePasswordLi":
        $("#userSettingsModal")
          .find(".modal-body")
          .html(nunjucks.render("report/userSettings/changePassword.html"));
        break;
      case "changeUsernameLi":
        _showChangeUsername();
        break;
      case "ManageUsersLi":
        showManageUsers();
        break;
      case "notification-settings-li":
        showManageNotifications();
    }
  };

  var _showChangeUsername = function () {
    var data = {
      email: Session.loadContext().email,
      username: Session.loadContext().username,
      fullname: Session.loadContext().full_name,
    };

    $("#userSettingsModal")
      .find(".modal-body")
      .html(nunjucks.render("report/userSettings/updateUsername.html", data));
  };

  var updateUsername = function () {
    var email = $("#emailSettings").val();
    var username = $("#usernameSettings").val();
    var fullname = $("#fullnameSettings").val();

    ReportApiCalls.updateUsername(
      email,
      username,
      fullname,
      function (data) {
        SingleSignOn.isLoggedIn({ token: data.token }, function () {
          SingleSignOn.warnAndRefresh(
            "Your user information has been updated. The page will now refresh.",
          );
        });
      },
      function (xhr) {
        $("#updateUserSettingsContent").html("<p>" + xhr.responseText + "</p>");
      },
    );
  };

  var showEditUser = async function (userToEdit) {
    await Promise.all([
      ReportApiCalls.getExistingUserData(userToEdit),
      ReportApiCalls.getPossibleContactCompanies(),
    ]).then((values) => {
      const existingUser = values[0];
      Tree.set("groupAffiliations", values[1]);
      const selectedUserType = getSelectedUserType(existingUser.role);
      const mode = "edit";

      $("#userSettingsModal")
        .find(".modal-body")
        .html(prepareUserModal(selectedUserType, mode, existingUser));
      loadInviteUsersListeners();
      renderAffiliations(existingUser.affiliation);
      renderNewAffiliationField();
    });
  };

  var showAddUser = async function () {
    const affiliations = await ReportApiCalls.getPossibleContactCompanies();
    Tree.set("groupAffiliations", affiliations);

    const selectedUserType = "municipal-employee";

    const mode = "new-user";
    const defaultOptions = {
      tools: [],
    };

    $("#userSettingsModal")
      .find(".modal-body")
      .html(prepareUserModal(selectedUserType, mode, defaultOptions));
    loadInviteUsersListeners();
  };

  var getSelectedUserType = function (role) {
    const userType = Object.keys(userTypes).find(function (parentRole) {
      return userTypes[parentRole].roles.find((existingRoles) => existingRoles.value === role);
    });
    return userType ? userType : "municipal-employee";
  };

  var prepareUserModal = function (selectedUserType, mode, existingUser) {
    const existingTools = Config.currentTools;
    const enabledTools = Tree.get("user", "enabledTools");
    const groupAffiliations = Tree.get("groupAffiliations");

    return nunjucks.render("report/userSettings/inviteNewUser.html", {
      enabledTools,
      existingTools,
      userTypes,
      selectedUserType,
      groupAffiliations,
      mode,
      existingUser,
    });
  };

  var showChangePassword = function () {
    $("#userSettingsModalBody").html(nunjucks.render("report/modals/changePassword.html"));
  };

  var deleteUser = function () {
    const data = $(this).data();
    ReportApiCalls.deleteUser(data.userId, function () {
      $("#removeUserContent").html(
        "<p> You have successfully Removed " + data.username + " From your group. </p>",
      );
    });
  };

  var createNewUserCallback = function (response) {
    var username = $("#usernameNew").val();
    var htmlData = { username: username };
    var theHtml = null;
    if (response === "New User Requires Approval") {
      theHtml = nunjucks.render("report/modals/approvalNeededMessage.html", htmlData);
    } else {
      theHtml = nunjucks.render("report/modals/newUserSuccess.html", htmlData);
    }
    $("#addNewUserContent").html(theHtml);
    $("#newUserComment").empty();
    $(".invite-button").hide();
  };

  var createNewUserError = function (xhr) {
    const errors = xhr.responseJSON ? xhr.responseJSON.errors : { response: [xhr.responseText] };
    const $commentBox = $("#newUserComment");
    $commentBox.html("");

    for (const field in errors) {
      const error = errors[field][0];
      $commentBox.append(`<p>${error}</p>`);
    }

    $commentBox.show();
  };

  var createNewUser = function () {
    var email = $("#emailNew").val();
    var username = $("#usernameNew").val();
    var role = $("#user-role").val();
    var tools = getSelectedToolAccess();
    var affiliation = $(".select-affiliation").val();
    var newAffiliation = affiliation === "new" ? $(".new-affiliation input").val() : null;

    ReportApiCalls.createNewUser(
      email,
      username,
      role,
      tools,
      affiliation,
      newAffiliation,
      createNewUserCallback,
      createNewUserError,
    );
  };

  var updateUserTypePermissions = function (e) {
    const selectedUserType = $(e.currentTarget).val();
    const userPermissions = nunjucks.render("report/userSettings/userPermissions.html", {
      userTypes,
      selectedUserType,
    });
    $("#userSettingsModal").find(".user-permissions").html(userPermissions);

    renderAffiliations();
    renderNewAffiliationField();
  };

  var renderAffiliations = function (selectedAffiliation = null) {
    const selectedUserType = $(".user-type").val();
    if (selectedUserType === "subcontractor") {
      const groupAffiliations = Tree.get("groupAffiliations");
      const affiliations = nunjucks.render("report/userSettings/affiliations.html", {
        affiliations: groupAffiliations,
        selectedAffiliation: selectedAffiliation,
      });
      $("#userSettingsModal").find(".affiliations").html(affiliations);
    } else {
      $("#userSettingsModal").find(".affiliations").empty();
    }
  };

  var renderNewAffiliationField = function () {
    const selectedAffiliation = $(".affiliations").find(".select-affiliation").val();
    if (selectedAffiliation === "new") {
      const newAffiliaction = nunjucks.render("report/userSettings/newAffiliation.html");
      $(".new-affiliation").html(newAffiliaction);
    } else {
      $(".new-affiliation").empty();
    }
  };

  var getSelectedToolAccess = function () {
    return $("#userSettingsModal")
      .find(".tool-access input:checkbox:checked")
      .map(function () {
        return $(this).val();
      })
      .get();
  };

  var showManageNotifications = async function () {
    const notifications = {
      notifications: await RamUserSettingsFunctions.getNotificationSettings(),
    };
    $("#userSettingsModal")
      .find(".modal-body")
      .html(nunjucks.render("report/userSettings/manageNotifications.html", notifications));
  };

  var updateNotificationSettings = async function () {
    const updatedNotificationSettings = $(".notifications input")
      .map(function () {
        const $item = $(this);
        const key = $item.val();
        const checked = $item.prop("checked");
        return {
          [key]: checked,
        };
      })
      .get();
    await RamUserSettingsFunctions.saveNotificationSettings(updatedNotificationSettings);
    showUserSettingsModal();
  };

  return {
    showUserSettingsModal,
    showRemoveUser,
    showManageUsers,
    backToManageUsers,
    userSettingSelected,
    showAddUser,
    showEditUser,
    showChangePassword,
    deleteUser,
    createNewUser,
    updateUsername,
    showManageNotifications,
    updateNotificationSettings,
    _showChangeUsername,
  };
};

module.exports = UserSettingsFunctions();

const ReportApiCalls = require("../reportApiCalls");
const Config = require("../config");
const ModalFunctions = require("../modals/modalFunctions");
const Session = require("../../login/session");
const UserPermissions = require("../../login/userPermissions");
const SingleSignOn = require("../../login/sso");
const Tree = require("../../tree");
const RamUserSettingsFunctions = require("../../modals/userSettingsFunctions");
