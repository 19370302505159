"use strict";

function ReviewPage() {
  let $container;
  let reviewLazyList;
  let sortedReviewDataArray;
  let reviewTableSort;

  async function render($newContainer) {
    const inited = !!$container;
    $container = $newContainer;
    _updateData();

    if (inited) {
      _rerenderReview();
    } else {
      _loadReviewTable();
      _loadListeners();
    }

    ToDoPrioritizeController.recalculateStyles();
  }

  function _loadReviewTable() {
    const $scrollContainer = $container.find(".list-container");

    reviewTableSort = new TableSort($scrollContainer, {
      dueDate: DateTime.dbDateStringComparator,
      bmpDisplayType: SortUtilities.alphaSorter,
      bmpName: SortUtilities.alphaSorter,
      address: SortUtilities.alphaSorter,
      catchBasinPriority: SharedDataFunctions.prioritySorter,
    });
    reviewTableSort.setSorting("bmpName");
    reviewTableSort.sort(sortedReviewDataArray);

    reviewLazyList = new LazyList({
      rowContainer: $scrollContainer.find("tbody")[0],
      scrollContainer: $scrollContainer[0],
      getRowCallback: _getReviewRowHtmlByIndex,
      totalRows: sortedReviewDataArray.length,
    });
  }

  function _loadListeners() {
    $container.on("click", ".js-remove", _onRemoveReview);
    $container.on("click", ".sortable", _onReviewTrSort);
  }

  function _getReviewRowHtmlByIndex(rowIndex) {
    return nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/reviewTableRow.njk", {
      datum: sortedReviewDataArray[rowIndex],
    });
  }

  function getStep() {
    return {
      title: "Review Tasks",
      descriptionHtml:
        "<p>Review your full list of <strong>Routine Inspection Tasks</strong>. Return to the “Create Tasks” tab to add additional tasks. Click Save & Close to return to the To Do Tab.</p>",
      contentHtml: nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/review.njk", {}),
      render,
    };
  }

  function destroy() {
    $container = null;
    sortedReviewDataArray = null;
    reviewLazyList?.destroy();
    reviewLazyList = null;
    reviewTableSort = null;
  }

  function _updateData() {
    sortedReviewDataArray = [...ToDoPrioritizeController.getToDoDataById().values()];
  }

  function _rerenderReview() {
    reviewTableSort.updateHeaders();
    reviewTableSort.sort(sortedReviewDataArray);
    reviewLazyList.updateData(_getReviewRowHtmlByIndex, sortedReviewDataArray.length);
  }

  async function _onRemoveReview() {
    const selectedToDoIds = [$(this).closest("tr").data("id")];

    const removed = await ToDoPrioritizeController.promptRemoveSelection(selectedToDoIds);

    if (removed) {
      _updateData();
      _rerenderReview();
    }
  }

  function _onReviewTrSort(e) {
    const $tr = $(e.currentTarget);
    const sortKey = $tr.data("sortKey");

    reviewTableSort.setSorting(sortKey);
    _rerenderReview();
  }

  return {
    getStep,
    render,
    destroy,
  };
}

module.exports = ReviewPage();

const LazyList = require("../../general/lazyList");
const TableSort = require("../../general/tableSort");
const SortUtilities = require("../../general/sortUtilities");
const DateTime = require("../../dateTime");
const ToDoPrioritizeController = require("./toDoPrioritizeController");
const SharedDataFunctions = require("../../general/sharedDataFunctions");
