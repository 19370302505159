"use strict";

var DisplayOptions = function () {
  var thisLegend = null;

  var startLegendListeners = function (mapId, mapLayers) {
    thisLegend = PageFunctions.getCurrentPage().find(".display-options");
    thisLegend.off();

    thisLegend.on("change", "input[type=checkbox]", function (e) {
      legendCheckboxChange(this, mapLayers, mapId);
    });

    thisLegend.on("click", "input[type=radio]", function (e) {
      legendRadioChange(this, mapLayers, mapId);
    });

    thisLegend.on("click", ".legend-button-close", function () {
      Actions.toggleLegendDisplay(false, mapId);
    });

    thisLegend.on("click", ".legend-button-legend", function () {
      Actions.toggleLegendDisplay(true, mapId);
    });

    thisLegend.on("click", ".legend-header-click", function () {
      toggleLegendArrow($(this), mapId);
    });

    thisLegend.on("click", ".basemap-dropdown a", function () {
      if ($(this).hasClass("disabled")) return;
      var style = $(this).data("style");
      var mapId = Tree.get("mapId");
      BasemapFunctions.setBasemap(style, mapId);
    });
  };

  var legendCheckboxChange = function (that, mapLayers, mapId) {
    const cleanValue = that.value.includes("-") ? that.value.split("-")[1] : that.value;
    if (that.name === "cluster") {
      Clustering.setState(cleanValue, that.checked);
    } else {
      handleFCSDisplay(cleanValue, mapId);
      Actions.toggleLayerEnabled(cleanValue, that.checked, mapLayers, mapId);
      toggleLegend(cleanValue, that.checked);
    }
  };

  var legendRadioChange = function (that, mapLayers) {
    const mapId = Tree.get("mapId");
    const alreadyEnabled = Tree.get("layers", mapId, that.value, "isEnabled");
    if (alreadyEnabled) {
      turnLayerOff(that.value, mapLayers);
    } else {
      turnLayerOn(that.value, mapLayers);
    }
  };

  var turnLayerOff = function (layerName, mapLayers) {
    const mapId = Tree.get("mapId");
    Actions.toggleLayerEnabled(layerName, false, mapLayers, mapId);
    toggleLegend(layerName, false);
    uncheckRadioInOtherPanel(layerName);

    const layerConfig = LayerFunctions.getLayerConfigByTreeName(layerName);
    if (layerConfig && layerConfig.clustering) {
      Clustering.setEnabledCheckbox(layerName, false);
    }

    if (layerName === "fcs") {
      turnLayerOff("fullDrainage", mapLayers);
    }
  };

  var turnLayerOn = function (layerName, mapLayers) {
    const mapId = Tree.get("mapId");

    const radioLayersInGroup = getRadioLayersInGroup(layerName);
    if (radioLayersInGroup.includes(layerName)) {
      removeOtherRadioButtonLayers(layerName, radioLayersInGroup, mapLayers, mapId);
    }

    Actions.toggleLayerEnabled(layerName, true, mapLayers, mapId);
    toggleLegend(layerName, true);
    checkRadioInOtherPanel(layerName);

    if (layerName === "fullDrainage") turnLayerOn("fcs", mapLayers);

    if (["fcs", "bmps", "sbmpProgress", "constructionProjectProgress"].includes(layerName))
      Clustering.setEnabledCheckbox(layerName, true);
  };

  var checkRadioInOtherPanel = function (layerName) {
    thisLegend.find("input[type=radio][value=" + layerName + "]").prop("checked", true);
  };

  var uncheckRadioInOtherPanel = function (layerName) {
    thisLegend.find("input[type=radio][value=" + layerName + "]").prop("checked", false);
  };

  //@TODO clean this up so that only special cases are handled in the switch statement
  //Everything else should be handled by similar naming convention
  var toggleLegend = function (layerName, isEnabled) {
    switch (layerName) {
      case "parcelTiles":
        isEnabled ? thisLegend.find(".js-parcels").show() : thisLegend.find(".js-parcels").hide();
        break;
      case "parcelsPluEsriDynamicMap":
        isEnabled ? thisLegend.find(".js-priority").show() : thisLegend.find(".js-priority").hide();
        break;
      case "landuse":
      case "landuseEsriDynamicMap":
        isEnabled ? thisLegend.find(".js-landuse").show() : thisLegend.find(".js-landuse").hide();
        break;
      case "trashProgressEsriDynamicMap":
        isEnabled
          ? thisLegend.find(".js-trash-progress").show()
          : thisLegend.find(".js-trash-progress").hide();
        break;
      case "trashConditionEsriDynamicMap":
        isEnabled
          ? thisLegend.find(".js-trash-condition").show()
          : thisLegend.find(".js-trash-condition").hide();
        break;
      case "trashConditionCertaintyEsriDynamicMap":
        isEnabled
          ? thisLegend.find(".js-certainty").show()
          : thisLegend.find(".js-certainty").hide();
        break;
      case "particulatesProgress":
        isEnabled
          ? thisLegend.find(".js-particulates-progress").show()
          : thisLegend.find(".js-particulates-progress").hide();
        break;
      case "particulatesOpportunity":
        isEnabled
          ? thisLegend.find(".js-particulates-opportunity").show()
          : thisLegend.find(".js-particulates-opportunity").hide();
        break;
      case "runoffProgress":
        isEnabled
          ? thisLegend.find(".js-runoff-progress").show()
          : thisLegend.find(".js-runoff-progress").hide();
        break;
      case "runoffOpportunity":
        isEnabled
          ? thisLegend.find(".js-runoff-opportunity").show()
          : thisLegend.find(".js-runoff-opportunity").hide();
        break;
      case "runoffRatioProgress":
        isEnabled
          ? thisLegend.find(".js-runoff-ratio-progress").show()
          : thisLegend.find(".js-runoff-ratio-progress").hide();
        break;
      case "runoffRatioOpportunity":
        isEnabled
          ? thisLegend.find(".js-runoff-ratio-opportunity").show()
          : thisLegend.find(".js-runoff-ratio-opportunity").hide();
        break;
      case "streams":
      case "streamsEsriDynamicMap":
        isEnabled
          ? thisLegend.find(".streams-td").css("visibility", "visible")
          : thisLegend.find(".streams-td").css("visibility", "hidden");
        break;
      case "ms4Boundary":
        isEnabled
          ? thisLegend.find(".boundary-td").css("visibility", "visible")
          : thisLegend.find(".boundary-td").css("visibility", "hidden");
        break;
      case "flowRouting":
        isEnabled
          ? thisLegend.find(".flow-routing-td").css("visibility", "visible")
          : thisLegend.find(".flow-routing-td").css("visibility", "hidden");
        break;
      case "outfallsSimple":
        isEnabled
          ? thisLegend.find(".outfalls-simple-td").css("visibility", "visible")
          : thisLegend.find(".outfalls-simple-td").css("visibility", "hidden");
        break;
      case "stormdrains":
        isEnabled
          ? thisLegend.find(".stormdrains-td").css("visibility", "visible")
          : thisLegend.find(".stormdrains-td").css("visibility", "hidden");
        break;
      case "bmps":
        isEnabled ? thisLegend.find(".js-bmps").show() : thisLegend.find(".js-bmps").hide();
        break;
      case "survey123":
        isEnabled
          ? thisLegend.find(".js-survey123").show()
          : thisLegend.find(".js-survey123").hide();
        break;
      case "fullDrainage":
        isEnabled
          ? thisLegend.find(".js-full-drainage").show()
          : thisLegend.find(".js-full-drainage").hide();
        break;
      case "fcs":
        isEnabled ? thisLegend.find(".js-fcs").show() : thisLegend.find(".js-fcs").hide();
        break;
      case "litterHotspotEsriDynamicMap":
        isEnabled
          ? thisLegend.find(".js-litter-hot-spots").show()
          : thisLegend.find(".js-litter-hot-spots").hide();
        break;
      case "sbmpProgress":
        isEnabled
          ? thisLegend.find(".js-sbmp-progress").show()
          : thisLegend.find(".js-sbmp-progress").hide();
        break;
      case "sbmpProgressDrainage":
        isEnabled
          ? thisLegend.find(".js-full-drainage").show()
          : thisLegend.find(".js-full-drainage").hide();
        break;
      case "fcsProgress":
        isEnabled
          ? thisLegend.find(".js-fcs-progress").show()
          : thisLegend.find(".js-fcs-progress").hide();
        break;
      case "fcsProgressDrainage":
        isEnabled
          ? thisLegend.find(".js-full-drainage").show()
          : thisLegend.find(".js-full-drainage").hide();
        break;
      case "constructionProjectProgress":
        isEnabled
          ? thisLegend.find(".js-construction-project-progress").show()
          : thisLegend.find(".js-construction-project-progress").hide();
        break;
      case "constructionProjectAreaProgress":
        isEnabled
          ? thisLegend.find(".js-construction-project-area-progress").show()
          : thisLegend.find(".js-construction-project-area-progress").hide();
        break;
      case "lidProjectAreaProgress":
        isEnabled
          ? thisLegend.find(".js-lid-project-area-progress").show()
          : thisLegend.find(".js-lid-project-area-progress").hide();
        break;
      case "incidentProgress":
        isEnabled
          ? thisLegend.find(".js-legend-incidents").show()
          : thisLegend.find(".js-legend-incidents").hide();
        break;
      case "highPriorityArea":
        isEnabled
          ? thisLegend.find(".legend-high-priority-area").show()
          : thisLegend.find(".legend-high-priority-area").hide();
        break;
      case "muniCatchBasinProgress":
        isEnabled
          ? thisLegend.find(".js-muni-catch-basin-progress").show()
          : thisLegend.find(".js-muni-catch-basin-progress").hide();
        break;
      case "muniCatchBasinProgressDrainage":
        isEnabled
          ? thisLegend.find(".js-muni-catch-basin-drainage").show()
          : thisLegend.find(".js-muni-catch-basin-drainage").hide();
        break;
      case "maintenanceZones":
        isEnabled
          ? thisLegend.find(".js-maintenance-zones").show()
          : thisLegend.find(".js-maintenance-zones").hide();
        break;
    }
  };

  var handleFCSDisplay = function (layer, mapId) {
    const alreadyChecked = Tree.get("layers", mapId, layer, "isEnabled");
    if (layer == "fcs") {
      if (alreadyChecked) {
        thisLegend.find("input[type=radio][value=fullDrainage]").prop("checked", false);
        toggleLegend("fullDrainage", false);
      }
    }
  };

  var showLegend = function () {
    thisLegend.addClass("open");
    thisLegend.find(".map-legend").show();
    thisLegend.find(".legend-divider").show();
    thisLegend.find(".report-legend-divider").show();
    thisLegend.find(".legend-button-close").show();
    thisLegend.find(".legend-button-legend").hide();
  };

  var hideLegend = function () {
    thisLegend.removeClass("open");
    thisLegend.find(".map-legend").hide();
    thisLegend.find(".legend-divider").hide();
    thisLegend.find(".report-legend-divider").hide();
    thisLegend.find(".legend-button-close").hide();
    thisLegend.find(".legend-button-legend").show();
  };

  var closeLayers = function (header, mapId) {
    hideLegend();
    Actions.toggleLegendDisplay(false, mapId);
    header.find(".expand-arrow").removeClass("open");

    header
      .closest(".map-layers")
      .find(".layers-body")
      .slideUp(400, function () {
        thisLegend.find(".legend-button-legend").hide();
      });
  };

  var openLayers = function (header) {
    thisLegend.find(".legend-button").show();
    thisLegend.find(".legend-button-legend").show();
    header.find(".expand-arrow").addClass("open");
    header
      .closest(".map-layers")
      .find(".layers-body")
      .slideDown(400, function () {});
  };

  var toggleLegendArrow = function (header, mapId) {
    if (header.find(".expand-arrow").hasClass("open")) {
      closeLayers(header, mapId);
    } else {
      openLayers(header, mapId);
    }
  };

  var removeOtherRadioButtonLayers = function (layerName, radioLayers, mapLayers, mapId) {
    radioLayers.forEach(function (otherLayerName) {
      if (layerName !== otherLayerName) turnLayerOff(otherLayerName, mapLayers);
    });
  };

  var getRadioLayersInGroup = function (layerName) {
    return thisLegend
      .find(`input[value="${layerName}"]`)
      .closest(".js-radio-group")
      .find("input")
      .get()
      .map((input) => input.value);
  };

  var resetRadios = function () {
    const mapId = Tree.get("mapId");

    if (mapId === "inputs") {
      if (!Tree.get("layers", mapId, "trashConditionEsriDynamicMapLayer", "isEnabled")) {
        PageFunctions.getCurrentPage().find("#radio-trash-condition").click();
        Tree.set(["layers", mapId, "pluParcelsEsriDynamicMapLayer", "isEnabled"], false);
        Tree.set(["layers", mapId, "fullDrainage", "isEnabled"], false);
        Tree.set(["layers", mapId, "fcs", "isEnabled"], false);

        PageFunctions.getCurrentPage().find(".radio-fcs").prop("checked", false);
      }
      if (!Progeny.activeHasProgeny()) {
        PageFunctions.getCurrentPage()
          .find("#radio-trash-condition, #radio-certainty")
          .parent()
          .removeClass("disabled");
      }
    } else if (mapId === "plan") {
      if (!Tree.get("layers", mapId, "LitterHotspotEsriDynamicMapLayer", "isEnabled")) {
        PageFunctions.getCurrentPage().find("#radio-litter-hot-spots").trigger();
      }
      if (Progeny.activeHasProgeny()) {
        PageFunctions.getCurrentPage()
          .find("#radio-litter-hot-spots")
          .parent()
          .removeClass("disabled");
      }
    }
  };

  return {
    legendCheckboxChange,
    legendRadioChange,
    showLegend,
    hideLegend,
    toggleLegendArrow,
    startLegendListeners,
    toggleLegend,
    resetRadios,
  };
};

module.exports = DisplayOptions();

const Actions = require("../actions");
const Tree = require("../../tree");
const Clustering = require("./clustering");
const BasemapFunctions = require("./basemapFunctions");
const PageFunctions = require("../pageFunctions");
const Progeny = require("../../login/progeny");
const LayerFunctions = require("./layerFunctions");
