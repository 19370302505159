"use strict";

var MapTitle = function () {
  function set(title) {
    if (title.includes("Regional Board")) {
      title = title.replace("al Board", "");
    }
    Tree.set("mapTitle", title);
    $(".map-title-header").text(title);
  }

  function setToSpatialTitle() {
    set(getSpatialTitle());
  }

  function getSpatialTitle() {
    const receivingWater = Tree.get("selected", "receivingWater");
    const receivingWaterSelected = receivingWater && receivingWater.length > 0;
    const catchment = Tree.get("selected", "catchment");
    const catchmentSelected = catchment && catchment.length > 0;
    const urbanDrainage = Tree.get("selected", "urbanDrainage");
    const urbanDrainageSelected = urbanDrainage && urbanDrainage.length > 0;
    const hasSpatialSelection =
      receivingWaterSelected || catchmentSelected || urbanDrainageSelected;
    if (hasSpatialSelection) {
      const selectionTitle =
        (receivingWater && receivingWater.length) > 0
          ? receivingWater
          : (urbanDrainage && urbanDrainage.length) > 0
            ? urbanDrainage
            : (catchment && catchment.length) > 0
              ? catchment
              : null;

      const drainsTo = Tree.get("selected", "rwThatSelectedDrainsTo");
      if (drainsTo) {
        return `${selectionTitle} drains to ${drainsTo}`;
      } else {
        return `Drains to ${selectionTitle}`;
      }
    } else {
      return Tree.get("activeGroup", "name");
    }
  }

  function loadListeners() {
    Tree.select("activeTab").on("update", () => {
      if (Tabs.activeTabHasMap()) {
        handleWhiteTextByBasemap(Tree.get("mapId"));
      }
    });

    Tree.select("activeGroup").on("update", () => {
      setToSpatialTitle();
    });
  }

  function toggleUseWhiteText(toggle) {
    const $mapTitle = PageFunctions.getCurrentPage().find(".map-title");
    toggle ? $mapTitle.addClass("white-text") : $mapTitle.removeClass("white-text");
  }

  function handleWhiteTextByBasemap(mapId) {
    const whiteTextSet = ["Imagery", "DarkGray", "Hybrid"];
    toggleUseWhiteText(whiteTextSet.includes(Tree.get("basemap", mapId)));
  }

  return {
    loadListeners,
    setToSpatialTitle,
    toggleUseWhiteText,
    getSpatialTitle,
  };
};

module.exports = MapTitle();

const Tree = require("../../tree");
const PageFunctions = require("../pageFunctions");
const Tabs = require("../tabs");
