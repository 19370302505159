"use strict";

const DateRange = function () {
  let $parent;

  const init = (parent = "body") => {
    $parent = $(parent);
    _loadListeners();
  };

  const _loadListeners = () => {
    $parent
      .off("dp.change", '[name$="[from]"], [name$="[to]"]', _handleDateChange)
      .on("dp.change", '[name$="[from]"], [name$="[to]"]', _handleDateChange)
      .off("change", '[name$="[noDate]"]', _handleNoDateChange)
      .on("change", '[name$="[noDate]"]', _handleNoDateChange);
  };

  const _handleDateChange = function () {
    const name = _getNameFromInput($(this));
    const from = $(`[name="${name}[from]"]`).val();
    const to = $(`[name="${name}[to]"]`).val();
    if (from || to) {
      $(`[name="${name}[noDate]"]`).prop("checked", false);
    }
  };

  const _handleNoDateChange = function () {
    const $noDate = $(this);
    const name = _getNameFromInput($noDate);
    if ($noDate.is(":checked")) {
      clear(name);
      $noDate.prop("checked", true);
    }
  };

  const getData = (all = false) => {
    const data = {};

    $(".date-range").each(function () {
      const $dateRange = $(this);
      const $dateFrom = $dateRange.find('[name*="[from]"]');
      const $dateTo = $dateRange.find('[name*="[to]"]');
      const $dateNone = $dateRange.siblings().find('[name*="[noDate]"]');

      if (!$dateTo.length) return;

      const name = _getNameFromInput($dateFrom);
      const noDate = $dateNone.length === 0 ? false : $dateNone.prop("checked");

      data[name] = {
        from: all ? "" : _getDate($dateFrom),
        to: all ? "" : _getDate($dateTo),
        noDate: all ? false : noDate,
      };
    });

    return data;
  };

  const setData = (data) => {
    for (const name in data) {
      const { from, to, noDate } = data[name] || {};
      $(`[name="${name}[from]"]`).val(from || "");
      $(`[name="${name}[to]"]`).val(to || "");
      $(`[name="${name}[noDate]"]`).prop("checked", !!noDate);
    }
  };

  const clear = (key) => {
    $(`.datepicker[name="${key}[from]"], .datepicker[name="${key}[to]"]`)
      .val("")
      .datetimepicker("clear");
    $(`[name="${key}[noDate]"][type=checkbox]`).prop("checked", false);
  };

  const _getNameFromInput = ($el) => {
    return $el.attr("name").split("[")[0];
  };

  const _getDate = ($input) => DateTimePicker.getStartOfDayGroupIsoString($input);

  return {
    init,
    getData,
    setData,
    clear,
    _getNameFromInput,
  };
};

module.exports = DateRange();

const DateTimePicker = require("../general/dateTimePicker");
