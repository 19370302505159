"use strict";

const Esg = function () {
  var initialize = function () {
    NavTabs.init();
    NavToggle.init();
    Tree.set("tool", "esg");
    $(".city-name").text(Tree.get("activeGroup", "group"));
    ModalMap.init();
    _setMapDefaults();
  };

  var renderHome = async function () {
    if (Session.isLoggedIn()) {
      UrlRoutes.go("portfolio");
      return;
    }

    await PageController.render("home", "esg/home.njk", async () => ({}));
  };

  var _setMapDefaults = function () {
    const { center, zoomLevel } = MapFunctions.getUsaView();
    Tree.set("zoomLevel", zoomLevel);
    Tree.set("mapCenter", center);
  };

  return {
    initialize,
    renderHome,
  };
};

module.exports = Esg();

const Session = require("../login/session");
const Tree = require("../tree");
const PageController = require("./pageController");
const NavTabs = require("./navTabs");
const NavToggle = require("./navToggle");
const UrlRoutes = require("../routes/urlRoutes");
const MapFunctions = require("../mapFunctions/mapFunctions");
const ModalMap = require("../mapFunctions/modalMap");
