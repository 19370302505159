"use strict";

const BulkSelectPage = function () {
  const stringKey = "bulk-select-page";
  let headerInformation = "Bulk Select";
  let pageConfig = {};
  let $page;

  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, "bulk-select-page", { isMultiPart: true });
    loadPopupListeners();
  };

  var loadPopupListeners = function () {
    $("#inventory-modal").off("click", ".popup-bulk-select .select-button", handlePopupSelect);
    $("#inventory-modal").on("click", ".popup-bulk-select .select-button", handlePopupSelect);

    $("#inventory-modal").off("click", ".popup-bulk-select .deselect-button", handlePopupDeselect);
    $("#inventory-modal").on("click", ".popup-bulk-select .deselect-button", handlePopupDeselect);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = function (options) {
    configureBulkSelectPageByLayerAction(options);
    renderBulkSelectHtml();
    showBulkSelectMap();
    loadListeners();
    setBulkSelectHint();
    setBulkSelectModalTitle();
    setBulkSelectPageHeaderInfo();
  };

  var configureBulkSelectPageByLayerAction = function (options) {
    // @TODO: Refactor when more than one bulk select action
    if (options.actionKey === "catchBasinBulkClean") {
      const CatchBasinBulkCleanLayer = require("../muni/catchBasinBulkCleanLayer");
      pageConfig.getBulkSelectLayer = CatchBasinBulkCleanLayer.getBulkSelectLayer;
      pageConfig.modalTitle = "Catch Basin Cleaning";
      pageConfig.hint = "Select catch basins that have been cleaned.";
      pageConfig.headerInformation = headerInformation = "Select Catch Basins";
      pageConfig.bulkSelectPageTemplate = "modals/bulkSelect/catchBasinBulkClean.njk";
      pageConfig.bulkSelectTableTemplate = "modals/bulkSelect/tables/catchBasinBulkCleanTable.njk";
      pageConfig.bulkSelectRowTemplate = "modals/bulkSelect/tables/catchBasinBulkCleanRow.njk";
      pageConfig.loadLayerHandler = CatchBasinBulkCleanLayer.show;
      pageConfig.selectHandler = CatchBasinBulkCleanLayer.select;
      pageConfig.deselectHandler = CatchBasinBulkCleanLayer.deselect;
      pageConfig.selectAllHandler = CatchBasinBulkCleanLayer.selectAll;
      pageConfig.assignTableIconsHandler = assignMuniCatchBasinSelectedIcons;
      pageConfig.multiSelectHandler = CatchBasinBulkCleanLayer.multiSelect;
      pageConfig.getMarkerReferenceHandler = CatchBasinBulkCleanLayer.getMarkerReference;
      pageConfig.modalBasemap = "Imagery";
      pageConfig.layerGeomType = "Point";
      pageConfig.polyline = false;
    }
  };

  var getPageConfig = function (propertyName, warning = false) {
    if (propertyName) {
      if (pageConfig[propertyName] !== undefined) {
        return pageConfig[propertyName];
      } else {
        if (warning) {
          console.warn(`Warning: ${propertyName} is not defined in bulk select pageConfig`);
        } else {
          console.error(`Error: ${propertyName} is not defined in bulk select pageConfig`);
        }
      }
    } else {
      return pageConfig;
    }
  };

  var renderBulkSelectHtml = function () {
    var pageTemplate = getPageConfig("bulkSelectPageTemplate");
    var templateOptions = getPageConfig("bulkSelectTemplateOptions", true);
    var bulkSelectPageListeners = getPageConfig("bulkSelectPageListeners", true);

    var renderData = {
      templateOptions,
      defaultDate: DateTime.getTodayIso(),
      bulkSelectedCount: 0,
    };
    var html = nunjucks.render(pageTemplate, renderData);
    BulkSelectModalController.renderPageContent(html);
    if (bulkSelectPageListeners) {
      bulkSelectPageListeners();
    }
    $page = $(".modal-dynamic-content");
  };

  var showBulkSelectMap = function () {
    const bulkSelectMapHandlers = {
      loadLayerHandler: pageConfig.loadLayerHandler,
      deselectHandler: pageConfig.deselectHandler,
      assignTableIconsHandler: pageConfig.assignTableIconsHandler,
      selectAllHandler: pageConfig.selectAllHandler,
      getMarkerReferenceHandler: pageConfig.getMarkerReferenceHandler,
      bulkSelectTableMouseoverHandler: pageConfig.bulkSelectTableMouseoverHandler,
      bulkSelectTableMouseoutHandler: pageConfig.bulkSelectTableMouseoutHandler,
    };
    BulkSelectMap.resetBulkSelectMapHandlers();
    BulkSelectMap.configureBulkSelectMapHandlers(bulkSelectMapHandlers);
    BulkSelectMap.showBulkSelectMap();
  };

  var setBulkSelectHint = function () {
    if (pageConfig.hint) {
      ModalMap.showLocationMessage(pageConfig.hint);
    }
  };

  var setBulkSelectModalTitle = function () {
    if (pageConfig.modalTitle) {
      BulkSelectModalController.setModalTitle(pageConfig.modalTitle);
    } else {
      console.warn("Warning: bulk select page modal title is not set");
    }
  };

  var setBulkSelectPageHeaderInfo = function () {
    if (pageConfig.headerInformation) {
      $(".steps").find(`li[data-string-key="${stringKey}"]`).text(pageConfig.headerInformation);
    } else {
      console.warn("Warning: bulk select page headerInformation is not set");
    }
  };

  var handlePopupSelect = function (e) {
    var id = $(e.target).closest(".popup-bulk-select").data("id");
    var selectHandler = getPageConfig("selectHandler");
    selectHandler(id);
  };

  var handlePopupDeselect = function (e) {
    var id = $(e.target).closest(".popup-bulk-select").data("id");
    var deselectHandler = getPageConfig("deselectHandler");
    deselectHandler(id);
  };

  var handleMultiSelect = function (selectedIds) {
    const BulkSelectTable = require("./bulkSelectTable");
    BulkSelectTable.resetSavedSelectedId();
    var multiSelectHandler = getPageConfig("multiSelectHandler");
    multiSelectHandler(selectedIds);
  };

  var validateAndShowWarningModal = function () {
    return true;
  };

  var cleanUp = function () {
    $("#floating-bulk-select-table").hide();
    $page.empty();
    return true;
  };

  var validate = function () {
    return validateAndShowWarningModal();
  };

  var resetState = function () {
    pageConfig = {};
  };

  var assignMuniCatchBasinSelectedIcons = function (data) {
    data.forEach(function (asset) {
      asset.tableIcon = MuniCatchBasinIcon.getBulkCleanIconClass(
        true,
        asset.isFcs,
        asset.isCatchBasinHighPriority,
      );
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    resetState,
    getPageConfig,
    handleMultiSelect,
  };
};

module.exports = BulkSelectPage();

const ModalMap = require("../mapFunctions/modalMap");
const BulkSelectModalController = require("./bulkSelectModalController");
const BulkSelectMap = require("./bulkSelectMap");
const Form = require("../general/form");
const DateTime = require("../dateTime");
const MuniCatchBasinIcon = require("../muni/muniCatchBasinIcon");
