"use strict";

const SentryConfig = function () {
  const init = function () {
    if (Config.isWorkerContext()) {
      return;
    }

    const environment = Config.get().environment;

    if (environment === "local") {
      return;
    }

    const Sentry = require("@sentry/browser");
    Sentry.init({
      environment: environment,
      release: `apps@${global.twoNformBuildVersion}`,
      dsn: "https://8578a7bd5a3545ff9c2587882eb7ed7d@sentry.io/1512747",
      beforeSend: _filterSentryEvents,
      beforeBreadcrumb: _filterSentryBreadcrumbs,
      integrations: [],
    });
  };

  const _filterSentryEvents = function (e, hint) {
    const error = hint.originalException;
    const isOffline = window.navigator.onLine === false || e.tags?.online === false;
    const isNormalNetworkError =
      error instanceof NetworkError && [0, 401, 403].includes(error.status);
    const isKnownApiError = error instanceof ApiError && [404, 422, 500].includes(error.status);
    const isOfflineNetworkError = error instanceof NetworkError && isOffline;

    if (!Config.isWorkerContext() && isOffline) {
      try {
        const ToolFunctions = require("./toolFunctions");
        const offlineUnsupported = !ToolFunctions.getToolConfigProperty("offline");

        if (offlineUnsupported) {
          return null;
        }
      } catch (e) {
        return null;
      }
    }

    if (isNormalNetworkError || isKnownApiError || isOfflineNetworkError) {
      return null;
    }

    // We warned them their browser is unsupported, don't bother us with further errors
    if (!BrowserSupportsFeature.all()) {
      return null;
    }

    return e;
  };

  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  const _filterSentryBreadcrumbs = function (breadcrumb, hint) {
    // Filter out WMS requests so they don't overwhelm the breadcrumb history
    if (_shouldFilterOutBreadcrumbUrl(breadcrumb, GeoServerLayerFunctions.getWmsLayerUrl())) {
      return null;
    }

    return breadcrumb;
  };

  const _shouldFilterOutBreadcrumbUrl = function (breadcrumb, url) {
    if (breadcrumb.type !== "http" || !url) {
      return false;
    }

    return breadcrumb.data.url === url;
  };

  return {
    init,
    _filterSentryEvents,
    _filterSentryBreadcrumbs,
  };
};

module.exports = SentryConfig();

const ApiError = require("./errors/apiError");
const BrowserSupportsFeature = require("./general/browserSupportsFeature");
const Config = require("./config");
const NetworkError = require("./errors/networkError");
const GeoServerLayerFunctions = require("./mapFunctions/geoServerLayerFunctions");
