"use strict";

const MonitoringLocationLayer = function () {
  const ApiCalls = require("../apiCalls");
  const DryWeatherLayer = require("./dryWeatherLayer");

  const layerName = "monitoringLocation";
  const layerKey = "monitoringLocationLayer";

  var getIddeMonitoringLocationPopup = function (id, mapData) {
    return ApiCalls.getIddeDryWeatherPopup(mapData.assetId, mapData.assetType);
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    monitoringLocationLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    monitoringLocationLayer.loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (mapId === "main") {
      monitoringLocationLayer.loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  const onPopupOpen = async function (data, $element) {
    var onDownloadReport = async function () {
      const id = $(this).closest("tr").data("id");
      await ApiCalls.downloadOutfallInspectionPdf(id);
    };

    $element.off("click", ".download-report", onDownloadReport);
    $element.on("click", ".download-report", onDownloadReport);
  };

  const monitoringLocationLayer = new DryWeatherLayer(
    layerName,
    layerKey,
    ApiCalls.getMonitoringLocations,
    getIddeMonitoringLocationPopup,
    true,
    true,
    true,
    { onPopupOpenFunction: onPopupOpen },
  );

  return {
    ...monitoringLocationLayer,
    loadLayerListenersForMap,
  };
};

module.exports = MonitoringLocationLayer();
