"use strict";

const Geolocation = function () {
  const locationOptions = {
    enableHighAccuracy: true,
  };
  const getCurrentPositionTimeoutSeconds = 5;

  var start = function () {
    if (!Session.isMobileParentView()) {
      navigator.geolocation.watchPosition(updateMarker, handleWatchError, locationOptions);
    }
  };

  var getCurrentLatLng = function () {
    const sToMs = 1000;

    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(updateMarker(position)),
        (error) => fallbackToLastWatchedLocation(resolve, reject, error.code),
        {
          ...locationOptions,
          timeout: getCurrentPositionTimeoutSeconds * sToMs,
        },
      );
    });
  };

  // Browsers seem to have an internal location request timeout that includes
  // both `watchPosition` and `getCurrentPosition` calls, causing
  // `getCurrentPosition` requests to timeout more often than they would if
  // the position wasn't also being watched.
  var fallbackToLastWatchedLocation = function (resolve, reject, errorCode) {
    const lastWatchedLocation = Tree.get("blueDotLatLng");

    // See all error codes at https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
    // Code 3 = TIMEOUT
    if (errorCode === 3 && lastWatchedLocation) {
      resolve(lastWatchedLocation);
      return;
    }

    reject(errorCode);
  };

  var updateMarker = function (pos) {
    const latLng = {
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    };

    Tree.set("blueDotLatLng", latLng);

    return latLng;
  };

  var handleWatchError = function (e) {
    console.warn("Geolocation error (" + e.code + ") " + e.message);
  };

  return {
    start,
    getCurrentLatLng,
  };
};

module.exports = Geolocation();

const Session = require("../login/session");
const Tree = require("../tree");
