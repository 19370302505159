"use strict";

const AddBmpsMap = function () {
  var handlers = {};
  var newBmpClickEnabled = true;
  var fetchingNewBmp = false;
  var overrides = {};
  var layer = { module: null, name: null };

  var init = function (layerName) {
    layer.name = layerName;
    layer.module = LayerFunctions.getLayerModule(layerName);
  };

  var resetAddBmpsMapHandlers = function () {
    handlers = {};
    AddBmpsTable.configureAddBmpsTableHandlers(handlers);
  };

  var configureAddBmpsMap = function (newConfiguration = {}) {
    configureAddBmpsMapHandlers(newConfiguration);
    configureAddBmpsMapOverrides(newConfiguration);
  };

  var configureAddBmpsMapHandlers = function (newHandlers) {
    handlers = {
      addNewBmpHandler: (newHandlers || {}).addNewBmpHandler || null,
      newExistingMarkerHandler: (newHandlers || {}).newExistingMarkerHandler || null,
      locationDataUpdateHandler: (newHandlers || {}).locationDataUpdateHandler || null,
      isValidUpdateHandler: (newHandlers || {}).isValidUpdateHandler || null,
    };
    var modalMapConfiguration = {
      modalMapClickHandler: addBmpsMapClick,
      newExistingMarkerHandler: handlers.newExistingMarkerHandler,
      dragEndHandler: getLocationData,
    };
    var tableHandlers = {
      ...handlers,
      newBmpClickEnabledUpdateHandler: setNewBmpClickEnabled,
    };
    ModalMap.resetModalMapHandlers();
    ModalMap.configureModalMapHandlers(modalMapConfiguration);
    AddBmpsTable.configureAddBmpsTableHandlers(tableHandlers);
  };

  var configureAddBmpsMapOverrides = function (newOverrides) {
    const defaultOverrides = {
      disableAddNewProjectBmp: false,
    };
    overrides = $.extend(true, defaultOverrides, newOverrides);
  };

  var addBmpsMapClick = function (e) {
    if ($("#modalMap").find(".popup-bmp").length) {
      return;
    }
    if (overrides.disableAddNewProjectBmp) {
      MessageModal.showSimpleWarningModal(
        "You cannot add another sBMP while the project is past the Design phase.",
      );
      return;
    }
    if (!newBmpClickEnabled) {
      MessageModal.showSimpleWarningModal(
        "You cannot add another sBMP until an ID has been entered for all inventoried sBMPs.",
        false,
      );
      return;
    }
    if (fetchingNewBmp) {
      return;
    }
    fetchingNewBmp = true;
    AddBmpsTable.resetSavedSelectedIdBmp();
    var latlng = e.latlng;
    getLocationData(latlng, addNewTempBmp);
  };

  var markerDragendHandler = function (idBmp, latlng) {
    AddBmpsTable.resetSavedSelectedIdBmp();
    getLocationData(latlng, updateBmpLocation, idBmp);
  };

  var getLocationData = function (latlng, callback, idBmp = null) {
    const markerDataCallback = function (locationData) {
      fetchingNewBmp = false;

      if (overrides.disableAddNewProjectBmp) {
        ModalMap.hideLocationMessage();
      } else if (locationData.length && locationData[0]) {
        ModalMap.showLocationMessage("Click map to add sBMP");
        if (callback) {
          callback(latlng, locationData, idBmp);
        }
      } else {
        MessageModal.showSimpleWarningModal(
          "An asset cannot be located outside of the MS4 boundary. Please contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
        layer.module.refreshLayer();
      }
    };

    (Tree.get("tool") === "esg" ? EsgApiCalls : ApiCalls)
      .getMarkerData(latlng.lat, latlng.lng)
      .done(markerDataCallback);
  };

  var addNewTempBmp = function (latlng, locationData) {
    const phase = InventoryModal.getAllData("project-inventory").phase;
    var newBmp = {
      isTemp: true,
      idbmp: Date.now(),
      geom: `{"type":"Point","coordinates":[${latlng.lng},${latlng.lat}]}`,

      bmpTypeObj: {
        sortOrder: 16,
        abbreviation: "?",
        name: "Unknown",
        isCentralized: false,
      },
      isBmp: true,
      isFcs: false,
      phase: phase,
      bmp_ptype: 0,
      bmpScore: null,
      bmpInventoryComplete: false,
      bmpObservationDue: false,

      bmp_latitude: latlng.lat,
      bmp_longitude: latlng.lng,
      bmp_apn: locationData[0].parcelid,
      lu_name: locationData[0].combo,
    };
    newBmp.id = newBmp.idbmp;
    if (newBmp.id) {
      $(".assets-page").hide();
      $(`.assets-page[data-id-bmp="${newBmp.id}"]`).show();
    }
    layer.module.addBmpData(newBmp);
  };

  var updateBmpLocation = function (latlng, locationData, idBmp) {
    var newData = {
      geom: `{"type":"Point","coordinates":[${latlng.lng},${latlng.lat}]}`,
      locationUpdated: true,
      bmp_latitude: latlng.lat,
      bmp_longitude: latlng.lng,
      bmp_apn: locationData[0].parcelid,
      lu_name: locationData[0].combo,
    };
    layer.module.updateDataByIdBmp(idBmp, newData);
  };

  var showAddBmpsMap = function () {
    if (!ModalMap.getMap()) {
      ModalMap.showModalMap();
    } else {
      ModalMap.updateModalMapDisplay();
    }
    $("#modalMap").show();

    if (!Tree.get("readOnlyModalMap")) {
      ModalMap.loadModalMapClickListeners();
    }

    ModalMap.showModalMapLegendGroup();
    ModalMap.invalidateModalMapSize();
    $("#modalMap").find(".construction-marker-icon").addClass("hidden");
    showAddBmpsTable();
    ModalMap.getMarker();
    ModalMap.hideLocationMessage();
  };

  var hideAddBmpsMap = function () {
    if (ModalMap.modalMapHasLayer("drainageLayer")) {
      ModalMap.removeMapLayer("drainageLayer");
    }
    Tree.set(["layers", "drainage", "isEnabledModal"], false);
    layer.module.hide();
    $("#modalMap").hide();
    $("#floating-add-bmps-table").hide();
    $("#modal-legend-group").hide();
  };

  var showAddBmpsTable = function () {
    layer.module.show();
    AddBmpsTable.init(layer.name);
    AddBmpsTable.setAddBmpsTableHeight();

    $(".modal-map-floating-table").hide();
    $("#floating-add-bmps-table").show();
  };

  var hideInstructionMessage = function () {
    ModalMap.hideLocationMessage();
  };

  var setNewBmpClickEnabled = function (enabled) {
    newBmpClickEnabled = enabled;

    if (handlers.isValidUpdateHandler !== null) {
      handlers.isValidUpdateHandler(enabled);
    } else {
      console.warn("Warning: isValidUpdateHandler is not set");
    }
  };

  var bmpIsEditable = function (bmp) {
    return true;
  };

  return {
    init,
    resetAddBmpsMapHandlers,
    configureAddBmpsMap,
    showAddBmpsMap,
    hideAddBmpsMap,
    hideInstructionMessage,
    markerDragendHandler,
    bmpIsEditable,
  };
};

module.exports = AddBmpsMap();

const AddBmpsTable = require("./addBmpsTable");
const ApiCalls = require("../apiCalls");
const EsgApiCalls = require("../esg/esgApiCalls");
const MessageModal = require("../modals/messageModal");
const ModalMap = require("./modalMap");
const Tree = require("../tree");
const InventoryModal = require("../general/inventoryModal");
const LayerFunctions = require("../layerFunctions");
