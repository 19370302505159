"use strict";

const FastMarker = function () {
  const FastMarkerGroup = L.FeatureGroup.extend({
    fastMarkerMarkerLayer: null,
    fastMarkerCircleLayer: null,
    fastMarkerClusteringLayer: null,
    fastMarkerPopupLayer: null,
    markersVisible: null,
    updateMarkersVisible: null,
    layerName: null,
  });
  const DefaultHiddenMarker = L.Marker.extend({
    getIcon: function () {
      this.initIcon();

      return this._icon;
    },
    _removeIcon: function () {
      if (this._icon) {
        L.Marker.prototype._removeIcon.call(this);
      }
    },
    _removeShadow: function () {
      if (this._shadow) {
        L.Marker.prototype._removeShadow.call(this);
      }
    },
    _initIcon: function (createIcon = false) {
      if (createIcon === true) {
        L.Marker.prototype._initIcon.call(this);
      }
    },
    _setPos: function (pos) {
      if (this._icon) {
        L.Marker.prototype._setPos.call(this, pos);
      }
    },
    initIcon: function () {
      if (!this._icon) {
        this._initIcon(true);
        this.update();
        this._iconParent = undefined;
        this._shadowParent = undefined;
      }
    },
    showIcon: function () {
      this.isVisible = true;
      this._wasVisible = false;
      updateIconVisibility(this);
    },
  });
  const maxVisibleCanvasFeaturesToUncluster = 10000;

  var createFastMarker = function (map, markerLayerGroup, layerName, circleMarkerColorCallback) {
    const firstLayer = markerLayerGroup.getLayers()[0];
    if (firstLayer && !(firstLayer instanceof DefaultHiddenMarker)) {
      throw new Error("FastMarker only supports layer groups containing DefaultHiddenMarker.");
    }

    const fastMarkerLayer = new FastMarkerGroup();
    fastMarkerLayer.layerName = layerName;
    const circleLayer = makeCircleGroupLayer(markerLayerGroup, circleMarkerColorCallback);
    const clusteringLayer = Clustering.createClusterGroup(markerLayerGroup);
    linkFastMarkerLayers(fastMarkerLayer, markerLayerGroup, circleLayer, clusteringLayer);
    initFastMarkerListeners(map, fastMarkerLayer);

    return fastMarkerLayer;
  };

  var initFastMarkerListeners = function (map, fastMarkerLayer) {
    map.off("resize moveend", updateAllFastMarkerLayers, map);
    map.on("resize moveend", updateAllFastMarkerLayers, map);

    fastMarkerLayer.on("add", function () {
      _updateFastMarker(map, fastMarkerLayer);
    });
  };

  var updateAllFastMarkerLayers = function () {
    const map = this;

    map.eachLayer(function (layer) {
      if (layer instanceof FastMarkerGroup) {
        _updateFastMarker(map, layer);
      }
    });
  };

  var isFastMarkerLayer = function (layer) {
    return layer instanceof FastMarkerGroup;
  };

  var isMaxVisible = function (fastMarkerLayer) {
    assertIsFastMarkerLayer(fastMarkerLayer);
    return fastMarkerLayer.markersVisible > maxVisibleCanvasFeaturesToUncluster;
  };

  var assertIsFastMarkerLayer = function (layer) {
    if (!isFastMarkerLayer(layer)) {
      throw new Error("Passed layer is not FastMarker");
    }
  };

  var _updateFastMarker = function (map, fastMarkerLayer) {
    countVisibleMarkers(map.getBounds(), fastMarkerLayer);
    updateCircleMarkerOptions(map.getZoom(), fastMarkerLayer);
    Clustering.setState(fastMarkerLayer.layerName);
  };

  var countVisibleMarkers = function (bounds, fastMarkerLayer) {
    const markerLayer = fastMarkerLayer.fastMarkerMarkerLayer;
    fastMarkerLayer.markersVisible = 0;

    markerLayer.eachLayer(function (layer) {
      setIsVisible(bounds, layer);

      if (layer.isVisible) {
        fastMarkerLayer.markersVisible++;
      }
    });
  };

  var setIsVisible = function (bounds, layer) {
    layer.isVisible = bounds.contains(layer.getLatLng());
  };

  var setVisibleFastMarkerLayer = function (fastMarkerLayer) {
    assertIsFastMarkerLayer(fastMarkerLayer);

    const zoom = fastMarkerLayer._map.getZoom();
    //   -16 normal markers
    // 14-15 automatic
    // 13-   dots

    var visibleLayer;
    if (Clustering.clusteringIsEnabled()) {
      visibleLayer = fastMarkerLayer.fastMarkerClusteringLayer;
    } else if (
      (fastMarkerLayer.markersVisible < Clustering.getMaxFeaturesToUncluster() && zoom > 13) ||
      zoom >= 16
    ) {
      visibleLayer = fastMarkerLayer.fastMarkerMarkerLayer;
    } else {
      visibleLayer = fastMarkerLayer.fastMarkerCircleLayer;
    }
    setFastMarkerLayer(fastMarkerLayer, visibleLayer);
  };

  var setFastMarkerLayer = function (fastMarkerLayer, layerGroup) {
    if (!fastMarkerLayer.hasLayer(layerGroup)) {
      const oldLayers = fastMarkerLayer.getLayers();
      fastMarkerLayer.clearLayers();
      for (const layerGroup of oldLayers) {
        for (const layer of layerGroup.getLayers()) {
          layer._wasVisible = true;
        }
      }

      fastMarkerLayer.addLayer(fastMarkerLayer.fastMarkerPopupLayer);
      fastMarkerLayer.addLayer(layerGroup);
    }

    updateLayerGroupIconVisibility(layerGroup);
  };

  var updateLayerGroupIconVisibility = function (layerGroup) {
    layerGroup.eachLayer(function (layer) {
      updateIconVisibility(layer);
    });
  };

  // Modified from https://github.com/Leaflet/Leaflet/issues/1324#issuecomment-384697787
  var updateIconVisibility = function (layer) {
    if (!layer._map || !(layer instanceof L.Marker) || (!layer._icon && !layer.isVisible)) {
      return;
    }

    var isVisible = layer.isVisible,
      icon = layer.getIcon(),
      wasVisible = layer._wasVisible,
      iconParent = layer._iconParent,
      shadow = layer._shadow,
      shadowParent = layer._shadowParent;

    // remember parent of icon
    if (!iconParent) {
      iconParent = layer._iconParent = icon.parentNode;
    }
    if (shadow && !shadowParent) {
      shadowParent = layer._shadowParent = shadow.parentNode;
    }

    // add/remove from DOM on change
    if (isVisible !== wasVisible) {
      if (isVisible) {
        iconParent.appendChild(icon);
        if (shadow) {
          shadowParent.appendChild(shadow);
        }
      } else {
        iconParent.removeChild(icon);
        if (shadow) {
          shadowParent.removeChild(shadow);
        }
      }

      layer._wasVisible = isVisible;
    }
  };

  var makeCircleGroupLayer = function (layerGroup, circleMarkerColorCallback) {
    const circleLayerGroup = L.layerGroup();

    layerGroup.eachLayer(function (layer) {
      // Popup and layer events aren't copied properly.
      // circleLayer.bindPopup(layer.getPopup());

      const color = circleMarkerColorCallback(layer.feature.properties);

      const circleMarkerOptions = {
        color: color,
        fillColor: color,
        fillOpacity: 1,
        weight: 0,
      };
      // Radius is dynamically set by getCircleMarkerRadiusByZoom()

      circleMarkerOptions.interactive = false;
      const circleLayer = L.circle(layer.getLatLng(), circleMarkerOptions);
      circleLayerGroup.addLayer(circleLayer);
    });

    return circleLayerGroup;
  };

  var getCircleMarkerRadiusByZoom = function (zoom) {
    if (zoom >= 14) {
      return 25;
    } else {
      return 50;
    }
  };

  var updateCircleMarkerOptions = function (zoom, fastMarkerLayer) {
    const circleLayer = fastMarkerLayer.fastMarkerCircleLayer;
    const radius = getCircleMarkerRadiusByZoom(zoom);

    circleLayer.eachLayer(function (layer) {
      layer.setRadius(radius);
    });
  };

  var linkFastMarkerLayers = function (fastMarkerLayer, markerLayer, circleLayer, clusteringLayer) {
    fastMarkerLayer.fastMarkerMarkerLayer = markerLayer;
    fastMarkerLayer.fastMarkerPopupLayer = L.layerGroup();
    fastMarkerLayer.fastMarkerCircleLayer = circleLayer;
    fastMarkerLayer.fastMarkerClusteringLayer = clusteringLayer;
  };

  var getMarkerByFeature = function (fastMarkerLayer, searchCallback) {
    assertIsFastMarkerLayer(fastMarkerLayer);
    resetPopupLayer(fastMarkerLayer);

    for (const layer of fastMarkerLayer.fastMarkerMarkerLayer.getLayers()) {
      if (searchCallback(layer.feature.properties)) {
        fastMarkerLayer.fastMarkerPopupLayer.addLayer(layer);

        return layer;
      }
    }

    return null;
  };

  var resetPopupLayer = function (fastMarkerLayer) {
    const popupLayer = fastMarkerLayer.fastMarkerPopupLayer;
    var lastMarkerOpened = popupLayer.getLayers();

    if (lastMarkerOpened.length > 1) {
      throw new Error("There should only ever be one marker on the popup layer.");
    } else if (lastMarkerOpened.length === 1) {
      const layer = lastMarkerOpened[0];
      popupLayer.clearLayers();
      // Readding the layer is a workaround for Leaflet not working properly
      // when you have the same marker in multiple layers.
      // https://github.com/Leaflet/Leaflet/issues/598
      fastMarkerLayer.fastMarkerMarkerLayer.removeLayer(layer);
      fastMarkerLayer.fastMarkerMarkerLayer.addLayer(layer);
      layer.showIcon();
    }
  };

  return {
    createFastMarker,
    isFastMarkerLayer,
    isMaxVisible,
    DefaultHiddenMarker,
    setVisibleFastMarkerLayer,
    _updateFastMarker,
    getMarkerByFeature,
  };
};

module.exports = FastMarker();

const Clustering = require("./clustering");
