"use strict";

const EsriConfig = function () {
  const get = function () {
    return {
      trash: {
        ids: {
          trashProgress: 0,
          trashCondition: 1,
          litterHotspot: 2,
          trashConditionCertainty: 3,
          progress: 4,
          condition: 5,
          hotspot: 6,
          conditionCertainty: 7,
        },
        filters: {
          catchments: "catch_name",
          drainsToRw: "drains_to_rw",
          drainsToC: "drains_to_c",
          reportYearFirstValid: "reporting_year_first_valid",
          reportYearNotValid: "reporting_year_not_valid",
        },
      },
      simLayer: {
        webmap: "9b990a663b14473294c300981125dc25",
      },
      soilImpervious: {
        ids: { soil: 0, impervious: 1, trash: 2, landuse: 3, catchment: 4, streams: 5 },
        filters: {
          catchments: "catch_id",
          drainsToRw: "drains_to_rw",
          drainsToC: "drains_to_c",
          reportYearFirstValid: "reporting_year_first_valid",
          reportYearNotValid: "reporting_year_not_valid",
        },
      },
      parentView: {
        ids: {
          catchment: 0,
          maintenanceZone: 1,
          "catch-basin": 8,
          streams: 3,
          manhole: 9,
          bmp: 7,
          "lift-station": 14,
          "clean-out": 10,
          culvert: 13,
          outfall: 6,
          "open-channel": 11,
          pipe: 12,
        },
        filters: {
          catchments: "catch_id",
          drainsToRw: "drains_to_rw",
          drainsToC: "drains_to_c",
          maintenanceZones: "zone_name",
          maintenanceZone: "zone_name",
          types: "bmp_type",
          phase: "phase",
          catchBasinPriority: "catch_basin_priority",
          inProximityTo303dWaterbody: "in_proximity_to_303d_waterbody",
          landOwnership: "bmp_ptype",
          responsiblePartyMaintenance: "responsible_party_maintenance",
          fcs: "fcs",
          cleanout: "cleanout",
          dateTo: "BMP_CREATION_TO",
          dateFrom: "BMP_CREATION_FROM",
          searchString: "SEARCH_STRING",
          correctiveAction: "maintenance_issue_todo_key",
          enforcementLevel: "maintenance_issue_todo_level",
          receivingWater: "drains_to_rw",
          catchment: "catch_id",
          dueDateFrom: "DUE_DATE_FROM",
          dueDateTo: "DUE_DATE_TO",
        },
      },
      assets: {
        ids: {
          catchment: 0,
          "asset-catch-basin": 3,
          "asset-manhole": 4,
          bmp: 2,
          "lift-station": 9,
          "clean-out": 5,
          culvert: 8,
          outfall: 1,
          "open-channel": 6,
          pipe: 7,
        },
        filters: {
          catchments: "catch_id",
          drainsToRw: "drains_to_rw",
          drainsToC: "drains_to_c",
        },
      },
      streetSweepingRoute: {
        ids:
          Config.get().environment === "Production"
            ? {
                streetSweepingRoute: 0,
                streetSweepingRoutePriority: 1,
                streetSweepingRouteSweeperType: 2,
                streetSweepingRouteSweepingFrequency: 3,
                streetSweepingRouteCurbAccess: 4,
                streetSweepingRoutePci: 5,
              }
            : {
                streetSweepingRoute: 6,
                streetSweepingRoutePriority: 7,
                streetSweepingRouteSweeperType: 8,
                streetSweepingRouteSweepingFrequency: 9,
                streetSweepingRouteCurbAccess: 10,
                streetSweepingRoutePci: 11,
              },
        filters: { maintenanceZones: "zone_name", sweepingNetworks: "route_name" },
      },
      roadsOm: {
        ids:
          Config.get().environment === "Production"
            ? {
                roads: 0,
                roadPriority: 1,
                roadSweeperType: 2,
                roadSweepingFrequency: 3,
                roadCurbAccess: 4,
                roadPci: 5,
              }
            : {
                roads: 6,
                roadPriority: 7,
                roadSweeperType: 8,
                roadSweepingFrequency: 9,
                roadCurbAccess: 10,
                roadPci: 11,
              },
        filters: { catchments: "catch_id", drainsToRw: "drains_to_rw", drainsToC: "drains_to_c" },
      },
      parcelsPlu: {
        ids: {
          parcels_plu: [0, 1],
        },
        filters: {
          catchments: "catchid",
          drainsToRw: "drains_to_rw",
          drainsToC: "drains_to_c",
          reportYearFirstValid: "reporting_year_first_valid",
          reportYearNotValid: "reporting_year_not_valid",
        },
      },
    };
  };

  return { get };
};

module.exports = EsriConfig();

const Config = require("../config");
