"use strict";

const ScenarioInsightDashboardController = function () {
  var inited = false;
  var filterChanged = false;
  var lastTelrRun = null;
  var controller = null;
  var $insightContainer;
  var colors = {
    runoff: {
      currentReduction: "#81B6E4",
      potentialReduction: "#1A86E5",
      disconnection: "#A1C0E3",
      decentralized: "#7DA7D8",
      centralized: "#5A8FCD",
    },
    particulate: {
      currentReduction: "#E49B81",
      potentialReduction: "#CD5B32",
      disconnection: "#DFB2A0",
      streetSweeping: "#D39278",
      decentralized: "#C67352",
      centralized: "#B15938",
    },
    zinc: {
      currentReduction: "#94D1CF",
      potentialReduction: "#24A8A3",
      disconnection: "#B4CFCE",
      streetSweeping: "#95BBBA",
      decentralized: "#76A7A6",
      centralized: "#5C8F8D",
    },
  };

  var init = function () {
    if (!inited) {
      $insightContainer = $(".insight-container");

      loadDomListeners();
      loadTreeListeners();

      var widgetCbmpCount = new InsightWidgetSimpleNumberScenario({
        size: 1,
        title: "Centralized sBMPs in Scenarios",
        getDataUnit: () => {
          return "";
        },
        focusOnBottom: () => {
          return true;
        },
        getDataNumber: (insightData, dataPath) => {
          return dataPath === "focus"
            ? insightData["centralized"]["potential"]
            : insightData["centralized"]["current"];
        },
        getSubtitle: (insightData, dataPath) => {
          return dataPath === "focus" ? "Potential" : "Current";
        },
        getHoverTitle: (insightData) => {
          const groupName = Tree.get("activeGroup", "name");
          return `${groupName}<br>Centralized sBMPs in Scenarios`;
        },
        getHoverDescription: (insightData) => {
          return "";
        },
        getHoverData: (insightData) => {
          return insightData["centralized"];
        },
      });

      var widgetDbmpCount = new InsightWidgetSimpleNumberScenario({
        size: 1,
        title: "Decentralized sBMPs in Scenarios",
        getDataUnit: () => {
          return "";
        },
        focusOnBottom: () => {
          return true;
        },
        getDataNumber: (insightData, dataPath) => {
          return dataPath === "focus"
            ? insightData["decentralized"]["potential"]
            : insightData["decentralized"]["current"];
        },
        getSubtitle: (insightData, dataPath) => {
          return dataPath === "focus" ? "Potential" : "Current";
        },
        getHoverTitle: (insightData) => {
          const groupName = Tree.get("activeGroup", "name");
          return `${groupName}<br>Decentralized sBMPs in Scenarios`;
        },
        getHoverDescription: (insightData) => {
          return "";
        },
        getHoverData: (insightData) => {
          return insightData["decentralized"];
        },
      });

      var widgetStorageCapacity = new InsightWidgetGaugeChart({
        size: 2,
        title: "Capacity Progress",
        legendData: {
          current: {
            label: "Current",
            color: "#838383",
          },
          potential: {
            label: "Potential",
            color: "#383838",
          },
          goal: {
            label: "Goal",
            color: "#D9D9D9",
          },
        },
        getDefaultCenterData: (insightData) => {
          const data = insightData.capacityProgress;
          return {
            unit: "ac-ft",
            label: "Current",
            value: data.current,
          };
        },
        getDataArray: (insightData) => {
          const data = insightData.capacityProgress,
            current = data.current,
            potential = data.potential,
            goal = Session.isUlar() ? null : data.goal,
            dataArray = [
              {
                key: "current",
                value: current,
                arrowLabel: goal
                  ? InsightWidgetFunctions.formatPercentage(current, goal)
                  : InsightWidgetFunctions.formatNumber(current),
                hover: true,
              },
              {
                key: "potential",
                value: potential,
                arrowLabel: goal
                  ? InsightWidgetFunctions.formatPercentage(potential, goal)
                  : InsightWidgetFunctions.formatNumber(potential),
                hover: true,
              },
            ];
          if (goal) {
            dataArray.push({
              key: "goal",
              value: goal,
              arrowLabel: "Goal",
              hover: true,
            });
          }
          return dataArray;
        },
      });

      var widgetRunoffVolumeReduction = new InsightWidgetReductionBar({
        size: 2,
        title: "Runoff Volume Reduction",
        unit: "afy",
        getDataArray: (insightData) => {
          const data = insightData.runoffVolumeReduction;
          return {
            baselineValue: data.baseline,
            goal: data.goal,
            bars: [
              {
                label: "Current",
                value: data.current,
                reduction: data.currentReduction,
                hoverKey: "current",
                color: colors.runoff.currentReduction,
              },
              {
                label: "Potential",
                value: data.potential,
                reduction: data.potentialReduction,
                hoverKey: "potential",
                color: colors.runoff.potentialReduction,
              },
            ],
          };
        },
        getHoverData: (insightData) => {
          const data = insightData.runoffVolumeReduction;
          const groupName = Tree.get("activeGroup", "name");
          return {
            current: {
              template: "insight/widgets/currentRunoffReductionHover.njk",
              title: `${groupName}<br>Current Runoff Volume Reduction`,
              baseline: data.baseline,
              baselineRatio: data.baselineRatio,
              current: data.current,
              currentRatio: data.currentRatio,
              currentReduction: data.currentReduction,
              currentRatioReduction: data.currentRatioReduction,
              currentReductionPercent: data.currentReductionPercent,
            },
            potential: {
              template: "insight/widgets/potentialRunoffReductionHover.njk",
              title: `${groupName}<br>Potential Runoff Volume Reduction`,
              baseline: data.baseline,
              baselineRatio: data.baselineRatio,
              potential: data.potential,
              potentialRatio: data.potentialRatio,
              potentialReduction: data.potentialReduction,
              potentialRatioReduction: data.potentialRatioReduction,
              potentialReductionPercent: data.potentialReductionPercent,
            },
          };
        },
        getGoalHoverData: (insightData) => {
          const data = insightData.runoffVolumeReduction;
          const spatial = Tree.get("mapTitle");

          return {
            title: `Runoff Volume Reduction Goal - <br> ${spatial}`,
            goalLabel: "Runoff Volume Goal (afy)",
            reductionLabel: "Reduction from Baseline (afy)",
            goal: data.goal,
            goalReduction: data.baseline - data.goal,
          };
        },
      });

      var widgetParticulateLoadReduction = new InsightWidgetReductionBar({
        size: 2,
        title: "Particulate Load Reduction",
        unit: "ton/yr",
        getDataArray: (insightData) => {
          const data = insightData.particulateLoadReduction;
          return {
            baselineValue: data.baseline,
            goal: data.goal,
            bars: [
              {
                label: "Current",
                value: data.current,
                reduction: data.currentReduction,
                hoverKey: "current",
                color: colors.particulate.currentReduction,
              },
              {
                label: "Potential",
                value: data.potential,
                reduction: data.potentialReduction,
                hoverKey: "potential",
                color: colors.particulate.potentialReduction,
              },
            ],
          };
        },
        getHoverData: (insightData) => {
          const data = insightData.particulateLoadReduction;
          const groupName = Tree.get("activeGroup", "name");
          return {
            current: {
              template: "insight/widgets/currentParticulateReductionHover.njk",
              title: `${groupName}<br>Current Particulate Load Reduction`,
              baseline: data.baseline,
              baselineRatio: data.baselineRatio,
              current: data.current,
              currentRatio: data.currentRatio,
              currentReduction: data.currentReduction,
              currentRatioReduction: data.currentRatioReduction,
              currentReductionPercent: data.currentReductionPercent,
            },
            potential: {
              template: "insight/widgets/potentialParticulateReductionHover.njk",
              title: `${groupName}<br>Potential Particulate Load Reduction`,
              baseline: data.baseline,
              baselineRatio: data.baselineRatio,
              potential: data.potential,
              potentialRatio: data.potentialRatio,
              potentialReduction: data.potentialReduction,
              potentialRatioReduction: data.potentialRatioReduction,
              potentialReductionPercent: data.potentialReductionPercent,
            },
          };
        },
        getGoalHoverData: (insightData) => {
          const data = insightData.particulateLoadReduction;
          const spatial = Tree.get("mapTitle");

          return {
            title: `Particulate Load Reduction Goal - <br> ${spatial}`,
            goalLabel: "Particulate Load Goal (ton/yr)",
            reductionLabel: "Reduction from Baseline (ton/yr)",
            goal: data.goal,
            goalReduction: data.baseline - data.goal,
          };
        },
      });

      var widgetZincLoadReduction = new InsightWidgetReductionBar({
        isHidden: (insightData) => !insightData.zincLoadReduction,
        size: 2,
        title: "Zinc Load Reduction",
        unit: "lb/yr",
        getDataArray: (insightData) => {
          const data = insightData.zincLoadReduction;
          return {
            baselineValue: data.baseline,
            goal: data.goal,
            bars: [
              {
                label: "Current",
                value: data.current,
                reduction: data.currentReduction,
                hoverKey: "current",
                color: colors.zinc.currentReduction,
              },
              {
                label: "Potential",
                value: data.potential,
                reduction: data.potentialReduction,
                hoverKey: "potential",
                color: colors.zinc.potentialReduction,
              },
            ],
          };
        },
        getHoverData: (insightData) => {
          const data = insightData.zincLoadReduction;
          const groupName = Tree.get("activeGroup", "name");
          return {
            current: {
              template: "insight/widgets/currentPollutantReductionHover.njk",
              title: `${groupName}<br>Current Zinc Load Reduction`,
              scenarioTitle: "Zinc Load (lb/yr)",
              baseline: data.baseline,
              baselineRatio: data.baselineRatio,
              current: data.current,
              currentRatio: data.currentRatio,
              currentReduction: data.currentReduction,
              currentRatioReduction: data.currentRatioReduction,
              currentReductionPercent: data.currentReductionPercent,
            },
            potential: {
              template: "insight/widgets/potentialPollutantReductionHover.njk",
              title: `${groupName}<br>Potential Zinc Load Reduction`,
              scenarioTitle: "Zinc Load (lb/yr)",
              baseline: data.baseline,
              baselineRatio: data.baselineRatio,
              potential: data.potential,
              potentialRatio: data.potentialRatio,
              potentialReduction: data.potentialReduction,
              potentialRatioReduction: data.potentialRatioReduction,
              potentialReductionPercent: data.potentialReductionPercent,
            },
          };
        },
        getGoalHoverData: (insightData) => {
          const data = insightData.zincLoadReduction;
          const spatial = Tree.get("mapTitle");

          return {
            title: `Zinc Load Reduction Goal - <br> ${spatial}`,
            goalLabel: "Zinc Load Goal (lb/yr)",
            reductionLabel: "Reduction from Baseline (lb/yr)",
            goal: data.goal,
            goalReduction: data.baseline - data.goal,
          };
        },
      });

      var widgetRunoffEjReduction = new InsightWidgetTwoDonuts({
        isHidden: (insightData) =>
          insightData.runoffVolumeReduction?.currentEjReduction === null &&
          insightData.runoffVolumeReduction?.potentialEjReduction === null,
        size: 2,
        title: "Volume Reductions within Environmental Justice Communities",
        unit: "afy",
        legendData: () => {
          return {
            current: {
              label: "",
              color: colors.runoff.currentReduction,
            },
            potential: {
              label: "",
              color: colors.runoff.potentialReduction,
            },
          };
        },
        getChartData: (insightData) => {
          const data = insightData.runoffVolumeReduction;
          return [
            {
              key: "current",
              hoverKey: "current",
              label: "Current",
              value: data.currentEjReduction,
              total: data.currentReduction,
            },
            {
              key: "potential",
              hoverKey: "potential",
              label: "Potential",
              value: data.potentialEjReduction,
              total: data.potentialReduction,
            },
          ];
        },
        getHoverData: (insightData) => {
          const data = insightData.runoffVolumeReduction;
          const template = "insight/widgets/ejReductionHover.njk";

          return {
            current: {
              template,
              title: `Runoff Volume Reduction within <br>EJ Communities - Current Scenario`,
              value: data.currentEjReduction,
              valueLabel: "EJ Volume Reduction",
              total: data.currentReduction,
              totalLabel: "Total Volume Reduction",
            },
            potential: {
              template,
              title: `Runoff Volume Reduction within <br>EJ Communities - Potential Scenario`,
              value: data.potentialEjReduction,
              valueLabel: "EJ Volume Reduction",
              total: data.potentialReduction,
              totalLabel: "Total Volume Reduction",
            },
          };
        },
      });

      var widgetParticulateEjReduction = new InsightWidgetTwoDonuts({
        isHidden: (insightData) =>
          insightData.particulateLoadReduction?.currentEjReduction === null &&
          insightData.particulateLoadReduction?.potentialEjReduction === null,
        size: 2,
        title: "Particulate Reductions within Environmental Justice Communities",
        unit: "ton/yr",
        legendData: () => {
          return {
            current: {
              label: "",
              color: colors.particulate.currentReduction,
            },
            potential: {
              label: "",
              color: colors.particulate.potentialReduction,
            },
          };
        },
        getChartData: (insightData) => {
          const data = insightData.particulateLoadReduction;
          return [
            {
              key: "current",
              hoverKey: "current",
              label: "Current",
              value: data.currentEjReduction,
              total: data.currentReduction,
            },
            {
              key: "potential",
              hoverKey: "potential",
              label: "Potential",
              value: data.potentialEjReduction,
              total: data.potentialReduction,
            },
          ];
        },
        getHoverData: (insightData) => {
          const data = insightData.particulateLoadReduction;
          const template = "insight/widgets/ejReductionHover.njk";

          return {
            current: {
              template,
              title: `Particulate Load Reduction within <br>EJ Communities - Current Scenario`,
              value: data.currentEjReduction,
              valueLabel: "EJ Load Reduction",
              total: data.currentReduction,
              totalLabel: "Total Load Reduction",
            },
            potential: {
              template,
              title: `Particulate Load Reduction within <br>EJ Communities - Potential Scenario`,
              value: data.potentialEjReduction,
              valueLabel: "EJ Load Reduction",
              total: data.potentialReduction,
              totalLabel: "Total Load Reduction",
            },
          };
        },
      });

      var widgetRunoffVolumeTreatmentNew = new InsightWidgetHorizontalBarChart({
        size: 2,
        title: "Treatment Contribution to Runoff Volume Reduction",
        unit: "afy",
        twoLineLabel: true,
        legendData: () => {
          return {
            current: {
              label: "Current",
              color: colors.runoff.currentReduction,
            },
            potential: {
              label: "Potential",
              color: colors.runoff.potentialReduction,
            },
          };
        },
        getChartData: (insightData) => {
          const data = insightData.runoffVolumeTreatment;
          return getTreatmentContributionWidgetChartData("runoff", data);
        },
        getHoverData: (insightData) => {
          const data = insightData.runoffVolumeTreatment;
          return getTreatmentContributionWidgetHoverData("runoff", data);
        },
      });

      var widgetParticulateTreatmentNew = new InsightWidgetHorizontalBarChart({
        size: 2,
        title: "Treatment Contribution to Particulate Load Reduction",
        unit: "ton/yr",
        twoLineLabel: true,
        legendData: () => {
          return {
            current: {
              label: "Current",
              color: colors.particulate.currentReduction,
            },
            potential: {
              label: "Potential",
              color: colors.particulate.potentialReduction,
            },
          };
        },
        getChartData: (insightData) => {
          const data = insightData.particulateLoadTreatment;
          return getTreatmentContributionWidgetChartData("particulate", data);
        },
        getHoverData: (insightData) => {
          const data = insightData.particulateLoadTreatment;
          return getTreatmentContributionWidgetHoverData("particulate", data);
        },
      });

      var widgetZincTreatmentNew = new InsightWidgetHorizontalBarChart({
        isHidden: (insightData) => !insightData.zincLoadTreatment,
        size: 2,
        title: "Treatment Contribution to Zinc Load Reduction",
        unit: "lb/yr",
        twoLineLabel: true,
        legendData: () => {
          return {
            current: {
              label: "Current",
              color: colors.zinc.currentReduction,
            },
            potential: {
              label: "Potential",
              color: colors.zinc.potentialReduction,
            },
          };
        },
        getChartData: (insightData) => {
          const data = insightData.zincLoadTreatment;
          return getTreatmentContributionWidgetChartData("zinc", data);
        },
        getHoverData: (insightData) => {
          const data = insightData.zincLoadTreatment;
          return getTreatmentContributionWidgetHoverData("zinc", data);
        },
      });

      var getTreatmentContributionWidgetChartData = function (type, data) {
        const chartData = [
          {
            key: "disconnection",
            displayName: "Runoff Disconnection",
            focusValue: data.currentParcelReduction,
            comparisonValue: data.potentialParcelReduction,
          },
          {
            key: "decentralized",
            displayName: "Decentralized sBMPs",
            focusValue: data.currentDecentralizedReduction,
            comparisonValue: data.potentialDecentralizedReduction,
          },
          {
            key: "centralized",
            displayName: "Centralized sBMPs",
            focusValue: data.currentCentralizedReduction,
            comparisonValue: data.potentialCentralizedReduction,
          },
        ];

        if (type !== "runoff") {
          chartData.splice(1, 0, {
            key: "streetSweeping",
            displayName: "Street Sweeping",
            focusValue: data.currentRoadReduction,
            comparisonValue: data.potentialRoadReduction,
          });
        }

        return chartData;
      };

      var getTreatmentContributionWidgetHoverData = function (type, data) {
        var currentTotal =
          data.currentParcelReduction +
          data.currentDecentralizedReduction +
          data.currentCentralizedReduction;

        var potentialTotal =
          data.potentialParcelReduction +
          data.potentialDecentralizedReduction +
          data.potentialCentralizedReduction;

        if (type !== "runoff") {
          currentTotal += data.currentRoadReduction;
          potentialTotal += data.potentialRoadReduction;
        }

        let title = "Treatment Contribution";
        if (type === "runoff") {
          title = "Treatment Contribution to Runoff Volume Reduction";
        } else if (type === "particulate") {
          title = "Treatment Contribution to Particulate Load Reduction";
        } else if (type === "zinc") {
          title = "Treatment Contribution to Zinc Load Reduction";
        }

        return {
          template: "insight/widgets/treatmentContributionHover.njk",
          title: title,
          current: {
            color: colors[type]?.currentReduction,
            disconnection: InsightWidgetFunctions.formatPercentage(
              data.currentParcelReduction,
              currentTotal,
            ),
            streetSweeping:
              type === "runoff"
                ? undefined
                : InsightWidgetFunctions.formatPercentage(data.currentRoadReduction, currentTotal),
            decentralized: InsightWidgetFunctions.formatPercentage(
              data.currentDecentralizedReduction,
              currentTotal,
            ),
            centralized: InsightWidgetFunctions.formatPercentage(
              data.currentCentralizedReduction,
              currentTotal,
            ),
          },
          potential: {
            color: colors[type]?.potentialReduction,
            disconnection: InsightWidgetFunctions.formatPercentage(
              data.potentialParcelReduction,
              potentialTotal,
            ),
            streetSweeping:
              type === "runoff"
                ? undefined
                : InsightWidgetFunctions.formatPercentage(
                    data.potentialRoadReduction,
                    potentialTotal,
                  ),
            decentralized: InsightWidgetFunctions.formatPercentage(
              data.potentialDecentralizedReduction,
              potentialTotal,
            ),
            centralized: InsightWidgetFunctions.formatPercentage(
              data.potentialCentralizedReduction,
              potentialTotal,
            ),
          },
        };
      };

      var config = {
        getInsightDataFunction: ApiCalls.getScenarioInsight,
        widgets: [
          widgetCbmpCount,
          widgetDbmpCount,
          widgetStorageCapacity,
          widgetRunoffVolumeReduction,
          widgetRunoffVolumeTreatmentNew,
          widgetParticulateLoadReduction,
          widgetParticulateTreatmentNew,
          widgetZincLoadReduction,
          widgetZincTreatmentNew,
          widgetRunoffEjReduction,
          widgetParticulateEjReduction,
        ],
        sideTable: true,
        hasHeading: true,
        getHeadingHtmlFunction,
      };

      controller = new InsightDashboardController(config);
      controller.init();
      inited = true;
      render();
    }

    renderIfFilterChanged();
    refetchLastTelrRun();
  };

  var getHeadingHtmlFunction = function () {
    const heading = Tree.get("mapTitle");
    return `<h4>${heading}</h4>`;
  };

  var loadDomListeners = function () {
    $insightContainer
      .off()
      .on("mouseenter", ".slice-hover", highlightPotentialOrGoal)
      .on("mouseleave", ".slice-hover", restoreCurrent);
  };

  var highlightPotentialOrGoal = function () {
    const $this = $(this),
      $chart = $this.closest(".chart-container"),
      i = $this.closest("[data-i]").data("i");
    $chart.find(".center-text-value .value").text($this.data("value"));
    $chart.find(".center-text-label").text($this.data("label"));
    $chart
      .find(`.slice[data-i="${i}"]`)
      .prevAll(".slice")
      .find("path")
      .css("fill", $this.data("color"))
      .css("stroke", $this.data("color"));
  };

  var restoreCurrent = function () {
    const $this = $(this),
      $chart = $this.closest(".chart-container"),
      $value = $chart.find(".center-text-value .value"),
      $label = $chart.find(".center-text-label");
    $value.text($value.data("default-value"));
    $label.text($label.data("default-label"));
    $chart.find(".slice [data-color]").each(function () {
      $(this).css("fill", $(this).data("color"));
      $(this).css("stroke", $(this).data("color"));
    });
  };

  var render = async function () {
    lastTelrRun = await ApiCalls.getLastTelrRun();
    await controller.render();
    handleActionButton();
    handleRunTelrDisplay();
  };

  var refetchLastTelrRun = async function () {
    lastTelrRun = await ApiCalls.getLastTelrRun();
    handleRunTelrDisplay();
  };

  var handleActionButton = function () {
    // @TODO - this is a hack to align the action button with the widgets
    // it is in the wrong place because widgets are hijacking list view
    function getRightDistance(selector) {
      var $element = $(selector);
      return $(window).width() - ($element.offset().left + $element.width());
    }
    function moveActionButton() {
      $(".action-button-div").css({
        position: "absolute",
        right: getRightDistance(".dashboard-container") - 2,
      });
    }
    moveActionButton();
    $(window).off("resize", moveActionButton).on("resize", moveActionButton);
  };

  var handleRunTelrDisplay = function () {
    $(".simple-number-container, .gauge-chart-container")
      .find(".widget-message")
      .text(`Data updated: Live`)
      .show();

    if (lastTelrRun?.widgetDisplayCompletedAt) {
      $(".reduction-bar-container, .two-pies-container, .horizontal-bar-chart-container")
        .find(".widget-message")
        .text(`Data updated: ${lastTelrRun.widgetDisplayCompletedAt}`)
        .show();
    }
  };

  var onClickRunTelrButton = function () {
    let messageHtml = "";

    if (lastTelrRun?.widgetDisplayCompletedAt) {
      messageHtml += `
          <p>
            The model was last run on ${lastTelrRun.widgetDisplayCompletedAt}
          </p>
        `;
    }
    messageHtml += `
        <p>
          The model may take up to 30 minutes to run, and you will receive an email when the analysis is complete. Are you sure you want to run TELR for the current and potential scenarios?
        </p>
      `;

    MessageModal.showConfirmWarningModal(
      "",
      runTelr,
      "Back",
      "Run TELR",
      messageHtml,
      null,
      "Run TELR",
    );
  };

  var runTelr = async function () {
    let title = "In Progress",
      message,
      user_full_name = Tree.get("user", "full_name"),
      year = Tree.get("waterYear"),
      response;
    try {
      response = await ApiCalls.runTelr(year);
    } catch (e) {
      // api error
    }

    switch (response?.status) {
      case "started":
        message = `Thank you ${user_full_name}. Your request to run TELR has been submitted. You will receive an email when the analysis is complete and your results are available to view.`;
        handleRunTelrDisplay();
        break;
      case "already_running":
        message = `Thank you ${user_full_name}. A previously submitted request is still in the process of being run. You will receive an email when the analysis is complete and your results are available to view.`;
        handleRunTelrDisplay();
        break;
      default:
        message = "There was an error submitting your request. Please try again later.";
        title = "Error";
    }
    MessageModal.showWarningModal(message, title);
  };

  var loadTreeListeners = function (map, mapLayers, mapId) {
    Tree.select("filters").on("update", function () {
      if (Tree.get("activeTab") !== "insight") {
        filterChanged = true;
      } else {
        render();
      }
    });
  };

  var renderIfFilterChanged = function () {
    if (filterChanged) {
      render();
      filterChanged = false;
    }
  };

  return {
    init,
    onClickRunTelrButton,
    runTelr,
  };
};

module.exports = ScenarioInsightDashboardController();

const ApiCalls = require("../apiCalls");
const InsightWidgetFunctions = require("../general/widgets/insightWidgetFunctions");
const InsightDashboardController = require("../general/widgets/insightDashboardController");
const InsightWidgetReductionBar = require("../general/widgets/insightWidgetReductionBar");
const InsightWidgetSimpleNumberScenario = require("../general/widgets/insightWidgetSimpleNumberScenario");
const InsightWidgetGaugeChart = require("../general/widgets/insightWidgetGaugeChart");
const InsightWidgetTwoDonuts = require("../general/widgets/insightWidgetTwoDonuts");
const InsightWidgetHorizontalBarChart = require("../general/widgets/insightWidgetHorizontalBarChart");
const Tree = require("../tree");
const MessageModal = require("../modals/messageModal");
const Session = require("../login/session");
