"use strict";

const LidProjectFactSheetController = require("../../lid/lidProjectFactSheetController");

const LidRoutes = function () {
  const lidProjectFactSheetRoute = {
    as: LidProjectFactSheetController.routeName,
    handler: function ({ data, params }) {
      LidProjectFactSheetController.open(data.id);
    },
  };

  return {
    lidProjectFactSheetRoute,
  };
};

module.exports = LidRoutes();
