"use strict";

const ReportingTimeline = function () {
  const QUARTER_WIDTH = 25; //width percentage of a single 'quarter' default to 25
  const DAY_MILLISECONDS = 86400000; //milliseconds in a day for converting epoch
  const DAYS_IN_MONTH = 30;
  const MONTHS_IN_QUARTER = 3;
  const INDEX_OFFSET = 1; //offset for date month object
  const MARKER_MARGIN_OFFSET = "-4px";

  var render = function (trashReportingSettings) {
    const timelineStartDate = getTimelineStartDate(
      trashReportingSettings.due_date || trashReportingSettings.deadline,
    );
    shiftLabels(
      timelineStartDate,
      trashReportingSettings.reportingYear || trashReportingSettings.year,
    );
    setTodayMarker();
    setDueMarker(trashReportingSettings);
  };

  var getTimelineStartDate = function (dueDate) {
    const today = new Date();
    dueDate = new Date(dueDate);
    return new Date(Math.min(today, dueDate));
  };

  var shiftLabels = function (earliestDate, reportingYear) {
    var month = earliestDate.getMonth();
    var monthsLabelArray = $(".month-label").toArray();
    var quarterLabelArray = $(".quarter-label").toArray();
    var quarterMonthIndex = Math.floor(month / MONTHS_IN_QUARTER) * MONTHS_IN_QUARTER;
    var quarterIndex = Math.floor(month / MONTHS_IN_QUARTER);
    //@TODO refactor this, got out of control afer new requests - DP
    if (quarterLabelArray[quarterIndex].textContent == "Q" + (quarterIndex + 1)) {
      var shiftedMonthLabelArray = monthsLabelArray.slice(quarterMonthIndex);
      var shiftedQuarterLabelArray = quarterLabelArray.slice(quarterIndex);
      shiftedMonthLabelArray = shiftedMonthLabelArray.concat(
        monthsLabelArray.slice(0, quarterMonthIndex),
      );
      shiftedQuarterLabelArray = shiftedQuarterLabelArray.concat(
        quarterLabelArray.slice(0, quarterIndex),
      );
      if (shiftedQuarterLabelArray[0].textContent == "Q1") {
        const trashYear = reportingYear;
        for (const i in shiftedQuarterLabelArray) {
          const label = shiftedQuarterLabelArray[i].textContent;
          shiftedQuarterLabelArray[i].textContent = label + " " + trashYear;
        }
      } else {
        const trashYear = reportingYear;
        const nextYear = parseInt(trashYear) + 1;
        let newYear = false;
        for (const i in shiftedQuarterLabelArray) {
          const label = shiftedQuarterLabelArray[i].textContent;
          if (label == "Q1" || newYear) {
            newYear = true;
            shiftedQuarterLabelArray[i].textContent = label + " " + nextYear;
          } else {
            shiftedQuarterLabelArray[i].textContent = label + " " + trashYear;
          }
        }
      }

      $(".month-div").empty();
      $(".quarter-div").empty();
      $(".month-div").append(shiftedMonthLabelArray);
      $(".quarter-div").append(shiftedQuarterLabelArray);
    }
  };

  var setTodayMarker = function () {
    const today = new Date();
    const month = today.getMonth();
    const date = today.getDate();
    const quarter = Math.ceil((month + INDEX_OFFSET) / MONTHS_IN_QUARTER);
    const year = today.getFullYear();
    const quarterWidth = QUARTER_WIDTH;
    const quarterPercentage = month % MONTHS_IN_QUARTER;
    const dayPercentage = date / DAYS_IN_MONTH;
    const quarterOffset = $(".quarter-label").map(function (index) {
      if (this.innerText.split(" ")[0] == "Q" + quarter && this.innerText.split(" ")[1] == year) {
        return index;
      }
    });

    //if due date is out of range, don't display
    if (!quarterOffset.length) {
      $(".arrow-down").hide();
      $(".arrow-tick").hide();
    } else {
      const marginPercentage =
        quarterOffset[0] * QUARTER_WIDTH +
        ((quarterPercentage + dayPercentage) / MONTHS_IN_QUARTER) * quarterWidth;

      $(".arrow-down").css(
        "margin-left",
        "calc(" + marginPercentage + "% + " + MARKER_MARGIN_OFFSET + ")",
      );
      $(".arrow-tick").css("margin-left", marginPercentage + "%");
    }
  };

  var setDueMarker = function (reportRecord) {
    if (reportRecord) {
      var dueDate = new Date(reportRecord.due_date || reportRecord.deadline);
      var dueMonth = dueDate.getMonth();
      var dueYear = dueDate.getFullYear();
      var dueQuarter = Math.ceil((dueMonth + INDEX_OFFSET) / MONTHS_IN_QUARTER);
      var quarterPercentage = dueMonth % MONTHS_IN_QUARTER;
      var dayPercentage = dueDate.getDate() / DAYS_IN_MONTH;
      var quarterOffset = $(".quarter-label").map(function (index) {
        if (
          this.innerText.split(" ")[0] == "Q" + dueQuarter &&
          this.innerText.split(" ")[1] == dueYear
        ) {
          return index;
        }
      });
      //if due date is out of range, don't display
      if (!quarterOffset.length) {
        $(".arrow-down-due").hide();
      } else {
        var dueMarginPercentage =
          quarterOffset[0] * QUARTER_WIDTH +
          ((quarterPercentage + dayPercentage) / MONTHS_IN_QUARTER) * QUARTER_WIDTH; //current quarter width is 20%
        $(".arrow-down-due").css(
          "margin-left",
          "calc(" + dueMarginPercentage + "% + " + MARKER_MARGIN_OFFSET + ")",
        );
        $(".arrow-tick-due").css("margin-left", dueMarginPercentage + "%");
      }
      var dayCount = calcDaysToGo(dueDate);
      if (reportRecord.submit_date || reportRecord.submittedOn) {
        $(".days-to-go")
          .text(
            "Submitted on " +
              DateTime.formatDateStringAsDisplayDate(
                reportRecord.submit_date || reportRecord.submittedOn,
              ),
          )
          .css("color", "#8c8c8c");
      } else {
        setDaysToGo(dayCount);
      }
    }
  };

  var calcDaysToGo = function (dueDate) {
    var today = new Date();
    dueDate = new Date(dueDate);
    return Math.ceil((dueDate.valueOf() - today.valueOf()) / DAY_MILLISECONDS);
  };

  var setDaysToGo = function (dayCount) {
    dayCount = parseInt(dayCount);
    if (dayCount < DAYS_IN_MONTH) {
      if (dayCount < 0) {
        $(".days-to-go").css("color", "red");
        $(".days-to-go").text(Math.abs(dayCount) + " Days Past Due");
      } else {
        $(".days-to-go").css("color", "#fe5a00");
        $(".days-to-go").text(dayCount + " Days to Go");
      }
    } else {
      $(".days-to-go").css("color", "#8c8c8c");
      $(".days-to-go").text(dayCount + " Days to Go");
    }
  };

  var show = function (callback) {
    $(".reporting-timeline").slideDown(300, callback);
  };

  var hide = function (callback) {
    $(".reporting-timeline").slideUp(300, callback);
  };

  return {
    render,
    show,
    hide,
  };
};

module.exports = ReportingTimeline();

const DateTime = require("./dateTime");
