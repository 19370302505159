"use strict";

var ConstructionProjectProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "constructionProjectProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (
            mapLayers.constructionProjectProgressLayer &&
            sameSpatialFilter &&
            sameView &&
            sameGroup
          ) {
            map.addLayer(mapLayers.constructionProjectProgressLayer);
            Clustering.setState("constructionProjectProgress");
          } else {
            const isFetching = Tree.get([
              "layers",
              Tree.get("mapId"),
              "constructionProjectProgress",
              "isFetching",
            ]);
            if (sameSpatialFilter && sameView && isFetching) {
              // Temp solution to prevent laoding twice from dataView and filters update
              return;
            }
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.constructionProjectProgressLayer)) {
            map.removeLayer(mapLayers.constructionProjectProgressLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");
    if (!filters.catchments.length && filters.searchString) {
      Tree.set(["layers", mapId, "constructionProjectProgress", "data"], []);
    } else {
      Tree.set(["layers", mapId, "constructionProjectProgress", "isFetching"], true);
      ReportApiCalls.getConstructionProjectProgress(filters, function (data) {
        Tree.set(["layers", mapId, "constructionProjectProgress", "isFetching"], false);
        Tree.set(["layers", mapId, "constructionProjectProgress", "data"], data);
        Tree.set(
          ["layers", mapId, "constructionProjectProgress", "spatialView"],
          Tree.get("spatialView", mapId),
        );
      });
    }
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "constructionProjectProgress", function (data) {
      if (Filters.spatialFilterIsSet()) {
        data = spatiallyFilterConstructionProjectData(data);
      }
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.constructionProjectProgressLayer = createLayerAndAddToMap(
        map,
        mapLayers,
        geoJsonData,
      );
      Clustering.setState("constructionProjectProgress");
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.constructionProjectProgressLayer) {
      map.removeLayer(mapLayers.constructionProjectProgressLayer);
    }
    mapLayers.constructionProjectProgressLayer = createLayer(data);
    ConstructionProjectPopup.loadDomListeners();
    mapLayers.constructionProjectProgressLayer.addTo(map);
    return mapLayers.constructionProjectProgressLayer;
  };

  var createLayer = function (data) {
    var layer = L.featureGroup();

    L.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        const constructionProjectData = feature.properties;
        const marker = L.marker(latlng, {
          icon: ConstructionIcon.getConstructionProjectIconMarker(constructionProjectData),
        });
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.on({
          click: (e) => {
            ConstructionProjectPopup.getConstructionProjectPopupHtml(feature.properties).then(
              (html) =>
                layer.bindPopup(html, ConstructionProjectPopup.getPopupOptions()).openPopup(),
            );
          },
        });
      },
    }).addTo(layer);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Only watch for specific filters since other filters updates are already handled in treeUpdates
    // @TODO: Investigate why listening to filters update causes an infinite loop while searching
    Tree.watch({
      ownership: ["filters", "ownership"],
      inspectionType: ["filters", "inspectionType"],
      priority: ["filters", "priority"],
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        Tree.get("dataView") === "constructionView" &&
        MapFunctions.mapHasLayer(map, mapLayers.constructionProjectProgressLayer) &&
        Tree.get("layers", mapId, "constructionProjectProgress", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var spatiallyFilterConstructionProjectData = function (projectData) {
    const filters = Tree.get("filters");
    return projectData.filter((datum) => {
      return (
        filters.receivingWaters.includes(datum.catchment["drains_to_rw"]) ||
        filters.receivingWaters.includes(datum.catchment["drains_to_c"]) ||
        filters.catchments.includes(datum.catchment["catchid"])
      );
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    createLayer,
  };
};

module.exports = ConstructionProjectProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Table = require("../mapFunctions/table");
const Filters = require("../mapFunctions/filters");
const Clustering = require("../mapFunctions/clustering");
const ConstructionIcon = require("./constructionIcon");
const ConstructionProjectPopup = require("./constructionProjectPopup");
