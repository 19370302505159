"use strict";

const IddeManholeEsriDynamicMapLayer = function () {
  const layerName = "iddeManholeEsriDynamicMap";
  const layerKey = "iddeManholeEsriDynamicMapLayer";
  const esriKey = "parentView";
  const esriLayer = "manhole";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var iddeManholeEsriDynamicMapLayer = new EsriDynamicMapLayer(
      layerName,
      layerKey,
      esriKey,
      esriLayer,
    );
    iddeManholeEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    iddeManholeEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = IddeManholeEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
