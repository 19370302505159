"use strict";

var PlanMap = function () {
  var planMap = null;
  var mapLayers = null;

  var loadPlanMap = function () {
    const activeGroupId = Tree.get("activeGroup", "groupId");
    const lastGroupId = Tree.get("lastGroupId", "plan");
    var firstLoad = false;

    initLayers();

    if (!planMap) {
      firstLoad = true;
      TreeUpdates.turnOnUpdates(mapLayers, getActivePlanMap, getCurrentPlanBasemap, "plan");

      planMap = initPlanMap();

      DisplayOptions.startLegendListeners("plan", mapLayers);
      loadLayerListenersForPlanMap();
    }

    if (firstLoad || activeGroupId !== lastGroupId) {
      if (Progeny.activeHasProgeny()) {
        loadPlanRegulatorMap();
      } else {
        loadPlanMS4Map();
      }
    }

    reloadListeners();
    CommonMapFunctions.afterMapLoad();
  };

  var initLayers = function () {
    if (!mapLayers) {
      mapLayers = LayerFunctions.getAllLayersAsMapLayers();
    }
  };

  var loadLayerListenersForPlanMap = function () {
    // @TODO: Set up config to load layer listneres dynamically
    const FlowRoutingLayer = require("../outfalls/flowRoutingLayer");
    FlowRoutingLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");

    const OutfallsSimpleLayer = require("../outfalls/outfallsSimpleLayer");
    OutfallsSimpleLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");

    const RunoffOpportunityLayer = require("../telr/runoffOpportunityLayer");
    RunoffOpportunityLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");

    const ParticulatesOpportunityLayer = require("../telr/particulatesOpportunityLayer");
    ParticulatesOpportunityLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");

    const RunoffRatioOpportunityLayer = require("../telr/runoffRatioOpportunityLayer");
    RunoffRatioOpportunityLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");

    const StreamsEsriDynamicMapLayer = require("../general/streamsEsriDynamicMapLayer");
    StreamsEsriDynamicMapLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");

    const LitterHotspotEsriDynamicMapLayer = require("../trash/litterHotspotEsriDynamicMapLayer");
    LitterHotspotEsriDynamicMapLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");

    const ParcelsPluEsriDynamicMapLayer = require("../general/parcelsPluEsriDynamicMapLayer");
    ParcelsPluEsriDynamicMapLayer.loadLayerListenersForMap(planMap, mapLayers, "plan");
  };

  var loadPlanMS4Map = function () {
    const annualReportingCallback = function () {
      initializePlanLayers();
    };
    if (Tree.get("annualReportingLoaded")) {
      annualReportingCallback();
    } else {
      Actions.loadAnnualReporting(annualReportingCallback);
    }

    CommonMapFunctions.afterMS4MapLoad(planMap);
  };

  var loadPlanRegulatorMap = function () {
    Ms4Table.loadListeners();
    RegionAreaLayer.loadLayer();
  };

  var reloadListeners = function () {
    Table.loadListeners("plan");
    DisplayOptions.startLegendListeners("plan", mapLayers);
    CatchmentView.startListeners();

    if (Progeny.activeHasProgeny()) {
      Ms4Table.loadListeners();
    }
  };

  var initializePlanLayers = function () {
    const autoLoadLayers = ["catchments", "ms4Boundary"];
    autoLoadLayers.forEach((layerName) => {
      loadLayer(layerName);
    });
  };

  var loadLayer = function (layerName) {
    switch (layerName) {
      case "catchments":
        Tree.set(["extentInitialized", "plan"], false);
        Tree.set(["layers", "plan", "catchments", "isEnabled"], true);
        break;
      case "ms4Boundary":
        Tree.set(["layers", "plan", "ms4Boundary", "isEnabled"], true);
        break;
    }
  };

  var initPlanMap = function () {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const maxZoom = rasterBasemap ? 19 : 21;
    planMap = L.map("planMap", {
      zoomControl: false,
      tileLayer: {
        maxNativeZoom: maxZoom,
      },
      minZoom: 3,
      maxZoom: maxZoom,
    });
    CommonMapFunctions.setMapHeight("planMap", "plan");
    mapLayers.basemap = MapFunctions.addBasemap(planMap, mapLayers.basemap, "Gray");

    BasemapFunctions.setBasemap("Gray", "plan");

    L.control
      .scale({
        position: "bottomright",
        metric: false,
      })
      .addTo(planMap);
    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(planMap);
    planMap.setView(Tree.get("activeGroup", "latlng"), 14);
    startMapListener(planMap);
    MapPaneFunctions.createCustomMapPanes(planMap);

    return planMap;
  };

  var getActivePlanMap = function () {
    return planMap;
  };

  var getActiveLayers = function () {
    initLayers();
    return mapLayers;
  };

  var getCurrentPlanBasemap = function () {
    return mapLayers.basemap;
  };

  // @TODO: remove startMapListener, disableNoZoomLayers, end nableNoZoomLayers when switching to vector basemaps
  // note: these functions are duplicated in 3 other places
  var startMapListener = function (planMap) {
    planMap.on("zoomend", function () {
      const rasterBasemap = FeatureFlag.enabled("raster-basemap");
      if (rasterBasemap) {
        const zoom = planMap.getZoom();
        const currentBasemap = Tree.get("basemap", "plan");

        if (zoom > 16) {
          disableNoZoomLayers();
          if (
            currentBasemap !== "DarkGray" &&
            currentBasemap !== "Gray" &&
            currentBasemap !== "NationalGeographic"
          )
            return;
          Tree.set(["basemapBeforeZoomMap", "plan"], currentBasemap);
          BasemapFunctions.setBasemap("Imagery", "plan");
        } else {
          enableNoZoomLayers();
          const basemapBeforeZoom = Tree.get("basemapBeforeZoomMap", "plan");
          if (basemapBeforeZoom) {
            BasemapFunctions.setBasemap(basemapBeforeZoom, "plan");
          }
          Tree.set(["basemapBeforeZoomMap", "plan"], "");
        }
      }
    });
  };

  var disableNoZoomLayers = function () {
    $("#plan").find("[data-style='Gray']").addClass("disabled");
    $("#plan").find("[data-style='DarkGray']").addClass("disabled");
    $("#plan").find("[data-style='NationalGeographic']").addClass("disabled");
  };

  var enableNoZoomLayers = function () {
    $("#plan").find("[data-style='Gray']").removeClass("disabled");
    $("#plan").find("[data-style='DarkGray']").removeClass("disabled");
    $("#plan").find("[data-style='NationalGeographic']").removeClass("disabled");
  };

  return {
    loadPlanMap,
    loadPlanMS4Map,
    loadPlanRegulatorMap,
    getActivePlanMap,
    getActiveLayers,
  };
};

module.exports = PlanMap();

const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
const Tree = require("../../tree");
const TreeUpdates = require("../mapFunctions/treeUpdates");
const Table = require("../mapFunctions/table");
const RegionAreaLayer = require("../general/regionAreaLayer");
const Actions = require("../actions");
const DisplayOptions = require("../mapFunctions/displayOptions");
const CatchmentView = require("../mapFunctions/catchmentView");
const BasemapFunctions = require("../mapFunctions/basemapFunctions");
const Ms4Table = require("../ms4Table");
const Progeny = require("../../login/progeny");
const LayerFunctions = require("../mapFunctions/layerFunctions");
const MapPaneFunctions = require("../mapFunctions/mapPaneFunctions");
const MapFunctions = require("../mapFunctions/mapFunctions");
const FeatureFlag = require("../../settings/featureFlag");
