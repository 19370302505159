"use strict";

const DrawController = function () {
  var drawControl = null;
  var drawnItems = null;
  var modalMap = null;
  var polygonSelect = null;
  var drainageDrawControl = null;
  var methodSelect = null;
  var hasChanges = false;
  var drawnPolygonStyle = null;

  var init = function (map, polyline = true, enableMethodSelection = false) {
    modalMap = map;
    setDrawnPolygonStyle(drawnPolygonStyle);
    loadDrainageDrawLayer();
    addSelectionControls(polyline);
    if (enableMethodSelection) {
      addDrainageDrawControls();
      addMethodSelectionControls();
    }

    hideDrawControl();
    loadDrawListeners(map);
  };

  var setDrawnPolygonStyle = function (style) {
    drawnPolygonStyle = style || MapStyles.defaultDrawnPolygonStyle;
  };

  var addDrainageDrawControls = function () {
    if (modalMap.drainageDrawControls) {
      modalMap.removeControl(drainageDrawControl);
    }
    drainageDrawControl = new L.Control.DrainageDrawControls(drawnItems, drawControl);
    modalMap.addControl(drainageDrawControl);
  };

  var addSelectionControls = function (polyline) {
    var drawOptions = getDrawOptions(drawnItems, polyline);

    if (drawControl !== null) {
      modalMap.removeControl(drawControl);
    }
    drawControl = new L.Control.Draw(drawOptions);
    modalMap.addControl(drawControl);

    if (!modalMap.polygonSelect) {
      polygonSelect = new L.Control.PolygonSelect();
      modalMap.addControl(polygonSelect);
    }
    $(".polygon-select.leaflet-control").insertAfter($(".leaflet-draw.leaflet-control"));
  };

  var getDrawOptions = function (featureGroup, polyline) {
    var shapeOptions = {
      color: "#ffd700",
    };
    var customIcon = new L.DivIcon({
      iconSize: new L.Point(15, 15),
      className: "leaflet-div-icon custom-div-icon",
    });

    return {
      position: "topright",
      draw: {
        polyline: polyline
          ? {
              shapeOptions: shapeOptions,
              icon: customIcon,
              metric: false, // Whether to use the metric measurement system or imperial
              feet: true, // When not metric, use feet instead of yards for display
            }
          : false,
        polygon: {
          allowIntersection: false,
          drawError: {
            color: "#dc3545",
            message: "Please select a new location",
          },
          shapeOptions: shapeOptions,
          icon: customIcon,
        },
        rectangle: false,
        circle: false,
        circlemarker: false,
        marker: false,
      },
      edit: {
        featureGroup: featureGroup,
        edit: false,
        remove: false,
      },
    };
  };

  var loadDrawListeners = function (map) {
    map.off("draw:drawstart");
    map.on("draw:drawstart", handleDrawStarted);
    map.off("draw:drawstop");
    map.on("draw:drawstop", handleDrawStopped);
    //map.off("draw:drawvertex", validateBoundaries);
    //map.on("draw:drawvertex", validateBoundaries);
  };

  var handleDrawStarted = function (e) {
    var layerType = e.layerType;
    hasChanges = true;
    $(".leaflet-draw")
      .find(".leaflet-draw-draw-" + layerType)
      .addClass("draw-selected");
    ModalMap.closePopups();
  };

  var handleDrawStopped = function () {
    $(".leaflet-draw").find(".draw-selected").removeClass("draw-selected");
  };

  var showDrawControl = function () {
    $(".leaflet-draw.leaflet-control").show();
    $(".leaflet-control.polygon-select").show();
  };

  var hideDrawControl = function () {
    $(".leaflet-draw.leaflet-control").hide();
    $(".leaflet-control.polygon-select").hide();
    if (drawControl && drawControl._toolbars["draw"]) {
      drawControl._toolbars["draw"].disable();
    }
  };

  var loadDrainageDrawLayer = async function () {
    if (drawnItems === null) {
      drawnItems = new L.FeatureGroup();
      drawnItems.setStyle(drawnPolygonStyle);
      await insertExistingDrainage(drawnItems);
    }
  };

  var insertExistingDrainage = async function (drawnItems) {
    const geom =
      Tree.get("tool") === "esg"
        ? PropertyInventoryModalController.getLatestData("geom")
        : InventoryModal.isBmpFcsInventoryModalFormat()
          ? BmpFcsInventoryModalController.getLatestBmpInventoryData("dissolvedDrainage")
          : ProjectInventoryModalController.getLatestConstructionData([
              "dissolvedAreaPolygon",
              "geom",
            ]);

    if (geom) {
      const geoJson = typeof geom === "string" ? JSON.parse(geom) : geom;
      createDrainageFromGeoJson(geoJson);
      await DrainageSelectDraw.calculateDrawnDrainageArea();
    }
  };

  var createDrainageFromGeoJson = function (jsonDrainage) {
    if (jsonDrainage.type === "Polygon") {
      const layer = L.geoJSON(jsonDrainage, {
        style: drawnPolygonStyle,
        pane: "overBoundaryPane",
      }).getLayers()[0];
      drawnItems.addLayer(layer);
    } else {
      jsonDrainage.coordinates.forEach(function (coordinates) {
        const polygonFeature = CleanData.getPolygonFeatureFromCoordinates(coordinates, {
          pane: "overBoundaryPane",
          style: drawnPolygonStyle,
        });
        drawnItems.addLayer(polygonFeature);
      });
    }
  };

  var isDrawnItemsLayerShowing = function () {
    return MapFunctions.mapHasLayer(modalMap, drawnItems);
  };

  var showDrawnItemsLayer = function () {
    if (drawnItems !== null && modalMap !== null) {
      modalMap.addLayer(drawnItems);
      drawnItems.bringToFront();
    }
  };

  var zoomToDrainage = function () {
    if (drawnItems !== null && modalMap !== null) {
      if (drawnItems.getBounds()?.isValid()) {
        modalMap.fitBounds(drawnItems.getBounds());
      }
    }
  };

  var hideDrawnItemsLayer = function () {
    if (drawnItems !== null && modalMap !== null) {
      modalMap.removeLayer(drawnItems);
    }
  };

  var addPolygonToDrawLayer = function (e) {
    MapStyles.setDrawnPolygonStyle(e.layer.options, drawnPolygonStyle);
    DrawFunctions.unionAddLayer(drawnItems, e.layer);
    drainageDrawControl.resetPolygonDraw();
  };

  var toggleDrainageDrawControls = function (toggle) {
    $(".drainage-draw-controls").toggle(toggle);
    if (!toggle && drainageDrawControl) {
      drainageDrawControl.resetControlState();
    }
  };

  var setDrawnDrainageGeom = async function (newGeometry, recalculateArea = true) {
    if (!drawnItems) {
      await loadDrainageDrawLayer();
    }
    drawnItems.eachLayer((layer) => {
      drawnItems.removeLayer(layer);
    });
    if (newGeometry) {
      createDrainageFromGeoJson(newGeometry);
    }
    if (recalculateArea) {
      await DrainageSelectDraw.calculateDrawnDrainageArea(false);
    }
  };

  var resetDrawnItemsLayer = function () {
    drawnItems = null;
  };

  var getDrawnDrainageGeom = function () {
    const drawnLayer = drawnItems.getLayers();
    if (drawnLayer.length) {
      const coordinates = drawnLayer.map((layer) =>
        layer
          .toGeoJSON()
          .geometry.coordinates.map((coords) => coords.map((latlng) => [latlng[1], latlng[0]])),
      );
      const multiPolygon = L.polygon(coordinates);
      const multiPolygonGeometry = multiPolygon.toGeoJSON().geometry;

      return [multiPolygonGeometry];
    } else {
      return drawnLayer.map((layer) => layer.toGeoJSON().geometry);
    }
  };

  var addMethodSelectionControls = function () {
    if (!modalMap.methodSelection) {
      methodSelect = new L.Control.DrawMethodSelector({ defaultValue: "data-layer" });
      modalMap.addControl(methodSelect);
      $(".method-selection-control").insertBefore($(".modal-map-legend-control"));
    }
  };

  var getDrawnItems = function () {
    return drawnItems;
  };

  var showDrawLayer = function () {
    showDrawnItemsLayer();
    hideDrawControl();
    toggleDrainageDrawControls(true);
  };

  var hideDrawLayer = function () {
    hideDrawnItemsLayer();
    showDrawControl();
    toggleDrainageDrawControls(false);
  };

  var setSelectedMethod = function (layer) {
    if (modalMap?.methodSelection) {
      modalMap.methodSelection.selectMethodRadio(layer);
    }

    const tool = Tree.get("tool");
    if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      Form.manuallySetFormDataField("bmp-fcs-inventory", ["drawMethod"], layer);
    } else if (["construction", "lid", "projdelivery"].includes(tool)) {
      Form.manuallySetFormDataField("project-inventory", ["drawMethod"], layer);
    } else if (tool === "esg") {
      Form.manuallySetFormDataField("property-inventory", ["drawMethod"], layer);
    } else {
      console.warn("Tool not included, therefor drawMethod does not equal drawing-tool");
    }
  };

  var getSelectedMethod = function () {
    if (modalMap?.methodSelection) {
      return modalMap?.methodSelection.getSelectedMethod();
    }
  };

  var resetDrawControlSelection = function () {
    if (drainageDrawControl) {
      drainageDrawControl.resetControlState();
    }
  };

  var hasDrawnChanges = function () {
    return hasChanges || drainageDrawControl.hasChanges();
  };

  var resetDrawController = function () {
    if (modalMap?.drainageDrawControls) {
      modalMap.removeControl(drainageDrawControl);
    }
    if (drawControl !== null) {
      modalMap.removeControl(drawControl);
    }
    drawControl = null;
    drawnItems = null;
    modalMap = null;
    polygonSelect = null;
    drainageDrawControl = null;
    methodSelect = null;
    hasChanges = false;
    drawnPolygonStyle = null;
  };

  return {
    init,
    setDrawnPolygonStyle,
    showDrawControl,
    hideDrawControl,
    loadDrainageDrawLayer,
    getDrawnItems,
    showDrawLayer,
    hideDrawLayer,
    setSelectedMethod,
    getSelectedMethod,
    isDrawnItemsLayerShowing,
    showDrawnItemsLayer,
    hideDrawnItemsLayer,
    toggleDrainageDrawControls,
    addPolygonToDrawLayer,
    resetDrawnItemsLayer,
    getDrawnDrainageGeom,
    setDrawnDrainageGeom,
    resetDrawControlSelection,
    resetDrawController,
    zoomToDrainage,
    hasDrawnChanges,
  };
};

module.exports = DrawController();

const ModalMap = require("./modalMap");
const Tree = require("../tree");
const MapStyles = require("./mapStyles");
const DrawFunctions = require("./drawFunctions");
const MapFunctions = require("./mapFunctions");
const CleanData = require("./cleanData");
const DrainageSelectDraw = require("./drainageSelectionDraw");
require("./polygonSelect");
require("./drainageDrawControls.js");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
const PropertyInventoryModalController = require("../esg/modals/propertyInventoryModalController");
