"use strict";

var EnforcementActionModalInputs = function () {
  const inputs = [
    {
      name: "ms4-staff",
      propertyName: "ms4StaffName",
      type: "text",
    },
    {
      name: "issued-date",
      propertyName: "issuedDate",
      type: "date",
    },
    {
      name: "level",
      propertyName: "level",
      type: "text",
    },
    {
      name: "followup-inspection-due-date",
      propertyName: "followupInspectionDueDate",
      type: "date",
    },
    {
      name: "signed-returned-date",
      propertyName: "letterSignedReturnedDate",
      type: "date",
    },
    {
      name: "followup-inspection",
      propertyName: "followupInspectionId",
      type: "select",
    },
    {
      name: "resolved-date",
      propertyName: "resolvedDate",
      type: "date",
    },
    {
      name: "notes",
      propertyName: "notes",
      type: "text",
    },
    {
      name: "contractor-notes",
      propertyName: "contractorNotes",
      type: "text",
    },
    {
      name: "response",
      propertyName: "response",
      type: "select",
    },
    {
      name: "contractor-response",
      propertyName: "contractorResponse",
      type: "select",
    },
  ];

  var getInputs = function () {
    return inputs;
  };

  var getPropertyByName = function (searchName) {
    return inputs.find((input) => {
      return input.name === searchName;
    }).propertyName;
  };

  return { getInputs, getPropertyByName };
};

module.exports = EnforcementActionModalInputs();
