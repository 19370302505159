"use strict";

const StructuralBmpFilters = function () {
  const searchFields = ["bmpName", "facility", "address"];
  const searchDbFields = ["bmp_id", "facility", "address"];
  const systemAccessData = {
    confinedSpaceObservable: {
      filter: function (datum) {
        return datum.system_access === false && (datum.inv_stwat === 1 || datum.inv_sedacc === 1);
      },
      query: function () {
        return "system_access = false AND (inv_stwat = 1 OR inv_sedacc = 1)";
      },
    },
    confinedSpaceNotObservable: {
      filter: function (datum) {
        return datum.system_access === false && !datum.inv_stwat && !datum.inv_sedacc;
      },
      query: function () {
        return "system_access = false AND (inv_stwat = 0 OR inv_stwat IS null) AND (inv_sedacc = 0 OR inv_sedacc IS null)";
      },
    },
    openAir: {
      filter: function (datum) {
        return datum.system_access === true;
      },
      query: function () {
        return "system_access = true";
      },
    },
    null: {
      filter: function (datum) {
        return datum.system_access === null;
      },
      query: function () {
        return "system_access IS null";
      },
    },
  };
  const categoryOptions = [
    { name: "Centralized", value: "centralized" },
    { name: "Decentralized", value: "decentralized" },
    { name: "Unknown", value: "unknown" },
  ];
  let mainSmartFilters;

  function getProps() {
    const subGroupProps = Progeny.getSubGroupFilterProps();
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions } =
      Filters.getSpatialArrays();
    return {
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      phaseOptions: ToolSettings.getSetting("filterConstants", "phases"),
      typesOptions: ToolSettings.getSetting("filterConstants", "bmpTypes"),
      responsiblePartyOptions: BmpFcsInventoryConstants.responsiblePartyDeliveryOptions,
      entityTypeOptions: ToolSettings.getDomainOptions("entity_type"),
      categoryOptions: categoryOptions,
      bmpConditionOptions: [
        { name: "Optimal", value: "optimal" },
        { name: "Good", value: "acceptable" },
        { name: "Fair", value: "fair" },
        { name: "Poor", value: "poor" },
        { name: "No Inspection", value: "no-inspection" },
      ],
      cofOptions: FilterConstants.cofOptionsWithNull,
      yesNoOptionsNullFalse: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      yesNoOptionsNullFalseReverse: [
        {
          name: "Yes",
          value: false,
        },
        {
          name: "No",
          value: true,
        },
      ],
      fcsTypesOptions: BmpFcsInventoryConstants.fcsTypeOptionsNoAbbreviations,
      yesNoOptions: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
        {
          name: "No Data",
          value: "null",
        },
      ],
      yesNoReversedOptions: [
        {
          name: "Yes",
          value: false,
        },
        {
          name: "No",
          value: true,
        },
        {
          name: "No Data",
          value: "null",
        },
      ],
      systemAccessibilityOptions: [
        { name: "Confined Space (Observable)", value: "confinedSpaceObservable" },
        { name: "Confined Space (Not Observable)", value: "confinedSpaceNotObservable" },
        { name: "Open Air", value: "openAir" },
        { name: "No Data", value: "null" },
      ],
    };
  }

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          StructuralBmpsGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var passAssesmentDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.assesmentDate,
      filters.assessmentDate?.from,
      filters.assessmentDate?.to,
    );
  };

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installationDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  var passScoreFilter = function (datum, filters) {
    return OfflineFilterFunctions.passMinMaxFilter(datum.bmpMapScore, filters.score);
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return ResourceFilters.spatialFiltersAreSet(filters) || isOnlineDateFilterSet(filters);
    }
  };

  var isOnlineDateFilterSet = function (filters) {
    return isOnlineDateType(filters.dateType) && (filters.dateFrom || filters.dateTo);
  };

  var isOnlineDateType = function (dateType) {
    return ["routine", "repair", "clean"].includes(dateType);
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(filters, "fcs", queryStrings);

    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }

    if (filters.assessmentDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "obs_date",
        filters.assessmentDate?.from,
        filters.assessmentDate?.to,
      );
    }

    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "bmp_creation",
        filters.installDate?.from,
        filters.installDate?.to,
      );
    }

    if (filters.systemAccess) {
      addSystemAccessFilter(queryStrings, filters.systemAccess);
    }

    if (filters.sbmpCategory) {
      addCategoryFilter(queryStrings, filters.sbmpCategory);
    }

    if (filters.bmpCondition) {
      addBmpConditionFilter(queryStrings, filters.bmpCondition);
    }

    GeoServerFilterFunctions.addTrueFalseNullAsIntFilter(queryStrings, filters.bmpFlow, "bmp_flow");
    GeoServerFilterFunctions.addTrueFalseNullAsIntFilter(
      queryStrings,
      filters.drainsout,
      "drainsout",
    );
    GeoServerFilterFunctions.addTrueFalseNullAsIntFilter(
      queryStrings,
      filters.diversion,
      "diversion",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "phase", "phase");

    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings, [
      { key: "drainsToRw", field: "drains_to_rw" },
      { key: "drainsToC", field: "drains_to_c" },
      { key: "catchmentName", field: "catchment_name" },
    ]);

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "types",
      "bmp_type_num",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementerType",
      "implementer_type",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "responsiblePartyMaintenance",
      "responsible_party_maintenance",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "consequenceOfFailure",
      "cof_final_text",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "inProximityTo303dWaterbody",
      "in_proximity_to_303d_waterbody",
    );

    GeoServerFilterFunctions.addMinMaxQuery(queryStrings, "bmp_map_score", filters.score);
  };

  var passSystemAccessFilter = function (datum, filters) {
    if (!filters.systemAccess) {
      return true;
    }

    for (const accessFilter of filters.systemAccess) {
      if (systemAccessData[accessFilter]["filter"](datum)) {
        return true;
      }
    }

    return false;
  };

  var addSystemAccessFilter = function (queryStrings, systemAccess) {
    const queries = [];

    for (const accessFilter of systemAccess) {
      const query = systemAccessData[accessFilter]["query"]();
      queries.push(`(${query})`);
    }

    if (queries.length) {
      queryStrings.push(`(${queries.join(" OR ")})`);
    }
  };

  var getPassCategoryFilter = function (bmpTypeKey) {
    return function (datum, filters) {
      const bmpType = datum[bmpTypeKey];
      const sbmpCategoryFilter = filters.sbmpCategory;

      if (!sbmpCategoryFilter) {
        return true;
      }

      if (sbmpCategoryFilter.includes("unknown") && (bmpType === undefined || bmpType === 16)) {
        return true;
      }

      if (
        sbmpCategoryFilter.includes("centralized") &&
        BmpFcsFunctions.isCentralizedBMPType(bmpType)
      ) {
        return true;
      }

      if (
        sbmpCategoryFilter.includes("decentralized") &&
        BmpFcsFunctions.isDecentralizedBMPType(bmpType)
      ) {
        return true;
      }

      return false;
    };
  };

  var addCategoryFilter = function (queryStrings, sbmpCategoryFilter) {
    const parts = [];

    if (sbmpCategoryFilter.includes("unknown")) {
      parts.push(`bmp_type_num = 16`);
    }

    const isCentralized = sbmpCategoryFilter.includes("centralized");
    const isDecentralized = sbmpCategoryFilter.includes("decentralized");
    if (isCentralized && isDecentralized) {
      parts.push(`bmp_type_num IS NOT null`);
    } else if (isCentralized && !isDecentralized) {
      parts.push(`bmp_type_num IN (${FormConstants.centralizedBmpTypeNumbers.join(",")})`);
    } else if (!isCentralized && isDecentralized) {
      parts.push(`bmp_type_num NOT IN (${FormConstants.centralizedBmpTypeNumbers.join(",")})`);
    }

    if (parts.length) {
      queryStrings.push(`(${parts.join(" OR ")})`);
    }
  };

  var passBmpConditionFilter = function (datum, filters) {
    const condition = MapStyles.getConditionFromScore(datum.bmpMapScore);

    if (!filters.bmpCondition) {
      return true;
    }

    if (filters.bmpCondition.includes(condition)) {
      return true;
    }

    if (filters.bmpCondition.includes("no-inspection") && condition === null) {
      return true;
    }

    return false;
  };

  var addBmpConditionFilter = function (queryStrings, conditionFilter) {
    const queryParts = [];

    let lastMax;
    for (const threshold of MapStyles.getConditionThresholds()) {
      const parts = [];

      if (lastMax !== undefined) {
        parts.push(`bmp_map_score > ${lastMax}`);
      }

      if (threshold.maxScore !== undefined) {
        parts.push(`bmp_map_score <= ${threshold.maxScore}`);
      }

      if (conditionFilter.includes(threshold.condition)) {
        queryParts.push(`(${parts.join(" AND ")})`);
      }

      lastMax = threshold.maxScore;
    }

    if (conditionFilter.includes("no-inspection")) {
      queryParts.push("bmp_map_score IS null");
    }

    if (queryParts.length) {
      queryStrings.push(`(${queryParts.join(" OR ")})`);
    }
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      subGroups: "groupId",
      phase: "phase",
      types: "bmpTypeId",
      implementerType: "implementerType",
      responsiblePartyMaintenance: "responsiblePartyMaintenance",
      sbmpCategory: getPassCategoryFilter("bmpTypeId"),
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      fcs: "fcs",
      consequenceOfFailure: "cof_final_text",
      inProximityTo303dWaterbody: "inProximityTo303dWaterbody",
      bmpFlow: "bmpFlow",
      drainsout: "drainsout",
      diversion: "diversion",
      systemAccess: passSystemAccessFilter,
      bmpCondition: passBmpConditionFilter,

      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passScoreFilter(datum, filters) &&
          passAssesmentDateFilter(datum, filters) &&
          passInstalledDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    isOnlineDateFilterSet,
    searchDbFields,
    addGeoServerFilterQuery,
    passScoreFilter,
    passAssesmentDateFilter,
    passInstalledDateFilter,
    passSystemAccessFilter,
    getPassCategoryFilter,
    passBmpConditionFilter,
    addSystemAccessFilter,
    categoryOptions,
    addCategoryFilter,
    addBmpConditionFilter,
    getProps,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = StructuralBmpFilters();

const ApiError = require("../errors/apiError");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const ResourceFilters = require("../filters/resourceFilters");
const StructuralBmpsGeoServerLayer = require("./structuralBmpsGeoServerLayer");
const OfflineFilterFunctions = require("../mapFunctions/offlineFilterFunctions");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const Filters = require("../mapFunctions/filters");
const ToolSettings = require("../settings/toolSettings");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const FormConstants = require("../mapFunctions/formConstants");
const MapStyles = require("../mapFunctions/mapStyles");
const FilterConstants = require("../filterConstants");
const Progeny = require("../login/progeny");
