"use strict";

const MuniCatchBasinPopup = function () {
  var loadDomListeners = function () {
    $(".map-content").off("click", ".popup-history-button");
    $(".map-content").on("click", ".popup-history-button", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
  };

  var loadPopupEventListeners = function (layer) {
    layer.on("popupopen", _onPopupOpen);
  };

  var getMuniCatchBasinPopupHtml = function (bmp) {
    const popupProps = getMuniCatchBasinPopupProps(bmp);
    return nunjucks.render("report/popups/muniCatchBasinProgressPopup.njk", popupProps);
  };

  var getMuniCatchBasinPopupProps = function (asset) {
    var props = {};

    props.popupColor = MuniCatchBasinIcon.getIconColor(asset.score, asset.routineMaintenanceDue);

    props.disableRoutineMaintenanceButton = asset.phase !== "completed";

    return $.extend({}, asset, props);
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();

    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-muni-catch-basin-history").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-history-button").show();
    $parent.find(".popup-muni-catch-basin-main").show();
  };

  var popupHistoryButtonClick = function () {
    var idbmp = $(this).parents(".popup-general").data("id");
    showInspectionHistory(idbmp);
  };

  var showInspectionHistory = function () {
    const inspections = Tree.get("currentBmpFcs", "inspections");

    const props = getInspectionHistoryProps(inspections);
    $(".popup-muni-catch-basin-history").html(
      nunjucks.render("report/popups/muniCatchBasinHistory.njk", props),
    );
    $(".popup-muni-catch-basin-main").hide();
    $(".popup-history-button").hide();
    $(".popup-muni-catch-basin-history").show();
    $(".popup-back-button").show();
  };

  var getInspectionHistoryProps = function (inspections) {
    const props = {};
    props.assessments = inspections;
    return props;
  };

  var _onPopupOpen = async function (e) {
    const id = e.layer.feature.properties.id;

    const data = await getPopupData(id);
    const html = getMuniCatchBasinPopupHtml(data);
    e.popup.setContent(html);
  };

  var getPopupData = async function (id) {
    const popupData = await ReportApiCalls.getMuniCatchBasinPopup(id);

    Tree.set("currentBmpFcs", popupData);

    return popupData;
  };

  return {
    getMuniCatchBasinPopupHtml,
    getPopupOptions,
    loadPopupEventListeners,
    loadDomListeners,
  };
};

module.exports = MuniCatchBasinPopup();

const ReportApiCalls = require("../reportApiCalls");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
const MuniCatchBasinIcon = require("./muniCatchBasinIcon");
const Tree = require("../../tree");
