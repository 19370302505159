"use strict";

const NetworkError = require("./networkError");

class ApiError extends NetworkError {
  constructor(xhrResponse) {
    super(xhrResponse.status, xhrResponse.responseText);
    this.name = "ApiError";
    this.xhrResponse = xhrResponse;
  }

  static assertIs(e) {
    if (!(e instanceof ApiError)) {
      throw e;
    }
  }
}

module.exports = ApiError;
