"use strict";

function RouteFunctions() {
  async function warnBeforeNavigating(
    done,
    modalPromptFunction,
    immediateNavigateFunction,
    quickExit = false,
  ) {
    const UrlRoutes = require("../urlRoutes");

    if (quickExit) {
      done();
      immediateNavigateFunction();
    } else if (await BrowserHistory.isNavigating()) {
      warnViaBrowser(
        function () {
          done();
          immediateNavigateFunction();
        },
        function () {
          UrlRoutes.stayOnPreviousPath();
          done(false);
        },
      );
    } else {
      modalPromptFunction(
        () => done(),
        () => done(false),
      );
    }
  }

  function warnViaBrowser(done, notDone) {
    const CommonModalFunctions = require("../../modals/commonModalFunctions");

    const shouldClose = window.confirm(
      CommonModalFunctions.getCloseWithoutSavingPrompt("Cancel", "Ok"),
    );

    if (shouldClose) {
      done();
    } else {
      notDone();
    }
  }

  async function leaveTabbedModalRoute(done) {
    await warnBeforeNavigating(
      done,
      TabbedModal.cancelPrompt,
      TabbedModal.cancelAndHide,
      !TabbedModal.getFormHasUpdates(),
    );
  }

  function parseId(maybeId) {
    if (maybeId === "" || maybeId === undefined) {
      return undefined;
    }

    if (typeof maybeId !== "string") {
      throw new Error(`${maybeId} must be a string`);
    }

    const result = parseInt(maybeId);

    if (Number.isNaN(result)) {
      throw new Error(`${maybeId} must be a number`);
    }

    return result;
  }

  async function openNewOrExistingInspection({
    openInspectionFn,
    resourceKey,
    assetId,
    inspectionId,
    params = null,
  } = {}) {
    let newDataStore;

    inspectionId = parseId(inspectionId);
    assetId = parseId(assetId);

    const resource = ResourceController.get(resourceKey);
    try {
      if (inspectionId === undefined) {
        newDataStore = await resource.createNew(assetId);
      } else {
        newDataStore = await resource.openExisting(inspectionId);
      }
    } catch (e) {
      if (e instanceof DataNotFoundError) {
        UrlRoutes.navigateToDefault();
        return;
      } else if (e instanceof DataStoreError || e instanceof MalformedIdError) {
        return;
      } else {
        throw e;
      }
    }

    return await openInspectionFn(newDataStore);
  }

  return {
    warnBeforeNavigating,
    leaveTabbedModalRoute,
    parseId,
    openNewOrExistingInspection,
  };
}

module.exports = RouteFunctions();

const BrowserHistory = require("../../general/browserHistory");
const DataNotFoundError = require("../../errors/dataNotFoundError");
const DataStoreError = require("../../errors/dataStoreError");
const MalformedIdError = require("../../errors/malformedIdError");
const ResourceController = require("../../offline/resourceController");
const TabbedModal = require("../../general/tabbedModal");
const UrlRoutes = require("../urlRoutes");
