"use strict";

function JobQueue() {
  let queue;
  let jobRunner;
  cancel();

  function add(job) {
    queue.push(job);
    return jobRunner.run();
  }

  function cancel() {
    jobRunner?.abort?.();
    queue = new Queue();
    jobRunner = new JobRunner(queue);
  }

  // Rejects if a job fails
  function getDonePromise() {
    return jobRunner.run();
  }

  return {
    add,
    cancel,
    getDonePromise,
  };
}

function Queue() {
  const jobs = [];

  function push(job) {
    assertFunction(job);
    jobs.push(job);
  }

  function assertFunction(job) {
    if (typeof job !== "function") {
      throw new Error("Job must be a function");
    }
  }

  function shift() {
    return jobs.shift();
  }

  return {
    push,
    shift,
  };
}

function JobRunner(queue) {
  const abortController = new AbortController();
  let currentJob;
  let jobRunnerPromise;
  let failed = false;

  // Rejects if a job fails
  function run() {
    if (!jobRunnerPromise || failed) {
      jobRunnerPromise = runQueue();
    }

    return jobRunnerPromise;
  }

  async function runQueue() {
    do {
      if (!failed) {
        currentJob = queue.shift();
      }

      try {
        await currentJob?.();
        failed = false;
      } catch (e) {
        failed = true;
        throw e;
      }
    } while (currentJob && !abortController.signal.aborted);

    jobRunnerPromise = null;
  }

  function abort() {
    abortController.abort();
  }

  return {
    run,
    abort,
  };
}

module.exports = JobQueue;
