"use strict";

const TelrResultLayer = function () {
  const layerName = "telrResult";
  const layerKey = "telrResultLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadTreeUpdateListenersForMap(map, mapLayers);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      if (isEnabled) {
        $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").addClass(
          "non-interactive-catchment",
        );

        if (mapLayers[layerKey] && (mapId === "modal" || (mapId === "main" && sameSpatialFilter))) {
          map.addLayer(mapLayers[layerKey]);
        } else {
          loadLayer(mapId);
        }
      } else {
        $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").removeClass(
          "non-interactive-catchment",
        );

        if (MapFunctions.mapHasLayer(map, mapLayers[layerKey])) {
          map.removeLayer(mapLayers[layerKey]);
        }
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(layerName, mapId, function (data) {
      mapLayers[layerKey] = addTelrResultLayer(map, mapLayers[layerKey], data);
    });
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    Tree.select("filters").on("update", function (e) {
      const unfilteredData = Tree.get(["layers", layerName, "unfilteredData"]);
      if (unfilteredData?.length && Filters.spatialFilterIsSet()) {
        const filteredData = spatiallyFilterData(unfilteredData);
        Tree.set(["layers", layerName, "data"], filteredData);
        TelrResultLayerFunctions.saveColumnArrays(filteredData);
      } else {
        Tree.set(["layers", layerName, "data"], unfilteredData);
        TelrResultLayerFunctions.saveColumnArrays(unfilteredData);
      }
    });

    Tree.select(["layers", layerName, "data"]).on("update", async function (e) {
      const data = e.data.currentData;

      if (!Tree.get(["insightDataIsFetching", "scenarios"])) {
        if (Tree.get("activeTab") !== "data") {
          const insightData = await ApiCalls.getScenarioInsight();
          Tree.set(["currentInsightData", "scenarios"], insightData);
          TelrResultLayerFunctions.renderSummaryTable(data, insightData);
        }
      } else {
        TelrResultLayerFunctions.renderSummaryTable(data);
      }
    });

    Tree.select("dataScenario").on("update", function (e) {
      const dataPath = Actions.getLayerDataPathByMapId("main");
      const currentLayerData = Tree.get(["layers", layerName, dataPath]);
      mapLayers[layerKey] = addTelrResultLayer(map, mapLayers[layerKey], currentLayerData);

      TelrResultLayerFunctions.updateLegend();
      TelrResultLayerFunctions.renderSummaryTable(Tree.get(["layers", layerName, "data"]));
      TelrResultLayerFunctions.handleTelrResultBmpLayerDisplay();
    });

    Tree.select("dataScenario").on("update", function (e) {
      const dataPath = Actions.getLayerDataPathByMapId("main");
      const currentLayerData = Tree.get(["layers", layerName, dataPath]);
      mapLayers[layerKey] = addTelrResultLayer(map, mapLayers[layerKey], currentLayerData);

      TelrResultLayerFunctions.updateLegend();
      TelrResultLayerFunctions.renderSummaryTable(Tree.get(["layers", layerName, "data"]));
      TelrResultLayerFunctions.handleTelrResultBmpLayerDisplay();
    });

    Tree.select(["currentInsightData", "scenarios"]).on("update", function (e) {
      TelrResultLayerFunctions.renderSummaryTable(
        Tree.get(["layers", layerName, "data"]),
        e.data.currentData,
      );
    });
  };

  var addTelrResultLayer = function (map, telrResultLayer, data) {
    if (telrResultLayer) {
      map.removeLayer(telrResultLayer);
    }

    const geoJson = CleanData.cleanGeoJson(data);
    telrResultLayer = new Layers.TelrResultLayer(geoJson).addTo(map);

    return telrResultLayer;
  };

  var loadLayer = function (mapId = "main") {
    const dataPath = Actions.getLayerDataPathByMapId(mapId);

    Tree.set(["layers", layerName, "isFetching"], true);
    ApiCalls.getTelrResultLayerData().then((data) => {
      if (Filters.spatialFilterIsSet()) {
        const filteredData = spatiallyFilterData(data);
        Tree.set(["layers", layerName, "data"], filteredData);
      } else {
        Tree.set(["layers", layerName, dataPath], data);
        Tree.set(["layers", layerName, "unfilteredData"], data);
      }
      Tree.set(["layers", layerName, "isFetching"], false);

      TelrResultLayerFunctions.saveColumnArrays(data);
    });
  };

  var spatiallyFilterData = function (data) {
    const filters = Tree.get("filters");
    return data.filter((datum) => {
      return (
        filters.receivingWaters.includes(datum["drains_to_rw"]) ||
        filters.receivingWaters.includes(datum["drains_to_c"]) ||
        filters.catchments.includes(datum["catchid"])
      );
    });
  };

  return {
    loadLayer,
    loadLayerListenersForMap,
  };
};

module.exports = TelrResultLayer();

const ApiCalls = require("../apiCalls");
const Actions = require("../actions");
const CleanData = require("../mapFunctions/cleanData");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Filters = require("../mapFunctions/filters");
const Layers = require("../mapFunctions/layers");
const Tree = require("../tree");
const TelrResultLayerFunctions = require("./telrResultLayerFunctions");
