"use strict";

const ActivitiesLayer = function () {
  const layerName = "activities";
  const layerKey = "activitiesLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const layer = new GeoServerMapLayer(layerName, layerKey);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, layer);

    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await ApiCalls.getActivities();
    return data.map(getOrganizedProperties);
  };

  var getOrganizedProperties = function (datum) {
    const constants = ActivityConstants.get();

    datum.activity = constants.activities[datum.activityKey]?.displayName ?? null;
    datum.outreachType = constants.activities[datum.activityKey]?.outreachType ?? null;
    datum.activityGroup = constants.activities[datum.activityKey]?.activityGroup ?? null;

    datum.targetAudience = ActivityConstants.getTargetDisplayByGrouping(datum.targetOptions, [
      "audience",
      "staff",
    ]);
    datum.targetPollutant = ActivityConstants.getTargetDisplayByGrouping(datum.targetOptions, [
      "pollutant",
    ]);

    return datum;
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    getLayerData,
  };
};

module.exports = ActivitiesLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const ApiCalls = require("../apiCalls");
const ActivityConstants = require("./activityConstants");
