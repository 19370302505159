"use strict";

const OutfallsSimpleLayer = function () {
  const Outfalls = require("./outfalls");
  const OutfallsIcon = require("./outfallsIcon");
  const ApiCalls = require("../apiCalls");

  const layerName = "outfallsSimple";
  const layerKey = "outfallsSimpleLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    Outfall.loadToggledListenerForMap(map, mapLayers, mapId);
    Outfall.loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (mapId === "main") {
      Outfall.loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  const Outfall = new Outfalls(
    layerName,
    layerKey,
    OutfallsIcon.getSimpleOutfallsMarker,
    ApiCalls.getOutfalls,
    {
      hideWhenZoomedOut: true,
      useSimplePopup: true,
    },
  );

  return {
    ...Outfall,
    loadLayerListenersForMap,
  };
};

module.exports = OutfallsSimpleLayer();
