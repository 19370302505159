"use strict";

const IndcomFacilityFilters = function () {
  const searchFields = ["facilityIdentifier", "address", "facilityName", "wdid"];
  const searchDbFields = [
    "facility_identifier",
    "address",
    "facility_name",
    "waste_discharger_identifier",
  ];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          IndcomFacilityGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData, filters) {
    if (filters?.toDoFilters) {
      return unfilteredData.filter((datum) => {
        return ResourceFilters.passToDoFilters(datum, filters);
      });
    } else {
      return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
    }
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return (
        ResourceFilters.spatialFiltersAreSet(filters) ||
        isOnlineDateFilterSet(filters) ||
        isOpenFindingsFilterSet(filters) ||
        isOpenEnforcementsFilterSet(filters)
      );
    }
  };

  var isOnlineDateType = function (dateType) {
    return ["inspection"].includes(dateType);
  };

  var isOnlineDateFilterSet = function (filters) {
    return (
      isOnlineDateType(filters.dateType) && (filters.inspection?.from || filters.inspection?.to)
    );
  };

  var isOpenFindingsFilterSet = function (filters) {
    return filters.openFindings?.length !== undefined && filters.openFindings.length < 2;
  };

  var isOpenEnforcementsFilterSet = function (filters) {
    return filters.openEnforcements?.length !== undefined && filters.openEnforcements.length < 2;
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "facilityType",
      "facility_type",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "facilityPhase",
      "facility_phase",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "responsiblePartyManagement",
      "responsible_party_management",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "fogParticipant",
      "fog_participant",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "isIndustrialGeneralPermit",
      "is_industrial_general_permit",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "riskProfile",
      "risk_profile",
    );

    if (
      filters.openEnforcements?.length !== undefined &&
      !FilterSummary.areAllCheckboxesChecked("openEnforcements")
    ) {
      queryStrings.push(
        GeoServerFilterFunctions.getYesNoQuery("open_enforcements", filters.openEnforcements),
      );
    }

    if (
      filters.openFindings?.length !== undefined &&
      !FilterSummary.areAllCheckboxesChecked("openFindings")
    ) {
      queryStrings.push(
        GeoServerFilterFunctions.getYesNoQuery("open_findings", filters.openFindings),
      );
    }

    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }
    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings, [
      { key: "drainsToRw", field: "drains_to_rw" },
      { key: "drainsToC", field: "drains_to_c" },
      { key: "catchmentName", field: "catchment_name" },
    ]);
  };

  var addGeoServerViewParams = function (params, filters) {
    ResourceFilters.addDateParam(
      params,
      "inspection",
      filters.inspection?.from,
      filters.inspection?.to,
    );
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      subGroups: "groupId",
      facilityType: "facilityType",
      facilityPhase: "facilityPhase",
      responsiblePartyManagement: "responsiblePartyManagement",
      fogParticipant: "fogParticipant",
      isIndustrialGeneralPermit: "isIndustrialGeneralPermit",
      riskProfile: "riskProfile",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      highwayName: "highway_name",
      openFindings: (datum, filters) =>
        filters.openFindings
          ? filters.openFindings.includes(datum.hasOpenFindings ? "yes" : "no")
          : true,
      openEnforcements: (datum, filters) =>
        filters.openEnforcements
          ? filters.openEnforcements.includes(datum.hasOpenEnforcements ? "yes" : "no")
          : true,
      otherFilters: (datum, filters) => {
        return ResourceFilters.passSearchStringFilter(datum, filters, searchFields);
      },
    };
  };

  var loadListeners = function () {
    FacilityTypeFilter.loadListeners();
  };

  var getProps = function () {
    return FacilityTypeFilter.getProps();
  };

  return {
    filterOffline,
    filterResourceDataHandler,
    searchDbFields,
    isOnlineDateFilterSet,
    isOpenFindingsFilterSet,
    isOpenEnforcementsFilterSet,
    addGeoServerFilterQuery,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
    loadListeners,
    getProps,
  };
};

module.exports = IndcomFacilityFilters();

const ApiError = require("../errors/apiError");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const IndcomFacilityGeoServerLayer = require("./indcomFacilityGeoServerLayer");
const ResourceFilters = require("../filters/resourceFilters");
const FilterSummary = require("../filters/filterSummary");
const FacilityTypeFilter = require("../filters/facilityTypeFilter");
