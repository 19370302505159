"use strict";

function NetworkErrorMessage() {
  function get(error, action, htmlErrorMessage = null) {
    return htmlErrorMessage ? addErrorMessageEnd(htmlErrorMessage) : getErrorMessage(error, action);
  }

  function getErrorMessage(error, action) {
    let message = `The ${action} has failed`;

    if (error instanceof UploadLimitError) {
      return error.message;
    }

    if (error instanceof ApiError) {
      const response = error.xhrResponse;
      const errorReason = getApiErrorReason(response);
      message += ` with error code ${response.status}${errorReason ? ` (${errorReason})` : ""}`;
    }

    message += ".";
    return addErrorMessageEnd(message);
  }

  function getApiErrorReason(response) {
    const reasonsByCode = {
      0: "network error",
      410: "file is missing",
      500: "server error",
    };
    let errorReason = response.statusText.toLowerCase();

    if (["", "error"].includes(errorReason)) {
      errorReason = null;
    }

    if (response.status === 400 && response.responseJSON?.error) {
      return response.responseJSON.error;
    }

    if (reasonsByCode[response.status]) {
      errorReason = reasonsByCode[response.status];
    }

    return errorReason;
  }

  function addErrorMessageEnd(message) {
    return (
      message +
      " Please try again later or contact us at <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for assistance."
    );
  }

  return {
    get,
  };
}

module.exports = NetworkErrorMessage();

const ApiError = require("../errors/apiError");
const UploadLimitError = require("../errors/uploadLimitError");
