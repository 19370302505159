"use strict";

const FilesPage = function () {
  const stringKey = "files";
  const headerInformation = "Files";

  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    let data = PropertyInventoryModalController.getAllData();
    data = _getPreparedData(data);

    await PropertyInventoryModalController.renderPageContent(
      nunjucks.render("esg/modals/propertyInventory/filesPage.njk", data),
    );
    CollapsingCard.initializeCards($("#inventory-modal"));
    _loadListeners();
    _updateFilesCount();
    Form.initializeDatePickers($page, PropertyInventoryModalController.getFormKey());
    data.files?.forEach((file) => {
      _showInputByLocationType(file);
      _initializeDropzone(file);
      _updateExternalLink(file);
    });
  };

  var _loadListeners = function () {
    Form.initializeAndLoadListeners($page, PropertyInventoryModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("change", "[name*=type]", _updateFileType);
    $page.on("change", "[name*=approvalDateLocal]", _updateCardStatus);
    $page.on("input, change", "[name*=files]", _addFileId);
    $page.on("click", ".add-file", _addFile);
    $page.on("click", ".delete-button", _deleteFile);
    $page.on("change", `[name*="locationType"]`, _changeLocationType);
    $page.on("input", `[name*="externalUrl"]`, _updateExternalUrl);
  };

  var _unloadListeners = function () {
    $page.off("change", "[name*=type]", _updateFileType);
    $page.off("change", "[name*=approvalDateLocal]", _updateCardStatus);
    $page.off("input, change", "[name*=files]", _addFileId);
    $page.off("click", ".add-file", _addFile);
    $page.off("click", ".delete-button", _deleteFile);
    $page.off("change", `[name*="locationType"]`, _changeLocationType);
    $page.off("input", `[name*="externalUrl"]`, _updateExternalUrl);
  };

  var _updateFileType = function () {
    const $this = $(this);
    const $card = $this.closest(".card");
    const deleteIcon = $card.find(".card-header span")[0].outerHTML;
    $card.find(".card-header").html(_getDisplayFileType($this.val()) + deleteIcon);
  };

  var _getDropdownOptions = function () {
    return {
      ...PageController.getDropdownOptions(),
      fileTypes: ProjectInventoryConstants.getFileTypeOptions(),
      uploadLocation: ProjectInventoryConstants.uploadLocationOptions,
    };
  };

  var _addFile = function () {
    const index = $(".card").length;
    const dropdownOptions = _getDropdownOptions();
    const approvalDateLocal = DateTime.getTodayIso();
    const id = Date.now();
    const file = {
      id,
      locationType: "external-url",
      approvalDateLocal,
      statusText: _getDisplayDate(approvalDateLocal),
      new: true,
    };
    const newCard = nunjucks.render("esg/modals/propertyInventory/_newFile.njk", {
      file,
      index,
      dropdownOptions,
    });
    $("#files").append(newCard);
    const $newCard = $("#inventory-modal .card:last");
    CollapsingCard.initializeCard($newCard);
    Form.initializeDatePickers($newCard, PropertyInventoryModalController.getFormKey());
    _initializeDropzone(file);
    _showInputByLocationType(file);

    const $modalBody = $("#inventory-modal .modal-body");
    $modalBody.animate({ scrollTop: $modalBody[0].scrollHeight }, 500);

    _updateFilesCount();
    PropertyInventoryModalController.setData(["files", index], file);
  };

  var _updateCardStatus = function () {
    const $card = $(this).closest(".card");
    const index = $card.data("index");
    const file = PropertyInventoryModalController.getAllData().files?.[index];
    const statusText = _getDisplayDate(file.approvalDateLocal);
    $card.data("custom-status-text", statusText);
  };

  var _addFileId = function () {
    const $this = $(this);
    const $card = $this.closest(".card");
    const index = $card.data("index");
    const id = $card.data("id");
    if (!id) return;
    PropertyInventoryModalController.setData(["files", index], {
      ...PropertyInventoryModalController.getLatestData(["files", index]),
      id,
    });
  };

  var _deleteFile = function () {
    const $this = $(this);
    const id = $this.data("id");
    const $card = $this.closest(".card");
    $card.addClass("hidden");
    const index = $card.data("index");
    PropertyInventoryModalController.setData(["files", index], { id, deleted: true });
    _updateFilesCount();
  };

  var _changeLocationType = function (e) {
    const $this = $(e.currentTarget);
    const locationType = $this.val();
    const id = $this.closest(".card").data("id");
    _showInputByLocationType({ id, locationType });
  };

  var _updateExternalUrl = function (e) {
    const $this = $(e.currentTarget);
    _updateExternalLink({
      id: $this.closest(".card").data("id"),
      externalUrl: $this.val(),
    });
  };

  var _showInputByLocationType = function (file) {
    const $card = $page.find(`[data-id=${file.id}]`);
    $card.find(".file-text-description").toggle(file.locationType === "location");
    $card.find(".file-drop-zone").toggle(file.locationType === "upload");
    $card.find(".file-external-url").toggle(file.locationType === "external-url");
  };

  var _updateFilesCount = function () {
    const count = $(".card:not(.hidden)").length;
    $(".files-count span").text(count);
    $(".no-files").toggleClass("hidden", count > 0);
  };

  var _getPreparedData = function (data) {
    return {
      ...data,
      dropdownOptions: _getDropdownOptions(),
      files: data?.files?.map(function (file) {
        return {
          ...file,
          statusText: _getDisplayDate(file.approvalDateLocal),
          displayType: _getDisplayFileType(file.type),
        };
      }),
    };
  };

  var _initializeDropzone = function (file, readOnly = false) {
    const $parent = $page.find(`[data-id=${file.id}]`);
    const dropzoneName = $parent.find(".drop-zone").attr("name");
    let newFile = [];
    let existingFile = [];
    if ($.isArray(file.fileUploadLocation)) {
      newFile = file.fileUploadLocation;
    } else if (file.uploadedFilename) {
      existingFile = [file.uploadedFilename];
    }
    const dropzone = Form.initializeDropzone(
      PropertyInventoryModalController.getFormKey(),
      $parent,
      {
        newFiles: newFile,
        existingFiles: existingFile,
        maxNumberFiles: 1,
        readOnly,
      },
    );

    _configureDropzoneHandlers(file.id, dropzoneName, dropzone);
  };

  var _updateExternalLink = function (file) {
    const $parent = $page.find(`[data-id=${file.id}]`);
    const makeAbsolute = file.externalUrl && !/^(https?:)?\/\/.*$/.test(file.externalUrl);
    const absoluteUrl = makeAbsolute ? "//" + file.externalUrl : file.externalUrl;
    $parent
      .find(".external-link")
      .attr("href", absoluteUrl)
      .toggleClass("hidden", !file.externalUrl);
  };

  var _configureDropzoneHandlers = function (fileId, dropzoneName, dropzone) {
    dropzone.setDownloadHandler(() => EsgApiCalls.downloadPropertyFile(fileId));
    dropzone.setRemoveExistingCallback((fileName) => {
      _removeFileFromForm(dropzoneName);
    });
  };

  const _removeFileFromForm = function (dropzoneName) {
    const index = Form.getPathPartsFromName(dropzoneName)[1];
    const id = PropertyInventoryModalController.getAllData().files?.[index].id;
    Form.manuallySetFormDataField(
      PropertyInventoryModalController.getFormKey(),
      ["files", index, "fileUploadLocation"],
      null,
    );
    PropertyInventoryModalController.setData(["files", index], {
      ...PropertyInventoryModalController.getLatestData(["files", index]),
      id,
    });
  };

  var _getDisplayFileType = function (type) {
    return ProjectInventoryConstants.getFileTypeOptions().find((t) => t.value === type)?.name || "";
  };

  var _getDisplayDate = function (date) {
    const statusText = DateTime.formatIsoString(date);
    return statusText;
  };

  var validate = function () {
    return true;
  };

  var cleanUp = function () {
    _unloadListeners();
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    _updateExternalLink,
  };
};

module.exports = FilesPage();

const CollapsingCard = require("../../../general/collapsingCard");
const PropertyInventoryModalController = require("../propertyInventoryModalController");
const Form = require("../../../general/form");
const PageController = require("../../pageController");
const ProjectInventoryConstants = require("../../../construction/projectInventoryConstants");
const DateTime = require("../../../dateTime");
const EsgApiCalls = require("../../esgApiCalls");
