"use strict";

const FacilityInspectionValidator = function () {
  var getMissingRequiredFields = function (allData) {
    return FormValidator.getEmptyRequiredFieldsInData(
      allData,
      getMissingGeneralInfoFields(allData),
    );
  };

  var getMissingGeneralInfoFields = function (data) {
    var fields = [];

    fields.push({
      fieldName: "inspector-name",
      message: "Inspector name",
    });

    fields.push({
      fieldName: "start-time",
      message: "Inspection date & time",
    });

    fields.push({
      fieldName: "inspection-type",
      message: "Inspection type",
    });

    fields.push({
      fieldName: "followUpInspection",
      message: "Follow Up",
    });

    if (data.followUpInspection) {
      fields.push({
        fieldName: "follow-up-inspection-type",
        message: "Follow Up Inspection Type",
      });
    }

    fields.push({
      fieldName: "isSiteRepPresent",
      message: "Is the site representative on site?",
    });

    if (data.isSiteRepPresent) {
      fields.push({
        fieldName: "signatures[siteRepDate]",
        message: "Site representative signature date",
      });
      fields.push({
        fieldName: "signatures[siteRepName]",
        message: "Site representative name",
      });
      fields.push({
        fieldName: "signatures[siteRepEsignature]",
        message: "Site representative signature",
      });
    }

    fields.push({
      fieldName: "end-time",
      message: "Inspection end date & time",
    });
    fields.push({
      fieldName: "signatures[inspectorName]",
      message: "Inspector name",
    });
    fields.push({
      fieldName: "signatures[inspectorEsignature]",
      message: "Inspector signature",
    });

    return fields;
  };

  return {
    getMissingRequiredFields,
  };
};

module.exports = FacilityInspectionValidator();

const FormValidator = require("../../general/formValidator");
