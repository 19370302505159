"use strict";

const LayerFunctions = function () {
  var getLayerConfig = function (layerName) {
    if (layerName?.includes("CustomLayer")) {
      return getCustomLayerConfigByLayerName(layerName);
    }
    return LayerConfig.get()[layerName];
  };

  var getCustomLayerConfigByLayerName = function (layerName) {
    const customEsriLayerSettings = ToolSettings.getSetting("customEsriLayerSettings");
    const filteredLayer = customEsriLayerSettings?.layers.find(
      (layer) => Misc.toCamelCase(layer.layerName) === layerName.replace("CustomLayer", ""),
    );
    return filteredLayer?.layerConfig;
  };

  var getPropertyForAllLayers = function (property) {
    var allLayers = {};
    for (const layerName in LayerConfig.get()) {
      const layer = LayerConfig.get()[layerName];
      let propertyValue = null;

      if (layer.hasOwnProperty(property)) {
        propertyValue = layer[property];
      }

      allLayers[layerName] = propertyValue;
    }
    return allLayers;
  };

  var getDefaultMapLayers = function () {
    var mapLayers = {};
    for (const layerName in LayerConfig.get()) {
      const mapLayerKey = getLayerKeyByLayerName(layerName);
      mapLayers[mapLayerKey] = null;
    }
    return mapLayers;
  };

  var getLayerKeyByLayerName = function (layerName) {
    return `${layerName}Layer`;
  };

  var getLayerModule = function (layerName) {
    return getLayerProperty(layerName, "module");
  };

  var filterToDoLayer = function (
    layerName,
    mapLayers = MainMap.getMapLayers(),
    map = MainMap.getMap(),
    mapId = "main",
    forceEnabled = false,
  ) {
    var activeTab = Tree.get("activeTab");
    var treeData = Tree.get(["layers", layerName]);
    if (
      (layerName === "bmps" && activeTab === "todo") ||
      (layerName === "lidProject" && activeTab === "todo")
    ) {
      treeData.isEnabled = true;
    }
    const config = getLayerConfig(layerName);
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const dataView = Tree.get("dataView");

    if (
      config?.isEsriLayer ||
      (isGisDataView && dataView !== "bmp" && dataView !== "lid-project")
    ) {
      loadTodoLayer(layerName, mapId);
      return;
    }

    var data;

    if (
      activeTab === "todo" ||
      (activeTab === "data" && dataView === "bmp") ||
      (activeTab === "data" && dataView === "lid-project")
    ) {
      data = getTodoMapData();
    } else {
      data = treeData.data ?? [];
    }

    if (forceEnabled || treeData.isEnabled) {
      setLayerData(layerName, data, mapLayers, map, mapId);
    }
  };

  var loadTodoLayer = function (layerName, mapId) {
    const layerModule = getLayerModule(layerName);

    if (!layerModule.hasOwnProperty("loadLayer")) {
      throw new Error(
        `Layer ${layerName} doesn't have a "loadLayer" function required for Esri layers in the to do maps.`,
      );
    }

    return layerModule.loadLayer(mapId);
  };

  var getTodoMapData = function () {
    const dataView = Tree.get("dataView");
    const todoData = Tree.get(["todos", dataView, "unfilteredData"]) || [];
    const uniqueTodos = {};

    for (const datum of todoData) {
      if (datum.id in uniqueTodos) {
        uniqueTodos[datum.id] = {
          ...datum,
          ...uniqueTodos[datum.id],
        };
      } else {
        uniqueTodos[datum.id] = datum;
      }
    }

    return Object.values(uniqueTodos);
  };

  var setLayerData = function (layerName, data, mapLayers, map, mapId) {
    var activeTab = Tree.get("activeTab");
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    if (isGisDataView) return;
    if (data && data.length) {
      if (activeTab === "todo") {
        const selectedSubject = Tree.get(["todos", Tree.get("dataView"), "selectedSubject"]);

        if (selectedSubject) {
          data = data.filter(function (asset) {
            return ToDoFunctions.getIsIdInTodos(asset.id, selectedSubject);
          });
        } else {
          data = data.filter((asset) => {
            return ToDoFunctions.getIsIdInTodos(asset.id);
          });
        }
      }
    }

    if (layerName === "fcs") {
      mapLayers.fcsLayer = MapFunctions.addFcsLayer(map, mapLayers.fcsLayer, data, mapId);
    } else if (layerName === "bmps") {
      mapLayers.bmpsLayer = MapFunctions.addBmpLayer(map, mapLayers.bmpsLayer, data, mapId);
    } else {
      const layerModule = getLayerModule(layerName);
      if (!layerModule.hasOwnProperty("setLayerData")) {
        console.warn(
          `Layer ${layerName} doesn't have a "setLayerData" function required for to do maps. MapLayer has an implementation you can expose.`,
        );
        return;
      } else {
        return layerModule.setLayerData(map, mapLayers, data, mapId);
      }
    }

    Clustering.setState(layerName);
  };

  var getLayerMarkerById = function (id) {
    if (!id) {
      throw new Error(`Id is required to fetch marker. The Id ${id} is not defined.`);
    }

    return getMarkerIdByLayerConfig(id) ?? getBmpFcsMarkerById(id);
  };

  var getMarkerIdByLayerConfig = function (id) {
    const dataView = Tree.get("dataView");
    if (!dataView) {
      return null;
    }

    const layerName = DataViewFunctions.getCurrentDataViewProperty("defaultLayers")?.[0];
    const LayerModule = getLayerConfig(layerName)?.module;

    if (!LayerModule?.getMarkerReference) {
      console.warn(`No module or getMarkerReference() function configured for ${dataView}.`);
      return null;
    }

    return LayerModule.getMarkerReference(id);
  };

  var fcsMarkers = {};
  var bmpMarkers = {};

  var storeBmpMarker = function (id, marker) {
    bmpMarkers[id] = marker;
  };

  var storeFcsMarker = function (id, marker) {
    fcsMarkers[id] = marker;
  };

  var getBmpFcsMarkerById = function (id) {
    return fcsMarkers[id] || bmpMarkers[id];
  };

  var loadAllLayerMapListeners = function (map, mapLayers, mapId) {
    const modulesArray = Object.values(getPropertyForAllLayers("module")).filter(
      (module) => module !== null,
    );

    for (const module of modulesArray) {
      module.loadLayerListenersForMap(map, mapLayers, mapId);
    }
  };

  var getLayerProperty = function (layerName, property) {
    const result = LayerConfig.get()?.[layerName]?.[property];

    if (result === undefined) {
      throw new Error(`${property} isn't yet configured for layer ${layerName}.`);
    }

    return result;
  };

  var getLayerData = async function (layerName) {
    const data = await new Promise(function (resolve) {
      const data = _getLayerTreeData(layerName);
      if (data !== null) {
        resolve(data);
        return;
      }

      const watcher = Tree.watch({ isFetching: ["layers", layerName, "isFetching"] });
      watcher.on("update", function () {
        const data = _getLayerTreeData(layerName);
        if (data === null) {
          return;
        }
        watcher.off();
        resolve(data);
      });
    });
    return data.slice(0);
  };

  var _getLayerTreeData = function (layerName) {
    const layer = Tree.get(["layers", layerName]);

    if (layer?.isFetching === true) {
      return null;
    }

    return layer?.data ?? [];
  };

  return {
    getLayerConfig,
    getPropertyForAllLayers,
    getDefaultMapLayers,
    getLayerKeyByLayerName,
    getLayerMarkerById,
    getLayerModule,
    filterToDoLayer,
    storeBmpMarker,
    storeFcsMarker,
    getBmpFcsMarkerById,
    getTodoMapData,
    loadAllLayerMapListeners,
    getLayerData,
  };
};

module.exports = LayerFunctions();

const Clustering = require("./mapFunctions/clustering");
const DataViewFunctions = require("./dataViewFunctions");
const LayerConfig = require("./config/layerConfig");
const MainMap = require("./mapFunctions/mainMap");
const MapFunctions = require("./mapFunctions/mapFunctions");
const Misc = require("./misc");
const ToDoFunctions = require("./mapFunctions/toDoFunctions");
const ToolSettings = require("./settings/toolSettings");
const Tree = require("./tree");
