"use strict";

var LidProjectProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "lidProjectProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.lidProjectProgressLayer && sameSpatialFilter && sameView && sameGroup) {
            RamMapFunctions.resizeLayerMarkers(
              mapLayers.lidProjectProgressLayer,
              LidIcon.getLidProjectMapIcon,
            );
            map.addLayer(mapLayers.lidProjectProgressLayer);
            Clustering.setState("lidProjectProgress");
          } else {
            const isFetching = Tree.get([
              "layers",
              Tree.get("mapId"),
              "lidProjectProgress",
              "isFetching",
            ]);
            if (sameSpatialFilter && sameView && isFetching) {
              // Temp solution to prevent laoding twice from dataView and filters update
              return;
            }
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.lidProjectProgressLayer)) {
            map.removeLayer(mapLayers.lidProjectProgressLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");
    if (!filters.catchments.length && filters.searchString) {
      Tree.set(["layers", mapId, "lidProjectProgress", "data"], []);
    } else {
      Tree.set(["layers", mapId, "lidProjectProgress", "isFetching"], true);
      ReportApiCalls.getLidProjectProgress(filters, function (data) {
        Tree.set(["layers", mapId, "lidProjectProgress", "isFetching"], false);
        Tree.set(["layers", mapId, "lidProjectProgress", "data"], data);
        Tree.set(
          ["layers", mapId, "lidProjectProgress", "spatialView"],
          Tree.get("spatialView", mapId),
        );
      });
    }
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "lidProjectProgress", function (data) {
      if (Filters.spatialFilterIsSet()) {
        data = spatiallyFilterLidProjectData(data);
      }
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.lidProjectProgressLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
      Clustering.setState("lidProjectProgress");
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.lidProjectProgressLayer) {
      map.removeLayer(mapLayers.lidProjectProgressLayer);
    }
    mapLayers.lidProjectProgressLayer = createLayer(data);
    LidProjectPopup.loadDomListeners();
    mapLayers.lidProjectProgressLayer.addTo(map);
    return mapLayers.lidProjectProgressLayer;
  };

  var createLayer = function (data) {
    var layer = L.featureGroup();

    L.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        const lidProjectData = feature.properties;
        const marker = L.marker(latlng, {
          icon: LidIcon.getLidProjectMapIcon(lidProjectData),
        });
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => LidProjectPopup.getLidProjectPopupHtml(feature.properties),
          LidProjectPopup.getPopupOptions(),
        );
      },
    }).addTo(layer);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Only watch for specific filters since other filters updates are already handled in treeUpdates
    // @TODO: Investigate why listening to filters update causes an infinite loop while searching
    Tree.watch({
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        Tree.get("dataView") === "lidView" &&
        MapFunctions.mapHasLayer(map, mapLayers.lidProjectProgressLayer) &&
        Tree.get("layers", mapId, "lidProjectProgress", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var spatiallyFilterLidProjectData = function (projectData) {
    const filters = Tree.get("filters");
    return projectData.filter((datum) => {
      return (
        filters.receivingWaters.includes(datum.catchment["drains_to_rw"]) ||
        filters.receivingWaters.includes(datum.catchment["drains_to_c"]) ||
        filters.catchments.includes(datum.catchment["catchid"])
      );
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    createLayer,
  };
};

module.exports = LidProjectProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Table = require("../mapFunctions/table");
const Filters = require("../mapFunctions/filters");
const Clustering = require("../mapFunctions/clustering");
const LidIcon = require("./lidIcon");
const LidProjectPopup = require("./lidProjectPopup");
const RamMapFunctions = require("../../mapFunctions/mapFunctions");
