"use strict";

const MuniCatchBasinIcon = function () {
  var getIconMarker = function (score, isDue, isPriority, zoom = Tree.get("zoomLevel")) {
    const iconClass = getIconClass(score, isDue, isPriority);

    return createIcon(iconClass, zoom);
  };

  var getIconMarkerFromCondition = function (condition, isDue, isPriority) {
    const iconClass = getIconClassFromCondition(condition, isDue, isPriority);

    return createIcon(iconClass);
  };

  var createIcon = function (iconClass, currentZoomLevel = Tree.get("zoomLevel")) {
    const iconSizesArray = [6, 8, 10, 12, 14, 14, 24, 24];
    const updatedIconProps = MapFunctions.resizeIconsOnZoom(
      currentZoomLevel,
      false,
      false,
      false,
      false,
      iconSizesArray,
    );
    return new L.DivIcon({
      className: iconClass,
      iconSize: updatedIconProps.size,
      popupAnchor: [0, 5],
    });
  };

  var getBulkCleanIconMarker = function (isSelected, isFcs, isPriority) {
    const iconClass = getBulkCleanIconClass(isSelected, isFcs, isPriority);

    return createIcon(iconClass);
  };

  var getBulkCleanIconClass = function (isSelected, isPriority) {
    return getIconClass(null, isSelected, isPriority, false);
  };

  var getMuniIconClass = function (data) {
    return getIconClass(data.score, data.routineMaintenanceDue, data.isCatchBasinHighPriority);
  };

  var getIconClass = function (score, isDue, isPriority) {
    const condition = getIconCondition(score);
    return getIconClassFromCondition(condition, isDue, isPriority);
  };

  var getIconClassFromCondition = function (condition, isDue, isPriority) {
    const classParts = ["catch-basin"];

    if (isDue) {
      classParts.push("due");
    } else if (condition !== null) {
      classParts.push(condition);
    }

    if (isPriority) {
      classParts.push("priority");
    }

    const toSend = classParts.join("-");
    return toSend;
  };

  var getIconCondition = function (score) {
    var condition = MapStyles.getConditionFromScore(score);

    if (condition !== null) {
      condition = condition === "poor" ? "poor" : "optimal";
    }

    return condition;
  };

  var getIconColor = function (score, isDue, hex = false) {
    if (isDue) {
      return hex ? MapStyles.getHexColors().blue : "blue";
    }

    return MapStyles.getColorFromCondition(getIconCondition(score), hex);
  };

  var getUnsavedIconMarker = function (zoom) {
    return createIcon(getIconClass(), zoom);
  };

  return {
    getIconMarker,
    getIconMarkerFromCondition,
    getUnsavedIconMarker,
    getIconClass,
    getBulkCleanIconMarker,
    getBulkCleanIconClass,
    getIconCondition,
    getIconColor,
    getMuniIconClass,
    createIcon,
  };
};

module.exports = MuniCatchBasinIcon();

const MapFunctions = require("../mapFunctions/mapFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const Tree = require("../tree");
