"use strict";

var InputsLayerFunctions = function () {
  var getFormattedKpiData = function (data) {
    const report = AnnualReports.getYearReports(
      AnnualReports.getSelectedYear(),
      "stormwater group",
    )[0];
    data.year = report.reportingYear;
    data.dataRangeFirst = report.dataRangeFirst;
    data.dataRangeLast = report.dataRangeLast;
    data.displayDataRangeFirst = report.displayDataRangeFirst;
    data.displayDataRangeLast = report.displayDataRangeLast;
    return [data];
  };

  return {
    getFormattedKpiData,
  };
};

module.exports = InputsLayerFunctions();

const AnnualReports = require("../annualReports");
