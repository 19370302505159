"use strict";

const IncidentsIcon = function () {
  var getIncidentsNewMarkerIcon = function () {
    return getIncidentsIcon(getIncidentsIconClass("new"));
  };

  var getIncidentsIconClass = function (status) {
    return `idde-incident-${status}`;
  };

  var getIconColor = function (incidentStatus) {
    if (incidentStatus === "closed") {
      return "black";
    } else if (incidentStatus === "open") {
      return "red";
    }
    return;
  };

  var getIncidentsMarkerIcon = function (properties) {
    const iconClass = getIncidentsIconClass(properties.incidentStatus);

    return getIncidentsIcon(iconClass);
  };

  var getIncidentsIcon = function (iconClass) {
    const zoom = ReportMap.getActiveReportMap()?.getZoom();
    const updatedIconSizeOptions = RamMapFunctions.resizeIconsOnZoom(zoom, 40, 20, false, false);
    return L.divIcon({
      iconSize: updatedIconSizeOptions.size,
      className: iconClass,
    });
  };

  return {
    getIncidentsNewMarkerIcon,
    getIncidentsMarkerIcon,
    getIconColor,
    getIncidentsIconClass,
  };
};

module.exports = IncidentsIcon();

const RamMapFunctions = require("../../mapFunctions/mapFunctions");
const ReportMap = require("../report/reportMap");
