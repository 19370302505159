"use strict";

function ToDoPrioritizeController() {
  let $container;
  let multiStepForm;
  let dataById;
  let toDoDataById;
  const formKey = "muni-catch-basin-to-do-prioritize";
  const toDoSubject = "routine-site-visit";

  async function open() {
    goToPage();
    _setData(await _fetchData());
    await render();
    Analytics.sendAction(["to-do", "prioritize", toDoSubject]);
  }

  function goToPage() {
    PageFunctions.goToPage("fullPage");
    NavToggle.showBackButton("Task Prioritization", close);
  }

  async function render() {
    multiStepForm = new MultiStepForm(formKey, getFormValidationErrors, getDataReviewHtml, {
      saveCallback: _saveCallback,
      cancelCallback,
    });

    $container = $("#full-page");
    multiStepForm.init($container, getSteps());
    _loadListeners();
  }

  function getSteps() {
    return [SelectPage.getStep(), ReviewPage.getStep()];
  }

  function _loadListeners() {
    $(window).on("resize", recalculateStyles);
  }

  function _unloadListeners() {
    $(window).off("resize", recalculateStyles);
  }

  function close() {
    UrlRoutes.navigateToDefault();
    leave();
  }

  async function promptRemoveSelection(selectedIds) {
    try {
      const html = nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/removeWarning.njk", {
        selectedCount: selectedIds.length,
      });
      await MessageModal.showConfirmWarningModalAsPromise(html, {
        okMessage: "Remove",
        returnMessage: "Cancel",
      });
    } catch (e) {
      return false;
    }

    setDue(selectedIds, false, null);
    return true;
  }

  function leave() {
    _unloadListeners();
    SelectPage.destroy();
    ReviewPage.destroy();
    $container.empty();
    dataById = null;
    toDoDataById = null;
    multiStepForm = null;
    Form.clearForm();

    PageFunctions.goToPage("todo");
    NavToggle.hideBackButton();
  }

  function getFormValidationErrors() {
    // @TODO: implement
    return [];
  }

  function getDataReviewHtml() {
    return nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/dataReview.njk", {
      toDoCount: toDoDataById.size,
    });
  }

  async function _saveCallback() {
    const updates = toDoDataById
      .values()
      .toArray()
      .map(function (datum) {
        return {
          id: datum.id,
          dueDate: datum.dueDate,
          groupId: datum.groupId,
        };
      });

    ToDoList.setToDoSubjectCustom(toDoSubject, true);
    await ApiCalls.setCustomToDoList(updates, toDoSubject);
    close();
    MuniCatchBasinGeoServerLayer.invalidateLayerData();
  }

  function cancelCallback() {
    // @TODO: implement
    close();
  }

  function _setData(newData) {
    dataById = new Map();
    toDoDataById = new Map();

    for (let datum of newData) {
      datum = {
        ...datum,
        isDue: datum.routineSiteVisit.isDue,
        dueDate: datum.routineSiteVisit.dueDate,
        isSelected: false,
      };
      dataById.set(datum.id, datum);

      if (datum.isDue) {
        toDoDataById.set(datum.id, datum);
      }
    }
  }

  async function _fetchData() {
    return await ResourceController.get("muniCatchBasin").getAll();
  }

  function getFormKey() {
    return formKey;
  }

  function getDataById() {
    return dataById;
  }

  function getToDoDataById() {
    return toDoDataById;
  }

  function setDue(ids, isDue, dueDate = null) {
    for (const id of ids) {
      const datum = dataById.get(id);
      datum.isDue = isDue;
      datum.dueDate = dueDate;

      if (isDue) {
        toDoDataById.set(datum.id, datum);
      } else {
        toDoDataById.delete(datum.id);
      }
    }

    multiStepForm.renderDataReview();
  }

  function _getMultiStepForm() {
    return multiStepForm;
  }

  function recalculateStyles() {
    const $wizardContainer = $container.find("#wizard-main-content");
    const containerRect = $wizardContainer[0].getBoundingClientRect();

    const $prioritizeContainer = $container.find(
      ".current .to-do-prioritize, .current .to-do-prioritize-review",
    );
    const prioritizeRect = $prioritizeContainer[0].getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--js-todo-prioritize-height",
      containerRect.bottom - prioritizeRect.top + "px",
    );
  }

  return {
    open,
    leave,
    getDataReviewHtml,
    getSteps,
    getFormKey,
    getDataById,
    getToDoDataById,
    setDue,
    promptRemoveSelection,
    recalculateStyles,
    _setData,
    _getMultiStepForm,
    _saveCallback,
  };
}

module.exports = ToDoPrioritizeController();

const NavToggle = require("../../mapFunctions/navToggle");
const PageFunctions = require("../../pageFunctions");
const UrlRoutes = require("../../routes/urlRoutes");
const MultiStepForm = require("../../general/multiStepForm");
const ResourceController = require("../../offline/resourceController");
const Form = require("../../general/form");
const SelectPage = require("./selectPage");
const ReviewPage = require("./reviewPage");
const MessageModal = require("../../modals/messageModal");
const ApiCalls = require("../../apiCalls");
const MuniCatchBasinGeoServerLayer = require("../muniCatchBasinGeoServerLayer");
const ToDoList = require("../../mapFunctions/toDoList");
const Analytics = require("../../general/analytics");
