"use strict";

const MuniCulvertFilters = function () {
  const searchDbFields = ["name"];
  const searchFields = ["name"];
  let mainSmartFilters;

  var filterResourceDataHandler = (unfilteredData) =>
    mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  var addGeoServerViewParams = function (params, filters) {
    GeoServerFilterFunctions.addSpatialFilterViewParams(params, filters);

    if (!FilterSummary.areAllCheckboxesChecked("correctiveAction")) {
      params["correctiveAction"] = filters.correctiveAction.map((item) => `'${item}'`).join("\\,");
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("correctiveAction") &&
      $('input[name="correctiveAction"][value="no-action"]').is(":checked")
    ) {
      params[`includeNoAction`] = true;
    }
    if (!FilterSummary.areAllCheckboxesChecked("enforcementLevel")) {
      params["enforcementLevel"] = filters.enforcementLevel.map((item) => `'${item}'`).join("\\,");
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("enforcementLevel") &&
      $('input[name="enforcementLevel"][value="null"]').is(":checked")
    ) {
      params[`includeNoActionLevel`] = true;
    }
    ResourceFilters.addDateParam(
      params,
      "routine",
      filters.routineDate?.from,
      filters.routineDate?.to,
    );
    ResourceFilters.addDateParam(
      params,
      "repair",
      filters.repairDate?.from,
      filters.repairDate?.to,
    );
  };

  var isOnlineDateFilterSet = function (filters) {
    return false;
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementerType",
      "implementer_type",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "responsiblePartyMaintenance",
      "responsible_party_maintenance",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "landOwnership",
      "property",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "culvertType",
      "type",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "serviceType",
      "line_service_type",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementationPhase",
      "phase",
    );

    if (
      filters.accessible?.length !== undefined &&
      !FilterSummary.areAllCheckboxesChecked("accessible")
    ) {
      GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "accessible",
        queryStrings,
        "accessible",
        filters.accessible,
      );
    }
    if (filters.lined?.length !== undefined && !FilterSummary.areAllCheckboxesChecked("lined")) {
      GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "lined",
        queryStrings,
        "lined",
        filters.lined,
      );
    }

    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "install_date",
        filters.installDate?.from,
        filters.installDate?.to,
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("condition")) {
      GeoServerFilterFunctions.addConditionFilterToGeoServerQuery(queryStrings, filters);
    }

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "cof",
      "cof_final_text",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "shape", "shape");
    const lengthWidthQueries = [];
    const diameterQuery = [];
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "diameter",
      filters.diameterRange,
      "diameter_units",
      diameterQuery,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "length",
      filters.lengthRange,
      "length_units",
      lengthWidthQueries,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "width",
      filters.widthRange,
      "width_units",
      lengthWidthQueries,
    );

    const shapeDbColumnName = "shape";
    const indexOfShapeQuery = MeasurementMinMax.findIndexOfShapeQuery(
      queryStrings,
      shapeDbColumnName,
    );
    GeoServerFilterFunctions.createMinMaxMeasurmentCombinedQuery(
      queryStrings,
      lengthWidthQueries,
      diameterQuery,
      indexOfShapeQuery,
      shapeDbColumnName,
    );
  };

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, zoneOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      responsiblePartyOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      landOwnershipOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      culvertTypeOptions: FilterConstants.culvertType,
      serviceTypeOptions: FilterConstants.serviceType,
      implementationPhaseOptions: FilterConstants.muniImplementationPhaseOptions,
      accessibleOptions: FilterConstants.yesNoOptions,
      conditionOptions: FilterConstants.conditionOptions,
      cofOptions: FilterConstants.cofOptionsWithNull,
      shapeOptions: FilterConstants.linnedAssetsShapes,
      correctiveOptions: FilterConstants.getAllCorrectiveOptions(),
      enforcementLevel: FilterConstants.getEnforcementLevel(),
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      zoneOptions,
      yesNoOptions: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
        {
          name: "No Data",
          value: "null",
        },
      ],
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      implementerType: "implementerType",
      responsiblePartyMaintenance: "responsiblePartyMaintenance",
      landOwnership: "landOwnership",
      culvertType: "culvertType",
      serviceType: "lineServiceType",
      implementationPhase: "phase",
      accessible: "accessible",
      condition: "condition",
      cof: "cof",
      drainsToRw: ["drains_to_rws", "array"],
      drainsToC: ["drains_to_cs", "array"],
      catchmentName: ["catchment_names", "array"],
      catchments: ["catchment_names", "array"],
      zones: ["zone_names", "array"],
      maintenanceZones: ["zone_names", "array"],
      correctiveAction: ["correctiveAction", "array"],
      enforcementLevel: ["enforcementLevel", "array"],
      shape: "shape",
      lined: "lined",
      subGroups: "groupId",

      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          MeasurementMinMax.passDiameterFilter(
            datum.shape,
            filters.shape,
            datum.diameter,
            datum.diameterUnits,
            { max: filters.diameterRange?.max, min: filters.diameterRange?.min },
          ) &&
          MeasurementMinMax.passLengthWidthFilter(
            datum.shape,
            filters.shape,
            datum.length,
            datum.lengthUnits,
            datum.width,
            datum.widthUnits,
            { max: filters.lengthRange?.max, min: filters.lengthRange?.min },
            { max: filters.widthRange?.max, min: filters.widthRange?.min },
          ) &&
          passInstalledDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    isOnlineDateFilterSet,
    addGeoServerFilterQuery,
    getProps,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = MuniCulvertFilters();

const ResourceFilters = require("../../filters/resourceFilters");
const GeoServerFilterFunctions = require("../../mapFunctions/geoServerFilterFunctions");
const FilterConstants = require("../../filterConstants");
const FilterSummary = require("../../filters/filterSummary");
const MeasurementMinMax = require("../../general/measurementMinMax");
const Filters = require("../../mapFunctions/filters");
const Progeny = require("../../login/progeny");
