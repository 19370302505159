"use strict";

const TreeInitialDataCommon = function () {
  const getStaticData = function () {
    return {};
  };

  return { getStaticData };
};

module.exports = TreeInitialDataCommon();
