"use strict";

const AssetsPofDynamicMapLayer = function () {
  const layerName = "assetsAssetsEsriDynamicMap";
  const layerKey = "assetsAssetsEsriDynamicMapLayer";
  const esriKey = "probability-failure";
  const esriLayer = "probability-failure";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsAssetsEsriDynamicMapLayer = new EsriDynamicMapLayer(
      layerName,
      layerKey,
      esriKey,
      esriLayer,
    );
    assetsAssetsEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsAssetsEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };
  var loadLayer = function () {};

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = AssetsPofDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
