"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetSimpleNumber = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [
      {
        className: "simple-number-container",
        getHoverHtml: InsightWidgetSimpleNumber.prototype.getHoverHtml,
      },
    ];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 1;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetSimpleNumber.prototype = Object.create(InsightWidget.prototype);
InsightWidgetSimpleNumber.prototype.constructor = InsightWidgetSimpleNumber;

InsightWidgetSimpleNumber.prototype.getHtml = function (insightData) {
  var focusDataNumber = this.widgetConfig.getDataNumber(insightData, "focus");
  var comparisonDataNumber = this.widgetConfig.getDataNumber(insightData, "comparison");

  var focusDataUnit = this.widgetConfig.getDataUnit(insightData, "focus");
  var comparisonDataUnit = this.widgetConfig.getDataUnit(insightData, "comparison");

  var focusSubtitle = this.widgetConfig.getSubtitle(insightData, "focus");
  var comparisonSubtitle = this.widgetConfig.getSubtitle(insightData, "comparison");

  var trendIconClass = this.getTrendIconClass(focusDataNumber, comparisonDataNumber);

  if (this.widgetConfig.convertDataNumberFunction) {
    focusDataNumber = this.widgetConfig.convertDataNumberFunction(focusDataNumber);
    comparisonDataNumber = this.widgetConfig.convertDataNumberFunction(comparisonDataNumber);
  }

  var dataDecimalPlaces =
    this.widgetConfig.dataDecimalPlaces !== undefined ? this.widgetConfig.dataDecimalPlaces : 1;

  var html = nunjucks.render("insight/widgets/simpleNumber.njk", {
    title: this.widgetConfig.title,
    focusDataNumber,
    comparisonDataNumber,
    focusDataUnit,
    comparisonDataUnit,
    focusSubtitle,
    comparisonSubtitle,
    trendIconClass,
    dataDecimalPlaces,
    hideTrendIcon: this.widgetConfig.hideTrendIcon,
    simpleText: this.widgetConfig.simpleText,
  });

  return html;
};

InsightWidgetSimpleNumber.prototype.getHoverHtml = function (insightData) {
  if (this.widgetConfig.getCustomHoverData) {
    const hoverData = this.widgetConfig.getCustomHoverData(insightData),
      template = hoverData.template;

    return nunjucks.render(template, {
      ...hoverData,
    });
  }

  var hoverTitle = this.widgetConfig.getHoverTitle();
  var hoverDescription = this.widgetConfig.getHoverDescription();

  var focusDataNumber = this.widgetConfig.getDataNumber(insightData, "focus");
  var comparisonDataNumber = this.widgetConfig.getDataNumber(insightData, "comparison");
  var trendIconClass = this.getTrendIconClass(focusDataNumber, comparisonDataNumber);
  var trendPercentDiff = this.widgetConfig.showFocusOverComparisonPercent
    ? this.getFocusOverComparisonPercent(focusDataNumber, comparisonDataNumber)
    : this.getTrendPercentDiff(focusDataNumber, comparisonDataNumber);

  return nunjucks.render("insight/widgets/simpleNumberHover.njk", {
    hoverTitle,
    hoverDescription,
    trendIconClass,
    trendPercentDiff,
    hideTrendIcon: this.widgetConfig.hideTrendIcon || this.widgetConfig.hideHoverTrendIcon,
  });
};

module.exports = InsightWidgetSimpleNumber;
