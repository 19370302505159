"use strict";

const IddeOutfallLayer = function () {
  const ApiCalls = require("../apiCalls");
  const DryWeatherLayer = require("./dryWeatherLayer");

  const layerName = "iddeOutfall";
  const layerKey = "iddeOutfallLayer";

  var getIddeOutfallPopup = function (id) {
    return ApiCalls.getIddeDryWeatherPopup(id, "outfall");
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    iddeOutfallLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    iddeOutfallLayer.loadDataUpdatedListenerForMap(map, mapLayers, mapId);

    if (mapId === "main") {
      iddeOutfallLayer.loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  const iddeOutfallLayer = new DryWeatherLayer(
    layerName,
    layerKey,
    ApiCalls.getDryWeatherOutfalls,
    getIddeOutfallPopup,
    false,
    true,
  );

  return {
    ...iddeOutfallLayer,
    loadLayerListenersForMap,
  };
};

module.exports = IddeOutfallLayer();
