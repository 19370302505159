"use strict";

const LidProjectPopup = function () {
  var lidHistory = null;

  var loadDomListeners = function () {
    $(".map-content").off("click", ".popup-history-button");
    $(".map-content").on("click", ".popup-history-button", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
  };

  var getLidProjectPopupHtml = function (project) {
    var popupProps = getLidProjctPopupProps(project);
    var props = $.extend({}, project, popupProps);
    lidHistory = props.lidHistory;
    return nunjucks.render("report/popups/lidProjectPopup.njk", props);
  };

  var getLidProjctPopupProps = function (project) {
    var props = {};

    props.popupColor = RamLidProjectStyles.getNewLidColor(project);
    props.tierNumber = RamLidIcon.getLidProjectTierNumber(project);
    props.allPerformanceTierString = RamLidProjectPopup.getAllPerformanceTierString(project);
    props.functionalBmpPercent = getFunctionalBmpPercent(project);

    return props;
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();
    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-lid-history").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-history-button").show();
    $parent.find(".popup-lid-main").show();
  };

  var popupHistoryButtonClick = function () {
    showInspectionHistory();
  };

  var showInspectionHistory = function () {
    var props = getInspectionHistoryProps();
    $(".popup-lid-history").html(nunjucks.render("report/popups/lidInspectionHistory.njk", props));
    $(".popup-lid-main").hide();
    $(".popup-history-button").hide();
    $(".popup-lid-history").show();
    $(".popup-back-button").show();
  };

  var getInspectionHistoryProps = function () {
    const props = {};
    props.inspections = lidHistory;
    return props;
  };

  var getFunctionalBmpPercent = function (project) {
    if (project.assessedBmpCount > 0) {
      return (project.functionalBmpCount / project.associatedBmpCount) * 100;
    }

    return "—";
  };

  return {
    loadDomListeners,
    getLidProjectPopupHtml,
    getPopupOptions,
  };
};

module.exports = LidProjectPopup();

const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
// Ram modules
const RamLidProjectPopup = require("../../lid/lidProjectPopup");
const RamLidProjectStyles = require("../../lid/lidProjectStyles");
const RamLidIcon = require("../../lid/lidIcon");
