"use strict";

const ProjectInspectionAdministrativeFindings = function () {
  const stringKey = "administrative-findings";
  const tabHeader = "Administrative Findings";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on(
      "input",
      "[name='administrative-finding-practice']",
      _onAdministrativeFindingsPracticeChange,
    );
    $page.on(
      "click",
      "[name='add-administrative-finding-practice']",
      _onAddAdministrativeFindingPracticeButtonClick,
    );
    $page.on("click", "[name=add-administrative-finding]", _onAddFinding);
    $page.on("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var unloadListeners = function () {
    $page.off(
      "input",
      "[name='administrative-finding-practice']",
      _onAdministrativeFindingsPracticeChange,
    );
    $page.off(
      "click",
      "[name='add-administrative-finding-practice']",
      _onAddAdministrativeFindingPracticeButtonClick,
    );
    $page.off("click", "[name=add-administrative-finding]", _onAddFinding);
    $page.off("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var getProps = function (data) {
    return {
      administrativeFindingPracticeOptions: getUnusedPracticeOptions(),
      findingSeverityOptions: ProjectInspectionConstants.getFindingSeverityOptions(),
      administrativeFindingsOptions: ProjectInspectionConstants.getAdministrativeFindingsOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var replaceFindingPractice = function () {
    const $practice = $page.find("[name=administrative-finding-practice]").closest("fieldset");

    const html = nunjucks.render(
      "modals/projectInspection/templates/administrativeFindingsPractice.njk",
      {
        props: getProps(),
      },
    );

    return $practice.replaceWith(html);
  };

  var getUnusedPracticeOptions = function () {
    const data = ProjectInspectionController.getAllData();
    const allOptions = ProjectInspectionConstants.getAdministrativeFindingPracticeOptions();
    const allExistingFindings = Object.keys(data.administrativeFindings);

    return allOptions.filter((option) => !allExistingFindings.includes(option.value));
  };

  var _onAdministrativeFindingsPracticeChange = function () {
    const practice = $(this).val();
    const $button = $page.find("[name=add-administrative-finding-practice]");

    updateAddFindingButton($button, practice);
  };

  var updateAddFindingButton = function ($button, practice) {
    $button.prop("disabled", !practice);
    $button.data("practice", practice);
  };

  var _onAddAdministrativeFindingPracticeButtonClick = function () {
    const $button = $(this);
    const practice = $button.data("practice");

    updateAddFindingButton($button, null);
    addAdditionalFinding(practice);
    replaceFindingPractice();
  };

  var addAdditionalFinding = function (practice) {
    const html = nunjucks.render("modals/projectInspection/templates/administrativeFinding.njk", {
      props: getProps(),
      practice,
      findings: [
        TemporaryBmpObservation.createNewTemporaryBmpInspection("administrativeFindings", practice),
      ],
      overideCardState: "open",
    });
    const $newObservation = $(html);

    $page.find("section").eq(1).prepend($newObservation);
    TemporaryBmpObservation.initializeTemporaryBmpInspection($newObservation);
    TemporaryBmpObservation.initializeTemporaryBmpInspection($newObservation.find(".card"));
  };

  var _onAddFinding = function () {
    const $button = $(this);
    TemporaryBmpObservation.addAdministrativeFinding($button, "administrativeFindings");
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _onAdministrativeFindingsPracticeChange,
    _onAddAdministrativeFindingPracticeButtonClick,
  };
};

module.exports = ProjectInspectionAdministrativeFindings();

const ProjectInspectionConstants = require("./projectInspectionConstants");
const ProjectInspectionController = require("./projectInspectionController");
const TemporaryBmpObservation = require("./temporaryBmpObservation");
