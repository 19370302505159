"use strict";

const SmartFilters = function (
  filterConfigs,
  {
    defaultFilters = {},
    treePath = "filters",
    beforeFilter = (e) => {},
    afterFilter = (filters) => {},
    onSetDefault = () => {},
  } = {},
) {
  const optionsThatMeanNull = ["null", "", "no-action"];
  const supportedFunctionTypes = ["string", "array"]; // _getFilterFunction
  const defaultFunctionType = "string";
  const maxOptionsToCount = 2000;
  let $container;
  let tallyAbortController;

  let data; // Needed for tallying
  let allOptions;
  let filterFunctions;
  let filtersHaveBeenHidden;
  let initialFiltersFromHtmlJson;

  const Tree = require("../tree");
  Tree.set(treePath, defaultFilters);

  const filter = function (unfilteredData) {
    if (unfilteredData) {
      data = unfilteredData;
      _updateHtml();
    }

    return _filterData(_getFilters());
  };

  const destroy = function () {
    _unloadListeners();
    $container = null;
    data = null;
    allOptions = null;
    filterFunctions = null;
    filtersHaveBeenHidden = null;
    initialFiltersFromHtmlJson = null;
  };

  const _loadListeners = function () {
    _unloadListeners();

    MultipleSelect.init($container);

    $container
      .find("fieldset .datepicker")
      .datetimepicker?.({
        format: "MM/DD/YYYY",
        useCurrent: false,
        showClose: true,
      })
      ?.on("dp.change", _updateDateRange);

    _setDefaultDateRange();

    $container
      .on("input", "input[type=checkbox]", _updateFilters)
      .on("blur", "input[name][type=text]", _updateFilters)
      .on("blur", "input[name][type=number]", _updateFilters)
      .on("change", ".unit-select", _updateFilters)
      .on("click", ".reset-to-default-button", setToDefault)
      .on(
        "click",
        ".multiple-select:not(.waiting-for-result) .glyphicon-search, .selected-items",
        _expandMultipleSelect,
      );

    Tree.select(treePath).on("update", _onAfterFilter);
  };

  const _setDefaultDateRange = function () {
    $container.find(".date-range").each((index, element) => {
      const $dateTo = $(element).find('[name*="[to]"]');
      const dateFilterName = $dateTo.attr("name")?.slice(0, -4);

      const dateFilterTreeData = Tree.get(treePath)?.[dateFilterName];
      if (dateFilterTreeData?.from) {
        $container
          .find(`input[name="${dateFilterName}[from]"]`)
          .data("DateTimePicker")
          .date(new Date(dateFilterTreeData.from));
      }
      if (dateFilterTreeData?.to) {
        $container
          .find(`input[name="${dateFilterName}[to]"]`)
          .data("DateTimePicker")
          .date(new Date(dateFilterTreeData.to));
      }
    });
  };

  const _unloadListeners = function () {
    Tree.select(treePath).off("update", _onAfterFilter);

    if (!$container) {
      return;
    }

    $container.find("fieldset .datepicker").off("dp.change", _updateDateRange);
    $container
      .off("input", "input[type=checkbox]", _updateFilters)
      .off("blur", "input[name][type=text]", _updateFilters)
      .off("blur", "input[name][type=number]", _updateFilters)
      .off("change", ".unit-select", _updateFilters)
      .off("click", ".reset-to-default-button")
      .off(
        "click",
        ".multiple-select:not(.waiting-for-result) .glyphicon-search, .selected-items",
        _expandMultipleSelect,
      );
  };

  const _generateTally = async function (filtersToTally = []) {
    const signal = _resetAndInitAbortController();

    const tally = {};
    if (!filtersToTally.length || !filterFunctions) return tally;
    _updateHtml();

    for (const filterKey of filtersToTally) {
      const filterFunction = filterFunctions?.[filterKey];

      if (!filterFunction || allOptions[filterKey]?.length > maxOptionsToCount) {
        return;
      }

      const filterTally = {};
      // Creating objects is slow, so instead we reuse the same object
      const filterObj = { [filterKey]: [] };

      const preFilteredData = _filterData(_allFiltersExcept(filterKey));
      for (const datum of preFilteredData.length ? preFilteredData : [{}]) {
        try {
          await yieldOrContinue("interactive", signal);
        } catch (e) {
          return;
        }
        allOptions[filterKey]?.forEach((option) => {
          if (!(option in filterTally)) {
            filterTally[option] = 0;
          }

          filterObj[filterKey] = [option];
          if (filterFunction(datum, filterObj)) {
            filterTally[option]++;
          }
        });
      }

      tally[filterKey] = filterTally;
    }

    return tally;
  };

  const _allFiltersExcept = function (filterKey) {
    const filters = { ..._getFilters() };
    delete filters[filterKey];
    return filters;
  };

  const _updateHtml = function () {
    if (!$container) {
      return;
    }
    _updateResetButton();
    _hideFiltersWithNoData();
    _setAllOptions();
  };

  const init = function ($newContainer) {
    $container = $newContainer;

    _populateFilterFunctions();
    _loadListeners();
    initialFiltersFromHtmlJson ||= JSON.stringify(_getFiltersFromHtml());
  };

  const _hideFiltersWithNoData = function () {
    if (data && !filtersHaveBeenHidden) filtersHaveBeenHidden = MultipleSelect.hideNoData(data);
  };

  const _setAllOptions = function () {
    const _allOptions = MultipleSelect.getData(true);
    if (Object.keys(_allOptions).length) allOptions = _allOptions;
  };

  const _populateFilterFunctions = function () {
    if (filterFunctions) return;
    filterFunctions = {};

    for (const filterKey in filterConfigs) {
      const filterConfig = filterConfigs[filterKey];
      let columnName;
      let functionType;

      if (typeof filterConfig === "function") {
        filterFunctions[filterKey] = filterConfig;
        continue;
      }

      if (typeof filterConfig === "string") {
        [columnName, functionType] = [filterConfig, defaultFunctionType];
      } else if (
        Array.isArray(filterConfig) &&
        filterConfig.length === 2 &&
        supportedFunctionTypes.includes(filterConfig[1])
      ) {
        [columnName, functionType] = filterConfig;
      }

      if (!functionType) throw new Error(_errorMessage(filterKey));

      filterFunctions[filterKey] = _getFilterFunction(filterKey, columnName, functionType);
    }
  };

  const _expandMultipleSelect = async function () {
    const $multipleSelect = $(this).closest(".multiple-select");
    _tally([$multipleSelect.data("name")]);
  };

  const _tally = async function (filtersToTally) {
    const tally = await LoadingScreen.waitForResult(
      $container.find(".multiple-select[data-name='" + filtersToTally[0] + "']"),
      _generateTally(filtersToTally),
    );
    MultipleSelect.addTallyToOptions(tally);
  };

  const _getFilters = () => Tree.get(treePath);

  const _filterData = function (filters) {
    return (data || []).filter((datum) =>
      Object.values(filterFunctions || {}).every((fn) => fn(datum, filters)),
    );
  };

  const _filterNotNeeded = function (filters, filterKey) {
    const optionsCount = filters?.[filterKey]?.length;
    if (["catchments", "maintenanceZones"].includes(filterKey) && optionsCount) return false;
    return (
      !allOptions ||
      optionsCount === 0 ||
      !allOptions?.[filterKey] ||
      optionsCount === undefined ||
      optionsCount === allOptions?.[filterKey]?.length
    );
  };

  const _getFilterFunction = function (filterKey, columnName, functionType) {
    return {
      string: (datum, filters) =>
        _filterNotNeeded(filters, filterKey) ||
        filters[filterKey].includes(String(datum[columnName])) ||
        (datum[columnName] === null && filters[filterKey].some((option) => _isNullOption(option))),

      array: (datum, filters) =>
        _filterNotNeeded(filters, filterKey) ||
        filters[filterKey].some((option) =>
          _isNullOption(option) || GeoServerFilterFunctions.isOutsideBoundary(option)
            ? !datum[columnName] || datum[columnName].includes(null)
            : datum[columnName]?.map((item) => String(item)).includes(option),
        ),
    }[functionType];
  };

  const _isNullOption = function (option) {
    return optionsThatMeanNull.includes(option);
  };

  const _resetAndInitAbortController = () => {
    if (tallyAbortController) tallyAbortController.abort();
    tallyAbortController = new AbortController();
    return tallyAbortController.signal;
  };

  const _errorMessage = function (filterKey) {
    return `Invalid filter config for "${filterKey}". Should be either:
       (1) a function (datum, filters) => boolean
       (2) a string columnName (will use default functionType "${defaultFunctionType}") or
       (3) an array [columnName, functionType]. Supported functionTypes are ${supportedFunctionTypes
         .map((t) => `"${t}"`)
         .join(", ")}`;
  };

  const _updateDateRange = function (e) {
    const name = $(this).closest(".date-range").data("name");
    const toFrom = $(this).hasClass("from") ? "from" : "to";
    const date = DateRange.getData()[name][toFrom];

    _triggerBeforeFilter(e);
    Tree.set([treePath, name, toFrom], date);
  };

  const _updateFilters = function (e) {
    const newFilters = {
      ...Tree.get(treePath),
      ..._getFiltersFromHtml(),
    };

    _triggerBeforeFilter(e);
    Tree.set(treePath, newFilters);
    MeasurementMinMax.updateStatusOfFilter(newFilters);
  };

  const _triggerBeforeFilter = function (e) {
    if (beforeFilter) {
      beforeFilter(e);
    }
    _updateResetButton();
  };

  const _getFiltersFromHtml = function () {
    return {
      ...MultipleSelect.getData(),
      ...MinMax.getData(),
      ...MeasurementMinMax.getData(),
      ...DateRange.getData(),
      ..._getTextInputs(),
    };
  };

  const _getTextInputs = function () {
    const textInputs = {};
    $container.find(`fieldset > input[name][type="text"]`).each(function () {
      textInputs[$(this).attr("name")] = $(this).val();
    });
    return textInputs;
  };

  const setToDefault = function () {
    Tree.set(treePath, defaultFilters);
    _updateHtml();
    onSetDefault();
  };

  const _updateResetButton = function () {
    if (initialFiltersFromHtmlJson) {
      const $button = $container.find(".reset-to-default-button");
      const isDefault = JSON.stringify(_getFiltersFromHtml()) === initialFiltersFromHtmlJson;
      $button.attr("disabled", isDefault);
    }
  };

  const _onAfterFilter = function (e) {
    afterFilter(e.data.currentData);
  };

  const getTreePath = function () {
    return treePath;
  };

  return {
    filter,
    init,
    setToDefault,
    getTreePath,
    destroy,
    _generateTally,
    _filterNotNeeded,
  };
};

module.exports = SmartFilters;

const MultipleSelect = require("../general/multipleSelect");
const { yieldOrContinue } = require("main-thread-scheduling");
const LoadingScreen = require("../general/loadingScreen");
const GeoServerFilterFunctions = require("./geoServerFilterFunctions");
const DateRange = require("../filters/dateRange");
const MeasurementMinMax = require("../general/measurementMinMax");
const MinMax = require("../general/minMax");
