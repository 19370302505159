"use strict";

var RunoffRatioProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadColumnArrayUpdatedListener(mapId);
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "runoffRatioProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        var runoffRatioLayer = mapLayers.runoffRatioProgressLayer;
        var forceRefresh = !sameSpatialFilter || !sameView || !sameGroup;
        if (isEnabled) {
          TelrLayerFunctions.displayTelrLayer(
            runoffRatioLayer,
            "runoffRatioProgress",
            "percentReduction",
            map,
            mapLayers,
            forceRefresh,
          );
        } else {
          if (MapFunctions.mapHasLayer(map, runoffRatioLayer)) {
            map.removeLayer(runoffRatioLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "runoffRatioProgress", function (data) {
      mapLayers.runoffRatioProgressLayer = addRunoffRatioProgressLayer(
        map,
        mapLayers.runoffRatioProgressLayer,
        data,
      );
      TelrLayerFunctions.selectTelrCatchmentsByFilter(mapLayers.runoffRatioProgressLayer);
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var addRunoffRatioProgressLayer = function (map, runoffRatioProgressLayer, layerData) {
    if (runoffRatioProgressLayer) {
      map.removeLayer(runoffRatioProgressLayer);
    }
    runoffRatioProgressLayer = new (Layers.getLayers().RunoffRatioLayer)(layerData).addTo(map);

    return runoffRatioProgressLayer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {};

  var loadColumnArrayUpdatedListener = function (mapId) {
    Tree.select(["layers", mapId, "runoffRatioProgress", "columnArray"]).on("update", function (e) {
      var columns = e.data.currentData;
      DataViewDropdown.updateLegendColumns(columns, "runoff-ratio-progress", true);
    });
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = RunoffRatioProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const Table = require("../mapFunctions/table");
const Layers = require("../mapFunctions/layers");
const TelrLayerFunctions = require("./telrLayerFunctions");
const DataViewDropdown = require("../mapFunctions/dataViewDropdown");
