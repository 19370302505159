"use strict";

const AreaSelectionMap = function () {
  let handlers = {
    addPolygonHandler: null,
    removePolygonHandler: null,
    selectAllPolygonsHandler: null,
    loadPolygonTreeHandler: null,
    newExistingMarkerHandler: null,
  };

  var loadSelectAllListener = function () {
    var $selectAllButton = $("#modalMap .select-all-button");
    var $clearAllButton = $("#modalMap .clear-all-button");

    if (Tree.get("readOnlyModalMap")) {
      $selectAllButton.hide();
      $clearAllButton.hide();
    } else {
      $selectAllButton.show();
      $clearAllButton.show();
    }

    $selectAllButton.off("click");
    $selectAllButton.on("click", handleSelectAll);
    $clearAllButton.off("click");
    $clearAllButton.on("click", handleClearAll);
  };

  var handleSelectAll = function () {
    selectAllPolygons(true);
  };

  var handleClearAll = function () {
    selectAllPolygons(false);
  };

  var resetAreaSelectionMapHandlers = function () {
    handlers = {
      addPolygonHandler: null,
      removePolygonHandler: null,
      selectAllPolygonsHandler: null,
      loadPolygonTreeHandler: null,
      percentageUpdateHandler: null,
      newExistingMarkerHandler: null,
    };
    AreaSelectionTable.configureAreaSelectionTableHandlers(handlers);
  };

  var configureAreaSelectionMapHandlers = function (newHandlers) {
    handlers = {
      addPolygonHandler: newHandlers.addPolygonHandler,
      removePolygonHandler: newHandlers.removePolygonHandler,
      selectAllPolygonsHandler: newHandlers.selectAllPolygonsHandler,
      loadPolygonTreeHandler: newHandlers.loadPolygonTreeHandler,
      newExistingMarkerHandler: newHandlers.newExistingMarkerHandler,
    };
    ModalMap.configureModalMapHandlers(handlers);
    AreaSelectionTable.configureAreaSelectionTableHandlers(newHandlers);
  };

  var showAreaSelectionMap = function (enableDrawMethod) {
    if (!ModalMap.getMap()) {
      ModalMap.showModalMap();
    } else {
      ModalMap.updateModalMapDisplay();
    }
    const modalMap = ModalMap.getMap();
    $("#modalMap").show();

    DrainageSelectionDraw.init(modalMap, enableDrawMethod);
    loadSelectAllListener();
    ModalMap.showModalMapLegendGroup();
    ModalMap.invalidateModalMapSize();
    initializeAreaSelectionTable(true);
    if (!Tree.get("readOnlyModalMap")) {
      $(".polygon-select").show();
      DrainageSelectionDraw.show();
    }
    ModalMap.getMarker();
    ModalMap.disableMarkerRelocation();
    setHelpMessage();
    //ModalMap.bounceSingleMarker();
  };

  var setHelpMessage = function () {
    if (Tree.get("asset", "basicInfo", "tt") === true && $(`select[name='ttFlowsTo']`).length) {
      ModalMap.showLocationMessage("Select BMP in treatment train, then drainage area.");
    } else {
      ModalMap.showLocationMessage("Click map to add polygons to drainage area.");
    }
  };

  var initializeAreaSelectionTable = function (showTable = true) {
    const mapLayers = ModalMap.getMapLayers();
    AreaSelectionTable.loadListeners(mapLayers);
    AreaSelectionTable.setAreaSelectionTablePercentageText("of Area Treated");
    AreaSelectionTable.setAreaSelectionTableHeight();

    $(".modal-map-floating-table").hide();
    AreaSelectionTable.toggleAreaSelectionTable(showTable);
  };

  var hideAreaSelectionMap = function () {
    if (ModalMap.modalMapHasLayer("drainageLayer")) {
      ModalMap.removeMapLayer("drainageLayer");
    }
    Tree.set(["layers", "drainage", "isEnabledModal"], false);
    $("#modalMap").hide();
    $(".polygon-select").hide();
    AreaSelectionTable.toggleAreaSelectionTable(false);
    $("#modal-legend-group").hide();
    $(".select-all-button").hide();
    $(".clear-all-button").hide();
    DrainageSelectionDraw.hide();
    DrawController.hideDrawnItemsLayer();
    DrawController.resetDrawControlSelection();
  };

  var handleAddPolygon = function (polyProps, polygonArray, catchmentArray) {
    hideInstructionMessage();
    if (handlers.addPolygonHandler !== null) {
      handlers.addPolygonHandler(polyProps, polygonArray, catchmentArray);
    } else {
      console.warn("Warning: addPolygonHandler is not set");
    }
  };

  var handleRemovePolygon = function (polyProps) {
    var otherPolygonsSelectedInCatch = false;
    var catchName = polyProps.catch_name;
    var polyArray = [...getSelectedPolygons()];
    var catchmentArray = [...getSelectedCatchments()];
    var markerCatch = getMarkerCatchment();
    hideInstructionMessage();

    var i = polyArray.length;
    while (i--) {
      if (
        polyArray[i].catch_name === catchName &&
        polyArray[i].polygon_id !== parseInt(polyProps.polygon_id)
      ) {
        otherPolygonsSelectedInCatch = true;
      }
      if (polyArray[i].polygon_id === parseInt(polyProps.polygon_id)) {
        polyArray.splice(i, 1);
      }
    }
    if (!otherPolygonsSelectedInCatch && catchName !== markerCatch) {
      var catchIndex = catchmentArray.indexOf(catchName);
      if (catchIndex > -1) {
        catchmentArray.splice(catchIndex, 1);
        setSelectedCatchments(catchmentArray);
      }
    }

    if (handlers.removePolygonHandler !== null) {
      handlers.removePolygonHandler(polyProps, polyArray, catchmentArray);
    } else {
      console.warn("Warning: removePolygonHandler is not set");
    }

    return polyArray;
  };

  var getMarkerCatchment = function () {
    if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      return BmpFcsInventoryModalController.getLatestBmpInventoryData([
        "locationInfo",
        "catchmentName",
      ]);
    } else {
      return ProjectInventoryModalController.getLatestConstructionData([
        "locationInfo",
        "catchmentName",
      ]);
    }
  };

  const selectAllPolygons = (select) => {
    const drainageLayer = ModalMap.getDrainageLayer();
    const polygonFeatures = drainageLayer._layers;
    const polygonArray = [];
    let tableString = "";

    for (const featureObj in polygonFeatures) {
      const properties = polygonFeatures[featureObj].feature.properties;

      if (select) {
        polygonFeatures[featureObj].feature.properties["selected"] = true;
        properties["percent"] = 100;
        polygonArray.push(properties);

        if (properties.apn) {
          tableString += nunjucks.render("table/areaSelectionTableRow.njk", properties);
        }
      } else {
        delete polygonFeatures[featureObj].feature.properties.selected;
        AreaSelectionTable.clearAreaSelectionTable();
      }
      polygonFeatures[featureObj].setStyle(
        MapStyles.styleDrainageLayer(polygonFeatures[featureObj].feature),
      );
    }

    //store data values to tree for saving to db
    if (handlers.selectAllPolygonsHandler !== null) {
      handlers.selectAllPolygonsHandler(polygonArray, select);
    } else {
      console.warn("Warning: selectAllPolygonsHandler is not set");
    }
    $("#area-selection-table tbody").html(tableString);
    AreaSelectionTable.handleNoApnRow();
    //save features back to layer
    drainageLayer._layers = polygonFeatures;
  };

  var getSelectedPolygons = function () {
    if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      return BmpFcsInventoryModalController.getLatestBmpInventoryData("areaPolygons") || [];
    } else {
      return ProjectInventoryModalController.getLatestConstructionData("areaPolygons");
    }
  };

  var setSelectedPolygons = function (polygons, firstLoad) {
    if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      Tree.set(["asset", "selected_polygons"], polygons);
    } else {
      if (firstLoad) {
        Tree.set(["asset", "project-inventory", "areaPolygons"], polygons);
      } else {
        ProjectInventoryModalController.setFormDataField("areaPolygons", polygons);
      }
    }
  };

  var getSelectedCatchments = function () {
    if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      return BmpFcsInventoryModalController.getLatestBmpInventoryData("selectedCatchments");
    } else {
      return ProjectInventoryModalController.getLatestConstructionData("selectedCatchments");
    }
  };

  var setSelectedCatchments = function (catchments, firstLoad) {
    if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      BmpFcsInventoryModalController.setFormDataField(["selectedCatchments"], catchments);
    } else {
      if (firstLoad) {
        Tree.set(["asset", "project-inventory", "selectedCatchments"], catchments);
      } else {
        ProjectInventoryModalController.setFormDataField("selectedCatchments", catchments);
      }
    }
  };

  var hideInstructionMessage = function () {
    ModalMap.hideLocationMessage();
  };

  var selectPolygonsFromArray = function (polygonArray) {
    const drainageLayer = ModalMap.getDrainageLayer();
    let tableString = "";
    BmpFcsInventoryModalController.setFormDataField("areaPolygons", polygonArray);

    if (drainageLayer) {
      const polygonFeatures = drainageLayer._layers;

      for (const featureObj in polygonFeatures) {
        const properties = polygonFeatures[featureObj].feature.properties;
        const polygonInArray = polygonArray.find((poly) => {
          return properties.id === poly.id;
        });

        if (polygonInArray) {
          polygonFeatures[featureObj].feature.properties["selected"] = true;
          properties["percent"] = polygonInArray.percent;

          if (properties.apn) {
            tableString += nunjucks.render("table/areaSelectionTableRow.njk", properties);
          }

          polygonFeatures[featureObj].setStyle(
            MapStyles.styleDrainageLayer(polygonFeatures[featureObj].feature),
          );
        }
      }
      $("#area-selection-table tbody").html(tableString);
      AreaSelectionTable.handleNoApnRow();
      drainageLayer._layers = polygonFeatures;
    }
  };

  var setActiveMethodLayer = async function (layer) {
    DrawController.setSelectedMethod(layer);
    if (layer === "drawing-tools") {
      showDrawingToolsLayer();
    } else if (layer === "data-layer") {
      await showDataLayer();
    }
  };

  var showDrawingToolsLayer = function () {
    DrawController.showDrawLayer();
    hideDrainageLayer();
    AreaSelectionTable.toggleAreaSelectionTable(false);
    displayDrawingToolsTips();
    if (Tree.get("tool") === "esg") {
      // nothing
    } else if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      DrainageTable.setDrainageDrawAcres();
    } else {
      ProjectInventoryAreaSelection.setTotalAreaAcres();
    }
    if (ModalMap.modalMapHasLayer("centralizedBmpsLayer")) {
      CentralizedBmpLayer.addCentralizedBmpDrainageLayer();
    }
  };

  var showDataLayer = async function () {
    DrawController.hideDrawLayer();
    try {
      await showDrainageLayer();
    } catch (e) {
      console.error(e);
    }
    displayDataLayerTips();
    AreaSelectionTable.toggleAreaSelectionTable(true);
    if (InventoryModal.isBmpFcsInventoryModalFormat()) {
      DrainageTable.updateDrainageAreaFooter();
    } else {
      ProjectInventoryAreaSelection.setTotalAreaAcres();
    }
    if (ModalMap.modalMapHasLayer("centralizedBmpsLayer")) {
      CentralizedBmpLayer.removeCentralizedBmpDrainageLayer();
    }
  };

  var showDrainageLayer = async function () {
    if (!ModalMap.modalMapHasLayer("drainageLayer")) {
      while (ModalMap.getDrainageLayer() === null) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      ModalMap.addMapLayer("drainageLayer");
    }
    Tree.set(["layers", "drainage", "isEnabledModal"], true);
  };

  var hideDrainageLayer = function () {
    if (ModalMap.modalMapHasLayer("drainageLayer")) {
      ModalMap.removeMapLayer("drainageLayer");
    }
    Tree.set(["layers", "drainage", "isEnabledModal"], false);
  };

  var displayDrawingToolsTips = function () {
    const helpMessage =
      Tree.get("tool") === "esg"
        ? "Draw the property boundary on the map via the drawing tools."
        : InventoryModal.isBmpFcsInventoryModalFormat()
          ? "Use drawing tools to create drainage area."
          : "Draw the project boundary via the tools in the top right corner or enter total area manually.";
    ModalMap.showLocationMessage(helpMessage);
  };

  var displayDataLayerTips = function () {
    const helpMessage = InventoryModal.isBmpFcsInventoryModalFormat()
      ? "Use selection tools to create drainage area."
      : "Use selection tools to create project area.";
    ModalMap.showLocationMessage(helpMessage);
  };

  var resetDrawController = function () {
    DrainageSelectionDraw.resetDrainageSelectionDraw();
  };

  return {
    resetAreaSelectionMapHandlers,
    configureAreaSelectionMapHandlers,
    showAreaSelectionMap,
    hideAreaSelectionMap,
    handleSelectAll,
    handleClearAll,
    handleAddPolygon,
    handleRemovePolygon,
    selectAllPolygons,
    getSelectedPolygons,
    getSelectedCatchments,
    setSelectedPolygons,
    setSelectedCatchments,
    getMarkerCatchment,
    hideInstructionMessage,
    selectPolygonsFromArray,
    setActiveMethodLayer,
    resetDrawController,
  };
};

module.exports = AreaSelectionMap();

const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const ProjectInventoryAreaSelection = require("../construction/projectInventoryAreaSelection");
const ModalMap = require("./modalMap");
const MapStyles = require("./mapStyles");
const Tree = require("../tree");
const DrawController = require("./drawController");
const DrainageSelectionDraw = require("./drainageSelectionDraw");
const AreaSelectionTable = require("./areaSelectionTable");
const DrainageTable = require("./drainageTable");
const CentralizedBmpLayer = require("../bmpfcs/centralizedBmpLayer");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
