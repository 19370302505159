"use strict";

const IncidentInvestigationInspectClean = function () {
  const stringKey = "inspectClean";
  const tabHeader = "Inspect & Clean";
  let $page;
  let defaultIncidentType;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("2N:FormInput", `[name="incident-type"]`, onIncidentTypeChange);
    $page.on("2N:FormInput", `[name="flow-characteristics-collected"]`, checkFlowTab);
    $page.on("2N:FormInput", `[name="incident-type-location"]`, onIncidentTypeLocationChange);

    toggleIncidentTypeSection($page, data.incidentType);
    toggleIncidentTypeLocationOtherInput(
      $(`[name="incident-type-location"]`),
      data.incidentTypeLocation,
    );

    defaultIncidentType = data.incidentType;
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="incident-type"]`, onIncidentTypeChange);
    $page.off("2N:FormInput", `[name="flow-characteristics-collected"]`, checkFlowTab);
    $page.off("2N:FormInput", `[name="incident-type-location"]`, onIncidentTypeLocationChange);
  };

  var getProps = function () {
    return {
      incidentTypeOptions: IncidentInvestigationConstants.getIncidentTypeOptions(),
      illicitFlowDescriptionOptions:
        IncidentInvestigationConstants.getIllicitFlowDescriptionOptions(),
      illicitFlowMaterialActivityOptions:
        IncidentInvestigationConstants.getIllicitFlowMaterialActivityOptions(),
      spillDescriptionOptions: IncidentInvestigationConstants.getSpillDescriptionOptions(),
      illegalDumpingDescriptionOptions:
        IncidentInvestigationConstants.getIllegalDumpingDescriptionOptions(),
      incidentTypeLocationOptions: IncidentInvestigationConstants.getIncidentTypeLocationOptions(),
      cleanLevelRequiredOptions: IncidentInvestigationConstants.getCleanLevelRequiredOptions(),
      cleanJurisdictionOptions: IncidentInvestigationConstants.getCleanJurisdictionOptions(),
      illicitSizeOptions1: IncidentInvestigationConstants.getIllicitSizeOptions1(),
      illicitSizeOptions2: IncidentInvestigationConstants.getIllicitSizeOptions2(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onIncidentTypeChange = function (e, incidentType) {
    toggleIncidentTypeSection($page, incidentType);
    checkFlowTab();
  };

  var toggleIncidentTypeSection = function ($page, incidentType) {
    $page.find(".incident-type-section").hide();

    if (incidentType === "spill") {
      $page.find(".incident-type-section.spill").show();
    } else if (incidentType === "illicit-flow") {
      $page.find(".incident-type-section.illicit-flow").show();
    } else if (incidentType === "illegal-dumping") {
      $page.find(".incident-type-section.illegal-dumping").show();
    }
  };

  var checkFlowTab = function () {
    const incidentType =
      IncidentInvestigationController.getFormData(["incidentType"]) || defaultIncidentType;
    const flowCharacteristicsCollected = IncidentInvestigationController.getFormData([
      "flowCharacteristicsCollected",
    ]);
    IncidentInvestigationController.checkFlowTab({ incidentType, flowCharacteristicsCollected });
  };

  var onIncidentTypeLocationChange = function (e, incidentTypeLocation) {
    toggleIncidentTypeLocationOtherInput($(this), incidentTypeLocation);
  };

  var toggleIncidentTypeLocationOtherInput = function (
    $incidentTypeLocationInput,
    incidentTypeLocation,
  ) {
    const showOtherInput = incidentTypeLocation === "other";
    $incidentTypeLocationInput.parent().find(".other-input").toggle(showOtherInput);
  };

  return { loadListeners, unloadListeners, getStringKey, getTabHeader, getProps };
};

module.exports = IncidentInvestigationInspectClean();

const IncidentInvestigationConstants = require("./incidentInvestigationConstants");
const IncidentInvestigationController = require("./incidentInvestigationController");
