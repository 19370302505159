"use strict";

var SbmpProgressDrainageLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "sbmpProgressDrainage",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.sbmpProgressDrainageLayer && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers.sbmpProgressDrainageLayer);
          } else {
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.sbmpProgressDrainageLayer)) {
            map.removeLayer(mapLayers.sbmpProgressDrainageLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "sbmpProgressDrainage", "isFetching"], true);
    ReportApiCalls.getSbmpProgressDrainage(filters, function (data) {
      data = data.filter(function (bmp) {
        return bmp.isBmp;
      });
      Tree.set(["layers", mapId, "sbmpProgressDrainage", "isFetching"], false);
      Tree.set(["layers", mapId, "sbmpProgressDrainage", "data"], data);
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "sbmpProgressDrainage", function (data) {
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.sbmpProgressDrainageLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.sbmpProgressDrainageLayer) {
      map.removeLayer(mapLayers.sbmpProgressDrainageLayer);
    }
    mapLayers.sbmpProgressDrainageLayer = createLayer(data);
    mapLayers.sbmpProgressDrainageLayer.addTo(map);
    return mapLayers.sbmpProgressDrainageLayer;
  };

  var createLayer = function (data) {
    return new (Layers.getLayers().SbmpProgressDrainageLayer)(data);
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      status: ["filters", "status"],
      ownership: ["filters", "ownership"],
      structureType: ["filters", "structureType"],
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.sbmpProgressDrainageLayer) &&
        Tree.get("layers", mapId, "sbmpProgressDrainage", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = SbmpProgressDrainageLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Layers = require("../mapFunctions/layers");
