"use strict";

const ProjectInventorySetLocation = function () {
  const stringKey = "construction-set-location";
  const headerInformation = "Location";
  let $page;
  let $inventoryModal;

  var loadSelectLocationListeners = function () {
    $page.on("input", "[name=project-identifier]", setProjectIdentifierInput);
    $page.on("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.on("focusin", ".radio-inline", updateMapMarkerPhase);
    $page.on("input", "[name=phase]", updateIsDateEstimateOnAsset);
    $page.on("change", `[name="use-manual-apn"]`, onUseManualApnChange);
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
  };

  var removeListeners = function () {
    $page.off("input", "[name=project-identifier]", setProjectIdentifierInput);
    $page.off("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.off("focusin", ".radio-inline", updateMapMarkerPhase);
    $page.off("input", "[name=phase]", updateIsDateEstimateOnAsset);
    $page.off("change", `[name="use-manual-apn"]`, onUseManualApnChange);
  };

  var setProjectIdentifierInput = function () {
    setProjectIdentifier($(this).val());
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var checkCreateNewProjectMarker = function () {
    var id = ProjectInventoryModalController.getLatestConstructionData("projectIdentifier");
    var isNewMarker = !id;
    return isNewMarker;
  };

  var updateMapMarkerPhase = function (e) {
    const phase = $(e.currentTarget).find("input").val();
    ProjectInventoryModalController.setFormDataField("phase", phase);
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      const marker = ModalMap.getMarker();
      const markerData = ProjectInventoryModalController.getProjectMarker();
      marker.setIcon(markerData);
    }
  };

  var updateIsDateEstimateOnAsset = function (e) {
    updateIsDateEstimateOnAssetByPhase(e.currentTarget.value);
  };

  var updateIsDateEstimateOnAssetByPhase = function (phase) {
    const updateMap = {
      planned: () => {},
      construction: setIsStartDateEstimateToFalseIfNecessary,
      completed: setIsDateEstimateToFalseIfNecessary,
      certified: setIsDateEstimateToFalseIfNecessary,
    };
    if (!(phase in updateMap)) {
      console.error(`Phase ${phase} not in update map.`);
    }
    updateMap[phase]();
  };

  var setIsDateEstimateToFalseIfNecessary = function () {
    setIsStartDateEstimateToFalseIfNecessary();
    setIsEndDateEstimateToFalseIfNecessary();
  };

  var setIsStartDateEstimateToFalseIfNecessary = function () {
    if (ProjectInventoryModalController.getLatestConstructionData("isStartDateEstimate") === true) {
      ProjectInventoryModalController.setFormDataField("isStartDateEstimate", false);
    }
  };

  var setIsEndDateEstimateToFalseIfNecessary = function () {
    if (ProjectInventoryModalController.getLatestConstructionData("isEndDateEstimate") === true) {
      ProjectInventoryModalController.setFormDataField("isEndDateEstimate", false);
    }
  };

  var onUseManualApnChange = function () {
    const showManualApnInput =
      ProjectInventoryModalController.getLatestConstructionData("useManualApn") === true;
    $page.find(`input[name="parcel-apn"]`).toggleClass("hidden", showManualApnInput);
    $page.find(`input[name="manual-apn"]`).toggleClass("hidden", !showManualApnInput);
  };

  var updateSetLocationFooter = async function (locationInfo, latlng) {
    const promise = updateLocationInfo(locationInfo);
    updateLatLngFields(latlng);

    const $projectIdentifier = $page.find("input[name='project-identifier']");
    if ($projectIdentifier.attr("disabled")) {
      Misc.toggleDisabled($projectIdentifier, false);
      $projectIdentifier.focus();
    }

    return promise;
  };

  var updateLocationInfo = function (locationInfo) {
    return getPossibleRelatedProjectsOnReceivingWaterChange(locationInfo.drains_to_rw)
      .then(() => {
        locationInfo = {
          catchmentGid: locationInfo.gid,
          catchmentName: locationInfo.catchid,
          receivingWaterName: locationInfo.drains_to_rw,
          landuseType: locationInfo.combo,
          groupId: locationInfo.groupId,
          groupName: locationInfo.gname,
          groupCityName: locationInfo.city,
          parcelApn: locationInfo.parcelid,
          address: locationInfo.address,
          state: locationInfo.state,
          zipCode: locationInfo.zip,
        };

        updateSelectedCatchments(locationInfo);
        ProjectInventoryModalController.setFormDataField("locationInfo", locationInfo);
        setInputField("ms4-name", locationInfo.groupName);
        setInputField("catchment-name", locationInfo.catchmentName);
        setInputField("parcel-apn", locationInfo.parcelApn);
        setInputField("streetAddress", locationInfo.address, true);
        setInputField("city", locationInfo.groupCityName, true);
        setInputField("zipCode", locationInfo.zipCode, true);
        setInputField("state", locationInfo.state, true);
        return;
      })
      .catch((err) => {
        throw err;
      });
  };

  var updateSelectedCatchments = function (locationInfo) {
    const AreaSelectionMap = require("../mapFunctions/areaSelectionMap");
    const selectedPolygons = AreaSelectionMap.getSelectedPolygons() || [];
    const selectedCatchments = selectedPolygons.reduce(
      (catchArray, polygon) => {
        if (!catchArray.includes(polygon.catch_name)) {
          catchArray.push(polygon.catch_name);
        }
        return catchArray;
      },
      [locationInfo.catchmentName],
    );
    AreaSelectionMap.setSelectedCatchments(selectedCatchments);
  };

  var getPossibleRelatedProjectsOnReceivingWaterChange = async function (newReceivingWater) {
    const existingReceivingWater = ProjectInventoryModalController.getChangedFormData([
      "locationInfo",
      "receivingWaterName",
    ]);
    if (existingReceivingWater === null || existingReceivingWater !== newReceivingWater) {
      const projectId = ProjectInventoryModalController.getExistingConstructionData("id");
      await ProjectInventoryModalController.fetchPossibleRunoffOffsetProjects(
        newReceivingWater,
        projectId,
      );
    }
  };

  var updateLatLngFields = function (latlng) {
    const latInput = $page.find(`input[name="latitude"]`)[0];
    const longInput = $page.find(`input[name="longitude"]`)[0];

    if (latInput && longInput) {
      NumberInput.manuallySetInput(latInput, latlng.lat);
      NumberInput.manuallySetInput(longInput, latlng.lng);
    }

    ProjectInventoryModalController.setFormDataField("latitude", latlng.lat);
    ProjectInventoryModalController.setFormDataField("longitude", latlng.lng);
    restoreButtonsIfHasAllRequiredInputs();
  };

  var setInputField = function (field, value, updateForm) {
    if (field === "state") {
      value = Misc.convertStateForAutoPopulate(value);
    }
    $page.find(`[name=${field}]`).val(value);
    if (updateForm) {
      ProjectInventoryModalController.setFormDataField(field, value);
    }
  };

  var render = async function (options) {
    _addAndRemoveMarginClass();
    await renderPageHtml();
    ModalMap.removeMarkerLayer();
    showLocationSelectionMap(options);
    loadSelectLocationListeners();
    ProjectInventoryModalController.setModalTitle();
    disableUnavailableElements();
    setCheckedPhase();
  };

  var renderPageHtml = async function () {
    const renderData = InventoryModal.getAllData("project-inventory");
    addProps(renderData);
    renderData.stateOptions = getProps();
    const html = nunjucks.render(
      "modals/projectInventory/projectInventorySetLocation.njk",
      renderData,
    );
    ProjectInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
    $inventoryModal = $("#inventory-modal");
    $(".setLocationMessage").addClass("project-map-text-styles");
  };

  var _addAndRemoveMarginClass = function () {
    $(".modal-dynamic-content").addClass("add-area-low-margin").removeClass("add-bmp-no-margin");
  };
  var getProps = function () {
    return ProjectInventoryConstants.getStatesOptions();
  };

  var addProps = function (renderData) {
    renderData.dataView = Tree.get("dataView");
  };

  var showLocationSelectionMap = function (options) {
    const configuration = {
      checkNewMarkerHandler: checkCreateNewProjectMarker,
      newExistingMarkerHandler: ProjectInventoryModalController.loadExistingProjectMarker,
      locationDataUpdateHandler: updateSetLocationFooter,
      badLocationUpdateHandler: resetLatLngToLastValidValue,
    };
    LocationSelectionMap.configureLocationModalMapHandlers(configuration);
    LocationSelectionMap.showLocationSelectionMap(options.existingLocation);

    setLocationMapText();
  };

  var setLocationMapText = function () {
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      ModalMap.showLocationMessage("Click map or drag marker to change location");
    } else {
      ModalMap.showLocationMessage(
        "Drop point within project boundary or enter latitude/longitude manually",
      );
    }
  };

  var disableUnavailableElements = function () {
    const projectIdentifier =
      ProjectInventoryModalController.getLatestConstructionData("projectIdentifier");
    if (projectIdentifier === undefined || projectIdentifier === "") {
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
      Misc.toggleDisabled($inventoryModal.find("[name=project-identifier]"), true);
    } else {
      InventoryModal.restoreNavigationButtons();
    }
  };

  var setCheckedPhase = function () {
    const phase = ProjectInventoryModalController.getLatestConstructionData("phase");
    if (phase === undefined) {
      $page.find("input[value=planned]").prop("checked", true).trigger("input");
      ProjectInventoryModalController.setFormDataField(
        "phase",
        ProjectInventoryModalController.getPhase(),
      );
    } else {
      $page.find(`input[value=${phase}]`).prop("checked", true);
    }
  };

  var setProjectIdentifier = function (newId) {
    if (
      newId === ProjectInventoryModalController.getExistingConstructionData("projectIdentifier")
    ) {
      restoreButtonsIfHasAllRequiredInputs();
      ProjectInventoryModalController.unsetFormDataField("projectIdentifier");
      ProjectInventoryModalController.setModalTitle();
    } else if (newId === "") {
      InventoryModal.allowedSaveState(false);
      ProjectInventoryModalController.unsetFormDataField("projectIdentifier");
      ProjectInventoryModalController.setModalTitle();
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
    } else {
      ProjectInventoryModalController.setFormDataField("projectIdentifier", newId);
      ProjectInventoryModalController.setModalTitle();
      restoreButtonsIfHasAllRequiredInputs();
    }
  };

  var validateProjectIdentifier = async function () {
    const projectIdentifier =
      ProjectInventoryModalController.getLatestConstructionData("projectIdentifier");
    const existingIdentifier =
      ProjectInventoryModalController.getExistingConstructionData("projectIdentifier");
    if (projectIdentifier === existingIdentifier) {
      return true;
    } else {
      const isUnique = await ApiCalls.getConstructionProjectIdentifierUnique(projectIdentifier);
      if (!isUnique) {
        MessageModal.showSimpleWarningModal(
          "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
      return isUnique;
    }
  };

  var updateLatLngFromInput = function () {
    if ($page.find(`input[name="latitude"]`).val() && $page.find(`input[name="longitude"]`).val()) {
      const e = {
        latlng: {
          lat: $page.find(`input[name="latitude"]`).val(),
          lng: $page.find(`input[name="longitude"]`).val(),
        },
      };
      LocationSelectionMap.locationSelectionMapClick(e);

      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var hasAllRequiredInputs = function () {
    const latitude = $page.find(`input[name="latitude"]`).val();
    const longitude = $page.find(`input[name="longitude"]`).val();
    const projectIdentifier = $page.find(`input[name="project-identifier"]`).val();

    return latitude && longitude && projectIdentifier;
  };

  var restoreButtonsIfHasAllRequiredInputs = function () {
    if (hasAllRequiredInputs()) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }
  };

  var resetLatLngToLastValidValue = function (latlng) {
    NumberInput.manuallySetInput($page.find(`input[name="latitude"]`)[0], latlng.lat);
    NumberInput.manuallySetInput($page.find(`input[name="longitude"]`)[0], latlng.lng);
  };

  var cleanUp = function () {
    removeListeners();
    LocationSelectionMap.hideLocationSelectionMap();
    $page.empty();
    return true;
  };

  var validate = async function () {
    return validateProjectIdentifier().catch((err) => {
      throw err;
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    disableUnavailableElements,
    setCheckedPhase,
    setProjectIdentifier,
    validateProjectIdentifier,
    setLocationMapText,
    updateSetLocationFooter,
    cleanUp,
    validate,
    updateIsDateEstimateOnAssetByPhase,
    _addAndRemoveMarginClass,
  };
};

module.exports = ProjectInventorySetLocation();

const ApiCalls = require("../apiCalls");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const LocationSelectionMap = require("../mapFunctions/locationSelectionMap");
const Misc = require("../misc");
const MessageModal = require("../modals/messageModal");
const ModalMap = require("../mapFunctions/modalMap");
const NumberInput = require("../general/numberInput");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const Tree = require("../tree");
const ProjectInventoryConstants = require("./projectInventoryConstants");
