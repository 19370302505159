"use strict";

function DataStore(
  resource,
  {
    id = UUID.v4(),
    metadata = {},
    data = null,
    isNew = false,
    isFirstOpen = true,
    relatedId = null,
    keepOffline = true,
  } = {},
) {
  const metadataFormKey = "formData";

  async function init() {
    metadata.data = metadata.data ?? data;
    metadata.isNew = metadata.isNew ?? isNew;
    metadata.isFirstOpen = metadata.isFirstOpen ?? isFirstOpen;
    metadata.relatedId = metadata.relatedId ?? relatedId;
    metadata[metadataFormKey] = metadata[metadataFormKey] ?? {};
    metadata.id = id;
    metadata.syncId = metadata.syncId ?? UUID.v4();
    metadata.fileIdSet = metadata.fileIdSet ?? new Set();
    metadata.uncommittedFileIdSubset = metadata.uncommittedFileIdSubset ?? new Set();

    metadata.editing = metadata.editing ?? "open";
    metadata.syncStatus = metadata.syncStatus ?? "unsynced";
    metadata.keepOffline = metadata.keepOffline ?? keepOffline;

    await _setMetadata();
  }

  function getInitial() {
    return structuredClone(metadata.data) ?? {};
  }

  function getWithUpdates() {
    if (metadata[metadataFormKey]) {
      return $.extend(true, {}, metadata.data, metadata[metadataFormKey]);
    }

    return getInitial();
  }

  function getForm() {
    return structuredClone(metadata[metadataFormKey]) ?? {};
  }

  async function _setMetadata(property, value) {
    if (property) {
      metadata[property] = value;
    }

    await resource.overrideMetadata(metadata);
  }

  function setForm(data) {
    _setMetadata(metadataFormKey, data);
  }

  async function discard() {
    if (!metadata) {
      console.warn("Data store is already disabled");
    } else if (metadata.isFirstOpen === true) {
      await resource.deleteOffline(_getId());
    } else {
      metadata.data = {};
      metadata[metadataFormKey] = {};
      await deleteUncommittedOfflineFiles();
      await _setMetadata("editing", "closed");
    }

    disableStore();
  }

  function isNewFunction() {
    return metadata.isNew;
  }

  async function save() {
    return closeAndSync("closed");
  }

  async function lock() {
    return closeAndSync("locked");
  }

  async function closeAndSync(editing) {
    metadata.syncStatus = "unsynced";
    metadata.isFirstOpen = false;
    metadata.editing = editing;
    metadata.uncommittedFileIdSubset.clear();
    await saveDataOffline();
    await resource.sync();
  }

  async function saveDataOffline(newData = null) {
    await Promise.all([
      _setMetadata(),
      resource.saveOffline({
        id: _getId(),
        data: newData ?? metadata.data,
        formData: metadata[metadataFormKey],
      }),
    ]);
  }

  async function sync() {
    assertNotOpen();
    assertNotSyncing();
    _setMetadata("syncStatus", "syncing");
    var newData;

    try {
      if (await ApiCalls.getSyncResult(metadata.syncId)) {
        console.log(`Skipping sync ${_getId()} because data is already up to date.`);
      } else {
        await directUploads();
        newData = await saveOnline();
      }
    } catch (e) {
      await handleSyncError(e);
    }

    await saveNewData(newData);
  }

  async function saveOnline() {
    const initial = getInitial();
    const changes = Offline.removeOfflineOnlyFields(getForm());
    const isNew = isNewFunction();

    if (isNew) {
      return await resource.saveNew(initial, changes, metadata.syncId);
    } else {
      return await resource.saveUpdates(initial, changes, metadata.syncId);
    }
  }

  async function directUploads() {
    const toUploadParents = [];

    Misc.eachNestedPropertyWithKey(
      metadata[metadataFormKey],
      "directUpload",
      function (file, parent) {
        toUploadParents.push(parent);
      },
    );

    for (const parent of toUploadParents) {
      if (!parent.uploadedFileName) {
        await directUploadFile(parent);
      }
    }
  }

  async function directUploadFile(parent) {
    const fileObj = await resource.getFile(parent.directUpload.id);

    if (fileObj) {
      parent.uploadedFileName = await DirectUpload.uploadFile(fileObj.file, {
        stringKey: resource.getStringKey(),
      });
    } else if (Config.get().environment === "Production") {
      parent.uploadedFileName = "?"; // API will skip when it can't find the file
      console.error(`Offline file ${parent.directUpload.id} not found`);
    } else {
      throw new Error(`Offline file ${parent.directUpload.id} not found`);
    }

    await saveDataOffline();
  }

  function assertNotOpen() {
    if (metadata.editing === "open") {
      throw new Error(`Resource is open, it should be closed before syncing`);
    }
  }

  function assertNotSyncing() {
    if (metadata.syncStatus === "syncing") {
      throw new Error("Data store is already syncing.");
    }
  }

  async function saveNewData(newData) {
    metadata.syncStatus = "synced";
    metadata.data = {};
    metadata[metadataFormKey] = {};
    metadata.isNew = false;
    metadata.syncId = UUID.v4();
    metadata.fileIdSet.clear();

    await resource.deleteOffline(_getId());

    if (newData && metadata.keepOffline !== false && metadata.editing !== "locked") {
      metadata.id = newData.id;
      await saveDataOffline(newData);
    }

    disableStore();
  }

  function disableStore() {
    resource = null;
    metadata = null;
  }

  function _getId() {
    return metadata.id;
  }

  async function addFile(file) {
    const id = UUID.v4();

    await resource.deleteOrphanedFiles();
    await resource.putFile({ id, file });
    metadata.fileIdSet.add(id);
    metadata.uncommittedFileIdSubset.add(id);
    await _setMetadata();

    return { id, url: `/offlineData/file/${resource.getStringKey()}/${id}` };
  }

  async function deleteFile(id) {
    await resource.deleteFile(id);
    metadata.fileIdSet.delete(id);
    metadata.uncommittedFileIdSubset.delete(id);
    await _setMetadata();
  }

  async function deleteUncommittedOfflineFiles() {
    const fileIds = metadata.uncommittedFileIdSubset;
    await resource.deleteFiles(fileIds);

    for (const fileId of metadata.uncommittedFileIdSubset) {
      metadata.fileIdSet.delete(fileId);
    }

    metadata.uncommittedFileIdSubset.clear();
  }

  async function handleSyncError(e) {
    if (e instanceof ApiError && e.xhrResponse.status === 423) {
      await recoverFromSyncError(metadata, "the inspection was locked on another device");
    } else if (e instanceof ApiError && e.xhrResponse.status === 404) {
      await recoverFromSyncError(metadata, "the inspection or asset can not be found");
    } else {
      await _setMetadata("syncStatus", "failed");
      throw e;
    }
  }

  async function recoverFromSyncError(metadata, failureReason) {
    metadata.keepOffline = false;
    await _setMetadata("editing", "locked");

    MessageModal.showSimpleWarningModal(
      `Unable to save data because ${failureReason}. The local data will be discarded.`,
    );
  }

  return {
    init,
    getWithUpdates,
    getInitial,
    getForm,
    setForm,
    discard,
    isNew: isNewFunction,
    save,
    sync,
    lock,
    addFile,
    deleteFile,
    _setMetadata,
    _getId,
  };
}

module.exports = DataStore;

const ApiError = require("../errors/apiError");
const DirectUpload = require("../files/directUpload");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
const Offline = require("./offline");
const UUID = require("uuid");
const Config = require("../config");
const ApiCalls = require("../apiCalls");
