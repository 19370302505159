const incidentPopup = function () {
  var getOptionNameByValue = function (options, value) {
    return options.find((item) => item.value === value)?.name;
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();
    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  var loadDomListeners = function () {
    $(".map-content").off("click", ".popup-show-history");
    $(".map-content").on("click", ".popup-show-history", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
  };

  var loadPopupEventListeners = function (layer) {
    layer.on("popupopen", _onPopupOpen);
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-incident-history-pane").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-show-history").show();
    $parent.find(".popup-incident-main-pane").show();
  };

  var popupHistoryButtonClick = function () {
    var id = $(this).parents(".popup-general").data("id");
    showInspectionHistory(id);
  };

  var showInspectionHistory = function () {
    $(".popup-incident-main-pane").hide();
    $(".popup-show-history").hide();
    $(".popup-incident-history-pane").show();
    $(".popup-back-button").show();
  };

  var _onPopupOpen = async function (e) {
    const id = e.layer.feature.properties.id;

    const data = await getPopupData(id);
    const html = getIncidentsPopupHtml(data);
    e.popup.setContent(html);
  };

  var getPopupData = async function (id) {
    const popupData = await ReportApiCalls.getIncidentPopup(id);
    return popupData;
  };

  var getIncidentsPopupHtml = function (data) {
    const requestingTypeName = getOptionNameByValue(
      FormSettings.getDropdownOptions("requesting-type", { tool: "idde" }),
      data?.requestingType,
    );
    const requestingAffiliationName = getOptionNameByValue(
      FormSettings.getDropdownOptions("requesting-affiliation", { tool: "idde" }),
      data?.requestingAffiliation,
    );

    const popupColor = IncidentsIcon.getIconColor(data.incidentStatus);
    const showNewInvestigationButton = data.incidentStatus === "new";
    return nunjucks.render("report/popups/incidentsPopup.njk", {
      data,
      popupColor,
      showNewInvestigationButton,
      requestingTypeName,
      requestingAffiliationName,
      incidentType: getOptionNameByValue(
        FormSettings.getDropdownOptions("incident-type", { tool: "idde" }),
        data.lastInvestigation?.incidentType,
      ),
      enforcementLevel: getOptionNameByValue(
        FormSettings.getDropdownOptions("enforcement-level", { tool: "idde" }),
        data.lastInvestigation?.enforcementLevel,
      ),
      illicitActivity: getOptionNameByValue(getIllicitActivityOptions(), data.illicitActivity),
      history: data.history,
    });
  };

  var getIllicitActivityOptions = function () {
    const illicitFlowMaterialActivityOptions =
      FormSettings.getDropdownOptions("illicit-flow-material-activity", { tool: "idde" }) || [];
    const illegalDumpingDescriptionOptions =
      FormSettings.getDropdownOptions("illegal-dumping-description", { tool: "idde" }) || [];

    return illicitFlowMaterialActivityOptions.concat(illegalDumpingDescriptionOptions);
  };

  return {
    getIncidentsPopupHtml,
    loadDomListeners,
    getPopupData,
    loadPopupEventListeners,
    getPopupOptions,
    getOptionNameByValue,
    getIllicitActivityOptions,
  };
};

module.exports = incidentPopup();

const IncidentsIcon = require("./incidentsIcon");
const FormSettings = require("../../settings/formSettings");
const ReportApiCalls = require("../reportApiCalls");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
