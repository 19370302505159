"use strict";

const IncidentsFilters = function () {
  const searchFields = ["incidentName"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.spatialFilters(unfilteredData, filters);
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };
  var passrequestedDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.requestedDate,
      filters.requestedDate?.from,
      filters.requestedDate?.to,
    );
  };
  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return ResourceFilters.spatialFiltersAreSet(filters);
    }
  };

  var isOnlineDateFilterSet = function (filters) {
    return isOnlineDateType(filters.dateType) && (filters.dateFrom || filters.dateTo);
  };

  var isOnlineDateType = function (dateType) {
    return ["routine", "repair", "clean"].includes(dateType);
  };

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  const getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions } =
      Filters.getSpatialArrays();
    const activityTypeOptions = [
      { name: "Agricultural Irrigation", value: "agricultural-irrigation" },
      { name: "Concrete Wash", value: "concrete-wash" },
      { name: "Fuel/Diesel", value: "fuel-diesel" },
      { name: "Grey Water", value: "grey-water" },
      { name: "Oil", value: "oil" },
      { name: "Paint", value: "paint" },
      { name: "Pressure Washing", value: "pressure-washing" },
      { name: "Residential Irrigation", value: "residential-irrigation" },
      { name: "Water Leak", value: "water-leak" },
      { name: "Hazardous Materials", value: "hazardous-materials" },
      { name: "Sewage", value: "sewage" },
      { name: "Other", value: "other" },
      { name: "Unknown", value: "unknown" },
      { name: "No Activity", value: "no-activity" },
      { name: "No Investigations", value: "no-investigations" },
      { name: "No Data", value: "null" },
    ];
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      isCdot: Session.isCdot(),

      incidentTypeOptions: IncidentInvestigationConstants.getFilterIncidentTypeOptions(),
      activityTypeOptions: activityTypeOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      activityType: ["activityType", "array"],
      drainsToRw: "drainsToRw",
      drainsToC: "drainsToC",
      catchmentName: "catchmentName",
      incidentType: "incidentType",
      subGroups: "groupId",
      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passrequestedDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    getProps,
    isOnlineDateFilterSet,
    passInstalledDateFilter,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = IncidentsFilters();

const ApiError = require("../../errors/apiError");
const ResourceFilters = require("../../filters/resourceFilters");
const Filters = require("../../mapFunctions/filters");
const IncidentInvestigationConstants = require("../incidentInvestigation/incidentInvestigationConstants");
const Session = require("../../login/session");
const Progeny = require("../../login/progeny");
