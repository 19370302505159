"use strict";

// This is the "Other" checkbox/radio input and text input that can
// optionally follow a radio or checkbox group.
var OtherInput = function () {
  const otherInputCheckValue = "other-input-check";

  var loadListeners = function ($parent) {
    $parent.on("input", `input[value='${otherInputCheckValue}']`, onOtherCheckUpdate);
    $parent.on("input", `.other-text-input`, onOtherTextUpdate);
  };

  var unloadListeners = function ($parent) {
    $parent.off("input", `input[value='${otherInputCheckValue}']`);
    $parent.off("input", `.other-text-input`);
  };

  var onOtherCheckUpdate = function (e) {
    const $otherCheck = $(e.currentTarget);
    const $otherTextInput = $otherCheck
      .parents(".checkbox-group, .radio-group")
      .find(".other-text-input")
      .assertLength(1);
    $otherTextInput.prop("disabled", !$otherCheck.prop("checked"));
  };

  var onOtherTextUpdate = function (e) {
    const $otherTextInput = $(e.currentTarget);
    const $otherCheck = $otherTextInput
      .siblings(`[value='${otherInputCheckValue}']`)
      .assertLength(1);
    if ($otherTextInput.val() !== "") {
      $otherCheck.trigger("input");
    }
  };

  var isOtherInput = function ($input) {
    return isOtherCheckInput($input) || isOtherTextInput($input);
  };

  var isOtherCheckInput = function ($checkElement) {
    return $checkElement.prop("value") === otherInputCheckValue;
  };

  var isOtherTextInput = function ($textElement) {
    return $textElement.hasClass("other-text-input");
  };

  var getOtherTextInput = function ($checkOrTextInput) {
    let $textElement = null;
    if (isOtherCheckInput($checkOrTextInput)) {
      $textElement = $checkOrTextInput
        .parents(".checkbox-group, .radio-group")
        .find(".other-text-input")
        .assertLength(1, "Couldn't find the text input for an 'Other' field.");
    } else if (isOtherTextInput($checkOrTextInput)) {
      $textElement = $checkOrTextInput;
    } else {
      console.error("Input element wasn't a check or text input for an 'Other' field.");
    }
    if (isCheckboxGroup($checkOrTextInput)) {
      if ($textElement.val() === "") {
        return null;
      }
      return [$textElement.val()];
    }
    return $textElement.val();
  };

  var isCheckboxGroup = function ($input) {
    return $input.parents(".checkbox-group").length > 0;
  };

  return {
    isOtherInput,
    getOtherTextInput,
    loadListeners,
    unloadListeners,
  };
};

module.exports = OtherInput();
