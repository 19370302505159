"use strict";

const AreaSelectionTable = function () {
  var layers;
  var tbl;
  let areaSelectionTableHandlers = {
    percentageUpdateHandler: null,
  };

  var loadListeners = function (mapLayers) {
    tbl = $("#floatingAreaSelectionTable");
    layers = mapLayers;
    tbl.off();
    tbl.on("click", ".delete-btn", removeAreaPolygon);
    tbl.on("change", ".percent-treated", updateInventoryAreaTreated);
  };

  var configureAreaSelectionTableHandlers = function (newHandlers) {
    areaSelectionTableHandlers = {
      percentageUpdateHandler: newHandlers.percentageUpdateHandler,
    };
  };

  var setAreaSelectionTablePercentageText = function (percentageText = "") {
    tbl.find("th:contains(%)").text(`% ${percentageText}`);
  };

  var updateInventoryAreaTreated = function (e) {
    const AreaSelectionMap = require("./areaSelectionMap");
    const data = $(this).closest("tr").data();
    if (this.value !== "") {
      const cleanPercent = Misc.clamp(1, Math.round(parseFloat(this.value)), 100);
      const oldPercent = data.percent;
      this.value = cleanPercent;

      //update tree
      var polyArray = AreaSelectionMap.getSelectedPolygons();
      for (let i = 0; i < polyArray.length; i++) {
        if (polyArray[i]["polygon_id"] == parseInt(data.polygon_id)) {
          polyArray[i]["percent"] = cleanPercent;
        }
      }
      AreaSelectionMap.setSelectedPolygons(polyArray);
      $(this).closest("tr").data("percent", cleanPercent); // update the row percent data attribute

      // run callbacks
      if (areaSelectionTableHandlers.percentageUpdateHandler !== null) {
        areaSelectionTableHandlers.percentageUpdateHandler(data, cleanPercent, oldPercent);
      } else {
        console.warn("Warning: percentageUpdateHandler is not set");
      }
    } else {
      this.value = data.percent;
    }
  };

  var removeAreaPolygon = function (e) {
    const data = $(e.target).closest("tr").data();

    removeAreaSelectionTableRow(data);
    layers.drainageLayer.removeSelected(data);
  };

  var makeAreaSelectionTable = function (propertiesArray) {
    let htmlString = "";
    const $areaSelectionTable = $("#area-selection-table tbody");

    for (const properties of propertiesArray) {
      const rowString = updateAndGetAreaSelectionTableRowString(properties);
      if (rowString) {
        htmlString += rowString;
      }
    }
    $areaSelectionTable.children().last().before(jQuery.parseHTML(htmlString));
  };

  var addAreaSelectionTableRow = function (properties) {
    const rowString = updateAndGetAreaSelectionTableRowString(properties);

    if (rowString) {
      const $areaSelectionTable = $("#area-selection-table tbody");
      $(rowString).insertBefore($areaSelectionTable.children().last());
    }
  };

  var updateAndGetAreaSelectionTableRowString = function (properties) {
    updateAreaSelectionTable(properties);
    return getAreaSelectionTableRowString(properties);
  };

  var updateAreaSelectionTable = function (properties) {
    const $areaSelectionTable = $("#area-selection-table tbody");
    if (!properties.apn) {
      handleNoApnRow();
    } else if ($areaSelectionTable.children().length == 0) {
      $areaSelectionTable.append("<span class='plu-hint'>&nbsp;&nbsp;* PLU parcel</span>");
    }
  };

  var removeAreaSelectionTableRow = function (data) {
    if (!data.apn) {
      handleNoApnRow();
    } else {
      $('#area-selection-table tr[data-polygon_id="' + data.polygon_id + '"]').remove();
    }
  };

  var getAreaSelectionTableRowString = function (properties) {
    if (!properties.apn) {
      return null;
    }

    properties.disabled = Tree.get("readOnlyModalMap");
    return nunjucks.render("table/areaSelectionTableRow.njk", properties);
  };

  var clearAreaSelectionTable = function () {
    $("#area-selection-table tbody").empty();
  };

  // @TODO do this via css
  var setAreaSelectionTableHeight = function () {
    const tableHeight = $("#inventory-modal").find(".modal-body").height();
    const modalContentHeight = $("#inventory-modal").find(".modal-dynamic-content").height();
    $("#area-selection-table-container").css("max-height", tableHeight - modalContentHeight + "px");
  };

  var handleNoApnRow = function () {
    const noApnAcres = getNoApnAcres();
    if (noApnAcres == 0) {
      //remove row if the last non apn has been removed
      $("#area-selection-table tr.noApnRow").remove();
    } else {
      const row = nunjucks.render("table/areaSelectionTableRowNoApn.njk", {
        noApnAcres: noApnAcres,
      });

      const noApnRowExists = $("#area-selection-table").find(".noApnRow").length;
      if (noApnRowExists) {
        $("#area-selection-table tr.noApnRow").remove();
      }
      $(row).prependTo("#area-selection-table tbody");
    }
  };

  var getNoApnAcres = function () {
    const AreaSelectionMap = require("./areaSelectionMap");
    var polyArray = AreaSelectionMap.getSelectedPolygons();
    let noApnAcres = 0;
    polyArray.forEach(function (polygon) {
      if (!polygon.apn) {
        noApnAcres += parseFloat(polygon.acres);
      }
    });
    return noApnAcres;
  };

  var toggleAreaSelectionTable = function (toggle) {
    $("#floatingAreaSelectionTable").toggle(toggle);
    MapFunctions.updateAutoPanPadding();
  };

  return {
    configureAreaSelectionTableHandlers,
    loadListeners,
    toggleAreaSelectionTable,
    makeAreaSelectionTable,
    clearAreaSelectionTable,
    setAreaSelectionTablePercentageText,
    setAreaSelectionTableHeight,
    addAreaSelectionTableRow,
    removeAreaSelectionTableRow,
    handleNoApnRow,
    getNoApnAcres,
  };
};

module.exports = AreaSelectionTable();

const MapFunctions = require("./mapFunctions");
const Misc = require("../misc");
const Tree = require("../tree");
