// Inspired by https://github.com/NeXTs/Clusterize.js

/*
  Requirements:
    * List items must all be the same height
    * Widths must be fixed, they can't be based on the contents of rendered elements
*/
class LazyList {
  scrollContainer;
  rowContainer;
  getRowCallback;
  totalRows;
  cachedData;
  renderedRowIndexes;
  resizeTimeout;
  resetScroll = true;

  constructor({ rowContainer, scrollContainer, getRowCallback, totalRows } = {}) {
    this.rowContainer = rowContainer;
    this.scrollContainer = scrollContainer;
    this.getRowCallback = getRowCallback;
    this.totalRows = totalRows;

    this._ensureCanFocus();
    this._loadDomListeners();
    this.recalculateAndRender();
  }

  updateData(getRowCallback, totalRows, { resetScroll = true } = {}) {
    this.getRowCallback = getRowCallback;
    if (this.totalRows !== totalRows) {
      this.renderedRowIndexes = null;

      if (resetScroll) {
        this.resetScroll = true;
      }
    }
    this.totalRows = totalRows;
    this.renderVisibleRows();
  }

  recalculateAndRender() {
    this.cachedData = null;
    this.renderVisibleRows();
  }

  destroy() {
    clearTimeout(this.resizeTimeout);
    this.unloadDomListeners();
  }

  scrollToIndex(index, forceRerender = false) {
    if (index < 0 || index >= this.totalRows || !this.cachedData) {
      return;
    }

    const visibleRowIndexes = LazyList._getVisibleRowIndexes(
      this.scrollContainer,
      this.totalRows,
      this.cachedData,
    );

    if (!(visibleRowIndexes.first <= index && index <= visibleRowIndexes.last)) {
      this.scrollContainer.scrollTop = LazyList._getScrollTopOfIndex(this.cachedData, index);
    }

    if (forceRerender) {
      this.renderVisibleRows();
    }
  }

  // Fixes keyboard scrolling not working
  _ensureCanFocus() {
    if (!this.rowContainer.hasAttribute("tabindex")) {
      this.rowContainer.setAttribute("tabindex", 0);
    }
  }

  _updateCachedDataIfUnset() {
    if (this.cachedData || this.totalRows === 0) {
      return;
    }

    // Need at least four because three are the extra padding rows
    if (this.rowContainer.children.length < 4) {
      this._renderRows(0, 1);
    }

    this._updateCachedData();
  }

  _updateCachedData() {
    const nodeRows = this.rowContainer.children;
    const nodeRow = nodeRows[Math.floor(nodeRows.length / 2)];
    const rowHeight = nodeRow.offsetHeight;

    this.cachedData = {
      rowHeight: rowHeight,
      maxVisibleRows: Math.ceil(this.scrollContainer.clientHeight / rowHeight) + 1,
      rowTag: nodeRow.tagName,
      offsetDifference: LazyList._sumOffsetTopBetweenElements(
        this.rowContainer,
        this.scrollContainer,
      ),
    };
  }

  _listIsVisible() {
    return this.rowContainer.offsetParent !== null;
  }

  static _sumOffsetTopBetweenElements(child, parent) {
    let offset = 0;
    let offsetElement = child;

    while (offsetElement !== parent) {
      if (offsetElement === null) {
        throw new Error(
          `Unable to find parent. To fix, please give the scroll container a position.`,
        );
      }

      offset += offsetElement.offsetTop;
      offsetElement = offsetElement.offsetParent;
    }

    return offset;
  }

  renderVisibleRows() {
    if (global.lazyListTestingDisabled) {
      this._renderRows(0, this.totalRows);
      return;
    }

    if (!this._listIsVisible()) {
      return;
    }

    this._updateCachedDataIfUnset();
    const visibleIndexes = LazyList._getVisibleRowIndexes(
      this.scrollContainer,
      this.totalRows,
      this.cachedData,
    );
    LazyList._addOffscreenRows(this.totalRows, visibleIndexes);
    this._renderRows(visibleIndexes.first, visibleIndexes.last);
    this._renderAdditionalPadding(visibleIndexes.first, visibleIndexes.last);

    if (this.resetScroll) {
      this.scrollContainer.scrollTop = 0;
      this.resetScroll = false;
    }
  }

  _renderRows(startRowIndex, endRowIndex) {
    if (endRowIndex > this.totalRows) {
      endRowIndex = this.totalRows;
    }

    const rows = [];
    for (let i = startRowIndex; i <= endRowIndex && i < this.totalRows; i++) {
      rows.push(this.getRowCallback(i));
    }
    this.rowContainer.innerHTML = rows.join("");
    this.renderedRowIndexes = { first: startRowIndex, last: endRowIndex };
  }

  static _getVisibleRowIndexes(scrollContainer, totalRows, cachedData) {
    if (totalRows === 0) {
      return { first: 0, last: 0 };
    }

    const scrollTopPastOffset = Math.max(
      scrollContainer.scrollTop - cachedData.offsetDifference,
      0,
    );
    const first = Math.floor(scrollTopPastOffset / cachedData.rowHeight);

    return { first, last: Math.min(first + cachedData.maxVisibleRows, totalRows - 1) };
  }

  static _getScrollTopOfIndex(cachedData, index) {
    return cachedData.rowHeight * index;
  }

  static _addOffscreenRows(totalRows, rowsObject) {
    const totalRowsIndex = totalRows - 1;
    const extraRows = rowsObject.last - rowsObject.first;

    for (let i = 0; i <= extraRows; i++) {
      if (rowsObject.first > 0) {
        rowsObject.first--;
      }

      if (rowsObject.last < totalRowsIndex) {
        rowsObject.last++;
      }
    }
  }

  _renderAdditionalPadding(firstRenderedRowIndex, lastRenderedRowIndex) {
    if (!this.cachedData) {
      return;
    }

    const topPadding = LazyList._getTopPadding(firstRenderedRowIndex, this.cachedData);
    this.rowContainer.prepend(this._createPaddingElement(topPadding));

    if (firstRenderedRowIndex % 2 === 0) {
      // Keeps CSS nth-child(even/odd) happy
      this.rowContainer.prepend(this._createPaddingElement(0));
    }

    const bottomPadding = LazyList._getBottomPadding(
      lastRenderedRowIndex,
      this.cachedData,
      this.totalRows,
    );
    this.rowContainer.append(this._createPaddingElement(bottomPadding));
  }

  static _getTopPadding(firstRenderedRowIndex, cachedData) {
    return firstRenderedRowIndex * cachedData.rowHeight;
  }

  static _getBottomPadding(lastRenderedRowIndex, cachedData, totalRows) {
    const totalRowsIndex = totalRows - 1;

    if (lastRenderedRowIndex >= totalRowsIndex) {
      return 0;
    }

    return (totalRowsIndex - lastRenderedRowIndex) * cachedData.rowHeight;
  }

  _createPaddingElement(height) {
    const bottomElement = document.createElement(this.cachedData.rowTag);
    bottomElement.style.height = `${height}px`;
    bottomElement.style.margin = `0`;
    bottomElement.style.padding = `0`;
    bottomElement.style.border = `0`;
    return bottomElement;
  }

  _loadDomListeners() {
    const $scrollContainer = $(this.scrollContainer);

    const scrollFn = () => this._onScroll();
    $scrollContainer.on("scroll", scrollFn);

    const resizeFn = () => this._onResize();
    const resizeObserver = new ResizeObserver(resizeFn);
    resizeObserver.observe(this.scrollContainer);

    this.unloadDomListeners = function () {
      $scrollContainer.off("scroll", scrollFn);
      resizeObserver.disconnect();
    };
  }

  _onScroll() {
    if (!this.cachedData) {
      return;
    }

    const visibleRowIndexes = LazyList._getVisibleRowIndexes(
      this.scrollContainer,
      this.totalRows,
      this.cachedData,
    );

    if (LazyList._pastEndOfRenderedRows(visibleRowIndexes, this.renderedRowIndexes)) {
      this.renderVisibleRows();
    }
  }

  _onResize() {
    if (this.resizeTimeout) {
      return;
    }

    this.resizeTimeout = setTimeout(() => {
      this.resizeTimeout = null;
      this.recalculateAndRender();
    }, 500);
  }

  static _pastEndOfRenderedRows(visibleRowIndexes, renderedRowIndexes) {
    return (
      visibleRowIndexes.first < renderedRowIndexes.first ||
      visibleRowIndexes.last > renderedRowIndexes.last
    );
  }
}

module.exports = LazyList;
