"use strict";

const FormConstants = function () {
  // @TODO: Move bmpfcs constants to a different file?
  const bmpTypes = {
    1: "Dry Basin",
    2: "Wet Basin",
    3: "Infiltration Basin",
    4: "Treatment Vault",
    5: "Media Filter",
    6: "Bed Filter",
    7: "Settling Basin",
    8: "Biofiltration",
    9: "Infiltration Feature",
    10: "Pervious Pavement",
    11: "Sediment Trap",
    12: "Drop Inlet",
    13: "Bioretention",
    14: "Detention Basin",
    15: "Bioswale",
    16: "Unknown",
    17: "Filtration Device",
    18: "Trash Trap",
    19: "Retention Basin",
    20: "Green Roof",
    21: "Rain Barrel",
    22: "Cistern",
  };

  const bmpTypeAbbrs = {
    1: "DB",
    2: "WB",
    3: "IB",
    4: "TV",
    5: "MF",
    6: "BF",
    7: "SB",
    8: "BI",
    9: "IF",
    10: "PP",
    11: "ST",
    12: "DI",
    13: "BR",
    14: "DT",
    15: "BS",
    16: "?",
    17: "FD",
    18: "TT",
    19: "RB",
    20: "GR",
    21: "RN",
    22: "CT",
  };

  const inventoryTypes = {
    1: {
      type: "Dry Basin",
      required: ["treatment-capacity", "footprint", "infiltration-rate", "drawdown-time"],
      optional: [
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    2: {
      type: "Wet Basin",
      required: ["treatment-capacity", "footprint", "drawdown-time", "wet-pool-capacity"],
      optional: [
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    3: {
      type: "Infiltration Basin",
      required: ["treatment-capacity", "footprint", "infiltration-rate"],
      optional: [
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    4: {
      type: "Treatment Vault",
      required: ["treatment-capacity", "footprint", "treatment-rate"],
      optional: [
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    5: {
      type: "Media Filter",
      required: ["treatment-capacity", "footprint", "treatment-rate"],
      optional: [
        "manufacturer",
        "name-model",
        "media-type",
        "filters",
        "filter-thickness",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: false },
    },
    6: {
      type: "Bed Filter",
      required: ["treatment-capacity", "footprint", "treatment-rate"],
      optional: [
        "name-model",
        "media-type",
        "filters",
        "filter-thickness",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    7: {
      type: "Settling Basin",
      required: ["treatment-capacity", "footprint"],
      optional: [
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    8: {
      type: "Biofiltration",
      required: [],
      optional: [
        "treatment-capacity",
        "footprint",
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    9: {
      type: "Infiltration Feature",
      required: [],
      optional: [
        "treatment-capacity",
        "footprint",
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    10: {
      type: "Pervious Pavement",
      required: [],
      optional: [
        "treatment-capacity",
        "footprint",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    11: {
      type: "Sediment Trap",
      required: [],
      optional: [],
      defaults: { system_access: true },
    },
    12: {
      type: "Drop Inlet",
      required: [],
      optional: [],
      defaults: { system_access: true },
    },
    13: {
      type: "Bioretention",
      required: [],
      optional: [
        "treatment-capacity",
        "footprint",
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    14: {
      type: "Detention Basin",
      required: ["treatment-capacity", "footprint", "drawdown-time"],
      optional: [
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    15: {
      type: "Bioswale",
      required: [],
      optional: [
        "treatment-capacity",
        "footprint",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    16: {
      type: "Unknown",
      required: [],
      optional: [],
      defaults: {},
    },
    17: {
      type: "Filtration Device",
      required: [],
      optional: [
        "treatment-capacity",
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: false },
    },
    18: {
      type: "Trash Trap",
      required: [],
      optional: [
        "treatment-capacity",
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true },
    },
    19: {
      type: "Retention Basin",
      required: ["treatment-capacity", "footprint", "wet-pool-capacity"],
      optional: [
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
      defaults: { system_access: true, bmp_drawdown: 1100 },
    },
    20: {
      type: "Green Roof",
      required: [],
      optional: [
        "treatment-capacity",
        "footprint",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
    },
    21: {
      type: "Rain Barrel",
      required: [],
      optional: [
        "treatment-capacity",
        "footprint",
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
    },
    22: {
      type: "Cistern",
      required: ["treatment-capacity", "footprint"],
      optional: [
        "manufacturer",
        "name-model",
        "footprint-material",
        "wall-material",
        "invert-elevation",
        "base-elevation",
        "rim-elevation",
        "datum",
      ],
    },
  };

  const EXTRA_BENCHMARK_FIELDS_BY_TYPE = {
    1: {
      type: "Dry Basin",
      required: ["veg-bench-tresh", "infiltration", "mat-accum"],
      optional: [],
    },
    2: {
      type: "Wet Basin",
      required: ["veg-min-max", "mat-accum"],
      optional: [],
    },
    3: {
      type: "Infiltration Basin",
      required: ["veg-bench-tresh", "infiltration"],
      optional: [],
    },
    4: {
      type: "Treatment Vault",
      required: ["mat-accum"],
      optional: [],
    },
    5: {
      type: "Media Filter",
      required: [],
      optional: [],
    },
    6: {
      type: "Bed Filter",
      required: [],
      optional: [],
    },
    7: {
      type: "Settling Basin",
      required: ["mat-accum"],
      optional: [],
    },
    8: {
      type: "Biofiltration",
      required: ["mat-accum"],
      optional: [],
    },
    9: {
      type: "Infiltration Feature",
      required: ["veg-bench-tresh"],
      optional: [],
    },
    10: {
      type: "Pervious Pavement",
      required: [],
      optional: [],
    },
    11: {
      type: "Sediment Trap",
      required: ["mat-accum"],
      optional: [],
    },
    12: {
      type: "Drop Inlet",
      required: [],
      optional: [],
    },
    13: {
      type: "Bioretention",
      required: ["mat-accum"],
      optional: [],
    },
    14: {
      type: "Detention Basin",
      required: ["mat-accum"],
      optional: [],
    },
    15: {
      type: "Bioswale",
      required: ["veg-bench-tresh"],
      optional: [],
    },
    17: {
      type: "Filtration Device",
      required: [],
      optional: [],
    },
    19: {
      type: "Retention Basin",
      required: ["veg-min-max", "mat-accum"],
      optional: [],
    },
    20: {
      type: "Green Roof",
      required: ["veg-bench-tresh"],
      optional: [],
    },
    21: {
      type: "Rain Barrel",
      required: ["mat-accum"],
      optional: [],
    },
    22: {
      type: "Cistern",
      required: ["mat-accum"],
      optional: [],
    },
  };

  //@TODO: Handle Treatment CAPACITY and footprint display
  const EXTRA_BMP_OBS_FIELDS_BY_TYPE = {
    1: {
      type: "Dry Basin",
      required: ["veg-obs", "infiltration", "mat-accum"],
      optional: [],
    },
    2: {
      type: "Wet Basin",
      required: ["veg-obs", "mat-accum"],
      optional: [],
    },
    3: {
      type: "Infiltration Basin",
      required: ["veg-obs", "infiltration"],
      optional: [],
    },
    4: {
      type: "Treatment Vault",
      required: ["mat-accum"],
      optional: [],
    },
    5: {
      type: "Media Filter",
      required: [],
      optional: [],
    },
    6: {
      type: "Bed Filter",
      required: ["permeability"],
      optional: [],
    },
    7: {
      type: "Settling Basin",
      required: ["mat-accum"],
      optional: [],
    },
    8: {
      type: "Biofiltration",
      required: ["mat-accum", "permeability", "substrate-type"],
      optional: [],
    },
    9: {
      type: "Infiltration Feature",
      required: ["veg-obs", "permeability"],
      optional: [],
    },
    10: {
      type: "Pervious Pavement",
      required: ["permeability"],
      optional: [],
    },
    11: {
      type: "Sediment Trap",
      required: ["mat-accum"],
      optional: [],
    },
    12: {
      type: "Drop Inlet",
      required: [],
      optional: [],
    },
    13: {
      type: "Bioretention",
      required: ["mat-accum", "permeability", "substrate-type"],
      optional: [],
    },
    14: {
      type: "Detention Basin",
      required: ["mat-accum"],
      optional: [],
    },
    15: {
      type: "Bioswale",
      required: ["veg-obs", "permeability"],
      optional: [],
    },
    17: {
      type: "Filtration Device",
      required: ["obs-port"],
      optional: [],
    },
    19: {
      type: "Retention Basin",
      required: ["veg-obs", "mat-accum"],
      optional: [],
    },
    20: {
      type: "Green Roof",
      required: ["veg-obs", "obs-port"],
      optional: [],
    },
    21: {
      type: "Rain Barrel",
      required: [],
      optional: ["mat-accum"],
    },
    22: {
      type: "Cistern",
      required: [],
      optional: ["mat-accum"],
    },
  };

  const inventoryDrainageAreaFieldToFixed = {
    bmp_imparea: 2,
    "priority-treated": 2,
    "total-treated": 2,
  };

  const UNIT_TYPE_BY_FIELD = {
    bmp_wqcap: "cubicFeet",
    bmp_wet: "cubicFeet",
    bmp_footprint: "squareFeet",
    bmp_designdepth: "feet",
    bmp_diam: "feet",
  };

  const INVENTORY_FIELDS_TO_REMOVE = [
    "bmp_wqcap_unit",
    "bmp_wet_unit",
    "bmp_footprint_unit",
    "bmp_designdepth_unit",
    "bmp_diam_unit",
    "trash_report_submitted",
    "bmp_benchmark",
    "bmp_assessment",
    "bmp_assessment_due",
    "bmp_score",
    "fcs_assessment",
    "fcs_assessment_due",
    "fcs_score",
  ];

  const hideFootprintWqcapTypeNumbers = [9, 10, 11, 12, 15, 17];

  const bmpOnlyTypeNumbers = [2, 10, 15, 19, 20, 21, 22];

  const fcsOnlyTypeNumbers = [12, 18];

  const unknownOnlyTypeNumbers = [16];

  const centralizedBmpTypeNumbers = [1, 2, 3, 4, 5, 6, 14, 19, 22];

  const stadiaRodOnlyTypeNumbers = [8, 11, 13];

  const defaultStaffPlateTypeNumbers = [1, 2, 7, 14, 19];

  const skipBenchmarkTypeNumbers = [5, 6, 10, 12, 17];

  const priorityCatchBasinTypeNumbers = [11, 12];

  const systemAccessNumbers = [5, 17];

  const fieldsToHideForDbmpTypes = ["intake-rate"];

  const bmpTypesUsingBioThresh = ["Biofiltration", "Bioretention", "Rain Barrel"];

  const inventoryFieldLegend = {
    planned: "Planned",
    drainageSelection: "Drainage selection",
    bmpType: "sBMP/FCS type",
    fcsInspectionFrequency: "Trash FCS inspection frequency",
    bmpInspectFreq: "Functional Assessment Frequency",
    property: "Property type",
    multiSystem: "FCS grouping",
    treatsStorm: "1YR-1HR storm",
    volumeBased: "FCS function",
    systemAccess: "System access",
    trapsParticles: "Small particle trapping",
    stateApproved: "State approval",
    manufacturer: "Manufacturer",
    nameModel: "Model name",
    installationDate: "Installation date",
    plan: "O&M plan",
    design: "Design plan",
    visibleScreen: "Screen visibility",
    screenCount: "Screen count",
    imperviousArea: "Impervious area",
    inlets: "Number of inlets",
    outlets: "Number of outlets",
    treatmentCapacity: "Treatment capacity",
    footprint: "Footprint",
    infiltrationRate: "Infiltration rate",
    drawdownTime: "Drawdown time",
    wetPoolCapacity: "Wet pool capacity",
    treatmentRate: "Treatment rate",
    standingWater: "Ports to observe standing water",
    sedimentAccumulation: "Ports to observe material accumulation",
    maintenanceInterval: "Maintenance interval",
    lowFlow: "Low flow bypass",
    diversion: "Dry weather diversion",
    drainsout: "Capture & reuse",
    flowLength: "Total flow path length",
    treatedStormType: "Design storm type",
    treatedStormPercentile: "Treated storm",
    treatedStormDepthsInches: "Treated storm depths",
    inletType: "Catch basin inlet type",
    totalDepth: "Total depth",
    cleanout: "Cleanout",
    sump: "Sump present",
    footprintMaterial: "Footprint material",
    wallMaterial: "Wall material",
    shape: "Shape",
    length: "Length",
    width: "Width",
    diameter: "Diameter",
    drainage: "Drainage area polygon",
    intakeRate: "Intake rate",
    treatmentTrain: "Treatment train",
  };

  const inventoryDisabledFieldsIfHasBenchmark = [
    "planned",
    "bmp_type",
    "system_access",
    "bmp_wqcap",
    "bmp_wqcap_unit",
    "bmp_footprint",
    "bmp_footprint_unit",
    "inv_stwat",
    "inv_sedacc",
    "maint_interval",
  ];

  const inventoryDisabledFieldsIfHasBmpObservation = ["bmp_inlets", "bmp_outlets"];

  const inventoryDisabledFieldsIfHasFcsAssessment = ["planned", "visible_screen", "system_access"];

  const inventoryDisabledFieldsIfTelrAccepted = [
    "bmp",
    "fcs",
    "bmp_latitude",
    "bmp_longitude",
    "bmp_creation",
    "bmp_imparea",
    "bmp_flow",
    "diversion",
    "drainsout",
  ];

  const dataGroupLabels = {
    "veg-bench-tresh": "Vegetation Cover",
    "veg-min-max": "Vegetation Cover",
    infiltration: "Infiltration",
    "mat-accum": "Material Accumulation",
    "veg-obs": "Vegetation Cover",
    permeability: "Permeability",
    "substrate-type": "Substrate Type",
    "obs-port": "Observation Port",
    obs_fxn_out_issues: "Non-functional Outlet Issues Present",
    obs_fxn_in_issues: "Non-functional Inlet Issues Present",
  };

  const bmpBenchmarkLabels = {
    bm_date: "Assessment Date",
    bm_pers: "Field Personnel",
    bmp_wqcap: "Treatment Capacity",
    bmp_footprint: "Footprint",
    bm_veg_pct: "Vegetation Cover Benchmark",
    thr_veg_pct: "Vegetation Cover Cover",
    thr_veg_min: "Vegetation Cover Min",
    thr_veg_max: "Vegetation Cover Max",
    inf_location_count: "Infiltration Locations",
    bm_inf_obs: "Infiltration Method",
    inf_locid: "Infiltration Location",
    inf_time: "Infiltration Minutes",
    inf_rdg: "Infiltration Reading",
    mat_location_count: "Material Accumulation # Of Locations",
    bm_dpth_type: "Material Accumulation Type",
    dpth_desc: "Material Accumulation Location",
    bm_dpth: "Material Accumulation Depth",
    dpth_unit: "Material Accumulation Depth Units",
    bench_notes: "Notes",
  };

  const bmpObservationLabels = {
    obs_date: "Assessment Date",
    obs_pers: "Field Personnel",
    bmp_wqcap: "Treatment Capacity",
    bmp_footprint: "Footprint",
    obs_veg_wet: "Wetland/riparian",
    obs_veg_tree: "Trees",
    obs_veg_grass: "Grass",
    inf_location_count: "Infiltration # Of Locations",
    bm_inf_obs: "Infiltration Method",
    inf_locid: "Infiltration Location ID",
    inf_time: "Infiltration Minutes",
    inf_rdg: "Infiltration Reading",
    obs_perm_location_count: "Permeability # Of Locations",
    obs_time_minutes: "Permeability Minutes",
    obs_time: "Permeability Seconds",
    runoff_height: "Permeability Inches",
    obs_cs_stwat: "Observation Port Standing Water",
    obs_cs_24stwat: "Observation Port Standing Water 24 Hours Later",
    obs_in: "Conveyance Observable Inlets",
    obs_fxn_in: "Conveyance Functional Inlets",
    obs_fxn_in_broken: "Non-functional Inlets",
    bmp_inlets: "Observable Inlets",
    obs_out: "Conveyance Observable Outlets",
    obs_fxn_out: "Conveyance Functional Outlets",
    obs_fxn_out_broken: "Non-functional Outlets",
    bmp_outlets: "Observable Outlets",
    obs_dpth_type: "Depth Type",
    obs_dpth_desc: "Depth Location",
    obs_dpth_msmt: "Depth",
    dpth_unit: "Depth Units",
  };

  const fcsAssessmentLabels = {
    date: "Date",
    personnel: "Personnel",
    bmp_footprint: "Footprint",
    screen_count: "Visible Screens",
    clog_percent: "Screen Clogged",
    clog_cleaned: "Screen Clog Cleaned",
    screen_damaged: "Screen Damaged",
    screen_repaired: "Screen Repair or Replace",
    full_percent: "Percent Full",
    trash_removed: "Trash Removed",
    location_count: "Pieces of Trash Locations",
    trash_counts: "Trash in 3ft Radius",
    trash_volume: "Volume of Collected Trash",
    mobile_percent: "Mobility of Trash",
  };

  const inventoryDefaultFields = {
    address: null,
    bmpName: null,
    bmp_apn: null,
    bmp_creation: null,
    bmp_flow: 0,
    bmp_type: 16, //default 'Unknown'
    bmp_groupid: null,
    bmp_latitude: null,
    bmp_longitude: null,
    bmp_luid: 7, //default 'OTH'
    bmp_maint: 0, //default 0
    bmp_ptype: 0, //default 0
    bmp_stat: null,
    catch_name: null, //cathcment string name 'SCW 13'
    diversion: 0,
    drainsout: 0,
    system_access: true,
    inline: 0,
    lu_name: "OTH",
    maint_interval: null,
    fcs: true,
    bmp: true,
    bmp_manufacturer: null,
    bmp_namemodel: null,
    bmp_notes: null,
    inspect_freq: null,
    traps_particles: null,
    treats_storm: null,
    storm_inches: null,
    partial_storm_inches: null,
    multi_system: null,
    volume_based: null,
    state_approved: null,
    screen_count: null,
    phase: "planned",
    visible_screen: 0,
    telr_accept: null,
    bmp_drainarea: 0,
    plu_treated: 0,
    catch_basin_priority: null,
    tt: false,
    ttFlowDirection: 1,
    ttFlowsTo: null,
    length: null,
  };

  const addressStateOptions = [
    { name: "AL", value: "al" },
    { name: "AK", value: "ak" },
    { name: "AZ", value: "az" },
    { name: "AR", value: "ar" },
    { name: "CA", value: "ca" },
    { name: "CO", value: "co" },
    { name: "CT", value: "ct" },
    { name: "DE", value: "de" },
    { name: "DC", value: "dc" },
    { name: "FL", value: "fl" },
    { name: "GA", value: "ga" },
    { name: "HI", value: "hi" },
    { name: "ID", value: "id" },
    { name: "IL", value: "il" },
    { name: "IN", value: "in" },
    { name: "IA", value: "ia" },
    { name: "KS", value: "ks" },
    { name: "KY", value: "ky" },
    { name: "LA", value: "la" },
    { name: "ME", value: "me" },
    { name: "MD", value: "md" },
    { name: "MA", value: "ma" },
    { name: "MI", value: "mi" },
    { name: "MN", value: "mn" },
    { name: "MS", value: "ms" },
    { name: "MO", value: "mo" },
    { name: "MT", value: "mt" },
    { name: "NE", value: "ne" },
    { name: "NV", value: "nv" },
    { name: "NH", value: "nh" },
    { name: "NJ", value: "nj" },
    { name: "NM", value: "nm" },
    { name: "NY", value: "ny" },
    { name: "NC", value: "nc" },
    { name: "ND", value: "nd" },
    { name: "OH", value: "oh" },
    { name: "OK", value: "ok" },
    { name: "OR", value: "or" },
    { name: "PA", value: "pa" },
    { name: "RI", value: "ri" },
    { name: "SC", value: "sc" },
    { name: "SD", value: "sd" },
    { name: "TN", value: "tn" },
    { name: "TX", value: "tx" },
    { name: "UT", value: "ut" },
    { name: "VT", value: "vt" },
    { name: "VA", value: "va" },
    { name: "WA", value: "wa" },
    { name: "WV", value: "wv" },
    { name: "WI", value: "wi" },
    { name: "WY", value: "wy" },
  ];

  return {
    bmpTypes,
    bmpTypeAbbrs,
    inventoryTypes,
    EXTRA_BENCHMARK_FIELDS_BY_TYPE,
    EXTRA_BMP_OBS_FIELDS_BY_TYPE,
    inventoryDrainageAreaFieldToFixed,
    UNIT_TYPE_BY_FIELD,
    INVENTORY_FIELDS_TO_REMOVE,
    hideFootprintWqcapTypeNumbers,
    bmpOnlyTypeNumbers,
    fcsOnlyTypeNumbers,
    stadiaRodOnlyTypeNumbers,
    defaultStaffPlateTypeNumbers,
    unknownOnlyTypeNumbers,
    systemAccessNumbers,
    fieldsToHideForDbmpTypes,
    inventoryFieldLegend,
    inventoryDisabledFieldsIfHasBenchmark,
    inventoryDisabledFieldsIfHasBmpObservation,
    inventoryDisabledFieldsIfHasFcsAssessment,
    inventoryDisabledFieldsIfTelrAccepted,
    skipBenchmarkTypeNumbers,
    dataGroupLabels,
    bmpBenchmarkLabels,
    bmpObservationLabels,
    fcsAssessmentLabels,
    inventoryDefaultFields,
    centralizedBmpTypeNumbers,
    bmpTypesUsingBioThresh,
    priorityCatchBasinTypeNumbers,
    addressStateOptions,
  };
};

module.exports = FormConstants();
