"use strict";

const IndcomListController = function () {
  var inited = false;

  var init = async function () {
    if (inited) return;
    inited = true;
    DataList.init({
      prepareDataCallback: prepareData,
      template: "list/indcomList.njk",
      layerName: "indcomFacilities",
    });
    initialRender();
    DataList.loadDomListeners([{ target: ".open-fact-sheet", callback: openFactSheet }]);
    DataList.loadDomListeners([{ target: ".map-button-datalist", callback: openPopup }]);
  };

  var openPopup = function () {
    const facilityId = $(this).data("id");
    MapFunctions.openPopupById(facilityId);
    const mapButtonContainer = $(this).parents("td");
    DataList.addAnalyticsToMapIconOnAddress(mapButtonContainer.hasClass("address-col"));
  };

  var initialRender = function () {
    DataList.setSorting("facilityName", false);
    render();
  };

  var render = function () {
    DataList.render();
  };

  var prepareData = function (facilities) {
    addIconClasses(facilities);
    sortBy(facilities);

    return { rows: facilities, options: {} };
  };

  var addIconClasses = function (facilities) {
    facilities.forEach((facility) => {
      facility.iconClass = IndcomIcon.getFacilityIconClass(facility);
    });
  };

  var sortBy = function (facilities) {
    const dateSorter = DateTime.dbDateStringComparator;
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    var sortersDict = {
      facilityName: alphaSorter,
      facilityIdentifier: alphaSorter,
      address: alphaSorter,
      facilityType: alphaSorter,
      sicCode: numberSorter,
      lastInspectionDate: dateSorter,
    };
    DataList.sortWithDict(facilities, sortersDict);
  };

  var openFactSheet = function () {
    const id = DataList.getIdFromRow($(this));
    FacilityFactSheetController.initFactSheet(id);
    $("#details-button-container").addClass("desktop-only");
    Analytics.sendDataListEvent("open_factsheet", "indcom");
    FactSheet.handleFilterFlow();
  };

  return {
    init,
    prepareData,
    render,
  };
};

module.exports = IndcomListController();

const DataList = require("../mapFunctions/dataList");
const DateTime = require("../dateTime");
const FacilityFactSheetController = require("./facilityFactSheetController");
const IndcomIcon = require("./indcomIcon");
const SortUtilities = require("../general/sortUtilities");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Analytics = require("../general/analytics");
const FactSheet = require("../general/factSheet");
