"use strict";

const Ms4Table = function () {
  var loadListeners = function () {
    const $table = PageFunctions.getCurrentPage().find(".bottom-floating-inputs-table");

    $table.off("click", ".ms4-table li", handleMs4RowClick);
    $table.on("click", ".ms4-table li", handleMs4RowClick);

    $table.off("dblclick", ".ms4-table li", handleMs4RowDoubleClick);
    $table.on("dblclick", ".ms4-table li", handleMs4RowDoubleClick);

    $table.off("mouseover", ".ms4-table li", ms4RowMouseover);
    $table.on("mouseover", ".ms4-table li", ms4RowMouseover);

    $table.off("mouseout", ".ms4-table li", ms4RowMouseout);
    $table.on("mouseout", ".ms4-table li", ms4RowMouseout);
  };

  var handleMs4RowClick = function (e) {
    setTimeout(() => {
      const mapId = Tree.get("mapId");
      var doubleClick = Tree.get("doubleClickCount", mapId);
      if (doubleClick > 0) {
        Tree.set(["doubleClickCount", mapId], doubleClick - 1);
      } else {
        ms4RowClick(e);
      }
    }, 300);
  };

  var ms4RowClick = function (e) {
    var groupId = $(e.currentTarget).data("groupId");
    var activeTab = Tree.get("activeTab");

    if (activeTab === "chart") {
      handleLoadKpiOnMs4RowClick(groupId);
    } else {
      openRegionalLayerPopup(groupId);
      setActiveRow(groupId);
    }
  };

  var handleLoadKpiOnMs4RowClick = function (groupId) {
    var $selectedRow = selectRowWithGroupId(groupId);

    if ($selectedRow.hasClass("active")) {
      Kpi.loadKpi();
      Tree.set("ms4BarChartTitleOveride", null);
      removeAllActive();
    } else {
      Kpi.loadKpiByGroupId(groupId);
      Tree.set("ms4BarChartTitleOveride", $selectedRow.text());
      setActiveRow(groupId);
    }
  };

  var setActiveRow = function (groupId) {
    var $selectedRow = selectRowWithGroupId(groupId);
    removeAllActive();
    $selectedRow.addClass("active");
  };

  var removeAllActive = function () {
    $(".bottom-floating-inputs-table").find(".active").removeClass("active");
  };

  var openRegionalLayerPopup = function (groupId) {
    const mapLayers = TreeUpdates.getCurrentMapLayers();
    const map = TreeUpdates.getCurrentMap();
    if (mapLayers.regionalLayer) {
      mapLayers.regionalLayer.eachLayer(function (layer) {
        const properties = layer.feature.properties;
        const layerGroupId = properties.group_id || properties.groupId;
        if (layerGroupId == groupId) {
          layer.setStyle(MapStyles.highlights());
          map.fitBounds(layer.getBounds(), {
            animate: true,
          });
          setTimeout(function () {
            layer.openPopup();
          }, 0);
        } else {
          mapLayers.regionalLayer.resetStyle(layer);
        }
      });
    } else if (mapLayers.regionPointLayer) {
      mapLayers.regionPointLayer.eachLayer(function (layer) {
        const properties = layer.feature.properties;
        const layerGroupId = properties.group_id || properties.groupId;
        if (layerGroupId == groupId) {
          map.panTo(layer.getLatLng(), {
            animate: true,
          });
          setTimeout(function () {
            layer.openPopup();
          }, 0);
        }
      });
    }
  };

  var handleMs4RowDoubleClick = function (e) {
    Tree.set(["doubleClickCount", Tree.get("mapId")], 2);
    ms4RowDoubleClick(e);
  };

  var ms4RowDoubleClick = function (e) {
    const groupId = $(e.currentTarget).data("groupId");
    RegionalView.changeActiveGroup(groupId);
  };

  var ms4RowMouseover = function (e) {
    if ($(e.currentTarget).hasClass("only-submitted")) {
      return;
    }
    highlightMs4ByGroupId($(e.currentTarget).data("groupId"));
  };

  var highlightMs4ByGroupId = function (groupId) {
    selectRowWithGroupId(groupId).addClass("table-highlight");
    const mapLayers = TreeUpdates.getCurrentMapLayers();
    if (mapLayers.regionalLayer) {
      mapLayers.regionalLayer.eachLayer(function (layer) {
        if (layer.feature.properties.group_id == groupId) {
          layer.setStyle(MapStyles.highlights());
        }
      });
    }
  };

  var ms4RowMouseout = function () {
    if ($(this).hasClass("only-submitted")) {
      return;
    }

    const groupId = $(this).data("groupId");
    const mapLayers = TreeUpdates.getCurrentMapLayers();
    if (mapLayers.regionalLayer) {
      mapLayers.regionalLayer.eachLayer(function (layer) {
        mapLayers.regionalLayer.resetStyle(layer);
      });
    }
    selectRowWithGroupId(groupId).removeClass("table-highlight");
  };

  var resetHighlightMs4 = function (e) {
    const ms4Row = e.target;
    const mapLayers = TreeUpdates.getCurrentMapLayers();
    if (mapLayers.regionalLayer) {
      mapLayers.regionalLayer.resetStyle(ms4Row);
    }
    const properties = e.target.feature.properties;
    const groupId = properties.group_id || properties.groupId;
    selectRowWithGroupId(groupId).removeClass("table-highlight");
  };

  var highlightMs4Feature = function (e) {
    const properties = e.target.feature.properties;
    const groupId = properties.group_id || properties.groupId;
    if (Progeny.rootGroupIsRegulator()) {
      highlightMs4ByGroupId(groupId);
    }
    selectRowWithGroupId(groupId).addClass("table-highlight");
  };

  var selectRowWithGroupId = function (groupId) {
    return PageFunctions.getCurrentPage().find(`li[data-group-id=${groupId}]`);
  };

  var zoomToMs4 = function (e) {
    this._map.fitBounds(e.target.getBounds());
    $(".bottom-floating-inputs-table").find(".active").removeClass("active");
    const properties = e.target.feature.properties;
    const groupId = properties.group_id || properties.groupId;
    selectRowWithGroupId(groupId).addClass("active");
  };

  var zoomToRegionPoint = function (e) {
    this._map.panTo(e.target.getLatLng());
    $(".bottom-floating-inputs-table").find(".active").removeClass("active");
    const properties = e.target.feature.properties;
    const groupId = properties.group_id || properties.groupId;
    selectRowWithGroupId(groupId).addClass("active");
  };

  var loadMs4Table = function (ms4s = Progeny.getActiveGroupProgeny()) {
    const html = nunjucks.render("report/table/ms4Table.html", {
      data: ms4s,
      disableNonParents: true,
    });
    $(".bottom-floating-inputs-table").html(html);
    RegionDropdown.handleDisablingRegionButtons();
  };

  return {
    loadMs4Table,
    loadListeners,
    zoomToMs4,
    resetHighlightMs4,
    highlightMs4Feature,
    zoomToRegionPoint,
    removeAllActive,
  };
};

module.exports = Ms4Table();

const Tree = require("../tree");
const TreeUpdates = require("./mapFunctions/treeUpdates");
const MapStyles = require("./mapFunctions/mapStyles");
const Progeny = require("../login/progeny");
const RegionalView = require("./regionalView");
const RegionDropdown = require("./regionDropdown");
const PageFunctions = require("./pageFunctions");
const Kpi = require("./report/kpi");
