"use strict";

const ProjectInspectionController = function () {
  const htmlPath = "modals/projectInspection";
  const formKey = "project-inspection";

  let $modal;
  let tabs;
  let dataStore;

  var loadNewProjectInspection = async function (projectId, readOnly = false) {
    var newDataStore;
    try {
      newDataStore = await ResourceController.get("constructionProjectInspection").createNew(
        projectId,
      );
    } catch (e) {
      if (!(e instanceof DataStoreError)) {
        throw e;
      }

      return;
    }
    _showProjectInspectionModal(newDataStore, readOnly);
  };

  var loadExistingProjectInspection = async function (inspectionId, readOnly = false) {
    var newDataStore;
    try {
      newDataStore = await ResourceController.get("constructionProjectInspection").openExisting(
        inspectionId,
      );
    } catch (e) {
      if (!(e instanceof DataStoreError)) {
        throw e;
      }

      return;
    }
    _showProjectInspectionModal(newDataStore, readOnly);
  };

  var _showProjectInspectionModal = function (newDataStore, readOnly) {
    dataStore = newDataStore;
    const ProjectInspectionGeneralInfo = require("./projectInspectionGeneralInfo");
    const ProjectInspectionSiteDocuments = require("./projectInspectionSiteDocuments");
    const ProjectInspectionCloseOutObservations = require("./projectInspectionCloseOutObservations");
    const ProjectInspectionRequiredObservations = require("./projectInspectionRequiredObservations");
    const ProjectInspectionAdditionalObservations = require("./projectInspectionAdditionalObservations");
    const ProjectInspectionObservationFindings = require("./projectInspectionObservationFindings");
    const ProjectInspectionAdministrativeFindings = require("./projectInspectionAdministrativeFindings");

    if (ToolSettings.getSetting("construction", "is-findings-workflow")) {
      tabs = [
        ProjectInspectionGeneralInfo,
        ProjectInspectionAdministrativeFindings,
        ProjectInspectionObservationFindings,
        ProjectInspectionCloseOutObservations,
        ProjectInspectionSubmission,
      ];
    } else {
      tabs = [
        ProjectInspectionGeneralInfo,
        ProjectInspectionSiteDocuments,
        ProjectInspectionCloseOutObservations,
        ProjectInspectionRequiredObservations,
        ProjectInspectionAdditionalObservations,
        ProjectInspectionSubmission,
      ];
    }
    const renderData = mapTabProps(tabs);

    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, { readOnly });
    Form.initializeAndLoadListeners($modal, formKey, { dataStore });
    FormSettings.customizeForm($modal, formKey);

    loadDropzone();

    TabbedModal.setHeaderText(getHeaderHtml(renderData));
    setAdditionalModalHandlers();
    updateVisibleTabs();
    loadListeners();
  };

  var loadListeners = function () {
    $modal.off("2N:FormInput", rerenderSummary);
    $modal.on("2N:FormInput", rerenderSummary);
  };

  var unloadListeners = function () {
    $modal.off("2N:FormInput", rerenderSummary);
  };

  var reloadListeners = function () {
    if (!$modal) {
      return;
    }

    Form.initializeDatePickers($modal, formKey);
    Form.initializeAutoNumericFields($modal);
  };

  var rerenderSummary = function () {
    ProjectInspectionSubmission.renderSummary(getAllData());
  };

  var mapTabProps = function (tabs) {
    const data = dataStore.getWithUpdates();

    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
      base: Config.get().apiUrl,
      isFindingsWorkflow: ToolSettings.getSetting("construction", "is-findings-workflow"),
    };
  };

  var getHeaderHtml = function (data) {
    return nunjucks.render("modals/projectInspection/projectInspectionHeader.njk", data);
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(() => {
      dataStore.discard();
      unloadListeners();
    });
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  };

  var saveButtonClick = function () {
    return new Promise((resolve) => {
      const fields = ProjectInspectionValidator.getMissingRequiredFields(getAllData());
      _saveOrWarnInvalid(fields);
      resolve(false);
    });
  };

  var _saveOrWarnInvalid = function (fields) {
    showSaveAndWarnModal(fields);
    highlightInvalidFields(fields);
  };

  var saveAndCloseModal = async function () {
    try {
      await Form.getReadyToSavePromise(formKey);
      if (dataStore.getForm().locked) {
        await dataStore.lock();
      } else {
        await dataStore.save();
      }
      unloadListeners();
      TabbedModal.hide();
      ProjectInventoryModalController.reopenPopupForUpdatedData();
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  };

  var getDataToUpload = function () {
    return getUpdateData(dataStore.getInitial(), dataStore.getForm(), dataStore.isNew());
  };

  var getUpdateData = function (initialData, dataToUpload, isNew) {
    if (isNew) {
      dataToUpload = mergeDefaultsIntoUpdates(initialData, dataToUpload);
    }

    dataToUpload.score = ProjectInspectionSummary.getSummaryData(
      $.extend(true, {}, initialData, dataToUpload),
    ).scoreNumber;
    prepareObservations(dataToUpload.requiredObservations, initialData.requiredObservations);
    prepareObservations(dataToUpload.additionalObservations, initialData.additionalObservations);
    prepareObservations(dataToUpload.administrativeFindings, initialData.administrativeFindings);
    prepareObservations(dataToUpload.observationFindings, initialData.observationFindings);

    if (isNew) {
      return {
        projectId: initialData.project.id,
        updates: dataToUpload,
      };
    } else {
      return {
        id: initialData.id,
        updates: dataToUpload,
      };
    }
  };

  var mergeDefaultsIntoUpdates = function (initialData, dataToUpload) {
    const result = $.extend(true, {}, initialData, dataToUpload);
    delete result.project;
    return result;
  };

  var prepareObservations = function (observationsMap, existingObservations) {
    for (const key in observationsMap) {
      prepareRequiredObservation(observationsMap, key, existingObservations);
    }
  };

  var prepareRequiredObservation = function (observationsMap, key, existingObservations) {
    if (observationsMap[key].length === 0) {
      delete observationsMap[key];
      return;
    }

    observationsMap[key] = addIdsToArray(observationsMap[key], existingObservations[key]);
    observationsMap[key] = filterDeletedAndUnsaved(observationsMap[key]);
  };

  var addIdsToArray = function (array, arrayWithIds) {
    for (var i = 0; i < array.length; i++) {
      const id = arrayWithIds?.[i]?.id;
      if (array[i] && id) {
        array[i].id = id;
      }
    }
    return array;
  };

  var filterDeletedAndUnsaved = function (array) {
    return array.filter((item) => item && (item.deleted !== true || item.id !== undefined));
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getAllData = function () {
    return dataStore.getWithUpdates();
  };

  var updateVisibleTabs = function () {
    const inspectionType = getAllData().inspectionType;

    const isCloseOut = inspectionType === "close-out";

    TabbedModal.toggleTab("close-out-observations", isCloseOut);
    TabbedModal.toggleTab("additional-observations", !isCloseOut);
    TabbedModal.toggleTab("required-observations", !isCloseOut);
    TabbedModal.toggleTab("administrative-findings", !isCloseOut);
    TabbedModal.toggleTab("observation-findings", !isCloseOut);
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var getFormKey = function () {
    return formKey;
  };

  var showSaveAndWarnModal = function (fields) {
    const messages = fields.map((field) => field.message);
    const uniqueMessages = new Set(messages);

    const incompleteFields = {
      entityName: dataStore.getInitial().project.projectIdentifier,
      entityKind: "inspection",
      incompleteFieldNames: [...uniqueMessages],
    };
    const buttonCallbacks = {
      cancelCallback: null,
      saveCallback: saveAndCloseModal,
      submitCallback: function () {
        setFormData(["locked"], true);
        saveAndCloseModal();
      },
    };

    SaveIncompleteModal.showWarnSaveSubmitModal(incompleteFields, buttonCallbacks);
  };

  var highlightInvalidFields = function (fields) {
    const fieldNames = fields.map((field) => field.fieldName);
    CommonModalFunctions.highlightFormFields($modal, fieldNames);
  };

  var loadDropzone = function () {
    const existingFiles = dataStore.getInitial().generalFiles ?? [];

    Form.initializeDropzone(formKey, $modal, {
      existingFiles: existingFiles,
      newFiles: dataStore.getForm().generalFiles,
      downloadCallback: ApiCalls.downloadConstructionProjectInspectionFile,
    });
  };

  return {
    getAllData,
    loadNewProjectInspection,
    loadExistingProjectInspection,
    saveAndCloseModal,
    updateVisibleTabs,
    setFormData,
    getFormKey,
    reloadListeners,
    getDataToUpload,
    _showProjectInspectionModal,
    getUpdateData,
    _saveOrWarnInvalid,
  };
};

module.exports = ProjectInspectionController();

const CommonModalFunctions = require("../../modals/commonModalFunctions");
const Config = require("../../config");
const DataStoreError = require("../../errors/dataStoreError");
const Form = require("../../general/form");
const FormSettings = require("../../settings/formSettings");
const ProjectInspectionSubmission = require("./projectInspectionSubmission");
const ProjectInspectionSummary = require("./projectInspectionSummary");
const ProjectInspectionValidator = require("./projectInspectionValidator");
const ResourceController = require("../../offline/resourceController");
const SaveIncompleteModal = require("../../modals/saveIncompleteModal");
const TabbedModal = require("../../general/tabbedModal");
const ToolSettings = require("../../settings/toolSettings");
const UploadValidator = require("../../general/uploadValidator");
const ProjectInventoryModalController = require("../projectInventoryModalController");
const ApiCalls = require("../../apiCalls");
