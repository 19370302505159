"use strict";

const ProjectFactSheetContoller = function () {
  let $parent;
  let currentProjectData = null;
  let currentProjectConfigs;

  var downloadInspectionReport = function () {
    var inspectionId = $getRow($(this)).data("id");
    var inspectionType = $getRow($(this)).data("type");
    ApiCalls.downloadConstructionInspectionReport(inspectionId, null, inspectionType);
  };

  var downloadPermitPlanFile = function (e) {
    const permitPlanId = $(e.currentTarget).parents(`[data-category=permit]`).data("id-permit");
    ApiCalls.downloadPermitPlanFile(permitPlanId);
  };

  var initFactSheet = async function (id) {
    const dataView = Tree.get("dataView");
    $parent = $("#fact-sheet-container");
    Tree.set(["factSheet", "open"], true);
    currentProjectData = await getData(id);

    if (dataView === "construction-project-delivery") {
      currentProjectConfigs = FactSheetConfig.getFactSheetConfig("project-delivery-projects");
    } else {
      currentProjectConfigs = FactSheetConfig.getFactSheetConfig("construction-projects");
    }

    FactSheet.init(currentProjectData, currentProjectConfigs);
    loadDomListeners();
  };

  var getData = async function (id) {
    try {
      return await ApiCalls.getConstructionProjectForFactSheet(id);
    } catch (e) {
      ApiError.assertIs(e);
    }

    return await ResourceController.get("constructionProjectG2").getById(id);
  };

  var loadDomListeners = function () {
    $parent.off("click", ".show-enforcement-action");
    $parent.on("click", ".show-enforcement-action", showEnforcementActionModal);

    $parent.off("click", ".show-enforcement-action-modal");
    $parent.on("click", ".show-enforcement-action-modal", onInitialEnforcementActionModal);

    $parent.off("click", ".download-inspection");
    $parent.on("click", ".download-inspection", downloadInspectionReport);

    $parent.off("click", ".new-contact-button-fs", showNewContactModal);
    $parent.on("click", ".new-contact-button-fs", showNewContactModal);

    $parent.off("click", ".new-user-button-fs", showNewUserModal);
    $parent.on("click", ".new-user-button-fs", showNewUserModal);

    $parent.off("click", ".edit-contact", showEditContactModal);
    $parent.on("click", ".edit-contact", showEditContactModal);

    $parent.off("click", ".edit-user", showEditUserModal);
    $parent.on("click", ".edit-user", showEditUserModal);

    $parent.off("click", ".edit-asset", showEditAssetModal);
    $parent.on("click", ".edit-asset", showEditAssetModal);

    $parent.off("click", ".upload-new-file-button", showNewFileModal);
    $parent.on("click", ".upload-new-file-button", showNewFileModal);

    $parent.off("click", ".edit-file", showEditFileModal);
    $parent.on("click", ".edit-file", showEditFileModal);

    $parent.off("click", ".download-file", downloadPermitPlanFile);
    $parent.on("click", ".download-file", downloadPermitPlanFile);

    $parent.off("click", ".js-delete-construction-project-inspection", deleteProjectInspection);
    $parent.on("click", ".js-delete-construction-project-inspection", deleteProjectInspection);
  };

  var showEditContactModal = function (e) {
    const projectId = currentProjectData.id;
    const contactId = $(e.currentTarget).parents(`[data-category=contact]`).data("id-contact");
    const options = {
      editContact: contactId,
    };
    ProjectInventoryModalController.invokeProjectInventoryModal(
      projectId,
      "project-contacts",
      options,
    );
  };

  var showEditUserModal = function (e) {
    const projectId = currentProjectData.id;
    const userId = $(e.currentTarget).parents(`[data-category=user]`).data("id-user");
    const options = {
      editUser: userId,
    };
    ProjectInventoryModalController.invokeProjectInventoryModal(
      projectId,
      "project-users",
      options,
    );
  };

  var showEditAssetModal = function (e) {
    const assetId = $(e.currentTarget).parents(`[data-category=assets]`).data("id-asset");
    PopupContent.showBmpFcsInventoryModal(assetId);
  };

  var showEditFileModal = function (e) {
    const fileId = $(e.currentTarget).parents(`[data-category=permit]`).data("id-permit");
    const options = {
      editFile: fileId,
    };
    showInventoryModal("project-files", options);
  };

  var showEnforcementActionModal = function () {
    const enforcementActionId = $getRow($(this)).data("id");
    $("#enforcement-action-modal").one("hidden.bs.modal", () => {
      FactSheet.init(currentProjectData, currentProjectConfigs);
    });
    EnforcementActionModalController.getAndRenderEnforcementAction(enforcementActionId);
  };

  var onInitialEnforcementActionModal = function () {
    const inspectionId = $getRow($(this)).data("id");
    $("#enforcement-action-modal").one("hidden.bs.modal", () => {
      FactSheet.init(currentProjectData, currentProjectConfigs);
    });

    ConstructionProjectPopup.openEnforcementActionModalForInspection(inspectionId);
  };

  var showNewContactModal = function () {
    const id = currentProjectData.id;
    const options = {
      newContact: true,
    };
    ProjectInventoryModalController.invokeProjectInventoryModal(id, "project-contacts", options);
  };

  var showNewUserModal = function () {
    const id = currentProjectData.id;
    const options = {
      newUser: true,
    };
    ProjectInventoryModalController.invokeProjectInventoryModal(id, "project-users", options);
  };

  var showNewFileModal = function () {
    const options = { newFile: true };
    showInventoryModal("project-files", options);
  };

  var showInventoryModal = function (page, options) {
    const projectId = currentProjectData.id;
    ProjectInventoryModalController.invokeProjectInventoryModal(projectId, page, options);
  };

  var deleteProjectInspection = function () {
    const $this = $(this);
    const inspectionId = $getRow($(this)).data("id");
    MessageModal.showDeleteRecordModal(async function () {
      await ApiCalls.deleteConstructionProjectInspection(inspectionId);
      $this.parents("tr").remove();
    });
  };

  var $getRow = function ($this) {
    return $this.parents(".history-item-tr");
  };

  return {
    initFactSheet,
    downloadPermitPlanFile,
    downloadInspectionReport,
    deleteProjectInspection,
  };
};

module.exports = ProjectFactSheetContoller();

const ApiCalls = require("../apiCalls");
const ApiError = require("../errors/apiError");
const EnforcementActionModalController = require("../construction/enforcementActionModalController");
const FactSheet = require("../general/factSheet");
const FactSheetConfig = require("../config/factSheetConfig");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const ResourceController = require("../offline/resourceController");
const Tree = require("../tree");
const MessageModal = require("../modals/messageModal");
const ConstructionProjectPopup = require("./constructionProjectPopup");
const PopupContent = require("../mapFunctions/popupContent");
