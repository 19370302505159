"use strict";

const ListPanel = function () {
  const $thisListPanel = $("#list-panel");

  var init = function () {
    Tree.watch({
      activeTab: ["activeTab"],
      dataView: ["dataView"],
      navToggleSelection: ["navToggleSelection"],
    }).on("update", (e) => {
      const data = e.target.get();

      if (PageFunctions.getPageConfig(data.activeTab).noPanels) {
        return;
      }

      if (data.navToggleSelection === "list") {
        if (data.activeTab === "data" || data.activeTab === "insight") {
          show(data.activeTab, data.dataView, true);
        } else {
          show(data.activeTab, data.dataView);
        }
      } else {
        hide();
      }
    });
  };

  var show = function (activeTab, dataView, reInitialize = false) {
    $("#mapContent").addClass("default-hidden");

    const dataListControllersByDataView = {
      "construction-project": ConstructionProjectDataListController,
      "construction-project-delivery": ConstructionProjectDeliveryDataListController,
      "muni-open-channel": MuniOpenChannelDataListController,
      "indcom-facilities": IndcomListController,
      "lid-project": LidProjectDataListController,
      "muni-catch-basin": MuniCatchBasinDataListController,
      "muni-culvert": MuniCulvertsDataListController,
      "muni-outfalls": MuniOutfallDataListController,
      "muni-manhole": MuniManholeDataListController,
      "muni-bmp": MuniBmpDataListController,
      "lid-bmp": MuniBmpDataListController,
      bmp: StructuralBmpDataListController,
      "muni-cleanout": MuniCleanoutDataListController,
      "muni-pipes": MuniPipesDataListController,
      "muni-lift-stations": MuniLiftStationsDataListController,
      activities: ActivitiesListController,
      scenarios: ScenarioDataListController,
      fcs: TrashFcsDataListController,
    };

    const insightDashboardControllersByDataView = {
      incidents: IddeIncidentInsightDashboardController,
      scenarios: ScenarioInsightDashboardController,
      "indcom-facilities": IndcomFacilityInsightDashboardController,
      "muni-catch-basin": MuniCatchBasinInsightDashboardController,
      "muni-outfalls": MuniOutfallInsightDashboardController,
      "lid-project": LidProjectInsightDashboardController,
      roads: StreetSweepingInsightDashboardController,
      "construction-project": ConstructionProjectInsightDashboardController,
    };

    let controller;
    if (activeTab === "todo") {
      controller = ToDoListController;
      $thisListPanel.find(".data-list-container, .insight-container").hide();
      $thisListPanel.find(".todo-list-container").show();
    } else if (activeTab === "data") {
      controller = dataListControllersByDataView[dataView];
      $thisListPanel.find(".todo-list-container, .insight-container").hide();
      $thisListPanel.find(".data-list-container").show();
    } else if (activeTab === "insight") {
      controller = insightDashboardControllersByDataView[dataView];
      $thisListPanel.find(".data-list-container, .todo-list-container").hide();
      $thisListPanel.find(".insight-container").show();
    } else {
      throw new Error(`Unexpected activeTab ${activeTab} for list panel.`);
    }
    if (!controller) {
      throw Error(`Trying to open list view with no controller defined for data list ${dataView}.`);
    }

    controller.init(reInitialize);
    $thisListPanel.addClass("active");
  };

  var hide = function () {
    $("#mapContent").removeClass("default-hidden");
    $thisListPanel.removeClass("active");
  };

  return {
    init,
    hide,
    show,
  };
};

module.exports = ListPanel();

const ActivitiesListController = require("../peo/activitiesListController");
const ToDoListController = require("./toDoListController");
const ConstructionProjectDataListController = require("../construction/constructionProjectDataListController");
const ConstructionProjectDeliveryDataListController = require("../construction/constructionProjectDeliveryDataListController");
const ConstructionProjectInsightDashboardController = require("../construction/constructionProjectInsightDashboardController");
const MuniOpenChannelDataListController = require("../muni/muniOpenChannelDataListController");
const StructuralBmpDataListController = require("../bmpfcs/structuralBmpDataListController");
const MuniCatchBasinDataListController = require("../muni/muniCatchBasinDataListController");
const MuniCulvertsDataListController = require("../muni/muniCulvertsDataListController.js");
const MuniOutfallDataListController = require("../muni/muniOutfallDataListController");
const MuniManholeDataListController = require("../muni/muniManholeDataListController");
const MuniCatchBasinInsightDashboardController = require("../muni/muniCatchBasinInsightDashboardController");
const MuniOutfallInsightDashboardController = require("../muni/outfalls/muniOutfallInsightDashboardController");
const IddeIncidentInsightDashboardController = require("../illicitDischarge/iddeIncidentInsightDashboardController");
const IndcomFacilityInsightDashboardController = require("../indcom/indcomFacilityInsightDashboardController");
const ScenarioInsightDashboardController = require("../scenario/scenarioInsightDashboardController");
const ScenarioDataListController = require("../scenario/scenarioDataListController");
const StreetSweepingInsightDashboardController = require("../muni/roads/streetSweepingInsightDashboardController");
const IndcomListController = require("../indcom/indcomListController");
const LidProjectDataListController = require("../lid/lidProjectDataListController");
const LidProjectInsightDashboardController = require("../lid/lidProjectInsightDashboardController");
const TrashFcsDataListController = require("../bmpfcs/trashFcsDataListController");
const Tree = require("../tree");
const PageFunctions = require("../pageFunctions");
const MuniBmpDataListController = require("../muni/muniBmpDataListController");
const MuniCleanoutDataListController = require("../muni/muniCleanoutDataListController");
const MuniPipesDataListController = require("../muni/pipes/muniPipesDataListController");
const MuniLiftStationsDataListController = require("../muni/liftStations/muniLiftStationsDataListController");
