"use strict";

const ModalFunctions = function () {
  var showWarningModal = function (message, title, isHtml = false) {
    const $warningModal = $("#warning-modal");
    const $textElement = $warningModal.find(".modal-body > p");
    const $titleElement = $warningModal.find(".modal-title");

    if (isHtml) {
      $textElement.html(message);
    } else {
      $textElement.text(message);
    }

    $titleElement.text(title);

    $warningModal.modal("show");
  };

  var showSimpleWarningModal = function (warningMessage, isHtml = false) {
    showWarningModal(warningMessage, "Warning", isHtml);
  };

  // @TODO: Refactor an all-purpose modal function
  var showConfirmWarningModal = function (
    message,
    confirmCallback,
    returnMessage,
    okMessage,
    messageHtml = null,
    cancelCallback = () => {},
    modalTitle = "Warning",
  ) {
    var $warningModal = $("#dynamic-warning-modal");
    returnMessage = returnMessage || "Return";
    okMessage = okMessage || "Okay";
    var modalContent = nunjucks.render("report/modals/confirmWarning.njk", {
      message,
      returnMessage,
      okMessage,
      messageHtml,
      modalTitle,
    });
    $warningModal.html(modalContent);
    $warningModal.modal("toggle");
    $warningModal.off();
    $warningModal.on("click", "button", function () {
      $warningModal.modal("toggle");

      if (this.value === "return") {
        cancelCallback();
      } else {
        confirmCallback();
      }
    });
  };

  return {
    showWarningModal,
    showSimpleWarningModal,
    showConfirmWarningModal,
  };
};

module.exports = ModalFunctions();
