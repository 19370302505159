"use strict";

const EsriLayerFunctions = function () {
  var publishingServerToken;
  var groupIdFilterStr;
  const esriLayerUrlByEsriKey = {
    streetSweepingRoute:
      "https://2nformspatial.com/server/rest/services/street_sweeping_routes_roads/MapServer",
    roadsOm: "https://2nformspatial.com/server/rest/services/Road_Conditions/MapServer",
    soilImpervious:
      "https://2nformspatial.com/server/rest/services/Annual_Reporting_Input_Layers/MapServer",
    trash: "https://2nformspatial.com/server/rest/services/Trash/MapServer",
    parcelsPlu: "https://2nformspatial.com/server/rest/services/Priority_Landuse/MapServer",
  };

  var getEsriLayer = async function (esriKey, esriLayer, options = {}) {
    const esriLayerUrl = getEsriLayerUrl(esriKey);
    var token = await getEsriLayerToken();

    const layerId = getLayerId(esriKey, esriLayer);
    const layerOptions = {
      url: esriLayerUrl,
      layers: [layerId],
      token: token,
    };
    return L.esri.dynamicMapLayer(Object.assign(layerOptions, options));
  };

  var getEsriLayerUrl = function (esriKey) {
    if (!esriLayerUrlByEsriKey[esriKey]) {
      throw new Error("esriKey not defined in esriLayerUrlByEsriKey: " + esriKey);
    }
    return esriLayerUrlByEsriKey[esriKey];
  };

  var getEsriFeatureLayer = async function (esriKey, esriLayer, options = {}) {
    const esriLayerUrl = getEsriLayerUrl(esriKey);
    var token = await getEsriLayerToken();

    const layerId = getLayerId(esriKey, esriLayer);
    const layerOptions = {
      url: `${esriLayerUrl}/${layerId}`,
      layers: [layerId],
      token: token,
      style: function () {
        return { color: "#70ca49", weight: 2 };
      },
      disableClusteringAtZoom: 16,
    };

    if (sessionStorage.getItem("esriLayer") === "feature-cluster") {
      return L.esri.Cluster.featureLayer(Object.assign(layerOptions, options));
    } else {
      return L.esri.featureLayer(Object.assign(layerOptions, options));
    }
  };

  var getEsriLayerToken = async function () {
    if (!publishingServerToken) {
      publishingServerToken = await ApiCalls.getEsriLayerToken("publishing");
      return publishingServerToken;
    } else {
      return publishingServerToken;
    }
  };

  var getLayerDefs = function (esriKey, esriLayer, skipSpatial = false, emptyLayerDef = false) {
    var filtersStringArray = [];
    var esriFilters = {};
    const config = EsriConfig.get()[esriKey];
    const esriFiltersMap = config["filters"];
    const layerId = config["ids"][esriLayer];
    const activeTab = Tree.get("activeTab");

    var twoNFilters;
    if (activeTab === "todo") {
      twoNFilters = FilterConstants.getDefaultFiltersByDataView();
      delete twoNFilters.phase;
    } else {
      twoNFilters = Tree.get("filters");
      if (!skipSpatial) {
        addEsriSpatialFilters(esriFilters, twoNFilters, esriFiltersMap);
      }
    }
    // Only add main filters for Muni Catch Basin layer
    if (Tree.get("dataView") === "muni-catch-basin" && esriLayer === "catch-basin") {
      addEsriFilterMenuFilters(esriFilters, twoNFilters, esriFiltersMap);
    }

    if (emptyLayerDef) {
      filtersStringArray.push("1=1");
    } else {
      for (const key in esriFilters) {
        if (key === "SEARCH_STRING") {
          filtersStringArray.push(searchFilterToSql(key, esriFilters[key]));
        } else if (key === "catch_basin_priority") {
          filtersStringArray.push(catchBasinPriorityFilterToSql(key, esriFilters[key]));
        } else if (key === "BMP_CREATION_FROM" || key === "BMP_CREATION_TO") {
          filtersStringArray.push(creationDateFilterToSql(key, esriFilters[key]));
        } else if (key === "zone_name") {
          filtersStringArray.push(maintenanceZoneFilterToSql(key, esriFilters[key]));
        } else if (Array.isArray(esriFilters[key])) {
          filtersStringArray.push(arrayToSqlIn(key, esriFilters[key]));
        } else {
          filtersStringArray.push(valueToSqlEqual(key, esriFilters[key]));
        }
      }

      addGroupIdFilterString(filtersStringArray);
      if (
        esriLayer === "landuse" ||
        esriLayer === "trashCondition" ||
        esriLayer === "parcels_plu"
      ) {
        const year = Tree.get("waterYear");
        filtersStringArray.push(getYearSQL(year, config, esriLayer));
      }

      if (activeTab === "todo" && esriKey === "parentView" && esriLayer === "catch-basin") {
        addEsriTodoFilterString(filtersStringArray, esriFiltersMap);
      }
    }

    filtersStringArray = filtersStringArray.filter((str) => str !== "");
    return getLayerDefinitionByLayer(layerId, filtersStringArray);
  };

  var getLayerDefinitionByLayer = function (layerId, filtersStringArray) {
    const layerDefinitions = {};

    if (Array.isArray(layerId)) {
      for (const layer in layerId) {
        layerDefinitions[layer] = filtersStringArray.join(" AND ");
      }
    } else {
      layerDefinitions[layerId] = filtersStringArray.join(" AND ");
    }

    return layerDefinitions;
  };

  var addGroupIdFilterString = function (filtersStringArray) {
    if (!groupIdFilterStr) {
      const progeny = Progeny.getActiveGroupProgeny();
      if (progeny) {
        const progenyGroupIds = progeny.map((group) => group.groupId);
        if (progenyGroupIds?.length) {
          groupIdFilterStr = `group_id IN (${progenyGroupIds.join(",")})`;
        } else {
          groupIdFilterStr = `group_id = ${Tree.get("activeGroup", "groupId")}`;
        }
      }
    }
    if (groupIdFilterStr) {
      filtersStringArray.push(groupIdFilterStr);
    }
  };

  var getLayerDefsValueByLayerId = function (esriKey, esriLayer, layerId, skipSpatial = false) {
    const layerDefs = getLayerDefs(esriKey, esriLayer, skipSpatial);
    if (layerDefs) {
      return layerDefs[layerId];
    }
  };

  var addEsriSpatialFilters = function (esriFilters, twoNFilters, filtersMap) {
    const dataSort = Tree.get(["table", "dataSort"]);
    if (twoNFilters["receivingWaters"] && twoNFilters["receivingWaters"].length > 0) {
      if (twoNFilters["spatialView"] === "drainage" || dataSort === "ud") {
        esriFilters[filtersMap["drainsToC"]] = twoNFilters["receivingWaters"];
      } else {
        esriFilters[filtersMap["drainsToRw"]] = twoNFilters["receivingWaters"];
      }
    }

    if (twoNFilters["catchments"] && twoNFilters["catchments"].length > 0) {
      esriFilters[filtersMap["catchments"]] = twoNFilters["catchments"];
    }
    if (twoNFilters["maintenanceZones"] && twoNFilters["maintenanceZones"].length > 0) {
      esriFilters[filtersMap["maintenanceZones"]] = twoNFilters["maintenanceZones"];
    }
    if (twoNFilters["sweepingNetworks"] && twoNFilters["sweepingNetworks"].length > 0) {
      esriFilters[filtersMap["sweepingNetworks"]] = twoNFilters["sweepingNetworks"];
    }
  };

  var addEsriFilterMenuFilters = function (esriFilters, twoNFilters, filtersMap) {
    if (twoNFilters["types"] && filtersMap["types"]) {
      esriFilters[filtersMap["types"]] = twoNFilters["types"];
    }
    if (twoNFilters["phase"] && twoNFilters["phase"].length > 0 && filtersMap["phase"]) {
      esriFilters[filtersMap["phase"]] = twoNFilters["phase"];
    }
    if (twoNFilters["catchBasinPriority"] && filtersMap["catchBasinPriority"]) {
      esriFilters[filtersMap["catchBasinPriority"]] = twoNFilters["catchBasinPriority"];
    }
    if (
      twoNFilters["landOwnership"] &&
      twoNFilters["landOwnership"].length > 0 &&
      filtersMap["landOwnership"]
    ) {
      esriFilters[filtersMap["landOwnership"]] = twoNFilters["landOwnership"];
    }
    if (twoNFilters["responsiblePartyMaintenance"] && filtersMap["responsiblePartyMaintenance"]) {
      esriFilters[filtersMap["responsiblePartyMaintenance"]] =
        twoNFilters["responsiblePartyMaintenance"];
    }
    if (twoNFilters["fcs"] && filtersMap["fcs"]) {
      esriFilters[filtersMap["fcs"]] = twoNFilters["fcs"];
    }
    if (twoNFilters["cleanout"] && filtersMap["cleanout"]) {
      esriFilters[filtersMap["cleanout"]] = twoNFilters["cleanout"];
    }
    if (twoNFilters["dateType"] === "install") {
      if (twoNFilters["dateFrom"] && filtersMap["dateFrom"]) {
        esriFilters[filtersMap["dateFrom"]] = twoNFilters["dateFrom"];
      }
      if (twoNFilters["dateTo"] && filtersMap["dateTo"]) {
        esriFilters[filtersMap["dateTo"]] = twoNFilters["dateTo"];
      }
    }
    if (twoNFilters["searchString"] && filtersMap["searchString"]) {
      esriFilters[filtersMap["searchString"]] = twoNFilters["searchString"];
    }
  };

  var addEsriTodoFilterString = function (filtersStringArray, esriFiltersMap) {
    const dataView = Tree.get("dataView");
    const selectedSubject = Tree.get(["todos", dataView, "selectedSubject"]);
    const toDoFilters = Tree.get("toDoFilters")?.[dataView];
    var todoFilterStringArray = [];

    for (const key in toDoFilters) {
      let todoFilterStringBySubject = "";
      if (key === "routineSiteVisit") {
        todoFilterStringBySubject = "inventory_todo_subject LIKE '%routine-site-visit%'";
      } else if (key === "catchBasinInventoryIncomplete") {
        todoFilterStringBySubject =
          "inventory_todo_subject LIKE '%catch-basin-inventory-incomplete%'";
      } else if (key === "routineRepair") {
        todoFilterStringBySubject = "maintenance_issue_todo_subject = 'routine-repair'";
      } else {
        throw new Error("Todo subject key not defined in toDoFilters: " + key);
      }

      if (toDoFilters[key]) {
        var todoFilterString = getTodoFilterStringForSubject(key, toDoFilters[key], esriFiltersMap);
        if (todoFilterString) {
          todoFilterStringBySubject += ` AND ${todoFilterString}`;
        }
      } else {
        throw new Error("Todo subject key not defined in toDoFilters: " + key);
      }

      if (selectedSubject && key !== selectedSubject) {
        todoFilterStringBySubject = null;
      }

      if (todoFilterStringBySubject) {
        todoFilterStringArray.push(`(${todoFilterStringBySubject})`);
      }
    }
    filtersStringArray.push("phase NOT IN ('planning')");
    var todoFilterStr = todoFilterStringArray.join(" OR ");
    if (todoFilterStr) {
      filtersStringArray.push(`(${todoFilterStr})`);
    }
  };

  var getTodoFilterStringForSubject = function (subjectKey, todoFilters, filtersMap) {
    var todoFiltersStringArray = [];
    var esriTodoFilters = {};
    addEsriTodoFilters(esriTodoFilters, todoFilters, filtersMap);

    for (const key in esriTodoFilters) {
      if (key === "catch_basin_priority") {
        todoFiltersStringArray.push(catchBasinPriorityFilterToSql(key, esriTodoFilters[key]));
      } else if (key === "in_proximity_to_303d_waterbody") {
        todoFiltersStringArray.push(
          inProximityTo303dWaterbodyFilterToSql(key, esriTodoFilters[key]),
        );
      } else if (key === "DUE_DATE_FROM" || key === "DUE_DATE_TO") {
        todoFiltersStringArray.push(dueDateFilterToSql(subjectKey, key, esriTodoFilters[key]));
      } else if (key === "zone_name") {
        todoFiltersStringArray.push(maintenanceZoneFilterToSql(key, esriTodoFilters[key]));
      } else if (key === "drains_to_rw" && esriTodoFilters[key] === null) {
        // Do nothing
      } else if (esriTodoFilters[key] === null) {
        todoFiltersStringArray.push("0 = 1");
      } else if (Array.isArray(esriTodoFilters[key])) {
        todoFiltersStringArray.push(arrayToSqlIn(key, esriTodoFilters[key]));
      } else {
        todoFiltersStringArray.push(valueToSqlEqual(key, esriTodoFilters[key]));
      }
    }
    todoFiltersStringArray = todoFiltersStringArray.filter((str) => str !== "");

    return todoFiltersStringArray.join(" AND ");
  };

  var addEsriTodoFilters = function (esriTodoFilters, todoFilters, filtersMap) {
    if ("catchBasinPriority" in todoFilters) {
      esriTodoFilters[filtersMap["catchBasinPriority"]] = todoFilters["catchBasinPriority"];
    }
    if ("inProximityTo303dWaterbody" in todoFilters) {
      esriTodoFilters[filtersMap["inProximityTo303dWaterbody"]] =
        todoFilters["inProximityTo303dWaterbody"];
    }
    if ("correctiveAction" in todoFilters) {
      esriTodoFilters[filtersMap["correctiveAction"]] = todoFilters["correctiveAction"];
    }
    if ("enforcementLevel" in todoFilters) {
      esriTodoFilters[filtersMap["enforcementLevel"]] = todoFilters["enforcementLevel"];
    }
    if (!todoFilters.allSpatial && "receivingWater" in todoFilters) {
      esriTodoFilters[filtersMap["receivingWater"]] = todoFilters["receivingWater"];
    }
    if (!todoFilters.allSpatial && "catchment" in todoFilters) {
      esriTodoFilters[filtersMap["catchment"]] = todoFilters["catchment"];
    }
    if (!todoFilters.allSpatial && "maintenanceZone" in todoFilters) {
      esriTodoFilters[filtersMap["maintenanceZone"]] = todoFilters["maintenanceZone"];
    }
    if (todoFilters["dueDateFrom"]) {
      esriTodoFilters[filtersMap["dueDateFrom"]] = todoFilters["dueDateFrom"];
    }
    if (todoFilters["dueDateTo"]) {
      esriTodoFilters[filtersMap["dueDateTo"]] = todoFilters["dueDateTo"];
    }
  };

  // @return "COLUMN IN ('VALUE', ...)"
  var arrayToSqlIn = function (key, filterArray) {
    if (["bmp_type", "responsible_party_maintenance"].includes(key)) {
      if (filterArray.length === 0) {
        return "0 = 1";
      }
    }

    const inString = filterArray
      .filter((filter) => filter !== "")
      .map((filter) => {
        if (Number.isInteger(filter) || filter === null) {
          return `${filter}`;
        } else {
          filter = filter.replace(/'/g, "''");
          return `'${filter}'`;
        }
      })
      .join(", ");

    var sqlIn = `${key} IN (${inString})`;
    if (["bmp_type", "bmp_ptype"].includes(key)) {
      return `(${sqlIn} OR ${key} IS NULL)`;
    } else {
      return sqlIn;
    }
  };

  var catchBasinPriorityFilterToSql = function (key, filterArray) {
    if (filterArray === null || filterArray.length === 0) {
      return `0 = 1`;
    } else if (filterArray.length === 4) {
      return "";
    }
    var inSql = arrayToSqlIn(key, filterArray);
    if (filterArray.includes("unknown")) {
      return `(${inSql} OR ${key} NOT IN ('high', 'medium', 'low') OR ${key} IS NULL)`;
    }
    return inSql;
  };

  var inProximityTo303dWaterbodyFilterToSql = function (key, filterArray) {
    if (filterArray === null || filterArray.length === 0) {
      return `0 = 1`;
    } else if (filterArray.length === 3) {
      return "";
    }
    key = "near_impaired_waterbody";
    var arr = [];
    if (filterArray.includes("true")) {
      arr.push("yes");
    }
    if (filterArray.includes("false")) {
      arr.push("no");
    }
    var inSql = arrayToSqlIn(key, arr);
    if (filterArray.includes("unknown")) {
      return `(${inSql} OR ${key} IS NULL)`;
    }
    return inSql;
  };

  var creationDateFilterToSql = function (key, dateValue) {
    var dateSql = "";
    if (key === "BMP_CREATION_FROM") {
      dateSql = `bmp_creation >= '${dateValue}'`;
    } else if (key === "BMP_CREATION_TO") {
      dateSql = `bmp_creation <= '${dateValue}'`;
    }
    return dateSql;
  };

  var maintenanceZoneFilterToSql = function (key, zoneArray) {
    if (zoneArray && zoneArray.length) {
      const dataView = Tree.get("dataView");
      if (dataView === "muni-catch-basin") {
        key = "formatted_zone_name";
        var similarStr = zoneArray.map((zone) => `%ZONE: ${zone}%`).join("|");
        return `${key} SIMILAR TO '${similarStr}'`;
      } else {
        return arrayToSqlIn(key, zoneArray);
      }
    }
    return "0 = 1";
  };

  var dueDateFilterToSql = function (subjectKey, key, dateValue) {
    var dateSql = "";
    var dueDateField;
    if (subjectKey === "routineSiteVisit") {
      dueDateField = "inventory_todo_due_date";
    } else if (subjectKey === "routineRepair") {
      dueDateField = "maintenance_issue_todo_due_date";
    } else {
      throw new Error("Due date field not defined for: " + subjectKey);
    }

    if (key === "DUE_DATE_FROM") {
      dateSql = `${dueDateField} >= '${dateValue}'`;
    } else if (key === "DUE_DATE_TO") {
      dateSql = `${dueDateField} <= '${dateValue}'`;
    }
    return dateSql;
  };

  var searchFilterToSql = function (key, searchString) {
    if (typeof searchString === "string") {
      searchString = searchString.toLowerCase();
    }
    return `(lower(bmp_id) LIKE '%${searchString}%' OR lower(address) LIKE '%${searchString}%' OR lower(facility) LIKE '%${searchString}%')`;
  };

  // @return "COLUMN = 'VALUE'"
  var valueToSqlEqual = function (key, filterValue) {
    if (typeof filterValue === "string") {
      return `${key} = '${filterValue}'`;
    } else {
      return `${key} = ${filterValue}`;
    }
  };

  var getYearSQL = function (year, config, esriLayer) {
    const reportYearValid = config["filters"]["reportYearFirstValid"];
    const reportYearNotValid = config["filters"]["reportYearNotValid"];
    var selectedYear = parseInt(year);
    if (esriLayer === "trashCondition") {
      return `year = ${selectedYear}`;
    } else {
      return `(${reportYearValid} <= ${selectedYear} OR ${reportYearValid} IS null) AND (${reportYearNotValid} > ${selectedYear} OR ${reportYearNotValid} IS null)`;
    }
  };

  var getLayerIds = function (esriKey) {
    const esriConfig = EsriConfig.get();
    if (esriKey in esriConfig) {
      const config = esriConfig[esriKey];
      return config["ids"];
    }
    throw new Error("esriKey not defined in EsriConfig: " + esriKey);
  };

  var getLayerId = function (esriKey, esriLayer, throwNotFound = true) {
    const esriConfig = EsriConfig.get();
    if (esriKey in esriConfig) {
      const config = esriConfig[esriKey];
      if (esriLayer in config["ids"]) {
        return config["ids"][esriLayer];
      }

      if (throwNotFound) throw new Error("esriLayer not defined in EsriConfig ids: " + esriLayer);
    }

    if (throwNotFound) throw new Error("esriKey not defined in EsriConfig: " + esriKey);
  };

  var getEsriLayerNameByLayerId = function (esriKey, layerId) {
    const esriConfig = EsriConfig.get();
    if (esriKey in esriConfig) {
      const config = esriConfig[esriKey];
      for (const key in config["ids"]) {
        if (config["ids"][key] === layerId) {
          return key;
        }
      }
      throw new Error("layerId not defined in EsriConfig" + layerId);
    }
    throw new Error("esriKey not defined in EsriConfig: " + esriKey);
  };

  var getLayerDefsQuery = function (esriKey, esriLayer) {
    const layerId = getLayerId(esriKey, esriLayer);
    const layerDefs = getLayerDefs(esriKey, esriLayer);
    return layerDefs[layerId];
  };

  var getLayerProperties = function (layer, esriKey, esriLayer, callback, layerId = null) {
    if (layerId === null) {
      layerId = getLayerId(esriKey, esriLayer);
    }
    const layerDefsQuery = getLayerDefsQuery(esriKey, esriLayer);

    layer
      .query()
      .layer(layerId)
      .where(layerDefsQuery)
      .run((error, featureCollection, response) => {
        if (callback) {
          callback(featureCollection);
        }
      });
  };

  var getLayerCount = function (layer, esriKey, esriLayer, callback, layerId = null) {
    if (layerId === null) {
      layerId = getLayerId(esriKey, esriLayer);
    }
    const layerDefsQuery = getLayerDefsQuery(esriKey, esriLayer);

    layer
      .query()
      .layer(layerId)
      .where(layerDefsQuery)
      .count(function (error, count, response) {
        if (error) {
          console.log(error);
          return;
        }
        if (callback) {
          callback(count);
        }
      });
  };

  var getLayerIdsToIdentify = function (esriKey) {
    const dataView = Tree.get("dataView");
    var layerIdsToIdentify = [];

    if (["muni-catch-basin", "dry-weather"].includes(dataView)) {
      let dataSortLayerId;
      if (Tree.get("availableDataSortLayers").includes("maintenanceZones")) {
        dataSortLayerId = getLayerId(esriKey, "maintenanceZone");
      } else {
        dataSortLayerId = getLayerId(esriKey, "catchment");
      }
      layerIdsToIdentify.push(dataSortLayerId);
    }

    const enabledEsriLayers = Tree.get("enabledEsriLayers");
    if (enabledEsriLayers?.length) {
      const enabledLayerIds = enabledEsriLayers.map((esriLayer) =>
        getLayerId(esriKey, esriLayer, false),
      );
      if (enabledLayerIds) {
        layerIdsToIdentify = layerIdsToIdentify.concat(enabledLayerIds);
      }
    }
    if (Tree.get("layers", "streams", "isEnabled")) {
      layerIdsToIdentify.push(getLayerId(esriKey, "streams"));
    }

    return layerIdsToIdentify.sort();
  };

  return {
    getEsriLayer,
    getEsriLayerUrl,
    getEsriFeatureLayer,
    getEsriLayerToken,
    getEsriLayerNameByLayerId,
    getLayerDefs,
    getLayerDefsQuery,
    getLayerDefsValueByLayerId,
    getLayerProperties,
    getLayerCount,
    getLayerId,
    getLayerIds,
    getLayerIdsToIdentify,
  };
};

module.exports = EsriLayerFunctions();

const ApiCalls = require("../apiCalls");
const EsriConfig = require("../config/esriConfig");
const FilterConstants = require("../filterConstants");
const Progeny = require("../login/progeny");
const Tree = require("../tree");
