"use strict";

const MapPaneFunctions = function () {
  var createCustomMapPanes = function (map) {
    // Custom overlay panes, default overlayPane zindex = 400
    map.createPane("underCatchmentPane").style.zIndex = 410;
    map.createPane("catchmentPane").style.zIndex = 420;
    map.createPane("overCatchmentPane").style.zIndex = 430;
    map.createPane("catchmentViewPane").style.zIndex = 435;
    map.createPane("stormdrainPane").style.zIndex = 440;
    map.createPane("ms4BoundaryPane").style.zIndex = 450;
    map.createPane("ms4BoundaryGrayPane").style.zIndex = 451;
    map.createPane("overBoundaryPane").style.zIndex = 460;

    // Custom marker panes, default markerPane zindex = 600
    map.createPane("flowRoutingPane").style.zIndex = 580;
    map.createPane("outfallPane").style.zIndex = 590;
  };

  return {
    createCustomMapPanes,
  };
};

module.exports = MapPaneFunctions();
