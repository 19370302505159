"use strict";

function Downloader() {
  var fromApi = async function (method, url, data) {
    let file;
    try {
      file = await Network.apiAsFilePromise(method, url, data);
    } catch (e) {
      const message = getApiErrorMessage(e);
      MessageModal.showSimpleWarningModal(message, true);
      return null;
    }

    fromBlob(file, file.name);
  };

  var fromBlob = function (blob, fileName = "") {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
  };

  var listenToDropzone = function (dropzone, existingFiles, apiCallback) {
    dropzone.setDownloadHandler(function (filename) {
      const file = existingFiles.find((fileObj) => fileObj?.filename === filename);

      if (!file) {
        throw new Error(`No associated file ID found for filename "${filename}".`);
      }

      apiCallback(file.id);
    });
  };

  var getApiErrorMessage = function (error) {
    if (error instanceof ApiError && error.xhrResponse.status === 404) {
      return "Your PDF is still being generated and will be available for download shortly. Please try again in a couple minutes.";
    }

    return NetworkErrorMessage.get(error, "download");
  };

  return { fromApi, fromBlob, listenToDropzone };
}

module.exports = Downloader();

const ApiError = require("../errors/apiError");
const NetworkErrorMessage = require("../general/networkErrorMessage");
const MessageModal = require("../modals/messageModal");
const Network = require("../network");
