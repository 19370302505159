"use strict";

const EnforcementActionModal = function () {
  var $modal;
  var inited = false;
  const enforcementDropzoneConfig = {
    name: "files",
    id: "upload-letter-file",
    jsonName: "",
    downloadName: "letters",
  };
  const formKey = "enforcement-action";
  var dropzoneObject = {};

  var getOnSaveAndCloseFn = function () {
    return onSaveAndClose;
  };
  var onSaveAndClose = function () {};

  var getOnCloseFn = function () {
    return onClose;
  };
  var onClose = function () {};

  var init = function () {
    $modal = $("#enforcement-action-modal");
    loadTreeListeners();
    loadDomListeners();
    inited = true;
  };

  var loadTreeListeners = function () {
    Tree.select(["enforcementActionUpdates", "issuedDate"]).on("update", (e) => {
      const issuedDate = e.data.currentData;
      if (issuedDate === undefined) return;
      const dateActionIssued = DateTime.parseDateToIso(issuedDate);
      const constructionProjectId = Tree.get("enforcementAction", "constructionProjectId");
      loadFollowupInspectionsSection(dateActionIssued, constructionProjectId);
    });

    Tree.select("enforcementActionUpdates", "followupInspectionId").on("update", (e) => {
      const followupInspectionId = e.data.currentData;
      if (followupInspectionId === undefined) return;
      selectFollowupInspection(followupInspectionId);
    });

    if (ToolSettings.getSetting("construction", "use-escalation-levels")) {
      Tree.select(["enforcementActionUpdates", "level"]).on("update", (e) => {
        const currentEscalation = Tree.get(["enforcementAction", "currentEscalation"]);
        if (currentEscalation.possibleLevels === undefined) return;
        const possibleLevel = currentEscalation.possibleLevels.find(
          (escalation) => escalation.key === e.data.currentData,
        );

        if (possibleLevel === undefined) return;
        $modal.find(".nav-tabs li:last-child a").text(possibleLevel.displayName);

        const $escalateOption = $modal.find("[name='response'] option[value='escalate']");
        $escalateOption.prop("disabled", e.data.currentData === "referral-to-water-board");
      });
    }
  };

  var loadDomListeners = function () {
    $modal.on("click", ".download-inspection-report", function () {
      const inspectionId = $(this).data("inspectionId");
      ApiCalls.downloadConstructionInspectionReport(inspectionId, null, "inspection");
    });

    $modal.on("click", ".download-zip-button", function () {
      EnforcementActionModalController.saveAndDownloadZip();
    });

    $modal.on("click", ".save-button", function () {
      EnforcementActionModalController.saveEnforcementActionAndClose(onSaveAndClose);
    });

    $modal.on("click", ".cancel-button", closeModalIfConfirmed);

    $modal.off("2N:FormInput", "select[name='contractor-response']", onContractorResponse);
    $modal.on("2N:FormInput", "select[name='contractor-response']", onContractorResponse);
  };

  var loadInputDomListeners = function () {
    EnforcementActionModalInputs.getInputs().forEach(loadListenerForInput);
  };

  var closeModalIfConfirmed = function () {
    if ($.isEmptyObject(Tree.get("enforcementActionUpdates"))) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
        close,
        "Go Back",
        "Close Without Saving",
      );
    }
  };

  var loadListenerForInput = function (input) {
    const inputSelector = `[name='${input.name}']`;
    var listenerLoadersByType = {
      date: () => loadDateListener($modal, inputSelector, addUpdateToTree),
      text: () => loadBasicListener($modal, inputSelector, addUpdateToTree),
      select: () => loadSelectListener($modal, inputSelector, addUpdateToTree),
    };
    var addUpdateToTree = (inputValue, unsetValue) => {
      const treePath = ["enforcementActionUpdates", input.propertyName];
      if (!unsetValue) {
        Tree.set(treePath, inputValue);
      } else {
        Tree.unset(treePath);
      }
    };
    listenerLoadersByType[input.type]();
  };

  var loadBasicListener = function ($parent, selector, callback) {
    $parent.off("input", selector);
    $parent.on("input", selector, function () {
      callback($(this).val());
    });
  };

  var loadSelectListener = function ($parent, selector, callback) {
    $parent.off("input", selector);
    $parent.on("input", selector, function () {
      const val = $(this).val();
      if (val === "no-selection") {
        callback(null, false);
      } else {
        callback(getAsNumberOrString(val), false);
      }
    });
  };

  var getAsNumberOrString = function (numberOrString) {
    const number = +numberOrString;

    if (Number.isNaN(number)) {
      return numberOrString;
    }

    return number;
  };

  var loadDateListener = function ($parent, selector, callback) {
    $parent.off("2N:FormInput", selector);
    $parent.on("2N:FormInput", selector, (e, date) => {
      callback(date, null);
    });
  };

  var renderAndShow = function (
    enforcementAction,
    onSaveAndCloseFn = () => {},
    onCloseFn = () => {},
  ) {
    if (!inited) init();

    onSaveAndClose = onSaveAndCloseFn;
    onClose = onCloseFn;

    const enforcementActionProps = getEnforcementActionProps(enforcementAction);
    render(enforcementActionProps);
    FormSettings.customizeForm($modal, formKey);
    Form.initializeAndLoadListeners($modal, formKey, { isMultiPart: true });
    initializeDates(enforcementActionProps);
    initializeDropzones(enforcementActionProps);
    loadInputDomListeners();
    initializeRespoderDropzone(enforcementActionProps);
    disableInactiveTabs();
    $modal.modal("show");
  };

  var render = function (enforcementActionProps) {
    $modal.html(
      nunjucks.render("modals/enforcementAction/enforcementActionModal.njk", {
        isCdot: Session.isCdot(),
        userRole: UserPermissions.getPermission("user_role"),
        featureFlag: FeatureFlag.enabled("construction-cdot-exemption"), // @FEATURE FLAG
        props: enforcementActionProps,
        useEscalationLevels: ToolSettings.getSetting("construction", "use-escalation-levels"),
        enforcementContractorMethod: ToolSettings.getSetting(
          "construction",
          "enforcement-contractor-method",
        ),
        useIssueEnforcementsModal: ToolSettings.getSetting(
          "construction",
          "use-issue-enforcements-modal",
        ),
      }),
    );
  };

  var getEnforcementActionProps = function (enforcementAction) {
    const props = {
      ...enforcementAction,
      defaultFollowupDueDate: enforcementAction.projectInspection.followupInspectionDueDate,
      escalations: getEscalationsProps(enforcementAction),
      isLocked: enforcementAction.isResolved,
      initialInspectionDisplayDate: enforcementAction.projectInspection.inspectionDisplayDate,
      initialInspectionId: enforcementAction.projectInspection.id,
      initialInspectionReportFilename: enforcementAction.projectInspection.reportFilename,

      resolvedDate: enforcementAction.dateResolvedIso,
      showPriorityTitle: enforcementAction.isProjectHighPriority,
      deferralDisabled: !enforcementAction.deferrable,
      exemptionReasonOptions: getExemptionReasonOptions(),
    };
    return props;
  };

  var getEscalationsProps = function (enforcementAction) {
    return enforcementAction.escalations.map((escalation) => {
      const levelObj = {
        key: escalation.levelKey,
        displayName: escalation.levelDisplayName,
      };
      const possibleFollowupInspections = escalation.possibleFollowupInspections;
      let noPossibleFollowupInspections = null;
      if (possibleFollowupInspections) {
        noPossibleFollowupInspections = possibleFollowupInspections.length === 0;
      }

      const escalationProps = {
        basicInfoEntryDisabled: !!(
          escalation.letterSignedReturnedDateIso || escalation.letterFilename !== null
        ),
        exemptionReason: escalation.exemptionReason ?? "upset-condition",
        disableDeferButton: !escalation.followupInspectionDueDateIso,
        followupInspectionDueDate: escalation.followupInspectionDueDateIso,
        isEscalateButtonDisabled: escalation.isHighestLevel,
        isFollowupInspectionSelected: !!escalation.followupInspection,
        isLocked: enforcementAction.isResolved || !escalation.active,
        issuedDate: escalation.issuedDateIso,
        letterSignedReturnedDate: escalation.letterSignedReturnedDateIso,
        levelKey: escalation.level,
        levelSelectorOptions: escalation.possibleLevels || [levelObj],
        noPossibleFollowupInspections: noPossibleFollowupInspections,
        possibleFollowupInspections: possibleFollowupInspections,
        showContractorResponseButtons:
          enforcementAction.canRespondToEnforcement && escalation.active,
        showFineCol: escalation.correctiveActions.some((action) => action.fine !== undefined),
        showMs4Notes:
          enforcementAction.canEditEnforcement ||
          (!enforcementAction.canEditEnforcement && escalation.ms4Notes),
        showDeferButton: UserRoles.getConfig("showDeferEnforcementButton"),
        showDeferralReviewButtons: UserRoles.getConfig("showDeferralReviewButtons"),
        showFollowupInspectionDueDateInput:
          ToolSettings.getSetting("construction", "enforcement-contractor-method") === "letter" ||
          escalation.contractorResponse === null ||
          escalation.contractorResponse === "deferred",
        showIssueTypeCol: escalation.correctiveActions.some(
          (action) => action.issueType !== undefined,
        ),
        showMs4ReviewButtons:
          escalation.active &&
          (escalation.contractorResponse ||
            ToolSettings.getSetting("construction", "enforcement-contractor-method") ===
              "letter") &&
          enforcementAction.canEditEnforcement &&
          (enforcementAction.canResolveEnforcement || escalation.reviewerResponse !== "approved"), // Reviewer already approved
        showStatusCol:
          !ToolSettings.getSetting("construction", "sync-enforcements-within-project") &&
          escalation.correctiveActions.some((action) => action.status !== undefined),
        tabActiveByDefault:
          escalation.active || (escalation.isFinalEscalation && enforcementAction.isResolved),
        displayExemptionReason: getDisplayExemptionReason(escalation.exemptionReason),
      };

      return {
        ...escalation,
        ...escalationProps,
        responseOptions: getResponseOptions(escalation, enforcementAction),
        contractorResponseOptions: getContractorResponseOptions(
          escalation,
          escalationProps,
          enforcementAction,
        ),
      };
    });
  };

  // The `response` fields isn't saved to the DB, but is used in `EnforcementActionModalController@updateResponse`
  var getResponseOptions = function (escalation, enforcementAction) {
    const options = [
      getResponseResolvedOption(escalation, enforcementAction),
      getResponseDeniedOption(escalation, enforcementAction),
    ];

    if (ToolSettings.getSetting("construction", "use-escalation-levels")) {
      options.push({
        name: "Escalate",
        value: "escalate",
        enabled: !escalation.isHighestLevel,
      });
    }

    return options;
  };

  var getResponseResolvedOption = function (escalation, enforcementAction) {
    const option = {};

    if (useDeferralResponse(enforcementAction)) {
      option.value = "deferral-approved";
    } else {
      option.value = "approved";
    }

    if (escalation.contractorResponse === "deferred") {
      option.name = "Approve Deferral";
    } else if (Session.isCdot() && UserPermissions.getPermission("user_role") !== "responder") {
      option.name = enforcementAction.isResolved ? "Approved" : "Approve";
    } else {
      option.name = enforcementAction.isResolved ? "Resolved" : "Resolve";
    }

    return option;
  };

  var getContractorResponseResolvedOption = function (
    escalation,
    escalationProps,
    enforcementAction,
  ) {
    const option = {
      value: "approved",
      enabled: escalation.contractorResponse !== "approved" && !escalationProps.isLocked,
    };

    if (Session.isCdot() && UserPermissions.getPermission("user_role") !== "responder") {
      option.name = "Approve";
    } else {
      option.name = escalation.contractorResponse === "approved" ? "Resolved" : "Resolve";
    }

    return option;
  };

  var getContractorDeferedOption = function (escalation, enforcementAction) {
    const option = {
      value: "deferred",
      name: escalation.contractorResponse === "deferred" ? "Deferred" : "Defer",
      enabled:
        enforcementAction.deferrable !== false && escalation.contractorResponse !== "deferred",
    };

    return option;
  };

  var getResponseDeniedOption = function (escalation, enforcementAction) {
    const option = {};

    if (useDeferralResponse(enforcementAction)) {
      option.value = "deferral-denied";
    } else {
      option.value = "denied";
    }

    if (escalation.contractorResponse === "deferred") {
      option.name = "Reject Deferral";
    } else if (Session.isCdot() && UserPermissions.getPermission("user_role") !== "responder") {
      option.name = "Reject";
    } else {
      option.name = "Keep Open";
    }

    return option;
  };

  var useDeferralResponse = function (enforcementAction) {
    return (
      UserRoles.getConfig("showDeferralReviewButtons") &&
      enforcementAction.currentEscalation.contractorResponse === "deferred"
    );
  };

  var getContractorResponseOptions = function (escalation, escalationProps, enforcementAction) {
    const options = [
      getContractorResponseResolvedOption(escalation, escalationProps, enforcementAction),
      getContractorDeferedOption(escalation, enforcementAction),
    ];

    if (Session.isCdot() && FeatureFlag.enabled("construction-cdot-exemption")) {
      options.push({
        name: "Exemption",
        value: "exemption",
      });
    }

    return options;
  };

  var getExemptionReasonOptions = function () {
    return [
      {
        name: "Upset Condition",
        value: "upset-condition",
      },
      {
        name: "Reportable Spills",
        value: "reportable-spills",
      },
      {
        name: "Winter Exemption",
        value: "winter-exemption",
      },
    ];
  };

  var getDisplayExemptionReason = function (exemptionReason) {
    const exemptionReasonOptions = getExemptionReasonOptions();
    return exemptionReasonOptions.find((item) => item.value === exemptionReason)?.name;
  };

  var onContractorResponse = function (e, contractorResponse) {
    toggleExemptionReason(contractorResponse);
    toggleFollowUpDueDate(contractorResponse);
  };

  var toggleExemptionReason = function (contractorResponse) {
    const $exemptionDropdown = $modal.find(".exemption-reason-dropdown");
    $exemptionDropdown.toggleClass("hidden", contractorResponse !== "exemption");
  };

  var toggleFollowUpDueDate = function (contractorResponse) {
    if (!Session.isCdot()) {
      return;
    }

    const $exemptionDropdown = $modal.find(".deffered-follow-up-input");
    $exemptionDropdown.toggleClass("hidden", contractorResponse !== "deferred");
  };

  var initializeRespoderDropzone = function (props) {
    const existingFiles = props.currentEscalation?.responderFile ?? [];

    const dropzone = Form.initializeDropzone("enforcement-action", $("#responder-files-section"), {
      newFiles: getNewFiles(),
      existingFiles: existingFiles,
      maxNumberFiles: 9,
      downloadCallback: ApiCalls.downloadResponderFile,
    });

    dropzone.setRemoveExistingCallback(removeExistingFile);
  };

  var removeExistingFile = function (filename) {
    Tree.set(["enforcementActionUpdates", "trashedFiles", "responderFiles"], filename);
  };

  var getNewFiles = function () {
    return Form.getFormDataAtPath(formKey, ["responderFiles"]);
  };

  var initializeDropzones = function (props) {
    props.escalations.forEach((escalation, index) => {
      const $dropzone = $(`#${enforcementDropzoneConfig.id}-${index}`);
      const maxNumFiles = 1;
      const thisDropzone = Dropzone($dropzone, false, maxNumFiles);
      if (escalation.letterFilename !== null) {
        thisDropzone.reset(null, [escalation.letterFilename]);
      }
      thisDropzone.setDownloadHandler(function (letterFilename) {
        ApiCalls.downloadTrashAttachment(
          escalation.id,
          letterFilename,
          "construction/escalations",
          enforcementDropzoneConfig.downloadName,
        );
      });
      thisDropzone.setFileSizeWarning(function (name) {
        MessageModal.showFileSizeWarning(name);
      });
      if (escalation.id) {
        if (!dropzoneObject[escalation.id]) {
          dropzoneObject[escalation.id] = {};
        }
        dropzoneObject[escalation.id][enforcementDropzoneConfig.name] = thisDropzone;
      } else {
        dropzoneObject[enforcementDropzoneConfig.name] = thisDropzone;
      }
    });
  };

  var getDropzoneObject = function (escalationId) {
    if (dropzoneObject[escalationId]) {
      return dropzoneObject[escalationId][enforcementDropzoneConfig.name];
    } else {
      return dropzoneObject[enforcementDropzoneConfig.name];
    }
  };

  var getDropzoneConfig = function () {
    return enforcementDropzoneConfig;
  };

  var selectFollowupInspection = function (followupInspectionId) {
    let followupInspection = null;
    if (followupInspectionId) {
      followupInspection =
        EnforcementActionModalController.getFollowupInspectionById(followupInspectionId);
    }
    renderFollowupInspectionsSection(
      Tree.get("enforcementAction", "currentEscalation", "possibleFollowupInspections"),
      followupInspection,
    );
  };

  var loadFollowupInspectionsSection = function (issuedDate, constructionProjectId) {
    ApiCalls.getPossibleFollowupInspectionsForEnforcementAction(
      constructionProjectId,
      issuedDate,
      Tree.get("enforcementAction", "projectInspection", "id"),
      (possibleFollowupInspections) => {
        Tree.set(
          ["enforcementAction", "currentEscalation", "possibleFollowupInspections"],
          possibleFollowupInspections,
        );
        renderFollowupInspectionsSection(
          possibleFollowupInspections,
          EnforcementActionModalController.getSelectedFollowupInspection(),
        );
      },
    );
  };

  var renderFollowupInspectionsSection = function (
    possibleFollowupInspections,
    selectedFollowupInspection,
  ) {
    var $followupInspectionSection = $modal.find(".followup-inspection-section").last();

    var noPossibleFollowupInspections = null;
    if (possibleFollowupInspections !== null) {
      noPossibleFollowupInspections = possibleFollowupInspections.length === 0;
    }

    var isFollowupInspectionSelected = selectedFollowupInspection !== null;

    nunjucks.render(
      "modals/enforcementAction/followupInspectionSection.njk",
      {
        possibleFollowupInspections,
        noPossibleFollowupInspections,
        selectedFollowupInspection,
        isFollowupInspectionSelected,
      },
      (error, html) => {
        if (error) console.error(error);
        $followupInspectionSection.html(html);
      },
    );
  };

  var initializeDates = function (props) {
    $modal.find(".datepicker").each((ind, element) => {
      DateTimePicker.initialize($(element));
    });

    props.escalations.forEach((escalation, index) => {
      const $tab = $modal.find(`#${index}-escalation-pane`);
      updateIssuedDateIfDefaultUsed(escalation);
      updateFollowupInspectionDateIfDefaultUsed(escalation);
      DateTimePicker.setDate($tab, "issued-date", escalation.issuedDate || new Date());
      DateTimePicker.setDatePickerRange($tab, "issued-date", false, escalation.minIssuedDate);
      DateTimePicker.setDate(
        $tab,
        "followup-inspection-due-date",
        escalation.followupInspectionDueDate,
      );
      DateTimePicker.setDate($tab, "signed-returned-date", escalation.letterSignedReturnedDate);
      DateTimePicker.setDate(
        $tab,
        "contractor-response-date",
        escalation.contractorResponseDateIso ?? new Date(),
      );
      DateTimePicker.setDate(
        $tab,
        "reviewer-response-date",
        escalation.reviewerResponseDateIso ?? new Date(),
      );

      DateTimePicker.setDate($tab, "resolved-date", props.dateResolvedIso ?? new Date());

      $tab.off("2N:FormInput", `[name="signed-returned-date"]`, updateContractorResponse);
      $tab.on("2N:FormInput", `[name="signed-returned-date"]`, updateContractorResponse);
    });
  };

  var updateContractorResponse = function (e, date) {
    if (date) {
      Tree.set(["enforcementActionUpdates", "contractorResponse"], "approved");
    } else {
      Tree.unset(["enforcementActionUpdates", "contractorResponse"]);
    }
  };

  var updateIssuedDateIfDefaultUsed = function (escalation) {
    if (escalation.active && !escalation.issuedDate) {
      Tree.set(["enforcementActionUpdates", "issuedDate"], DateTime.getToday());
    }
  };

  var updateFollowupInspectionDateIfDefaultUsed = function (escalation) {
    if (!escalation.id && escalation.followupInspectionDueDate) {
      Tree.set(
        ["enforcementActionUpdates", "followupInspectionDueDate"],
        new Date(escalation.followupInspectionDueDate),
      );
    }
  };

  var close = function () {
    DateTimePicker.deleteInstancesBySelector("input[data-subject='enforcement-modal']");
    Form.clearForm(formKey);
    $modal.modal("hide");
    onClose();
  };

  var disableInactiveTabs = function () {
    Misc.toggleDisabledInputs($modal.find(`[data-active="false"]`), true);
  };

  return {
    getDropzoneObject,
    getDropzoneConfig,
    renderAndShow,
    close,
    getOnSaveAndCloseFn,
    getOnCloseFn,
  };
};

module.exports = EnforcementActionModal();

const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const ToolSettings = require("../settings/toolSettings");
const DateTime = require("../dateTime");
const DateTimePicker = require("../general/dateTimePicker");
const Dropzone = require("../dropzone");
const EnforcementActionModalController = require("./enforcementActionModalController");
const EnforcementActionModalInputs = require("./enforcementActionModalInputs");
const Form = require("../general/form");
const FormSettings = require("../settings/formSettings");
const MessageModal = require("../modals/messageModal");
const Tree = require("../tree");
const UserRoles = require("../login/userRoles");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const FeatureFlag = require("../settings/featureFlag");
const Misc = require("../misc");
