"use strict";

const PermitSettings = function () {
  var permitSettings = {};

  var saveSettings = function (data) {
    permitSettings = sortByGroupIdAndDataProgram(data);
  };

  var sortByGroupIdAndDataProgram = function (arr) {
    return arr?.reduce((obj, item) => {
      const groupId = item["groupId"];
      const dataProgram = item["dataProgram"];

      if (!obj[groupId]) {
        obj[groupId] = {};
      }
      if (!obj[groupId][dataProgram]) {
        obj[groupId][dataProgram] = {};
      }
      obj[groupId][dataProgram] = item;

      return obj;
    }, {});
  };

  var getPermitSettingsByGroupId = function (groupId = Tree.get("activeGroup", "groupId")) {
    return permitSettings?.[groupId];
  };

  var getPermitSettingsByDataProgram = function (
    dataProgram,
    groupId = Tree.get("activeGroup", "groupId"),
  ) {
    const settingsByGroupId = getPermitSettingsByGroupId(groupId);
    if (settingsByGroupId) {
      return settingsByGroupId[dataProgram];
    }
  };

  var getPermitSettingsByDataView = function (
    dataView = Tree.get("dataView"),
    groupId = Tree.get("activeGroup", "groupId"),
  ) {
    const dataProgram = SubmitConfig.getAll().find(
      (config) => config.dataView === dataView,
    ).dataProgram;

    if (!dataProgram) {
      throw new Error(`No dataProgram not found for dataView: ${dataView}`);
    }

    return getPermitSettingsByDataProgram(dataProgram, groupId);
  };

  var getDisplayPermitSectionsByDataName = function (
    dataName,
    options = {},
    groupId = Tree.get("activeGroup", "groupId"),
  ) {
    const configs = SubmitConfig.getAll().filter((config) => config.dataName === dataName);
    const settings = getPermitSettingsByGroupId(groupId);

    if (configs.length === 0) {
      throw new Error(`No configuration found for dataName: ${dataName}`);
    }

    return configs
      .map((config) => combineConfigSettings(config, settings))
      .filter(shouldShowPermit)
      .map(function (config) {
        return getDisplayPermitSection(config, config, options);
      });
  };

  var combineConfigSettings = function (config, settings) {
    const dataViewSettings = settings?.[config.dataProgram];

    if (dataViewSettings) {
      $.extend(true, config, dataViewSettings);
    }

    return config;
  };

  var shouldShowPermit = function (configSetting) {
    if (configSetting.show) {
      return true;
    }

    if (configSetting.hide || configSetting.defaultHidden) {
      return false;
    }

    return true;
  };

  var getDisplayPermitSectionByDataView = function (dataView, options = {}) {
    const config = SubmitConfig.getAll().find((config) => config.dataView === dataView);

    if (!config || !dataView) {
      throw new Error(`No config found for ${dataView}`);
    }

    const settings = getPermitSettingsByDataProgram(config.dataProgram);
    return getDisplayPermitSection(config, settings, options);
  };

  var getDisplayPermitSectionByDataProgram = function (dataProgram, options = {}) {
    const config = SubmitConfig.getAll().find((config) => config.dataProgram === dataProgram);

    if (!config || !dataProgram) {
      throw new Error(`No config found for ${dataProgram}`);
    }

    const settings = getPermitSettingsByDataProgram(config.dataProgram);
    return getDisplayPermitSection(config, settings, options);
  };

  var getDisplayPermitSection = function (
    config,
    settings,
    { includePermit = false, includeProgramType = false } = {},
  ) {
    let name = settings?.permitSectionName ?? config.displayName;

    if (!name) {
      throw new Error(`No dataProgram found for config: ${JSON.stringify(config)}`);
    }

    if (includePermit && settings?.permitPrefix) {
      name = `${settings.permitPrefix} ${name}`;
    }

    if (includeProgramType) {
      const programType = config.programType === "impact" ? "Progress" : "Program";
      name = `${name} ${programType}`;
    }

    return name;
  };

  return {
    saveSettings,
    getPermitSettingsByGroupId,
    getPermitSettingsByDataProgram,
    getPermitSettingsByDataView,
    getDisplayPermitSectionByDataView,
    getDisplayPermitSectionByDataProgram,
    getDisplayPermitSectionsByDataName,
  };
};

module.exports = PermitSettings();

const Tree = require("../../tree");
const SubmitConfig = require("../config/submitConfig");
