"use strict";

const LidToDoList = function () {
  const lidMissingInformationToDoLabels = {
    street_address: "Street Address",
    associated_bmps: "sBMPs",
    contact: "O&M Contact or Property Owner/LRP",
  };

  const organizeFormalCommunications = function (data) {
    for (const datum of data) {
      organizeCommonData(datum);
      datum.showFormalCommunications = UserRoles.getConfig("showFormalCommunications");
    }

    return data;
  };

  const organizeResponseNeeded = function (data) {
    for (const datum of data) {
      organizeCommonData(datum);
      datum.showResponseNeeded = UserRoles.getConfig("showResponseNeeded");
    }

    return data;
  };

  const organizeMissingInformation = function (data) {
    for (const datum of data) {
      organizeCommonData(datum);
      if (datum.additionalInfo && typeof datum.additionalInfo === "string") {
        datum.additionalInfo = JSON.parse(datum.additionalInfo);
      }
      addDisplayRequiredFields(datum, datum.additionalInfo || []);
      datum.showMissingInformation = UserRoles.getConfig("showMissingInformation");
    }

    return data;
  };

  const organizeProjectEnforcements = function (data) {
    const result = [];
    for (const datum of data) {
      for (const enforcement of datum.additionalInfo) {
        TableDataFunctions.assignLidProjectIcon(datum, true);
        const issueDate = enforcement.issueDate
          ? DateTime.parseIsoDate(enforcement.issueDate)
          : null;

        result.push({
          id: datum.id,
          enforcementId: enforcement.id,
          tableIcon: datum.tableIcon,
          tierNumber: datum.tierNumber,
          projectIdentifier: datum.projectIdentifier,
          name: datum.projectName,
          streetAddress: datum.streetAddressShort,
          displayResponsibleParty: Misc.getOptionNameByValue(
            ProjectInventoryConstants.ownershipOptions,
            datum.maintainerType,
          ),
          displayEnforcementReason: _getDisplayEnforcementReason(enforcement.currentEscalation),
          displayLevel: Misc.getOptionNameByValue(
            FormSettings.getDropdownOptions("level", { tool: "indcom" }),
            enforcement.currentEscalation?.level,
          ),
          fine: enforcement.currentEscalation?.fine,
          issueDate: issueDate,
          displayIssueDate: issueDate ? DateTime.dateToGroupDisplayDate(issueDate) : issueDate,
          canIssueEnforcement: canEnforceProject(),
        });
      }
    }

    return result;
  };

  const _getDisplayEnforcementReason = function (escalation) {
    const displayEnforcementReason = Misc.getOptionNameByValue(
      FormSettings.getDropdownOptions("enforcement-reason", { tool: "lid" }),
      escalation.enforcementReason,
    );
    const count = escalation.reasonCount ? ` (${escalation.reasonCount})` : "";

    return displayEnforcementReason + count;
  };

  const addDisplayRequiredFields = function (toDo, requiredFields) {
    toDo.displayRequiredFields = getLidMissingInformationDisplayFields(requiredFields);
    toDo.numMissingRequiredFields = toDo.displayRequiredFields.length;
  };

  var getLidMissingInformationDisplayFields = function (fields) {
    return fields.map((field) => getLidMissingInformationFieldDisplayName(field));
  };

  var getLidMissingInformationFieldDisplayName = function (field) {
    const readableField = lidMissingInformationToDoLabels[field];
    if (readableField === undefined) {
      throw new Error(`Unknown required field: ${field}`);
    }
    return readableField;
  };

  const organizeCommonData = function (datum) {
    datum.name = datum.projectIdentifier;
    datum.tierNumber = LidIcon.getLidProjectTierNumber(datum);
    datum.iconClass = LidProjectStyles.getNewLidProjectIconClass(datum, true);
    ToDoFunctions.addCommonDueDateFields(datum, datum.dueDateIso);
  };

  const canManage = function () {
    return UserRoles.getConfig("managers");
  };

  const canInventory = function () {
    return UserRoles.getConfig("inventory");
  };

  function getSortersDict() {
    const dateSorter = DateTime.dateComparator;
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;

    return {
      projectIdentifier: alphaSorter,
      name: alphaSorter,
      streetAddress: alphaSorter,
      displayResponsibleParty: alphaSorter,
      displayEnforcementReason: alphaSorter,
      displayLevel: alphaSorter,
      fine: numberSorter,
      issueDate: dateSorter,
    };
  }

  function canEnforceProject() {
    return ToolSettings.getSetting("permissions", "can_issue_lid_enforcement");
  }

  const loadMissingInformationListeners = function ($container) {
    $container.off("click", ".project-inventory-button");
    $container.on("click", ".project-inventory-button", showProjectInventoryModal);
  };

  var showProjectInventoryModal = function () {
    const id = $(this).parents("tr").data("id");
    ProjectInventoryModalController.invokeProjectInventoryModal(id);
  };

  const loadFormalCommunicationsListeners = function ($container, { selectedTable }) {
    const onFormalCommunicationsClear = function (e) {
      // Stop the underlying header from collapsing/expanding
      e.stopPropagation();
      const checkedRowIds = selectedTable
        .getAllSelectedRows()
        .toArray()
        .map((elm) => $(elm).data("id"));
      _clearFormalCommunications(checkedRowIds);
    };
    $container.off(
      "click",
      ".js-lid-formal-communications-clear-selected",
      onFormalCommunicationsClear,
    );
    $container.on(
      "click",
      ".js-lid-formal-communications-clear-selected",
      onFormalCommunicationsClear,
    );

    const onGenerateLetter = function (e) {
      onLetterClick($(e.currentTarget), selectedTable, { action: "send" });
    };
    $container.off("click", ".js-lid-generate", onGenerateLetter);
    $container.on("click", ".js-lid-generate", onGenerateLetter);

    const toggleActionButtons = function () {
      _toggleHeaderActionButton($container, selectedTable.getAllSelectedRows().length > 0);
    };
    selectedTable.addListener(toggleActionButtons);
    toggleActionButtons();
  };

  const loadResponseNeededListeners = function ($container, { selectedTable }) {
    const onResponseNeededClear = function (e) {
      // Stop the underlying header from collapsing/expanding
      e.stopPropagation();
      const checkedRowIds = selectedTable
        .getAllSelectedRows()
        .toArray()
        .map((elm) => $(elm).data("id"));
      _clearResponseNeeded(checkedRowIds);
    };
    $container.off("click", ".js-lid-response-needed-clear-selected", onResponseNeededClear);
    $container.on("click", ".js-lid-response-needed-clear-selected", onResponseNeededClear);

    const onUpdateResponseClick = function (e) {
      onLetterClick($(e.currentTarget), selectedTable);
    };
    $container.off("click", ".js-lid-update-response", onUpdateResponseClick);
    $container.on("click", ".js-lid-update-response", onUpdateResponseClick);

    const toggleActionButtons = function () {
      _toggleHeaderUpdateButton($container, selectedTable.getAllSelectedRows());
    };
    selectedTable.addListener(toggleActionButtons);
    toggleActionButtons();
  };

  const _clearFormalCommunications = async function (rowIds) {
    try {
      await ToDoFunctions.showConfirmClearToDoListConfirm(rowIds.length, {
        selectedToDosName: FeatureFlag.enabled("pcr-letter")
          ? "self-verification notice"
          : "formal communication",
        confirmButton: "Clear Selected",
      });
    } catch (e) {
      // User canceled message
      return;
    }

    await ApiCalls.lidToDoClearFormalCommuncations(rowIds);
    ToDoListController.loadTodos();
  };

  const _clearResponseNeeded = async function (rowIds) {
    try {
      await ToDoFunctions.showConfirmClearToDoListConfirm(rowIds.length, {
        selectedToDosName: "response",
        confirmButton: "Clear Selected",
      });
    } catch (e) {
      // User canceled message
      return;
    }

    await ApiCalls.lidToDoClearResponseNeeded(rowIds);
    ToDoListController.loadTodos();
  };

  const _toggleHeaderActionButton = function ($table, showButton) {
    const buttonHtml = `<a class="js-lid-generate">Generate</a>`;
    _toggleHeaderButton($table, buttonHtml, showButton);
  };

  const _toggleHeaderUpdateButton = function ($table, $selectedRows) {
    const showButton = $selectedRows.length > 0;
    const actionsText = _getResponseActionText($selectedRows);
    const buttonHtml = `<a class="js-lid-update-response">${actionsText}</a>`;
    _toggleHeaderButton($table, buttonHtml, showButton);
  };

  const _getResponseActionText = function ($selectedRows) {
    const allNeedReview = $selectedRows
      .toArray()
      .every((element) => element.dataset.needsReview === "true");

    if (allNeedReview) {
      return "Review";
    }

    return "Update";
  };

  const _toggleHeaderButton = function ($table, buttonHtml, showButton) {
    const normalHtml = `Actions`;
    $table.find("th.actions-col").html(showButton ? buttonHtml : normalHtml);
  };

  const onLetterClick = function ($element, selectedTable, options = {}) {
    const projectId = $element.closest("tr").data("id");
    let projectIds = selectedTable
      .getAllSelectedRows()
      .toArray()
      .map((element) => $(element).data("id"));

    if (projectIds.length === 0) {
      projectIds = [projectId];
    }

    LetterCommunicationModal.renderAndShow(projectIds, options);
  };

  const handleConditionPhaseToggleDisplay = function (activeTab) {
    $(".condition-phase-toggle").toggle(activeTab !== "todo");
    $(".condition-label").toggle(activeTab === "todo");

    const toggle =
      activeTab === "todo" ? "condition" : Tree.get(["filters", "conditionPhaseToggle"]);
    PostConstructionProjectGeoServerLayer.handleLegendDisplayByConditionPhaseToggle(toggle);
  };

  return {
    organizeFormalCommunications,
    organizeResponseNeeded,
    organizeMissingInformation,
    organizeProjectEnforcements,
    canManage,
    canInventory,
    canEnforceProject,
    getSortersDict,
    loadFormalCommunicationsListeners,
    loadResponseNeededListeners,
    loadMissingInformationListeners,
    addDisplayRequiredFields,
    _toggleHeaderActionButton,
    _getResponseActionText,
    handleConditionPhaseToggleDisplay,
  };
};

module.exports = LidToDoList();

const UserRoles = require("../login/userRoles");
const LidIcon = require("./lidIcon");
const LidProjectStyles = require("./lidProjectStyles");
const ToDoFunctions = require("../mapFunctions/toDoFunctions");
const ApiCalls = require("../apiCalls");
const ToDoListController = require("../mapFunctions/toDoListController");
const PostConstructionProjectGeoServerLayer = require("../constructionG2/postConstructionProjectGeoServerLayer");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const LetterCommunicationModal = require("./letterCommunicationModal");
const Tree = require("../tree");
const FeatureFlag = require("../settings/featureFlag");
const DateTime = require("../dateTime");
const Misc = require("../misc");
const FormSettings = require("../settings/formSettings");
const SortUtilities = require("../general/sortUtilities");
const ToolSettings = require("../settings/toolSettings");
const ProjectInventoryConstants = require("../construction/projectInventoryConstants");
const TableDataFunctions = require("../general/tableDataFunctions");
