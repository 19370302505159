"use strict";

const FactSheetPhotos = function () {
  let config;
  let data;
  let $container;

  const init = function (_config, _data, _$container) {
    config = _config;
    data = _data;
    data.photos ||= [];
    data.photos.forEach((photo) => (photo.full = photo.url));
    $container = _$container;
    insertPhotos();
    loadPhotoCarousel();
    setProfilePicture(data.profileId || data.photos[0]?.id);
    loadListeners();
  };

  const insertPhotos = function () {
    const $placeholder = $container.find("#fact-sheet-photos-placeholder");
    if (!$placeholder.length) return;
    const photos = nunjucks.render("factSheet/sections/factSheetPhotosNew.njk", data);
    $placeholder.replaceWith(photos);
  };

  const loadPhotoCarousel = function () {
    PhotoCarousel.load(data.photos, {
      caption: updatePhotoCaption,
      rotate: updatePhotoRotate,
      delete: updatePhotoDelete,
      setProfile: setSelectedProfilePicture,
      showProfile: checkSelectedProfilePicture,
    });
  };

  const loadListeners = function () {
    $container
      .off("click", ".fact-sheet-photo", displayPhotoCarousel)
      .on("click", ".fact-sheet-photo", displayPhotoCarousel)
      .off("click", "#add-photo", clickAddPhoto)
      .on("click", "#add-photo", clickAddPhoto)
      .off("change", ".file-uploader")
      .on("change", ".file-uploader", function () {
        uploadFactSheetPhotos(this.files);
      });
  };

  var displayPhotoCarousel = function (e) {
    PhotoCarousel.showPhotoCarouselModalFromPhotoId($(e.currentTarget).data("idPhoto"));
  };

  const clickAddPhoto = function () {
    $(this).closest("section").find(".file-uploader").trigger("click");
  };

  const uploadFactSheetPhotos = function (fileList) {
    if (fileList !== undefined) {
      for (var i = 0; i < fileList.length; i++) {
        if (!FileFunctions.isFileImage(fileList[i])) {
          MessageModal.showSimpleWarningModal("Unsupported File Type");
          return;
        } else if (fileList[i].size > 8 * 1024 * 1024) {
          MessageModal.showFileSizeWarning(fileList[i].name);
          return;
        } else {
          const timestamp = new Date().valueOf();
          const $thumb = createTempProgressThumbnail(timestamp, fileList[i]);
          loadThumbnailImage($thumb, fileList[i]);
          ApiCalls.uploadFactSheetPhoto(
            config.apiAssetName,
            data.id,
            fileList[i],
            updatePhotoUploadProgress($thumb),
            (newFile) => {
              uploadCompleteCallback($thumb, newFile);
            },
          );
        }
      }
    }
  };

  const createTempProgressThumbnail = function (timestamp) {
    const thumb = nunjucks.render("factSheet/photoThumb.njk", {
      isUploading: true,
      progress: 0,
      photo: { id: timestamp },
    });
    $(thumb).insertBefore(".photo-list-grid .upload-box");
    return $container.find(`[data-id-photo=${timestamp}]`);
  };

  const loadThumbnailImage = function ($target, file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener(
      "load",
      function () {
        $target.find("img").attr("src", reader.result);
      },
      false,
    );
  };

  const updatePhotoUploadProgress = function ($thumb) {
    return function handleUploadProgress(e) {
      if (e.lengthComputable) {
        $thumb.find("progress").val(e.loaded / e.total);
      }
    };
  };

  const uploadCompleteCallback = function ($thumb, newFile) {
    data.photos.push(newFile);
    if (data.photos.length === 1) {
      setSelectedProfilePicture(newFile.id);
    }

    $thumb.data("idPhoto", newFile.id).attr("data-id-photo", newFile.id);
    $thumb.find(".upload-progress").remove();
    const $img = $thumb.find("img");
    newFile.full = newFile.url;
    $img.attr("src", newFile.full);
    $img.attr("alt", newFile.caption);
    $thumb.removeClass("uploading");

    loadPhotoCarousel();
  };

  const updatePhotoCaption = function ($activeSlide, newCaption) {
    const photoId = $activeSlide.data("id");
    const photoName = $activeSlide.data("photo");
    const captionCallback = function () {
      var itemIndex = locateById(data.photos, photoId);
      data.photos[itemIndex].caption = newCaption;
    };
    ApiCalls.saveFactSheetPhotoCaption(
      config.apiAssetName,
      data.id,
      photoId,
      newCaption,
      photoName,
      captionCallback,
    );
  };

  const locateById = function (array, id) {
    const index = array.findIndex((x) => {
      return x.id === id;
    });
    return index;
  };

  const updatePhotoRotate = function ($activeSlide, rotation) {
    const photoId = $activeSlide.data("id");
    const photoName = $activeSlide.data("photo");
    const itemIndex = locateById(data.photos, photoId);
    const currentPhoto = data.photos[itemIndex];
    ApiCalls.rotateFactSheetPhoto(
      config.apiAssetName,
      data.id,
      photoId,
      photoName,
      rotation,
      function (response) {
        currentPhoto.url = response;
        $(`[data-id-photo=${currentPhoto.id}] img`).attr("src", response);
        PhotoCarousel.restoreRotation();
        if (data.profileId === photoId) {
          setProfilePicture(photoId);
        }
      },
    );
  };

  const updatePhotoDelete = function ($activeSlide) {
    const photoId = $activeSlide.data("id");
    const photoName = $activeSlide.data("photo");
    ApiCalls.deleteFactSheetPhoto(config.apiAssetName, data.id, photoId, photoName, function () {
      var removeIndex = locateById(data.photos, photoId);
      data.photos.splice(removeIndex, 1);
      $container.find(`a[data-id-photo=${photoId}]`).remove();
      if (data.profileId === photoId) {
        setProfilePicture(null);
        checkSelectedProfilePicture(photoId);
      }
    });
  };

  const setSelectedProfilePicture = async function (photoId) {
    const currentProfilePhoto = photoId === data.profileId;
    const idToSet = currentProfilePhoto ? null : photoId;
    await ApiCalls.setFactSheetProfilePhoto(config.apiAssetName, data.id, idToSet);
    setProfilePicture(idToSet);
    checkSelectedProfilePicture(photoId);
  };

  const checkSelectedProfilePicture = function (itemId) {
    const currentProfile = itemId === data.profileId;
    PhotoCarousel.setCurrentProfileItem(currentProfile);
  };

  const setProfilePicture = function (pictureId) {
    const photoToSet = data.photos?.find((photo) => photo.id === pictureId);
    $container.find(".profile-photo").toggleClass("default-profile-photo", !photoToSet);
    if (photoToSet) {
      data.profileId = pictureId;
      $container
        .find(".profile-photo")
        .attr("src", photoToSet.thumbUrl)
        .attr("onerror", `this.onerror=null; this.src='${photoToSet.url}'`);
    } else {
      data.profileId = null;
      $container.find(".profile-photo").attr("src", `/ram/images/icons/2n-greyscale.png`);
    }
  };

  return {
    init,
  };
};

module.exports = FactSheetPhotos();

const ApiCalls = require("../apiCalls");
const FileFunctions = require("../files/fileFunctions");
const MessageModal = require("../modals/messageModal");
const PhotoCarousel = require("./photoCarousel");
