"use strict";

const ProjectInventoryPermitInfo = function () {
  const stringKey = "construction-permit-info";
  const headerInformation = "Permit Info";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    const project = InventoryModal.getAllData("project-inventory");
    return renderPermitInfoHtml(project).then((props) => {
      Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
      loadListeners();
      return;
    });
  };

  var renderPermitInfoHtml = async function (project) {
    const props = await getProps(project);
    const html = await nunjucks.asyncRender(
      "modals/projectInventory/projectInventoryPermitInfo.njk",
      props,
    );
    ProjectInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    return props;
  };

  var getProps = async function (project) {
    return {
      project: project,
      possiblePerformanceTiers: ToolSettings.getDomainOptions("project_performance_tier"),
      possibleSpecialCircumstances: ToolSettings.getDomainOptions("project_special_circumstance"),
      possibleRunoffOffsetProjects: mapRelatedProjectsToSelectOptions(
        project.possibleRelatedProjects,
      ),
      noRelatedProjectsMessage: `No other projects in ${project.locationInfo.receivingWaterName}`,
      cgpRiskLevelOptions: ProjectInventoryConstants.cgpRiskLevelOptions,
      alternativeComplianceCircumstancesOptions:
        ProjectInventoryConstants.alternativeComplianceCircumstancesOptions,
      showInLieuFeeAmount: !!project.lidInfo.inLieuFees,
      showAlternativeComplianceCircumstances: !!project.lidInfo.alternativeCompliance,
      showOffSiteMitigationDetails: !!project.lidInfo.offsiteMitigation,
    };
  };

  var mapRelatedProjectsToSelectOptions = function (possibleRelatedProjects) {
    if (!possibleRelatedProjects) return [];
    return possibleRelatedProjects.map((project) => {
      return {
        value: project.id,
        name: project.projectName,
      };
    });
  };

  var loadListeners = function () {
    $page.on(
      "2N:FormInput",
      "input[name='lid-info[in-lieu-fees]']",
      toggleInLieuFeeAmountNumberInputForEvent,
    );
    $page.on(
      "2N:FormInput",
      "input[name='lid-info[alternative-compliance]']",
      toggleAlternativeComplianceCircumstances,
    );
    $page.on(
      "2N:FormInput",
      "input[name='lid-info[offsite-mitigation]']",
      toggleOffsiteMitigationDetails,
    );
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", "input[name='permit-info[general-permit-coverage]']");
    $page.off("2N:FormInput", "input[name='lid-info[in-lieu-fees]']");
    $page.off("2N:FormInput", "input[name='lid-info[alternative-compliance]']");
    $page.off("2N:FormInput", "input[name='lid-info[offsite-mitigation]']");
  };

  var toggleInLieuFeeAmountNumberInputForEvent = function (e, value) {
    toggleInLieuFeeAmountNumberInput(value);
  };

  var toggleInLieuFeeAmountNumberInput = function (bool) {
    $page.find(".in-lieu-fee-amount").toggleClass("invisible", !bool);
  };

  var toggleAlternativeComplianceCircumstances = function (e, value) {
    $page
      .find("input[name='lid-info[alternative-compliance-circumstance-values]']")
      .closest("fieldset")
      .toggle(value);
  };

  var toggleOffsiteMitigationDetails = function (e, value) {
    $page
      .find("[name='lid-info[offsite-mitigation]']")
      .closest("fieldset")
      .siblings()
      .toggle(value);
  };

  var cleanUp = function () {
    unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
  };
};

module.exports = ProjectInventoryPermitInfo();

const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const Form = require("../general/form");
const ToolSettings = require("../settings/toolSettings");
