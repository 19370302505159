"use strict";

const InsightWidgetFunctions = function () {
  var convertTimeUnit = function (hours) {
    if (!isNaN(hours)) {
      if (hours > 24 * 365) {
        return "Years";
      } else if (hours > 96) {
        return "Days";
      } else {
        return "Hours";
      }
    }
  };

  var convertTime = function (hours) {
    if (!isNaN(hours)) {
      if (hours > 24 * 365) {
        return hours / 24 / 365;
      } else if (hours > 96) {
        return hours / 24;
      } else {
        return hours;
      }
    }
  };

  var formatPercentage = function (value, total, sigFig = 1) {
    if (total === 0) {
      return "—";
    }

    return ((value / total) * 100).toFixed(sigFig) + "%";
  };

  var formatNumber = function (value) {
    if (value === undefined || value === null) return "";
    return NunjucksFilters.formatTelrNumber(value);
  };

  var sortDataArrayByKey = function (dataArray, orderArray, keyPropName = "key") {
    dataArray.sort((a, b) => {
      return orderArray.indexOf(a[keyPropName]) - orderArray.indexOf(b[keyPropName]);
    });
  };

  var getSpatialDataOptions = function () {
    const topLevelDataSorts = DataViewFunctions.getAvailableDataSorts();

    let spatialDataOptions = [];

    if ("maintenanceZones" in topLevelDataSorts) {
      const zones = Tree.get("maintenanceZones", "items");
      spatialDataOptions = zones.map((zone) => {
        return {
          name: zone.maintenanceZoneName,
          value: zone.maintenanceZoneName,
        };
      });
    } else {
      const catchments = Tree.get("catchments", "items");
      const receivingWaters = Array.from(
        new Set(catchments.map((catchment) => catchment.drains_to_rw)),
      );
      spatialDataOptions = receivingWaters.map((rw) => {
        return {
          name: rw,
          value: rw,
        };
      });
    }

    spatialDataOptions.unshift({ name: "All", value: "total" });

    return spatialDataOptions;
  };

  var getSpatialDataTitle = function () {
    const topLevelDataSorts = DataViewFunctions.getAvailableDataSorts();
    if ("maintenanceZones" in topLevelDataSorts) {
      return "Maintenance Zones";
    } else {
      return "Receiving Waters";
    }
  };

  var generateTicks = function (min, max, numOfTicks) {
    var ticks = [];

    for (var i = 0; i < numOfTicks; i++) {
      ticks.push(min + (max - min) * (i / (numOfTicks - 1)));
    }

    return ticks;
  };

  var handleMonthlyDataDisplay = function (monthlyData) {
    if (monthlyData.length === 13) {
      const month13Value = monthlyData[12].value;
      const month12Value = monthlyData[11].value;
      if (month13Value === 0 && month13Value < month12Value) {
        monthlyData[12].value = null;
      }
    }

    monthlyData.forEach((item) => {
      if (item.isFutureMonth) {
        item.value = null;
      }
    });
  };

  return {
    convertTime,
    convertTimeUnit,
    formatPercentage,
    formatNumber,
    sortDataArrayByKey,
    getSpatialDataOptions,
    getSpatialDataTitle,
    generateTicks,
    handleMonthlyDataDisplay,
  };
};

module.exports = InsightWidgetFunctions();

const NunjucksFilters = require("../nunjucksFilters");
const DataViewFunctions = require("../../dataViewFunctions");
const Tree = require("../../tree");
