"use strict";
const OutfallInventoryConstants = function () {
  var getResponsiblePartyMaintenanceOptions = function () {
    return FormSettings.getDropdownOptions("maintainer-type", { tool: "muni" });
  };

  var getConsequenceOfFailureOptions = function () {
    return FormSettings.getDropdownOptions("consequence-of-failure", { tool: "muni" });
  };

  const eswiftEntityTypeOptions = [
    {
      name: "Partner",
      value: "partner",
    },
    {
      name: "Private",
      value: "private",
    },
    {
      name: "Our Agency",
      value: "our-agency",
    },
    {
      name: "DOT",
      value: "dot",
    },
    {
      name: "MS4",
      value: "ms4",
    },
    {
      name: "Local Agency",
      value: "local-agency",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const datumOptions = [
    {
      name: "EGM 96",
      value: "egm96",
    },
    {
      name: "EGM 2008",
      value: "egm2008",
    },
    {
      name: "NAVD 1929",
      value: "navd1929",
    },
    {
      name: "NGVD 1988",
      value: "ngvd1988",
    },
    {
      name: "Other",
      value: "other",
    },
    {
      name: "WGS 84",
      value: "wgs84",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  const phaseOptions = [
    {
      name: "Planning",
      value: "planning",
    },
    {
      name: "Design",
      value: "design",
    },
    {
      name: "Abandoned",
      value: "abandoned",
    },
    {
      name: "Removed",
      value: "removed",
    },
    {
      name: "Construction",
      value: "construction",
    },
    {
      name: "Post Active",
      value: "post-active",
    },
    {
      name: "Completed",
      value: "completed",
    },
    {
      name: "Certified",
      value: "certified",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
    {
      name: "Shelved",
      value: "shelved",
    },
  ];

  const priorityOptions = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
  ];

  const consequenceOfFailureOptions = [
    {
      name: "Very High",
      value: "very-high",
    },
    {
      name: "High",
      value: "high",
    },
    {
      name: "Moderate",
      value: "moderate",
    },
    {
      name: "Low",
      value: "low",
    },
    {
      name: "Very Low",
      value: "very-low",
    },
    {
      name: "Unknown",
      value: "unknown",
    },
  ];

  var getOutfallInventoryDefaults = function () {
    const defaults = {
      rotationAzimuth: 0,
      priority: false,
      routineSiteVisitFrequencyMonths: 12,
      existingOutfalls: [],
    };

    return defaults;
  };
  return {
    consequenceOfFailureOptions,
    priorityOptions,
    phaseOptions,
    datumOptions,
    getResponsiblePartyMaintenanceOptions,
    getOutfallInventoryDefaults,
    getConsequenceOfFailureOptions,
    eswiftEntityTypeOptions,
  };
};
module.exports = OutfallInventoryConstants();

const FormSettings = require("../settings/formSettings");
