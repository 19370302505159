"use strict";

const AssetsBmpGeoServerDynamicMapLayer = function () {
  const layerName = "assetsBmpEsriDynamicMap";
  const layerKey = "assetsBmpEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsBmpEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsBmpEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsBmpEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };
  var loadLayer = function () {};

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = AssetsBmpGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
