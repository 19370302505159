"use strict";

const CommonMapFunctions = function () {
  const getMapOptions = function () {
    return {
      popupOptions: {
        className: "twon-popup",
        minWidth: 200,
        maxWidth: 500,
        autoPan: false,
      },
    };
  };

  const setMapHeight = function () {
    const $page = PageFunctions.getCurrentPage();
    const $map = $page.find(".map");

    if (!$map.length) {
      return;
    }

    const $table = $page.find(".floating-inputs-table");
    const $container = $("#map-container");

    $map.css("height", window.innerHeight - $map.offset().top);
    $table.css("height", window.innerHeight - $container.offset().top);
  };

  var invalidateSize = function () {
    if (Tabs.activeTabHasMap()) {
      setMapHeight();
      TreeUpdates.getCurrentMap().invalidateSize();

      if (Tree.get("reZoomCatchmentLayer")) {
        MapFunctions.zoomToCatchmentLayer();
        Tree.set("reZoomCatchmentLayer", false);
      }
    }
  };

  var afterMapLoad = function () {
    const mapId = Tree.get("mapId");
    const activeGroupId = Tree.get("activeGroup", "groupId");

    invalidateSize();

    Tree.set(["lastGroupId", mapId], activeGroupId);
    Tree.set(["firstLoad", mapId], false);

    MapFunctions.setCurrentMapToLastMapExtent();
  };

  var afterMS4MapLoad = function (map) {
    const mapId = Tree.get("mapId");

    Tree.set("bbox", map.getBounds().toBBoxString());
    Table.loadTable();
    Table.loadListeners(mapId);
    CatchmentView.startListeners();
  };

  return {
    setMapHeight,
    getMapOptions,
    afterMapLoad,
    invalidateSize,
    afterMS4MapLoad,
  };
};

module.exports = CommonMapFunctions();

const Tree = require("../../tree");
const PageFunctions = require("../pageFunctions");
const TreeUpdates = require("./treeUpdates");
const MapFunctions = require("./mapFunctions");
const Table = require("./table");
const CatchmentView = require("./catchmentView");
const Tabs = require("../tabs");
