"use strict";

var EditableItems = function () {
  var loadListeners = function ($parent) {
    $parent.on("click", ".toggle-edit-items", onToggleEditItems);
    $parent.on("click", ".clear-items-search", onClickClearItemsSearch);
    $parent.on("input", ".item-search", onInputItemSearch);
    $parent.on("2N:FormInput", ".editable-items input", onItemsChange);
    $parent.find(".editable-items input").trigger("2N:FormInput");
  };

  var unloadListeners = function ($parent) {
    $parent.off("click", ".toggle-edit-items", onToggleEditItems);
    $parent.off("click", ".clear-items-search", onClickClearItemsSearch);
    $parent.off("input", ".item-search", onInputItemSearch);
    $parent.off("2N:FormInput", ".editable-items input", onItemsChange);
  };

  var onToggleEditItems = function () {
    $(this).parents(".editable-items-container").toggleClass("editing");
  };

  var onClickClearItemsSearch = function () {
    $(this).parents(".editable-items-container").find(".item-search").val("").trigger("input");
  };

  var onInputItemSearch = function () {
    const searchString = $(this).val();
    const $selector = $(this)
      .parents(".editable-items-container")
      .find(".edit-items .checkbox-container");
    const options = {
      parentSelector: ".item-category",
      parentTextSelector: "h5",
    };
    FilterDom.filterByText(searchString, $selector, options);
  };

  var onItemsChange = function () {
    const $container = $(this).parents(".editable-items-container");
    $container.find(".display-items .editable-item").removeClass("selected");
    $container.find("input:checked").each(function () {
      $container.find(`.editable-item[data-id="${$(this).val()}"]`).addClass("selected");
    });
    $container.find(".no-items-selected").show();
    $container
      .find(".display-items .item-category")
      .hide()
      .each(function () {
        if ($(this).find(".editable-item.selected").length > 0) {
          $(this).show();
          $container.find(".no-items-selected").hide();
        }
      });
  };

  return {
    loadListeners,
    unloadListeners,
  };
};

module.exports = EditableItems();

const FilterDom = require("./filterDom");
