"use strict";

const LidBmpGeoServerLayer = function () {
  const layerName = "lidBmp";
  const layerKey = "lidBmpLayer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: createIconFunction(feature),
    });
  };

  var createIconFunction = function (feature) {
    return MuniBmpIcon.getIconMarker(
      feature.properties.bmpTypeObj.isCentralized,
      feature.properties.condition,
      feature.properties.isDue,
      feature.properties.bmpTypeObj.abbreviation,
      Tree.get("zoomLevel"),
    );
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const lidBmpGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
      createIconFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, lidBmpGeoServerLayer);

    lidBmpGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    lidBmpGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return _organizeLayerData(data);
  };

  var _organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    const toDos = GeoServerLayerFunctions.prepareToDoData(data, {
      routine_repair: "routineRepairBmp",
      routine_site_visit: "routineSiteVisitBmp",
    });
    RoutineMaintenanceFormTable.processRepairAdditionalInfo(toDos.routineRepairBmp.additionalInfo);

    const ownership = getOwnership(data.bmp_ptype);

    return {
      id: data.idbmp,
      groupId: data.group_id,
      type: data.bmp_type,
      isDue: toDos.routineSiteVisitBmp.isDue,
      idbmp: data.idbmp,
      geometry: geometry,
      implementerType: data.implementer_type,
      responsiblePartyMaintenance: data.responsible_party_maintenance,
      bmpName: data.bmp_id,
      treatmentCapacity: data.bmp_wqcap,
      address: data.address,
      fullAddress: Misc.getFullMailingAddress(data.address, data.city, data.state, data.zip),
      imperviousAreaAcres: data.bmp_imparea,
      cof: data.cof_final_text,
      bmpDisplayType: BmpFcsFunctions.getBmpTypeNameByNumber(data.bmp_type),
      bmpTypeObj: {
        isCentralized: data.cbmp,
        abbreviation: data.bmp_abbrev,
      },
      isFcs: data.fcs,
      fcs: data.fcs,
      installationDate: data.bmp_creation,
      inProximityTo303dWaterbody: data.in_proximity_to_303d_waterbody,
      phase: data.phase,
      facility: data.facility,
      displayOwnership: ownership?.name,
      permissions: {
        canBeEdited: BmpFcsFunctions.canEditBmpFcs(data.idbmp, data.phase),
        canCreateRoutineInspection: ToolSettings.getSetting("permissions", "can_inspect_bmps"),
      },
      condition_status: data.condition_status,
      condition: RoutineMaintenanceIcon.getLayerCondition(
        data.last_maintenance_inspection_id,
        data.todo_is_routine_repair,
      ),
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      zone_names: data.zone_names,
      routine: data.routine_inspection_dates ? JSON.parse(data.routine_inspection_dates) : null,
      repair: data.repair_dates ? JSON.parse(data.repair_dates) : null,
      enforcementLevel: GeoServerLayerFunctions.getAdditionalInfo(
        JSON.parse(data.additional_info),
        "level",
      ),
      correctiveAction: GeoServerLayerFunctions.getAdditionalInfo(
        JSON.parse(data.additional_info),
        "key",
      ),
      // G2 auto-converts the not nullable ID to a number
      projectId: data.project_id === 0 ? null : data.project_id,
      ...toDos,
    };
  };

  var getAdditionalInfo = function (parsedData, key) {
    if (!parsedData || !Array.isArray(parsedData)) {
      return null;
    }
    return parsedData.map((datum) => {
      return datum[key];
    });
  };

  var getOwnership = function (ptype) {
    return BmpFcsInventoryConstants.propertyTypeOptions.find((option) => option.value === ptype);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
    _organizeLayerData,
    getAdditionalInfo,
  };
};

module.exports = LidBmpGeoServerLayer();

const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const MuniBmpIcon = require("../muni/bmp/muniBmpIcon");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
const Tree = require("../tree");
const ToolSettings = require("../settings/toolSettings");
const Misc = require("../misc");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
