"use strict";

var FcsProgressDrainageLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "fcsProgressDrainage",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.fcsProgressDrainageLayer && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers.fcsProgressDrainageLayer);
          } else {
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.fcsProgressDrainageLayer)) {
            map.removeLayer(mapLayers.fcsProgressDrainageLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "fcsProgressDrainage", "isFetching"], true);
    ReportApiCalls.getFcsProgressDrainage(filters, function (data) {
      Tree.set(["layers", mapId, "fcsProgressDrainage", "isFetching"], false);
      Tree.set(["layers", mapId, "fcsProgressDrainage", "data"], data);
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "fcsProgressDrainage", function (data) {
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.fcsProgressDrainageLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.fcsProgressDrainageLayer) {
      map.removeLayer(mapLayers.fcsProgressDrainageLayer);
    }
    mapLayers.fcsProgressDrainageLayer = createLayer(data);
    mapLayers.fcsProgressDrainageLayer.addTo(map);
    return mapLayers.fcsProgressDrainageLayer;
  };

  var createLayer = function (data) {
    return new (Layers.getLayers().FcsProgressDrainageLayer)(data);
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      status: ["filters", "status"],
      ownership: ["filters", "ownership"],
      structureType: ["filters", "structureType"],
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.fcsProgressDrainageLayer) &&
        Tree.get("layers", mapId, "fcsProgressDrainage", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = FcsProgressDrainageLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Layers = require("../mapFunctions/layers");
