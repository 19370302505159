"use strict";

function UserResource() {
  const keyPath = "userId";
  const objectStoreName = "main";
  var cachedMainObjectStore;
  var loggedInUserData;

  async function init() {
    assertNotInited();

    const db = new Database("user", [
      {
        key: "user",
        migration: function (migrationDb) {
          migrationDb.createObjectStore(objectStoreName, { keyPath });
        },
      },
    ]);

    cachedMainObjectStore = new CachedObjectStore(db, objectStoreName, keyPath);

    // Hydrates the cache now, so logging in later is fast
    await cachedMainObjectStore.getAll();
  }

  async function login(userId) {
    loggedInUserData = await cachedMainObjectStore.get(userId);

    return setLastLoggedInUser(userId);
  }

  function assertNotInited() {
    if (cachedMainObjectStore) {
      throw new Error(`UserResource is already initialized.`);
    }
  }

  function assertInited() {
    if (!cachedMainObjectStore) {
      throw new Error(`UserResource is not initialized.`);
    }
  }

  function setLastLoggedInUser(userId) {
    if (getUserData("userId") === userId) {
      setUserData("lastLogin", new Date());
      return false;
    } else {
      cachedMainObjectStore.clear();
      loggedInUserData = { userId, lastLogin: new Date() };
      cachedMainObjectStore.put(structuredClone(loggedInUserData));
      return true;
    }
  }

  function setUserData(key, value) {
    assertInited();
    loggedInUserData = { ...loggedInUserData, [key]: structuredClone(value) };
    return cachedMainObjectStore.put(loggedInUserData);
  }

  function getUserData(key) {
    assertInited();
    return structuredClone(loggedInUserData?.[key]);
  }

  async function _reset() {
    await cachedMainObjectStore._getDbUpdateJobQueue().getDonePromise();
    cachedMainObjectStore = undefined;
    loggedInUserData = undefined;
  }

  function _getCachedObjectStore() {
    return cachedMainObjectStore;
  }

  return {
    init,
    login,
    setUserData,
    getUserData,
    _reset,
    _getCachedObjectStore,
  };
}

module.exports = UserResource();

const CachedObjectStore = require("./cachedObjectStore");
const Database = require("./database");
