"use strict";

// We should pull substantial display code from MainMap.js into here
const MapPanel = function () {
  const thisMapPanel = $("#map-panel");

  var init = function () {
    Tree.watch({
      activeTab: ["activeTab"],
      dataView: ["dataView"],
      navToggleSelection: ["navToggleSelection"],
    }).on("update", (e) => {
      const data = e.target.get();

      if (PageFunctions.getPageConfig(data.activeTab).noPanels) {
        return;
      }

      if (data.navToggleSelection === "map") {
        show();
      } else {
        hide();
      }
    });
  };

  var show = function () {
    $("#mapContent").removeClass("default-hidden");
    thisMapPanel.addClass("active");
    MapFunctions.setMapHeight();
    MainMap.invalidateMapSize();
    Mobile.toggleMobileButtons();
    MainMap.initFitBounds();
  };

  var hide = function () {
    thisMapPanel.removeClass("active");
    Mobile.toggleMobileButtons();
  };

  return {
    init,
    hide,
    show,
  };
};

module.exports = MapPanel();

const MainMap = require("./mainMap");
const MapFunctions = require("./mapFunctions");
const Mobile = require("../mobile");
const PageFunctions = require("../pageFunctions");
const Tree = require("../tree");
