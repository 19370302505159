"use strict";

const BoundaryPage = function () {
  const stringKey = "property-boundary";
  const headerInformation = "Boundary";
  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = function () {
    $page = $(".modal-dynamic-content");
    const renderData = InventoryModal.getAllData("property-inventory");
    const template = "esg/modals/propertyInventory/boundaryPage.njk";
    const html = nunjucks.render(template, renderData);
    PropertyInventoryModalController.renderPageContent(html);
    _addAndRemoveMarginClass();
    loadListeners();
    showAreaSelectionMap();
    DrawController.zoomToDrainage();
  };

  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, PropertyInventoryModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("change", "[name=useManualImperviousAcres]", _changeUseManualImperviousAcres);
    $page.on("change", "[name=useManualBuildingFootprint]", _changeUseManualBuildingFootprint);
    $page.on("input", "[name=buildingFootprintManual]", _inputBuildingFootprintManual);
    $page.on("input", "[name=imperviousAcresManual]", _inputImperviousAcresManual);
  };

  var _unloadListeners = function () {
    $page.off("change", "[name=useManualImperviousAcres]", _changeUseManualImperviousAcres);
    $page.off("change", "[name=useManualBuildingFootprint]", _changeUseManualBuildingFootprint);
    $page.off("input", "[name=buildingFootprintManual]", _inputBuildingFootprintManual);
    $page.off("input", "[name=imperviousAcresManual]", _inputImperviousAcresManual);
  };

  var _changeUseManualImperviousAcres = function () {
    _toggleManualImperviousAcres($(this));
  };

  var _changeUseManualBuildingFootprint = function () {
    _toggleManualBuildingFootprint($(this));
    calculateConsumptiveUse();
  };

  var _inputBuildingFootprintManual = function () {
    PropertyInventoryModalController.setData("useManualBuildingFootprint", true);
    calculateConsumptiveUse();
  };

  var _inputImperviousAcresManual = function () {
    PropertyInventoryModalController.setData("useManualImperviousAcres", true);
  };

  var _toggleManualImperviousAcres = function ($this) {
    const $imperviousAcres = $this.closest(".impervious-acres");
    const imperviousAcres = Number(
      PropertyInventoryModalController.getLatestData("imperviousAcres") || 0,
    );
    const useManual = PropertyInventoryModalController.getLatestData("useManualImperviousAcres");
    const imperviousAcresManual =
      PropertyInventoryModalController.getLatestData("imperviousAcresManual");

    const $input = $imperviousAcres.find("[name=imperviousAcresManual]");
    $imperviousAcres.toggleClass("manual", $this.is(":checked"));
    NumberInput.manuallySetInput($input[0], useManual ? imperviousAcresManual : imperviousAcres);
    $input.select();
  };

  var _toggleManualBuildingFootprint = function ($this) {
    const $buildingFootprint = $this.closest(".building-footprint");
    const buildingFootprint = Number(
      PropertyInventoryModalController.getLatestData("buildingFootprint") || 0,
    );
    const $input = $buildingFootprint.find("[name=buildingFootprintManual]");
    $buildingFootprint.toggleClass("manual", $this.is(":checked"));
    NumberInput.manuallySetInput($input[0], buildingFootprint);
    $input.select();
  };

  var showAreaSelectionMap = function () {
    const propertyAreaSelectionMapHandlers = {
      newExistingMarkerHandler: PropertyInventoryModalController.loadExistingPropertyMarker,
    };
    const activeMethod = "drawing-tools";
    ModalMap.removeMarkerLayer();
    DrawController.setDrawnPolygonStyle(MapStyles.esgPropertyDrawnPolygonStyle);
    AreaSelectionMap.resetAreaSelectionMapHandlers();
    AreaSelectionMap.configureAreaSelectionMapHandlers(propertyAreaSelectionMapHandlers);
    AreaSelectionMap.showAreaSelectionMap(true);
    AreaSelectionMap.setActiveMethodLayer(activeMethod);
    AreaSelectionTable.setAreaSelectionTablePercentageText("in Project");
  };

  var calculateConsumptiveUse = function () {
    const data = PropertyInventoryModalController.getAllData();
    const multiplier = PageController.getConstants("propertyTypeUnitConsumptiveUse")[
      data.propertyType
    ];
    const buildingFootprint = data.useManualBuildingFootprint
      ? data.buildingFootprintManual
      : data.buildingFootprint;
    const consumptiveUse = buildingFootprint * multiplier;
    PropertyInventoryModalController.setData("consumptiveUse", consumptiveUse);
  };

  var _addAndRemoveMarginClass = function () {
    $(".modal-dynamic-content").addClass("add-bmp-no-margin");
  };

  var cleanUp = function () {
    _unloadListeners();
    AreaSelectionMap.hideAreaSelectionMap();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var resetState = function () {
    DrawController.resetDrawnItemsLayer();
    AreaSelectionMap.resetDrawController();
  };

  var getLatestBoundary = function () {
    return DrawController.getDrawnItems()
      ? DrawController.getDrawnDrainageGeom()
      : PropertyInventoryModalController.getLatestData("geom");
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    calculateConsumptiveUse,
    cleanUp,
    validate,
    resetState,
    showAreaSelectionMap,
    getLatestBoundary,
  };
};

module.exports = BoundaryPage();

const InventoryModal = require("../../../general/inventoryModal");
const AreaSelectionMap = require("../../../mapFunctions/areaSelectionMap");
const AreaSelectionTable = require("../../../mapFunctions/areaSelectionTable");
const Form = require("../../../general/form");
const DrawController = require("../../../mapFunctions/drawController");
const ModalMap = require("../../../mapFunctions/modalMap");
const PropertyInventoryModalController = require("../propertyInventoryModalController");
const NumberInput = require("../../../general/numberInput");
const PageController = require("../../pageController");
const MapStyles = require("../../../mapFunctions/mapStyles");
