"use strict";

const MuniCleanoutFilters = function () {
  const searchDbFields = ["name", "address"];
  const searchFields = ["name", "address"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          MuniCleanoutGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return (
        ResourceFilters.spatialFiltersAreSet(filters) ||
        isOnlineDateFilterSet(filters) ||
        ResourceFilters.isDateFilterSet(filters.routineDate) ||
        ResourceFilters.isDateFilterSet(filters.repairDate)
      );
    }
  };

  var addGeoServerViewParams = function (params, filters) {
    if (!FilterSummary.areAllCheckboxesChecked("correctiveAction")) {
      params["correctiveAction"] = filters.correctiveAction.map((item) => `'${item}'`).join("\\,");
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("correctiveAction") &&
      $('input[name="correctiveAction"][value="no-action"]').is(":checked")
    ) {
      params[`includeNoAction`] = true;
    }
    if (!FilterSummary.areAllCheckboxesChecked("enforcementLevel")) {
      params["enforcementLevel"] = filters.enforcementLevel.map((item) => `'${item}'`).join("\\,");
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("enforcementLevel") &&
      $('input[name="enforcementLevel"][value="null"]').is(":checked")
    ) {
      params[`includeNoActionLevel`] = true;
    }
    ResourceFilters.addDateParam(
      params,
      "routine",
      filters.routineDate?.from,
      filters.routineDate?.to,
    );
    ResourceFilters.addDateParam(
      params,
      "repair",
      filters.repairDate?.from,
      filters.repairDate?.to,
    );
  };

  var isOnlineDateFilterSet = function (filters) {
    return isOnlineDateType(filters.dateType) && (filters.dateFrom || filters.dateTo);
  };

  var isOnlineDateType = function (dateType) {
    return ["routine", "repair", "clean"].includes(dateType);
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }

    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings);

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementerType",
      "implementer_type",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "maintainedBy",
      "responsible_party_maintenance",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "phase", "phase");
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "landOwnership",
      "property",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "consequenceOfFailure",
      "cof_final_text",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "shape",
      "cover_shape",
    );

    const lengthWidthQueries = [];
    const diameterQuery = [];
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "diameter",
      filters.diameterRange,
      "diameter_units",
      diameterQuery,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "length",
      filters.lengthRange,
      "length_units",
      lengthWidthQueries,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "width",
      filters.widthRange,
      "width_units",
      lengthWidthQueries,
    );

    const shapeDbColumnName = "cover_shape";
    const indexOfShapeQuery = MeasurementMinMax.findIndexOfShapeQuery(
      queryStrings,
      shapeDbColumnName,
    );
    GeoServerFilterFunctions.createMinMaxMeasurmentCombinedQuery(
      queryStrings,
      lengthWidthQueries,
      diameterQuery,
      indexOfShapeQuery,
      shapeDbColumnName,
    );

    GeoServerFilterFunctions.addConditionFilterToGeoServerQuery(queryStrings, filters);
    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "type", "type");
    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "install_date",
        filters.installDate?.from,
        filters.installDate?.to,
      );
    }
  };

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, zoneOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      zoneOptions,
      receivingWaterOptions,
      urbanDrainageOptions,
      catchmentsOptions,
      landOwnershipOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      phaseOptions: ToolSettings.getSetting("filterConstants", "phases"),
      cofOptions: FilterConstants.cofOptionsWithNull,
      yesNoOptions: FilterConstants.yesNoOptions,
      shapeOptions: FilterConstants.shapeOptionsCleanoutManholes,
      conditionOptions: FilterConstants.conditionOptions,
      typeOptions: FilterConstants.cleanoutType,
      correctiveOptions: FilterConstants.getAllCorrectiveOptions(),
      enforcementLevel: FilterConstants.getEnforcementLevel(),
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      implementerType: "implementerType",
      maintainedBy: "maintainedBy",
      landOwnership: "landOwnership",
      type: "type",
      phase: "phase",
      condition: "condition",
      consequenceOfFailure: "cof",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      zones: "zone_names",
      correctiveAction: ["correctiveAction", "array"],
      enforcementLevel: ["enforcementLevel", "array"],
      shape: "coverShape",
      subGroups: "groupId",
      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          MeasurementMinMax.passDiameterFilter(
            datum.coverShape,
            filters.shape,
            datum.cleanoutDiameter,
            datum.cleanoutDiameterUnits,
            { max: filters.diameterRange?.max, min: filters.diameterRange?.min },
          ) &&
          MeasurementMinMax.passLengthWidthFilter(
            datum.coverShape,
            filters.shape,
            datum.cleanoutLength,
            datum.cleanoutLengthUnits,
            datum.cleanoutWidth,
            datum.cleanoutWidthUnits,
            { max: filters.lengthRange?.max, min: filters.lengthRange?.min },
            { max: filters.widthRange?.max, min: filters.widthRange?.min },
          ) &&
          passInstalledDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    isOnlineDateFilterSet,
    addGeoServerFilterQuery,
    getProps,
    passInstalledDateFilter,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = MuniCleanoutFilters();

const ApiError = require("../errors/apiError");
const ResourceFilters = require("../filters/resourceFilters");
const MuniCleanoutGeoServerLayer = require("./muniCleanoutGeoServerLayer");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const FilterConstants = require("../filterConstants");
const FilterSummary = require("../filters/filterSummary");
const MeasurementMinMax = require("../general/measurementMinMax");
const Filters = require("../mapFunctions/filters");
const ToolSettings = require("../settings/toolSettings");
const Progeny = require("../login/progeny");
