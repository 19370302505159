"use strict";

const MuniBmpPopup = function () {
  var getPopupHtml = function (properties) {
    properties = addInspectionPermissions(properties);
    return PopupContent.getLayerPopup(properties, "muniBmp");
  };

  var addInspectionPermissions = function (properties) {
    if (properties.permissions) {
      const canAddPhotosViewInventoryAndDelete =
        properties.phase === "planning" || properties.phase === "planned"
          ? ["planner", "data-manager", "admin"]
          : ["data-manager", "admin"];
      const cannotDelete =
        properties.phase !== "planning" || properties.phase !== "planned"
          ? ["viewer", "field-personnel", "responder"]
          : ["planner", "viewer", "field-personnel", "responder"];
      properties.permissions.user_role = UserPermissions.getAllPermissions().user_role;
      if (canAddPhotosViewInventoryAndDelete.includes(properties.permissions.user_role)) {
        properties.readOnly = false;
        properties.canBeDeleted = true;
      } else {
        properties.readOnly = !properties?.permissions?.canEditBmps;
        if (cannotDelete.includes(properties.permissions.user_role)) {
          properties.canBeDeleted = false;
        }
      }
    }
    properties.displayAddInspectionButton =
      SharedDataFunctions.isPhaseOrLater(properties.phase, "completed") &&
      properties?.permissions?.canCreateRoutineInspection &&
      properties.bmpTypeName !== "Unknown" &&
      !(Tree.get("dataView") === "bmp");

    return properties;
  };

  var onPopupOpen = async function (properties, layerName) {
    const id = properties.id ?? properties.idbmp;
    var data = await _getPopupData(id, properties);
    const html = getPopupHtml(data);
    $(".leaflet-popup-content").html(html);
    const $popup = $(".leaflet-popup-content");
    const layerModule = LayerFunctions.getLayerModule(layerName);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
    PopupPhotos.load("bmp", id);
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteBmp(data.id, data.bmpName, invalidateLayerDataFunction);
    };
    loadConditionBars(data);

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);

    $element.off("click", ".download-report", onDownloadReport);
    $element.on("click", ".download-report", onDownloadReport);
  };

  var deleteBmp = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Muni BMP ${name}? Click "Delete" to permanently delete the BMP. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteBmp(id);
        invalidateLayerDataFunction();
        _reloadAndClosePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "muni-bmp");
  };

  var _reloadAndClosePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var _getPopupData = async function (id) {
    const popupData = await ApiCalls.getMuniBmpPopup(id);
    setLegacyAssessmentHistory(popupData.history);
    Tree.set("currentBmpFcs", popupData);

    const condition = RoutineMaintenanceIcon.getLayerCondition(
      popupData.lastMaintenanceInspection,
      popupData.lastMaintenanceInspection?.needsRepair,
    );

    popupData.iconInfo = MuniBmpIcon.getIconInfo(
      popupData.bmpTypeObj.isCentralized,
      condition,
      popupData.isDue,
      popupData.bmpTypeObj.abbreviation,
    );
    return popupData;
  };

  var setLegacyAssessmentHistory = function (history) {
    const legacyHistory = {
      history: history,
      bmp: [],
      fcs: [],
      benchmarks: [],
    };
    Tree.set("bmpFcsAssessmentHistory", legacyHistory);
    return legacyHistory;
  };

  var loadConditionBars = function (data) {
    PopupContent.setPopupConditionBar(
      $(".tr-muni-bmp-score"),
      data?.history,
      (datum) => datum.score,
    );
  };

  var onDownloadReport = function () {
    var data = $(this).closest("tr").data();

    if (["routineMaintenance", "routineRepair"].includes(data.assetType)) {
      Analytics.sendPopupEvent("download_report", "muni-bmp");
      ApiCalls.downloadMuniBmpPdf(data.id);
    } else {
      throw new Error(`No download defined for ${data.assetType}`);
    }
  };

  return {
    getPopupHtml,
    onPopupOpen,
    _getPopupData,
    addInspectionPermissions,
    loadConditionBars,
    onDownloadReport,
    setLegacyAssessmentHistory,
  };
};

module.exports = MuniBmpPopup();

const ApiCalls = require("../../apiCalls");
const MuniBmpIcon = require("./muniBmpIcon");
const PopupContent = require("../../mapFunctions/popupContent");
const LayerFunctions = require("../../layerFunctions");
const MessageModal = require("../../modals/messageModal");
const UserPermissions = require("../../login/userPermissions");
const Analytics = require("../../general/analytics");
const RoutineMaintenanceIcon = require("../../general/routineMaintenanceIcon");
const Tree = require("../../tree");
const PopupPhotos = require("../../mapFunctions/popupPhotos");
const SharedDataFunctions = require("../../general/sharedDataFunctions");
