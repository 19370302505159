"use strict";

const LayerDataFunctions = function () {
  var getDataCursor = function (layerName, mapId) {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    return Tree.select(["layers", layerName, dataPath]);
  };

  var getCurrentLayerData = function (layerName, mapId = "main") {
    if (layerName in LayerConfig.get()) {
      var dataCursor = getDataCursor(layerName, mapId);
      return dataCursor.get() || [];
    } else {
      throw `Layer not defined in LayerConfig: ${layerName} for getCurrentLayerData`;
    }
  };

  var getLayerDataAndIndexById = function (layerName, id, mapId = "main") {
    // @TODO:  Handle speical lid-runoff logic with config
    layerName =
      layerName !== "catchments" && Tree.get("dataView") === "lid-runoff"
        ? "postConstructionProjectG2"
        : layerName;
    if (layerName in LayerConfig.get()) {
      var data = getCurrentLayerData(layerName, mapId);
      var index = data.findIndex((item) => {
        return item.id === id;
      });
      return {
        data: data[index],
        index: index,
      };
    } else {
      throw `Layer not defined in LayerConfig: ${layerName} for getLayerDataAndIndexById`;
    }
  };

  var updateLayerDataById = function (layerName, id, newData, mapId = "main") {
    if (layerName in LayerConfig.get()) {
      var dataCursor = getDataCursor(layerName, mapId);
      var layerDataAtIndex = getLayerDataAndIndexById(layerName, id, mapId);
      var layerData = layerDataAtIndex.data;
      var index = layerDataAtIndex.index;
      var updatedData = Object.assign({}, layerData, newData);
      dataCursor.splice([index, 1, updatedData]);
    } else {
      throw `Layer not defined in LayerConfig: ${layerName} for updateLayerDataById`;
    }
  };

  var updateData = function (layerName, newDataCallback, mapId = "main") {
    if (layerName in LayerConfig.get()) {
      const dataCursor = getDataCursor(layerName, mapId);
      const allData = getCurrentLayerData(layerName, mapId).map(function (datum) {
        return { ...datum, ...newDataCallback(datum) };
      });

      dataCursor.set([]);
      dataCursor.set(allData);
    } else {
      throw `Layer not defined in LayerConfig: ${layerName}`;
    }
  };

  var updateAllData = function (layerName, newData, mapId = "main") {
    return updateData(layerName, () => newData, mapId);
  };

  var multiUpdateData = function (layerName, selectedIds, newData, mapId = "main") {
    return updateData(
      layerName,
      function (datum) {
        if (selectedIds.includes(datum.id)) {
          return newData;
        }

        return {};
      },
      mapId,
    );
  };

  var refreshLayer = function (layerName, mapId = "modal") {
    var dataCursor = getDataCursor(layerName, mapId);
    var data = dataCursor.get() || [];
    dataCursor.set([]);
    dataCursor.set(data);
  };

  return {
    getCurrentLayerData,
    getLayerDataAndIndexById,
    updateLayerDataById,
    updateAllData,
    multiUpdateData,
    refreshLayer,
  };
};

module.exports = LayerDataFunctions();

const Actions = require("../actions");
const Tree = require("../tree");
const LayerConfig = require("../config/layerConfig");
