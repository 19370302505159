"use strict";

const TokenManager = function () {
  window.addEventListener("storage", (e) => {
    if (e.key === "token" && e.storageArea == localStorage) {
      afterTokenSave(e.newValue, e.oldValue);
    }
  });

  const saveToken = function (newToken) {
    localStorage.setItem("token", newToken);
  };

  const afterTokenSave = function (newToken, existingToken) {
    const noToken = newToken === null;
    const updatingToken = newToken !== null && existingToken !== null;
    if (noToken) {
      SingleSignOn.warnAndRefresh(
        "You have been signed out. You will be redirected to the sign in screen.",
      );
    } else if (updatingToken) {
      window.location.reload();
    } else {
      SingleSignOn.warnAndRefresh("You have been signed in. The page will now refresh.");
    }
  };

  const loadToken = function () {
    return localStorage.getItem("token");
  };

  const removeToken = function () {
    localStorage.removeItem("token");
  };

  const getTokenData = function (token) {
    var decoded = jwt_decode(token);
    return {
      token: token,
      decoded: decoded,
      context: decoded.context,
    };
  };

  return {
    saveToken,
    loadToken,
    removeToken,
    getTokenData,
  };
};

module.exports = TokenManager();

const jwt_decode = require("jwt-decode");
const SingleSignOn = require("./sso");
