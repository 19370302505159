"use strict";

const ProjectInspectionValidator = function () {
  var getMissingRequiredFields = function (allData) {
    var fields = [];

    if (allData.inspectionType !== "close-out") {
      fields = fields.concat(
        getMissingRequiredTemporaryBmpFields(
          allData.requiredObservations,
          getRequiredObservationField,
          ["condition"],
        ),
      );
      fields = fields.concat(
        getMissingRequiredTemporaryBmpFields(
          allData.additionalObservations,
          getAdditionalObservationField,
          ["condition"],
        ),
      );
      fields = fields.concat(
        getMissingRequiredTemporaryBmpFields(
          allData.administrativeFindings,
          getAdministrativeFindingsField,
          ["issueType", "severity"],
        ),
      );
      fields = fields.concat(
        getMissingRequiredTemporaryBmpFields(
          allData.observationFindings,
          getObservationFindingsField,
          getRequiredObservationFindingFields,
        ),
      );
    }

    fields = fields.concat(
      FormValidator.getEmptyRequiredFieldsInData(allData, getRequiredSubmissionFields(allData)),
    );
    fields = fields.concat(
      FormValidator.getEmptyRequiredFieldsInData(allData, getRequiredGeneralInfoFields(allData)),
    );

    return fields;
  };

  var getMissingRequiredTemporaryBmpFields = function (
    inspections,
    getFieldFunction,
    requiredTbmpFields,
  ) {
    var fields = [];

    for (const key in inspections) {
      for (const inspection of inspections[key]) {
        fields = fields.concat(
          getMissingTemporaryBmpFields(inspection, key, getFieldFunction, requiredTbmpFields),
        );
      }
    }

    return fields;
  };

  var getMissingTemporaryBmpFields = function (
    inspection,
    key,
    getFieldFunction,
    requiredTbmpFields,
  ) {
    const missingFields = [];

    if (inspection.deleted) {
      return missingFields;
    }

    if (typeof requiredTbmpFields === "function") {
      requiredTbmpFields = requiredTbmpFields(inspection, key);
    }

    for (const field of requiredTbmpFields) {
      if (
        inspection[field] === null ||
        inspection[field] === undefined ||
        inspection[field] === ""
      ) {
        missingFields.push(getFieldFunction(inspection, key, field));
      }
    }

    return missingFields;
  };

  var getRequiredObservationField = function (observation, key, fieldName) {
    const observationDisplayName =
      ProjectInspectionConstants.getRequiredObservationOptions().getObjectWith("value", key).name;

    return {
      fieldName: `requiredObservations[${key}][${observation.index}][${fieldName}]`,
      message: `${observationDisplayName} ${fieldName}`,
    };
  };

  var getAdditionalObservationField = function (observation, key, fieldName) {
    const observationDisplayName =
      ProjectInspectionConstants.getAdditionalObservationPracticeOptionsObject()[key].name;

    return {
      fieldName: `additionalObservations[${key}][${observation.index}][${fieldName}]`,
      message: `${observationDisplayName} ${fieldName}`,
    };
  };

  var getAdministrativeFindingsField = function (finding, key, fieldName) {
    const findingOptions = ProjectInspectionConstants.getAdministrativeFindingByPractice(key);
    const displayName = findingOptions.displayName;

    const fieldNameToDisplayName = {
      severity: "finding type",
      issueType: "select finding",
    };

    if (!fieldNameToDisplayName[fieldName]) {
      throw new Error(`No display name defined for field ${fieldName}.`);
    }

    return {
      fieldName: `administrativeFindings[${key}][${finding.index}][${fieldName}]`,
      message: `${displayName} ${fieldNameToDisplayName[fieldName]}`,
    };
  };

  var getObservationFindingsField = function (finding, key, fieldName) {
    const findingOptions = ProjectInspectionConstants.getObservationFindings()[key];
    const displayName = findingOptions.displayName;

    const fieldNameToDisplayName = {
      severity: "finding type",
      issueType: "select finding",
      customDisplayIssue: "select finding",
      customDisplayCitation: "citation",
      inadequateControlMeasure: "inadequate control measure",
      locationDescription: "location description",
    };

    if (!fieldNameToDisplayName[fieldName]) {
      throw new Error(`No display name defined for field ${fieldName}.`);
    }

    return {
      fieldName: `observationFindings[${key}][${finding.index}][${fieldName}]`,
      message: `${displayName} ${fieldNameToDisplayName[fieldName]}`,
    };
  };

  var getRequiredSubmissionFields = function (data) {
    const fields = [];

    if (!ToolSettings.getSetting("construction", "is-findings-workflow")) {
      fields.push({
        fieldName: "followupInspection",
        message: "Schedule additional follow up inspection",
      });

      fields.push({
        fieldName: "flagEnforcement",
        message: "Flag for enforcement",
      });
    }

    fields.push({
      fieldName: "isSiteRepPresent",
      message: "Is the site representative on site",
    });

    if (data.isSiteRepPresent === "yes") {
      fields.push({
        fieldName: "signatures[siteRepDate]",
        message: "Site representative signature date",
      });
      fields.push({
        fieldName: "signatures[siteRepName]",
        message: "Site representative name",
      });
      fields.push({
        fieldName: "signatures[siteRepEsignature]",
        message: "Site representative signature",
      });
    }

    fields.push({
      fieldName: "signatures[inspectorDate]",
      message: "Inspector signature date",
    });
    fields.push({
      fieldName: "signatures[inspectorName]",
      message: "Inspector name",
    });
    fields.push({
      fieldName: "signatures[inspectorEsignature]",
      message: "Inspector signature",
    });

    return fields;
  };

  var getRequiredGeneralInfoFields = function (data) {
    return [
      {
        fieldName: "inspector-name",
        message: "Inspector name",
      },
      {
        fieldName: "inspection-date",
        message: "Inspection date",
      },
      {
        fieldName: "inspection-type",
        message: "Inspection type",
      },
    ];
  };

  var getRequiredObservationFindingFields = function (finding, key) {
    const findingOptions = ProjectInspectionConstants.getObservationFindings()[key];
    const required = ["severity", "inadequateControlMeasure", "locationDescription"];

    if (findingOptions?.purpose === "other") {
      required.push("customDisplayIssue");
      required.push("customDisplayCitation");
    } else {
      required.push("issueType");
    }

    return required;
  };

  return {
    getMissingRequiredFields,
  };
};

module.exports = ProjectInspectionValidator();

const ToolSettings = require("../../settings/toolSettings");
const ProjectInspectionConstants = require("./projectInspectionConstants");
const FormValidator = require("../../general/formValidator");
