"use strict";

const InspectionPreview = function () {
  var preview = async function (dataFn, endpointFn) {
    const preview = new PreviewDownloader();
    const data = await dataFn();
    data.previewImages = true;

    convertImagesToPreviews(data);

    const pdfBinary = await endpointFn(Form.toFormData(data));

    preview.openBlob(new Blob([pdfBinary], { type: "application/pdf" }));

    return data;
  };

  var convertImagesToPreviews = function (data) {
    eachPhoto(data, function (photo) {
      try {
        delete photo.file;
        photo.preview = true;
      } catch (e) {
        Analytics.reportException(e);
      }

      return photo;
    });

    return data;
  };

  var eachPhoto = function (data, eachFunction) {
    Misc.eachNestedPropertyWithKey(data, "photos", function (photos) {
      if (!photos) {
        return;
      }

      for (const photo of photos) {
        eachFunction(photo);
      }
    });
  };

  return {
    preview,
    convertImagesToPreviews,
  };
};

module.exports = InspectionPreview();

const Analytics = require("../general/analytics");
const Form = require("../general/form");
const Misc = require("../misc");
const PreviewDownloader = require("../files/previewDownloader");
