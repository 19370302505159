"use strict";

const MuniCatchBasinReportGeoServerLayer = function () {
  const layerName = "muniCatchBasinReport";
  const layerKey = "muniCatchBasinReportLayer";
  var muniCatchBasinGeoServerLayer;

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: MuniCatchBasinIcon.getIconMarker(
        feature.properties.score,
        feature.properties.routineMaintenanceDue,
        feature.properties.isCatchBasinHighPriority,
      ),
    });
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    muniCatchBasinGeoServerLayer = new ReportGeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
    muniCatchBasinGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniCatchBasinGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function () {
    muniCatchBasinGeoServerLayer.loadGeoServerLayer(
      TreeUpdates.getMap("report"),
      TreeUpdates.getMapLayers("report"),
      "report",
      { refreshData: true },
    );
  };

  var invalidateLayerData = function (loadingScreen = true) {
    muniCatchBasinGeoServerLayer.invalidateLayerData(
      TreeUpdates.getMap("report"),
      TreeUpdates.getMapLayers("report"),
      "report",
      { loadingScreen },
    );
  };

  var organizeMuniLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    const toDos = GeoServerLayerFunctions.prepareToDoData(data, {
      catch_basin_inventory_incomplete: "catchBasinInventoryIncomplete",
      routine_site_visit: "routineSiteVisit",
      routine_repair: "routineRepair",
    });
    RoutineMaintenanceFormTable.processRepairAdditionalInfo(toDos.routineRepair.additionalInfo);

    return {
      id: data.id,
      idbmp: data.id,
      idBmp: data.id,
      address: data.address,
      bmpName: data.bmp_id,
      geometry: geometry,
      bmpTypeObj: {
        id: data.bmp_type,
        sortOrder: data.bmp_type,
        name: BmpFcsFunctions.getBmpTypeNameByNumber(data.bmp_type),
      },
      bmpDisplayType: BmpFcsFunctions.getBmpTypeNameByNumber(data.bmp_type),
      bmpType: data.bmp_type,
      catchBasinPriority: data.cof_final_text,
      cleanout: data.cleanout,
      drainageAreaAcres: data.bmp_drainarea,
      facility: data.facility,
      fullAddress: Misc.getFullMailingAddress(data.address, data.city, data.state, data.zip),
      imperviousAreaAcres: data.bmp_imparea,
      inletType: data.inlet_type,
      installationDate: data.bmp_creation,
      inProximityTo303dWaterbody: data.in_proximity_to_303d_waterbody,
      isCatchBasinHighPriority: ["high", "very-high"].includes(data.cof_final_text),
      displayCatchBasinPriority: getDisplayCatchBasinPriority(data.cof_final_text),
      isFcs: data.fcs,
      fcs: data.fcs,
      permissions: {
        canBeEdited: BmpFcsFunctions.canEditBmpFcs(data.id, data.phase),
        canCreateRoutineInspection: ToolSettings.getSetting("permissions", "can_inspect_bmps"),
      },
      phase: data.phase,
      responsiblePartyMaintenance: data.responsible_party_maintenance,
      score: data.catch_basin_score,
      structureId: data.structure_identifier,
      totalDepth: data.total_depth,
      sump: data.sump,
      siphon: data.siphon,
      designDepth: data.bmp_designdepth,
      footprintMaterial: data.footprint_material,
      wallMaterial: data.wall_material,
      shape: data.shape,
      length: data.length,
      width: data.width,
      diameter: data.bmp_diam,
      maintenanceObservationStructuralCondition: data.maintenance_observation_structural_condition,
      routineMaintenanceDue: data.todo_is_routine_site_visit,
      routineRepairDue: data.todo_is_routine_repair,
      ...toDos,
      activeMaintenanceIssues: toDos.routineRepair?.additionalInfo ?? [],
      usefulLife: data.useful_life,
      attributed: data.attributed,
      pof: data.pof_final_text,
      cof: data.cof_final_text,
      catchment: {
        catchid: data.catchment_name,
        drains_to_c: data.drains_to_c,
        gid: data.catch_id,
        drains_to_rw: data.drains_to_rw,
      },
    };
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeMuniLayerData(data);
  };

  var getDisplayCatchBasinPriority = function (priority) {
    for (const option of BmpFcsInventoryConstants.catchBasinPriorityOptions) {
      if (option.value === priority) {
        return option.name;
      }
    }

    return null;
  };

  var getOfflineData = async function (idbmp) {
    return await ResourceController.get("muniCatchBasinReport").getById(idbmp);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeMuniLayerData,
    getLayerData,
    getOfflineData,
  };
};

module.exports = MuniCatchBasinReportGeoServerLayer();

const BmpFcsFunctions = require("../../../bmpfcs/bmpFcsFunctions");
const GeoServerLayerFunctions = require("../../../mapFunctions/geoServerLayerFunctions");
const Misc = require("../../../misc");
const ResourceController = require("../../../offline/resourceController");
const ToolSettings = require("../../../settings/toolSettings");
const RoutineMaintenanceFormTable = require("../../../general/routineMaintenanceFormTable");
const BmpFcsInventoryConstants = require("../../../bmpfcs/bmpFcsInventoryConstants");
const ReportGeoServerMapLayer = require("./reportGeoServerMapLayer");
const TreeUpdates = require("../../mapFunctions/treeUpdates");
const MuniCatchBasinIcon = require("../muniCatchBasinIcon");
