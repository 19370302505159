"use strict";

var FcsProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "fcsProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.fcsProgressLayer && sameSpatialFilter && sameView && sameGroup) {
            RamMapFunctions.resizeLayerMarkers(
              mapLayers.fcsProgressLayer,
              updateFcsIcon,
              map.getZoom(),
            );
            map.addLayer(mapLayers.fcsProgressLayer);
            Clustering.setState("fcsProgress");
          } else {
            const isFetching = Tree.get(["layers", Tree.get("mapId"), "fcsProgress", "isFetching"]);
            if (sameSpatialFilter && sameView && isFetching) {
              // Temp solution to prevent laoding twice from dataView and filters update
              return;
            }
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.fcsProgressLayer)) {
            map.removeLayer(mapLayers.fcsProgressLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");
    if (!filters.catchments.length && filters.searchString) {
      Tree.set(["layers", mapId, "fcsProgress", "data"], []);
    } else {
      Tree.set(["layers", mapId, "fcsProgress", "isFetching"], true);
      ReportApiCalls.getFcsProgress(filters, function (data) {
        Tree.set(["layers", mapId, "fcsProgress", "isFetching"], false);
        Tree.set(["layers", mapId, "fcsProgress", "data"], data);
        Tree.set(["layers", mapId, "fcsProgress", "spatialView"], Tree.get("spatialView", mapId));
      });
    }
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "fcsProgress", function (data) {
      if (Filters.spatialFilterIsSet()) {
        data = spatiallyFilterFcsData(data);
      }
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.fcsProgressLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
      Clustering.setState("fcsProgress");
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.fcsProgressLayer) {
      map.removeLayer(mapLayers.fcsProgressLayer);
    }
    FcsProgressPopup.loadDomListeners();
    mapLayers.fcsProgressLayer = createLayer(data, false, map);
    mapLayers.fcsProgressLayer.addTo(map);
    return mapLayers.fcsProgressLayer;
  };

  var createLayer = function (data, highlightDrainage = false, map = false) {
    var layer = L.featureGroup();

    L.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;

        const marker = L.marker(latlng, {
          icon: BmpFcsIcon.getIconMarker(
            properties.fcsScore,
            properties.bmpTypeObj.isCentralized,
            properties.bmpTypeObj.abbreviation,
            properties.isFcs,
            null,
            properties.planned,
            properties.fixedColor,
            map.getZoom(),
          ),
        });
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => FcsProgressPopup.getFcsProgressPopupHtml(feature.properties),
          FcsProgressPopup.getPopupOptions(),
        );
        if (highlightDrainage) {
          layer.on({
            click: Layers.setDrainageHighlight,
          });
        }
      },
    }).addTo(layer);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Only watch for specific filters since other filters updates are already handled in treeUpdates
    // @TODO: Investigate why listening to filters update causes an infinite loop while searching
    Tree.watch({
      status: ["filters", "status"],
      ownership: ["filters", "ownership"],
      structureType: ["filters", "structureType"],
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        Tree.get("dataView") === "fcsView" &&
        MapFunctions.mapHasLayer(map, mapLayers.fcsProgressLayer) &&
        Tree.get("layers", mapId, "fcsProgress", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var spatiallyFilterFcsData = function (data) {
    const filters = Tree.get("filters");
    return data.filter((fcs) => {
      return (
        filters.receivingWaters.includes(fcs.catchment["drains_to_rw"]) ||
        filters.receivingWaters.includes(fcs.catchment["drains_to_c"]) ||
        filters.catchments.includes(fcs.catchment["catchid"])
      );
    });
  };

  var loadZoomListener = function (map, activeLayer) {
    const layer = activeLayer;
    map.on("zoomend", function () {
      RamMapFunctions.resizeLayerMarkers(layer, updateFcsIcon, map.getZoom());
    });
  };

  var updateFcsIcon = function (properties, zoom) {
    return BmpFcsIcon.getIconMarker(
      properties.fcsScore,
      properties.bmpTypeObj.isCentralized,
      properties.bmpTypeObj.abbreviation,
      properties.isFcs,
      null,
      properties.planned,
      properties.fixedColor,
      zoom,
    );
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    spatiallyFilterFcsData,
    createLayer,
    loadZoomListener,
    updateFcsIcon,
  };
};

module.exports = FcsProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const BmpFcsIcon = require("./bmpFcsIcon");
const Table = require("../mapFunctions/table");
const Filters = require("../mapFunctions/filters");
const Clustering = require("../mapFunctions/clustering");
const FcsProgressPopup = require("./fcsProgressPopup");
const Layers = require("../mapFunctions/layers");
const RamMapFunctions = require("../../mapFunctions/mapFunctions");
