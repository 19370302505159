"use strict";

const BmpFcsInventoryAdditionalBmpInformation = function () {
  const stringKey = "additional-bmp-information";
  const headerInformation = "sBMP Info";
  let $page;

  var loadAdditionalBmpInformationListeners = function () {
    Form.initializeAndLoadListeners($page, "bmp-fcs-inventory", { isMultiPart: true });
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    const options = BmpFcsInventoryModalController.getOptions();
    await renderPageHtml(options);
    loadAdditionalBmpInformationListeners();
    BmpFcsInventoryModalController.addHighlightsToFields($page);
    InventoryModal.restoreNavigationButtons();
  };

  var renderPageHtml = async function () {
    const renderData = getAdditionalBmpProps();

    const html = nunjucks.render(
      "modals/bmpFcsInventory/bmpFcsInventoryAdditionalBmpInformation.njk",
      renderData,
    );
    BmpFcsInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
    BmpFcsInventoryBmpFcsInformation.showAdditionalFieldsByType(renderData.bmpType, $page);
  };

  var getAdditionalBmpProps = function () {
    return {
      ...InventoryModal.getAllData("bmp-fcs-inventory"),
      ...getBmpOptionProps(),
    };
  };

  var getBmpOptionProps = function () {
    return {
      volumeUnitOptions: BmpFcsInventoryConstants.volumeUnitOptions,
      areaUnitOptions: BmpFcsInventoryConstants.areaUnitOptions,
      wetPoolCapacityUnitOptions: BmpFcsInventoryConstants.wetPoolCapacityUnitOptions,
      materialOptions: BmpFcsInventoryConstants.materialOptions,
      unitOptions: BmpFcsInventoryConstants.unitOptions,
      datumOptions: BmpFcsInventoryConstants.datumOptions,
    };
  };

  var cleanUp = function () {
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    getBmpOptionProps,
  };
};

module.exports = BmpFcsInventoryAdditionalBmpInformation();

const BmpFcsInventoryBmpFcsInformation = require("./bmpFcsInventoryBmpFcsInformation");
const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
