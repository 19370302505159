"use strict";

const ButtonStyleRadios = function () {
  let formKey;

  const init = function (_formKey) {
    formKey = _formKey;
    _loadListeners();
  };

  const _loadListeners = function () {
    $("body").on("click", ".button-style-radios .button", _clickButton);
  };

  const _clickButton = function () {
    if ($(this).hasClass("disabled")) return;

    const $button = $(this);
    const $input = $button.prev("input");
    $input.prop("checked", true);
    formKey = formKey || $input.closest("form").data("form-key");
    if (formKey) {
      Form.setFieldValue(formKey, $input, $input.val());
    }
    $button.closest(".button-style-radios").find(".button").removeClass("active suggested");
    $button.addClass("active");
  };

  return {
    init,
  };
};

module.exports = ButtonStyleRadios();

const Form = require("./form");
