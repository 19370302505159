"use strict";

const PropertyCostsAddBmps = function () {
  const stringKey = "property-costs-add-bmps";
  const headerInformation = "Site Map";
  const bmpLayerName = "propertyBmp";
  var $page;
  var isValid = true;
  var propertyPolygon;

  var loadListeners = function () {
    Form.initializeAndLoadListeners(
      $("#floating-add-bmps-table"),
      PropertyCostsModalController.getFormKey(),
      {
        isMultiPart: true,
      },
    );
  };

  var loadAssociatedFormListeners = function (data) {
    Form.initializeAndLoadListeners($page, PropertyCostsModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("change", `[name*="bmps["]`, _handleInputChange);
    $page.on("change", `[name*="phase"]`, _onChangePhase);
  };

  var _onChangePhase = function () {
    const $this = $(this);
    const showYearBuilt = ["completed", "certified"].includes($this.val());
    $this
      .closest(".assets-page")
      .find("[name*='year-built']")
      .closest("fieldset")
      .toggleClass("hidden", !showYearBuilt);
  };

  var _handleInputChange = function () {
    const $this = $(this),
      idBmp = $this.closest(".assets-page").data("id-bmp"),
      value = $this.val(),
      fieldName = ProjectInventoryConstants.extractName($this.attr("name")),
      name = Misc.kebabToCamel(fieldName),
      updateName = `${name}Updated`,
      newData = {
        [name]: value,
        [updateName]: true,
      };
    PropertyBmpLayer.updateDataByIdBmp(idBmp, newData);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = function () {
    const associatedBmps = Tree.get("layers", bmpLayerName, "dataModal");
    const addBmpEnabled = isAddBmpEnabled();

    AddBmpsMap.init(bmpLayerName);

    renderAddBmpsHtml(associatedBmps);
    showAddBmpsMap(addBmpEnabled);
    loadListeners();
    $(".assets-page").hide();
    if (associatedBmps.length > 0) {
      const topRow = associatedBmps[0].idbmp;

      $(`.assets-page[data-id-bmp="${topRow}"]`).show();
      var $addBmpsTable = $("#add-bmps-table");
      $addBmpsTable.find(`tr[data-id-bmp="${topRow}"]`).addClass("selected");
    }

    setAddBmpsHint(addBmpEnabled);
    PropertyCostsModalController.setModalTitle();
    addPropertyPolygonToMap();
  };

  var addPropertyPolygonToMap = function () {
    const geom = PropertyCostsModalController.getExistingPropertyData("geom");
    const map = ModalMap.getMap();
    propertyPolygon = L.geoJSON(geom, { style: MapStyles.styleActivePropertyPolygon }).addTo(map);
    map.fitBounds(propertyPolygon.getBounds());
  };

  var removePropertyPolygonFromMap = function () {
    if (propertyPolygon) ModalMap.getMap().removeLayer(propertyPolygon);
  };

  var isAddBmpEnabled = function () {
    return true;
  };

  var renderAddBmpsHtml = function (data) {
    const dropdownOptions = {
      ...PageController.getDropdownOptions(),
      phase: _getPhaseOptions(),
      bmpRelationship: _getBmpRelationshipOptions(),
    };

    $(".modal-dynamic-content").show();

    const html = nunjucks.render("esg/modals/propertyCosts/addBmpsDetailsForm.njk", {
      data,
      dropdownOptions,
    });
    PropertyCostsModalController.renderPageContent(html);
    $(".add-bmps-details-down-arrow").on("click", collapseForm);

    $(".assets-page").hide();

    const selectedRow = $(".add-bmps-table-rows .selected").data("id-bmp");
    $(`.assets-page[data-id-bmp="${selectedRow}"]`).show();

    $page = $(".modal-dynamic-content .add-bmps-details-container");
    loadAssociatedFormListeners(data);
    const hasSaved = Tree.get("hasSaved");
    if (hasSaved) {
      const fields = FormValidator.getRequireNotBlankFields();
      CommonModalFunctions.highlightFormFields($("#inventory-modal"), fields);
    }
    CapitalCostPage.updateCosts();
  };

  var _getPhaseOptions = function () {
    const excludePhases = ["post-active", "certified", "rejected", "shelved"];
    return BmpFcsInventoryConstants.phaseOptions.filter(
      (obj) => !excludePhases.includes(obj.value),
    );
  };

  var _getBmpRelationshipOptions = function () {
    const excludedOptions = ["none", "unknown"];
    return PageController.getDropdownOptions()?.bmpRelationship.filter(
      (obj) => !excludedOptions.includes(obj.value),
    );
  };

  var collapseForm = function () {
    $(".add-bmps-details-down-arrow > img").remove();
    $(".add-bmps-details-down-arrow").prepend(
      '<img class="add-bmps-table-arrows" src="/ram/images/assetManagement/up_arrow.png">',
    );
    $(".add-bmps-details-down-arrow")
      .addClass("add-bmps-details-up-arrow")
      .removeClass("add-bmps-details-down-arrow");
    $(".add-bmps-details-container").hide();
    $(".add-bmps-drainage-container").hide();
    removeFormArrowListeners();
    $(".add-bmps-details-up-arrow").on("click", openForm);
  };

  var openForm = function () {
    $(".add-bmps-details-up-arrow > img").remove();
    $(".add-bmps-details-up-arrow").prepend(
      '<img class="add-bmps-table-arrows" src="/ram/images/assetManagement/summary_button.png">',
    );
    $(".add-bmps-details-up-arrow")
      .addClass("add-bmps-details-down-arrow")
      .removeClass("add-bmps-details-up-arrow");
    $(".add-bmps-details-container").show();
    $(".add-bmps-drainage-container").show();
    removeFormArrowListeners();
    $(".add-bmps-details-down-arrow").on("click", collapseForm);
  };

  var removeFormArrowListeners = function () {
    $(".add-bmps-details-up-arrow").off();
    $(".add-bmps-details-down-arrow").off();
  };

  var showAddBmpsMap = function (addBmpEnabled) {
    const projectAddBmpsMapConfiguration = {
      isValidUpdateHandler: isValidUpdateHandler,
      newExistingMarkerHandler: PropertyCostsModalController.loadExistingPropertyMarker,
      // disableAddNewProjectBmp: !addBmpEnabled,
    };
    AddBmpsMap.resetAddBmpsMapHandlers();
    AddBmpsMap.configureAddBmpsMap(projectAddBmpsMapConfiguration);
    AddBmpsMap.showAddBmpsMap();
  };

  var isValidUpdateHandler = function (newBmpClickEnabled) {
    isValid = newBmpClickEnabled;
  };

  var setAddBmpsHint = function (addBmpsEnabled) {
    if (addBmpsEnabled) {
      ModalMap.showLocationMessage("Click map to add sBMP");
    }
  };

  var validateAddBmpsAndShowWarningModal = function () {
    if (!isValid) {
      MessageModal.showSimpleWarningModal(
        "<p>You have added a Structural BMP that does not have an <b>ID</b>.<br><p>In order to leave this page, <b>you must add an ID</b> or delete the Structural BMP.</p>",
        true,
      );
    }
    return isValid;
  };

  var cleanUp = function () {
    AddBmpsMap.hideAddBmpsMap();
    removePropertyPolygonFromMap();
    $page.empty();
    return true;
  };

  var validate = function () {
    return validateAddBmpsAndShowWarningModal();
  };

  var resetState = function () {};

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    resetState,
    renderAddBmpsHtml,
    showAddBmpsMap,
    _handleInputChange,
    _getPhaseOptions,
    _getBmpRelationshipOptions,
  };
};

module.exports = PropertyCostsAddBmps();

const AddBmpsMap = require("../../../mapFunctions/addBmpsMap");
const Form = require("../../../general/form");
const MessageModal = require("../../../modals/messageModal");
const ModalMap = require("../../../mapFunctions/modalMap");
const Tree = require("../../../tree");
const BmpFcsInventoryConstants = require("../../../bmpfcs/bmpFcsInventoryConstants");
const CommonModalFunctions = require("../../../modals/commonModalFunctions");
const FormValidator = require("../../../general/formValidator");
const PropertyCostsModalController = require("../propertyCostsModalController");
const PageController = require("../../pageController");
const MapStyles = require("../../../mapFunctions/mapStyles");
const ProjectInventoryConstants = require("../../../construction/projectInventoryConstants");
const PropertyBmpLayer = require("../../propertyBmpLayer");
const Misc = require("../../../misc");
const CapitalCostPage = require("./capitalCostPage");
