"use strict";

const FacilityEnforcementController = require("./facilityEnforcementController");
const BaseEnforcementLetterManagement = require("../../enforcement/baseEnforcementLetterManagement");

const EnforcementLetterManagement = function () {
  const base = BaseEnforcementLetterManagement(FacilityEnforcementController);

  return {
    ...base,
  };
};

module.exports = EnforcementLetterManagement();
