"use strict";

const MainMap = function () {
  var setUpListeners = function () {
    Tabs.startListening();
    DataViewDropdown.startDataViewDropdownListeners();
    YearDropdown.startYearDropdownListeners();
    LayerDropdown.startLayerDropdownListeners();
    //_hideDataImportBasedOnRole();
    $(window).on("resize", CommonMapFunctions.invalidateSize);

    $("#userSettingsModal").on("click", ".user-settings-ul li", function () {
      var selectedOption = $(this).attr("id");
      UserSettingsFunctions.userSettingSelected(selectedOption);
    });

    $(".data-export-link").off("click", DataExport.showModal);
    $(".data-export-link").on("click", DataExport.showModal);

    var $exportModal = $("#exportModal");
    $exportModal.off("click", ".export-button", DataExport.exportData);
    $exportModal.on("click", ".export-button", DataExport.exportData);

    $("#navbar-menu ul.dropdown-menu a").off("click", navClickEvent);
    $("#navbar-menu ul.dropdown-menu a").on("click", navClickEvent);
  };

  var _hideDataImportBasedOnRole = function () {
    if (Session.isFieldPersonnel()) {
      $(".data-import-disabled-link").removeClass("disabled");
      $(".data-import-disabled-link").addClass("hide-option");
    }
  };

  var zoomToCatchmentView = function () {
    var mapLayers = TreeUpdates.getCurrentMapLayers();
    var map = TreeUpdates.getCurrentMap();
    var selectedCatchment = Tree.get(["selected", "catchment"]);

    if (map && mapLayers && mapLayers.catchmentLayer) {
      mapLayers.catchmentLayer.eachLayer(function (layer) {
        if (layer.feature.properties.catchid === selectedCatchment) {
          map.fitBounds(layer.getBounds());
          return;
        }
      });
    }
  };

  var zoomToDrainageView = function () {
    var mapLayers = TreeUpdates.getCurrentMapLayers();
    var map = TreeUpdates.getCurrentMap();
    var catchmentsInDrainage = [];
    var selectedDrainage = Tree.get(["selected", "urbanDrainage"])[0];

    if (map && mapLayers && mapLayers.catchmentLayer) {
      mapLayers.catchmentLayer.eachLayer(function (layer) {
        if (layer.feature.properties.drains_to_c === selectedDrainage) {
          catchmentsInDrainage.push(layer);
        }
      });
      var catchLayerGroup = L.featureGroup(catchmentsInDrainage);
      map.fitBounds(catchLayerGroup.getBounds());
    }
  };

  var navClickEvent = function () {
    const linkSource = $(this).closest("li.dropdown").find("a").attr("title").split(" ")[0];
    let linkName = $(this).attr("class");
    linkName = linkName.replace("navbar-", "");
    linkName = linkName.replaceAll("-", "_");
    linkName = linkSource.concat("/").concat(linkName);
    Analytics.sendAction(["nav_link", linkName]);
  };

  return {
    zoomToCatchmentView,
    zoomToDrainageView,
    setUpListeners,
    _hideDataImportBasedOnRole,
  };
};

module.exports = MainMap();

const Analytics = require("../../general/analytics");
const CommonMapFunctions = require("./commonMapFunctions");
const DataExport = require("../dataExport");
const DataViewDropdown = require("./dataViewDropdown");
const LayerDropdown = require("./layerDropdown");
const Tabs = require("../tabs");
const Tree = require("../../tree");
const TreeUpdates = require("./treeUpdates");
const UserSettingsFunctions = require("../settings/userSettingsFunctions");
const YearDropdown = require("./yearDropdown");
const Session = require("../../login/session");
