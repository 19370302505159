"use strict";

const IndcomInventoryGeneralInfo = function () {
  const stringKey = "indcom-general-info";
  const headerInformation = "General info";
  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    await renderPageHtml();
  };

  var loadIndcomGeneralInfoListeners = function () {
    $page.on("change", `[name="facility-phase"]`, toggleFacilityPhaseField);
    $page.on("change", `[name="is-industrial-general-permit"]`, toggleIndustrialGeneralPermitField);
    $page.on("change", `[name="risk-profile"]`, toggleRiskProfileField);

    Form.initializeAndLoadListeners($page, "indcom-inventory", { isMultiPart: true });
  };

  var removeListeners = function () {
    $page.off("change", `[name="facility-phase"]`, toggleFacilityPhaseField);
    $page.off(
      "change",
      `[name="is-industrial-general-permit"]`,
      toggleIndustrialGeneralPermitField,
    );
    $page.off("change", `[name="risk-profile"]`, toggleRiskProfileField);
  };

  var toggleFacilityPhaseField = function () {
    const facilityPhase =
      IndcomInventoryModalController.getLatestIndcomInventoryData("facilityPhase");
    const closedDateToggle = facilityPhase === "closed";
    $page.find(".facility-closed-date").toggleClass("hidden", !closedDateToggle);
  };

  var toggleIndustrialGeneralPermitField = function () {
    const toggle = IndcomInventoryModalController.getLatestIndcomInventoryData(
      "isIndustrialGeneralPermit",
    );
    $page.find(".igp-number").toggleClass("hidden", !toggle);
  };

  var toggleRiskProfileField = function () {
    const riskProfile = IndcomInventoryModalController.getLatestIndcomInventoryData("riskProfile");
    const exemptionNumberToggle = riskProfile === "exempt";
    $page.find(".exemption-number").toggleClass("hidden", !exemptionNumberToggle);
  };

  var renderPageHtml = function () {
    const renderData = IndcomInventoryModalController.getInventoryForRender();
    const additionalProps = getAdditionalProps(renderData);
    const html = nunjucks.render("modals/indcomInventory/indcomInventoryGeneralInfo.njk", {
      ...renderData,
      ...additionalProps,
    });
    $page = $(".modal-dynamic-content");
    IndcomInventoryModalController.renderPageContent(html);
    loadIndcomGeneralInfoListeners();
  };

  function sortOptions(options) {
    return options.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  var getAdditionalProps = function (outfallData) {
    return {
      consequenceOfFailureOptions: IndcomInventoryConstants.consequenceOfFailureOptions,
      facilityTypeOptions: IndcomInventoryConstants.getFacilityTypeOptions(),
      pollutantsGeneratedOptions: ToolSettings.getDomainOptions("facility_pollutant"),
      responsiblePartyMaintenanceOptions: IndcomInventoryConstants.responsiblePartyOptions,
      ownershipOptions: IndcomInventoryConstants.eswiftEntityTypeOptions,
      phaseOptions: IndcomInventoryConstants.phaseOptions,
      riskFactorOptions: IndcomInventoryConstants.riskFactorOptions,
      outfallInfo: outfallData,
    };
  };

  var cleanUp = function () {
    removeListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    sortOptions,
  };
};

module.exports = IndcomInventoryGeneralInfo();

const IndcomInventoryConstants = require("./indcomInventoryConstants");
const IndcomInventoryModalController = require("./indcomInventoryModalController");
const Form = require("../general/form");
const ToolSettings = require("../settings/toolSettings");
