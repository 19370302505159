"use strict";

const FacilityInspectionGeneralInfo = function () {
  const stringKey = "general-info";
  const tabHeader = "General Info";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("change", "[name='facility[facility-type]']", toggleCommercialFoodTypes);
    $page.on("change", "[name='facility[is-industrial-general-permit]']", toggleIGPNumberField);
  };

  var unloadListeners = function () {
    $page.off("change", "[name='facility[facility-type]']", toggleCommercialFoodTypes);
    $page.off("change", "[name='facility[is-industrial-general-permit]']", toggleIGPNumberField);
  };

  var getProps = function (data) {
    return {
      inspectionCategoryOptions: ToolSettings.getDomainOptions("inspection_category"),
      inspectionTypeOptions: FormSettings.getDropdownOptions("inspection-type"),
      facilityTypeOptions: IndcomInventoryConstants.getFacilityTypeOptions().sort((dd1, dd2) =>
        dd1.name > dd2.name ? 1 : dd2.name > dd1.name ? -1 : 0,
      ),
      riskFactorOptions: IndcomInventoryConstants.riskFactorOptions,
      responsiblePartyMaintenanceOptions: IndcomInventoryConstants.responsiblePartyOptions,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var toggleCommercialFoodTypes = function () {
    const currentType = FacilityInspectionController.getAllData()?.facility?.facilityType;
    $page.find(".commercial-food-type").toggleClass("hidden", currentType !== "commercial-food");
  };

  var toggleIGPNumberField = function () {
    const isIGPPermit =
      FacilityInspectionController.getAllData()?.facility?.isIndustrialGeneralPermit;
    $page.find(".igp-number").toggleClass("hidden", !isIGPPermit);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = FacilityInspectionGeneralInfo();

const FormSettings = require("../../settings/formSettings");
const ToolSettings = require("../../settings/toolSettings");
const IndcomInventoryConstants = require("../indcomInventoryConstants");
const FacilityInspectionController = require("./facilityInspectionController");
