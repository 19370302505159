"use strict";

const IddeCatchBasinEsriDynamicMapLayer = function () {
  // @TODO: Update to use a new endpoint for IDDE catch basin layer instead of existing (muni) catch-basin
  const layerName = "iddeCatchBasinEsriDynamicMap";
  const layerKey = "iddeCatchBasinEsriDynamicMapLayer";
  const esriKey = "parentView";
  const esriLayer = "catch-basin";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var iddeCatchBasinEsriDynamicMapLayer = new EsriDynamicMapLayer(
      layerName,
      layerKey,
      esriKey,
      esriLayer,
    );
    iddeCatchBasinEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    iddeCatchBasinEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = IddeCatchBasinEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
