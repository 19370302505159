"use strict";

const Signature = function ($canvas, onChangeCallback) {
  const $clearButton = $canvas.siblings(".clear-canvas");
  const signaturePad = new SignaturePad($canvas[0], {
    onBegin: (e) => e.preventDefault(),
    onEnd: function () {
      onChangeCallback($canvas, $canvas[0].toDataURL());
    },
  });
  const $container = $canvas.parent();

  var init = function () {
    showPlaceholder();
    loadExistingData();
    initializeListeners();
  };

  var loadExistingData = function () {
    const image = $canvas.data("defaultImage");

    if (!image) {
      return;
    }

    hidePlaceholder();
    signaturePad.fromDataURL(image, { ratio: 1 });
  };

  var initializeListeners = function () {
    $clearButton.off("click", clearFn);
    $clearButton.on("click", clearFn);

    $canvas.off("pointerdown", hidePlaceholder);
    $canvas.on("pointerdown", hidePlaceholder);
  };

  var clearFn = function () {
    showPlaceholder();
    onChangeCallback($canvas, "");
    signaturePad.clear();
  };

  var showPlaceholder = function () {
    $container.addClass("placeholder");
  };

  var hidePlaceholder = function () {
    $container.removeClass("placeholder");
  };

  init();

  return {};
};

module.exports = Signature;

const SignaturePad = require("signature_pad");
