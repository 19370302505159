"use strict";

const UserSettingsFunctions = function () {
  var loadListeners = function () {
    const userSettingsModal = $("#userSettingsModal");
    userSettingsModal.off();
    userSettingsModal.on("click", "#changePasswordLi", showChangePassword);
    userSettingsModal.on("click", "#changeUsernameLi", _showChangeUsername);
    userSettingsModal.on("click", ".user-back-div", showUserSettings);
    userSettingsModal.on("submit", "#updatePasswordForm", updatePasswordSubmit);
    userSettingsModal.on("submit", "#updateUsernameForm", _updateUsername);
    userSettingsModal.on("click", "#notification-settings-li", showManageNotifications);
    userSettingsModal.on("click", "#update-notifications", updateNotificationSettings);
  };

  var updatePasswordSubmit = function () {
    Login.updatePassword();
    return false;
  };

  var showUserSettingsModal = function () {
    showUserSettings();
    $("#userSettingsModal").modal("show");
  };

  var showUserSettings = function () {
    const userData = Session.getUser();
    $("#userSettingsModal")
      .find(".modal-body")
      .html(nunjucks.render("userSettings/userSettings.njk", { isSsoUser: userData.isSsoUser }));
  };

  var _showChangeUsername = function () {
    var data = {
      email: Session.loadContext().email,
      username: Session.loadContext().username,
      fullname: Session.loadContext().full_name,
    };

    $("#userSettingsModal")
      .find(".modal-body")
      .html(nunjucks.render("userSettings/updateUsername.njk", data));
  };

  var _updateUsername = function () {
    var email = $("#emailSettings").val();
    var username = $("#usernameSettings").val();
    var fullname = $("#fullnameSettings").val();
    ApiCalls.updateUsername(
      email,
      username,
      fullname,
      function (data) {
        SingleSignOn.isLoggedIn({ token: data.token }, function () {
          SingleSignOn.warnAndRefresh(
            "Your user information has been updated. The page will now refresh.",
          );
        });
      },
      function (xhr) {
        $("#updateUserSettingsContent").html("<p>" + xhr.responseText + "</p>");
      },
    );
    return false;
  };

  var showChangePassword = function () {
    var data = {
      email: Session.loadContext().email,
    };
    $("#userSettingsModal")
      .find(".modal-body")
      .html(nunjucks.render("userSettings/changePassword.njk", data));
  };

  var showManageNotifications = async function () {
    const notifications = {
      notifications: await getNotificationSettings(),
    };

    $("#userSettingsModal")
      .find(".modal-body")
      .html(nunjucks.render("userSettings/manageNotifications.njk", notifications));
  };

  var getNotificationSettings = async function () {
    const data = await ApiCalls.getUserNotificationSettings();
    return _filterOutFeatureFlags(data);
  };

  var _filterOutFeatureFlags = function (notifications) {
    for (const key in notifications) {
      const notification = notifications[key];

      if (notification.featureFlag && !FeatureFlag.enabled(notification.featureFlag)) {
        delete notifications[key];
      }
    }

    return notifications;
  };

  var updateNotificationSettings = async function () {
    const updatedNotificationSettings = $(".notifications input")
      .map(function () {
        const $item = $(this);
        const key = $item.val();
        const checked = $item.prop("checked");
        return {
          [key]: checked,
        };
      })
      .get();
    await saveNotificationSettings(updatedNotificationSettings);
    showUserSettings();
  };

  var saveNotificationSettings = async function (updatedNotificationSettings) {
    const newToken = await ApiCalls.updateUserNotificationSettings(updatedNotificationSettings);
    TokenManager.saveToken(newToken);
  };

  return {
    showUserSettingsModal,
    showChangePassword,
    loadListeners,
    showManageNotifications,
    getNotificationSettings,
    updateNotificationSettings,
    saveNotificationSettings,
    _showChangeUsername,
    _updateUsername,
  };
};

module.exports = UserSettingsFunctions();

const ApiCalls = require("../apiCalls");
const Login = require("../login/login");
const Session = require("../login/session");
const SingleSignOn = require("../login/sso");
const TokenManager = require("../login/tokenManager");
const FeatureFlag = require("../settings/featureFlag");
