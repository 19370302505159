"use strict";

const ProjectDeliveryCertifyModal = function () {
  const $modal = $("#project-certify-modal");
  const formKey = "project-certify";
  var inited = false;
  var props;
  var initialData;

  var init = function () {
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-button", _saveCertifyModal);
    $modal.on("click", ".save-button", _saveCertifyModal);
    $modal.off("click", ".certify-button", certifyButtonClick);
    $modal.on("click", ".certify-button", certifyButtonClick);
    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);

    $modal.off("2N:FormInput", "input", _validateForm);
    $modal.on("2N:FormInput", "input", _validateForm);

    ProjectStageData.loadStageDataListeners($modal, {
      formKey,
      isInventory: false,
      limitToPast: true,
    });
  };

  var _saveCertifyModal = async function () {
    var data = Form.getDataFromForm(formKey);
    var projectId = props.id;

    prepareUploadData(data);

    if ($.isEmptyObject(data)) {
      close();
    } else {
      var formData = Form.toFormData({
        constructionProjectId: projectId,
        constructionProjectUpdates: data,
      });
      $modal.find(".modal-footer .save-btn").addClass("disabled").text("Saving");

      await ApiCalls.updateConstructionProject(formData);
      if (Tree.get("tool") === "scenario") {
        ScenariosLayer.loadLayer();
      } else {
        ConstructionProjectLayer.loadLayer();
      }

      if (Tree.get(["factSheet", "open"])) {
        ProjectFactSheetController.initFactSheet(projectId);
      }
      close();
    }
  };

  var certifyButtonClick = function () {
    MessageModal.showConfirmWarningModal(
      `This will update the phase for the project ${props.projectIdentifier} as well as the ${props.bmpCount} structural BMPs associated with it.`,
      _certifyAndSaveModal,
      "Go Back",
      "Change Phase",
    );
  };

  var _certifyAndSaveModal = function () {
    Form.manuallySetFormDataField(formKey, ["certifiedLocalDate"], DateTime.getTodayIso());
    Form.manuallySetFormDataField(formKey, ["phase"], "certified");

    return _saveCertifyModal();
  };

  var prepareUploadData = function (data) {
    ProjectTreatmentTypes.prepareTreatmentTypeUploadData(data, initialData);
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var renderAndShow = async function (projectId) {
    const data = await ApiCalls.getCertifyProjectStatus(projectId);
    _renderAndShowData(data);
  };

  var _renderAndShowData = function (data) {
    if (!inited) init();
    Form.initializeAndLoadListeners($modal, formKey);
    props = Object.assign({}, data);
    initialData = Object.assign({}, data);
    prepareRenderData();
    render();
    initializeFields();
    Analytics.sendScreenView("modal", formKey);
  };

  var render = function () {
    const html = nunjucks.render("modals/projectInventory/projectDeliveryCertifyModal.njk", props);
    $modal.html(html);
    $modal.modal("show");
  };

  var prepareRenderData = function () {
    props.stageData = ProjectStageData.getRenderStageData(props.stageData);
    ProjectTreatmentTypes.prepareTreatmentTypeData(props, null, formKey);

    const asBuiltFile = props.permitsAndPlans.find(function (file) {
      return file.type === "as-built";
    });
    props.asBuiltDesignsCompleted = asBuiltFile ? true : false;
    props.asBuiltDesignsCompletedDisplayDate = asBuiltFile?.displayApprovalDate;

    const certificationFile = props.permitsAndPlans.find(function (file) {
      return file.type === "project-certification-docs";
    });
    props.projectCertificationDocs = certificationFile ? true : false;
    props.projectCertificationDocsDisplayDate = certificationFile?.displayApprovalDate;

    props.treatmentTypes = props.treatmentTypes.filter(function (treatmentType) {
      return treatmentType.firstType === "bmp-type";
    });
    addHasCompletedBmpBenchmark(props.bmps);
  };

  var addHasCompletedBmpBenchmark = function (bmps) {
    for (const bmp of bmps) {
      bmp.hasCompletedBmpBenchmark =
        bmp.skipBenchmark ||
        bmp.bmpBenchmarks.some(function (benchmark) {
          return benchmark.complete;
        });
    }
  };

  var initializeFields = function () {
    Form.initializeAutoNumericFields($modal);
    Form.initializeDatePickers($modal, formKey);
    ProjectStageData.updateAllStageDataDatePickers(props.stageData);
    _validateForm();
  };

  var _validateForm = function () {
    $modal.find(".certify-button").prop("disabled", !_isFormCertifiable());
  };

  var _isFormCertifiable = function () {
    const ignoreAllRequirements = ifNotIgnoringRequirement("ignoreAllRequirements", false);
    const hasConstructionEndDate =
      Form.getFormDataAtPath(formKey, ["stageData", "certified", "endDateIso"]) ??
      !!props.stageData?.certified?.endDateIso;
    const asBuiltDesignsCompleted = ifNotIgnoringRequirement(
      "ignoreAsBuiltDocument",
      props.asBuiltDesignsCompleted,
    );
    const projectCertificationDocs = ifNotIgnoringRequirement(
      "ignoreCertificationDocument",
      props.projectCertificationDocs,
    );

    return (
      ignoreAllRequirements ||
      (hasConstructionEndDate &&
        asBuiltDesignsCompleted &&
        projectCertificationDocs &&
        treatmentTypesAreCertifiable())
    );
  };

  var ifNotIgnoringRequirement = function (
    certificationStatusKey,
    valueIfNotIgnoring,
    valueIfIgnoring = true,
  ) {
    if (
      Form.getFormDataAtPath(formKey, ["certificationStatus", certificationStatusKey]) ??
      props.certificationStatus?.[certificationStatusKey]
    ) {
      return valueIfIgnoring;
    } else {
      return valueIfNotIgnoring;
    }
  };

  var treatmentTypesAreCertifiable = function () {
    return props.treatmentTypes.every(function (oldTreatmentType) {
      const formData = Form.getFormDataAtPath(formKey, ["treatmentTypes", oldTreatmentType.index]);
      const treatmentType = $.extend(true, oldTreatmentType, formData);

      return (
        treatmentType.bmp?.bmpInventoryComplete &&
        treatmentType.bmp?.hasCompletedBmpBenchmark &&
        treatmentType.drainageCheck
      );
    });
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
  };

  return {
    renderAndShow,
    close,
    _closeModalIfConfirmed,
    _validateForm,
    _saveCertifyModal,
    _certifyAndSaveModal,
    _renderAndShowData,
  };
};

module.exports = ProjectDeliveryCertifyModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const DateTime = require("../dateTime");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const ProjectFactSheetController = require("./projectFactSheetController");
const ProjectStageData = require("./projectStageData");
const ProjectTreatmentTypes = require("./projectTreatmentTypes");
const Tree = require("../tree");
const ConstructionProjectLayer = require("../construction/constructionProjectLayer");
const ScenariosLayer = require("../scenario/scenariosLayer");
