"use strict";

const TelrResultLayerFunctions = function () {
  const runoffColors = ["#edf2f5", "#bedbed", "#7daac8", "#5f88a4", "#343c5b", "#020A29"]; // MapColors.runoffOpportunity
  const particulateColors = ["#fddfd7", "#f1c2b2", "#db9880", "#8d4730", "#671705", "#420d0d"]; // MapColors.particulatesOpportunity

  const colorArrayMap = {
    "current-runoff": runoffColors,
    "baseline-runoff": runoffColors,
    "potential-runoff": runoffColors,
    "current-particulate": particulateColors,
    "baseline-particulate": particulateColors,
    "potential-particulate": particulateColors,
  };
  const colorValuePropertyKeyMap = {
    "current-runoff": "currentRunoffNormalized",
    "baseline-runoff": "baselineRunoffNormalized",
    "potential-runoff": "potentialRunoffNormalized",
    "current-particulate": "currentParticulateNormalized",
    "baseline-particulate": "baselineParticulateNormalized",
    "potential-particulate": "potentialParticulateNormalized",
  };

  var getTelrResultLayerStyle = function (feature) {
    var theStyle = {
      color: "#999999",
      fillOpacity: 0.7,
      fillColor: getColorForFeature(feature),
      weight: 1,
    };
    return theStyle;
  };

  var getColorForFeature = function (feature) {
    const dataScenario = Tree.get("dataScenario");

    const colorArray = colorArrayMap[dataScenario];
    const columnArray = getColumnArrayByKey(dataScenario);
    const valuePropertyKey = colorValuePropertyKeyMap[dataScenario];
    const value = feature.properties[valuePropertyKey];

    if (value === null || columnArray.length === 0) {
      return colorArray[0];
    }

    var i = 0;
    for (; i < columnArray.length; i++) {
      if (value == 0) {
        return colorArray[0];
      } else if (value < columnArray[i]) {
        return colorArray[i];
      }
    }

    return colorArray[i];
  };

  var getColumnArrayByKey = function (key) {
    const columnArrayMap = Tree.get("columnArrayMap");

    if (!columnArrayMap[key]) {
      throw new Error(`columnArray not defined for "${key}".`);
    }

    return columnArrayMap[key];
  };

  var saveColumnArrays = function (data) {
    // Setting up for runoff/particulate baseline only. Reference getColumnArray() in report CleanData for other column arrays
    const runoffColumnNumberArray = [];
    const particulateColumnNumberArray = [];

    if (!data?.length) {
      return;
    }

    data.forEach((item) => {
      runoffColumnNumberArray.push(item.baselineRunoffNormalized);
      particulateColumnNumberArray.push(item.baselineParticulateNormalized);
    });

    const runoffColumnArray = getColumnArray(runoffColumnNumberArray);
    const particulateColumnArray = getColumnArray(particulateColumnNumberArray);

    const columnArrayMap = {
      "current-runoff": runoffColumnArray,
      "baseline-runoff": runoffColumnArray,
      "potential-runoff": runoffColumnArray,
      "current-particulate": particulateColumnArray,
      "baseline-particulate": particulateColumnArray,
      "potential-particulate": particulateColumnArray,
    };

    Tree.set("columnArrayMap", columnArrayMap);
    updateLegendColumns();
  };

  var getColumnArray = function (columnNumberArray) {
    var columnValues = SegmentData.quantile(columnNumberArray);
    columnValues.unshift(0);

    return columnValues;
  };

  var updateLegend = function () {
    const dataScenario = Tree.get("dataScenario");

    let dataType;
    if (dataScenario.includes("particulate")) {
      dataType = "particulate";
      $(".telr-result-layer-label").text("Particulate Load (ton/ac/yr)");
    } else {
      dataType = "runoff";
      $(".telr-result-layer-label").text("Runoff Rate (ft/yr)");
    }

    $(".legend-telr-result").data("type", dataType).attr("data-type", dataType);

    updateLegendColumns();
  };

  var updateLegendColumns = function () {
    const dataScenario = Tree.get("dataScenario");
    const columnArray = getColumnArrayByKey(dataScenario);
    const lastColumnIndex = columnArray.length - 1;
    const columns = getColumnsWithSigFigs(columnArray);

    const $lastColumn = $(`.telr-result-category-${lastColumnIndex}`);
    const lastColumnText = `> ${columns[lastColumnIndex]}`;
    $lastColumn.text(lastColumnText);

    for (let i = 0; i < lastColumnIndex; i++) {
      const $middleColumn = $(`.telr-result-category-${i}`);
      const middleColumnText = `>${columns[i]} - ${columns[i + 1]}`;
      $middleColumn.text(middleColumnText);
    }
  };

  var getColumnsWithSigFigs = function (columnArray) {
    const ReportCleanData = require("../report/mapFunctions/cleanData");
    const sigFigs = 2;

    return columnArray.map((column) => {
      const number = parseFloat(column);
      let string = number.toFixed(sigFigs);

      if (string === (0).toFixed(sigFigs)) {
        string = ReportCleanData.toStringFirstSigFig(number, sigFigs);
      }

      return string;
    });
  };

  var renderSummaryTable = function (
    data,
    insightData = Tree.get(["currentInsightData", "scenarios"]),
  ) {
    if (!data) {
      return;
    }

    const template = "maps/telrResultLayerSummaryTable.njk";
    const dataScenario = Tree.get("dataScenario");

    const summaryTableData = getSummaryTableData(data);
    const subtitleHtml = getSummaryTableSubtitleHtml();

    MapSummaryTable.renderSummaryTableContent(template, {
      ...summaryTableData,
      ...insightData,
      dataScenario,
      subtitleHtml,
    });
  };

  var getSummaryTableData = function (data) {
    const dataProps = getSummaryTableDataProps();
    let totalBaselineValue = 0;
    let totalValue = 0;
    let totalReductionValue = 0;
    let totalRunoffRainVolume = 0;
    let totalBaselineReducedRunoffReduction = 0;
    let totalReducedRunoffReduction = 0;

    data.forEach((item) => {
      totalBaselineValue += Number(item[dataProps.baselineValueProp]);
      totalValue += Number(item[dataProps.valueProp]);
      totalReductionValue += Number(item[dataProps.reductionValueProp]);
      totalRunoffRainVolume += Number(item[dataProps.runoffRainVolumeProp]);
      totalBaselineReducedRunoffReduction += Number(
        item[dataProps.baselineReducedRunoffReductionProp],
      );
      totalReducedRunoffReduction += Number(item[dataProps.reducedRunoffReductionProp]);
    });

    const totalBaselineRunoffRatioValue =
      (totalBaselineReducedRunoffReduction / totalRunoffRainVolume) * 100;
    const totalRunoffRatioValue = (totalReducedRunoffReduction / totalRunoffRainVolume) * 100;
    const totalRunoffRatioReductionValue = totalBaselineRunoffRatioValue - totalRunoffRatioValue;

    return {
      totalBaselineValue,
      totalValue,
      totalReductionValue,
      totalBaselineRunoffRatioValue,
      totalRunoffRatioValue,
      totalRunoffRatioReductionValue,
    };
  };

  var getSummaryTableDataProps = function () {
    const dataScenario = Tree.get("dataScenario");
    let baselineValueProp, valueProp, reductionValueProp, reducedRunoffReductionProp;

    if (dataScenario.includes("particulate")) {
      if (dataScenario.includes("potential")) {
        valueProp = "potentialParticulate";
        reductionValueProp = "potentialParticulateReduction";
        reducedRunoffReductionProp = "potentialReducedRunoffReduction";
      } else {
        valueProp = "currentParticulate";
        reductionValueProp = "currentParticulateReduction";
        reducedRunoffReductionProp = "currentReducedRunoffReduction";
      }
      baselineValueProp = "baselineParticulate";
    } else {
      // Runoff
      if (dataScenario.includes("potential")) {
        valueProp = "potentialRunoff";
        reductionValueProp = "potentialRunoffReduction";
        reducedRunoffReductionProp = "potentialReducedRunoffReduction";
      } else {
        valueProp = "currentRunoff";
        reductionValueProp = "currentRunoffReduction";
        reducedRunoffReductionProp = "currentReducedRunoffReduction";
      }
      baselineValueProp = "baselineRunoff";
    }

    const baselineReducedRunoffReductionProp = "baselineReducedRunoffReduction";
    const runoffRainVolumeProp = "runoffRainVolume";

    return {
      baselineValueProp,
      valueProp,
      reductionValueProp,
      baselineReducedRunoffReductionProp,
      reducedRunoffReductionProp,
      runoffRainVolumeProp,
    };
  };

  var getSummaryTableSubtitleHtml = function () {
    const dataScenario = Tree.get("dataScenario");
    let subtitlePart1 = "";
    let subtitlePart2 = "";
    let subtitlePart3 = "";

    if (dataScenario.includes("baseline")) {
      subtitlePart1 = "Baseline";
      subtitlePart3 = "<strong>excluding</strong> all stormwater BMPs";
    } else if (dataScenario.includes("potential")) {
      subtitlePart1 = "Potential";
      subtitlePart3 =
        "<strong>including</strong> existing and planned stormwater BMPs at optimal performance";
    } else {
      subtitlePart1 = "Current";
      subtitlePart3 = "<strong>including</strong> existing stormwater BMPs at current performance";
    }

    if (dataScenario.includes("particulate")) {
      subtitlePart1 += " Particulate Load Scenario";
      subtitlePart2 = "Estimated stormwater particulate load";
    } else {
      subtitlePart1 += " Runoff Scenario";
      subtitlePart2 = "Estimated stormwater volume";
    }

    return `<strong>${subtitlePart1}</strong>: ${subtitlePart2} ${subtitlePart3}`;
  };

  var handleTelrResultBmpLayerDisplay = function () {
    const telrResultBmpIsEnabled = Tree.get("layers", "telrResultBmp", "isEnabled");
    if (Tree.get("dataScenario").includes("baseline")) {
      if (telrResultBmpIsEnabled) {
        Tree.set(["layers", "telrResultBmp", "isEnabled"], false);
        $(`input[value="telrResultBmp"]`).prop("checked", false);
      }
      $(".telr-result-bmp-radio-group").hide();
      $(".legend-bmps").hide();
    } else {
      $(".telr-result-bmp-radio-group").show();
    }
  };

  var openPopupByGid = function (gid) {
    const mapLayers = MainMap.getMapLayers();

    mapLayers["telrResultLayer"].eachLayer(function (layer) {
      if (layer.feature.properties.gid === gid) {
        layer.openPopup();
      }
    });
  };

  return {
    getTelrResultLayerStyle,
    getColorForFeature,
    getSummaryTableData,
    getSummaryTableSubtitleHtml,
    handleTelrResultBmpLayerDisplay,
    renderSummaryTable,
    saveColumnArrays,
    updateLegend,
    openPopupByGid,
  };
};

module.exports = TelrResultLayerFunctions();

const SegmentData = require("../report/segmentData");
const Tree = require("../tree");
const MapSummaryTable = require("../general/mapSummaryTable");
const MainMap = require("../mapFunctions/mainMap");
