"use strict";

const EsriDynamicMapLayerFunctions = function () {
  var addMapClickListener = async function (map, mapLayers) {
    addPopUpCenterListener(map);
    map.on("click", async function (event) {
      const dataView = Tree.get("dataView");

      if (
        !(dataView === "roads" && Session.isNewDataDrivenRoadConditionGroup()) &&
        !["dry-weather"].includes(dataView)
      ) {
        return;
      }

      if (dataView === "dry-weather") {
        return;
      }

      const token = await EsriLayerFunctions.getEsriLayerToken();
      const esriKey = DataViewFunctions.getCurrentDataViewProperty("esriKey") || "parentView";
      const esriLayer =
        esriKey === "streetSweepingRoute"
          ? Tree.get("streetSweepingEsriLayer")
          : DataViewFunctions.getCurrentDataViewProperty("esriLayer");
      const layerId = EsriLayerFunctions.getLayerId(esriKey, esriLayer);
      const layerIdsToIdentify = EsriLayerFunctions.getLayerIdsToIdentify(esriKey);

      L.esri
        .identifyFeatures({
          url: EsriLayerFunctions.getEsriLayerUrl(esriKey),
        })
        .token(token)
        .on(map)
        .at(event.latlng)
        .tolerance(5)
        .layers(`visible:${layerIdsToIdentify.join(",")}`)
        .layerDef(
          layerIdsToIdentify,
          EsriLayerFunctions.getLayerDefsValueByLayerId(esriKey, esriLayer, layerId, true),
        )
        .format(false)
        .run(async function (error, featureCollection, response) {
          Table.removeMapHoverHighlight();

          if (error) {
            console.log(error);
            return;
          }
          const topFeature = featureCollection.features[0];
          if (!topFeature) {
            return;
          }
          const esriLayerName = EsriLayerFunctions.getEsriLayerNameByLayerId(
            esriKey,
            topFeature.layerId,
          );
          if (topFeature) {
            let popupContent;
            if (Tree.get("tool") === "assets") {
              return;
            } else if (esriLayerName === "maintenanceZone") {
              topFeature.properties.maintenanceZoneName = topFeature.properties.zone_name;
              topFeature.properties.acres = topFeature.properties.zone_area;
              popupContent = PopupContent.getLayerPopup(topFeature, "maintenanceZones");
            } else if (esriLayerName === "streams") {
              popupContent = PopupContent.getWatershedPopup(topFeature);
            } else if (esriLayerName === "manhole") {
              var dryWeatherPopupData = await ApiCalls.getIddeDryWeatherPopup(
                topFeature.id,
                "manhole",
              );
              popupContent = DryWeatherFunctions.getDryWeatherLayerPopupHtml(dryWeatherPopupData);
            } else if (esriKey === "streetSweepingRoute") {
              popupContent = await StreetSweepingPopup.getStreetSweepingRoutePopup(
                topFeature,
                esriLayerName,
              );
            } else {
              topFeature.properties.catchid = topFeature.properties.CATCH_ID;
              topFeature.properties.drains_to_rw = topFeature.properties.DRAINS_TO_RW;
              topFeature.properties.drains_to_c = topFeature.properties.DRAINS_TO_C;
              popupContent = PopupContent.getLayerPopup(topFeature, "catchments");
            }

            L.popup(MapFunctions.getPopupOptions())
              .setLatLng(event.latlng)
              .setContent(popupContent)
              .openOn(map);

            if (esriLayerName === "manhole") {
              const $element = $(`.popup-general[data-popup-type="dryWeather"]`);
              DryWeatherFunctions.loadPopupListeners(dryWeatherPopupData, $element);
              DryWeatherHistory.renderDryWeatherHistory(
                dryWeatherPopupData.history,
                dryWeatherPopupData.assetType,
              );
            }
          }
        });
    });
  };

  var addPopUpCenterListener = async function (map) {
    const dataView = Tree.get("dataView");
    if (dataView === "asset-management") {
      map.on("popupopen", function (e) {
        if (e.target._popup._latlng) {
          var px = map.project(e.target._popup._latlng);
          px.y -= e.target._popup._container.clientHeight / 2;
          map.panTo(map.unproject(px), { animate: true });
        }
      });
    }
  };

  return {
    addMapClickListener,
  };
};

module.exports = EsriDynamicMapLayerFunctions();

const ApiCalls = require("../apiCalls");
const DryWeatherFunctions = require("../illicitDischarge/dryWeatherFunctions");
const DryWeatherHistory = require("../illicitDischarge/dryWeatherHistory");
const DataViewFunctions = require("../dataViewFunctions");
const EsriLayerFunctions = require("./esriLayerFunctions");
const MapFunctions = require("./mapFunctions");
const PopupContent = require("./popupContent");
const StreetSweepingPopup = require("../muni/streetSweepingPopup");
const Session = require("../login/session");
const Table = require("./table");
const Tree = require("../tree");
