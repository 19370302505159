"use strict";

const FacilityFactSheetController = function () {
  let currentFacilityData = null;
  let currentFacilityConfigs;

  var initFactSheet = async function (id) {
    const data = await getFactSheetData(id);
    currentFacilityData = data;
    currentFacilityConfigs = FactSheetConfig.getFactSheetConfig("facility");
    FactSheet.init(currentFacilityData, currentFacilityConfigs);
    FactSheet.handleFilterFlow();
  };

  var getFactSheetData = async function (id) {
    try {
      return await ApiCalls.getFacilityForFactSheet(id);
    } catch (e) {
      ApiError.assertIs(e);
    }

    return await IndcomFacilityGeoServerLayer.getOfflineData(id);
  };

  return {
    initFactSheet,
  };
};

module.exports = FacilityFactSheetController();

const ApiCalls = require("../apiCalls");
const FactSheet = require("../general/factSheet");
const FactSheetConfig = require("../config/factSheetConfig");
const ApiError = require("../errors/apiError");
const IndcomFacilityGeoServerLayer = require("./indcomFacilityGeoServerLayer");
