"use strict";

function UrlRoutes() {
  const absolutePrefixBySite = {
    "2nform": "/ram/",
    esg: "/",
    portal: "/portal/",
  };
  let absolutePrefix;
  let prevUrl;

  const Navigo = require("navigo");
  let router;
  const compiledRoutes = {};

  function reinit() {
    router.destroy();
    init();
  }

  function init() {
    const RouteConfig = require("../config/routeConfig");
    const site = Config.get().site;

    _getRouter();
    _addGlobalHooks(site);
    _compileRoutes(RouteConfig.get()[site]);
    router.on(compiledRoutes);
    $("body").on("click", `[data-internal-link][href^="${absolutePrefix}"]`, onAbsoluteLinkClick);
    BrowserHistory.init();
    return router;
  }

  function _getRouter() {
    if (router) return router;
    absolutePrefix = absolutePrefixBySite[Config.get().site];
    return (router = new Navigo(absolutePrefix, { noMatchWarning: true }));
  }

  function _addGlobalHooks(site) {
    if (site === "esg") {
      EsgRouteFunctions.setupGlobalHooks(router);
    } else {
      router.hooks({
        after: function (match) {
          prevUrl = match.url;
        },
      });
    }
  }

  function _compileRoutes(routes) {
    for (const route in routes) {
      const handler = routes[route];
      if (typeof handler?.handler !== "function")
        throw new Error(`No handler for route "${route}"`);
      compiledRoutes[route] = {
        uses: handler.handler,
      };
      if (handler.as) {
        compiledRoutes[route].as = handler.as;
      }
      if (handler.hooks) {
        compiledRoutes[route].hooks = handler.hooks;
      }
      if (handler.data) {
        compiledRoutes[route].data = handler.data;
      }
    }
  }

  const _getRouteData = function (route) {
    return compiledRoutes[route]?.data;
  };

  function onAbsoluteLinkClick(e) {
    const path = e.currentTarget.pathname ?? $(e.currentTarget).attr("href");
    const hash = e.currentTarget.hash;

    e.preventDefault();
    hash ? navigate(`${path}${hash}`) : navigate(path);
  }

  function removeAbsolutePrefix(absolutePath) {
    if (absolutePath === absolutePrefix) return absolutePath;
    if (!absolutePath.startsWith(absolutePrefix) || absolutePath.length === absolutePrefix.length) {
      throw new Error(`${absolutePath} is not a valid path`);
    }

    return absolutePath.slice(absolutePrefix.length);
  }

  // Navigate options: https://github.com/krasimir/navigo/blob/master/DOCUMENTATION.md#navigating-between-routes
  function navigate(absolutePath, options = {}) {
    router.navigate(removeAbsolutePrefix(absolutePath), options);
  }

  function stayOnPreviousPath() {
    router.navigate(getLastUrl(), {
      callHandler: false,
      callHooks: false,
    });
  }

  function resolve() {
    router.resolve();
  }

  function navigateToDefault(triggerRoutes = false) {
    const newPath = getDefaultPath();
    router.navigate(newPath, {
      callHandler: triggerRoutes,
      callHooks: triggerRoutes,
    });
    return newPath;
  }

  function getDefaultPath() {
    const pathArray = [Tree.get("tool"), Tree.get("dataView")];
    const newPath = `${pathArray.join("/")}`;
    return newPath;
  }

  function getDataViewByPath() {
    return getDataViewByRouteData() ?? getDataViewInPath();
  }

  function getDataViewByRouteData() {
    const match = router.getCurrentLocation();
    const data = getDataByAbsolutePath(match.route.path);
    return data?.dataView;
  }

  function getToolByPath() {
    if (!window.location.pathname.startsWith("/ram/")) {
      return getToolByUrl();
    }

    const toolInPath = getToolInPath();
    if (toolInPath !== null) {
      return toolInPath;
    }

    const dataView = getDataViewByPath();
    if (dataView) {
      return ToolFunctions.getToolByDataView(dataView);
    }

    console.warn(`Unknown tool path: ${getPathPartByIndex(0)}`);
    return "";
  }

  function getToolInPath() {
    const firstPart = getPathPartByIndex(0);

    if (firstPart === "" || ToolFunctions.isValidTool(firstPart)) {
      return firstPart;
    }

    return null;
  }

  function getDataViewInPath() {
    const firstPart = getPathPartByIndex(0);
    const secondPart = getPathPartByIndex(1);

    if (
      secondPart === "" ||
      ToolFunctions.getDataViewsByTool(firstPart, false).includes(secondPart)
    ) {
      return secondPart;
    }

    return null;
  }

  function getPathPartByIndex(pathPartIndex) {
    const match = router?.getCurrentLocation() ?? "";
    return match?.url?.split("/")?.[pathPartIndex];
  }

  function getDataByAbsolutePath(absolutePath) {
    const match = router.match(absolutePath);

    if (!match) {
      return null;
    }

    const path = removeAbsolutePrefix("/" + match[0].route.path);
    return _getRouteData(path);
  }

  function getLastUrl() {
    return router.lastResolved()[0].url;
  }

  function getToolByUrl() {
    if (Config.get().site === "esg") {
      return "esg";
    }
    if (window.location.pathname.startsWith("/report/")) {
      return "report";
    }

    return "home";
  }

  function navigateToHomePage() {
    window.location = Config.get().origin;
  }

  function go(name, data) {
    router.navigateByName(name, data);
  }

  function goToPrevUrl() {
    const url = getLastUrl() === prevUrl ? getDefaultPath() : prevUrl;
    router.navigate(url);
  }

  function setHash(hash) {
    window.location.hash = hash;
  }

  function replaceFirstUrlSegmentWithLowercase() {
    const pathname = window.location.pathname;
    const pathnameLowerCaseFirstSegment = pathname.replace(/^\/[^/]*/, (str) => str.toLowerCase());

    if (pathname !== pathnameLowerCaseFirstSegment) {
      const newUrl = new URL(window.location);
      newUrl.pathname = pathnameLowerCaseFirstSegment;
      history.replaceState(history.state, "", newUrl.href);
    }
  }

  return {
    init,
    reinit,
    resolve,
    getToolByPath,
    getDataViewByPath,
    navigate,
    navigateToDefault,
    navigateToHomePage,
    stayOnPreviousPath,
    _getRouter,
    replaceFirstUrlSegmentWithLowercase,
    setHash,
    go,
    goToPrevUrl,
    getPathPartByIndex,
    getDefaultPath,
  };
}

module.exports = UrlRoutes();

const Config = require("../config");
const BrowserHistory = require("../general/browserHistory");
const ToolFunctions = require("../toolFunctions");
const Tree = require("../tree");
const EsgRouteFunctions = require("./esg/esgRouteFunctions");
