"use strict";

const DataViewController = function () {
  var init = function () {
    initDataView();
    loadDomListeners();
    loadTreeListeners();
  };

  var loadDomListeners = function () {
    $(".data-view-dropdown").on("click", ".dropdown-menu li", dataViewDropdownClick);
  };

  var loadTreeListeners = function () {
    Tree.select("dataView").on("update", (e) => {
      // @TODO: handle mapId
      var dataView = e.data.currentData;
      Actions.setUpTopLevelDataSorts();
      MainMapController.loadLegend("main");
      MainMapController.loadLegend("modal");
      MainMapController.initActionButtons();
      MainMapController.handleMapListToggleDisplay();
      MainMapController.handleTabsVisible();
      NavBar.repopulateDropdowns();
      MainMap.disableUserMapLayers();
      handleSpatialViewOnDataViewChange(dataView);
      handleLayersOnDataViewChange(dataView, "main");
      MainMap.closePopups();
      DisplayOptions.resetDisplayOptions();
      ToDoFunctions.loadToDoFiltersListeners();
      ToDoMapTable.init();
      ToDoListController.init();
      DataViewFunctions.setLastUsedDataViewByTool(Tree.get("tool"), dataView);
      ScenarioDropdown.init();
      MapSummaryTable.init();
    });
  };

  var initDataView = function () {
    var dataView = ToolFunctions.getDefaultDataView();
    Tree.set("dataView", dataView);
    $("body").attr("data-view", dataView);
    $("body").data("view", dataView);

    if (
      ToolFunctions.getToolShowDataViewDropdown() &&
      _showLimitedAccessUserDataViewDropdownButton()
    ) {
      renderDataViewDropdown(dataView);
    } else {
      hideDataViewButton();
    }
  };

  var renderDataViewDropdown = function (selectedDataView) {
    let dataViewConfigs = ToolFunctions.getToolDataViewConfigs();
    const defaultConfig = DataViewFunctions.getDataViewConfig(selectedDataView);
    dataViewConfigs = _getUpdatedDataViewDropdownOptions(dataViewConfigs);

    if (dataViewConfigs && defaultConfig) {
      var dropdownHtml = nunjucks.render("navbar/dataViewDropdown.njk", {
        dataViewConfigs,
        defaultConfig,
      });
      $(".data-view-dropdown").html(dropdownHtml).show();
    }
  };

  var _getUpdatedDataViewDropdownOptions = function (currentOptions) {
    if (!_limitDataViewDropdownOptions()) return currentOptions;
    const dataViewsForDropdown = _getLimitedAccessDropdownDataviewOptions();
    return currentOptions?.filter((option) => !dataViewsForDropdown?.includes(option?.id));
  };

  var _getLimitedAccessDropdownDataviewOptions = function () {
    const currentDataView = Tree.get("dataView");
    if (_limitDataViewDropdownOptions()) {
      return DataViewConfig.get()[currentDataView]?.limitedAccessDisabledDataViews;
    }

    return [];
  };

  var _limitDataViewDropdownOptions = function () {
    const currentDataView = Tree.get("dataView");
    const isLimitedAccess = UserPermissions.getPermission("is_limited_access");
    return currentDataView === "lid-project" && isLimitedAccess;
  };

  var _showLimitedAccessUserDataViewDropdownButton = function () {
    if (!_limitDataViewDropdownOptions()) return true;
    const totalDataviewDropdownOptions = ToolFunctions.getToolDataViewConfigs();
    const updatedDataviewDropdownOptions = _getUpdatedDataViewDropdownOptions(
      totalDataviewDropdownOptions,
    );
    return updatedDataviewDropdownOptions?.length > 1;
  };

  var dataViewDropdownClick = function () {
    Tree.set("resetFiltersToDefaultAfterFilterMenuRender", true);
    var selectedId = $(this).data("id");
    handleClosingFactSheetToMap();
    var currentSelectionId = $(".dropdown-toggle.view").data("id");
    _setGotoMapOrListBasedOnConfig(selectedId);
    MainMapController.handleMapListToggleDisplay();
    if (selectedId !== currentSelectionId) {
      setDataView(selectedId);
    }
    if (selectedId === "fcs" && currentSelectionId === "va") {
      Tree.set("loadNewTrashLines", true);
    }
    const filtersDisabled = DataViewFunctions.getCurrentDataViewProperty("filterBarHiddenButtons");
    if (filtersDisabled && filtersDisabled.includes("filter")) {
      FilterSummary.disable();
      $("#bottomFloatingInputsTable").removeClass("hidden");
    } else {
      Filters.setToDefault();
      FilterSummary.enable();
    }
  };

  var handleClosingFactSheetToMap = function () {
    if (Tree.get(["factSheet", "open"])) {
      NavToggle._showMapPanel();
    }
  };

  var _setGotoMapOrListBasedOnConfig = function (targetDataView) {
    const setToMapView = DataViewFunctions.getCurrentDataViewProperty("setToMapView");

    if (setToMapView) {
      toggleActive(targetDataView);
    }
  };

  var toggleActive = function (targetDataView) {
    const navToggleSelection = Tree.get("navToggleSelection");
    let targetToggle = "map";

    if (
      navToggleSelection === "list" &&
      DataViewFunctions.getCurrentDataViewProperty("listEnabledTabs", targetDataView).includes(
        "data",
      )
    ) {
      targetToggle = "list";
    }

    Tree.set("navToggleSelection", targetToggle);

    if (navToggleSelection !== targetToggle) {
      toggleActiveButton(targetToggle);
    }
  };

  var toggleActiveButton = function (targetToggle) {
    $(".nav-toggle-map-button").toggleClass("active", targetToggle === "map");
    $(".nav-toggle-list-button").toggleClass("active", targetToggle === "list");
  };

  var setDataView = function (selectedId, { updateUrl = true } = {}) {
    var $dataViewDropdownMenu = $(".data-view-dropdown .dropdown-menu");
    var $selectedLi = $dataViewDropdownMenu.find(`li[data-id= ${selectedId}]`);
    var selectedText = $selectedLi.find("a").text();

    Tree.set("dataView", selectedId);
    $("body").attr("data-view", selectedId);
    $("body").data("view", selectedId);

    $(".dropdown-toggle.view").data("id", selectedId);
    $(".data-view-button-text").text(selectedText);
    Dropdown.select($selectedLi);

    Filters.handleFilterMenuDisplayByDataView(selectedId);
    Filters.renderFiltersMenu();
    MainMapController.handleTabsEnabled();
    NavToggleController.handleNavToggleDisplayByTab(
      Tree.get("tool"),
      Tree.get("activeTab"),
      selectedId,
    );
    handleRoadsDataSortInteractive(selectedId);
    ToDoMapTable.update();
    ToDoListController.init();

    if (updateUrl) {
      UrlRoutes.navigateToDefault();
    }
  };

  var handleLayersOnDataViewChange = function (dataView, mapId) {
    var tool = Tree.get("tool");
    removeLayersByDataView(tool, dataView, mapId);
    removeCustomLayers();
    enableDataViewDefaultLayers(tool, dataView, mapId);
  };

  var removeCustomLayers = function () {
    const map = MainMap.getMap();
    const mapLayers = MainMap.getMapLayers();
    const customLayers = Object.keys(mapLayers).filter((layerName) =>
      layerName.includes("CustomLayer"),
    );
    customLayers?.forEach((layerName) => {
      if (MapFunctions.mapHasLayer(map, mapLayers[layerName])) {
        map.removeLayer(mapLayers[layerName]);
      }
      Tree.set(["layers", layerName, "isEnabled"], false);
    });
  };

  var enableDataViewDefaultLayers = function (tool, dataView, mapId) {
    var dataViewConfig = ToolFunctions.getToolDataViewConfigById(dataView, tool);
    if (dataViewConfig) {
      const defaultDataViewLayers = dataViewConfig.defaultLayers;
      MainMapController.enableLayers(defaultDataViewLayers, mapId);
    }
  };

  var removeLayersByDataView = function (tool, dataView, mapId) {
    var layersToRemove = ToolFunctions.getToolDataViewDisabledLayers(dataView, tool);

    if (layersToRemove) {
      DisplayOptions.removeRadioButtonLayers(mapId, layersToRemove);

      const map = MainMap.getMap();
      layersToRemove.forEach((layerName) => {
        const mapLayer = MainMap.getMapLayerByLayerName(layerName);
        if (mapLayer && map.hasLayer(mapLayer)) {
          map.removeLayer(mapLayer);
        }
        MainMap.setMapLayerByLayerName(layerName, null);
      });
    }
  };

  var handleSpatialViewOnDataViewChange = function () {
    if (SpatialViewController.isCatchmentOrDrainageView()) {
      var dataViewConfig = ToolFunctions.getCurrentDataViewConfig();
      if (dataViewConfig && !dataViewConfig.catchmentView) {
        SpatialViewController.goToMs4View();
      }
    }
  };

  var hideDataViewButton = function () {
    $(".data-view-dropdown").hide();
  };

  var showDataViewButton = function () {
    if (
      !ToolFunctions.getToolShowDataViewDropdown() ||
      !_showLimitedAccessUserDataViewDropdownButton()
    ) {
      return;
    }

    $(".data-view-dropdown").show();
  };

  var handleRoadsDataSortInteractive = function (dataView) {
    if (dataView === "roads" && Session.isNewDataDrivenRoadConditionGroup()) {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").addClass("street-sweeping");
    } else {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").removeClass("street-sweeping");
    }
  };

  return {
    init,
    setDataView,
    hideDataViewButton,
    showDataViewButton,
    toggleActiveButton,
    _setGotoMapOrListBasedOnConfig,
    _getUpdatedDataViewDropdownOptions,
    _limitDataViewDropdownOptions,
    _getLimitedAccessDropdownDataviewOptions,
    _showLimitedAccessUserDataViewDropdownButton,
  };
};

module.exports = DataViewController();

const DataViewFunctions = require("../dataViewFunctions");
const DisplayOptions = require("./displayOptions");
const Dropdown = require("../general/dropdown");
const Filters = require("./filters");
const MainMap = require("./mainMap");
const MainMapController = require("./mainMapController");
const MapFunctions = require("./mapFunctions");
const NavBar = require("../navBar");
const NavToggleController = require("../mapFunctions/navToggleController");
const Session = require("../login/session");
const SpatialViewController = require("./spatialViewController");
const ToDoFunctions = require("./toDoFunctions");
const ToDoListController = require("./toDoListController");
const ToDoMapTable = require("./toDoMapTable");
const ToolFunctions = require("../toolFunctions");
const Tree = require("../tree");
const UrlRoutes = require("../routes/urlRoutes");
const ScenarioDropdown = require("../scenario/scenarioDropdown");
const MapSummaryTable = require("../general/mapSummaryTable");
const NavToggle = require("./navToggle");
const FilterSummary = require("../filters/filterSummary");
const Actions = require("../actions");
const DataViewConfig = require("../config/dataViewConfig");
const UserPermissions = require("../login/userPermissions");
