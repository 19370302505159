"use strict";

const DateTime = function () {
  const formatTimestampStringAsDisplayDate = (timestampDate) => {
    //timestampDate expected is "YYYY-MM-DD HH:MM:SS"
    const dateArray = timestampDate.split(" ")[0].split("-");
    return [dateArray[1], dateArray[2], dateArray[0]].join("/");
  };

  const formatDueDate = (dateWithTimestamp, inspectFreq) => {
    if (dateWithTimestamp && inspectFreq.val) {
      const dateArray = dateWithTimestamp.split(" ")[0].split("-");
      let month = parseInt(dateArray[1]) + parseInt(inspectFreq.val);
      let year = parseInt(dateArray[0]);
      if (month > 12) {
        year += parseInt(month / 12);
        month %= 12;
      }
      return [month, dateArray[2], year].join("/");
    }
    return;
  };

  const isoDateYearsAgo = function (years, date = null) {
    date = date ?? new Date();
    date.setFullYear(date.getFullYear() - years);
    date.setHours(0, 0, 0, 0);
    date = getLocalAtGroupTimeZone(date);
    return getIsoString(date);
  };

  const getTodayDisplayDate = () => {
    var todayTime = new Date();
    return formatDateAsDisplayDate(todayTime);
  };

  const getCurrentWaterYear = () => {
    var dateObj = new Date();
    var month = dateObj.getMonth() + 1; //months from 1-12
    var year = dateObj.getFullYear();
    if (month > 9) {
      //if october 1st next water year has started
      year += 1;
    }
    return year;
  };

  const formatLocalStringDate = (timeStampTimeZoneString) => {
    const localDate = parseDbDateTime(timeStampTimeZoneString);
    const options = {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };
    return localDate.toLocaleDateString("en-US", options);
  };

  const formatDateStringAsDisplayDate = (dateString) => {
    const date = parseDbDateTime(dateString);
    return formatDateAsDisplayDate(date);
  };

  const formatDateAsDisplayDate = (date) => {
    const year = date.getFullYear();

    const month = (1 + date.getMonth()).toLocaleString(undefined, {
      minimumIntegerDigits: 1,
    });

    const day = date.getDate().toLocaleString(undefined, {
      minimumIntegerDigits: 1,
    });

    return `${month}/${day}/${year}`;
  };

  const dbDateStringComparator = function (date1Str, date2Str) {
    const date1 = date1Str ? parseDbDateTime(date1Str) : null;
    const date2 = date2Str ? parseDbDateTime(date2Str) : null;

    return dateComparator(date1, date2);
  };

  const dateComparator = function (date1, date2) {
    if (!date1 && !date2) {
      return 0;
    } else if (!date1) {
      return 1;
    } else if (!date2) {
      return -1;
    }

    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      throw new Error(`${date1} and ${date2} must be Date objects`);
    }

    if (date1 > date2) {
      return 1;
    }

    if (date2 > date1) {
      return -1;
    }

    return 0;
  };

  const parseDbDateTime = function (dateTimeFromDb) {
    if (typeof dateTimeFromDb !== "string") {
      console.warn("Trying to convert a non-string to a Date object");
      return dateTimeFromDb;
    }

    var date;
    var match = dateTimeFromDb.match(
      /^\s*(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+).?(\d+)?([+\-]\d+)?\s*$/,
    );

    if (match) {
      // Assumed to be in SQL standard yyyy-mm-dd hh:mm:ss
      // Or timestamptz yyyy-mm-dd hh:mm:ss.ss+-tz

      if (match[8] !== undefined) {
        date = new Date(
          Date.UTC(
            match[1],
            parseInt(match[2]) - 1,
            match[3],
            parseInt(match[4]) + parseInt(match[8]),
            match[5],
            parseInt(match[7]) * 10,
          ),
        );
      } else if (match[7] !== undefined) {
        date = new Date(
          match[1],
          parseInt(match[2]) - 1,
          match[3],
          match[4],
          match[5],
          match[6],
          parseInt(match[7]) * 10,
        );
      } else {
        date = new Date(match[1], parseInt(match[2]) - 1, match[3], match[4], match[5], match[6]);
      }
    } else {
      // Assumed to be in https://www.w3.org/TR/NOTE-datetime
      date = new Date(dateTimeFromDb);
    }

    if (isNaN(date)) {
      throw `String could not be converted to Date object: ${dateTimeFromDb}.`;
    }

    return date;
  };

  var dateEquals = function (date1, date2) {
    if (date1 === null || date1 === undefined || date2 === null || date2 === undefined) {
      return date1 === date2;
    }
    date1 = new Date(date1);
    date2 = new Date(date2);
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    return date1.getTime() === date2.getTime();
  };

  var getToday = function () {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
  };

  var formatIsoString = function (date) {
    const dateObj = parseIsoDate(date);
    if (dateObj === undefined) {
      return "—";
    }
    return Flatpickr.formatDate(dateObj, "m/d/Y");
  };

  var parseIsoDate = function (stringIsoDate) {
    return Flatpickr.parseDate(stringIsoDate, "Z");
  };

  var getTodayIso = function () {
    const today = getToday();
    return getIsoString(today);
  };

  var getNowIso = function () {
    const today = new Date(Date.now());
    return getIsoString(today);
  };

  var getIsoString = function (date) {
    return truncateMilliseconds(Flatpickr.formatDate(date, "Z"));
  };

  var parseDateToIso = function (date) {
    const dateObj = Flatpickr.parseDate(date, "m/d/Y");
    if (dateObj === undefined) {
      return "—";
    }
    return getIsoString(dateObj);
  };

  var truncateMilliseconds = function (isoString) {
    if (isoString) {
      return isoString.split(".")[0] + "Z";
    }
    return "";
  };

  var getDiffrenceInDays = function (date1, date2) {
    const diff = date1 - date2;
    const msToSecons = 1000;
    const secondsToMinutes = 60;
    const minutesToHours = 60;
    const hoursToDays = 24;

    return diff / msToSecons / secondsToMinutes / minutesToHours / hoursToDays;
  };

  // From https://github.com/moment/moment/blob/develop/src/lib/moment/diff.js
  var getDaysDifferenceNumber = function (laterDate, earlierDate) {
    assertIsValidDate(laterDate);
    assertIsValidDate(earlierDate);

    return Math.floor((laterDate - earlierDate) / 864e5);
  };

  var getWeeksDifference = function (laterDate, earlierDate) {
    var dayDifference = getDaysDifferenceNumber(laterDate, earlierDate);
    var weekDifference = dayDifference / 7;
    return weekDifference.toFixed(1);
  };

  var assertIsValidDate = function (date) {
    if (!(date instanceof Date)) {
      throw new Error(`${date} must be an instance of Date.`);
    }
    if (isNaN(date)) {
      throw new Error(`Date must be valid`);
    }
  };

  // Takes a date at the group's local time and returns a group-local display date
  var dateToGroupDisplayDate = function (date) {
    if (!(date instanceof Date)) {
      throw new Error(`${date} must be an instance of Date`);
    }

    return Tempo.format({
      date,
      format: "MM/DD/YYYY",
      tz: ToolSettings.getSetting("group", "timeZone"),
    });
  };

  // Takes a local date and returns the same date at the group's time zone
  var getLocalAtGroupTimeZone = function (date) {
    const groupTimeZone = ToolSettings.getSetting("group", "timeZone");

    return Tempo.tzDate(date, groupTimeZone);
  };

  var isPast = function (date) {
    if (!(date instanceof Date)) {
      throw new Error(`${date} must be an instance of Date`);
    }

    return date <= new Date(Date.now());
  };

  var parseDisplayDate = function (dateString) {
    if (dateString?.includes("/")) {
      const [month, day, year] = dateString.split("/");
      return new Date(Date.UTC(year, month - 1, day));
    } else {
      return new Date(dateString + "T00:00:00.000Z");
    }
  };

  return {
    formatTimestampStringAsDisplayDate,
    formatDueDate,
    isoDateYearsAgo,
    getTodayDisplayDate,
    getCurrentWaterYear,
    formatLocalStringDate,
    dbDateStringComparator,
    formatDateStringAsDisplayDate,
    formatDateAsDisplayDate,
    parseDbDateTime,
    dateEquals,
    getToday,
    getTodayIso,
    formatIsoString,
    parseDateToIso,
    truncateMilliseconds,
    getIsoString,
    getDaysDifferenceNumber,
    getDiffrenceInDays,
    getWeeksDifference,
    parseIsoDate,
    getNowIso,
    getLocalAtGroupTimeZone,
    dateToGroupDisplayDate,
    isPast,
    dateComparator,
    parseDisplayDate,
  };
};

module.exports = DateTime();

const Flatpickr = require("flatpickr");
const ToolSettings = require("./settings/toolSettings");
const Tempo = require("@formkit/tempo");
