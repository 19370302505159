"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetMonthlyCountChart = function (widgetConfig) {
  widgetConfig.hovers = [
    {
      className: "bar-group",
      getHoverHtml: InsightWidgetMonthlyCountChart.prototype.getMonthlyCountBarHoverHtml,
    },
  ];

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetMonthlyCountChart.prototype = Object.create(InsightWidget.prototype);
InsightWidgetMonthlyCountChart.prototype.constructor = InsightWidgetMonthlyCountChart;

InsightWidgetMonthlyCountChart.prototype.getHtml = function (insightData) {
  var notes = this.widgetConfig.getNotes();

  var html = nunjucks.render("insight/widgets/monthlyCountChart.njk", {
    title: this.widgetConfig.title,
    notes,
  });

  return html;
};

InsightWidgetMonthlyCountChart.prototype.renderChart = function (insightData, exportModal = false) {
  var focusDataArray = this.widgetConfig.getDataArray(insightData, "focus") || [];
  var comparisonDataArray = this.widgetConfig.getDataArray(insightData, "comparison") || [];

  var focusYear = this.widgetConfig.getYearByDataPath("focus");
  var comparisonYear = this.widgetConfig.getYearByDataPath("comparison");

  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }
  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight;

  var margin = defaults.margin,
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom;
  const extraMarginLeft = 10;

  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right - extraMarginLeft)
    .attr("height", height + margin.top + margin.bottom + 10);

  this.appendLegend(focusYear, comparisonYear, svg, width);

  const unit = this.widgetConfig.unit;
  if (unit) {
    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr("x", width + margin.left + margin.right - extraMarginLeft)
      .attr("y", 10)
      .attr("fill", "#000")
      .attr("font-size", "11px")
      .attr("font-weight", "100")
      .text(`Unit: ${unit}`);
  }

  var chartGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left + extraMarginLeft},${margin.top + 15})`);

  var scaleBandX = d3
    .scaleBand()
    .domain(focusDataArray.map((item, i) => i))
    .range([0, width]);

  var barGroups = chartGroup
    .selectAll()
    .data(focusDataArray)
    .enter()
    .append("g")
    .attr("class", "bar-group")
    .attr("data-hover-index", function (d, i) {
      return i;
    });

  barGroups
    .append("rect")
    .attr("class", "hover-background-rect")
    .attr("x", function (d, i) {
      return scaleBandX(i);
    })
    .attr("y", function (d) {
      return 0;
    })
    .attr("width", scaleBandX.bandwidth())
    .attr("height", function (d) {
      return height;
    })
    .attr("fill", "transparent");

  var scaleX = d3
    .scalePoint()
    .domain(focusDataArray.map((item, i) => i))
    .range([0, width])
    .padding(0.5);
  var axisX = d3
    .axisBottom(scaleX)
    .tickFormat((d) => focusDataArray[d]?.month)
    .tickSize(0)
    .tickPadding(10)
    .tickSizeInner(-height);
  chartGroup
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(0," + height + ")")
    .call(axisX);

  var extentY = d3.extent(focusDataArray.concat(comparisonDataArray), function (d) {
    return d.value || 0;
  });
  const maxY = extentY[1] === 0 ? 10 : Math.ceil(extentY[1] * 1.25);

  var scaleY = d3.scaleLinear().domain([0, maxY]).range([height, 0]);
  var axisY = d3.axisLeft(scaleY).ticks(5).tickSize(0).tickPadding(10).tickSizeInner(-width);
  chartGroup.append("g").attr("class", "axis").call(axisY);

  this.drawDataLines("comparison", comparisonDataArray, chartGroup, scaleX, scaleY, barGroups);
  this.drawDataLines("focus", focusDataArray, chartGroup, scaleX, scaleY, barGroups);
};

InsightWidgetMonthlyCountChart.prototype.drawDataLines = function (
  dataPath,
  dataArray,
  chartGroup,
  scaleX,
  scaleY,
  barGroups,
) {
  const color = dataPath === "focus" ? "#336F97" : "#8C8C8C";

  const filteredDataArray = dataArray.filter((datum) => datum.value !== null);
  chartGroup
    .append("path")
    .datum(filteredDataArray)
    .attr("class", "no-pointer-event")
    .attr("fill", "none")
    .attr("stroke", color)
    .attr("stroke-width", 2)
    .attr(
      "d",
      d3
        .line()
        .x(function (d, i) {
          return scaleX(i);
        })
        .y(function (d) {
          return scaleY(d.value);
        }),
    );

  barGroups
    .append("circle")
    .data(dataArray)
    .attr("cx", function (d, i) {
      return scaleX(i);
    })
    .attr("cy", function (d) {
      return scaleY(d.value);
    })
    .style("display", function (d) {
      return d.value === null ? "none" : null;
    })
    .attr("r", 5)
    .attr("fill", "white")
    .style("stroke", color);

  barGroups
    .append("circle")
    .data(dataArray)
    .attr("cx", function (d, i) {
      return scaleX(i);
    })
    .attr("cy", function (d) {
      return scaleY(d.value);
    })
    .style("display", function (d) {
      return d.value === null ? "none" : null;
    })
    .attr("r", 3)
    .attr("fill", color);
};

InsightWidgetMonthlyCountChart.prototype.appendLegend = function (
  focusYear,
  comparisonYear,
  svg,
  width,
) {
  var legend = svg.append("g").attr("class", "legend-group").attr("transform", `translate(0, 0)`);

  const size = 15;
  const padding = 5;
  const legendItemWidth = width / 8;

  legend
    .append("line")
    .style("stroke-width", 2)
    .attr("x1", 0)
    .attr("y1", 5)
    .attr("x2", size)
    .attr("y2", 5)
    .attr("class", "focus");

  legend
    .append("line")
    .style("stroke-width", 2)
    .attr("x1", legendItemWidth)
    .attr("y1", 5)
    .attr("x2", legendItemWidth + size)
    .attr("y2", 5)
    .attr("class", "comparison");

  legend
    .append("text")
    .attr("x", size + padding)
    .attr("y", 9)
    .text(focusYear);
  legend
    .append("text")
    .attr("x", legendItemWidth + size + padding)
    .attr("y", 9)
    .text(comparisonYear);
};

InsightWidgetMonthlyCountChart.prototype.getMonthlyCountBarHoverHtml = function (
  insightData,
  $hoverTarget,
) {
  var hoverIndex = $hoverTarget.data("hoverIndex");

  var hoverTitle = this.widgetConfig.getHoverTitle(hoverIndex);
  var hoverDescription = this.widgetConfig.getHoverDescription();

  var focusDataArray = this.widgetConfig.getDataArray(insightData, "focus") || [];
  var comparisonDataArray = this.widgetConfig.getDataArray(insightData, "comparison") || [];

  var focusMonthItem = focusDataArray[hoverIndex];
  var comparisonMonthItem = comparisonDataArray[hoverIndex];

  var focusCount = focusMonthItem?.value;
  var comparisonCount = comparisonMonthItem?.value;

  var trendIconClass = this.getTrendIconClass(focusCount, comparisonCount);
  var trendPercentDiff = this.getTrendPercentDiff(focusCount, comparisonCount);

  var month = focusMonthItem?.month;
  var focusYear = focusMonthItem?.year;
  var comparisonYear = comparisonMonthItem?.year;

  const dataDecimalPlaces =
    this.widgetConfig.dataDecimalPlaces !== undefined ? this.widgetConfig.dataDecimalPlaces : 0;

  var html = nunjucks.render("insight/widgets/monthlyCountBarHover.njk", {
    hoverTitle,
    hoverDescription,
    focusCount,
    comparisonCount,
    trendIconClass,
    trendPercentDiff,
    month,
    focusYear,
    comparisonYear,
    hideTrendIcon: this.widgetConfig.hideTrendIcon,
    dataDecimalPlaces,
    unit: this.widgetConfig.unit,
  });

  return html;
};

module.exports = InsightWidgetMonthlyCountChart;

const d3 = require("d3-v6");
