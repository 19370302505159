"use strict";

const ConstructionIcon = function () {
  var getConstructionProjectIconMarker = function (constructionProjectData) {
    const iconSvgClass = getConstructionProjectIconClass(constructionProjectData, true);
    const zoom = ReportMap.getActiveReportMap()?.getZoom();
    const updatedIconSizeOptions = RamMapFunctions.resizeIconsOnZoom(zoom, 35, 24);

    return new L.DivIcon({
      className: "construction-marker-icon " + iconSvgClass,
      iconSize: [
        updatedIconSizeOptions.size[0],
        updatedIconSizeOptions.size[0] + Math.floor(updatedIconSizeOptions.size[0] / 5),
      ],
    });
  };

  /**
   * @param constructionProjectData obj
   *   @param phase str
   *   @param highPriority bool
   *   @param isScorePassing bool
   *   @param hasUnresolvedEnforcementAction bool               Whether to show the enforcement action outline
   *   @param inspectionDue bool
   *   @param nextInspection obj
   *    @param isDue bool
   */
  var addComplianceIconClassParts = function (classParts, data, includePriority) {
    const isPriority = includePriority && data.isHighPriority;

    if (data.phase !== "construction" && data.phase !== "post-active") {
      if (isPriority) {
        classParts.push("priority");
      }
      classParts.push(data.phase);
    } else {
      addComplianceActiveIconClassParts(classParts, data, isPriority);
    }
  };

  var addComplianceActiveIconClassParts = function (classParts, data, isPriority) {
    if (data.hasEnforcementAction) {
      classParts.push("enforcement");
    }
    if (isPriority) {
      classParts.push("priority");
    }
    if (data.conditionName) {
      classParts.push(data.conditionName);
    } else {
      classParts.push("no-inspection");
    }
  };

  var getOtherIconClasses = function (data) {
    if (data.inspectionDue) {
      return [""];
    }

    return [];
  };

  var getConstructionProjectIconClass = function (
    constructionProjectData,
    includePriority = false,
  ) {
    const data = processConstructionProjectData(constructionProjectData);
    const phase = data.phase;
    const classParts = ["construction"];

    if (Session.isCdot()) {
      classParts.push("phase", phase);
    } else {
      addComplianceIconClassParts(classParts, data, includePriority);
    }

    return [classParts.join("-"), ...getOtherIconClasses(data)].join(" ");
  };

  var getConstructionProjectConditionName = function (isScorePassing) {
    if (isScorePassing === null || isScorePassing === undefined) {
      return null;
    }
    if (isScorePassing) {
      return "satisfactory";
    } else {
      return "unsatisfactory";
    }
  };

  var processConstructionProjectData = function (constructionProjectData) {
    return {
      phase: constructionProjectData.phase,
      hasEnforcementAction: constructionProjectData.hasUnresolvedEnforcementAction,
      isHighPriority: constructionProjectData.highPriority,
      inspectionDue: constructionProjectData.nextInspection
        ? constructionProjectData.nextInspection.isDue
        : null,
      conditionName: getConstructionProjectConditionName(constructionProjectData.isScorePassing),
    };
  };

  return {
    getConstructionProjectIconMarker,
    getConstructionProjectIconClass,
  };
};

module.exports = ConstructionIcon();

const Session = require("../../login/session");
const RamMapFunctions = require("../../mapFunctions/mapFunctions");
const ReportMap = require("../report/reportMap");
