"use strict";

var ParticulatesProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadColumnArrayUpdatedListener(mapId);
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "particulatesProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        var particulatesLayer = mapLayers.particulatesProgressLayer;
        var forceRefresh = !sameSpatialFilter || !sameView || !sameGroup;
        if (isEnabled) {
          TelrLayerFunctions.displayTelrLayer(
            particulatesLayer,
            "particulatesProgress",
            "particulateReduction",
            map,
            mapLayers,
            forceRefresh,
          );
        } else {
          if (MapFunctions.mapHasLayer(map, particulatesLayer)) {
            map.removeLayer(particulatesLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "particulatesProgress", function (data) {
      mapLayers.particulatesProgressLayer = addParticulatesProgressLayer(
        map,
        mapLayers.particulatesProgressLayer,
        data,
      );
      TelrLayerFunctions.selectTelrCatchmentsByFilter(mapLayers.particulatesProgressLayer);
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var addParticulatesProgressLayer = function (map, particulatesProgressLayer, layerData) {
    if (particulatesProgressLayer) {
      map.removeLayer(particulatesProgressLayer);
    }
    particulatesProgressLayer = new (Layers.getLayers().ParticulatesLayer)(layerData).addTo(map);

    return particulatesProgressLayer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {};

  var loadColumnArrayUpdatedListener = function (mapId) {
    Tree.select(["layers", mapId, "particulatesProgress", "columnArray"]).on(
      "update",
      function (e) {
        var columns = e.data.currentData;
        DataViewDropdown.updateLegendColumns(columns, "particulates-progress", true);
      },
    );
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = ParticulatesProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const Table = require("../mapFunctions/table");
const Layers = require("../mapFunctions/layers");
const TelrLayerFunctions = require("./telrLayerFunctions");
const DataViewDropdown = require("../mapFunctions/dataViewDropdown");
