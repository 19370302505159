"use strict";

const ActivityPostActivitySummary = function () {
  const stringKey = "post-activity-summary";
  const headerInformation = "Post activity summary";
  let $page;

  var loadActivityInfoListeners = function () {
    $page.on("change", `[name="materialType"]`, toggleOtherMaterialInput);
    $page.on("change", `[name="materialsDistributed"]`, toggleMaterialFields);

    Form.initializeAndLoadListeners(
      $page,
      ActivityInventoryModalController.getActivityInventoryFormKey(),
      { isMultiPart: true },
    );
  };

  var removeActivityInfoListeners = function () {
    $page.off("change", `[name="materialType"]`, toggleOtherMaterialInput);
    $page.off("change", `[name="materialsDistributed"]`, toggleMaterialFields);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    await renderPageHtml();
    toggleMaterialFields();
    toggleAttendeeCountField();
    toggleImpressionCountField();
    toggleOtherMaterialInput();
    loadActivityInfoListeners();
  };

  var renderPageHtml = async function () {
    const renderData = getActivityInfoProps();
    const html = await nunjucks.asyncRender("modals/peo/activityInventoryPostActivitySummary.njk", {
      ...renderData,
    });
    ActivityInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  var getActivityInfoProps = function () {
    const constants = ActivityConstants.get();

    return {
      ...ActivityInventoryModalController.getActivityInventoryData(),
      constants: {
        materials: constants.materials,
      },
    };
  };

  var toggleMaterialFields = function () {
    const materialsDistributed =
      ActivityInventoryModalController.getLatestActivityInventoryData("materialsDistributed");

    $page.find(".materials-fields").toggleClass("hidden", !materialsDistributed);
  };

  var toggleAttendeeCountField = function () {
    const activityConstants = ActivityConstants.get();
    const activity = ActivityInventoryModalController.getLatestActivityInventoryData("activity");

    const toggleAttendeeField = activity
      ? activityConstants.activities[activity]["outreachType"] === "Passive"
      : true;
    $page.find(".attendee-count").toggleClass("hidden", toggleAttendeeField);
  };

  var toggleImpressionCountField = function () {
    const activityConstants = ActivityConstants.get();
    const activity = ActivityInventoryModalController.getLatestActivityInventoryData("activity");

    const toggleImpressionField = activity
      ? activityConstants.activities[activity]["outreachType"] === "Active/Interactive"
      : true;
    $page.find(".impression-count").toggleClass("hidden", toggleImpressionField);
  };

  var toggleOtherMaterialInput = function () {
    const materialType =
      ActivityInventoryModalController.getLatestActivityInventoryData("materialType");
    const toggleOtherMaterialField = materialType == "other";

    $page.find(".other-material").toggleClass("hidden", !toggleOtherMaterialField);
  };

  var cleanUp = function () {
    removeActivityInfoListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
  };
};

module.exports = ActivityPostActivitySummary();

const Form = require("../general/form");
const ActivityInventoryModalController = require("./activityInventoryModalController");
const ActivityConstants = require("./activityConstants");
