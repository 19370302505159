"use strict";

var LayerFunctions = function () {
  var getLayerConfig = function (layerName, layerProperty = null, errorIfNoProperty = false) {
    const config = LayerConfig.get()[layerName];

    if (config === undefined) {
      throw new Error(`No configuration for layer ${layerName}.`);
    }

    if (layerProperty !== null) {
      const property = config[layerProperty];

      if (errorIfNoProperty && property === undefined) {
        throw new Error(`No property ${layerProperty} configured for ${layerName}.`);
      }

      return property;
    }

    return config;
  };

  var getLayerConfigByTreeName = function (treeName, layerProperty = null) {
    const config = LayerConfig.get();
    for (const layerName in config) {
      const layerConfig = config[layerName];

      if (layerConfig.treeName === treeName) {
        return getLayerConfig(layerName, layerProperty);
      }
    }

    console.warn(`No tree layer named ${treeName}.`);
  };

  var getMapLayerNameByTreeName = function (treeName) {
    const config = LayerConfig.get();
    for (const layerName in config) {
      const layerConfig = config[layerName];

      if (layerConfig.treeName === treeName) {
        return layerName;
      }
    }

    console.warn(`No tree layer named ${treeName}.`);
  };

  var getAllLayersAsMapLayers = function () {
    var mapLayers = {};

    for (const layerName in LayerConfig.get()) {
      mapLayers[layerName] = null;
    }

    return mapLayers;
  };

  var getCurrentLayerProperty = function (property) {
    const treeName = DataViewFunctions.getCurrentLayerName();
    return getLayerConfigByTreeName(treeName, property);
  };

  var getAllLayersWith = function (property, value = undefined) {
    const config = LayerConfig.get();
    const toReturn = {};

    for (const layerName in config) {
      const layerConfig = config[layerName];
      if (
        layerConfig[property] !== undefined &&
        (value === undefined || value === layerConfig[property])
      ) {
        toReturn[layerName] = layerConfig;
      }
    }

    return toReturn;
  };

  return {
    getAllLayersAsMapLayers,
    getLayerConfig,
    getCurrentLayerProperty,
    getLayerConfigByTreeName,
    getAllLayersWith,
    getMapLayerNameByTreeName,
  };
};

module.exports = LayerFunctions();

const LayerConfig = require("../config/layerConfig");
const DataViewFunctions = require("../dataViewFunctions");
