"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetSimpleNumberScenario = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [
      {
        className: "simple-number-container",
        getHoverHtml: InsightWidgetSimpleNumberScenario.prototype.getHoverHtml,
      },
    ];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 1;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetSimpleNumberScenario.prototype = Object.create(InsightWidget.prototype);
InsightWidgetSimpleNumberScenario.prototype.constructor = InsightWidgetSimpleNumberScenario;

InsightWidgetSimpleNumberScenario.prototype.getHtml = function (insightData) {
  var title = this.widgetConfig.title;

  var focusDataNumber = this.widgetConfig.getDataNumber(insightData, "focus");
  var comparisonDataNumber = this.widgetConfig.getDataNumber(insightData, "comparison");

  var focusDataUnit = this.widgetConfig.getDataUnit(insightData, "focus");
  var comparisonDataUnit = this.widgetConfig.getDataUnit(insightData, "comparison");

  var focusSubtitle = this.widgetConfig.getSubtitle(insightData, "focus");
  var comparisonSubtitle = this.widgetConfig.getSubtitle(insightData, "comparison");

  var focusOnBottom = this.widgetConfig.focusOnBottom ? this.widgetConfig.focusOnBottom() : false;

  var html = nunjucks.render("insight/widgets/simpleNumber.njk", {
    title,
    focusDataNumber,
    comparisonDataNumber,
    focusDataUnit,
    comparisonDataUnit,
    focusSubtitle,
    comparisonSubtitle,
    dataDecimalPlaces: 0,
    focusOnBottom,
    scenario: true,
  });

  return html;
};

InsightWidgetSimpleNumberScenario.prototype.getHoverHtml = function (insightData) {
  var hoverTitle = this.widgetConfig.getHoverTitle(insightData);
  var hoverDescription = this.widgetConfig.getHoverDescription(insightData);
  var hoverData = this.widgetConfig.getHoverData(insightData);

  var html = nunjucks.render("insight/widgets/simpleNumberScenarioHover.njk", {
    hoverTitle,
    hoverDescription,
    ...hoverData,
  });

  return html;
};

module.exports = InsightWidgetSimpleNumberScenario;
