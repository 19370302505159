"use strict";

const DataImport = function () {
  var $importModal;
  var currentPage;
  var selectedLayer;

  var init = function () {
    $importModal = $("#import-modal");
    loadDataImportListeners();
  };

  var loadDataImportListeners = function () {
    $(".data-import-link").off("click", start);
    $(".data-import-link").on("click", start);

    $importModal.off("click", ".data-next-button", goToDataPage);
    $importModal.on("click", ".data-next-button", goToDataPage);

    $importModal.off("click", ".import-next-button", goToImportPage);
    $importModal.on("click", ".import-next-button", goToImportPage);

    $importModal.off("click", ".back-button", goToPreviousPage);
    $importModal.on("click", ".back-button", goToPreviousPage);

    $importModal.off("click", closePopoverOnClickOutside);
    $importModal.on("click", closePopoverOnClickOutside);

    $importModal.off("click", ".import-button", handleImportButtonClick);
    $importModal.on("click", ".import-button", handleImportButtonClick);
  };

  var start = function () {
    currentPage = "layer-selection";
    $importModal.html(nunjucks.render("dynamicModals/dataImport.njk"));
    $importModal.find(".back-button").hide();
    LayerSelection.show($importModal.find(".modal-body"), setSelectedLayer);
    $importModal.modal("show");
  };

  var closePopoverOnClickOutside = function (e) {
    $('[data-toggle="popover"],[data-original-title]').each(function () {
      if (
        !$(this).is(e.target) &&
        $(this).has(e.target).length === 0 &&
        $(".popover").has(e.target).length === 0
      ) {
        (($(this).popover("hide").data("bs.popover") || {}).inState || {}).click = false; // fix for BS 3.3.6
      }
    });
  };

  var setModalTitleToUploadedCsv = function (filename, numRows) {
    setModalTitleText("Data Import: ");
    setModalSubtitleText(
      `${filename}, ${numRows} ${NunjucksFilters.pluralIfMultiple("row", numRows)}`,
    );
  };

  var setModalTitleToSelectedLayer = function () {
    setModalTitleText("Data Import: ");
    setModalSubtitleText(selectedLayer.name);
  };

  var resetModalTitle = function () {
    setModalTitleText("Data Import");
    setModalSubtitleText("");
  };

  var setModalTitleText = function (text) {
    $importModal.find(".import-modal-title").text(text);
  };

  var setModalSubtitleText = function (text) {
    $importModal.find(".import-modal-subtitle").text(text);
  };

  var goToDataPage = function () {
    $importModal.find(".data-next-button").hide();
    $importModal.find(".import-next-button").show(); // toImportPage

    setModalTitleToSelectedLayer();
    $importModal.find(".back-button").show();
    LayerSelection.cleanUp();
    if (selectedLayer.type === "url") {
      initializeUrlPage();
    } else if (selectedLayer.type === "csv") {
      initializeCsvPage();
    }
  };

  var initializeUrlPage = function () {
    setCurrentPage("url-validate");
    UrlImport.init(selectedLayer.value, selectedLayer.mapLayers, selectedLayer.handler);
    UrlImport.show($importModal.find(".modal-body"), toggleImportEnabledButton);
  };

  var initializeCsvPage = function () {
    setCurrentPage("csv-preparation");
    CsvUpload.init(
      selectedLayer.value,
      selectedLayer.mapLayers,
      setImportPromise,
      setModalTitleToUploadedCsv,
      selectedLayer.exportEnabled,
    );
    CsvUpload.show($importModal.find(".modal-body"), toggleImportEnabledButton);
  };

  var goToImportPage = function () {
    if (selectedLayer.type === "url") {
      UrlImport.validateUrl();
    } else {
      setCurrentPage("csv-upload");
      $importModal.find(".csv-preparation").hide();
      $importModal.find(".csv-import").show();
      $importModal.find(".import-button").show();
      $importModal.find(".import-next-button").hide();
      toggleWideImportModal(true);
    }
  };

  var setImportPromise = async function (importPromise) {
    const $importButton = $importModal.find(".import-button");
    $importButton.prop("disabled", true).html("Importing");
    return importPromise.finally(() => {
      $importButton.hide();
      setCurrentPage("import-finished");
      $importButton.html("Import");
    });
  };

  var goToPreviousPage = function () {
    toggleWideImportModal(false);
    if (["csv-preparation", "url-validate", "import-finished"].includes(currentPage)) {
      showLayerSelectionButtons();
      resetModalTitle();
      setCurrentPage("layer-selection");
      if (selectedLayer.type === "url") {
        toggleImportEnabledButton(false);
      } else {
        CsvUpload.hideErrorMessage();
      }
      LayerSelection.show($importModal.find(".modal-body"), setSelectedLayer);
    } else if (currentPage === "url-import") {
      showUrlPageButtons();
      setCurrentPage("url-validate");
    } else if (currentPage === "csv-upload") {
      showCsvDataPage();
      setCurrentPage("csv-preparation");
    }
  };

  var showLayerSelectionButtons = function () {
    $importModal.find(".back-button").hide();
    $importModal.find(".import-button").hide();
    $importModal.find(".import-next-button").hide();
    $importModal.find(".data-next-button").show();
  };

  var showUrlPageButtons = function () {
    $importModal.find(".back-button").show();
    $importModal.find(".import-button").hide();
    $importModal.find(".import-next-button").show();
    $importModal.find(".url-validator").show();
    $importModal.find(".url-upload").hide();
  };

  var showCsvDataPage = function () {
    CsvUpload.resetImportPreview();
    setModalTitleToSelectedLayer();
    toggleImportEnabledButton(false);
    $importModal.find(".csv-preparation").show();
    $importModal.find(".csv-import").hide();
    $importModal.find(".import-button").hide();
    $importModal.find(".import-next-button").show();
  };

  var setSelectedLayer = function (layer) {
    if (layer.value === undefined) console.error("Layer must have a value property.");
    if (layer.name === undefined) console.error("Layer must have a name property.");
    selectedLayer = layer;
  };

  var setCurrentPage = function (newPage) {
    currentPage = newPage;
  };

  var getCurrentPage = function () {
    return currentPage;
  };

  var toggleImportEnabledButton = function (enabled) {
    $importModal.find(".import-button").prop("disabled", !enabled);
  };

  var handleImportButtonClick = function () {
    if (selectedLayer.type === "url") {
      UrlImport.importValidatedUrl();
    } else {
      CsvUpload.importValidatedFile();
    }
  };

  var toggleWideImportModal = function (enabled) {
    $importModal.find(".modal-dialog").toggleClass("import-table-preview", enabled);
  };

  return {
    init,
    start,
    setModalTitleToUploadedCsv,
    setCurrentPage,
    getCurrentPage,
    goToDataPage,
    goToImportPage,
    goToPreviousPage,
    resetModalTitle,
    toggleWideImportModal,
  };
};

module.exports = DataImport();

const LayerSelection = require("./layerSelection");
const CsvUpload = require("./csvUpload");
const UrlImport = require("./urlImport");
const NunjucksFilters = require("../general/nunjucksFilters");
