"use strict";

const EsriDynamicMapLayer = function (layerName, layerKey, esriKey, esriLayer) {
  const layerConfig = LayerFunctions.getLayerConfig(layerName);

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      var currentMapLayer = mapLayers[`${layerName}Layer`];
      if (isEnabled) {
        enableLayer(map, mapLayers, mapId, currentMapLayer, sameSpatialFilter);
      } else {
        disableLayer(map, mapLayers, mapId, currentMapLayer);
      }
    });
  };

  var enableLayer = function (map, mapLayers, mapId, currentMapLayer, sameSpatialFilter) {
    if (!layerConfig?.enableCatchmentLayer) {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").addClass("esri-dynamic-map-layer");
    }

    if (!Tree.get("enabledEsriLayers").includes(esriLayer)) {
      Tree.select("enabledEsriLayers").push(esriLayer);
    }

    if (currentMapLayer && (mapId === "modal" || (mapId === "main" && sameSpatialFilter))) {
      map.addLayer(currentMapLayer);
    } else {
      loadEsriLayer(map, mapLayers, mapId);
    }
  };

  var disableLayer = function (map, mapLayers, mapId, currentMapLayer) {
    const enabledEsriLayers = Tree.get("enabledEsriLayers").filter(
      (enabledLayer) => enabledLayer !== esriLayer,
    );
    Tree.set("enabledEsriLayers", enabledEsriLayers);
    if (!Tree.get("enabledEsriLayers")?.length && !Tree.get("enabledGeoServerLayers")?.length) {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").removeClass(
        "esri-dynamic-map-layer",
      );
    }
    if (MapFunctions.mapHasLayer(map, currentMapLayer)) {
      map.removeLayer(currentMapLayer);
    }
  };

  var loadEsriLayer = async function (map, mapLayers, mapId) {
    if (!mapLayers[layerKey]) {
      mapLayers[layerKey] = await createEsriLayer();
    } else {
      const layerId = EsriLayerFunctions.getLayerId(esriKey, esriLayer);
      const currentLayerDefinition = EsriLayerFunctions.getLayerDefs(
        esriKey,
        esriLayer,
        false,
        layerConfig?.emptyLayerDef,
      );
      const sameDynamicLayerDefinition = checkLayerArray(
        layerId,
        currentLayerDefinition,
        mapLayers,
      );
      if (!sameDynamicLayerDefinition) {
        mapLayers[layerKey].setLayerDefs(currentLayerDefinition);
      }
    }

    if (Tree.get("layers", layerName, Actions.getLayerIsEnabledPathByMapId(mapId))) {
      mapLayers[layerKey].addTo(map);
    }
    return mapLayers[layerKey];
  };

  var createEsriLayer = async function () {
    var mapLayerOptions = getMapLayerOptions();
    var layer = await EsriLayerFunctions.getEsriLayer(esriKey, esriLayer, mapLayerOptions);
    return layer;
  };

  var getMapLayerOptions = function () {
    return {
      opacity: layerConfig?.opacity || 1,
      pane: layerConfig?.pane || "overCatchmentPane",
      layerDefs: EsriLayerFunctions.getLayerDefs(
        esriKey,
        esriLayer,
        false,
        layerConfig?.emptyLayerDef,
      ),
    };
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers, mapId) {
    Tree.select("filters").on("update", function () {
      const currentMapLayer = mapLayers[layerKey];
      if (
        currentMapLayer &&
        MapFunctions.mapHasLayer(map, currentMapLayer) &&
        Tree.get("layers", layerName, "isEnabled")
      ) {
        loadEsriLayer(map, mapLayers, mapId);
      }
    });
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.select("filters").on("update", function () {
      if (
        mapLayers[layerKey] &&
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", layerName, "isEnabled")
      ) {
        mapLayers[layerKey].setLayerDefs(
          EsriLayerFunctions.getLayerDefs(esriKey, esriLayer, layerConfig?.emptyLayerDef),
        );
      }
    });
  };

  var checkLayerArray = function (layerId, currentLayerDefinition, mapLayers) {
    let sameDynamicLayerDefinition;
    if (Array.isArray(layerId)) {
      for (const layer in layerId) {
        sameDynamicLayerDefinition =
          currentLayerDefinition?.[layer] === mapLayers[layerKey].getLayerDefs()?.[layer];
      }
    } else {
      sameDynamicLayerDefinition =
        currentLayerDefinition?.[layerId] === mapLayers[layerKey].getLayerDefs()?.[layerId];
    }
    return sameDynamicLayerDefinition;
  };

  return {
    loadFiltersUpdatedListenerForMap,
    loadToggledListenerForMap,
    loadTreeUpdateListenersForMap,
  };
};

module.exports = EsriDynamicMapLayer;

const Actions = require("../actions");
const EsriLayerFunctions = require("./esriLayerFunctions");
const MapFunctions = require("./mapFunctions");
const Tree = require("../tree");
const LayerFunctions = require("../layerFunctions");
