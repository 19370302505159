"use strict";

const YearDropdown = function () {
  var startYearDropdownListeners = function () {
    var $yearDropdown = $("#year-dropdown");

    $yearDropdown.off();
    $yearDropdown.on("click", ".year-dropdown-list li", yearDropdownLiClick);
  };

  var yearDropdownLiClick = function () {
    var $theThis = $(this);
    if ($theThis.hasClass("disabled")) return;
    var year = $(this).val();
    setYearDropdown(year);

    Analytics.sendAction(["select_year", year]);
  };

  var setYearDropdown = function (year = null) {
    if (year === null) {
      year = AnnualReports.getSelectedYear();
    } else {
      Tree.set(["yearDropdownSelection"], year);
    }

    setSentryExtra("selectedYear", year);
    BarChartController.highlightYear(year);
    updateYearDropdown(year);
    GroupSettings.updateSettings();
    DataViewFunctions.handleDataViewDisabled();
    DataViewFunctions.customizeDataViewDropdownByGroup();
    handleYearDropdownDisabled();
    SubmitReport.updateSubmitReport();
  };

  var updateYearDropdown = function (selectionKey) {
    const dropdownList = $(".year-dropdown-list");
    dropdownList.children().removeClass("dropdown-selection");

    const $yearDropdownBtnText = $("#year-dropdown .dropdown-btn-text");
    const selectionText = dropdownList.find(`li[value=${selectionKey}]`).text();
    $yearDropdownBtnText.text(selectionText);

    dropdownList.find("li[value=" + selectionKey + "]").addClass("dropdown-selection");

    $yearDropdownBtnText.data("selected", selectionKey);

    RegionDropdown.handleDisablingRegionButtons();
  };

  var handleYearDropdownDisabled = function () {
    const mapNotShown = !Tree.get("mapId");
    if (Progeny.activeHasProgeny() || mapNotShown) {
      $(".year-dropdown-list li").removeClass("disabled");
      return;
    }

    const dataModule = DataViewFunctions.getCurrentDataModule();
    const dataName = AnnualReports.mapDataModuleToDataName(dataModule);
    const activeYears = AnnualReports.getMs4Years(dataName);

    $(".year-dropdown-list li").addClass("disabled");

    activeYears.forEach((year) => {
      $(".year-dropdown-list").find(`[value='${year}']`).removeClass("disabled");
    });
  };

  var populateYearDropdown = function (years) {
    const $dropdown = $("#year-dropdown");

    const html = nunjucks.render("report/navbar/yearDropdown.html", { years: years });
    $dropdown.find(".year-dropdown-list").html(html);

    updateYearDropdownDisplay();
  };

  var updateYearDropdownDisplay = function () {
    const $dropdown = $("#year-dropdown");
    const years = $dropdown.find(".year-dropdown-list li").length;
    var display = false;

    if (years > 0 && PageFunctions.isCurrentPageSet()) {
      display = PageGroupFunctions.getCurrentPageGroupProperty("yearDropdown");
    }

    $dropdown.toggle(display);
  };

  var setSentryExtra = function (extra, value) {
    Sentry.getCurrentScope().setExtra(extra, value);
  };

  var setSelectedOrValidYear = function () {
    if (Progeny.activeHasProgeny()) {
      return;
    }

    const dataModule = DataViewFunctions.getCurrentDataModule();
    const selectedDataName = AnnualReports.mapDataModuleToDataName(dataModule);

    const selectedValidReports = AnnualReports.getYearReports(
      AnnualReports.getSelectedYear(),
      selectedDataName,
    );
    if (selectedValidReports.length === 0) {
      const validReport = AnnualReports.getLatestMs4Report(selectedDataName).reportingYear;
      if (validReport === null) {
        throw Error(`Can't find latest report with data name ${selectedDataName}.`);
      }
      setYearDropdown(validReport);
    }
  };

  var disableAllExceptYears = function (years = []) {
    let $menuItems = $("#year-dropdown li");
    years.forEach(function (year) {
      $menuItems = $menuItems.not("li[value=" + year + "]");
    });
    $menuItems.addClass("disabled");
  };

  var enableAllYears = function () {
    $("#year-dropdown li").removeClass("disabled");
  };

  return {
    startYearDropdownListeners,
    handleYearDropdownDisabled,
    setYearDropdown,
    populateYearDropdown,
    updateYearDropdownDisplay,
    setSelectedOrValidYear,
    disableAllExceptYears,
    enableAllYears,
  };
};

module.exports = YearDropdown();

const Analytics = require("../../general/analytics");
const AnnualReports = require("../annualReports");
const BarChartController = require("../report/progress/barChartController");
const DataViewFunctions = require("../dataViewFunctions");
const GroupSettings = require("../settings/groupSettings");
const PageFunctions = require("../pageFunctions");
const PageGroupFunctions = require("../pageGroupFunctions");
const Progeny = require("../../login/progeny");
const RegionDropdown = require("../regionDropdown");
const Sentry = require("@sentry/browser");
const SubmitReport = require("../report/submitReport");
const Tree = require("../../tree");
