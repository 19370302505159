"use strict";

var MapStyles = function () {
  const purple = "#5e2887";
  const red = "#d95945";
  const orange = "#f98628";
  const yellow = "#e0bc67";
  const light_green = "#78b362";
  const green = "#389675";
  const highlightYellow = "#ebe9a3";
  const highlightYellowOutline = "#c6cc28"; //"#bec23b";
  const grey = "#666666";
  const strokeColor = "#999999";
  const white = "#ffffff";
  const blue = "#3388ff";

  const dueIconClass = "due";

  var getHexColors = function () {
    return {
      purple,
      red,
      yellow,
      orange,
      green,
      grey,
      highlightYellow,
      highlightYellowOutline,
      blue,
      white,
    };
  };

  var styleThreeOThree = function (feature) {
    var theStyle = {
      color: 0,
      weight: 0,
      opacity: 0,
    };

    var color = getStreamColor(feature.properties.flow_type);
    theStyle = {
      color: color,
      weight: 2,
      opacity: 0.5,
    };
    return theStyle;
  };

  var styleBaseStormdrain = function (feature) {
    var theStyle = {
      color: "#000000",
      weight: 4,
      opacity: 1,
    };
    return theStyle;
  };

  var styleMiddleStormdrain = function (feature) {
    var theStyle = {
      color: white,
      weight: 3,
      opacity: 0,
    };
    return theStyle;
  };

  var styleStormdrain = function (feature) {
    var theStyle = {
      color: "#000000",
      weight: 3,
      opacity: 0,
      dashArray: "5,10",
      lineCap: "square",
    };
    return theStyle;
  };

  // var styleEmptyStormdrain = function(feature) {
  //   var theStyle = {
  //     color: "#000000",
  //     weight: 3,
  //     opacity: 0,
  //     lineCap: "square"
  //   };
  //   return theStyle;
  // };

  var getTrashPointIcon = function (condition) {
    const TrashPointIcon = L.DivIcon.extend({
      createShadow: function (oldIcon) {
        return this._createIcon("shadow", oldIcon);
      },
    });
    const theClass = "trash-point-" + condition.toLowerCase().replace(/ /g, "-");
    const icon = new TrashPointIcon({
      className: "trash-point-icon " + theClass,
      iconSize: [20, 20],
    });
    return icon;
  };

  var styleCollectorData = function (feature) {
    var color = getTrashDataColor(feature.properties.condition);
    var theStyle = {
      color: color,
      weight: 3,
      opacity: 1,
      lineCap: "square",
    };
    return theStyle;
  };

  var styleCollectorOutline = function (feature) {
    var theStyle = {
      color: grey,
      weight: 5,
      opacity: 1,
      lineCap: "square",
    };
    return theStyle;
  };

  var styleTrashPoints = function (feature) {
    var color = getTrashDataColor(feature.properties.condition);
    var theStyle = {
      radius: 5,
      color: color,
      weight: 3,
      opacity: 1,
    };
    return theStyle;
  };

  var styleParcelCondition = function (props, zoom) {
    return {
      stroke: true,
      fill: true,
      color: strokeColor,
      fillOpacity: 0.5,
      strokeOpacity: 1,
      fillColor: getParcelConditionColor(props.condition),
      weight: 1,
    };
  };

  var highlights = function () {
    var theStyle = {
      color: highlightYellowOutline,
      fillOpacity: 0.65,
      weight: 4,
      fillColor: highlightYellow,
    };
    return theStyle;
  };

  var zoomHighlights = function (zoom) {
    const opacity = getOpacityFromZoom(zoom);
    var theStyle = {
      color: highlightYellowOutline,
      fillOpacity: opacity,
      weight: 4,
      fillColor: highlightYellow,
    };
    return theStyle;
  };

  var getOpacityFromZoom = function (zoom) {
    return zoom < 13 ? 0.65 : zoom >= 12 && zoom < 14 ? 0.5 : zoom >= 14 && zoom < 16 ? 0.3 : 0.2;
  };

  var selectedHighlight = function (thickness) {
    if (!thickness) {
      thickness = 4;
    }
    var theStyle = {
      color: highlightYellowOutline,
      fillOpacity: 0,
      weight: thickness,
      fillColor: highlightYellow,
    };
    return theStyle;
  };

  var grayResults = function (color) {
    var grayColor = getGrayColor(color);
    var theStyle = {
      color: "#888888",
      fillOpacity: 0,
      weight: 1.25,
      fillColor: grayColor,
    };
    return theStyle;
  };

  var getGrayColor = function (TSStaa) {
    return TSStaa < 50
      ? "#E8E8E8"
      : TSStaa >= 50 && TSStaa < 100
        ? "#C8C8C8"
        : TSStaa >= 100 && TSStaa < 150
          ? "#B0B0B0"
          : TSStaa >= 150 && TSStaa < 200
            ? "#989898"
            : TSStaa >= 200 && TSStaa < 250
              ? "#808080"
              : "#686868";
  };

  var styleCatchments = function (feature) {
    var theStyle = {
      color: "#888888",
      fillOpacity: 0,
      fillColor: "#598fb4",
      weight: 1.25,
    };
    return theStyle;
  };

  var getMapIconClasses = function (conditionArray, assessmentDue, planned) {
    var classNames = [];

    if (planned) {
      classNames.push("white");
    } else {
      classNames.push(getConditionArrayColor(conditionArray));
    }

    if (assessmentDue) {
      classNames.push(dueIconClass);
    }

    return classNames.join(" ");
  };

  var convertBmpColorToHex = function (color) {
    switch (color) {
      case "white":
        return "#fff";
      case "blue":
        return "#5c99bf";
      case "red":
        return "#d95945";
      case "orange":
        return "#f98628";
      case "yellow":
        return "#e0bc67";
      case "green_light":
        return "#78b362";
      case "green_dark":
        return "#389675";
      case "green":
        return "#389675";
      case "grey":
        return grey;
      default:
        return grey;
    }
  };

  var getBmpIcon = function (bmp_type_number, condition, maint, fcs, assessmentDue, planned) {
    var color, bmp_type_abbr;
    if (!bmp_type_number) {
      color = "grey";
      bmp_type_abbr = "?";
    } else {
      color = getMapIconClasses(condition, assessmentDue, planned);
      bmp_type_abbr = getBmpTypeAbbr(bmp_type_number);
    }
    var checkView = Session.loadContext().gid;
    var isCentralized = isCentralizedBMP(parseInt(bmp_type_number));

    // div icon with shadow
    var BMPIcon = L.DivIcon.extend({
      createShadow: function (oldIcon) {
        return this._createIcon("shadow", oldIcon);
      },
    });

    var cls;
    if (fcs) {
      cls = isCentralized ? "bmp-l fcs-l-" + color : "fcs-s-" + color;
    } else {
      cls = isCentralized ? "bmp-l bmp-l-" + color : "bmp-s-" + color;
    }

    var shadow = isCentralized
      ? "/ram/images/report/squareShadow.png"
      : "/ram/images/report/shadow.png";
    var icon = new BMPIcon({
      className: "bmp-marker-icon " + cls,
      iconSize:
        isCentralized && (checkView < 1 || checkView === undefined)
          ? [40, 48]
          : isCentralized
            ? [48, 54]
            : [34, 42],
      iconAnchor: isCentralized ? [20, 40] : [17, 36],
      shadowSize: isCentralized ? [62, 86] : [46, 60],
      shadowAnchor: isCentralized ? [22, 80] : [14, 58],
      popupAnchor: isCentralized ? [1, -12] : [0, -15],
      shadowUrl: shadow,
      html: "<span>" + bmp_type_abbr.toUpperCase() + "</span>",
    });
    return icon;
  };

  var getBmpTypeAbbr = function (type) {
    var abbr = [
      "",
      "db",
      "wb",
      "ib",
      "tv",
      "mf",
      "bf",
      "sb",
      "bi",
      "if",
      "pp",
      "st",
      "di",
      "br",
      "dt",
      "bs",
      "?",
      "fd",
      "tt",
      "rb",
    ];
    return abbr[type];
  };

  var isCentralizedBMP = function (bmpTypeNumber) {
    return [1, 2, 3, 4, 5, 6, 14, 18, 19].indexOf(parseInt(bmpTypeNumber)) > -1;
  };

  var getBarHeight = function (score) {
    const heightsConditionMap = {
      null: "6px",
      poor: "6px",
      fair: "10px",
      acceptable: "14px",
      optimal: "18px",
    };

    const condition = getConditionFromScore(score);

    return heightsConditionMap[condition];
  };

  var getConditionArrayColor = function (conditionArray) {
    var condition = conditionArray[0] ? conditionArray[0].condition : null;

    return getConditionColor(condition);
  };

  var getConditionColor = function (score, hex = false) {
    const hexColors = {
      null: grey,
      poor: red,
      fair: orange,
      acceptable: yellow,
      optimal: green,
    };
    const textColors = {
      null: "grey",
      poor: "red",
      fair: "orange",
      acceptable: "yellow",
      optimal: "green",
    };
    const condition = getConditionFromScore(score);

    if (hex) {
      return hexColors[condition];
    }

    return textColors[condition];
  };
  var getOutfallsConditionColor = function (condition, hex = false) {
    const hexColors = {
      null: grey,
      poor: red,
      optimal: green,
    };
    const textColors = {
      null: "grey",
      poor: "red",
      optimal: "green",
    };

    if (hex) {
      return hexColors[condition];
    }

    return textColors[condition];
  };

  var getConditionFromScore = function (score) {
    if (score === null || score === undefined) {
      return null;
    } else if (score <= 2.04) {
      return "poor";
    } else if (score > 2.04 && score <= 2.94) {
      return "fair";
    } else if (score > 2.94 && score <= 3.94) {
      return "acceptable";
    } else if (score > 3.94) {
      return "optimal";
    } else {
      throw new Error(`Unknown score ${score}.`);
    }
  };

  var getColorHex = function (condition) {
    switch (true) {
      case condition == null:
        return "#5c99bf";
      case condition <= 2.04:
        return "#d95945";
      case condition >= 2.05 && condition <= 2.94:
        return "#e0bc67";
      case condition > 2.94 && condition <= 3.94:
        return "#78b362";
      case condition >= 3.95:
        return "#389675";
      default:
        return grey;
    }
  };

  var getStreamColor = function (condition) {
    return condition == "303d" ? "#D84C00" : condition == "receiving" ? "#0A8CCD" : "#0000FF00";
    //        return condition == '303d' ? '#e8462f' : '#2e47a7';
  };

  var getParcelConditionColor = function (condition) {
    return condition == "Yes"
      ? "#4c7439"
      : condition == "No"
        ? "#a73928"
        : condition == "Partial"
          ? "#dcbc04"
          : "#cbcbcb";
  };

  var styleLanduse = function (feature) {
    var fillColor = getParcelColor(feature.properties.combo);
    var theStyle = {
      color: "#444444",
      fillOpacity: 0.5,
      fillColor: fillColor,
      weight: 1,
    };
    return theStyle;
  };

  var getParcelColor = function (landuse) {
    return landuse == "CUL"
      ? "#e8f0bc"
      : landuse == "OTH"
        ? "#83a841"
        : landuse == "IND"
          ? "#c0996b"
          : landuse == "COM"
            ? "#ebdb84"
            : landuse == "SFR"
              ? "#b1d1ea"
              : landuse == "MFR"
                ? "#347ab8"
                : landuse == "HTR"
                  ? "#0b0b0c"
                  : landuse == "MTR"
                    ? "#686867"
                    : landuse == "LTR"
                      ? "#b2b2b2"
                      : "#dacfb4";
  };

  var getTrashDataColor = function (condition) {
    return condition == "Low"
      ? light_green
      : condition == "Moderate"
        ? yellow
        : condition == "High"
          ? red
          : purple;
  };

  var colorByLoadColumns = function (features, layerName) {
    var valuePropertyName = "reduction";

    if (layerName.includes("Progress")) {
      valuePropertyName = "reduction";
    } else if (layerName.includes("Opportunity")) {
      const scenario = TelrLayerFunctions.getMitigationType(layerName);
      valuePropertyName = ["Mitigated", "Matrix Mitigated"].includes(scenario)
        ? "normalized"
        : "baselineNorm";
    } else if (layerName === "connectivityInputs") {
      valuePropertyName = "connect";
    }

    for (var i = 0; i < features.length; i++) {
      if (layerName === "runoffRatioProgress") {
        features[i].properties.value = CleanData.roundToZero(
          features[i].properties[valuePropertyName] * 100,
        );
      } else if (layerName === "runoffRatioOpportunity") {
        features[i].properties.value = features[i].properties[valuePropertyName] * 100;
      } else {
        features[i].properties.value = features[i].properties[valuePropertyName];
      }
    }
    return features;
  };

  var styleSwtelrCatchments = function (feature) {
    const dataView = Tree.get("dataView");
    const mapId = Tree.get("mapId");

    var theStyle = {
      color: strokeColor,
      fillOpacity: 0.7,
      fillColor: colorMapBasedOnView(dataView, mapId, feature.properties),
      weight: 1,
    };
    return theStyle;
  };

  var colorMapBasedOnView = function (view, mapId, data) {
    const layerType = DataViewFunctions.getCurrentLayerName();
    if (view === "particulatesView" && mapId === "report") {
      return colorMapBasedOnPerReduction(!isNaN(data) ? data : data.perReduction);
    }
    if (view === "particulatesView" && mapId === "plan") {
      return colorMapFeature(!isNaN(data) ? data : data.value);
    }
    if (view === "runoffView" && mapId === "report") {
      return colorMapBasedOnPerReduction(!isNaN(data) ? data : data.perReduction);
    }
    if (view === "runoffView" && mapId === "plan") {
      return colorMapFeature(!isNaN(data) ? data : data.value);
    }
    if (view === "runoffRatioView") {
      return colorMapFeature(!isNaN(data) ? data : data.value);
    }
    if (layerType === "connectivityInputs") {
      return colorMapFeature(!isNaN(data) ? data : data.value);
    }
  };

  var colorMapBasedOnPerReduction = function (value, layerType = null) {
    if (!layerType) {
      layerType = DataViewFunctions.getCurrentLayerName();
    }
    const colorArray = MapColors[layerType];

    //to deal with data where perReduction is a negative number
    value = Math.abs(value);

    switch (true) {
      case value === 0:
        return colorArray[0];
      case value > 0 && value < 0.2:
        return colorArray[1];
      case value >= 0.2 && value < 0.4:
        return colorArray[2];
      case value >= 0.4 && value < 0.6:
        return colorArray[3];
      case value >= 0.6 && value < 0.8:
        return colorArray[4];
      case value >= 0.8 && value <= 1.0:
        return colorArray[5];

      default:
        return colorArray[0];
    }
  };

  var colorMapFeature = function (value, columnArray, layerType = null) {
    const DataViewDropdown = require("./dataViewDropdown");
    const DataViewFunctions = require("../dataViewFunctions");

    if (!layerType) {
      layerType = DataViewFunctions.getCurrentLayerName();
    }

    var colorArray = MapColors[layerType];
    if (!colorArray) {
      console.warn("MapColors not defined for layer: " + layerType);
    }

    if (!columnArray) {
      columnArray = DataViewDropdown.getCurrentColumnArray();
    }

    if (value === null || columnArray.length === 0) {
      return colorArray[0];
    }

    var i = 0;
    for (; i < columnArray.length; i++) {
      if (value == 0) {
        return colorArray[0];
      } else if (value < columnArray[i]) {
        return colorArray[i];
      }
    }

    return colorArray[i];
  };

  var styleBoundaryGray = function () {
    var theStyle = {
      color: "#777777",
      fillOpacity: 0,
      fillColor: white,
      weight: 3,
    };
    return theStyle;
  };

  var styleBoundaryWhite = function () {
    var theStyle = {
      color: "#ffffff",
      fillOpacity: 0,
      fillColor: white,
      weight: 5,
    };
    return theStyle;
  };

  var styleRegionalRegulator = {
    stroke: true,
    fill: true,
    color: blue,
    fillOpacity: 0.6,
    fillColor: blue,
    weight: 1,
  };

  var styleRegional = {
    color: "#777777",
    fillOpacity: 0,
    fillColor: white,
    weight: 4,
  };

  var styleBaseStormdrainThin = function (feature) {
    var theStyle = {
      color: "#000000",
      weight: 2,
      opacity: 0,
    };
    return theStyle;
  };

  var getPrevCondBars = function (conditionArr) {
    var iconArray = [];
    for (var i = 1; i < conditionArr.length; i++) {
      if (conditionArr[i].condition !== null) {
        iconArray.push([
          getColorHex(conditionArr[i].condition),
          getBarHeight(conditionArr[i].condition),
        ]);
      }
    }
    return iconArray.reverse();
  };

  var styleMaintenanceZones = {
    stroke: true,
    fill: true,
    color: blue,
    fillOpacity: 0.2,
    fillColor: blue,
    weight: 2,
  };

  var getPriorityColor = function (priority) {
    if (priority == "Yes") {
      return white;
    } else {
      return grey;
    }
  };

  var getPriorityGradient = function (priority) {
    if (priority == "Yes") {
      return "linear-gradient(to bottom, #ffffff, #e6e6e6)";
    } else {
      return "linear-gradient(to bottom, #808080, #5d5d5d)";
    }
  };

  var fullDrainagePolygon = function (feature, isEnabled = false) {
    var drainageColor = convertBmpColorToHex(getConditionColor(feature.properties.score));

    var styleProps = {
      color: drainageColor,
      fillOpacity: 0.4,
      fillColor: drainageColor,
      weight: 0,
    };

    if (!isEnabled) {
      styleProps.fillOpacity = 0;
    }

    if (feature.properties["highlight"]) {
      styleProps.color = highlightYellowOutline;
      styleProps.weight = 3;
    }

    return styleProps;
  };

  var sbmpProgressDrainagePolygon = function (feature) {
    var color = null;
    if (feature.properties.planned) {
      color = "white";
    } else {
      color = getConditionColor(feature.properties.bmpScore);
    }
    var colorHex = convertBmpColorToHex(color);

    var styleProps = {
      color: colorHex,
      fillOpacity: 0.4,
      fillColor: colorHex,
      weight: 0,
    };

    return styleProps;
  };

  var fcsProgressDrainagePolygon = function (feature) {
    var color = null;
    if (feature.properties.planned) {
      color = "white";
    } else {
      color = getConditionColor(feature.properties.fcsScore);
    }
    var colorHex = convertBmpColorToHex(color);

    var styleProps = {
      color: colorHex,
      fillOpacity: 0.4,
      fillColor: colorHex,
      weight: 0,
    };

    return styleProps;
  };

  var muniCatchBasinProgressDrainagePolygon = function (feature) {
    var color = getConditionColor(feature.properties.score);
    var colorHex = convertBmpColorToHex(color);

    var styleProps = {
      color: colorHex,
      fillOpacity: 0.4,
      fillColor: colorHex,
      weight: 0,
    };

    return styleProps;
  };

  var convertColorToGradient = function (color) {
    switch (color) {
      case "purple":
        return "linear-gradient(to bottom, #9e62b6, #7c4f8e)";
      case "red":
        return "linear-gradient(to bottom, #d95945, #b34939)";
      case "orange":
        return "linear-gradient(to bottom, #f98628, #b76335)";
      case "yellow":
        return "linear-gradient(to bottom, #e0bc67, #b39652)";
      case "light_green":
        return "linear-gradient(to bottom, #78b362, #6a9959)";
      case "green_light":
        return "linear-gradient(to bottom, #78b362, #6a9959)";
      case "green_dark":
        return "linear-gradient(to bottom, #389675, #2a7359)";
      case "dark_green":
        return "linear-gradient(to bottom, #389675, #2a7359)";
      case "green":
        return "linear-gradient(to bottom, #389675, #2a7359)";
      case "grey":
        return "linear-gradient(to bottom, #808080, #5d5d5d)";
      case "white":
        return "linear-gradient(to bottom, #ffffff, #e6e6e6)";
      default:
        return grey;
    }
  };

  return {
    styleThreeOThree,
    styleBaseStormdrain,
    styleMiddleStormdrain,
    styleStormdrain,
    styleTrashPoints,
    styleCollectorData,
    styleParcelCondition,
    highlights,
    zoomHighlights,
    getOpacityFromZoom,
    selectedHighlight,
    grayResults,
    styleCatchments,
    getMapIconClasses,
    getBmpIcon,
    isCentralizedBMP,
    getBarHeight,
    getConditionColor,
    getConditionFromScore,
    getConditionArrayColor,
    getColorHex,
    getStreamColor,
    getParcelConditionColor,
    styleLanduse,
    colorMapBasedOnView,
    colorByLoadColumns,
    colorMapBasedOnPerReduction,
    styleRegional,
    styleRegionalRegulator,
    styleBaseStormdrainThin,
    getPrevCondBars,
    styleCollectorOutline,
    getTrashPointIcon,
    getTrashDataColor,
    styleMaintenanceZones,
    fullDrainagePolygon,
    styleSwtelrCatchments,
    getPriorityColor,
    getPriorityGradient,
    convertColorToGradient,
    colorMapFeature,
    sbmpProgressDrainagePolygon,
    fcsProgressDrainagePolygon,
    muniCatchBasinProgressDrainagePolygon,
    getHexColors,
    getOutfallsConditionColor,
    styleBoundaryGray,
    styleBoundaryWhite,
  };
};

module.exports = MapStyles();

const Session = require("../../login/session");
const Tree = require("../../tree");
const MapColors = require("./mapColors");
const TelrLayerFunctions = require("../telr/telrLayerFunctions");
const CleanData = require("./cleanData");
const DataViewFunctions = require("../dataViewFunctions");
