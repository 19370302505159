"use strict";

const MuniOpenChannelGeoServerLayer = function () {
  const layerName = "muniOpenChannel";
  const layerKey = "muniOpenChannelLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const muniOpenChannelGeoServerLayer = new GeoServerMapLayer(layerName, layerKey);
    GeoServerLayerFunctions.setLayerInstanceByMapId(
      layerName,
      mapId,
      muniOpenChannelGeoServerLayer,
    );

    muniOpenChannelGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniOpenChannelGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await ApiCalls.getMuniOpenChannels();
    return _organizeLayerData(data);
  };

  var _organizeLayerData = function (layerData) {
    return layerData.map((data) => getOrganizedProperties(data));
  };

  var getOrganizedProperties = function (data) {
    return {
      id: data.id,
      groupId: data.group_id,
      name: data.name,
      cof: data.cof_final_text,
      material: data.material,
      openChannelType: data.type,
      lineServiceType: data.service_type,
      icon: RoutineMaintenanceIcon.getMaintenanceIcon(
        "muni-open-channel",
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
      implementerType: data.implementer_type,
      responsiblePartyMaintenance: data.responsible_party_maintenance,
      landOwnership: data.property,
      flowType: data.flow_type,
      phase: data.phase,
      accessible: data.accessible,
      shape: data.shape,
      lined: data.lined,
      installDate: data.install_date,
      lastInspectionDue: data.last_maintenance_inspection_due,
      correctiveAction: data.corrective_actions ? JSON.parse(data.corrective_actions) : null,
      enforcementLevel: data.severity ? JSON.parse(data.severity) : null,
      condition: RoutineMaintenanceIcon.getLayerCondition(
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
      length: data.length,
      lengthUnits: data.length_units,
      width: data.width,
      widthUnits: data.width_units,
      catchment_names: data.catchment_names ? JSON.parse(data.catchment_names) : null,
      drains_to_rws: data.drains_to_rws ? JSON.parse(data.drains_to_rws) : null,
      drains_to_cs: data.drains_to_cs ? JSON.parse(data.drains_to_cs) : null,
      zone_names: data.zone_names ? JSON.parse(data.zone_names) : null,
      routine: data.routine_inspection_dates ? JSON.parse(data.routine_inspection_dates) : null,
      repair: data.repair_dates ? JSON.parse(data.repair_dates) : null,
    };
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
    _organizeLayerData,
  };
};

module.exports = MuniOpenChannelGeoServerLayer();

const GeoServerLayerFunctions = require("../../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../../mapFunctions/geoServerMapLayer");
const RoutineMaintenanceIcon = require("../../general/routineMaintenanceIcon");
const ApiCalls = require("../../apiCalls");
