"use strict";

const MuniCatchBasinReportPopup = function () {
  var getPopupHtml = function (properties) {
    properties.permissions = UserPermissions.getAllPermissions();
    properties.popupColor = MuniCatchBasinIcon.getIconColor(
      properties.score,
      properties.routineMaintenanceDue,
    );
    return PopupContent.getLayerPopup(properties, "muniCatchBasinReport");
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteCatchBasin(data.id, data.name, invalidateLayerDataFunction);
    };
    $(".map-content").off("click", ".popup-history-button");
    $(".map-content").on("click", ".popup-history-button", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deleteCatchBasin = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete CatchBasin ${name}? Click "Delete" to permanently delete the CatchBasin. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.ApiCalls.deleteBmp(id);
        invalidateLayerDataFunction();
        _closePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
  };

  var _closePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };
  var onPopupOpen = async function (properties, event = null) {
    const id = properties.idbmp;
    var data = await getPopupData(id);
    const html = getPopupHtml(data);
    var layerName = "muniCatchBasinReport";
    const layerModule = LayerFunctions.getLayerModule(layerName);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-muni-catch-basin-history").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-history-button").show();
    $parent.find(".popup-muni-catch-basin-main").show();
  };

  var popupHistoryButtonClick = function () {
    var idbmp = $(this).parents(".popup-general").data("id");
    showInspectionHistory(idbmp);
  };

  var showInspectionHistory = function () {
    const inspections = Tree.get("currentBmpFcs", "inspections");

    const props = getInspectionHistoryProps(inspections);
    $(".popup-muni-catch-basin-history").html(
      nunjucks.render("report/popups/muniCatchBasinHistory.njk", props),
    );
    $(".popup-muni-catch-basin-main").hide();
    $(".popup-history-button").hide();
    $(".popup-muni-catch-basin-history").show();
    $(".popup-back-button").show();
  };

  var getInspectionHistoryProps = function (inspections) {
    const props = {};
    props.assessments = inspections;
    return props;
  };

  var getPopupData = async function (id) {
    const popupData = await ReportApiCalls.getMuniCatchBasinPopup(id);
    Tree.set("currentBmpFcs", popupData);

    return popupData;
  };

  return {
    getPopupHtml,
    onPopupOpen,
  };
};

module.exports = MuniCatchBasinReportPopup();

const ApiCalls = require("../apiCalls");
const LayerFunctions = require("../layerFunctions");
const PopupContent = require("../mapFunctions/popupContent");
const MessageModal = require("../modals/messageModal");
const UserPermissions = require("../login/userPermissions");
const ReportApiCalls = require("../report/reportApiCalls");
const MuniCatchBasinIcon = require("../report/muni/muniCatchBasinIcon");
const Tree = require("../tree");
