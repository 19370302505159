"use strict";

const BmpFcsInventoryAdditionalFcsInformation = function () {
  const stringKey = "additional-fcs-information";
  const headerInformation = "FCS Info";
  let $page;

  var loadAdditionalFcsInformationListeners = function () {
    $page.on("change", "[name=multi-system]", toggleMultiSystemFields);
    $page.on("change", "[name=treats-storm]", toggleTreatsStormFields);
    Form.initializeAndLoadListeners($page, "bmp-fcs-inventory", { isMultiPart: true });
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    const options = BmpFcsInventoryModalController.getOptions();
    await renderPageHtml(options);
    BmpFcsInventoryModalController.addHighlightsToFields($page);
    BmpFcsInventoryModalController.initializeDropzones(options.readOnly);
  };

  var renderPageHtml = async function () {
    const renderData = getAdditionalFcsProps();

    const html = nunjucks.render(
      "modals/bmpFcsInventory/bmpFcsInventoryAdditionalFcsInformation.njk",
      renderData,
    );
    BmpFcsInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
    loadAdditionalFcsInformationListeners();
  };

  var getAdditionalFcsProps = function () {
    const inventoryData = InventoryModal.getAllData("bmp-fcs-inventory");
    return {
      ...inventoryData,
      catchBasinType: BmpFcsFunctions.isPriorityCatchBasinType(inventoryData.bmpType),
      ...getFcsOptionProps(),
    };
  };

  var getFcsOptionProps = function () {
    return {
      multiSystemOptions: BmpFcsInventoryConstants.multiSystemOptions,
      volumeBasedOptions: BmpFcsInventoryConstants.volumeBasedOptions,
    };
  };

  var toggleTreatsStormFields = function () {
    const treatsStorm = BmpFcsInventoryModalController.getLatestBmpInventoryData("treatsStorm");
    if (treatsStorm !== null) {
      $page.find("[data-field=storm-inches]").toggleClass("hidden", !treatsStorm);
      $page.find("[data-field=partial-storm-inches]").toggleClass("hidden", treatsStorm);
    }
  };

  var toggleMultiSystemFields = function () {
    const multiSystem = BmpFcsInventoryModalController.getLatestBmpInventoryData("multiSystem");
    if (multiSystem !== null) {
      $page.find(".fcs-grouping").toggleClass("hidden", false);
      $page.find(".fcs-mbs-section").toggleClass("hidden", !multiSystem);
      $page.find(".fcs-tdd-section").toggleClass("hidden", multiSystem);
    }
  };

  var cleanUp = function () {
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    getFcsOptionProps,
  };
};

module.exports = BmpFcsInventoryAdditionalFcsInformation();

const BmpFcsFunctions = require("./bmpFcsFunctions");
const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
