"use strict";

const JQueryExtensions = function () {
  var run = function () {
    // $.support.transition isn't instantiated until later, so need to cache it another time.
    var realTransitions;

    $.fn.extend({
      assertLength: function (expectedLength, customErrorMessage) {
        if (this.length !== expectedLength) {
          console.error(
            customErrorMessage ||
              `Expected jQuery object of length ${expectedLength}, but is ${this.length}.`,
          );
        }
        return this;
      },
      getOnlyOrThrow: function () {
        const length = this.length;
        if (length == 0) {
          // console.warn(`Expected one result, got none.`);
        } else if (length > 1) {
          console.error(`Expected one result, got ${length}.`);
        }
        return this.first();
      },
      // https://stackoverflow.com/questions/5474871/html-how-can-i-show-tooltip-only-when-ellipsis-is-activated
      // Call this function on a display: block element and the full text
      // contents will be rendered in a title tooltip on hover.
      tooltipOnOverflow: function () {
        $(this).on("mouseenter", function () {
          if (this.offsetWidth < this.scrollWidth) {
            $(this).attr("title", $(this).text().trim());
          } else {
            $(this).removeAttr("title");
          }
        });
      },
      toggleTransitions: function (transitionsEnabled) {
        // $.support.transition disables Bootstrap transitions

        if (realTransitions === undefined) {
          realTransitions = $.support.transition;
        }

        if (transitionsEnabled) {
          $.support.transition = realTransitions;
        } else {
          $.support.transition = false;
        }

        if (transitionsEnabled) {
          // Need to wait to enable transitions, otherwise iOS will still play them
          window.setTimeout(() => this.toggleClass("disable-transitions", !transitionsEnabled), 0);
        } else {
          this.toggleClass("disable-transitions", !transitionsEnabled);
        }

        return this;
      },
    });
  };

  return {
    run,
  };
};

module.exports = JQueryExtensions();
