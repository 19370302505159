"use strict";

const IndcomFacilityInsightDashboardController = function () {
  var $insightContainer;
  var inited = false;
  var controller = null;
  var reports = [];
  var reportingYears;

  var focusYear;
  var comparisonYear;
  var facilityType = "total";
  var responsiblePartyManagement = "total";
  var inspectionCategory = "total";
  var inspectionType = "total";

  var facilityTypeOptions;
  var responsiblePartyManagementOptions;
  var inspectionCategoryOptions;
  var inspectionTypeOptions;

  var init = async function () {
    if (!inited) {
      await loadReportingYears();

      $insightContainer = $(".insight-container");

      facilityTypeOptions = FacilityTypeFilter.getFilterFacilityTypeOptions() || [];
      facilityTypeOptions.unshift({ name: "All Types", value: "total" });

      responsiblePartyManagementOptions = IndcomInventoryConstants.responsiblePartyOptions?.slice();
      responsiblePartyManagementOptions.unshift({ name: "All", value: "total" });

      inspectionCategoryOptions = ToolSettings.getDomainOptions("inspection_category")?.slice();
      inspectionCategoryOptions.unshift({ name: "All", value: "total" });

      inspectionTypeOptions = FormSettings.getDropdownOptions("inspection-type")?.slice();
      inspectionTypeOptions.unshift({ name: "All", value: "total" });

      loadDomListeners();

      var widgetInspectionCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Inspections",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["inspectionCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total # of Inspections`;
        },
        getHoverDescription: () => {
          return "The percent difference in total inspections between the comparison and focus reporting years";
        },
      });

      var widgetInspectionTime = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total inspection time",
        neutralTrendColor: true,
        getDataUnit: (insightData, dataPath) => {
          const hours = insightData[dataPath]["inspectionTime"];
          return InsightWidgetFunctions.convertTimeUnit(hours);
        },
        getDataNumber: (insightData, dataPath) => {
          const hours = insightData[dataPath]["inspectionTime"];
          return hours;
        },
        convertDataNumberFunction: InsightWidgetFunctions.convertTime,
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total inspection time`;
        },
        getHoverDescription: () => {
          return "The percent difference in total inspection time between the comparison and focus reporting years";
        },
      });

      var widgetAvgFindingOpenTime = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Average Time Findings Open",
        getDataUnit: (insightData, dataPath) => {
          const hours = insightData[dataPath]["avgFindingOpenTime"];
          return InsightWidgetFunctions.convertTimeUnit(hours);
        },
        getDataNumber: (insightData, dataPath) => {
          const hours = insightData[dataPath]["avgFindingOpenTime"];
          return hours;
        },
        convertDataNumberFunction: InsightWidgetFunctions.convertTime,
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Average Time Findings Open`;
        },
        getHoverDescription: () => {
          return "The percent difference in average time findings are open between the comparison and focus reporting years";
        },
      });

      var widgetEnforcementCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Enforcements",
        dataDecimalPlaces: 0,
        isGreyedOut: () => inspectionCategory !== "total" || inspectionType !== "total",
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["enforcementCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total # of Enforcements`;
        },
        getHoverDescription: () => {
          return "The percent difference in total # of enforcements between the comparison and focus reporting years";
        },
      });

      var widgetMonthlyInspectionCountChart = new InsightWidgetMonthlyCountChart({
        size: 4,
        sizeSm: 2,
        title: "Cumulative Total # of Inspections",
        neutralTrendColor: true,
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["monthlyInspectionCount"];
        },
        getYearByDataPath,
        getHoverTitle: (hoverIndex) => {
          const monthIndex = hoverIndex + 1;

          return `${focusYear} & ${comparisonYear} Month ${monthIndex} <br/> Total # of Inspections`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of inspections between the comparison and focus reporting years";
        },
        getNotes: () => {
          const focusReport = reports.find(
            (report) => report.reportingYear === parseInt(focusYear),
          );

          return `*${focusReport.displayDataRangeFirst} is the start of the focus reporting year. ${focusReport.displayDataRangeLast} is the end of the focus reporting year.`;
        },
      });

      var widgetTopLocationCount = new InsightWidgetTopList({
        size: 2,
        title: "Top 5 Locations with Findings",
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["topLocationCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: (insightData, hoverType) => {
          const displayName = hoverType;

          return `${displayName} <br/>Location Ranking`;
        },
      });

      var widgetFindingCountByFacilityTypeChart = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "Total # of Findings by Facility Type",
        excludeZeros: true,
        orderByCount: true,
        neutralTrendColor: true,
        twoLineLabel: true,
        typeCountLimit: 4,
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["findingCountByFacilityType"];
        },
        getYearByDataPath,
        getSelectedType,
        getHoverTitle: (insightData, hoverType) => {
          const matchedItem = facilityTypeOptions.find((item) => item.value === hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `${focusYear} & ${comparisonYear} <br/> ${displayName}`;
        },
        getHoverDescription: () => {
          return "The percent difference in findings between the comparison and focus reporting years";
        },
      });

      var config = {
        getInsightDataFunction: ApiCalls.getIndcomFacilityInsight,
        widgets: [
          widgetMonthlyInspectionCountChart,
          widgetTopLocationCount,
          widgetFindingCountByFacilityTypeChart,
          widgetInspectionCount,
          widgetInspectionTime,
          widgetAvgFindingOpenTime,
          widgetEnforcementCount,
        ],
        hasFilter: true,
        getFilterMenuHtmlFunction,
        saveFilterFunction,
        getMobileFilterButtonTextFunction,
      };

      controller = new InsightDashboardController(config);
      controller.init();
      inited = true;
      render();
    }
  };

  var loadDomListeners = function () {
    $insightContainer.off("change", ".year-dropdown", onYearChange);
    $insightContainer.on("change", ".year-dropdown", onYearChange);

    $insightContainer.off("change", `[name="facility-type"]`, onFacilityTypeChange);
    $insightContainer.on("change", `[name="facility-type"]`, onFacilityTypeChange);

    $insightContainer.off(
      "change",
      `[name="responsible-party-management"]`,
      onResponsiblePartyManagementChange,
    );
    $insightContainer.on(
      "change",
      `[name="responsible-party-management"]`,
      onResponsiblePartyManagementChange,
    );

    $insightContainer.off("change", `[name="inspection-category"]`, onInspectionCategoryChange);
    $insightContainer.on("change", `[name="inspection-category"]`, onInspectionCategoryChange);

    $insightContainer.off("change", `[name="inspection-type"]`, onInspectionTypeChange);
    $insightContainer.on("change", `[name="inspection-type"]`, onInspectionTypeChange);

    $insightContainer.off("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
    $insightContainer.on("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
  };

  var onYearChange = function () {
    if (this.name === "focus-year") {
      focusYear = this.value;
    } else if (this.name === "comparison-year") {
      comparisonYear = this.value;
    }
    render();
  };

  var onFacilityTypeChange = function () {
    facilityType = this.value;
    render();
  };

  var onResponsiblePartyManagementChange = function () {
    responsiblePartyManagement = this.value;
    render();
  };

  var onInspectionCategoryChange = function () {
    inspectionCategory = this.value;
    render();
  };

  var onInspectionTypeChange = function () {
    inspectionType = this.value;
    render();
  };

  var onBarGroupClick = function () {
    var $barGroup = $(this);
    var $facilityTypeSelect = $insightContainer.find(`select[name="facility-type"]`);

    if ($barGroup.hasClass("selected")) {
      $facilityTypeSelect.val("total").change();
    } else {
      const facilityType = $barGroup.data("type");
      if (facilityType) {
        $facilityTypeSelect.val(facilityType).change();
      }
    }
  };

  var render = function () {
    controller.render({
      focusYear,
      comparisonYear,
      facilityType,
      responsiblePartyManagement,
      inspectionCategory,
      inspectionType,
    });
  };

  var loadReportingYears = async function () {
    reports = await ApiCalls.getAnnualReports("indcom");
    reportingYears = reports
      .map((report) => report.reportingYear)
      .sort()
      .reverse();
    focusYear = reportingYears[0];
    comparisonYear = reportingYears[1] || reportingYears[0];
  };

  var getFilterMenuHtmlFunction = function () {
    var yearOptions = reportingYears.map((year) => {
      return { name: year, value: year };
    });

    return nunjucks.render("insight/indcomFacilityInsightDashboardFilters.njk", {
      facilityTypeOptions,
      facilityType,
      yearOptions,
      focusYear,
      comparisonYear,
      responsiblePartyManagement,
      responsiblePartyManagementOptions,
      inspectionCategory,
      inspectionCategoryOptions,
      inspectionType,
      inspectionTypeOptions,
    });
  };

  var getMobileFilterButtonTextFunction = function () {
    const displayName = facilityTypeOptions.getObjectWith("value", facilityType)?.name;
    return `${focusYear} & ${comparisonYear}, ${displayName}`;
  };

  var saveFilterFunction = function (formData) {
    if (formData["focusYear"]) {
      focusYear = parseInt(formData["focusYear"]);
      $insightContainer.find(`select[name="focus-year"]`).val(focusYear);
    }
    if (formData["comparisonYear"]) {
      comparisonYear = parseInt(formData["comparisonYear"]);
      $insightContainer.find(`select[name="comparison-year"]`).val(comparisonYear);
    }
    if (formData["facilityType"]) {
      facilityType = formData["facilityType"];
      $insightContainer.find(`select[name="facility-type"]`).val(facilityType);
    }
    render();
  };

  var getYearByDataPath = function (dataPath) {
    return dataPath === "focus" ? focusYear : comparisonYear;
  };

  var getSelectedType = function () {
    return facilityType;
  };

  return {
    init,
  };
};

module.exports = IndcomFacilityInsightDashboardController();

const ApiCalls = require("../apiCalls");
const InsightDashboardController = require("../general/widgets/insightDashboardController");
const InsightWidgetFunctions = require("../general/widgets/insightWidgetFunctions");
const InsightWidgetSimpleNumber = require("../general/widgets/insightWidgetSimpleNumber");
const InsightWidgetTopList = require("../general/widgets/insightWidgetTopList");
const InsightWidgetMonthlyCountChart = require("../general/widgets/insightWidgetMonthlyCountChart");
const InsightWidgetCountByTypeChart = require("../general/widgets/insightWidgetCountByTypeChart");
const IndcomInventoryConstants = require("../indcom/indcomInventoryConstants");
const FacilityTypeFilter = require("../filters/facilityTypeFilter");
const FormSettings = require("../settings/formSettings");
const ToolSettings = require("../settings/toolSettings");
