"use strict";

const FormValidator = function () {
  const getRequireNotBlankFields = function () {
    return $("input[data-required-not-blank]")
      .map(function () {
        if (!$(this).val()) {
          return $(this).attr("name");
        }
      })
      .get();
  };

  /*
    requiredFields: [ { fieldName: "inputName" }, ... ]
  */
  const getEmptyRequiredFieldsInData = function (data, requiredFields) {
    const emptyRequiredFields = [];

    for (const fieldObj of requiredFields) {
      const path = fieldObj.path ?? Form.getPathPartsFromName(fieldObj.fieldName);
      const fieldData = Form.getDataAtPath(data, path);
      if (isEmpty(fieldData)) {
        emptyRequiredFields.push(fieldObj);
      }
    }

    return emptyRequiredFields;
  };

  var isEmpty = function (data) {
    return data === null || data === undefined || data === "";
  };

  return {
    getRequireNotBlankFields,
    getEmptyRequiredFieldsInData,
  };
};

module.exports = FormValidator();

const Form = require("./form");
