"use strict";

const CustomLayerConfig = function () {
  var getCustomLayerByType = function (layerSettings) {
    if (layerSettings.geometryType === "polygon") {
      return Layers.customPolygonLayer(layerSettings);
    }
    if (layerSettings.geometryType === "line") {
      return Layers.customLineLayer(layerSettings);
    }
  };

  return { getCustomLayerByType };
};

module.exports = CustomLayerConfig();

const Layers = require("./layers");
