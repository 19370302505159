"use strict";

const Breadcrumbs = function () {
  let currentSettings;

  const setBreadcrumbs = function (options) {
    _setTitle(options);
    currentSettings = options;
  };

  const _setTitle = function (options) {
    let pageHtml = options?.currentPage ? `<div>${options.currentPage}</div>` : "";
    if (options?.previousPage) {
      pageHtml =
        `<a href="${options?.previousPage.route}" data-internal-link>Back to ${options?.previousPage.page}</a>` +
        pageHtml;
    }
    $("#page-title").html(pageHtml);
    $("title").text(options?.currentPage ? `${options.currentPage} | rainsteward` : "rainsteward");
  };

  const getPreviousRoute = function () {
    return currentSettings?.previousPage?.route ?? null;
  };

  return { setBreadcrumbs, _setTitle, getPreviousRoute };
};

module.exports = Breadcrumbs();
