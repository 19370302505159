"use strict";

function MuniOutfallToDoList() {
  function organizeMuniOutfallRoutineInspection(allToDos) {
    for (const toDo of allToDos) {
      ToDoFunctions.addCommonDueDateFields(toDo, toDo.muniOutfallRoutineInspection.dueDate);
      toDo.permissions = {
        ...toDo.permissions,
        canCreateRoutineInspection: toDo.permissions.canCreateOutfallInspection,
      };
    }

    return allToDos;
  }

  function organizeMuniOutfallRoutineMaintenance(allToDos) {
    const result = [...allToDos];

    for (const toDo of result) {
      toDo.muniOutfallRoutineMaintenance = structuredClone(toDo.muniOutfallRoutineMaintenance);
      ToDoFunctions.flattenAdditionalInfoAndCloneExtrasOntoEndOfArray(
        result,
        toDo,
        "muniOutfallRoutineMaintenance",
      );

      ToDoFunctions.addCommonDueDateFields(toDo, toDo.muniOutfallRoutineMaintenance.dueDate);
      toDo.correctiveAction = toDo.muniOutfallRoutineMaintenance.additionalInfo?.key;
      toDo.displayLevel = getDisplayLevel(toDo.muniOutfallRoutineMaintenance.additionalInfo?.level);
    }

    return BmpFcsToDoController.addOutfallRepairConditions(result);
  }

  function getDisplayLevel(level) {
    if (!level) {
      return "Repair";
    }

    return RoutineMaintenanceFormTable.getLevelOptions().find((options) => options.value === level)
      .name;
  }

  function canAccessRoutineInspections() {
    return ToolSettings.getSetting("permissions", "can_create_outfall_inspection");
  }

  return {
    organizeMuniOutfallRoutineInspection,
    organizeMuniOutfallRoutineMaintenance,
    canAccessRoutineInspections,
  };
}

module.exports = MuniOutfallToDoList();

const BmpFcsToDoController = require("../../bmpfcs/bmpFcsToDoController");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const ToDoFunctions = require("../../mapFunctions/toDoFunctions");
const ToolSettings = require("../../settings/toolSettings");
