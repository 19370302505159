"use strict";

const EsriDynamicMapLayer = function (layerName, layerKey, esriKey, esriLayer) {
  var layer;
  var layerConfig = LayerFunctions.getLayerConfigByTreeName(layerName);

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      layerName,
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers[layerKey] && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers[layerKey]);
          } else {
            if (sameSpatialFilter && sameView) {
              // Temp solution to prevent loading twice from dataView and filters update
              return;
            }
            createLayerAndAddToMap(map, mapLayers);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers[layerKey])) {
            map.removeLayer(mapLayers[layerKey]);
          }
        }
      },
    );
  };

  var createLayerAndAddToMap = async function (map, mapLayers) {
    if (mapLayers[layerKey]) {
      map.removeLayer(mapLayers[layerKey]);
    }
    if (!Tree.get(["layers", layerName, "isFetching"])) {
      Tree.set(["layers", layerName, "isFetching"], true);
      mapLayers[layerKey] = await createLayer();
      Tree.set(["layers", layerName, "isFetching"], false);
      mapLayers[layerKey].addTo(map);
    }

    return mapLayers[layerKey];
  };

  var createLayer = async function () {
    const options = {
      pane: "underCatchmentPane",
      layerDefs: EsriLayerFunctions.getLayerDefs(esriKey, esriLayer, layerConfig?.emptyLayerDef),
      opacity: layerConfig?.opacity || 0.7,
    };
    layer = await EsriLayerFunctions.getEsriLayer(esriKey, esriLayer, options);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      catchments: ["filters", "catchments"],
      receivingWaters: ["filters", "receivingWaters"],
    }).on("update", function () {
      if (
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", mapId, layerName, "isEnabled")
      ) {
        mapLayers[layerKey].setLayerDefs(
          EsriLayerFunctions.getLayerDefs(esriKey, esriLayer, layerConfig?.emptyLayerDef),
        );
      }
    });
  };

  return {
    loadToggledListenerForMap,
    loadFiltersUpdatedListenerForMap,
  };
};

module.exports = EsriDynamicMapLayer;

const EsriLayerFunctions = require("../mapFunctions/esriLayerFunctions");
const LayerFunctions = require("../mapFunctions/layerFunctions");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
