"use strict";

const StormwaterOpportunityController = require("../../esg/dashboards/stormwaterOpportunityController");
const Esg = require("../../esg/esg");
const PropertyFactSheetController = require("../../esg/propertyFactSheetController");
const PlanWizardController = require("../../esg/planWizardController");
const PlanPropertiesController = require("../../esg/properties/planPropertiesController");
const MessageModal = require("../../modals/messageModal");
const CommonModalFunctions = require("../../modals/commonModalFunctions");
const RouteFunctions = require("../2nform/routeFunctions");
const ImpactController = require("../..//esg/dashboards/impactController");
const NavToggle = require("../../esg/navToggle");
const PropertiesController = require("../../esg/properties/propertiesController");
const PlansController = require("../../esg/plansController");
const StormwaterImpactAccountingController = require("../../esg/dashboards/stormwaterImpactAccountingController");
const IncentiveProgramsController = require("../../esg/incentiveProgramsController");

const EsgRoutes = function () {
  const stormwaterOpportunityRoute = {
    as: "portfolio",
    handler: () => {
      StormwaterOpportunityController.render();
    },
    mobileFriendly: true,
  };

  const stormwaterImpactAccountingRoute = {
    as: "impact-accounting",
    handler: () => {
      StormwaterImpactAccountingController.render();
    },
  };

  const homeRoute = {
    as: "home",
    handler: function ({ data }) {
      Esg.renderHome();
    },
    mobileFriendly: true,
  };

  const propertiesRoute = {
    as: "properties",
    handler: function ({ data, hashString }) {
      PropertiesController.render(hashString);
    },
    hooks: {
      already: function ({ data, hashString }) {
        if (hashString) {
          NavToggle.updateView(hashString?.length === 0 ? "list" : hashString);
        }
      },
    },
    mobileFriendly: true,
  };

  const propertyFactSheetRoute = {
    as: "property",
    handler: function ({ data }) {
      PropertyFactSheetController.render(data.propertySlug);
    },
    mobileFriendly: true,
  };

  const propertyFactSheetRouteForPlanSelected = {
    handler: function ({ data }) {
      PropertyFactSheetController.render(data.propertySlug, data.planSlug, "selected");
    },
  };

  const propertyFactSheetRouteForPlanSuitable = {
    handler: function ({ data }) {
      PropertyFactSheetController.render(data.propertySlug, data.planSlug, "suitable");
    },
  };

  const _planWizardHooks = {
    leave: function (done) {
      RouteFunctions.warnBeforeNavigating(
        done,
        (go, cancel) => {
          const cancelBtnText = "Go Back",
            okBtnText = "Close Without Saving";
          MessageModal.showConfirmWarningModal(
            null,
            go,
            cancelBtnText,
            okBtnText,
            CommonModalFunctions.getCloseWithoutSavingPrompt(),
            cancel,
          );
        },
        () => {},
        PlanWizardController.okToLeave(),
      );
    },
  };

  const planRoute = {
    handler: function ({ data }) {
      PlanWizardController.render(data?.planSlug, true);
    },
    hooks: _planWizardHooks,
  };

  const planInfoRoute = {
    handler: function ({ data }) {
      PlanWizardController.render(data?.planSlug, false);
    },
    hooks: _planWizardHooks,
  };

  const plansRoute = {
    as: "stormwater-plans",
    handler: function ({ data }) {
      PlansController.render();
    },
  };

  const impactRoute = {
    as: "impact",
    handler: ({ data }) => {
      ImpactController.render(data?.planSlug);
    },
  };

  const _planPropertiesHooks = {
    already: function ({ data, hashString }) {
      if (hashString) {
        NavToggle.updateView(hashString?.length === 0 ? "list" : hashString);
      }
    },
  };

  const selectedPropertiesRoute = {
    as: "selected",
    handler: function ({ data, hashString }) {
      PlanPropertiesController.render(data?.planSlug, "selected", hashString);
    },
    hooks: _planPropertiesHooks,
  };

  const suitablePropertiesRoute = {
    as: "suitable",
    handler: function ({ data, hashString }) {
      PlanPropertiesController.render(data?.planSlug, "suitable", hashString);
    },
    hooks: _planPropertiesHooks,
  };

  const incentiveProgramsRoute = {
    as: "incentive-programs",
    handler: ({ data }) => {
      IncentiveProgramsController.render();
    },
    mobileFriendly: true,
  };

  return {
    homeRoute,
    propertyFactSheetRoute,
    propertyFactSheetRouteForPlanSelected,
    propertyFactSheetRouteForPlanSuitable,
    planRoute,
    planInfoRoute,
    plansRoute,
    impactRoute,
    propertiesRoute,
    selectedPropertiesRoute,
    suitablePropertiesRoute,
    stormwaterOpportunityRoute,
    stormwaterImpactAccountingRoute,
    incentiveProgramsRoute,
  };
};

module.exports = EsgRoutes();
