"use strict";

const CatchBasinBulkCleanLayer = function () {
  const layerName = "catchBasinBulkClean";
  var markerReferences = {};

  var wfsMarkers;
  var superclusterIndex;
  var superclusterReady = false;
  var openedFeature = null;
  var bulkSelectLayer = null;

  var getLayerKey = function () {
    return LayerFunctions.getLayerKeyByLayerName(layerName);
  };

  var getBulkSelectLayer = function () {
    return bulkSelectLayer;
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(layerName, mapId, async function (isEnabled, sameSpatialFilter) {
      if (isEnabled) {
        if (mapLayers.catchBasinBulkCleanLayer) {
          map.addLayer(mapLayers.catchBasinBulkCleanLayer);
        } else {
          await loadWfsLayerData();
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.catchBasinBulkCleanLayer)) {
          map.removeLayer(mapLayers.catchBasinBulkCleanLayer);
        }
      }
    });
  };

  var createClusterIcon = function (feature, latlng) {
    if (!feature.properties.cluster) {
      return createMarkerFunction(feature, latlng);
    } else {
      const count = feature.properties.point_count;
      const size = count < 100 ? "small" : count < 1000 ? "medium" : "large";
      const icon = L.divIcon({
        html: "<div><span>" + feature.properties.point_count_abbreviated + "</span></div>",
        className: "marker-cluster marker-cluster-" + size,
        iconSize: L.point(40, 40),
      });
      return L.marker(latlng, {
        icon: icon,
      });
    }
  };

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: MuniCatchBasinIcon.getBulkCleanIconMarker(
        feature.properties.selected,
        feature.properties.isFcs,
        feature.properties.isCatchBasinHighPriority,
      ),
    });
  };

  var loadWfsLayerData = async function () {
    const resource = ResourceController.get("muniCatchBasin");
    var data = await resource.getAll({ filters: null, refreshData: false });

    setTreeLayerData(data);
  };

  var setTreeLayerData = function (layerData) {
    const dataPath = Actions.getLayerDataPathByMapId("modal");
    Tree.set(["layers", layerName, "isFetching"], false);
    Tree.set(["layers", layerName, dataPath], layerData);
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(layerName, mapId, function (data) {
      mapLayers.catchBasinBulkCleanLayer = createLayerAndAddToMap(
        map,
        mapLayers.catchBasinBulkCleanLayer,
        data.map(function (data) {
          return {
            type: "Feature",
            geometry: data.geometry,
            properties: data,
          };
        }),
      );

      // Filter only selected data for bulk select table
      var selectedData = data.filter((item) => item.selected);
      renderSelectedData(selectedData);
    });
  };

  var createLayerAndAddToMap = function (map, catchBasinBulkCleanLayer, data) {
    if (catchBasinBulkCleanLayer) {
      map.removeLayer(catchBasinBulkCleanLayer);
    }
    catchBasinBulkCleanLayer = createLayer(map, data);

    return catchBasinBulkCleanLayer.addTo(map);
  };

  var createLayer = function (map, data) {
    const clusterEnabled = data.length > Clustering.getMaxFeaturesToUncluster();

    if (MapFunctions.mapHasLayer(map, wfsMarkers)) {
      map.removeLayer(wfsMarkers);
    }

    bulkSelectLayer = L.geoJSON(data);
    wfsMarkers = L.geoJSON(clusterEnabled ? null : data, {
      pointToLayer: createClusterIcon,
      onEachFeature: function (feature, layer) {
        if (!feature.properties.cluster) {
          layer.bindPopup(getCatchBasinBulkCleanPopupHtml(feature));

          layer.on({
            click: function (event) {
              handleLayerClick(event, map);
            },
          });
        }
      },
    }).addTo(map);

    wfsMarkers.on("popupopen", onPopupOpen);
    wfsMarkers.on("popupclose", onPopupClose);

    map.off("moveend", triggerSuperclusterLayerUpdate);
    map.on("moveend", triggerSuperclusterLayerUpdate);

    wfsMarkers.off("click");
    wfsMarkers.on("click", function (e) {
      var clusterId = e.layer.feature.properties.cluster_id;
      var center = e.latlng;
      var expansionZoom;
      if (clusterId) {
        expansionZoom = superclusterIndex.getClusterExpansionZoom(clusterId);
        map.flyTo(center, expansionZoom);
      }
    });

    if (clusterEnabled) {
      createSuperclusterLayer(data, map);
    }

    return wfsMarkers;
  };

  var triggerSuperclusterLayerUpdate = function (event) {
    updateSuperclusterLayer(event.target);
  };

  var createSuperclusterLayer = function (data, map) {
    superclusterReady = false;
    superclusterIndex = new Supercluster({
      radius: 100,
      maxZoom: 16,
      minPoints: 5,
    }).load(data);
    superclusterReady = true;

    updateSuperclusterLayer(map);
  };

  var updateSuperclusterLayer = function (map) {
    if (!superclusterReady) return;
    var bounds = map.getBounds();
    var bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
    var zoom = map.getZoom();
    var clusters = superclusterIndex.getClusters(bbox, zoom);
    wfsMarkers.clearLayers();
    wfsMarkers.addData(clusters);

    if ($(".popup-bulk-select").length && openedFeature) {
      reopenPopup(openedFeature, map);
    }
  };

  var getCatchBasinBulkCleanPopupHtml = function (feature) {
    return nunjucks.render("popups/catchBasinBulkCleanPopup.njk", {
      ...feature.properties,
      popupColor: feature.properties.selected ? "blue" : "grey",
      showSelectButton: !feature.properties.selected,
      showDeselectButton: feature.properties.selected,
    });
  };

  var getCurrentData = function (mapId = "modal") {
    return LayerDataFunctions.getCurrentLayerData(layerName, mapId);
  };

  var getDataAndIndexByIdBmp = function (idBmp, mapId = "modal") {
    return LayerDataFunctions.getLayerDataAndIndexById(layerName, idBmp, mapId);
  };

  var updateDataByIdBmp = function (idBmp, newData, mapId = "modal") {
    LayerDataFunctions.updateLayerDataById(layerName, idBmp, newData, mapId);
  };

  var refreshLayer = function (mapId = "modal") {
    LayerDataFunctions.refreshLayer(layerName, mapId);
  };

  var show = function () {
    Actions.toggleLayer(layerName, true, "modal");
  };

  var hide = function () {
    Actions.toggleLayer(layerName, false, "modal");
  };

  var getMarkerReference = function (idBmp) {
    return markerReferences[idBmp];
  };

  var select = function (idBmp) {
    updateDataByIdBmp(idBmp, { selected: true });
  };

  var deselect = function (idBmp) {
    updateDataByIdBmp(idBmp, { selected: false });
  };

  var selectAll = function (selected) {
    LayerDataFunctions.updateAllData(layerName, { selected: selected }, "modal");
  };

  var multiSelect = function (selectedIds) {
    LayerDataFunctions.multiUpdateData(layerName, selectedIds, { selected: true }, "modal");
  };

  var renderSelectedData = function (data) {
    const BulkSelectTable = require("../bulkSelect/bulkSelectTable");

    BulkSelectTable.render(data);

    var selectedIds = data.map((datum) => {
      return datum.id;
    });
    Tree.set(["bulkSelect", "selectedIds"], selectedIds);
  };

  var onPopupClose = function () {
    const BulkSelectTable = require("../bulkSelect/bulkSelectTable");
    BulkSelectTable.resetSavedSelectedId();
    openedFeature = null;
  };

  var onPopupOpen = function (event) {
    openedFeature = event.layer.feature;
  };

  var handleLayerClick = function (event, map) {
    highlightTable(event);
  };

  var reopenPopup = function (feature, map) {
    const popupContent = getCatchBasinBulkCleanPopupHtml(feature);
    const latlng = L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
    L.popup({ autoPan: false }).setLatLng(latlng).setContent(popupContent).openOn(map);
  };

  var highlightTable = function (event) {
    const BulkSelectTable = require("../bulkSelect/bulkSelectTable");
    var properties = event.target.feature.properties;
    var id = properties.id;

    BulkSelectTable.scrollToAndHighlightTableRow(id);
  };

  return {
    loadLayerListenersForMap,
    show,
    hide,
    getCurrentData,
    getDataAndIndexByIdBmp,
    updateDataByIdBmp,
    refreshLayer,
    getMarkerReference,
    select,
    deselect,
    selectAll,
    multiSelect,
    getLayerKey,
    getBulkSelectLayer,
  };
};

module.exports = CatchBasinBulkCleanLayer();

const Actions = require("../actions");
const LayerDataFunctions = require("../general/layerDataFunctions");
const LayerFunctions = require("../layerFunctions");
const MapFunctions = require("../mapFunctions/mapFunctions");
const MuniCatchBasinIcon = require("./muniCatchBasinIcon");
const Tree = require("../tree");
const ResourceController = require("../offline/resourceController");
const Supercluster = require("supercluster");
const Clustering = require("../mapFunctions/clustering");
