"use strict";

const ProjectInventoryContacts = function () {
  const stringKey = "project-contacts";
  const headerInformation = "Contacts";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
    $page.on("click", ".new-contact-button", createNewContact);
    $page.on("click", ".edit-button", editContact);
    $page.on("click", ".save-button", saveContact);
    $page.on("click", ".delete-button", deleteExistingContact);
    $page.on("click", ".delete-editing-button", deleteEditingContact);
    $page.on("click", ".save-initial-button", saveInitialContact);
  };

  var saveInitialContact = function (e) {
    const contactLength = getContactLength();
    setFormInitialState(contactLength);
    saveContact(e);
  };

  var unloadListeners = function () {
    $page.off("click", ".new-contact-button", createNewContact);
    $page.off("click", ".edit-button", editContact);
    $page.off("click", ".save-button", saveContact);
    $page.off("click", ".delete-button", deleteExistingContact);
    $page.off("click", ".delete-editing-button", deleteEditingContact);
    $page.off("click", ".save-initial-button", saveInitialContact);
    $page.off("input", `[name*="contacts"]`, stageInitialId);
  };

  var render = async function (options = {}) {
    const project = InventoryModal.getAllData("project-inventory");
    const props = getProps(project);
    renderContactsHtml(props, options);
    loadListeners();
    setInitialState(props, options);
  };

  var renderContactsHtml = function (props, options) {
    const html = nunjucks.render("modals/projectInventory/projectInventoryContacts.njk", props);
    ProjectInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  var getProps = function (props) {
    return {
      contacts: prepareContacts(props),
      roleOptions: ProjectInventoryConstants.getRoleOptions(),
    };
  };

  var setInitialState = function (props, options) {
    const currentContactsLength = props?.contacts?.length;
    if (options.editContact) {
      editContactById(options.editContact);
    } else if (currentContactsLength > 0 && options.newContact) {
      createNewContact();
    } else if (currentContactsLength === 0 || options.newContact) {
      $page.find(".new-contact-button").hide();
      stageInitialContact();
    }
  };

  var stageInitialId = function () {
    const contactLength = Math.max(0, getContactLength() - 1);
    setFormInitialState(contactLength);
    $page.off("input", `[name*="contacts[${contactLength}]"]`, stageInitialId);
  };

  var setFormInitialState = function (contactLength = 0) {
    const newContactId = $page.find(".project-contacts .contact").data("id");
    if (!getContactInfoById(newContactId)) {
      const newContactRole = $page.find(`[name="contacts[${contactLength}][role]"]`).val();
      Form.manuallySetFormDataField(
        "project-inventory",
        ["contacts", contactLength, "id"],
        newContactId,
      );
      Form.manuallySetFormDataField(
        "project-inventory",
        ["contacts", contactLength, "role"],
        newContactRole,
      );
    }
  };

  var cleanUp = function () {
    unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var prepareContacts = function (props) {
    return ((props || {}).contacts || [])
      .filter((contact) => !contact.deleted)
      .map((contact) => ({
        ...contact,
        displayRole: getDisplayRoleFromContact(contact),
        fullMailingAddress:
          contact.fullMailingAddress ??
          Misc.getFullMailingAddress(
            contact.mailingAddress,
            contact.city,
            contact.state,
            contact.zipCode,
          ),
      }))
      .sort((a, b) => a.id - b.id);
  };

  var toggleEditSaveButtonsDisabled = function (disabled) {
    if (disabled === false) {
      $page.find(".new-contact-button").show();
    }
    $(".edit-button, .new-contact-button").prop("disabled", disabled);
  };

  var stageInitialContact = function () {
    const { html, currentContactsLength } = createContactHtml(true);
    toggleEditSaveButtonsDisabled(true);
    $page.find(".project-contacts").prepend(html);
    $page.on("input", `[name*="contacts[${currentContactsLength}]"]`, stageInitialId);
  };

  var createNewContact = function () {
    const { html, currentContactsLength, newContact } = createContactHtml(false);
    Form.manuallySetFormDataField(
      "project-inventory",
      ["contacts", currentContactsLength],
      newContact,
    );
    toggleEditSaveButtonsDisabled(true);
    $page.find(".project-contacts").prepend(html);
  };

  var createContactHtml = function (initialContact = false) {
    const contactRoles = ProjectInventoryConstants.getRoleOptions();
    const newId = Date.now();
    const newContact = { id: newId, role: contactRoles[0].value };
    const currentContactsLength = getContactLength();
    return {
      html: nunjucks.render("modals/projectInventory/projectInventoryNewContact.njk", {
        roleOptions: contactRoles,
        contact: newContact,
        index: currentContactsLength,
        initialContact: initialContact,
      }),
      currentContactsLength: currentContactsLength,
      newContact: newContact,
    };
  };

  var getContactLength = function () {
    const data = InventoryModal.getAllData("project-inventory");
    return (data.contacts || []).length;
  };

  var saveContact = function (e) {
    const $contact = $(e.currentTarget);
    const contactId = $contact.closest(".contact").data("id");
    const contactInfo = getContactInfoById(contactId);
    if (contactInfo === undefined) throw new Error(`Couldn't get contact with id ${contactId}.`);
    contactInfo.displayRole = getDisplayRoleFromContact(contactInfo);
    contactInfo.fullMailingAddress =
      contactInfo.fullMailingAddress ??
      Misc.getFullMailingAddress(
        contactInfo.mailingAddress,
        contactInfo.city,
        contactInfo.state,
        contactInfo.zipCode,
      );

    const html = nunjucks.render("modals/projectInventory/projectInventoryExistingContact.njk", {
      contact: contactInfo,
    });
    $contact.closest("section").html(html);
    toggleEditSaveButtonsDisabled(false);
    removeInitialInputListener();
  };

  var deleteExistingContact = function (e) {
    const $contact = $(e.currentTarget);
    const contactId = $contact.closest(".contact").data("id");
    const contactInfo = getContactInfoById(contactId);
    const fullName = `${contactInfo.firstName || ""} ${contactInfo.lastName || ""}`;
    const deleteExistingContactCallback = () => {
      deleteContact(contactId);
    };

    MessageModal.showDeleteContactModal(fullName, deleteExistingContactCallback);
  };

  var deleteEditingContact = function (e) {
    const $contact = $(e.currentTarget);
    const contactId = $contact.closest(".contact").data("id");
    const contactInfo = getContactInfoById(contactId);
    if (contactInfo) {
      const fullName = `${contactInfo.firstName || ""} ${contactInfo.lastName || ""}`;
      const deleteEditingContactCallback = () => {
        deleteContact(contactId);
        toggleEditSaveButtonsDisabled(false);
      };
      MessageModal.showDeleteContactModal(fullName, deleteEditingContactCallback);
    } else {
      removeInitialInputListener();
      $(`[data-id="${contactId}"]`).remove();
      toggleEditSaveButtonsDisabled(false);
    }
  };

  var deleteContact = function (contactId) {
    const contactIndex = getContactIndexById(contactId);
    const existingContactIds = (
      ProjectInventoryModalController.getExistingConstructionData("contacts") || []
    ).map((contact) => {
      return contact.id;
    });

    if (existingContactIds.includes(contactId)) {
      Form.manuallySetFormDataField("project-inventory", ["contacts", contactIndex], {
        deleted: true,
        id: contactId,
      });
    } else {
      Form.manuallyUnsetField("project-inventory", ["contacts", contactIndex]);
    }
    $(`[data-id="${contactId}"]`).remove();
  };

  var editContact = function (e) {
    const $contact = $(e.currentTarget);
    const contactId = $contact.closest(".contact").data("id");
    editContactById(contactId);
  };

  var editContactById = function (contactId) {
    const contactInfo = getContactInfoById(contactId);
    const html = nunjucks.render("modals/projectInventory/projectInventoryModifyContact.njk", {
      roleOptions: getRoleOptionsWithContact(contactInfo),
      contact: contactInfo,
      index: getContactIndexById(contactInfo.id),
    });
    $(`.contact[data-id=${contactId}]`).html(html);
    toggleEditSaveButtonsDisabled(true);
  };

  var getRoleOptionsWithContact = function (contactInfo) {
    const options = Array.from(ProjectInventoryConstants.getRoleOptions());

    if (!options.containsObjectWith("value", contactInfo.role)) {
      options.unshift({ value: contactInfo.role, name: contactInfo.displayRole });
    }

    return options;
  };

  var getDisplayRoleFromContact = function (contact) {
    const roles = ProjectInventoryConstants.getAllRoleOptions();
    const matchingRole = roles.find((options) => {
      return options.value === contact.role;
    });

    if (matchingRole === undefined) {
      return contact.displayRole;
    }

    return matchingRole.displayName;
  };

  var getContactIndexById = function (contactId) {
    const data = InventoryModal.getAllData("project-inventory");
    if (data.contacts) {
      return data.contacts.findIndex((contact) => {
        return contactId === (contact || {}).id;
      });
    }
  };

  var getContactInfoById = function (contactId) {
    const data = InventoryModal.getAllData("project-inventory");
    const contactIndex = getContactIndexById(contactId);

    return contactIndex === undefined ? undefined : data.contacts[contactIndex];
  };

  var removeInitialInputListener = function () {
    const length = getContactLength() - 1;
    $page.off("input", `[name*="contacts[${length}]"]`, stageInitialId);
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    editContactById,
    deleteContact,
  };
};

module.exports = ProjectInventoryContacts();

const ProjectInventoryModalController = require("./projectInventoryModalController");
const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const MessageModal = require("../modals/messageModal");
const Form = require("../general/form");

const Misc = require("../misc");
