"use strict";

const IncidentInvestigationResponsibleParty = function () {
  const stringKey = "responsibleParty";
  const tabHeader = "Responsible Party";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on(
      "2N:FormInput",
      `[name="responsible-party-identified"]`,
      onResponsiblePartyIdentifiedToggle,
    );
    $page.on("2N:FormInput", `[name="damage-billed"]`, onDamageBilledToggle);
    $page.on("2N:FormInput", `[name="enforcement-issued"]`, onEnforcementIssuedToggle);

    toggleIResponsiblePartyIdentified($page, data.incidentInfo.responsiblePartyIdentified);
    toggleDamageCostField($page, data.damageBilled);
    toggleEnforcementLevelField($page, data.enforcementIssued);
  };

  var unloadListeners = function () {
    $page.off(
      "2N:FormInput",
      `[name="responsible-party-identified"]`,
      onResponsiblePartyIdentifiedToggle,
    );
    $page.off("2N:FormInput", `[name="damage-billed"]`, onDamageBilledToggle);
    $page.off("2N:FormInput", `[name="enforcement-issued"]`, onEnforcementIssuedToggle);
  };

  var getProps = function () {
    return {
      addressStateOptions: FormConstants.addressStateOptions,
      enforcementLevelOptions: IncidentInvestigationConstants.getEnforcementLevelOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onResponsiblePartyIdentifiedToggle = function (e, identified) {
    toggleIResponsiblePartyIdentified($page, identified);
  };

  var toggleIResponsiblePartyIdentified = function ($page, identified) {
    $page.find(`.show-when-identified`).toggle(identified);
  };

  var onDamageBilledToggle = function (e, damageBilled) {
    toggleDamageCostField($page, damageBilled);
  };

  var toggleDamageCostField = function ($page, damageBilled) {
    $("fieldset.damage-cost").toggle(damageBilled);
  };

  var onEnforcementIssuedToggle = function (e, enforcementIssued) {
    toggleEnforcementLevelField($page, enforcementIssued);
  };

  var toggleEnforcementLevelField = function ($page, enforcementIssued) {
    $("fieldset.enforcement-level").toggle(enforcementIssued);
  };

  return { loadListeners, unloadListeners, getStringKey, getTabHeader, getProps };
};

module.exports = IncidentInvestigationResponsibleParty();

const FormConstants = require("../../mapFunctions/formConstants");
const IncidentInvestigationConstants = require("./incidentInvestigationConstants");
