"use strict";

const NavToggle = function () {
  let thisToggle;

  var loadDomListeners = function () {
    const widgetScreen = DataViewFunctions.getCurrentDataViewProperty("widgetScreenButton");
    menuCollapseListener();
    if (!widgetScreen) {
      thisToggle.off();
      thisToggle.on("click", ".nav-toggle-map-button", _showMapPanel);
      thisToggle.on("click", ".nav-toggle-list-button", showListPanel);
    } else {
      AssetManagementWidgetToggle.loadDomListeners();
    }
  };

  var render = function () {
    const selected = "map";
    const widgetScreen = DataViewFunctions.getCurrentDataViewProperty("widgetScreenButton");
    if (!widgetScreen) {
      Tree.set("navToggleSelection", selected);
      $(".nav-toggle-mount")
        .getOnlyOrThrow()
        .html(nunjucks.render("navbar/navToggle.njk", { selected }));
      thisToggle = $(".nav-toggle");
    } else {
      AssetManagementWidgetToggle.render();
    }
  };

  var showToggle = function () {
    $(".nav-toggle-mount").getOnlyOrThrow().show();
  };

  var hideToggle = function () {
    $(".nav-toggle-mount").getOnlyOrThrow().hide();
  };

  var menuCollapseListener = function () {
    $("#main-header-collapse-container").on("hidden.bs.collapse", function () {
      $("#construction, #indcom, #muni").removeClass("expanded-mobile-menu");
    });

    $("#main-header-collapse-container").on("shown.bs.collapse", function () {
      $("#construction, #indcom, #muni").addClass("expanded-mobile-menu");
    });
  };

  var showPanel = function (selected = Tree.get("navToggleSelection")) {
    const listEnabledTabs = DataViewFunctions.getUserEnabledTabs(Tree.get("dataView"), "list");
    const mapEnabledTabs = DataViewFunctions.getUserEnabledTabs(Tree.get("dataView"), "map");

    if (selected === "map") {
      if (
        !(mapEnabledTabs.includes("todo") || mapEnabledTabs.includes("result")) &&
        !(listEnabledTabs.includes("insight") && !listEnabledTabs.includes("data"))
      ) {
        Panels.handleDisplayTab("data");
      }
      _showMapPanel();
    } else if (selected === "list") {
      showListPanel();
      if (!mapEnabledTabs.includes("todo")) {
        toggleMapButtonDisabled(true);
      }
    } else {
      throw new Error(`Can't show selected panel ${selected}.`);
    }
  };

  var showListPanel = function () {
    const enableSummary = DataViewFunctions.getCurrentDataViewProperty(
      "enableFilterSummaryOnMapOrListToggle",
    );
    FactSheetNew.close();
    Tree.set("navToggleSelection", "list");
    $(".ms4-return-button").addClass("hidden");
    $(".nav-toggle-list-button").addClass("active");
    if (enableSummary && Tree.get("activeTab") !== "insight") FilterSummary.enable();
    $(".nav-toggle-map-button").removeClass("active");
    MapTitle.toggleUseWhiteText(false);
    MapPanel.hide();
    Mobile.toggleMobileButtons();
    MainMapController.handleHideListActionButton();
    handlePhaseCondition();
    $(".filter-summary-container").addClass("raise-z-index");
  };

  var _showMapPanel = function () {
    const enableSummary = DataViewFunctions.getCurrentDataViewProperty(
      "enableFilterSummaryOnMapOrListToggle",
    );
    FactSheetNew.close();
    Tree.set("navToggleSelection", "map");
    $(".ms4-return-button").addClass("hidden");
    $(".nav-toggle-map-button").addClass("active");
    $(".nav-toggle-list-button").removeClass("active");
    if (enableSummary && Tree.get("activeTab") !== "insight") FilterSummary.enable();

    MapTitle.useWhiteTextBaseMap();
    MapPanel.show();
    Mobile.toggleMobileButtons();
    handlePhaseCondition();
    $(".filter-summary-container").removeClass("raise-z-index");
  };

  var toggleAllPanels = function (toggle) {
    $(
      "#tool-header .breadcrumbs, #tool-header .tool-tabs-container, #tool-header .tool-header-item-container",
    ).toggle(toggle);
  };

  var showBackButton = function (pageTitle, onBackCallback) {
    const $backButton = $("#tool-header .back-button");

    $backButton.off("click");
    $backButton.on("click", onBackCallback);

    $backButton.find(".title").text(pageTitle);
    $backButton.toggle(true);

    toggleAllPanels(false);
  };

  var hideBackButton = function () {
    const $backButton = $("#tool-header .back-button");
    $backButton.toggle(false);
    toggleAllPanels(true);
  };

  var handleCollapsedSideBar = function () {
    if (!$("#collapsed-side-table").hasClass("hidden") && window.innerWidth > 992) {
      Table.handleSideBarToggle();
    }
  };

  var handlePhaseCondition = function () {
    const conditionalPhaseLogic = Tree.get("filters", "conditionPhaseToggle");
    const dataView = Tree.get("dataView");
    if (conditionalPhaseLogic === "condition" && dataView === "bmp") {
      setTimeout(function () {
        Filters.disableNonConditionPhases(["completed", "certified"]);
      }, 100);
    }
    if (conditionalPhaseLogic === "condition" && dataView === "construction-project") {
      setTimeout(function () {
        Filters.disableNonConditionPhases(["construction", "post-active"]);
      }, 100);
    }
  };

  var toggleListButtonDisabled = function (disabled) {
    const $button = $(".nav-toggle-list-button");
    $button.toggleClass("disabled", disabled);
    Misc.toggleDisabled($button, disabled);
  };

  var toggleMapButtonDisabled = function (disabled) {
    const $button = $(".nav-toggle-map-button");
    $button.toggleClass("disabled", disabled);
    Misc.toggleDisabled($button, disabled);
  };

  return {
    loadDomListeners,
    render,
    showToggle,
    showPanel,
    hideToggle,
    toggleListButtonDisabled,
    toggleMapButtonDisabled,
    handleCollapsedSideBar,
    showBackButton,
    hideBackButton,
    _showMapPanel,
  };
};

module.exports = NavToggle();

const DataViewFunctions = require("../dataViewFunctions");
const MapPanel = require("./mapPanel");
const MapTitle = require("./mapTitle");
const Misc = require("../misc");
const Mobile = require("../mobile");
const Panels = require("../panels");
const Tree = require("../tree");
const AssetManagementWidgetToggle = require("../assetManagement/assetManagementWidgetToggle");
const MainMapController = require("../mapFunctions/mainMapController");
const Filters = require("./filters");
const FilterSummary = require("../filters/filterSummary");
const Table = require("./table");
const FactSheetNew = require("../general/factSheetNew");
