"use strict";

const MapLayer = function (
  layerName,
  layerKey,
  getDataFunction,
  {
    createMarkerFunction = null,
    getPopupHtmlFunction = null,
    getPopupDataFunction = null,
    onPopupOpenFunction = null,
    getFiltersFunction = null,
    clusteringEnabled = false,
    itemCountEnabled = false,
    hideWhenZoomedOut = false,
    createIcon = null,
    getGeoServerPopupHtmlFunction = null,
  } = {},
) {
  var markerReferences = {};
  var fetchingPromise;
  var addLayer = function (map, mapLayers, data, mapId) {
    if (mapLayers[layerKey]) {
      map.removeLayer(mapLayers[layerKey]);
    }
    mapLayers[layerKey] = createLayer(data, mapId);
    mapLayers[layerKey].on("popupclose", function () {
      if (Tree.get(["layers", layerName, "hasNewData"])) {
        loadLayer("main");
        Tree.set(["layers", layerName, "hasNewData"], false);
      }
    });
    mapLayers[layerKey].addTo(map);
    return mapLayers[layerKey];
  };

  var createLayer = function (data, mapId) {
    var showPopup = mapId !== "modal";
    const Layer = L.GeoJSON.extend({
      options: {
        pointToLayer: function (feature, latlng) {
          const marker = createMarkerFunction(latlng, feature.properties);
          storeMarkerReference(feature.properties.id, marker);
          return marker;
        },
        onEachFeature: async function (feature, layer) {
          const Table = require("../mapFunctions/table");
          if (showPopup) {
            layer.bindPopup(
              () => getPopupHtmlFunction(structuredClone(feature.properties)),
              Layers.getPopupOptions(),
            );
            layer.on({
              click: (e) => Table.expandTableAndScroll(e.target.feature.properties.id),
              popupopen: _onPopupOpen,
            });
          }
        },
      },
    });
    var layer = new Layer();
    layer.addData(data);

    return layer;
  };

  var layerEnabledBasedOnZoom = function (currentZoom) {
    if (!hideWhenZoomedOut) {
      return true;
    }

    return MapFunctions.isMuniScaleZoom(currentZoom);
  };

  var startZoomListener = function (map, mapLayers, mapId) {
    Tree.set(["layers", layerName, "listening"], true);
    map.on("zoomend", function () {
      const isEnabled = Tree.get([
        "layers",
        layerName,
        Actions.getLayerIsEnabledPathByMapId(mapId),
      ]);
      _updateLayerEnabled(map, mapId, mapLayers[layerKey], isEnabled);

      mapLayers[layerKey]?.eachLayer(function (marker) {
        if (createIcon && marker instanceof L.Marker) {
          marker.setIcon(createIcon(marker.feature.properties, map.getZoom(), marker.getLatLng()));
        }
      });
    });
  };

  var _updateLayerEnabled = function (map, mapId, layer, isEnabled, sameSpatialFilter = true) {
    if (isEnabled) {
      if (
        layer &&
        layerHasData(layerName, mapId) &&
        (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
      ) {
        if (layerEnabledBasedOnZoom(map.getZoom())) {
          map.addLayer(layer);
        } else {
          map.removeLayer(layer);
        }
      } else {
        return loadLayer(mapId);
      }
    } else if (MapFunctions.mapHasLayer(map, layer)) {
      map.removeLayer(layer);
    }
  };

  var _onPopupOpen = async function (e) {
    const data = await _getPopupData(e.sourceTarget.feature.properties);

    if (data === null) {
      return;
    }

    data.isFieldPersonnel = Session.isFieldPersonnel();

    let html = getPopupHtmlFunction(data);
    if (!html) {
      html = getGeoServerPopupHtmlFunction(data);
    }
    e.popup.setContent(html);

    if (onPopupOpenFunction) {
      onPopupOpenFunction(data, $(e.popup.getElement()));
    }
  };

  var _getPopupData = async function (featurProperties) {
    const popupDataPath = [...getPopupDataPath(), featurProperties.id];
    const previousPopupData = Tree.get(popupDataPath);

    if (previousPopupData?.isFetching) {
      return null;
    }

    if (!getPopupDataFunction) {
      return featurProperties;
    }

    return await fetchOrGetPreviousPopupData(featurProperties, previousPopupData, popupDataPath);
  };

  var fetchOrGetPreviousPopupData = async function (
    featurProperties,
    previousPopupData,
    popupDataPath,
  ) {
    Tree.set([...popupDataPath, "isFetching"], !previousPopupData);

    if (!previousPopupData) {
      const data = await getPopupDataFunction(featurProperties.id, featurProperties);
      Tree.set(popupDataPath, data);

      return data;
    }

    return previousPopupData;
  };

  var getPopupDataPath = function () {
    return ["popupData", layerName];
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerDataUpdated(layerName, mapId, function (data) {
      if (ToDoFunctions.isToDoLayer(layerName)) {
        LayerFunctions.filterToDoLayer(layerName, mapLayers, map, mapId);
      } else {
        setLayerData(map, mapLayers, data, mapId);
      }
    });
  };

  var setLayerData = function (map, mapLayers, data, mapId) {
    if (!data) {
      loadLayer();
      return;
    }

    const geoJson = CleanData.cleanGeoJson(data);
    mapLayers[layerKey] = addLayer(map, mapLayers, geoJson, mapId);
    startZoomListener(map, mapLayers, mapId);
    if (clusteringEnabled) {
      const Clustering = require("../mapFunctions/clustering");
      Clustering.setState(layerName);
    }

    tableDataUpdated(mapId);
  };

  var tableDataUpdated = function (mapId) {
    if (
      mapId === "main" &&
      DataViewFunctions.getCurrentDataViewProperty("defaultLayers")?.includes(layerName) &&
      !Filters.spatialFilterIsSet()
    ) {
      Table.render();
    }
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      _updateLayerEnabled(map, mapId, mapLayers[layerKey], isEnabled, sameSpatialFilter);
    });
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    Tree.select("filters").on("update", function () {
      if (
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", layerName, "isEnabled")
      ) {
        loadLayer();
      }
    });

    Tree.select("activeTab").on("update", function () {
      if (
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", layerName, "isEnabled") &&
        !Tree.get("layers", layerName, "data")
      ) {
        loadLayer();
      }
    });
  };

  var loadLayer = async function (mapId = "main") {
    Tree.set(getPopupDataPath(), {});
    if (ToDoFunctions.loadTodoByLayer(layerName, mapId)) {
      return;
    }

    const filters = getFiltersFunction
      ? getFiltersFunction(mapId)
      : Actions.getFiltersByMapId(mapId);
    const dataPath = Actions.getLayerDataPathByMapId(mapId);

    if (Tree.get(["layers", layerName, "isFetching"])) {
      return await fetchingPromise;
    }

    Tree.set(["layers", layerName, "isFetching"], true);
    fetchingPromise = getDataFunction(filters);
    const data = await fetchingPromise;
    Tree.set(["layers", layerName, dataPath], data);
    Tree.set(["layers", layerName, "isFetching"], false);

    if (itemCountEnabled) {
      Actions.setItemCount(data.length);
    }
  };

  var invalidateLayerData = function () {
    const isEnabled = Tree.get("layers", layerName, "isEnabled");

    Tree.set(["layers", layerName, "data"], null);
    ToDoFunctions.invalidateToDoData(layerName);

    if (isEnabled) {
      return loadLayer();
    } else {
      MainMap.invalidateLayerByName(layerName);
    }
  };

  var layerHasData = function (layerName, mapId) {
    return !!Tree.get(getCurrentLayerDataPath(layerName, mapId));
  };

  var getCurrentLayerDataPath = function (layerName, mapId) {
    if (Tree.get("activeTab") === "todo") {
      return ["todos", Tree.get("dataView"), "unfilteredData"];
    } else {
      return ["layers", layerName, Actions.getLayerDataPathByMapId(mapId)];
    }
  };

  var storeMarkerReference = function (id, marker) {
    markerReferences[id] = marker;
  };

  var getMarkerReference = function (id) {
    return markerReferences[id];
  };

  return {
    loadToggledListenerForMap,
    loadDataUpdatedListenerForMap,
    loadTreeUpdateListenersForMap,
    getMarkerReference,
    loadLayer,
    invalidateLayerData,
    setLayerData,
    _updateLayerEnabled,
    _getPopupData,
  };
};

module.exports = MapLayer;

const Actions = require("../actions");
const CleanData = require("../mapFunctions/cleanData");
const DataViewFunctions = require("../dataViewFunctions");
const Filters = require("../mapFunctions/filters");
const LayerFunctions = require("../layerFunctions");
const Layers = require("../mapFunctions/layers");
const MainMap = require("./mainMap");
const MapFunctions = require("./mapFunctions");
const Table = require("../mapFunctions/table");
const ToDoFunctions = require("./toDoFunctions");
const Tree = require("../tree");
const Session = require("../login/session");
