"use strict";

const PlansController = function () {
  let dataSorting = { key: "id", reversed: true };

  function _loadListeners() {
    $("#esg-plans")
      .off("click", ".delete-plan-button", _deletePlan)
      .on("click", ".delete-plan-button", _deletePlan)
      .off("click", ".change-phase-button", _showPlanPhaseModal)
      .on("click", ".change-phase-button", _showPlanPhaseModal)
      .off("click", "th.sortable", _sortByClickedColumn)
      .on("click", "th.sortable", _sortByClickedColumn)
      .off("click", ".recreate-plan-button", _showRecreateWarningModal)
      .on("click", ".recreate-plan-button", _showRecreateWarningModal);
  }

  const render = async function () {
    const options = {
      breadcrumbs: {
        previousPage: {
          page: "Impact Accounting",
          route: "/impact-accounting",
        },
        currentPage: "Stormwater Plans",
      },
      preparedDataFunction: _getPreparedData,
      afterRenderFunction: _afterRenderFunction,
    };
    await PageController.render("plans", "esg/plans.njk", () => EsgApiCalls.getPlans(), options);
  };

  const _afterRenderFunction = function () {
    _loadListeners();
    FilterSummary.disable();
    SideBar.hideCollapsedSideBar();
  };

  const _sortByClickedColumn = function () {
    const $this = $(this);
    dataSorting = {
      key: $this.data("sortKey"),
      reversed: dataSorting?.key === $this.data("sortKey") ? !dataSorting?.reversed : false,
    };
    PageController.rerender();
    TableSort.updateHeaders(dataSorting, $("#esg-plans"));
  };

  const _getPreparedData = function (plans) {
    sortBy(dataSorting.key, dataSorting.reversed, plans);
    plans = plans.map((plan) => {
      return {
        ...plan,
        dropClasses: DataRichness.getDropClasses(plan.dataRichness),
      };
    });
    const completedPlans = plans.filter((plan) => {
      return plan.wizardCompleted;
    });
    return {
      rows: plans,
      options: {
        selectedPropertiesTotal: completedPlans?.sumValues("propertyCount"),
        dropClassesAvg: DataRichness.getDropClasses(completedPlans?.averageValues("dataRichness")),
        totalCostTotal: completedPlans?.sumValues("totalCost"),
        costPerGallonAvg: completedPlans?.averageValues("costPerGallon"),
        swMngdTotal: completedPlans?.sumValues("stormwaterBetterManaged"),
        pollMngdTotal: completedPlans?.sumValues("stormwaterPollution"),
        imperviousAcresTotal: completedPlans?.sumValues("treatedImperviousArea"),
        canCreatePlan: UserPermissions.getPermission("can_create_plan"),
      },
    };
  };

  const sortBy = function (key, reversed, plans) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    var sortersDict = {
      id: alphaSorter,
      planName: alphaSorter,
      phase: alphaSorter,
      propertyCount: numberSorter,
      dataRichness: numberSorter,
      totalCost: numberSorter,
      costPerGallon: numberSorter,
      stormwaterBetterManaged: numberSorter,
      stormwaterPollution: numberSorter,
      treatedImperviousArea: numberSorter,
    };
    if (Object.keys(sortersDict).includes(key)) {
      SortUtilities.sortRows(key, reversed, plans, sortersDict);
    }
  };

  const _showPlanPhaseModal = async function () {
    const planId = $(this).attr("data-id");
    if (!planId) {
      return;
    }
    const planData = await EsgApiCalls.getFullPlan(planId, { loadingScreen: true });
    ChangePhaseModal.render(planData);
  };

  const _showRecreateWarningModal = async function (id) {
    MessageModal.showConfirmWarningModal(
      null,
      () => {
        const planId = $(this).data("id");
        window.location.assign(`/impact-accounting/stormwater-plans/${planId}`);
      },
      "Go Back",
      "Continue",
      `<p>Recreating the plan allows you to change your objective weightings, change filter selections, and rerun the benefit analysis. This will unshelve properties and generate a new selection of properties based on specified weightings and filters.</p><p>Click <strong>Go Back</strong> to keep the existing property selection.<br>Click <strong>Continue</strong> to adjust your plan specifications.</p>`,
    );
  };

  var _deletePlan = async function (event) {
    event.preventDefault();
    const id = $(this).data("id");
    const tr = $(this).closest("tr");
    const name = tr.find(".plan-name-col").text().trim();
    MessageModal.showConfirmWarningModal(
      null,
      () => deleteFromModal(id),
      "Cancel",
      "Delete",
      `<p>Delete <b>${name}</b> plan?<p><p>Click <b>Delete</b> to permanently delete the plan.<br>Click <b>Cancel</b> to keep the plan.</p>`,
    );
  };

  var deleteFromModal = async function (id, tr) {
    await EsgApiCalls.deletePlan(id);
    await PageController.refresh();
  };
  return { render };
};

module.exports = PlansController();

const PageController = require("./pageController");
const SortUtilities = require("../general/sortUtilities");
const EsgApiCalls = require("./esgApiCalls");
const DataRichness = require("./dataRichness");
const MessageModal = require("../modals/messageModal");
const FilterSummary = require("../filters/filterSummary");
const SideBar = require("./sideBar");
const ChangePhaseModal = require("./modals/changePhaseModal");
const UserPermissions = require("../login/userPermissions");
const TableSort = require("../general/tableSort");
