"use strict";

const ModuleSettingsController = function () {
  const htmlPath = "modals/moduleSettings";
  const formKey = "module-settings";

  let $modal;
  let tabs;
  let initialData;

  var open = async function () {
    initialData = await ApiCalls.getModuleSettings();
    showModal();
    Form.initializeAndLoadListeners($modal, formKey);
  };

  var showModal = function () {
    tabs = [
      require("./userConfiguration"),
      require("./groupConfiguration"),
      require("./constructionSettings"),
      require("./indComSettings"),
      require("./lidSettings"),
    ];
    const renderData = mapTabProps(tabs);
    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath);
    TabbedModal.setHeaderText("Module Settings");

    TabbedModal.setSaveHandler(_confirmAndSave, false);
    TabbedModal.setCancelHandler(() => {});
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);

    return $modal;
  };

  var mapTabProps = function (tabs) {
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(initialData));
    }, {});
    return {
      ...initialData,
      ...tabProps,
    };
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var _confirmAndSave = async function () {
    try {
      await MessageModal.showConfirmWarningModalAsPromise(
        "Are you sure you want to save? Changes may not be reflected until you refresh the page.",
      );
    } catch (e) {
      return false;
    }

    try {
      await Form.getReadyToSavePromise(formKey);
      await ApiCalls.setModuleSettings(
        _getUpdateData(initialData, Form.getDataFromForm(formKey, false)),
      );
    } catch (e) {
      return UploadValidator.reportError(e);
    }

    return true;
  };

  var _getUpdateData = function (initialData, dataToUpload) {
    return {
      updates: dataToUpload,
    };
  };

  var manuallySetFormDataField = function (path, value) {
    Form.manuallySetFormDataField(formKey, path, value);
  };

  return {
    open,
    manuallySetFormDataField,
    _confirmAndSave,
    _getUpdateData,
  };
};

module.exports = ModuleSettingsController();

const ApiCalls = require("../../apiCalls");
const Form = require("../../general/form");
const MessageModal = require("../../modals/messageModal");
const TabbedModal = require("../../general/tabbedModal");
const UploadValidator = require("../../general/uploadValidator");
