"use strict";

var Misc = function () {
  // A slightly modified version of David Koelle's alphanum algorithm
  // http://www.davekoelle.com/files/alphanum.js
  var alphanum = function (a, b) {
    var re = /(\d+)/g;

    // split strings into chunks containing either all alphabetic, or all numeric chars
    var aa = a.split(re);
    var bb = b.split(re);
    var i, c, d;

    for (i = 0; i < aa.length && i < bb.length; i++) {
      // chunks are different
      if (aa[i] !== bb[i]) {
        // try to parse as number and compare
        c = Number(aa[i]);
        d = Number(bb[i]);
        if (!isNaN(c) && !isNaN(d)) return c - d;
        // compare as strings
        return aa[i].localeCompare(bb[i]);
      }
    }
    return a.length - b.length;
  };

  var debounce = function (fn, time) {
    var timeout;
    time = time || 200;
    return function () {
      var args = [].slice.call(arguments, 0);
      clearTimeout(timeout);
      timeout = setTimeout(fn.bind(this, args), time);
    };
  };

  const parseDbDateTime = function (dateTimeFromDb) {
    if (typeof dateTimeFromDb !== "string") {
      console.warn("Trying to convert a non-string to a Date object");
      return dateTimeFromDb;
    }

    var date;
    var match = dateTimeFromDb.match(
      /^\s*(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+).?(\d+)?([+\-]\d+)?\s*$/,
    );

    if (match) {
      // Assumed to be in SQL standard yyyy-mm-dd hh:mm:ss
      // Or timestamptz yyyy-mm-dd hh:mm:ss.ss+-tz

      if (match[8] !== undefined) {
        date = new Date(
          Date.UTC(
            match[1],
            parseInt(match[2]) - 1,
            match[3],
            parseInt(match[4]) + parseInt(match[8]),
            match[5],
            parseInt(match[7]) * 10,
          ),
        );
      } else if (match[7] !== undefined) {
        date = new Date(
          match[1],
          parseInt(match[2]) - 1,
          match[3],
          match[4],
          match[5],
          match[6],
          parseInt(match[7]) * 10,
        );
      } else {
        date = new Date(match[1], parseInt(match[2]) - 1, match[3], match[4], match[5], match[6]);
      }
    } else {
      // Assumed to be in https://www.w3.org/TR/NOTE-datetime
      date = new Date(dateTimeFromDb);
    }

    if (isNaN(date)) {
      throw `String could not be converted to Date object: ${dateTimeFromDb}.`;
    }

    return date;
  };

  var arrayDifference = function (array1, array2) {
    return array1.filter((x) => !array2.includes(x));
  };

  var kebabToCamel = function (string) {
    return string.replace(/(\-\w)/g, function (matches) {
      return matches[1].toUpperCase();
    });
  };

  var camelToKebab = function (string) {
    return string.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
  };

  var camelToSnake = function (string) {
    return string.replace(/([a-z0-9])([A-Z])/g, "$1_$2").toLowerCase();
  };

  var groupBy = function (objectArray, prop) {
    return objectArray.reduce(function (acc, obj) {
      const key = obj[prop];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  var sumBy = function (objectArray, prop) {
    return objectArray.reduce(function (acc, obj) {
      return acc + obj[prop];
    }, 0);
  };

  var removeAttributeValues = function (valuesToKeep, removeFrom, attribute) {
    removeFrom.forEach((item) => {
      if (!valuesToKeep.includes(item[attribute])) {
        delete item[attribute];
      }
    });
  };

  var appendExistingAttribute = function (values, appendTo, attribute) {
    appendTo.forEach((item, index) => {
      if (values[index] !== undefined && item !== undefined && item[attribute] === undefined) {
        item[attribute] = values[index];
      }
    });
  };

  var isValidUrl = function (str) {
    if (str === undefined || str === null) {
      return false;
    }

    if (typeof str !== "string") {
      throw `Type is not a string: ${str}.`;
    }

    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_\\/]*)?$",
      "i",
    ); // fragment locator
    return !!pattern.test(str.trim());
  };

  return {
    debounce,
    alphanum,
    parseDbDateTime,
    arrayDifference,
    kebabToCamel,
    camelToKebab,
    camelToSnake,
    groupBy,
    sumBy,
    removeAttributeValues,
    appendExistingAttribute,
    isValidUrl,
  };
};

module.exports = Misc();
