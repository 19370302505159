"use strict";

const MuniSbmpDrainageAreaLayer = function () {
  const layerName = "muniSbmpDrainageArea";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadTreeUpdateListenersForMap(map, mapLayers);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      if (isEnabled) {
        if (
          mapLayers.muniSbmpDrainageAreaLayer &&
          (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
        ) {
          map.addLayer(mapLayers.muniSbmpDrainageAreaLayer);
        } else {
          loadLayer(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.muniSbmpDrainageAreaLayer)) {
          map.removeLayer(mapLayers.muniSbmpDrainageAreaLayer);
        }
      }
    });
  };

  var loadLayer = async function (mapId) {
    var filters = Actions.getFiltersByMapId(mapId);
    var dataPath = Actions.getLayerDataPathByMapId(mapId);

    Tree.set(["layers", layerName, "isFetching"], true);
    const data = await ApiCalls.getMuniSbmpsDrainages(filters);
    Tree.set(["layers", layerName, dataPath], data);
    Tree.set(["layers", layerName, "isFetching"], false);
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerDataUpdated(layerName, mapId, function (data) {
      const geoJsonData = CleanData.cleanGeoJson(data);
      mapLayers.muniSbmpDrainageAreaLayer = createLayerAndAddToMap(
        map,
        mapLayers,
        geoJsonData,
        mapId,
      );
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data, mapId) {
    if (mapLayers.muniSbmpDrainageAreaLayer) {
      map.removeLayer(mapLayers.muniSbmpDrainageAreaLayer);
    }
    mapLayers.muniSbmpDrainageAreaLayer = createLayer(data, mapId);
    mapLayers.muniSbmpDrainageAreaLayer.addTo(map);
    return mapLayers.muniSbmpDrainageAreaLayer;
  };

  var createLayer = function (data, mapId) {
    var isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);
    const geoJsonLayer = L.GeoJSON.extend({
      options: {
        style: function (feature) {
          const properties = feature.properties;

          return getMuniSbmpDrainageAreaStyle(
            properties.lastRoutineMaintenanceId,
            properties.bmpRoutinRepairIsDue,
            Tree.get("layers", layerName, isEnabledPath),
          );
        },
      },
    });

    var layer = new geoJsonLayer();
    layer.addData(data);

    return layer;
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    Tree.select("filters").on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.muniSbmpDrainageAreaLayer) &&
        Tree.get("layers", layerName, "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var getMuniSbmpDrainageAreaStyle = function (
    lastRoutineMaintenanceId,
    bmpRoutinRepairIsDue,
    isEnabled,
  ) {
    const condition = RoutineMaintenanceIcon.getLayerCondition(
      lastRoutineMaintenanceId,
      bmpRoutinRepairIsDue,
    );
    const colorName = RoutineMaintenanceIcon.getConditionColor(condition);
    const color = MapStyles.getHexColor(colorName);
    const opacity = isEnabled ? 0.4 : 0;

    var styleProps = {
      color: color,
      fillOpacity: opacity,
      fillColor: color,
      weight: 0,
    };

    return styleProps;
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = MuniSbmpDrainageAreaLayer();

const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const CleanData = require("../mapFunctions/cleanData");
const MapFunctions = require("../mapFunctions/mapFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const Actions = require("../actions");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
