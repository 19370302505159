"use strict";

const DryWeatherHistory = function () {
  var renderDryWeatherHistory = function (history, assetType, assetId) {
    disableActionsByProperty(history, "assetType", "incident");
    disableActionsByProperty(history, "maintenanceType", "close-out");

    var html = nunjucks.render("popups/history/dryWeatherHistory.njk", {
      history,
      assetType,
      assetId,
    });
    $(".popup-history-pane").html(html);
  };

  var disableActionsByProperty = function (data, key, value) {
    for (const history of data) {
      if (history[key] === value) {
        history.noActions = true;
      }
    }

    return data;
  };

  var editHistory = function (tableRowData, readOnly) {
    DryWeatherFunctions.openDryWeatherScreening(
      tableRowData.assetId,
      tableRowData.assetType,
      tableRowData.id,
      readOnly,
    );
  };

  var confirmDeleteHistory = function ($todoTr, tableRowData) {
    DryWeatherFunctions.deleteDryWeatherScreening($todoTr, tableRowData.id, tableRowData.assetType);
  };

  return {
    editHistory,
    confirmDeleteHistory,
    renderDryWeatherHistory,
  };
};

module.exports = DryWeatherHistory();

const DryWeatherFunctions = require("./dryWeatherFunctions");
