"use strict";

var PopupContent = function () {
  let activeGroupId;

  var getCatchmentPopup = function (feature) {
    if (!activeGroupId) {
      activeGroupId = Tree.get("activeGroup", "groupId");
    }
    return nunjucks.render("report/popups/catchment.html", feature.properties);
  };

  var getWatershedPopup = function (feature) {
    return nunjucks.render("report/popups/watershed.html", feature.properties);
  };

  var getRegionAreaPopup = function (feature) {
    var disableGoToMs4Button =
      Progeny.rootGroupIsRegulator() &&
      !RegionDropdown.hasSubmittedInYear(feature.properties.reports);
    feature.properties.disableGoToMs4Button = disableGoToMs4Button;
    return nunjucks.render("report/popups/ms4.html", feature.properties);
  };

  var getRegionPointPopup = function (feature) {
    return nunjucks.render("report/popups/programs.html", feature.properties);
  };

  /* ***********  POPUP LISTENERS *********** */
  $(".map-content").on("click", ".popupShowHistory", function () {
    $(".popup-content-bmp").hide();
    $(".popup-second-page").show();
    $(".popup-history").show();
  });

  $(".map-content").on("click", ".popupShowPhotos", function () {
    $(".popup-content-bmp").hide();
    $(".popup-second-page").show();
    $(".popup-photos").show();
  });

  $(".map-content").on("click", ".popupHideSecondPage", hidePopupSecondPage);

  function hidePopupSecondPage() {
    $(".popup-content-bmp").show();
    $(".popup-second-page").hide();
    $(".popup-history").hide();
    $(".popup-photos").hide();
  }

  return {
    getCatchmentPopup,
    getWatershedPopup,
    getRegionAreaPopup,
    getRegionPointPopup,
  };
};

module.exports = PopupContent();

const Tree = require("../../tree");
const Progeny = require("../../login/progeny");
const RegionDropdown = require("../regionDropdown");
