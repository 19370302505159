"use strict";

const IncidentInvestigationConstants = function () {
  var getNewIncidentInvestigationDefaults = function (data) {
    const defaults = {
      fieldPersonnel: Session.loadContext().full_name,
      incidentId: data.id,
      inspectionDate: DateTime.getTodayIso(),
      investigationStartTime: new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }),
      investigationEndTime: null,
      investigationTotalTime: null,
      samplesCollected: false,
      followUpNeeded: false,
      closeOutInvestigation: false,
      incidentInfo: data,
    };

    if (data.requestingType === "dry-weather-screen") {
      defaults.incidentType = data.incidentType || "illicit-flow";
      defaults.reachedStormDrain = true;
    }

    return defaults;
  };

  var getPrecipitationTypeOptions = function () {
    return FormSettings.getDropdownOptions("precipitation-type");
  };

  var getIncidentTypeOptions = function () {
    return FormSettings.getDropdownOptions("incident-type", { throwIfUndefined: false });
  };

  var getFilterIncidentTypeOptions = function () {
    const options = getIncidentTypeOptions();
    return [...options, { name: "Unknown", value: "unknown" }, { name: "No Data", value: "null" }];
  };

  var getIllicitFlowDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("illicit-flow-description");
  };

  var getIllicitFlowMaterialActivityOptions = function () {
    const options = FormSettings.getDropdownOptions("illicit-flow-material-activity");
    Misc.pushOptionToEndOfArray(options, "other");
    return options;
  };

  var getSpillDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("spill-description");
  };

  var getIllegalDumpingDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("illegal-dumping-description");
  };

  var getIllicitActivityOptions = function () {
    const illegalDumpingDescriptionOptions = getIllegalDumpingDescriptionOptions() || [];
    const illicitFlowMaterialActivityOptions = getIllicitFlowMaterialActivityOptions() || [];

    return illicitFlowMaterialActivityOptions.concat(illegalDumpingDescriptionOptions);
  };

  var getIncidentTypeLocationOptions = function () {
    return FormSettings.getDropdownOptions("incident-type-location");
  };

  var getCleanLevelRequiredOptions = function () {
    return FormSettings.getDropdownOptions("clean-level-required");
  };

  var getCleanJurisdictionOptions = function () {
    return FormSettings.getDropdownOptions("clean-jurisdiction");
  };

  var getEnforcementLevelOptions = function () {
    return FormSettings.getDropdownOptions("enforcement-level");
  };

  var getCloseOutReasonOptions = function () {
    return FormSettings.getDropdownOptions("close-out-reason");
  };

  var getFollowUpActionOptions = function () {
    return FormSettings.getDropdownOptions("follow-up-action");
  };

  var getIllicitSizeOptions1 = function () {
    const illicitSizeOptions1 = [
      {
        name: "cu-yds",
        value: "cubic-yards",
      },
    ];
    return illicitSizeOptions1;
  };

  var getIllicitSizeOptions2 = function () {
    const illicitSizeOptions2 = [
      {
        name: "cu-ft",
        value: "cubic-feet",
      },
      {
        name: "gallons",
        value: "gallons",
      },
    ];
    return illicitSizeOptions2;
  };

  var addIncidentOpenToDoDisplayNames = function (allData) {
    for (const data of allData) {
      data.incidentTypeDisplayName = getIncidentTypeOptions().getObjectWith(
        "value",
        data.incidentType,
      )?.name;
      data.illicitActivityDisplayName = getIllicitActivityOptions().getObjectWith(
        "value",
        data.illicitActivity,
      )?.name;
    }

    return allData;
  };

  var canAccessIncidentInvestigations = function () {
    return ToolSettings.getSetting("permissions", "can_create_incident_investigation");
  };

  return {
    getNewIncidentInvestigationDefaults,
    addIncidentOpenToDoDisplayNames,
    getIncidentTypeLocationOptions,
    getCleanJurisdictionOptions,
    getFollowUpActionOptions,
    getPrecipitationTypeOptions,
    getIncidentTypeOptions,
    getFilterIncidentTypeOptions,
    getIllicitFlowDescriptionOptions,
    getIllicitFlowMaterialActivityOptions,
    getSpillDescriptionOptions,
    getIllegalDumpingDescriptionOptions,
    getCleanLevelRequiredOptions,
    getEnforcementLevelOptions,
    getCloseOutReasonOptions,
    getIllicitSizeOptions1,
    getIllicitSizeOptions2,
    getIllicitActivityOptions,
    canAccessIncidentInvestigations,
  };
};

module.exports = IncidentInvestigationConstants();

const Session = require("../../login/session");
const DateTime = require("../../dateTime");
const FormSettings = require("../../settings/formSettings");
const Misc = require("../../misc.js");
const ToolSettings = require("../../settings/toolSettings");
