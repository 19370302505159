"use strict";

const ProjectTreatmentTypes = function () {
  var treatmentTypesByStage;

  var prepareTreatmentTypeData = function (
    renderData,
    changingTreatmentTypeDropdownIndex = null,
    formKey = "project-inventory",
  ) {
    renderData.formKey = formKey;
    if (!renderData.stage) {
      renderData.stage = ProjectStageData.getStageByPhase(renderData.phase);
    }
    addInitialTreatmentTypeData(renderData);
    filterDeletedData(renderData);
    updateRenderData(renderData, changingTreatmentTypeDropdownIndex);
    filterByStageData(renderData);
    addDropdownOptions(renderData);
    addBmpSummaries(renderData);
    _sortTreatmentTypes(renderData);
  };

  var addInitialTreatmentTypeData = function (renderData) {
    if (!Array.isArray(renderData.treatmentTypes)) {
      renderData.treatmentTypes = [];
    }
  };

  var filterDeletedData = function (renderData) {
    renderData.treatmentTypes = renderData.treatmentTypes
      .map(function (treatmentType, index) {
        if (treatmentType.index === undefined) {
          treatmentType.index = index;
        }
        return treatmentType;
      })
      .filter(function (treatmentType) {
        return treatmentType.deleted !== true;
      });
  };

  var filterByStageData = function (renderData) {
    if (!Array.isArray(renderData.treatmentTypes)) {
      return;
    }

    treatmentTypesByStage = {};
    const treatmentTypes = renderData.treatmentTypes;

    for (const stage of ["planning", "design", "certified"]) {
      const stageTreatmentTypes = treatmentTypes.filter(function (treatmentType) {
        return treatmentType.stage === stage;
      });

      treatmentTypesByStage[stage] = stageTreatmentTypes;

      if (renderData.stage === stage) {
        renderData.treatmentTypes = stageTreatmentTypes;
      }
    }
  };

  var saveNewRow = function (renderData, defaultData = null) {
    addInitialTreatmentTypeData(renderData, false);
    const unfilteredTreatmentTypesLength = renderData.treatmentTypes.length;

    prepareTreatmentTypeData(renderData);

    var treatmentTypes = renderData.treatmentTypes;
    if (!treatmentTypes) {
      treatmentTypes = [];
    }

    var newType = defaultData || getFirstEnabledDefaultData(renderData);
    newType.stage = renderData.stage;
    newType.index = unfilteredTreatmentTypesLength;
    treatmentTypes.push(newType);

    var formData = $.extend(true, {}, newType);
    delete formData.firstType;
    delete formData.secondType;
    delete formData.index;
    ProjectInventoryModalController.setFormDataField(
      ["treatmentTypes", unfilteredTreatmentTypesLength],
      formData,
    );
  };

  var getFirstEnabledDefaultData = function (renderData) {
    const typeOptions =
      renderData.firstTypeOptions || ProjectInventoryConstants.getTreatmentTypeFirstTypeOptions();
    const firstEnabledOption = typeOptions.find(function (option) {
      return isOptionEnabled(option, renderData.treatmentTypes, renderData.phase);
    });

    var defaultData;
    if (firstEnabledOption.defaultData) {
      defaultData = firstEnabledOption.defaultData;
    } else if (firstEnabledOption.subTypeOptions) {
      defaultData = firstEnabledOption.subTypeOptions.defaultData;
    } else {
      defaultData = { type: firstEnabledOption.value };
    }
    return defaultData;
  };

  var addDropdownOptions = function (renderData) {
    var firstTypeOptions = ProjectInventoryConstants.getTreatmentTypeFirstTypeOptions();

    disableDropdownOptions(firstTypeOptions, renderData);
    renderData.firstTypeOptions = firstTypeOptions;
  };

  var disableDropdownOptions = function (allOptions, renderData) {
    const treatmentTypes = renderData.treatmentTypes;
    var numOptionsEnabled = allOptions.length;

    for (const option of allOptions) {
      const isEnabled = isOptionEnabled(option, treatmentTypes, renderData.phase);

      if (!isEnabled) {
        option.enabled = isEnabled;
      }

      if (option.enabled === false) {
        numOptionsEnabled--;
      }
    }

    renderData.treatmentTypeAddButtonEnabled = numOptionsEnabled > 0;
  };

  var isOptionEnabled = function (option, treatmentTypes, phase) {
    const disabledInPhase = option.disabledInPhases && option.disabledInPhases.includes(phase);

    if (isReadOnlyPhase(phase) || disabledInPhase) {
      return false;
    }

    var enabled = true;

    if (treatmentTypes.length >= 2 && option.disableMultiple) {
      const optionHasTreatmentType = treatmentTypes.find(function (treatmentType) {
        return treatmentType.firstType === option.value;
      });
      enabled = !optionHasTreatmentType;
    }

    return enabled;
  };

  var updateRenderData = function (renderData, changingTreatmentTypeDropdownIndex) {
    const treatmentTypes = renderData.treatmentTypes;
    renderData.isBmpSync = isBmpSyncPhase(renderData.phase);
    renderData.isTreatmentTypeReadOnly = isReadOnlyPhase(renderData.phase);

    for (const data of treatmentTypes) {
      convertSingleTypeToMultitype(data);
      updateDataByType(data, renderData, changingTreatmentTypeDropdownIndex);
      updateDataByStage(data, renderData);
    }
  };

  var convertSingleTypeToMultitype = function (data) {
    const multiType = getMultiType(data.type);

    if (multiType) {
      $.extend(data, multiType);
    } else {
      const singleType = getSingleTypeOptions(data.type);
      $.extend(data, singleType);
    }
  };

  var getSingleTypeOptions = function (type) {
    for (const option of ProjectInventoryConstants.getTreatmentTypeFirstTypeOptions()) {
      const key = option.value;

      if (type === key) {
        const data = $.extend(true, {}, option);
        data.firstType = key;

        return data;
      }
    }

    throw new Error(`No configuration found for ${type}.`);
  };

  var updateDataByType = function (treatmentType, renderData, changingTreatmentTypeDropdownIndex) {
    const treatmentTypeFirstTypeFunctionMap = {
      "bmp-type": updateBmpTypeData,
      "environmental-site-design": updateEnvironmentalSiteDesignData,
      "outfall-protection": updateFootprintWithTotalArea,
      "riparian-restoration": updateFootprintWithTotalArea,
    };

    const treatmentTypeUpdateFunction = treatmentTypeFirstTypeFunctionMap[treatmentType.firstType];
    if (treatmentTypeUpdateFunction) {
      treatmentTypeUpdateFunction(treatmentType, renderData, changingTreatmentTypeDropdownIndex);
    }
  };

  var updateDataByStage = function (treatmentType, renderData) {
    const fieldsByPhase = treatmentType.readOnlyFieldsByPhase;

    if (renderData.isTreatmentTypeReadOnly) {
      treatmentType.readOnlyFields = ProjectInventoryConstants.allTreatmentTypeFields;
      setCustomAssetName(treatmentType);
    } else if (fieldsByPhase && fieldsByPhase[renderData.phase]) {
      treatmentType.readOnlyFields = treatmentType.readOnlyFields.concat(
        fieldsByPhase[renderData.phase],
      );
    }
  };

  var updateBmpTypeData = function (treatmentType, renderData) {
    setBmpTypeCount(treatmentType, renderData.bmps, renderData.isBmpSync);
    if (renderData.isBmpSync) {
      addBmpData(treatmentType, renderData.bmps);
      setCustomAssetName(treatmentType);
      setFieldsFromBmp(treatmentType);
    }
  };

  var setBmpTypeCount = function (treatmentType, bmps, isBmpSync) {
    var bmpCount = 1;

    if (!isBmpSync) {
      const subTypeBmpType = parseInt(treatmentType.subType);

      bmpCount = bmps.filter(function (bmp) {
        const bmpTypeId = parseInt(bmp.bmpType) || bmp.bmpTypeObj.id;
        return bmpTypeId === subTypeBmpType;
      }).length;
    }

    treatmentType.count = bmpCount === 0 ? null : bmpCount;
  };

  var addBmpData = function (treatmentType, bmps) {
    treatmentType.bmp = getAssociatedBmp(treatmentType, bmps);
  };

  var getCustomBmpAssetName = function (treatmentType) {
    const bmp = treatmentType.bmp;
    const bmpName = bmp.bmpName || bmp.bmpId;
    const bmpTypeAbbreviation = FormConstants.bmpTypeAbbrs[treatmentType.subType];

    return `${bmpName} (${bmpTypeAbbreviation})`;
  };

  var setCustomAssetName = function (treatmentType) {
    if (treatmentType.bmp) {
      treatmentType.customAssetName = getCustomBmpAssetName(treatmentType);
    }
  };

  var setFieldsFromBmp = function (treatmentType) {
    if (!treatmentType.bmp) {
      return;
    }

    const bmpCubicFeetAcreFeet = UnitConversion.cubicFeetToAcreFeet(
      treatmentType.bmp.treatmentCapacity,
    );
    treatmentType.capacityAcreFeet = isNaN(bmpCubicFeetAcreFeet) ? null : bmpCubicFeetAcreFeet;

    treatmentType.treatedImperviousAreaAcres = treatmentType.bmp.imperviousAreaAcres;
    treatmentType.footprintSquareFeet = treatmentType.bmp.footprintSqft;
  };

  var updateEnvironmentalSiteDesignData = function (
    treatmentType,
    renderData,
    changingTreatmentTypeDropdownIndex,
  ) {
    const treatmentTypeSubTypeFunctionMap = {
      "runoff-neutral": updateFootprintWithTotalArea,
      "impervious-removal": updateFootprintWithImperviousArea,
    };

    const updateFunction = treatmentTypeSubTypeFunctionMap[treatmentType.subType];
    if (updateFunction) {
      updateFunction(treatmentType, renderData, changingTreatmentTypeDropdownIndex);
    }
  };

  var updateFootprintWithTotalArea = function (treatmentType, renderData) {
    if (renderData.formKey === "project-inventory") {
      const projectAreaSquareFeet = UnitConversion.acresToSquareFeet(
        ProjectInventoryAreaSelection.getTotalAreaAcres(),
      );

      treatmentType.footprintSquareFeet = projectAreaSquareFeet;
    }
  };

  var updateFootprintWithImperviousArea = function (
    treatmentType,
    renderData,
    changingTreatmentTypeDropdownIndex,
  ) {
    const priorityInfo = renderData.priorityInfo;
    const isEmptyOrChangingType =
      (treatmentType.footprintSquareFeet !== undefined ||
        changingTreatmentTypeDropdownIndex !== null) &&
      treatmentType.index !== changingTreatmentTypeDropdownIndex;
    if (isEmptyOrChangingType) {
      return;
    }

    const preArea = priorityInfo.preImperviousArea
      ? UnitConversion.convertVal(
          priorityInfo.preImperviousArea,
          priorityInfo.preImperviousAreaUnits,
          "squareFeet",
        )
      : 0;
    const postArea = priorityInfo.postImperviousArea
      ? UnitConversion.convertVal(
          priorityInfo.postImperviousArea,
          priorityInfo.postImperviousAreaUnits,
          "squareFeet",
        )
      : 0;
    const footprintSquareFeet = preArea - postArea;

    treatmentType.footprintSquareFeet = footprintSquareFeet;
    ProjectInventoryModalController.setFormDataField(
      ["treatmentTypes", treatmentType.index, "footprintSquareFeet"],
      footprintSquareFeet,
    );
  };

  var updateType = function (renderData, index) {
    const treatmentType = renderData.treatmentTypes[index];
    const firstType = treatmentType.firstType;
    const subType = treatmentType.subType;
    const multiType = getMultiType(firstType || treatmentType.type);

    if (!firstType || multiType) {
      if (multiType.emptyFieldsOnChange) {
        emptyTypeNumbers(treatmentType, index);
      }
      if (subType) {
        ProjectInventoryModalController.setFormDataField(
          ["treatmentTypes", index, "type"],
          `${multiType.firstType}-${subType}`,
        );
      } else {
        const defaultData = getMultiType(multiType.firstType).defaultData;
        if (defaultData) {
          defaultData.stage = ProjectStageData.getStageByPhase(renderData.phase);
          renderData.treatmentTypes[index] = defaultData;
          ProjectInventoryModalController.setFormDataField(["treatmentTypes", index], defaultData);
        }
      }
    } else {
      ProjectInventoryModalController.setFormDataField(
        ["treatmentTypes", index, "type"],
        firstType,
      );
      emptyTypeNumbers(treatmentType, index);
    }

    if (firstType) {
      ProjectInventoryModalController.unsetFormDataField(["treatmentTypes", index, "firstType"]);
    }
    if (subType) {
      ProjectInventoryModalController.unsetFormDataField(["treatmentTypes", index, "subType"]);
    }
  };

  var emptyTypeNumbers = function (treatmentType, index) {
    const fieldsToEmpty = [
      "count",
      "capacityAcreFeet",
      "treatedImperviousAreaAcres",
      "footprintSquareFeet",
      "lengthFeet",
    ];

    for (const field of fieldsToEmpty) {
      if (treatmentType[field] !== null && treatmentType[field] !== undefined) {
        ProjectInventoryModalController.setFormDataField(["treatmentTypes", index, field], null);
      }
    }
  };

  var getMultiType = function (type) {
    if (!type) {
      return null;
    }

    for (const option of ProjectInventoryConstants.getTreatmentTypeFirstTypeOptions()) {
      const key = option.value;
      const subTypeOptions = option.subTypeOptions;

      if (subTypeOptions && type.startsWith(key)) {
        const data = $.extend(true, {}, subTypeOptions);
        data.subType = type.substring(key.length + 1);
        data.firstType = key;
        addTypeOptionFields(data, subTypeOptions.typeOptions, data.subType);

        return data;
      }
    }

    return null;
  };

  var addTypeOptionFields = function (data, typeOptions, key) {
    const typeOption = typeOptions.find(function (option) {
      return option.value == key;
    });

    if (typeOption) {
      $.extend(data, typeOption);
    }
  };

  var deleteExistingTreatmentTypeRow = function (treatmentType, index) {
    treatmentType.deleted = true;

    if (treatmentType.id === undefined) {
      ProjectInventoryModalController.unsetFormDataField(["treatmentTypes", index]);
    } else {
      ProjectInventoryModalController.setFormDataField(["treatmentTypes", index, "deleted"], true);
    }
  };

  var addByBmp = function (bmp) {
    const renderData = InventoryModal.getAllData("project-inventory");

    if (!isBmpSyncPhase(renderData.phase)) {
      return;
    }

    const defaultData = getSingleTypeOptions("bmp-type").subTypeOptions.defaultData;

    defaultData.type = getBmpTypeFromBmp(bmp);
    defaultData.associatedAsset = bmp.id;

    saveNewRow(renderData, defaultData);
  };

  var getBmpTypeFromBmp = function (bmp) {
    const type = bmp.bmpType || bmp.bmpTypeObj.id || bmp.bmpTypeObj.sortOrder;
    return `bmp-type-${type}`;
  };

  var isBmpSyncPhase = function (phase) {
    const isSyncStage = SharedDataFunctions.isPhaseOrLater(phase, "planned");
    const isProjectDelivery = Tree.get("dataView") === "construction-project-delivery";
    return isSyncStage && isProjectDelivery;
  };

  var isBmpSummaryStage = function (stage) {
    return stage === "design";
  };

  var isReadOnlyPhase = function (phase) {
    return SharedDataFunctions.isPhaseOrLater(phase, "completed");
  };

  var deleteByBmp = function (bmp) {
    const renderData = InventoryModal.getAllData("project-inventory");
    prepareTreatmentTypeData(renderData);

    if (!isBmpSyncPhase(renderData.phase)) {
      return;
    }

    const treatmentTypes = renderData.treatmentTypes;
    const indices = findIndicesByBmp(treatmentTypes, bmp);
    deleteExistingTreatmentTypeRow(treatmentTypes[indices.localIndex], indices.formIndex);

    treatmentTypes.splice(indices.localIndex, 1);
  };

  var getAssociatedBmp = function (treatmentType, bmps) {
    const bmp = bmps.find(function (bmp) {
      return isAssociatedBmp(treatmentType, bmp);
    });

    return bmp;
  };

  var findIndicesByBmp = function (treatmentTypes, bmp) {
    var localIndex = treatmentTypes.findIndex(function (treatmentType) {
      return isAssociatedBmp(treatmentType, bmp);
    });

    if (localIndex === -1) {
      throw new Error(`No treatment type found for bmp "${bmp.bmpName}".`);
    }
    const formIndex = treatmentTypes[localIndex].index;

    return { localIndex, formIndex };
  };

  var isAssociatedBmp = function (treatmentType, bmp) {
    if (
      treatmentType.firstType !== "bmp-type" ||
      treatmentType.isSummary ||
      treatmentType.associatedAsset === undefined
    ) {
      return false;
    }

    return (
      treatmentType.associatedAsset === bmp.id ||
      treatmentType.associatedAsset === bmp.bmpName ||
      treatmentType.associatedAsset === bmp.bmpId
    );
  };

  var updateTypeByBmp = function (oldBmp, newBmp) {
    const renderData = InventoryModal.getAllData("project-inventory");
    prepareTreatmentTypeData(renderData);

    if (!isBmpSyncPhase(renderData.phase)) {
      return;
    }

    const treatmentTypes = renderData.treatmentTypes;
    const indices = findIndicesByBmp(treatmentTypes, oldBmp);

    ProjectInventoryModalController.setFormDataField(
      ["treatmentTypes", indices.formIndex, "type"],
      getBmpTypeFromBmp(newBmp),
    );

    if (newBmp.isTemp && bmpHasName(newBmp)) {
      ProjectInventoryModalController.setFormDataField(
        ["treatmentTypes", indices.formIndex, "associatedAsset"],
        newBmp.bmpName,
      );
    } else if (newBmp.isTemp) {
      ProjectInventoryModalController.setFormDataField(
        ["treatmentTypes", indices.formIndex, "associatedAsset"],
        newBmp.id,
      );
    }
  };

  var bmpHasName = function (bmp) {
    return bmp.bmpName !== undefined && bmp.bmpName !== "";
  };

  var _sortTreatmentTypes = function (renderData) {
    if (isBmpSyncPhase(renderData.phase)) {
      sortByBmps(renderData);
    }
  };

  var sortByBmps = function (renderData) {
    renderData.treatmentTypes = renderData.treatmentTypes.sort(function (a, b) {
      const aIsBmpType = a.firstType === "bmp-type";
      const bIsBmpType = b.firstType === "bmp-type";

      var sort = sortTrueFirst(aIsBmpType, bIsBmpType);

      if (sort === 0 && (aIsBmpType || bIsBmpType)) {
        sort = a.subType - b.subType;
      }

      if (sort === 0) {
        sort = sortTrueFirst(!a.isSummary, !b.isSummary);
      }

      return sort === 0 ? a.index - b.index : sort;
    });
  };

  var sortTrueFirst = function (aIsTrue, bIsTrue) {
    if (aIsTrue !== bIsTrue) {
      return aIsTrue ? -1 : 1;
    }

    return 0;
  };

  var addBmpSummaries = function (renderData) {
    if (!isBmpSummaryStage(renderData.stage)) {
      return;
    }

    addBmpSummaryRows(renderData);
  };

  var addBmpSummaryRows = function (renderData) {
    const designSummaryRows = _sumTreatmentTypeDataBySubType(renderData.treatmentTypes);
    const planningSummaryRows = _sumTreatmentTypeDataBySubType(treatmentTypesByStage.planning);

    _setPlannedSummaryRows(designSummaryRows, planningSummaryRows);

    for (const subType in designSummaryRows) {
      const summary = designSummaryRows[subType];
      if (summary.firstType === "bmp-type") {
        renderData.treatmentTypes.push(summary);
      }
    }
  };

  var _getNewSummaryRow = function (treatmentType = {}) {
    const subTypeOptions = (treatmentType.typeOptions || []).find(function (subType) {
      return subType.value == treatmentType.subType;
    });
    return {
      isSummary: true,
      firstType: treatmentType.firstType,
      subType: treatmentType.subType,
      customAssetName: subTypeOptions ? subTypeOptions.name : treatmentType.customAssetName,
      readOnlyFields: ProjectInventoryConstants.allTreatmentTypeFields,
      enabledFields: [
        "firstType",
        "subType",
        "count",
        "capacityAcreFeet",
        "treatedImperviousAreaAcres",
        "footprintSquareFeet",
      ],
      count: 0,
      capacityAcreFeet: 0,
      treatedImperviousAreaAcres: 0,
      footprintSquareFeet: 0,
      index: new String(treatmentType.index).endsWith("-summary")
        ? treatmentType.index
        : `${treatmentType.index}-summary`,
    };
  };

  var _sumTreatmentTypeDataBySubType = function (treatmentTypes) {
    const sumBySubType = {};

    for (const treatmentType of treatmentTypes) {
      if (!sumBySubType[treatmentType.subType]) {
        sumBySubType[treatmentType.subType] = _getNewSummaryRow(treatmentType);
      }

      const sum = sumBySubType[treatmentType.subType];
      sum.count += +treatmentType.count || 1;
      sum.capacityAcreFeet += +treatmentType.capacityAcreFeet || 0;
      sum.treatedImperviousAreaAcres += +treatmentType.treatedImperviousAreaAcres || 0;
      sum.footprintSquareFeet += +treatmentType.footprintSquareFeet || 0;
    }

    return sumBySubType;
  };

  var _setPlannedSummaryRows = function (designSummaryRows, planningSummaryRows) {
    for (const subType in planningSummaryRows) {
      const plannedRow = planningSummaryRows[subType];

      if (designSummaryRows[subType] === undefined) {
        designSummaryRows[subType] = _getNewSummaryRow(plannedRow);
      }

      designSummaryRows[subType].planned = plannedRow;
    }

    for (const subType in designSummaryRows) {
      const designRow = designSummaryRows[subType];

      if (designRow.planned === undefined) {
        designRow.planned = _getNewSummaryRow(designRow);
      }
    }
  };

  var prepareTreatmentTypeUploadData = function (projectToUpload, allData) {
    if (projectToUpload.treatmentTypes !== undefined) {
      addTreatmentTypeIds(projectToUpload, allData);
      removeDeletedUnsavedTreatmentTypes(projectToUpload);
    }
  };

  var addTreatmentTypeIds = function (projectToUpload, allData) {
    const allTreatmentTypes = allData.treatmentTypes;
    const treatmentTypes = projectToUpload.treatmentTypes;

    treatmentTypes.forEach(function (treatmentType, index) {
      const existingTreatmentType = (allTreatmentTypes || {})[index];
      if (existingTreatmentType && existingTreatmentType.id !== undefined) {
        treatmentTypes[index].id = existingTreatmentType.id;
      }
    });
  };

  var removeDeletedUnsavedTreatmentTypes = function (projectToUpload) {
    projectToUpload.treatmentTypes = projectToUpload.treatmentTypes.filter(
      function (treatmentType) {
        const isDeleted = treatmentType.deleted === true;
        const isUnsaved = treatmentType.id === undefined;

        return !(isDeleted && isUnsaved);
      },
    );
  };

  return {
    prepareTreatmentTypeData,
    saveNewRow,
    updateType,
    filterByStageData,
    addDropdownOptions,
    deleteExistingTreatmentTypeRow,
    addByBmp,
    deleteByBmp,
    updateTypeByBmp,
    prepareTreatmentTypeUploadData,
    _sortTreatmentTypes,
    _sumTreatmentTypeDataBySubType,
    _setPlannedSummaryRows,
    _getNewSummaryRow,
  };
};

module.exports = ProjectTreatmentTypes();

const FormConstants = require("../mapFunctions/formConstants");
const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryAreaSelection = require("./projectInventoryAreaSelection");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const ProjectStageData = require("./projectStageData");
const SharedDataFunctions = require("../general/sharedDataFunctions");
const Tree = require("../tree");
const UnitConversion = require("../unitConversion");
