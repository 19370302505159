"use strict";

const PlanPropertiesGeoServerPopup = function () {
  var getPopupHtml = function (properties) {
    properties.totalCount = PlanPropertiesGeoServerLayer.getTotalPropertiesCount();
    return PopupContent.getLayerPopup(properties, "planProperties");
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    var onPopupDelete = function () {
      deleteProperty(data.id, data.name, invalidateLayerDataFunction);
    };

    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);
  };

  var deleteProperty = function (id, name, invalidateLayerDataFunction) {
    const popupCloseButn = $(".leaflet-popup-close-button");
    MessageModal.showConfirmWarningModal(
      `Delete Property ${name}? Click "Delete" to permanently delete the Property. Click "Cancel" to return.`,
      async function () {
        // await ApiCalls.deleteCleanout(id);
        invalidateLayerDataFunction();
        _closePopup(popupCloseButn);
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "cleanouts");
  };

  var _closePopup = function (popupCloseButn) {
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
  };

  var onPopupOpen = async function (properties, event = null) {
    var layerName = "planProperties";
    const layerModule = LayerFunctions.getLayerModule(layerName);
    const currentTab = Tree.get("filters", "type");
    const id = properties.id;
    const property = LayerDataFunctions.getLayerDataAndIndexById(layerName, id);
    const color = PropertiesIcon.getClassAndColor(property.data.esg_property_symbol).color;
    var data = await getPopupData(id);
    data.color = color;

    data.planId = properties.planId;
    data.planSlug = properties.plan_slug ? properties.plan_slug : properties.planSlug;
    data.currentTab = currentTab;
    data.id = id;
    const html = getPopupHtml(data);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  var getPopupData = async function (id) {
    const popupData = await EsgApiCalls.getPropertyPopup(id);
    return {
      ...popupData,
      title: EsgSettings.propertyTitleDisplay(popupData),
    };
  };

  return {
    getPopupHtml,
    onPopupOpen,
  };
};

module.exports = PlanPropertiesGeoServerPopup();

const LayerFunctions = require("../../layerFunctions");
const PopupContent = require("../../mapFunctions/popupContent");
const MessageModal = require("../../modals/messageModal");
const Analytics = require("../../general/analytics");
const EsgApiCalls = require("../esgApiCalls");
const PlanPropertiesGeoServerLayer = require("./planPropertiesGeoServerLayer");
const PropertiesIcon = require("../icons/propertiesIcon");
const Tree = require("../../tree");
const LayerDataFunctions = require("../../general/layerDataFunctions");
const EsgSettings = require("../esgSettings");
