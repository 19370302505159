"use strict";

const UnitsPage = function () {
  const stringKey = "units";
  const headerInformation = "Units";

  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    let data = PropertyInventoryModalController.getAllData();
    data = _getPreparedData(data);

    await PropertyInventoryModalController.renderPageContent(
      nunjucks.render("esg/modals/propertyInventory/unitsPage.njk", data),
    );
    CollapsingCard.initializeCards($("#inventory-modal"));
    _loadListeners();
    _updateUnitsCount();
  };

  var _loadListeners = function () {
    Form.initializeAndLoadListeners($page, PropertyInventoryModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("input", "[name*=unitIdentifier]", _updateUnitIdentifier);
    $page.on("input", "[name*=consumptiveUse]", _updateConsumptiveUse);
    $page.on("input", "[name*=address], [name*=city]", _updateCardStatus);
    $page.on("change", "[name*=ownershipStatus], [name*=state]", _updateCardStatus);
    $page.on("input, change", "[name*=units]", _addUnitId);
    $page.on("click", ".add-unit", _addUnit);
    $page.on("click", ".delete-button", _deleteUnit);
  };

  var _unloadListeners = function () {
    $page.off("input", "[name*=unitIdentifier]", _updateUnitIdentifier);
    $page.off("input", "[name*=consumptiveUse]", _updateConsumptiveUse);
    $page.off("input", "[name*=address], [name*=city]", _updateCardStatus);
    $page.off("change", "[name*=ownershipStatus], [name*=state]", _updateCardStatus);
    $page.off("input, change", "[name*=units]", _addUnitId);
    $page.off("click", ".add-unit", _addUnit);
    $page.off("click", ".delete-button", _deleteUnit);
  };

  var _updateUnitIdentifier = function () {
    const $this = $(this);
    const $card = $this.closest(".card");
    const deleteIcon = $card.find(".card-header span")[0].outerHTML;
    $card.find(".card-header").html($this.val() + deleteIcon);
  };

  var _updateConsumptiveUse = function () {
    const $this = $(this);
    const $card = $this.closest(".card");
    const index = $card.data("index");
    const newValue = $this.val() * 1e6;
    PropertyInventoryModalController.setData(["units", index, "consumptiveUse"], newValue);
  };

  var _addUnit = function () {
    const index = $(".card").length;
    const newCard = nunjucks.render("esg/modals/propertyInventory/_newUnit.njk", {
      unit: {},
      index,
      dropdownOptions: PageController.getDropdownOptions(),
      hiddenFields: EsgSettings.hiddenFields(),
    });
    $("#units").append(newCard);
    const $newCard = $("#inventory-modal .card:last");
    CollapsingCard.initializeCard($newCard);
    Form.initializeAutoNumericFields($newCard);
    Form.initializeYearInputs($newCard);
    Form.initializeDatePickers($newCard);

    const $modalBody = $("#inventory-modal .modal-body");
    $modalBody.animate({ scrollTop: $modalBody[0].scrollHeight }, 500);

    _updateUnitsCount();
    PropertyInventoryModalController.setData(["units", index, "unitIdentifier"], "");
  };

  var _updateCardStatus = function () {
    const $card = $(this).closest(".card");
    const index = $card.data("index");
    const unit = PropertyInventoryModalController.getAllData().units?.[index];
    const statusText = _getStatusText(unit);
    $card.data("custom-status-text", statusText);
  };

  var _addUnitId = function () {
    const $this = $(this);
    const $card = $this.closest(".card");
    const index = $card.data("index");
    const id = $card.data("id");
    if (!id) return;
    PropertyInventoryModalController.setData(["units", index], {
      ...PropertyInventoryModalController.getLatestData(["units", index]),
      id,
    });
  };

  var _deleteUnit = function () {
    const $this = $(this);
    const id = $this.data("id");
    const $card = $this.closest(".card");
    $card.addClass("hidden");
    const index = $card.data("index");
    PropertyInventoryModalController.setData(["units", index], { id, deleted: true });
    _updateUnitsCount();
  };

  var _updateUnitsCount = function () {
    const count = $(".card:not(.hidden)").length;
    $(".units-count span").text(count);
    $(".no-units").toggleClass("hidden", count > 0);
  };

  var _getPreparedData = function (data) {
    return {
      ...data,
      hiddenFields: EsgSettings.hiddenFields(),
      units: data?.units?.map(function (unit) {
        return {
          ...unit,
          statusText: _getStatusText(unit),
        };
      }),
    };
  };

  var _getStatusText = function (unit) {
    const ownershipConstants = PageController.getConstants("ownershipStatus");
    const addressText = [unit.address, unit.city, NunjucksFilters.getStateAbbreviation(unit.state)]
      .filter(Boolean)
      .join(", ");
    const statusText = [ownershipConstants[unit.ownershipStatus], addressText]
      .filter(Boolean)
      .join(" - ");
    return statusText;
  };

  var validate = function () {
    return true;
  };

  var cleanUp = function () {
    _unloadListeners();
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
  };
};

module.exports = UnitsPage();

const CollapsingCard = require("../../../general/collapsingCard");
const PropertyInventoryModalController = require("../propertyInventoryModalController");
const Form = require("../../../general/form");
const PageController = require("../../pageController");
const NunjucksFilters = require("../../../general/nunjucksFilters");
const EsgSettings = require("../../esgSettings");
