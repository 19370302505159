"use strict";

const SpatialViewController = function () {
  var init = function () {
    loadListeners();
  };

  var loadListeners = function () {
    Tree.select("spatialView").on("update", spatialViewUpdated);

    $(".ms4-view-button").off("click");
    $(".ms4-view-button").on("click", goToMs4View);
  };

  var goToView = function (viewType, catchName, rw, gid) {
    Table.resetTableHighlight();
    if (viewType === "drainage") {
      goToDrainageView(catchName, rw);
    } else {
      goToCatchmentView(catchName, rw, gid);
    }
  };

  var goToCatchmentView = function (catchName, rw, gid) {
    Tree.set("spatialView", "catchment");
    Tree.set(["filters", "spatialView"], "catchment");
    Tree.set(["filters", "catchments"], [catchName]);
    Tree.set(["filters", "receivingWaters"], []);
    Tree.set(["filters", "spatialGroupingId"], null);
    Tree.set(["selected", "catchments"], catchName);
    Tree.set(["selected", "rwDrainsTo"], rw);
    Tree.set(["selected", "urbanDrainage"], null);
    Tree.set(["selected", "receivingWater"], null);
    Tree.set(["selected", "watershed"], null);
    Tree.set(["selected", "spatialGrouping"], null);
    setDataSortBySpatialView("catchment");

    setTimeout(function () {
      $("#mapContent").find(".catchments-only h3.selected").removeClass("selected");
      $("#mapContent")
        .find("h3[data-catchid='" + catchName[0] + "']")
        .addClass("selected");
      Table.scrollHighlightTable(gid);
    }, 0);

    MainMap.closePopups();
    MainMap.zoomToCatchmentView();
  };

  var goToDrainageView = function (catchName, rw) {
    Tree.set("spatialView", "drainage");
    Tree.set(["filters", "spatialView"], "drainage");
    Tree.set(["filters", "receivingWaters"], [catchName]);
    Tree.set(["filters", "catchments"], []);
    Tree.set(["filters", "spatialGroupingId"], null);
    Tree.set(["selected", "urbanDrainage"], catchName);
    Tree.set(["selected", "rwDrainsTo"], rw);
    Tree.set(["selected", "receivingWater"], null);
    Tree.set(["selected", "watershed"], null);
    Tree.set(["selected", "spatialGrouping"], null);
    setDataSortBySpatialView("drainage");

    setTimeout(function () {
      const $drainsToParentDiv = $("#mapContent")
        .find("h3[data-id='" + catchName[0] + "']")
        .addClass("open")
        .closest(".drains-to-parent-div");

      $drainsToParentDiv.addClass("active selected");
      $drainsToParentDiv.find(".expand-arrow").addClass("open");
      $drainsToParentDiv.find(".inputs-table .collapse").collapse("show");

      $("#mapContent").find("li.catch-selected").removeClass("catch-selected");
    }, 0);

    MainMap.closePopups();
    MainMap.zoomToDrainageView();
  };

  var goToMs4View = function (e) {
    Tree.set("spatialView", "ms4");
    Tree.set(["filters", "spatialView"], "ms4");
    Filters.resetSpatialFilters();
    setDataSortBySpatialView("ms4");
    Table.resetTableHighlight();
    MainMap.zoomToMs4View();
  };

  var spatialViewUpdated = function (e) {
    var spatialView = e.data.currentData;
    handleSpatialViewLayers(spatialView);
    Filters.handleFilterMenuDisplayBySpatialView(spatialView);
    handleAllMs4ButtonDisplay(spatialView);
    handleDataSortDropdownDisplay(spatialView);
  };

  var handleSpatialViewLayers = function (spatialView) {
    if (isCatchmentOrDrainageView(spatialView)) {
      const dataViewConfig = ToolFunctions.getCurrentDataViewConfig();
      if (dataViewConfig && dataViewConfig.catchmentViewDefaultEnabledLayers) {
        const layers = dataViewConfig.catchmentViewDefaultEnabledLayers;
        layers.forEach((layer) => {
          DisplayOptions.handleLayerDisplay(layer, true);
        });
      }
    }
  };

  var setDataSortBySpatialView = function (spatialView) {
    // @TODO: refactor to use Filters.setDataSort()
    if (isCatchmentOrDrainageView(spatialView)) {
      var dataViewConfig = ToolFunctions.getCurrentDataViewConfig();
      if (dataViewConfig && dataViewConfig.catchmentViewDefaultDataSort) {
        Filters.forceDataSort(dataViewConfig.catchmentViewDefaultDataSort);
      } else {
        console.error("No catchment view default dataSort");
      }
    } else {
      Filters.forceDataSort("rw");
    }
  };

  var handleDataSortDropdownDisplay = function (spatialView) {
    var catchmentView = isCatchmentOrDrainageView(spatialView);
    var $sortDropdown = $(".sort.dropdown-menu");
    $sortDropdown.find(".catchment-view-only").toggleClass("disabled", !catchmentView);
    $sortDropdown.find(".catchment-view-disabled").toggleClass("disabled", catchmentView);
  };

  var handleAllMs4ButtonDisplay = function (spatialView) {
    var showAllMs4Button = isCatchmentOrDrainageView(spatialView);
    $(".ms4-view-button").toggle(showAllMs4Button);
    MapFunctions.setMapHeight();
  };

  var isCatchmentOrDrainageView = function (spatialView) {
    if (!spatialView) {
      spatialView = Tree.get("spatialView");
    }
    return spatialView === "catchment" || spatialView === "drainage";
  };

  var isDataOfCatchmentOrDrainageView = function (layer) {
    var dataSpatialView = Tree.get(["layers", layer, "dataSpatialView"]);
    return isCatchmentOrDrainageView(dataSpatialView);
  };

  return {
    init,
    goToView,
    goToMs4View,
    isCatchmentOrDrainageView,
    isDataOfCatchmentOrDrainageView,
  };
};

module.exports = SpatialViewController();

const Tree = require("../tree");
const Table = require("./table");
const Filters = require("./filters");
const ToolFunctions = require("../toolFunctions");
const MapFunctions = require("./mapFunctions");
const DisplayOptions = require("./displayOptions");
const MainMap = require("./mainMap");
