"use strict";

function IndcomToDoList() {
  function organizeFacilityInspections(data) {
    return data.map(function (datum) {
      const dueDate = DateTime.parseIsoDate(datum.indcomFacilityInspection.dueDate);

      return {
        id: datum.id,
        tableIcon: datum.tableIcon,
        facilityIdentifier: datum.facilityIdentifier,
        name: datum.facilityName,
        fullAddress: Misc.getFullMailingAddress(datum.address, datum.city, datum.state, datum.zip),
        displayResponsiblePartyManagement: Misc.getOptionNameByValue(
          IndcomInventoryConstants.responsiblePartyOptions,
          datum.responsiblePartyManagement,
        ),
        displayFacilityType: datum.displayFacilityType,
        displayOwnerContact: _getDisplayFirstContactWithRole(datum.contacts, "primary-contact"),
        displayInspectionType: _getDisplayInspectionType(
          datum.indcomFacilityInspection.additionalInfo,
        ),
        displayDueDate: DateTime.dateToGroupDisplayDate(dueDate),
        dueDate,
        isPastDue: dueDate < new Date(),
        canInspectFacility: datum.canInspectFacility,
      };
    });
  }

  function organizeFacilityFindings(data) {
    return data.map(function (datum) {
      const dueDate = datum.indcomFacilityFinding.dueDate
        ? DateTime.parseIsoDate(datum.indcomFacilityFinding.dueDate)
        : null;

      return {
        id: datum.id,
        tableIcon: datum.tableIcon,
        facilityIdentifier: datum.facilityIdentifier,
        name: datum.facilityName,
        fullAddress: Misc.getFullMailingAddress(datum.address, datum.city, datum.state, datum.zip),
        displayResponsiblePartyManagement: Misc.getOptionNameByValue(
          IndcomInventoryConstants.responsiblePartyOptions,
          datum.responsiblePartyManagement,
        ),
        displayFacilityType: datum.displayFacilityType,
        displayFindings: datum.indcomFacilityFinding.additionalInfo.displayFindings,
        displayFollowUpInspectionType: Misc.getOptionNameByValue(
          FormSettings.getDropdownOptions("follow-up-inspection-type"),
          datum.indcomFacilityFinding.additionalInfo.followUpInspectionType,
        ),
        selfInspectionStatus: datum.indcomFacilityFinding.additionalInfo.selfInspectionStatus,
        displayDueDate: dueDate ? DateTime.dateToGroupDisplayDate(dueDate) : dueDate,
        dueDate,
        isPastDue: dueDate ? dueDate < new Date() : false,
        canInspectFacility: datum.canInspectFacility,
      };
    });
  }

  function organizeFacilityEnforcements(data) {
    const result = [];

    for (const datum of data) {
      for (const enforcement of datum.indcomFacilityEnforcement.additionalInfo) {
        const issueDate = enforcement.issueDate
          ? DateTime.parseIsoDate(enforcement.issueDate)
          : null;

        result.push({
          id: datum.id,
          enforcementId: enforcement.id,
          tableIcon: datum.tableIcon,
          facilityIdentifier: datum.facilityIdentifier,
          name: datum.facilityName,
          fullAddress: Misc.getFullMailingAddress(
            datum.address,
            datum.city,
            datum.state,
            datum.zip,
          ),
          displayResponsiblePartyManagement: Misc.getOptionNameByValue(
            IndcomInventoryConstants.responsiblePartyOptions,
            datum.responsiblePartyManagement,
          ),
          displayFacilityType: datum.displayFacilityType,
          displayEnforcementReason: Misc.getOptionNameByValue(
            EnforcementAction.getEnforcementReasonOptions(),
            enforcement.currentEscalation?.enforcementReason,
          ),
          displayLevel: Misc.getOptionNameByValue(
            FormSettings.getDropdownOptions("level"),
            enforcement.currentEscalation?.level,
          ),
          fine: enforcement.currentEscalation?.fine,
          issueDate: issueDate,
          displayIssueDate: issueDate ? DateTime.dateToGroupDisplayDate(issueDate) : issueDate,
          canIssueEnforcement: datum.canIssueEnforcement,
        });
      }
    }

    return result;
  }

  function organizeFacilityInventoryIncomplete(data) {
    return data.map(function (datum) {
      return {
        id: datum.id,
        tableIcon: datum.tableIcon,
        facilityIdentifier: datum.facilityIdentifier,
        name: datum.facilityName,
        fullAddress: Misc.getFullMailingAddress(datum.address, datum.city, datum.state, datum.zip),
        displayResponsiblePartyManagement: Misc.getOptionNameByValue(
          IndcomInventoryConstants.responsiblePartyOptions,
          datum.responsiblePartyManagement,
        ),
        displayFacilityType: datum.displayFacilityType,
        displayInventoryIncomplete: datum.indcomFacilityInventoryIncomplete.additionalInfo
          .map(_getRequiredFieldDisplayName)
          .sort(SortUtilities.alphaSorter),
        canEditFacility: canEditFacility(),
      };
    });
  }

  function canInspectFacility() {
    return ToolSettings.getSetting("permissions", "can_inspect_facility");
  }

  function canEnforceFacility() {
    return ToolSettings.getSetting("permissions", "can_issue_facility_enforcement");
  }

  function canEditFacility() {
    return ToolSettings.getSetting("permissions", "can_edit_facility");
  }

  function getSortersDict() {
    const dateSorter = DateTime.dateComparator;
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    const alphaArrayQuantitySort = (a, b) => SortUtilities.arrayQuantitySort(a, b, alphaSorter);

    return {
      name: alphaSorter,
      facilityIdentifier: alphaSorter,
      fullAddress: alphaSorter,
      displayResponsiblePartyManagement: alphaSorter,
      displayFacilityType: alphaSorter,
      displayOwnerContact: alphaSorter,
      displayInspectionType: alphaSorter,
      dueDate: dateSorter,
      displayFindings: alphaArrayQuantitySort,
      displayFollowUpInspectionType: alphaSorter,
      displayEnforcementReason: alphaSorter,
      displayLevel: alphaSorter,
      fine: numberSorter,
      issueDate: dateSorter,
      displayInventoryIncomplete: alphaArrayQuantitySort,
    };
  }

  function loadListeners($container) {
    $container
      .off("click", ".add-facility-inspection", _onAddFacilityInspection)
      .on("click", ".add-facility-inspection", _onAddFacilityInspection)
      .off("click", ".add-facility-self-inspection", _onAddFacilitySelfInspection)
      .on("click", ".add-facility-self-inspection", _onAddFacilitySelfInspection)
      .off("click", ".show-facility-inventory", _onShowFacilityInventory)
      .on("click", ".show-facility-inventory", _onShowFacilityInventory);
  }

  function _onAddFacilityInspection() {
    const id = $(this).parents("tr").data("id");

    FacilityInspectionController.loadNewFacilityInspection(id);
  }

  function _onAddFacilitySelfInspection() {
    const id = $(this).parents("tr").data("id");

    FacilitySelfInspectionController.loadNew(id);
  }

  function _onShowFacilityInventory() {
    const id = $(this).parents("tr").data("id");

    IndcomInventoryModalController.invokeIndcomInventoryModal(id);
  }

  function _getDisplayFirstContactWithRole(contacts, role) {
    const owner = contacts.find((contact) => contact.displayRole === role);

    if (!owner) {
      return null;
    }

    const displayParts = [];

    if (owner.name) {
      displayParts.push(owner.name);
    }

    if (owner.phoneNumber) {
      displayParts.push(`(${owner.phoneNumber})`);
    }

    return displayParts.join(" ");
  }

  function _getDisplayInspectionType(additionalInfo) {
    if (additionalInfo?.followUpInspectionType) {
      return "Follow Up";
    }

    return "Routine";
  }

  function _getRequiredFieldDisplayName(requiredField) {
    return Misc.getOptionNameByValue(IndcomInventoryConstants.requiredFieldOptions, requiredField);
  }

  return {
    organizeFacilityInspections,
    organizeFacilityFindings,
    organizeFacilityEnforcements,
    organizeFacilityInventoryIncomplete,
    canInspectFacility,
    canEnforceFacility,
    canEditFacility,
    getSortersDict,
    loadListeners,
  };
}

module.exports = IndcomToDoList();

const DateTime = require("../dateTime");
const SortUtilities = require("../general/sortUtilities");
const Misc = require("../misc");
const FormSettings = require("../settings/formSettings");
const ToolSettings = require("../settings/toolSettings");
const EnforcementAction = require("./facilityEnforcement/enforcementAction");
const FacilityInspectionController = require("./facilityInspection/facilityInspectionController");
const FacilitySelfInspectionController = require("./facilitySelfInspection/facilitySelfInspectionController");
const IndcomInventoryConstants = require("./indcomInventoryConstants");
const IndcomInventoryModalController = require("./indcomInventoryModalController");
