"use strict";

const ConstructionSettings = function () {
  const stringKey = "construction-settings";
  const tabHeader = "Construction";

  var loadListeners = function (data) {};

  var unloadListeners = function () {};

  var getProps = function (data) {
    return {
      durationOptions: [
        { name: "Day", value: "day" },
        { name: "Week", value: "week" },
        { name: "Month", value: "month" },
        { name: "Season", value: "season" },
        { name: "null", value: "" },
      ],
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = ConstructionSettings();
