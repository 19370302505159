"use strict";

var RunoffProgressLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadColumnArrayUpdatedListener(mapId);
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "runoffProgress",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        var runoffLayer = mapLayers.runoffProgressLayer;
        var forceRefresh = !sameSpatialFilter || !sameView || !sameGroup;
        if (isEnabled) {
          TelrLayerFunctions.displayTelrLayer(
            runoffLayer,
            "runoffProgress",
            "runoffReduction",
            map,
            mapLayers,
            forceRefresh,
          );
        } else {
          if (MapFunctions.mapHasLayer(map, runoffLayer)) {
            map.removeLayer(runoffLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "runoffProgress", function (data) {
      mapLayers.runoffProgressLayer = addRunoffProgressLayer(
        map,
        mapLayers.runoffProgressLayer,
        data,
      );
      TelrLayerFunctions.selectTelrCatchmentsByFilter(mapLayers.runoffProgressLayer);
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var addRunoffProgressLayer = function (map, runoffProgressLayer, layerData) {
    if (runoffProgressLayer) {
      map.removeLayer(runoffProgressLayer);
    }
    runoffProgressLayer = new (Layers.getLayers().RunoffLayer)(layerData).addTo(map);
    return runoffProgressLayer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {};

  var loadColumnArrayUpdatedListener = function (mapId) {
    Tree.select(["layers", mapId, "runoffProgress", "columnArray"]).on("update", function (e) {
      var columns = e.data.currentData;
      DataViewDropdown.updateLegendColumns(columns, "runoff-progress", true);
    });
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = RunoffProgressLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const Table = require("../mapFunctions/table");
const Layers = require("../mapFunctions/layers");
const TelrLayerFunctions = require("./telrLayerFunctions");
const DataViewDropdown = require("../mapFunctions/dataViewDropdown");
