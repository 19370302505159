"use strict";

const ProjectInspectionConstants = function () {
  var getConstants = function (key) {
    return ToolSettings.getSetting("construction", "project-inspection-constants")?.[key];
  };

  var getInspectionTypeOptions = function () {
    let options = [
      {
        name: "Pre-Disturbance",
        value: "pre-disturbance",
      },
      {
        name: "Routine",
        value: "routine",
      },
      {
        name: "Pre-Storm",
        value: "pre-storm",
      },
      {
        name: "Post-Storm",
        value: "post-storm",
      },
      {
        name: "Follow Up",
        value: "follow-up",
      },
      {
        name: "Close-Out",
        value: "close-out",
      },
    ];

    if (ToolSettings.getSetting("construction", "is-findings-workflow")) {
      options = options
        .filter((option) => option.value !== "pre-storm")
        .concat([
          {
            name: "Winter Conditions",
            value: "winter-conditions",
          },
        ]);
    }

    return options;
  };

  var getPrecipitationNowOptions = function () {
    return [
      {
        name: "No Precipitation",
        value: "none",
      },
      {
        name: "Light Precipitation",
        value: "some",
      },
      {
        name: "Heavy Precipitation",
        value: "heavy",
      },
      {
        name: "Snow Covering Entire Site",
        value: "snow",
      },
    ];
  };

  var getRequiredObservationOptions = function () {
    // These keys are kebab-case on the backend, but because Form
    // converts everything to camelCase anyway, these need to also
    // be camelCase to match.
    return [
      {
        name: "Illicit Connection/Illicit Discharge Detection and Reporting",
        value: "illicitDischargeDetection",
      },
      {
        name: "Preservation of Existing Vegetation and Property",
        value: "preserveVegetation",
      },
      { name: "Street Sweeping", value: "streetSweeping" },
      { name: "Drainage Inlet Protection", value: "inletProtection" },
      { name: "Stabilized Entrance/Exit", value: "stabilizedEntrance" },
      { name: "Stabilized Construction Roadway", value: "stabilizedRoadway" },
      { name: "Material Delivery and Storage", value: "materialStorage" },
      { name: "Material Use", value: "materialUse" },
      { name: "Stockpile Management", value: "stockpileManagement" },
      { name: "Spill and Prevention Control", value: "spillPrevention" },
      { name: "Solid Waste Management", value: "solidWasteManagement" },
      { name: "Concrete Waste Management", value: "concreteWasteManagement" },
      { name: "Sanitary/Septic Waste Management", value: "septicWasteManagement" },
    ];
  };

  var getRequiredObservationConditionOptions = function () {
    return [
      { name: "No Issues (Score = 1.0)", value: "10" },
      { name: "Minor Issues (Score = 0.8)", value: "8" },
      { name: "Significant Issues (Score = 0.5)", value: "5" },
      { name: "Failing/Absent/Wrong Location (Score = 0.0)", value: "0" },
    ];
  };

  var getAdditionalObservationPurposeOptions = function () {
    return getConstants("purposeOptions");
  };

  var getObservationFindingPurposeOptions = function () {
    return getConstants("purposeOptions");
  };

  var getAdditionalObservationPracticeOptions = function () {
    return getConstants("additionalObservationOptions") ?? [];
  };

  var getAdditionalCorrectiveActionOptions = function () {
    return [
      { value: "active-treatment", name: "Active Treatment Systems - SE-11" },
      { value: "failed-closeout", name: "Failed Closeout Inspection" },
      { value: "missing-monitoring-records", name: "Inadequate/Missing Monitoring Records" },
      { value: "missing-training-logs", name: "Inadequate/Missing Training Logs" },
      { value: "missing-swppp-document", name: "Missing Updated SWPPP Documentation" },
      { value: "scheduling", name: "Scheduling - EC-1" },
      { value: "batch-plant", name: "Temporary Batch Plant - NS-16" },
    ];
  };

  var getAdministrativeFindingsOptions = function () {
    return getConstants("administrativeFindings") ?? {};
  };

  var getObservationFindings = function () {
    return getConstants("observationFindings") ?? {};
  };

  var getAdministrativeFindingPracticeOptions = function () {
    const practiceOptions = [];
    const findings = getConstants("administrativeFindings") ?? {};

    for (const practice in findings) {
      practiceOptions.push({ value: practice, name: findings[practice].displayName });
    }

    return practiceOptions;
  };

  var getAdministrativeFindingByPractice = function (practice) {
    return getConstants("administrativeFindings")?.[practice];
  };

  var getObservationFindingByPractice = function (practice) {
    return getObservationFindings()?.[practice];
  };

  var getFindingSeverityOptions = function () {
    return getConstants("findingSeverityOptions") ?? [];
  };

  var getNewProjectInspectionDefaults = function (project) {
    var inspectionData = { project };
    inspectionData.inspectorName = Session.getUser()["full_name"];
    inspectionData.inspectionDate = DateTime.getTodayIso();
    inspectionData.requiredObservations = getDefaultRequiredObservations();
    inspectionData.protectedFromConstruction = "n-a";
    inspectionData.additionalObservations = {};
    inspectionData.administrativeFindings = {};
    inspectionData.observationFindings = {};
    inspectionData.signatures = {
      inspectorName: inspectionData.inspectorName,
      inspectorDate: DateTime.getTodayIso(),
      siteRepDate: DateTime.getTodayIso(),
    };
    inspectionData.locked = false;

    return inspectionData;
  };

  var getDefaultRequiredObservations = function () {
    return getRequiredObservationOptions().mapToObject((option) => option.value, []);
  };

  var organizeProjectInspectionData = function (data) {
    addRequiredObservations(data);
    addAdditionalObservations(data);
    addAdministrativeFindings(data);
    addObservationFindings(data);
    addFollowupInspection(data);

    delete data.temporaryBmpInspections;

    return data;
  };

  var addCorrectiveActionInfo = function (temporaryBmpInspections) {
    const correctiveActionInfo = getConstants("correctiveActionInfo");
    if (!correctiveActionInfo) {
      return;
    }

    for (const key in temporaryBmpInspections) {
      for (const tbmp of temporaryBmpInspections[key]) {
        if (correctiveActionInfo[key]) {
          tbmp.correctiveActionInfo = correctiveActionInfo[key];
        }
      }
    }
  };

  var addRequiredObservations = function (inspectionData) {
    if (!inspectionData.requiredObservations) {
      inspectionData.requiredObservations = filterByAllOptions(
        inspectionData.temporaryBmpInspections,
        getDefaultRequiredObservations(),
      );
    }
    inspectionData.requiredObservations = addMissingRequiredObservations(
      inspectionData.requiredObservations,
    );
    inspectionData.requiredObservations = indexObservations(inspectionData.requiredObservations);
  };

  var addAdditionalObservations = function (inspectionData) {
    if (!inspectionData.additionalObservations) {
      inspectionData.additionalObservations = filterByAllOptions(
        inspectionData.temporaryBmpInspections,
        getAdditionalObservationPracticeOptionsObject(),
      );
    }
    inspectionData.additionalObservations = indexObservations(
      inspectionData.additionalObservations,
    );
  };

  var addAdministrativeFindings = function (inspectionData) {
    if (!inspectionData.administrativeFindings) {
      inspectionData.administrativeFindings = filterByAllOptions(
        inspectionData.temporaryBmpInspections,
        getAdministrativeFindingsOptions(),
      );
    }
    inspectionData.administrativeFindings = indexObservations(
      inspectionData.administrativeFindings,
    );
  };

  var addObservationFindings = function (inspectionData) {
    if (!inspectionData.observationFindings) {
      inspectionData.observationFindings = filterByAllOptions(
        inspectionData.temporaryBmpInspections,
        getObservationFindings(),
      );
    }
    inspectionData.observationFindings = indexObservations(inspectionData.observationFindings);

    for (const practice in inspectionData.observationFindings) {
      addFindingCitations(practice, inspectionData.observationFindings[practice]);
    }
  };

  var addFindingCitations = function (practice, findings) {
    for (const finding of findings) {
      if (finding.issueType) {
        finding.citation = getFindingIssueCitation(practice, finding.issueType);
      }
    }
  };

  var getFindingIssueCitation = function (practice, issueType) {
    const issueTypes = getObservationFindings()[practice]["issueTypes"] ?? [];
    const issue = issueTypes.find((issue) => issue.value === issueType);

    return issue?.citation;
  };

  var addFollowupInspection = function (data) {
    if (data.id) {
      data.followupInspection = !!data.followupInspectionDueDate;
    } else {
      data.followupInspection = null;
    }
  };

  var filterByAllOptions = function (temporaryBmpInspections, allOptionsObject) {
    const requiredObservations = {};

    for (const key in temporaryBmpInspections) {
      if (allOptionsObject[key]) {
        requiredObservations[key] = temporaryBmpInspections[key];
      }
    }

    return requiredObservations;
  };

  var indexObservations = function (observations) {
    for (const observationName in observations) {
      indexObservationArray(observations[observationName]);
    }

    return observations;
  };

  var indexObservationArray = function (observationArray) {
    let lastDisplayNumber = observationArray.reduce(
      (num, obs) => Math.max(num, obs.displayNumber ?? 0),
      0,
    );

    observationArray.forEach(function (observation, i) {
      observation.index = i;
      if (!observation.displayNumber) {
        observation.displayNumber = lastDisplayNumber + 1;
      }
      lastDisplayNumber = observation.displayNumber;
    });
  };

  var addMissingRequiredObservations = function (requiredObservations) {
    const allRequiredObservations = getDefaultRequiredObservations();
    return $.extend(allRequiredObservations, requiredObservations);
  };

  var getAdditionalObservationPracticeOptionsByPurpose = function (purpose) {
    return getAdditionalObservationPracticeOptions().filter((option) => option.purpose === purpose);
  };

  var getAdditionalObservationPracticeOptionsObject = function () {
    return getAdditionalObservationPracticeOptions().mapToObject(
      (option) => option.value,
      (option) => option,
    );
  };

  var getObservationFindingPracticeOptionsByPurpose = function (purpose) {
    const result = [];
    const findings = getObservationFindings();

    for (const key in findings) {
      const data = findings[key];

      if (data.purpose === purpose) {
        result.push({
          name: data.displayName,
          purpose: data.purpose,
          value: key,
        });
      }
    }

    return result;
  };

  return {
    getInspectionTypeOptions,
    getPrecipitationNowOptions,
    getNewProjectInspectionDefaults,
    getRequiredObservationOptions,
    getRequiredObservationConditionOptions,
    getAdministrativeFindingPracticeOptions,
    organizeProjectInspectionData,
    getAdditionalObservationPurposeOptions,
    getAdditionalObservationPracticeOptionsObject,
    getAdditionalObservationPracticeOptionsByPurpose,
    getAdditionalCorrectiveActionOptions,
    getAdministrativeFindingByPractice,
    getFindingSeverityOptions,
    getAdministrativeFindingsOptions,
    getObservationFindingPurposeOptions,
    getObservationFindingPracticeOptionsByPurpose,
    getObservationFindingByPractice,
    getObservationFindings,
    getFindingIssueCitation,
    addCorrectiveActionInfo,
    indexObservationArray,
  };
};

module.exports = ProjectInspectionConstants();

const DateTime = require("../../dateTime");
const Session = require("../../login/session");
const ToolSettings = require("../../settings/toolSettings");
