"use strict";

class DataStoreError extends Error {
  constructor(message) {
    super(message);
    this.name = "DataStoreError";
  }
}

module.exports = DataStoreError;
