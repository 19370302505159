"use strict";

const ConstructionProjectPopup = function () {
  var currentPopupProps = null;

  var loadDomListeners = function () {
    $(".map-content").off("click", ".popup-history-button");
    $(".map-content").on("click", ".popup-history-button", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-construction-history").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-history-button").show();
    $parent.find(".popup-construction-main").show();
  };

  var popupHistoryButtonClick = function () {
    var projectId = $(this).parents(".popup-general").data("id");
    showInspectionHistory(projectId);
  };

  var showInspectionHistory = function (projectId) {
    ReportApiCalls.getConstructionProjectHistory(projectId, function (inspections) {
      const props = getInspectionHistoryProps(inspections);
      $(".popup-construction-history").html(
        nunjucks.render(
          Session.isCdot()
            ? "report/popups/constructionInspectionHistoryCdot.njk"
            : "report/popups/constructionInspectionHistoryMs4.njk",
          props,
        ),
      );
      $(".popup-construction-main").hide();
      $(".popup-history-button").hide();
      $(".popup-construction-history").show();
      $(".popup-back-button").show();
    });
  };

  var getConstructionProjectPopupHtml = async function (constructionProjectData) {
    const popupData = await ReportApiCalls.getProjectPopup(constructionProjectData.id);
    return nunjucks.render(
      Session.isCdot()
        ? "report/popups/constructionProjectPopupCdot.njk"
        : "report/popups/constructionProjectPopupMs4.njk",
      getConstructionPopupProps(popupData),
    );
  };

  var getConstructionPopupProps = function (constructionProjectData) {
    const props = {};
    props.id = constructionProjectData.id;
    props.subtitle = getPopupSubtitle(constructionProjectData);
    props.title = constructionProjectData.projectIdentifier;
    props.projectName = constructionProjectData.projectName;
    props.displayAreaOfDisturbance = (
      constructionProjectData.priorityInfo || {}
    ).displayAreaOfDisturbance;
    props.priority = constructionProjectData.highPriority ? "Yes" : "No";
    props.highPriority = constructionProjectData.highPriority;
    props.streetAddress = constructionProjectData.streetAddressShort;
    props.phase = constructionProjectData.phase;
    props.implementerType = constructionProjectData.implementerType;
    props.maintainerType = constructionProjectData.maintainerType;
    props.headerColorClass = ConstructionProjectStyles.getHeaderColorClass(constructionProjectData);

    props.enforcementLevel = constructionProjectData.enforcementLevel
      ? constructionProjectData.enforcementLevel
      : "None";
    props.enforcementDaysOpen = constructionProjectData.enforcementDaysOpen;
    props.averageEnforcementDaysOpen = constructionProjectData.averageEnforcementDaysOpen;
    props.averageScore = constructionProjectData.averageScore;
    props.enforcementActionsIssued = constructionProjectData.enforcementActionsIssued;
    props.totalInspections = constructionProjectData.inspections.length;

    currentPopupProps = props;
    return props;
  };

  var getPopupSubtitle = function (constructionProjectData) {
    var subtitle = "";

    if (constructionProjectData.highPriority) {
      subtitle += "Priority";
    }

    if (constructionProjectData.hasUnresolvedEnforcementAction) {
      if (subtitle !== "") subtitle += ", ";
      subtitle += "Open Enforcement";
    }
    return subtitle;
  };

  var getInspectionHistoryProps = function (inspections) {
    const props = {};
    props.inspections = inspections;
    props.enforcementCount = currentPopupProps.enforcementActionsIssued;
    props.averageScore = currentPopupProps.averageScore;
    return props;
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();
    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  return {
    loadDomListeners,
    getConstructionProjectPopupHtml,
    getConstructionPopupProps,
    showInspectionHistory,
    getPopupOptions,
  };
};

module.exports = ConstructionProjectPopup();

const ReportApiCalls = require("../reportApiCalls");
const Session = require("../../login/session");
const ConstructionProjectStyles = require("./constructionProjectStyles");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
