"use strict";

const BmpFcsFunctions = function () {
  var isValidBmpTypeNumber = function (bmpTypeNumber) {
    return !!FormConstants.bmpTypes[bmpTypeNumber];
  };

  var isValidBmpTypeName = function (bmpTypeName) {
    var bmpTypes = FormConstants.bmpTypes;
    for (const key in bmpTypes) {
      if (bmpTypes[key].toUpperCase() === String(bmpTypeName).trim().toUpperCase()) {
        return true;
      }
    }
    return false;
  };

  var isValidBmpTypeAbbr = function (bmpTypeAbbr) {
    var bmpAbbrs = FormConstants.bmpTypeAbbrs;
    for (const key in bmpAbbrs) {
      if (bmpAbbrs[key].toUpperCase() === String(bmpTypeAbbr).trim().toUpperCase()) {
        return true;
      }
    }
    return false;
  };

  var isBmpOnlyType = function (bmpTypeNumber) {
    // @TODO: Make this function take any bmpType input (number, name, abbr)
    if (!isValidBmpTypeNumber(bmpTypeNumber)) {
      throw `Invalid input for isBmpOnlyType: ${bmpTypeNumber}.`;
    }
    return FormConstants.bmpOnlyTypeNumbers.includes(parseInt(bmpTypeNumber));
  };

  var isFcsOnlyType = function (bmpTypeNumber) {
    // @TODO: Make this function take any bmpType input (number, name, abbr)
    if (!isValidBmpTypeNumber(bmpTypeNumber)) {
      throw `Invalid input for isFcsOnlyType: ${bmpTypeNumber}.`;
    }
    return FormConstants.fcsOnlyTypeNumbers.includes(parseInt(bmpTypeNumber));
  };

  var isCentralizedBMPType = function (bmpTypeNumber) {
    return FormConstants.centralizedBmpTypeNumbers.includes(parseInt(bmpTypeNumber));
  };

  var isDecentralizedBMPType = function (bmpTypeNumber) {
    if (!bmpTypeNumber || bmpTypeNumber == 16) {
      return false;
    }

    return !isCentralizedBMPType(bmpTypeNumber);
  };

  var isPriorityCatchBasinType = function (bmpTypeNumber) {
    if (!isValidBmpTypeNumber(bmpTypeNumber)) {
      throw `Invalid input for isPriorityCatchBasinType: ${bmpTypeNumber}.`;
    }
    return FormConstants.priorityCatchBasinTypeNumbers.includes(parseInt(bmpTypeNumber));
  };

  var getBmpTypeAbbr = function (bmpTypeNumber) {
    if (!FormConstants.bmpTypeAbbrs[bmpTypeNumber]) {
      throw `Invalid input for getBmpTypeAbbr: ${bmpTypeNumber}.`;
    }
    return FormConstants.bmpTypeAbbrs[parseInt(bmpTypeNumber)];
  };

  var getBmpTypeNameByNumber = function (bmpTypeNumber) {
    if (!isValidBmpTypeNumber(bmpTypeNumber)) {
      throw `Invalid input for getBmpTypeNameByNumber: ${bmpTypeNumber}.`;
    }
    return FormConstants.bmpTypes[parseInt(bmpTypeNumber)];
  };

  var getBmpTypeNumberByName = function (bmpTypeName) {
    if (!bmpTypeName || typeof bmpTypeName !== "string") {
      throw "Invalid input for getBmpTypeNumberByName";
    }
    var bmpTypes = FormConstants.bmpTypes;
    for (const key in bmpTypes) {
      if (bmpTypes[key].toUpperCase() === bmpTypeName.toUpperCase()) {
        return parseInt(key);
      }
    }
    throw `BMP name not found: ${bmpTypeName}.`;
  };

  var getBmpTypeNumberByAbbr = function (bmpTypeAbbr) {
    if (!bmpTypeAbbr || typeof bmpTypeAbbr !== "string") {
      throw "Invalid input for getBmpTypeNumberByName";
    }
    var bmpTypeAbbrs = FormConstants.bmpTypeAbbrs;
    for (const key in bmpTypeAbbrs) {
      if (bmpTypeAbbrs[key].toUpperCase() === bmpTypeAbbr.toUpperCase()) {
        return parseInt(key);
      }
    }
    throw `BMP abbreviation not found: ${bmpTypeAbbr}.`;
  };

  var getBmpTypeNumber = function (bmpType) {
    if (isValidBmpTypeNumber(bmpType)) {
      return parseInt(bmpType);
    } else if (isValidBmpTypeName(bmpType)) {
      return getBmpTypeNumberByName(bmpType);
    } else if (isValidBmpTypeAbbr(bmpType)) {
      return getBmpTypeNumberByAbbr(bmpType);
    }
    throw "Invalid bmpType for getBmpTypeNumber";
  };

  var getBmpTypeSelectOptions = function (excludeFcsOnly = false) {
    var bmpTypes = FormConstants.bmpTypes;
    var bmpAbbrs = FormConstants.bmpTypeAbbr;
    var options = [];
    var excludeTypes = [16];

    if (excludeFcsOnly) {
      excludeTypes = excludeTypes.concat(FormConstants.fcsOnlyTypeNumbers);
    }

    for (const key in bmpTypes) {
      if (!excludeTypes.includes(parseInt(key))) {
        options.push({ name: `(${bmpAbbrs[key]}) ${bmpTypes[key]}`, value: key });
      }
    }
    options.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    options.unshift({ name: "Select Type", value: 16 });
    return options;
  };

  var getActiveBmpFcsLayer = function () {
    if (Tree.get("layers", "fcs", "isEnabled")) {
      return ["fcs", "fullDrainageFcs"];
    }
    if (Tree.get("layers", "bmps", "isEnabled")) {
      return ["bmps", "fullDrainageBmp"];
    }
    return [null, null];
  };

  var canEditBmpFcs = function (id, phase) {
    const canEdit = ToolSettings.getSetting("permissions", "can_edit_bmps");

    const permissionByContactIdbmps = ToolSettings.getSetting(
      "permissions",
      "permission_by_project_contact_idbmps",
    );

    if (!canEdit || !canEditByPhase(phase)) {
      return false;
    } else if (permissionByContactIdbmps) {
      return permissionByContactIdbmps.includes(id);
    }

    return true;
  };

  var canEditByPhase = function (phase) {
    const restrictedPhases = ToolSettings.getSetting(
      "permissions",
      "can_edit_bmps_restricted_phases",
    );

    if (!restrictedPhases) {
      return true;
    }

    return restrictedPhases.includes(phase);
  };

  function getAssetDisplayName(bmpType) {
    if (!bmpType || bmpType == 16) {
      return "Asset";
    } else if (isPriorityCatchBasinType(bmpType)) {
      return ToolSettings.getSetting("constants", "assetDisplayNames", "catch-basin");
    } else if (isFcsOnlyType(bmpType) || Tree.get("tool") === "trashram") {
      return ToolSettings.getSetting("constants", "assetDisplayNames", "fcs");
    } else {
      return ToolSettings.getSetting("constants", "assetDisplayNames", "bmp");
    }
  }

  return {
    isValidBmpTypeNumber,
    isValidBmpTypeName,
    isValidBmpTypeAbbr,
    isBmpOnlyType,
    isFcsOnlyType,
    isCentralizedBMPType,
    isDecentralizedBMPType,
    isPriorityCatchBasinType,
    getBmpTypeAbbr,
    getBmpTypeNameByNumber,
    getBmpTypeNumberByName,
    getBmpTypeNumberByAbbr,
    getBmpTypeNumber,
    getBmpTypeSelectOptions,
    getActiveBmpFcsLayer,
    canEditBmpFcs,
    getAssetDisplayName,
  };
};

module.exports = BmpFcsFunctions();

const FormConstants = require("../mapFunctions/formConstants");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
