"use strict";

const DryWeatherLayer = function (
  layerName,
  layerKey,
  getDataFunction,
  getPopupDataFunction,
  clusteringEnabled = false,
  itemCountEnabled = false,
  useFilters = true,
  { onPopupOpenFunction } = {},
) {
  const DryWeatherFunctions = require("./dryWeatherFunctions");
  const MapLayer = require("../mapFunctions/mapLayer");

  var createDryWeatherLayerMarker = function (latlng, data) {
    return DryWeatherIcon.getDryWeatherMarker(
      latlng,
      data,
      MapFunctions.resizeIconsOnZoom(
        Tree.get("zoomLevel"),
        40,
        20,
        "icon-text-large",
        "icon-text-medium",
      ),
    );
  };

  var createIcon = function (data, zoom) {
    return DryWeatherIcon.getDryWeatherIcon(
      data,
      MapFunctions.resizeIconsOnZoom(zoom, 40, 20, "icon-text-large", "icon-text-medium"),
    );
  };

  var onPopupOpen = function (data, $element) {
    DryWeatherFunctions.loadPopupListeners(data, $element);

    var onPopupDelete = function () {
      deleteOutfall(data.id, data.name);
    };
    $element.off("click", ".popup-delete-button", onPopupDelete);
    $element.on("click", ".popup-delete-button", onPopupDelete);

    DryWeatherHistory.renderDryWeatherHistory(data.history, data.assetType, data.id);
    onPopupOpenFunction?.(data, $element);
  };

  var deleteOutfall = function (id, name) {
    MessageModal.showConfirmWarningModal(
      `Delete Outfall ${name}? Click "Delete" to permanently delete the Outfall. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteOutfall(id);
        dryWeatherLayer.invalidateLayerData();
      },
      "Cancel",
      "Delete",
    );
  };

  const dryWeatherLayer = new MapLayer(layerName, layerKey, getDataFunction, {
    createMarkerFunction: createDryWeatherLayerMarker,
    getPopupHtmlFunction: DryWeatherFunctions.getDryWeatherLayerPopupHtml,
    getPopupDataFunction: getPopupDataFunction,
    onPopupOpenFunction: onPopupOpen,
    getFiltersFunction: useFilters ? null : () => {},
    clusteringEnabled: clusteringEnabled,
    itemCountEnabled: itemCountEnabled,
    createIcon: createIcon,
  });

  return {
    ...dryWeatherLayer,
  };
};

module.exports = DryWeatherLayer;

const ApiCalls = require("../apiCalls");
const DryWeatherHistory = require("./dryWeatherHistory");
const DryWeatherIcon = require("./dryWeatherIcon");
const MessageModal = require("../modals/messageModal");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
