"use strict";

var SummaryConstants = function () {
  var getPermitPrefixText = function (dataView) {
    const permitSettings = PermitSettings.getPermitSettingsByDataView(dataView);

    var prefix = null;
    if (permitSettings) {
      prefix = permitSettings.permitPrefix;
    }
    if (prefix) {
      return "Section " + prefix + " of";
    } else {
      return "";
    }
  };

  var getGroupShortName = function () {
    let shortName = "the group";

    if (Session.groupNameContains("city")) {
      shortName = "the City";
    } else if (Session.groupNameContains("county")) {
      shortName = "the County";
    } else if (Session.groupNameContains("town")) {
      shortName = "the Town";
    } else if (Session.isCdot()) {
      shortName = "CDOT";
    }

    return shortName;
  };

  return {
    getPermitPrefixText,
    getGroupShortName,
  };
};

module.exports = SummaryConstants();

const Session = require("../../login/session");
const PermitSettings = require("../settings/permitSettings");
