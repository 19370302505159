"use strict";

const IncidentInvestigationFlow = function () {
  const stringKey = "flow";
  const tabHeader = "Flow";
  let $measurementsPage, $samplePage;

  var loadListeners = function (data) {
    const MeasurementsTab = new Measurements(IncidentInvestigationController);
    $measurementsPage = $(`#assessment-modal #measurements`);
    MeasurementsTab.setPage($measurementsPage);
    MeasurementsTab.loadListeners(data);

    const SampleTab = new Sample(IncidentInvestigationController);
    $samplePage = $(`#assessment-modal #sample`);
    SampleTab.setPage($samplePage);
    SampleTab.loadListeners(data);
  };

  var unloadListeners = function () {
    const MeasurementsTab = new Measurements(IncidentInvestigationController);
    MeasurementsTab.setPage($measurementsPage);
    MeasurementsTab.unloadListeners();

    const SampleTab = new Sample(IncidentInvestigationController);
    SampleTab.setPage($samplePage);
    SampleTab.unloadListeners();
  };

  var getProps = function () {
    const MeasurementsTab = new Measurements(IncidentInvestigationController);
    const SampleTab = new Sample(IncidentInvestigationController);

    return {
      ...MeasurementsTab.getProps(),
      ...SampleTab.getProps(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return { loadListeners, unloadListeners, getStringKey, getTabHeader, getProps };
};

module.exports = IncidentInvestigationFlow();

const IncidentInvestigationController = require("./incidentInvestigationController");
const Measurements = require("../../outfalls/routineMaintenance/outfallRoutineMaintenanceMeasurements");
const Sample = require("../../outfalls/routineMaintenance/outfallRoutineMaintenanceSample");
