"use strict";

const ManholesIcon = function () {
  var getManholesMarker = function (latlng, condition, isPriority, isDue) {
    return L.marker(latlng, {
      icon: getManholesMarkerIcon(condition, isPriority, isDue),
    });
  };

  var getManholesMarkerIcon = function (condition, isPriority, isDue) {
    const iconClass = getManholesIconClass(condition, isPriority, isDue);
    var icon = L.divIcon({
      iconSize: _updateIconSizeOnZoom(),
      popupAnchor: [0, 5],
      className: iconClass,
    });

    return icon;
  };

  var _updateIconSizeOnZoom = function () {
    const currentZoomLevel = Tree.get("zoomLevel");
    const currentDataView = Tree.get("dataView");
    const iconSizesArray = [6, 8, 10, 12, 14, 14, 24, 24];
    const updatedIconProps = MapFunctions.resizeIconsOnZoom(
      currentZoomLevel,
      false,
      false,
      false,
      false,
      iconSizesArray,
    );

    return currentDataView === "muni-manhole" || currentDataView === "dry-weather"
      ? updatedIconProps.size
      : [24, 24];
  };

  var getManholesIconClass = function (condition, isPriority, isDue) {
    const validConditions = [null, "optimal", "poor", "closed"];
    const classNames = ["manhole"];

    if (!validConditions.includes(condition)) {
      throw new Error(`${condition} is not a valid condition.`);
    }

    if (isDue) {
      classNames.push("due");
    } else if (condition) {
      classNames.push(condition);
    }

    if (isPriority) {
      classNames.push("priority");
    }

    return classNames.join("-");
  };

  return {
    getManholesMarker,
    getManholesIconClass,
    getManholesMarkerIcon,
    _updateIconSizeOnZoom,
  };
};

module.exports = ManholesIcon();

const MapFunctions = require("../../mapFunctions/mapFunctions");
const Tree = require("../../tree");
