"use strict";

var ToolNavTitle = function () {
  var set = function (title = Tree.get("activeGroup", "name"), fixed = false) {
    $(".city-name").text(title);
    if (!fixed) {
      adjustToolNavTitle();
    }
  };

  var adjustToolNavTitle = function () {
    const $toolNavTitle = $(".city-name");
    const titleLength = $toolNavTitle.first().text().length;

    if (titleLength > 30) {
      const width = 7.75 * (titleLength / 2);
      $toolNavTitle.css({ "font-size": "15px", width: width });
    } else if (titleLength > 18) {
      $toolNavTitle.css({ "font-size": "18px" });
    }
  };

  return {
    set,
  };
};

module.exports = ToolNavTitle();

const Tree = require("../tree");
