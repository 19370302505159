"use strict";

const TrashFcsDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareTrashFcs,
        template: "list/trashFcsDataList.njk",
        layerName: "fcs",
      };
      DataList.init(config);
      initialRender();
      loadListeners();
      DataList.loadDomListeners();
    }
  };

  var loadListeners = function () {
    $(".data-list-container").off("click", ".map-button");
    $(".data-list-container").off("click", ".show-fcs-assessment");
    $(".data-list-container").on("click", ".map-button", showMarkerOnMap);
    $(".data-list-container").on("click", ".show-fcs-assessment", siteVisitClick);
  };

  var showMarkerOnMap = function () {
    var $tr = $(this).parents("tr");
    var id = $tr.data("id");

    MapFunctions.openPopupById(id);
    const mapButtonContainer = $(this).parents("td");
    DataList.addAnalyticsToMapIconOnAddress(mapButtonContainer.hasClass("address-col"));
  };

  var setCurrentFcsData = function (id) {
    const allFcss = Tree.get("layers", "fcs", "data");
    const currentFcs = allFcss.find((fcs) => fcs.idBmp === id);

    Tree.set("currentBmpFcs", currentFcs);
  };

  var siteVisitClick = function () {
    const data = $(this).parents("tr").data();

    setCurrentFcsData(data.id);
    BmpFunctions.showFcsAssessmentModal(data.bmpName, data.id, data.type);
  };

  var initialRender = function () {
    DataList.setSorting("bmpName", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareTrashFcs = function (trashFcs) {
    sortTrashFcsBy(trashFcs);

    for (const fcs of trashFcs) {
      fcs.iconClass = MapStyles.getFcsTodoIconClasses(fcs);
    }

    return {
      rows: trashFcs,
      options: {},
    };
  };

  var sortTrashFcsBy = function (trashFcs) {
    const alphaSorter = SortUtilities.alphaSorter;

    var sortersDict = {
      bmpName: alphaSorter,
      address: alphaSorter,
      bmp_type: alphaSorter,
      plu_treated: alphaSorter,
      cof_final_text: alphaSorter,
    };
    DataList.sortWithDict(trashFcs, sortersDict);
  };

  return {
    init,
  };
};

module.exports = TrashFcsDataListController();

const DataList = require("../mapFunctions/dataList");
const Tree = require("../tree");
const SortUtilities = require("../general/sortUtilities.js");
const MapFunctions = require("../mapFunctions/mapFunctions.js");
const BmpFunctions = require("../modals/bmpFunctions.js");
const MapStyles = require("../mapFunctions/mapStyles.js");
