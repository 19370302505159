"use strict";

var CleanData = function () {
  var cleanGeoJSON = function (data) {
    var queryResult = data;

    var i = 0,
      length = queryResult.length,
      prop = null,
      geojson = {
        type: "FeatureCollection",
        features: [],
      }; // Set up the initial GeoJSON object.
    for (i = 0; i < length; i++) {
      // For each result create a feature
      var feature = {
        type: "Feature",
        geometry: JSON.parse(queryResult[i].geom),
        properties: {},
      };
      // finally for each property/extra field, add it to the feature as properties as defined in the GeoJSON spec.
      for (prop in queryResult[i]) {
        if (prop !== "geom" && queryResult[i].hasOwnProperty(prop)) {
          feature.properties[prop] = queryResult[i][prop];
        }
      }
      // Push the feature into the features array in the geojson object.
      geojson.features.push(feature);
    }
    // return the FeatureCollection geojson object.
    return geojson;
  };

  var toNumberNullSafe = function (num) {
    if (num === null) return null;
    if (num === "") return null;
    return +num;
  };

  var buildDictionary = function (array, key, prop) {
    var dict = {};
    if (Array.isArray(array)) {
      array.forEach(function (item) {
        dict[item[key]] = item[prop];
      });
    }
    return dict;
  };

  var cleanGeoData = function (data, type) {
    var queryResult = prepareRawData(data);
    // Initalise variables.
    var i = 0,
      length = queryResult.length,
      prop = null,
      geojson = {
        type: "FeatureCollection",
        features: [],
      }; // Set up the initial GeoJSON object.

    for (i = 0; i < length; i++) {
      // For each result create a feature
      var feature = {
        type: "Feature",
        geometry: JSON.parse(queryResult[i].geom),
        properties: {},
      };
      // finally for each property/extra field, add it to the feature as properties as defined in the GeoJSON spec.
      for (prop in queryResult[i]) {
        if (prop !== "geojson" && queryResult[i].hasOwnProperty(prop)) {
          feature.properties[prop] = queryResult[i][prop];
        }
      }
      // Push the feature into the features array in the geojson object.
      geojson.features.push(feature);
    }
    // Remove points that are remnants of the clip in postgis
    if (type !== "line") {
      var cleanFeatures = geojson.features.filter(removePoints);
      geojson.features = cleanFeatures;
    }
    return geojson;
  };

  var prepareRawData = function (geoJson) {
    var cleanedGeoJson = [];

    for (var i = 0; i < geoJson.length; i++) {
      // For each result create a feature
      //             for (var j = 0; j < geoJson[i].length; j++) {
      var feature = {};
      $.each(geoJson[i], function (k, v) {
        feature[k] = v;
        //                feature += k + ":" + v + ",";
      });
      cleanedGeoJson.push(feature);
    }
    return cleanedGeoJson;
  };

  var removePoints = function (feature) {
    if (typeof feature.geometry.geometries !== "undefined") {
      var thisGeomArray = [];
      feature.geometry.geometries.forEach(function (geometry) {
        if (geometry.type == "Polygon") {
          thisGeomArray.push(geometry);
        }
      });
      feature.geometry.geometries = thisGeomArray;
      return feature;
      //            return feature.geometry.geometries(geometriesRemovePoints);
    } else {
      return feature.geometry.type == "MultiPolygon";
    }
  };

  var applyParcelConditions = function (parcelFeatures, conditionData) {
    var dict = buildDictionary(conditionData, "apn", "condition");
    parcelFeatures.forEach(function (feat) {
      var apn = feat.properties.parcelid;
      feat.properties.condition = apn in dict ? dict[apn] : null;
    });
  };

  var makePointOutfalls = function makePointOutfalls(json) {
    var cleanedJson = prepareOutfalls(json);
    var i = 0,
      length = cleanedJson.length,
      prop = null,
      geojson = {
        type: "FeatureCollection",
        features: [], // Set up the initial GeoJSON object.
      };
    for (i = 0; i < length; i++) {
      // For each result create a feature
      var feature = {
        type: "Feature",
        geometry: JSON.parse(cleanedJson[i].geom),
        properties: {},
      };
      for (prop in cleanedJson[i]) {
        if (prop !== "geojson" && cleanedJson[i].hasOwnProperty(prop)) {
          feature.properties[prop] = cleanedJson[i][prop];
        }
      }
      geojson.features.push(feature);
    }
    return geojson;
  };

  var prepareOutfalls = function (json) {
    var cleanedGeoJson = [];
    for (var i = 0; i < json.length; i++) {
      // For each result create a feature
      var feature = {
        gid: json[i].gid,
        catchid: json[i].catchid,
        outid: json[i].outid,
        muni_name: json[i].muni_name,
        area: json[i].acres,
        outfall: json[i].outfall,
        obsout: json[i].obsout,
        outsize: json[i].out_size,
        rot_azi: json[i].rot_azi,
        geom: json[i].geom,
      };
      cleanedGeoJson.push(feature);
    }
    return cleanedGeoJson;
  };

  var groupByProp = function (items, propName, arrayName) {
    //remove null headers
    items = items.filter((item) => item[propName] != null);
    // get unique values for propName
    var propValues = items
      .map(function (item) {
        // return [item[propName], item["drains_to_rw"]];
        return item[propName];
      })
      .filter(function (value, i, self) {
        return self.indexOf(value) === i;
      });
    // build groups
    var grouped = propValues.map(function (value) {
      return {
        [propName]: value,
        [arrayName]: [],
        ["drains_to_rw"]: "",
      };
    });
    items.forEach(function (item) {
      var idx = propValues.indexOf(item[propName]);
      grouped[idx][arrayName].push(item);
      grouped[idx]["drains_to_rw"] = item["drains_to_rw"];
    });
    //sort rws alphabetically
    var alphaDescend = Tree.get("filters", "descend");
    grouped.sort(dynamicSort(propName, alphaDescend));
    grouped = appendOther(grouped);
    return grouped;
  };

  var dynamicSort = function (property, descending) {
    var sortOrder = 1;
    if (descending) {
      sortOrder = -1;
    }
    return function (a, b) {
      //treat underscores & hyphens as spaces
      var aa = a[property].replace(/_|\/|-/g, " ");
      var bb = b[property].replace(/_|\/|-/g, " ");
      return (
        aa.localeCompare(bb, undefined, {
          numeric: true,
          sensitivity: "base",
        }) * sortOrder
      );
    };
  };

  var appendOther = function (objArray) {
    //remove out of bounds data and append to the end of table
    var otherIndex = objArray.findIndex(function (obj) {
      return obj.drains_to_c == "Other" || obj.drains_to_rw == "Other";
    });
    objArray.push(objArray.splice(otherIndex, 1)[0]);
    return objArray;
  };

  const getFcsOnly = function (bmp_type_index, fcs) {
    if (bmp_type_index == 16) {
      return fcs ? true : false;
    }
    return bmp_type_index == 18;
  };

  const getBmpOnly = function (bmp_type_index, fcs) {
    if (bmp_type_index == 16) {
      return !fcs ? true : false;
    }
    return bmp_type_index == 2 || bmp_type_index == 10 || bmp_type_index == 15;
  };

  var addLoadType = function (geoJson, loadType) {
    geoJson.features.forEach(function (feature) {
      feature.properties["particulates"] = loadType == "Particulates";
      feature.properties["runoff"] = loadType == "Runoff";
    });
    return geoJson;
  };

  var getArrayProperties = function (array) {
    return array.map((feature) => feature.properties || feature);
  };

  var getColumnNumberArray = function (features, runoffOrParticulates) {
    if (features.length == 0) {
      return [];
    }
    var numberArray = [];
    var i;
    var properties = getArrayProperties(features);

    //put all normalised runoff values in an array
    // @TODO: Investigate whether it shoud be using runoffOrParticulates or layerName
    if (runoffOrParticulates == "Impervious") {
      for (i = 0; i < features.length; i++) {
        numberArray.push(Number(properties[i].imp));
      }
    } else if (runoffOrParticulates == "Percent Runoff") {
      for (i = 0; i < features.length; i++) {
        numberArray.push(Number(properties[i].normalized * 100));
      }
    } else if (runoffOrParticulates === "runoffRatioProgress") {
      for (let i = 0; i < features.length; i++) {
        if (i > 0 && Number(properties[i].normalized) > 0) {
          numberArray.push(Number(properties[i].reduction * 100));
        }
      }
    } else if (runoffOrParticulates === "connectivityInputs") {
      for (let i = 0; i < features.length; i++) {
        numberArray.push(Number(properties[i].connect));
      }
    } else if (runoffOrParticulates.includes("Progress")) {
      for (let i = 0; i < features.length; i++) {
        numberArray.push(Number(properties[i].reduction));
      }
    } else if (runoffOrParticulates.includes("Opportunity")) {
      for (let i = 0; i < features.length; i++) {
        numberArray.push(Number(properties[i].baselineNorm));
      }
    } else {
      for (i = 0; i < features.length; i++) {
        numberArray.push(Number(properties[i].normalized));
      }
    }
    return numberArray;
  };

  var getNumberArrayNoZeros = function (numberArray) {
    var zeroCount = numberArray.filter(function (v) {
      return v === 0;
    }).length;

    //remove zeros from calculation for quantile values
    if (zeroCount > 0) {
      numberArray.splice(0, zeroCount);
    }
    return numberArray;
  };

  var removeDuplicateEndData = function (numberArray) {
    while (numberArray.length > 1 && numberArray[0] === numberArray[1]) {
      numberArray.shift();
    }

    while (
      numberArray.length > 1 &&
      numberArray[numberArray.length - 1] === numberArray[numberArray.length - 2]
    ) {
      numberArray.pop();
    }

    return numberArray;
  };

  var getUniqueValArray = function (numberArray) {
    return [...new Set(numberArray)];
  };

  var getSortedArray = function (numberArray) {
    var numberArrayCopy = [...numberArray];
    return numberArrayCopy.sort((a, b) => a - b);
  };

  var getColumnArray = function (features, layerName) {
    var numberArray = getColumnNumberArray(features, layerName);

    var columnValues;
    if (layerName.includes("Progress")) {
      columnValues = SegmentData.jenks(numberArray, 5);
      columnValues.pop();
    } else if (layerName === "runoffRatioOpportunity") {
      columnValues = RunoffRatioOpportunityLayer.getColumnValues();
    } else if (layerName === "connectivityInputs") {
      columnValues = ConnectivityInputsLayer.getColumnValues();
    } else {
      columnValues = SegmentData.quantile(numberArray);
      columnValues.unshift(0);
    }

    return columnValues;
  };

  var roundToZero = function (num) {
    if (num < 0.000001 && num > -0.000001) {
      num = 0;
    }
    return num;
  };

  var camelToKebabCase = function (str) {
    return str.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
  };

  var toStringFirstSigFig = function (number, minSigFig = 0) {
    const numberString = number.toString().split(".");
    const whole = numberString[0];
    const decimal = numberString[1];

    if (decimal === undefined || whole !== "0") {
      return number.toFixed(minSigFig);
    }

    for (let i = minSigFig; i <= decimal.length; i++) {
      const string = number.toFixed(i);
      if (string[string.length - 1] !== "0") {
        return string;
      }
    }
  };

  return {
    cleanGeoJSON,
    buildDictionary,
    applyParcelConditions,
    cleanGeoData,
    getColumnArray,
    makePointOutfalls,
    groupByProp,
    getFcsOnly,
    getBmpOnly,
    addLoadType,
    roundToZero,
    camelToKebabCase,
    toNumberNullSafe,
    getColumnNumberArray,
    getNumberArrayNoZeros,
    getUniqueValArray,
    getSortedArray,
    toStringFirstSigFig,
    removeDuplicateEndData,
  };
};

module.exports = CleanData();

const Tree = require("../../tree");
const SegmentData = require("../segmentData");
const RunoffRatioOpportunityLayer = require("../telr/runoffRatioOpportunityLayer");
const ConnectivityInputsLayer = require("../inputs/layers/connectivityInputsLayer");
