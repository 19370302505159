"use strict";

const IddeIncidentInsightDashboardController = function () {
  var $insightContainer;
  var inited = false;
  var controller = null;
  var incidentTypeOptions;
  var reports = [];
  var reportingYears;

  var focusYear;
  var comparisonYear;
  var incidentType = "total";

  var init = async function () {
    if (!inited) {
      await loadReportingYears();

      $insightContainer = $(".insight-container");

      incidentTypeOptions = IncidentInvestigationConstants.getFilterIncidentTypeOptions();
      incidentTypeOptions.unshift({ name: "All Types", value: "total" });

      loadDomListeners();

      var widgetIncidentCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Incidents",
        dataDecimalPlaces: 0,
        getDataUnit: () => {
          return "Incidents";
        },
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["incidentCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total # of Incidents`;
        },
        getHoverDescription: () => {
          return "The percent difference in total incidents between the comparison and focus reporting years";
        },
      });

      var widgetInvestigationTime = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total Investigation Time",
        getDataUnit: (insightData, dataPath) => {
          const hours = insightData[dataPath]["investigationTime"];
          return InsightWidgetFunctions.convertTimeUnit(hours);
        },
        getDataNumber: (insightData, dataPath) => {
          const hours = insightData[dataPath]["investigationTime"];
          return hours;
        },
        convertDataNumberFunction: InsightWidgetFunctions.convertTime,
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total Investigation Time`;
        },
        getHoverDescription: () => {
          return "The percent difference in total investigation time between the comparison and focus reporting years";
        },
      });

      var widgetAvgTimeOpen = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Average Time Open",
        getDataUnit: (insightData, dataPath) => {
          const hours = insightData[dataPath]["avgTimeOpen"];
          return InsightWidgetFunctions.convertTimeUnit(hours);
        },
        getDataNumber: (insightData, dataPath) => {
          const hours = insightData[dataPath]["avgTimeOpen"];
          return hours;
        },
        convertDataNumberFunction: InsightWidgetFunctions.convertTime,
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Average Time Open`;
        },
        getHoverDescription: () => {
          return "The percent difference in average time open between the comparison and focus reporting years";
        },
      });

      var widgetMaxTimeOpen = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Maximum Time Open",
        getDataUnit: (insightData, dataPath) => {
          const hours = insightData[dataPath]["maxTimeOpen"];
          return InsightWidgetFunctions.convertTimeUnit(hours);
        },
        getDataNumber: (insightData, dataPath) => {
          const hours = insightData[dataPath]["maxTimeOpen"];
          return hours;
        },
        convertDataNumberFunction: InsightWidgetFunctions.convertTime,
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Maximum Time Open`;
        },
        getHoverDescription: () => {
          return "The percent difference in maximum time open between the comparison and focus reporting years";
        },
      });

      var widgetTopActivity = new InsightWidgetTopList({
        size: 2,
        title: "Top 5 Incident Activity/Material Types",
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["topActivity"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        keyToDisplayNameFunction: function (key) {
          const allOptions = IncidentInvestigationConstants.getIllicitActivityOptions();
          var matchedItem = allOptions.find((item) => item.value === key);

          return matchedItem?.name || key;
        },
        getHoverTitle: (insightData, hoverType) => {
          const allOptions = IncidentInvestigationConstants.getIllicitActivityOptions();
          const matchedItem = allOptions.find((item) => item.value === hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `${displayName} <br/>Activity/Material Type Ranking`;
        },
      });

      var widgetMonthlyIncidentCountChart = new InsightWidgetMonthlyCountChart({
        size: 4,
        sizeSm: 2,
        title: "Cumulative Total # of Requested Incidents",
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["monthlyIncidentCount"];
        },
        getYearByDataPath,
        getHoverTitle: (hoverIndex) => {
          const monthIndex = hoverIndex + 1;
          return `${focusYear} & ${comparisonYear} Month ${monthIndex} <br/> Total # of Requested Incidents`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of requested incidents between the comparison and focus reporting years";
        },
        getNotes: () => {
          const focusReport = reports.find(
            (report) => report.reportingYear === parseInt(focusYear),
          );

          return `*${focusReport.displayDataRangeFirst} is the start of the focus reporting year. ${focusReport.displayDataRangeLast} is the end of the focus reporting year.`;
        },
      });

      var widgetIncidentCountByTypeChart = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "Total # of Incidents by Incident Type",
        getDataArray: (insightData, dataPath) => {
          return insightData[dataPath]["incidentCountByIncidentType"];
        },
        getYearByDataPath,
        getSelectedType,
        getHoverTitle: (insightData, hoverType) => {
          const displayIncidentType = incidentTypeOptions.getObjectWith("value", hoverType)?.name;
          return `${focusYear} & ${comparisonYear} <br/> ${displayIncidentType}`;
        },
        getHoverDescription: () => {
          return "The percent difference in incidents between the comparison and focus reporting years";
        },
      });

      var config = {
        getInsightDataFunction: ApiCalls.getIddeIncidentInsight,
        widgets: [
          widgetMonthlyIncidentCountChart,
          widgetTopActivity,
          widgetIncidentCountByTypeChart,
          widgetIncidentCount,
          widgetInvestigationTime,
          widgetAvgTimeOpen,
          widgetMaxTimeOpen,
        ],
        hasFilter: true,
        getFilterMenuHtmlFunction,
        saveFilterFunction,
        getMobileFilterButtonTextFunction,
      };

      controller = new InsightDashboardController(config);
      controller.init();
      inited = true;
      render();
    }
  };

  var loadDomListeners = function () {
    $insightContainer.off("change", ".year-dropdown", onYearChange);
    $insightContainer.on("change", ".year-dropdown", onYearChange);

    $insightContainer.off("change", `[name="incident-type"]`, onIncidentTypeChange);
    $insightContainer.on("change", `[name="incident-type"]`, onIncidentTypeChange);

    $insightContainer.off("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
    $insightContainer.on("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
  };

  var onYearChange = function () {
    if (this.name === "focus-year") {
      focusYear = this.value;
    } else if (this.name === "comparison-year") {
      comparisonYear = this.value;
    }
    render();
  };

  var onIncidentTypeChange = function () {
    incidentType = this.value;
    render();
  };

  var onBarGroupClick = function () {
    var $barGroup = $(this);
    var $incidentTypeSelect = $insightContainer.find(`select[name="incident-type"]`);

    if ($barGroup.hasClass("selected")) {
      $incidentTypeSelect.val("total").change();
    } else {
      const incidentType = $barGroup.data("type");
      if (incidentType) {
        $incidentTypeSelect.val(incidentType).change();
      }
    }
  };

  var render = function () {
    controller.render({ focusYear, comparisonYear, incidentType });
  };

  var loadReportingYears = async function () {
    reports = await ApiCalls.getAnnualReports("idde");
    reportingYears = reports
      .map((report) => report.reportingYear)
      .sort()
      .reverse();
    focusYear = reportingYears[0];
    comparisonYear = reportingYears[1] || reportingYears[0];
  };

  var getFilterMenuHtmlFunction = function () {
    var yearOptions = reportingYears.map((year) => {
      return { name: year, value: year };
    });

    return nunjucks.render("insight/iddeIncidentInsightDashboardFilters.njk", {
      incidentTypeOptions,
      incidentType,
      yearOptions,
      focusYear,
      comparisonYear,
    });
  };

  var getMobileFilterButtonTextFunction = function () {
    const displayIncidentType = incidentTypeOptions.getObjectWith("value", incidentType)?.name;
    return `${focusYear} & ${comparisonYear}, ${displayIncidentType}`;
  };

  var saveFilterFunction = function (formData) {
    if (formData["focusYear"]) {
      focusYear = parseInt(formData["focusYear"]);
      $insightContainer.find(`select[name="focus-year"]`).val(focusYear);
    }
    if (formData["comparisonYear"]) {
      comparisonYear = parseInt(formData["comparisonYear"]);
      $insightContainer.find(`select[name="comparison-year"]`).val(comparisonYear);
    }
    if (formData["incidentType"]) {
      incidentType = formData["incidentType"];
      $insightContainer.find(`select[name="incident-type"]`).val(incidentType);
    }
    render();
  };

  var getYearByDataPath = function (dataPath) {
    return dataPath === "focus" ? focusYear : comparisonYear;
  };

  var getSelectedType = function () {
    return incidentType;
  };

  return {
    init,
  };
};

module.exports = IddeIncidentInsightDashboardController();

const ApiCalls = require("../apiCalls");
const InsightDashboardController = require("../general/widgets/insightDashboardController");
const InsightWidgetFunctions = require("../general/widgets/insightWidgetFunctions");
const InsightWidgetSimpleNumber = require("../general/widgets/insightWidgetSimpleNumber");
const InsightWidgetTopList = require("../general/widgets/insightWidgetTopList");
const InsightWidgetMonthlyCountChart = require("../general/widgets/insightWidgetMonthlyCountChart");
const InsightWidgetCountByTypeChart = require("../general/widgets/insightWidgetCountByTypeChart");
const IncidentInvestigationConstants = require("./incidentInvestigation/incidentInvestigationConstants");
