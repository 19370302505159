"use strict";

const TreeInitialDataReport = function () {
  const getStaticData = function () {
    return {
      tool: "report",
      activeGroup: {
        groupId: null,
        group: null,
        lat: null,
        lng: null,
        group_role: null,
        latlng: null,
        reports: null,
      },
      enabledGeoServerLayers: null,
      user: {
        username: "",
        full_name: "",
        admin_type: "",
        email: "",
        trueGroupId: "",
        group: "",
        group_role: "",
        paying: "",
        enabledTools: null,
      },
      selected: {
        receivingWater: null,
        rwThatSelectedDrainsTo: null,
        catchment: null,
        receivingWaters: [],
      },
      filters: {
        search: "",
        statuses: [],
        ownership: [],
        types: [],
        catchments: [],
        receivingWaters: [],
        descend: false,
        dataSort: "rw", //dropdown options  'ud' & 'uc'
        percentileUntreatedPlu: {
          percentileUntreatedPlu: [],
          spatialTypeUntreatedPlu: [],
        },
        searchString: "",
      },
      trashResults: {
        data: [],
        layer: "",
        selected: "",
        selectedType: "",
        searchString: "",
      },
      spatialGroups: {
        inputs: {
          allMs4: [],
          receivingWaters: [],
          urbanDrainages: [],
        },
        report: {
          allMs4: [],
          receivingWaters: [],
          urbanDrainages: [],
        },
        plan: {
          allMs4: [],
          receivingWaters: [],
          urbanDrainages: [],
        },
      },
      layers: {
        inputs: {
          catchments: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          streams: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          stormdrains: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          ms4Boundary: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          priority: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          fullDrainage: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          bmps: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
            selectedId: null,
            spatialView: "MS4View",
          },
          fcs: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
            selectedId: null,
            spatialView: "MS4View",
          },
          regional: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          regionPoint: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
        },
        report: {
          catchments: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          streams: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          stormdrains: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          ms4Boundary: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          regional: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          runoffProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          particulatesProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          runoffRatioProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          connectivityInputsProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          sbmpProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          sbmpProgressDrainage: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          fcsProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          fcsProgressDrainage: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          constructionProjectProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          constructionProjectAreaProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          incidentProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          outfallProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          lidProjectProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          lidAreaProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          muniCatchBasinProgress: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          muniCatchBasinProgressDrainage: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          maintenanceZones: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          regionPoint: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
        },
        plan: {
          catchments: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          streams: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          stormdrains: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          ms4Boundary: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          priority: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          fullDrainage: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
          bmps: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedId: null,
            selectedType: "",
            searchString: "",
          },
          fcs: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
            selectedId: null,
          },
          regional: {
            isEnabled: false,
            isFetching: false,
            data: [],
          },
          runoffOpportunity: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          particulatesOpportunity: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          runoffRatioOpportunity: {
            isEnabled: false,
            isFetching: false,
            data: [],
            rawData: [],
            selected: "",
            selectedType: "",
            searchString: "",
            spatialView: "MS4View",
            columnArray: [],
          },
          runoff: {
            isEnabled: false,
            isFetching: false,
            data: [],
            selected: "",
            selectedType: "",
            searchString: "",
          },
        },
      },
      basemap: {
        inputs: "Gray",
        report: "DarkGray",
        plan: "Gray",
      },
      basemapBeforeZoomMap: {
        report: "",
        plan: "",
      },
      spatialView: {
        inputs: "MS4View",
        report: "MS4View",
        plan: "MS4View",
      },
      lastGroupId: {
        inputs: null,
        report: null,
        plan: null,
      },
      yearDropdownSelection: null,
      allGroupsData: [],
      isLoadingModalShown: false,
      bbox: null,
      clustered: { layer: "" },
      runoffType: "runoffRate",
      progressType: "opportunities",
      mitigationType: "Mitigated",
      activeHref: "",
      inputsLayer: "drainage",
      selectedDrainsTo: "undefined",
      search_filter_on: false,
      activeCatchmentTab: "results",
      latlng: [],
      outfall_photos: {},
      catchId: "",
      catchmentData: {
        inputs: [],
        report: [],
        plan: [],
      }, //filtered catchments with all data
      legendShowing: {
        inputs: false,
        report: false,
        plan: false,
      },
      mapId: null,
      lastMapId: null,
      firstLoad: {
        inputs: true,
        report: true,
        plan: true,
      },
      reportDataFiltered: {
        isFetching: false,
        data: [],
      },
      reportData: null,
      overviewKpi: {
        data: null,
        isFetching: false,
      },
      extentInitialized: {
        inputs: false,
        report: false,
        plan: false,
      },
      annualReportingLoaded: false,
      submittedMS4sLoaded: false,
      table: {
        expanded: [],
        masterToggled: false,
        searchBox: null,
      },

      dataView: "trashView",

      regionalSubmitReportView: "permit-requirement",

      layerDropdown: { inputs: "soilInputs", report: null, plan: "current" },

      summaryTableOpen: true,
      renderTable: false,
      allMs4s: null,
      currentMs4Reports: null,
      activeTab: null,
      activePageGroup: null,
      currentColumnArray: null,
      globalGroupSettings: {
        isFetching: false,
        data: [],
      },
    };
  };

  return { getStaticData };
};

module.exports = TreeInitialDataReport();
