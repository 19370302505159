"use strict";

const UserManagement = function () {
  const key = "user-management";
  const option = "User Management";
  const iconClass = "people";
  const filtersTreePath = "userFilters";
  let $page;
  let $editPage;
  let $editUsers;
  const inspectionPermissionsGrouped = [];
  let inspectionPermissions = [];
  let props = [];
  let filteredUsers = [];
  let dataSorting = { key: "email", reversed: false };

  function isEsg() {
    return Tree.get("tool") === "esg";
  }

  function loadListeners() {
    $page = SettingsModalController.getParentPage();

    $page.off("click", ".user-setting-button", _showInviteUserPage);
    $page.on("click", ".user-setting-button", _showInviteUserPage);

    $page.off("click", ".edit-permissions", _showEditUserPage);
    $page.on("click", ".edit-permissions", _showEditUserPage);

    $page.off("click", ".remove-user", _showRemoveUserPage);
    $page.on("click", ".remove-user", _showRemoveUserPage);

    $page.off("click", ".resend-invite", attemptResendInvite);
    $page.on("click", ".resend-invite", attemptResendInvite);

    $page.off("input", "#search-users", _updateSearchFilter);
    $page.on("input", "#search-users", _updateSearchFilter);

    $page.off("click", ".filter-button", _toggleFilters);
    $page.on("click", ".filter-button", _toggleFilters);

    $page.off("click", "th.sortable", _sortByClickedColumn);
    $page.on("click", "th.sortable", _sortByClickedColumn);

    _initializeFilters();
  }

  const _initializeFilters = function () {
    Tree.select(filtersTreePath).off("update", _filtersUpdated).on("update", _filtersUpdated);
    Tree.set(filtersTreePath, {});
  };

  const _updateSearchFilter = function () {
    Tree.set([filtersTreePath, "searchString"], $("#search-users").val());
  };

  const _toggleFilters = function () {
    const $filters = $("#settings-modal #filters");
    $filters.toggleClass("hidden");
    $(".filter-button").toggleClass("invert-colors", !$filters.hasClass("hidden"));
    $("#settings-modal .settings-nav").toggleClass("hidden", !$filters.hasClass("hidden"));
  };

  function _filtersUpdated() {
    rerenderUserList({ refresh: false });
  }

  function _sortByClickedColumn() {
    const $this = $(this);
    dataSorting = {
      key: $this.data("sortKey"),
      reversed: dataSorting?.key === $this.data("sortKey") ? !dataSorting?.reversed : false,
    };
    rerenderUserList({ refresh: false });
    TableSort.updateHeaders(dataSorting, $page);
  }

  function loadEditPageListeners() {
    $editPage = $("#edit-users-modal");

    $editPage.off("click", "[value='invite']", _showConfirmInvite);
    $editPage.on("click", "[value='invite']", _showConfirmInvite);

    $editPage.off("click", "[value='edit']", _confirmEdit);
    $editPage.on("click", "[value='edit']", _confirmEdit);

    $editPage.off("change", ".user-permissions", updateUserTypePermissions);
    $editPage.on("change", ".user-permissions", updateUserTypePermissions);

    $editPage.off("click", ".affiliated", toggleAffiliated);
    $editPage.on("click", ".affiliated", toggleAffiliated);

    $editPage.off("change", ".affiliations", renderNewAffiliationField);
    $editPage.on("change", ".affiliations", renderNewAffiliationField);

    $editPage.off("change", ".tool-access input", showInspectionFields);
    $editPage.on("change", ".tool-access input", showInspectionFields);
  }

  var updateUserTypePermissions = function (e) {
    const selectedUserRole = $(e.currentTarget).val();

    showAffiliationsField(selectedUserRole);
    showInspectionFields();
  };

  var showAffiliationsField = function (selectedUserRole) {
    // Admins cannot be affiliated, Responders must be affiliated
    const nonAffiliated = ["admin", "reviewer"].includes(selectedUserRole);
    const mustBeAffiliated = selectedUserRole === "responder";
    const $affiliated = $editPage.find(".affiliated");
    const $affiliatedInput = $affiliated.find("input");
    if (nonAffiliated || mustBeAffiliated) {
      $affiliatedInput.prop("disabled", true);
      $affiliatedInput.prop("checked", !nonAffiliated);
    } else {
      $affiliatedInput.prop("disabled", false);
    }

    toggleAffiliated();
    renderNewAffiliationField();
  };

  var renderNewAffiliationField = function () {
    const selectedAffiliation = $(".affiliations").find(".select-affiliation").val();
    if (selectedAffiliation === "new") {
      const newAffiliaction = nunjucks.render("userSettings/newAffiliation.njk");
      $(".new-affiliation").html(newAffiliaction).removeClass("hidden");
    } else {
      $(".new-affiliation").empty().addClass("hidden");
    }
  };

  var toggleAffiliated = function () {
    const affiliationValue = $editPage.find(".affiliated input").is(":checked");
    renderAffiliationsDropdown(affiliationValue);
    renderNewAffiliationField();
  };

  var renderAffiliationsDropdown = function (showAffiliations, selectedAffiliation = null) {
    if (showAffiliations) {
      const groupAffiliations = Tree.get("groupAffiliations");
      const affiliations = nunjucks.render("userSettings/affiliations.njk", {
        affiliations: groupAffiliations,
        selectedAffiliation: selectedAffiliation,
      });
      $editPage.find(".affiliations").html(affiliations).removeClass("hidden");
    } else {
      $editPage.find(".affiliations").empty().addClass("hidden");
    }
  };

  var showInspectionFields = function () {
    const filteredFields = filterInspectionFields(getSelectedToolAccess());
    const toggleInspectionField =
      filteredFields.length === 0 || getSelectedRole() !== "field-personnel";
    $editPage.find(".inspection-permissions").toggleClass("hidden", toggleInspectionField);

    inspectionPermissionsGrouped.forEach(function (permission) {
      const toggleView = filteredFields.flatMap((filtered) => filtered.value);
      $editPage
        .find(`#${permission.value}`)
        .toggleClass("hidden", !toggleView.includes(permission.value) || toggleInspectionField);
    });
  };

  var getSelectedRole = function () {
    return $editPage.find("[name=role]").val();
  };

  var getSelectedToolAccess = function () {
    return $editPage
      .find(".tool-access input:checkbox:checked")
      .map(function () {
        return $(this).val();
      })
      .get();
  };

  var filterInspectionFields = function (selectedTools) {
    const inpsectionsToFilter = selectedTools
      .map(function (tool) {
        return inspectionPermissions[tool];
      })
      .flatMap(function (tools) {
        return tools;
      });

    return inpsectionsToFilter.filter(
      (fieldToFilter, index, self) =>
        index ===
        self.findIndex(
          (fieldToSearch) => fieldToSearch?.value === fieldToFilter?.value && fieldToSearch?.value,
        ),
    );
  };

  async function getProps() {
    let users;
    if (isEsg()) {
      users = await ApiCalls.getUsers(FeatureFlag.enabled("user-management-show-staff"));
    } else {
      await Promise.all([
        ApiCalls.getUsers(FeatureFlag.enabled("user-management-show-staff")),
        ApiCalls.getPossibleContactCompanies(),
        ApiCalls.getAdditionalInspectionPermissions(),
      ]).then((values) => {
        users = values[0];
        Tree.set("groupAffiliations", values[1]);
        inspectionPermissions = values[2];
        return;
      });
    }
    users.forEach((user) => {
      user.status = user.canResend ? "invited" : "active";
      user.displayTools = _getDisplayTools(user.tools);
    });

    props = { users };
    return props;
  }

  function renderFilters() {
    const $filtersContainer = $("#settings-modal #filters");

    const dropdownOptions = {
      roles: getUserRoles(),
      tools: _getToolsOptions(),
      statuses: [
        { value: "active", name: "Active" },
        { value: "invited", name: "Invited" },
      ],
    };
    const html = nunjucks.render("modals/settingsModal/userManagementFilters.njk", dropdownOptions);
    $filtersContainer.html(html);
    UserManagementFilters.init($filtersContainer);
    MultipleSelect.init($filtersContainer);
    Analytics.setFilterContext(key);
    Filters.loadAnalyticsListeners($filtersContainer);
  }

  function getMenuKey() {
    return key;
  }

  function getMenuOption() {
    return option;
  }

  function getMenuIcon() {
    return iconClass;
  }

  function getUserRoles() {
    // @TODO: Get these from domains via tool settings
    return isEsg()
      ? [
          { value: "admin", name: "Admin" },
          { value: "data-manager", name: "Data Manager" },
          { value: "viewer", name: "Viewer" },
        ]
      : [
          { value: "admin", name: "Admin" },
          { value: "data-manager", name: "Data Manager" },
          { value: "reviewer", name: "Reviewer" },
          { value: "field-personnel", name: "Field Person" },
          { value: "responder", name: "Responder" },
          { value: "viewer", name: "Viewer" },
          { value: "planner", name: "Planner" },
        ];
  }

  function _getToolsOptions() {
    const tools = getTools();
    return tools.map((tool) => {
      return {
        value: tool.internalName,
        name: tool.name,
      };
    });
  }

  function _getDisplayTools(userTools) {
    const tools = getTools();
    if (tools.length === userTools.length) return "All Modules";

    const displayTools = tools.filter((tool) => userTools.includes(tool.internalName));
    return displayTools.map((tool) => tool.name).join(", ");
  }

  function getDisplayRoles(value) {
    const roles = getUserRoles();
    const role = roles.find((role) => role.value === value);
    return role.name;
  }

  function _showInviteUserPage() {
    const groupSetting = ToolSettings.getSetting("group");
    if (!groupSetting?.canCreateUsers) {
      const maxUserCount = groupSetting?.maxUsers ?? 0;
      MessageModal.showWarningModal(
        `You are currently only allowed ${maxUserCount} users. Please remove a user or contact 2NDNATURE to proceed.`,
      );
      return;
    }
    showEditModal("invite");
  }

  async function _showConfirmInvite() {
    const userObj = getUserObjToPass();

    try {
      const validation = await ApiCalls.validateEmailAddress(userObj);

      if (validation?.existing) {
        MessageModal.showWarningModal(
          "This user already exists. Please use another email",
          "Existing user",
        );
      } else {
        MessageModal.showConfirmWarningModal(
          null,
          inviteNewUser,
          "Back",
          "Send Invite",
          `<p>You will invite <b>1</b> new user to ${isEsg() ? "Rainsteward" : "2NFORM"}.</p>
                <p><b>New User: </b><br>${userObj.email} - ${getDisplayRoles(userObj.role)}</p>
                <p>Press <b>"Send Invite"</b> to send the invitation email to the user listed above.</p>`,
          () => {},
          "Confirm new user",
          "modal-sm",
        );
      }
    } catch (error) {
      MessageModal.showSimpleWarningModal("Please enter a valid email address.");
    }
  }

  var createNewUserError = function (xhr) {
    const errors = xhr.responseJSON ? xhr.responseJSON.errors : { response: [xhr.responseText] };
    const $commentBox = $("#newUserComment");
    $commentBox.empty();

    for (const field in errors) {
      const error = errors[field][0];
      $commentBox.append(`<p>${error}</p>`);
    }

    $commentBox.show();
  };

  var createNewUserCallback = function (response) {
    rerenderUserList();
    hideEditUsersModal();
    var username = $("#usernameNew").val();
    var htmlData = { username: username };
    var theHtml = null;
    if (response === "New User Requires Approval") {
      theHtml = nunjucks.render("modals/approvalNeededMessage.njk", htmlData);
    } else {
      theHtml = nunjucks.render("modals/newUserSuccess.njk", htmlData);
    }
    MessageModal.showWarningModal(theHtml, "Success!", true);
    Analytics.sendSettingsEvent("create-user");
  };

  var updateUserCallback = function () {
    rerenderUserList();
    hideEditUsersModal();
    var email = $("#email-existing").text().trim();
    var htmlData = { email: email };
    var html = nunjucks.render("modals/updateUserSuccess.njk", htmlData);
    MessageModal.showWarningModal(html, "Success!", true);
    Analytics.sendSettingsEvent("update-user");
  };

  async function _confirmEdit(e) {
    const userObj = getUserObjToPass();
    const id = $(e.currentTarget).data("id");
    const data = SettingsModalController.getExistingProps();
    const existingData = data?.users?.find((user) => user.id === id);

    await ApiCalls.updateExistingUser(
      existingData.id,
      userObj.email,
      userObj.role,
      userObj.tools,
      userObj.affiliation,
      userObj.newAffiliation,
      userObj.inspectionPermissions,
      updateUserCallback,
      createNewUserError,
    );
  }

  async function inviteNewUser() {
    const userObj = getUserObjToPass();
    await ApiCalls.createNewUser(
      userObj.email,
      userObj.username,
      userObj.role,
      userObj.tools,
      userObj.affiliation,
      userObj.newAffiliation,
      userObj.inspectionPermissions,
      createNewUserCallback,
      createNewUserError,
    );
  }

  function getUserObjToPass() {
    $editUsers = $("#edit-users-modal");
    const email = $editUsers.find("input[name=email]").val();
    const role = $editUsers.find("select[name=role] :selected").val();
    const tools = isEsg() ? ["esg"] : getFromCheckedInputs(".tool-access");
    const affiliation = $editUsers.find(".select-affiliation").val();
    const newAffiliation = $editUsers.find(".new-affiliation input").val();
    const inspectionPermissions = getFromCheckedInputs(".inspection-permissions");

    return {
      username: email,
      email: email,
      role: role,
      tools: tools,
      affiliation: affiliation,
      newAffiliation: newAffiliation,
      inspectionPermissions: inspectionPermissions,
    };
  }

  function getFromCheckedInputs(selector) {
    return $editUsers
      .find(`${selector} input:checked`)
      .map(function () {
        return $(this).val();
      })
      .get();
  }

  function _showEditUserPage(e) {
    const id = $(e.currentTarget).data("id");
    const data = SettingsModalController.getExistingProps();
    const existingData = data?.users?.find((user) => user.id === id);
    showEditModal("edit", existingData);
  }

  async function _showRemoveUserPage(e) {
    const id = $(e.currentTarget).data("id");
    const userWarning = await ApiCalls.checkDeleteUser(id);
    if (userWarning) {
      const data = SettingsModalController.getExistingProps();
      const existingData = data?.users?.find((user) => user.id === id);
      const gname = Tree.get(["rootGroup", "name"]);
      const site = Tree.get("tool") === "esg" ? "Rainsteward" : "2NFORM";

      MessageModal.showConfirmWarningModal(
        null,
        async () => {
          await removeUser(id);
        },
        "Cancel",
        "Remove",
        `<p>This will permanently delete the user <b>"${existingData.email}"</b> from ${gname}'s ${site} account</p>
        <p>Click <b>"Cancel"</b> to return to the previous screen.</p>
        <p>Click <b>"Remove"</b> to permanently delete.</p>`,
        () => {},
        "Remove User",
        "modal-sm",
      );
    } else {
      MessageModal.showSimpleWarningModal("You do not have permission to remove this user");
    }
  }

  async function removeUser(id) {
    await ApiCalls.deleteUser(id).finally(async () => {
      await rerenderUserList();
      hideEditUsersModal();
    });
  }

  async function showEditModal(type, data) {
    const $editUsers = $("#edit-users-modal");
    const existingTools = getTools();
    $editUsers.html(
      nunjucks.render("userSettings/editUsers.njk", {
        id: data?.id,
        type: type,
        role: data?.role ?? "viewer",
        email: data?.email ?? "",
        tools: data?.tools ?? [],
        affiliation: data?.affiliation,
        groupAffiliations: Tree.get("groupAffiliations"),
        inspections: filterInspectionFields(existingTools.map((tool) => tool.internalName)),
        inspectionPermissions: data?.inspectionPermissions ?? [],
        roleOptions: getUserRoles(),
        existingTools,
        enabledTools: getToolsEnabled(),
        isEsg: isEsg(),
      }),
    );
    loadEditPageListeners();
    $editUsers.modal("show");
    renderAffiliationsDropdown(!!data?.affiliation, data?.affiliation);
    renderNewAffiliationField();
    showInspectionFields();
  }

  function getTools() {
    return isEsg() ? [] : ToolFunctions.getToolsInOrder();
  }

  function getToolsEnabled() {
    return isEsg() ? [] : ToolFunctions.getToolsEnabledForUser();
  }

  async function rerenderUserList({ refresh = true } = {}) {
    if (refresh) {
      await getProps();
      const existingProps = SettingsModalController.getExistingProps();
      existingProps.users = props.users;
      SettingsModalController.updateExistingProps(props);
    }
    filterUsers();
    sortUsers();
    $("#users-tbody").html(
      nunjucks.render("modals/settingsModal/userManagementDatum.njk", {
        props: {
          users: filteredUsers,
        },
      }),
    );
  }

  function filterUsers() {
    filteredUsers = UserManagementFilters.filterOffline(props.users);
    updateCounts();
  }

  function updateCounts() {
    const countUniqueEmails = new Set(props.users.map((user) => user.email)).size;
    const maxUsers = ToolSettings.getSetting("group").maxUsers;
    const filteredCount = filteredUsers.length;

    $("#count-unique-emails").text(countUniqueEmails);
    $("#max-users").text(maxUsers);
    $("#user-management-page .item-count-button span").text(filteredCount);
  }

  function sortUsers() {
    const alphaSorter = SortUtilities.alphaSorter;
    var sortersDict = {
      email: alphaSorter,
      full_name: alphaSorter,
      role: alphaSorter,
      status: alphaSorter,
      displayTools: alphaSorter,
    };
    if (Object.keys(sortersDict).includes(dataSorting.key)) {
      SortUtilities.sortRows(dataSorting.key, dataSorting.reversed, filteredUsers, sortersDict);
    }
  }

  async function attemptResendInvite(e) {
    const user = $(e.currentTarget).data("id");
    ApiCalls.resendInvite(
      user,
      function (responseMessage) {
        MessageModal.showWarningModal(responseMessage, "Notice");
      },
      function (error) {
        MessageModal.showWarningModal(error.responseText, "Warning");
      },
    );
  }

  function hideEditUsersModal() {
    $("#edit-users-modal").modal("hide");
  }

  function onClose() {
    hideEditUsersModal();
  }

  return {
    getProps,
    getMenuKey,
    getMenuOption,
    getMenuIcon,
    onClose,
    renderFilters,
    loadListeners,
    _getDisplayTools,
    _filtersUpdated,
    _showRemoveUserPage,
  };
};

module.exports = UserManagement();

const ApiCalls = require("../../apiCalls");
const Analytics = require("../../general/analytics");
const MultipleSelect = require("../../general/multipleSelect");
const TableSort = require("../../general/tableSort");
const Filters = require("../../mapFunctions/filters");
const SortUtilities = require("../../general/sortUtilities");
const MessageModal = require("../../modals/messageModal");
const ToolFunctions = require("../../toolFunctions");
const Tree = require("../../tree");
const FeatureFlag = require("../featureFlag");
const SettingsModalController = require("../settingsModalController");
const ToolSettings = require("../toolSettings");
const UserManagementFilters = require("./userManagementFilters");
