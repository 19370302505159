"use strict";

const Checkbox = function () {
  var getCheckedNamesInCheckboxGroup = function ($checkboxGroup) {
    const values = $checkboxGroup
      .filter(":checked")
      .map((ind, elem) => {
        if (OtherInput.isOtherInput($(elem))) {
          return OtherInput.getOtherTextInput($(elem));
        }
        return elem.value;
      })
      .toArray()
      .filter((value) => value !== null)
      .filter((value) => value !== OtherInput.otherInputCheckValue)
      .map((value) => {
        return Misc.isIntOrStringInt(value) ? parseInt(value) : value;
      });
    if (values.length === 0) {
      return null; // HTTP Parameters don't support empty arrays, so null is used.
    }
    return values;
  };

  var getValueListFromCheckboxGroup = function ($parent, name) {
    const $checkboxes = $parent.find(`[name='${name}']:checkbox`);
    return $checkboxes.length > 1 || $checkboxes.first().closest(".checkbox-group").length
      ? getCheckedNamesInCheckboxGroup($checkboxes)
      : $checkboxes.first().is(":checked");
  };

  return {
    getValueListFromCheckboxGroup,
  };
};

module.exports = Checkbox();

const OtherInput = require("./otherInput");
const Misc = require("../misc");
