"use strict";

const Map = function () {
  let mapLayers = {};
  let config;
  let map;
  let currentBasemap;
  let extentSet;

  const init = function (options) {
    if ($("#map").length === 0) return;
    config = options;
    Tree.set("baseMap", config?.baseMap || "Gray");

    map = MainMap.createMap({ noScroll: config?.simplified });
    currentBasemap = addBasemap();
    loadLegend();
    DisplayOptions.resetDisplayOptions();

    handleMapChange();
    setLegend();
    _loadListeners();
  };

  const remove = function () {
    if (!map) return;
    map.remove();
    mapLayers = {};
    map = null;
    extentSet = false;
  };

  const popupsDisabled = function () {
    return config?.simplified;
  };

  const _loadListeners = function () {
    GeoServerLayerFunctions.addMapClickListener(map);

    config.layerModule.loadLayerListenersForMap(map, mapLayers, "main");
  };

  const getMap = function () {
    return map;
  };

  const getLayers = function () {
    return mapLayers;
  };

  const render = function () {
    $(".tab-content").removeClass("hidden");
    setMapExtent();
  };

  const setMapExtent = function () {
    if (extentSet) return;
    extentSet = true;
    const layerData = PageController.getLayerData();
    const geomArray = getGeometryCoordinatesArray(layerData);
    var bounds = L.latLngBounds([]);
    for (var i = 0; i < geomArray.length; i++) {
      var innerArray = geomArray[i];
      var latLng = L.latLng(innerArray[1], innerArray[0]);
      bounds.extend(latLng);
    }

    map.fitBounds(bounds.pad(1), { maxZoom: 8 });
    if (map.getZoom() <= 4) {
      zoomToUsa();
    }
  };

  const zoomToUsa = function () {
    const { center, zoomLevel } = MapFunctions.getUsaView();
    map.setView(center, zoomLevel);
  };

  function getGeometryCoordinatesArray(dataArray) {
    var geometryCoordinatesArray = dataArray.map(function (item) {
      return item.geometry?.coordinates || item.geom?.coordinates;
    });

    return geometryCoordinatesArray;
  }

  const handleMapChange = function () {
    BasemapFunctions.addBasemapChangeListener(function (newBasemap) {
      addBasemap();
    }, "main");
  };

  const addBasemap = function () {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const basemap = BasemapFunctions.getBasemap("main", true);
    currentBasemap = rasterBasemap
      ? MapFunctions.addBasemap(map, currentBasemap, basemap)
      : MapFunctions.addVectorBasemap(map, currentBasemap, basemap);
    return currentBasemap;
  };

  const setLegend = function () {
    const radioBtn = document.getElementById("radio-properties-main");
    const clusterButton = document.getElementById("check-properties-geo-server-layer-main");
    radioBtn.checked = true;

    if (!sessionStorage.getItem("geoServerLayerType")) {
      sessionStorage.setItem("geoServerLayerType", "WFS");
    }
    if (sessionStorage.getItem("geoServerLayerType") === "WMS") {
      if (config?.simplified) {
        sessionStorage.setItem("geoServerLayerType", "WFS");

        clusterButton.checked = true;
      } else {
        clusterButton.checked = false;
      }
    } else {
      clusterButton.checked = true;
    }
  };

  const loadLegend = function () {
    var formattedDataView = Misc.capitalizeFirstLetter(Misc.kebabToCamel(config.dataView));

    var legendLayersFilePath = "esg/maps/legendLayers" + formattedDataView + ".njk";
    var legendLegendFilePath = "esg/maps/legendLegend" + formattedDataView + ".njk";
    const layersHtml = nunjucks.render(legendLayersFilePath, {
      page: PageController.getPageName(),
    });
    const legendHtml = nunjucks.render(legendLegendFilePath);
    $("#main-legend").find(".legend-layers").html(layersHtml);

    $("#main-legend").find(".map-legend").html(legendHtml);

    $(".dual-action, .single-action").addClass("hidden");
  };

  return {
    init,
    remove,
    render,
    getMap,
    getLayers,
    popupsDisabled,
  };
};

module.exports = Map();

const BasemapFunctions = require("../mapFunctions/basemapFunctions");
const DisplayOptions = require("../mapFunctions/displayOptions");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const MainMap = require("../mapFunctions/mainMap");
const MapFunctions = require("../mapFunctions/mapFunctions");
const FeatureFlag = require("../settings/featureFlag");
const Misc = require("../misc");
const Tree = require("../tree");
const PageController = require("./pageController");
