"use strict";

const MessageModal = function () {
  const defaultWarnText = "Warning";

  const showModal = function (
    messageHtml,
    modalTitle,
    okButtonText = "Ok",
    cancelButtonText = "Cancel",
  ) {
    var modalHtml = nunjucks.render("report/modals/messageModal.njk", {
      messageHtml,
      modalTitle,
      okButtonText,
      cancelButtonText,
    });

    const $modal = _initModal(modalHtml);

    return _promiseWaitOnClick($modal);
  };

  var _initModal = function (modalHtml) {
    const $modal = $("#message-modal-container").prepend(modalHtml).children().first();

    $modal.modal("toggle");
    $modal.on("hidden.bs.modal", function () {
      $modal.remove();
    });

    return $modal;
  };

  var _promiseWaitOnClick = function ($modal) {
    return new Promise(function (resolve, reject) {
      $modal.off("click");
      $modal.on("click", "button", function () {
        $modal.modal("toggle");

        if (this.value === "ok") {
          resolve();
        } else {
          reject();
        }
      });
    });
  };

  var showWarningModal = function (message, title, isHtml = false) {
    const $warningModal = $("#warning-modal");
    const $textElement = $warningModal.find(".modal-body > p");
    const $titleElement = $warningModal.find(".modal-title");

    if (isHtml) {
      $textElement.html(message);
    } else {
      $textElement.text(message);
    }

    $titleElement.text(title);

    $warningModal.modal("show");
  };

  var showSimpleWarningModal = function (warningMessage, isHtml = false) {
    showWarningModal(warningMessage, defaultWarnText, isHtml);
  };

  var showFileSizeWarning = function (filename) {
    showSimpleWarningModal(
      `${filename} is too large to upload.  Please keep the file size under 8MB.`,
    );
  };

  return { showModal, showFileSizeWarning };
};

module.exports = MessageModal();
