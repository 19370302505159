"use strict";

const BmpFcsInventorySetLocation = function () {
  const stringKey = "bmp-fcs-set-location";
  const headerInformation = "Location";
  let $page;
  let $inventoryModal;

  var loadSelectLocationListeners = function () {
    $page.on("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.on("input", ":input.form-control", changeSetLocationInput);
    $page.on("change", `[name="use-manual-apn"]`, onUseManualApnChange);
    Form.initializeAndLoadListeners($page, "bmp-fcs-inventory", { isMultiPart: true });
  };

  var removeListeners = function () {
    $page.off("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.off("input", ":input.form-control", changeSetLocationInput);
    $page.off("change", `[name="use-manual-apn"]`, onUseManualApnChange);
  };

  var changeSetLocationInput = function () {
    const changedField = $(this).attr("name");
    if (changedField === "bmp-name") {
      setBmpName($(this).val());
    } else if (changedField === "phase") {
      BmpFcsInventoryModalController.setFormDataField("phase", $(this).val());
      toggleInstallDateField();
      ModalMap.refreshMarkerLayer();
    } else if (changedField === "associated-project") {
      const selectedProjectId = $(this).val();
      setPhaseBasedOnAssociatedProject(selectedProjectId);

      setFacilityNameBasedOnAssociatedProject(selectedProjectId);
      setResponsiblePartyDeliveryBasedOnAssociatedProject(selectedProjectId);
      _setResponsiblePartyMaintenanceBasedOnAssociatedProject(selectedProjectId);
      toggleInstallDateField(selectedProjectId);
    }
  };

  var setPhaseBasedOnAssociatedProject = function (selectedProjectId) {
    const associatedProject = getAssociatedProject(selectedProjectId);

    $page.find("select[name=phase]").attr("disabled", !!associatedProject);
    if (associatedProject) {
      $page.find(`select[name=phase] [value=${associatedProject.phase}]`).prop("selected", true);
      Form.manuallyUnsetField("bmp-fcs-inventory", ["phase"]);
    }
  };

  var onUseManualApnChange = function () {
    const showManualApnInput =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("useManualApn") === true;
    $page.find(`input[name="parcel-apn"]`).toggleClass("hidden", showManualApnInput);
    $page.find(`input[name="manual-apn"]`).toggleClass("hidden", !showManualApnInput);
  };

  var setBmpName = function (newName) {
    if (newName === BmpFcsInventoryModalController.getExistingData("bmpName")) {
      restoreButtonsIfHasAllRequiredInputs();
      BmpFcsInventoryModalController.unsetFormDataField("bmpName");
    } else if (newName === "") {
      InventoryModal.allowedSaveState(false);
      BmpFcsInventoryModalController.unsetFormDataField("bmpName");
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
    } else {
      BmpFcsInventoryModalController.setFormDataField("bmpName", newName);
      restoreButtonsIfHasAllRequiredInputs();
    }
    BmpFcsInventoryModalController.setModalTitle();
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    await renderPageHtml();
    ModalMap.removeMarkerLayer();
    showLocationSelectionMap(options);
    loadSelectLocationListeners();
    disableUnavailableElements();
    setDateMaximum();
    toggleInstallDateField();
    togglePostActivePhase();
    if ($page.find(`select[name="associated-project"]`).select2) {
      $page.find(`select[name="associated-project"]`).select2();
    }
  };

  var checkCreateNewBmpFcsMarker = function () {
    var id = BmpFcsInventoryModalController.getLatestBmpInventoryData("idbmp");
    var isNewMarker = !id;
    return isNewMarker;
  };

  var showLocationSelectionMap = function (options) {
    const configuration = {
      checkNewMarkerHandler: checkCreateNewBmpFcsMarker,
      newExistingMarkerHandler: BmpFcsInventoryModalController.loadExistingBmpFcsCatchBasinMarker,
      locationDataUpdateHandler: updateSetLocationFooter,
      badLocationUpdateHandler: resetLatLngToLastValidValue,
    };
    LocationSelectionMap.configureLocationModalMapHandlers(configuration);
    LocationSelectionMap.showLocationSelectionMap(options?.existingLocation);

    setLocationMapText();
  };

  var updateSetLocationFooter = async function (locationInfo, latlng) {
    const promise = updateLocationInfo(locationInfo);
    updateLatLngFields(latlng);
    updateCentralizedBmpInformation(locationInfo.centralizedBmps);
    const $bmpName = $page.find("input[name='bmp-name']");
    if ($bmpName.attr("disabled")) {
      Misc.toggleDisabled($bmpName, false);
      $bmpName.focus();
    }

    return promise;
  };

  var setDateMaximum = function () {
    const maxDate = DateTime.getTodayIso();
    DateTimePicker.setDatePickerRange($page, "installation-date", true, maxDate);
  };

  var updateCentralizedBmpInformation = function (centralizedBmps) {
    BmpFcsInventoryModalController.setFormDataField("centralizedBmps", centralizedBmps);
  };

  var updateLocationInfo = function (locationInfo) {
    locationInfo = {
      catchmentGid: locationInfo.gid,
      catchmentName: locationInfo.catchid,
      receivingWaterName: locationInfo.drains_to_rw,
      landuseType: locationInfo.combo,
      groupName: locationInfo.gname,
      groupId: locationInfo.groupId,
      groupCityName: locationInfo.city,
      address: locationInfo.address,
      zip: locationInfo.zip,
      state: locationInfo.state,
      parcelApn: locationInfo.parcelid,
      maintenanceZone: locationInfo.maintenanceZone,
    };
    Misc.toggleDisabled($("#inventory-modal input[name='bmp-name']"), false);
    updateSelectedCatchments(locationInfo);
    BmpFcsInventoryModalController.setFormDataField("locationInfo", locationInfo);
    BmpFcsInventoryModalController.setFormDataField("groupId", locationInfo.groupId);
    setInputField("maintenance-zone", locationInfo.maintenanceZone);
    setInputField("parcel-apn", locationInfo.parcelApn);
    setInputField("address", locationInfo.address, true);
    setInputField("city", locationInfo.groupCityName, true);
    setInputField("zip", locationInfo.zip, true);
    setInputField("state", locationInfo.state, true);
    loadProjectOptions(locationInfo.groupId, selectAssociatedProjectFromInventoryData);

    return;
  };

  var selectAssociatedProjectFromInventoryData = function () {
    const associatedProjectId =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("associatedProject");
    if (associatedProjectId) {
      const projectOptionAvailable =
        $(`#inventory-modal [name='associated-project'] option[value=${associatedProjectId}]`)
          .length > 0;
      if (projectOptionAvailable) {
        $("#inventory-modal [name='associated-project']").val(associatedProjectId);
      } else {
        $("#inventory-modal [name='associated-project'] option[value='no-selection']")
          .prop("selected", true)
          .trigger("input");
      }
    }
  };

  var updateSelectedCatchments = function (locationInfo) {
    const AreaSelectionMap = require("../mapFunctions/areaSelectionMap");
    const selectedPolygons = AreaSelectionMap.getSelectedPolygons() || [];
    const selectedCatchments = selectedPolygons.reduce(
      (catchArray, polygon) => {
        if (!catchArray.includes(polygon.catch_name)) {
          catchArray.push(polygon.catch_name);
        }
        return catchArray;
      },
      [locationInfo.catchmentName],
    );
    BmpFcsInventoryModalController.setFormDataField(["selectedCatchments"], selectedCatchments);
  };

  var updateLatLngFields = function (latlng) {
    const latInput = $page.find(`input[name="latitude"]`)[0];
    const longInput = $page.find(`input[name="longitude"]`)[0];

    if (latInput && longInput) {
      NumberInput.manuallySetInput(latInput, latlng.lat);
      NumberInput.manuallySetInput(longInput, latlng.lng);
    }

    BmpFcsInventoryModalController.setFormDataField("latitude", latlng.lat);
    BmpFcsInventoryModalController.setFormDataField("longitude", latlng.lng);
    restoreButtonsIfHasAllRequiredInputs();
  };

  var setInputField = function (field, value, updateForm) {
    $page.find(`input[name=${field}]`).val(value);
    if (updateForm) {
      BmpFcsInventoryModalController.setFormDataField(field, value);
    }
  };

  var setLocationMapText = function () {
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      ModalMap.showLocationMessage("Click map or drag marker to change location");
    } else {
      ModalMap.showLocationMessage("Click map to set location");
    }
  };

  var renderPageHtml = async function () {
    const renderData = InventoryModal.getAllData("bmp-fcs-inventory");
    await addProps(renderData);

    const html = nunjucks.render(
      "modals/bmpFcsInventory/bmpFcsInventorySetLocation.njk",
      renderData,
    );

    BmpFcsInventoryModalController.renderPageContent(html);
    $inventoryModal = $("#inventory-modal");
    $page = $inventoryModal.find(".modal-dynamic-content");
    BmpFcsInventoryModalController.addHighlightsToFields($page);
  };

  var addProps = async function (renderData) {
    renderData.mode = BmpFcsInventoryModalController.getOptions()?.mode;
    renderData.dataView = Tree.get("dataView");
    renderData.assetDisplayName = BmpFcsFunctions.getAssetDisplayName(renderData.bmpType);
    renderData.phaseOptions = BmpFcsInventoryConstants.phaseOptions;
    renderData.displayPhase = renderData.phaseOptions.find(
      (option) => option.value === renderData.phase,
    )?.name;
    renderData.associatedProjectDisabled = renderData.associatedProject !== null;
    renderData.possibleAssociatedProjects?.forEach((project) => {
      if (project.projectIdentifier) {
        project.name = project.projectIdentifier;

        if (project.projectName) {
          project.name += ` - ${project.projectName}`;
        }
      }
    });
  };

  var disableUnavailableElements = function () {
    const bmpName = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpName");
    if (bmpName === undefined || bmpName === "") {
      InventoryModal.disableSaveButton();
      InventoryModal.disableNavigationButtons();
      Misc.toggleDisabled($inventoryModal.find("[name=bmp-name]"), true);
    } else {
      InventoryModal.restoreNavigationButtons();
    }
  };

  var updateLatLngFromInput = function () {
    if ($page.find(`input[name="latitude"]`).val() && $page.find(`input[name="longitude"]`).val()) {
      const e = {
        latlng: {
          lat: $page.find(`input[name="latitude"]`).val(),
          lng: $page.find(`input[name="longitude"]`).val(),
        },
      };
      LocationSelectionMap.locationSelectionMapClick(e);

      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var hasAllRequiredInputs = function () {
    const latitude = $page.find(`input[name="latitude"]`).val();
    const longitude = $page.find(`input[name="longitude"]`).val();
    const bmpName = $page.find(`input[name="bmp-name"]`).val();

    return latitude && longitude && bmpName;
  };

  var restoreButtonsIfHasAllRequiredInputs = function () {
    if (hasAllRequiredInputs()) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }
  };

  var resetLatLngToLastValidValue = function (latlng) {
    NumberInput.manuallySetInput($page.find(`input[name="latitude"]`)[0], latlng.lat);
    NumberInput.manuallySetInput($page.find(`input[name="longitude"]`)[0], latlng.lng);
  };

  var cleanUp = function () {
    removeListeners();
    LocationSelectionMap.hideLocationSelectionMap();
    $page.empty();
    return true;
  };

  var validateBmpName = async function () {
    const bmpName = BmpFcsInventoryModalController.getLatestBmpInventoryData("bmpName");
    const existingName = BmpFcsInventoryModalController.getExistingData("bmpName");
    if (bmpName === existingName) {
      return true;
    } else {
      const groupId = Tree.get("activeGroup", "groupId");
      const uniqueness = await ApiCalls.checkUniqueBmpName(groupId, bmpName);
      if (!uniqueness) {
        MessageModal.showSimpleWarningModal(
          "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
      return uniqueness;
    }
  };

  var validate = async function () {
    return validateBmpName().catch((err) => {
      throw err;
    });
  };

  var loadProjectOptions = async function (groupId, callback) {
    const possibleProjects = await ApiCalls.getProjectOptions(groupId);

    BmpFcsInventoryModalController.setFormDataField("possibleAssociatedProjects", possibleProjects);
    let opts = possibleProjects.map(function (item) {
      if (item.facilityName) {
        return `<option value="${item.id}">${item.name} - ${item.facilityName}</option>`;
      }
      return `<option value="${item.id}">${item.name}</option>`;
    });
    opts = ['<option value="no-selection">Select a project</option>'].concat(opts);

    var $select = $("#inventory-modal").find("[name=associated-project], [name=project_id]");
    if ($select.length) {
      $select.html(opts.join(""));
      Misc.toggleDisabled($select, possibleProjects.length === 0);
    }

    if (callback) {
      callback();
    }
  };

  var toggleInstallDateField = function (selectedProjectId = null) {
    const phase = BmpFcsInventoryModalController.getLatestBmpInventoryData("phase");
    const toggle = !SharedDataFunctions.isPhaseOrLater(phase, "construction");

    $page.find(".installation-date").toggleClass("hidden", toggle);
    if (selectedProjectId && !toggle) {
      const associatedProject = getAssociatedProject(selectedProjectId);
      if (associatedProject?.endDate) {
        Form.manuallySetDateTime(
          "bmp-fcs-inventory",
          ["installationDate"],
          associatedProject.endDate,
          $page.find("input[name=installation-date]"),
        );
      }
    }
  };

  var togglePostActivePhase = function () {
    const enablePostActivePhase = ToolSettings.getSetting(
      "construction",
      "enable-post-active-phase",
    );
    $page.find(`option[value="post-active"]`).toggle(enablePostActivePhase);
  };

  var setFacilityNameBasedOnAssociatedProject = function (selectedProjectId) {
    const associatedProject = getAssociatedProject(selectedProjectId);

    $page.find("input[name=facility]").attr("disabled", !!associatedProject);
    if (associatedProject) {
      $page.find(`input[name=facility]`).val(associatedProject.facilityName).trigger("input");
    }
  };

  var setResponsiblePartyDeliveryBasedOnAssociatedProject = function (selectedProjectId) {
    const associatedProject = getAssociatedProject(selectedProjectId);

    if (associatedProject) {
      associatedProject.responsiblePartyDelivery == "public"
        ? BmpFcsInventoryModalController.setFormDataField("responsiblePartyDelivery", "our-agency")
        : BmpFcsInventoryModalController.setFormDataField(
            "responsiblePartyDelivery",
            associatedProject.responsiblePartyDelivery,
          );
    }
  };

  var assertValidResponsiblePartyMaintenance = function (setValue) {
    const options = BmpFcsInventoryConstants.responsiblePartyMaintenanceOptions;

    if (!options.some((option) => option.value === setValue)) {
      throw new Error(
        `Trying to set responsiblePartyMaintenance to value without a configured option ${setValue}.`,
      );
    }

    return setValue;
  };

  var setResponsiblePartyMaintenance = function (setValue) {
    assertValidResponsiblePartyMaintenance(setValue);

    BmpFcsInventoryModalController.setFormDataField("responsiblePartyMaintenance", setValue);
  };

  var _setResponsiblePartyMaintenanceBasedOnAssociatedProject = function (selectedProjectId) {
    const associatedProject = getAssociatedProject(selectedProjectId);

    if (associatedProject?.responsiblePartyMaintenance) {
      associatedProject?.responsiblePartyMaintenance == "public"
        ? setResponsiblePartyMaintenance("our-agency")
        : setResponsiblePartyMaintenance(associatedProject.responsiblePartyMaintenance);
    }
  };

  var getAssociatedProject = function (selectedProjectId) {
    const noProjectAssociated = selectedProjectId === "" || selectedProjectId === "no-selection";

    if (noProjectAssociated) {
      return null;
    }

    const project = BmpFcsInventoryModalController.getLatestBmpInventoryData(
      "possibleAssociatedProjects",
    ).find((project) => project.id === parseInt(selectedProjectId));

    if (project === undefined) {
      throw new Error(
        `Couldn't find project with id ${selectedProjectId} in possibleAssociatedProjects.`,
      );
    }

    return project ?? null;
  };

  return {
    assertValidResponsiblePartyMaintenance,
    getStringKey,
    getHeaderInformation,
    setBmpName,
    render,
    renderPageHtml,
    cleanUp,
    validate,
    toggleInstallDateField,
    validateBmpName,
    updateSetLocationFooter,
    setPhaseBasedOnAssociatedProject,
    setFacilityNameBasedOnAssociatedProject,
    setResponsiblePartyDeliveryBasedOnAssociatedProject,
    _setResponsiblePartyMaintenanceBasedOnAssociatedProject,
  };
};

module.exports = BmpFcsInventorySetLocation();

const ApiCalls = require("../apiCalls");
const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const ToolSettings = require("../settings/toolSettings");
const DateTime = require("../dateTime");
const DateTimePicker = require("../general/dateTimePicker");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const LocationSelectionMap = require("../mapFunctions/locationSelectionMap");
const Misc = require("../misc");
const MessageModal = require("../modals/messageModal");
const ModalMap = require("../mapFunctions/modalMap");
const NumberInput = require("../general/numberInput");
const SharedDataFunctions = require("../general/sharedDataFunctions");
const Tree = require("../tree");
const BmpFcsFunctions = require("./bmpFcsFunctions");
