"use strict";

const MuniCatchBasinDrainageAreaLayer = function () {
  const stringKey = "muniCatchBasinDrainageArea";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadTreeUpdateListenersForMap(map, mapLayers);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerToggled(stringKey, mapId, function (isEnabled, sameSpatialFilter) {
      if (isEnabled) {
        if (
          mapLayers.muniCatchBasinDrainageAreaLayer &&
          (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
        ) {
          map.addLayer(mapLayers.muniCatchBasinDrainageAreaLayer);
        } else {
          loadLayer(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.muniCatchBasinDrainageAreaLayer)) {
          map.removeLayer(mapLayers.muniCatchBasinDrainageAreaLayer);
        }
      }
    });
  };

  var loadLayer = async function (mapId) {
    var filters = Actions.getFiltersByMapId(mapId);
    var dataPath = Actions.getLayerDataPathByMapId(mapId);

    Tree.set(["layers", stringKey, "isFetching"], true);
    const data = await ApiCalls.getMuniCatchBasinDrainages(filters);
    Tree.set(["layers", stringKey, dataPath], data);
    Tree.set(["layers", stringKey, "isFetching"], false);
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerDataUpdated(stringKey, mapId, function (data) {
      const geoJsonData = CleanData.cleanGeoJson(data);
      mapLayers.muniCatchBasinDrainageAreaLayer = createLayerAndAddToMap(
        map,
        mapLayers,
        geoJsonData,
        mapId,
      );
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data, mapId) {
    if (mapLayers.muniCatchBasinDrainageAreaLayer) {
      map.removeLayer(mapLayers.muniCatchBasinDrainageAreaLayer);
    }
    mapLayers.muniCatchBasinDrainageAreaLayer = createLayer(data, mapId);
    mapLayers.muniCatchBasinDrainageAreaLayer.addTo(map);
    return mapLayers.muniCatchBasinDrainageAreaLayer;
  };

  var createLayer = function (data, mapId) {
    var isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);
    const geoJsonLayer = L.GeoJSON.extend({
      options: {
        style: function (feature) {
          const properties = feature.properties;

          return BmpFcsIcon.getBmpDrainageAreaStyle(
            properties.bmpScore,
            properties.phase,
            Tree.get("layers", stringKey, isEnabledPath),
          );
        },
      },
    });

    var layer = new geoJsonLayer();
    layer.addData(data);

    return layer;
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    Tree.select("filters").on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.muniCatchBasinDrainageAreaLayer) &&
        Tree.get("layers", stringKey, "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = MuniCatchBasinDrainageAreaLayer();

const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const CleanData = require("../mapFunctions/cleanData");
const MapFunctions = require("../mapFunctions/mapFunctions");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const Actions = require("../actions");
