"use strict";

const GroupBasedMenuItems = function () {
  var saveSettings = function (data) {
    Tree.set("groupMenuSettings", data);
  };

  var get = function (tool = Tree.get("tool")) {
    return Tree.get("groupMenuSettings", tool);
  };

  return {
    saveSettings,
    get,
  };
};

module.exports = GroupBasedMenuItems();

const Tree = require("../tree");
