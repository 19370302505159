"use strict";

const MuniManholeGeoServerLayer = function () {
  const layerName = "muniManhole";
  const layerKey = "muniManholeLayer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: L.divIcon({
        iconSize: ManholesIcon._updateIconSizeOnZoom(),
        popupAnchor: [0, 5],
        className: feature.properties.icon.class,
      }),
    });
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const muniManholeGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, muniManholeGeoServerLayer);

    muniManholeGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniManholeGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeMuniLayerData(data);
  };

  var organizeMuniLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    return {
      id: data.id,
      groupId: data.group_id,
      geometry: geometry,
      manholeName: data.manhole_name,
      manholeType: data.manhole_type,
      address: data.address,
      cof: data.cof_final_text,
      coverShape: data.cover_shape,
      icon: RoutineMaintenanceIcon.getMaintenanceIcon(
        "muni-manhole",
        data.last_maintenance_inspection_id,
        data.last_maintenance_inspection_due,
      ),
      maintainerType: data.maintainer_type,
      implementerType: data.implementer_type,
      responsiblePartyMaintenance: data.responsible_party_maintenance,
      phase: data.phase,
      installDate: data.install_date,
      landOwnership: data.property,
      correctiveAction: JSON.parse(data.corrective_actions),
      enforcementLevel: JSON.parse(data.severity),
      manholeDiameter: data.manhole_diameter,
      manholeDiameterUnits: data.manhole_diameter_units,
      manholeLength: data.manhole_length,
      manholeLengthUnits: data.manhole_length_units,
      manholeWidth: data.manhole_width,
      manholeWidthUnits: data.manhole_width_units,
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      zone_names: data.zone_names,
      routine: data.routine_inspection_dates ? JSON.parse(data.routine_inspection_dates) : null,
      repair: data.repair_dates ? JSON.parse(data.repair_dates) : null,
    };
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
  };
};

module.exports = MuniManholeGeoServerLayer();

const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const RoutineMaintenanceIcon = require("../general/routineMaintenanceIcon");
const ManholesIcon = require("./manholes/manholesIcon");
