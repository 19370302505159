"use strict";

const IncentiveProgramsFilters = function () {
  const searchFields = ["programName"];
  const searchDbFields = ["programName"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          IncentiveProgramsGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var onlineFilterIsSet = function (filters) {
    return ResourceFilters.spatialFiltersAreSet(filters);
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      search: (datum, filters) =>
        ResourceFilters.passSearchStringFilter(datum, filters, searchFields),
      regions: ["regions", "array"],
      states: ["states", "array"],
      programTypes: "programType",
      compTypes: "compType",
    };
  };

  return {
    filterResourceDataHandler,
    searchDbFields,
    filterOffline,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = IncentiveProgramsFilters();

const ApiError = require("../../errors/apiError");
const IncentiveProgramsGeoServerLayer = require("../../esg/geoServer/incentiveProgramsGeoServerLayer");
const ResourceFilters = require("../../filters/resourceFilters");
