"use strict";

const DataExport = function () {
  var $exportModal;
  var selectedType;

  var init = function () {
    $exportModal = $("#export-modal");
    loadDataExportListeners();
  };

  var loadDataExportListeners = function () {
    $(".data-export-link").off("click", start);
    $(".data-export-link").on("click", start);

    $exportModal.off("click", ".export-button", handlExportButtonClick);
    $exportModal.on("click", ".export-button", handlExportButtonClick);
  };

  var start = function () {
    $exportModal.html(nunjucks.render("dynamicModals/dataExport.njk"));
    TypeSelection.show($exportModal.find(".modal-body"), setSelectedType);
    $exportModal.modal("show");
  };

  var setSelectedType = function (type) {
    if (type.value === undefined) {
      throw Error("Type must have a value property.");
    }
    if (type.name === undefined) {
      throw Error("Type must have a name property.");
    }
    selectedType = type;
  };

  var handlExportButtonClick = function () {
    exportData(selectedType);
  };

  var exportData = function (selectedType) {
    var exportType = selectedType.handler;
    var exportDataType = selectedType.value;
    var dataView = Tree.get("dataView");

    var url = getExportURL(exportType, exportDataType);

    var requestData = {
      service: dataView,
      dataType: exportDataType,
    };

    closeModal();
    showStartingMessage(exportDataType);
    getExportData(url, requestData);
  };

  var getExportData = async function (url, requestData) {
    try {
      const data = await ApiCalls.getExportData(url, requestData);

      if (!isEmailDataType(requestData.dataType)) {
        saveBlob(data);
      }
    } catch {
      $exportModal.modal("hide");
      MessageModal.showSimpleWarningModal(
        "The import has failed! Please try again later or contact us at <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for assistance.",
        true,
      );
    }
  };

  var saveBlob = function (data) {
    var a = document.createElement("a");
    const groupName = Tree.get("activeGroup", "name");
    const dateNow = DateTime.getTodayDisplayDate();
    var fileName = "2NFORM_" + groupName + "_" + dateNow;
    a.href = window.URL.createObjectURL(data);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent("click"));
  };

  var getExportURL = function (exportType, dataType) {
    const groupId = Tree.get("activeGroup", "groupId");
    const data = Tree.get("dataView");
    var url = {
      postgis: `/shapes/${groupId}/${data}`,
      excel: `/exports/${groupId}/${data}`,
      geoserver: `/geoserver/${groupId}/export`,
    };
    Analytics.sendAction(["export_data", `${dataType}_${exportType}`], { truncate: true });

    return url[exportType];
  };

  var closeModal = function () {
    var modalBody = $exportModal.find(".modal-body");
    var modalFooter = $exportModal.find(".modal-footer");

    TypeSelection.cleanUp();
    modalBody.empty();
    modalFooter.empty();
    $exportModal.modal("hide");
  };

  var showStartingMessage = function (dataType) {
    let message;

    if (isEmailDataType(dataType)) {
      message =
        "Your export is being prepared and will be emailed to you when ready. This may take a while for larger exports.";
    } else {
      message = "Your download will begin shortly.";
    }

    MessageModal.showSimpleWarningModal(message);
  };

  var isEmailDataType = function (dataType) {
    return dataType === "excel";
  };

  return {
    init,
    start,
  };
};

module.exports = DataExport();

const TypeSelection = require("./typeSelection");
const Analytics = require("../general/analytics");
const Tree = require("../tree");
const DateTime = require("../dateTime");
const ApiCalls = require("../apiCalls");
const MessageModal = require("../modals/messageModal");
