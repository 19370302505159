"use strict";

const CentralizedBmpLayer = function () {
  var markerReferences = {};

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled("centralizedBmps", mapId, function (isEnabled) {
      if (isEnabled) {
        loadCentralizedBmps(mapId);
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.centralizedBmpsLayer)) {
          map.removeLayer(mapLayers.centralizedBmpsLayer);
        }
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated("centralizedBmps", mapId, function (data) {
      const activeBmpId = BmpFcsInventoryModalController.getExistingData("idbmp");

      data = data.filter((item) => item.idbmp !== activeBmpId);

      mapLayers.centralizedBmpsLayer = addCentralizedBmpsLayer(
        map,
        mapLayers.centralizedBmpsLayer,
        data,
        mapId,
      );
    });
  };

  var loadCentralizedBmps = function (mapId) {
    var centralizedBmps =
      BmpFcsInventoryModalController.getLatestBmpInventoryData("centralizedBmps");

    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    Tree.set(["layers", "centralizedBmps", dataPath], centralizedBmps);
  };

  var addCentralizedBmpsLayer = function (map, centralizedBmpsLayer, data, mapId) {
    if (centralizedBmpsLayer) {
      map.removeLayer(centralizedBmpsLayer);
    }
    centralizedBmpsLayer = createCentralizedBmpMarkers(centralizedBmpsLayer, data, mapId);
    if (DrawController.getSelectedMethod() === "drawing-tools") {
      centralizedBmpsLayer = createCentralizedBmpDrainageAreas(centralizedBmpsLayer, data);
    }
    return centralizedBmpsLayer.addTo(map);
  };

  var createCentralizedBmpMarkers = function (centralizedBmpsLayer, data) {
    var geoJson = CleanData.cleanGeoJson(data);

    centralizedBmpsLayer = L.featureGroup();
    return L.geoJson(geoJson, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;
        const marker = L.marker(latlng, {
          draggable: false,
          icon: BmpFcsIcon.getIconMarker(
            null,
            properties.bmpTypeObj.isCentralized,
            properties.bmpTypeObj.abbreviation,
            null,
            null,
            null,
            "completed",
          ),
        });
        storeMarkerReference(properties.idbmp, marker);
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(nunjucks.render("popups/centralizedBmpPopup.njk", feature.properties));
      },
    }).addTo(centralizedBmpsLayer);
  };

  var show = function () {
    Actions.toggleLayer("centralizedBmps", true, "modal");
  };

  var hide = function () {
    Actions.toggleLayer("centralizedBmps", false, "modal");
  };

  var storeMarkerReference = function (idBmp, marker) {
    markerReferences[idBmp] = marker;
  };

  var getMarkerReference = function (idBmp) {
    return markerReferences[idBmp];
  };

  var createCentralizedBmpDrainageAreas = function (centralizedBmpsLayer, data) {
    var cbmpDrainageLayer = new L.featureGroup();
    cbmpDrainageLayer.id = "cbmpDrainageLayer";
    data.forEach((bmp) => {
      const drainage = bmp["dissolvedDrainage"];
      if (drainage.geom !== null) {
        const drainageGeoJson = L.geoJSON(JSON.parse(drainage.geom), {
          pane: "underCatchmentPane",
          bmp_id: bmp.bmp_id,
          idbmp: bmp.idbmp,
          style: MapStyles.styleCbmpDrainage,
        });
        drainageGeoJson.id = bmp.idbmp;
        drainageGeoJson.addTo(cbmpDrainageLayer);
      }
    });

    cbmpDrainageLayer.addTo(centralizedBmpsLayer);
    cbmpDrainageLayer.bringToBack();
    return centralizedBmpsLayer;
  };

  var addCentralizedBmpDrainageLayer = function (cbmpLayer = null) {
    if (!cbmpLayer) {
      cbmpLayer = ModalMap.getMapLayer("centralizedBmpsLayer");
    }
    const cbmpData = BmpFcsInventoryModalController.getLatestBmpInventoryData("centralizedBmps");
    if (!_findDissolvedDrainageLayer(cbmpLayer)) {
      createCentralizedBmpDrainageAreas(cbmpLayer, cbmpData);
    }
  };

  var removeCentralizedBmpDrainageLayer = function () {
    const cbmpLayer = ModalMap.getMapLayer("centralizedBmpsLayer");
    const dissolved = _findDissolvedDrainageLayer(cbmpLayer);
    cbmpLayer.removeLayer(dissolved);
  };

  var _findDissolvedDrainageLayer = function (featureGroup) {
    return featureGroup.getLayers().find((layer) => {
      return layer.id === "cbmpDrainageLayer";
    });
  };

  return {
    loadLayerListenersForMap,
    show,
    hide,
    getMarkerReference,
    addCentralizedBmpDrainageLayer,
    removeCentralizedBmpDrainageLayer,
  };
};

module.exports = CentralizedBmpLayer();

const Actions = require("../actions");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
const BmpFcsIcon = require("./bmpFcsIcon");
const CleanData = require("../mapFunctions/cleanData");
const ModalMap = require("../mapFunctions/modalMap");
const MapStyles = require("../mapFunctions/mapStyles");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const DrawController = require("../mapFunctions/drawController");
