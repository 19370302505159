"use strict";

const OutfallInspectionConstants = function () {
  // Structural attributes
  var getOutletMaterialOptions = function () {
    return FormSettings.getDropdownOptions("outlet-material", { tool: "muni" });
  };

  var getOutletTypeOptions = function () {
    return FormSettings.getDropdownOptions("outlet-type", { tool: "muni" });
  };

  var getOutfallTypeOptions = function () {
    return FormSettings.getDropdownOptions("outfall-type", { tool: "muni" });
  };

  var getOutletShapeOptions = function () {
    return FormSettings.getDropdownOptions("outlet-shape", { tool: "muni" });
  };

  var getPrecipitationTypeOptions = function () {
    return FormSettings.getDropdownOptions("precipitation-type", { tool: "muni" });
  };

  var getVegetationGrowthDescriptionOptions = function () {
    return FormSettings.getDropdownOptions("vegetation-growth-description", { tool: "muni" });
  };

  var getResponsiblePartyMaintenanceOptions = function () {
    return FormSettings.getDropdownOptions("maintainer-type", { tool: "muni" });
  };

  const circularShapes = ["circular", "other"];

  const lengthUnitOptions = [
    { value: "inches", name: "in" },
    { value: "feet", name: "ft" },
  ];

  const elevationUnitOptions = [
    { value: "feet", name: "ft" },
    { value: "inches", name: "in" },
    { value: "survey-feet", name: "survey-ft" },
  ];

  var getNewRoutineMaintenanceDefaults = function (outfallId, outfallInfo = {}) {
    const activeMaintenanceIssues =
      OutfallRoutineMaintenanceConstants.getRoutineRepairMaintenanceIssuesWithOptions(
        outfallInfo.activeMaintenanceIssues ?? [],
      );
    delete outfallInfo.activeMaintenanceIssues;

    const structuralConditionIssues =
      OutfallRoutineMaintenanceConstants.getDefaultStructuralConditionIssues();

    return {
      ...DryWeatherConstants.getNewDryWeatherScreeningDefaults(
        outfallId,
        "outfall",
        activeMaintenanceIssues,
      ),
      activeMaintenanceIssues,
      structuralConditionIssues: RoutineMaintenanceFormTable.filterIssuesBy(
        structuralConditionIssues,
        activeMaintenanceIssues,
      ),
      outfallInfo: outfallInfo,
      asset: outfallInfo?.asset,
      maintenanceType: getMaintenanceType(outfallInfo?.asset?.dryWeatherCondition),
    };
  };

  var getMaintenanceType = function (dryWeatherCondition) {
    return dryWeatherCondition === "open" ? "follow-up" : "routine";
  };

  var outletShapeIsCircular = function (outletShape) {
    const shapeOptions = getOutletShapeOptions().find((option) => option.value === outletShape);

    if (!outletShape) {
      return true;
    }

    if (!shapeOptions) {
      throw new Error(`No outlet shape options defined for shape ${outletShape}.`);
    }

    return circularShapes.includes(shapeOptions.value);
  };

  var addInspectionToDoFilterOptions = function (props) {
    props.typeOptions = [...getOutletTypeOptions()];
    props.responsiblePartyOptions = OutfallInventoryConstants.eswiftEntityTypeOptions;
    props.priorityOptions = [
      { value: "true", name: "Yes" },
      { value: "false", name: "No" },
    ];
    props.consequenceOfFailureOptions = [
      {
        name: "Very High",
        value: "very-high",
      },
      {
        name: "High",
        value: "high",
      },
      {
        name: "Moderate",
        value: "moderate",
      },
      {
        name: "Low",
        value: "low",
      },
      {
        name: "Very Low",
        value: "very-low",
      },
      {
        name: "Unknown",
        value: "unknown",
      },
    ];

    return props;
  };

  // @TODO: Safe to remove this function after a week or so
  var migrateOutletShape = function (data) {
    const outletShapeMigrationMap = {
      rundown: "other",
      headwall: "other",
      "trickle-pan": "other",
      "box-culvert": "rectangle",
    };
    const newOutletShape = outletShapeMigrationMap[data?.outletShape];

    if (newOutletShape === undefined) {
      return;
    }

    data.outletShape = newOutletShape;
  };

  return {
    getOutletMaterialOptions,
    getOutletTypeOptions,
    getOutfallTypeOptions,
    lengthUnitOptions,
    elevationUnitOptions,
    getPrecipitationTypeOptions,
    getNewRoutineMaintenanceDefaults,
    outletShapeIsCircular,
    addInspectionToDoFilterOptions,
    getOutletShapeOptions,
    getVegetationGrowthDescriptionOptions,
    getResponsiblePartyMaintenanceOptions,
    migrateOutletShape,
  };
};

module.exports = OutfallInspectionConstants();

const DryWeatherConstants = require("../illicitDischarge/dryWeatherScreening/dryWeatherConstants");
const FormSettings = require("../settings/formSettings");
const OutfallInventoryConstants = require("./outfallInventoryConstants");
const OutfallRoutineMaintenanceConstants = require("./outfallRoutineMaintenanceConstants");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
