"use strict";

const LidProjectLayer = function () {
  const MapLayer = require("../mapFunctions/mapLayer");

  const layerName = "lidProject";
  const layerKey = "lidProjectLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    mapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    mapLayer.loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadTabListenerForMap(map, mapLayers, mapId);
    resetSpatialFiltersOnDataViewSwitch(map, mapLayers);

    if (mapId === "main") {
      mapLayer.loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  var loadTabListenerForMap = function (map, mapLayers, mapId) {
    Tree.select("activeTab").on("update", function (e) {
      if (Tree.get("dataView") === "lid-project") {
        const activeTab = e.data.currentData;
        const lidBmpLayerSelected = MapFunctions.mapHasLayer(map, mapLayers.lidProjectBmpLayer);
        const lidProjectMapLayer = MapFunctions.mapHasLayer(map, mapLayers.lidProjectLayer);
        const lidProjectG2Layer = MapFunctions.mapHasLayer(
          map,
          mapLayers.postConstructionProjectG2Layer,
        );
        _updateDataAndStoreInTree();

        if (activeTab === "todo") {
          if (lidProjectG2Layer) {
            map.removeLayer(mapLayers.postConstructionProjectG2Layer);
          }
        } else if (activeTab === "data") {
          if (!lidBmpLayerSelected && !lidProjectMapLayer) {
            return;
          } else if (!lidBmpLayerSelected) {
            const data = Tree.get("layers", "postConstructionProjectG2", "data");
            if (data) {
              Tree.set(["layers", "postConstructionProjectG2", "isEnabled"], true);
              PostConstructionProjectGeoServerLayer.loadLayer();
            }
          } else {
            map.removeLayer(mapLayers.postConstructionProjectG2Layer);
          }

          if (lidProjectMapLayer) {
            map.removeLayer(mapLayers.lidProjectLayer);
            Tree.set(["layers", "lidProject", "isEnabled"], false);
          }
        }
      }
    });
  };

  var resetSpatialFiltersOnDataViewSwitch = function (map, mapLayers, mapId) {
    Tree.select("dataView").on("update", function (e) {
      Filters.resetSpatialFilters();
      _resetSpatialFiltersOnTree();
      Table.collapseAllTableSections();
      const layerName = "postConstructionProjectG2";
      const data = Tree.get(["layers", layerName, "data"]);
      const isLidRunOff = e.data.currentData === "lid-runoff";
      const hasLidBmpLayer = MapFunctions.mapHasLayer(map, mapLayers.lidProjectBmpLayer);
      const hasALidProjectLayer = MapFunctions.mapHasLayer(
        map,
        mapLayers.postConstructionProjectG2Layer,
      );

      if (isLidRunOff && hasALidProjectLayer) {
        map.removeLayer(mapLayers.postConstructionProjectG2Layer);
      }

      if (isLidRunOff && hasLidBmpLayer) {
        map.removeLayer(mapLayers.lidProjectBmpLayer);
      }

      if (isLidRunOff && data?.length > 0) {
        Tree.set(["layers", layerName, "data"], []);
      }
    });
  };

  var _resetSpatialFiltersOnTree = function () {
    Tree.set(["filters", "receivingWaters"], []);
    Tree.set(["filters", "catchments"], []);
    Tree.set(["filters", "spatialGroupingId"], null);
    Tree.set(["filters", "maintenanceZones"], []);
  };

  var loadPopupListeners = function (properties, $popup) {
    PostConstructionProjectPopupGeoServerLayer.onPopupOpen(properties);
  };

  var _updateDataAndStoreInTree = async function () {
    var data;
    const activeTab = Tree.get("activeTab");
    const filters = Filters.getDefaultFilters();
    const resource = ResourceController.get("postConstructionProjectG2");

    if (activeTab === "todo") {
      data = await resource.getAll();
    } else {
      data = await resource.getAll({
        filters: filters,
        refreshData: false,
        lodingScreen: false,
      });
    }
    Tree.set(["layers", "postConstructionProjectG2", "data"], data);
  };

  var createLidProjectMarker = function (latlng, properties, mapId) {
    const isTodo = Tree.get("activeTab") === "todo";
    return L.marker(latlng, {
      icon: LidIcon.getLidProjectMapIcon(properties, isTodo),
    });
  };

  var _createIcon = function (properties, zoom, latlng) {
    const isTodo = Tree.get("activeTab") === "todo";
    const options = MapFunctions.resizeIconsOnZoom(
      zoom,
      40,
      20,
      "icon-text-large",
      "icon-text-medium",
    );
    return LidIcon.getLidProjectMapIcon(
      properties,
      isTodo,
      null,
      options.size,
      options.showText,
      options.textCSSClass,
    );
  };

  var getCurrentData = function (mapId) {
    return LayerDataFunctions.getCurrentLayerData("lidProject", mapId);
  };

  var loadLidProjectLayerData = function (filters) {
    return ApiCalls.getLidProjects(filters);
  };

  var getLidProjectGeoServerPopupHtmlFunction = function (properties) {
    return PostConstructionProjectPopupGeoServerLayer.getPopupHtml(properties);
  };

  const mapLayer = new MapLayer(layerName, layerKey, loadLidProjectLayerData, {
    createMarkerFunction: createLidProjectMarker,
    getPopupHtmlFunction: getLidProjectGeoServerPopupHtmlFunction,
    onPopupOpenFunction: loadPopupListeners,
    clusteringEnabled: true,
    itemCountEnabled: true,
    createIcon: _createIcon,
    getGeoServerPopupHtmlFunction: getLidProjectGeoServerPopupHtmlFunction,
  });

  return {
    ...mapLayer,
    getCurrentData,
    loadLayerListenersForMap,
    _createIcon,
  };
};

module.exports = LidProjectLayer();

const LidIcon = require("./lidIcon");
const LayerDataFunctions = require("../general/layerDataFunctions");
const ApiCalls = require("../apiCalls");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const PostConstructionProjectPopupGeoServerLayer = require("../constructionG2/postConstructionProjectPopupGeoServerLayer");
const Filters = require("../mapFunctions/filters");
const Table = require("../mapFunctions/table");
const ResourceController = require("../offline/resourceController");
const PostConstructionProjectGeoServerLayer = require("../constructionG2/postConstructionProjectGeoServerLayer");
