"use strict";

const IncidentInvestigationGeneralInfo = function () {
  const stringKey = "general-info";
  const tabHeader = "General Info";
  let $page;
  let defaultData;

  var loadListeners = function (data) {
    defaultData = data;
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("2N:FormInput", `[name="investigation-start-time"]`, onInvestigationStartTimeChange);

    flatpickr("#investigation-start-time", {
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      dateFormat: "H:i",
    });
    toggleLocationSection(data.incidentInfo.requestingType);
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="investigation-start-time"]`, onInvestigationStartTimeChange);
  };

  var getProps = function (data) {
    return {
      precipTypeOptions: IncidentInvestigationConstants.getPrecipitationTypeOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var toggleLocationSection = function (requestingType) {
    const show = requestingType === "dry-weather-screen";
    $page.find(`.location-section`).toggle(show);
  };

  var onInvestigationStartTimeChange = function (
    e,
    newValue,
    pathParts,
    formState,
    formKey,
    oldValue,
  ) {
    const startTime = newValue;
    const endTime =
      IncidentInvestigationController.getFormData(["investigationEndTime"]) ||
      defaultData.investigationEndTime;

    if (endTime && startTime > endTime) {
      MessageModal.showSimpleWarningModal(
        "Investigation Start Time must be earlier than Investigation End Time.",
      );
      if (!oldValue) {
        oldValue = defaultData.investigationStartTime;
      }
      Form.manuallySetDateTime(
        "incident-investigation",
        ["investigationStartTime"],
        oldValue,
        $(e.currentTarget),
      );
    } else {
      IncidentInvestigationController.renderInvestigationTotalTime(startTime, endTime);
    }
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = IncidentInvestigationGeneralInfo();

const Form = require("../../general/form");
const IncidentInvestigationController = require("./incidentInvestigationController");
const IncidentInvestigationConstants = require("./incidentInvestigationConstants");
const MessageModal = require("../../modals/messageModal");
const flatpickr = require("flatpickr");
