"use strict";

const RecordSweepModal = function () {
  const $modal = $("#record-sweep-modal");
  const formKey = "record-sweep";
  var inited = false;
  var props;
  var debrisCollectedUnitSetting;
  var useOdometerSetting;

  var init = function () {
    loadDomListeners();

    debrisCollectedUnitSetting = ToolSettings.getSetting("streetSweeping", "debris-collected-unit");
    useOdometerSetting = ToolSettings.getSetting("streetSweeping", "use-odometer");

    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-button", saveRecordSweep);
    $modal.on("click", ".save-button", saveRecordSweep);
    $modal.off("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.on("click", ".cancel-button", _closeModalIfConfirmed);
    $modal.off("2N:FormInput", `input[name="broom-miles-swept"]`, updatePercentMilesSwept);
    $modal.on("2N:FormInput", `input[name="broom-miles-swept"]`, updatePercentMilesSwept);
    $modal.off("2N:FormInput", `input[name="starting-odometer"]`, handleOdometerUpdate);
    $modal.on("2N:FormInput", `input[name="starting-odometer"]`, handleOdometerUpdate);
    $modal.off("2N:FormInput", `input[name="ending-odometer"]`, handleOdometerUpdate);
    $modal.on("2N:FormInput", `input[name="ending-odometer"]`, handleOdometerUpdate);
    $modal.off("2N:FormInput", `input[name="date"]`, updateWeeksSinceLastSweep);
    $modal.on("2N:FormInput", `input[name="date"]`, updateWeeksSinceLastSweep);
    $modal.off("click", ".save-record-btn", saveNewSweep);
    $modal.on("click", ".save-record-btn", saveNewSweep);
    $modal.on("click", ".delete-sweep-btn", deleteSweep);
  };

  var saveRecordSweep = async function () {
    if (props.bulkModal && (Form.getFormDataAtPath(formKey, ["newSweeps"]) || []).length === 0) {
      MessageModal.showSimpleWarningModal("Please save at least one sweeping record.");
      return;
    }

    await Form.getReadyToSavePromise(formKey);
    var formUpdates = Form.getDataFromForm(formKey, false);
    var recordSweepData;

    if (props.recordSweepId) {
      if ($.isEmptyObject(formUpdates)) {
        close();
        return;
      }
      recordSweepData = Object.assign({ id: props.recordSweepId }, formUpdates);
    } else {
      formUpdates["groupId"] = props.group_id;
      formUpdates["networkName"] = props.network_name || props.route_name;
      formUpdates["catchName"] = props.catch_id || props.catchid;
      formUpdates["totalMilesSwept"] = props.totalMilesSwept;

      const defaultFormData = getDefaultFormData();
      recordSweepData = Object.assign({}, defaultFormData, formUpdates);
    }

    if (!recordSweepData.date) {
      MessageModal.showSimpleWarningModal("Please enter a valid date");
      return;
    }

    const invalidWarning = getInvalidWarningForSaveRecordSweepIfExists(recordSweepData);
    if (invalidWarning) {
      MessageModal.showSimpleWarningModal(invalidWarning);
      return;
    }

    await ApiCalls.saveRecordSweep(recordSweepData);

    if (!props.bulkModal) {
      StreetSweepingPopup.loadRecordSweepHistory(
        props.network_name || props.route_name,
        props.catch_id,
      );
    }
    $modal.find(".modal-footer .save-btn").addClass("disabled").text("Saving");
    close();
  };

  var getInvalidWarningForSaveRecordSweepIfExists = function (
    recordSweepData,
    bulkIndividualRecord = false,
  ) {
    if (useOdometerSetting) {
      const starting =
        recordSweepData.startingOdometer === undefined
          ? props.startingOdometer
          : recordSweepData.startingOdometer;
      const ending =
        recordSweepData.endingOdometer === undefined
          ? props.endingOdometer
          : recordSweepData.endingOdometer;

      return getInvalidOdometerWarningIfExists(starting, ending, bulkIndividualRecord);
    } else {
      return getInvalidMilesSweptWarningIfExists(recordSweepData.broomMilesSwept);
    }
  };

  var _closeModalIfConfirmed = function () {
    if (Object.keys(Form.getDataFromForm(formKey, false)).length === 0) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        null,
        close,
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
      );
    }
  };

  var renderAndShowBulkModal = function () {
    Analytics.sendMapEvent("bulk-record-sweep");
    renderAndShow(null, null, true);
  };

  var renderAndShow = function (streetSweepingPopupData, existingData, bulkModal = false) {
    if (!inited) init();

    var defaultFormData = getDefaultFormData();

    if (bulkModal) {
      props = Object.assign({}, defaultFormData, { group_id: Tree.get("activeGroup", "groupId") });
    } else if (existingData) {
      props = Object.assign({}, streetSweepingPopupData, existingData);
    } else {
      props = Object.assign({}, streetSweepingPopupData, defaultFormData);
    }
    props.sweeperTypeSelectOptions = RoadBulkOmOptions.sweeperTypeSelectOptions;
    props.curbAccessibilitySelectOptions = RecordSweepConstants.curbAccessibilitySelectOptions;
    props.debrisCollectedUnitOptions = RecordSweepConstants.debrisCollectedUnitOptions;

    if (bulkModal) {
      props.bulkModal = bulkModal;

      if (Tree.get("sweepingNetworks")?.items?.length) {
        props.hasSweepingNetwork = true;
        props.sweepingNetworks = Tree.get("sweepingNetworks").items;
        props.sweepingNetworks.forEach((item) => {
          const routeName = Session.isNewDataDrivenRoadConditionGroup()
            ? item.route_name
            : item.network_name;
          item.name = routeName;
          item.value = routeName;
        });
        props.sweepingNetworks.sort((a, b) => ("" + a.name).localeCompare(b.name));
      } else {
        props.catchments = Tree.get("catchments")?.items || [];
        props.catchments.forEach((item) => {
          item.name = item.catchid;
          item.value = item.catchid;
        });
      }
    } else {
      props.hasSweepingNetwork = !!(
        streetSweepingPopupData?.network_name || streetSweepingPopupData?.route_name
      );
    }

    props.displayDebrisCollectedUnit = props.debrisCollectedUnitOptions.getObjectWith(
      "value",
      props.debrisCollectedUnit,
    )?.name;

    props.useOdometer = useOdometerSetting;
    if (
      useOdometerSetting &&
      props.broomMilesSwept &&
      !props.startingOdometer &&
      !props.endingOdometer
    ) {
      props.disableOdometer = true;
    }

    const template = bulkModal
      ? "modals/muni/bulkRecordSweepModal.njk"
      : "modals/muni/recordSweepModal.njk";
    const html = nunjucks.render(template, props);
    $modal.html(html);
    $modal.modal("show");
    Form.initializeAndLoadListeners($modal, formKey);
    Analytics.sendScreenView("modal", formKey);
    renderPercentMilesSwept();
    renderWeeksSinceLastSweep();
    renderOdometerMilesSwept(props.startingOdometer, props.endingOdometer);
  };

  var getDefaultFormData = function () {
    return {
      personnel: Session.loadContext().full_name,
      date: DateTime.getIsoString(new Date()),
      sweeperType: "mechanical_broom",
      curbAccessibility: "greater_than_75",
      debrisCollectedUnit: debrisCollectedUnitSetting,
    };
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
  };

  var updatePercentMilesSwept = function (e, value, path) {
    renderPercentMilesSwept(value);
  };

  var renderPercentMilesSwept = function (broomMilesSwept = props.broomMilesSwept) {
    var percentSweptText = getPercentSweptText(broomMilesSwept, props.curb_length);
    $modal.find(".percent-swept").text(percentSweptText);
  };

  var getPercentSweptText = function (milesSwept, curbLength) {
    if (((milesSwept && !isNaN(milesSwept)) || milesSwept === 0) && curbLength) {
      const percentSwept = Math.round((milesSwept / curbLength) * 100);
      return `${percentSwept}%`;
    } else {
      return "—";
    }
  };

  var handleOdometerUpdate = function (e, value, path) {
    const inputName = e.target.name;
    let startingValue, endingValue;

    if (inputName === "starting-odometer") {
      startingValue = parseFloat(value);
      const currentFormEndingValue = Form.getFormDataAtPath(formKey, ["endingOdometer"]);
      if (currentFormEndingValue === null) {
        endingValue = parseFloat(props.endingOdometer);
      } else {
        endingValue = parseFloat(currentFormEndingValue);
      }
    } else if (inputName === "ending-odometer") {
      const currentFormStartingValue = Form.getFormDataAtPath(formKey, ["startingOdometer"]);
      if (currentFormStartingValue === null) {
        startingValue = parseFloat(props.startingOdometer);
      } else {
        startingValue = parseFloat(currentFormStartingValue);
      }
      endingValue = parseFloat(value);
    }

    renderOdometerMilesSwept(startingValue, endingValue);
  };

  var renderOdometerMilesSwept = function (startingOdometer, endingOdometer) {
    if (isValidOdometerNumber(startingOdometer) && isValidOdometerNumber(endingOdometer)) {
      const odometerMiles = endingOdometer - startingOdometer;
      const odometerMilesSweptText = NunjucksFilters.commafyWithPlaces(odometerMiles, 1) + " miles";
      $modal.find(".odometer-miles-swept").text(odometerMilesSweptText);
    }
  };

  var updateWeeksSinceLastSweep = function (e, value, path) {
    renderWeeksSinceLastSweep();
  };

  var renderWeeksSinceLastSweep = function () {
    const weeksSinceLastSweep = getWeeksSinceLastSweep();
    var weeksSinceLastSweepText;
    if (weeksSinceLastSweep) {
      weeksSinceLastSweepText = `${weeksSinceLastSweep} weeks`;
    } else {
      weeksSinceLastSweepText = "—";
    }
    $modal.find(".weeks-since-last-sweep").text(weeksSinceLastSweepText);
  };

  var getWeeksSinceLastSweep = function () {
    const history = Tree.get("recordSweepHistory");
    if (history?.length) {
      let lastRecordSweep;
      if (props.recordSweepId) {
        const currentIndex = getRecordSweepHistoryIndexById(props.recordSweepId);
        const previousSweep = history[currentIndex + 1];
        if (previousSweep) {
          lastRecordSweep = previousSweep;
        }
      } else {
        lastRecordSweep = history[0];
      }
      if (lastRecordSweep) {
        const lastRecordSweepDate = lastRecordSweep.displayDate;
        const lastSweepMoment = Flatpickr.parseDate(lastRecordSweepDate, "m/d/Y");
        const currentSweepDate = DateTimePicker.getDate($modal, "date");
        if (currentSweepDate && lastSweepMoment) {
          return DateTime.getWeeksDifference(currentSweepDate, lastSweepMoment);
        }
      }
    }
  };

  var editHistory = function (tableRowData) {
    const popupData = $(".popup-general[data-popup-type]").data();
    const id = popupData.objectId || popupData.gid;
    const streetSweepingPopupData = Tree.get(["streetSweepingPopupData", id]);

    const selectedId = tableRowData.id;
    const recordSweepData = getRecordSweepById(selectedId);

    renderAndShow(streetSweepingPopupData, recordSweepData);
  };

  var getRecordSweepById = function (selectedId) {
    const recordSweepHistory = Tree.get("recordSweepHistory");
    if (recordSweepHistory && recordSweepHistory.length) {
      return recordSweepHistory.find((record) => record.id === selectedId);
    }
    return null;
  };

  var getRecordSweepHistoryIndexById = function (selectedId) {
    const recordSweepHistory = Tree.get("recordSweepHistory");
    if (recordSweepHistory && recordSweepHistory.length) {
      return recordSweepHistory.findIndex((record) => record.id === selectedId);
    }
    return null;
  };

  var saveNewSweep = function () {
    const newSweepData = Form.getFormDataAtPath(formKey, ["newSweep"]) || {};

    const hasRequiredMiles = useOdometerSetting
      ? newSweepData.startingOdometer && newSweepData.endingOdometer
      : newSweepData.broomMilesSwept;
    const hasRequiredSpatialName = !!(newSweepData.networkName || newSweepData.catchName);

    if (hasRequiredSpatialName && hasRequiredMiles && newSweepData.curbAccessibility) {
      const invalidWarning = getInvalidWarningForSaveRecordSweepIfExists(newSweepData, true);
      if (invalidWarning) {
        MessageModal.showSimpleWarningModal(invalidWarning);
        return;
      }

      const index = (Form.getFormDataAtPath(formKey, ["newSweeps"]) || []).length;
      const newSweep = {
        ...newSweepData,
        id: Date.now(),
      };
      Form.manuallySetFormDataField(formKey, ["newSweeps", index], newSweep);

      newSweep.displayCurbAccessibility = props.curbAccessibilitySelectOptions.getObjectWith(
        "value",
        newSweep.curbAccessibility,
      )?.name;

      insertNewSweepRecord(newSweep);
      resetSweepFields();
    } else {
      MessageModal.showSimpleWarningModal("Enter a value for all fields to save.");
    }
  };

  var insertNewSweepRecord = function (newSweep) {
    const html = nunjucks.render("modals/muni/newSweep.njk", {
      sweep: newSweep,
      useOdometer: useOdometerSetting,
    });
    $modal.find(".new-sweeps-div").prepend(html);
    calculateTotalMilesSwept();
  };

  var deleteSweep = function (e) {
    const existingSweeps = Form.getFormDataAtPath(formKey, ["newSweeps"]) || [];
    const idToDelete = parseInt($(e.currentTarget).parent(".sweep").attr("id"));

    const index = existingSweeps.findIndex((sweep) => {
      return sweep?.id === idToDelete;
    });
    Form.manuallyUnsetField(formKey, ["newSweeps", index]);

    $(e.currentTarget).parent(".sweep").remove();

    calculateTotalMilesSwept();
  };

  var resetSweepFields = function () {
    const curbAccessibility = Form.getFormDataAtPath(formKey, ["newSweep", "curbAccessibility"]);
    const resetValues = {
      broomMilesSwept: null,
      startingOdometer: null,
      endingOdometer: null,
      catchName: null,
      networkName: null,
      curbAccessibility: curbAccessibility,
    };

    $modal.find(`[name="new-sweep[catch-name]"]`).val(resetValues.catchName);
    $modal.find(`[name="new-sweep[network-name]"]`).val("no-selection");
    if (useOdometerSetting) {
      NumberInput.manuallySetInput($modal.find(`[name="new-sweep[starting-odometer]"]`)[0], "");
      NumberInput.manuallySetInput($modal.find(`[name="new-sweep[ending-odometer]"]`)[0], "");
    } else {
      NumberInput.manuallySetInput($modal.find(`[name="new-sweep[broom-miles-swept]"]`)[0], "");
    }

    Form.manuallySetFormDataField(formKey, ["newSweep"], resetValues);
  };

  var calculateTotalMilesSwept = function () {
    let totalMiles = 0;

    const existingSweeps = Form.getFormDataAtPath(formKey, ["newSweeps"]) || [];
    existingSweeps.forEach((sweep) => {
      if (useOdometerSetting) {
        const miles = Number(sweep.endingOdometer) - Number(sweep.startingOdometer);
        totalMiles += miles;
      } else {
        totalMiles += Number(sweep.broomMilesSwept);
      }
    });

    props.totalMilesSwept = totalMiles;
    $modal
      .find(".total-miles-swept")
      .text(NunjucksFilters.commafyWithPlaces(totalMiles, 1) + " Miles");
  };

  var getCurrentMaxOdometer = function () {
    const existingSweeps = Form.getFormDataAtPath(formKey, ["newSweeps"]) || [];

    if (existingSweeps.length > 0) {
      return existingSweeps[existingSweeps.length - 1].endingOdometer;
    }

    return 0;
  };

  var getInvalidMilesSweptWarningIfExists = function (milesSwept) {
    if (milesSwept > 500) {
      return "The total miles swept exceeds 500 miles. Please enter a different value.";
    }
  };

  var getInvalidOdometerWarningIfExists = function (
    startingOdometer,
    endingOdometer,
    bulkIndividualRecord = false,
  ) {
    if (
      (!props.bulkModal || bulkIndividualRecord) &&
      (!isValidOdometerNumber(startingOdometer) || !isValidOdometerNumber(endingOdometer))
    ) {
      return "Enter valid odometer values to save.";
    }

    if (props.bulkModal && bulkIndividualRecord && startingOdometer < getCurrentMaxOdometer()) {
      return "Starting Odometer must be greater than the Ending Odometer of the last record.";
    }

    if (endingOdometer <= startingOdometer) {
      return "Ending Odometer must be greater than Starting Odometer.";
    }

    if (endingOdometer - startingOdometer > 500) {
      return "The total miles swept exceeds 500 miles. Please enter a different value.";
    }
  };

  var isValidOdometerNumber = function (num) {
    return num !== undefined && num !== null && num !== "" && !isNaN(num);
  };

  var confirmDeleteHistory = function ($todoTr, tableRowData) {
    var selectedId = tableRowData.id;

    MessageModal.showDeleteRecordModal(() => {
      deleteHistory(selectedId);
      $todoTr.remove();
    });
  };

  var deleteHistory = function (id) {
    ApiCalls.deleteRecordSweep(id, () => {});
  };

  return {
    getPercentSweptText,
    getWeeksSinceLastSweep,
    renderAndShow,
    close,
    _closeModalIfConfirmed,
    editHistory,
    renderAndShowBulkModal,
    saveNewSweep,
    confirmDeleteHistory,
  };
};

module.exports = RecordSweepModal();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const DateTime = require("../dateTime");
const DateTimePicker = require("../general/dateTimePicker");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const NumberInput = require("../general/numberInput");
const NunjucksFilters = require("../general/nunjucksFilters");
const RecordSweepConstants = require("./recordSweepConstants");
const RoadBulkOmOptions = require("../bulkSelect/roadBulkOmOptions");
const Session = require("../login/session");
const StreetSweepingPopup = require("./streetSweepingPopup");
const Tree = require("../tree");
const Flatpickr = require("flatpickr");
const ToolSettings = require("../settings/toolSettings");
