"use strict";

const BmpFcsToDoController = function () {
  var updateBmpFcsLayersByTodo = function () {
    const dataView = Tree.get("dataView");
    const bmpFcsUpdateTodoLayerMap = {
      "muni-catch-basin": updateBmpFcsTodos,
      bmp: updateBmpFcsTodos,
      fcs: updateBmpFcsTodos,
    };

    bmpFcsUpdateTodoLayerMap[dataView]?.();

    if (bmpFcsUpdateTodoLayerMap[dataView]) {
      setTodoMapBmpFcsRadioButtons();
    }
  };

  var updateBmpFcsTodos = function () {
    var bmpFcsLayerName = DataViewFunctions.getCurrentDataViewProperty("defaultLayers")[0];
    MainMap.setupAndFilterFullDrainageLayer(bmpFcsLayerName);
  };

  var setTodoMapBmpFcsRadioButtons = function () {
    var activeTab = Tree.get("activeTab");
    var tool = Tree.get("tool");
    var $floatingLegend = $("#main-legend");

    if (activeTab === "todo") {
      if (tool === "trashram") {
        $floatingLegend.find(".bmp-group").hide();
      } else if (tool === "bmpram") {
        $floatingLegend.find(".fcs-group").hide();
      }
    } else {
      $floatingLegend.find(".bmp-group").show();
      $floatingLegend.find(".fcs-group").show();
    }
  };

  var organizeRepairData = function (allToDos) {
    const result = Misc.shallowCloneArrayObjects(allToDos);

    for (const toDo of result) {
      toDo.routineRepair = structuredClone(toDo.routineRepair);
      ToDoFunctions.flattenAdditionalInfoAndCloneExtrasOntoEndOfArray(
        result,
        toDo,
        "routineRepair",
      );

      mapFieldsInfo(toDo, toDo.routineRepair?.dueDate);
      addMaintenanceInfo(toDo, toDo.routineRepair);
    }

    return result;
  };

  var mapFieldsInfo = function (toDo, dueDate) {
    toDo.priority = toDo.catchBasinPriority;
    toDo.facilityName = toDo.facility;
    toDo.address = toDo.fullAddress;

    toDo.relatedId = toDo.id;
    toDo.relatedType = "muni-catch-basin";

    ToDoFunctions.addCommonDueDateFields(toDo, dueDate);
  };

  var addMaintenanceInfo = function (toDo, routineRepair = null) {
    toDo.correction = toDo.correction ?? routineRepair?.additionalInfo?.key;
    toDo.level = toDo.level ?? routineRepair?.additionalInfo?.level;

    const task = BmpFcsInventoryConstants.findMaintenanceTask(toDo.correction);
    toDo.correctionDisplayText = task?.name;

    if (toDo.level) {
      toDo.displayLevel = getDisplayLevel(toDo.level);
    }
  };

  var addOutfallRepairConditions = function (repairTodos) {
    repairTodos.forEach((todo) => {
      var task = OutfallRoutineMaintenanceConstants.findMaintenanceTask(todo.correctiveAction);
      todo.correctionDisplayText = task?.name;
    });

    return repairTodos;
  };

  var handleConditionPhaseToggleDisplay = function (activeTab) {
    $(".condition-phase-toggle").toggle(activeTab !== "todo");
    $(".condition-label").toggle(activeTab === "todo");

    const toggle =
      activeTab === "todo" ? "condition" : Tree.get(["filters", "conditionPhaseToggle"]);
    BmpLayer.handleLegendDisplayByConditionPhaseToggle(toggle);
  };

  var addRepairConditions = function (repairTodos) {
    repairTodos.forEach(addMaintenanceInfo);
    return repairTodos;
  };

  var getDisplayLevel = function (level) {
    const conditionMap = ToolSettings.getSetting("constants", "routineMaintenanceConditionMap");
    const displayLevel = conditionMap[level];

    if (!displayLevel) {
      throw new Error(`Unknown level ${level}`);
    }

    return displayLevel;
  };

  var organizeCatchBasinInventoryIncompleteData = function (allToDos) {
    allToDos = Misc.shallowCloneArrayObjects(allToDos);

    for (const toDo of allToDos) {
      toDo.catchBasinInventoryIncomplete = structuredClone(toDo.catchBasinInventoryIncomplete);
      mapFieldsInfo(toDo, toDo.catchBasinInventoryIncomplete?.dueDate);
      addDisplayRequiredFields(toDo, toDo.catchBasinInventoryIncomplete.additionalInfo);
    }

    return allToDos;
  };

  var addDisplayRequiredFields = function (toDo, displayRequiredFields) {
    toDo.displayRequiredFields = getBmpFcsInventoryDisplayFields(displayRequiredFields);
    toDo.numMissingRequiredFields = toDo.displayRequiredFields.length;
  };

  var addBmpFcsRequiredFieldStrings = function (toDos) {
    for (const toDo of toDos) {
      const requiredFields = toDo.requiredFields ?? [];
      addDisplayRequiredFields(toDo, requiredFields);
    }
  };

  var getBmpFcsInventoryDisplayFields = function (fields) {
    var requiredFieldsReadable = [];
    fields.forEach((field) => {
      const displayField = getBmpFcsInventoryFieldDisplayName(field);
      requiredFieldsReadable.push(displayField);
    });
    return requiredFieldsReadable;
  };

  var getBmpFcsInventoryFieldDisplayName = function (field) {
    const readableField = BmpFcsInventoryConstants.bmpFcsInventoryFieldLabels[field];
    if (readableField === undefined) {
      console.error(`Unknown required field: ${field}`);
    }
    return readableField;
  };

  var organizeRoutineSiteVisitData = function (allToDos) {
    allToDos = Misc.shallowCloneArrayObjects(allToDos);

    for (const toDo of allToDos) {
      toDo.routineSiteVisit = structuredClone(toDo.routineSiteVisit);
      mapFieldsInfo(toDo, toDo.routineSiteVisit?.dueDate);
    }

    return allToDos;
  };

  var canAssess = function () {
    return UserPermissions.getPermission("can_assess_bmps") ?? false;
  };

  var canCreate = function () {
    return UserPermissions.getPermission("can_create_bmps") ?? false;
  };

  var canInspect = function () {
    return UserPermissions.getPermission("can_inspect_bmps") ?? false;
  };

  var possiblyEditBmp = function () {
    return UserPermissions.getPermission("possibly_edit_bmp") ?? false;
  };

  var organizeRoutineSiteVisitBmpData = function (allToDos) {
    allToDos = Misc.shallowCloneArrayObjects(allToDos);

    for (const toDo of allToDos) {
      toDo.routineSiteVisitBmp = structuredClone(toDo.routineSiteVisitBmp);
      mapFieldsInfo(toDo, toDo.routineSiteVisitBmp?.dueDate);
      addMuniBmpSharedData(toDo);
    }

    return allToDos;
  };

  var organizeRoutineRepairBmpData = function (allToDos) {
    const result = Misc.shallowCloneArrayObjects(allToDos);

    for (const toDo of result) {
      toDo.routineRepairBmp = structuredClone(toDo.routineRepairBmp);
      ToDoFunctions.flattenAdditionalInfoAndCloneExtrasOntoEndOfArray(
        result,
        toDo,
        "routineRepairBmp",
      );

      addMuniBmpSharedData(toDo);
      mapFieldsInfo(toDo, toDo.routineRepairBmp?.dueDate);
      addMaintenanceInfo(toDo, toDo.routineRepairBmp);
    }

    return result;
  };

  var addMuniBmpSharedData = function (toDo) {
    toDo.ownership = toDo.displayOwnership;
    toDo.tableIcon = MuniBmpIcon.getIconInfo(
      toDo.bmpTypeObj.isCentralized,
      toDo.condition,
      toDo.isDue,
      toDo.bmpTypeObj.abbreviation,
    ).class;
    toDo.facilityName = toDo.facility;
  };

  return {
    setTodoMapBmpFcsRadioButtons,
    updateBmpFcsLayersByTodo,
    organizeCatchBasinInventoryIncompleteData,
    organizeRoutineSiteVisitData,
    organizeRepairData,
    organizeRoutineSiteVisitBmpData,
    organizeRoutineRepairBmpData,
    addOutfallRepairConditions,
    handleConditionPhaseToggleDisplay,
    addRepairConditions,
    addBmpFcsRequiredFieldStrings,
    canAssess,
    canCreate,
    canInspect,
    possiblyEditBmp,
  };
};

module.exports = BmpFcsToDoController();

const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const BmpLayer = require("./bmpLayer");
const DataViewFunctions = require("../dataViewFunctions");
const MainMap = require("../mapFunctions/mainMap");
const OutfallRoutineMaintenanceConstants = require("../outfalls/outfallRoutineMaintenanceConstants");
const ToDoFunctions = require("../mapFunctions/toDoFunctions");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const UserPermissions = require("../login/userPermissions");
const MuniBmpIcon = require("../muni/bmp/muniBmpIcon");
const Misc = require("../misc");
