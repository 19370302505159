"use strict";

const SortUtilities = function () {
  const localComparer = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  var sortRows = function (key, reversed, rows, sortersDict) {
    if (key) {
      let sortFunction = sortersDict[key];
      if (!sortFunction) {
        throw new Error(`Sorting key ${key} not known in sortersDict`);
      }

      if (reversed) {
        sortFunction = (item1, item2) => -sortersDict[key](item1, item2);
      }

      const compare = (value1, value2, item1, item2) => {
        const result = sortFunction(value1, value2);
        return result === 0 && item1.id && item2.id ? numberSorter(item1.id, item2.id) : result;
      };

      if (key.includes(".")) {
        rows.sort((item1, item2) => {
          const paths = key.split(".");
          const value1 = getMultiLevelKeyValue(paths, item1);
          const value2 = getMultiLevelKeyValue(paths, item2);
          return compare(value1, value2, item1, item2);
        });
      } else {
        rows.sort((item1, item2) => compare(item1[key], item2[key], item1, item2));
      }
    }
  };

  var getMultiLevelKeyValue = function (paths, obj) {
    return paths.reduce((acc, path) => {
      if (!acc) {
        return null;
      }
      return acc[path];
    }, obj);
  };

  var numberSorter = function (x, y) {
    return undefinedSort(x, y) ?? +x - +y;
  };

  var alphaSorter = function (str1, str2) {
    return undefinedSort(str1, str2) ?? localStringSort(str1, str2);
  };

  var localStringSort = function (str1, str2) {
    return localComparer.compare(str1.trim(), str2.trim());
  };

  var booleanSorter = function (bool1, bool2) {
    return undefinedSort(bool1, bool2) ?? (bool1 === bool2 ? 0 : bool1 ? 1 : -1);
  };

  var undefinedSort = function (item1, item2) {
    if (item1 === undefined || item1 === null) {
      if (item2 === undefined || item2 === null) {
        return 0;
      }
      return -1;
    }

    if (item2 === undefined || item2 === null) {
      return 1;
    }

    return null;
  };

  var cofSorter = function (str1, str2) {
    const cofOrders = ["unknown", "very-low", "low", "moderate", "high", "very-high"];

    return undefinedSort(str1, str2) ?? cofOrders.indexOf(str1) - cofOrders.indexOf(str2);
  };

  var arrayQuantitySort = function (array1, array2, tieSortFunction = null) {
    if (array1.length !== array2.length) {
      return array1.length - array2.length;
    }

    if (tieSortFunction) {
      for (let i = 0; i < array1.length; i++) {
        const result = tieSortFunction(array1[i], array2[i]);
        if (result !== 0) {
          return result;
        }
      }
    }

    return 0;
  };

  return { numberSorter, alphaSorter, booleanSorter, sortRows, cofSorter, arrayQuantitySort };
};

module.exports = SortUtilities();
