"use strict";

const ArrayExtensions = function () {
  var run = function () {
    getElementWithMaxKey();
    containsSameAs();
    getObjectWith();
    containsObjectWith();
    concatWithUniqueObjectProperty();
    mergeByKey();
    groupIntoMap();
    mapToObject();
    union();
    sumValues();
    averageValues();
  };

  var sumValues = function () {
    Object.defineProperty(Array.prototype, "sumValues", {
      value: function (key) {
        let total = 0;
        this.forEach((item) => {
          if (item[key]) total += Number(item[key]);
        });
        return total;
      },
    });
  };

  var averageValues = function () {
    Object.defineProperty(Array.prototype, "averageValues", {
      value: function (key) {
        return this.length === 0 ? 0 : this.sumValues(key) / this.length;
      },
    });
  };

  var getElementWithMaxKey = function () {
    Object.defineProperty(Array.prototype, "getElementWithMaxKey", {
      value: function (keyGetter) {
        if (!this) {
          throw new TypeError('"this" is null or not defined');
        }

        var thisArray = Object(this);
        var len = thisArray.length;

        if (typeof keyGetter !== "function") {
          throw new TypeError("keyGetter must be a function");
        }

        let ind = 0;
        let indOfMax;
        let currMax;
        while (ind < len) {
          var value = keyGetter(thisArray[ind]);
          if (value !== null && value !== undefined && (currMax === undefined || value > currMax)) {
            currMax = value;
            indOfMax = ind;
          }
          ind++;
        }
        return currMax === undefined ? undefined : thisArray[indOfMax];
      },
      configurable: true,
      writable: true,
    });
  };

  var containsSameAs = function () {
    Object.defineProperty(Array.prototype, "containsSameAs", {
      value: function (originalOtherArray) {
        if (!Array.isArray(this) || !Array.isArray(originalOtherArray)) {
          return false;
        }

        var otherArray = Array.from(originalOtherArray);
        var thisArray = Array.from(this);

        if (thisArray.length !== otherArray.length) {
          return false;
        }

        thisArray.sort();
        otherArray.sort();

        for (let i = 0; i < thisArray.length; i++) {
          if (thisArray[i] !== otherArray[i]) {
            return false;
          }
        }

        return true;
      },
    });
  };

  var containsObjectWith = function () {
    Object.defineProperty(Array.prototype, "containsObjectWith", {
      value: function (propertyName, propertyValueShouldBe) {
        if (!Array.isArray(this)) {
          return false;
        }

        return this.some(function (option) {
          return option[propertyName] === propertyValueShouldBe;
        });
      },
    });
  };

  var getObjectWith = function () {
    Object.defineProperty(Array.prototype, "getObjectWith", {
      value: function (propertyName, propertyValue) {
        if (!Array.isArray(this)) {
          throw new Error(`${this} must be an array.`);
        }

        if (propertyValue === null || propertyValue === undefined) {
          return undefined;
        }

        const object = this.find((object) => object[propertyName] === propertyValue);
        if (object) {
          return object;
        }

        throw new Error(`No object found with { ${propertyName}: ${propertyValue} }`);
      },
    });
  };

  var concatWithUniqueObjectProperty = function () {
    Object.defineProperty(Array.prototype, "concatWithUniqueObjectProperty", {
      value: function (array2, objectProperty) {
        if (!Array.isArray(this) || !Array.isArray(array2)) {
          return false;
        }

        var allOptions = Array.from(this);

        for (const newOption of array2) {
          if (
            allOptions.every(function (existingOptions) {
              return existingOptions[objectProperty] !== newOption[objectProperty];
            })
          ) {
            allOptions.push(newOption);
          }
        }

        return allOptions;
      },
    });
  };

  var mergeByKey = function () {
    Object.defineProperty(Array.prototype, "mergeByKey", {
      value: function (array2, getKeyCallback) {
        if (!Array.isArray(this) || !Array.isArray(array2)) {
          throw new Error(`${this} must be an array.`);
        } else if (typeof getKeyCallback !== "function") {
          throw new Error(`${getKeyCallback} must be a function.`);
        }

        const resultMap = new Map();

        for (const element of this) {
          const key = getKeyCallback(element);
          _mergeToMap(resultMap, key, element);
        }

        for (const element of array2) {
          const key = getKeyCallback(element);
          _mergeToMap(resultMap, key, element);
        }

        return [...resultMap.values()];
      },
    });
  };

  var _mergeToMap = function (map, key, value) {
    if (key === undefined) {
      return;
    }

    const existing = map.get(key);

    if (existing === undefined) {
      map.set(key, structuredClone(value));
    } else {
      map.set(key, $.extend(true, existing, value));
    }
  };

  var groupIntoMap = function () {
    Object.defineProperty(Array.prototype, "groupIntoMap", {
      value: function (getKeyFn) {
        if (!this) {
          throw new TypeError('"this" is null or not defined');
        }

        var thisArray = Object(this);
        var len = thisArray.length;

        if (typeof getKeyFn !== "function") {
          throw new TypeError("getKeyFn must be a function");
        }

        const mapResult = new Map();
        for (let i = 0; i < len; i++) {
          const element = thisArray[i];
          const key = getKeyFn(element);
          if (mapResult.has(key)) {
            const existingArray = mapResult.get(key);
            existingArray.push(element);
            mapResult.set(key, existingArray);
          } else {
            mapResult.set(key, [element]);
          }
        }
        return mapResult;
      },
    });
  };

  var mapToObject = function () {
    Object.defineProperty(Array.prototype, "mapToObject", {
      value: function (getKeyFn, newObjectValueOrFunction = null) {
        if (!Array.isArray(this)) {
          throw new Error(`${this} must be an array.`);
        }
        if (typeof getKeyFn !== "function") {
          throw new TypeError("getKeyFn must be a function");
        }

        const newObjectFunction =
          typeof newObjectValueOrFunction === "function"
            ? newObjectValueOrFunction
            : () => newObjectValueOrFunction;

        var newObject = {};
        this.forEach(
          (arrayItem) => (newObject[getKeyFn(arrayItem)] = newObjectFunction(arrayItem)),
        );

        return newObject;
      },
    });
  };

  var union = function () {
    Object.defineProperty(Array.prototype, "union", {
      value: function (array2) {
        if (!Array.isArray(array2)) {
          throw new Error(`${array2} must be an array.`);
        }

        const unionSet = new Set(this);
        for (const elem of array2) {
          unionSet.add(elem);
        }
        return [...unionSet];
      },
    });
  };

  return {
    run,
  };
};

module.exports = ArrayExtensions();
