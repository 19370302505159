"use strict";

const ConstructionProjectStyles = function () {
  var getColor = function (constructionProjectData) {
    var phase = constructionProjectData.phase;

    if (Session.isCdot()) {
      return phase;
    }

    if (
      Tree.get("dataView") !== "construction-project-delivery" &&
      phase === "construction" &&
      !constructionProjectData.noInspection
    ) {
      return constructionProjectData.isScorePassing ? "green" : "red";
    }

    return getColorByPhase(phase);
  };

  var getColorByPhase = function (phase) {
    if (["planning", "shelved", "rejected"].includes(phase)) {
      return "white";
    } else if (["construction", "post-active", "planned"].includes(phase)) {
      return "grey";
    } else if (phase === "completed") {
      return "black";
    }

    throw new Error(`Unknown project color for pahse ${phase}`);
  };

  var getColorHex = function (constructionProjectData) {
    const colorHexMap = {
      white: "#ffffff",
      black: "#000000",
      grey: "#8c8c8c",
      red: "#d95945",
      green: "#389675",
      construction: "#ffbb00",
      "post-active": "#d56200",
      completed: "#813c00",
    };
    const color = getColor(constructionProjectData);
    if (color in colorHexMap) {
      return colorHexMap[color];
    }
    throw `Hex not known for color ${color}.`;
  };

  var getHeaderColorClass = function (constructionProjectData) {
    return `background-${getColor(constructionProjectData)}`;
  };

  var getDisplayPhase = function (phase) {
    const displayPhases = {
      planned: "Design",
      construction: "Construction",
      completed: "Completed",
      planning: "Planning",
      shelved: "Shelved",
      rejected: "Rejected",
    };

    return displayPhases[phase];
  };

  return {
    getHeaderColorClass,
    getColorHex,
    getDisplayPhase,
  };
};

module.exports = ConstructionProjectStyles();

const Tree = require("../../tree");
const Session = require("../../login/session");
