"use strict";

const OutfallInventoryModalController = function () {
  const outfallInventoryFormKey = "outfall-inventory";

  function loadFormListener() {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  }
  function refreshState() {
    Tree.set(["outfallInventoryState", "currentOutfalloutfallRotationAngle"], "");
    Tree.set(["outfallInventoryState", "currentOutfallLatLng"], "");
    Tree.set(["outfallInventoryState", "currentOutfallData"], {});
    Tree.set(["outfallInventoryState", "existing"], false);
    Tree.set(["outfallInventoryState", "outfallId"], null);
  }

  function getLatestOutfallInventoryData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(outfallInventoryFormKey, path);
  }
  function _resetOutfallMarker(value) {
    const latlng = Tree.get("modalMapSingleMarkerLastValidLatLng");
    const angle = parseFloat(value);
    let singleMarkerLayer = ModalMap.getMapLayer("singleMarkerLayer");
    ModalMap.removeMarkerLayer();
    if (latlng?.lat && latlng?.lng) {
      singleMarkerLayer = ModalMap.createSingleRotatingOutFallMarker(latlng, angle, true);
      ModalMap.setMarkerDragHandlers(singleMarkerLayer, true);
    }
  }
  function onFormInput(e, value, path) {
    if (path[0] === "rotationAzimuth") {
      _resetOutfallMarker(value);
    }
    if (path[0] === "outfallName") {
      Tree.set(["outfallInventoryState", "outfallId"], value);
    }
    checkEnableSaveButton(value, path);
  }

  function getExistingData(path) {
    const pathToCheck = $.isArray(path) ? path : [path];
    return Tree.get(["asset", outfallInventoryFormKey, ...pathToCheck]);
  }

  function unsetFormDataField(path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(outfallInventoryFormKey, path);
    return Form.getDataFromForm(outfallInventoryFormKey, false);
  }

  async function invokeOutfallInventoryModal(maybeId, options, startingPage) {
    refreshState();
    InventoryModal.setOpenInventory(outfallInventoryFormKey);
    if (maybeId) {
      const data = await ApiCalls.getOutfall(maybeId);
      Tree.set(["outfallInventoryState", "currentOutfallData"], data);
      Tree.set(["outfallInventoryState", "existing"], true);
      return initializeOutfallInventoryModalPages(data, "outfall-set-location", {
        existingLocation: true,
      });
    } else {
      return initializeOutfallInventoryModalPages(
        OutfallInventoryConstants.getOutfallInventoryDefaults(),
        startingPage,
        options,
      );
    }
  }

  async function initializeOutfallInventoryModalPages(processedData, startingPage, options = {}) {
    const pageOptions = {
      existingLocation: !!(processedData || {}).projectIdentifier,
      ...options,
    };

    $("#modalMap").hide();
    Tree.set(["asset", outfallInventoryFormKey], processedData);
    resetAllPages();
    const inputPages = getOutfallInventoryPages();
    await InventoryModal.showModal(inputPages, startingPage, pageOptions, {
      saveAndCloseHandler: checkSaveAndClose,
      cancelHandler: closeInventory,
      formHasUpdatesHandler: () => !noUpdatesInForm(),
    });
    InventoryModal.disableSaveButton();
    InventoryModal.resetModalTitle();
    setModalTitle();

    InventoryModal.setHideInventoryOnClose(!options?.modalInModal);
    loadFormListener();
  }

  function getOutfallInventoryPages() {
    const basePages = [OutfallInventorySetLocation, OutfallInventoryGeneralInfo];
    return basePages;
  }

  function loadExistingOutfallMarker() {
    const latlng = getOutfallLatLng();
    const rotazi = parseInt(getLatestOutfallInventoryData("rotationAzimuth"));
    const priority = getLatestOutfallInventoryData("priority") ?? false;

    ModalMap.createSingleRotatingOutFallMarker(latlng, rotazi, priority);
    InventoryModal.restoreSaveButton();
  }

  function getOutfallLatLng() {
    return {
      lat: getLatestOutfallInventoryData("latitude"),
      lng: getLatestOutfallInventoryData("longitude"),
    };
  }

  function setModalTitle() {
    if (Tree.get("outfallInventoryState", "existing")) {
      const outfallName = Tree.get("outfallInventoryState", "currentOutfallData", "outfallName");
      const assetDisplayName = ToolSettings.getSetting("constants", "assetDisplayNames", "outfall");
      const titleLabel = `${assetDisplayName} ID: `;
      InventoryModal.setModalTitle(titleLabel, outfallName);

      const outfallType = Tree.get(
        "outfallInventoryState",
        "currentOutfallData",
        "displayOutfallType",
      );
      InventoryModal.setModalTitle("Type: ", outfallType, "type");
    } else {
      InventoryModal.setModalTitle("Add New Outfall");
      InventoryModal.setModalTitle("", null, "type");
    }
  }

  function resetAllPages() {
    const allPages = [OutfallInventorySetLocation, OutfallInventoryGeneralInfo];
    allPages.forEach((page) => {
      if (page?.resetState) {
        page.resetState();
      }
    });
  }

  function checkSaveAndClose() {
    return new Promise((resolve) => {
      saveAndCloseModal();
      resolve(false);
    });
  }

  function closeInventory() {
    resetInventory();
  }
  function resetInventory() {
    Tree.unset("modalMapSingleMarkerLastValidLatLng");
    Form.clearForm(outfallInventoryFormKey);
    ModalMap.resetModalMap();
    ModalMap.setModalMapView();
  }

  function checkEnableSaveButton(value, path) {
    const existingValue = getExistingData(path);
    if (existingValue !== undefined && value === existingValue) {
      Form.manuallyUnsetField(outfallInventoryFormKey, path);
    }
    if (noUpdatesInForm()) {
      InventoryModal.readySaveState(false);
    } else {
      InventoryModal.readySaveState(true);
    }
  }
  function setFormDataField(path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(outfallInventoryFormKey, path, value);
  }
  function noUpdatesInForm() {
    return Object.keys(Form.getDataFromForm(outfallInventoryFormKey, false)).length === 0;
  }
  function renderPageContent(html) {
    InventoryModal.setModalDynamicContent(html);
  }
  function closeModal() {
    InventoryModal.hide();
  }

  async function saveAndCloseModal() {
    try {
      const exists = Tree.get(["outfallInventoryState", "existing"]);
      const currentId = Tree.get(["outfallInventoryState", "currentOutfallData", "id"]);
      const outfallToLoad = Form.getDataFromForm(outfallInventoryFormKey);
      if (exists) {
        const data = Form.toFormData({
          outfallId: currentId,
          outfallUpdates: outfallToLoad,
        });
        await ApiCalls.updateOutfall(data);
        OutfallPopup.onPopupOpen({ id: currentId }, "muniOutfalls");
      } else {
        const newOutfallData = $.extend(
          true,
          {},
          OutfallInventoryConstants.getOutfallInventoryDefaults(),
          outfallToLoad,
        );
        const data = Form.toFormData({
          outfallData: newOutfallData,
        });
        await ApiCalls.createNewOutfall(data);
      }

      closeModal();
      OutfallFunctions.invalidateOutfallLayerDataByDataView();
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  }

  function getInventoryForRender() {
    return InventoryModal.getAllData(outfallInventoryFormKey);
  }

  return {
    invokeOutfallInventoryModal,
    initializeOutfallInventoryModalPages,
    setFormDataField,
    renderPageContent,
    unsetFormDataField,
    getExistingData,
    onFormInput,
    _resetOutfallMarker,
    saveAndCloseModal,
    closeModal,
    getLatestOutfallInventoryData,
    loadExistingOutfallMarker,
    getInventoryForRender,
  };
};

module.exports = OutfallInventoryModalController();

const ApiCalls = require("../apiCalls");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const ModalMap = require("../mapFunctions/modalMap");
const OutfallFunctions = require("../../js/outfalls/outfallFunctions");
const OutfallInventoryConstants = require("./outfallInventoryConstants");
const OutfallInventorySetLocation = require("./outfallInventorySetLocation");
const OutfallInventoryGeneralInfo = require("./outfallInventoryGeneralInfo");
const Tree = require("../tree");
const UploadValidator = require("../general/uploadValidator");
const OutfallPopup = require("../outfalls/outfallPopup");
const ToolSettings = require("../settings/toolSettings");
