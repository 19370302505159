"use strict";

const FilterSummary = function () {
  let listenersLoaded;
  let disabled;
  let allFilters;
  let modifiedFilters;
  let defaultFilters;
  let esgEnabled;
  let activeTab;
  let currentView;
  let needToRender;
  const noRenderOnTabs = ["todo", "insight"];

  const enable = function ({ esg = false, force = false } = {}) {
    disabled = false;
    esgEnabled = esg;
    if (force) needToRender = true;
    render();

    if (!listenersLoaded) {
      loadListeners();
    }
  };

  const render = function () {
    if (disabled || noRenderOnTabs.includes(Tree.get("activeTab"))) {
      return;
    }
    if (esgEnabled) {
      activeTab = NavTabs.getActiveTab();
      currentView = NavToggle.getView();
    }

    if (!needToRender) return;
    needToRender = true;

    const bubbles = getBubbleData();
    const html = nunjucks.render("filters/filterSummary.njk", { bubbles });
    $(".filter-summary-container").html(html);
    handleShowMore();
    if (esgEnabled) handlePadding(activeTab, currentView);
  };

  const rerenderHandleRows = function () {
    render();
    handleMultiRowCss();
  };

  const handleMultiRowCss = function () {
    returnToInitialSpacing();
    $(".data-list-container.list-view").css("margin-top", "0px");
    if (esgEnabled && currentView === "list") {
      const ovalsVisible = $(".properties-list .filter-summary-container .oval:visible").length > 0;
      if (ovalsVisible) {
        $(".data-list-container table thead tr.top-filters-bar td > div").css("height", "36px");
      } else {
        $(".data-list-container table thead tr.top-filters-bar td > div").css("height", "100%");
      }
    }
  };

  const handlePadding = function (activeTab, currentView) {
    if (!urlContainsProperties()) {
      if (activeTab === "selected" && currentView === "list") {
        handlePaddingSelectedList();
      } else if (activeTab === "suitable" && currentView === "list") {
        handlePaddingSuitableList();
      } else {
        handleMapPadding();
      }
    } else {
      handlePaddingProperties(currentView);
    }
  };

  const handleMapPadding = function () {
    $(".filter-summary-container").addClass("esg-map-padding");
    $(".filter-summary-container").removeClass("selected-list-padding");
    $(".filter-summary-container").removeClass("suitable-list-padding");
  };

  const handlePaddingSuitableList = function () {
    $(".filter-summary-container").addClass("suitable-list-padding");
    $(".filter-summary-container").removeClass("selected-list-padding");
    $(".filter-summary-container").removeClass("esg-map-padding");
  };

  const handlePaddingSelectedList = function () {
    $(".filter-summary-container").addClass("selected-list-padding");
    $(".filter-summary-container").removeClass("suitable-list-padding");
    $(".filter-summary-container").removeClass("esg-map-padding");
  };

  const handlePaddingProperties = function (currentView) {
    if (currentView === "list") {
      $(".filter-summary-container").removeClass("esg-map-padding");
    } else {
      $(".filter-summary-container").addClass("esg-map-padding");
    }
  };

  const urlContainsProperties = function () {
    return window.location.href.indexOf("/properties") > -1;
  };

  const handleShowMore = function () {
    const hide = DomFunctions.elementsHaveSameTop($(".filter-summary-container .oval:visible"));

    $(".bubbles-show-more").toggleClass("hidden", hide);

    if (hide) {
      toggleExpanded(false);
    }
  };

  const disable = function () {
    disabled = true;
    clearBubbles();
  };

  const loadListeners = function () {
    listenersLoaded = true;
    $("body").on("click", ".filter-summary-container .remove-filter", onClickRemoveFilter);
    $("body").on("click", ".filter-summary-container .bubbles-show-more", toggleExpanded);
    $(window).on("resize", handleShowMore, handleMultiRowCss);

    Tree.select("filters").off("update", _filtersUpdated).on("update", _filtersUpdated);
  };

  const _filtersUpdated = function () {
    needToRender = true;
    if (!esgEnabled) {
      // in ESG this happens already because of DataList.onLayerDataUpdate()
      render();
    }
  };

  const areAllCheckboxesChecked = function (name) {
    var checkboxes = $('input[name="' + name + '"]');
    var checkedCheckboxes = $('input[name="' + name + '"]:checked');
    return checkboxes.length === checkedCheckboxes.length;
  };

  const toggleExpanded = function (expand = !$(".filter-summary-container").hasClass("expanded")) {
    $(".filter-summary-container").toggleClass("expanded", expand);
    handleMultiRowCss();
  };

  const returnToInitialSpacing = function () {
    if (esgEnabled) {
      $(".top-filters-bar td").css(`padding-bottom`, `0px`);
      $(".data-list-container table").css(`margin-top`, `-45px`);
      $(".data-list-container table thead").css(`top`, `-45px`);
    }
  };

  const clearBubbles = function () {
    $(".filter-summary-container").html("");
  };

  const onClickRemoveFilter = function () {
    const dataKey = $(this).closest(".oval").data("key");

    clearFilter(dataKey);
  };

  const getDisplay = function (name) {
    let displayName;
    const $checkboxes = $(
      `.checkbox.outside-accordion[value="${name}"], [name="${name}"], [name^="${name}["]`,
    );
    if ($checkboxes.length) {
      displayName = $checkboxes.closest("[data-display]").data("display");
    } else {
      displayName = $(`[name^=${Misc.camelToKebab(name)}], [name^=${name}]`)
        .closest("[data-display]")
        .data("display");
    }
    return displayName;
  };

  const getBubbleData = function () {
    allFilters = getAllOptions();
    modifiedFilters = getSelected();

    return [...getStandardBubbles()].filter(Boolean);
  };

  const getDateRange = function (key) {
    const { from, to, noDate } = modifiedFilters[key];

    if (!from && !to && !noDate) {
      return null;
    }

    return {
      from: from || null,
      to: to || null,
      noDate: noDate || null,
    };
  };

  const getMinMax = function (key) {
    const { min, max } = modifiedFilters[key];

    if (!min && !max) {
      return null;
    }

    return {
      min: min || null,
      max: max || null,
    };
  };

  const getMeasurementMinMax = function (key) {
    let { min, max, unit } = modifiedFilters[key];

    if (!min && !max) {
      return null;
    }

    min = unit === "in" ? min * 12 : min;
    max = unit === "in" ? max * 12 : max;

    const isEnabled =
      MeasurementMinMax._measurementRangeIsSet("diameterRange") ||
      MeasurementMinMax._measurementRangeIsSet("lengthRange") ||
      MeasurementMinMax._measurementRangeIsSet("widthRange");

    return {
      min: min || null,
      max: max || null,
      unit: unit || null,
      isEnabled: isEnabled,
    };
  };

  const getStandardBubbles = function () {
    const bubbles = [];
    Object.keys(allFilters)
      .filter((key) => {
        return !["dateTo", "dateFrom", "dateType"].includes(key);
      })
      .forEach((key) => {
        const allFiltersjSON = JSON.stringify(allFilters[key]);
        const modifiedFiltersjSON = JSON.stringify(modifiedFilters[key]);
        if (allFiltersjSON !== modifiedFiltersjSON && modifiedFilters[key]) {
          let count;
          if (Array.isArray(modifiedFilters[key])) {
            count = modifiedFilters[key].length;
          } else {
            count = 1;
          }
          var bubble = {
            name: key,
            displayName: getDisplay(key),
            count: count,
            checked: Array.isArray(modifiedFilters[key]) ? modifiedFilters[key] : null,
            selectedDisplayOptions: getSelectedDisplayOptions(key),
            minMax: getMinMax(key),
            measurementMinMax: getMeasurementMinMax(key),
          };

          bubble.dateRange = getDateRange(key);

          bubbles.push(bubble);
        }
      });

    return bubbles;
  };

  const getSelectedDisplayOptions = function (key) {
    const data = [];
    const $checkboxes = $(`.display-filters input[name="${key}"][type=checkbox]:checked`);

    $checkboxes.each(function () {
      data.push($(this).data("name"));
    });

    return data;
  };

  const clearFilter = function (key, toDefault = false) {
    checkCheckBoxes(key);
    uncheckSingleBoolan(key);
    setDateToBaseLine(key);
    MinMax.clear(key);
    MeasurementMinMax.clear(key);
    DateRange.clear(key);

    if (toDefault) {
      Tree.set(["filters", key], defaultFilters[key]);
    } else {
      const clearedState = getSelected();
      Tree.set(["filters", key], clearedState[key]);
    }
    colorFilterButton();
    _hideCatchmentTableShowFilterPanel();
  };

  const _hideCatchmentTableShowFilterPanel = function () {
    if (esgEnabled) return;
    $("#bottomFloatingInputsTable").addClass("hidden");
    $(".filter-menu").addClass("filter-full-height");
    Filters.showFilterMenu();
  };

  const colorFilterButton = function () {
    var $filterButton = $(".filter-button");
    $filterButton.addClass("invert-colors");
  };

  const setDateToBaseLine = function (key) {
    if (key === "dateTo" || key === "dateFrom" || key === "dateType") {
      Filters.clearDateRange();
      $('input[placeholder="From"]').val("");
      $('input[placeholder="To"]').val("");
      const radioContainer = $(".date-radio-container");
      const dateType = selectFirstRadioButton(radioContainer);
      if (dateType) {
        Tree.set(["filters", "dateType"], dateType);
      }
    }
  };

  const selectFirstRadioButton = function (container) {
    var value;
    container
      ?.find('input[type="radio"]:first')
      .prop("checked", true)
      .each(function () {
        value = $(this).val();
      });
    return value;
  };

  const uncheckSingleBoolan = function (key) {
    $(`.filter-title-button.outside-accordion  input[value=${key}]`).prop("checked", false);
  };

  const checkCheckBoxes = function (key) {
    const $names = $(`input[name=${key}]:not(:disabled)`);
    $names.each(function () {
      $(this).prop("checked", true);
    });

    $names.first().trigger("input");
  };

  const handleResetingTitle = function (key) {
    const $titleSpan = $(`input[name=${key}]:checked`)
      .closest(".filter-dropdown")
      .prev(".filter-title-button")
      .find("span:first-child[title]");

    if ($titleSpan.length) {
      const defaultText = getStringBeforeColon($titleSpan.attr("title"));
      $titleSpan.text(defaultText).attr("title", defaultText);
      $titleSpan.closest(".filter-title-button").removeClass("active");
    }
  };

  const getStringBeforeColon = function (str) {
    const parts = str.split(":");
    return parts[0].trim();
  };

  const getAllOptions = function () {
    return getFilterOptions(true);
  };

  const getSelected = function () {
    if (!defaultFilters || !Object.values(defaultFilters).some((value) => value !== null)) {
      defaultFilters = getFilterOptions(false);
    }
    return getFilterOptions(false);
  };

  const getFilterOptions = function (all = true) {
    const data = {
      ...MultipleSelect.getData(all),
      ...getDateValues(all),
      ...MinMax.getData(all),
      ...MeasurementMinMax.getData(all),
      ...DateRange.getData(all),
    };

    return data;
  };

  const getDateValues = function (all) {
    const $fromDateInput = $('input[placeholder="From"]');
    const $toDateInput = $('input[placeholder="To"]');

    return !all && ($fromDateInput.val() || $toDateInput.val())
      ? { dateFrom: $fromDateInput.val(), dateTo: $toDateInput.val() }
      : { dateFrom: null, dateTo: null };
  };

  const show = function () {
    $(".filter-summary-container").toggle(true);
  };

  const hide = function () {
    $(".filter-summary-container").toggle(false);
  };

  return {
    enable,
    disable,
    show,
    hide,
    render,
    getSelected,
    getAllOptions,
    getBubbleData,
    clearFilter,
    checkCheckBoxes,
    getDisplay,
    getSelectedDisplayOptions,
    clearBubbles,
    handleResetingTitle,
    handleShowMore,
    toggleExpanded,
    handlePadding,
    handleMultiRowCss,
    handleMapPadding,
    rerenderHandleRows,
    areAllCheckboxesChecked,
  };
};

module.exports = FilterSummary();

const MinMax = require("../general/minMax");
const Filters = require("../mapFunctions/filters");
const Tree = require("../tree");
const DomFunctions = require("../general/domFunctions");
const Misc = require("../misc");
const NavToggle = require("../esg/navToggle");
const NavTabs = require("../esg/navTabs");
const MultipleSelect = require("../general/multipleSelect");
const DateRange = require("./dateRange");
const MeasurementMinMax = require("../general/measurementMinMax");
