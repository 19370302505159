"use strict";

const OutfallsIcon = function () {
  var getSimpleOutfallsMarker = function (latlng, rotation, isPriority) {
    return getOutfallsMarker(latlng, rotation, isPriority);
  };

  var getOutfallsMarker = function (latlng, rotation, isPriority, condition = null, isDue = false) {
    const iconRotationOffset = -180;

    return L.marker(latlng, {
      pane: "outfallPane",
      icon: getOutfallsMarkerIcon(isPriority, condition, isDue),
      rotationAngle: rotation + iconRotationOffset,
      rotationOrigin: "center",
    });
  };

  var getOutfallsMarkerIcon = function (isPriority, condition, isDue) {
    const iconClass = getOutfallsIconClass(isPriority, condition, isDue);
    const currentZoomLevel = Tree.get("zoomLevel");
    const currentDataView = Tree.get("dataView");
    const iconSizesArray = [6, 14, 14, 24, 24, 24, 24, 24];
    const updatedIconSize = MapFunctions.resizeIconsOnZoom(
      currentZoomLevel,
      false,
      false,
      false,
      false,
      iconSizesArray,
    );
    const activeLayers = ["muni-outfalls", "outfalls", "dry-weather", "scenarios"];
    const size = activeLayers.includes(currentDataView) ? updatedIconSize.size : [24, 24];
    var icon;
    icon = L.divIcon({
      iconSize: size,
      className: iconClass,
    });
    return icon;
  };

  var getOutfallsIconClass = function (isPriority, condition = null, isDue = false) {
    const classNames = ["muni_outfall"];

    if (isDue) {
      classNames.push("due");
    } else if (condition) {
      classNames.push(condition);
    }
    if (isPriority) {
      classNames.push("priority");
    }
    return classNames.join("_");
  };

  var getFlowRoutingMarker = function (latlng, rotation, isTerminal) {
    const iconRotationOffset = -90;

    return L.marker(latlng, {
      pane: "flowRoutingPane",
      icon: getFlowRoutingMarkerIcon(isTerminal),
      rotationAngle: rotation + iconRotationOffset,
      rotationOrigin: "center",
    });
  };

  var getOutfallsFactSheetIconClass = function (data) {
    return getOutfallsIconClass(
      data.priority,
      data.condition,
      data.outfallInspectionIsDue,
      data.isPriority2,
    );
  };

  var getFlowRoutingMarkerIcon = function (isTerminal) {
    const image = getFlowRoutingIcon(isTerminal);

    var icon = L.icon({
      iconSize: [33, 22],
      popupAnchor: [0, -12],
      iconUrl: image.url,
      className: image.class,
    });

    return icon;
  };

  var getFlowRoutingIcon = function (isTerminal) {
    const terminalFlowIcon = {
      url: "/ram/images/markers/flow-routing-terminal.svg",
      class: "flow-routing-terminal-icon",
    };
    const flowIcon = {
      url: "/ram/images/markers/flow-routing.svg",
      class: "flow-routing-icon",
    };
    return isTerminal ? terminalFlowIcon : flowIcon;
  };

  var getIconColor = function (condition, isDue, hex = false) {
    if (condition === undefined) {
      condition = null;
    }

    if (isDue) {
      return hex ? MapStyles.getHexColors().blue : "blue";
    }

    return MapStyles.getColorFromCondition(condition, hex);
  };

  return {
    getFlowRoutingMarker,
    getOutfallsMarker,
    getSimpleOutfallsMarker,
    getOutfallsIconClass,
    getOutfallsFactSheetIconClass,
    getIconColor,
    getOutfallsMarkerIcon,
  };
};

module.exports = OutfallsIcon();

const MapFunctions = require("../mapFunctions/mapFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const Tree = require("../tree");
