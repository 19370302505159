"use strict";

const TypeSelection = function () {
  let $container;

  var show = function ($modalBody, setTypeFunction) {
    typeHandler = setTypeFunction;
    $container = $modalBody;
    const selectedType = getDefaultType();
    $container.html(
      nunjucks.render("dataExport/typeSelection.njk", {
        typeOptions: DataExportConfig.getTypeOptions(),
        selectedType: selectedType.value,
      }),
    );
    setTypeFunction(selectedType);
    $container.on("input", "[name='type-selection']", setTypeSelection);
  };

  var getDefaultType = function () {
    const selectedTypeValue = DataViewFunctions.getCurrentDataViewProperty("defaultExportType");
    if (selectedTypeValue) {
      const selectedType = getTypeByValue(selectedTypeValue);
      if (selectedType.enabled) {
        return selectedType;
      }
    }
    return DataExportConfig.getTypeOptions().filter((option) => option.enabled);
  };

  var getTypeByValue = function (value) {
    return DataExportConfig.getTypeOptions().find((type) => type.value === value);
  };

  var setTypeSelection = function (e) {
    const selectedTypeValue = $(e.currentTarget).val();
    typeHandler(getTypeByValue(selectedTypeValue));
  };

  var typeHandler = function () {
    throw "Handler not set.";
  };

  var cleanUp = function () {
    $container.off("click", "[name='type-selection']", setTypeSelection);
    $container.empty();
  };

  return {
    cleanUp,
    show,
  };
};

module.exports = TypeSelection();

const DataExportConfig = require("../config/dataExportConfig");
const DataViewFunctions = require("../dataViewFunctions");
