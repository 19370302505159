"use strict";

const FcsLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFrontEndFilterListernsForMap(mapId, "fcs");
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled("fcs", mapId, function (fcsIsEnabled, sameSpatialFilter) {
      if (fcsIsEnabled) {
        if (Tree.get("activeTab") === "todo") FilterSummary.disable();
        if (MapFunctions.mapHasLayer(map, mapLayers.bmpsLayer))
          map.removeLayer(mapLayers.bmpsLayer);
        if (mapLayers.fcsLayer && sameSpatialFilter) {
          map.addLayer(mapLayers.fcsLayer);
        } else {
          loadLayer(mapId);
        }
      } else {
        MapFunctions.removeFcsLayer(map, mapLayers);
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated("fcs", mapId, function () {
      if (MapFunctions.mapHasLayer(map, mapLayers.bmpsLayer)) {
        map.removeLayer(mapLayers.bmpsLayer);
      }
      if (MapFunctions.mapHasLayer(map, mapLayers.fcsLayer)) {
        map.removeLayer(mapLayers.fcsLayer);
      }
      LayerFunctions.filterToDoLayer("fcs", mapLayers, map, mapId);
    });
  };

  var loadLayer = function (mapId) {
    laodLayerWResourceController(mapId, "fcs", true);
  };

  var loadFrontEndFilterListernsForMap = function (mapId, layerName) {
    Tree.select("filters").on("update", async function () {
      if (Tree.get(["layers", layerName, "isEnabled"])) {
        if (Tree.get("dataView") === layerName) {
          laodLayerWResourceController(mapId, layerName, false);
        } else {
          laodLayerWResourceController(mapId, layerName, true);
        }
      }
    });
  };

  var laodLayerWResourceController = async function (mapId, layerName, refreshData = true) {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var filters = Actions.getFiltersByMapId(mapId);

    ApiCalls.checkspatialboxes(filters);

    Tree.set(["layers", layerName, "isFetching"], true);

    const resource = ResourceController.get(layerName);
    const data = await resource.getAll({ filters, refreshData });
    Tree.set(["layers", layerName, dataPath], data);

    Tree.set(["layers", layerName, "isFetching"], false);
    if (mapId !== "modal") {
      const count = Tree.get(["layers", layerName, "data"]).length;
      Actions.setItemCount(count);
    }
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = FcsLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const LayerFunctions = require("../layerFunctions");
const Actions = require("../actions");
const FilterSummary = require("../filters/filterSummary");
const ResourceController = require("../offline/resourceController");
