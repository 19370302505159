"use strict";

const ReportingTimelineController = function () {
  var init = function () {
    if (Session.isRegulator()) return;
    loadDataListeners();
    load();
    handleShown();
  };

  var handleShown = function (activeTab = Tree.get("activeTab")) {
    if (activeTab === "todo") {
      ReportingTimeline.show(updateHeights);
    } else {
      ReportingTimeline.hide(updateHeights);
    }
  };

  var updateHeights = function () {
    MapFunctions.setMapHeight();
  };

  var loadDataListeners = function () {
    Tree.select("activeTab").on("update", (e) => handleShown(e.data.currentData));

    Tree.select("annualReportingSettings").on("update", function (e) {
      if (e.data.currentData) {
        ReportingTimeline.render(e.data.currentData);
        MapFunctions.setMapHeight();
      }
    });
  };

  var load = function () {
    const annualReportingSettings = Tree.get("annualReportingSettings");
    const toolReport = annualReportingSettings.find(
      (report) =>
        report.dataName === DataViewFunctions.getCurrentDataViewProperty("reportingDataGroup"),
    );

    if (toolReport) {
      Tree.set("annualReportingSettings", toolReport);
    } else {
      console.warn(`No "reportingDataGroup" configured for the current data view.`);
      Tree.set("annualReportingSettings", null);
    }
  };

  return {
    init,
  };
};

module.exports = ReportingTimelineController();

const Tree = require("./tree");
const ReportingTimeline = require("./reportingTimeline");
const MapFunctions = require("./mapFunctions/mapFunctions");
const Session = require("./login/session");
const DataViewFunctions = require("./dataViewFunctions");
