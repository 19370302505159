"use strict";

var RunoffOpportunityLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadColumnArrayUpdatedListener(mapId);
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "runoffOpportunity",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        var runoffLayer = mapLayers.runoffOpportunityLayer;
        var sameLayerDropdown =
          Tree.get("layers", mapId, "runoffOpportunity", "layerDropdown") ===
          Tree.get("layerDropdown", mapId);
        var forceRefresh = !sameView || !sameLayerDropdown || !sameGroup;
        if (isEnabled) {
          TelrLayerFunctions.displayTelrLayer(
            runoffLayer,
            "runoffOpportunity",
            "runoffRate",
            map,
            mapLayers,
            forceRefresh,
          );
        } else {
          if (MapFunctions.mapHasLayer(map, runoffLayer)) {
            map.removeLayer(runoffLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "runoffOpportunity", function (data) {
      mapLayers.runoffOpportunityLayer = addRunoffOpportunityLayer(
        map,
        mapLayers.runoffOpportunityLayer,
        data,
      );
      TelrLayerFunctions.selectTelrCatchmentsByFilter(mapLayers.runoffOpportunityLayer);
      if (
        Tree.get("layers", mapId, "catchments", "isEnabled") &&
        !Tree.get("layers", mapId, "catchments", "isFetching")
      ) {
        Table.render();
      }
    });
  };

  var addRunoffOpportunityLayer = function (map, runoffOpportunityLayer, layerData) {
    if (runoffOpportunityLayer) {
      map.removeLayer(runoffOpportunityLayer);
    }
    runoffOpportunityLayer = new (Layers.getLayers().RunoffLayer)(layerData).addTo(map);
    return runoffOpportunityLayer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {};

  var loadColumnArrayUpdatedListener = function (mapId) {
    Tree.select(["layers", mapId, "runoffOpportunity", "columnArray"]).on("update", function (e) {
      const DataViewDropdown = require("../mapFunctions/dataViewDropdown");
      var columns = e.data.currentData;
      DataViewDropdown.updateLegendColumns(columns, "runoff-opportunity");
    });
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = RunoffOpportunityLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const Table = require("../mapFunctions/table");
const Layers = require("../mapFunctions/layers");
const TelrLayerFunctions = require("./telrLayerFunctions");
