"use strict";

const Analytics = function () {
  let filterContext = null;

  var init = function (data) {
    if (typeof gtag !== "undefined" && Config.isProductionDomain() && !data.is_2n_admin) {
      // https://developers.google.com/analytics/devguides/collection/ga4/reference/config
      const config = {
        user_id: data.userId,
        user_properties: {
          // The standard user_id field isn't exported to Data Studio, so using
          // db_user_id instead even though they really don't want you to:
          // https://support.google.com/analytics/answer/10075209
          db_user_id: data.userId,
          is_limited_access: data.is_limited_access,
        },
        send_page_view: false,
      };

      gtag("js", new Date());
      gtag("config", "G-RR7N4MWWTR", config);
    }

    loadTreeListeners();
  };

  var loadTreeListeners = function () {
    Tree.watch({
      activeTab: ["activeTab"],
      dataView: ["dataView"],
      navToggleSelection: ["navToggleSelection"],
      factSheet: ["factSheet", "open"],
    }).on("update", (e) => {
      const target = e.target.get();
      Sentry.setTag("dataView", target.dataView);
      Sentry.setExtra("activeTab", target.activeTab);
      Sentry.setExtra("navToggleSelection", target.navToggleSelection);

      sendScreenView();
    });
  };

  var getDefaultRamPath = function () {
    const path = [Tree.get("activeTab"), Tree.get("navToggleSelection")];

    if (Tree.get("factSheet", "open") === true) {
      path.push("fact-sheet");
    }

    return path;
  };

  var sendScreenView = function (...screenPath) {
    const screenName = _getScreenName(screenPath);

    sendGaScreenView(screenName);
  };

  var _getScreenName = function (screenPath = []) {
    if (Config.get().site === "esg" || Config.get().site === "portal") {
      return getBasicScreenName(screenPath);
    }
    if (window.twoNformReport) {
      return getReportScreenName(screenPath);
    }

    return getRamScreenName(screenPath);
  };

  var getBasicScreenName = function (screenPath) {
    return pathFromArray([window.location.origin, ...screenPath]);
  };

  var getRamScreenName = function (screenPath) {
    const dataView = Tree.get("dataView");

    if (screenPath.length === 0) {
      screenPath = getDefaultRamPath();
    }

    return pathFromArray([window.location.origin, dataView, ...screenPath]);
  };

  var getReportScreenName = function (screenPath) {
    const pageName = Tree.get("activeTab");

    if (screenPath.length === 0) {
      screenPath = getDefaultReportPath();
    }

    return pathFromArray([window.location.origin, pageName, ...screenPath]);
  };

  var getDefaultReportPath = function () {
    var path = [];

    if (Tree.get("mapId") !== null) {
      path.push(Tree.get("dataView"));
    }

    return path;
  };

  var sendGaScreenView = function (screen_path) {
    // https://support.google.com/analytics/answer/11403294

    const page_title = ToolFunctions.getDefaultTool();
    const page_location = screen_path;

    if (typeof gtag !== "undefined") {
      // Persist page data for future events
      gtag("set", {
        page_title,
        page_location,
      });
    }
    // Send page view event
    sendGtagEvent("page_view", {
      page_title,
      page_location,
    });
  };

  var sendGtagEvent = function (eventName, eventParameters) {
    // https://developers.google.com/gtagjs/reference/api#event
    if (typeof gtag !== "undefined") {
      gtag("event", eventName, eventParameters);
      Sentry.addBreadcrumb({
        category: `analytics.${eventName}`,
        message: Object.values(eventParameters).join(", "),
      });
    }

    console.info(
      `%cAnalytics ${eventName}: %c${Object.values(eventParameters).join(", ")}`,
      "font-weight: bold;",
      `
          border: 1px solid black;
          border-radius: 4px;
          background-color: #2A7359;
          color: white;
          padding: 4px 6px;
        `,
    );
  };

  var sendNavClickEvent = function () {
    const linkSource = $(this).closest("li.dropdown").find("a").attr("title").split(" ")[0];
    let linkName = $(this).attr("class");
    linkName = linkName.replace("navbar-", "");
    linkName = linkName.replaceAll("-", "_");
    linkName = linkSource.concat("/").concat(linkName);
    sendAction(["nav_link", linkName]);
  };

  var sendTableEvent = function (action) {
    sendAction(["sidebar_table", action]);
  };

  var sendTodoEvent = function (action) {
    sendAction(["todo", action]);
  };

  var sendModalEvent = function (action, event_label) {
    sendAction(["modal", action, event_label]);
  };

  var sendPopupEvent = function (action, event_label) {
    sendAction(["popup", action, event_label]);
  };

  var setFilterContext = function (context) {
    filterContext = context;
  };

  var sendFilterEvent = function (action) {
    const context = filterContext || _dataViewNameAbriviation(Tree.get("dataView"));
    sendAction(["filter", action, context]);
  };

  var clearFilterContext = function () {
    filterContext = null;
  };

  var sendMapEvent = function (action) {
    const dataView = Tree.get("dataView");
    sendAction(["map", action, dataView]);
  };

  var sendSettingsEvent = function (action) {
    sendAction(["settings", action]);
  };

  var sendDataListEvent = function (action) {
    const abbvDataView = _dataViewNameAbriviation(Tree.get("dataView"));
    const eventName = pathFromArray(["datalist", action, abbvDataView]);
    if (eventName?.length > 40) {
      sendAction(["datalist", "ERROR_40_char_limit", "exceeded"]);
    } else sendAction(["datalist", action, abbvDataView]);
  };

  var sendAction = function (actionArray, { truncate = false } = {}) {
    // Events with parameters must be manually setup as custom dimensions or metrics
    // https://support.google.com/analytics/answer/11396839?hl=en
    const lengthLimit = 40;
    let eventName = pathFromArray(actionArray);

    // https://support.google.com/analytics/answer/9267744?hl=en
    if (eventName.length > lengthLimit) {
      if (truncate) {
        eventName = eventName.substring(0, lengthLimit);
      } else {
        reportException(new Error(`Action exceeded event name length limit ${eventName}`));
      }
    }

    sendGtagEvent(eventName, {});
  };

  var reportException = function (e) {
    if (Sentry.isInitialized()) {
      Sentry.captureException(e);
    } else {
      throw e;
    }
  };

  var pathFromArray = function (array) {
    return array.filter((path) => !!path).join("/");
  };

  var _dataViewNameAbriviation = function (dataView) {
    if (!dataView) {
      return "unkown";
    }
    return dataView
      .replace("construction-project-delivery", "project-delivery")
      .replace("construction-project", "construction")
      .replace("muni-b", "muniB")
      .replace("muni-", "")
      .replace("-facilities", "");
  };

  var shortenTextByHalf = function (text) {
    if (text?.length > 14) {
      const halfLength = Math.ceil(text.length / 2);
      const firstHalf = text.substr(0, halfLength);
      return firstHalf;
    } else return text;
  };
  return {
    init,
    sendNavClickEvent,
    reportException,
    sendMapEvent,
    sendModalEvent,
    sendPopupEvent,
    setFilterContext,
    sendFilterEvent,
    sendSettingsEvent,
    clearFilterContext,
    sendScreenView,
    sendTableEvent,
    sendTodoEvent,
    sendDataListEvent,
    sendAction,
    _getScreenName,
    _dataViewNameAbriviation,
    shortenTextByHalf,
  };
};

module.exports = Analytics();

const Config = require("../config");
const Tree = require("../tree");
const ToolFunctions = require("../toolFunctions");
const Sentry = require("@sentry/browser");
