"use strict";

function DirectUpload() {
  var directUploadPost = null;

  // https://2ndnature.atlassian.net/l/c/odW5KMNG
  async function uploadFile(
    file,
    {
      stringKey = null, // Null to use real file name, string to generate unique name
      progressCallback = undefined,
    } = {},
  ) {
    assertFile(file);
    directUploadPost = FormSettings.getDirectUploadPost();

    if (!enabled()) {
      throw new Error(`AWS S3 is not enabled in the API. Please check enabled() before calling.`);
    }

    await getNewPostIfExpired();

    return await postFile(file, stringKey, progressCallback);
  }

  function assertFile(file) {
    if (!(file instanceof File)) {
      throw new Error(`${file} must be an instance of the File class.`);
    }
  }

  async function getNewPostIfExpired() {
    if (postExpired()) {
      await FormSettings.load();
      directUploadPost = FormSettings.getDirectUploadPost();
    }
  }

  function postExpired() {
    const expirationDate = DateTime.parseIsoDate(directUploadPost.expirationDate);
    const nowWithBuffer = getNowAddHours(1);

    return expirationDate < nowWithBuffer;
  }

  function getNowAddHours(hours) {
    const now = new Date(Date.now());

    now.setUTCHours(now.getUTCHours() + hours);

    return now;
  }

  async function postFile(file, stringKey, progressCallback) {
    const fileName = getUniqueFileName(file, stringKey);

    await Retryer.tryPromise(
      () => Network.postTo(directUploadPost.url, getPostData(file, fileName), progressCallback),
      { safeToRetryFunction: Retryer.isSafeToRetryApi },
    );

    return fileName;
  }

  function getUniqueFileName(file, stringKey) {
    if (stringKey === null) {
      return file.name;
    }

    const dotToEndOfString = /\.[^\.]+$/;
    const dotFileExtension = file.name.match(dotToEndOfString)[0];
    const newFileName = `${stringKey}_${UUID.v4()}`;

    return newFileName + dotFileExtension;
  }

  function getPostData(file, fileName) {
    const postData = directUploadPost["otherFields"];
    const path = directUploadPost["fileNameValuePrefix"] + fileName;

    postData[directUploadPost["fileNameFieldName"]] = path;
    postData[directUploadPost["fileFieldName"]] = file;

    return postData;
  }

  function enabled() {
    return directUploadPost !== null || FormSettings.getDirectUploadPost() !== null;
  }

  return {
    uploadFile,
    enabled,
  };
}

module.exports = DirectUpload();

const Network = require("../network");
const DateTime = require("../dateTime");
const FormSettings = require("../settings/formSettings");
const Retryer = require("../general/retryer");
const UUID = require("uuid");
