"use strict";

const FormSettings = function () {
  var labelSettings = {};
  var dropdownSettings = {};
  var directUploadPost = null;

  var load = function () {
    return ApiCalls.getFormSettings().then(saveSettings);
  };

  var saveSettings = function (data) {
    labelSettings = sortByDataViewAndFormKey(data["labelSettings"]);
    dropdownSettings = sortByDropdownKey(data["dropdownSettings"]);
    directUploadPost = data["directUploadPost"];
  };

  var sortByDataViewAndFormKey = function (arr) {
    return arr?.reduce((obj, item) => {
      const formKey = item["formKey"];

      const nestedObj = nestIntoObject("dataView", obj, item);
      if (!nestedObj[formKey]) {
        nestedObj[formKey] = [];
      }
      nestedObj[formKey].push(item);

      return obj;
    }, {});
  };

  var sortByDropdownKey = function (arr) {
    return arr?.reduce((obj, item) => {
      const dropdownKey = item["dropdownKey"];
      const nestedObj = nestIntoObject("tool", obj, item);

      if (!nestedObj[dropdownKey]) {
        nestedObj[dropdownKey] = item;
      }
      item.options.forEach((option) => {
        if (option.settings) {
          option.settings = option.settings[0];
        }
      });

      return obj;
    }, {});
  };

  var nestIntoObject = function (nestingKey, obj, item) {
    const keyValue = item[nestingKey];

    if (!(keyValue in obj)) {
      obj[keyValue] = {};
    }

    return obj[keyValue];
  };

  var getLabelSettingsByDataView = function (dataView = Tree.get("dataView")) {
    return labelSettings?.[dataView];
  };

  var getLabelSettingsByFormKey = function (formKey, dataView = Tree.get("dataView")) {
    const settingsByDataView = getLabelSettingsByDataView(dataView);
    if (settingsByDataView) {
      return settingsByDataView[formKey];
    }
  };

  var customizeForm = function ($container, formKey) {
    const formSettings = getLabelSettingsByFormKey(formKey);

    if (formSettings?.length) {
      formSettings.forEach((item) => {
        const $formField = $container.find(`[data-form-setting-key="${item.key}"]`);
        if (item.newLabel) {
          setNewLabel($formField, item.newLabel);
        }
        if (item.hide && $formField.hasClass("hideable")) {
          hideFormField($formField);
        }
      });
    }
  };

  var setNewLabel = function ($formField, newLabel) {
    if (newLabel && newLabel.includes("<a href=")) {
      newLabel = newLabel.replace(/<a href=/i, `<a target="_blank" href=`);
    }
    // Inventory fields
    $formField.find("legend, > h3").html(newLabel).addClass("text-uppercase");
    // Table rows
    $formField.find("[scope='row'] span").html(newLabel);
  };

  var hideFormField = function ($formField) {
    $formField.addClass("hide");
  };

  var getDropdownOptions = function (
    dropdownKey,
    { throwIfUndefined = true, tool = Tree.get("tool"), sort = false } = {},
  ) {
    const TableDataFunctions = require("../general/tableDataFunctions");
    const result = getDropdownOption(dropdownKey, tool);

    if (!result) {
      if (throwIfUndefined) {
        throw new Error(
          `dropdownSettings not found for dropdownKey: ${dropdownKey} and tool: ${tool}`,
        );
      }

      return [];
    }

    if (sort) {
      TableDataFunctions.niceSort(result.options, "name");
    }

    return result.options;
  };

  var getDropdownOption = function (dropdownKey, tool) {
    return dropdownSettings[tool]?.[dropdownKey] ?? dropdownSettings[null]?.[dropdownKey];
  };

  function getDropdownName(dropdownKey, value) {
    const options = getDropdownOptions(dropdownKey);
    return options.find((option) => option.value === value)?.name;
  }

  var getDirectUploadPost = function () {
    return directUploadPost;
  };

  return {
    load,
    saveSettings,
    customizeForm,
    getLabelSettingsByDataView,
    getLabelSettingsByFormKey,
    getDropdownOptions,
    getDropdownName,
    getDirectUploadPost,
  };
};

module.exports = FormSettings();

const ApiCalls = require("../apiCalls");
const Tree = require("../tree");
