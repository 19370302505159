"use strict";

const BmpObservation = function () {
  const $bmpObsModal = $("#observationModal");
  const $scoreModal = $("#score-modal");
  const formKey = "bmpobs";
  var $bmpObsForm;
  var soilData;
  var observationData;
  var infData;
  var runoffData;
  var tempInfData;
  var initialData;
  var depthDataByBenchmarkDepthId;

  // Form constants
  const conveyanceIssueOptions = [
    { name: "Access", value: "access" },
    { name: "Active Erosion", value: "active_erosion" },
    { name: "Debris/Clogging", value: "debris_clogging" },
    { name: "Structural Damage", value: "structural_damage" },
  ];

  var _renderModal = function (bmpTypeNumber) {
    const html = nunjucks.render("modals/bmpObservation/bmpObservation.njk", {
      conveyanceIssueOptions,
      observationData: getObservationFormData(),
      initialData,
      showInletOutlets: showInletOutlets(bmpTypeNumber),
      showWqcap: !FormConstants.hideFootprintWqcapTypeNumbers.includes(bmpTypeNumber),
      locationCount: getBmpObsLocationCount(),
      infiltrationMethod: getInfiltrationMethod(),
    });
    $bmpObsModal.find(".modal-body").html(html);

    $bmpObsForm = $("#bmp-observation-form");
  };

  var getObservationFormData = function () {
    return {
      ...observationData,
      obs_fxn_in_broken: totalInletOutletsRemaining(
        initialData?.inventory?.bmp_inlets,
        observationData.obs_fxn_in,
      ),
      obs_fxn_out_broken: totalInletOutletsRemaining(
        initialData?.inventory?.bmp_outlets,
        observationData.obs_fxn_out,
      ),
    };
  };

  var showInletOutlets = function (bmpTypeNumber) {
    return !BmpFcsFunctions.isPriorityCatchBasinType(bmpTypeNumber);
  };

  var totalInletOutletsRemaining = function (total, remaining) {
    total = parseInt(total);
    remaining = parseInt(remaining);

    if (isNaN(total) || isNaN(remaining)) {
      return "";
    }

    return total - remaining;
  };

  var initializeForm = function (obs_id) {
    // Form is currently only used for photos
    Form.initializeAndLoadListeners($bmpObsForm, formKey);

    $(`[name="obs_date"]`).datetimepicker({
      format: "YYYY-MM-DD HH:mm",
      defaultDate: observationData.obs_date
        ? new Date(observationData.obs_date)
        : observationData.obs_date,
    });
  };

  var loadListeners = function (isNew) {
    $bmpObsModal.off();
    $bmpObsModal.on("click", ".modal-footer .cancel-btn", cancelBmpObsForm);
    $bmpObsModal.on("click", ".modal-footer .save-btn", saveBmpObsForm);
    $bmpObsModal.on("click", ".modal-footer .calc-score-btn", calculateScoreClick);
    $bmpObsModal.on(
      "click",
      ".bmp-obs-field-group[data-field-group='permeability'] .save-btn",
      saveRunoff,
    );
    $bmpObsModal.on(
      "click",
      ".bmp-obs-field-group[data-field-group='infiltration'] .save-btn",
      saveBmpObsInf,
    );

    $bmpObsForm.off();
    $bmpObsForm.on("click", ".runoff-record .delete-btn", deleteRun);
    $bmpObsForm.on("click", ".inf-record .delete-btn", deleteObsInf);

    //Inventory listeners
    $bmpObsForm.on("change", "input[type=radio]", function () {
      var field = $(this).attr("name");
      observationData[field] = $(this).val();
      validateBmpObsFields();
    });

    $bmpObsForm.on("change", "input[type=checkbox]", function () {
      const field = $(this).attr("name");
      var data = Checkbox.getValueListFromCheckboxGroup($bmpObsForm, field);
      if (data === null) {
        data = "";
      }
      observationData[field] = data;
      validateBmpObsFields();
    });

    $bmpObsForm.on("blur", "input[type=text]", function () {
      var field = $(this).attr("name");

      if (field == "obs_date") {
        if (isNew && !isBmpObsDateValid($(this).val())) {
          $bmpObsForm
            .find("input[name='obs_date']")
            .data("DateTimePicker")
            .date(observationData.obs_date);
          return;
        }
      }

      observationData[field] = $(this).val();
      validateBmpObsFields();
    });

    $bmpObsForm.on("change", "input[type=number]", onNumberChange);

    $bmpObsForm.on("change", "select", function () {
      soilData[$(this).attr("name")] = $(this).val();
      validateBmpObsFields();
    });

    $bmpObsForm.on("blur", "textarea", function () {
      var field = $(this).attr("name");
      observationData[field] = $(this).val();
    });
  };

  var loadScoreListeners = function () {
    $scoreModal.off();
    $scoreModal.on("click", ".save-score-btn", saveBmpScore);
    $scoreModal.on("click", ".cancel-score-btn", cancelBmpScore);
    $scoreModal.on("click", ".preview-pdf-button", generatePdfPreview);
  };

  var customNumberChange = function ($input, field, value) {
    if (field === "runoff_height") {
      return true;
    } else if (field == "obs_time_minutes" || field == "obs_time") {
      var TEN_MINUTES = 600;
      var TEN = 10;
      var time = 0;
      var seconds = parseInt($("input[name='obs_time']").val());
      if (seconds < TEN) {
        $("input[name='obs_time']").val("0" + seconds);
      }
      var minutes = parseInt($("input[name='obs_time_minutes']").val());
      if (!isNaN(seconds)) {
        time = seconds;
      }
      if (!isNaN(minutes)) {
        time += minutes * 60;
      }
      if (time >= TEN_MINUTES) {
        $bmpObsForm.find("input[name='obs_time_minutes']").val(10);
        $bmpObsForm.find("input[name='obs_time']").val("00");
        Misc.toggleDisabled($("[name=runoff_height]"), false);
      } else {
        Misc.toggleDisabled($("[name=runoff_height]"), true);
        $bmpObsForm.find("input[name='runoff_height']").val("");
      }
      return true;
    } else if (field === "obs_dpth_msmt") {
      depthNumberChange($input, value);

      return true;
    }

    return false;
  };

  var depthNumberChange = function ($input, value) {
    const $depthRecord = $input.closest(".depth-record");
    const currentDepthUnit = $depthRecord.find("[data-unit]").data("unit");
    const bm_depth_id = $depthRecord.data("bm_depth_id");

    depthDataByBenchmarkDepthId[bm_depth_id] = _getCurrentDepthNumber(value, currentDepthUnit);
  };

  var _getCurrentDepthNumber = function (value, currentDepthUnit) {
    if (value === "") {
      return null;
    }

    value = value == "0" ? 0.01 : parseFloat(value);

    if (Misc.countDecimals(value) > 2) {
      value = Math.ceil(value * 100) / 100;
      $('#bmp-observation-form input[name="obs_dpth_msmt"]').val(value);
    }

    return UnitConversion.convertVal(value, currentDepthUnit, "feet");
  };

  var onNumberChange = function () {
    const $input = $(this);
    var field = $(this).attr("name");
    var value = $(this).val();

    if (customNumberChange($input, field, value)) {
      validateBmpObsFields();
      return;
    } else if (field == "obs_veg_wet" || field == "obs_veg_tree" || field == "obs_veg_grass") {
      var incrementVal = round5(parseFloat(value));
      observationData[field] = incrementVal;
      $("#bmp-observation-form input[name='" + field + "']").val(incrementVal);
      observationData[field] = validateBmpObsVegCover(field);
    } else if (field === "inf_locid" || field === "inf_time" || field === "inf_rdg") {
      tempInfData[field] = value;
      return;
    } else if (field === "obs_fxn_out_broken" || field === "obs_fxn_in_broken") {
      const result = getInletOutletValue(field, value);
      value = result.value;
      field = result.name;
      updateInletOutletDisplay();
    } else if (field === "bmp_inlets" || field === "bmp_outlets") {
      updateInletOutletMax(field, value);
      updateInletOutletDisplay();
    }

    observationData[field] = value;
    validateBmpObsFields();
  };

  var getInletOutletValue = function (field, value) {
    const fields = getInletOutletFields("broken", field);

    return {
      value: totalInletOutletsRemaining(
        $bmpObsForm.find(`[name="${fields.inventory}"]`).val(),
        value,
      ),
      name: fields.db,
    };
  };

  var getInletOutletFields = function (key, field) {
    const result = [
      { broken: "obs_fxn_out_broken", inventory: "bmp_outlets", db: "obs_fxn_out" },
      { broken: "obs_fxn_in_broken", inventory: "bmp_inlets", db: "obs_fxn_in" },
    ].find((fields) => fields[key] === field);

    if (!result) {
      throw new Error(`No ${key} field configured for inlet ${field}.`);
    }

    return result;
  };

  var updateInletOutletMax = function (field, value) {
    const fields = getInletOutletFields("inventory", field);

    value = parseInt(value);
    if (isNaN(value)) {
      return;
    }

    const $inletOutlet = $bmpObsForm.find(`[name="${fields.broken}"]`);
    $inletOutlet.attr("max", value);
    const inletOutletValue = parseInt($inletOutlet.val());
    if (isNaN(inletOutletValue)) {
      return;
    }

    if (inletOutletValue >= value) {
      $inletOutlet.val(value);
    }

    $inletOutlet.trigger("change"); // Recalculate obs_fxn
  };

  var cancelBmpObsForm = function () {
    if (Tree.get("readOnlyModal")) {
      closeBmpObsModal();
    } else {
      const doNotCloseButtonText = "Go Back";
      const closeButtonText = "Close Without Saving";
      MessageModal.showConfirmWarningModal(
        CommonModalFunctions.getCloseWithoutSavingPrompt(
          doNotCloseButtonText,
          closeButtonText,
          false,
        ),
        function () {
          closeBmpObsModal();
        },
        doNotCloseButtonText,
        closeButtonText,
      );
    }
  };

  var saveBmpObsForm = function () {
    var invalidFields = validateBmpObsFields(true);
    var current = Tree.get(["bmpram", "bmp"]);

    if (invalidFields.invalidDataGroups.length > 0 || invalidFields.invalidInputs.length > 0) {
      var fieldNames;

      CommonModalFunctions.removeHighlights($bmpObsForm);
      for (const group of invalidFields.invalidDataGroups) {
        CommonModalFunctions.highlight($bmpObsForm.find("[data-field-group='" + group + "']"));
      }
      for (const name of invalidFields.invalidInputs) {
        CommonModalFunctions.highlightParent($bmpObsForm.find("[name='" + name + "']"));
      }

      fieldNames = invalidFields.invalidDataGroups.map(
        (group) => FormConstants.dataGroupLabels[group],
      );
      fieldNames = fieldNames.concat(
        invalidFields.invalidInputs.map((input) => FormConstants.bmpObservationLabels[input]),
      );

      SaveIncompleteModal.showConfirmSaveIncompleteModal(
        current.bmpName,
        "observation",
        fieldNames,
        function () {
          _saveBmpObservation().then(closeBmpObsModalAndReload);
        },
      );
    } else {
      //if data entry is complete but score hasn't been calculated, prompt user to calc score to finish or save & return
      if (!$(".calc-score-btn").prop("disabled")) {
        MessageModal.showConfirmWarningModal(
          "You have saved the assessment data but have not calculated a BMP RAM score yet. Click 'OK' to close the assessment form and calculate the score at a later time. Click 'Cancel' to return to the form and calculate the score now.",
          function () {
            _saveBmpObservation().then(closeBmpObsModalAndReload);
          },
        );
      }
    }
  };

  var cancelBmpScore = function () {
    $scoreModal.modal("hide");
    $bmpObsModal.modal("show");
    $(".score-text").text("");
  };

  var calculateScoreClick = function () {
    showCalcScoreModal();
  };

  var showBmpObsModal = async function (
    idBmp,
    bmpName,
    bmpType,
    bmpTypeNumber,
    readOnly,
    obs_id = null,
  ) {
    const isNew = obs_id === null;

    const data = await ApiCalls.getBmpObservation(idBmp, obs_id);
    _setVariables({
      bmpType,
      initial: data,
      inf: data.observationInfiltration,
      runoff: data.runoff,
      observation: getObservationData(data, bmpName),
    });

    _renderModal(bmpTypeNumber);
    initializeForm(obs_id);
    loadListeners(isNew);

    setBmpObsFormDisplayOptions(bmpName, bmpType);

    CommonModalFunctions.handleReadOnlyView($bmpObsModal, readOnly);
    setBmpObsFormAlwaysDisabled();

    Tree.set(["bmpram", "bmp"], {
      bmp_inlets: data.inventory.bmp_inlets,
      bmp_outlets: data.inventory.bmp_outlets,
      bmpName: bmpName,
      idBmp: idBmp,
    });

    var temp = Tree.get(["bmpram", "bmp"]);
    temp["bm_id"] = data.benchmark?.bm_id ?? 0;
    Tree.set(["bmpram", "bmp"], temp);

    handleExtraBmpObsFields(bmpTypeNumber);
    populateBmpObsForm(observationData);
    showInfFields(data, bmpType);

    if (obs_id) {
      loadExistingObservation(data, temp, bmpType);
    } else {
      loadNewObservation(data);
    }

    populateUsername();
  };

  var _setVariables = function ({
    initial = {},
    bmpType = {},
    soil = {},
    observation = {},
    inf = [],
    tempInf = {},
    runoff = [],
    depthByBenchmarkDepthId = {},
    benchmark = [{}],
  } = {}) {
    initialData = initial;
    soilData = soil;
    observationData = observation;
    infData = inf;
    tempInfData = tempInf;
    runoffData = runoff ?? [];
    depthDataByBenchmarkDepthId = depthByBenchmarkDepthId;
    Tree.set(["bmpram", "benchmarkData"], benchmark);
  };

  var loadExistingObservation = function (data, temp, bmpType) {
    if ($bmpObsForm.find(".depth-container").is(":visible")) {
      showBmpObsDepthRecords(data);
    }
    if (bmpType == "Biofiltration" || bmpType == "Bioretention") {
      showSoil(data);
    }
    if ($(".runoff-container").is(":visible")) {
      renderRunoff();
    }
    validateBmpObsFields();
  };

  var getObservationData = function (data, bmpName) {
    let observation = data.observation;

    if (!observation) {
      observation = {
        bmpName: bmpName,
        bm_id: data.benchmark?.bm_id,
        idBmp: data.inventory.id,
        bmp_groupid: Tree.get("activeGroup", "groupId"),
        obs_date: DateTime.getNowIso(),
        obs_pers: Tree.get(["user", "username"]),
      };
    }

    return observation;
  };

  var loadNewObservation = function (data) {
    if ($bmpObsForm.find(".depth-container").is(":visible")) {
      showBmpObsDepthRecords(data);
    }
  };

  var setBmpObsFormDisplayOptions = function (bmpName, bmpType) {
    $("#observationModal .bmp-id").text(bmpName);
    $("#observationModal .bmp-type").text(bmpType);

    $("#observationModal").modal("show");
    $(".runoff-container").empty();

    $bmpObsForm.find(".inf-container").empty();
    $("#bmp-observation-form select").val("");

    updateInletOutletDisplay();

    Analytics.sendScreenView("modal", "assessment", "bmp");
  };

  var updateInletOutletDisplay = function () {
    const $brokenInlets = $bmpObsForm.find(`[name="obs_fxn_in_broken"]`);
    const $brokenInletContainer = $brokenInlets.parent();
    const $inletIssues = $bmpObsForm.find(`[name="obs_fxn_in_issues"]`);
    const $inletIssuesContainer = $inletIssues.closest("fieldset");
    const showInlets = valueExistsNotZero($bmpObsForm.find(`[name="bmp_inlets"]`).val());
    const showInletIssues = showInlets && valueExistsNotZero($brokenInlets.val());

    const $brokenOutlets = $bmpObsForm.find(`[name="obs_fxn_out_broken"]`);
    const $brokenOutletContainer = $brokenOutlets.parent();
    const $outletIssues = $bmpObsForm.find(`[name="obs_fxn_out_issues"]`);
    const $outletIssuesContainer = $outletIssues.closest("fieldset");
    const showOutlets = valueExistsNotZero($bmpObsForm.find(`[name="bmp_outlets"]`).val());
    const showOutletIssues = showOutlets && valueExistsNotZero($brokenOutlets.val());

    $brokenInletContainer.toggle(showInlets);
    $inletIssuesContainer.toggle(showInletIssues);
    if (!showInletIssues) {
      $inletIssues.prop("checked", false).trigger("change");
    }

    $brokenOutletContainer.toggle(showOutlets);
    $outletIssuesContainer.toggle(showOutletIssues);
    if (!showOutletIssues) {
      $outletIssues.prop("checked", false).trigger("change");
    }
  };

  var valueExistsNotZero = function (value) {
    return value !== "0" && value !== "";
  };

  var populateUsername = function () {
    $("#observationModal").find("input[name='obs_pers']").val(observationData.obs_pers);
  };

  var setBmpObsFormAlwaysDisabled = function () {
    Misc.toggleDisabled($(".calc-score-btn"), true);
    Misc.toggleDisabled($("[name=runoff_height]"), true);
  };

  var handleExtraBmpObsFields = function (typeNumber) {
    var currentBmpFcs = Tree.get("currentBmpFcs");
    var requiredFields = [];
    var optionalFields = [];
    $(".bmp-obs-field-group.type-specific").hide();

    if (currentBmpFcs.system_access === false) {
      requiredFields = getSpecialCaseBmpObsFields(currentBmpFcs);
    } else {
      const extraBmpObsFieldsByType = getExtraBmpObsFieldsByType(typeNumber);
      requiredFields = extraBmpObsFieldsByType.required;
      optionalFields = extraBmpObsFieldsByType.optional;
    }

    requireExtraFields(requiredFields, optionalFields);
  };

  var getExtraBmpObsFieldsByType = function (typeNumber) {
    if (FormConstants.EXTRA_BMP_OBS_FIELDS_BY_TYPE[typeNumber]) {
      return FormConstants.EXTRA_BMP_OBS_FIELDS_BY_TYPE[typeNumber];
    }
    return { required: [], optional: [] };
  };

  var getSpecialCaseBmpObsFields = function (currentBmpFcs) {
    var obsPortMatAccum = currentBmpFcs.inv_sedacc;
    var obsPortStdWater = currentBmpFcs.inv_stwat;
    if (obsPortMatAccum == 1 && obsPortStdWater == 1) {
      if (currentBmpFcs.bmpType === 21) {
        return ["mat-accum"];
      } else {
        return ["mat-accum", "obs-port"];
      }
    } else if (obsPortMatAccum == 1 && obsPortStdWater == 0) {
      return ["mat-accum"];
    } else if (obsPortMatAccum == 0 && obsPortStdWater == 1) {
      return ["obs-port"];
    }
    return [];
  };

  //limit to increments of 5
  var round5 = function (x) {
    return x % 5 >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5;
  };

  var populateBmpObsForm = function (data) {
    if (data) {
      for (var key in data) {
        if (key == "obs_cs_stwat" || key == "obs_cs_24stwat") {
          $("input[name=" + key + "][value=" + data[key] + "]").prop("checked", true);
        } else if (key === "obs_date") {
          const obsDate = data[key] ? new Date(data[key]) : data[key];
          $('#bmp-observation-form :input[name="' + key + '"]')
            ?.data("DateTimePicker")
            ?.date(obsDate);
        } else if (key === "obs_fxn_out_issues" || key === "obs_fxn_in_issues") {
          // Populated via Nunjucks
        } else {
          $('#bmp-observation-form :input[name="' + key + '"]').val(data[key]);
        }
      }
    }
    validateBmpObsVegCover();
  };

  var getBmpObsLocationCount = function () {
    const footprint = parseFloat(initialData?.inventory?.bmp_footprint);
    return footprint >= 3000 ? 6 : 3;
  };

  var validateBmpObsVegCover = function (field) {
    var HUNDRED = 100;
    var obsWet = parseInt($("#bmp-observation-form input[name='obs_veg_wet']").val());
    var obsTree = parseInt($("#bmp-observation-form input[name='obs_veg_tree']").val());
    var obsGrass = parseInt($("#bmp-observation-form input[name='obs_veg_grass']").val());
    var bareSoil =
      HUNDRED -
      [
        (isNaN(obsWet) ? 0 : obsWet) +
          (isNaN(obsTree) ? 0 : obsTree) +
          (isNaN(obsGrass) ? 0 : obsGrass),
      ];
    if (bareSoil < 0) {
      MessageModal.showSimpleWarningModal("Total percentage exceeds 100%, please re-enter values.");
      $("#bmp-observation-form input[name='" + field + "']").val(null);
    } else {
      $("#bmp-observation-form .bareSoil").text(bareSoil);
      return bareSoil;
    }
    return null;
  };

  var showBmpObsDepthRecords = function (data) {
    const depthArray = data.depth;
    const $observationContainer = $bmpObsForm.find(".depth-container");
    $observationContainer.empty();

    for (var i = 0; i < depthArray.length; i++) {
      const depthHtml = nunjucks.render("modals/bmpObservation/obsDepth.njk", {
        depth: {
          depthId: depthArray[i].dpth_id,
          bm_depth_id: depthArray[i].bm_depth_id ?? depthArray[i].dpth_id,
          depthDesc: depthArray[i].dpth_desc,
          depthTypeName: Benchmark.getDepthTypeName(depthArray[i].bm_dpth_type),
          depthValue: depthArray[i].dpth_unit
            ? UnitConversion.convertVal(
                parseFloat(depthArray[i].obs_dpth_msmt),
                "feet",
                depthArray[i].dpth_unit,
              )
            : "—",
          depthUnit: depthArray[i].dpth_unit ? depthArray[i].dpth_unit : "—",
          depthUnitAbbr: depthArray[i].dpth_unit
            ? UnitConversion.getUnitAbbreviation(depthArray[i].dpth_unit)
            : "—",
        },
      });
      $observationContainer.append(depthHtml);
      CommonModalFunctions.toggleDisabledInputs($observationContainer);
    }
  };

  var showBmpObsInfiltrationData = function () {
    const dataArrInf = getUndeletedInfData();

    if (dataArrInf.length) {
      var avgRate = calcBmpObsInfRate(dataArrInf);
      observationData["obs_inf_rate"] = avgRate;
      observationData = Benchmark.removeInvFields(observationData);
      observationData = Benchmark.removeNullFields(observationData);
    } else {
      $bmpObsForm.find(".inf-container").empty();
    }
  };

  var calcBmpObsInfRate = function (observationsArray) {
    //finds average rate for each location then averages those for final rate value
    var obsArray = separateLocationsObs(observationsArray);
    obsArray = sortByTimeObs(obsArray);
    var locationCount, totalRate, avgRate;
    locationCount = totalRate = avgRate = 0;
    $bmpObsForm.find(".inf-container").empty();

    obsArray.forEach(function (item, index) {
      var locationRate = 0;
      for (let i = 0; i < item.length; i++) {
        const infHtml = nunjucks.render("modals/bmpObservation/obsInfiltration.njk", {
          inf: item[i],
        });
        $bmpObsForm.find(".inf-container").append(infHtml);
      }
      if (item.length >= 2) {
        locationCount++;
        var tempTime, tempReading, tempRate;
        for (let i = 0; i < item.length - 1; i++) {
          tempTime = item[i + 1]["inf_time"] - item[i]["inf_time"];
          tempReading = item[i + 1]["inf_rdg"] - item[i]["inf_rdg"];
          tempRate = tempReading / tempTime;
          locationRate += Math.abs(tempRate);
        }
        totalRate += locationRate / (item.length - 1);
      }
    });
    avgRate = totalRate / locationCount;
    $("#observationModal input[name='obs_inf_rate']").val(avgRate);
    return avgRate;
  };

  var getUndeletedInfData = function () {
    return infData.filter((inf) => inf.deleted !== true);
  };

  var _getInfSaveData = function () {
    const saveData = _getInfData();

    for (const inf of saveData) {
      delete inf.tempId;
    }

    return saveData;
  };

  var _getRunoffSaveData = function () {
    const saveData = [];

    for (const datum of structuredClone(runoffData)) {
      delete datum.tempId;

      const savedAndUndeleted = datum.obs_run_id && datum.deleted !== true;
      if (!savedAndUndeleted) {
        saveData.push(datum);
      }
    }

    return saveData;
  };

  var _getCurrentUndeletedInfData = function () {
    return (initialData.observationInfiltration ?? [])
      .mergeByKey(_getInfData(), function (inf) {
        return inf.obs_inf_id ?? Symbol();
      })
      .filter((inf) => inf.deleted !== true);
  };

  var _getInfData = function () {
    return structuredClone(infData);
  };

  var separateLocationsObs = function (obsArray) {
    //groups observation records by location
    var idname = "inf_locid";
    var locationArray = [];
    for (var i = 0; i < obsArray.length; i++) {
      var locid = obsArray[i][idname];
      if (locationArray[locid] !== undefined) {
        locationArray[locid].push(obsArray[i]);
      } else {
        locationArray[locid] = [];
        locationArray[locid].push(obsArray[i]);
      }
    }
    return locationArray;
  };

  //TODO combine with above if possible
  var sortByTimeObs = function (dataArray) {
    var timename = "inf_time";
    dataArray.forEach(function (item, index) {
      dataArray[index].sort(function (a, b) {
        return parseFloat(a[timename]) - parseFloat(b[timename]);
      });
    });
    return dataArray;
  };

  var showInfFields = function (data, bmpType) {
    const activeBench = data.benchmark;
    const isInfBmpType =
      (bmpType == "Dry Basin" || bmpType == "Infiltration Basin") &&
      data.inventory.inaccessible != 1;
    if (!activeBench || !isInfBmpType) {
      return;
    }

    if (activeBench.inf_input == "observation") {
      $(".obsTable").show();
      $(".rateValue").hide();
    } else {
      $(".obsTable").hide();
      $(".rateValue").show();
    }

    showBmpObsInfiltrationData();
  };

  var getInfiltrationMethod = function () {
    const activeBench = initialData.benchmark;
    if (activeBench?.bm_inf_obs == 1) {
      return "CHP";
    } else if (activeBench?.bm_inf_obs == 2) {
      return "Infiltrometer";
    } else {
      return "Other";
    }
  };

  var showSoil = function (data) {
    const soilData = data.soil;

    for (var key in soilData) {
      if (soilData.hasOwnProperty(key)) {
        $("#bmp-observation-form select[name=" + key + "]").val(soilData[key]);
      }
    }
  };

  var renderRunoff = function () {
    $(".runoff-container").empty();
    const undeletedData = runoffData.filter((datum) => datum.deleted !== true);

    for (var i = 0; i < undeletedData.length; i++) {
      var minutes = Math.floor(undeletedData[i].obs_time / 60);
      var seconds = undeletedData[i].obs_time - minutes * 60;
      if (seconds == 0) {
        seconds = "00";
      } else if (seconds < 10) {
        seconds = "0" + seconds;
      }

      const runoffHtml = nunjucks.render("modals/bmpObservation/obsRunoff.njk", {
        runoff: {
          runoffId: undeletedData[i].obs_run_id,
          tempId: undeletedData[i].tempId,
          runoffHeight: undeletedData[i].runoff_height,
          minutes: minutes,
          seconds: seconds,
        },
      });
      $(".runoff-container").append(runoffHtml);
      $(".runoff-container").show();
    }
  };

  // todo clean req checks
  var validateBmpObsFields = function (returnInvalidLabels = false) {
    var invalidDataGroups = [];
    var invalidInputs = [];
    var isInvalid;

    const $filteredGroups = $bmpObsForm.find(
      ".bmp-obs-field-group:not([data-field-group='infiltration'], [data-field-group='permeability'])",
    );
    $filteredGroups.find("input:visible").each(function (index, element) {
      if (
        $(element).is(":visible") &&
        !element.checkValidity() &&
        !invalidInputs.includes(element.name)
      ) {
        invalidInputs.push(element.name);
      }
    });
    //check for runoff required fields

    if (
      $bmpObsForm.find("[data-field-group='permeability']").is(":visible") &&
      !$(".runoff-record").is(":visible")
    ) {
      invalidDataGroups.push("permeability");
    }
    //check for soil selecttion
    if ($(".soilDrops").is(":visible")) {
      $(".soilDrop").each(function (index) {
        if ($(this).val() === "") {
          invalidDataGroups.push("substrate-type");
          return false;
        }
      });
    }

    //check for Infiltration
    if ($bmpObsForm.find(".inf-container").is(":visible")) {
      if ($(".bmp-obs-field-group .inf-record").length) {
        var infCheck = validateInf();
        if (infCheck == false) {
          invalidDataGroups.push("infiltration");
        }
      } else {
        invalidDataGroups.push("infiltration");
      }
    }

    checkboxGroupFilled(invalidDataGroups, "obs_fxn_in_issues");
    checkboxGroupFilled(invalidDataGroups, "obs_fxn_out_issues");

    isInvalid = invalidDataGroups.length > 0 || invalidInputs.length > 0;

    Misc.toggleDisabled($bmpObsModal.find(".calc-score-btn"), isInvalid);

    if (!returnInvalidLabels) {
      return !isInvalid;
    }

    return { invalidDataGroups, invalidInputs };
  };

  var checkboxGroupFilled = function (invalidDataGroups, fieldGroup) {
    const $group = $bmpObsForm.find(`[data-field-group="${fieldGroup}"]:visible`);

    if ($group.length && !$group.find(":checkbox:checked").length) {
      invalidDataGroups.push(fieldGroup);
    }
  };

  var _saveBmpObservation = async function (saveScore = false) {
    const observationSaveData = await getObservationSaveData();
    await ApiCalls.saveBmpObservation(
      observationSaveData,
      soilData,
      _getInfSaveData(),
      _getRunoffSaveData(),
      depthDataByBenchmarkDepthId,
      saveScore,
    );
  };

  var getObservationSaveData = async function (includePhotos = true) {
    var cleanObsArray = structuredClone(observationData);
    cleanObsArray = Benchmark.removeNullFields(cleanObsArray);
    cleanObsArray = Benchmark.removeReadOnlyFields("#bmp-observation-form", cleanObsArray);

    await Form.getReadyToSavePromise(formKey);
    if (includePhotos) {
      cleanObsArray.photos = Form.getFormDataAtPath(formKey, ["photos"]);
    } else {
      const photos = structuredClone(Form.getFormDataAtPath(formKey, ["photos"]));
      cleanObsArray.photos = InspectionPreview.convertImagesToPreviews(photos);
    }

    _addAverageDepth(cleanObsArray);

    return cleanObsArray;
  };

  var validateInf = function () {
    var array = $bmpObsForm
      .find(".infLocid")
      .map(function () {
        return $(this).text().trim();
      })
      .get();

    for (var i in array) {
      if (countInArray(array, array[i]) == 1) {
        $("#infiltration-warning")
          .text("There must be at least two entries for each infiltration location.")
          .show();
        return false;
      }
    }

    $("#infiltration-warning").hide();

    function countInArray(array, what) {
      return array.filter((item) => item == what).length;
    }
  };

  var _addAverageDepth = function (cleanObsArray) {
    const depths = getCurrentDepths();

    if (depths.length === 0) {
      return;
    }

    cleanObsArray["depth_avg"] = calcAverageDepth(depths);
  };

  var calcAverageDepth = function (currentDepths) {
    var averageDepth = 0;
    var depthCount = 0;

    for (const depth of currentDepths) {
      if (depth !== null) {
        averageDepth += parseFloat(depth);
        depthCount++;
      }
    }

    if (depthCount === 0) {
      return null;
    }

    return averageDepth / depthCount;
  };

  var getCurrentDepths = function () {
    const resultByBenchmarkDepthId = {};

    for (const depth of initialData?.depth ?? []) {
      if ("obs_dpth_msmt" in depth) {
        resultByBenchmarkDepthId[depth.bm_depth_id] = depth.obs_dpth_msmt;
      }
    }

    for (const bm_depth_id in depthDataByBenchmarkDepthId) {
      resultByBenchmarkDepthId[bm_depth_id] = depthDataByBenchmarkDepthId[bm_depth_id];
    }

    return Object.values(resultByBenchmarkDepthId);
  };

  var saveBmpObsInf = function () {
    const errorMessage = getInfiltrationErrorMessage(
      $bmpObsForm,
      tempInfData,
      _getCurrentUndeletedInfData(),
    );
    if (errorMessage) {
      MessageModal.showSimpleWarningModal(errorMessage);
      return;
    }

    _saveNewObsInfRow();
    resetBmpObsInfForm();
    validateBmpObsFields();
  };

  var getInfiltrationErrorMessage = function ($form, tempInfData, existingData) {
    const missingFieldMessage = getMissingInfiltrationMessage($form, tempInfData);
    if (missingFieldMessage) {
      return missingFieldMessage;
    } else if (Infiltration.sameLocIdAndTimeExists(tempInfData, existingData)) {
      return "You cannot enter a second Reading value for the same Location ID and Minutes. Please edit your data entry.";
    }
    return null;
  };

  var getMissingInfiltrationMessage = function ($form, tempInfData) {
    const missingFieldLabels = getMissinFieldLabels($form, tempInfData, [
      "inf_locid",
      "inf_time",
      "inf_rdg",
    ]);

    if (!missingFieldLabels.length) {
      return null;
    }

    return `To save the infiltration observation, please fill out the following fields: ${NunjucksFilters.listWords(
      missingFieldLabels,
    )}`;
  };

  var getMissinFieldLabels = function ($form, data, fieldNames) {
    const missingLabels = [];

    for (const field of fieldNames) {
      if (!data[field]) {
        const label = $form.find(`[name="${field}"]`).parent().find(".form-label").text().trim();

        if (!label) {
          throw new Error(`No label found for field ${field}`);
        }

        missingLabels.push(label);
      }
    }

    return missingLabels;
  };

  var resetBmpObsInfForm = function () {
    tempInfData.inf_time = null;
    tempInfData.inf_rdg = null;

    $bmpObsForm.find("input[name='inf_time']").val("");
    $bmpObsForm.find("input[name='inf_rdg']").val("");
  };

  var deleteObsInf = function (e) {
    const $obsInf = $(this).closest(".inf-record");

    MessageModal.showDeleteRecordModal(function () {
      _deleteObsInfById($obsInf.data("infid"), $obsInf.data("tempId"));
      validateBmpObsFields();
    });
  };

  var _deleteObsInfById = function (obs_inf_id, tempId) {
    _deleteById(infData, obs_inf_id, "obs_inf_id", tempId);
    showBmpObsInfiltrationData();
  };

  var _deleteById = function (allData, id, idKeyName, tempId) {
    if (tempId) {
      const index = allData.findIndex((datum) => datum.tempId === tempId);
      allData.splice(index, 1);
    } else {
      const datum = allData.find((datum) => datum[idKeyName] === id);
      datum.deleted = true;
    }
  };

  var _saveNewObsInfRow = function () {
    infData.push({
      tempId: UUID.v4(),
      ...tempInfData,
    });

    showBmpObsInfiltrationData();
  };

  var saveRunoff = function () {
    var TEN_MINUTES = 600;
    var obsid = observationData.obs_id;

    var minutes = parseInt($bmpObsForm.find("input[name='obs_time_minutes']").val());
    var time = parseInt($bmpObsForm.find("input[name='obs_time']").val());
    if (minutes) {
      time += minutes * 60;
    }
    var spread = $bmpObsForm.find("input[name='runoff_height']").val();
    if ((time < TEN_MINUTES && time > 0) || (time == TEN_MINUTES && spread)) {
      var record = {
        obs_time: time,
        obs_id: obsid,
      };

      if (spread) {
        record.runoff_height = spread;
      }
      saveRunoffRecord(record);
    } else if (time == TEN_MINUTES && !spread) {
      MessageModal.showSimpleWarningModal("Enter height value to add permeability measurement.");
    } else if (time == 0) {
      MessageModal.showSimpleWarningModal(
        "Enter a valid time value to add permeability measurement.",
      );
    } else {
      MessageModal.showSimpleWarningModal("Enter time value to add permeability measurement.");
    }
  };

  var saveRunoffRecord = function (record) {
    record.tempId = UUID.v4();
    runoffData.push(record);
    renderRunoff();
    clearRunoffForm();
    validateBmpObsFields();
  };

  var clearRunoffForm = function () {
    $bmpObsForm.find("input[name='obs_time']").val("");
    $bmpObsForm.find("input[name='obs_time_minutes']").val("");
    $bmpObsForm.find("input[name='runoff_height']").val("");
    Misc.toggleDisabled($("[name=runoff_height]"), true);
  };

  var deleteRun = function (e) {
    const $runoff = $(this).closest(".runoff-record");

    MessageModal.showDeleteRecordModal(function () {
      deleteRunoffRecord($runoff.data("runoffid"), $runoff.data("tempId"));
    });
  };

  var deleteRunoffRecord = function (runoffId, tempId) {
    _deleteById(runoffData, runoffId, "obs_run_id", tempId);
    clearRunoffForm();
    renderRunoff();
    validateBmpObsFields();
  };

  var showCalcScoreModal = async function () {
    const results = await getBmpScore();
    let lastScore = parseFloat(Tree.get("currentBmpFcs").bmpScore);
    if (isNaN(lastScore)) {
      lastScore = 0;
    }

    renderScoreModal(results["actions"]);

    const score = parseFloat(results["score"]).toFixed(1);
    BmpFunctions.animateScoreUpdate(lastScore, score);

    $bmpObsModal.modal("hide");
  };

  var renderScoreModal = function (remediationActions) {
    const html = nunjucks.render("modals/bmpObservation/score.njk", {
      remediationActions,
      ...ResultModal.getAllConfirmText("assessment"),
    });

    $scoreModal.html(html);
    $scoreModal.modal("show");

    loadScoreListeners();
  };

  var getBmpScore = async function () {
    const response = await ApiCalls.calculateBmpScore(
      await getObservationSaveData(false),
      soilData,
      _getInfSaveData(),
      _getRunoffSaveData(),
      depthDataByBenchmarkDepthId,
    );

    return response;
  };

  var saveBmpScore = function () {
    _saveBmpObservation(true).then(closeBmpObsModalAndReload);

    $scoreModal.modal("hide");
    $("#scoreName").text("");
    $(".score-text").text("");
  };

  var closeBmpObsModalAndReload = function () {
    MainMap.reloadBmpFcsLayer();
    closeBmpObsModal();
  };

  var closeBmpObsModal = function () {
    Form.clearForm(formKey);
    $bmpObsModal.modal("hide");
    Analytics.sendScreenView();
  };

  var isBmpObsDateValid = function (date) {
    var currentBenchmarkDate = initialData.benchmark?.bm_date;
    var datesData = {};

    if (currentBenchmarkDate) {
      datesData.earlierDate = currentBenchmarkDate;
      datesData.earlierDateName = "Current benchmark date";
    } else {
      return true;
    }

    datesData.laterDate = date;
    datesData.laterDateName = "BMP assessment date";
    return Benchmark.validateDate(datesData);
  };

  var requireExtraFields = function (requiredFields, optionalFields) {
    for (const field of [...requiredFields, ...optionalFields]) {
      const isRequired = !optionalFields.includes(field);

      const $fieldGroup = $bmpObsForm.find(`[data-field-group='${field}']`);
      $fieldGroup.show();

      const $fieldsets = $fieldGroup.find("fieldset"); // Read-only fields are divs, not fieldsets
      $fieldsets.find(".form-label").toggleClass("required", isRequired);
      $fieldsets.find("input").attr("required", isRequired);
    }
  };

  var generatePdfPreview = async function () {
    const preview = new PreviewDownloader();
    const pdf = await ApiCalls.bmpObservationPdfPreview(
      await getObservationSaveData(false),
      soilData,
      _getInfSaveData(),
      _getRunoffSaveData(),
      depthDataByBenchmarkDepthId,
    );
    preview.openBlob(new Blob([pdf], { type: "application/pdf" }));
  };

  return {
    showBmpObsModal,
    calcAverageDepth,
    _saveBmpObservation,
    _renderModal,
    _setVariables,
    _addAverageDepth,
    _saveNewObsInfRow,
    _deleteObsInfById,
    _getInfSaveData,
    _getCurrentUndeletedInfData,
    _getInfData,
    _getRunoffSaveData,
    _getCurrentDepthNumber,
    getInfiltrationErrorMessage,
  };
};

module.exports = BmpObservation();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const Benchmark = require("./benchmark");
const BmpFunctions = require("./bmpFunctions");
const CommonModalFunctions = require("./commonModalFunctions");
const FormConstants = require("../mapFunctions/formConstants");
const Infiltration = require("./infiltration");
const MainMap = require("../mapFunctions/mainMap");
const MessageModal = require("./messageModal");
const SaveIncompleteModal = require("./saveIncompleteModal");
const Misc = require("../misc");
const Tree = require("../tree");
const UnitConversion = require("../unitConversion");
const UUID = require("uuid");
const Checkbox = require("../general/checkbox");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const Form = require("../general/form");
const DateTime = require("../dateTime");
const NunjucksFilters = require("../general/nunjucksFilters");
const PreviewDownloader = require("../files/previewDownloader");
const InspectionPreview = require("../general/inspectionPreview");
const ResultModal = require("../general/resultModal");
