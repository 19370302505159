"use strict";

const LayerDropdown = function () {
  var startLayerDropdownListeners = function () {
    var $dropdown = $(".layer-dropdown");

    $dropdown.off();
    $dropdown.on("click", ".dropdown-menu li", layerDropdownLiClick);
  };

  var layerDropdownLiClick = function () {
    if ($(this).hasClass("disabled")) return;
    var layerDropdown = $(this).data("layer-dropdown");
    setLayerDropdown(layerDropdown);

    var openPopups = $(".leaflet-popup-close-button");
    if (openPopups.length) {
      openPopups[0].click();
    }

    Analytics.sendAction(["select_layer", layerDropdown]);
  };

  var setLayerDropdown = function (layerDropdown) {
    const mapId = Tree.get("mapId");
    const originalSelection = Tree.get("layerDropdown", mapId);

    Tree.set(["layerDropdown", mapId], layerDropdown);
    setSentryExtra("layerDropdown", layerDropdown);
    _setDropdownSelection(layerDropdown);

    PageFunctions.getCurrentPage().find(".floating-legend").attr("layer-dropdown", layerDropdown);

    if (originalSelection !== layerDropdown) {
      DataViewFunctions.setMapLayerFromDataView();
    }
  };

  var isLayerDropdownEnabled = function () {
    const mapId = Tree.get("mapId");
    const layerDropdown = DataViewFunctions.getCurrentDataViewProperty("layerDropdown");
    return (layerDropdown && layerDropdown[mapId]) !== undefined;
  };

  var populateLayerDropdown = function () {
    const $dropdownMenu = PageFunctions.getCurrentPage().find(".layer-dropdown");
    const dropdownEnabled = isLayerDropdownEnabled();

    $dropdownMenu.toggleClass("enabled", dropdownEnabled);

    if (!dropdownEnabled) {
      return;
    }

    const mapId = Tree.get("mapId");
    const template = "report/layerDropdown.njk";
    let dropdownItems = DataViewFunctions.getCurrentDataViewProperty("layerDropdown")[mapId];
    const selected = Tree.get("layerDropdown", mapId);
    if (!FeatureFlag.enabled("layer-dropdown-matrix")) {
      dropdownItems = dropdownItems.filter((layer) => !layer.layerDropdown.includes("matrix"));
    }

    $dropdownMenu.html(nunjucks.render(template, { dropdownItems }));
    _setDropdownSelection(selected);
  };

  var _setDropdownSelection = function (layerDropdown) {
    var $selectedLi = PageFunctions.getCurrentPage()
      .find(`.layer-dropdown li[data-layer-dropdown="${layerDropdown}"]`)
      .closest("li");
    var dropdownButtonText = $selectedLi.find("a").text();

    Dropdown.select($selectedLi);
    _setDropdownButtonText(dropdownButtonText);
  };

  var _setDropdownButtonText = function (dropdownButtonText) {
    var pageName = PageFunctions.getCurrentPageProperty("displayName");
    pageName = removeTrailingS(pageName);
    PageFunctions.getCurrentPage()
      .find(".layer-dropdown .dropdown-btn-text")
      .html(`${pageName}: ${dropdownButtonText}`);
  };

  var removeTrailingS = function (string) {
    return string.endsWith("s") ? string.slice(0, string.length - 1) : string;
  };

  var setSentryExtra = function (extra, value) {
    Sentry.getCurrentScope().setExtra(extra, value);
  };

  var handleLayerDropdownDisplay = function () {
    var show = isLayerDropdownEnabled();
    PageFunctions.getCurrentPage().find(".layer-dropdown").toggle(show);
  };

  var getSelectedDropdown = function () {
    const mapId = Tree.get("mapId");
    return Tree.get("layerDropdown", mapId);
  };

  var getCurrentLayerDropdownProperty = function (property) {
    const mapId = Tree.get("mapId");
    const currentLayerDropdown = getSelectedDropdown();
    const dataViewLayerDropdowns = DataViewFunctions.getCurrentDataViewProperty("layerDropdown");
    if (dataViewLayerDropdowns) {
      const layerDropdonws = dataViewLayerDropdowns[mapId];
      if (currentLayerDropdown && layerDropdonws) {
        for (const item of layerDropdonws) {
          if (item.layerDropdown === currentLayerDropdown) {
            return item[property];
          }
        }
      }
    }
  };

  var isBaseline = function () {
    return getSelectedDropdown() === "baseline";
  };

  var isCurrent = function () {
    return getSelectedDropdown() === "current";
  };

  var isMatrixBaseline = function () {
    return getSelectedDropdown() === "matrixBaseline";
  };

  var isMatrixCurrent = function () {
    return getSelectedDropdown() === "matrixCurrent";
  };

  return {
    startLayerDropdownListeners,
    isLayerDropdownEnabled,
    populateLayerDropdown,
    handleLayerDropdownDisplay,
    isBaseline,
    isMatrixBaseline,
    isMatrixCurrent,
    getSelectedDropdown,
    getCurrentLayerDropdownProperty,
    isCurrent,
  };
};

module.exports = LayerDropdown();

const Analytics = require("../../general/analytics");
const DataViewFunctions = require("../dataViewFunctions");
const Dropdown = require("../general/dropdown");
const PageFunctions = require("../pageFunctions");
const Sentry = require("@sentry/browser");
const FeatureFlag = require("../../settings/featureFlag");
const Tree = require("../../tree");
