"use strict";

const assetManagementWidgetDataCache = function () {
  var getWidgetDataArray = function () {
    const assetDataArray = [
      { assetName: "Structural BMPs", iconString: "bmps.svg", isLinear: false },
      { assetName: "Catch Basins", iconString: "catch_basin.svg", isLinear: false },
      { assetName: "Outfalls", iconString: "outfall.svg", isLinear: false },
      { assetName: "Manholes", iconString: "manhole.svg", isLinear: false },
      { assetName: "Cleanouts", iconString: "cleanout.png", isLinear: false },
      { assetName: "Lift Stations", iconString: "lift_station.svg", isLinear: false },
      { assetName: "Pipes", iconString: "linear_asset_pipe.a47a6e63.svg", isLinear: true },
      {
        assetName: "Open Channels",
        iconString: "linear_asset_open_channel.e7d2ef24.svg",
        isLinear: true,
      },
      { assetName: "Culverts", iconString: "linear_asset_culvert.12ee16fc.svg", isLinear: true },
    ];
    addPropsToDataArray(assetDataArray);
    return assetDataArray;
  };
  var addPropsToDataArray = function (dataArray) {
    const apiArray = Tree.get("assetManagementApiData");
    dataArray.forEach((asset, index) => {
      asset.attributionPercentage = apiArray[index]["attributionPercentage"];
      asset.totalCount = apiArray[index]["total"];
      asset.linearFt = apiArray[index]["totalLengthFt"];
      asset.unknownPercentage = apiArray[index]["unknownPercentage"];
      asset.veryLowPercentage = apiArray[index]["veryLowPercentage"];
      asset.lowPercentage = apiArray[index]["lowPercentage"];
      asset.moderatePercentage = apiArray[index]["moderatePercentage"];
      asset.highPercentage = apiArray[index]["highPercentage"];
      asset.veryHighPercentage = apiArray[index]["veryHighPercentage"];

      asset.unknownCofPercent = apiArray[index]["unknownCofPercentage"];
      asset.veryLowCofPercent = apiArray[index]["veryLowCofPercentage"];
      asset.lowCofPercent = apiArray[index]["lowCofPercentage"];
      asset.moderateCofPercent = apiArray[index]["moderateCofPercentage"];
      asset.highCofPercent = apiArray[index]["highCofPercentage"];
      asset.veryHighCofPercent = apiArray[index]["veryHighCofPercentage"];

      asset.unknownCriticalityPercent = apiArray[index]["unknownCriticalityPercentage"];
      asset.veryLowCriticalityPercent = apiArray[index]["veryLowCriticalityPercentage"];
      asset.lowCriticalityPercent = apiArray[index]["lowCriticalityPercentage"];
      asset.moderateCriticalityPercent = apiArray[index]["moderateCriticalityPercentage"];
      asset.highCriticalityPercent = apiArray[index]["highCriticalityPercentage"];
      asset.veryHighCriticalityPercent = apiArray[index]["veryHighCriticalityPercentage"];
    });
  };
  return {
    getWidgetDataArray,
  };
};
module.exports = assetManagementWidgetDataCache();

const Tree = require("../tree");
