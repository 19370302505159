"use strict";

/**
 * Note about DateTimePicker:
 * Values stored in the date picker are stored in Z format with milliseconds
 * Remember to truncate the milliseconds before you send it through the API
 * If you are using Form.js, this is automatically done for you
 */
const DateTimePicker = function () {
  var initializeDateTimePickers = function ($parent, onChangeCallback) {
    $parent.find(".date-picker").each(function (ind, elem) {
      const $element = $(elem);
      const includeTime = $element.hasClass("include-time");
      const use24Hours = $element.hasClass("use-24-hours");

      initialize($element, includeTime, use24Hours, onChangeCallback);
    });
  };

  var unloadDateTimePickerListeners = function ($parent) {
    $parent.find(".date-picker").each(function (ind, elem) {
      const $element = $(elem);
      const instance = _getInstanceByElement($element);

      if (instance) {
        instance.config.onChange.splice(0, instance.config.onChange.length);
      }
    });
  };

  var initialize = function ($element, includeTime = false, use24Hours = false, onChangeCallback) {
    var options = {
      altInput: true,
      altFormat: "m/d/Y",
      allowInput: true,
      dateFormat: "Z",
      time_24hr: use24Hours,
      enableTime: includeTime,
      onClose(dates, currentdatestring, picker) {
        picker.setDate(picker.altInput.value, true, picker.config.altFormat);
      },
    };

    if (includeTime) {
      if (use24Hours) {
        options.altFormat = "m/d/Y H:i";
      } else {
        options.altFormat = "m/d/Y h:iK";
      }
    }

    if ($element.length !== 0) {
      const instance = Flatpickr($element, options);
      if (onChangeCallback) {
        instance.config.onChange.push(onChangeCallback);
      }
    }
  };

  var _getInstanceByElement = function ($element) {
    if ($element === null) return null;
    const instance = $element[0];
    if (instance === undefined || instance === null) {
      return null;
    } else return instance._flatpickr;
  };

  var _getInstanceByInputName = function ($element, inputName) {
    if ($element === null || inputName === null) return null;
    const instance = $element.find(`[name='${inputName}']`)[0];
    if (instance === undefined) {
      return null;
    } else return instance._flatpickr;
  };

  var setDate = function ($parent, inputName, date) {
    const instance = inputName
      ? _getInstanceByInputName($parent, inputName)
      : _getInstanceByElement($parent);
    if (instance !== null) {
      instance.setDate(date);
      return DateTime.parseDateToIso(date);
    }
  };

  var unsetDate = function ($parent, inputName) {
    const instance = inputName
      ? _getInstanceByInputName($parent, inputName)
      : _getInstanceByElement($parent);
    if (instance !== null) {
      instance.setDate("");
    }
  };

  var setDateOnElement = function ($element, date) {
    const instance = _getInstanceByElement($element);
    if (instance === undefined) {
      console.error("Provided element isn't an initialized Flatpickr instance.");
      return;
    }
    instance.setDate(date);
  };

  var getDisplayDate = function ($parent, inputName) {
    const instance = inputName
      ? _getInstanceByInputName($parent, inputName)
      : _getInstanceByElement($parent);
    if (instance !== null) {
      return instance.altInput.value;
    }
  };

  var getIsoDate = function ($parent, inputName) {
    const date = getDate($parent, inputName);

    if (date) {
      return DateTime.truncateMilliseconds(Flatpickr.formatDate(date, "Z"));
    }
  };

  var getDate = function ($parent, inputName) {
    const instance = inputName
      ? _getInstanceByInputName($parent, inputName)
      : _getInstanceByElement($parent);
    if (instance !== null && instance.selectedDates[0] !== undefined) {
      return instance.selectedDates[0];
    }
  };

  var deleteInstancesBySelector = function (selector) {
    $(selector).each(function (index, instance) {
      instance._flatpickr.destroy();
    });
  };

  var deleteAllInstances = function () {
    $(".flatpickr-input").each(function (index, instance) {
      instance._flatpickr.destroy();
    });
  };

  var setToNow = function ($parent, inputName) {
    const nowDate = DateTime.getTodayIso();
    const instance = inputName
      ? _getInstanceByInputName($parent, inputName)
      : _getInstanceByElement($parent);
    instance.setDate(nowDate);
    return nowDate;
  };

  var formatDateTime = function (date, format) {
    return Flatpickr.formatDate(date, format);
  };

  var isDatePicker = function ($parent) {
    return $parent.hasClass("date-picker");
  };

  var getDateTimeInput = function ($parent) {
    const isoString = $parent.val();
    if (isoString !== undefined) {
      return DateTime.truncateMilliseconds(isoString);
    }
  };

  var setDatePickerRange = function ($page, inputName, isMaxDate, date) {
    const datePicker = _getInstanceByInputName($page, inputName);

    if (!datePicker) {
      return;
    }

    datePicker.set(isMaxDate ? "maxDate" : "minDate", date);
  };

  var getDatePickerConfig = function ($page, inputName) {
    const datePicker = _getInstanceByInputName($page, inputName);

    if (!datePicker) {
      return;
    }

    return datePicker.config;
  };

  var toggleDisabled = function ($page, inputName, disabled) {
    const datePicker = _getInstanceByInputName($page, inputName);

    if (!datePicker) {
      return;
    }

    if (disabled) {
      datePicker._input.setAttribute("disabled", "disabled");
    } else {
      datePicker._input.removeAttribute("disabled");
    }
  };

  var isDisabled = function ($page, inputName, disabled) {
    const datePicker = _getInstanceByInputName($page, inputName);

    if (!datePicker) {
      return;
    }

    return datePicker._input.getAttribute("disabled") === "disabled";
  };

  const getStartOfDayGroupIsoString = function ($datePicker) {
    let date = $datePicker?.data("DateTimePicker")?.date()?.toDate() ?? "";

    if (date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      date = DateTime.getLocalAtGroupTimeZone(date);
      date = DateTime.getIsoString(date);
    }

    return date;
  };

  return {
    _getInstanceByElement,
    _getInstanceByInputName,
    deleteAllInstances,
    deleteInstancesBySelector,
    formatDateTime,
    getDatePickerConfig,
    getDateTimeInput,
    getDisplayDate,
    getIsoDate,
    getDate,
    initialize,
    initializeDateTimePickers,
    isDatePicker,
    isDisabled,
    setDate,
    setDateOnElement,
    setDatePickerRange,
    setToNow,
    toggleDisabled,
    unloadDateTimePickerListeners,
    unsetDate,
    getStartOfDayGroupIsoString,
  };
};

module.exports = DateTimePicker();

const Flatpickr = require("flatpickr");
const DateTime = require("../dateTime");
