"use strict";

const LanduseEsriDynamicMapLayer = function () {
  const layerName = "landuseEsriDynamicMap";
  const layerKey = "landuseEsriDynamicMapLayer";
  const esriKey = "soilImpervious";
  const esriLayer = "landuse";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var landuseEsriDynamicMapLayer = new EsriDynamicMapLayer(
      layerName,
      layerKey,
      esriKey,
      esriLayer,
    );
    landuseEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    landuseEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = LanduseEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
