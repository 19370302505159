"use strict";

const ProjectInventoryPriority = function () {
  const stringKey = "project-priority";
  const headerInformation = "Priority";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
  };

  var render = function () {
    const project = InventoryModal.getAllData("project-inventory");
    updateAreaProps(project);
    checkPriorityRadio(project);
    renderPriorityHtml(project);
    loadListeners();
  };

  var updateAreaProps = function (props) {
    if (props?.priorityInfo?.areaOfDisturbance) {
      const areaUnits = ProjectInventoryConstants.areaUnits;
      const displayAreaOfDisturbanceUnit = areaUnits.find(
        (unit) => unit.value === props.priorityInfo.areaOfDisturbanceUnits,
      ).name;
      props.priorityInfo.displayAreaOfDisturbance = `${props.priorityInfo.areaOfDisturbance} ${displayAreaOfDisturbanceUnit}`;
    }

    if (props?.priorityInfo?.proximityTo303dReceivingWater) {
      const lengthUnits = ProjectInventoryConstants.lengthUnits;
      const displayProximityTo303dReceivingWaterUnit = lengthUnits.find(
        (unit) => unit.value === props.priorityInfo.proximityTo303dReceivingWaterUnits,
      ).name;
      props.priorityInfo.displayProximityTo303dReceivingWater = `${props.priorityInfo.proximityTo303dReceivingWater} ${displayProximityTo303dReceivingWaterUnit}`;
    }
  };

  var checkPriorityRadio = function (projectToRender) {
    if (projectToRender.highPriority === undefined) {
      projectToRender.highPriority = false;
    }
  };

  var renderPriorityHtml = function (props) {
    const html = nunjucks.render("modals/projectInventory/projectInventoryPriority.njk", props);
    ProjectInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  var cleanUp = function () {
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
  };
};

module.exports = ProjectInventoryPriority();

const ProjectInventoryConstants = require("./projectInventoryConstants");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const InventoryModal = require("../general/inventoryModal");
const Form = require("../general/form");
