"use strict";

const ApiCalls = function () {
  var validateToken = function (token) {
    return Network.api("post", "/validate", { token });
  };

  var authenticate = function (username, password) {
    return $.post(Config.get().apiUrl + "/authenticate", {
      username: encodeURIComponent(username),
      password: encodeURIComponent(password),
      site: Config.get().site,
    });
  };

  var user = function (params) {
    return Network.api("post", "/user", params, Network.noLoadingScreen());
  };

  var revokeToken = function (params) {
    return Network.api("post", "/revoke", params, Network.noLoadingScreen());
  };

  var password = function (params) {
    return Network.api("post", "/password", { ...params, site: Config.get().site });
  };

  var getPossibleUserRoles = function () {
    return Network.asPromise(Network.api("get", "/user/possible-roles").fail(error));
  };

  var getCatchments = function (callback) {
    const groupId = Session.getGroupIdToPass();
    const partner = 0;
    const plu = 1;
    const year = Tree.get("waterYear");

    return Network.api("get", "/ms4s/" + groupId + "/catchments", {
      groupId,
      plu,
      partner,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getWatersheds = function (bbox, callback) {
    return Network.api("get", "/receiving-waters", {
      bbox: bbox,
    })
      .done(callback)
      .fail(error);
  };

  function error(xhr, textStatus, errorThrown) {
    console.warn("API ERROR", xhr, textStatus, errorThrown);
  }

  var sendPassword = function (email, callback, error) {
    Network.api("post", "/password/email", { email: email, site: Config.get().site })
      .done(callback)
      .fail(error);
  };

  var createNewUser = function (
    email,
    username,
    role,
    tools,
    affiliation,
    newAffiliation,
    inspectionPermissions,
    callback,
    error,
  ) {
    return Network.asPromise(
      Network.api("post", "/users", {
        email,
        username,
        role,
        tools,
        affiliation,
        newAffiliation,
        inspectionPermissions,
      })
        .done(callback)
        .fail(error),
    );
  };

  var activateUser = function (code, fullName, password, callback, error) {
    var xhr = Network.api("post", "/activate-user/" + code, {
      fullName: fullName,
      code: code,
      password: encodeURIComponent(password),
    });
    xhr.done(callback);
    xhr.fail(error);
  };

  var queryActivationCode = function (code, callback) {
    var xhr = Network.api("post", "/activation", { code });
    xhr.done(callback);
    xhr.fail(error);
  };

  var newPassword = function (code, password, callback, error) {
    var xhr = Network.api("post", "/password/reset/" + code, {
      password: encodeURIComponent(password),
      site: Config.get().site,
    });
    xhr.done(callback);
    xhr.fail(error);
  };

  var getUsers = function (showStaff = false) {
    return Network.asPromise(Network.api("get", `/users`, { showStaff }).fail(error));
  };

  var getGroupUsers = function (callback) {
    Network.api("get", "/users").done(callback).fail(error);
  };

  var getExistingUserData = function (userId) {
    return Network.asPromise(Network.api("get", `/user`, { userId }).fail(error));
  };

  var updateUsername = function (email, username, fullname, callback, error) {
    var user = Session.getUser();
    var originalUsername = user.username;
    Network.api("post", "/users/" + originalUsername, {
      email: email,
      username: username,
      fullname: fullname,
      userId: user.userId,
    })
      .done(callback)
      .fail(error);
  };

  var updateExistingUser = function (
    userId,
    email,
    role,
    tools,
    affiliation,
    newAffiliation,
    inspectionPermissions,
    callback,
    error,
  ) {
    return Network.asPromise(
      Network.api("put", "/users", {
        email: encodeURIComponent(email),
        role: role,
        tools: tools,
        affiliation: affiliation,
        newAffiliation: newAffiliation,
        inspectionPermissions: inspectionPermissions,
        userId: userId,
      })
        .done(callback)
        .fail(error),
    );
  };

  var resendInvite = function (user, callback, errorCallback) {
    Network.api("post", "/resend-invite", { user: encodeURIComponent(user) })
      .done(callback)
      .fail(errorCallback);
  };

  var checkDeleteUser = function (userId, callback) {
    return Network.asPromise(
      Network.api("get", "/users/" + userId)
        .done(callback)
        .fail(error),
    );
  };

  var deleteUser = function (userId, callback) {
    return Network.asPromise(
      Network.api("delete", "/users/" + userId)
        .done(callback)
        .fail(error),
    );
  };

  var getBoundary = function (group_id, callback) {
    if (Tree.get("tool") === "esg") return callback([]);

    const partner = 0;

    return Network.api("get", "/ms4s/" + group_id + "/boundary", {
      partner,
    })
      .done(callback)
      .fail(error);
  };

  //get single BMP info
  var getBmp = function (bmpName, groupId, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/bmpram-bmp", {
      bmpId: bmpName,
      groupId: groupId,
    })
      .done(callback)
      .fail(error);
  };

  var getAllBmps = function (
    year,
    filters,
    filtersOn,
    onlyAccepted,
    callback,
    loadingScreen = true,
  ) {
    const groupId = Session.getGroupIdToPass();
    const partner = 0;

    return Network.api(
      "post",
      "/ms4s/" + groupId + "/bmpram-points",

      {
        year: year,
        filters: filters,
        partner: partner,
        onlyAccepted: onlyAccepted,
        bmp: Tree.get("tool") == "bmpram" ? 1 : 0,
        fcs: 0,
        filtersOn: filtersOn,
      },
      Network.noLoadingScreenWhenFalse(loadingScreen),
    )
      .done(callback)
      .fail(error);
  };

  var getFcss = function (year, filters, filtersOn, callback, loadingScreen = true) {
    const noFiltering = true;
    var groupId = Session.getGroupIdToPass();
    var onlyAccepted = Session.isRegulator();
    const validFilters = filters ? filters : Tree.get("filters");
    checkspatialboxes(validFilters);
    const partner = 0;

    return Network.api(
      "post",
      "/ms4s/" + groupId + "/bmpram-points",

      {
        year: year,
        filters: filters,
        fcs: 1,
        onlyAccepted: onlyAccepted,
        filtersOn: filtersOn,
        partner: partner,
        noFiltering,
      },
      Network.noLoadingScreenWhenFalse(loadingScreen),
    )
      .done(callback)
      .fail(error);
  };

  var checkspatialboxes = function (filters) {
    if (areAllCheckboxesChecked("drainsToRw")) {
      filters.drainsToRw = [];
    }
    if (areAllCheckboxesChecked("drainsToC")) {
      filters.drainsToC = [];
    }
    if (areAllCheckboxesChecked("catchmentName")) {
      filters.catchmentName = [];
    }
    if (areAllCheckboxesChecked("drainsToSpatialGroupingId")) {
      filters.drainsToSpatialGroupingId = [];
    }
    if (areAllCheckboxesChecked("drainsToAssesmentArea")) {
      filters.drainsToAssesmentArea = [];
    }

    return filters;
  };

  var getSingleFcs = function (groupId, idBmp, callback) {
    groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/single-fcs", {
      idbmp: idBmp,
    })
      .done(callback)
      .fail(error);
  };

  var getBmpFcs = function (idbmp) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/get-bmp-fcs`, {
        idbmp,
      }).fail(error),
    );
  };

  var getMuniBmpPopup = function (idbmp) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-bmp-popup`, {
        idbmp,
      }).fail(error),
    );
  };

  var getMuniCatchBasinPopup = function (idbmp) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-catch-basin-popup`, {
        idbmp,
      }).fail(error),
    );
  };

  var getMuniManholePopup = function (id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-manhole-popup`, {
        id,
      }).fail(error),
    );
  };

  var getMuniCleanoutPopup = function (id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-cleanout-popup`, {
        id,
      }).fail(error),
    );
  };

  var getMuniCulverts = function () {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/muni-culverts`).fail(error));
  };

  var getMuniCulvertPopup = function (id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-culvert-popup`, {
        id,
      }).fail(error),
    );
  };

  var getMuniOpenChannels = function () {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/muni-open-channels`).fail(error));
  };

  var getMuniOpenChannelPopup = function (id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-open-channel-popup`, {
        id,
      }).fail(error),
    );
  };

  var deleteOpenChannel = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/muni-open-channel`, { id }));
  };

  var createBmpFcs = function (bmpData, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/create-bmp-fcs`, bmpData)
        .done(callback)
        .fail(error),
    );
  };

  var getMuniLiftStationsPopup = function (id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-lift-station-popup`, {
        id,
      }).fail(error),
    );
  };

  var getMuniPipes = function () {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/muni-pipes`).fail(error));
  };

  var getMuniPipesPopup = function (id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-pipes-popup`, {
        id,
      }).fail(error),
    );
  };

  var getAttributionPercentage = function (filters, { loadingScreen = true } = {}) {
    const groupId = Session.getGroupIdToPass();
    checkspatialboxes(filters);
    return Network.asPromise(
      Network.api(
        "get",
        `/ms4s/${groupId}/get-attribution-percentage`,
        { filters },
        Network.noLoadingScreenWhenFalse(loadingScreen),
      ).fail(error),
    );
  };

  var createNewIncident = function (incidentData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/create-incident`, incidentData),
    );
  };

  var deleteIncident = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/incident`, { id }));
  };

  var getIncidents = function (filters) {
    const groupId = Session.getGroupIdToPass();
    filters = [];
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/incidents`, { filters }));
  };

  var updateIncident = function (incidentUpdates) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-incident`, incidentUpdates),
    );
  };

  var updateBmpFcs = function (bmpUpdates, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-bmp-fcs`, bmpUpdates)
        .done(callback)
        .fail(error),
    );
  };

  var getRequiredInventoryFields = function (idBmp, typeNumber, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/required-inventory", {
      idbmp: idBmp,
      typeNumber: typeNumber,
    })
      .done(callback)
      .fail(error);
  };

  var getSingleFcsFilenames = function (groupId, idBmp, callback) {
    groupId = Session.getGroupIdToPass();
    return Network.api(
      "get",
      "/ms4s/" + groupId + "/single-fcs-filenames",
      {
        idbmp: idBmp,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var getLidBmpPopup = function (idbmp) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/lid-bmp-popup", {
        idbmp: idbmp,
      }),
    );
  };

  var getBmpFcsAssessmentHistory = function (groupId, idBmp, onlyAccepted, callback) {
    groupId = Session.getGroupIdToPass();
    return Network.api(
      "get",
      "/ms4s/" + groupId + "/bmp-fcs-history",
      {
        idbmp: idBmp,
        onlyAccepted: onlyAccepted,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var getBmpPopup = function (idbmp) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/bmp-popup", {
        idbmp,
      }),
    );
  };

  var getFcsPopup = function (idbmp) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/fcs-popup", {
        idbmp,
      }),
    );
  };

  var getScenarioBmpPopup = function (idbmp) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/scenario-bmp-popup", {
        idbmp,
      }),
    );
  };

  var getSingleFcsAssessment = function (groupId, idBmp, obs_id, callback) {
    groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/single-fcs-assessment", {
      idbmp: idBmp,
      obs_id: obs_id,
    })
      .done(callback)
      .fail(error);
  };

  var getLastFcsAssessment = function (idBmp, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/last-fcs-assessment", {
      idbmp: idBmp,
    })
      .done(callback)
      .fail(error);
  };

  var getLanduse = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/landuse", {
        filters: filters,
      }),
    );
  };

  var getMaintenanceZones = function (filters) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/maintenance-zones", {
        filters: filters,
      }),
    );
  };

  var getHighPriorityArea = function (filters) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/high-priority-area`));
  };

  var getAllParcels = function (year, filters, spatialView, callback) {
    var groupId = Session.getGroupIdToPass();
    var partner = 0;

    var xhr1 = Network.api("get", "/ms4s/" + groupId + "/parcel-polygons", {
      groupId: groupId,
      partner: partner,
      filters: filters,
      spatialView: spatialView,
    });
    var xhr2 = Network.api("get", "/ms4s/" + groupId + "/conditions", {
      year: year,
      onlyAccepted: 0,
      partner: partner,
    });
    return $.when(xhr1, xhr2)
      .done(function (resp1, resp2) {
        callback(resp1[0], resp2[0]);
      })
      .fail(function (xhr, status, error) {
        console.warn(error);
      });
  };

  var getFlowRouting = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/flow-routing", { filters })
      .done(callback)
      .fail(error);
  };

  var createNewFacility = function (facilityData) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/create-facility`, facilityData),
    );
  };

  var updateFacility = function (facilityUpdates) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-facility`, facilityUpdates),
    );
  };

  var createNewOutfall = function (outfallData) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/create-outfall`, outfallData),
    );
  };

  var getOutfalls = function (filters) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/outfalls`, { filters }));
  };

  var getManholes = function (filters) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/manholes`, { filters }));
  };

  var createOrUpdateManhole = function (assetId, updates) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/manholes`, { id: assetId, updates }),
    );
  };

  var getMonitoringLocations = function (filters) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/monitoring-locations`, {
        filters,
      }),
    );
  };

  var getDryWeatherOutfalls = function (filters) {
    const groupId = Session.getGroupIdToPass();
    const assetTypes = ["outfall"];

    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/dry-weather-map`, {
        filters,
        assetTypes,
      }),
    );
  };

  var getDryWeatherManholes = function (filters) {
    const groupId = Session.getGroupIdToPass();
    const assetTypes = ["manhole"];

    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/dry-weather-map`, {
        filters,
        assetTypes,
      }),
    );
  };

  var getDryWeatherCatchBasins = function (filters) {
    const groupId = Session.getGroupIdToPass();
    const assetTypes = ["bmpFcs"];

    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/dry-weather-map`, {
        filters,
        assetTypes,
      }),
    );
  };

  var getIddeDryWeatherPopup = function (id, assetType) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/dry-weather-popup`, { id, assetType }),
    );
  };

  var getIddeDryWeatherAsset = function (id, assetType) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/dry-weather`, { id, assetType }),
    );
  };

  var getManholeInspection = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/manholes/inspection`, { id }));
  };

  var deleteManholeInspection = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/manholes/inspection`, { id }));
  };

  var getOutfallForFactSheet = function (outfallId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/outfall-fact-sheet`, { outfallId }),
    );
  };

  var getStormdrains = function (filters, callback, partnerid) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/stormdrains", {
      partner: partnerid,
      filters: filters,
    })
      .done(callback)
      .fail(error);
  };

  /**********************Trash Calls***********************/
  var getTrashPoints = function (groupId, filters, onlyAccepted, callback) {
    groupId = Session.getGroupIdToPass();
    checkspatialboxes(filters);
    return Network.api("get", "/ms4s/" + groupId + "/collector-data", {
      filters: filters,
      onlyAccepted: onlyAccepted,
    })
      .done(callback)
      .fail(error);
  };

  /* ***************** Photos **************** */

  var getPhotoList = function (groupId, id, layer, callback) {
    return Network.api(
      "get",
      "/ms4s/" + groupId + "/" + id + "/photos",
      {
        layerName: layer,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var uploadPhotos = function (groupId, id, formData, layer, progressCallback, callback) {
    return Network.apiPostWithFormData(
      "/ms4s/" + groupId + "/" + id + "/" + layer + "/photos",
      formData,
      progressCallback,
    )
      .done(callback)
      .fail(error);
  };

  var saveFactSheetPhotoCaption = function (
    layerName,
    layerId,
    photoId,
    caption,
    photoName,
    callback,
  ) {
    var groupId = Session.getGroupIdToPass();
    Network.api("post", "/ms4s/" + groupId + "/update-fact-sheet-caption", {
      layerName: layerName,
      layerId: layerId,
      photoId: photoId,
      caption: caption,
      photoName: photoName,
    })
      .done(callback)
      .fail(error);
  };

  var savePhotoCaption = function (groupId, id, filename, caption, layerName, callback) {
    Network.api("patch", "/ms4s/" + groupId + "/caption/" + id + "/photos", {
      filename: filename,
      caption: caption,
      layerName: layerName,
    })
      .done(callback)
      .fail(error);
  };

  var deletePhoto = function (layerName, id, filename, groupId, callback) {
    Network.api("delete", "/ms4s/" + groupId + "/" + layerName + "/" + id + "/photos/" + filename)
      .done(callback)
      .fail(error);
  };

  var getLidProjectById = function (projectId) {
    var groupId = Session.getGroupIdToPass();
    var params = {
      onlyCompleted: Session.isRegulator() ? 1 : 0,
      id: projectId,
    };
    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/lid-project", params));
  };

  var getLidProjects = function (filters) {
    var groupId = Session.getGroupIdToPass();
    var params = {
      onlyCompleted: Session.isRegulator() ? 1 : 0,
      filters: filters,
    };
    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/lid-projects", params));
  };

  var getAllLidBmps = function (year, filters, filtersOn) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api(
        "post",
        "/ms4s/" + groupId + "/lid-bmps",

        {
          year: year,
          filters: filters,
        },
      ),
    );
  };

  var getLidProjectAreas = function (filters, callback) {
    var groupId = Session.getGroupIdToPass();
    var params = {
      onlyCompleted: Session.isRegulator() ? 1 : 0,
      filters: filters,
    };
    return Network.api("get", "/ms4s/" + groupId + "/lid-project-areas", params)
      .done(callback)
      .fail(error);
  };

  var getAssociatedLidProjectAreas = function (filters, callback) {
    var groupId = Session.getGroupIdToPass();
    var params = {
      onlyCompleted: Session.isRegulator() ? 1 : 0,
      filters: filters,
    };
    return Network.api("get", "/ms4s/" + groupId + "/associated-lid-project-areas", params)
      .done(callback)
      .fail(error);
  };

  var getConstructionProjectAreas = function (filters, callback) {
    var groupId = Session.getGroupIdToPass();
    var params = {
      onlyCompleted: Session.isRegulator() ? 1 : 0,
      filters: filters,
    };
    return Network.api("get", "/ms4s/" + groupId + "/construction-project-areas", params)
      .done(callback)
      .fail(error);
  };

  var getMapDrainageLayer = function (
    layerName,
    groupId,
    filters,
    filtersOn,
    onlyAccepted,
    callback,
  ) {
    return Network.api(
      "post",
      "/ms4s/" + groupId + "/priority-drainage-layer",
      {
        onlyAccepted: onlyAccepted,
        layerName: layerName,
        filters: filters,
        filtersOn: filtersOn,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var getPriorityArea = function (groupId, year, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/priority-area", {
      year: year,
    })
      .done(callback)
      .fail(error);
  };

  var getUrbanDrainageStatus = function (groupId, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/urban-drainage-status")
      .done(callback)
      .fail(error);
  };

  var getMarkerData = function (lat, lng, callback) {
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.api("get", "/ms4s/" + groupId + "/bmpram-location", {
      lat: lat,
      long: lng,
    })
      .done(callback)
      .fail(error);
  };

  var checkUniqueIncidentName = function (groupId, incidentName, callback) {
    return Network.asPromise(
      Network.api(
        "get",
        "/ms4s/" + groupId + "/unique-incident-name",
        {
          incident_name: incidentName,
        },
        Network.noLoadingScreen(),
      )
        .done(callback)
        .fail(error),
    );
  };

  var checkUniqueOutfallName = function (groupId, outfallName, callback) {
    return Network.api(
      "get",
      "/ms4s/" + groupId + "/unique-outfall-name",
      {
        outfall_name: outfallName,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var checkUniqueFacilityIdentifier = function (groupId, facilityIdentifier, callback) {
    return Network.api(
      "get",
      "/ms4s/" + groupId + "/unique-facility-identifier",
      {
        facility_identifier: facilityIdentifier,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var checkUniqueBmpName = function (groupId, bmpName, callback) {
    return Network.asPromise(
      Network.api(
        "get",
        "/ms4s/" + groupId + "/unique-id",
        {
          bmp_id: bmpName,
        },
        Network.noLoadingScreen(),
      )
        .done(callback)
        .fail(error),
    );
  };

  var downloadTrashAttachment = function (idBmp, name, attachmentAssetType, directory) {
    const groupId = Tree.get("activeGroup", "groupId");
    const urlPath =
      "/ms4s/" +
      groupId +
      "/attachments/" +
      idBmp +
      "/download-trash-attachments/" +
      name +
      "/" +
      directory;

    return Downloader.fromApi("post", urlPath, { filePath: attachmentAssetType });
  };

  var formFileUpload = function (formData, folderName, callback) {
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.apiPostWithFormData(
      "/ms4s/" + groupId + "/form-file-upload/" + folderName,
      formData,
    ).done(callback);
  };

  var formFileDelete = function (formData, folderName, callback) {
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.apiPostWithFormData(
      "/ms4s/" + groupId + "/form-file-delete/" + folderName,
      formData,
    ).done(callback);
  };

  var saveNewFcsObs = function (formData, callback) {
    renameBmpIdsOnFormData(formData);
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.api("post", "/ms4s/" + groupId + "/new-fcs-observation", {
      formData: formData,
    })
      .done(callback)
      .fail(error);
  };

  var updateFcsObs = function (formData, callback) {
    renameBmpIdsOnFormData(formData);
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.api("post", "/ms4s/" + groupId + "/update-fcs-observation", {
      formData: formData,
    })
      .done(callback)
      .fail(error);
  };

  var deleteFcsAssessment = function (obs_id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/delete-fcs-observation", {
        obs_id,
      }),
    );
  };

  var getFcsScore = function (fcsData, callback) {
    var groupId = Tree.get("activeGroup", "groupId");
    renameBmpIdsOnFormData(fcsData);

    return Network.api("get", "/ms4s/" + groupId + "/fcs-score", {
      data: fcsData,
    })
      .done(callback)
      .fail(error);
  };

  var saveFcsScore = function (group_id, obs_id, score, callback) {
    return Network.api("post", "/ms4s/" + group_id + "/fcs-savescore", {
      obs_id: obs_id,
      score: score,
    })
      .done(callback)
      .fail(error);
  };

  //delete bmp
  var deleteBmp = function (idBmp) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/delete-bmp", {
        idBmp,
      }),
    );
  };

  var deleteFcs = function (idBmp, groupId, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/remove-fcs-status", {
      idbmp: idBmp,
    })
      .done(callback)
      .fail(error);
  };

  var getAnnualReports = function (dataName) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/annual-reports", {
        dataName,
      }),
    );
  };

  var getAnnualReporting = function (callback) {
    const groupId = Session.getGroupIdToPass();
    const onlyAccepted = Session.isRegulator();

    const callbackInternal = (data) => {
      data["trackReportingFiles"] = data["trackReporting"];
      data["fileId"] = `${groupId}${data.year}`;
      callback(data);
    };
    return Network.api("get", "/ms4s/" + groupId + "/annual-reporting", {
      module: "trash",
      onlyAccepted: onlyAccepted,
      includePostSubmittalBuffer: false,
    })
      .done(callbackInternal)
      .fail(error);
  };

  var updateAnnualReporting = function (formData, groupId, waterYear, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/annual-reporting", {
      formData: formData,
      waterYear: waterYear,
      module: "trash",
    })
      .done(callback)
      .fail(error);
  };

  var getContacts = function (groupId, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/contacts", {})
      .done(callback)
      .fail(error);
  };

  var createNewContact = function (groupId, formData, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/add-contact", {
      formData: formData,
    })
      .done(callback)
      .fail(error);
  };

  var updateContact = function (groupId, contactId, formData, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/update-contact", {
      contactId: contactId,
      formData: formData,
    })
      .done(callback)
      .fail(error);
  };

  var deleteContact = function (groupId, contactId, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/delete-contact", {
      contactId: contactId,
    })
      .done(callback)
      .fail(error);
  };

  var getMilestones = function (groupId, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/milestones", {
      // year: year
    })
      .done(callback)
      .fail(error);
  };

  var createNewMilestone = function (groupId, formData, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/add-milestone", {
      formData: formData,
    })
      .done(callback)
      .fail(error);
  };

  var updateMilestone = function (groupId, milestoneId, formData, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/update-milestone", {
      milestoneId: milestoneId,
      formData: formData,
    })
      .done(callback)
      .fail(error);
  };

  var deleteMilestone = function (groupId, milestoneId, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/delete-milestone", {
      milestoneId: milestoneId,
    })
      .done(callback)
      .fail(error);
  };

  var rotateFactSheetPhoto = function (
    layerName,
    layerId,
    photoId,
    photoName,
    direction,
    callback,
  ) {
    const groupId = Tree.get("activeGroup", "groupId");
    Network.api(
      "post",
      "/ms4s/" + groupId + "/rotate-fact-sheet-photo",
      {
        layerName,
        layerId,
        photoId,
        direction,
        photoName,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var rotateImage = function (layerName, layerId, photoId, direction, callback) {
    const groupId = Tree.get("activeGroup", "groupId");
    Network.api(
      "post",
      "/ms4s/" + groupId + "/rotate-image",
      {
        layerName,
        layerId,
        photoId,
        direction,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var getAllMS4s = function (callback) {
    const groupId = Tree.get(["user", "trueGroupId"]);
    Network.api("get", "/regions/" + groupId + "/ms4s", {
      year: "ALL",
    })
      .done(callback)
      .fail(error);
  };

  var getTodos = function (dataView, filters, toDoFilters, loadingScreen = true) {
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.asPromise(
      Network.api(
        "get",
        "/ms4s/" + groupId + "/todos",
        {
          todoAssetType: dataView,
          filters: filters,
          toDoFilters: toDoFilters,
        },
        Network.noLoadingScreenWhenFalse(loadingScreen),
      ),
    );
  };

  var getProjectOptions = function (groupId) {
    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/project-options"));
  };

  var getTypeData = function (groupId, bmp_type, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/bmpram-bmp-type", { bmpType: bmp_type })
      .done(callback)
      .fail(error);
  };

  var saveNewBenchmark = function (formData, callback) {
    renameBmpIdsOnFormData(formData);
    var groupId = Tree.get("activeGroup", "groupId");

    return Network.api("post", "/ms4s/" + groupId + "/new-benchmark", {
      formData: formData,
    })
      .done(callback)
      .fail(error);
  };

  // get benchmark data for bmp
  var getBenchmark = function (idBmp, groupId, benchmarkId, observationId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/bmp-benchmark", {
      idBmp: idBmp,
      bm_id: benchmarkId,
      obs_id: observationId,
    })
      .done(callback)
      .fail(error);
  };

  var getAdditionalInspectionPermissions = function () {
    return Network.asPromise(
      Network.api("get", `/user/additional-inspection-permissions`).fail(error),
    );
  };

  //retrieve active benchmark bm_id if exists
  var getActiveBenchmark = function (bmpName, groupId, callback) {
    Network.api("get", "/ms4s/" + groupId + "/bmp-active-benchmark", {
      bmpId: bmpName,
    })
      .done(callback)
      .fail(error);
  };

  var saveDepth = function (data, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/bmp-benchmark-savedepth", {
      depthData: data,
    })
      .done(callback)
      .fail(error);
  };

  var saveBenchmarkDepths = function (data, bm_id, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/save-benchmark-depths", {
      depthRecords: data,
      bm_id: bm_id,
    })
      .done(callback)
      .fail(error);
  };

  var deleteDepth = function (depthId, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/delete-depth", {
      depth_id: depthId,
    })
      .done(callback)
      .fail(error);
  };

  var deleteBenchmarkDepths = function (data, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/delete-benchmark-depths", {
      depthIds: data,
    })
      .done(callback)
      .fail(error);
  };

  // get benchmark_depth records for bmp
  var getDepthData = function (benchmarkId, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/bmp-benchmark-depth", {
      bm_id: benchmarkId,
    })
      .done(callback)
      .fail(error);
  };

  // update existing benchmark data for bmp
  var updateBenchmark = function (data, callback) {
    renameBmpIdsOnFormData(data);
    var groupId = Tree.get("activeGroup", "groupId");

    Network.api("post", "/ms4s/" + groupId + "/update-benchmark", {
      formData: data,
      groupId: groupId,
    })
      .done(callback)
      .fail(error);
  };

  var deleteBenchmark = function (bm_id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/delete-benchmark", {
        bm_id,
      }),
    );
  };

  // save benchmark_inf data for bmp
  var saveInf = function (data, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/bmp-benchmark-saveinf", {
      infData: data,
    })
      .done(callback)
      .fail(error);
  };

  var saveBenchmarkInfs = function (data, bm_id, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/save-benchmark-infs", {
      infRecords: data,
      bm_id: bm_id,
    })
      .done(callback)
      .fail(error);
  };

  var deleteInf = function (inf_id, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/delete-inf", {
      inf_id: inf_id,
    })
      .done(callback)
      .fail(error);
  };

  var deleteBenchmarkInfs = function (data, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/delete-benchmark-infs", {
      infIds: data,
    })
      .done(callback)
      .fail(error);
  };

  // get benchmark_inf records for bmp
  var getInfData = function (benchmarkId, groupId, callback) {
    Network.api("post", "/ms4s/" + groupId + "/bmp-benchmark-inf", {
      bm_id: benchmarkId,
    })
      .done(callback)
      .fail(error);
  };

  var getConstructionProjects = function () {
    const onlyAccepted = false;
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/construction-projects", {
        onlyAccepted: onlyAccepted,
      }),
    );
  };

  var getConstructionProjectDeliveryProjects = function () {
    const onlyAccepted = false;
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/project-delivery-projects", {
        onlyAccepted: onlyAccepted,
      }),
    );
  };
  var getConstructionSpatialArrays = function () {
    const onlyAccepted = false;
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/construction-projects-spatial-arrays", {
        onlyAccepted: onlyAccepted,
      }),
    );
  };

  var getNewConstructionProjectInspection = function (constructionProjectId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/new-project-inspection", {
        constructionProjectId,
      }),
    );
  };

  var getExistingConstructionProjectInspection = function (projectInspectionId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/project-inspection", {
        projectInspectionId,
      }),
    );
  };

  var downloadConstructionProjectInspectionFile = function (fileId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/project-inspection-file`, {
      id: fileId,
    });
  };

  var createConstructionProjectInspection = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/construction-project-inspection`,
        data,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var updateConstructionProjectInspection = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/update-construction-project-inspection`,
        data,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var deleteConstructionProjectInspection = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("delete", `/ms4s/${groupId}/construction-project-inspection`, { id }),
    );
  };

  var downloadConstructionProjectInspectionPreview = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/construction-project-inspection-preview`,
        data,
        null,
        {
          xhrFields: { responseType: "arraybuffer" },
        },
      ),
    );
  };

  var getConstructionProjectHistory = function (constructionProjectId, callback) {
    var groupId = Session.getGroupIdToPass();
    return Network.api("get", `/ms4s/${groupId}/project-history`, { constructionProjectId })
      .done(callback)
      .fail(error);
  };

  var getBmpObservation = function (idBmp, obsId = null) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/bmp-observation", { idBmp, obsId }),
    );
  };

  var saveBmpObservation = function (
    observation,
    soil,
    inf,
    runoff,
    depthDataByBenchmarkDepthId,
    saveScore,
  ) {
    const groupId = Session.getGroupIdToPass();
    renameBmpIdsOnFormData(observation);

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/bmp-observation", {
        observation,
        soil,
        inf,
        runoff,
        depthDataByBenchmarkDepthId,
        saveScore,
      }),
    );
  };

  var deleteBmpObservation = function (obs_id) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/delete-bmp-observation", {
        obs_id,
      }),
    );
  };

  var calculateBmpScore = function (observation, soil, inf, runoff, depthDataByBenchmarkDepthId) {
    var groupId = Tree.get("activeGroup", "groupId");
    renameBmpIdsOnFormData(observation);

    return Network.api("get", "/ms4s/" + groupId + "/calculate-bmp-score", {
      observation,
      soil,
      inf,
      runoff,
      depthDataByBenchmarkDepthId,
    }).fail(error);
  };

  var bmpObservationPdfPreview = function (
    observation,
    soil,
    inf,
    runoff,
    depthDataByBenchmarkDepthId,
  ) {
    var groupId = Tree.get("activeGroup", "groupId");
    renameBmpIdsOnFormData(observation);

    return Network.asPromise(
      Network.api(
        "post",
        `/ms4s/${groupId}/bmp-observation-pdf-preview`,
        {
          observation,
          soil,
          inf,
          runoff,
          depthDataByBenchmarkDepthId,
          previewImages: true,
        },
        { xhrFields: { responseType: "arraybuffer" } },
      ),
    );
  };

  var downloadBmpObservationPdf = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/bmp-observation-pdf`, { id });
  };

  var downloadMuniBmpPdf = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/muni-bmp-pdf`, { id });
  };

  var previewMuniBmpPdf = function (formData) {
    var groupId = Tree.get("activeGroup", "groupId");
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/muni-bmp-pdf-preview`, formData, null, {
        xhrFields: { responseType: "arraybuffer" },
      }),
    );
  };

  var getBmpFcsInventoryRemaining = function (
    idBmp,
    isNew,
    isBmp,
    isFcs,
    fieldsReadyToInventory,
    fieldsAffectingRequiredFieldSet,
  ) {
    var groupId = Tree.get("activeGroup", "groupId");
    var requestData = {
      isNew: isNew,
      isBmp: isBmp,
      isFcs: isFcs,
      fieldsReadyToInventory: fieldsReadyToInventory,
      fieldsAffectingRequiredFieldSet: fieldsAffectingRequiredFieldSet,
    };
    if (idBmp) {
      requestData.idBmp = idBmp;
    }

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/bmp-fcs-fields-remaining", requestData).fail(error),
    );
  };

  var getTelrReadyMissingFields = function (idBmp, data) {
    var requestData = { idBmp, data };
    var groupId = Tree.get("activeGroup", "groupId");
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/not-telr-ready-reasons", requestData).fail(error),
    );
  };

  var runTelr = function (year) {
    var groupId = Tree.get("activeGroup", "groupId");
    const scenarioType = Tree.get("tool") == "scenario" ? "Potential" : "Mitigated";
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/run-telr`, {
        year: year,
        group_id: groupId,
        scenario_type: scenarioType,
      }),
    );
  };

  var getLastTelrRun = function () {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/last-telr-run", {}, Network.noLoadingScreen()),
    );
  };

  var renameBmpIdsOnFormData = function (formData) {
    if (formData instanceof FormData) {
      if (formData.has("idBmp")) {
        formData.append("idbmp", formData.get("idBmp"));
        formData.delete("idBmp");
      }
      if (formData.has("bmpName")) {
        formData.append("bmp_id", formData.get("bmpName"));
        formData.delete("bmpName");
      }
    } else {
      if (formData["idBmp"]) {
        formData["idbmp"] = formData["idBmp"];
        delete formData["idBmp"];
      }
      if (formData["bmpName"]) {
        formData["bmp_id"] = formData["bmpName"];
        delete formData["bmpName"];
      }
    }
  };

  var downloadPermitPlanFile = function (permitPlanId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/permits-plans`, {
      permitPlanId,
    });
  };

  var downloadFacilityFile = function (facilityId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/facility-files`, {
      facilityId,
    });
  };

  var downloadActivityFile = function (activityFileId, activityFileName) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/activity-files`, {
      activityFileId,
      activityFileName,
    });
  };

  var downloadLidSelfInspectionFile = function (id, action, filename) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/lid-self-inspection-file`, {
      id,
      action,
      filename,
    });
  };

  var downloadConstructionInspectionReport = function (inspectionId, enforcementActionId, type) {
    const groupId = Session.getGroupIdToPass();
    if (type === "installation-inspection") {
      return Downloader.fromApi("post", `/ms4s/${groupId}/installation-inspection-report`, {
        inspectionId,
      });
    } else {
      return Downloader.fromApi("post", `/ms4s/${groupId}/inspection-report`, {
        inspectionId,
        enforcementActionId,
      });
    }
  };

  var previewOutfallInspectionPdf = function (formData) {
    var groupId = Tree.get("activeGroup", "groupId");
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/outfall-inspection-pdf-preview`,
        formData,
        null,
        {
          xhrFields: { responseType: "arraybuffer" },
        },
      ),
    );
  };

  var downloadOutfallInspectionPdf = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/outfall-inspection-pdf`, { id });
  };

  var importBMPs = function (groupId, bmpData, callback) {
    var tool = Tree.get("tool");
    return Network.api("post", "/ms4s/" + groupId + "/bmp-import", { bmpData: bmpData, tool: tool })
      .done(callback)
      .fail(error);
  };

  var downloadConstructionInspectionReportsZip = function (enforcementActionId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/inspection-reports-zip`, {
      enforcementActionId,
    });
  };

  var getInitialEnforcementActionFields = function (inspectionId, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/initial-enforcement-action-fields", {
      inspectionId,
    })
      .done(callback)
      .fail(error);
  };

  var getEnforcementAction = function (enforcementActionId, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/enforcement-action", {
      enforcementActionId,
    })
      .done(callback)
      .fail(error);
  };

  var getPossibleInstallationInspectionTypes = function (bmpType) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/possible-installation-inspection-types", {
        bmpType: bmpType,
      }),
    );
  };

  var getPossibleFollowupInspectionsForEnforcementAction = function (
    constructionProjectId,
    dateActionIssued,
    excludedInspectionId,
    callback,
  ) {
    const groupId = Session.getGroupIdToPass();

    return Network.api("get", "/ms4s/" + groupId + "/possible-followup-inspections", {
      constructionProjectId,
      dateActionIssued,
      excludedInspectionId,
    })
      .done(callback)
      .fail(error);
  };

  var updateEnforcementAction = function (
    enforcementActionId,
    enforcementActionUpdates,
    projectInspectionId,
    { addNewEscalation = false, addEscalationAtSameLevel = false } = {},
  ) {
    if (enforcementActionId === null && projectInspectionId === null) {
      throw new Error(
        "Must provide enforcementActionId for existing enforcement action or \
        projectInspectionId for new enforcement action. Both were null.",
      );
    }

    const groupId = Session.getGroupIdToPass();

    const formData = Form.toFormData({
      enforcementActionUpdates,
      enforcementActionId,
      projectInspectionId,
      addNewEscalation,
      addEscalationAtSameLevel,
    });

    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/enforcement-action`, formData),
    );
  };

  var getFacility = function (facilityId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/facility`, { facilityId }));
  };

  var getFacilityForFactSheet = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/facility-fact-sheet`, { id }).fail(error),
    );
  };

  var setFactSheetProfilePhoto = function (layerName, layerId, photoId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "post",
        "/ms4s/" + groupId + "/fact-sheet-profile-photo",
        {
          layerName: layerName,
          layerId: layerId,
          photoId: photoId,
        },
        Network.noLoadingScreen(),
      ).fail(error),
    );
  };

  var signUserAgreement = function (callback) {
    const username = Tree.get("user", "username");
    const id = Tree.get("user", "userId");
    Network.api("post", "/users/" + username + "/sign-user-agreement", { id })
      .done(callback)
      .fail(error);
  };

  var signSaasAgreement = function (callback) {
    const groupId = Session.getGroupIdToPass();
    Network.api("post", `/ms4s/${groupId}/sign-saas-agreement`).done(callback).fail(error);
  };

  var uploadFactSheetPhoto = function (layerName, layerId, file, progressCallback, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.apiPostWithFormData(
      `/ms4s/${groupId}/upload-fact-sheet-photo`,
      {
        layerName: layerName,
        layerId: layerId,
        file: file,
      },
      progressCallback,
    )
      .done(callback)
      .fail(error);
  };

  var deleteFacilityFactSheet = function (facilityId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("delete", `/ms4s/${groupId}/delete-facility`, { facilityId }).fail(error),
    );
  };

  var deleteProjectFactSheet = function (constructionProjectId, deleteAssets = false) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("delete", `/ms4s/${groupId}/construction-project`, {
        constructionProjectId,
        deleteAssets,
      }).fail(error),
    );
  };

  var deleteFactSheetPhoto = function (layerName, layerId, photoId, photoName, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("delete", "/ms4s/" + groupId + "/delete-fact-sheet-photo", {
      layerName: layerName,
      layerId: layerId,
      photoId: photoId,
      photoName: photoName,
    })
      .done(callback)
      .fail(error);
  };

  var getConstructionProjectForFactSheet = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/construction-project-fact-sheet`, {
        constructionProjectId,
      }),
    );
  };

  var getLidProjectForFactSheet = function (lidProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/lid-project-fact-sheet`, {
        lidProjectId,
      }),
    );
  };

  var deleteConstructionProject = function (groupId, constructionProjectId, callback) {
    return Network.api("delete", "/ms4s/" + groupId + "/construction-project", {
      constructionProjectId,
    })
      .done(callback)
      .fail(error);
  };

  var setConstructionProjectProfilePhoto = function (constructionProjectId, photoId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "post",
        "/ms4s/" + groupId + "/construction-project-profile-photo",
        {
          constructionProjectId: constructionProjectId,
          photoId: photoId,
        },
        Network.noLoadingScreen(),
      ).fail(error),
    );
  };

  var uploadConstructionProjectPhoto = function (formData, progressCallback, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.apiPostWithFormData(
      "/ms4s/" + groupId + "/construction-project-photos",
      formData,
      progressCallback,
    )
      .done(callback)
      .fail(error);
  };

  var updateConstructionPhotoCaption = function (constructionPhotoId, caption, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("post", "/ms4s/" + groupId + "/update-construction-photo-caption", {
      constructionPhotoId,
      caption,
    })
      .done(callback)
      .fail(error);
  };

  var deleteConstructionProjectPhoto = function (groupId, constructionPhotoId, callback) {
    return Network.api("delete", "/ms4s/" + groupId + "/construction-project-photo", {
      constructionPhotoId: constructionPhotoId,
    })
      .done(callback)
      .fail(error);
  };

  var rotateConstructionPhoto = function (constructionPhotoId, direction, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api(
      "post",
      `/ms4s/${groupId}/rotate-construction-photo`,
      { constructionPhotoId, direction },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var saveNewParcelAssessment = function (uniquePolygonId, data, callback) {
    var groupIdToPass = Session.getGroupIdToPass();
    Network.api(
      "post",
      "/ms4s/" + groupIdToPass + "/parcels/" + uniquePolygonId + "/conditions",
      data,
    )
      .done(callback)
      .fail(error);
  };

  var updateParcelAssessment = function (assessmentId, data, callback) {
    Network.api("put", "/conditions/" + assessmentId, data)
      .done(callback)
      .fail(error);
  };

  var deleteParcelAssessment = function (id, callback) {
    Network.api("delete", "/conditions/" + id)
      .done(callback)
      .fail(error);
  };

  var saveLidSelfInspection = function (data) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/lid-self-inspection`, {
        ...data,
      }),
    );
  };

  var getLidSelfInspection = function (id, action) {
    var groupId = Session.getGroupIdToPass();
    return Network.api(
      "get",
      `/ms4s/${groupId}/lid-self-inspection`,
      {
        inspectionId: id,
        action: action,
      },
      Network.noLoadingScreen(),
    ).fail(error);
  };

  var deleteLidSelfInspection = function (inspectionId, action, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("delete", "/ms4s/" + groupId + "/lid-self-inspection", {
      inspectionId,
      action,
    })
      .done(callback)
      .fail(error);
  };

  var downloadLidSelfInspectionPdf = function (sendNoticeId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", "/ms4s/" + groupId + "/lid-self-inspection-pdf", {
      sendNoticeId,
    });
  };

  var downloadLidSummaryPdf = function (sendSummaryId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", "/ms4s/" + groupId + "/lid-summary-pdf", { sendSummaryId });
  };

  var downloadLidResponsePdf = function (receiveNoticeId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", "/ms4s/" + groupId + "/lid-response-pdf", {
      receiveNoticeId,
    });
  };

  var previewLidSelfInspectionPdf = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/lid-self-inspection-pdf-preview`, data, null, {
        xhrFields: { responseType: "arraybuffer" },
      }),
    );
  };

  var getOutfall = function (outfallId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/outfall`, { outfallId }));
  };

  var getIncident = function (incidentId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/incident`, { incidentId }));
  };

  var generateIncidentName = function (data, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/generate-incident-name`, data));
  };

  var getOutfallPopup = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/outfall-popup`, { id }));
  };

  var getIncidentPopup = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/incident-popup`, { id }));
  };

  var updateOutfall = function (outfallUpdates) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-outfall`, outfallUpdates),
    );
  };

  var deleteOutfallInspection = function (outfallInspectionId, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("delete", "/ms4s/" + groupId + "/outfall-inspection", {
      outfallInspectionId,
    })
      .done(callback)
      .fail(error);
  };

  var deleteOutfall = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/outfall`, { id }));
  };

  var deleteCleanout = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/muni-cleanout`, { id }));
  };

  var deleteCulvert = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/culvert`, { id }));
  };

  var deleteManhole = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/manholes`, { id }));
  };

  var deleteLiftStation = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/lift-station`, { id }));
  };

  var deletePipe = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("delete", `/ms4s/${groupId}/pipe`, { id }));
  };

  var downloadOutfallFile = function (outfallFileId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/outfall-file`, {
      id: outfallFileId,
    });
  };

  var downloadIncidentFile = function (incidentFileId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/incident-file`, {
      id: incidentFileId,
    });
  };
  var downloadResponderFile = function (enforcementActionId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/responder-file`, {
      id: enforcementActionId,
    });
  };

  var downloadOutfallInspectionFile = function (outfallFileId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/outfall-inspection-file`, {
      id: outfallFileId,
    });
  };

  var downloadBmpFcsFile = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/bmp-fcs-file`, {
      id,
    });
  };

  var downloadManholeFile = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/manholes/file`, {
      id,
    });
  };

  var getOutfallRoutineMaintenance = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/outfall-routine-maintenance`, {
        id,
      }),
    );
  };

  var createOutfallRoutineMaintenance = function (routineMaintenance) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/outfall-routine-maintenance`,
        routineMaintenance,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var updateOutfallRoutineMaintenance = function (routineMaintenance) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/update-outfall-routine-maintenance`,
        routineMaintenance,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var getIncidentInvestigation = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/incident-investigation`, {
        id,
      }),
    );
  };

  var previewIncidentInvestigationPdf = function (formData) {
    var groupId = Tree.get("activeGroup", "groupId");
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/incident-investigation-pdf-preview`,
        formData,
        null,
        {
          xhrFields: { responseType: "arraybuffer" },
        },
      ),
    );
  };

  var createIncidentInvestigation = function (investigation) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/create-incident-investigation`, investigation),
    );
  };

  var updateIncidentInvestigation = function (investigation) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-incident-investigation`, investigation),
    );
  };

  var downloadIncidentInvestigationPdf = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/incident-investigation-pdf`, { id });
  };

  var deleteIncidentInvestigation = function (id, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("delete", `/ms4s/${groupId}/incident-investigation`, { id })
      .done(callback)
      .fail(error);
  };

  var downloadIncidentInvestigationFile = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/incident-investigation-file`, {
      id,
    });
  };

  var createRoutineMaintenance = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/create-routine-maintenance`,
        formData,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var updateRoutineMaintenance = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/update-routine-maintenance`,
        formData,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var deleteMaintenance = function (maintenanceId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("delete", `/ms4s/${groupId}/delete-maintenance`, { maintenanceId }),
    );
  };

  var getRoutineMaintenance = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/get-maintenance`, { id }));
  };

  var getConstructionProjectIdentifierUnique = function (projectIdentifier) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/ms4s/${groupId}/construction-project-identifier-unique`,
        {
          projectIdentifier,
        },
        Network.noLoadingScreen(),
      ),
    );
  };

  var lidToDoClearFormalCommuncations = function (projectIds) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("delete", `/ms4s/${groupId}/lid-to-do-formal-communications`, {
      projectIds,
    });
  };

  var lidToDoClearResponseNeeded = function (projectIds) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("delete", `/ms4s/${groupId}/lid-to-do-response-needed`, {
      projectIds,
    });
  };

  var getConstructionProjectInventory = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/construction-project-inventory`, {
        constructionProjectId,
      }),
    );
  };

  var getConstructionProjectForLidAction = function (constructionProjectIds) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/construction-project-lid-action`, {
        constructionProjectIds,
      }),
    );
  };

  var getConstructionProjectForLidInspectionSummary = function (constructionProjectIds) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/lid-inspection-summary-project`, {
        constructionProjectIds,
      }),
    );
  };

  var getLidInspectionSummary = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/lid-inspection-summary`, {
        id,
      }),
    );
  };

  var deleteLidInspectionSummary = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("delete", `/ms4s/${groupId}/lid-inspection-summary`, {
        id,
      }),
    );
  };

  var generateLidInspectionSummaryLetter = function (data) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/lid-inspection-summary`, {
        ...data,
      }),
    );
  };

  var previewLidInspectionSummaryLetter = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/lid-inspection-summary-preview`, data, null, {
        xhrFields: { responseType: "arraybuffer" },
      }),
    );
  };

  var createConstructionProject = function (constructionProject, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.apiPostWithFormData(`/ms4s/${groupId}/construction-project`, constructionProject)
      .done(callback)
      .fail(error);
  };

  var updateConstructionProject = function (constructionProjectUpdates) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/update-construction-project`,
        constructionProjectUpdates,
      ),
    );
  };

  var updateConstructionProjectAsPromise = function (constructionProjectUpdates, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/update-construction-project`,
        constructionProjectUpdates,
      ),
    );
  };

  var getPossibleRunoffOffsetProjectsInReceivingWater = async function (
    receivingWaterName,
    ignoredProjectId,
  ) {
    const groupId = Session.getGroupIdToPass();
    const data = {
      receivingWaterName,
    };
    if (ignoredProjectId) {
      data.ignoredProjectId = ignoredProjectId;
    }
    return Network.asPromise(
      Network.api(
        "get",
        `/ms4s/${groupId}/possible-offset-construction-projects`,
        data,
        Network.noLoadingScreen(),
      ),
    );
  };

  var getParcelAssessmentHistory = function (uniquePolygonId, year, callback) {
    var onlyAccepted = Session.isRegulator() || 0;
    var groupIdToPass = Session.getGroupIdToPass();
    var partner = 0;

    Network.api(
      "get",
      "/ms4s/" + groupIdToPass + "/parcels/" + uniquePolygonId + "/history",
      {
        onlyAccepted,
        partner,
        year,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var getFacilitiesLayer = function (filters) {
    var groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/facilities", {
        filters,
      }),
    );
  };

  var getFacilityPopupData = function (id) {
    var groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/facility-popup", {
        id,
      }),
    );
  };

  var getNewFacilityInspection = function (facilityId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/new-facility-inspection", {
        facilityId,
      }),
    );
  };

  var getNewFacilitySelfInspection = function (facilityId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/new-facility-self-inspection", {
        facilityId,
      }),
    );
  };

  var getNewFacilityEnforcement = function (facilityId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/new-facility-enforcement", {
        facilityId,
      }).fail(error),
    );
  };

  var getFacilityEnforcement = function (enforcementId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/facility-enforcement", {
        enforcementId,
      }).fail(error),
    );
  };

  var issueLidEnforcement = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/new-lid-enforcement`, formData),
    );
  };

  var updateLidEnforcement = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-lid-enforcement`, formData),
    );
  };

  var getLidEnforcement = function (enforcementId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/lid-enforcement", {
        enforcementId,
      }).fail(error),
    );
  };

  var getExistingFacilityInspection = function (inspectionId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/facility-inspection", {
        inspectionId,
      }).fail(error),
    );
  };

  var createFacilityInspection = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/facility-inspection`,
        formData,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var getFacilitySelfInspection = function (selfInspectionId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/facility-self-inspection", {
        id: selfInspectionId,
      }),
    );
  };

  var createFacilitySelfInspection = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/facility-self-inspection`, formData),
    );
  };

  var previewFacilitySelfInspectionPdf = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/facility-self-inspection-pdf-preview`,
        formData,
        null,
        {
          xhrFields: { responseType: "arraybuffer" },
        },
      ),
    );
  };

  var downloadFacilitySelfInspectionFile = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/facility-self-inspection-file`, {
      id,
    });
  };

  var issueFacilityEnforcement = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/new-facility-enforcement`, formData),
    );
  };

  var updateFacilityInspection = function (formData) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.apiPostWithFormData(
        `/ms4s/${groupId}/update-facility-inspection`,
        formData,
        null,
        Network.noLoadingScreen(),
      ),
    );
  };

  var updateFacilityEnforcement = function (formData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-facility-enforcement`, formData),
    );
  };

  var downloadFacilityInspectionPreview = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/facility-inspection-preview`, data, null, {
        xhrFields: { responseType: "arraybuffer" },
      }),
    );
  };

  var downloadFacilityInspectionReport = function (inspectionId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/facility-inspection-report`, {
      inspectionId,
    });
  };

  var downloadFacilitySelfInspectionReport = function (inspectionId) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/facility-self-inspection-report`, {
      inspectionId,
    });
  };

  var downloadEnforcementLetterFile = function (uuid) {
    const groupId = Session.getGroupIdToPass();
    return Downloader.fromApi("post", `/ms4s/${groupId}/letter-file`, {
      uuid,
    });
  };

  var getNewLidEnforcement = function (id) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/new-lid-enforcement", {
        id,
      }).fail(error),
    );
  };

  var getMuniCatchBasinDrainages = function (filters) {
    var groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/muni-catch-basin-drainages", {
        filters,
      }),
    );
  };

  var getMuniSbmpsDrainages = function (filters) {
    var groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/muni-sbmp-drainages", {
        filters,
      }),
    );
  };

  var saveCatchBasinBulkClean = function (selectedIds, date) {
    var groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/catch-basin-bulk-clean", { selectedIds, date }),
    );
  };

  var saveRoadCondition = function (data) {
    var groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.apiPostWithFormData("/ms4s/" + groupId + "/road-condition", { updates: data }),
    );
  };

  var getCatchmentCentralizedBmps = function (catchmentId) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/catchment-cbmp`, { catchmentId }),
    );
  };

  var getCatchBasinForFactSheet = function (idbmp) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-catch-basin-fact-sheet`, { idbmp }),
    );
  };

  var validateCsvForImport = function (csvFile, fieldMapping, dataType) {
    var groupId = Session.getGroupIdToPass();
    if ($.isEmptyObject(fieldMapping)) fieldMapping = undefined;
    const formData = Form.toFormData({ csvFile, fieldMapping, dataType });

    return Network.asPromise(
      Network.apiPostWithFormData("/ms4s/" + groupId + "/validate-csv-import", formData),
    );
  };

  var importFromCsv = function (csvFile, fieldMapping, dataType) {
    var groupId = Session.getGroupIdToPass();
    if ($.isEmptyObject(fieldMapping)) fieldMapping = undefined;
    const formData = Form.toFormData({ csvFile, fieldMapping, dataType });

    return Network.asPromise(
      Network.apiPostWithFormData("/ms4s/" + groupId + "/import-from-csv", formData),
    );
  };

  var getRoads = function (filters) {
    var groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/roads", {
        filters,
      }),
    );
  };

  var getExportData = function (url, data) {
    return Network.asPromise(
      Network.api(
        "post",
        url,
        {
          token: TokenManager.loadToken(),
          experimentalFeaturesEnabled: FeatureFlag.enabled("export-data"),
          ...data,
        },
        { xhrFields: { responseType: "blob" }, ...Network.noLoadingScreen() },
      ),
    );
  };

  var getArcGisUrlData = function (url) {
    return $.ajax({
      url: url,
      type: "GET",
      data: { f: "geojson", where: "1=1", outFields: "*", returnGeometry: true },
    });
  };

  var validateUrlDataForImport = function (urlData, fieldMapping, dataType) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/validate-url-import`, {
        urlData,
        fieldMapping,
        dataType,
      }),
    );
  };

  var importValidatedUrl = function (url, fieldMapping, dataType) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/import-from-url`, { url, fieldMapping, dataType }),
    );
  };

  var getBulkUpdateData = function (dataType) {
    const groupId = Session.getGroupIdToPass();

    return Downloader.fromApi("post", `/ms4s/${groupId}/reimport-csv`, { dataType });
  };

  var calculateDrainageArea = function (geometry) {
    const groupId = Session.getGroupIdToPass();
    const encodedGeometry = JSON.stringify(geometry);
    return Network.asPromise(
      Network.api(
        "post",
        `/ms4s/${groupId}/calculate-drainage-area`,
        { geometry: encodedGeometry },
        Network.noLoadingScreen(),
      ),
    );
  };

  var getPossibleContactCompanies = function (projectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/possible-contact-companies`, { projectId }),
    );
  };

  var getPossibleContactUsers = function (projectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/possible-contact-users`, { projectId }),
    );
  };

  var getProjectPermissions = function (projectIds) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/project-permissions`, { projectIds }),
    );
  };

  var getCompleteProjectStatus = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/complete-project-status", {
        constructionProjectId,
      }),
    );
  };

  var getPostActiveStatus = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/post-active-status", { constructionProjectId }),
    );
  };

  var getProjectPauseStatus = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/project-pause-status", { constructionProjectId }),
    );
  };

  var getCertifyProjectStatus = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/certify-project-status", { constructionProjectId }),
    );
  };

  var getProjectPopup = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/project-popup", { constructionProjectId }),
    );
  };

  var saveCompleteProjectStatus = function (constructionProjectId, completeProjectStatus) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/complete-project-status", {
        constructionProjectId,
        completeProjectStatus,
      }),
    );
  };

  var getFindingsStatus = function (findingId, relatedId, severity) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/findings-status`, {
        findingId,
        relatedId,
        severity,
      }),
    );
  };

  var getFormalCommunication = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/formal-communication`, { id }));
  };

  var getInspectionIssues = function (projectInspectionId, enforcementActionId) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/inspection-issues", {
        projectInspectionId,
        enforcementActionId,
      }),
    );
  };

  var getJwtSsoToken = function () {
    return Network.asPromise(Network.api("get", `/sso/jwt`));
  };

  var getSaml2SsoResponse = function (samlRequest) {
    return Network.asPromise(Network.api("get", `/sso/saml2`, { samlRequest }));
  };

  var saml2SsoLogin = function (email) {
    return Network.asPromise(
      Network.api("get", `/sso/saml2-login`, { email: encodeURIComponent(email) }),
    );
  };

  var ssoTokenLogin = function (ssoToken) {
    return Network.asPromise(Network.api("get", `/sso/login`, { ssoToken }));
  };

  var getFormSettings = function () {
    const groupId = Session.getGroupIdToPass();
    const tool = Tree.get("tool");
    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/form-settings", { tool }));
  };

  var getMenuSettings = function (tool) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/menu-settings", { tool }));
  };

  var getUserNotificationSettings = function () {
    return Network.asPromise(Network.api("get", `/user/notifications`));
  };

  var updateUserNotificationSettings = function (updatedNotifications, callback) {
    return Network.asPromise(Network.api("put", `/user/notifications`, { updatedNotifications }));
  };

  var getEsriLayerToken = function (server = "modeling") {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/map-layer/" + server + "/token", {}),
    );
  };

  var updateTodo = function (id, subject, updates) {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "post",
        "/ms4s/" + groupId + "/todos/update",
        { id, subject, updates },
        Network.noLoadingScreen(),
      ),
    );
  };

  var setCustomToDoList = function (updates, subject) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/todos/customize`, { updates, subject }),
    );
  };

  var setDefaultToDoList = function (subject) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/todos/default`, { subject }),
    );
  };

  var saveRecordSweep = function (recordSweepData) {
    const groupId = Session.getGroupIdToPass();
    var formData = Form.toFormData({ recordSweepData });

    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/save-record-sweep`, formData),
    );
  };

  var getRecordSweepHistory = function (networkName, catchId) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/record-sweep-history`, {
        networkName,
        catchId,
      }),
    );
  };

  var deleteRecordSweep = function (id, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("delete", `/ms4s/${groupId}/record-sweep`, { id })
      .done(callback)
      .fail(error);
  };

  var formalCommunicationPdfPreview = function (inputData) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api(
        "post",
        `/ms4s/${groupId}/formal-communication-preview`,
        {
          ...inputData,
        },
        { xhrFields: { responseType: "arraybuffer" } },
      ),
    );
  };

  var sendFormalCommunications = function (inputData) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/send-formal-communication`, {
        ...inputData,
      }),
    );
  };

  var getActivities = function () {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/activities`, {}).fail(error));
  };

  var getActivity = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/activity`, { id }).fail(error));
  };

  var updateActivity = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/update-activity`, data).fail(error),
    );
  };

  var createActivity = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/ms4s/${groupId}/create-activity`, data).fail(error),
    );
  };

  var deleteActivity = function (activityId, callback) {
    const groupId = Session.getGroupIdToPass();

    return Network.api("delete", "/ms4s/" + groupId + "/activity", {
      activityId,
    })
      .done(callback)
      .fail(error);
  };

  var getToolSettings = function (version) {
    const groupId = Tree.get("rootGroup", "groupId");
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/tool-settings", { version }),
    );
  };

  var getModuleSettings = function () {
    const groupId = Tree.get("rootGroup", "groupId");
    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/module-settings"));
  };

  var setModuleSettings = function (data) {
    const groupId = Tree.get("rootGroup", "groupId");
    return Network.asPromise(Network.api("post", "/ms4s/" + groupId + "/module-settings", data));
  };

  var downloadProjectPhotos = function (id) {
    const groupId = Tree.get("rootGroup", "groupId");

    return Downloader.fromApi("post", `/ms4s/${groupId}/project-photo-export`, { id });
  };

  var getIddeIncidentInsight = function (params) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/idde-incident-insight", params),
    );
  };

  var getIndcomFacilityInsight = function (params) {
    const groupId = Session.getGroupIdToPass();
    const filters = {};

    if (params?.responsiblePartyManagement) {
      const selectedParty = params.responsiblePartyManagement;

      if (selectedParty !== "total") {
        filters["responsiblePartyManagement"] = [selectedParty];
      }
    }

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/indcom-facility-insight", {
        ...params,
        filters,
      }),
    );
  };

  var getMuniCatchBasinInsight = function (params) {
    const groupId = Session.getGroupIdToPass();
    var filters = FilterConstants.getDefaultFiltersByDataView("muni-catch-basin");

    if (params?.responsiblePartyMaintenance) {
      const selectedParty = params.responsiblePartyMaintenance;

      if (selectedParty === "total") {
        filters["responsiblePartyMaintenance"] = [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
        ];
      } else {
        filters["responsiblePartyMaintenance"] = [selectedParty];
      }
    }

    if (params?.spatialItem) {
      const selectedItem = params.spatialItem;
      const filterKey =
        params.spatialDataTitle === "Maintenance Zones" ? "maintenanceZones" : "receivingWaters";

      if (selectedItem === "total") {
        filters[filterKey] = null;
      } else {
        filters["dataSort"] = "rw";
        filters[filterKey] = [selectedItem];
      }
    }

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/muni-catch-basin-insight", {
        ...params,
        filters,
      }),
    );
  };

  var getMuniOutfallInsight = function (params) {
    const Filters = require("./mapFunctions/filters");

    const groupId = Session.getGroupIdToPass();
    const filters = Filters.getDefaultFilters();

    if (params?.responsiblePartyMaintenance) {
      const selectedParty = params.responsiblePartyMaintenance;

      if (selectedParty === "total") {
        filters["responsiblePartyMaintenance"] = [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
        ];
      } else {
        filters["responsiblePartyMaintenance"] = [selectedParty];
      }
    }

    if (params?.spatialItem) {
      const selectedItem = params.spatialItem;
      const filterKey =
        params.spatialDataTitle === "Maintenance Zones" ? "maintenanceZones" : "receivingWaters";

      if (selectedItem === "total") {
        filters[filterKey] = null;
      } else {
        filters["dataSort"] = "rw";
        filters[filterKey] = [selectedItem];
      }
    }

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/muni-outfall-insight", {
        ...params,
        filters,
      }),
    );
  };

  var getStreetSweepingInsight = function (params) {
    const Filters = require("./mapFunctions/filters");

    const groupId = Session.getGroupIdToPass();
    const filters = Filters.getDefaultFilters();

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/street-sweeping-insight", { ...params, filters }),
    );
  };

  var getStreetSwepingInsightInitData = function () {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/street-sweeping-insight-init-data", {}),
    );
  };

  var getLidProjectInsight = function (params) {
    const Filters = require("./mapFunctions/filters");

    const groupId = Session.getGroupIdToPass();
    const filters = Filters.getDefaultFilters();

    if (params?.responsiblePartyMaintenance) {
      const selectedParty = params.responsiblePartyMaintenance;

      if (selectedParty === "total") {
        filters["lidOwnership"] = [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
        ];
      } else {
        filters["lidOwnership"] = [selectedParty];
      }
    }

    filters["phase"] = ["completed", "certified"];

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/lid-project-insight", { ...params, filters }),
    );
  };

  var getConstructionProjectInsight = function (params) {
    const Filters = require("./mapFunctions/filters");

    const groupId = Session.getGroupIdToPass();
    const filters = Filters.getDefaultFilters();

    if (params?.responsiblePartyMaintenance) {
      const selectedParty = params.responsiblePartyMaintenance;

      if (selectedParty === "total") {
        filters["lidOwnership"] = [
          "our-agency",
          "private",
          "partner",
          "dot",
          "ms4",
          "local-agency",
          "other",
          "unknown",
        ];
      } else {
        filters["lidOwnership"] = [selectedParty];
      }
    }

    filters["phase"] = ["construction", "post-active", "completed", "certified"];

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/construction-project-insight", {
        ...params,
        filters,
      }),
    );
  };

  var getScenarioInsight = function () {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/scenario-insight", {
        filters: Tree.get("filters"),
      }),
    );
  };

  var getProjectDeliverySummary = function () {
    const groupId = Session.getGroupIdToPass();
    const filters = Tree.get("filters");
    checkspatialboxes(filters);
    return Network.asPromise(
      Network.api(
        "post",
        "/ms4s/" + groupId + "/project-delivery-summary",
        { filters },
        Network.noLoadingScreen(),
      ),
    );
  };

  var getUnfilteredScenarios = function () {
    checkSpatialCheckBoxes();
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("post", "/ms4s/" + groupId + "/unfiltered-scenarios", {}));
  };

  var checkSpatialCheckBoxes = function () {
    if (areAllCheckboxesChecked("drainsToRw")) {
      Tree.set(["filters", "drainsToRw"], []);
    }
    if (areAllCheckboxesChecked("drainsToC")) {
      Tree.set(["filters", "drainsToC"], []);
    }
    if (areAllCheckboxesChecked("catchmentName")) {
      Tree.set(["filters", "catchmentName"], []);
    }
    if (areAllCheckboxesChecked("drainsToSpatialGroupingId")) {
      Tree.set(["filters", "drainsToSpatialGroupingId"], []);
    }
    if (areAllCheckboxesChecked("drainsToAssesmentArea")) {
      Tree.set(["filters", "drainsToAssesmentArea"], []);
    }
  };
  const areAllCheckboxesChecked = function (name) {
    var checkboxes = $('input[name="' + name + '"]');
    var checkedCheckboxes = $('input[name="' + name + '"]:checked');
    return checkboxes.length === checkedCheckboxes.length;
  };

  var getTelrResultLayerData = function () {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/telr-result-layer", {
        filters: Tree.get("filters"),
      }),
    );
  };

  var constructionToDoClearFlaggedInspections = function (inspectionIds) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/todos/construction-clear-flagged-inspections`, {
        inspectionIds,
      }),
    );
  };

  var validateEmailAddress = function (userObj) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(Network.api("post", `/validate-email`, { ...userObj, groupId }));
  };

  var getSyncResult = function (uuid) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/sync-result`, { uuid }, Network.noLoadingScreen()),
    );
  };

  var saveFeatureFlags = function (enabledFlags) {
    return Network.asPromise(Network.api("post", `/save-feature-flags`, { enabledFlags }));
  };

  return {
    activateUser,
    queryActivationCode,
    calculateDrainageArea,
    checkDeleteUser,
    checkUniqueBmpName,
    checkUniqueIncidentName,
    checkUniqueOutfallName,
    checkUniqueFacilityIdentifier,
    createActivity,
    createBmpFcs,
    createConstructionProject,
    createConstructionProjectInspection,
    createFacilityInspection,
    getFacilitySelfInspection,
    createFacilitySelfInspection,
    previewFacilitySelfInspectionPdf,
    downloadFacilitySelfInspectionFile,
    createIncidentInvestigation,
    createNewContact,
    createNewIncident,
    createNewMilestone,
    createNewOutfall,
    createNewUser,
    createOutfallRoutineMaintenance,
    createRoutineMaintenance,
    deleteActivity,
    deleteBenchmark,
    deleteBenchmarkDepths,
    deleteBenchmarkInfs,
    deleteBmp,
    deleteBmpObservation,
    deleteConstructionProject,
    deleteConstructionProjectPhoto,
    deleteContact,
    deleteDepth,
    deleteFcs,
    deleteFcsAssessment,
    deleteIncident,
    deleteIncidentInvestigation,
    downloadIncidentInvestigationFile,
    deleteFactSheetPhoto,
    deleteFacilityFactSheet,
    deleteInf,
    deleteLidSelfInspection,
    downloadLidSelfInspectionPdf,
    downloadLidSummaryPdf,
    downloadLidResponsePdf,
    previewLidSelfInspectionPdf,
    deleteMaintenance,
    deleteMilestone,
    deleteCulvert,
    deleteOpenChannel,
    deleteOutfall,
    deleteManhole,
    deleteManholeInspection,
    deleteOutfallInspection,
    deleteParcelAssessment,
    deletePhoto,
    deleteProjectFactSheet,
    deleteUser,
    downloadActivityFile,
    downloadFacilityFile,
    downloadConstructionInspectionReport,
    downloadConstructionInspectionReportsZip,
    downloadConstructionProjectInspectionPreview,
    downloadFacilityInspectionPreview,
    downloadFacilityInspectionReport,
    downloadFacilitySelfInspectionReport,
    downloadEnforcementLetterFile,
    getNewLidEnforcement,
    downloadLidSelfInspectionFile,
    downloadOutfallFile,
    previewOutfallInspectionPdf,
    downloadOutfallInspectionPdf,
    downloadIncidentFile,
    downloadOutfallInspectionFile,
    downloadBmpFcsFile,
    downloadManholeFile,
    downloadPermitPlanFile,
    downloadResponderFile,
    downloadTrashAttachment,
    downloadProjectPhotos,
    formFileDelete,
    formFileUpload,
    formalCommunicationPdfPreview,
    generateIncidentName,
    getAdditionalInspectionPermissions,
    getActiveBenchmark,
    getActivities,
    getActivity,
    getAllBmps,
    getAllMS4s,
    getAllParcels,
    getAnnualReporting,
    getAnnualReports,
    getArcGisUrlData,
    getAssociatedLidProjectAreas,
    getAttributionPercentage,
    getBenchmark,
    getBmp,
    getBmpFcs,
    getLidBmpPopup,
    getBmpFcsAssessmentHistory,
    getBmpFcsInventoryRemaining,
    getTelrReadyMissingFields,
    runTelr,
    getLastTelrRun,
    getBmpPopup,
    getFcsPopup,
    getScenarioBmpPopup,
    getBmpObservation,
    calculateBmpScore,
    bmpObservationPdfPreview,
    downloadBmpObservationPdf,
    getBoundary,
    getBulkUpdateData,
    getCatchmentCentralizedBmps,
    getCatchments,
    getCatchBasinForFactSheet,
    getCertifyProjectStatus,
    getTrashPoints,
    getCompleteProjectStatus,
    getConstructionProjectAreas,
    getConstructionProjectForFactSheet,
    getLidProjectForFactSheet,
    getConstructionProjectHistory,
    getConstructionProjectIdentifierUnique,
    getConstructionProjectInventory,
    getConstructionProjectForLidAction,
    getConstructionProjectForLidInspectionSummary,
    getLidInspectionSummary,
    deleteLidInspectionSummary,
    generateLidInspectionSummaryLetter,
    previewLidInspectionSummaryLetter,
    getConstructionProjects,
    getConstructionProjectDeliveryProjects,
    getContacts,
    getDepthData,
    getDryWeatherCatchBasins,
    getDryWeatherManholes,
    getDryWeatherOutfalls,
    getEnforcementAction,
    getEsriLayerToken,
    getExistingConstructionProjectInspection,
    getExistingFacilityInspection,
    getExistingUserData,
    getExportData,
    getFacility,
    getFacilityEnforcement,
    issueLidEnforcement,
    updateLidEnforcement,
    getLidEnforcement,
    getFacilitiesLayer,
    getFacilityForFactSheet,
    getFacilityPopupData,
    getFcsScore,
    getFcss,
    getFindingsStatus,
    getFlowRouting,
    getFormalCommunication,
    getFormSettings,
    getUsers,
    getGroupUsers,
    getHighPriorityArea,
    getIddeDryWeatherAsset,
    getIddeDryWeatherPopup,
    getManholeInspection,
    getIddeIncidentInsight,
    getIndcomFacilityInsight,
    getIncident,
    getIncidentInvestigation,
    previewIncidentInvestigationPdf,
    getIncidentPopup,
    getIncidents,
    getInfData,
    getInitialEnforcementActionFields,
    getInspectionIssues,
    getJwtSsoToken,
    getLanduse,
    getLastFcsAssessment,
    getLidProjectAreas,
    getLidProjects,
    getAllLidBmps,
    getLidProjectById,
    getLidSelfInspection,
    getMaintenanceZones,
    getManholes,
    createOrUpdateManhole,
    getMapDrainageLayer,
    getMarkerData,
    getMenuSettings,
    getMilestones,
    getMonitoringLocations,
    getMuniBmpPopup,
    getMuniCatchBasinPopup,
    getMuniManholePopup,
    getMuniCleanoutPopup,
    getMuniCulverts,
    getMuniCulvertPopup,
    getMuniOpenChannels,
    getMuniOpenChannelPopup,
    getMuniCatchBasinDrainages,
    getMuniSbmpsDrainages,
    downloadMuniBmpPdf,
    previewMuniBmpPdf,
    getMuniLiftStationsPopup,
    getMuniPipes,
    getMuniPipesPopup,
    getMuniCatchBasinInsight,
    getMuniOutfallInsight,
    getLidProjectInsight,
    getConstructionProjectInsight,
    getNewConstructionProjectInspection,
    getNewFacilityInspection,
    getNewFacilitySelfInspection,
    getNewFacilityEnforcement,
    getOutfall,
    getOutfallForFactSheet,
    getOutfallPopup,
    getOutfallRoutineMaintenance,
    getOutfalls,
    getParcelAssessmentHistory,
    getPhotoList,
    getPossibleContactCompanies,
    getPossibleContactUsers,
    getPossibleFollowupInspectionsForEnforcementAction,
    getPossibleInstallationInspectionTypes,
    getPossibleRunoffOffsetProjectsInReceivingWater,
    getPossibleUserRoles,
    getPostActiveStatus,
    getProjectPauseStatus,
    getPriorityArea,
    getProjectOptions,
    getProjectPermissions,
    getProjectPopup,
    getRecordSweepHistory,
    getRequiredInventoryFields,
    getRoads,
    getRoutineMaintenance,
    getScenarioInsight,
    getStreetSweepingInsight,
    getStreetSwepingInsightInitData,
    getProjectDeliverySummary,
    getUnfilteredScenarios,
    getTelrResultLayerData,
    getSaml2SsoResponse,
    saml2SsoLogin,
    ssoTokenLogin,
    getSingleFcs,
    getSingleFcsAssessment,
    getSingleFcsFilenames,
    getStormdrains,
    getTodos,
    getToolSettings,
    getModuleSettings,
    setModuleSettings,
    getTypeData,
    getUrbanDrainageStatus,
    getUserNotificationSettings,
    getWatersheds,
    importBMPs,
    importFromCsv,
    importValidatedUrl,
    issueFacilityEnforcement,
    newPassword,
    resendInvite,
    rotateConstructionPhoto,
    rotateFactSheetPhoto,
    rotateImage,
    saveBenchmarkDepths,
    saveBenchmarkInfs,
    saveCatchBasinBulkClean,
    saveCompleteProjectStatus,
    saveDepth,
    saveFcsScore,
    saveInf,
    saveNewBenchmark,
    saveNewFcsObs,
    saveLidSelfInspection,
    saveNewParcelAssessment,
    saveFactSheetPhotoCaption,
    savePhotoCaption,
    saveRecordSweep,
    deleteRecordSweep,
    saveRoadCondition,
    sendFormalCommunications,
    sendPassword,
    setConstructionProjectProfilePhoto,
    setFactSheetProfilePhoto,
    signUserAgreement,
    signSaasAgreement,
    updateActivity,
    updateAnnualReporting,
    updateBenchmark,
    updateBmpFcs,
    saveBmpObservation,
    updateConstructionPhotoCaption,
    updateConstructionProject,
    updateConstructionProjectAsPromise,
    updateConstructionProjectInspection,
    deleteConstructionProjectInspection,
    updateContact,
    updateEnforcementAction,
    updateExistingUser,
    updateFacilityEnforcement,
    updateFacilityInspection,
    updateFcsObs,
    updateIncident,
    updateIncidentInvestigation,
    downloadIncidentInvestigationPdf,
    lidToDoClearFormalCommuncations,
    lidToDoClearResponseNeeded,
    updateMilestone,
    updateOutfall,
    updateOutfallRoutineMaintenance,
    updateParcelAssessment,
    updateRoutineMaintenance,
    updateTodo,
    setCustomToDoList,
    setDefaultToDoList,
    updateUserNotificationSettings,
    updateUsername,
    uploadConstructionProjectPhoto,
    uploadFactSheetPhoto,
    uploadPhotos,
    validateCsvForImport,
    validateUrlDataForImport,
    deleteCleanout,
    deleteLiftStation,
    deletePipe,
    validateToken,
    validateEmailAddress,
    authenticate,
    user,
    revokeToken,
    password,
    constructionToDoClearFlaggedInspections,
    downloadConstructionProjectInspectionFile,
    getConstructionSpatialArrays,
    checkspatialboxes,
    createNewFacility,
    updateFacility,
    getSyncResult,
    saveFeatureFlags,
  };
};

module.exports = ApiCalls();

const Config = require("./config");
const Downloader = require("./files/downloader");
const FeatureFlag = require("./settings/featureFlag");
const Form = require("./general/form");
const Network = require("./network");
const Session = require("./login/session");
const TokenManager = require("./login/tokenManager");
const Tree = require("./tree");
const FilterConstants = require("./filterConstants");
