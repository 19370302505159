"use strict";

class MalformedIdError extends TypeError {
  constructor(message, id) {
    super(message);
    this.name = "MalformedIdError";
    this.id = id;
  }
}

module.exports = MalformedIdError;
