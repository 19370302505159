"use strict";

const Actions = function () {
  var setUpTopLevelDataSorts = async function () {
    const dataView = Tree.get("dataView");
    const data =
      (await getDataSortByDataViewCallback(dataView)) ?? getDataSortFromToolSettings(dataView);
    const dataSortData = data["dataSort"];
    const dataSortLayers = Object.keys(dataSortData);

    const spatialGroupings = data["spatialGrouping"];
    Tree.set("spatialGroupings", spatialGroupings);

    MainMap.setMapDataWatchers(dataSortLayers);
    Table.setTableDataWatchers(dataSortLayers);
    Tree.set("availableDataSortLayers", dataSortLayers);
    Filters.setDataSortByAvailableDataLayers();

    for (const [layer, layerData] of Object.entries(dataSortData)) {
      Tree.set([layer, "isFetching"], false);
      Tree.set([layer, "items"], layerData);
    }
    Table.render();
    Filters.renderDataSortDropdown();
  };

  var getDataSortByDataViewCallback = function (dataView) {
    const getTopLevelDataSortCallback = DataViewFunctions.getCurrentDataViewProperty(
      "getTopLevelDataSortCallback",
      dataView,
    );

    if (!getTopLevelDataSortCallback) {
      return null;
    }

    return getTopLevelDataSortCallback();
  };

  var getDataSortFromToolSettings = function (dataView) {
    const topLevelDataSorts = ToolSettings.getSetting("topLevelDataSorts");
    const dataSort = {};
    let spatialGrouping = null;

    for (const sort of topLevelDataSorts["dataViewSorts"][dataView]) {
      const data = topLevelDataSorts["dataSort"][sort];
      if (data) {
        dataSort[sort] = data;

        const group = topLevelDataSorts["spatialGrouping"][sort];
        if (group) {
          spatialGrouping = group;
        }

        break;
      }
    }

    return {
      dataSort,
      spatialGrouping,
    };
  };

  var loadCatchments = function () {
    Tree.set(["catchments", "isFetching"], true);

    ApiCalls.getCatchments(function (data) {
      Tree.set("catchments", {
        isFetching: false,
        items: data,
      });
    });
  };

  var loadFullDrainageBmp = function (mapId) {
    var groupId = Tree.get(["activeGroup", "groupId"]);
    var filters = getFiltersByMapId(mapId);
    var dataPath = getLayerDataPathByMapId(mapId);
    var onlyAccepted = Session.isRegulator();
    var filtersOn = getFiltersOn("bmpram");

    Tree.set(["layers", "fullDrainageBmp", "isFetching"], true);
    ApiCalls.getMapDrainageLayer("bmp", groupId, filters, filtersOn, onlyAccepted, function (data) {
      if (data) {
        var geoJsonPolygons = CleanData.cleanGeoJson(data);
        Tree.set(["layers", "fullDrainageBmp", dataPath], geoJsonPolygons);
        Tree.set(["layers", "fullDrainageBmp", "isFetching"], false);
        Layers.highlightDrainage("bmps", "fullDrainageBmp");
      }
    });
  };

  var loadFullDrainageFcs = function (mapId) {
    var groupId = Tree.get(["activeGroup", "groupId"]);
    var filters = getFiltersByMapId(mapId);
    var dataPath = getLayerDataPathByMapId(mapId);
    var onlyAccepted = Session.isRegulator();
    var filtersOn = getFiltersOn("trashram");

    Tree.set(["layers", "fullDrainageFcs", "isFetching"], true);
    ApiCalls.getMapDrainageLayer("fcs", groupId, filters, filtersOn, onlyAccepted, function (data) {
      if (data) {
        var geoJsonPolygons = CleanData.cleanGeoJson(data);
        Tree.set(["layers", "fullDrainageFcs", dataPath], geoJsonPolygons);
        Tree.set(["layers", "fullDrainageFcs", "isFetching"], false);
        Layers.highlightDrainage("fcs", "fullDrainageFcs");
      }
    });
  };

  var selectCatchmentsByFilter = function (mapLayers = MainMap.getMapLayers()) {
    var filters = Tree.get("filters");
    if (mapLayers.catchmentsLayer) mapLayers.catchmentsLayer.filterDrainsTo(filters);
  };

  var toggleLayer = function (layerName, isEnabled, mapId) {
    var isEnabledPath = getLayerIsEnabledPathByMapId(mapId);

    Tree.set(["layers", layerName, isEnabledPath], isEnabled);
    _saveLayerEnabledToSessionStorage(layerName, isEnabled);

    //toggle all visual assessment layers together
    if (layerName == "trashLines") {
      Tree.set(["layers", "trashPoints", isEnabledPath], isEnabled);
      Tree.set(["layers", "survey123", isEnabledPath], isEnabled);
    }
  };

  var _saveLayerEnabledToSessionStorage = function (layerName, isEnabled) {
    const ignoreLayers = ["streamsEsriFeature", "flowRouting", "bounds"];
    const dataView = Tree.get("dataView");
    let enabledLayers = JSON.parse(sessionStorage.getItem(`enabledLayers-${dataView}`) || "[]");

    if (!ignoreLayers.includes(layerName)) {
      enabledLayers = isEnabled
        ? [...new Set([...enabledLayers, layerName])]
        : enabledLayers.filter((layer) => layer !== layerName);
    }

    sessionStorage.setItem(`enabledLayers-${dataView}`, JSON.stringify(enabledLayers));
  };

  var useCachedLayers = function (defaultLayers) {
    const dataView = Tree.get("dataView");
    const enabledLayers =
      JSON.parse(sessionStorage.getItem(`enabledLayers-${dataView}`)) || defaultLayers;

    if (enabledLayers === defaultLayers) {
      defaultLayers.forEach((layer) => _saveLayerEnabledToSessionStorage(layer, true));
    }

    return enabledLayers;
  };

  var loadRunoffCondition = function () {
    var year = Tree.get("waterYear");
    var filters = Tree.get("filters");
    var spatialView = Tree.get("spatialView");

    Tree.set(["layers", "runoffCondition", "isFetching"], true);

    ApiCalls.getAllParcels(year, filters, spatialView, function (parcelData, conditionData) {
      var parcelTiles = CleanData.cleanGeoJson(parcelData);
      CleanData.applyParcelConditions(parcelTiles.features, conditionData);
      Tree.set(["layers", "runoffCondition", "dataSpatialView"], Tree.get("spatialView"));
      Tree.set(["layers", "runoffCondition", "data"], parcelTiles);
      Tree.set(["layers", "runoffCondition", "isFetching"], false);
    });
  };

  var loadAssociatedProjectArea = function () {
    var filters = Tree.get("filters");

    Tree.set(["layers", "projects", "isFetching"], true);

    ApiCalls.getAssociatedLidProjectAreas(filters, function (data) {
      if (data) {
        data.forEach((project) => {
          project.geom = project.projectAreaGeom;
        });
      }
      data = CleanData.cleanGeoJson(data);
      Tree.set(["layers", "projects", "data"], data);
      Tree.set(["layers", "projects", "isFetching"], false);
    });
  };

  var loadLidProjectBmps = async function (mapId) {
    var waterYear = Tree.get("waterYear");
    var filters = getFiltersByMapId(mapId);
    var dataPath = getLayerDataPathByMapId(mapId);

    const defaultBmpramFilters = FilterConstants.getDefaultFiltersByDataView("bmp");
    filters = Object.assign({}, filters, defaultBmpramFilters);
    filters["inProject"] = true;

    Tree.set(["layers", "lidProjectBmp", "isFetching"], true);

    const data = await ApiCalls.getAllLidBmps(waterYear, filters);
    Tree.set(["layers", "lidProjectBmp", "isFetching"], false);
    Tree.set(["layers", "lidProjectBmp", dataPath], data);
  };

  var loadWatersheds = function (mapId) {
    var dataPath = getLayerDataPathByMapId(mapId);

    var bbox = Tree.get("bbox");
    Tree.set(["layers", "streams", "isFetching"], true);
    ApiCalls.getWatersheds(bbox, function (data) {
      var geoJson = CleanData.cleanGeoJson(data, "line");
      Tree.set(["layers", "streams", dataPath], geoJson);
      Tree.set(["layers", "streams", "isFetching"], false);
    });
  };

  var loadBoundary = function () {
    const groupId = Tree.get(["activeGroup", "groupId"]);
    ApiCalls.getBoundary(groupId, function (data) {
      if ($.isEmptyObject(data)) console.error("received empty boundary");
      const geoJson = CleanData.cleanGeoJson(data);
      Tree.merge(["layers", "bounds"], {
        data: geoJson,
        isFetching: false,
      });
    });
  };

  var loadTodos = async function (dataView, filters, onlyUnfiltered, loadingScreen = true) {
    const enabledTabs = DataViewFunctions.getUserEnabledTabs(dataView);
    if (!enabledTabs.includes("todo")) {
      console.warn(`Failed to load disabled todos for data view "${dataView}".`);
      return;
    }

    Tree.set(["todos", dataView, "isFetching"], true);
    const data = await getTodoData(dataView, filters, loadingScreen);
    getTodosCallback(dataView, filters, onlyUnfiltered, data);
    Tree.set(["todos", dataView, "isFetching"], false);
  };

  var getTodoData = async function (dataView, filters, loadingScreen) {
    if (DataViewFunctions.getCurrentDataViewProperty("offlineTodos", dataView)) {
      const filters = Filters.getOfflineFilters(dataView);
      return await ResourceController.get(
        DataViewFunctions.getCurrentDataViewProperty("defaultLayers", dataView)?.[0],
      ).getAll({ filters, loadingScreen });
    } else {
      const toDoFilters = ToDoFunctions.getToDoFilters(dataView);
      let data;

      if (dataView === "bmp" || dataView === "construction-project" || dataView === "lid-project") {
        data = await ApiCalls.getTodos(dataView, filters, toDoFilters, false);
      } else {
        data = await ApiCalls.getTodos(dataView, filters, toDoFilters, loadingScreen);
      }

      return ToDoFunctions.addMissingToDoSubjects(data);
    }
  };

  var getTodosCallback = function (dataView, filters, onlyUnfiltered, data) {
    if (
      filters === undefined ||
      filters === null ||
      (filters.catchments.length === 0 && filters.receivingWaters.length === 0)
    ) {
      Tree.set(["todos", dataView, "unfilteredData"], data);
    }
    if (!onlyUnfiltered) {
      Tree.set(["todos", dataView, "data"], data);
    }
  };

  var loadBmpFcsAssessmentHistory = function (idBmp) {
    var groupId = Tree.get("activeGroup", "groupId");
    const onlyAccepted = Session.isRegulator();
    ApiCalls.getBmpFcsAssessmentHistory(groupId, idBmp, onlyAccepted, function (data) {
      Tree.set("bmpFcsAssessmentHistory", data);
    });
  };

  /* ****************** Photos ****************** */

  var loadPhotoList = function (id, layer) {
    var groupId = Tree.get("activeGroup", "groupId");
    Tree.set([layer + "_photos", id, "isFetching"], true);
    ApiCalls.getPhotoList(groupId, id, layer, function (photoList) {
      const cssList = initiatePhotoCss(photoList);
      Tree.merge([layer + "_photos", id], {
        items: photoList,
        isFetching: false,
        lastUpdated: Date.now(),
        cssList: cssList,
      });
    });
  };

  var uploadPhotos = function (id, files, layer, caption) {
    var formData = new FormData();
    var groupId = Tree.get("activeGroup", "groupId");
    var i;

    if (files && files.length) {
      for (i = 0; i < files.length; i++) {
        formData.append("photo-" + i, files[i]);
      }

      Tree.set([layer + "_photos", id, "isUploading"], true);
      Tree.set([layer + "_photos", id, "progress"], 0);

      ApiCalls.uploadPhotos(
        groupId,
        id,
        formData,
        layer,
        function (e) {
          if (e.lengthComputable) {
            Tree.set([layer + "_photos", id, "progress"], e.loaded / e.total);
          }
        },
        function (uploadedList) {
          if (caption) {
            updatePhotoCaption(id, uploadedList[0].filename, caption, layer);
          }
          var stateObjectName = layer + "_photos";
          Tree.set([stateObjectName, id, "isUploading"], false);

          if (!Tree.get([stateObjectName, id, "items"])) {
            Tree.set([stateObjectName, id, "items"], []);
          }
          Tree.concat([stateObjectName, id, "items"], uploadedList);

          if (layer == "obs" || layer == "bench" || layer == "bmpobs") {
            const photosTreePath = layer + "_photos";
            const photoListClassSelector = "." + layer + "-photo-list";

            loadBmpFcsAssessmentHistory(Tree.get("currentBmpFcs").idBmp);
            Tree.set([photosTreePath, "temps"], []);
            Tree.set("photo_layer", "bmp");
            $(".modal " + photoListClassSelector).empty();
          } else if (layer === "parcelAssessment") {
            const photosTreePath = layer + "_photos";
            const photoListClassSelector = "." + Misc.camelToKebab(layer) + "-photo-list";

            Tree.set([photosTreePath, "temps"], []);
            Tree.set("photo_layer", "bmp");
            $(photoListClassSelector).empty();
          }
        },
      );
    }
  };

  var uploadConstructionPhoto = function (id, files, progressCallback, callback) {
    const formData = preparePhotoFormData(files);
    formData.append("constructionProjectId", id);
    ApiCalls.uploadConstructionProjectPhoto(formData, progressCallback, function (newFiles) {
      if (callback) {
        callback(newFiles);
      }
    });
  };

  var uploadFactSheetPhoto = function (toolId, photoType, id, files, progressCallback, callback) {
    const formData = preparePhotoFormData(files);
    formData.append(toolId, id);
    ApiCalls.uploadFactSheetPhoto(photoType, formData, progressCallback, function (newFiles) {
      if (callback) {
        callback(newFiles);
      }
    });
  };

  var preparePhotoFormData = function (file) {
    const formData = new FormData();
    formData.append(`photo-0`, file);
    formData.append(`captionsByName[photo-0]`, "");
    return formData;
  };

  var deletePhoto = function (id, filename, layer) {
    var items = Tree.get(layer + "_photos", id, "items");

    if (!Array.isArray(items)) {
      throw Error(`Can't find photos for layer ${layer}, id ${id}.`);
    }

    var groupId = Tree.get("activeGroup", "groupId");
    items = items.filter(function (photo) {
      return photo.filename !== filename;
    });
    Tree.set([layer + "_photos", id, "items"], items);

    ApiCalls.deletePhoto(layer, id, filename, groupId, function () {
      // do nothing
    });
  };

  var updatePhotoCaption = function (id, filename, caption, layer) {
    var groupId = Tree.get("activeGroup", "groupId");

    // use baobab's descriptor object to find the first matching photo
    var cursor = Tree.select(layer + "_photos", id, "items", {
      filename: filename,
    });
    var photo = cursor.get();

    ApiCalls.savePhotoCaption(
      groupId,
      id,
      photo ? photo.filename : filename,
      caption,
      layer,
      function () {
        if (cursor.get()) {
          cursor.set(["caption"], caption);
          cursor.release();
        }
      },
    );
  };

  var loadTrashLinesAndPoints = function () {
    var groupId = Tree.get("activeGroup", "groupId");
    var filters = Tree.get("filters"); //inventory or observations

    const onlyAccepted = Session.isRegulator();
    ApiCalls.getTrashPoints(groupId, filters, onlyAccepted, function (data) {
      var geoJsonLines = CleanData.cleanGeoJson(data[0]);
      Tree.merge(["layers", "trashLines"], {
        data: geoJsonLines.features,
        isFetching: false,
      });

      var geoJsonPoints = CleanData.cleanGeoJson(data[1]);
      Tree.merge(["layers", "trashPoints"], {
        data: geoJsonPoints.features,
        isFetching: false,
      });
      var combinedArray = data[0].concat(data[1]);
      //update catch list for urban catchment table
      updateAppCatchArray(combinedArray, "trashLines");
    });
  };

  var updateAppCatchArray = function (objArray, layerName) {
    var filteredCatchArray = [];
    objArray.forEach(function (record) {
      if (filteredCatchArray.indexOf(record.catchid) == -1) {
        filteredCatchArray.push(record.catchid);
      }
    });
    Tree.set(["layers", layerName, "catchList"], filteredCatchArray);
    updateFilteredCatchData();
  };

  function updateFilteredCatchData() {
    // compiles array of catchments that contain filtered data by building
    // app-specific lists and then combining those lists for a table filter list

    var layers = Tree.get("layers");
    var collectorArray = layers.trashLines.catchList;
    var surveyArray = layers.survey123.catchList;

    if (layers.trashLines.isEnabled || layers.survey123.isEnabled) {
      if (layers.trashLines.isEnabled && layers.survey123.isEnabled) {
        var tempArray = [];
        surveyArray.forEach(function (record) {
          if (collectorArray.indexOf(record) == -1) {
            tempArray.push(record);
          }
        });
        tempArray = collectorArray.concat(tempArray);
        setFilteredCatchData(tempArray);
      } else if (!layers.trashLines.isEnabled && layers.survey123.isEnabled) {
        setFilteredCatchData(surveyArray);
      } else {
        setFilteredCatchData(collectorArray);
      }
    } else {
      Tree.set("catchmentData", []);
    }
  }

  function setFilteredCatchData(catchNameArray) {
    //actually sets the current filtered list for the urban catchment table
    var filteredCatchArray = [];
    var catchments = Tree.get("catchments", "items");
    catchNameArray.forEach(function (catchment) {
      for (var i = 0; i < catchments.length; i++) {
        if (catchment == catchments[i].catchid) {
          filteredCatchArray.push(catchments[i]);
        }
      }
    });
    Tree.set(["catchmentData"], filteredCatchArray);
  }

  var getRecordCount = function () {
    var count = 0;
    var surveyRecords = Tree.get("layers", "survey123");
    var collectorLineRecords = Tree.get("layers", "trashLines");
    var collectorPointRecords = Tree.get("layers", "trashPoints");

    //only count records for displayed layers
    count +=
      surveyRecords.data.features && surveyRecords.isEnabled
        ? surveyRecords.data.features.length
        : 0;
    count +=
      collectorLineRecords.data.features && collectorLineRecords.isEnabled
        ? collectorLineRecords.data.features.length
        : 0;
    count +=
      collectorPointRecords.data.features && collectorPointRecords.isEnabled
        ? collectorPointRecords.data.features.length
        : 0;

    //round large counts to the nearest thousand and display as '{#}k'
    if (count > 1000) {
      count = Math.round(count / 1000) * 1000;
      count = count.toString()[0] + "k";
    }

    setItemCount(count);
  };

  var updateBmpCount = function () {
    const count = Tree.get(["layers", "bmps", "data"]).length;
    setItemCount(count);
  };

  var updateConstructionProjectCount = function () {
    const count = Tree.get(["layers", "constructionProject", "data"]).length;
    setItemCount(count);
  };

  var setItemCount = function (num) {
    $("#item-count").text(num);
  };

  var initiatePhotoCss = function (photoList) {
    const cssListArray = Array.apply(null, Array(photoList.length)).map(
      Number.prototype.valueOf,
      0,
    );
    return cssListArray;
  };

  var getLayerDataPathByMapId = function (mapId) {
    return mapId === "modal" ? "dataModal" : "data";
  };

  var getLayerIsEnabledPathByMapId = function (mapId) {
    return mapId === "modal" ? "isEnabledModal" : "isEnabled";
  };

  var getLayerDataExistsPathByMapId = function (mapId) {
    return mapId === "modal" ? "dataModal" : "data";
  };

  var getFiltersByMapId = function (mapId) {
    const filters =
      mapId === "modal" ? Filters.getDefaultFiltersWithoutSpatial() : Tree.get("filters");
    filters["dataSort"] = Tree.get("table", "dataSort");
    return filters;
  };

  var getFiltersOn = function (tool) {
    // We only want filters to apply to bmp queries if we are in the correct tool.
    // So, we will filter the bmpsLayer in bmpram, but not in trashram, same FCS in trashram
    if (!tool) {
      tool = getFiltersOnToolByDataView();
    }

    if (Tree.get("tool") === tool) {
      return true;
    } else {
      return "";
    }
  };

  var getFiltersOnToolByDataView = function () {
    var view = Tree.get("dataView");
    if (view === "fcs") {
      return "trashram";
    } else if (view === "bmp") {
      return "bmpram";
    } else if (view === "construction-project" || view === "construction-project-delivery") {
      return "construction";
    } else if (view === "va") {
      return "trashram";
    }
    return null;
  };

  return {
    toggleLayer,
    loadCatchments,
    loadWatersheds,
    loadBoundary,
    loadPhotoList,
    uploadPhotos,
    uploadConstructionPhoto,
    uploadFactSheetPhoto,
    deletePhoto,
    updatePhotoCaption,
    selectCatchmentsByFilter,
    loadTrashLinesAndPoints,
    getRecordCount,
    loadRunoffCondition,
    loadFullDrainageBmp,
    loadFullDrainageFcs,
    initiatePhotoCss,
    loadTodos,
    loadAssociatedProjectArea,
    loadLidProjectBmps,
    setItemCount,
    setUpTopLevelDataSorts,
    getLayerDataPathByMapId,
    getLayerIsEnabledPathByMapId,
    getLayerDataExistsPathByMapId,
    getFiltersByMapId,
    getFiltersOn,
    updateConstructionProjectCount,
    updateBmpCount,
    _saveLayerEnabledToSessionStorage,
    useCachedLayers,
  };
};

module.exports = Actions();

const ApiCalls = require("./apiCalls");
const CleanData = require("./mapFunctions/cleanData");
const DataViewFunctions = require("./dataViewFunctions");
const FilterConstants = require("./filterConstants");
const Filters = require("./mapFunctions/filters");
const Layers = require("./mapFunctions/layers");
const MainMap = require("./mapFunctions/mainMap");
const Misc = require("./misc");
const ResourceController = require("./offline/resourceController");
const Session = require("./login/session");
const Table = require("./mapFunctions/table");
const ToDoFunctions = require("./mapFunctions/toDoFunctions");
const Tree = require("./tree");
const ToolSettings = require("./settings/toolSettings");
