"use strict";

var HighPriorityAreaLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "highPriorityArea",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.highPriorityAreaLayer && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers.highPriorityAreaLayer);
          } else {
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.highPriorityAreaLayer)) {
            map.removeLayer(mapLayers.highPriorityAreaLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "highPriorityArea", "isFetching"], true);
    ReportApiCalls.getHighPriorityAreaLayer(filters, function (data) {
      Tree.set(["layers", mapId, "highPriorityArea", "isFetching"], false);
      Tree.set(["layers", mapId, "highPriorityArea", "data"], data);
      Tree.set(
        ["layers", mapId, "highPriorityArea", "spatialView"],
        Tree.get("spatialView", mapId),
      );
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "highPriorityArea", function (data) {
      // let geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.highPriorityAreaLayer = createLayerAndAddToMap(map, mapLayers, data);
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.highPriorityAreaLayer) {
      map.removeLayer(mapLayers.highPriorityAreaLayer);
    }
    mapLayers.highPriorityAreaLayer = createLayer(data);
    mapLayers.highPriorityAreaLayer.addTo(map);
    return mapLayers.highPriorityAreaLayer;
  };

  var createLayer = function (data) {
    return new (Layers.getLayers().HighPriorityAreaLayer)(data);
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      status: ["filters", "status"],
      ownership: ["filters", "ownership"],
      structureType: ["filters", "structureType"],
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.highPriorityAreaLayer) &&
        Tree.get("layers", mapId, "highPriorityArea", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var getStyle = function () {
    return {
      stroke: true,
      fill: true,
      color: "#3388ff",
      fillOpacity: 0.2,
      fillColor: "#3388ff",
      weight: 2,
    };
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getStyle,
  };
};

module.exports = HighPriorityAreaLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const Layers = require("../mapFunctions/layers");
