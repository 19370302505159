"use strict";

const RoadBulkOmOptions = function () {
  const sweeperTypeSelectOptions = [
    {
      name: "Mechanical Broom",
      value: "mechanical_broom",
    },
    {
      name: "Regenerative Air",
      value: "regenerative_air",
    },
    {
      name: "Vacuum Assisted",
      value: "vacuum_assisted",
    },
  ];

  const sweeperFrequencySelectOptions = [
    {
      name: "< Monthly",
      value: "less_than_monthly",
    },
    {
      name: "Monthly",
      value: "monthly",
    },
    {
      name: "2x Monthly",
      value: "two_monthly",
    },
    {
      name: "Weekly",
      value: "weekly",
    },
    {
      name: "2x Weekly",
      value: "two_weekly",
    },
  ];

  const averagePciSelectOptions = [
    {
      name: "< 25",
      value: "less_than_25",
    },
    {
      name: "25 - 50",
      value: "between_25_50",
    },
    {
      name: "50 - 75",
      value: "between_50_75",
    },
    {
      name: "> 75",
      value: "greater_than_75",
    },
  ];

  const priorityOptions = [
    { name: "Very High", value: "very-high" },
    { name: "High", value: "high" },
    { name: "Moderate", value: "moderate" },
    { name: "Low", value: "low" },
    { name: "Very Low", value: "very-low" },
    { name: "Unknown", value: "unknown" },
  ];

  return {
    sweeperTypeSelectOptions,
    sweeperFrequencySelectOptions,
    averagePciSelectOptions,
    priorityOptions,
  };
};

module.exports = RoadBulkOmOptions();
