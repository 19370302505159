"use strict";

const RoutineMaintenanceConstants = function () {
  const trashRemovalVolumeUnitOptions = [
    {
      name: "gallons",
      value: "gallons",
    },
    {
      name: "cu-ft",
      value: "cubic-feet",
    },
    {
      name: "40-gal bags",
      value: "40-gal-bags",
    },
    {
      name: "5-gal buckets",
      value: "5-gal-buckets",
    },
  ];

  const structuralConditionIssues = ["debris-clogging", "damage"];

  const structuralConditionInletIssues = [
    "access-inlet",
    "debris-clogging-inlet",
    "damage-inlet",
    "active-erosion-inlet",
  ];

  const structuralConditionOutletIssues = [
    "access-outlet",
    "debris-clogging-outlet",
    "damage-outlet",
    "active-erosion-outlet",
  ];

  var combineOnlineData = function (bmpFcsInfo, data, missingFieldsArray) {
    data.missingFields = missingFieldsArray;
    data.bmpFcsInfo = bmpFcsInfo;
    data.bmpFcsInfo.notes = data.notes;
    return data;
  };

  var getNewRoutineMaintenanceData = function (bmpFcsInfo, missingFields) {
    const data = _getNewRoutineMaintenanceDefaults(bmpFcsInfo);
    const missingFieldsArray = getMissingFieldsArray(missingFields);
    return combineOnlineData(bmpFcsInfo, data, missingFieldsArray);
  };

  var getMissingFieldsArray = function (missingFields) {
    if (Array.isArray(missingFields)) {
      return missingFields;
    } else {
      return BmpFcsInventoryModalController.getUniqueRequiredFields(missingFields);
    }
  };

  var _getNewRoutineMaintenanceDefaults = function (bmpFcsInfo) {
    const today = DateTime.getIsoString(new Date());

    const activeIssues = getActiveMaintenanceIssues(bmpFcsInfo);
    const activeMaintenanceIssues = getRepairIssues(activeIssues);
    const structuralIssues = getStructuralIssues(structuralConditionIssues);
    const structuralInletIssues = getStructuralIssues(structuralConditionInletIssues);
    const structuralOutletIssues = getStructuralIssues(structuralConditionOutletIssues);

    const props = {
      idbmp: bmpFcsInfo.idbmp,
      date: today,
      fieldPersonnel: Session.loadContext().full_name,
      structuralConditionIssues: getNewIssues(
        bmpFcsInfo,
        structuralIssues,
        activeMaintenanceIssues,
      ),
      structuralConditionInletIssues: getNewIssues(
        bmpFcsInfo,
        structuralInletIssues,
        activeMaintenanceIssues,
      ),
      structuralConditionOutletIssues: getNewIssues(
        bmpFcsInfo,
        structuralOutletIssues,
        activeMaintenanceIssues,
      ),
      maintenanceTasksIssues: getRequiredMaintenanceTasks(bmpFcsInfo, activeMaintenanceIssues),
      activeMaintenanceIssues,
      assetAccessible: getAssetAccessibleDefault(activeIssues),
      accessIssue: getAccessIssueDefault(activeIssues),
    };

    return props;
  };

  var _organizeRoutineMaintenanceTasks = function (routineMaintenance) {
    const [routineIssues, repairIssues] = extractRoutineAndRepairIssues(routineMaintenance);

    routineMaintenance.activeMaintenanceIssues = repairIssues;
    routineMaintenance.structuralConditionIssues = [];
    routineMaintenance.structuralConditionInletIssues = [];
    routineMaintenance.structuralConditionOutletIssues = [];
    routineMaintenance.maintenanceTasksIssues = [];

    for (const issue of routineIssues) {
      if (structuralConditionIssues.includes(issue.key)) {
        routineMaintenance.structuralConditionIssues.push(issue);
      } else if (structuralConditionInletIssues.includes(issue.key)) {
        routineMaintenance.structuralConditionInletIssues.push(issue);
      } else if (structuralConditionOutletIssues.includes(issue.key)) {
        routineMaintenance.structuralConditionOutletIssues.push(issue);
      } else {
        routineMaintenance.maintenanceTasksIssues.push(issue);
      }
    }

    return routineMaintenance;
  };

  var extractRoutineAndRepairIssues = function (routineMaintenance) {
    const routineIssues = getIssuesWithOptions(
      routineMaintenance.maintenanceIssues?.filter(
        (issue) => issue.outstandingIssue !== true && issue.key !== "asset-accessible",
      ) ?? [],
    );
    const repairIssues = getIssuesWithOptions(
      routineMaintenance.maintenanceIssues?.filter((issue) => issue.outstandingIssue === true) ??
        [],
    );

    delete routineMaintenance.maintenanceIssues;

    return [routineIssues, repairIssues];
  };

  var getIssuesWithOptions = function (maintenanceTasks) {
    const allTasks = BmpFcsInventoryConstants.getAllMaintenanceTasks();
    return RoutineMaintenanceFormTable.getMaintenanceTasksWithOptions(allTasks, maintenanceTasks);
  };

  var getStructuralIssues = function (allIssues) {
    return allIssues.map(function (key) {
      return { key };
    });
  };

  var getNewIssues = function (bmpFcsInfo, allIssues, filterIssuesBy = []) {
    const issues = RoutineMaintenanceFormTable.filterIssuesBy(allIssues, filterIssuesBy);

    return RoutineMaintenanceFormTable.getDefaultMaintenanceTasksWithOptions(
      BmpFcsInventoryConstants.getAllMaintenanceTasks(),
      { maintenanceTasks: issues, filterByConfiguredKey: `bmp-${bmpFcsInfo.bmpType}` },
    );
  };

  var getRepairIssues = function (allIssues) {
    return RoutineMaintenanceFormTable.getDefaultMaintenanceTasksWithOptions(
      BmpFcsInventoryConstants.getAllMaintenanceTasks(),
      { maintenanceTasks: allIssues },
    );
  };

  var getRequiredMaintenanceTasks = function (bmpFcsInfo, activeMaintenanceIssues) {
    const allIssues = RoutineMaintenanceFormTable.getDefaultMaintenanceTasksWithOptions(
      BmpFcsInventoryConstants.getAllMaintenanceTasks(),
      { filterByConfiguredKey: `bmp-${bmpFcsInfo.bmpType}` },
    );

    const issuesInOtherCategories = [
      ...structuralConditionIssues,
      ...structuralConditionInletIssues,
      ...structuralConditionOutletIssues,
    ].map(function (key) {
      return { key };
    });

    return RoutineMaintenanceFormTable.filterIssuesBy(allIssues, [
      ...issuesInOtherCategories,
      ...activeMaintenanceIssues,
    ]);
  };

  var getActiveMaintenanceIssues = function (bmpFcsInfo) {
    if (bmpFcsInfo.activeMaintenanceIssues && bmpFcsInfo.activeMaintenanceIssues.length > 0) {
      bmpFcsInfo.activeMaintenanceIssues.every((issue) => (issue.issue = true));
      return bmpFcsInfo.activeMaintenanceIssues;
    }

    return [];
  };

  var mergeBmpFcsData = function (data) {
    return $.extend(true, {}, data.bmpFcsInfo, data.bmpFcsUpdates, data);
  };

  var getExistingRoutineMaintenanceData = function (routineMaintenance, missingFields, bmp) {
    const data = _organizeRoutineMaintenanceTasks(routineMaintenance);

    // Need to remove for asset-accessible issues
    delete bmp.activeMaintenanceIssues;

    return combineOnlineData(bmp, data, missingFields);
  };

  var getAssetAccessibleDefault = function (activeIssues) {
    return !activeIssues?.some((issue) => issue.key === "asset-accessible");
  };

  var getAccessIssueDefault = function (activeIssues) {
    return activeIssues?.find((issue) => issue.key === "asset-accessible")?.dataOption;
  };

  return {
    getNewRoutineMaintenanceData,
    getExistingRoutineMaintenanceData,
    trashRemovalVolumeUnitOptions,
    mergeBmpFcsData,
    getAssetAccessibleDefault,
    getAccessIssueDefault,
    extractRoutineAndRepairIssues,
    _organizeRoutineMaintenanceTasks,
    _getNewRoutineMaintenanceDefaults,
  };
};

module.exports = RoutineMaintenanceConstants();

const BmpFcsInventoryConstants = require("./bmpFcsInventoryConstants");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const DateTime = require("../dateTime");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
const Session = require("../login/session");
