"use strict";

const ResourceFilters = function () {
  var spatialFilters = function (unfilteredData, filters) {
    return unfilteredData.filter((datum) => {
      return spatialFilter(
        {
          drains_to_rw: datum.catchment?.drains_to_rw,
          drains_to_c: datum.catchment?.drains_to_c,
          catchid: datum.catchment?.catchid,
          highwayName: datum.highways?.highwayName,
          maintenanceZoneName: datum.maintenanceZones?.maintenanceZoneName,
        },
        filters,
      );
    });
  };

  var spatialFilter = function (
    { drains_to_rw, drains_to_c, catchid, highwayName, maintenanceZoneName } = {},
    filters,
  ) {
    return (
      (passSpatialFilter(drains_to_rw, filters.receivingWaters) ||
        passSpatialFilter(drains_to_c, filters.receivingWaters)) &&
      passSpatialFilter(catchid, filters.catchments, "outside boundary") &&
      passSpatialFilter(highwayName, filters.highways, "Outside Highways") &&
      passSpatialFilter(maintenanceZoneName, filters.maintenanceZones, "Outside Maintenance Zones")
    );
  };

  var passSpatialFilter = function (dataProperty, filterProperty, outsideKey = null) {
    if (filterProperty?.length) {
      if (outsideKey && filterProperty.includes(outsideKey)) {
        return filterProperty.includes(dataProperty) || !dataProperty;
      } else {
        return filterProperty.includes(dataProperty);
      }
    }

    return true;
  };

  var passIsoDateFilter = function (isoDateString, dateFrom, dateTo) {
    if (dateFrom || dateTo) {
      const date = DateTime.parseIsoDate(isoDateString);
      return passDateFilter(date, dateFrom, dateTo);
    }

    return true;
  };

  var passDateRangeOverRangeFilter = function (
    isoDateFrom,
    isoDateTo,
    dateFromFilter,
    dateToFilter,
  ) {
    if (dateFromFilter || dateToFilter) {
      const dateFrom = DateTime.parseIsoDate(isoDateFrom);
      const dateTo = DateTime.parseIsoDate(isoDateTo);

      return passDateFilter(dateFrom, dateFromFilter, dateToFilter, dateTo);
    }

    return true;
  };

  var isDateFilterSet = function (filterObj) {
    return filterObj?.from || filterObj?.to;
  };

  var passDateFilter = function (dateToCheckTo, dateFrom, dateTo, dateToCheckFrom = null) {
    dateFrom = DateTime.parseIsoDate(dateFrom);
    dateTo = DateTime.parseIsoDate(dateTo);
    var passFrom = true;
    var passTo = true;

    if (!(dateToCheckTo instanceof Date)) {
      return false;
    }

    if (dateFrom) {
      passFrom = dateToCheckTo >= dateFrom;

      if (!passFrom && dateToCheckFrom) {
        passFrom = dateToCheckFrom >= dateFrom;
      }
    }

    if (dateTo) {
      const nextDay = setNextDay(dateTo);
      passTo = dateToCheckTo < nextDay;

      if (!passTo && dateToCheckFrom) {
        passTo = dateToCheckFrom < nextDay;
      }
    }

    return passFrom && passTo;
  };

  var setNextDay = function (date) {
    date.setDate(date.getDate() + 1);
    return date;
  };

  var passSearchStringFilter = function (datum, filters, searchFields) {
    if (filters?.searchString) {
      const str = filters.searchString.toLowerCase();

      return searchFields.some((field) => datum[field]?.toLowerCase().includes(str));
    }

    return true;
  };

  var spatialFiltersAreSet = function (filters) {
    const spatialFilters = ["receivingWaters", "catchments", "highways", "maintenanceZones"];

    for (const filterField of spatialFilters) {
      if (filters[filterField]?.length) {
        return true;
      }
    }

    return false;
  };

  var toDoSpatialFiltersAreSet = function (toDoFilters) {
    if (!toDoFilters) {
      return false;
    }

    for (const subject in toDoFilters) {
      if (toDoFilters[subject].allSpatial === false) {
        return true;
      }
    }

    return false;
  };

  var passUnknownWhiteListFilter = function (data, list, whiteList) {
    if (!Array.isArray(list)) {
      return true;
    }

    if (list.includes(data)) {
      return true;
    } else if (list.includes("unknown")) {
      return !whiteList.includes(data);
    }

    return false;
  };

  var passTrueFalseBooleanFilter = function (data, filterList) {
    if (!filterList) {
      return true;
    }

    const filter = filterList.map(function (filter) {
      if (filter === "true") {
        return true;
      } else if (filter === "false") {
        return false;
      }
      return filter;
    });

    return passUnknownWhiteListFilter(data, filter, [true, false]);
  };

  var passToDoFilters = function (datum, filters, filterFunction = () => true) {
    const toDoFilters = filters.toDoFilters;

    if (!passSelectedSubject(datum, filters)) {
      return false;
    }

    if (toDoFilters === undefined) {
      return true;
    }

    for (var i = 0; i < datum.toDoSubjects?.length; i++) {
      const subject = datum.toDoSubjects[i];
      const subjectFilters = toDoFilters[subject];

      if (subjectFilters !== undefined && !filterFunction(datum, subjectFilters, subject)) {
        return false;
      }
    }

    return datum.toDoSubjects?.length !== 0;
  };

  var passSelectedSubject = function (datum, filters) {
    if (!filters.selectedSubject) {
      return true;
    }

    return datum.toDoSubjects.includes(filters.selectedSubject);
  };

  var getIdSet = function (allData) {
    const idSet = new Set();

    for (const datum of allData) {
      idSet.add(datum.id);
    }

    return idSet;
  };

  var filterOnline = async function (
    unfilteredData,
    loadingScreen,
    getLayerDataFunction,
    options = {},
  ) {
    const filteredData = await getLayerDataFunction(true, loadingScreen, options);
    const idSet = getIdSet(filteredData);

    return unfilteredData.filter((datum) => idSet.has(datum.id));
  };

  var addDateParam = function (params, key, from, to) {
    if (from) {
      params[`${key}From`] = GeoServerFilterFunctions.formatSqlDateFrom(from);
    }
    if (to) {
      params[`${key}To`] = GeoServerFilterFunctions.formatSqlDateTo(to);
    }
  };

  return {
    spatialFilters,
    spatialFilter,
    spatialFiltersAreSet,
    passSearchStringFilter,
    passIsoDateFilter,
    passDateFilter,
    addDateParam,
    passUnknownWhiteListFilter,
    passTrueFalseBooleanFilter,
    passToDoFilters,
    passDateRangeOverRangeFilter,
    toDoSpatialFiltersAreSet,
    filterOnline,
    isDateFilterSet,
  };
};

module.exports = ResourceFilters();

const DateTime = require("../dateTime");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
