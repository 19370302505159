"use strict";

var ReportApiCalls = function () {
  var getCatchments = function (callback, percentileUntreatedPluFilter, year) {
    const groupIdToPass = Session.getGroupIdToPass();
    return Network.cacheFirstApi("get", "/ms4s/" + groupIdToPass + "/catchments", {
      groupId: groupIdToPass,
      plu: 1,
      filters: percentileUntreatedPluFilter,
      year: year,
      calculatePluStatsForSpatialGroups: true,
      isReport: 1,
    })
      .done(callback)
      .fail(error);
  };

  var getUrbanDrainageStatus = function (groupId, callback) {
    return Network.cacheFirstApi("get", "/ms4s/" + groupId + "/urban-drainage-status")
      .done(callback)
      .fail(error);
  };

  var getReceivingWaters = function (bbox, callback) {
    return Network.api("get", "/receiving-waters", {
      bbox: bbox,
    })
      .done(callback)
      .fail(error);
  };

  function error(xhr, textStatus, errorThrown) {
    console.warn("API ERROR", xhr, textStatus, errorThrown);
  }

  var sendPassword = function (email, callback, error) {
    Network.api("post", "/password/email", { email: email }).done(callback).fail(error);
  };

  var getPossibleContactCompanies = function () {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/possible-contact-companies`));
  };

  var createNewUser = function (
    email,
    username,
    role,
    tools,
    affiliation,
    newAffiliation,
    callback,
    error,
  ) {
    Network.api("post", "/users", {
      email,
      username,
      role,
      tools,
      affiliation,
      newAffiliation,
    })
      .done(callback)
      .fail(error);
  };

  var getGroupUsers = function (callback) {
    Network.api("get", "/users").done(callback).fail(error);
  };

  var getToolsEnabledForUser = function () {
    const userId = Tree.get("user", "userId");

    return Network.asPromise(
      Network.api("get", "/ms4s/user-enabled-tools", {
        userId,
      }),
    );
  };

  var updateUsername = function (email, username, fullname, callback, error) {
    var user = Tree.get("user");
    var originalUsername = user.username;

    Network.api("post", "/users/" + originalUsername, {
      email: email,
      username: username,
      fullname: fullname,
      userId: user.userId,
    })
      .done(callback)
      .fail(error);
  };

  var updateExistingUser = function (
    userId,
    email,
    role,
    tools,
    affiliation,
    newAffiliation,
    callback,
    error,
  ) {
    Network.api("put", "/users", {
      email: encodeURIComponent(email),
      role: role,
      tools: tools,
      affiliation: affiliation,
      newAffiliation: newAffiliation,
      userId: userId,
    })
      .done(callback)
      .fail(error);
  };

  var deleteUser = function (userId, callback) {
    Network.api("delete", "/users/" + userId)
      .done(callback)
      .fail(error);
  };

  var getBoundary = function (year, callback) {
    const groupIdToPass = Session.getGroupIdToPass();
    var data = { partner: 0 };
    if (year !== null) {
      data.year = year;
    }

    return Network.cacheFirstApi("get", "/ms4s/" + groupIdToPass + "/boundary", data)
      .done(callback)
      .fail(error);
  };

  var getMapDrainageLayer = function (groupId, filters, layerName, onlyAccepted, callback) {
    return Network.api("post", "/ms4s/" + groupId + "/priority-drainage-layer", {
      filters,
      onlyAccepted,
      layerName,
    })
      .done(callback)
      .fail(error);
  };

  var loadLandUse = function (callback) {
    const groupIdToPass = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    return Network.api("get", "/ms4s/" + groupIdToPass + "/landuse", {
      partner: 0,
      filters: filters,
    })
      .done(callback)
      .fail(error);
  };

  var getAllParcels = function (callback) {
    var year = AnnualReports.getSelectedYear();
    const groupIdToPass = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var xhr1 = Network.api("get", "/ms4s/" + groupIdToPass + "/ms4-parcels", {
      partner: 0,
      groupId: groupIdToPass,
      filters: filters,
    });
    var xhr2 = Network.api("get", "/ms4s/" + groupIdToPass + "/conditions", {
      partner: 0,
      year: year,
    });
    return $.when(xhr1, xhr2)
      .done(function (resp1, resp2) {
        callback(resp1[0], resp2[0]);
      })
      .fail(function (xhr, status, error) {
        console.warn(error);
      });
  };

  var getStormdrains = function (callback) {
    const groupIdToPass = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    return Network.api("get", "/ms4s/" + groupIdToPass + "/stormdrains", {
      partner: 0,
      filters: filters,
    })
      .done(callback)
      .fail(error);
  };

  /**********************Trash Calls***********************/
  var getTrashPoints = function (groupId, filters, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/collector-data", {
      filters: filters,
    })
      .done(callback)
      .fail(error);
  };

  var getAllMS4s = function (callback) {
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.api("get", "/regions/" + groupId + "/ms4s", {
      year: "ALL",
      legacyImplementation: false,
    })
      .done(callback)
      .fail(error);
  };

  var getSubmittedMS4s = function (year, dataModule, callback) {
    const groupId = Tree.get("user", "trueGroupId");
    return Network.api("get", "/regions/" + groupId + "/submitted-ms4s", {
      year: year,
      module: dataModule,
    })
      .done(callback)
      .fail(error);
  };

  /* ***************** Photos **************** */

  var getPhotoList = function (groupId, id, layer, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/" + id + "/photos", {
      layer_name: layer,
    })
      .done(callback)
      .fail(error);
  };

  var savePhotoCaption = function (groupId, id, filename, caption, layer_name, callback) {
    Network.api("patch", "/ms4s/" + groupId + "/caption/" + id + "/photos", {
      filename: filename,
      caption: caption,
      layer_name: layer_name,
    })
      .done(callback)
      .fail(error);
  };

  var deletePhoto = function (layer_name, id, filename, groupId, callback) {
    Network.api("delete", "/ms4s/" + groupId + "/" + layer_name + "/" + id + "/photos/" + filename)
      .done(callback)
      .fail(error);
  };

  var getKpiData = function (
    callback,
    omitFilters,
    onlyAccepted,
    groupId = Session.getGroupIdToPass(),
  ) {
    const params = {
      onlyAccepted,
    };
    if (!omitFilters) {
      params.filters = Tree.get("filters");
    }
    return Network.api("get", "/ms4s/" + groupId + "/report-kpi-data", params)
      .done(callback)
      .fail(error);
  };

  var getSbmpKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/sbmp-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getMuniBmpKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/muni-bmp-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getFcsKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/fcs-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getMuniCatchBasinKpi = function (callback, loadingScreen = false) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.cacheFirstApi(
      "get",
      "/ms4s/" + groupId + "/muni-catch-basin-kpi",
      {
        filters,
        year,
      },
      Network.noLoadingScreenWhenFalse(loadingScreen),
    )
      .done(callback)
      .fail(error);
  };

  var getConstructionKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/construction-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getActivityKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/activity-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getFacilityKpi = async function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();
    const dataView = Tree.get("dataView");

    return Network.api("get", "/ms4s/" + groupId + "/facility-kpi", {
      filters,
      year,
      dataView,
    })
      .done(callback)
      .fail(error);
  };

  var getStreetSweepingKpi = async function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/street-sweeping-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getAssetManagentData = function (callback) {
    const groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/get-attribution-percentage`, { filters })
        .done(callback)
        .fail(error),
    );
  };

  var getToolSettings = function () {
    const groupId = Tree.get("rootGroup", "groupId");
    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/tool-settings"));
  };

  var getLidKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/lid-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getIddeKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/idde-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getMuniOutfallKpi = function (callback) {
    var groupId = Session.getGroupIdToPass();
    var filters = Tree.get("filters");
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", "/ms4s/" + groupId + "/muni-outfall-kpi", {
      filters,
      year,
    })
      .done(callback)
      .fail(error);
  };

  var getAllSubmittedMs4sKpiData = function (callback, omitFilters) {
    const groupId = Tree.get("activeGroup", "groupId");
    const params = {};
    if (!omitFilters) {
      params.filters = Tree.get("filters");
    }
    Network.api("get", "/ms4s/" + groupId + "/all-report-kpi-data", params)
      .done(callback)
      .fail(error);
  };

  var getAnnualReportingYears = function (groupId, callback) {
    const submittedOnly = Progeny.rootGroupIsRegulator();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/annual-reports", {
        submittedOnly,
      }),
    );
  };

  var editAnnualReport = function (formData) {
    const groupId = Tree.get("activeGroup", "groupId");

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/annual-reports", { formData }),
    );
  };

  var getAnnualReporting = function (groupId, dataModule, includePostSubmittalBuffer, callback) {
    const onlyAccepted = !Progeny.canViewUnsubmittedDataForGroup(groupId);

    return Network.api("get", "/ms4s/" + groupId + "/annual-reporting", {
      module: dataModule,
      onlyAccepted: onlyAccepted,
      includePostSubmittalBuffer: includePostSubmittalBuffer,
    })
      .done(callback)
      .fail(error);
  };

  var getGlobalGroupSettings = function () {
    const groupId = Tree.get("activeGroup", "groupId");

    return Network.asPromise(Network.api("get", "/ms4s/" + groupId + "/group-settings"));
  };

  var editGlobalGroupSettings = function (formData) {
    const groupId = Tree.get("activeGroup", "groupId");

    return Network.asPromise(
      Network.api("post", "/ms4s/" + groupId + "/group-settings", { formData }),
    );
  };

  var getReductionPercentages = function (year, groupId, callback) {
    return Network.api("get", "/ms4s/" + groupId + "/reduction-percentages", {
      year: year,
    })
      .done(callback)
      .fail(error);
  };

  var submitReports = function (data) {
    const groupId = Tree.get("activeGroup", "groupId");

    return Network.asPromise(Network.apiPostWithFormData(`/ms4s/${groupId}/submit-reports`, data));
  };

  var runTelrForGroup = function (groupId, year) {
    const scenarioType = Tree.get("tool") == "scenario" ? "Potential" : "Mitigated";
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/run-telr`, {
        year: year,
        group_id: groupId,
        scenario_type: scenarioType,
      }),
    );
  };

  var unsubmitReports = function (groupId, year) {
    return Network.asPromise(
      Network.api("post", `/ms4s/${groupId}/unsubmit-reports`, {
        year: year,
      }),
    );
  };

  var rotateImage = function (layerName, layerId, photoId, direction, callback) {
    const groupId = sessionStorage.activeGroupId;
    Network.api("post", "/ms4s/" + groupId + "/rotate-image", {
      layerName,
      layerId,
      photoId,
      direction,
    })
      .done(callback)
      .fail(error);
  };

  var getTelrResults = function (year, runoffOrParticulates, mitigationType, callback) {
    var groupId = Session.getGroupIdToPass();
    var onlyAccepted = !Progeny.canViewUnsubmittedDataForGroup(groupId);
    Network.api("get", "/ms4s/" + groupId + "/catchment-results-update", {
      mitigationType: mitigationType,
      runoffOrParticulates: runoffOrParticulates,
      onlyAccepted: onlyAccepted,
      year: year,
      partner: 0,
    })
      .done(callback)
      .fail(error);
  };

  var getTelrKpi = function (callback, matrix, groupId = Session.getGroupIdToPass()) {
    var onlyAccepted = !Progeny.canViewUnsubmittedDataForGroup(groupId);
    var runoffType = TelrLayerFunctions.getRunoffType();
    var selectedYear = AnnualReports.getSelectedYear();
    var filters = Tree.get("filters");
    return Network.api("get", "/ms4s/" + groupId + "/catchment-results/" + selectedYear, {
      onlyAccepted,
      runoffType,
      partner: 0,
      filters,
      isReport: 1,
      matrix,
    })
      .done(callback)
      .fail(error);
  };

  var getSbmpProgress = function (filters, callback) {
    const year = AnnualReports.getSelectedYear();
    const groupIdToPass = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupIdToPass + "/sbmp-progress", {
      year: year,
      filters: filters,
      noSpatialFiltering: true,
    })
      .done(callback)
      .fail(error);
  };

  var getSbmpProgressDrainage = function (filters, callback) {
    const year = AnnualReports.getSelectedYear();
    const groupIdToPass = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupIdToPass + "/sbmp-progress-drainage", {
      year: year,
      filters: filters,
    })
      .done(callback)
      .fail(error);
  };

  var getFcsProgress = function (filters, callback) {
    const year = AnnualReports.getSelectedYear();
    const groupIdToPass = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupIdToPass + "/fcs-progress", {
      year: year,
      filters: filters,
      noSpatialFiltering: true,
    })
      .done(callback)
      .fail(error);
  };

  var getFcsProgressDrainage = function (filters, callback) {
    const year = AnnualReports.getSelectedYear();
    const groupIdToPass = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupIdToPass + "/fcs-progress-drainage", {
      year: year,
      filters: filters,
    })
      .done(callback)
      .fail(error);
  };

  var getConstructionProjectProgress = function (filters, callback) {
    const year = AnnualReports.getSelectedYear();
    const groupIdToPass = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupIdToPass + "/construction-projects", {
      year: year,
      filters: filters,
      noSpatialFiltering: true,
      isReport: true,
    })
      .done(callback)
      .fail(error);
  };

  var getConstructionProjectAreaProgress = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();
    var params = {
      isReport: true,
      year: year,
      filters: filters,
    };
    return Network.api("get", "/ms4s/" + groupId + "/construction-project-areas", params)
      .done(callback)
      .fail(error);
  };

  var getMuniCatchBasinPopup = function (idbmp) {
    var groupId = Session.getGroupIdToPass();
    var year = AnnualReports.getSelectedYear();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/muni-catch-basin-report-popup`, { idbmp, year }),
    );
  };

  var getConstructionProjectHistory = function (constructionProjectId, callback) {
    var groupId = Session.getGroupIdToPass();
    var year = AnnualReports.getSelectedYear();

    return Network.api("get", `/ms4s/${groupId}/project-history`, {
      constructionProjectId,
      year,
      isReport: true,
    })
      .done(callback)
      .fail(error);
  };

  var getLidProjectProgress = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    const params = {
      filters: filters,
      year: year,
      noSpatialFiltering: true,
      isReport: true,
    };
    return Network.api("get", "/ms4s/" + groupId + "/lid-projects", params)
      .done(callback)
      .fail(error);
  };

  var getLidProjectAreaProgress = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    var params = {
      filters: filters,
      isReport: true,
      year: year,
    };
    return Network.api("get", "/ms4s/" + groupId + "/lid-project-areas", params)
      .done(callback)
      .fail(error);
  };

  var getOutfallProgress = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    var params = {
      filters: filters,
      isReport: true,
      year: year,
      noSpatialFiltering: true,
    };

    return Network.api("get", "/ms4s/" + groupId + "/muni-outfalls", params)
      .done(callback)
      .fail(error);
  };

  var getIncidentProgress = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    var params = {
      filters: filters,
      isReport: true,
      year: year,
      noSpatialFiltering: true,
    };

    return Network.api("get", `/ms4s/${groupId}/incidents`, params).done(callback).fail(error);
  };

  var getIncidentPopup = function (id) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/incident-popup`, { id, year }));
  };

  var getHighPriorityAreaLayer = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();

    return Network.api("get", `/ms4s/${groupId}/high-priority-area`).done(callback).fail(error);
  };

  var getMuniCatchBasinProgress = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    const params = {
      filters: filters,
      year: year,
      noSpatialFiltering: true,
      isReport: true,
    };

    return Network.cacheFirstApi("get", "/ms4s/" + groupId + "/muni-bmps", params)
      .done(callback)
      .fail(error);
  };

  var getMuniCatchBasinProgressDrainage = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    const params = {
      filters: filters,
      year: year,
      isReport: true,
    };

    return Network.api("get", "/ms4s/" + groupId + "/muni-bmp-drainages", params)
      .done(callback)
      .fail(error);
  };

  var getFlowRouting = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/flow-routing", { filters })
      .done(callback)
      .fail(error);
  };

  var getOutfalls = function (filters, callback) {
    const groupId = Session.getGroupIdToPass();
    return Network.api("get", "/ms4s/" + groupId + "/outfalls", { filters })
      .done(callback)
      .fail(error);
  };

  var getOutfallPopup = function (id) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/outfall-popup`, { id, year }));
  };

  var getOverviewKpi = function (groupId, year) {
    if (!groupId) {
      groupId = Session.getGroupIdToPass();
    }
    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/kpi-overview`, {
        year: year,
        constructionFilters: FilterConstants.constructionProjectProgressFilters,
        lidFilters: FilterConstants.lidProjectProgressFilters,
        muniCatchBasinFilters: FilterConstants.getMuniCatchBasinProgressFilters(),
      }),
    );
  };

  var getRegionalOverviewKpi = function (year) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/kpi-overview-regional`, {
        year: year,
        constructionFilters: FilterConstants.constructionProjectProgressFilters,
        lidFilters: FilterConstants.lidProjectProgressFilters,
        muniCatchBasinFilters: FilterConstants.getMuniCatchBasinProgressFilters(),
      }),
    );
  };

  var getRegionalPermitteeOverviewKpi = function (year) {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(
      Network.api("get", `/ms4s/${groupId}/kpi-overview-regional-permittee`, {
        year: year,
      }),
    );
  };

  var updateReportLinkSummary = function (annualReportId, linkSummaryUpdates, callback) {
    const groupId = Session.getGroupIdToPass();

    return Network.api("post", "/ms4s/" + groupId + "/update-report-link-summary", {
      annualReportId,
      linkSummaryUpdates,
    })
      .done(callback)
      .fail(error);
  };

  var getMaintenanceZones = function (filters) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/maintenance-zones", {
        filters: filters,
        year: AnnualReports.getSelectedYear(),
      }),
    );
  };

  var getProjectPopup = function (constructionProjectId) {
    const groupId = Session.getGroupIdToPass();
    const year = AnnualReports.getSelectedYear();

    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/project-popup", { constructionProjectId, year }),
    );
  };

  var getExistingUserData = function (userId) {
    return Network.asPromise(Network.api("get", `/user`, { userId }).fail(error));
  };

  var resendInvite = function (user, callback, errorCallback) {
    Network.api("post", "/resend-invite", { user: encodeURIComponent(user) })
      .done(callback)
      .fail(errorCallback);
  };

  var getEsriLayerToken = function (server = "modeling") {
    var groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", "/ms4s/" + groupId + "/map-layer/" + server + "/token", {}),
    );
  };

  var getHighways = function () {
    const groupId = Session.getGroupIdToPass();

    return Network.asPromise(Network.api("get", `/ms4s/${groupId}/highways`, {}));
  };

  var getAlias = function (userId) {
    return Network.asPromise(Network.api("get", "/alias", { userId }));
  };

  var selectAlias = function (aliasId) {
    const token = TokenManager.loadToken();
    return Network.asPromise(Network.api("post", "/user", { aliasId, token }));
  };

  return {
    createNewUser,
    deletePhoto,
    deleteUser,
    editAnnualReport,
    editGlobalGroupSettings,
    getAllMS4s,
    getAllParcels,
    getAllSubmittedMs4sKpiData,
    getAnnualReporting,
    getAnnualReportingYears,
    getBoundary,
    getCatchments,
    getTrashPoints,
    getConstructionKpi,
    getActivityKpi,
    getConstructionProjectAreaProgress,
    getConstructionProjectHistory,
    getConstructionProjectProgress,
    getEsriLayerToken,
    getExistingUserData,
    getFcsKpi,
    getFcsProgress,
    getFcsProgressDrainage,
    getFlowRouting,
    getToolSettings,
    getGlobalGroupSettings,
    getGroupUsers,
    getHighPriorityAreaLayer,
    getIddeKpi,
    getMuniOutfallKpi,
    getIncidentPopup,
    getIncidentProgress,
    getKpiData,
    getLidKpi,
    getLidProjectAreaProgress,
    getLidProjectProgress,
    getMaintenanceZones,
    getMapDrainageLayer,
    getMuniBmpKpi,
    getMuniCatchBasinPopup,
    getMuniCatchBasinKpi,
    getMuniCatchBasinProgress,
    getMuniCatchBasinProgressDrainage,
    getOutfalls,
    getOutfallPopup,
    getOverviewKpi,
    getPhotoList,
    getPossibleContactCompanies,
    getProjectPopup,
    getReceivingWaters,
    getReductionPercentages,
    getRegionalOverviewKpi,
    getRegionalPermitteeOverviewKpi,
    getSbmpKpi,
    getSbmpProgress,
    getSbmpProgressDrainage,
    getStormdrains,
    getSubmittedMS4s,
    getTelrKpi,
    getTelrResults,
    getToolsEnabledForUser,
    getUrbanDrainageStatus,
    loadLandUse,
    resendInvite,
    rotateImage,
    runTelrForGroup,
    savePhotoCaption,
    sendPassword,
    submitReports,
    unsubmitReports,
    updateExistingUser,
    updateReportLinkSummary,
    updateUsername,
    getHighways,
    getFacilityKpi,
    getStreetSweepingKpi,
    getAssetManagentData,
    getOutfallProgress,
    getAlias,
    selectAlias,
  };
};

module.exports = ReportApiCalls();

const AnnualReports = require("./annualReports");
const FilterConstants = require("./mapFunctions/filterConstants");
const Progeny = require("../login/progeny");
const Session = require("../login/session");
const TokenManager = require("../login/tokenManager");
const TelrLayerFunctions = require("./telr/telrLayerFunctions");
const Tree = require("../tree");
const Network = require("../network");
