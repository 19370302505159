"use strict";

const ScenariosLayer = function () {
  const ApiCalls = require("../apiCalls");
  const MapLayer = require("../mapFunctions/mapLayer");

  const layerName = "scenarios";
  const layerKey = "scenariosLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    scenariosLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    scenariosLayer.loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    if (mapId === "main") {
      _loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  var getScenarioData = async function () {
    return await ApiCalls.getUnfilteredScenarios();
  };

  const scenariosLayer = new MapLayer(layerName, layerKey, getScenarioData, {
    clusteringEnabled: true,
    itemCountEnabled: true,
  });

  var _loadTreeUpdateListenersForMap = function (map, mapLayers) {
    Tree.select("filters").on("update", async function () {
      if (Tree.get("dataView") === "scenarios") {
        loadLayer("main", false);
      }
    });
    Tree.select("activeTab").on("update", async function () {
      if (Tree.get("dataView") === "scenarios") {
        Filters.renderDataSortDropdown();
      }
    });
  };

  var loadLayer = async function (mapId, refreshData = true) {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var filters = Actions.getFiltersByMapId(mapId);

    ApiCalls.checkspatialboxes(filters);

    Tree.set(["layers", layerName, "isFetching"], true);

    const resource = ResourceController.get(layerName);
    const data = await resource.getAll({ filters, refreshData });
    Tree.set(["layers", layerName, dataPath], data);

    Tree.set(["layers", layerName, "isFetching"], false);
    if (mapId !== "modal") {
      const count = Tree.get(["layers", "scenarios", "data"]).length;
      Actions.setItemCount(count);
    }
  };

  return {
    ...scenariosLayer,
    loadLayerListenersForMap,
  };
};

module.exports = ScenariosLayer();

const Actions = require("../actions");
const Filters = require("../mapFunctions/filters");
const ResourceController = require("../offline/resourceController");
const Tree = require("../tree");
