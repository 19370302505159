"use strict";

const OutfallRoutineMaintenanceCondition = function () {
  const stringKey = "condition";
  let $modalTab;

  var loadListeners = function () {
    $modalTab = $(`#assessment-modal #${stringKey}`);
    $modalTab.on("2N:FormInput", `.fieldset [type="radio"]`, toggleDropdowns);
    $modalTab.on(
      "2N:FormInput",
      `[name="condition-info[maintenance-needed]"]`,
      toggleSiblingFieldsets,
    );
    RoutineMaintenanceFormTable.loadListeners($modalTab);
  };

  var unloadListeners = function () {
    $modalTab.off("2N:FormInput", `.fieldset [type="radio"]`);
    $modalTab.off("2N:FormInput", `[name="condition-info[maintenance-needed]"]`);
    RoutineMaintenanceFormTable.unloadListeners($modalTab);
  };

  var getProps = function () {
    return {
      trashRemovalVolumeUnitOptions: RoutineMaintenanceConstants.trashRemovalVolumeUnitOptions,
      damageDescriptionOptions: OutfallInspectionConstants.damageDescriptionOptions,
      cloggingMaterialOptions: OutfallInspectionConstants.cloggingMaterialOptions,
      stainsDescriptionOptions: OutfallInspectionConstants.stainsDescriptionOptions,
      vegetationGrowthDescriptionOptions:
        OutfallInspectionConstants.getVegetationGrowthDescriptionOptions(),
      biologicalGrowthDescriptionOptions:
        OutfallInspectionConstants.biologicalGrowthDescriptionOptions,
      activeErosionDescriptionOptions: OutfallInspectionConstants.activeErosionDescriptionOptions,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormTable.getConditionTabName();
  };

  var toggleDropdowns = function (e, value) {
    const dropdowns = $(e.currentTarget).parents(".fieldset").find(".autonumeric, select");
    dropdowns.toggle(value);
  };

  var toggleSiblingFieldsets = function (e, value) {
    const siblingFields = $(e.currentTarget).parents("fieldset").siblings();
    siblingFields.toggleClass("hidden", !value);
  };

  return { loadListeners, unloadListeners, getStringKey, getTabHeader, getProps };
};

module.exports = OutfallRoutineMaintenanceCondition();

const OutfallInspectionConstants = require("../outfallInspectionConstants");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const RoutineMaintenanceConstants = require("../../bmpfcs/routineMaintenanceConstants");
