"use strict";

const Home = function () {
  var initialize = function () {
    Tree.set("tool", "home");
    _addCityName();
    _renderBody();
    _addListeners();
  };

  var _addListeners = function () {
    $("#content").on("click", ".open-tool-popup", _triggerToolPopup);
  };

  var _triggerToolPopup = function (e) {
    e.preventDefault();
    const tool = $(this).data("tool");
    _renderToolPopup(tool);
  };

  var _renderToolPopup = function (tool) {
    var descriptions = ToolDescriptions.get()[tool];
    if (!descriptions) throw Error(`No tool ${tool} in toolDescriptions.json`);

    var details = {
      tool,
      ...descriptions,
      comingSoon: _isComingSoon(tool),
    };
    $(".modal-content").attr("data-tool", tool);
    $("#toolInfoModal").modal("show");
    const htmlContent = nunjucks.render("modals/home/toolInfoModalBody.njk", details);
    $("#toolInfoModalBody").html(htmlContent);
  };

  var _isComingSoon = function (tool) {
    const config = ToolConfig.get(true)[tool];
    if (!config) return false;
    return config.comingSoon;
  };

  var _addCityName = function () {
    const groupName = Tree.get("activeGroup", "name");
    $(".city-name").text(groupName);
  };

  var _renderBody = function () {
    $("#content-container #content").html(
      nunjucks.render("home/content.njk", {
        groupLogo: ToolSettings.getSetting("group", "groupLogo"),
        welcomeMessage: _getWelcomeMessage(),
        toolsByCategory: _getToolsByCategory(),
      }),
    );
  };

  var _getWelcomeMessage = function () {
    const userFullName = Tree.get(["user", "full_name"]);
    const aliasList = ToolSettings.getSetting("alias");
    if (Session.isLoggedIn()) {
      const displayRole = UserPermissions.getPermission("display_role");
      const city = Tree.get(["activeGroup", "name"]);
      return aliasList?.length > 1
        ? `Hello ${userFullName}! ${displayRole} in ${city}`
        : `Hello ${userFullName}!`;
    } else {
      return "Welcome to 2NDNATURE!";
    }
  };

  var _getToolsByCategory = function () {
    const portalSubscriberTools = ToolSettings.getSetting("group", "portalSubscriberTools");
    const isUserAdmin = UserPermissions.getPermission("user_role") === "admin";
    const forHome = true;
    const homeTools = ToolFunctions.getToolsInOrder(forHome);
    const categorized = {};

    for (const tool in homeTools) {
      const toolData = homeTools[tool];
      const homeCategory = toolData.homeCategory;
      if (homeCategory === undefined) continue;

      if (!categorized[homeCategory]) categorized[homeCategory] = [];
      const internalName = toolData.internalName;

      categorized[homeCategory].push({
        name: toolData.name,
        description: toolData.description,
        daysToGo: toolData.daysToGo,
        internalName: internalName,
        href: _getHref(internalName),
        classes: _getClasses(toolData),
        showPortalBadge: isUserAdmin && portalSubscriberTools.includes(internalName),
      });
    }
    return categorized;
  };

  var _getHref = function (tool) {
    return Config.get().toolLinks[tool];
  };

  var _getClasses = function (toolData) {
    const tool = toolData.internalName;
    const classes = ["home-btn"];
    const currentUser = Tree.get("user");
    const showComingSoon =
      (currentUser.is_2n_admin && currentUser.user_role === "admin") || !Session.isLoggedIn();
    if (toolData.comingSoon) {
      if (showComingSoon) {
        classes.push("coming-soon");
        classes.push("open-tool-popup");
      } else {
        classes.push("hidden");
      }
    } else if (Session.isLoggedIn()) {
      if (!_groupHasAccess(tool)) {
        classes.push("open-tool-popup");
        classes.push("locked");
      }
      if (!_userHasAccess(tool)) {
        classes.push("hidden");
      }
    } else {
      classes.push("open-tool-popup");
    }

    return classes.join(" ");
  };

  var _groupHasAccess = function (tool) {
    const enabledTools = _addPieIfReportEnabled(ToolFunctions.getToolsEnabledForGroup());
    return Object.values(enabledTools).includes(tool);
  };

  var _userHasAccess = function (tool) {
    let enabledTools = ToolFunctions.getUserToolsToDisplay();
    if (Tree.get("user", "is_2n_admin")) {
      enabledTools.push("report");
    }
    if (Tree.get("user", "user_role") === "admin") {
      enabledTools = _addPieIfReportEnabled(enabledTools);
    }
    return Object.values(enabledTools).includes(tool);
  };

  var _addPieIfReportEnabled = function (tools) {
    if (tools.includes("report")) {
      tools.push("pie");
    }
    return tools;
  };

  var getAnnualReportCountDown = function () {
    if (Tree.get("tool") !== "home" || !Session.isLoggedIn()) return;
    let daysToGo = "";
    const reports = Tree.get("annualReportingSettings");
    if (!reports || !reports.length) {
      return 0;
    }

    const stormwaterGroupTask = reports.find((report) => report.dataName === "stormwater group");
    const stormwaterGroupDeadline = !stormwaterGroupTask
      ? _getADeadline(reports)
      : stormwaterGroupTask?.deadline;

    daysToGo = stormwaterGroupDeadline
      ? SubmitReport.calcDaysToGo(stormwaterGroupDeadline)
      : daysToGo;

    return daysToGo;
  };

  var _getADeadline = function (reports) {
    for (let i = 0; i < reports?.length; i++) {
      if (reports[i].includeDataInReport === true) {
        return reports[i].deadline;
      }
    }
  };

  var getAnnualReportDescription = function () {
    // return Session.isLoggedIn() ? " days to submit the annual report" : "Submit the annual report";
    return "Lock and view annual records";
  };

  return {
    initialize,
    _getToolsByCategory,
    _renderToolPopup,
    _getClasses,
    getAnnualReportCountDown,
    getAnnualReportDescription,
    _getADeadline,
  };
};

module.exports = Home();

const Config = require("./config");
const ToolDescriptions = require("./home/toolDescriptions");
const Session = require("./login/session");
const UserPermissions = require("./login/userPermissions");
const ToolFunctions = require("./toolFunctions");
const ToolConfig = require("./config/toolConfig");
const Tree = require("./tree");
const ToolSettings = require("./settings/toolSettings");
const SubmitReport = require("./report/report/submitReport");
