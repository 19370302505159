"use strict";

const MuniCatchBasinIcon = function () {
  var getIconMarker = function (score, isDue, isPriority) {
    const iconClass = getIconClass(score, isDue, isPriority);
    const zoom = ReportMap.getActiveReportMap()?.getZoom();
    const updatedIconSizeOptions = RamMapFunctions.resizeIconsOnZoom(zoom, 30, 12);

    return new L.DivIcon({
      className: iconClass,
      iconSize: FeatureFlag.enabled("annual-report-muni-catch-basin-tko")
        ? updatedIconSizeOptions.size
        : [10, 10],
    });
  };

  var getIconClass = function (score, isDue, isPriority) {
    const classParts = ["catch-basin"];
    const condition = MapStyles.getConditionFromScore(score);

    if (condition !== null) {
      classParts.push(condition === "poor" ? "poor" : "optimal");
    }

    if (isPriority) {
      classParts.push("priority");
    }

    return classParts.join("-");
  };

  var getIconColor = function (score, isDue, hex = false) {
    var color = MapStyles.getConditionColor(score, hex);
    return color;
  };

  var getCircleMarkerColor = function (data) {
    const color = getIconColor(data.score, data.routineMaintenanceDue, true);

    return color;
  };

  return {
    getIconMarker,
    getCircleMarkerColor,
    getIconClass,
    getIconColor,
  };
};

module.exports = MuniCatchBasinIcon();

const MapStyles = require("../mapFunctions/mapStyles");
const RamMapFunctions = require("../../mapFunctions/mapFunctions");
const ReportMap = require("../report/reportMap");
const FeatureFlag = require("../../settings/featureFlag");
