const DryWeatherCatchBasinGeoServerLayer = function () {
  const layerName = "dryWeatherCatchBasin";
  const layerKey = "dryWeatherCatchBasinLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const layer = new GeoServerMapLayer(layerName, layerKey);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, layer);

    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
  };
};

module.exports = DryWeatherCatchBasinGeoServerLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
