"use strict";

const InsightDashboardController = function (config) {
  const filterFormKey = "insight-filter";
  var $insightContainer;
  var $dashboardContainer;
  var insightData;
  var $insightFilterModal;
  var $insightHeadingContainer;
  var $insightFilterContainer;

  var init = function () {
    $insightContainer = $(".insight-container");
    $dashboardContainer = $insightContainer.find(".dashboard-container");
    $insightFilterModal = $("#insight-filter-modal");
    $insightHeadingContainer = $insightContainer.find(".insight-heading-container");
    $insightFilterContainer = $insightContainer.find(".insight-filter-container");

    loadDomListeners();

    clearInsightContainer();
    renderFilterMenu();
  };

  var loadDomListeners = function () {
    $(window).on("resize", onWindowResize);

    $insightContainer.off("click", ".widget-export-button", exportButtonClick);
    $insightContainer.on("click", ".widget-export-button", exportButtonClick);

    if (config.hasFilter) {
      $insightContainer.off("click", ".insight-filter-button", mobileFilterButtonClick);
      $insightContainer.on("click", ".insight-filter-button", mobileFilterButtonClick);

      $insightFilterModal.off("click", ".save-button", saveFilter);
      $insightFilterModal.on("click", ".save-button", saveFilter);

      $insightFilterModal.off("click", ".close-button", closeFilterModal);
      $insightFilterModal.on("click", ".close-button", closeFilterModal);
    }
  };

  var render = async function (getDataParams) {
    const dataView = Tree.get("dataView");
    if (Tree.get(["insightDataIsFetching", dataView])) return;
    if (Tree.get("activeTab") !== "insight") return;

    Tree.set(["insightDataIsFetching", dataView], true);
    insightData = await config.getInsightDataFunction(getDataParams);
    Tree.set(["insightDataIsFetching", dataView], false);
    Tree.set(["currentInsightData", dataView], insightData);

    $dashboardContainer.css("min-height", $dashboardContainer.height()).empty();

    if (config.sideTable) {
      $insightContainer.find(".main-container").addClass("side-table");
      $("#details-button-container").addClass("insight-with-table");
      $("#details-button-container").removeClass("default-hidden");
    }

    renderHeading();
    if (config.hasDashboardType) {
      renderFilterMenu();
    }

    config.widgets.forEach((widget) =>
      WidgetHandler.render($dashboardContainer, widget, insightData),
    );

    if (config.hasFilter && config.getMobileFilterButtonTextFunction) {
      const buttonText = config.getMobileFilterButtonTextFunction();
      $(".insight-filter-button").find(".button-text").text(buttonText);
    }

    if (!FeatureFlag.enabled("insight-widget-export")) {
      $(".widget-export-button").hide();
    }
  };

  var onWindowResize = function () {
    if (Tree.get("activeTab") !== "insight") return;
    $dashboardContainer.css("min-height", "initial");
    config.widgets.forEach((widget) => {
      if (widget.widgetConfig.isHidden && widget.widgetConfig.isHidden(insightData)) {
        return;
      }

      if (widget.renderChart) {
        widget.renderChart(insightData);
        widget.renderChart(insightData, true);
      }
      if (widget.widgetConfig.hovers?.length) {
        widget.widgetConfig.hovers.forEach((hover) => {
          widget.getWidgetElement().find(`.${hover.className}`).addClass("hover-target");
        });
      }
    });
  };

  var exportButtonClick = function () {
    const widgetId = $(this).closest(".insight-widget-container").data("widgetId");

    var currentWidget = config.widgets.find((widget) => widget.widgetId === widgetId);

    if (currentWidget) {
      var modalContentHtml = currentWidget.getHtml(insightData);
      var modalTitle = currentWidget.widgetConfig.title;

      var html = nunjucks.render("insight/insightWidgetModal.njk", {
        widgetId,
        modalTitle,
        modalContentHtml,
      });

      var $modalContainer = $("#insight-widget-export-modal");
      $modalContainer.html(html);
      $modalContainer.modal("show");

      if (currentWidget.renderChart) {
        currentWidget.renderChart(insightData, true);
      }
    }
  };

  var renderFilterMenu = function () {
    if (!config.hasFilter) {
      $insightFilterContainer.hide();
      return;
    }
    var filterMenuHtml = config.getFilterMenuHtmlFunction();
    var mobileFilterButtonHtml = nunjucks.render("insight/insightMobileFilterButton.njk", {
      showFilterButton: config.showFilterButton,
    });
    $insightFilterContainer.html(filterMenuHtml);
    $insightFilterContainer.find("fieldset").addClass("insight-desktop-only");
    $insightFilterContainer.append(mobileFilterButtonHtml);
  };

  var renderHeading = function () {
    if (!config.hasHeading) {
      $insightHeadingContainer.hide();
      return;
    }
    var headingHtml = config.getHeadingHtmlFunction();
    $insightHeadingContainer.html(headingHtml);
  };

  var mobileFilterButtonClick = function () {
    var filterMenuHtml = config.getFilterMenuHtmlFunction();
    var modalHtml = nunjucks.render("insight/insightFilterModal.njk", {
      modalContentHtml: filterMenuHtml,
    });
    Form.initializeAndLoadListeners($insightFilterModal, filterFormKey);

    $insightFilterModal.html(modalHtml);
    $insightFilterModal.modal("show");
  };

  var saveFilter = function () {
    var formData = Form.getDataFromForm(filterFormKey);
    config.saveFilterFunction(formData);
    closeFilterModal();
  };

  var closeFilterModal = function () {
    Form.clearForm(filterFormKey);
    $insightFilterModal.modal("hide");
  };

  var setConfigWidgets = function (widgets) {
    config.widgets = widgets;
  };

  var clearInsightContainer = function () {
    $dashboardContainer.empty();
    $dashboardContainer.css("min-height", "initial");
  };

  return {
    init,
    render,
    setConfigWidgets,
    clearInsightContainer,
  };
};

module.exports = InsightDashboardController;

const FeatureFlag = require("../../settings/featureFlag");
const Form = require("../form");
const Tree = require("../../tree");
const WidgetHandler = require("./widgetHandler");
