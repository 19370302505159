"use strict";

var Outfalls = function (
  layerName,
  layerKey,
  iconFunction,
  getDataFunction,
  clusteringEnabled = false,
) {
  var addOutfallsLayer = function (map, mapLayers, data) {
    if (mapLayers[layerKey]) {
      map.removeLayer(mapLayers[layerKey]);
    }
    mapLayers[layerKey] = createLayer(data);
    mapLayers[layerKey].on("popupopen", function (e) {
      // @TODO: Work on photos later
      // if (e.layer && e.layer.feature) {
      //   PopupPhotos.load(e.layer.feature.properties);
      // }
    });
    mapLayers[layerKey].addTo(map);
    return mapLayers[layerKey];
  };

  var createLayer = function (data) {
    const RotationLayer = L.GeoJSON.extend({
      options: {
        pointToLayer: function (feature, latlng) {
          const marker = iconFunction(
            latlng,
            feature.properties.rotationAzimuth,
            feature.properties,
          );
          return marker;
        },
        onEachFeature: function (feature, layer) {
          layer.bindPopup(_getOutfallsPopupHtml(feature), getPopupOptions());
          layer.on({
            popupopen: _onOutfallPopupOpen,
          });
        },
      },
    });
    var layer = new RotationLayer();
    layer.addData(data);

    return layer;
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();

    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  var startOutfallZoomListener = function (map, mapLayers, mapId) {
    map.on("zoomend", function () {
      const isEnabled = Tree.get(["layers", mapId, layerName, "isEnabled"]);
      if (map.getZoom() <= 12) {
        if (mapLayers[layerKey] && isEnabled) {
          map.removeLayer(mapLayers[layerKey]);
        }
      } else if (isEnabled) {
        map.addLayer(mapLayers[layerKey]);
      }
    });
  };

  var _getOutfallsPopupHtml = function (data) {
    data.isOutfall = layerName !== "flowRouting";

    return nunjucks.render("report/popups/outfallsPopup.njk", {
      featureProperties: data,
      showDryInspection: true,
      showWetInspection: true,
    });
  };

  var _onOutfallPopupOpen = async function (e) {
    const id = e.sourceTarget.feature.properties.id;
    var data = await ReportApiCalls.getOutfallPopup(id);

    const html = _getOutfallsPopupHtml(data);
    e.popup.setContent(html);
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerDataUpdated(Tree, mapId, layerName, function (data) {
      const geoJson = CleanData.cleanGeoJSON(data);
      mapLayers[layerKey] = addOutfallsLayer(map, mapLayers, geoJson);
      startOutfallZoomListener(map, mapLayers, mapId);
      if (clusteringEnabled) {
        const Clustering = require("../mapFunctions/clustering");
        Clustering.setState(layerName);
      }
    });
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");

    MapFunctions.whenLayerToggled(
      mapId,
      layerName,
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers[layerKey] && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers[layerKey]);
          } else {
            loadOutfalls();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers[layerKey])) {
            map.removeLayer(mapLayers[layerKey]);
          }
        }
      },
    );
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    const MapFunctions = require("../mapFunctions/mapFunctions");
    Tree.select("filters").on("update", function () {
      if (
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", mapId, layerName, "isEnabled")
      ) {
        loadOutfalls();
      }
    });
  };

  var loadOutfalls = function () {
    const mapId = Tree.get("mapId");
    const filters = Tree.get("filters");

    Tree.set(["layers", mapId, layerName, "isFetching"], true);
    getDataFunction(filters, function (data) {
      Tree.set(["layers", mapId, layerName, "data"], data);
      Tree.set(["layers", mapId, layerName, "isFetching"], false);
    });
  };

  return {
    loadToggledListenerForMap,
    loadDataUpdatedListenerForMap,
    loadFiltersUpdatedListenerForMap,
    loadOutfalls,
  };
};

module.exports = Outfalls;

const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
const Tree = require("../../tree");
