"use strict";

const EsriLayerPopup = function () {
  var renderCatchmentPopupByLayerDropdown = function (feature) {
    const layerDropdown = LayerDropdown.getSelectedDropdown();
    const catchId = feature.properties.catchid;

    if (layerDropdown === "soilInputs") {
      const catchmentSoilData = SoilInputsLayer.getSoilDataByCatchment()?.[catchId];
      feature.properties.soilData = catchmentSoilData;
    }
    render(feature);
  };

  var render = function (feature) {
    const catchmentPopupTemplate =
      LayerDropdown.getCurrentLayerDropdownProperty("catchmentPopupTemplate");

    if (catchmentPopupTemplate) {
      const html = nunjucks.render(catchmentPopupTemplate, feature.properties);
      $(".popup-catchment").html(html);
    }
  };

  return {
    renderCatchmentPopupByLayerDropdown,
  };
};

module.exports = EsriLayerPopup();

const LayerDropdown = require("./layerDropdown");
const SoilInputsLayer = require("../inputs/layers/soilInputsLayer");
