"use strict";

const LidIcon = function () {
  var getLidProjectMapIcon = function (
    lidProjectData,
    isTodo = false,
    zoom = null,
    size = [24, 24],
    showText = true,
    cssTextClass = "",
    isReport = false,
  ) {
    const sizeArray = [11, 13, 18, 32, 32, 36, 43, 43];
    const iconClassNamesArray = [
      "g2-lid-icon-text-small",
      "g2-lid-icon-text-small",
      "g2-lid-icon-text-medium",
      "g2-lid-icon-text-large",
      "g2-lid-icon-text-large",
      "g2-lid-icon-text-large",
      "g2-lid-icon-text-large",
      "g2-lid-icon-text-large",
    ];

    if (zoom === null) {
      zoom = lidProjectData.zoom != null ? lidProjectData.zoom : Tree.get("zoomLevel");
    }

    const updatedIconProps = MapFunctions.resizeIconsOnZoom(
      zoom,
      40,
      20,
      "icon-text-large",
      "icon-text-medium",
      sizeArray,
      null,
      false,
      false,
      iconClassNamesArray,
    );

    var classNames = ["lid-marker-icon"];
    var colorClass = LidProjectStyles.getMapIconColorClass(lidProjectData, isTodo);
    var fontColor = LidProjectStyles.getLidPerformanceFontColor(lidProjectData, isTodo);
    const iconSvgClass = LidProjectStyles.getNewLidProjectIconClass(lidProjectData, isTodo);
    classNames.push(colorClass);
    cssTextClass = updatedIconProps.textCSSClass ? updatedIconProps.textCSSClass : cssTextClass;
    showText = true;

    return new L.DivIcon({
      className: "construction-marker-icon " + "lid-new " + iconSvgClass,
      iconSize: updatedIconProps.size,
      html:
        cssTextClass && showText
          ? `<span class="${fontColor + " " + updatedIconProps.textCSSClass}"></span>`
          : `<span class="${fontColor}"></span>`,
    });
  };

  var getTierNumber = function (lidProjectData, showText, isReport = false) {
    const todoTab = Tree.get("activeTab") === "todo";
    if (showText && !todoTab && !isReport) {
      return getTierNumberFromG2Data(lidProjectData);
    } else if (showText || todoTab || isReport) {
      return getLidProjectTierNumber(lidProjectData);
    } else {
      return "";
    }
  };

  var getTierNumberFromG2Data = function (data) {
    let { performanceTier } = data;
    performanceTier =
      Tree.get("activeTab") === "todo" && !performanceTier ? data.tierNumber : performanceTier;

    if (performanceTier || performanceTier === 0) {
      return performanceTier;
    } else {
      return "?";
    }
  };

  var getLidProjectTierNumber = function (lidProjectData) {
    if (lidProjectData.performanceTierNumbers) {
      const tierNumbers = lidProjectData.performanceTierNumbers.map(function (tierNumber) {
        return parseInt(tierNumber);
      });

      return Math.max(...tierNumbers);
    }
    return "?";
  };

  var getNewLidProjectMapIcon = function (zoom) {
    const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
    const phase = ProjectInventoryModalController.getLatestConstructionData("phase") || "completed";
    const props = { phase: phase, zoom: zoom };
    return getLidProjectMapIcon(props);
  };

  return {
    getLidProjectMapIcon,
    getLidProjectTierNumber,
    getNewLidProjectMapIcon,
    getTierNumberFromG2Data,
    getTierNumber,
  };
};

module.exports = LidIcon();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const LidProjectStyles = require("./lidProjectStyles");
