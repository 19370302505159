"use strict";

const BootstrapExtensions = function () {
  var run = function () {
    preventMultipleModalBackdrops();
    preventDropDownOverflow();
    stopOverflowIssueWhenListIsSmall();
  };

  var preventMultipleModalBackdrops = function () {
    $(document.body).on("shown.bs.modal", function () {
      if ($(".modal-backdrop").length > 1) {
        $(".modal-backdrop").not(":first").remove();
      }
    });
  };
  var stopOverflowIssueWhenListIsSmall = function () {
    $(document).on("shown.bs.dropdown", ".dropdown", function () {
      var $rows = $(".data-list").find("tbody > tr");
      if ($rows.length > 4) {
        var $lastThree = $(".data-list").find("tbody tr:nth-last-child(-n+3)");
        var $lastThreeDropdowns = $lastThree.find(".actions-col >.dropdown");
        $lastThreeDropdowns.addClass("dropup");
      } else {
        if ($rows.length === 4) {
          var $lastTwo = $(".data-list").find("tbody tr:nth-last-child(-n+2)");
          var $lastTwoDropdown = $lastTwo.find(".actions-col >.dropdown");
          $lastTwoDropdown.addClass("dropup");
        }
        if ($rows.length === 3) {
          var $lastOne = $(".data-list").find("tbody tr:last");
          var $lastOneDropdown = $lastOne.find(".actions-col >.dropdown");
          $lastOneDropdown.addClass("dropup");
          var $firstRow = $(".data-list").find("tbody tr:first");
          var $secondRow = $(".data-list").find("tbody tr:nth-child(2)");
          $firstRow.find("ul").css("position", "static");
          $secondRow.find("ul").css("position", "static");
        }
        if ($rows.length <= 2) {
          $(".actions-col:not(.leave-alone)")
            .find(".dropdown")
            .find("ul")
            .css("position", "static");
        }
      }
    });
  };

  var preventDropDownOverflow = function () {
    $(document)
      .on("shown.bs.dropdown", ".dropdown", _applyDropupClass)
      .on("hidden.bs.dropdown", ".dropdown", function () {
        $(this).removeClass("dropup");
      });
  };

  var _applyDropupClass = function (e) {
    var $dropdown = $(this);
    var $ul = $dropdown.children(".dropdown-menu");
    var $button = $dropdown.children(".dropdown-toggle");
    var $container =
      $dropdown.parents(".data-list-container").length > 0 &&
      $dropdown.parents(".data-list-container").height() > 0
        ? $dropdown.parents(".data-list-container")
        : $(window);

    if (
      _hasMoreSpaceAbove(
        $ul.offset().top - ($container.offset()?.top ?? 0),
        $button.height(),
        $ul.height(),
        $container.scrollTop(),
        $container.height(),
      )
    ) {
      $(this).addClass("dropup");
    }
  };

  var _hasMoreSpaceAbove = function (ulOffsetTop, buttonHeight, ulHeight, scrollTop, windowHeight) {
    var spaceUp = ulOffsetTop - buttonHeight - ulHeight - scrollTop;
    var spaceDown = scrollTop + windowHeight - (ulOffsetTop + ulHeight);

    return spaceDown < 0 && (spaceUp >= 0 || spaceUp > spaceDown);
  };

  return {
    run,
    _hasMoreSpaceAbove,
  };
};

module.exports = BootstrapExtensions();
