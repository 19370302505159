"use strict";

const NewProjectInspectionValidator = function () {
  var getMissingRequiredFields = function (allData) {
    return FormValidator.getEmptyRequiredFieldsInData(
      allData,
      getMissingGeneralInfoFields(allData),
    );
  };

  var getMissingGeneralInfoFields = function (data) {
    var fields = [];

    fields.push({
      fieldName: "inspector-name",
      message: "Inspector name",
    });

    fields.push({
      fieldName: "start-time",
      message: "Inspection date & time",
    });

    fields.push({
      fieldName: "inspection-type",
      message: "Inspection type",
    });

    return fields;
  };

  return {
    getMissingRequiredFields,
  };
};

module.exports = NewProjectInspectionValidator();

const FormValidator = require("../../general/formValidator");
