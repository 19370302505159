"use strict";

const IncidentInventoryGeneralInfo = function () {
  const stringKey = "incident-general-info";
  const headerInformation = "General info";
  var $page;
  var $inventoryModal = $("#inventory-modal");

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function () {
    const incident = InventoryModal.getAllData("incident-inventory");

    await renderGeneralInfoHtml(incident);
    loadListeners();
  };

  var initializeDropzones = function (files) {
    const existingFiles = getExistingFiles();

    Form.initializeDropzone("incident-inventory", $inventoryModal, {
      newFiles: getNewFiles(),
      existingFiles: existingFiles,
      maxNumberFiles: 9,
      downloadCallback: ApiCalls.downloadIncidentFile,
    });
  };

  var getExistingFiles = function () {
    const files = IncidentInventoryModalController.getExistingData(["additionalFiles"]) ?? [];
    const removedFiles =
      IncidentInventoryModalController.getLatestIncidentInventoryData([
        "trashedFiles",
        "additionalFiles",
      ]) ?? [];
    return files.filter((file) => !removedFiles.includes(file));
  };

  var getNewFiles = function () {
    return Form.getFormDataAtPath("incident-inventory", ["additionalFiles"]);
  };

  var renderGeneralInfoHtml = async function (incident) {
    const renderData = IncidentInventoryModalController.getInventoryForRender();
    const props = await getProps(incident);
    const uploaderFeatureFlag = FeatureFlag.enabled("incident-uploader");
    props.uploaderFeatureFlag = uploaderFeatureFlag;
    const html = await nunjucks.asyncRender(
      "modals/incidentInventory/incidentsGeneralInfo.njk",
      props,
    );
    IncidentInventoryModalController.renderPageContent(html);
    initializeDropzones(renderData?.additionalFiles);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    $inventoryModal = $("#inventory-modal");
    return props;
  };

  var getProps = async function (incident) {
    return {
      incident,
      requestingTypeOptions: IncidentInventoryConstants.getRequestingTypeOptions(),
      requestingAffiliationOptions: IncidentInventoryConstants.getRequestingAffiliationOptions(),
    };
  };
  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, "incident-inventory", { isMultiPart: true });

    flatpickr("#estimated-start-time", {
      enableTime: true,
      time_24hr: true,
      dateFormat: "m/d/Y H:i",
    });
  };

  var cleanUp = function () {
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    loadListeners,
  };
};

module.exports = IncidentInventoryGeneralInfo();

const Form = require("../../general/form");
const InventoryModal = require("../../general/inventoryModal");
const IncidentInventoryModalController = require("./incidentInventoryModalController");
const IncidentInventoryConstants = require("./incidentInventoryConstants");
const flatpickr = require("flatpickr");
const ApiCalls = require("../../apiCalls");
const FeatureFlag = require("../../settings/featureFlag");
