"use strict";

const CostRecoveryPage = function () {
  const stringKey = "cost-recovery";
  const headerInformation = "Cost Recovery";

  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    const data = PropertyCostsModalController.getAllData();

    _removeUnknownFromStatusOptions(data);
    _addDefaultValues(data);
    await PropertyCostsModalController.renderPageContent(
      nunjucks.render("esg/modals/propertyCosts/costRecoveryPage.njk", data),
    );
    CollapsingCard.initializeCards($("#inventory-modal"));
    _loadListeners();
    updateCostRecoveryPrograms();
    _updateDefaultValues(data);
  };

  var _loadListeners = function () {
    Form.initializeAndLoadListeners($page, PropertyCostsModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("input", ".card select", _onUpdateStatus);
    $page.on("change", "[name*=useManualCompensationValue]", _changedUseManualCompensationValue);
    $page.on("input", "[name*=compValueManual]", _inputCompValueManual);
    $page.on("input change", "[name*=incentivePrograms]", _addIndexToIncentiveProgramData);
    $page.find(".card").on("hide.bs.collapse", _onCollapse);
  };

  var _unloadListeners = function () {
    $page.off("input", ".card select", _onUpdateStatus);
    $page.off("change", "[name*=useManualCompensationValue]", _changedUseManualCompensationValue);
    $page.off("input", "[name*=compValueManual]", _inputCompValueManual);
    $page.off("input change", "[name*=incentivePrograms]", _addIndexToIncentiveProgramData);
    $page.find(".card").off("hide.bs.collapse", _onCollapse);
  };

  var _onUpdateStatus = function () {
    _updateCardStatus($(this));
    updateCostRecoveryPrograms();
  };

  var _onCollapse = function () {
    updateCostRecoveryPrograms();
  };

  var _addDefaultValues = function (data) {
    if (!data.incentivePrograms?.length) return;
    data.incentivePrograms.forEach((program) => {
      program.compValueManual = program.compValueManual || program.compValue;
      program.statusText = _statusText(program);
    });
  };

  var _updateDefaultValues = function (data) {
    if (!data.incentivePrograms?.length) return;
    PropertyCostsModalController.setData("incentivePrograms", data.incentivePrograms);
  };

  var _statusText = function (program) {
    const status = NunjucksFilters.kebabToCapFirstLetters(program.compStatus);
    const value = NunjucksFilters.money(_compValue(program, true), true);
    return `${status} - ${value}`;
  };

  var _compValue = function (program, ignoreNA = false) {
    const property = PropertyCostsModalController.getAllData();
    if (["not-applicable", "identified"].includes(program.compStatus) && !ignoreNA) {
      return 0;
    }
    const value = program.useManualCompensationValue
      ? program.compValueManual
      : getCompensation(program, property);
    return parseFloat(value || 0);
  };

  var _setStatusHtml = function (program) {
    $(".card[data-id=" + program.id + "] .card-status").html(_statusText(program));
  };

  var _changedUseManualCompensationValue = function () {
    const $this = $(this);
    const $costRecovery = $this.closest(".cost-recovery");
    $costRecovery.toggleClass("manual", $this.is(":checked"));
    $costRecovery.find("[name*=compValueManual]").select();
    updateCostRecoveryPrograms();
  };

  var _updateCardStatus = function ($select) {
    const $card = $select.closest(".card");
    $card.attr("data-status", $select.val());
  };

  var _addIndexToIncentiveProgramData = function () {
    const $card = $(this).closest(".card");
    PropertyCostsModalController.setData(
      ["incentivePrograms", $card.data("index"), "id"],
      $card.data("id"),
    );
  };

  var _inputCompValueManual = function () {
    const index = $(this).closest(".card").data("index");
    PropertyCostsModalController.setData(
      ["incentivePrograms", index, "useManualCompensationValue"],
      true,
    );
    updateCostRecoveryPrograms();
  };

  var updateCostRecoveryPrograms = function () {
    const data = PropertyCostsModalController.getAllData();
    let costRecovery = 0;

    data?.incentivePrograms?.forEach(function (program, idx) {
      const compensation = _compValue(program);
      costRecovery += compensation;
      PropertyCostsModalController.setData(["incentivePrograms", idx, "id"], program.id);
      PropertyCostsModalController.setData(
        ["incentivePrograms", idx, "compValue"],
        _compValue(program),
      );
      PropertyCostsModalController.setData(
        ["incentivePrograms", idx, "compValueManual"],
        program.compValueManual,
      );
      PropertyCostsModalController.setData(
        ["incentivePrograms", idx, "useManualCompensationValue"],
        program.useManualCompensationValue,
      );
      $(`.card[data-id=${program.id}] .cost-recovery .calculated-value`).text(
        NunjucksFilters.money(_compValue(program, true), true),
      );
      _setStatusHtml(program);
    });

    const costCapital = data.costType === "fixed" ? data.costCapitalManual : data.costCapital || 0;
    const total = Math.round(costCapital - costRecovery);

    PropertyCostsModalController.setData("costRecovery", costRecovery);
    PropertyCostsModalController.setData(
      "costRecoveryPercent",
      costCapital === 0 ? 0 : 100 * (costRecovery / costCapital),
    );
    $('input[name="costRecovery"]').val(costRecovery);
    $(".capital-cost-equation .cost-recovery strong").text(
      NunjucksFilters.money(costRecovery, true),
    );
    PropertyCostsModalController.setData("costTotal", total);
    $("input[name=costTotal]").val(total);
    $(".capital-cost-equation .total-cost strong").text(NunjucksFilters.money(total, true));
  };

  var _removeUnknownFromStatusOptions = function (data) {
    data.dropdownOptions.compensationStatus = data.dropdownOptions.compensationStatus.filter(
      (option) => option.value !== "unknown",
    );
  };

  var getCompensation = function (ip, p) {
    let _maxCompTypeCoeff,
      _compensation,
      _compensationMax,
      _compTime,
      _costRecoveryConst,
      _newCompensationValue;

    if (ip.rate === null) {
      _compensation = null;
    } else {
      if (ip.compTimeMax === null || Number(ip.compTimeMax) === 0) {
        _compTime = ip.compTimeDefault;
      } else {
        _compTime = Math.min(ip.compTimeMax, ip.compTimeDefault) || 0;
      }
    }

    const _newCompCoeff = ip.compRecurring ? ip.rate * _compTime : ip.rate;

    switch (ip.compensationType) {
      case "cost-area-imp":
        if (p.useManualImperviousAcres) {
          _costRecoveryConst = p.imperviousAcresManual * 43560;
        } else {
          _costRecoveryConst = p.imperviousAcres * 43560;
        }
        break;
      case "cost-area":
        _costRecoveryConst = p.propertyAcres * 43560;
        break;
      case "cost-gallon":
        _costRecoveryConst = p.swMngd;
        break;
      case "cost-percent":
        if (p.costType === "fixed") {
          _costRecoveryConst = p.costCapitalManual / 100.0;
        } else {
          _costRecoveryConst = p.costCapital / 100.0;
        }
        break;
      case "cost-fixed-val":
        _costRecoveryConst = 1;
        break;
      case "cost-pollution":
        _costRecoveryConst = p.pollMngd;
        break;
      case "cost-roof-area":
        if (p.useManualBuildingFootprint) {
          _costRecoveryConst = p.buildingFootprintManual;
        } else {
          _costRecoveryConst = p.buildingFootprint;
        }
        break;
      case "cost-bmp-vol-capacity":
        _costRecoveryConst = p.targetCapacity * 325900;
        break;
      case "cost-gallon-consumed":
      case "cost-bmp-footprint":
      default:
        _costRecoveryConst = null;
        break;
    }

    _compensation = _costRecoveryConst === null ? null : _costRecoveryConst * _newCompCoeff;

    if (ip.maxCompCoeff === null || Number(ip.maxCompCoeff) === 0) {
      _compensationMax = null;
    } else {
      switch (ip.maxCompType) {
        case "max-cost-fixed-val":
          _maxCompTypeCoeff = 1;
          break;
        case "max-cost-perc":
          if (p.costType === "fixed") {
            _maxCompTypeCoeff = p.costCapitalManual / 100;
          } else {
            _maxCompTypeCoeff = p.costCapital / 100;
          }
          break;
        case "max-cost-area-imp":
          if (p.useManualImperviousAcres) {
            _maxCompTypeCoeff = p.imperviousAcresManual * 43560;
          } else {
            _maxCompTypeCoeff = p.imperviousAcres * 43560;
          }
          break;
        case "max-cost-area":
          _maxCompTypeCoeff = p.propertyAcres * 43560;
          break;
        default:
          _maxCompTypeCoeff = null;
      }
      _compensationMax = _maxCompTypeCoeff === null ? null : ip.maxCompCoeff * _maxCompTypeCoeff;
    }

    if (_compensationMax === null) {
      _newCompensationValue = Math.round(_compensation);
    } else {
      _newCompensationValue = Math.round(Math.max(0, Math.min(_compensation, _compensationMax)));
    }

    return Number(_newCompensationValue) || 0;
  };

  var validate = function () {
    return true;
  };

  var cleanUp = function () {
    _unloadListeners();
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    getCompensation,
    _statusText,
    updateCostRecoveryPrograms,
  };
};

module.exports = CostRecoveryPage();

const CollapsingCard = require("../../../general/collapsingCard");
const PropertyCostsModalController = require("../propertyCostsModalController");
const NunjucksFilters = require("../../../general/nunjucksFilters");
const Form = require("../../../general/form");
