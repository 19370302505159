"use strict";

class DatabaseError extends Error {
  constructor(message, databaseName) {
    super(message);
    this.name = "DatabaseError";
    this.databaseName = databaseName;
  }
}

module.exports = DatabaseError;
