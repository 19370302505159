"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetTopList = function (widgetConfig) {
  widgetConfig.maxListItem = 5;
  widgetConfig.hovers = [
    {
      className: "list-item",
      getHoverHtml: InsightWidgetTopList.prototype.getListItemHoverHtml,
      relativeContainerClassName: "list-box",
    },
  ];

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetTopList.prototype = Object.create(InsightWidget.prototype);
InsightWidgetTopList.prototype.constructor = InsightWidgetTopList;

InsightWidgetTopList.prototype.getHtml = function (insightData) {
  var focusDataArray = this.widgetConfig.getDataArray(insightData, "focus") || [];
  var comparisonDataArray = this.widgetConfig.getDataArray(insightData, "comparison") || [];

  this.addTrendIconClass(focusDataArray, comparisonDataArray);

  var formattedFocusDataArray = this.formatDataArray(focusDataArray);
  var formattedComparisonDataArray = this.formatDataArray(comparisonDataArray);

  const dataDecimalPlaces =
    this.widgetConfig.dataDecimalPlaces !== undefined ? this.widgetConfig.dataDecimalPlaces : 0;

  var html = nunjucks.render("insight/widgets/topList.njk", {
    title: this.widgetConfig.title,
    focusDataArray: formattedFocusDataArray,
    comparisonDataArray: formattedComparisonDataArray,
    focusSubtitle: this.widgetConfig.getSubtitle(insightData, "focus"),
    comparisonSubtitle: this.widgetConfig.getSubtitle(insightData, "comparison"),
    unit: this.widgetConfig.unit,
    dataDecimalPlaces,
  });

  return html;
};

InsightWidgetTopList.prototype.addTrendIconClass = function (focusDataArray, comparisonDataArray) {
  for (let i = 0; i < this.widgetConfig.maxListItem; i++) {
    const focusItem = focusDataArray[i];

    if (focusItem) {
      const focusItemIndex = i;
      const comparisonItemIndex = comparisonDataArray.findIndex(
        (item) => item.key === focusItem.key,
      );

      focusItem.trendIconClass = this.getTrendIconClass(
        comparisonItemIndex !== -1 ? comparisonItemIndex : 1000,
        focusItemIndex,
      );
    }
  }
};

InsightWidgetTopList.prototype.formatDataArray = function (dataArray) {
  if (this.widgetConfig.keyToDisplayNameFunction) {
    for (let i = 0; i < this.widgetConfig.maxListItem; i++) {
      const item = dataArray[i];
      if (item && this.widgetConfig.keyToDisplayNameFunction) {
        item.displayName = this.widgetConfig.keyToDisplayNameFunction(item.key);
      }
    }
  }

  this.addEmptyListItems(dataArray);

  return dataArray.slice(0, this.widgetConfig.maxListItem);
};

InsightWidgetTopList.prototype.addEmptyListItems = function (dataArray) {
  if (dataArray.length < this.widgetConfig.maxListItem) {
    while (dataArray.length < this.widgetConfig.maxListItem) {
      dataArray.push({
        displayName: "—",
        value: "-",
      });
    }
  }
};

InsightWidgetTopList.prototype.getListItemHoverHtml = function (insightData, $hoverTarget) {
  var hoverType = $hoverTarget.data("type");

  if (hoverType) {
    $(".top-list-container").find(`.list-item[data-type="${hoverType}"]`).addClass("hovered");

    var hoverTitle = this.widgetConfig.getHoverTitle(insightData, hoverType);

    const focusSubtitle = this.widgetConfig.getSubtitle(insightData, "focus");
    const comparisonSubtitle = this.widgetConfig.getSubtitle(insightData, "comparison");

    var focusDataArray = this.widgetConfig.getDataArray(insightData, "focus") || [];
    var comparisonDataArray = this.widgetConfig.getDataArray(insightData, "comparison") || [];

    const focusItem = focusDataArray.find((item) => item.key === hoverType);
    const focusItemIndex = focusDataArray.findIndex((item) => item.key === hoverType);
    const focusRank = focusItemIndex === -1 ? null : Misc.formatRankNumber(focusItemIndex + 1);
    const focusCount = focusItem?.value;

    const comparisonItem = comparisonDataArray.find((item) => item.key === hoverType);
    const comparisonItemIndex = comparisonDataArray.findIndex((item) => item.key === hoverType);
    const comparisonRank =
      comparisonItemIndex === -1 ? null : Misc.formatRankNumber(comparisonItemIndex + 1);
    const comparisonCount = comparisonItem?.value;

    const rankTrendIconClass = this.getTrendIconClass(
      comparisonItemIndex !== -1 ? comparisonItemIndex : 1000,
      focusItemIndex !== -1 ? focusItemIndex : 1000,
    );
    const valueTrendIconClass = this.getTrendIconClass(focusCount, comparisonCount);
    var trendPercentDiff = this.getTrendPercentDiff(focusCount, comparisonCount);

    const dataDecimalPlaces =
      this.widgetConfig.dataDecimalPlaces !== undefined ? this.widgetConfig.dataDecimalPlaces : 0;

    var html = nunjucks.render("insight/widgets/topListItemHover.njk", {
      hoverTitle,
      focusSubtitle,
      comparisonSubtitle,
      focusRank,
      focusCount,
      comparisonRank,
      comparisonCount,
      rankTrendIconClass,
      valueTrendIconClass,
      trendPercentDiff,
      dataDecimalPlaces,
      unit: this.widgetConfig.unit,
    });

    return html;
  }
};

module.exports = InsightWidgetTopList;

const Misc = require("../../misc");
