"use strict";

const MapSummaryTable = function () {
  var $container;
  var isOpen = true;

  var isSummaryTableEnabled = function () {
    const mapSummaryTableEnabledTabs = DataViewFunctions.getCurrentDataViewProperty(
      "mapSummaryTableEnabledTabs",
    );
    const activeTab = Tree.get("activeTab");

    if (mapSummaryTableEnabledTabs?.length) {
      return mapSummaryTableEnabledTabs.includes(activeTab);
    }

    return false;
  };

  var init = function () {
    $container = $(".summary-table-wrapper");
    handleMapSummaryTableDisplay();
    loadListeners();
  };

  var loadListeners = function () {
    $container.off();
    $container.on("click", ".toggle-button", toggleButtonClick);

    Tree.select("activeTab").on("update", function (e) {
      handleMapSummaryTableDisplay();
    });
  };

  var toggleButtonClick = function () {
    setSummaryTableState(!isOpen);
  };

  var setSummaryTableState = function (open) {
    isOpen = open;

    if (isOpen) {
      ProgressMapTable.openSummaryTable();
    } else {
      ProgressMapTable.closeSummaryTable();
      $container.find(".summary-table-container.compact").removeClass("compact");
    }
  };

  var renderSummaryTableContent = function (template, renderData) {
    if ($container) {
      const spatial = Tree.get("mapTitle");
      $container.html(nunjucks.render(template, { ...renderData, spatial }));

      setSummaryTableState(isOpen);
    }
  };

  var handleMapSummaryTableDisplay = function () {
    var show = isSummaryTableEnabled();
    $container.toggle(show);
  };

  return {
    init,
    renderSummaryTableContent,
  };
};

module.exports = MapSummaryTable();

const DataViewFunctions = require("../dataViewFunctions");
const Tree = require("../tree");
const ProgressMapTable = require("../report/report/progress/progressMapTable");
