"use strict";

const MeasurementMinMax = function () {
  const getData = function (all = false) {
    const data = {};
    $(".measurement-min-max").each(function () {
      const $minMax = $(this);
      const name = $minMax.find("input:first").attr("name").split("[")[0];

      const minInput = $minMax.find('[name*="[min]"]');
      const maxInput = $minMax.find('[name*="[max]"]');

      const hasMin = minInput.val() !== "";
      const hasMax = maxInput.val() !== "";
      data[name] = {
        min: all ? "" : $minMax.find('[name*="[min]"]').val().replaceAll(",", ""),
        max: all ? "" : $minMax.find('[name*="[max]"]').val().replaceAll(",", ""),

        unit:
          all && (hasMin || hasMax)
            ? ""
            : $minMax.find('[name*="[unit]"]').val().replaceAll(",", ""),
      };
    });
    return convertFilterToFeet(data);
  };

  const setData = function (data) {
    for (const name in data) {
      const values = data[name];
      const min = values?.min;
      const max = values?.max;
      $(`[name="${name}[max]"]`).val(max);
      $(`[name="${name}[min]"]`).val(min);
      $(`[name="${name}[unit]"]`).val(values?.unit);

      if (min === "" && max === "") {
        hideShow($(`.measurement-min-max-${name}`), true);
      }
    }
  };

  const convertFilterToFeet = function (data) {
    if (data?.diameterRange?.unit === "in") {
      data.diameterRange.min = UnitConversion.inchesToFeet(data.diameterRange.min);
      data.diameterRange.max = UnitConversion.inchesToFeet(data.diameterRange.max);
    }
    if (data?.lengthRange?.unit === "in") {
      data.lengthRange.min = UnitConversion.inchesToFeet(data.lengthRange.min);
      data.lengthRange.max = UnitConversion.inchesToFeet(data.lengthRange.max);
    }
    if (data?.widthRange?.unit === "in") {
      data.widthRange.min = UnitConversion.inchesToFeet(data.widthRange.min);
      data.widthRange.max = UnitConversion.inchesToFeet(data.widthRange.max);
    }
    return data;
  };

  const convertUnits = function (unit, value) {
    if (unit === "inches") {
      return UnitConversion.inchesToFeet(value);
    }
    return value;
  };

  const clear = function (key) {
    $(".measurement-min-max input[name^=" + key + "]").val("");
    _clearMeasurementRanges(key);
  };

  var _clearMeasurementRanges = function (key) {
    const currentSelectedFilters = Tree.get("filters");
    if (currentSelectedFilters && currentSelectedFilters[key]) {
      currentSelectedFilters[key] = { min: "", max: "", unit: "" };
    }
  };

  const updateStatusOfFilter = function (currentSelectedFilters) {
    const allFiltersSelected = _allFiltersSelected(currentSelectedFilters);
    const currentlySelectedDiameterOnlyShapes =
      _getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected(true);
    const currentlySelectedLengthWidthOnlyShapes =
      _getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected(false);
    const hasDiameterShapeSelected = currentlySelectedDiameterOnlyShapes?.length > 0;
    const hasLengthWidthShapeSelected = currentlySelectedLengthWidthOnlyShapes?.length > 0;
    const hideDiameter = true;
    const hideLength = true;
    const hideWidth = true;

    if (allFiltersSelected) {
      hideFilters(hideDiameter, hideLength, hideWidth);
    } else if (hasDiameterShapeSelected && hasLengthWidthShapeSelected) {
      hideFilters(!hideDiameter, !hideLength, !hideWidth);
    } else if (hasDiameterShapeSelected && !hasLengthWidthShapeSelected) {
      hideFilters(!hideDiameter, hideLength, hideWidth);
    } else if (!hasDiameterShapeSelected && hasLengthWidthShapeSelected) {
      hideFilters(hideDiameter, !hideLength, !hideWidth);
    }
  };

  var hideFilters = function (hideDiameter = true, hideLength = true, hideWidth = true) {
    const $diameterFilter = $(".measurement-min-max-diameterRange");
    const $lengthFilter = $(".measurement-min-max-lengthRange");
    const $widthFilter = $(".measurement-min-max-widthRange");
    _hideAndClearFilter($diameterFilter, "diameterRange", hideDiameter);
    _hideAndClearFilter($lengthFilter, "lengthRange", hideLength);
    _hideAndClearFilter($widthFilter, "widthRange", hideWidth);
  };

  var _hideAndClearFilter = function (filter, clearKey, hide) {
    if (hide) {
      hideShow(filter, true);
      clear(clearKey);
    } else {
      hideShow(filter, false);
    }
  };

  var hideShow = function ($input, show) {
    $input.closest("fieldset").toggleClass("hidden", show);
  };

  const _allFiltersSelected = function (currentSelectedFilters) {
    const currentDataView = Tree.get("dataView");
    const defaultFilters = FilterConstants.getDefaultFiltersByDataView(currentDataView);
    const shapeFilter = defaultFilters?.shape;
    return shapeFilter?.length === currentSelectedFilters?.shape?.length;
  };

  var _measurementRangeIsSet = function (rangeName) {
    const currentSelectedFilters = Tree.get("filters");
    const rangeSet =
      currentSelectedFilters?.[rangeName]?.min || currentSelectedFilters?.[rangeName]?.max;
    return rangeSet;
  };

  var _getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected = function (isDiameterOnly) {
    const currentSelectedShapeFilters = Tree.get("filters")?.shape;
    const diameterOnlyShapes = getDiameterOnlyShapesByDataView()[Tree.get("dataView")]?.shape;
    if (!diameterOnlyShapes) return;

    const filterFunction = isDiameterOnly
      ? (shape) => diameterOnlyShapes.includes(shape)
      : (shape) => !diameterOnlyShapes.includes(shape);

    const updatedShapeSelection = currentSelectedShapeFilters?.filter(filterFunction);

    return updatedShapeSelection;
  };

  var getDiameterOnlyShapesByDataView = function () {
    return {
      "muni-outfalls": {
        shape: ["circular", "other"],
      },
      "muni-manhole": {
        shape: ["circular"],
      },
      "muni-cleanout": {
        shape: ["circular"],
      },
      "muni-pipes": {
        shape: ["circular"],
      },
      "muni-culvert": {
        shape: ["circular"],
      },
      "muni-open-channel": {
        shape: [],
      },
    };
  };

  var passLengthWidthFilter = function (
    assetShape,
    shapeFilter,
    assetLengthValue,
    assetLengthUnits,
    assetWidthValue,
    assetWidthUnits,
    lengthFilterRange,
    widthFilterRange,
  ) {
    let pass = true;
    const lengthWidthOnlyShapes = _getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected(false);
    const unitLengthValue = _getDefaultUnits(assetLengthUnits);
    const unitWidthValue = _getDefaultUnits(assetLengthUnits);
    const convertedLengthVal = convertUnits(unitLengthValue, assetLengthValue);
    const convertedWidthVal = convertUnits(unitWidthValue, assetWidthValue);
    const datumShape = assetShape || "null";

    if (
      lengthWidthOnlyShapes?.includes(datumShape) &&
      _measurementRangeIsSet("lengthRange") &&
      !_measurementRangeIsSet("widthRange")
    ) {
      pass = OfflineFilterFunctions.passMinMaxFilter(convertedLengthVal, lengthFilterRange);
    } else if (
      lengthWidthOnlyShapes?.includes(datumShape) &&
      _measurementRangeIsSet("widthRange") &&
      !_measurementRangeIsSet("lengthRange")
    ) {
      pass = OfflineFilterFunctions.passMinMaxFilter(convertedWidthVal, widthFilterRange);
    } else if (
      lengthWidthOnlyShapes?.includes(datumShape) &&
      _measurementRangeIsSet("widthRange") &&
      _measurementRangeIsSet("lengthRange")
    ) {
      pass =
        OfflineFilterFunctions.passMinMaxFilter(convertedWidthVal, widthFilterRange) ||
        OfflineFilterFunctions.passMinMaxFilter(convertedLengthVal, lengthFilterRange);
    } else if (shapeFilter?.includes(datumShape)) {
      pass = true;
    }

    return pass;
  };

  var passDiameterFilter = function (
    assetShape,
    shapeFilter,
    assetMeasurementValue,
    assetMeasurementUnits,
    diameterFilterRange,
  ) {
    let pass = true;
    const diameterOnlyShapes = _getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected(true);
    const unitValue = _getDefaultUnits(assetMeasurementUnits);
    const convertedVal = convertUnits(unitValue, assetMeasurementValue);

    if (diameterOnlyShapes?.includes(assetShape) && _measurementRangeIsSet("diameterRange")) {
      pass = OfflineFilterFunctions.passMinMaxFilter(convertedVal, diameterFilterRange);
    } else if (shapeFilter?.includes(assetShape)) {
      pass = true;
    }
    return pass;
  };

  var findIndexOfShapeQuery = function (array, term) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes(term)) {
        return i;
      }
    }
    return -1;
  };

  var _getDefaultUnits = function (assetMeasurementUnits) {
    const currentDataView = Tree.get("dataView");
    if (currentDataView === "muni-culvert") {
      return "feet";
    } else {
      return assetMeasurementUnits || "inches";
    }
  };

  return {
    getData,
    setData,
    clear,
    convertUnits,
    updateStatusOfFilter,
    _hideAndClearFilter,
    _allFiltersSelected,
    _clearMeasurementRanges,
    _measurementRangeIsSet,
    _getDefaultUnits,
    getDiameterOnlyShapesByDataView,
    passLengthWidthFilter,
    passDiameterFilter,
    _getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected,
    findIndexOfShapeQuery,
  };
};

module.exports = MeasurementMinMax();
const UnitConversion = require("../unitConversion");
const FilterConstants = require("../filterConstants");
const Tree = require("../tree");
const OfflineFilterFunctions = require("../mapFunctions/offlineFilterFunctions");
