"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetCountByTypeChart = function (widgetConfig) {
  widgetConfig.hovers = [
    {
      className: "bar-group",
      getHoverHtml: InsightWidgetCountByTypeChart.prototype.getHoverHtml,
    },
  ];

  InsightWidget.call(this, widgetConfig);

  this.widgetConfig = widgetConfig;
  this.groupIndex = 0;
};

InsightWidgetCountByTypeChart.prototype = Object.create(InsightWidget.prototype);
InsightWidgetCountByTypeChart.prototype.constructor = InsightWidgetCountByTypeChart;

InsightWidgetCountByTypeChart.prototype.getHtml = function (insightData) {
  var html = nunjucks.render("insight/widgets/countByTypeChart.njk", {
    title: this.widgetConfig.title,
  });

  return html;
};

InsightWidgetCountByTypeChart.prototype.resetWidgetData = function () {
  this.mergedDataArray = [];
  this.dataGroups = [];
};

InsightWidgetCountByTypeChart.prototype.getMergedDataArray = function (
  focusDataArray,
  comparisonDataArray,
) {
  var mergedDataArray = [];

  focusDataArray.forEach((focusItem) => {
    let mergedItem = mergedDataArray.find((item) => {
      return item.key === focusItem.key;
    });
    if (mergedItem) {
      mergedItem.focusCount = focusItem.value;
    } else {
      mergedItem = focusItem;
      mergedItem.focusCount = focusItem.value;
      mergedDataArray.push(mergedItem);
    }
  });
  comparisonDataArray.forEach((comparisonItem) => {
    let mergedItem = mergedDataArray.find((item) => {
      return item.key === comparisonItem.key;
    });
    if (mergedItem) {
      mergedItem.comparisonCount = comparisonItem.value;
    } else {
      mergedItem = comparisonItem;
      mergedItem.comparisonCount = comparisonItem.value;
      mergedDataArray.push(mergedItem);
    }
  });

  return mergedDataArray;
};

InsightWidgetCountByTypeChart.prototype.renderChart = function (insightData, exportModal = false) {
  this.resetWidgetData();
  var $widget = this.getWidgetElement();
  var focusDataArray = this.widgetConfig.getDataArray(insightData, "focus") || [];
  var comparisonDataArray = this.widgetConfig.getDataArray(insightData, "comparison") || [];
  var mergedDataArray = this.getMergedDataArray(focusDataArray, comparisonDataArray);

  if (this.widgetConfig.excludeZeros) {
    mergedDataArray = mergedDataArray.filter((item) => {
      return item.focusCount > 0 || item.comparisonCount > 0;
    });
  }

  if (this.widgetConfig.orderByCount) {
    mergedDataArray.sort((a, b) => b.focusCount - a.focusCount);
  }

  this.mergedDataArray = mergedDataArray;

  if (
    this.widgetConfig.typeCountLimit &&
    this.mergedDataArray.length > this.widgetConfig.typeCountLimit
  ) {
    const limit = this.widgetConfig.typeCountLimit;
    const groupCount = Math.ceil(this.mergedDataArray.length / limit);
    this.groupCount = groupCount;

    const dataGroups = [];
    for (let i = 0; i <= groupCount - 1; i++) {
      const start = limit * i;
      const end = limit * (i + 1);
      dataGroups.push(this.mergedDataArray.slice(start, end));
    }

    // Add typeCountLimit data items to last group
    const lastGroup = dataGroups[dataGroups.length - 1];
    if (lastGroup?.length > 0 && lastGroup.length < limit) {
      this.addEmptyBars(lastGroup, limit);
    }

    this.dataGroups = dataGroups;

    // Set groupIndex if a type is selected from dropdown
    const selectedType = this.widgetConfig.getSelectedType();
    if (selectedType !== "total") {
      const selectedIndex = mergedDataArray.findIndex((item) => item.key === selectedType);
      if (selectedIndex !== -1) {
        this.groupIndex = Math.floor(selectedIndex / limit);
      }
    }

    $widget.find(".page-button-container").show();
  } else {
    $widget.find(".page-button-container").hide();

    if (this.widgetConfig.typeCountLimit) {
      this.addEmptyBars(this.mergedDataArray, this.widgetConfig.typeCountLimit);
    }
  }

  this.draw(exportModal);
};

InsightWidgetCountByTypeChart.prototype.addEmptyBars = function (array, limit) {
  if (array.length < limit) {
    while (array.length < limit) {
      array.push({
        key: array.length,
        displayName: "",
        focusCount: 0,
        comparisonCount: 0,
        empty: true,
      });
    }
  }
};

InsightWidgetCountByTypeChart.prototype.draw = function (exportModal = false) {
  var currentDataArray;
  if (this.dataGroups?.length) {
    currentDataArray = this.dataGroups[this.groupIndex];
    this.handlePageButtonDisplay();
  } else {
    currentDataArray = this.mergedDataArray;
  }
  this.currentDataArray = currentDataArray;

  const focusYear = this.widgetConfig.getYearByDataPath("focus");
  const comparisonYear = this.widgetConfig.getYearByDataPath("comparison");
  const selectedType = this.widgetConfig.getSelectedType();

  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }

  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  defaults.containerHeight = 190;
  const extraPadding = 20; // for x-axis labels that are 2 lines (margin-bottom removed for .count-by-type-chart-container .chart-container in _listView.scss)
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight;

  var margin = defaults.margin,
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom;

  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom + 10 + extraPadding);

  var legendData = {
    focus: {
      label: focusYear,
      color: "#0b3e5e",
    },
    comparison: {
      label: comparisonYear,
      color: "#808080",
    },
  };
  this.appendStandardChartLegend(svg, legendData, 4, "left");

  const unit = this.widgetConfig.unit;
  if (unit) {
    svg
      .append("text")
      .attr("text-anchor", "end")
      .attr("x", width + margin.left + margin.right)
      .attr("y", 10)
      .attr("fill", "#000")
      .attr("font-size", "11px")
      .attr("font-weight", "100")
      .text(`Unit: ${unit}`);
  }

  var chartGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top + 15})`);

  var scaleX = d3
    .scaleBand()
    .domain(currentDataArray.map((item) => item.key))
    .range([0, width])
    .padding(0.4);

  var barGroups = chartGroup
    .selectAll()
    .data(currentDataArray)
    .enter()
    .append("g")
    // .attr("class", "bar-group")
    .attr("class", function (d, i) {
      if (d.empty) {
        return "";
      } else if (selectedType === "total") {
        return "bar-group";
      } else {
        if (d.key === selectedType) {
          return "bar-group selected";
        } else {
          return "bar-group not-selected";
        }
      }
    })
    .attr("data-hover-index", function (d, i) {
      return i;
    })
    .attr("data-type", function (d, i) {
      return d.key;
    });

  if (this.widgetConfig.dataTypeKey) {
    barGroups.attr("data-type-key", this.widgetConfig.dataTypeKey);
  }

  barGroups
    .append("rect")
    .attr("class", "hover-background-rect")
    .attr("x", function (d) {
      return scaleX(d.key);
    })
    .attr("y", function (d) {
      return 0;
    })
    .attr("width", scaleX.bandwidth())
    .attr("height", function (d) {
      return height;
    })
    .attr("fill", "transparent");

  const twoLineLabel = this.widgetConfig.twoLineLabel;
  var axisX = d3
    .axisBottom(scaleX)
    .tickFormat(function (d) {
      return "";
    })
    .tickPadding(25)
    .tickSize(0);
  chartGroup
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(0," + height + ")")
    .call(axisX)
    .selectAll("text")
    .selectAll("tspan")
    .data(function (d) {
      const displayName = currentDataArray.find((item) => item.key === d)?.displayName;
      if (twoLineLabel && displayName.includes(" ")) {
        const index = displayName.indexOf(" ");
        const line1 = displayName.slice(0, index);
        const line2 = displayName.slice(index + 1, displayName.length);
        return [line1, line2];
      } else {
        return [displayName, ""];
      }
    })
    .enter()
    .append("tspan")
    .attr("x", 0)
    .attr("dy", function (d, i) {
      return 2 * i - 1 + "em";
    })
    .text(String);

  var extentY = d3.extent(this.mergedDataArray, function (d) {
    return Math.max(d.focusCount || 0, d.comparisonCount || 0);
  });
  const maxY = extentY[1] === 0 ? 10 : Math.ceil(extentY[1] * 1.25);

  var scaleY = d3.scaleLinear().domain([0, maxY]).range([height, 0]);
  var axisY = d3.axisLeft(scaleY).ticks(5).tickSize(0).tickPadding(10).tickSizeInner(-width);
  chartGroup.append("g").attr("class", "axis").call(axisY);

  this.drawDataBars("comparison", currentDataArray, barGroups, scaleX, scaleY, height);
  this.drawDataBars("focus", currentDataArray, barGroups, scaleX, scaleY, height);
};

InsightWidgetCountByTypeChart.prototype.drawDataBars = function (
  dataPath,
  dataArray,
  barGroups,
  scaleX,
  scaleY,
  height,
) {
  const subGroupPadding = 2;
  const barWidth = (scaleX.bandwidth() - subGroupPadding) / 2;

  barGroups
    .append("rect")
    .data(dataArray)
    .attr("class", dataPath)
    .attr("x", function (d) {
      if (dataPath === "focus") {
        return scaleX(d.key);
      } else {
        return scaleX(d.key) + barWidth + subGroupPadding;
      }
    })
    .attr("y", function (d) {
      const count = dataPath === "focus" ? d.focusCount : d.comparisonCount;
      return scaleY(count);
    })
    .attr("width", barWidth)
    .attr("height", function (d) {
      const count = dataPath === "focus" ? d.focusCount : d.comparisonCount;
      if (count !== null && count !== undefined) {
        return height - scaleY(count);
      }
      return 0;
    });

  const dataDecimalPlaces =
    this.widgetConfig.dataDecimalPlaces !== undefined ? this.widgetConfig.dataDecimalPlaces : 0;

  barGroups
    .append("text")
    .data(dataArray)
    .attr("class", "bar")
    .attr("text-anchor", "middle")
    .attr("x", function (d) {
      if (dataPath === "focus") {
        return scaleX(d.key) + barWidth / 2;
      } else {
        return scaleX(d.key) + barWidth + barWidth / 2 + subGroupPadding;
      }
    })
    .attr("y", function (d) {
      const count = dataPath === "focus" ? d.focusCount : d.comparisonCount;
      if (count !== null && count !== undefined) {
        return scaleY(count) - 5;
      }
      return height;
    })
    .text(function (d) {
      if (d.empty) {
        return "";
      } else {
        const count = dataPath === "focus" ? d.focusCount : d.comparisonCount;
        if (count !== null && count !== undefined) {
          // return count.toFixed(dataDecimalPlaces);
          return count.toLocaleString(undefined, {
            minimumFractionDigits: dataDecimalPlaces,
            maximumFractionDigits: dataDecimalPlaces,
          });
        }
        return "—";
      }
    });
};

InsightWidgetCountByTypeChart.prototype.getHoverHtml = function (insightData, $hoverTarget) {
  var hoverIndex = $hoverTarget.data("hoverIndex");
  var hoverType = $hoverTarget.data("type");

  var hoverTitle = this.widgetConfig.getHoverTitle(insightData, hoverType);
  var hoverDescription = this.widgetConfig.getHoverDescription(insightData, hoverType);

  var focusCount = this.currentDataArray[hoverIndex]?.focusCount;
  var comparisonCount = this.currentDataArray[hoverIndex]?.comparisonCount;
  var trendIconClass = this.getTrendIconClass(focusCount, comparisonCount);
  var trendPercentDiff = this.getTrendPercentDiff(focusCount, comparisonCount);

  var html = nunjucks.render("insight/widgets/countByTypeBarHover.njk", {
    hoverTitle,
    hoverDescription,
    trendIconClass,
    trendPercentDiff,
    hideTrendIcon: this.widgetConfig.hideTrendIcon,
  });

  return html;
};

InsightWidgetCountByTypeChart.prototype.loadListeners = function () {
  var $widget = this.getWidgetElement();
  $widget.off("click", ".page-button");
  $widget.on("click", ".page-button", (event) => {
    this.onPageButtonClick(event);
  });
};

InsightWidgetCountByTypeChart.prototype.onPageButtonClick = function (event) {
  var $widget = this.getWidgetElement();
  var $button = $(event.target);

  if ($button.hasClass("next-button")) {
    this.groupIndex = Math.min(this.groupIndex + 1, this.groupCount - 1);
  } else if ($button.hasClass("prev-button")) {
    this.groupIndex = Math.max(this.groupIndex - 1, 0);
  }

  this.draw();
  this.widgetConfig.hovers.forEach((hover) => {
    $widget.find(`.${hover.className}`).addClass("hover-target");
  });
};

InsightWidgetCountByTypeChart.prototype.handlePageButtonDisplay = function () {
  var $widget = this.getWidgetElement();
  var $container = $widget.find(".page-button-container");

  var disableNextButton = this.groupIndex === this.groupCount - 1;
  var disablePrevButton = this.groupIndex === 0;

  var html = nunjucks.render("insight/widgets/pagination.njk", {
    pageCount: this.groupCount,
    pageIndex: this.groupIndex,
    disableNextButton,
    disablePrevButton,
  });
  $container.html(html);
};

module.exports = InsightWidgetCountByTypeChart;

const d3 = require("d3-v6");
