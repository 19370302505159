"use strict";

function SyncManager() {
  var toSyncList = {};
  var syncedList = {};
  var statusCallbacks = [];
  var syncing = null;

  function add(key, syncFunction, postSyncFunction = null) {
    if (syncing && keyExists(key)) {
      return syncing;
    }

    addToSyncList(key, syncFunction, postSyncFunction);
    syncIfReady();

    return syncing;
  }

  function addToSyncList(key, syncFunction, postSyncFunction) {
    assertFunction(syncFunction);

    if (postSyncFunction !== null) {
      assertFunction(postSyncFunction);
    }

    toSyncList[key] = { syncFunction, postSyncFunction };
  }

  function syncIfReady() {
    if (!Offline.onLine()) {
      return;
    }

    triggerStatusUpdate();

    if (!syncing) {
      syncing = sync();
    }
  }

  function remove(key) {
    const existed = key in toSyncList;
    delete toSyncList[key];
    if (existed) {
      triggerStatusUpdate();
    }
  }

  function assertFunction(possibleFunction) {
    if (typeof possibleFunction !== "function") {
      throw new Error(`${possibleFunction} must be a function that returns a promise.`);
    }
  }

  function keyExists(key) {
    return key in toSyncList || key in syncedList;
  }

  async function sync() {
    const mainSync = startSync();
    warnUntilDone(mainSync);
    await mainSync;
    const synced = syncedList;
    syncedList = {};
    syncing = null;
    postSync(synced);
  }

  async function startSync() {
    var keys = Object.keys(toSyncList);

    while (keys.length > 0) {
      for (const key of keys) {
        await syncByKey(key);
      }

      keys = Object.keys(toSyncList);
    }
  }

  async function syncByKey(key) {
    const syncObject = toSyncList[key];
    var data;
    var resolved = true;

    try {
      data = await syncObject.syncFunction();
    } catch (e) {
      if (!(e instanceof ApiError)) {
        Analytics.reportException(e);
      }
      data = e;
      resolved = false;
    }

    syncedList[key] = {
      data,
      resolved,
      ...syncObject,
    };
    delete toSyncList[key];

    triggerStatusUpdate();
  }

  function getStatus() {
    const toSync = Object.keys(toSyncList).length;
    var resolved = 0;
    var rejected = 0;

    for (const key in syncedList) {
      if (syncedList[key].resolved) {
        resolved++;
      } else {
        rejected++;
      }
    }

    return { toSync, resolved, rejected };
  }

  function triggerStatusUpdate() {
    const status = getStatus();
    for (const callback of statusCallbacks) {
      callback(status);
    }

    if (status.toSync === 0 && status.rejected > 0) {
      Analytics.sendAction(["sync_failed"]);
    }
  }

  function addStatusListener(callback) {
    statusCallbacks.push(callback);
  }

  function warnUntilDone(syncPromise) {
    BrowserWarning.warnOnCloseWhile(
      new Promise(function (resolve) {
        syncPromise.finally(resolve);
      }),
    );
  }

  function postSync(syncList) {
    for (const postSyncFunction of getUniquePostSyncFunctions(syncList)) {
      postSyncFunction();
    }
  }

  function getUniquePostSyncFunctions(syncList) {
    const uniqueFunctions = [];

    for (const key in syncList) {
      const synced = syncList[key];

      if (
        synced.resolved &&
        synced.postSyncFunction &&
        !uniqueFunctions.includes(synced.postSyncFunction)
      ) {
        assertNotAnonymous(synced.postSyncFunction);
        uniqueFunctions.push(synced.postSyncFunction);
      }
    }

    return uniqueFunctions;
  }

  function assertNotAnonymous(fn) {
    if (fn.name === "postSync" || fn.prototype === undefined) {
      throw new Error(
        `Configured "postSync" functions must not be anonymous. The SyncManager can't detect duplicate queued syncs otherwise.`,
      );
    }
  }

  return { add, remove, getStatus, addStatusListener };
}

module.exports = SyncManager();

const Analytics = require("../general/analytics");
const ApiError = require("../errors/apiError");
const BrowserWarning = require("../general/browserWarning");
const Offline = require("./offline");
