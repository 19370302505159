"use strict";

var MapConstants = function () {
  var highlightData = {
    runoffProgress: {
      highlight1: "reduction-runoff",
      highlight2: "reduction-perc",
    },
    particulatesProgress: {
      highlight1: "reduction-particulates",
      highlight2: "reduction-perc",
    },
    runoffRatioProgress: {
      highlight1: "reduction-runoff-ratio",
    },
  };

  var dataSortToProperty = {
    uc: "catchid",
    total: "acres",
    plu: "plu_acreage",
    plu_perc: "plu_perc",
    plu_untreated: "plu_untreated",
    reduction: "reduction",
    reduction_perc: "perReduction",
    volume: "raw",
    rate: "normalized",
    percentImp: "imp",
  };

  var defaultMapLayers = ["ms4BoundaryLayer", "catchmentLayer", "basemap", "highwayLayer"];

  var sigFigsByLayer = {
    Runoff: 2,
    "Percent Runoff": 0,
    Particulates: 3,
    Impervious: 2,
    runoffProgress: 2,
    particulatesProgress: 2,
  };

  return {
    dataSortToProperty,
    highlightData,
    defaultMapLayers,
    sigFigsByLayer,
  };
};

module.exports = MapConstants();
