"use strict";

var RegionalSubmitReportPermitteeView = function () {
  var loadingPromise;

  var renderPermitteeTable = async function () {
    var permitteeData = await _getPermitteeData();
    var permitteeTable = nunjucks.render("report/submit/regionalPermitteeTable.njk", {
      permitteeData,
    });
    $(".regional-report-table-container").html(permitteeTable);
    loadListeners();
  };

  var loadListeners = function () {
    $("#regional-submit-report").off("click", ".permittee-link", _permitteeLinkClick);
    $("#regional-submit-report").on("click", ".permittee-link", _permitteeLinkClick);
  };

  var _permitteeLinkClick = function (e) {
    const groupId = $(e.currentTarget).closest("tr").data("groupId");
    Progeny.setActiveGroupById(groupId);
    SubmitReprot.updateSubmitReport();
  };

  var _getPermitteeData = async function () {
    await _fetchOverviewKpiData();
    const year = AnnualReports.getSelectedYear();
    const progeny = Tree.get(["rootGroup", "progeny"]);
    const kpiData = Tree.get("regionalPermitteeOverviewKpi", "data", year);

    var permitteeData = [];
    progeny.forEach((progenyGroup) => {
      const permitteeDatum = {};
      const progenyGroupKpiData = kpiData[progenyGroup.groupId];
      permitteeDatum.disabled = !(progenyGroupKpiData && progenyGroupKpiData.submittedCount);

      addPermitteeGroupData(permitteeDatum, progenyGroup);
      if (progenyGroupKpiData) {
        addPermitteeKpiData(permitteeDatum, progenyGroupKpiData, year);
      }
      permitteeData.push(permitteeDatum);
    });

    return permitteeData;
  };

  var addPermitteeGroupData = function (permitteeDatum, progenyGroup) {
    permitteeDatum.groupId = progenyGroup.groupId;
    permitteeDatum.name = progenyGroup.name;
  };

  var addPermitteeKpiData = function (permitteeDatum, progenyGroupKpiData, year) {
    permitteeDatum.submittedDate = progenyGroupKpiData.submittedDate;
    permitteeDatum.submittedCount = !progenyGroupKpiData.submittedCount
      ? "—"
      : progenyGroupKpiData.submittedCount;

    addRunoffKpiString(permitteeDatum, progenyGroupKpiData, year);
    addParticulateKpiString(permitteeDatum, progenyGroupKpiData, year);
    addTrashKpiString(permitteeDatum, progenyGroupKpiData, year);
  };

  var addRunoffKpiString = function (permitteeDatum, progenyGroupKpiData, year) {
    const runoffData = progenyGroupKpiData?.["catchmentResults"]?.[year]["runoff_program"];
    const runoffpercentReduced = SubmitReportFunctions.getRunoffPercentReduced(runoffData);
    permitteeDatum.runoffReductionString =
      runoffpercentReduced === undefined ? "—" : `${runoffpercentReduced}% lower than baseline`;
  };

  var addParticulateKpiString = function (permitteeDatum, progenyGroupKpiData, year) {
    const particulateData =
      progenyGroupKpiData?.["catchmentResults"]?.[year]["particulate_program"];
    const particulatesPercentReduced =
      SubmitReportFunctions.getParticulatesPercentReduced(particulateData);
    permitteeDatum.particulateReductionString =
      particulatesPercentReduced === undefined
        ? "—"
        : `${particulatesPercentReduced}% lower than baseline`;
  };

  var addTrashKpiString = function (permitteeDatum, progenyGroupKpiData, year) {
    const trashData = progenyGroupKpiData?.["trashResults"]?.[year]["trash_program"];
    const trashPercentReduced = SubmitReportFunctions.getTrashPercentTreated(trashData);
    permitteeDatum.trashReductionString =
      trashPercentReduced === undefined ? "—" : `${trashPercentReduced}% of goal`;
  };

  var _fetchOverviewKpiData = async function () {
    var currentSelectedYear = AnnualReports.getSelectedYear();
    const dataByYear = Tree.get("regionalPermitteeOverviewKpi", "data", currentSelectedYear);
    if (!Tree.get("regionalPermitteeOverviewKpi", "isFetching") && !dataByYear) {
      Tree.set(["regionalPermitteeOverviewKpi", "isFetching"], true);
      loadingPromise = ReportApiCalls.getRegionalPermitteeOverviewKpi(currentSelectedYear).then(
        function (data) {
          Tree.set(["regionalPermitteeOverviewKpi", "data", currentSelectedYear], data);
          Tree.set(["regionalPermitteeOverviewKpi", "isFetching"], false);
          return data;
        },
      );
    }
    return loadingPromise;
  };

  return {
    renderPermitteeTable,
    _getPermitteeData,
  };
};

module.exports = RegionalSubmitReportPermitteeView();

const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const AnnualReports = require("../annualReports");
const SubmitReportFunctions = require("./submitReportFunctions");
const SubmitReprot = require("./submitReport");
const Progeny = require("../../login/progeny");
