"use strict";

class NetworkError extends Error {
  constructor(status, response) {
    super(`Network response:${status} (${response})`);
    this.name = "ApiError";
    this.status = status;
  }

  static assertIs(e) {
    if (!(e instanceof NetworkError)) {
      throw e;
    }
  }
}

module.exports = NetworkError;
