"use strict";

const IssueEnforcementsModal = function () {
  var $modal;
  var inited = false;
  var activeInspectionId;
  var activeEnforcementActionId;
  var inspectionIssues;
  var relatedType;

  var onIssue = function () {};

  var init = function () {
    $modal = $("#issue-enforcements-modal");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.on("click", ".issue-button", _openIssueEnforcementsConfirmation);
    $modal.on("click", ".cancel-button", _cancel);

    $modal.on("click", ".download-inspection-report", _downloadInspectionReport);
  };

  var _downloadInspectionReport = function () {
    ApiCalls.downloadConstructionInspectionReport(
      activeInspectionId,
      activeEnforcementActionId,
      relatedType,
    );
  };

  var open = async function (projectInspectionId, enforcementActionId, onIssueFn = () => {}, type) {
    if (!inited) init();
    activeInspectionId = projectInspectionId;
    activeEnforcementActionId = enforcementActionId;
    relatedType = type;
    onIssue = onIssueFn;
    inspectionIssues = await ApiCalls.getInspectionIssues(projectInspectionId, enforcementActionId);
    const isCdot = Session.isCdot();
    $modal.html(nunjucks.render("modals/issueEnforcementsModal.njk", { inspectionIssues, isCdot }));
    activateOverflowTooltips();
    $modal.modal("show");
  };

  var activateOverflowTooltips = function () {
    $modal.find(".overflow-ellipses").tooltipOnOverflow();
  };

  var _openIssueEnforcementsConfirmation = function () {
    MessageModal.showConfirmWarningModal(
      null,
      _issueEnforcements,
      "Cancel",
      "Issue",
      _getConfirmationMessage(),
      () => {},
      "Issue Findings",
    );
  };

  var getNumCanIssueEnforcements = function () {
    return Object.values(inspectionIssues.infoBySeverity).reduce((carry, info) => {
      return carry + info.issues.filter((issue) => issue.canIssueEnforcement).length;
    }, 0);
  };

  var getNumCantIssueEnforcements = function () {
    return Object.values(inspectionIssues.infoBySeverity).reduce((carry, info) => {
      return carry + info.issues.filter((issue) => !issue.canIssueEnforcement).length;
    }, 0);
  };

  var _getConfirmationMessage = function () {
    const numOtherIssues = getNumCantIssueEnforcements();
    const numFindingsText = _getNumFindingsText();
    let additionalFindingsText = "";
    if (numOtherIssues > 0) {
      additionalFindingsText += `There are an additional ${numOtherIssues} finding(s) that are already open and in the process of being resolved. The status of those finding(s) will not change.<br />`;
    }
    const totalFindingsToIssue = getNumCanIssueEnforcements();
    return `You have selected to issue the following findings to the general contractor to be resolved:<ul>${numFindingsText}</ul><br />
${additionalFindingsText}To issue ${totalFindingsToIssue} finding(s), click Issue. To return to the form without issuing, click Cancel.`;
  };

  var _getNumFindingsText = function () {
    return Object.values(inspectionIssues.infoBySeverity)
      .map((info) => {
        const numIssues = info.issues.length;
        const displaySeverity = info.displaySeverity;
        return `<li>${numIssues} ${displaySeverity} findings.</li>`;
      })
      .join("");
  };

  var _issueEnforcements = async function () {
    await ApiCalls.updateEnforcementAction(
      null,
      { issuedDate: DateTime.getTodayIso() },
      activeInspectionId,
    );
    onIssue();
    close();
  };

  var _cancel = function () {
    const numFindingsText = _getNumFindingsText();
    const message = `The following findings have been flagged but not issued to the general contractor to be resolved:<ul>${numFindingsText}</ul><br />
Click "Go Back" to return to the form to issue the findings.<br />
Click "Close Without Issuing" to close the form and keep the findings' status as flagged.`;

    MessageModal.showConfirmWarningModal(
      null,
      close,
      "Go Back",
      "Close Without Issuing",
      message,
      null,
      "Cancel",
    );
  };

  var close = function () {
    $modal.modal("hide");
  };

  return {
    open,
    close,
    _cancel,
    _issueEnforcements,
    _downloadInspectionReport,
    _getConfirmationMessage,
    _openIssueEnforcementsConfirmation,
    _getNumFindingsText,
  };
};

module.exports = IssueEnforcementsModal();

const ApiCalls = require("../apiCalls");
const DateTime = require("../dateTime");
const MessageModal = require("../modals/messageModal");
const Session = require("../login/session");
