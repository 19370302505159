"use strict";

const dryWeatherGeoServerLayerFilters = function () {
  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.responsiblePartyMaintenance?.length > 0) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayQuery(
          "responsible_party_maintenance",
          filters.responsiblePartyMaintenance,
        ),
      );
    }

    if (filters.phase?.length > 0) {
      queryStrings.push(GeoServerFilterFunctions.getInArrayQuery("phase", filters.phase));
    }
  };

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          DryWeatherOutfallGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var onlineFilterIsSet = function (filters) {
    return ResourceFilters.spatialFiltersAreSet(filters);
  };

  var filterOffline = function (unfilteredData, filters) {
    return unfilteredData.filter((datum) => {
      return (
        passPhaseFilter(datum, filters) && passResponsiblePartyMaintenanceFilter(datum, filters)
      );
    });
  };

  var passPhaseFilter = function (datum, filters) {
    var pass = true;

    if (filters.phase?.length) {
      pass = filters.phase.includes(datum.phase);
    } else {
      pass = false;
    }

    return pass;
  };

  var passResponsiblePartyMaintenanceFilter = function (datum, filters) {
    var pass = true;

    if (filters.responsiblePartyMaintenance?.length) {
      pass = filters.responsiblePartyMaintenance.includes(datum.responsiblePartyMaintenance);
    } else {
      pass = false;
    }

    return pass;
  };

  return {
    addGeoServerFilterQuery,
    filterResourceDataHandler,
  };
};

module.exports = dryWeatherGeoServerLayerFilters();

const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const ApiError = require("../errors/apiError");
const ResourceFilters = require("../filters/resourceFilters");
const DryWeatherOutfallGeoServerLayer = require("./dryWeatherOutfallGeoServerLayer");
