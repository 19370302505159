"use strict";

const ToDoFiltersModal = function () {
  const $modal = $("#todo-filters-modal");
  const formKey = "todo-filter";
  var dataView;
  var inited = false;
  var sectionLabels;

  var init = function () {
    dataView = Tree.get("dataView");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".filter-title-button", filterTitleClick);
    $modal.on("click", ".filter-title-button", filterTitleClick);
    $modal.off("click", ".save-button", saveButtonClick);
    $modal.on("click", ".save-button", saveButtonClick);
    $modal.off("click", ".clear-button", clearButtonClick);
    $modal.on("click", ".clear-button", clearButtonClick);
    $modal.off("click", ".js-select-all", filterSelectAllClick);
    $modal.on("click", ".js-select-all", filterSelectAllClick);
    $modal.off("click", ".clear-date-btn", clearDateRange);
    $modal.on("click", ".clear-date-btn", clearDateRange);
    $modal.off("click", "input[type='checkbox']", inputCheckboxClick);
    $modal.on("click", "input[type='checkbox']", inputCheckboxClick);
    $modal.off("input", "input.date-picker", updateFilterTitle);
    $modal.on("input", "input.date-picker", updateFilterTitle);
    $modal.off("click", ".spatial-parent-button", spatialParentClick);
    $modal.on("click", ".spatial-parent-button", spatialParentClick);
  };

  var renderAndShow = function (sectionName) {
    if (!inited) init();
    const props = {
      toDoFilters: Tree.get(["toDoFilters", dataView, sectionName]),
      sectionName: sectionName,
      sectionDisplayName: ToDoConfig.getToDoSubjectConfig([sectionName, "displayName"]),
      toDoSpatialFilterData: Tree.get("toDoSpatialFilterData"),
    };
    addSectionProps(sectionName, props);
    sectionLabels = {};

    const html = getToDoFilterHtml(sectionName, props);
    $modal.html(html);
    $modal.modal("show");
    Form.initializeAndLoadListeners($modal, formKey);

    renderFilterTitles();
    Analytics.sendScreenView("modal", formKey, dataView, sectionName);
  };

  var addSectionProps = function (sectionName, props) {
    const getFilterDataCallback = ToDoConfig.getToDoSubjectConfig(
      [sectionName, "getFilterTemplateDataCallback"],
      false,
    );

    if (getFilterDataCallback) {
      getFilterDataCallback(props);
    }
  };

  var getToDoFilterHtml = function (sectionName, props) {
    const filterTemplate = ToDoConfig.getToDoSubjectConfig([sectionName, "filterTemplate"]);
    return nunjucks.render(filterTemplate, props);
  };

  var filterTitleClick = function () {
    var $section = $(this).closest(".todo-filter-section");
    var $filterDropdown = $(this).next();
    $filterDropdown.slideToggle(200);
    $section.toggleClass("active");
    $section.siblings(".todo-filter-section").removeClass("active");
    $section.parent().find(".filter-dropdown").not($filterDropdown).hide();
  };

  var clearButtonClick = function () {
    var toDoSection = $(this).closest(".modal-content").data("sectionName");
    var sectionFilters = ToDoConfig.getToDoFiltersDataViewConfig([
      dataView,
      "filters",
      toDoSection,
    ]);
    Tree.set(["toDoFilters", dataView, toDoSection], sectionFilters);
    Tree.set(["toDoFilterLabels", dataView, toDoSection], {});
    close();
  };

  var saveButtonClick = function () {
    var sectionName = $(this).closest(".modal-content").data("sectionName");
    var filtersUpdates = Form.getDataFromForm(formKey, false);

    handleUpdatesForSpatialFilters(filtersUpdates);
    if (!validateTodoSpatialFilter(filtersUpdates)) {
      MessageModal.showSimpleWarningModal(`Please select fewer than 20 mainteance zones.`);
      return;
    }

    if (!$.isEmptyObject(filtersUpdates)) {
      Tree.merge(["toDoFilters", dataView, sectionName], filtersUpdates);
    }
    if (!$.isEmptyObject(sectionLabels)) {
      const filterLabels = Tree.get("toDoFilterLabels", dataView, sectionName) ?? {};
      $.extend(filterLabels, sectionLabels);
      Tree.set(["toDoFilterLabels", dataView, sectionName], filterLabels);
    }
    close();
  };

  var handleUpdatesForSpatialFilters = function (filtersUpdates) {
    if ("receivingWater" in filtersUpdates || "catchment" in filtersUpdates) {
      const allSpatial = sectionLabels["receivingWater"] === null;
      filtersUpdates["allSpatial"] = allSpatial;

      if (allSpatial) {
        filtersUpdates["receivingWater"] = null;
        filtersUpdates["catchment"] = null;
      }
    } else if ("maintenanceZone" in filtersUpdates) {
      const allSpatial = sectionLabels["maintenanceZone"] === null;
      filtersUpdates["allSpatial"] = allSpatial;

      if (allSpatial) {
        filtersUpdates["maintenanceZone"] = null;
      }
    }
  };

  var validateTodoSpatialFilter = function (filtersUpdates) {
    // @TODO: Handle receivingWater/catchment
    if ("maintenanceZone" in filtersUpdates && !filtersUpdates["allSpatial"]) {
      if (filtersUpdates["maintenanceZone"]?.length > 20) {
        return false;
      }
    }
    return true;
  };

  var close = function () {
    Form.clearForm(formKey);
    $modal.modal("hide");
    inited = false;
    Analytics.sendScreenView();
  };

  var spatialParentClick = function (e) {
    if ($(e.target).prop("tagName") === "INPUT") {
      e.stopPropagation();
    } else {
      $(this).find(".expand-arrow").toggleClass("open");
    }
  };

  var filterSelectAllClick = function () {
    var $inputsContainer = $(this).closest(".filter-dropdown");
    var $checked = $inputsContainer.find('input[type="checkbox"]').not("[disabled]");

    if ($checked.filter(":checked").length === $checked.length) {
      $checked.each(function () {
        if (this.checked) {
          $(this).prop("checked", false).trigger("input");
        }
      });
    } else {
      $checked.each(function () {
        if (!this.checked) {
          $(this).prop("checked", true).trigger("input");
        }
      });
    }

    updateFilterTitle.call(this);
  };

  var inputCheckboxClick = function () {
    if ($(this).hasClass("spatial-parent")) {
      handleSpatialParentCheckboxClick.call(this);
    } else if ($(this).hasClass("spatial-child")) {
      handleSpatialChildCheckboxClick.call(this);
    }
    updateFilterTitle.call(this);
  };

  var handleSpatialParentCheckboxClick = function () {
    var parentChecked = this.checked;
    var $childrenCheckboxes = $(this).closest(".spatial-group").find("input.spatial-child");
    $childrenCheckboxes.each(function () {
      if (this.checked !== parentChecked) {
        $(this).prop("checked", parentChecked).trigger("input");
      }
    });
  };

  var handleSpatialChildCheckboxClick = function () {
    var $spatialGroup = $(this).closest(".spatial-group");
    var $parentCheckbox = $spatialGroup.find("input.spatial-parent");

    if (this.checked) {
      const childrenCheckedLength = $spatialGroup.find("input.spatial-child:checked").length;
      if (childrenCheckedLength === $spatialGroup.data("length")) {
        if (!$parentCheckbox.prop("checked")) {
          $parentCheckbox.prop("checked", true).trigger("input");
        }
      }
    } else {
      if ($parentCheckbox.prop("checked")) {
        $parentCheckbox.prop("checked", false).trigger("input");
      }
    }
  };

  var updateFilterTitle = function () {
    var $filterSection = $(this).closest(".todo-filter-section");
    var $filterDropdown = $filterSection.find(".filter-dropdown");
    var $filterTitleText = $filterSection.find(".filter-title-text");
    var filterSectionName = $filterSection.data("filterSection");
    const filterDataType = ToDoFunctions.getFilterDataType(filterSectionName, dataView);

    var flags;
    if (filterDataType === "dateRange") {
      flags = getDueDateFilterFlags($filterDropdown, filterSectionName);
    } else if (filterSectionName === "receivingWater") {
      flags = getReceivingWaterFilterFlags($filterDropdown);
    } else {
      flags = getCheckboxFilterFlags($filterDropdown);
    }
    $filterTitleText.find("strong").html(flags);
    setToDoFilterLabels($filterTitleText, flags, filterSectionName);
  };

  var setToDoFilterLabels = function ($filterTitleText, flags, filterSectionName) {
    if (flags === "All") {
      sectionLabels[filterSectionName] = null;
    } else {
      sectionLabels[filterSectionName] = $filterTitleText.text();
    }
  };

  var getCheckboxFilterFlags = function ($filterDropdown) {
    var checkedLabels = getCheckedLabels($filterDropdown);
    var totalCheckboxLength = $filterDropdown.find("input").length;

    if (checkedLabels.length === totalCheckboxLength) {
      return "All";
    } else if (checkedLabels.length === 0) {
      return "None";
    } else if (checkedLabels.length > 2) {
      return `(${checkedLabels.length})`;
    } else {
      return checkedLabels.join(", ");
    }
  };

  var getReceivingWaterFilterFlags = function ($filterDropdown) {
    var totalCheckboxLength = $filterDropdown.find("input").length;
    var receivingWaterCheckedLabels = getCheckedLabels($filterDropdown, "receivingWater", true);
    var catchmentCheckedLabels = getCheckedLabels($filterDropdown, "catchment");
    var totalCheckedLabelsLength =
      receivingWaterCheckedLabels.length + catchmentCheckedLabels.length;

    if (totalCheckedLabelsLength === totalCheckboxLength) {
      return "All";
    } else if (totalCheckedLabelsLength === 0) {
      return "None";
    } else {
      const rwFlag =
        receivingWaterCheckedLabels.length > 2
          ? `(${receivingWaterCheckedLabels.length})`
          : receivingWaterCheckedLabels.join(", ");
      const catchFlag =
        "Catchment: " +
        (catchmentCheckedLabels.length > 2
          ? `(${catchmentCheckedLabels.length})`
          : catchmentCheckedLabels.join(", "));
      return `${rwFlag} \n<br />${catchFlag}`;
    }
  };

  var getCheckedLabels = function ($filterDropdown, name, includeChildren = false) {
    var labels = [];
    var checkboxes = name
      ? $filterDropdown.find(`input[name="${name}"][type="checkbox"]`)
      : $filterDropdown.find(`input[type="checkbox"]`);

    checkboxes.each(function (i, chk) {
      const label = $(chk).parent().text().trim();
      if (this.checked) {
        labels.push(label);
      } else if (!this.checked && includeChildren) {
        const childrenChecked = $(this)
          .closest(".spatial-group")
          .find("input.spatial-child:checked");
        if (childrenChecked.length > 0) {
          labels.push(label);
        }
      }
    });

    return labels;
  };

  var getDueDateFilterFlags = function ($filterDropdown, filterSectionName) {
    var fromValue = $filterDropdown.find(`input[name='${filterSectionName}From']`).val();
    var toValue = $filterDropdown.find(`input[name='${filterSectionName}To']`).val();

    if (fromValue === "" && toValue === "") {
      return "All";
    } else {
      const fromStr = fromValue ? DateTime.formatIsoString(fromValue) : "Any";
      const toStr = toValue ? DateTime.formatIsoString(toValue) : "Any";
      return `${fromStr} - ${toStr}`;
    }
  };

  var renderFilterTitles = function () {
    $modal.find(".filter-dropdown").each(function () {
      updateFilterTitle.call(this);
    });
  };

  var clearDateRange = function () {
    $modal.find(".date input").val("").trigger("input");
  };

  return {
    renderAndShow,
    getCheckboxFilterFlags,
    getDueDateFilterFlags,
    getReceivingWaterFilterFlags,
  };
};

module.exports = ToDoFiltersModal();

const Analytics = require("../general/analytics");
const DateTime = require("../dateTime");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const ToDoConfig = require("../config/toDoConfig");
const ToDoFunctions = require("./toDoFunctions");
const Tree = require("../tree");
