"use strict";

const RoutineMaintenanceGeneralInfo = function () {
  const stringKey = "general-info";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("2N:FormInput", "[name='date']", showDateWarning);
    $page.on("change", `[name="sump"]`, toggleSumpDepthField);
    $page.on("change", `[name="shape"]`, toggleDiameterField);
    $page.on("2N:FormInput", `.structural`, onSaveTypeSepecificInfo);

    RoutineMaintenanceFormGeneral.loadListeners(
      $page,
      data,
      [stringKey, "functional-repair"],
      RoutineMaintenanceController,
    );
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", "[name='date']");
    $page.off("change", `[name="sump"]`, toggleSumpDepthField);
    $page.off("change", `[name="shape"]`, toggleDiameterField);
    $page.off("2N:FormInput", `.structural`, onSaveTypeSepecificInfo);
    RoutineMaintenanceFormGeneral.unloadListeners($page);
  };

  var onSaveTypeSepecificInfo = function (e, value, pathParts, formState, formKey) {
    Form.manuallySetFormDataField(formKey, ["bmpFcsUpdates", ...pathParts], value);
    Form.manuallyUnsetField(formKey, pathParts);

    if (formKey === "routine-maintenance") {
      RoutineMaintenanceCondition.renderCapacityRemaining();
    }
  };

  var getProps = function (data) {
    const props = {
      ...BmpFcsInventoryAdditionalCatchBasinInformation.getCatchBasinOptionProps(),
    };
    RoutineMaintenanceFormGeneral.addProps(props, data);
    props.unitOptions = BmpFcsInventoryConstants.unitOptions;
    props.datumOptions = BmpFcsInventoryConstants.datumOptions;
    return props;
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormGeneral.getTabName();
  };

  var showDateWarning = function (e, value) {
    if (!value) {
      MessageModal.showSimpleWarningModal(`Please enter a valid date.`);
      const defaultDate = DateTime.getTodayIso();
      Form.manuallySetDateTime("routine-maintenance", ["date"], defaultDate, $(e.currentTarget));
    }
  };

  var toggleSumpDepthField = function (e) {
    var toggle = this.value === "true";
    $page.find(".sump-depth").toggleClass("hidden", !toggle);
  };

  var toggleDiameterField = function (e) {
    var toggle = this.value === "circular";
    $page.find(".length, .width").toggleClass("hidden", toggle);
    $page.find(".diameter").toggleClass("hidden", !toggle);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    onSaveTypeSepecificInfo,
  };
};

module.exports = RoutineMaintenanceGeneralInfo();

const BmpFcsInventoryAdditionalCatchBasinInformation = require("../bmpFcsInventoryAdditionalCatchBasinInformation");
const DateTime = require("../../dateTime");
const Form = require("../../general/form");
const MessageModal = require("../../modals/messageModal");
const RoutineMaintenanceCondition = require("./routineMaintenanceCondition");
const RoutineMaintenanceFormGeneral = require("../../general/routineMaintenanceFormGeneral");
const BmpFcsInventoryConstants = require("../bmpFcsInventoryConstants.js");
const RoutineMaintenanceController = require("../routineMaintenanceController");
