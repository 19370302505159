"use strict";

const popupFiles = function () {
  let thisBmpId;

  var attachDownloadClickListener = function () {
    $(".js-popup-files-download").on("click", function () {
      const filename = $(this).data().filename;
      const directory = $(this).data().directory;
      ApiCalls.downloadTrashAttachment(thisBmpId, filename, "fcs", directory);
    });
  };

  var loadFilenamesAndRender = function (bmpName) {
    thisBmpId = bmpName;
    const groupId = Tree.get("activeGroup", "groupId");
    ApiCalls.getSingleFcsFilenames(groupId, bmpName, function (data) {
      const files = ["design", "plan", "additional"].map((directoryName) => {
        return {
          directoryName: directoryName,
          filenames: data[directoryName],
        };
      });

      const html = nunjucks.render("popups/popupFilesPane.njk", {
        files: files,
      });
      const fileListMount = $(".popup-files-pane-mount");
      fileListMount.html(html);
      attachDownloadClickListener();
    });
  };

  return {
    loadFilenamesAndRender,
  };
};

module.exports = popupFiles();

const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
