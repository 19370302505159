"use strict";

const OutfallRoutineMaintenanceConstants = function () {
  var getDefaultStructuralConditionIssues = function () {
    return RoutineMaintenanceFormTable.getDefaultMaintenanceTasksWithOptions(
      BmpFcsInventoryConstants.getAllMaintenanceTasks(),
      {
        filterByConfiguredKey: "outfalls",
      },
    );
  };

  var getRoutineRepairMaintenanceIssuesWithOptions = function (activeMaintenanceIssues) {
    return RoutineMaintenanceFormTable.getDefaultMaintenanceTasksWithOptions(
      BmpFcsInventoryConstants.getAllMaintenanceTasks(),
      {
        maintenanceTasks: activeMaintenanceIssues,
      },
    );
  };

  var organizeRoutineMaintenanceTasks = function (data) {
    const [routineIssues, repairIssues] =
      RoutineMaintenanceConstants.extractRoutineAndRepairIssues(data);

    data.structuralConditionIssues = routineIssues;
    data.activeMaintenanceIssues = repairIssues;

    return data;
  };

  var findMaintenanceTask = function (key) {
    const foundTask = BmpFcsInventoryConstants.getAllMaintenanceTasks().find(function (task) {
      return task.key === key || task.aliases?.includes(key);
    });
    return $.extend(true, {}, foundTask);
  };

  return {
    getDefaultStructuralConditionIssues,
    getRoutineRepairMaintenanceIssuesWithOptions,
    organizeRoutineMaintenanceTasks,
    findMaintenanceTask,
  };
};

module.exports = OutfallRoutineMaintenanceConstants();

const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const RoutineMaintenanceConstants = require("../bmpfcs/routineMaintenanceConstants");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
