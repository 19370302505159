"use strict";

const KPI = function () {
  var init = function () {
    loadListeners();
    loadKpi();
  };

  var loadListeners = function () {
    // @TODO: Move this to a module for summary table
    // @TODO: Add mapId to selector?
    var $summaryTableWrapper = $(".summary-table-wrapper");
    $summaryTableWrapper.off();
    $summaryTableWrapper.on("click", ".toggle-button", toggleSummaryClick);
  };

  const toggleSummaryClick = function () {
    var $summaryTableWrapper = PageFunctions.getCurrentPage().find(".summary-table-wrapper");
    if ($summaryTableWrapper.find(".toggle-button").hasClass("open")) {
      ProgressMapTable.closeSummaryTable();
    } else {
      ProgressMapTable.openSummaryTable();
    }
  };

  var updateKpiTable = function (data) {
    if (data) {
      var layerName = DataViewFunctions.getCurrentLayerName();
      var kpiTemplate = getKpiTemplate(layerName);
      data["layerName"] = layerName;
      var theHtml = nunjucks.render(kpiTemplate, data);

      handleKpiTableDisplay();
      $(".kpi-div").html(theHtml);
    }
  };

  var handleKpiTableDisplay = function () {
    const renderNavKpiTable = LayerFunctions.getCurrentLayerProperty("renderNavKpiTable");
    $(".kpi-div").toggle(!!renderNavKpiTable);
  };

  var getKpiTemplate = function (layerName) {
    var kpiTemplate = layerName.includes("trashProgress")
      ? "report/report/kpiTrash.html"
      : "report/report/kpiSwtelr.html";
    return kpiTemplate;
  };

  var getDisplayedCount = function (assetsArray) {
    let count = 0;
    assetsArray.forEach((asset) => {
      asset.total > 0 ? (count += 1) : null;
    });

    return count;
  };

  var loadAssetManagementCallBack = function (data) {
    const count = getDisplayedCount(data);
    Tree.set(["reportDataFiltered", "data"], data);
    Tree.set(["reportDataFiltered", "isFetching"], false);
    const formattedData = [
      {
        drainLengthTarget: "60,000",
        displayedAssetCount: count,
        year: 2021,
        dataArray: data,
      },
    ];
    renderKpi(formattedData);
  };

  var loadKpiCallback = function (data) {
    data = sortByYear(data);
    Tree.set(["reportDataFiltered", "data"], data);
    Tree.set(["reportDataFiltered", "isFetching"], false);
    renderKpi(data);
  };

  var disableNavToolMenuItem = function (dataView) {
    const selectedDate = Tree.get("yearDropdownSelection");
    if (selectedDate !== 2021) {
      $(`li[data-view=${dataView}]`).removeClass("dropdown-item").addClass("disabled");
    }
  };

  var hideDisabledTabs = function () {
    const $tabs = $("#tool-nav-tabs .page-link");
    if (DataViewFunctions.getCurrentDataViewProperty("hideChartTab")) {
      $tabs.filter(`[data-page-name='chart']`).closest("li").addClass("hidden");
    } else {
      $tabs.filter(`[data-page-name='chart']`).closest("li").removeClass("hidden");
    }
    if (DataViewFunctions.getCurrentDataViewProperty("hideSummaryTab")) {
      $tabs.filter(`[data-page-name='summary']`).closest("li").addClass("hidden");
    } else {
      $tabs.filter(`[data-page-name='summary']`).closest("li").removeClass("hidden");
    }
    if (DataViewFunctions.getCurrentDataViewProperty("hideProgressTab")) {
      $tabs.filter(`[data-page-name='report']`).closest("li").addClass("hidden");
    } else {
      $tabs.filter(`[data-page-name='report']`).closest("li").removeClass("hidden");
    }
  };

  var loadKpi = function () {
    hideDisabledTabs();
    if (Tree.get(["reportDataFiltered", "isFetching"])) return;
    const currentLayerName = DataViewFunctions.getCurrentLayerName();
    if (currentLayerName === "connectivityInputs") {
      fetchingTrue();
      ConnectivityInputsLayer.getKpi(loadKpiCallback);
    } else if (currentLayerName === "landuseInputs") {
      return;
    } else {
      handleGetKpiByDataView();
    }
  };

  var renderKpi = function (allKpiData, selectedYear = null, summaryTableOnly = false) {
    if (selectedYear === null) {
      selectedYear = AnnualReports.getSelectedYear();
    }

    const thisYearData = getThisYearData(allKpiData, selectedYear);
    const mapId = Tree.get("mapId");

    if (!thisYearData || Progeny.activeHasProgeny() || !mapId) {
      $(".kpi-div").toggle(false);
      ProgressMapTable.toggle(false);
      if (!Progeny.activeHasProgeny()) {
        return;
      }
    }

    if (allKpiData.length === 0) {
      return;
    }

    // @TODO: Move to a place to handle all display logic
    disableNavToolMenuItem("assetManagementView");

    handleKpiTableDisplay();
    renderKpiByDataView(allKpiData, thisYearData, selectedYear, summaryTableOnly);
  };

  var renderKpiByDataView = function (
    allKpiData,
    thisYearData,
    selectedYear,
    summaryTableOnly = false,
  ) {
    var summaryTableTemplate = LayerFunctions.getCurrentLayerProperty("summaryTableTemplate");

    if (summaryTableTemplate) {
      ProgressMapTable.render(thisYearData, selectedYear);
    } else {
      ProgressMapTable.toggle(false);
    }
    if (!summaryTableOnly) {
      if (LayerFunctions.getCurrentLayerProperty("renderNavKpiTable")) {
        updateKpiTable(thisYearData);
      }
      if (DataViewFunctions.getCurrentDataViewProperty("reportSummaryEnabled")) {
        ReportSummary.load(thisYearData);
      }
      if (DataViewFunctions.getCurrentDataViewProperty("progressChartEnabled")) {
        ReportProgressChart.init(allKpiData);
      }
    }
  };

  var getThisYearData = function (data, selectedYear) {
    var dataView = Tree.get("dataView");

    if (DataViewFunctions.isTelrDataViewInNonInputsMap(dataView)) {
      data = data.map(updateTelrData);
    }

    var thisYearData = data.find((datum) => datum?.year === selectedYear);
    return thisYearData;
  };

  var updateTelrData = function (datum) {
    // @TODO: Move all this to the backend
    /* SWTELR uses this same data, so instead of updating the data output from the Api
    I am updating cleaning the data on the clientside. When we get rid of TELR,
    we should update the data side */
    datum["year"] = datum.Year;
    datum["telrSubmitted"] = datum.TELR_Submitted == true;

    datum["currentRunoff"] = Number(datum.Current_Load);
    datum["reductionRunoff"] = datum.Load_Reduction;
    datum["baselineRunoff"] = datum.Load_Reduction + datum.currentRunoff;
    datum["reductionPercRunoff"] =
      datum.Load_Reduction == 0 ? 0 : (datum.Load_Reduction / datum.baselineRunoff) * 100;

    datum["currentTss"] = Number(datum.TSS_Current_Load);
    datum["reductionTss"] = datum.TSS_Load_Reduction;
    datum["baselineTSS"] = datum.TSS_Load_Reduction + datum.currentTss;
    datum["reductionPercTss"] = (datum.TSS_Load_Reduction / datum.baselineTSS) * 100;

    datum["currentRunoffRatio"] = Number(datum.Current_Load);
    datum["reductionRunoffRatio"] = datum.Load_Reduction;
    datum["baselineRunoffRatio"] = datum.Load_Reduction + datum.currentRunoff;

    datum["runoffDecentralizedConnected"] = Number(datum.runoffDecentralizedConnected);
    datum["runoffCentralizedConnected"] = Number(datum.runoffCentralizedConnected);
    datum["runoffParcelConnected"] = Number(datum.runoffParcelConnected);
    datum["totalRunoffReductionConnected"] = Number(datum.totalRunoffReductionConnected);

    datum["particulateDecentralizedConnected"] = Number(datum.particulateDecentralizedConnected);
    datum["particulateCentralizedConnected"] = Number(datum.particulateCentralizedConnected);
    datum["particulateParcelConnected"] = Number(datum.particulateParcelConnected);
    datum["particulateRoadConnected"] = Number(datum.particulateRoadConnected);
    datum["totalParticulateReductionConnected"] = Number(datum.totalParticulateReductionConnected);

    datum["layerName"] = [
      "Year",
      "Current_Load",
      "TSS_Current_Load",
      "Load_Reduction",
      "TSS_Load_Reduction",
      "TELR_Submitted",
    ].forEach((key) => {
      delete datum[key];
    });
    return datum;
  };

  var fetchingTrue = function () {
    Tree.set(["reportDataFiltered", "isFetching"], true);
  };

  var handleGetKpiByDataView = function () {
    var dataView = Tree.get("dataView");
    const kpiApiCall = DataViewFunctions.getKpiApiCall(dataView);
    if (!kpiApiCall) return;

    if (dataView === "trashView") {
      if (Progeny.activeHasProgeny()) {
        fetchingTrue();
        ReportApiCalls.getAllSubmittedMs4sKpiData(loadKpiCallback);
      } else {
        const onlyAccepted = !Progeny.canViewUnsubmittedDataForGroup(
          Tree.get("activeGroup", "groupId"),
        );
        fetchingTrue();
        const omitFilters = Tree.get("activeTab") === "summary";
        ReportApiCalls.getKpiData(loadKpiCallback, omitFilters, onlyAccepted);
      }
    } else if (DataViewFunctions.isTelrDataView(dataView)) {
      const esriLayerId = LayerDropdown.getCurrentLayerDropdownProperty("esriLayerId");
      if (
        (Progeny.activeHasProgeny() && dataView === "runoffRatioView") ||
        (esriLayerId !== null && esriLayerId !== undefined)
      ) {
        return;
      }
      fetchingTrue();
      const matrix = LayerDropdown.isMatrixBaseline() || LayerDropdown.isMatrixCurrent();
      kpiApiCall(loadKpiCallback, matrix);
    } else if (dataView === "assetManagementView") {
      if (Progeny.activeGroupIsRegulator() && Progeny.activeHasProgeny()) {
        ProgressMapTable.toggle(false);
        return;
      }
      fetchingTrue();
      kpiApiCall(loadAssetManagementCallBack);
    } else {
      fetchingTrue();
      kpiApiCall(loadKpiCallback);
    }
  };

  var loadKpiByGroupId = function (groupId) {
    if (Tree.get(["reportDataFiltered", "isFetching"])) return;

    var dataView = Tree.get("dataView");
    if (dataView === "trashView") {
      const onlyAccepted = !Progeny.canViewUnsubmittedDataForGroup(
        Tree.get("activeGroup", "groupId"),
      );
      fetchingTrue();
      ReportApiCalls.getKpiData(loadKpiCallback, false, onlyAccepted, groupId);
    } else if (DataViewFunctions.isTelrDataView(dataView)) {
      fetchingTrue();
      const matrix = LayerDropdown.isMatrixBaseline() || LayerDropdown.isMatrixCurrent();
      ReportApiCalls.getTelrKpi(loadKpiCallback, matrix, groupId);
    }
  };

  var sortByYear = function (data) {
    data.sort((d1, d2) => {
      if (+d1.year > +d2.year) return 1;
      if (+d1.year < +d2.year) return -1;
      return 0;
    });
    return data;
  };

  return {
    loadKpi,
    loadKpiByGroupId,
    loadListeners,
    init,
    getKpiTemplate,
    getThisYearData,
    handleKpiTableDisplay,
    renderKpi,
    disableNavToolMenuItem,
    hideDisabledTabs,
  };
};

module.exports = KPI();

const ReportApiCalls = require("../reportApiCalls");
const ReportSummary = require("./reportSummary");
const Progeny = require("../../login/progeny");
const Tree = require("../../tree");
const ReportProgressChart = require("./progress/reportProgressChart");
const ProgressMapTable = require("./progress/progressMapTable");
const AnnualReports = require("../annualReports");
const DataViewFunctions = require("../dataViewFunctions");
const PageFunctions = require("../pageFunctions");
const LayerFunctions = require("../mapFunctions/layerFunctions");
const ConnectivityInputsLayer = require("../inputs/layers/connectivityInputsLayer");
const LayerDropdown = require("../mapFunctions/layerDropdown");
