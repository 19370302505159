"use strict";

const InputsMap = function () {
  var map = null;
  var mapLayers = null;

  var loadInputsMap = function () {
    const activeGroupId = Tree.get("activeGroup", "groupId");
    const lastGroupId = Tree.get("lastGroupId", "inputs");
    var firstLoad = false;
    var user = Tree.get("activeGroup");

    initLayers();

    ToolNavTitle.set(user.group);

    loadListeners();

    if (!map) {
      firstLoad = true;

      TreeUpdates.turnOnUpdates(mapLayers, getActiveInputsMap, getCurrentBasemap, "inputs");

      map = initMap(user.group_role === "regulator");
      MapFunctions.startLegendZoomListeners(map);
      loadLayerListenersForInputsMap();
    }

    if (firstLoad || activeGroupId !== lastGroupId) {
      if (Progeny.activeHasProgeny()) {
        loadInputsRegulatorMap();
      } else {
        loadInputsMS4Map();
      }
    }

    CommonMapFunctions.afterMapLoad();
  };

  var initLayers = function () {
    if (!mapLayers) {
      mapLayers = LayerFunctions.getAllLayersAsMapLayers();
    }
  };

  var loadLayerListenersForInputsMap = function () {
    // @TODO: Set up config to load layer listneres dynamically
    const FlowRoutingLayer = require("../outfalls/flowRoutingLayer");
    FlowRoutingLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const OutfallsSimpleLayer = require("../outfalls/outfallsSimpleLayer");
    OutfallsSimpleLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const SoilInputsLayer = require("./layers/soilInputsLayer");
    SoilInputsLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const ImperviousInputsLayer = require("./layers/imperviousInputsLayer");
    ImperviousInputsLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const ConnectivityInputsLayer = require("./layers/connectivityInputsLayer");
    ConnectivityInputsLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const LanduseInputsLayer = require("./layers/landuseInputsLayer");
    LanduseInputsLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const StreamsEsriDynamicMapLayer = require("../general/streamsEsriDynamicMapLayer");
    StreamsEsriDynamicMapLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const TrashConditionEsriDynamicMapLayer = require("../trash/trashConditionEsriDynamicMapLayer");
    TrashConditionEsriDynamicMapLayer.loadLayerListenersForMap(map, mapLayers, "inputs");
    const TrashConditionCertaintyEsriDynamicMapLayer = require("../trash/trashConditionCertaintyEsriDynamicMapLayer");
    TrashConditionCertaintyEsriDynamicMapLayer.loadLayerListenersForMap(map, mapLayers, "inputs");

    const ParcelsPluEsriDynamicMapLayer = require("../general/parcelsPluEsriDynamicMapLayer");
    ParcelsPluEsriDynamicMapLayer.loadLayerListenersForMap(map, mapLayers, "inputs");
  };

  var loadInputsMS4Map = function () {
    const annualReportingCallback = function () {
      Tree.set(["layers", "inputs", "catchments", "isEnabled"], true);
    };
    if (Tree.get("annualReportingLoaded")) {
      annualReportingCallback();
    } else {
      Actions.loadAnnualReporting(annualReportingCallback);
    }

    Tree.set(["layers", "inputs", "ms4Boundary", "isEnabled"], true);
    PopupPhotos.loadListeners(); //Only need to bring in for one tab, all maps use same popups

    CommonMapFunctions.afterMS4MapLoad(map);
  };

  var loadListeners = function () {
    Table.loadListeners("inputs");
    DisplayOptions.startLegendListeners("inputs", mapLayers);
    CatchmentView.startListeners();

    if (Progeny.rootGroupIsRegulator()) {
      Ms4Table.loadListeners();
    }
  };

  var loadInputsRegulatorMap = function () {
    RegionalView.setRegionalViewDom();
    Ms4Table.loadListeners();
    RegionAreaLayer.loadLayer();
  };

  var initMap = function (isRegulator) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const maxZoom = rasterBasemap ? 19 : 21;
    map = L.map("map", {
      zoomControl: false,
      tileLayer: {
        maxNativeZoom: maxZoom,
      },
      minZoom: 3,
      maxZoom: maxZoom,
    });

    CommonMapFunctions.setMapHeight("map", "inputs");
    mapLayers.basemap = MapFunctions.addBasemap(map, mapLayers.basemap, "Gray");
    L.control
      .scale({
        position: "bottomright",
        metric: false,
      })
      .addTo(map);
    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(map);

    if (!isRegulator) {
      map.setView(Tree.get("activeGroup", "latlng"), 14);
    }
    MapPaneFunctions.createCustomMapPanes(map);

    return map;
  };

  var getActiveInputsMap = function () {
    return map;
  };

  var getActiveLayers = function () {
    initLayers();
    return mapLayers;
  };

  var getCurrentBasemap = function () {
    return mapLayers.basemap;
  };

  return {
    loadInputsMap,
    loadInputsMS4Map,
    getActiveInputsMap,
    getActiveLayers,
    loadInputsRegulatorMap,
  };
};

module.exports = InputsMap();

const TreeUpdates = require("../mapFunctions/treeUpdates");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Actions = require("../actions");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
const Tree = require("../../tree");
const ToolNavTitle = require("../toolNavTitle");
const Table = require("../mapFunctions/table");
const Ms4Table = require("../ms4Table");
const Progeny = require("../../login/progeny");
const RegionalView = require("../regionalView");
const RegionAreaLayer = require("../general/regionAreaLayer");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const DisplayOptions = require("../mapFunctions/displayOptions");
const CatchmentView = require("../mapFunctions/catchmentView");
const LayerFunctions = require("../mapFunctions/layerFunctions");
const MapPaneFunctions = require("../mapFunctions/mapPaneFunctions");
const FeatureFlag = require("../../settings/featureFlag");
