"use strict";

const RoutineMaintenanceGeneralInfoBmp = function () {
  const stringKey = "general-info-bmp";
  let $page;

  var loadListeners = function (props) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("2N:FormInput", "[name='date']", showDateWarning);
    $page.on("change", `[name="sump"]`, toggleSumpDepthField);
    $page.on("change", `[name="shape"]`, toggleDiameterField);
    $page.on(
      "2N:FormInput",
      `.bmp-type-specific-info`,
      RoutineMaintenanceGeneralInfo.onSaveTypeSepecificInfo,
    );
    BmpFcsInventoryBmpFcsInformation.showAdditionalFieldsByType(props.bmpType, $page);
    hideNonStructuralAttributeFields($page);

    RoutineMaintenanceFormGeneral.loadListeners(
      $page,
      props,
      [stringKey, "functional-repair"],
      RoutineMaintenanceController,
    );
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", "[name='date']");
    $page.off("change", `[name="sump"]`, toggleSumpDepthField);
    $page.off("change", `[name="shape"]`, toggleDiameterField);
    $page.off(
      "2N:FormInput",
      `.bmp-type-specific-info`,
      RoutineMaintenanceGeneralInfo.onSaveTypeSepecificInfo,
    );
    RoutineMaintenanceFormGeneral.unloadListeners($page);
  };

  var hideNonStructuralAttributeFields = function ($thisPage) {
    $thisPage.find(`[data-field="treatment-capacity"]`).toggle(false);
    $thisPage.find(`[data-field="footprint"]`).toggle(false);
  };

  var getProps = function (data) {
    const props = {
      ...BmpFcsInventoryAdditionalBmpInformation.getBmpOptionProps(),
    };
    RoutineMaintenanceFormGeneral.addProps(props, data);
    return props;
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormGeneral.getTabName();
  };

  var showDateWarning = function (e, value) {
    if (!value) {
      MessageModal.showSimpleWarningModal(`Please enter a valid date.`);
      const defaultDate = DateTime.getTodayIso();

      Form.manuallySetDateTime("routine-maintenance", ["date"], defaultDate, $(e.currentTarget));
    }
  };

  var toggleSumpDepthField = function (e) {
    var toggle = this.value === "true";
    $page.find(".sump-depth").toggleClass("hidden", !toggle);
  };

  var toggleDiameterField = function (e) {
    var toggle = this.value === "circular";
    $page.find(".length, .width").toggleClass("hidden", toggle);
    $page.find(".diameter").toggleClass("hidden", !toggle);
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    hideNonStructuralAttributeFields,
  };
};

module.exports = RoutineMaintenanceGeneralInfoBmp();

const BmpFcsInventoryAdditionalBmpInformation = require("../bmpFcsInventoryAdditionalBmpInformation");
const BmpFcsInventoryBmpFcsInformation = require("../bmpFcsInventoryBmpFcsInformation");
const DateTime = require("../../dateTime");
const Form = require("../../general/form");
const MessageModal = require("../../modals/messageModal");
const RoutineMaintenanceFormGeneral = require("../../general/routineMaintenanceFormGeneral");
const RoutineMaintenanceGeneralInfo = require("./routineMaintenanceGeneralInfo");
const RoutineMaintenanceController = require("../routineMaintenanceController");
