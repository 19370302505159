/*
  This file lists all layers by layer name.

  MainMap.mapLayers and ModalMap.mapLayers use the layer name
  postfixed with "Layer". So the "bmps" layer can be accessed
  from MainMap.mapLayers.bmpsLayer.

  Postfixing "Layer" kinda sucks, so it should probably be refactored.
*/
const LayerConfig = function () {
  return {
    get: function () {
      const ActivitiesLayer = require("../peo/activitiesLayer");

      return {
        trashConditionEsriDynamicMap: {
          legendClass: "legend-condition",
          module: TrashConditionEsriDynamicMapLayer,
          isEsriLayer: true,
          enableCatchmentLayer: true,
          pane: "underCatchmentPane",
          opacity: 0.7,
        },
        landuseEsriDynamicMap: {
          legendClass: "legend-landuse",
          module: LanduseEsriDynamicMapLayer,
          isEsriLayer: true,
          enableCatchmentLayer: true,
          pane: "underCatchmentPane",
          opacity: 0.7,
        },
        streamsEsriDynamicMap: {
          legendClass: "legend-streams",
          legendType: "invisible",
          module: StreamsEsriDynamicMapLayer,
          isEsriLayer: true,
          enableCatchmentLayer: true,
          pane: "underCatchmentPane",
          emptyLayerDef: true,
        },
        streamsEsriFeature: {
          legendClass: "legend-streams",
          legendType: "invisible",
          module: StreamsEsriFeatureLayer,
          isEsriLayer: true,
          emptyLayerDef: true,
          minZoom: 10,
        },
        projects: { legendClass: "legend-projects" },
        flowRouting: {
          legendClass: "legend-flow-routing",
          legendType: "invisible",
          module: FlowRoutingLayer,
        },
        outfalls: {
          legendClass: "legend-outfalls",
          legendType: "invisible",
          clusteringEnabled: true,
          module: OutfallsLayer,
        },
        muniOutfalls: {
          legendClass: "legend-muni-outfalls",
          legendType: "invisible",
          clusteringEnabled: true,
          module: MuniOutfallsGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        streams: { legendClass: "legend-streams", legendType: "invisible" },
        stormdrains: { legendClass: "legend-stormdrains", legendType: "invisible" },
        bounds: {
          legendClass: "legend-boundary",
          legendType: "invisible",
          doNotReset: true,
        },
        structuralBmps: {
          legendClass: "legend-bmps",
          clusteringEnabled: true,
          module: StructuralBmpsGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        bmpDrainageG2: {
          legendClass: "legend-full-drainage",
          module: BmpDrainageG2Layer,
          isGisDataViewPrimaryLayer: false,
          noWfsLayer: true,
          pane: "overCatchmentPane",
        },
        bmps: { legendClass: "legend-bmps", clusteringEnabled: true, module: BmpLayer },
        fcs: { legendClass: "legend-fcs", clusteringEnabled: true, module: FcsLayer },
        parcelsPluEsriDynamicMap: {
          legendClass: "legend-priority",
          module: ParcelsPluEsriDynamicMapLayer,
          isEsriLayer: true,
          enableCatchmentLayer: true,
          pane: "underCatchmentPane",
          opacity: 0.7,
        },
        fullDrainageBmp: { legendClass: "legend-full-drainage" },
        fullDrainageFcs: { legendClass: "legend-full-drainage" },
        constructionProject: {
          legendClass: "legend-construction-project",
          clusteringEnabled: true,
          module: ConstructionProjectLayer,
        },
        constructionProjectG2: {
          legendClass: "legend-construction-project",
          clusteringEnabled: true,
          module: ConstructionProjectGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        constructionProjectAreaG2: {
          legendClass: "legend-construction-project-area",
          module: ConstructionProjectAreaG2Layer,
          isGisDataViewPrimaryLayer: false,
          noWfsLayer: true,
          pane: "overCatchmentPane",
        },
        postConstructionProjectG2: {
          legendClass: "legend-lid-project",
          clusteringEnabled: true,
          module: PostConstructionProjectGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        postConstructionProjectAreaG2: {
          legendClass: "legend-lid-project",
          module: PostConstructionProjectAreaG2Layer,
          isGisDataViewPrimaryLayer: false,
          noWfsLayer: true,
          pane: "overCatchmentPane",
        },
        trashLines: { legendClass: "legend-trash-assessments" },
        lidProjectArea: { legendClass: "legend-lid-project-area", module: LidProjectAreaLayer },
        constructionProjectArea: {
          legendClass: "legend-construction-project-area",
          module: ConstructionProjectAreaLayer,
        },
        lidProject: {
          legendClass: "legend-lid-project",
          clusteringEnabled: true,
          module: LidProjectLayer,
        },
        projectDeliveryBmps: {
          legendClass: "legend-bmps",
          clusteringEnabled: true,
          module: projectDeliveryBmpsGeoServerLayer,
          isGisDataViewPrimaryLayer: false,
        },
        telrResultBmp: {
          legendClass: "legend-bmps",
          clusteringEnabled: true,
          module: TelrResultBmpGeoServerLayer,
          isGisDataViewPrimaryLayer: false,
        },
        stormdrainGeoServer: {
          legendClass: "legend-stormdrains",
          legendType: "invisible",
          module: StormdrainGeoServerLayer,
          isGisDataViewPrimaryLayer: false,
          noWfsLayer: true,
          onlineFiltering: false,
          isLinearAsset: true,
        },
        lidProjectBmp: { legendClass: "legend-bmps", clusteringEnabled: true },
        runoffCondition: { legendClass: "legend-runoff-condition" },
        muniCatchBasinDrainageArea: {
          legendClass: "legend-full-drainage",
          module: MuniCatchBasinDrainageAreaLayer,
        },
        muniSbmpDrainageArea: {
          legendClass: "legend-full-drainage",
          module: MuniSbmpDrainageAreaLayer,
        },
        muniCatchBasin: {
          legendClass: "legend-muni-catch-basin",
          clusteringEnabled: true,
          module: MuniCatchBasinGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        muniCatchBasinReport: {
          legendClass: "legend-muni-catch-basin",
          clusteringEnabled: true,
          module: MuniCatchBasinReportGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        muniManhole: {
          legendClass: "legend-muni-manhole",
          clusteringEnabled: true,
          module: MuniManholeGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        muniCleanout: {
          legendClass: "legend-muni-cleanout",
          clusteringEnabled: true,
          module: MuniCleanoutGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        muniBmp: {
          legendClass: "legend-muni-bmp",
          clusteringEnabled: true,
          module: MuniBmpGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        lidBmp: {
          legendClass: "legend-muni-bmp",
          clusteringEnabled: true,
          module: LidBmpGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        muniCulvert: {
          legendClass: "legend-muni-culvert",
          module: MuniCulvertGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
          isLinearAsset: true,
        },
        muniOpenChannel: {
          legendClass: "legend-muni-open-channel",
          module: MuniOpenChannelGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
          isLinearAsset: true,
        },
        maintenanceZonesLegend: {
          legendClass: "legend-maintenance-zones",
          module: MaintenanceZonesLegendLayer,
        },
        highPriorityArea: {
          legendClass: "legend-high-priority-area",
          module: HighPriorityAreaLayer,
        },
        rw: {},
        maintenanceZones: { doNotReset: true },
        catchments: { doNotReset: true },
        highways: { doNotReset: true },
        ranking: {},
        baseStormdrain: {},
        middleStormdrain: {},
        stormdrain: {},
        regional: {},
        survey: {},
        blueDot: { doNotReset: true },
        drainage: {},
        singleMarker: {},
        projectAssociatedBmp: { module: ProjectAssociatedBmpLayer },
        propertyBmp: { module: PropertyBmpLayer },
        catchBasinBulkClean: { module: CatchBasinBulkCleanLayer },
        centralizedBmps: { module: CentralizedBmpLayer },
        roads: {
          legendClass: "legend-street-sweeping-route",
          module: RoadBulkOmEsriLayer,
          isEsriLayer: true,
          pane: "stormdrainPane",
        },
        roadPriority: {
          legendClass: "legend-street-sweeping-route-priority",
        },
        roadSweeperType: {
          legendClass: "legend-street-sweeping-route-sweeper-type",
        },
        roadSweepingFrequency: {
          legendClass: "legend-street-sweeping-route-sweeping-frequency",
        },
        roadCurbAccess: { legendClass: "legend-street-sweeping-route-curb-access" },
        roadPci: { legendClass: "legend-street-sweeping-route-pci" },
        streetSweepingRoute: {
          legendClass: "legend-street-sweeping-route",
          module: StreetSweepingLayer,
          isEsriLayer: true,
          pane: "stormdrainPane",
        },
        streetSweepingRoutePriority: {
          legendClass: "legend-street-sweeping-route-priority",
        },
        streetSweepingRouteSweeperType: {
          legendClass: "legend-street-sweeping-route-sweeper-type",
        },
        streetSweepingRouteSweepingFrequency: {
          legendClass: "legend-street-sweeping-route-sweeping-frequency",
        },
        streetSweepingRouteCurbAccess: { legendClass: "legend-street-sweeping-route-curb-access" },
        streetSweepingRoutePci: { legendClass: "legend-street-sweeping-route-pci" },
        monitoringLocation: {
          clusteringEnabled: true,
          legendClass: [
            "legend-monitoring-location",
            "legend-idde-outfall",
            "legend-idde-catch-basin",
            "legend-idde-manhole",
          ],
          module: MonitoringLocationLayer,
        },
        iddeOutfall: {
          legendClass: ["legend-monitoring-location", "legend-idde-outfall"],
          module: IddeOutfallLayer,
        },
        iddeCatchBasin: {
          legendClass: ["legend-monitoring-location", "legend-idde-catch-basin"],
          module: IddeCatchBasinLayer,
        },
        iddeCatchBasinEsriDynamicMap: {
          legendClass: "legend-idde-catch-basin",
          module: IddeCatchBasinEsriDynamicMapLayer,
        },
        iddeManhole: {
          legendClass: ["legend-monitoring-location", "legend-idde-manhole"],
          module: IddeManholeGeoServerLayer,
          noWfsLayer: true,
        },
        dryWeatherOutfall: {
          legendClass: ["legend-monitoring-location", "legend-idde-outfall"],
          module: DryWeatherOutfallGeoServerLayer,
          clusteringEnabled: FeatureFlag.enabled("dry-weather-g2-layer-cluster") ? true : false,
          noWfsLayer: FeatureFlag.enabled("dry-weather-g2-layer-cluster") ? false : true,
        },
        dryWeatherCatchBasin: {
          legendClass: ["legend-monitoring-location", "legend-idde-catch-basin"],
          module: DryWeatherCatchBasinGeoServerLayer,
          noWfsLayer: true,
        },
        iddeManholeEsriDynamicMap: {
          legendClass: "legend-idde-manhole",
          module: IddeManholeEsriDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        assetsBmpEsriDynamicMap: {
          legendClass: "legend-assets-bmp",
          module: AssetsBmpGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        assetsCatchBasinEsriDynamicMap: {
          legendClass: "legend-assets-catch-basins",
          module: AssetsCatchBasinGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        assetsManholeEsriDynamicMap: {
          legendClass: "legend-assets-manhole",
          module: AssetsManholeGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        assetsLiftStationEsriDynamicMap: {
          legendClass: "legend-assets-lift-station",
          module: AssetsLiftStationGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        assetsCleanOutEsriDynamicMap: {
          legendClass: "legend-assets-clean-out",
          module: AssetsCleanoutsGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        assetsCulvertEsriDynamicMap: {
          legendClass: "legend-assets-culvert",
          module: AssetsCulvertGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
          isLinearAsset: true,
        },
        assetsOutfallEsriDynamicMap: {
          legendClass: "legend-assets-outfall",
          module: AssetsOutfallGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "outfallPane",
        },
        outfallsSimple: {
          legendClass: "legend-outfalls-simple",
          legendType: "invisible",
          module: OutfallsSimpleLayer,
        },
        simpleOutfallGeoServerDynamicMap: {
          legendClass: "legend-outfalls-simple",
          module: SimpleOutfallGeoServerDynamicMapLayer,
          isEsriLayer: true,
          isGisDataViewPrimaryLayer: false,
          pane: "outfallPane",
          clusteringEnabled: false,
          legendType: "invisible",
          noWfsLayer: true,
        },
        assetsOpenChannelEsriDynamicMap: {
          legendClass: "legend-assets-open-channel",
          module: AssetsOpenChannelGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "stormdrainPane",
          isLinearAsset: true,
        },
        assetsPipeEsriDynamicMap: {
          legendClass: "legend-assets-pipe",
          module: AssetsPipeGeoServerDynamicMapLayer,
          isEsriLayer: true,
          pane: "stormdrainPane",
          isLinearAsset: true,
        },
        assets: {
          legendClass: "legend-assets-assets",
          module: AssetsAssetsEsriDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        "probability-failure": {
          legendClass: "legend-assets-pof",
          module: AssetsPofDynamicMapLayer,
          isEsriLayer: true,
          pane: "markerPane",
        },
        cof: {
          legendClass: "legend-assets-cof",
          isEsriLayer: true,
        },
        criticality: {
          legendClass: "legend-assets-criticality",
          isEsriLayer: true,
        },
        incidents: {
          clusteringEnabled: true,
          legendClass: "legend-incidents",
          module: IncidentsLayer,
        },
        indcomFacilities: {
          clusteringEnabled: true,
          legendClass: "legend-facilities",
          module: IndcomFacilityGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        muniLiftStations: {
          legendClass: "legend-muni-lift-stations",
          clusteringEnabled: true,
          module: MuniLiftStationGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
        },
        muniPipes: {
          legendClass: "legend-muni-pipes",
          module: MuniPipesGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
          isLinearAsset: true,
        },
        planProperties: {
          legendClass: "legend-esg-properties",
          module: PlanPropertiesGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
          pane: "tilePane",
          clusteringEnabled: true,
        },
        allProperties: {
          legendClass: "legend-esg-all-properties",
          module: AllPropertiesGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
          pane: "tilePane",
          clusteringEnabled: true,
        },
        otherProperties: {
          legendClass: "legend-esg-other-properties",
          module: OtherPropertiesLayer,
        },
        incentivePrograms: {
          legendClass: "legend-esg-incentive-programs",
          module: IncentiveProgramsGeoServerLayer,
          isGisDataViewPrimaryLayer: true,
          pane: "tilePane",
          clusteringEnabled: true,
        },
        activities: {
          module: ActivitiesLayer,
          isGisDataViewPrimaryLayer: true,
          clusteringEnabled: true,
        },
        scenarios: { module: ScenariosLayer },
        telrResult: { module: TelrResultLayer, legendClass: "legend-telr-result" },
      };
    },
  };
};

module.exports = LayerConfig();

const BmpLayer = require("../bmpfcs/bmpLayer");
const BmpDrainageG2Layer = require("../bmpFcsG2/bmpDrainageG2Layer");
const CatchBasinBulkCleanLayer = require("../muni/catchBasinBulkCleanLayer");
const CentralizedBmpLayer = require("../bmpfcs/centralizedBmpLayer");
const ConstructionProjectAreaLayer = require("../construction/constructionProjectAreaLayer");
const ConstructionProjectLayer = require("../construction/constructionProjectLayer");
const ConstructionProjectAreaG2Layer = require("../constructionG2/constructionProjectAreaG2Layer");
const DryWeatherOutfallGeoServerLayer = require("../illicitDischarge/dryWeatherOutfallGeoServerLayer");
const DryWeatherCatchBasinGeoServerLayer = require("../illicitDischarge/dryWeatherCatchBasinGeoServerLayer");
const FcsLayer = require("../bmpfcs/fcsLayer");
const FlowRoutingLayer = require("../outfalls/flowRoutingLayer");
const IddeCatchBasinLayer = require("../illicitDischarge/iddeCatchBasinLayer");
const IddeCatchBasinEsriDynamicMapLayer = require("../illicitDischarge/iddeCatchBasinEsriDynamicMapLayer");
const IddeManholeEsriDynamicMapLayer = require("../illicitDischarge/iddeManholeEsriDynamicMapLayer");
const IddeOutfallLayer = require("../illicitDischarge/iddeOutfallLayer");
const IncidentsLayer = require("../illicitDischarge/incidents/incidentLayer");
const IndcomFacilityGeoServerLayer = require("../indcom/indcomFacilityGeoServerLayer");
const LanduseEsriDynamicMapLayer = require("../general/landuseEsriDynamicMapLayer");
const LidProjectAreaLayer = require("../lid/lidProjectAreaLayer");
const LidProjectLayer = require("../lid/lidProjectLayer");
const MaintenanceZonesLegendLayer = require("../muni/maintenanceZonesLegendLayer");
const MuniManholeGeoServerLayer = require("../muni/muniManholeGeoServerLayer");
const MuniCleanoutGeoServerLayer = require("../muni/muniCleanoutGeoServerLayer");
const MuniLiftStationGeoServerLayer = require("../muni/muniLiftStationsGeoServerLayer");
const MuniPipesGeoServerLayer = require("../muni/muniPipesGeoServerLayer");
const MuniBmpGeoServerLayer = require("../muni/bmp/muniBmpGeoServerLayer");
const MuniCulvertGeoServerLayer = require("../muni/culvert/muniCulvertGeoServerLayer");
const MuniOpenChannelGeoServerLayer = require("../muni/openChannel/muniOpenChannelGeoServerLayer");
const MonitoringLocationLayer = require("../illicitDischarge/monitoringLocationLayer");
const MuniCatchBasinDrainageAreaLayer = require("../muni/muniCatchBasinDrainageAreaLayer");
const MuniSbmpDrainageAreaLayer = require("../muni/muniSbmpDrainageAreaLayer");
const MuniCatchBasinGeoServerLayer = require("../muni/muniCatchBasinGeoServerLayer");
const MuniOutfallsGeoServerLayer = require("../muni/outfalls/muniOutfallsGeoServerLayer");
const OutfallsLayer = require("../outfalls/outfallsLayer");
const OutfallsSimpleLayer = require("../outfalls/outfallsSimpleLayer");
const ProjectAssociatedBmpLayer = require("../construction/projectAssociatedBmpLayer");
const ScenariosLayer = require("../scenario/scenariosLayer");
const TelrResultLayer = require("../scenario/telrResultLayer");
const TelrResultBmpGeoServerLayer = require("../scenario/telrResultBmpGeoServerLayer");
const StreetSweepingLayer = require("../muni/streetSweepingLayer");
const StreamsEsriDynamicMapLayer = require("../general/streamsEsriDynamicMapLayer");
const StreamsEsriFeatureLayer = require("../general/streamsEsriFeatureLayer");
const RoadBulkOmEsriLayer = require("../muni/roadBulkOmEsriLayer");
const HighPriorityAreaLayer = require("../illicitDischarge/highPriorityAreaLayer");
const AssetsAssetsEsriDynamicMapLayer = require("../assetManagement/assetsassetsEsriDynamicMapLayers");
const AssetsManholeGeoServerDynamicMapLayer = require("../assetManagement/assetsManholesGeoServerDynamicMapLayer");
const AssetsCleanoutsGeoServerDynamicMapLayer = require("../assetManagement/assetsCleanoutsGeoServerDynamicMapLayer");
const AssetsPipeGeoServerDynamicMapLayer = require("../assetManagement/assetsPipeGeoServerDynamicMapLayer");
const AssetsBmpGeoServerDynamicMapLayer = require("../assetManagement/assetsBmpGeoServerDynamicMapLayer");
const AssetsOutfallGeoServerDynamicMapLayer = require("../assetManagement/assetsOutfallGeoServerDynamicMapLayer");
const SimpleOutfallGeoServerDynamicMapLayer = require("../outfalls/simpleOutfallGeoServerDynamicMapLayer");
const AssetsCatchBasinGeoServerDynamicMapLayer = require("../assetManagement/assetsCatchBasinGeoServerDynamicMapLayer");
const AssetsLiftStationGeoServerDynamicMapLayer = require("../assetManagement/assetsLiftStationGeoServerDynamicMapLayer");
const AssetsCulvertGeoServerDynamicMapLayer = require("../assetManagement/assetsCulvertGeoServerDynamicMapLayer");
const AssetsOpenChannelGeoServerDynamicMapLayer = require("../assetManagement/assetsOpenChannelGeoServerDynamicMapLayer");
const AssetsPofDynamicMapLayer = require("../assetManagement/assetsPofDynamicMapLayer");
const TrashConditionEsriDynamicMapLayer = require("../trash/trashConditionEsriDynamicMapLayer");
const ParcelsPluEsriDynamicMapLayer = require("../general/parcelsPluEsriDynamicMapLayer");
const projectDeliveryBmpsGeoServerLayer = require("../projectDelivery/projectDeliveryBmpsGeoServerLayer");
const StructuralBmpsGeoServerLayer = require("../bmpFcsG2/structuralBmpsGeoServerLayer");
const StormdrainGeoServerLayer = require("../general/stormdrainGeoServerLayer");
const ConstructionProjectGeoServerLayer = require("../constructionG2/constructionProjectGeoServerLayer");
const PostConstructionProjectGeoServerLayer = require("../constructionG2/postConstructionProjectGeoServerLayer");
const PostConstructionProjectAreaG2Layer = require("../constructionG2/postConstructionProjectAreaG2Layer");
const MuniCatchBasinReportGeoServerLayer = require("../report/muni/g2/muniCatchBasinReportGeoServerLayer");
const IddeManholeGeoServerLayer = require("../muni/iddeManholeGeoServerLayer");
const PlanPropertiesGeoServerLayer = require("../esg/geoServer/planPropertiesGeoServerLayer");
const AllPropertiesGeoServerLayer = require("../esg/geoServer/allPropertiesGeoServerLayer");
const PropertyBmpLayer = require("../esg/propertyBmpLayer");
const IncentiveProgramsGeoServerLayer = require("../esg/geoServer/incentiveProgramsGeoServerLayer");
const OtherPropertiesLayer = require("../esg/properties/otherPropertiesLayer");
const LidBmpGeoServerLayer = require("../lid/lidBmpGeoServerLayer");
const FeatureFlag = require("../settings/featureFlag");
