"use strict";

const DataRichness = function () {
  const getDropClasses = function (dataRichness) {
    const drops = ["", "", "", ""];
    const remainder = dataRichness % 1;
    const integer = Math.floor(dataRichness);
    for (let i = 0; i < integer; i++) {
      drops[i] = "full";
    }
    if (remainder > 0.24 && remainder < 0.74) {
      drops[integer] = "half";
    } else if (remainder >= 0.75) {
      drops[integer] = "full";
    }
    return drops;
  };
  return {
    getDropClasses,
  };
};

module.exports = DataRichness();
