"use strict";

var ReportMap = function () {
  var reportMap = null;
  var mapLayers = null;

  var loadReportMap = async function () {
    Tree.set("mapId", "report");
    const activeGroupId = Tree.get("activeGroup", "groupId");
    const lastGroupId = Tree.get("lastGroupId", "report");
    var firstLoad = false;
    initLayers();

    if (!reportMap) {
      firstLoad = true;
      TreeUpdates.turnOnUpdates(mapLayers, getActiveReportMap, getCurrentReportBasemap, "report");
      reportMap = initReportMap();
      DisplayOptions.startLegendListeners("report", mapLayers);
      loadLayerListenersForReportMap();
      initSimMap();
    }
    const groupChanged = activeGroupId !== lastGroupId;
    if (firstLoad || groupChanged) {
      if (Progeny.activeGroupHasGrandprogeny() && !Progeny.rootGroupIsRegulator()) {
        loadRegionalPointMap();
      } else {
        loadReportMS4Map();
      }
    }

    reloadListeners();
    CommonMapFunctions.afterMapLoad();
  };

  var initLayers = function () {
    if (!mapLayers) {
      mapLayers = LayerFunctions.getAllLayersAsMapLayers();
    }
  };

  var loadLayerListenersForReportMap = function () {
    // @TODO: Set up config to load layer listneres dynamically
    const SbmpProgressLayer = require("../bmpfcs/sbmpProgressLayer");
    SbmpProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const SbmpProgressDrainageLayer = require("../bmpfcs/sbmpProgressDrainageLayer");
    SbmpProgressDrainageLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const FcsProgressLayer = require("../bmpfcs/fcsProgressLayer");
    FcsProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const FcsProgressDrainageLayer = require("../bmpfcs/fcsProgressDrainageLayer");
    FcsProgressDrainageLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const GroundwaterBasinLayer = require("../watershed/groundwaterBasinLayer");
    GroundwaterBasinLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const ConstructionProjectProgressLayer = require("../construction/constructionProjectProgressLayer");
    ConstructionProjectProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const ConstructionProjectAreaProgressLayer = require("../construction/constructionProjectAreaProgressLayer");
    ConstructionProjectAreaProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const IncidentProgressLayer = require("../idde/incidentProgressLayer");
    IncidentProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const OutfallProgressLayer = require("../outfalls/outfallProgressLayer");
    OutfallProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const HighPriorityAreaLayer = require("../idde/highPriorityAreaLayer");
    HighPriorityAreaLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const LidProjectProgressLayer = require("../lid/lidProjectProgressLayer");
    LidProjectProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const LidProjectAreaProgressLayer = require("../lid/lidProjectAreaProgressLayer");
    LidProjectAreaProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");

    if (FeatureFlag.enabled("annual-report-muni-catch-basin-tko")) {
      const MuniCatchBasinReportGeoServerLayer = require("../muni/g2/muniCatchBasinReportGeoServerLayer");
      MuniCatchBasinReportGeoServerLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    } else {
      const MuniCatchBasinProgressLayer = require("../muni/muniCatchBasinProgressLayer");
      MuniCatchBasinProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    }

    const MuniCatchBasinProgressDrainageLayer = require("../muni/muniCatchBasinProgressDrainageLayer");
    MuniCatchBasinProgressDrainageLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const MaintenanceZonesLayer = require("../muni/maintenanceZonesLayer");
    MaintenanceZonesLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const FlowRoutingLayer = require("../outfalls/flowRoutingLayer");
    FlowRoutingLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const OutfallsSimpleLayer = require("../outfalls/outfallsSimpleLayer");
    OutfallsSimpleLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const RunoffProgressLayer = require("../telr/runoffProgressLayer");
    RunoffProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const ParticulatesProgressLayer = require("../telr/particulatesProgressLayer");
    ParticulatesProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const RunoffRatioProgressLayer = require("../telr/runoffRatioProgressLayer");
    RunoffRatioProgressLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    RegionPointLayer.loadListeners(reportMap, mapLayers, "report");
    RegionAreaLayer.loadListeners(reportMap, mapLayers, "report");
    const LanduseEsriDynamicMapLayer = require("../general/landuseEsriDynamicMapLayer");
    LanduseEsriDynamicMapLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const StreamsEsriDynamicMapLayer = require("../general/streamsEsriDynamicMapLayer");
    StreamsEsriDynamicMapLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
    const TrashProgressEsriDynamicMapLayer = require("../trash/trashProgressEsriDynamicMapLayer");
    TrashProgressEsriDynamicMapLayer.loadLayerListenersForMap(reportMap, mapLayers, "report");
  };

  var annualReportingCallback = function () {
    initializeReportLayers();
    // KPI.init(); // @Keep this to investigate duplicate calls issue later
  };

  var loadReportMS4Map = async function () {
    if (Tree.get("annualReportingLoaded")) {
      annualReportingCallback();
    } else {
      await Actions.loadAnnualReporting(annualReportingCallback);
    }

    CommonMapFunctions.afterMS4MapLoad(reportMap);
  };

  var loadRegionalPointMap = function () {
    RegionalView.setRegionalViewDom();
    Ms4Table.loadListeners();
    DataViewFunctions.setDataView("regionPointView");
  };

  var loadRegionalAreaMap = function () {
    RegionalView.setRegionalViewDom();
    Ms4Table.loadListeners();
    DataViewFunctions.setDataView("regionAreaView");
  };

  var reloadListeners = function () {
    Table.loadListeners("report");
    DisplayOptions.startLegendListeners("report", mapLayers);
    CatchmentView.startListeners();

    if (Progeny.activeHasProgeny()) {
      Ms4Table.loadListeners();
    }
  };

  var initializeReportLayers = function () {
    var autoLoadLayers = ["catchments", "ms4Boundary"];
    if (Session.isCdot()) {
      autoLoadLayers.push("highways");
    }
    autoLoadLayers.forEach((layerName) => {
      loadLayer(layerName);
    });
  };

  var loadLayer = function (layerName) {
    switch (layerName) {
      case "catchments":
        Tree.set(["layers", "report", "catchments", "isEnabled"], true);
        break;
      case "ms4Boundary":
        Tree.set(["layers", "report", "ms4Boundary", "isEnabled"], true);
        break;
      case "highways":
        Tree.set(["layers", "report", "highways", "isEnabled"], true);
        break;
    }
  };

  var initSimMap = function () {
    if (!Session.isSalinas()) return;
    SimLayer.main();
  };

  var initReportMap = function () {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const maxZoom = rasterBasemap ? 19 : 21;
    reportMap = L.map("reportMap", {
      zoomControl: false,
      tileLayer: {
        maxNativeZoom: maxZoom,
      },
      minZoom: 3,
      maxZoom: maxZoom,
    });
    CommonMapFunctions.setMapHeight("reportMap", "report");

    var defaultBasemap = DataViewFunctions.getCurrentDataViewProperty("defaultBasemap")["report"];
    mapLayers.basemap = MapFunctions.addBasemap(reportMap, mapLayers.basemap, defaultBasemap);

    L.control
      .scale({
        position: "bottomright",
        metric: false,
      })
      .addTo(reportMap);
    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(reportMap);
    reportMap.setView(Tree.get("activeGroup", "latlng"), 14);
    startMapListener(reportMap);
    MapPaneFunctions.createCustomMapPanes(reportMap);

    return reportMap;
  };

  var getActiveReportMap = function () {
    return reportMap;
  };

  var getActiveLayers = function () {
    initLayers();
    return mapLayers;
  };

  var getCurrentReportBasemap = function () {
    return mapLayers.basemap;
  };

  // @TODO: remove startMapListener, disableNoZoomLayers, end nableNoZoomLayers when switching to vector basemaps
  // note: these functions are duplicated in 3 other places
  var startMapListener = function (reportMap) {
    // GeoServerLayerFunctions.addMapClickListener(reportMap);

    reportMap.on("zoomend", function () {
      const rasterBasemap = FeatureFlag.enabled("raster-basemap");
      if (rasterBasemap) {
        const zoom = reportMap.getZoom();
        const currentBasemap = Tree.get("basemap", "report");

        if (zoom > 16) {
          disableNoZoomLayers();
          if (
            currentBasemap !== "DarkGray" &&
            currentBasemap !== "Gray" &&
            currentBasemap !== "NationalGeographic"
          )
            return;
          Tree.set(["basemapBeforeZoomMap", "report"], currentBasemap);
          BasemapFunctions.setBasemap("Imagery", "report");
        } else {
          enableNoZoomLayers();
          const basemapBeforeZoom = Tree.get("basemapBeforeZoomMap", "report");
          if (basemapBeforeZoom) {
            BasemapFunctions.setBasemap(basemapBeforeZoom, "report");
          }
          Tree.set(["basemapBeforeZoomMap", "report"], "");
        }
        resizeIconsByLayer();
      }
    });
  };

  var resizeIconsByLayer = function () {
    let activeLayer;
    let createIcon;
    const currentDataView = Tree.get("dataView");
    if (currentDataView === "outfallView") {
      activeLayer = getActiveLayers().outfallProgressLayer;
      createIcon = OutfallsIcon.getOutfallsMarkerIcon;
    } else if (currentDataView === "iddeView") {
      createIcon = IncidentsIcon.getIncidentsMarkerIcon;
      activeLayer = getActiveLayers().incidentProgressLayer;
    } else if (currentDataView === "sbmpView") {
      activeLayer = getActiveLayers().sbmpProgressLayer;
      createIcon = SbmpProgressLayer.updateBmpIcon;
    } else if (currentDataView === "fcsView") {
      activeLayer = getActiveLayers().fcsProgressLayer;
      createIcon = FcsProgressLayer.updateFcsIcon;
    } else if (currentDataView === "constructionView") {
      activeLayer = getActiveLayers().constructionProjectProgressLayer;
      createIcon = ConstructionIcon.getConstructionProjectIconMarker;
    } else if (currentDataView === "lidView") {
      activeLayer = getActiveLayers().lidProjectProgressLayer;
      createIcon = LidIcon.getLidProjectMapIcon;
    }
    if (activeLayer && createIcon) {
      RamMapFunctions.resizeLayerMarkers(activeLayer, createIcon, reportMap.getZoom());
    }
  };

  var disableNoZoomLayers = function () {
    $("#report").find("[data-style='Gray']").addClass("disabled");
    $("#report").find("[data-style='DarkGray']").addClass("disabled");
    $("#report").find("[data-style='NationalGeographic']").addClass("disabled");
  };

  var enableNoZoomLayers = function () {
    $("#report").find("[data-style='Gray']").removeClass("disabled");
    $("#report").find("[data-style='DarkGray']").removeClass("disabled");
    $("#report").find("[data-style='NationalGeographic']").removeClass("disabled");
  };

  return {
    loadReportMap,
    loadReportMS4Map,
    loadRegionalAreaMap,
    getActiveLayers,
    getActiveReportMap,
  };
};

module.exports = ReportMap();

const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
const Tree = require("../../tree");
const Progeny = require("../../login/progeny");
const TreeUpdates = require("../mapFunctions/treeUpdates");
const Table = require("../mapFunctions/table");
const Actions = require("../actions");
const DisplayOptions = require("../mapFunctions/displayOptions");
const CatchmentView = require("../mapFunctions/catchmentView");
const BasemapFunctions = require("../mapFunctions/basemapFunctions");
const DataViewFunctions = require("../dataViewFunctions");
const Ms4Table = require("../ms4Table");
const RegionPointLayer = require("../general/regionPointLayer");
const RegionAreaLayer = require("../general/regionAreaLayer");
const RegionalView = require("../regionalView");
const LayerFunctions = require("../mapFunctions/layerFunctions");
const MapPaneFunctions = require("../mapFunctions/mapPaneFunctions");
const SimLayer = require("../watershed/simLayer");
const Session = require("../../login/session");
const MapFunctions = require("../mapFunctions/mapFunctions");
const FeatureFlag = require("../../settings/featureFlag");
const RamMapFunctions = require("../../mapFunctions/mapFunctions");
const SbmpProgressLayer = require("../bmpfcs/sbmpProgressLayer");
const FcsProgressLayer = require("../bmpfcs/fcsProgressLayer");
const ConstructionIcon = require("../construction/constructionIcon");
const LidIcon = require("../lid/lidIcon");
const IncidentsIcon = require("../idde/incidentsIcon");
const OutfallsIcon = require("../outfalls/outfallsIcon");
