"use strict";

const GeoServerFilterFunctions = function () {
  var getFilterQuery = function (layerName) {
    const queryStrings = [];

    if (!window.twoNformReport) {
      const FilterModule = GeoServerConfig.get()[layerName]?.filterModule;

      if (shouldFilterByToDos(layerName)) {
        addToDoQuery(queryStrings, FilterModule?.addToDoGeoServerFilterQuery, layerName);
      } else {
        if (["projectDeliveryBmps", "telrResultBmp"].includes(layerName)) {
          addSpatialFilterQueryWithCachedCatchment(queryStrings);
        } else {
          addSpatialFilterQuery(queryStrings, layerName);
        }

        if (FilterModule?.addGeoServerFilterQuery) {
          const filters = Tree.get("filters");
          FilterModule.addGeoServerFilterQuery(queryStrings, filters);
        }
      }
    } else {
      addSpatialFilterQuery(queryStrings);
    }

    addBaseQuery(queryStrings, layerName);

    return queryStrings.join(" AND ");
  };

  var shouldFilterByToDos = function (layerName) {
    const activeTab = Tree.get("activeTab");
    const isGisDataViewPrimaryLayer =
      LayerFunctions.getLayerConfig(layerName)["isGisDataViewPrimaryLayer"];

    return activeTab === "todo" && isGisDataViewPrimaryLayer;
  };

  var addSpatialFilterQueryWithCachedCatchment = function (queryStrings) {
    const receivingWaters = Tree.get("filters", "receivingWaters");
    if (receivingWaters?.length > 0) {
      const receivingWaterQuery = getReceivingWaterQueryWithCachedCatchment(receivingWaters);
      if (receivingWaterQuery) queryStrings.push(receivingWaterQuery);
    }

    const catchments = Tree.get("filters", "catchments");
    if (catchments?.length > 0) {
      const catchmentQuery = getCatchmentQueryWithCachedCatchment(catchments);
      if (catchmentQuery) queryStrings.push(catchmentQuery);
    }

    const maintenanceZones = Tree.get("filters", "maintenanceZones");
    if (maintenanceZones?.length > 0) {
      const maintenanceZoneQuery = getMaintenanceZoneQuery(maintenanceZones);
      if (maintenanceZoneQuery) queryStrings.push(maintenanceZoneQuery);
    }
  };

  var getReceivingWaterQueryWithCachedCatchment = function (receivingWaters) {
    const dataSort = Tree.get("filters", "dataSort");
    const activeTab = Tree.get("activeTab");

    if (dataSort === "ud" && activeTab !== "todo") {
      return getInArrayQuery("drains_to_c", receivingWaters);
    } else {
      return getInArrayQuery("drains_to_rw", receivingWaters);
    }
  };

  var getCatchmentQueryWithCachedCatchment = function (catchments) {
    return getInArrayQuery("catchment_name", catchments);
  };

  var addSpatialFilterQuery = function (queryStrings, layerName) {
    const receivingWaters = Tree.get("filters", "receivingWaters");
    if (receivingWaters?.length > 0) {
      const receivingWaterQuery = getReceivingWaterQuery(receivingWaters, layerName);
      if (receivingWaterQuery) queryStrings.push(receivingWaterQuery);
    }

    const spatialGroupingId = Tree.get("filters", "spatialGroupingId");
    if (spatialGroupingId) {
      const spatialGroupingQuery = `spatial_grouping_ids LIKE ''%(${spatialGroupingId})%''`;
      const baseQuery = getBaseQuery();
      const query = `(INTERSECTS(geom, collectGeometries(queryCollection('catchments', 'geom', '${baseQuery} AND ${spatialGroupingQuery}'))))`;
      queryStrings.push(query);
    } else {
      const catchments = Tree.get("filters", "catchments");
      if (catchments?.length > 0) {
        const catchmentQuery = getCatchmentQuery(catchments, layerName);
        if (catchmentQuery) queryStrings.push(catchmentQuery);
      }
    }

    const maintenanceZones = Tree.get("filters", "maintenanceZones");
    if (maintenanceZones?.length > 0) {
      const maintenanceZoneQuery = getMaintenanceZoneQuery(maintenanceZones, layerName);
      if (maintenanceZoneQuery) queryStrings.push(maintenanceZoneQuery);
    }

    const highways = Tree.get("filters", "highways");
    if (highways?.length > 0) {
      const highwaysQuery = getSpatialQuery(
        highways,
        "highway_name",
        "highway_name",
        "outside boundary",
        layerName,
      );
      if (highwaysQuery) queryStrings.push(highwaysQuery);
    }
  };

  var getReceivingWaterQuery = function (receivingWaters, layerName) {
    const dataSort = Tree.get("filters", "dataSort");
    const activeTab = Tree.get("activeTab");

    if (dataSort === "ud" && activeTab !== "todo") {
      return getSpatialQuery(
        receivingWaters,
        "catchments",
        "drains_to_c",
        "outside boundary",
        layerName,
      );
    } else {
      return getSpatialQuery(
        receivingWaters,
        "catchments",
        "drains_to_rw",
        "outside boundary",
        layerName,
      );
    }
  };

  var getCatchmentQuery = function (catchments, layerName) {
    return getSpatialQuery(catchments, "catchments", "catchid", "outside boundary", layerName);
  };

  var getMaintenanceZoneQuery = function (maintenanceZones, layerName) {
    return getSpatialQuery(
      maintenanceZones,
      "maintenance_zones",
      "zone_name",
      "Outside Maintenance Zones",
      layerName,
    );
  };

  var getSpatialQuery = function (spatialAreas, tableName, tableField, outlierName, layerName) {
    const dataSort = Tree.get("table", "dataSort");

    if (!spatialAreas?.length) {
      return;
    }

    if (layerName) {
      const isLinearAsset = LayerFunctions.getLayerConfig(layerName)["isLinearAsset"];
      if (isLinearAsset) {
        return;
      }
    }

    const baseQuery = getBaseQuery();
    const spatialStrings = getCommaSeparatedStringsCQL(spatialAreas);
    const includesOutlier = spatialAreas.includes(outlierName);
    const queryOrs = [];

    if (spatialAreas.length !== 1 || !includesOutlier) {
      if (dataSort !== "monitoringDrainages") {
        queryOrs.push(constructSpatialCql(tableField, spatialAreas));
      } else {
        queryOrs.push(
          `INTERSECTS(geom, collectGeometries(queryCollection('${tableName}', 'geom', '${baseQuery} AND ${tableField} IN (${spatialStrings})')))`,
        );
      }
    }

    if (includesOutlier) {
      if (dataSort !== "monitoringDrainages") {
        queryOrs.push(constructSpatialCql(tableField, spatialAreas));
      } else {
        queryOrs.push(
          `DISJOINT(geom, collectGeometries(queryCollection('${tableName}', 'geom', '${baseQuery}')))`,
        );
      }
    }

    return `(${queryOrs.join(" OR ")})`;
  };

  var getAnnualReportCqlFilters = function () {
    let cqlFilter;
    const { dataRangeLast, reportingYear, snapshotYear } = GeoServerLayerFunctions.getReportData();
    if (snapshotYear) {
      cqlFilter = `bmp_creation <= '${dataRangeLast}' AND snapshot_year = ${reportingYear}`;
    } else {
      cqlFilter = `bmp_creation <= '${dataRangeLast}'`;
    }

    const query = getFilterQuery();
    if (query) {
      return cqlFilter + " AND " + query;
    } else {
      return cqlFilter;
    }
  };

  var addBaseQuery = function (queryStrings, layerName) {
    const groupId = Tree.get("activeGroup", "groupId");

    if (groupId) {
      queryStrings.push(getBaseQuery(layerName));
    }
  };

  var getBaseQuery = function (layerName = null) {
    const query = getGroupIdQuery();
    if (layerName) {
      const layerModule = LayerFunctions.getLayerConfig(layerName)["module"];
      if (layerModule?.getBaseQuery) {
        return query + " AND " + layerModule.getBaseQuery();
      }
    }
    return query;
  };

  var getGroupIdQuery = function () {
    const groupId = Tree.get("activeGroup", "groupId");
    const progeny = Progeny.getActiveGroupProgeny() || [];
    const progenyGroupIds = progeny?.map((group) => group.groupId);
    if (progenyGroupIds?.length) {
      const subGroupIds = Tree.get("filters", "subGroups");
      if (subGroupIds?.length) {
        return `group_id IN (${subGroupIds.join(", ")})`;
      }
      return `group_id IN (${progenyGroupIds.join(", ")})`;
    } else {
      return `group_id = ${groupId}`;
    }
  };

  var getBaseSpatialFilterQuery = function () {
    const queryStrings = [];
    addSpatialFilterQuery(queryStrings);
    addBaseQuery(queryStrings);

    return queryStrings.join(" AND ");
  };

  var addToDoQuery = function (queryStrings, addToDoGeoServerFilterQuery, layerName) {
    addToDoSubjectQuery(queryStrings);
    addToDoFilterQuery(queryStrings, addToDoGeoServerFilterQuery, layerName);
  };

  var addToDoSubjectQuery = function (queryStrings) {
    const toDoSubjectQuery = getToDoSubjectQuery();

    if (toDoSubjectQuery) {
      queryStrings.push(toDoSubjectQuery);
    }
  };

  var getToDoSubjectQuery = function () {
    const dataView = Tree.get("dataView");
    const selectedSubject = Tree.get(["todos", dataView, "selectedSubject"]);

    if (selectedSubject) {
      return getSingleToDoSubjectQuery(selectedSubject);
    } else {
      const allToDoSubjects = DataViewFunctions.getCurrentDataViewProperty(
        "toDoSubjects",
        dataView,
      );

      if (allToDoSubjects?.length) {
        const mapSubjectQueryStrings = allToDoSubjects.map((subject) =>
          getSingleToDoSubjectQuery(subject),
        );

        return `(${mapSubjectQueryStrings.join(" OR ")})`;
      }
    }
  };

  var getSingleToDoSubjectQuery = function (subject) {
    const databaseName = ToDoConfig.getToDoSubjectConfig([subject, "databaseName"]);

    return `todo_is_${databaseName} = true`;
  };

  var addToDoFilterQuery = function (queryStrings, addToDoGeoServerFilterQuery, layerName) {
    const toDoFilterQuery = getToDoFilterQuery(addToDoGeoServerFilterQuery, layerName);

    if (toDoFilterQuery) {
      queryStrings.push(toDoFilterQuery);
    }
  };

  var getToDoFilterQuery = function (addToDoGeoServerFilterQuery, layerName) {
    const dataView = Tree.get("dataView");
    const toDoFilters = Tree.get("toDoFilters")?.[dataView];

    const subjectStrings = [];
    for (const subject in toDoFilters) {
      const filters = toDoFilters[subject];

      if (!filters.allSpatial && filters.receivingWater?.length) {
        const receivingWaterQuery = getReceivingWaterQuery(filters.receivingWater, layerName);
        if (receivingWaterQuery) subjectStrings.push(receivingWaterQuery);
      }
      if (!filters.allSpatial && filters.catchment?.length) {
        const catchmentQuery = getCatchmentQuery(filters.catchment, layerName);
        if (catchmentQuery) subjectStrings.push(catchmentQuery);
      }
      if (!filters.allSpatial && filters.maintenanceZone?.length) {
        const maintenanceZoneQuery = getMaintenanceZoneQuery(filters.maintenanceZone, layerName);
        if (maintenanceZoneQuery) subjectStrings.push(maintenanceZoneQuery);
      }

      if (addToDoGeoServerFilterQuery) {
        addToDoGeoServerFilterQuery(subjectStrings, filters);
      }

      addToDoDueDateQuery(subjectStrings, filters, subject);
    }

    if (subjectStrings?.length) {
      return `(${subjectStrings.join(" AND ")})`;
    }
  };

  var addToDoDueDateQuery = function (subjectStrings, filters, subject) {
    const databaseName = ToDoConfig.getToDoSubjectConfig([subject, "databaseName"]);

    addDateQuery(
      subjectStrings,
      `todo_due_date_${databaseName}`,
      filters.dueDateFrom,
      filters.dueDateTo,
    );
  };

  var getYesNoQuery = function (field, filterArray) {
    if (filterArray.length === 0) {
      return "0 = 1";
    }

    var sqlArray = [];
    if (filterArray.includes("yes")) {
      sqlArray.push(`${field} = true`);
    }
    if (filterArray.includes("no")) {
      sqlArray.push(`${field} = false`);
    }
    const sql = sqlArray.join(" OR ");

    if (sql) {
      return `(${sql})`;
    }
  };

  var getInArrayQuery = function (
    field,
    filterArray,
    knownOptions = null,
    separateUnknownAndNull = false,
  ) {
    let nullIncluded;

    if (filterArray.length === 0) return "0 = 1";

    if (separateUnknownAndNull) [nullIncluded, filterArray] = _handleNulls(filterArray);
    const inClause = prepareArrayToIn(filterArray);
    const sqlIn = `${field} IN (${inClause})`;

    if (separateUnknownAndNull ? nullIncluded : filterArray.includes("unknown")) {
      if (knownOptions) {
        knownOptions = prepareArrayToIn(knownOptions);
        return `(${sqlIn} OR ${field} IS NULL OR (${field} IS NOT NULL AND ${field} NOT IN (${knownOptions})))`;
      }
      return filterArray.length ? `(${sqlIn} OR ${field} IS NULL)` : `${field} IS NULL`;
    }
    return sqlIn;
  };

  var _handleNulls = function (filterArray) {
    filterArray = filterArray.map((item) => item || "null");
    const nullIncluded = filterArray.includes("null");
    filterArray = filterArray.filter((item) => item !== "null");
    return [nullIncluded, filterArray];
  };

  var getInArrayOrNotQuery = function (field, filterArray, knownOptions = []) {
    const separateUnknownAndNull = _handleNulls(filterArray)[0];
    if (filterArray.length < knownOptions.length / 2) {
      return getInArrayQuery(field, filterArray, null, separateUnknownAndNull);
    } else {
      const notInArray = knownOptions.filter((option) => !filterArray.includes(option));
      return getWhereNotInArrayQuery(field, notInArray, separateUnknownAndNull);
    }
  };

  var getWhereNotInArrayQuery = function (field, notInValuesArray, separateUnknownAndNull) {
    let nullIncluded;
    [nullIncluded, notInValuesArray] = _handleNulls(notInValuesArray);
    notInValuesArray = prepareArrayToIn(notInValuesArray);

    let condition = notInValuesArray.length ? `"${field}" NOT IN (${notInValuesArray})` : "";

    if (nullIncluded || (!separateUnknownAndNull && notInValuesArray.length)) {
      if (condition) condition += " AND ";
      condition += `"${field}" IS NOT NULL`;
    }

    return condition;
  };

  var prepareArrayToIn = function (array) {
    return array
      .filter((filter) => filter !== "")
      .map((filter) => {
        if (Number.isInteger(filter) || filter === null) {
          return `${filter}`;
        } else {
          return `'${filter}'`;
        }
      });
  };

  var getInBooleanArrayQuery = function (field, filterArray) {
    if (filterArray.length === 0) {
      return "0 = 1";
    }

    let includeNull = false;
    const inValues = [];
    const sqlStrings = [];

    filterArray
      .filter((filter) => filter !== "")
      .forEach((filter) => {
        if (filter === "unknown" || filter === null) {
          includeNull = true;
        } else {
          inValues.push(filter.toString());
        }
      });

    if (inValues.length) {
      sqlStrings.push(`${field} IN (${inValues.join(", ")})`);
    }

    if (includeNull) {
      sqlStrings.push(`${field} IS NULL`);
    }

    return `(${sqlStrings.join(" OR ")})`;
  };

  // Same as getInArrayQuery()
  var getInArrayJsonParamValue = function (filterObjectArray) {
    const topLevelQueries = [];

    for (const section in filterObjectArray) {
      const info = filterObjectArray[section];
      topLevelQueries.push(getInArrayJsonPaths(info.values, info.key, info.whiteList));
    }

    // https://www.postgresql.org/docs/13/functions-json.html#FUNCTIONS-SQLJSON-PATH
    return `$ ? (${topLevelQueries.join(" && ")})`;
  };

  var getInArrayJsonPaths = function (values, key = null, whiteList = null) {
    const jsonPaths = values.map((value) => getJsonParamPathQuery(value, key, whiteList));
    return `(${jsonPaths.join(" || ")})`;
  };

  var getJsonParamPathQuery = function (value, key, whiteList) {
    if (value === "unknown") {
      if (whiteList) {
        return getJsonParamWhereNotIn(key, whiteList);
      } else {
        value = null;
      }
    }

    return whereJsonParamPath(key, "==", value);
  };

  var getJsonParamWhereNotIn = function (key, whiteList) {
    const jsonPaths = [];

    for (const value of whiteList) {
      jsonPaths.push(whereJsonParamPath(key, "!=", value));
    }

    return `(${jsonPaths.join(" && ")})`;
  };

  var whereJsonParamPath = function (key = null, compare, value) {
    const base = key ? `@.${key}` : `@`;

    if (typeof value === "string") {
      value = `"${value}"`;
    }

    return `${base} ${compare} ${value}`;
  };

  var getSearchQuery = function (fields, searchString) {
    searchString = searchString.toLowerCase().replace("'", "%''%");
    const searchQueries = fields.map(
      (fieldName) => `strToLowerCase(${fieldName}) LIKE '%${searchString}%'`,
    );

    return `(${searchQueries.join(" OR ")})`;
  };

  var addMinMaxQuery = function (queryStrings, field, minMax = { min: null, max: null }) {
    const { min, max } = minMax;
    if (Misc.onlyContainsNumbers(min)) queryStrings.push(`${field} >= ${min}`);
    if (Misc.onlyContainsNumbers(max)) queryStrings.push(`${field} <= ${max}`);
  };

  var addFeetAndInchesMinMaxMeasurmentQuery = function (
    field,
    minMax = { min: null, max: null },
    unitColName,
    measurementQueries,
  ) {
    if (!field || !unitColName || (!minMax.max && !minMax.min)) return;
    const { min, max } = minMax;
    let query = [];
    let subQueryInches = [];
    let subQueryFeet = [];
    const minInches = min ? min * 12 : null;
    const maxInches = max ? max * 12 : null;

    addMinMaxQuery(subQueryInches, field, { max: maxInches, min: minInches });
    addMinMaxQuery(subQueryFeet, field, minMax);

    subQueryFeet = "(" + subQueryFeet.join(" AND ") + ")";
    subQueryInches = "(" + subQueryInches.join(" AND ") + ")";
    const nullSubQuery = Tree.get("dataView") === "muni-culvert" ? subQueryFeet : subQueryInches;
    query = `((${unitColName}='${"inches"}' AND ${subQueryInches}) OR (${unitColName}='${"feet"}' AND ${subQueryFeet}) OR (${unitColName} IS NULL AND ${nullSubQuery}))`;

    measurementQueries.push(query);
  };

  var createMinMaxMeasurmentCombinedQuery = function (
    queryStrings,
    lengthWidthRangeQueries,
    diameterRangeQuery,
    indexOfShapeQuery,
    shapeDbColumnName,
  ) {
    if (lengthWidthRangeQueries.length === 0 && diameterRangeQuery.length === 0) return;

    const query = [];
    const shouldSeparateUnknownAndNull = true;
    const diameterRangeIsSet = MeasurementMinMax._measurementRangeIsSet("diameterRange");
    const lengthRangeIsSet = MeasurementMinMax._measurementRangeIsSet("lengthRange");
    const widthRangeIsSet = MeasurementMinMax._measurementRangeIsSet("widthRange");
    const diameterOnlyShapesFromSelectedShapeFilter =
      MeasurementMinMax._getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected(true);
    const hasDiameterOnlyShapes = diameterOnlyShapesFromSelectedShapeFilter?.length > 0;
    const diameterOnlyShapesQuery = getInArrayQuery(
      shapeDbColumnName,
      diameterOnlyShapesFromSelectedShapeFilter,
      null,
      shouldSeparateUnknownAndNull,
    );

    const lengthWidthOnlyShapeArray =
      MeasurementMinMax._getOrExcludeDiameterOnlyShapesFromCurrentShapesSelected(false);
    const lengthWidthShapesQuery = getInArrayQuery(
      shapeDbColumnName,
      lengthWidthOnlyShapeArray,
      null,
      shouldSeparateUnknownAndNull,
    );

    if (!diameterRangeIsSet && hasDiameterOnlyShapes) {
      query.push(`(${diameterOnlyShapesQuery})`);
    }
    if (diameterRangeIsSet && hasDiameterOnlyShapes) {
      query.push(`(${diameterOnlyShapesQuery} AND (${diameterRangeQuery.join(" OR ")}))`);
    }
    if (lengthRangeIsSet || widthRangeIsSet) {
      query.push(`(${lengthWidthShapesQuery} AND (${lengthWidthRangeQueries.join(" OR ")}))`);
    }
    if (lengthWidthOnlyShapeArray?.length > 0 && !lengthRangeIsSet && !widthRangeIsSet) {
      query.push(`(${lengthWidthShapesQuery})`);
    }

    const shapeAndMeasurementCombinedQuery = query?.length > 1 ? query.join(" OR ") : query;
    queryStrings[indexOfShapeQuery] = `(${shapeAndMeasurementCombinedQuery})`;
  };

  var addDateQuery = function (queryStrings, column, dateFrom, dateTo, noDate = false) {
    if (noDate) {
      queryStrings.push(`${column} IS NULL`);
      return;
    } else if (!dateFrom && !dateTo) {
      return;
    }

    const queries = [];

    if (dateFrom) {
      queries.push(`${column} >= ${formatSqlDateFrom(dateFrom)}`);
    }
    if (dateTo) {
      queries.push(`${column} < ${formatSqlDateTo(dateTo)}`);
    }

    queryStrings.push(`(${queries.join(" AND ")})`);
  };

  var formatSqlDateFrom = function (dateFrom) {
    return new Date(dateFrom).toISOString();
  };

  var formatSqlDateTo = function (dateTo) {
    const nextDay = new Date(dateTo);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString();
  };

  var yesNoNullGeoServerFilter = function (
    filters,
    filterKey,
    queryStrings,
    columnName = null,
    injectSingleQuote = false,
  ) {
    if (Array.isArray(filters[filterKey])) {
      const filterQueries = [];
      filters[filterKey].forEach((value) => {
        if (columnName) {
          if (value === "null") {
            filterQueries.push(`${columnName} IS NULL`);
          } else if (injectSingleQuote) {
            filterQueries.push(`${columnName}='${value}'`);
          } else {
            filterQueries.push(`${columnName}=${value}`);
          }
        } else {
          if (value === "null") {
            filterQueries.push(`${filterKey} IS NULL`);
          } else if (injectSingleQuote) {
            filterQueries.push(`${filterKey}='${value}'`);
          } else {
            filterQueries.push(`${filterKey}=${value}`);
          }
        }
      });
      queryStrings.push(`(${filterQueries.join(" OR ")})`);
    }
    return queryStrings;
  };

  var yesNoExistsGeoServerFilter = function (
    filters,
    filterKey,
    queryStrings,
    { columnName = null } = {},
  ) {
    const filterArray = filters[filterKey];

    if (!Array.isArray(filterArray)) {
      return queryStrings;
    }

    if (!columnName) {
      columnName = filterKey;
    }

    const hasYes = filterArray.includes("true");
    const hasNo = filterArray.includes("false");

    if (hasYes !== hasNo) {
      if (hasYes) {
        queryStrings.push(`${columnName} is not null`);
      } else if (hasNo) {
        queryStrings.push(`${columnName} is null`);
      }
    }

    return queryStrings;
  };

  var addTrueFalseNullAsIntFilter = function (queryStrings, filter, columnName) {
    const queryParts = [];

    if (!filter?.length || filter.length === 3) {
      return;
    }

    if (filter.includes("true")) {
      queryParts.push(`${columnName} = 1`);
    }

    if (filter.includes("false")) {
      queryParts.push(`${columnName} = 0`);
    }

    if (filter.includes("null")) {
      queryParts.push(`${columnName} IS null`);
    }

    queryStrings.push(`(${queryParts.join(" OR ")})`);
  };

  var getCommaSeparatedStringsCQL = function (arr) {
    const mappedArray = arr.map((item) => `''${item}''`);
    return mappedArray.join(", ");
  };

  var getFilterViewParams = function (layerName, { treePath = "filters" } = {}) {
    const activeTab = Tree.get("activeTab");
    const params = {};

    if (activeTab === "todo") {
      const toDoFilters = ToDoFunctions.getToDoFilters();

      for (const subject in toDoFilters) {
        const filters = toDoFilters[subject];
        addAdditionalInfoParams(filters, params, subject);
      }
    } else {
      const filters = Tree.get(treePath);
      addDateParams(filters, params);
      if (layerName) {
        const FilterModule = GeoServerConfig.get()[layerName]?.filterModule;
        const isLinearAsset = LayerFunctions.getLayerConfig(layerName)["isLinearAsset"];

        if (FilterModule?.addGeoServerViewParams) {
          FilterModule.addGeoServerViewParams(params, filters);
        } else if (isLinearAsset) {
          addSpatialFilterViewParams(params, filters);
        }
      }
    }

    return Object.entries(params).reduce((string, [key, value]) => `${string}${key}:${value};`, "");
  };

  var isOnlineDateFilterSet = function (filters) {
    const dataView = Tree.get("dataView");
    // @TODO: Set this in layerConfig or geoServerConfig
    if (dataView === "muni-catch-basin") {
      return MuniCatchBasinFilters.isOnlineDateFilterSet(filters);
    } else if (dataView === "indcom-facilities") {
      return IndcomFacilityFilters.isOnlineDateFilterSet(filters);
    }
  };

  var addDateParams = function (filters, params) {
    if (!isOnlineDateFilterSet(filters)) {
      return;
    }

    params.dateType = filters.dateType;

    if (filters.dateFrom) {
      params.dateFrom = filters.dateFrom;
    }
    if (filters.dateTo) {
      params.dateTo = filters.dateTo;
    }
  };

  var addAdditionalInfoParams = function (
    filters,
    params,
    subject,
    corrective = true,
    level = true,
  ) {
    const infoFilters = [];
    if (corrective) {
      addJsonParamValueFilterInfoObject(infoFilters, filters.correctiveAction, "key");
    }
    if (level) {
      const enforcementLevels = RoutineMaintenanceFormTable.getLevelOptions().map(
        (options) => options.value,
      );
      addJsonParamValueFilterInfoObject(
        infoFilters,
        filters.enforcementLevel,
        "level",
        enforcementLevels,
      );
    }

    if (infoFilters.length === 0) {
      return;
    }

    params[`todoAdditionalInfo${firstLetterToUpperCase(subject)}`] =
      getInArrayJsonParamValue(infoFilters);
  };

  var addJsonParamValueFilterInfoObject = function (array, maybeArray, key, whiteList = null) {
    if (!Array.isArray(maybeArray) || maybeArray.length === 0) {
      return;
    }

    return array.push({
      key,
      values: maybeArray,
      whiteList,
    });
  };

  var firstLetterToUpperCase = function (str) {
    return str.replace(/^./, (char) => char.toUpperCase());
  };

  var addFilterToGeoServerQuery = function (queryStrings, filters, filterName, columnName) {
    if (filters[filterName]?.length && !FilterSummary.areAllCheckboxesChecked(filterName)) {
      queryStrings.push(getInArrayQuery(columnName, filters[filterName], null, true));
    }
  };

  function arrayToViewparams(array) {
    return `${array.join(";")};`;
  }

  var addConditionFilterToGeoServerQuery = function (queryStrings, filters) {
    if (FilterSummary.areAllCheckboxesChecked("condition")) {
      return;
    }
    const query = [];
    if (filters.condition?.length !== undefined) {
      if (filters.condition.includes("poor")) {
        query.push("last_maintenance_inspection_due = true");
      }
      if (filters.condition.includes("optimal")) {
        query.push(
          "last_maintenance_inspection_due = false AND last_maintenance_inspection_id is not NULL",
        );
      }
      if (filters.condition.includes("null")) {
        query.push(
          "last_maintenance_inspection_id is NULL OR last_maintenance_inspection_due = false",
        );
      }
      if (query.length) {
        queryStrings.push(`(${query.join(" OR ")})`);
      }
    }
  };

  var addGeographicalFocusFiltersToQuery = function (filters, queryStrings, customFilters = null) {
    const filtersToCheck =
      customFilters && customFilters?.length > 0
        ? customFilters
        : [
            { key: "drainsToRw", field: "drains_to_rw" },
            { key: "drainsToC", field: "drains_to_c" },
            { key: "catchmentName", field: "catchment_name" },
            { key: "zones", field: "zone_names" },
          ];

    for (const filter of filtersToCheck) {
      if (
        filters[filter.key]?.length !== undefined &&
        !FilterSummary.areAllCheckboxesChecked(filter.key)
      ) {
        queryStrings.push(constructSpatialCql(filter.field, filters[filter.key]));
      }
    }
  };

  function constructSpatialCql(field, filterArray) {
    if (!filterArray.length) return "";
    const hasNullString = filterArray.includes("null");
    const hasOutlierOption = filterArray.some((item) => isOtherOption(item));
    const filterValues = filterArray
      .filter((value) => value !== "null")
      .map((value) => `'${value}'`)
      .join(", ");

    const currentDataSort = Tree.get("filters", "dataSort");
    field = _getSpatialColumnField(currentDataSort, field);
    if ((hasNullString || hasOutlierOption) && filterValues && filterArray?.length > 1) {
      return `(${field} IN (${filterValues}) OR ${field} IS NULL)`;
    } else if (hasNullString || hasOutlierOption) {
      return `${field} IS NULL`;
    } else {
      return `${field} IN (${filterValues})`;
    }
  }

  var _getSpatialColumnField = function (dataSort, currentField) {
    const catchmentSelected = Tree.get("filters", "catchments")?.length > 0;
    if (dataSort === "rw" || dataSort === "ud") {
      const parentFieldName = dataSort === "rw" ? "drains_to_rw" : "drains_to_c";
      return catchmentSelected ? "catchment_name" : parentFieldName;
    } else if (dataSort === "uc") {
      return "catchment_name";
    } else if (dataSort === "maintenanceZones") {
      return "zone_names";
    } else {
      return currentField;
    }
  };

  var isOutsideBoundary = function (boundaryName) {
    return ["outside boundary", "Outside Maintenance Zones", "Outside Highways"].includes(
      boundaryName,
    );
  };

  var isOtherOption = function (boundaryName) {
    return ["null", "Other"].includes(boundaryName) || isOutsideBoundary(boundaryName);
  };

  var addSpatialFilterViewParams = function (params, filters) {
    if (!FilterSummary.areAllCheckboxesChecked("drainsToRw")) {
      _setSpatialParams(params, "drainsToRw", filters, "drainsToRw");
    } else {
      if (filters.dataSort === "rw") {
        _setSpatialParams(params, "drainsToRw", filters, "receivingWaters");
      }
    }

    if (!FilterSummary.areAllCheckboxesChecked("drainsToC")) {
      _setSpatialParams(params, "drainsToC", filters, "drainsToC");
    } else {
      if (filters.dataSort === "ud") {
        _setSpatialParams(params, "drainsToC", filters, "receivingWaters");
      }
    }

    if (!FilterSummary.areAllCheckboxesChecked("catchmentName")) {
      _setSpatialParams(params, "catchmentName", filters, "catchmentName");
    } else {
      _setSpatialParams(params, "catchmentName", filters, "catchments");
    }

    if (!FilterSummary.areAllCheckboxesChecked("zones")) {
      _setSpatialParams(params, "zones", filters, "zones");
    } else {
      _setSpatialParams(params, "zones", filters, "maintenanceZones");
    }
  };

  var _setSpatialParams = function (params, paramName, filters, filterName) {
    const filter = filters?.[filterName]?.map(function (item) {
      if (isOtherOption(item)) {
        return null;
      }

      return item;
    });

    if (!filter?.length) {
      return;
    }

    params[paramName] = `$ ? ${getInArrayJsonPaths(filter)}`;
  };

  return {
    getFilterQuery,
    addConditionFilterToGeoServerQuery,
    addFilterToGeoServerQuery,
    getBaseQuery,
    getGroupIdQuery,
    getBaseSpatialFilterQuery,
    getFilterViewParams,
    getToDoSubjectQuery,
    getToDoFilterQuery,
    addFeetAndInchesMinMaxMeasurmentQuery,
    addAdditionalInfoParams,
    getInArrayQuery,
    getWhereNotInArrayQuery,
    getInArrayOrNotQuery,
    getYesNoQuery,
    getSearchQuery,
    getInBooleanArrayQuery,
    addMinMaxQuery,
    yesNoNullGeoServerFilter,
    yesNoExistsGeoServerFilter,
    addTrueFalseNullAsIntFilter,
    addDateQuery,
    formatSqlDateFrom,
    formatSqlDateTo,
    addSpatialFilterQuery,
    getAnnualReportCqlFilters,
    addSpatialFilterQueryWithCachedCatchment,
    createMinMaxMeasurmentCombinedQuery,
    arrayToViewparams,
    addGeographicalFocusFiltersToQuery,
    addSpatialFilterViewParams,
    isOutsideBoundary,
  };
};

module.exports = GeoServerFilterFunctions();

const DataViewFunctions = require("../dataViewFunctions");
const GeoServerConfig = require("../config/geoServerConfig");
const IndcomFacilityFilters = require("../indcom/indcomFacilityFilters");
const LayerFunctions = require("../layerFunctions");
const MuniCatchBasinFilters = require("../muni/muniCatchBasinFilters");
const Progeny = require("../login/progeny");
const ToDoConfig = require("../config/toDoConfig");
const ToDoFunctions = require("./toDoFunctions");
const Tree = require("../tree");
const GeoServerLayerFunctions = require("./geoServerLayerFunctions");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
const Misc = require("../misc");
const MeasurementMinMax = require("../general/measurementMinMax");
const FilterSummary = require("../filters/filterSummary");
