"use strict";

var UserAgreementModal = function () {
  var startUserAgreement = function () {
    $("#userAgreementModal").modal({ backdrop: "static", keyboard: false });
    setUserAgreementModalListeners();
  };

  var setUserAgreementModalListeners = function () {
    $("#user-agreement-cancel").on("click", userAgreementCancelClick);
    $("#user-agreement-confirm").on("click", userAgreementConfirmClick);
  };

  var userAgreementCancelClick = function () {
    Login.logout();
    $("#userAgreementModal").modal("hide");
  };

  var userAgreementConfirmClick = function () {
    ApiCalls.signUserAgreement(function () {
      $("#userAgreementModal").modal("hide");
    });
    Login.setSessionUserAgreementTrue();
  };

  return { startUserAgreement };
};

module.exports = UserAgreementModal();

const Login = require("../login/login");
const ApiCalls = require("../apiCalls");
