"use strict";

var ImperviousInputsLayer = function () {
  const esriKey = "soilImpervious";
  const esriLayer = "impervious";
  const layerName = "imperviousInputs";
  const layerKey = "imperviousInputsLayer";
  const kpiDataLayerId = 4;

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      layerName,
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers[layerKey] && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers[layerKey]);
          } else {
            if (sameSpatialFilter && sameView) {
              // Temp solution to prevent loading twice from dataView and filters update
              return;
            }
            createLayerAndAddToMap(map, mapLayers);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers[layerKey])) {
            map.removeLayer(mapLayers[layerKey]);
          }
        }
      },
    );
  };

  var createLayerAndAddToMap = async function (map, mapLayers) {
    if (mapLayers[layerKey]) {
      map.removeLayer(mapLayers[layerKey]);
    }
    if (!Tree.get(["layers", layerName, "isFetching"])) {
      Tree.set(["layers", layerName, "isFetching"], true);
      mapLayers[layerKey] = await createLayer();
      Tree.set(["layers", layerName, "isFetching"], false);
      mapLayers[layerKey].addTo(map);
    }

    return mapLayers[layerKey];
  };

  var createLayer = async function () {
    const options = {
      pane: "underCatchmentPane",
      layerDefs: EsriLayerFunctions.getLayerDefs(esriKey, esriLayer),
      opacity: 0.7,
    };
    const layer = await EsriLayerFunctions.getEsriLayer(esriKey, esriLayer, options);
    EsriLayerFunctions.getLayerProperties(
      layer,
      esriKey,
      esriLayer,
      renderSummaryTable,
      kpiDataLayerId,
    );
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      catchments: ["filters", "catchments"],
      receivingWaters: ["filters", "receivingWaters"],
    }).on("update", function () {
      if (
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", mapId, layerName, "isEnabled")
      ) {
        mapLayers[layerKey].setLayerDefs(EsriLayerFunctions.getLayerDefs(esriKey, esriLayer));
      }
    });
  };

  var renderSummaryTable = function (featureCollection) {
    var data = getImperviousKpiData(featureCollection);
    var formattedKpiData = InputsLayerFunctions.getFormattedKpiData(data);
    Kpi.renderKpi(formattedKpiData, null, true);
  };

  var getImperviousKpiData = function (featureCollection) {
    var totalAcres = 0;
    var impAcresMap = {
      impTier1: { acres: 0 },
      impTier2: { acres: 0 },
      impTier3: { acres: 0 },
      impTier4: { acres: 0 },
      impTier5: { acres: 0 },
    };

    if (featureCollection && featureCollection.features) {
      featureCollection.features.forEach((imp) => {
        totalAcres += imp.properties.imp_tot_ac;
        if (imp.properties.imp_tier_1) impAcresMap["impTier1"].acres += imp.properties.imp_tier_1;
        if (imp.properties.imp_tier_2) impAcresMap["impTier2"].acres += imp.properties.imp_tier_2;
        if (imp.properties.imp_tier_3) impAcresMap["impTier3"].acres += imp.properties.imp_tier_3;
        if (imp.properties.imp_tier_4) impAcresMap["impTier4"].acres += imp.properties.imp_tier_4;
        if (imp.properties.imp_tier_5) impAcresMap["impTier5"].acres += imp.properties.imp_tier_5;
      });
      addImpPercentAcres(totalAcres, impAcresMap);
    }
    return { totalAcres, impAcresMap };
  };

  var addImpPercentAcres = function (totalAcres, impAcresMap) {
    for (const impTier in impAcresMap) {
      impAcresMap[impTier].percentAcres = impAcresMap[impTier].acres / totalAcres;
    }
  };

  return {
    loadLayerListenersForMap,
    getImperviousKpiData,
  };
};

module.exports = ImperviousInputsLayer();

const MapFunctions = require("../../mapFunctions/mapFunctions");
const Tree = require("../../../tree");
const EsriLayerFunctions = require("../../mapFunctions/esriLayerFunctions");
const InputsLayerFunctions = require("../inputsLayerFunctions");
const Kpi = require("../../report/kpi");
