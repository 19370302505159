"use strict";

const NavToggle = function () {
  let $container;
  let inited = false;
  let currentView;
  let lastViewByPage = {};

  function init() {
    $container = $("#tool-header .nav-toggle-mount");
    $container.getOnlyOrThrow().html(nunjucks.render("navbar/navToggle.njk", { selected: "list" }));

    $container.off("click", ".nav-toggle-button", _updateViewHandler);
    $container.on("click", ".nav-toggle-button", _updateViewHandler);
    inited = true;
  }

  function setupToggles(params) {
    if (!inited) init();
    showToggle(!!params);
    const view = _getLastViewByPage() || params?.view;
    setSelected(view);
    showSelectedView(view, false);
    if (Map.getMap() && !params) {
      $("#mapContent").removeClass("hidden");
      $("#legend-container").addClass("hidden");
      Map.render();
    }
  }

  function showToggle(show) {
    $container.toggleClass("hidden", !show);
  }

  function setSelected(view) {
    $container.find(".nav-toggle-button").toggleClass("active", false);
    if (view) {
      $container.find(`.nav-toggle-${view}-button`).toggleClass("active", true);
    }
    _saveViewByPage(view);
  }

  function _saveViewByPage(view) {
    lastViewByPage[PageController.getPageName()] = view;
  }

  function _getLastViewByPage() {
    return lastViewByPage[PageController.getPageName()];
  }

  function getView() {
    return currentView;
  }

  function showSelectedView(view, updateUrlHash = true) {
    const $pages = $("[id^=esg-]");
    $pages.find(".list-view, .map-view").toggleClass("hidden", true);
    if (view) {
      $pages.find(`.${view}-view`).toggleClass("hidden", false);
      if (updateUrlHash) {
        UrlRoutes.setHash(view);
      }
    }
    if (view === "map") {
      currentView = "map";

      Map.render();
      Map.getMap().invalidateSize();
      FilterSummary.render();
      FilterSummary.handleMapPadding();
    }
    if (view === "list") {
      if (currentView === "map") DataList.render();
      currentView = "list";
      FilterSummary.rerenderHandleRows();
    }
    toggleListViewOnly(view === "map");
  }

  function toggleListViewOnly(hidden) {
    $(".list-view-only").toggleClass("hidden", hidden);
  }

  function _updateViewHandler(e) {
    const view = $(e.currentTarget).data("view");
    updateView(view);
  }

  function updateView(view) {
    setSelected(view);
    showSelectedView(view);
  }

  return {
    init,
    setupToggles,
    setSelected,
    updateView,
    getView,
    showToggle,
  };
};

module.exports = NavToggle();

const Map = require("./map");
const UrlRoutes = require("../routes/urlRoutes");
const FilterSummary = require("../filters/filterSummary");
const DataList = require("../mapFunctions/dataList");
const PageController = require("./pageController");
