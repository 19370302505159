"use strict";

const FactSheet = function () {
  let $parent;
  let currentFactSheetData;
  let currentFactSheetConfigs = {};

  var browseButtonClick = function () {
    $(this).next().click();
  };

  var checkSelectedProfilePicture = function (itemId) {
    const currentProfile = itemId === currentFactSheetData.profileId;
    PhotoCarousel.setCurrentProfileItem(currentProfile);
  };

  var close = function () {
    Tree.set(["factSheet", "open"], false);
    $parent.removeClass("active");
    if (Tree.get("activeTab") === "todo") {
      $(".reporting-timeline").show();
    }
  };

  var createTempProgressThumbnail = function (timestamp) {
    const thumb = nunjucks.render("factSheet/photoThumb.njk", {
      isUploading: true,
      progress: 0,
      photo: { id: timestamp },
    });
    $(thumb).insertBefore(".photo-list-grid .upload-box");
    return $parent.find(`[data-id-photo=${timestamp}]`);
  };

  var displayDeleteProjectModal = function () {
    const factSheetId = currentFactSheetData.id;

    MessageModal.showThreeButtonConfirmWarningModal(
      `Delete ${currentFactSheetData.assetDisplayName}? Click "Delete Project" to permanently delete the ${currentFactSheetData.assetDisplayName} and all associated inspections, photos and files. Click "Delete Project & Assets" to delete the ${currentFactSheetData.assetDisplayName} and all associated stormwater assets. Click "Cancel" to return.`,
      async function () {
        await currentFactSheetConfigs.deleteFactSheetFn(factSheetId);
        updateAndHideFactSheet();
      },
      async function () {
        await currentFactSheetConfigs.deleteFactSheetFn(factSheetId, true);
        updateAndHideFactSheet();
      },
      "Cancel",
      "Delete Project",
      "Delete Project & Assets",
    );
  };

  var displayPhotoCarousel = function (e) {
    PhotoCarousel.showPhotoCarouselModalFromPhotoId($(e.currentTarget).data("idPhoto"));
  };

  var getSorting = function (sectionName) {
    return {
      key: currentFactSheetConfigs.sortingConfigs.keys[sectionName],
      reversed: currentFactSheetConfigs.sortingConfigs.reversed[sectionName],
    };
  };

  var hideFactSheet = function () {
    if (!Tree.get(["factSheet", "open"])) {
      return;
    }
    const currentPhaseConditionToggle = Tree.get(["filters", "conditionPhaseToggle"]);
    const dataView = Tree.get("dataView");
    if (
      [
        "construction-project-delivery",
        "muni-catch-basin",
        "muni-outfalls",
        "construction-project",
        "indcom-facilities",
      ].includes(dataView)
    ) {
      Tree.set("navToggleSelection", "list");
      $(".ms4-return-button").addClass("hidden");
      if (Tree.get("activeTab") !== "todo") {
        FilterSummary.enable();
      }
    }
    Filters.setDataSort(Tree.get(["factSheet", "previousDataSort"]), true, false);
    if (Tree.get(["layers", "constructionProjectG2", "isEnabled"])) {
      Tree.set(["filters", "conditionPhaseToggle"], currentPhaseConditionToggle);
    }
    Filters.toggleFilterButtons(false);
    $("#details-button-container").removeClass("desktop-only");

    if (Tree.get("navToggleSelection") === "list") {
      ListPanel.show(Tree.get("activeTab"), Tree.get("dataView"));
      if (Tree.get("activeTab") === "data") {
        DataList.show();
      }
    }
    $("#details-button-container").removeClass("desktop-only");
    close();
  };

  var handleFilterFlow = function () {
    Tree.set(["factSheet", "open"], true);
    Table.renderBackButtonIfFactSheet();
    FilterSummary.disable();
  };

  var _toggleDataListIcon = function () {
    $(".nav-toggle-list-button").addClass("active");
    $(".nav-toggle-map-button").removeClass("active");
  };
  var init = function (data, configs) {
    $parent = $("#fact-sheet-container");
    currentFactSheetConfigs = configs;

    currentFactSheetData = prepareFactSheetData(data, configs);
    _loadPhotoCarousel(data);
    updateDataSort();
    loadDomListeners();
    convertStateToDisplay(currentFactSheetData);
    render();
  };

  var _loadPhotoCarousel = function (data) {
    PhotoCarousel.load(data.photos, {
      caption: updatePhotoCaption,
      rotate: updatePhotoRotate,
      delete: updatePhotoDelete,
      setProfile: setSelectedProfilePicture,
      showProfile: checkSelectedProfilePicture,
    });
  };

  var prepareFactSheetData = function (data, configs) {
    currentFactSheetData = addConfigData(data, configs);
    currentFactSheetData = processPhotoData(data);
    currentFactSheetData.permitsAndPlans = addAbsoluteUrlsToFiles(data.permitsAndPlans);
    currentFactSheetData.isFullWidth = Tree.get("activeTab") === "todo";
    currentFactSheetData.dataView = Tree.get("dataView");

    return currentFactSheetData;
  };

  function addAbsoluteUrlsToFiles(files) {
    return files?.map((file) => {
      const makeAbsolute = file.externalUrl && !/^(https?:)?\/\/.*$/.test(file.externalUrl);
      file.absoluteUrl = makeAbsolute ? "//" + file.externalUrl : file.externalUrl;
      return file;
    });
  }

  var convertStateToDisplay = function (currentFactSheetData) {
    const stateKey = currentFactSheetData.state;
    const stateDisplay = Misc.inverseConvertState(stateKey);
    currentFactSheetData.state = stateDisplay;
    return currentFactSheetData;
  };

  var loadDomListeners = function () {
    $("#bottomFloatingInputsTable").off("click", ".ms4-return-button");
    $("#bottomFloatingInputsTable").on("click", ".ms4-return-button", hideFactSheet);

    $("#bottomFloatingInputsTable").off("click", ".table-datum");
    $("#bottomFloatingInputsTable").on("click", ".table-datum", selectNewFactSheet);

    $parent.off("click", ".delete-fact-sheet-button");
    $parent.on("click", ".delete-fact-sheet-button", displayDeleteProjectModal);

    $parent.off("click", ".fact-sheet-photo");
    $parent.on("click", ".fact-sheet-photo", displayPhotoCarousel);

    $parent.off("click", ".browse-button");
    $parent.on("click", ".browse-button", browseButtonClick);

    $parent.off("click", "th.sortable");
    $parent.on("click", "th.sortable", sortByClickedColumn);

    $parent.off("click", ".header-clickable");
    $parent.on("click", ".header-clickable", toggleSectionTableExpanded);

    $parent.off("click", ".back-button");
    $parent.on("click", ".back-button", hideFactSheet);

    $parent.off("change", ".file-uploader");
    $parent.on("change", ".file-uploader", function () {
      uploadFactSheetPhotos(this.files);
    });

    $parent.off("click", ".js-delete-assessment");
    $parent.on("click", ".js-delete-assessment", deleteAssessmentClick);
  };

  var deleteAssessmentClick = function () {
    var $tableTr = $(this).closest("tr");
    var data = $tableTr.data();

    BmpFunctions.confirmDeleteHistory($tableTr, data);
  };

  var loadThumbnailImage = function ($target, file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener(
      "load",
      function () {
        $target.find("img").attr("src", reader.result);
      },
      false,
    );
  };

  var locateById = function (array, id) {
    const index = array.findIndex((x) => {
      return x.id === id;
    });
    return index;
  };

  var processPhotoData = function (factSheetData) {
    factSheetData.photos?.forEach((photo) => {
      photo.full = photo.url;
    });
    return factSheetData;
  };

  var render = function () {
    var factSheetTemplate = "factSheet/factSheet.njk";
    $parent.html(nunjucks.render(factSheetTemplate, currentFactSheetData));
    $parent
      .find(".fact-sheet-section-content")
      .html(nunjucks.render(currentFactSheetConfigs.nunjucks.sectionHandler, currentFactSheetData));
    setOpenHeaders();
    setProfilePicture(currentFactSheetData.profileId);
    show();
  };

  var renderSection = function (sectionName, currentFactSheetData) {
    const $section = $parent.find(`[data-section-name=${sectionName}]`);
    const $headerSection = $section.find(`thead`).parent();
    const $dataSection = $section.find(".table-wrapper");
    $dataSection.html(
      nunjucks.render(currentFactSheetConfigs.nunjucks[sectionName].table, {
        [sectionName]: currentFactSheetData[sectionName],
      }),
    );
    $headerSection.html(nunjucks.render(currentFactSheetConfigs.nunjucks[sectionName].header));
  };

  var renderSortingForSection = function (sectionName) {
    const sorting = getSorting(sectionName);
    if (sorting.key) {
      const header = $parent
        .find(`[data-section-name=${sectionName}]`)
        .find(`th[data-sort-key="${sorting.key}"]`);
      header.addClass("sorting");
      header.find(".sort-arrow").toggleClass("flipped", !sorting.reversed);
    }
  };

  var selectNewFactSheet = function (newFactSheet) {
    if (Tree.get(["factSheet", "open"]) === true) {
      currentFactSheetConfigs.loadNewFactSheet($(newFactSheet.currentTarget).data("id"));
    }
  };

  var setOpenHeaders = function () {
    for (const key in currentFactSheetConfigs.openSections) {
      const $section = $(`[data-section-name=${key}]`);
      const $expandArrow = $section.find(".expand-arrow");
      if ($expandArrow.hasClass("disabled")) {
        toggleAdditionalButton($section);
      }
      if (
        currentFactSheetConfigs.openSections[key] === true &&
        !$expandArrow.hasClass("disabled")
      ) {
        $expandArrow.addClass("disable-transition");
        $expandArrow.addClass("open");
        $section.find(".table-header-container table").show();
        $section.find(".table-wrapper").show();
        $section.find(".pull-right").show();
        $expandArrow.removeClass("disable-transition");
      }
    }
  };

  var setProfilePicture = function (pictureId) {
    const photoToSet = currentFactSheetData.photos?.find((photo) => photo.id === pictureId);
    $parent.find(".profile-photo").toggleClass("default-profile-photo", !photoToSet);
    if (photoToSet) {
      currentFactSheetData.profileId = pictureId;
      $parent
        .find(".profile-photo")
        .attr("src", photoToSet.thumbUrl)
        .attr("onerror", `this.onerror=null; this.src='${photoToSet.url}'`);
    } else {
      currentFactSheetData.profileId = null;
      $parent.find(".profile-photo").attr("src", `/ram/images/icons/2n-greyscale.png`);
    }
  };

  var setSelectedProfilePicture = async function (photoId) {
    const currentProfilePhoto = photoId === currentFactSheetData.profileId;
    const idToSet = currentProfilePhoto ? null : photoId;
    await ApiCalls.setFactSheetProfilePhoto(
      currentFactSheetConfigs.apiAssetName,
      currentFactSheetData.id,
      idToSet,
    );
    setProfilePicture(idToSet);
    checkSelectedProfilePicture(photoId);
  };

  var setSorting = function (section, key, reversed) {
    if (key) {
      currentFactSheetConfigs.sortingConfigs.keys[section] = key;
    }
    currentFactSheetConfigs.sortingConfigs.reversed[section] = reversed;
  };

  var show = function () {
    Tree.set(["factSheet", "open"], true);
    Tree.set(["factSheet", "id"], currentFactSheetData.id);
    $("#map-pages").children().removeClass("active");
    $parent.addClass("active");
    $(".reporting-timeline").hide();
  };

  var sortByClickedColumn = function () {
    const sectionName = $(this).parents(".table-section").data("sectionName");
    const key = $(this).data("sortKey");
    if (currentFactSheetConfigs.sortingConfigs.keys[sectionName] === key) {
      setSorting(sectionName, null, !currentFactSheetConfigs.sortingConfigs.reversed[sectionName]);
    } else {
      setSorting(sectionName, key, false);
    }
    sortSingleSection(sectionName, currentFactSheetData);
    renderSection(sectionName, currentFactSheetData);
    renderSortingForSection(sectionName);
  };

  var sortSingleSection = function (sectionName, factSheetData) {
    const sorting = getSorting(sectionName);
    if (currentFactSheetConfigs.sortingConfigs.dictionary[sectionName] !== undefined) {
      SortUtilities.sortRows(
        sorting.key,
        sorting.reversed,
        factSheetData[sectionName],
        currentFactSheetConfigs.sortingConfigs.dictionary[sectionName],
      );
    }
  };

  var toggleAdditionalButton = function ($element) {
    $element.find(".pull-right").toggle();
  };

  var toggleExpandArrow = function ($expandArrow) {
    if ($expandArrow.attr("disabled")) return;
    $expandArrow.toggleClass("open");
  };

  var toggleOpenSection = function ($header) {
    const sectionName = $header.parents("[data-section-name]").data("sectionName");
    currentFactSheetConfigs.openSections[sectionName] =
      !currentFactSheetConfigs.openSections[sectionName];
  };

  var toggleSectionTable = function ($header) {
    const $section = $header.parents(".table-section");
    $section.find(".table-header-container table").toggle();
    $section.find(".table-wrapper").slideToggle(300);
  };

  var toggleSectionTableExpanded = function () {
    const $header = $(this).parent(".table-header");
    const $expandArrow = $header.find(".expand-arrow");
    if (!$expandArrow.hasClass("disabled")) {
      toggleExpandArrow($expandArrow);
      toggleSectionTable($header);
      toggleAdditionalButton($header);
      toggleOpenSection($header);
    }
  };

  var updateAndHideFactSheet = function () {
    if (currentFactSheetConfigs.layerConfigs.invalidateLayerData) {
      currentFactSheetConfigs.layerConfigs.invalidateLayerData();
    } else {
      currentFactSheetConfigs.layerConfigs.loadLayer();
    }
    hideFactSheet();
  };

  var updateDataSort = function () {
    if (Tree.get(["factSheet", "previousDataSort"]) === undefined) {
      Tree.set(["factSheet", "previousDataSort"], Tree.get(["table", "dataSort"]));
    }
    Filters.setDataSort(currentFactSheetConfigs.dataListFilter);
  };

  var updatePhotoCaption = function ($activeSlide, newCaption) {
    const photoId = $activeSlide.data("id");
    const photoName = $activeSlide.data("photo");
    const captionCallback = function () {
      var itemIndex = locateById(currentFactSheetData.photos, photoId);
      currentFactSheetData.photos[itemIndex].caption = newCaption;
    };
    ApiCalls.saveFactSheetPhotoCaption(
      currentFactSheetConfigs.apiAssetName,
      currentFactSheetData.id,
      photoId,
      newCaption,
      photoName,
      captionCallback,
    );
  };

  var updatePhotoDelete = function ($activeSlide) {
    const photoId = $activeSlide.data("id");
    const photoName = $activeSlide.data("photo");
    ApiCalls.deleteFactSheetPhoto(
      currentFactSheetConfigs.apiAssetName,
      currentFactSheetData.id,
      photoId,
      photoName,
      function () {
        var removeIndex = locateById(currentFactSheetData.photos, photoId);
        currentFactSheetData.photos.splice(removeIndex, 1);
        $parent.find(`a[data-id-photo=${photoId}]`).remove();
        if (currentFactSheetData.profileId === photoId) {
          setProfilePicture(null);
          checkSelectedProfilePicture(photoId);
        }
      },
    );
  };

  var updatePhotoRotate = function ($activeSlide, rotation) {
    const photoId = $activeSlide.data("id");
    const photoName = $activeSlide.data("photo");
    const itemIndex = locateById(currentFactSheetData.photos, photoId);
    const currentPhoto = currentFactSheetData.photos[itemIndex];
    ApiCalls.rotateFactSheetPhoto(
      currentFactSheetConfigs.apiAssetName,
      currentFactSheetData.id,
      photoId,
      photoName,
      rotation,
      function (response) {
        currentPhoto.url = response;
        $(`[data-id-photo=${currentPhoto.id}] img`).attr("src", response);
        PhotoCarousel.restoreRotation();
        if (currentFactSheetData.profileId === photoId) {
          setProfilePicture(photoId);
        }
      },
    );
  };

  var updatePhotoUploadProgress = function ($thumb) {
    return function handleUploadProgress(e) {
      if (e.lengthComputable) {
        $thumb.find("progress").val(e.loaded / e.total);
      }
    };
  };

  var uploadCompleteCallback = function ($thumb, newFile) {
    currentFactSheetData.photos.push(newFile);
    if (currentFactSheetData.photos.length === 1) {
      setSelectedProfilePicture(newFile.id);
    }

    $thumb.data("idPhoto", newFile.id).attr("data-id-photo", newFile.id);
    $thumb.find(".upload-progress").remove();
    const $img = $thumb.find("img");
    newFile.full = newFile.url;
    $img.attr("src", newFile.full);
    $img.attr("alt", newFile.caption);
    $thumb.removeClass("uploading");

    _loadPhotoCarousel(currentFactSheetData);
  };

  var uploadFactSheetPhotos = function (fileList) {
    if (fileList !== undefined) {
      for (var i = 0; i < fileList.length; i++) {
        if (!FileFunctions.isFileImage(fileList[i])) {
          MessageModal.showSimpleWarningModal("Unsupported File Type");
          return;
        } else if (fileList[i].size > 8 * 1024 * 1024) {
          MessageModal.showFileSizeWarning(fileList[i].name);
          return;
        } else {
          const timestamp = new Date().valueOf();
          const $thumb = createTempProgressThumbnail(timestamp, fileList[i]);
          loadThumbnailImage($thumb, fileList[i]);
          ApiCalls.uploadFactSheetPhoto(
            currentFactSheetConfigs.apiAssetName,
            currentFactSheetData.id,
            fileList[i],
            updatePhotoUploadProgress($thumb),
            (newFile) => {
              uploadCompleteCallback($thumb, newFile);
            },
          );
        }
      }
    }
  };

  var addConfigData = function (data, config) {
    data.assetDisplayName = config.assetDisplayName;
    data.factSheetIcon = config.getFactSheetIconFunction(data, true);
  };

  return {
    displayDeleteProjectModal,
    hideFactSheet,
    init,
    loadDomListeners,
    setSorting,
    setProfilePicture,
    sortSingleSection,
    updateAndHideFactSheet,
    updatePhotoCaption,
    updatePhotoDelete,
    updatePhotoRotate,
    setSelectedProfilePicture,
    checkSelectedProfilePicture,
    convertStateToDisplay,
    _toggleDataListIcon,
    handleFilterFlow,
    addAbsoluteUrlsToFiles,
  };
};

module.exports = FactSheet();

const ApiCalls = require("../apiCalls");
const DataList = require("../mapFunctions/dataList");
const FileFunctions = require("../files/fileFunctions");
const Filters = require("../mapFunctions/filters");
const ListPanel = require("../mapFunctions/listPanel");
const SortUtilities = require("../general/sortUtilities");
const MessageModal = require("../modals/messageModal");
const PhotoCarousel = require("./photoCarousel");
const Tree = require("../tree");
const BmpFunctions = require("../modals/bmpFunctions");
const Misc = require("../misc");
const FilterSummary = require("../filters/filterSummary");
const Table = require("../mapFunctions/table");
