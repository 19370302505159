"use strict";

const ParcelAssessment = function () {
  const $parcelAssessmentModalContainer = $("#parcel-assessment-modal-container");

  var init = function () {
    loadListeners();
  };

  var loadListeners = function () {
    $parcelAssessmentModalContainer.off();
    $parcelAssessmentModalContainer.on("2N:FormInput", ".date-picker", parcelAssessmentDateInput);
    $parcelAssessmentModalContainer.on("click", ".modal-footer .save-btn", saveParcelAssessment);
    $parcelAssessmentModalContainer.on(
      "click",
      ".modal-footer .cancel-btn",
      cancelParcelAssessmentForm,
    );
    $parcelAssessmentModalContainer.on(
      "change",
      "input[type=radio][name=condition]",
      conditionRadioChange,
    );
  };

  var getDefaultDataForNewAssessment = function () {
    var currentParcelData = Tree.get("currentParcelData");
    return {
      polygon_id: currentParcelData.id,
      group_id: currentParcelData.group_id,
      apn: currentParcelData.apn,
      username: Session.loadContext().username,
      address: currentParcelData.street1,
      condition: "No",
      reason: null,
    };
  };

  var showNewParcelAssessmentModal = function () {
    var data = getDefaultDataForNewAssessment();
    showParcelAssessmentModal(data, () => {
      setParcelAssessmentDateToToday();
      $parcelAssessmentModalContainer.data("readOnly", false);
      initializeAssessmentModalPhotos();
    });
  };

  var showEditParcelAssessmentModal = function (data, readOnly) {
    showParcelAssessmentModal(data, () => {
      DateTimePicker.setDateOnElement(
        $parcelAssessmentModalContainer.find("[name=date]").assertLength(1),
        data.date,
      );
      if (readOnly) {
        Misc.toggleDisabledInputs($("#parcel-assessment-form"), true);
        Misc.toggleDisabledInputs($parcelAssessmentModalContainer.find(".save-btn"), true);
      }
      $parcelAssessmentModalContainer.data("readOnly", readOnly);
      initializeAssessmentModalPhotos(data.id, readOnly);
    });
  };

  var showParcelAssessmentModal = function (data, callback) {
    var props = $.extend({}, data, {
      parcelConditionOptions: ParcelAssessmentConstants.parcelConditionOptions,
      parcelConditionReasonOptions: ParcelAssessmentConstants.parcelConditionReasonOptions,
    });

    renderParcelAssessmentForm(props).then(() => {
      Form.initializeAndLoadListeners($parcelAssessmentModalContainer, "parcel-assessment");
      Form.manuallySetFormDataField("parcel-assessment", [], data);
      if (callback) {
        callback();
      }
      $("#parcel-assessment-modal").modal("show");
    });
  };

  var renderParcelAssessmentForm = async function (props) {
    const html = nunjucks.render("modals/lid/parcelAssessmentModal.njk", props);
    $parcelAssessmentModalContainer.html(html);
  };

  var parcelAssessmentDateInput = function (e, newDate) {
    if (!newDate) {
      setParcelAssessmentDateToToday();
    }
  };

  var setParcelAssessmentDateToToday = function () {
    const todayDate = DateTimePicker.setToNow($parcelAssessmentModalContainer, "date");
    Form.manuallySetFormDataField("parcel-assessment", ["date"], todayDate);
  };

  var saveParcelAssessment = function () {
    var data = Form.getDataFromForm("parcel-assessment");

    $parcelAssessmentModalContainer
      .find(".modal-footer .save-btn")
      .addClass("disabled")
      .text("Saving");

    if (data.id) {
      ApiCalls.updateParcelAssessment(data.id, data, parcelAssessmentSaved);
    } else {
      ApiCalls.saveNewParcelAssessment(data.polygon_id, data, parcelAssessmentSaved);
    }
  };

  var parcelAssessmentSaved = function (id) {
    PopupPhotos.saveTempPhotos("parcelAssessment", id);
    Actions.loadRunoffCondition();
    $("#parcel-assessment-modal").modal("toggle");
  };

  var cancelParcelAssessmentForm = function () {
    function close() {
      Form.clearForm("parcel-assessment");
      PopupPhotos.clearAssessmentTempPhotos("parcelAssessment");
      $("#parcel-assessment-modal").modal("toggle");
    }

    if ($parcelAssessmentModalContainer.data("readOnly")) {
      close();
    } else {
      MessageModal.showConfirmWarningModal(
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
        close,
        "Go Back",
        "Close Without Saving",
      );
    }
  };

  var conditionRadioChange = function (e) {
    var showReasonSelect = $(this).val() === "No";
    $parcelAssessmentModalContainer.find(".info-input.reason").toggle(showReasonSelect);
  };

  var getParcelConditionAbbr = function (condition) {
    if (condition && typeof condition === "string") {
      condition = condition.toUpperCase();
    }
    switch (condition) {
      case "YES":
        return "RN";
      case "NO":
        return "SQ";
      case "PARTIAL":
        return "RR";
      default:
        return "—";
    }
  };

  var getParcelConditionString = function (condition) {
    if (condition && typeof condition === "string") {
      condition = condition.toUpperCase();
    }
    switch (condition) {
      case "YES":
        return "Runoff Neutral";
      case "NO":
        return "Status Quo";
      case "PARTIAL":
        return "Reduced Runoff";
      default:
        return "—";
    }
  };

  var initializeAssessmentModalPhotos = function (id, readOnly = false) {
    if (id === undefined) {
      const currentParcelData = Tree.get("currentParcelData");
      id = currentParcelData.id;
    }
    Tree.set(["parcelAssessment_photos", "currentId"], id);
    PopupPhotos.load("parcelAssessment", id, readOnly);
  };

  return {
    init,
    showNewParcelAssessmentModal,
    showEditParcelAssessmentModal,
    getParcelConditionAbbr,
    getParcelConditionString,
  };
};

module.exports = ParcelAssessment();

const CommonModalFunctions = require("../modals/commonModalFunctions");
const Tree = require("../tree");
const Session = require("../login/session");
const ApiCalls = require("../apiCalls");
const Actions = require("../actions");
const Misc = require("../misc");
const MessageModal = require("../modals/messageModal");
const DateTimePicker = require("../general/dateTimePicker");
const Form = require("../general/form");
const ParcelAssessmentConstants = require("./parcelAssessmentConstants");
const PopupPhotos = require("../mapFunctions/popupPhotos");
