"use strict";

const ToDoConfig = function () {
  const getToDoConfigBySubject = function () {
    return {
      maintenanceNeeded: {
        apiSubjectName: "maintenance needed",
        displayName: "Functional Repairs",
        popupHighlightSelector: ".tr-score",
        numberBubbleClass: "asset-poor",
        canAccessCallback: BmpFcsToDoController.canAssess,
        listTemplate: "list/toDoListTables/maintenanceNeeded.njk",
      },
      assessmentDue: {
        apiSubjectName: "assessment due",
        displayName: "Functional Assessments",
        popupHighlightSelector: ".js-popup-assessment-button",
        numberBubbleClass: "asset-due",
        canAccessCallback: BmpFcsToDoController.canAssess,
        listTemplate: "list/toDoListTables/assessmentDue.njk",
      },
      inventoryIncomplete: {
        apiSubjectName: "inventory incomplete",
        displayName: "Missing Information",
        popupHighlightSelector: ".tr-inventory",
        numberBubbleClass: "asset-incomplete",
        canAccessCallback: BmpFcsToDoController.canCreate,
        listTemplate: "list/toDoListTables/inventoryIncomplete.njk",
      },
      routineSiteVisit: {
        apiSubjectName: "routine-site-visit",
        databaseName: "routine_site_visit",
        displayName: "Routine Inspection",
        popupHighlightSelector: ".js-add-bmpfcs-routine-inspection",
        numberBubbleClass: "asset-due",
        canAccessCallback: BmpFcsToDoController.canInspect,
        prepareDataCallback: BmpFcsToDoController.organizeRoutineSiteVisitData,
        filterTemplate: "modals/toDoFilters/routineSiteVisitFiltersModal.njk",
        defaultSort: "dueDateIso",
        listTemplate: "list/toDoListTables/routineSiteVisit.njk",
        prioritizeModule: Session.isAdmin() ? ToDoPrioritizeController : null,
      },
      routineRepair: {
        apiSubjectName: "routine-repair",
        databaseName: "routine_repair",
        displayName: "Routine Maintenance",
        popupHighlightSelector: ".js-add-bmpfcs-routine-repair",
        numberBubbleClass: "asset-poor",
        canAccessCallback: BmpFcsToDoController.canInspect,
        prepareDataCallback: BmpFcsToDoController.organizeRepairData,
        filterTemplate: "modals/toDoFilters/routineRepairFiltersModal.njk",
        getFilterTemplateDataCallback: MuniBmpFilters.addTodoProps,
        defaultSort: "dueDateIso",
        listTemplate: "list/toDoListTables/routineRepair.njk",
      },
      routineSiteVisitBmp: {
        apiSubjectName: "sbmp-site-visit",
        displayName: "Routine Inspection",
        databaseName: "routine_site_visit",
        popupHighlightSelector: ".js-popup-routine-inspection-maintenance",
        numberBubbleClass: "asset-due",
        canAccessCallback: BmpFcsToDoController.canInspect,
        prepareDataCallback: BmpFcsToDoController.organizeRoutineSiteVisitBmpData,
        listTemplate: "list/toDoListTables/routineSiteVisitBmp.njk",
        defaultSort: "dueDateIso",
      },
      routineRepairBmp: {
        apiSubjectName: "sbmp-routine-repair",
        displayName: "Routine Maintenance",
        databaseName: "routine_repair",
        popupHighlightSelector: ".js-popup-routine-inspection-maintenance",
        numberBubbleClass: "asset-poor",
        canAccessCallback: BmpFcsToDoController.canInspect,
        prepareDataCallback: BmpFcsToDoController.organizeRoutineRepairBmpData,
        listTemplate: "list/toDoListTables/routineRepairBmp.njk",
        defaultSort: "dueDateIso",
      },
      catchBasinInventoryIncomplete: {
        apiSubjectName: "catch-basin-inventory-incomplete",
        databaseName: "catch_basin_inventory_incomplete",
        displayName: "Missing Information",
        popupHighlightSelector: ".tr-inventory",
        numberBubbleClass: "asset-incomplete",
        prepareDataCallback: BmpFcsToDoController.organizeCatchBasinInventoryIncompleteData,
        canAccessCallback: BmpFcsToDoController.possiblyEditBmp,
        filterTemplate: "modals/toDoFilters/catchBasinInventoryIncompleteFiltersModal.njk",
        defaultSort: "bmpName",
        listTemplate: "list/toDoListTables/catchBasinInventoryIncomplete.njk",
      },
      incidentNew: {
        apiSubjectName: "incident-new",
        displayName: "New Requests",
        popupHighlightSelector: ".new-incident-investigation",
        numberBubbleClass: "asset-due",
        prepareDataCallback: IncidentInventoryConstants.addIncidentNewToDoDisplayNames,
        canAccessCallback: IncidentInvestigationConstants.canAccessIncidentInvestigations,
        filterTemplate: "modals/toDoFilters/incidentNewFiltersModal.njk",
        getFilterTemplateDataCallback: IncidentInventoryConstants.addIncidentToDoFilterOptions,
        listTemplate: "list/incidentNewToDoList.njk",
        loadListenersCallback: IncidentInventoryConstants.loadToDoListeners,
      },
      incidentOpen: {
        apiSubjectName: "incident-open",
        displayName: "Open Investigations",
        popupHighlightSelector: ".new-incident-investigation",
        numberBubbleClass: "asset-poor",
        prepareDataCallback: IncidentInvestigationConstants.addIncidentOpenToDoDisplayNames,
        canAccessCallback: IncidentInvestigationConstants.canAccessIncidentInvestigations,
        filterTemplate: "modals/toDoFilters/incidentOpenFiltersModal.njk",
        getFilterTemplateDataCallback: IncidentInventoryConstants.addIncidentToDoFilterOptions,
        listTemplate: "list/incidentOpenToDoList.njk",
        loadListenersCallback: IncidentInventoryConstants.loadToDoListeners,
      },
      muniOutfallRoutineInspection: {
        apiSubjectName: "muni-outfall-routine-inspection",
        databaseName: "outfall_inspection",
        displayName: "Routine Inspection",
        numberBubbleClass: "asset-due",
        filterTemplate: "modals/toDoFilters/muniOutfallFiltersModal.njk",
        getFilterTemplateDataCallback: OutfallInspectionConstants.addInspectionToDoFilterOptions,
        prepareDataCallback: MuniOutfallToDoList.organizeMuniOutfallRoutineInspection,
        canAccessCallback: MuniOutfallToDoList.canAccessRoutineInspections,
        defaultSort: "dueDateIso",
        listTemplate: "list/toDoListTables/muniOutfallRoutineInspection.njk",
      },
      muniOutfallRoutineMaintenance: {
        apiSubjectName: "muni-outfall-routine-maintenance",
        databaseName: "outfall_repair",
        displayName: "Routine Maintenance",
        numberBubbleClass: "asset-poor",
        filterTemplate: "modals/toDoFilters/muniOutfallFiltersModal.njk",
        getFilterTemplateDataCallback: OutfallInspectionConstants.addInspectionToDoFilterOptions,
        prepareDataCallback: MuniOutfallToDoList.organizeMuniOutfallRoutineMaintenance,
        canAccessCallback: MuniOutfallToDoList.canAccessRoutineInspections,
        defaultSort: "dueDateIso",
        listTemplate: "list/toDoListTables/muniOutfallRoutineMaintenance.njk",
      },
      constructionCompleteProject: {
        displayName: "Complete Project Tasks",
        numberBubbleClass: "asset-due",
        apiSubjectName: "complete-project",
        listTemplate: "list/toDoListTables/constructionCompleteProject.njk",
        canAccessCallback: ConstructionToDoController.canShowCompleteProjectTodos,
        loadListenersCallback: ConstructionToDoController.loadToDoListeners,
      },
      constructionInspectionDue: {
        displayName: "Inspection Due",
        numberBubbleClass: "asset-due",
        apiSubjectName: "inspection-due",
        listTemplate: "list/toDoListTables/constructionInspectionDue.njk",
        canAccessCallback: ConstructionToDoController.canShowProjectInspectionTodos,
      },
      constructionEnforcementUnresolved: {
        displayName: ToolSettings.getSetting("construction", "is-findings-workflow")
          ? "Open Project Findings"
          : "Open Enforcements",
        apiSubjectName: "enforcement-unresolved",
        listTemplate: "list/toDoListTables/constructionEnforcementUnresolved.njk",
        numberBubbleClass: "asset-poor",
        canAccessCallback: ConstructionToDoController.canShowProjectEnforcementActionsTodos,
        loadListenersCallback: ConstructionToDoController.loadToDoListeners,
      },
      constructionInstallationInspection: {
        displayName: "Structural BMP Installation Tasks",
        numberBubbleClass: "asset-incomplete",
        apiSubjectName: "installation-inspection",
        listTemplate: "list/toDoListTables/constructionInstallationInspection.njk",
        canAccessCallback: ConstructionToDoController.canShowBmpInstallationTodos,
      },
      constructionFormalCommunication: {
        displayName: "Formal Communications",
        numberBubbleClass: "asset-poor",
        apiSubjectName: "formal-communication",
        listTemplate: "list/toDoListTables/constructionFormalCommunication.njk",
        canAccessCallback: ConstructionToDoController.canShowFormalCommunicationTodos,
        loadListenersCallback: ConstructionToDoController.loadToDoListeners,
      },
      constructionIndividualEnforcementUnresolved: {
        displayName: UserRoles.getConfig("individualEnforcementsTodoTitle"),
        numberBubbleClass: "asset-poor",
        apiSubjectName: "individual-enforcement-unresolved",
        listTemplate: "list/toDoListTables/constructionIndividualEnforcementUnresolved.njk",
        canAccessCallback: ConstructionToDoController.canShowIndividualEnforcementsSection,
        loadListenersCallback: ConstructionToDoController.loadToDoListeners,
      },
      lidFormalCommunications: {
        apiSubjectName: "lid-formal-communications",
        displayName: FeatureFlag.enabled("pcr-letter")
          ? "Self-Verification Notices"
          : "Formal Communications",
        prepareDataCallback: LidToDoList.organizeFormalCommunications,
        canAccessCallback: LidToDoList.canManage,
        numberBubbleClass: "asset-due",
        listTemplate: "list/lidProjectFormalCommunicationsToDoList.njk",
        loadListenersCallback: LidToDoList.loadFormalCommunicationsListeners,
      },
      lidResponseNeeded: {
        apiSubjectName: "lid-response-needed",
        displayName: "Responses",
        prepareDataCallback: LidToDoList.organizeResponseNeeded,
        canAccessCallback: LidToDoList.canManage,
        numberBubbleClass: "asset-poor",
        listTemplate: ToolSettings.getSetting("group", "portalSubscriberTools")?.includes("lid")
          ? "list/lidProjectResponseNeededCollaborationPortalToDoList.njk"
          : "list/lidProjectResponseNeededToDoList.njk",
        loadListenersCallback: LidToDoList.loadResponseNeededListeners,
      },
      lidMissingInformation: {
        apiSubjectName: "lid-missing-information",
        displayName: "Missing Information",
        prepareDataCallback: LidToDoList.organizeMissingInformation,
        canAccessCallback: LidToDoList.canInventory,
        numberBubbleClass: "asset-incomplete",
        listTemplate: "list/lidProjectMissingInformationToDoList.njk",
        loadListenersCallback: LidToDoList.loadMissingInformationListeners,
      },
      lidProjectEnforcement: {
        apiSubjectName: "lid-open-enforcements",
        databaseName: "lid_open_enforcement",
        displayName: "Open Enforcements",
        prepareDataCallback: LidToDoList.organizeProjectEnforcements,
        canAccessCallback: LidToDoList.canEnforceProject,
        numberBubbleClass: "asset-action",
        listTemplate: "list/toDoListTables/lidProjectEnforcement.njk",
        defaultSort: "issueDate",
        getSortersDictCallback: LidToDoList.getSortersDict,
        loadListenersCallback: () => {},
      },
      indcomFacilityInspection: {
        apiSubjectName: "indcom-facility-inspections",
        databaseName: "indcom_facility_inspection",
        displayName: "Inspection Due",
        prepareDataCallback: IndcomToDoList.organizeFacilityInspections,
        canAccessCallback: IndcomToDoList.canInspectFacility,
        numberBubbleClass: "asset-due",
        listTemplate: "list/toDoListTables/indcomFacilityInspection.njk",
        defaultSort: "dueDate",
        getSortersDictCallback: IndcomToDoList.getSortersDict,
        loadListenersCallback: IndcomToDoList.loadListeners,
      },
      indcomFacilityFinding: {
        apiSubjectName: "indcom-facility-findings",
        databaseName: "indcom_facility_finding",
        displayName: "Open Findings",
        prepareDataCallback: IndcomToDoList.organizeFacilityFindings,
        canAccessCallback: IndcomToDoList.canInspectFacility,
        numberBubbleClass: "asset-poor",
        listTemplate: "list/toDoListTables/indcomFacilityFinding.njk",
        defaultSort: "dueDate",
        getSortersDictCallback: IndcomToDoList.getSortersDict,
        loadListenersCallback: IndcomToDoList.loadListeners,
      },
      indcomFacilityEnforcement: {
        apiSubjectName: "indcom-facility-enforcements",
        databaseName: "indcom_facility_enforcement",
        displayName: "Open Enforcements",
        prepareDataCallback: IndcomToDoList.organizeFacilityEnforcements,
        canAccessCallback: IndcomToDoList.canEnforceFacility,
        numberBubbleClass: "asset-action",
        listTemplate: "list/toDoListTables/indcomFacilityEnforcement.njk",
        defaultSort: "issueDate",
        getSortersDictCallback: IndcomToDoList.getSortersDict,
        loadListenersCallback: IndcomToDoList.loadListeners,
      },
      indcomFacilityInventoryIncomplete: {
        apiSubjectName: "indcom-facility-inventory-incomplete",
        databaseName: "indcom_facility_inventory_incomplete",
        displayName: "Missing Information",
        prepareDataCallback: IndcomToDoList.organizeFacilityInventoryIncomplete,
        canAccessCallback: IndcomToDoList.canEditFacility,
        numberBubbleClass: "asset-incomplete",
        listTemplate: "list/toDoListTables/indcomFacilityInventoryIncomplete.njk",
        defaultSort: "name",
        getSortersDictCallback: IndcomToDoList.getSortersDict,
        loadListenersCallback: IndcomToDoList.loadListeners,
      },
    };
  };

  const getToDoConfigObjectByDataView = function () {
    return {
      "construction-project": {
        genericFactSheet: ProjectFactSheetController,
      },
      bmp: {
        genericFactSheet: false,
      },
      fcs: {
        genericFactSheet: false,
      },
      incidents: {
        genericFactSheet: false,
      },
      "muni-catch-basin": {
        genericFactSheet: CatchBasinFactSheetController,
      },
      "muni-outfalls": {
        genericFactSheet: OutfallFactSheetController,
      },
      "indcom-facilities": {
        genericFactSheet: FacilityFactSheetController,
      },
      "lid-project": {
        genericFactSheet: { initFactSheet: (id) => UrlRoutes.go("lid-fact-sheet", { id }) },
      },
    };
  };

  const _getToDoFiltersConfigObjectByDataView = function () {
    return {
      incidents: {
        filters: {
          incidentNew: {
            requestingType: [
              "phone",
              "app",
              "dry-weather-screen",
              "off-hour-assessments",
              "permit-inspection",
              "drive-by",
              "storm-water-asset",
              "email",
              "quarterly-evening-inspections",
            ],
            requestingAffiliation: [
              "general-public",
              "partner-agency",
              "dot-staff",
              "ms4-staff",
              "local-agency",
            ],
            requestedDateFrom: null,
            requestedDateTo: null,
          },
          incidentOpen: {
            requestingType: [
              "phone",
              "app",
              "dry-weather-screen",
              "off-hour-assessments",
              "permit-inspection",
              "drive-by",
              "storm-water-asset",
            ],
            incidentType: ["illicit-flow", "spill", "illegal-dumping", "unknown"],
            responsiblePartyIdentified: ["true", "false", "unknown"],
            requestingAffiliation: [
              "general-public",
              "partner-agency",
              "dot-staff",
              "ms4-staff",
              "local-agency",
            ],
            requestedDateFrom: null,
            requestedDateTo: null,
          },
        },
        filterDataTypes: {
          requestedDate: "dateRange",
        },
      },
      "muni-catch-basin": {
        filters: {
          routineSiteVisit: {
            consequenceOfFailure: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
            catchBasinPriority: ["high", "medium", "low", "unknown"],
            inProximityTo303dWaterbody: ["true", "false", "unknown"],
            dueDateFrom: null,
            dueDateTo: null,
            allSpatial: true,
          },
          routineRepair: {
            consequenceOfFailure: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
            catchBasinPriority: ["high", "medium", "low", "unknown"],
            correctiveAction: MuniBmpFilters.getCatchBasinCorrectiveActions().map(
              (task) => task.value,
            ),
            enforcementLevel: [
              "minimal-issue",
              "minor-issue",
              "major-issue",
              "replacement-required",
              "clean",
              "unknown",
            ],
            dueDateFrom: null,
            dueDateTo: null,
            allSpatial: true,
          },
          catchBasinInventoryIncomplete: {
            allSpatial: true,
          },
        },
        filterDataTypes: {
          dueDate: "dateRange",
        },
      },
      "muni-outfalls": {
        filters: {
          muniOutfallRoutineInspection: {
            dueDateFrom: null,
            dueDateTo: null,
            implementerType: [
              "partner",
              "private",
              "our-agency",
              "dot",
              "ms4",
              "local-agency",
              "other",
              "unknown",
            ],
            maintainerType: [
              "partner",
              "private",
              "our-agency",
              "dot",
              "ms4",
              "local-agency",
              "other",
              "unknown",
            ],
            outletType: [
              "apron",
              "ditch-outlet",
              "earthen",
              "flap-gate",
              "pipe",
              "slotted",
              "tide-chamber",
              "unknown",
              "weir",
              "rundown",
              "other",
              "flared",
              "riser",
              "endwall",
            ],
            priority: ["true", "false"],
            consequenceOfFailure: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
          },
          muniOutfallRoutineMaintenance: {
            dueDateFrom: null,
            dueDateTo: null,
            implementerType: [
              "partner",
              "private",
              "our-agency",
              "dot",
              "ms4",
              "local-agency",
              "other",
              "unknown",
            ],
            maintainerType: [
              "partner",
              "private",
              "our-agency",
              "dot",
              "ms4",
              "local-agency",
              "other",
              "unknown",
            ],
            outletType: [
              "apron",
              "ditch-outlet",
              "earthen",
              "flap-gate",
              "pipe",
              "slotted",
              "tide-chamber",
              "unknown",
              "weir",
              "rundown",
              "other",
              "flared",
              "riser",
              "endwall",
            ],
            priority: ["true", "false"],
            consequenceOfFailure: ["very-high", "high", "moderate", "low", "very-low", "unknown"],
          },
        },
        filterDataTypes: {
          dueDate: "dateRange",
        },
      },
    };
  };

  var getAllToDoDataViewConfigsByProperty = function (property) {
    const configsByDataView = _getToDoFiltersConfigObjectByDataView();
    var result = {};

    for (const dataView in configsByDataView) {
      const configProperty = configsByDataView[dataView][property];

      if (configProperty !== undefined) {
        result[dataView] = configProperty;
      }
    }

    return result;
  };

  var getToDoDataViewConfig = function (pathArray, throwOnUndefined = true) {
    var config = getToDoConfigObjectByDataView();

    for (const pathPart of pathArray) {
      config = config ? config[pathPart] : undefined;

      if (config === undefined && throwOnUndefined) {
        throw new Error(`No to do data view property configured for [${pathArray.join(", ")}].`);
      }
    }

    return config;
  };

  var getToDoFiltersDataViewConfig = function (pathArray, throwOnUndefined = true) {
    var config = _getToDoFiltersConfigObjectByDataView();

    for (const pathPart of pathArray) {
      config = config ? config[pathPart] : undefined;

      if (config === undefined && throwOnUndefined) {
        throw new Error(`No to do data view property configured for [${pathArray.join(", ")}].`);
      }
    }

    return config;
  };

  var getToDoSubjectConfig = function (pathArray, throwOnUndefined = true) {
    var config = getToDoConfigBySubject();

    for (const pathPart of pathArray) {
      config = config ? config[pathPart] : undefined;

      if (config === undefined && throwOnUndefined) {
        throw new Error(`No to do subject property configured for [${pathArray.join(", ")}].`);
      }
    }

    return config;
  };

  return {
    getAllToDoDataViewConfigsByProperty,
    getToDoSubjectConfig,
    getToDoDataViewConfig,
    getToDoFiltersDataViewConfig,
    _getToDoFiltersConfigObjectByDataView,
  };
};

module.exports = ToDoConfig();

const BmpFcsToDoController = require("../bmpfcs/bmpFcsToDoController");
const CatchBasinFactSheetController = require("../muni/catchBasinFactSheetController.js");
const IncidentInventoryConstants = require("../illicitDischarge/incidents/incidentInventoryConstants");
const IncidentInvestigationConstants = require("../illicitDischarge/incidentInvestigation/incidentInvestigationConstants");
const MuniBmpFilters = require("../muni/bmp/muniBmpFilters");
const MuniOutfallToDoList = require("../muni/outfalls/muniOutfallToDoList");
const OutfallFactSheetController = require("../muni/outfallFactSheetController");
const OutfallInspectionConstants = require("../outfalls/outfallInspectionConstants");
const ProjectFactSheetController = require("../construction/projectFactSheetController.js");
const LidToDoList = require("../lid/lidToDoList");
const ConstructionToDoController = require("../construction/constructionToDoController");
const ToolSettings = require("../settings/toolSettings");
const UserRoles = require("../login/userRoles");
const UrlRoutes = require("../routes/urlRoutes.js");
const ToDoPrioritizeController = require("../muni/catchBasinToDoPrioritize/toDoPrioritizeController.js");
const Session = require("../login/session.js");
const IndcomToDoList = require("../indcom/indcomToDoList");
const FacilityFactSheetController = require("../indcom/facilityFactSheetController.js");
const FeatureFlag = require("../settings/featureFlag.js");
