"use strict";

const PropertyInventoryModalController = function () {
  let renderData;
  const formKey = "property-inventory";

  var loadFormListener = function () {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
    $("#inventory-modal .modal-dynamic-content").on("2N:FormInput", onFormInput);
  };

  var unloadFormListener = function () {
    $("#inventory-modal .modal-dynamic-content").off("2N:FormInput");
  };

  var onFormInput = function (e, value, path) {
    checkEnableSaveButton(value, path);

    setModalTitle();
  };

  var checkEnableSaveButton = function (value, path) {
    const existingValue = getExistingPropertyData(path);
    if (existingValue !== undefined && value === existingValue) {
      Form.manuallyUnsetField(formKey, path);
    }

    if (formHasUpdates()) {
      InventoryModal.readySaveState(false);
    } else {
      InventoryModal.readySaveState(true);
    }
  };

  var formHasUpdates = function () {
    return !Object.keys(Form.getDataFromForm(formKey, false)).length === 0;
  };

  const renderPageContent = function (html) {
    InventoryModal.setModalDynamicContent(html);
  };

  const getFormKey = function () {
    return formKey;
  };

  const getAllData = function () {
    return InventoryModal.getAllData(formKey);
  };

  const showPropertyInventoryModal = async function (data, startingPage, options = {}) {
    const Location = require("./propertyInventory/locationPage");
    const Boundary = require("./propertyInventory/boundaryPage");
    const Information = require("./propertyInventory/informationPage");
    const Contacts = require("./propertyInventory/contactsPage");
    const Files = require("./propertyInventory/filesPage");
    const Units = require("./propertyInventory/unitsPage");

    renderData = data;
    const pages = [Location, Boundary, Information, Contacts, Files, Units];
    const handlers = {
      saveAndCloseHandler: saveAndCloseModal,
      cancelHandler: _onCancelled,
      formHasUpdatesHandler: _onFormHasUpdates,
    };

    options = {
      ...options,
      existingLocation: !!renderData?.propertyIdentifier,
    };

    Tree.set(["asset", formKey], {
      ...renderData,
      dropdownOptions: PageController.getDropdownOptions(),
    });

    await InventoryModal.showModal(pages, startingPage, options, handlers);
    InventoryModal.disableSaveButton();
    InventoryModal.resetModalTitle();

    setModalTitle(data.propertyIdentifier, data.ejCommunity);
    InventoryModal.allowedSaveState(!!data?.propertyIdentifier);
    loadFormListener();
  };

  var saveAndCloseModal = async function () {
    await Form.getReadyToSavePromise(formKey);
    const data = getExistingPropertyData();
    const updates = Form.getDataFromForm(formKey, false);
    processAreaSelection(updates);
    const updateData = Form.toFormData({
      id: data.id,
      updates: updates,
    });

    const confirmSave = async function () {
      Form.clearForm(formKey);
      const newPropertyData = data.id
        ? await EsgApiCalls.updateProperty(updateData)
        : await EsgApiCalls.createProperty(updateData);

      const isFactSheet = window.location.pathname.split("/").slice(-1)[0] === data.id;
      if (isFactSheet) PageController.refresh(newPropertyData);

      closeModal();
    };

    const validation = await EsgApiCalls.validateUpdateProperty(updateData);

    return new Promise((resolve) => {
      const modalMessage = getModalMessage(data, updates, validation);

      const showConfirmModal = function () {
        MessageModal.showConfirmWarningModal(
          null,
          confirmSave,
          "Go Back",
          "Save & Close",
          modalMessage,
          null,
          "Warning",
          "esg",
        );
      };

      if (validation?.validated) {
        // temporarily disabled until product team decides on this (remove this line to re-enable)
        validation.unitDataAligns = true;
        // end of temporary disable
        if (!validation?.unitDataAligns) {
          MessageModal.showConfirmWarningModal(
            null,
            () => setTimeout(showConfirmModal, 100),
            "Go Back",
            "Continue",
            "The sum of your unit data does not match your property data. Click “Go Back” to change this data or “Continue” to proceed.",
            () => _goToPage("units"),
            "Warning",
            "esg",
          );
        } else {
          showConfirmModal();
        }
      } else {
        MessageModal.showReturnWarningModal(modalMessage, true, "Ok");
        _goToPageWithErrors(validation);
      }
      resolve(false);
    });
  };

  var getModalMessage = function (data, updates, validation) {
    if (validation?.validated) {
      return nunjucks.render("esg/modals/propertyInventory/confirmModal.njk", {
        isNew: !data.id,
        noBoundary: !updates.drawnGeom,
        boundaryChanged: updates.drawnGeom || updates.drawnGeom === null,
      });
    } else {
      return nunjucks.render("esg/modals/propertyInventory/warningModal.njk", validation);
    }
  };

  var _goToPageWithErrors = function (validation) {
    if (validation.propertyMessages?.length) {
      _goToPage("property-location");
    } else if (validation.geomMessages?.length) {
      _goToPage("property-boundary");
    } else if (validation.unitMessages?.length) {
      _goToPage("units");
    }
  };

  var _goToPage = function (pageKey) {
    if (InventoryModal.getActivePageStringKey() === pageKey) return;
    InventoryModal.cleanUpPage();
    InventoryModal.setActivePageByKey(pageKey, { existingLocation: true });
  };

  var closeModal = function () {
    AreaSelectionMap.resetDrawController();
    DrawController.resetDrawnItemsLayer();
    Form.clearForm(formKey);
    unloadFormListener();
    InventoryModal.hide();
  };

  var _onCancelled = function () {
    DrawController.resetDrawnItemsLayer();
    unloadFormListener();
    Form.clearForm(formKey);
  };

  var _onFormHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getExistingPropertyData = function (path = []) {
    path = Array.isArray(path) ? path : [path];
    return Tree.get(["asset", formKey, ...path]);
  };

  function getLatestData(path) {
    path = Array.isArray(path) ? path : [path];
    return InventoryModal.getLatestData(formKey, path);
  }

  var setData = function (path, value) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallySetFormDataField(formKey, path, value);
  };

  var unsetData = function (path) {
    path = Array.isArray(path) ? path : [path];
    Form.manuallyUnsetField(formKey, path);
    return Form.getDataFromForm(formKey, false);
  };

  var setModalTitle = function () {
    const propertyIdentifier = getLatestData("propertyIdentifier");
    const propertyName = getLatestData("propertyName");
    const ejCommunity = getLatestData("ejCommunity");

    InventoryModal.resetModalTitle();

    const title = EsgSettings.propertyTitleDisplay({ propertyName, propertyIdentifier });
    InventoryModal.setModalTitle(`${title.label}: `, title.value, title.fieldName);
    if (typeof ejCommunity !== typeof undefined) {
      InventoryModal.setModalTitle("EJ Community: ", ejCommunity ? "Yes" : "No", "ejCommunity");
    }
  };

  var loadExistingPropertyMarker = function (zoom) {
    const latlng = {
      lat: getLatestData("latitude"),
      lng: getLatestData("longitude"),
    };

    const marker = new L.marker(latlng, {
      icon: PropertiesIcon.getIcon(3, [25, 25]),
      zIndexOffset: 2000,
    });
    ModalMap.setSingleMarker(marker);
  };

  var updateAcres = function (acres) {
    $(".property-acres div").text(NunjucksFilters.commafyWithPlaces(acres, 2));
    // $(".impervious-acres div").text("Calculating...");
    // $(".building-footprint div").text("Calculating...");
    setData("propertyAcres", acres);
  };

  var processAreaSelection = function (updates) {
    const drawMethod = DrawController.getSelectedMethod();
    if (drawMethod === "drawing-tools" && DrawController.hasDrawnChanges()) {
      updates.drawMethod = drawMethod;
      const polygons = DrawController.getDrawnDrainageGeom();
      if (polygons && polygons.length > 0) {
        updates.drawnGeom = polygons;
      } else {
        updates.drawnGeom = null;
      }
    }
  };

  return {
    showPropertyInventoryModal,
    getFormKey,
    getAllData,
    renderPageContent,
    getExistingPropertyData,
    getLatestData,
    setData,
    unsetData,
    setModalTitle,
    loadExistingPropertyMarker,
    updateAcres,
    getModalMessage,
  };
};

module.exports = PropertyInventoryModalController();
const InventoryModal = require("../../general/inventoryModal");
const Tree = require("../../tree");
const Form = require("../../general/form");
const PageController = require("../pageController");
const MessageModal = require("../../modals/messageModal");
const EsgApiCalls = require("../esgApiCalls");
const ModalMap = require("../../mapFunctions/modalMap");
const NunjucksFilters = require("../../general/nunjucksFilters");
const PropertiesIcon = require("../icons/propertiesIcon");
const DrawController = require("../../mapFunctions/drawController");
const EsgSettings = require("../esgSettings");
const AreaSelectionMap = require("../../mapFunctions/areaSelectionMap");
