"use strict";

const MapFunctions = function () {
  const fcsType = "fcs";
  const bmpType = "bmps";
  // To do: find a way to share constants between SASS and JS
  const minWidthDesktop = 992;
  const minWidthTablet = 720;
  const bottomMapScaleHeight = 40;
  const surroundingBlockSpacer = 10;
  var autoPanPaddingTopLeft;
  var autoPanPaddingBottomRight;

  var getDeviceByCurrentScreenSize = function () {
    const screenWidth = window.screen.availWidth;
    if (screenWidth > minWidthDesktop) {
      return "desktop";
    } else if (screenWidth > minWidthTablet) {
      return "tablet";
    } else {
      return "mobile";
    }
  };

  var dataViewsAddBuffer = function (vw1) {
    const toolArray = ["indcom", "bmpram", "construction"];
    const tool = Tree.get("tool");
    if (["muni", "lid"].includes(tool)) {
      return handleMuniBuffer(vw1);
    } else if (toolArray.includes(tool)) {
      return handleIndcomBuffer(vw1);
    } else {
      return 0;
    }
  };

  var handleMuniBuffer = function (vw1) {
    if (vw1 <= 7.68) {
      return 120;
    } else if (vw1 > 7.68 && vw1 <= 9.8) {
      return 95;
    } else if (vw1 > 9.8) {
      return 80;
    }
  };

  var handleIndcomBuffer = function (vw1) {
    if (vw1 <= 7.68) {
      return 70;
    } else if (vw1 > 7.68 && vw1 <= 9.8) {
      return 145;
    } else if (vw1 > 9.8) {
      return 140;
    }
  };

  var getMainMapAutoPanPadding = function () {
    const vw1 = $(window).width() / 100;
    const legendWidth =
      $("#main-legend .map-layers").outerWidth() +
      $("#main-legend .map-legend:visible").outerWidth() +
      vw1;
    const tableWidth =
      $("#bottomFloatingInputsTable").outerWidth() +
      $("#todo-map-table-container .todo-map-table").outerWidth();
    const navToggleHeight = $("#navbar-toggle").outerHeight() + surroundingBlockSpacer;

    const autoPanPadding = {
      desktop: {
        top: addBlockSpacer(surroundingBlockSpacer + dataViewsAddBuffer(vw1)),
        right: addBlockSpacer(legendWidth),
        bottom: addBlockSpacer(bottomMapScaleHeight),
        left: addBlockSpacer(tableWidth),
      },
      tablet: {
        top: addBlockSpacer(surroundingBlockSpacer + dataViewsAddBuffer(vw1)),
        right: addBlockSpacer(legendWidth),
        bottom: addBlockSpacer(bottomMapScaleHeight),
        left: addBlockSpacer(surroundingBlockSpacer),
      },
      mobile: {
        top: addBlockSpacer(navToggleHeight + dataViewsAddBuffer(vw1)),
        right: addBlockSpacer(surroundingBlockSpacer),
        bottom: addBlockSpacer(bottomMapScaleHeight),
        left: addBlockSpacer(surroundingBlockSpacer),
      },
    };

    return autoPanPadding[getDeviceByCurrentScreenSize()];
  };

  var getModalMapAutoPanPadding = function () {
    const legendWidth = $(".modal-map-legend-control").parent().outerWidth();
    const tableWidth = $(".modal-map-floating-table:visible").outerWidth();
    const floatingButtonWidth = $("#modal-legend-group > div").outerWidth();
    const messageBoxHeight = $(".setLocationMessageBox:visible").outerHeight();

    const autoPanPadding = {
      desktop: {
        top: addBlockSpacer(messageBoxHeight),
        right: addBlockSpacer(legendWidth),
        bottom: addBlockSpacer(bottomMapScaleHeight),
        left: addBlockSpacer(tableWidth),
      },
      tablet: {
        top: addBlockSpacer(messageBoxHeight),
        right: addBlockSpacer(legendWidth),
        bottom: addBlockSpacer(bottomMapScaleHeight),
        left: addBlockSpacer(tableWidth),
      },
      mobile: {
        top: addBlockSpacer(messageBoxHeight),
        right: addBlockSpacer(floatingButtonWidth),
        bottom: addBlockSpacer(bottomMapScaleHeight),
        left: addBlockSpacer(0),
      },
    };

    return autoPanPadding[getDeviceByCurrentScreenSize()];
  };

  var addBlockSpacer = function (number) {
    const autoPanPadding = number ?? 0;
    return autoPanPadding + surroundingBlockSpacer;
  };

  var getAutoPanPadding = function () {
    initAutoPanPadding();
    if (ModalMap.isModalMapVisible()) {
      return getModalMapAutoPanPadding();
    }

    return getMainMapAutoPanPadding();
  };

  var updateAutoPanPadding = function () {
    const autoPanPadding = getAutoPanPadding();
    autoPanPaddingTopLeft.x = autoPanPadding.left;
    autoPanPaddingTopLeft.y = autoPanPadding.top;
    autoPanPaddingBottomRight.x = autoPanPadding.right;
    autoPanPaddingBottomRight.y = autoPanPadding.bottom;
  };

  var initAutoPanPadding = function () {
    if (autoPanPaddingTopLeft && autoPanPaddingBottomRight) {
      return;
    }

    autoPanPaddingTopLeft = L.point(0, 0);
    autoPanPaddingBottomRight = L.point(0, 0);
    updateAutoPanPadding();

    $(window).on("resize", updateAutoPanPadding);
    Tree.watch({
      activeTab: ["activeTab"],
      navToggleSelection: ["navToggleSelection"],
      legendShowing: ["legendShowing"],
      modalLegendShowing: ["modalLegendShowing"],
    }).on("update", updateAutoPanPadding);
  };

  var getPopupOptions = function () {
    initAutoPanPadding();

    return {
      autoPanPaddingTopLeft,
      autoPanPaddingBottomRight,
    };
  };

  var rePanMapForPopup = function ($popup = $(".leaflet-popup").first()) {
    if ($popup) {
      const popupOffsetTop = $popup.offset().top;
      const padding = 100;
      if (popupOffsetTop < padding) {
        const map = MainMap.getMap();
        const mapOffsetY = Math.min(0, popupOffsetTop - padding);
        map.panBy([0, mapOffsetY]);
      }
    }
  };

  var getActive = function () {
    const bmpActive = Tree.get(["layers", "bmps", "isEnabled"]);
    const fcsActive = Tree.get(["layers", "fcs", "isEnabled"]);

    if (bmpActive === fcsActive) {
      return Tree.get("tool") === "bmpram";
    }

    return bmpActive ? bmpType : fcsType;
  };

  var bmpIsActive = function () {
    return getActive() === bmpType;
  };

  var addBasemap = function (map, basemap, style) {
    if (basemap) {
      map.removeLayer(basemap);
    }

    if (style === "Hybrid") {
      var imagery = L.esri.basemapLayer("Imagery", {
        detectRetina: true,
        maxNativeZoom: 19,
      });
      var imageryTransportation = L.esri.basemapLayer("ImageryTransportation");
      var imageryLabels = L.esri.basemapLayer("ImageryLabels");
      basemap = L.featureGroup([imagery, imageryTransportation, imageryLabels]);

      if (basemap._layers) {
        for (const key in basemap._layers) {
          const basemapLayer = basemap._layers[key];
          resetBasemapOptions(basemapLayer);
        }
      }
    } else {
      basemap = L.esri.basemapLayer(style, {
        detectRetina: true,
        maxNativeZoom: 19,
      });
    }

    resetBasemapOptions(basemap);

    return basemap.addTo(map);
  };

  var addVectorBasemap = function (map, basemap, style) {
    style = cleanVectorStyle(style);
    const apiKey = ToolSettings.getSetting("esri", "authkey");
    if (basemap) {
      map.removeLayer(basemap);
    }
    basemap = L.esri.Vector.vectorBasemapLayer(`ArcGIS:${style}`, {
      apiKey: apiKey,
    });
    return basemap.addTo(map);
  };

  var cleanVectorStyle = function (style) {
    if (style === "Gray") {
      style = "LightGray";
    } else if (style === "Imagery") {
      style = "Imagery:Standard";
    } else if (style === "Hybrid") {
      style = "Imagery";
    }
    return style;
  };

  var resetBasemapOptions = function (basemap) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const maxZoom = rasterBasemap ? 19 : 21;

    basemap.options.maxNativeZoom = 19;
    basemap.options.maxZoom = maxZoom;
    basemap.options.zoomOffset = 0;
    basemap.options.tileSize = 256;
  };

  var addDataLayer = function (map, mapLayers, data, layerType) {
    let mapLayer = mapLayers[`${layerType}Layer`];
    const geoJson = CleanData.cleanGeoJson(data);
    if (mapLayer) {
      map.removeLayer(mapLayer);
    }

    mapLayer = new Layers.DataLayer(geoJson, {
      layerType: layerType,
      enableHighlights: true,
    });

    mapLayer.on("popupopen", function (e) {
      if (e?.layer?.feature && layerType === "catchments") {
        const feature = e.layer.feature;
        CatchmentPopup.onPopupOpen(feature.properties, e);
      }
    });

    if (Object.keys(mapLayer._layers).length) {
      const bounds = mapLayer.getBounds();
      Tree.set("bbox", bounds.toBBoxString());
    }

    mapLayer.addTo(map);
    return mapLayer;
  };

  var addModalCatchLayer = function (map, catchmentLayer, data) {
    var geoJson = CleanData.cleanGeoJson(data);
    if (catchmentLayer) {
      map.removeLayer(catchmentLayer);
    }
    catchmentLayer = new Layers.ModalCatchmentLayer(geoJson);
    // if (Object.keys(catchmentLayer._layers).length) {
    //   map.fitBounds(catchmentLayer.getBounds());
    // }
    return catchmentLayer.addTo(map);
  };

  var addBmpLayer = function (map, bmpsLayer, data, mapId, callback) {
    var geoJson = CleanData.cleanGeoJson(data);
    var showBmpFcsPopup = getShowBmpFcsPopup(mapId);
    var showProjectBmpPopup = getShowProjectBmpPopup(mapId);

    if (bmpsLayer) {
      map.removeLayer(bmpsLayer);
    }
    bmpsLayer = L.featureGroup();
    L.geoJson(geoJson, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;
        const markerOpacity =
          mapId === "modal"
            ? getModalMapMarkerOpacity(properties.idBmp, Tree.get(["asset", "basicInfo", "idBmp"]))
            : 1;
        const marker = L.marker(latlng, {
          icon: showProjectBmpPopup
            ? ProjectBmpPopup.createIcon(properties, map.getZoom())
            : createBmpIcon(properties, map.getZoom()),
          opacity: markerOpacity,
        });

        storeBmpMarker(map, feature.properties.idBmp, marker);
        return marker;
      },
      onEachFeature: function (feature, layer) {
        if (showBmpFcsPopup) {
          setBmpLayerPopup(map, layer, feature);
        } else if (showProjectBmpPopup) {
          ProjectBmpPopup.setProjectBmpLayerPopup(map, layer, feature);
        }
      },
    }).addTo(bmpsLayer);

    if (showProjectBmpPopup) {
      map.on("zoomend", function () {
        resizeLayerMarkers(bmpsLayer, ProjectBmpPopup.createIcon, map.getZoom());
      });
    }

    if (bmpsLayer && showBmpFcsPopup) {
      loadBmpPopupListeners(bmpsLayer);
    } else if (bmpsLayer && showProjectBmpPopup) {
      ProjectBmpPopup.loadPopupListeners(bmpsLayer);
    }

    if (map) {
      return bmpsLayer.addTo(map);
    } else {
      return bmpsLayer;
    }
  };

  var createBmpIcon = function (properties, zoom) {
    return BmpFcsIcon.getIconMarker(
      properties.bmpMapScore,
      BmpFcsFunctions.isCentralizedBMPType(properties.bmp_kind),
      properties.bmp_type_abbr,
      properties.fcs,
      properties.bmpAssessmentDue,
      properties.phase,
      null,
      Tree.get("activeTab") === "todo",
      zoom,
    );
  };

  var loadBmpPopupListeners = function (bmpsLayer) {
    bmpsLayer.on("popupopen", function (e) {
      if (e.layer && e.layer.feature) {
        StructuralBmpsPopupGeoServerLayer.onPopupOpen(e.layer.feature.properties);
      }

      const highlightSubject = Tree.get(["todos", "bmp", "highlightSubject"]);
      highlightTodoSection(".popup-bmp", highlightSubject);
      toggleLayerInFront(e.layer, true);
    });

    bmpsLayer.on("popupclose", function (e) {
      toggleLayerInFront(e.layer, false);
      Layers.clearHighlightDrainage("bmps", "fullDrainageBmp");
      Table.removeMapHoverHighlight();
    });
  };

  var setBmpLayerPopup = function (map, layer, feature) {
    layer.bindPopup(() => _getBmpFcsPopupHtml(feature.properties, "bmp"), getPopupOptions());
  };

  var _onFcsPopupOpen = async function (e) {
    const id = e.layer.feature.properties.id;
    const data = await getFcsPopupData(id);

    const html = _getBmpFcsPopupHtml(data, "fcs");
    e.popup.setContent(html);

    PopupPhotos.load("bmp", id); // Note: FCS photos are treated the same as BMP photos
    PopupFiles.loadFilenamesAndRender(id);
  };

  var getFcsPopupData = async function (id) {
    const popupData = await ApiCalls.getFcsPopup(id);

    Tree.set("bmpFcsAssessmentHistory", popupData.history);

    Tree.set("currentBmpFcs", popupData);

    popupData.userPermissions = UserPermissions.getAllPermissions();

    return popupData;
  };

  var _getBmpFcsPopupHtml = function (bmp, layerName) {
    const year = Tree.get("waterYear");
    bmp.id = bmp.idBmp;
    const markerData = LegacyBmpFcs.prepareBmpMarkerData(bmp, layerName, year);
    const popupProps = LegacyBmpFcs.getPopupRenderingProps(markerData, layerName, year);

    MuniCatchBasinPopup.addInspectionPermissions(bmp, popupProps);

    const markerDataAndPopupProps = $.extend({}, markerData, popupProps);
    return renderPopupWithData(markerDataAndPopupProps);
  };

  var renderPopupWithData = function (markerDataAndPopupProps) {
    const view = Tree.get("dataView");

    if (view === "bmp") {
      return nunjucks.render("popups/bmpfcs.njk", markerDataAndPopupProps);
    } else if (view === "fcs" || view === "va") {
      return nunjucks.render("popups/fcsPopup.njk", markerDataAndPopupProps);
    } else {
      throw new Error(`No popup defined for data view ${view}.`);
    }
  };

  var getFcsLayer = function (fcsLayer, data, map, mapId) {
    var geoJson = CleanData.cleanGeoJson(data);
    var showBmpFcsPopup = getShowBmpFcsPopup(mapId);

    fcsLayer = L.featureGroup();
    L.geoJson(geoJson, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;
        const markerOpacity =
          mapId === "modal"
            ? getModalMapMarkerOpacity(properties.idBmp, Tree.get(["asset", "basicInfo", "idBmp"]))
            : 1;
        const marker = L.marker(latlng, {
          icon: BmpFcsIcon.getIconMarker(
            properties.score,
            BmpFcsFunctions.isCentralizedBMPType(properties.bmp_kind),
            properties.bmp_type_abbr,
            properties.fcs,
            properties.fcsAssessmentDue,
            properties.phase,
          ),
          opacity: markerOpacity,
        });

        marker.on("popupclose", function () {
          Layers.clearHighlightDrainage("fcs", "fullDrainageFcs");
        });

        storeFcsMarker(map, feature.properties.idBmp, marker);
        return marker;
      },
      onEachFeature: function (feature, layer) {
        if (showBmpFcsPopup) {
          setFcsLayerPopup(map, layer, feature);
        }
      },
    }).addTo(fcsLayer);

    const fcsDataViews = ["fcs"];
    if (fcsDataViews.includes(Tree.get("dataView"))) {
      map.on("zoomend", function () {
        resizeLayerMarkers(fcsLayer, TrashIconFunctions.createTrashFcsIcon, map.getZoom());
      });
    }

    return fcsLayer;
  };

  var addFcsLayer = function (map, fcsLayer, data, mapId, callback) {
    var showBmpFcsPopup = getShowBmpFcsPopup(mapId);

    if (fcsLayer) {
      map.removeLayer(fcsLayer);
    }
    fcsLayer = getFcsLayer(fcsLayer, data, map, mapId);

    if (fcsLayer && showBmpFcsPopup) {
      loadFcsPopupListeners(fcsLayer);
    }

    if (map) {
      return fcsLayer.addTo(map);
    } else {
      return fcsLayer;
    }
  };

  var setFcsLayerPopup = function (map, layer) {
    layer.bindPopup(
      (layer) => _getBmpFcsPopupHtml(layer.feature.properties, "fcs"),
      getPopupOptions(),
    );
  };

  var loadFcsPopupListeners = function (fcsLayer) {
    fcsLayer.on("popupopen", function (e) {
      if (e.layer && e.layer.feature) {
        _onFcsPopupOpen(e);
      }

      const highlightSubject = Tree.get(["todos", "fcs", "highlightSubject"]);
      highlightTodoSection(".popup-bmp", highlightSubject);
      toggleLayerInFront(e.layer, true);
    });

    fcsLayer.on("popupclose", function (e) {
      toggleLayerInFront(e.layer, false);
      Table.removeMapHoverHighlight();
    });
  };

  var storeFcsMarker = function (map, id, marker) {
    if (map === MainMap.getMap()) {
      LayerFunctions.storeFcsMarker(id, marker);
    }
  };

  var storeBmpMarker = function (map, id, marker) {
    if (map === MainMap.getMap()) {
      LayerFunctions.storeBmpMarker(id, marker);
    }
  };

  var getShowBmpFcsPopup = function (mapId) {
    const tool = Tree.get("tool");
    return mapId !== "modal" && (tool === "trashram" || tool === "bmpram");
  };

  var getShowProjectBmpPopup = function (mapId) {
    var tool = Tree.get("tool");
    return mapId !== "modal" && tool === "lid";
  };

  var addProjectLayer = function (map, projectLayer, data) {
    if (projectLayer) {
      map.removeLayer(projectLayer);
    }
    projectLayer = new Layers.ProjectLayer();
    projectLayer.addData(data).addTo(map);
    return projectLayer;
  };

  var addRankingLayer = function (map, rankingLayer, data) {
    if (rankingLayer) {
      map.removeLayer(rankingLayer);
    }
    rankingLayer = L.geoJSON(data, {
      onEachFeature: function (feature, layer) {
        layer.bindPopup(nunjucks.render("popups/catchments-data.njk", feature.properties));
      },
      style: MapStyles.styleRankingCatchments,
    }).addTo(map);

    return rankingLayer;
  };

  var addStreamLayer = function (map, mapLayers, data, mapId) {
    if (mapLayers.rwLayer) {
      map.removeLayer(mapLayers.rwLayer);
    }
    const rwLayer = new Layers.WatershedLayer();
    rwLayer.addData(data);
    startRWZoomListener(map, mapLayers, mapId);
    return rwLayer.addTo(map);
  };

  var addBoundsLayer = function (map, boundsLayer, data) {
    if (boundsLayer) {
      map.removeLayer(boundsLayer);
    }
    boundsLayer = Layers.getBoundsLayer(data);

    if ($("#map-panel").hasClass("active") && !Session.isMobileParentView()) {
      const firstBoundary = boundsLayer.getLayers()[0];
      const bounds = firstBoundary.getBounds();
      map.fitBounds(bounds);
      Tree.set("initFitBounds", false);
      Tree.set("bbox", bounds.toBBoxString());
    }

    if (Tree.get("initFitBounds") === undefined) {
      Tree.set("initFitBounds", true);
    }

    return boundsLayer.addTo(map);
  };

  var startRWZoomListener = function (currentMap, mapLayers, mapId) {
    currentMap.on("zoomend", function () {
      handleRWDisplay(currentMap, mapLayers, mapId);
    });
  };

  var handleRWDisplay = function (currentMap, mapLayers, mapId) {
    var isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);
    const streamIsEnabled = Tree.get(["layers", "streams", isEnabledPath]);

    if (!isMuniScaleZoom(currentMap.getZoom())) {
      if (streamIsEnabled) {
        currentMap.removeLayer(mapLayers.rwLayer);
      }
    } else {
      if (mapHasLayer(currentMap, mapLayers.rwLayer)) {
        currentMap.addLayer(mapLayers.rwLayer);
      }
    }
  };

  var addParcelTileLayer = function (map, tileLayer, data) {
    if (tileLayer) {
      map.removeLayer(tileLayer);
    }
    tileLayer = new Layers.ParcelGridLayer(data);
    return tileLayer.addTo(map);
  };

  var addRunoffConditionLayer = function (map, runoffConditionLayer, data) {
    if (runoffConditionLayer) {
      map.removeLayer(runoffConditionLayer);
    }

    if (SpatialViewController.isCatchmentOrDrainageView()) {
      runoffConditionLayer = new Layers.ParcelPolygonViewLayer(data);
      runoffConditionLayer.on("popupopen", function (e) {
        if (e.layer && e.layer.feature) {
          Tree.set("currentParcelData", e.layer.feature.properties);
          ParcelAssessmentHistory.load(e.layer.feature.properties);
        }
      });
      runoffConditionLayer.on("popupclose", function (e) {
        Tree.set("currentParcelData", null);
      });
    } else {
      runoffConditionLayer = new Layers.ParcelGridLayer(data);
    }
    return runoffConditionLayer.addTo(map);
  };

  var addDrainageLayer = function (map, drainageLayer, data) {
    if (drainageLayer) {
      map.removeLayer(drainageLayer);
    }
    drainageLayer = new Layers.DrainageLayer(data);
    return drainageLayer;
  };

  var addFullDrainageLayer = function (map, fullDrainageLayer, data, layer) {
    if (fullDrainageLayer) {
      map.removeLayer(fullDrainageLayer);
    }

    if (layer === "bmp") {
      fullDrainageLayer = new Layers.FullDrainageBmpLayer(data);
    } else {
      fullDrainageLayer = new Layers.FullDrainageFcsLayer(data);
    }
    return fullDrainageLayer;
  };

  var displayFullDrainageLayer = function (map, mapLayers, layer) {
    if (layer === "bmp") {
      if (mapLayers.fullDrainageBmpLayer) mapLayers.fullDrainageBmpLayer.addTo(map);
    } else {
      if (mapLayers.fullDrainageFcsLayer) mapLayers.fullDrainageFcsLayer.addTo(map);
    }
  };

  var addLidProjectAreaLayer = function (map, lidProjectAreaLayer, data) {
    if (lidProjectAreaLayer) {
      map.removeLayer(lidProjectAreaLayer);
    }

    lidProjectAreaLayer = new Layers.LidProjectAreaLayer();
    lidProjectAreaLayer.addData(data);
    return lidProjectAreaLayer.addTo(map);
  };

  var addConstructionProjectAreaLayer = function (map, constructionProjectAreaLayer, data) {
    if (constructionProjectAreaLayer) {
      map.removeLayer(constructionProjectAreaLayer);
    }

    constructionProjectAreaLayer = new Layers.ConstructionProjectAreaLayer();
    constructionProjectAreaLayer.addData(data);
    return constructionProjectAreaLayer.addTo(map);
  };

  var whenLayerDataUpdated = function (key, mapId, callback) {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);

    Tree.select("layers", key, dataPath).on("update", function () {
      const cursor = Tree.get("layers", key);
      if (cursor[isEnabledPath] && !cursor["isFetching"]) {
        callback(cursor[dataPath]);
      }
    });
  };

  var openPopupById = function (id, waitForMarkerToFetch = false, layerName = null) {
    const dataView = Tree.get("dataView");

    Mobile.collapseAll("hide");

    if (Tree.get("navToggleSelection") !== "map") {
      NavToggle.showPanel("map");
    }

    if (!layerName) {
      const defaultLayer = DataViewFunctions.getCurrentDataViewProperty("defaultLayers", dataView);
      layerName = defaultLayer?.[0];
    }
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const openedById = true;
    if (isGisDataView) {
      return openGeoServerPopup(layerName, id, openedById);
    } else if (dataView === "lid-runoff" && SpatialViewController.isCatchmentOrDrainageView()) {
      return MainMap.handlePolygonTableSelect(id);
    } else {
      const marker = LayerFunctions.getLayerMarkerById(id);
      return openPopupByMarker(marker, waitForMarkerToFetch, id);
    }
  };

  var openGeoServerPopup = async function (layerName, id, openedById = false) {
    const map = MainMap.getMap();
    const data = LayerDataFunctions.getLayerDataAndIndexById(layerName, id, "main").data;
    let latlng = await _getPopupLatLng(data?.id, layerName);

    if (!data?.geometry && !latlng) {
      console.warn(
        `Unable to open popup for layer ${layerName} ID ${id}. It does not have geometry.`,
      );
      return;
    }

    latlng = latlng || getLatLngOnGeoJson(data.geometry);
    GeoServerLayerFunctions.openGeoServerPopup(map, layerName, data, latlng, openedById);
  };

  var _getPopupLatLng = async function (id, layerName) {
    const popupModule = GeoServerLayerFunctions.getGeoServerLayerProperty(layerName, "popupModule");
    const getPopupLatLng = popupModule?.getPopupLatLng;
    if (getPopupLatLng && id) {
      return await getPopupLatLng(id);
    }
  };

  var getLatLngOnGeoJson = function (geoJson) {
    const geomLayer = L.geoJSON(geoJson).getLayers()[0];

    if (geomLayer instanceof L.Polyline) {
      return getNestedLatLng(geomLayer.getLatLngs());
    } else if (geomLayer instanceof L.Marker) {
      return geomLayer.getLatLng();
    }

    throw new Error(`Unknown geo JSON ${geoJson}.`);
  };

  var getNestedLatLng = function (latLng) {
    while (!(latLng instanceof L.LatLng)) {
      latLng = latLng[0];
    }
    return latLng;
  };

  var openPopupByMarker = function (marker, waitForMarkerToFetch = false, markerId = null) {
    if (waitForMarkerToFetch) {
      let counter = 1;
      const interval = setInterval(function () {
        if (
          counter >= 10 ||
          ($(".popup-bmp").is(":visible") && $(".popup-bmp").data("idBmp") == markerId)
        ) {
          clearInterval(interval);
        } else {
          counter++;
          _openMarkerPopup(marker);
        }
      }, 500);
    } else {
      setTimeout(function () {
        _openMarkerPopup(marker);
      }, 0);
    }
  };

  var _openMarkerPopup = function (marker) {
    if (Clustering.clusteringIsEnabled()) {
      Clustering.disableClustering();
    }
    if (!marker) return;
    if (marker.getPopup() === undefined) {
      marker.fireEvent("click");
      return;
    }
    const isMarkerPopupAlreadyOpen = marker.getPopup().isOpen();
    if (!isMarkerPopupAlreadyOpen) {
      marker.openPopup();
      Tree.get("dataView") === "construction-project-delivery" ? marker.fireEvent("click") : null;
    }
  };

  var highlightTodoSection = function (popupSelector, todoSubject) {
    if (!todoSubject) {
      $(popupSelector).find(".section-highlight").removeClass("section-highlight");
      return;
    }

    const popupHighlightSelector = ToDoConfig.getToDoSubjectConfig([
      todoSubject,
      "popupHighlightSelector",
    ]);
    $(popupSelector).find(popupHighlightSelector).addClass("section-highlight");

    clearTodoHighlightSubject();
  };

  var clearTodoHighlightSubject = function () {
    var dataView = Tree.get("dataView");
    Tree.set(["todos", dataView, "highlightSubject"], undefined);
  };

  var addTrashLinesLayer = function (map, trashLineLayer, data) {
    if (trashLineLayer) {
      map.removeLayer(trashLineLayer);
    }
    trashLineLayer = new Layers.TrashLineLayer();
    trashLineLayer.addData(data);
    trashLineLayer.on("popupopen", function (e) {
      if (e.layer && e.layer.feature) {
        PopupPhotos.load(
          e.layer.feature.properties.source.toLowerCase().replace(/\s/g, ""),
          e.layer.feature.properties.id,
        );
        PopupFiles.loadFilenamesAndRender(e.layer.feature.properties.idBmp);
      }
    });

    return trashLineLayer.addTo(map);
  };

  var addCollectorLineOutlineLayer = function (map, mapLayers, data) {
    if (mapLayers.collectorLineOutlineLayer) {
      map.removeLayer(mapLayers.collectorLineOutlineLayer);
    }

    mapLayers.collectorLineOutlineLayer = new Layers.CollectorLineOutlineLayer();
    mapLayers.collectorLineOutlineLayer.addData(data);
    mapLayers.collectorLineOutlineLayer.addTo(map).bringToBack();

    return mapLayers.collectorLineOutlineLayer;
  };

  var addTrashPointsLayer = function (map, trashPointLayer, data) {
    if (trashPointLayer) {
      map.removeLayer(trashPointLayer);
    }
    trashPointLayer = new Layers.TrashPointLayer();
    trashPointLayer.addData(data);
    trashPointLayer.on("popupopen", function (e) {
      if (e.layer && e.layer.feature) {
        PopupPhotos.load(
          e.layer.feature.properties.source.toLowerCase().replace(/\s/g, ""),
          e.layer.feature.properties.id,
        );
        PopupFiles.loadFilenamesAndRender(e.layer.feature.properties.idBmp);
      }
    });
    return trashPointLayer.addTo(map);
  };

  var whenLayerToggled = function (key, mapId, callback) {
    var isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);

    Tree.select("layers", key, isEnabledPath).off("update");
    Tree.select("layers", key, isEnabledPath).on("update", function (e) {
      if (mapId === "modal") {
        const isEnabledModal = Tree.get("layers", key, "isEnabledModal");
        callback(isEnabledModal, null);
      } else {
        copyCurrentSelectionToToggledLayer(key, callback);
      }
    });
  };

  var copyCurrentSelectionToToggledLayer = function (key, callback) {
    const layerIsEnabled = Tree.get("layers", key, "isEnabled");
    const globalSelected = getGlobalSelected();
    const globalSelectedType = getGlobalSelectedType();
    const globalSearchString = Tree.get(["filters", "searchString"]);
    const layerSelected = Tree.get(["layers", key, "selected"]);
    const layerSelectedType = Tree.get(["layers", key, "selectedType"]);
    const localSearchString = Tree.get(["layers", key, "searchString"]);
    const isCatchmentViewData = SpatialViewController.isDataOfCatchmentOrDrainageView(key);
    const sameSpatialFilter =
      globalSelected == layerSelected &&
      globalSelectedType == layerSelectedType &&
      globalSearchString == localSearchString &&
      !isCatchmentViewData;
    callback(layerIsEnabled, sameSpatialFilter);
    setSelectedTree(key, globalSelected, globalSelectedType, globalSearchString);
  };

  var setSelectedTree = function (key, globalSelected, globalSelectedType, globalSearchString) {
    Tree.set(["layers", key, "selected"], globalSelected);
    Tree.set(["layers", key, "selectedType"], globalSelectedType);
    Tree.set(["layers", key, "searchString"], globalSearchString);
  };

  var getGlobalSelected = function () {
    return Tree.get(["selected", "spatialGrouping"])
      ? Tree.get(["selected", "spatialGrouping"])
      : Tree.get(["selected", "watershed"])
        ? Tree.get(["selected", "watershed"])
        : Tree.get(["selected", "receivingWater"])
          ? Tree.get(["selected", "receivingWater"])
          : Tree.get(["selected", "catchments"])
            ? Tree.get(["selected", "catchments"])[0]
            : "";
  };

  var getGlobalSelectedType = function () {
    return Tree.get(["selected", "spatialGrouping"])
      ? "spatial-grouping-header"
      : Tree.get(["selected", "watershed"])
        ? "watershed-header"
        : Tree.get(["selected", "receivingWater"])
          ? "header"
          : Tree.get(["selected", "catchments"])
            ? "catchment"
            : "";
  };

  var hideStormdrains = function (map, mapLayers) {
    if (mapLayers.stormdrainLayer || mapLayers.middleStormdrainLayer) {
      map.removeLayer(mapLayers.stormdrainLayer);
      map.removeLayer(mapLayers.middleStormdrainLayer);
    }
    if (mapLayers.baseStormdrainLayer) {
      map.removeLayer(mapLayers.baseStormdrainLayer);
    }
  };

  var removeFcsLayer = function (map, mapLayers) {
    const currentTool = Tree.get("tool");
    if (mapHasLayer(map, mapLayers.fcsLayer)) map.removeLayer(mapLayers.fcsLayer);
    if (mapLayers.fullDrainageLayer && currentTool == "trashram")
      map.removeLayer(mapLayers.fullDrainageLayer);
  };

  var removeFullDrainageBmp = function (map, mapLayers) {
    Layers.highlightDrainage("bmps", "fullDrainageBmp");

    if (mapHasLayer(map, mapLayers.fullDrainageBmpLayer)) {
      map.removeLayer(mapLayers.fullDrainageBmpLayer);
    }
  };

  var removeFullDrainageFcs = function (map, mapLayers) {
    Layers.highlightDrainage("fcs", "fullDrainageFcs");

    if (mapHasLayer(map, mapLayers.fullDrainageFcsLayer)) {
      map.removeLayer(mapLayers.fullDrainageFcsLayer);
    }
  };

  var displayTrashLines = function (map, mapLayers) {
    if (mapLayers.trashLineLayer) {
      map.addLayer(mapLayers.collectorLineOutlineLayer);
      map.addLayer(mapLayers.trashLineLayer);
    }
    if (mapLayers.trashPointLayer) map.addLayer(mapLayers.trashPointLayer);
    if (mapLayers.surveyLayer) map.addLayer(mapLayers.surveyLayer);
  };

  var removeTrashLines = function (map, mapLayers) {
    if (mapLayers.trashLineLayer) map.removeLayer(mapLayers.trashLineLayer);
    if (mapLayers.collectorLineOutlineLayer) map.removeLayer(mapLayers.collectorLineOutlineLayer);
    if (mapLayers.trashPointLayer) map.removeLayer(mapLayers.trashPointLayer);
    if (mapLayers.surveyLayer) map.removeLayer(mapLayers.surveyLayer);
  };

  var getMapOptions = function () {
    return {
      popupOptions: {
        className: "popup-general",
        minWidth: 200,
        maxWidth: 500,
        autoPan: false,
      },
    };
  };

  var setMapHeight = function () {
    const $inputsTable = $("#bottomFloatingInputsTable");
    const $todoMapTable = $("#todo-map-table-container");
    const $floatingLegend = $("#main-legend-group");
    const $floatingInputs = $(".floating-inputs");
    const $mainContent = $(".main-content");
    const topOfPageToContentBottomHeight = $mainContent.height() + $mainContent.offset().top;

    setLegendHeight();

    $inputsTable.css("height", topOfPageToContentBottomHeight - $inputsTable.offset().top);
    $todoMapTable.css("height", topOfPageToContentBottomHeight - $todoMapTable.offset().top);

    if (!$floatingLegend.length) return;
    $floatingLegend.css("height", topOfPageToContentBottomHeight - $floatingLegend.offset().top);
    $floatingInputs.css(
      "max-height",
      topOfPageToContentBottomHeight - $floatingInputs.offset().top,
    );

    MainMap.getMap()?.invalidateSize();
  };

  var setLegendHeight = function () {
    var $legend = $("#mapLegend");
    var $layers = $(".map-layers").first();
    var $layersBody = $(".layers-body").first();
    var hiddenByJs = $layersBody.css("visibility") === "hidden";

    if (hiddenByJs) {
      $layersBody.show();
    }
    $legend.css("height", $layers.height());
    if (hiddenByJs) {
      $layersBody.hide();
    }
  };

  var toggleLayerInFront = function (marker, isInFront) {
    if (marker.setZIndexOffset) {
      marker.setZIndexOffset(isInFront ? Number.MAX_SAFE_INTEGER : 0);
    }
  };

  var getSpatialTitle = function () {
    const spatialGrouping = Tree.get(["selected", "spatialGrouping"]);
    const receivingWater = Tree.get(["selected", "receivingWater"]);
    const watershed = Tree.get(["selected", "watershed"]);
    const catchment = Tree.get(["selected", "catchments"]);
    const urbanDrainage = Tree.get(["selected", "urbanDrainage"]);
    const rwDrainsTo = Tree.get(["selected", "rwDrainsTo"]);

    const groupText =
      (spatialGrouping && spatialGrouping.length) > 0
        ? spatialGrouping
        : (watershed && watershed.length) > 0
          ? watershed
          : (receivingWater && receivingWater.length) > 0
            ? receivingWater
            : (urbanDrainage && urbanDrainage.length) > 0
              ? urbanDrainage
              : (catchment && catchment.length) > 0
                ? catchment
                : Tree.get(["activeGroup", "groupId"]) === -1
                  ? Tree.get(["user", "name"])
                  : Tree.get(["activeGroup", "name"]);

    const drainsToText =
      rwDrainsTo && groupText !== Tree.get(["activeGroup", "group"])
        ? " Drains to " + rwDrainsTo
        : "";
    return groupText + drainsToText;
  };

  var getModalMapMarkerOpacity = function (currentLayerId, existingAssetId, defaultOpacity = 1) {
    // Hide existing inventory marker to avoid double markers
    if (existingAssetId && currentLayerId === existingAssetId) {
      return 0;
    }
    return defaultOpacity;
  };

  var addMapZoomControl = function (map) {
    L.control.scale({ position: "bottomright", metric: false }).addTo(map);

    if (Tree.get("tool") !== "esg") {
      LocationSelectionMap.addGeosearch(map);
    }

    L.control.locate({ position: "bottomright" }).addTo(map);

    const mapDomId = map._container?.id;
    const ruler = L.control
      .ruler({
        position: "bottomright",
        events: {
          onToggle: (isActive) => {
            handleRulerToggled(mapDomId, isActive);
          },
        },
      })
      .addTo(map);
    map.ruler = ruler;

    $(`#${mapDomId}`).off("click", ".leaflet-ruler-clicked");
    $(`#${mapDomId}`).on("click", ".leaflet-ruler-clicked", () => {
      closeRuler(ruler);
    });

    L.control.zoom({ position: "bottomright" }).addTo(map);
  };

  var closeRuler = function (ruler) {
    if (ruler?.isActive() && ruler?._clickedLatLong) {
      ruler._closePath();
      ruler._toggleMeasure();
    }
  };

  var handleRulerToggled = function (mapDomId, isActive) {
    if (mapDomId) {
      if (isActive) {
        Tree.set(["leafletRulerEnabled", mapDomId], true);
        $(`#${mapDomId}`).addClass("non-interactive");
      } else {
        Tree.set(["leafletRulerEnabled", mapDomId], false);
        $(`#${mapDomId}.non-interactive`).removeClass("non-interactive");
      }
    }
  };

  var isMuniScaleZoom = function (zoomLevel) {
    return zoomLevel > 12;
  };

  var createStaticMap = function (selector, { basemapType = "Imagery" } = {}) {
    const map = L.map(selector, {
      attributionControl: false,
      zoomControl: false,
      zoom: 3,
      zoomSnap: 0,
      minZoom: 3,
      maxZoom: 18,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      tap: false,
      touchZoom: false,
      dragging: false,
      keyboard: false,
    });
    L.esri.basemapLayer(basemapType, { detectRetina: true }).addTo(map);

    return map;
  };

  var createStaticMapWithPolygon = function (selector, { basemapType = "Imagery", geom } = {}) {
    const map = createStaticMap(selector, { basemapType, geom });

    if (!geom) return map;

    const polygon = L.geoJSON(geom, { style: MapStyles.stylePropertyPolygon }).addTo(map);
    map.fitBounds(polygon.getBounds().pad(0.1));

    return map;
  };

  var createStaticMapWithPoint = function (selector, { basemapType = "Imagery", geom, icon } = {}) {
    const map = createStaticMap(selector, { basemapType, geom });

    const [longitude, latitude] = geom.coordinates;
    L.marker([latitude, longitude], { icon }).addTo(map);
    map.setView([latitude, longitude], 16);

    return map;
  };

  var resizeIconsOnZoom = function (
    zoomLevel,
    largePx = 40,
    mediumPx = 20,
    largeIconTextCss,
    mediumIconTextCss,
    customResizeValues = null,
    customAnchorValues = null,
    isBmpIcon = false,
    ratioForBmpIcon = 1,
    customCssClassName = null,
    smallPx = 6,
  ) {
    const adjustedZoom = zoomLevel < 12 ? 12 : zoomLevel;
    const largeGroupZoom = zoomLevel < 8 ? 8 : zoomLevel;
    const showText = zoomLevel > 12;
    const iconSizes = [
      smallPx, // zoom 12, map 1m
      mediumPx, // zoom 13, map 5k || 3k
      mediumPx, // zoom 14, map 2k
      largePx, // zoom 15, map 1k
      largePx, // zoom 16, map 5h
      largePx, // zoom 17, map 3h
      largePx, // zoom 18, map 1h
      largePx, // zoom 19, map 50ft
      largePx, // zoom 20, map 30ft
      largePx, // zoom 21, map 10ft
    ];
    let size;
    let anchor;
    const largeGroup = Session.isLargeGroup();
    const largeGroupCustomResize = customResizeValues?.length === 12;
    if (customResizeValues) {
      size = getSizeFromCustomResizeValues(
        largeGroupCustomResize,
        customResizeValues,
        largeGroupZoom,
        adjustedZoom,
      );
    } else {
      size = iconSizes[adjustedZoom - 12];
    }

    if (customAnchorValues?.length === 8) {
      anchor = customAnchorValues[adjustedZoom - 12];
    } else if (customAnchorValues?.length === 12) {
      anchor = customAnchorValues[largeGroupZoom - 8];
    } else {
      anchor = customResizeValues
        ? customResizeValues[adjustedZoom - 12]
        : iconSizes[adjustedZoom - 12];
    }
    const textCSSClass = size === largePx ? largeIconTextCss : mediumIconTextCss;
    const sizeArray = isBmpIcon ? [size, size / ratioForBmpIcon] : [size, size];
    const cssClass = _getCssClass(isBmpIcon, customCssClassName, adjustedZoom, textCSSClass);
    const zoomClass = _addIconZoomClass(zoomLevel, largeGroup);

    return {
      size: sizeArray,
      showText: showText,
      textCSSClass: cssClass,
      anchor: [anchor, anchor],
      zoomClass: zoomClass,
    };
  };

  var _getCssClass = function (isBmpIcon, customCssClassName, adjustedZoom, textCSSClass) {
    let cssClass;
    if (isBmpIcon || customCssClassName) {
      cssClass = customCssClassName[adjustedZoom - 12];
    } else {
      cssClass = textCSSClass;
    }
    if (!cssClass && customCssClassName) {
      cssClass = customCssClassName[customCssClassName.length - 1];
    }
    return cssClass;
  };

  var getSizeFromCustomResizeValues = function (
    largeGroupCustomResize,
    customResizeValues,
    largeGroupZoom,
    adjustedZoom,
  ) {
    let size = largeGroupCustomResize
      ? customResizeValues[largeGroupZoom - 8]
      : customResizeValues[adjustedZoom - 12];
    if (!size) {
      size = customResizeValues[customResizeValues.length - 1];
    }
    return size;
  };

  var _addIconZoomClass = function (zoom, isLargeGroup) {
    const zoomLevel = zoom ? zoom : Tree.get("zoomLevel");
    let iconSizeClass;
    if (zoomLevel >= 15) {
      isLargeGroup ? (iconSizeClass = "zoom-15-lrg-group") : (iconSizeClass = "zoom-close");
    } else if (zoomLevel === 14) {
      isLargeGroup ? (iconSizeClass = "zoom-14-lrg-group") : (iconSizeClass = "zoom-far");
    } else if (zoomLevel === 13) {
      isLargeGroup ? (iconSizeClass = "zoom-13-lrg-group") : (iconSizeClass = "zoom-more-far");
    } else if (zoomLevel === 12) {
      isLargeGroup ? (iconSizeClass = "zoom-12-lrg-group") : (iconSizeClass = "zoom-very-far");
    } else if (zoomLevel === 11) {
      isLargeGroup ? (iconSizeClass = "zoom-11-lrg-group") : (iconSizeClass = "zoom-very-far");
    } else if (zoomLevel === 10) {
      isLargeGroup ? (iconSizeClass = "zoom-10-lrg-group") : (iconSizeClass = "zoom-very-far");
    }

    return iconSizeClass;
  };

  var resizeLayerMarkers = function (activeLayer, createIcon, zoom) {
    activeLayer?.eachLayer(function (layer) {
      if (layer._layers) {
        //Handles Non-cluster Layer
        layer.eachLayer(function (marker) {
          if (marker instanceof L.Marker) {
            marker.setIcon(createIcon(marker?.feature?.properties, zoom));
          }
        });
        return;
      }
      //Handles Cluster Layer
      if (layer instanceof L.Marker) {
        layer.setIcon(createIcon(layer?.feature?.properties, zoom));
      }
    });
  };

  var getUsaView = function () {
    return {
      center: [37.8, -96],
      zoomLevel: 4,
    };
  };

  var mapHasLayer = function (map, mapLayer) {
    const hasLayer = mapLayer && map.hasLayer(mapLayer) ? true : false;
    return hasLayer;
  };

  return {
    addBasemap,
    addVectorBasemap,
    addDataLayer,
    addModalCatchLayer,
    addBmpLayer,
    addFcsLayer,
    addParcelTileLayer,
    addRunoffConditionLayer,
    addProjectLayer,
    addRankingLayer,
    addStreamLayer,
    addBoundsLayer,
    whenLayerDataUpdated,
    addTrashLinesLayer,
    addTrashPointsLayer,
    addCollectorLineOutlineLayer,
    addDrainageLayer,
    addFullDrainageLayer,
    addLidProjectAreaLayer,
    addConstructionProjectAreaLayer,
    openPopupById,
    openPopupByMarker,
    whenLayerToggled,
    hideStormdrains,
    removeFcsLayer,
    removeFullDrainageBmp,
    removeFullDrainageFcs,
    resizeIconsOnZoom,
    resizeLayerMarkers,
    displayTrashLines,
    removeTrashLines,
    getMapOptions,
    setMapHeight,
    getSpatialTitle,
    getPopupOptions,
    highlightTodoSection,
    fcsType,
    bmpType,
    bmpIsActive,
    toggleLayerInFront,
    displayFullDrainageLayer,
    getModalMapMarkerOpacity,
    addMapZoomControl,
    updateAutoPanPadding,
    handleRWDisplay,
    isMuniScaleZoom,
    _getBmpFcsPopupHtml,
    renderPopupWithData,
    openGeoServerPopup,
    _getPopupLatLng,
    createStaticMap,
    createStaticMapWithPolygon,
    createStaticMapWithPoint,
    getUsaView,
    mapHasLayer,
    closeRuler,
    rePanMapForPopup,
  };
};

module.exports = MapFunctions();

const Actions = require("../actions");
const ApiCalls = require("../apiCalls");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const CleanData = require("./cleanData");
const Clustering = require("./clustering");
const DataViewFunctions = require("../dataViewFunctions");
const GeoServerLayerFunctions = require("./geoServerLayerFunctions");
const LayerDataFunctions = require("../general/layerDataFunctions");
const LayerFunctions = require("../layerFunctions");
const Layers = require("./layers");
const LegacyBmpFcs = require("../bmpfcs/legacyBmpFcs");
const MainMap = require("./mainMap");
const MapStyles = require("./mapStyles");
const Mobile = require("../mobile");
const ModalMap = require("./modalMap");
const NavToggle = require("./navToggle");
const ParcelAssessmentHistory = require("../lid/parcelAssessmentHistory");
const PopupFiles = require("./popupFiles");
const PopupPhotos = require("./popupPhotos");
const ProjectBmpPopup = require("../lid/projectBmpPopup");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const SpatialViewController = require("./spatialViewController");
const Table = require("./table");
const ToDoConfig = require("../config/toDoConfig");
const Tree = require("../tree");
const CatchmentPopup = require("../catchments/catchmentPopup");
const MuniCatchBasinPopup = require("../muni/muniCatchBasinPopup");
const ToolSettings = require("../settings/toolSettings");
const TrashIconFunctions = require("../trash/trashIconFunctions.js");
const StructuralBmpsPopupGeoServerLayer = require("../bmpFcsG2/structuralBmpsPopupGeoServerLayer");
const FeatureFlag = require("../settings/featureFlag");
const LocationSelectionMap = require("./locationSelectionMap");
