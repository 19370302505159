"use strict";

const GroupedFormOptions = function () {
  const byGroup = new Map();
  const byFormIndex = [];

  function add(groupBy, value, displayName = null) {
    let datum = byGroup.get(groupBy);

    if (!datum) {
      datum = {
        values: new Set(),
        displayName: displayName ?? groupBy,
      };
      byGroup.set(groupBy, datum);
      byFormIndex.push(datum);
    }

    datum.values.add(value);
  }

  function has(formIndex, value) {
    const datum = byFormIndex[formIndex];

    if (!datum) return false;

    return datum.values.has(value);
  }

  function getFormOptions() {
    return byFormIndex.map(function (datum, i) {
      return { value: i, name: datum.displayName };
    });
  }

  return {
    add,
    has,
    getFormOptions,
  };
};

module.exports = GroupedFormOptions;
