"use strict";

function ResourceController() {
  var resourcesByKey;

  async function init(loggedInAsDifferentUser) {
    assertNotInited();
    createResources();

    if (loggedInAsDifferentUser) {
      await deleteAll();
    }

    await initResources();
  }

  function createResources() {
    const resourceConfig = ResourceConfig.getResources();
    resourcesByKey = {};

    for (const config of resourceConfig) {
      const model = new config.resource(config.key, config.options);
      resourcesByKey[config.key] = model;
    }
  }

  async function initResources() {
    const initPromises = [];

    for (const key in resourcesByKey) {
      initPromises.push(resourcesByKey[key].init());
    }

    await Promise.all(initPromises);
  }

  function assertNotInited() {
    if (resourcesByKey) {
      throw new Error(`ResourceController can only be initialized once.`);
    }
  }

  function getAll() {
    return resourcesByKey;
  }

  function get(stringKey) {
    const resource = resourcesByKey[stringKey];

    if (resource === undefined) {
      throw new Error(`No resource configured for ${stringKey}.`);
    }

    return resource;
  }

  async function sync() {
    for (const key in resourcesByKey) {
      const resource = resourcesByKey[key];
      await resource.sync();
    }
  }

  async function deleteAll() {
    const deletePromises = [];

    for (const key in resourcesByKey) {
      const db = new Database(key);

      deletePromises.push(db.deleteDatabase());
    }

    await Promise.all(deletePromises);
  }

  // For testing only
  async function _reset() {
    resourcesByKey = undefined;
  }

  // For simulating database failures
  function _closeAll() {
    for (const key in resourcesByKey) {
      resourcesByKey[key]._getDb().close();
    }
  }

  return { init, getAll, get, sync, _reset, _closeAll };
}

module.exports = ResourceController();

const Database = require("./database");
const ResourceConfig = require("../config/resourceConfig");
