/*
  The base SelectTable does not work with LazyList because it stores data in the HTML
  Instead LazySelectTable stores the `selected` property directly on the data

  Requires table rows to render with:
  * A `data-id` property corresponding to the data's `id`
  * The `selected` class when data has the `selected` property
*/
class LazySelectTable extends require("./selectTable") {
  dataByIdMap;

  constructor(dataByIdMap, $table, { onSelect = null } = {}) {
    super($table, { onSelect });

    this.setData(dataByIdMap);
  }

  toggleAllRows(toggle) {
    for (const datum of this.dataByIdMap.values()) {
      datum.selected = toggle;
    }

    for (const input of this.getAllInputs()) {
      this._toggleSelectedRow($(input), toggle);
    }

    this.updateTable();
  }

  _updateRow($input) {
    const id = $input.closest("tr").data("id");
    const isChecked = $input.prop("checked");

    this.dataByIdMap.get(id).selected = isChecked;
    this._toggleSelectedRow($input, isChecked);

    this.updateTable();
  }

  updateTable() {
    let someChecked = false;
    let someUnchecked = false;

    for (const datum of this.dataByIdMap.values()) {
      if (datum.selected) {
        someChecked = true;
      } else {
        someUnchecked = true;
      }
    }

    this._setToggleAllInput(someChecked, someUnchecked);
    this._setDisabledClasses(someChecked);
  }

  setData(dataByIdMap) {
    if (Array.isArray(dataByIdMap)) {
      this.dataByIdMap = new Map();
      for (const datum of dataByIdMap) {
        this.dataByIdMap.set(datum.id, datum);
      }
    } else {
      this.dataByIdMap = dataByIdMap;
    }
  }

  getAllSelectedRows() {
    throw new Error("Not implemented for LazySelectTable");
  }
}

module.exports = LazySelectTable;
