"use strict";

// https://web.dev/customize-install/
function InstallManager() {
  async function installServiceWorker() {
    if (!serviceWorkersSupported()) {
      return;
    }

    let registration;
    try {
      registration = await navigator.serviceWorker.register("/service-worker.js");
    } catch (e) {
      if (Config.get().environment === "local") {
        console.info(
          "Unable to install service worker, please run `npm run gulp offline` to build with offline enabled.",
        );
        return;
      } else if (e.name === "SecurityError") {
        // Catches FireFox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1413615
        window.alert(
          "This web browser is not supported. Please update your device and use the latest version of Google Chrome.",
        );
        return;
      } else {
        throw e;
      }
    }
    _replaceLegacyWorkerOnceInstalled(registration);
  }

  function serviceWorkersSupported() {
    return "serviceWorker" in navigator;
  }

  async function serviceWorkerReady() {
    const activeWorker = navigator.serviceWorker.controller;
    const indexCache = await caches.open("index.html");
    const cachedIndexes = await indexCache.keys("/ram/index.html");

    return activeWorker !== null && cachedIndexes.length >= 1;
  }

  // Used to remove the old service worker at `\ram\service-worker.js`
  async function _replaceLegacyWorkerOnceInstalled(newRegistration) {
    const currentRegistration = await navigator.serviceWorker.ready;

    if (currentRegistration.scope === newRegistration.scope) {
      return;
    }

    const result = await currentRegistration.unregister();
    console.log(
      `Unregistered service worker with result ${result} at scope: ${currentRegistration.scope}`,
    );
  }

  return { installServiceWorker, serviceWorkerReady, _replaceLegacyWorkerOnceInstalled };
}

module.exports = InstallManager();

const Config = require("../config");
