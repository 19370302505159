"use strict";

const BmpLayer = function () {
  const layerName = "bmps";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadTabListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersListenerForMap(map, mapLayers, mapId);
    if (Tree.get("tool") === "bmpram") {
      loadConditionPhaseToggleUpdatedListener(map, mapLayers);
    }
  };

  var loadTabListenerForMap = function (map, mapLayers, mapId) {
    Tree.select("activeTab").on("update", function (e) {
      const activeTab = e.data.currentData;
      const dataView = Tree.get("dataView");

      if (dataView === "bmp") {
        addRemoveLayerByTab(activeTab, map, mapLayers, "structuralBmps");
      }
    });
  };

  var addRemoveLayerByTab = function (activeTab, map, mapLayers, g2LayerName) {
    const mapLayerName = `${g2LayerName}Layer`;

    if (activeTab === "todo") {
      if (MapFunctions.mapHasLayer(map, mapLayers[mapLayerName])) {
        map.removeLayer(mapLayers[mapLayerName]);
      }
    } else if (activeTab === "data") {
      if (MapFunctions.mapHasLayer(map, mapLayers.bmpsLayer)) {
        map.removeLayer(mapLayers.bmpsLayer);
      }
      if (Tree.get("layers", g2LayerName, "data")) {
        if (
          !MapFunctions.mapHasLayer(map, mapLayers[mapLayerName]) &&
          mapLayers[mapLayerName] !== null
        ) {
          map.addLayer(mapLayers[mapLayerName]);
        }
      }
    }
  };

  var loadFiltersListenerForMap = function (map, mapLayers, mapId) {
    Tree.select("filters").on("update", function (e) {
      if (MapFunctions.mapHasLayer(map, mapLayers.bmpsLayer) && Tree.get("reloadFilter")) {
        loadLayer(mapId);
      }
    });
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    startZoomListener(map, mapLayers);

    MapFunctions.whenLayerToggled(layerName, mapId, function (bmpsIsEnabled, sameSpatialFilter) {
      if (bmpsIsEnabled) {
        if (MapFunctions.mapHasLayer(map, mapLayers.fcsLayer)) map.removeLayer(mapLayers.fcsLayer);
        if (mapLayers.bmpsLayer && sameSpatialFilter) {
          map.addLayer(mapLayers.bmpsLayer);
        } else {
          loadLayer(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.bmpsLayer)) {
          map.removeLayer(mapLayers.bmpsLayer);
        }
      }
      if (mapId === "main") {
        DisplayOptions.handleConditionPhaseToggleDisable(layerName, bmpsIsEnabled);
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(layerName, mapId, function () {
      if (MapFunctions.mapHasLayer(map, mapLayers.bmpsLayer)) {
        map.removeLayer(mapLayers.bmpsLayer);
      }
      if (MapFunctions.mapHasLayer(map, mapLayers.fcsLayer)) {
        map.removeLayer(mapLayers.fcsLayer);
      }
      LayerFunctions.filterToDoLayer(layerName, mapLayers, map, mapId);
    });
  };

  var startZoomListener = function (map, mapLayers) {
    map.on("zoomend", function () {
      if (Tree.get("activeTab") === "todo") {
        MapFunctions.resizeLayerMarkers(mapLayers["bmpsLayer"], createIcon, map.getZoom());
      }
    });
  };

  var createIcon = function (properties, zoom) {
    return BmpFcsIcon.getIconMarker(
      properties.bmpScore,
      BmpFcsFunctions.isCentralizedBMPType(properties.bmp_kind),
      properties.bmp_type_abbr,
      properties.fcs,
      properties.bmpAssessmentDue,
      properties.phase,
      null,
      Tree.get("activeTab") === "todo",
      zoom,
    );
  };

  var loadLayer = function (mapId, loadingScreen = true) {
    var waterYear = Tree.get("waterYear");
    var filters = Actions.getFiltersByMapId(mapId);
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var onlyAccepted = Session.isRegulator();
    var filtersOn = Actions.getFiltersOn("bmpram");
    var tool = Tree.get("tool");

    if (["lid", "trashram"].includes(tool)) {
      const defaultBmpramFilters = FilterConstants.getDefaultFiltersByDataView("bmp");
      filters = Object.assign({}, filters, defaultBmpramFilters);
      filtersOn = true;
    }

    Tree.set(["layers", layerName, "isFetching"], true);
    ApiCalls.getAllBmps(
      waterYear,
      filters,
      filtersOn,
      onlyAccepted,
      function (data) {
        Tree.set(["layers", layerName, "isFetching"], false);
        Tree.set(["layers", layerName, dataPath], data);

        if (mapId !== "modal") {
          TableDataFunctions.niceSort(data, "bmpName");
          Actions.updateBmpCount();
        }
      },
      loadingScreen,
    );
  };

  var loadConditionPhaseToggleUpdatedListener = function (map, mapLayers) {
    Tree.select(["filters", "conditionPhaseToggle"]).on("update", function (e) {
      const toggle = e.data.currentData;
      cleanUpAreaLayerOnConditionPhaseToggleChange(map, mapLayers);

      if (toggle === "condition") {
        Filters.disableNonConditionPhases(["completed", "certified"]);
      } else {
        Filters.enableDisabledPhases();
      }
      handleLegendDisplayByConditionPhaseToggle(toggle);
      DisplayOptions.validateConditionPhaseToggleRadioButtons(toggle);
    });
  };

  var handleLegendDisplayByConditionPhaseToggle = function (toggle) {
    const layerNeedFilters = Tree.get("layers", "fullDrainageBmp", "data")
      ? "fullDrainageBmp"
      : "structuralBmps";

    const currentLayer = Tree.get("dataView") === "bmp" ? "structuralBmps" : layerName;
    DisplayOptions.handleLegendDisplayByConditionPhaseToggle(
      currentLayer,
      toggle,
      layerNeedFilters,
    );
  };

  var cleanUpAreaLayerOnConditionPhaseToggleChange = function (map, mapLayers) {
    if (!MapFunctions.mapHasLayer(map, mapLayers.fullDrainageBmpLayer)) {
      mapLayers.fullDrainageBmpLayer = null;
    }
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    createIcon,
    handleLegendDisplayByConditionPhaseToggle,
  };
};

module.exports = BmpLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const TableDataFunctions = require("../general/tableDataFunctions");
const Session = require("../login/session");
const LayerFunctions = require("../layerFunctions");
const Actions = require("../actions");
const FilterConstants = require("../filterConstants");
const Filters = require("../mapFunctions/filters");
const DisplayOptions = require("../mapFunctions/displayOptions");
const BmpFcsIcon = require("./bmpFcsIcon");
const BmpFcsFunctions = require("./bmpFcsFunctions");
