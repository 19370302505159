"use strict";

const StormwaterImpactAccountingController = function () {
  var render = async function () {
    const options = {
      breadcrumbs: {
        currentPage: "Stormwater Impact Accounting",
      },
      preparedDataFunction: _getPreparedData,
      afterRenderFunction: _afterRenderFunction,
    };

    await PageController.render(
      "impact-accounting",
      "esg/dashboards/stormwaterImpactAccounting.njk",
      () => EsgApiCalls.getProgramImpact(),
      options,
    );
    FilterSummary.disable();
  };

  const _getPreparedData = function (data) {
    return {
      ...data,
      logos: GroupLogos.get(),
    };
  };

  const _afterRenderFunction = function () {
    _renderWidgets();
  };

  const _renderWidgets = function () {
    const $container = $(".program-progress");
    const data = PageController.getData();
    const programImpactProgress = new StackedBar({
      title: "Program Impact Progress",
      unit: "Stormwater Managed (M gal/yr)",
      size: 4,
      legendCountPerRow: 8,
      getHoverData: (insightData) => {
        return Object.fromEntries(
          Object.entries(insightData.programImpactProgress).map(([year, data]) => [
            year,
            { title: year, template: "insight/widgets/programImpactBarHover.njk", ...data },
          ]),
        );
      },
      getDataArray: (data) => {
        const currentYear = new Date().getFullYear().toString();
        const dataArray = {
          goal: data.portfolio.stormwaterManagedGoal,
          bars: Object.entries(data.programImpactProgress).map(([year, data]) => {
            return { label: year, hoverKey: year, ...data, focus: year === currentYear };
          }),
        };
        return dataArray;
      },
      legendData: () => {
        return {
          completed: {
            label: "Completed",
            color: "#206A97",
            textColor: "#FFFFFF",
          },
          planning: {
            label: "Planned",
            color: "#46A0D8",
            textColor: "#000000",
          },
          draft: {
            label: "Draft",
            color: "#EEF6FB",
            textColor: "#000000",
          },
        };
      },
    });
    WidgetHandler.render($container, programImpactProgress, data);
  };

  return {
    render,
  };
};

module.exports = StormwaterImpactAccountingController();

const FilterSummary = require("../../filters/filterSummary");
const StackedBar = require("../../general/widgets/stackedBar");
const WidgetHandler = require("../../general/widgets/widgetHandler");
const EsgApiCalls = require("../esgApiCalls");
const GroupLogos = require("../groupLogos");
const PageController = require("../pageController");
