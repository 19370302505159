"use strict";

const DataViewFunctions = function () {
  var getDataViewConfig = function (dataView) {
    const config = DataViewConfig.get()[dataView];

    if (config === undefined) {
      throw Error(`Data view ${dataView} has no configuration.`);
    }
    config.id = dataView;
    return { ...config };
  };

  var getCurrentDataViewProperty = function (property, dataView = Tree.get("dataView")) {
    if (!dataView) {
      throw Error(`Data view is not populated`);
    }

    const config = getDataViewConfig(dataView);
    const propertyValue = config[property];

    return propertyValue;
  };

  var getCurrentDataSorts = function () {
    const currentTab = Tree.get("activeTab");
    var dataSortByTab = getCurrentDataViewProperty("dataSortByTab");

    if (!dataSortByTab || !dataSortByTab[currentTab]) {
      return {};
    }

    const activeDataSorts = dataSortByTab[currentTab];
    const dataSortsWithData = {};
    for (const dataSort of activeDataSorts) {
      dataSortsWithData[dataSort] = DataSortFunctions.getDataSortConfig(dataSort);
    }

    return dataSortsWithData;
  };

  var getCurrentTopLevelDataSorts = function () {
    const topLevelDataSort = getCurrentDataViewProperty("topLevelDataSort") || [];

    if (topLevelDataSort.includes("rw") && Progeny.activeHasProgeny()) {
      const catchments = Tree.get("catchments", "items");
      if (catchments?.length && catchments[0]?.watershed) {
        topLevelDataSort.unshift("watersheds");
      }
    }

    return Object.fromEntries(
      topLevelDataSort.map((dataSort) => [dataSort, DataSortFunctions.getDataSortConfig(dataSort)]),
    );
  };

  var getSpatialGroupingDataSorts = function () {
    const topLevelDataSort = getCurrentDataViewProperty("topLevelDataSort") || [];
    const spatialGroupings = Tree.get("spatialGroupings");
    var spatialGroupingDataSorts = [];

    if (topLevelDataSort.includes("rw") && spatialGroupings) {
      for (const key in spatialGroupings) {
        if (
          ["monitoringDrainages", "assessmentAreas", "jurisdictions"].includes(key) &&
          spatialGroupings[key]?.length
        ) {
          spatialGroupingDataSorts.push(key);
        }
      }
    }

    return Object.fromEntries(
      spatialGroupingDataSorts.map((dataSort) => [
        dataSort,
        DataSortFunctions.getDataSortConfig(dataSort),
      ]),
    );
  };

  var getAvailableDataSorts = function () {
    const topLevelDataSorts = getCurrentTopLevelDataSorts();
    const layersAvailable = Tree.get("availableDataSortLayers") ?? [];
    const availableDataSorts = {};

    Object.keys(topLevelDataSorts).forEach((dataSort) => {
      if (layersAvailable.includes(topLevelDataSorts[dataSort].topLevelLayer)) {
        availableDataSorts[dataSort] = topLevelDataSorts[dataSort];
      }
    });

    return availableDataSorts;
  };

  var getLastUsedDataViewByAllTools = function () {
    return UserResource.getUserData("lastUsedDataViewByTool") ?? {};
  };

  var getLastUsedDataViewByTool = function (tool) {
    const lastUsedDataViewByTool = getLastUsedDataViewByAllTools();
    return lastUsedDataViewByTool[tool] ?? null;
  };

  var setLastUsedDataViewByTool = function (tool, dataView) {
    const lastUsedDataViewByTool = getLastUsedDataViewByAllTools();
    lastUsedDataViewByTool[tool] = dataView;
    UserResource.setUserData("lastUsedDataViewByTool", lastUsedDataViewByTool);
  };

  var getUserEnabledToDoSubjects = function (dataView = Tree.get("dataView")) {
    const ToDoFunctions = require("./mapFunctions/toDoFunctions");

    const toDoSubjects = getDataViewConfig(dataView).toDoSubjects ?? [];
    return toDoSubjects.filter((subject) => ToDoFunctions.canAccessToDoSubject(subject));
  };

  var getUserEnabledTabs = function (dataView = Tree.get("dataView"), listOrMapOnly = null) {
    const config = getDataViewConfig(dataView);
    return getPossibleEnabledTabs(config, listOrMapOnly).filter(function (tab) {
      if (tab === "todo" && getUserEnabledToDoSubjects(dataView).length === 0) {
        return false;
      }

      return true;
    });
  };

  var getPossibleEnabledTabs = function (config, listOrMapOnly) {
    if (listOrMapOnly === "list") {
      return config.listEnabledTabs;
    } else if (listOrMapOnly === "map") {
      return config.mapEnabledTabs;
    }

    return config.listEnabledTabs.union(config.mapEnabledTabs);
  };

  return {
    getDataViewConfig,
    getCurrentDataViewProperty,
    getCurrentDataSorts,
    getCurrentTopLevelDataSorts,
    getAvailableDataSorts,
    getSpatialGroupingDataSorts,
    getLastUsedDataViewByTool,
    setLastUsedDataViewByTool,
    getUserEnabledTabs,
    getUserEnabledToDoSubjects,
  };
};

module.exports = DataViewFunctions();

const DataSortFunctions = require("./dataSortFunctions");
const DataViewConfig = require("./config/dataViewConfig");
const Tree = require("./tree");
const Progeny = require("./login/progeny");
const UserResource = require("./offline/userResource");
