"use strict";

const formatDisplayDateAsTimestamp = (dateString) => {
  //dateString expected is "MM/DD/YYYY" or "MM/DD/YYYY HH:MM:SS"
  dateString = dateString.indexOf(":") > -1 ? dateString : dateString + " 00:00:01";
  const displayDateArray = dateString.split(" ")[0].split("/");
  return (
    [displayDateArray[2], displayDateArray[0], displayDateArray[1]].join("-") +
    " " +
    dateString.split(" ")[1]
  );
};

const formatTimestampStringAsDisplayDate = (timestampDate) => {
  //timestampDate expected is "YYYY-MM-DD HH:MM:SS"
  const dateArray = timestampDate.split(" ")[0].split("-");
  return [dateArray[1], dateArray[2], dateArray[0]].join("/");
};

const formatDueDate = (dateWithTimestamp, inspectFreq) => {
  if (dateWithTimestamp && inspectFreq.val) {
    const dateArray = dateWithTimestamp.split(" ")[0].split("-");
    let month = parseInt(dateArray[1]) + parseInt(inspectFreq.val);
    let year = parseInt(dateArray[0]);
    if (month > 12) {
      year += parseInt(month / 12);
      month %= 12;
    }
    return [month, dateArray[2], year].join("/");
  }
  return;
};

const getTodayDisplayDate = ({ zeroPadded = false } = {}) => {
  var todayTime = new Date();
  return formatDateAsDisplayDate(todayTime, { zeroPadded });
};

const getCurrentWaterYear = () => {
  var dateObj = new Date();
  var month = dateObj.getMonth() + 1; //months from 1-12
  var year = dateObj.getFullYear();
  if (month > 9) {
    //if october 1st next water year has started
    year += 1;
  }
  return year;
};

const formatLocalStringDate = (timeStampTimeZoneString) => {
  const localDate = parseDbDateTime(timeStampTimeZoneString);
  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  };
  return localDate.toLocaleDateString("en-US", options);
};

const formatDateStringAsDisplayDate = (dateString) => {
  const date = parseDbDateTime(dateString);
  return formatDateAsDisplayDate(date);
};

const formatDateAsDisplayDate = (date, { zeroPadded = false } = {}) => {
  const options = { minimumIntegerDigits: zeroPadded ? 2 : 1 };

  const month = (1 + date.getMonth()).toLocaleString(undefined, options);
  const day = date.getDate().toLocaleString(undefined, options);
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

const dateComparator = function (date1Str, date2Str) {
  if (!date1Str) {
    if (!date2Str) {
      return 0;
    }
    return -1;
  }
  if (!date2Str) return 1;
  const date1 = parseDbDateTime(date1Str);
  const date2 = parseDbDateTime(date2Str);
  if (date1 > date2) {
    return 1;
  }
  if (date2 > date1) {
    return -1;
  }
  return 0;
};

const parseDbDateTime = function (dateTimeFromDb) {
  if (typeof dateTimeFromDb !== "string") {
    console.warn("Trying to convert a non-string to a Date object");
    return dateTimeFromDb;
  }

  var date;
  var match = dateTimeFromDb.match(
    /^\s*(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+).?(\d+)?([+\-]\d+)?\s*$/,
  );

  if (match) {
    // Assumed to be in SQL standard yyyy-mm-dd hh:mm:ss
    // Or timestamptz yyyy-mm-dd hh:mm:ss.ss+-tz

    if (match[8] !== undefined) {
      date = new Date(
        Date.UTC(
          match[1],
          parseInt(match[2]) - 1,
          match[3],
          parseInt(match[4]) + parseInt(match[8]),
          match[5],
          parseInt(match[7]) * 10,
        ),
      );
    } else if (match[7] !== undefined) {
      date = new Date(
        match[1],
        parseInt(match[2]) - 1,
        match[3],
        match[4],
        match[5],
        match[6],
        parseInt(match[7]) * 10,
      );
    } else {
      date = new Date(match[1], parseInt(match[2]) - 1, match[3], match[4], match[5], match[6]);
    }
  } else {
    // Assumed to be in https://www.w3.org/TR/NOTE-datetime
    date = new Date(dateTimeFromDb);
  }

  if (isNaN(date)) {
    throw `String could not be converted to Date object: ${dateTimeFromDb}.`;
  }

  return date;
};

var dateEquals = function (date1, date2) {
  if (date1 === null || date1 === undefined || date2 === null || date2 === undefined) {
    return date1 === date2;
  }
  date1 = new Date(date1);
  date2 = new Date(date2);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return date1.getTime() === date2.getTime();
};

var getToday = function () {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return now;
};

var formatIsoString = function (date) {
  const dateObj = parseIsoDateString(date);
  if (dateObj === undefined) {
    return "—";
  }
  return Flatpickr.formatDate(dateObj, "m/d/Y");
};

var getNowIso = function () {
  const today = getToday();
  return toIsoDateString(today);
};

var parseDateToIso = function (date) {
  const dateObj = Flatpickr.parseDate(date, "m/d/Y");
  if (dateObj === undefined) {
    return "—";
  }
  return toIsoDateString(dateObj);
};

var toIsoDateString = function (date) {
  return truncateMilliseconds(Flatpickr.formatDate(date, "Z"));
};

var truncateMilliseconds = function (isoString) {
  if (isoString) {
    return isoString.split(".")[0] + "Z";
  }
  return "";
};

var parseIsoDateString = function (stringDate) {
  return Flatpickr.parseDate(stringDate, "Z");
};

var getDiffrenceInDays = function (date1, date2) {
  const diff = date1 - date2;
  const msToSecons = 1000;
  const secondsToMinutes = 60;
  const minutesToHours = 60;
  const hoursToDays = 24;

  return diff / msToSecons / secondsToMinutes / minutesToHours / hoursToDays;
};

module.exports = {
  formatTimestampStringAsDisplayDate,
  formatDisplayDateAsTimestamp,
  formatDueDate,
  getTodayDisplayDate,
  getCurrentWaterYear,
  formatLocalStringDate,
  dateComparator,
  formatDateStringAsDisplayDate,
  formatDateAsDisplayDate,
  parseDbDateTime,
  dateEquals,
  getToday,
  getNowIso,
  formatIsoString,
  parseDateToIso,
  truncateMilliseconds,
  parseIsoDateString,
  getDiffrenceInDays,
  toIsoDateString,
};

const Flatpickr = require("flatpickr");
