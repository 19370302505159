"use strict";

const ConsoleCommands = function () {
  const breakOffline = function () {
    const ResourceController = require("./offline/resourceController");
    ResourceController._closeAll();
    return "Offline broken.";
  };

  const removeToken = function () {
    const TokenManager = require("./login/tokenManager");
    TokenManager.removeToken();
    return "Token removed.";
  };

  const getCachedObjectStores = async function (resourceName) {
    const ResourceController = require("./offline/resourceController");

    const resource = await ResourceController.get(resourceName);
    return resource._getCachedObjectStores();
  };

  const samlLogin = function (email) {
    const SingleSignOn = require("./login/sso");

    return SingleSignOn.samlLogin(email);
  };

  const importOfflineData = async function () {
    const LoadingScreen = require("./general/loadingScreen");
    const OfflineImporter = require("./offline/offlineImporter");

    LoadingScreen.show();

    const input = document.createElement("input");
    input.type = "file";
    input.click();

    const json = await new Promise((resolve) => {
      input.addEventListener("change", () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsText(file, "UTF-8");
      });
    });

    const result = await OfflineImporter.import(json);
    LoadingScreen.hide();
    return result;
  };

  const openBlob = function (blob) {
    const PreviewDownloader = require("./files/previewDownloader");
    const preview = new PreviewDownloader();
    preview.openBlob(blob);
  };

  return {
    breakOffline,
    removeToken,
    getCachedObjectStores,
    samlLogin,
    importOfflineData,
    openBlob,
  };
};

module.exports = ConsoleCommands();

// `require()` modules inside functions above to avoid circular decencies
