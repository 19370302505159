"use strict";

const ToDoList = function () {
  const initedDataViews = [];
  const expandedSections = new Set();
  const largeViewSections = new Set();
  const rowDataBySectionAndId = {};
  const sortingKeys = {};
  const sortingReversed = {};
  const previewLimit = 4;
  const largeViewLimit = 1000;

  let lastRenderedTodos = null;

  var init = function (todos) {
    if (initedDataViews.includes(Tree.get("dataView"))) return;
    populateExpandedSections(todos);
    initedDataViews.push(Tree.get("dataView"));
  };

  /**
   * @param obj todos {
   *   todoSubject => obj[] todos {
   *     ...
   *   }
   * }
   */
  var render = function (todos) {
    init(todos);
    assertTodosValid(todos);
    lastRenderedTodos = todos;

    const $container = $(".todo-list-container").html("");
    const subjectData = renderToDoSubjects($container, todos);
    renderSorting(subjectData);
    activateOverflowTooltips();
    setToDoFilterButtonDisplay();
    DateTimePicker.initializeDateTimePickers($container, _onDueDateChange);
    updateAllVisibleTableRows();
    loadToDoSubjectListeners(subjectData);
  };

  var renderToDoSubjects = function ($container, todos) {
    const props = getSharedToDoProps();
    const dataView = Tree.get("dataView");
    const subjectData = {};

    for (const subject of DataViewFunctions.getUserEnabledToDoSubjects(dataView)) {
      const config = ToDoConfig.getToDoSubjectConfig([subject]);
      const todoSubject = todos[subject] || todos[config.apiSubjectName] || [];

      const html = nunjucks.render(config.listTemplate, {
        ...props,
        expanded: expandedSections.has(subject) || expandedSections.has(config.apiSubjectName),
        isLargeViewMode:
          largeViewSections.has(subject) || largeViewSections.has(config.apiSubjectName),
        rowDataById: rowDataBySectionAndId[subject] ?? {},
        filterLabels: Tree.get(["toDoFilterLabels", dataView, subject]),
        subjectConfigs: config,
        todos: todoSubject.slice(0, largeViewLimit),
        defaultPath: UrlRoutes.getDefaultPath(),
        customized: ToolSettings.getSetting("group", "customToDoListSubjects").includes(
          config.apiSubjectName,
        ),
      });
      $container.append(html);
      subjectData[subject] = {
        $section: $container.find("> :last-child"),
        config,
        data: todoSubject,
      };
    }

    return subjectData;
  };

  var updateAllVisibleTableRows = function () {
    $(".todo-list-container .toggle-preview-button").each(function (i, button) {
      setVisibleTableRowsByButton($(button));
    });
  };

  var setToDoFilterButtonDisplay = function (
    mainSelector = ".todo-list-container",
    sectionSelector = ".list-section",
  ) {
    var $toDoFilterLabelContainers = $(mainSelector).find(".todo-filter-label-container");
    if ($toDoFilterLabelContainers.length > 0) {
      $toDoFilterLabelContainers.each(function () {
        const filterApplied = $(this).find(".todo-filter-label").length > 0;
        $(this)
          .closest(sectionSelector)
          .find(".todo-filter-button")
          .toggleClass("invert-colors", filterApplied);
      });
    }
  };

  var getConstructionProps = function () {
    if (Tree.get("tool") === "construction") {
      return {
        enforcementContractorMethod: ToolSettings.getSetting(
          "construction",
          "enforcement-contractor-method",
        ),
        requireEnforcementReview: ToolSettings.getSetting(
          "construction",
          "require-enforcement-review",
        ),
        showAssignedToColumn:
          ToolSettings.getSetting("construction", "enforcement-contractor-method") == "2nform" ||
          ToolSettings.getSetting("construction", "require-enforcement-review"),
      };
    }
  };

  var assertTodosValid = function (todos) {
    if (typeof todos !== "object") {
      throw new Error(`todos must be an object with grouping keys, was ${todos}.`);
    }
    for (const subject in todos) {
      if (!Array.isArray(todos[subject])) {
        throw new Error(
          `todos group must be an array of todos, was ${todos[subject]} with subject ${subject}.`,
        );
      }
    }
  };

  var renderSorting = function (subjectData) {
    for (const subject in subjectData) {
      const { $section } = subjectData[subject];
      const sorting = getSorting(subject);
      if (sorting.key) {
        const header = $section.find(`th[data-sort-key="${sorting.key}"]`);
        header.addClass("sorting");
        header.find(".sort-arrow").toggleClass("flipped", !!sorting.reversed);
      }
    }
  };

  var activateOverflowTooltips = function () {
    $(".todo-list-container").find(".overflow-ellipses").tooltipOnOverflow();
  };

  var populateExpandedSections = function (todos) {
    Object.keys(todos).forEach((todoSubject) => {
      if (todos[todoSubject].length > 0) {
        expandedSections.add(todoSubject);
      }
    });
  };

  var renderUpdate = function (prevTodos, currentTodos) {
    Promise.all(
      Object.keys(prevTodos).map((todoSubject) => {
        const deletions = getDeletionSet(prevTodos[todoSubject], currentTodos[todoSubject] || []);
        return applyDeletions(todoSubject, deletions);
      }),
    ).then(() => render(currentTodos));
  };

  var getDeletionSet = function (prevTodos, currentTodos) {
    let prevTodoIds = new Set(prevTodos.map((todo) => todo.idBmp));
    const currTodoIds = new Set(currentTodos.map((todo) => todo.idBmp));
    prevTodoIds = [...prevTodoIds];
    const deletedIds = prevTodoIds.filter((id) => !currTodoIds.has(id));
    return Array.from(new Set(deletedIds));
  };

  var applyDeletions = async function (todoSubject, deletions) {
    applyDeletionsInElement(
      deletions,
      $(".todo-list-container").find(`[data-section-name='${todoSubject}']`),
    );
    const FADE_DURATION_MS = 500; // in CSS too
    return new Promise((resolve) => {
      setTimeout(resolve, FADE_DURATION_MS);
    });
  };

  var applyDeletionsInElement = function (deletedIds, element) {
    deletedIds.map((id) => {
      return element.find(`tr[data-asset-id=${id}]`).getOnlyOrThrow().css("opacity", 0);
    });
  };

  // Please configure a `loadListenersCallback` to do listener instead of adding more here
  var loadDomListeners = function (customListeners = null) {
    $(".todo-list-container").off("click", ".header");
    $(".todo-list-container").on("click", ".header", toggleSectionHeader);

    $(".todo-list-container").off("click", ".toggle-preview-button");
    $(".todo-list-container").on("click", ".toggle-preview-button", togglePreviewButtonClick);

    $(".todo-list-container").off("click", ".todo-filter-button");
    $(".todo-list-container").on("click", ".todo-filter-button", toDoFilterButtonClick);

    $(".todo-list-container").off("click", ".todo-filter-label");
    $(".todo-list-container").on("click", ".todo-filter-label", toDoFilterLabelClick);

    $(".todo-list-container").off("click", ".map-button");
    $(".todo-list-container").on("click", ".map-button", showMarkerOnMap);

    $(".todo-list-container").off("click", ".assessment-button");
    $(".todo-list-container").on("click", ".assessment-button", showAssessmentModal);

    $(".todo-list-container").off("click", ".inventory-button");
    $(".todo-list-container").on("click", ".inventory-button", showInventoryModal);

    $(".todo-list-container").off("click", "th.sortable");
    $(".todo-list-container").on("click", "th.sortable", _sortByClickedColumn);

    $(".todo-list-container").off("click", ".js-reload-defaults");
    $(".todo-list-container").on("click", ".js-reload-defaults", _reloadDefaults);

    for (const listener of customListeners) {
      $(".todo-list-container").off("click", listener.target);
      $(".todo-list-container").on("click", listener.target, listener.callback);
    }
  };

  var _sortByClickedColumn = async function () {
    const sectionName = $(this).parents(".list-section").data("sectionName");
    const key = $(this).data("sortKey");
    const sort = getSorting(sectionName);

    if (sort.key === key) {
      sortingReversed[sectionName] = !sort.reversed;
    } else {
      sortingReversed[sectionName] = false;
    }

    sortingKeys[sectionName] = key;

    await ToDoListController.rerenderToDoList();
  };

  var getSorting = function (sectionName) {
    return {
      key:
        sortingKeys[sectionName] ??
        ToDoConfig.getToDoSubjectConfig([sectionName, "defaultSort"], false),
      reversed: sortingReversed[sectionName] ?? false,
    };
  };

  var show = function () {
    $(".todo-list-container").show();
  };

  var hide = function () {
    $(".todo-list-container").hide();
  };

  var showMarkerOnMap = function () {
    var $tr = $(this).parents("tr");
    var id = $tr.data("id") || $tr.data("projectId");
    var todoSubject = $tr.data("subject");

    if (!todoSubject) {
      throw new Error(`Can't go to map marker with unknown todoSubject ${todoSubject}.`);
    }

    Tree.set(["todos", Tree.get("dataView"), "highlightSubject"], todoSubject);

    MapFunctions.openPopupById(id);
  };

  var showAssessmentModal = function () {
    var data = $(this).parents("tr").data();
    var todoSubject = data.subject;
    var todo = ToDoFunctions.getTodoByIdBmp(data.id);
    Tree.set("currentBmpFcs", todo);
    PopupPhotos.setCurrentId(data.id);

    if (todoSubject === "maintenanceNeeded") {
      showAssessmentModalFromMaintenanceNeededTodo(todo);
    } else if (todoSubject === "assessmentDue") {
      const assessmentType = data.assessmentType;
      showAssessmentModalFromAssessmentDueTodo(todo, assessmentType);
    } else {
      throw new Error(`Can't open assessment modal for todoSubject ${todoSubject}.`);
    }
  };

  var showAssessmentModalFromMaintenanceNeededTodo = function (todo) {
    showBmpFcsAssessmentModalFromTodo(todo);
  };

  var showAssessmentModalFromAssessmentDueTodo = function (todo, assessmentType) {
    if (assessmentType === "bmp" || assessmentType === "fcs") {
      showBmpFcsAssessmentModalFromTodo(todo);
    } else if (assessmentType === "benchmark") {
      showBenchmarkModalFromTodo(todo);
    }
  };

  var showBmpFcsAssessmentModalFromTodo = function (todo) {
    var dataView = Tree.get("dataView");
    if (dataView === "bmp") {
      showBmpObsModalFromTodo(todo);
    } else if (dataView === "fcs") {
      showFcsAssessmentModalFromTodo(todo);
    } else {
      throw new Error(`Can't open assessment modal for asset type ${dataView}.`);
    }
  };

  var showBenchmarkModalFromTodo = function (todo) {
    if (todo.currentBenchmarkId && !todo.currentBenchmarkComplete) {
      Benchmark.showEditBenchmarkModal(
        todo.bmpName,
        todo.idBmp,
        todo.bmp_type,
        todo.bmp_type_key,
        todo.currentBenchmarkId,
        false,
      );
    } else {
      Benchmark.showNewBenchmarkModal(todo.bmpName, todo.bmp_type, todo.bmp_type_key);
    }
  };

  var showBmpObsModalFromTodo = function (todo) {
    BmpObservation.showBmpObsModal(
      todo.idBmp,
      todo.bmpName,
      todo.bmp_type,
      todo.bmp_type_key,
      false,
      0,
      todo.currentBenchmarkId,
    );
  };

  var showFcsAssessmentModalFromTodo = function (todo) {
    BmpFunctions.showFcsAssessmentModal(todo.bmpName, todo.idBmp, todo.bmp_type);
  };

  var showInventoryModal = function () {
    const idBmp = $(this).parents("tr").data("id");
    ToDoListController.openIncompleteInventoryModalForBmp(idBmp);
  };

  var toggleSectionHeader = function (e) {
    if (e.target.tagName === "A" || e.target.tagName === "BUTTON") return;

    const $headerContainer = $(e.currentTarget).closest(".header-container");
    const sectionName = $headerContainer.closest(".list-section").data("sectionName");
    const expandArrow = $headerContainer.find(".expand-arrow");

    if (expandArrow.hasClass("open")) {
      closeExpandButton(expandArrow, sectionName);
      $headerContainer.find(".sub-header").slideUp(300);
      $headerContainer.next().slideUp(300);
    } else {
      openExpandButton(expandArrow, sectionName);
      $headerContainer.find(".sub-header").slideDown(300);
      $headerContainer.next().slideDown(300);
    }
  };

  var openExpandButton = function (expandArrow, sectionName) {
    expandedSections.add(sectionName);
    expandArrow.addClass("open");
  };

  var togglePreviewButtonClick = function () {
    var $button = $(this);
    var $section = $button.parents(".list-section");
    var sectionName = $section.data("sectionName");
    var mode = $button.data("mode");

    if (mode === "preview") {
      $button.data("mode", "large-view").attr("data-mode", "large-view");
      $button.text("...Show Less");
      largeViewSections.add(sectionName);
    } else {
      $button.data("mode", "preview").attr("data-mode", "preview");
      $button.text("...Show More");
      largeViewSections.delete(sectionName);
    }

    setVisibleTableRowsByButton($button);
  };

  var setVisibleTableRowsByButton = function ($button) {
    var $section = $button.parents(".list-section");
    var mode = $button.data("mode");

    if (mode === "large-view" || mode === "hidden") {
      setVisibleTableRows($section);
    } else {
      setVisibleTableRows($section, previewLimit);
    }
  };

  var setVisibleTableRows = function ($section, showHowMany = Number.POSITIVE_INFINITY) {
    $section.find("tbody tr").each(function (i, row) {
      $(row).toggleClass("hide", i >= showHowMany);
    });
  };

  var toDoFilterButtonClick = function (e) {
    var $button = $(this);
    var $section = $button.parents(".list-section");
    var sectionName = $section.data("sectionName");
    ToDoFiltersModal.renderAndShow(sectionName);
  };

  var toDoFilterLabelClick = function (e) {
    if ($(e.target).hasClass("close-button")) {
      const $label = $(this);
      const filterSection = $label.data("filterSection");
      const toDoSection = $label.parents(".list-section").data("sectionName");
      setToDoFilterSectionToDefault(toDoSection, filterSection);
      $label.remove();
    }
    e.stopPropagation();
  };

  var setToDoFilterSectionToDefault = function (toDoSection, filterSection) {
    const dataView = Tree.get("dataView");
    const toDoSectionDefaults = ToDoConfig.getToDoFiltersDataViewConfig([
      dataView,
      "filters",
      toDoSection,
    ]);
    const filterDataType = ToDoFunctions.getFilterDataType(filterSection, dataView);

    if (filterSection === "receivingWater") {
      Tree.set(["toDoFilters", dataView, toDoSection, "receivingWater"], null);
      Tree.set(["toDoFilters", dataView, toDoSection, "catchment"], null);
      Tree.set(["toDoFilters", dataView, toDoSection, "allSpatial"], true);
    } else if (filterSection === "maintenanceZone") {
      Tree.set(["toDoFilters", dataView, toDoSection, "maintenanceZone"], null);
      Tree.set(["toDoFilters", dataView, toDoSection, "allSpatial"], true);
    } else if (filterDataType === "dateRange") {
      Tree.set(["toDoFilters", dataView, toDoSection, `${filterSection}From`], null);
      Tree.set(["toDoFilters", dataView, toDoSection, `${filterSection}To`], null);
    } else if (toDoSectionDefaults[filterSection]) {
      const defaults = toDoSectionDefaults[filterSection];
      Tree.set(["toDoFilters", dataView, toDoSection, filterSection], defaults);
    }
    Tree.unset(["toDoFilterLabels", dataView, toDoSection, filterSection]);
  };

  var closeExpandButton = function (expandArrow, sectionName) {
    if (!expandedSections.has(sectionName)) {
      console.error(`expandedSections missing ${sectionName}`);
    }
    expandedSections.delete(sectionName);
    expandArrow.removeClass("open");
  };

  var getIdFromRow = function ($element) {
    return $element.parents("tr").data("id");
  };

  var _onDueDateChange = async function (selectedDates, dateStr, instance) {
    const $dateInput = $(instance.input);
    const dueDate = DateTimePicker.getIsoDate($dateInput);
    const path = $dateInput.attr("name").split(".");
    const lastRenderedTodo = lastRenderedTodos[path[0]][path[1]];

    if (!dueDate) {
      DateTimePicker.setDateOnElement($dateInput, lastRenderedTodo.dueDateIso);
    } else if (dueDate !== lastRenderedTodo.dueDateIso) {
      await updateDueDate($dateInput, dueDate, lastRenderedTodo);
    }
  };

  var updateDueDate = async function ($dateInput, dueDate, lastRenderedTodo) {
    const $tr = $dateInput.closest("tr");
    const subject = ToDoConfig.getToDoSubjectConfig([$tr.data("subject"), "apiSubjectName"]);
    const todoId = $tr.data("todo-id");

    lastRenderedTodo.dueDateIso = dueDate;
    await ApiCalls.updateTodo(todoId, subject, { dueDate });
    Analytics.sendTodoEvent("edit_todo_due_date", subject);
  };

  // Please add new properties to the configured to do `prepareDataCallback`
  // instead of adding more here.
  var getSharedToDoProps = function () {
    return {
      ...getConstructionProps(),
      previewLimit,
      userRole: UserRoles.getRole(),
      permissions: UserPermissions.getAllPermissions(),
      enablePostActivePhase: ToolSettings.getSetting("construction", "enable-post-active-phase"),
      availableDataSortLayers: Tree.get("availableDataSortLayers"),
      isCdot: Session.isCdot(),
      isFindingsWorkflow: ToolSettings.getSetting("construction", "is-findings-workflow"),
    };
  };

  var loadToDoSubjectListeners = function (subjectData) {
    for (const subject in subjectData) {
      const { config, $section, data } = subjectData[subject];
      initRowDataBySectionAndId(subject, data);

      const selectedTable = new SelectTable($section, {
        onSelect: ($input) => updateCheckedRowData(subject, selectedTable.getAllInputs()),
      });

      config.loadListenersCallback?.($section, { selectedTable });
    }
  };

  var initRowDataBySectionAndId = function (subject, data) {
    if (!rowDataBySectionAndId[subject]) {
      rowDataBySectionAndId[subject] = {};
    }

    for (const row of data) {
      if (!rowDataBySectionAndId[subject][row.id]) {
        rowDataBySectionAndId[subject][row.id] = {};
      }
    }
  };

  var updateCheckedRowData = function (subject, $allInputs) {
    for (const input of $allInputs) {
      const id = $(input).closest("tr").data("id");
      rowDataBySectionAndId[subject][id].checked = input.checked;
    }
  };

  var _reloadDefaults = async function (e) {
    try {
      await MessageModal.showConfirmWarningModalAsPromise(
        `
        <p>You are about to reload 2NFORM To Do defaults. This will replace any custom To Do configuration, including specific tasks and due dates.</p>
        <p>Confirm below or click “Cancel” to return. Defaults may take a few minutes to reload.</p>
      `,
        {
          okMessage: "Auto-Populate Tasks",
          returnMessage: "Cancel",
        },
      );
    } catch (e) {
      return;
    }

    const apiSubject = $(this).data("subject");
    setToDoSubjectCustom(apiSubject, false);
    Analytics.sendAction(["to-do", "defaults", apiSubject]);
    await ApiCalls.setDefaultToDoList(apiSubject);
    MuniCatchBasinGeoServerLayer.invalidateLayerData();
  };

  var setToDoSubjectCustom = function (apiSubject, customized) {
    const customToDoListSubjects = ToolSettings.getSetting("group", "customToDoListSubjects");

    if (customized) {
      if (!customToDoListSubjects.includes(apiSubject)) {
        customToDoListSubjects.push(apiSubject);
      }
    } else {
      customToDoListSubjects.splice(customToDoListSubjects.indexOf(apiSubject), 1);
    }

    $(`[data-subject="${apiSubject}"].disabled-to-do-custom`).toggleClass("disabled", !customized);
  };

  return {
    render,
    renderUpdate,
    loadDomListeners,
    getSorting,
    getIdFromRow,
    hide,
    show,
    setToDoFilterSectionToDefault,
    setToDoFilterButtonDisplay,
    setToDoSubjectCustom,
    _onDueDateChange,
    _sortByClickedColumn,
  };
};

module.exports = ToDoList();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const Benchmark = require("../modals/benchmark");
const BmpFunctions = require("../modals/bmpFunctions");
const BmpObservation = require("../modals/bmpObservation");
const ToolSettings = require("../settings/toolSettings");
const DateTimePicker = require("../general/dateTimePicker");
const MapFunctions = require("./mapFunctions");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const ToDoConfig = require("../config/toDoConfig");
const ToDoFiltersModal = require("./toDoFiltersModal");
const ToDoFunctions = require("../mapFunctions/toDoFunctions");
const ToDoListController = require("./toDoListController");
const Tree = require("../tree");
const UserRoles = require("../login/userRoles");
const DataViewFunctions = require("../dataViewFunctions");
const SelectTable = require("../general/selectTable");
const UrlRoutes = require("../routes/urlRoutes");
const MuniCatchBasinGeoServerLayer = require("../muni/muniCatchBasinGeoServerLayer");
const MessageModal = require("../modals/messageModal");
