"use strict";

var ReportLogin = function () {
  var initializeUser = function (data) {
    $("#usernameSpan").text(data.context.full_name);
    $(".home-link").attr("href", Config.origin);

    Session.initSession(data);
    _setDemoOptions();
    BreadCrumbsDropdown.setDropdown();
    FeatureFlagModal.enableFeatureFlagModal();
  };

  var afterRequired = async function () {
    if (Progeny.activeHasProgeny()) {
      if (!Progeny.rootGroupIsRegulator()) {
        await Actions.loadAnnualReporting();
      }
      const progeny = Progeny.getActiveGroupProgeny();
      AnnualReports.setRegionalYears(progeny);
      RegionDropdown.load(progeny);
      YearDropdown.setYearDropdown();
    } else {
      await await Actions.loadAnnualReporting();
    }
  };

  var toggleManageUsers = function (canManageUsers) {
    if (!canManageUsers) {
      $("#navbar-menu .dropdown.settings").remove();
    }
  };

  var setEsftDownloadUrl = function () {
    $(".navbar-eswft-resources").attr("href", ToolSettings.getSetting("constants", "eswftUrl"));
  };

  var _setDemoOptions = function () {
    if (!Session.isDemoAccount()) {
      return;
    }
  };

  return {
    initializeUser,
    afterRequired,
    toggleManageUsers,
    _setDemoOptions,
    setEsftDownloadUrl,
  };
};

module.exports = ReportLogin();

const Session = require("../../login/session");
const Progeny = require("../../login/progeny");
const Actions = require("../actions");
const AnnualReports = require("../annualReports");
const RegionDropdown = require("../regionDropdown");
const YearDropdown = require("../mapFunctions/yearDropdown");
const Config = require("../config");
const BreadCrumbsDropdown = require("../breadCrumbsDropdown");
const FeatureFlagModal = require("../../settings/featureFlagModal");
const ToolSettings = require("../../settings/toolSettings");
