"use strict";

const MuniCatchBasinFilters = function () {
  const searchFields = ["bmpName", "fullAddress", "facility", "structureId"];
  const searchDbFields = ["bmp_id", "address", "facility", "structure_identifier"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (
    unfilteredData,
    filters,
    loadingScreen,
    smartFilters = mainSmartFilters,
  ) {
    if (onlineFilterIsSet(filters)) {
      try {
        unfilteredData = await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          MuniCatchBasinGeoServerLayer.getLayerData,
          { treePath: smartFilters.getTreePath() },
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters, smartFilters);
  };

  var filterOffline = function (unfilteredData, filters, smartFilters) {
    if (filters.toDoFilters) {
      return unfilteredData.filter((datum) => {
        return ResourceFilters.passToDoFilters(datum, filters, toDoFilters);
      });
    } else {
      return smartFilters.filter(unfilteredData);
    }
  };

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installationDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  var getFilterPhases = function (filters) {
    let phases = filters.phase;

    if (filters.conditionPhaseToggle === "condition") {
      phases = phases.filter((phase) => ["completed", "certified"].includes(phase));
    }

    return phases;
  };

  var passCatchBasinConsequenceOfFailureFilter = function (datum, filters) {
    if (Tree.get("activeTab") === "data") {
      if (filters.consequenceOfFailure?.length !== undefined) {
        return (
          filters.consequenceOfFailure?.includes(datum.cof) ||
          (datum.cof == null && filters.consequenceOfFailure?.includes("null"))
        );
      } else {
        return true;
      }
    } else {
      return ResourceFilters.passUnknownWhiteListFilter(datum.cof, filters.consequenceOfFailure, [
        "very-high",
        "high",
        "moderate",
        "low",
        "very-low",
      ]);
    }
  };

  var passFcsFilter = function (datum, filters) {
    return OfflineFilterFunctions.passYesNoNullFilter(datum, filters, "fcs", "isFcs");
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    }

    return false;
  };

  var isOnlineDateFilterSet = function (filters) {
    return isOnlineDateType(filters.dateType) && (filters.dateFrom || filters.dateTo);
  };

  var addGeoServerViewParams = function (params, filters) {
    ResourceFilters.addDateParam(
      params,
      "routine",
      filters.routineDate?.from,
      filters.routineDate?.to,
    );
    ResourceFilters.addDateParam(
      params,
      "repair",
      filters.repairDate?.from,
      filters.repairDate?.to,
    );
    ResourceFilters.addDateParam(params, "clean", filters.cleanDate?.from, filters.cleanDate?.to);
    GeoServerFilterFunctions.addAdditionalInfoParams(filters, params, "routineRepair");
  };

  var isOnlineDateType = function (dateType) {
    return ["routine", "repair", "clean"].includes(dateType);
  };

  var toDoFilters = function (datum, subjectFilters, subject) {
    return (
      passInProximityTo303dWaterbodyToDo(datum, subjectFilters) &&
      passDueDateFilter(datum, subjectFilters, subject) &&
      passCorrectiveActionFilter(datum, subjectFilters, subject) &&
      passEnforcementLevelFilter(datum, subjectFilters, subject) &&
      passCatchBasinConsequenceOfFailureFilter(datum, subjectFilters)
    );
  };

  var passInProximityTo303dWaterbodyToDo = function (datum, filters) {
    return ResourceFilters.passTrueFalseBooleanFilter(
      datum.inProximityTo303dWaterbody,
      filters.inProximityTo303dWaterbody,
    );
  };

  var passInProximityTo303dWaterbody = function (datum, filters) {
    if (filters.inProximityTo303dWaterbody?.length !== undefined) {
      return (
        (datum.inProximityTo303dWaterbody == null &&
          filters.inProximityTo303dWaterbody?.includes("null")) ||
        filters.inProximityTo303dWaterbody.includes(String(datum.inProximityTo303dWaterbody))
      );
    } else {
      return true;
    }
  };

  var passLastDateFilter = function (datum, filters) {
    const from = filters.lastDate?.from;
    const to = filters.lastDate?.to;

    if (!from && !to) {
      return true;
    }

    return ResourceFilters.passDateFilter(datum.latestCatchBasinDate, from, to);
  };

  var passDatesFilter = function (dates, from, to) {
    if (!from && !to) {
      return true;
    }

    return dates.some((date) => ResourceFilters.passDateFilter(date, from, to));
  };

  var passDueDateFilter = function (datum, filters, subject) {
    return ResourceFilters.passIsoDateFilter(
      datum[subject]?.dueDate,
      filters.dueDateFrom,
      filters.dueDateTo,
    );
  };

  var passCorrectiveActionFilter = function (datum, filters, subject) {
    if (!filters.correctiveAction) {
      return true;
    }

    const correctiveActions = ToDoConfig.getToDoFiltersDataViewConfig([
      "muni-catch-basin",
      "filters",
      subject,
      "correctiveAction",
    ]);

    return datum[subject].additionalInfo.some(function (info) {
      return ResourceFilters.passUnknownWhiteListFilter(
        info.key,
        filters.correctiveAction,
        correctiveActions,
      );
    });
  };

  var passEnforcementLevelFilter = function (datum, filters, subject) {
    if (!filters.enforcementLevel) {
      return true;
    }

    const enforcementLevels = RoutineMaintenanceFormTable.getLevelOptions().map(
      (options) => options.value,
    );

    return datum[subject].additionalInfo.some(function (info) {
      return ResourceFilters.passUnknownWhiteListFilter(
        info.level,
        filters.enforcementLevel,
        enforcementLevels,
      );
    });
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementerType",
      "implementer_type",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "responsiblePartyMaintenance",
      "responsible_party_maintenance",
    );

    if (Tree.get("activeTab") === "data") {
      GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings);
    }

    if (
      (Tree.get("activeTab") === "data" &&
        (!FilterSummary.areAllCheckboxesChecked("correctiveAction") ||
          !FilterSummary.areAllCheckboxesChecked("enforcementLevel")) &&
        filters.correctiveAction?.length !== undefined &&
        filters.correctiveAction.length < 6) ||
      (filters.enforcementLevel?.length !== undefined && filters.enforcementLevel?.length < 6)
    ) {
      if (
        (filters.correctiveAction?.includes("no-action") &&
          filters.correctiveAction?.length === 1) ||
        (filters.enforcementLevel?.includes("null") && filters.enforcementLevel?.length === 1)
      ) {
        queryStrings.push("todo_is_routine_repair = false");
      } else if (
        !filters.correctiveAction?.includes("no-action") ||
        !filters.enforcementLevel?.includes("null")
      ) {
        queryStrings.push("todo_is_routine_repair = true");
      }
    }

    if (filters.phase?.length !== undefined && !FilterSummary.areAllCheckboxesChecked("phase")) {
      const phases = getFilterPhases(filters);
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayQuery(
          "phase",
          phases,
          null,
          Tree.get("activeTab") === "data",
        ),
      );
    }

    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "types", "bmp_type");

    if (
      filters.inProximityTo303dWaterbody?.length !== undefined &&
      !FilterSummary.areAllCheckboxesChecked("inProximityTo303dWaterbody")
    ) {
      queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "inProximityTo303dWaterbody",
        queryStrings,
        "in_proximity_to_303d_waterbody",
        filters.inProximityTo303dWaterbody,
      );
    }
    if (
      filters.condition &&
      Tree.get("activeTab") === "data" &&
      !FilterSummary.areAllCheckboxesChecked("condition")
    ) {
      queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "condition",
        queryStrings,
        null,
        true,
      );
    }

    if (!FilterSummary.areAllCheckboxesChecked("consequenceOfFailure")) {
      addCatchBasinConsequenceOfFailureQuery(queryStrings, filters);
    }

    if (filters.lastDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "latest_catch_basin_date",
        filters.lastDate?.from,
        filters.lastDate?.to,
      );
    }
    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "bmp_creation",
        filters.installDate?.from,
        filters.installDate?.to,
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("fcs")) {
      queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "fcs",
        queryStrings,
      );
    }
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }
  };

  var addCatchBasinConsequenceOfFailureQuery = function (queryStrings, filters) {
    if (filters.consequenceOfFailure?.length !== undefined) {
      if (Tree.get("activeTab") === "data") {
        queryStrings.push(
          GeoServerFilterFunctions.getInArrayQuery(
            "cof_final_text",
            filters.consequenceOfFailure,
            null,
            true,
          ),
        );
      } else {
        queryStrings.push(
          GeoServerFilterFunctions.getInArrayQuery("cof_final_text", filters.consequenceOfFailure, [
            "very-high",
            "high",
            "moderate",
            "low",
            "very-low",
          ]),
        );
      }
    }
  };

  var resetTodoFilter = function () {
    Filters.resetToDoFiltersByDataView("muni-catch-basin");
  };

  var addToDoGeoServerFilterQuery = function (subjectStrings, filters) {
    addCatchBasinConsequenceOfFailureQuery(subjectStrings, filters);

    if (Array.isArray(filters.inProximityTo303dWaterbody)) {
      subjectStrings.push(
        GeoServerFilterFunctions.getInBooleanArrayQuery(
          "in_proximity_to_303d_waterbody",
          filters.inProximityTo303dWaterbody,
        ),
      );
    }
  };

  var getFilterConfigs = function () {
    return {
      implementerType: "implementerType",
      responsiblePartyMaintenance: "responsiblePartyMaintenance",
      types: "bmpType",
      phase: "phase",
      consequenceOfFailure: "cof",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      zones: "zone_names",
      correctiveAction: ["correctiveAction", "array"],
      enforcementLevel: ["enforcementLevel", "array"],
      condition: "condition",
      fcs: passFcsFilter,
      inProximityTo303dWaterbody: passInProximityTo303dWaterbody,
      subGroups: "groupId",
      routineDate: (datum, filters) =>
        passDatesFilter(datum.routine, filters.routineDate?.from, filters.routineDate?.to),
      repairDate: (datum, filters) =>
        passDatesFilter(datum.repair, filters.repairDate?.from, filters.repairDate?.to),
      cleanDate: (datum, filters) =>
        passDatesFilter(datum.cleaned, filters.cleanDate?.from, filters.cleanDate?.to),
      lastDate: passLastDateFilter,
      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passInstalledDateFilter(datum, filters) &&
          ResourceFilters.spatialFilter(
            {
              drains_to_rw: datum.drains_to_rw,
              drains_to_c: datum.drains_to_c,
              catchid: datum.catchment_name,
              maintenanceZoneName: datum.zone_names,
            },
            filters,
          )
        );
      },
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, zoneOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      zoneOptions,
      receivingWaterOptions,
      urbanDrainageOptions,
      catchmentsOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      phaseOptions: ToolSettings.getSetting("filterConstants", "phases"),
      typesOptions: ToolSettings.getSetting("filterConstants", "bmpTypes"),
      responsiblePartyOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      cofOptions: BmpFcsInventoryConstants.cofOptionsFlagged,
      correctiveOptions: FilterConstants.getAllCorrectiveOptions(),
      enforcementLevel: FilterConstants.getEnforcementLevel(),
      conditionOptions: FilterConstants.conditionOptions,
      yesNoOptions: [
        {
          name: "Yes",
          value: "true",
        },
        {
          name: "No",
          value: "false",
        },
        {
          name: "No Data",
          value: "null",
        },
      ],
      yesNoUnknownOptions: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
        {
          name: "Unknown",
          value: "unknown",
        },
      ],
    };
  };

  return {
    filterResourceDataHandler,
    isOnlineDateFilterSet,
    getFilterPhases,
    searchDbFields,
    addGeoServerFilterQuery,
    addToDoGeoServerFilterQuery,
    addGeoServerViewParams,
    resetTodoFilter,
    getProps,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = MuniCatchBasinFilters();

const ApiError = require("../errors/apiError");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const MuniCatchBasinGeoServerLayer = require("../muni/muniCatchBasinGeoServerLayer");
const ResourceFilters = require("../filters/resourceFilters");
const ToDoConfig = require("../config/toDoConfig");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
const OfflineFilterFunctions = require("../mapFunctions/offlineFilterFunctions");
const Tree = require("../tree");
const Filters = require("../mapFunctions/filters");
const FilterSummary = require("../filters/filterSummary");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const FilterConstants = require("../filterConstants");
const ToolSettings = require("../settings/toolSettings");
const Progeny = require("../login/progeny");
