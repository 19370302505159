L.Control.DrainageDrawControls = L.Control.extend({
  initialize: function (targetLayer, drawController) {
    this._layer = targetLayer;
    this._drawController = drawController;
    this._activeDraw = null;
    this._map = null;
    this._controlDiv = null;
    this._currentMode = null;
    this._hasChanges = false;

    this._editControls = new L.EditToolbar({
      featureGroup: this._layer,
      poly: {
        allowIntersection: false,
        allowOverlap: false,
      },
    });
    this._editHandler = this._editControls.getModeHandlers()[0].handler;
  },
  onAdd: function (map) {
    this._map = map;
    this._editHandler._map = map;
    map.editTools = new L.Editable(map);
    map.drainageDrawControls = this;
    this._controlDiv = L.DomUtil.create("div", "drainage-draw-controls leaflet-draw-toolbar");
    this._controlDiv.innerHTML = nunjucks.render("maps/modal/modalMapDrainageDrawControls.njk");
    L.DomEvent.disableClickPropagation(this._controlDiv);

    this.loadListeners();
    return this._controlDiv;
  },
  onRemove: function (map) {
    this._layer.off("click");
    delete map.drainageDrawControls;
  },
  loadListeners: function () {
    const $polygonButton = $(this._controlDiv).find(".leaflet-draw-draw-polygon");
    const $deleteButton = $(this._controlDiv).find(".leaflet-draw-edit-remove");
    const $editButton = $(this._controlDiv).find(".glyphicon-pencil");

    this._layer.on("click", (e) => this.deletePolygon(e));
    $polygonButton.off("click");
    $polygonButton.on("click", (e) => this.togglePolygonDraw(e));
    $deleteButton.off("click");
    $deleteButton.on("click", (e) => this.toggleDeleteMode(e));
    $editButton.on("click", (e) => this.togglePolygonEdit(e));

    this._map.off("draw:created", (e) => this.resetControlState(e));
    this._map.on("draw:created", (e) => this.resetControlState(e));

    this._map.off("editable:vertex:dragend editable:vertex:deleted");
    this._map.on("editable:vertex:dragend editable:vertex:deleted", () => {
      this.setChanges(true);
      DrainageSelectionDraw.calculateDrawnDrainageArea();
    });
  },
  togglePolygonEdit: function (e) {
    this.toggleSelectedButton(e);
    if (this._currentMode === "editing") {
      this._currentMode = null;
      this.toggleEditMode(false);
    } else {
      this.resetPolygonDraw();
      this._currentMode = "editing";
      this.toggleEditMode(true);
    }
  },
  toggleEditMode: function (toggle) {
    this._layer.eachLayer((layer) => {
      toggle ? layer.enableEdit(this._map) : layer.disableEdit(this._map);
    });
  },
  deletePolygon: async function (e) {
    if (this._currentMode === "deleting") {
      this._layer.removeLayer(e.layer);
      this.setChanges(true);
      await DrainageSelectionDraw.calculateDrawnDrainageArea();
    }
  },
  togglePolygonDraw: function (e) {
    this.toggleSelectedButton(e);
    this.toggleEditMode(false);
    if (this._activeDraw === null) {
      this._currentMode = "drawing";
      this._activeDraw = new L.Draw.Polygon(this._map, this._drawController.options.draw.polygon);
      this._activeDraw.enable();
    } else {
      this._currentMode = null;
      this.resetPolygonDraw();
    }
  },
  toggleDeleteMode: function (e) {
    this.toggleSelectedButton(e);
    if (this._currentMode === "deleting") {
      this._currentMode = null;
    } else {
      this._currentMode = "deleting";
      this.resetPolygonDraw();
    }
  },
  toggleSelectedButton: function (e) {
    const $currentButton = $(e.currentTarget);
    $currentButton.toggleClass("draw-selected");
    $currentButton.siblings().toggleClass("draw-selected", false);
  },
  resetPolygonDraw: function () {
    if (this._activeDraw) {
      this._activeDraw.disable();
      this._activeDraw = null;
    }
  },
  resetControlState: function () {
    this.setChanges(false);
    this._currentMode = null;
    this.resetPolygonDraw();
    this.toggleEditMode(false);
    $(this._controlDiv).find(".leaflet-button").toggleClass("draw-selected", false);
  },
  setChanges: function (value) {
    this._hasChanges = value;
  },
  hasChanges: function () {
    return this._hasChanges;
  },
  options: {
    position: "topright",
  },
});

const DrainageSelectionDraw = require("./drainageSelectionDraw");
