"use strict";

const DrawFunctions = function () {
  var getDrawIntersectingData = function (
    drawnLayer,
    drawnLayerType,
    mapLayer,
    mapLayerGeomType = "Point",
  ) {
    var drawnPoly = getDrawnPoly(drawnLayer, drawnLayerType);

    if (validateDrawnPoly(drawnPoly)) {
      if (drawnLayerType === "polyline") {
        return getLineIntersectingPolygons(drawnPoly, mapLayer);
      } else {
        if (mapLayerGeomType === "Point") {
          return getPolygonIntersectingPoints(drawnPoly, mapLayer);
        } else if (mapLayerGeomType === "Polygon") {
          return getPolygonIntersectingPolygons(drawnPoly, mapLayer);
        }
      }
    } else {
      return [];
    }
  };

  var getDrawnPoly = function (drawnLayer, drawnLayerType) {
    if (drawnLayerType === "circle") {
      return getDrawnCirclePolygon(drawnLayer);
    } else {
      return drawnLayer.toGeoJSON();
    }
  };

  var getDrawnCirclePolygon = function (drawnLayer) {
    var centerLatLng = drawnLayer.getLatLng();
    var center = [centerLatLng.lng, centerLatLng.lat];
    var radius = drawnLayer.getRadius();
    var options = {
      steps: 60,
      units: "meters",
    };
    return turfCircle(center, radius, options);
  };

  var getPolygonIntersectingPolygons = function (drawnPoly, mapLayer) {
    var intersectingPolys = [];
    if (mapLayer) {
      mapLayer.eachLayer((layer) => {
        const poly = layer.toGeoJSON();
        try {
          const intersection = turfIntersect(drawnPoly, poly);
          if (intersection) {
            intersectingPolys.push(layer);
          }
        } catch (err) {
          if (!err.message.includes("Each LinearRing of a Polygon must have 4 or more Positions")) {
            throw err;
          }
        }
      });
    }
    return intersectingPolys;
  };

  var getPolygonIntersectingPoints = function (drawnPoly, mapLayer) {
    var intersectingPoints = [];
    if (mapLayer) {
      const featureCollection = mapLayer.toGeoJSON();

      featureCollection.features.forEach((point) => {
        try {
          const pointInPoly = turfBooleanPointInPolygon(point, drawnPoly);
          if (pointInPoly) {
            intersectingPoints.push(point);
          }
        } catch (err) {
          if (!err.message.includes("Each LinearRing of a Polygon must have 4 or more Positions")) {
            throw err;
          }
        }
      });
    }
    return intersectingPoints;
  };

  var getLineIntersectingPolygons = function (drawnPoly, mapLayer) {
    var intersectingPolys = [];
    if (mapLayer) {
      mapLayer.eachLayer((layer) => {
        const poly = layer.toGeoJSON();
        const intersection = turfLineIntersect(drawnPoly, poly);
        if (intersection && intersection.features && intersection.features.length !== 0) {
          intersectingPolys.push(layer);
        }
      });
    }
    return intersectingPolys;
  };

  var validateDrawnPoly = function (drawnPoly) {
    if (_isInvalidDrawnPoly(drawnPoly) || _isPolygonFinishedWithOnlyTwoPoints(drawnPoly)) {
      return false;
    }
    return true;
  };

  var _isInvalidDrawnPoly = function (drawnPoly) {
    return !drawnPoly || !drawnPoly.geometry;
  };

  var _isPolygonFinishedWithOnlyTwoPoints = function (drawnPoly) {
    return drawnPoly.geometry.type === "Polygon" && drawnPoly.geometry.coordinates[0].length === 3;
  };

  var unionAddLayer = function (targetLayer, addLayer) {
    const layers = targetLayer.getLayers();
    if (layers.length === 0) {
      targetLayer.addLayer(addLayer);
    } else {
      _unionAddLayers(targetLayer, layers, addLayer, addLayer.options);
    }
  };

  var _unionAddLayers = function (targetLayer, layers, addLayer, style) {
    if (layers.length === 0) return targetLayer.addLayer(addLayer);
    const layer = layers.pop();
    const layerGeoJson = layer.toGeoJSON();
    const addLayerGeoJson = addLayer.toGeoJSON();

    if (
      turfBooleanOverlap(layerGeoJson, addLayerGeoJson) ||
      turfBooleanContains(layerGeoJson, addLayerGeoJson) ||
      turfBooleanContains(addLayerGeoJson, layerGeoJson)
    ) {
      const union = turfUnion(layerGeoJson, addLayerGeoJson);
      if (union.geometry.type === "MultiPolygon") {
        union.geometry = convertMultiPolygonToPolygon(union.geometry);
      }
      const unionFeature = L.geoJSON(union, { style }).getLayers()[0];
      targetLayer.removeLayer(layer);
      return _unionAddLayers(targetLayer, layers, unionFeature, style);
    } else {
      return _unionAddLayers(targetLayer, layers, addLayer, style);
    }
  };

  var pointContained = function (point, geom) {
    return turfBooleanContains(geom, point);
  };

  var convertMultiPolygonToPolygon = function (geometry) {
    geometry.type = "Polygon";
    const oldCoordinates = geometry.coordinates;
    const newCoordinates = oldCoordinates.shift();

    oldCoordinates.forEach(function (coordinateSet) {
      coordinateSet.forEach(function (nestedCoordinates) {
        let hole = nestedCoordinates;
        if (!turfBooleanClockwise(hole)) {
          hole = hole.reverse();
        }
        newCoordinates.push(hole);
      });
    });

    geometry.coordinates = newCoordinates;
    return geometry;
  };

  return {
    getDrawIntersectingData,
    unionAddLayer,
    pointContained,
    convertMultiPolygonToPolygon,
  };
};

module.exports = DrawFunctions();

const turfUnion = require("@turf/union").default;
const turfIntersect = require("@turf/intersect").default;
const turfLineIntersect = require("@turf/line-intersect").default;
const turfBooleanPointInPolygon = require("@turf/boolean-point-in-polygon").default;
const turfCircle = require("@turf/circle").default;
const turfBooleanOverlap = require("@turf/boolean-overlap").default;
const turfBooleanContains = require("@turf/boolean-contains").default;
const turfBooleanClockwise = require("@turf/boolean-clockwise").default;
