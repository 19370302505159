"use strict";

const ProjectInventoryUsers = function () {
  const stringKey = "project-users";
  const headerInformation = "Users";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var loadListeners = function () {
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
    $page.on("click", ".new-user-button", createNewUser);
    $page.on("click", ".edit-button", editUser);
    $page.on("click", ".save-button", saveUser);
    $page.on("click", ".delete-button", deleteExistingUser);
    $page.on("click", ".delete-editing-button", deleteEditingUser);
    $page.on("click", ".save-initial-button", saveInitialUser);
    $page.on("change", "select[name*=role]", roleChangeHandler);
    $page.on("change", "select[name*=company-id]", updateUserDropdownHandler);
    $page.on("change", "select[name*=company-access]", updateUserDropdownHandler);
    $page.on("change", "select[name*=user-id]", updateUserEmailHandler);
  };

  var saveInitialUser = function (e) {
    const userLength = getUserLength();
    setFormInitialState(userLength);
    saveUser(e);
  };

  var unloadListeners = function () {
    $page.off("click", ".new-user-button", createNewUser);
    $page.off("click", ".edit-button", editUser);
    $page.off("click", ".save-button", saveUser);
    $page.off("click", ".delete-button", deleteExistingUser);
    $page.off("click", ".delete-editing-button", deleteEditingUser);
    $page.off("click", ".save-initial-button", saveInitialUser);
    $page.off("change", "select[name*=role]", roleChangeHandler);
    $page.off("change", "select[name*=company-id]", updateUserDropdownHandler);
    $page.off("change", "select[name*=company-access]", updateUserDropdownHandler);
    $page.off("change", "select[name*=user-id]", updateUserEmailHandler);
    $page.off("input", `[name*="users"]`, stageInitialId);
  };

  var render = async function (options = {}) {
    return Promise.all([getPossibleCompaniesIfNecessary(), getAllPossibleUsers()]).then(() => {
      const project = InventoryModal.getAllData("project-inventory");
      const props = getProps(project);
      renderUsersHtml(props, options);
      loadListeners();
      setInitialState(props, options);

      return;
    });
  };

  var getAllPossibleUsers = async function () {
    const possibleUsers =
      ProjectInventoryModalController.getExistingConstructionData("possibleUsers");
    if (possibleUsers === undefined) {
      const responseData = await ApiCalls.getPossibleContactUsers();
      Tree.set(["asset", "project-inventory", "possibleUsers"], responseData);
    }
    return possibleUsers;
  };

  var getPossibleCompaniesIfNecessary = async function () {
    const possibleCompanies =
      ProjectInventoryModalController.getExistingConstructionData("possibleCompanies");
    if (possibleCompanies === undefined) {
      const responseData = await ApiCalls.getPossibleContactCompanies();
      Tree.set(["asset", "project-inventory", "possibleCompanies"], responseData);
    }
    return possibleCompanies;
  };

  var renderUsersHtml = function (props, options) {
    const html = nunjucks.render("modals/projectInventory/projectInventoryUsers.njk", props);
    ProjectInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  var getProps = function (props) {
    return {
      users: prepareUsers(props),
      roleOptions: ProjectInventoryConstants.getUserRoleOptions(),
      possibleCompanies:
        ProjectInventoryModalController.getExistingConstructionData("possibleCompanies"),
    };
  };

  var setInitialState = function (props, options) {
    const currentUsersLength = props?.users?.length;
    if (options.editUser) {
      editUserById(options.editUser);
    } else if (currentUsersLength > 0 && options.newUser) {
      createNewUser();
    } else if (currentUsersLength === 0 || options.newUser) {
      $page.find(".new-user-button").hide();
      stageInitialUser();
    }
  };

  var stageInitialId = function () {
    const userLength = Math.max(0, getUserLength() - 1);
    setFormInitialState(userLength);
    $page.off("input", `[name*="users[${userLength}]"]`, stageInitialId);
  };

  var setFormInitialState = function (userLength = 0) {
    const newUserId = $page.find(".project-users .user").data("id");
    if (!getUserInfoById(newUserId)) {
      const userRole = $page.find(`[name="users[${userLength}][role]"]`).val();
      const companyId = parseInt($page.find(`[name="users[${userLength}][company-id]"]`).val());
      const affiliationAccess = Misc.getStringBooleanValue(
        $page.find(`[name="users[${userLength}][company-access]"]`).val(),
      );

      Form.manuallySetFormDataField("project-inventory", ["users", userLength, "id"], newUserId);
      Form.manuallySetFormDataField("project-inventory", ["users", userLength, "role"], userRole);
      Form.manuallySetFormDataField(
        "project-inventory",
        ["users", userLength, "companyId"],
        isNaN(companyId) ? null : companyId,
      );
      Form.manuallySetFormDataField(
        "project-inventory",
        ["users", userLength, "companyAccess"],
        affiliationAccess === "no-selection" ? null : affiliationAccess,
      );
    }
  };

  var cleanUp = function () {
    unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var prepareUsers = function (props) {
    return (
      props?.users
        ?.filter((user) => !user.deleted)
        .map(getUserInfoObject)
        .sort((a, b) => a.id - b.id) ?? []
    );
  };

  function getCompanyName(user) {
    const companies =
      ProjectInventoryModalController.getExistingConstructionData("possibleCompanies");
    if (companies.length === 0 || isNaN(parseInt(user.companyId))) {
      return null;
    }
    const company = companies.find((company) => company.value === parseInt(user.companyId));
    if (company === undefined) {
      throw Error(
        `Couldn't find company with id: ${parseInt(user.companyId)} in possibleCompanies.`,
      );
    }
    return company.name;
  }

  var toggleEditSaveButtonsDisabled = function (disabled) {
    if (disabled === false) {
      $page.find(".new-user-button").show();
    }
    $(".edit-button, .new-user-button").prop("disabled", disabled);
  };

  var stageInitialUser = function () {
    const { html, currentUsersLength } = createUserHtml(true);
    toggleEditSaveButtonsDisabled(true);
    $page.find(".project-users").prepend(html);
    $page.on("input", `[name*="users[${currentUsersLength}]"]`, stageInitialId);
  };

  var createNewUser = function () {
    const { html, currentUsersLength, newUser } = createUserHtml(false);
    Form.manuallySetFormDataField("project-inventory", ["users", currentUsersLength], newUser);
    toggleEditSaveButtonsDisabled(true);
    $page.find(".project-users").prepend(html);
    toggleUsersDropdown(newUser.id);
  };

  var createUserHtml = function (initialUser = false) {
    const userRoles = ProjectInventoryConstants.getUserRoleOptions();
    const companies =
      ProjectInventoryModalController.getExistingConstructionData("possibleCompanies") ?? [];
    const newId = Date.now();
    const newUser = {
      id: newId,
      role: userRoles[0].value,
      companyId: ProjectInventoryConstants.noAffiliationRequired.includes(userRoles[0].value)
        ? null
        : (companies[0]?.value ?? null),
    };
    const currentUsersLength = getUserLength();
    const affiliationAccessOptions = ProjectInventoryConstants.getAffiliationAccessOptions(
      companies?.length,
      newUser.role,
      newUser.companyId,
    );

    newUser.companyAccess = Misc.getStringBooleanValue(affiliationAccessOptions[0]?.value) ?? null;

    return {
      html: nunjucks.render("modals/projectInventory/projectInventoryNewUser.njk", {
        roleOptions: userRoles,
        affiliationAccessOptions: affiliationAccessOptions,
        user: newUser,
        index: currentUsersLength,
        initialUser: initialUser,
        possibleCompanies: companies,
        possibleUsers: getPossibleUsers(newUser.companyId, newUser.role, newUser.companyAccess),
      }),
      currentUsersLength: currentUsersLength,
      newUser: newUser,
    };
  };

  var getUserLength = function () {
    const data = InventoryModal.getAllData("project-inventory");
    return data?.users?.length ?? 0;
  };

  var saveUser = function (e) {
    const $user = $(e.currentTarget);
    const userId = $user.closest(".user").data("id");
    const userInfo = getUserInfoById(userId);
    if (userInfo === undefined) throw new Error(`Couldn't get user with id ${userId}.`);
    const html = nunjucks.render("modals/projectInventory/projectInventoryExistingUser.njk", {
      user: getUserInfoObject(userInfo),
    });
    $user.closest("section").html(html);
    toggleEditSaveButtonsDisabled(false);
    removeInitialInputListener();
  };

  var getUserInfoObject = function (user) {
    const possibleAlias = getAliasInfoById(user.userId);

    return {
      ...user,
      companyName: getCompanyName(user) ?? "",
      displayRole: getDisplayRoleFromUser(user) ?? "",
      fullName: possibleAlias?.name ?? "",
      emailAddress: possibleAlias?.emailAddress ?? "",
      fullMailingAddress:
        user.fullMailingAddress ??
        Misc.getFullMailingAddress(user.mailingAddress, user.city, user.state, user.zipCode),
    };
  };

  var deleteExistingUser = function (e) {
    const $user = $(e.currentTarget);
    const userId = $user.closest(".user").data("id");
    const userInfo = getUserInfoById(userId);

    const aliasInfo = getAliasInfoById(userInfo?.userId);
    const fullName = aliasInfo?.name ?? "";
    const deleteExistingUserCallback = () => {
      deleteUser(userId);
    };

    MessageModal.showDeleteUserModal(fullName, deleteExistingUserCallback);
  };

  var deleteEditingUser = function (e) {
    const $user = $(e.currentTarget);
    const userId = $user.closest(".user").data("id");
    const userInfo = getUserInfoById(userId);

    if (userInfo) {
      const aliasInfo = getAliasInfoById(userInfo?.userId);
      const fullName = aliasInfo?.name ?? "";
      const deleteEditingUserCallback = () => {
        deleteUser(userId);
        toggleEditSaveButtonsDisabled(false);
      };
      MessageModal.showDeleteUserModal(fullName, deleteEditingUserCallback);
    } else {
      removeInitialInputListener();
      $(`[data-id="${userId}"]`).remove();
      toggleEditSaveButtonsDisabled(false);
    }
  };

  var deleteUser = function (userId) {
    const userIndex = getUserIndexById(userId);
    const existingUserIds =
      ProjectInventoryModalController.getExistingConstructionData("users")?.map((user) => {
        return user.id;
      }) ?? [];

    if (existingUserIds.includes(userId)) {
      Form.manuallySetFormDataField("project-inventory", ["users", userIndex], {
        deleted: true,
        id: userId,
      });
    } else {
      Form.manuallyUnsetField("project-inventory", ["users", userIndex]);
    }
    $(`[data-id="${userId}"]`).remove();
  };

  var editUser = function (e) {
    const $user = $(e.currentTarget);
    const userId = $user.closest(".user").data("id");
    editUserById(userId);
  };

  var editUserById = function (userId) {
    const userInfo = getUserInfoById(userId);
    const possibleCompanies = getPossibleCompaniesByRole(userInfo.role);
    const affiliationAccessOptions = ProjectInventoryConstants.getAffiliationAccessOptions(
      possibleCompanies?.length,
      userInfo.role,
      userInfo.companyId,
    );
    const html = nunjucks.render("modals/projectInventory/projectInventoryModifyUser.njk", {
      roleOptions: getUserRoleOptionsWithUser(userInfo),
      affiliationAccessOptions: affiliationAccessOptions,
      possibleCompanies: getPossibleCompaniesByRole(userInfo.role),
      possibleUsers: getPossibleUsers(userInfo.companyId, userInfo.role, userInfo.companyAccess),
      companyAccess: userInfo.companyAccess,
      user: { ...userInfo, emailAddress: getAliasInfoById(userInfo?.userId)?.emailAddress ?? "" },
      index: getUserIndexById(userInfo.id),
    });
    $(`.user[data-id=${userId}]`).html(html);
    toggleEditSaveButtonsDisabled(true);
  };

  var getPossibleUsers = function (companyId, role, companyAccess) {
    const users = ProjectInventoryModalController.getExistingConstructionData("possibleUsers");
    const possibleRoles = ProjectInventoryConstants.getPossibleUserRolesByRole(role);
    if (role === "ms4-project-engineer") {
      return users.filter((user) => possibleRoles.includes(user.role));
    }

    if (companyAccess || companyAccess === null || companyAccess === undefined) return [];

    return users.filter(
      (user) =>
        parseInt(user?.companyId) === parseInt(companyId) && possibleRoles.includes(user.role),
    );
  };

  var getUserRoleOptionsWithUser = function (userInfo) {
    const options = Array.from(ProjectInventoryConstants.getUserRoleOptions());

    if (!options.containsObjectWith("value", userInfo.role)) {
      options.unshift({ value: userInfo.role, name: userInfo.displayRole });
    }

    return options;
  };

  var getDisplayRoleFromUser = function (user) {
    const roles = ProjectInventoryConstants.getAllUserRoleOptions();
    const matchingRole = roles.find((options) => {
      return options.value === user.role;
    });

    if (matchingRole === undefined) {
      return user.displayRole;
    }

    return matchingRole.displayName;
  };

  var getUserIndexById = function (userId) {
    const data = InventoryModal.getAllData("project-inventory");
    if (data.users) {
      return data.users.findIndex((user) => {
        return userId === user?.id;
      });
    }
  };

  var getUserInfoById = function (userId) {
    const data = InventoryModal.getAllData("project-inventory");
    const userIndex = getUserIndexById(userId);

    return userIndex === undefined ? undefined : data.users[userIndex];
  };

  var removeInitialInputListener = function () {
    const length = getUserLength() - 1;
    $page.off("input", `[name*="users[${length}]"]`, stageInitialId);
  };

  var roleChangeHandler = function (e) {
    const $targetField = $(e.currentTarget);
    const id = $targetField.parents(".user").data("id");
    updateAffiliationDropdown(id);
    updateAffiliationAccessDropdown(id);
    toggleUsersDropdown(id);
    resetUserSelection(id);
  };

  var updateAffiliationAccessDropdown = function (id) {
    const userEntry = getUserInfoById(id);
    const index = getUserIndexById(id);
    const possibleCompanies =
      ProjectInventoryModalController.getExistingConstructionData("possibleCompanies") ?? [];
    const affiliationAccessOptions = ProjectInventoryConstants.getAffiliationAccessOptions(
      possibleCompanies?.length,
      userEntry.role,
      userEntry.companyId,
    );
    const firstAccessOption = affiliationAccessOptions[0]?.value ?? null;

    ProjectInventoryModalController.setFormDataField(
      ["users", index, "companyAccess"],
      firstAccessOption,
    );

    $page.find(".affiliation-access-selection").html(
      nunjucks.render("modals/projectInventory/projectInventoryAffiliationAccessDropdown.njk", {
        user: { ...userEntry, companyAccess: firstAccessOption },
        index: index,
        affiliationAccessOptions: affiliationAccessOptions,
      }),
    );
  };

  var updateAffiliationDropdown = function (id) {
    const userEntry = getUserInfoById(id);
    const index = getUserIndexById(id);

    if (userEntry.role === "ms4-project-engineer") {
      ProjectInventoryModalController.setFormDataField(["users", index, "companyId"], "");
      userEntry.companyId = null;
    } else {
      const possibleCompanies =
        ProjectInventoryModalController.getExistingConstructionData("possibleCompanies") ?? [];
      const firstCompany = userEntry.companyId
        ? userEntry.companyId
        : (possibleCompanies[0]?.value ?? null);

      ProjectInventoryModalController.setFormDataField(["users", index, "companyId"], firstCompany);
      userEntry.companyId = firstCompany;
    }

    $page.find(".affiliation-selection").html(
      nunjucks.render("modals/projectInventory/projectInventoryAffiliationDropdown.njk", {
        user: userEntry,
        index: index,
        possibleCompanies: getPossibleCompaniesByRole(userEntry.role),
      }),
    );
  };

  var toggleUsersDropdown = function (id) {
    resetEmailDisplay();
    createUserDropdown(id);
  };

  var resetEmailDisplay = function () {
    $page.find(".email-address input").val(null);
  };

  var updateUserDropdownHandler = function (e) {
    const $targetField = $(e.currentTarget);
    const id = $targetField.parents(".user").data("id");

    createUserDropdown(id);
    resetUserSelection(id);
  };

  var createUserDropdown = function (id) {
    const userEntry = getUserInfoById(id);
    const index = getUserIndexById(id);

    const possibleUsers = getPossibleUsers(
      userEntry?.companyId,
      userEntry?.role,
      userEntry?.companyAccess,
    );

    const newUserDropdownOptions = nunjucks.render(
      "modals/projectInventory/projectInventoryUserDropdown.njk",
      {
        index,
        possibleUsers,
        user: { userId: null, companyAccess: userEntry.companyAccess },
      },
    );
    $page.find(".user-selection").html(newUserDropdownOptions);
  };

  var resetUserSelection = function (id) {
    const index = getUserIndexById(id);

    ProjectInventoryModalController.setFormDataField(["users", index, "userId"], null);
    resetEmailDisplay();
  };

  var getAliasInfoById = function (id) {
    const possibleUsers =
      ProjectInventoryModalController.getExistingConstructionData("possibleUsers");
    return possibleUsers.find((user) => user.value == id);
  };

  var updateUserEmailHandler = function (e) {
    const $targetField = $(e.currentTarget);
    const userId = $targetField.val();
    const userInfo = getAliasInfoById(userId);
    $page.find(".email-address input").val(userInfo?.emailAddress);
  };

  var getPossibleCompaniesByRole = function (role) {
    if (role === "ms4-project-engineer") {
      return [];
    } else {
      return ProjectInventoryModalController.getExistingConstructionData("possibleCompanies") ?? [];
    }
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    editUserById,
    deleteUser,
    getPossibleUsers,
    updateAffiliationDropdown,
    updateAffiliationAccessDropdown,
  };
};

module.exports = ProjectInventoryUsers();

const ProjectInventoryModalController = require("./projectInventoryModalController");
const InventoryModal = require("../general/inventoryModal");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const MessageModal = require("../modals/messageModal");
const Form = require("../general/form");
const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const Misc = require("../misc");
