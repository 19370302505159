"use strict";

const CommonModalFunctions = function () {
  var toggleDisabledInputs = function ($element) {
    Misc.toggleDisabledInputs($element, Tree.get("readOnlyModal"));
  };

  var handleReadOnlyView = function ($modal, readOnly) {
    Tree.set("readOnlyModal", readOnly);
    toggleDisabledInputs($modal.find(".modal-body"));

    if (readOnly) {
      $modal.find(".bmpModalButton").hide();
    } else {
      $modal.find(".bmpModalButton").show();
    }
  };

  var hideModalDatePickerOnClickOutside = function ($datePicker, $modal) {
    $datePicker.on("dp.show", function () {
      $modal.off("click", "*", onClick);
      $modal.on("click", "*", onClick);

      $datePicker.off("blur", onBlur);
      $datePicker.on("blur", onBlur);
    });

    var onBlur = function () {
      $datePicker.data("DateTimePicker").hide();
      $datePicker.off("blur", onClick);
    };

    var onClick = function (clickEvent) {
      if (!$datePicker.is(clickEvent.target) && $datePicker.has(clickEvent.target).length === 0) {
        $datePicker.data("DateTimePicker").hide();
        $modal.off("click", "*", onBlur);
      }
    };
  };

  var getInventoryFieldsByInputNames = function (inputNames) {
    var labelText = [];

    for (const name of inputNames) {
      const labels = FormConstants.inventoryFieldLegend;
      if (!labels[name]) {
        throw new Error("Legend not found for input name: " + name);
      }
      labelText.push(labels[name]);
    }

    return labelText;
  };

  var highlightParent = function ($inputs) {
    var $toHighlight = $inputs;

    if ($inputs.attr("type") === "radio") {
      $toHighlight = $inputs.parents(".info-input");
      if ($toHighlight.length == 0) {
        $toHighlight = $inputs.parents(".input-group");
      }
    } else {
      $toHighlight = $inputs.parent();
    }

    highlight($toHighlight);
  };

  var highlight = function ($elements, highlighted = true) {
    $elements.toggleClass("highlight-field", highlighted);
  };

  var removeHighlights = function ($container) {
    highlight($container.find(".highlight-field"), false);
  };

  var highlightEmptyFormFields = function (
    $modal,
    fieldNameArray,
    data,
    fieldValuesToHighlight = {},
    fieldNamePrefixPath = [],
  ) {
    removeHighlights($modal);

    for (const fieldName of fieldNameArray) {
      const path = [...fieldNamePrefixPath, fieldName];
      const fieldValue = Form.getDataAtPath(data, path);
      const forceHighlight =
        fieldName in fieldValuesToHighlight
          ? fieldValuesToHighlight[fieldName].includes(fieldValue)
          : false;

      if (
        [null, undefined, ""].includes(fieldValue) ||
        fieldValue?.length === 0 ||
        forceHighlight
      ) {
        highlightField($modal, Form.getInputNameFromPath(path));
      }
    }
  };

  var removeUploadOrLocation = function (fileList) {
    return fileList.forEach((file) => {
      if (file.locationType === "upload") {
        file.fileAccessDescription = "";
        file.externalUrl = "";
      } else if (file.locationType === "location") {
        file.fileUploadLocation = "";
        file.externalUrl = "";
      } else if (file.locationType === "external-url") {
        file.fileAccessDescription = "";
        file.fileUploadLocation = "";
      }
    });
  };

  var cleanIds = function (listToClean, existingList) {
    const existingIds = existingList.map((file) => {
      return file.id;
    });

    Misc.appendExistingAttribute(existingIds, listToClean, "id");
    Misc.removeAttributeValues(existingIds, listToClean, "id");
    return Object.values(listToClean);
  };

  var highlightFormFields = function ($modal, fieldNameArray) {
    removeHighlights($modal);

    for (const fieldName of fieldNameArray) {
      highlightField($modal, fieldName);
    }
  };

  var highlightField = function ($modal, fieldName) {
    const $field = $modal.find(`[name="${fieldName}"]`);
    highlight($field.parents("fieldset, .fieldset"));
    $field.one("input 2N:FormInput", (e) => {
      highlight($(e.currentTarget).parents("fieldset, .fieldset"), false);
    });
  };

  var getCloseWithoutSavingPrompt = function (
    doNotCloseButtonText = "Go Back",
    closeButtonText = "Close Without Saving",
    isHtml = true,
  ) {
    return isHtml
      ? `<p>None of the changes you have made will be saved.</p><p>Click <b>${doNotCloseButtonText}</b> to return to the data entry form.<br>Click <b>${closeButtonText}</b> to close and lose all changes.</p>`
      : `None of the changes you have made will be saved. Click "${doNotCloseButtonText}" to return to the data entry form. Click "${closeButtonText}" to close and lose all changes.`;
  };

  return {
    toggleDisabledInputs,
    handleReadOnlyView,
    hideModalDatePickerOnClickOutside,
    getInventoryFieldsByInputNames,
    highlight,
    highlightParent,
    removeHighlights,
    highlightEmptyFormFields,
    removeUploadOrLocation,
    cleanIds,
    highlightFormFields,
    getCloseWithoutSavingPrompt,
  };
};

module.exports = CommonModalFunctions();

const Form = require("../general/form");
const FormConstants = require("../mapFunctions/formConstants");
const Misc = require("../misc");
const Tree = require("../tree");
