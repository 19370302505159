"use strict";

/*
  All functions here are designed to work with the old BMP RAM endpoints
  and are superseded by the new Eloquent models. Please don't use this.
*/

const LegacyBmpFcs = function () {
  var prepareTotalAreaData = function (inventory) {
    inventory.forEach(function (bmp) {
      bmp.isCentralized = isSquareIconBMP(parseInt(bmp.bmp_kind));
      if (bmp.drainageAreaAcres === null) {
        bmp.formatted_bmp_drainarea = "—";
      } else if (bmp.drainageAreaAcres === "0") {
        if (bmp.isCentralized) {
          bmp.formatted_bmp_drainarea = "0.0";
        } else {
          bmp.formatted_bmp_drainarea = "—";
        }
      } else {
        bmp.formatted_bmp_drainarea = parseFloat(bmp.drainageAreaAcres).toFixed(1);
      }
    });
  };

  var assignBmpFcsTableIcons = function (bmps) {
    bmps.forEach(function (feature) {
      feature.tableIcon = getLegacyBmpFcsTableIcon(feature);
    });
  };

  var addNaIfBmpStatusIsNull = function (bmps) {
    bmps.forEach(function (bmp) {
      if (bmp.bmpStatus === null) {
        bmp.bmpStatus = "—";
      }
    });
  };

  var getLegacyBmpFcsTableIcon = function (feature) {
    const bmpActive = MapFunctions.bmpIsActive();
    return BmpFcsIcon.getTableIcon(
      bmpActive ? feature.bmpScore : feature.score,
      BmpFcsFunctions.isCentralizedBMPType(feature.bmp_kind),
      feature.fcs,
      bmpActive ? feature.bmpAssessmentDue : feature.fcsAssessmentDue,
      feature.phase,
    );
  };

  var getPopupRenderingProps = function (bmpFcs, layer) {
    if (Session.isRegulator()) {
      bmpFcs.permissions.canBeEdited = false;
      bmpFcs.permissions.canCreateObservation = false;
      bmpFcs.permissions.canCreateBenchmark = false;
    }

    const props = {};
    props.firstScoreDisplayed = layer;
    props.showInventoryButton = bmpFcs.permissions.canBeEdited;
    let score;
    if (layer === "fcs" || bmpFcs.isFcs) {
      score = bmpFcs.score;
    } else {
      score = bmpFcs.bmpScore;
    }

    props.popupColor = BmpFcsIcon.getIconColor(score, bmpFcs.phase);
    props.showFcsScore = bmpFcs.isFcs;
    props.showBmpScore = bmpFcs.bmp;
    const showFcsCheckmark = bmpFcs.isFcs && bmpFcs.fcs_stat;
    const showBmpCheckmark = bmpFcs.bmp_stat;
    props.showInventoryCheckmark =
      !bmpFcs.planned &&
      ((showFcsCheckmark && layer === "fcs") || (showBmpCheckmark && layer === "bmp"));
    const validBmpDrainageArea =
      bmpFcs.bmp_drainarea && (bmpFcs.isCentralized || bmpFcs.bmp_drainarea != 0);
    props.validBmpDrainageArea = !!validBmpDrainageArea;
    props.showFcsMiniTab = bmpFcs.isFcs;
    props.showBmpMiniTab = bmpFcs.isBmp;
    props.miniTabSelected = layer;
    props.readOnlyFooter = !bmpFcs.permissions.canBeEdited;
    if (bmpFcs.isBmp) addSbmpPopupProps(bmpFcs, props);
    if (bmpFcs.isFcs) addFcsPopupProps(bmpFcs, props);
    return props;
  };

  var addFcsPopupProps = function (fcs, props) {
    props.showAddFcsAssessmentButton =
      fcs.fcs_stat && !fcs.planned && fcs.permissions.canCreateObservation;
  };

  var addSbmpPopupProps = function (bmp, props) {
    if (!bmp.skipBenchmark) {
      addSbmpBenchmarkButtonProps(bmp, props);
    }
    if (!bmp.skipObservation) {
      addSbmpAssessmentButtonProps(bmp, props, bmp.skipBenchmark);
    }

    const isBmpOnly = BmpFcsFunctions.isBmpOnlyType(bmp.bmp_kind);

    props.isBmpOnly = isBmpOnly;
    props.showDeleteBmp = !bmp.inv_accept && bmp.permissions.canBeEdited;
    props.showAddAsFcsButton = !bmp.isFcs && !isBmpOnly && bmp.permissions.canBeEdited;
  };

  var addSbmpBenchmarkButtonProps = function (bmp, props) {
    var hasBenchmark = !!bmp.currentBenchmarkId;
    var bmpInventoryComplete = bmp.bmp_stat;

    props.hasBenchmark = hasBenchmark;
    props.showAddNewBenchmarkButton =
      bmp.permissions.canCreateBenchmark &&
      bmpInventoryComplete &&
      !bmp.planned &&
      (!hasBenchmark ||
        (bmp.currentBenchmarkComplete && bmp.currentBenchmarkHasObs) ||
        (bmp.currentBenchmarkComplete && bmp.skipObservation));
    props.showDisabledAddNewBenchmarkButton =
      bmp.permissions.canCreateBenchmark && (!bmpInventoryComplete || bmp.planned);

    props.hasIncompleteBenchmark = hasBenchmark && !bmp.currentBenchmarkComplete;
    props.showEditBenchmarkButton =
      bmp.permissions.canCreateBenchmark &&
      hasBenchmark &&
      !bmp.currentBenchmarkHasObs &&
      !(bmp.currentBenchmarkComplete && bmp.skipObservation);
    props.benchmarkDate = getBenchmarkDate(bmp);
    props.showBenchmarkCheckmark = hasBenchmark && bmp.currentBenchmarkComplete;
  };

  var addSbmpAssessmentButtonProps = function (bmp, props, skipBenchmark) {
    if (!bmp.bmp_stat || bmp.planned) return;
    if ((bmp.currentBenchmarkId && bmp.currentBenchmarkComplete) || skipBenchmark) {
      props.showBmpAssessmentDueDate = true;

      if (bmp.currentBenchmarkHasObs || skipBenchmark) {
        if (bmp.currentObsComplete || bmp.currentObsId === null) {
          props.showAddSbmpAssessmentButton = true;
        } else {
          props.showEditSbmpAssessmentButton = true;
          props.bmpAssessmentDueDate = "In Progress";
        }
      } else {
        props.showAddSbmpAssessmentButton = true;
        props.bmpAssessmentDueDate = bmp.currentBenchmarkDate;
      }
      if (bmp.permissions.canCreateObservation === false) {
        props.showAddSbmpAssessmentButton = false;
        props.showEditSbmpAssessmentButton = false;
        props.showBmpAssessmentDueDate = false;
      }
    }
  };

  var getBenchmarkDate = function (bmp) {
    if (bmp.currentBenchmarkId) {
      if (bmp.currentBenchmarkComplete) {
        return bmp.currentBenchmarkDate;
      }
      return "In Progress";
    }
    return "—";
  };

  var prepareBmpMarkerData = function (bmp) {
    var bmpMarkerData = Object.assign({}, bmp);
    bmpMarkerData.isBmp = bmpMarkerData.bmp;
    bmpMarkerData.isFcs = bmpMarkerData.fcs;
    bmpMarkerData.isPartialCapture = bmpMarkerData.treats_storm === false;
    bmpMarkerData.bmp_type_index = bmpMarkerData.bmp_kind;
    bmpMarkerData.bmp_obs = bmpMarkerData.bmp_score !== "—" ? true : false;

    bmpMarkerData.isCentralized = isSquareIconBMP(parseInt(bmpMarkerData.bmp_kind));

    bmpMarkerData.skipBenchmark = BmpFunctions.getSkipBenchmark(bmp);

    bmpMarkerData.regionalView = Session.isRegulator();

    Tree.set("currentBmpFcs", bmpMarkerData);
    return bmpMarkerData;
  };

  var isSquareIconBMP = function (bmpTypeNumber) {
    return BmpFcsFunctions.isCentralizedBMPType(bmpTypeNumber) || bmpTypeNumber == 18;
  };

  var fullDrainagePolygon = function (feature, isEnabled = false, isEnabledModal = false) {
    var drainageColor = convertBmpColorToHex(
      BmpFcsIcon.getIconColor(
        feature.properties.score,
        feature.properties.phase,
        false,
        Tree.get("activeTab") === "todo",
      ),
    );

    var styleProps = {
      color: drainageColor,
      fillOpacity: 0.4,
      fillColor: drainageColor,
      weight: 0,
    };

    if (!isEnabled && !isEnabledModal) {
      styleProps.fillOpacity = 0;
    }

    if (feature.properties["highlight"]) {
      styleProps.color = MapStyles.highlights().color;
      styleProps.weight = 3;
    }

    return styleProps;
  };

  var convertBmpColorToHex = function (color) {
    const hexColors = MapStyles.getHexColors();
    if (color && color.includes("-")) {
      color = Misc.kebabToCamel(color);
    }

    if (hexColors[color]) {
      return hexColors[color];
    } else {
      console.warn(`No hex color found for color: ${color}`);
      return hexColors.grey;
    }
  };

  return {
    prepareTotalAreaData,
    assignBmpFcsTableIcons,
    getLegacyBmpFcsTableIcon,
    getPopupRenderingProps,
    prepareBmpMarkerData,
    isSquareIconBMP,
    fullDrainagePolygon,
    addNaIfBmpStatusIsNull,
  };
};

module.exports = LegacyBmpFcs();

const BmpFunctions = require("../modals/bmpFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const BmpFcsFunctions = require("./bmpFcsFunctions");
const Session = require("../login/session");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
const BmpFcsIcon = require("./bmpFcsIcon");
const Misc = require("../misc");
