"use strict";

const MaintenanceZonePopup = function () {
  var getPopupHtml = function (properties) {
    return PopupContent.getLayerPopup(properties, "maintenanceZones");
  };

  return {
    getPopupHtml,
  };
};

module.exports = MaintenanceZonePopup();

const PopupContent = require("../mapFunctions/popupContent");
