"use strict";

const CapitalCostPage = function () {
  let $page;
  const headerInformation = "Capital Cost";

  var getStringKey = function () {
    return "capital-cost";
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    const data = _preparedData();
    await PropertyCostsModalController.renderPageContent(
      nunjucks.render("esg/modals/propertyCosts/capitalCostPage.njk", data),
    );
    _loadListeners();
    CostRecoveryPage.updateCostRecoveryPrograms();
  };

  const _preparedData = function () {
    const data = PropertyCostsModalController.getAllData();
    const constants = PageController.getConstants();

    const anyOnsite = _howManyOf(data.bmps, "bmpRelationship", "onsite") > 0;
    const anyOffsite = _howManyOf(data.bmps, "bmpRelationship", "offsite") > 0;
    const choseOnsiteOrOffsite = ["onsite", "offsite"].includes(data.bmpRelationship);

    return {
      ...data,
      costCapitalPlaceholder: _getPlaceholderCapitalCost(),
      bmpRelationshipRollupText: PropertyCostsModalController.bmpSummary(
        data,
        constants.bmpRelationship,
        "bmpRelationship",
      ),
      retrofitOpportunityRollupText: PropertyCostsModalController.bmpSummary(
        data,
        constants.retrofitOpportunities,
        "retrofitOpportunity",
      ),
      hideRetrofitOpportunity: !choseOnsiteOrOffsite && !anyOnsite && !anyOffsite,
      defaultUnitCost: _getDefaultUnitCost(data),
      countBmps: _countBmps(data.bmps),
    };
  };

  var _countBmps = function (bmps) {
    return bmps.filter((bmp) => !bmp.deleted).length;
  };

  var _howManyOf = function (arr, key, val) {
    return !Array.isArray(arr) ? 0 : arr.filter((item) => item[key] === val).length;
  };

  var _loadListeners = function () {
    Form.initializeAndLoadListeners($page, PropertyCostsModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("change input", ".impervious-area input", _changedPercentTreatable);
    $page.on("change", "select[name=climateScenario]", _changedClimateScenario);
    $page.on("click", ".button-style-radios .button", _changedSiteConfig);
    $page.on("change input", "[name=unit-treatment-cost-manual]", _changedManualUnitCost);
    $page.on("change input", "[name=cost-capital-manual]", _changedManualCapitalCost);
    $page.on("input", "input[name=cost-type]", _changedCostType);
    $page.on("change", "[name=useManualDesignStorm]", _changeUseManualDesignStorm);
    $page.on("change input", "[name=designStormManual]", _changedDesignStormManual);
  };

  var _unloadListeners = function () {
    $page.off("change input", ".impervious-area input", _changedPercentTreatable);
    $page.off("change", "select[name=climateScenario]", _changedClimateScenario);
    $page.off("click", ".button-style-radios .button", _changedSiteConfig);
    $page.off("change input", "[name=unit-treatment-cost-manual]", _changedManualUnitCost);
    $page.off("change input", "[name=cost-capital-manual]", _changedManualCapitalCost);
    $page.off("input", "input[name=cost-type]", _changedCostType);
    $page.off("change", "[name=useManualDesignStorm]", _changeUseManualDesignStorm);
    $page.off("change input", "[name=designStormManual]", _changedDesignStormManual);
  };

  var _changedPercentTreatable = function () {
    _updateTargetImperviousArea();
    _updateTargetCapacity();
    _updateDefaultUnitCost();
    updateCosts();
  };

  var _changedClimateScenario = function () {
    _updateDesignStormDepth();
    _updateTargetCapacity();
    _updateDefaultUnitCost();
    updateCosts();
  };

  var _changedSiteConfig = function () {
    window.setTimeout(() => {
      _updateSbmpButtonStyleRadios();
      _updateDefaultUnitCost();
      updateCosts();
    });
  };

  var _changedCostType = function () {
    _updateCostType();
    updateCosts();
  };

  var _changeUseManualDesignStorm = function () {
    _toggleManualDesignStorm($(this));
    _changedClimateScenario();
  };

  var _toggleManualDesignStorm = function ($this) {
    const stormDepth = _getStormDepth(true);
    const checked = $this.is(":checked");
    const $container = $this.closest(".column");
    const $input = $container.find("[name=designStormManual]");
    $container.toggleClass("manual", checked);
    $("[name=climateScenario]")
      .prop("disabled", checked)
      .val(checked ? "" : "2050");
    PropertyCostsModalController.setData("climateScenario", checked ? "" : "2050");
    NumberInput.manuallySetInput($input[0], stormDepth);
    $input.select();
  };

  var _changedDesignStormManual = function () {
    _changedClimateScenario();
  };

  var _changedManualUnitCost = function () {
    updateCosts();
  };

  var _changedManualCapitalCost = function () {
    updateCosts();
  };

  var _updateTargetImperviousArea = function () {
    const imperviousAcres = PropertyCostsModalController.getLatestData("imperviousAcres");
    const imperviousManual = PropertyCostsModalController.getLatestData("imperviousAcresManual");
    const useManual = PropertyCostsModalController.getLatestData("useManualImperviousAcres");
    const manualOrImperviousAcres = useManual ? imperviousManual || 0 : imperviousAcres || 0;
    const treatable = PropertyCostsModalController.getLatestData("imperviousTreatablePercent");
    PropertyCostsModalController.setData("imperviousTreatablePercent", treatable);
    const floatAcres = (manualOrImperviousAcres * treatable) / 100;
    PropertyCostsModalController.setData("imperviousTreatableAcres", floatAcres);
    $(".impervious-area .right .value").html(floatAcres.toFixed(2) + " ac");
  };

  var _updateTargetCapacity = function () {
    const treatableAcres = PropertyCostsModalController.getLatestData("imperviousTreatableAcres");
    const stormDepth = _getStormDepth();
    const targetCapacityAcreFt = treatableAcres * (stormDepth / 12);
    PropertyCostsModalController.setData("targetCapacity", targetCapacityAcreFt);
    $(".stormwater-capacity .right .value").html(targetCapacityAcreFt.toFixed(2) + " ac-ft");
  };

  var _getStormDepth = function (noManual = false) {
    const useManualDesignStorm = PropertyCostsModalController.getLatestData("useManualDesignStorm");
    const climateScenario = PropertyCostsModalController.getLatestData("climateScenario");
    const stormDepthType =
      useManualDesignStorm && !noManual
        ? "Manual"
        : climateScenario === "historic"
          ? "Hist"
          : "2050";
    return Number(PropertyCostsModalController.getLatestData(`designStorm${stormDepthType}`) || 0);
  };

  var _updateDesignStormDepth = function () {
    const stormDepth = _getStormDepth();
    const treatableAcres = PropertyCostsModalController.getLatestData("imperviousTreatableAcres");
    $(".stormwater-capacity .left .value").html(stormDepth.toFixed(2) + " inches");
    const floatVal = (stormDepth / 12) * treatableAcres;
    $(".stormwater-capacity .right .value").html(floatVal.toFixed(2) + " ac-ft");
  };

  var _updateSbmpButtonStyleRadios = function () {
    const $retrofitOpportunitySection = $(".site-config-item.retrofit-opportunity");
    const bmpRelationship = PropertyCostsModalController.getLatestData("bmpRelationship");
    const retrofitOpportunity = PropertyCostsModalController.getLatestData("retrofitOpportunity");
    const bmps = PropertyCostsModalController.getLatestData("bmps");

    const resetRetrofit = retrofitOpportunity && ["none", "unknown"].includes(bmpRelationship);
    if (resetRetrofit) {
      $('input[name="retrofitOpportunity"]')
        .prop("checked", false)
        .find("+ .button")
        .removeClass("active");
      PropertyCostsModalController.setData("retrofitOpportunity", null);
    }

    const isOnSiteOrOffsite = ["onsite", "offsite"].includes(bmpRelationship);
    const anyBmps = _countBmps(bmps) > 0;
    const showRetrofitOpportunity = isOnSiteOrOffsite || anyBmps;
    $retrofitOpportunitySection.toggleClass("hidden", !showRetrofitOpportunity);
  };

  var _updateCostType = function () {
    const $perAcreFtColumn = $(".site-config-capital-cost .column.calculated-type");
    const $fixedCostColumn = $(".site-config-capital-cost .column.fixed-type");
    if ($("#cost-type-ac-ft").prop("checked")) {
      $fixedCostColumn.addClass("hidden");
      $perAcreFtColumn.removeClass("hidden");
      $('input[name="unit-treatment-cost-manual"]')[0].focus();
      PropertyCostsModalController.setData(["costType"], "ac-ft");
      PropertyCostsModalController.setData(["costCapitalManual"], null);
    } else {
      $perAcreFtColumn.addClass("hidden");
      $fixedCostColumn.removeClass("hidden");
      $('input[name="cost-capital-manual"]')[0].focus();
      PropertyCostsModalController.setData(["costType"], "fixed");
      PropertyCostsModalController.setData(["unitTreatmentCostManual"], null);
    }
  };

  var _updateCapitalCost = function () {
    const data = PropertyCostsModalController.getAllData();
    const useManualValue = (manualValue) =>
      manualValue !== "" && manualValue !== null && manualValue !== undefined;
    let newCapitalCost;
    if (data.costType === "fixed") {
      const newPlaceholder = _getPlaceholderCapitalCost();
      newCapitalCost = useManualValue(data.costCapitalManual)
        ? data.costCapitalManual
        : newPlaceholder;
    } else {
      data.costType = "ac-ft";
      const unitCost = useManualValue(data.unitTreatmentCostManual)
        ? data.unitTreatmentCostManual
        : _getDefaultUnitCost(data);
      newCapitalCost = Math.round(unitCost * data.targetCapacity);
    }

    $(".capital-cost-equation .capital-cost strong").text(
      "$" + Math.round(newCapitalCost).toLocaleString("en-us"),
    );

    PropertyCostsModalController.setData(["costCapital"], newCapitalCost);
  };

  var updateCosts = function () {
    _updateCapitalCost();
    CostRecoveryPage.updateCostRecoveryPrograms();
    _updateTotalCost();
  };

  var _getPlaceholderCapitalCost = function () {
    const newDefault = _getDefaultUnitCost(PropertyCostsModalController.getAllData());
    const stormwaterCapacity = PropertyCostsModalController.getLatestData("targetCapacity");
    const newPlaceholder = Math.round(newDefault * stormwaterCapacity);

    return newPlaceholder;
  };

  var _updateTotalCost = function () {
    const latestData = PropertyCostsModalController.getAllData();
    const capitalCost = latestData.costCapital;
    const costRecovery = latestData.costRecovery;
    const totalCost = capitalCost - costRecovery;
    PropertyCostsModalController.setData("totalCost", totalCost);
    $(".capital-cost-equation .total-cost strong").text(
      "$" + Math.round(capitalCost - costRecovery).toLocaleString("en-us"),
    );
    $('input[name="costTotal"]').val(totalCost);
  };

  var _updateDefaultUnitCost = function () {
    // set new placeholder for ac-ft
    const newDefault = _getDefaultUnitCost(PropertyCostsModalController.getAllData());
    $('input[name="unit-treatment-cost-manual"]').attr(
      "placeholder",
      "$" + newDefault.toLocaleString("en-us"),
    );
    $("label.default-unit-cost span").text(Number(newDefault / 1000000).toFixed(1) + "M /ac-ft");

    // set new placeholder for fixed
    const stormwaterCapacity = PropertyCostsModalController.getLatestData("targetCapacity");
    const newPlaceholder =
      "$" + Math.round(newDefault * stormwaterCapacity).toLocaleString("en-us");
    $page.find("[name=cost-capital-manual]").attr("placeholder", newPlaceholder);
  };

  var _getDefaultUnitCost = function (data) {
    if (data.retrofitOpportunity === "yes" || _howManyOf(data.bmps, "retrofitOpportunity", "yes")) {
      if (
        data.bmpRelationship === "offsite" ||
        (_howManyOf(data.bmps, "bmpRelationship", "offsite") &&
          !_howManyOf(data.bmps, "bmpRelationship", "onsite"))
      ) {
        return 400000;
      }
      return 200000;
    } else if (data.siteComplexity === "moderate") {
      return 800000;
    } else if (data.siteComplexity === "low") {
      return 600000;
    }
    return 1000000;
  };

  var _validateInputInteger = function (inputVal, fallbackVal) {
    const cleanVal = inputVal.replace(/[^0-9-]/g, "");

    if (isNaN(Number(cleanVal)) || cleanVal === "") {
      return fallbackVal ?? 0;
    }
    return Number(cleanVal);
  };

  var cleanUp = function () {
    _unloadListeners();
    return true;
  };

  var validate = function () {
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    updateCosts,
    _howManyOf,
    _changedClimateScenario,
    _changedCostType,
    _changedPercentTreatable,
    _changedSiteConfig,
    _updateTargetImperviousArea,
    _updateTargetCapacity,
    _updateDesignStormDepth,
    _updateSbmpButtonStyleRadios,
    _updateCostType,
    _updateCapitalCost,
    _validateInputInteger,
    _getPlaceholderCapitalCost,
    _updateTotalCost,
    _updateDefaultUnitCost,
    _getDefaultUnitCost,
    _getStormDepth,
    cleanUp,
    validate,
  };
};

module.exports = CapitalCostPage();

const PropertyCostsModalController = require("../propertyCostsModalController");
const Form = require("../../../general/form");
const PageController = require("../../pageController");
const NumberInput = require("../../../general/numberInput");
const CostRecoveryPage = require("./costRecoveryPage");
