"use strict";

const ContactsPage = function () {
  const stringKey = "contacts";
  const headerInformation = "Contacts";

  let $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
    let data = PropertyInventoryModalController.getAllData();
    data = _getPreparedData(data);

    await PropertyInventoryModalController.renderPageContent(
      nunjucks.render("esg/modals/propertyInventory/contactsPage.njk", data),
    );
    CollapsingCard.initializeCards($("#inventory-modal"));
    _loadListeners();
    _updateContactsCount();
  };

  var _loadListeners = function () {
    Form.initializeAndLoadListeners($page, PropertyInventoryModalController.getFormKey(), {
      isMultiPart: true,
    });
    $page.on("input", "[name*=firstName], [name*=lastName]", _updateFirstLast);
    $page.on("change", "[name*=role]", _updateCardStatus);
    $page.on("input, change", "[name*=contacts]", _addContactId);
    $page.on("click", ".add-contact", _addContact);
    $page.on("click", ".delete-button", _deleteContact);
  };

  var _unloadListeners = function () {
    $page.off("input", "[name*=firstName], [name*=lastName]", _updateFirstLast);
    $page.off("change", "[name*=role]", _updateCardStatus);
    $page.off("input, change", "[name*=contacts]", _addContactId);
    $page.off("click", ".add-contact", _addContact);
    $page.off("click", ".delete-button", _deleteContact);
  };

  var _updateFirstLast = function () {
    const $this = $(this);
    const $card = $this.closest(".card");
    const deleteIcon = $card.find(".card-header span")[0].outerHTML;
    $card
      .find(".card-header")
      .html(
        $card.find("[name*=firstName]").val() +
          " " +
          $card.find("[name*=lastName]").val() +
          deleteIcon,
      );
  };

  var _getDropdownOptions = function () {
    return {
      ...PageController.getDropdownOptions(),
      contactRoles: ProjectInventoryConstants.getRoleOptions(),
    };
  };

  var _addContact = function () {
    const index = $(".card").length;
    const newCard = nunjucks.render("esg/modals/propertyInventory/_newContact.njk", {
      unit: {},
      index,
      dropdownOptions: _getDropdownOptions(),
    });
    $("#contacts").append(newCard);
    const $newCard = $("#inventory-modal .card:last");
    CollapsingCard.initializeCard($newCard);
    Form.initializeAutoNumericFields($newCard);
    Form.initializeYearInputs($newCard);
    Form.initializeDatePickers($newCard);

    const $modalBody = $("#inventory-modal .modal-body");
    $modalBody.animate({ scrollTop: $modalBody[0].scrollHeight }, 500);

    _updateContactsCount();
    PropertyInventoryModalController.setData(["contacts", index], {});
  };

  var _updateCardStatus = function () {
    const $card = $(this).closest(".card");
    const index = $card.data("index");
    const contact = PropertyInventoryModalController.getAllData().contacts?.[index];
    const statusText = _getStatusText(contact);
    $card.data("custom-status-text", statusText);
  };

  var _addContactId = function () {
    const $this = $(this);
    const $card = $this.closest(".card");
    const index = $card.data("index");
    const id = $card.data("id");
    if (!id) return;
    PropertyInventoryModalController.setData(["contacts", index], {
      ...PropertyInventoryModalController.getLatestData(["contacts", index]),
      id,
    });
  };

  var _deleteContact = function () {
    const $this = $(this);
    const id = $this.data("id");
    const $card = $this.closest(".card");
    $card.addClass("hidden");
    const index = $card.data("index");
    PropertyInventoryModalController.setData(["contacts", index], { id, deleted: true });
    _updateContactsCount();
  };

  var _updateContactsCount = function () {
    const count = $(".card:not(.hidden)").length;
    $(".contacts-count span").text(count);
    $(".no-contacts").toggleClass("hidden", count > 0);
  };

  var _getPreparedData = function (data) {
    return {
      ...data,
      dropdownOptions: _getDropdownOptions(),
      contacts: data?.contacts?.map(function (contact) {
        return {
          ...contact,
          statusText: _getStatusText(contact),
          firstLast: _getFirstLast(contact),
          displayRole: _getDisplayRole(contact.role),
        };
      }),
    };
  };

  var _getFirstLast = function (contact) {
    return contact.firstName || contact.lastName
      ? (contact.firstName ?? "") + " " + (contact.lastName ?? "")
      : "";
  };

  var _getDisplayRole = function (role) {
    return ProjectInventoryConstants.getRoleOptions().find((r) => r.value === role)?.name || "";
  };

  var _getStatusText = function (contact) {
    const statusText = _getDisplayRole(contact.role);
    return statusText;
  };

  var validate = function () {
    return true;
  };

  var cleanUp = function () {
    _unloadListeners();
    return true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
  };
};

module.exports = ContactsPage();

const CollapsingCard = require("../../../general/collapsingCard");
const PropertyInventoryModalController = require("../propertyInventoryModalController");
const Form = require("../../../general/form");
const PageController = require("../../pageController");
const ProjectInventoryConstants = require("../../../construction/projectInventoryConstants");
