"use strict";

var ProgressMapTable = function () {
  var inited = false;

  function init() {
    if (!inited) {
      loadListeners();
      inited = true;
    }
  }

  function render(data, selectedYear) {
    init();
    var summaryTableTemplate = LayerFunctions.getCurrentLayerProperty("summaryTableTemplate");

    if (data && summaryTableTemplate) {
      data["spatial"] = MapTitle.getSpatialTitle();
      data["reportingYear"] = selectedYear;
      data["activeGroupIsRegulator"] = Progeny.activeGroupIsRegulator();

      addRenderingPropsByDataView(data);

      const theHtml = nunjucks.render(
        summaryTableTemplate,
        Object.assign({}, data, {
          enablePostActivePhase: ToolSettings.getSetting(
            "construction",
            "enable-post-active-phase",
          ),
        }),
      );
      toggle(true);
      PageFunctions.getCurrentPage().find(".summary-table-wrapper").html(theHtml);
      if (!Tree.get("summaryTableOpen")) {
        closeSummaryTable();
      }
    }
  }

  function loadListeners() {
    Tree.watch({
      dataView: "dataView",
      activeTab: "activeTab",
    }).on("update", () => {
      const summaryTableTemplate = LayerFunctions.getCurrentLayerProperty("summaryTableTemplate");
      const show = !!(summaryTableTemplate && !Progeny.activeHasProgeny());
      toggle(show);
    });
  }

  var toggle = function (show) {
    PageFunctions.getCurrentPage().find(".summary-table-wrapper").toggle(show);
  };

  var addRenderingPropsByDataView = function (data) {
    const dataView = Tree.get("dataView");

    if (dataView === "sbmpView") {
      addSbmpViewRenderingProps(data);
    } else if (dataView === "fcsView") {
      addFcsViewRenderingProps(data);
    }

    if (LayerDropdown.isLayerDropdownEnabled()) {
      addLayerDropdownRenderingProps(data);
    }
  };

  var addSbmpViewRenderingProps = function (data) {
    data.showPublicDataRow = true;
    data.showPrivateDataRow = true;
    data.showPartnerDataRow = data.partnerSbmpCount > 0;
    data.showUnknownDataRow = data.unknownSbmpCount > 0;
    data.showTotalDataRow = true;
    data.subheaderCountTitle = "Total Structural BMPs";
  };

  var addFcsViewRenderingProps = function (data) {
    // No filter for FCS dataView yet
    data.showPublicDataRow = true;
    data.showPrivateDataRow = true;
    data.showPartnerDataRow = data.partnerFcsCount > 0;
    data.showUnknownDataRow = data.unknownFcsCount > 0;
    data.showTotalDataRow = true;
    data.subheaderCountTitle = "Total Managed Trash Full Capture System Assets";
  };

  var addLayerDropdownRenderingProps = function (data) {
    data["showBaselineData"] = LayerDropdown.isBaseline() || LayerDropdown.isMatrixBaseline();
  };

  var closeSummaryTable = function () {
    var $summaryTableWrapper = $(".summary-table-wrapper");
    $summaryTableWrapper.find(".toggle-button").removeClass("open");
    $summaryTableWrapper.find(".data-window").hide();
    $summaryTableWrapper.find(".summary-body").hide();
    $summaryTableWrapper.find(".summary-header hr").hide();
    $summaryTableWrapper.find(".summary-table-container").addClass("compact");
    $summaryTableWrapper.find(".summary-table-container").addClass("closed");
    $summaryTableWrapper.find(".summary-header .spatial-title").addClass("closed");
    $summaryTableWrapper.find(".summary-header .header-title").addClass("closed");
    Tree.set("summaryTableOpen", false);
  };

  var openSummaryTable = function () {
    var $summaryTableWrapper = $(".summary-table-wrapper");
    $summaryTableWrapper.find(".toggle-button").addClass("open");
    $summaryTableWrapper.find(".data-window").show();
    $summaryTableWrapper.find(".summary-body").show();
    $summaryTableWrapper.find(".summary-header hr").show();
    $summaryTableWrapper.find(".summary-table-container").removeClass("compact");
    $summaryTableWrapper.find(".summary-table-container").removeClass("closed");
    $summaryTableWrapper.find(".summary-header .spatial-title").removeClass("closed");
    $summaryTableWrapper.find(".summary-header .header-title").removeClass("closed");
    Tree.set("summaryTableOpen", true);
  };

  return {
    render,
    toggle,
    closeSummaryTable,
    openSummaryTable,
  };
};

module.exports = ProgressMapTable();

const Tree = require("../../../tree");
const Progeny = require("../../../login/progeny");
const PageFunctions = require("../../pageFunctions");
const MapTitle = require("../../mapFunctions/mapTitle");
const LayerDropdown = require("../../mapFunctions/layerDropdown");
const LayerFunctions = require("../../mapFunctions/layerFunctions");
const ToolSettings = require("../../../settings/toolSettings");
