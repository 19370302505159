"use strict";

L.Control.ModalMapLegend = L.Control.extend({
  onAdd: function (map) {
    const html = L.DomUtil.create("div", "modal-map-legend-control");
    html.innerHTML = nunjucks.render("maps/modal/modalMapLegend.njk");
    L.DomEvent.disableClickPropagation(html);

    return html;
  },
  options: {
    position: "topright",
  },
});
