"use strict";

var GroundwaterBasinLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "groundwaterBasin",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.groundwaterBasinLayer && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers.groundwaterBasinLayer);
          } else {
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.groundwaterBasinLayer)) {
            map.removeLayer(mapLayers.groundwaterBasinLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    // Handle fetching layer data
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "groundwaterBasin", "isFetching"], true);
    ReportApiCalls.getLidProjectAreaProgress(filters, function (data) {
      Tree.set(["layers", mapId, "groundwaterBasin", "isFetching"], false);
      Tree.set(["layers", mapId, "groundwaterBasin", "data"], data);
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // Handle layer data update after data fetch
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "groundwaterBasin", function (data) {
      if (data) {
        data.forEach((project) => {
          project.geom = project.projectAreaGeom;
        });
      }
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.groundwaterBasinLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.groundwaterBasinLayer) {
      map.removeLayer(mapLayers.groundwaterBasinLayer);
    }
    mapLayers.groundwaterBasinLayer = createLayer(data);
    mapLayers.groundwaterBasinLayer.addTo(map);
    return mapLayers.groundwaterBasinLayer;
  };

  var createLayer = function (data) {
    return new (Layers.getLayers().LidProjectAreaLayer)(data);
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    // @TODO: Update for watershedView filters
    Tree.watch({
      dataSort: ["filters", "dataSort"],
    }).on("update", function (e) {
      if (
        MapFunctions.mapHasLayer(map, mapLayers.groundwaterBasinLayer) &&
        Tree.get("layers", mapId, "groundwaterBasin", "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = GroundwaterBasinLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Layers = require("../mapFunctions/layers");
