"use strict";

const OutfallRoutineMaintenanceRepair = function () {
  const stringKey = "maintenance-repair";
  let $page;

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    RoutineMaintenanceFormTable.loadListeners($page);
  };

  var unloadListeners = function () {
    RoutineMaintenanceFormTable.unloadListeners($page);
  };

  var getProps = function () {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormTable.getRepairTabName();
  };

  var _getPage = function () {
    return $page;
  };

  return { loadListeners, unloadListeners, getStringKey, getTabHeader, getProps, _getPage };
};

module.exports = OutfallRoutineMaintenanceRepair();

const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
