"use strict";

const EnforcementActionModalController = function () {
  var setSelectedFollowupInspectionInUpdates = function (followupInspectionSelectorValue) {
    if (followupInspectionSelectorValue === "no-selection") {
      Tree.unset(["enforcementActionUpdates", "followupInspectionId"]);
    } else {
      var followupInspectionId = +followupInspectionSelectorValue;
      Tree.set(["enforcementActionUpdates", "followupInspectionId"], followupInspectionId);
    }
  };

  var getSelectedFollowupInspection = function () {
    var followupInspection = getSelectedFollowupInspectionInUpdates();
    if (followupInspection === null) {
      followupInspection = getCurrentEscalationFollowupInspection();
    }
    return followupInspection;
  };

  var getSelectedFollowupInspectionInUpdates = function () {
    const followupInspectionId = Tree.get("enforcementActionUpdates", "followupInspectionId");
    if (followupInspectionId === undefined) return null;
    return getFollowupInspectionById(followupInspectionId);
  };

  var getFollowupInspectionById = function (id) {
    const followupInspections = Tree.get(
      "enforcementAction",
      "currentEscalation",
      "possibleFollowupInspections",
    );
    return followupInspections.find((inspection) => inspection.id === id);
  };

  var getCurrentEscalationFollowupInspection = function () {
    const followupInspection = Tree.get(
      "enforcementAction",
      "currentEscalation",
      "followupInspection",
    );
    return followupInspection === undefined ? null : followupInspection;
  };

  var saveEnforcementActionAndClose = function (callback) {
    _saveEnforcementAction(function (responseData) {
      responseData.enforcementAction.escalations.forEach((escalation) => {
        _enforcementLetterFileUpload(escalation.id, () => {});
      });
      EnforcementActionModal.close();
      LidProjectFactSheetController.reload(responseData?.project);
      callback();
    });
  };

  var _saveEnforcementAction = async function (updatedEnforcementCallback) {
    await Form.getReadyToSavePromise("enforcement-action");
    const updates = Tree.get("enforcementActionUpdates");
    addFormDataUpdates(updates);

    if ($.isEmptyObject(updates)) {
      updatedEnforcementCallback(Tree.get("enforcementAction"));
      return;
    }

    const escilationOptions = getEscilationOptions(updates);
    updateResponse(updates);
    updateContractorResponse(updates);
    if (escilationOptions.addNewEscalation) {
      prepareEscilationUpdates(updates);
    }
    setUpdatesDateTimesToIso(updates);

    await ApiCalls.updateEnforcementAction(
      Tree.get("enforcementAction", "id"),
      updates,
      Tree.get("enforcementAction", "projectInspection", "id"),
      escilationOptions,
    ).then(updatedEnforcementCallback);

    Tree.set("enforcementActionUpdates", {});
  };

  var getEscilationOptions = function (updates) {
    const escilationOptions = {
      addNewEscalation: false,
      addEscalationAtSameLevel: false,
    };

    if (
      ["deferral-approved", "denied"].includes(updates.response) ||
      (updates.response === "approved" && shouldEscilateApproved())
    ) {
      escilationOptions.addNewEscalation = true;
      escilationOptions.addEscalationAtSameLevel = true;
    } else if (updates.response === "escalate") {
      escilationOptions.addNewEscalation = true;
      escilationOptions.addEscalationAtSameLevel = false;
    }

    return escilationOptions;
  };

  var shouldEscilateApproved = function () {
    const enforcementAction = Tree.get("enforcementAction");
    return (
      !enforcementAction.canResolveEnforcement &&
      ToolSettings.getSetting("construction", "require-enforcement-review") &&
      !enforcementAction.canRespondToEnforcement &&
      enforcementAction.currentEscalation.contractorResponse === "deferred"
    );
  };

  var prepareEscilationUpdates = function (updates) {
    delete updates.resolvedDate;

    if (!updates.issuedDate && !Tree.get("enforcementAction", "currentEscalation", "issuedDate")) {
      updates.issuedDate = DateTime.getToday();
    }
  };

  var updateResponse = function (updates) {
    const enforcementAction = Tree.get("enforcementAction");

    if (["denied", "deferral-denied"].includes(updates.response)) {
      updates.reviewerResponse = updates.response;
      updates.reviewerResponseDate = getReviewerResponseDate();
    } else if (updates.response === "deferral-approved") {
      updates.reviewerResponse = "approved";
      updates.reviewerResponseDate = getReviewerResponseDate();
    } else if (updates.response === "approved") {
      if (
        enforcementAction.canResolveEnforcement ||
        !ToolSettings.getSetting("construction", "require-enforcement-review")
      ) {
        updates.resolved = true;
        updates.resolvedDate = getResolvedDate();
      } else if (enforcementAction.canRespondToEnforcement) {
        updates.contractorResponse = "approved";
        updates.contractorResponseDate = getContractorResponseDate();
      } else {
        updates.reviewerResponse = "approved";
        updates.reviewerResponseDate = getReviewerResponseDate();
      }
    }

    delete updates.response;
  };

  var addFormDataUpdates = function (updates) {
    const data = Form.getDataFromForm("enforcement-action");

    for (const dataKey in data) {
      updates[dataKey] = data[dataKey];
    }
  };

  var updateContractorResponse = function (updates) {
    if (!updates.contractorResponse) {
      return;
    }

    updates.contractorResponseDate = getContractorResponseDate();

    if (updates.contractorResponse === "exemption") {
      updates.exemptionReason = getExemptionReason();
    } else if (updates.reviewerResponse) {
      updates.reviewerResponse = null;
    }
  };

  var getContractorResponseDate = function () {
    const escalationId = Tree.get("enforcementAction", "currentEscalation", "id");
    const responseDate = $(
      `[data-escalation-id=${escalationId}] [name='contractor-response-date']`,
    ).val();
    return responseDate?.length ? responseDate : DateTime.getNowIso();
  };

  var getReviewerResponseDate = function () {
    const escalationId = Tree.get("enforcementAction", "currentEscalation", "id");
    const responseDate = $(
      `[data-escalation-id=${escalationId}] [name='reviewer-response-date']`,
    ).val();

    return responseDate?.length ? responseDate : DateTime.getNowIso();
  };

  var getResolvedDate = function () {
    const enforcementAction = Tree.get("enforcementAction");
    const escalationId = enforcementAction?.currentEscalation?.id;
    const responseDate = $(`[data-escalation-id=${escalationId}] [name='resolved-date']`).val();
    return responseDate?.length ? responseDate : DateTime.getNowIso();
  };

  var getExemptionReason = function () {
    const escalationId = Tree.get("enforcementAction", "currentEscalation", "id");
    return $(`[data-escalation-id=${escalationId}] [name='exemption-reason']`).val();
  };

  var getAndRenderEnforcementAction = function (
    enforcementActionId,
    onSaveAndClose = () => {},
    onClose = () => {},
  ) {
    ApiCalls.getEnforcementAction(enforcementActionId, (enforcementAction) => {
      renderEnforcementAction(enforcementAction, onSaveAndClose, onClose);
    });
  };

  var renderEnforcementAction = function (
    enforcement,
    onSaveAndClose = () => {},
    onClose = () => {},
  ) {
    enforcement.escalations = enforcement.sortedEscalations;
    Tree.set("enforcementAction", enforcement);
    Tree.set("enforcementActionUpdates", {});
    EnforcementActionModal.renderAndShow(enforcement, onSaveAndClose, onClose);
  };

  var _enforcementLetterFileUpload = function (escalationId, callback) {
    const dropzoneObject = EnforcementActionModal.getDropzoneObject(escalationId);

    if (!dropzoneObject) {
      return callback();
    }

    const newFiles = dropzoneObject.getFiles();
    const removedFiles = dropzoneObject.getRemoved();

    if (newFiles.length > 0) {
      escalationFileUploadHandler(newFiles, escalationId, false, callback);
    }

    if (removedFiles.length > 0) {
      escalationFileUploadHandler(removedFiles, escalationId, true, callback);
    }

    if (newFiles.length === 0 && removedFiles.length === 0) {
      callback();
    }
  };

  var escalationFileUploadHandler = function (files, escalationId, deleteFile, callback) {
    const dropzoneConfig = EnforcementActionModal.getDropzoneConfig();
    const fileFormData = new FormData();
    files.forEach((file, index) => {
      fileFormData.append("file-" + (index + 1), file);
    });
    fileFormData.append("folderName", "letters");
    fileFormData.append("fileId", escalationId);
    fileFormData.append("pathName", "construction/escalations");
    deleteFile
      ? ApiCalls.formFileDelete(fileFormData, dropzoneConfig.downloadName, callback)
      : ApiCalls.formFileUpload(fileFormData, dropzoneConfig.downloadName, callback);
  };

  var setUpdatesDateTimesToIso = function (enforcementActionUpdates) {
    const dateTimeFields = [
      "issuedDate",
      "followupInspectionDueDate",
      "letterSignedReturnedDate",
      "contractorResponseDate",
      "reviewerResponseDate",
      "resolvedDate",
    ];
    dateTimeFields.forEach((fieldName) => {
      if (enforcementActionUpdates.hasOwnProperty(fieldName)) {
        if (enforcementActionUpdates[fieldName]) {
          enforcementActionUpdates[fieldName] = DateTime.parseDateToIso(
            enforcementActionUpdates[fieldName],
          );
        }
      }
    });
  };

  var saveAndDownloadZip = function () {
    _saveEnforcementAction(function (responseData) {
      _enforcementLetterFileUpload(responseData.enforcementAction.currentEscalation.id, () => {
        const enforcementActionId = responseData.enforcementAction.id;
        ApiCalls.downloadConstructionInspectionReportsZip(enforcementActionId);
        getAndRenderEnforcementAction(
          enforcementActionId,
          () => {},
          EnforcementActionModal.getOnSaveAndCloseFn(),
        );
      });
      LidProjectFactSheetController.reload(responseData?.project);
    });
  };

  var openEnforcementActionModalForInspection = function (inspectionId, onSaveAndClose) {
    ApiCalls.getInitialEnforcementActionFields(inspectionId, (enforcementAction) => {
      Tree.set("enforcementAction", enforcementAction);
      EnforcementActionModal.renderAndShow(enforcementAction, onSaveAndClose);
    });
  };

  var saveAndRerenderEnforcement = function () {
    return _saveEnforcementAction(function (responseData) {
      renderEnforcementAction(
        responseData.enforcementAction,
        () => {},
        EnforcementActionModal.getOnSaveAndCloseFn(),
      );
      LidProjectFactSheetController.reload(responseData?.project);
    });
  };

  return {
    getAndRenderEnforcementAction,
    getFollowupInspectionById,
    getSelectedFollowupInspection,
    getSelectedFollowupInspectionInUpdates,
    openEnforcementActionModalForInspection,
    saveAndDownloadZip,
    saveEnforcementActionAndClose,
    setSelectedFollowupInspectionInUpdates,
    saveAndRerenderEnforcement,
    _saveEnforcementAction,
    _enforcementLetterFileUpload,
  };
};

module.exports = EnforcementActionModalController();

const ApiCalls = require("../apiCalls");
const DateTime = require("../dateTime");
const EnforcementActionModal = require("./enforcementActionModal");
const Form = require("../general/form");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const LidProjectFactSheetController = require("../lid/lidProjectFactSheetController");
