"use strict";

const DrainageTable = function () {
  var getDrainageTableConfigurations = function () {
    return {
      addPolygonHandler: addDrainagePolygonHandler,
      removePolygonHandler: removeDrainagePolygonHandler,
      percentageUpdateHandler: percentageUpdateHandler,
      loadPolygonTreeHandler: setAssetInfoFromPolygonData,
      selectAllPolygonsHandler: selectAllDrainagePolygonsHandler,
    };
  };

  var selectAllDrainagePolygonsHandler = function (polygonArray, select) {
    BmpFcsInventoryModalController.setFormDataField("areaPolygons", polygonArray);

    const drainageLayer = ModalMap.getDrainageLayer();
    const polygonFeatures = drainageLayer._layers;
    let totalTreated = 0.0;
    let pluTreated = 0.0;

    for (const featureObj in polygonFeatures) {
      const properties = polygonFeatures[featureObj].feature.properties;
      const isPlu = properties.priority === "Yes" ? true : false;
      if (select) {
        const percent = properties.percent / 100;
        totalTreated += parseFloat(properties.acres) * percent;
        if (isPlu) {
          pluTreated += parseFloat(properties.acres) * percent;
        }
      }
    }

    BmpFcsInventoryModalController.setFormDataField("pluTreated", `${pluTreated}`);
    BmpFcsInventoryModalController.setFormDataField("drainageArea", `${totalTreated}`);

    updateDrainageAreaFooter();
    Misc.toggleDisabled($(".impervious-treated"), !select);
  };

  var addDrainagePolygonHandler = function (polyProps, polygonArray, catchmentArray) {
    BmpFcsInventoryModalController.setFormDataField("areaPolygons", polygonArray);
    BmpFcsInventoryModalController.setFormDataField("selectedCatchments", catchmentArray);

    calculateTreatedAcres(polyProps, "+");
    updateDrainageAreaFooter();
    Misc.toggleDisabled($(".impervious-treated"), false);
  };

  var percentageUpdateHandler = function (data, cleanPercent, oldPercent) {
    updateTreatedByPercent(data, cleanPercent, oldPercent);
    updateDrainageAreaFooter();
  };

  var removeDrainagePolygonHandler = function (polyProps, polygonArray, catchmentArray) {
    BmpFcsInventoryModalController.setFormDataField("areaPolygons", polygonArray);
    BmpFcsInventoryModalController.setFormDataField("selectedCatchments", catchmentArray);

    calculateTreatedAcres(polyProps, "-");
    updateDrainageAreaFooter();
    if (polygonArray.length <= 1) {
      Misc.toggleDisabled($(".impervious-treated"), true);
    }
  };

  var updateTreatedByPercent = function (properties, percent, oldPercent) {
    var pluTreated = Tree.get(["asset", "basicInfo", "plu_treated"]);
    var totalTreated = Tree.get(["asset", "basicInfo", "bmp_drainarea"]);

    //total
    totalTreated =
      totalTreated -
      (oldPercent / 100) * properties.acres + //remove old percent acreage from total
      properties.acres * (percent / 100); // add back new percent acreage of total
    //plu
    if (properties.priority === "Yes") {
      pluTreated =
        pluTreated -
        (oldPercent / 100) * properties.acres + //remove old percent acreage from total
        properties.acres * (percent / 100);
    }

    //store updated values
    Tree.set(["asset", "basicInfo", "plu_treated"], pluTreated);
    Tree.set(["asset", "basicInfo", "bmp_drainarea"], totalTreated);

    return {
      pluTreated,
      totalTreated,
    };
  };

  var setDrainageDrawAcres = function () {
    const drainageAcres = BmpFcsInventoryModalController.getLatestBmpInventoryData("drawAcres") || {
      acres: 0,
      pluAcres: 0,
    };

    updateDrainageAreaFooter(drainageAcres["acres"], drainageAcres["pluAcres"]);
  };

  var updateDrainageAreaFooter = function (totalTreated = null, pluTreated = null) {
    if (totalTreated === null && pluTreated === null) {
      pluTreated = BmpFcsInventoryModalController.getLatestBmpInventoryData("pluTreated") || 0;
      totalTreated = BmpFcsInventoryModalController.getLatestBmpInventoryData("drainageArea") || 0;
    }

    pluTreated = Math.max(0, pluTreated).toFixed(
      FormConstants.inventoryDrainageAreaFieldToFixed["priority-treated"],
    );
    totalTreated = Math.max(0, totalTreated).toFixed(
      FormConstants.inventoryDrainageAreaFieldToFixed["total-treated"],
    );

    $(".priority-treated").text(`${pluTreated} acres`);

    const totalTreatedText =
      Tree.get("tool") === "scenario" ? totalTreated : `${totalTreated} acres`;
    $(".total-treated").text(totalTreatedText);

    var allowEditImperviousArea = totalTreated !== "0.00";
    Misc.toggleDisabled(
      $(".impervious-treated, [name=impervious-treated]"),
      !allowEditImperviousArea,
    );
  };

  var calculateTreatedAcres = function (properties, op) {
    let selectedPolygons;
    selectedPolygons = BmpFcsInventoryModalController.getLatestBmpInventoryData("areaPolygons");

    var treated = {};
    if (selectedPolygons.length === 1 && op === "+") {
      treated.totalTreated = 0;
      treated.pluTreated = 0;
    } else {
      treated.totalTreated =
        BmpFcsInventoryModalController.getLatestBmpInventoryData("drainageArea");
      treated.pluTreated = BmpFcsInventoryModalController.getLatestBmpInventoryData("pluTreated");
    }

    var percent = parseInt(properties.percent);

    var operators = {
      "+": function (a, b) {
        return a + b;
      },
      "-": function (a, b) {
        return a - b;
      },
    };

    const isPlu = properties.priority === "Yes" ? true : false;
    if (isPlu) {
      treated.pluTreated = operators[op](
        parseFloat(treated.pluTreated),
        (parseFloat(properties.acres) * (!isNaN(percent) ? percent : 100)) / 100,
      );
    }
    treated.totalTreated = operators[op](
      parseFloat(treated.totalTreated),
      (parseFloat(properties.acres) * (!isNaN(percent) ? percent : 100)) / 100,
    );

    //store updated values
    BmpFcsInventoryModalController.setFormDataField("drainageArea", `${treated.totalTreated}`);
    BmpFcsInventoryModalController.setFormDataField("pluTreated", `${treated.pluTreated}`);

    return treated;
  };

  var setAssetInfoFromPolygonData = function (info) {
    let totalTreated = 0;
    let pluTreated = 0;
    const polygons = info ? info.polygons : null;
    for (const index in polygons) {
      if (polygons[index].polygon_id) {
        totalTreated += parseFloat((polygons[index].acres * polygons[index].percent) / 100);
        if (polygons[index].priority) {
          pluTreated += parseFloat((polygons[index].acres * polygons[index].percent) / 100);
        }
      }
    }
    Tree.set(["asset", "lastSavedBmpDrainarea"], parseFloat(info.bmp_drainarea));
    Tree.set(["asset", "basicInfo", "plu_treated"], pluTreated);
    Tree.set(["asset", "basicInfo", "bmp_drainarea"], totalTreated);
    updateDrainageAreaFooter();
  };

  return {
    getDrainageTableConfigurations,
    updateDrainageAreaFooter,
    setDrainageDrawAcres,
  };
};

module.exports = DrainageTable();

const ModalMap = require("./modalMap");
const Tree = require("../tree");
const Misc = require("../misc");
const FormConstants = require("./formConstants");
const BmpFcsInventoryModalController = require("../bmpfcs/bmpFcsInventoryModalController");
