"use strict";

const AssetsCleanOutEsriDynamicMapLayer = function () {
  const layerName = "assetsCleanOutEsriDynamicMap";
  const layerKey = "assetsCleanOutEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsCleanOutEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsCleanOutEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsCleanOutEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};
module.exports = AssetsCleanOutEsriDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
