"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetPercentByYearBarChart = function (widgetConfig) {
  widgetConfig.hovers = [
    {
      className: "bar-group",
      getHoverHtml: InsightWidgetPercentByYearBarChart.prototype.getHoverHtml,
    },
  ];

  InsightWidget.call(this, widgetConfig);

  this.widgetConfig = widgetConfig;
};

InsightWidgetPercentByYearBarChart.prototype = Object.create(InsightWidget.prototype);
InsightWidgetPercentByYearBarChart.prototype.constructor = InsightWidgetPercentByYearBarChart;

InsightWidgetPercentByYearBarChart.prototype.getHtml = function (insightData) {
  var html = nunjucks.render("insight/widgets/countByYearChart.njk", {
    title: this.widgetConfig.title,
  });

  return html;
};

InsightWidgetPercentByYearBarChart.prototype.renderChart = function (
  insightData,
  exportModal = false,
) {
  var currentDataArray = this.widgetConfig.getDataArray(insightData);
  this.currentDataArray = currentDataArray;
  this.draw(exportModal);
};

InsightWidgetPercentByYearBarChart.prototype.draw = function (exportModal = false) {
  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }

  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  defaults.containerHeight = 190;
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight;

  var margin = defaults.margin,
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom;

  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom + 10);

  var legendData = this.widgetConfig.legendData();
  this.appendStandardChartLegend(svg, legendData);

  var chartGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top + 10})`);

  var currentDataArray = this.currentDataArray;
  var scaleX = d3
    .scaleBand()
    .domain(currentDataArray.map((item) => item.key))
    .range([0, width])
    .padding(0.4);

  var barGroups = chartGroup
    .selectAll()
    .data(currentDataArray)
    .enter()
    .append("g")
    .attr("class", "bar-group")
    .attr("data-hover-index", function (d, i) {
      return i;
    })
    .attr("data-type", function (d, i) {
      return d.key;
    });

  barGroups
    .append("rect")
    .attr("class", "hover-background-rect")
    .attr("x", function (d) {
      return scaleX(d.key);
    })
    .attr("y", function (d) {
      return 0;
    })
    .attr("width", scaleX.bandwidth())
    .attr("height", function (d) {
      return height;
    })
    .attr("fill", "transparent");

  const twoLineLabel = this.widgetConfig.twoLineLabel;
  var axisX = d3
    .axisBottom(scaleX)
    .tickFormat(function (d) {
      return "";
    })
    .tickPadding(25)
    .tickSize(0);
  chartGroup
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(0," + height + ")")
    .call(axisX)
    .selectAll("text")
    .selectAll("tspan")
    .data(function (d) {
      const displayName = currentDataArray.find((item) => item.key === d)?.displayName;
      if (twoLineLabel && displayName.includes(" ")) {
        const index = displayName.indexOf(" ");
        const line1 = displayName.slice(0, index);
        const line2 = displayName.slice(index + 1, displayName.length);
        return [line1, line2];
      } else {
        return [displayName, ""];
      }
    })
    .enter()
    .append("tspan")
    .attr("x", 0)
    .attr("dy", function (d, i) {
      return 2 * i - 1 + "em";
    })
    .text(String);

  var extentY = d3.extent(this.currentDataArray, function (d) {
    return Math.max(d.total || 0, d.value || 0);
  });
  const maxY = extentY[1] === 0 ? 10 : Math.ceil(extentY[1] * 1.25);

  var scaleY = d3.scaleLinear().domain([0, maxY]).range([height, 0]);
  var axisY = d3.axisLeft(scaleY).ticks(5).tickSize(0).tickPadding(10).tickSizeInner(-width);
  chartGroup.append("g").attr("class", "axis").call(axisY);

  if (this.widgetConfig.separateBars) {
    this.drawSeparateBars("total", currentDataArray, barGroups, scaleX, scaleY, height, legendData);
    this.drawSeparateBars("value", currentDataArray, barGroups, scaleX, scaleY, height, legendData);
  } else {
    this.drawSingleBars("total", currentDataArray, barGroups, scaleX, scaleY, height, legendData);
    this.drawSingleBars("value", currentDataArray, barGroups, scaleX, scaleY, height, legendData);

    // Adjust label position to avoid overlapping
    barGroups.each(function (d) {
      const barGroup = d3.select(this);
      const valueLabel = barGroup.selectAll(".bar-label.value");
      const valueLabelY = valueLabel.attr("y");

      const totalLabel = barGroup.selectAll(".bar-label.total");
      const totalLabelY = totalLabel.attr("y");

      if (totalLabelY >= valueLabelY - 16) {
        totalLabel.attr("y", valueLabelY - 16);
      }
    });
  }
};

InsightWidgetPercentByYearBarChart.prototype.drawSeparateBars = function (
  dataPath,
  dataArray,
  barGroups,
  scaleX,
  scaleY,
  height,
  legendData,
) {
  const subGroupPadding = 2;
  const barWidth = (scaleX.bandwidth() - subGroupPadding) / 2;

  barGroups
    .append("rect")
    .data(dataArray)
    .attr("class", dataPath)
    .attr("x", function (d) {
      if (dataPath === "total") {
        return scaleX(d.key);
      } else {
        return scaleX(d.key) + barWidth + subGroupPadding;
      }
    })
    .attr("y", function (d) {
      const count = dataPath === "total" ? d.total : d.value;
      return scaleY(count);
    })
    .attr("width", barWidth)
    .attr("height", function (d) {
      let count = dataPath === "total" ? d.total : d.value;
      if (count === null) count = 0;
      return height - scaleY(count);
    })
    .attr("fill", function (d, i) {
      const yearKey = i === 0 ? "focus" : "comparison";
      const valueKey = dataPath === "total" ? "Total" : "Value";
      return legendData[yearKey + valueKey].color;
    });

  const dataDecimalPlaces =
    this.widgetConfig.dataDecimalPlaces !== undefined ? this.widgetConfig.dataDecimalPlaces : 0;

  barGroups
    .append("text")
    .data(dataArray)
    .attr("class", "bar-label")
    .attr("text-anchor", "middle")
    .attr("x", function (d) {
      if (dataPath === "total") {
        return scaleX(d.key) + barWidth / 2;
      } else {
        return scaleX(d.key) + barWidth + barWidth / 2 + subGroupPadding;
      }
    })
    .attr("y", function (d) {
      let count = dataPath === "total" ? d.total : d.value;
      if (count === null) count = 0;
      return scaleY(count) - 5;
    })
    .text(function (d) {
      const count = dataPath === "total" ? d.total : d.value;
      return count?.toFixed(dataDecimalPlaces);
    });
};

InsightWidgetPercentByYearBarChart.prototype.drawSingleBars = function (
  dataPath,
  dataArray,
  barGroups,
  scaleX,
  scaleY,
  height,
  legendData,
) {
  const barWidth = scaleX.bandwidth();

  barGroups
    .append("rect")
    .data(dataArray)
    .attr("x", function (d) {
      return scaleX(d.key);
    })
    .attr("y", function (d) {
      const count = dataPath === "total" ? d.total : d.value;
      return scaleY(count);
    })
    .attr("width", barWidth)
    .attr("height", function (d) {
      let count = dataPath === "total" ? d.total : d.value;
      if (count === null) count = 0;
      return height - scaleY(count);
    })
    .attr("fill", function (d, i) {
      const yearKey = i === 0 ? "focus" : "comparison";
      const valueKey = dataPath === "total" ? "Total" : "Value";
      return legendData[yearKey + valueKey].color;
    });

  const dataDecimalPlaces =
    this.widgetConfig.dataDecimalPlaces !== undefined ? this.widgetConfig.dataDecimalPlaces : 0;

  const maxLabelY = height - 3;
  barGroups
    .append("text")
    .data(dataArray)
    .attr("class", `keep-color bar-label ${dataPath}`)
    .attr("text-anchor", "middle")
    .attr("x", function (d) {
      return scaleX(d.key) + barWidth / 2;
    })
    .attr("y", function (d) {
      let count = dataPath === "total" ? d.total : d.value;
      if (count === null) count = 0;
      return Math.min(scaleY(count) + 15, maxLabelY);
    })
    .attr("fill", function (d) {
      if (dataPath === "value" && scaleY(d.value) >= maxLabelY) {
        return "#333333";
      }
      return dataPath === "total" ? "#333333" : "white";
    })
    .text(function (d) {
      const count = dataPath === "total" ? d.total : d.value;
      return count?.toFixed(dataDecimalPlaces);
    });
};

InsightWidgetPercentByYearBarChart.prototype.getHoverHtml = function (insightData, $hoverTarget) {
  var hoverIndex = $hoverTarget.data("hoverIndex");

  var hoverTitle = this.widgetConfig.getHoverTitle(hoverIndex);
  var hoverDescription = this.widgetConfig.getHoverDescription(hoverIndex);

  var value = this.currentDataArray[hoverIndex]?.value;
  var total = this.currentDataArray[hoverIndex]?.total;
  var percentValue = (value / total) * 100;

  var trendIconClass;
  // Only show trend icon for foucs year
  if (hoverIndex === 0) {
    var comparisonValue = this.currentDataArray[1]?.value;
    var comparisonTotal = this.currentDataArray[1]?.total;
    var comparisonPercentValue = (comparisonValue / comparisonTotal) * 100;

    trendIconClass = this.getTrendIconClass(percentValue, comparisonPercentValue);
  }

  var html = nunjucks.render("insight/widgets/countByTypeBarHover.njk", {
    hoverTitle,
    hoverDescription,
    trendIconClass,
    trendPercentDiff: !isFinite(percentValue) ? "—" : percentValue,
    hideTrendIcon: this.widgetConfig.hideTrendIcon || hoverIndex !== 0,
  });

  return html;
};

module.exports = InsightWidgetPercentByYearBarChart;

const d3 = require("d3-v6");
