"use strict";

const Outfalls = function (
  layerName,
  layerKey,
  rotatingIconFunction,
  getDataFunction,
  {
    clusteringEnabled = false,
    itemCountEnabled = false,
    useFilters = true,
    hideWhenZoomedOut = false,
    useSimplePopup = false,
  } = {},
) {
  const ApiCalls = require("../apiCalls");
  const MapLayer = require("../mapFunctions/mapLayer");

  var createOutfallMarker = function (latlng, data) {
    return rotatingIconFunction(
      latlng,
      data.rotationAzimuth,
      data.priority,
      data.condition,
      data.outfallInspectionIsDue,
    );
  };

  var createIcon = function (data, zoom) {
    return OutfallsIcon.getOutfallsMarkerIcon(
      data.priority,
      data.condition,
      data.outfallInspectionIsDue,
    );
  };

  const popupModule = useSimplePopup ? SimpleOutfallPopup : OutfallPopup;

  const outfallsLayer = new MapLayer(layerName, layerKey, getDataFunction, {
    createMarkerFunction: createOutfallMarker,
    getPopupHtmlFunction: (data) => popupModule.getPopupHtml(data),
    getPopupDataFunction: ApiCalls.getOutfallPopup,
    onPopupOpenFunction: (data, $element) =>
      popupModule.onPopupOpenWithData(data, $element, outfallsLayer.invalidateLayerData),
    getFiltersFunction: useFilters ? null : () => {},
    clusteringEnabled: clusteringEnabled,
    itemCountEnabled: itemCountEnabled,
    hideWhenZoomedOut: hideWhenZoomedOut,
    createIcon: createIcon,
  });

  return {
    ...outfallsLayer,
  };
};

module.exports = Outfalls;

const OutfallPopup = require("./outfallPopup");
const SimpleOutfallPopup = require("./simpleOutfallPopup");
const OutfallsIcon = require("./outfallsIcon");
