"use strict";

const BmpFunctions = function () {
  const $fcsAssessmentModal = $("#fcs-assessment-modal");

  var loadFcsModalListeners = function () {
    $fcsAssessmentModal.off();
    $fcsAssessmentModal.on(
      "click",
      "#cancel-observation-button",
      FormFunctions.cancelFcsAssessment,
    );
    $fcsAssessmentModal.on("click", ".assessment-calc-score", calcScore);
    $fcsAssessmentModal.on("click", ".save-assessment-button", () =>
      FormFunctions.saveFcsAssessmentInfo(),
    );
    $fcsAssessmentModal.on("click", "#cancel-update-button", closeFcsModal);

    const scoreModal = $("#score-modal");
    scoreModal.off();
    scoreModal.on("click", ".save-score-btn", saveFcsScore);
    scoreModal.on("click", ".cancel-score-btn", fcsScoreCancelClick);
  };

  var fcsScoreCancelClick = function () {
    $("#score-modal").modal("hide");
    $fcsAssessmentModal.modal("show");
  };

  var showFcsAssessmentModal = function (bmpName, idBmp, bmp_type, obs_id, readOnly = false) {
    loadFcsModalListeners();
    $fcsAssessmentModal.modal("toggle");

    var groupId = Tree.get("activeGroup", "groupId");

    $fcsAssessmentModal.data("idBmp", idBmp);
    resetObsForm();
    CommonModalFunctions.handleReadOnlyView($fcsAssessmentModal, readOnly);

    var $assessmentDateTimePicker = $("#fcs-assessment-datetime-picker");
    $assessmentDateTimePicker.datetimepicker({
      format: "YYYY-MM-DD HH:mm",
      defaultDate: new Date(),
    });

    if (obs_id) {
      showFcsAssessmentModalForExistingAssessment(obs_id, groupId, idBmp, readOnly);
    } else {
      showFcsAssessmentModalForNewAssessment($assessmentDateTimePicker);
    }
    handleInputsDisplayByFcsFields(groupId, idBmp);
    setFcsAssessmentModalTitle(bmpName, bmp_type);

    var fcsAssessment = Tree.get("asset", "observation");
    fcsAssessment.idBmp = idBmp;
    fcsAssessment.fcs_name = bmpName;
    Tree.set(["asset", "observation"], fcsAssessment);

    if (Tree.get(["asset", "update"])) {
      PopupPhotos.load("obs", Tree.get(["obs_photos", "currentId"]));
    } else {
      PopupPhotos.load("obs");
    }

    Analytics.sendScreenView("modal", "assessment", "fcs");
  };

  var showFcsAssessmentModalForExistingAssessment = function (obs_id, groupId, idBmp, readOnly) {
    $("#cancel-observation-button").hide();
    $("#cancel-update-button").show();
    Tree.set(["asset", "update"], true);
    Tree.set(["obs_photos", "currentId"], obs_id);

    ApiCalls.getSingleFcsAssessment(groupId, idBmp, obs_id, function (data) {
      const obsData = Object.assign(Tree.get(["asset", "observation"]), data);
      Tree.set(["asset", "observation"], obsData);
      FormFunctions.populateFcsAssessmentForm(data);
      FormFunctions.setFcsAssessmentScoreButton();
      FormFunctions.loadFcsAssessmentFormListeners();
      CommonModalFunctions.handleReadOnlyView($fcsAssessmentModal, readOnly);
    });
  };

  var showFcsAssessmentModalForNewAssessment = function ($assessmentDateTimePicker) {
    $("#cancel-observation-button").show();
    $("#cancel-update-button").hide();
    const obsData = Tree.get(["asset", "observation"]);
    delete obsData.obs_id;

    obsData.personnel = Tree.get(["user", "username"]);
    $fcsAssessmentModal.find("input[name=personnel]").val(obsData.personnel);

    setAssessmentDateTimeToDefault($assessmentDateTimePicker, obsData);
    Tree.set(["asset", "observation"], obsData);
    CommonModalFunctions.hideModalDatePickerOnClickOutside(
      $("#fcs-assessment-datetime-picker"),
      $fcsAssessmentModal,
    );
    FormFunctions.loadFcsAssessmentFormListeners();
  };

  var handleInputsDisplayByFcsFields = function (groupId, idBmp) {
    ApiCalls.getSingleFcs(groupId, idBmp, function (data) {
      if (data.visible_screen) {
        $(".trash-screen").show();
        $(".visible-screens").show();
      } else {
        $(".trash-screen").hide();
        $(".visible-screens").hide();
      }
      if (data.system_access) {
        $(".trash-mobility").show();
        $(".trash-count").show();
        $(".footprint").show();
      } else {
        $(".trash-percent").show();
        $(".trash-count").hide();
        $(".footprint").hide();
      }

      $("#fcs-assessment-form input[name=bmp_footprint]").val(data.bmp_footprint);
      $("#fcs-assessment-form input[name=screen_count]").val(data.screen_count);

      const footprint = parseFloat(data.bmp_footprint);
      const locationCount = getLocationCount(footprint);
      $("#fcs-assessment-form input[name=location_count]").val(locationCount);

      $("#fcs-assessment-form input[name=bmp_outlets]").val(data.bmp_outlets);

      FormFunctions.setFcsAssessmentScoreButton();
    });
  };

  var setFcsAssessmentModalTitle = function (bmpName, bmp_type) {
    $("#obs-name").text(bmpName);
    $("#type-name").text(bmp_type);
  };

  var setAssessmentDateTimeToDefault = function ($assessmentDateTimePicker, obsData) {
    $assessmentDateTimePicker.data("DateTimePicker").date(new Date());
    obsData.date = $assessmentDateTimePicker.val();
  };

  var getLocationCount = function (footprint) {
    //default for null or 501 to 2000 range
    let locationCount = 3;

    if (footprint < 10) {
      locationCount = 1;
    } else if (footprint >= 10 && footprint <= 500) {
      locationCount = 2;
    } else if (footprint > 2000 && footprint <= 10000) {
      locationCount = 4;
    } else if (footprint > 10000) {
      locationCount = 5;
    }

    return locationCount;
  };

  var resetObsForm = function () {
    $(".trash-screen").hide();
    $(".trash-percent").hide();
    $(".trash-mobility").hide();
    FormFunctions.resetObsForm();
    CommonModalFunctions.handleReadOnlyView($fcsAssessmentModal, false);
  };

  const deleteFcs = function (idBmp) {
    var groupId = Tree.get("activeGroup", "groupId");
    ApiCalls.deleteFcs(idBmp, groupId, function () {
      MainMap.reloadBmpFcsLayer();
      $("#delete-fcs-modal").modal("hide");
    });
  };

  var calcScore = function () {
    var obsData = Tree.get("asset", "observation");
    obsData.group_id = Tree.get("activeGroup", "groupId");
    //if recalculating score, assessment record already exists
    if (obsData.obs_id != null) {
      Tree.set(["asset", "update"], true);
    }
    ApiCalls.getLastFcsAssessment(obsData.idBmp, (lastObs) => {
      FormFunctions.saveFcsAssessmentInfo(function (obsid) {
        obsData.obs_id = obsid;
        Tree.set(["asset", "observation", "obs_id"], obsid);
        renderUpdatedScoreModal(lastObs, obsData);
      });
    });
  };

  var renderUpdatedScoreModal = function (lastObs, obsData) {
    $(".score-text").empty();
    $(".fcs-score-bar").css("height", "0px");
    const callback = (lastScore) => {
      ApiCalls.getFcsScore(obsData, function (data) {
        $fcsAssessmentModal.modal("hide");
        $("#scoreName").text(obsData["fcs_name"]);
        $("#score-modal").find(".modal-title").text("FCS Score");
        $("#score-modal").modal("show");
        var newScore = parseFloat(data).toFixed(1);
        animateScoreUpdate(lastScore, newScore);
        Tree.set(["asset", "tempScore"], newScore);
        Tree.set(["asset", "update"], true);
      });
    };
    if (lastObs !== null && lastObs !== undefined && !$.isEmptyObject(lastObs)) {
      const lastObsDate = new Date(lastObs.date);
      const newObsDate = new Date(obsData.date);
      const isMostRecentObs = newObsDate > lastObsDate;
      if (isMostRecentObs) {
        ApiCalls.getFcsScore(lastObs, callback);
        return;
      }
    }
    const defaultScore = 0;
    callback(defaultScore);
  };

  var animateScoreUpdate = function (previousScore, newScore) {
    const ANIMATION_TIME = 1600;
    const MAX_SCORE = 5.0;
    const SCORE_TO_HEIGHT = parseInt($(".fcs-score-bar").css("max-height")) / MAX_SCORE;
    const EASE_IN_OUT_QUART_FUNCTION = (t) =>
      t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    const previousBarHeight = SCORE_TO_HEIGHT * previousScore;
    const newBarHeight = SCORE_TO_HEIGHT * newScore;
    const fcsScoreText = $(".score-text");
    const fcsScoreBar = $(".fcs-score-bar");
    let startTime;

    var updateScoreAnimationCallback = (currTime) => {
      if (!startTime) {
        startTime = currTime;
      }

      let elapsedTime = currTime - startTime;

      if (elapsedTime < ANIMATION_TIME) {
        requestAnimationFrame(updateScoreAnimationCallback);
      } else {
        // Fixes ending on the wrong number if the tab is not visible
        elapsedTime = ANIMATION_TIME;
      }

      const progressFraction = EASE_IN_OUT_QUART_FUNCTION(elapsedTime / ANIMATION_TIME);
      const currScore = previousScore + (newScore - previousScore) * progressFraction;
      const currBarHeight =
        previousBarHeight + (newBarHeight - previousBarHeight) * progressFraction;
      const currColor = MapStyles.getConditionColor(currScore, true);
      fcsScoreText.text(currScore.toFixed(1));
      fcsScoreText.css("color", currColor);
      fcsScoreBar.css("height", currBarHeight + "px");
      fcsScoreBar.css("background-color", currColor);
    };
    requestAnimationFrame(updateScoreAnimationCallback);
  };

  var saveFcsScore = function () {
    var groupid = Tree.get("activeGroup", "groupId");
    var obs_id = Tree.get("asset", "observation", "obs_id");
    var score = Tree.get("asset", "tempScore");
    $("#score-modal").modal("hide");
    closeFcsModal();
    $("#scoreName").text("");
    Tree.set(["asset", "tempScore"], null);
    ApiCalls.saveFcsScore(groupid, obs_id, score, function () {
      MainMap.reloadBmpFcsLayer();
      ToDoListController.loadTodos();
    });
  };

  var editHistory = function (data, readOnly) {
    switch (data.assetType) {
      case "fcs":
        showFcsAssessmentModal(data.bmpName, data.idBmp, data.type, data.obs_id, readOnly);
        break;
      case "bmp":
        BmpObservation.showBmpObsModal(
          data.idBmp,
          data.bmpName,
          data.type || BmpFcsFunctions.getBmpTypeNameByNumber(data.bmpType),
          data.bmpType || BmpFcsFunctions.getBmpTypeNumberByName(data.type),
          readOnly,
          data.obs_id,
          data.bm_id,
        );
        break;
      case "bmpBench":
        Benchmark.showEditBenchmarkModal(
          data.bmpName,
          data.idBmp,
          data.type || BmpFcsFunctions.getBmpTypeNameByNumber(data.bmpType),
          data.bmpType || BmpFcsFunctions.getBmpTypeNumberByName(data.type),
          data.bm_id,
          readOnly,
        );
        break;
      case "routineMaintenance":
        UrlRoutes.navigate(`/ram/asset/catchBasin/${data.idBmp}/routineInspection/${data.id}`);
        break;
      case "routineRepair":
        UrlRoutes.navigate(`/ram/asset/catchBasin/${data.idBmp}/routineInspection/${data.id}`);
        break;
      default:
        throw new Error(`No edit history defined for ${data.assetType}`);
    }
  };

  var confirmDeleteHistory = function ($todoTr, data) {
    return MessageModal.showDeleteRecordModal(() => {
      $todoTr.remove();
      return deleteHistory(data);
    }, getDeleteMessageOptions(data));
  };

  var getDeleteMessageOptions = function (data) {
    return {
      pdf:
        ["bmp"].includes(data.assetType) ||
        ["routineMaintenance", "routineRepair"].includes(data.assetType),
    };
  };

  var deleteHistory = async function (data) {
    const deleteInfoByType = {
      fcs: { delete: ApiCalls.deleteFcsAssessment, id: data.obs_id },
      bmp: { delete: ApiCalls.deleteBmpObservation, id: data.obs_id },
      bmpBench: { delete: ApiCalls.deleteBenchmark, id: data.bm_id },
      routineMaintenance: { delete: ApiCalls.deleteMaintenance, id: data.id },
      routineRepair: { delete: ApiCalls.deleteMaintenance, id: data.id },
    };
    const isG2sBmpLayer = Tree.get(["layers", "structuralBmps", "isEnabled"]);
    const deleteInfo = deleteInfoByType[data.assetType];
    if (!deleteInfo) {
      throw new Error(`No delete info defined for ${data.assetType}`);
    }
    const responseData = await deleteInfo.delete(deleteInfo.id);
    LidProjectFactSheetController.reload(responseData?.project);

    if (data.idBmp) {
      MainMap.reloadBmpFcsLayer();
      isG2sBmpLayer ? MapFunctions.openGeoServerPopup("structuralBmps", data.idBmp) : "";
    }
  };

  var getSkipBenchmark = function (bmp) {
    var systemAccess = bmp.system_access;
    var bmpTypeNumber = bmp.bmp_kind || bmp.bmp_type_key;
    var obsPortMatAccum = bmp.inv_sedacc;
    var obsPortStdWater = bmp.inv_stwat;

    if (systemAccess !== null) {
      if (systemAccess) {
        return FormConstants.skipBenchmarkTypeNumbers.includes(bmpTypeNumber);
      } else {
        return obsPortMatAccum == 0 && obsPortStdWater == 1;
      }
    }
    return false;
  };

  var closeFcsModal = function () {
    $("#fcs-assessment-modal").modal("hide");
    resetObsForm();
    PopupPhotos.clearObsTempPhotos("obs");
    Analytics.sendScreenView();
  };

  return {
    deleteFcs,
    showFcsAssessmentModal,
    confirmDeleteHistory,
    editHistory,
    getLocationCount,
    animateScoreUpdate,
    getSkipBenchmark,
    closeFcsModal,
  };
};

module.exports = BmpFunctions();

const Analytics = require("../general/analytics");
const ApiCalls = require("../apiCalls");
const Benchmark = require("./benchmark");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const BmpObservation = require("./bmpObservation");
const CommonModalFunctions = require("./commonModalFunctions");
const FormConstants = require("../mapFunctions/formConstants");
const FormFunctions = require("../mapFunctions/formFunctions");
const MainMap = require("../mapFunctions/mainMap");
const MapStyles = require("../mapFunctions/mapStyles");
const MessageModal = require("./messageModal");
const PopupPhotos = require("../mapFunctions/popupPhotos");
const UrlRoutes = require("../routes/urlRoutes");
const ToDoListController = require("../mapFunctions/toDoListController");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
const LidProjectFactSheetController = require("../lid/lidProjectFactSheetController");
