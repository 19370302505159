"use strict";

var TrashProgressEsriDynamicMapLayer = function () {
  const esriKey = "trash";
  const esriLayer = "trashProgress";
  const layerName = "trashProgressEsriDynamicMap";
  const layerKey = "trashProgressEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var layer = new EsriDynamicMapLayer(layerName, layerKey, esriKey, esriLayer);
    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = TrashProgressEsriDynamicMapLayer();

const EsriDynamicMapLayer = require("../mapFunctions/esriDynamicMapLayer");
