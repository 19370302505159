"use strict";

const DataList = function () {
  const maxRows = 1000;

  var $container = null;
  var $scrollContainer = null;
  var inited = false;
  var config = null;
  var headerText = "ID";
  var layerWatcher = null;
  let extraData;
  let list;
  let rowsData;
  var tableSort;

  /*
    Arguments:
      newConfig: {
        prepareDataCallback: function,
        template: "dataList.njk"
        layerName: "layer",
        afterRenderFunction: function,
      }
  */
  var init = function (initConfig) {
    $container = $(".data-list-container");
    $scrollContainer = $container.parent();
    tableSort = new TableSort($container);
    setIfNewConfig(initConfig);
    inited = true;
    loadTreeListeners();
    setDefaultIndcomHeaderText();
    extraData = {
      isCdot: Session.isCdot(),
      isSalinas: Session.isSalinas(),
    };
  };

  var setDefaultIndcomHeaderText = function () {
    if (Tree.get("tool") === "indcom") headerText = "Facility Name";
  };

  var render = async function (newConfig = null) {
    if (!inited) throw Error(`DataList.render() called without being initialized.`);
    setIfNewConfig(newConfig);

    if (
      (Tree.get("navToggleSelection") === "list" && Tree.get("activeTab") === "data") ||
      Tree.get("tool") === "esg"
    ) {
      const LayerFunctions = require("../layerFunctions");
      const data = Misc.shallowCloneArrayObjects(
        await LayerFunctions.getLayerData(config.layerName),
      );
      const rowsAndOptions = config.prepareDataCallback(data);

      if (!list) {
        const html = nunjucks.render(config.template, {
          rows: [],
          options: rowsAndOptions.options,
          sortHeader: headerText,
          ...extraData,
        });
        $container.html(html);
      }
      _renderRows(rowsAndOptions);

      FilterSummary.render();
      FilterSummary.handleMultiRowCss();
      NavToggle.handleCollapsedSideBar();
      config.afterRenderFunction?.(rowsAndOptions);
    }

    if ($(".search-input").is(":visible") || $(".filter-menu").is(":visible")) {
      Filters.mobileFilterSearchExpansionTableAdjustment();
    }

    renderSorting();
    activateOverflowTooltips();
    loadLayerListeners();
  };

  var _renderRows = function (rowsAndOptions) {
    rowsData = rowsAndOptions.rows || [];
    const rowTemplate = config.template.replace(".njk", "Row.njk");

    const getRowCallback = function (rowIndex) {
      return nunjucks.render(rowTemplate, {
        options: rowsAndOptions.options,
        row: rowsData[rowIndex],
      });
    };

    if (!list) {
      const LazyList = require("../general/lazyList");
      list = new LazyList({
        rowContainer: $container.find("tbody")[0],
        scrollContainer: $scrollContainer[0],
        getRowCallback: getRowCallback,
        totalRows: rowsData.length,
      });
    } else {
      list.updateData(getRowCallback, rowsData.length);
    }
  };

  var setIfNewConfig = function (newConfig) {
    if (isSameConfig(newConfig)) {
      return;
    }

    unloadLayerListeners();
    config = newConfig;
    invalidateList();
  };

  var invalidateList = function () {
    list?.destroy();
    list = null;
  };

  var isSameConfig = function (newConfig) {
    if (!newConfig) {
      return true;
    }

    if (!config) {
      return false;
    }

    return config.layerName === newConfig.layerName && config.template === newConfig.template;
  };

  var renderSorting = function () {
    tableSort.updateHeaders();
  };

  var activateOverflowTooltips = function () {
    $(".data-list-container .overflow-ellipses").tooltipOnOverflow();
  };

  /*
    Arguments:
      customListeners: [{target: ".your-class", callback: yourFunction}]
  */
  var loadDomListeners = function (customListeners = []) {
    if (!inited)
      throw Error(
        `DataList.loadDomListeners(${customListeners}) called without being initialized.`,
      );

    $container.off("click", "th.sortable");
    $container.on("click", "th.sortable", sortByClickedColumn);
    $container.off("click", "th.icon-col", sortButtonClick);
    $container.on("click", "th.icon-col", sortButtonClick);

    $container.off("click", ".dropdown-menu a", addAnalyticsToActionsDropdown);
    $container.on("click", ".dropdown-menu a", addAnalyticsToActionsDropdown);

    for (const listener of customListeners) {
      $container.off("click", listener.target);
      $container.on("click", listener.target, listener.callback);
    }
  };

  var hideDetailsButton = function () {
    $("#details-button-container .details-button").hide();
  };

  var showDetailsButton = function () {
    // If cond. keeps desktop version from getting details button when toggling between list and map
    var deviceWidth = NunjucksFunctions.getDeviceSize();
    if (deviceWidth === "mobile" || deviceWidth === "tablet") {
      $("#details-button-container .details-button").show();
    }
  };

  var showFilterSearchButton = function () {
    $("#floatingInputsCollapse").removeClass("collapse");
    $("#floatingInputsCollapse").addClass("collapse in");
  };

  var hideFilterSearchButton = function () {
    $("#floatingInputsCollapse").removeClass("collapse in");
    $("#floatingInputsCollapse").addClass("collapse");
  };

  var removeDesktopFilterSearchFeatureButtons = function () {
    //Add class to remove the extra filter/search icons mobile view data list as well as add all mobile filter/search styles
    $(".main-content").addClass("data-list-view");
  };

  var addDesktopFilterSearchFeatureButtons = function () {
    //Remove class to bring back the filter/search icons removed from mobile view data list
    $(".main-content").removeClass("data-list-view");
  };

  var addOpacityAboveMobileSearchFilterBar = function () {
    $("#details-button-container").addClass("opacity-above-search-filter-bar");
  };

  var removeOpacityAboveMobileSearchFilterBar = function () {
    $("#details-button-container").removeClass("opacity-above-search-filter-bar");
  };

  var constructionHideShowDetailsButtonListener = function () {
    const navToggleSelection = Tree.get("navToggleSelection");
    const activeTab = Tree.get("activeTab");
    const tool = Tree.get("tool");
    const deviceWidth = NunjucksFunctions.getDeviceSize();

    if (
      ["construction", "indcom", "muni"].includes(tool) &&
      activeTab === "data" &&
      (deviceWidth === "mobile" || deviceWidth === "tablet")
    ) {
      if (navToggleSelection === "list") {
        hideDetailsButton();
        $("#floatingInputsCollapse").removeClass(
          "search-filter-desktop-initial-click-mobile-expanded-sizing",
        );
        $("#floatingInputsCollapse").addClass("search-filter-click-initial-mobile-sizing");
        addOpacityAboveMobileSearchFilterBar();
        showFilterSearchButton();
        removeDesktopFilterSearchFeatureButtons();
      } else {
        showDetailsButton();
        $("#floatingInputsCollapse").removeClass("search-filter-click-initial-mobile-sizing");
        $("#floatingInputsCollapse").addClass(
          "search-filter-desktop-initial-click-mobile-expanded-sizing",
        );
        removeOpacityAboveMobileSearchFilterBar();
        hideFilterSearchButton();
        addDesktopFilterSearchFeatureButtons();
      }
    }
  };

  var unloadLayerListeners = function () {
    layerWatcher?.off("update", onLayerDataUpdate);
  };

  var loadLayerListeners = function () {
    unloadLayerListeners();
    layerWatcher = Tree.select(["layers", config.layerName, "data"]);
    layerWatcher.on("update", onLayerDataUpdate);
  };

  var onLayerDataUpdate = function () {
    render();
  };

  var loadTreeListeners = function () {
    Tree.select("navToggleSelection").on("update", function () {
      constructionHideShowDetailsButtonListener();
    });

    Tree.select("activeTab").on("update", function () {
      constructionHideShowDetailsButtonListener();
    });

    Tree.select("dataListView").on("update", function (e) {
      if (e.data.currentData === "data-list") {
        show();
      } else {
        hide();
      }
    });
  };

  var sortButtonClick = function () {
    updateMobileSortButtons();
  };

  var updateMobileSortButtons = function (showButtons = undefined) {
    if (showButtons !== undefined) {
      showButtons = !showButtons;
    }

    $container.find("thead th.sortable").toggleClass("hidden-xs hidden-sm", showButtons);
    $container.find("span.mobile-only").text(headerText);
  };

  var setSortHeaderTypeText = function ($newHeaderText) {
    headerText = $newHeaderText.text();
  };

  var sortByClickedColumn = function () {
    setSortHeaderTypeText($(this));
    const key = $(this).data("sortKey");
    tableSort.setSorting(key);
    updateMobileSortButtons(false);
    $scrollContainer.scrollTop(0);
    return render();
  };

  var setSorting = function (key, reversed) {
    tableSort.setSorting(key, reversed);
  };

  var sortWithDict = function (data, sortersDict) {
    tableSort.sortWithDict(data, sortersDict);
    sendFilterAnalytics(tableSort.getSorting().key);
  };

  var show = function () {
    $container.show();
  };

  var hide = function () {
    $container.hide();
  };

  var getIdFromRow = function ($element) {
    return $element.parents("tr").data("id");
  };

  var limitToMaxListSize = function (dataArray) {
    if (dataArray.length > maxRows) {
      dataArray = dataArray.slice(0, maxRows);
    }

    return dataArray;
  };

  var addAnalyticsToActionsDropdown = function () {
    const action = $(this).data("analyticsAction") || "not_tracked";
    Analytics.sendDataListEvent("dd:" + action);
  };

  var sendFilterAnalytics = function (filterOption) {
    Analytics.sendDataListEvent(`filter:${_shortenFilterNameText(filterOption)}`);
  };

  var addAnalyticsToMapIconOnAddress = function (invoked) {
    if (invoked) {
      Analytics.sendDataListEvent("address_to_map");
    }
  };

  var _shortenFilterNameText = function (filterOption) {
    if (!filterOption) {
      return "unkown";
    }
    return filterOption
      .replace("bmpName", "name")
      .replace("totalDepth", "depth")
      .replace("bmpDisplayType", "bmpType")
      .replace("treatmentCapacity", "capacity")
      .replace("lineServiceType", "serviceType")
      .replace("openChannelType", "channelType")
      .replace("cof_final_text", "cof")
      .replace("plu_treated", "pluArea")
      .replace("facilityIdentifier", "facilityId")
      .replace("lastInspectionDate", "inspectionDate")
      .replace("projectIdentifier", "id")
      .replace("projectName", "name")
      .replace("streetAddress", "address")
      .replace("highPriority", "priority")
      .replace("startDateIso", "startDate")
      .replace("disturbanceArea", "disturbance")
      .replace("lastActionType", "actionType")
      .replace("lastActionDate", "actionDate")
      .replace("responseDueDate", "dueDate")
      .replace("imperviousAreaDisplay", "impervious")
      .replace("displayOutfallType", "type")
      .replace("displayOutletType", "outletType");
  };

  var scrollToId = function (id) {
    if (!list || !Array.isArray(rowsData)) {
      return;
    }

    const i = setHighlightRowByIdAndGetIndex(id);

    if (i === null) {
      return;
    }

    list.scrollToIndex(i, true);
  };

  var setHighlightRowByIdAndGetIndex = function (id) {
    let idIndex = null;
    let rowIdName;

    if (rowsData.length) {
      rowIdName = getRowIdName(rowsData[0]);
    }

    for (const i in rowsData) {
      const row = rowsData[i];
      const isRowWithId = row[rowIdName] === id;
      row.highlight = isRowWithId;

      if (isRowWithId) {
        idIndex = i;
      }
    }

    return idIndex;
  };

  var getRowIdName = function (row) {
    if ("id" in rowsData[0]) {
      return "id";
    } else if ("idBmp" in rowsData[0]) {
      // @TODO: remove when we update the FCS layer
      return "idBmp";
    } else {
      throw new Error(`Row does not have an ID`);
    }
  };

  var reset = function () {
    invalidateList();
    unloadLayerListeners();
    config = null;
    inited = false;
    $container = null;
    $scrollContainer = null;
    tableSort = new TableSort();
    headerText = "ID";
    layerWatcher = null;
    extraData = null;
    list = null;
    rowsData = null;
  };

  var _getTableSort = function () {
    return tableSort;
  };

  return {
    init,
    constructionHideShowDetailsButtonListener,
    render,
    invalidateList,
    loadDomListeners,
    sortWithDict,
    setSorting,
    show,
    hide,
    getIdFromRow,
    limitToMaxListSize,
    addAnalyticsToMapIconOnAddress,
    renderSorting,
    activateOverflowTooltips,
    loadLayerListeners,
    scrollToId,
    reset,
    _getTableSort,
  };
};

module.exports = DataList();

const NunjucksFunctions = require("../general/nunjucksFunctions.js");
const Filters = require("./filters.js");
const Tree = require("../tree");
const Session = require("../login/session");
const Analytics = require("../general/analytics.js");
const FilterSummary = require("../filters/filterSummary");
const NavToggle = require("./navToggle.js");
const Misc = require("../misc.js");
const TableSort = require("../general/tableSort");
