"use strict";

function CachedObjectStore(databaseInstance, objectStoreName, keyPathString = "id") {
  const db = databaseInstance;
  const storeName = objectStoreName;
  const keyPath = keyPathString;
  const cacheMap = new Map();
  const dbUpdateJobQueue = new JobQueue();
  let cacheHydratePromise = null;

  async function get(key) {
    _assertValidKey(key);
    await _hydrateIfStale();

    return cacheMap.get(key);
  }

  async function getAll() {
    await _hydrateIfStale();

    return [...cacheMap.values()];
  }

  async function count() {
    if (await _awaitCacheIfHydrating()) {
      return cacheMap.size;
    } else {
      return (await _dbCount()) ?? cacheMap.size;
    }
  }

  async function getAllKeys() {
    if (await _awaitCacheIfHydrating()) {
      return [...cacheMap.keys()];
    } else {
      return (await _dbGetAllKeys()) ?? [...cacheMap.keys()];
    }
  }

  function put(data) {
    if (!Array.isArray(data)) {
      data = [data];
    }

    for (const datum of data) {
      _singlePut(datum);
    }
    dbUpdateJobQueue.add(() => _dbPut(data));
  }

  function _singlePut(data) {
    _assertHasKey(data);
    const key = data[keyPath];
    _assertValidKey(key);

    cacheMap.set(key, Misc.deepFreezeObject(data));
  }

  function replaceAll(data) {
    clear();
    put(data);
  }

  function clear() {
    cacheMap.clear();

    _setCacheHydrated();
    dbUpdateJobQueue.cancel();
    dbUpdateJobQueue.add(() => _dbClear());
  }

  function deleteFn(keys) {
    if (!Array.isArray(keys)) {
      keys = [keys];
    }

    for (const key of keys) {
      _singleDelete(key);
    }

    dbUpdateJobQueue.add(() => _dbDelete(keys));
  }

  function _singleDelete(key) {
    _assertValidKey(key);
    cacheMap.delete(key);
  }

  function _assertHasKey(data) {
    if (!data || typeof data !== "object" || !(keyPath in data)) {
      throw new DatabaseError(
        `Data (${typeof data}) does not contain key "${keyPath}"`,
        db.getName(),
      );
    }
  }

  function _assertValidKey(key) {
    if (key === "" || key === null || key === undefined) {
      throw new DatabaseError(`Invalid key ${typeof key}`, db.getName());
    }
  }

  function _cacheIsHydrated() {
    return cacheHydratePromise === true;
  }

  async function _awaitCacheIfHydrating() {
    if (_cacheIsHydrated()) {
      return true;
    } else if (cacheHydratePromise) {
      await cacheHydratePromise;
      return true;
    }

    return false;
  }

  function _setCacheHydrated() {
    cacheHydratePromise = true;
  }

  async function _hydrateIfStale() {
    if (_cacheIsHydrated()) {
      return;
    } else if (cacheHydratePromise === null) {
      cacheHydratePromise = _hydrateCacheFromDb();
    }

    await cacheHydratePromise;
  }

  async function _hydrateCacheFromDb() {
    await getDbDonePromise();

    const allData = await _dbGetAll();

    if (allData) {
      cacheMap.clear();

      for (const data of allData) {
        cacheMap.set(data[keyPath], Object.freeze(data));
      }
    }

    _setCacheHydrated();
  }

  async function _dbGetAll() {
    try {
      const transaction = await _getDbObjectStoreTransaction();

      return await transaction.getAll();
    } catch (e) {
      console.error(e);
    }
  }

  async function _dbCount() {
    try {
      const transaction = await _getDbObjectStoreTransaction();

      return await transaction.count();
    } catch (e) {
      console.error(e);
    }
  }

  async function _dbGetAllKeys() {
    try {
      const transaction = await _getDbObjectStoreTransaction();

      return await transaction.getAllKeys();
    } catch (e) {
      console.error(e);
    }
  }

  async function _dbPut(data) {
    try {
      await db.backgroundBulkWriteOperation(storeName, data, (store, datum) => store.put(datum));
    } catch (e) {
      console.error(e);
    }
  }

  async function _dbDelete(keys) {
    try {
      await db.backgroundBulkWriteOperation(storeName, keys, (store, key) => store.delete(key));
    } catch (e) {
      console.error(e);
    }
  }

  async function _dbClear() {
    try {
      const transaction = await _getDbObjectStoreTransaction(true);
      await transaction.clear();
    } catch (e) {
      console.error(e);
    }
  }

  function getDbDonePromise() {
    return dbUpdateJobQueue.getDonePromise();
  }

  function _getDbUpdateJobQueue() {
    return dbUpdateJobQueue;
  }

  function _getDb() {
    return db;
  }

  function _getDbObjectStoreTransaction(...args) {
    return db.getObjectStoreTransaction(storeName, ...args);
  }

  return {
    put,
    get,
    delete: deleteFn,
    count,
    replaceAll,
    getAll,
    getAllKeys,
    clear,
    getDbDonePromise,
    _getDbUpdateJobQueue,
    _getDb,
    _getDbObjectStoreTransaction,
    _dbGetAll,
  };
}

module.exports = CachedObjectStore;

const DatabaseError = require("../errors/databaseError");
const Misc = require("../misc");
const JobQueue = require("../misc/jobQueue");
