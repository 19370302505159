"use strict";

const NewProjectInspectionConstants = function () {
  var getConstants = function (key) {
    return ToolSettings.getSetting("construction", "project-inspection-constants")?.[key];
  };

  var getInspectionTypeOptions = function () {
    let options = [
      {
        name: "Pre-Disturbance",
        value: "pre-disturbance",
      },
      {
        name: "Routine",
        value: "routine",
      },
      {
        name: "Pre-Storm",
        value: "pre-storm",
      },
      {
        name: "Post-Storm",
        value: "post-storm",
      },
      {
        name: "Follow Up",
        value: "follow-up",
      },
      {
        name: "Close-Out",
        value: "close-out",
      },
    ];

    if (ToolSettings.getSetting("construction", "is-findings-workflow")) {
      options = options
        .filter((option) => option.value !== "pre-storm")
        .concat([
          {
            name: "Winter Conditions",
            value: "winter-conditions",
          },
        ]);
    }

    return options;
  };

  var getPrecipitationNowOptions = function () {
    return [
      {
        name: "No Precipitation",
        value: "none",
      },
      {
        name: "Light Precipitation",
        value: "some",
      },
      {
        name: "Heavy Precipitation",
        value: "heavy",
      },
      {
        name: "Snow Covering Entire Site",
        value: "snow",
      },
    ];
  };

  var getRequiredObservationConditionOptions = function () {
    return [
      { name: "No Issues (Score = 1.0)", value: "10" },
      { name: "Minor Issues (Score = 0.8)", value: "8" },
      { name: "Significant Issues (Score = 0.5)", value: "5" },
      { name: "Failing/Absent/Wrong Location (Score = 0.0)", value: "0" },
    ];
  };

  var getNewProjectInspectionDefaults = function (inspectionData) {
    inspectionData.inspectorName = Session.getUser()["full_name"];
    inspectionData.startTime = DateTime.getNowIso();

    inspectionData.protectedFromConstruction = "n-a";
    inspectionData.additionalObservations = {};
    inspectionData.administrativeFindings = {};
    inspectionData.observationFindings = {};
    inspectionData.signatures = {
      inspectorName: inspectionData.inspectorName,
      inspectorDate: DateTime.getTodayIso(),
      siteRepDate: DateTime.getTodayIso(),
    };
    inspectionData.locked = false;

    return inspectionData;
  };

  var prepareInspectionData = function (data) {
    data.projectId = data.project?.id;
    mergeOpenFindings(data);
    indexObservations(data);

    return {
      ...data,
      ...getObservationsData(data),
    };
  };

  var getObservationsData = function (data) {
    const projectInspectionConstants = ToolSettings.getSetting(
      "construction",
      "project-inspection-constants",
    );
    const inspectionObservationConstants =
      projectInspectionConstants.inspectionObservationConstants;
    const observationCategoryOptions = ToolSettings.getDomainOptions("observation_category");

    const observationConstantsMap = getObservationConstantsMap(
      inspectionObservationConstants,
      observationCategoryOptions,
    );
    const openFindingsConstantsMap = getOpenFindingsConstantsMap(
      data,
      observationConstantsMap,
      observationCategoryOptions,
    );

    return {
      observationConstantsMap: observationConstantsMap,
      openFindingsConstantsMap: openFindingsConstantsMap,
      startedCategories: getStartedCategories(
        inspectionObservationConstants,
        observationCategoryOptions,
        data.inspectionObservations,
      ),
      customCompliantRadioLabels: getCustomCompliantRadioLabels(),
    };
  };

  var getObservationConstantsMap = function (
    inspectionObservationConstants,
    observationCategoryOptions,
  ) {
    const grouped = new Map();
    inspectionObservationConstants.forEach(function (constant) {
      constant.displayObservationCategory = Misc.getOptionNameByValue(
        observationCategoryOptions,
        constant.observationCategory,
      );
      pushInspectionObservationConstantToMap(grouped, constant);
    });

    const sortedMap = sortObservationConstantsMap(grouped);

    return sortedMap;
  };

  var sortObservationConstantsMap = function (map) {
    const mapArray = [...map.entries()];

    const illicitDischargeIndex = mapArray.findIndex((item) => item[0] === "Illicit Discharge");
    if (illicitDischargeIndex !== -1) {
      const illicitDischargeItem = mapArray.splice(illicitDischargeIndex, 1)[0];
      mapArray.unshift(illicitDischargeItem);
    }

    const siteDocumentsIndex = mapArray.findIndex((item) => item[0] === "Site Documents");
    if (siteDocumentsIndex !== -1) {
      const siteDocumentsItem = mapArray.splice(siteDocumentsIndex, 1)[0];
      mapArray.unshift(siteDocumentsItem);
    }

    const sbmpIndex = mapArray.findIndex((item) => item[0] === "Structural BMPs");
    if (sbmpIndex !== -1) {
      const sbmpItem = mapArray.splice(sbmpIndex, 1)[0];
      mapArray.push(sbmpItem);
    }

    return new Map(mapArray);
  };

  var getStartedCategories = function (
    inspectionObservationConstants,
    observationCategoryOptions,
    inspectionObservations,
  ) {
    const locations = new Set();
    if (!inspectionObservations) return [];
    inspectionObservationConstants.forEach(function (constant) {
      if (Object.keys(inspectionObservations).includes(String(constant.id))) {
        const displayObservationCategory = Misc.getOptionNameByValue(
          observationCategoryOptions,
          constant.observationCategory,
        );
        locations.add(displayObservationCategory);
      }
    });
    return Array.from(locations);
  };

  var getCustomCompliantRadioLabels = function () {
    if (Session.isSalinas()) {
      return ["Compliant", "Non-Compliant", "N/A"];
    } else if (Session.isCdot()) {
      return ["No", "Yes", "N/A"];
    }
    return ["Yes", "No", "N/A"];
  };

  var getOpenFindingsConstantsMap = function (
    data,
    observationConstantsMap,
    observationCategoryOptions,
  ) {
    const grouped = new Map();
    Object.keys(data?.openFindings ?? []).forEach(function (openFindingKey) {
      const constant = getConstantById(parseInt(openFindingKey));
      constant.displayObservationCategory = Misc.getOptionNameByValue(
        observationCategoryOptions,
        constant.observationCategory,
      );

      pushInspectionObservationConstantToMap(grouped, constant);
      updateExistingInspectionObservation(openFindingKey, constant, observationConstantsMap);
    });

    const sortedMap = sortObservationConstantsMap(grouped);

    return sortedMap;
  };

  var getConstantById = function (id) {
    const constants = ToolSettings.getSetting("construction", "project-inspection-constants");
    return (
      constants?.inspectionObservationConstants?.find((constant) => constant.id === id) ||
      constants?.historicalObservationConstants?.find((constant) => constant.id === id)
    );
  };

  var mergeOpenFindings = function (data) {
    for (const constantId in data.openFindings) {
      if (constantId in data.inspectionObservations) {
        const findings = data.openFindings[constantId];
        const observations = data.inspectionObservations[constantId];

        findings.forEach(function (finding, index) {
          const obsIndex = observations.findIndex(
            (obs) => obs.findingsCode === finding.findingsCode,
          );
          if (obsIndex !== -1) {
            const obsToMove = observations.splice(obsIndex, 1)[0];
            obsToMove.previousNotes = finding.notes;
            findings[index] = obsToMove;
          }
        });
      }
    }
  };

  var updateExistingInspectionObservation = function (
    openFindingKey,
    constant,
    observationConstantsMap,
  ) {
    const observationConstants = observationConstantsMap.get(constant.displayObservationCategory);
    const index = observationConstants?.findIndex((x) => x.id === parseInt(openFindingKey));
    if (observationConstants && index !== -1) {
      const constant = observationConstants[index];
      if (!constant.multipleObservations) {
        observationConstants.splice(index, 1);
      }
    }
  };

  var pushInspectionObservationConstantToMap = function (map, constant, validTypes = null) {
    const displayObservationCategory = constant.displayObservationCategory;

    if (!map.has(displayObservationCategory)) {
      map.set(displayObservationCategory, []);
    }

    if (validTypes === null) {
      validTypes = constant.validInspectionTypes.join(" ");
    }

    const observationConstants = map.get(displayObservationCategory);
    observationConstants.push(
      $.extend(true, {}, constant, {
        constantDisplayText: constant.display,
        validInspectionTypes: validTypes,
      }),
    );
  };

  var indexObservations = function (data) {
    const inspectionObservationKeys = Object.keys(data.inspectionObservations ?? {}) || [];
    const openFindingKeys = Object.keys(data.openFindings ?? {}) || [];
    const allKeys = [...new Set(openFindingKeys.concat(inspectionObservationKeys))];

    allKeys.forEach(function (key) {
      const inspectionObservationByKeyArray = data.inspectionObservations?.[key] || [];
      const openFindingByKeyArray = data.openFindings?.[key] || [];
      const allObservationsByKeyArray = openFindingByKeyArray.concat(
        inspectionObservationByKeyArray,
      );

      indexObservationArray(allObservationsByKeyArray);
    });
  };

  var indexObservationArray = function (observationArray) {
    let lastDisplayNumber = observationArray.reduce(
      (num, obs) => Math.max(num, obs.displayNumber ?? 0),
      0,
    );

    observationArray.forEach(function (observation, i) {
      observation.index = i;
      if (!observation.displayNumber) {
        observation.displayNumber = lastDisplayNumber + 1;
      }
      lastDisplayNumber = observation.displayNumber;
    });
  };

  return {
    getConstants,
    getConstantById,
    getInspectionTypeOptions,
    getPrecipitationNowOptions,
    getRequiredObservationConditionOptions,
    getNewProjectInspectionDefaults,
    getObservationConstantsMap,
    getOpenFindingsConstantsMap,
    indexObservationArray,
    prepareInspectionData,
    mergeOpenFindings,
  };
};

module.exports = NewProjectInspectionConstants();

const DateTime = require("../../dateTime");
const Misc = require("../../misc");
const Session = require("../../login/session");
const ToolSettings = require("../../settings/toolSettings");
