"use strict";

L.Control.PolygonSelect = L.Control.extend({
  onAdd: function (map) {
    const html = L.DomUtil.create("div", "polygon-select");
    html.innerHTML = nunjucks.render("maps/modal/modalMapPolygonSelect.njk");
    L.DomEvent.disableClickPropagation(html);
    map.polygonSelect = this;
    return html;
  },
  onRemove: function (map) {
    delete map.polygonSelect;
  },
  options: {
    position: "topright",
  },
});
