"use strict";

const GlobalGroupSettings = function () {
  var getGlobalSettings = function (reportingYear = null) {
    var data = Tree.get("globalGroupSettings", "data");

    if (reportingYear) {
      data = _getYear(data, reportingYear);
    }

    return data;
  };

  var _getYear = function (data, reportingYear) {
    return data.find(function (settings) {
      return settings.reportingYear === reportingYear;
    });
  };

  var loadGlobalSettings = async function () {
    var globalGroupSettings = Tree.get("globalGroupSettings");

    if (!globalGroupSettings.data.length && !globalGroupSettings.isFetching) {
      Tree.set(["globalGroupSettings", "isFetching"], true);
      globalGroupSettings.data = await ReportApiCalls.getGlobalGroupSettings();
      Tree.set(["globalGroupSettings", "isFetching"], false);
      Tree.set(["globalGroupSettings", "data"], globalGroupSettings.data);
    }

    return globalGroupSettings.data;
  };

  var editGlobalSettings = async function (formData) {
    const data = await ReportApiCalls.editGlobalGroupSettings(formData);
    Tree.set(["globalGroupSettings", "data"], data.settings);

    AnnualReports.updateMs4Years(data.reports);

    return data;
  };

  return { getGlobalSettings, loadGlobalSettings, editGlobalSettings };
};

module.exports = GlobalGroupSettings();

const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const AnnualReports = require("../annualReports");
