"use strict";

const BulkSelectModalController = function () {
  var loadListeners = function () {
    Tree.select(["bulkSelect", "selectedIds"]).on("update", selectedIdsUpdate);
  };

  var invokeBulkSelectModal = async function (actionKey) {
    const startingPage = "bulk-select-page";
    Tree.set(["bulkSelect", "actionKey"], actionKey);
    return initializeBulkSelectModalPages(startingPage, actionKey);
  };

  var invokeCatchBasinBulkCleanModal = function () {
    invokeBulkSelectModal("catchBasinBulkClean");
  };

  var initializeBulkSelectModalPages = async function (startingPage, actionKey) {
    const BulkSelectPage = require("./bulkSelectPage");
    const inputPages = [BulkSelectPage];

    inputPages.forEach((page) => {
      if (page.resetState) {
        page.resetState();
      }
    });

    await InventoryModal.showModal(
      inputPages,
      startingPage,
      { actionKey },
      {
        saveAndCloseHandler: saveAndCloseModal,
        cancelHandler: resetBulkSelectPage,
        formHasUpdatesHandler: handleHasUpdates,
      },
    );
    InventoryModal.disableSaveButton();

    hideLegendLayer();
    loadListeners();
  };

  var renderPageContent = function (html) {
    InventoryModal.setModalDynamicContent(html);
  };

  var setModalTitle = function (title) {
    InventoryModal.setModalTitle(title);
  };

  var saveAndCloseModal = async function () {
    try {
      var actionKey = Tree.get(["bulkSelect", "actionKey"]);
      var date = DateTime.truncateMilliseconds(
        $("input.date-picker[name='bulk-select-date']").val(),
      );
      Tree.set(["bulkSelect", "date"], date);
      var apiCallHandler = BulkSelectFunctions.getApiCallHandler(actionKey);
      await apiCallHandler();
      refreshLayerAndHideInventoryModal();
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  };

  var refreshLayerAndHideInventoryModal = function () {
    var actionKey = Tree.get(["bulkSelect", "actionKey"]);
    var refreshLayerHandler = BulkSelectFunctions.getRefreshLayerHandler(actionKey);
    refreshLayerHandler();
    resetBulkSelectPage();
    InventoryModal.hide();
  };

  var goToPage = function (stringKey, options) {
    InventoryModal.restoreButtonsToActive();
    InventoryModal.setActivePageByKey(stringKey, options);
  };

  var resetBulkSelectPage = function () {
    const BulkSelectTable = require("./bulkSelectTable");
    BulkSelectTable.clearBulkSelectTable();
    BulkSelectTable.resetSavedSelectedId();
    showLegendLayer();
  };

  var handleHasUpdates = function () {
    var selectedIds = Tree.get(["bulkSelect", "selectedIds"]);
    return !!selectedIds.length;
  };

  var checkEnableSaveButton = function (selectedIds) {
    if (selectedIds.length) {
      InventoryModal.readySaveState(true);
      InventoryModal.allowedSaveState(true);
    } else {
      InventoryModal.readySaveState(false);
      InventoryModal.allowedSaveState(false);
    }
  };

  var selectedIdsUpdate = function (e) {
    var selectedIds = e.data.currentData;
    checkEnableSaveButton(selectedIds);
  };

  var hideLegendLayer = function () {
    $("#modalMap .bottom-layers-div,  #modalMap .bottom-legend-div").css("visibility", "hidden");
  };

  var showLegendLayer = function () {
    $("#modalMap .bottom-layers-div,  #modalMap .bottom-legend-div").css("visibility", "visible");
  };

  return {
    invokeCatchBasinBulkCleanModal,
    renderPageContent,
    setModalTitle,
    goToPage,
    saveAndCloseModal,
  };
};

module.exports = BulkSelectModalController();

const Tree = require("../tree");
const InventoryModal = require("../general/inventoryModal");
const DateTime = require("../dateTime");
const BulkSelectFunctions = require("./bulkSelectFunctions");
const UploadValidator = require("../general/uploadValidator");
