"use strict";

const RegionalView = function () {
  var changeActiveGroup = function (groupId) {
    MapFunctions.setAllMapExtentsNotInitialized();
    MapFunctions.resetAllMapSpatialViewsToMs4();
    DisplayOptions.resetRadios();
    Table.resetSelectedTree();
    MapFunctions.clearLayersForAllMapIds();
    Progeny.setActiveGroupById(groupId);
    Filters.closeAll();

    if (Progeny.activeHasProgeny()) {
      changeToRegionalGroup(groupId);
    } else {
      changeToMs4Group(groupId);
    }

    if (Session.isCdot()) {
      Actions.loadHighways(Tree.get("mapId"));
    }
  };

  var changeToMs4Group = function (groupId) {
    Tree.set(["filters", "dataSort"], "rw");
    Actions.loadAnnualReporting().then(() => {
      const mapId = Tree.get("mapId");
      if (Tabs.activeTabHasMap()) Tree.set(["firstLoad", mapId], true);
      setRegionalViewMs4Dom();
      return PageFunctions.goToPage(Tree.get("activeTab"));
    });
  };

  var changeToRegionalGroup = function (groupId) {
    setRegionalViewDom();
    const newProgeny = Progeny.getActiveGroupProgeny();
    if (Progeny.activeGroupHasGrandprogeny() && !Progeny.rootGroupIsRegulator()) {
      DataViewFunctions.setDataView("regionPointView");
    }

    setRegionalViewDom();
    AnnualReports.setRegionalYears(newProgeny);
    YearDropdown.setYearDropdown();
    const targetTab = Tabs.activeTabHasMap() ? Tree.get("activeTab") : "submit";
    PageFunctions.goToPage(targetTab);
  };

  var setRegionalViewDom = function () {
    $(".ms4-view-dropdown").hide();
    $(".regional-view-dropdown").show();
    if (Progeny.activeGroupIsRegulator()) {
      $(".floating-legend").hide();
    } else {
      $(".floating-legend").show();
    }
    $("input:checkbox[value=bmps]").prop("checked", false);
    $("input:checkbox[value=streams]").prop("checked", false);
    $(".filter-bar").hide();
    $("#floating-inputs-header-table").hide();
    $("#groupNameHeader").show();
    ToolNavTitle.set(Tree.get("activeGroup", "name"));
  };

  var setRegionalViewMs4Dom = function () {
    $(".floating-legend").show();
  };

  return {
    changeActiveGroup,
    setRegionalViewDom,
    setRegionalViewMs4Dom,
  };
};

module.exports = RegionalView();

const Tree = require("../tree");
const Actions = require("./actions");
const Progeny = require("../login/progeny");
const Session = require("../login/session");
const AnnualReports = require("./annualReports");
const PageFunctions = require("./pageFunctions");
const ToolNavTitle = require("./toolNavTitle");
const DataViewFunctions = require("./dataViewFunctions");
const YearDropdown = require("./mapFunctions/yearDropdown");
const Filters = require("./mapFunctions/filters");
const MapFunctions = require("./mapFunctions/mapFunctions");
const DisplayOptions = require("./mapFunctions/displayOptions");
const Table = require("./mapFunctions/table");
const Tabs = require("./tabs");
