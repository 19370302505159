"use strict";

const AssetsCleanoutFilters = function () {
  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {};

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    AssetManagementFilters.addGeoServerFilterQuery(queryStrings, filters);

    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings, [
      { key: "drainsToRw", field: "drains_to_rw" },
      { key: "drainsToC", field: "drains_to_c" },
      { key: "catchmentName", field: "catchment_name" },
    ]);
  };

  var addGeoServerViewParams = function (params, filters) {};

  var getFilterConfigs = function () {
    return {};
  };

  var loadListeners = function () {};

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
    addGeoServerViewParams,
    getFilterConfigs,
    loadListeners,
  };
};

module.exports = AssetsCleanoutFilters();

const GeoServerFilterFunctions = require("../../mapFunctions/geoServerFilterFunctions");
const AssetManagementFilters = require("./assetManagementFilters");
