"use strict";

var TreeUpdates = function () {
  var getInputsMap = null;
  var getReportMap = null;
  var getPlanMap = null;
  var inputsMapLayers = null;
  var reportMapLayers = null;
  var planMapLayers = null;

  var turnOnUpdates = function (mapLayers, getActiveMap, getCurrentBasemap, mapId) {
    setCurrentMapGetter(getActiveMap);
    setCurrentMapLayers(mapLayers);

    //Listen for changes to and from Catchment View
    Tree.select("spatialView", mapId).on("update", function (e) {
      const currentView = e.data.currentData;
      setSentrySpatialView(currentView);

      const $thisMap = PageFunctions.getCurrentPage();
      const $fullDrainageCheckbox = $thisMap
        .find("input[type='radio'][value='fullDrainage']")
        .closest(".checkbox");
      const $runoffRateCheckbox = $thisMap
        .find("input[type='radio'][value='runoff']")
        .closest(".checkbox");

      if (currentView === "catchmentView" || currentView === "drainageView") {
        $fullDrainageCheckbox.addClass("disabled");
        $runoffRateCheckbox.addClass("disabled");
      } else {
        $fullDrainageCheckbox.removeClass("disabled");
        $runoffRateCheckbox.removeClass("disabled");
      }
    });

    // @TODO: set up filters update listeners by layer, dataView and/or mapId
    Tree.select("filters").on("update", function (e) {
      const mapId = Tree.get("mapId");
      var currentMapLayers = getMapLayers(mapId);
      Actions.selectCatchmentsByFilter(currentMapLayers);

      if (Progeny.activeHasProgeny()) {
        return;
      }

      if (mapId === "plan") {
        handleUntreatedPluPercentileFilter();
      }

      refreshAllLayers(mapId);
    });

    Tree.select("selected").on("update", function (e) {
      const mapId = Tree.get("mapId");
      if (Tabs.activeTabHasMap()) {
        const currentMapLayers = getMapLayers(mapId);
        Actions.selectCatchmentsByFilter(currentMapLayers);
        MapTitle.setToSpatialTitle();
      }
    });

    Tree.select(["basemap", mapId]).on("update", function (e) {
      const currentMap = getMap(mapId);
      // let currentBasemap = getCurrentBasemap();
      const currentBasemap = mapLayers.basemap;
      mapLayers.basemap = MapFunctions.addBasemap(currentMap, currentBasemap, e.data.currentData);
    });

    /**
     * Unlike other layers, the catchments layer stores unprocessed data
     * from the API. It must be transformed to geoJson before adding to the map.
     */
    Tree.select(["layers", mapId, "catchments", "data"]).on("update", function (e) {
      var layers = Tree.get("layers", mapId);
      if (!layers.catchments.isEnabled || layers.catchments.isFetching) {
        return;
      }

      var currentMap = getMap(mapId);
      var currentMapLayers = getMapLayers(mapId);
      var catchments = e.data.currentData;
      var receivingWaters = Array.from(
        new Set(catchments.map((catchment) => catchment.drains_to_rw)),
      );
      var catchmentIds = catchments.map(function (catchment) {
        return catchment.catchid;
      });
      catchmentIds.sort(Misc.alphanum);

      currentMapLayers.catchmentLayer = MapFunctions.addCatchLayer(
        currentMap,
        currentMapLayers.catchmentLayer,
        catchments,
      );
      MapFunctions.zoomToCatchmentLayer(currentMap, currentMapLayers.catchmentLayer);
      if (!DataViewFunctions.isTelrDataViewInNonInputsMap()) {
        Table.render();
        // Else, the table will be rendered by the TELR layer on load.
      }
      Actions.selectCatchmentsByFilter(currentMapLayers);
      $(".filter-dropdown .scrollable").remove();
      $(".filter-dropdown")
        .filter('[data-filter="watersheds"]')
        .append(nunjucks.render("report/table/filterDropdown.html", { filters: receivingWaters }));
      $(".filter-dropdown")
        .filter('[data-filter="catchments"]')
        .append(nunjucks.render("report/table/filterDropdown.html", { filters: catchmentIds }));
    });

    Tree.select(["layers", mapId, "highways", "data"]).on("update", function (e) {
      var currentMap = getMap(mapId);
      var currentMapLayers = getMapLayers(mapId);
      var highways = e.data.currentData;

      currentMapLayers.highwayLayer = MapFunctions.addHighwayLayer(
        currentMap,
        currentMapLayers.highwayLayer,
        highways,
      );
    });

    Tree.select(["filters", "dataSort"]).on("update", function (e) {
      const mapId = Tree.get("mapId");
      const filters = Tree.get("filters");
      const catchmentSelected = filters.catchments.length;
      const spatialView = Tree.get("spatialView", mapId);
      // do not re-render table if already in catchment
      if (!catchmentSelected || spatialView === "catchmentView") {
        Table.render();
      }
    });

    // ----------------------------------------------------------------------
    // List to change notifications when layer data is updated
    // ----------------------------------------------------------------------

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "parcelTiles", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.parcelTiles = MapFunctions.addParcelTileLayer(
        currentMap,
        currentMapLayers.parcelTiles,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "landuse", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.landuseLayer = MapFunctions.addLanduseLayer(
        currentMap,
        currentMapLayers.landuseLayer,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "streams", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.rwLayer = MapFunctions.addStreamLayer(
        currentMap,
        currentMapLayers.rwLayer,
        data,
      );
    });

    $(`#${mapId}`).on("click", ".open-ms4", (e) => {
      const groupId = $(e.currentTarget).data("groupId");
      RegionalView.changeActiveGroup(groupId);
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "trashLines", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.trashLineLayer = MapFunctions.addTrashLinesLayer(
        currentMap,
        currentMapLayers.trashLineLayer,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "trashPoints", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.trashPointLayer = MapFunctions.addTrashPointsLayer(
        currentMap,
        currentMapLayers.trashPointLayer,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "bmps", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.bmpLayer = MapFunctions.addBmpLayer(
        currentMap,
        currentMapLayers.bmpLayer,
        data,
      );
      Clustering.setState("bmps");
      SbmpProgressLayer.loadZoomListener(currentMap, currentMapLayers.bmpLayer);
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "fcs", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.fcsLayer = MapFunctions.addFcsLayer(
        currentMap,
        currentMapLayers.fcsLayer,
        data,
      );
      Clustering.setState("fcs");
      FcsProgressLayer.loadZoomListener(currentMap, currentMapLayers.fcsLayer);
    });

    //fullDrainage layer is handled differently
    Tree.select("layers", mapId, "fullDrainage", "data").on("update", function () {
      var data = Tree.get("layers", mapId, "fullDrainage", "data");
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.fullDrainageLayer = MapFunctions.addFullDrainageLayer(
        currentMap,
        currentMapLayers.fullDrainageLayer,
        data,
      );
      currentMapLayers.fullDrainageLayer.addTo(currentMap);
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "ms4Boundary", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.ms4BoundaryLayer = MapFunctions.addMs4BoundaryLayer(
        currentMap,
        currentMapLayers.ms4BoundaryLayer,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "stormdrains", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.baseStormdrainLayer = MapFunctions.addBaseStormdrainLayer(
        currentMap,
        currentMapLayers.baseStormdrainLayer,
        data,
      );
      currentMapLayers.middleStormdrainLayer = MapFunctions.addMiddleStormdrainLayer(
        currentMap,
        currentMapLayers.middleStormdrainLayer,
        data,
      );
      currentMapLayers.stormdrainLayer = MapFunctions.addStormdrainLayer(
        currentMap,
        currentMapLayers,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "trashLines", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.trashLineLayer = MapFunctions.addTrashLinesLayer(
        currentMap,
        currentMapLayers.trashLineLayer,
        data,
      );
      currentMapLayers.collectorLineOutlineLayer = MapFunctions.addCollectorLineOutlineLayer(
        currentMap,
        currentMapLayers,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated(Tree, mapId, "trashPoints", function (data) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);
      currentMapLayers.trashPointLayer = MapFunctions.addTrashPointsLayer(
        currentMap,
        currentMapLayers.trashPointLayer,
        data,
      );
    });

    // ----------------------------------------------------------------------
    // List to change notifications when layer is toggled
    // ----------------------------------------------------------------------

    MapFunctions.whenLayerToggled(
      mapId,
      "catchments",
      function (catchmentsIsEnabled, sameSpatialFilter) {
        const currentMap = getMap(mapId);
        if (catchmentsIsEnabled) {
          if (mapLayers.catchmentLayer && sameSpatialFilter) {
            currentMap.addLayer(mapLayers.catchmentLayer);
          } else {
            Actions.loadCatchments(mapId);
          }
        } else {
          if (mapLayers.catchmentLayer !== null) {
            currentMap.removeLayer(mapLayers.catchmentLayer);
          }
        }
      },
    );

    MapFunctions.whenLayerToggled(
      mapId,
      "highways",
      function (highwaysIsEnabled, sameSpatialFilter) {
        const currentMap = getMap(mapId);
        if (highwaysIsEnabled) {
          if (mapLayers.highwayLayer && sameSpatialFilter) {
            currentMap.addLayer(mapLayers.highwayLayer);
          } else {
            Actions.loadHighways(mapId);
          }
        } else {
          if (mapLayers.highwayLayer !== null) {
            currentMap.removeLayer(mapLayers.highwayLayer);
          }
        }
      },
    );

    MapFunctions.whenLayerToggled(
      mapId,
      "parcelTiles",
      function (parcelsIsEnabled, sameSpatialFilter) {
        const currentMap = getMap(mapId);
        if (parcelsIsEnabled) {
          if (mapLayers.parcelTiles && sameSpatialFilter) {
            currentMap.addLayer(mapLayers.parcelTiles);
          } else {
            Actions.loadParcelCanvasLayer();
          }
        } else {
          currentMap.removeLayer(mapLayers.parcelTiles);
        }
      },
    );

    MapFunctions.whenLayerToggled(mapId, "landuse", function (landuseIsEnabled, sameSpatialFilter) {
      const currentMap = getMap(mapId);
      if (landuseIsEnabled) {
        if (mapLayers.landuseLayer && sameSpatialFilter) {
          currentMap.addLayer(mapLayers.landuseLayer);
        } else {
          Actions.loadLanduse();
        }
      } else {
        currentMap.removeLayer(mapLayers.landuseLayer);
      }
    });

    MapFunctions.whenLayerToggled(
      mapId,
      "trashLines",
      function (trashLinesIsEnabled, sameSpatialFilter) {
        const currentMap = getMap(mapId);
        if (trashLinesIsEnabled) {
          if (mapLayers.trashLineLayer && sameSpatialFilter) {
            if (mapLayers.trashLineLayer) {
              currentMap.addLayer(mapLayers.collectorLineOutlineLayer);
              currentMap.addLayer(mapLayers.trashLineLayer);
            }
            if (mapLayers.trashPointLayer) currentMap.addLayer(mapLayers.trashPointLayer);
            if (mapLayers.surveyLayer) currentMap.addLayer(mapLayers.surveyLayer);
          } else {
            Actions.loadCollectorData();
          }
          $(".trash-td").css("visibility", "visible");
        } else {
          if (mapLayers.trashLineLayer) currentMap.removeLayer(mapLayers.trashLineLayer);
          currentMap.removeLayer(mapLayers.collectorLineOutlineLayer);
          if (mapLayers.trashPointLayer) currentMap.removeLayer(mapLayers.trashPointLayer);
          if (mapLayers.surveyLayer) currentMap.removeLayer(mapLayers.surveyLayer);
          $(".trash-td").css("visibility", "hidden");
        }
      },
    );

    //@TODO I think this function can use whenLayerToggled instead with destructuring
    MapFunctions.whenLayerToggled(mapId, "streams", function (streamsEnabled) {
      const currentMap = getMap(mapId);
      const currentMapLayers = getMapLayers(mapId);

      if (streamsEnabled) {
        if (currentMapLayers.rwLayer) {
          currentMap.addLayer(currentMapLayers.rwLayer);
          currentMapLayers.rwLayer.bringToBack();
        } else {
          Actions.loadReceivingWaters(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(currentMap, mapLayers.rwLayer)) {
          currentMap.removeLayer(currentMapLayers.rwLayer);
        }
      }
    });

    MapFunctions.whenLayerToggled(mapId, "bmps", function (bmpsIsEnabled, sameSpatialFilter) {
      const currentMap = getMap(mapId);
      if (bmpsIsEnabled) {
        if (mapLayers.bmpLayer && sameSpatialFilter) {
          currentMap.addLayer(mapLayers.bmpLayer);
          Clustering.setState("bmps");
        } else {
          Actions.loadBmps();
        }
      } else {
        if (MapFunctions.mapHasLayer(currentMap, mapLayers.bmpLayer)) {
          currentMap.removeLayer(mapLayers.bmpLayer);
        }
      }
    });

    MapFunctions.whenLayerToggled(mapId, "fcs", function (fcsIsEnabled, sameSpatialFilter) {
      const currentMap = getMap(mapId);
      // Does FCS work when we change groups?
      if (fcsIsEnabled) {
        if (mapLayers.fcsLayer && sameSpatialFilter) {
          currentMap.addLayer(mapLayers.fcsLayer);
          Clustering.setState("fcs");
        } else {
          Actions.loadFCSs(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(currentMap, mapLayers.fcsLayer)) {
          currentMap.removeLayer(mapLayers.fcsLayer);
        }
      }
    });

    MapFunctions.whenLayerToggled(
      mapId,
      "fullDrainage",
      function (fullDrainageIsEnabled, sameSpatialFilter) {
        const currentMap = getMap(mapId);
        if (fullDrainageIsEnabled) {
          if (Tree.get("layers", mapId, "fcs", "selectedId")) {
            Layers.highlightDrainage();
          }
          Actions.loadFcsDrainage(mapId);
        } else {
          if (Tree.get("layers", mapId, "fcs", "selectedId")) {
            Layers.highlightDrainage();
          } else {
            if (MapFunctions.mapHasLayer(currentMap, mapLayers.fullDrainageLayer)) {
              currentMap.removeLayer(mapLayers.fullDrainageLayer);
            }
          }
        }
      },
    );

    MapFunctions.whenLayerToggled(mapId, "ms4Boundary", function (ms4BoundaryIsEnabled) {
      const currentMap = getMap(mapId);
      if (ms4BoundaryIsEnabled) {
        if (mapLayers.ms4BoundaryLayer) {
          mapLayers.ms4BoundaryLayer.addTo(currentMap);
        } else {
          Actions.loadMs4Boundary(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(currentMap, mapLayers.ms4BoundaryLayer)) {
          currentMap.removeLayer(mapLayers.ms4BoundaryLayer);
        }
      }
    });

    MapFunctions.whenLayerToggled(
      mapId,
      "stormdrains",
      function (stormdrainsIsEnabled, sameSpatialFilter) {
        const currentMap = getMap(mapId);
        if (stormdrainsIsEnabled) {
          if (mapLayers.stormdrainLayer && sameSpatialFilter) {
            MapFunctions.handleStormdrainDisplay(currentMap, mapLayers);
          } else {
            Actions.loadStormdrains(mapId);
          }
        } else {
          MapFunctions.handleHideStormdrains(currentMap, mapLayers);
        }
      },
    );

    Tree.select("legendShowing", mapId).on("update", function (e) {
      var legendShowing = e.data.currentData;
      if (legendShowing) {
        DisplayOptions.showLegend();
      } else {
        DisplayOptions.hideLegend();
      }
    });

    Tree.select("yearDropdownSelection").on("update", (e) => {
      var mapId = Tree.get("mapId");
      var selectedYear = e.data.currentData;

      if (!mapId || selectedYear === null || Tree.get("firstLoad", mapId)) {
        return;
      }

      if (Tree.get("dataView") === "muniCatchBasinView") {
        const defaultFIlters = FilterConstants.getMuniCatchBasinProgressFilters();
        Tree.set("filters", {
          ...Tree.get("filters"),
          ...defaultFIlters,
        });
      }

      // For each layer find which layer is turned on
      clearDataLayers();
      Tree.get("activeTab") === "summary" ? KPI.loadKpi() : refreshAllLayers(mapId);
      Actions.loadCatchments(mapId);
      Actions.loadMs4Boundary(mapId);
      if (
        FeatureFlag.enabled("annual-report-muni-catch-basin-tko") &&
        Tree.get("dataView") === "muniCatchBasinView"
      ) {
        MuniCatchBasinReportGeoServerLayer.loadLayer(mapId);
      }

      const dataView = Tree.get("dataView");
      if (dataView === "sbmpView") {
        // @TODO: Move and handle this with dataView config
        KPI.loadKpi();
      }
      // @TODO: Temporary fix
      if (Tree.get("activeTab") === "chart") {
        Ms4Table.removeAllActive();
      }
    });
  };

  var getCurrentMap = function () {
    if (!Tabs.activeTabHasMap()) {
      throw new Error("Can't get current map on a non-map page.");
    }
    return getMap(Tree.get("mapId"));
  };

  var getMap = function (mapId) {
    switch (mapId) {
      case "inputs":
        return getInputsMap !== null ? getInputsMap() : null;
      case "report":
        return getReportMap !== null ? getReportMap() : null;
      case "plan":
        return getPlanMap !== null ? getPlanMap() : null;
    }
  };

  var setCurrentMapGetter = function (getMapFunction) {
    switch (Tree.get("mapId")) {
      case "inputs":
        getInputsMap = getMapFunction;
        break;
      case "report":
        getReportMap = getMapFunction;
        break;
      case "plan":
        getPlanMap = getMapFunction;
        break;
    }
  };

  var getCurrentMapLayers = function () {
    if (!Tabs.activeTabHasMap()) {
      throw new Error("Can't get current map layers on a non-map page.");
    }
    return getMapLayers(Tree.get("mapId"));
  };

  var getMapLayers = function (mapId) {
    switch (mapId) {
      case "inputs":
        return inputsMapLayers;
      case "report":
        return reportMapLayers;
      case "plan":
        return planMapLayers;
    }
  };

  var setCurrentMapLayers = function (mapLayers) {
    switch (Tree.get("mapId")) {
      case "inputs":
        inputsMapLayers = mapLayers;
        break;
      case "report":
        reportMapLayers = mapLayers;
        break;
      case "plan":
        planMapLayers = mapLayers;
        break;
    }
  };

  var refreshAllLayers = function (mapId = Tree.get("mapId")) {
    Actions.selectCatchmentsByFilter(getMapLayers(mapId));

    var noSpatialEdits = ["ms4Boundary", "streams"];
    var layerList = Tree.get("layers", mapId);
    for (const key in layerList) {
      if (layerList[key].isEnabled && !noSpatialEdits.includes(key)) {
        forceLayerRefresh(key);
      }
    }

    if (mapId === "report" || mapId === "plan") {
      DataViewDropdown.updateLegendForTelrLayer();
    }

    Tree.commit();
    KPI.loadKpi();
  };

  var forceLayerRefresh = function (layerName) {
    const mapId = Tree.get("mapId");

    Tree.set(["layers", mapId, layerName, "isEnabled"], false);
    Tree.set(["layers", mapId, layerName, "isEnabled"], true);
  };

  var clearDataLayers = function () {
    var currentMap = getCurrentMap();
    var currentMapLayers = getCurrentMapLayers();

    const primaryLayers = LayerFunctions.getAllLayersWith("isPrimary", true);
    const layersToclearOnYearChange = LayerFunctions.getAllLayersWith("clearOnYearChange", true);
    const layersToClear = { ...primaryLayers, ...layersToclearOnYearChange };
    var layerNamesToClear = [];
    for (const layerName in layersToClear) {
      const layerConfig = layersToClear[layerName];
      layerNamesToClear.push(layerConfig.treeName);
    }

    layerNamesToClear.forEach((layerName) => {
      const layer = LayerFunctions.getMapLayerNameByTreeName(layerName);
      const mapLayer = currentMapLayers[layer];
      if (mapLayer) {
        currentMap.removeLayer(mapLayer);
        currentMapLayers[layer] = null;
      }
    });
  };

  var setSentrySpatialView = function (spatialView) {
    Sentry.getCurrentScope().setExtra("spatialView", spatialView);
  };

  var handleUntreatedPluPercentileFilter = function () {
    const untreatedPluPercentileFilter = Tree.get("filters", "percentileUntreatedPlu");
    if (
      untreatedPluPercentileFilter &&
      untreatedPluPercentileFilter["spatialTypeUntreatedPlu"] &&
      untreatedPluPercentileFilter["percentileUntreatedPlu"] &&
      untreatedPluPercentileFilter["spatialTypeUntreatedPlu"].length > 0 &&
      untreatedPluPercentileFilter["percentileUntreatedPlu"].length > 0
    ) {
      FilterActions.filterTableByUntreatedPluPercentile(untreatedPluPercentileFilter);
    }
  };

  return {
    turnOnUpdates,
    getCurrentMap,
    getMap,
    getCurrentMapLayers,
    getMapLayers,
    forceLayerRefresh,
    refreshAllLayers,
  };
};

module.exports = TreeUpdates();

const Actions = require("../actions");
const Tree = require("../../tree");
const MapFunctions = require("./mapFunctions");
const DisplayOptions = require("./displayOptions");
const Table = require("./table");
const Misc = require("../misc");
const KPI = require("../report/kpi");
const FilterActions = require("./filterActions");
const Clustering = require("./clustering");
const RegionalView = require("../regionalView");
const Layers = require("./layers");
const PageFunctions = require("../pageFunctions");
const DataViewDropdown = require("./dataViewDropdown");
const DataViewFunctions = require("../dataViewFunctions");
const Progeny = require("../../login/progeny");
const Tabs = require("../tabs");
const MapTitle = require("./mapTitle");
const Sentry = require("@sentry/browser");
const LayerFunctions = require("./layerFunctions");
const Ms4Table = require("../ms4Table");
const FcsProgressLayer = require("../bmpfcs/fcsProgressLayer");
const SbmpProgressLayer = require("../bmpfcs/sbmpProgressLayer");
const MuniCatchBasinReportGeoServerLayer = require("../muni/g2/muniCatchBasinReportGeoServerLayer");
const FeatureFlag = require("../../settings/featureFlag");
const FilterConstants = require("./filterConstants");
