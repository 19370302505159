"use strict";

const ScenarioDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareScenario,
        template: "list/scenarioDataList.njk",
        layerName: "scenarios",
      };
      DataList.init(config);
      initialRender();
      DataList.loadDomListeners();
    }
  };

  var initialRender = function () {
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareScenario = function (scenarios) {
    addIconClasses(scenarios);
    addDisplayPhase(scenarios);
    sortScenarioDataBy(scenarios);
    return {
      rows: scenarios,
      options: {},
    };
  };

  var addIconClasses = function (scenarios) {
    scenarios.forEach((bmp) => {
      bmp.iconClass = BmpFcsIcon.getTableIcon(null, bmp.centralized, bmp.fcs, null, bmp.phase);
    });
  };

  var addDisplayPhase = function (scenarios) {
    scenarios.forEach((bmp) => {
      bmp.displayPhase = BmpFcsInventoryConstants.phaseOptions.find(
        (option) => option.value === bmp.phase,
      )?.name;
    });
  };

  var sortScenarioDataBy = function (scenarios) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    const booleanSorter = SortUtilities.booleanSorter;
    const dateSorter = DateTime.dbDateStringComparator;

    var sortersDict = {
      assetId: alphaSorter,
      scenario: alphaSorter,
      phase: alphaSorter,
      analysisReady: booleanSorter,
      capacity: numberSorter,
      drainageArea: numberSorter,
      imperviousAreaTreated: numberSorter,
      associatedProjectName: alphaSorter,
      displayUpdatedAt: dateSorter,
    };
    DataList.sortWithDict(scenarios, sortersDict);
  };

  return {
    init,
    _prepareScenario,
  };
};

module.exports = ScenarioDataListController();

const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const DataList = require("../mapFunctions/dataList");
const DateTime = require("../dateTime");
const SortUtilities = require("../general/sortUtilities");
