"use strict";

const GeneralRoutes = function () {
  let prioritizeModule;

  const toolDataViewRoute = {
    handler: function ({ data }) {
      const newTool = data.tool;
      const newDataView = data.dataView;
      const existingDataView = Tree.get("dataView");

      if (!ToolFunctions.getDataViewsByTool(newTool).includes(newDataView)) {
        UrlRoutes.navigateToDefault();
        return false;
      } else if (existingDataView && newDataView !== existingDataView) {
        DataViewController.setDataView(newDataView, { updateUrl: false });
      }
    },
  };

  const toDoPrioritizeRoute = {
    handler: function ({ data }) {
      prioritizeModule = null;

      if (toolDataViewRoute.handler({ data }) === false) {
        return;
      }

      const toDoSubject = data.toDoSubject;
      prioritizeModule = ToDoConfig.getToDoSubjectConfig([toDoSubject, "prioritizeModule"], false);

      if (prioritizeModule) {
        prioritizeModule.open();
      } else {
        console.error("No prioritize module found for to-do subject", toDoSubject);
        UrlRoutes.navigateToDefault();
      }
    },
    hooks: {
      leave: function (done) {
        done(prioritizeModule?.leave() !== false);
      },
    },
  };

  return { toolDataViewRoute, toDoPrioritizeRoute };
};

module.exports = GeneralRoutes();

const DataViewController = require("../../mapFunctions/dataViewController");
const ToolFunctions = require("../../toolFunctions");
const Tree = require("../../tree");
const UrlRoutes = require("../urlRoutes");
const ToDoConfig = require("../../config/toDoConfig");
