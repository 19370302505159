"use strict";

const PostConstructionProjectPopupGeoServerLayer = function () {
  var loadDomListeners = function () {
    const $map = $("#map");

    $map.off("click", ".lid-popup-show-history");
    $map.on("click", ".lid-popup-show-history", _showInspectionHistory);

    $map.off("click", ".lid-popup-back-button");
    $map.on("click", ".lid-popup-back-button", clickOnBackButton);

    $map.off("click", ".lid-popup-delete-button");
    $map.on("click", ".lid-popup-delete-button", deleteLidProject);

    $map.off("click", ".view-lid-project-inventory");
    $map.on("click", ".view-lid-project-inventory", viewLidProjectInventory);

    $map.off("click", ".js-view-summary", LidProjectPopup.handleViewSummaryClick);
    $map.on("click", ".js-view-summary", LidProjectPopup.handleViewSummaryClick);

    $map.off("click", "#construction-complete-project-btn");
    $map.on("click", "#construction-complete-project-btn", openCompleteModal);

    $map.off("click", ".lid-popup-update-response");
    $map.on("click", ".lid-popup-update-response", LidProjectPopup.handleUpdateResponseClick);

    $map.off("click", ".js-lid-generate", LidProjectPopup.handleGenerateLetterClick);
    $map.on("click", ".js-lid-generate", LidProjectPopup.handleGenerateLetterClick);

    $map.off("click", ".lid-popup-fact-sheet");
    $map.on("click", ".lid-popup-fact-sheet", clickOpenFactSheet);

    $map.off("click", ".download-inspection-report", LidProjectPopup.handleDownloadLetterClick);
    $map.on("click", ".download-inspection-report", LidProjectPopup.handleDownloadLetterClick);

    $map.off("click", ".download-summary-report", LidProjectPopup.handleDownloadSummaryClick);
    $map.on("click", ".download-summary-report", LidProjectPopup.handleDownloadSummaryClick);

    $map.off("click", ".download-response-report", LidProjectPopup.handleDownloadResponseClick);
    $map.on("click", ".download-response-report", LidProjectPopup.handleDownloadResponseClick);

    $map.off("click", ".js-delete-summary", LidProjectPopup.handleDeleteSummaryClick);
    $map.on("click", ".js-delete-summary", LidProjectPopup.handleDeleteSummaryClick);
  };

  var viewLidProjectInventory = function () {
    const id = $(this).parents(".popup-lid-project").data("id");
    ProjectInventoryModalController.invokeProjectInventoryModal(id);
  };

  var openCompleteModal = function (e) {
    const $row = $(e.currentTarget).closest("div");
    const id = $row.data("id");

    ProjectCompleteModal.renderAndShow(id);
  };

  var getPopupHtml = function (properties) {
    return PopupContent.getLayerPopup(properties, "postConstructionProjectG2");
  };

  var onPopupOpen = async function (properties) {
    const id = properties.id;
    Tree.set(["layers", "lidProject", "selectedId"], id);
    var data = await getPopupData(id);
    data.siteVisitHistory = data.lidHistory.filter(
      (history) => !!history.sbmpCondition && history.type !== "enforcement",
    );
    const html = getPopupHtml(data);
    $(".leaflet-popup-content").html(html);
    LidProjectPopup._renderSiteVisitHistoryBarGraph(data.siteVisitHistory);
    LidSelfInspectionHistory.load(data);
  };

  var deleteLidProject = function () {
    const data = $(this).closest(".popup-general").data();
    MessageModal.showConfirmWarningModal(
      `Delete Construction Project ${data.name} ? Click "Delete" to permanently delete the Construction Project. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteConstructionProject(data.group, data.id);
        _closePopupAndReloadLayer();
      },
      "Cancel",
      "Delete",
    );
    Analytics.sendPopupEvent("delete_asset", "lid-project");
  };

  var _closePopupAndReloadLayer = function () {
    const activeTab = Tree.get("activeTab");
    const popupCloseButn = $(".leaflet-popup-close-button");
    if (popupCloseButn.length) {
      popupCloseButn[0].click();
    }
    if (activeTab === "data") {
      PostConstructionProjectGeoServerLayer.loadLayer();
    } else {
      LidProjectLayer.loadLayer("main");
    }
  };

  var getPopupData = async function (id) {
    const isTodo = Tree.get("activeTab") === "todo";
    const data = await ApiCalls.getLidProjectById(id);
    data.popupColor = LidProjectStyles.getNewLidColor(data, isTodo);
    data.tierNumber = LidIcon.getLidProjectTierNumber(data);
    data.allPerformanceTierString = LidProjectPopup.getAllPerformanceTierString(data);
    data.showGenerateButton = LidProjectDataListController.showGenerateButton(data); // @TODO pcr-letter: remove this
    data.showUpdateResponseButton = LidProjectDataListController.showUpdateResponseButton(data); // @TODO pcr-letter: remove this
    data.permissions = UserPermissions.getAllPermissions();

    return data;
  };

  var _showInspectionHistory = function (projectId) {
    $(".popup-main-pane").hide();
    $(".lid-popup-back-button").show();
    $(".popup-history-pane").show();
    $(".lid-popup-delete-button").show();
    Analytics.sendPopupEvent("view_historyTab", "lid-project");
  };

  var clickOnBackButton = function () {
    $(".popup-main-pane").show();
    $(".popup-history-pane").hide();
    $(".lid-popup-back-button").hide();
    $(".lid-popup-delete-button").hide();
  };

  var clickOpenFactSheet = function () {
    const id = $(this).parents(".popup-lid-project").data("id");
    UrlRoutes.go("lid-fact-sheet", { id });
    Analytics.sendPopupEvent("view_details", "lid");
  };

  return {
    loadDomListeners,
    getPopupHtml,
    onPopupOpen,
    getPopupData,
    _showInspectionHistory,
  };
};

module.exports = PostConstructionProjectPopupGeoServerLayer();

const ApiCalls = require("../apiCalls");
const PopupContent = require("../mapFunctions/popupContent");
const MessageModal = require("../modals/messageModal");
const Analytics = require("../general/analytics");
const ProjectInventoryModalController = require("../construction/projectInventoryModalController");
const ProjectCompleteModal = require("../construction/projectCompleteModal");
const PostConstructionProjectGeoServerLayer = require("./postConstructionProjectGeoServerLayer");
const LidProjectStyles = require("../lid/lidProjectStyles");
const LidIcon = require("../lid/lidIcon");
const LidProjectPopup = require("../lid/lidProjectPopup");
const LidProjectDataListController = require("../lid/lidProjectDataListController");
const UserPermissions = require("../login/userPermissions");
const LidSelfInspectionHistory = require("../lid/lidSelfInspectionHistory");
const Tree = require("../tree");
const LidProjectLayer = require("../lid/lidProjectLayer");
const UrlRoutes = require("../routes/urlRoutes");
