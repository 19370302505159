"use strict";

const Baobab = require("baobab");
const TreeInitialDataRam = require("./treeInitialDataRam");
const TreeInitialDataReport = require("./treeInitialDataReport");
const TreeInitialDataCommon = require("./treeInitialDataCommon");

var initialData = {
  ...(window.twoNformReport
    ? TreeInitialDataReport.getStaticData()
    : TreeInitialDataRam.getStaticData()),
  ...TreeInitialDataCommon.getStaticData(),
};

var options = {
  immutable: false, // disabled for performance reasons
  monkeyBusiness: false, // disabled for performance reasons
};

var tree = new Baobab(initialData, options);

tree.getAfterUpdate = function (path = []) {
  const cursor = tree.select(path);

  return new Promise((resolve, reject) => {
    cursor.on("update", () => {
      resolve(cursor.get());
      cursor.off();
    });
  });
};

module.exports = tree;
