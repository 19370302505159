"use strict";

// https://cloud.google.com/storage/docs/retry-strategy
// https://docs.aws.amazon.com/general/latest/gr/api-retries.html
function Retryer() {
  async function tryPromise(
    retryFunction,
    { safeToRetryFunction = null, retryWaitSecondsByAttempt = [0.5, 1, 2, 4, 8] } = {},
  ) {
    var lastFailure;

    assertFunction(retryFunction);

    for (var i = 0; i < [0, ...retryWaitSecondsByAttempt].length; i++) {
      const timeToWait = retryWaitSecondsByAttempt[i];
      if (timeToWait !== 0) {
        await waitSeconds(timeToWait);
      }

      try {
        const retryPromise = retryFunction();
        assertPromise(retryPromise);
        return await retryPromise;
      } catch (e) {
        lastFailure = e;
        if (safeToRetryFunction !== null && safeToRetryFunction(e) !== true) {
          throw e;
        }
      }
    }

    throw lastFailure;
  }

  function isSafeToRetryApi(e) {
    const safeToRetryHttpCodes = [0, 408, 429, 500, 502, 503, 504];

    if (!(e instanceof ApiError)) {
      return false;
    }

    return e.xhrResponse.status in safeToRetryHttpCodes;
  }

  function waitSeconds(seconds) {
    return new Promise(function (resolve) {
      if (typeof jest === "undefined") {
        setTimeout(resolve, seconds * 1000);
      } else {
        resolve();
      }
    });
  }

  function assertFunction(retryFunction) {
    if (typeof retryFunction !== "function") {
      throw new Error(`Passed argument must be a function.`);
    }
  }

  function assertPromise(retryPromise) {
    if (!(retryPromise instanceof Promise)) {
      throw new Error(`The passed function must return a promise.`);
    }
  }

  return {
    tryPromise,
    isSafeToRetryApi,
  };
}

module.exports = Retryer();

const ApiError = require("../errors/apiError");
