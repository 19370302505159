const outfallssPopup = function () {
  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();
    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  var loadDomListeners = function () {
    $(".map-content").off("click", ".popup-show-history");
    $(".map-content").on("click", ".popup-show-history", popupHistoryButtonClick);

    $(".map-content").off("click", ".popup-back-button");
    $(".map-content").on("click", ".popup-back-button", backButtonClick);
  };

  var loadPopupEventListeners = function (layer) {
    layer.on("popupopen", _onPopupOpen);
  };

  var backButtonClick = function () {
    var $parent = $(this).parents(".popup-general");
    $parent.find(".popup-outfall-history-pane").hide();
    $parent.find(".popup-back-button").hide();
    $parent.find(".popup-show-history").show();
    $parent.find(".popup-outfall-main-pane").show();
  };

  var popupHistoryButtonClick = function () {
    var id = $(this).parents(".popup-general").data("id");
    showInspectionHistory(id);
  };

  var showInspectionHistory = function () {
    $(".popup-outfall-main-pane").hide();
    $(".popup-show-history").hide();
    $(".popup-outfall-history-pane").show();
    $(".popup-back-button").show();
  };

  var _onPopupOpen = async function (e) {
    const id = e.layer.feature.properties.id;

    const data = await getPopupData(id);
    const html = getOutfallPopupHtml(data);
    e.popup.setContent(html);
  };

  var getPopupData = async function (id) {
    const popupData = await ReportApiCalls.getOutfallPopup(id);
    return popupData;
  };

  var getOutfallPopupHtml = function (data) {
    const popupColor = OutfallsIcon.getIconColor(data.condition);
    const showNewInvestigationButton = data.incidentStatus === "new";
    return nunjucks.render("report/popups/outfallReportPopup.njk", {
      data,
      popupColor,
      showNewInvestigationButton,
      history: data.history,
    });
  };

  return {
    getOutfallPopupHtml,
    loadDomListeners,
    getPopupData,
    loadPopupEventListeners,
    getPopupOptions,
  };
};

module.exports = outfallssPopup();

const OutfallsIcon = require("../outfalls/outfallsIcon");
const ReportApiCalls = require("../reportApiCalls");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
