"use strict";

const RegionPointLayer = function () {
  var loadListeners = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadOpenProgramListenerForMap();
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(mapId, "regionPoint", function (isEnabled) {
      if (isEnabled) {
        if (mapLayers.regionPointLayer) {
          map.addLayer(mapLayers.regionPointLayer);
          map.fitBounds(mapLayers.regionPointLayer.getBounds(), {
            paddingTopLeft: new L.Point(320, 0),
          });
        } else {
          if (Tree.get(["layers", mapId, "regionPoint", "isFetching"])) {
            return;
          }
          loadLayer(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.regionPointLayer)) {
          map.removeLayer(mapLayers.regionPointLayer);
        }
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "regionPoint", function (data) {
      const geoJsonData = CleanData.cleanGeoJSON(data);
      mapLayers.regionPointLayer = createLayerAndAddToMap(map, mapLayers, geoJsonData);
      RegionDropdown.handleDisablingRegionButtons();
    });
  };

  var loadOpenProgramListenerForMap = function () {
    PageFunctions.getCurrentPage().on("click", ".open-program", (e) => {
      closePopups();
      RegionalView.changeActiveGroup($(e.currentTarget).data("groupId"));
    });
  };

  var closePopups = function () {
    TreeUpdates.getCurrentMapLayers().regionPointLayer.eachLayer((layer) => {
      layer.closePopup();
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.regionPointLayer) {
      map.removeLayer(mapLayers.regionPointLayer);
    }
    const regionPointLayer = createLayer(data);
    regionPointLayer.addTo(map);
    map.fitBounds(regionPointLayer.getBounds(), {
      paddingTopLeft: new L.Point(320, 0),
    });
    regionPointLayer.on("popupclose", function (e) {
      $(".ms4-table .active").removeClass("active");
    });
    return regionPointLayer;
  };

  var createLayer = function (data) {
    return L.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        return L.marker(latlng, {
          icon: new L.DivIcon({
            className: "bmp-marker-icon region-point-flag",
            iconSize: [30, 36],
            iconAnchor: [17, 36],
            shadowSize: [40, 50],
            shadowAnchor: [14, 58],
            popupAnchor: [0, -36 - 10],
            shadowUrl: "/ram/images/report/shadow.png",
          }),
        });
      },
      onEachFeature: function (feature, layer) {
        layer
          .on({
            mouseover: Ms4Table.highlightMs4Feature,
            mouseout: Ms4Table.resetHighlightMs4,
            click: Ms4Table.zoomToRegionPoint,
          })
          .bindPopup((layer) => PopupContent.getRegionPointPopup(feature));
      },
    });
  };

  var loadLayer = function (mapId = Tree.get("mapId")) {
    const progeny = Progeny.getActiveGroupProgeny();
    Tree.set(["layers", mapId, "regionPoint", "isFetching"], false);
    Tree.set(["layers", mapId, "regionPoint", "data"], progeny);
  };

  return {
    loadListeners,
    loadLayer,
  };
};

module.exports = RegionPointLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Ms4Table = require("../ms4Table");
const Tree = require("../../tree");
const TreeUpdates = require("../mapFunctions/treeUpdates");
const Progeny = require("../../login/progeny");
const CleanData = require("../mapFunctions/cleanData");
const PopupContent = require("../mapFunctions/popupContent");
const RegionDropdown = require("../regionDropdown");
const PageFunctions = require("../pageFunctions");
const RegionalView = require("../regionalView");
