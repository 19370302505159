"use strict";

const RoutineMaintenanceIcon = function () {
  const getMaintenanceIcon = function (prefix, lastInspection, repairDue) {
    const condition = getLayerCondition(lastInspection, repairDue);

    return { class: getConditionClass(prefix, condition), color: getConditionColor(condition) };
  };

  const getLayerCondition = function (lastInspection, repairDue) {
    if (repairDue) {
      return "poor";
    } else if (!lastInspection) {
      return null;
    } else {
      return "optimal";
    }
  };

  const getConditionClass = function (prefix, condition) {
    if (!(condition === null || typeof condition === "string")) {
      throw new Error(`Condition is ${JSON.stringify(condition)}`);
    }

    const classNames = [prefix];

    if (condition !== null) {
      classNames.push(condition);
    }

    return classNames.join("-");
  };

  const getConditionColor = function (condition) {
    if (condition === undefined) {
      condition = null;
    }

    return MapStyles.getColorFromCondition(condition);
  };

  return { getLayerCondition, getConditionClass, getConditionColor, getMaintenanceIcon };
};

module.exports = RoutineMaintenanceIcon();

const MapStyles = require("../mapFunctions/mapStyles");
