"use strict";

const MimeTypeConstants = function () {
  const documentMimeTypes = [
    "application/pdf",
    "application/doc",
    "application/dot",
    "application/docx",
    "application/dotx",
    "application/docm",
    "application/dotm",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const presentationMimeTypes = ["application/vnd.ms-powerpoint"];
  const spreadsheetMimeTypes = [
    "application/xls",
    "application/xlt",
    "application/xla",
    "application/xlsx",
    "application/xltx",
    "application/xlsm",
    "application/xltm",
    "application/xlam",
    "application/xlsb",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/csv",
    "application/x-csv",
    "text/csv",
    "text/plain",
    "text/comma-separated-values",
    "text/x-comma-separated-values",
  ];
  const imageMimeTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp"];
  const zipMimeTypes = [
    "application/zip",
    "application/octet-stream",
    "application/x-zip-compressed",
    "multipart/x-zip",
  ];

  return {
    documentMimeTypes,
    presentationMimeTypes,
    spreadsheetMimeTypes,
    imageMimeTypes,
    zipMimeTypes,
  };
};

module.exports = MimeTypeConstants();
