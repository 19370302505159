"use strict";

const ReportSummary = function () {
  let dataView;
  let data;
  let runOffResults;
  let bmpGreenPercentage;
  let permittedBoundry;
  let inventoriedBmp;
  let reductionIncrease;
  let reductionIncreasePercent;
  let noPriorYearData;

  var load = function (thisYearData) {
    if (
      !(Progeny.activeGroupIsRegulator() && Progeny.activeHasProgeny()) &&
      Tree.get("activeTab") === "summary"
    ) {
      ScheduleTicker.setTimeline("#navigation-header-container", "summary");
      data = thisYearData;
      renderSummaryText();
    }
  };

  var renderSummaryText = async function () {
    Table.expandSideBar();
    dataView = Tree.get("dataView");
    data = {
      ...data,
      permitPrefixText: SummaryConstants.getPermitPrefixText(dataView),
      permitSectionHeader: PermitSettings.getDisplayPermitSectionByDataView(dataView, {
        includeProgramType: true,
      }),
      permitSectionBody: PermitSettings.getDisplayPermitSectionByDataView(dataView, {
        includeProgramType: true,
        includePermit: true,
      }),
      groupName: Tree.get("activeGroup", "name"),
      groupShortName: SummaryConstants.getGroupShortName(),
    };
    var updateData = DataViewFunctions.getUpdateDataFunction(dataView);
    if (updateData) await updateData();

    $("#report-summary-text").html(
      nunjucks.render(DataViewFunctions.getSummaryTemplate(dataView), data),
    );

    YearDropdown.enableAllYears();
    var enabledYears = DataViewFunctions.getSummaryDisableYearsExcept(dataView);
    var selectedYear = AnnualReports.getSelectedYear();

    if (enabledYears) {
      if (!enabledYears.includes(selectedYear)) {
        YearDropdown.setYearDropdown(enabledYears[0]);
      }
      YearDropdown.disableAllExceptYears(enabledYears);
    }

    addTogglePreviewButtonListener();
  };

  var addTogglePreviewButtonListener = function () {
    $(".summary-list-container")
      .off("click.togglePreviewButton")
      .on("click.togglePreviewButton", ".toggle-preview-button", function (e) {
        $(this).parents(".summary-list-container").toggleClass("expanded");
        e.preventDefault();
      });
  };

  var updateTrashData = function () {
    const { year, treatedPlu } = data;
    getPriorYearData(year, treatedPlu, "treatedPlu");
    const { percentPluTreated, percentProgress, fcsInventory, functionalFcss } = data;
    const percentFcsFunctional = functionalFcss === 0 ? 0 : (functionalFcss / fcsInventory) * 100;
    const totalPercent = percentPluTreated + percentProgress;
    data = {
      ...data,
      totalPercent,
      percentFcsFunctional,
      reductionIncreasePercent,
      reductionIncrease,
      noPriorYearData,
    };
  };

  var updateParticulatesData = async function () {
    const { year, totalParticulateReductionConnected } = data;
    getPermittedBoundry();
    const bmpObj = await getBmpData();
    const { totalDecentralized, totalCentralized, decentralizedBmps, centralizedBmps } = bmpObj;
    getPriorYearData(
      year,
      totalParticulateReductionConnected,
      "totalParticulateReductionConnected",
    );

    data = {
      ...data,
      permittedBoundry,
      inventoriedBmp,
      totalCentralized,
      totalDecentralized,
      decentralizedBmps,
      centralizedBmps,
      reductionIncrease,
      reductionIncreasePercent,
      noPriorYearData,
    };
  };

  var updateRunoffData = async function () {
    const { year, totalRunoffReductionConnected } = data;
    getPermittedBoundry();
    const bmpObj = await getBmpData();
    const { totalDecentralized, totalCentralized, decentralizedBmps, centralizedBmps } = bmpObj;
    getPriorYearData(year, totalRunoffReductionConnected, "totalRunoffReductionConnected");

    data = {
      ...data,
      inventoriedBmp,
      totalDecentralized,
      totalCentralized,
      centralizedBmps,
      decentralizedBmps,
      reductionIncrease,
      reductionIncreasePercent,
      permittedBoundry,
      noPriorYearData,
    };
  };

  var getPermittedBoundry = function () {
    permittedBoundry = Tree.get(["spatialGroups", "report", "allMs4", "acres"]);
  };

  var getPriorYearData = function (currentYear, currentReduction, specificProp) {
    const layerType = DataViewFunctions.getCurrentLayerName();
    if (typeof currentReduction === "string") currentReduction = parseFloat(currentReduction);
    const filteredDataArray = Tree.get(["reportDataFiltered", "data"]);
    const priorYear = currentYear - 1;
    if (
      currentYear > 2017 &&
      layerType !== "connectivityInputs" &&
      layerType !== "landuseInputs" &&
      layerType !== "soilInputs" &&
      layerType !== "imperviousInputs"
    ) {
      const priorYearData = filteredDataArray.find(({ year }) => priorYear === year);
      if (!priorYearData) {
        noPriorYearData = true;
        return;
      }
      noPriorYearData = false;

      let priorReduction = priorYearData[`${specificProp}`];
      if (typeof priorReduction === "string") priorReduction = parseFloat(priorReduction);

      if (currentReduction - priorReduction > 0) {
        reductionIncrease = currentReduction - priorReduction;
        if (currentReduction !== 0 && priorReduction !== 0) {
          reductionIncreasePercent = ((currentReduction - priorReduction) / priorReduction) * 100;
        }
        if (currentReduction !== 0 && priorReduction === 0) {
          reductionIncreasePercent = "—";
        }
      } else {
        reductionIncrease = 0;
        reductionIncreasePercent = 0;
      }
    } else {
      reductionIncrease = 0;
      reductionIncreasePercent = 0;
    }
  };

  var getBmpData = async function () {
    let totalDecentralized = 0;
    let totalCentralized = 0;
    const bmpData = await ReportApiCalls.getSbmpProgress(FilterConstants.sbmpLayerFilters);

    inventoriedBmp = bmpData.length;

    const decentralizedBmps = await bmpData.filter(({ bmpTypeObj }) => {
      return !bmpTypeObj.isCentralized;
    });
    decentralizedBmps
      .map(({ imperviousAreaAcres }) => {
        return parseFloat(imperviousAreaAcres);
      })
      .forEach((num) => {
        if (!isNaN(num)) {
          totalDecentralized += num;
        }
      });

    const centralizedBmps = await bmpData.filter(({ bmpTypeObj }) => {
      return bmpTypeObj.isCentralized;
    });
    centralizedBmps
      .map(({ imperviousAreaAcres }) => {
        return parseFloat(imperviousAreaAcres);
      })
      .forEach((num) => {
        if (!isNaN(num)) {
          totalCentralized += num;
        }
      });
    return {
      totalDecentralized,
      totalCentralized,
      centralizedBmps: centralizedBmps.length,
      decentralizedBmps: decentralizedBmps.length,
    };
  };

  var updateIddeData = async function () {
    data.tableData.forEach((incident) => {
      incident.requestingType = IncidentPopup.getOptionNameByValue(
        FormSettings.getDropdownOptions("requesting-type", { tool: "idde" }),
        incident.requestingType,
      );

      incident.enforcementLevel = IncidentPopup.getOptionNameByValue(
        FormSettings.getDropdownOptions("enforcement-level", { tool: "idde" }),
        incident.enforcementLevel,
      );

      if (incident.enforcementLevel === "Administrative Action") {
        incident.enforcementLevel = "NOV";
      } else {
        incident.enforcementLevel;
      }

      incident.illicitActivity = IncidentPopup.getOptionNameByValue(
        IncidentPopup.getIllicitActivityOptions(),
        incident.illicitActivity,
      );
      if (!incident.illicitActivity) {
        incident.illicitActivity = "Unknown Activity";
      }

      if (
        incident.responsiblePartyIdentified &&
        incident.responsiblePartyAffiliation &&
        incident.responsiblePartyName
      ) {
        incident.responsibleParty = `${incident.responsiblePartyAffiliation} and ${incident.responsiblePartyName}`;
      } else if (incident.responsiblePartyAffiliation) {
        incident.responsibleParty = incident.responsiblePartyAffiliation;
      } else if (incident.responsiblePartyName) {
        incident.responsibleParty = incident.responsiblePartyName;
      } else {
        incident.responsibleParty = "Unknown";
      }
    });
  };

  var updateSbmpData = async function () {
    var getGreenBpmPercentage = function () {
      const sbmpData = Tree.get(["layers", "report", "sbmpProgress", "data"]);
      const greenBmpTypes = [6, 3, 9, 1, 19, 2, 10, 13, 8, 15];
      const totalbmp = sbmpData.length;
      const greenBmps = sbmpData.filter(({ bmpTypeObj }) => {
        return greenBmpTypes.includes(bmpTypeObj.id);
      });
      bmpGreenPercentage = totalbmp !== 0 ? Math.round((greenBmps.length / totalbmp) * 100) : 0;
    };

    var getTotalVolumeGallonsForYear = async function () {
      return ReportApiCalls.getTelrKpi((telrData) => {
        const results = telrData.find((theYear) => theYear.Year === data.year);
        const { runoffCentralizedConnected, runoffDecentralizedConnected } = results || {
          runoffCentralizedConnected: 0,
          runoffDecentralizedConnected: 0,
        };
        const gallons = Math.round(
          (((parseFloat(runoffCentralizedConnected) || 0) +
            (parseFloat(runoffDecentralizedConnected) || 0)) *
            325851.43188913) /
            1000000,
        );

        runOffResults = gallons;
      });
    };

    getGreenBpmPercentage();
    await getTotalVolumeGallonsForYear();
    data = {
      ...data,
      runOffResults,
      bmpGreenPercentage,
    };
  };

  var updateConstructionData = async function () {
    if (Array.isArray(data.topTbmpCategories)) {
      data.topTbmpCategories = data.topTbmpCategories.join(", ");
    }
  };

  var updateLidData = async function () {
    if (data.totalAlternativeComplianceProjectCount > 0) {
      const displayNameArray = [];
      data.alternativeComplianceString = data.alternativeComplianceProjects.forEach((project) => {
        if (project.alternativeComplianceCircumstanceReasons?.length) {
          project.alternativeComplianceCircumstanceReasons.forEach((reason) => {
            if (!displayNameArray.includes(reason.displayName)) {
              displayNameArray.push(reason.displayName);
            }
          });
        }
      });

      if (displayNameArray.length > 1) {
        displayNameArray[displayNameArray.length - 1] =
          "and " + displayNameArray[displayNameArray.length - 1];
      }

      data.alternativeComplianceCircumstanceString = displayNameArray.join(", ");
    }
  };

  var updateMuniBmpData = async function () {
    if (data.allOpenIssueKeys?.length) {
      data.allOpenIssueDisplayKeys = data.allOpenIssueKeys.map((key) => {
        return MaintenanceResult.getIssueNameByKey(key);
      });
    }

    if (data.openIssuesData?.length) {
      data.openIssuesData.forEach((item) => {
        item.displayKeys = item.keys
          .map((key) => MaintenanceResult.getIssueNameByKey(key))
          .join(", ");
      });
    }
  };

  var updateStreetSweepingData = async function () {
    if (data.routeCountBySweeperFrequency?.length) {
      data.routeCountBySweeperFrequencyText = getRouteCountBySweeperFrequencyText(
        data.routeCountBySweeperFrequency,
      );
    }

    if (data.totalRouteCount && data.routeCountBySweeperType?.length) {
      data.routeCountBySweeperTypeText = getRouteCountBySweeperTypeText(
        data.routeCountBySweeperType,
        data.totalRouteCount,
      );
    }

    if (data.routeCountByPriority?.length) {
      data.routeCountByPriorityText = getRouteCountByPriorityText(data.routeCountByPriority);
    }
  };

  var getRouteCountBySweeperFrequencyText = function (routeCountBySweeperFrequency) {
    const frequencyTextMap = {
      two_weekly: "twice per week",
      weekly: "weekly",
      two_monthly: "twice per month",
      monthly: "monthly",
      less_than_monthly: "less than once per month",
    };
    const order = ["two_weekly", "weekly", "two_monthly", "monthly", "less_than_monthly"];
    routeCountBySweeperFrequency?.sort(
      (a, b) => order.indexOf(a.sweeperFrequency) - order.indexOf(b.sweeperFrequency),
    );

    let routeCountBySweeperFrequencyText = "";

    routeCountBySweeperFrequency
      .filter((item) => item.sweeperFrequency !== null && item.sweeperFrequency && item.count)
      .forEach((item, index, arr) => {
        const lastIndex = arr.length - 1;

        if (index === 1) {
          routeCountBySweeperFrequencyText += ", while ";
        } else if (index > 1 && index < lastIndex) {
          routeCountBySweeperFrequencyText += ", ";
        } else if (index > 1 && index === lastIndex) {
          routeCountBySweeperFrequencyText += ", and ";
        }

        const frequencyText = frequencyTextMap[item.sweeperFrequency];
        const routeText = item.count <= 1 ? "route is" : "routes are";
        routeCountBySweeperFrequencyText += `${item.count} ${routeText} swept ${frequencyText}`;
      });

    var nullSweepeFrequencyCount = routeCountBySweeperFrequency.find(
      (item) => item.sweeperFrequency === null,
    )?.count;
    if (routeCountBySweeperFrequencyText && nullSweepeFrequencyCount) {
      const routeText = nullSweepeFrequencyCount <= 1 ? "route has" : "routes have";
      routeCountBySweeperFrequencyText += ` (${nullSweepeFrequencyCount} ${routeText} no data for frequency)`;
    }

    if (routeCountBySweeperFrequencyText) {
      routeCountBySweeperFrequencyText += ".";
    }

    return routeCountBySweeperFrequencyText;
  };

  var getRouteCountBySweeperTypeText = function (routeCountBySweeperType, totalRouteCount) {
    const sweeperTypeTextMap = {
      vacuum_assisted: "vacuum-assisted",
      regenerative_air: "regenerative air",
      mechanical_broom: "mechanical broom",
    };
    let routeCountBySweeperTypeText = "";

    routeCountBySweeperType
      .filter((item) => item.sweeperType !== null && item.sweeperType && item.count)
      .forEach((item, index, arr) => {
        const lastIndex = arr.length - 1;

        if (index >= 1 && index < lastIndex) {
          routeCountBySweeperTypeText += ", ";
        } else if (index >= 1 && index === lastIndex) {
          routeCountBySweeperTypeText += ", and ";
        }

        const sweeperTypeText = sweeperTypeTextMap[item.sweeperType];
        const percent = Math.round((item.count / totalRouteCount) * 100);
        routeCountBySweeperTypeText += `the ${sweeperTypeText} sweeper used on ${percent}% of the routes`;
      });

    var nullSweeperTypeCount = routeCountBySweeperType.find(
      (item) => item.sweeperType === null,
    )?.count;
    if (routeCountBySweeperTypeText && nullSweeperTypeCount) {
      const routeText = nullSweeperTypeCount <= 1 ? "route has" : "routes have";
      routeCountBySweeperTypeText += ` (${nullSweeperTypeCount} ${routeText} unknown sweeper type)`;
    }

    if (routeCountBySweeperTypeText) {
      routeCountBySweeperTypeText += ".";
    }

    return routeCountBySweeperTypeText;
  };

  var getRouteCountByPriorityText = function (routeCountByPriority) {
    let routeCountByPriorityText = "";

    routeCountByPriority
      .filter(
        (item) =>
          item.priority !== "unknown" && item.priority !== null && item.priority && item.count,
      )
      .forEach((item, index, arr) => {
        const lastIndex = arr.length - 1;

        if (index >= 1) {
          if (index < lastIndex) {
            routeCountByPriorityText += ", ";
          } else if (index === lastIndex) {
            routeCountByPriorityText += ", and ";
          }
        }

        const priorityText = item.priority.replace("-", " ");
        routeCountByPriorityText += `${item.count} ${priorityText} priority`;
      });

    var emptyStrArray = [];
    var unknownPriorityCount = routeCountByPriority.find(
      (item) => item.priority === "unknown",
    )?.count;
    if (unknownPriorityCount) {
      const routeText = unknownPriorityCount <= 1 ? "route has" : "routes have";
      emptyStrArray.push(`${unknownPriorityCount} ${routeText} unknown priority`);
    }

    var nullPriorityCount = routeCountByPriority.find((item) => item.priority === null)?.count;
    if (nullPriorityCount) {
      const routeText = nullPriorityCount <= 1 ? "route has" : "routes have";
      emptyStrArray.push(`${nullPriorityCount} ${routeText} no priority data`);
    }

    if (routeCountByPriorityText && emptyStrArray.length) {
      const emptyStr = emptyStrArray.join(" and ");
      routeCountByPriorityText += ` (${emptyStr})`;
    }

    if (routeCountByPriorityText) {
      routeCountByPriorityText += ".";
    }

    return routeCountByPriorityText;
  };

  return {
    renderSummaryText,
    updateTrashData,
    updateParticulatesData,
    updateRunoffData,
    updateIddeData,
    updateSbmpData,
    updateConstructionData,
    updateMuniBmpData,
    updateLidData,
    updateStreetSweepingData,
    load,
    getRouteCountBySweeperFrequencyText,
    getRouteCountBySweeperTypeText,
    getRouteCountByPriorityText,
  };
};

module.exports = ReportSummary();

const AnnualReports = require("../annualReports");
const ReportApiCalls = require("../reportApiCalls");
const DataViewFunctions = require("../dataViewFunctions");
const FilterConstants = require("../mapFunctions/filterConstants");
const FormSettings = require("../../settings/formSettings");
const IncidentPopup = require("../idde/incidentPopup");
const PermitSettings = require("../settings/permitSettings");
const Progeny = require("../../login/progeny");
const ScheduleTicker = require("../report/scheduleTicker");
const SummaryConstants = require("./summaryConstants");
const Tree = require("../../tree");
const YearDropdown = require("../mapFunctions/yearDropdown");
const Table = require("../mapFunctions/table");
const MaintenanceResult = require("../../general/results/maintenanceResult");
