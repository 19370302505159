"use strict";

const StreetSweepingLayer = function () {
  var inited = false;
  var esriKey, esriLayer, dataSortFieldKey;
  var messagesInited = false;

  var init = function () {
    if (inited) {
      return;
    }
    handleMessages();
    esriKey = "streetSweepingRoute";
    esriLayer = "streetSweepingRoute";
    dataSortFieldKey = Session.isNewDataDrivenRoadConditionGroup() ? "route_name" : "network_name";
  };

  var loadLayerListenersForMap = async function (map, mapLayers, mapId) {
    init();
    const esriLayerIds = EsriLayerFunctions.getLayerIds(esriKey);
    for (const layerName in esriLayerIds) {
      loadToggledListenerForMap(map, mapLayers, mapId, layerName);
    }

    loadTreeUpdateListenersForMap(map, mapLayers);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId, layerName) {
    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      var currentMapLayer = mapLayers[`${layerName}Layer`];
      if (isEnabled) {
        esriLayer = layerName;
        Tree.set("streetSweepingEsriLayer", layerName);
        Tree.set("enabledEsriLayers", [layerName]);
        if (currentMapLayer && (mapId === "modal" || (mapId === "main" && sameSpatialFilter))) {
          map.addLayer(currentMapLayer);
        } else {
          loadLayer(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, currentMapLayer)) {
          map.removeLayer(currentMapLayer);
        }
      }
    });
  };

  var loadLayer = async function () {
    await loadEsriLayer();
  };

  var handleMessages = function () {
    if (messagesInited) {
      return;
    }
    messagesInited = true;
    const groupId = Tree.get("activeGroup", "groupId");
    const channel = `streets.${groupId}`;

    Messages.subscribe({
      channel,
      event: "update",
      callback: loadLayer,
    });
  };

  var loadTreeUpdateListenersForMap = function (map, mapLayers) {
    Tree.select("filters").on("update", function () {
      const currentMapLayer = mapLayers[`${esriLayer}Layer`];
      if (
        MapFunctions.mapHasLayer(map, currentMapLayer) &&
        Tree.get("layers", esriLayer, "isEnabled")
      ) {
        loadLayer();
      }
    });
  };

  var getTopLevelDataSort = async function () {
    const token = await EsriLayerFunctions.getEsriLayerToken();
    const layerId = EsriLayerFunctions.getLayerId(esriKey, esriLayer);
    const esriLayerWhere = EsriLayerFunctions.getLayerDefsQuery(esriKey, esriLayer);
    const esriLayerUrl = EsriLayerFunctions.getEsriLayerUrl(esriKey);

    LoadingScreen.show();
    return await new Promise(function (resolve) {
      L.esri
        .query({
          url: `${esriLayerUrl}/${layerId}`,
        })
        .token(token)
        .returnGeometry(false)
        .fields([dataSortFieldKey])
        .distinct()
        .where(esriLayerWhere)
        .run(function (error, featureCollection) {
          LoadingScreen.hide();
          if (error) {
            console.log(error);
            return null;
          }

          var data = featureCollection.features.map((feature) => feature.properties);
          resolve({
            dataSort: { sweepingNetworks: data },
            spatialGrouping: null,
          });
        });
    });
  };

  var loadEsriLayer = async function (map = MainMap.getMap(), mapLayers = MainMap.getMapLayers()) {
    if (!mapLayers[`${esriLayer}Layer`]) {
      mapLayers[`${esriLayer}Layer`] = await createEsriLayer();
    } else {
      const layerId = EsriLayerFunctions.getLayerId(esriKey, esriLayer);
      const currentLayerDefs = EsriLayerFunctions.getLayerDefs(esriKey, esriLayer);
      const sameDynamicLayerDefs =
        currentLayerDefs?.[layerId] === mapLayers[`${esriLayer}Layer`].getLayerDefs()?.[layerId];
      if (!sameDynamicLayerDefs) {
        mapLayers[`${esriLayer}Layer`].setLayerDefs(currentLayerDefs);
      }
    }
    mapLayers.streetSweepingLayer = mapLayers[`${esriLayer}Layer`];
    mapLayers.streetSweepingLayer.redraw();

    if (Tree.get("layers", esriLayer, "isEnabled")) {
      mapLayers.streetSweepingLayer.addTo(map);
    }
    return mapLayers.streetSweepingLayer;
  };

  var createEsriLayer = async function () {
    const mapLayerOptions = {
      pane: "overCatchmentPane",
      layerDefs: EsriLayerFunctions.getLayerDefs(esriKey, esriLayer),
    };
    var layer = await EsriLayerFunctions.getEsriLayer(esriKey, esriLayer, mapLayerOptions);
    return layer;
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getTopLevelDataSort,
  };
};

module.exports = StreetSweepingLayer();

const EsriLayerFunctions = require("../mapFunctions/esriLayerFunctions");
const LoadingScreen = require("../general/loadingScreen");
const MainMap = require("../mapFunctions/mainMap");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
const Session = require("../login/session");
const Messages = require("../messages");
