"use strict";

const StructuralBmpsGeoServerLayer = function () {
  const layerName = "structuralBmps";
  const layerKey = "structuralBmpsLayer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, { icon: createIconFunction(feature) });
  };

  var createIconFunction = function (feature) {
    return BmpFcsIcon.getIconMarker(
      feature.properties.bmpMapScore,
      feature.properties.isCentralized,
      feature.properties.bmp_type_abbr,
      feature.properties.isFcs,
      feature.properties.isDue,
      feature.properties.phase,
      false,
      false,
      Tree.get("zoomLevel"),
    );
  };

  var getTableIcon = function (bmp) {
    return BmpFcsIcon.getTableIcon(
      bmp.bmpMapScore,
      bmp.isCentralized,
      bmp.isFcs,
      bmp.isDue,
      bmp.phase,
    );
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const structuralBmpsGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
      createIconFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, structuralBmpsGeoServerLayer);

    structuralBmpsGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    structuralBmpsGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
    loadConditionPhaseToggleUpdatedListener();
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var loadConditionPhaseToggleUpdatedListener = function (map, mapLayers) {
    Tree.select(["filters", "conditionPhaseToggle"]).on("update", function (e) {
      if (e.data.previousData !== e.data.currentData && Tree.get("dataView") === "bmp") {
        const toggle = e.data.currentData;

        if (toggle === "condition") {
          Filters.updateConditionCheckboxes(["completed", "certified"]);
          Tree.set(["filters", "phase"], ["completed", "certified"]);
        } else {
          Filters.updatePhaseCheckboxes([
            "planning",
            "planned",
            "construction",
            "post-active",
            "completed",
            "certified",
          ]);
          Tree.set(
            ["filters", "phase"],
            ["planning", "planned", "construction", "post-active", "completed", "certified"],
          );
        }
        MultipleSelect.updateDropdown($('.dropdown input[name="phase"]'));
        FilterSummary.render();
      }
    });
  };

  var organizeMuniLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    return {
      id: data.idbmp,
      idbmp: data.idbmp,
      idBmp: data.idbmp,
      address: data.address,
      bmpName: data.bmp_id,
      groupId: data.group_id,
      geometry: geometry,
      bmpTypeObj: {
        id: data.bmp_type_num,
        sortOrder: data.bmp_type,
      },
      bmpTypeId: data.bmp_type_num,
      implementerType: data.implementer_type,
      bmpType: data.bmp_type,
      drainageAreaAcres: data.bmp_drainarea,
      facility: data.facility,
      imperviousAreaAcres: data.bmp_imparea,
      inletType: data.inlet_type,
      inaccessible: data.system_access,
      assesmentDate: data.obs_date,
      installationDate: data.bmp_creation,
      isFcs: data.fcs,
      fcs: data.fcs,
      permissions: {
        canBeEdited: BmpFcsFunctions.canEditBmpFcs(data.idbmp, data.phase),
        canCreateRoutineInspection: ToolSettings.getSetting("permissions", "can_inspect_bmps"),
        canCreateObservation: ToolSettings.getSetting("permissions", "can_inspect_bmps"),
        canCreateBenchmark: ToolSettings.getSetting("permissions", "can_assess_bmps"),
        canCreateFunctionalInspection: ToolSettings.getSetting("permissions", "can_assess_bmps"),
      },
      phase: data.phase,
      responsiblePartyMaintenance: data.responsible_party_maintenance,
      score: data.bmp_score,
      inProximityTo303dWaterbody: data.in_proximity_to_303d_waterbody,
      bmpFlow: intToBool(data.bmp_flow),
      drainsout: intToBool(data.drainsout),
      diversion: intToBool(data.diversion),
      cof_final_text: data.cof_final_text,
      cof: data.cof_final_text,
      bmp_wqcap: data.bmp_wqcap,
      bmp_type: data.bmp_type,
      bmp_stat: data.bmp_stat,
      bmpStatus: data.bmp_status,
      skipObservation: data.skipobservation,
      subject: data.subject,
      bmp_type_abbr: data.bmp_type_abbr,
      isCentralized: data.cbmp,
      isDue: data.isdue,
      fcs_score: data.fcs_score,
      bmp_score: data.bmp_score,
      bmpMapScore: data.bmp_map_score,
      bmp_imparea: data.bmp_imparea,
      iconFields: {
        bmpScore: data.bmpMapScore,
        centralized: data.cbmp,
        fcs: data.fcs,
        pastDue: data.isdue,
        phase: data.phase,
      },
      currentBenchmarkHasObs: data.current_benchmark_has_obs,
      currentBenchmarkComplete: data.current_benchmark_complete,
      currentBenchmarkId: data.current_benchmark_id,
      currentObsComplete: data.currentobscomplete,
      planned: data.planned,
      system_access: data.system_access,
      bmp_kind: data.bmp_type_num,
      inv_sedacc: data.inv_sedacc,
      inv_stwat: data.inv_stwat,
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
    };
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeMuniLayerData(data);
  };

  var intToBool = function (datum) {
    if (datum === 1) {
      return true;
    } else if (datum === 0) {
      return false;
    } else {
      return null;
    }
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeMuniLayerData,
    getLayerData,
    getTableIcon,
  };
};

module.exports = StructuralBmpsGeoServerLayer();

const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const Filters = require("../mapFunctions/filters");
const FilterSummary = require("../filters/filterSummary");
const MultipleSelect = require("../general/multipleSelect");
