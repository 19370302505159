"use strict";

const LidSelfInspectionHistory = function () {
  var load = function (project) {
    if (project) {
      renderLidSelfInspectionHistory(project);
    }
  };

  var renderLidSelfInspectionHistory = function (data) {
    var readOnly = Session.isRegulator() || !data.canEditInspections;
    var html = nunjucks.render("popups/history/lidSelfInspectionHistory.njk", {
      inspections: data.lidHistory,
      readOnly: readOnly,
      canDeleteInspection: data.canDeleteInspections,
    });
    $(".popup-history-pane").html(html);
  };

  var editHistory = async function (tableRowData, readOnly) {
    const inspectionId = tableRowData.id;
    const modalAction = tableRowData.modalAction;
    const noticeData = await ApiCalls.getLidSelfInspection(inspectionId, modalAction);
    const projectId = noticeData.send?.projectId ?? noticeData.receive?.projectId;

    LetterCommunicationModal.renderAndShow([projectId], {
      noticeData,
      readOnly,
    });
  };

  var confirmDeleteHistory = function ($todoTr, tableRowData) {
    var selectedId = tableRowData.id;
    var action = tableRowData.action;

    MessageModal.showDeleteRecordModal(() => {
      deleteHistory(selectedId, action);
      $todoTr.remove();
    });
  };

  var deleteHistory = function (id, action) {
    ApiCalls.deleteLidSelfInspection(id, action, LidProjectLayer.loadLayer);
  };

  return {
    load,
    editHistory,
    confirmDeleteHistory,
  };
};

module.exports = LidSelfInspectionHistory();

const ApiCalls = require("../apiCalls");
const Session = require("../login/session");
const MessageModal = require("../modals/messageModal");
const LidProjectLayer = require("../lid/lidProjectLayer");
const LetterCommunicationModal = require("./letterCommunicationModal");
