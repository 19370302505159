"use strict";

const NunjucksFilters = function () {
  const run = function (env = nunjucks.configure()) {
    const filters = { ...RamNunjucksFilters.getFilters(), ...getFilters() };
    for (const filterName in filters) {
      env.addFilter(filterName, filters[filterName]);
    }
  };

  var getFilters = function () {
    return {
      noSpaces,
      decimalToPercent,
      roundToSigFig,
      valueToHeaderColor,
    };
  };

  const noSpaces = function (str) {
    return str.replace(/\/|\\|\s|\\/g, "_", str).toLowerCase();
  };

  const decimalToPercent = function (data) {
    var number = parseFloat(data);
    if (!isNaN(number)) {
      return number * 100;
    }
    return RamNunjucksFilters.displayFallback;
  };

  const roundToSigFig = function (data) {
    var number = parseFloat(data);

    if (!isNaN(number)) {
      return CleanData.toStringFirstSigFig(number);
    }

    console.error(`Non-number passed to numberRoundedToSigFig(${data})`);
    return RamNunjucksFilters.displayFallback;
  };

  const valueToHeaderColor = function (data) {
    const mapId = Tree.get("mapId");
    const dataView = Tree.get("dataView");
    var number = parseFloat(data);
    if (!isNaN(number)) {
      return MapStyles.colorMapBasedOnView(dataView, mapId, data);
    }

    console.error(`Non-number passed to valueToHeaderColor(${data})`);
    return RamNunjucksFilters.displayFallback;
  };

  return { run };
};

module.exports = NunjucksFilters();

const CleanData = require("../mapFunctions/cleanData");
const MapStyles = require("../mapFunctions/mapStyles");
const Tree = require("../../tree");
const RamNunjucksFilters = require("../../general/nunjucksFilters");
