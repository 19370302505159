"use strict";

const AssetManagementFilters = function () {
  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {};

  // Also filtering in API: AssetManagementProvider@addFiltersToQuery
  var addGeoServerFilterQuery = function (queryStrings, filters) {
    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "phase", "phase");
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "responsiblePartyMaintenance",
      "responsible_party_maintenance",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "attributionStatus",
      "attributed",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "probabilityOfFailure",
      "pof_final_text",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "consequenceOfFailure",
      "cof_final_text",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "criticality",
      "criticality_text",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "size", "size_text");
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "material",
      "material_text",
    );
    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "install_date",
        filters.installDate?.from,
        filters.installDate?.to,
        filters.installDate?.noDate,
      );
    }
  };

  var addGeoServerViewParams = function (params, filters) {};

  var getFilterConfigs = function () {
    return {};
  };

  var loadListeners = function () {};

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, highwayOptions } =
      Filters.getSpatialArrays();

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      highwayOptions,
      cofOptions: ToolSettings.getDomainOptions("consequence_of_failure"),
      attributionStatusOptions: _completeIncompleteOptions(),
      sizeOptions: _completeIncompleteNaOptions(),
      materialOptions: _completeIncompleteNaOptions(),
      phaseOptions: _getAloCustomPhases(),
      responsiblePartyOptions: _getCustomAloMaintainedByOptions(),
    };
  };

  var _completeIncompleteOptions = () => [
    { name: "Complete", value: "true" },
    { name: "Incomplete", value: "false" },
  ];

  var _completeIncompleteNaOptions = () => [
    { name: "Complete", value: "complete" },
    { name: "Incomplete", value: "incomplete" },
    { name: "Not Applicable", value: null },
  ];

  var _getAloCustomPhases = () => [
    { name: "Planning", value: "planning" },
    { name: "Design", value: "planned" },
    { name: "Construction", value: "construction" },
    { name: "Post Active", value: "post-active" },
    { name: "Completed", value: "completed" },
    { name: "Certified", value: "certified" },
  ];

  var _getCustomAloMaintainedByOptions = () => [
    { name: "Local Agency", value: "local-agency" },
    { name: "MS4", value: "ms4" },
    { name: "Other", value: "other" },
    { name: "Our Agency", value: "our-agency" },
    { name: "Partner", value: "partner" },
    { name: "Private", value: "private" },
    { name: "Unknown", value: "unknown" },
  ];

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
    addGeoServerViewParams,
    getFilterConfigs,
    loadListeners,
    getProps,
  };
};

module.exports = AssetManagementFilters();

const Filters = require("../../mapFunctions/filters");
const GeoServerFilterFunctions = require("../../mapFunctions/geoServerFilterFunctions");
const ToolSettings = require("../../settings/toolSettings");
