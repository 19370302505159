"use strict";

const RoutineMaintenanceCondition = function () {
  const stringKey = "condition";
  var $page;

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    RoutineMaintenanceFormTable.loadListeners($page);
    $page
      .find(".structural-condition")
      .on("2N:FormInput", `input, select`, renderCapacityRemaining);
  };

  var unloadListeners = function () {
    $page
      .find(".structural-condition")
      .off("2N:FormInput", `input, select`, renderCapacityRemaining);
    RoutineMaintenanceFormTable.unloadListeners($page);
  };

  var getProps = function (data) {
    return {
      trashRemovalVolumeUnitOptions: RoutineMaintenanceConstants.trashRemovalVolumeUnitOptions,
      totalRemainingCapacityPercentage: getTotalRemainingCapacityPercentage(
        data.totalDepth,
        data.totalDepthUnit,
        data.structuralConditionIssues,
      ),
      debrisCloggingCorrected: getDebrisCloggingCorrected(data.structuralConditionIssues),
      catchBasinInspection: true,
    };
  };

  var getTotalRemainingCapacityPercentage = function (depth, units, issues) {
    const totalDepthFeet = _getTotalDepthFeet(depth, units);
    const debrisCloggingDepthFeet = _getDebrisCloggingDepthFeet(issues);

    if (!totalDepthFeet) {
      return null;
    }

    let totalRemainingCapacity = (totalDepthFeet - debrisCloggingDepthFeet) / totalDepthFeet;
    totalRemainingCapacity = totalRemainingCapacity * 100;

    return Math.max(0, totalRemainingCapacity);
  };

  var _getTotalDepthFeet = function (depth, units) {
    const parsedDepth = Number(depth);

    if (Number.isNaN(parsedDepth) || depth === null) {
      return null;
    }

    // The depth is always in feet when returned from the DB, but it
    // can be other units if the user edits it during an inspection.
    return UnitConversion.convertVal(parsedDepth, units ?? "feet", "feet");
  };

  var _getDebrisCloggingDepthFeet = function (issues) {
    const debrisClogging = issues.find((issue) => issue.key === "debris-clogging");
    const depthData = debrisClogging?.dataNumber;
    const depthUnits = debrisClogging?.dataOption;

    if (typeof depthData !== "number" || debrisClogging?.issue === false) {
      return 0;
    }

    return UnitConversion.convertVal(depthData, depthUnits, "feet");
  };

  var getDebrisCloggingCorrected = function (issues) {
    const debrisClogging = issues.find((issue) => issue.key === "debris-clogging");

    if (!debrisClogging) {
      return false;
    }

    return debrisClogging.issue === true && debrisClogging.correction === "cleaned";
  };

  var renderCapacityRemaining = function () {
    if (!$page) {
      return;
    }

    let props = RoutineMaintenanceController.getRoutineMaintenanceData();
    props = getProps(props);
    const html = nunjucks.render("modals/routineMaintenance/sedimentTrashCapacitySection.njk", {
      props,
    });
    $page.find(".sediment-trash-capacity-section").html(html);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormTable.getConditionTabName();
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _getTotalDepthFeet,
    _getDebrisCloggingDepthFeet,
    renderCapacityRemaining,
  };
};

module.exports = RoutineMaintenanceCondition();

const RoutineMaintenanceConstants = require("../routineMaintenanceConstants");
const RoutineMaintenanceController = require("../routineMaintenanceController");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const UnitConversion = require("../../unitConversion");
