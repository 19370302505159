"use strict";

const IncidentInvestigationFollowUpClose = function () {
  const stringKey = "followUpClose";
  const tabHeader = "Follow Up & Close";
  let $page;
  let defaultData;

  var loadListeners = function (data) {
    defaultData = data;
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on("2N:FormInput", `[name="follow-up-needed"]`, onFollowUpNeededToggle);
    $page.on("2N:FormInput", `[name="close-out-investigation"]`, onCloseOutInvestigationToggle);

    toggleFollowUpDateField($page, data.followUpNeeded);
    toggleCloseOutReasonField($page, data.closeOutInvestigation);
    IncidentInvestigationController.renderInvestigationTotalTime(
      data.investigationStartTime,
      data.investigationEndTime,
    );

    flatpickr("#investigation-end-time", {
      enableTime: true,
      noCalendar: true,
      time_24hr: true,
      dateFormat: "H:i",
    });
    $page.on("2N:FormInput", `[name="investigation-end-time"]`, onInvestigationEndTimeChange);
  };

  var unloadListeners = function () {
    $page.off("2N:FormInput", `[name="follow-up-needed"]`, onFollowUpNeededToggle);
    $page.off("2N:FormInput", `[name="close-out-investigation"]`, onCloseOutInvestigationToggle);
    $page.off("2N:FormInput", `[name="investigation-end-time"]`, onInvestigationEndTimeChange);
  };

  var getProps = function () {
    return {
      closeOutReasonOptions: IncidentInvestigationConstants.getCloseOutReasonOptions(),
      followUpActionOptions: IncidentInvestigationConstants.getFollowUpActionOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onFollowUpNeededToggle = function (e, followUpNeeded) {
    toggleFollowUpDateField($page, followUpNeeded);
  };

  var toggleFollowUpDateField = function ($page, followUpNeeded) {
    $("fieldset.follow-up-needed-fields").toggle(followUpNeeded);
  };

  var onCloseOutInvestigationToggle = function (e, closeOutInvestigation) {
    toggleCloseOutReasonField($page, closeOutInvestigation);
  };

  var toggleCloseOutReasonField = function ($page, closeOutInvestigation) {
    $("fieldset.close-out-reason").toggle(closeOutInvestigation);
  };

  var onInvestigationEndTimeChange = function (
    e,
    newValue,
    pathParts,
    formState,
    formKey,
    oldValue,
  ) {
    const endTime = newValue;
    const startTime =
      IncidentInvestigationController.getFormData(["investigationStartTime"]) ||
      defaultData.investigationStartTime;

    if (startTime && startTime > endTime) {
      MessageModal.showSimpleWarningModal(
        "Investigation End Time must be later than Investigation Start Time.",
      );
      if (!oldValue) {
        oldValue = defaultData.investigationEndTime;
      }
      Form.manuallySetDateTime(
        "incident-investigation",
        ["investigationEndTime"],
        oldValue,
        $(e.currentTarget),
      );
    } else {
      IncidentInvestigationController.renderInvestigationTotalTime(startTime, endTime);
    }
  };

  return { loadListeners, unloadListeners, getStringKey, getTabHeader, getProps };
};

module.exports = IncidentInvestigationFollowUpClose();

const Form = require("../../general/form");
const IncidentInvestigationController = require("./incidentInvestigationController");
const IncidentInvestigationConstants = require("./incidentInvestigationConstants");
const MessageModal = require("../../modals/messageModal");
const flatpickr = require("flatpickr");
