"use strict";

const FilterDom = function () {
  var filterByText = function (searchString, $selector, options = []) {
    const { selectorTextSelector, parentSelector, parentTextSelector } = options;
    searchString = searchString.trim().toLowerCase();
    if (searchString.length === 0) {
      $(".filtering").removeClass("filtering match");
      return;
    }
    $selector.addClass("filtering").removeClass("match");
    if (parentSelector) {
      $selector.parents(parentSelector).addClass("filtering").removeClass("match");
    }
    $selector.each(function () {
      let $this = $(this);
      if (selectorTextSelector) $this = $this.find(selectorTextSelector);

      let text = "";
      if ($this.data("searchString")) {
        text = $this.data("searchString").trim().toLowerCase();
      } else {
        text = $this.text().trim().toLowerCase();
      }

      if (parentTextSelector) {
        text += $(this).parents(".filtering").find(parentTextSelector).text().trim().toLowerCase();
      }
      if (text.includes(searchString)) {
        $(this).addClass("match").parents(".filtering").addClass("match");
      }
    });
  };

  return {
    filterByText,
  };
};

module.exports = FilterDom();
