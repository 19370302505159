"use strict";

const EsgRouteFunctions = function () {
  const defaultMaxMobileWidth = 770; // add mobileWidth to any route in EsgRoutes to override this
  let lastRouteName;
  let _isNewRoute = true;

  const isNewRoute = function () {
    return _isNewRoute;
  };

  const setupGlobalHooks = function (router) {
    router.notFound(function () {
      UrlRoutes.go("home");
    });

    router.hooks({
      before: function (done, match) {
        _isNewRoute = lastRouteName !== match.route.name;
        lastRouteName = match.route.name;
        _addMobileListeners(match.route.name);
        if (!Session.isLoggedIn() && match.route.name !== "home") {
          location = "/";
          done(false);
        } else {
          done();
        }
      },
      after: function (match) {
        const Analytics = require("../../general/analytics");
        Analytics.sendScreenView([match.url]);
      },
      already: function (match) {
        _isNewRoute = false;
        MegaMenu.close();
      },
      leave: function (done) {
        const PageController = require("../../esg/pageController");
        PageController.saveFilters();
        done();
      },
    });
  };

  const _addMobileListeners = function (routeName) {
    _warnIfMobileNotSupported(routeName);
    $(window).on("resize", () => {
      _warnIfMobileNotSupported(routeName);
    });
  };

  const _warnIfMobileNotSupported = function (routeName) {
    const route = _getEsgRouteByName(routeName);
    const tooSmallForMobile = $(window).width() < (route?.mobileWidth || defaultMaxMobileWidth);
    $("body").toggleClass("mobile-is-not-supported", tooSmallForMobile && !route?.mobileFriendly);
  };

  const _getEsgRouteByName = function (routeName) {
    const EsgRoutes = require("./esgRoutes");
    return Object.values(EsgRoutes).find((route) => route.as === routeName);
  };

  return {
    setupGlobalHooks,
    isNewRoute,
    _getEsgRouteByName,
  };
};

module.exports = EsgRouteFunctions();

const MegaMenu = require("../../esg/megaMenu");
const Session = require("../../login/session");
const UrlRoutes = require("../urlRoutes");
