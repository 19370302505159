"use strict";

const MuniCatchBasinDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareMuniCatchBasins,
        template: "list/muniCatchBasinDataList.njk",
        layerName: "muniCatchBasin",
      };
      DataList.init(config);
      initialRender();
      DataList.loadDomListeners([{ target: ".open-fact-sheet", callback: openGenericFactSheet }]);
      loadListeners();
    }
  };

  var loadListeners = function () {
    $(".data-list-container").off("click", ".map-button");
    $(".data-list-container").on("click", ".map-button", showMarkerOnMap);
  };

  var showMarkerOnMap = function () {
    var $tr = $(this).parents("tr");
    var id = $tr.data("id");

    MapFunctions.openPopupById(id);
    const mapButtonContainer = $(this).parents("td");
    DataList.addAnalyticsToMapIconOnAddress(mapButtonContainer.hasClass("address-col"));
  };

  var initialRender = function () {
    DataList.setSorting("bmpName", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var _prepareMuniCatchBasins = function (muniCatchBasins) {
    sortMuniCatchBasinsBy(muniCatchBasins);

    for (const catchBasin of muniCatchBasins) {
      catchBasin.inletTypeDisplayName = Misc.getOptionNameByValue(
        BmpFcsInventoryConstants.inletTypeOptions,
        catchBasin.inletType,
      );

      catchBasin.iconClass = MuniCatchBasinIcon.getIconClass(
        catchBasin.score,
        catchBasin.routineMaintenanceDue,
        catchBasin.isCatchBasinHighPriority,
      );
    }

    return {
      rows: muniCatchBasins,
      options: {},
    };
  };

  var sortMuniCatchBasinsBy = function (muniCatchBasins) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;

    var sortersDict = {
      "catchment.catchid": alphaSorter,
      bmpName: alphaSorter,
      address: alphaSorter,
      totalDepth: numberSorter,
      bmpDisplayType: alphaSorter,
      cof: SortUtilities.cofSorter,
    };
    DataList.sortWithDict(muniCatchBasins, sortersDict);
  };

  var openGenericFactSheet = function () {
    $("#details-button-container").addClass("desktop-only");
    const id = DataList.getIdFromRow($(this));
    CatchBasinFactSheetController.initFactSheet(id);
    Analytics.sendDataListEvent("open_factsheet", "catch-basin");
  };

  return {
    init,
    _prepareMuniCatchBasins,
    render,
  };
};

module.exports = MuniCatchBasinDataListController();

const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants.js");
const CatchBasinFactSheetController = require("../muni/catchBasinFactSheetController.js");
const DataList = require("../mapFunctions/dataList");
const SortUtilities = require("../general/sortUtilities.js");
const Misc = require("../misc.js");
const MuniCatchBasinIcon = require("../muni/muniCatchBasinIcon.js");
const MapFunctions = require("../mapFunctions/mapFunctions.js");
const Analytics = require("../general/analytics.js");
