"use strict";

const RouteFunctions = require("./routeFunctions");
const RoutineMaintenanceController = require("../../bmpfcs/routineMaintenanceController");

const BmpFcsRoutes = function () {
  const routineInspectionRoute = {
    handler: function ({ data, params }) {
      return RouteFunctions.openNewOrExistingInspection({
        openInspectionFn: RoutineMaintenanceController.showRoutineMaintenanceModal,
        resourceKey: "bmpRoutineMaintenanceInspection",
        assetId: data.idbmp,
        inspectionId: data.inspectionId,
        params: params,
      });
    },
    hooks: {
      leave: RouteFunctions.leaveTabbedModalRoute,
    },
    data: {
      dataView: "bmp",
    },
  };

  return { routineInspectionRoute };
};

module.exports = BmpFcsRoutes();
