"use strict";

const ProjectInventoryAddBmps = function () {
  const stringKey = "project-add-bmps";
  const headerInformation = "Assets";
  const bmpLayerName = "projectAssociatedBmp";
  var firstLoad = true;
  var $page;
  var isValid = true;

  var loadListeners = function () {
    ModalMap.zoomToMarker();
    Form.initializeAndLoadListeners($("#floating-add-bmps-table"), "project-inventory", {
      isMultiPart: true,
    });
  };

  var loadAssociatedFormListeners = function (data) {
    Form.initializeAndLoadListeners($page, "project-inventory", { isMultiPart: true });
    $page.on("focus", `[name*="bmps["]`, function () {
      Tree.set("focusedInputName", $(this).attr("name"));
    });
    $page.on("change", `[name*="bmps["]`, _handleInputChange);
  };

  var _handleInputChange = function () {
    const $this = $(this),
      idBmp = $this.closest(".assets-page").data("id-bmp"),
      value = $this.val(),
      fieldName = ProjectInventoryConstants.extractName($this.attr("name")),
      name = ProjectInventoryConstants.getAttributeMap()[fieldName],
      updateName = `${name}Updated`,
      newData = {
        [name]: value,
        [updateName]: true,
      };
    if (name === undefined) {
      throw new Error(`Column name not listed for "${fieldName}" in attribute map`);
    }
    ProjectAssociatedBmpLayer.updateDataByIdBmp(idBmp, newData);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = function () {
    if (firstLoad) {
      checkNoBmpWarning();
    }
    const associatedBmps = Tree.get("layers", bmpLayerName, "dataModal");
    const addBmpEnabled = isAddBmpEnabled();

    AddBmpsMap.init(bmpLayerName);
    renderAddBmpsHtml(associatedBmps);
    _addAndRemoveMarginClass();
    loadListeners();
    $(".assets-page").hide();
    showAddBmpsMap(addBmpEnabled);
    if (associatedBmps.length > 0) {
      const topRow = associatedBmps[0].idbmp;

      $(`.assets-page[data-id-bmp="${topRow}"]`).show();
      var $addBmpsTable = $("#add-bmps-table");
      $addBmpsTable.find(`tr[data-id-bmp="${topRow}"]`).addClass("selected");
    }

    setAddBmpsHint(addBmpEnabled);
    ProjectInventoryModalController.setModalTitle();
    ModalMap.zoomToMarker();
    DrawController.zoomToDrainage();
    firstLoad = false;
  };

  var _addAndRemoveMarginClass = function () {
    $(".modal-dynamic-content").addClass("add-bmp-no-margin").removeClass("add-area-low-margin");
  };
  var isAddBmpEnabled = function () {
    return true;
  };

  var renderAddBmpsHtml = function (data) {
    const volumeUnitOptions = BmpFcsInventoryConstants.volumeUnitOptions;
    const areaUnitOptions = BmpFcsInventoryConstants.areaUnitOptions;
    const wetPoolCapacityUnitOptions = BmpFcsInventoryConstants.wetPoolCapacityUnitOptions;
    $(".modal-dynamic-content").show();

    const html = nunjucks.render(
      "modals/projectInventory/projectInventoryAddBmpsDetailsForm.njk",

      {
        data,
        volumeUnitOptions,
        areaUnitOptions,
        wetPoolCapacityUnitOptions,
      },
    );
    ProjectInventoryModalController.renderPageContent(html);
    $(".add-bmps-details-down-arrow").on("click", collapseForm);

    $(".assets-page").hide();

    const selectedRow = $(".add-bmps-table-rows .selected").data("id-bmp");
    $(`.assets-page[data-id-bmp="${selectedRow}"]`).show();

    $page = $("#inventory-modal .add-bmps-details-container");
    loadAssociatedFormListeners(data);
    handleFocusOnRender();
    const hasSaved = Tree.get("hasSaved");
    if (hasSaved) {
      const fields = FormValidator.getRequireNotBlankFields();
      CommonModalFunctions.highlightFormFields($("#inventory-modal"), fields);
    }
  };

  var handleFocusOnRender = function () {
    const focusedInputName = Tree.get("focusedInputName");
    const isnum = /^\d+$/.test(focusedInputName);
    if (focusedInputName) {
      if (!isnum) {
        $("input[name='" + focusedInputName + "']").focus();
      } else {
        const $tr = $(`tr[data-id-bmp="${focusedInputName}"]`);
        $tr.find('input[name="bmp-name"]').focus();
      }
    }
  };

  var collapseForm = function () {
    $(".add-bmps-details-down-arrow > img").remove();
    $(".add-bmps-details-down-arrow").prepend(
      '<img class="add-bmps-table-arrows" src="/ram/images/assetManagement/up_arrow.png">',
    );
    $(".add-bmps-details-down-arrow")
      .addClass("add-bmps-details-up-arrow")
      .removeClass("add-bmps-details-down-arrow");
    $(".add-bmps-details-container").hide();
    $(".add-bmps-drainage-container").hide();
    removeFormArrowListeners();
    $(".add-bmps-details-up-arrow").on("click", openForm);
  };

  var openForm = function () {
    $(".add-bmps-details-up-arrow > img").remove();
    $(".add-bmps-details-up-arrow").prepend(
      '<img class="add-bmps-table-arrows" src="/ram/images/assetManagement/summary_button.png">',
    );
    $(".add-bmps-details-up-arrow")
      .addClass("add-bmps-details-down-arrow")
      .removeClass("add-bmps-details-up-arrow");
    $(".add-bmps-details-container").show();
    $(".add-bmps-drainage-container").show();
    removeFormArrowListeners();
    $(".add-bmps-details-down-arrow").on("click", collapseForm);
  };

  var removeFormArrowListeners = function () {
    $(".add-bmps-details-up-arrow").off();
    $(".add-bmps-details-down-arrow").off();
  };
  var showAddBmpsMap = function (addBmpEnabled) {
    const projectAddBmpsMapConfiguration = {
      isValidUpdateHandler: isValidUpdateHandler,
      newExistingMarkerHandler: ProjectInventoryModalController.loadExistingProjectMarker,
      disableAddNewProjectBmp: !addBmpEnabled,
    };
    AddBmpsMap.resetAddBmpsMapHandlers();
    AddBmpsMap.configureAddBmpsMap(projectAddBmpsMapConfiguration);
    AddBmpsMap.showAddBmpsMap();
  };

  var isValidUpdateHandler = function (newBmpClickEnabled) {
    isValid = newBmpClickEnabled;
  };

  var setAddBmpsHint = function (addBmpsEnabled) {
    if (addBmpsEnabled) {
      ModalMap.showLocationMessage("Click map to add sBMP");
    }
  };

  var validateAddBmpsAndShowWarningModal = function () {
    if (!isValid) {
      MessageModal.showSimpleWarningModal(
        "<p>You have added a Structural BMP that does not have an <b>ID</b>.<br>In order to leave this page, <b>you must add an ID</b> or delete the Structural BMP.</p>",
        true,
      );
    }
    return isValid;
  };

  var cleanUp = function () {
    AddBmpsMap.hideAddBmpsMap();
    $page.empty();
    return true;
  };

  var validate = function () {
    return validateAddBmpsAndShowWarningModal();
  };

  var checkNoBmpWarning = function () {
    var currentDataView = Tree.get("dataView");
    var existingBmps = ProjectInventoryModalController.getExistingConstructionData(["bmps"]);
    if (!existingBmps || !existingBmps.length) {
      var confirmCallback = function () {
        InventoryModal.cleanUpPage();
        InventoryModal.setActivePageByKey("project-contacts");
      };
      if (currentDataView !== "construction-project-delivery") {
        MessageModal.showConfirmWarningModal(
          `Do you want to add Structural BMPs at this time? You can always return to this form at another time. Click "Yes" to add structural BMPs to this project. Click "No" to continue without adding a sBMP. `,
          confirmCallback,
          "Yes",
          "No",
          null,
          () => {},
          "Add Structural BMPs?",
        );
      }
    }
  };

  var resetState = function () {
    firstLoad = true;
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    resetState,
    renderAddBmpsHtml,
    showAddBmpsMap,
    _addAndRemoveMarginClass,
    _handleInputChange,
    handleFocusOnRender,
  };
};

module.exports = ProjectInventoryAddBmps();

const AddBmpsMap = require("../mapFunctions/addBmpsMap");
const Form = require("../general/form");
const InventoryModal = require("../general/inventoryModal");
const MessageModal = require("../modals/messageModal");
const ModalMap = require("../mapFunctions/modalMap");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const Tree = require("../tree");
const ProjectAssociatedBmpLayer = require("../construction/projectAssociatedBmpLayer");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const FormValidator = require("../general/formValidator");
const ProjectInventoryConstants = require("./projectInventoryConstants");
const DrawController = require("../mapFunctions/drawController");
