"use strict";

var SaasAgreementModal = function () {
  var $modal;

  var startSaasAgreement = function () {
    $modal = $("#saasAgreementModal");
    $modal.modal({ backdrop: "static", keyboard: false });

    setSaasAgreementModalListeners();
  };

  var setSaasAgreementModalListeners = function () {
    $modal.find(".cancel").off("click", saasAgreementCancelClick);
    $modal.find(".cancel").on("click", saasAgreementCancelClick);

    $modal.find(".confirm").off("click", saasAgreementConfirmClick);
    $modal.find(".confirm").on("click", saasAgreementConfirmClick);

    $modal.find(".agreement-checkbox").off("click", agreementChecked);
    $modal.find(".agreement-checkbox").on("click", agreementChecked);
  };

  var saasAgreementCancelClick = function () {
    Login.logout();
    $modal.modal("hide");
  };

  var saasAgreementConfirmClick = function () {
    ApiCalls.signSaasAgreement(function () {
      $modal.modal("hide");
    });
    Login.setSessionSaasAgreementTrue();
  };

  var agreementChecked = function () {
    const isChecked = this.checked;

    $modal.find(".confirm").attr("disabled", !isChecked);
  };

  return { startSaasAgreement };
};

module.exports = SaasAgreementModal();

const Login = require("../login/login");
const ApiCalls = require("../apiCalls");
