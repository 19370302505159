"use strict";

const MuniBmpFilters = function () {
  const searchFields = ["bmpName", "address"];
  const searchDbFields = ["bmp_id", "address"];
  let mainSmartFilters;

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          MuniBmpGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }
    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData, filters) {
    if (filters.toDoFilters) {
      return unfilteredData.filter((datum) => {
        return ResourceFilters.passToDoFilters(datum, filters);
      });
    } else {
      return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
    }
  };

  var onlineFilterIsSet = function (filters) {
    if (filters.toDoFilters) {
      return ResourceFilters.toDoSpatialFiltersAreSet(filters.toDoFilters);
    } else {
      return (
        ResourceFilters.spatialFiltersAreSet(filters) ||
        ResourceFilters.isDateFilterSet(filters.routineDate) ||
        ResourceFilters.isDateFilterSet(filters.repairDate)
      );
    }
  };

  var addGeoServerViewParams = function (params, filters) {
    if (!FilterSummary.areAllCheckboxesChecked("correctiveAction")) {
      GeoServerFilterFunctions.addAdditionalInfoParams(
        filters,
        params,
        "routineRepair",
        true,
        false,
      );
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("correctiveAction") &&
      $('input[name="correctiveAction"][value="no-action"]').is(":checked")
    ) {
      params[`includeNoAction`] = true;
    }
    if (!FilterSummary.areAllCheckboxesChecked("enforcementLevel")) {
      GeoServerFilterFunctions.addAdditionalInfoParams(
        filters,
        params,
        "enforcementLevel",
        false,
        true,
      );
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("enforcementLevel") &&
      $('input[name="enforcementLevel"][value="null"]').is(":checked")
    ) {
      params[`includeNoActionLevel`] = true;
    }
    ResourceFilters.addDateParam(
      params,
      "routine",
      filters.routineDate?.from,
      filters.routineDate?.to,
    );
    ResourceFilters.addDateParam(
      params,
      "repair",
      filters.repairDate?.from,
      filters.repairDate?.to,
    );
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }

    GeoServerFilterFunctions.addGeographicalFocusFiltersToQuery(filters, queryStrings);

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementerType",
      "implementer_type",
    );
    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "responsiblePartyMaintenance",
      "responsible_party_maintenance",
    );

    if (filters.fcs?.length !== undefined && !FilterSummary.areAllCheckboxesChecked("fcs")) {
      queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "fcs",
        queryStrings,
      );
    }

    GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "types", "bmp_type");

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "implementationPhase",
      "phase",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "consequenceOfFailure",
      "cof_final_text",
    );

    GeoServerFilterFunctions.addFilterToGeoServerQuery(
      queryStrings,
      filters,
      "inProximityTo303dWaterbody",
      "in_proximity_to_303d_waterbody",
    );

    if (filters.condition && !FilterSummary.areAllCheckboxesChecked("condition")) {
      queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "condition",
        queryStrings,
        "condition_status",
        true,
      );
    }

    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "bmp_creation",
        filters.installDate?.from,
        filters.installDate?.to,
      );
    }
  };

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installationDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  var addTodoProps = function (props) {
    props.correctiveActionOptions = getCatchBasinCorrectiveActions();
  };

  // @TODO: remove when we enable smart filters on the to do list
  var getCatchBasinCorrectiveActions = function () {
    const tasks = BmpFcsInventoryConstants.getDefaultMaintenanceTasksInTypes(
      FormConstants.priorityCatchBasinTypeNumbers,
    ).map(function (task) {
      return {
        name: task.name,
        value: task.key,
      };
    });

    tasks.push({
      name: "No Access",
      value: "asset-accessible",
    });

    return tasks;
  };

  var passInProximityTo303dWaterbody = function (datum, filters) {
    if (filters.inProximityTo303dWaterbody?.length !== undefined) {
      return OfflineFilterFunctions.includedOrNull(
        filters.inProximityTo303dWaterbody,
        datum.inProximityTo303dWaterbody,
      );
    } else {
      return true;
    }
  };

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, zoneOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      zoneOptions,
      receivingWaterOptions,
      urbanDrainageOptions,
      catchmentsOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      conditionOptions: FilterConstants.conditionOptions,
      responsiblePartyOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      typesOptions: ToolSettings.getSetting("filterConstants", "bmpTypes"),
      phaseOptions: ToolSettings.getSetting("filterConstants", "phases"),
      cofOptions: BmpFcsInventoryConstants.cofOptionsFlagged,
      yesNoOptions: FilterConstants.yesNoOptions,
      correctiveOptions: FilterConstants.getAllCorrectiveOptions(),
      enforcementLevel: FilterConstants.getEnforcementLevel(),
    };
  };

  var isOnlineDateFilterSet = function (filters) {
    return false;
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      subGroups: "groupId",
      implementerType: "implementerType",
      responsiblePartyMaintenance: "responsiblePartyMaintenance",
      types: "type",
      implementationPhase: "phase",
      condition: "condition_status",
      consequenceOfFailure: "cof",
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      zones: "zone_names",
      correctiveAction: ["correctiveAction", "array"],
      enforcementLevel: ["enforcementLevel", "array"],
      fcs: "fcs",
      inProximityTo303dWaterbody: passInProximityTo303dWaterbody,
      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passInstalledDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    passInstalledDateFilter,
    addGeoServerFilterQuery,
    isOnlineDateFilterSet,
    addTodoProps,
    getProps,
    getCatchBasinCorrectiveActions,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = MuniBmpFilters();

const ApiError = require("../../errors/apiError");
const BmpFcsInventoryConstants = require("../../bmpfcs/bmpFcsInventoryConstants");
const FormConstants = require("../../mapFunctions/formConstants");
const GeoServerFilterFunctions = require("../../mapFunctions/geoServerFilterFunctions");
const MuniBmpGeoServerLayer = require("./muniBmpGeoServerLayer");
const ResourceFilters = require("../../filters/resourceFilters");
const FilterConstants = require("../../filterConstants");
const OfflineFilterFunctions = require("../../mapFunctions/offlineFilterFunctions");
const FilterSummary = require("../../filters/filterSummary");
const Filters = require("../../mapFunctions/filters");
const ToolSettings = require("../../settings/toolSettings");
const Progeny = require("../../login/progeny");
