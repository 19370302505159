"use strict";

const ChangePhaseModal = function () {
  let $modal;
  let planId;

  const _loadListeners = function () {
    $modal
      .off("click", "[value='cancel']")
      .on("click", "[value='cancel']", () => $modal.modal("hide"))
      .off("click", "[value='ok']", _saveChanges)
      .on("click", "[value='ok']", _saveChanges)
      .off("change", "select", _changedPhaseDropdown)
      .on("change", "select", _changedPhaseDropdown)
      .off("input", "input", _validateDateInputs)
      .on("input", "input", _validateDateInputs);
  };

  const render = async function (data) {
    planId = data.id;
    $modal = $("#change-phase-modal");
    const phaseOptions = EsgDropdownOptions.planPhaseOptions;
    $modal.html(nunjucks.render("esg/modals/changePhaseModal.njk", { ...data, phaseOptions }));
    $modal.modal("show");
    DateTimePicker.initializeDateTimePickers($modal);
    _loadListeners();
    _changedPhaseDropdown();
  };

  const _constrainPlanningYear = function (rawPlanningYear, planCreatedYear, currentYear) {
    const minYear = 999;
    const maxYear = 2999;
    const minPlanningYear = Math.max(planCreatedYear, currentYear);
    if (rawPlanningYear && rawPlanningYear > minYear) {
      return Math.min(maxYear, Math.max(minPlanningYear, rawPlanningYear));
    }
    return rawPlanningYear;
  };

  const _calcMinCompletedYear = function (
    currentPhase,
    currentYear,
    projectedPlanningYear,
    actualPlanningYear,
  ) {
    if (currentPhase === "draft") {
      return Math.max(currentYear, projectedPlanningYear);
    }
    return actualPlanningYear ? actualPlanningYear : currentYear;
  };

  const _validateDateInputs = function (e) {
    const minYear = 999,
      maxYear = 2999;
    const whichChanged = $(e.target).attr("name");
    const currentPhase = $('select[name="phase"]').val();

    const $planningYearProjectedField = $('input[name="planningYearProjected"]');
    const $completedYearProjectedField = $('input[name="completedYearProjected"]');
    const $planCreatedHidden = $('input[name="createdDate"]');

    const todaysDate = new Date();
    const thisYear = todaysDate.getFullYear();

    const planCreatedDate = new Date($planCreatedHidden.val());
    const planCreatedYear = planCreatedDate.getFullYear();

    const projectedPlanningYear = $planningYearProjectedField.val();
    const actualPlanningDate = DateTimePicker.getDate($modal, "planningDate");
    const actualPlanningYear = actualPlanningDate ? actualPlanningDate.getFullYear() : null;

    const projectedCompletedYear = $completedYearProjectedField.val();

    if (whichChanged === "planningYearProjected") {
      const clampedPlanningYear = _constrainPlanningYear(
        projectedPlanningYear,
        planCreatedYear,
        thisYear,
      );
      $planningYearProjectedField.val(clampedPlanningYear);
      if (projectedCompletedYear) {
        $completedYearProjectedField.val(Math.max(clampedPlanningYear, projectedCompletedYear));
      }
    }
    if (whichChanged === "completedYearProjected") {
      const rawCompletedYear = $completedYearProjectedField.val();
      const minCompletedYear = _calcMinCompletedYear(
        currentPhase,
        thisYear,
        projectedPlanningYear,
        actualPlanningYear,
      );
      if (rawCompletedYear && rawCompletedYear > minYear) {
        $completedYearProjectedField.val(
          Math.min(maxYear, Math.max(rawCompletedYear, minCompletedYear)),
        );
      }
    }
    _clampDatePickerRanges(planCreatedDate, todaysDate, actualPlanningDate);
  };

  const _clampDatePickerRanges = function (planCreatedDate, todaysDate, actualPlanningDate) {
    const minCompletedDate = actualPlanningDate ? actualPlanningDate : todaysDate;

    [planCreatedDate, todaysDate, minCompletedDate].forEach((date) => date.setHours(0, 0, 0, 0));

    DateTimePicker.setDatePickerRange($modal, "completedDate", true, todaysDate);
    DateTimePicker.setDatePickerRange($modal, "completedDate", false, minCompletedDate);
    DateTimePicker.setDatePickerRange($modal, "planningDate", true, todaysDate);
    DateTimePicker.setDatePickerRange($modal, "planningDate", false, planCreatedDate);
  };

  const _changedPhaseDropdown = function () {
    const newPhase = $('select[name="phase"]').val() || "draft";
    const todaysDate = new Date();
    const planCreatedDate = new Date($('input[name="createdDate"]').val());
    const actualPlanningDate = DateTimePicker.getDate($modal, "planningDate") || null;
    const actualCompletedDate = DateTimePicker.getDate($modal, "completedDate") || null;

    $modal
      .find(".modal-dialog")
      .removeClass("phase-draft phase-planning phase-completed")
      .addClass(`phase-${newPhase}`);

    $("#change-phase-modal fieldset").removeClass("required");
    $(`.start-${newPhase}`).addClass("required");

    if (!actualPlanningDate && newPhase === "planning") {
      DateTimePicker.setToNow($modal, "planningDate");
    } else if (!actualCompletedDate && newPhase === "completed") {
      DateTimePicker.setToNow($modal, "completedDate");
    } else {
      _clampDatePickerRanges(planCreatedDate, todaysDate, actualPlanningDate);
    }
  };

  const _saveChanges = async function () {
    const $phaseSelect = $('select[name="phase"]');
    const currentPhase = $phaseSelect.val();
    let requiredFieldsFilled = true;
    if (currentPhase === "planning" && !DateTimePicker.getDate($modal, "planningDate")) {
      requiredFieldsFilled = false;
    }
    if (currentPhase === "completed" && !DateTimePicker.getDate($modal, "completedDate")) {
      requiredFieldsFilled = false;
    }

    if (!requiredFieldsFilled) {
      MessageModal.showWarningModal(
        "All required fields must be completed",
        "Unable to save phase",
      );
    } else {
      const saveData = {
        phase: currentPhase,
        planningYearProjected: $('input[name="planningYearProjected"]').val(),
        completedYearProjected: $('input[name="completedYearProjected"]').val(),
        planningDate: DateTimePicker.getIsoDate($modal, "planningDate") || null,
        completedDate: DateTimePicker.getIsoDate($modal, "completedDate") || null,
      };
      await EsgApiCalls.updatePlan({ id: planId, updates: saveData });
      await PageController.refresh();
      $modal.modal("hide");
    }
  };

  return {
    render,
    _constrainPlanningYear,
    _calcMinCompletedYear,
  };
};

module.exports = ChangePhaseModal();

const DateTimePicker = require("../../general/dateTimePicker");
const EsgDropdownOptions = require("../esgDropdownOptions");
const MessageModal = require("../../modals/messageModal");
const EsgApiCalls = require("../../esg/esgApiCalls");
const PageController = require("../pageController");
