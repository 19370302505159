"use strict";

const Panels = function () {
  var init = function () {
    loadDomListeners();
    populateToolDropdown();
    setHomepageLink();
  };

  var loadDomListeners = function () {
    const $navbar = $("#tool-nav");
    const $toolDropdownList = $("#tool-dropdown-list");
    const $headerTabs = $("#tool-nav-tabs a");
    const $body = $("body");

    $navbar.off();
    $toolDropdownList.off();
    $headerTabs.off();
    $body.off("click", ".trash-settings-link", trashSettingsLinkClick);

    $navbar.on("click", ".logout-button", Login.logout);
    $toolDropdownList.on("click", "#tool-dropdown-list .disabled a", disabledToolClick);
    $headerTabs.on("shown.bs.tab", onShownBsTab);
    // Attached to the body because regulator view rerenders .trash-settings-link
    $body.on("click", ".trash-settings-link", trashSettingsLinkClick);
  };

  var trashSettingsLinkClick = function () {
    PageFunctions.goToPage("settings");
  };

  var disabledToolClick = function (event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  var onShownBsTab = function (e) {
    const data = $(e.target).data();
    handleDisplayTab(data.maptarget);
  };

  var handleDisplayTab = function (pageName) {
    const config = PageFunctions.getPageConfig(pageName);
    const DataViewController = require("./mapFunctions/dataViewController");

    DataViewController.showDataViewButton();
    FactSheetNew.close();

    if (config.targetSelector === "#map-panel") {
      hideConstructionTodoFilterPanel();
      handleMapPanelTab(pageName);
      $(".floating-inputs").show();
    } else if (config.targetSelector === "#settings") {
      handleSettingsTab();
    }

    _makeTabActive(pageName, config.targetSelector);

    if (config.noPanels) {
      fullScreenPage();
    } else {
      normalPage(pageName);
    }
  };

  var fullScreenPage = function () {
    const DataViewController = require("./mapFunctions/dataViewController");

    FilterSummary.hide();
    ListPanel.hide();
    MapPanel.hide();
    DataViewController.hideDataViewButton();
    $(".floating-inputs").hide();
  };

  var handleSettingsTab = function () {
    Settings.init();
    if (Session.isRegulator()) {
      $("#general-tab").parent().hide();
    }
    $("#settings-mini-tabs").show();
  };

  var hideConstructionTodoFilterPanel = function () {
    if (Tree.get("dataView") === "construction-project" && Tree.get("activeTab") === "todo") {
      $(".filter-menu").hide();
    }
  };

  var handleMapPanelTab = function (tab) {
    $("#details-button-container").removeClass("default-hidden");

    if (tab === "todo") {
      handleTodoTabDisplay();
      FilterSummary.disable();
      Table.expandSideBar();
      handleResetTodos();
    } else if (tab === "insight") {
      handleInsightTabDisplay();

      Table.expandSideBar();
      handleShowSpatialDrawers();
      FilterSummary.disable();
    } else if (tab === "result") {
      handleResultTabDisplay();
      FilterSummary.disable();
      Table.expandSideBar();
      $("#bottomFloatingInputsTable").removeClass("hidden");
    } else {
      $(".filter-menu").removeClass("hidden");
      handleDataTabDisplay();
      resetScenarioFilters();

      const filtersDisabled =
        DataViewFunctions.getCurrentDataViewProperty("filterBarHiddenButtons");
      if (!(filtersDisabled && filtersDisabled.includes("filter"))) {
        FilterSummary.enable({ esg: false, force: true });
      }
    }
    $("#settings-mini-tabs").hide();
  };

  var handleShowSpatialDrawers = function () {
    $("#bottomFloatingInputsTable").removeClass("hidden");
    $(".filter-menu").addClass("hidden");
    const optionsToDisable = [
      "scenarioAssetId",
      "drainageArea",
      "imperviousAreaTreated",
      "typeAbbreviation",
      "bmpPhase",
    ];
    if (
      optionsToDisable.includes(Tree.get("filters", "dataSort")) &&
      Tree.get("dataView") === "scenarios"
    ) {
      const dataSort = Session.isUlar() || Session.isVentura() ? "assessmentAreas" : "rw";
      Filters.forceDataSort(dataSort);
      Filters.closeSearch();
    }
  };

  var resetScenarioFilters = function () {
    if (Tree.get("dataView") === "scenarios") {
      Filters.setToDefault();
    }
  };

  var handleResetTodos = function () {
    const dataview = Tree.get("dataView");
    const resetTodoFiltersModule = DataViewFunctions.getCurrentDataViewProperty(
      "resetTodoFiltersModule",
      dataview,
    );
    resetTodoFiltersModule?.resetTodoFilter();
  };

  var handleDataTabDisplay = function () {
    const dataView = Tree.get("dataView");
    if (dataView === "scenarios") {
      checkFilterStateFireClick();
    }
    $("#bottomFloatingInputsTable").removeClass("hide-catchment-expand");
    $("#main-legend").removeClass("todo-only");
    $("#todo-map-table-container").hide();
    $("#bottomFloatingInputsTable").show();
    $("#dataTab").parent().addClass("active");
    $("#filter-row").show();
  };

  var checkFilterStateFireClick = function () {
    setTimeout(function () {
      if ($(".filter-button").hasClass("invert-colors")) {
        $(".filter-button").trigger("click");
      }
    }, 10);
  };

  var handleTodoTabDisplay = function () {
    if (DataViewFunctions.getUserEnabledTabs(Tree.get("dataView"), "map").includes("todo")) {
      $("#bottomFloatingInputsTable").addClass("hide-catchment-expand");
      $("#main-legend").addClass("todo-only");
      $("#bottomFloatingInputsTable").hide();
      $("#todo-map-table-container").show();
      $("#filter-row").hide();
      ToDoMapTable.init();
    } else {
      NavToggle.showPanel("list");
    }
  };

  var handleInsightTabDisplay = function () {
    $("#bottomFloatingInputsTable").addClass("hide-catchment-expand");

    if ($("#details-button-container").hasClass("insight-with-table")) {
      $("#details-button-container").removeClass("default-hidden");
    } else {
      $("#details-button-container").addClass("default-hidden");
    }
  };

  var handleResultTabDisplay = function () {
    $("#bottomFloatingInputsTable").addClass("hide-catchment-expand");
  };

  const goTo2nForm = function () {
    $("#grayArrow").animate(
      {
        left: "0px",
      },
      200,
    );
    setTimeout(function () {
      location.href = Config.get().origin;
    }, 200);
  };

  const setHomepageLink = function () {
    $(".home-link").attr("href", Config.get().origin);
  };

  const getToolDropdownItems = function () {
    const userTools = ToolFunctions.getUserToolsToDisplay();
    const groupTools = ToolFunctions.getToolsEnabledForGroup();
    let toolDropdownItems = ToolFunctions.getToolsInOrder();

    toolDropdownItems = toolDropdownItems.filter(function (tool) {
      return userTools.includes(tool.internalName);
    });

    toolDropdownItems.forEach((tool) => {
      if (!tool.internalName || !groupTools.includes(tool.internalName)) {
        tool.disabled = true;
      }
    });

    activeCurrentTool(toolDropdownItems);

    return toolDropdownItems;
  };

  const activeCurrentTool = function (toolDropdownItems) {
    const currentToolName = Tree.get("tool");
    const currentTool = toolDropdownItems.find((x) => x.internalName === currentToolName);

    if (!currentTool) {
      return;
    }

    currentTool.active = true;
  };

  const populateToolDropdown = function () {
    const toolDropdownItems = getToolDropdownItems();
    const currentToolName = Tree.get("tool");

    if (ToolFunctions.getToolIsTeaserTool(currentToolName)) {
      enableTeaserMode();
    }

    const $toolDropdownList = $("#tool-dropdown-list");
    $toolDropdownList.html(getToolDropdownHtml(toolDropdownItems));
    $("#tool-title").text(ToolFunctions.getToolConfigProperty("name"));
  };

  var enableTeaserMode = function () {
    const toolTitle = ToolFunctions.getToolConfigProperty("name");

    NavToggle.toggleListButtonDisabled(true);
    MessageModal.showWelcomeModal(
      `If you are interested in learning more about 2NFORM's ${toolTitle} module and how you can start using it in your stormwater program, please contact us at <a href="mailto:customersuccess@2ndnaturewater.com">customersuccess@2ndnaturewater.com</a>. We look forward to hearing from you.`,
      "Okay",
    );
  };

  var showWelcomeModal = function (dataTypeStr) {
    const toolTitle = ToolFunctions.getToolConfigProperty("name");

    MessageModal.showWelcomeModal(
      `Thank you for your interest in the ${toolTitle} module. To get started, you can upload your ${dataTypeStr} from the import link in the banner.<br>Learn more by visiting our Help Center from the link in the banner.`,
      "Okay",
    );
  };

  var getToolDropdownHtml = function (toolDropdownItems) {
    addDataViewOfflineStatus(toolDropdownItems);
    addToolLinks(toolDropdownItems);
    const groupOneTools = toolDropdownItems.filter((item) => item.external);
    const groupTwoTools = toolDropdownItems.filter((item) => !item.external);

    return nunjucks.render("navbar/toolDropdown.njk", { groupOneTools, groupTwoTools });
  };

  var addToolLinks = function (toolDropdownItems) {
    for (const item of toolDropdownItems) {
      item.link = Config.get().toolLinks[item.internalName];
    }
  };

  var _makeTabActive = function (pageName, targetSelector) {
    _setActiveTabButton(pageName);
    _showTabPage(targetSelector);
    Tree.set("activeTab", pageName);
  };

  var normalPage = function (pageName) {
    NavToggleController.handleNavToggleDisplayByTab(
      Tree.get("tool"),
      pageName,
      Tree.get("dataView"),
    );
    MainMap.invalidateMapSize();
    FilterSummary.show();
  };

  var _setActiveTabButton = function (pageName) {
    $("#tool-nav-tabs li").removeClass("active");
    $("#tool-nav-tabs [data-maptarget=" + pageName + "]")
      .closest("li")
      .addClass("active");
  };

  var _showTabPage = function (targetSelector) {
    const $mapPages = $("#map-pages > .tab-pane");
    const activeTab = Tree.get("activeTab");

    $mapPages.removeClass("active");
    if (!(["data", "todo"].includes(activeTab) && targetSelector === "#map-panel")) {
      $mapPages.filter(targetSelector).addClass("active");
    }
  };

  var addDataViewOfflineStatus = function (toolDropdownItems) {
    for (const toolConfig of toolDropdownItems) {
      const dataViewConfigs = ToolFunctions.getToolDataViewConfigs(toolConfig.internalName, false);

      toolConfig.offline =
        toolConfig.offline === true &&
        dataViewConfigs?.some((dataViewConfig) => dataViewConfig.offline === true);
    }
  };

  return {
    goTo2nForm,
    populateToolDropdown,
    init,
    enableTeaserMode,
    handleDisplayTab,
    _makeTabActive,
    showWelcomeModal,
    getToolDropdownItems,
    getToolDropdownHtml,
    handleMapPanelTab,
  };
};

module.exports = Panels();

const Config = require("./config");
const Session = require("./login/session");
const Tree = require("./tree");
const ListPanel = require("./mapFunctions/listPanel");
const MapPanel = require("./mapFunctions/mapPanel");
const Login = require("./login/login");
const ToolFunctions = require("./toolFunctions");
const MessageModal = require("./modals/messageModal");
const NavToggle = require("./mapFunctions/navToggle");
const PageFunctions = require("./pageFunctions");
const ToDoMapTable = require("./mapFunctions/toDoMapTable");
const Settings = require("./settings/settings");
const MainMap = require("./mapFunctions/mainMap");
const NavToggleController = require("./mapFunctions/navToggleController");
const DataViewFunctions = require("./dataViewFunctions");
const FilterSummary = require("./filters/filterSummary");
const Table = require("./mapFunctions/table");
const Filters = require("./mapFunctions/filters");
const FactSheetNew = require("./general/factSheetNew");
