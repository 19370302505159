"use strict";

const OtherPropertiesLayer = function () {
  const layerName = "otherProperties";
  let map;
  let otherPropertiesLayer;

  const loadLayerListenersForMap = function (_map) {
    map = _map;
    MapFunctions.whenLayerDataUpdated("otherProperties", "modal", _whenLayerDataUpdated);
  };

  const show = function () {
    MapFunctions.whenLayerToggled(layerName, "modal", _toggleLayer);
    _removeZoomListener();
    _addZoomListener();
    if (!_isEnabled()) DisplayOptions.handleLayerDisplay(layerName, true, "modal");
  };

  const _whenLayerDataUpdated = function (data) {
    _addOtherPropertiesLayer(data);
    _displayOtherPropertiesLayer();
    _addPopups();
  };

  const _displayOtherPropertiesLayer = function () {
    if (otherPropertiesLayer) otherPropertiesLayer.addTo(map);
  };

  const _addPopups = function () {
    otherPropertiesLayer.eachLayer(function (featureLayer) {
      featureLayer.bindPopup(_getPopupHtml(featureLayer.feature.properties));
    });
  };

  const _getPopupHtml = function (properties) {
    const title = EsgSettings.propertyTitleDisplay(properties);
    const color = PropertiesIcon.getClassAndColor(properties.esgPropertySymbol).color;
    return nunjucks.render("popups/otherPropertiesPopup.njk", { title, color });
  };

  const _loadOtherProperties = function (mapId) {
    const dataPath = Actions.getLayerDataPathByMapId(mapId);

    Tree.set(["layers", "otherProperties", "isFetching"], true);
    EsgApiCalls.getOtherProperties(_getPropertyId(), function (data) {
      if (data) {
        var geoJsonPolygons = CleanData.cleanGeoJson(data);
        Tree.set(["layers", "otherProperties", dataPath], geoJsonPolygons);
        Tree.set(["layers", "otherProperties", "isFetching"], false);
      }
    });
  };

  const _getPropertyId = function () {
    return (
      Tree.get("asset", "property-inventory", "dbId") || Tree.get("asset", "property-costs", "dbId")
    );
  };

  const _removeOtherPropertiesLayer = function () {
    if (MapFunctions.mapHasLayer(map, otherPropertiesLayer)) {
      map.removeLayer(otherPropertiesLayer);
    }
  };

  const _addOtherPropertiesLayer = function (data) {
    if (otherPropertiesLayer) map.removeLayer(otherPropertiesLayer);
    otherPropertiesLayer = Layers.getOtherPropertiesLayer(data);
  };

  const _addZoomListener = function () {
    map.on("zoomend", _toggleLayer);
  };

  const _removeZoomListener = function () {
    map.off("zoomend", _toggleLayer);
  };

  const _zoomedOutTooFar = function () {
    return map.getZoom() < 13;
  };

  const _toggleDisabled = function () {
    Misc.toggleDisabledInputs($("#check-other-properties-modal"), _zoomedOutTooFar());
  };

  const _isEnabled = function () {
    const isEnabledPath = Actions.getLayerIsEnabledPathByMapId("modal");
    return Tree.get(["layers", layerName, isEnabledPath]);
  };

  const _toggleLayer = function () {
    if (_isEnabled() && !_zoomedOutTooFar()) {
      if (otherPropertiesLayer) {
        _displayOtherPropertiesLayer();
      } else {
        _loadOtherProperties("modal");
      }
    } else {
      _removeOtherPropertiesLayer();
    }
    _toggleDisabled();
  };

  const reset = function () {
    _removeOtherPropertiesLayer();
    otherPropertiesLayer = null;
    _removeZoomListener();
  };

  return {
    loadLayerListenersForMap,
    show,
    reset,
    _getPopupHtml,
  };
};

module.exports = OtherPropertiesLayer();

const EsgApiCalls = require("../esgApiCalls");
const MapFunctions = require("../../mapFunctions/mapFunctions");
const Actions = require("../../actions");
const CleanData = require("../../mapFunctions/cleanData");
const Tree = require("../../tree");
const Layers = require("../../mapFunctions/layers");
const DisplayOptions = require("../../mapFunctions/displayOptions");
const Misc = require("../../misc");
const EsgSettings = require("../esgSettings");
const PropertiesIcon = require("../icons/propertiesIcon");
