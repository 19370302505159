"use strict";

const DataViewDropdown = function () {
  var startDataViewDropdownListeners = function () {
    var $dataViewDropdown = $("#data-view-dropdown");
    $dataViewDropdown.off();

    $dataViewDropdown.on("click", ".data-view-dropdown-list li", dataViewDropdownLiClick);
  };

  var dataViewDropdownLiClick = function () {
    if ($(this).hasClass("disabled")) {
      return;
    }
    var dataView = $(this).data("view");

    YearDropdown.enableAllYears();
    DataViewFunctions.setDataView(dataView);
    DataViewFunctions.setMapLayerFromDataView();
  };

  var updateDataViewDropdown = function (dataView) {
    $("#data-view-dropdown").removeClass("disabled");
    $(".data-view-dropdown-list").children().removeClass("dropdown-selection");

    var $element = $(`.data-view-dropdown-list [data-view="${dataView}"]`);
    $element.addClass("dropdown-selection");
    $("#data-view-dropdown .dropdown-btn-text").text($element.text());
  };

  var setupDataViewDropdown = function (dataViewToSet = Tree.get("dataView")) {
    if (!AnnualReports.isYearDataLoaded() || !Tree.get("activeTab")) {
      return;
    }

    clearDataViewDropdown();

    var allDataViews = DataViewFunctions.getAllDataViews();
    for (const dataView of allDataViews) {
      enableDataViewDropdownItem(dataView);
    }

    DataViewFunctions.setDataView(dataViewToSet);
    YearDropdown.setYearDropdown();
    displayLayerButton();
  };

  var displayLayerButton = function () {
    const disableButton = !Tabs.activeTabHasMap();
    $("#data-view-dropdown .dropdown-toggle").toggleClass("disabled", disableButton);
  };

  var enableDataViewDropdownItem = function (dataView) {
    const $element = $(".data-view-dropdown-list").find(`[data-view='${dataView}']`);
    $element
      .addClass("dropdown-item")
      .removeClass("disabled")
      .removeClass("no-italics")
      .attr("disabled", false);
  };

  var disableDataViewDropdownItem = function ($element) {
    $element.addClass("disabled").removeClass("dropdown-item").attr("disabled", true);
    $element.find("a").addClass("no-italics");
  };

  var clearDataViewDropdown = function () {
    $(".data-view-dropdown-list")
      .children()
      .removeClass("dropdown-selection")
      .addClass("disabled")
      .attr("disabled", true);
  };

  var updateLegendForTelrLayer = function () {
    var layerName = DataViewFunctions.getCurrentLayerName();

    if (DataViewFunctions.isTelrDataViewInNonInputsMap()) {
      var columnData = getFilteredColumnData(layerName);
      var layerNameKebab = Misc.camelToKebab(layerName);
      var isReduction = layerName.includes("Progress");
      updateLegendColumns(columnData, layerNameKebab, isReduction);
    }
  };

  var getFilteredColumnData = function (layerName) {
    const CleanData = require("./cleanData");

    var filters = Tree.get("filters");
    var mapId = Tree.get("mapId");

    var rawData = Tree.get("layers", mapId, layerName, "rawData");

    var filteredRawData = rawData.filter(function (data) {
      return Layers.featureInFilter(filters, data);
    });

    return CleanData.getColumnArray(filteredRawData, layerName);
  };

  var getCurrentColumnArray = function () {
    return Tree.get("currentColumnArray");
  };

  var getColumnsWithSigFigs = function (columns, layerNameKebab) {
    const CleanData = require("./cleanData");
    var sigFigs = layerNameKebab === "runoff-ratio-opportunity" ? 0 : 2;

    return columns.map((column) => {
      const number = parseFloat(column);
      let string = number.toFixed(sigFigs);

      if (string === (0).toFixed(sigFigs)) {
        string = CleanData.toStringFirstSigFig(number, sigFigs);
      }

      return string;
    });
  };

  var updateLegendColumns = function (columns, layerNameKebab, isReduction = false) {
    const lastColumnIndex = columns.length - 1;
    Tree.set("currentColumnArray", columns);

    columns = getColumnsWithSigFigs(columns, layerNameKebab);

    $(".js-trash-progress td").hide();

    const $zeroColumn = $(`.${layerNameKebab}-category-empty`);
    showParentWithPrev($zeroColumn);

    const $lastColumn = $(`.${layerNameKebab}-category-${lastColumnIndex}`);
    const lastColumnText = isReduction
      ? `> (${columns[lastColumnIndex]})`
      : `> ${columns[lastColumnIndex]}`;
    $lastColumn.text(lastColumnText);
    showParentWithPrev($lastColumn);

    for (let i = 0; i < lastColumnIndex; i++) {
      const $middleColumn = $(`.${layerNameKebab}-category-${i}`);
      const middleColumnText = isReduction
        ? `>(${columns[i]}) - (${columns[i + 1]})`
        : `>${columns[i]} - ${columns[i + 1]}`;
      $middleColumn.text(middleColumnText);
      showParentWithPrev($middleColumn);
    }
  };

  var showParentWithPrev = function ($element) {
    var $parent = $element.parent();
    $parent.show();
    $parent.prev().show();
  };

  var toggleDropdown = function (isEnabled) {
    $("#data-view-dropdown").toggle(isEnabled);
  };

  return {
    startDataViewDropdownListeners,
    setupDataViewDropdown,
    updateLegendColumns,
    getColumnsWithSigFigs,
    updateLegendForTelrLayer,
    getCurrentColumnArray,
    disableDataViewDropdownItem,
    updateDataViewDropdown,
    enableDataViewDropdownItem,
    toggleDropdown,
  };
};

module.exports = DataViewDropdown();

const Tree = require("../../tree");
const Layers = require("./layers");
const DataViewFunctions = require("../dataViewFunctions");
const Misc = require("../misc");
const AnnualReports = require("../annualReports");
const YearDropdown = require("./yearDropdown");
const Tabs = require("../tabs");
