"use strict";

const Settings = function () {
  var settingsDropzones = [
    {
      name: "trackReportingDropzone",
      id: "upload-container-track",
      jsonName: "trackReportingFiles",
      downloadName: "trackReporting",
    },
  ];
  var dropzoneObject = {};

  var contactsArray = [];
  var milestonesArray = [];

  var thisSettingsDiv = $("#settings-container");

  const init = function () {
    $("#settings-container").hide();

    const user = Session.loadContext();
    const groupId = Tree.get("activeGroup", "groupId");

    $(".settings-group-name").text(user.group);

    loadSettingsListeners();

    ApiCalls.getUrbanDrainageStatus(groupId, function (status) {
      //if there are no urban drainages disable UD dropdown option
      if (!status.length) {
        $(".dropdown-menu.sort li[data-id=ud]").addClass("disabled");
      }
    });

    ApiCalls.getAnnualReporting(function (data) {
      if (!$.isEmptyObject(data)) {
        Tree.set("annualReportingSettings", data);
        Tree.set("trashReportingYear", data.year);
        loadPriorityArea(data.year);
        populateAnnualReporting(data);
        loadSettingsDropzones(data);
        $("#settings-container").show();

        if (Session.isRegulator()) {
          handleRegulatorSettings(data);
        } else {
          disableSettingsButton(false);
        }
      }
    });

    ApiCalls.getContacts(groupId, function (data) {
      contactsArray = data;
      renderContacts();
    });

    ApiCalls.getMilestones(groupId, function (data) {
      milestonesArray = data;
      renderMilestones();
    });

    makeReadOnlyIfRegulator();
  };

  var loadPriorityArea = function (year) {
    const groupId = Tree.get("activeGroup", "groupId");

    ApiCalls.getPriorityArea(groupId, year, function (data) {
      if (!$.isEmptyObject(data)) {
        if (data.totalAcres) {
          const ms4AcresString =
            " - " + Math.round(Number(data.totalAcres)).toLocaleString() + " acres";
          $("#annual-pane").find("label[for='ms4-acres'] .acres").text(ms4AcresString);
        }

        if (data.pluAcres) {
          const pluAcresString =
            " - " + Math.round(Number(data.pluAcres)).toLocaleString() + " acres";
          $("#annual-pane").find("label[for='plu-acres'] .acres").text(pluAcresString);
        }
      }
    });
  };

  var loadSettingsListeners = function () {
    $("#add-contact-btn").off();
    $("#add-contact-btn").on("click", function () {
      showContactForm();
    });

    $("#add-milestone-btn").off();
    $("#add-milestone-btn").on("click", function () {
      showMilestoneForm();
    });

    $("#contact-form").off();
    $("#contact-form").on("submit", function (e) {
      e.preventDefault();
      saveContact();
    });

    $("#milestone-form").off();
    $("#milestone-form").on("submit", function (e) {
      e.preventDefault();
      saveMilestone();
    });

    $("#general-form-area .btn-cancel").off();
    $("#general-form-area .btn-cancel").on("click", function () {
      if ($(this).data("form") == "contact") {
        hideContactForm();
        resetContactForm();
      } else if ($(this).data("form") == "milestone") {
        hideMilestoneForm();
        resetMilestoneForm();
      }
    });

    $("#contact-list").off();
    $("#contact-list").on("click", ".edit-btn", (e) => {
      var id = $(e.target).data("id");

      for (let i = 0; i < contactsArray.length; i++) {
        const contact = contactsArray[i];
        if (id == contact.id) {
          populateContactForm(contact);
          break;
        }
      }
    });

    $("#milestone-list").off();
    $("#milestone-list").on("click", ".edit-btn", (e) => {
      var id = $(e.target).data("id");

      for (let i = 0; i < milestonesArray.length; i++) {
        const milestone = milestonesArray[i];
        if (id == milestone.id) {
          populateMilestoneForm(milestone);
          break;
        }
      }
    });

    $("#contact-list").off();
    $("#contact-list").on("click", ".delete-btn", (e) => {
      var id = $(e.target).data("id");
      deleteContact(id);
    });

    $("#milestone-list").on("click", ".delete-btn", (e) => {
      var id = $(e.target).data("id");
      deleteMilestone(id);
    });

    $("#sync-btn").off();
    $("#sync-btn").on("click", function () {
      var groupId = Tree.get("activeGroup", "groupId");
      ApiCalls.syncAGOLData(groupId, function (data) {
        if (data.status && data.status !== 200) {
          $("#data-sync-label").text("Sync Failed");
          $("#data-sync-modal .modal-body").html(data.statusText);
        } else {
          $("#data-sync-label").text("Sync Completed");
          // $("#data-sync-modal .modal-body").html(data);
          $("#data-sync-modal .modal-body").text(
            "You have successfully downloaded all trash data for this group.",
          );
        }

        $("#data-sync-modal").modal("show");
      });
    });

    $("#settings-container").off();

    $(".tab-pane .settings-save-button").on("click", function () {
      $(this)
        .parents(".settings-save-scope")
        .find("input, select, input-group")
        .trigger("2N:OnSave");
    });

    $(".settings-save-scope").on("2N:OnSave", "input", saveInputElements);
    $(".settings-save-scope").on("2N:OnSave", "select", saveSelectElements);

    $("#settings-container input[type=radio][name=track]").change(function () {
      showTrackSpecificElements($(this).val());
    });
  };

  function showTrackSpecificElements(value) {
    if (value == 1) {
      $(".track-1-only").show();
      $(".track-2-only").hide();
    } else if (value == 2) {
      $(".track-1-only").hide();
      $(".track-2-only").show();
    }
  }

  function saveInputElements() {
    let trashReportingYear = Tree.get("trashReportingYear");
    const groupId = Tree.get("activeGroup", "groupId");

    const formData = {};
    let key, value;
    if ($(this).attr("type") == "checkbox") {
      key = $(this).attr("name");
      value = $(this).prop("checked");
    } else if ($(this).attr("type") == "radio" && $(this).prop("checked")) {
      key = $(this).attr("name");
      value = $(this).val();
    } else {
      return;
    }

    if (Tree.get("annualReportingSettings")[key] == value) return;

    formData[key] = value;

    if ($(this).hasClass("annual-input")) {
      trashReportingYear++;
    }

    Tree.set(["annualReportingSettings", key], value);
    ApiCalls.updateAnnualReporting(formData, groupId, trashReportingYear, function () {
      $(".settings-save-modal").modal("show");
    });
  }

  function saveSelectElements() {
    let trashReportingYear = Tree.get("trashReportingYear");
    const groupId = Tree.get("activeGroup", "groupId");

    const formData = {};
    const key = $(this).attr("name");
    const value = $(this).val();

    if (Tree.get("annualReportingSettings")[key] == value) return;
    formData[key] = value;

    if ($(this).hasClass("annual-input")) {
      trashReportingYear++;
    }

    Tree.set(["annualReportingSettings", key], value);
    ApiCalls.updateAnnualReporting(formData, groupId, trashReportingYear, function () {
      $(".settings-save-modal").modal("show");
    });
  }

  const populateAnnualReporting = function (data) {
    Tree.set("lastTrashAcceptedDate", data.submitted ? data.end_date : data.start_date);

    var $annualContainer = $("#annual-pane");

    if (data.year) {
      $annualContainer.find(".reporting-year").text(data.year);
    }

    if (data.track !== null) {
      if (data.track == 1) {
        $annualContainer.find("input[name='track'][value=1]").prop("checked", true);
      } else if (data.track == 2) {
        $annualContainer.find("input[name='track'][value=2]").prop("checked", true);
      }
      showTrackSpecificElements(data.track);
    }

    if (data.due_date) {
      const formattedDate = DateTime.formatTimestampStringAsDisplayDate(data.due_date);
      $annualContainer
        .find("input[name='due_date']")
        .val(formattedDate)
        .data("curr", formattedDate);
    }

    if (data.start_date) {
      const formattedDate = DateTime.formatTimestampStringAsDisplayDate(data.start_date);
      $annualContainer
        .find("input[name='start_date']")
        .val(formattedDate)
        .data("curr", formattedDate);
    }

    if (data.end_date) {
      const formattedDate = DateTime.formatTimestampStringAsDisplayDate(data.end_date);
      $annualContainer
        .find("input[name='end_date']")
        .val(formattedDate)
        .data("curr", formattedDate);
    }

    if (data.threshold !== null) {
      $annualContainer.find("select[name='threshold']").val(data.threshold);
    }

    if (data.implementation_plan !== undefined) {
      $annualContainer
        .find("input[name='implementation_plan']")
        .prop("checked", data.implementation_plan);
    }

    if (data.proposed_locations !== undefined) {
      $annualContainer
        .find("input[name='proposed_locations']")
        .prop("checked", data.proposed_locations);
    }

    if (data.alt_land_use !== undefined) {
      $annualContainer.find("input[name='alt_land_use']").prop("checked", data.alt_land_use);
    }

    if (data.priority_landuse !== undefined) {
      // $annualContainer.find("input[name='alt_land_use']").prop("checked", true);
      if (data.priority_landuse) {
        $annualContainer.find("label[for='plu-acres']").addClass("checked");
      } else {
        $annualContainer.find("label[for='plu-acres']").removeClass("checked");
      }
    }

    if (data.boundary !== undefined) {
      // $annualContainer.find("input[name='boundary']").prop("checked", true);
      if (data.boundary) {
        $annualContainer.find("label[for='ms4-acres']").addClass("checked");
      } else {
        $annualContainer.find("label[for='ms4-acres']").removeClass("checked");
      }
    }

    if (data.storm_drains !== undefined) {
      // $annualContainer.find("input[name='storm_drains']").prop("checked", true);
      if (data.storm_drains) {
        $annualContainer.find("label[for='muni-network']").addClass("checked");
      } else {
        $annualContainer.find("label[for='muni-network']").removeClass("checked");
      }
    }

    if (data.trash_levels !== undefined) {
      // $annualContainer.find("input[name='trash_levels']").prop("checked", true);
      if (data.trash_levels) {
        $annualContainer.find("label[for='trash-levels']").addClass("checked");
      } else {
        $annualContainer.find("label[for='trash-levels']").removeClass("checked");
      }
    }
  };

  var loadSettingsDropzones = function (data) {
    settingsDropzones.forEach(function (dropzone) {
      const isReadOnly = Session.isRegulator();
      const thisDropzone = Dropzone($("#" + dropzone.id), isReadOnly);
      thisDropzone.reset(null, data[dropzone.jsonName]);
      thisDropzone.setDownloadHandler(function (name) {
        ApiCalls.downloadTrashAttachment(data.fileId, name, "settings", dropzone.downloadName);
      });
      thisDropzone.setFileSizeWarning(function (name) {
        MessageModal.showFileSizeWarning(name);
      });

      thisDropzone.setHiddenInputChangeCallback(handleSettingsFileUploads);
      thisDropzone.setFileZoneDropCallback(handleSettingsFileUploads);
      thisDropzone.setRemoveExistingCallback(handleSettingsFileUploads);

      dropzoneObject[dropzone.name] = thisDropzone;
    });
  };

  var handleRegulatorSettings = function (data) {
    disableSettingsButton(!data.submitted);
  };

  var disableSettingsButton = function (isDisabled) {
    $(".trash-settings-link").toggleClass("disabled", isDisabled);
  };

  function handleSettingsFileUploads() {
    const trashReportingYear = Tree.get("trashReportingYear");
    const groupId = Tree.get("activeGroup", "groupId");

    //for each dropzone, send files with a seperate api call
    settingsDropzones.forEach(function (dropzone) {
      const thisDropzone = dropzoneObject[dropzone.name];

      if (thisDropzone) {
        const newFiles = thisDropzone.getFiles();
        const removedFiles = thisDropzone.getRemoved();

        if (newFiles.length > 0) {
          const fileFormData = new FormData();
          newFiles.forEach((file, index) => {
            fileFormData.append("file-" + (index + 1), file);
          });

          fileFormData.append("fileId", "" + groupId + trashReportingYear);
          fileFormData.append("pathName", "settings");

          ApiCalls.formFileUpload(fileFormData, dropzone.downloadName, () => {});
        }
        if (removedFiles.length > 0) {
          const fileFormData = new FormData();
          removedFiles.forEach((file, index) => {
            fileFormData.append("file-" + (index + 1), file);
          });

          fileFormData.append("fileId", "" + groupId + trashReportingYear);
          fileFormData.append("pathName", "settings");

          ApiCalls.formFileDelete(fileFormData, dropzone.downloadName, () => {});
        }
      }
    });
  }

  function showContactForm() {
    $("#general-form-area").show();
    $("#general-form-area #milestone-form").hide();
    $("#general-form-area #contact-form").show();
  }

  function hideContactForm() {
    $("#general-form-area").hide();
    $("#general-form-area #contact-form").hide();
  }

  function showMilestoneForm() {
    $("#general-form-area").show();
    $("#general-form-area #contact-form").hide();
    $("#general-form-area #milestone-form").show();
  }

  function hideMilestoneForm() {
    $("#general-form-area").hide();
    $("#general-form-area #milestone-form").hide();
  }

  function resetContactForm() {
    $("#general-form-area #contact-form input").val("");
    $("#general-form-area #contact-form select").val("");
    $("#contact-form").data("id", 0);
  }

  function resetMilestoneForm() {
    $("#general-form-area #milestone-form input").val("");
    $("#milestone-form").data("id", 0);
  }

  function saveContact() {
    var groupId = Tree.get("activeGroup", "groupId");
    const $contactForm = $("#contact-form");
    const contactObj = {};

    contactObj["group"] = groupId;
    contactObj["module"] = "Trash";
    contactObj["first_name"] = $contactForm.find("input[name='first_name']").val();
    contactObj["last_name"] = $contactForm.find("input[name='last_name']").val();
    contactObj["position"] = $contactForm.find("select[name='position']").val();
    contactObj["email"] = $contactForm.find("input[name='email']").val();
    contactObj["phone"] = $contactForm.find("input[name='phone']").val();

    if ($contactForm.data("id")) {
      const contactId = $contactForm.data("id");
      contactObj["id"] = contactId;

      ApiCalls.updateContact(groupId, contactId, contactObj, function () {
        for (let i = 0; i < contactsArray.length; i++) {
          const contact = contactsArray[i];
          if (contactObj["id"] == contact.id) {
            contactsArray.splice(i, 1, contactObj);
            break;
          }
        }

        renderContacts();
        resetContactForm();
        hideContactForm();
      });
    } else {
      ApiCalls.createNewContact(groupId, contactObj, function (data) {
        contactObj["id"] = data;
        contactsArray.push(contactObj);
        renderContacts();
        resetContactForm();
        hideContactForm();
      });
    }
  }

  function saveMilestone() {
    var groupId = Tree.get("activeGroup", "groupId");
    const $milestoneForm = $("#milestone-form");
    const milestoneObj = {};

    milestoneObj["group"] = groupId;
    milestoneObj["year"] = $milestoneForm.find("input[name='year']").val();
    milestoneObj["plu"] = $milestoneForm.find("input[name='plu']").val();

    if ($milestoneForm.data("id")) {
      const milestoneId = $milestoneForm.data("id");
      milestoneObj["id"] = milestoneId;

      ApiCalls.updateMilestone(groupId, milestoneId, milestoneObj, function () {
        for (let i = 0; i < milestonesArray.length; i++) {
          const milestone = milestonesArray[i];
          if (milestoneObj["id"] == milestone.id) {
            milestonesArray.splice(i, 1, milestoneObj);
            break;
          }
        }
        renderMilestones();
        resetMilestoneForm();
        hideMilestoneForm();
      });
    } else {
      ApiCalls.createNewMilestone(groupId, milestoneObj, function (data) {
        milestoneObj["id"] = data;
        milestonesArray.push(milestoneObj);
        renderMilestones();
        resetMilestoneForm();
        hideMilestoneForm();
      });
    }
  }

  function renderContacts() {
    const html = nunjucks.render("settings/contacts.njk", {
      contacts: contactsArray,
    });
    $("#contact-list").html(html);
  }

  function renderMilestones() {
    const html = nunjucks.render("settings/milestones.njk", {
      milestones: milestonesArray,
    });
    $("#milestone-list").html(html);
  }

  function populateContactForm(data) {
    const $contactForm = $("#contact-form");

    $contactForm.find("input[name='first_name']").val(data.first_name);
    $contactForm.find("input[name='last_name']").val(data.last_name);
    $contactForm.find("select[name='position']").val(data.position);
    $contactForm.find("input[name='email']").val(data.email);
    $contactForm.find("input[name='phone']").val(data.phone);

    $contactForm.data("id", data.id);
    showContactForm();
  }

  function populateMilestoneForm(data) {
    const $milestoneForm = $("#milestone-form");

    $milestoneForm.find("input[name='year']").val(data.year);
    $milestoneForm.find("input[name='plu']").val(data.plu);

    $milestoneForm.data("id", data.id);
    showMilestoneForm();
  }

  function deleteContact(contactId) {
    var groupId = Tree.get("activeGroup", "groupId");

    ApiCalls.deleteContact(groupId, contactId, function () {
      for (let i = 0; i < contactsArray.length; i++) {
        const contact = contactsArray[i];
        if (contactId == contact.id) {
          contactsArray.splice(i, 1);
          break;
        }
      }
      renderContacts();
    });
  }

  function deleteMilestone(milestoneId) {
    var groupId = Tree.get("activeGroup", "groupId");

    ApiCalls.deleteMilestone(groupId, milestoneId, function () {
      for (let i = 0; i < milestonesArray.length; i++) {
        const milestone = milestonesArray[i];
        if (milestoneId == milestone.id) {
          milestonesArray.splice(i, 1);
          break;
        }
      }
      renderMilestones();
    });
  }

  var makeReadOnlyIfRegulator = function () {
    if (Session.isRegulator()) {
      disableDateInputs();
      disableRadioCheckboxInputs();
      disableThresholdSelector();
      hideSaveButton();
      hideUploadInstructions();
      hideAddContactButton();
      hideAddMilestoneButton();
      hideSyncButton();
    }
  };

  var disableRadioCheckboxInputs = function () {
    thisSettingsDiv.find("input[type=radio], input[type=checkbox]").attr("disabled", true);
  };

  var disableDateInputs = function () {
    thisSettingsDiv.find(".input-group .input-date").attr("disabled", true);

    thisSettingsDiv.find(".input-group .input-group-addon").addClass("disabled");
  };

  var disableThresholdSelector = function () {
    thisSettingsDiv.find(".threshold-selector").attr("disabled", true);
  };

  var hideSaveButton = function () {
    thisSettingsDiv.find(".settings-save-button").hide();
  };

  var hideUploadInstructions = function () {
    thisSettingsDiv.find(".upload-instructions").hide();
  };

  var hideAddContactButton = function () {
    thisSettingsDiv.find("#add-contact-btn").hide();
  };

  var hideAddMilestoneButton = function () {
    thisSettingsDiv.find("#add-milestone-btn").hide();
  };

  var hideSyncButton = function () {
    thisSettingsDiv.find("#sync-btn").hide();
  };

  return {
    init,
  };
};

module.exports = Settings();

const ApiCalls = require("../apiCalls");
const Dropzone = require("../dropzone");
const Tree = require("../tree");
const DateTime = require("../dateTime");
const Session = require("../login/session");
const MessageModal = require("../modals/messageModal");
