"use strict";

const MapStyles = function () {
  const planning = "#ffffff";
  const planned = "#ffee6a";
  const construction = "#ffbb00";
  const postActive = "#d56200";
  const completed = "#813c00";
  const certified = "#231000";
  const shelved = "#a2937e";
  const rejected = shelved;
  const noInspection = "#808080";

  const purple = "#5e2887";
  const red = "#d95945";
  const yellow = "#e0bc67";
  const orange = "#f98628";
  const green = "#389675";
  const grey = "#747b79";
  const highlightYellow = "#ebe9a3";
  const highlightYellowOutline = "#c6cc28";
  const blue = "#3388ff";
  const white = "#ffffff";
  const black = "#000000";

  const dueIconClass = "due";

  var getHexColors = function () {
    return {
      purple,
      red,
      yellow,
      orange,
      green,
      grey,
      highlightYellow,
      highlightYellowOutline,
      blue,
      white,
      black,
      planning,
      planned,
      construction,
      postActive,
      completed,
      certified,
      rejected,
      shelved,
      noInspection,
    };
  };

  var getHexColor = function (colorName) {
    if (colorName && colorName.includes("-")) {
      colorName = Misc.kebabToCamel(colorName);
    }
    const hexColor = getHexColors()[colorName];

    if (hexColor === undefined) {
      throw new Error(`Unknown hex color name ${colorName}`);
    }

    return hexColor;
  };

  var highlights = function () {
    var theStyle = {
      color: highlightYellowOutline,
      fillOpacity: 0.65,
      weight: 4,
      fillColor: highlightYellow,
    };
    return theStyle;
  };

  var zoomHighlights = function (zoom) {
    const opacity = getOpacityFromZoom(zoom);
    var theStyle = {
      color: highlightYellowOutline,
      fillOpacity: opacity,
      weight: 4,
      fillColor: highlightYellow,
    };
    return theStyle;
  };

  var getOpacityFromZoom = function (zoom) {
    return zoom < 13
      ? 0.65
      : zoom >= 12 && zoom < 14
        ? 0.5
        : zoom >= 14 && zoom < 16
          ? 0.3
          : // : value >= columnArray[1] && value < columnArray[2]
            //   ? "#7daac8"
            //   : value >= columnArray[2] && value < columnArray[3]
            //     ? "#5f88a4"
            0.2;
  };

  var selectedHighlight = function (thickness) {
    if (!thickness) {
      thickness = 4;
    }
    var theStyle = {
      color: highlightYellowOutline,
      fillOpacity: 0,
      weight: thickness,
      fillColor: highlightYellow,
    };
    return theStyle;
  };

  var styleMS4Boundary = {
    color: "#444444",
    fillOpacity: 0.5,
    fillColor: "#598fb4",
    weight: 1,
  };

  var getConditionColor = function (score, hex = false, nullColor = null) {
    return getColorFromCondition(getConditionFromScore(score), hex, nullColor);
  };

  var getColorFromCondition = function (condition, hex = false, nullColor = null) {
    const hexColors = {
      poor: red,
      fair: orange,
      acceptable: yellow,
      optimal: green,
    };
    const textColors = {
      poor: "red",
      fair: "orange",
      acceptable: "yellow",
      optimal: "green",
    };

    if (condition === null) {
      return nullColor ?? (hex ? grey : "grey");
    }

    var color;

    if (hex) {
      color = hexColors[condition];
    } else {
      color = textColors[condition];
    }

    if (color === undefined) {
      throw new Error(`Unknown condition ${condition}.`);
    }

    return color;
  };

  var getConditionThresholds = function () {
    return [
      { condition: "poor", maxScore: 2.04 },
      { condition: "fair", maxScore: 2.94 },
      { condition: "acceptable", maxScore: 3.94 },
      { condition: "optimal" },
    ];
  };

  var getConditionFromScore = function (score) {
    if (score === null || score === undefined) {
      return null;
    }

    let lastMax;
    for (const threshold of getConditionThresholds()) {
      if (score <= threshold.maxScore) {
        return threshold.condition;
      } else if (threshold.maxScore === undefined && lastMax < score) {
        return threshold.condition;
      }

      lastMax = threshold.maxScore;
    }

    throw new Error(`Unknown score ${score}.`);
  };

  var getNewMapIcon = function (zoom) {
    const tool = Tree.get("tool");
    const dataView = Tree.get("dataView");

    if (["trashram", "bmpram"].includes(tool) || ["muni-bmp", "lid-bmp"].includes(dataView)) {
      return BmpFcsIcon.getUnsavedIconMarker(zoom);
    } else if (dataView === "muni-catch-basin") {
      return MuniCatchBasinIcon.getUnsavedIconMarker(zoom);
    } else if (tool === "construction" || tool === "projdelivery") {
      return ConstructionIcon.getNewConstructionProjectIconMarker();
    } else if (tool === "lid") {
      return LidIcon.getNewLidProjectMapIcon(zoom);
    } else if ((tool === "idde" && dataView === "incidents") || dataView === "dry-weather") {
      return IncidentsIcon.getIncidentsNewMarkerIcon();
    } else if (tool === "esg") {
      return PropertiesIcon.getIcon(3, [25, 25]);
    } else if (tool === "indcom") {
      return IndcomIcon.getFacilityIconMarker(null, true);
    } else {
      throw Error("Unknown tool");
    }
  };

  var getTrashPointIcon = function (condition, size = [10, 10]) {
    const theClass = "trash-point-" + condition.toLowerCase().replace(/ /g, "-");
    const icon = new L.DivIcon({
      className: "trash-point-icon " + theClass,
      iconSize: size,
    });
    return icon;
  };

  var getDueIconClass = function () {
    return dueIconClass;
  };

  var isTodoDue = function (subject, alternativeSubjects) {
    const requiredSubject = "assessment due";

    if (subject === requiredSubject) {
      return true;
    }

    for (const subject of alternativeSubjects) {
      if (subject === requiredSubject) {
        return true;
      }
    }

    return false;
  };

  var getBmpTodoIconClasses = function (todo) {
    return BmpFcsIcon.getTableIcon(
      todo[ToDoFunctions.getTodoPropertyNameByAssetType("score")],
      BmpFcsFunctions.isCentralizedBMPType(todo.bmp_type_key),
      todo.fcs,
      isTodoDue(todo.subject, todo.alternativeSubjects ?? []),
      todo.phase,
      null,
      true,
    );
  };

  var getFcsTodoIconClasses = function (todo) {
    if (todo.bmp_type_key) {
      return BmpFcsIcon.getTableIcon(
        todo[ToDoFunctions.getTodoPropertyNameByAssetType("score")],
        BmpFcsFunctions.isCentralizedBMPType(todo.bmp_type_key),
        todo.fcs,
        isTodoDue(todo.subject, todo.alternativeSubjects ?? []),
        todo.phase,
        null,
        true,
      );
    } else {
      return LegacyBmpFcs.getLegacyBmpFcsTableIcon(todo);
    }
  };

  var convertColorToGradient = function (color) {
    switch (color) {
      case "purple":
        return "linear-gradient(to bottom, #9e62b6, #7c4f8e)";
      case "red":
        return "linear-gradient(to bottom, #d95945, #b34939)";
      case "yellow":
        return "linear-gradient(to bottom, #e0bc67, #b39652)";
      case "orange":
        return "linear-gradient(to bottom, #f98628, #b76335)";
      case "green":
        return "linear-gradient(to bottom, #389675, #2a7359)";
      case "grey":
        return "linear-gradient(to bottom, #808080, #5d5d5d)";
      default:
        return grey;
    }
  };

  var getBarHeight = function (score) {
    const heightsConditionMap = {
      null: "6px",
      poor: "6px",
      fair: "10px",
      acceptable: "14px",
      optimal: "18px",
    };

    const condition = getConditionFromScore(score);

    return heightsConditionMap[condition];
  };

  var colorParticulatesFeature = function (value, columnArray) {
    return value < columnArray[0]
      ? "#f9d6cc"
      : value >= columnArray[0] && value < columnArray[1]
        ? "#db9880"
        : value >= columnArray[1] && value < columnArray[2]
          ? "#8d4730"
          : value >= columnArray[2] && value < columnArray[3]
            ? "#671705"
            : "#420D0D";
  };

  var styleRankingCatchments = function (feature) {
    var theStyle = {
      color: "#888888",
      fillOpacity: 0.7,
      fillColor: feature.properties.color,
      weight: 1.25,
    };
    return theStyle;
  };

  var styleThreeOThree = function (feature) {
    var theStyle = {
      color: 0,
      weight: 0,
      opacity: 0,
    };

    var color = getStreamColor(feature.properties.flowtype || feature.properties.flowType);
    theStyle = {
      color: color,
      weight: 2,
      opacity: 0.5,
    };
    return theStyle;
  };

  var styleStormdrain = function (feature) {
    var theStyle = {
      color: "#000000",
      weight: 3,
      opacity: 1,
      dashArray: "5,10",
      lineCap: "square",
    };

    return theStyle;
  };

  var styleMiddleStormdrain = function (feature) {
    var theStyle = {
      color: white,
      weight: 3,
      opacity: 1,
    };
    return theStyle;
  };

  var styleBaseStormdrain = function (feature) {
    var theStyle = {
      color: "#000000",
      weight: 4,
      opacity: 1,
    };
    return theStyle;
  };

  var styleBaseStormdrainThin = function (feature) {
    var theStyle = {
      color: "#000000",
      weight: 2,
      opacity: 1,
    };
    return theStyle;
  };

  var styleCollectorData = function (feature) {
    var color = getTrashDataColorHex(feature.properties.condition);
    var theStyle = {
      color: color,
      weight: 3,
      opacity: 1,
      lineCap: "square",
    };
    return theStyle;
  };

  var styleCollectorOutline = function (feature) {
    var theStyle = {
      color: "#666666",
      weight: 5,
      opacity: 1,
      lineCap: "square",
    };
    return theStyle;
  };

  var styleTrashPoints = function (feature) {
    var color = getTrashDataColorHex(feature.properties.condition);
    var theStyle = {
      radius: 5,
      color: color,
      weight: 3,
      opacity: 1,
    };
    return theStyle;
  };

  var stylePropertyPolygon = function () {
    return {
      color: "#4082F2",
      weight: 2,
      opacity: 1,
      fillColor: "#4082F2",
      fillOpacity: 0.2,
    };
  };

  var styleOtherPropertyPolygon = function (feature) {
    const symbol = feature.properties.esgPropertySymbol;
    const color = symbol ? PropertiesIcon.getClassAndColor(symbol).color : "#4082F2";
    return {
      color,
      weight: 3,
      opacity: 1,
      fillColor: color,
      fillOpacity: 0.6,
    };
  };

  var styleActivePropertyPolygon = {
    color: "#ffd700",
    opacity: 0.5,
    fillColor: "#ffd700",
    fillOpacity: 0.2,
    weight: 3,
  };

  var getTrashDataColorHex = function (condition) {
    //Note survey 123 data is coming as "Very _High" and "Very High"
    // - so important to keep the styling set as it is or fix accordingly
    return condition == "Low"
      ? green
      : condition == "Moderate"
        ? yellow
        : condition == "High"
          ? red
          : purple;
  };

  var getTrashDataColor = function (condition) {
    // return color name instead of hex
    return condition == "Low"
      ? "green"
      : condition == "Moderate"
        ? "yellow"
        : condition == "High"
          ? "red"
          : "purple";
  };

  var getStreamColor = function (condition) {
    return condition === "303d"
      ? "#e8462f"
      : ["receiving", "Receiving Water"].includes(condition)
        ? "#2e47a7"
        : "#0000FF00";
  };

  var styleCatchmentOutline = function () {
    var theStyle = {
      color: "#777777",
      fillOpacity: 0,
      fillColor: white,
      weight: 4,
    };
    return theStyle;
  };

  var styleBoundaryGray = function () {
    var theStyle = {
      color: "#777777",
      fillOpacity: 0,
      fillColor: white,
      weight: 3,
    };
    return theStyle;
  };

  var styleBoundaryWhite = function () {
    var theStyle = {
      color: "#ffffff",
      fillOpacity: 0,
      fillColor: white,
      weight: 5,
    };
    return theStyle;
  };

  var grayResults = function (color) {
    var grayColor = getGrayColor(color);
    var theStyle = {
      color: "#888888",
      fillOpacity: 0,
      weight: 1.25,
      fillColor: grayColor,
    };
    return theStyle;
  };

  var getGrayColor = function (TSStaa) {
    return TSStaa < 50
      ? "#E8E8E8"
      : TSStaa >= 50 && TSStaa < 100
        ? "#C8C8C8"
        : TSStaa >= 100 && TSStaa < 150
          ? "#B0B0B0"
          : TSStaa >= 150 && TSStaa < 200
            ? "#989898"
            : TSStaa >= 200 && TSStaa < 250
              ? "#808080"
              : "#686868";
  };

  var getParcelColor = function (landuse) {
    return landuse == "CUL"
      ? "#e8f0bc"
      : landuse == "OTH"
        ? "#83a841"
        : landuse == "IND"
          ? "#c0996b"
          : landuse == "COM"
            ? "#ebdb84"
            : landuse == "SFR"
              ? "#b1d1ea"
              : landuse == "MFR"
                ? "#347ab8"
                : landuse == "HTR"
                  ? "#0b0b0c"
                  : landuse == "MTR"
                    ? "#686867"
                    : landuse == "LTR"
                      ? "#b2b2b2"
                      : "#dacfb4";
  };

  var styleLanduseDissolved = function (feature) {
    var fillColor = getParcelColor(feature.properties.combo);
    var theStyle = {
      stroke: true,
      fill: true,
      color: "#666666",
      fillOpacity: 0.5,
      fillColor: fillColor,
      weight: 1,
    };
    return theStyle;
  };

  var styleHighPriorityAreas = {
    stroke: true,
    fill: true,
    color: blue,
    fillOpacity: 0.2,
    fillColor: blue,
    weight: 2,
  };

  var styleMaintenanceZonesLegend = {
    stroke: true,
    fill: true,
    color: blue,
    fillOpacity: 0.2,
    fillColor: blue,
    weight: 2,
  };

  var getParcelConditionColor = function (condition) {
    return condition == "Yes"
      ? "#4c7439"
      : condition == "No"
        ? "#a73928"
        : condition == "Partial"
          ? "#dcbc04"
          : "#cbcbcb";
  };

  var getParcelConditionColorString = function (condition) {
    return condition == "Yes"
      ? "green"
      : condition == "No"
        ? "red"
        : condition == "Partial"
          ? "yellow"
          : "grey";
  };

  var styleParcelCondition = function (props, zoom) {
    if (props.condition === undefined && props.properties) {
      props = props.properties;
    }
    return {
      stroke: true,
      fill: true,
      color: "#999",
      fillOpacity: 0.5,
      strokeOpacity: 1,
      fillColor: getParcelConditionColor(props.condition),
      weight: 1,
    };
  };

  var styleCustomLineLayer = function (feature) {
    return {
      color: "#000000",
      weight: 2,
    };
  };

  var styleCatchments = function (feature) {
    if (feature && feature.properties["selected"]) {
      return {
        color: "#468dd2",
        fillOpacity: 0.8,
        weight: 2,
        fillColor: "#8eb0cc",
      };
    }

    return {
      color: "#888888",
      fillOpacity: 0,
      fillColor: "#598fb4",
      weight: 1.25,
    };
  };

  var styleDrainageLayer = function (feature) {
    if (feature.properties.hasOwnProperty("selected")) {
      return selectedPolygon(feature);
    }
    var theStyle = {
      color: white,
      fillOpacity: 0.9,
      fillColor: "#999",
      weight: 1.0,
    };

    //make roads a bit lighter
    if (feature.properties.poly_type != "Parcel") {
      theStyle.fillOpacity = 0.5;
    }

    //default non-priority to transparent
    if (feature.properties.priority == "Yes") {
      theStyle.color = "#999";
      theStyle.fillColor = white;
      theStyle.fillOpacity = 0.65;
    }

    if (feature.properties.ownedByOtherCentralizedBmp) {
      theStyle.color = white;
      theStyle.fillColor = "#777";
      theStyle.fillOpacity = 0.8;
    }

    return theStyle;
  };

  var selectedPolygon = function (feature) {
    const style = {
      color: "#468dd2",
      fillOpacity: 0.8,
      weight: 2,
      // fillColor: "#c3d5e4"
      fillColor: "#8eb0cc",
    };

    if (feature.properties.priority == "Yes") {
      style.fillColor = "#49a4e1";
    }

    return style;
  };

  var selectedPolygonHighlight = {
    color: "#3399dd",
    fillOpacity: 0.4,
    weight: 4,
    fillColor: "#add4f7",
  };

  var styleDrainageOutline = {
    color: "#444444",
    fillOpacity: 0.5,
    fillColor: "#598fb4",
    weight: 1,
  };

  var styleCbmpDrainage = {
    color: white,
    fillOpacity: 0.5,
    fillColor: grey,
    weight: 1,
  };

  var expandingSectionToggle = function (elementToToggle, buttonToToggle, isOpen, callback = null) {
    var animationTime = 250;

    if (isOpen) {
      buttonToToggle.toggleClass("invert-colors", true);
      elementToToggle.slideDown(animationTime, callback);
    } else {
      buttonToToggle.toggleClass("invert-colors", false);
      elementToToggle.slideUp(animationTime, callback);
    }
  };

  var defaultDrawnPolygonStyle = {
    color: blue,
    opacity: 1,
    fillColor: blue,
    fillOpacity: 0.2,
    weight: 3,
  };

  var esgPropertyDrawnPolygonStyle = {
    color: "#ffd700",
    opacity: 1,
    fillColor: "#ffd700",
    fillOpacity: 0.2,
    weight: 3,
  };

  var setDrawnPolygonStyle = function (options, style = defaultDrawnPolygonStyle) {
    for (const key in style) {
      options[key] = style[key];
    }
  };

  return {
    getNewMapIcon,
    colorParticulatesFeature,
    styleRankingCatchments,
    styleThreeOThree,
    styleCatchments,
    styleCatchmentOutline,
    highlights,
    zoomHighlights,
    grayResults,
    styleLanduseDissolved,
    getConditionColor,
    getBarHeight,
    selectedHighlight,
    styleParcelCondition,
    styleCustomLineLayer,
    styleStormdrain,
    styleBaseStormdrain,
    styleBaseStormdrainThin,
    styleMiddleStormdrain,
    styleMS4Boundary,
    styleCollectorData,
    styleCollectorOutline,
    styleTrashPoints,
    stylePropertyPolygon,
    styleOtherPropertyPolygon,
    styleActivePropertyPolygon,
    getTrashDataColorHex,
    getTrashDataColor,
    convertColorToGradient,
    getTrashPointIcon,
    styleDrainageLayer,
    selectedPolygon,
    selectedPolygonHighlight,
    styleDrainageOutline,
    expandingSectionToggle,
    dueIconClass,
    getParcelConditionColorString,
    getDueIconClass,
    defaultDrawnPolygonStyle,
    esgPropertyDrawnPolygonStyle,
    setDrawnPolygonStyle,
    styleCbmpDrainage,
    getHexColors,
    styleHighPriorityAreas,
    styleMaintenanceZonesLegend,
    getConditionFromScore,
    getConditionThresholds,
    getColorFromCondition,
    getBmpTodoIconClasses,
    getFcsTodoIconClasses,
    getHexColor,
    styleBoundaryGray,
    styleBoundaryWhite,
  };
};

module.exports = MapStyles();

const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const ConstructionIcon = require("../construction/constructionIcon");
const IncidentsIcon = require("../illicitDischarge/incidents/incidentsIcon");
const LegacyBmpFcs = require("../bmpfcs/legacyBmpFcs");
const LidIcon = require("../lid/lidIcon");
const Misc = require("../misc");
const ToDoFunctions = require("./toDoFunctions");
const Tree = require("../tree");
const PropertiesIcon = require("../esg/icons/propertiesIcon");
const IndcomIcon = require("../indcom/indcomIcon");
const MuniCatchBasinIcon = require("../muni/muniCatchBasinIcon");
