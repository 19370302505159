class LidSelfInspectionModal {
  static formKey = "lid-self-inspection";
  static $modal = $("#assessment-modal");
  static initialData;

  static async show() {
    LidSelfInspectionModal.initialData = await PortalApiCalls.getLidSelfInspectionProject();
    LidSelfInspectionModal.#render();
    LidSelfInspectionModal.#loadDomListeners();

    Analytics.sendScreenView("portal", LidSelfInspectionModal.formKey);
  }

  static #loadDomListeners() {
    LidSelfInspectionModal.$modal.on("click", ".save-button", LidSelfInspectionModal._warnAndSave);
    LidSelfInspectionModal.$modal.on("2N:FormInput", LidSelfInspectionModal.#checkEnableSaveButton);

    Form.initializeAndLoadListeners(LidSelfInspectionModal.$modal, LidSelfInspectionModal.formKey);
    Form.initializeDropzone(LidSelfInspectionModal.formKey, LidSelfInspectionModal.$modal);

    DateTimePicker.setDatePickerRange(
      LidSelfInspectionModal.$modal,
      "receivedDate",
      false,
      DateTime.parseIsoDate(LidSelfInspectionModal.initialData.lidNoticeSentDate),
    );
  }

  static #unloadDomListeners() {
    LidSelfInspectionModal.$modal.off("click", ".save-button", LidSelfInspectionModal._warnAndSave);
    LidSelfInspectionModal.$modal.off(
      "2N:FormInput",
      LidSelfInspectionModal.#checkEnableSaveButton,
    );
  }

  static #checkEnableSaveButton() {
    const canSaveLetter = LidSelfInspectionModal._hasAllRequiredFields();
    LidSelfInspectionModal.$modal.find(".save-button").toggleClass("disabled", !canSaveLetter);
  }

  static #render() {
    const html = nunjucks.render("modals/portal/lidSelfInspection.njk", {
      initialData: LidSelfInspectionModal.initialData,
    });
    LidSelfInspectionModal.$modal.html(html);
    LidSelfInspectionModal.$modal.modal("show");
  }

  static #getUpdateData(updateData) {
    updateData.bmpMaintenance = LidSelfInspectionModal.getBmpMaintenance(
      LidSelfInspectionModal.initialData.bmps,
      updateData.bmpMaintenance,
    );
    return {
      updates: updateData,
    };
  }

  static getBmpMaintenance(existingBmps, maintenanceUpdates = []) {
    const bmpMaintenance = [];

    for (let i = 0; i < existingBmps.length; i++) {
      bmpMaintenance.push({
        id: existingBmps[i].id,
        maintained: maintenanceUpdates[i] ?? false,
      });
    }

    return bmpMaintenance;
  }

  static async _warnAndSave() {
    try {
      await MessageModal.showConfirmWarningModalAsPromise(
        `Are you ready to submit? Once you click “Submit” you will not be able to edit your response at a later time. Click “Submit” to officially submit your response, or click “Go Back” to return to the previous window and review your response.`,
        { returnMessage: "Go Back", okMessage: "Submit" },
      );
    } catch (e) {
      return;
    }

    await Form.getReadyToSavePromise(LidSelfInspectionModal.formKey);
    const updateData = LidSelfInspectionModal.#getUpdateData(
      Form.getDataFromForm(LidSelfInspectionModal.formKey, false),
    );
    await PortalApiCalls.saveNewLidSelfInspection(updateData);
    PortalController.goWithSecret("portal-lid-self-inspection-confirmation");
  }

  static close() {
    Form.clearForm(LidSelfInspectionModal.formKey);
    LidSelfInspectionModal.#unloadDomListeners();
    LidSelfInspectionModal.$modal.modal("hide");
  }

  static _hasAllRequiredFields() {
    let requiredFilled = 0;
    const requiredFields = ["receivedDate", "notes"];
    const data = Form.getDataFromForm(LidSelfInspectionModal.formKey, false);

    for (const key in data) {
      if (requiredFields.includes(key) && !!data[key]) {
        requiredFilled++;
      }
    }

    return requiredFilled === requiredFields.length;
  }
}

module.exports = LidSelfInspectionModal;

const Analytics = require("../../general/analytics");
const PortalApiCalls = require("../portalApiCalls");
const Form = require("../../general/form");
const MessageModal = require("../../modals/messageModal");
const PortalController = require("../portalController");
const DateTimePicker = require("../../general/dateTimePicker");
const DateTime = require("../../dateTime");
