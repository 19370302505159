"use strict";

const ProjectInspectionAdditionalObservations = function () {
  const stringKey = "additional-observations";
  const tabHeader = "Additional Observations";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on("input", "[name='additional-observation-purpose']", _onObservationPurposeChange);
    $page.on("input", "[name='additional-observation-practice']", _onObservationPracticeChange);
    $page.on("click", "[name='add-additional-observation']", _onAddObservationButtonChange);
    $page.on("click", "[name=add-observation]", _onAddObservation);
    $page.on("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var unloadListeners = function () {
    $page.off("input", "[name='additional-observation-purpose']", _onObservationPurposeChange);
    $page.off("input", "[name='additional-observation-practice']", _onObservationPracticeChange);
    $page.off("click", "[name='add-additional-observation']", _onAddObservationButtonChange);
    $page.off("click", "[name=add-observation]", _onAddObservation);
    $page.off("click", ".card .delete-button", TemporaryBmpObservation.onDeleteObservation);
  };

  var getProps = function (data) {
    return {
      additionalObservationPurposeOptions:
        ProjectInspectionConstants.getAdditionalObservationPurposeOptions(),
      additionalObservationPracticeOptionsObject:
        ProjectInspectionConstants.getAdditionalObservationPracticeOptionsObject(),
      requiredObservationConditionOptions:
        ProjectInspectionConstants.getRequiredObservationConditionOptions(),
      additionalObservationPracticeEnabled: false,
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var _onObservationPurposeChange = function () {
    const purpose = $(this).val();

    replaceObservationPractice(purpose);
  };

  var replaceObservationPractice = function (purpose) {
    const $practice = $page.find("[name=additional-observation-practice]").closest("fieldset");

    const props = {
      additionalObservationPracticeOptions: getUnusedPracticeOptions(purpose),
      additionalObservationPracticeEnabled: true,
    };
    const html = nunjucks.render(
      "modals/projectInspection/templates/additionalObservationsPractice.njk",
      {
        props,
      },
    );

    return $practice.replaceWith(html);
  };

  var getUnusedPracticeOptions = function (purpose) {
    const allOptions =
      ProjectInspectionConstants.getAdditionalObservationPracticeOptionsByPurpose(purpose);
    const allExistingPractices = Object.keys(
      ProjectInspectionController.getAllData().additionalObservations,
    );

    return allOptions.filter((option) => !allExistingPractices.includes(option.value));
  };

  var _onObservationPracticeChange = function () {
    const practice = $(this).val();
    const $button = $page.find("[name=add-additional-observation]");

    updateAddObservationButton($button, practice);
  };

  var updateAddObservationButton = function ($button, practice) {
    $button.prop("disabled", !practice);
    $button.data("practice", practice);
  };

  var _onAddObservationButtonChange = function () {
    const $button = $(this);
    const practice = $button.data("practice");
    const purpose =
      ProjectInspectionConstants.getAdditionalObservationPracticeOptionsObject()[practice].purpose;

    updateAddObservationButton($button, null);
    addAdditionalObservation(practice);
    replaceObservationPractice(purpose);
  };

  var addAdditionalObservation = function (practice) {
    const props = {
      ...getProps(),
    };
    const html = nunjucks.render("modals/projectInspection/templates/additionalObservation.njk", {
      props,
      observations: [
        TemporaryBmpObservation.createNewTemporaryBmpInspection("additionalObservations", practice),
      ],
      practice,
      overideCardState: "open",
    });
    const $newObservation = $(html);

    $page.find("section").eq(1).prepend($newObservation);
    TemporaryBmpObservation.initializeTemporaryBmpInspection($newObservation);
    TemporaryBmpObservation.initializeTemporaryBmpInspection($newObservation.find(".card"));
  };

  var _onAddObservation = function () {
    const $button = $(this);
    TemporaryBmpObservation.addObservation($button, "additionalObservations");
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
    _onObservationPurposeChange,
    _onObservationPracticeChange,
    _onAddObservationButtonChange,
  };
};

module.exports = ProjectInspectionAdditionalObservations();

const ProjectInspectionConstants = require("./projectInspectionConstants");
const ProjectInspectionController = require("./projectInspectionController");
const TemporaryBmpObservation = require("./temporaryBmpObservation");
