"use strict";

const FeatureFlagModal = function () {
  var initialized = false;
  var availableFlags;
  var enabledFlags;
  var $modal;

  const init = function () {
    if (initialized) {
      return;
    }

    $modal = $("#feature-flag-modal");
    availableFlags = getAllFlags().map((flag) => flag.key);

    loadListeners();
    setInitialFlags();
    initialized = true;
  };

  const loadListeners = function () {
    $modal.off("change", `input[name="feature-flag"]`, featureFlagToggled);
    $modal.on("change", `input[name="feature-flag"]`, featureFlagToggled);

    $modal.off("click", `.save-button`, saveButtonClicked);
    $modal.on("click", `.save-button`, saveButtonClicked);

    $modal.off("click", `.enable-all-button`, enableAllButtonClicked);
    $modal.on("click", `.enable-all-button`, enableAllButtonClicked);

    $modal.off("click", `.disable-all-button`, disableAllButtonClicked);
    $modal.on("click", `.disable-all-button`, disableAllButtonClicked);
  };

  const setInitialFlags = function () {
    if (enabledFlags) return;
    setEnabledFlags(Session.getUser()?.enabledFlags ?? []);
  };

  const setEnabledFlags = function (newFlags) {
    enabledFlags = newFlags;
  };

  const isFeatureFlagEnabled = function (flagKey) {
    init();

    if (!availableFlags.includes(flagKey)) {
      console.error(`Feature flag key ${flagKey} is not listed in FeatureFlagConfig`);
      return false;
    }

    return enabledFlags?.includes(flagKey);
  };

  const enableFeatureFlagModal = function () {
    if (!Session.is2nAdmin()) {
      return;
    }

    $(".feature-flag-settings").parent().show();

    $("#tool-nav").off("click", ".feature-flag-settings", showFeatureFlagModal);
    $("#tool-nav").on("click", ".feature-flag-settings", showFeatureFlagModal);
  };

  const showFeatureFlagModal = function () {
    init();

    const allFlags = getAllFlags().reverse();
    standardizeFlags(allFlags);
    allFlags.forEach((flag) => {
      if (enabledFlags?.includes(flag.key)) {
        flag.enabled = true;
      }
    });

    const generalFlags = allFlags.filter((flag) => !isSpecial(flag));
    const speicalFlags = allFlags.filter((flag) => isSpecial(flag));

    $modal.html(
      nunjucks.render("modals/settingsModal/featureFlagModal.njk", { generalFlags, speicalFlags }),
    );
    $modal.modal("show");
  };

  const featureFlagToggled = function () {
    $(this).closest("tr").toggleClass("enabled", !!this.checked);

    $modal.find(".save-button").removeClass("disabled");
  };

  const isSpecial = function (flag) {
    return flag.defaultDisabled || flag.defaultEnabled;
  };

  const enableAllButtonClicked = function () {
    $modal
      .find(".general-container")
      .find(`input[name="feature-flag"]`)
      .prop("checked", true)
      .trigger("change");
  };

  const disableAllButtonClicked = function () {
    $modal
      .find(".general-container")
      .find(`input[name="feature-flag"]`)
      .prop("checked", false)
      .trigger("change");
  };

  const saveButtonClicked = async function () {
    const updatedFlags = [];
    $modal.find(`input[name="feature-flag"]:checked`).each(function () {
      updatedFlags.push($(this).val());
    });

    const newToken = await ApiCalls.saveFeatureFlags(updatedFlags);
    TokenManager.saveToken(newToken);
    BrowserWarning.overrideCanClose();

    window.location.reload();
  };

  const standardizeFlags = function (allConfigs) {
    for (const config of allConfigs) {
      if (typeof config.jira === "string") {
        config.jira = [config.jira];
      } else if (config.jira === undefined) {
        config.jira = [];
      }
    }
  };

  const getAllFlags = function () {
    return ToolSettings.getSetting("featureFlags") ?? [];
  };

  return {
    enableFeatureFlagModal,
    isFeatureFlagEnabled,
    setEnabledFlags,
    showFeatureFlagModal,
  };
};

module.exports = FeatureFlagModal();

const Session = require("../login/session");
const ApiCalls = require("../apiCalls");
const TokenManager = require("../login/tokenManager");
const BrowserWarning = require("../general/browserWarning");
const ToolSettings = require("./toolSettings");
