"use strict";

var BmpFilter = function () {
  const searchFields = ["bmpName", "address"];
  let mainSmartFilters;

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      phaseOptions: ToolSettings.getSetting("filterConstants", "phases"),
      typesOptions: ToolSettings.getSetting("filterConstants", "bmpTypes"),
      responsiblePartyOptions: BmpFcsInventoryConstants.responsiblePartyDeliveryOptions,
      yesNoOptionsNullFalse: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],

      yesNoOptions: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
        {
          name: "No Data",
          value: "null",
        },
      ],
      yesNoNumberOptions: [
        {
          name: "Yes",
          value: 1,
        },
        {
          name: "No",
          value: 0,
        },
        {
          name: "No Data",
          value: "null",
        },
      ],
    };
  };

  var filterResourceDataHandler = function (unfilteredData, filters) {
    const filteredData = ResourceFilters.spatialFilters(unfilteredData, filters);
    if (mainSmartFilters) {
      return mainSmartFilters.filter(filteredData);
    } else {
      return filteredData;
    }
  };

  var passScoreFilter = function (datum, filters) {
    return OfflineFilterFunctions.passMinMaxFilter(datum.score, filters.score);
  };

  var passDateFilter = function (dateData, filters, dateType) {
    return ResourceFilters.passIsoDateFilter(
      dateData,
      filters[dateType]?.from,
      filters[dateType]?.to,
    );
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      drainsToRw: "drains_to_rw",
      drainsToC: "drains_to_c",
      catchmentName: "catchment_name",
      phase: "phase",
      types: "bmp_kind",
      ownership: "landOwnership",
      subGroups: "groupId",
      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passScoreFilter(datum, filters) &&
          passDateFilter(datum.bmp_creation, filters, "installDate") &&
          passDateFilter(datum.fcs_obs_date, filters, "assessmentDate")
        );
      },
    };
  };

  return {
    getProps,
    filterResourceDataHandler,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = BmpFilter();

const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const Filters = require("../mapFunctions/filters");
const OfflineFilterFunctions = require("../mapFunctions/offlineFilterFunctions");
const ToolSettings = require("../settings/toolSettings");
const ResourceFilters = require("./resourceFilters");
const Progeny = require("../login/progeny");
