"use strict";

const EnforcementConstants = function () {
  const warningMessages = {
    issue:
      "Are you sure you want to issue this enforcement? This will create a new enforcement record. Click 'Save' to continue or 'Cancel' to return.",
    "keep-open":
      "Are you sure you want to keep this enforcement open? This will create a new enforcement record. Click 'Save' to continue or 'Cancel' to return.",
    escalate:
      "Are you sure you want to escalate this enforcement? This will create a new enforcement record. Click 'Save' to continue or 'Cancel' to return.",
    resolve:
      "Are you sure you want to resolve this enforcement? Once resolved, you cannot edit or add any more information. Click 'Save' to continue or 'Cancel' to return.",
  };

  var getNewEnforcementDefaults = function (enforcementData) {
    enforcementData.escalationAction = "issue";
    enforcementData.issueDate = DateTime.getTodayIso();
    return enforcementData;
  };

  var getWarningMessageFromLevel = function (level) {
    return warningMessages[level] ?? "";
  };

  return { getNewEnforcementDefaults, getWarningMessageFromLevel };
};

module.exports = EnforcementConstants();

const DateTime = require("../dateTime");
