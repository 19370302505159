"use strict";

const LidProjectStyles = function () {
  var getColor = function (lidProjectData) {
    let phase = lidProjectData.phase;
    const condition = lidProjectData.lidCondition;

    if (phase === undefined) {
      phase = "planned";
    }
    if (phase === "planned" || phase === "construction") {
      return "white";
    } else if (phase === "completed" || phase === "certified") {
      if (condition) {
        if (condition === "desired") {
          return "green";
        } else if (condition === "poor") {
          return "red";
        }
      }
      return "grey";
    } else {
      throw Error(`Unknown phase ${phase} provided`);
    }
  };

  var getColorHex = function (lidProjectData) {
    const colorHexMap = {
      white: "#ffffff",
      grey: "#8c8c8c",
      red: "#d95945",
      green: "#389675",
    };
    const color = getColor(lidProjectData);
    if (color in colorHexMap) {
      return colorHexMap[color];
    }
    throw `Hex not known for color ${color}.`;
  };

  var getTableIconColorClass = function (lidProjectData) {
    const color = getColor(lidProjectData);
    return `lid-table-${color}`;
  };

  var getMapIconColorClass = function (lidProjectData) {
    const color = getColor(lidProjectData);
    return `lid-map-${color}`;
  };

  var getLidPerformanceFontColor = function (lidProjectData) {
    const color = getColor(lidProjectData);
    const fontColor = color === "white" ? "black-text" : "white-text";
    return fontColor;
  };

  return {
    getColorHex,
    getTableIconColorClass,
    getMapIconColorClass,
    getLidPerformanceFontColor,
    getColor,
  };
};

module.exports = LidProjectStyles();
