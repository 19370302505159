"use strict";

var ReportProgressChart = function () {
  let thisChartData;
  let inited = false;
  let pieChartOpen = true;

  var init = function (chartData) {
    if (!inited) {
      loadListeners();
      inited = true;
    }

    load(chartData);
  };

  var load = function (chartData) {
    if (chartData && chartData.length) {
      thisChartData = chartData;
    } else {
      return;
    }
    if (shouldDisplayPieChart()) {
      openPieChartDrawer();
    } else {
      closePieChartDrawer(pieChartOpen);
    }
  };

  var loadListeners = function () {
    Tree.select("yearDropdownSelection").on("update", (e) => {
      const selectedYear = e.data.currentData;

      if (selectedYear === null) {
        return;
      }

      const selectedYearData = thisChartData.filter(
        (yearDatum) => yearDatum.year === selectedYear,
      )[0];
      if (selectedYearData) {
        PieChart.render(selectedYearData);
      }
    });

    const pieChartContainer = $(".pie-chart-container");
    pieChartContainer.off();
    pieChartContainer.on("click", ".pie-chart-expand-button", () => openPieChartDrawer());

    pieChartContainer.on("click", ".pie-chart-close-button", () => closePieChartDrawer());

    const debounceInterval = 500;
    $(window).on("resize", Misc.debounce(render, debounceInterval));
  };

  var render = function () {
    const AnnualReports = require("../../annualReports");

    const filters = Tree.get("filters");
    const filtersEmpty = filters.catchments.length === 0 && filters.receivingWaters.length === 0;
    const shouldShowMilestones = filtersEmpty && !Progeny.activeHasProgeny();

    ProgressBarChart.render(thisChartData, !pieChartOpen, shouldShowMilestones);

    const yearSelected = AnnualReports.getSelectedYear();
    const selectedYearData = thisChartData.filter((datum) => datum.year === yearSelected)[0];
    PieChart.render(selectedYearData);
  };

  var shouldDisplayPieChart = function () {
    return PieChart.pieChartIsEnabled() && pieChartOpen;
  };

  var closePieChartDrawer = function (animate = true) {
    const timeout = animate ? 200 : 0;
    const iconWidth = $(".pie-chart-expand-button").width();
    const pieChart = $(".pie-chart-container");
    pieChart.removeClass("open");
    const icon = pieChart.find(".pie-chart-expand-button");
    if (PieChart.pieChartIsEnabled()) {
      icon.css("visibility", "visible");
    } else {
      icon.css("visibility", "hidden");
    }
    pieChartOpen = false;

    render();
    pieChart.animate({ right: -pieChart.width() + iconWidth }, timeout);
  };

  var openPieChartDrawer = function () {
    const pieChart = $(".pie-chart-container");
    pieChart.addClass("open");
    const icon = pieChart.find(".pie-chart-expand-button");
    icon.css("visibility", "hidden");
    pieChartOpen = true;

    render();
    pieChart.animate({ right: 0 });
  };

  return {
    load,
    init,
    render,
    openPieChartDrawer,
    closePieChartDrawer,
  };
};

module.exports = ReportProgressChart();

const Tree = require("../../../tree");
const ProgressBarChart = require("./progressBarChart");
const PieChart = require("./pieChart");
const Misc = require("../../misc");
const Progeny = require("../../../login/progeny");
