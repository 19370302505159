"use strict";

const MaintenanceResult = function () {
  var getRoutineMaintenanceResultData = function (
    assetName,
    maintenanceIssues,
    assetAccessible = null,
  ) {
    var resultData = {
      issuesMessages: [],
      message: "",
      messageColor: "",
    };

    if (!maintenanceIssues) {
      maintenanceIssues = [];
    }

    assetAccessible = updateAssetAccessible(maintenanceIssues, assetAccessible);

    if (maintenanceIssues?.length) {
      const debrisCleanedIssues = getDebrisCleanedIssues(maintenanceIssues);
      const furtherReviewIssues = getFurtherReviewIssues(maintenanceIssues);

      if (furtherReviewIssues.length === 0) {
        if (debrisCleanedIssues.length === 0) {
          resultData.message = getNoFurtherMaintenanceMessage(assetName);
          resultData.messageColor = "green";
        } else {
          const cleanedMessage = getCleanedMessage(debrisCleanedIssues);
          resultData.message = `${assetName} ${cleanedMessage} and does not require further maintenance at this time.`;
          resultData.messageColor = "green";
        }
      } else if (furtherReviewIssues.length > 0) {
        if (debrisCleanedIssues.length === 0) {
          resultData.message = `${assetName} requires the following maintenance: `;
          resultData.messageColor = "red";
        } else {
          const cleanedMessage = getCleanedMessage(debrisCleanedIssues);
          resultData.message = `${assetName} ${cleanedMessage} but requires the following maintenance: `;
          resultData.messageColor = "red";
        }
        resultData.issuesMessages = getIssuesMessages(furtherReviewIssues);
      }
    } else {
      resultData.message = getNoFurtherMaintenanceMessage(assetName);
      resultData.messageColor = "green";
    }
    return resultData;
  };

  var getCleanedMessage = function (debrisCleanedIssues) {
    const cleanedData = debrisCleanedIssues[0].dataNumber;
    const cleanedUnitName = BmpFcsInventoryConstants.lengthUnitOptions.getObjectWith(
      "value",
      debrisCleanedIssues[0].dataOption,
    )?.name;

    if (cleanedData) {
      return `was cleaned of ${cleanedData} ${cleanedUnitName} of debris`;
    } else {
      return `was cleaned of debris`;
    }
  };

  var getDebrisCleanedIssues = function (issues) {
    return issues.filter((issue) => issue.issue && issue.key && issue.correction === "cleaned");
  };

  var getFurtherReviewIssues = function (issues) {
    return issues.filter((issue) => {
      return issue.issue && issue.correction && issue.correction === "further-review";
    });
  };

  var getIssuesMessages = function (issues) {
    return issues.map((issue) => {
      const issueName = getIssueNameByKey(issue.key);
      let issueString = `${issueName}`;
      if (issue.level) {
        const levelName = RoutineMaintenanceFormTable.getLevelOptions().getObjectWith(
          "value",
          issue.level,
        )?.name;
        issueString += ` - ${levelName}`;
      }
      return issueString;
    });
  };

  var getIssueNameByKey = function (key) {
    const issue = RoutineMaintenanceFormTable.getTaskByKey(
      BmpFcsInventoryConstants.getAllMaintenanceTasks(),
      key,
    );
    return issue.name;
  };

  var getNoFurtherMaintenanceMessage = function (assetName) {
    return `${assetName} does not require further maintenance at this time.`;
  };

  var updateAssetAccessible = function (maintenanceIssues, assetAccessible) {
    let accessibleIssue = maintenanceIssues.find((issue) => issue.key === "asset-accessible");

    if (accessibleIssue === undefined && assetAccessible === null) {
      return null;
    } else if (!accessibleIssue) {
      accessibleIssue = {
        key: "asset-accessible",
        correction: "further-review",
        issue: !assetAccessible,
      };
      maintenanceIssues.push(accessibleIssue);
    }

    return !accessibleIssue.issue;
  };

  return {
    getRoutineMaintenanceResultData,
    getIssueNameByKey,
    updateAssetAccessible,
  };
};

module.exports = MaintenanceResult();

const BmpFcsInventoryConstants = require("../../bmpfcs/bmpFcsInventoryConstants");
const RoutineMaintenanceFormTable = require("../routineMaintenanceFormTable");
