"use strict";

const LocationSelectionMap = function () {
  let locationMapHandlers = {
    locationDataUpdateHandler: null,
    checkNewMarkerHandler: null,
    newExistingMarkerHandler: null,
    badLocationUpdateHandler: null,
    createMarkerHandler: null,
  };
  let geosearchLayer;

  var configureLocationModalMapHandlers = function (handlers) {
    locationMapHandlers = {
      checkNewMarkerHandler: (handlers || {}).checkNewMarkerHandler || null,
      locationDataUpdateHandler: (handlers || {}).locationDataUpdateHandler || null,
      newExistingMarkerHandler: (handlers || {}).newExistingMarkerHandler || null,
      badLocationUpdateHandler: (handlers || {}).badLocationUpdateHandler || null,
      createMarkerHandler: (handlers || {}).createMarkerHandler || null,
    };
    const modalMapConfiguration = {
      modalMapClickHandler: locationSelectionMapClick,
      newExistingMarkerHandler: locationMapHandlers.newExistingMarkerHandler,
      dragEndHandler: getLocationData,
    };
    ModalMap.resetModalMapHandlers();
    ModalMap.configureModalMapHandlers(modalMapConfiguration);
  };

  var showLocationSelectionMap = function (existingLocation = false, zoomToMarker = true) {
    if (!ModalMap.getMap() || !existingLocation) {
      ModalMap.showModalMap();
    } else {
      ModalMap.updateModalMapDisplay();
    }
    setLocationText();
    ModalMap.showModalMapLegendGroup();
    $("#modalMap").show();
    let marker;

    if (existingLocation) {
      marker = ModalMap.getMarker();
      if (zoomToMarker) ModalMap.zoomToMarker();
    }
    if (!Tree.get("readOnlyModalMap")) {
      ModalMap.loadModalMapClickListeners();
      if (marker) {
        ModalMap.setMarkerDragHandlers(marker, false);
      }
    }
    ModalMap.invalidateModalMapSize();
  };

  var hideLocationSelectionMap = function () {
    $("#modalMap").hide();
  };

  var setLocationText = function () {
    ModalMap.showLocationMessage("Click map to set location");
  };

  var attachGeocoding = function (defaultHidden) {
    const modalMap = ModalMap.getMap();
    const geocodingControls = new L.Control.GeocodingControls({
      onSelectFunction: locationSelectionMapClick,
      visible: !defaultHidden,
    });
    modalMap?.addControl(geocodingControls);
  };

  var addGeosearch = function (map) {
    const apiKey = ToolSettings.getSetting("esri", "authkey");

    const searchControl = L.esri.Geocoding.geosearch({
      position: "bottomright",
      placeholder: "Enter an address",
      useMapBounds: false,
      providers: [
        L.esri.Geocoding.arcgisOnlineProvider({
          apikey: apiKey,
        }),
      ],
    }).addTo(map);

    var results = L.layerGroup().addTo(map);

    searchControl.on("results", function (data) {
      results.clearLayers();
      for (var i = data.results.length - 1; i >= 0; i--) {
        showGeosearchResult(map, data.results[i].latlng);
      }
    });
  };

  var showGeosearchResult = function (map, latLng) {
    if (latLng) {
      if (geosearchLayer) {
        map.removeLayer(geosearchLayer);
      }
      geosearchLayer = L.featureGroup();
      const locationCircle = getLocationCircle(latLng).addTo(geosearchLayer);
      geosearchLayer.addTo(map);

      locationCircle.on("mousedown", function (e) {
        removeGeosearchLayer(map);
      });
    }
  };

  var removeGeosearchLayer = function (map) {
    if (MapFunctions.mapHasLayer(map, geosearchLayer)) {
      map.removeLayer(geosearchLayer);
    }
  };

  var getLocationCircle = function (latLng) {
    return L.circleMarker(latLng, {
      pane: "blueDotPane",
      color: "black",
      fillColor: "#ffffff",
      fillOpacity: 0.5,
    });
  };

  var removeGeocoding = function () {
    const modalMap = ModalMap.getMap();
    if (modalMap.geocoding) {
      modalMap.removeControl(modalMap.geocoding);
    }
  };

  var toggleSearchControlVisibility = function ({ show = null } = {}) {
    if (show === null) {
      $("#modalMap").toggleClass("searching-by-address");
    } else {
      $("#modalMap").toggleClass("searching-by-address", show);
    }
  };

  var locationSelectionMapClick = function (e) {
    if (_handleGeocoderWrapperClick(e)) return;
    if (Tree.get(["leafletRulerEnabled", "modalMap"])) {
      return;
    }

    var latlng = e.latlng;

    let isNewMarker;
    if (locationMapHandlers.checkNewMarkerHandler !== null) {
      isNewMarker = locationMapHandlers.checkNewMarkerHandler(e);
    }
    getLocationData(latlng, isNewMarker);
  };

  function _handleGeocoderWrapperClick(e) {
    let shouldStop = false;
    if ($(".geocoder-wrapper").length) {
      if (e.originalEvent && DomFunctions.withinElement(e.originalEvent, $(".geocoder-wrapper")))
        shouldStop = true;
      if ($("#modalMap").hasClass("searching-by-address")) {
        toggleSearchControlVisibility({ show: false });
        if (e.originalEvent) shouldStop = true;
      }
    }

    return shouldStop;
  }

  var getLocationData = function (latlng, newMarker) {
    if (isInvalidNumber(latlng.lat) || isInvalidNumber(latlng.lng)) {
      restoreMarkerToLastValidLocation();
      return;
    }

    ApiCalls.getMarkerData(latlng.lat, latlng.lng, (locationInfo) => {
      let singleMarkerLayer = ModalMap.getMapLayer("singleMarkerLayer");

      if (locationInfo[0] && locationInfo.length > 0) {
        locationInfo = locationInfo[0];
        //set marker to new location
        if (newMarker) {
          Tree.set("modalMapSingleMarkerLastValidLatLng", latlng);

          if (!ModalMap.modalMapHasLayer("singleMarkerLayer")) {
            if (locationMapHandlers.createMarkerHandler === null) {
              singleMarkerLayer = ModalMap.createNewSingleMarker(latlng);
            } else {
              singleMarkerLayer = locationMapHandlers.createMarkerHandler(latlng);
            }
          } else {
            singleMarkerLayer.setLatLng(latlng);
          }
          ModalMap.setMarkerDragHandlers(singleMarkerLayer, true);
        } else {
          const existingMarker = ModalMap.getMarker();
          existingMarker.setLatLng(latlng);
        }
        // Send all the information back to let other modules update info
        if (locationMapHandlers.locationDataUpdateHandler !== null) {
          locationMapHandlers.locationDataUpdateHandler(locationInfo, latlng);
        } else {
          console.warn("Warning: locationDataUpdateHandler is not set");
        }
        ModalMap.showLocationMessage("Click map or drag marker to change location");
      } else {
        //location is NOT within ms4 boundary
        restoreMarkerToLastValidLocation();
        MessageModal.showSimpleWarningModal(
          "An asset cannot be located outside of the MS4 boundary. Please contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
    });
  };

  var isInvalidNumber = function (num) {
    const regexNotNumber = /[^\d\s-+\.]/;

    const numIsNaN = isNaN(parseFloat(num));
    const hasOtherChars = regexNotNumber.test(num);

    return numIsNaN || hasOtherChars;
  };

  var restoreMarkerToLastValidLocation = function () {
    const oldLatLng = Tree.get("modalMapSingleMarkerLastValidLatLng");

    if (locationMapHandlers.badLocationUpdateHandler !== null) {
      if (oldLatLng !== null) {
        locationMapHandlers.badLocationUpdateHandler(oldLatLng);
      } else {
        locationMapHandlers.badLocationUpdateHandler({ lat: null, lng: null });
      }
    }

    if (oldLatLng !== null) {
      const marker = ModalMap.getMarker();
      marker.setLatLng(oldLatLng);
    }
  };

  return {
    configureLocationModalMapHandlers,
    showLocationSelectionMap,
    hideLocationSelectionMap,
    locationSelectionMapClick,
    attachGeocoding,
    removeGeocoding,
    toggleSearchControlVisibility,
    getLocationData,
    showGeosearchResult,
    addGeosearch,
  };
};

module.exports = LocationSelectionMap();

const ApiCalls = require("../apiCalls");
const ModalMap = require("./modalMap");
const MessageModal = require("../modals/messageModal");
const Tree = require("../tree");
const DomFunctions = require("../general/domFunctions");
const MapFunctions = require("./mapFunctions");
const ToolSettings = require("../settings/toolSettings");
