"use strict";

var BarChartData = function () {
  var prepareData = function (
    allYears,
    additionalValuesData,
    submittedKey,
    isFutureKey,
    barSegmentGroupData,
    barSegmentLabel,
  ) {
    return allYears.map((year) => {
      const newYear = {};
      let isFuture = isFutureYear(year);

      if (isFutureKey !== null) {
        isFuture = isFuture || year[isFutureKey] === undefined;
      }
      newYear.isFuture = isFuture;
      newYear.additionalValues = getAdditionalValues(year, additionalValuesData);
      newYear.submitted = year[submittedKey];
      newYear.year = year.year;

      newYear.barSegmentGroups = getBarSegmentGroups(year, barSegmentGroupData);
      newYear.barSegmentLabel = barSegmentLabel;

      return newYear;
    });
  };

  var getAdditionalValues = function (year, additionalValuesData) {
    const defaultValues = {
      name: "",
      value: null,
      fieldName: "",
      clickable: false,
      format: "",
      unit: "",
      visibleInFuture: false,
      copiedIntoFuture: false,
    };

    return getYearData(year, additionalValuesData, defaultValues);
  };

  var getBarSegmentGroups = function (year, barSegmentGroupData) {
    const defaultValues = {
      year: year.year,
      name: "",
      value: null,
      fieldName: "",
      format: "bar",
    };

    return getYearData(year, barSegmentGroupData, defaultValues);
  };

  var getYearData = function (year, data, defaults = {}) {
    var toReturn = [];

    for (const values of data) {
      var newValue = {};
      Object.assign(newValue, defaults);

      for (const key in values) {
        newValue[key] = values[key];

        if (key === "fieldName") {
          newValue.value = roundInputValue(year[values[key]]);
        }
      }
      toReturn.push(newValue);
    }

    return toReturn;
  };

  var isFutureYear = function (yearData) {
    const AnnualReports = require("../../annualReports");

    const dataName = AnnualReports.mapDataViewToDataName(Tree.get("dataView"));
    const report = AnnualReports.getLatestMs4Report(dataName);

    if (!report) {
      return true;
    }

    const currentYear = AnnualReports.getLatestMs4Report(dataName).reportingYear;
    return yearData.year > currentYear;
  };

  var roundInputValue = function (val) {
    if (val === undefined || val === null || val === "") return val;
    return +Number(val).toFixed(4);
  };

  var prepareTrash = function (yearData, extendedView) {
    const additionalValuesData = [
      {
        name: "Total PLU",
        fieldName: "totalPlu",
        clickable: true,
        format: "unit",
        unit: "acres",
        visibleInFuture: true,
        copiedIntoFuture: true,
      },
      {
        name: "% Treated",
        fieldName: "percentPluTreated",
        format: "percent",
      },
      {
        name: "% In Progress",
        fieldName: "percentProgress",
        format: "percent",
      },
      {
        name: "Target Milestone",
        fieldName: "percentTreatedPluMilestone",
        format: "milestone",
      },
    ];
    const submittedKey = "trashSubmitted";
    const isFutureKey = "totalPlu";
    const barSegmentGroupData = [
      {
        name: "Treated PLU",
        fieldName: "treatedPlu",
      },
      {
        name: "In Progress PLU",
        fieldName: "progressPlu",
      },
      {
        name: "Untreated PLU",
        fieldName: "untreatedPlu",
      },
      {
        name: "Target Milestone",
        fieldName: "percentTreatedPluMilestone",
        format: "milestone",
      },
    ];
    const barSegmentLabel = {
      verticalUnits: "acres",
    };

    const trashData = prepareData(
      yearData,
      additionalValuesData,
      submittedKey,
      isFutureKey,
      barSegmentGroupData,
      barSegmentLabel,
    );
    return getRecentTrashData(trashData, extendedView);
  };

  var getRecentTrashData = function (yearArray, extendedView) {
    if (!extendedView) {
      yearArray = yearArray.slice(-5);
    }
    return yearArray;
  };

  var prepareRunoff = function (yearData, extendedView) {
    const additionalValuesData = [
      {
        name: "Baseline",
        fieldName: "baselineRunoff",
        clickable: true,
        format: "unit",
        unit: "afy",
        visibleInFuture: true,
        copiedIntoFuture: true,
      },
      {
        name: "Current",
        fieldName: "currentRunoff",
        format: "unit",
        unit: "afy",
      },
      {
        name: "% Reduction",
        fieldName: "reductionPercRunoff",
        format: "percent",
      },
    ];
    const submittedKey = "telrSubmitted";
    const isFutureKey = null;
    const barSegmentGroupData = [
      {
        name: "Current",
        fieldName: "currentRunoff",
      },
      {
        name: "Reduction",
        fieldName: "reductionRunoff",
      },
    ];

    const barSegmentLabel = {
      verticalUnits: "afy",
    };

    const runoffData = prepareData(
      yearData,
      additionalValuesData,
      submittedKey,
      isFutureKey,
      barSegmentGroupData,
      barSegmentLabel,
    );

    return getRecentTrashData(runoffData, extendedView);
  };

  var prepareParticulate = function (yearData, extendedView) {
    const additionalValuesData = [
      {
        name: "Baseline",
        fieldName: "baselineTSS",
        clickable: true,
        format: "unit",
        unit: "ton",
        visibleInFuture: true,
        copiedIntoFuture: true,
      },
      {
        name: "Current",
        fieldName: "currentTss",
        format: "unit",
        unit: "ton",
      },
      {
        name: "% Reduction",
        fieldName: "reductionPercTss",
        format: "percent",
      },
    ];
    const submittedKey = "telrSubmitted";
    const isFutureKey = null;
    const barSegmentGroupData = [
      {
        name: "Current",
        fieldName: "currentTss",
      },
      {
        name: "Reduction",
        fieldName: "reductionTss",
      },
    ];

    const barSegmentLabel = {
      verticalUnits: "ton",
    };

    const particulateData = prepareData(
      yearData,
      additionalValuesData,
      submittedKey,
      isFutureKey,
      barSegmentGroupData,
      barSegmentLabel,
    );

    return getRecentTrashData(particulateData, extendedView);
  };

  return {
    prepareData,
    getAdditionalValues,
    getBarSegmentGroups,
    getYearData,
    isFutureYear,
    roundInputValue,
    prepareTrash,
    prepareRunoff,
    prepareParticulate,
    getRecentTrashData,
  };
};

module.exports = BarChartData();

const Tree = require("../../../tree");
