"use strict";

var MaintenanceZonesLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      "maintenanceZones",
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers.maintenanceZonesLayer && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers.maintenanceZonesLayer);
          } else {
            loadLayer();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.maintenanceZonesLayer)) {
            map.removeLayer(mapLayers.maintenanceZonesLayer);
          }
        }
      },
    );
  };

  var loadLayer = function () {
    var mapId = Tree.get("mapId");
    var filters = Tree.get("filters");

    Tree.set(["layers", mapId, "maintenanceZones", "isFetching"], true);
    ReportApiCalls.getMaintenanceZones(filters).then((data) => {
      Tree.set(["layers", mapId, "maintenanceZones", "isFetching"], false);
      Tree.set(["layers", mapId, "maintenanceZones", "data"], data);
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(Tree, mapId, "maintenanceZones", function (data) {
      mapLayers.maintenanceZonesLayer = createLayerAndAddToMap(map, mapLayers, data);
    });
  };

  var createLayerAndAddToMap = function (map, mapLayers, data) {
    if (mapLayers.maintenanceZonesLayer) {
      map.removeLayer(mapLayers.maintenanceZonesLayer);
    }
    mapLayers.maintenanceZonesLayer = createLayer(data);
    mapLayers.maintenanceZonesLayer.addTo(map);
    return mapLayers.maintenanceZonesLayer;
  };

  var createLayer = function (data) {
    return L.geoJson(data, {
      pane: "overCatchmentPane",
      style: MapStyles.styleMaintenanceZones,
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => nunjucks.render("report/popups/maintenanceZonePopup.njk", feature.properties),
          getPopupOptions(),
        );
      },
    });
  };

  var getPopupOptions = function () {
    var mapOptions = CommonMapFunctions.getMapOptions();
    return Object.assign({}, mapOptions.popupOptions, {
      autoPan: true,
      autoPanPaddingTopLeft: new L.Point(0, 70),
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    createLayer,
  };
};

module.exports = MaintenanceZonesLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../../tree");
const ReportApiCalls = require("../reportApiCalls");
const MapStyles = require("../mapFunctions/mapStyles");
const CommonMapFunctions = require("../mapFunctions/commonMapFunctions");
