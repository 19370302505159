"use strict";

const ProjectDeliverySummaryTable = function () {
  var render = async function () {
    const projectLayerData = Tree.get(["layers", "constructionProject", "data"]);
    if (!projectLayerData) {
      return;
    }

    const summaryData = await LoadingScreen.waitForResult(
      ".project-delivery-summary div",
      ApiCalls.getProjectDeliverySummary(),
    );

    filterSummaryDataByPhase(summaryData);

    for (const phase in summaryData) {
      summaryData[phase]["projectCount"] = 0;
    }

    projectLayerData.forEach((project) => {
      const phase = project.phase;
      if (summaryData[phase]) {
        summaryData[phase]["projectCount"]++;
        summaryData["total"]["projectCount"]++;
      }
    });

    const template = "maps/projectDeliverySummaryTable.njk";

    MapSummaryTable.renderSummaryTableContent(template, {
      data: summaryData,
    });

    handleGeoFocusFilterTitleDisplay();
  };

  var filterSummaryDataByPhase = function (summaryData) {
    const phaseFilter = Tree.get("filters", "phase");

    // Convert phaseFilter array to a Set for O(1) lookup times
    const phaseSet = new Set(phaseFilter);

    if (phaseSet.size) {
      for (const phase in summaryData) {
        if (!phaseSet.has(phase) && phase !== "total") {
          delete summaryData[phase];
        }
      }
    }
  };

  var handleGeoFocusFilterTitleDisplay = function () {
    const geoFocusItems = getSelectedGeoFocusItems();

    if (geoFocusItems.length > 1) {
      $(".summary-spatial-title").hide();
    } else {
      if (geoFocusItems.length === 1) {
        $(".summary-spatial-title-text").text(geoFocusItems[0]);
      }
      $(".summary-spatial-title").show();
    }
  };

  var getSelectedGeoFocusItems = function () {
    const filters = Tree.get("filters");
    const spatialFilterKeys = [
      "catchmentName",
      "drainsToC",
      "drainsToRw",
      "highwayName",
      "drainsToSpatialGroupingId",
      "drainsToAssesmentArea",
    ];
    let geoFocusItems = [];

    spatialFilterKeys.forEach((key) => {
      if (key in filters) {
        const items = filters[key];
        if (items.length && !FilterSummary.areAllCheckboxesChecked(key)) {
          geoFocusItems = geoFocusItems.concat(items);
        }
      }
    });

    return geoFocusItems;
  };

  return {
    render,
    filterSummaryDataByPhase,
  };
};

module.exports = ProjectDeliverySummaryTable();

const ApiCalls = require("../apiCalls");
const MapSummaryTable = require("../general/mapSummaryTable");
const Tree = require("../tree");
const FilterSummary = require("../filters/filterSummary");
const LoadingScreen = require("../general/loadingScreen");
