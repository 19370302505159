"use strict";

const StreetSweepingPopup = function () {
  var getStreetSweepingPopup = async function (feature) {
    const history = await getRecordSweepHistory(
      feature.properties.network_name,
      feature.properties.catch_id,
    );
    const hasSweepingNetwork = !!feature.properties.network_name;
    const showRecordSweep = UserPermissions.getPermission("can_create_record_sweeps");
    const lastRoadOm = _getLastRoadOm(history);
    _setRoadOmDisplayNames(lastRoadOm);

    var popopProps = {};
    const esriLayerId = EsriLayerFunctions.getLayerIdsToIdentify("roadsOm")?.[0];
    if (esriLayerId !== undefined && lastRoadOm) {
      const esriLayerName = EsriLayerFunctions.getEsriLayerNameByLayerId("roadsOm", esriLayerId);
      feature.properties.priority = lastRoadOm.priority;
      feature.properties.sweeper_type = lastRoadOm.sweeperType;
      feature.properties.sweeping_frequency = lastRoadOm.sweeperFrequency;
      feature.properties.curb_access = lastRoadOm.roadAccessible;
      feature.properties.pci = lastRoadOm.pci;
      popopProps = getStreetSweepingRoutePopupProps(feature, esriLayerName);
    }

    Tree.set(["streetSweepingPopupData", feature.properties.objectid], feature.properties);
    return nunjucks.render(
      "popups/streetSweeping.njk",
      Object.assign({}, feature.properties, popopProps, {
        hasSweepingNetwork,
        showRecordSweep,
        history,
        lastRoadOm,
      }),
    );
  };

  var getStreetSweepingRoutePopup = async function (feature, esriLayerName) {
    const history = await getRecordSweepHistory(
      feature.properties.route_name,
      feature.properties.route_name,
    );
    const showRecordSweep = UserPermissions.getPermission("can_create_record_sweeps");
    const popopProps = getStreetSweepingRoutePopupProps(feature, esriLayerName);

    _setRoadOmDisplayNames(feature.properties);

    popopProps.objectid = feature.properties.objectid || feature.properties.OBJECTID;
    Tree.set(["streetSweepingPopupData", popopProps.objectid], feature.properties);
    return nunjucks.render(
      "popups/streetSweepingRoute.njk",
      Object.assign({}, feature.properties, popopProps, {
        showRecordSweep,
        history,
      }),
    );
  };

  var getStreetSweepingRoutePopupProps = function (feature, esriLayerName) {
    if (["streetSweepingRoutePriority", "roadPriority"].includes(esriLayerName)) {
      return getStreetSweepingRoutePriorityPopupColorProps(feature);
    } else if (["streetSweepingRouteSweeperType", "roadSweeperType"].includes(esriLayerName)) {
      return getStreetSweepingRouteSweeperTypePopupColorProps(feature);
    } else if (
      ["streetSweepingRouteSweepingFrequency", "roadSweepingFrequency"].includes(esriLayerName)
    ) {
      return getStreetSweepingRouteSweepingFrequencyPopupColorProps(feature);
    } else if (["streetSweepingRouteCurbAccess", "roadCurbAccess"].includes(esriLayerName)) {
      return getStreetSweepingRouteCurbAccessPopupColorProps(feature);
    } else if (["streetSweepingRoutePci", "roadPci"].includes(esriLayerName)) {
      return getStreetSweepingRoutePciPopupColorProps(feature);
    }

    return {
      backgroundColorClass: "background-grey",
    };
  };

  var getStreetSweepingRoutePriorityPopupColorProps = function (feature) {
    return getStreetSweepingRoutePopupColorProps(
      feature,
      "priority",
      "route-priority",
      {
        "very-low": 1,
        low: 2,
        moderate: 3,
        high: 4,
        "very-high": 5,
      },
      [1, 2],
    );
  };

  var getStreetSweepingRouteSweeperTypePopupColorProps = function (feature) {
    return getStreetSweepingRoutePopupColorProps(feature, "sweeper_type", "route-sweeper-type", {
      mechanical_broom: 1,
      vacuum_assisted: 2,
      regenerative_air: 3,
    });
  };

  var getStreetSweepingRouteSweepingFrequencyPopupColorProps = function (feature) {
    return getStreetSweepingRoutePopupColorProps(
      feature,
      "sweeping_frequency",
      "route-sweeping-frequency",
      {
        less_than_monthly: 1,
        monthly: 2,
        two_monthly: 3,
        weekly: 4,
        two_weekly: 5,
      },
    );
  };

  var getStreetSweepingRouteCurbAccessPopupColorProps = function (feature) {
    return getStreetSweepingRoutePopupColorProps(feature, "curb_access", "route-curb-access", {
      greater_than_75: 1,
      between_50_75: 2,
      between_25_50: 3,
      less_than_25: 4,
    });
  };

  var getStreetSweepingRoutePciPopupColorProps = function (feature) {
    return getStreetSweepingRoutePopupColorProps(
      feature,
      "pci",
      "route-pci",
      {
        greater_than_75: 1,
        between_50_75: 2,
        between_25_50: 3,
        less_than_25: 4,
      },
      [2, 3],
    );
  };

  var getStreetSweepingRoutePopupColorProps = function (
    feature,
    propertyKey,
    classKey = "",
    colorKeyMap = {},
    lightTextKeys = [],
  ) {
    const prop = feature?.properties[propertyKey];
    const backgroundColorClassPrefix = `bg-color-${classKey}-color`;
    let backgroundColorClass = "background-grey";
    let textColorClass = "";

    if (prop && prop in colorKeyMap) {
      const colorKey = colorKeyMap[prop];
      backgroundColorClass = `${backgroundColorClassPrefix}-${colorKey}`;

      if (lightTextKeys.includes(colorKey)) {
        textColorClass = "light-text";
      }
    }

    return { backgroundColorClass, textColorClass };
  };

  var getRecordSweepHistory = async function (networkName, catchId) {
    const history = await ApiCalls.getRecordSweepHistory(networkName, catchId);
    Tree.set("recordSweepHistory", history);
    return history;
  };

  var loadRecordSweepHistory = async function (networkName, catchId) {
    const history = await getRecordSweepHistory(networkName, catchId);
    updateRecordSweepHistory(history);
  };

  var updateRecordSweepHistory = function (history) {
    const debrisCollectedUnitOptions = RecordSweepConstants.debrisCollectedUnitOptions;
    const historyHtml = nunjucks.render("popups/recordSweepHistory.njk", {
      history,
      debrisCollectedUnitOptions,
    });
    $(".popup-general").find(".popup-history-pane").html(historyHtml);
    updateWeeksSinceLastSweep(history);
  };

  var updateWeeksSinceLastSweep = function (history) {
    const weeksSinceLastSweep = getWeeksSinceLastSweep(history);
    var weeksSinceLastSweepText;
    if (weeksSinceLastSweep) {
      weeksSinceLastSweepText = `${weeksSinceLastSweep} weeks`;
    } else {
      weeksSinceLastSweepText = "—";
    }
    $(".popup-general").find(".weeks-since-last-sweep").text(weeksSinceLastSweepText);
  };

  var getWeeksSinceLastSweep = function (history = Tree.get("recordSweepHistory")) {
    if (history?.length) {
      var lastSweepDate = history[0].displayDate;
      var lastSweepMoment = Flatpickr.parseDate(lastSweepDate, "m/d/Y");
      var nowDate = new Date(Date.now());
      return DateTime.getWeeksDifference(nowDate, lastSweepMoment);
    }
  };

  var getOptionNameByValue = function (options, value) {
    return options.find((option) => option.value === value)?.name ?? null;
  };

  var _setRoadOmDisplayNames = function (roadOm) {
    if (!roadOm) {
      return;
    }

    roadOm.displaySweeperType = getOptionNameByValue(
      RoadBulkOmOptions.sweeperTypeSelectOptions,
      roadOm.sweeperType || roadOm.sweeper_type,
    );
    roadOm.displaySweeperFrequency = getOptionNameByValue(
      RoadBulkOmOptions.sweeperFrequencySelectOptions,
      roadOm.sweeperFrequency || roadOm.sweeping_frequency,
    );
    roadOm.displayRoadAccessible = getOptionNameByValue(
      RecordSweepConstants.curbAccessibilitySelectOptions,
      roadOm.roadAccessible || roadOm.curb_access,
    );
    roadOm.displayPci = getOptionNameByValue(
      RoadBulkOmOptions.averagePciSelectOptions,
      roadOm.pci || roadOm.pci,
    );
  };

  var _getLastRoadOm = function (history) {
    for (const item of history) {
      if (item?.assetType === "road-condition") {
        return item;
      }
    }

    return null;
  };

  return {
    getStreetSweepingPopup,
    getStreetSweepingRoutePopup,
    loadRecordSweepHistory,
    getWeeksSinceLastSweep,
    _getLastRoadOm,
    _setRoadOmDisplayNames,
  };
};

module.exports = StreetSweepingPopup();

const ApiCalls = require("../apiCalls");
const DateTime = require("../dateTime");
const RecordSweepConstants = require("./recordSweepConstants");
const UserPermissions = require("../login/userPermissions");
const Tree = require("../tree");
const Flatpickr = require("flatpickr");
const RoadBulkOmOptions = require("../bulkSelect/roadBulkOmOptions");
const EsriLayerFunctions = require("../mapFunctions/esriLayerFunctions");
