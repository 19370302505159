"use strict";

// @TODO: refactor to be a generic muni inspection
const DryWeatherController = function () {
  const htmlPath = "modals/dryWeatherScreening";
  const formKey = "dry-weather-screening";

  let $modal;
  let tabs;
  let data;
  let type;
  let assetId;

  var loadDryWeatherScreening = async function ({
    screeningId = null,
    id,
    readOnly,
    assetType,
    maintenanceType = null,
  } = {}) {
    if (screeningId !== null) {
      data = await getApiCall(assetType, "get")(screeningId);
    } else {
      data = DryWeatherConstants.getNewDryWeatherScreeningDefaults(id, assetType);
      data.asset = await ApiCalls.getIddeDryWeatherAsset(id, assetType);
      data.maintenanceType = maintenanceType ?? "routine";
    }

    assetId = id;
    type = assetType;

    showDryWeatherModal(data, readOnly);
  };

  var deleteDryWeatherScreening = function ({ screeningId, assetType, $todoTr } = {}) {
    const deleteApiCall = getApiCall(assetType, "delete");

    MessageModal.showDeleteRecordModal(function () {
      deleteApiCall(screeningId);
      $todoTr.remove();
    });
  };

  var getApiCall = function (assetType, action) {
    const apiCalls = {
      manhole: {
        get: ApiCalls.getManholeInspection,
        save: ApiCalls.createOrUpdateManhole,
        delete: ApiCalls.deleteManholeInspection,
        downloadFile: ApiCalls.downloadManholeFile,
      },
    };

    const result = apiCalls[assetType]?.[action];

    if (!result) {
      throw new Error(`No API call defined for asset type ${assetType} and action ${action}`);
    }

    return result;
  };

  var showDryWeatherModal = function (data, readOnly) {
    const GeneralInfo = require("../../outfalls/routineMaintenance/outfallRoutineMaintenanceGeneralInfo");
    tabs = [new GeneralInfo(module.exports)];
    addDryWeatherTabs(tabs, module.exports);
    const renderData = mapTabProps(tabs, data);

    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, { readOnly });

    initializeForm(data);
    checkFlowTabs(data);
    setInspectionHeaderText(data);
    setAdditionalModalHandlers();
  };

  var addDryWeatherTabs = function (tabs, moduleExports) {
    const Measurements = require("../../outfalls/routineMaintenance/outfallRoutineMaintenanceMeasurements");
    const Sample = require("../../outfalls/routineMaintenance/outfallRoutineMaintenanceSample");

    tabs.push(new Measurements(moduleExports));
    tabs.push(new Sample(moduleExports));
  };

  var mapTabProps = function (tabs, data) {
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
    };
  };

  var getHeaderHtml = function (data) {
    return nunjucks.render("modals/dryWeatherScreening/dryWeatherHeader.njk", data);
  };

  var setInspectionHeaderText = function (data) {
    TabbedModal.setHeaderText(getHeaderHtml(data));
  };

  var checkFlowTabs = function (data) {
    if (data.flowPresent === true || data.standingWaterPresent === true) {
      toggleFlowTabs(true);
    } else {
      toggleFlowTabs(false);
    }
  };

  var toggleFlowTabs = function (visibility = false) {
    const toggleMeasurements = ToolSettings.getSetting("outfall", "hide-field-measurements")
      ? false
      : visibility;
    TabbedModal.toggleTab("measurements", toggleMeasurements);
    TabbedModal.toggleTab("sample", visibility);
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(() => {});
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  };

  var saveButtonClick = async function () {
    TabbedModal.hide(false);

    const result = DryWeatherResult.getDryWeatherResultData(
      data.asset.name,
      getInspectionRenderData(),
    );

    ResultModal.renderAndShow([result], getHeaderHtml(data), saveAndCloseModal, TabbedModal.show, {
      createsPdf: false,
    });

    return false;
  };

  var saveAndCloseModal = async function () {
    try {
      const editExisting = data.id !== undefined;
      const inspectionData = _getUpdateData(editExisting);
      if (editExisting) {
        inspectionData.id = data.id;
      }
      await createOrUpdateInspection(inspectionData);

      TabbedModal.hide();
      DryWeatherFunctions.invalidateAllDryWeatherLayersData();
    } catch (e) {
      TabbedModal.show();
      return UploadValidator.reportError(e);
    }
  };

  var createOrUpdateInspection = async function (inspectionData) {
    const toUpload = { maintenanceInspection: inspectionData };

    return await getApiCall(type, "save")(assetId, toUpload);
  };

  var _getUpdateData = function (editExisting = false) {
    let dataToUpload = $.extend(true, {}, Form.getDataFromForm(formKey, false));

    processInspectionFormData(data, dataToUpload);
    if (!editExisting) {
      dataToUpload = mergeDefaultsIntoUpdates(dataToUpload);
    }

    return dataToUpload;
  };

  var processInspectionFormData = function (initial, dataToUpload) {
    if (initial.maintenanceType) {
      dataToUpload.maintenanceType = initial.maintenanceType;
    }

    processInspectionAttribute(initial, dataToUpload, "measurements");
    processInspectionAttribute(initial, dataToUpload, "samples");
  };

  var processInspectionAttribute = function (initial, object, path) {
    if (object !== undefined && object[path] !== undefined) {
      cleanInspectionIds(initial[path], object[path]);
      object[path] = Object.values(object[path]);
    }
  };

  var cleanInspectionIds = function (existingData, inspectionData) {
    const existingIds = existingData
      ? existingData.map((data) => {
          return data.id;
        })
      : [];
    Misc.removeAttributeValues(existingIds, inspectionData, "id");
  };

  var mergeDefaultsIntoUpdates = function (dataToUpload) {
    return $.extend(true, {}, data, dataToUpload);
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getInspectionRenderData = function () {
    return $.extend(true, {}, data, Form.getDataFromForm(formKey, false));
  };

  var getExistingInspectionData = function () {
    return $.extend(true, {}, data);
  };

  var getFormData = function (path) {
    return Form.getFormDataAtPath(formKey, path);
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var unsetFormData = function (path) {
    return Form.manuallyUnsetField(formKey, path);
  };

  var initializeForm = function (data) {
    Form.initializeAndLoadListeners($modal, formKey);
    FormSettings.customizeForm($modal, formKey);

    Form.initializeDropzone(formKey, $modal, {
      existingFiles: data.files ?? [],
      downloadCallback: getApiCall(type, "downloadFile"),
    });
  };

  return {
    loadDryWeatherScreening,
    toggleFlowTabs,
    checkFlowTabs,
    getInspectionRenderData,
    getExistingInspectionData,
    getFormData,
    setFormData,
    unsetFormData,
    saveAndCloseModal,
    processInspectionFormData,
    addDryWeatherTabs,
    deleteDryWeatherScreening,
    _getUpdateData,
  };
};

module.exports = DryWeatherController();

const ApiCalls = require("../../apiCalls");
const DryWeatherConstants = require("./dryWeatherConstants");
const DryWeatherFunctions = require("../dryWeatherFunctions");
const DryWeatherResult = require("../../general/results/dryWeatherResult");
const Form = require("../../general/form");
const Misc = require("../../misc");
const ResultModal = require("../../general/resultModal");
const TabbedModal = require("../../general/tabbedModal");
const FormSettings = require("../../settings/formSettings");
const UploadValidator = require("../../general/uploadValidator");
const ToolSettings = require("../../settings/toolSettings");
const MessageModal = require("../../modals/messageModal");
