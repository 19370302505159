"use strict";

const StructuralBmpDataListController = function () {
  var inited = false;
  var config = null;

  var init = function (reInitialize = false) {
    if (inited && !reInitialize) {
      render();
    } else {
      inited = true;
      config = {
        prepareDataCallback: _prepareStructuralBmps,
        template: "list/structuralBmpDataList.njk",
        layerName: "structuralBmps",
      };
      DataList.init(config);
      initialRender();
      loadListeners();
      DataList.loadDomListeners();
    }
  };

  var loadListeners = function () {
    $(".data-list-container").off("click", ".map-button");
    $(".data-list-container").off("click", ".add-assessment-button");
    $(".data-list-container").off("click", ".add-benchmark-button");
    $(".data-list-container").off("click", ".edit-benchmark-button");

    $(".data-list-container").on("click", ".map-button", showMarkerOnMap);
    $(".data-list-container").on("click", ".add-assessment-button", showNewBmpAssessmentClick);
    $(".data-list-container").on("click", ".add-benchmark-button", showNewBenchmarkClick);
    $(".data-list-container").on("click", ".edit-benchmark-button", showEditBenchmarkClick);
  };

  var showMarkerOnMap = function () {
    var $tr = $(this).parents("tr");
    var id = $tr.data("id");

    MapFunctions.openPopupById(id);
    const mapButtonContainer = $(this).parents("td");
    DataList.addAnalyticsToMapIconOnAddress(mapButtonContainer.hasClass("address-col"));
  };

  var setCurrentBmpData = function (id) {
    const allBmps = Tree.get("layers", "structuralBmps", "data");
    const currentBmps = allBmps.find((fcs) => fcs.idBmp === id);

    Tree.set("currentBmpFcs", currentBmps);
  };

  var showNewBmpAssessmentClick = function () {
    const data = $(this).parents("tr").data();

    setCurrentBmpData(data.id);
    BmpObservation.showBmpObsModal(data.id, data.bmpName, data.type, data.typeNumber, false);
  };

  var showEditBenchmarkClick = function () {
    const data = $(this).parents("tr").data();

    setCurrentBmpData(data.id);
    Benchmark.showEditBenchmarkModal(
      data.bmpName,
      data.id,
      data.type,
      data.typeNumber,
      data.benchmarkId,
      false,
    );
  };

  var showNewBenchmarkClick = function () {
    const data = $(this).parents("tr").data();
    setCurrentBmpData(data.id);

    Benchmark.showNewBenchmarkModal(data.bmpName, data.type, data.typeNumber);
  };

  var initialRender = function () {
    DataList.setSorting("bmpName", false);
    render();
  };

  var render = function () {
    DataList.render(config);
  };

  var addSbmpBenchmarkActionDropdown = function (bmp) {
    var hasBenchmark = !!bmp.currentBenchmarkId;
    var bmpInventoryComplete = bmp.bmp_stat;

    bmp.hasBenchmark = hasBenchmark;
    bmp.showAddNewBenchmarkButton =
      bmp.permissions.canCreateBenchmark &&
      bmpInventoryComplete &&
      !bmp.planned &&
      (!hasBenchmark ||
        (bmp.currentBenchmarkComplete && bmp.currentBenchmarkHasObs) ||
        (bmp.currentBenchmarkComplete && bmp.skipObservation));

    bmp.showEditBenchmarkButton =
      bmp.permissions.canCreateBenchmark &&
      hasBenchmark &&
      !bmp.currentBenchmarkHasObs &&
      !(bmp.currentBenchmarkComplete && bmp.skipObservation);
  };

  var addSbmpAssessmentActionDropdown = function (bmp, skipBenchmark) {
    if (!bmp.bmp_stat || bmp.planned) return;
    if ((bmp.currentBenchmarkId && bmp.currentBenchmarkComplete) || skipBenchmark) {
      if (bmp.currentBenchmarkHasObs || skipBenchmark) {
        if (bmp.currentObsComplete || bmp.currentObsId === null) {
          bmp.showAddSbmpAssessmentButton = true;
        } else {
          bmp.showEditSbmpAssessmentButton = true;
        }
      } else {
        bmp.showAddSbmpAssessmentButton = true;
      }
      if (bmp.permissions.canCreateObservation === false) {
        bmp.showAddSbmpAssessmentButton = false;
        bmp.showEditSbmpAssessmentButton = false;
      }
    }
  };

  var _prepareStructuralBmps = function (structuralBmps) {
    sortStructuralBmpsBy(structuralBmps);

    for (const bmp of structuralBmps) {
      bmp.skipBenchmark = BmpFunctions.getSkipBenchmark(bmp);
      bmp.iconClass = _getIconClass(bmp);
      if (!bmp.skipObservation) {
        addSbmpAssessmentActionDropdown(bmp, bmp.skipBenchmark);
      }
      if (!bmp.skipBenchmark) {
        addSbmpBenchmarkActionDropdown(bmp);
      }
    }

    return {
      rows: structuralBmps,
      options: {},
    };
  };

  var _getIconClass = function (bmp) {
    if (Tree.get("layers", "structuralBmps", "isEnabled")) {
      return StructuralBmpsGeoServerLayer.getTableIcon(bmp);
    } else {
      return MapStyles.getBmpTodoIconClasses(bmp);
    }
  };

  var sortStructuralBmpsBy = function (structuralBmps) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;

    var sortersDict = {
      bmpName: alphaSorter,
      address: alphaSorter,
      bmp_type: alphaSorter,
      bmp_wqcap: numberSorter,
      cof_final_text: alphaSorter,
    };
    DataList.sortWithDict(structuralBmps, sortersDict);
  };

  return {
    init,
    _getIconClass,
  };
};

module.exports = StructuralBmpDataListController();

const DataList = require("../mapFunctions/dataList");
const Tree = require("../tree");
const SortUtilities = require("../general/sortUtilities.js");
const MapFunctions = require("../mapFunctions/mapFunctions.js");
const MapStyles = require("../mapFunctions/mapStyles.js");
const BmpFunctions = require("../modals/bmpFunctions.js");
const BmpObservation = require("../modals/bmpObservation.js");
const Benchmark = require("../modals/benchmark.js");
const StructuralBmpsGeoServerLayer = require("../bmpFcsG2/structuralBmpsGeoServerLayer");
