"use strict";

const TelrResultBmpFilters = function () {
  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          TelrResultBmpGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData, filters) {
    return unfilteredData.filter((datum) => {
      return passPhaseFilter(datum, filters) && passTelrReadyFilter(datum, filters);
    });
  };

  var onlineFilterIsSet = function (filters) {
    return ResourceFilters.spatialFiltersAreSet(filters);
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    const dataScenario = Tree.get("dataScenario");

    let phases = ["completed", "certified"];
    if (dataScenario.includes("potential")) {
      phases = ["completed", "certified", "planning", "planned", "construction", "post-active"];
    }
    queryStrings.push(GeoServerFilterFunctions.getInArrayQuery("phase", phases));

    queryStrings.push("telr_ready = true");
  };

  var passPhaseFilter = function (datum, filters) {
    var phase = datum.phase;

    const dataScenario = Tree.get("dataScenario");

    let phases = ["completed", "certified"];
    if (dataScenario.includes("potential")) {
      phases = ["completed", "certified", "planning", "planned", "construction", "post-active"];
    }

    return phases.includes(phase);
  };

  var passTelrReadyFilter = function (datum, filters) {
    return datum.telr_ready;
  };

  return {
    filterResourceDataHandler,
    addGeoServerFilterQuery,
  };
};

module.exports = TelrResultBmpFilters();

const ApiError = require("../errors/apiError");
const ResourceFilters = require("../filters/resourceFilters");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const Tree = require("../tree");
const TelrResultBmpGeoServerLayer = require("./telrResultBmpGeoServerLayer");
