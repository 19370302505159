"use strict";

const EsgApiCalls = function () {
  function error(xhr, textStatus, errorThrown) {
    console.warn("API ERROR", xhr, textStatus, errorThrown);
  }

  function redirectToHomepage(e) {
    if (e.status === 404) {
      return UrlRoutes.navigate("/portfolio");
    } else {
      throw e;
    }
  }

  const validateUpdateProperty = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/esg/${groupId}/validate-property`, data),
    );
  };

  const deleteProperty = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("delete", `/esg/${groupId}/property`, { id }, Network.noLoadingScreen()),
    );
  };

  const getProperty = function (id, planId, loadingScreen = false) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/property`,
        { id, planId },
        Network.noLoadingScreen(loadingScreen),
      ),
    ).catch(redirectToHomepage);
  };

  const updateProperty = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.apiPostWithFormData(`/esg/${groupId}/property`, data));
  };

  const createProperty = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.apiPostWithFormData(`/esg/${groupId}/property/new`, data));
  };

  const getPropertyPopup = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/esg/${groupId}/property-popup`, { id }, Network.noLoadingScreen()),
    );
  };

  const getPropertyRankingSettings = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/esg/${groupId}/property-weights`, {}));
  };

  const updatePropertyRankingSettings = function (data) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("post", `/esg/${groupId}/property-weights`, data));
  };

  var getProgramImpact = function () {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/esg/${groupId}/program-impact`, {}, Network.noLoadingScreen()),
    );
  };

  var getPlanImpact = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/esg/${groupId}/plan/impact`, { id }, Network.noLoadingScreen()),
    ).catch(redirectToHomepage);
  };

  var getPlanProperties = function (id, type) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/plan/properties`,
        { id, type },
        Network.noLoadingScreen(),
      ),
    ).catch(redirectToHomepage);
  };

  const getOpportunityPortfolio = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("get", `/esg/${groupId}/portfolio`));
  };

  const getProperties = function (planId, planType) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/properties`,
        { planId, planType },
        Network.noLoadingScreen(),
      ),
    );
  };

  const newOrEditPlan = function (id = null, editObjectives = true) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/plan/new-or-edit`,
        { id, editObjectives: true },
        Network.noLoadingScreen(),
      ),
    );
  };

  const getPlan = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/esg/${groupId}/plan`, { id }, Network.noLoadingScreen()),
    ).catch(redirectToHomepage);
  };

  const getFullPlan = function (id, { loadingScreen = false } = {}) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/plan/full`,
        { id },
        Network.noLoadingScreen(loadingScreen),
      ),
    ).catch(redirectToHomepage);
  };

  const getPlans = function () {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("get", `/esg/${groupId}/plans`, null, Network.noLoadingScreen()),
    );
  };

  const createPlan = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("post", `/esg/${groupId}/plan`, inputData));
  };

  const updatePlan = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(Network.api("put", `/esg/${groupId}/plan`, inputData));
  };

  const updatePropertyCost = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.apiPostWithFormData(`/esg/${groupId}/property/cost`, inputData),
    );
  };

  const deletePlan = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api("delete", `/esg/${groupId}/plan`, { id }, Network.noLoadingScreen()),
    );
  };

  const shelveProperties = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "put",
        `/esg/${groupId}/plan/properties/shelve`,
        inputData,
        Network.noLoadingScreen(),
      ),
    );
  };

  const unshelveProperties = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "put",
        `/esg/${groupId}/plan/properties/unshelve`,
        inputData,
        Network.noLoadingScreen(),
      ),
    );
  };

  const selectProperties = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "put",
        `/esg/${groupId}/plan/properties/select`,
        inputData,
        Network.noLoadingScreen(),
      ),
    );
  };

  const deselectProperties = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "put",
        `/esg/${groupId}/plan/properties/deselect`,
        inputData,
        Network.noLoadingScreen(),
      ),
    );
  };

  const loadTenProperties = function (inputData) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "put",
        `/esg/${groupId}/plan/properties/selectTen`,
        inputData,
        Network.noLoadingScreen(),
      ),
    );
  };

  const getSelectablePropertyCount = function (id) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/plan/properties/selectTen`,
        { id },
        Network.noLoadingScreen(),
      ),
    );
  };

  var getConstants = function (constants, planId = null) {
    const groupId = Session.getGroupIdToPass();
    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/constants`,
        { constants, planId },
        Network.noLoadingScreen(),
      ),
    );
  };

  var getMarkerData = function (lat, lng, callback) {
    const groupId = Tree.get("activeGroup", "groupId");
    return Network.api(
      "get",
      "/esg/" + groupId + "/bmpram-location",
      {
        lat: lat,
        long: lng,
      },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var checkUniqueBmpName = function (groupId, bmpName, callback) {
    return Network.asPromise(
      Network.api(
        "get",
        "/esg/" + groupId + "/unique-id",
        {
          bmp_id: bmpName,
        },
        Network.noLoadingScreen(),
      )
        .done(callback)
        .fail(error),
    );
  };

  var checkpropertyIdentifierUnique = function (propertyIdentifier, existingId = null) {
    const groupId = Tree.get("activeGroup", "groupId");

    return Network.asPromise(
      Network.api(
        "get",
        `/esg/${groupId}/property/id`,
        {
          id: existingId,
          propertyIdentifier,
        },
        Network.noLoadingScreen(),
      ),
    );
  };

  var getOtherProperties = function (propertyId, callback) {
    const groupId = Tree.get("activeGroup", "groupId");

    return Network.api(
      "get",
      "/esg/" + groupId + "/other-properties",
      { propertyId },
      Network.noLoadingScreen(),
    )
      .done(callback)
      .fail(error);
  };

  var downloadPropertyFile = function (fileId) {
    const groupId = Tree.get("activeGroup", "groupId");
    Downloader.fromApi("post", `/esg/${groupId}/property-file`, { fileId });
  };

  return {
    createPlan,
    deletePlan,
    getPlans,
    getProgramImpact,
    getPlanImpact,
    getPlanProperties,
    getProperties,
    getProperty,
    getPropertyPopup,
    deleteProperty,
    shelveProperties,
    unshelveProperties,
    selectProperties,
    deselectProperties,
    loadTenProperties,
    getSelectablePropertyCount,
    getConstants,
    newOrEditPlan,
    updatePlan,
    getPlan,
    getFullPlan,
    getOpportunityPortfolio,
    getPropertyRankingSettings,
    updatePropertyRankingSettings,
    updatePropertyCost,
    updateProperty,
    createProperty,
    getMarkerData,
    checkUniqueBmpName,
    checkpropertyIdentifierUnique,
    validateUpdateProperty,
    getOtherProperties,
    downloadPropertyFile,
  };
};

module.exports = EsgApiCalls();

const Network = require("../network");
const Session = require("../login/session");
const Tree = require("../tree");
const UrlRoutes = require("../routes/urlRoutes");
const Downloader = require("../files/downloader");
