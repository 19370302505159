"use strict";

const DataImportPreview = function () {
  var $mount;

  // state variables
  var csvFile;
  var fieldMapping = {};
  var data = [];
  var fieldObjs = [];
  var validationResult = {};
  var importEnabled;
  var failureTypeMessages;
  var layer;
  var list;

  var toggleImportEnabledHandler = function () {
    throw "toggleImportEnabledHandler must be configured before it can be called.";
  };

  var init = function (layerToPreview, $renderMount, toggleImportEnabledFn) {
    $mount = $renderMount;
    toggleImportEnabledHandler = toggleImportEnabledFn;
    layer = layerToPreview;
  };

  var loadPreviewListeners = function () {
    $mount.off("change", ".field-mapping-select", remapField);
    $mount.on("change", ".field-mapping-select", remapField);
  };

  var remapField = function () {
    var selectedFieldName = $(this).val();
    var origFieldName = $(this).data("mapFrom");
    fieldMapping[origFieldName] = selectedFieldName;

    fieldObjs = ImportFields.getFieldObjs(
      fieldObjs.map((fieldObj) => fieldObj.importFieldName),
      fieldMapping,
      layer,
    );

    const layerImportType = LayerSelection.getLayerImportType(layer);
    if (layerImportType === "csv") {
      validateCsv(csvFile, fieldMapping, layer);
    } else {
      validateUrlData(data, fieldMapping, layer);
    }
  };

  var getProps = function () {
    return {
      fieldFailures: fillFieldFailures(
        validationResult.fieldFailures,
        fieldObjs.map((fieldObj) => fieldObj.importFieldName),
        data,
      ),
    };
  };

  var fillFieldFailures = function (sparseFieldFailures, fieldNames, csvData) {
    const fieldFailures = [];
    csvData.forEach((_, rowIndex) => {
      fieldFailures.push({});
      fieldNames.forEach((fieldName) => {
        if (
          sparseFieldFailures &&
          sparseFieldFailures[rowIndex] &&
          sparseFieldFailures[rowIndex][fieldName]
        ) {
          const failureObj = sparseFieldFailures[rowIndex][fieldName];
          fieldFailures[rowIndex][fieldName] = {
            severity: failureObj["severity"],
            failureNames: failureObj["failureNames"],
          };
        } else {
          fieldFailures[rowIndex][fieldName] = {
            severity: "none",
            failureNames: [],
          };
        }
      });
    });
    return fieldFailures;
  };

  var render = function (layer) {
    const props = {
      fieldFailures: getProps().fieldFailures,
      failureTypeMessages: failureTypeMessages,
      dataSource: csvFile ? csvFile.name : "ArcGis Online",
      validation: validationResult,
      dataLength: data.length,
      fieldObjs: fieldObjs,
      inArray: function (needle, haystack) {
        return haystack.indexOf(needle) !== -1;
      },
    };
    const html = nunjucks.render("dataImport/preview.njk", props);

    $mount.html(html);
    _renderRows(props, data);

    toggleImportEnabledHandler(importEnabled);

    $mount.popover({
      content: function () {
        const takenFieldNames = getFieldNamesWithMapping();
        const validFields = ImportFields.getFieldNames(layer).filter((validField) => {
          return !takenFieldNames.includes(validField);
        });
        const validFieldObjs = ImportFields.getFieldObjs(validFields, {}, layer);
        // Bootstrap 3.4 requires a { sanitize: false } param to render HTML in
        // the popover. Be sure to add this if an upgrade breaks the import popover.
        return nunjucks.render("dataImport/fieldMappingPopover.njk", {
          from: $(this).data("field"),
          options: validFieldObjs.sort((a, b) => a.displayName.localeCompare(b.displayName)),
        });
      },
      sanitize: false,
      placement: "bottom",
      html: true,
      trigger: "click",
      selector: ".field-mapping",
      container: $mount,
    });
  };

  var _renderRows = function (props, data) {
    list?.destroy();
    list = null;

    const getRowCallback = function (rowIndex) {
      props.record = data[rowIndex];
      props.rowIdx = rowIndex;

      return nunjucks.render("dataImport/previewRow.njk", props);
    };

    const LazyList = require("../general/lazyList");
    list = new LazyList({
      rowContainer: $mount.find("#previewContentArea")[0],
      scrollContainer: $mount.find("#previewScrollArea")[0],
      getRowCallback: getRowCallback,
      totalRows: data.length,
    });
  };

  var loadInitialImportPreview = async function (
    csvData,
    inputFieldNames,
    csvFileToPreview,
    layer,
  ) {
    csvFile = csvFileToPreview;
    data = csvData;
    fieldObjs = ImportFields.getFieldObjs(inputFieldNames, {}, layer);
    applyDefaultsToFieldMapping(layer, inputFieldNames);
    fieldObjs = ImportFields.getFieldObjs(inputFieldNames, fieldMapping, layer);
    return validateCsv(csvFile, fieldMapping, layer);
  };

  var loadUrlImportPreview = function (urlData, inputFieldNames) {
    csvFile = undefined;
    data = urlData;
    fieldMapping = {};
    fieldObjs = ImportFields.getFieldObjs(inputFieldNames, {}, layer);
    applyDefaultsToFieldMapping(layer, inputFieldNames);
    fieldObjs = ImportFields.getFieldObjs(inputFieldNames, fieldMapping, layer);
    validateUrlData(urlData, fieldMapping, layer);
  };

  var validateUrlData = async function (data, fieldMapping, layer) {
    const validation = await ApiCalls.validateUrlDataForImport(
      JSON.stringify(data),
      fieldMapping,
      layer,
    );
    validationResult = validation;
    importEnabled = validation.isImportAcceptable;
    render(layer);
  };

  var applyDefaultsToFieldMapping = function (layer, importFieldNames) {
    const allDefaultFieldMappings = {
      outfalls: {
        drainage_area: "drainageAreaAcres",
      },
      sbmps: {
        installdat: "bmpCreation",
        confined_space: "systemAccess",
      },
      catchBasins: {
        confined_space: "systemAccess",
      },
      "bmp-observations": {
        date: "observationDate",
      },
      projects: {
        actualStartDate: "projectStartDate",
        actualEndDate: "projectEndDate",
        deliveryCost: "projectDeliveryCost",
        lat: "latitude",
        long: "longitude",
        discretionaryApprovalDate: "discretionaryApprovalLocalDate",
        closeoutInspectionDate: "closeoutInspectionLocalDate",
        areaManual: "manualArea",
        permitId: "wasteDischargerId",
        proximityTo303d: "proximityTo303dReceivingWater",
        amount: "inLieuFeeAmount",
      },
      facilities: {
        lat: "latitude",
        long: "longitude",
        facilityResponsibleParty: "responsiblePartyManagement",
        registeredIgp: "isIndustrialGeneralPermit",
        igpNumber: "industrialGeneralPermitNumber",
        permitId: "wasteDischargerIdentifier",
        dateOpened: "openedDate",
        dateClosed: "closedDate",
        inspectionFrequency: "inspectionFrequencyMonths",
      },
      manholes: {
        assetId: "manholeName",
        structureId: "structureIdentifier",
        length: "manholeLength",
        width: "manholeWidth",
        diameter: "manholeDiameter",
        responsiblePartyDelivery: "implementerType",
        consequenceOfFailure: "cofIndividualOverrideText",
        zip: "zipCode",
      },
    };
    const defaultFieldMappingForLayer = new CaseInsensitiveMap(allDefaultFieldMappings[layer]);
    ImportFields.getFieldNames(layer).forEach((fieldName) => {
      if (!defaultFieldMappingForLayer.has(fieldName)) {
        defaultFieldMappingForLayer.set(fieldName, fieldName);
      }
    });

    importFieldNames.forEach((importFieldName) => {
      if (defaultFieldMappingForLayer.has(importFieldName)) {
        fieldMapping[importFieldName] = defaultFieldMappingForLayer.get(importFieldName);
      }
    });
  };

  var validateCsv = function (csvFile, fieldMapping, dataType) {
    return ApiCalls.validateCsvForImport(csvFile, fieldMapping, dataType)
      .then((validation) => {
        validationResult = validation;
        importEnabled = validation.isImportAcceptable;
        if (validation.fileErrors) {
          CsvUpload.showErrorMessages(validation.fileErrors);
        } else {
          render(layer);
        }
      })
      .catch((err) => {
        CsvUpload.showErrorMessages([
          `There was a server error while processing your import, please try again later or contact us at <a href="mailto:customersuccess@2ndnaturewater.com">customersuccess@2ndnaturewater.com</a>.`,
        ]);
        throw err;
      });
  };

  var getFieldNamesWithMapping = function () {
    return fieldObjs.map((fieldObj) => fieldObj.finalFieldName || fieldObj.importFieldName);
  };

  var getFieldMapping = function () {
    return fieldMapping;
  };

  var resetImportPreview = function () {
    $mount.empty();
    init(layer, $mount, toggleImportEnabledHandler);
    loadPreviewListeners();
  };

  return {
    init,
    loadPreviewListeners,
    loadInitialImportPreview,
    loadUrlImportPreview,
    getFieldMapping,
    resetImportPreview,
  };
};

module.exports = DataImportPreview();

const ApiCalls = require("../apiCalls");
const CaseInsensitiveMap = require("../caseInsensitiveMap");
const ImportFields = require("./importFields");
const CsvUpload = require("./csvUpload");
const LayerSelection = require("./layerSelection");
