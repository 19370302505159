"use strict";

const DisplayOptions = function () {
  var displayOptions = $(".display-options");
  var $mainDisplay = $("#main-legend.display-options");
  var $modalDisplay = $("#modal-legend.display-options");

  var loadLegendListeners = function () {
    displayOptions = displayOptions.length ? displayOptions : $(".display-options");
    $mainDisplay = $mainDisplay.length ? $mainDisplay : $("#main-legend.display-options");
    $modalDisplay = $modalDisplay.length ? $modalDisplay : $("#modal-legend.display-options");

    turnOffOldListeners();

    displayOptions.on("change", "input[type=checkbox]", function (e) {
      legendCheckboxChange(this);
    });

    displayOptions.on("click", "input[type=radio]", function (e) {
      legendRadioChange(this);
    });

    displayOptions.on("click", ".basemap-dropdown-menu a", function () {
      basemapDropdownClick(this);
    });

    $mainDisplay.on("click", ".legend-button-close", function () {
      toggleLegendDisplay(false);
    });

    $mainDisplay.on("click", ".legend-button-legend", function () {
      toggleLegendDisplay(true);
    });

    $mainDisplay.on("click", ".legend-header-click", function () {
      toggleLegendArrow($(this));
    });

    $modalDisplay.on("click", ".legend-button-close", function () {
      toggleModalLegendDisplay(false);
    });

    $modalDisplay.on("click", ".legend-button-legend", function () {
      toggleModalLegendDisplay(true);
    });

    $modalDisplay.on("click", ".legend-header-click", function () {
      toggleLegendArrow($(this));
    });

    Tree.select("legendShowing").on("update", function () {
      var legendShowing = Tree.get("legendShowing");
      if (legendShowing) {
        showLegend();
      } else {
        hideLegend();
      }
    });

    Tree.select("modalLegendShowing").on("update", function () {
      var legendShowing = Tree.get("modalLegendShowing");
      if (legendShowing) {
        showModalLegend();
      } else {
        hideModalLegend();
      }
    });
  };

  var turnOffOldListeners = function () {
    displayOptions.off();
    $("#inventory-modal .legend-header-click").unbind("click");
    $("#mapContent .legend-header-click").unbind("click");
  };

  var basemapDropdownClick = function (that) {
    var mapId = $(that).closest(".floating-legend-group").data("mapId");

    BasemapFunctions.setBasemap($(that).data("style"), mapId);
  };

  var legendCheckboxChange = function (that) {
    var mapId = $(that).closest(".floating-legend-group").data("mapId");

    if (that.name === "cluster") {
      Clustering.setState($(that).data("layer"), that.checked);
    } else {
      Actions.toggleLayer(that.value, that.checked, mapId);
      toggleLegend(that.value, that.checked, mapId);
    }

    if (Tree.get("dataView") === "asset-management") {
      AssetManagementSummaryTableController.renderSummaryTable();
    }
  };

  var legendRadioChange = function (that) {
    if ($(that).hasClass("asset-radio-layer")) {
      AssetManagementRadioModeToggleController.handleRadioClick(that);
    }
    PostConstructionProjectGeoServerLayer.removeLayerOnRadioClick(that);
    if ($(that).attr("name") === "radio_condition_phase_toggle") {
      const constructionLayer = Tree.get(["layers", "constructionProjectG2", "isEnabled"])
        ? "constructionProjectG2"
        : "null";
      closeAnyOpenedPopups(constructionLayer);
      Tree.set(["filters", "conditionPhaseToggle"], that.value);
      return;
    }
    const mapId = $(that).closest(".floating-legend-group").data("mapId");
    const isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);
    const alreadyChecked = Tree.get(["layers", that.value, isEnabledPath]);
    const $container = $(that).closest(".layers-div");
    if (alreadyChecked) {
      closeAnyOpenedPopups($(that).attr("value"));
      handleLayerDisplay(that.value, false, mapId);
    } else {
      removeOtherRadioButtons($container, that.value, mapId);
      handleLayerDisplay(that.value, true, mapId);
    }
  };

  var closeAnyOpenedPopups = function (radioLayerOption) {
    const listOfG2Layers = [
      "muniCatchBasin",
      "muniOutfalls",
      "muniBmp",
      "lidBmp",
      "muniManhole",
      "muniLiftStations",
      "muniCleanout",
      "muniOpenChannel",
      "muniPipes",
      "muniCulvert",
      "constructionProjectG2",
    ];
    const popupCloseButn = $(".leaflet-popup-close-button");
    if (radioLayerOption && popupCloseButn?.length && listOfG2Layers.includes(radioLayerOption)) {
      popupCloseButn[0].click();
    }
  };

  var removeOtherRadioButtons = function ($container, excludeValue, mapId) {
    const $otherRadios = $container.find(
      `[type="radio"]:not([value="${excludeValue}"], [name="radio_condition_phase_toggle"])`,
    );
    const layers = [];

    for (const radio of $otherRadios) {
      layers.push(radio.value);
    }

    removeRadioButtonLayers(mapId, layers);
  };

  var toggleLegendArrow = function (header) {
    //@TODO clean
    // thisDrain.find(".expand-arrow").removeClass("open");
    if (header.find(".expand-arrow").hasClass("open")) {
      if (!$("#inventory-modal").is(":visible")) {
        toggleLegendDisplay(false);
      } else {
        toggleModalLegendDisplay(false);
      }
      header.find(".expand-arrow").removeClass("open");
      header
        .closest(".map-layers")
        .find(".layers-body")
        .slideUp(400, function () {
          //don't change main map if toggling modal map
          if (!$("#inventory-modal").is(":visible")) {
            $("#mapContent .legend-button-legend").hide();
          } else {
            $("#inventory-modal .legend-button-legend").hide();
          }
        });
    } else {
      $(".legend-button-legend").show();
      $(".legend-button-close").hide();
      header.find(".expand-arrow").addClass("open");
      header.closest(".map-layers").find(".layers-body").slideDown(400);
    }
  };

  var handleLayerDisplay = function (layer, isEnabled, mapId) {
    const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
    const hasAssociatedG2Layer =
      DataViewFunctions.getCurrentDataViewProperty("hasAssociatedG2Layer");

    Actions.toggleLayer(layer, isEnabled, mapId);
    toggleLegend(layer, isEnabled, mapId);
    toggleLegendLayerCheckbox(layer, isEnabled, mapId);

    var $displayOptions = mapId === "modal" ? $modalDisplay : $mainDisplay;
    $displayOptions = $displayOptions.find("input[value='" + layer + "']");
    $displayOptions.prop("checked", isEnabled);

    if ($displayOptions.length > 1) {
      console.warn("Multiple inputs with the same value in the same legend is not supported.");
    }

    if (Clustering.enabledForLayer(layer) && mapId !== "modal") {
      if (isGisDataView || hasAssociatedG2Layer) {
        Clustering.setSuperClusterEnabledCheckbox(layer, isEnabled);
      } else {
        Clustering.setEnabledCheckbox(layer, isEnabled);
      }
    }
  };

  var showLegend = function () {
    MapFunctions.setMapHeight();
    $("#mapContent .map-legend").show();
    $("#main-legend").toggleClass("open", true);
    $("#mapContent .legend-button-close").show();
    $("#mapContent .legend-button-legend").hide();
  };

  var showModalLegend = function () {
    $("#inventory-modal .map-legend").show();
    $("#inventory-modal .legend-button-close").show();
    $("#inventory-modal .legend-button-legend").hide();
  };

  var hideLegend = function () {
    $("#mapContent .map-legend").hide();
    $("#main-legend").toggleClass("open", false);
    $("#mapContent .legend-button-close").hide();
    $("#mapContent .legend-button-legend").show();
  };

  var hideModalLegend = function () {
    $("#inventory-modal .map-legend").hide();
    $("#inventory-modal .legend-button-close").hide();
    $("#inventory-modal .legend-button-legend").show();
  };

  var removeRadioButtonLayers = function (mapId, layerNames) {
    layerNames.forEach(function (layer) {
      handleLayerDisplay(layer, false, mapId);
    });
  };

  var unselectRadioButtons = function (mapId, ...radioGroups) {
    radioGroups.forEach(function (radioGroup) {
      var selector = mapId ? `.${radioGroup}-radio-layers-${mapId}` : `.${radioGroup}-radio-layers`;
      $(selector).find("input[type='radio']").prop("checked", false);
    });
  };

  var toggleLegend = function (layerName, isEnabled, mapId) {
    const $container = mapId === "modal" ? $("#modal-legend") : $("#main-legend");

    const layerConfig = LayerFunctions.getLayerConfig(layerName);
    if (!layerConfig || !layerConfig.legendClass) {
      console.info(`No legend associated with layer ${layerName}.`);
      return null;
    }

    for (const legendClass of getLegendClasses(layerConfig)) {
      const $legendDiv = _getLegendDivByClass($container, legendClass);
      _toggleLegendLayer($legendDiv, layerName, layerConfig, isEnabled);
    }
  };

  var _toggleLegendLayer = function ($legendDiv, layerName, layerConfig, isEnabled) {
    isEnabled = updateLegendLayersEnabled($legendDiv, layerName, isEnabled).size !== 0;

    if (layerConfig.legendType === "invisible") {
      $legendDiv.toggleClass("invisible", !isEnabled);
    } else {
      $legendDiv.toggle(isEnabled);
    }
  };

  var updateLegendLayersEnabled = function ($legendDiv, layerName, isEnabled) {
    let layersEnabled = $legendDiv.data("layersEnabled");

    if (!layersEnabled) {
      layersEnabled = new Set();
      $legendDiv.data("layersEnabled", layersEnabled);
    }

    if (isEnabled) {
      layersEnabled.add(layerName);
    } else {
      layersEnabled.delete(layerName);
    }

    return layersEnabled;
  };

  var _getLegendDivByClass = function ($legendDiv, legendClass) {
    const conditionPhaseToggle = Tree.get(["filters", "conditionPhaseToggle"]) ?? "condition";

    if ($legendDiv.find(`.${legendClass}`).hasClass(conditionPhaseToggle)) {
      return $legendDiv.find(`.${legendClass}.${conditionPhaseToggle}`);
    } else {
      return $legendDiv.find(`.${legendClass}`);
    }
  };

  var toggleLegendLayerCheckbox = function (layerName, isEnabled, mapId) {
    var $legendDiv = mapId === "modal" ? $("#modal-legend") : $("#main-legend");

    const layerConfig = LayerFunctions.getLayerConfig(layerName);
    if (!layerConfig || !layerConfig.legendClass) {
      console.info(`No legend associated with layer ${layerName}.`);
      return null;
    }

    $legendDiv = $legendDiv.find(`[value="${layerName}"]`);
    $legendDiv.prop("checked", isEnabled);
  };

  var resetDisplayOptions = function () {
    toggleLegendDisplay(false);
    toggleModalLegendDisplay(false);
    displayOptions = $(".display-options");
    $mainDisplay = $("#main-legend.display-options");
    $modalDisplay = $("#modal-legend.display-options");
    loadLegendListeners();
    disableLayerControlsForMmsdConstruction();
  };

  var toggleLegendDisplay = function (display) {
    Tree.set("legendShowing", display);
  };

  var toggleModalLegendDisplay = function (display) {
    Tree.set("modalLegendShowing", display);
  };

  /**
   * Temporary. Will go away once these layers work for progeny.
   */
  var disableLayerControlsForMmsdConstruction = function () {
    if (Session.isMmsd() || Session.isCwp()) {
      displayOptions.find(".layers-checkbox-div input:not([value=bounds])").prop("disabled", true);
    }
  };

  var handleLegendDisplayByConditionPhaseToggle = function (
    layerName,
    conditionPhaseToggle,
    areaLayerName = null,
  ) {
    toggleLegendByConditionPhaseToggle(layerName, conditionPhaseToggle);
    if (areaLayerName) {
      toggleLegendByConditionPhaseToggle(areaLayerName, conditionPhaseToggle);
    }
  };

  var toggleLegendByConditionPhaseToggle = function (layerName, conditionPhaseToggle) {
    var $legendDiv = $("#main-legend");

    if (Tree.get("layers", layerName, "isEnabled")) {
      const layerConfig = LayerFunctions.getLayerConfig(layerName);

      for (const legendClass of getLegendClasses(layerConfig)) {
        $legendDiv.find(`.${legendClass}.phase`).toggle(conditionPhaseToggle === "phase");
        $legendDiv.find(`.${legendClass}.condition`).toggle(conditionPhaseToggle === "condition");
      }
    }
  };

  var getLegendClasses = function (layerConfig) {
    const legendClass = layerConfig?.legendClass;

    if (!legendClass) {
      return [];
    }

    return Array.isArray(legendClass) ? legendClass : [legendClass];
  };

  var handleConditionPhaseToggleDisable = function (layerName, layerIsEnabled) {
    Misc.toggleDisabled(
      $(`input[name="radio_condition_phase_toggle"][data-layer="${layerName}"]`),
      !layerIsEnabled,
    );
  };

  var validateConditionPhaseToggleRadioButtons = function (conditionPhaseToggle) {
    $(`input[name="radio_condition_phase_toggle"][value="${conditionPhaseToggle}"]`).prop(
      "checked",
      true,
    );
  };

  return {
    loadLegendListeners,
    toggleLegend,
    removeRadioButtonLayers,
    handleLayerDisplay,
    unselectRadioButtons,
    resetDisplayOptions,
    removeOtherRadioButtons,
    legendRadioChange,
    disableLayerControlsForMmsdConstruction,
    handleLegendDisplayByConditionPhaseToggle,
    handleConditionPhaseToggleDisable,
    validateConditionPhaseToggleRadioButtons,
    _getLegendDivByClass,
    _toggleLegendLayer,
  };
};

module.exports = DisplayOptions();

const Actions = require("../actions");
const BasemapFunctions = require("./basemapFunctions");
const Tree = require("../tree");
const Clustering = require("./clustering");
const LayerFunctions = require("../layerFunctions");
const MapFunctions = require("./mapFunctions");
const Misc = require("../misc");
const Session = require("../login/session");
const AssetManagementRadioModeToggleController = require("../assetManagement/assetManagementRadioModeToggleController");
const AssetManagementSummaryTableController = require("../assetManagement/assetManagementSummaryTableController");
const DataViewFunctions = require("../dataViewFunctions");
const PostConstructionProjectGeoServerLayer = require("../constructionG2/postConstructionProjectGeoServerLayer");
