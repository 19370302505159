"use strict";

const TabbedModal = function () {
  let $tabbedModal;
  let activeTab;
  let formKey;
  let saveText;
  const inited = false;
  let loadedTabs = [];
  let toggleSavingButtonText = true;
  let prevUrlOnClose = false;

  var loadTabbedModalListeners = function () {
    $tabbedModal.on("click", ".save-button", saveButtonClick);
    $tabbedModal.on("click", ".cancel-button", cancelButtonClick);
    $tabbedModal.on("click", ".tab", navigateTabClick);
    $tabbedModal.on("keypress", disableImplicitSubmission);
  };

  var unloadTabbedModalListeners = function () {
    $tabbedModal.off("click", ".save-button");
    $tabbedModal.off("click", ".cancel-button");
    $tabbedModal.off("click", ".tab");
    $tabbedModal.off("keypress", disableImplicitSubmission);
  };

  var loadExtraButtonListeners = function (extraButtons) {
    for (const i in extraButtons) {
      const button = extraButtons[i];

      if (button.callback) {
        $tabbedModal.find(`.modal-footer button[data-index="${i}"]`).on("click", button.callback);
      }
    }
  };

  var loadTabListeners = function (props) {
    loadedTabs.forEach((tab) => {
      tab.loadListeners(props);
    });
  };

  var unloadTabListeners = function () {
    loadedTabs.forEach((tab) => {
      tab.unloadListeners();
    });
  };

  var init = function (newFormKey) {
    if (inited) return;
    formKey = newFormKey;
    $tabbedModal = $("#assessment-modal");
    loadTabbedModalListeners();
  };

  var showTabbedModal = function (
    newFormKey,
    props,
    tabs,
    htmlPath,
    {
      readOnly = false,
      activeTab = undefined,
      saveButtonText = "Save",
      cancelButtonText = "Cancel",
      extraButtons = [], // { text: "Button Text", type: "primary", callback: () => {}, class: "button-class" }
    } = {},
  ) {
    init(newFormKey);

    loadedTabs = tabs;
    saveText = saveButtonText;

    const html = nunjucks.render("modals/tabbedModal/tabbedModal.njk", {
      tabs: getTabsProps(tabs, htmlPath),
      props: props,
      saveButtonText,
      cancelButtonText,
      extraButtons,
    });
    $tabbedModal.html(html);
    _setActiveTab(activeTab);
    loadExtraButtonListeners(extraButtons);
    loadTabListeners(props);
    handleModalReadOnly(readOnly);
    show(false);
    return $tabbedModal;
  };

  var handleModalReadOnly = function (readOnly = false) {
    if (readOnly) {
      Misc.toggleDisabledInputs($tabbedModal.find(".modal-body"), readOnly);
      Misc.toggleDisabledInputs($tabbedModal.find(".modal-footer :not(.cancel-button)"), readOnly);
    }
  };

  var getTabsProps = function (tabs, htmlPath) {
    return tabs.map((tab) => ({
      tabStringKey: tab.getStringKey(),
      tabHeader: tab.getTabHeader(),
      tabPath: `${htmlPath}/${Misc.kebabToCamel(tab.getStringKey())}.njk`,
    }));
  };

  var setHeaderText = function (newText) {
    $tabbedModal.find(".modal-title").html(newText);
  };

  var navigateTabClick = function () {
    _setActiveTab($(this).attr("data-tab"));
  };

  var _setActiveTab = function (tabToSetActive) {
    if (activeTab !== undefined) {
      $tabbedModal
        .find(`.nav-tabs [data-tab="${activeTab.getStringKey()}"]`)
        .parent("li")
        .removeClass("active");
      $tabbedModal.find(`.modal-dynamic-content #${activeTab.getStringKey()}`).hide();
    }

    if (tabToSetActive === undefined) {
      activeTab = loadedTabs[0];
    } else {
      activeTab = getLoadedTabFromStringKey(tabToSetActive);
    }

    $tabbedModal
      .find(`.nav-tabs [data-tab="${activeTab.getStringKey()}"]`)
      .parent("li")
      .addClass("active")
      .scrollTop(0);
    $tabbedModal.find(`.modal-dynamic-content #${activeTab.getStringKey()}`).show();

    if (activeTab.tabShownCallback) {
      activeTab.tabShownCallback();
    }

    toggleNavTabs(activeTab.getTabHeader() !== null);
    sendActiveTabScreenView();
  };

  var toggleNavTabs = function (shouldShow) {
    $tabbedModal.find(`.mini-tabs`).toggleClass("hidden", shouldShow === false);
  };

  var getLoadedTabFromStringKey = function (stringKey) {
    return loadedTabs.find((tab) => {
      return tab.getStringKey() === stringKey;
    });
  };

  var toggleTab = function (stringKey, visibility = true, { restrictionKey = "enabled" } = {}) {
    const $tab = $tabbedModal.find(`.nav-tabs [data-tab="${stringKey}"]`).parent("li");
    visibility = _updateVisibilityRestrictions($tab, visibility, restrictionKey);
    $tab.toggleClass("hidden", !visibility);
  };

  var _updateVisibilityRestrictions = function ($element, visibility, restrictionKey) {
    const restrictions = $element.data("visibilityRestrictions") ?? {};
    restrictions[restrictionKey] = visibility;
    visibility = !_anyObjectValueEquals(restrictions, false);
    $element.data("visibilityRestrictions", restrictions);
    return visibility;
  };

  var _anyObjectValueEquals = function (object, expectedValue) {
    for (const key in object) {
      if (object[key] === expectedValue) {
        return true;
      }
    }

    return false;
  };

  var toggleExclusivelyShowActiveTab = function (showActiveOnly = true) {
    toggleExclusivelyShowTabs(showActiveOnly, "showActiveOnly", [], true);
  };

  var toggleExclusivelyShowTabs = function (
    showOnly,
    restrictionKey,
    tabKeysToShow = [],
    forceShowActive = false,
  ) {
    for (const tab of loadedTabs) {
      toggleTab(
        tab.getStringKey(),
        !showOnly || _shouldShowTab(tab, tabKeysToShow, forceShowActive),
        {
          restrictionKey,
        },
      );
    }
  };

  var _shouldShowTab = function (tab, tabKeysToShow, forceShowActive) {
    const stringKey = tab.getStringKey();

    return (forceShowActive && tab === activeTab) || tabKeysToShow.includes(stringKey);
  };

  var setSaveHandler = function (newSaveHandler, toggle = true) {
    saveHandler = newSaveHandler;
    toggleSavingButtonText = toggle;
  };

  var setCancelHandler = function (newCancelHandler) {
    cancelHandler = newCancelHandler;
  };

  var setFormHasUpdatesHandler = function (newFormHasUpdatesHandler) {
    formHasUpdates = newFormHasUpdatesHandler;
  };

  var goToPrevUrlOnClose = function () {
    prevUrlOnClose = true;
  };

  var saveButtonClick = async function () {
    if (toggleSavingButtonText) {
      toggleSavingButton(true);
    }
    return Misc.assertPromise(saveHandler()).then((hideTabbedModal = true) => {
      if (hideTabbedModal) {
        hide();
      }
      return false;
    });
  };

  var cancelButtonClick = function () {
    cancelPrompt();
  };

  var cancelPrompt = function (closedCallback = () => {}, stayOpenCallback = () => {}) {
    if (formHasUpdates()) {
      MessageModal.showConfirmWarningModal(
        null,
        function () {
          cancelAndHide();
          closedCallback();
        },
        "Go Back",
        "Close Without Saving",
        CommonModalFunctions.getCloseWithoutSavingPrompt(),
        stayOpenCallback,
      );
    } else {
      cancelAndHide();
      closedCallback();
    }
  };

  var cancelAndHide = function () {
    cancelHandler();
    hide();
  };

  var toggleSavingButton = function (toggleSaving) {
    const savingText = toggleSaving ? "Saving..." : saveText;
    setSaveButtonText(savingText);
    disableSaveButton(toggleSaving);
  };

  var show = function (sendAnalytics = true) {
    $tabbedModal.modal("show");
    if (sendAnalytics) {
      sendActiveTabScreenView();
    }
  };

  var sendActiveTabScreenView = function () {
    Analytics.sendScreenView("modal", formKey, activeTab.getStringKey());
  };

  var hide = function (unbindListeners = true) {
    const UrlRoutes = require("../routes/urlRoutes");

    if (unbindListeners) {
      unloadTabbedModalListeners();
      unloadTabListeners();
      Form.clearForm(formKey);
      if (prevUrlOnClose) {
        prevUrlOnClose = false;
        UrlRoutes.goToPrevUrl();
        Analytics.sendScreenView();
      }
    }
    $tabbedModal.modal("hide");
  };

  var formHasUpdates = function () {
    throw new Error("Form has updates was not configured");
  };

  var getFormHasUpdates = function () {
    return formHasUpdates();
  };

  var saveHandler = function () {
    throw new Error("Save handler was not configured");
  };

  var cancelHandler = function () {
    throw new Error("Cancel handler was not configured");
  };

  var disableImplicitSubmission = function (e) {
    if (e.keyCode === 13 && e.target.nodeName !== "TEXTAREA") {
      e.preventDefault();
    }
  };

  var hideSaveButton = function (hide = true) {
    getSaveButton().toggleClass("hidden", hide);
  };

  var disableSaveButton = function (disable = true) {
    Misc.toggleDisabled(getSaveButton(), disable);
  };

  var setSaveButtonText = function (text = null) {
    if (text === null) {
      text = saveText;
    }

    getSaveButton().text(text);
  };

  var getSaveButton = function () {
    return $tabbedModal.find(".save-button");
  };

  return {
    showTabbedModal,
    setHeaderText,
    setSaveHandler,
    setCancelHandler,
    setFormHasUpdatesHandler,
    goToPrevUrlOnClose,
    getFormHasUpdates,
    toggleTab,
    toggleExclusivelyShowActiveTab,
    toggleExclusivelyShowTabs,
    toggleSavingButton,
    setSaveButtonText,
    hideSaveButton,
    disableSaveButton,
    hide,
    show,
    cancelPrompt,
    cancelAndHide,
    _setActiveTab,
  };
};

module.exports = TabbedModal();

const Analytics = require("./analytics");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const Form = require("./form");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
