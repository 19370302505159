"use strict";

const SelectInput = function () {
  var removeNoSelectionDefaultOnInput = function ($parent) {
    $parent.on("input", "select", function (e) {
      $(e.currentTarget)
        .find("option[value='no-selection']")
        .filter((elem) => {
          const canUnselect = !$(elem).hasClass("allow-unselect");
          return !canUnselect;
        })
        .remove();
    });
  };

  var unloadNoSelectionListener = function ($parent) {
    $parent.off("input", "select");
  };

  return {
    removeNoSelectionDefaultOnInput,
    unloadNoSelectionListener,
  };
};

module.exports = SelectInput();
