"use strict";

const NavToggleController = function () {
  var load = function (user) {
    NavToggle.render();
    NavToggle.loadDomListeners();
    loadTreeListeners();
    handleNavToggleDisplayByTab();

    if (!Offline.onLine()) {
      NavToggle.showPanel("list");
    }
  };

  var loadTreeListeners = function () {
    Tree.watch({ activeTab: ["activeTab"], tool: ["tool"], dataView: ["dataView"] }).on(
      "update",
      (e) => {
        const target = e.target.get();
        handleNavToggleDisplayByTab(target.tool, target.activeTab, target.dataView);
        _hideAndShowActionButtons(target.activeTab);
      },
    );
  };

  var handleNavToggleDisplayByTab = function (
    tool = Tree.get("tool"),
    activeTab = Tree.get("activeTab"),
    dataView = Tree.get("dataView"),
  ) {
    if (PageFunctions.getTabProperty(activeTab, "noPanels")) {
      NavToggle.hideToggle();
    } else {
      NavToggle.showToggle();
      NavToggle.toggleListButtonDisabled(
        !ToolFunctions.toolListIsEnabledForTab(activeTab, dataView, tool),
      );
      NavToggle.toggleMapButtonDisabled(
        !ToolFunctions.toolMapIsEnabledForTab(activeTab, dataView, tool),
      );
      if (
        Tree.get("navToggleSelection") === "list" &&
        !ToolFunctions.toolListIsEnabledForTab(activeTab, dataView, tool)
      ) {
        NavToggle.showPanel("map");
      } else if (
        Tree.get("navToggleSelection") === "map" &&
        !ToolFunctions.toolMapIsEnabledForTab(activeTab, dataView, tool)
      ) {
        NavToggle.showPanel("list");
      }

      const showDashboardIcon = activeTab === "insight";
      $(".nav-toggle-button-contents.dashboard").toggleClass("default-hidden", !showDashboardIcon);
      $(".nav-toggle-button-contents.list").toggleClass("default-hidden", showDashboardIcon);
    }
  };

  var _hideAndShowActionButtons = function (activeTab) {
    const showActionButtonsTabs =
      DataViewFunctions.getCurrentDataViewProperty("showActionButtonsTabs");
    if (!showActionButtonsTabs) return;

    $(".action-button-div").toggleClass("hidden", !showActionButtonsTabs.includes(activeTab));
  };

  return {
    load,
    handleNavToggleDisplayByTab,
    _hideAndShowActionButtons,
  };
};

module.exports = NavToggleController();

const NavToggle = require("./navToggle");
const Tree = require("../tree");
const ToolFunctions = require("../toolFunctions");
const Offline = require("../offline/offline");
const DataViewFunctions = require("../dataViewFunctions");
const PageFunctions = require("../pageFunctions");
