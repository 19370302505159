"use strict";

const assetManagementSummaryTableDataCache = function () {
  var getLayersArray = function (layers) {
    const {
      assetsBmpEsriDynamicMap,
      assetsCatchBasinEsriDynamicMap,
      assetsOutfallEsriDynamicMap,
      assetsManholeEsriDynamicMap,
      assetsCleanOutEsriDynamicMap,
      assetsLiftStationEsriDynamicMap,
      assetsPipeEsriDynamicMap,
      assetsOpenChannelEsriDynamicMap,
      assetsCulvertEsriDynamicMap,
    } = layers;

    const assetsArray = [
      { esriAsset: assetsBmpEsriDynamicMap },
      { esriAsset: assetsCatchBasinEsriDynamicMap },
      { esriAsset: assetsOutfallEsriDynamicMap },
      { esriAsset: assetsManholeEsriDynamicMap },
      { esriAsset: assetsCleanOutEsriDynamicMap },
      { esriAsset: assetsLiftStationEsriDynamicMap },
      { esriAsset: assetsPipeEsriDynamicMap },
      { esriAsset: assetsOpenChannelEsriDynamicMap },
      { esriAsset: assetsCulvertEsriDynamicMap },
    ];
    return assetsArray;
  };
  var getTableSummaryDataArray = function (layerArray, apiArray) {
    let assetDataArray = [
      { assetName: "Structural BMPs" },
      { assetName: "Catch Basins" },
      { assetName: "Outfalls" },
      { assetName: "Manholes" },
      { assetName: "Cleanouts" },
      { assetName: "Lift Stations" },
      { assetName: "Pipes" },
      { assetName: "Open Channels" },
      { assetName: "Culverts" },
    ];
    assetDataArray = mergeArrays(layerArray, assetDataArray);
    addPropsToDataArray(assetDataArray, apiArray);
    return assetDataArray;
  };
  var addPropsToDataArray = function (dataArray, apiArray) {
    dataArray.forEach((asset, index) => {
      if (apiArray) {
        asset.attributionPercentage = apiArray[index]["attributionPercentage"];
        asset.totalCount = apiArray[index]["total"];
        asset.complete = apiArray[index]["totalAttributed"];
        asset.incomplete =
          parseInt(apiArray[index]["total"]) - parseInt(apiArray[index]["totalAttributed"]);
        asset.linearFt = apiArray[index]["totalLengthFt"];
        asset.veryLowPercentage = apiArray[index]["veryLowPercentage"];
        asset.lowPercentage = apiArray[index]["lowPercentage"];
        asset.moderatePercentage = apiArray[index]["moderatePercentage"];
        asset.highPercentage = apiArray[index]["highPercentage"];
        asset.veryHighPercentage = apiArray[index]["veryHighPercentage"];
        asset.unknownPercentage = apiArray[index]["unknownPercentage"];
        asset.veryHighCofPercentage = apiArray[index]["veryHighCofPercentage"];
        asset.highCofPercentage = apiArray[index]["highCofPercentage"];
        asset.moderateCofPercentage = apiArray[index]["moderateCofPercentage"];
        asset.lowCofPercentage = apiArray[index]["lowCofPercentage"];
        asset.veryLowCofPercentage = apiArray[index]["veryLowCofPercentage"];
        asset.unknownCofPercentage = apiArray[index]["unknownCofPercentage"];
        asset.veryHighCriticalityPercentage = apiArray[index]["veryHighCriticalityPercentage"];
        asset.highCriticalityPercentage = apiArray[index]["highCriticalityPercentage"];
        asset.moderateCriticalityPercentage = apiArray[index]["moderateCriticalityPercentage"];
        asset.lowCriticalityPercentage = apiArray[index]["lowCriticalityPercentage"];
        asset.veryLowCriticalityPercentage = apiArray[index]["veryLowCriticalityPercentage"];
        asset.unknownCriticalityPercentage = apiArray[index]["unknownCriticalityPercentage"];
      }
    });
  };

  var checkForNoLayerEnabled = function (layerArray) {
    let notEnabledCount = 0;
    layerArray.forEach((asset) => {
      if (asset.esriAsset === undefined || asset.esriAsset.isEnabled === false) {
        notEnabledCount += 1;
      }
      notEnabledCount + -1;
    });
    return notEnabledCount;
  };

  var mergeArrays = function (layerArray, dataArray) {
    dataArray.forEach((asset, index) => {
      asset.esriLayer = layerArray[index]["esriAsset"];
    });
    return dataArray;
  };
  return {
    getTableSummaryDataArray,
    checkForNoLayerEnabled,
    getLayersArray,
  };
};
module.exports = assetManagementSummaryTableDataCache();
