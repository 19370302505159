"use strict";

const BaseEnforcementController = require("../../enforcement/baseEnforcementController");

const FacilityEnforcementController = function () {
  const formKey = "facility-enforcement";
  const htmlPath = "modals/facilityEnforcement";
  const headerTemplate = "modals/facilityInspection/facilityInspectionHeader.njk";

  const getTabModules = () => {
    return {
      EnforcementAction: require("./enforcementAction"),
      EnforcementLetterManagement: require("./enforcementLetterManagement"),
      EnforcementHistory: require("./enforcementHistory"),
    };
  };

  const newEnforcementApiFunction = (facilityId) => ApiCalls.getNewFacilityEnforcement(facilityId);
  const getEnforcementApiFunction = (enforcementId) =>
    ApiCalls.getFacilityEnforcement(enforcementId);
  const createEnforcementApiFunction = (formData) => ApiCalls.issueFacilityEnforcement(formData);
  const updateEnforcementApiFunction = (formData) => ApiCalls.updateFacilityEnforcement(formData);

  const getAssetNameFunction = (data) => data.facility.facilityName;
  const getAssetIdFunction = (data) => {
    return { facilityId: data.facility.id };
  };

  const afterHideFunction = () => IndcomFacilityGeoServerLayer.invalidateLayerData();

  const base = BaseEnforcementController(
    formKey,
    htmlPath,
    headerTemplate,
    getTabModules,
    newEnforcementApiFunction,
    getEnforcementApiFunction,
    createEnforcementApiFunction,
    updateEnforcementApiFunction,
    getAssetNameFunction,
    getAssetIdFunction,
    { afterHideFunction },
  );

  return {
    ...base,
  };
};

module.exports = FacilityEnforcementController();

const IndcomFacilityGeoServerLayer = require("../indcomFacilityGeoServerLayer");
const ApiCalls = require("../../apiCalls");
