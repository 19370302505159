"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetGaugeChart = function (widgetConfig) {
  if (!widgetConfig.size) {
    widgetConfig.size = 2;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetGaugeChart.prototype = Object.create(InsightWidget.prototype);
InsightWidgetGaugeChart.prototype.constructor = InsightWidgetGaugeChart;

InsightWidgetGaugeChart.prototype.getHtml = function () {
  var title = this.widgetConfig.title;

  var html = nunjucks.render("insight/widgets/gaugeChart.njk", {
    title,
  });

  return html;
};

InsightWidgetGaugeChart.prototype.renderChart = function (insightData, exportModal = false) {
  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }
  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight;

  var margin = defaults.margin,
    width = containerWidth - margin.left - margin.right,
    height = containerHeight - margin.top - margin.bottom,
    radius = height - 30,
    innerRadius = radius * 0.75,
    dataArray = this.widgetConfig.getDataArray(insightData),
    legendData = this.widgetConfig.legendData,
    total,
    defaultCenterData = this.widgetConfig.getDefaultCenterData(insightData),
    svg,
    arrowImagePath = "/ram/shared/insight/gauge-chart-arrow.svg";

  if (dataArray.every((d) => d.value === 0)) {
    $chartContainer.append('<div class="no-chart-data">No data</div>');
    return;
  }
  drawChart();

  function drawChart() {
    sortData();
    expandData();
    createSvg();
    drawArcs();
    addArrows();
    addLabels();
    adjustChartLocation();
    addMinAndMaxLabels();
    addCenterReadout(defaultCenterData);
    appendLegend();
  }

  function createSvg() {
    svg = d3
      .select(chartContainerSelector)
      .append("svg")
      .style("font-size", radius * 0.1)
      .data([dataArray])
      .attr("width", containerWidth)
      .attr("height", containerHeight + margin.bottom)
      .append("svg:g")
      .attr("transform", "translate(" + containerWidth / 2 + "," + containerHeight + ")");
  }

  function sortData() {
    dataArray.forEach((d, i) => {
      d.i = i;
    });
    dataArray.sort((a, b) => a.value - b.value || a.i - b.i);
  }

  function expandData() {
    total = dataArray[dataArray.length - 1].value;
    dataArray.forEach((d, i) => {
      d.displayValue = InsightWidgetFunctions.formatNumber(d.value);
      d.arcValue = d.value - (dataArray[i - 1]?.value || 0);
      d.percentage = getPercentage(d.value, total);
      d.angle = getAngle(d.percentage);
    });
  }

  function drawArcs() {
    var arc = d3.arc().innerRadius(innerRadius).outerRadius(radius),
      pie = d3
        .pie()
        .startAngle(-90 * (Math.PI / 180))
        .endAngle(90 * (Math.PI / 180))
        .sort(null)
        .value(function (d) {
          return d.arcValue;
        }),
      arcs = svg
        .selectAll("g.slice")
        .data(pie)
        .enter()
        .append("svg:g")
        .attr("class", "slice")
        .attr("data-i", function (d, i) {
          return i;
        });
    arcs
      .append("svg:path")
      .attr("class", function (d) {
        return d.data.hover ? "slice-hover" : "";
      })
      .attr("data-label", function (d) {
        return legendData[d.data.key].label;
      })
      .attr("data-value", function (d) {
        return d.data.displayValue;
      })
      .attr("data-color", function (d) {
        return legendData[d.data.key].color;
      })
      .attr("fill", function (d, i) {
        return legendData[d.data.key].color;
      })
      .attr("stroke", function (d, i) {
        return legendData[d.data.key].color;
      })
      .attr("d", arc);
  }

  function addArrows() {
    const arrowSize = 10;
    const distanceFromArc = 10;
    svg
      .selectAll("g.arrow")
      .data(dataArray)
      .enter()
      .append("svg:image")
      .attr("class", "arrow")
      .attr("xlink:href", arrowImagePath)
      .attr("x", function () {
        return -arrowSize / 2;
      })
      .attr("y", function () {
        return -arrowSize / 2;
      })
      .attr("transform", function (d) {
        return translateOutsideArcByAngle(d.angle, distanceFromArc);
      })
      .attr("width", arrowSize)
      .attr("height", arrowSize);
  }

  function addLabels() {
    preventLabelCollision();
    svg
      .selectAll("g.label-group")
      .data(dataArray)
      .enter()
      .append("g")
      .attr("class", "label-group")
      .append("svg:text")
      .attr("class", "label")
      .style("font-size", "1em")
      .attr("transform", function (d) {
        return `translate(${d.x}, ${-d.y})`;
      })
      .attr("text-anchor", function (d) {
        switch (true) {
          case d.angle <= 0:
            return "end";
          case d.angle > 0:
            return "start";
          default:
            return "middle";
        }
      });
    svg
      .selectAll("text.label")
      .insert("svg:tspan")
      .attr("alignment-baseline", "middle")
      .attr("x", 0)
      .attr("y", 0)
      .text(function (d) {
        return d.arrowLabel;
      });
  }

  function adjustChartLocation() {
    if ($(chartContainerSelector).length === 0) return;

    let widgetBox = $(chartContainerSelector)[0].getBoundingClientRect(),
      labelBoxes = $(chartContainerSelector + " text.label")
        .map(function () {
          return $(this)[0].getBoundingClientRect();
        })
        .get(),
      leftmostLabel = Math.min(...labelBoxes.map((box) => box.left)),
      rightmostLabel = Math.max(...labelBoxes.map((box) => box.right)),
      adjustment = 0;
    if (rightmostLabel > widgetBox.right) {
      adjustment = widgetBox.right - rightmostLabel;
    } else if (leftmostLabel < widgetBox.left) {
      adjustment = widgetBox.left - leftmostLabel;
    }
    svg.attr("transform", `translate(${containerWidth / 2 + adjustment},${containerHeight})`);
  }

  function preventLabelCollision() {
    let distanceFromCenter = radius,
      labels = dataArray.map((d) => {
        const angleRad = degToRad(d.angle),
          x = distanceFromCenter * Math.sin(angleRad),
          y = distanceFromCenter * Math.cos(angleRad),
          offsetMax = radius * 0.1,
          absAngle = Math.abs(d.angle),
          offset = offsetMax * (1 - absAngle / 90);
        return {
          targetX: x,
          targetY: y,
          size: radius * 0.1 + offset,
        };
      });
    const centerCircle = {
      fx: 0,
      fy: 0,
      size: radius * 1.05,
    };
    labels = [...labels, centerCircle];

    const links = dataArray.map((d, i) => {
      return {
        source: i,
        target: labels.length - 1,
      };
    });

    const forceClamp = (max) => {
      let nodes;
      const force = () => {
        nodes.forEach((n) => {
          if (n.y < max) n.y = max;
        });
      };
      force.initialize = (_) => (nodes = _);
      return force;
    };

    const force = d3
      .forceSimulation()
      .nodes(labels)
      .force("link", d3.forceLink(links).distance(0).strength(1))
      .force(
        "collide",
        d3.forceCollide().radius((d) => d.size),
      )
      .force("y", d3.forceY((d) => d.targetY).strength(1))
      .force("x", d3.forceX((d) => d.targetX).strength(1))
      .force("clamp", forceClamp(0))
      .stop();
    for (let i = 0; i < 300; i++) force.tick();
    labels.pop(); // remove the circle;
    labels.sort((a, b) => a.x - b.x); // in case they jumped over each other
    dataArray.forEach((d, i) => {
      d.y = labels[i].y;
      d.x = labels[i].x;
    });
  }

  function addMinAndMaxLabels() {
    const minDisplay = 0,
      maxDisplay = Misc.formatLargeNumber(total, 1),
      middle = (radius - innerRadius) / 2 + innerRadius,
      down = radius * 0.15;
    svg
      .append("svg:text")
      .attr("fill", "black")
      .style("font-size", "1em")
      .attr("transform", `translate(${-middle}, ${down})`)
      .attr("text-anchor", "middle")
      .text(minDisplay);
    svg
      .append("svg:text")
      .attr("fill", "black")
      .style("font-size", "1em")
      .attr("transform", `translate(${middle}, ${down})`)
      .attr("text-anchor", "middle")
      .text(maxDisplay);
  }

  function addCenterReadout(data) {
    const { value, label, unit } = data;
    const displayValue = InsightWidgetFunctions.formatNumber(value);
    svg
      .append("svg:text")
      .attr("class", "center-text")
      .attr("fill", "black")
      .attr("text-anchor", "middle");
    svg
      .select("text.center-text")
      .insert("svg:tspan")
      .attr("class", "center-text-value")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", "-1.8em");
    svg
      .select("tspan.center-text-value")
      .insert("svg:tspan")
      .attr("class", "value")
      .attr("data-default-value", displayValue)
      .style("font-size", "1.7em")
      .text(displayValue);
    svg.select("tspan.center-text-value").insert("svg:tspan").style("font-size", "1.7em").text(" ");
    svg
      .select("tspan.center-text-value")
      .insert("svg:tspan")
      .style("font-size", "1.14em")
      .text(unit);
    svg
      .select("text.center-text")
      .insert("svg:tspan")
      .attr("class", "center-text-label")
      .attr("data-default-label", label)
      .attr("x", 0)
      .attr("y", 0)
      .style("font-size", "1.5em")
      .text(label);
  }

  function appendLegend() {
    const size = 10;
    const padding = 5;
    const gap = width / 2;
    var legendItem = d3
      .select(`${chartContainerSelector} svg`)
      .selectAll(".legend-item")
      .data(dataArray)
      .enter()
      .append("g")
      .attr("class", function (d) {
        const classNames = "legend-item";
        return classNames + (d.hover ? " slice-hover" : "");
      })
      .attr("pointer-events", "bounding-box")
      .attr("data-label", function (d) {
        return legendData[d.key].label;
      })
      .attr("data-value", function (d) {
        return d.displayValue;
      })
      .attr("data-color", function (d) {
        return legendData[d.key].color;
      })
      .attr("data-i", function (d, i) {
        return i;
      });

    legendItem
      .append("rect")
      .attr("x", (d, i) => {
        return (i % 2) * gap;
      })
      .attr("y", function (d, i) {
        return (i > 1 ? 1 : 0) * (size + padding);
      })
      .attr("width", size)
      .attr("height", size)
      .attr("stroke-width", 0)
      .attr("fill", function (d) {
        return legendData[d.key].color;
      });
    legendItem
      .append("text")
      .attr("alignment-baseline", "middle")
      .attr("font-size", "10px")
      .attr("x", (d, i) => {
        return (i % 2) * gap + size + padding;
      })
      .attr("y", function (d, i) {
        return (i > 1 ? 1 : 0) * (size + padding) + size / 2 + 1;
      })
      .text(function (d) {
        return legendData[d.key].label;
      });
  }

  function getPercentage(value, total) {
    return Math.round((parseFloat(value) / total) * 100);
  }

  function getAngle(percentage) {
    return (percentage * 180) / 100 - 90;
  }

  function degToRad(degrees) {
    return (degrees * Math.PI) / 180;
  }

  function translateOutsideArcByAngle(angle, distanceFromArc, rotate = true) {
    const distanceFromCenter = radius + distanceFromArc,
      angleRad = degToRad(angle),
      x = distanceFromCenter * Math.sin(angleRad),
      y = distanceFromCenter * Math.cos(angleRad);
    let transform = `translate(${x}, ${-y})`;
    if (rotate) {
      transform += ` rotate(${angle})`;
    }
    return transform;
  }
};

module.exports = InsightWidgetGaugeChart;

const d3 = require("d3-v6");
const Misc = require("../../misc");
const InsightWidgetFunctions = require("./insightWidgetFunctions");
