"use strict";

const MinMax = function () {
  const getData = function (all = false) {
    const data = {};
    $(".min-max").each(function () {
      const $minMax = $(this);
      const $minInput = $minMax.find('[name*="[min]"]');
      const $maxInput = $minMax.find('[name*="[max]"]');

      if ($minInput.length > 0 && $maxInput.length > 0) {
        const name = $minMax.find("input:first").attr("name").split("[")[0];

        data[name] = {
          min: all ? "" : $minInput.val().replaceAll(",", ""),
          max: all ? "" : $maxInput.val().replaceAll(",", ""),
        };
      }
    });
    return data;
  };

  const setData = function (data) {
    for (const name in data) {
      const values = data[name];
      $(`[name="${name}[max]"]`).val(values?.max);
      $(`[name="${name}[min]"]`).val(values?.min);
    }
  };

  const clear = function (key) {
    $(".min-max input[name^=" + key + "]").val("");
  };

  return {
    getData,
    setData,
    clear,
  };
};

module.exports = MinMax();
