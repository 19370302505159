"use strict";

require("core-js");
window.Buffer = require("buffer/").Buffer; // Required for the file-type package
const Login = require("./login/login");

const Main = function () {
  let userPromise;

  var initializeHome = async function () {
    beforeRun();
    NunjucksExtensions.initializeNunjucks();

    SingleSignOn.run(async function (data) {
      const isLoggedIn = !!data;

      if (isLoggedIn) {
        Session.initSession(data);
        await loadRequired();
        Login.checkAgreements(data);
      } else {
        Login.showLoginModal();
      }

      Analytics.sendScreenView([]);
      Login.initialize();
      Home.initialize();
      NavBar.initialize();

      // Service worker needs to be installed after initial page load
      InstallManager.installServiceWorker();
    });
  };

  var initializeRam = async function () {
    beforeRun();
    loadExtensions();

    BrowserWarning.init();
    Mobile.loadListeners();
    Geolocation.start();
    Offline.init();
    const MainMap = require("./mapFunctions/mainMap");
    MainMap.init();

    SingleSignOn.run(async function (data) {
      if (!data) {
        UrlRoutes.navigateToHomePage();
        return;
      }

      Session.initSession(data);

      await loadRequired();

      TreeInitialDataRam.initDynamicData();
      ToolFunctions.handleUrl();
      StatusBarOnlineStatus.init();
      await ResourceController.sync();
      Tree.commit(); //This will allow tool update listeners to execute first.
      DisplayOptions.disableLayerControlsForMmsdConstruction();
      UrlRoutes.resolve();

      // Service worker needs to be installed after initial page load
      InstallManager.installServiceWorker();
    });
  };

  var initializeEsg = async function () {
    beforeRun();
    loadExtensions();

    SingleSignOn.run(async function (data) {
      const isLoggedIn = !!data;

      if (isLoggedIn) {
        Session.initSession(data);
        await loadRequired();
      } else {
        Login.showLoginModal();
      }

      Login.initialize();
      Esg.initialize();
      NavBar.initializeEsg();
      UrlRoutes.resolve();
    });
  };

  var initializeSso = function () {
    SingleSignOn.run(function (data) {
      const isLoggedIn = !!data;
      if (isLoggedIn) {
        Session.initSession(data);
        SingleSignOn.ssoLogin();
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const relayState = urlParams.get("RelayState");

        if (Config.get().site === "2nform" && relayState?.toLowerCase().includes("rainsteward")) {
          const newUrl = new URL(window.location);
          newUrl.host = "rainsteward.com";
          window.location = newUrl.toString();
        } else {
          Login.showLoginModal({ backdrop: "static" });
        }
      }
      Login.initialize();
    });
  };

  var initializePortal = async function () {
    UrlRoutes.replaceFirstUrlSegmentWithLowercase();
    beforeRun();
    loadExtensions();

    if (!(await PortalController.authenticate())) {
      return;
    }

    UrlRoutes.resolve();
  };

  var initializeDebug = async function () {
    const OfflineExporter = require("./offline/offlineExporter");

    ResourceController.init(false);
    $("#download-debug").on("click", () =>
      OfflineExporter.exportAndDownloadOfflineData({
        onlyIncludeObjectStores: ["cachedDefaultStore", "cachedMetadataStore"],
      }),
    );
    $("#download-photos").on("click", () =>
      OfflineExporter.exportAndDownloadOfflineData({
        onlyIncludeObjectStores: ["cachedFileStore"],
      }),
    );
  };

  var loadExtensions = function () {
    JQueryExtensions.run();
    ArrayExtensions.run();
    BootstrapExtensions.run();
    SignaturePadExtensions.run();
    LeafletExtensions.loadLeafletExtensions();
    NunjucksExtensions.initializeNunjucks();
  };

  var beforeRun = function () {
    console.info(
      `%cBuild: %c${global.twoNformBuildVersion}`,
      "font-weight: bold;",
      `
                 border: 1px solid black;
                 border-radius: 4px;
                 background-color: #0b3e5e;
                 color: white;
                 padding: 4px 6px;
                 `,
    );

    // Not awaiting so we can load the page faster
    alertIfNotSupported();
    userPromise = UserResource.init();
    UrlRoutes.init();
    LoadingScreen.loadAjaxEventListeners();
  };

  var loadRequired = async function () {
    const data = await LoadingScreen.showWhilePromisePending(getToolSettingsData());

    GroupBasedMenuItems.saveSettings(data["menu"]);
    FormSettings.saveSettings(data["form"]);
    Progeny.saveProgeny(data["progeny"]);
    Session.saveUserRole(data["permissions"]);
    UserPermissions.saveUserPermissions(data["permissions"]);
    Tree.set("annualReportingSettings", data["currentAnnualReports"]);
    RoutineMaintenanceConfig.init(data["maintenanceTasks"]);
    PermitSettings.saveSettings(data["permit"]);
  };

  var getToolSettingsData = async function () {
    await userPromise;
    const userId = Session.getUser()["userId"];
    const loggedInAsDifferentUser = await UserResource.login(userId);

    const promise = Promise.all([
      ToolSettings.requestSettings(),
      ResourceController.init(loggedInAsDifferentUser),
    ]);
    return (await promise)[0];
  };

  var alertIfNotSupported = async function () {
    if (!BrowserSupportsFeature.checkRequired()) {
      window.alert(
        "This web browser is not supported. Please update your device and use the latest version of Google Chrome.",
      );
    } else if (!(await BrowserSupportsFeature.offlineImages())) {
      window.alert(
        "Private browsing/incognito mode is not supported. Please use a normal browser window instead.",
      );
    }
  };

  var initializeReport = async function () {
    const ReportLogin = require("./report/login/reportLogin");
    const LeafletExtensions = require("./report/mapFunctions/leafletExtensions");
    const MapTitle = require("./report/mapFunctions/mapTitle");
    const NunjucksFilters = require("./report/general/nunjucksFilters");
    const NunjucksFunctions = require("./general/nunjucksFunctions");
    const PageFunctions = require("./report/pageFunctions");
    const ToolNavTitle = require("./report/toolNavTitle");
    const MainMap = require("./report/mapFunctions/mainMap");

    beforeRun();
    LeafletExtensions.loadLeafletExtensions();
    MainMap.setUpListeners();

    SingleSignOn.run(async function (data) {
      if (!data) {
        UrlRoutes.navigateToHomePage();
        return;
      }

      ReportLogin.initializeUser(data);
      await loadRequired();
      await ReportLogin.afterRequired();

      const env = nunjucks.configure();
      NunjucksFilters.run(env);
      NunjucksFunctions.setupFunctions(env);

      MapTitle.loadListeners();
      $(".username").text(data.context.username);

      ToolNavTitle.set();
      PageFunctions.goToPage("submit");
      ReportLogin.toggleManageUsers(ToolSettings.getSetting("permissions", "can_manage_users"));
      ReportLogin.setEsftDownloadUrl();
      LoadingScreen.hide();
      MainMap._hideDataImportBasedOnRole();

      // Service worker needs to be installed after initial page load
      InstallManager.installServiceWorker();
    });
  };

  return {
    initializeRam,
    initializeHome,
    initializeEsg,
    initializeReport,
    ReportLogin: require("./report/login/login"),
    ReportManageUsers: require("./modals/manageUsers"),
    ReportUserSettingsFunctions: require("./report/settings/userSettingsFunctions"),
    initializeSso,
    initializePortal,
    initializeDebug,
    initializeResetPassword: Login.initializeResetPassword,
    commands: require("./consoleCommands"),
  };
};

module.exports = Main();

const SingleSignOn = require("./login/sso");
const Session = require("./login/session");
const GroupBasedMenuItems = require("./settings/groupBasedMenuItems");
const JQueryExtensions = require("./jqueryExtensions");
const ArrayExtensions = require("./arrayExtensions");
const BootstrapExtensions = require("./general/bootstrapExtensions");
const LeafletExtensions = require("./mapFunctions/leafletExtensions");
const NunjucksExtensions = require("./general/nunjucksExtensions");
const LoadingScreen = require("./general/loadingScreen");
const Progeny = require("./login/progeny");
const Tree = require("./tree");
require("leaflet");
const DisplayOptions = require("./mapFunctions/displayOptions");
const ToolFunctions = require("./toolFunctions");
const Geolocation = require("./mapFunctions/geolocation");
const SignaturePadExtensions = require("./general/signaturePadExtensions");
const Mobile = require("./mobile");
const InstallManager = require("./offline/installManager");
const Offline = require("./offline/offline");
const StatusBarOnlineStatus = require("./offline/statusBarOnlineStatus");
const ResourceController = require("./offline/resourceController");
const UserResource = require("./offline/userResource");
const FormSettings = require("./settings/formSettings");
const ToolSettings = require("./settings/toolSettings");
const BrowserWarning = require("./general/browserWarning");
const Home = require("./home");
const NavBar = require("./navBar");
const UrlRoutes = require("./routes/urlRoutes");
const SentryConfig = require("./sentryConfig");
const Analytics = require("./general/analytics");
const BrowserSupportsFeature = require("./general/browserSupportsFeature");
const RoutineMaintenanceConfig = require("./config/routineMaintenanceConfig");
const TreeInitialDataRam = require("./treeInitialDataRam");
const Esg = require("./esg/esg");
const PermitSettings = require("./report/settings/permitSettings");
const UserPermissions = require("./login/userPermissions");
const PortalController = require("./portal/portalController");
const Config = require("./config");

SentryConfig.init();

L.esri = require("esri-leaflet");
L.esri.Cluster = require("esri-leaflet-cluster");
L.esri.Vector = require("esri-leaflet-vector");
L.esri.Geocoding = require("esri-leaflet-geocoder");
const GestureHandling = require("leaflet-gesture-handling");
require("leaflet.locatecontrol");
require("leaflet-geoserver-request");
L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling.GestureHandling);
require("./mapFunctions/leaflet-ruler.js");

// This suppresses ESRI BasemapLayer warnings.
// Remove after Vector Tile release
const _warn = console.warn;
console.warn = (message) => {
  if (typeof message === "string" && message.includes("L.esri.BasemapLayer")) return;
  _warn(message);
};

require("leaflet-draw");
require("leaflet-rotatedmarker");
require("leaflet-editable");
require("bootstrap");
