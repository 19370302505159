"use strict";

const BmpFcsIcon = function () {
  var getTableIcon = function (
    score,
    isCentralized,
    isFcs,
    isDue,
    phase,
    fixedColor = null,
    isTodo = false,
  ) {
    var iconData = { score, isCentralized, isFcs, isDue, phase, fixedColor };
    var classNamesArray = getIconClassNamesArray(iconData, isTodo);

    return classNamesArray.join(" ");
  };

  var getIconMarker = function (
    score,
    isCentralized,
    abbreviation,
    isFcs,
    isDue,
    phase,
    fixedColor = null,
    isTodo = false,
    mapZoom = null,
  ) {
    var iconData = { score, isCentralized, isFcs, isDue, phase, fixedColor };
    var classNamesArray = getIconClassNamesArray(iconData, isTodo, mapZoom);
    const props = {
      classNames: classNamesArray,
      isCentralized: isCentralized,
      abbreviation: abbreviation,
      isFcs: isFcs,
      zoom: mapZoom,
    };
    return newIconMarker(props);
  };

  var getIconClassNamesArray = function (iconData, isTodo, mapZoom) {
    var color = iconData.fixedColor
      ? iconData.fixedColor
      : getIconColor(iconData.score, iconData.phase, false, isTodo);
    var classNamesArray = getMarkerColorClassArray(color, iconData.isFcs, iconData.isCentralized);

    if (iconData.isDue) {
      classNamesArray.push(MapStyles.getDueIconClass());
    }
    const mapView = Tree.get("navToggleSelection") === "map";
    const dataViews = ["bmp", "va", "fcs", "lid-project", "lid-runoff"];
    const bmpDataViewWithDueIcons = dataViews.includes(Tree.get("dataView"));
    if (bmpDataViewWithDueIcons && mapView) {
      classNamesArray.push(getBmpIconZoomClass(mapZoom));
    }
    return classNamesArray;
  };

  var getMarkerColorClassArray = function (color, isFcs, isCentralized) {
    var classNamesArray = ["bmp-marker-icon"];

    if (isFcs) {
      classNamesArray.push(
        isCentralized ? "centralized fcs-square-" + color : "fcs-circle-" + color,
      );
    } else {
      classNamesArray.push(
        isCentralized ? "centralized bmp-square-" + color : "bmp-circle-" + color,
      );
    }

    return classNamesArray;
  };

  var newIconMarker = function (props) {
    var BMPIcon = L.DivIcon.extend({
      createShadow: function () {
        return this._createIcon("shadow");
      },
    });
    const currentDataView = Tree.get("dataView");
    const bmpDataViews = [
      "bmp",
      "muni-bmp",
      "construction-project-delivery",
      "scenarios",
      "sbmpView",
      "fcsView",
      "trashView",
      "runoffView",
      "particulatesView",
      "runoffRatioView",
      "fcs",
      "va",
      "lid-project",
      "lid-runoff",
      "lid-bmp",
    ];
    const updatedIconProps = MuniBmpIcon._updateIconPropsOnZoom(props.zoom, currentDataView);
    const bmpAnchors = updateIconAnchorOnZoom(props.isCentralized, props.zoom, props.isFcs);
    return new BMPIcon({
      className: props.classNames.join(" "),
      iconSize: bmpDataViews.includes(currentDataView) ? updatedIconProps.size : [34, 42],
      iconAnchor: bmpAnchors.iconAnchor,
      popupAnchor: bmpAnchors.popupAnchor,
      html: bmpDataViews.includes(currentDataView)
        ? "<span class='" + updatedIconProps.textCSSClass + "'>" + props.abbreviation + "</span>"
        : "<span class='default-style' >" + props.abbreviation + "</span>",
    });
  };

  var getBmpIconZoomClass = function (zoom) {
    const zoomLevel = zoom ? zoom : Tree.get("zoomLevel");
    let iconSizeClass;
    if (zoomLevel >= 15) {
      iconSizeClass = "zoom-close";
    } else if (zoomLevel === 14 || zoomLevel === 13) {
      iconSizeClass = "zoom-far";
    } else {
      iconSizeClass = "zoom-very-far";
    }

    return iconSizeClass;
  };

  var updateIconAnchorOnZoom = function (isCentralized, zoom, isFcs) {
    const zoomLevel = zoom ? zoom : Tree.get("zoomLevel");
    const anchors = { iconAnchor: [], popupAnchor: [] };
    const currentDataView = Tree.get("dataView");
    const bmpDataViews = [
      "bmp",
      "muni-bmp",
      "lid-bmp",
      "sbmpView",
      "fcsView",
      "trashView",
      "runoffView",
      "va",
      "particulatesView",
      "runoffRatioView",
    ];
    const secondaryBmpDataViews = [
      "construction-project-delivery",
      "scenarios",
      "lid-project",
      "construction-project",
      "lid-runoff",
      "fcs",
    ];
    const largeGroupDataViews = ["construction-project-delivery", "scenarios"];
    const isLargeGroupAndInLargeGroupDataView =
      Session.isLargeGroup() && largeGroupDataViews.includes(currentDataView);
    const primaryOrSecondaryBmpDataView =
      bmpDataViews.includes(currentDataView) || secondaryBmpDataViews.includes(currentDataView);
    const arBmpViews = ["sbmpView", "fcsView"];

    if (zoomLevel >= 19) {
      if (primaryOrSecondaryBmpDataView) {
        anchors.iconAnchor = isLargeGroupAndInLargeGroupDataView ? [23, 59] : [19, 44];
      }
      if (secondaryBmpDataViews.includes(currentDataView)) {
        anchors.popupAnchor = isLargeGroupAndInLargeGroupDataView ? [-4, -32] : [0, -20];
      }
    } else if (zoomLevel === 18) {
      if (primaryOrSecondaryBmpDataView) {
        anchors.iconAnchor = isLargeGroupAndInLargeGroupDataView ? [22, 50] : [19, 44];
      }
      if (secondaryBmpDataViews.includes(currentDataView)) {
        anchors.popupAnchor = isLargeGroupAndInLargeGroupDataView ? [-2, -24] : [0, -20];
      }
    } else if (zoomLevel === 17 || zoomLevel === 16) {
      if (primaryOrSecondaryBmpDataView) {
        anchors.iconAnchor = isLargeGroupAndInLargeGroupDataView ? [20, 46] : [19, 44];
      }
      if (secondaryBmpDataViews.includes(currentDataView)) {
        anchors.popupAnchor = isLargeGroupAndInLargeGroupDataView ? [0, -22] : [0, -17];
      }
    } else if (zoomLevel === 15) {
      if (primaryOrSecondaryBmpDataView) {
        anchors.iconAnchor = [20, 46];
      }
      if (secondaryBmpDataViews.includes(currentDataView)) {
        anchors.popupAnchor = isLargeGroupAndInLargeGroupDataView ? [0, -20] : [0, -16];
      }
    } else if (zoomLevel === 14) {
      if (primaryOrSecondaryBmpDataView) {
        anchors.iconAnchor = isLargeGroupAndInLargeGroupDataView ? [19, 45] : [10, 20];
      }
      if (secondaryBmpDataViews.includes(currentDataView)) {
        anchors.popupAnchor = isLargeGroupAndInLargeGroupDataView ? [0, -22] : [0, -10];
      }
    } else if (zoomLevel === 13) {
      if (primaryOrSecondaryBmpDataView) {
        anchors.iconAnchor = isLargeGroupAndInLargeGroupDataView ? [18, 43] : [11, 18];
      }
      if (secondaryBmpDataViews.includes(currentDataView)) {
        anchors.popupAnchor = isLargeGroupAndInLargeGroupDataView ? [2, -20] : [0, -7];
      }
    } else if (zoomLevel <= 12 && zoomLevel >= 9) {
      if (primaryOrSecondaryBmpDataView) {
        anchors.iconAnchor = isLargeGroupAndInLargeGroupDataView ? [11, 21] : [4, 4];
      }
      if (secondaryBmpDataViews.includes(currentDataView)) {
        anchors.popupAnchor = isLargeGroupAndInLargeGroupDataView ? [-1, -10] : [0, 2];
      }
    } else if (primaryOrSecondaryBmpDataView) {
      anchors.iconAnchor = isLargeGroupAndInLargeGroupDataView ? [10, 20] : [4, 4];
    } else {
      anchors.iconAnchor = isCentralized ? [20, 40] : [17, 36];
    }
    if (anchors.popupAnchor.length === 0) {
      anchors.popupAnchor = isFcs ? [0, -12] : [0, -10];
      if (arBmpViews.includes(currentDataView) && zoom <= 12) {
        anchors.popupAnchor = [0, 0];
      }
      if (currentDataView === "properties") {
        anchors.popupAnchor = [-3, -24];
      }
    }

    return anchors;
  };

  var getUnsavedIconMarker = function (zoom) {
    var classNamesArray = getMarkerColorClassArray("blue", false, false);
    const props = {
      classNames: classNamesArray,
      isCentralized: false,
      abbreviation: "",
      isFcs: false,
      zoom: zoom,
    };
    return newIconMarker(props);
  };

  var getIconColor = function (score, phase, hex = false, isTodo = false) {
    if (Tree.get(["filters", "conditionPhaseToggle"]) === "phase" && !isTodo) {
      return getIconColorByPhase(phase, hex);
    } else {
      return getIconColorByCondition(score, hex);
    }
  };

  var getIconColorByPhase = function (phase, hex) {
    return hex ? MapStyles.getHexColor(phase) : phase;
  };

  var getIconColorByCondition = function (score, hex) {
    return MapStyles.getConditionColor(score, hex, "no-inspection");
  };

  var getBmpDrainageAreaStyle = function (score, phase, isEnabled) {
    const color = getIconColor(score, phase, true);
    const opacity = isEnabled ? 0.4 : 0;

    var styleProps = {
      color: color,
      fillOpacity: opacity,
      fillColor: color,
      weight: 0,
    };

    return styleProps;
  };

  return {
    getIconMarker,
    getUnsavedIconMarker,
    getTableIcon,
    getBmpDrainageAreaStyle,
    getIconColor,
    getBmpIconZoomClass,
    updateIconAnchorOnZoom,
  };
};

module.exports = BmpFcsIcon();

const Session = require("../login/session");
const MapStyles = require("../mapFunctions/mapStyles");
const MuniBmpIcon = require("../muni/bmp/muniBmpIcon");
const Tree = require("../tree");
