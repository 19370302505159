"use strict";

const ProjectInspectionCloseOutObservations = function () {
  const stringKey = "close-out-observations";
  const tabHeader = "Close Out Observations";

  var loadListeners = function (data) {};

  var unloadListeners = function () {};

  var getProps = function (data) {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = ProjectInspectionCloseOutObservations();
