"use strict";

const StormdrainGeoServerLayer = function () {
  const layerName = "stormdrainGeoServer";
  const layerKey = "stormdrainGeoServerLayer";

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );

    return data;
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const layer = new GeoServerMapLayer(layerName, layerKey);
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, layer);

    layer.loadToggledListenerForMap(map, mapLayers, mapId);
    layer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
    startGeoServerStormdrainsZoomListener(map);
  };

  var startGeoServerStormdrainsZoomListener = function (map) {
    map.on("zoomend", function () {
      geoServerStormdrainsZoomListener(map);
    });
  };

  var geoServerStormdrainsZoomListener = function (map) {
    var mapId = Tree.get("mapId");
    var mapZoom = map.getZoom();
    var isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);
    var stormdrainsEnabled = Tree.get(["layers", "stormdrainGeoServer", isEnabledPath]);

    toggleStormDrainIcon(mapZoom, stormdrainsEnabled);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var toggleStormDrainIcon = function (zoomLevel, isEnabled) {
    if (zoomLevel < 14) {
      $(".stormdrain-legend").addClass("mid-zoom");
    } else {
      $(".stormdrain-legend").removeClass("mid-zoom");
    }
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
  };
};

module.exports = StormdrainGeoServerLayer();

const Actions = require("../actions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const Tree = require("../tree");
