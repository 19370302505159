"use strict";

function FacilitySelfInspectionPage() {
  const stringKey = "page";
  let $page;

  function loadListeners(data) {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on("2N:FormInput", `[name="action"]`, _onActionUpdate);
    $page.on(
      "2N:FormInput",
      `[name^="locationObservationResponses"][name$="[compliant]"]`,
      _onActionUpdate,
    );

    _toggleResponseActionFields();
  }

  function unloadListeners() {
    $page.off("2N:FormInput", `[name="action"]`, _onActionUpdate);
    $page.off(
      "2N:FormInput",
      `[name^="locationObservationResponses"][name$="[compliant]"]`,
      _onActionUpdate,
    );
  }

  var _onActionUpdate = function () {
    _toggleResponseActionFields();
  };

  var _toggleResponseActionFields = function () {
    const actionState = FacilitySelfInspectionController.getCurrentActionState();
    $(`[data-show-action]`).hide();
    $(`[data-show-action~="${actionState.action}"]`).show();
  };

  function getProps(data) {
    const locationObservationsInfo = getLocationObservationsInfo(data.locationObservations);

    return {
      actionOptions: getActionOptions(data),
      locationObservationsInfo,
      contactIdOptions: getProjectContactIdOptions(data.businessOwnerContacts),
      customCompliantRadioLabels: FacilityInspectionConstants.getCustomCompliantRadioLabels(),
      approveRejectRadioLabels: ["Approve", "Reject"],
    };
  }

  function getLocationObservationsInfo(locationObservations) {
    const correctedCount = locationObservations.filter(
      (finding) => finding.compliant === "yes",
    ).length;
    const totalCount = locationObservations.length;

    return {
      correctedCount,
      totalCount,
      allCorrected: correctedCount === totalCount,
      correctedPercent: totalCount ? correctedCount / totalCount : 0,
    };
  }

  function getActionOptions(data) {
    const options = [
      {
        name: "Approve or Reject",
        value: "respond",
      },
      {
        name: "Generate Reminder",
        value: "remind",
      },
    ];

    if (!data.selfInspection) {
      options.push({
        name: "Mark Unresponsive",
        value: "unresponsive",
      });
    }

    if (options.length === 1) {
      const initialFormData = FacilitySelfInspectionController.getInitialFormDataObject();
      initialFormData.action = options[0].value;
    }

    return options;
  }

  function getStringKey() {
    return stringKey;
  }

  function getTabHeader() {
    return null;
  }

  function getProjectContactIdOptions(contacts) {
    contacts = (contacts ?? []).map(function (contact) {
      return {
        value: contact.id,
        name: `${contact.name}`,
      };
    });

    if (contacts.length === 1) {
      const initialFormData = FacilitySelfInspectionController.getInitialFormDataObject();
      initialFormData.letter = initialFormData.letter ?? {};
      initialFormData.letter.contactId = contacts[0].value;
    }

    return contacts;
  }

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
}

module.exports = FacilitySelfInspectionPage();

const FacilityInspectionConstants = require("../facilityInspection/facilityInspectionConstants");
const FacilitySelfInspectionController = require("./facilitySelfInspectionController");
