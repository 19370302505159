"use strict";

const ActivitiesListController = function () {
  var inited = false;

  var init = async function () {
    if (inited) return;
    inited = true;
    DataList.init({
      prepareDataCallback: prepareData,
      template: "list/activitiesList.njk",
      layerName: "activities",
    });
    initialRender();
    DataList.loadDomListeners();
  };

  var initialRender = function () {
    DataList.setSorting("activityName", false);
    DataList.render();
  };

  var prepareData = function (activities) {
    addIconClasses(activities);
    sortBy(activities);
    return { rows: activities, options: {} };
  };

  var addIconClasses = function (activities) {
    activities.forEach((activity) => {
      activity.iconClass = "";
    });
  };

  var sortBy = function (activities) {
    const dateSorter = DateTime.dbDateStringComparator;
    const alphaSorter = SortUtilities.alphaSorter;
    var sortersDict = {
      activityName: alphaSorter,
      outreachType: alphaSorter,
      activityGroup: alphaSorter,
      activity: alphaSorter,
      startDateIso: dateSorter,
      targetAudience: alphaSorter,
      targetPollutant: alphaSorter,
    };
    DataList.sortWithDict(activities, sortersDict);
  };

  var invokeDeleteActivityModal = function (activityId, activityName) {
    MessageModal.showConfirmWarningModal(
      `Delete ${activityName}? Click "Delete" to permanently delete this activity and all associated photos and files or click "Cancel" to return.`,
      function () {
        ApiCalls.deleteActivity(activityId, ActivitiesLayer.invalidateLayerData);
      },
      "Cancel",
      "Delete",
    );
  };

  return {
    init,
    prepareData,
    invokeDeleteActivityModal,
  };
};

module.exports = ActivitiesListController();

const DataList = require("../mapFunctions/dataList");
const DateTime = require("../dateTime");
const SortUtilities = require("../general/sortUtilities");
const ActivitiesLayer = require("./activitiesLayer");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
