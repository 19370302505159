"use strict";

const LocationSelectionMap = require("./locationSelectionMap");
const ToolSettings = require("../settings/toolSettings");

L.Control.GeocodingControls = L.Control.extend({
  initialize: function (props) {
    this._onSelectFunction = props.onSelectFunction;
    this._visible = props.visible;
  },
  onAdd: function (map) {
    this._map = map;
    this._controlDiv = L.DomUtil.create("div", "geocoding-search-control");
    this._controlDiv.innerHTML = nunjucks.render("maps/geocodingSearchButton.njk");
    L.DomEvent.disableClickPropagation(this._controlDiv);

    const apiKey = ToolSettings.getSetting("esri", "authkey");
    const searchControl = L.esri.Geocoding.geosearch({
      placeholder: "Enter an address or place e.g. 1 York St",
      searchBounds: L.latLngBounds(
        L.latLng(72.009313, -170.736483),
        L.latLng(13.973429, -51.746358),
      ),
      useMapBounds: false,
      providers: [
        L.esri.Geocoding.arcgisOnlineProvider({
          apikey: apiKey,
        }),
      ],
    }).addTo(this._map);

    map.searchControl = searchControl;
    map.geocoding = this;

    searchControl.setPosition("middlecenter");

    $(searchControl._container).wrap("<div class='geocoder-wrapper'></div>");
    $(".geocoder-wrapper").prepend("<span>Search the property by address:</span>");
    L.DomEvent.disableClickPropagation($(".geocoder-wrapper")[0]);

    searchControl.on("results", (data) => {
      if (data.results?.length > 0) {
        this._onSelectFunction(data.results[0]);
        map.panTo;
      } else {
        this._onSelectFunction(data);
      }
    });

    LocationSelectionMap.toggleSearchControlVisibility({ show: this._visible });

    this.loadListeners();
    return this._controlDiv;
  },
  loadListeners: function () {
    $(this._controlDiv).off("click");
    $(this._controlDiv).on("click", () => LocationSelectionMap.toggleSearchControlVisibility());
  },
  onRemove: function (map) {
    delete map.removeControl(map.searchControl);
    delete map.geocoding;
    $(".geocoder-wrapper").remove();
  },
  options: {
    position: "topleft",
  },
});
