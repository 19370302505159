"use strict";

const EsriLoader = require("esri-loader");

EsriLoader.setDefaultOptions({
  css: true,
});

var SimLayer = function () {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  const tokenUrl =
    "https://www.arcgis.com/sharing/rest/oauth2/token?client_id=uwjmB5gK1J1KkaKW&client_secret=aff25be05b614e4ba48cf62326596337&grant_type=client_credentials";

  const serverUrl = "https://2ndnature.maps.arcgis.com/";

  var featureFilter = {};
  var featureLayerView;

  var main = function () {
    EsriLoader.loadModules([
      "esri/views/MapView",
      "esri/WebMap",
      "esri/identity/IdentityManager",
      "esri/widgets/LayerList",
      "esri/widgets/Legend",
    ])
      .then(([MapView, WebMap, esriId, LayerList, Legend]) => {
        getToken(tokenUrl, requestOptions, esriId);
        const webmap = getWebMap(WebMap);
        const view = buildView(MapView, webmap);
        view.when(function () {
          buildLayerList(LayerList, view);
          buildLegend(Legend, view);
          getMs4Extent(webmap)
            .then((result) => buildSpatialFilter(view, result))
            .catch(console.error);
        });

        return view;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  var getToken = async function (url, requestOptions, esriId) {
    try {
      const response = await Network.fetchFrom(url, requestOptions);
      const result = await response.json();
      return esriId.registerToken({
        server: serverUrl,
        token: result.access_token,
      });
    } catch (err) {
      return console.error(err);
    }
  };

  var getWebMap = function (webMapModule) {
    const config = EsriConfig["simLayer"];
    var webmap = new webMapModule({
      portalItem: {
        id: config["webmap"],
      },
    });

    return webmap;
  };

  var buildView = function (mapViewModule, webmap) {
    var view = new mapViewModule({
      map: webmap,
      container: "simMap",
    });

    return view;
  };

  var buildLayerList = function (layersModule, view) {
    var layerNode = document.createElement("div");
    layerNode.id = "simsLayer";

    var layerList = new layersModule({
      view: view,
      container: layerNode,
    });

    view.ui.add(layerList, {
      position: "top-left",
    });

    $("#sim-map-container").find(".floating-inputs-table").append(layerNode);

    return layerList;
  };

  var buildLegend = function (legendModule, view) {
    var legendNode = document.createElement("div");
    legendNode.id = "simsLegend";

    var legend = new legendModule({
      view: view,
      container: legendNode,
    });

    view.ui.add(legend, "top-left");

    $("#sim-map-container")
      .find(".floating-inputs-table")
      .append("<center><b>Legend</b></center>")
      .append(legendNode);

    return legend;
  };

  var getMs4Extent = function (webmap) {
    const ms4Layer = webmap.allLayers.find(function (layer) {
      return layer.title === "MS4 Boundary";
    });

    return ms4Layer
      .queryExtent()
      .then(function (results) {
        featureFilter["spatialRelationship"] = "intersects";
        featureFilter["geometry"] = results.extent;
        return featureFilter;
      })
      .catch(console.error);
  };

  var buildSpatialFilter = function (view) {
    view.map.layers.forEach(function (layer, index) {
      view
        .whenLayerView(layer)
        .then(function (layerView) {
          if (layer.type === "feature") {
            featureLayerView = layerView;
            featureLayerView.filter = featureFilter;
          }
          return layerView;
        })
        .catch(console.error);
    });

    return featureLayerView;
  };

  return {
    main,
  };
};

module.exports = SimLayer();

const EsriConfig = require("../config/esriConfig");
const Network = require("../../network");
