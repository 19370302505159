"use strict";

const RoutineRepairFunctionalRepair = function () {
  const stringKey = "functional-repair";
  let $page;

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    RoutineMaintenanceFormTable.loadListeners($page);
  };

  var unloadListeners = function () {
    RoutineMaintenanceFormTable.unloadListeners($page);
  };

  var getProps = function () {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return RoutineMaintenanceFormTable.getRepairTabName();
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = RoutineRepairFunctionalRepair();

const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
