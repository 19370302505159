"use strict";

const ParcelAssessmentHistory = function () {
  var currentHistory = [];

  var load = function (parcel) {
    if (parcel.id) {
      loadParcelAssessmentHistory(parcel);
    }
  };

  var loadParcelAssessmentHistory = function (currentParcel) {
    var waterYear = Tree.get("waterYear");
    ApiCalls.getParcelAssessmentHistory(currentParcel.id, waterYear, function (data) {
      currentHistory = data;
      if (data && ((data.length && currentParcel.id === data[0].polygon_id) || data.length === 0)) {
        renderHistoryTable(data);
      }
    });
  };

  var renderHistoryTable = function (assessmentHistory) {
    var readOnlyTopLevel =
      Session.isRegulator() || !UserPermissions.getPermission("can_assess_parcels");

    if (!readOnlyTopLevel) {
      handleParcelAssessmentHistoryReadOnly(assessmentHistory);
    }
    addParcelAssessmentHistoryProps(assessmentHistory);

    var html = nunjucks.render("popups/history/parcelAssessmentHistory.njk", {
      assessments: assessmentHistory,
      readOnlyTopLevel: readOnlyTopLevel,
      canDeleteInspection: UserPermissions.getPermission("can_delete_parcel_assessments"),
    });
    $(".popup-history-pane").html(html);

    populatePopupBars(assessmentHistory);
  };

  var populatePopupBars = function (assessmentHistory) {
    PopupContent.setPopupConditionBar(
      $(".tr-runoff-condition-score"),
      assessmentHistory,
      function (assessment) {
        const condition = ParcelAssessmentConstants.parcelConditionOptions.find(
          function (condition) {
            return condition.value === assessment.condition;
          },
        );

        if (!condition) {
          console.warn(`Unknown runoff assessment condition ${assessment.condition}.`);
          return;
        }

        return condition.score;
      },
    );
  };

  var handleParcelAssessmentHistoryReadOnly = function (assessmentHistory) {
    assessmentHistory.forEach(function (item) {
      item.readOnly = item.submitted;
    });
  };

  var addParcelAssessmentHistoryProps = function (assessmentHistory) {
    if (assessmentHistory && assessmentHistory.length) {
      assessmentHistory.forEach((item) => {
        item.conditionString = ParcelAssessment.getParcelConditionString(item.condition);
      });
    }
  };

  var editHistory = function (tableRowData, readOnly) {
    var selectedId = tableRowData.id;
    var assessmentData = getParcelAssessmentById(selectedId);
    ParcelAssessment.showEditParcelAssessmentModal(assessmentData, readOnly);
  };

  var confirmDeleteHistory = function ($todoTr, tableRowData) {
    var selectedId = tableRowData.id;

    MessageModal.showDeleteRecordModal(() => {
      deleteHistory(selectedId);
      $todoTr.remove();
    });
  };

  var deleteHistory = function (id) {
    ApiCalls.deleteParcelAssessment(id, Actions.loadRunoffCondition);
  };

  var getParcelAssessmentById = function (selectedId) {
    if (currentHistory && currentHistory.length) {
      for (const assessment of currentHistory) {
        if (assessment.id === selectedId) {
          return assessment;
        }
      }
    }
    return null;
  };

  return {
    load,
    editHistory,
    confirmDeleteHistory,
    populatePopupBars,
  };
};

module.exports = ParcelAssessmentHistory();

const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const Actions = require("../actions");
const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const ParcelAssessment = require("./parcelAssessment");
const MessageModal = require("../modals/messageModal");
const ParcelAssessmentConstants = require("./parcelAssessmentConstants");
const PopupContent = require("../mapFunctions/popupContent");
