"use strict";

const PlanPropertiesController = function () {
  let selectablePropertyCount;
  let planId;
  let type;

  const render = async function (id = null, _type, view = "list") {
    type = _type;
    const alreadyOnPage = planId && PageController.getConfig()?.layer?.name === "planProperties";

    planId = id;
    selectablePropertyCount = await EsgApiCalls.getSelectablePropertyCount(planId);

    const options = {
      doNotRerender: alreadyOnPage,
      dataView: "properties",
      saveFilters: true,
      layer: {
        g2: true,
        name: "planProperties",
        get initParam() {
          return PageController.getData("id");
        },
        map: { baseMap: "Gray" },
        list: {
          defaultSortKey: "rank",
          preparedDataFunction: _getPreparedData,
          template: "esg/properties/planPropertyList.njk",
          afterRenderFunction: _afterListRenderFunction,
          listeners: [
            {
              target: ".shelve-property",
              callback: _showShelvePropertyModal,
            },
            {
              target: ".action-shelve-property",
              callback: _actionShelveProperty,
            },
            {
              target: ".unshelve-property",
              callback: _unshelveProperties,
            },
            {
              target: ".select-property",
              callback: _selectProperties,
            },
            {
              target: "#load-ten-more",
              callback: _showLoadMoreModal,
            },
          ],
        },
      },
      navTabs: {
        asset: id,
        enabledTabs: ["impact", "suitable", "selected"],
        activeTab: type,
      },
      navToggle: {
        view: view.length === 0 ? "list" : view,
      },
      breadcrumbs: {
        previousPage: {
          page: "Stormwater Plans",
          route: "/impact-accounting/stormwater-plans",
        },
        get currentPage() {
          return PageController.getData("planName");
        },
      },
      constants: [
        "regions",
        "states",
        "huc6",
        "huc8",
        "tenants",
        "acquisitionPortfolios",
        "funds",
        "propertyTypes",
        "ejCommunity",
        "retrofitOpportunities",
        "siteComplexity",
        "bmpRelationship",
        "compensationStatus",
        "ownershipStatus",
        "districts",
        "rankCategories",
      ],
      sideBar: {
        filterBarButtons: true,
        isFlatList: false,
        dataFunction: () => {},
        onRowClick: () => {},
        filtersTemplate: "esg/properties/propertyFilters.njk",
      },
      afterRenderFunction: afterRenderFunction,
    };
    await PageController.render(
      "plan-properties",
      "esg/properties/planProperties.njk",
      () => EsgApiCalls.getPlan(planId),
      options,
    );
  };

  const afterRenderFunction = function () {
    Tree.set(["filters", "type"], type);
    FilterSummary.enable({ esg: true });
    Tooltip.setTooltip({
      selector: ".benefits-badge.mini.failed",
      text: "Property analytics could not be completed. The Rainsteward team is investigating the issue.",
      width: 320,
    });
    _handleMessages();
  };

  const _handleMessages = function () {
    const groupId = Tree.get("activeGroup", "groupId");
    const channel = `properties.${groupId}`;

    Messages.subscribe({
      channel,
      event: "refresh",
      callback: () => PlanPropertiesGeoServerLayer.loadGeoServerLayer(),
    });

    PageController.beforeNextRender(() => Messages.unsubscribe(channel));
  };

  const _afterListRenderFunction = function (rowsAndOptions) {
    _renderSummaryBar(rowsAndOptions);
  };

  const _renderSummaryBar = function (rowsAndOptions) {
    const $summaryBar = $(".summary-bar");
    const html = nunjucks.render("esg/properties/planPropertyListSummaryBar.njk", rowsAndOptions);
    $summaryBar.html(html);
  };

  const _getPreparedData = function (properties) {
    sortBy(properties);

    properties?.forEach(function (property) {
      property.iconClass = "list-badge";
      property.dropClasses = DataRichness.getDropClasses(property.dataRichness);
      property.title = EsgSettings.propertyTitleDisplay(property);
    });
    return {
      rows: properties,
      options: {
        selectedPropertiesTotal: properties?.length,
        dropClassesAvg: DataRichness.getDropClasses(properties?.averageValues("dataRichness")),
        totalCostTotal: properties?.sumValues("costTotal"),
        costPerGallonAvg: properties?.sumValues("costTotal") / properties?.sumValues("swMngd"),
        swMngdTotal: properties?.sumValues("swMngd"),
        pollMngdTotal: properties?.sumValues("pollMngd"),
        imperviousAcresTotal: properties?.sumValues("imperviousAcres"),
        navTabs: { activeTab: NavTabs.getActiveTab() },
        selectable: selectablePropertyCount,
        type: Tree.get("filters", "type"),
        permissions: { canCreatePlan: UserPermissions.getPermission("can_create_plan") },
        title: EsgSettings.propertyTitleDisplay(),
      },
    };
  };

  const sortBy = function (properties) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    var sortersDict = {
      propertyIdentifier: alphaSorter,
      propertyName: alphaSorter,
      rank: numberSorter,
      displayAddress: alphaSorter,
      dataRichness: numberSorter,
      costTotal: numberSorter,
      costPerGallon: numberSorter,
      swMngd: numberSorter,
      pollMngd: numberSorter,
      imperviousAcres: numberSorter,
    };
    DataList.sortWithDict(properties, sortersDict);
  };

  const _showLoadMoreModal = function () {
    const propertiesAvailable = Number($(this).attr("data-available"));
    const propertiesToLoad = Math.min(10, propertiesAvailable);
    MessageModal.showConfirmWarningModal(
      null,
      function () {
        _loadTenProperties(planId);
      },
      "Cancel",
      "Load next " + propertiesToLoad + " properties",
      nunjucks.render("esg/modals/selectTenMoreModal.njk", {
        available: propertiesAvailable,
        propCount: propertiesToLoad,
      }),
      null,
      "Load more properties to review",
      "esg",
    );
  };

  const _loadTenProperties = async function (planId) {
    LoadingScreen.waitForResult(
      `.data-list-container`,
      EsgApiCalls.loadTenProperties({ id: planId })
        .then(async function (response) {
          await PageController.refresh();
          return response;
        })
        .catch((error) => {
          return error;
        }),
    );
  };

  const _selectProperties = async function () {
    const propertyId = $(this).parents("tr").attr("data-id");
    LoadingScreen.waitForResult(
      `[data-id="${propertyId}"]`,
      EsgApiCalls.selectProperties({ planId, propertyIds: [propertyId], type })
        .then(async function (response) {
          await PageController.refresh();
          return response;
        })
        .catch((error) => {
          return error;
        }),
    );
  };

  const _deselectProperties = async function () {
    const propertyId = $(this).parents("tr").attr("data-id");
    LoadingScreen.waitForResult(
      `[data-id="${propertyId}"]`,
      EsgApiCalls.deselectProperties({ planId, propertyIds: [propertyId], type })
        .then(async function (response) {
          await PageController.refresh();
          return response;
        })
        .catch((error) => {
          return error;
        }),
    );
  };

  const _showShelvePropertyModal = function () {
    const propertyId = $(this).parents("tr").attr("data-id");
    MessageModal.showConfirmWarningModal(
      null,
      function () {
        _shelveProperties([propertyId]);
      },
      "Cancel",
      "Shelve",
      nunjucks.render("esg/modals/shelvePropertyModal.njk", {
        properties: [propertyId],
      }),
      null,
      "Shelve property from selected",
      "esg",
    );
  };

  const _actionShelveProperty = function () {
    const propertyId = $(this).parents("tr").attr("data-id");
    _shelveProperties([propertyId]);
  };

  const _shelveProperties = async function (propertyIds) {
    LoadingScreen.waitForResult(
      `[data-id="${propertyIds[0]}"]`,
      EsgApiCalls.shelveProperties({ id: planId, propertyIds, type })
        .then(async function () {
          await PageController.refresh();
          return 0;
        })
        .catch((error) => {
          return error;
        }),
    );
  };

  const _unshelveProperties = async function () {
    const propertyId = $(this).parents("tr").attr("data-id");
    LoadingScreen.waitForResult(
      `[data-id="${propertyId}"]`,
      EsgApiCalls.unshelveProperties({ id: planId, propertyIds: [propertyId], type })
        .then(async function () {
          await PageController.refresh();
          return 0;
        })
        .catch((error) => {
          return error;
        }),
    );
  };

  return {
    render,
    _showShelvePropertyModal,
    _shelveProperties,
    _unshelveProperties,
    _showLoadMoreModal,
    _selectProperties,
    _deselectProperties,
    _loadTenProperties,
    _getSelectablePropertyCount: () => selectablePropertyCount,
  };
};

module.exports = PlanPropertiesController();

const PageController = require("../pageController");
const EsgApiCalls = require("../esgApiCalls");
const DataList = require("../../mapFunctions/dataList");
const SortUtilities = require("../../general/sortUtilities");
const DataRichness = require("../dataRichness");
const Tree = require("../../tree");
const MessageModal = require("../../modals/messageModal");
const NavTabs = require("../navTabs");
const FilterSummary = require("../../filters/filterSummary");
const UserPermissions = require("../../login/userPermissions");
const Messages = require("../../messages");
const PlanPropertiesGeoServerLayer = require("../geoServer/planPropertiesGeoServerLayer");
const EsgSettings = require("../esgSettings");
const Tooltip = require("../../tooltip");
const LoadingScreen = require("../../general/loadingScreen");
