"use strict";

const LidSettings = function () {
  const stringKey = "lid-settings";
  const tabHeader = "Post-Construction";

  var loadListeners = function (data) {};

  var unloadListeners = function () {};

  var getProps = function (data) {
    return {
      portalSubscriber: ToolSettings.getSetting("group", "portalSubscriberTools")?.includes("lid"),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = LidSettings();

const ToolSettings = require("../toolSettings");
