"use strict";

const ToolDescriptions = function () {
  const get = function () {
    return {
      pie: {
        name: "Program Impact",
        description:
          "Quantify, map, and track the effectiveness of your coordinated efforts to reduce stormwater pollution and communicate progress towards meeting annual goals.",
      },
      bmpram: {
        name: "Structural BMP Diagnostics",
        description:
          "Provide your teams a consistent approach to map, classify, and routinely inspect your assets to ensure maximum performance, reduce the probability of asset failure, and determine relative maintenance urgency.",
      },
      construction: {
        name: "Construction",
        description:
          "Organize and streamline permitting, inspection, and enforcement activities. Track each project’s compliance with MS4 requirements and standards.",
      },
      lid: {
        name: "Post-Construction",
        description:
          "Streamline your record-keeping to preserve the details of each project that triggered your MS4 post-construction requirements to ensure responsible parties met their annual obligations.",
      },
      trashram: {
        name: "Trash Compliance",
        description:
          "Efficiently turn your observations and data into litter distribution maps that help you prioritize litter control strategies and verify the effectiveness of investments from street sweeping to structural trash capture devices over time.",
      },
      idde: {
        name: "Illicit Discharge",
        description:
          "Empower your team to investigate, evaluate, and document discharge incidents and dry weather outfall inspections.",
      },
      muni: {
        name: "Municipal Maintenance",
        description:
          "Build and maintain a structured asset registry to map, plan, and track all required inspection and maintenance activities and maximize the allocation of resources with intuitive KPIs.",
      },
      indcom: {
        name: "Industrial / Commercial",
        description:
          "Streamline the records, inspections, and documentation necessary to verify and enforce  industrial and commercial facility compliance.",
      },
      peo: {
        name: "Public Education & Outreach",
        description:
          "Centralize and manage all education and outreach activity records and resources. Gain insights to communicate the collective impact of the breadth and diversity of those engaged.",
      },
      outmon: {
        name: "Water Quality Monitoring",
        description:
          "Streamlined collection and organization of water quality monitoring data and results.",
      },
      report: {
        name: "Annual Reporting",
        description: "Automated annual report generation and submission to your regulator",
      },
      assets: {
        name: "Asset Lifecycle Optimization",
        description:
          "Fulfill MS4 asset management requirements to optimize your stormwater assets' reliability and operational performance to maximize program expenditures and achieve clean water outcomes.",
      },
      projdelivery: {
        name: "Project Delivery",
        description:
          "Provide local and regional partners a collaborative solution to manage, visualize and share an authoritative inventory of stormwater improvement projects from concept to delivery.",
      },
      compcal: {
        name: "Compliance Calendar",
        description:
          "Consolidated to do list of activities required by your MS4 permit for complete program visibility",
      },
      suit: {
        name: "Suitability Analysis",
        description:
          "Identify locations best suited for proposed stormwater investments that align with your team’s defined environmental and community goals.",
      },
      scenario: {
        name: "Scenario Planning",
        description:
          "Quantify and evaluate the cumulative watershed-scale impacts of potential stormwater projects to make the best use of available funds and produce reports that help secure grants.",
      },
      help: {
        name: "Help Center",
        description:
          "Comprehensive stormwater FAQ to answer your questions about stormwater management and 2NFORM",
      },
      imac: {
        name: "Impervious Accounting",
        description:
          "Track impervious land coverage allotments by parcel within a standardized accounting system and view results aggregated by development, urban drainage, MS4 or other area of interest.",
      },
      cr: {
        name: "Cost Reporting",
        description:
          "Simply integrate program budgets into your stormwater information management system to streamline past cost accounting and future fiscal projects.",
      },
    };
  };

  return {
    get,
  };
};

module.exports = ToolDescriptions();
