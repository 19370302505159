"use strict";

var MapColors = function () {
  //@TODO add more mapstyle colors here
  const runoffProgress = ["#FFFFFF", "#D2E9FF", "#9ACCFC", "#56A1EC", "#427FBD", "#1C558E"];
  const particulatesProgress = ["#FFFFFF", "#F1F1C9", "#C6A96E", "#A5631B", "#79380D", "#4A1902"];
  const runoffOpportunity = ["#edf2f5", "#bedbed", "#7daac8", "#5f88a4", "#343c5b", "#020A29"];
  const particulatesOpportunity = [
    "#fddfd7",
    "#f1c2b2",
    "#db9880",
    "#8d4730",
    "#671705",
    "#420d0d",
  ];
  const runoffRatioProgress = ["#e9fee9", "#99d7ae", "#6cb2a1", "#56958c", "#457874", "#345b68"];
  const runoffRatioOpportunity = ["#f0f9e8", "#ccebc5", "#a8ddb5", "#7bccc4", "#43a2ca", "#0868ac"];
  const connectivityInputs = ["#F7FEFF", "#D9FCFF", "#9DE5EB", "#7ACDD4", "#26A0AB", "#1a7077"];

  return {
    runoffProgress,
    particulatesProgress,
    runoffOpportunity,
    particulatesOpportunity,
    runoffRatioProgress,
    runoffRatioOpportunity,
    connectivityInputs,
  };
};

module.exports = MapColors();
