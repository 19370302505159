"use strict";

/**
 * Note about DateTimePicker:
 * Values stored in the date picker are stored in Z format with milliseconds
 * Remember to truncate the milliseconds before you send it through the API
 * If you are using Form.js, this is automatically done for you
 */
var DateTimePicker = function () {
  var initialize = function ($element, setToNow = false, includeTime = false, use24Hours = false) {
    var options = {
      altInput: true,
      altFormat: "m/d/Y",
      allowInput: true,
      dateFormat: "Z",
      time_24hr: use24Hours,
      enableTime: includeTime,
    };
    if (includeTime) {
      if (use24Hours) {
        options.altFormat = "m/d/Y H:i";
      } else {
        options.altFormat = "m/d/Y h:iK";
      }
    }

    if ($element.length !== 0) {
      const instance = Flatpickr($element, options);
      if (setToNow) {
        instance.setDate(DateTime.getNowIso());
      }
      // Direct calendar pick
      instance.config.onChange.push(function (inputDates) {
        $element.trigger("2N:OnDateChange", inputDates[0]);
      });
      // Typing a date into the input
      instance.config.onClose.push(function (inputDates, dateStr, fp) {
        const inputValue = fp.altInput.value;
        instance.setDate(fp._input.value);
        $element.trigger("input", fp._input.value);
        if (instance._input.value === "") {
          $element.trigger("2N:BlankInput", {
            inputValue: inputValue,
            oldValue: dateStr,
            newValue: fp._input.value,
          });
        } else {
          $element.trigger("2N:OnDateChange", fp._input.value);
        }
      });
    }
  };

  var getInstanceByElement = function ($element) {
    if ($element === null) return null;
    const instance = $element[0];
    if (instance === undefined || instance === null) {
      return null;
    } else return instance._flatpickr;
  };

  var getInstanceByInputName = function ($element, inputName) {
    if ($element === null || inputName === null) return null;
    const instance = $element.find(`[name='${inputName}']`)[0];
    if (instance === undefined) {
      return null;
    } else return instance._flatpickr;
  };

  var setDate = function ($parent, inputName, date) {
    const instance = inputName
      ? getInstanceByInputName($parent, inputName)
      : getInstanceByElement($parent);
    if (instance !== null) {
      instance.setDate(date);
      return DateTime.parseDateToIso(date);
    }
  };

  var setDateOnElement = function ($element, date) {
    const instance = getInstanceByElement($element);
    if (instance === undefined) {
      console.error("Provided element isn't an initialized Flatpickr instance.");
      return;
    }
    instance.setDate(date);
  };

  var getDisplayDate = function ($parent, inputName) {
    const instance = inputName
      ? getInstanceByInputName($parent, inputName)
      : getInstanceByElement($parent);
    if (instance !== null) {
      return instance.altInput.value;
    }
  };

  var getIsoDate = function (
    $parent,
    inputName,
    localHours = null,
    localMinutes = null,
    localSeconds = null,
  ) {
    const instance = inputName
      ? getInstanceByInputName($parent, inputName)
      : getInstanceByElement($parent);

    if (instance !== null && instance.selectedDates[0] !== undefined) {
      var dateObj = instance.selectedDates[0];
      setLocalTime(dateObj, localHours, localMinutes, localSeconds);
      var dateString = instance.formatDate(dateObj, "Z");
      return DateTime.truncateMilliseconds(dateString);
    }
  };

  var setLocalTime = function (dateObj, localHours, localMinutes, localSeconds) {
    if (localHours !== null) {
      dateObj.setHours(localHours);
    }
    if (localMinutes !== null) {
      dateObj.setMinutes(localMinutes);
    }
    if (localSeconds !== null) {
      dateObj.setSeconds(localSeconds);
    }
  };

  var deleteInstancesBySelector = function (selector) {
    $(selector).each(function (index, instance) {
      instance._flatpickr.destroy();
    });
  };

  var deleteAllInstances = function () {
    $(".flatpickr-input").each(function (index, instance) {
      instance._flatpickr.destroy();
    });
  };

  var setToNow = function ($parent, inputName) {
    const nowDate = DateTime.getNowIso();
    const instance = inputName
      ? getInstanceByInputName($parent, inputName)
      : getInstanceByElement($parent);
    instance.setDate(nowDate);
    return nowDate;
  };

  var formatDateTime = function (date, format) {
    return Flatpickr.formatDate(date, format);
  };

  var isDatePicker = function ($parent) {
    return $parent.hasClass("date-picker");
  };

  var getDateTimeInput = function ($parent) {
    const isoString = $parent.val();
    if (isoString !== undefined) {
      return DateTime.truncateMilliseconds(isoString);
    }
  };

  return {
    initialize,
    getInstanceByElement,
    getInstanceByInputName,
    setDate,
    setDateOnElement,
    setToNow,
    getDisplayDate,
    getIsoDate,
    deleteAllInstances,
    deleteInstancesBySelector,
    formatDateTime,
    isDatePicker,
    getDateTimeInput,
  };
};

module.exports = DateTimePicker();

const Flatpickr = require("flatpickr");
const DateTime = require("../dateTime");
