"use strict";

const ActivityInventoryFiles = function () {
  const stringKey = "activity-files";
  const headerInformation = "Files";
  var $page;

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var loadListeners = function () {
    Form.initializeAndLoadListeners(
      $page,
      ActivityInventoryModalController.getActivityInventoryFormKey(),
      { isMultiPart: true },
    );
    $page.on("click", ".save-button", saveFileData);
    $page.on("click", ".edit-button", editFile);
    $page.on("click", `[name*="location-type"]`, setLocationRadioClick);
    $page.on("click", ".new-file", insertNewFile);
    $page.on("click", ".delete-button", deleteFileButtonClick);
    $page.on("click", ".delete-editing-button", deleteEditingFileButtonClick);
    $page.on("click", ".save-initial-button", saveInitialFileData);
  };

  var unloadListeners = function () {
    $page.off("click", ".save-button");
    $page.off("click", ".edit-button");
    $page.off("click", `[name*="location-type"]`);
    $page.off("click", ".new-file");
    $page.off("click", ".delete-button");
    $page.off("click", ".delete-editing-button");
    $page.off("click", ".save-initial-button", saveInitialFileData);
  };

  var render = function (options = {}) {
    const activity = ActivityInventoryModalController.getActivityInventoryData();
    const props = getProps(activity);
    renderFilesHtml(props);
    initializeExistingFiles(props);
    loadListeners();
    setInitialState(props, options);
  };

  var getProps = function (activity) {
    const constants = ActivityConstants.get();
    const files = activity?.activityFiles ?? [];

    return {
      activityFiles: prepareFiles(files),
      fileTypes: constants.fileTypes,
      uploadLocation: constants.uploadLocation,
    };
  };

  var prepareFiles = function (files) {
    return files
      .filter((file) => !file.deleted)
      .map((file) => ({
        ...file,
        displayType: getDisplayFileType(file.type),
        displayLocationType: getDisplayLocation(file.locationType),
      }))
      .sort((a, b) => a.id - b.id);
  };

  var setInitialState = function (props, options) {
    const numFiles = props?.activityFiles?.length ?? 0;
    if (options.editFile) {
      editFileById(options.editFile);
    } else if (numFiles === 0 || options.newFile) {
      $page.find(".new-file").hide();
      stageNewFile();
      const index = $page.find(".file-display").first().data("index");
      $page.on(
        "input drop",
        `[name*="activityFiles[${index}]"], [data-name*="activityFiles[${index}]"]`,
        stageInitialFileObj,
      );
    }
  };

  var insertNewFile = function () {
    const { html, newFile, currentFilesLength } = createFileHtml();
    ActivityInventoryModalController.setFormDataField(
      ["activityFiles", currentFilesLength],
      newFile,
    );
    $page.find(".activity-files").prepend(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(newFile);
  };

  var stageNewFile = function () {
    const { html, newFile, currentFilesLength } = createFileHtml(true);
    $page.on("input", `[name*="activityFiles[${currentFilesLength}]"]`, createFormFileEntry);
    $page.find(".activity-files").prepend(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(newFile);
  };

  var createFormFileEntry = function (e) {
    const filePosition = $(e.currentTarget).parents(".file-display").data("index");
    stageInitialFileObj(e);
    removeInitialInputListener(filePosition);
  };

  var createFileHtml = function (initialFile = false) {
    const constants = ActivityConstants.get();
    const fileTypes = constants.fileTypes;
    var uploadLocation = constants.uploadLocation;
    uploadLocation = uploadLocation.find((location) => {
      return location.value === "external-url";
    });

    const newId = Date.now();
    const newFile = {
      id: newId,
      type: fileTypes[0].value,
      locationType: uploadLocation.value,
      displayLocationType: uploadLocation.name,
    };
    const currentFilesLength = getCurrentFilesLength();

    return {
      html: nunjucks.render("modals/peo/activityInventoryNewFile.njk", {
        fileTypes: fileTypes,
        file: newFile,
        uploadLocation: constants.uploadLocation,
        index: currentFilesLength,
        initialFile: initialFile,
      }),
      newFile: newFile,
      currentFilesLength: currentFilesLength,
    };
  };

  var getCurrentFilesLength = function () {
    const data = ActivityInventoryModalController.getActivityInventoryData();
    return data.activityFiles?.length ?? 0;
  };

  var stageInitialFileObj = function (e) {
    const $firstFile = $page.find(".activity-files .file").first();
    const fileId = $firstFile.data("id");
    const filePosition = $(e.currentTarget).parents(".file").find(".file-display").data("index");
    if (!getFileById(fileId)) {
      const type = $firstFile.find(`[name="activityFiles[${filePosition}][type]"]`).val();
      const locationType = $firstFile
        .find(`[name="activityFiles[${filePosition}][location-type]"]:checked`)
        .val();

      ActivityInventoryModalController.setFormDataField(
        ["activityFiles", filePosition, "id"],
        fileId,
      );
      ActivityInventoryModalController.setFormDataField(
        ["activityFiles", filePosition, "locationType"],
        locationType,
      );
      ActivityInventoryModalController.setFormDataField(
        ["activityFiles", filePosition, "type"],
        type,
      );
    }

    $page.off(
      "input drop",
      `[name*="activityFiles[${filePosition}]"], [data-name*="activityFiles[${filePosition}]"] `,
      stageInitialFileObj,
    );
  };

  var initializeAdditionalFormFields = function (file) {
    initializeDropzone(file, false);
    showInputByLocationType(file.locationType);
  };

  var initializeExistingFiles = function (activity) {
    activity.activityFiles.forEach((existingFile) => {
      initializeDropzone(existingFile, true);
    });
  };

  var initializeDropzone = function (file, readOnly) {
    const $parent = $page.find(`[data-id=${file.id}]`);
    const dropzoneName = $parent.find(".drop-zone").attr("name");
    let newFile = [];
    let existingFile = [];
    if ($.isArray(file.fileUploadLocation)) {
      newFile = file.fileUploadLocation;
    } else if (file.fileUploadLocation) {
      existingFile = [file.fileUploadLocation];
    }
    const dropzone = Form.initializeDropzone("activity-inventory", $parent, {
      newFiles: newFile,
      existingFiles: existingFile,
      maxNumberFiles: 1,
      readOnly,
    });

    configureDropzoneHandlers(file.id, dropzoneName, dropzone);
  };

  var configureDropzoneHandlers = function (activityId, dropzoneName, dropzone) {
    dropzone.setDownloadHandler((activityFileName) => {
      ApiCalls.downloadActivityFile(activityId, activityFileName);
    });
    dropzone.setRemoveExistingCallback((file) => {
      removeFileFromForm(dropzoneName);
    });
  };

  var setLocationRadioClick = function (e) {
    const value = $(e.currentTarget).val();
    showInputByLocationType(value);
  };

  var editFile = function (e) {
    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    editFileById(fileId);
  };

  var editFileById = function (fileId) {
    const constants = ActivityConstants.get();

    const fileInfo = getFileById(fileId);
    const options = getFileTypeOptionsWithFile(fileInfo);

    const html = nunjucks.render("modals/peo/activityInventoryModifyFile.njk", {
      fileTypes: options,
      uploadLocation: constants.uploadLocation,
      file: fileInfo,
      index: getFileIndexById(fileId),
    });
    $(`.file[data-id=${fileId}]`).html(html);
    toggleEditSaveButtonsDisabled(true);
    initializeAdditionalFormFields(fileInfo);
  };

  var getFileTypeOptionsWithFile = function (fileInfo) {
    const constants = ActivityConstants.get();
    const options = Array.from(constants.fileTypes);

    if (
      !options.some(function (option) {
        return option.value === fileInfo.type;
      })
    ) {
      options.unshift(getFileType(fileInfo.type));
    }

    return options;
  };

  var saveInitialFileData = function (e) {
    stageInitialFileObj(e);
    saveFileData(e);
  };

  var saveFileData = function (e) {
    const constants = ActivityConstants.get();

    const $file = $(e.currentTarget);
    const fileId = $file.closest(".file").data("id");
    let file = getFileById(fileId);
    const type = constants.uploadLocation.find((option) => {
      return option.value === file.locationType;
    });
    if (type === undefined) {
      throw new Error(`File type ${file.locationType} does not exist.`);
    }
    file.displayLocationType = type.name;
    file = addAbsoluteUrlsToFiles([file])[0];
    const html = nunjucks.render("modals/peo/activityInventoryExistingFile.njk", {
      file: {
        ...file,
        displayType: getDisplayFileType(file.type),
        displayLocationType: getDisplayLocation(file.locationType),
      },
    });
    removeInitialInputListener();
    toggleEditSaveButtonsDisabled(false);
    $file.closest("section").html(html);
    initializeDropzone(file, true);
  };

  var deleteFileButtonClick = function (e) {
    showDeleteFileModal(e, false);
  };

  var deleteEditingFileButtonClick = function (e) {
    showDeleteFileModal(e, true);
  };

  var showDeleteFileModal = function (e, editingFile) {
    const $file = $(e.currentTarget).closest(".file");
    const fileId = $file.data("id");
    const displayFileType = getDisplayFileTypeById(fileId);
    if (displayFileType) {
      const deleteCallback = function () {
        deleteFile(fileId);
        if (editingFile === true) {
          toggleEditSaveButtonsDisabled(false);
        }
      };
      MessageModal.showDeleteFileModal(displayFileType, deleteCallback);
    } else {
      removeInitialInputListener();
      $(`[data-id="${fileId}"]`).remove();
      toggleEditSaveButtonsDisabled(false);
    }
  };

  var deleteFile = function (fileId) {
    const fileIndex = getFileIndexById(fileId);
    const existingFileIds = (
      ActivityInventoryModalController.getExistingData("activityFiles") || []
    ).map((file) => {
      return file.id;
    });

    if (existingFileIds.includes(fileId)) {
      ActivityInventoryModalController.setFormDataField(["activityFiles", fileIndex], {
        deleted: true,
        id: fileId,
      });
    } else {
      ActivityInventoryModalController.unsetFormDataField(["activityFiles", fileIndex]);
    }
    $(`[data-id="${fileId}"]`).remove();
  };

  var toggleEditSaveButtonsDisabled = function (disabled) {
    if (disabled === false) {
      $page.find(".new-file").show();
    }
    $page.find(".edit-button, .new-file").prop("disabled", disabled);
  };

  var renderFilesHtml = function (activity) {
    activity.activityFiles = addAbsoluteUrlsToFiles(activity.activityFiles || []);
    const html = nunjucks.render("modals/peo/activityInventoryFiles.njk", activity);
    ActivityInventoryModalController.renderPageContent(html);
    $page = $("#inventory-modal .modal-dynamic-content").assertLength(1);
  };

  function addAbsoluteUrlsToFiles(files) {
    return files.map((file) => {
      const makeAbsolute = file.externalUrl && !/^(https?:)?\/\/.*$/.test(file.externalUrl);
      file.absoluteUrl = makeAbsolute ? "//" + file.externalUrl : file.externalUrl;
      return file;
    });
  }

  var getFileById = function (fileId) {
    const data = ActivityInventoryModalController.getActivityInventoryData();
    return (
      data?.activityFiles?.find((file) => {
        return file.id === fileId;
      }) ?? undefined
    );
  };

  var getFileIndexById = function (fileId) {
    const data = ActivityInventoryModalController.getActivityInventoryData();
    if (data.activityFiles) {
      return data.activityFiles.findIndex((file) => {
        return fileId === file?.id;
      });
    }
    return 0;
  };

  var getDisplayFileTypeById = function (fileId) {
    const fileIndex = getFileIndexById(fileId);
    const fileType = ActivityInventoryModalController.getLatestActivityInventoryData([
      "activityFiles",
      fileIndex,
      "type",
    ]);
    return fileType === undefined ? undefined : getDisplayFileType(fileType);
  };

  var getDisplayFileType = function (fileValue) {
    return getFileType(fileValue).name;
  };

  var getFileType = function (fileValue) {
    const constants = ActivityConstants.get();

    return constants.fileTypes.find((options) => {
      return options.value === fileValue;
    });
  };

  var getDisplayLocation = function (location) {
    const constants = ActivityConstants.get();

    return constants.uploadLocation.find((options) => {
      return options.value === location;
    }).name;
  };

  var cleanUp = function () {
    unloadListeners();
    $page.empty();
    return true;
  };

  var validate = function () {
    return true;
  };

  var showInputByLocationType = function (locationType) {
    $page.find(".file-text-description").toggle(locationType === "location");
    $page.find(".file-drop-zone").toggle(locationType === "upload");
    $page.find(".file-external-url").toggle(locationType === "external-url");
  };

  const removeFileFromForm = function (dropzoneName) {
    const pathParts = Form.getPathPartsFromName(dropzoneName);
    ActivityInventoryModalController.setFormDataField(pathParts, "");
  };

  var removeInitialInputListener = function (length) {
    const currentFilesLength = length || getCurrentFilesLength();
    $page.off("input", `[name*="activityFiles[${currentFilesLength}]"]`, createFormFileEntry);
  };

  return {
    getStringKey,
    getHeaderInformation,
    render,
    cleanUp,
    validate,
    deleteFile,
  };
};

module.exports = ActivityInventoryFiles();

const ActivityInventoryModalController = require("./activityInventoryModalController");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const ApiCalls = require("../apiCalls");
const ActivityConstants = require("./activityConstants");
