"use strict";

const LidProjectPopup = function () {
  var getAllPerformanceTierString = function (project) {
    if (project.performanceTiers?.length) {
      return project.performanceTiers.join(", ");
    }
    return "";
  };

  var handleUpdateResponseClick = async function () {
    const projectId = $(this).parents(".popup-lid-project").data("id");
    LetterCommunicationModal.renderAndShow([projectId]);
  };

  var handleGenerateLetterClick = async function () {
    const projectId = $(this).parents(".popup-lid-project").data("id");
    LetterCommunicationModal.renderAndShow([projectId], { action: "send" });
  };

  var handleDownloadLetterClick = function () {
    const selfInspectionId = $(this).parents("tr").data("id");

    return ApiCalls.downloadLidSelfInspectionPdf(selfInspectionId);
  };

  var handleDownloadSummaryClick = function () {
    const selfInspectionId = $(this).parents("tr").data("id");

    return ApiCalls.downloadLidSummaryPdf(selfInspectionId);
  };

  var handleDownloadResponseClick = async function () {
    const selfInspectionId = $(this).parents("tr").data("id");
    ApiCalls.downloadLidResponsePdf(selfInspectionId);
  };

  var handleViewSummaryClick = function () {
    const summaryId = $(this).parents("tr").data("id");

    return InspectionSummaryLetterModal.renderAndShowEdit(summaryId, { readOnly: true });
  };

  var handleDeleteSummaryClick = function () {
    const $row = $(this).parents("tr");

    MessageModal.showDeleteRecordModal(async function () {
      await ApiCalls.deleteLidInspectionSummary($row.data("id"));
      $row.remove();
    });
  };

  var _renderSiteVisitHistoryBarGraph = function (inspectionsArray) {
    PopupContent.setPopupConditionBar($(".tr-lid-score"), inspectionsArray, function (datum) {
      if (datum.sbmpCondition === "desired") {
        return 5;
      } else if (datum.sbmpCondition === "poor") {
        return 0;
      }
    });
  };

  return {
    _renderSiteVisitHistoryBarGraph,
    getAllPerformanceTierString,
    handleUpdateResponseClick,
    handleGenerateLetterClick,
    handleDownloadLetterClick,
    handleDownloadSummaryClick,
    handleDownloadResponseClick,
    handleViewSummaryClick,
    handleDeleteSummaryClick,
  };
};

module.exports = LidProjectPopup();

const ApiCalls = require("../apiCalls");
const LetterCommunicationModal = require("./letterCommunicationModal");
const PopupContent = require("../mapFunctions/popupContent");
const InspectionSummaryLetterModal = require("./inspectionSummaryLetterModal");
const MessageModal = require("../modals/messageModal");
