"use strict";

const MuniPipesFilters = function () {
  const searchDbFields = ["name"];
  const searchFields = ["name"];
  let mainSmartFilters;

  var filterResourceDataHandler = (unfilteredData) =>
    mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;

  var passInstalledDateFilter = function (datum, filters) {
    return ResourceFilters.passIsoDateFilter(
      datum.installDate,
      filters.installDate?.from,
      filters.installDate?.to,
    );
  };

  var addGeoServerViewParams = function (params, filters) {
    GeoServerFilterFunctions.addSpatialFilterViewParams(params, filters);

    if (!FilterSummary.areAllCheckboxesChecked("correctiveAction")) {
      params["correctiveAction"] = filters.correctiveAction.map((item) => `'${item}'`).join("\\,");
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("correctiveAction") &&
      $('input[name="correctiveAction"][value="no-action"]').is(":checked")
    ) {
      params[`includeNoAction`] = true;
    }
    if (!FilterSummary.areAllCheckboxesChecked("enforcementLevel")) {
      params["enforcementLevel"] = filters.enforcementLevel.map((item) => `'${item}'`).join("\\,");
    }
    if (
      !FilterSummary.areAllCheckboxesChecked("enforcementLevel") &&
      $('input[name="enforcementLevel"][value="null"]').is(":checked")
    ) {
      params[`includeNoActionLevel`] = true;
    }
    ResourceFilters.addDateParam(
      params,
      "routine",
      filters.routineDate?.from,
      filters.routineDate?.to,
    );
    ResourceFilters.addDateParam(
      params,
      "repair",
      filters.repairDate?.from,
      filters.repairDate?.to,
    );
  };

  var isOnlineDateFilterSet = function (filters) {
    return false;
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("implementerType")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "implementerType",
        "implementer_type",
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("maintainedBy")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "maintainedBy",
        "responsible_party_maintenance",
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("lines")) {
      queryStrings = GeoServerFilterFunctions.yesNoNullGeoServerFilter(
        filters,
        "lines",
        queryStrings,
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("phase")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "phase", "phase");
    }
    if (!FilterSummary.areAllCheckboxesChecked("ålandOwnership")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "landOwnership",
        "property",
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("consequenceOfFailure")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "consequenceOfFailure",
        "cof_final_text",
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("serviceType")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "serviceType",
        "line_service_type",
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("lined")) {
      if (filters.lined?.length !== undefined) {
        GeoServerFilterFunctions.yesNoNullGeoServerFilter(
          filters,
          "lined",
          queryStrings,
          "lined",
          filters.lined,
        );
      }
    }
    if (!FilterSummary.areAllCheckboxesChecked("accessible")) {
      if (filters.accessible?.length !== undefined) {
        GeoServerFilterFunctions.yesNoNullGeoServerFilter(
          filters,
          "accessible",
          queryStrings,
          "accessible",
          filters.accessible,
        );
      }
    }
    if (filters.installDate) {
      GeoServerFilterFunctions.addDateQuery(
        queryStrings,
        "install_date",
        filters.installDate?.from,
        filters.installDate?.to,
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("accessible")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(queryStrings, filters, "shape", "shape");
    }

    const lengthWidthQueries = [];
    const diameterQuery = [];
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "diameter",
      filters.diameterRange,
      "diameter_units",
      diameterQuery,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "length",
      filters.lengthRange,
      "length_units",
      lengthWidthQueries,
    );
    GeoServerFilterFunctions.addFeetAndInchesMinMaxMeasurmentQuery(
      "width",
      filters.widthRange,
      "width_units",
      lengthWidthQueries,
    );
    const shapeDbColumnName = "shape";
    const indexOfShapeQuery = MeasurementMinMax.findIndexOfShapeQuery(
      queryStrings,
      shapeDbColumnName,
    );
    GeoServerFilterFunctions.createMinMaxMeasurmentCombinedQuery(
      queryStrings,
      lengthWidthQueries,
      diameterQuery,
      indexOfShapeQuery,
      shapeDbColumnName,
    );
    if (!FilterSummary.areAllCheckboxesChecked("type")) {
      GeoServerFilterFunctions.addFilterToGeoServerQuery(
        queryStrings,
        filters,
        "type",
        "pipe_type",
      );
    }
    if (!FilterSummary.areAllCheckboxesChecked("condition")) {
      GeoServerFilterFunctions.addConditionFilterToGeoServerQuery(queryStrings, filters);
    }
  };

  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, zoneOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      landOwnershipOptions: FilterConstants.responsiblePartyDeliveryOptionsCB,
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      zoneOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      phaseOptions: BmpFcsInventoryConstants.phaseOptionsCB,
      cofOptions: FilterConstants.cofOptionsWithNull,
      conditionOptions: FilterConstants.conditionOptions,
      shapeOptions: FilterConstants.linnedAssetsShapes,
      serviceTypeOptions: FilterConstants.serviceType,
      flowTypeOptions: FilterConstants.flowType,
      accessibleOptions: FilterConstants.yesNoOptions,
      typeOptions: FilterConstants.pipeTypeOptions,
      correctiveOptions: FilterConstants.getAllCorrectiveOptions(),
      enforcementLevel: FilterConstants.getEnforcementLevel(),
      yesNoOptions: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
        {
          name: "No Data",
          value: "null",
        },
      ],
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      implementerType: "implementerType",
      maintainedBy: "maintainedBy",
      landOwnership: "landOwnership",
      type: "type",
      serviceType: "serviceType",
      phase: "phase",
      accessible: "accessible",
      condition: "condition",
      consequenceOfFailure: "cof",
      drainsToRw: ["drains_to_rws", "array"],
      drainsToC: ["drains_to_cs", "array"],
      catchmentName: ["catchment_names", "array"],
      catchments: ["catchment_names", "array"],
      zones: ["zone_names", "array"],
      maintenanceZones: ["zone_names", "array"],
      correctiveAction: ["correctiveAction", "array"],
      enforcementLevel: ["enforcementLevel", "array"],
      shape: "shape",
      lined: "lined",
      subGroups: "groupId",

      otherFilters: (datum, filters) => {
        return (
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields) &&
          passInstalledDateFilter(datum, filters)
        );
      },
    };
  };

  return {
    filterResourceDataHandler,
    isOnlineDateFilterSet,
    addGeoServerFilterQuery,
    getProps,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = MuniPipesFilters();

const ResourceFilters = require("../filters/resourceFilters");
const GeoServerFilterFunctions = require("../mapFunctions/geoServerFilterFunctions");
const FilterConstants = require("../filterConstants");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const FilterSummary = require("../filters/filterSummary");
const MeasurementMinMax = require("../general/measurementMinMax");
const Filters = require("../mapFunctions/filters");
const Progeny = require("../login/progeny");
