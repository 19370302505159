"use strict";

/* *********This Module handles tab functions like initializing map loads on tab change. *********** */
const Tabs = function () {
  var lastPageName = null;

  const startListening = function () {
    $("#tool-nav-tabs a").on("shown.bs.tab", tabClick);
    $("body").on("click", ".page-link", tabClick);
  };

  const tabClick = function (e) {
    const pageName = $(e.target).data("pageName");

    // Prevents duplicate calls when more than one tab is switched by one button
    if (lastPageName === pageName) {
      return;
    }

    PageFunctions.goToPage(pageName);
  };

  const loadTab = function (pageName, dataView) {
    if (Tree.get("activeTab") !== pageName) {
      _makeTabActive(pageName);
      beforeUpdate();
    }

    switch (pageName) {
      case "inputs":
        InputsMap.loadInputsMap();
        break;
      case "report":
        ReportMap.loadReportMap();
        break;
      case "plan":
        PlanMap.loadPlanMap();
        break;
      case "submit":
        $("body").removeAttr("style");
        SubmitReport.updateSubmitReport();
        break;
      case "chart":
        ReportMap.loadReportMap();
        break;
      case "summary":
        ReportMap.loadReportMap();
        break;
      case "groupSettings":
        GroupSettings.updateSettings();
        break;
    }

    afterUpdate(dataView);
  };

  var beforeUpdate = function () {
    KPI.handleKpiTableDisplay();
    YearDropdown.updateYearDropdownDisplay();
  };

  var afterUpdate = function (dataView) {
    DataViewDropdown.setupDataViewDropdown(dataView);
    DataViewFunctions.setMapLayerFromDataView();

    if (
      Progeny.activeHasProgeny() &&
      PageFunctions.getCurrentPageProperty("disabledInRegionalView") &&
      Tree.get("mapId")
    ) {
      var activeTab = getActiveTab();
      if (activeTab === "summary") {
        PageFunctions.goToPage("submit");
      } else {
        PageFunctions.goToPage(Tree.get("mapId"));
      }
    }
  };

  var getActiveTab = function () {
    return Tree.get("activeTab");
  };

  function activeTabHasMap() {
    return Tree.get("mapId") !== null;
  }

  var activeTabHasMapShowing = function () {
    var activeTab = getActiveTab();
    return !["chart", "summary", "submit", "groupSettings"].includes(activeTab);
  };

  var _makeTabActive = function (pageName) {
    _makeTabActiveInDom(pageName);
    _makePageVisible(pageName);
    _makeTabActiveInTree(pageName);
  };

  var _makeTabActiveInDom = function (pageName) {
    const $tabs = $("#tool-nav-tabs .page-link");
    $tabs.removeClass("active");
    $tabs.filter(`[data-page-name='${pageName}']`).addClass("active");
  };

  var _makePageVisible = function (pageName) {
    const pageSelector = PageFunctions.getPageConfigByPageName(pageName).pageSelector;
    const $mapContainer = $("#map-container");
    $mapContainer.find(".tab-pane").hide();
    $mapContainer.find(pageSelector).show();
  };

  var _makeTabActiveInTree = function (pageName) {
    lastPageName = pageName;
    Tree.set("activeTab", pageName);

    const oldMapId = Tree.get("mapId");
    if (oldMapId) {
      Tree.set(["lastMapId"], oldMapId);
    }

    const mapId = PageFunctions.getCurrentPageProperty("mapId");
    Tree.set(["mapId"], mapId);

    setSentryActiveTab(pageName, mapId);
  };

  var renderTabs = function (pageNameArray) {
    const pageConfigs = pageNameArray.map(function (pageName) {
      var config = PageFunctions.getPageConfigByPageName(pageName);
      config.pageName = pageName;
      return config;
    });
    $("#tool-nav-tabs").html(nunjucks.render("report/navbar/tabs.njk", { pageConfigs }));
  };

  var setSentryActiveTab = function (pageName, mapId) {
    const scope = Sentry.getCurrentScope();
    scope.setTag("activeTab", pageName);
    scope.setExtra("activeTab", pageName);

    scope.setExtra("selectedLayer", DataViewFunctions.getCurrentLayerName());

    const selectedYear = Tree.get("yearDropdownSelection");
    scope.setExtra("selectedYear", selectedYear);

    scope.setExtra("mapId", mapId);
  };

  var handleDisabledTabs = function () {
    const allPageConfigs = PageFunctions.getAllPageConfigs();

    for (const page in allPageConfigs) {
      const config = allPageConfigs[page];
      const isEnabled = isTabEnabled(page, config);
      const $pageLinks = $(`[data-page-name="${page}"]`);
      $pageLinks.toggleClass("disabled", !isEnabled);

      if (page === "inputs" || page === "plan") {
        $pageLinks.toggle(isEnabled);
      }
    }
  };

  var isTabEnabled = function (page, config) {
    const mapId = Tree.get("mapId");
    const dataView = Tree.get("dataView");
    const enabledDataViews = config.enabledDataViews;
    const pageConfig = PageFunctions.getPageConfigByPageName(page);

    const tabEnabledInDataView = enabledDataViews.includes(dataView) || !mapId;
    const pageHasSupportedReport = PageFunctions.pageHasSupportedReport(config);
    const isRegionalViewAndTabDisabled =
      Progeny.activeHasProgeny() && pageConfig.disabledInRegionalView;
    const isRegulatorAndTabDisabled =
      Progeny.rootGroupIsRegulator() && pageConfig.disabledForRegulators;

    return (
      tabEnabledInDataView &&
      pageHasSupportedReport &&
      !isRegionalViewAndTabDisabled &&
      !isRegulatorAndTabDisabled
    );
  };

  return {
    startListening,
    getActiveTab,
    activeTabHasMap,
    loadTab,
    handleDisabledTabs,
    renderTabs,
    activeTabHasMapShowing,
  };
};

module.exports = Tabs();

const Sentry = require("@sentry/browser");
const ReportMap = require("./report/reportMap");
const PlanMap = require("./plan/planMap");
const Tree = require("../tree");
const KPI = require("./report/kpi");
const SubmitReport = require("./report/submitReport");
const PageFunctions = require("./pageFunctions");
const DataViewFunctions = require("./dataViewFunctions");
const DataViewDropdown = require("./mapFunctions/dataViewDropdown");
const YearDropdown = require("./mapFunctions/yearDropdown");
const Progeny = require("../login/progeny");
const InputsMap = require("./inputs/inputsMap");
const GroupSettings = require("./settings/groupSettings");
