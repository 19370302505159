"use strict";

function DataResource(
  stringKey,
  {
    getAllOnline = null,
    getNewOnline = null,
    processNewOfflineData = null,
    getExistingOnline = null,
    processExistingOnlineData = (data) => data,
    saveNewOnline = null,
    saveUpdatesOnline = null,
    filterResourceDataHandler = null,
    formLockedKey = null,
    getRelatedData,
    relatedResourceKey = null,
    postSync = null,
    dbOptions = {},
  } = {},
) {
  const defaultStoreName = "main";
  const metadataStoreName = "metadata";
  const fileStoreName = "files";
  const keyPath = "id";
  var cachedDefaultStore;
  var cachedMetadataStore;
  var cachedFileStore;
  var offlineCount;
  var isFetchingPromise = null;
  var initialFetch = false;

  async function init() {
    if (cachedDefaultStore) {
      throw new Error(`Models should only be initialized once.`);
    }

    const db = new Database(
      stringKey,
      [
        { key: "data-resource-initial", migration: initialMigration },
        { key: "data-resource-related-id", migration: () => {} },
        { key: "data-resource-related-global-id", migration: () => {} },
        { key: "data-resource-global-id", migration: () => {} },
        { key: "data-resource-files", migration: addFilesStore },
        { key: "data-resource-sync-status-index", migration: () => {} },
        { key: "data-resource-remove-related-global-id", migration: removeRelatedGlobalIdIndex },
        { key: "data-resource-remove-global-id", migration: removeGlobalIdIndex },
        { key: "data-resource-remove-related-id", migration: removeRelatedIdIndex },
        { key: "data-resource-remove-sync-status-index", migration: removeSyncStatusIndex },
        { key: "data-resource-remove-editing-index", migration: removeEditingIndex },
      ],
      dbOptions,
    );

    cachedDefaultStore = new CachedObjectStore(db, defaultStoreName, keyPath);
    cachedMetadataStore = new CachedObjectStore(db, metadataStoreName, keyPath);
    cachedFileStore = new CachedObjectStore(db, fileStoreName, keyPath);

    await Promise.all([_updateOfflineCount(), _fixMetadata(), deleteOrphanedFiles()]);
  }

  function initialMigration(migrationDb) {
    migrationDb.createObjectStore(defaultStoreName, { keyPath });
    migrationDb.createObjectStore(metadataStoreName, { keyPath });
  }

  function removeEditingIndex(migrationDb, transaction) {
    const metaStore = transaction.objectStore(metadataStoreName);

    if (metaStore.indexNames.contains("editing")) {
      metaStore.deleteIndex("editing");
    }
  }

  function removeRelatedIdIndex(migrationDb, transaction) {
    const metaStore = transaction.objectStore(metadataStoreName);

    if (metaStore.indexNames.contains("relatedId")) {
      metaStore.deleteIndex("relatedId");
    }
  }

  function removeRelatedGlobalIdIndex(migrationDb, transaction) {
    const metaStore = transaction.objectStore(metadataStoreName);

    if (metaStore.indexNames.contains("relatedGlobalId")) {
      metaStore.deleteIndex("relatedGlobalId");
    }
  }

  function removeGlobalIdIndex(migrationDb, transaction) {
    const mainStore = transaction.objectStore(defaultStoreName);

    if (mainStore.indexNames.contains("globalId")) {
      mainStore.deleteIndex("globalId");
    }
  }

  async function addFilesStore(migrationDb, transaction) {
    migrationDb.createObjectStore(fileStoreName, { keyPath });

    const metaStore = transaction.objectStore(metadataStoreName);
    const mainStore = transaction.objectStore(defaultStoreName);
    const fileStore = transaction.objectStore(fileStoreName);
    await deleteTemporaryMetadata();
    await migrateFiles();

    async function deleteTemporaryMetadata() {
      let cursor = await metaStore.openCursor();
      while (cursor) {
        const metadata = cursor.value;

        metadata.formData = {};
        await metaStore.put(metadata);
        cursor = await cursor.continue();
      }
    }

    async function migrateFiles() {
      let cursor = await mainStore.openCursor();
      while (cursor) {
        const mainData = cursor.value;
        const metadata = await metaStore.get(mainData.id);

        if (mainData.formData && metadata) {
          metadata.fileIdSet = new Set();

          Misc.eachNestedPropertyWithKey(
            mainData.formData,
            "directUpload",
            function (file, parent) {
              if (file instanceof File) {
                const id = UUID.v4();
                fileStore.put({ id, file });
                metadata.fileIdSet.add(id);

                parent.directUpload = { id, url: `/offlineData/file/${getStringKey()}/${id}` };
                delete parent.offlineOnly;
              }
            },
          );

          metaStore.put(metadata);
          mainStore.put(mainData);
        }

        cursor = await cursor.continue();
      }
    }
  }

  function removeSyncStatusIndex(migrationDb, transaction) {
    const metaStore = transaction.objectStore(metadataStoreName);

    if (metaStore.indexNames.contains("syncStatus")) {
      metaStore.deleteIndex("syncStatus");
    }
  }

  function getStringKey() {
    return stringKey;
  }

  async function getById(id, offlineOnly = false) {
    if (getExistingOnline !== null && offlineOnly !== true) {
      try {
        const data = await getExistingOnline(id);
        return processExistingOnlineData(data);
      } catch (e) {
        ApiError.assertIs(e);
        if (typeof id === "string") {
          Sentry.captureException(e);
        }
      }
    }

    return await getOfflineById(id);
  }

  async function getOfflineById(id) {
    return structuredClone(await cachedDefaultStore.get(id));
  }

  async function getAll({ filters = null, refreshData = false, loadingScreen = true } = {}) {
    var unfilteredData = await _getAllUnfilteredData(refreshData, loadingScreen);

    if (filters && filterResourceDataHandler) {
      return await filterResourceDataHandler(unfilteredData, filters, loadingScreen);
    }

    return unfilteredData;
  }

  async function _getAllUnfilteredData(refreshData, loadingScreen) {
    let allData = null;

    if (initialFetch === false || (refreshData && isFetchingPromise === null)) {
      initialFetch = true;
      isFetchingPromise = getAndReplaceAllOnlineData(loadingScreen);
      allData = await isFetchingPromise;
      isFetchingPromise = null;
    } else if (isFetchingPromise) {
      await isFetchingPromise;
    }

    if (allData) {
      return allData;
    }

    return await cachedDefaultStore.getAll();
  }

  async function getAndReplaceAllOnlineData(loadingScreen) {
    try {
      const allData = await getAllOnline(loadingScreen);
      await replaceAllStoredData(allData);
      return allData;
    } catch (e) {
      ApiError.assertIs(e);
    }
  }

  async function replaceAllStoredData(allData) {
    await cachedDefaultStore.replaceAll(allData);

    offlineCount = allData.length;
  }

  async function createNew(relatedId) {
    persistStorage();
    const existing = await getExistingNewDataStore(this, relatedId);
    if (existing) {
      return existing;
    }

    const data = await getNew(relatedId);
    const relatedData = getRelatedData(data);
    return await forceCreate.call(this, data, {
      relatedId: relatedData.id,
    });
  }

  async function forceCreate(data, { relatedId = null } = {}) {
    const store = new DataStore(this, {
      data,
      isNew: true,
      relatedId: relatedId,
      keepOffline: formLockedKey !== null,
    });
    await store.init();
    return store;
  }

  async function getExistingNewDataStore(resource, relatedId) {
    const existingMetadata = await getRelatedIdMetadata(relatedId);

    if (!existingMetadata) {
      return null;
    }

    assertCanOpen(existingMetadata);

    if (existingMetadata.editing === "open") {
      return await promptGetOrDeleteExistingDataStore(resource, existingMetadata);
    } else if (
      existingMetadata.editing === "closed" &&
      !(await promptExistingDataStore(existingMetadata))
    ) {
      await deleteOffline(existingMetadata.id);
      return null;
    }

    return await openExisting.call(resource, existingMetadata.id);
  }

  function assertCanOpen(existingMetadata) {
    if (existingMetadata?.editing === "locked") {
      MessageModal.showSimpleWarningModal(
        `The form is locked and waiting to be synced to the server. Please go online and wait for syncing to complete.`,
      );
      throw new DataStoreError(`The data store is locked and can not be opened.`);
    } else if (existingMetadata?.syncStatus === "syncing") {
      MessageModal.showSimpleWarningModal(
        `The form is being synced to the server. Please wait for syncing to complete.`,
      );
      throw new DataStoreError(`The data store is syncing and can not be opened.`);
    }
  }

  // Always assertCanOpen() before calling this
  async function promptExistingDataStore(existingMetadata) {
    const answer = await MessageModal.showYesNoMessage(
      "Resume Editing?",
      `There is ${
        existingMetadata.editing === "open" ? "unsaved" : "existing"
      } data from the last time this form was used, do you want to resume where you left off? Press "No" to delete the unsaved data and start fresh.`,
    );

    return answer === "yes";
  }

  // Always assertCanOpen() before calling this
  async function promptGetOrDeleteExistingDataStore(resource, existingMetadata) {
    const shouldKeep = await promptExistingDataStore(existingMetadata);

    if (shouldKeep) {
      const store = new DataStore(resource, {
        id: existingMetadata.id,
        metadata: existingMetadata,
      });
      await store.init();
      return store;
    } else {
      await deleteOffline(existingMetadata.id);
    }

    return null;
  }

  async function getNew(relatedId) {
    const relatedData = await getRelatedOfflineData(relatedId);

    try {
      return await getNewOnline(getIntId(relatedData, relatedId));
    } catch (e) {
      ApiError.assertIs(e);
    }

    if (!relatedData) {
      throw new DataNotFoundError(`No related data found for ${relatedId}.`, relatedId);
    }

    return processNewOfflineData(relatedData);
  }

  function getIntId(relatedData, relatedId) {
    const result = relatedData?.id ?? relatedId;

    if (!Misc.onlyContainsNumbers(result)) {
      if (relatedData) {
        throw new Error(
          `Unexpected data ${JSON.stringify(relatedData)} from asset with ID ${JSON.stringify(
            relatedId,
          )}.`,
        );
      } else if (relatedData === undefined) {
        throw new DataNotFoundError(
          `No related data found for ID ${JSON.stringify(relatedId)}`,
          relatedId,
        );
      } else {
        MessageModal.showSimpleWarningModal(`"${relatedId}" is not a valid ID.`);
        throw new MalformedIdError(`Unexpected ID ${JSON.stringify(relatedId)}.`, relatedId);
      }
    }

    return result;
  }

  async function getRelatedOfflineData(relatedId) {
    if (!relatedResourceKey) {
      return null;
    }

    return await ResourceController.get(relatedResourceKey).getOfflineById(relatedId);
  }

  async function openExisting(id) {
    const existingMetadata = structuredClone(await getMetadata(id));

    assertCanOpen(existingMetadata);

    if (existingMetadata?.editing === "open") {
      const existing = await promptGetOrDeleteExistingDataStore(this, existingMetadata);
      if (existing) {
        return existing;
      }
    }

    SyncManager.remove(getSyncKey(id));
    const data = await getById(id, existingMetadata?.isNew);

    if (formLockedKey && data[formLockedKey] === true) {
      const relatedData = getRelatedData(data);
      await deleteOffline(id);
      return createNew.call(this, relatedData.id);
    }

    return await makeExistingDataStore(this, id, data, { editing: "open" });
  }

  async function makeExistingDataStore(instance, id, data, { editing = null } = {}) {
    var metadata = await getMetadata(id);
    metadata = structuredClone(metadata);

    if (!metadata) {
      metadata = { syncStatus: "synced", keepOffline: false };
    }

    if (editing) {
      metadata.editing = editing;
    }

    data = organizeOfflineData(data);
    metadata.data = data.data;
    metadata.formData = data.formData;

    const store = new DataStore(instance, { id, metadata });
    await store.init();
    return store;
  }

  function organizeOfflineData(data) {
    if (!data) {
      return {};
    }

    if (data.data && data.formData) {
      return { data: data.data, formData: data.formData };
    }

    return { data: data };
  }

  async function overrideMetadata(metadata) {
    metadata = structuredClone(metadata);
    await cachedMetadataStore.put(metadata);

    return metadata;
  }

  async function _clearMetadata(id) {
    const metadata = await getMetadata(id);

    await deleteFiles(metadata?.fileIdSet ?? []);
    cachedMetadataStore.delete(id);
  }

  async function getMetadata(id = null, property = null) {
    var metadata;

    if (id) {
      metadata = await cachedMetadataStore.get(id);
    } else {
      metadata = await cachedMetadataStore.getAll();
    }

    if (property) {
      return metadata?.[property];
    }

    return metadata;
  }

  async function getAllEditingMetadata(editing = "open") {
    return getMetadataWhere("editing", editing);
  }

  async function getRelatedIdMetadata(relatedId) {
    return getMetadataFirstWhere("relatedId", relatedId);
  }

  async function getMetadataWhere(property, value) {
    const allMetadata = await cachedMetadataStore.getAll();

    return structuredClone(allMetadata.filter((metadata) => metadata[property] === value));
  }

  async function getMetadataFirstWhere(property, value) {
    const allMetadata = await cachedMetadataStore.getAll();

    return structuredClone(allMetadata.find((metadata) => metadata[property] === value));
  }

  async function saveNew(initial, changes, syncId) {
    const data = await saveNewOnline(initial, changes, syncId);
    return processExistingOnlineData(data);
  }

  async function saveUpdates(initial, changes, syncId) {
    const data = await saveUpdatesOnline(initial, changes, syncId);
    return processExistingOnlineData(data);
  }

  async function sync() {
    const notEditing = [
      ...(await getAllEditingMetadata("closed")),
      ...(await getAllEditingMetadata("locked")),
    ];
    const allUnsynced = notEditing.filter((data) =>
      ["unsynced", "failed"].includes(data.syncStatus),
    );

    for (const unsynced of allUnsynced) {
      await syncData(this, unsynced);
    }
  }

  async function syncData(instance, unsynced) {
    const id = unsynced.id;
    const data = structuredClone(await cachedDefaultStore.get(id));
    const store = await makeExistingDataStore(instance, id, data);

    if (getRelatedData && !getRelatedData(store.getInitial())) {
      console.error(`No related data found for ${id}`);
      await deleteOffline(id);
      return;
    }

    SyncManager.add(getSyncKey(id), store.sync, postSync);
  }

  async function saveOffline(data) {
    await cachedDefaultStore.put(structuredClone(data));
    await _updateOfflineCount();
  }

  async function deleteOffline(id) {
    await _clearMetadata(id);
    await cachedDefaultStore.delete(id);
    await _updateOfflineCount();
  }

  function getSyncKey(id) {
    return `${stringKey}-${id}`;
  }

  async function putFile(file) {
    cachedFileStore.put(file);

    try {
      // Make synchronous so the service worker can display offline photos
      await cachedFileStore.getDbDonePromise();
    } catch (e) {
      console.error(e);
    }

    Offline.logStorageQuota();
  }

  function deleteFile(id) {
    cachedFileStore.delete(id);
  }

  async function deleteFiles(fileIdIterable) {
    cachedFileStore.delete([...fileIdIterable]);
  }

  async function getFile(id) {
    return await cachedFileStore.get(id);
  }

  function getOfflineCount() {
    return offlineCount;
  }

  async function _updateOfflineCount() {
    offlineCount = await cachedDefaultStore.count();
  }

  async function _fixMetadata() {
    const allMetadata = await cachedMetadataStore.getAll();
    const updates = [];
    const promises = [];

    for (const data of allMetadata) {
      if (data.syncStatus === "syncing") {
        updates.push({ ...data, syncStatus: "failed" });
      } else if (data.syncStatus === "synced" && data.editing === "locked") {
        console.error("Deleting synced locked data", data);
        promises.push(deleteOffline(data.id));
      }
    }

    if (updates.length > 0) {
      promises.push(cachedMetadataStore.put(updates));
    }

    await Promise.all(promises);
  }

  async function deleteOrphanedFiles() {
    const usedFileIdsSet = await _getAllUsedFileIdsSet();
    const keysToDelete = [];

    for (const fileId of await cachedFileStore.getAllKeys()) {
      if (!usedFileIdsSet.has(fileId)) {
        keysToDelete.push(fileId);
      }
    }

    await cachedFileStore.delete(keysToDelete);
  }

  async function _getAllUsedFileIdsSet() {
    const usedFileIdsSet = new Set();

    for (const metadata of await getMetadata()) {
      for (const fileId of metadata.fileIdSet) {
        usedFileIdsSet.add(fileId);
      }
    }

    return usedFileIdsSet;
  }

  // Based on https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/persist
  async function persistStorage() {
    if (navigator?.storage?.persist) {
      const persistent = await navigator.storage.persist();

      if (persistent) {
        console.debug("Storage will not be cleared except by explicit user action");
      } else {
        console.debug("Storage may be cleared by the UA under storage pressure.");
      }
    }
  }

  function _getCachedObjectStores() {
    return {
      cachedDefaultStore,
      cachedMetadataStore,
      cachedFileStore,
    };
  }

  return {
    init,
    getStringKey,
    getById,
    getOfflineById,
    getAll,
    replaceAllStoredData,
    createNew,
    forceCreate,
    openExisting,
    saveNew,
    saveUpdates,
    saveOffline,
    deleteOffline,
    overrideMetadata,
    getMetadata,
    sync,
    putFile,
    deleteFile,
    deleteFiles,
    getFile,
    getRelatedIdMetadata,
    getOfflineCount,
    deleteOrphanedFiles,
    _getCachedObjectStores,
  };
}

module.exports = DataResource;

const ApiError = require("../errors/apiError");
const Database = require("./database");
const DataStore = require("./dataStore");
const DataNotFoundError = require("../errors/dataNotFoundError");
const DataStoreError = require("../errors/dataStoreError");
const MalformedIdError = require("../errors/malformedIdError");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
const ResourceController = require("./resourceController");
const Sentry = require("@sentry/browser");
const SyncManager = require("./syncManager");
const UUID = require("uuid");
const Offline = require("./offline");
const CachedObjectStore = require("./cachedObjectStore");
