"use strict";

const DryWeatherIcon = function () {
  var getDryWeatherMarker = function (latlng, properties, options = null) {
    if (properties.assetType === "outfall") {
      return OutfallsIcon.getOutfallsMarker(
        latlng,
        properties.rotationAzimuth,
        properties.isHighPriority,
        dryWeatherConditionToAssetCondition(properties.dryWeatherCondition),
        properties.isDue,
        properties.isMonitoringLocation,
      );
    } else if (properties.assetType === "manhole") {
      return ManholesIcon.getManholesMarker(
        latlng,
        dryWeatherConditionToAssetCondition(properties.dryWeatherCondition),
        properties.isHighPriority,
        properties.isDue,
      );
    } else if (properties.assetType === "bmpFcs") {
      const icon = MuniCatchBasinIcon.getIconMarkerFromCondition(
        dryWeatherConditionToAssetCondition(properties.dryWeatherCondition),
        properties.isDue,
        properties.isHighPriority,
      );

      return L.marker(latlng, { icon });
    }

    throw new Error(`Unknown asset type ${properties.assetType}.`);
  };

  var getDryWeatherIcon = function (properties, options = null) {
    if (properties.assetType === "outfall") {
      return OutfallsIcon.getOutfallsMarkerIcon(
        properties.isHighPriority,
        dryWeatherConditionToAssetCondition(properties.dryWeatherCondition),
        properties.isDue,
      );
    } else if (properties.assetType === "manhole") {
      if (options) {
        return ManholesIcon.getManholesMarkerIcon(
          dryWeatherConditionToAssetCondition(properties.dryWeatherCondition),
          properties.isHighPriority,
          properties.isDue,
        );
      }
    } else if (properties.assetType === "bmpFcs") {
      const icon = MuniCatchBasinIcon.getIconMarkerFromCondition(
        dryWeatherConditionToAssetCondition(properties.dryWeatherCondition),
        properties.isDue,
        properties.isHighPriority,
      );
      return icon;
    }

    throw new Error(`Unknown asset type ${properties.assetType}.`);
  };

  var getDryWeatherClass = function (properties) {
    return getDryWeatherMarker(null, properties).options.icon.options.className;
  };

  var dryWeatherConditionToAssetCondition = function (dryWeatherCondition) {
    const conditionMap = {
      none: "optimal",
      open: "poor",
      closed: "closed",
      "no-access": null,
      null: null,
    };

    return getMappedProperty(conditionMap, dryWeatherCondition);
  };

  var getMappedProperty = function (map, property) {
    const result = map[property];

    if (result === undefined) {
      throw new Error(`Property ${property} not found in map.`);
    }

    return result;
  };

  var getIconColor = function (dryWeatherCondition, isDue) {
    if (isDue) {
      return "blue";
    }

    const conditionMap = {
      none: "green",
      open: "red",
      closed: "black",
      "no-access": "grey",
      null: "grey",
    };

    return getMappedProperty(conditionMap, dryWeatherCondition);
  };

  return {
    getDryWeatherMarker,
    getIconColor,
    getDryWeatherClass,
    getDryWeatherIcon,
  };
};

module.exports = DryWeatherIcon();

const ManholesIcon = require("../muni/manholes/manholesIcon");
const MuniCatchBasinIcon = require("../muni/muniCatchBasinIcon");
const OutfallsIcon = require("../outfalls/outfallsIcon");
