"use strict";

const ActivityConstants = function () {
  let targetOptionsById;

  function get() {
    return ToolSettings.getSetting("constants", "peoActivity");
  }

  function getTargetOption(id) {
    if (!targetOptionsById) {
      setTargetOptionsById();
    }

    return targetOptionsById[id];
  }

  function setTargetOptionsById() {
    targetOptionsById = [];

    const constants = get();
    setTargetOption(constants.audience);
    setTargetOption(constants.pollutant);
    setTargetOption(constants.behavior);
    setTargetOption(constants.staff);
  }

  function setTargetOption(options) {
    for (const option of options) {
      targetOptionsById[option.id] = option;
    }
  }

  function getTargetDisplayByGrouping(targetOptionIds, targetGroupings) {
    const displayValues = [];

    for (const optionId of targetOptionIds) {
      const option = getTargetOption(optionId);
      if (targetGroupings.includes(option.targetGrouping)) {
        displayValues.push(option.displayValue);
      }
    }

    return displayValues.sort().join(", ");
  }

  return {
    get,
    getTargetOption,
    getTargetDisplayByGrouping,
  };
};

module.exports = ActivityConstants();

const ToolSettings = require("../settings/toolSettings");
