"use strict";

var ChartStyles = function () {
  const sizes = {
    chartHeight: 272,
    columnWidth: 65,
    labelHeight: 55,
    chartHorizontalPadding: 65,
    textHeight: "1em",
    verticalTextPadding: "0.5em",
    verticalTextSpacing: "1.5em",
  };

  var getMargin = function (largeMarginRight) {
    var margin = {
      top: 100,
      right: 100,
      bottom: 350,
      left: 100,
    };

    if (largeMarginRight) {
      margin.right += 280;
    }

    return margin;
  };

  var barSegmentIsDark = function (fieldName) {
    const isDark = {
      treatedPlu: false,
      progressPlu: false,
      untreatedPlu: true,
      currentRunoff: false,
      reductionRunoff: false,
    };

    return isDark[fieldName];
  };

  var getBarSegmentShape = function (format) {
    const isLine = ["milestone"];
    const shape = isLine.includes(format) ? "line" : "square";

    return shape;
  };

  var getRowYPos = function (rowNumber) {
    const rowTextHeight = 35;
    const rowTextSpacing = 67;

    return sizes.chartHeight + rowTextHeight + rowTextSpacing * Math.max(0, rowNumber - 1);
  };

  return {
    sizes,
    barSegmentIsDark,
    getBarSegmentShape,
    getRowYPos,
    getMargin,
  };
};

module.exports = ChartStyles();
