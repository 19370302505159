"use strict";

const HighPriorityAreaLayer = function () {
  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      "highPriorityArea",
      mapId,
      function (highPriorityAreaIsEnabled, sameSpatialFilter) {
        if (highPriorityAreaIsEnabled) {
          if (
            mapLayers.highPriorityAreaLayer &&
            (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
          ) {
            map.addLayer(mapLayers.highPriorityAreaLayer);
          } else {
            loadLayer(mapId);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.highPriorityAreaLayer)) {
            map.removeLayer(mapLayers.highPriorityAreaLayer);
          }
        }
      },
    );
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated("highPriorityArea", mapId, function (data) {
      mapLayers.highPriorityAreaLayer = addHighPriorityAreaLayer(
        map,
        mapLayers.highPriorityAreaLayer,
        data,
      );
    });
  };

  var addHighPriorityAreaLayer = function (map, highPriorityAreaLayer, data) {
    if (highPriorityAreaLayer) {
      map.removeLayer(highPriorityAreaLayer);
    }

    return L.geoJson(data, {
      pane: "overCatchmentPane",
      style: MapStyles.styleHighPriorityAreas,
    }).addTo(map);
  };

  var loadLayer = function (mapId = "main") {
    const filters = Actions.getFiltersByMapId(mapId);
    const dataPath = Actions.getLayerDataPathByMapId(mapId);

    Tree.set(["layers", "highPriorityArea", "isFetching"], true);
    ApiCalls.getHighPriorityArea(filters).then((data) => {
      Tree.set(["layers", "highPriorityArea", dataPath], data);
      Tree.set(["layers", "highPriorityArea", "isFetching"], false);
    });
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
  };
};

module.exports = HighPriorityAreaLayer();

const MapFunctions = require("../mapFunctions/mapFunctions");
const MapStyles = require("../mapFunctions/mapStyles");
const Tree = require("../tree");
const ApiCalls = require("../apiCalls");
const Actions = require("../actions");
