"use strict";

const DataSortFunctions = function () {
  var getDataSortConfig = function (dataSort) {
    const config = getAllDataSortConfigs()[dataSort];

    if (config === undefined) {
      throw Error(`Data sort ${dataSort} has no configuration.`);
    }

    return config;
  };

  var getCurrentDataSortProperty = function (property, dataSort = null) {
    if (dataSort === null) {
      dataSort = Tree.get("table", "dataSort");
    }
    const configProperty = getDataSortConfig(dataSort)[property];

    return configProperty;
  };

  var getCurrentDataSortConfig = function (dataSort = null) {
    if (dataSort === null) {
      dataSort = Tree.get("table", "dataSort");
    }

    const config = getAllDataSortConfigs()[dataSort];
    if (config === undefined) {
      throw Error(`Data sort ${dataSort} has no configuration.`);
    }

    return config;
  };

  var getAllDataSortConfigs = function () {
    return DataSortConfig.get();
  };

  var getCurrentDataSortData = function (dataSort) {
    return Tree.get([dataSort, "items"]) ?? [];
  };

  return {
    getCurrentDataSortProperty,
    getDataSortConfig,
    getAllDataSortConfigs,
    getCurrentDataSortConfig,
    getCurrentDataSortData,
  };
};

module.exports = DataSortFunctions();

const DataSortConfig = require("./config/dataSortConfig");
const Tree = require("./tree");
