"use strict";

const StormwaterOpportunityController = function () {
  var render = async function () {
    Tree.set("filters", {});
    const options = {
      dataView: "properties",

      layer: {
        name: "allProperties",
        g2: true,
        map: {
          baseMap: "Gray",
          simplified: true,
        },
      },
      breadcrumbs: {
        currentPage: "Portfolio Summary",
      },
      preparedDataFunction: _getPreparedData,
      afterRenderFunction: _afterRenderFunction,
    };
    await PageController.render(
      "portfolio",
      "esg/dashboards/stormwaterOpportunity.njk",
      () => ApiCalls.getOpportunityPortfolio(),
      options,
    );
  };

  const _getPreparedData = function (data) {
    return {
      ...data,
      logos: GroupLogos.get(),
      canCreatePlan: UserPermissions.getPermission("can_create_plan"),
      hasUnits: ToolSettings.getSetting("hasUnits"),
    };
  };

  const _afterRenderFunction = function () {
    Tree.set("filters", {});
  };

  return {
    render,
  };
};

module.exports = StormwaterOpportunityController();

const PageController = require("../pageController");
const ApiCalls = require("../esgApiCalls");
const GroupLogos = require("../groupLogos");
const UserPermissions = require("../../login/userPermissions");
const ToolSettings = require("../../settings/toolSettings");
const Tree = require("../../tree");
