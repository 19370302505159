"use strict";

const DataExport = function () {
  var showModal = function () {
    // regulator has to select a MS4 first
    if (Progeny.activeHasProgeny()) {
      return;
    }

    $("#exportModal").modal("show");
  };

  var handleDisabledLink = function () {
    var disabled = Progeny.activeHasProgeny() || !DataViewFunctions.isTelrDataView();

    $(".import-export").find(".data-export-link").toggleClass("disabled", disabled);
  };

  var exportData = function () {
    // show a message to users
    var $exportModal = $("#exportModal");
    var modalBody = $exportModal.find(".modal-body");
    var modalFooter = $exportModal.find(".modal-footer");
    var bodyHTML = modalBody.html();
    var footerHTML = modalFooter.html();
    var exportType = modalBody.find("#exportTypeSelector").val();

    modalBody.html("Your download will begin shortly.");
    modalFooter.empty();

    // Can't set HTTP headers without XHR,
    // and XHR is hard to do right for file downloads.
    // Let's send our JWT as a query parameter instead.
    var form = document.createElement("form");
    var input = document.createElement("input");

    input.name = "token";
    input.value = TokenManager.loadToken();
    form.action = getExportUrl(exportType);
    form.method = "POST";
    form.style.display = "none";
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    $exportModal.one("hidden.bs.modal", function (e) {
      modalBody.html(bodyHTML);
      modalFooter.html(footerHTML);
    });

    setTimeout(function () {
      $exportModal.modal("hide");
    }, 2000);
  };

  var getExportUrl = function (exportType) {
    var groupId = Tree.get("activeGroup", "groupId");

    const url =
      exportType === "shp"
        ? `${Config.apiUrl}/shapes/swtelr/${groupId}`
        : `${Config.apiUrl}/exports/swtelr/${groupId}`;

    return url;
  };

  return {
    showModal,
    exportData,
    handleDisabledLink,
  };
};

module.exports = DataExport();

const Config = require("./config.js");
const TokenManager = require("../login/tokenManager");
const Tree = require("../tree");
const Progeny = require("../login/progeny");
const DataViewFunctions = require("./dataViewFunctions");
