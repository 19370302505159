"use strict";

const DataViewConfig = function () {
  const get = function () {
    const inputsLayerDropdown = [
      {
        layerDropdown: "soilInputs",
        displayName: "Soil Group",
        catchmentPopupTemplate: "report/popups/catchmentSoil.njk",
        esriLayerId: 0,
      },
      {
        layerDropdown: "imperviousInputs",
        displayName: "% Impervious",
        catchmentPopupTemplate: "report/popups/catchmentImpervious.njk",
        esriLayerId: 1,
      },
      { layerDropdown: "connectivityInputs", displayName: "Connectivity" },
      {
        layerDropdown: "landuseInputs",
        displayName: "Land Use",
        catchmentPopupTemplate: "report/popups/catchmentLanduse.njk",
        esriLayerId: 3,
      },
    ];

    return {
      trashView: {
        dataModule: "trash",
        summaryTemplate: "report/report/trashSummaryText.njk",
        updateDataFunction: ReportSummary.updateTrashData,
        kpiApiCall: true, // just until we figure out what to do about this one
        layerNameByMapId: {
          inputs: "trashConditionEsriDynamicMap",
          report: "trashProgressEsriDynamicMap",
          plan: "litterHotspotEsriDynamicMap",
        },
        tableDataColumns: {
          report: ["reduction"],
          inputs: ["reduction"],
          plan: ["reduction"],
        },
        defaultBasemap: {
          report: "DarkGray",
          plan: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportDefaultLayersBody.njk",
          plan: "report/legend/plan/layers/planTrashViewLayersBody.njk",
          inputs: "report/legend/inputs/layers/inputsTrashViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportTrashViewLegendsBody.njk",
          plan: "report/legend/plan/legends/planTrashViewLegendsBody.njk",
          inputs: "report/legend/inputs/legends/inputsTrashViewLegendsBody.njk",
        },
        dataSortLabelData: {
          report: {
            dataSort1Id: "plu",
            dataSort1Label: "Total Catchment PLU",
            dataSort2Id: "plu_perc",
            dataSort2Label: "% Treated",
          },
          inputs: {
            dataSort1Id: "total",
            dataSort1Label: "Total Catchment Area",
            dataSort2Id: "plu",
            dataSort2Label: "Total Catchment PLU",
          },
          plan: {
            dataSort1Id: "total",
            dataSort1Label: "Total Catchment Area",
            dataSort2Id: "plu_untreated",
            dataSort2Label: "Untreated PLU Area",
          },
        },
        catchmentViewEnabled: true,
        reportSummaryEnabled: true,
        progressChartEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: true,
      },

      runoffView: {
        dataModule: "swtelr",
        kpiApiCall: ReportApiCalls.getTelrKpi,
        summaryTemplate: "report/report/runoffSummaryText.njk",
        updateDataFunction: ReportSummary.updateRunoffData,
        layerNameByMapId: {
          report: "runoffProgress",
          plan: "runoffOpportunity",
          inputs: "soilInputs",
        },
        tableDataColumns: {
          report: ["reduction"],
          plan: ["rate"],
          inputs: ["area"],
        },
        defaultBasemap: {
          report: "DarkGray",
          plan: "Gray",
          inputs: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportDefaultLayersBody.njk",
          plan: "report/legend/plan/layers/planRunoffViewLayersBody.njk",
          inputs: "report/legend/inputs/layers/inputsSoilImperviousLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportRunoffViewLegendsBody.njk",
          plan: "report/legend/plan/legends/planRunoffViewLegendsBody.njk",
          inputs: "report/legend/inputs/legends/inputsSoilImperviousLegendsBody.njk",
        },
        dataSortLabelData: {
          report: {
            dataSort1Id: "reduction",
            dataSort1Label: "Reduction (afy)",
            dataSort2Id: "reduction_perc",
            dataSort2Label: "Reduction from Baseline",
          },
          plan: {
            dataSort1Id: "volume",
            dataSort1Label: "Runoff Volume (afy)",
            dataSort2Id: "rate",
            dataSort2Label: "Runoff Rate (ft/yr)",
          },
        },
        layerDropdown: {
          plan: [
            { layerDropdown: "current", displayName: "Current" },
            { layerDropdown: "baseline", displayName: "Baseline" },
            { layerDropdown: "matrixCurrent", displayName: "Matrix Current" },
            { layerDropdown: "matrixBaseline", displayName: "Matrix Baseline" },
          ],
          inputs: inputsLayerDropdown,
        },
        progressChartEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: true,
        reportSummaryEnabled: true,
        layerSelectionControll: {
          inputs: "layerDropdown",
        },
      },

      particulatesView: {
        dataModule: "swtelr",
        kpiApiCall: ReportApiCalls.getTelrKpi,
        summaryTemplate: "report/report/particulatesSummaryText.njk",
        updateDataFunction: ReportSummary.updateParticulatesData,
        layerNameByMapId: {
          report: "particulatesProgress",
          plan: "particulatesOpportunity",
          inputs: "soilInputs",
        },
        tableDataColumns: {
          report: ["reduction"],
          plan: ["rate"],
          inputs: ["area"],
        },
        defaultBasemap: {
          report: "DarkGray",
          plan: "Gray",
          inputs: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportDefaultLayersBody.njk",
          plan: "report/legend/plan/layers/planParticulatesViewLayersBody.njk",
          inputs: "report/legend/inputs/layers/inputsSoilImperviousLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportParticulatesViewLegendsBody.njk",
          plan: "report/legend/plan/legends/planParticulatesViewLegendsBody.njk",
          inputs: "report/legend/inputs/legends/inputsSoilImperviousLegendsBody.njk",
        },
        dataSortLabelData: {
          report: {
            dataSort1Id: "reduction",
            dataSort1Label: "Reduction (ton/yr)",
            dataSort2Id: "reduction_perc",
            dataSort2Label: "Reduction from Baseline",
          },
          plan: {
            dataSort1Id: "volume",
            dataSort1Label: "Particulate Load (ton/yr)",
            dataSort2Id: "rate",
            dataSort2Label: "Particulate Rate (ton/ac/yr)",
          },
        },
        layerDropdown: {
          plan: [
            { layerDropdown: "current", displayName: "Current" },
            { layerDropdown: "baseline", displayName: "Baseline" },
          ],
          inputs: inputsLayerDropdown,
        },
        progressChartEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: true,
        reportSummaryEnabled: true,
        layerSelectionControll: {
          inputs: "layerDropdown",
        },
      },

      runoffRatioView: {
        dataModule: "swtelr",
        kpiApiCall: ReportApiCalls.getTelrKpi,
        layerNameByMapId: {
          report: "runoffRatioProgress",
          plan: "runoffRatioOpportunity",
          inputs: "soilInputs",
        },
        tableDataColumns: {
          report: ["reduction"],
          plan: ["rate"],
          inputs: ["area"],
        },
        defaultBasemap: {
          report: "DarkGray",
          plan: "Gray",
          inputs: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportDefaultLayersBody.njk",
          plan: "report/legend/plan/layers/planRunoffRatioViewLayersBody.njk",
          inputs: "report/legend/inputs/layers/inputsSoilImperviousLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportRunoffRatioViewLegendsBody.njk",
          plan: "report/legend/plan/legends/planRunoffRatioViewLegendsBody.njk",
          inputs: "report/legend/inputs/legends/inputsSoilImperviousLegendsBody.njk",
        },
        layerDropdown: {
          plan: [
            { layerDropdown: "current", displayName: "Current" },
            { layerDropdown: "baseline", displayName: "Baseline" },
          ],
          inputs: inputsLayerDropdown,
        },
        dataSortLabelData: {
          report: {
            dataSort1Id: "reduction",
            dataSort1Label: "Reduction (%)",
          },
          plan: {
            dataSort1Id: "volume",
            dataSort1Label: "Runoff Ratio (%)",
          },
        },
        progressChartEnabled: false,
        hideChartTab: true,
        hideSummaryTab: true,
        showDefaultLayers: true,
        blueDotEnabled: true,
        layerSelectionControll: {
          inputs: "layerDropdown",
        },
      },

      sbmpView: {
        dataModule: "swtelr",
        kpiApiCall: ReportApiCalls.getSbmpKpi,
        summaryTemplate: "report/report/bmpSummaryText.njk",
        updateDataFunction: ReportSummary.updateSbmpData,
        layerNameByMapId: {
          report: "sbmpProgress",
        },
        tableDataColumns: {
          report: ["count", "impArea"],
        },
        defaultBasemap: {
          report: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportSbmpViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportSbmpViewLegendsBody.njk",
        },
        catchmentViewEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: true,
        reportSummaryEnabled: true,
        hideChartTab: true,
      },

      fcsView: {
        dataModule: "trash",
        kpiApiCall: ReportApiCalls.getFcsKpi,
        layerNameByMapId: {
          report: "fcsProgress",
        },
        tableDataColumns: {
          report: ["count", "pluArea"],
        },
        defaultBasemap: {
          report: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportFcsViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportFcsViewLegendsBody.njk",
        },
        catchmentViewEnabled: false,
        showDefaultLayers: true,
        blueDotEnabled: true,
        hideChartTab: true,
        hideSummaryTab: true,
      },

      muniCatchBasinView: {
        dataModule: "muniCatchBasin",
        kpiApiCall: ReportApiCalls.getMuniCatchBasinKpi,
        summaryTemplate: "report/report/catchBasinSummaryText.html",
        layerNameByMapId: {
          report: FeatureFlag.enabled("annual-report-muni-catch-basin-tko")
            ? "muniCatchBasinReport"
            : "muniCatchBasinProgress",
        },
        tableDataColumns: {
          report: ["count"],
        },
        defaultBasemap: {
          report: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportMuniCatchBasinViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportMuniCatchBasinViewLegendsBody.njk",
        },

        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: true,
        hideCatchmentPopupPriorityLanduse: true,
        hideChartTab: true,
      },

      constructionView: {
        dataModule: "construction",
        kpiApiCall: ReportApiCalls.getConstructionKpi,
        summaryTemplate: Session.isCdot()
          ? "report/report/constructionSummaryTextCdot.njk"
          : "report/report/constructionSummaryText.njk",
        updateDataFunction: ReportSummary.updateConstructionData,
        layerNameByMapId: {
          report: "constructionProjectProgress",
        },
        tableDataColumns: {
          report: ["count"],
        },
        defaultBasemap: {
          report: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportConstructionViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: Session.isCdot()
            ? "report/legend/report/legends/reportConstructionViewLegendsBodyCdot.njk"
            : "report/legend/report/legends/reportConstructionViewLegendsBodyMs4.njk",
        },
        catchmentViewEnabled: false,
        showDefaultLayers: true,
        blueDotEnabled: true,
        hideCatchmentPopupPriorityLanduse: true,
        reportSummaryEnabled: true,
        hideChartTab: true,
      },

      lidView: {
        dataModule: "lid",
        kpiApiCall: ReportApiCalls.getLidKpi,
        layerNameByMapId: {
          report: "lidProjectProgress",
        },
        tableDataColumns: {
          report: ["count"],
        },
        defaultBasemap: {
          report: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportLidViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportLidViewLegendsBody.njk",
        },
        catchmentViewEnabled: false,
        showDefaultLayers: true,
        blueDotEnabled: true,
        hideCatchmentPopupPriorityLanduse: true,
        hideChartTab: true,
        reportSummaryEnabled: true,
        summaryTemplate: "report/report/lidSummaryText.njk",
        updateDataFunction: ReportSummary.updateLidData,
      },

      iddeView: {
        dataModule: "idde",
        kpiApiCall: ReportApiCalls.getIddeKpi,
        summaryTemplate: "report/report/iddeSummaryText.njk",
        updateDataFunction: ReportSummary.updateIddeData,
        layerNameByMapId: {
          report: "incidentProgress",
        },
        tableDataColumns: {
          report: ["count"],
        },
        defaultBasemap: {
          report: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportIddeViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportIddeViewLegendsBody.njk",
        },
        catchmentViewEnabled: false,
        showDefaultLayers: true,
        reportSummaryEnabled: true,
        blueDotEnabled: true,
        hideCatchmentPopupPriorityLanduse: true,
        hideChartTab: true,
      },

      streetSweepingView: {
        dataModule: "swtelr",
        kpiApiCall: ReportApiCalls.getStreetSweepingKpi,
        summaryTemplate: "report/report/streetSweepingSummaryText.njk",
        updateDataFunction: ReportSummary.updateStreetSweepingData,
        summaryDisableYearsExcept: AnnualReports.getMs4Years("stormwater group")
          .sort()
          .filter((year) => year >= 2023),
        layerNameByMapId: {
          report: "tempLayerName",
        },
        legendLayersTemplate: {
          report: "",
        },
        legendLegendsTemplate: {
          report: "",
        },
        defaultBasemap: {
          report: "",
        },
        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: false,
        defaultTab: "summary",
        disabled: Progeny.activeGroupIsRegulator() || SubmitConfig.handleStreetSweepingDisable(),
      },

      watershedView: {
        dataModule: "swtelr",
        layerNameByMapId: {
          report: "tempLayerName",
        },
        defaultBasemap: {
          report: "Gray",
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportWatershedViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportWatershedViewLegendsBody.njk",
        },
        catchmentViewEnabled: false,
        showDefaultLayers: true,
        blueDotEnabled: false,
        hideCatchmentPopupPriorityLanduse: true,
      },

      regionPointView: {
        layerNameByMapId: {
          inputs: "regionPoint",
          report: "regionPoint",
          plan: "regionPoint",
        },
        tableDataReduction: false,
        defaultBasemap: {
          report: "DarkGray",
        },
        catchmentViewEnabled: false,
        progressChartEnabled: false,
        showDefaultLayers: false,
        blueDotEnabled: false,
        legendLayersTemplate: null,
        legendLegendsTemplate: null,
      },
      regionAreaView: {
        layerNameByMapId: {
          inputs: "regional",
          report: "regional",
          plan: "regional",
        },
        tableDataReduction: false,
        defaultBasemap: {
          report: "DarkGray",
        },
        catchmentViewEnabled: false,
        progressChartEnabled: true,
        showDefaultLayers: false,
        blueDotEnabled: false,
        legendLayersTemplate: null,
        legendLegendsTemplate: null,
      },

      publicView: {
        dataModule: "public",
        kpiApiCall: ReportApiCalls.getActivityKpi,
        summaryTemplate: "report/report/peoSummaryText.njk",
        layerNameByMapId: {
          report: "tempLayerName",
        },
        legendLayersTemplate: {
          report: "",
        },
        legendLegendsTemplate: {
          report: "",
        },
        defaultBasemap: {
          report: "",
        },
        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: false,
        defaultTab: "summary",
        disabled: Progeny.activeGroupIsRegulator(),
        hideProgressTab: true,
        hideChartTab: true,
      },

      assetManagementView: {
        dataModule: "asset management",
        kpiApiCall: ReportApiCalls.getAssetManagentData,
        summaryTemplate: "report/report/assetManagementSummaryText.njk",
        summaryDisableYearsExcept: [2021],
        layerNameByMapId: {
          report: "tempLayerName",
        },
        legendLayersTemplate: {
          report: "",
        },
        legendLegendsTemplate: {
          report: "",
        },
        defaultBasemap: {
          report: "",
        },
        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: false,
        defaultTab: "summary",
        disabled: Progeny.activeGroupIsRegulator(),
      },

      indCommView: {
        dataModule: "indcom",
        kpiApiCall: ReportApiCalls.getFacilityKpi,
        summaryTemplate:
          // Keeping both versions
          AnnualReports.getSelectedYear(true) <= 2021 && Session.isSalinas()
            ? "report/report/indCommSummaryText.njk"
            : "report/report/indCommSummaryTextNew.njk",
        layerNameByMapId: {
          report: "tempLayerName",
        },
        legendLayersTemplate: {
          report: "",
        },
        legendLegendsTemplate: {
          report: "",
        },
        defaultBasemap: {
          report: "",
        },
        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: false,
        defaultTab: "summary",
        disabled: Progeny.activeGroupIsRegulator(),
        hideProgressTab: true,
        hideChartTab: true,
      },

      muniFacilityView: {
        dataModule: "muni facility",
        kpiApiCall: ReportApiCalls.getFacilityKpi,
        summaryTemplate: "report/report/muniFacilitySummaryText.njk",
        summaryDisableYearsExcept: AnnualReports.getMs4Years("stormwater group").filter(
          (year) => year >= 2023,
        ),
        layerNameByMapId: {
          report: "tempLayerName",
        },
        legendLayersTemplate: {
          report: "",
        },
        legendLegendsTemplate: {
          report: "",
        },
        defaultBasemap: {
          report: "",
        },
        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: false,
        defaultTab: "summary",
        disabled: Progeny.activeGroupIsRegulator(),
        hideProgressTab: true,
        hideChartTab: true,
      },

      muniBmpView: {
        dataModule: "muni bmp",
        kpiApiCall: ReportApiCalls.getMuniBmpKpi,
        summaryTemplate: "report/report/muniBmpSummaryText.njk",
        updateDataFunction: ReportSummary.updateMuniBmpData,
        summaryDisableYearsExcept: AnnualReports.getMs4Years("stormwater group").filter(
          (year) => year >= 2023,
        ),
        layerNameByMapId: {
          report: "tempLayerName",
        },
        legendLayersTemplate: {
          report: "",
        },
        legendLegendsTemplate: {
          report: "",
        },
        defaultBasemap: {
          report: "",
        },
        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: false,
        defaultTab: "summary",
        disabled: Progeny.activeGroupIsRegulator(),
        hideProgressTab: true,
        hideChartTab: true,
      },

      outfallView: {
        dataModule: "muni outfall",
        kpiApiCall: ReportApiCalls.getMuniOutfallKpi,
        summaryTemplate: "report/report/outfallSummaryText.njk",
        layerNameByMapId: {
          report: "outfallProgress",
        },
        tableDataColumns: {
          report: ["count"],
        },
        legendLayersTemplate: {
          report: "report/legend/report/layers/reportOutfallsViewLayersBody.njk",
        },
        legendLegendsTemplate: {
          report: "report/legend/report/legends/reportOutfallViewLegendsBody.njk",
        },
        defaultBasemap: {
          report: "",
        },
        catchmentViewEnabled: false,
        reportSummaryEnabled: true,
        showDefaultLayers: true,
        blueDotEnabled: false,
        hideChartTab: true,
      },
    };
  };

  return {
    get,
  };
};

module.exports = DataViewConfig();

const Session = require("../../login/session");
const Progeny = require("../../login/progeny");
const ReportApiCalls = require("../reportApiCalls");
const ReportSummary = require("../report/reportSummary");
const FeatureFlag = require("../../settings/featureFlag");
const AnnualReports = require("../annualReports");
const SubmitConfig = require("./submitConfig");
