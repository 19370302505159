"use strict";

const RoutineMaintenanceStructuralCondition = function () {
  const stringKey = "structural-condition";
  const tabHeader = "Structural Condition";
  var $page;

  var loadListeners = function () {
    $page = $(`#assessment-modal #${stringKey}`);
    $page.on(
      "2N:FormInput",
      `[name="inlets"], [name="outlets"]`,
      RoutineMaintenanceGeneralInfo.onSaveTypeSepecificInfo,
    );
    RoutineMaintenanceFormTable.loadListeners($page);
  };

  var unloadListeners = function () {
    RoutineMaintenanceFormTable.unloadListeners($page);
    $page.off(
      "2N:FormInput",
      `[name="inlets"], [name="outlets"]`,
      RoutineMaintenanceGeneralInfo.onSaveTypeSepecificInfo,
    );
  };

  var getProps = function () {
    return {};
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  return {
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = RoutineMaintenanceStructuralCondition();

const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const RoutineMaintenanceGeneralInfo = require("./routineMaintenanceGeneralInfo");
