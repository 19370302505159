class OfflineImporter {
  static async import(json) {
    const resourcesByKey = ResourceController.getAll();
    const resourceDataByKey = JSON.parse(json, OfflineImporter._reviver);

    for (const key in resourcesByKey) {
      const resource = resourcesByKey[key];
      const data = resourceDataByKey[key];
      await OfflineImporter._setResourceData(resource, data);
    }

    return resourceDataByKey;
  }

  static async _setResourceData(resource, data) {
    const stores = resource._getCachedObjectStores();

    for (const name in data) {
      if (name in stores) {
        await stores[name].replaceAll(data[name]);
      }
    }
  }

  static _reviver(key, value) {
    for (const { string, parse } of OfflineExporter.typeConversions) {
      if (Array.isArray(value) && value[0] === string) {
        return parse(value[1]);
      }
    }

    if (typeof value === "string" && value.startsWith(OfflineExporter.fileId)) {
      const typeEnd = 19 + parseInt(value.slice(16, 19));
      const type = value.slice(19, typeEnd);
      return OfflineImporter._decodeBase64(value.slice(typeEnd), type);
    }

    return value;
  }

  static _decodeBase64(base64, type) {
    // https://stackoverflow.com/a/73181046/613512
    const unitArray = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));

    return new Blob([unitArray], { type });
  }
}

module.exports = OfflineImporter;

const ResourceController = require("../offline/resourceController");
const OfflineExporter = require("./offlineExporter");
