"use strict";

const ScheduleTicker = function () {
  const QUARTER_WIDTH = 25; //width percentage of a single 'quarter' default to 25
  const DAYS_IN_MONTH = 30;
  const MONTHS_IN_QUARTER = 3;
  const INDEX_OFFSET = 1; //offeset for date month object
  const MARKER_MARGIN_OFFSET = "-11px";

  var dueMarginPercentage;
  var nowMarginPercentage;

  var shiftLabels = function (parentSelector) {
    var monthIndex = getTimelineEndMonthIndex();
    var monthsLabelArray = $(parentSelector + " .month-label").toArray();
    var quarterLabelArray = $(parentSelector + " .quarter-label").toArray();
    var quarterEndIndex = Math.floor(monthIndex / MONTHS_IN_QUARTER);
    var quarterStartIndex = (quarterEndIndex + 1) % 4;
    var monthStartIndex = quarterStartIndex * MONTHS_IN_QUARTER;
    var shiftedMonthLabelArray = monthsLabelArray.slice(monthStartIndex);
    shiftedMonthLabelArray = shiftedMonthLabelArray.concat(
      monthsLabelArray.slice(0, monthStartIndex),
    );

    const year = getTimelineEndYear();
    for (let i = 0; i < 4; i++) {
      const currentQuarterIndex = quarterStartIndex + i;
      const currentQuarter = (currentQuarterIndex % 4) + 1;
      var currentYear = year;
      if (quarterEndIndex < 3) {
        currentYear = currentQuarterIndex - 3 <= 0 ? year - 1 : year;
      }

      quarterLabelArray[i].textContent = `Q${currentQuarter} ${currentYear}`;
    }
    $(parentSelector + " .month-div").html(shiftedMonthLabelArray);
    $(parentSelector + " .quarter-div").html(quarterLabelArray);
  };

  var setTodayMarker = function (parentSelector) {
    const today = new Date(Date.now());
    const month = today.getMonth();
    const date = today.getDate();
    const quarter = Math.ceil((month + INDEX_OFFSET) / MONTHS_IN_QUARTER);
    const year = today.getFullYear();
    const quarterWidth = parentSelector == "#progressSummary" ? 20 : QUARTER_WIDTH;
    const quarterPercentage = month % MONTHS_IN_QUARTER;
    const dayPercentage = Math.min(date, DAYS_IN_MONTH) / DAYS_IN_MONTH;
    const quarterOffset = $(parentSelector + " .quarter-label").map(function (index) {
      if (this.innerText.split(" ")[0] == "Q" + quarter && this.innerText.split(" ")[1] == year) {
        return index;
      }
    });

    //if today is out of range, don't display
    if (!quarterOffset.length) {
      $(".arrow-down-now").hide();
    } else {
      nowMarginPercentage =
        quarterOffset[0] * QUARTER_WIDTH +
        ((quarterPercentage + dayPercentage) / MONTHS_IN_QUARTER) * quarterWidth;
      $(parentSelector + " .arrow-down-now").css(
        "margin-left",
        "calc(" + nowMarginPercentage + "% + " + MARKER_MARGIN_OFFSET + ")",
      );
    }
  };

  var setDueMarker = function (parentSelector) {
    var reportRecord = getTimelineReport();
    if (reportRecord) {
      var dueDate = new Date(reportRecord.deadline);
      var dueMonth = dueDate.getMonth();
      var dueYear = dueDate.getFullYear();
      var dueQuarter = Math.ceil((dueMonth + INDEX_OFFSET) / MONTHS_IN_QUARTER);
      var quarterPercentage = dueMonth % MONTHS_IN_QUARTER;
      var dayPercentage = Math.min(dueDate.getDate(), DAYS_IN_MONTH) / DAYS_IN_MONTH;
      var quarterOffset = $(parentSelector + " .quarter-label").map(function (index) {
        if (
          this.innerText.split(" ")[0] == "Q" + dueQuarter &&
          this.innerText.split(" ")[1] == dueYear
        ) {
          return index;
        }
      });
      //if due date is out of range, don't display
      if (!quarterOffset.length) {
        $(parentSelector + " .arrow-down-due").hide();
      } else {
        dueMarginPercentage =
          quarterOffset[0] * QUARTER_WIDTH +
          ((quarterPercentage + dayPercentage) / MONTHS_IN_QUARTER) * QUARTER_WIDTH; //current quarter width is 20%
        $(parentSelector + " .arrow-down-due").css(
          "margin-left",
          "calc(" + dueMarginPercentage + "% + " + MARKER_MARGIN_OFFSET + ")",
        );
      }
    }
  };

  var setMarkerGap = function (parentSelector) {
    var $dueMarker = $(parentSelector).find(".arrow-down-due");
    var $nowMarker = $(parentSelector).find(".arrow-down-now");
    var $markerGap = $(parentSelector).find(".marker-gap");

    if (
      $dueMarker.is(":visible") &&
      $nowMarker.is(":visible") &&
      !isNaN(dueMarginPercentage) &&
      !isNaN(nowMarginPercentage)
    ) {
      const percentDiff = Math.abs(dueMarginPercentage - nowMarginPercentage);

      if (nowMarginPercentage < dueMarginPercentage) {
        $markerGap.attr("style", $nowMarker.attr("style"));
      } else {
        $markerGap.attr("style", $dueMarker.attr("style"));
      }
      $markerGap.css("width", `${percentDiff}%`);
    }
  };

  var handleTimelineDisplay = function (parentSelector) {
    var reportRecord = getTimelineReport();
    var daysToGo = SubmitReport.calcDaysToGo(reportRecord.deadline);
    var submittedReport = SubmitReport.getLatestSubmittedReport();
    var reportingStatus = SubmitReport.getReportingStatus(reportRecord, submittedReport, daysToGo);
    var timelineText;

    $(".ticker-container").removeClass("blue-text red-text blurred-timeline red-gap");

    if (reportingStatus === "submitted_locked") {
      timelineText = "Locked " + submittedReport.displaySubmittedOn;
      $(".ticker-container").addClass("blue-text blurred-timeline");
    } else if (reportingStatus === "not_submitted_locked") {
      timelineText = "Annual Report Not Submitted";
      $(".ticker-container").addClass("blue-text blurred-timeline");
    } else if (reportingStatus === "past_due") {
      $(".ticker-container").addClass("red-text red-gap");
      timelineText = Math.abs(daysToGo) + " Days Past Due";
    } else if (reportingStatus === "due_soon") {
      $(".ticker-container").addClass("red-text red-gap");
      timelineText = daysToGo + " Days to Go";
    } else {
      timelineText = daysToGo + " Days to Go";
    }

    $(parentSelector + " .timeline-text").text(timelineText);
  };

  var loadTimeline = function () {
    $(".ticker-container").html(nunjucks.render("report/submit/scheduleTickerContents.njk"));
  };

  var setTimeline = function (parentDiv, screen) {
    if (!getTimelineReport()) {
      return;
    }

    if (screen !== "summary") {
      shiftLabels(parentDiv);
      setTodayMarker(parentDiv);
      setDueMarker(parentDiv);
      handleTimelineDisplay(parentDiv);
      setMarkerGap(parentDiv);
    }
  };

  var getTimelineReport = function () {
    return SubmitReport.getTargetReport();
  };

  var getTimelineEndMonthIndex = function () {
    const deadline = new Date(getTimelineReport()?.deadline);
    return deadline?.getMonth();
  };

  var getTimelineEndYear = function () {
    const deadline = new Date(getTimelineReport()?.deadline);
    return deadline?.getFullYear();
  };

  return {
    setTimeline,
    loadTimeline,
    handleTimelineDisplay,
  };
};

module.exports = ScheduleTicker();

const SubmitReport = require("./submitReport");
