"use strict";

const InsightWidget = require("./insightWidget");

const InsightWidgetHorizontalBarChart = function (widgetConfig) {
  if (!widgetConfig.hovers) {
    widgetConfig.hovers = [
      {
        className: "inner-chart-group",
        getHoverHtml: InsightWidgetHorizontalBarChart.prototype.getHoverHtml,
      },
    ];
  }

  if (!widgetConfig.size) {
    widgetConfig.size = 1;
  }

  if (!widgetConfig.barWidth) {
    widgetConfig.barWidth = 11;
  }

  InsightWidget.call(this, widgetConfig);
  this.widgetConfig = widgetConfig;
};

InsightWidgetHorizontalBarChart.prototype = Object.create(InsightWidget.prototype);
InsightWidgetHorizontalBarChart.prototype.constructor = InsightWidgetHorizontalBarChart;

InsightWidgetHorizontalBarChart.prototype.getHtml = function (insightData) {
  var title = this.widgetConfig.title;

  var html = nunjucks.render("insight/widgets/horizontalBarChart.njk", {
    title,
  });

  return html;
};

InsightWidgetHorizontalBarChart.prototype.renderChart = function (
  insightData,
  exportModal = false,
) {
  var chartContainerSelector = `[data-widget-id="${this.widgetId}"] .chart-container`;
  if (exportModal) {
    chartContainerSelector = "#insight-widget-export-modal " + chartContainerSelector;
  }

  var $chartContainer = $(chartContainerSelector);
  $chartContainer.empty();

  const defaults = this.getDefaultChartDimensions();
  const containerWidth = $chartContainer.width();
  const containerHeight = defaults.containerHeight + 20;

  var margin = defaults.margin;
  margin.left += 40;

  const width = containerWidth - margin.left - margin.right;
  const height = containerHeight - margin.top - margin.bottom;

  var chartData = this.widgetConfig.getChartData(insightData);
  var legendData = this.widgetConfig.legendData();

  const extraMargin = 10;
  var svg = d3
    .select(chartContainerSelector)
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom + extraMargin + 8);

  this.appendStandardChartLegend(svg, legendData);

  var chartGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top + extraMargin})`);

  var scaleY = d3
    .scaleBand()
    .domain(chartData.map((item) => item.key))
    .range([0, height])
    .padding(0.4);

  var innerChartGroup = chartGroup.append("g").attr("class", "inner-chart-group");

  innerChartGroup
    .append("rect")
    .attr("class", "hover-background-rect")
    .attr("display", function (d) {
      return "block";
    })
    .attr("x", 0)
    .attr("y", function (d) {
      return 0;
    })
    .attr("width", function (d) {
      return width;
    })
    .attr("height", function (d) {
      return height;
    })
    .attr("fill", "transparent");

  var barGroups = innerChartGroup
    .selectAll()
    .data(chartData)
    .enter()
    .append("g")
    .attr("class", "bar-group")
    .attr("data-hover-index", function (d, i) {
      return i;
    })
    .attr("data-type", function (d, i) {
      return d.key;
    });

  const subGroupPadding = 2;
  const barGroupWidth = this.widgetConfig.barWidth
    ? this.widgetConfig.barWidth * 2 + subGroupPadding
    : scaleY.bandwidth();
  barGroups
    .append("rect")
    // .attr("class", "hover-background-rect")
    .attr("y", function (d) {
      return scaleY(d.key);
    })
    .attr("x", function (d) {
      return 0;
    })
    .attr("height", barGroupWidth)
    .attr("width", function (d) {
      return width;
    })
    .attr("fill", "transparent");

  const twoLineLabel = this.widgetConfig.twoLineLabel;
  var axisY = d3.axisLeft(scaleY).tickFormat(function (d) {
    return "";
  });

  chartGroup
    .append("g")
    .attr("class", "axis axis-y")
    .attr("transform", "translate(0, 0)")
    .call(axisY)
    .selectAll("text")
    .attr("y", function (d) {
      if (twoLineLabel) {
        return 8;
      } else {
        return 4;
      }
    })
    .selectAll("tspan")
    .data(function (d) {
      const displayName = chartData.find((item) => item.key === d)?.displayName;
      if (twoLineLabel && displayName.includes(" ")) {
        const index = displayName.indexOf(" ");
        const line1 = displayName.slice(0, index);
        const line2 = displayName.slice(index + 1, displayName.length);
        return [line1, line2];
      } else {
        return [displayName, ""];
      }
    })
    .enter()
    .append("tspan")
    .attr("x", -10)
    .attr("dy", function (d, i) {
      return 2 * i - 1 + "em";
    })
    .text(String);

  var extentX = d3.extent(chartData, function (d) {
    return Math.max(d.focusValue || 0, d.comparisonValue || 0);
  });
  const maxX = extentX[1] === 0 ? 10 : Math.ceil(extentX[1] * 1.25);

  var scaleX = d3.scaleLinear().domain([0, maxX]).range([0, width]);

  var axisX = d3.axisBottom(scaleX).ticks(5).tickSize(6).tickPadding(10).tickSizeInner(-height);

  chartGroup
    .append("g")
    .attr("class", "axis")
    .attr("transform", "translate(0," + height + ")")
    .call(axisX);

  const unit = this.widgetConfig.unit;
  chartGroup
    .append("text")
    .attr("class", "unit-label")
    .attr("text-anchor", "end")
    .attr("y", height + 30)
    .attr("x", width - 45)
    .attr("fill", "#979797")
    .text(`Unit: ${unit}`);

  this.drawDataBars("comparison", chartData, barGroups, scaleX, scaleY, width);
  this.drawDataBars("focus", chartData, barGroups, scaleX, scaleY, width);
};

InsightWidgetHorizontalBarChart.prototype.drawDataBars = function (
  dataPath,
  dataArray,
  barGroups,
  scaleX,
  scaleY,
  width,
) {
  const subGroupPadding = 2;
  const barWidth = this.widgetConfig.barWidth
    ? this.widgetConfig.barWidth
    : (scaleY.bandwidth() - subGroupPadding) / 2;
  const legendData = this.widgetConfig.legendData();

  barGroups
    .append("rect")
    .data(dataArray)
    .attr("y", function (d) {
      if (dataPath === "focus") {
        return scaleY(d.key);
      } else {
        return scaleY(d.key) + barWidth + subGroupPadding;
      }
    })
    .attr("x", 0)
    .attr("height", barWidth)
    .attr("width", function (d) {
      const value = dataPath === "focus" ? d.focusValue : d.comparisonValue;
      if (value !== null && value !== undefined) {
        return scaleX(value);
      }
      return 0;
    })
    .attr("fill", function (d) {
      const legendKey = dataPath === "focus" ? "current" : "potential";

      if (legendData[legendKey]?.color) {
        return legendData[legendKey].color;
      }

      return d.color;
    });

  barGroups
    .append("text")
    .data(dataArray)
    .attr("class", "bar")
    .attr("text-anchor", "start")
    .attr("y", function (d) {
      if (dataPath === "focus") {
        return scaleY(d.key) + barWidth / 2 + 4;
      } else {
        return scaleY(d.key) + barWidth + barWidth / 2 + subGroupPadding + 4;
      }
    })
    .attr("x", function (d) {
      const value = dataPath === "focus" ? d.focusValue : d.comparisonValue;
      if (value !== null && value !== undefined) {
        return scaleX(value) + 4;
      }
      return width;
    })
    .text(function (d) {
      if (d.empty) {
        return "";
      } else {
        const value = dataPath === "focus" ? d.focusValue : d.comparisonValue;
        if (value !== null && value !== undefined) {
          return InsightWidgetFunctions.formatNumber(value);
        }
        return "—";
      }
    });
};

InsightWidgetHorizontalBarChart.prototype.getHoverHtml = function (insightData, $hoverTarget) {
  const hoverData = this.widgetConfig.getHoverData(insightData);
  const template = hoverData.template;

  var html = nunjucks.render(template, {
    ...hoverData,
  });

  return html;
};

module.exports = InsightWidgetHorizontalBarChart;

const d3 = require("d3-v6");
const InsightWidgetFunctions = require("./insightWidgetFunctions");
