"use strict";

const UserManagementFilters = function () {
  const searchFields = ["full_name", "email"];
  let smartFilters;

  var filterOffline = function (unfilteredData) {
    return smartFilters.filter(unfilteredData);
  };

  var init = function ($container) {
    smartFilters?.destroy();

    smartFilters = new SmartFilters(
      {
        search: (datum, filters) =>
          ResourceFilters.passSearchStringFilter(datum, filters, searchFields),
        roles: "role",
        status: "status",
        tools: ["tools", "array"],
      },
      { treePath: "userFilters" },
    );
    smartFilters.init($container);
  };

  return {
    init,
    filterOffline,
  };
};

module.exports = UserManagementFilters();

const ResourceFilters = require("../../filters/resourceFilters");
const SmartFilters = require("../../mapFunctions/smartFilters");
