"use strict";

const MuniCatchBasinInsightDashboardController = function () {
  var $insightContainer;
  var inited = false;
  var controller = null;

  var reports = [];
  var reportingYears;

  var cofOptions;
  var responsiblePartyMaintenanceOptions;
  var allMaintenanceTasks;
  var spatialDataOptions;
  var spatialDataTitle;

  var focusYear;
  var comparisonYear;
  var currentReportingYear;
  var cof;
  var responsiblePartyMaintenance;
  var spatialItem;

  const colors = {
    "very-high": "#333333",
    high: "#4D4D4D",
    moderate: "#666666",
    low: "#808080",
    "very-low": "#999999",
    unknown: "#202020",
  };
  const cofOrders = ["very-high", "high", "moderate", "low", "very-low", "unknown"];

  var init = async function (reInitialize = false) {
    if (!inited || reInitialize) {
      await loadReportingYears();

      $insightContainer = $(".insight-container");

      cof = "total";
      responsiblePartyMaintenance = "our-agency";
      spatialItem = "total";

      cofOptions = [
        { name: "All", value: "total" },
        { name: "Very High", value: "very-high" },
        { name: "High", value: "high" },
        { name: "Moderate", value: "moderate" },
        { name: "Low", value: "low" },
        { name: "Very Low", value: "very-low" },
        { name: "Unknown", value: "unknown" },
      ];

      responsiblePartyMaintenanceOptions =
        BmpFcsInventoryConstants.responsiblePartyMaintenanceOptions.slice();
      responsiblePartyMaintenanceOptions.unshift({ name: "All", value: "total" });

      allMaintenanceTasks = BmpFcsInventoryConstants.getAllMaintenanceTasks();
      spatialDataOptions = InsightWidgetFunctions.getSpatialDataOptions();
      spatialDataTitle = InsightWidgetFunctions.getSpatialDataTitle();

      loadDomListeners();

      var widgetCatchBasinCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Catch Basins",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["catchBasinCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total # of Catch Basins`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of completed or certified catch basins between the comparison and focus reporting years";
        },
      });

      var widgetSiteVisitCount = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total # of Site Visits",
        neutralTrendColor: true,
        dataDecimalPlaces: 0,
        getDataUnit: () => "",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["siteVisitCount"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total # of Site Visits`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of site visits between the comparison and focus reporting years";
        },
      });

      var widgetSiteVisitCountByCof = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "# of Site Visits by Consequence of Failure",
        excludeZeros: true,
        neutralTrendColor: true,
        typeCountLimit: 4,
        getDataArray: (insightData, dataPath) => {
          const dataArray = insightData[dataPath]["siteVisitCountByCof"];
          InsightWidgetFunctions.sortDataArrayByKey(dataArray, cofOrders);
          dataArray.forEach((item) => {
            item.displayName = cofOptions.getObjectWith("value", item.key)?.name;
          });
          return dataArray;
        },
        getYearByDataPath,
        getSelectedType: getSelectedCof,
        getHoverTitle: (insightData, hoverType) => {
          const matchedItem = cofOptions.find((item) => item.value === hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `${focusYear} & ${comparisonYear} <br/> ${displayName}`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of site visits between the comparison and focus reporting years";
        },
      });

      var widgetMonthlySiteVisitCount = new InsightWidgetMonthlyCountChart({
        size: 4,
        sizeSm: 2,
        title: "Cumulative Total # of Site Visits by Month",
        neutralTrendColor: true,
        getDataArray: (insightData, dataPath) => {
          const monthlyData = insightData[dataPath]["monthlySiteVisitCount"];
          InsightWidgetFunctions.handleMonthlyDataDisplay(monthlyData);
          return monthlyData;
        },
        getYearByDataPath,
        getHoverTitle: (hoverIndex) => {
          const monthIndex = hoverIndex + 1;

          return `${focusYear} & ${comparisonYear} Month ${monthIndex} <br/> Total # of Site Visits`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total number of site visits between the comparison and focus reporting years";
        },
        getNotes: () => {
          const focusReport = reports.find(
            (report) => report.reportingYear === parseInt(focusYear),
          );

          return `*${focusReport.displayDataRangeFirst} is the start of the focus reporting year. ${focusReport.displayDataRangeLast} is the end of the focus reporting year.`;
        },
      });

      var widgetDebrisRemoved = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Total Debris Removed",
        upGreenDownRed: true,
        getDataUnit: () => "cu-ft",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["debrisRemoved"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Total Debris Removed`;
        },
        getHoverDescription: () => {
          return "The percent difference in the total debris removed between the comparison and focus reporting years";
        },
      });

      var widgetDebrisRemovedByCof = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "Debris Removed by Consequence of Failure",
        excludeZeros: true,
        upGreenDownRed: true,
        typeCountLimit: 4,
        dataDecimalPlaces: 1,
        getDataArray: (insightData, dataPath) => {
          const dataArray = insightData[dataPath]["debrisRemovedByCof"];
          InsightWidgetFunctions.sortDataArrayByKey(dataArray, cofOrders);
          dataArray.forEach((item) => {
            item.displayName = cofOptions.getObjectWith("value", item.key)?.name;
          });
          return dataArray;
        },
        getYearByDataPath,
        getSelectedType: getSelectedCof,
        getHoverTitle: (insightData, hoverType) => {
          const matchedItem = cofOptions.find((item) => item.value === hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `${focusYear} & ${comparisonYear} <br/> ${displayName}`;
        },
        getHoverDescription: () => {
          return "The percent difference in the debris removed between the comparison and focus reporting years";
        },
      });

      var widgetDebrisRemovedPerSiteVisit = new InsightWidgetSimpleNumber({
        size: 1,
        title: "Debris Removed Per Site Visit",
        upGreenDownRed: true,
        getDataUnit: () => "cu-ft/visit",
        getDataNumber: (insightData, dataPath) => {
          return insightData[dataPath]["debrisRemovedPerSiteVisit"];
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: () => {
          return `${focusYear} & ${comparisonYear} <br/> Debris Removed Per Site Visit`;
        },
        getHoverDescription: () => {
          return "The percent difference in the debris removed per site visit between the comparison and focus reporting years";
        },
      });

      var widgetDebrisRemovedPerSiteVisitByCof = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "Debris Removed Per Site Visit by Consequence of Failure",
        excludeZeros: true,
        upGreenDownRed: true,
        typeCountLimit: 4,
        dataDecimalPlaces: 1,
        getDataArray: (insightData, dataPath) => {
          const dataArray = insightData[dataPath]["debrisRemovedPerSiteVisitByCof"];
          InsightWidgetFunctions.sortDataArrayByKey(dataArray, cofOrders);
          dataArray.forEach((item) => {
            item.displayName = cofOptions.getObjectWith("value", item.key)?.name;
          });
          return dataArray;
        },
        getYearByDataPath,
        getSelectedType: getSelectedCof,
        getHoverTitle: (insightData, hoverType) => {
          const matchedItem = cofOptions.find((item) => item.value === hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `${focusYear} & ${comparisonYear} <br/> ${displayName}`;
        },
        getHoverDescription: () => {
          return "The percent difference in the debris removed per site visit between the comparison and focus reporting years";
        },
      });

      var widgetTopCorrectiveActionCount = new InsightWidgetTopList({
        size: 2,
        title: "Top 5 Corrective Actions",
        getDataArray: (insightData, dataPath) => {
          const dataArray = insightData[dataPath]["topCorrectiveActionCount"];
          dataArray.forEach((item) => {
            if (item.key) {
              item.displayName = MaintenanceResult.getIssueNameByKey(item.key, allMaintenanceTasks);
            }
          });

          return dataArray;
        },
        getSubtitle: (insightData, dataPath) => {
          return getYearByDataPath(dataPath);
        },
        getHoverTitle: (insightData, hoverType) => {
          const displayName = MaintenanceResult.getIssueNameByKey(hoverType, allMaintenanceTasks);

          return `${displayName} <br/>Corrective Action Ranking`;
        },
      });

      var widgetCorrectiveActionNeededByCof = new InsightWidgetCountByTypeChart({
        size: 2,
        title: "Total Corrective Actions Identified by Consequence of Failure",
        excludeZeros: true,
        typeCountLimit: 4,
        dataDecimalPlaces: 0,
        getDataArray: (insightData, dataPath) => {
          const dataArray = insightData[dataPath]["correctiveActionNeededCountByCof"];
          InsightWidgetFunctions.sortDataArrayByKey(dataArray, cofOrders);
          dataArray.forEach((item) => {
            item.displayName = cofOptions.getObjectWith("value", item.key)?.name;
          });
          return dataArray;
        },
        getYearByDataPath,
        getSelectedType: getSelectedCof,
        getHoverTitle: (insightData, hoverType) => {
          const matchedItem = cofOptions.find((item) => item.value === hoverType);
          const displayName = matchedItem?.name || hoverType;

          return `${focusYear} & ${comparisonYear} <br/> ${displayName}`;
        },
        getHoverDescription: () => {
          return "The percent difference in the # of corrective actions identified between the comparison and focus reporting years";
        },
      });

      var widgetCatchBasinCountByCof = new InsightWidgetTwoPies({
        size: 2,
        title: "Consequence of Failure",
        onePie: true,
        wholeNumber: true,
        onePieLabel: currentReportingYear,
        hideTotal: true,
        legendCountPerRow: 5,
        customSort: true,
        legendData: () => {
          return {
            "very-high": {
              label: "Very High",
              color: colors["very-high"],
            },
            high: {
              label: "High",
              color: colors["high"],
            },
            moderate: {
              label: "Moderate",
              color: colors["moderate"],
            },
            low: {
              label: "Low",
              color: colors["low"],
            },
            "very-low": {
              label: "Very Low",
              color: colors["very-low"],
            },
            unknown: {
              label: "Unknown",
              color: colors["unknown"],
            },
          };
        },
        getChartData: (insightData) => {
          const dataArray = insightData["current"].catchBasinCountByCof;
          InsightWidgetFunctions.sortDataArrayByKey(dataArray, cofOrders);

          return [
            {
              hoverKey: "current",
              label: "",
              unit: "",
              slices: dataArray,
            },
          ];
        },
        getHoverData: (insightData) => {
          const template = "insight/widgets/simplePieHover.njk";

          const dataArray = insightData["current"].catchBasinCountByCof;
          InsightWidgetFunctions.sortDataArrayByKey(dataArray, cofOrders);

          const totalValue = dataArray.reduce(function (a, b) {
            return a + b.value;
          }, 0);

          const table = dataArray.map((item) => {
            return [
              { legendColor: colors[item.key], text: item.key },
              InsightWidgetFunctions.formatPercentage(item.value, totalValue),
            ];
          });
          table.unshift([{ text: "", colspan: 2 }]);

          const tablesData = [table];

          return {
            current: {
              template,
              title: `${currentReportingYear} Consequence of Failure`,
              tablesData,
            },
          };
        },
      });

      var widgetCorrectiveActionCompletedPercentByYear = new InsightWidgetPercentByYearBarChart({
        separateBars: true,
        size: 2,
        title: "Corrective Actions Needed & Completed",
        upGreenDownRed: true,
        getDataArray: (insightData) => {
          return [
            {
              key: focusYear,
              value: insightData["focus"].correctiveActionCompletedCount,
              total: insightData["focus"].correctiveActionNeededCount,
              displayName: focusYear,
            },
            {
              key: comparisonYear,
              value: insightData["comparison"].correctiveActionCompletedCount,
              total: insightData["comparison"].correctiveActionNeededCount,
              displayName: comparisonYear,
            },
          ];
        },
        legendData: () => {
          return {
            focusTotal: {
              label: `Needed ${focusYear}`,
              color: "#A4B6C2",
            },
            comparisonTotal: {
              label: `Needed ${comparisonYear}`,
              color: "#CCCCCC",
            },
            focusValue: {
              label: `Completed ${focusYear}`,
              color: "#1B4966",
            },
            comparisonValue: {
              label: `Completed ${comparisonYear}`,
              color: "#808080",
            },
          };
        },
        getHoverTitle: (hoverIndex) => {
          return hoverIndex === 0 ? focusYear : comparisonYear;
        },
        getHoverDescription: () => {
          return "The percent of corrective actions completed";
        },
      });

      var widgetSiteVisitCleanedPercentByYear = new InsightWidgetPercentByYearBarChart({
        size: 2,
        title: "Proportion of Visits That Required Cleaning",
        neutralTrendColor: true,
        getDataArray: (insightData) => {
          return [
            {
              key: focusYear,
              value: insightData["focus"].cleanSiteVisitCount,
              total: insightData["focus"].siteVisitCount,
              displayName: focusYear,
            },
            {
              key: comparisonYear,
              value: insightData["comparison"].cleanSiteVisitCount,
              total: insightData["comparison"].siteVisitCount,
              displayName: comparisonYear,
            },
          ];
        },
        legendData: () => {
          return {
            focusTotal: {
              label: `Visited ${focusYear}`,
              color: "#A4B6C2",
            },
            comparisonTotal: {
              label: `Visited ${comparisonYear}`,
              color: "#CCCCCC",
            },
            focusValue: {
              label: `Cleaned ${focusYear}`,
              color: "#1B4966",
            },
            comparisonValue: {
              label: `Cleaned ${comparisonYear}`,
              color: "#808080",
            },
          };
        },
        getHoverTitle: (hoverIndex) => {
          return hoverIndex === 0 ? focusYear : comparisonYear;
        },
        getHoverDescription: () => {
          return "The percent of visits that required cleaning";
        },
      });

      var config = {
        getInsightDataFunction: ApiCalls.getMuniCatchBasinInsight,
        widgets: [
          widgetCatchBasinCount,
          widgetCatchBasinCountByCof,
          widgetSiteVisitCount,
          widgetSiteVisitCountByCof,
          widgetMonthlySiteVisitCount,
          widgetSiteVisitCleanedPercentByYear,
          widgetDebrisRemoved,
          widgetDebrisRemovedByCof,
          widgetDebrisRemovedPerSiteVisit,
          widgetDebrisRemovedPerSiteVisitByCof,
          widgetTopCorrectiveActionCount,
          widgetCorrectiveActionNeededByCof,
          widgetCorrectiveActionCompletedPercentByYear,
        ],
        hasFilter: true,
        getFilterMenuHtmlFunction,
        saveFilterFunction,
        getMobileFilterButtonTextFunction,
      };

      controller = new InsightDashboardController(config);
      controller.init();
      inited = true;
      render();
    }
  };

  var loadDomListeners = function () {
    $insightContainer.off("change", ".year-dropdown", onYearChange);
    $insightContainer.on("change", ".year-dropdown", onYearChange);

    $insightContainer.off("change", `[name="cof"]`, onCofChange);
    $insightContainer.on("change", `[name="cof"]`, onCofChange);

    $insightContainer.off("change", `[name="spatial-item"]`, onSpatialItemChange);
    $insightContainer.on("change", `[name="spatial-item"]`, onSpatialItemChange);

    $insightContainer.off(
      "change",
      `[name="responsiblePartyMaintenance"]`,
      onReponsiblePartyMaintenanceChange,
    );
    $insightContainer.on(
      "change",
      `[name="responsiblePartyMaintenance"]`,
      onReponsiblePartyMaintenanceChange,
    );

    $insightContainer.off("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
    $insightContainer.on("click", ".count-by-type-chart-container .bar-group", onBarGroupClick);
  };

  var onYearChange = function () {
    if (this.name === "focus-year") {
      focusYear = this.value;
    } else if (this.name === "comparison-year") {
      comparisonYear = this.value;
    }
    render();
  };

  var onCofChange = function () {
    cof = this.value;
    render();
  };

  var onReponsiblePartyMaintenanceChange = function () {
    responsiblePartyMaintenance = this.value;
    render();
  };

  var onSpatialItemChange = function () {
    spatialItem = this.value;
    render();
  };

  var onBarGroupClick = function () {
    var $barGroup = $(this);
    var $cofSelect = $insightContainer.find(`select[name="cof"]`);

    if ($barGroup.hasClass("selected")) {
      $cofSelect.val("total").change();
    } else {
      const cof = $barGroup.data("type");
      if (cof) {
        $cofSelect.val(cof).change();
      }
    }
  };

  var render = function () {
    if (Tree.get("dataView") !== "muni-catch-basin") {
      return;
    }

    controller.render({
      focusYear,
      comparisonYear,
      cof,
      responsiblePartyMaintenance,
      currentReportingYear,
      spatialItem,
      spatialDataTitle,
    });
  };

  var loadReportingYears = async function () {
    reports = await ApiCalls.getAnnualReports("muni catch basin");
    reportingYears = reports
      .map((report) => report.reportingYear)
      .sort()
      .reverse();
    focusYear = reportingYears[0];
    currentReportingYear = reportingYears[0];
    comparisonYear = reportingYears[1] || reportingYears[0];
  };

  var getFilterMenuHtmlFunction = function () {
    var yearOptions = reportingYears.map((year) => {
      return { name: year, value: year };
    });

    return nunjucks.render("insight/muniCatchBasinInsightDashboardFilters.njk", {
      cofOptions,
      cof,
      responsiblePartyMaintenanceOptions,
      responsiblePartyMaintenance,
      yearOptions,
      focusYear,
      comparisonYear,
      spatialDataOptions,
      spatialDataTitle,
      spatialItem,
    });
  };

  var getMobileFilterButtonTextFunction = function () {
    const displayName = cofOptions.getObjectWith("value", cof)?.name;
    return `${focusYear} & ${comparisonYear}, ${displayName}`;
  };

  var saveFilterFunction = function (formData) {
    if (formData["focusYear"]) {
      focusYear = parseInt(formData["focusYear"]);
      $insightContainer.find(`select[name="focus-year"]`).val(focusYear);
    }
    if (formData["comparisonYear"]) {
      comparisonYear = parseInt(formData["comparisonYear"]);
      $insightContainer.find(`select[name="comparison-year"]`).val(comparisonYear);
    }
    if (formData["cof"]) {
      cof = formData["cof"];
      $insightContainer.find(`select[name="cof"]`).val(cof);
    }
    if (formData["responsiblePartyMaintenance"]) {
      responsiblePartyMaintenance = formData["responsiblePartyMaintenance"];
      $insightContainer
        .find(`select[name="responsiblePartyMaintenance"]`)
        .val(responsiblePartyMaintenance);
    }
    if (formData["spatialItem"]) {
      spatialItem = formData["spatialItem"];
      $insightContainer.find(`select[name="spatial-item"]`).val(spatialItem);
    }

    render();
  };

  var getYearByDataPath = function (dataPath) {
    return dataPath === "focus" ? focusYear : comparisonYear;
  };

  var getSelectedCof = function () {
    return cof;
  };

  return {
    init,
  };
};

module.exports = MuniCatchBasinInsightDashboardController();

const ApiCalls = require("../apiCalls");
const InsightDashboardController = require("../general/widgets/insightDashboardController");
const InsightWidgetFunctions = require("../general/widgets/insightWidgetFunctions");
const InsightWidgetSimpleNumber = require("../general/widgets/insightWidgetSimpleNumber");
const InsightWidgetTopList = require("../general/widgets/insightWidgetTopList");
const InsightWidgetMonthlyCountChart = require("../general/widgets/insightWidgetMonthlyCountChart");
const InsightWidgetCountByTypeChart = require("../general/widgets/insightWidgetCountByTypeChart");
const InsightWidgetPercentByYearBarChart = require("../general/widgets/insightWidgetPercentByYearBarChart");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const MaintenanceResult = require("../general/results/maintenanceResult");
const InsightWidgetTwoPies = require("../general/widgets/insightWidgetTwoPies");
const Tree = require("../tree");
