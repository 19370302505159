"use strict";

const ToolFunctions = function () {
  // @TODO: Merge this with toolDropdownItems in Panels.js?

  var handleUrl = function () {
    var currentTool = getDefaultTool();
    if (!getToolsEnabledForUser().includes(currentTool)) {
      UrlRoutes.navigateToHomePage();
    }
    initTool(currentTool);
    UserResource.setUserData("lastToolUsed", currentTool);
    setSentryToolContext(currentTool);
  };

  var initTool = function (tool) {
    Tree.set("tool", tool);
    $("body").prop("id", tool);

    document.title = `2NFORM - ${getToolConfigProperty("name", tool)}`;
    setCustomTabName(tool);
  };

  var setCustomTabName = function (tool) {
    var customTabName = getToolConfigProperty("customTabName", tool, false);
    if (customTabName) {
      for (const key in customTabName) {
        $("#tool-nav-tabs").find(`[data-maptarget="${key}"]`).text(customTabName[key]);
      }
    }
  };

  var getDataViewsByTool = function (tool, errorIfNotConfigured = true) {
    const toolDefaultDataView = getToolConfigProperty("dataViews", tool);

    if (!toolDefaultDataView && errorIfNotConfigured) {
      throw new Error(`No data view configured for tool ${tool}`);
    }

    return toolDefaultDataView ?? [];
  };

  var getDefaultDataView = function () {
    const tool = Tree.get("tool");
    const toolDataViews = getDataViewsByTool(tool);
    let dataView = UrlRoutes.getDataViewByPath();

    if (!dataView || !toolDataViews.includes(dataView)) {
      dataView = DataViewFunctions.getLastUsedDataViewByTool(tool);

      if (!toolDataViews.includes(dataView)) {
        dataView = toolDataViews[0];
      }

      UrlRoutes.navigate(`/ram/${tool}/${dataView}`, {
        historyAPIMethod: "replaceState",
        callHandler: false,
      });
    }

    return dataView;
  };

  var getDefaultTool = function () {
    let tool = UrlRoutes.getToolByPath();

    if (!tool) {
      tool = UserResource.getUserData("lastToolUsed") ?? getDefaultOrFirstUserTool();
      UrlRoutes.navigate(`/ram/${tool}`, { historyAPIMethod: "replaceState" });
    }

    return tool;
  };

  var getDefaultOrFirstUserTool = function (defaultTool = "construction") {
    const userTools = getToolsEnabledForUser();
    if (userTools.includes(defaultTool)) {
      return defaultTool;
    }
    return userTools[0];
  };

  var setSentryToolContext = function (tool) {
    Sentry.getCurrentScope().setTag("tool", tool);
  };

  var getToolConfig = function (tool, forHome = false) {
    const config = ToolConfig.get(forHome);
    if (config[tool]) {
      return config[tool];
    }
    console.error(`Undefined tool in toolConfig: ${tool}`);
  };

  var getToolConfigProperty = function (propName, tool, logError = true) {
    if (!tool) {
      tool = getActiveTool();
    }
    var toolConfig = getToolConfig(tool);
    if (toolConfig) {
      if (propName in toolConfig) {
        return toolConfig[propName];
      } else if (logError) {
        console.error(`Missing property "${propName}" in toolConfig for tool: ${tool}`);
      }
    }
  };

  var getActiveTool = function () {
    return Tree.get("tool");
  };

  var getToolDefaultEnabledLayers = function (tool) {
    return ["bounds"];
  };

  var getToolDataViewConfigs = function (tool, logErrors = true) {
    var toolDataViewConfigs = [];
    const dataViewsForTool = getToolConfigProperty("dataViews", tool, logErrors) || [];

    if (!dataViewsForTool) {
      return undefined;
    }

    for (const dataViewName of dataViewsForTool) {
      const dataView = DataViewFunctions.getDataViewConfig(dataViewName);
      dataView.id = dataViewName;
      toolDataViewConfigs.push(dataView);
    }

    return toolDataViewConfigs;
  };

  var getCurrentDataViewConfig = function (tool) {
    var currentDataView = Tree.get("dataView");
    return getToolDataViewConfigById(currentDataView, tool);
  };

  var getToolDataViewConfigById = function (id, tool) {
    if (!tool) {
      tool = Tree.get("tool");
    }
    var configs = getToolDataViewConfigs(tool);
    if (configs && configs.length) {
      for (let i = 0; i < configs.length; i++) {
        const config = configs[i];
        if (config.id === id) {
          return config;
        }
      }
      console.error(`No config found in toolConfig.dataViewConfigs for id: ${id}`);
    }
  };

  var getToolShowDataViewDropdown = function () {
    return getToolConfigProperty("dataViews", Tree.get("tool")).length > 1;
  };

  var getToolDataViewDisabledLayers = function (dataView, tool) {
    var dataViewConfig = getToolDataViewConfigById(dataView, tool);
    if (dataViewConfig) {
      return dataViewConfig.defaultDisabledLayers;
    }
  };

  var getToolIsTeaserTool = function (tool) {
    return getToolConfigProperty("isTeaserTool", tool, false);
  };

  var getToolDataViewSearchByPropertyEnabled = function (dataView, tool) {
    var dataViewConfig = getToolDataViewConfigById(dataView, tool);
    if (dataViewConfig) {
      return dataViewConfig.searchByPropertyEnabled;
    }
  };

  var toolListIsEnabledForTab = function (tab, dataView, tool) {
    const listEnabledTabs = DataViewFunctions.getUserEnabledTabs(dataView, "list");

    return listEnabledTabs.includes(tab);
  };

  var toolMapIsEnabledForTab = function (tab, dataView, tool) {
    const mapEnabledTabs = DataViewFunctions.getUserEnabledTabs(dataView, "map");

    if ((Session.isCwp() || Session.isMmsd()) && tab === "todo" && tool === "bmpram") {
      return false;
    }

    return mapEnabledTabs.includes(tab);
  };

  var getToolsInOrder = function (forHome = false) {
    const toolMap = ToolConfig.get(forHome);
    const toolArray = [];

    for (const name in toolMap) {
      const tool = toolMap[name];
      tool.internalName = name;

      toolArray.push(tool);
    }

    return toolArray.sort((a, b) => a.sortOrder - b.sortOrder);
  };

  var isValidTool = function (maybeTool) {
    const allTools = Object.keys(ToolConfig.get());
    return allTools.includes(maybeTool);
  };

  var getToolByDataView = function (dataView) {
    const toolMap = ToolConfig.get();

    for (const tool in toolMap) {
      const config = toolMap[tool];

      if (config.dataViews?.includes(dataView)) {
        return tool;
      }
    }

    throw new Error(`No tool has ${dataView} configured as a data view.`);
  };

  var getUserToolsToDisplay = function () {
    let userTools = getToolsEnabledForUser();

    if (Tree.get("user", "user_role") === "admin") {
      const allTools = Object.keys(ToolConfig.get());
      const groupTools = getToolsEnabledForGroup();
      const groupToolsNotInUser = groupTools.filter((tool) => !userTools.includes(tool));
      userTools = allTools.filter((tool) => !groupToolsNotInUser.includes(tool));
    }

    return userTools;
  };

  var getToolsEnabledForUser = function () {
    return ToolSettings.getSetting("enabledTools") || [];
  };

  var getToolsEnabledForGroup = function () {
    return ToolSettings.getSetting("groupTools") || [];
  };

  return {
    getActiveTool,
    getCurrentDataViewConfig,
    getDefaultTool,
    getToolConfig,
    getToolConfigProperty,
    getToolDataViewConfigById,
    getToolDataViewConfigs,
    getToolDataViewDisabledLayers,
    getToolDataViewSearchByPropertyEnabled,
    getDefaultDataView,
    getDataViewsByTool,
    getToolDefaultEnabledLayers,
    getToolIsTeaserTool,
    getToolShowDataViewDropdown,
    getToolsInOrder,
    isValidTool,
    getToolByDataView,
    handleUrl,
    toolListIsEnabledForTab,
    toolMapIsEnabledForTab,
    getUserToolsToDisplay,
    getToolsEnabledForUser,
    getToolsEnabledForGroup,
  };
};

module.exports = ToolFunctions();

const DataViewFunctions = require("./dataViewFunctions");
const Sentry = require("@sentry/browser");
const Session = require("./login/session");
const ToolConfig = require("./config/toolConfig");
const ToolSettings = require("./settings/toolSettings");
const Tree = require("./tree");
const UrlRoutes = require("./routes/urlRoutes");
const UserResource = require("./offline/userResource");
