"use strict";

const ConstructionFilters = function () {
  let mainSmartFilters;

  const filterResourceDataHandler = function (unfilteredData, filters) {
    const filteredData = ResourceFilters.spatialFilters(unfilteredData, filters);

    return filterOffline(filteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var passSubGroupFilters = function (datum, filters) {
    let pass = true;
    const allElementsAreStrings = filters.subGroups?.every((element) => typeof element === "string")
      ? true
      : false;
    const datumGroupId = allElementsAreStrings ? datum.groupId?.toString() : datum.groupId;
    if (
      filters.subGroups?.length !== undefined &&
      !FilterSummary.areAllCheckboxesChecked("subGroups")
    ) {
      pass = filters.subGroups.includes(datumGroupId);
    }

    return pass;
  };

  var passMonitoringDrainageFilters = function (datum, filters) {
    if (filters.drainsToSpatialGroupingId?.length) {
      return filters.drainsToSpatialGroupingId.includes(datum.monitoringName);
    }

    return true;
  };

  var passAreasDrainageFilters = function (datum, filters) {
    if (filters.drainsToAssesmentArea?.length) {
      return datum.assessmentMonitoringName.some((name) =>
        filters.drainsToAssesmentArea.includes(name),
      );
    }

    return true;
  };

  var passHighwaysFilter = function (datum, filters) {
    if (
      filters.highwayName?.length !== undefined &&
      !FilterSummary.areAllCheckboxesChecked("highwayName")
    ) {
      return filters.highwayName.includes(datum.highwayName);
    }

    return true;
  };

  var passUrbanDrainageFilter = function (datum, filters) {
    if (filters.drainsToC?.length) {
      return filters.drainsToC.includes(datum.drainsToC);
    }

    return true;
  };

  var passCatchmentsFilter = function (datum, filters) {
    if (filters.catchmentName?.length) {
      return filters.catchmentName.includes(datum.catchmentName);
    }

    return true;
  };

  var passReceivingWaterFilter = function (datum, filters) {
    if (filters.drainsToRw?.length) {
      return filters.drainsToRw.includes(datum.drainsToRw);
    }

    return true;
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      drainsToSpatialGroupingId: ["assessmentMonitoringName", "array"],
      drainsToAssesmentArea: ["assessmentMonitoringName", "array"],
      highwayName: "highwayName",
      implementerType: "implementerType",
      maintainerType: "maintainerType",
      landOwnership: "landOwnership",
      drainsToRw: "drainsToRw",
      drainsToC: "drainsToC",
      catchmentName: "catchmentName",
      phase: "phase",
      priority: "highPriority",
      subGroups: "groupId",
      associatedBmpTypes: ["associatedBmpTypes", "array"],
      projectType: "projectType",
      developmentType: "developmentType",
      otherFilters: (datum, filters) => {
        return ResourceFilters.passSearchStringFilter(datum, filters, [
          "projectIdentifier",
          "projectName",
          "streetAddress",
        ]);
      },
    };
  };

  var getSessionData = function () {
    return {
      isVentura: Session.isVentura(),
      isUlar: Session.isUlar(),
    };
  };

  var getMonitoringAndAssessmentData = function () {
    let monitoringOptions, assesmentAreaOptions;
    const monitoringDrainageData = ToolSettings.getSetting(
      "topLevelDataSorts",
      "spatialGrouping",
      "catchments",
      "monitoringDrainages",
    );
    if (monitoringDrainageData?.length) {
      monitoringOptions = Table.getUnique(monitoringDrainageData, "groupingName");
    }
    const assessmentAreaData = ToolSettings.getSetting(
      "topLevelDataSorts",
      "spatialGrouping",
      "catchments",
      "assessmentAreas",
    );
    if (assessmentAreaData?.length) {
      assesmentAreaOptions = Table.getUnique(assessmentAreaData, "groupingName");
    }
    return { monitoringOptions, assesmentAreaOptions };
  };

  var getProps = async function () {
    const sessionData = getSessionData();
    const monitoringAndAssessmentData = getMonitoringAndAssessmentData();
    const unfilteredData = await Tree.getAfterUpdate(["layers", "constructionProject", "data"]);
    const subGroupProps = Progeny.getSubGroupFilterProps();

    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions, highwayOptions } =
      Filters.getSpatialArrays();

    return {
      data: unfilteredData,
      receivingWaterOptions,
      catchmentsOptions,
      urbanDrainageOptions,
      highwayOptions,
      phaseOptions: ToolSettings.getSetting("filterConstants", "phases"),
      bmpTypeOption: ToolSettings.getSetting("filterConstants", "bmpTypes"),
      landOwnershipOptions: ToolSettings.getSetting(
        "filterConstants",
        "projectDeliverylandOwnershipOptions",
      ),
      projectTypeOptions: ToolSettings.getSetting("filterConstants", "projectTypeOptions"),
      developmentTypeOptions: ToolSettings.getDomainOptions("development_type"),
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      monitoringOptions: monitoringAndAssessmentData.monitoringOptions,
      ...sessionData,
      ...monitoringAndAssessmentData,
    };
  };

  return {
    filterOffline,
    filterResourceDataHandler,
    passReceivingWaterFilter,
    passUrbanDrainageFilter,
    passCatchmentsFilter,
    passHighwaysFilter,
    passMonitoringDrainageFilters,
    passAreasDrainageFilters,
    passSubGroupFilters,
    getFilterConfigs,
    setSmartFilters,
    getProps,
  };
};

module.exports = ConstructionFilters();

const FilterSummary = require("../filters/filterSummary");
const ResourceFilters = require("../filters/resourceFilters");
const Progeny = require("../login/progeny");
const Table = require("../mapFunctions/table");
const ToolSettings = require("../settings/toolSettings");
const Session = require("../login/session");
const Tree = require("../tree");
const Filters = require("../mapFunctions/filters");
