"use strict";

var Filters = function () {
  let lastSmartFilter;

  var filterClearScoreClick = function () {
    $(".filter-score-input").val("");
    resetScoreTreeFilters();
    updateScoreTitle();

    FilterSummary.render();
  };

  var loadFilterBarListeners = function () {
    $(".filter-button").off();
    $(".filter-button").on("click", filterButtonClick);

    $(".search-button").off();
    $(".search-button").on("click", searchButtonClick);

    $(".dropdown-menu.sort").off("li", sortDropdownClick);
    $(".dropdown-menu.sort").on("click", "li", sortDropdownClick);
  };

  var loadFilterMenuListeners = function () {
    const $menu = getFilterMenu();
    $menu.off("click", "button.filter-title-button");
    $menu.on("click", "button.filter-title-button", filterTitleClick);
    $menu.find(".clear-date-btn").off();
    $menu.find(".clear-date-btn").on("click", clearDateRange);
    $menu.find(".js-select-all").off();
    $menu.find(".js-select-all").on("click", filterSelectAllClick);

    $(".filter-score-input").off();
    $(".filter-score-input").on("change", filterScoreInput);

    loadAnalyticsListeners($menu);
  };

  var loadAnalyticsListeners = function ($filterContainer) {
    $filterContainer
      .off("change", ".measurement-min-max input", addAnalyticsMeasurementFilter)
      .on("change", ".measurement-min-max input", addAnalyticsMeasurementFilter)
      .off("change", ".filter-score-input", addAnalyticsScoreFilter)
      .on("change", ".filter-score-input", addAnalyticsScoreFilter)
      .off("change", ".multiple-select", addAnalyticsMultipleSelectFilter)
      .on("change", ".multiple-select", addAnalyticsMultipleSelectFilter)
      .off("click", ".multiple-select a", addAnalyticsMultipleSelectFilter)
      .on("click", ".multiple-select a", addAnalyticsMultipleSelectFilter);
  };

  const addAnalyticsResetDefaultFilterButton = function () {
    Analytics.sendFilterEvent("resetBtn");
  };

  const addAnalyticsMeasurementFilter = function (e) {
    const $input = $(e.currentTarget);
    const $container = $input.closest(".measurement-min-max");
    const name = $container.data("display");
    const unit = $container.find(".unit-select").val();
    const min = $input.hasClass("from") ? "Min" : "";
    const max = $input.hasClass("to") ? "Max" : "";
    const unitDropDown = !min && !max ? "Unit" : "";
    Analytics.sendFilterEvent(`${name}${min}${max}${unitDropDown}:${unit}`);
  };

  const addAnalyticsScoreFilter = function (e) {
    const name = updateMinMaxName(e.currentTarget.name);
    Analytics.sendFilterEvent(name);
  };

  const addAnalyticsMultipleSelectFilter = function (e) {
    const filterName = $(e.currentTarget).closest(".multiple-select").data("name");
    Analytics.sendFilterEvent(Analytics.shortenTextByHalf(filterName));
  };

  const updateMinMaxName = function (name) {
    if (name.includes("[min]")) {
      return name.replace("[min]", "Min");
    } else if (name.includes("[max]")) {
      return name.replace("[max]", "Max");
    } else {
      return name;
    }
  };

  const onFilterUpdate = function (e) {
    _clearInvalidDates();
    if ($(e.target).is(":checkbox")) enableDisableSortItemsBySpatialFilters();

    Tree.set(["filters", "searchString"], $(".search-input input").val());
  };

  const _clearInvalidDates = function () {
    const $menu = getFilterMenu();
    $menu.find(".datepicker").off("blur");
    $menu.find(".datepicker").on("blur", function (e) {
      const dateValue = $(e.target).val();
      const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/; // Simple validation for date format MM/DD/YYYY
      if (dateValue && !datePattern.test(dateValue)) {
        $(e.target).val("");
      }
    });
  };

  const enableDisableSortItemsBySpatialFilters = function () {
    if (anyCheckboxesChecked(FilterConstants.filtersThatDisableDataSorts)) {
      handleSwitchToId();
      disableListItems(FilterConstants.dataSortsDisabledForSpatialSmartFilters);
      enableListItems(FilterConstants.dataSortsEnabledForSpatialSmartFilters);
    } else {
      enableListItems(FilterConstants.dataSortsDisabledForSpatialSmartFilters);
      disableListItems(FilterConstants.dataSortsEnabledForSpatialSmartFilters);
      $(".filter-button").removeClass("disabled");
    }
  };

  const disableListItems = (ids) => {
    ids.forEach((id) => {
      const $menuItem = $(`ul.dropdown-menu.sort li[data-id='${id}']`);
      if ($menuItem.hasClass("dropdown-selection")) {
        const dontCloseFilters = true;
        setDataSort(getDefaultDataSort(), false, true, dontCloseFilters);
      }
      $menuItem.addClass("disabled");
    });
  };

  const enableListItems = (ids) => {
    ids.forEach((id) => {
      $(`ul.dropdown-menu.sort li[data-id='${id}']`).removeClass("disabled");
    });
  };

  const handleSwitchToId = function () {
    const flatDataSort = DataViewFunctions.getCurrentDataViewProperty("flatDataSort");

    sortDropdownClick({
      target: $(`<li data-id="${flatDataSort}">`),
    });
  };

  const showFilterMenu = function () {
    setTimeout(function () {
      $(".filter-menu").css("display", "block");
    }, 1);
  };

  const countCheckedCheckboxes = (names, allMeansNone = true) =>
    names.reduce((total, name) => {
      const allCount = $(`input[type="checkbox"][name="${name}"]`).length;
      const checkedCount = $(`input[type="checkbox"][name="${name}"]:checked`).length;
      return total + (checkedCount === allCount && allMeansNone ? 0 : checkedCount);
    }, 0);

  const anyCheckboxesChecked = (names, allMeansNone = true) =>
    countCheckedCheckboxes(names, allMeansNone) > 0;

  var loadTreeListeners = function () {
    Tree.select("activeTab").on("update", (e) => {
      DataList.constructionHideShowDetailsButtonListener();
      var activeTab = e.data.currentData;
      handleFiltersOnTabChange(activeTab);
      handleToolOrTabChange(activeTab);
      setDefaultDataSort(activeTab);
      Tree.set("isDefaultFilter", false);
    });

    Tree.watch({
      activeTab: ["activeTab"],
      navToggleSelection: ["navToggleSelection"],
    }).on("update", function (e) {
      const target = e.target.get();
      const activeTab = target.activeTab;
      const navToggleSelection = target.navToggleSelection;
      const $inventoryFilters = $("#inventoryFilters");
      if (
        PageFunctions.getTabProperty(activeTab, "noPanels") ||
        (activeTab === "todo" && navToggleSelection === "list")
      ) {
        $inventoryFilters.hide();
      } else {
        $inventoryFilters.show();
      }
      if (!Tree.get("filterMenuRendered")) {
        renderFiltersMenu();
        Tree.set("filterMenuRendered", true);
      }
    });
    MeasurementMinMax.updateStatusOfFilter(Tree.get("filters"));
  };

  var updateScoreTitle = function () {
    var $scoreTitle = $(".filter-score").parent().prev().children(":first-child");
    var scoreFrom = Tree.get("filters", "scoreFrom");
    var scoreTo = Tree.get("filters", "scoreTo");

    updateRangeTitle(scoreFrom, scoreTo, $scoreTitle);
  };

  var filterScoreInput = function () {
    var $score = $(this);
    var isFrom = $score.hasClass("from");
    var val = FormFunctions.getValidAndRoundedNumberInput($score);

    if (isFrom) {
      const toVal = $score.parents(".min-max").find(".to").val();
      const newVal = toVal === "" ? val : Math.min(val, toVal);
      Tree.set(["filters", "score", "min"], newVal);
      $score.val(newVal);
    } else {
      const fromVal = $score.parents(".min-max").find(".from").val();
      const newVal = fromVal === "" ? val : Math.max(val, fromVal);
      Tree.set(["filters", "score", "max"], newVal);
      $score.val(newVal);
    }

    updateScoreTitle();
  };

  var initFilters = function () {
    loadTreeListeners();
    loadFilterBarListeners();

    setDataSort("rw");
    _setFilterToDefault();
    Tree.set("isDefaultFilter", true);
  };

  var storeOriginalFilterLables = function () {
    getFilterMenu()
      .find("button.filter-title-button span")
      .each(function () {
        var span = $(this);
        span.data("title", span.text());
      });
  };

  var updateRangeTitle = function (from, to, $title) {
    if (from == null) {
      from = "";
    }
    if (to == null) {
      to = "";
    }

    if (from || to) {
      $title.text(from + " - " + to);
    } else {
      resetOriginalTitleText($title);
    }
  };

  var resetOriginalTitleText = function ($titles) {
    for (const title of $titles) {
      const $title = $(title);
      $title.text(getOriginalTitleText($title));
    }
  };

  var getOriginalTitleText = function ($title) {
    return $title.data("title");
  };

  var _addDateFilterAnalytics = function () {
    const filterName = $(this).closest(".date-range").data("name");
    const toFrom = $(this).hasClass("from") ? "from" : "to";
    const treeDate = Tree.get(["filters", filterName, toFrom]);

    const dataDate = DateRange.getData()[filterName][toFrom];

    if (!treeDate && dataDate) {
      Analytics.sendFilterEvent(filterName + ":" + toFrom);
    } else {
      const treeDateObj = DateTime.parseDisplayDate(treeDate);
      const dataDateObj = DateTime.parseDisplayDate(dataDate);

      if (treeDateObj.getTime() !== dataDateObj.getTime()) {
        Analytics.sendFilterEvent(filterName + ":" + toFrom);
      }
    }
  };

  var filterDateCheckboxClick = function () {
    var $radio = $(this);
    Tree.set(["filters", $radio.attr("name")], $radio.val());
  };

  var filterSelectAllClick = function () {
    var $inputsContainer = $(this).closest(".filter-dropdown");
    const $checked = $inputsContainer.find('input[type="checkbox"]').not(".disabled input");

    if ($checked.filter(":checked").length === $checked.length) {
      $checked.prop("checked", false);
    } else {
      $checked.prop("checked", true);
    }

    updateFlags.call(this, 1);
  };

  var updateFlags = function (selectAll) {
    var $inputsContainer = $(this).closest(".filter-dropdown");
    var checkedValues = [];
    var checkedLabels = [];
    var $title = $inputsContainer.prev().children().first();

    $inputsContainer.find("input:checked").each(function (i, chk) {
      checkedValues.push(chk.value);
      checkedLabels.push($(chk).parent().text());
    });

    updateCheckboxTitle(
      $title,
      checkedLabels,
      $inputsContainer.find("input").length,
      checkedValues,
    );

    setCorrectFilter($inputsContainer.find("input")[0].name, selectAll, checkedValues);
  };

  var updateCheckboxTitle = function ($title, checkedLabels, totalCheckboxes, checkedValues) {
    const maxCheckedInTitle = 3;
    const checkedString = checkedLabels.join(", ");

    $title.attr("title", getOriginalTitleText($title) + ": " + checkedLabels);

    const defaultLabels = FilterConstants.getDefaultFiltersByDataView().types;

    if (
      checkedLabels.length === totalCheckboxes ||
      checkedLabels.length === 0 ||
      checkedValues.containsSameAs(defaultLabels)
    ) {
      resetOriginalTitleText($title);
      $title.attr("title", "");
    } else if (checkedLabels.length <= maxCheckedInTitle) {
      $title.text(checkedString);
    } else {
      const titleString = " (" + checkedLabels.length + ")";

      resetOriginalTitleText($title);
      $title.text($title.text() + titleString);
    }
  };

  var setCorrectFilter = function (key, selectAll, values) {
    if (key === "condition" && selectAll === 1) {
      Tree.set(["filters", "condition"], values);
    } else if (key === "fcsStatus") {
      Tree.set(["filters", "fcsStatus"], values);
    } else if (key === "bmpStatus") {
      Tree.set(["filters", "bmpStatus"], values);
    } else {
      Tree.set(["filters", key], values);
    }
  };

  var filterTitleClick = function () {
    var $self = $(this).closest("button");
    var $next = $self.next();
    $next.slideToggle(200, function () {
      MapFunctions.setMapHeight();
      mobileFilterSearchExpansionTableAdjustment();
    });
    $self.toggleClass("active");
    $self.siblings("button").removeClass("active");
    $self.parent().find(".filter-dropdown").not($next).hide();
  };

  var sortDropdownClick = function (e) {
    var selected = $(e.target).closest("li");
    Table.collapseAllTableSections();
    if (selected.hasClass("disabled")) {
      e.stopPropagation();
      return;
    }
    var selectedId = selected.attr("data-id");
    setDataSort(selectedId);
    if (!filterMenuEnabled()) {
      Analytics.sendTableEvent("set_data_sort");
    }
  };

  var filterMenuEnabled = function () {
    return (
      $(".filter-menu").hasClass("filter-full-height") &&
      $(".filter-menu").css("display") === "block"
    );
  };

  var filterBarSearchFilterButtonAddPadding = function () {
    $("#inventoryFilters").find(".filter-bar").addClass("filter-bar-padding");
  };

  var filterBarSearchFilterButtonRemovePadding = function () {
    $("#inventoryFilters").find(".filter-bar").removeClass("filter-bar-padding");
  };

  var mobileFilterSearchExpansionTableAdjustment = function () {
    var $searchFilterButtonContainer = $("#floatingInputsCollapse");
    var $mobileDataListTable = $(".mobile-table");
    var $sortContainer = $("#list-panel > div.data-list-container.list-view > div > table > thead");
    var navToggleSelection = Tree.get("navToggleSelection");
    var currentTool = Tree.get("tool");
    var activeTab = Tree.get("activeTab");
    var deviceWidth = NunjucksFunctions.getDeviceSize();

    if (
      activeTab === "data" &&
      ["construction", "indcom", "muni", "lid"].includes(currentTool) &&
      navToggleSelection === "list" &&
      (deviceWidth === "mobile" || deviceWidth === "tablet")
    ) {
      filterBarSearchFilterButtonAddPadding();
      $("#floatingInputsCollapse").removeClass("search-filter-click-initial-mobile-sizing");
      $("#floatingInputsCollapse").addClass(
        "search-filter-desktop-initial-click-mobile-expanded-sizing",
      );

      if ($searchFilterButtonContainer.height() > $sortContainer.height()) {
        $mobileDataListTable.css({
          "margin-top": $searchFilterButtonContainer.height() - $sortContainer.height(),
          display: "block",
        });
      } else {
        $sortContainer.hide();
        $mobileDataListTable.css({
          "margin-top": $searchFilterButtonContainer.height(),
          display: "block",
        });
      }
    }
  };

  var mobileFilterSearchContractionTableAdjustment = function () {
    var $searchFilterButtonContainer = $("#floatingInputsCollapse");
    var $mobileDataListTable = $(".mobile-table");
    var $sortContainer = $("#list-panel > div.data-list-container.list-view > div > table > thead");
    var navToggleSelection = Tree.get("navToggleSelection");
    var currentTool = Tree.get("tool");
    var activeTab = Tree.get("activeTab");
    var deviceWidth = NunjucksFunctions.getDeviceSize();

    if (
      activeTab === "data" &&
      ["construction", "indcom", "muni"].includes(currentTool) &&
      navToggleSelection === "list" &&
      (deviceWidth === "mobile" || deviceWidth === "tablet")
    ) {
      $sortContainer.show();
      filterBarSearchFilterButtonRemovePadding();
      $searchFilterButtonContainer.removeClass(
        "search-filter-desktop-initial-click-mobile-expanded-sizing",
      );
      $searchFilterButtonContainer.addClass("search-filter-click-initial-mobile-sizing");
      $mobileDataListTable.css("margin-top", "0");
    }
  };

  var searchButtonClick = function () {
    var $searchField = $(".search-input");
    var $button = $(".search-button");

    if ($searchField.is(":visible")) {
      mobileFilterSearchContractionTableAdjustment();
      closeSearch();
      _closeFiltersMenu();
      $("#bottomFloatingInputsTable").removeClass("hidden");
    } else {
      setFlatDataSort();
      MapStyles.expandingSectionToggle($searchField, $button, true, function () {
        $(".search-input input").focus();
        MapFunctions.setMapHeight();
        mobileFilterSearchExpansionTableAdjustment();
      });
      Analytics.sendTableEvent("show_search");
    }
  };

  var _closeSearchField = function () {
    var $searchField = $(".search-input");
    var $button = $(".search-button");

    if ($searchField.is(":visible")) {
      MapStyles.expandingSectionToggle($searchField, $button, false, function () {
        MapFunctions.setMapHeight();
        mobileFilterSearchContractionTableAdjustment();
      });
    }
  };

  var filterButtonClick = function (e) {
    var $button = $(".filter-button");
    var $menu = $button.closest(".filter-container").find(".filter-menu");

    if (spatialFilterIsSet()) {
      resetSpatialFilters();
    }

    Table.collapseAllTableSections();
    $(".dropdown label").css("justify-content", "flex-start");

    if ($menu.is(":visible")) {
      Table.renderBackButtonIfFactSheet();
      $("#bottomFloatingInputsTable").removeClass("hidden");
      mobileFilterSearchContractionTableAdjustment();
      closeSearch();
      _closeFiltersMenu();
    } else {
      $("#bottomFloatingInputsTable").addClass("hidden");
      $(".filter-menu").addClass("filter-full-height");

      $button.html($button.html().replace("Show", "Hide"));
      MapStyles.expandingSectionToggle($menu, $button, true, function () {
        MapFunctions.setMapHeight();
        setTimeout(() => {
          Tree.set("reloadFilter", true);
        }, 0);
        mobileFilterSearchExpansionTableAdjustment();
      });
      MeasurementMinMax.updateStatusOfFilter(Tree.get("filters"));
      Analytics.sendTableEvent("show_filter");
    }
  };

  var _closeFiltersMenu = function () {
    var $button = $(".filter-button");
    var $menu = $button.closest(".filter-container").find(".filter-menu");

    hideFilterPopovers();
    $button.html($button.html().replace("Hide", "Show"));
    if ($menu.is(":visible")) {
      MapStyles.expandingSectionToggle($menu, $button, false, function () {
        MapFunctions.setMapHeight();
        mobileFilterSearchContractionTableAdjustment();
      });
    }
  };

  var handleToolOrTabChange = (activeTab) => {
    const currentTool = Tree.get("tool");
    if (activeTab === "todo") {
      if (currentTool === "trashram") {
        if (Tree.get("dataView") === "va") {
          DataViewController.setDataView("fcs");
        } else {
          disableTrashAssessmentLayers();
          DisplayOptions.handleLayerDisplay("bmps", false);
          DisplayOptions.handleLayerDisplay("fcs", true);
        }
      }
    }
    handleFilterMenuDisplayByTab(activeTab);
  };

  var setDefaultDataSort = function (activeTab) {
    const defaultDataSort =
      DataViewFunctions.getCurrentDataViewProperty("defaultDataSortByTab")?.[activeTab];
    if (defaultDataSort) {
      setDataSort(defaultDataSort);
    }
  };

  var handleFiltersOnTabChange = function (activeTab) {
    if (_handleConstructionFilterMenueOnChange(activeTab)) return;
    var $menu = getFilterMenu();
    if (activeTab === "todo" && $menu.is(":visible")) {
      _setFilterToDefault();
    }
    _handleFilterPannelUpdateOnDataTab(activeTab);
  };

  var _handleConstructionFilterMenueOnChange = function (activeTab) {
    return Tree.get("dataView") === "construction-project" && activeTab === "todo";
  };

  var _handleFilterPannelUpdateOnDataTab = function (activeTab) {
    if (activeTab === "data") {
      $("#bottomFloatingInputsTable").removeClass("hidden");
    }
  };

  var renderFiltersMenu = function () {
    renderDataSortDropdown();

    if (Tree.get("navToggleSelection") === "list") {
      $(".disableInList").hide();
    }

    $(".search-input input").prop(
      "placeholder",
      DataViewFunctions.getCurrentDataViewProperty("searchPlaceholder"),
    );

    renderFilter();

    resetFlatDataSortByDataView();
  };

  var resetFlatDataSortByDataView = function () {
    const dataView = Tree.get("dataView");
    var currentDataSort = Tree.get("table", "dataSort");
    if (
      ((dataView === "construction-project" || dataView === "construction-project-delivery") &&
        ["priority", "status"].includes(currentDataSort)) ||
      (dataView === "lid-project" &&
        ["lidProjectPerformance", "lidProjectStatus"].includes(currentDataSort))
    ) {
      setToFlatDataSort();
    }
  };

  var setToFlatDataSortIfSpatialDisabled = function () {
    // To-Do Map doesn't currently have a flat list available.
    if (Tree.get("activeTab") === "todo") {
      return;
    }
  };

  var setToFlatDataSort = function () {
    setDataSort(DataViewFunctions.getCurrentDataViewProperty("flatDataSort"));
  };

  var resetSpatialFilters = function () {
    resetSpatialTreeSelected();
    resetSpatialTreeFilters();
    MapFunctions.setMapHeight();
  };

  var resetSpatialTreeSelected = function () {
    Tree.set(["selected", "spatialGrouping"], "");
    Tree.set(["selected", "receivingWater"], "");
    Tree.set(["selected", "watershed"], "");
    Tree.set(["selected", "catchments"], "");
    Tree.set(["selected", "rwDrainsTo"], "");
    Tree.set(["selected", "urbanDrainage"], null);
    Tree.set(["selected", "maintenanceZones"], "");
    Tree.set(["selected", "highways"], "");
  };

  var resetSpatialTreeFilters = function () {
    if (!Tree.get("isDefaultFilter")) {
      Tree.set(["filters", "receivingWaters"], []);
      Tree.set(["filters", "catchments"], []);
      Tree.set(["filters", "spatialGroupingId"], null);
      Tree.set(["filters", "maintenanceZones"], []);
      Tree.set(["filters", "highways"], "");
    }
  };

  var resetSearchTreeFilters = function () {
    if (!Tree.get("isDefaultFilter")) {
      Tree.set(["filters", "search"], "");
      Tree.set(["filters", "searchString"], "");
    }
  };

  var _clearSearchInput = function () {
    $(".search-input input").val("");
  };

  var closeSearch = function () {
    _closeSearchField();
    resetSearchTreeFilters();
    _clearSearchInput();
  };

  var closeSearchAndFilters = function () {
    closeSearch();
    _closeFiltersMenu();
    _setFilterToDefault();
  };

  var resetScoreTreeFilters = function () {
    if (!Tree.get("isDefaultFilter")) {
      Tree.set(["filters", "score", "min"], null);
      Tree.set(["filters", "score", "max"], null);
    }
  };

  var resetDateTreeFilters = function () {
    if (!Tree.get("isDefaultFilter")) {
      Tree.set(["filters", "dateFrom"], null);
      Tree.set(["filters", "dateTo"], null);
    }
  };

  var resetPhotosTreeFilters = function () {
    if (!Tree.get("isDefaultFilter")) {
      Tree.set(["filters", "photos"], false);
    }
  };

  var spatialFilterIsSet = function () {
    let spatialFilterIsSet = false;
    FilterConstants.spatialFilters.forEach(function (filter) {
      const filterType = Tree.get(["filters", filter]);
      if (filterType?.length) {
        spatialFilterIsSet = true;
      }
    });
    return spatialFilterIsSet;
  };

  var clearDateRange = function () {
    resetOriginalTitleText($(".date-range-title"));
    $(".date input").val("");
    $(".filter-dropdown .datepicker").each(function () {
      if ($(this).is(":visible")) {
        $(this).data("DateTimePicker").clear();
      }
    });
    resetDateTreeFilters();
  };

  var hideFilterPopovers = function () {
    getFilterMenu().find("button").removeClass("active");
    $(".filter-dropdown").hide();
  };

  var setDataSort = function (
    dataSort,
    forceSelection = false,
    resetSpatial = true,
    dontCloseFilters = false,
  ) {
    const currentSelection = $(".dropdown-toggle.sort");
    const currentSelectionId = currentSelection.attr("data-id");
    const isFlatList = DataSortFunctions.getCurrentDataSortProperty("isFlatList", dataSort);
    const isDataViewFlatList =
      DataViewFunctions.getCurrentDataViewProperty("flatDataSort") === dataSort;

    if (dataSort === currentSelectionId && !forceSelection) {
      return;
    } else {
      if (resetSpatial) {
        resetSpatialFilters();
      }

      // VA uses Urban Catchment as its flat list
      // even though it's not a true flat list,
      // so we need to also check we aren't on the
      // default flat list.
      if (!isFlatList && !(!isFlatList && isDataViewFlatList) && !dontCloseFilters) {
        closeSearch();
        _closeFiltersMenu();
        $("#bottomFloatingInputsTable").removeClass("hidden");
      }
      forceDataSort(dataSort);
    }
  };

  var forceDataSort = function (dataSort) {
    _setSortDropdownTitle(dataSort);
    _setSortDropdownSelected(dataSort);
    Tree.set(["table", "dataSort"], dataSort);
  };

  var _setSortDropdownTitle = function (dataSort) {
    const selectedText = $(".dropdown-menu.sort")
      .find("li[data-id='" + dataSort + "'] a")
      .text();
    $(".dropdown-toggle.sort")
      .attr("data-id", dataSort)
      .html(selectedText + '<span class="glyphicon glyphicon-menu-down"></span>');
  };

  var _setSortDropdownSelected = function (dataSort) {
    const $selected = $(".dropdown-menu.sort li[data-id='" + dataSort + "']");
    if ($selected.length > 0) {
      Dropdown.select($selected);
    }
  };

  var renderDataSortDropdown = function () {
    const currentDataSorts = DataViewFunctions.getCurrentDataSorts();
    const topLevelDataSorts = DataViewFunctions.getAvailableDataSorts();
    const spatialGroupingDataSorts = DataViewFunctions.getSpatialGroupingDataSorts();
    applyGroupBasedDataSorts(topLevelDataSorts);
    const allDataSorts = Object.assign(
      {},
      topLevelDataSorts,
      currentDataSorts,
      spatialGroupingDataSorts,
    );

    const html = nunjucks.render("table/dataSortDropdown.njk", {
      topLevelDataSorts: topLevelDataSorts,
      dataSorts: currentDataSorts,
      spatialGroupingDataSorts,
    });
    $(".dropdown-menu.sort").html(html);

    let selectedDataSort = Tree.get("table", "dataSort");
    if (!(selectedDataSort in allDataSorts)) {
      selectedDataSort = getDefaultDataSort();
    }

    forceDataSort(selectedDataSort);
  };

  var applyGroupBasedDataSorts = function (dataSorts) {
    const dataView = Tree.get("dataView");
    if (
      (Session.isUlar() || Session.isVentura()) &&
      ["scenarios", "construction-project-delivery"].includes(dataView)
    ) {
      if (Session.isUlar() && dataView === "scenarios") {
        delete dataSorts.rw;
        delete dataSorts.ud;
        delete dataSorts.uc;
      }
      Tree.set(["table", "dataSort"], "assessmentAreas");
    }
  };

  var setIfFlatDataSort = function (setTo, setIfTrue = true) {
    const isFlatList = DataSortFunctions.getCurrentDataSortProperty("isFlatList");

    if (setIfTrue ? isFlatList : !isFlatList) {
      setDataSort(setTo);
    }
  };

  var setFlatDataSort = function () {
    setIfFlatDataSort(DataViewFunctions.getCurrentDataViewProperty("flatDataSort"), false);
  };

  var disableTrashAssessmentLayers = function () {
    if (Tree.get("tool") === "trashram") {
      DisplayOptions.unselectRadioButtons("main", "trash");
      DisplayOptions.handleLayerDisplay("trashLines", false);
      DisplayOptions.handleLayerDisplay("trashPoints", false);
      DisplayOptions.handleLayerDisplay("survey123", false);
    }
  };

  var _setFilterToDefault = function () {
    if (!Tree.get("isDefaultFilter")) {
      setFilterFormToDefault();
      setFilterTreeToDefault();
      setCheckboxesToDefault();
      filterClearScoreClick();
    } else {
      return true;
    }
  };

  var setFilterTreeToDefault = function () {
    var filters = getDefaultFilters();
    var dataSort = getDefaultDataSort();
    Tree.set("filters", filters);
    setDataSort(dataSort);
  };

  var getDefaultDataSort = function () {
    const dataView = Tree.get("dataView");
    const isParent = Session.isParent();
    const tool = Tree.get("tool");

    if (!Offline.onLine()) {
      return DataViewFunctions.getCurrentDataViewProperty("flatDataSort");
    } else if (["construction", "lid"].includes(tool)) {
      return dataView === "construction-project-delivery" || isParent
        ? "projectIdentifier"
        : getDefaultTopLevelDataSort();
    } else if (["peo"].includes(tool)) {
      return "activities";
    } else if (
      (Session.isUlar() || Session.isVentura()) &&
      ["scenarios", "construction-project-delivery"].includes(dataView)
    ) {
      return "assessmentAreas";
    } else {
      return getDefaultTopLevelDataSort();
    }
  };

  var getDefaultTopLevelDataSort = function () {
    const topLevelDataSorts = DataViewFunctions.getAvailableDataSorts();
    if (topLevelDataSorts) {
      if ("maintenanceZones" in topLevelDataSorts) {
        return "maintenanceZones";
      } else if ("highways" in topLevelDataSorts) {
        return "highways";
      }
    }
    return "rw";
  };

  var getDefaultFilters = function (noSpatial = false) {
    const filters = Object.assign({}, FilterConstants.initialFilters);
    const filtersByTool = FilterConstants.getDefaultFiltersByDataView();

    var filtersToExclude = getFiltersToExclude(noSpatial);
    for (const filter of filtersToExclude) {
      filters[filter] = Tree.get(["filters", filter]);
    }

    for (const filter of Object.keys(filtersByTool)) {
      filters[filter] = filtersByTool[filter];
    }

    return filters;
  };

  var getFiltersToExclude = function (noSpatial) {
    if (noSpatial) {
      return FilterConstants.filtersNotToReset;
    } else {
      return FilterConstants.filtersNotToReset.concat(FilterConstants.spatialFilters);
    }
  };

  var getDefaultFiltersWithoutSpatial = function () {
    return getDefaultFilters(true);
  };

  var setFilterFormToDefault = function () {
    // Do not change Tree.select(["filters"]) in this function
    clearDateRange();
    clearPhotos();
    _clearSearchInput();
    resetFilterTitles();

    $("#inventoryFilters").find("input[type=checkbox]").prop("checked", false);
    MapFunctions.setMapHeight();
  };

  var resetFilterTitles = function () {
    var $filterTitles = getFilterMenu().find("button.filter-title-button span");
    resetOriginalTitleText($filterTitles);
  };

  var setCheckboxesToDefault = function () {
    const filters = getDefaultFilters();
    var $filterContainer = $("#inventoryFilters");

    for (const name in filters) {
      const $allCheckboxes = $filterContainer.find("[name='" + name + "']");
      const values = filters[name];

      for (const checkbox of $allCheckboxes) {
        const $checkbox = $(checkbox);

        if (values?.includes($checkbox.val())) {
          $checkbox.prop("checked", true);
        }
      }
    }
  };

  var toggleFilterButtons = function (forceState = undefined) {
    const $filterBar = $(".filter-bar");
    $filterBar.find(".dropdown").toggleClass("disabled", forceState);
    $filterBar.find(".item-count-button").toggleClass("hidden", forceState);
    $filterBar.find(".icon-rectangle").toggleClass("disabled", forceState);
  };

  var clearPhotos = function () {
    $(".checkbox.outside-accordion").prop("checked", false);
    $(".checkbox-photos").prop("checked", false);
    resetPhotosTreeFilters();
  };

  var handleFilterMenuDisplayByTab = function (activeTab) {
    var inTodo = activeTab === "todo";
    var inData = activeTab === "data";
    var inInsight = activeTab === "insight";
    var inResult = activeTab === "result";
    var $filterContainer = $(".filter-container");

    $(".filter-bar").find(".search-button, .filter-button, .item-count-button").toggle(inData);
    $(".filter-bar").toggle(inData || inInsight || inResult);
    if (inTodo || inResult) {
      $filterContainer.find(".filter-menu, .search-input").hide();
      $filterContainer.find(".filter-button, .search-button").removeClass("invert-colors");
      const defaultDataSOrt = getDefaultDataSort();
      setIfFlatDataSort(defaultDataSOrt);
    }
    setToFlatDataSortIfSpatialDisabled();
    setFilterMenuButtonsByDataView(false);
  };

  var setFilterMenuButtonsByDataView = function (resetFirst = true) {
    const $filterContainer = $(".filter-bar");
    const $buttons = $filterContainer.find(".btn");

    if (resetFirst) {
      Tree.set("isDefaultFilter", false);

      closeSearchAndFilters();
      $("#bottomFloatingInputsTable").removeClass("hidden");

      $buttons.removeClass("disabled");
      $buttons.each(function () {
        if ($(this).prop("disabled")) {
          Misc.toggleDisabled($(this), false);
        }
      });
    }
    const disabledButtons = DataViewFunctions.getCurrentDataViewProperty(
      "filterBarDisabledButtons",
    );
    (disabledButtons || []).forEach((item) => {
      Misc.toggleDisabled($filterContainer.find(`.${item}-button`), true);
    });

    if (resetFirst) {
      $buttons.toggle(true);
    }
    const hiddenButtons = DataViewFunctions.getCurrentDataViewProperty("filterBarHiddenButtons");
    (hiddenButtons || []).forEach((item) => {
      $filterContainer.find(`.${item}-button`).toggle(false);
    });
  };

  var handleFilterMenuDisplayByDataView = function (dataView) {
    if (!dataView) {
      dataView = Tree.get("dataView");
    }

    var dataSort = getDefaultDataSort();
    setDataSort(dataSort);
    setFilterMenuButtonsByDataView(true);
  };

  var handleFilterMenuDisplayBySpatialView = function (spatialView) {
    if (!spatialView) {
      spatialView = Tree.get("spatialView");
    }

    if (SpatialViewController.isCatchmentOrDrainageView(spatialView)) {
      const $filterContainer = $(".filter-container");
      Misc.toggleDisabled($filterContainer.find(".search-button"), false);
    } else if (spatialView === "ms4") {
      handleFilterMenuDisplayByDataView();
    }
  };

  var setDataSortByAvailableDataLayers = function () {
    const availableLayers = Tree.get("availableDataSortLayers") ?? [];
    const topLevelDataSorts = DataSortFunctions.getAllDataSortConfigs();
    const currentDataViewDefaultFilter =
      DataViewFunctions.getCurrentDataViewProperty("defaultDataSort");
    const currentDataViewFlatDataSort =
      DataViewFunctions.getCurrentDataViewProperty("flatDataSort");
    const currentDataSorts = DataViewFunctions.getCurrentDataSorts();

    let defaultDataSort;

    if (
      !Offline.onLine() ||
      (Session.isParent() &&
        !Session.isUlar() &&
        !Session.isVentura() &&
        !Session.isNewDataDrivenRoadConditionGroup() &&
        currentDataViewFlatDataSort &&
        currentDataViewFlatDataSort in currentDataSorts)
    ) {
      defaultDataSort = currentDataViewFlatDataSort;
    } else if (currentDataViewDefaultFilter) {
      defaultDataSort = currentDataViewDefaultFilter;
    } else {
      defaultDataSort = Object.keys(topLevelDataSorts).find((dataSort) => {
        return (
          topLevelDataSorts[dataSort].topLevelLayer === availableLayers[0] &&
          dataSort !== "watersheds"
        );
      });
    }

    if (!defaultDataSort) {
      throw new Error(`No data sort found.`);
    }

    Tree.set(["table", "dataSort"], defaultDataSort);
    renderDataSortDropdown();
  };

  var disableNonConditionPhases = function (conditionPhases) {
    const phaseInputName = Tree.get("dataView") === "lid-project" ? "status" : "phase";
    $(".filter-dropdown")
      .find(`input[name="${phaseInputName}"], input[name="facilityPhase"]`)
      .each(function () {
        if (!conditionPhases.includes($(this).val())) {
          $(this).prop("disabled", true).closest("label").addClass("disabled");
        }
      });
  };

  var enableDisabledPhases = function () {
    const phaseInputName = Tree.get("dataView") === "lid-project" ? "status" : "phase";
    $(".filter-dropdown")
      .find(`input[name="${phaseInputName}"], input[name="facilityPhase"]`)
      .prop("disabled", false)
      .closest("label")
      .removeClass("disabled");
  };

  var getOfflineFilters = function (dataView = Tree.get("dataView")) {
    const filters = Actions.getFiltersByMapId("main");

    if (Tree.get("activeTab") === "todo") {
      const toDoFilters = ToDoFunctions.getToDoFilters(dataView);
      return { ...filters, toDoFilters };
    }

    return filters;
  };

  var updatePhaseCheckboxes = function (checkboxesToCheck) {
    ["phase", "status", "facilityPhase"].forEach(function (name) {
      $(`input[name="${name}"]`).each(function () {
        if (checkboxesToCheck.includes($(this).val())) {
          $(this).prop("checked", true);
        } else {
          $(this).prop("checked", false);
        }
        $(this).prop("disabled", false);
      });
    });
  };

  var updateConditionCheckboxes = function (checkboxesToCheck) {
    ["phase", "status", "facilityPhase"].forEach(function (name) {
      $(`input[name="${name}"]`).each(function () {
        if (checkboxesToCheck.includes($(this).val())) {
          $(this).prop("checked", true);
          $(this).prop("disabled", false);
        } else {
          $(this).prop("checked", false);
          $(this).prop("disabled", true);
        }
      });
    });
  };

  var renderFilter = function () {
    renderFilterHtml();

    if (["construction", "bmpram"].includes(Tree.get("tool"))) {
      const enablePostActivePhase = ToolSettings.getSetting(
        "construction",
        "enable-post-active-phase",
      );
      $(`.display-filters`)
        .find(`input[value="post-active"]`)
        .closest(".checkbox")
        .toggle(enablePostActivePhase);
    }

    loadFilterMenuListeners();
    storeOriginalFilterLables();
    resetFilterTitles();
  };

  var renderFilterHtml = async function () {
    const dataView = Tree.get("dataView");
    const html = await getFilterHtml(dataView, "filters");

    if (!html) {
      console.log(`No filter template configured for data view ${dataView}`);
      return;
    }

    const $menu = getFilterMenu();
    $menu.html(html);

    if (Tree.get("activeTab") !== "insight") FilterSummary.enable();

    loadFilterModule($menu, dataView);

    if (Tree.get("resetFiltersToDefaultAfterFilterMenuRender")) {
      setToDefault();
      Tree.set("resetFiltersToDefaultAfterFilterMenuRender", false);
    }

    $(".reset-to-default-button")
      .off("click", addAnalyticsResetDefaultFilterButton)
      .on("click", addAnalyticsResetDefaultFilterButton);
    $("fieldset .datepicker")
      .off("dp.change", _addDateFilterAnalytics)
      .on("dp.change", _addDateFilterAnalytics);

    MultipleSelect.init(".display-filters");
    DateRange.init(".display-filters");
  };

  var loadFilterModule = function ($menu, dataView) {
    const $filters = $menu.find(".display-filters");
    const filterModule = DataViewFunctions.getCurrentDataViewProperty("filterModule", dataView);

    lastSmartFilter?.destroy();

    if (!filterModule) {
      return;
    }

    const filterConfigs = filterModule.getFilterConfigs?.();
    if (filterConfigs) {
      lastSmartFilter = new SmartFilters(filterConfigs, {
        defaultFilters: getDefaultFilters(),
        beforeFilter: onFilterUpdate,
        onSetDefault: setFiltersDefault,
      });
      lastSmartFilter.init($filters);
      filterModule.setSmartFilters?.(lastSmartFilter);
    }

    filterModule.loadListeners?.($filters);
  };

  var getFilterHtml = async function (dataView, treeFilterPath, extraProps = {}) {
    const template = DataViewFunctions.getCurrentDataViewProperty("filterTemplate", dataView);
    const filterModule = DataViewFunctions.getCurrentDataViewProperty("filterModule", dataView);

    let props = filterModule?.getProps ? filterModule.getProps() : {};

    if (props && typeof props.then === "function") {
      props = await props;
    }

    if (!template) {
      return null;
    }

    return nunjucks.render(template, {
      ...props,
      filters: Tree.get(treeFilterPath),
      ...extraProps,
    });
  };

  var getFilterMenu = function () {
    return $(".filter-menu");
  };

  var setToDefault = function () {
    lastSmartFilter?.setToDefault();
  };

  var setFiltersDefault = function () {
    renderFilter();
    handlePhaseToggleDefaultReset();
    $(".search-input input").val("");
    setTimeout(function () {
      enableDisableSortItemsBySpatialFilters();
    }, 1);
  };

  const handlePhaseToggleDefaultReset = function () {
    $(`.condition-phase-toggle input[value=${Tree.get("filters", "conditionPhaseToggle")}]`)
      .prop("checked", true)
      .trigger("click");
  };

  var resetToDoFiltersByDataView = function (dataView) {
    const filters = ToDoConfig._getToDoFiltersConfigObjectByDataView()[dataView];
    const filterKeys = Object.keys(filters.filters);
    filterKeys.forEach((key) => {
      Tree.set(["toDoFilters", dataView, key], filters.filters[key]);
      Tree.set(["toDoFilterLabels", dataView, key], {});
    });
  };

  var setSpatialArrays = function (data) {
    let catchmentOptions = new Set();
    let receivingWaterOptions = new Set();
    let urbanDrainageOptions = new Set();
    let highwayOptions = new Set();

    for (const item of data) {
      item.drainsToC && urbanDrainageOptions.add(item.drainsToC);
      item.drainsToRw && receivingWaterOptions.add(item.drainsToRw);
      item.catchmentName && catchmentOptions.add(item.catchmentName);
      item.highwayName && highwayOptions.add(item.highwayName);
    }

    const customSort = (a, b) => {
      const regex = /([a-zA-Z\s]+)(\d*)/;
      const matchA = a.match(regex) || ["", a, ""];
      const matchB = b.match(regex) || ["", b, ""];

      // Compare text
      if (matchA[1] < matchB[1]) return -1;
      if (matchA[1] > matchB[1]) return 1;

      // Compare numbers, treat non-matches as 0
      return parseInt(matchA[2] || "0") - parseInt(matchB[2] || "0");
    };

    catchmentOptions = Array.from(catchmentOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));
    receivingWaterOptions = Array.from(receivingWaterOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));
    urbanDrainageOptions = Array.from(urbanDrainageOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));
    highwayOptions = Array.from(highwayOptions)
      .sort(customSort)
      .map((val) => ({ name: val, value: val }));

    return {
      catchmentOptions,
      receivingWaterOptions,
      urbanDrainageOptions,
      highwayOptions,
    };
  };

  const sortAndFilterData = function (
    data,
    key,
    allOptions,
    includeNoData = false,
    featureflag = false,
  ) {
    if (!featureflag) {
      if (includeNoData) {
        allOptions.push({ name: "No Data", value: "null" });
      }
      return allOptions;
    }

    const uniqueSortedValues = Array.from(new Set(data.map((item) => item[key]))).sort();
    const filteredOptions = allOptions.filter((option) =>
      uniqueSortedValues.includes(option.value),
    );

    if (includeNoData) {
      filteredOptions.push({ name: "No Data", value: "null" });
    }

    return filteredOptions;
  };

  const getSpatialArrays = function () {
    const catchmentData = ToolSettings.getSetting("topLevelDataSorts", "dataSort");
    const urbanDrainageOptions = Table.getUnique(catchmentData?.catchments, "drains_to_c");
    const receivingWaterOptions = Table.getUnique(catchmentData?.catchments, "drains_to_rw");
    const catchmentsOptions = Table.getUnique(catchmentData?.catchments, "catchid");
    const zoneOptions = catchmentData?.maintenanceZones
      ? Table.getUnique(catchmentData?.maintenanceZones, "maintenanceZoneName")
      : null;
    const highwayOptions = catchmentData?.highways
      ? Table.getUnique(catchmentData?.highways, "highwayName")
      : null;

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      zoneOptions,
      highwayOptions,
    };
  };

  return {
    setDataSort,
    forceDataSort,
    resetSpatialFilters,
    toggleFilterButtons,
    disableTrashAssessmentLayers,
    handleToolOrTabChange,
    initFilters,
    spatialFilterIsSet,
    filterDateCheckboxClick,
    resetOriginalTitleText,
    filterScoreInput,
    updateRangeTitle,
    resetFilterTitles,
    getDefaultFilters,
    getDefaultFiltersWithoutSpatial,
    getDefaultDataSort,
    renderFiltersMenu,
    renderFilter,
    handleFilterMenuDisplayByDataView,
    handleFilterMenuDisplayBySpatialView,
    resetSpatialTreeSelected,
    setToFlatDataSortIfSpatialDisabled,
    setDataSortByAvailableDataLayers,
    disableNonConditionPhases,
    enableDisabledPhases,
    mobileFilterSearchExpansionTableAdjustment,
    renderDataSortDropdown,
    getOfflineFilters,
    setFlatDataSort,
    getFilterHtml,
    filterSelectAllClick,
    loadFilterBarListeners,
    clearDateRange,
    _setFilterToDefault,
    filterButtonClick,
    _handleConstructionFilterMenueOnChange,
    _handleFilterPannelUpdateOnDataTab,
    updateConditionCheckboxes,
    updatePhaseCheckboxes,
    countCheckedCheckboxes,
    resetToDoFiltersByDataView,
    setSpatialArrays,
    sortDropdownClick,
    sortAndFilterData,
    getSpatialArrays,
    enableListItems,
    closeSearch,
    loadAnalyticsListeners,
    setToDefault,
    showFilterMenu,
  };
};

module.exports = Filters();

const Actions = require("../actions");
const Analytics = require("../general/analytics");
const DataList = require("../mapFunctions/dataList");
const DataSortFunctions = require("../dataSortFunctions");
const DataViewController = require("./dataViewController");
const DataViewFunctions = require("../dataViewFunctions");
const DateTime = require("../dateTime");
const DisplayOptions = require("./displayOptions");
const Dropdown = require("../general/dropdown");
const FilterConstants = require("../filterConstants");
const FormFunctions = require("./formFunctions");
const MapFunctions = require("./mapFunctions");
const MapStyles = require("./mapStyles");
const Misc = require("../misc");
const NunjucksFunctions = require("../general/nunjucksFunctions.js");
const Offline = require("../offline/offline");
const Session = require("../login/session");
const SpatialViewController = require("./spatialViewController");
const ToDoFunctions = require("./toDoFunctions");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const Table = require("./table");
const FilterSummary = require("../filters/filterSummary");
const DateRange = require("../filters/dateRange");
const MultipleSelect = require("../general/multipleSelect");
const MeasurementMinMax = require("../general/measurementMinMax");
const ToDoConfig = require("../config/toDoConfig");
const PageFunctions = require("../pageFunctions.js");
const SmartFilters = require("./smartFilters.js");
