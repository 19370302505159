"use strict";

const ReopenPopups = function () {
  var _lastRemovedPopupId = null;

  const loadMapListeners = function (map) {
    map.off("layeradd", _onLayerAdd);
    map.off("layerremove", _onLayerRemove);
    map.off("popupopen", _onPopupOpen);

    map.on("layeradd", _onLayerAdd);
    map.on("layerremove", _onLayerRemove);
    map.on("popupopen", _onPopupOpen);
  };

  const _onLayerAdd = function (e) {
    const layer = e.layer;

    _openPopupIfSameId(layer, getLastRemovedPopupId());
  };

  const _openPopupIfSameId = function (layer, id) {
    if (id === null || !layer.feature) {
      return;
    }

    const shouldOpenPopup = _getLayerPropertyId(layer) === id;
    if (shouldOpenPopup) {
      layer.openPopup();
    }
  };

  const _onLayerRemove = function (e) {
    const layer = e.layer;

    _saveOpenPopupLayerId(layer);
  };

  const _saveOpenPopupLayerId = function (layer) {
    if (layer.feature && layer.isPopupOpen()) {
      _lastRemovedPopupId = _getLayerPropertyId(layer);
    }
  };

  const _getLayerPropertyId = function (layer) {
    return layer.feature.properties.id || layer.feature.properties.idBmp;
  };

  const _onPopupOpen = function () {
    clearLastRemovedPopupId();
  };

  const clearLastRemovedPopupId = function () {
    _lastRemovedPopupId = null;
  };

  const getLastRemovedPopupId = function () {
    return _lastRemovedPopupId;
  };

  return {
    loadMapListeners,
    _openPopupIfSameId,
    _saveOpenPopupLayerId,
    getLastRemovedPopupId,
    clearLastRemovedPopupId,
  };
};

module.exports = ReopenPopups();
