"use strict";

const FormFunctions = function () {
  const $fcsAssessmentForm = $("#fcs-assessment-form");

  var getValidAndRoundedNumberInput = function ($input) {
    var numberString = null;
    const validity = $input[0].validity;
    const fixed = getNumberInputValidNumberOfDecimals($input);

    if (validity.rangeOverflow) {
      numberString = parseFloat($input.attr("max")).toFixed(fixed);
    } else if (validity.rangeUnderflow) {
      numberString = parseFloat($input.attr("min")).toFixed(fixed);
    } else if (validity.stepMismatch) {
      numberString = parseFloat($input.val()).toFixed(fixed);
    } else {
      numberString = $input.val();
    }

    var number = parseFloat(numberString);
    if (isNaN(number)) {
      number = null;
      numberString = "";
    }

    $input.val(numberString);
    return number;
  };

  var getNumberInputValidNumberOfDecimals = function ($input) {
    const step = $input.attr("step");
    let fixed = 0;

    if (step) {
      const dotIndex = step.indexOf(".");

      if (dotIndex != -1) {
        fixed = step.length - dotIndex - 1;
      }
    }

    return fixed;
  };

  const getInvalidFcsAssessmentFields = function () {
    var inputs = $fcsAssessmentForm.find("input:visible").toArray();
    var invalidFieldNames = [];

    for (const i in inputs) {
      if (invalidFieldNames.includes(inputs[i].name)) {
        break;
      }

      //validate inputs by input requirements
      if (!inputs[i].checkValidity()) {
        invalidFieldNames.push(inputs[i].name);
      } else if (inputs[i].hasAttribute("req")) {
        //at least one radio button is checked
        if (inputs[i].type == "radio" && !$("input[name=" + inputs[i].name + "]:checked").length) {
          invalidFieldNames.push(inputs[i].name);
          //number & text fields
        } else if (inputs[i].value == null || inputs[i].value == "") {
          invalidFieldNames.push(inputs[i].name);
        }
      }
    }
    //at least one trash counts selected
    if (
      $(".trash-count-div").is(":visible") &&
      Tree.get("asset", "observation", "trash_counts") &&
      !Tree.get("asset", "observation", "trash_counts").length
    ) {
      invalidFieldNames.push("trash_counts");
    }

    //volume drop downs
    if ($("select[name=trash_volume]").is(":visible")) {
      if (
        $("select[name=trash_volume]").val() &&
        $("select[name=trash_volume]").val().toLowerCase() == "select"
      ) {
        invalidFieldNames.push("trash_volume");
      } else if (
        $("select[name=mobile_percent]").val() &&
        $("select[name=mobile_percent]").val().toLowerCase() == "select"
      ) {
        invalidFieldNames.push("mobile_percent");
      }
    }

    return invalidFieldNames;
  };

  var saveFcsAssessmentInfo = function (callback) {
    let fieldNames = getInvalidFcsAssessmentFields();
    var fcsName = Tree.get(["asset", "observation", "fcs_name"]);
    if (fieldNames.length > 0) {
      CommonModalFunctions.removeHighlights($fcsAssessmentForm);
      for (const name of fieldNames) {
        CommonModalFunctions.highlightParent($fcsAssessmentForm.find("[name='" + name + "']"));
      }

      fieldNames = fieldNames.map((name) => FormConstants.fcsAssessmentLabels[name]);

      SaveIncompleteModal.showConfirmSaveIncompleteModal(
        fcsName,
        "assessment",
        fieldNames,
        function () {
          finishsaveFcsAssessmentInfo(callback);
        },
      );
    } else {
      finishsaveFcsAssessmentInfo(callback);
    }
  };

  var finishsaveFcsAssessmentInfo = function (callback) {
    const update = Tree.get("asset", "update");
    let data = Tree.get("asset", "observation");
    var trashCountsCopy = JSON.parse(JSON.stringify(data.trash_counts));
    data.trash_counts.forEach(function (v) {
      delete v.id;
    });
    data = removeNullFields(data);

    if (!update) {
      ApiCalls.saveNewFcsObs(data, function (obsId) {
        PopupPhotos.saveTempPhotos("obs", obsId);

        if (callback) {
          callback(obsId);
        } else {
          MainMap.reloadBmpFcsLayer();
          BmpFunctions.closeFcsModal();
        }
      });
    } else {
      ApiCalls.updateFcsObs(data, function (obsId) {
        PopupPhotos.saveTempPhotos("obs", obsId);
        PopupPhotos.setCurrentId($("#fcs-assessment-modal").data("idBmp"));

        if (callback) {
          callback(obsId);
        } else {
          BmpFunctions.closeFcsModal();
        }
      });
    }
    data.trash_counts = trashCountsCopy;
    $(".leaflet-popup-close-button").click();
  };

  // @TODO: Check if this function is still necessary
  var reloadMarkerLayer = function () {
    //on cancel/save, reload bmp or fcs layer after edit details
    var layerCheck = Tree.get("layers", "fcs", "isEnabled")
      ? true
      : Tree.get("layers", "bmps", "isEnabled")
        ? true
        : false;
    if (layerCheck) {
      Tree.set("modalDefault", null);
    } else {
      var modalDefault = Tree.get("modalDefault");
      if (modalDefault) {
        $(".radio-" + modalDefault)[1].click();
        Tree.set("modalDefault", null);
      }
    }
  };

  var removeNullFields = function (data) {
    for (const property in data) {
      if (data.hasOwnProperty(property) && !data[property] && data[property] !== 0) {
        delete data[property];
      }
    }
    return data;
  };

  var cancelFcsAssessment = function () {
    MessageModal.showConfirmWarningModal(
      null,
      function () {
        var obsId = Tree.get("asset", "observation", "obs_id");
        if (obsId) {
          ApiCalls.deleteFcsAssessment(obsId);
        }
        BmpFunctions.closeFcsModal();
      },
      "Go Back",
      "Close Without Saving",
      CommonModalFunctions.getCloseWithoutSavingPrompt(),
    );
  };

  const handleExtraRequiredFields = function (requiredFields, containerSelector, dataSelector) {
    requiredFields.forEach((item) => {
      const $infoInput = $(containerSelector).find(`[${dataSelector}='${item}']`);
      $infoInput.show();
      $infoInput.find(".form-label").addClass("required");
      $infoInput.find("input").attr("required", true);

      const readOnlyFileds = $(containerSelector).find(".info-input.read-only");
      readOnlyFileds.find(".form-label").removeClass("required");
      readOnlyFileds.find("input").attr("required", false);
    });
  };

  const handleExtraOptionalFields = function (optionalFields, containerSelector, dataSelector) {
    optionalFields.forEach((item) => {
      const $infoInput = $(containerSelector).find(`[${dataSelector}='${item}']`);
      $infoInput.show();
      $infoInput.find(".form-label").removeClass("required");
      $infoInput.find("input").attr("required", false);
    });
  };

  const loadFcsAssessmentFormListeners = function () {
    $fcsAssessmentForm.find("input[type='radio']").off("change");
    $fcsAssessmentForm.find("input[type='radio']").on("change", function () {
      var data_field = $(this).attr("name");
      var data_value = $(this).val();
      Tree.set(["asset", "observation", data_field], data_value);
      setFcsAssessmentScoreButton();
    });
    $fcsAssessmentForm.find("select").off("change");
    $fcsAssessmentForm.find("select").on("change", function () {
      var data_field = $(this).attr("name");
      var data_value = $(this).val();
      var data_id = parseInt($(this).attr("data-id"));
      if (data_field == "trash_counts") {
        var countObjArray = Tree.get("asset", "observation", "trash_counts");
        //increment based on last record id
        if (data_value != "select") {
          //change select option to remove
          this.options[0].innerHTML = "Remove";
          //on selection add new dropdown
          if ($("select[name=trash_counts]").last().val() != "select") {
            countObjArray.push({ id: data_id, trash_count: data_value });
            var index = countObjArray.length ? getMaxDataId(countObjArray) : 1;
            $(".trash-count-div").append(
              "<select req name='trash_counts' data-id= " +
                index +
                " class='form-control additionalInfo-input'>\
              <option value='select' selected>Select</option>\
              <option value=0>No Trash</option>\
              <option value=2.5>&lt;5 pieces</option>\
              <option value=10>&lt;15</option>\
              <option value=20>&lt;25</option>\
              <option value=30>&lt;35</option>\
              <option value=40>&ge;35</option>\
              </select>",
            );
            loadFcsAssessmentFormListeners();
          } else {
            //remove value from array to replace with new value
            const selectedIndex = countObjArray.findIndex((item) => item.id === data_id);
            countObjArray[selectedIndex].trash_count = data_value;
          }
        } else {
          //remove select div & value from counts array
          $(this).remove();
          countObjArray = $.grep(countObjArray, function (e) {
            return e.id !== data_id;
          });
        }
        Tree.set(["asset", "observation", "trash_counts"], countObjArray);
        Tree.set(["asset", "observation", "avg_trash_count"], getAverageTrashCount(countObjArray));
      } else {
        Tree.set(["asset", "observation", data_field], data_value);
      }
      setFcsAssessmentScoreButton();
    });
    $fcsAssessmentForm.find("textarea").off("blur");
    $fcsAssessmentForm.find("textarea").on("blur", function () {
      var data_field = $(this).attr("name");
      var data_value = $(this).val();
      Tree.set(["asset", "observation", data_field], data_value);
      setFcsAssessmentScoreButton();
    });
    $fcsAssessmentForm.find("input[type='text']").off("blur", onFcsTextBlur);
    $fcsAssessmentForm.find("input[type='text']").on("blur", onFcsTextBlur);
    $fcsAssessmentForm.find("input[type='number']").off("change");
    $fcsAssessmentForm.find("input[type='number']").on("change", function () {
      var dataField = $(this).attr("name");
      var dataValue = $(this).val();
      var updatedValue = updateNumberInputs($(this), dataField, dataValue);
      Tree.set(["asset", "observation", dataField], updatedValue);
      setFcsAssessmentScoreButton();
    });
  };

  var onFcsTextBlur = function () {
    var data_field = $(this).attr("name");
    var data_value = $(this).val();
    if (data_field === "location_count") {
      return;
    } else if (data_field === "date") {
      const acceptedDate = Tree.get("lastTrashAcceptedDate");
      if (acceptedDate !== null && new Date(data_value) < new Date(acceptedDate)) {
        const formattedAcceptedDate = DateTime.formatTimestampStringAsDisplayDate(acceptedDate);
        MessageModal.showSimpleWarningModal(
          `Assessment date can not be in a previous reporting period. Enter a date after ${formattedAcceptedDate}`,
        );
        $fcsAssessmentForm.find("input[name='date']").data("DateTimePicker").date(new Date());
        return;
      }
    }

    Tree.set(["asset", "observation", data_field], data_value);
    setFcsAssessmentScoreButton();
  };

  var updateNumberInputs = function (input, dataField, dataValue) {
    var fullOrCloged = dataField == "full_percent" || dataField == "clog_percent";
    //round clog percentage to nearest 10%
    if (fullOrCloged && parseFloat(dataValue) % 10 !== 0) {
      dataValue = Math.round(parseFloat(dataValue) / 10) * 10;
      input.val(dataValue);
    }
    if (fullOrCloged && dataValue > 100) {
      dataValue = 100;
      input.val(100);
    }
    return dataValue;
  };

  const populateFcsAssessmentForm = function (data) {
    var $obsInfoForm = $("#fcs-assessment-form");

    if (data.date) {
      $obsInfoForm.find("input[name='date']").data("DateTimePicker").date(data.date);
    }

    if (data.personnel) {
      $obsInfoForm.find("input[name='personnel']").val(data.personnel);
    }

    if (data.clog_percent) {
      $obsInfoForm.find("input[name='clog_percent']").val(Number(data.clog_percent));
    }

    if (data.screen_count) {
      $obsInfoForm.find("input[name='screen_count']").val(Number(data.screen_count));
    }

    if (data.clog_cleaned !== null) {
      if (data.clog_cleaned) {
        $obsInfoForm.find("input[name='clog_cleaned'][value=1]").prop("checked", true);
      } else {
        $obsInfoForm.find("input[name='clog_cleaned'][value=0]").prop("checked", true);
      }
    }

    if (data.screen_damaged !== null) {
      if (data.screen_damaged) {
        $obsInfoForm.find("input[name='screen_damaged'][value=1]").prop("checked", true);
      } else {
        $obsInfoForm.find("input[name='screen_damaged'][value=0]").prop("checked", true);
      }
    }

    if (data.screen_repaired !== null) {
      if (data.screen_repaired) {
        $obsInfoForm.find("input[name='screen_repaired'][value=1]").prop("checked", true);
      } else {
        $obsInfoForm.find("input[name='screen_repaired'][value=0]").prop("checked", true);
      }
    }

    //@TODO Populate trash count fields
    if (data.trash_counts && data.trash_counts.length) {
      //set first select
      $("select[name=trash_counts]").eq(0).val(data.trash_counts[0].trash_count);
      $("select[name=trash_counts]").eq(0).attr("data-id", data.trash_counts[0].id);

      //populate any additional trash count select divs
      for (let i = 1; i < data.trash_counts.length; i++) {
        $(".trash-count-div").append(
          "<select req name='trash_counts' data-id= " +
            data.trash_counts[i].id +
            " class='form-control additionalInfo-input'>\
          <option value='select' selected>Remove</option>\
          <option value=0>No Trash</option>\
          <option value=2.5>&lt;5 pieces</option>\
          <option value=10>&lt;15</option>\
          <option value=20>&lt;25</option>\
          <option value=30>&lt;35</option>\
          <option value=40>&ge;35</option>\
          </select>",
        );
        //select correct option
        $("select[name=trash_counts]").eq(i).val(data.trash_counts[i].trash_count);
      }
      var index = data.trash_counts.length ? getMaxDataId(data.trash_counts) : 1;
      //append empty select
      $(".trash-count-div").append(
        "<select req name='trash_counts' data-id= " +
          index +
          " class='form-control additionalInfo-input'>\
        <option value='select' selected>Select</option>\
        <option value=0>No Trash</option>\
        <option value=2.5>&lt;5 pieces</option>\
        <option value=10>&lt;15</option>\
        <option value=20>&lt;25</option>\
        <option value=30>&lt;35</option>\
        <option value=40>&ge;35</option>\
        </select>",
      );
    }

    if (data.trash_volume !== null) {
      $obsInfoForm.find("select[name='trash_volume']").val(data.trash_volume);
    }

    if (data.mobile_percent !== null) {
      $obsInfoForm.find("select[name='mobile_percent']").val(data.mobile_percent);
    }

    if (data.full_percent !== null) {
      $obsInfoForm.find("input[name='full_percent']").val(data.full_percent);
    }

    if (data.notes) {
      $obsInfoForm.find("textarea[name='notes']").val(data.notes);
    }

    setTimeout(function () {
      if (data.trash_removed !== null) {
        if (data.trash_removed) {
          if (data.full_percent !== null) {
            $obsInfoForm
              .find(".trash-percent input[name='trash_removed'][value=1]")
              .prop("checked", true);
          } else {
            $obsInfoForm
              .find(".trash-mobility input[name='trash_removed'][value=1]")
              .prop("checked", true);
          }
        } else {
          if (data.full_percent !== null) {
            $obsInfoForm
              .find(".trash-percent input[name='trash_removed'][value=0]")
              .prop("checked", true);
          } else {
            $obsInfoForm
              .find(".trash-mobility input[name='trash_removed'][value=0]")
              .prop("checked", true);
          }
        }
      }
    }, 150);
  };
  const getMaxDataId = function (source) {
    const maxId = Math.max.apply(
      Math,
      source.map(function (f) {
        return f.id;
      }),
    );
    return maxId + 1;
  };
  const setFcsAssessmentScoreButton = function () {
    const isInvalid = getInvalidFcsAssessmentFields().length > 0;
    Misc.toggleDisabled($(".assessment-calc-score"), isInvalid || Session.isRegulator());
  };

  const getAverageTrashCount = function (trashCountArray) {
    let total = 0.0;
    for (const obj in trashCountArray) {
      total += parseFloat(trashCountArray[obj].trash_count);
    }
    return total / trashCountArray.length;
  };

  const resetObsForm = function () {
    Misc.toggleDisabled($(".assessment-calc-score"), true);
    Tree.set(["asset", "update"], false);
    clearFcsAssessmentInputs();
    _resetObsTreeState();
    clearFcsAssessmentSelects();
  };

  const clearFcsAssessmentInputs = function () {
    $fcsAssessmentForm.find("input[type=radio]").prop("checked", false);
    $fcsAssessmentForm.find("input[type=number]").val("");
    $fcsAssessmentForm.find("input[type=text]").val("");
    $fcsAssessmentForm.find("textarea").val("");
    $fcsAssessmentForm.find("select").val("select");

    CommonModalFunctions.removeHighlights($fcsAssessmentForm);
  };

  const clearFcsAssessmentSelects = function () {
    const selects = $fcsAssessmentForm.find("select[name=trash_counts]").toArray();
    for (let i = 1; i < selects.length; i++) {
      selects[i].remove();
    }
    selects[0].options[0].innerHTML = "Select";
  };

  const _resetObsTreeState = function () {
    const initialState = TreeInitialDataRam.getStaticData();
    Tree.set(["asset", "observation"], initialState.asset.observation);
    Tree.set(["asset", "observation", "trash_counts"], []);
    reloadMarkerLayer();
  };

  return {
    saveFcsAssessmentInfo,
    cancelFcsAssessment,
    resetObsForm,
    loadFcsAssessmentFormListeners,
    populateFcsAssessmentForm,
    setFcsAssessmentScoreButton,
    handleExtraRequiredFields,
    handleExtraOptionalFields,
    getValidAndRoundedNumberInput,
    getNumberInputValidNumberOfDecimals,
    getMaxDataId,
    removeNullFields,
    _resetObsTreeState,
  };
};

module.exports = FormFunctions();

const ApiCalls = require("../apiCalls");
const BmpFunctions = require("../modals/bmpFunctions");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const DateTime = require("../dateTime");
const FormConstants = require("./formConstants");
const MainMap = require("./mainMap");
const MessageModal = require("../modals/messageModal");
const SaveIncompleteModal = require("../modals/saveIncompleteModal");
const Misc = require("../misc");
const PopupPhotos = require("./popupPhotos");
const Session = require("../login/session");
const Tree = require("../tree");
const TreeInitialDataRam = require("../treeInitialDataRam");
