"use strict";

const Form = function () {
  var state = {};
  var parentElements = {};
  var readyToSavePromises = {};
  var dataStores = {};

  const inputListenerEvent = "input";
  const inputListenerSelector =
    "input:not(.autonumeric):not(.date-picker):not(.drop-zone-input), select, textarea";

  var initializeAndLoadListeners = function (
    $parent,
    formKey,
    { isMultiPart = false, dataStore = null } = {},
  ) {
    if (state.hasOwnProperty(formKey)) {
      if (isMultiPart) {
        unloadListeners(formKey);
      } else {
        console.error(
          `Form ${formKey} is already initialized. State is ${JSON.stringify(
            state,
          )}. It should be removed before reinitializing.`,
        );
        return;
      }
    }

    state[formKey] = state[formKey] ?? {};
    dataStores[formKey] = dataStore;
    readyToSavePromises[formKey] = readyToSavePromises[formKey] || [];
    parentElements[formKey] = $parent;
    initializeDatePickers($parent, formKey);
    initializeAutoNumericFields($parent);
    initializeYearInputs($parent);
    initializePhotos($parent, formKey);
    initializeSignatures($parent, formKey);
    loadListeners($parent, formKey);
    OtherInput.loadListeners($parent);
    EditableItems.loadListeners($parent);
    CollapsingCard.initializeCards($parent);
    LocationButton.initializeButtons($parent, formKey);
  };

  var isInitialized = function (formKey) {
    return typeof _getState(formKey) === "object";
  };

  var initializeDatePickers = function ($parent, formKey) {
    // Date pickers don't use events, it uses callbacks instead,
    // so we can't listen to the parent, and instead need to
    // reinitialize every time a new HTML picker is created.

    DateTimePicker.initializeDateTimePickers($parent, saveDateInputForForm(formKey));
  };

  var initializeSignatures = function ($parent, formKey) {
    $parent.find("canvas.signature-pad").each(function (index, canvasElement) {
      new Signature($(canvasElement), ($element, value) => saveInput(formKey, $element, value));
    });
  };

  var initializeAutoNumericFields = function ($parent) {
    $parent.find(".autonumeric").each((ind, elem) => {
      NumberInput.initializeAutoNumericFieldByClass($(elem));
    });
  };

  var initializeYearInputs = function ($parent) {
    $parent.find(".year-input").each((ind, elem) => {
      NumberInput.initializeYearInput($(elem));
    });
  };

  var initializePhotos = function ($parent, formKey) {
    $parent.find(".form-photos").each((ind, elem) => {
      FormPhotos.initializeFormPhotos($(elem), formKey);
    });
  };

  var loadListeners = function ($parent, formKey) {
    $parent.on(inputListenerEvent, inputListenerSelector, saveInputForForm(formKey));
    NumberInput.loadAutoNumericListener($parent, ".autonumeric", saveAutoNumericForForm(formKey));
    SelectInput.removeNoSelectionDefaultOnInput($parent);
  };

  var saveDateInputForForm = function (formKey) {
    // https://flatpickr.js.org/events/
    return function (selectedDates, dateStr, instance) {
      const $input = $(instance.input);
      // Setting to empty string instead of null for backwards compatibility
      const value = dateStr === "" ? "" : DateTimePicker.getIsoDate($input);
      saveInput(formKey, $input, value);
    };
  };

  var saveAutoNumericForForm = function (formKey) {
    // https://github.com/autoNumeric/autoNumeric/blob/master/README.md#autonumeric-custom-events-details
    return function (e) {
      saveInput(formKey, $(e.currentTarget), e.newValue);
    };
  };

  var saveInputForForm = function (formKey) {
    return function (e) {
      const $input = $(e.currentTarget);
      saveInput(formKey, $input, getValueFromInput(formKey, $input));
    };
  };

  var saveInput = function (formKey, $input, value) {
    if (!$input.hasClass("excluded") && $input.attr("name") !== undefined) {
      const pathParts = getPathPartsFromName($input.attr("name"));
      const oldValue = getFormDataAtPath(formKey, pathParts);
      _setFormDataField(formKey, pathParts, value);
      triggerFormInputEvent($input, value, formKey, pathParts, oldValue);
    }
  };

  var triggerFormInputEvent = function (currentTarget, value, formKey, pathParts, oldValue) {
    $(currentTarget).trigger("2N:FormInput", [
      value,
      pathParts,
      _getState(formKey),
      formKey,
      oldValue,
    ]);
  };

  var getPathPartsFromName = function (name) {
    return removeClosingBrackets(name)
      .split("[")
      .map((part) => Misc.kebabToCamel(part));
  };

  var removeClosingBrackets = function (string) {
    return string?.replace(/\]/g, "");
  };

  var manuallySetFormDataField = function (formKey, path, value) {
    const oldValue = getFormDataAtPath(formKey, path);
    _setFormDataField(formKey, path, value);
    triggerFormInputEvent(parentElements[formKey], value, formKey, path, oldValue);
  };

  var manuallySetDateTime = function (formKey, path, value, $parentOrInput, inputName = null) {
    DateTimePicker.setDate($parentOrInput, inputName, value);
    manuallySetFormDataField(formKey, path, value);
  };

  var _setFormDataField = function (formKey, path, value) {
    if (
      !assertIsArray(path, `path must be an array, was ${path}.`) ||
      !assertIsDefined(value, "value was undefined. Use manuallyUnsetField to unset Form state.") ||
      !assertFormKeyExists(formKey)
    ) {
      return;
    }
    _setStateAtPath([formKey, ...path], value);
  };

  var assertIsDefined = function (input, message) {
    if (input === undefined) {
      console.error(message);
      return false;
    }
    return true;
  };

  var assertIsArray = function (input, message) {
    if (!Array.isArray(input)) {
      console.error(message);
      return false;
    }
    return true;
  };

  var manuallyUnsetField = function (formKey, path) {
    const oldValue = getFormDataAtPath(formKey, path);
    _unsetFormDataField(formKey, path);
    triggerFormInputEvent(parentElements[formKey], undefined, formKey, path, oldValue);
  };

  var _unsetFormDataField = function (formKey, path) {
    if (
      !assertIsArray(path, `path must be an array, was ${path}.`) ||
      !assertFormKeyExists(formKey)
    ) {
      return;
    }
    unsetStateAtPath(formKey, path);
  };

  var _setStateAtPath = function (pathParts, value) {
    const formKey = pathParts[0];
    pathParts = pathParts.slice(1);
    const currentState = _getState(formKey);

    let currNode = currentState;
    pathParts.forEach((part, index) => {
      const isFinalElement = index === pathParts.length - 1;
      if (isFinalElement) {
        currNode[part] = value;
      } else {
        const nextPart = pathParts[index + 1];
        if (currNode[part] === undefined) {
          if (isNaN(nextPart)) {
            currNode[part] = {};
          } else {
            currNode[part] = [];
          }
        }
        currNode = currNode[part];
      }
    });

    if (pathParts.length) {
      _setState(formKey, currentState);
    } else {
      _setState(formKey, value);
    }
  };

  var unsetStateAtPath = function (formKey, pathParts) {
    const currentState = _getState(formKey);
    let currNode = currentState;
    let ancestorToDelete = null;
    let parentToDeleteAncestorFrom = null;

    pathParts.forEach((part, index) => {
      const isFinalElement = index === pathParts.length - 1;
      if (isFinalElement) {
        if (currNode[part] !== undefined && ancestorToDelete !== null) {
          delete parentToDeleteAncestorFrom[ancestorToDelete];
        } else {
          delete currNode[part];
        }
      } else {
        if (currNode[part]) {
          const isOnlyChild =
            (typeof currNode[part] === "object" && Object.keys(currNode[part]).length === 1) ||
            (Array.isArray(currNode[part]) && currNode[part].length === 1);
          if (isOnlyChild && ancestorToDelete === null) {
            parentToDeleteAncestorFrom = currNode;
            ancestorToDelete = part;
          } else if (!isOnlyChild) {
            parentToDeleteAncestorFrom = null;
            ancestorToDelete = null;
          }
          currNode = currNode[part];
        }
      }
    });

    _setState(formKey, currentState);
    $(parentElements[pathParts[0]]).trigger("2N:FormInput", [undefined, pathParts]);
  };

  var getFormDataAtPath = function (formKey, path) {
    if (
      !assertIsArray(path, `path must be an array, was ${path}.`) ||
      !assertFormKeyExists(formKey)
    ) {
      return;
    }
    return getDataAtPath(_getState(formKey), path);
  };

  var getDataAtPath = function (data, path) {
    return path.reduce(
      (item, index) => (item && item[index] !== undefined ? item[index] : null),
      data,
    );
  };

  var getInputNameFromPath = function (path) {
    const kebabPath = path.map((pathPart) => Misc.camelToKebab(pathPart));
    const firstPart = kebabPath[0];
    const trailingParts = kebabPath.slice(1);
    const trailingPath = trailingParts.reduce(
      (existingPath, pathPart) => `${existingPath}[${pathPart}]`,
      "",
    );

    return `${firstPart}${trailingPath}`;
  };

  var getValueFromInput = function (formKey, $input) {
    if ($input.is(":checkbox")) {
      const name = $input.prop("name");
      return Checkbox.getValueListFromCheckboxGroup(parentElements[formKey], name);
    }
    if ($input.is("select") && $input.val() === "no-selection") {
      return null;
    }
    if (OtherInput.isOtherInput($input)) {
      return OtherInput.getOtherTextInput($input);
    }
    const value = $input.val();
    if (["true", "false"].includes(value)) {
      return $input.val() === "true";
    }
    return value;
  };

  var getDataFromForm = function (formKey, clearFormData = true) {
    const data = _getState(formKey);
    if (clearFormData) {
      clearForm(formKey);
    }
    return data;
  };

  // Visible for testing
  var clearForm = function (formKey) {
    delete state[formKey];
    unloadListeners(formKey);
    delete parentElements[formKey];
    delete readyToSavePromises[formKey];
    delete dataStores[formKey];
  };

  var unloadListeners = function (formKey) {
    const $parent = parentElements[formKey];
    if ($parent !== undefined) {
      $parent.off(inputListenerEvent, inputListenerSelector);
      NumberInput.removeAutoNumericListener($parent, ".autonumeric");
      SelectInput.unloadNoSelectionListener($parent);
      OtherInput.unloadListeners($parent);
      EditableItems.unloadListeners($parent);
      FormPhotos.unloadListeners($parent);
      DateTimePicker.unloadDateTimePickerListeners($parent);
    }
  };

  var assertFormKeyExists = function (formKey, message) {
    if (!isInitialized(formKey)) {
      console.error(message || `Form key does not exist: ${formKey}.`);
      return false;
    }
    return true;
  };

  var assertCleared = function (formKey) {
    if (!$.isEmptyObject(_getState(formKey))) {
      console.error(
        `Form state is expected to be clean, but is ${JSON.stringify(_getState(formKey))}`,
      );
      clearForm(formKey);
    }
  };

  var isFormEmpty = function (formKey) {
    return Object.keys(_getState(formKey) || {}).length === 0;
  };

  var initializeDropzone = function (
    formKey,
    $parent,
    {
      newFiles = [],
      existingFiles = [],
      maxNumberFiles = null,
      readOnly = false,
      fileChangeCallback = null,
      downloadCallback = null,
    } = {},
  ) {
    const dropzone = Dropzone($parent, readOnly, maxNumberFiles);
    setDropzoneState(dropzone, formKey, $parent, newFiles, existingFiles);
    setDropzoneInputChange(dropzone, formKey, $parent, fileChangeCallback);
    setDropzoneRemoveExisting(dropzone, formKey, $parent);
    setDropzoneDownloadHandler(dropzone, existingFiles, downloadCallback);
    dropzone.setFileSizeWarning(function (name) {
      MessageModal.showFileSizeWarning(name);
    });
    return dropzone;
  };

  var setDropzoneState = function (dropzone, formKey, $parent, newFiles, existingFiles) {
    const trashedFiles = getDropzoneTrashedFiles(formKey, $parent);
    const undeletedExistingFilenames = getUndeletedExistingFilenames(existingFiles, trashedFiles);

    dropzone.reset(newFiles, undeletedExistingFilenames, trashedFiles);
  };

  var getUndeletedExistingFilenames = function (existingFiles, trashedFiles) {
    const trashedSet = new Set(trashedFiles);
    const undeletedExistingFilenames = [];

    for (const maybeFilename of existingFiles ?? []) {
      const filename = maybeFilename?.filename ?? maybeFilename;

      if (!trashedSet.has(filename)) {
        undeletedExistingFilenames.push(filename);
      }
    }

    return undeletedExistingFilenames;
  };

  var getDropzoneTrashedFiles = function (formKey, $parent) {
    const trashedPath = getDropZoneTrashedPath($parent);

    if (!isInitialized(formKey) || !trashedPath) {
      return [];
    }

    return getFormDataAtPath(formKey, trashedPath) ?? [];
  };

  var setDropzoneInputChange = function (dropzone, formKey, $parent, fileChangeCallback) {
    const setDropzoneFiles = async function () {
      const pathParts = getDropZonePath($parent);
      const files = dropzone.getFiles();

      if (getDataStore(formKey)) {
        await directUploadDropzoneFiles(formKey, pathParts, files);
      }

      if (files.length === 0) {
        // API validators fail when we send an empty array of files
        unsetStateAtPath(formKey, pathParts);
      } else {
        _setStateAtPath([formKey, ...pathParts], files);
      }

      if (fileChangeCallback) {
        fileChangeCallback(files);
      }
    };

    const dropzoneInputChange = function () {
      addReadyToSaveLock(formKey, setDropzoneFiles());
    };

    dropzone.setHiddenInputChangeCallback(dropzoneInputChange);
    dropzone.setFileZoneDropCallback(dropzoneInputChange);
    dropzone.setRemoveNewCallback(dropzoneInputChange);
  };

  var setDropzoneRemoveExisting = function (dropzone, formKey, $parent) {
    const dropzoneRemoveExisting = function (filename) {
      const path = getDropZoneTrashedPath($parent);
      const currentFileData = getFormDataAtPath(formKey, path) || [];
      currentFileData.push(filename);
      manuallySetFormDataField(formKey, path, currentFileData);
    };

    dropzone.setRemoveExistingCallback(dropzoneRemoveExisting);
  };

  var setDropzoneDownloadHandler = function (dropzone, existingFiles, downloadCallback) {
    if (!existingFiles || !downloadCallback) {
      return;
    }

    Downloader.listenToDropzone(dropzone, existingFiles, downloadCallback);
  };

  var directUploadDropzoneFiles = async function (formKey, pathParts, files) {
    const existingFiles = getFormDataAtPath(formKey, pathParts) ?? [];

    for (const i in files) {
      const existingFile = existingFiles.find((file) => file?.name === files[i].name);
      if (existingFile) {
        files[i] = existingFile;
      } else {
        files[i] = {
          directUpload: await addFile(formKey, files[i]),
          name: files[i].name,
        };
      }
    }
  };

  var getDropZonePath = function ($parent) {
    const name = $parent.find(".drop-zone").attr("name");

    if (!name) {
      return null;
    }

    return getPathPartsFromName(name);
  };

  var getDropZoneTrashedPath = function ($parent) {
    const path = getDropZonePath($parent);

    if (!path) {
      return null;
    }

    return ["trashedFiles", ...path];
  };

  var toFormData = function (object) {
    return ObjectToFormData.serialize(object, {
      // https://github.com/therealparmesh/object-to-formdata
      indices: true,
      allowEmptyArrays: true,
    });
  };

  var addReadyToSaveLock = function (formKey, promise) {
    readyToSavePromises[formKey].push(promise);
  };

  var getReadyToSavePromise = function (formKey, showLoadingScreenUntilReady = true) {
    const promise = Promise.all(readyToSavePromises[formKey] ?? []);
    readyToSavePromises[formKey] = [];

    if (showLoadingScreenUntilReady) {
      LoadingScreen.showWhilePromisePending(promise);
    }

    return promise;
  };

  var _setState = function (formKey, newState) {
    if (dataStores[formKey]) {
      dataStores[formKey].setForm(newState);
    } else {
      state[formKey] = newState;
    }
  };

  var _getState = function (formKey) {
    return dataStores[formKey]?.getForm() ?? getLocalState(formKey);
  };

  var getLocalState = function (formKey) {
    if (!(formKey in state)) {
      return undefined;
    }

    return structuredClone(state[formKey]) ?? {};
  };

  var getDataStore = function (formKey) {
    return dataStores[formKey] ?? null;
  };

  var addFile = async function (formKey, file) {
    return await getDataStore(formKey)?.addFile(file);
  };

  var setFieldValue = function (formKey, $field, value) {
    if ($field.is("input, select, textarea")) {
      if ($field.is(":checkbox")) {
        $field.prop("checked", value);
      } else {
        $field.val(value);
      }
    } else {
      throw new Error("This element type is not supported (yet?)");
    }
    const pathParts = getPathPartsFromName($field.attr("name"));
    const newValue = getValueFromInput(formKey, $field);
    manuallySetFormDataField(formKey, pathParts, newValue);
  };

  return {
    initializeAndLoadListeners,
    initializeDatePickers,
    initializeDropzone,
    initializeAutoNumericFields,
    initializeYearInputs,
    isInitialized,
    getFormDataAtPath,
    getDataAtPath,
    getInputNameFromPath,
    getDataFromForm,
    getPathPartsFromName,
    getDataStore,
    clearForm,
    manuallySetFormDataField,
    manuallyUnsetField,
    assertCleared,
    toFormData,
    isFormEmpty,
    manuallySetDateTime,
    addReadyToSaveLock,
    getReadyToSavePromise,
    addFile,
    setFieldValue,
  };
};

module.exports = Form();

const Checkbox = require("./checkbox");
const CollapsingCard = require("./collapsingCard");
const DateTimePicker = require("./dateTimePicker");
const Dropzone = require("../dropzone");
const EditableItems = require("./editableItems");
const FormPhotos = require("./formPhotos");
const LoadingScreen = require("./loadingScreen");
const LocationButton = require("./locationButton");
const MessageModal = require("../modals/messageModal");
const Misc = require("../misc");
const NumberInput = require("./numberInput");
const ObjectToFormData = require("object-to-formdata");
const OtherInput = require("./otherInput");
const SelectInput = require("./selectInput");
const Signature = require("./signature");
const Downloader = require("../files/downloader");
