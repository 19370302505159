"use strict";

const AssetsBmpPopup = function () {
  var getPopupHtml = function (props) {
    const layerName = "assetsBmpEsriDynamicMap";
    const properties = {
      layerName,
      props,
      formattedDate: AssetsPopupFunctions.getFormattedDate(props.bmp_creation),
      assetAge: AssetsPopupFunctions.getAssetAge(props.bmp_creation),
      type: AssetsPopupFunctions.getBmpTypeFromId(props.bmp_type),
      popupHeaderProps: AssetsPopupFunctions.getPopupHeaderProps(props),
      displayPofText: AssetsPopupFunctions.getDisplayPofText(props),
      mode: AssetsPopupFunctions.getMode(),
    };
    return PopupContent.getLayerPopup(properties, layerName);
  };

  return {
    getPopupHtml,
  };
};

module.exports = AssetsBmpPopup();

const PopupContent = require("../../mapFunctions/popupContent");
const AssetsPopupFunctions = require("../assetsPopupFunctions");
