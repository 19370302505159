"use strict";

const AssetsCulvertGeoServerDynamicMapLayer = function () {
  const layerName = "assetsCulvertEsriDynamicMap";
  const layerKey = "assetsCulvertEsriDynamicMapLayer";

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    var assetsCulvertEsriDynamicMapLayer = new GeoServerMapLayer(layerName, layerKey);
    assetsCulvertEsriDynamicMapLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    assetsCulvertEsriDynamicMapLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  return {
    loadLayerListenersForMap,
  };
};

module.exports = AssetsCulvertGeoServerDynamicMapLayer();

const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
