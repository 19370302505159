"use strict";

const NewProjectInspectionObservations = function () {
  const stringKey = "observations";
  const tabHeader = "Observations";
  let $page;

  var loadListeners = function (data) {
    $page = $(`#assessment-modal #${stringKey}`);

    $page.on("click", ".add-observation-button", _onAddObservation);
    $page.on("click", ".card .delete-button", MultipleObservation.onDeleteObservation);
  };

  var unloadListeners = function () {
    $page.off("click", ".add-observation-button", _onAddObservation);
    $page.off("click", ".card .delete-button", MultipleObservation.onDeleteObservation);
  };

  var getProps = function (data) {
    return {
      requiredObservationConditionOptions:
        NewProjectInspectionConstants.getRequiredObservationConditionOptions(),
    };
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getTabHeader = function () {
    return tabHeader;
  };

  var onChangeInspectionType = function ($container) {
    const searchString = $container.find("[name='inspection-type']").val() || "";
    const $selector = $container.find("#observations").find(".observation-constant");
    const options = {
      selectorTextSelector: ".valid-inspection-types",
      parentSelector: ".card",
    };
    FilterDom.filterByText(searchString, $selector, options);
  };

  var _onAddObservation = function () {
    const $button = $(this);
    MultipleObservation.addObservation($button);
  };

  return {
    onChangeInspectionType,
    loadListeners,
    unloadListeners,
    getStringKey,
    getTabHeader,
    getProps,
  };
};

module.exports = NewProjectInspectionObservations();

const FilterDom = require("../../general/filterDom");
const NewProjectInspectionConstants = require("./newProjectInspectionConstants");
const MultipleObservation = require("./multipleObservation");
