"use strict";

var SoilInputsLayer = function () {
  const esriKey = "soilImpervious";
  const esriLayer = "soil";
  const layerName = "soilInputs";
  const layerKey = "soilInputsLayer";
  var layer = null;
  var soilDataByCatchment;

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadFiltersUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(
      mapId,
      layerName,
      function (isEnabled, sameSpatialFilter, sameView, sameGroup) {
        if (isEnabled) {
          if (mapLayers[layerKey] && sameSpatialFilter && sameView && sameGroup) {
            map.addLayer(mapLayers[layerKey]);
          } else {
            if (sameSpatialFilter && sameView) {
              // Temp solution to prevent loading twice from dataView and filters update
              return;
            }
            createLayerAndAddToMap(map, mapLayers);
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers[layerKey])) {
            map.removeLayer(mapLayers[layerKey]);
          }
        }
      },
    );
  };

  var createLayerAndAddToMap = async function (map, mapLayers) {
    if (mapLayers[layerKey]) {
      map.removeLayer(mapLayers[layerKey]);
    }
    if (!Tree.get(["layers", layerName, "isFetching"])) {
      Tree.set(["layers", layerName, "isFetching"], true);
      mapLayers[layerKey] = await createLayer();
      Tree.set(["layers", layerName, "isFetching"], false);
      mapLayers[layerKey].addTo(map);
    }

    return mapLayers[layerKey];
  };

  var getLayerPropertiesCallback = function (featureCollection) {
    renderSummaryTable(featureCollection);
    soilDataByCatchment = prepareSoilDataByCatchment(featureCollection);
  };

  var renderSummaryTable = function (featureCollection) {
    var data = getSoilKpiData(featureCollection);
    var formattedKpiData = InputsLayerFunctions.getFormattedKpiData(data);
    Kpi.renderKpi(formattedKpiData, null, true);
  };

  var createLayer = async function () {
    const options = {
      pane: "underCatchmentPane",
      layerDefs: EsriLayerFunctions.getLayerDefs(esriKey, esriLayer),
      opacity: 0.7,
    };
    layer = await EsriLayerFunctions.getEsriLayer(esriKey, esriLayer, options);
    EsriLayerFunctions.getLayerProperties(layer, esriKey, esriLayer, getLayerPropertiesCallback);
    return layer;
  };

  var loadFiltersUpdatedListenerForMap = function (map, mapLayers, mapId) {
    Tree.watch({
      catchments: ["filters", "catchments"],
      receivingWaters: ["filters", "receivingWaters"],
    }).on("update", function () {
      if (
        MapFunctions.mapHasLayer(map, mapLayers[layerKey]) &&
        Tree.get("layers", mapId, layerName, "isEnabled")
      ) {
        mapLayers[layerKey].setLayerDefs(EsriLayerFunctions.getLayerDefs(esriKey, esriLayer));
      }
    });
  };

  var prepareSoilDataByCatchment = function (featureCollection) {
    var data = sumSoilDataByCatchment(featureCollection);
    addSoilPercentAcresByCatchment(data);
    return data;
  };

  var sumSoilDataByCatchment = function (featureCollection) {
    var soilByCatch = {};
    if (featureCollection && featureCollection.features) {
      featureCollection.features.forEach((soil) => {
        const catchId = soil.properties.catch_id || soil.properties.CATCH_ID;
        let soilGroup = soil.properties.hydgrpdcd;
        const acres = soil.properties.acres;

        if (soilGroup === null) {
          soilGroup = "noData";
        }

        if (!(catchId in soilByCatch)) {
          soilByCatch[catchId] = {};
        }
        if (!(soilGroup in soilByCatch[catchId])) {
          soilByCatch[catchId][soilGroup] = { acres: 0 };
        }
        soilByCatch[catchId][soilGroup].acres += acres;
      });
    }
    return soilByCatch;
  };

  var addSoilPercentAcresByCatchment = function (soilByCatch) {
    for (const catchId in soilByCatch) {
      const catchmentSoilData = soilByCatch[catchId];
      let totalCatchmentAcres = 0;
      for (const key in catchmentSoilData) {
        const soil = catchmentSoilData[key];
        totalCatchmentAcres += soil.acres;
      }

      for (const key in catchmentSoilData) {
        const soil = catchmentSoilData[key];
        soil.percentAcres = soil.acres / totalCatchmentAcres;
      }
      catchmentSoilData.total_acres = totalCatchmentAcres;
    }
  };

  var getSoilDataByCatchment = function () {
    return soilDataByCatchment;
  };

  var getSoilKpiData = function (featureCollection) {
    var totalAcres = 0;
    var soilAcresMap = {};

    if (featureCollection && featureCollection.features) {
      featureCollection.features.forEach((soil) => {
        const acres = soil.properties.acres;
        let soilGroup = soil.properties.hydgrpdcd;
        if (soilGroup === null) {
          soilGroup = "noData";
        }
        if (!(soilGroup in soilAcresMap)) {
          soilAcresMap[soilGroup] = { acres: 0 };
        }
        soilAcresMap[soilGroup].acres += acres;
        totalAcres += acres;
      });
      addSoilPercentAcres(totalAcres, soilAcresMap);
    }
    return { totalAcres, soilAcresMap };
  };

  var addSoilPercentAcres = function (totalAcres, soilAcresMap) {
    for (const soil in soilAcresMap) {
      soilAcresMap[soil].percentAcres = soilAcresMap[soil].acres / totalAcres;
    }
  };

  return {
    loadLayerListenersForMap,
    getSoilDataByCatchment,
    prepareSoilDataByCatchment,
    getSoilKpiData,
  };
};

module.exports = SoilInputsLayer();

const MapFunctions = require("../../mapFunctions/mapFunctions");
const Tree = require("../../../tree");
const EsriLayerFunctions = require("../../mapFunctions/esriLayerFunctions");
const Kpi = require("../../report/kpi");
const InputsLayerFunctions = require("../inputsLayerFunctions");
