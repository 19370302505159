"use strict";

const LeafletExtensions = function () {
  var loadLeafletExtensions = function () {
    /**
     *  Extend the Polygon Object to set an image to fill the path in SVG
     *  exp:
     *    L.Polygon(points, {fill: 'url(image.gif)'});
     *  Author: lanwei@cloudybay.com.tw
     */

    if (typeof L !== "undefined") {
      if (L.Browser.svg) {
        L.Polygon.prototype._updateStyle = function () {
          L.Path.prototype._updateStyle.call(this);

          if (typeof this.options.fill === "string" && this.options.fill.match(/^url\(/)) {
            if (!this._defs) {
              this._defs = this._createElement("defs");
              this._container.appendChild(this._defs);
            }
            var _img_url = this.options.fill.substring(4, this.options.fill.length - 1);
            var _ref_id =
              _img_url + (Math.random() * Math.pow(10, 17) + Math.random() * Math.pow(10, 17));
            _ref_id += new Date().getUTCMilliseconds();
            var _p = document.getElementById(_ref_id);
            if (!_p) {
              var _im = new Image();
              _im.src = _img_url;

              _p = this._createElement("pattern");
              _p.setAttribute("id", _ref_id);
              _p.setAttribute("x", "0");
              _p.setAttribute("y", "0");
              _p.setAttribute("patternUnits", "userSpaceOnUse");
              _p.setAttribute("width", "24");
              _p.setAttribute("height", "24");
              this._defs.appendChild(_p);

              var _img = this._createElement("image");
              _img.setAttribute("x", "0");
              _img.setAttribute("y", "0");
              _img.setAttributeNS("http://www.w3.org/1999/xlink", "href", _img_url);
              _img.setAttribute("width", "24");
              _img.setAttribute("height", "24");
              _p.appendChild(_img);

              _im.onload = function () {
                _p.setAttribute("width", _im.width);
                _p.setAttribute("height", _im.height);
                _img.setAttribute("width", _im.width);
                _img.setAttribute("height", _im.height);
              };
            }
            this._path.setAttribute("fill", "url(#" + _ref_id + ")");
          }
        };
      }

      // Hack to make dragging not drop a vertex
      L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

      // Add center anchor to map
      // https://stackoverflow.com/posts/60391674/revisions
      L.Map.include({
        _initControlPos: function () {
          var corners = (this._controlCorners = {}),
            l = "leaflet-",
            container = (this._controlContainer = L.DomUtil.create(
              "div",
              l + "control-container",
              this._container,
            ));

          function createCorner(vSide, hSide) {
            var className = l + vSide + " " + l + hSide;

            corners[vSide + hSide] = L.DomUtil.create("div", className, container);
          }

          createCorner("top", "left");
          createCorner("top", "right");
          createCorner("bottom", "left");
          createCorner("bottom", "right");

          createCorner("top", "center");
          createCorner("middle", "center");
          createCorner("middle", "left");
          createCorner("middle", "right");
          createCorner("bottom", "center");
        },
      });
    }
  };

  return {
    loadLeafletExtensions: loadLeafletExtensions,
  };
};

module.exports = LeafletExtensions();
