"use strict";

const PropertyBmpLayer = function () {
  const layerName = "propertyBmp";
  var markerReferences = {};

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    loadToggledListenerForMap(map, mapLayers, mapId);
    loadDataUpdatedListenerForMap(map, mapLayers, mapId);
  };

  var loadToggledListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerToggled(layerName, mapId, function (isEnabled, sameSpatialFilter) {
      if (isEnabled) {
        if (
          mapLayers.propertyBmpLayer &&
          (mapId === "modal" || (mapId === "main" && sameSpatialFilter))
        ) {
          map.addLayer(mapLayers.propertyBmpLayer);
        } else {
          loadPropertyAssociatedBmps(mapId);
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.propertyBmpLayer)) {
          map.removeLayer(mapLayers.propertyBmpLayer);
        }
      }
    });
  };

  var loadDataUpdatedListenerForMap = function (map, mapLayers, mapId) {
    MapFunctions.whenLayerDataUpdated(layerName, mapId, function (data) {
      data = data.filter((item) => !item.deleted);
      mapLayers.propertyBmpLayer = addPropertyBmpLayer(
        map,
        mapLayers.propertyBmpLayer,
        data,
        mapId,
      );
      AddBmpsTable.render(data);
      PropertyCostsAddBmps.renderAddBmpsHtml(data);
    });
  };

  var loadPropertyAssociatedBmps = async function (mapId) {
    var propertyBmps = PropertyCostsModalController.getExistingPropertyData("bmps") || [];
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    Tree.set(["layers", layerName, dataPath], propertyBmps);
  };

  var addPropertyBmpLayer = function (map, propertyBmpLayer, data, mapId) {
    const CleanData = require("../mapFunctions/cleanData");

    var geoJson = CleanData.cleanGeoJson(data);

    if (propertyBmpLayer) {
      map.removeLayer(propertyBmpLayer);
    }
    propertyBmpLayer = L.featureGroup();
    L.geoJson(geoJson, {
      pointToLayer: function (feature, latlng) {
        const properties = feature.properties;
        const markerOpacity = getMarkerOpacity(mapId, properties.idBmp);
        const draggable = AddBmpsMap.bmpIsEditable(properties);
        const marker = L.marker(latlng, {
          draggable: draggable,
          icon: BmpFcsIcon.getIconMarker(
            properties.bmpScore,
            properties.bmpTypeObj.isCentralized,
            properties.bmpTypeObj.abbreviation,
            properties.isFcs,
            false,
            properties.phase,
          ),
          opacity: markerOpacity,
        });
        marker.on("dragend", markerDragend);
        storeMarkerReference(properties.idbmp, marker);
        return marker;
      },
      onEachFeature: function (feature, layer) {
        layer.bindPopup(
          () => getPropertyBmpPopupHtml(feature.properties),
          MapFunctions.getPopupOptions(),
        );
        layer.on({
          click: highlightTable,
        });
      },
    }).addTo(propertyBmpLayer);

    propertyBmpLayer.on("popupclose", onPopupClose);

    return propertyBmpLayer.addTo(map);
  };

  var getPropertyBmpPopupHtml = function (bmp) {
    var popupProps = getPropertyBmpPopupProps(bmp);
    var props = $.extend({}, bmp, popupProps);
    return nunjucks.render("popups/projectAssociatedBmpPopup.njk", props);
  };

  var getPropertyBmpPopupProps = function (bmp) {
    var props = {};
    props.popupColor = BmpFcsIcon.getIconColor(bmp.bmpScore, bmp.phase);
    return props;
  };

  var getMarkerOpacity = function (mapId, idBmp) {
    return mapId === "modal"
      ? MapFunctions.getModalMapMarkerOpacity(idBmp, Tree.get(["asset", "basicInfo", "idBmp"]))
      : 1;
  };

  var markerDragend = function (event) {
    var marker = event.target;
    var idBmp = marker.feature.properties.idbmp;
    var latlng = marker.getLatLng();
    AddBmpsMap.markerDragendHandler(idBmp, latlng);
  };

  var addBmpData = function (newData, mapId = "modal") {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var dataCursor = Tree.select(["layers", layerName, dataPath]);
    dataCursor.push(newData);
    saveLayerDataToForm();
  };

  var removeBmpDataByIdBmp = function (idBmp, mapId = "modal") {
    var bmpData = getDataAndIndexByIdBmp(idBmp, mapId).data;

    if (bmpData.isTemp) {
      var dataPath = Actions.getLayerDataPathByMapId(mapId);
      var dataCursor = Tree.select(["layers", layerName, dataPath]);
      dataCursor.apply(function (data) {
        return data.filter((item) => item.idbmp !== idBmp);
      });
      saveLayerDataToForm();
    } else {
      updateDataByIdBmp(idBmp, { deleted: true }, mapId);
    }
  };

  var updateDataByIdBmp = function (idBmp, newData, mapId = "modal") {
    LayerDataFunctions.updateLayerDataById(layerName, idBmp, newData, mapId);
    saveLayerDataToForm();
  };

  var getCurrentData = function (mapId = "modal") {
    return LayerDataFunctions.getCurrentLayerData(layerName, mapId);
  };

  var getDataAndIndexByIdBmp = function (idBmp, mapId = "modal") {
    return LayerDataFunctions.getLayerDataAndIndexById(layerName, idBmp, mapId);
  };

  var saveLayerDataToForm = function (mapId = "modal") {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var dataCursor = Tree.select(["layers", layerName, dataPath]);
    var bmps = dataCursor.get() || [];
    var processedData = processDataForFormUpdates(bmps);
    Form.manuallySetFormDataField(
      PropertyCostsModalController.getFormKey(),
      ["bmps"],
      processedData,
    );
    Misc.toggleDisabled($(".add-new-save-button"), false);
  };

  var processDataForFormUpdates = function (bmps) {
    var processedData = [];

    bmps.forEach((bmp) => {
      let processedBmpData;
      if (bmp.isTemp) {
        processedBmpData = getProcessedNewBmpData(bmp);
      } else if (bmp.deleted) {
        processedBmpData = getProcessedDeletedBmpData(bmp);
      } else {
        processedBmpData = getProcessedUpdatedBmpData(bmp);
      }
      processedData.push(processedBmpData);
    });
    return processedData;
  };

  var getProcessedNewBmpData = function (bmp) {
    var newData = {
      bmpGroupid: Tree.get(["activeGroup", "groupId"]),
    };

    setBmpNameUpdates(newData, bmp);
    setBmpTypeUpdates(newData, bmp);
    setBmpLocationUpdates(newData, bmp);
    setBmpDetailsFormUpdates(newData, bmp);

    return {
      ...newData,
      phase: bmp.phase || "completed",
      bmpRelationship: bmp.bmpRelationship || "onsite",
      retrofitOpportunity: bmp.retrofitOpportunity || "unknown",
    };
  };

  var getProcessedDeletedBmpData = function (bmp) {
    return {
      idbmp: bmp.idbmp,
      deleted: true,
    };
  };

  var getProcessedUpdatedBmpData = function (bmp) {
    const updatedDetailsArray = [
      bmp.phaseUpdated,
      bmp.bmpRelationshipUpdated,
      bmp.retrofitOpportunityUpdated,
      bmp.yearBuiltUpdated,
      bmp.drainageAreaUpdated,
      bmp.imperviousAreaUpdated,
      bmp.treatmentCapacityUpdated,
      bmp.footprintUpdated,
    ];
    var updatedData = {
      idbmp: bmp.idbmp,
    };
    if (bmp.bmpNameUpdated) {
      setBmpNameUpdates(updatedData, bmp);
    }
    if (bmp.bmpTypeUpdated) {
      setBmpTypeUpdates(updatedData, bmp);
    }
    if (bmp.locationUpdated) {
      setBmpLocationUpdates(updatedData, bmp);
    }
    if (updatedDetailsArray.includes(true)) {
      setBmpDetailsFormUpdates(updatedData, bmp);
    }

    return updatedData;
  };

  var setBmpDetailsFormUpdates = function (newData, bmp) {
    newData.phase = bmp.phase;
    newData.bmpRelationship = bmp.bmpRelationship;
    newData.retrofitOpportunity = bmp.retrofitOpportunity;
    newData.yearBuilt = bmp.yearBuilt;
    newData.drainageArea = bmp.drainageArea;
    newData.imperviousArea = bmp.imperviousArea;
    newData.treatmentCapacity = bmp.treatmentCapacity;
    newData.footprint = bmp.footprint;
  };

  var setBmpNameUpdates = function (newData, bmp) {
    newData.bmpId = bmp.bmpName;
  };

  var setBmpTypeUpdates = function (newData, bmp) {
    newData.bmpType = bmp.bmpTypeObj.sortOrder;
    newData.bmpTypeName = bmp.bmpTypeObj.name;
    newData.bmpTypeAbbr = bmp.bmpTypeObj.abbreviation;
  };

  var setBmpLocationUpdates = function (newData, bmp) {
    newData.bmpLatitude = bmp.bmp_latitude;
    newData.bmpLongitude = bmp.bmp_longitude;
    newData.bmpApn = bmp.bmp_apn;
    newData.luName = bmp.lu_name;
    newData.bmpLuid = bmp.bmp_luid;
  };

  var refreshLayer = function (mapId = "modal") {
    LayerDataFunctions.refreshLayer(layerName, mapId);
  };

  var show = function () {
    Actions.toggleLayer(layerName, true, "modal");
  };

  var hide = function () {
    Actions.toggleLayer(layerName, false, "modal");
  };

  var highlightTable = function (e) {
    var properties = e.target.feature.properties;
    var idBmp = properties.idbmp;

    AddBmpsTable.scrollToAndHighlightTableRow(idBmp);
  };

  var onPopupClose = function () {
    AddBmpsTable.removeTableHighlight();
  };

  var storeMarkerReference = function (idBmp, marker) {
    markerReferences[idBmp] = marker;
  };

  var getMarkerReference = function (idBmp) {
    return markerReferences[idBmp];
  };

  var canEditBmpOfType = function (bmpTypeAbbreviation) {
    return true;
  };

  return {
    loadLayerListenersForMap,
    loadPropertyAssociatedBmps,
    show,
    hide,
    addBmpData,
    removeBmpDataByIdBmp,
    updateDataByIdBmp,
    getCurrentData,
    refreshLayer,
    getMarkerReference,
    processDataForFormUpdates,
    canEditBmpOfType,
  };
};

module.exports = PropertyBmpLayer();

const Actions = require("../actions");
const Tree = require("../tree");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Form = require("../general/form");
const Misc = require("../misc");
const BmpFcsIcon = require("../bmpfcs/bmpFcsIcon");
const LayerDataFunctions = require("../general/layerDataFunctions");
const AddBmpsTable = require("../mapFunctions/addBmpsTable");
const AddBmpsMap = require("../mapFunctions/addBmpsMap");
const PropertyCostsModalController = require("./modals/propertyCostsModalController");
const PropertyCostsAddBmps = require("./modals/propertyCosts/propertyCostsAddBmps");
