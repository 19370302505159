"use strict";

const IncidentInvestigationController = function () {
  const htmlPath = "modals/incidentInvestigation";
  const formKey = "incident-investigation";

  let $modal;
  let tabs;
  let data;

  var loadIncidentInvestigation = async function ({ maybeId, incidentId, readOnly } = {}) {
    if (maybeId !== undefined) {
      data = await ApiCalls.getIncidentInvestigation(maybeId);
      data.incidentInfo = await ApiCalls.getIncident(data.incidentId);
    } else {
      data = await ApiCalls.getIncident(incidentId);
      data = IncidentInvestigationConstants.getNewIncidentInvestigationDefaults(data);
    }
    showIncidentInvestigationModal(data, readOnly);
  };

  var showIncidentInvestigationModal = function (data, readOnly) {
    const GeneralInfo = require("./incidentInvestigationGeneralInfo");
    const InspectClean = require("./incidentInvestigationInspectClean");
    const Flow = require("./incidentInvestigationFlow");
    const ResponsibleParty = require("./incidentInvestigationResponsibleParty");
    const FollowUpClose = require("./incidentInvestigationFollowUpClose");
    tabs = [GeneralInfo, InspectClean, Flow, ResponsibleParty, FollowUpClose];
    const renderData = mapTabProps(tabs, data);

    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, {
      readOnly,
      saveButtonText: "Save & Create PDF",
      extraButtons: [{ text: "Preview", type: "primary", callback: downloadPreviewPdf }],
    });
    Form.initializeAndLoadListeners($modal, formKey);

    FormSettings.customizeForm($modal, formKey);

    const existingFiles = data.files ?? [];
    Form.initializeDropzone(formKey, $modal, {
      existingFiles: existingFiles,
      downloadCallback: ApiCalls.downloadIncidentInvestigationFile,
    });

    checkFlowTab(data);
    setInspectionHeaderText(data);
    setAdditionalModalHandlers();
  };

  var mapTabProps = function (tabs, data) {
    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
    };
  };

  var getHeaderHtml = function (data) {
    if (data.incidentInfo.requestingType) {
      data.incidentInfo.displayRequestingType =
        IncidentInventoryConstants.getRequestingTypeOptions().getObjectWith(
          "value",
          data.incidentInfo.requestingType,
        )?.name;
    }
    return nunjucks.render("modals/incidentInvestigation/incidentInvestigationHeader.njk", data);
  };

  var setInspectionHeaderText = function (data) {
    TabbedModal.setHeaderText(getHeaderHtml(data));
  };

  var checkFlowTab = function (data) {
    $("#assessment-modal #measurements").show();
    if (data.incidentType === "illicit-flow" && data.flowCharacteristicsCollected === true) {
      toggleFlowTab(true);
    } else {
      toggleFlowTab(false);
    }
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(() => {});
    TabbedModal.setSaveHandler(saveAndCloseModal, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  };

  var downloadPreviewPdf = async function () {
    InspectionPreview.preview(async function () {
      await Form.getReadyToSavePromise(formKey);
      return _getUpdateData();
    }, ApiCalls.previewIncidentInvestigationPdf);
  };

  var saveAndCloseModal = async function () {
    try {
      await Form.getReadyToSavePromise(formKey);
      const editExisting = data.id !== undefined;
      const dataToUpload = _getUpdateData(editExisting);

      if (editExisting) {
        const formData = Form.toFormData({
          id: data.id,
          updates: dataToUpload,
        });
        await ApiCalls.updateIncidentInvestigation(formData);
      } else {
        const formData = Form.toFormData({
          updates: dataToUpload,
        });
        await ApiCalls.createIncidentInvestigation(formData);
      }

      TabbedModal.hide();
      IncidentLayer.invalidateLayerData();
    } catch (e) {
      return UploadValidator.reportError(e);
    }
  };

  var _getUpdateData = function (editExisting = false) {
    let dataToUpload = $.extend(true, {}, Form.getDataFromForm(formKey, false));

    processInspectionFormData(dataToUpload);

    if (editExisting) {
      dataToUpload = mergeIncidentIdIntoUpdates(dataToUpload);
    } else {
      dataToUpload = mergeDefaultsIntoUpdates(dataToUpload);
    }
    delete dataToUpload["incidentInfo"];

    return dataToUpload;
  };

  var mergeIncidentIdIntoUpdates = function (updates) {
    if (updates.incidentInfo) {
      updates.incidentInfo.id = data.incidentInfo.id;
    }
    return updates;
  };

  var processInspectionFormData = function (dataToUpload) {
    dataToUpload.maintenanceType = "routine";

    processInspectionAttribute(dataToUpload, "measurements");
    processInspectionAttribute(dataToUpload, "samples");
  };

  var processInspectionAttribute = function (object, path) {
    if (object !== undefined && object[path] !== undefined) {
      const existingValues = getExistingInspectionData()[path];
      cleanInspectionIds(existingValues, object[path]);
      object[path] = Object.values(object[path]);
    }
  };

  var cleanInspectionIds = function (existingData, inspectionData) {
    const existingIds = existingData
      ? existingData.map((data) => {
          return data.id;
        })
      : [];
    Misc.removeAttributeValues(existingIds, inspectionData, "id");
  };

  var mergeDefaultsIntoUpdates = function (dataToUpload) {
    return $.extend(true, {}, data, dataToUpload);
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getInspectionRenderData = function () {
    return $.extend(true, {}, data, Form.getDataFromForm(formKey, false));
  };

  var getExistingInspectionData = function () {
    return $.extend(true, {}, data);
  };

  var getFormData = function (path) {
    return Form.getFormDataAtPath(formKey, path);
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var unsetFormData = function (path) {
    return Form.manuallyUnsetField(formKey, path);
  };

  var toggleFlowTab = function (visibility = false) {
    TabbedModal.toggleTab("flow", visibility);
  };

  var renderInvestigationTotalTime = function (startTime, endTime) {
    var hourDifference;
    if (startTime && endTime) {
      hourDifference = endTime.split(":")[0] - startTime.split(":")[0];
    } else {
      hourDifference = "—";
    }
    $(".investigation-hour-difference").text(hourDifference);
  };

  return {
    loadIncidentInvestigation,
    showIncidentInvestigationModal,
    toggleFlowTab,
    checkFlowTab,
    getInspectionRenderData,
    getExistingInspectionData,
    getFormData,
    setFormData,
    unsetFormData,
    saveAndCloseModal,
    _getUpdateData,
    renderInvestigationTotalTime,
  };
};

module.exports = IncidentInvestigationController();

const ApiCalls = require("../../apiCalls");
const UploadValidator = require("../../general/uploadValidator");
const Form = require("../../general/form");
const FormSettings = require("../../settings/formSettings");
const Misc = require("../../misc");
const IncidentLayer = require("../incidents/incidentLayer");
const IncidentInventoryConstants = require("../incidents/incidentInventoryConstants");
const IncidentInvestigationConstants = require("./incidentInvestigationConstants");
const TabbedModal = require("../../general/tabbedModal");
const InspectionPreview = require("../../general/inspectionPreview");
