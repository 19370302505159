"use strict";

const MultiStepForm = function (
  formKey,
  getFormValidationErrorsCallback,
  getDataReviewHtmlCallback,
  {
    saveCallback,
    cancelCallback,
    updateCallback = null,
    createCallback = null,
    isCompleted = false,
    initialStep = 0,
  } = {},
) {
  let $navSteps;
  let $steps;
  let $page;
  let $dataReview;
  let steps;

  const init = function ($container, newSteps) {
    steps = newSteps;
    $container.html(
      nunjucks.render("multiStepForm/wizard.njk", {
        formKey,
        steps,
      }),
    );

    $page = $("#wizard-main-content");
    $navSteps = $("#steps-nav div");
    $steps = $("#wizard-main-content .step");
    $dataReview = $("#data-review-container");

    Form.clearForm(formKey);
    Form.initializeAndLoadListeners($page, formKey);

    const currentStep = isCompleted ? 0 : initialStep;
    goToStep(currentStep, true);

    if (!isCompleted) $navSteps.slice(currentStep + 1).addClass("unvisited");
    _updateSubmitButton();

    renderDataReview();
    _loadListeners();
    FilterSummary.disable();
  };

  const _updateSubmitButton = function () {
    const submitType = _getSubmitType();
    $(".submit-plan").addClass("hidden");
    $(`#${submitType}-plan`).removeClass("hidden");
  };

  const _getSubmitType = function () {
    if (isCompleted) return "update";
    if (!_anyUnvisitedSteps() && createCallback) return "create";
    return "save";
  };

  const _anyUnvisitedSteps = function () {
    return $navSteps ? $navSteps.filter(".unvisited").length > 0 : false;
  };

  const _loadListeners = function () {
    $navSteps.off().on("click", _onClickStep);
    $("#next-step").off("click", _nextStep).on("click", _nextStep);
    $("#prev-step").off("click", _prevStep).on("click", _prevStep);
    $("#wizard-main-content")
      .off("input", "input, textarea", renderDataReview)
      .on("input", "input, textarea", renderDataReview)
      .off("submit", "form", renderDataReview)
      .on("submit", "form", renderDataReview);
    $("#cancel-plan").off("click", _cancel).on("click", _cancel);
    $("#save-plan").off().on("click", _onClickSavePlan);
    $("#create-plan").off().on("click", _onClickCreatePlan);
    $("#update-plan").off().on("click", _onClickUpdatePlan);
  };

  const _onClickStep = function () {
    goToStep($(this).index());
  };

  const goToStep = function (step, force = false) {
    const $clickedStep = $navSteps.eq(step);
    if ($clickedStep.prev().hasClass("unvisited") && !force) return;

    $steps.add($navSteps).removeClass("current");

    const $newStep = $steps.eq(step);
    $newStep.addClass("current");
    $("#multi-step-form-right-sidebar .footer").html("");
    steps[step]?.render?.($newStep);

    $clickedStep.addClass("current").removeClass("unvisited");
    $("#prev-step").toggleClass("hidden", step === 0);
    $("#next-step").toggleClass("hidden", step === $navSteps.last().index());
    _updateSubmitButton();
    if (!force) renderDataReview();
  };

  const _nextStep = function () {
    const currentStep = _getCurrentStepNum();
    if (currentStep < $navSteps.length - 1) goToStep(currentStep + 1);
  };

  const _prevStep = function () {
    const currentStep = _getCurrentStepNum();
    if (currentStep > 0) goToStep(currentStep - 1);
  };

  const _cancel = function () {
    cancelCallback();
  };

  const _getCurrentStepNum = function () {
    return $navSteps.filter(".current").index();
  };

  const getLastVisitedStepNum = function () {
    return $navSteps ? $navSteps.filter(":not(.unvisited)").last().index() : 0;
  };

  const _onClickSavePlan = function () {
    saveCallback();
  };

  const _onClickCreatePlan = function () {
    if (!_validateForm()) return;
    createCallback();
  };

  const _onClickUpdatePlan = function () {
    if (!_validateForm()) return;
    updateCallback();
  };

  const _validateForm = function () {
    const errors = getFormValidationErrorsCallback();

    if (errors.length) {
      let errorText = "Please resolve the following errors:";
      errors.forEach((error) => {
        errorText += `<br/>- ${error.message}`;
      });
      MessageModal.showWarningModal(errorText, "Invalid Plan", true);
      goToStep(errors[0].step);
      return false;
    }

    return true;
  };

  const getPage = function () {
    return $page;
  };

  const renderDataReview = function () {
    const html = getDataReviewHtmlCallback();
    $dataReview.html(html);
  };

  return {
    init,
    getLastVisitedStepNum,
    goToStep,
    getPage,
    renderDataReview,
    _onClickStep,
    _nextStep,
    _prevStep,
    _cancel,
  };
};

module.exports = MultiStepForm;

const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const FilterSummary = require("../filters/filterSummary");
