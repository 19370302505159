"use strict";

const ProjectStageData = function () {
  var $page = null;
  var options;

  var loadStageDataListeners = function (
    $newPage,
    newOptions = { isInventory: true, formKey: "project-inventory", limitToPast: false },
  ) {
    const data = InventoryModal.getAllData("project-inventory");
    const phase = ProjectInventoryModalController.getPhase(data);
    var config = { attributes: true, childList: true, subtree: true };
    $page = $newPage;
    options = newOptions;

    $page.off("2N:FormInput", "[name^='stageData'].date-picker", afterStageDataDateInput);
    $page.on("2N:FormInput", "[name^='stageData'].date-picker", afterStageDataDateInput);

    $page.off("2N:FormInput", "[name^='stageData']", afterStageDataInput);
    $page.on("2N:FormInput", "[name^='stageData']", afterStageDataInput);

    setStageDataCapacityOnChange(phase, config);
    setStageDataTreatedOnChange(phase, config);
  };

  var afterStageDataInput = function () {
    const value = $(this).val();
    const insideBrackets = /.*?\[([^\]]+)\].*?/g;
    const name = $(this).attr("name");
    const stage = insideBrackets.exec(name)[1];
    const isStartDate = insideBrackets.exec(name)[1].startsWith("start");
    setStageDataStage(stage);

    if (!$(this).hasClass("date-picker")) {
      return;
    }

    if (isStartDate) {
      afterStageDataDateUpdate(stage, value, null);
    } else {
      afterStageDataDateUpdate(stage, null, value);
    }
  };

  var setStageDataCapacityOnChange = function (phase, config) {
    const p = document.querySelector(".treatment-type-capacity");
    if (p && p.nodeName === "P") {
      var observer = new MutationObserver(() => {
        setStageDataStage(phase);
      });

      observer.observe(p, config);
    }
  };

  var setStageDataTreatedOnChange = function (phase, config) {
    const p = document.querySelector(".treatment-type-treated-impervious-area");
    if (p && p.nodeName === "P") {
      var observer = new MutationObserver(() => {
        setStageDataStage(phase);
      });

      observer.observe(p, config);
    }
  };

  var afterStageDataDateUpdate = function (stage, startDate, endDate) {
    const endDateKey = `stageData[${stage}][endDateIso]`;
    const startDateKey = `stageData[${stage}][startDateIso]`;

    if (startDate) {
      updateStageDataDate(stage, endDateKey, false, startDate);
    }

    if (endDate) {
      updateStageDataDate(stage, startDateKey, true, endDate);
    }

    if (options.limitToPast) {
      limitDateFieldToNow(stage, endDateKey);
      limitDateFieldToNow(stage, startDateKey);
    }
  };

  var limitDateFieldToNow = function (stage, dateKey) {
    const config = DateTimePicker.getDatePickerConfig($page, dateKey);
    if (!config || !config.maxDate) {
      updateStageDataDate(stage, dateKey, true, new Date());
    }
  };

  var updateStageDataDate = function (stage, name, isMaxDate, value) {
    DateTimePicker.setDatePickerRange($page, name, isMaxDate, value);
    updateMapIcon();
  };

  var updateMapIcon = function () {
    if (!options.isInventory) {
      return;
    }

    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      const marker = ModalMap.getMarker();
      const markerData = ProjectInventoryModalController.getProjectMarker();
      marker.setIcon(markerData);
    }
  };

  var setStageDataStage = function (stage) {
    Form.manuallySetFormDataField(options.formKey, ["stageData", stage, "stage"], stage);
  };

  var updateAllStageDataDatePickers = function (stageData) {
    for (const stage in stageData) {
      const data = stageData[stage];
      afterStageDataDateUpdate(stage, data.startDateIso, data.endDateIso);
    }
  };

  var afterStageDataDateInput = function (e, newValue, pathParts, formState, formKey, oldValue) {
    if (!newValue) {
      MessageModal.showSimpleWarningModal(`Please enter a valid date.`);
      if (oldValue) {
        Form.manuallySetDateTime(formKey, pathParts, oldValue, $(e.currentTarget));
      }
    }
  };

  var getRenderStageData = function (dbStageData) {
    const stageData = {};

    if (!Array.isArray(dbStageData)) {
      return Object.assign(stageData, dbStageData);
    }
    for (const data of dbStageData) {
      const stage = data.stage;
      stageData[stage] = Object.assign({}, data);
      delete stageData[stage].stage;
    }

    return stageData;
  };

  var getStageData = function (stageData, targetStage) {
    for (const key in stageData) {
      const data = stageData[key];

      if (key === targetStage || data.stage === targetStage) {
        return data;
      }
    }

    return null;
  };

  var getStageByPhase = function (phase) {
    // https://2ndnature.atlassian.net/l/c/Cau1EsR1
    const phaseToStageMap = {
      planning: "planning",
      planned: "design",
      construction: "certified",
      "post-active": "certified",
      completed: "certified",
      certified: "certified",
      shelved: "planning",
      rejected: "planning",
    };

    const stage = phaseToStageMap[phase];

    if (!stage) {
      throw new Error(`No stage defined for phase "${phase}".`);
    }

    return stage;
  };

  var getDisplayStageData = function (project) {
    const stage = getStageByPhase(project.phase);
    var stageData = Object.assign({}, getStageData(project.stageData, stage));

    if (
      Tree.get("dataView") === "construction-project-delivery" &&
      project.phase === "construction" &&
      !stageData.deliveryCost
    ) {
      const designStageData = getStageData(project.stageData, "design");
      if (designStageData) {
        stageData.deliveryCost = designStageData.deliveryCost;
      }
    }

    return stageData;
  };

  return {
    loadStageDataListeners,
    getRenderStageData,
    limitDateFieldToNow,
    updateAllStageDataDatePickers,
    getStageData,
    getStageByPhase,
    getDisplayStageData,
  };
};

module.exports = ProjectStageData();

const DateTimePicker = require("../general/dateTimePicker");
const Form = require("../general/form");
const MessageModal = require("../modals/messageModal");
const ModalMap = require("../mapFunctions/modalMap");
const ProjectInventoryModalController = require("./projectInventoryModalController");
const Tree = require("../tree");
const InventoryModal = require("../general/inventoryModal");
