"use strict";

const DataStoreError = require("./dataStoreError");

class DataNotFoundError extends DataStoreError {
  constructor(message, notFoundId) {
    super(message);
    this.name = "DataNotFoundError";
    this.notFoundId = notFoundId;
  }
}

module.exports = DataNotFoundError;
