"use strict";

var VaFilter = function () {
  var getProps = function () {
    const { urbanDrainageOptions, receivingWaterOptions, catchmentsOptions } =
      Filters.getSpatialArrays();
    const subGroupProps = Progeny.getSubGroupFilterProps();

    return {
      urbanDrainageOptions,
      receivingWaterOptions,
      catchmentsOptions,
      subGroupOptions: subGroupProps?.subGroupOptions,
      subGroupSelected: subGroupProps?.subGroupSelected,
      yesNoOptionsNullFalse: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
    };
  };

  var setSmartFilters = function (newSmartFilters) {
    // Hack to work with smart filters
  };

  var getFilterConfigs = function () {
    return {};
  };

  return {
    getProps,
    setSmartFilters,
    getFilterConfigs,
  };
};

module.exports = VaFilter();

const Filters = require("../mapFunctions/filters");
const Progeny = require("../login/progeny");
