"use strict";

var Filters = function () {
  var thisMap = null;
  var filterDiv = null;

  var loadFilterListeners = function () {
    thisMap = PageFunctions.getCurrentPage();
    filterDiv = thisMap.find(".js-filters");

    filterDiv.off();

    thisMap.find(".js-filter-menu").data("display", false);

    // Show / hide filter menu
    filterDiv.on("click", ".filter-button", filterButtonClick);
    filterDiv.on("click", ".search-button", searchButtonClick);
    filterDiv.on("click", ".dropdown-menu.view li", dropdownMenuClick);
    filterDiv.on("click", ".dropdown-menu.sort li", dropdownSortClick);
    filterDiv.on("change", ".filter-dropdown input[type=checkbox]", onInputClicked);
    filterDiv.on("change", ".filter-dropdown input[type=radio]", onInputClicked);
    filterDiv.on("click", ".js-filter-title-button", toggleFilterDropdown);
    filterDiv.on("click", ".js-filter-menu .js-select-all", filterSelectAllClick);
    filterDiv.on("click", ".js-filter-menu .js-clear-filter", filterClear);
    filterDiv.on("input", ".search-input input", Misc.debounce(onSearchInput, 500));
    filterDiv.on("click", ".outside-accordion input[type=checkbox]", filterSingleCheckboxClick);
  };

  var load = function () {
    loadFilters();
    loadFilterMenu();
    loadSortDropdown();
    checkIfDrainagesExist();
    loadFilterListeners();
    setDataViewDefaultFilters();
  };

  var loadFilters = function () {
    const disableFilter = getDisableFilter();
    const filtersHtml = nunjucks.render("report/table/filters.html", {
      disableFilter,
    });
    PageFunctions.getCurrentPage().find(".js-filter-mount").html(filtersHtml);
  };

  var getDisableFilter = function () {
    var filterEnabled = LayerFunctions.getCurrentLayerProperty("filterEnabled");

    return !filterEnabled;
  };

  var getDisableSearch = function () {
    var searchDisabled = LayerFunctions.getCurrentLayerProperty("searchDisabled");

    return !!searchDisabled;
  };

  var disableSearchIfInConfig = function () {
    const disableSearch = getDisableSearch();
    $(".search-button")
      .toggleClass("permanentlyDisabled", disableSearch)
      .attr("disabled", disableSearch);
  };

  var loadFilterMenu = function () {
    const layerName = DataViewFunctions.getCurrentLayerName();
    const isCdot = Session.isCdot();

    const filterMenuHtml = nunjucks.render("report/table/filterMenu.html", {
      layerName,
      isCdot,
    });
    PageFunctions.getCurrentPage().find(".js-filter-menu").html(filterMenuHtml);
    initFilterTitles();
  };

  var initFilterTitles = function () {
    $(".filter-menu button.filter-title-button span").each(function () {
      var span = $(this);
      span.data("title", span.text());
      span.attr("data-title", span.text());
    });
  };

  var loadSortDropdown = function () {
    var data = getDataSortLabelData();
    var html = nunjucks.render("report/table/sortDropdown.html", {
      data,
    });
    PageFunctions.getCurrentPage().find(".sort-container").html(html);
  };

  var getDataSortLabelData = function () {
    var dataSortLabelDataByLayer = LayerFunctions.getCurrentLayerProperty("dataSortLabelData");
    if (dataSortLabelDataByLayer) return dataSortLabelDataByLayer;

    var mapId = Tree.get("mapId");
    var dataSortLabelData = DataViewFunctions.getCurrentDataViewProperty("dataSortLabelData");
    if (dataSortLabelData) {
      return dataSortLabelData[mapId];
    }
  };

  var checkIfDrainagesExist = function () {
    const groupId = Session.getGroupIdToPass();

    ReportApiCalls.getUrbanDrainageStatus(groupId, function (status) {
      //if there are no urban drainages disable UD dropdown option
      if (!status.length) {
        $(".dropdown-menu.sort li[data-id=ud]").addClass("disable");
      }
    });
  };

  var closeAll = function () {
    if (filterDiv) {
      closeFilters(filterDiv.find(".js-filter-menu"));
      closeSearch(filterDiv.find(".search-input"));
    }
  };

  var filterButtonClick = function () {
    const menu = filterDiv.find(".js-filter-menu");
    if (menu.is(":visible")) {
      closeFilters(menu);
    } else {
      openFilters(menu);
    }
  };

  var closeFilters = function (menu) {
    var $filterButton = filterDiv.find(".filter-button");

    if (!$filterButton.prop("disabled") && filtersAreOpen()) {
      $filterButton.removeClass("invert-colors");
      hideFilterDropdowns();
      menu.slideUp();
      clearFilters();
    }
  };

  var openFilters = function (menu) {
    selectUrbanCatchmentDataSort();
    filterDiv.find(".filter-button").addClass("invert-colors");
    menu.slideDown();
  };

  var filtersAreOpen = function () {
    if (!filterDiv) {
      return false;
    }

    return filterDiv.find(".filter-button").hasClass("invert-colors");
  };

  var searchButtonClick = function () {
    var $searchField = filterDiv.find(".search-input");
    if ($searchField.is(":visible")) {
      closeSearch($searchField);
    } else {
      openSearch($searchField);
    }
  };

  var closeSearch = function () {
    Tree.set(["table", "searchBox"], null);
    syncSearchDomWithTree();
    setSearchFilter("");
  };

  var openSearch = function ($searchField) {
    const search = $searchField.find("input").val();
    Tree.set(["table", "searchBox"], search);
    syncSearchDomWithTree();
    selectUrbanCatchmentDataSort();
  };

  var syncSearchDomWithTree = function () {
    const $searchField = filterDiv.find(".search-input");
    const searchBox = Tree.get("table", "searchBox");

    if (searchBox === null) {
      filterDiv.find(".search-button").removeClass("invert-colors");
      $searchField.hide();
      $searchField.find("input").val("");
    } else {
      $searchField.show();
      filterDiv.find(".search-button").addClass("invert-colors");
      filterDiv.find(".search-input input").focus();
      $searchField.find("input").val(searchBox);
    }
  };

  var selectUrbanCatchmentDataSort = function () {
    const dropdownClick = thisMap.find(".dropdown-menu.sort li[data-id=uc]");
    dropdownClick.click();
  };

  var filterSelectAllClick = function () {
    var $inputsContainer = $(this).closest(".filter-dropdown");
    var $checked = $inputsContainer.find('input[type="checkbox"]').not("[disabled]");

    if ($checked.filter(":checked").length == $checked.length) {
      $checked.prop("checked", false);
    } else {
      $checked.prop("checked", true);
    }

    updateCheckbox.call(this);
  };

  var updateCheckbox = function () {
    var $inputsContainer = $(this).closest(".filter-dropdown");
    var key = $inputsContainer.find("input")[0].name;
    var checkedValues = [];
    var checkedLabels = [];
    var $title = $inputsContainer.prev().children().first();

    $inputsContainer.find("input:checked").each(function (i, chk) {
      checkedValues.push(chk.value);
      checkedLabels.push($(chk).parent().text());
    });

    Tree.set(["filters", key], checkedValues);
    updateCheckboxTitle(
      $title,
      checkedLabels,
      $inputsContainer.find("input").length,
      checkedValues,
    );
  };

  // Newer logic from ram @TODO: Merge this with updateFilterTitle
  var updateCheckboxTitle = function ($title, checkedLabels, totalCheckboxes, checkedValues) {
    const maxCheckedInTitle = 3;
    const checkedString = checkedLabels.join(", ");

    $title.attr("title", getOriginalTitleText($title) + ": " + checkedLabels);

    if (checkedLabels.length === totalCheckboxes || checkedLabels.length === 0) {
      resetOriginalTitleText($title);
      $title.attr("title", "");
    } else if (checkedLabels.length <= maxCheckedInTitle) {
      $title.text(checkedString);
    } else {
      const titleString = " (" + checkedLabels.length + ")";

      resetOriginalTitleText($title);
      $title.text($title.text() + titleString);
    }
  };

  var resetOriginalTitleText = function ($titles) {
    for (const title of $titles) {
      const $title = $(title);
      $title.text(getOriginalTitleText($title));
    }
  };

  var getOriginalTitleText = function ($title) {
    return $title.data("title");
  };

  var filterClear = function () {
    const clearButton = $(this);
    clearButton.parents(".js-filter").find("input").prop("checked", false);
    saveFilterToTree(clearButton.parents(".js-filter"));
  };

  var onSearchInput = function () {
    var searchString = $(this).val();
    Tree.set(["table", "searchBox"], searchString);
    setSearchFilter(searchString);
  };

  var setSearchFilter = function (searchString) {
    const mapId = Tree.get("mapId");
    searchString = searchString.trim().toUpperCase();

    if (searchString === Tree.get("filters", "searchString")) return;

    // Clear all catchment filters when searching, excpet in catchmentView
    const spatialView = Tree.get("spatialView", mapId);
    if (!["catchmentView", "drainageView"].includes(spatialView)) {
      Tree.set(["filters", "catchments"], []);
      Tree.set(["filters", "spatialGroupingId"], null);
      Tree.set(["selected", "catchment"], null);
    }

    Tree.set(["filters", "searchString"], searchString);
    if (!searchString.length) {
      Tree.set(["catchmentData", mapId], []);
      $(".bottomFloatingInputsTable").show();
    }

    Table.render();
  };

  var filterCatchmentsBySearch = function (catchmentArray) {
    const searchString = Tree.get("filters", "searchString");

    if (!searchString || !searchString.length) {
      return catchmentArray;
    }

    return searchCatchmentArray(searchString, catchmentArray);
  };

  var searchCatchmentArray = function (catchName, catchmentArray) {
    var filterArray = [];
    var catchArray = [];
    for (var i = 0; i < catchmentArray.length; i++) {
      if (catchmentArray[i].catchid.toUpperCase().includes(catchName.toUpperCase())) {
        filterArray.push(catchmentArray[i].catchid);
        catchArray.push(catchmentArray[i]);
      }
    }
    Tree.set(["catchmentData", Tree.get("mapId")], catchArray);
    Tree.set(["filters", "catchments"], filterArray);

    thisMap.find(".bottomFloatingInputsTable").toggle(catchArray.length !== 0);

    return catchArray;
  };

  var toggleFilterDropdown = function () {
    const filterTitleButton = $(this);
    const filterDiv = filterTitleButton.parents(".js-filter");
    const dropdown = filterDiv.find(".js-filter-dropdown");
    dropdown.slideToggle();
    filterTitleButton.toggleClass("active");
    deactivateOtherDropdowns(filterDiv, dropdown, filterTitleButton);
  };

  var deactivateOtherDropdowns = function (thisFilterDiv, thisDropdown, thisFilterTitleButton) {
    const otherButtons = thisFilterDiv
      .parents(".js-filter-menu")
      .find("button")
      .not(thisFilterTitleButton);
    otherButtons.removeClass("active");

    const otherDropdowns = thisFilterDiv
      .parents(".js-filter-menu")
      .find(".js-filter-dropdown")
      .not(thisDropdown);
    otherDropdowns.hide();
  };

  var dropdownSortClick = function (e) {
    var selected = $(this);
    var selectedId = selected.attr("data-id");
    var selectedText = selected.text();

    if (selected.hasClass("disable")) {
      e.stopPropagation();
      return;
    }

    Tree.set(["spatialView", Tree.get("mapId")], "MS4View");
    setDataSortDropdownSelection(selectedId, selectedText);
  };

  var setDataSortDropdownSelection = function (
    selectedId,
    selectedTitle,
    forceReselection = false,
  ) {
    var currentSelectionId = Tree.get("filters", "dataSort");

    //for now only allow searching catchment list for visual assessments
    if (selectedId !== "uc" && selectedId !== "plu_untreated") {
      closeAll();
    }

    if (
      (forceReselection === false && selectedId == currentSelectionId) ||
      (selectedId == "uc" && currentSelectionId == "plu_untreated")
    ) {
      return;
    }

    if (selectedId !== currentSelectionId) {
      Table.resetTable();
    }

    //set dropdown text to selected list item
    thisMap
      .find(".dropdown-toggle.sort")
      .attr("data-id", selectedId)
      .html(
        `<span class="current-sort-text">${selectedTitle}</span><span class="glyphicon glyphicon-menu-down"></span>`,
      );

    //reset dropdown items
    thisMap.find(".dropdown-menu.sort li").removeClass("dropdown-selection");
    thisMap.find(".dropdown-menu.sort span").hide();

    //bold & add check to selected list item
    thisMap
      .find(".dropdown-menu.sort li[data-id='" + selectedId + "']")
      .addClass("dropdown-selection");

    thisMap.find(".dropdown-menu.sort li[data-id='" + selectedId + "'] span").show();
    Tree.set(["filters", "dataSort"], selectedId); //set table view

    TreeUpdates.refreshAllLayers();
  };

  var dropdownMenuClick = function () {
    var selected = $(this);
    var selectedId = selected.attr("data-id");
    var selectedText = selected.text();
    var currentSelection = $(".dropdown-toggle.view");
    var currentSelectionId = currentSelection.attr("data-id");
    if (selectedId == currentSelectionId) {
      return;
    } else {
      //set dropdown text to selected list item
      $(".dropdown-toggle.view")
        .attr("data-id", selectedId)
        .html(selectedText + '<span class="glyphicon glyphicon-menu-down"></span>');

      //reset dropdown items
      $(".dropdown-menu.view li").removeClass("dropdown-selection");
      $(".dropdown-menu.view span").hide();

      //bold & add check to selected list item
      $(".dropdown-menu.view li[data-id='" + selectedId + "']").addClass("dropdown-selection");
      $(".dropdown-menu.view li[data-id='" + selectedId + "'] span").show();
    }
  };

  var filterSingleCheckboxClick = function (e) {
    const $target = $(e.target);
    const key = $target.closest('input[type="checkbox"]').val();
    const checked = $target
      .parents(".js-filter-menu")
      .find("input[value='" + key + "']")
      .prop("checked");
    Tree.set(["filters", key], checked);
  };

  var onInputClicked = function () {
    const filterDiv = $(this).parents(".js-filter");
    var mapId = Tree.get("mapId");

    // Hack: Only call saveFilterToTree for filtering in Plan map, other filter inputs are handled separately
    if (mapId === "plan") {
      saveFilterToTree(filterDiv);
    } else {
      updateCheckbox.call(this);
    }
  };

  var flat = function (arr) {
    return arr.reduce((x, y) => x.concat(y));
  };

  var saveFilterToTree = function (filterDiv) {
    const maybeCheckboxFilter = getFilterFromSelectedCheckboxes(filterDiv);
    const maybeRadioFilter = getFilterFromSelectedRadios(filterDiv);
    maybeCheckboxFilter.concat(maybeRadioFilter).forEach((filter) => {
      const filterName = filter.filterName;
      const filterContents = filter[filterName];
      filterContents.forEach((filterRadioGroup) => {
        const inputName = filterRadioGroup.inputName;
        const values = filterRadioGroup.values;
        const camelCaseInputName = kebabToCamelCase(inputName);
        Tree.set(["filters", filterName, camelCaseInputName], values);
      });
      const allLabels = flat(filterContents.map((filterRadioGroup) => filterRadioGroup.labels));
      updateFilterTitle(filterDiv, allLabels);
    });
  };

  var kebabToCamelCase = function (str) {
    return str.replace(/\-\w/g, (m) => m[1].toUpperCase());
  };

  var getFilterFromSelectedCheckboxes = function (filterDiv) {
    const selectedCheckboxes = filterDiv.find("input[type=checkbox]:checked").get();
    if (selectedCheckboxes.length === 0) {
      return [];
    }
    const values = selectedCheckboxes.map((checkboxInput) => checkboxInput.value);
    const labels = selectedCheckboxes.map((checkboxInput) => $(checkboxInput).parent().text());
    if (!selectedCheckboxes.every((checkbox) => checkbox.name === selectedCheckboxes[0].name)) {
      console.error("Not expecting multiple checkbox groups in one filter.");
    }
    const inputName = selectedCheckboxes[0].name;
    const filterName = filterDiv.data().filter;
    return [
      {
        filterName: filterName,
        [filterName]: {
          inputName: inputName,
          values: values,
          labels: labels,
        },
      },
    ];
  };

  var getFilterFromSelectedRadios = function (filterDiv) {
    const radios = filterDiv.find("input[type=radio]");
    const numRadioGroups = getNumRadioGroups(radios);
    let selectedRadios = radios.filter(":checked");
    const numSelectedRadioGroups = getNumRadioGroups(selectedRadios);
    selectedRadios = selectedRadios.get();

    if (numSelectedRadioGroups !== 0 && numSelectedRadioGroups < numRadioGroups) {
      return [];
    }
    if (numSelectedRadioGroups === 0) {
      let radioGroupNames = radios.get().map((radio) => radio.name);
      radioGroupNames = Array.from(new Set(radioGroupNames));
      const filterName = filterDiv.data().filter;
      return [
        {
          filterName: filterName,
          [filterName]: radioGroupNames.map((name) => {
            return {
              inputName: name,
              values: [null],
              labels: [],
            };
          }),
        },
      ];
    }

    const filter = {
      filterName: filterDiv.data().filter,
    };

    selectedRadios.forEach((radio) => {
      const filterName = filterDiv.data().filter;
      filter[filterName] = [];
    });

    selectedRadios.forEach((radio) => {
      const filterName = filterDiv.data().filter;
      filter[filterName] = filter[filterName].concat([
        {
          inputName: radio.name,
          values: [radio.value],
          labels: [radio.label],
        },
      ]);
    });
    return [filter];
  };

  var getNumRadioGroups = function (radios) {
    const radioNames = radios.get().map((radio) => radio.name);
    return new Set(radioNames).size;
  };

  var updateFilterTitle = function (filterDiv, selectedLabels) {
    const titleSpan = filterDiv.find("js-filter-title");

    if (selectedLabels.length > 0) {
      titleSpan.text(selectedLabels.join(", "));
    } else {
      titleSpan.text(titleSpan.data("title"));
    }
  };

  var syncSpatialFiltersWithTable = function () {
    const selectedCatchment = Tree.get("selected", "catchment");
    const selectedUrbanDrainage = Tree.get("selected", "urbanDrainage");
    const selectedReceivingWater = Tree.get("selected", "receivingWater");
    const receivingWaters = selectedReceivingWater
      ? [selectedReceivingWater]
      : selectedUrbanDrainage;

    if (receivingWaters) {
      updateTreeIfDifferentArray(["filters", "receivingWaters"], receivingWaters);
    } else {
      updateTreeIfDifferentArray(["filters", "receivingWaters"], []);
    }

    if (selectedCatchment) {
      updateTreeIfDifferentArray(["filters", "catchments"], [selectedCatchment]);
    } else if (!searchFilterIsSet()) {
      updateTreeIfDifferentArray(["filters", "catchments"], []);
    }
  };

  /*
    Setting any array triggers an update event in the tree.
  */
  var updateTreeIfDifferentArray = function (treePath, newArray) {
    const currentArray = Tree.get(treePath);

    if (newArray.length !== currentArray.length) {
      Tree.set(treePath, newArray);
      return;
    }

    for (let i = 0; i < newArray.length; i++) {
      if (currentArray[i] !== newArray[i]) {
        Tree.set(treePath, newArray);
        return;
      }
    }
  };

  var clearFilters = function () {
    // @TODO: clear filters by dataView and/or mapId
    Tree.set(["filters", "condition"], []);
    Tree.set(["filters", "connectivity"], null);
    Tree.set(["filters", "dateFrom"], null);
    Tree.set(["filters", "dateTo"], null);
    Tree.set(["filters", "percentileUntreatedPlu", "percentileUntreatedPlu"], [null]);
    Tree.set(["filters", "percentileUntreatedPlu", "spatialTypeUntreatedPlu"], [null]);
    loadFilterMenu();
    setDataViewDefaultFilters();
  };

  var filterCatchments = function (catchmentType) {
    const catchmentLoadData = Tree.get("layers", catchmentType, "allData");
    const catchArray = getCatchArray();
    let filteredCatchmentData = catchmentLoadData; //default all outfalls unfiltered

    //filter outfalls based on selected catchments
    if (catchArray.length) {
      filteredCatchmentData = filteredCatchmentData.filter(
        (catchment) => catchArray.indexOf(catchment.properties.catchid) > -1,
      );
    }

    Tree.set(["layers", catchmentType, "data"], filteredCatchmentData);
  };

  var getCatchArray = function (mapId) {
    const Actions = require("../actions");
    var filters = Tree.get("filters");
    var allCatchments = Actions.getCatchmentData();
    var catchArray = [];
    var catchments = filters.catchments;
    var receivingWaters = filters.receivingWaters;
    var filterColumn = filters.dataSort == "ud" ? "drains_to_c" : "drains_to_rw";

    if (catchments.length) {
      catchArray = catchments;
    } else if (receivingWaters.length) {
      //filter out catchments not in selected header
      allCatchments.forEach(function (catchment) {
        if (catchment[filterColumn] == receivingWaters[0]) {
          catchArray.push(catchment.catchid);
        }
      });
    }
    return catchArray;
  };

  var hideFilterDropdowns = function () {
    filterDiv.find(".js-filter-menu button").removeClass("active");
    filterDiv.find(".filter-dropdown").hide();
  };

  var spatialFilterIsSet = function () {
    var receivingWaters = Tree.get(["filters", "receivingWaters"]);
    var catchments = Tree.get(["filters", "catchments"]);

    return (receivingWaters && receivingWaters.length) || (catchments && catchments.length);
  };

  var searchFilterIsSet = function () {
    var searchString = Tree.get(["filters", "searchString"]);

    return searchString && searchString.length;
  };

  var setDataViewDefaultFilters = function () {
    var dataViewDefaultFilters = getDataViewDefaultFilters();

    if (dataViewDefaultFilters) {
      for (const filter of Object.keys(dataViewDefaultFilters)) {
        Tree.set(["filters", filter], dataViewDefaultFilters[filter]);
      }
    }
    setCheckboxesToDefault(dataViewDefaultFilters);
  };

  var setCheckboxesToDefault = function (filters) {
    var $filterContainer = $(".js-filter-menu");

    for (const name in filters) {
      const $allCheckboxes = $filterContainer.find("[name='" + name + "']");
      const values = filters[name];

      for (const checkbox of $allCheckboxes) {
        const $checkbox = $(checkbox);

        if (values.includes($checkbox.val())) {
          $checkbox.prop("checked", true);
        }
      }
    }
  };

  var getDataViewDefaultFilters = function () {
    var dataView = Tree.get("dataView");
    var mapId = Tree.get("mapId");
    var defaultFilters = null;
    var dataViewDefaultFilters = FilterConstants.getDefaultFiltersByDataViewAndMapId()[dataView];

    if (dataViewDefaultFilters) {
      defaultFilters = dataViewDefaultFilters[mapId];
    }

    if (!defaultFilters) {
      console.warn(
        `No default filters provided for dataView ${dataView} & mapId ${mapId} in filterConstants`,
      );
    }
    return defaultFilters;
  };

  return {
    load,
    loadFilterListeners,
    loadFilters,
    disableSearchIfInConfig,
    filterCatchments,
    setDataSortDropdownSelection,
    loadSortDropdown,
    closeAll,
    filterCatchmentsBySearch,
    filtersAreOpen,
    spatialFilterIsSet,
    searchFilterIsSet,
    syncSearchDomWithTree,
    syncSpatialFiltersWithTable,
    setDataViewDefaultFilters,
  };
};

module.exports = Filters();

const Tree = require("../../tree");
const Misc = require("../misc");
const ReportApiCalls = require("../reportApiCalls");
const Session = require("../../login/session");
const Table = require("./table");
const DataViewFunctions = require("../dataViewFunctions");
const FilterConstants = require("./filterConstants");
const TreeUpdates = require("./treeUpdates");
const PageFunctions = require("../pageFunctions");
const LayerFunctions = require("./layerFunctions");
