"use strict";

const Misc = function () {
  var timeoutIds = {};

  var hexToRgb = function (hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
      : null;
  };

  var isDarkColor = function (...color) {
    // http://www.w3.org/TR/AERT#color-contrast
    let r, g, b;
    if (color.length === 1) {
      color = hexToRgb(color[0]);
    }
    [r, g, b] = color;
    const brightness = Math.round(
      (parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000,
    );
    return brightness <= 125;
  };

  var formatLargeNumber = function (num, digits, noZeroDecimal = false) {
    function numberPart(number) {
      if (noZeroDecimal && Number.isInteger(number)) digits = 0;
      return number.toFixed(digits);
    }
    if (parseFloat(num) === 0) return "0";
    const negativePrefix = num < 0 ? "-" : "";
    num = Math.abs(num);
    if (num < 1000) return negativePrefix + numberPart(num);
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return negativePrefix + numberPart(num / item.value).replace(rx, "$1") + item.symbol;
  };

  var floorDecimals = function (number, precision) {
    const multiplier = Math.pow(10, precision);
    return Math.floor(number * multiplier) / multiplier;
  };

  var debounce = function (fn) {
    clearTimeout(timeoutIds[fn.name]);
    timeoutIds[fn.name] = setTimeout(fn);
  };

  var debounceDelay = function (fn, time) {
    var timeout;
    time = time || 500;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(fn.bind(this, ...arguments), time);
    };
  };

  var getOptionNameByValue = function (options, value) {
    return options.find((item) => item.value === value)?.name;
  };

  var pushOptionToEndOfArray = function (options, value) {
    if (options?.length > 1) {
      const index = options.findIndex((item) => item.value === value);

      if (index !== -1) {
        const otherItem = options.splice(index, 1)[0];
        options.push(otherItem);
      }
    }
  };

  var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  var kebabToCamel = function (string) {
    return string.replace(/(\-\w)/g, function (matches) {
      return matches[1].toUpperCase();
    });
  };

  // From https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707
  var camelTo = function (string, separator) {
    return string.replace(/([a-z0-9])([A-Z])/g, "$1" + separator + "$2").toLowerCase();
  };

  var camelToKebab = function (string) {
    return camelTo(string, "-");
  };

  // From https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707
  var camelToSnake = function (string) {
    return camelTo(string, "_");
  };

  var toggleDisabledInputs = function ($element, disabled) {
    var selector = "input, select, option, textarea, button, .delete-btn";
    var $inputs = $element.find(selector).addBack(selector);

    $element.toggleClass("hide-all-buttons", disabled);

    $inputs.each(function () {
      const $input = $(this);
      if (!$input.hasClass("never-toggle-disabled")) {
        toggleDisabled($input, disabled);
      }
    });
  };

  var toggleDisabled = function ($element, disabled) {
    $element.prop("disabled", disabled);
    togglePlaceholder($element, !disabled);
    $element.nextAll("label").first().toggleClass("disabled", disabled);
  };

  var togglePlaceholder = function ($element, showPlaceholder) {
    let placeholder = "";

    if (showPlaceholder) {
      placeholder = $element.data("placeholder");
    } else {
      const existingPlaceholder = $element.attr("placeholder");
      $element.data("placeholder", existingPlaceholder);
    }

    $element.attr("placeholder", placeholder);
  };

  var isDisabled = function ($element) {
    if ($element.propertyIsEnumerable(0)) {
      throw "isDisabled() parameter is not a single element.";
    }
    return $element.prop("disabled") === true;
  };

  var countDecimals = function (value) {
    if (value % 1 != 0) return value.toString().split(".")[1].length;
    return 0;
  };

  var subtractArrayItems = function (array, arrayToSubtract) {
    if (array && array.length && arrayToSubtract && arrayToSubtract.length) {
      return array.filter((el) => !arrayToSubtract.includes(el));
    }
    return array;
  };

  var arrayToPrettyString = function (array) {
    var prettyString = "";
    var joiner = array.length > 2 ? ", " : " and ";

    if (array.length === 0) {
      return prettyString;
    }

    for (let i = 0; i < array.length - 1; i++) {
      prettyString += array[i] + joiner;
    }

    if (array.length > 2) {
      prettyString += "and ";
    }

    prettyString += array[array.length - 1] + ".";

    return prettyString;
  };

  var pluralIsAre = function (isPlural) {
    return isPlural ? "s are" : " is";
  };

  var clamp = function (min, number, max) {
    return Math.max(min, Math.min(number, max));
  };

  var formDataToObj = function (formSelector) {
    return $(formSelector)
      .serializeArray()
      .reduce(function (obj, item) {
        obj[item.name] = item.value;
        return obj;
      }, {});
  };

  /*
    This is a hack.
    It's not recommended to bind both the
    click and dblclick events to the same object.
    As there is no way to determine the double
    click interval of the OS, we have to guess.
  */
  var bindDoubleClickListeners = function (
    container,
    selector,
    clickListener,
    doubleClickListener,
  ) {
    const windowsDefaultDoubleClickTime = 500;
    var timers = [];

    container
      .on("click", selector, function (e) {
        const eventEnviroment = this;

        const timer = setTimeout(function () {
          clickListener.call(eventEnviroment, e);
        }, windowsDefaultDoubleClickTime);

        timers.push(timer);
      })
      .on("dblclick", selector, function (e) {
        doubleClickListener.call(this, e);

        for (const timer of timers) {
          clearTimeout(timer);
        }
        timers = [];
      });
  };

  // Strings with trailing zeros are considered not ints since trailing zeros
  // are often significant in scientific data.
  var isIntOrStringInt = function (intOrStringInt) {
    if (typeof intOrStringInt === "string") {
      return $.isNumeric(intOrStringInt) && !intOrStringInt.includes(".");
    } else if (typeof intOrStringInt === "number") {
      return Number.isInteger(intOrStringInt);
    }
    throw "Input was not a number or string.";
  };

  var getSequenceGenerator = function* (prefix = "", startAt = 0) {
    let id = startAt;
    while (true) {
      yield `${prefix}${id++}`;
    }
  };

  var removeAttributeValues = function (valuesToKeep, removeFrom, attribute) {
    removeFrom.forEach((item) => {
      if (!valuesToKeep.includes(item[attribute])) {
        delete item[attribute];
      }
    });
  };

  var appendExistingAttribute = function (values, appendTo, attribute) {
    appendTo.forEach((item, index) => {
      if (values[index] !== undefined && item !== undefined && item[attribute] === undefined) {
        item[attribute] = values[index];
      }
    });
  };

  var getStringBooleanValue = function (stringBoolean) {
    if (typeof stringBoolean === "boolean") {
      return stringBoolean;
    }
    return stringBoolean === "true" ? true : false;
  };

  var groupBy = function (items, key) {
    return items.reduce(function (carry, item) {
      (carry[item[key]] = carry[item[key]] || []).push(item);
      return carry;
    }, {});
  };

  var divideOrNa = function (numerator, denominator, excludeZero = false) {
    const result = numerator / denominator;
    if ((result == 0 && excludeZero) || isNaN(result) || !isFinite(result)) {
      return "—";
    } else {
      return result;
    }
  };

  var assertPromise = function (promise) {
    if (!(promise instanceof Promise)) {
      // This should throw an error, but a lot of these functions aren't promises,
      // so we can switch to an exception after we've fixed the existing issues.
      console.error(`The passed ${typeof promise} should be a promise.`);
    }

    return promise;
  };

  var getFullMailingAddress = function (mailingAddress, city, state, zipCode) {
    let addressBuilder = mailingAddress ?? "";

    if (city) {
      if (addressBuilder) {
        addressBuilder += " ";
      }
      addressBuilder += city;
    }
    if (state) {
      if (addressBuilder) {
        addressBuilder += ", ";
      }
      addressBuilder += state;
    }
    if (zipCode) {
      if (addressBuilder) {
        addressBuilder += " ";
      }
      addressBuilder += zipCode;
    }

    if (addressBuilder === "") {
      return null;
    }

    return addressBuilder;
  };

  var eachNestedPropertyWithKey = function (data, targetKey, eachFunction) {
    if (objectOrArray(data)) {
      for (const key in data) {
        if (key === targetKey) {
          eachFunction(data[key], data);
        } else {
          eachNestedPropertyWithKey(data[key], targetKey, eachFunction);
        }
      }
    }
  };

  var objectOrArray = function (data) {
    return (typeof data === "object" && data !== null) || Array.isArray(data);
  };

  var toTitleCase = function (string) {
    if (typeof string === "string" || string instanceof String) {
      return string
        .toLowerCase()
        .split(" ")
        .map((string) => string.charAt(0).toUpperCase() + string.substring(1))
        .join(" ");
    }
    return string;
  };

  var toCamelCase = function (string) {
    return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  };

  var onlyContainsNumbers = function (string) {
    return /^\d+(\.\d+)?$/.test(string);
  };

  var formatRankNumber = function (num) {
    if (!isNaN(num)) {
      var j = num % 10;
      var k = num % 100;

      if (j == 1 && k != 11) {
        return num + "st";
      }
      if (j == 2 && k != 12) {
        return num + "nd";
      }
      if (j == 3 && k != 13) {
        return num + "rd";
      }
      return num + "th";
    }
  };

  var convertStateForAutoPopulate = function (str) {
    if (!str) {
      return "";
    }
    return str.replace(/\s+/g, "-").toLowerCase();
  };

  var inverseConvertState = function (str) {
    if (!str) {
      return "";
    }
    return str
      .replace(/\b\w/g, function (l) {
        return l.toUpperCase();
      })
      .replace(/-/g, " ");
  };

  // About 18000% faster than structuredClone()
  var shallowCloneArrayObjects = function (array) {
    return array.map(shallowCloneObject);
  };

  var shallowCloneObject = function (datum) {
    if (typeof datum === "object" && datum !== null) {
      return { ...datum };
    }

    return datum;
  };

  var deepFreezeObject = function (object) {
    if (!Object.isFrozen(object)) {
      Object.freeze(object);

      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          deepFreezeObject(object[key]);
        }
      }
    }

    return object;
  };

  // same as API RandomUtilities@getAspectDisplay
  const getAspectDisplay = function (degrees, short = false) {
    if (degrees === null || isNaN(Number(degrees))) {
      return null;
    }
    if (degrees === -1) {
      return "Flat";
    }

    const directions = [
      ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
      ["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"],
    ];

    const index = Math.floor((Number(degrees) + 22.5) / 45) % 8;
    return short ? directions[0][index] : directions[1][index];
  };

  return {
    hexToRgb,
    formatLargeNumber,
    floorDecimals,
    debounce,
    debounceDelay,
    capitalizeFirstLetter,
    toggleDisabled,
    toggleDisabledInputs,
    isDisabled,
    isDarkColor,
    countDecimals,
    subtractArrayItems,
    arrayToPrettyString,
    pluralIsAre,
    clamp,
    kebabToCamel,
    camelToKebab,
    formDataToObj,
    bindDoubleClickListeners,
    isIntOrStringInt,
    getSequenceGenerator,
    removeAttributeValues,
    appendExistingAttribute,
    getStringBooleanValue,
    groupBy,
    divideOrNa,
    assertPromise,
    getFullMailingAddress,
    eachNestedPropertyWithKey,
    getOptionNameByValue,
    pushOptionToEndOfArray,
    camelToSnake,
    toTitleCase,
    onlyContainsNumbers,
    formatRankNumber,
    convertStateForAutoPopulate,
    inverseConvertState,
    shallowCloneArrayObjects,
    shallowCloneObject,
    deepFreezeObject,
    toCamelCase,
    getAspectDisplay,
  };
};

module.exports = Misc();
