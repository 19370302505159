"use strict";

const StormdrainLayer = function () {
  var loadStormdrains = function (mapId) {
    var dataPath = mapId === "modal" ? "dataModal" : "data";
    var filters = Actions.getFiltersByMapId(mapId);

    Tree.set(["layers", "stormdrains", "isFetching"], true);

    ApiCalls.getStormdrains(
      filters,
      function (data) {
        var geoJson = CleanData.cleanGeoJson(data, "LineString");
        Tree.set(["layers", "stormdrains", dataPath], geoJson);
        Tree.set(["layers", "stormdrains", "isFetching"], false);
      },
      null,
    );
  };

  var addAllStormdrainLayers = function (map, mapLayers, data, mapId) {
    var zoomLevel = map.getZoom();
    mapLayers.baseStormdrainLayer = addBaseStormdrainLayer(
      map,
      mapLayers.baseStormdrainLayer,
      data,
    );
    mapLayers.middleStormdrainLayer = addMiddleStormdrainLayer(
      map,
      mapLayers.middleStormdrainLayer,
      data,
    );
    mapLayers.stormdrainLayer = addStormdrainLayer(map, mapLayers, data, mapId);

    //if toggle on in midrange zoom level restyle base layer
    if (zoomLevel >= 14) {
      $(".stormdrain-legend").removeClass("mid-zoom");
    } else {
      map.removeLayer(mapLayers.stormdrainLayer);
      map.removeLayer(mapLayers.middleStormdrainLayer);
      mapLayers.baseStormdrainLayer.setStyle(MapStyles.styleBaseStormdrainThin);
      $(".stormdrain-legend").addClass("mid-zoom");
    }
  };

  var addStormdrainLayer = function (map, mapLayers, data, mapId) {
    if (mapLayers.stormdrainLayer) {
      map.removeLayer(mapLayers.stormdrainLayer);
    }
    mapLayers.stormdrainLayer = new Layers.StormdrainLayer();
    mapLayers.stormdrainLayer.addData(data);
    startStormdrainsZoomListener(map, mapLayers, mapId);

    return mapLayers.stormdrainLayer.addTo(map);
  };

  var addMiddleStormdrainLayer = function (map, middleStormdrainLayer, data) {
    if (middleStormdrainLayer) {
      map.removeLayer(middleStormdrainLayer);
    }
    middleStormdrainLayer = new Layers.MiddleStormdrainLayer();
    middleStormdrainLayer.addData(data);

    return middleStormdrainLayer.addTo(map);
  };

  var addBaseStormdrainLayer = function (map, baseStormdrainLayer, data) {
    if (baseStormdrainLayer) {
      map.removeLayer(baseStormdrainLayer);
    }
    baseStormdrainLayer = new Layers.BaseStormdrainLayer();
    baseStormdrainLayer.addData(data);

    return baseStormdrainLayer.addTo(map);
  };

  var startStormdrainsZoomListener = function (map, mapLayers) {
    map.on("zoomend", function () {
      handleStormdrainDisplay(map, mapLayers);
    });
  };

  var handleLayerDisplay = function (layerName, currentMap, mapLayers, style) {
    const layer = mapLayers[layerName];
    const hasLayer = layer && currentMap.hasLayer(layer);
    if (!hasLayer) currentMap.addLayer(layer);
    layer.setStyle(style);
  };

  var handleStormdrainDisplay = function (map, mapLayers, mapId) {
    var mapZoom = map.getZoom();
    var isEnabledPath = Actions.getLayerIsEnabledPathByMapId(mapId);
    var hasDataPath = Actions.getLayerDataExistsPathByMapId(mapId);
    var stormdrainsEnabled = Tree.get(["layers", "stormdrains", isEnabledPath]);
    var stormdrainsExist = Tree.get(["layers", "stormdrains", hasDataPath])?.features?.length > 0;

    if (stormdrainsExist && stormdrainsEnabled) {
      if (!mapZoom > 12) {
        //hide all storm drains
        handleLayerDisplay("stormdrainLayer", map, mapLayers, { opacity: 0 });
        handleLayerDisplay("middleStormdrainLayer", map, mapLayers, { opacity: 0 });
        handleLayerDisplay("baseStormdrainLayer", map, mapLayers, { opacity: 0 });
        $(".stormdrain-legend").addClass("mid-zoom");
      } else {
        if (mapZoom <= 14) {
          //display the thin layer of base storm drains only
          handleLayerDisplay("stormdrainLayer", map, mapLayers, { opacity: 0 });
          handleLayerDisplay("middleStormdrainLayer", map, mapLayers, { opacity: 0 });
          handleLayerDisplay(
            "baseStormdrainLayer",
            map,
            mapLayers,
            MapStyles.styleBaseStormdrainThin,
          );
          $(".stormdrain-legend").addClass("mid-zoom");
        } else {
          //display the three layers of storm drains
          handleLayerDisplay("baseStormdrainLayer", map, mapLayers, MapStyles.styleBaseStormdrain);
          handleLayerDisplay("middleStormdrainLayer", map, mapLayers, { opacity: 1 });
          handleLayerDisplay("stormdrainLayer", map, mapLayers, { opacity: 1 });
          $(".stormdrain-legend").removeClass("mid-zoom");
        }
      }
    }
  };

  return {
    addAllStormdrainLayers,
    loadStormdrains,
    handleStormdrainDisplay,
  };
};

module.exports = StormdrainLayer();

const Actions = require("../actions");
const ApiCalls = require("../apiCalls");
const CleanData = require("../mapFunctions/cleanData");
const Layers = require("../mapFunctions/layers");
const MapStyles = require("../mapFunctions/mapStyles");
const Tree = require("../tree");
