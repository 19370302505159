"use strict";

const InsightWidget = function (widgetConfig) {
  this.widgetConfig = widgetConfig;
  this.widgetId = UUID.v4();
};

InsightWidget.prototype.getDefaultChartDimensions = function () {
  return {
    containerHeight: 180,
    margin: { top: 20, right: 10, bottom: 25, left: 35 },
  };
};

InsightWidget.prototype.getSize = function () {
  return this.widgetConfig.size;
};

InsightWidget.prototype.getSizeMd = function () {
  return this.widgetConfig.sizeMd;
};

InsightWidget.prototype.getSizeSm = function () {
  return this.widgetConfig.sizeSm;
};

InsightWidget.prototype.getWidgetElement = function () {
  return $(`[data-widget-id="${this.widgetId}"]`);
};

InsightWidget.prototype.getTrendIconClass = function (focusNumber, comparisonNumber) {
  if (focusNumber !== null && comparisonNumber !== null) {
    if (focusNumber < comparisonNumber) {
      const color = this.widgetConfig.neutralTrendColor
        ? "grey"
        : this.widgetConfig.upGreenDownRed
          ? "red"
          : "green";
      return `trend-arrow-down-${color}`;
    } else if (focusNumber > comparisonNumber) {
      const color = this.widgetConfig.neutralTrendColor
        ? "grey"
        : this.widgetConfig.upGreenDownRed
          ? "green"
          : "red";
      return `trend-arrow-up-${color}`;
    } else {
      return "trend-equal";
    }
  }
};

InsightWidget.prototype.getTrendPercentDiff = function (focusNumber, comparisonNumber) {
  if (focusNumber !== null && comparisonNumber !== null && comparisonNumber !== 0) {
    if (focusNumber === comparisonNumber) {
      return 0;
    }

    return Math.abs((focusNumber - comparisonNumber) / comparisonNumber) * 100;
  }
};

InsightWidget.prototype.getFocusOverComparisonPercent = function (focusNumber, comparisonNumber) {
  if (focusNumber !== null && comparisonNumber !== null && comparisonNumber !== 0) {
    return Math.abs(focusNumber / comparisonNumber) * 100;
  }
};

InsightWidget.prototype.appendStandardChartLegend = function (
  svg,
  legendData,
  legendCountPerRow = 2, // assuming max 2 rows
  alignItems = "space-between", // "space-between" or "left"
  reverseOrder = false,
) {
  if (!svg.node()) return; // prevent error when svg is not rendered due to no data

  const size = 10;
  const labelPadding = 5;
  const itemPadding = 10;
  const width = svg.node().getBoundingClientRect().width;
  const legendItemWidth = width / legendCountPerRow;

  var legendDataArray = reverseOrder
    ? Object.values(legendData).reverse()
    : Object.values(legendData);

  var legendItem = svg
    .append("g")
    .attr("class", "legend-container")
    .selectAll(".legend-item")
    .data(legendDataArray)
    .enter()
    .append("g")
    .attr("class", "legend-item");

  if (alignItems === "left") {
    let countInCurrentRow = 0;
    let prevWidth = 0;

    legendItem.each(function (d, i) {
      const textElement = svg.append("text").text(d.label);
      const textWidth = textElement.node().getBoundingClientRect().width;
      textElement.remove();

      if (countInCurrentRow >= legendCountPerRow) {
        countInCurrentRow = 0;
        prevWidth = 0;
      }

      const rectX = prevWidth;
      const textX = prevWidth + size + labelPadding;
      prevWidth += textWidth + size + 2 * labelPadding + itemPadding;
      countInCurrentRow += 1;

      const y = (i > legendCountPerRow - 1 ? 1 : 0) * (size + labelPadding);

      d3.select(this)
        .append("rect")
        .attr("x", rectX)
        .attr("y", y)
        .attr("width", size)
        .attr("height", size)
        .attr("stroke-width", 0)
        .attr("fill", d.color);

      d3.select(this)
        .append("text")
        .attr("alignment-baseline", "middle")
        .attr("font-size", "10px")
        .attr("x", textX)
        .attr("y", y + size / 2 + 1)
        .text(d.label);
    });
  } else if (alignItems === "space-between") {
    legendItem.each(function (d, i) {
      const x = (i % legendCountPerRow) * legendItemWidth;
      const y = Math.floor(i / legendCountPerRow) * (size + labelPadding);

      d3.select(this)
        .append("rect")
        .attr("x", x)
        .attr("y", y)
        .attr("width", size)
        .attr("height", size)
        .attr("stroke-width", 0)
        .attr("fill", d.color);

      d3.select(this)
        .append("text")
        .attr("alignment-baseline", "middle")
        .attr("font-size", "10px")
        .attr("x", x + size + labelPadding)
        .attr("y", y + size / 2 + 1)
        .text(d.label);
    });
  }
};

module.exports = InsightWidget;

const UUID = require("uuid");
const d3 = require("d3-v6");
