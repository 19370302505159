"use strict";

const LocationButton = function () {
  var initializeButtons = function ($parent, formKey) {
    for (const button of $parent.find(".location-button")) {
      initializeButton($(button), formKey);
    }
  };

  var initializeButton = function ($button, formKey) {
    assertIsButton($button);

    $button.data("formKey", formKey);

    if ($button.data("latLng")) {
      $button.off("click", _removeLocation);
      $button.on("click", _removeLocation);
    } else {
      $button.off("click", _saveLocation);
      $button.on("click", _saveLocation);
    }
  };

  var assertIsButton = function ($button) {
    if ($button?.length !== 1) {
      throw new Error(`${$button} must be a single jQuery element.`);
    }
    if (!$button.hasClass("location-button")) {
      throw new Error(`${$button?.html()} is not a location button.`);
    }
  };

  var _saveLocation = async function () {
    const $button = $(this);

    if ($button.data("isLoading")) {
      return;
    }

    await updateLocation($button);
    if ($button.data("latLng")) {
      setFormDataFieldForButton($button);
    }
    replaceLocationButton($button);
  };

  var updateLocation = async function ($button) {
    setLoadingState($button);
    const latLng = await getLocation($button);
    $button.data("latLng", latLng);
  };

  var setLoadingState = function ($button) {
    $button.data("isLoading", true);
    $button.find(".js-target-location-text").text("Getting current location...");
  };

  var getLocation = async function () {
    try {
      return await Geolocation.getCurrentLatLng();
    } catch (geolocationPositionErrorCode) {
      // Code 1 = PERMISSION_DENIED
      if (geolocationPositionErrorCode === 1) {
        MessageModal.showSimpleWarningModal(
          "Access to location information had been denied. Please allow access in your browser.",
        );
      } else {
        MessageModal.showSimpleWarningModal(
          "Unable to get location information. Please try again.",
        );
      }
      return null;
    }
  };

  var setFormDataFieldForButton = function ($button) {
    const formKey = $button.data("formKey");
    const path = Form.getPathPartsFromName($button.attr("name"));

    Form.manuallySetFormDataField(formKey, path, $button.data("latLng"));
  };

  var replaceLocationButton = function ($oldButton) {
    const formKey = $oldButton.data("formKey");
    const $newButton = $(
      nunjucks.render("macros/macroTemplates/locationButton.njk", {
        latLng: $oldButton.data("latLng"),
        name: $oldButton.attr("name"),
      }),
    );
    $oldButton.replaceWith($newButton);

    initializeButton($newButton, formKey);

    return $newButton;
  };

  var _removeLocation = async function () {
    const $button = $(this);
    $button.data("latLng", null);

    setFormDataFieldForButton($button);
    replaceLocationButton($button);
  };

  return {
    initializeButtons,
    initializeButton,
    _saveLocation,
    _removeLocation,
  };
};

module.exports = LocationButton();

const Form = require("./form");
const Geolocation = require("../mapFunctions/geolocation");
const MessageModal = require("../modals/messageModal");
