"use strict";

const IndcomIcon = function () {
  var getFacilityMarker = function (latlng, data) {
    return L.marker(latlng, {
      icon: getFacilityIconMarker(data),
    });
  };

  var getFacilityIconMarker = function (data, modalMap = false) {
    const zoomUpdatedIconsSizeOptions = MapFunctions.resizeIconsOnZoom(
      MainMap.getMap()?.getZoom(),
      30,
      15,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      8,
    );
    return new L.DivIcon({
      className: getFacilityIconClass(data),
      iconSize: modalMap ? 22 : zoomUpdatedIconsSizeOptions.size,
    });
  };

  var getFacilityIconClass = function (data) {
    if (Tree.get(["filters", "conditionPhaseToggle"]) === "phase") {
      return getFacilityIconClassByPhase(data);
    } else {
      return getFacilityIconClassByCondition(data);
    }
  };

  var getFacilityIconClassByPhase = function (data) {
    let classStr = "indcom-facility-phase";
    if (data?.facilityPhase === "closed") {
      classStr += "-closed";
    } else {
      classStr += "-open";
    }

    return classStr;
  };

  var getFacilityIconClassByCondition = function (data) {
    let classStr = "indcom-facility-condition";

    if (data?.indcomFacilityInspection?.isDue) {
      classStr += "-due";
    } else if (data?.hasInspections) {
      if (data?.hasOpenFindings) {
        classStr += "-finding";
      } else {
        classStr += "-no-finding";
      }
    } else {
      classStr += "-no-inspection";
    }

    if (data?.priority) {
      classStr += "-priority";
    }

    if (data?.hasOpenEnforcements) {
      classStr += "-enforcement";
    }

    return classStr;
  };

  return {
    getFacilityMarker,
    getFacilityIconClass,
    getFacilityIconMarker,
  };
};

module.exports = IndcomIcon();

const MainMap = require("../mapFunctions/mainMap");
const MapFunctions = require("../mapFunctions/mapFunctions");
const Tree = require("../tree");
