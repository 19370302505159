"use strict";

const IncentiveProgramsGeoServerLayer = function () {
  const layerName = "incentivePrograms";
  const layerKey = "incentiveProgramsLayer";
  var incentiveProgramsGeoServerLayer;

  var init = function () {
    incentiveProgramsGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
    );
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    if (mapId === "modal") return;
    if (!incentiveProgramsGeoServerLayer) init();
    incentiveProgramsGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
  };

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: PropertiesIcon.getIcon(feature.properties.esg_property_symbol),
    });
  };

  var loadWfsLayerData = async function (refreshData = false, loadingScreen = true) {
    return await incentiveProgramsGeoServerLayer.loadWfsLayerData({}, refreshData, loadingScreen);
  };

  var loadLayer = function (map, mapLayers) {
    incentiveProgramsGeoServerLayer.loadGeoServerLayer({ refreshData: false });
  };

  var loadGeoServerLayer = function ({ refreshData = true, loadingScreen = true }) {
    incentiveProgramsGeoServerLayer.loadGeoServerLayer(Map.getMap(), Map.getLayers(), "main", {
      refreshData,
      loadingScreen,
    });
  };

  var invalidateLayerData = function (loadingScreen = true) {
    incentiveProgramsGeoServerLayer.invalidateLayerData(Map.getMap(), Map.getLayers(), "main", {
      loadingScreen,
    });
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return _organizeLayerData(data);
  };

  var _organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return _getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var _getOrganizedProperties = function (data, geometry) {
    return {
      id: data.id,
      geometry: geometry,
      programId: data.program_identifier,
      programName: data.program_name,
      programSponsor: data.program_sponsor,
      identifiedValue: data.potential_value,
      swManaged: data.sw_managed,
      pollManaged: data.poll_managed,
      programType: data.program_type,
      programTypeDisplay: data.program_type_display,
      compType: data.comp_type,
      compTypeDisplay: data.comp_type_display,
      propertyCount: data.property_count,
      website: data.website,
      states: JSON.parse(data.states),
      regions: JSON.parse(data.regions),
      compRecurring: data.comp_recurring,
    };
  };

  return {
    init,
    loadWfsLayerData,
    loadGeoServerLayer,
    loadLayerListenersForMap,
    loadLayer,
    getLayerData,
    invalidateLayerData,
  };
};

module.exports = IncentiveProgramsGeoServerLayer();

const GeoServerLayerFunctions = require("../../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../../mapFunctions/geoServerMapLayer");
const Map = require("../../esg/map");
const PropertiesIcon = require("../icons/propertiesIcon");
