"use strict";

const PropertiesFilters = function () {
  const searchFields = ["propertyIdentifier", "propertyName", "address", "city", "state", "zip"];
  const searchDbFields = [
    "property_identifier",
    "property_name",
    "address",
    "city",
    "state",
    "zip",
  ];
  let mainSmartFilters;

  var getOptions = function (field) {
    const options = PageController.getConstants(field);
    return Array.isArray(options) ? options : options ? Object.keys(options) : [];
  };

  var shouldFilter = function (filters, field) {
    const options = getOptions(field);
    return (
      options.length &&
      filters[field]?.length !== undefined &&
      filters[field].length !== options.length
    );
  };

  var filterResourceDataHandler = async function (unfilteredData, filters, loadingScreen) {
    if (onlineFilterIsSet(filters)) {
      try {
        return await ResourceFilters.filterOnline(
          unfilteredData,
          loadingScreen,
          PlanPropertiesGeoServerLayer.getLayerData,
        );
      } catch (e) {
        ApiError.assertIs(e);
      }
    }

    return filterOffline(unfilteredData, filters);
  };

  var filterOffline = function (unfilteredData) {
    return mainSmartFilters ? mainSmartFilters.filter(unfilteredData) : unfilteredData;
  };

  var onlineFilterIsSet = function (filters) {
    return ResourceFilters.spatialFiltersAreSet(filters);
  };

  var addGeoServerFilterQuery = function (queryStrings, filters) {
    if (filters.searchString) {
      queryStrings.push(
        GeoServerFilterFunctions.getSearchQuery(searchDbFields, filters.searchString),
      );
    }
    if (filters.type === "selected") {
      queryStrings.push(`selected = true`);
      queryStrings.push("shelved = false");
    }
    if (shouldFilter(filters, "regions")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "region",
          filters.regions,
          getOptions("regions"),
        ),
      );
    }
    if (shouldFilter(filters, "states")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "state",
          filters.states,
          getOptions("states"),
        ),
      );
    }
    if (shouldFilter(filters, "huc6")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "huc6_code",
          filters.huc6,
          getOptions("huc6"),
        ),
      );
    }
    if (shouldFilter(filters, "huc8")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "huc8_code",
          filters.huc8,
          getOptions("huc8"),
        ),
      );
    }
    if (shouldFilter(filters, "acquisitionPortfolios")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "acquisition_portfolio",
          filters.acquisitionPortfolios,
          getOptions("acquisitionPortfolios"),
        ),
      );
    }
    if (shouldFilter(filters, "ownershipStatus")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "ownership_status",
          filters.ownershipStatus,
          getOptions("ownershipStatus"),
        ),
      );
    }
    if (shouldFilter(filters, "propertyTypes")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "property_type",
          filters.propertyTypes,
          getOptions("propertyTypes"),
        ),
      );
    }
    if (shouldFilter(filters, "funds")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery("fund", filters.funds, getOptions("funds")),
      );
    }
    if (shouldFilter(filters, "districts")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "district",
          filters.districts,
          getOptions("districts"),
        ),
      );
    }
    if (shouldFilter(filters, "ejCommunity")) {
      queryStrings.push(
        "ej_community = " + (filters.ejCommunity[0] === "Yes" ? "true" : "false") + "",
      );
    }
    if (shouldFilter(filters, "siteComplexity")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "site_complexity",
          filters.siteComplexity,
          getOptions("siteComplexity"),
        ),
      );
    }
    GeoServerFilterFunctions.addMinMaxQuery(queryStrings, "rank", filters.rank);
    if (shouldFilter(filters, "rankCategories")) {
      queryStrings.push(
        GeoServerFilterFunctions.getInArrayOrNotQuery(
          "esg_property_symbol",
          filters.rankCategories,
          getOptions("rankCategories"),
        ),
      );
    }
    GeoServerFilterFunctions.addMinMaxQuery(
      queryStrings,
      "impervious_acres",
      filters.imperviousAcres,
    );
  };

  const addGeoServerViewParams = function (params, filters) {
    if (shouldFilter(filters, "tenants")) {
      const tenantOptions = getOptions("tenants");
      if (filters.tenants.length < tenantOptions.length / 2) {
        params.includeTenants = filters.tenants.join("\\,");
      } else {
        const tenantsToExclude = tenantOptions.filter((t) => !filters.tenants.includes(t));
        params.excludeTenants = tenantsToExclude.join("\\,");
      }
    }
    if (shouldFilter(filters, "retrofitOpportunities")) {
      const retrofitOpportunityOptions = getOptions("retrofitOpportunities");
      if (retrofitOpportunityOptions.length === filters.retrofitOpportunities.length) return;
      params.includeRetrofitOpportunities = filters.retrofitOpportunities
        .map((item) => `'${item}'`)
        .join("\\,");
    }
    if (shouldFilter(filters, "bmpRelationship")) {
      const bmpRelationshipOptions = getOptions("bmpRelationship");
      if (bmpRelationshipOptions.length === filters.bmpRelationship.length) return;
      params.includeBmpRelationship = filters.bmpRelationship
        .map((item) => `'${item}'`)
        .join("\\,");
    }
    if (shouldFilter(filters, "compensationStatus")) {
      const compensationStatusOptions = getOptions("compensationStatus");
      if (compensationStatusOptions.length === filters.compensationStatus.length) return;
      params.includeCompensationStatus = filters.compensationStatus
        .map((item) => `'${item}'`)
        .join("\\,");
    }
  };

  var setSmartFilters = function (newSmartFilters) {
    mainSmartFilters = newSmartFilters;
  };

  var getFilterConfigs = function () {
    return {
      search: (datum, filters) =>
        ResourceFilters.passSearchStringFilter(datum, filters, searchFields),
      selected: (datum, filters) =>
        filters.type === "selected" ? datum.selected && !datum.shelved : true,
      regions: "region",
      states: "state",
      huc6: "huc6Code",
      huc8: "huc8Code",
      ownershipStatus: "ownershipStatus",
      tenants: ["tenants", "array"],
      acquisitionPortfolios: "acquisitionPortfolio",
      propertyTypes: "propertyType",
      funds: "fund",
      districts: "district",
      ejCommunity: (datum, filters) =>
        shouldFilter(filters, "ejCommunity")
          ? filters.ejCommunity.includes(datum.ejCommunity ? "Yes" : "No")
          : true,
      retrofitOpportunities: ["retrofitOpportunities", "array"],
      bmpRelationship: ["bmpRelationship", "array"],
      siteComplexity: "siteComplexity",
      compensationStatus: ["compensationStatus", "array"],
      programs: ["programs", "array"],
      rank: (datum, filters) => OfflineFilterFunctions.passMinMaxFilter(datum.rank, filters.rank),
      rankCategories: "esg_property_symbol",
      imperviousAcres: (datum, filters) =>
        OfflineFilterFunctions.passMinMaxFilter(datum.imperviousAcres, filters.imperviousAcres),
    };
  };

  return {
    getOptions,
    shouldFilter,
    filterResourceDataHandler,
    filterOffline,
    searchDbFields,
    addGeoServerFilterQuery,
    addGeoServerViewParams,
    getFilterConfigs,
    setSmartFilters,
  };
};

module.exports = PropertiesFilters();

const ApiError = require("../../errors/apiError");
const GeoServerFilterFunctions = require("../../mapFunctions/geoServerFilterFunctions");
const ResourceFilters = require("../../filters/resourceFilters");
const PlanPropertiesGeoServerLayer = require("./planPropertiesGeoServerLayer");
const PageController = require("../pageController");
const OfflineFilterFunctions = require("../../mapFunctions/offlineFilterFunctions");
