"use strict";

const ToolTips = function () {
  const $tip = $("#esg-tooltip");
  let hideTooltipTimeout;

  const init = function (pageName, data = {}) {
    $tip.off().on("mouseenter", () => clearTimeout(hideTooltipTimeout));

    const _hideToolTip = function ($hoverButton) {
      hideTooltipTimeout = setTimeout(() => {
        $hoverButton.removeClass("active");
        $tip.addClass("default-hidden").attr("for", "");
      }, 200);
    };

    $("[data-tooltip]").each(function () {
      const $item = $(this);
      const showAboveSelector = $item.data("show-above");
      const showBelowSelector = $item.data("show-below");
      const $elementToGoAbove = showAboveSelector ? $(showAboveSelector) : $item;
      const $elementToGoBelow = showBelowSelector ? $(showBelowSelector) : $item;

      if ($elementToGoAbove.length !== 1) {
        console.error(`Tooltip: ${showAboveSelector} selector did not return one element`);
      }

      if ($elementToGoBelow.length !== 1) {
        console.error(`Tooltip: ${showBelowSelector} selector did not return one element`);
      }

      const hoverButtonSelector = $item.data("hover-button");
      let $hoverButton = hoverButtonSelector === "none" ? $item : $(hoverButtonSelector);
      if (!$hoverButton.length) {
        $hoverButton = $(`<div class="tooltip-icon"></div>`).appendTo($item);
      }

      $tip.on("mouseleave", () => _hideToolTip($hoverButton));
      $hoverButton.on("mouseleave", () => _hideToolTip($hoverButton));

      $hoverButton.on("mouseenter", () => {
        clearTimeout(hideTooltipTimeout);

        $hoverButton.addClass("active");

        const elTop = Math.round($elementToGoAbove.offset().top);
        const elBottom = Math.round($elementToGoBelow.offset().top + $elementToGoBelow.height());
        const elLeft = Math.round($elementToGoAbove.offset().left);
        const elWidth = $elementToGoAbove.width();

        const template = `esg/toolTips/${pageName}/${$item.data("tooltip")}.njk`;
        $tip.html(nunjucks.render(template, data));

        $tip.css({
          left: elLeft + "px",
          width: $item.data("tooltip-width") || elWidth + "px",
          height: $item.data("tooltip-height") || "auto",
        });

        $tip.removeClass("default-hidden");
        $tip.css({ top: elTop - Math.round($tip.height()) - 30 + "px" });

        if ($tip.offset().top < 0) {
          $tip.css({ top: elBottom + 4 + "px" });
        }

        $tip.attr("for", $item.attr("data-tooltip"));
      });
    });
  };

  return { init };
};

module.exports = ToolTips();
