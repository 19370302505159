"use strict";

const EsgDropdownOptions = function () {
  const planPhaseOptions = [
    { name: "Draft", value: "draft" },
    { name: "Planning", value: "planning" },
    { name: "Completed", value: "completed" },
  ];
  return {
    planPhaseOptions,
  };
};

module.exports = EsgDropdownOptions();
