"use strict";

const SimpleOutfallPopup = function () {
  var getPopupHtml = function (data) {
    const popupProperties = {
      diameterLabel:
        FormSettings.getLabelSettingsByFormKey("outfall-inventory")?.find(
          (label) => label.key === "outlet-diameter",
        )?.newLabel ?? null,
      featureProperties: data,
      readOnly: true,
      popupColor: "grey",
      permissions: UserPermissions.getAllPermissions(),
    };

    return nunjucks.render("popups/simpleOutfallsPopup.njk", popupProperties);
  };

  var onPopupOpenWithData = function (data, $element, invalidateLayerDataFunction) {
    // Intentionally left blank
  };

  var onPopupOpen = async function (properties, layerName) {
    const id = properties.id;
    const data = await ApiCalls.getOutfallPopup(id);
    const html = getPopupHtml(data);
    const layerModule = LayerFunctions.getLayerModule(layerName);

    const $popup = $(".leaflet-popup-content");
    $popup.html(html);
    onPopupOpenWithData(data, $popup, layerModule.invalidateLayerData);
  };

  return {
    getPopupHtml,
    onPopupOpen,
    onPopupOpenWithData,
  };
};

module.exports = SimpleOutfallPopup();

const ApiCalls = require("../apiCalls");
const FormSettings = require("../settings/formSettings");
const LayerFunctions = require("../layerFunctions");
const UserPermissions = require("../login/userPermissions");
